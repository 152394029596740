import axios from "axios";
import { Helmet } from "react-helmet";
import { Box, Typography, Paper, Link } from "@mui/material";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectComponentsProvider,
  ConnectPayments,
  ConnectPayouts,
  ConnectAccountManagement,
} from "@stripe/react-connect-js";
import { CircularProgress } from "@mui/material";

import { useScreenSize } from "globals/hooks";
import MoovsTabs from "components/globals/MoovsTabs";
import EntityHeader from "components/common/EntityHeader";
import { grayLight } from "../../design-system/colors";
import { getServerLink } from "globals/utils/getServerLink";

export enum StripeFinancesPageTab {
  Payments,
  Payouts,
  Balances,
  AccountManagement,
}

type StripeAccountSessionResponse = {
  client_secret: string;
};

function StripeFinancesPage() {
  const history = useHistory();
  const location = useLocation<{ from: any }>();

  const fetchClientSecret = async (): Promise<string> => {
    const url = `${getServerLink()}/account-session`;
    try {
      const response = await axios.post<StripeAccountSessionResponse>(url);
      return response.data.client_secret;
    } catch (error) {
      if (error.response?.status === 400) {
        setNoStripeAccount(true);
      } else {
        Sentry.captureException(error);
      }
    }
    return null;
  };

  const [loading, setLoading] = useState(true);
  const [noStripeAccount, setNoStripeAccount] = useState(false);
  const [tabMode, setTabMode] = useState<StripeFinancesPageTab>(
    StripeFinancesPageTab.Payments
  );
  const [stripeConnectInstance] = useState(() => {
    return loadConnectAndInitialize({
      publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
      fetchClientSecret: fetchClientSecret,
    });
  });
  const stripeComponentDoneLoading = () => setLoading(false);

  const { isMobileView } = useScreenSize();

  if (!stripeConnectInstance) {
    return <CircularProgress />;
  }

  const tabs = [
    { label: "PAYMENTS", value: StripeFinancesPageTab.Payments },
    { label: "PAYOUTS", value: StripeFinancesPageTab.Payouts },
    {
      label: "ACCOUNT MANAGEMENT",
      value: StripeFinancesPageTab.AccountManagement,
    },
  ];

  const handleTabChange = (newValue: StripeFinancesPageTab) => {
    setLoading(true);
    setTabMode(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Finances | Moovs</title>
      </Helmet>

      <Box justifyContent="center" width="100%">
        <Box
          position="sticky"
          top={isMobileView ? "56px" : "78px"}
          zIndex={10}
          width="100%"
        >
          <Paper
            square
            style={{ borderBottom: `1px solid ${grayLight}` }}
            elevation={0}
          >
            <Box data-testid="entity-header" maxWidth="900px" margin="auto">
              <EntityHeader title="Finances" entityName="Finance" />
              <Box
                data-testid="status-tabs"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <MoovsTabs
                  value={tabMode}
                  tabs={tabs}
                  onChange={handleTabChange}
                />
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box
          data-testid="list-container"
          sx={[
            {
              display: "flex",
              flex: 1,
              overflowY: "auto",
              flexDirection: "column",
            },
          ]}
        >
          <Box
            display="flex"
            flexDirection="row"
            flex="1"
            px={3}
            mb={1}
            justifyContent="center"
          >
            <Box justifyContent="center" width="70%" mt={2}>
              <ConnectComponentsProvider
                connectInstance={stripeConnectInstance}
              >
                {noStripeAccount ? (
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    width="70%"
                    mt={2}
                  >
                    <Typography textAlign="center">
                      No Stripe account found
                    </Typography>
                    <Link
                      component="button"
                      underline="none"
                      variant="body1"
                      color="primary"
                      sx={{ fontWeight: 500, mt: 2, width: "100%" }}
                      onClick={() =>
                        history.push(
                          "/settings/general?tab=payments",
                          { from: location?.state?.from }
                        )
                      }
                    >
                      Set up Stripe account
                    </Link>
                  </Box>
                ) : (
                  <>
                    {loading && (
                      <Box
                        justifyContent="center"
                        display="flex"
                        width="100%"
                        mt={20}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                    {tabMode === StripeFinancesPageTab.Payments && (
                      <ConnectPayments
                        onLoaderStart={stripeComponentDoneLoading}
                      />
                    )}
                    {tabMode === StripeFinancesPageTab.Payouts && (
                      <ConnectPayouts
                        onLoaderStart={stripeComponentDoneLoading}
                      />
                    )}
                    {tabMode === StripeFinancesPageTab.AccountManagement && (
                      <ConnectAccountManagement
                        onLoaderStart={stripeComponentDoneLoading}
                      />
                    )}
                  </>
                )}
              </ConnectComponentsProvider>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default StripeFinancesPage;
