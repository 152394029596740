import React from "react";
import toLower from "lodash/toLower";

import {
  Typography,
  CircularProgress,
  Box,
  Card,
  CardActionArea,
} from "@mui/material";

import {
  moovsBlueSelected,
  moovsBlue,
  moovsBlueLight,
} from "design-system/colors";
import { CompanyIcon } from "design-system/icons";
import { useRequestCompanyLinking } from "globals/hooks";
import { RequestStage } from "types";

type LinkCompanyButtonProps = {
  requestId: string;
  companyName: string;
  stage: RequestStage;
};

function LinkCompanyButton(props: LinkCompanyButtonProps) {
  const { requestId, companyName, stage } = props;

  // hooks
  const { linkCompanyLoading, onLinkCompanyToRequest } =
    useRequestCompanyLinking({ requestId, stage });

  return (
    <Card
      variant="outlined"
      sx={{
        borderWidth: "1px",
        borderColor: moovsBlueLight,
      }}
    >
      <CardActionArea
        onClick={onLinkCompanyToRequest}
        disabled={linkCompanyLoading}
        sx={{
          bgcolor: moovsBlueSelected,
          width: "100%",
          height: 36,
        }}
      >
        <Box display="flex" flexDirection="row" justifyContent="center">
          {linkCompanyLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Box px={1} display="flex" mt="2px">
              <Box mr={1}>
                <CompanyIcon color={moovsBlue} size="small" strokeWidth="2" />
              </Box>
              <Typography
                fontSize="12px"
                fontWeight={500}
                color={moovsBlue}
                mb={0.3}
              >
                Link {toLower(stage)} to {companyName}
              </Typography>
            </Box>
          )}
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default LinkCompanyButton;
