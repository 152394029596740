import React, { useMemo, useState } from "react";
import uniqBy from "lodash/uniqBy";

import { Box, Typography, Divider } from "@mui/material";

import ProfileInfoCard from "../../../../globals/ProfileInfoCard";
import { DeepPartial } from "ts-essentials";
import { Trip, Contact } from "types";
import CreateTemporaryPassengerDialog from "../../CreateTemporaryPassengerDialog";
import ReplacePassengerDialog from "../../../../globals/ReplacePassengerDialog";
import { PlusIcon, ReplaceContactIcon } from "design-system/icons";
import { grayDark, moovsBlue } from "design-system/colors";
import AddContactToOrderDialog from "components/contacts/people/AddContactToOrderDialog";
import MoovsEllipsisMenu from "components/MoovsEllipsisMenu";
import { getContactForReservation } from "globals/utils/contact";
import ContactAutoComplete from "components/autocompletes/ContactAutoComplete";
import { useQuery } from "@apollo/client";
import { LOAD_CONTACTS_QUERY } from "globals/graphql";
import { useLaunchDarklyFlags } from "globals/utils/useLaunchDarklyFlags";

export const initialTemporaryPassenger = {
  name: "",
  mobilePhone: "",
  mobilePhoneInternational: "",
  phoneCountryCode: "",
  phoneCountryDialCode: "",
  phoneCountryName: "",
  phoneCountryFormat: "",
};

const defaultContact = {
  firstName: "",
  lastName: "",
  email: "",
  mobilePhone: "",
};

type TripDetailsCreateBlockType = {
  trip: DeepPartial<Trip>;
  tripIndex: number;
  updateTrip: (newTrip: DeepPartial<Trip>, tripIndex: number) => void;
  companyId?: string;
  bookingContact: Contact | null;
};

function TripDetailsCreateBlock(props: TripDetailsCreateBlockType) {
  const { trip, updateTrip, tripIndex, companyId, bookingContact } = props;

  // hooks
  const { data: contacts } = useQuery(LOAD_CONTACTS_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      searchTerm: "",
      limit: 30,
    },
  });

  const bookingContactLinkedPassengerIds = useMemo(
    () =>
      bookingContact?.linkedPassengers?.map((passenger) => passenger.id) || [],
    [bookingContact]
  );
  const suggestedContacts = useMemo(
    () =>
      uniqBy(
        [
          ...(contacts?.loadContacts?.edges.map(({ node }) => node) || []),
          ...(bookingContact?.linkedPassengers || []),
        ],
        "id"
      ),
    [bookingContact?.linkedPassengers, contacts?.loadContacts?.edges]
  );

  const { enableLinkedPassenger } = useLaunchDarklyFlags();

  // state
  const [replaceDialogOpen, setReplaceDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [
    createTemporaryPassengerDialogOpen,
    setCreateTemporaryPassengerDialogOpen,
  ] = useState(false);
  const [isSearchPassengerDropdownOpen, setIsSearchPassengerDropdownOpen] =
    useState(false);
  const [temporaryPassengerValues, setTemporaryPassengerValues] = useState(
    trip.temporaryPassenger || initialTemporaryPassenger
  );
  const [temporaryPassengerErrors, setTemporaryPassengerErrors] = useState({
    name: "",
  });
  const [carryOverSearch, setCarryOverSearch] = useState("");

  // event handlers
  const handleReplacePassengerClick = () => setReplaceDialogOpen(true);
  const handleReplaceDialogClose = () => setReplaceDialogOpen(false);

  const handleReplaceTripContact = (tripContact: Contact) => {
    updateTrip(
      {
        ...trip,
        contact: getContactForReservation(tripContact),
      },
      tripIndex
    );

    handleReplaceDialogClose();
  };

  const handleCreateTemporaryPassengerClick = () => {
    setCreateTemporaryPassengerDialogOpen(true);
  };

  const handleAddNewContactClick = () => {
    setAddDialogOpen(true);
  };

  const handleCreateNewContactClick = (contact: Contact) => {
    updateTrip(
      {
        ...trip,
        contact: contact,
      },
      tripIndex
    );
  };

  const handleUpdateTrip = (contact: Contact) => {
    updateTrip(
      {
        ...trip,
        contact: contact || defaultContact,
      },
      tripIndex
    );
    setIsSearchPassengerDropdownOpen(false);
  };

  const handleOpenSearchPassengerDropdown = () => {
    setIsSearchPassengerDropdownOpen(true);
  };

  const handleCreateTemporaryPassengerDialogClose = () =>
    setCreateTemporaryPassengerDialogOpen(false);

  const handleResetBookingContact = () => {
    handleReplaceTripContact(bookingContact);
  };

  const handleRemoveTemporaryPassengerClick = () => {
    setTemporaryPassengerValues({
      name: "",
      mobilePhone: "",
      mobilePhoneInternational: "",
      phoneCountryCode: "",
      phoneCountryDialCode: "",
      phoneCountryName: "",
      phoneCountryFormat: "",
    });
    updateTrip(
      {
        ...trip,
        temporaryPassenger: {
          name: "",
          mobilePhone: "",
        },
      },
      tripIndex
    );
  };

  const isTripContact = !!trip.contact?.firstName;
  const isTemporaryPassenger = !!trip.temporaryPassenger?.name;
  const hasMoreThanOneLinkedPassenger =
    bookingContact?.linkedPassengers?.length > 1;

  return (
    <>
      <Box
        mt={4}
        mb={1.5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" style={{ fontWeight: 700 }}>
          Trip Details
        </Typography>

        {hasMoreThanOneLinkedPassenger && !isTripContact && (
          <MoovsEllipsisMenu
            icon={<PlusIcon color={moovsBlue} />}
            size="large"
            options={[
              {
                text: "Existing Contact",
                onClick: handleOpenSearchPassengerDropdown,
              },
              {
                text: "Temporary Passenger",
                onClick: handleCreateTemporaryPassengerClick,
              },
              {
                text: "New Contact",
                onClick: handleAddNewContactClick,
              },
              {
                text: "Booking Contact",
                onClick: handleResetBookingContact,
              },
            ]}
          />
        )}
      </Box>

      {enableLinkedPassenger &&
        isSearchPassengerDropdownOpen &&
        !isTripContact && (
          <Box>
            <ContactAutoComplete
              label="Search for passenger"
              onChange={handleUpdateTrip}
              value={trip?.contact || defaultContact}
              setCarryOverSearch={setCarryOverSearch}
              suggestedContacts={suggestedContacts}
              onCreateNewContactClick={handleAddNewContactClick}
              contactsOptionsGroupBy={{
                "ADD LINKED PASSENGER": bookingContactLinkedPassengerIds,
              }}
            />
          </Box>
        )}

      <Box mb={2}>
        <Divider />
      </Box>

      {isTemporaryPassenger && (
        <ProfileInfoCard
          headerText="Temporary Passenger"
          firstName={trip.temporaryPassenger.name}
          phone={trip.temporaryPassenger.mobilePhone || ""}
          actionButtons={[
            {
              icon: <ReplaceContactIcon color={grayDark} />,
              menuOptions: [
                { label: "Header", header: "REPLACE CONTACT" },
                {
                  eventHandler: handleCreateTemporaryPassengerClick,
                  label: "Edit Temporary Passenger",
                },
                {
                  eventHandler: handleRemoveTemporaryPassengerClick,
                  label: "Remove Temporary Passenger",
                },
              ],
            },
          ]}
        />
      )}
      {isTripContact && !isTemporaryPassenger && (
        <ProfileInfoCard
          headerText="Passenger"
          firstName={trip.contact.firstName}
          lastName={trip.contact.lastName}
          email={trip.contact.email}
          phone={trip.contact.mobilePhone}
          preferences={trip.contact.preferences}
          actionButtons={[
            {
              icon: <ReplaceContactIcon color={grayDark} />,
              menuOptions: [
                { label: "Header", header: "REPLACE PASSENGER" },
                {
                  eventHandler: handleReplacePassengerClick,
                  label: "Existing Contact",
                },
                {
                  eventHandler: handleCreateTemporaryPassengerClick,
                  label: "Temporary Passenger",
                },
                {
                  eventHandler: handleAddNewContactClick,
                  label: "New Contact",
                },
                ...(trip.contact.id !== bookingContact?.id
                  ? [
                      {
                        eventHandler: handleResetBookingContact,
                        label: "Booking Contact",
                      },
                    ]
                  : []),
              ],
            },
          ]}
        />
      )}
      {!isTemporaryPassenger && !isTripContact && (
        <Typography>No passenger</Typography>
      )}
      <CreateTemporaryPassengerDialog
        open={createTemporaryPassengerDialogOpen}
        onClose={handleCreateTemporaryPassengerDialogClose}
        trip={trip}
        tripIndex={tripIndex}
        updateTrip={updateTrip}
        temporaryPassengerValues={temporaryPassengerValues}
        setTemporaryPassengerValues={setTemporaryPassengerValues}
        temporaryPassengerErrors={temporaryPassengerErrors}
        setTemporaryPassengerErrors={setTemporaryPassengerErrors}
      />
      <ReplacePassengerDialog
        open={replaceDialogOpen}
        handleClose={handleReplaceDialogClose}
        handleSave={handleReplaceTripContact}
        suggestedContacts={suggestedContacts}
        bookingContactLinkedPassengerIds={bookingContactLinkedPassengerIds}
      />
      <AddContactToOrderDialog
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onContactUpdate={handleCreateNewContactClick}
        carryOverSearch={carryOverSearch}
        companyId={companyId}
      />
    </>
  );
}

export default TripDetailsCreateBlock;
