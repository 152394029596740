import React from "react";
import capitalize from "lodash/capitalize";

import { Box, Link, Typography } from "@mui/material";

import googleIcon from "design-system/illustrations/moovsMarketApps/google.svg";
import facebookIcon from "design-system/illustrations/moovsMarketApps/facebook.svg";
import yelpIcon from "design-system/illustrations/moovsMarketApps/yelp.svg";
import { grayDark, moovsBlue } from "design-system/colors";
import { ReviewSite } from "types";
import { useOperator, useScreenSize } from "globals/hooks";
import { OpenIcon } from "design-system/icons";

const ReviewPlatformIcon = {
  [ReviewSite.Google]: googleIcon,
  [ReviewSite.Facebook]: facebookIcon,
  [ReviewSite.Yelp]: yelpIcon,
};

function ReviewPlatformInfo() {
  // hooks
  const {
    settings: {
      reviewSite,
      reviewSiteUrlPrefix,
      reviewSiteUrlSuffix,
      reviewSiteId,
    },
  } = useOperator();
  const { isMobileView } = useScreenSize();

  // derived state
  const reviewPlatformURL =
    "https://" + reviewSiteUrlPrefix + reviewSiteId + reviewSiteUrlSuffix;

  return (
    <Box display="flex" flexDirection={isMobileView ? "column" : "row"}>
      {/* Review Platform Icon + Name */}
      <Box
        display="flex"
        flexDirection={isMobileView ? "row" : "column"}
        alignItems="center"
      >
        <Box maxWidth="32px" maxHeight="32px">
          <img
            src={ReviewPlatformIcon[reviewSite]}
            alt={reviewSite + "icon"}
            {...(isMobileView && {
              height: "24px",
              width: "24px",
            })}
          />
        </Box>

        <Typography
          variant="subtitle2"
          {...(isMobileView ? { ml: 2 } : { mt: 1 })}
        >
          {capitalize(reviewSite)}
        </Typography>
      </Box>

      {/* Review Platform Link */}
      <Box
        display="flex"
        alignItems="center"
        {...(isMobileView ? { my: 2 } : { ml: 4 })}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="body2" color={grayDark} mt={1}>
            {`${isMobileView ? "Tap" : "Click"} to test your URL`}
          </Typography>

          <Box flexWrap="wrap">
            <Typography display="inline" variant="body2" color={moovsBlue}>
              {reviewSiteUrlPrefix}
            </Typography>
            <Typography
              display="inline"
              variant="body2"
              fontWeight={700}
              color={moovsBlue}
            >
              {reviewSiteId}
            </Typography>
            <Typography display="inline" variant="body2" color={moovsBlue}>
              {reviewSiteUrlSuffix}
            </Typography>
          </Box>
        </Box>

        <Box height="100%" display="flex" alignItems="center" ml={2} mt={1}>
          <Link target="_blank" href={reviewPlatformURL}>
            <OpenIcon color={moovsBlue} />
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default ReviewPlatformInfo;
