import React, { useEffect, useState } from "react";
import size from "lodash/size";
import { useHistory, useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";
import MoovsMultiDatePicker from "components/MoovsMultiDatePicker";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { useMutation } from "@apollo/client";
import { CREATE_DUPLICATE_TRIP_MUTATION } from "globals/graphql";
import { Request } from "types";
import { getRequestStage } from "globals/utils/getRequestStage";
import { pluralize } from "globals/utils/helpers";

type DuplicateTripDialogProps = {
  open: boolean;
  onClose: () => void;
  request: Request;
  tripId: string;
  isParentDrawer?: boolean;
};

function DuplicateTripDialog(props: DuplicateTripDialogProps) {
  const { open, onClose, request, tripId, isParentDrawer } = props;

  // derived state
  const requestId = request?.id;

  // hooks
  const location = useLocation();
  const history = useHistory();
  const { track } = useAnalytics();
  const snackbar = useSnackbar();

  // state
  const [dateList, setDateList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  // mutations
  const [createDuplicateTrip] = useMutation(CREATE_DUPLICATE_TRIP_MUTATION, {
    onCompleted(data) {
      setIsSubmitting(false);

      const { trips } = data.createDuplicateTrip.request.trips;

      snackbar.success("Successfully duplicated trip");
      track("trip_duplicated", {
        numberOfTripsDuplicated: size(trips),
      });

      clearListAndClose();

      if (isParentDrawer) {
        const requestStage = pluralize(getRequestStage(request.stage));
        history.push({
          pathname: `/${requestStage}/${requestId}`,
          search: location.search,
        });
      }
    },
    onError(error) {
      setIsSubmitting(false);
      snackbar.error(error.message);
    },
    refetchQueries: ["LoadTripConflict"],
  });

  // event handlers
  const handleSubmit = () => {
    setIsSubmitting(true);
    setErrorMsg(null);

    if (size(dateList) < 1) {
      setIsSubmitting(false);
      setErrorMsg("Please select at least one date");
      return;
    }

    const tripDates = dateList.map((momentDate) => momentDate.toDate());

    createDuplicateTrip({
      variables: {
        input: {
          tripId,
          tripDates,
        },
      },
    });
  };

  const clearListAndClose = () => {
    setDateList([]);
    onClose();
  };

  const handleOnClose = () => {
    if (isSubmitting) return;

    clearListAndClose();
  };

  // effects
  useEffect(() => {
    if (!!errorMsg && size(dateList)) setErrorMsg(null);
  }, [errorMsg, dateList]);

  return (
    <MoovsDialog
      open={open}
      onClose={handleOnClose}
      dialogTitle={"Duplicate Trip"}
      size="sm"
      xsFullscreenMobile
      acceptButtonText="Duplicate"
      closeButtonText="Cancel"
      onAccept={handleSubmit}
      acceptDisabled={isSubmitting}
    >
      <Box display="flex" flex="1" alignItems="center" justifyContent="center">
        <MoovsMultiDatePicker
          dateList={dateList}
          setDateList={setDateList}
          dateLimit={5}
          errorMsg={errorMsg}
        />
      </Box>
    </MoovsDialog>
  );
}

export default DuplicateTripDialog;
