import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/client";

import { Box, Typography, Divider, Button, Hidden } from "@mui/material";

import { PlusIcon } from "../../design-system/icons";
import { granite, white } from "../../design-system/colors";
import TermsList from "../../components/settings/terms/TermsList";
import { LOAD_TERMS_QUERY, LOAD_TERM_TYPES_QUERY } from "../../globals/graphql";
import { Term } from "../../types";
import GQLQueryStatusIndicator from "../../components/GQLQueryStatusIndicator";
import { useSnackbar } from "../../globals/hooks/useSnackbar";
import SquareButton from "../../design-system/SquareButton";
import { useAnalytics, useScreenSize } from "../../globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import {
  containerStyles,
  headerStyles,
  listStyles,
  tableStyles,
} from "./utils/styles";

function TermsSettingsPage() {
  const snackbar = useSnackbar();
  const { isSmallTabletView } = useScreenSize();
  const { track } = useAnalytics();

  const {
    data: termsData,
    refetch: refetchTerms,
    loading: termsLoading,
  } = useQuery(LOAD_TERMS_QUERY);

  const { data: termTypesData, loading: termTypesLoading } = useQuery(
    LOAD_TERM_TYPES_QUERY,
    {
      onError: (error) => {
        const errorMessage = getErrorMessage(error) || error.message;

        snackbar.error(errorMessage);
      },
    }
  );

  const terms = useMemo(() => (termsData ? termsData.terms : []), [termsData]);
  const noTerms = !!terms.length;

  const termTypes = useMemo(() => {
    if (!termTypesData) return [];

    const allTerms = termTypesData ? termTypesData.termTypes : [];

    return allTerms.filter((term: Term, index: number) => {
      return terms.every(({ name }) => name !== term.name);
    });
  }, [termTypesData, terms]);

  const handleCreateTermClick = () =>
    track("termsAndConditions_createInitiated");

  if (!termsData || !termTypesData || termsLoading || termTypesLoading) {
    return (
      <GQLQueryStatusIndicator
        name="Terms"
        data={termsData || termTypesData}
        loading={termsLoading || termTypesLoading}
        refetch={refetchTerms}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Terms | Moovs</title>
      </Helmet>
      <Box sx={containerStyles}>
        <Box sx={headerStyles}>
          <Typography variant="h2">Terms & Conditions</Typography>
          <Box>
            {noTerms && !isSmallTabletView && (
              <Button
                disableElevation
                variant="contained"
                color="primary"
                component={Link}
                to="/settings/terms-and-conditions/create"
                onClick={handleCreateTermClick}
              >
                Create Term
              </Button>
            )}
            {noTerms && isSmallTabletView && (
              <SquareButton
                disableElevation
                variant="contained"
                color="primary"
                component={Link}
                to="/settings/terms-and-conditions/create"
                onClick={handleCreateTermClick}
              >
                <PlusIcon color={white} />
              </SquareButton>
            )}
          </Box>
        </Box>
        <Hidden mdDown>
          <Box px={3}>
            <Divider />
          </Box>
        </Hidden>
        {noTerms ? (
          <Box {...(!isSmallTabletView && { sx: tableStyles })}>
            <TermsList terms={terms} sxStyles={listStyles} />
          </Box>
        ) : (
          <Box
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            display="flex"
            flex="1"
            flexDirection="column"
          >
            <Box m={1}>
              <Typography variant="h5">No Terms Added Yet.</Typography>
            </Box>
            <Box maxWidth="410px" mb={3}>
              <Typography variant="body2" sx={{ color: granite }}>
                Carefully set the rules and guidelines your customers must
                follow in order to use your service. Customize each term to
                represent how you professionally run your business.
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/settings/terms-and-conditions/create"
                disabled={!termTypes.length}
                onClick={handleCreateTermClick}
              >
                Create Term
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}

export default TermsSettingsPage;
