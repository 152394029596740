import React, { useState } from "react";

import { Box, Divider, Typography } from "@mui/material";

import { useScreenSize } from "globals/hooks";
import { LoadTransactionsForPaymentMethodConnection } from "types";
import TransactionListItem from "components/globals/TransactionListItem/";
import MoovsInfiniteScroll from "components/MoovsInfiniteScroll";
import ViewMoreDetailsDialog from "components/invoices/ViewMorePaymentDetailsDialog";
import { white } from "design-system/colors";

type CardTransactionListProps = {
  loading: boolean;
  fetchMore: () => void;
  data: LoadTransactionsForPaymentMethodConnection;
};

function CardTransactionList(props: CardTransactionListProps) {
  // props
  const { loading, fetchMore, data } = props;

  // state
  const [viewMoreDetailsPaymentId, setViewMoreDetailsPaymentId] = useState("");

  // hooks
  const { isMobileView } = useScreenSize();

  // handler
  const handleCloseViewPaymentDialog = () => {
    setViewMoreDetailsPaymentId("");
  };

  return (
    <Box>
      {!isMobileView && (
        <Box position="sticky" zIndex={10} top="-8px">
          <Box
            display="flex"
            flex="1"
            justifyContent="flex-start"
            bgcolor={white}
            py={2}
          >
            <Box flex={0.2} />
            <Box flex={0.4}>
              <Typography variant="overline">Amount</Typography>
            </Box>
            <Box flex={0.6}>
              <Typography variant="overline">Date</Typography>
            </Box>
            <Box flex={0.6}>
              <Typography variant="overline">Status</Typography>
            </Box>
            <Box flex={0.5}>
              <Typography variant="overline">Risk Evaluation</Typography>
            </Box>
            <Box flex={0.1}></Box>
          </Box>
          <Divider />
        </Box>
      )}

      <Box mb={2}>
        <MoovsInfiniteScroll
          loading={loading}
          data={data}
          next={fetchMore}
          name="transactions"
          scrollableId="transactionsDialog"
        >
          {data.edges?.map((transactionData, index) => (
            <TransactionListItem
              transaction={transactionData.node}
              key={transactionData.node.id}
              isFirstItem={index === 0}
              setViewMoreDetailsPaymentId={setViewMoreDetailsPaymentId}
            />
          ))}
        </MoovsInfiniteScroll>
      </Box>

      <ViewMoreDetailsDialog
        open={!!viewMoreDetailsPaymentId}
        onClose={handleCloseViewPaymentDialog}
        paymentId={viewMoreDetailsPaymentId}
      />
    </Box>
  );
}

export default CardTransactionList;
