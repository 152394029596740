import React from "react";

type DriverPayoutIconProps = {
  color?: string;
};

const DriverPayoutIcon = (props: DriverPayoutIconProps) => {
  const { color } = props;

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2 5.5V1.8C12.2 1.58783 12.1157 1.38434 11.9657 1.23431C11.8157 1.08429 11.6122 1 11.4 1H1.8C1.58783 1 1.38434 1.08429 1.23431 1.23431C1.08429 1.38434 1 1.58783 1 1.8V10.6C1 10.8122 1.08429 11.0157 1.23431 11.1657C1.38434 11.3157 1.58783 11.4 1.8 11.4H5M9.70224 6.5H8.07524C7.82554 6.49976 7.58354 6.572 7.39086 6.70431C7.19818 6.83662 7.06687 7.02072 7.0195 7.22496C6.97214 7.42919 7.01168 7.64079 7.13133 7.82336C7.25098 8.00594 7.44325 8.14807 7.67509 8.22533L9.3269 8.77533C9.55823 8.85287 9.74995 8.99504 9.86921 9.17745C9.98846 9.35987 10.0278 9.57117 9.98048 9.77511C9.93317 9.97905 9.80214 10.1629 9.60988 10.2952C9.41762 10.4274 9.1761 10.4999 8.92675 10.5H7.30136M8.5018 6.5V6M8.5018 11V10.5M1.5 4.5H6M13 8.5C13 10.9853 10.9853 13 8.5 13C6.01472 13 4 10.9853 4 8.5C4 6.01472 6.01472 4 8.5 4C10.9853 4 13 6.01472 13 8.5Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DriverPayoutIcon;
