import React, { useState } from "react";

import ActionButton from "design-system/components/ActionButton";
import { EmailIcon } from "design-system/icons";
import { useAnalytics } from "globals/hooks";
import { EmailModeEnum, Request, Trip } from "types";
import SendEmailDialog from "components/SendEmailDialog";
import { EmailRecipient } from "components/SendEmailDialog/SendEmailDialog";

type SendEmailActionButtonProps = {
  request: Request;
  trip: Trip;
};

function SendEmailActionButton(props: SendEmailActionButtonProps) {
  const { request, trip } = props;

  // hooks
  const { track } = useAnalytics();

  // state
  const [sendEmailDialogOpen, setSendEmailDialogOpen] = useState(false);

  // derived state
  const bookingContact = request.bookingContact;
  const tripContact = trip.tempPassenger?.name ? null : trip.contact;
  const toEmails = bookingContact ? [bookingContact] : [];
  const ccEmails =
    tripContact && bookingContact.id !== tripContact.id ? [tripContact] : [];

  // event handlers
  const handleSendEmailButtonClick = async () => {
    track("reservations_sendEmailOpened");
    handleSendEmailOpen();
  };

  const handleSendEmailOpen = () => setSendEmailDialogOpen(true);
  const handleSendEmailClose = () => setSendEmailDialogOpen(false);

  return (
    <>
      <ActionButton
        icon={EmailIcon}
        label="Email"
        onClick={handleSendEmailButtonClick}
        maxWidth={65}
      />

      <SendEmailDialog
        open={sendEmailDialogOpen}
        onClose={handleSendEmailClose}
        mode={EmailModeEnum.Reservation}
        requestId={request.id}
        // @ts-ignore fix later
        toEmails={toEmails as EmailRecipient}
        // @ts-ignore fix later
        ccEmails={ccEmails as EmailRecipient}
      />
    </>
  );
}

export default SendEmailActionButton;
