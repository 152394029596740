import React from "react";
import moment from "moment";
import isNil from "lodash/isNil";

import RoundChip from "design-system/components/chips/RoundChip";

import {
  black,
  errorRed,
  successGreen,
  tintGreen,
  tintRed,
} from "design-system/colors";

export const promoCodeStyles = {
  promoCodeName: {
    fontWeight: 700,
    color: black,
    fontSize: 14,
  },
  promoCodeInfoCopy: {
    color: black,
  },
};

export const getFormattedAmount = (promoCodeAmt, promoCodePercent) => {
  if (!isNil(promoCodeAmt)) {
    return `$${promoCodeAmt.toFixed(2)}`;
  } else if (!isNil(promoCodePercent)) {
    return `${Number((promoCodePercent * 100).toFixed(2))}%`;
  } else {
    return "";
  }
};

export const getActiveStatusChip = (active) => {
  return active ? (
    <RoundChip
      label={"ACTIVE"}
      color={successGreen}
      backgroundColor={tintGreen}
    />
  ) : (
    <RoundChip label={"INACTIVE"} color={errorRed} backgroundColor={tintRed} />
  );
};

export const getFormattedDateRange = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return "";
  }

  const startDateFormatted = moment.utc(startDate).format("M/DD/YY");
  const endDateFormatted = moment.utc(endDate).format("M/DD/YY");

  return `${startDateFormatted} - ${endDateFormatted}`;
};
