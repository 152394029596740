import React from "react";

import { Box } from "@mui/material";

import NotificationsBlock from "../../../../components/settings/general/NotificationsBlock";
import ChatBlock from "../../../../components/settings/general/ChatBlock/ChatBlock";
import CustomDomainBlock from "../../../../components/settings/general/CustomDomainBlock/CustomDomainBlock";

type CommunicationsTabProps = {
  setSaveIndicatorState: (state: string) => void;
};

function CommunicationsTab(props: CommunicationsTabProps) {
  const { setSaveIndicatorState } = props;
  return (
    <Box mb={4}>
      <NotificationsBlock setSaveIndicatorState={setSaveIndicatorState} />
      {/* Chat (Twilio) */}
      <Box mb={4}>
        <ChatBlock setSaveIndicatorState={setSaveIndicatorState} />
      </Box>
      {/* Custom Domain (Sendgrid) */}
      <Box mb={16}>
        <CustomDomainBlock setSaveIndicatorState={setSaveIndicatorState} />
      </Box>
    </Box>
  );
}

export default CommunicationsTab;
