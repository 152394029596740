import React, { MouseEvent, useMemo, useRef, useState } from "react";

import {
  Box,
  Button,
  ButtonProps,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";

import { useDimensions } from "../globals/hooks";
import { generateOptionalPricingFields } from "./pricing/utils/optionalPricingFields";
import { PricingFieldsRendered } from "./pricing/utils";
import AirportDropOffIcon from "design-system/icons/info/AirportDropOffIcon";

type MoovsSwitchDropdownProps = {
  buttonLabel: string;
  fieldsRendered: PricingFieldsRendered;
  MuiButtonProps?: ButtonProps;
  isFullWidth?: boolean;
  isPricingLayout?: boolean;
  onDropdownOptionClick: (
    isChecked: boolean,
    fieldName: string,
    percentFieldName?: string
  ) => void;
  childSeatFieldNameOptions?: string[];
};

function MoovsSwitchDropdown(props: MoovsSwitchDropdownProps) {
  const {
    MuiButtonProps,
    buttonLabel,
    fieldsRendered,
    isFullWidth,
    onDropdownOptionClick,
    isPricingLayout,
    childSeatFieldNameOptions,
  } = props;

  const options = useMemo(
    () =>
      generateOptionalPricingFields({
        isForDropdownMenu: true,
        childSeatFieldNameOptions,
      }).map((opt) => ({
        ...opt,
        name: opt.fieldName || opt?.percentFieldName,
        fieldName: opt.fieldName,
        isChecked:
          fieldsRendered[opt.fieldName] ||
          fieldsRendered[opt?.percentFieldName],
        onOptionClick: onDropdownOptionClick,
        ...(opt?.percentFieldName && {
          percentFieldName: opt.percentFieldName,
        }),
      })),
    [fieldsRendered, onDropdownOptionClick, childSeatFieldNameOptions]
  );

  // hooks
  const buttonRef = useRef();
  const { offsetWidth: buttonWidth } = useDimensions(buttonRef.current);

  // state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // event handlers
  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box data-testid="moovs-switch-dropdown">
      <Button
        ref={buttonRef}
        color="primary"
        variant="outlined"
        onClick={handleMenuOpen}
        sx={{ marginTop: 1 }}
        {...MuiButtonProps}
        {...(!!isFullWidth && { fullWidth: true })}
      >
        {buttonLabel}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, i) => {
          const showAirportIcon =
            option.fieldName === "meetGreetAmt" && isPricingLayout;

          return (
            <MenuItem
              key={i}
              onClick={() =>
                option.onOptionClick(
                  !option.isChecked,
                  option.fieldName,
                  option?.percentFieldName || null
                )
              }
              data-testid={`checkbox-${option.name}`}
              style={
                !!isFullWidth ? { width: buttonWidth } : { paddingRight: 60 }
              }
            >
              <Box display="flex">
                <Switch
                  color="primary"
                  name={option.name}
                  checked={option.isChecked}
                />
                <Box display="flex" alignItems="center">
                  <Typography variant="body2">{option.label}</Typography>
                  {showAirportIcon && (
                    <Box ml={1} mt={0.5}>
                      <AirportDropOffIcon />
                    </Box>
                  )}
                </Box>
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}

export default MoovsSwitchDropdown;
