import React, { Dispatch, SetStateAction, useState } from "react";
import { useMutation } from "@apollo/client";
import filter from "lodash/filter";
import size from "lodash/size";

import { UserAccessPermission } from "types";
import {
  CREATE_USER_ACCESS_PERMISSION_MUTATION,
  REMOVE_USER_ACCESS_PERMISSION_MUTATION,
} from "globals/graphql";
import { useSnackbar } from "globals/hooks";

type UseAccessPermissionsChangeParams = {
  userId: string;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
  activePermissions: UserAccessPermission[];
  setActivePermissions: Dispatch<SetStateAction<UserAccessPermission[]>>;
};

export function useAccessPermissionsChange(
  params: UseAccessPermissionsChangeParams
) {
  const {
    userId,
    setSaveIndicatorState,
    activePermissions,
    setActivePermissions,
  } = params;

  // hooks
  const snackbar = useSnackbar();

  // state
  const [memberPermissionsHomeError, setMemberPermissionsHomeError] =
    useState("");

  // mutations
  const [createUserAccessPermission] = useMutation(
    CREATE_USER_ACCESS_PERMISSION_MUTATION,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError() {
        setSaveIndicatorState("error");
        snackbar.error("Error updating member");
      },
    }
  );

  const [removeUserAccessPermission] = useMutation(
    REMOVE_USER_ACCESS_PERMISSION_MUTATION,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError() {
        setSaveIndicatorState("error");
        snackbar.error("Error updating member");
      },
    }
  );

  const handleUserAccessPermissionsChange =
    (modifiedAccessPermission: UserAccessPermission) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!size(activePermissions)) return;

      setMemberPermissionsHomeError("");
      setSaveIndicatorState("loading");

      const { id, name, category, path, grantedByDefault } =
        modifiedAccessPermission;

      const variables = {
        input: {
          accessPermissionId: modifiedAccessPermission.id,
          userId: userId,
        },
      };

      const initialActivePermissions = activePermissions;

      const newActivePermissions = event.target.checked
        ? [...activePermissions, { id, name, category, path, grantedByDefault }]
        : activePermissions.filter(
            (permission: UserAccessPermission) => permission.id !== id
          );

      setActivePermissions(newActivePermissions);

      if (event.target.checked) {
        createUserAccessPermission({ variables });
      } else {
        // User must keep at least 1 home permission
        if (modifiedAccessPermission.category === "home") {
          const homePermissions = filter(
            initialActivePermissions,
            (permission) => permission.category === "home"
          );

          if (homePermissions.length <= 1) {
            setActivePermissions(initialActivePermissions);
            setMemberPermissionsHomeError(
              "Member must have access to at least 1 Home Page"
            );
            setSaveIndicatorState("error");
            return;
          }
        }

        removeUserAccessPermission({ variables });
      }
    };

  return {
    handleUserAccessPermissionsChange,
    memberPermissionsHomeError,
  };
}
