import React from "react";

import { Box, Card, Typography } from "@mui/material";

import { white } from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import MoovsAvatar from "design-system/components/MoovsAvatar";

type DriverPlatformReportContentProps = {
  operatorTotalTrips: number;
  drivers: {
    driver: {
      id: string;
      firstName: string;
      lastName: string;
      driverProfilePhoto?: string;
    };
    driverTrips: number;
  }[];
};

function DriverPlatformReportContent(props: DriverPlatformReportContentProps) {
  const { operatorTotalTrips, drivers } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Box
      display="flex"
      flexDirection={isMobileView ? "column" : "row"}
      justifyContent="space-between"
    >
      {/* operator's total trips for the period */}
      <Box
        sx={{
          backgroundColor: white,
          padding: "24px",
          marginBottom: "24px",
          height: "fit-content",
          ...(!isMobileView && { maxWidth: "33%", marginRight: "32px" }),
        }}
      >
        <Typography variant="body1">
          Total Trips Completed In Driver App
        </Typography>
        <Box mt={1}>
          <Typography variant="h1">{`${operatorTotalTrips} Trips`}</Typography>
        </Box>
      </Box>

      {/* List of drivers and their respective total trips */}
      {/* Header */}
      <Box sx={{ ...(!isMobileView && { minWidth: "65%" }) }}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mb={isMobileView ? 1.5 : 3}
        >
          <Typography variant="overline">Drivers</Typography>
          <Typography variant="overline">
            Trips Completed in Driver App
          </Typography>
        </Box>

        {/* Driver list */}
        <Box sx={{ maxHeight: "316px", overflow: "auto" }}>
          {drivers?.map((driverRecord) => {
            const { driver, driverTrips } = driverRecord;
            return (
              <Card
                variant="outlined"
                key={driver.id}
                sx={{
                  padding: "12px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    maxWidth: isMobileView ? "70%" : "80%",
                  }}
                >
                  <MoovsAvatar
                    alt="contact, person"
                    size="medium"
                    src={driver.driverProfilePhoto}
                    placeholder={[driver.firstName, driver.lastName]}
                  />
                  <Box
                    p={2}
                    sx={{
                      textOverflow: "ellipsis",
                      maxWidth: isMobileView ? "90%" : "95%",
                    }}
                  >
                    <Typography noWrap variant="subtitle2">
                      {`${driver.firstName} ${driver.lastName}`}
                    </Typography>
                  </Box>
                </Box>
                <Box pr={2}>
                  <Typography variant="subtitle1">{`${driverTrips} Trips`}</Typography>
                </Box>
              </Card>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default DriverPlatformReportContent;
