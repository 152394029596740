import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useAnalytics, useSnackbar } from "globals/hooks";
import { CREATE_OPERATOR_GETTING_STARTED_MUTATION } from "globals/graphql";
import { errorRed } from "design-system/colors";
import { GettingStartedGoal } from "types";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import GrowMyBusinessIcon from "design-system/icons/info/GrowMyBusinessIcon";
import { OperatorGettingStartedGoal } from "./components";
import AccessMoovsDriverAppIcon from "design-system/icons/info/AccessMoovsDriverApp";
import ExploreMyOwnIcon from "design-system/icons/info/ExploreOnMyOwnIcon";

type GettingStartedFieldsProps = {
  setActiveStep: React.Dispatch<React.SetStateAction<4>>;
};

function GettingStartedFields(props: GettingStartedFieldsProps) {
  const { setActiveStep } = props;

  // state
  const [gettingStartedGoal, setGettingStartedGoal] =
    useState<GettingStartedGoal>(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { GrowBusinessOnline, ExploreOnMyOwn, AccessDriverApp } =
    GettingStartedGoal;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // mutation
  const [createOperatorGettingStarted] = useMutation(
    CREATE_OPERATOR_GETTING_STARTED_MUTATION,
    {
      onCompleted: () => {
        const goalForTracking =
          gettingStartedGoal === GrowBusinessOnline
            ? "businessOnline"
            : gettingStartedGoal === ExploreOnMyOwn
            ? "exploreOnOwn"
            : "driverApp";

        track("onboarding_questionSubmitted", { answer: goalForTracking });

        setLoading(false);
        setActiveStep(4);
      },
      onError: (error) => {
        setLoading(false);

        const errorMessage = getErrorMessage(error) || error.message;

        snackbar.error(errorMessage);
      },
    }
  );

  // event handlers
  const handleOnSubmit = () => {
    if (!isFormValid()) return;

    setLoading(true);

    createOperatorGettingStarted({
      variables: {
        input: {
          goal: gettingStartedGoal,
        },
      },
    });
  };

  const handleClick = (goal: GettingStartedGoal) => {
    setGettingStartedGoal(goal);
    setErrorMessage("");
  };

  const isFormValid = () => {
    if (!gettingStartedGoal) {
      setErrorMessage("Please select one option");
      return false;
    }

    return true;
  };

  // effects
  useEffect(() => {
    track("onboarding_questionInitiated ");
  }, [track]);

  return (
    <>
      <Box mb={1}>
        <OperatorGettingStartedGoal
          onClick={handleClick}
          gettingStartedGoal={gettingStartedGoal}
          errorMessage={errorMessage}
          GoalIcon={GrowMyBusinessIcon}
          goalVariant={GrowBusinessOnline}
          title="Grow my business online"
          subtitle="Give my customers access to book transportation directly from my
        website"
        />
      </Box>
      <Box mb={1}>
        <OperatorGettingStartedGoal
          onClick={handleClick}
          gettingStartedGoal={gettingStartedGoal}
          errorMessage={errorMessage}
          GoalIcon={AccessMoovsDriverAppIcon}
          goalVariant={AccessDriverApp}
          title="Access the Moovs Driver App"
          subtitle="Get real-time tracking of my drivers to ensure efficient operations and improved customer service"
        />
      </Box>
      <Box mb={1}>
        <OperatorGettingStartedGoal
          onClick={handleClick}
          gettingStartedGoal={gettingStartedGoal}
          errorMessage={errorMessage}
          GoalIcon={ExploreMyOwnIcon}
          goalVariant={ExploreOnMyOwn}
          title="Explore on my own"
          subtitle="Setup my account to fit my business needs and create my first trip"
        />
      </Box>

      {errorMessage && (
        <Typography color={errorRed} variant="body1">
          {errorMessage}
        </Typography>
      )}

      <Box display="flex" flexDirection="column" alignItems="center" my={5}>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={loading}
          fullWidth
          onClick={handleOnSubmit}
        >
          Continue
        </LoadingButton>
      </Box>
    </>
  );
}

export default GettingStartedFields;
