import React, { useState } from "react";
import moment from "moment-timezone";

import { Box, Button, CircularProgress, IconButton } from "@mui/material";

import { DownloadIcon } from "design-system/icons";
import { grayMedium, moovsBlue } from "design-system/colors";
import { useAnalytics, useScreenSize, useSnackbar } from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { downloader } from "globals/utils/downloader";
import MoovsTooltip from "components/MoovsTooltip";

type DownloadButtonProps = {
  endpoint: string;
  buttonText: string;
  params?: {
    startDate: string | number;
    endDate: string | number;
    companyId?: string;
  };
  analyticsName?: string;
  disabled?: boolean;
};

function DownloadButton(props: DownloadButtonProps) {
  const { endpoint, buttonText, params, analyticsName, disabled } = props;

  // state
  const [isLoading, setIsLoading] = useState(false);

  // hooks
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();
  const snackbar = useSnackbar();

  // event handlers
  const handleDownloadReportClick = async () => {
    try {
      setIsLoading(true);
      await downloader(endpoint, params);
    } catch (error) {
      // Hacky way for js-file-downloader reject all promises with their own custom error, so we do not receive the error message from server.
      if (error.request.status === 503) {
        // product requested this to be green.
        snackbar.success(
          "Your report is processing. Please try again in a few minutes."
        );
      } else {
        const errorMessage = getErrorMessage(error) || error.message;
        snackbar.error(errorMessage);
      }
    }
    setIsLoading(false);
    if (analyticsName) track(analyticsName);
  };

  if (isMobileView)
    return isLoading ? (
      <CircularProgress size={25} />
    ) : (
      <IconButton onClick={handleDownloadReportClick} sx={{ mr: -1, mt: -1 }}>
        <DownloadIcon color={moovsBlue} />
      </IconButton>
    );

  // 6.01 to add some buffer for DST change which could potentially put it one hour over the limit
  const disableDownload =
    disabled ||
    (params &&
      moment(params.endDate).diff(moment(params.startDate), "months", true) >=
        6.01);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        minWidth: "180px",
        mt: -1.25,
        ml: 3,
      }}
    >
      {isLoading ? (
        <CircularProgress size={25} />
      ) : (
        <MoovsTooltip
          tooltipText={
            disabled
              ? "Can not download report at this time."
              : "Maximum date range for downloads is 6 months. Please narrow your search."
          }
          hidden={!disableDownload}
        >
          <Button
            disabled={disableDownload}
            onClick={handleDownloadReportClick}
            endIcon={
              <DownloadIcon color={disableDownload ? grayMedium : moovsBlue} />
            }
          >
            {buttonText}
          </Button>
        </MoovsTooltip>
      )}
    </Box>
  );
}

export default DownloadButton;
