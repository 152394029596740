import React from "react";

type ClipboardIconProps = {
  color?: string;
  size?: "small";
  viewBox?: string;
};

const ClipboardIcon = (props: ClipboardIconProps) => {
  const { color, size, viewBox } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox={viewBox || "0 0 16 16"}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5 2.3H11.75C12.0815 2.3 12.3995 2.43696 12.6339 2.68076C12.8683 2.92456 13 3.25522 13 3.6V12.7C13 13.0448 12.8683 13.3754 12.6339 13.6192C12.3995 13.863 12.0815 14 11.75 14H4.25C3.91848 14 3.60054 13.863 3.36612 13.6192C3.1317 13.3754 3 13.0448 3 12.7V3.6C3 3.25522 3.1317 2.92456 3.36612 2.68076C3.60054 2.43696 3.91848 2.3 4.25 2.3H5.5M6.125 1H9.875C10.2202 1 10.5 1.29101 10.5 1.65V2.95C10.5 3.30898 10.2202 3.6 9.875 3.6H6.125C5.77982 3.6 5.5 3.30898 5.5 2.95V1.65C5.5 1.29101 5.77982 1 6.125 1Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox={viewBox || "0 0 24 24"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H8M9 2H15C15.5523 2 16 2.44772 16 3V5C16 5.55228 15.5523 6 15 6H9C8.44772 6 8 5.55228 8 5V3C8 2.44772 8.44772 2 9 2Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClipboardIcon;
