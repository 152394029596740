import { useState } from "react";

function useFormStepProgress(stepsLength: number) {
  const [currentStep, setCurrentStep] = useState(0);

  const goForward = () => {
    setCurrentStep(currentStep + 1);
  };

  const goBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const startAtFirstStep = () => {
    setCurrentStep(0);
  };

  const isFirst = currentStep === 0;
  const isLast = currentStep === stepsLength - 1;

  return { currentStep, goForward, goBack, startAtFirstStep, isFirst, isLast };
}

export default useFormStepProgress;
