import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import { Box } from "@mui/material";

import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";
import { PricelessBookingTarget } from "types";
import { PricelessBookingTargetDropdown } from "./components";
import {
  LOAD_COMPANIES_NAME_AND_ID_QUERY,
  LOAD_CONTACTS_QUERY,
} from "globals/graphql";
import {
  CompanyType,
  ContactType,
  CreateVehicle,
  FieldNameEnum,
  SelectionType,
} from "components/vehicles/CreateVehicleDrawer/types";
import { useScreenSize } from "globals/hooks";
import { toggleButtons } from "components/vehicles/constant";

type CreatePricelessBookingTargetSectionProps = {
  setVehicle: React.Dispatch<React.SetStateAction<CreateVehicle>>;
  vehicle: CreateVehicle;
};

function CreatePricelessBookingTargetSection(
  props: CreatePricelessBookingTargetSectionProps
) {
  const { setVehicle, vehicle } = props;

  const companySelectionFromDuplicateVehicle: SelectionType[] =
    vehicle.settings.pricelessBookingCompanies?.map((company: CompanyType) => ({
      name: company.name,
      id: company.id,
    })) || [];

  const contactSelectionFromDuplicateVehicle: SelectionType[] =
    vehicle.settings.pricelessBookingContacts?.map((contact: ContactType) => ({
      name: `${contact.firstName} ${contact.lastName}`,
      id: contact.id,
    })) || [];

  // state
  const [companySelection, setCompanySelection] = useState<SelectionType[]>(
    companySelectionFromDuplicateVehicle
  );
  const [contactSelection, setContactSelection] = useState<SelectionType[]>(
    contactSelectionFromDuplicateVehicle
  );

  // hooks
  const { isMobileView } = useScreenSize();

  // queries
  const { data: companiesData } = useQuery(LOAD_COMPANIES_NAME_AND_ID_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const { data: contactsData } = useQuery(LOAD_CONTACTS_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const companyOptions: SelectionType[] =
    companiesData?.companies.map((company: CompanyType) => ({
      name: company.name,
      id: company.id,
    })) || [];

  const contactOptions: SelectionType[] =
    contactsData?.loadContacts.edges.map(
      ({ node: contact }: { node: ContactType }) => ({
        name: `${contact.firstName} ${contact.lastName}`,
        id: contact.id,
      })
    ) || [];

  // event handlers
  const handleToggleButtonChange = (
    _: React.MouseEvent<HTMLElement>,
    value: PricelessBookingTarget
  ): void => {
    setVehicle({
      ...vehicle,
      settings: {
        ...vehicle.settings,
        pricelessBookingTarget: value,
      },
    });
  };

  const handleDropdownChange = (
    value: SelectionType[],
    fieldName: FieldNameEnum
  ): void => {
    if (fieldName === FieldNameEnum.company) {
      setCompanySelection(value);
    } else {
      setContactSelection(value);
    }

    setVehicle({
      ...vehicle,
      settings: {
        ...vehicle.settings,
        [fieldName === FieldNameEnum.company
          ? "pricelessBookingCompanyIds"
          : "pricelessBookingContactIds"]: value.map((item) => item.id),
      },
    });
  };

  const handleDeleteDropdown = (
    item: SelectionType,
    fieldName: FieldNameEnum
  ): void => {
    const selection =
      fieldName === FieldNameEnum.company ? companySelection : contactSelection;

    const filtered: SelectionType[] = selection.filter(
      (selected) => selected.id !== item.id
    );

    if (fieldName === FieldNameEnum.company) {
      setCompanySelection(filtered);
    } else {
      setContactSelection(filtered);
    }

    setVehicle({
      ...vehicle,
      settings: {
        ...vehicle.settings,
        [fieldName === FieldNameEnum.company
          ? "pricelessBookingCompanyIds"
          : "pricelessBookingContactIds"]: [item.id],
      },
    });
  };

  return (
    <>
      {vehicle.settings.pricelessBookingEnabled && (
        <>
          <Box my={1}>
            <MoovsToggleButtonGroup
              onChange={handleToggleButtonChange}
              options={toggleButtons}
              value={vehicle.settings.pricelessBookingTarget}
              fullWidth
              keepInline
            />
          </Box>
          <Box>
            {vehicle.settings.pricelessBookingTarget ===
              PricelessBookingTarget.Specific && (
              <Box
                display="flex"
                gap={isMobileView ? 0 : 1}
                flexDirection={isMobileView ? "column" : "row"}
              >
                <Box width="100%">
                  <PricelessBookingTargetDropdown
                    options={companyOptions}
                    fieldName={FieldNameEnum.company}
                    dropdownTitle="Companies"
                    placeholder="No Company Selected"
                    selection={companySelection}
                    handleDropdownChange={handleDropdownChange}
                    handleDeleteDropdown={handleDeleteDropdown}
                  />
                </Box>
                <Box width="100%">
                  <PricelessBookingTargetDropdown
                    options={contactOptions}
                    fieldName={FieldNameEnum.contact}
                    dropdownTitle="Contacts"
                    placeholder="No Contact Selected"
                    selection={contactSelection}
                    handleDropdownChange={handleDropdownChange}
                    handleDeleteDropdown={handleDeleteDropdown}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  );
}

export default CreatePricelessBookingTargetSection;
