import { GridColumns } from "@mui/x-data-grid-pro";

import { grayDark } from "design-system/colors";

import BookingContactColumn from "./BookingContactColumn";
import {
  DateColumn,
  PassengerColumn,
  StatusColumn,
  TotalAmountColumn,
  TripConfNumberColumn,
} from "components/contacts/people/UpdateContactDrawer/components/ContactTripsGrid/components";

const defaults = {
  sortable: false,
  hide: false,
  color: grayDark,
  width: 130,
};

const companyTripsGridColumns: GridColumns = [
  {
    ...defaults,
    field: "date",
    headerName: "Date",
    renderCell: DateColumn,
    align: "center",
  },
  {
    ...defaults,
    field: "conf.No.",
    headerName: "Conf. No.",
    renderCell: TripConfNumberColumn,
  },
  {
    ...defaults,
    field: "booking-contact",
    headerName: "Booking Contact",
    renderCell: BookingContactColumn,
    width: 150,
  },
  {
    ...defaults,
    field: "passenger",
    headerName: "Passenger",
    renderCell: PassengerColumn,
  },
  {
    ...defaults,
    field: "totalAmount",
    headerName: "Total Amount",
    type: "number",
    renderCell: TotalAmountColumn,
  },
  {
    ...defaults,
    field: "status",
    headerName: "Status",
    renderCell: StatusColumn,
  },
];

export default companyTripsGridColumns;
