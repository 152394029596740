import React from "react";

type AddTripIconProps = {
  color?: string;
  size?: "small";
};

const AddTripIcon = (props: AddTripIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 6.21053L12 1L6.78947 12L5.63158 7.36842L1 6.21053Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 10V12.5M12.5 12.5V15M12.5 12.5H10M12.5 12.5H15"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 6.21053L12 1L6.78947 12L5.63158 7.36842L1 6.21053Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 10V12.5M12.5 12.5V15M12.5 12.5H10M12.5 12.5H15"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddTripIcon;
