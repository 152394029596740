import React, { useState, ChangeEvent, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";

import { auth } from "globals/utils/firebaseApp";
import { JOIN_TEAM_MUTATION } from "globals/graphql";
import { useSnackbar } from "globals/hooks/useSnackbar";
import GoogleAuthButton from "components/buttons/GoogleAuthButton";
import {
  CheckIcon,
  ViewIcon,
  WarningIcon,
  ViewOffIcon,
} from "design-system/icons";
import {
  errorRed,
  granite,
  grayDark,
  moovsBlue,
  successGreen,
} from "design-system/colors";
import { useAuth, useScreenSize } from "globals/hooks";
import { LoadingButton } from "@mui/lab";
import { useLoadPendingUser } from "globals/hooks/useLoadPendingUser";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

axios.defaults.withCredentials = true;

const styles = {
  buttonProgress: {
    top: "50%",
    left: "50%",
    marginTop: "-12px",
    marginLeft: "-12px",
  },
  googleBox: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "32px",
    marginBottom: "23px",
    borderBottom: "1px solid #EDEDED",
  },
};

function JoinTeamPage() {
  // local state
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [matchingPasswords, setMatchingPasswords] = useState(false);
  const [credentials, setCredentials] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordErrors, setPasswordErrors] = useState("");

  // hooks
  const snackbar = useSnackbar();
  const { isMobileView } = useScreenSize();
  let history = useHistory();
  const { onLogin } = useAuth();
  const { userId } = useParams<{ userId: string }>();
  const user = useLoadPendingUser({ userId });

  // effects
  useEffect(() => {
    if (credentials.password === credentials.confirmPassword) {
      setMatchingPasswords(true);
    } else {
      setMatchingPasswords(false);
    }
  }, [credentials.password, credentials.confirmPassword]);

  // mutations
  const [joinTeam] = useMutation(JOIN_TEAM_MUTATION, {
    onError: (error) => {
      const errorMessage = getErrorMessage(error);

      snackbar.error(errorMessage, {
        linkLabel: "Log In",
        linkColor: moovsBlue,
        link: "/login",
      });

      setLoading(false);

      setPasswordErrors("Error");
    },
  });

  const validatePasswords = () => {
    if (
      credentials.password !== credentials.confirmPassword &&
      credentials.password.length < 6
    ) {
      setPasswordErrors("Passwords must match");

      const passwordErrorMessage =
        credentials.password !== credentials.confirmPassword
          ? "The passwords you entered didn't match. Please check your entries and try again."
          : "  The password you entered is has fewer then 6 characters. Please choose a longer password.";

      snackbar.error(passwordErrorMessage);
    }

    handleJoinTeam();
    setPasswordErrors("");
  };

  const handleJoinTeam = async () => {
    setLoading(true);

    try {
      const email = user.email;

      const { errors } = await joinTeam({
        variables: {
          input: {
            userId,
          },
        },
      });

      if (errors) return;

      const firebaseResponse = await createUserWithEmailAndPassword(
        auth,
        email,
        credentials.password
      );
      const token = await firebaseResponse.user.getIdToken();

      await onLogin(token);

      setLoading(false);

      history.replace("/");
    } catch (error) {
      setLoading(false);

      snackbar.error(error.message);

      setPasswordErrors("Error");
    }
  };

  const handleJoinTeamWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();

      const firebaseResponse = await signInWithPopup(auth, provider);

      if (firebaseResponse.user.email !== user.email) {
        throw new Error("Google email must match the email invited.");
      }

      const { errors } = await joinTeam({
        variables: {
          input: {
            userId,
          },
        },
      });

      if (errors) return;

      const token = await firebaseResponse.user.getIdToken();

      await onLogin(token);

      history.replace("/");
    } catch (error) {
      const errorMessage = error.response ? error.response.data : error.message;

      snackbar.error(errorMessage);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setPasswordErrors("");
    setCredentials((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    event.key === "Enter" && validatePasswords();
  };

  if (!user) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width="100%"
        height="100%"
      >
        <CircularProgress size={40} thickness={2} />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>Join Team | Moovs</title>
      </Helmet>
      <Box sx={styles.googleBox}>
        <GoogleAuthButton isSignup onClick={handleJoinTeamWithGoogle} />
      </Box>
      <Box display="flex" justifyContent="center" mb="23px">
        <Typography
          align="center"
          variant="overline"
          style={{ color: grayDark }}
        >
          OR YOU CAN ALSO
        </Typography>
      </Box>

      <TextField
        fullWidth
        disabled
        variant="outlined"
        value={user.email}
        name="email"
        onChange={handleChange}
      />

      <Box mt={1}>
        <TextField
          type={showPassword ? "text" : "password"}
          error={!!passwordErrors}
          fullWidth
          variant="outlined"
          value={credentials.password}
          label="Choose Password"
          name="password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  tabIndex={-1}
                  onClick={() => setShowPassword(!showPassword)}
                  size="large"
                >
                  {showPassword ? (
                    <ViewOffIcon size="small" />
                  ) : (
                    <ViewIcon size="small" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
        />
      </Box>
      <Box mt={1}>
        <TextField
          type={showConfirmPassword ? "text" : "password"}
          error={!!passwordErrors}
          fullWidth
          variant="outlined"
          value={credentials.confirmPassword}
          label="Confirm Password"
          name="confirmPassword"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {passwordErrors ? (
                  <WarningIcon size="small" color={errorRed} />
                ) : (
                  matchingPasswords &&
                  !!credentials.confirmPassword && (
                    <CheckIcon size="small" color={successGreen} />
                  )
                )}
                <IconButton
                  tabIndex={-1}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  size="large"
                >
                  {showConfirmPassword ? (
                    <ViewOffIcon size="small" />
                  ) : (
                    <ViewIcon size="small" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
        />
      </Box>
      <Box mt={1}>
        <Box mb={3} display="flex" flexDirection="column">
          <Typography
            color="textSecondary"
            style={{
              fontSize: 12,
              color: passwordErrors ? errorRed : granite,
            }}
            variant="caption"
          >
            Password must be at least 6 characters
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
          <LoadingButton
            data-testid="button"
            variant="contained"
            color="primary"
            loading={loading}
            fullWidth={isMobileView}
            onClick={validatePasswords}
            sx={{ minWidth: 160 }}
          >
            Join Team
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
}

export default JoinTeamPage;
