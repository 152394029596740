import React, { ReactNode, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  Box,
  CircularProgress,
  Container,
  Link,
  Typography,
} from "@mui/material";

import MoovsLogoWhiteOnBlue from "../../images/icons/MoovsLogoWhiteOnBlue.svg";
import { granite, grayDark, moovsBlueSelected, white } from "../../colors";
import { useAuth } from "../../../globals/hooks";

type AuthPageLayoutProps = {
  children: ReactNode;
};

function AuthLayout(props: AuthPageLayoutProps) {
  const { children } = props;

  // hooks
  const { authStage } = useAuth();
  const history = useHistory();
  const location = useLocation();

  // derived state
  const isSignup = location.pathname.includes("sign-up");
  const isLogin = location.pathname.includes("login");
  const isNetflixLogin = location.pathname.includes("netflix/login");
  const isJoinTeam = location.pathname.includes("join-team");
  const isForgotPassword = location.pathname.includes("forgot-password");

  const headerText = useMemo(() => {
    if (isLogin || isNetflixLogin)
      return {
        title: "Log In to Moovs",
        subTitle: "Welcome back, it's great to see you!",
      };
    if (isJoinTeam)
      return {
        title: "Join Your Team On Moovs",
        subTitle: "The new home for all your business.",
      };
    if (isForgotPassword)
      return {
        title: "Forgot Password?",
        subTitle:
          "Don’t worry! Fill in your email and we’ll send you a link to reset your password.",
      };
  }, [isForgotPassword, isLogin, isJoinTeam, isNetflixLogin]);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          height: "100vh",
          width: "100%",
          overflow: "auto",
          backgroundColor: isSignup ? moovsBlueSelected : white,
        }}
      >
        {authStage === "pending" ? (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={80} thickness={4} />
          </Box>
        ) : isSignup ? (
          <Box width="100%">{children}</Box>
        ) : (
          <Container maxWidth="sm">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Box mb={1}>
                  <Box
                    component="img"
                    sx={{ logo: { height: 55 } }}
                    src={MoovsLogoWhiteOnBlue}
                    alt="MoovsLogoWhiteOnBlue"
                  />
                </Box>

                <Box display="flex" justifyContent="center" mb={1}>
                  <Typography variant="h1" align="center">
                    {headerText?.title}
                  </Typography>
                </Box>
                <Box mb={4}>
                  <Typography
                    variant="body1"
                    sx={{ color: grayDark, fontWeight: 400, maxWidth: 326 }}
                    align="center"
                  >
                    {headerText?.subTitle}
                  </Typography>
                </Box>
                {(isLogin || isJoinTeam) && !isNetflixLogin && (
                  <Typography
                    variant="body1"
                    sx={{ color: granite, fontWeight: 400, mb: 4 }}
                    align="center"
                  >
                    {isJoinTeam
                      ? "Already have an account? "
                      : "Don't have an account yet? "}
                    <Link
                      component="button"
                      underline="none"
                      variant="body1"
                      color="primary"
                      sx={{ fontWeight: 500 }}
                      onClick={() => {
                        history.push(isJoinTeam ? "/login" : "/sign-up");
                      }}
                    >
                      {isJoinTeam ? "Log In" : "Sign up"}
                    </Link>{" "}
                    instead.
                  </Typography>
                )}
              </Box>
              <Box width="100%">{children}</Box>
            </Box>
          </Container>
        )}
      </Box>
    </div>
  );
}

export default AuthLayout;
