import { useQuery } from "@apollo/client";
import { UserAccessPermission } from "types";

import { LOAD_ALL_ACCESS_PERMISSIONS_QUERY } from "globals/graphql";

export function useLoadAllAccessPermissions() {
  // queries
  const { data, loading: allAccessPermissionsLoading } = useQuery<{
    loadAllAccessPermissions: UserAccessPermission[];
  }>(LOAD_ALL_ACCESS_PERMISSIONS_QUERY, {
    fetchPolicy: "network-only",
  });

  const allAccessPermissions = data?.loadAllAccessPermissions;

  return {
    allAccessPermissions,
    allAccessPermissionsLoading,
  };
}
