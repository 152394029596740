import React, { ChangeEvent } from "react";
import { DeepPartial } from "ts-essentials";

import { Box, TextField } from "@mui/material";

import MoovsDialog from "../../MoovsDialog";
import { Trip } from "../../../types";
import InternationalPhoneInput from "../../inputs/InternationalPhoneInput";
import { formatPhoneNumber } from "../../../globals/utils/phoneNumberFormatter/phoneNumberFormatter";
import { initialTemporaryPassenger } from "./CreateRequest/components/TripDetailsCreateBlock";
import { useScreenSize } from "globals/hooks";

type CreateTemporaryPassengerDialogProps = {
  open: boolean;
  onClose: () => void;
  trip: DeepPartial<Trip>;
  tripIndex: number;
  updateTrip: any;
  temporaryPassengerValues: {
    name: string;
    mobilePhone: string;
    mobilePhoneInternational: string;
    phoneCountryCode: string;
    phoneCountryDialCode: string;
    phoneCountryName: string;
    phoneCountryFormat: string;
  };
  setTemporaryPassengerValues: (val) => void;
  temporaryPassengerErrors: { name: string };
  setTemporaryPassengerErrors: (err) => void;
};

function CreateTemporaryPassengerDialog(
  props: CreateTemporaryPassengerDialogProps
) {
  const {
    open,
    onClose,
    trip,
    tripIndex,
    updateTrip,
    temporaryPassengerValues,
    setTemporaryPassengerValues,
    temporaryPassengerErrors,
    setTemporaryPassengerErrors,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  const handleTemporaryPassengerInfoChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    const name = event.target.name;

    setTemporaryPassengerValues({
      ...temporaryPassengerValues,
      [name]: value,
    });
    name === "name" && setTemporaryPassengerErrors({ name: "" });
  };

  const handlePhoneNumberInput = (value, country) => {
    setTemporaryPassengerValues({
      ...temporaryPassengerValues,
      mobilePhone: value,
      mobilePhoneInternational: value,
      phoneCountryCode: country.countryCode,
      phoneCountryDialCode: country.dialCode,
      phoneCountryName: country.name,
      phoneCountryFormat: country.format,
    });
  };

  const handleSaveTemporaryPassengerClick = () => {
    const { formatted, international } =
      formatPhoneNumber(
        temporaryPassengerValues.mobilePhoneInternational,
        temporaryPassengerValues.phoneCountryCode,
        temporaryPassengerValues.phoneCountryDialCode
      ) || {};

    if (!temporaryPassengerValues.name) {
      setTemporaryPassengerErrors({ name: "Please enter a name" });
      return;
    }

    updateTrip(
      {
        ...trip,
        temporaryPassenger: {
          ...temporaryPassengerValues,
          mobilePhone: formatted,
          mobilePhoneInternational: international,
        },
      },
      tripIndex
    );
    onClose();
  };

  const handleCloseClick = () => {
    setTemporaryPassengerValues(
      trip.temporaryPassenger || initialTemporaryPassenger
    );
    onClose();
  };

  return (
    <MoovsDialog
      open={open}
      onClose={handleCloseClick}
      dialogTitle="Temporary Passenger"
      acceptButtonText="Replace Contact"
      onAccept={handleSaveTemporaryPassengerClick}
      size="sm"
      titleToolTip="Passenger info will not be saved in contact list"
      childrenSx={{ overflow: "visible" }}
    >
      <Box my={6}>
        <Box mb={2}>
          <TextField
            required
            fullWidth
            variant="outlined"
            label="Passenger Name"
            name="name"
            value={temporaryPassengerValues.name || ""}
            onChange={handleTemporaryPassengerInfoChange}
            error={!!temporaryPassengerErrors.name}
            helperText={temporaryPassengerErrors.name}
          />
        </Box>
        <InternationalPhoneInput
          value={temporaryPassengerValues.mobilePhoneInternational}
          onChange={handlePhoneNumberInput}
          phoneCountryDialCode={temporaryPassengerValues?.phoneCountryDialCode}
          dropdownBelowInput={!!isMobileView}
          dropdownWidth={isMobileView ? "600%" : "1035%"}
        />
      </Box>
    </MoovsDialog>
  );
}

export default CreateTemporaryPassengerDialog;
