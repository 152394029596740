import { useQuery } from "@apollo/client";
import map from "lodash/map";

import { LOAD_TRIPS_FOR_BOOKING_CONTACT_QUERY } from "globals/graphql";

type useLoadTripsForContactProps = {
  contactId: string;
};

export function useLoadTripsForContact(props: useLoadTripsForContactProps) {
  const { contactId } = props;

  // queries
  const {
    data,
    loading: tripsLoading,
    fetchMore: fetchMoreTrips,
  } = useQuery(LOAD_TRIPS_FOR_BOOKING_CONTACT_QUERY, {
    variables: {
      contactId,
    },
    skip: !contactId,
    fetchPolicy: "network-only",
  });

  const trips = map(
    data?.loadTripsForBookingContact?.edges,
    (trip) => trip?.node
  );

  return {
    trips,
    tripsLoading,
    fetchMoreTrips,
    hasNextPage: data?.loadTripsForBookingContact.pageInfo.hasNextPage,
    cursor: data?.loadTripsForBookingContact.pageInfo.endCursor,
  };
}
