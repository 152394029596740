import React from "react";
import { Link } from "react-router-dom";

import { Box, Typography, Hidden } from "@mui/material";

import { MembersListItemDesktop, MembersListItemMobile } from "./components";
import { User } from "types";

type MembersListProps = {
  users: User[];
  sxStyles: any;
};

function MembersList(props: MembersListProps) {
  const { users, sxStyles } = props;

  return (
    <>
      {/* Large Screen View */}
      <Hidden mdDown>
        <Box sx={sxStyles}>
          <Box display="flex" flexDirection="row" flex="1" px={3} mb={1}>
            <Box display="flex" flex="2">
              <Typography variant="overline">name</Typography>
            </Box>
            <Box display="flex" flex="2">
              <Typography variant="overline">email</Typography>
            </Box>
            <Box display="flex" flex="1.5">
              <Typography variant="overline">phone</Typography>
            </Box>
            <Box display="flex" flex="1">
              <Typography variant="overline">role</Typography>
            </Box>
          </Box>
          {users.map((user) => {
            return (
              <Box key={user.id} mb={1}>
                <Link to={`/settings/members/update/${user.id}`}>
                  <MembersListItemDesktop user={user} />
                </Link>
              </Box>
            );
          })}
        </Box>
      </Hidden>
      {/* Small screen view */}
      <Hidden mdUp>
        <Box px={2}>
          {users.map((user: User) => (
            <Link key={user.id} to={`/settings/members/update/${user.id}`}>
              <MembersListItemMobile user={user} />
            </Link>
          ))}
        </Box>
      </Hidden>
    </>
  );
}

export default MembersList;
