/**
 * @file graphql/AccessPermission.ts
 * queries/mutations for AccessPermission.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_ALL_ACCESS_PERMISSIONS_QUERY = gql`
  query LoadAllAccessPermissions {
    loadAllAccessPermissions {
      id
      path
      category
      name
      grantedByDefault
    }
  }
`;
