import React, { Dispatch, SetStateAction } from "react";
import uniqBy from "lodash/uniqBy";

import { Box, Button, Typography } from "@mui/material";

import AddContactToOrderDialog from "components/contacts/people/AddContactToOrderDialog";
import EditBookingContactDialog from "components/contacts/bookingContact/components/EditBookingContactDialog";
import { useScreenSize } from "globals/hooks";
import { Contact } from "types";
import { useUpdateBookingContactClick } from "components/contacts/bookingContact/hooks/useUpdateBookingContactClick";
import ContactInfoCard from "components/globals/ContactInfoCard";

type AddLinkedPassengersToCreateContactProps = {
  linkedPassengers: Contact[];
  setLinkedPassengers: Dispatch<SetStateAction<Contact[]>>;
};

export default function AddLinkedPassengersToCreateContact(
  props: AddLinkedPassengersToCreateContactProps
) {
  const { linkedPassengers, setLinkedPassengers } = props;
  //hooks
  const { isMobileView } = useScreenSize();
  const {
    editBookingContactDialogOpen,
    setEditBookingContactDialogOpen,
    addContactToOrderDialogOpen,
    setAddContactToOrderDialogOpen,
    carryOverSearch,
    setCarryOverSearch,
    bookingContact: passengerContact,
    setBookingContact: setPassengerContact,
  } = useUpdateBookingContactClick();

  // state

  const hasLinkedPassengers = linkedPassengers.length > 0;
  const linkedPassengerIds = linkedPassengers.map((passenger) => passenger.id);

  // event handlers

  const handleAddLinkedPassengerToNewContact = () => {
    setLinkedPassengers(
      uniqBy([...linkedPassengers, passengerContact as Contact], "id")
    );
    setPassengerContact(null);
    setEditBookingContactDialogOpen(false);
  };

  const handleDeletePassengerFromNewContact = (passengerId: string) => {
    setLinkedPassengers((prev) => prev.filter((p) => p.id !== passengerId));
  };

  const handleOpenEditBookingContactDialog = () => {
    setEditBookingContactDialogOpen(true);
  };

  return (
    <Box mt={4}>
      <Box>
        <Typography variant="h3">Linked Passengers</Typography>
      </Box>
      {hasLinkedPassengers && (
        <Box my={3} display="flex" rowGap={1} flexDirection="column">
          {linkedPassengers.map((passenger) => (
            <ContactInfoCard
              key={passenger.id}
              contact={passenger}
              isDeleteButtonDisabled={false}
              onDelete={() => handleDeletePassengerFromNewContact(passenger.id)}
              deleteButtonTitle="Remove linked passenger"
            />
          ))}
        </Box>
      )}
      <Box mt={3}>
        <Button
          color="primary"
          variant="outlined"
          disableElevation
          onClick={handleOpenEditBookingContactDialog}
          fullWidth={isMobileView ? true : false}
        >
          Link Passenger
        </Button>
      </Box>
      <EditBookingContactDialog
        title="Link Passenger"
        open={editBookingContactDialogOpen}
        onClose={() => setEditBookingContactDialogOpen(false)}
        setAddContactToOrderDialogOpen={setAddContactToOrderDialogOpen}
        bookingContact={passengerContact}
        setBookingContact={setPassengerContact}
        setCarryOverSearch={setCarryOverSearch}
        onSave={handleAddLinkedPassengerToNewContact}
        filter={linkedPassengerIds}
      />
      <AddContactToOrderDialog
        open={addContactToOrderDialogOpen}
        onClose={() => setAddContactToOrderDialogOpen(false)}
        onContactUpdate={setPassengerContact}
        carryOverSearch={carryOverSearch}
      />
    </Box>
  );
}
