import React from "react";

import { Avatar } from "@mui/material";
import { grayDark, grayLight, grayMedium } from "../colors";

type MoovsAvatarProps = {
  alt?: string;
  size?: "small" | "medium" | "large" | "xl" | "xxl";
  src?: string;
  placeholder?: string | string[];
  shape?: "circle" | "rectangle";
  backgroundColor?: string;
  color?: string;
};

function MoovsAvatar(props: MoovsAvatarProps) {
  const {
    alt,
    src,
    placeholder,
    size = "medium",
    shape = "circle",
    backgroundColor = grayLight,
    color = grayDark,
  } = props;

  const avatarSizeStyles = (
    size: "small" | "medium" | "large" | "xl" | "xxl"
  ) => {
    const widthMultiplier = shape === "rectangle" ? 2.5 : 1;

    switch (size) {
      case "small":
        return {
          width: 32 * widthMultiplier,
          height: 32,
          fontSize: 13,
        };
      case "medium":
        return {
          width: 40 * widthMultiplier,
          height: 40,
          fontSize: 14,
        };
      case "large":
        return {
          width: 64 * widthMultiplier,
          height: 64,
          fontSize: 24,
        };
      case "xl":
        return {
          width: 72 * widthMultiplier,
          height: 72,
          fontSize: 32,
        };
      case "xxl":
        return {
          width: 124 * widthMultiplier,
          height: 124,
          fontSize: 32,
        };
      default:
        return {};
    }
  };

  let initials = placeholder ? placeholder[0] : "";
  if (Array.isArray(placeholder)) {
    initials = `${placeholder[0][0] || ""}${
      !placeholder[1] ? "" : placeholder[1][0]
    }`;
  }

  return (
    <Avatar
      alt={alt}
      src={src}
      variant={shape === "rectangle" ? "square" : "circular"}
      sx={{
        backgroundColor,
        color,
        letterSpacing: "0.5px",
        fontWeight: 500,
        ...avatarSizeStyles(size),
        "&.MuiAvatar-root": {
          ...(shape === "rectangle" && {
            borderRadius: "4px",
          }),
          ...(!!src && {
            border: 1,
            borderColor: grayMedium,
          }),
        },
      }}
    >
      {initials && initials.toUpperCase()}
    </Avatar>
  );
}

export default MoovsAvatar;
