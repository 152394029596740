import React from "react";

type PassengerCountIconProps = {
  color?: string;
  size?: "small";
};

const PassengerCountIcon = (props: PassengerCountIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 13V11.7778C13 11.1295 12.7366 10.5077 12.2678 10.0493C11.7989 9.59087 11.163 9.33333 10.5 9.33333H5.5C4.83696 9.33333 4.20107 9.59087 3.73223 10.0493C3.26339 10.5077 3 11.1295 3 11.7778V13M10.5 4.44444C10.5 5.79447 9.38071 6.88889 8 6.88889C6.61929 6.88889 5.5 5.79447 5.5 4.44444C5.5 3.09441 6.61929 2 8 2C9.38071 2 10.5 3.09441 10.5 4.44444Z"
          stroke="#999999"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 21V19.3333C14 18.4493 13.6576 17.6014 13.0481 16.9763C12.4386 16.3512 11.612 16 10.75 16H4.25C3.38805 16 2.5614 16.3512 1.9519 16.9763C1.34241 17.6014 1 18.4493 1 19.3333V21M11 9.5C11 11.433 9.433 13 7.5 13C5.567 13 4 11.433 4 9.5C4 7.567 5.567 6 7.5 6C9.433 6 11 7.567 11 9.5Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 7.56063C23 9.7565 19.5787 14.6732 18.7027 15.8954C18.6795 15.9278 18.6491 15.9542 18.6139 15.9724C18.5787 15.9905 18.5398 16 18.5003 16C18.4609 16 18.4219 15.9905 18.3868 15.9724C18.3516 15.9542 18.3212 15.9278 18.298 15.8954C17.422 14.6732 14 9.7565 14 7.56063C14 6.35108 14.4741 5.19106 15.318 4.33578C16.1619 3.48049 17.3065 3 18.5 3C19.6935 3 20.8381 3.48049 21.682 4.33578C22.5259 5.19106 23 6.35108 23 7.56063Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 7.5C20 8.32843 19.3284 9 18.5 9C17.6716 9 17 8.32843 17 7.5C17 6.67157 17.6716 6 18.5 6C19.3284 6 20 6.67157 20 7.5Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PassengerCountIcon;
