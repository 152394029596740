/**
 * @file useBaseRateData.tsx
 * Provides handlers that when called will update base rate values (flat rate, hourly rate, and hourly rate hours).
 * - This includes a handler to be invoked when activating BRA.
 * Keeps in sync base rate values whenever one changes.
 *
 * Notes:
 * - When activating BRA, the hourly rate hours changes back to total duration hours.
 *   If there is not total duration, then it gets set to 1.
 *
 * useBaseRateData Output
 * @typedef {Object} useBaseRateDataOutput
 * @property {BaseRateVariantEnum} baseRateVariant - Returns the BaseRateVariant currently selected by the user
 * @property {number} flatRate - The flat rate value.
 * @property {number} hourlyBaseRate - The rate of hourly base rate.
 * @property {number} hourlyBaseRateHours - The hours for hourly base raet.
 * @property {number} onToggleBaseRateVariant - Handler that updates hours and base rate variant when toggled.
 * @property {number} onFlatRateInput - Handler that should be invoked when user manually updates flat rate.
 * @property {number} onHourlyBaseRateInput - Handler that should be invoked when user manually updates hourly rate.
 * @property {number} onHourlyBaseRateHoursInput -  Handler that should be invoked when user manually updates hourly rate hours.
 * @property {number} onActivateBRA - Handler that should be invoked when user activates BRA.
 */
import { useEffect, useState, Dispatch, SetStateAction } from "react";
import isNil from "lodash/isNil";

import { convertMinutesToNearestQuarterHour } from "globals/utils/time";
import { BaseRateAutomation, BaseRateVariantEnum } from "types";

type UseBaseRateDataParams = {
  onBaseRateAmtChange: (baseRateAmt: number) => void;
  onHoursChange: (hours: number) => void;
  totalDurationMinutes: number;
  baseRateAmt: number;
  hourlyBaseRateHours: number;
  setIsBaseRateAutomationClicked?: Dispatch<SetStateAction<boolean>>;
  setExisitngAutomatedBaseRate?: Dispatch<SetStateAction<BaseRateAutomation>>;
};

const { Flat, Hourly } = BaseRateVariantEnum;

function useBaseRateData(params: UseBaseRateDataParams) {
  const {
    totalDurationMinutes,
    onBaseRateAmtChange,
    onHoursChange,
    setIsBaseRateAutomationClicked,
    setExisitngAutomatedBaseRate,
  } = params;

  // state
  const [baseRateVariant, setBaseRateVariant] = useState<BaseRateVariantEnum>(
    params.hourlyBaseRateHours ? Hourly : Flat
  );
  const [flatRate, setFlatRate] = useState<number>(params.baseRateAmt);
  const [hourlyBaseRateHours, setHourlyBaseRateHours] = useState<number>(() =>
    getInitialHourlyBaseRateHours({
      hourlyBaseRateHours: params.hourlyBaseRateHours,
      totalDurationMinutes,
      baseRateVariant,
    })
  );
  const [hourlyBaseRate, setHourlyBaseRate] = useState<number>(
    getInitialHourlyBaseRate({
      baseRateAmt: params.baseRateAmt,
      hourlyBaseRateHours,
    })
  );

  const totalDurationHours =
    convertMinutesToNearestQuarterHour(totalDurationMinutes) || 1;

  // event handlers
  const handleToggle = (baseRateVariant: BaseRateVariantEnum) => {
    if (baseRateVariant === Hourly || baseRateVariant === Flat) {
      setBaseRateVariant(baseRateVariant);
      onHoursChange(
        baseRateVariant === Hourly ? hourlyBaseRateHours || 1 : null
      );
    }
  };

  /**
   * Input handlers
   * These should only be invoked onInput.
   * This prevents programmatic changes to the TextFields from triggering.
   *
   * Ex:
   * <TextField ... onInput={handleFlatRateInput} />
   */
  const handleFlatRateInput = (flatRate: number) => {
    setFlatRate(normalizeValue(flatRate));
    setHourlyBaseRate(flatRate / hourlyBaseRateHours);
  };

  const handleHourlyBaseRateInput = (hourlyRate: number) => {
    const inputHourlyRate = normalizeValue(hourlyRate);
    setHourlyBaseRate(inputHourlyRate);
    setFlatRate(inputHourlyRate * hourlyBaseRateHours);
  };

  const handleHourlyBaseRateHoursInput = (hours: number) => {
    setHourlyBaseRateHours(hours);
    setFlatRate(hours * hourlyBaseRate);
  };

  const handleActivateBRA = (automatedBaseRate: BaseRateAutomation) => {
    if (baseRateVariant === Hourly || !!totalDurationMinutes) {
      // used totalDurationMinutes to indicate one-way vs hourly since totalDurationHours defaults 1 on null
      setHourlyBaseRate(automatedBaseRate?.total / totalDurationHours);
      setHourlyBaseRateHours(totalDurationHours);
    }
    setFlatRate(automatedBaseRate?.total);
    if (setIsBaseRateAutomationClicked) {
      setIsBaseRateAutomationClicked(true);
    }
    if (setExisitngAutomatedBaseRate) {
      setExisitngAutomatedBaseRate(automatedBaseRate);
    }
  };

  // effects
  // whenever the base (flat) rate changes, trigger the passed in callback with that base rate.
  useEffect(() => {
    onBaseRateAmtChange(flatRate);
    /**
     * onBaseRateAmtChange should be in the dependency array.
     * However, in CreateRequestDrawer, a new function is created on each render
     * This is not ideal behavior, but memoizing that function would require a larger refactor
     * that isn't worth the effort right now.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatRate]);

  // whenever the hours changes, trigger the passed in callback with the hours.
  useEffect(() => {
    onHoursChange(hourlyBaseRateHours);
    /**
     * Refer to comment in flatRate useEffect
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourlyBaseRateHours]);

  // update hourly base rate whenever hours change
  // adjust base rate variant according to whether hours value exists
  useEffect(() => {
    if (!isNil(params.hourlyBaseRateHours)) {
      setHourlyBaseRateHours(params.hourlyBaseRateHours);
      setBaseRateVariant(Hourly);
    } else {
      setBaseRateVariant(Flat);
    }
  }, [params.hourlyBaseRateHours]);

  useEffect(() => {
    setFlatRate(params.baseRateAmt);
  }, [params.baseRateAmt]);

  // respond to a change in hourlyBaseRateHours or baseRateAmt params
  useEffect(() => {
    // retain hourly base rate if baseRateAmt changes to 0
    if (params.baseRateAmt !== 0) {
      // if hours is null or 0, we set the hourly rate to be the baseRateAmt
      setHourlyBaseRate(params.baseRateAmt / (params.hourlyBaseRateHours || 1));
    }
  }, [params.hourlyBaseRateHours, params.baseRateAmt]);

  return {
    baseRateVariant,
    flatRate,
    hourlyBaseRate,
    hourlyBaseRateHours,
    onToggleBaseRateVariant: handleToggle,
    onFlatRateInput: handleFlatRateInput,
    onHourlyBaseRateInput: handleHourlyBaseRateInput,
    onHourlyBaseRateHoursInput: handleHourlyBaseRateHoursInput,
    onActivateBRA: handleActivateBRA,
  };
}

export default useBaseRateData;

const normalizeValue = (value: number | string | null) => {
  if (!value) {
    return null;
  } else {
    return Number(value);
  }
};

const getInitialHourlyBaseRate = (params: {
  baseRateAmt: number;
  hourlyBaseRateHours: number;
}) => {
  const { baseRateAmt, hourlyBaseRateHours } = params;
  return hourlyBaseRateHours ? baseRateAmt / hourlyBaseRateHours : 0;
};

const getInitialHourlyBaseRateHours = (params: {
  hourlyBaseRateHours: number;
  totalDurationMinutes: number;
  baseRateVariant: BaseRateVariantEnum;
}) => {
  const { hourlyBaseRateHours, totalDurationMinutes, baseRateVariant } = params;
  const nearestQuarterHour =
    convertMinutesToNearestQuarterHour(totalDurationMinutes);

  return baseRateVariant === Hourly
    ? hourlyBaseRateHours || nearestQuarterHour || 1
    : null;
};
