import React, { useState } from "react";
import first from "lodash/first";

import { successGreen, tintGreen } from "../../../../../design-system/colors";
import MoovsBanner from "components/MoovsBanner";
import { CloseOutDisabledEnum, FarmRelationshipEnum, Trip } from "types";
import CloseTripDialog from "pages/requests/RequestOverview/ReservationOverview/CloseTripDialog/CloseTripDialog";
import { isAutomationPayoutUpdated } from "components/driverPayout/update/utils";
import { useAnalytics } from "globals/hooks";

// constants
const { AwaitingTripEnd, AwaitingFarmeeAcceptTrip, AwaitingFarmeeCloseout } =
  CloseOutDisabledEnum;

type TripCompleteBannerProps = {
  trip: Trip;
  orderNumber: string;
  closeOutDisabledVariant: CloseOutDisabledEnum;
  onShowConfetti: () => void;
};

function TripCompleteBanner(props: TripCompleteBannerProps) {
  const { trip, orderNumber, closeOutDisabledVariant, onShowConfetti } = props;

  // hooks
  const { track } = useAnalytics();

  // state
  const [closeTripDialogOpen, setCloseTripDialogOpen] = useState(false);

  // derived state
  const actionButtonDisabledTooltipText = {
    [AwaitingTripEnd]: "Trip must be in the past",
    [AwaitingFarmeeAcceptTrip]: "Trip must be accepted",
    [AwaitingFarmeeCloseout]: "Trip must be closed out by Farmee",
  }[closeOutDisabledVariant];

  const route = first(trip.routes);
  const isPayoutsNull =
    route.driverPayoutFlatRate === null &&
    !route.driverPayoutHours &&
    route.driverPayoutRatePerHour === null &&
    route.driverPayoutGratuity === null;

  const isPayoutUpdated = isAutomationPayoutUpdated(
    {
      driverPayoutFlatRate: route.driverPayoutFlatRate,
      driverPayoutHours: route.driverPayoutHours,
      driverPayoutRatePerHour: route.driverPayoutRatePerHour,
      driverPayoutGratuity: route.driverPayoutGratuity,
    },
    {
      automatedDriverPayoutFlatRate: route.automatedDriverPayoutFlatRate,
      automatedDriverPayoutHours: route.automatedDriverPayoutHours,
      automatedDriverPayoutRatePerHour: route.automatedDriverPayoutRatePerHour,
      automatedDriverPayoutGratuity: route.automatedDriverPayoutGratuity,
      driverGratuityAmt:
        route.pricing?.driverGratuityAmt ||
        route.farmeePricing?.driverGratuityAmt,
    }
  );
  const isFarmor = route.farmRelationship === FarmRelationshipEnum.Farmor;
  const canApplyDriverPayoutAutomation =
    !isFarmor &&
    route.hasAutomatedDriverPayout &&
    (isPayoutsNull || !isPayoutUpdated);

  // event handlers
  const handleSuccessfullyClosedTrip = () => {
    onShowConfetti();
    setCloseTripDialogOpen(false);
  };

  const handleCloseTripClick = () => {
    track("reservation_tripCloseOutInitiated");
    setCloseTripDialogOpen(true);
  };
  return (
    <>
      <MoovsBanner
        bannerVariant="compact"
        bannerText="Trip is ready to be closed"
        actionButtonText="Close Trip"
        onActionButtonClick={handleCloseTripClick}
        bannerBgColor={tintGreen}
        bannerTextColor={successGreen}
        actionButtonDisabledTooltipText={actionButtonDisabledTooltipText}
        showAutomationIcon={canApplyDriverPayoutAutomation}
      />
      <CloseTripDialog
        onCloseTripDialogClose={() => setCloseTripDialogOpen(false)}
        closeTripDialogOpen={closeTripDialogOpen}
        trip={trip}
        orderNumber={orderNumber}
        onSuccessfullyClosedTrip={handleSuccessfullyClosedTrip}
        isPayoutAutomated={canApplyDriverPayoutAutomation}
      />
    </>
  );
}

export default TripCompleteBanner;
