import { useMutation } from "@apollo/client";

import { UPDATE_REQUEST_MUTATION } from "globals/graphql";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { RequestStage } from "types";

function useBookingContactMutation() {
  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // mutation
  const [updateRequest] = useMutation(UPDATE_REQUEST_MUTATION, {
    onCompleted(data) {
      const request = data.updateRequest.request;
      const isQuote = request.stage === RequestStage.Quote;
      snackbar.success("Successfully updated booking contact", {
        link: isQuote
          ? `/quotes/${request.id}/`
          : `/reservations/${request.id}/`,
        linkLabel: isQuote ? "View Quote" : "View Reservation",
        onLinkClick: () => {
          track("snackbar_reservationViewed");
        },
      });
    },
    onError: () => snackbar.error("Error updating request booking contact"),
  });

  return { updateRequest };
}

export { useBookingContactMutation };
