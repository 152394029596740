import React, { useState } from "react";
import map from "lodash/map";
import capitalize from "lodash/capitalize";

import { Box, Card, Typography } from "@mui/material";

import { useAnalytics, useScreenSize, useSnackbar } from "globals/hooks";
import ChipsList from "./components/ChipsList";
import MoovsTooltipBottomListDrawer from "../../../../../components/MoovsTooltip/MoovsTooltipBottomListDrawer";
import { DynamicPricingRule } from "types";
import EditActivateDesktop from "./components/EditActivate.desktop";
import EditActivateMobile from "./components/EditActivate.mobile";
import { useMutation } from "@apollo/client";
import { UPDATE_DYNAMIC_PRICING_ACTIVE_STATUS } from "globals/graphql";
import EditDynamicPricingDialog from "../EditDynamicPricingDialog/EditDynamicPricingDialog";

type DynamicPricingItemProps = {
  rule: DynamicPricingRule;
};

function DynamicPricingItem(props: DynamicPricingItemProps) {
  const { rule } = props;

  // derived state
  const vehicleNames = rule.vehicles
    ?.filter((vehicle) => vehicle.enableBaseRateAutomation && vehicle.available)
    .map((vehicle) => vehicle.name);
  const weekdayNames = map(rule.daysOfWeek, capitalize);

  // hooks
  const { isSmallTabletView } = useScreenSize();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // state
  const [vehiclesDrawerOpen, setVehiclesDrawerOpen] = useState(false);
  const [weekdaysDrawerOpen, setWeekdaysDrawerOpen] = useState(false);
  const [editDynamicPricingDialogOpen, setEditDynamicPricingDialogOpen] =
    useState(false);

  // mutation
  const [updateDynamicPricingActiveStatus] = useMutation(
    UPDATE_DYNAMIC_PRICING_ACTIVE_STATUS,
    {
      onCompleted(data) {
        const { isActive } = data.updateDynamicPricingRuleActiveStatus.rule;

        if (!isActive) {
          snackbar.success("Successfully deactivated rule");
        } else {
          snackbar.success("Successfully activated rule");
        }
      },
      onError() {
        snackbar.error("Could not change rule status");
      },
    }
  );

  // event handler
  const handleActivateSwitch = (e) => {
    updateDynamicPricingActiveStatus({
      variables: {
        input: {
          ruleId: rule.id,
          active: !rule.isActive,
        },
      },
    });
  };

  const handleEditBaseRateClick = () => {
    setEditDynamicPricingDialogOpen(true);
    track("dynamicPricing_edited");
  };

  const handleSetVehiclesDrawerOpen = () => setVehiclesDrawerOpen(true);
  const handleSetWeekdaysDrawerOpen = () => setWeekdaysDrawerOpen(true);
  const handleEditDialogClose = () => setEditDynamicPricingDialogOpen(false);

  return (
    <>
      <Box marginBottom={2} marginTop={2}>
        <Card variant="outlined">
          <Box
            display="flex"
            height="100%"
            padding={1.5}
            width="100%"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="column">
              {isSmallTabletView ? (
                <EditActivateMobile
                  onEditClick={handleEditBaseRateClick}
                  subtitle={rule.name}
                  activationStatus={rule.isActive}
                  onHandleActivateSwitch={handleActivateSwitch}
                />
              ) : (
                <Typography variant="subtitle2">{rule.name}</Typography>
              )}
              <ChipsList
                onSetVehiclesDrawerOpen={handleSetVehiclesDrawerOpen}
                onSetWeekdaysDrawerOpen={handleSetWeekdaysDrawerOpen}
                rule={rule}
              />
            </Box>
            {!isSmallTabletView ? (
              <EditActivateDesktop
                onEditClick={handleEditBaseRateClick}
                activationStatus={rule.isActive}
                onHandleActivateSwitch={handleActivateSwitch}
              />
            ) : null}
          </Box>
        </Card>
      </Box>
      {/* Edit Dialog */}
      <EditDynamicPricingDialog
        open={editDynamicPricingDialogOpen}
        onClose={handleEditDialogClose}
        rule={rule}
      />
      {/* Vehicle Drawer */}
      <MoovsTooltipBottomListDrawer
        onSetDrawerOpen={setVehiclesDrawerOpen}
        drawerOpen={vehiclesDrawerOpen}
        list={vehicleNames}
        title="VEHICLES"
      />
      {/* Weekday Drawer */}
      <MoovsTooltipBottomListDrawer
        onSetDrawerOpen={setWeekdaysDrawerOpen}
        drawerOpen={weekdaysDrawerOpen}
        list={weekdayNames}
        title="WEEKDAYS"
      />
    </>
  );
}

export default DynamicPricingItem;
