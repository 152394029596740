import React, { useState } from "react";
import moment from "moment-timezone";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from "@mui/material";

import theme from "theme";
import { grayLight } from "design-system/colors";
import { ChevronDownIcon, ClickedIcon, EmailIcon } from "design-system/icons";
import { EmailEvent, EmailLog, RequestStage } from "types";
import { mapEmailTypeToIconAndName } from "./helpers";
import ToAndCCLabel from "../ToAndCCLabel";
import RecipientsBottomDrawer from "./RecipientsBottomDrawer";

const styles = {
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
};

type EmailLogItemProps = {
  emailLog: EmailLog;
  removeRecipientEmail: boolean;
  requestStage: string;
  orderNumber: string;
};

function EmailLogItem(props: EmailLogItemProps) {
  const {
    emailLog: {
      createdAt,
      emailType,
      emailEvents,
      confirmationNumber,
      toRecipients,
      ccRecipients,
    },
    removeRecipientEmail,
    requestStage,
    orderNumber,
  } = props;

  // state
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [recipientsDrawerOpen, setRecipientsDrawerOpen] = useState(false);

  // derived state
  const isReservation = requestStage === RequestStage.Reservation;
  const { icon, name } = mapEmailTypeToIconAndName({
    emailType,
    confirmationNumber,
    isReservation,
    orderNumber,
  });

  // filters out emailEvents that are not click or open
  // this may be unnecessary if we unsubscribe to the other
  // types on sendgrid
  const filteredEmailEvents = emailEvents.filter(
    ({ eventType }) => eventType === "click" || eventType === "open"
  );

  // event handlers
  const handleAccordionClick = () => setAccordionOpen((prev) => !prev);
  const handleSetRecipientsDrawerOpen = () => setRecipientsDrawerOpen(true);

  // helpers
  const getEmailEventData = (eventType: string) => {
    switch (eventType) {
      case "click":
        return {
          icon: <ClickedIcon />,
          action: "clicked link",
        };
      case "open":
        return {
          icon: <EmailIcon size="small" />,
          action: "opened email",
        };
      default:
        return {
          icon: <EmailIcon size="small" />,
          action: "performed unspecified action",
        };
    }
  };

  // shared components for non/accordion component
  const cardEmailLabel = (props: { isAccordion?: boolean }) => {
    const { isAccordion } = props;
    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        m={!isAccordion && 2}
      >
        {icon}
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          whiteSpace="pre"
          alignItems="center"
          ml="18px"
          my={1}
        >
          <Typography variant="body2">{`${name} Email sent `}</Typography>

          {!removeRecipientEmail && (
            <>
              <Typography variant="body2">to </Typography>
              <ToAndCCLabel
                toRecipients={toRecipients}
                ccRecipients={ccRecipients}
                onRecipientsDrawerOpen={handleSetRecipientsDrawerOpen}
              />
            </>
          )}

          <Typography variant="body2">on </Typography>

          <Typography variant="body2" style={{ fontWeight: 500 }}>
            {moment(createdAt).format("L LT")}
          </Typography>
        </Box>
      </Box>
    );
  };

  // if no emailEvents, return non-accordion component.
  if (!filteredEmailEvents.length) {
    return (
      <>
        <Paper variant="outlined">{cardEmailLabel({})}</Paper>

        <RecipientsBottomDrawer
          drawerOpen={recipientsDrawerOpen}
          onSetDrawerOpen={setRecipientsDrawerOpen}
          title="Recipients"
          toList={toRecipients}
          ccList={ccRecipients}
        />
      </>
    );
  }

  // if emailEvents, return accordion component
  return (
    <>
      <Accordion variant="outlined" expanded={accordionOpen}>
        <AccordionSummary
          expandIcon={<ChevronDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            "& .MuiAccordionSummary-content": styles.content,
          }}
          onClick={handleAccordionClick}
        >
          {cardEmailLabel({ isAccordion: true })}
        </AccordionSummary>
        <AccordionDetails
          style={{
            borderTop: `1px solid ${grayLight}`,
            margin: "0 16px",
            padding: 0,
          }}
        >
          <Box display="flex" flex="1" flexDirection="column" mt={2} mb={1}>
            {filteredEmailEvents.map(
              ({ id, eventType, triggeredAt, recipient }: EmailEvent) => {
                const emailEventData = getEmailEventData(eventType);
                return (
                  <Box
                    key={id}
                    minHeight="18px"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    flex="1"
                    mb={1}
                  >
                    <Box display="flex" flexDirection="row" pl={0.5}>
                      <Box mt={0.5}>{emailEventData.icon}</Box>

                      <Box
                        display="flex"
                        whiteSpace="pre"
                        flexWrap="wrap"
                        alignItems="center"
                        ml={2}
                      >
                        <Typography variant="h6" style={{ fontWeight: 600 }}>
                          {recipient}{" "}
                        </Typography>
                        <Typography style={{ fontSize: "12px" }}>
                          {emailEventData.action} on{" "}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          {moment(triggeredAt).format("L LT")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              }
            )}
          </Box>
        </AccordionDetails>
      </Accordion>

      <RecipientsBottomDrawer
        drawerOpen={recipientsDrawerOpen}
        onSetDrawerOpen={setRecipientsDrawerOpen}
        title="Recipients"
        toList={toRecipients}
        ccList={ccRecipients}
      />
    </>
  );
}

export default EmailLogItem;
