import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/client";

import { Box, Typography, Divider, Button, Hidden } from "@mui/material";

import DriversList from "../../../components/settings/drivers/DriversList";
import GQLQueryStatusIndicator from "../../../components/GQLQueryStatusIndicator";
import DriversMobileListItem from "../../../components/settings/drivers/DriversMobileListItem";
import EntityHeader from "components/common/EntityHeader";
import UpgradePlanDialog from "components/UpgradePlanDialog";
import { PlanVariant } from "types";
import { LOAD_OPERATOR_DRIVERS_QUERY } from "../../../globals/graphql";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import { useAnalytics, useOperator } from "../../../globals/hooks";
import { containerStyles, headerStyles, tableStyles } from "../utils/styles";

function DriversSettingsPage() {
  // constants
  const MAX_FREE_PLAN_DRIVERS_COUNT = 10;

  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const { track } = useAnalytics();
  const operator = useOperator();

  // state
  const [upgradePlanDialogOpen, setUpgradePlanDialogOpen] = useState(false);

  // query
  const { data, error, loading, refetch } = useQuery(
    LOAD_OPERATOR_DRIVERS_QUERY,
    {
      variables: {
        includeExternalOperators: true,
      },
      onError: (error) => {
        snackbar.error(error.message);
      },
    }
  );

  if (!data || loading || error) {
    return (
      <GQLQueryStatusIndicator
        name="Drivers"
        error={error}
        data={data}
        loading={loading}
        refetch={refetch}
      />
    );
  }

  // event handlers
  const handleDriverListItemClick = (driverId: string) => {
    history.push(`/settings/drivers/update/${driverId}`);
  };

  const handleCreateDriverClickWithLimitReached = () => {
    track("drivers_createInitiated", {
      limitRestriction: "limit reached",
    });
    setUpgradePlanDialogOpen(true);
  };

  const handleUpgradePlanClick = () => {
    track("driver_upgradePlan");
    history.push("/settings/billing/plans");
  };

  // free plan can only create 10 drivers
  const freePlanAndDriverLimitReached =
    operator?.plan === PlanVariant.Free && data.drivers.length >= MAX_FREE_PLAN_DRIVERS_COUNT;

  return (
    <>
      <Helmet>
        <title>Drivers | Moovs</title>
      </Helmet>
      <Box sx={containerStyles}>
        {data.drivers.length === 0 && (
          <Box sx={headerStyles}>
            <Typography variant="h2">Drivers</Typography>
          </Box>
        )}
        <Box>
          {data.drivers.length !== 0 && (
            <>
              {freePlanAndDriverLimitReached ? (
                <>
                  <EntityHeader
                    title="Drivers"
                    entityName="Driver"
                    titleVariant="h2"
                    createButtonMode="button"
                    onCreateButtonClick={
                      handleCreateDriverClickWithLimitReached
                    }
                  />
                  <UpgradePlanDialog
                    open={upgradePlanDialogOpen}
                    onUpgrade={handleUpgradePlanClick}
                    onClose={() => setUpgradePlanDialogOpen(false)}
                    body={
                      <Typography variant="body2">
                        You must upgrade your plan in order to{" "}
                        <strong>create more than {MAX_FREE_PLAN_DRIVERS_COUNT} drivers</strong>.
                      </Typography>
                    }
                  />
                </>
              ) : (
                <EntityHeader
                  title="Drivers"
                  entityName="Driver"
                  titleVariant="h2"
                  createEntityRoute={"/settings/drivers/create"}
                  createButtonMode="link"
                  onCreateButtonClick={() =>
                    track("drivers_createInitiated", {
                      limitRestriction: "limit not reached",
                    })
                  }
                />
              )}
            </>
          )}
        </Box>

        <Hidden mdDown>
          <Box px={3}>
            <Divider />
          </Box>
        </Hidden>
        <Hidden mdDown>
          {/* Large screen view */}
          {data.drivers.length !== 0 && (
            <Box sx={tableStyles}>
              <DriversList
                drivers={data.drivers}
                onDriverListItemClick={handleDriverListItemClick}
              />
            </Box>
          )}
        </Hidden>
        {/* Small screen view */}
        <Hidden mdUp>
          {data.drivers.map((driver) => (
            <Box mx={2} m={1} key={driver.id}>
              <DriversMobileListItem
                driver={driver}
                onDriverListItemClick={handleDriverListItemClick}
              />
            </Box>
          ))}
        </Hidden>
        {data.drivers.length === 0 && (
          <Box
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            display="flex"
            flex="1"
            flexDirection="column"
          >
            <Box mt={1} mb={3}>
              <Typography variant="h5">No Drivers Added Yet.</Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                to="/settings/drivers/create"
                component={Link}
                onClick={() =>
                  track("drivers_createInitiated", {
                    limitRestriction: "limit not reached",
                  })
                }
              >
                Create Driver
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}

export default DriversSettingsPage;
