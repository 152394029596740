import React, { ReactNode } from "react";

import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import { errorRed, grayLight, moovsBlue } from "design-system/colors";
import { InfoIcon } from "design-system/icons";
import { useScreenSize } from "globals/hooks";
import { WorkflowSelectionsEnum } from "../schemaValidation";

type WorkflowOptionProps = {
  image: string;
  header: string;
  body: ReactNode;
  onClick: (event) => void;
  workflowKey: WorkflowSelectionsEnum;
  error: boolean;
  workflowOutline: WorkflowSelectionsEnum;
  tooltipText?: string;
};

const mobileStyles = {
  width: "100%",
  cursor: "pointer",
  marginBottom: "10px",
};

const desktopStyles = {
  height: "285px",
  width: "31%",
  cursor: "pointer",
  "&:hover": {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
  },
};

function WorkflowOption(props: WorkflowOptionProps) {
  const {
    image,
    header,
    body,
    onClick,
    workflowKey,
    error,
    workflowOutline,
    tooltipText,
  } = props;

  const { isSmallTabletView } = useScreenSize();

  return (
    <Box
      onClick={onClick}
      data-workflow-key={workflowKey}
      sx={isSmallTabletView ? mobileStyles : desktopStyles}
    >
      <Card
        variant="outlined"
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          borderColor:
            workflowOutline === workflowKey
              ? moovsBlue
              : error
              ? errorRed
              : grayLight,
        }}
      >
        <CardContent>
          <Box p={isSmallTabletView ? 0.5 : 1}>
            <img src={image} alt="promo-code-type-option"></img>
          </Box>
          <Box
            p={isSmallTabletView ? 0.5 : 1}
            display="flex"
            alignItems="center"
          >
            <Typography variant="h4">{header}</Typography>
            {tooltipText && (
              <Tooltip
                enterDelay={200}
                title={
                  <Box sx={{ textAlign: "center", width: "200px" }}>
                    {tooltipText}
                  </Box>
                }
                placement="top"
              >
                <IconButton
                  aria-label="info"
                  size="medium"
                  sx={{ ml: "8px", p: 0 }}
                >
                  <InfoIcon size="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Box p={isSmallTabletView ? 0.5 : 1} minHeight="76px">
            {body}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default WorkflowOption;
