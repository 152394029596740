import React, { Dispatch, SetStateAction } from "react";

import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";
import { DriverPayoutToggleVariant } from "../utils/types";
import { getDriverPayoutButtons } from "../utils/getDriverPayoutButtons";
import {
  TripLocationsVariant,
  UpdateDriverInput,
  TripCategory,
  CreateDriverInput,
} from "types";

type DriverPayoutRateToggleProps = {
  toggleVariant: DriverPayoutToggleVariant;
  onSetToggleVariant: (variant) => void;
  tripType: TripCategory;
  setDriver: Dispatch<SetStateAction<CreateDriverInput | UpdateDriverInput>>;
  driver: CreateDriverInput | UpdateDriverInput;
};

function DriverPayoutRateToggle(props: DriverPayoutRateToggleProps) {
  const { toggleVariant, onSetToggleVariant, tripType, setDriver, driver } =
    props;

  // derived state
  const toggleButtons = getDriverPayoutButtons(tripType);

  // event handler
  const handleOnChange = (
    e: React.MouseEvent<HTMLElement>,
    variant: DriverPayoutToggleVariant
  ) => {
    onSetToggleVariant(variant || toggleVariant);

    // clear other values and set location variant to default when toggling to new option
    if (tripType === TripCategory.Hourly) {
      setDriver({
        ...driver,
        settings: {
          ...driver.settings,
          hourlyTripBaseRatePct: null,
          hourlyTripHourlyRate: null,
          hourlyTripLocationsVariant: TripLocationsVariant.LocationDropOff,
        },
      });
    }

    if (tripType === TripCategory.OneWay) {
      setDriver({
        ...driver,
        settings: {
          ...driver.settings,
          oneWayTripHourlyRate: null,
          oneWayTripMinimumHours: null,
          oneWayTripBaseRatePct: null,
          oneWayTripFlatRate: null,
          oneWayTripLocationsVariant: TripLocationsVariant.LocationDropOff,
        },
      });
    }
  };

  return (
    <MoovsToggleButtonGroup
      onChange={handleOnChange}
      options={toggleButtons}
      value={toggleVariant}
      sx={{
        padding: "10px!important",
        width: "100%!important",
      }}
    />
  );
}

export default DriverPayoutRateToggle;
