import {
  aqua,
  black,
  granite,
  grayLight,
  tintAqua,
  white,
} from "design-system/colors";
import { Trip } from "types";

type GuestType = "BOOKING CONTACT" | "PASSENGER" | null | "DRIVER";

export function getGuestChipStyle(guestType: GuestType) {
  if (guestType === "DRIVER") {
    return {
      backgroundColor: tintAqua,
      color: aqua,
    };
  }

  if (guestType === "BOOKING CONTACT") {
    return {
      backgroundColor: granite,
      color: white,
    };
  }

  if (guestType === "PASSENGER") {
    return {
      backgroundColor: grayLight,
      color: black,
    };
  }

  return {
    backgroundColor: grayLight,
    color: black,
  };
}

export function getPassengerNameFromTrip(trip: Trip) {
  const firstName =
    trip.tempPassenger?.name?.split(" ")[0] || trip.contact.firstName;
  const lastName =
    trip.tempPassenger?.name?.split(" ")[1] || trip.contact.lastName;
  return { firstName, lastName };
}
