import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import isEqual from "lodash/isEqual";

import { Box, Divider, Typography } from "@mui/material";

import {
  BaseRateAutomation,
  Billing,
  UpdateFarmedRoutePricingInput,
  UpdateRoutePricingInput,
  VehicleChildSeat,
} from "../../types";
import LegacyTransactionsBlock from "../LegacyTransactionsBlock";
import MoovsDialog from "../MoovsDialog";
import MoovsSelectDropdown from "./MoovsSelectDropdown";
import { PricingDialogPricingData, PricingKeys } from "./utils";
import MoovsPricingForm from "./MoovsPricingForm";
import ConfirmSwitchPricingDialog from "./ConfirmSwitchPricingDialog";

// types
export type MoovsPricingDialogProps = {
  open: boolean;
  pricings: PricingDialogPricingData[];
  onClose: () => void;
  onAccept: (
    pricing:
      | Omit<UpdateRoutePricingInput, "id">
      | Omit<UpdateFarmedRoutePricingInput, "routeId">,
    tripIndex: number,
    closeDialogOnComplete?: boolean
  ) => void;
  currentTripIndex?: number;
  isUpdating?: boolean;
  sendPricingAsDollarAmt?: boolean; // if true, percent values are converted to dollar when saving
  legacyBillings?: Billing[];
  setIsBaseRateAutomationClicked?: Dispatch<SetStateAction<boolean>>;
  setExisitngAutomatedBaseRate?: Dispatch<SetStateAction<BaseRateAutomation>>;
  isFarmedRoute?: boolean;
  hidePromoCodes?: boolean;
  childSeatOptions?: VehicleChildSeat[];
};

function MoovsPricingDialog(props: MoovsPricingDialogProps) {
  const {
    open,
    currentTripIndex,
    onClose,
    pricings,
    legacyBillings = [],
    onAccept,
    isUpdating,
    setIsBaseRateAutomationClicked,
    setExisitngAutomatedBaseRate,
    isFarmedRoute,
    hidePromoCodes,
    childSeatOptions,
  } = props;

  // state
  // used to render the confirm dialog - gets set to null when we want the dialog to be closed.
  const [newSelectedTripIndex, setNewSelectedTripIndex] = useState<
    number | null
  >(null);
  const [activeTripIndex, setActiveTripIndex] = useState(currentTripIndex || 0);

  const activePricing = useMemo(
    () =>
      ({
        ...pricings[activeTripIndex],
        ...(pricings[activeTripIndex] && {
          driverGratuityPercent:
            pricings[activeTripIndex].driverGratuityPercent !== null
              ? pricings[activeTripIndex].driverGratuityPercent * 100
              : null,
          promoDiscountPercent:
            pricings[activeTripIndex].promoDiscountPercent !== null
              ? pricings[activeTripIndex].promoDiscountPercent * 100
              : null,
          taxPercent:
            pricings[activeTripIndex].taxPercent !== null
              ? pricings[activeTripIndex].taxPercent * 100
              : null,
          otherPercent:
            pricings[activeTripIndex].otherPercent !== null
              ? pricings[activeTripIndex].otherPercent * 100
              : null,
          other2Percent:
            pricings[activeTripIndex].other2Percent !== null
              ? pricings[activeTripIndex].other2Percent * 100
              : null,
          other3Percent:
            pricings[activeTripIndex].other3Percent !== null
              ? pricings[activeTripIndex].other3Percent * 100
              : null,
          promoCodePercent:
            pricings[activeTripIndex].promoCodePercent !== null
              ? pricings[activeTripIndex].promoCodePercent * 100
              : null,
          // need to get seat amt per unit
          rearFacingSeatAmt: pricings[activeTripIndex].rearFacingSeatQuantity
            ? pricings[activeTripIndex].rearFacingSeatAmt /
              pricings[activeTripIndex].rearFacingSeatQuantity
            : null,
          forwardFacingSeatAmt: pricings[activeTripIndex]
            .forwardFacingSeatQuantity
            ? pricings[activeTripIndex].forwardFacingSeatAmt /
              pricings[activeTripIndex].forwardFacingSeatQuantity
            : null,
          boosterSeatAmt: pricings[activeTripIndex].boosterSeatQuantity
            ? pricings[activeTripIndex].boosterSeatAmt /
              pricings[activeTripIndex].boosterSeatQuantity
            : null,
        }),
      } || ({} as PricingDialogPricingData)),
    [pricings, activeTripIndex]
  );

  const [pricing, setPricing] =
    useState<PricingDialogPricingData>(activePricing);

  // event handlers
  const handlePricingUpdate = (pricingKeys: PricingKeys) => {
    const { fieldName, fieldAmt, percentFieldName, percentFieldValue } =
      pricingKeys;

    const fieldsToUpdate = {
      ...(fieldName && { [fieldName]: fieldAmt }),
      ...(percentFieldName && { [percentFieldName]: percentFieldValue }),
    };
    setPricing((pricing) => ({ ...pricing, ...fieldsToUpdate }));
  };

  const handleAcceptPricing = (closeDialogOnComplete: boolean) => {
    // When sendPricingAsDollarAmt is true, all percentage fields need to be changed to their percentages.
    // ex: if base rate is 200 and driverGratuityAmt is 10
    // - if NOT driverGratuityisPct, then driverGratuityAmt is 10
    // - if driverGratuityisPct, then driverGratuityAmt is (10 / 100) * 200 = 20
    // TODO: add some tests to validate the above.

    // remove fields not used by pricing
    const onAcceptPricing:
      | Omit<UpdateRoutePricingInput, "id">
      | Omit<UpdateFarmedRoutePricingInput, "routeId"> = {
      baseRateAmt: pricing.baseRateAmt,
      driverGratuityAmt: pricing.driverGratuityAmt,
      driverGratuityPercent:
        pricing.driverGratuityPercent !== null
          ? pricing.driverGratuityPercent
          : null,
      hourlyBaseRateHours: pricing.hourlyBaseRateHours,
      otherAmt: pricing.otherAmt,
      otherPercent: pricing.otherPercent !== null ? pricing.otherPercent : null,
      otherName: pricing.otherName,
      other2Amt: pricing.other2Amt,
      other2Percent:
        pricing.other2Percent !== null ? pricing.other2Percent : null,
      other2Name: pricing.other2Name,
      other3Amt: pricing.other3Amt,
      other3Percent:
        pricing.other3Percent !== null ? pricing.other3Percent : null,
      other3Name: pricing.other3Name,
      promoCodeId: pricing.promoCodeId,
      promoCodeAmt: pricing.promoCodeAmt,
      promoCodePercent:
        pricing.promoCodePercent !== null ? pricing.promoCodePercent : null,
      promoDiscountAmt: pricing.promoDiscountAmt,
      promoDiscountPercent:
        pricing.promoDiscountPercent !== null
          ? pricing.promoDiscountPercent
          : null,
      taxAmt: pricing.taxAmt,
      taxPercent: pricing.taxPercent !== null ? pricing.taxPercent : null,
      tollsAmt: pricing.tollsAmt,
      meetGreetAmt: pricing.meetGreetAmt,
      rearFacingSeatQuantity: pricing.rearFacingSeatQuantity,
      forwardFacingSeatQuantity: pricing.forwardFacingSeatQuantity,
      boosterSeatQuantity: pricing.boosterSeatQuantity,
      // calculate total seat amt
      rearFacingSeatAmt: pricing.rearFacingSeatQuantity
        ? pricing.rearFacingSeatAmt * pricing.rearFacingSeatQuantity
        : null,
      forwardFacingSeatAmt: pricing.forwardFacingSeatQuantity
        ? pricing.forwardFacingSeatAmt * pricing.forwardFacingSeatQuantity
        : null,
      boosterSeatAmt: pricing.boosterSeatQuantity
        ? pricing.boosterSeatAmt * pricing.boosterSeatQuantity
        : null,
    };

    onAccept(onAcceptPricing, activeTripIndex, closeDialogOnComplete);
  };

  // if the inputted pricing differs from the pricing currently in the form, open the confirm dialog
  const handleTripOptionClick = (index: number) => {
    if (!isEqual(pricing, activePricing)) {
      setNewSelectedTripIndex(index);
    } else {
      setActiveTripIndex(index);
    }
  };

  // confirm dialog handlers
  const handleCloseConfirmDialog = () => {
    setNewSelectedTripIndex(null);
  };

  const handleSaveChanges = () => {
    handleAcceptPricing(false);
    handleCloseConfirmDialog();
    setActiveTripIndex(newSelectedTripIndex);
  };

  const handleDiscardChanges = () => {
    setActiveTripIndex(newSelectedTripIndex);
    handleCloseConfirmDialog();
  };

  // effects
  // reset active trip index when currentTripIndex changes
  useEffect(() => {
    if (!open) return;

    setActiveTripIndex(currentTripIndex);
  }, [currentTripIndex, open]);

  // when selected trip changes, update pricing fields and rendered fields
  // when pricings prop changes, update pricing fields and rendered fields
  useEffect(() => {
    setPricing(activePricing);
  }, [activePricing]);

  // MoovsPricingForm props
  const BRAProps = {
    preexistingABR: pricing.preexistingABR,
    ...pricing.BRAProps,
  };

  return (
    <>
      <MoovsDialog
        open={open}
        onClose={onClose}
        dialogTitle="Pricing"
        fixedFooter
        size="sm"
        acceptButtonText="Update Price"
        onAccept={() => handleAcceptPricing(true)}
        acceptDisabled={pricing.baseRateAmt === 0 || isUpdating}
        hideLoadingIndicator={pricing.baseRateAmt === 0}
      >
        <Box display="flex" flexDirection="column" my={3}>
          {pricings.length > 1 && (
            <Box mb={3}>
              <Box mb={2}>
                <Typography variant="h4">Selected Trip</Typography>
              </Box>
              <Box mb={2}>
                <MoovsSelectDropdown
                  options={pricings.map((pricing) => ({
                    label: pricing.label,
                    disabled: pricing.isCancelled,
                  }))}
                  onOptionClick={handleTripOptionClick}
                  activeIndex={activeTripIndex}
                />
              </Box>
              <Divider />
            </Box>
          )}

          {legacyBillings.length > 0 && (
            <Box mb={2} data-testid="legacy-billings-block-container">
              <LegacyTransactionsBlock billings={legacyBillings} />
            </Box>
          )}
          <MoovsPricingForm
            pricing={pricing}
            onPricingUpdate={handlePricingUpdate}
            // @ts-ignore fix later
            BRAProps={BRAProps}
            totalDurationMinutes={pricing.totalDurationMinutes}
            setIsBaseRateAutomationClicked={setIsBaseRateAutomationClicked}
            setExisitngAutomatedBaseRate={setExisitngAutomatedBaseRate}
            isFarmedRoute={isFarmedRoute}
            hidePromoCodes={hidePromoCodes}
            childSeatOptions={childSeatOptions}
          />
        </Box>
      </MoovsDialog>
      <ConfirmSwitchPricingDialog
        open={typeof newSelectedTripIndex === "number"}
        onConfirm={handleSaveChanges}
        onDiscard={handleDiscardChanges}
        onCancel={handleCloseConfirmDialog}
        newPricingLabel={activePricing?.label}
      />
    </>
  );
}

export default MoovsPricingDialog;
