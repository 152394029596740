import React from "react";
import startCase from "lodash/startCase";

import { Box, Card, CardActionArea, Typography } from "@mui/material";

import { InsurancePolicy } from "../../../types";
import { currency } from "../../../globals/utils/helpers";

const styles = {
  cancelationItemText: {
    fontSize: "12px",
  },
};

type InsuranceListItemProps = {
  insurancePolicy: InsurancePolicy;
  onClick: (cancellationPolicyId: string) => void;
};

function InsuranceListItem(props: InsuranceListItemProps) {
  const { insurancePolicy, onClick } = props;

  const { id, insuranceCompany, policyNumber, coverageAmount, endDate } =
    insurancePolicy;

  const handleCardClick = () => onClick(id);

  return (
    <Card variant="outlined">
      <CardActionArea disableRipple onClick={handleCardClick}>
        <Box
          display="flex"
          flexDirection="row"
          flex="1"
          py={1.5}
          px={3}
          height="76px"
          alignItems="center"
        >
          {/* Policy */}
          <Box display="flex" flex="2">
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {startCase(insuranceCompany)}
            </Typography>
          </Box>

          {/* Policy Number  */}
          <Box display="flex" flex="1">
            <Typography sx={styles.cancelationItemText}>
              {policyNumber || "-"}
            </Typography>
          </Box>

          {/* Coverage Amount*/}
          <Box display="flex" flex="1">
            <Typography sx={styles.cancelationItemText}>
              {currency(coverageAmount)}
            </Typography>
          </Box>

          {/* 25% Refund */}
          <Box display="flex" flex="1">
            <Typography sx={styles.cancelationItemText}>
              {endDate || "-"}
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default InsuranceListItem;
