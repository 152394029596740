import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import moment from "moment-timezone";

import { Box, CircularProgress } from "@mui/material";

import UpdateDrawer from "../../../globals/UpdateDrawer";
import { InsurancePolicy } from "../../../../types";
import { LOAD_INSURANCE_POLICY_QUERY } from "../../../../globals/graphql";
import GQLQueryStatusIndicator from "../../../GQLQueryStatusIndicator";
import RemoveInsuranceDialog from "./components/RemoveInsuranceDialog";
import UpdateInsuranceForm from "./components/UpdateInsuranceForm";

function UpdateInsuranceDrawer() {
  const history = useHistory();
  const { insuranceId } = useParams<{ insuranceId: string }>();

  // state
  const [removeInsuranceDialogOpen, setRemoveInsuranceDialogOpen] =
    useState<boolean>(false);
  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "loading" | "saved" | "error"
  >("default");

  // queries
  const {
    data: insurancePolicyData,
    loading: insurancePolicyLoading,
    refetch: insurancePolicyRefetch,
    error: insurancePolicyError,
  } = useQuery(LOAD_INSURANCE_POLICY_QUERY, {
    variables: {
      id: insuranceId,
    },
    skip: !insuranceId,
    errorPolicy: "all",
  });

  // derived state
  const insurancePolicy: InsurancePolicy = insurancePolicyData?.node;

  const createdAt = insurancePolicy
    ? moment(insurancePolicy.createdAt).format("LLL")
    : "";

  const updatedAt = insurancePolicy
    ? moment(insurancePolicy.updatedAt).format("LLL")
    : "";

  // event handlers
  const handleDrawerClose = () => {
    history.push("/settings/insurance");
  };

  return (
    <>
      <UpdateDrawer
        onClose={handleDrawerClose}
        createdAt={createdAt}
        updatedAt={updatedAt}
        saveIndicatorState={saveIndicatorState}
        ellipsisMenuOptions={[
          {
            onClick: () => {
              setRemoveInsuranceDialogOpen(true);
            },
            text: "Delete Policy",
          },
        ]}
      >
        {insurancePolicyLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={40} thickness={2} />
          </Box>
        )}
        {insurancePolicyError && !insurancePolicyLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <GQLQueryStatusIndicator
              name="Insurance Policy"
              data={insurancePolicyData}
              error={insurancePolicyError}
              refetch={insurancePolicyRefetch}
            />
          </Box>
        )}
        {insurancePolicy && (
          <>
            <UpdateInsuranceForm
              insurancePolicy={insurancePolicy}
              setSaveIndicatorState={setSaveIndicatorState}
              insurancePolicyRefetch={insurancePolicyRefetch}
            />
            <RemoveInsuranceDialog
              open={removeInsuranceDialogOpen}
              onClose={() => setRemoveInsuranceDialogOpen(false)}
              insuranceId={insuranceId}
            />
          </>
        )}
      </UpdateDrawer>
    </>
  );
}

export default UpdateInsuranceDrawer;
