import React, { Fragment } from "react";
import { useMutation } from "@apollo/client";
import includes from "lodash/includes";

import {
  Box,
  Typography,
  CircularProgress,
  Divider,
  Checkbox,
  CheckboxProps,
} from "@mui/material";

import { grayDark, grayLight } from "design-system/colors";
import { UPDATE_OPERATOR_TRIP_TYPE_MUTATION } from "globals/graphql";
import { TripCategory } from "types";
import { useOperator, useSnackbar } from "globals/hooks";

enum TripType {
  HOURLY = "hourly",
  ONE_WAY = "one-way",
  ROUND_TRIP = "round-trip",
}

type TripTypesProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function TripTypes(props: TripTypesProps) {
  const { setSaveIndicatorState } = props;

  const tripTypes = [
    { name: TripCategory.OneWay, label: "One Way" },
    { name: TripCategory.RoundTrip, label: "Round Trip" },
    { name: TripCategory.Hourly, label: "Hourly" },
  ];

  // hooks
  const snackbar = useSnackbar();
  const { enabledTripCategories } = useOperator();

  // derived state
  const sortedTripTypes = tripTypes.sort((a, b) => {
    if (enabledTripCategories.indexOf(a.name) === -1) {
      return 1;
    }
    if (enabledTripCategories.indexOf(b.name) === -1) {
      return -1;
    }
    return (
      enabledTripCategories.indexOf(a.name) -
      enabledTripCategories.indexOf(b.name)
    );
  });

  // mutations
  const [UpdateOperatorTripType] = useMutation(
    UPDATE_OPERATOR_TRIP_TYPE_MUTATION,
    {
      refetchQueries: ["Operator"],
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        snackbar.error("Error updating trip types.");
      },
    }
  );

  // event handlers
  const handleTripTypeChange: CheckboxProps["onChange"] = (event) => {
    setSaveIndicatorState("loading");
    const tripType = event.target.value;
    const enabled = event.target.checked;

    if (enabledTripCategories.length === 1 && !enabled) {
      setSaveIndicatorState("error");
      snackbar.error("Must have at least one selected");
    } else {
      UpdateOperatorTripType({
        variables: {
          input: {
            tripType,
            enabled,
          },
        },
      });
    }
  };

  return (
    <Box>
      {!enabledTripCategories ? (
        <Box
          width="100%"
          height="100px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={40} thickness={4} />
        </Box>
      ) : (
        <Box mb={4}>
          <Typography variant="h3" mb={1}>
            Trip Types
          </Typography>
          <Typography variant="body2" color={grayDark} mb={2}>
            Select trip types you would like to offer to your customers
          </Typography>
          {sortedTripTypes.map((tripType, i) => {
            const checked = includes(enabledTripCategories, tripType.name);

            return (
              <Fragment key={i}>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    sx={{
                      padding: 0,
                      "& .MuiSvgIcon-root": { fontSize: 28 },
                    }}
                    color="primary"
                    value={TripType[tripType.name]}
                    checked={checked}
                    onChange={handleTripTypeChange}
                  />
                  <Typography variant="body2" sx={{ ml: 2 }}>
                    {tripType.label}
                  </Typography>
                </Box>

                {i !== tripTypes.length - 1 && (
                  <Divider sx={{ my: 1.5, opacity: "50%" }} color={grayLight} />
                )}
              </Fragment>
            );
          })}
        </Box>
      )}
    </Box>
  );
}

export default TripTypes;
