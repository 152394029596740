import isNil from "lodash/isNil";

import { LuggageFields } from "components/requests/luggage/types";
import { Trip } from "types";

export const convertTripToBeInputReady = (
  trip: Trip,
  luggageAmount: LuggageFields
) => {
  // remove contact fields passed in for PersonInfoCard
  delete trip.contact.company;
  delete trip.contact.paymentMethods;
  delete trip.contact.mobilePhoneInternational;
  delete trip.contact.companyPosition;
  delete trip.contact.updatedAt;
  delete trip.contact.createdAt;

  trip.stops = trip.stops.map((stop: any) => {
    if (stop.airport) {
      stop.location = stop.airport.airportName;
      stop.airportIcao = stop.airport.icaoCode;
    }

    if (stop.airline) {
      stop.airlineIcao = stop.airline.icaoCode;
    }

    if (stop.trackedFlight) {
      delete stop.trackedFlight.__typename;
      delete stop.trackedFlight.airline.__typename;
      delete stop.trackedFlight.origin.__typename;
      delete stop.trackedFlight.origin.airport.__typename;
      delete stop.trackedFlight.destination.__typename;
      delete stop.trackedFlight.destination.airport.__typename;
    }

    delete stop.variant;
    delete stop.id;
    delete stop.airport;
    delete stop.airline;

    return stop;
  });

  trip.routes = trip.routes.map((route: any) => {
    if (route?.vehicle?.id) {
      route.vehicle = { id: route.vehicle.id };
    }
    if (route?.otherAmt === "" || route?.otherPercent !== null) {
      route.otherAmt = null;
    }
    if (route?.other2Amt === "" || route?.other2Percent !== null) {
      route.other2Amt = null;
    }
    if (route?.other3Amt === "" || route?.other3Percent !== null) {
      route.other3Amt = null;
    }
    if (
      route?.promoDiscountAmt === "" ||
      route?.promoDiscountPercent !== null
    ) {
      route.promoDiscountAmt = null;
    }
    if (route?.taxAmt === "" || route?.taxPercent !== null) {
      route.taxAmt = null;
    }
    if (route?.tollsAmt === "") {
      route.tollsAmt = null;
    }
    if (route?.meetGreetAmt === "") {
      route.meetGreetAmt = null;
    }
    if (
      route?.driverGratuityAmt === "" ||
      route?.driverGratuityPercent !== null
    ) {
      route.driverGratuityAmt = null;
    }
    if (route?.promoCodeAmt === "" || route?.promoCodePercent !== null) {
      route.promoCodeAmt = null;
    }

    const percentDecimals = {
      driverGratuityPercent: route.driverGratuityPercent
        ? route.driverGratuityPercent / 100
        : null,
      promoDiscountPercent: route.promoDiscountPercent
        ? route.promoDiscountPercent / 100
        : null,
      taxPercent: route.taxPercent ? route.taxPercent / 100 : null,
      otherPercent: route.otherPercent ? route.otherPercent / 100 : null,
      other2Percent: route.other2Percent ? route.other2Percent / 100 : null,
      other3Percent: route.other3Percent ? route.other3Percent / 100 : null,
      promoCodePercent: !isNil(route.promoCodePercent)
        ? route.promoCodePercent / 100
        : null,
    };

    // promoCodeName not needed for inputs
    delete route.promoCodeName;

    // get final child seat amounts
    const childSeatAmounts = {
      rearFacingSeatAmt:
        route.rearFacingSeatAmt !== null
          ? route.rearFacingSeatAmt * route.rearFacingSeatQuantity
          : null,
      forwardFacingSeatAmt:
        route.forwardFacingSeatAmt !== null
          ? route.forwardFacingSeatAmt * route.forwardFacingSeatQuantity
          : null,
      boosterSeatAmt:
        route.boosterSeatAmt !== null
          ? route.boosterSeatAmt * route.boosterSeatQuantity
          : null,
    };

    return {
      ...route,
      ...percentDecimals,
      ...childSeatAmounts,
      ...luggageAmount,
    };
  });

  delete trip.contact.__typename;

  return trip;
};
