import React, { Dispatch, SetStateAction, useState } from "react";
import startCase from "lodash/startCase";

import { Typography } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";
import MoovsProfilePhotoDialog from "components/MoovsProfilePhotoDialog";

type ViewPhotoDialogProps = {
  imageViewDialogOpen: boolean;
  setImageViewDialogOpen: Dispatch<SetStateAction<boolean>>;
  photoUrl: string;
  onPhotoUrlUpdate: (photoUrl: string) => void;
  variant: "driver" | "company";
  shape?: "rectangle" | "circle";
};

function ViewPhotoDialog(props: ViewPhotoDialogProps) {
  const {
    imageViewDialogOpen,
    setImageViewDialogOpen,
    photoUrl,
    onPhotoUrlUpdate,
    variant,
    shape = "circle",
  } = props;

  // state
  const [removeConfirmDialogOpen, setRemoveConfirmDialogOpen] = useState(false);

  // event handlers
  const handleRemoveImage = () => {
    setRemoveConfirmDialogOpen(true);
  };

  const handleRemoveImageConfirm = () => {
    onPhotoUrlUpdate("");

    setImageViewDialogOpen(false);
    setRemoveConfirmDialogOpen(false);
  };

  return (
    <>
      {/* photo view dialog */}
      <MoovsProfilePhotoDialog
        open={imageViewDialogOpen}
        onClose={() => {
          setImageViewDialogOpen(false);
        }}
        dialogTitle={`${startCase(variant)} Photo`}
        closeButtonText="Close"
        isDeleteImage
        onRemoveImage={handleRemoveImage}
      >
        <img
          src={photoUrl}
          alt={variant}
          style={{
            borderRadius: shape === "circle" ? "50%" : undefined,
            width: "100%",
            margin: "48px auto",
          }}
        />
      </MoovsProfilePhotoDialog>

      {/* remove photo confirm dialog*/}
      <MoovsDialog
        open={removeConfirmDialogOpen}
        onClose={() => {
          setRemoveConfirmDialogOpen(false);
        }}
        dialogTitle="Delete Photo"
        closeButtonText="No, Keep"
        size="xs"
        onAccept={handleRemoveImageConfirm}
        acceptButtonText="Yes, Delete"
      >
        <Typography style={{ margin: "8px" }}>
          Are you sure you want to delete this photo?
        </Typography>
      </MoovsDialog>
    </>
  );
}

export default ViewPhotoDialog;
