import { ApolloQueryResult, useQuery } from "@apollo/client";

import { LOAD_VEHICLES_QUERY } from "../graphql";
import { Vehicle } from "../../types";

type UseVehiclesTuple = [
  Vehicle[],
  () => Promise<ApolloQueryResult<Vehicle[]>>,
  boolean
];

function useVehicles(): UseVehiclesTuple {
  // query
  const {
    data: vehiclesData,
    refetch,
    loading,
  } = useQuery(LOAD_VEHICLES_QUERY, {
    fetchPolicy: "network-only",
  });

  const vehicles: Vehicle[] = vehiclesData?.loadVehicles?.edges
    .filter(({ node }) => node.available)
    .map(({ node }) => node);

  return [vehicles, refetch, loading];
}

export { useVehicles };
