import { useMutation } from "@apollo/client";

import { GridColDef } from "@mui/x-data-grid-pro";

import { UPDATE_USER_SETTINGS_MUTATION } from "globals/graphql";
import { useSnackbar } from "globals/hooks";

function useUpdateTripsViewConfigMutation() {
  // hooks
  const snackbar = useSnackbar();

  // mutations
  const [updateUserSettings] = useMutation(UPDATE_USER_SETTINGS_MUTATION, {
    onError: () => snackbar.error("Error saving trips view config"),
  });

  // event handlers
  const handleUpdateTripsViewConfig = (
    config: Pick<GridColDef, "hide" | "width" | "field">[]
  ) => {
    updateUserSettings({
      variables: {
        input: {
          tripsViewConfig: { config },
        },
      },
    });
  };

  return {
    onUpdateTripsViewConfig: handleUpdateTripsViewConfig,
  };
}

export default useUpdateTripsViewConfigMutation;
