import React from "react";
import { useHistory } from "react-router-dom";

import { Box, Button } from "@mui/material";

import { moovsBlue } from "design-system/colors";
import { UpgradeIcon } from "design-system/icons";
import { useAnalytics, useOperator } from "globals/hooks";
import { PlanVariant } from "types";

function UpgradePlanButton() {
  // hooks
  const operator = useOperator();
  const { track } = useAnalytics();
  const history = useHistory();

  // event handlers
  const trackClick = () => {
    track("bookingToolEnhancements_upgradePlanClicked");
    history.push("/settings/billing/plans");
  };

  return (
    <>
      {operator.plan !== PlanVariant.Pro &&
        operator.plan !== PlanVariant.Vip && (
          <>
            <Box sx={{ flexGrow: 1 }} />
            <Button sx={{ color: moovsBlue }} onClick={trackClick}>
              Upgrade Plan
              <Box pl={1}>
                <UpgradeIcon color={moovsBlue} size="small" />
              </Box>
            </Button>
          </>
        )}
    </>
  );
}

export default UpgradePlanButton;
