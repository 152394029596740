import React from "react";

type EstimationIconProps = {
  color?: string;
  size: "small";
};

const EstimationIcon = (props: EstimationIconProps) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#estimation-icon-clip-path)">
        <path
          d="M9.66706 1L3.09839 8.13793C3.04715 8.19453 3.0143 8.26361 3.00372 8.33705C2.99314 8.41048 3.00525 8.48521 3.03864 8.55243C3.07204 8.61966 3.12531 8.67659 3.1922 8.71651C3.25909 8.75643 3.33681 8.77769 3.41621 8.77778H6.33328V15L12.902 7.86311C12.9531 7.8064 12.9858 7.73726 12.9963 7.66381C13.0068 7.59036 12.9947 7.51563 12.9614 7.44838C12.928 7.38113 12.8748 7.32414 12.808 7.28408C12.7412 7.24403 12.6635 7.22257 12.5841 7.22222H9.66706V1Z"
          stroke={color || "#28B668"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="estimation-icon-clip-path">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EstimationIcon;
