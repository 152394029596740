import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";

import { Box, Typography, Hidden } from "@mui/material";

import {
  AmountAwaitingPayoutBlock,
  PayoutsList,
  PayoutsTitleBlock,
} from "./components";
import { useScreenSize } from "globals/hooks";
import { LOAD_STRIPE_PAYOUTS_QUERY } from "globals/graphql";

type PayoutsBlockProps = {
  hasStripeAccount: boolean;
};

function PayoutsBlock(props: PayoutsBlockProps) {
  const { hasStripeAccount } = props;

  // state
  const [payoutInterval, setPayoutInterval] = useState<{
    // type will be numbers as we will get the UTC timestamp
    startDate: number;
    endDate: number;
  }>({
    startDate: 0,
    endDate: 0,
  });
  // for when operator has stripe account but has no payouts yet.
  const [hidePayoutDownload, setHidePayoutDownload] = useState(false);

  // hooks
  const { isSmallTabletView } = useScreenSize();

  // queries
  const {
    data: stripePayoutsData,
    error: stripePayoutsError,
    loading: stripePayoutsLoading,
    fetchMore: stripePayoutsFetchMore,
  } = useQuery(LOAD_STRIPE_PAYOUTS_QUERY, {
    variables: {
      limit: isSmallTabletView ? 10 : 20,
    },
    skip: !hasStripeAccount,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true, // needed to render loading when fetching more
  });

  // event handlers
  const handleFetchMore = () => {
    stripePayoutsFetchMore({
      variables: {
        cursor: stripePayoutsData?.stripePayouts?.pageInfo.endCursor,
      },
    });
  };

  useEffect(() => {
    if (stripePayoutsData?.stripePayouts) {
      setHidePayoutDownload(isEmpty(stripePayoutsData.stripePayouts.edges));

      setPayoutInterval({
        startDate: stripePayoutsData.stripePayouts.payoutIntervalStart,
        endDate: stripePayoutsData.stripePayouts.payoutIntervalEnd,
      });
    }
  }, [stripePayoutsData, setPayoutInterval, setHidePayoutDownload]);

  const hasIntervalDates =
    payoutInterval.startDate > 0 && payoutInterval.endDate > 0;
  const showPayoutDownloadSection = hasStripeAccount && !hidePayoutDownload;

  return (
    hasStripeAccount && (
      <>
        <AmountAwaitingPayoutBlock />
        {!isEmpty(stripePayoutsData?.stripePayouts) && (
          <Box mb={3}>
            <PayoutsTitleBlock
              showPayoutDownloadSection={showPayoutDownloadSection}
              hasIntervalDates={hasIntervalDates}
              payoutInterval={payoutInterval}
            />
          </Box>
        )}
        <Hidden mdDown>
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            alignItems="center"
            pb="12px"
          >
            <Box
              display="flex"
              flexDirection="row"
              flex="1"
              pr={2}
              maxWidth={900}
              mx="auto"
            >
              <Box display="flex" flex="1">
                <Typography variant="overline">PAYOUT DATE</Typography>
              </Box>
              <Box display="flex" flex="4">
                <Typography variant="overline">AMOUNT</Typography>
              </Box>
              <Box display="flex" flex="1" justifyContent="flex-end">
                <Typography variant="overline">STATUS</Typography>
              </Box>
            </Box>
          </Box>
        </Hidden>
        <PayoutsList
          stripePayoutsData={stripePayoutsData?.stripePayouts}
          stripePayoutsError={stripePayoutsError}
          stripePayoutsLoading={stripePayoutsLoading}
          onFetchMore={handleFetchMore}
        />
      </>
    )
  );
}

export default PayoutsBlock;
