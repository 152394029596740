import React from "react";

import { Box, Typography } from "@mui/material";

import { DriverPayable } from "../../../types";
import MoovsAvatar from "../../../design-system/components/MoovsAvatar";
import { currency } from "../../../globals/utils/helpers";
import DriverPayoutTextBlock from "../DriverPayoutTextBlock";

type DriverPayoutListItemMobileType = {
  driverPayable: DriverPayable;
};

function DriverPayoutListItemMobile(props: DriverPayoutListItemMobileType) {
  const { driverPayable } = props;
  const { driver, routesCount, flatRateAmt, gratuityAmt, hourlyAmt, totalAmt } =
    driverPayable || {};
  const { driverProfilePhoto, firstName, lastName } = driver || {};

  return (
    <Box display="flex" flexDirection="column" borderRadius="4px" px={3} py={2}>
      <Box
        display="flex"
        flex="1"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        mb={1}
      >
        <MoovsAvatar
          size="small"
          placeholder={[firstName, lastName]}
          src={driverProfilePhoto}
        />
        <Typography
          variant="subtitle1"
          style={{ marginLeft: 12, fontWeight: 700 }}
        >
          {firstName} {lastName}
        </Typography>
      </Box>
      <Box
        display="flex"
        flex="1"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        <Box mr="10px">
          <DriverPayoutTextBlock size="sm" header="trips" body={routesCount} />
        </Box>
        <Box mr="10px">
          <DriverPayoutTextBlock
            size="sm"
            header="flat rate"
            body={currency(flatRateAmt)}
          />
        </Box>
        <Box mr="10px">
          <DriverPayoutTextBlock
            size="sm"
            header="hourly"
            body={currency(hourlyAmt)}
          />
        </Box>
        <Box mr="10px">
          <DriverPayoutTextBlock
            size="sm"
            header="gratuity"
            body={currency(gratuityAmt)}
          />
        </Box>
        <Box>
          <DriverPayoutTextBlock
            size="sm"
            bold
            header="total"
            body={currency(totalAmt)}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default DriverPayoutListItemMobile;
