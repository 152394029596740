import React, { useState } from "react";

import { Box, Button, ButtonBase, Typography } from "@mui/material";

import {
  grayMedium,
  moovsBlue,
  successGreen,
  white,
} from "../../design-system/colors";
import { Operator, WebsitePermissions } from "../../types";
import {
  ArrowRightIcon,
  CheckIcon,
  UpgradeIcon,
} from "../../design-system/icons";
import { useMutation } from "@apollo/client";
import {
  SET_BASIC_DUDA_PERMISSIONS_MUTATION,
  SET_PREMIUM_DUDA_PERMISSIONS_MUTATION,
} from "../../globals/graphql";
import { useAnalytics } from "../../globals/hooks";
import RemoveDialog from "../RemoveDialog";
import MoovsConfetti from "../../design-system/components/MoovsConfetti";

type WebsiteMyPlanSectionProps = {
  operator: Partial<Operator>;
};

function WebsiteMyPlanSection(props: WebsiteMyPlanSectionProps) {
  const { operator } = props;

  // hooks
  const { track } = useAnalytics();
  const [confetti, setConfetti] = useState(false);

  const remoteWebsitePermissions = operator.dudaWebsite.websitePermissions;

  // state
  const [websitePermissions, setWebsitePermissions] = useState(
    remoteWebsitePermissions
  );
  const [downgradeDialogOpen, setDowngradeDialogOpen] = useState(false);

  // mutations
  const [setPremiumPermissions] = useMutation(
    SET_PREMIUM_DUDA_PERMISSIONS_MUTATION,
    {
      onCompleted(data) {
        setConfetti(true);
        track("Premium plan upgrade");
      },
    }
  );

  const [setBasicPermissions] = useMutation(
    SET_BASIC_DUDA_PERMISSIONS_MUTATION,
    {
      onCompleted(data) {
        setConfetti(false);
        track("Basic plan downgrade");
      },
    }
  );

  // event handlers
  const handleBasicPlanButtonClick = () => {
    setDowngradeDialogOpen(true);
  };

  const handlePremiumPlanButtonClick = () => {
    setWebsitePermissions(WebsitePermissions.Premium);
    setPremiumPermissions({
      variables: {
        input: {},
      },
    });
  };

  const handleClickDowngrade = () => {
    setBasicPermissions({
      variables: {
        input: {},
      },
    });
    setWebsitePermissions(WebsitePermissions.Basic);
    setDowngradeDialogOpen(false);
  };

  return <>
    <MoovsConfetti active={confetti} />
    <Box>
      <Box mb={2}>
        <Typography variant="overline">My Plan</Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box
          mb={2}
          display="flex"
          borderRadius="4px"
          maxWidth="360px"
          border={`1px solid ${
            websitePermissions === WebsitePermissions.Basic
              ? moovsBlue
              : grayMedium
          }`}
        >
          <ButtonBase
            name={WebsitePermissions.Basic}
            component={Button}
            focusRipple
            disabled={websitePermissions === WebsitePermissions.Basic}
            disableTouchRipple
            style={{ display: "flex", flex: 1 }}
            onClick={handleBasicPlanButtonClick}
          >
            <Box
              display="flex"
              my="14px"
              mx={3}
              width="100%"
              minHeight={6}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="column">
                <Typography style={{ textAlign: "left" }} variant="h4">
                  Basic
                </Typography>
                <Typography style={{ textAlign: "left" }} variant="body2">
                  Basic 1 page website
                </Typography>
              </Box>
              <Box>
                {websitePermissions === WebsitePermissions.Basic && (
                  <CheckIcon color={moovsBlue} />
                )}
              </Box>
            </Box>
          </ButtonBase>
        </Box>

        <Box
          display="flex"
          borderRadius="4px"
          maxWidth="360px"
          border={`1px solid ${
            websitePermissions === WebsitePermissions.Basic
              ? grayMedium
              : moovsBlue
          }`}
        >
          <Box m={3} width="100%" minHeight={6}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography style={{ textAlign: "left" }} variant="h4">
                  Premium
                </Typography>
                {websitePermissions === WebsitePermissions.Premium && (
                  <Box>
                    <CheckIcon color={moovsBlue} />
                  </Box>
                )}
              </Box>
              {websitePermissions === WebsitePermissions.Basic && (
                <Box mb={2} style={{ textAlign: "left" }}>
                  <Typography variant="caption">
                    Upgrade your plan to get access to:
                  </Typography>
                </Box>
              )}

              <Box display="flex" mt={1} mb={2}>
                <Box mr={1.5} style={{ textAlign: "left" }}>
                  <ArrowRightIcon color={successGreen} size="small" />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ textAlign: "left" }}
                >
                  <Box mb={0.5}>
                    <Typography variant="subtitle1">Editing</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      Customize the content and visual style
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box display="flex" mb={2}>
                <Box
                  mr={1.5}
                  style={{ textAlign: "left" }}
                  alignItems="center"
                >
                  <ArrowRightIcon color={successGreen} size="small" />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ textAlign: "left" }}
                >
                  <Box mb={0.5}>
                    <Typography variant="subtitle1">Analytics</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      See detailed data about who is coming to your site
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {websitePermissions === WebsitePermissions.Basic && (
                <Button
                  name={WebsitePermissions.Premium}
                  variant="contained"
                  color="primary"
                  onClick={handlePremiumPlanButtonClick}
                >
                  <Box display="flex" alignItems="center">
                    <UpgradeIcon color={white} />

                    <Box ml={1}>
                      <Typography variant="button">Upgrade Plan</Typography>
                    </Box>
                  </Box>
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
    <RemoveDialog
      open={downgradeDialogOpen}
      onRemove={handleClickDowngrade}
      onClose={() => setDowngradeDialogOpen(false)}
      title="Are you sure you want to downgrade to basic?"
      body="You will lose editing features and access to site analytics."
      removeButtonText="Downgrade to Basic"
    />
  </>;
}

export default WebsiteMyPlanSection;
