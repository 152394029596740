import React from "react";

import { Box, Divider } from "@mui/material";
import MoovsInfiniteScroll from "components/MoovsInfiniteScroll";
import CustomerReviewListItem from "./components";
import { LoadTripReviewsConnection } from "types";

type CustomerReviewListProps = {
  tripReviewsData: LoadTripReviewsConnection;
  tripReviewsLoading: boolean;
  tripReviewsFetchMore: () => void;
};

function CustomerReviewList(props: CustomerReviewListProps) {
  const { tripReviewsData, tripReviewsLoading, tripReviewsFetchMore } = props;

  return (
    <Box>
      <MoovsInfiniteScroll
        loading={tripReviewsLoading}
        data={tripReviewsData}
        next={tripReviewsFetchMore}
        name="reviews"
      >
        {tripReviewsData?.edges.map((data, index) => {
          return (
            <>
              <CustomerReviewListItem
                tripReviewData={data.node}
                key={data.node.id}
              />
              {tripReviewsData?.edges.length - 1 !== index ? (
                <Box my={3}>
                  <Divider light />
                </Box>
              ) : (
                <Box my={4}></Box>
              )}
            </>
          );
        })}
      </MoovsInfiniteScroll>
    </Box>
  );
}

export default CustomerReviewList;
