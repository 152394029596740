import React, { useState, Dispatch, SetStateAction } from "react";
import { DeepPartial } from "ts-essentials";
import isEmpty from "lodash/isEmpty";
import flatMap from "lodash/flatMap";
import compact from "lodash/compact";

import { Box, TextField, Typography, Divider, MenuItem } from "@mui/material";

import {
  Contact,
  Request,
  OrderType,
  OrderTypeEnum,
  TripCategory,
  Company,
} from "types";
import ContactAutoComplete from "../../../../../autocompletes/ContactAutoComplete";
import ProfileInfoCard from "../../../../../globals/ProfileInfoCard";
import { orderTypeSlugToEnumMap } from "globals/utils/enumMaps";
import { CompanyIcon, TrashIcon } from "design-system/icons";
import AddContactToOrderDialog from "components/contacts/people/AddContactToOrderDialog";
import TripTypeButtonsBlock from "../TripTypeButtonsBlock";
import { useAnalytics, useScreenSize } from "globals/hooks";
import { LinkCompanyButton } from "./components";
import { purple, tintPurple } from "design-system/colors";

type OrderDetailsCreateBlockProps = {
  bookingContact: DeepPartial<Contact>;
  setBookingContact: (contact: DeepPartial<Contact>) => void;
  requestErrors: any;
  setRequestErrors: any;
  requestType: string;
  updateRequest: (update: Partial<Request>) => void;
  enabledOrderTypes: OrderType[];
  createNewContact: boolean;
  setCreateNewContact: Dispatch<SetStateAction<boolean>>;
  onClearBookingContact: () => void;
  setHasPaymentMethod?: (val: boolean) => void;
  tripType: string;
  onTripTypeChange: (e: any, value: TripCategory) => void;
  enabledTripCategories: TripCategory[];
  isQuote: boolean;
  company: DeepPartial<Company>;
  setCompany: (company: DeepPartial<Company>) => void;
  linkCompany: boolean;
  setLinkCompany: Dispatch<SetStateAction<boolean>>;
};

function OrderDetailsCreateBlock(props: OrderDetailsCreateBlockProps) {
  const {
    bookingContact,
    setBookingContact,
    requestErrors,
    setRequestErrors,
    requestType,
    updateRequest,
    enabledOrderTypes,
    createNewContact,
    setCreateNewContact,
    onClearBookingContact,
    setHasPaymentMethod,
    tripType,
    onTripTypeChange,
    enabledTripCategories,
    company,
    setCompany,
    isQuote,
    linkCompany,
    setLinkCompany,
  } = props;

  // state
  const [carryOverSearch, setCarryOverSearch] = useState("");

  const showLinkCompanyButton = company && !linkCompany;

  // hooks
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();

  // event handlers
  const handleContactSelect = (contact: Contact) => {
    setBookingContact(contact);

    if (contact.company && setCompany) {
      setCompany(contact.company);
      setLinkCompany(true);
    }

    if (!!setHasPaymentMethod) {
      setHasPaymentMethod(!isEmpty(contact.paymentMethods));
    }
    setRequestErrors({
      ...requestErrors,
      email: "",
    });
  };

  // Resets contact in case user has already selected a
  // contact and then decides to create new contact
  const handleCreateNewContactClick = () => {
    onClearBookingContact(); // clear request state
    setBookingContact(null); // clear local state
    setCreateNewContact(true);
  };

  const handleRemoveBookingContact = () => {
    onClearBookingContact();
    setBookingContact(null);
    setLinkCompany(false);
    setCompany(null);
  };

  const handleAddNewBookingContact = (contact: Contact) => {
    setBookingContact(contact);
    setHasPaymentMethod && setHasPaymentMethod(false);
  };

  const handleRequestTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    updateRequest({
      orderType: event.target.value as OrderTypeEnum,
    });

    setRequestErrors({
      ...requestErrors,
      type: "",
    });
  };

  const handleRemoveCompany = () => {
    setLinkCompany(false);
    isQuote
      ? track("quote_companyRemoved")
      : track("reservation_companyRemoved");
  };

  const handleLinkCompany = () => {
    setLinkCompany(true);
    isQuote ? track("quote_companyAdded") : track("reservation_companyAdded");
  };

  // helpers
  const orderTypeMenuItems = flatMap(
    enabledOrderTypes,
    ({ id, name, slug, category }, index) => {
      const isNewCategory =
        !index || category !== enabledOrderTypes[index - 1].category;

      return compact([
        index && isNewCategory && (
          <MenuItem key={`${id}_${category}_divider`} disabled>
            <Box mb={1} mt={1} width={"100%"}>
              <Divider />
            </Box>
          </MenuItem>
        ),

        isNewCategory && (
          <MenuItem key={`${id}_${category}`} disabled>
            <Typography variant={"overline"}>{category}</Typography>
          </MenuItem>
        ),

        <MenuItem key={id} value={orderTypeSlugToEnumMap[slug]}>
          <Box>{name}</Box>
        </MenuItem>,
      ]);
    }
  );

  return (
    <>
      <Box mt={4} mb={1.5}>
        <Typography variant="h5" style={{ fontWeight: 700 }}>
          Order Details
        </Typography>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      {!createNewContact && !bookingContact && (
        <Box mb={1}>
          <ContactAutoComplete
            setCarryOverSearch={setCarryOverSearch}
            onCreateNewContactClick={handleCreateNewContactClick}
            onChange={handleContactSelect}
            error={!!requestErrors.bookingContact}
            helperText={requestErrors.bookingContact}
            label="Search for booking contact"
          />
        </Box>
      )}

      <Box
        {...(!isMobileView && {
          display: "flex",
        })}
      >
        {bookingContact && (
          <ProfileInfoCard
            headerText="Booking / Billing Contact"
            firstName={bookingContact.firstName}
            lastName={bookingContact.lastName}
            email={bookingContact.email}
            phone={bookingContact.mobilePhone}
            preferences={bookingContact.preferences}
            company={bookingContact?.company?.name}
            paymentMethods={bookingContact.paymentMethods}
            actionButtons={[
              {
                icon: <TrashIcon />,
                eventHandler: handleRemoveBookingContact,
              },
            ]}
            sx={{ mr: 2 }}
          />
        )}

        {showLinkCompanyButton ? (
          <LinkCompanyButton
            company={company}
            onLinkCompany={handleLinkCompany}
            isQuote={isQuote}
          />
        ) : company ? (
          <ProfileInfoCard
            headerText="Company"
            firstName={company.name}
            email={company.email}
            actionButtons={[
              {
                icon: <TrashIcon />,
                eventHandler: handleRemoveCompany,
              },
            ]}
            headerColor={purple}
            headerIcon={<CompanyIcon color={purple} size="small" />}
            avatarBackgroundColor={tintPurple}
            avatarColor={purple}
          />
        ) : null}
      </Box>

      <Box mt={2}>
        <TextField
          label="Order Type"
          fullWidth
          variant="outlined"
          select
          required
          value={requestType || ""}
          onChange={handleRequestTypeChange}
          error={!!requestErrors.type}
          helperText={requestErrors.type ? requestErrors.type : ""}
        >
          {orderTypeMenuItems}
        </TextField>
      </Box>

      <TripTypeButtonsBlock
        onChange={onTripTypeChange}
        value={tripType}
        enabledTripCategories={enabledTripCategories}
      />

      <AddContactToOrderDialog
        open={createNewContact}
        onClose={() => setCreateNewContact(false)}
        onContactUpdate={handleAddNewBookingContact}
        carryOverSearch={carryOverSearch}
        {...(linkCompany && { companyId: company.id })}
      />
    </>
  );
}

export default OrderDetailsCreateBlock;
