import { useQuery } from "@apollo/client";
import map from "lodash/map";

import { FarmAffiliateVariantEnum } from "types";
import { LOAD_AFFILIATE_TRIPS_QUERY } from "globals/graphql";

type useLoadTripsForAffiliateProps = {
  affiliateId: string;
  farmAffiliateVariant: FarmAffiliateVariantEnum;
};

export function useLoadTripsForAffiliate(props: useLoadTripsForAffiliateProps) {
  const { affiliateId, farmAffiliateVariant } = props;

  const variables =
    farmAffiliateVariant === FarmAffiliateVariantEnum.ExternalOperator
      ? { externalOperatorId: affiliateId }
      : { farmAffiliateId: affiliateId };

  // queries
  const {
    data,
    loading: tripsLoading,
    fetchMore: fetchMoreTrips,
  } = useQuery(LOAD_AFFILIATE_TRIPS_QUERY, {
    variables,
    skip: !affiliateId,
    fetchPolicy: "network-only",
  });

  const trips = map(data?.loadAffiliateTrips?.edges, (trip) => trip?.node);

  return {
    trips,
    tripsLoading,
    fetchMoreTrips,
    hasNextPage: data?.loadAffiliateTrips.pageInfo.hasNextPage,
    cursor: data?.loadAffiliateTrips.pageInfo.endCursor,
  };
}
