import React from "react";
import { useHistory } from "react-router-dom";

import { Box, Button } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";

function BackButton() {
  // hooks
  const history = useHistory();

  return (
    <Box pt={2}>
      <Button
        disableRipple
        onClick={() => history.push("/settings/billing")}
        color="primary"
        startIcon={<ArrowBack />}
        sx={{ px: 0, "&:hover": { bgcolor: "transparent" } }}
      >
        Back
      </Button>
    </Box>
  );
}

export default BackButton;
