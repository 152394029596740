import React from "react";

import { Box, Typography, MenuList, MenuItem, Skeleton } from "@mui/material";

import {
  grayLight,
  moovsBlueSelected,
  moovsBlueLight,
} from "../../../../design-system/colors";
import { Vehicle } from "../../../../types";
import { vehicleIconByIconCategory } from "../../../../globals/utils/vehicleTypes";
import theme from "theme";

type MoovsMediaVehiclesListProps = {
  vehicles: Vehicle[];
  onVehicleSelect: (selectedVehicle: Vehicle) => void;
  selectedVehicle: Vehicle;
  vehiclesLoading?: boolean;
  isMobileView?: boolean;
};

function MoovsMediaVehiclesList(props: MoovsMediaVehiclesListProps) {
  const { vehicles, onVehicleSelect, selectedVehicle, vehiclesLoading } = props;

  // custom styles
  const styles = {
    selectedMenuItem: {
      backgroundColor: `${moovsBlueSelected} !important`,
      border: `1px solid ${moovsBlueLight}`,
    },
    menuItem: {
      mt: "2px",
      padding: `${theme.spacing(1.5)} 0`,
      width: "95%",
      borderRadius: "5px",
    },
  };

  return (
    <Box
      borderRadius="undefinedpx"
      border={`1px solid ${grayLight}`}
      px={2}
      pt={2}
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box>
        <Typography variant="overline">Vehicles</Typography>
      </Box>
      {vehiclesLoading ? (
        <Box>
          {[...Array(5)].map((_, i) => (
            <Skeleton
              sx={{
                "& .MuiSkeleton-text": { transform: "none", marginTop: "2px" },
              }}
              key={i}
              height={52}
            />
          ))}
        </Box>
      ) : vehicles.length > 0 ? (
        <MenuList>
          {vehicles.map((vehicle, index) => {
            const isSelected = selectedVehicle?.id === vehicle.id;
            const vehicleIcon =
              vehicleIconByIconCategory()[vehicle.vehicleType.iconCategory];
            return (
              <MenuItem
                selected={isSelected}
                sx={{
                  ...styles.menuItem,
                  "&.Mui-selected": {
                    ...styles.selectedMenuItem,
                  },
                }}
                onClick={() => onVehicleSelect(vehicle)}
                key={index}
              >
                <Box ml={1} mr={2} position="relative" top="2px">
                  {vehicleIcon}
                </Box>
                <Typography
                  variant="body2"
                  noWrap
                  {...(isSelected && { style: { fontWeight: 500 } })}
                >
                  {vehicle.name}
                </Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flex={1}
          px={3}
        >
          <Typography variant="body2" align="center">
            You have not created any vehicles yet
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default MoovsMediaVehiclesList;
