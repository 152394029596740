import React from "react";
import first from "lodash/first";

import { Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import RoundChip from "design-system/components/chips/RoundChip";
import { tripGridStatusLabelColors } from "design-system/colors";
import { Route } from "types";

function AffiliateStatusColumn(props: GridRenderCellParams<string>) {
  const { row } = props;

  const route = first(row.routes as Route[]);

  return (
    <Box display="flex" justifyContent="center">
      <RoundChip
        {...tripGridStatusLabelColors[route?.farmeePricing?.paymentStatus]}
      />
    </Box>
  );
}

export default AffiliateStatusColumn;
