import React from "react";
import startCase from "lodash/startCase";

import { Box, Card, CardActionArea, Typography } from "@mui/material";

import { black, granite, grayDark } from "../../../design-system/colors";
import { Contact } from "../../../types";
import MoovsAvatar from "../../../design-system/components/MoovsAvatar";
import OverflowTooltip from "../../../design-system/components/OverflowTooltip";
import { useLaunchDarklyFlags } from "globals/utils/useLaunchDarklyFlags";
import MoovsLimitedChips from "components/MoovsLimitedChips";

function ContactsListItemDesktop({
  contact,
  mode,
  onContactClick,
}: {
  contact: Contact;
  mode?: string;
  onContactClick: (contactId: string) => void;
}) {
  const { enableLinkedPassenger } = useLaunchDarklyFlags();
  const contactName = [contact.firstName, contact.lastName]
    .map((word) => startCase(word))
    .join(" ");

  return (
    <Card variant="outlined" data-testid="contacts-list-items">
      <CardActionArea disableRipple onClick={() => onContactClick(contact.id)}>
        <Box
          display="flex"
          flexDirection="row"
          flex="1"
          py={1.5}
          px={3}
          height="88px"
          alignItems="center"
        >
          {/* Contact Name */}
          <Box display="flex" flex="2" alignItems="center">
            <MoovsAvatar
              alt="contact, person"
              size="medium"
              placeholder={[contact.firstName, contact.lastName]}
            />

            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              ml={2}
            >
              <Typography
                sx={{ fontWeight: 700, color: black, fontSize: 16 }}
                data-testid="contacts-list-contact-name"
              >
                {contactName}
              </Typography>
              {contact.company?.name && (
                <Typography variant="body2" sx={{ color: grayDark }}>
                  {contact.company.name}
                </Typography>
              )}

              {mode === "updateCompanyDrawer" && contact.companyPosition && (
                <Typography variant="body2" sx={{ color: grayDark }}>
                  {contact.companyPosition}
                </Typography>
              )}
            </Box>
          </Box>

          {mode !== "updateCompanyDrawer" && (
            <>
              {/* Email */}
              <Box display="flex" flex="2">
                <Box
                  sx={{ color: granite, maxWidth: "240px" }}
                  data-testid="contacts-list-email"
                >
                  <OverflowTooltip
                    variant="h6"
                    toolTipText={contact.email || "-"}
                    displayText={contact.email || "-"}
                  />
                </Box>
              </Box>

              {/* Linked to */}
              {enableLinkedPassenger && (
                <MoovsLimitedChips
                  label="linked passengers"
                  items={contact?.linkedPassengers}
                  keys={["firstName", "lastName"]}
                />
              )}

              {/* Phone Number */}
              <Box display="flex" flex="1">
                {!!contact.mobilePhone && (
                  <Typography
                    variant="body2"
                    sx={{ color: granite, maxWidth: "240px" }}
                    data-testid="mobile-phone-number"
                  >
                    {contact.mobilePhone}
                  </Typography>
                )}
              </Box>
            </>
          )}
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default ContactsListItemDesktop;
