import React from "react";

import { Box, CircularProgress, Typography } from "@mui/material";

import InfoIconTooltip from "components/globals/InfoIconTooltip";
import { currency } from "globals/utils/helpers";

type AmountAwaitingInfoProps = {
  label: string;
  tooltipText: string;
  loading: boolean;
  amount: number;
};

function AmountAwaitingInfo(props: AmountAwaitingInfoProps) {
  const { amount, label, loading, tooltipText } = props;

  return (
    <Box display="flex" flexDirection="column" minWidth="180px">
      <Box display="flex" alignItems="center">
        <Typography sx={{ my: 1 }} variant="h3">
          {label}
        </Typography>
        <InfoIconTooltip sx={{ p: 1 }} size="large" tooltipText={tooltipText} />
      </Box>
      {loading ? (
        <CircularProgress size={40} sx={{ mb: 1 }} />
      ) : (
        <Typography sx={{ fontWeight: 500, mb: 1 }} variant="h1">
          {currency(amount)}
        </Typography>
      )}
    </Box>
  );
}

export default AmountAwaitingInfo;
