/**
 * @file ContactsList.tsx
 * List of Contact info cards. Used on contactsPage.
 *
 * components:
 *  ContactsList
 */
import React from "react";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

import { Box, CircularProgress, Typography } from "@mui/material";

import { LOAD_CONTACTS_QUERY } from "../../../globals/graphql";
import { LoadContactsConnection } from "../../../types";
import ContactsListItemDesktop from "./ContactsListItem.desktop";
import ContactsListItemMobile from "./ContactsListItem.mobile";
import GQLQueryStatusIndicator from "../../GQLQueryStatusIndicator";
import { useAnalytics, useScreenSize } from "../../../globals/hooks";
import MoovsInfiniteScroll from "components/MoovsInfiniteScroll";

type ContactsListProps = {
  searchTerm: string;
};

function ContactsList(props: ContactsListProps) {
  const { searchTerm } = props;

  // hooks
  const history = useHistory();
  const { isSmallTabletView } = useScreenSize();
  const { track } = useAnalytics();

  // queries
  const {
    data: contactsData,
    error: contactsError,
    loading: contactsLoading,
    fetchMore: contactsFetchMore,
  } = useQuery<{ loadContacts: LoadContactsConnection }>(LOAD_CONTACTS_QUERY, {
    variables: {
      searchTerm,
      limit: 30,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true, // needed to render loading when fetching more
  });

  const contacts = contactsData?.loadContacts?.edges.map(({ node }) => node);

  // event handlers
  const handleContactClick = (contactId: string) => {
    track("contact_opened");
    history.push(`/contacts/update/${contactId}`);
  };

  const handleFetchMore = () => {
    contactsFetchMore({
      variables: {
        cursor: contactsData?.loadContacts?.pageInfo.endCursor,
      },
    });
  };

  if (contactsError) {
    return <GQLQueryStatusIndicator error={contactsError} name="Contacts" />;
  }

  if (contactsLoading && !contactsData) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        flex="1"
        px={3}
        mt={2}
        mb={1}
        justifyContent="center"
        data-testid="contacts-list-item-loading"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      {...(isSmallTabletView && {
        sx: {
          width: "100%",
          overflowX: "auto",
          overflowY: "hidden",
          height: "100%",
        },
      })}
      data-testid="contacts-list"
    >
      {isEmpty(contacts) ? (
        <Box display="flex" justifyContent="center" m={10}>
          <Typography variant="subtitle1">No Contacts Found</Typography>
        </Box>
      ) : (
        <MoovsInfiniteScroll
          loading={contactsLoading}
          data={contactsData.loadContacts}
          next={handleFetchMore}
          name="contacts"
        >
          <>
            {contacts.map((contact) => {
              return (
                <Box
                  key={contact.id}
                  my={1}
                  {...(isSmallTabletView && { mx: 2 })}
                >
                  {isSmallTabletView ? (
                    <ContactsListItemMobile
                      contact={contact}
                      onContactClick={handleContactClick}
                    />
                  ) : (
                    <ContactsListItemDesktop
                      contact={contact}
                      onContactClick={handleContactClick}
                    />
                  )}
                </Box>
              );
            })}
          </>
        </MoovsInfiniteScroll>
      )}
    </Box>
  );
}

export default ContactsList;
