import React from "react";

import { Box, Drawer } from "@mui/material";

import theme, {
  SIDE_NAV_WIDTH,
  SIDE_NAV_WIDTH_DESKTOP,
} from "../../../../theme";
import { white, grayLight } from "../../../colors";
import { useAuth, useScreenSize } from "../../../../globals/hooks";
import SideNavigationContent from "./components/SideNavigationContent";

const styles = {
  drawerPaper: {
    backgroundColor: white,
    width: SIDE_NAV_WIDTH,
  },
  desktopDrawerPaper: {
    paddingTop: "88px",
    width: SIDE_NAV_WIDTH_DESKTOP,
    zIndex: theme.zIndex.drawer - 2,
  },
  paperAnchorDockedLeftOverride: {
    borderRight: `1px solid ${grayLight}`,
  },
};

type SideNavigationProps = {
  mobileOpen: boolean;
  onDrawerToggle: () => void;
};

function SideNavigation(props: SideNavigationProps) {
  const { mobileOpen, onDrawerToggle } = props;

  // hooks
  const { authStage } = useAuth();
  const { isMobileView } = useScreenSize();

  if (authStage !== "authenticated") {
    return null;
  }

  return (
    <Box
      aria-label="moovs navigation panel"
      component="nav"
      sx={{
        width: { sm: SIDE_NAV_WIDTH_DESKTOP },
        height: { sm: "100%" },
        flexShrink: { sm: 0 },
      }}
    >
      {/* mobile view */}
      {isMobileView && (
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={onDrawerToggle}
          sx={{ "& .MuiDrawer-paper": styles.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          <SideNavigationContent
            showCompanyLogo
            onDrawerToggle={onDrawerToggle}
          />
        </Drawer>
      )}

      {/* desktop view */}
      {!isMobileView && (
        <Drawer
          sx={{
            "& .MuiDrawer-paper": {
              ...styles.drawerPaper,
              ...styles.desktopDrawerPaper,
            },
            "& .MuiDrawer-paperAnchorDockedLeft":
              styles.paperAnchorDockedLeftOverride,
          }}
          variant="permanent"
          open
        >
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box height="100%">
              <SideNavigationContent />
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

export default SideNavigation;
