import React, { useState } from "react";

import { FarmedRouteStatusEnum } from "types";
import { grayDark } from "design-system/colors";
import { useAnalytics } from "globals/hooks";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";
import CancelTripIcon from "design-system/icons/actions/CancelTripIcon";

type UseCancelFarmOutClickProps = {
  farmStatus: FarmedRouteStatusEnum;
  isFarmedRouteInvoiced: boolean;
  isFarmee: boolean;
  analyticsName?: string;
};

function useCancelFarmOutClick(
  props: UseCancelFarmOutClickProps
): EllipsisMenuOption & { open: boolean; onClose: () => void } {
  const { farmStatus, isFarmedRouteInvoiced, isFarmee, analyticsName } = props;

  // hooks
  const { track } = useAnalytics();

  // state
  const [cancelFarmOutDialogOpen, setCancelFarmOutDialogOpen] = useState(false);

  // derived state
  const isFarmedOut = farmStatus;
  const cancelFarmOutDisabled =
    !isFarmedOut ||
    isFarmedRouteInvoiced ||
    farmStatus === FarmedRouteStatusEnum.Completed;

  // event handlers
  const handleCancelFarmOutClick = () => {
    if (analyticsName) track(analyticsName);
    setCancelFarmOutDialogOpen(true);
  };

  const handleCancelFarmOutDialogClose = () => {
    setCancelFarmOutDialogOpen(false);
  };

  return {
    text: "Cancel Farm-Out",
    icon: <CancelTripIcon color={grayDark} size="small" />,
    onClick: handleCancelFarmOutClick,
    disableOption: cancelFarmOutDisabled,
    hideOption: isFarmee,
    tooltip: cancelFarmOutDisabled
      ? {
          titleText: !isFarmedOut
            ? "Trip is not farmed out"
            : isFarmedRouteInvoiced
            ? "You cannot cancel a farm-out on a trip with an active invoice."
            : "You cannot cancel a farm-out that has been completed.",
        }
      : undefined,

    // dialog state
    open: cancelFarmOutDialogOpen,
    onClose: handleCancelFarmOutDialogClose,
  };
}

export { useCancelFarmOutClick };
