import React from "react";
import first from "lodash/first";

import { Box, Paper, Typography } from "@mui/material";

import { EstimationIcon } from "design-system/icons";
import { Stop } from "types";
import FlightChangeDisplay from "pages/requests/RequestOverview/ReservationOverview/TripItineraryBlock/components/FlightChangeDisplay";

type TripTrackerMobileBlockProps = {
  title: string;
  customerData: string | number;
  driverData: string;
  farmeeData: string;
  dropOffIsEstimate?: boolean | null;
  isFarmedRoute: boolean;
  stops?: Stop[];
};

const styles = {
  rowTitle: {
    fontWeight: 600,
  },
  columnTitle: {
    fontWeight: 500,
  },
};

const TripTrackerMobileBlock = (props: TripTrackerMobileBlockProps) => {
  const {
    title,
    customerData,
    driverData,
    farmeeData,
    dropOffIsEstimate = null,
    isFarmedRoute,
    stops,
  } = props;
  return (
    <Paper variant="outlined" style={{ marginBottom: "8px", marginTop: "8px" }}>
      <Box p={2}>
        <Box mb={1}>
          <Typography variant="subtitle1" sx={styles.rowTitle}>
            {title}
          </Typography>
        </Box>
        {/* CUSTOMER */}
        <Box display="flex" flexDirection="column" mb={1}>
          <Typography variant="overline" sx={styles.columnTitle}>
            Customer
          </Typography>
          {dropOffIsEstimate ? (
            <Box display="flex" alignItems="center">
              <Box mr={0.5}>
                <Typography variant="caption">{customerData}</Typography>
              </Box>
              <EstimationIcon size="small" />
            </Box>
          ) : (
            <Box display="flex">
              <Typography variant="caption">{customerData}</Typography>
              <Box ml={0.5}>
                <FlightChangeDisplay
                  flightOffset={first(stops)?.flightOffset}
                  originalDateTime={first(stops)?.originalDateTime}
                  dateTime={first(stops)?.dateTime}
                  iconSize="small"
                />
              </Box>
            </Box>
          )}
        </Box>
        {/* DRIVER */}
        <Box display="flex" flexDirection="column" mb={1}>
          <Typography variant="overline" sx={styles.columnTitle}>
            Driver
          </Typography>
          <Typography variant="caption">{driverData}</Typography>
        </Box>
        {/* AFFILIATE */}
        {isFarmedRoute && (
          <Box display="flex" flexDirection="column">
            <Typography variant="overline" sx={styles.columnTitle}>
              Affiliate
            </Typography>
            <Typography variant="caption">{farmeeData}</Typography>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default TripTrackerMobileBlock;
