import { useMutation } from "@apollo/client";
import map from "lodash/map";
import React, { useState } from "react";
import theme from "theme";

import {
  SelectChangeEvent,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";

import { CheckIcon } from "design-system/icons";
import { UPDATE_OPERATOR_SETTINGS } from "globals/graphql";
import { useAnalytics, useOperator } from "globals/hooks";
import { PlanVariant } from "types";
import UpgradePlanButton from "../../../../UpgradePlan";
import { colorThemeOptions } from "./utils/colorThemeOptions";

type CustomerPortalColorThemeProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function CustomerPortalColorTheme(props: CustomerPortalColorThemeProps) {
  const { setSaveIndicatorState } = props;

  // hooks
  const operator = useOperator();
  const { track } = useAnalytics();

  // derived state
  const isProOrVIP =
    operator.plan === PlanVariant.Pro || operator.plan === PlanVariant.Vip;
  const operatorSelectedColor: { label: string; value: string; color: string } =
    colorThemeOptions[operator.settings?.colorTheme] || colorThemeOptions.MOOVS;

  // state
  const [selectedColor, setSelectedColor] = useState(operatorSelectedColor);

  // mutation
  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError() {
      setSaveIndicatorState("error");
      setSelectedColor(operatorSelectedColor);
    },
  });

  // event handlers
  const handleSelectedColorChange = (event: SelectChangeEvent<string>) => {
    track("bookingToolEnhancements_colorSelected");

    const colorOption = isProOrVIP
      ? colorThemeOptions[event.target.value]
      : colorThemeOptions.MOOVS;
    setSelectedColor(colorOption);

    if (isProOrVIP) {
      setSaveIndicatorState("loading");
      updateOperatorSettings({
        variables: {
          input: {
            colorTheme: colorOption.value,
          },
        },
      });
    }
  };

  const trackOpen = () => {
    track("bookingToolEnhancements_colorOpened");
  };

  return (
    <>
      <Box mt={4} mb={2} display="flex">
        <Box flex="2">
          <Typography variant="h3">Customer Portal Color Theme</Typography>
        </Box>
        <UpgradePlanButton />
      </Box>

      <Box mt={1}>
        <FormControl fullWidth sx={{ maxWidth: "370px" }}>
          <InputLabel id="test-select-label">Color</InputLabel>
          <Select
            fullWidth
            label="Label"
            labelId="test-select-label"
            value={selectedColor.value}
            onOpen={trackOpen}
            onChange={handleSelectedColorChange}
            renderValue={() => (
              <Box display="flex" alignItems="center">
                <Box
                  bgcolor={selectedColor.color}
                  borderRadius={1}
                  width="16px"
                  height="16px"
                />
                <Typography ml={2}>{selectedColor.label}</Typography>
              </Box>
            )}
          >
            {map(colorThemeOptions, (option) => {
              const isSelected = selectedColor.value === option.value;
              const isDisabled =
                !isProOrVIP && option.value !== colorThemeOptions.MOOVS.value;
              return (
                <MenuItem
                  value={option.value}
                  disabled={isDisabled}
                  style={isDisabled ? { pointerEvents: "auto" } : null}
                >
                  <Tooltip
                    title={isDisabled ? "Must upgrade plan to Pro Package" : ""}
                    placement="top"
                  >
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                    >
                      <Box display="flex" alignItems="center">
                        <Box
                          bgcolor={option.color}
                          borderRadius={1}
                          width="16px"
                          height="16px"
                        />
                        <Typography ml={2}>{option.label}</Typography>
                      </Box>

                      <Box
                        display="flex"
                        maxHeight="24px"
                        maxWidth="24px"
                        alignItems="center"
                      >
                        {isSelected && (
                          <CheckIcon
                            color={theme.palette.primary.main}
                            size="small"
                          />
                        )}
                      </Box>
                    </Box>
                  </Tooltip>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </>
  );
}

export default CustomerPortalColorTheme;
