import React, { useState } from "react";

import { Box } from "@mui/material";

import {
  errorRed,
  grayMedium,
  granite,
  white,
} from "../../../design-system/colors";
import {
  PDFIcon,
  VideoIcon,
  CrossIcon,
  WarningTriangleIcon,
} from "../../../design-system/icons";
import { MediaFileType, MediaVariantEnum, getMediaVariant } from "../utils";
import MediaMessageLightBox from "./MediaMessageLightBox";

type MediaPreviewProps = {
  media: MediaFileType;
  onRemoveMedia: () => void;
};

function MediaPreview(props: MediaPreviewProps) {
  const { media, onRemoveMedia } = props;

  const [openLightBox, setOpenLightBox] = useState(false);

  const { isInvalid } = media;
  const mediaVariant = getMediaVariant(media.type);

  const fileURL = URL.createObjectURL(media);

  const filePreviewIcon = () => {
    switch (mediaVariant) {
      case MediaVariantEnum.Image:
        return (
          <img
            alt="media"
            style={{
              width: "38px",
              height: "38px",
              objectFit: "cover",
              borderRadius: "2px",
            }}
            src={fileURL}
          />
        );
      case MediaVariantEnum.PDF:
        return <PDFIcon color={granite} />;
      case MediaVariantEnum.Video:
        return <VideoIcon color={granite} />;
      default:
        return <WarningTriangleIcon color={errorRed} />;
    }
  };

  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor={grayMedium}
      width="38px"
      height="38px"
      borderRadius="2px"
      {...(isInvalid && {
        boxShadow: `0 0 0 2px ${errorRed}`,
      })}
      {...(mediaVariant !== MediaVariantEnum.PDF && {
        onClick: () => setOpenLightBox(true),
        style: { cursor: "pointer" },
      })}
    >
      {filePreviewIcon()}
      <MediaMessageLightBox
        mediaVariant={mediaVariant}
        url={fileURL}
        open={openLightBox}
        onDialogClose={(e) => {
          e.stopPropagation();
          setOpenLightBox(false);
        }}
      />

      {/* delete cross icon */}
      <Box
        position="absolute"
        top={-8}
        right={-8}
        bgcolor={isInvalid ? errorRed : granite}
        width={16}
        height={16}
        borderRadius="50%"
        border={`0.66px solid ${isInvalid ? errorRed : white}`}
        style={{ cursor: "pointer" }}
        onClick={onRemoveMedia}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CrossIcon width="12" height="12" color={white} />
      </Box>
    </Box>
  );
}

export default MediaPreview;
