import { gql } from "@apollo/client";

export const LOAD_GOOGLE_CALENDAR_AUTH_QUERY = gql`
  query LoadGoogleCalendarAuth {
    loadGoogleCalendarAuth {
      id
      emailAddress
    }
  }
`;

export const DISCONNECT_GOOGLE_CALENDAR_AUTH_MUTATION = gql`
  mutation disconnectGoogleCalendarAuth(
    $input: DisconnectGoogleCalendarAuthInput!
  ) {
    disconnectGoogleCalendarAuth(input: $input) {
      __typename
    }
  }
`;
