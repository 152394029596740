import React, { useState } from "react";
import moment from "moment";
import { useQuery } from "@apollo/client";

import { Box, CircularProgress, Container, Typography } from "@mui/material";

import { alabaster } from "design-system/colors";
import { useScreenSize, useSnackbar } from "globals/hooks";
import { LOAD_DRIVER_PLATFORM_REPORT_QUERY } from "globals/graphql";
import {
  DriverPlatformReportContent,
  DriverPlatformReportDateWindow,
  DriverPlatformReportNoDataForCurrentPeriod,
} from "./components";

function DriverPlatformReport() {
  // hooks
  const { isMobileView } = useScreenSize();
  const snackbar = useSnackbar();

  // state
  const [dateRange, setDateRange] = useState(null);
  const [datePeriodLabel, setDatePeriodLabel] = useState(moment(new Date()));

  // query
  const { data: reportData, loading: reportLoading } = useQuery(
    LOAD_DRIVER_PLATFORM_REPORT_QUERY,
    {
      variables: {
        startDate:
          dateRange?.startDate ||
          moment(new Date()).startOf("month").format("YYYY-MM-DD"),
        endDate:
          dateRange?.endDate ||
          moment(new Date()).endOf("month").format("YYYY-MM-DD"),
      },
      fetchPolicy: "network-only",
      onError: () => {
        snackbar.error();
      },
    }
  );

  // derived states
  const operatorTotalTrips =
    reportData?.loadDriverPlatformReport?.operatorTotalTrips;
  const drivers = reportData?.loadDriverPlatformReport?.drivers;
  const noTripsInCurrentPeriod = operatorTotalTrips === 0;

  return (
    <Container sx={{ backgroundColor: alabaster }} maxWidth="xl">
      <Container
        sx={{
          backgroundColor: alabaster,
          padding: isMobileView ? "24px 16px" : "48px",
          marginTop: isMobileView ? "24px" : "48px",
          height: "514px",
        }}
        maxWidth="md"
      >
        {/* Report header */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems={isMobileView ? "left" : "center"}
          flexDirection={isMobileView ? "column" : "row"}
          mb={3}
        >
          <Typography variant="h3">Report</Typography>

          {/* Date window navigator */}
          <DriverPlatformReportDateWindow
            setDateRange={setDateRange}
            loading={reportLoading}
            datePeriodLabel={datePeriodLabel}
            setDatePeriodLabel={setDatePeriodLabel}
          />
        </Box>

        {reportLoading && (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}

        {/* Report content - when no data available for current period */}
        {noTripsInCurrentPeriod && !reportLoading && (
          <DriverPlatformReportNoDataForCurrentPeriod
            datePeriodLabel={datePeriodLabel}
          />
        )}

        {/* Report content - when  data available for current period */}
        {!noTripsInCurrentPeriod && !reportLoading && (
          <DriverPlatformReportContent
            operatorTotalTrips={operatorTotalTrips}
            drivers={drivers}
          />
        )}
      </Container>
    </Container>
  );
}

export default DriverPlatformReport;
