import startCase from "lodash/startCase";
import React from "react";

import {
  Box,
  FormControl,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import VehicleFormSection from "components/vehicles/VehicleFormSection";
import { CancellationPolicy, InsurancePolicy } from "types";
import { CreateVehicle } from "../../types";

type OptionalInfoProps = {
  vehicle: CreateVehicle;
  setVehicle: React.Dispatch<React.SetStateAction<CreateVehicle>>;
  cancellationPolicies: CancellationPolicy[];
  insurancePolicies: InsurancePolicy[];
};

function OptionalInfo(props: OptionalInfoProps) {
  const { vehicle, setVehicle, cancellationPolicies, insurancePolicies } =
    props;

  // event handler
  const handleOptionalInfoChange = (e) => {
    const { value, name } = e.target;

    setVehicle({ ...vehicle, [name]: value });
  };

  return (
    <VehicleFormSection title="Optional Info">
      <Box my="8px">
        <TextField
          fullWidth
          id="license-plate"
          label="License Plate #"
          variant="outlined"
          name="licensePlate"
          inputProps={{ maxLength: 8 }}
          value={vehicle.licensePlate}
          onChange={handleOptionalInfoChange}
        />
      </Box>
      <Box my="8px">
        <TextField
          fullWidth
          multiline
          rows={4}
          id="description"
          label="Description"
          variant="outlined"
          name="description"
          value={vehicle.description || ""}
          onChange={handleOptionalInfoChange}
        />
      </Box>

      <Box my="8px">
        <TextField
          fullWidth
          id="exteriorColor"
          name="exteriorColor"
          label="Exterior Color"
          variant="outlined"
          value={vehicle.exteriorColor}
          onChange={handleOptionalInfoChange}
        />
      </Box>
      <Box my="8px">
        <TextField
          fullWidth
          id="vinNumber"
          name="vinNumber"
          label="VIN Number"
          variant="outlined"
          inputProps={{ maxLength: 17 }}
          value={vehicle.vinNumber}
          onChange={handleOptionalInfoChange}
        />
      </Box>
      {cancellationPolicies && (
        <Box my="8px">
          <FormControl fullWidth variant="outlined">
            <TextField
              select
              variant="outlined"
              label="Cancellation Policy"
              name="cancellationPolicyId"
              value={vehicle.cancellationPolicyId}
              onChange={handleOptionalInfoChange}
            >
              {cancellationPolicies.map(
                (cancellationPolicy: CancellationPolicy) => (
                  <MenuItem
                    key={cancellationPolicy.id}
                    value={cancellationPolicy.id}
                  >
                    {startCase(cancellationPolicy.variant.toLowerCase())}
                  </MenuItem>
                )
              )}
            </TextField>
          </FormControl>
        </Box>
      )}
      {insurancePolicies && (
        <Box my="8px">
          <FormControl fullWidth variant="outlined">
            <TextField
              select
              variant="outlined"
              label="Insurance Policy"
              name="insuranceId"
              value={vehicle.insuranceId}
              onChange={handleOptionalInfoChange}
            >
              {insurancePolicies.map((insurancePolicy: InsurancePolicy) => (
                <MenuItem key={insurancePolicy.id} value={insurancePolicy.id}>
                  <Box>
                    {insurancePolicy.insuranceCompany}
                    <Typography style={{ display: "block" }} variant="caption">
                      {insurancePolicy.policyNumber}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Box>
      )}
    </VehicleFormSection>
  );
}

export default OptionalInfo;
