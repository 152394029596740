import React from "react";

import { Box, CircularProgress, Divider, Typography } from "@mui/material";

import ActionButton from "design-system/components/ActionButton";
import { CopyIcon, OpenIcon, DownloadIcon } from "design-system/icons";
import { black, grayMedium, moovsBlue } from "design-system/colors";

export type DialogItemProps = {
  label: string;
  icon: (props: { color?: string }) => JSX.Element;
  onClickPrimary: () => void;
  onClickOpen: (e: React.MouseEvent) => void;
  disabled?: boolean;
  disableTooltipText?: string;
  primaryAction: "Download" | "Copy";
  isLoading?: boolean;
};

function DialogItemMobile(props: DialogItemProps) {
  const {
    label,
    icon,
    onClickPrimary,
    onClickOpen,
    disabled,
    disableTooltipText,
    primaryAction,
    isLoading,
  } = props;

  const Icon = icon;

  const createButton = ({ label, icon, onClick, isLoading }) => {
    const ActionIcon = icon;

    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <ActionButton
          minWidth={60}
          label={label}
          icon={() =>
            isLoading ? (
              <CircularProgress size={20} />
            ) : (
              <ActionIcon
                size="small"
                color={disabled ? grayMedium : moovsBlue}
              />
            )
          }
          onClick={onClick}
          disabled={disabled || isLoading}
          disableTooltipText={disableTooltipText}
        />
      </Box>
    );
  };

  const openButton = createButton({
    label: "Open",
    icon: OpenIcon,
    onClick: onClickOpen,
    isLoading,
  });
  const copyButton = createButton({
    label: "Copy",
    icon: CopyIcon,
    onClick: onClickPrimary,
    isLoading,
  });
  const downloadButton = createButton({
    label: "Download",
    icon: DownloadIcon,
    onClick: onClickPrimary,
    isLoading,
  });

  const actionButtons =
    primaryAction === "Copy" ? (
      <Box display="flex">
        {copyButton}
        {openButton}
      </Box>
    ) : primaryAction === "Download" ? (
      <Box display="flex">{downloadButton}</Box>
    ) : null;

  return (
    <Box width="100%">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
        mb={2}
      >
        <Box display="flex" alignItems="center">
          <Icon color={black} />
          <Typography ml={1} variant="button">
            {label}
          </Typography>
        </Box>
        {actionButtons}
      </Box>
      <Divider />
    </Box>
  );
}

export default DialogItemMobile;
