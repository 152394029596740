import React from "react";
import { useFormContext } from "react-hook-form";

import { Box, Typography } from "@mui/material";

import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";
import { PaidNotPaid } from "types";
import { useScreenSize } from "globals/hooks";

import { FilterProps } from "../FilterDispatchTripsDrawer";

function PaidStatusToggle(props: FilterProps) {
  const { disabledSelect } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const { watch, setValue } = useFormContext();

  const paidStatusToggleButtons = [
    {
      value: null,
      label: "All",
    },
    {
      value: PaidNotPaid.Paid,
      label: "Paid",
    },
    {
      value: PaidNotPaid.NotPaid,
      label: "Not Paid",
    },
  ];

  return (
    <Box mb={2}>
      <Typography variant="h3" mb={2}>
        Paid Status
      </Typography>

      <MoovsToggleButtonGroup
        onChange={(_, newValue) => setValue("paidStatus", newValue)}
        options={paidStatusToggleButtons}
        value={watch("paidStatus")}
        size="large"
        fullWidth={isMobileView}
        disabled={disabledSelect}
        tooltipText="Upgrade plan to Pro in order to use filtering feature"
      />
    </Box>
  );
}

export default PaidStatusToggle;
