import React from "react";
import { useMutation } from "@apollo/client";

import { Box, Button, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useAnalytics, useScreenSize, useSnackbar } from "globals/hooks";
import { UPDATE_OPERATOR_REVIEW_SITE_SETTINGS } from "globals/graphql";
import { errorRed } from "design-system/colors";

type ModifyReviewPlatformButtonsProps = {
  onModifyReviewPlatformDialogOpen: () => void;
};

function ModifyReviewPlatformButtons(props: ModifyReviewPlatformButtonsProps) {
  const { onModifyReviewPlatformDialogOpen } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();
  const snackbar = useSnackbar();

  // mutations
  const [updateOperatorReviewSiteSettings, { loading: removePlatformLoading }] =
    useMutation(UPDATE_OPERATOR_REVIEW_SITE_SETTINGS, {
      onError() {
        snackbar.error("Error removing review platform");
      },
      onCompleted() {
        track("reviewPlatform_removed");
      },
    });

  // event handlers
  const handleRemoveReviewPlatformClick = () => {
    updateOperatorReviewSiteSettings({
      variables: {
        input: {
          reviewSite: null,
          reviewSiteId: null,
        },
      },
    });
  };

  return (
    <>
      {isMobileView && <Divider sx={{ mt: 2, mb: 1 }} />}
      <Box display="flex" justifyContent="space-evenly">
        <Button disableRipple onClick={onModifyReviewPlatformDialogOpen}>
          Modify Review Platform
        </Button>
        <LoadingButton
          disableRipple
          loading={removePlatformLoading}
          sx={{ ml: 3, color: errorRed }}
          onClick={handleRemoveReviewPlatformClick}
        >
          Remove
        </LoadingButton>
      </Box>
    </>
  );
}

export default ModifyReviewPlatformButtons;
