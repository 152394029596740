import React from "react";

type InvoiceIconProps = {
  color?: string;
  size?: "small";
};

const InvoiceIcon = (props: InvoiceIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.7775 5.77648V2.22092C13.7775 1.98517 13.6838 1.75908 13.5171 1.59238C13.3504 1.42568 13.1243 1.33203 12.8886 1.33203H2.2219C1.98615 1.33203 1.76006 1.42568 1.59336 1.59238C1.42666 1.75908 1.33301 1.98517 1.33301 2.22092V11.9987C1.33301 12.2344 1.42666 12.4605 1.59336 12.6272C1.76006 12.7939 1.98615 12.8876 2.2219 12.8876H5.77745M1.33301 3.9987H13.7775M11.9997 9.33203H10.7949C10.61 9.33182 10.4308 9.39604 10.2882 9.51364C10.1455 9.63125 10.0483 9.7949 10.0132 9.97644C9.97812 10.158 10.0074 10.3461 10.096 10.5084C10.1846 10.6706 10.327 10.797 10.4986 10.8657L11.7217 11.3546C11.893 11.4235 12.035 11.5498 12.1233 11.712C12.2116 11.8741 12.2407 12.062 12.2057 12.2432C12.1707 12.4245 12.0736 12.588 11.9313 12.7055C11.7889 12.8231 11.6101 12.8875 11.4255 12.8876H10.2219M11.1108 9.33203V8.88759M11.1108 13.332V12.8876M14.6663 11.1098C14.6663 13.0735 13.0745 14.6654 11.1108 14.6654C9.14711 14.6654 7.55523 13.0735 7.55523 11.1098C7.55523 9.14613 9.14711 7.55425 11.1108 7.55425C13.0745 7.55425 14.6663 9.14613 14.6663 11.1098Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6667 8.66667V3.33333C20.6667 2.97971 20.5262 2.64057 20.2761 2.39052C20.0261 2.14048 19.687 2 19.3333 2H3.33333C2.97971 2 2.64057 2.14048 2.39052 2.39052C2.14048 2.64057 2 2.97971 2 3.33333V18C2 18.3536 2.14048 18.6928 2.39052 18.9428C2.64057 19.1929 2.97971 19.3333 3.33333 19.3333H8.66667M2 6H20.6667M18 14H16.1929C15.9155 13.9997 15.6468 14.096 15.4327 14.2724C15.2187 14.4488 15.0729 14.6943 15.0203 14.9666C14.9677 15.2389 15.0116 15.521 15.1445 15.7645C15.2774 16.0079 15.4909 16.1974 15.7484 16.3004L17.5831 17.0338C17.84 17.1372 18.053 17.3267 18.1855 17.5699C18.3179 17.8132 18.3616 18.0949 18.309 18.3668C18.2565 18.6387 18.111 18.8839 17.8974 19.0602C17.6839 19.2366 17.4156 19.3331 17.1387 19.3333H15.3333M16.6667 14V13.3333M16.6667 20V19.3333M22 16.6667C22 19.6122 19.6122 22 16.6667 22C13.7211 22 11.3333 19.6122 11.3333 16.6667C11.3333 13.7211 13.7211 11.3333 16.6667 11.3333C19.6122 11.3333 22 13.7211 22 16.6667Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InvoiceIcon;
