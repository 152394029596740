import React from "react";
import { Control, FieldErrors, UseFormWatch } from "react-hook-form";

import { Box, Typography } from "@mui/material";

import { EditPromoCodeValues } from "../useEditPromoCodeDefaultValues";
import RHFToggleButtonsAlternative from "components/reactHookFormInputs/RHFToggleButtonsAlternative";
import { useScreenSize } from "globals/hooks";

type ActiveProps = {
  control: Control<EditPromoCodeValues>;
  errors: FieldErrors;
  watch: UseFormWatch<EditPromoCodeValues>;
};

function Active(props: ActiveProps) {
  const { control } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Box mx={isMobileView ? 2 : 0} mb={isMobileView ? 8 : 6}>
      <Box display="flex" alignItems="center" mb={1}>
        <Typography variant="h4">Status</Typography>
      </Box>
      <RHFToggleButtonsAlternative
        name="active"
        control={control}
        width={isMobileView ? "100%" : "196px"}
        toggleButtons={[
          {
            ariaLabel: "active",
            value: true,
            text: "Active",
          },
          {
            ariaLabel: "inactive",
            value: false,
            text: "Inactive",
          },
        ]}
      />
    </Box>
  );
}

export default Active;
