/**
 * @file useCompany.ts
 * Hook that abstracts Company query.
 */

import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { LOAD_COMPANY_QUERY } from "globals/graphql";
import { Company } from "types";

function useCompany() {
  // hooks
  const { companyId } = useParams<{ companyId: string }>();

  // queries
  const { data, loading, refetch, error } = useQuery<{ node: Company }>(
    LOAD_COMPANY_QUERY,
    {
      variables: {
        id: companyId,
      },
      skip: !companyId,
      fetchPolicy: "cache-and-network",
    }
  );

  const { paymentMethods } = data?.node || {};

  // sort payment methods by stripe status
  const paymentMethodPriority = { succeeded: 1, pending: 2, rejected: 3 };

  const sortedPaymentMethods = [...(paymentMethods || [])].sort((a, b) =>
    paymentMethodPriority[a.stripeStatus] >
    paymentMethodPriority[b.stripeStatus]
      ? 1
      : -1
  );

  return {
    company: data?.node,
    paymentMethods: sortedPaymentMethods,
    companyLoading: loading,
    companyError: error,
    companyRefetch: refetch,
  };
}

export { useCompany };
