import { useQuery } from "@apollo/client";
import {
  LOAD_DISPATCH_LOG_QUERY,
  LOAD_ROUTE_STATUS_LOGS_QUERY,
} from "globals/graphql";

import { fromRouteIdToOperatorRouteId } from "globals/utils/helpers";
import { Route, RouteStatusLog } from "types";

type useLoadDispatchLogProps = {
  routeId: string;
  open: boolean;
};

function useLoadDispatchLog(props: useLoadDispatchLogProps) {
  const { routeId, open } = props;

  const shouldSkipQuery = !routeId;

  const { data: routeData, loading: loadingRoute } = useQuery(
    LOAD_DISPATCH_LOG_QUERY,
    {
      variables: {
        id: routeId,
      },
      fetchPolicy: "cache-and-network",
      skip: shouldSkipQuery,
      ...(!shouldSkipQuery && { pollInterval: open ? 10 * 1000 : 0 }), // every 10 seconds
    }
  );

  const { data: statusLogsData, loading: loadingStatusLogs } = useQuery(
    LOAD_ROUTE_STATUS_LOGS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        operatorRouteId: fromRouteIdToOperatorRouteId(routeId),
      },
      skip: shouldSkipQuery,
      ...(!shouldSkipQuery && { pollInterval: open ? 10 * 1000 : 0 }), // every 10 seconds
    }
  );

  const route: Route = routeData?.node;
  const routeStatusLogs: RouteStatusLog[] = statusLogsData?.routeStatusLogs;

  return {
    route,
    routeStatusLogs,
    loading: loadingRoute || loadingStatusLogs,
  };
}

export default useLoadDispatchLog;
