import React, { useMemo } from "react";
import size from "lodash/size";

import HorizontalBarChart from "./Charts/HorizontalBarChart";

type DateReservationData = {
  totalAmount: number;
  amountPaid: number;
  amountDue: number;
};

type DashboardChartGeneratorType = {
  barChartData: any;
};

function DashboardChartGenerator(props: DashboardChartGeneratorType) {
  const { barChartData } = props;

  const barChartRenderData = useMemo(() => {
    if (!barChartData) return null;

    const labels = Object.keys(barChartData);
    const totalAmountData = Object.values(barChartData).map(
      (dateReservationData: DateReservationData) =>
        dateReservationData.totalAmount / 100
    );
    const amountPaidData = Object.values(barChartData).map(
      (dateReservationData: DateReservationData) =>
        dateReservationData.amountPaid / 100
    );
    const amountDueData = Object.values(barChartData).map(
      (dateReservationData: DateReservationData) =>
        dateReservationData.amountDue / 100
    );

    return {
      labels,
      datasets: [
        {
          label: "Amount Paid",
          backgroundColor: "#28B668",
          data: amountPaidData,
          maxBarThickness: 64,
        },
        {
          label: "Amount Due",
          backgroundColor: "#D0ECDD",
          data: amountDueData,
          maxBarThickness: 64,
          datalabels: totalAmountData,
        },
      ],
      dataSize: size(totalAmountData),
    };
  }, [barChartData]);

  // @ts-ignore fix later
  return <HorizontalBarChart barChartRenderData={barChartRenderData} />;
}

export default DashboardChartGenerator;
