import React from "react";

import { Box, Typography } from "@mui/material";

import DynamicPricingItem from "./DynamicPricingItem";
import { DynamicPricingRule } from "types";

type DynamicPricingItemListProps = {
  header: string;
  rules: DynamicPricingRule[];
};

function DynamicPricingList(props: DynamicPricingItemListProps) {
  const { rules, header } = props;

  return (
    <Box>
      <Box mt={4}>
        <Typography variant="h3">{header}</Typography>
      </Box>
      {rules.map((rule) => (
        <DynamicPricingItem key={rule.id} rule={rule} />
      ))}
    </Box>
  );
}
export default DynamicPricingList;
