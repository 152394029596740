import moment from "moment";

import { CreatePromoCodeInputType } from "./types";
import { CreatePromoCodeInput } from "types";

type FinalizeCreatePromoCodeInputProps = {
  createPromoCodeInputs: CreatePromoCodeInputType;
  workflowOption: string;
};

export const finalizeCreatePromoCodeInput = (
  props: FinalizeCreatePromoCodeInputProps
) => {
  const { createPromoCodeInputs, workflowOption } = props;
  const validatedInput = createPromoCodeInputs[workflowOption];

  const {
    isPercent,
    amount,
    name,
    isTotalLimited,
    isBookingContactLimited,
    totalLimit,
    bookingContactLimit,
    bookingStartDate,
    bookingEndDate,
    tripStartDate,
    tripEndDate,
  } = validatedInput;

  const finalData: CreatePromoCodeInput = { promoCodeName: name };

  return finalizeSharedPromoCodeAttributes({
    finalData,
    isPercent,
    amount,
    isTotalLimited,
    isBookingContactLimited,
    totalLimit,
    bookingContactLimit,
    bookingStartDate,
    bookingEndDate,
    tripStartDate,
    tripEndDate,
  });
};

export const finalizeSharedPromoCodeAttributes = ({
  finalData,
  isPercent,
  amount,
  isTotalLimited,
  isBookingContactLimited,
  totalLimit,
  bookingContactLimit,
  bookingStartDate,
  bookingEndDate,
  tripStartDate,
  tripEndDate,
}) => {
  if (isPercent) {
    // The server accepts promoCodePercent as a decimal. I.e. 12.34% should be sent in the request as 0.1234
    finalData.promoCodePercent = Number((amount / 100).toFixed(4));
  } else {
    // 'CreatePromoCode' mutation accepts promoCodeAmt input in dollars
    finalData.promoCodeAmt = Number(amount);
  }

  if (isTotalLimited) {
    finalData.totalLimit = Number(totalLimit);
  }
  if (isBookingContactLimited) {
    finalData.bookingContactLimit = Number(bookingContactLimit);
  }

  // remove timezones
  if (bookingStartDate && bookingEndDate) {
    finalData.bookingStartDate = moment(bookingStartDate).utc(true);
    finalData.bookingEndDate = moment(bookingEndDate).utc(true);
  }
  if (tripStartDate && tripEndDate) {
    finalData.tripStartDate = moment(tripStartDate).utc(true);
    finalData.tripEndDate = moment(tripEndDate).utc(true);
  }

  return { finalData };
};
