import React from "react";

import { RouteDispatchLogStatusEnum } from "types";
import ArrivedChip from "./components/ArrivedChip";
import EnRouteChip from "./components/EnRouteChip";

type DispatchTimeChipProps = {
  stopLabel: string;
  routeDispatchLogStatus: RouteDispatchLogStatusEnum;
  driverEta: Date;
  pickUpTime: Date;
  arrivalStopTime: Date;
};

const { Arrived, EnRoute } = RouteDispatchLogStatusEnum;

function DispatchTimeChip(props: DispatchTimeChipProps) {
  const {
    pickUpTime,
    stopLabel,
    arrivalStopTime,
    routeDispatchLogStatus,
    driverEta,
  } = props;

  if (routeDispatchLogStatus === Arrived) {
    return <ArrivedChip arrivedAt={arrivalStopTime} />;
  }

  if (routeDispatchLogStatus === EnRoute) {
    return (
      <EnRouteChip
        driverEta={driverEta}
        pickUpTime={pickUpTime}
        isPickUp={stopLabel === "Pick-Up"}
      />
    );
  }

  return null;
}

export { DispatchTimeChip };
