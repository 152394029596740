import React from "react";

import { Button } from "@mui/material";

import { useAnalytics } from "globals/hooks";

function CancelSubscriptionButton() {
  const { track } = useAnalytics();

  const trackClick = () => {
    track("subscription_cancelInitiated");
  };

  return (
    <Button
      disableRipple
      color="error"
      id="fab-help"
      sx={{
        height: 40,
        my: 2,
        px: 0,
        "&:hover": { bgcolor: "transparent" },
      }}
      onClick={trackClick}
    >
      Cancel Subscription
    </Button>
  );
}

export default CancelSubscriptionButton;
