import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import first from "lodash/first";

import { Box, Button, Typography } from "@mui/material";

import {
  grayDark,
  white,
  warningYellow,
  black,
  grayMedium,
} from "design-system/colors";
import { StarIcon } from "design-system/icons";
import { FiveStarReviewBanner, StarsRating } from "./components";
import { TripReview } from "types";

type CustomerReviewListItemProps = {
  key: string;
  tripReviewData: TripReview;
};

function CustomerReviewListItem(props: CustomerReviewListItemProps) {
  const { key, tripReviewData } = props;
  const { rating, createdAt, body, trip } = tripReviewData;

  const confNo = `${trip.request.orderNumber}-${trip.tripNumber}`;

  // hook
  const history = useHistory();

  // event handler
  const handleConfirmationClick = () => {
    history.push(`/reservations/${trip.request.id}?tripId=${trip.id}`);
  };

  return (
    <Box display="flex" justifyContent="space-between" key={key}>
      {/* Left Section */}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="30%"
        mr={0.5}
      >
        <Button
          sx={{
            display: "flex",
            justifyContent: "start",
            width: "100px",
            paddingBottom: 1,
            paddingLeft: 0,
          }}
          onClick={handleConfirmationClick}
        >
          <Typography variant="subtitle2">{confNo}</Typography>
        </Button>
        <Typography variant="caption" mb={1}>
          {moment(first(trip.stops).dateTime).format("M-DD-YY")}
        </Typography>
        <StarsRating
          readOnly
          stars={rating}
          emptyStarIcon={
            <StarIcon size="small" color={grayDark} fillColor={white} />
          }
          filledStarIcon={
            <StarIcon
              size="small"
              color={warningYellow}
              fillColor={warningYellow}
            />
          }
        />
      </Box>
      {/* Right Section */}
      <Box width="70%">
        <Box mb={1.5}>
          {rating === 5 ? (
            <FiveStarReviewBanner />
          ) : (
            <Typography sx={{ color: body ? black : grayMedium }}>
              {body || "No Comments"}
            </Typography>
          )}
        </Box>

        <Typography variant="caption" sx={{ color: grayDark, marginTop: 2 }}>
          Reviewed on {moment(createdAt).format("MMM D, YYYY")}
        </Typography>
      </Box>
    </Box>
  );
}

export default CustomerReviewListItem;
