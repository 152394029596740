import { gql } from "@apollo/client";

export const LOAD_DASHBOARD_QUERY = gql`
  query Dashboard($startDate: DateTime!, $endDate: DateTime!) {
    loadReservationDashboard(startDate: $startDate, endDate: $endDate) {
      totalAmount
      totalAmountPaid
      totalAmountDue
      timeSeries
      seriesInterval
      vehicleBreakdown
      orderTypeBreakdown
      paymentBreakdown
    }
  }
`;

export const LOAD_DRIVER_DASHBOARD_QUERY = gql`
  query LoadDriverDashboard($startDate: DateTime!, $endDate: DateTime!) {
    loadDriverDashboard(startDate: $startDate, endDate: $endDate) {
      id
      driver {
        id
        firstName
        lastName
        driverProfilePhoto
      }
      amountPaid
      amountDue
      totalPayoutAmount
      tripCount
    }
  }
`;
