import React from "react";

import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { FarmAffiliate } from "types";
import { granite } from "design-system/colors";

function AffiliateColumn(props: GridRenderCellParams<string>) {
  const { value, row } = props;

  const farmAffiliate = row.farmAffiliate as FarmAffiliate;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
    >
      <Typography variant="body2" textOverflow="ellipsis" overflow="hidden">
        {value}
      </Typography>
      <Typography
        color={granite}
        variant="caption"
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {farmAffiliate?.operatorPhone || ""}
      </Typography>
    </Box>
  );
}

export default AffiliateColumn;
