/**
 * @file graphql/driverPayout.ts
 * queries/mutations for DriverPayout / DriverPayables.
 *
 */

import { gql } from "@apollo/client";

/**
 * this is a driver payout based on a date range (or all unpaid / closed routes),
 * that will be created if user calls `createDriverPayout` mutation.
 */
export const LOAD_POTENTIAL_DRIVER_PAYOUT_QUERY = gql`
  query LoadPotentialDriverPayouts($startDate: Date, $endDate: Date) {
    loadPotentialDriverPayouts(startDate: $startDate, endDate: $endDate) {
      routesCount
      driversCount
      flatRateAmt
      gratuityAmt
      hourlyAmt
      totalAmt
      driverPayables {
        id
        driver {
          firstName
          lastName
          driverProfilePhoto
        }
        routesCount
        flatRateAmt
        gratuityAmt
        hourlyAmt
        totalAmt
        operatorRoutes {
          id
        }
      }
    }
  }
`;

/**
 * Creates a driver payout from a list of routes that are included in the payout.
 */
export const CREATE_DRIVER_PAYOUT_MUTATION = gql`
  mutation CreateDriverPayout($input: CreateDriverPayoutInput!) {
    createDriverPayout(input: $input) {
      driverPayout {
        id
        totalAmt
        driversCount
        routesCount
        driverPayables {
          id
          driver {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

/**
 * Updates driver payout fields on farmed and non-farmed routes
 */
export const UPDATE_DRIVER_PAYOUT_MUTATION = gql`
  mutation UpdateDriverPayout($input: UpdateDriverPayoutInput!) {
    updateDriverPayout(input: $input) {
      route {
        id
        driverPayoutFlatRate
        driverPayoutGratuity
        driverPayoutRatePerHour
        driverPayoutHours
      }
    }
  }
`;

/**
 * Loads driver payout fields for update dialog
 */
export const LOAD_DRIVER_PAYOUT_FIELDS_QUERY = gql`
  query Trip($id: ID!) {
    node(id: $id) {
      id
      ... on Trip {
        id
        routes {
          id
          driverPayoutFlatRate
          driverPayoutHours
          driverPayoutRatePerHour
          driverPayoutGratuity
          automatedDriverPayoutFlatRate
          automatedDriverPayoutHours
          automatedDriverPayoutRatePerHour
          automatedDriverPayoutGratuity
          hasAutomatedDriverPayout
          pricing {
            id
            baseRateAmt
            driverGratuityAmt
          }
          farmeePricing {
            id
            baseRateAmt
            driverGratuityAmt
          }
          driver {
            id
            firstName
            lastName
            mobilePhone
            email
          }
        }
      }
    }
  }
`;
