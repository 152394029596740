import { useMutation } from "@apollo/client";

import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { useSnackbar } from "./useSnackbar";
import { SEND_DRIVER_ASSIGN_NOTIFICATION } from "globals/graphql/routeDriver.graphql";

function useSendDriverAssignNotification() {
  // hooks
  const snackbar = useSnackbar();

  // mutations
  const [sendDriverAssignNotification] = useMutation(
    SEND_DRIVER_ASSIGN_NOTIFICATION,
    {
      onCompleted(data) {
        if (
          data.sendDriverAssignTextOrNotificationV2.result.__typename ===
          "SendDriverAssignTextOrNotificationFailure"
        ) {
          snackbar.error(
            data.sendDriverAssignTextOrNotificationV2.result.message
          );
        }
      },
      onError(error) {
        const errorMessage = `Error sending notification to driver. ${
          getErrorMessage(error) || ""
        }`;
        snackbar.error(errorMessage);
      },
    }
  );

  // handlers
  const handleSendDriverAssignNotification = async (routeId: string) => {
    await sendDriverAssignNotification({
      variables: {
        input: {
          routeId,
        },
      },
    });
  };

  return {
    onSendDriverAssignNotification: handleSendDriverAssignNotification,
  };
}

export { useSendDriverAssignNotification };
