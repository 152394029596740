import { useMutation } from "@apollo/client";

import { DELETE_MOOVS_MARKET_APP_MUTATION } from "globals/graphql";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { MoovsMarketAppData } from "pages/moovs-market/components/MoovsMarketAppList/hooks/useMoovsMarketApps";
import { deleteToTrack } from "pages/moovs-market/helpers/enumToTrack";

type useDeleteAppMutationParams = {
  onCompleted: () => void;
  app: MoovsMarketAppData;
  deleteReason: string;
  otherReason: string;
};

const useDeleteAppMutation = (params: useDeleteAppMutationParams) => {
  const { onCompleted, app, deleteReason, otherReason } = params;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // mutations
  const [deleteApp, { loading }] = useMutation(
    DELETE_MOOVS_MARKET_APP_MUTATION,
    {
      onError: () => {
        snackbar.error("Error deleting app");
      },
      onCompleted: () => {
        track(deleteToTrack[app.enum]);
        snackbar.success(`Successfully deleted ${app.name}`);

        onCompleted();
      },
    }
  );

  // event handlers
  const handleDeleteApp = () => {
    deleteApp({
      variables: {
        input: {
          deleteReason,
          app: app.enum,
          otherReason: otherReason || null,
        },
      },
    });
  };

  return {
    onDeleteApp: handleDeleteApp,
    loading,
  };
};

export { useDeleteAppMutation };
