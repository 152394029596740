import React, { Dispatch, SetStateAction } from "react";

import { Box, Button } from "@mui/material";

import { SalesAutomationText, SalesAutomationTextStageEnum } from "types";
import {
  blueLabelBackground,
  grayLight,
  moovsBlue,
} from "design-system/colors";
import { TextButtonHeader, TextButtonSubtext } from "./components";

type SalesAutomationTextButtonProps = {
  text: SalesAutomationText;
  activeTextIndex: number;
  setActiveTextIndex: Dispatch<SetStateAction<number>>;
};

const SalesAutomationTextButton = (props: SalesAutomationTextButtonProps) => {
  const { text, activeTextIndex, setActiveTextIndex } = props;

  // derived state
  const isActive = text.index === activeTextIndex;
  const isDisabled = text.stage === SalesAutomationTextStageEnum.Disabled;

  return (
    <Button
      variant={isActive ? "outlined" : null}
      onClick={() => setActiveTextIndex(text.index)}
      sx={{
        "&.MuiButton-root:hover": {
          backgroundColor: isActive ? blueLabelBackground : grayLight,
        },
        backgroundColor: isActive && blueLabelBackground,
        outlineColor: isActive && moovsBlue,
      }}
      disabled={isDisabled}
    >
      <Box display="flex" flexDirection="column" width="100%" mb={1}>
        <TextButtonHeader
          isActive={isActive}
          isDisabled={isDisabled}
          text={text}
        />

        {/* Text Button Subtext */}
        <TextButtonSubtext isDisabled={isDisabled} text={text} />
      </Box>
    </Button>
  );
};

export default SalesAutomationTextButton;
