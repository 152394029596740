import React, { MouseEvent, ChangeEvent } from "react";

import { Box, Button, ButtonProps, TextField, Typography } from "@mui/material";

import { MinusIcon, PlusIcon } from "design-system/icons";
import { NumberFormatDecimal } from "design-system/components/inputs/NumberFormat";
import theme, { primaryMainColor } from "theme";

type MoovsIncrementerProps = {
  value: number;
  limits: [number, number];
  decrement: (event: MouseEvent<HTMLElement>) => void;
  increment: (event: MouseEvent<HTMLElement>) => void;
  onTextFieldChange: (value: number, event: ChangeEvent<HTMLElement>) => void;
  textFieldAdornment?: string;
  disabled?: boolean;
  errorMessage?: string;
  iconColor?: string;
  ButtonProps?: ButtonProps;
};

function MoovsIncrementer(props: MoovsIncrementerProps) {
  const {
    value,
    limits,
    decrement,
    increment,
    disabled,
    onTextFieldChange,
    textFieldAdornment,
    errorMessage,
    iconColor,
    ButtonProps,
  } = props;

  const [lowerLimit, upperLimit] = limits;

  const isMinusDisabled = disabled || value <= lowerLimit;
  const isPlusDisabled = disabled || value >= upperLimit;

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" alignItems="center">
        <Button
          variant="outlined"
          sx={{
            height: 44,
            width: 44,
            padding: 0,
            minWidth: 0,
            borderWidth: "2px",
            "&.MuiButton-outlined.Mui-disabled": {
              borderWidth: "2px",
            },
          }}
          disabled={isMinusDisabled}
          onClick={decrement}
          {...ButtonProps}
        >
          <MinusIcon
            {...(!isMinusDisabled && {
              color: iconColor || primaryMainColor,
            })}
          />
        </Button>
        <TextField
          sx={{
            width: 70,
            margin: `0px ${theme.spacing(2)}`,
          }}
          variant="outlined"
          value={value}
          onChange={(e) => {
            onTextFieldChange(Number(e.target.value), e);
          }}
          fullWidth={false}
          disabled={disabled}
          error={!!errorMessage}
          InputProps={{
            ...(textFieldAdornment && {
              endAdornment: (
                <Typography variant="overline">{textFieldAdornment}</Typography>
              ),
            }),
            inputComponent: NumberFormatDecimal as any,
          }}
          inputProps={{
            min: 0,
            max: 99,
            decimalScale: 1,
            style: { textAlign: "center" },
          }}
          name="count"
          size="small"
        />
        <Button
          variant="outlined"
          sx={{
            height: 44,
            width: 44,
            minWidth: 0,
            padding: 0,
            borderWidth: "2px",
            "&.MuiButton-outlined.Mui-disabled": {
              borderWidth: "2px",
            },
          }}
          disabled={isPlusDisabled}
          onClick={increment}
          {...ButtonProps}
        >
          <PlusIcon
            {...(!isPlusDisabled && {
              color: iconColor || primaryMainColor,
            })}
          />
        </Button>
      </Box>

      {errorMessage && (
        <Typography variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
}
export default MoovsIncrementer;
