import React from "react";
import map from "lodash/map";

import { Tab, Tabs } from "@mui/material";

import { grayMedium, moovsBlue } from "design-system/colors";

export type TabConfig = {
  label: string;
  component: (props: any) => JSX.Element;
};

type SettingsTabsProp = {
  tabIndex: string;
  setTabIndex: (index: string) => void;
  isMobileView: boolean;
  tabs: TabConfig[];
};

function SettingsTabs(props: SettingsTabsProp) {
  const { isMobileView, tabIndex, setTabIndex, tabs } = props;

  const handleTabSelect = (_, tabIndex) => {
    setTabIndex(tabIndex);
  };

  // custom styles
  const styles = {
    selectedTabBorder: {
      borderBottom: `2px solid ${moovsBlue}`,
    },
    tabsBorder: {
      borderBottom: `1px solid ${grayMedium}`,
    },
  };

  return (
    <Tabs
      sx={{
        "&& .MuiTabs-indicator": styles.selectedTabBorder,
        ...styles.tabsBorder,
      }}
      onChange={handleTabSelect}
      value={tabIndex}
      variant={isMobileView ? "scrollable" : "standard"}
    >
      {map(tabs, (tab, index) => (
        <Tab key={index} label={tab.label} value={index.toString()} />
      ))}
    </Tabs>
  );
}

export default SettingsTabs;
