import React from "react";

type PriceBreakdownIconProps = {
  color?: string;
  size?: "small";
};

const PriceBreakdownIcon = (props: PriceBreakdownIconProps) => {
  const { color, size } = props;
  if (size === "small") {
    return (
      <svg
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.36944 7.47587C9.56997 7.76623 9.83103 7.99901 10.1305 8.1545C10.43 8.30998 10.7591 8.38358 11.0898 8.36906C12.1444 8.36906 13 7.66165 13 6.7899C13 5.91815 12.1472 5.21177 11.0935 5.21177C10.0398 5.21177 9.18426 4.50436 9.18426 3.63159C9.18426 2.75881 10.0398 2.05243 11.0935 2.05243M11.0935 2.05243C11.4243 2.03765 11.7535 2.11113 12.053 2.26664C12.3525 2.42214 12.6135 2.65507 12.8139 2.94562M11.0935 2.05243V1M11.0935 8.36906V9.42149M1 11H7.30556M1 7.86283H2.62963M4.85185 7.86283H6.7037M1 4.72565H4.85185M1 1.52632H5.96296"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9491 13.2176C16.2833 13.6982 16.7184 14.0834 17.2175 14.3407C17.7166 14.5981 18.2651 14.7199 18.8164 14.6958C20.5741 14.6958 22 13.5251 22 12.0823C22 10.6396 20.5787 9.4705 18.8225 9.4705C17.0664 9.4705 15.6404 8.29974 15.6404 6.8553C15.6404 5.41085 17.0664 4.24178 18.8225 4.24178M18.8225 4.24178C19.3739 4.21732 19.9224 4.33894 20.4216 4.5963C20.9208 4.85366 21.3559 5.23915 21.6898 5.72001M18.8225 4.24178V2.5M18.8225 14.6958V16.4376M2 18.179H12.5093M2 22.5H18.3735M2 13.858H4.71605M8.41975 13.858H11.5062M2 9.53704H8.41975M2 5.21605H10.2716"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PriceBreakdownIcon;
