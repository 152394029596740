import { useAnalytics } from "globals/hooks";
import { useEffect, useState } from "react";

export enum DispatchLogDialogTab {
  DispatchLog,
  RideReport,
}

function useDispatchLogTabs(open: boolean) {
  // hooks
  const { track } = useAnalytics();

  // state
  const [tabMode, setTabMode] = useState<DispatchLogDialogTab>(
    DispatchLogDialogTab.DispatchLog
  );

  // effects
  // reset tab mode on close
  useEffect(() => {
    if (!open) setTabMode(DispatchLogDialogTab.DispatchLog);
  }, [open]);

  // event handlers
  const handleTabModeChange = (newTabMode: DispatchLogDialogTab) => {
    if (newTabMode === DispatchLogDialogTab.RideReport) {
      track("reservation_rideReportOpened");
    }

    setTabMode(newTabMode);
  };

  return { tabMode, onTabModeChange: handleTabModeChange };
}

export default useDispatchLogTabs;
