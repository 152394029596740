import React, { useState } from "react";

import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { grayDark } from "design-system/colors";
import { useAnalytics, useOperator } from "globals/hooks";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";

type UseAddToCalendarInviteClickProps = {
  analyticsName?: string;
};

function useAddToCalendarInviteClick(
  props: UseAddToCalendarInviteClickProps
): EllipsisMenuOption & { open: boolean; onClose: () => void } {
  const { analyticsName } = props;

  // hooks
  const { track } = useAnalytics();
  const {
    settings: { isCalendarIntegrationEnabled },
  } = useOperator();

  // state
  const [isAddToCalendarInviteDialogOpen, setIsAddToCalendarInviteDialogOpen] =
    useState(false);

  // event handlers
  const handleDialogOpen = () => {
    if (analyticsName) track(analyticsName);
    setIsAddToCalendarInviteDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsAddToCalendarInviteDialogOpen(false);
  };

  return {
    text: "Invite",
    icon: <PersonAddAltIcon fontSize="small" htmlColor={grayDark} />,
    onClick: handleDialogOpen,
    disableOption: false,
    hideOption: !isCalendarIntegrationEnabled,

    // dialog state
    open: isAddToCalendarInviteDialogOpen,
    onClose: handleDialogClose,
  };
}

export { useAddToCalendarInviteClick };
