import React from "react";

import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { black, grayLight, moovsBlue, white } from "design-system/colors";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  padding: "6px",
  width: "100%",
  justifyContent: "space-between",
  "& .MuiToggleButtonGroup-grouped": {
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 4,
    },
    "&:first-of-type": {
      borderRadius: 4,
    },
  },
});

const StyledToggleButton = styled(ToggleButton)({
  padding: "12px 16px",
  flexWrap: "nowrap",
  color: black,
  "&:hover": {
    background: "none",
  },
  "&.Mui-selected": {
    color: black,
    backgroundColor: white,
    "&:hover": {
      backgroundColor: white,
    },
  },
});

type PlanLengthToggleProps = {
  planLength: string;
  handleToggle: (_: any, newType: "monthly" | "annual") => void;
};

function PlanLengthToggle(props: PlanLengthToggleProps) {
  const { planLength, handleToggle } = props;

  return (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="space-around"
      height="54px"
      width="292px"
      sx={{
        backgroundColor: grayLight,
        borderRadius: "6px",
      }}
    >
      <StyledToggleButtonGroup
        color="primary"
        value={planLength}
        exclusive
        onChange={handleToggle}
        aria-label="Platform"
      >
        <StyledToggleButton disableRipple value="monthly">
          <Typography variant="body2">Monthly</Typography>
        </StyledToggleButton>
        <StyledToggleButton disableRipple value="annual">
          <Box display="flex" flexDirection="row" gap={1}>
            <Typography variant="body2">Annual</Typography>
            <Typography variant="body2" color={moovsBlue}>
              Save up to 20%
            </Typography>
          </Box>
        </StyledToggleButton>
      </StyledToggleButtonGroup>
    </Box>
  );
}

export default PlanLengthToggle;
