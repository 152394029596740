/**
 * @file ContactAutoComplete.tsx
 * Material ui autocomplete function
 * hooked up to custmer email search.
 *
 * You should supply null as initial value.
 *
 * components:
 *  ContactAutoComplete
 *
 * author: jackv
 */

import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useLazyQuery } from "@apollo/client";
import { DeepPartial } from "ts-essentials";

import {
  Box,
  Divider,
  TextField,
  Typography,
  Paper,
  Button,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { Contact } from "types";
import { LOAD_CONTACTS_QUERY } from "globals/graphql";
import { white, black } from "design-system/colors";
import { PlusIcon } from "design-system/icons";
import { getContactDropdownOptions } from "globals/utils/contact";

type ContactAutoCompleteProps = {
  value?: DeepPartial<Contact> | null;
  onChange: (newValue: Contact) => void;
  onCreateNewContactClick?: () => void;
  error?: any;
  helperText?: string;
  setCarryOverSearch?: Dispatch<SetStateAction<string>>;
  label?: string;
  companyId?: string;
  suggestedContacts?: Contact[];
  filter?: string[];
  isInEditMode?: boolean;
  shouldIncludeFilterItems?: boolean;
  contactsOptionsGroupBy?: {
    [key: string]: string[] | string;
  };
};

function ContactAutoComplete(props: ContactAutoCompleteProps) {
  const {
    value,
    onChange,
    onCreateNewContactClick,
    error,
    helperText,
    setCarryOverSearch,
    label,
    companyId,
    suggestedContacts,
    filter = [],
    isInEditMode = false,
    shouldIncludeFilterItems = false,
    contactsOptionsGroupBy,
  } = props;

  const [contactInput, setContactInput] = useState("");
  const [contactOptions, setContactOptions] = useState([]);

  const [loadContactsList, { loading, data }] = useLazyQuery(
    LOAD_CONTACTS_QUERY,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const contactList = data?.loadContacts.edges.map(({ node }) => node);
        const filteredContactList = contactList?.filter((contact) =>
          shouldIncludeFilterItems
            ? filter.includes(contact.id)
            : !filter?.includes(contact.id)
        );

        if (contactsOptionsGroupBy) {
          const contactOptions = getContactDropdownOptions(
            contactsOptionsGroupBy,
            filteredContactList
          );
          setContactOptions(contactOptions);
        } else {
          setContactOptions(filteredContactList || []);
        }
      },
      onError: (error) => {
        console.error("Error loading contacts", error);
      },
    }
  );

  const handleContactInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event) setContactInput(event.target.value);
  };

  const debouncedGetContacts = useDebouncedCallback((contactInput: string) => {
    loadContactsList({
      variables: {
        searchTerm: contactInput,
        limit: 20,
        ...(companyId && { companyId }),
      },
    });
  }, 250);

  const handleCreateNewContactClick = () => {
    setCarryOverSearch(contactInput);
    onCreateNewContactClick();
  };

  useEffect(() => {
    if (contactInput === "" && suggestedContacts) {
      if (contactsOptionsGroupBy) {
        const contactOptions = getContactDropdownOptions(
          contactsOptionsGroupBy,
          suggestedContacts
        );
        setContactOptions(contactOptions);
      } else {
        setContactOptions(suggestedContacts);
      }
    }

    if (contactInput) {
      debouncedGetContacts(contactInput);
    }
  }, [
    contactInput,
    debouncedGetContacts,
    suggestedContacts,
    contactsOptionsGroupBy,
  ]);

  const paperComponent = ({ children }) => {
    return (
      <Paper>
        {!contactsOptionsGroupBy && (
          <Box px={2} pt={1}>
            <Typography sx={{ backgroundColor: white }} variant="overline">
              Add existing contact
            </Typography>
          </Box>
        )}
        {children}
        {onCreateNewContactClick && !isInEditMode && (
          <Box flex="1" display="flex" justifyContent="flex-start">
            <Button
              fullWidth
              onMouseDown={(event) => {
                event.preventDefault();
              }}
              onClick={handleCreateNewContactClick}
              startIcon={<PlusIcon size="small" color={black} />}
            >
              Create New Contact
            </Button>
          </Box>
        )}
      </Paper>
    );
  };

  return (
    <Autocomplete
      autoHighlight
      id="transactions-from-contact"
      PaperComponent={paperComponent}
      clearOnEscape
      noOptionsText={
        data && contactInput
          ? "No Contact Found"
          : "Type to search for a contact"
      }
      filterOptions={(x) => x}
      // helps autocomplete to know if the value is in the options
      isOptionEqualToValue={(option, value) =>
        option.id === value?.id || option.email === value?.email
      }
      loading={debouncedGetContacts.isPending() || loading}
      value={value}
      onChange={(_e, newValue: Contact) => onChange(newValue)}
      options={contactOptions}
      onInputChange={handleContactInputChange}
      getOptionLabel={(contact: Contact) => contact.email}
      groupBy={contactsOptionsGroupBy ? (option) => option.title : undefined}
      renderGroup={(params) => (
        <li key={params.key}>
          <Box px={2} py={1}>
            <Typography sx={{ backgroundColor: white }} variant="overline">
              {params.group}
            </Typography>
          </Box>
          {params.children}
        </li>
      )}
      renderInput={(params) => {
        const { InputProps, ...restParams } = params;
        return (
          <TextField
            {...restParams}
            fullWidth
            label={label || "Search for contact"}
            name="no-show"
            variant="outlined"
            error={error}
            helperText={helperText}
            InputProps={{
              ...InputProps,
              autoComplete: "name",
            }}
          />
        );
      }}
      renderOption={(props, option) => (
        <li {...props}>
          <Box>
            <Typography variant="h6" data-testid="name-display">
              {option.firstName} {option.lastName}
            </Typography>
            <Typography variant="body2">{option.email}</Typography>
            <Typography variant="body2">{option.mobilePhone}</Typography>
          </Box>
          <Divider />
        </li>
      )}
    />
  );
}

export default ContactAutoComplete;
