/**
 * @file PublishedIndicatorChip.tsx
 * Design-system component for showing publishing states.
 * Very similar functionality to SaveIndicatorChip.tsx.
 * Similar to Chip component but not built on Material UI.
 *
 * components:
 * PublishedIndicatorChip
 *
 * author: sienag
 */
import React from "react";
import { useDebounce } from "use-debounce";
import moment from "moment-timezone";

import { Box, CircularProgress, Tooltip } from "@mui/material";

import { WebsiteBrowserIcon, WarningIcon } from "../icons";
import { granite, errorRed } from "../colors";

type PublishedIndicatorChipProps = {
  mode?: "default" | "published" | "publishing" | "error";
  publishedAt?: string;
};

function PublishedIndicatorChip(props: PublishedIndicatorChipProps) {
  const { mode = "default", publishedAt } = props;

  // hooks
  const [debouncedMode] = useDebounce(mode, 1000, { leading: true });

  const modesMap = {
    default: {
      label: "Published",
      icon: <WebsiteBrowserIcon />,
    },
    published: {
      label: "Published",
      icon: <WebsiteBrowserIcon />,
    },
    publishing: {
      label: "Publishing...",
      icon: (
        <CircularProgress
          size={15}
          style={{ marginLeft: "2px" }}
          color="primary"
        />
      ),
    },
    error: {
      label: "Error",
      icon: <WarningIcon size="small" color={errorRed} />,
    },
  };

  return (
    <Tooltip
      enterDelay={200}
      enterNextDelay={200}
      title={publishedAt ? `Published ${moment(publishedAt).fromNow()}` : ""}
    >
      <Box
        component="div"
        sx={{
          cursor: "default",
          height: "32px",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "flex-start",
          backgroundColor: "transparent",
          borderRadius: "6px",
          width: "88px",
          color: granite,
          paddingLeft: "8px",
        }}
      >
        <Box component="span" display="flex" width="20px">
          {modesMap[mode === "error" ? mode : debouncedMode].icon}
        </Box>
        <Box component="span" sx={{ marginLeft: "8px", fontSize: "small" }}>
          {modesMap[mode === "error" ? mode : debouncedMode].label}
        </Box>
      </Box>
    </Tooltip>
  );
}

export default PublishedIndicatorChip;
