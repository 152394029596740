/**
 * @file useCurrentUser.ts
 * Hook that abstracts meQuery calls and returns current user.
 */

import { useQuery } from "@apollo/client";

import { useSnackbar } from "./useSnackbar";
import { useAuth } from "./useAuth";
import { LOAD_ME_QUERY } from "../graphql";
import { User } from "../../types";

function useCurrentUser(): User & {
  isSwoopCurrentOperator: boolean;
  shouldHideAffiliates: boolean;
} {
  const auth = useAuth();
  const snackbar = useSnackbar();

  // queries
  const { data, error } = useQuery<{ me: User }>(LOAD_ME_QUERY, {
    skip: auth?.authStage !== "authenticated",
  });

  if (error) {
    snackbar.error(error.message);
  }

  const swoopOperatorIds = process.env.REACT_APP_SWOOP_OPERATOR_IDS.split(",");

  return data?.me
    ? {
        ...data.me,
        isSwoopCurrentOperator: swoopOperatorIds.includes(data.me.operatorId),
        shouldHideAffiliates:
          data.me.operatorId ===
          process.env.REACT_APP_HIDE_AFFILIATES_OPERATOR_ID,
      }
    : null;
}

export { useCurrentUser };
