/**
 * @file ToFieldAutoComplete.tsx
 * Autocomplete for searching for phone numbers for chat.
 * Allows entering a new number or searching for drivers.
 * Will eventually allow searching for contacts as well.
 *
 * components:
 *  ToFieldAutoComplete
 */

import React, { ChangeEvent, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";

import { Box, Divider, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { LOAD_RECIPIENTS_QUERY } from "../../globals/graphql/recipient.graphql";
import { useSnackbar } from "../../globals/hooks/useSnackbar";
import { UserIcon, WheelIcon } from "../../design-system/icons";
import { black } from "../../design-system/colors";
import { formatPhoneNumber } from "../../globals/utils/phoneNumberFormatter/phoneNumberFormatter";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type ToFieldAutoCompleteProps = {
  recipients: any[];
  onChange: (newValue: any) => void;
};

function ToFieldAutoComplete(props: ToFieldAutoCompleteProps) {
  const { onChange, recipients } = props;

  // hooks
  const snackbar = useSnackbar();

  // state
  const [inputValue, setInputValue] = useState("");
  const [queryLoading, setQueryLoading] = useState(false);
  const [debouncedInput] = useDebounce(inputValue, 200);
  const [options, setOptions] = useState<any[]>([]);

  // queries
  const [loadRecipients] = useLazyQuery(LOAD_RECIPIENTS_QUERY, {
    onCompleted(data) {
      setQueryLoading(false);

      if (!data.recipients) {
        setOptions(undefined);
        return;
      }

      const formattedRecipients = data?.recipients.map((recipient) => {
        const { e164: E164Number, formatted: mobilePhone } = formatPhoneNumber(
          recipient.mobilePhone
        );

        return {
          ...recipient,
          mobilePhone,
          E164Number,
        };
      });

      setOptions(formattedRecipients);
    },
    onError(error) {
      setQueryLoading(false);
      const errorMessage = getErrorMessage(error) || error.message;

      snackbar.error(errorMessage);
    },
    fetchPolicy: "network-only",
  });

  // event handlers
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleAutocompleteChange = (_, recipients, reason) => {
    // if clearing/removing just return that instead of processing
    if (
      !recipients.length ||
      reason === "remove-option" ||
      reason === "clear"
    ) {
      onChange(recipients);
      return;
    }

    let newRecipient = recipients[recipients.length - 1];

    // item is already selected
    if (
      recipients.findIndex(({ id }) => id === newRecipient.id) !==
      recipients.length - 1
    ) {
      return;
    }

    // an existing contact or driver
    const handleExistingPerson = (existingPerson) => {
      const { e164: E164Number } = formatPhoneNumber(
        existingPerson.mobilePhone
      );

      recipients.splice(recipients.length - 1, 1, {
        ...existingPerson,
        E164Number,
      });

      onChange(recipients);
    };

    if (typeof newRecipient === "object") {
      handleExistingPerson(newRecipient);
      return;
    }

    // new number
    if (newRecipient[0] === "1") {
      // trim 1 from start of number
      newRecipient = newRecipient.slice(1);
    }

    const {
      e164: E164Number,
      formatted: mobilePhone,
      countryCode: phoneCountryCode,
    } = formatPhoneNumber(newRecipient) || {};

    if (!E164Number || !mobilePhone) return;

    // search options for matching mobile phone
    // handles edge case of user selecting a number that exists
    // in options list but not selecting it
    const existingPerson = options.find((person) => {
      return person?.mobilePhone === mobilePhone;
    });

    if (!!existingPerson) {
      handleExistingPerson(existingPerson);
      return;
    }

    recipients.splice(recipients.length - 1, 1, {
      E164Number,
      mobilePhone,
      phoneCountryCode: phoneCountryCode.toLowerCase(), // country code from formatPhoneInput comes out as uppercase
    });

    onChange(recipients);
    return;
  };

  const handleInputBlur = (event: any) => {
    handleAutocompleteChange(null, [...recipients, inputValue], "blur");
  };

  // effects
  useEffect(() => {
    if (debouncedInput === "") {
      setOptions([]);
      return;
    }

    if (debouncedInput.length) {
      loadRecipients({
        variables: {
          searchTerm: debouncedInput,
        },
      });
    }
  }, [debouncedInput, loadRecipients]);

  // display loading as soon as user makes an input change
  useEffect(() => {
    setQueryLoading(!!inputValue);
  }, [inputValue]);

  return (
    <Autocomplete
      autoHighlight
      disableClearable
      multiple
      freeSolo
      clearOnEscape
      onBlur={handleInputBlur}
      value={recipients}
      onChange={handleAutocompleteChange}
      filterOptions={(x) => x}
      options={options}
      loading={queryLoading}
      loadingText="Loading..."
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={({ firstName, lastName, mobilePhone }) => {
        const name =
          firstName || lastName ? `${firstName || ""} ${lastName || ""}: ` : "";
        return `${name}${mobilePhone}`;
      }}
      renderInput={(params) => {
        const { InputProps, ...restParams } = params;

        return (
          <TextField
            {...restParams}
            fullWidth
            onChange={handleInputChange}
            InputProps={{
              ...InputProps,
              autoComplete: "new-password",
              style: { paddingTop: 4, paddingBottom: 4 },
            }}
          />
        );
      }}
      renderOption={(props, option) => (
        <li {...props}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row">
              {option.__typename === "Driver" && (
                <Box mr={0.5}>
                  <WheelIcon size="small" color={black} />
                </Box>
              )}
              {option.__typename === "Contact" && (
                <Box mr={0.5}>
                  <UserIcon size="small" color={black} />
                </Box>
              )}
              <Typography variant="body2" style={{ fontWeight: 500 }}>
                {option.firstName} {option.lastName}
              </Typography>
            </Box>
            <Typography variant="caption">
              {option.email || option.driverEmail || ""}
            </Typography>
            <Typography variant="caption">{option.mobilePhone}</Typography>
          </Box>
          <Divider />
        </li>
      )}
    />
  );
}

export default ToFieldAutoComplete;
