import * as yup from "yup";

export const codeAndAmountSchema = yup.object({
  isPercent: yup
    .boolean()
    .required("Please make a selection")
    .typeError("Please make a selection"),
  amount: yup.number().when("isPercent", {
    is: true,
    then: yup
      .number()
      .required("Please enter a percent")
      .min(0.0, "Percent must be between 0 and 100")
      .max(100.0, "Percent must be between 0 and 100")
      .typeError("Please enter a percent"),
    otherwise: yup
      .number()
      .required("Please enter an amount")
      .min(0.0, "Amount must be greater than or equal to 0")
      .typeError("Please enter an amount"),
  }),
  name: yup
    .string()
    .min(4, "Code must be 4-15 characters")
    .max(15, "Code must be 4-15 characters")
    .matches(/^[a-zA-Z0-9]+$/, "Code must be letters and numbers")
    .required("Please enter a code") // Keep after the regex test so this error message shows up first if name: undefined
    .typeError("Please enter a valid code"),
});
