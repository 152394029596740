import React from "react";

import { Typography } from "@mui/material";
import MoovsDialog from "components/MoovsDialog";
import { black } from "design-system/colors";

type CustomerReviewAppInstallOptionsDialogProps = {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
  onSecondaryAccept: () => void;
  acceptLoading: boolean;
};

const CustomerReviewAppInstallOptionsDialog = (
  props: CustomerReviewAppInstallOptionsDialogProps
) => {
  const { open, onClose, onAccept, onSecondaryAccept, acceptLoading } = props;

  return (
    <MoovsDialog
      dialogTitle="Customer Review App"
      open={open}
      onClose={onClose}
      acceptButtonText="Install Without"
      onAccept={onAccept}
      acceptButtonVariant="outlined"
      secondaryAcceptButtonText="Add Review Platform"
      onSecondaryAccept={onSecondaryAccept}
      secondaryAcceptButtonVariant="contained"
      size="sm"
      acceptDisabled={acceptLoading}
      hideLoadingIndicator={!acceptLoading}
    >
      <Typography variant="body2" color={black} fontWeight={500} my={2}>
        Customer Review App allows you to send contacts a trip review link to
        rate their experience with you on a scale 1 to 5. If you add your review
        platform link, contacts will be directed to leave a review on your
        platform for all 5-star ratings. Any rating below a 5 will prompt them
        to leave a comment that you can view separetely.
      </Typography>
    </MoovsDialog>
  );
};

export default CustomerReviewAppInstallOptionsDialog;
