import React from "react";

import { Box, Typography } from "@mui/material";

import MoovsDialog from "../../MoovsDialog";
import { granite } from "../../../design-system/colors";

type DeleteCreditCardDialogProps = {
  open: boolean;
  onClose: () => void;
  last4: string;
  onDeleteCard: () => void;
};

function DeleteCreditCardDialog(props: DeleteCreditCardDialogProps) {
  const { open, onClose, last4, onDeleteCard } = props;

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      dialogTitle="Delete Card"
      onAccept={onDeleteCard}
      acceptButtonText="Delete"
      hideTopBorder
      size="sm"
      acceptButtonVariant="text"
    >
      <Box mb={3}>
        <Typography style={{ color: granite }}>
          This will permanently remove credit card ****-{last4}. Are you sure
          you want to delete this card?
        </Typography>
      </Box>
    </MoovsDialog>
  );
}

export default DeleteCreditCardDialog;
