/**
 * @file VehiclePhotos.tsx
 * Vehicle photo editing / adding component.
 *
 * components:
 *  VehiclePhotos
 */

import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import { Box } from "@mui/material";

import VehiclePhotosDraggableList from "./VehiclePhotosDraggableList";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import { VehiclePhoto } from "../../../types";
import {
  CREATE_VEHICLE_PHOTO_MUTATION,
  UPDATE_VEHICLE_PHOTO_MUTATION,
  REMOVE_VEHICLE_PHOTO_MUTATION,
  UPDATE_VEHICLE_PHOTO_ORDER_MUTATION,
} from "../../../globals/graphql";
import MoovsLightbox from "../../MoovsLightbox";
import ReadOnlyVehiclePhotos from "./ReadOnlyVehiclePhotos";

type VehiclePhotosProps = {
  mode: "create" | "update" | "view-only";
  photos: VehiclePhoto[];
  vehicleId?: string;
  onVehiclePhotosChange?: (newPhotos: VehiclePhoto[]) => void;
  setSaveIndicatorState?: Function;
  onResetPhotos?: () => void;
};

function VehiclePhotos(props: VehiclePhotosProps) {
  const {
    mode,
    photos,
    vehicleId,
    onVehiclePhotosChange,
    setSaveIndicatorState,
    onResetPhotos,
  } = props;

  const snackbar = useSnackbar();

  // state
  const [activePhotoIndex, setActivePhotoIndex] = useState(null);

  // mutations
  const [createVehiclePhoto] = useMutation(CREATE_VEHICLE_PHOTO_MUTATION, {
    refetchQueries: ["loadVehicles"],
    onCompleted(data) {
      onVehiclePhotosChange(data.createVehiclePhoto.vehicle.photos);
      setSaveIndicatorState("saved");
    },
    onError(error) {
      snackbar.error();
      setSaveIndicatorState("error");

      // reset photos to match db
      onResetPhotos();
    },
  });

  const [updateVehiclePhotoOrder] = useMutation(
    UPDATE_VEHICLE_PHOTO_ORDER_MUTATION,
    {
      refetchQueries: ["loadVehicles"],
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        snackbar.error();
        setSaveIndicatorState("error");

        // reset photos to match db
        onResetPhotos();
      },
    }
  );

  const [removeVehiclePhoto] = useMutation(REMOVE_VEHICLE_PHOTO_MUTATION, {
    refetchQueries: ["loadVehicles"],
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      snackbar.error();
      setSaveIndicatorState("error");

      // reset photos to match db
      onResetPhotos();
    },
  });

  const [updateVehiclePhoto] = useMutation(UPDATE_VEHICLE_PHOTO_MUTATION, {
    refetchQueries: ["loadVehicles"],
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      snackbar.error();
      setSaveIndicatorState("error");

      // reset photos to match db
      onResetPhotos();
    },
  });

  // event handlers
  const handleCreateVehiclePhoto = async (url: string) => {
    if (mode === "create") {
      const newPhotos = [
        ...photos,
        {
          photoIndex: photos.length + 1,
          id: Date.now().toString(),
          url,
        },
      ];

      onVehiclePhotosChange(newPhotos);
    }

    if (mode === "update") {
      await createVehiclePhoto({
        variables: {
          input: {
            photoIndex: photos.length + 1,
            url,
            vehicleId,
          },
        },
      });
    }
  };

  const handleUpdateVehiclePhoto = async ({
    id,
    url,
    photoIndex,
  }: VehiclePhoto) => {
    try {
      const newPhotos = photos.map((photo) => {
        const isEditingImage = id === photo.id;

        return {
          ...photo,
          ...(isEditingImage && { url }),
        };
      });

      onVehiclePhotosChange(newPhotos);

      if (mode === "update") {
        setSaveIndicatorState("loading");

        await updateVehiclePhoto({
          variables: {
            input: {
              id,
              url,
              photoIndex,
            },
          },
        });
      }
    } catch (error) {
      snackbar.error();
    }
  };

  const handleRemoveVehiclePhoto = async (removedPhoto: VehiclePhoto) => {
    try {
      const newPhotos = photos
        .filter(({ photoIndex }) => photoIndex !== removedPhoto.photoIndex)
        .map((photo, index) => {
          photo.photoIndex = index + 1;
          return photo;
        });

      onVehiclePhotosChange(newPhotos);

      if (mode === "update") {
        setSaveIndicatorState("loading");

        await removeVehiclePhoto({
          variables: {
            input: {
              id: removedPhoto.id,
            },
          },
        });
      }
    } catch (error) {
      snackbar.error();
    }
  };

  const handleUpdateVehiclePhotoOrder = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const reorder = (array: any[], startIndex: number, endIndex: number) => {
      const result = [...array].sort((photoA, photoB) => {
        return photoA.photoIndex - photoB.photoIndex;
      });

      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const reorderedPhotos = reorder(photos, oldIndex, newIndex);
    const newPhotos = reorderedPhotos.map(
      (photo: VehiclePhoto, index: number) => ({
        id: photo.id,
        photoIndex: index + 1,
        url: photo.url,
      })
    );

    onVehiclePhotosChange(newPhotos);

    if (mode === "update") {
      setSaveIndicatorState("loading");

      updateVehiclePhotoOrder({
        variables: {
          input: {
            photos: newPhotos.map(({ id, photoIndex }) => ({
              id,
              photoIndex,
            })),
          },
        },
      });
    }
  };

  const handleViewOnlyPhotoClick = (photoIndex: number) => {
    setActivePhotoIndex(photoIndex);
  };

  const VehiclePhotosContainer = () => {
    if (mode === "view-only") {
      return (
        <ReadOnlyVehiclePhotos
          photos={photos}
          onPhotoClick={handleViewOnlyPhotoClick}
        />
      );
    }
    return (
      <VehiclePhotosDraggableList
        photos={photos}
        onUpdateVehiclePhotoOrder={handleUpdateVehiclePhotoOrder}
        onCreateVehiclePhoto={handleCreateVehiclePhoto}
        onUpdateVehiclePhoto={handleUpdateVehiclePhoto}
        onRemoveVehiclePhoto={handleRemoveVehiclePhoto}
        setActivePhotoIndex={setActivePhotoIndex}
      />
    );
  };

  return (
    <>
      <Box my={1}>
        <VehiclePhotosContainer />
      </Box>
      <MoovsLightbox
        photos={photos.map(({ url }) => url)}
        setActivePhotoIndex={setActivePhotoIndex}
        activePhotoIndex={activePhotoIndex}
      />
    </>
  );
}

export default VehiclePhotos;
