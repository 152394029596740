import React from "react";

import { Card, Box, Typography, CardContent } from "@mui/material";

import {
  black,
  errorRed,
  grayDark,
  green,
  orange,
} from "../../../design-system/colors";
import greenCheckMark from "../../../design-system/illustrations/greenCheckMark.svg";
import redUnavailableCross from "../../../design-system/illustrations/redUnavailableCross.svg";
import orangeUnavailableWarning from "../../../design-system/illustrations/orangeUnavailableWarning.svg";
import { AutomatedPricingIcon } from "../../../design-system/icons";
import { useScreenSize } from "../../../globals/hooks";

type CurrentTripScheduleBlockProps = {
  pickUpTime: string;
  pickUpLocation: string;
  isAutomatedDropTimeCalculation: boolean;
  dropOffTime: string;
  dropOffLocation: string;
  isVehicleAvailable: boolean;
  blockedByQuote: boolean;
};

function CurrentTripScheduleBlock(props: CurrentTripScheduleBlockProps) {
  const {
    pickUpTime,
    pickUpLocation,
    isAutomatedDropTimeCalculation,
    dropOffTime,
    dropOffLocation,
    isVehicleAvailable,
    blockedByQuote,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Card variant="outlined">
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: isMobileView ? "column" : "row-reverse",
        }}
      >
        <Box {...(isMobileView && { mb: "12px" })} flex={1}>
          {isVehicleAvailable ? (
            <Box
              color={green}
              display="flex"
              alignItems="center"
              {...(!isMobileView && { flexDirection: "column" })}
            >
              <Box {...(isMobileView && { mr: 1.5 })}>
                <img src={greenCheckMark} alt="" height="40px" />
              </Box>
              <Box>
                <Typography style={{ textAlign: "center" }} variant="subtitle2">
                  Vehicle is Available
                </Typography>
              </Box>
            </Box>
          ) : blockedByQuote ? (
            <Box
              color={orange}
              display="flex"
              alignItems="center"
              {...(!isMobileView && { flexDirection: "column" })}
            >
              <Box {...(isMobileView && { mr: 1.5 })}>
                <img src={orangeUnavailableWarning} alt="" height="40px" />
              </Box>
              <Box>
                <Typography
                  style={{ textAlign: "center", maxWidth: "250px" }}
                  variant="subtitle2"
                >
                  Vehicle is assigned to an existing quote during this trip time
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              color={errorRed}
              display="flex"
              alignItems="center"
              {...(!isMobileView && { flexDirection: "column" })}
            >
              <Box {...(isMobileView && { mr: 1.5 })}>
                <img src={redUnavailableCross} alt="" height="40px" />
              </Box>
              <Box>
                <Typography
                  style={{ textAlign: "center", maxWidth: "250px" }}
                  variant="subtitle2"
                >
                  Vehicle is Unavailable
                </Typography>
              </Box>
            </Box>
          )}
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          color={black}
          alignSelf="baseline"
          flex={1}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            mb={1}
            height={64}
          >
            <Box color={grayDark}>
              <Typography variant="h5">PICK-UP</Typography>
            </Box>
            <Typography variant="subtitle2">{pickUpTime}</Typography>
            <Typography variant="caption">{pickUpLocation}</Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            mt={2.5}
            height={64}
          >
            <Box color={grayDark}>
              <Typography variant="h5">DROP-OFF</Typography>
            </Box>
            <Box display="flex">
              {isAutomatedDropTimeCalculation && (
                <AutomatedPricingIcon size="small" />
              )}
              <Box ml={0.5}>
                <Typography variant="subtitle2">{dropOffTime}</Typography>
              </Box>
            </Box>
            <Typography variant="caption">{dropOffLocation}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default CurrentTripScheduleBlock;
