import React from "react";

import { Box, Typography } from "@mui/material";

import SubscriptionPlansGridChart from "../utils/SubscriptionPlansGridChart";
import chartData from "./utils/data";

function OperationsBackOfficeSection() {
  return (
    <Box my={3}>
      <Typography variant="h3">Operations / Back Office</Typography>
      <Box my={2}>
        <SubscriptionPlansGridChart chartData={chartData} />
      </Box>
    </Box>
  );
}

export default OperationsBackOfficeSection;
