import { useEffect, useCallback, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment-timezone";
import isEqual from "lodash/isEqual";
import queryString from "query-string";

import { usePrevious } from "../../../globals/hooks";
import { DateRange } from "./types";

const isValidQueryDate = (queryDate) => {
  return queryDate ? moment(queryDate).isValid() : false;
};

type useQueryParamsToSetDatesProps = {
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
  mode: "reservations" | "dispatch" | "driver-payout";
};

function useQueryParamsToSetDates(props: useQueryParamsToSetDatesProps) {
  const { dateRange, setDateRange, mode } = props;

  // refs
  const initialMountFlag = useRef(false);

  // hooks
  const history = useHistory();
  const location = useLocation();

  // event handlers
  const setQueryParams = useCallback(
    (newParams) => {
      history.replace({
        pathname: location.pathname,
        search: queryString.stringify(newParams),
      });
    },
    [history, location]
  );

  // derived state
  const queryParams = queryString.parse(location.search) as {
    startDate: string;
    endDate: string;
  };
  const prevQueryParams = usePrevious(queryParams);
  const { startDate: queryStartDate, endDate: queryEndDate } = queryParams;
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    if (!initialMountFlag.current) {
      // on mount - set dates based on query params
      const startDateIsValid = isValidQueryDate(queryStartDate);
      const endDateIsValid = isValidQueryDate(queryEndDate);

      if (startDateIsValid || endDateIsValid) {
        // if only one is valid use that
        let startDate = startDateIsValid ? queryStartDate : queryEndDate;
        let endDate = endDateIsValid ? queryEndDate : queryStartDate;

        // ensure start date is before end date
        const startBeforeEnd = moment(startDate).isSameOrBefore(
          moment(endDate)
        );
        setDateRange(
          startBeforeEnd
            ? [
                moment(queryStartDate).format("YYYY-MM-DD"),
                moment(queryEndDate).format("YYYY-MM-DD"),
              ]
            : [
                moment(queryEndDate).format("YYYY-MM-DD"),
                moment(queryStartDate).format("YYYY-MM-DD"),
              ]
        );
      } else {
        // if no start or end date query param, check local storage
        const [localStorageStart, localStorageEnd] = JSON.parse(
          window.localStorage.getItem(`${mode}-customDateRange`) || "[]"
        );

        if (localStorageStart && localStorageEnd) {
          const formatLocalStart =
            moment(localStorageStart).format("YYYY-MM-DD");
          const formatLocalEnd = moment(localStorageEnd).format("YYYY-MM-DD");
          setDateRange([formatLocalStart, formatLocalEnd]);
        } else {
          // if nothing in local storage, default to today
          const today = moment().format("YYYY-MM-DD");
          setDateRange([today, today]);
        }
      }

      initialMountFlag.current = true;
    } else {
      // when date changes, set the query params
      // if no start date is provided, we assume we're using ALL, therefore remove the query params
      const dateParams = startDate
        ? {
            ...queryParams,
            ...(startDate &&
              startDate !== queryStartDate && {
                startDate: startDate,
              }),
            ...(endDate &&
              endDate !== queryEndDate && {
                endDate: endDate,
              }),
          }
        : {};

      if (!isEqual(dateParams, prevQueryParams)) {
        setQueryParams(dateParams);
      }
    }
  }, [
    startDate,
    endDate,
    initialMountFlag,
    prevQueryParams,
    queryEndDate,
    queryParams,
    queryStartDate,
    setDateRange,
    setQueryParams,
    mode,
  ]);
}

export default useQueryParamsToSetDates;
