import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMutation } from "@apollo/client";
import { useDebounce } from "use-debounce";

import { Box, Typography } from "@mui/material";

import { PhoneIcon } from "design-system/icons";
import {
  UPDATE_OPERATOR_SETTINGS,
  UPDATE_OPERATOR_MUTATION,
} from "globals/graphql";
import { formatPhoneNumber } from "globals/utils/phoneNumberFormatter/phoneNumberFormatter";
import { moovsBlue } from "design-system/colors";
import LabeledSwitchInput from "../LabeledSwitchInput";
import { useSnackbar } from "globals/hooks";
import InternationalPhoneInput from "components/inputs/InternationalPhoneInput";
import { Operator } from "types";

type PortPhoneNumberBlockProps = {
  operatorData: Operator;
  setSaveIndicatorState?: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function MoovsChatNumberAndVoiceNumber(props: PortPhoneNumberBlockProps) {
  const { setSaveIndicatorState, operatorData } = props;

  // hooks
  const snackbar = useSnackbar();
  const flag = useRef(false);

  // state
  const [moovsChatNumberPrimary, setMoovsChatNumberPrimary] = useState(null);
  const [operator, setOperator] = useState(null);
  const [debouncedOperator] = useDebounce(operator, 500);

  // derived state
  const { twilioPhoneNumber, moovsChatNumberAsPrimary } = operatorData;

  // mutations
  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
      setOperator(operatorData);
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error("Error updating operator");

      setOperator(operatorData);
      flag.current = false;
    },
  });
  const [updateOperator] = useMutation(UPDATE_OPERATOR_MUTATION, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error("Error updating operator");

      setOperator(operatorData);
      flag.current = false;
    },
  });

  // useCallbacks
  const handleDebouncedOperatorUpdate = useCallback(() => {
    setSaveIndicatorState("loading");

    updateOperator({
      variables: {
        input: {
          voicePhoneNumber: debouncedOperator.voicePhoneNumber,
          voicePhoneCountryCode: debouncedOperator.voicePhoneCountryCode,
          voicePhoneCountryDialCode:
            debouncedOperator.voicePhoneCountryDialCode,
          voicePhoneCountryName: debouncedOperator.voicePhoneCountryName,
          voicePhoneCountryFormat: debouncedOperator.voicePhoneCountryFormat,
        },
      },
    });
  }, [debouncedOperator, updateOperator, setSaveIndicatorState]);

  // event handlers
  const handleMoovsChatPrimaryNumberToggle = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setSaveIndicatorState("loading");
    setMoovsChatNumberPrimary(event.target.checked);

    updateOperatorSettings({
      variables: {
        input: {
          moovsChatNumberAsPrimary: event.target.checked,
        },
      },
    });
  };

  const handleVoicePhoneNumberChange = (value, country) => {
    setOperator({
      ...operator,
      voicePhoneNumber: value,
      voicePhoneCountryCode: country.countryCode,
      voicePhoneCountryDialCode: country.dialCode,
      voicePhoneCountryName: country.name,
      voicePhoneCountryFormat: country.format,
    });
  };

  // effects
  // handle update operator debounce
  useEffect(() => {
    if (!debouncedOperator) return;

    if (!flag.current) {
      flag.current = true;
    } else {
      handleDebouncedOperatorUpdate();
    }
  }, [debouncedOperator, handleDebouncedOperatorUpdate]);

  // set initial state
  useEffect(() => {
    if (operatorData && !operator) {
      flag.current = false;
      setOperator(operatorData);
      setMoovsChatNumberPrimary(moovsChatNumberAsPrimary);
    }
  }, [operatorData, operator, setOperator, moovsChatNumberAsPrimary]);

  return (
    <>
      <Box display="flex" flexDirection="column">
        {/* Moovs Chat Number */}
        <Box
          my={2}
          display="flex"
          flexDirection="row"
          alignItems="center"
          textAlign="center"
        >
          <Box>
            <PhoneIcon color={moovsBlue} />
          </Box>
          <Box ml={1}>
            <Typography variant="body2">
              {formatPhoneNumber(twilioPhoneNumber.phoneNumber).formatted}
            </Typography>
          </Box>
        </Box>

        {/* Moovs Chat Number Primary Toggle */}
        <Box mb={2}>
          <LabeledSwitchInput
            name="moovsChatPrimaryNumber"
            checked={moovsChatNumberPrimary}
            onChange={handleMoovsChatPrimaryNumberToggle}
            text="Use Moovs Chat Number as Primary number"
            subText="Moovs Chat Number will show on the customer portal and emails, calls and text will be forwarded to all member phone numbers"
          />
        </Box>

        {/* Voice Phone Number Field */}
        {!moovsChatNumberPrimary && (
          <Box my={3}>
            <InternationalPhoneInput
              value={operator?.voicePhoneNumberInternational}
              onChange={handleVoicePhoneNumberChange}
              dropdownWidth="580%"
              sx={{ width: "50%" }}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

export default MoovsChatNumberAndVoiceNumber;
