import React from "react";
import size from "lodash/size";

import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";

import { useScreenSize } from "globals/hooks";
import { MoovsMarketAppData } from "./hooks/useMoovsMarketApps";
import useMoovsMarketApps from "./hooks";
import MoovsMarketAppListItem from "./components";
import { grayDark } from "design-system/colors";

type MoovsMarketAppListProps = {
  onAppClick: (app: MoovsMarketAppData) => void;
};

function MoovsMarketAppList(props: MoovsMarketAppListProps) {
  const { onAppClick } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const { installedApps, availableApps, loading } = useMoovsMarketApps();

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="60vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box mt={isMobileView ? 1 : 3} mb={6}>
      {/* installed apps */}
      {!!size(installedApps) && (
        <Box mb={4}>
          <Typography variant="h3" mb={2}>
            Installed Apps
          </Typography>
          <Stack spacing={2}>
            {installedApps.map((app) => (
              <MoovsMarketAppListItem
                {...app}
                mode="installed"
                onClick={onAppClick}
              />
            ))}
          </Stack>
        </Box>
      )}

      {/* available apps */}
      <Box width="100%" sx={{ flexGrow: 1 }}>
        <Typography variant="h3" mb={2}>
          Available Apps
        </Typography>

        <Grid container spacing={2}>
          {availableApps
            .filter((app) => app.isPaidApp)
            .map((app) => (
              <Grid item xs={12} md={6} key={app.id}>
                <MoovsMarketAppListItem
                  {...app}
                  mode="available"
                  onClick={onAppClick}
                />
              </Grid>
            ))}
        </Grid>

        {/* Free Apps */}
        <Typography variant="h5" color={grayDark} mt={3} mb={1.5}>
          Free
        </Typography>
        <Grid container spacing={2}>
          {availableApps
            .filter((app) => !app.isPaidApp)
            .map((app) => (
              <Grid item xs={12} md={6} key={app.id}>
                <MoovsMarketAppListItem
                  {...app}
                  mode="available"
                  onClick={onAppClick}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default MoovsMarketAppList;
