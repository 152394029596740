import React from "react";

import { Box, Typography } from "@mui/material";

import BillingDisplay from "components/common/BillingDisplay";
import { reservationPaymentStatusLabelColors } from "design-system/colors";
import RoundChip from "design-system/components/chips/RoundChip";
import { PaidNotPaid } from "types";

type InfoPanelProps = {
  totalAmount: number;
  amountDue: number;
  paymentStatus: PaidNotPaid;
};

function InfoPanel(props: InfoPanelProps) {
  const { totalAmount, amountDue, paymentStatus } = props;

  return (
    <Box display="flex">
      <Box mr={3}>
        <Typography variant="overline">Total Price</Typography>
        <BillingDisplay amount={totalAmount} />
      </Box>
      <Box mr={4}>
        <Typography variant="overline">Amount Due</Typography>
        <BillingDisplay amount={amountDue} />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" mr={4}>
        <Typography variant="overline" textAlign="center">
          Status
        </Typography>
        <RoundChip {...reservationPaymentStatusLabelColors[paymentStatus]} />
      </Box>
    </Box>
  );
}

export default InfoPanel;
