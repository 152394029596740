import size from "lodash/size";

import { DynamicPricingVehicleType } from "./types";

type GetSelectedEnabledVehiclesProps = {
  vehicles: DynamicPricingVehicleType[];
  vehiclesAvailable: number;
};

// this return selected vehicle name,and if all enabled vehicles are selected
export const getSelectedEnabledVehicles = (
  props: GetSelectedEnabledVehiclesProps
) => {
  const { vehicles, vehiclesAvailable } = props;

  const selectedVehicleNames = vehicles
    .filter((vehicle) => !vehicle.disabled && vehicle.isSelected)
    .map((vehicle) => vehicle.value);

  return {
    selectedVehicleNames,
    allSelected: size(selectedVehicleNames) === vehiclesAvailable,
  };
};

// this will return selected vehicle ids
export const getSelectedVehicleIds = (vehicles: DynamicPricingVehicleType[]) =>
  vehicles
    .filter((vehicle) => !vehicle.disabled && vehicle.isSelected)
    .map((vehicle) => vehicle.id);
