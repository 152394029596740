import React from "react";

import { Box, CircularProgress } from "@mui/material";

import MoovsDialog from "../../MoovsDialog";
import useLoadDispatchLog from "./hooks/useLoadDispatchLog";
import MoovsTabs from "components/globals/MoovsTabs";
import { RideReportTab, DispatchLogTab } from "./components";
import useDispatchLogTabs, {
  DispatchLogDialogTab,
} from "./hooks/useDispatchLogTabs";

type DispatchLogDialogProps = {
  open: boolean;
  onClose: () => void;
  routeId: string;
};

function DispatchLogDialog(props: DispatchLogDialogProps) {
  const { open, onClose, routeId } = props;

  // hooks
  const { route, routeStatusLogs, loading } = useLoadDispatchLog({
    routeId,
    open,
  });
  const { tabMode, onTabModeChange } = useDispatchLogTabs(open);

  return (
    <MoovsDialog
      onClose={onClose}
      open={open}
      dialogTitle="Dispatch Log"
      tabs={
        <MoovsTabs
          onChange={onTabModeChange}
          value={tabMode}
          tabs={[
            {
              label: "DISPATCH LOG",
              value: DispatchLogDialogTab.DispatchLog,
            },
            {
              label: "RIDE REPORT",
              value: DispatchLogDialogTab.RideReport,
            },
          ]}
        />
      }
    >
      {loading || !route ? (
        <Box
          display="flex"
          flex="1"
          height="100%"
          width="100%"
          minHeight="50vh"
          alignItems="center"
          justifyContent="center"
          margin={2}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box height="100%">
          {
            [
              <DispatchLogTab
                route={route}
                routeStatusLogs={routeStatusLogs}
              />,
              <RideReportTab route={route} routeStatusLogs={routeStatusLogs} />,
            ][tabMode]
          }
        </Box>
      )}
    </MoovsDialog>
  );
}

export default DispatchLogDialog;
