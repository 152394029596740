import React from "react";

import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { FarmRelationshipEnum } from "types";
import { granite } from "design-system/colors";
import { getPassenger } from "globals/utils/passenger";

function PassengerColumn(props: GridRenderCellParams<string>) {
  const { row } = props;

  const isFarmee = row.farmRelationship === FarmRelationshipEnum.Farmee;

  const passenger = getPassenger(row.contact, row.tempPassenger, isFarmee);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
    >
      <Typography variant="body2" textOverflow="ellipsis" overflow="hidden">
        {passenger.name}
      </Typography>
      <Typography
        color={granite}
        variant="caption"
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {passenger.mobilePhone}
      </Typography>
    </Box>
  );
}

export default PassengerColumn;
