import React from "react";

type InstantPayoutIconProps = {
  color?: string;
};

const InstantPayoutIcon = (props: InstantPayoutIconProps) => {
  const { color } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.94673 4.22222C7.63967 2.83244 9.79544 2 12.1429 2C17.5868 2 22 6.47715 22 12C22 17.5228 17.5868 22 12.1429 22C9.79544 22 7.63967 21.1676 5.94673 19.7778M13 6.5873V8.60751M13 8.60751V12.1429M13 8.60751H15.7381M13 8.60751H11.631C11.1226 8.60751 10.6351 8.79374 10.2757 9.12525C9.91622 9.45675 9.71428 9.90637 9.71428 10.3752C9.71428 10.844 9.91622 11.2936 10.2757 11.6251C10.6351 11.9566 11.1226 12.1429 11.631 12.1429H13M13 12.1429V15.6782M13 12.1429H14.369C14.8774 12.1429 15.3649 12.3291 15.7243 12.6606C16.0838 12.9921 16.2857 13.4417 16.2857 13.9105C16.2857 14.3794 16.0838 14.829 15.7243 15.1605C15.3649 15.492 14.8774 15.6782 14.369 15.6782H13M13 15.6782V17.6984M13 15.6782H9.71428M1.5 12H7M7 12L4.0625 15M7 12L4.0625 9"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InstantPayoutIcon;
