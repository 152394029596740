import React from "react";
import { Box, Link, Typography } from "@mui/material";

import driverAppAndroid from "design-system/illustrations/moovsMarketApps/driverAppAndroid.svg";
import driverAppiOS from "design-system/illustrations/moovsMarketApps/driverAppiOS.svg";
import { useAnalytics, useScreenSize } from "globals/hooks";

const DriverAppAdditionalInfo = () => {
  // hooks
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();

  // event handler
  const handleClick = () => {
    track("market_driverAppOpened");
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" mb={2} justifyContent={isMobileView && "center"}>
        <Typography variant="overline"> Get Driver App</Typography>
      </Box>

      <Box display="flex" justifyContent={isMobileView && "center"}>
        <Box onClick={handleClick}>
          <Link
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.moovs.driver"
          >
            <img src={driverAppAndroid} alt={"driverAppAndroidIcon"} />
          </Link>
        </Box>
        <Box ml={2} onClick={handleClick}>
          <Link
            target="_blank"
            href="https://apps.apple.com/lc/app/moovs-driver/id1613250751"
          >
            <img src={driverAppiOS} alt={"driverAppiOSIcon"} />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default DriverAppAdditionalInfo;
