import React from "react";

import { Box, IconButton, MenuItem, TextField } from "@mui/material";
import { DefaultScreenVariant } from "types";
import {
  DashboardIcon,
  DispatchIcon,
  InfoIcon,
  InvoiceIcon,
  ListIcon,
  PayoutIcon,
  QuotesIcon,
  TripIcon,
} from "design-system/icons";
import { black } from "design-system/colors";
import MoovsTooltip from "components/MoovsTooltip";

// constants
const {
  Quotes,
  Reservations,
  Dispatch,
  DriverTracking,
  Invoices,
  Payables,
  Dashboard,
} = DefaultScreenVariant;

type DefaultScreenCreateDropdownProps = {
  value: string;
  error: boolean;
  helperText?: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
};

function DefaultScreenCreateDropdown(props: DefaultScreenCreateDropdownProps) {
  const { value, error, helperText, onChange } = props;

  return (
    <TextField
      select
      fullWidth
      required
      variant="outlined"
      name="defaultScreen"
      label="Default Screen"
      value={value}
      error={error}
      helperText={helperText}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <Box sx={{ mr: 2 }}>
            <MoovsTooltip
              noWrap
              tooltipText="This will be the screen you land on when you log in"
            >
              <IconButton size="large">
                <InfoIcon size="large" />
              </IconButton>
            </MoovsTooltip>
          </Box>
        ),
      }}
    >
      {/* quotes */}
      <MenuItem value={Quotes}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <QuotesIcon size="small" color={black} />
          <Box ml={1}>Quotes</Box>
        </Box>
      </MenuItem>

      {/* reservations */}
      <MenuItem value={Reservations}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <TripIcon size="small" color={black} />
          <Box ml={1}>Reservations</Box>
        </Box>
      </MenuItem>

      {/* dispatch */}
      <MenuItem value={Dispatch}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <ListIcon size="small" color={black} />
          <Box ml={1}>Dispatch</Box>
        </Box>
      </MenuItem>

      {/* driver tracking */}
      <MenuItem value={DriverTracking}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <DispatchIcon size="small" color={black} />
          <Box ml={1}>Driver Tracking</Box>
        </Box>
      </MenuItem>

      {/* invoices */}
      <MenuItem value={Invoices}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <InvoiceIcon size="small" color={black} />
          <Box ml={1}>Invoices</Box>
        </Box>
      </MenuItem>

      {/* payables */}
      <MenuItem value={Payables}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <PayoutIcon size="small" color={black} />
          <Box ml={1}>Payables</Box>
        </Box>
      </MenuItem>

      {/* dashboard */}
      <MenuItem value={Dashboard}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <DashboardIcon size="small" color={black} />
          <Box ml={1}>Dashboard</Box>
        </Box>
      </MenuItem>
    </TextField>
  );
}

export default DefaultScreenCreateDropdown;
