import { Box, MenuItem } from "@mui/material";
import { black } from "design-system/colors";
import { LabeledInlineSelect } from "design-system/components/inputs";
import {
  QuotesIcon,
  TripIcon,
  ListIcon,
  DispatchIcon,
  InvoiceIcon,
  PayoutIcon,
  DashboardIcon,
} from "design-system/icons";
import React from "react";
import { DefaultScreenVariant } from "types";

// constants
const {
  Quotes,
  Reservations,
  Dispatch,
  DriverTracking,
  Invoices,
  Payables,
  Dashboard,
} = DefaultScreenVariant;

type DefaultScreenUpdateDropdownProps = {
  value: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
};

function DefaultScreenUpdateDropdown(props: DefaultScreenUpdateDropdownProps) {
  const { value, onChange } = props;

  return (
    <LabeledInlineSelect
      label="Default Screen"
      value={value}
      onChange={onChange}
      infoTooltipText="This will be the screen you land on when you log in"
    >
      {/* quotes */}
      <MenuItem value={Quotes}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <QuotesIcon size="small" color={black} />
          <Box ml={1}>Quotes</Box>
        </Box>
      </MenuItem>

      {/* reservations */}
      <MenuItem value={Reservations}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <TripIcon size="small" color={black} />
          <Box ml={1}>Reservations</Box>
        </Box>
      </MenuItem>

      {/* dispatch */}
      <MenuItem value={Dispatch}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <ListIcon size="small" color={black} />
          <Box ml={1}>Dispatch</Box>
        </Box>
      </MenuItem>

      {/* driver tracking */}
      <MenuItem value={DriverTracking}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <DispatchIcon size="small" color={black} />
          <Box ml={1}>Driver Tracking</Box>
        </Box>
      </MenuItem>

      {/* invoices */}
      <MenuItem value={Invoices}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <InvoiceIcon size="small" color={black} />
          <Box ml={1}>Invoices</Box>
        </Box>
      </MenuItem>

      {/* payables */}
      <MenuItem value={Payables}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <PayoutIcon size="small" color={black} />
          <Box ml={1}>Payables</Box>
        </Box>
      </MenuItem>

      {/* dashboard */}
      <MenuItem value={Dashboard}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <DashboardIcon size="small" color={black} />
          <Box ml={1}>Dashboard</Box>
        </Box>
      </MenuItem>
    </LabeledInlineSelect>
  );
}

export default DefaultScreenUpdateDropdown;
