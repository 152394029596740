import React, { ChangeEvent } from "react";

import { TextField } from "@mui/material";

import { NumberFormatDollar } from "design-system/components/inputs/NumberFormat";
import AutomatedBaseRateButton from "components/buttons/AutomatedBaseRateButton";
import { BRAStatusEnum } from "components/buttons/types";
import {
  normalizeValueToBeDisplayedAsDollar,
  DollarPercentVariant,
} from "components/pricing/utils";
import { normalizeOnInputDollarValue } from "components/pricing/utils/displayUtils";
import { BaseRateAutomation } from "types";

type FlatBaseRateFieldProps = {
  flatRate: number;
  onFlatRateInput: (flatRate: number) => void;
  onActivateBRAClick: () => void;
  baseRateAmtError: string;
  BRAProps: {
    automatedBaseRate: BaseRateAutomation;
    hasAutomatedBaseRate: boolean;
    BRAStatus: BRAStatusEnum;
    isLoadingBRA: boolean;
  };
  isFarmedRoute?: boolean;
};

const { Dollar } = DollarPercentVariant;

function FlatBaseRateField(props: FlatBaseRateFieldProps) {
  const {
    flatRate,
    baseRateAmtError,
    onFlatRateInput,
    onActivateBRAClick,
    BRAProps: {
      automatedBaseRate,
      hasAutomatedBaseRate,
      BRAStatus,
      isLoadingBRA,
    },
    isFarmedRoute,
  } = props;

  // event handlers
  const handleFlatRateInput = (e: ChangeEvent<HTMLInputElement>) => {
    onFlatRateInput(normalizeOnInputDollarValue(e));
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      placeholder="Enter Amount"
      name="baseRateAmt"
      onInput={handleFlatRateInput}
      value={normalizeValueToBeDisplayedAsDollar({
        value: flatRate,
        variant: Dollar,
      })}
      InputProps={{
        inputComponent: NumberFormatDollar as any,
        ...((hasAutomatedBaseRate || isLoadingBRA) &&
          !isFarmedRoute && {
            endAdornment: (
              <AutomatedBaseRateButton
                automatedBaseRate={automatedBaseRate}
                BRAStatus={BRAStatus}
                isLoadingBRA={isLoadingBRA}
                onActivateBRAClick={onActivateBRAClick}
              />
            ),
          }),
      }}
      {...(!!baseRateAmtError && {
        error: true,
        helperText: baseRateAmtError,
      })}
    />
  );
}
export default FlatBaseRateField;
