import React from "react";

import { Box, Typography } from "@mui/material";

import { grayLight, moovsBlue } from "design-system/colors";
import { ExternalLinkIcon, HelpIcon } from "design-system/icons";
import { WidgetCode } from "./components/WidgetCode";
import { TestWidgetConnection } from "./components/TestWidgetConnection";
import { InstallationStep } from "./components/InstallationStep";
import { useAnalytics } from "globals/hooks";

function InstallationSection() {
  const { track } = useAnalytics();

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize={20} fontWeight={600}>
          How to add widget to website
        </Typography>
        <Box
          id="fab-help"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={120}
          sx={{ cursor: "pointer" }}
          onClick={() =>
            track("customerPortalSettings_helpRequested", {
              location: "Widget",
            })
          }
        >
          <Typography fontSize={16} fontWeight={500} color={moovsBlue}>
            Ask for help
          </Typography>
          <HelpIcon size="small" color={moovsBlue} />
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        bgcolor={grayLight}
        borderRadius={1}
        px={4}
        py={2}
        flex={1}
        gap={3}
      >
        <InstallationStep
          stepNumber={1}
          title="Copy the code below"
          subtitle='The code below must be pasted into your website builder editor. Copy the code by clicking on the "Copy code" button.'
        >
          <WidgetCode />
        </InstallationStep>
        <InstallationStep
          stepNumber={2}
          title="Open the editor of your website builder, paste the code and save."
        >
          <a
            href="https://intercom.help/moovs-05c940f1970e/en/articles/8549265-how-to-add-widget-to-website-step-by-step-guide"
            target="_blank"
            rel="noreferrer"
            onClick={() => track("portalWidget_guideOpened")}
          >
            <Typography
              mt={1}
              display="flex"
              alignItems="center"
              color={moovsBlue}
              fontWeight={500}
            >
              Step-by-step guide&nbsp;&nbsp;
              <ExternalLinkIcon color={moovsBlue} />
            </Typography>
          </a>
        </InstallationStep>
        <InstallationStep
          stepNumber={3}
          title="Test to make sure the booking tool was successfully added on your website"
          subtitle="Click the link to get detailed, step-by-step instructions based on your specific website builder."
        >
          <TestWidgetConnection />
        </InstallationStep>
      </Box>
    </Box>
  );
}

export default InstallationSection;
