/**
 * @file VehiclesListItem.tsx
 * Card with vehicle information.
 *
 * components:
 * VehiclesListItem
 *
 * author: sienag
 */
import React from "react";

import { Box, Card, CardActionArea, Typography } from "@mui/material";

import { Vehicle } from "../../types";
import AutomatedPricingIcon from "../../design-system/icons/info/AutomatedPricingIcon";
import WebsiteBrowserIcon from "../../design-system/icons/navigation/WebsiteBrowserIcon";

const styles = {
  requestItemText: {
    fontSize: "12px",
    lineHeight: 1.33,
  },
  vehiclePhoto: {
    borderRadius: "4px",
    width: 100, // 4:3 aspect ratio
    height: 75,
  },
};

function VehiclesListItemDesktop({
  vehicle,
  onClick,
}: {
  vehicle: Vehicle;
  onClick: (vehicleId: string) => void;
}) {
  const vehicleName = vehicle.name;
  const vehicleType = vehicle.vehicleType.typeName;
  const vehiclePlateNo = vehicle.licensePlate;
  const vehiclePic =
    vehicle.photos && vehicle.photos[0] && vehicle.photos[0].url;

  return (
    <Card variant="outlined">
      <CardActionArea disableRipple onClick={() => onClick(vehicle.id)}>
        <Box
          display="flex"
          flexDirection="row"
          flex="1"
          p={2}
          alignItems="center"
        >
          {/* Vehicle Name */}
          <Box display="flex" flex="4" alignItems="center">
            <Typography data-testid="vehicle-name" variant="h5">
              {vehicleName}
            </Typography>

            {vehicle.enableBaseRateAutomation && (
              <Box ml={1} data-testid="base-rate-automation-icon">
                <AutomatedPricingIcon size="small" />
              </Box>
            )}
            {vehicle.publishedToDudaSite && (
              <Box ml={1} data-testid="duda-website-publish-icon">
                <WebsiteBrowserIcon size="small" />
              </Box>
            )}
          </Box>

          {/* Vehicle Type */}
          <Box display="flex" flex="1" data-testid="vehicle-type">
            <Typography variant="caption">{vehicleType}</Typography>
          </Box>
          {/* Vehicle Plate No. */}
          <Box display="flex" flex="1" data-testid="vehicle-plate">
            <Typography variant="caption">{vehiclePlateNo}</Typography>
          </Box>

          {/* Vehicle Pic */}
          <Box display="flex" data-testid="vehicle-img">
            <Box
              component="img"
              alt="Vehicle Preview"
              src={vehiclePic}
              sx={[{ objectFit: "cover" }, styles.vehiclePhoto]} //check this
            />
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default VehiclesListItemDesktop;
