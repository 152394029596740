import { useMutation } from "@apollo/client";
import { Box } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";

import { useSnackbar } from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { useState } from "react";

import GuestCard from "./GuestCard";
import { getPassengerNameFromTrip } from "../utils";
import { Trip } from "types";
import { UPDATE_CALENDAR_TRIP_INVITE_MUTATION } from "globals/graphql/tripCalendarInvites.graphql";

type AddCalendarTripInvitesDialogProps = {
  open: boolean;
  onClose: () => void;
  trip: Trip;
};

function AddCalendarTripInvitesDialog(
  props: AddCalendarTripInvitesDialogProps
) {
  const { open, onClose, trip } = props;

  const tripHasDriver = Boolean(trip.routes[0]?.routeDriver?.driver?.email);
  const tripHasOrganizer = Boolean(trip.organizer);
  const { firstName, lastName } = getPassengerNameFromTrip(trip);
  const isPassengerBookingContact =
    trip.contact.id === trip.request.bookingContact.id &&
    !trip.tempPassenger?.name;
  const isTemporaryPassenger = !!trip.tempPassenger?.name;

  const [
    isPassengerAddedToCalendarInvite,
    setIsPassengerAddedToCalendarInvite,
  ] = useState(trip.calendarTripInvitePassengerEnabled);
  const [
    isBookingContactAddedToCalendarInvite,
    setIsBookingContactAddedToCalendarInvite,
  ] = useState(trip.calendarTripInviteBcEnabled);

  const hasTripInviteSettingsChanged =
    isBookingContactAddedToCalendarInvite !==
      trip.calendarTripInviteBcEnabled ||
    isPassengerAddedToCalendarInvite !==
      trip.calendarTripInvitePassengerEnabled;

  const snackbar = useSnackbar();

  const [updateCalendarTripInvite, { loading: isLoading }] = useMutation(
    UPDATE_CALENDAR_TRIP_INVITE_MUTATION,
    {
      onCompleted() {
        snackbar.success("Invites for this trip have been updated!");
        onClose();
      },
      onError(error) {
        console.error(error);
        snackbar.error(
          getErrorMessage(error) ||
            "There were issues updating invites for this trip"
        );
      },
    }
  );

  const handleUpdateGuestList = () => {
    updateCalendarTripInvite({
      variables: {
        input: {
          tripId: trip.id,
          calendarTripInviteBcEnabled: isBookingContactAddedToCalendarInvite,
          calendarTripInvitePassengerEnabled: isPassengerAddedToCalendarInvite,
        },
      },
    });
  };

  const handleUpdateBookingContactTripInvite = () => {
    setIsBookingContactAddedToCalendarInvite(
      (isBookingContactAddedToCalendarInvite) =>
        !isBookingContactAddedToCalendarInvite
    );
  };

  const handleUpdatePassengerTripInvite = () => {
    setIsPassengerAddedToCalendarInvite(
      (isPassengerAddedToCalendarInvite) => !isPassengerAddedToCalendarInvite
    );
  };

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      closeButtonText="Cancel"
      dialogTitle="Guests"
      size="sm"
      acceptButtonText="Confirm and send"
      acceptDisabled={isLoading || !hasTripInviteSettingsChanged}
      hideLoadingIndicator={!isLoading}
      onAccept={handleUpdateGuestList}
    >
      <Box display="flex" flexDirection="column" flexWrap="wrap">
        {tripHasOrganizer && (
          <GuestCard
            id={trip.organizer.id}
            firstName={trip?.organizer.firstName}
            lastName={trip?.organizer.lastName}
            checked={false}
            onChange={() => {}}
            guestType={null}
          />
        )}

        {tripHasDriver && (
          <GuestCard
            id={trip.routes[0]?.routeDriver?.id}
            firstName={trip.routes[0]?.routeDriver?.driver?.firstName}
            lastName={trip.routes[0]?.routeDriver?.driver?.lastName}
            checked={false}
            guestType="DRIVER"
          />
        )}

        <GuestCard
          id={trip.request.bookingContact.id}
          firstName={trip.request.bookingContact.firstName}
          lastName={trip.request.bookingContact.lastName}
          checked={isBookingContactAddedToCalendarInvite}
          onChange={handleUpdateBookingContactTripInvite}
          disabled={false}
          guestType="BOOKING CONTACT"
          isToggleVisible
        />

        {!isPassengerBookingContact && !isTemporaryPassenger && (
          <GuestCard
            id={trip.contact.id || trip.tempPassenger.name}
            firstName={firstName}
            lastName={lastName}
            checked={isPassengerAddedToCalendarInvite}
            onChange={handleUpdatePassengerTripInvite}
            disabled={false}
            guestType="PASSENGER"
            isToggleVisible
          />
        )}
      </Box>
    </MoovsDialog>
  );
}

export default AddCalendarTripInvitesDialog;
