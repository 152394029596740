import React, { ChangeEvent, useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/client";

import {
  Box,
  Paper,
  Hidden,
  Typography,
  CircularProgress,
} from "@mui/material";

import { LOAD_INVOICES_QUERY } from "../../globals/graphql";
import GQLQueryStatusIndicator from "../../components/GQLQueryStatusIndicator";
import InvoicesList from "../../components/invoices/InvoicesList";
import EntityHeader from "../../components/common/EntityHeader";
import EmptyList from "../../components/globals/EmptyList";
import { black, grayLight } from "../../design-system/colors";
import MoovsTabs from "../../components/globals/MoovsTabs";
import { Invoice } from "../../types";
import { currency } from "../../globals/utils/helpers";
import { useAnalytics, useScreenSize } from "../../globals/hooks";
import theme from "theme";

const NAV_WIDTH = "250px";

const styles = {
  container: {
    maxWidth: "852px",
    margin: "auto",
  },
  header: {
    alignItems: "center",
    paddingBottom: "12px",
    color: black,
    opacity: 0.6,
  },
  tabsContainer: {
    maxWidth: "900px",
    margin: "auto",
    padding: `0px ${theme.spacing(2)} 0px ${theme.spacing(2)}`,
    [theme.breakpoints.up("lg")]: {
      padding: `0px ${theme.spacing(2)} 0px ${theme.spacing(3)}`,
      marginBottom: "40px",
    },
  },
  table: {
    maxWidth: "100vw",
    [theme.breakpoints.up("sm")]: {
      maxWidth: `calc(100vw - ${NAV_WIDTH})`,
    },
    overflowX: "auto",
    overflowY: "hidden",
    paddingLeft: theme.spacing(3),
    display: "flex",
    flex: 2,
    height: "100%",
  },
  list: {
    minWidth: "800px",
    width: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    height: "100%",
    paddingRight: "24px",
  },
};

export const getOverallAmountDue = (
  invoices: Invoice[],
  status: "PENDING" | "OUTSTANDING"
) => {
  if (!invoices) {
    return 0;
  }

  return invoices
    .filter((invoice) =>
      invoice.isOutstanding
        ? status === "OUTSTANDING"
        : invoice.status === status
    )
    .reduce((amountDue, invoice) => {
      return amountDue + invoice.amountDue;
    }, 0);
};

const getOverallAmountPaid = (invoices: Invoice[]) => {
  if (!invoices) {
    return 0;
  }

  return invoices
    .filter((invoice) => invoice.status === "PAID")
    .reduce((totalAmountPaid, invoice) => {
      return totalAmountPaid + invoice.totalAmount;
    }, 0);
};

function InvoicesPage() {
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();

  // state
  const [invoiceSearch, setInvoiceSearch] = useState("");
  const [tabMode, setTabMode] = useState("PAID");

  // queries
  const {
    data: invoicesData,
    error: invoicesError,
    refetch: invoicesRefetch,
    networkStatus: invoicesNetworkStatus,
    loading: invoicesLoading,
  } = useQuery(LOAD_INVOICES_QUERY, {
    variables: {
      invoiceSearch,
    },
    fetchPolicy: "network-only",
  });

  // event handlers
  const handleTabChange = (newValue: string) => {
    setTabMode(newValue);
  };

  const handleInvoiceSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInvoiceSearch(event.target.value);
  };

  const handleInvoiceSearchFocus = () => {
    track("invoice_searched");
  };

  const invoices = invoicesData?.loadInvoices.edges.map(({ node }) => node);

  // filter for invoices to display based on tabMode
  const invoicesToDisplay = invoices?.filter((invoice: Invoice) =>
    invoice.isOutstanding
      ? tabMode === "OUTSTANDING"
      : invoice.status === tabMode
  );

  return (
    <>
      <Helmet>
        <title>Invoices | Moovs</title>
      </Helmet>

      <Box justifyContent="center" width="100%">
        <Box
          position="sticky"
          top={isMobileView ? "56px" : "78px"}
          zIndex={10}
          width="100%"
        >
          <Paper
            square
            style={{ borderBottom: `1px solid ${grayLight}` }}
            elevation={0}
          >
            <Box data-testid="entity-header" maxWidth="900px" margin="auto">
              <EntityHeader
                title="Invoices"
                entityName="Invoice"
                createEntityRoute="/invoices/create"
                createButtonMode="link"
                onGlobalSearchChange={handleInvoiceSearchChange}
                onGlobalSearchFocus={handleInvoiceSearchFocus}
                globalSearch={invoiceSearch}
              />
              <Box data-testid="invoice-status-tabs" sx={styles.tabsContainer}>
                {(!invoicesLoading || invoicesData) && (
                  <MoovsTabs
                    onChange={handleTabChange}
                    value={tabMode}
                    tabs={[
                      {
                        label: "PAID",
                        bigLabel: currency(getOverallAmountPaid(invoices)),
                        value: "PAID",
                      },
                      {
                        label: "OUTSTANDING",
                        bigLabel: currency(
                          getOverallAmountDue(invoices, "OUTSTANDING")
                        ),
                        value: "OUTSTANDING",
                      },
                      {
                        label: "PENDING",
                        bigLabel: currency(
                          getOverallAmountDue(invoices, "PENDING")
                        ),
                        value: "PENDING",
                      },
                    ]}
                  />
                )}
              </Box>
            </Box>

            <Hidden mdDown>
              <Box
                sx={[
                  {
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  },
                  styles.header,
                ]}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  flex="1"
                  pt={3}
                  pb={1}
                  px={6}
                  maxWidth="900px"
                  margin="auto"
                >
                  <Box display="flex" flex="1">
                    <Typography variant="overline">INVOICE NO.</Typography>
                  </Box>
                  <Box display="flex" flex="2">
                    <Typography variant="overline">BILLING CONTACT</Typography>
                  </Box>
                  <Box display="flex" flex="1">
                    <Typography variant="overline">DUE DATE</Typography>
                  </Box>
                  <Box display="flex" flex="1">
                    <Typography variant="overline">CREATE DATE</Typography>
                  </Box>
                  <Box display="flex" flex="1">
                    <Typography variant="overline">TOTAL AMOUNT</Typography>
                  </Box>
                </Box>
              </Box>
            </Hidden>
          </Paper>
        </Box>

        <Box
          data-testid="invoices-list-container"
          sx={[
            {
              display: "flex",
              flex: 1,
              overflowY: "auto",
              flexDirection: "column",
            },
            styles.container,
          ]}
        >
          {(invoicesLoading || !invoicesData) && (
            <Box mt={3}>
              <GQLQueryStatusIndicator
                name="Invoices"
                error={invoicesError}
                data={invoicesData}
                loading={invoicesLoading}
                networkStatus={invoicesNetworkStatus}
                refetch={invoicesRefetch}
              />
            </Box>
          )}

          {invoicesLoading && !invoices ? (
            <Box
              display="flex"
              flexDirection="row"
              flex="1"
              px={3}
              mb={1}
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : invoicesToDisplay?.length > 0 ? (
            <Box>
              <InvoicesList invoices={invoicesToDisplay} />
            </Box>
          ) : (
            <Box
              height="calc(100vh - 170px)"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box display="flex">
                {!invoicesLoading && !invoicesError && (
                  <EmptyList
                    title={
                      {
                        PENDING: "You have no pending invoices!",
                        PAID: "You have no paid invoices at the moment",
                        OUTSTANDING: "You have no outstanding invoices!",
                      }[tabMode]
                    }
                    copy={
                      {
                        PENDING: "All invoices are currently up to date",
                        PAID: "",
                        OUTSTANDING: "All invoices are currently up to date",
                      }[tabMode]
                    }
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default InvoicesPage;
