import React from "react";
import moment from "moment-timezone";

import { Box, IconButton, Typography } from "@mui/material";

import MoovsTooltip from "components/MoovsTooltip";
import { orange } from "design-system/colors";
import { ClockIcon, EstimationIcon, WarningIcon } from "design-system/icons";
import { convertMinutesToHoursMinutes } from "globals/utils/helpers";

type DurationDisplayProps = {
  totalDuration?: number;
  estimatedDuration?: number;
  isEstimated?: boolean;
  dateTime?: string;
  size?: "small";
  hideClockIcon?: boolean;
  renderDropOffWarning?: boolean;
};

function DurationDisplay(props: DurationDisplayProps) {
  const {
    totalDuration,
    estimatedDuration,
    dateTime,
    size,
    isEstimated,
    hideClockIcon,
    renderDropOffWarning,
  } = props;

  const formattedTime = dateTime ? moment.utc(dateTime).format("LT") : null;

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {/* duration */}
      {!hideClockIcon && (
        <Box display="flex" alignItems="center">
          <ClockIcon {...(size === "small" && { size: "small" })} />
        </Box>
      )}
      {isEstimated ? (
        <Box display="flex" alignItems="center">
          <Box {...(!hideClockIcon && { ml: 1 })}>
            <Typography variant="body2">
              {formattedTime ||
                convertMinutesToHoursMinutes(estimatedDuration, "formal")}
            </Typography>
          </Box>
          <Box ml={1.5}>
            {renderDropOffWarning ? (
              <MoovsTooltip tooltipText="Drop off has no date & time. ETA may be inaccurate">
                <IconButton
                  size="large"
                  sx={{
                    "&.MuiButtonBase-root": {
                      padding: "0px 0px 1px 0px",
                    },
                  }}
                >
                  <WarningIcon color={orange} />
                </IconButton>
              </MoovsTooltip>
            ) : (
              <EstimationIcon size="small" />
            )}
          </Box>
        </Box>
      ) : (
        <Box {...(!hideClockIcon && { ml: 1 })} mr={1}>
          <Typography variant="body2">
            {formattedTime ||
              convertMinutesToHoursMinutes(totalDuration, "formal")}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default DurationDisplay;
