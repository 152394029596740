import React from "react";
import moment from "moment-timezone";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";

import { ChevronDownIcon, ClickedIcon } from "design-system/icons";
import { grayLight } from "design-system/colors";
import theme from "theme";
import { SalesAutomationText } from "types";
import LogItemTextBlock from "./LogItemTextBlock";

type LogItemAccordionBlockProps = {
  text: SalesAutomationText;
};

const LogItemAccordionBlock = (props: LogItemAccordionBlockProps) => {
  const { text } = props;

  return (
    <Accordion variant="outlined">
      <AccordionSummary
        expandIcon={<ChevronDownIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ marginTop: theme.spacing(0.5), marginBottom: theme.spacing(0.5) }}
      >
        <LogItemTextBlock text={text} />
      </AccordionSummary>
      <AccordionDetails
        style={{
          borderTop: `1px solid ${grayLight}`,
          margin: "0 16px",
          padding: 0,
        }}
      >
        <Box display="flex" flex="1" flexDirection="column" mt={2} mb={1}>
          {text.impressions.map((date) => {
            return (
              <Box
                key={date}
                minHeight="18px"
                display="flex"
                flexDirection="row"
                alignItems="center"
                flex="1"
                mb={1}
              >
                {/* recipiants clicks a link on email */}
                {
                  <Box
                    display="flex"
                    flexDirection="row"
                    whiteSpace="pre"
                    flexWrap="wrap"
                    pl={0.5}
                  >
                    <ClickedIcon />
                    <Typography
                      style={{ fontSize: "12px", marginLeft: "20px" }}
                    >
                      Clicked link on{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {moment(date).format("L LT")}
                    </Typography>
                  </Box>
                }
              </Box>
            );
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default LogItemAccordionBlock;
