import React from "react";

import { Box, Typography } from "@mui/material";

import UserAccessPermissions from "./UserAccessPermissions";
import { UserAccessPermission } from "types";
import { grayDark } from "design-system/colors";

type MemberPermissionsProps = {
  activePermissions: UserAccessPermission[];
  allPermissions: UserAccessPermission[];
  handleUserAccessPermissionsChange: (
    modifiedAccessPermission: UserAccessPermission
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  homeError: string;
};

function MemberPermissions(props: MemberPermissionsProps) {
  const {
    activePermissions,
    allPermissions,
    handleUserAccessPermissionsChange,
    homeError,
  } = props;

  return (
    <Box mb={2}>
      <Typography variant="h5">Member Permissions</Typography>
      <Typography variant="body2" color={grayDark} mt={1}>
        Member has access to all of the pages that are turned on below:
      </Typography>
      <UserAccessPermissions
        activePermissions={activePermissions}
        allPermissions={allPermissions}
        handleUserAccessPermissionsChange={handleUserAccessPermissionsChange}
        homeError={homeError}
      />
    </Box>
  );
}

export default MemberPermissions;
