import React from "react";
import first from "lodash/first";
import { useQuery } from "@apollo/client";

import { Box, Typography, Divider, Button } from "@mui/material";

import {
  RoundTripVariant,
  Trip,
  PlanVariant,
} from "../../../../../../../../../types";
import BillingDisplay from "../../../../../../../../../components/common/BillingDisplay";
import {
  grayDark,
  grayLight,
} from "../../../../../../../../../design-system/colors";
import { getTripContactFromTrip } from "../../../../../../../requests.utils";
import MultiLineDateTime from "../../../../../../../../../components/MultiLineDateTime";
import OverflowTooltip from "design-system/components/OverflowTooltip";
import { getTripIconAndCategoryType } from "pages/requests/utils/getTripIconAndCategoryType";
import { calculateTripEndDateTime } from "pages/requests/utils/calculateTripEndDateTime";
import FlightChangeDisplay from "../../../../../../ReservationOverview/TripItineraryBlock/components/FlightChangeDisplay";
import { LOAD_TRIP_CONFLICT_QUERY } from "globals/graphql";
import MoovsTooltip from "components/MoovsTooltip";
import CalendarErrorIcon from "design-system/icons/info/CalendarErrorIcon";
import { UpdateQuoteActionButtonVariant } from "../../../../../types";
import { useOperator } from "globals/hooks";

type TripInformationMobileProps = {
  confirmationNumber: string;
  trip: Trip;
  btnVariant: UpdateQuoteActionButtonVariant;
};

function TripInformationDesktop(props: TripInformationMobileProps) {
  const { confirmationNumber, trip, btnVariant } = props;
  const { stops, totalDuration, tripCategory, roundTripVariant } = trip;
  const vehicleId = first(trip.routes).vehicle?.id;

  // hooks
  const operator = useOperator();

  const isProOrAbove =
    operator?.plan === PlanVariant.Pro || operator?.plan === PlanVariant.Vip;

  // query
  const { data } = useQuery(LOAD_TRIP_CONFLICT_QUERY, {
    variables: {
      tripId: trip.id,
      vehicleId: vehicleId,
    },
    fetchPolicy: "cache-and-network",
    skip: !vehicleId || !isProOrAbove,
  });

  // derived state
  const tripContact = getTripContactFromTrip(trip);
  const tripVehicle =
    trip && trip.routes.length && first(trip.routes).vehicle?.name;
  const needsPricing = btnVariant === UpdateQuoteActionButtonVariant.ADD_PRICE;

  const { text, icon } = getTripIconAndCategoryType({
    tripCategory,
    roundTripVariant,
    hideRoundTripVariant: true,
  });

  const shouldHideTripIconAndCategory =
    roundTripVariant !== RoundTripVariant.Return;

  // only show end time for hourly rides
  const endDateTime = calculateTripEndDateTime({
    tripCategory,
    stops,
    totalDuration,
  });

  const requestItemTextStyles = {
    fontSize: "12px",
    lineHeight: 1.33,
    maxWidth: "154px",
  } as const;

  return (
    <Box
      display="flex"
      flexDirection="row"
      flex="1"
      px={3}
      py={1.5}
      minHeight="72px"
      justifyContent="space-around"
      alignItems="center"
    >
      {/* Trip Date & Time */}
      <Box display="flex" flex=".6">
        {first(trip.stops).dateTime ? (
          <MultiLineDateTime
            dateTime={first(trip.stops).dateTime}
            endDateTime={endDateTime}
            icon={
              <FlightChangeDisplay
                flightOffset={first(trip.stops).flightOffset}
                originalDateTime={first(trip.stops).originalDateTime}
                dateTime={first(trip.stops).dateTime}
                iconSize="small"
              />
            }
          />
        ) : (
          "Date/Time missing"
        )}
      </Box>

      {/* Total Amount (quotes) or Vehicle (reservations) */}
      <Box display="flex" flex={0.3} height="100%" alignItems="center">
        <Box display="flex" alignItems="center" flex="1" height="100%">
          <BillingDisplay
            amount={trip.totalAmount}
            sxStyles={requestItemTextStyles}
            showDashWhenNoAmount
            hideColor
          />
        </Box>
      </Box>

      <Box mx={1} />
      <Divider style={{ color: grayLight }} orientation="vertical" flexItem />
      <Box mx={1} />

      {/* Order Number */}
      <Box display="flex" flex=".5">
        <Typography sx={requestItemTextStyles}>{confirmationNumber}</Typography>
      </Box>

      <Box display="flex" flex="1" height="100%" alignItems="center">
        <Box display="flex" alignItems="center" flex="1" height="100%" mr={2}>
          <Typography sx={requestItemTextStyles} mr={1}>
            {tripVehicle || "-"}
          </Typography>
          {data?.loadTripConflict && (
            <MoovsTooltip tooltipText="Vehicle Conflict">
              <CalendarErrorIcon />
            </MoovsTooltip>
          )}
        </Box>
      </Box>

      <Box display="flex" flex="1">
        <Box display="flex" flexDirection="column">
          <Typography sx={requestItemTextStyles} style={{ fontWeight: 500 }}>
            {tripContact.name}
          </Typography>
          <Box mb={0.5}>
            {!!tripContact.email && (
              <Box sx={requestItemTextStyles}>
                <OverflowTooltip
                  variant="caption"
                  toolTipText={tripContact.email}
                  displayText={tripContact.email}
                />
              </Box>
            )}
            {!!tripContact.mobilePhone && (
              <Typography sx={requestItemTextStyles}>
                {tripContact.mobilePhone}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      {/* Roundtrip Indicator & Request Closed Out Status */}
      <Box
        display="flex"
        alignSelf="flex-start"
        flexDirection="column"
        flex=".5"
      >
        {shouldHideTripIconAndCategory && (
          <Box
            minWidth={92}
            display="flex"
            flexDirection="row-reverse"
            alignSelf="flex-end"
            alignItems="center"
            mb={1}
          >
            <Box display="flex">{icon}</Box>
            <Box mr={1.5}>
              <Typography variant="caption" style={{ color: grayDark }}>
                {text}
              </Typography>
            </Box>
          </Box>
        )}

        <Button
          variant={needsPricing ? "contained" : "outlined"}
          color="primary"
          size="small"
          sx={{
            whiteSpace: "nowrap",
            height: 30,
            width: 92,
            alignSelf: "self-end",
          }}
        >
          {needsPricing ? "Add Price" : "View Trip"}
        </Button>
      </Box>
    </Box>
  );
}

export default TripInformationDesktop;
