import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

import { Box, CircularProgress, Typography } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";

import theme from "theme";
import { alabaster } from "design-system/colors";
import {
  useCurrentUser,
  useOperator,
  useScreenSize,
  useSnackbar,
} from "globals/hooks";
import { TOP_BAR_HEIGHT_MOBILE } from "globals/hooks/useLayout";
import SettingsTabs, { TabConfig } from "../components/SettingsTabs";
import BalanceTab from "./tabs/BalanceTab";
import {
  LOAD_FINANCIAL_ACCOUNT,
  LOAD_STRIPE_ACCOUNT_QUERY,
} from "globals/graphql";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import SavedIndicatorChip from "design-system/components/chips/SaveIndicatorChip";

function FinancialPage() {
  // hooks
  const { isSmallTabletView, isMediumTabletView } = useScreenSize();
  const operator = useOperator();
  const history = useHistory();
  const snackbar = useSnackbar();
  const currentUser = useCurrentUser();

  const [loadStripeAccount, { data: stripeAccount }] = useLazyQuery(
    LOAD_STRIPE_ACCOUNT_QUERY,
    {
      onError(error) {
        const errorMessage = getErrorMessage(error) || error.message;

        snackbar.error(errorMessage);
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [loadFinancialAccount, { data: financialAccount }] = useLazyQuery(
    LOAD_FINANCIAL_ACCOUNT,
    {
      pollInterval: 1000 * 300, // poll every 5 minutes
      onError(error) {
        const errorMessage = getErrorMessage(error) || error.message;

        snackbar.error(errorMessage);
      },
      fetchPolicy: "cache-and-network",
    }
  );

  // state
  const [tabIndex, setTabIndex] = useState("0");
  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "loading" | "saved" | "error"
  >("default");

  // effects
  useEffect(() => {
    if (!operator) return;

    // In case user navigates directly to "/settings/financial"
    if (
      currentUser.role.slug !== "owner" ||
      !operator.stripeAccount?.isTreasuryEnabled
    ) {
      history.replace("/settings");
    }

    loadStripeAccount();
    loadFinancialAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, operator, loadFinancialAccount, loadStripeAccount]);

  // styles
  const styles = {
    fixedToTop: {
      top: 0,
      right: 0,
      left: 0,
      width: "100%",
      backgroundColor: alabaster,
      zIndex: 20,
      minWidth: "50vw",
    },
    container: {
      display: "flex",
      minHeight: "100%",
      flex: 1,

      maxWidth: "826px",
      margin: "auto",
      padding: 0,
      [theme.breakpoints.down("sm")]: {
        overflowY: "auto",
      },
    },
    header: {
      minHeight: "78px",
      display: "flex",

      justifyContent: "space-between",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
  };

  return (
    <Box
      px={2}
      width="100%"
      display="flex"
      flexDirection="column"
      height={
        isSmallTabletView ? `calc(100vh - ${TOP_BAR_HEIGHT_MOBILE}px)` : "100vh"
      }
    >
      <Helmet>
        <title>Financial Account | Moovs</title>
      </Helmet>

      <Box flexDirection="column" sx={styles.container}>
        <TabContext value={tabIndex}>
          <Box position="sticky" sx={styles.fixedToTop}>
            <Box flexDirection="row" flexWrap="wrap" sx={styles.header}>
              <Typography variant="h2">Financial Account</Typography>
              <Box>
                <SavedIndicatorChip mode={saveIndicatorState} />
              </Box>
            </Box>
            <SettingsTabs
              isMobileView={isMediumTabletView}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
              tabs={financialTabs}
            />
          </Box>

          {/* Financial Account Settings Tab Contents */}
          {!financialAccount || !stripeAccount ? (
            <Box
              width="100%"
              height="100vh"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress size={40} thickness={4} />
            </Box>
          ) : (
            <Box>
              {financialTabs.map((tab, index) => (
                <TabPanel key={index} value={index.toString()}>
                  {tab.component({
                    setSaveIndicatorState,
                    stripeAccount: stripeAccount.stripeAccount,
                    financialAccount: financialAccount.loadFinancialAccount,
                  })}
                </TabPanel>
              ))}
            </Box>
          )}
        </TabContext>
      </Box>
    </Box>
  );
}

const financialTabs: TabConfig[] = [
  {
    label: "Balance",
    component: (props) => <BalanceTab {...props} />,
  },
];

export default FinancialPage;
