import React, { ChangeEvent, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import { Box, TextField } from "@mui/material";

import MoovsDialog from "../MoovsDialog";
import { ShuttleRoute, CreateShuttleRouteInput } from "types";
import { CREATE_SHUTTLE_ROUTE_MUTATION } from "globals/graphql";
import { useSnackbar } from "globals/hooks";

type CreateAndAddShuttleRouteToRequestDialogProps = {
  open: boolean;
  onClose: () => void;
  onShuttleRouteCreated: (shuttleRoute: ShuttleRoute) => void;
  companyId: string;
  carryOverSearch?: string;
};

const initialShuttleRoute = {
  name: "",
};

const initialShuttleRouteErrors = {
  name: "",
};

function CreateAndAddShuttleRouteToRequestDialog(
  props: CreateAndAddShuttleRouteToRequestDialogProps
) {
  const {
    open,
    onClose,
    onShuttleRouteCreated,
    carryOverSearch = "",
    companyId,
  } = props;

  // hooks
  const snackbar = useSnackbar();

  // state
  const [shuttleRoute, setShuttleRoute] =
    useState<Partial<CreateShuttleRouteInput>>(initialShuttleRoute);
  const [shuttleRouteErrors, setShuttleRouteErrors] = useState<
    Partial<ShuttleRoute>
  >(initialShuttleRouteErrors);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // mutations
  const [createShuttleRoute] = useMutation(CREATE_SHUTTLE_ROUTE_MUTATION, {
    onCompleted(data) {
      setIsSubmitting(false);
      const createdShuttleRoute = data.createShuttleRoute.shuttleRoute;

      // populate request shuttleRoute with new shuttleRoute
      onShuttleRouteCreated(createdShuttleRoute);
      handleCloseDialog();
    },
    onError(error) {
      setIsSubmitting(false);
      snackbar.error("Error creating route");
    },
  });

  // event handlers
  const handleCloseDialog = () => {
    setShuttleRoute(initialShuttleRoute);
    setShuttleRouteErrors(initialShuttleRouteErrors);
    onClose();
  };

  const handleShuttleRouteChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setShuttleRoute({
      [event.target.name]: event.target.value,
    });
    setShuttleRouteErrors({ ...shuttleRouteErrors, [event.target.name]: "" });
  };

  const handleSaveClick = () => {
    if (!shuttleRoute.name) {
      setShuttleRouteErrors({ name: "Please enter a route name" });

      return;
    }

    if (shuttleRoute.name.length > 30) {
      setShuttleRouteErrors({
        name: "Route name must be between 1-30 characters",
      });

      return;
    }

    setIsSubmitting(true);

    createShuttleRoute({
      variables: {
        input: {
          name: shuttleRoute.name,
          companyId,
        },
      },
    });
  };

  // effects
  // reset dialog on close -> re-open
  useEffect(() => {
    if (open) {
      setIsSubmitting(false);
      setShuttleRouteErrors(initialShuttleRouteErrors);
      setShuttleRoute(initialShuttleRoute);
    }
  }, [open]);

  // preset field based on `carryOverSearch` content
  useEffect(() => {
    if (!shuttleRoute.name) {
      setShuttleRoute({ name: carryOverSearch });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carryOverSearch]);

  return (
    <MoovsDialog
      open={open}
      onClose={handleCloseDialog}
      dialogTitle="Add New Route"
      onAccept={handleSaveClick}
      acceptButtonText="Add Route"
      closeButtonText="Cancel"
      size="xs"
      acceptDisabled={isSubmitting}
      childrenSx={{ overflow: "visible" }}
    >
      <Box py={3}>
        <TextField
          fullWidth
          variant="outlined"
          name="name"
          value={shuttleRoute.name}
          label="Route Name"
          onChange={handleShuttleRouteChange}
          error={!!shuttleRouteErrors.name}
          helperText={shuttleRouteErrors.name}
        />
      </Box>
    </MoovsDialog>
  );
}

export default CreateAndAddShuttleRouteToRequestDialog;
