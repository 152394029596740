import React from "react";

import { Box, Typography } from "@mui/material";

import { DriverPayable } from "../../../types";
import MoovsAvatar from "../../../design-system/components/MoovsAvatar";
import { currency } from "../../../globals/utils/helpers";

type DriverPayoutListItemDesktopType = {
  driverPayable: DriverPayable;
};

function DriverPayoutListItemDesktop(props: DriverPayoutListItemDesktopType) {
  const { driverPayable } = props;
  const { driver, routesCount, flatRateAmt, gratuityAmt, hourlyAmt, totalAmt } =
    driverPayable || {};
  const { driverProfilePhoto, firstName, lastName } = driver || {};

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      borderRadius="4px"
      px={3}
      py={2}
      height={72}
    >
      <Box flex={2.5} display="flex" flexDirection="row" alignItems="center">
        <MoovsAvatar
          size="small"
          placeholder={[firstName, lastName]}
          src={driverProfilePhoto}
        />
        <Typography
          variant="subtitle1"
          style={{ marginLeft: 12, fontWeight: 700 }}
        >
          {firstName} {lastName}
        </Typography>
      </Box>
      <Box flex={0.5}>
        <Typography variant="body2">{routesCount}</Typography>
      </Box>
      <Box flex={1}>
        <Typography variant="body2">{currency(flatRateAmt)}</Typography>
      </Box>
      <Box flex={1}>
        <Typography variant="body2">{currency(hourlyAmt)}</Typography>
      </Box>
      <Box flex={1}>
        <Typography variant="body2">{currency(gratuityAmt)}</Typography>
      </Box>
      <Box flex={1}>
        <Typography variant="body2">{currency(totalAmt)}</Typography>
      </Box>
    </Box>
  );
}

export default DriverPayoutListItemDesktop;
