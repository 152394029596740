import { DateRange } from "components/common/DateFilter/types";
import moment from "moment-timezone";

import { dateFormatter } from "../utils/helpers";

// string constants
export enum TimeViewsEnum {
  TODAY = "today",
  TOMORROW = "tomorrow",
  YESTERDAY = "yesterday",
  THIS_WEEK = "this-week",
  SPECIFIC_DATE = "specific-date",
  CUSTOM_RANGE = "custom-range",
  ALL = "all",
}

export enum QuoteStatusViewsEnum {
  NEW = "NEW_QUOTE",
  SENT = "EMAIL_SENT",
  DRAFT = "DRAFT",
  ALL = "ALL",
  ARCHIVED = "ARCHIVED",
}

export enum ReservationStatusViewsEnum {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  NOT_PAID = "NOT_PAID",
  UNCONFIRMED = "UNCONFIRMED",
  ALL = "ALL",
}

const {
  TODAY,
  TOMORROW,
  YESTERDAY,
  THIS_WEEK,
  SPECIFIC_DATE,
  CUSTOM_RANGE,
  ALL: ALL_TIME,
} = TimeViewsEnum;
const {
  NEW,
  SENT,
  DRAFT,
  ALL: ALL_QUOTE_STATUS,
  ARCHIVED,
} = QuoteStatusViewsEnum;
const {
  OPEN,
  CLOSED,
  NOT_PAID,
  ALL: ALL_RESERVATION_STATUS,
  UNCONFIRMED,
} = ReservationStatusViewsEnum;

// timespan values
const DAY = "day";
const RANGE = "range";

// times
const today = moment();
const todayFormatted = today.clone().format("YYYY-MM-DD");
const todayButtonLabel = today.clone().format("MM/DD/YY");
const yesterday = today.clone().subtract(1, "day");
const yesterdayFormatted = yesterday.clone().format("YYYY-MM-DD");
const yesterdayButtonLabel = yesterday.clone().format("MM/DD/YY");
const tomorrow = today.clone().add(1, "day");
const tomorrowFormatted = tomorrow.clone().format("YYYY-MM-DD");
const tomorrowButtonLabel = tomorrow.clone().format("MM/DD/YY");
const weekStart = today
  .clone()
  .startOf("week")
  .add(1, "day")
  .format("YYYY-MM-DD"); // Monday
const weekEnd = today.clone().endOf("week").add(1, "day").format("YYYY-MM-DD"); // Sunday

const isSameDay = (day1: string, day2: string) => {
  return moment(day1).isSame(moment(day2), "day");
};

const getTimeViewValueFromDates = (
  dateRange: DateRange,
  mode?: "reservations" | "dispatch" | "driver-payout"
) => {
  const [startDate, endDate] = dateRange;

  if (!startDate && mode === "driver-payout") return ALL_TIME;
  if (isSameDay(startDate, endDate)) {
    if (mode === "driver-payout") return SPECIFIC_DATE;
    if (isSameDay(startDate, todayFormatted)) return TODAY;
    if (isSameDay(startDate, yesterdayFormatted)) return YESTERDAY;
    if (isSameDay(startDate, tomorrowFormatted)) return TOMORROW;
    return SPECIFIC_DATE;
  } else {
    if (mode === "driver-payout") return CUSTOM_RANGE;
    if (isSameDay(startDate, weekStart) && isSameDay(endDate, weekEnd)) {
      return THIS_WEEK;
    }
    return CUSTOM_RANGE;
  }
};

type TimeViewType = {
  menuLabel: string;
  getButtonDisplayLabel: (startDate?: string, endDate?: string) => string;
  value: TimeViewsEnum;
  defaultStartDate: string;
  defaultEndDate: string;
  timespan: "day" | "range" | "all";
};

// views
const timeViews: TimeViewType[] = [
  {
    menuLabel: "Today",
    getButtonDisplayLabel: () => `Today ${todayButtonLabel}`,
    value: TODAY,
    timespan: DAY,
    defaultStartDate: todayFormatted,
    defaultEndDate: todayFormatted,
  },
  {
    menuLabel: "Yesterday",
    getButtonDisplayLabel: () => `Yesterday ${yesterdayButtonLabel}`,
    value: YESTERDAY,
    timespan: DAY,
    defaultStartDate: yesterdayFormatted,
    defaultEndDate: yesterdayFormatted,
  },
  {
    menuLabel: "Tomorrow",
    getButtonDisplayLabel: () => `Tomorrow ${tomorrowButtonLabel}`,
    value: TOMORROW,
    timespan: DAY,
    defaultStartDate: tomorrowFormatted,
    defaultEndDate: tomorrowFormatted,
  },
  {
    menuLabel: "This Week",
    getButtonDisplayLabel: () => "This Week",
    value: THIS_WEEK,
    timespan: RANGE,
    defaultStartDate: weekStart, // Monday
    defaultEndDate: weekEnd, // Sunday
  },
  {
    menuLabel: "Specific Date",
    getButtonDisplayLabel: (startDate) =>
      dateFormatter(moment(startDate), "fullNumerical"),
    value: SPECIFIC_DATE,
    timespan: DAY,
    defaultStartDate: todayFormatted,
    defaultEndDate: todayFormatted,
  },
  {
    menuLabel: "Custom Range",
    getButtonDisplayLabel: (startDate, endDate) => {
      const formattedStartDate = dateFormatter(
        moment(startDate),
        "fullNumerical"
      );
      const formattedEndDate = dateFormatter(moment(endDate), "fullNumerical");

      return `${formattedStartDate} - ${formattedEndDate}`;
    },
    value: CUSTOM_RANGE,
    timespan: RANGE,
    defaultStartDate: todayFormatted,
    defaultEndDate: todayFormatted,
  },
];

// driverPayout time view
// const driverPayoutTimeView = [
const driverPayoutTimeViews: TimeViewType[] = [
  {
    menuLabel: "All",
    getButtonDisplayLabel: () => "All",
    value: ALL_TIME,
    timespan: "all",
    defaultStartDate: null,
    defaultEndDate: null,
  },
  {
    menuLabel: "Specific Date",
    getButtonDisplayLabel: (startDate) => {
      return dateFormatter(moment(startDate), "fullNumerical");
    },
    value: SPECIFIC_DATE,
    timespan: DAY,
    defaultStartDate: todayFormatted,
    defaultEndDate: todayFormatted,
  },
  {
    menuLabel: "Custom Range",
    getButtonDisplayLabel: (startDate, endDate) => {
      const formattedStartDate = dateFormatter(
        moment(startDate),
        "fullNumerical"
      );
      const formattedEndDate = dateFormatter(moment(endDate), "fullNumerical");

      return `${formattedStartDate} - ${formattedEndDate}`;
    },
    value: CUSTOM_RANGE,
    timespan: RANGE,
    defaultStartDate: todayFormatted,
    defaultEndDate: todayFormatted,
  },
];

type StatusViewType = {
  menuLabel: string;
  value: string;
};

const quoteStatusViews: StatusViewType[] = [
  {
    menuLabel: "New",
    value: NEW,
  },
  {
    menuLabel: "Sent",
    value: SENT,
  },
  {
    menuLabel: "Draft",
    value: DRAFT,
  },
  {
    menuLabel: "ARCHIVED",
    value: ARCHIVED,
  },
  {
    menuLabel: "All",
    value: ALL_QUOTE_STATUS,
  },
];

const requestClosedStatusViews: StatusViewType[] = [
  {
    menuLabel: "OPEN",
    value: OPEN,
  },
  {
    menuLabel: "CLOSED",
    value: CLOSED,
  },
  {
    menuLabel: "NOT PAID",
    value: NOT_PAID,
  },
  {
    menuLabel: "UNCONFIRMED",
    value: UNCONFIRMED,
  },
  {
    menuLabel: "ALL",
    value: ALL_RESERVATION_STATUS,
  },
];

function getDateBySelectedView(inputView, type: "start" | "end") {
  const selectedView = getAssociatedTimeView(inputView);
  if (!selectedView) return todayFormatted;

  const { defaultStartDate, defaultEndDate } = selectedView;
  return type === "start" ? defaultStartDate : defaultEndDate;
}

function getAssociatedTimeView(inputValue: TimeViewsEnum): TimeViewType {
  return timeViews.find((view) => view.value === inputValue);
}

function getAssociatedDriverPayoutView(
  inputValue: TimeViewsEnum
): TimeViewType {
  return driverPayoutTimeViews.find((view) => view.value === inputValue);
}

function getAssociatedStatusView(
  inputValue: QuoteStatusViewsEnum
): StatusViewType {
  return quoteStatusViews.find((view) => view.value === inputValue);
}

export {
  timeViews,
  quoteStatusViews,
  driverPayoutTimeViews,
  getDateBySelectedView,
  getAssociatedTimeView,
  getAssociatedDriverPayoutView,
  getAssociatedStatusView,
  requestClosedStatusViews,
  getTimeViewValueFromDates,
};
