import React from "react";
import { Box, Typography } from "@mui/material";
import moment from "moment";

import { granite, grayMedium } from "design-system/colors";

type DepartedChipProps = {
  departedAt: Date;
};

function DepartedChip(props: DepartedChipProps) {
  // derived state
  const departedAt = moment(props.departedAt).format("h:mm A");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "fit-content",
        borderRadius: "4px",
        textAlign: "center",
        backgroundColor: grayMedium,
        color: granite,
        px: 1,
        py: 0.5,
      }}
    >
      <Typography variant="h6">Departed</Typography>
      <Typography variant="caption" sx={{ marginLeft: 1 }}>
        {departedAt}
      </Typography>
    </Box>
  );
}

export default DepartedChip;
