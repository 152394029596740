import React from "react";
import startCase from "lodash/startCase";

import { Box, CardContent, Typography } from "@mui/material";

import { grayDark } from "design-system/colors";
import { GenericCreditCardIcon } from "design-system/icons";
import { PaymentMethod } from "types";

type LagoPaymentMethodsProps = {
  lagoPaymentMethods: PaymentMethod[];
};

function LagoPaymentMethods(props: LagoPaymentMethodsProps) {
  const { lagoPaymentMethods } = props;

  return (
    <>
      {lagoPaymentMethods.map((paymentMethod) => (
        <CardContent
          sx={{
            border: "1px solid #EDEDED",
            boxShadow:
              "0px 8px 10px -2.5px rgba(30, 30, 30, 0.02), 0px 24px 24px -4px rgba(30, 30, 30, 0.02)",
            borderRadius: "4px",
            mt: 3,
          }}
        >
          <Box display="flex" alignItems="center" p={1} minHeight="40px">
            <GenericCreditCardIcon color={grayDark} />
            <Typography variant="body2" pl={2}>
              {`Card Saved: ${startCase(paymentMethod.card.brand)} ending in ${
                paymentMethod.card.last4
              }`}
            </Typography>
          </Box>
        </CardContent>
      ))}
    </>
  );
}

export default LagoPaymentMethods;
