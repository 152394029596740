import React from "react";

import { Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import RoundChip from "design-system/components/chips/RoundChip";
import {
  errorRed,
  successGreen,
  tintGreen,
  tintRed,
} from "design-system/colors";

function PaidStatusColumn(props: GridRenderCellParams<string>) {
  const { value } = props;

  return (
    <Box display="flex" justifyContent="center">
      {value === "Paid" ? (
        <RoundChip
          label={value}
          backgroundColor={tintGreen}
          color={successGreen}
        />
      ) : (
        <RoundChip label={value} backgroundColor={tintRed} color={errorRed} />
      )}
    </Box>
  );
}

export default PaidStatusColumn;
