import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

import RemoveDialog from "components/RemoveDialog";
import { useAnalytics, useSnackbar } from "globals/hooks";
import {
  REMOVE_TRIP_MUTATION,
  UNDO_REMOVE_TRIP_MUTATION,
} from "globals/graphql";
import {
  confirmationNumberFromRequest,
  pluralize,
} from "globals/utils/helpers";
import { Request, Trip } from "types";
import { getRequestStage } from "globals/utils/getRequestStage";

type DeleteTripDialogProps = {
  open: boolean;
  onClose: () => void;
  trip: Trip;
  request: Request;
  analyticsName?: string;
};

function DeleteTripDialog(props: DeleteTripDialogProps) {
  const { open, onClose, trip, request, analyticsName } = props;

  // derived state
  const confirmationNumber = confirmationNumberFromRequest(request, trip);
  const requestStage = getRequestStage(request.stage);

  // hooks
  const { track } = useAnalytics();
  const snackbar = useSnackbar();
  const history = useHistory();

  // mutations
  const [removeTrip] = useMutation(REMOVE_TRIP_MUTATION, {
    onCompleted(data) {
      track("reservation_deleted");
      snackbar.success(`Successfully deleted ${confirmationNumber}!`, {
        onUndo: () => handleUndoDeleteTrip(data.removeTrip),
      });
    },
    onError(error) {
      snackbar.error(`Error deleting ${confirmationNumber}`);
    },
  });

  const [undoRemoveTrip] = useMutation(UNDO_REMOVE_TRIP_MUTATION, {
    refetchQueries: ["Request"],
    onCompleted(data) {
      snackbar.success(`Successfully retrieved ${confirmationNumber}!`, {
        link: `/reservations/${request.id}?tripId=${trip.id}`,
        linkLabel: "See Trip",
      });
    },
    onError(error) {
      snackbar.error("Error retrieving deleted reservation");
    },
  });

  // event handlers
  const handleDeleteTrip = () => {
    if (analyticsName) track(analyticsName);

    removeTrip({
      variables: {
        input: {
          tripId: trip.id,
        },
      },
    });
    onClose();
    history.push(`/${pluralize(requestStage)}/${request.id}`);
  };

  // dialog/snackbar handlers
  const handleUndoDeleteTrip = (removedTrip) => {
    undoRemoveTrip({
      variables: {
        input: {
          tripId: trip.id,
          // only in case of deleting a trip on roundtrip.
          ...(trip.returnTrip?.id && { returnTripId: trip.returnTrip.id }),
          ...(removedTrip?.outboundTrip?.id && {
            outboundTripId: removedTrip.outboundTrip.id,
          }),
        },
      },
    });
    history.push(`/reservations/${request.id}?tripId=${trip.id}`);
  };

  return (
    <RemoveDialog
      open={open}
      onClose={onClose}
      onRemove={handleDeleteTrip}
      title="Are you sure you want to delete this trip?"
      body="This trip will be removed from your reservation, invoice, and dispatch pages. You will not be able to see these deleted trips on saved contacts."
      removeButtonText="Delete"
    />
  );
}

export default DeleteTripDialog;
