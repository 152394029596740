import { useQuery } from "@apollo/client";
import { useEffect, useRef } from "react";

import { LOAD_OPERATOR_QUERY } from "../graphql";
import { useCurrentUser } from "./useCurrentUser";
import { OperatorGettingStarted } from "types";

function useOperatorGettingStarted() {
  // hooks
  const { operatorId } = useCurrentUser() || {};
  const flag = useRef(null);

  // queries
  const { data: operatorData, stopPolling } = useQuery(LOAD_OPERATOR_QUERY, {
    variables: { id: operatorId },
    skip: !operatorId,
    ...(operatorId && { pollInterval: 60 * 1000 }), // every 60 seconds
  });

  const gettingStarted = operatorData?.node
    ?.gettingStarted as OperatorGettingStarted;

  // effects
  // stop polling
  useEffect(() => {
    if (!operatorData || flag.current) return;

    // legacy users
    if (!gettingStarted) {
      stopPolling();

      flag.current = true;
      return;
    }

    if (gettingStarted.tasksRemaining === 0) {
      stopPolling();
      flag.current = true;
    }
  }, [gettingStarted, operatorData, stopPolling]);

  return gettingStarted || null;
}

export { useOperatorGettingStarted };
