import React from "react";
import capitalize from "lodash/capitalize";
import truncate from "lodash/truncate";

import { Box, Chip, Tooltip, Typography } from "@mui/material";

import { grayDark, white } from "design-system/colors";

const shapeCircleStyles = {
  bgcolor: grayDark,
  width: 30,
  height: 30,
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ml: 0.5,
};

type Item = Record<string, any>;
type Key = keyof Item;

type MoovsLimitedChipsProps = {
  items: Item[];
  keys: Key[];
  label: string;
  limit?: number;
  maxLength?: number;
};

export default function MoovsLimitedChips({
  items,
  keys,
  label,
  limit = 1,
  maxLength = 10,
}: MoovsLimitedChipsProps) {
  return (
    <Box display="flex" flex="1" flexWrap="wrap" rowGap={1}>
      {items?.slice(0, limit)?.map((item) => (
        <Tooltip
          id={item.id}
          key={item.id}
          title={keys.map((key) => capitalize(item[key])).join(" ")}
        >
          <Chip
            label={truncate(
              keys.map((key) => capitalize(item[key])).join(" "),
              {
                length: maxLength,
              }
            )}
          />
        </Tooltip>
      ))}

      {items?.length > limit && (
        <Tooltip
          title={
            <Box>
              {items
                .map((item) => (
                  <Box key={item.id}>
                    {keys.map((key) => capitalize(item[key])).join(" ")}
                  </Box>
                ))
                .slice(limit)}
            </Box>
          }
        >
          <Box component="span" sx={shapeCircleStyles}>
            <Typography
              variant="h6"
              sx={{ color: white }}
              aria-label={`${label} extra count`}
            >
              {items?.length - limit}
            </Typography>
          </Box>
        </Tooltip>
      )}
    </Box>
  );
}
