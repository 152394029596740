import React from "react";

type MagnifyingGlassPlusIconProps = {
  color?: string;
};

const MagnifyingGlassPlusIcon = (props: MagnifyingGlassPlusIconProps) => {
  const { color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7523 15.7511L22 22M18.1113 10.0551C18.1114 11.6485 17.6391 13.2062 16.754 14.5311C15.8689 15.8561 14.6108 16.8888 13.1388 17.4986C11.6668 18.1085 10.047 18.2681 8.48427 17.9572C6.92155 17.6464 5.48609 16.8792 4.35944 15.7525C3.23279 14.6257 2.46555 13.1902 2.15475 11.6274C1.84395 10.0646 2.00355 8.44477 2.61337 6.97269C3.22319 5.50061 4.25584 4.24245 5.58072 3.35731C6.90559 2.47217 8.46319 1.99982 10.0565 2C12.1927 2.00024 14.2413 2.84897 15.7518 4.35955C17.2623 5.87012 18.111 7.91884 18.1113 10.0551Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 10H14M10 14V6"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MagnifyingGlassPlusIcon;
