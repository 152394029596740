import React, { useState, useEffect, useCallback } from "react";
import { useMutation } from "@apollo/client";

import StepperFormBody from "components/forms/StepperForm/StepperFormBody";
import StepperFormFooter from "components/forms/StepperForm/StepperFormFooter";
import MoovsDialog from "components/MoovsDialog";
import { CREATE_PROMO_CODE_MUTATION } from "globals/graphql";
import { useAnalytics, useSnackbar } from "globals/hooks";
import useFormStepProgress from "globals/hooks/useFormStepProgress";
import { WorkflowSelectionsEnum } from "./steps/workflow/schemaValidation";
import { finalizeCreatePromoCodeInput } from "./utils/finalizeCreatePromoCodeInput";
import { initialCreatePromoCodeInputs } from "./utils/initialCreatePromoCodeInputs";
import { CreatePromoCodeInputType } from "./utils/types";
import useInitializeCreatePromoCodeForms from "./hooks/useInitializeCreatePromoCodeForms";
import CreatePromoCodeHeaderStepper from "./components/CreatePromoCodeHeaderStepper";

export const GlobalCreatePromoCode = React.createContext([]);

type CreatePromoCodeDialogProps = {
  open: boolean;
  onClose: () => void;
};

function CreatePromoCodeDialog(props: CreatePromoCodeDialogProps) {
  const { open, onClose } = props;

  // state
  const [workflowOption, setWorkflowOption] =
    useState<WorkflowSelectionsEnum>(null);
  const [createPromoCodeInputs, setCreatePromoCodeInputs] =
    useState<CreatePromoCodeInputType>(initialCreatePromoCodeInputs);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // hooks
  const { track } = useAnalytics();
  const snackbar = useSnackbar();

  const {
    formComponentSteps,
    eventHandlerSteps,
    resetAllForms,
    setWorkflowOutline,
    subHeaderSteps,
  } = useInitializeCreatePromoCodeForms(workflowOption);

  const { currentStep, goForward, goBack, startAtFirstStep, isFirst, isLast } =
    useFormStepProgress(formComponentSteps?.length);

  // mutation
  const [createPromoCode] = useMutation(CREATE_PROMO_CODE_MUTATION, {
    onCompleted() {
      handleCloseAndReset();
      snackbar.success("Successfully created promo code!");

      // tracking
      const totalLimit = createPromoCodeInputs[workflowOption].isTotalLimited
        ? "limited"
        : "unlimited";
      const bookingContactLimit = createPromoCodeInputs[workflowOption]
        .isBookingContactLimited
        ? "limited"
        : "unlimited";

      if (workflowOption === WorkflowSelectionsEnum.anytime) {
        track("promoCode_created", {
          totalLimit,
          bookingContactLimit,
          type: "Anytime",
        });
      } else if (workflowOption === WorkflowSelectionsEnum.bookingDate) {
        track("promoCode_created", {
          totalLimit,
          bookingContactLimit,
          type: "Booking Date",
        });
      } else if (workflowOption === WorkflowSelectionsEnum.tripDate) {
        track("promoCode_created", {
          totalLimit,
          bookingContactLimit,
          type: "Trip Date",
        });
      }
    },
    onError() {
      snackbar.error("Error creating promo code.");
    },
  });

  const handleCreatePromoCode = useCallback(async () => {
    const { finalData } = finalizeCreatePromoCodeInput({
      createPromoCodeInputs,
      workflowOption,
    });

    await createPromoCode({
      variables: {
        input: finalData,
      },
    });

    setIsSubmitting(false);
  }, [createPromoCode, createPromoCodeInputs, workflowOption]);

  useEffect(() => {
    if (isSubmitting) {
      handleCreatePromoCode();
    }
  }, [isSubmitting, handleCreatePromoCode]);

  // event handlers
  // handleAfterSubmit only gets invoked when validation passes (handleValidate is where validation occurs)
  const handleAfterSubmit = (data) => {
    if (isFirst) {
      // on step 1, changing workflow option
      const selectedWorkflow = data.workflow;
      setCreatePromoCodeInputs(initialCreatePromoCodeInputs);
      setWorkflowOption(selectedWorkflow);

      // tracking
      if (selectedWorkflow === WorkflowSelectionsEnum.anytime) {
        track("promoCode_typeSelected", { type: "Anytime" });
      } else if (selectedWorkflow === WorkflowSelectionsEnum.bookingDate) {
        track("promoCode_typeSelected", { type: "Booking Date" });
      } else if (selectedWorkflow === WorkflowSelectionsEnum.tripDate) {
        track("promoCode_typeSelected", { type: "Trip Date" });
      }
    } else {
      //  on step 2 and above
      setCreatePromoCodeInputs({
        ...createPromoCodeInputs,
        [workflowOption]: {
          ...createPromoCodeInputs[workflowOption],
          ...data,
        },
      });

      // codeAndAmount step
      if (data.name) {
        track("promoCode_codeAmountSelected");
      }

      // bookingDate step
      if (data.bookingStartDate) {
        track("promoCode_dateRangeSelected", { type: "Booking Date" });
      }

      // tripDate step
      if (data.tripStartDate) {
        track("promoCode_dateRangeSelected", { type: "Trip Date" });
      }
    }

    if (isLast) {
      /* On last step:
        We need to allow the setCreatePromoCodeInputs hook to update the
        data for the final form before we make the request to create the
        promo code, so we update the data first, then set isSubmitting
        to true which triggers the useEffect, where we make the request 
        after the data (createPromoCodeInputs) has been updated.
        */
      setIsSubmitting(true);
    } else {
      goForward();
    }
  };

  // each step/form is validated before user is allowed to move towards the next step/form
  const handleValidate = () => {
    const eventHandlerStepsForCurrentWorkflow =
      eventHandlerSteps(handleAfterSubmit);

    const currentStepEventHandler =
      eventHandlerStepsForCurrentWorkflow[currentStep];
    currentStepEventHandler();
  };

  const handleCloseAndReset = () => {
    onClose();
    setWorkflowOutline(null);
    resetAllForms();
    const timeToResetState = setTimeout(() => {
      startAtFirstStep();
    }, 500);
    return () => {
      clearTimeout(timeToResetState);
    };
  };

  const isOnLimitsScreen =
    (currentStep === 2 && workflowOption === "anytime") ||
    (currentStep === 3 && ["bookingDate", "tripDate"].includes(workflowOption));

  return (
    <MoovsDialog
      open={open}
      onClose={handleCloseAndReset}
      dialogTitle={formComponentSteps?.[currentStep].header}
      size="md"
      {...(isOnLimitsScreen && { sx: { minHeight: "630px" } })}
      customFooter={
        <StepperFormFooter
          isFirst={isFirst}
          isLast={isLast}
          onGoBack={goBack}
          onCancel={handleCloseAndReset}
          onValidate={handleValidate}
          lastStepSubmitButtonText="Create Promo Code"
          showHelpButton={true}
          onHelp={() => track("promoCode_helpRequested")}
          submitDisabled={isSubmitting}
        />
      }
      {...(currentStep > 0 && {
        subHeaderComponent: (
          <CreatePromoCodeHeaderStepper
            activeStepExcludingTypeSelectStep={currentStep - 1} // Adjust since we're not showing the header on the first type select screen
            subHeaderSteps={subHeaderSteps}
          />
        ),
      })}
    >
      <GlobalCreatePromoCode.Provider
        value={[
          createPromoCodeInputs,
          workflowOption,
          setCreatePromoCodeInputs,
        ]}
      >
        <StepperFormBody steps={formComponentSteps} currentStep={currentStep} />
      </GlobalCreatePromoCode.Provider>
    </MoovsDialog>
  );
}

export default CreatePromoCodeDialog;
