import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";

import {
  Box,
  Select,
  Typography,
  MenuItem,
  ListItemText,
  ListItemIcon,
  SelectChangeEvent,
} from "@mui/material";

import { useOperator, useScreenSize, useSnackbar } from "globals/hooks";
import { grayDark, black, errorRed } from "design-system/colors";
import {
  ChevronDownIcon,
  CheckIcon,
  WarningTriangleIcon,
} from "design-system/icons";
import {
  LOAD_VEHICLES_NAME_AND_ID_QUERY,
  UPDATE_OPERATOR_SETTINGS,
} from "globals/graphql";
import LabeledSwitchInput from "components/settings/general/LabeledSwitchInput";

type SkipVehicleSelectionProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

type VehicleOption = { name: string; id: string };

function SkipVehicleSelection(props: SkipVehicleSelectionProps) {
  const { setSaveIndicatorState } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const snackbar = useSnackbar();
  const operator = useOperator();

  // derived state
  const { skipVehicleSelectionEnabled, skipVehicleDefaultVehicleId } =
    operator.settings;

  const { data } = useQuery(LOAD_VEHICLES_NAME_AND_ID_QUERY, {
    fetchPolicy: "no-cache",
  });

  const vehicleOptions: VehicleOption[] =
    data?.loadVehicles.edges.map(({ node: vehicle }) => ({
      name: vehicle.name,
      id: vehicle.id,
    })) || [];

  const operatorHasVehicle = !isEmpty(vehicleOptions);

  const defaultVehicleId =
    skipVehicleDefaultVehicleId ||
    (!isEmpty(vehicleOptions) ? vehicleOptions[0].id : null);

  const defaultVehicleName = vehicleOptions.find(
    (vehicle) => vehicle.id === defaultVehicleId
  )?.name;

  // mutations
  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error("Error updating settings");
    },
  });

  // event handlers
  const handleEnableSkipVehicleStep = () => {
    setSaveIndicatorState("loading");
    if (isEmpty(vehicleOptions)) {
      setSaveIndicatorState("error");
      return snackbar.error(
        "You need to have at least one vehicle to enable this setting",
        {
          icon: <WarningTriangleIcon color={errorRed} size="large" />,
          link: `/vehicles`,
          linkLabel: `Go to Vehicles`,
        }
      );
    }

    updateOperatorSettings({
      variables: {
        input: {
          skipVehicleSelectionEnabled: !skipVehicleSelectionEnabled,
          skipVehicleDefaultVehicleId: !skipVehicleSelectionEnabled
            ? defaultVehicleId
            : null,
        },
      },
    });
  };

  const handleUpdateDefaultVehicle = (event: SelectChangeEvent<string>) => {
    setSaveIndicatorState("loading");

    updateOperatorSettings({
      variables: {
        input: {
          skipVehicleDefaultVehicleId: event.target.value,
        },
      },
    });
  };

  return (
    <Box mt={4}>
      <Typography variant="h3" mb={2}>
        Skip Vehicle Selection
      </Typography>

      <Box display="flex" flexDirection="column">
        <Box ml={-1} mb={3}>
          <LabeledSwitchInput
            labelPlacement="start"
            name="skipVehicleSelectionEnabled"
            checked={skipVehicleSelectionEnabled}
            onChange={handleEnableSkipVehicleStep}
            text="Choose the default vehicle for reservations created without step 2"
            subText={
              <Box maxWidth={538}>
                <Typography variant="caption">
                  When this is on, the vehicle selection step won’t be displayed
                  in the customer portal. The default vehicle assigned to trips
                  will be:
                </Typography>
              </Box>
            }
          />
        </Box>

        {operatorHasVehicle && skipVehicleSelectionEnabled && (
          <Box maxWidth={isMobileView ? "100%" : 220} display="flex">
            <Select
              disabled={!defaultVehicleId}
              value={defaultVehicleId}
              renderValue={() => defaultVehicleName}
              fullWidth
              name="selectDefaultVehicle"
              onChange={handleUpdateDefaultVehicle}
              IconComponent={() => (
                <Box
                  position="relative"
                  top="5px"
                  right="20px"
                  sx={[
                    {
                      pointerEvents: "none",
                    },
                  ]}
                >
                  <ChevronDownIcon color={grayDark} size="small" />
                </Box>
              )}
            >
              {vehicleOptions.map((vehicle) => (
                <MenuItem value={vehicle.id} key={vehicle.id}>
                  <Box mr={2}>
                    <ListItemText primary={vehicle.name} />
                  </Box>
                  {skipVehicleDefaultVehicleId === vehicle.id && (
                    <ListItemIcon>
                      <CheckIcon color={black} size="small" />
                    </ListItemIcon>
                  )}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default SkipVehicleSelection;
