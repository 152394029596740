import driverIcon from "design-system/illustrations/moovsMarketApps/driver.svg";
import reviewPlatform from "design-system/illustrations/moovsMarketApps/reviewPlatform.svg";
import googleCalendar from "design-system/illustrations/moovsMarketApps/googleCalendar.svg";
// commented out as coming soon apps are currently hidden, will be added back when they are actually coming soon
// import salesAutomationIcon from "design-system/illustrations/moovsMarketApps/salesAutomation.svg";
// import dynamicPricingIcon from "design-system/illustrations/moovsMarketApps/dynamicPricing.svg";

export enum MarketApp {
  Driver = "driver",
  SalesAutomation = "sales_automation",
  DynamicPrice = "dynamic_pricing",
  ReviewPlatform = "review_platform",
  GoogleCalendar = "google_calendar",
}

export const appData = {
  driverApp: {
    name: "Driver Platform",
    enum: MarketApp.Driver,
    icon: driverIcon,
    description:
      "Driver tracking, ride reports, driver scheduling, accept/deny jobs",
    goTo: {
      text: "Driver Tracking",
      path: "/driver-tracking",
    },
    aboutPage:
      "https://intercom.help/moovs-05c940f1970e/en/articles/6411777-about-driver-platform-app",
    isPaidApp: false,
  },
  // Hidden as SOON may not be in the next year...
  // salesAutomation: {
  //   name: "Sales Automation",
  //   enum: MarketApp.SalesAutomation,
  //   icon: salesAutomationIcon,
  //   description:
  //     "Automated text messages to contacts to increase conversion rates on quotes",
  //   comingSoon: true,
  //   isPaidApp: true,
  // },
  // dynamicPricing: {
  //   name: "Dynamic Pricing",
  //   enum: MarketApp.DynamicPrice,
  //   icon: dynamicPricingIcon,
  //   description:
  //     "Increase or decrease your base rate based on dates, days of the week, and time of day",
  //   comingSoon: true,
  //   isPaidApp: true,
  // },
  reviewPlatform: {
    name: "Customer Reviews",
    enum: MarketApp.ReviewPlatform,
    icon: reviewPlatform,
    description:
      "Increase your 5-star ratings on your review platform and receive feedback from your customers",
    aboutPage:
      "https://intercom.help/moovs-05c940f1970e/en/articles/6759829-about-customer-reviews-app",
    isPaidApp: false,
  },
  googleCalendar: {
    name: "Google Calendar",
    enum: MarketApp.GoogleCalendar,
    icon: googleCalendar,
    description:
      "Connect Moovs with Google Calendar to automatically sync all future trips with your calendar",
    longDescription:
      "Connect Moovs with you Google Calendar to automatically sync all future trips with your calendar. To push trips to your calendar, we need to authorize your account with Google. Please note that initial calendar creation might take up to 24 hours.",
    aboutPage:
      "https://intercom.help/moovs-05c940f1970e/en/articles/7199695-google-calendar-integration", //
    isPaidApp: false,
  },
};
