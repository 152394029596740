import React from "react";
import { Box, Typography } from "@mui/material";

import {
  black,
  errorRed,
  granite,
  green,
  tintGreen,
  tintRed,
  tintYellow,
} from "design-system/colors";
import useDriverEtaCalculations from "../hooks/useDriverEtaCalculations";
import { convertMinutesToHoursMinutes } from "globals/utils/helpers";

type EnRouteChipProps = {
  driverEta: Date;
  pickUpTime: Date;
  isPickUp: boolean;
};

function EnRouteChip(props: EnRouteChipProps) {
  const { pickUpTime, isPickUp } = props;

  // hooks
  const { driverEta, minutesLate } = useDriverEtaCalculations({
    pickUpTime,
    driverEta: props.driverEta,
  });

  // derived state
  const isLate = minutesLate >= 1;

  const chipBackgroundColor = isPickUp
    ? isLate
      ? tintRed
      : tintGreen
    : tintYellow;

  const chipText = isLate
    ? convertMinutesToHoursMinutes(minutesLate, "semi-formal") + " late"
    : "On Time";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "fit-content",
        borderRadius: "4px",
        fontWeight: "normal",
        textAlign: "center",
        "& .MuiTypography-root": {
          fontSize: "12px",
        },
        backgroundColor: chipBackgroundColor,
        px: 1,
        py: 0.5,
        mt: 0.5,
      }}
    >
      {isPickUp && (
        <Typography
          sx={{
            color: isLate ? errorRed : green,
            fontWeight: "bold",
            mr: 0.7,
          }}
        >
          {chipText}
        </Typography>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          color: granite,
        }}
      >
        <Typography
          sx={{
            fontWeight: isPickUp ? "normal" : "bold",
            color: isPickUp ? "inherit" : black,
          }}
        >
          ETA
        </Typography>
        <Typography sx={{ ml: 0.5 }}>{driverEta}</Typography>
      </Box>
    </Box>
  );
}

export default EnRouteChip;
