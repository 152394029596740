import { useState } from "react";

type UseFreeTrialDeleteDialogParams = {
  onClose: () => void;
  onDeleteApp: () => void;
};

const useFreeTrialDeleteDialog = (params: UseFreeTrialDeleteDialogParams) => {
  const { onClose, onDeleteApp } = params;

  // state
  const [freeTrialDeleteDialogOpen, setFreeTrialDeleteDialogOpen] =
    useState(false);

  // event handlers
  const handleFreeTrialDeleteDialogOpen = () => {
    setFreeTrialDeleteDialogOpen(true);
  };

  const handleFreeTrialDeleteDialogClose = () => {
    setFreeTrialDeleteDialogOpen(false);
    onClose();
  };

  const handleFreeTrialDeleteApp = () => {
    onDeleteApp();
    setFreeTrialDeleteDialogOpen(false);
  };

  return {
    freeTrialDeleteDialogOpen,
    onFreeTrialDeleteDialogOpen: handleFreeTrialDeleteDialogOpen,
    onFreeTrialDeleteDialogClose: handleFreeTrialDeleteDialogClose,
    onFreeTrialDeleteApp: handleFreeTrialDeleteApp,
  };
};

export { useFreeTrialDeleteDialog };
