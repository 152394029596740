import React, { ChangeEvent, Dispatch, SetStateAction } from "react";

import { Box, Typography } from "@mui/material";

import { grayDark } from "design-system/colors";
import { FormVariant, InputFormatVariant } from "../utils/types";
import DriverPayoutField from "./DriverPayoutField";
import { CreateDriverInput, UpdateDriverInput } from "types";

type DriverGratuityProps = {
  driver: CreateDriverInput | UpdateDriverInput;
  setDriver: Dispatch<SetStateAction<CreateDriverInput | UpdateDriverInput>>;
  variant?: FormVariant;
};

function DriverGratuity(props: DriverGratuityProps) {
  const { driver, setDriver, variant } = props;

  // event handlers
  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    setDriver({
      ...driver,
      settings: {
        ...driver.settings,
        [name]: Number(value) || null,
      },
    });
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box width="60%">
        <Typography variant="body2">Gratuity Amount</Typography>
        <Typography variant="caption" color={grayDark}>
          {`${driver.settings.gratuityPct || 0}`}% of the gratuity added by the
          customer goes to driver
        </Typography>
      </Box>
      <Box width="40%">
        <DriverPayoutField
          placeholder="Driver Gratuity"
          fieldName="gratuityPct"
          value={driver.settings.gratuityPct}
          onHandleFieldChange={handleFieldChange}
          format={InputFormatVariant.PERCENT}
          variant={variant}
        />
      </Box>
    </Box>
  );
}

export default DriverGratuity;
