import { useEffect, useState } from "react";

export enum DeleteReason {
  UserNeeds = "user_needs",
  Performance = "performance",
  NotNeeded = "not_needed",
  NotWorthCost = "not_worth_cost",
  Other = "other",
}

type UseDeleteAppFeedbackParams = {
  open: boolean;
};

const useDeleteAppFeedback = (params: UseDeleteAppFeedbackParams) => {
  const { open } = params;

  // state
  const [deleteReason, setDeleteReason] = useState<DeleteReason>(null);
  const [deleteReasonError, setDeleteReasonError] = useState(null);
  const [otherReason, setOtherDeleteReason] = useState("");

  // event handlers
  const handleDeleteReasonChange = (e) => {
    setDeleteReason(e.target.value);
    setOtherDeleteReason("");
  };

  const handleOtherReasonChange = (e: any) => {
    setOtherDeleteReason(e.target.value);
  };

  const handleDeleteReasonValidate = () => {
    if (!deleteReason) {
      setDeleteReasonError(true);
      return false;
    }

    setDeleteReasonError(false);
    return true;
  };

  // effects
  // reset state on open
  useEffect(() => {
    if (open) {
      setDeleteReason(null);
      setDeleteReasonError(null);
      setOtherDeleteReason("");
    }
  }, [open]);

  return {
    deleteReason,
    deleteReasonError,
    otherReason,
    onDeleteReasonChange: handleDeleteReasonChange,
    onOtherReasonChange: handleOtherReasonChange,
    onDeleteReasonValidate: handleDeleteReasonValidate,
  };
};

export { useDeleteAppFeedback };
