import React from "react";
import startCase from "lodash/startCase";

import { Card, CardActionArea, Typography, Box } from "@mui/material";

import { black, granite } from "../../../design-system/colors";
import MoovsAvatar from "../../../design-system/components/MoovsAvatar";
import { Driver } from "../../../types";

type DriversListItemProps = {
  driver: Driver;
  onDriverClick: (driverId: string) => void;
};

function DriversListItem(props: DriversListItemProps) {
  const { driver, onDriverClick } = props;

  const driverName = `${startCase(driver.firstName)} ${startCase(
    driver.lastName
  )}
  `;

  return (
    <Card variant="outlined">
      <CardActionArea disableRipple onClick={() => onDriverClick(driver.id)}>
        <Box
          display="flex"
          flexDirection="row"
          flex="1"
          py={1.5}
          px={3}
          height="76px"
          alignItems="center"
        >
          <Box display="flex" flex="2" flexDirection="row" alignItems="center">
            <Box mr={2}>
              <MoovsAvatar
                alt="contact, person"
                size="medium"
                src={driver.driverProfilePhoto}
                placeholder={[driver.firstName, driver.lastName]}
              />
            </Box>
            <Typography sx={{ fontWeight: 700, color: black, fontSize: 16 }}>
              {driverName}
            </Typography>
          </Box>
          <Box display="flex" flex="1">
            <Typography variant="body2" sx={{ color: granite }}>
              {driver.mobilePhone}
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default DriversListItem;
