import { useMutation } from "@apollo/client";

import { Box } from "@mui/material";

import ToggleHeader from "../ToogleHeader";
import LabeledSwitchInput from "../../LabeledSwitchInput";
import { useOperator, useScreenSize, useSnackbar } from "globals/hooks";
import { UPDATE_OPERATOR_SETTINGS } from "globals/graphql";
import { TextRecipientEnum } from "types";
import getRecipient from "globals/utils/getRecipient";

type SendTripStatusRecipientsBlockProps = {
  setSaveIndicatorState: (value: string) => void;
};

function SendTripStatusRecipientsBlock(
  props: SendTripStatusRecipientsBlockProps
) {
  const { setSaveIndicatorState } = props;
  const { isMobileView } = useScreenSize();
  const snackbar = useSnackbar();

  const {
    settings: { tripStatusUpdateTextTo },
  } = useOperator();

  const isRecipientPassenger =
    tripStatusUpdateTextTo === TextRecipientEnum.Passenger ||
    tripStatusUpdateTextTo === TextRecipientEnum.Both;

  const isRecipientBookingContact =
    tripStatusUpdateTextTo === TextRecipientEnum.BookingContact ||
    tripStatusUpdateTextTo === TextRecipientEnum.Both;

  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error(
        "Error updating setting for text notifications for trip status updates"
      );
    },
  });

  const handleUpdateRecipientAsPassenger = () => {
    setSaveIndicatorState("loading");
    updateOperatorSettings({
      variables: {
        input: {
          tripStatusUpdateTextTo: getRecipient(
            !isRecipientPassenger,
            isRecipientBookingContact
          ),
        },
      },
    });
  };

  const handleUpdateRecipientAsBookingContact = () => {
    setSaveIndicatorState("loading");
    updateOperatorSettings({
      variables: {
        input: {
          tripStatusUpdateTextTo: getRecipient(
            isRecipientPassenger,
            !isRecipientBookingContact
          ),
        },
      },
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      maxWidth={isMobileView ? "100%" : "50%"}
    >
      <ToggleHeader
        text="RECIPIENTS"
        subText="Specify who will receive Trip Status Update SMS notifications by default. You can override these default recipients in the trip-specific settings."
      />

      <Box display="flex" flexDirection="column" my={2} gap={1}>
        <LabeledSwitchInput
          name="setTripStatusUpdateRecipientsToBePassenger"
          checked={isRecipientPassenger}
          onChange={handleUpdateRecipientAsPassenger}
          text="Passenger"
        />

        <LabeledSwitchInput
          name="setTripStatusUpdateRecipientsToBeBookingContact"
          checked={isRecipientBookingContact}
          onChange={handleUpdateRecipientAsBookingContact}
          text="Booking Contact"
        />
      </Box>
    </Box>
  );
}

export default SendTripStatusRecipientsBlock;
