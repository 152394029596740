import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import isEqual from "lodash/isEqual";
import { useMutation, useQuery } from "@apollo/client";
import cloneDeep from "lodash/cloneDeep";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import CompanyInfoTab from "./settingTabs/CompanyInfoTab";
import OrderTypesTabItems from "./settingTabs/OrderTypesTabItems";
import { primaryMainColor } from "../../theme";
import { grayDark, grayMedium } from "../../design-system/colors";
import {
  CREATE_OPERATOR_ORDER_TYPE,
  LOAD_ORDER_TYPES_QUERY,
  REMOVE_OPERATOR_ORDER_TYPE,
  UPDATE_OPERATOR_MUTATION,
} from "../../globals/graphql";
import { Operator } from "../../types";
import { useSnackbar } from "../../globals/hooks/useSnackbar";
import GQLQueryStatusIndicator from "../../components/GQLQueryStatusIndicator";
import { useOperator, useScreenSize } from "../../globals/hooks";

function WebsiteSettingsPage() {
  // state
  const [tabIndex, setTabIndex] = useState(0);
  const [operator, setOperator] = useState<Partial<Operator> | undefined>();
  const [activeIds, setActiveIds] = useState([]);
  // to maintain the original checked items.
  const [oldActiveIds, setOldActiveIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const isCompanyInfoTab = tabIndex === 0;
  const isOrderTypesTab = tabIndex === 1;

  // hooks
  const snackbar = useSnackbar();
  const { isMediumTabletView } = useScreenSize();
  const operatorData = useOperator();

  // queries
  const {
    data: orderTypesData,
    error: orderTypesError,
    loading: orderTypesLoading,
  } = useQuery(LOAD_ORDER_TYPES_QUERY);

  const orderTypes = orderTypesData?.orderTypes;

  // mutations
  const [updateOperator] = useMutation(UPDATE_OPERATOR_MUTATION, {
    onCompleted() {
      setIsLoading(false);
    },
    onError(error) {
      setIsLoading(false);
      snackbar.error("Error updating operator");

      // resets graphql to match cache
      setOperator(cloneDeep(operatorData));
    },
  });

  const [createOperatorOrderType] = useMutation(CREATE_OPERATOR_ORDER_TYPE, {
    onCompleted() {
      setIsLoading(false);
    },
    onError(error) {
      const errorMessage = error.message
        ? `${error.message.replace("GraphQL error:", "Error:")}`
        : "";
      snackbar.error(errorMessage);
      setIsLoading(false);
    },
  });

  const [removeOperatorOrderType] = useMutation(REMOVE_OPERATOR_ORDER_TYPE, {
    onCompleted() {
      setIsLoading(false);
    },

    onError(error) {
      const errorMessage = error.message
        ? `${error.message.replace("GraphQL error:", "Error:")}`
        : "Error: At least 1 order type must be selected";
      snackbar.error(errorMessage);
      setIsLoading(false);
    },
  });

  // effects
  // set on load
  useEffect(() => {
    if (operatorData && !operator) {
      setOperator(cloneDeep(operatorData));
    }
  }, [operatorData, operator]);

  // check for changes in settings
  const hasMadeChanges =
    operatorData && operator && !isEqual(operatorData, operator);

  useEffect(() => {
    if (isOrderTypesTab && operator?.enabledOrderTypes?.length > 0) {
      let newActiveIds = operator.enabledOrderTypes.map((el) => {
        return el.id;
      });
      // to maintain original checked order types
      setOldActiveIds(newActiveIds);
      // to maintain the changes in order types
      setActiveIds(newActiveIds);
    }
  }, [operator, isOrderTypesTab]);

  // event handlers
  // handle the company info change.
  const saveOperator = () => {
    const { name, voicePhoneNumber, generalEmail, address, companyLogoUrl } =
      operator;

    updateOperator({
      variables: {
        input: {
          name,
          voicePhoneNumber,
          generalEmail,
          address,
          companyLogoUrl,
        },
      },
    });
  };

  // handle order types change
  const saveOrderTypes = () => {
    let hasChanged = false;

    if (activeIds.length <= 0) {
      setIsLoading(false);
      snackbar.error("At least 1 order type must be selected");
    } else {
      // Insert newly checked order types
      activeIds.forEach((element) => {
        const newIndex = oldActiveIds.indexOf(element);
        if (newIndex < 0) {
          hasChanged = true;
          createOperatorOrderType({
            variables: { input: { orderTypeId: element } },
          });
        }
      });

      // Remove newly unchecked orderTypes
      oldActiveIds.forEach((element) => {
        const newIndex = activeIds.indexOf(element);
        if (newIndex < 0) {
          hasChanged = true;
          removeOperatorOrderType({
            variables: { input: { orderTypeId: element } },
          });
        }
      });

      hasChanged ? setOldActiveIds([...activeIds]) : setIsLoading(false);
    }
  };

  const handleTabChange = (_, newValue) => {
    setTabIndex(newValue);
  };

  const handleCompanyInfoChange =
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setOperator({ ...operator, [key]: event.target.value });
    };

  const handleCompanyAddressChange = (value: string) => {
    setOperator({ ...operator, address: value });
  };

  const handleSaveChangesClick = () => {
    setIsLoading(true);

    isCompanyInfoTab && saveOperator();
    isOrderTypesTab && saveOrderTypes();
  };

  const handleOperatorPhotoChange = (key: string) => (url: string) => {
    setOperator({ ...operator, [key]: url });
  };

  if (
    (isOrderTypesTab && (orderTypesLoading || !orderTypesData)) ||
    !operatorData
  ) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        flex="1"
        px={3}
        mb={1}
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (orderTypesError) {
    return (
      <GQLQueryStatusIndicator error={orderTypesError} name="Order Types" />
    );
  }

  // custom styles
  const styles = {
    selected: {
      color: primaryMainColor,
    },
    root: {
      fontSize: 14,
      fontWeight: 600,
      minWidth: 111,
      borderBottom: `1px solid ${grayMedium}`,
    },
  };

  return (
    <>
      <Helmet>
        <title>Website Settings | Moovs</title>
      </Helmet>
      <Box ml={isMediumTabletView ? 0 : 11} width="700px">
        <Box ml={2} mt={3}>
          <Box display="flex" flex={1} justifyContent="space-between" mr={2}>
            <Typography variant="h2" style={{ marginTop: "auto" }}>
              Settings
            </Typography>
            <Box mt={isMediumTabletView ? 2 : 0}>
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={!hasMadeChanges}
                  onClick={handleSaveChangesClick}
                >
                  Save Changes
                </Button>
              )}
            </Box>
          </Box>
          <Tabs
            indicatorColor="primary"
            value={tabIndex}
            variant="standard"
            onChange={handleTabChange}
            sx={{
              marginTop: "29.5px",
              marginBottom: "40px",
              borderBottom: `1px solid ${grayMedium}`,
            }}
          >
            <Tab
              sx={{
                "&.Mui-selected": styles.selected,
                "&.MuiTab-root": styles.root,
              }}
              label="COMPANY INFO"
            />
            <Tab
              sx={{
                "&.Mui-selected": styles.selected,
                "&.MuiTab-root": styles.root,
              }}
              label="ORDER TYPES"
            />
          </Tabs>
        </Box>
        {isCompanyInfoTab ? (
          <CompanyInfoTab
            isMobileView={isMediumTabletView}
            companyInfo={operator}
            onCompanyInfoChange={handleCompanyInfoChange}
            onCompanyAddressChange={handleCompanyAddressChange}
            onPhotoChange={handleOperatorPhotoChange("companyLogoUrl")}
          />
        ) : (
          <>
            <Box ml={2} mt={-2}>
              <Box mb={3}>
                <Typography variant="body2" style={{ color: grayDark }}>
                  Select all order types you would like to offer
                </Typography>
              </Box>
              <Grid container>
                {orderTypes.map((orderType) => {
                  const { id, name } = orderType;

                  return (
                    <OrderTypesTabItems
                      key={id}
                      id={id}
                      name={name}
                      activeIds={activeIds}
                      setActiveIds={setActiveIds}
                    />
                  );
                })}
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default WebsiteSettingsPage;
