import React from "react";

import { Box, Typography } from "@mui/material";

import { white, granite } from "design-system/colors";
import TinyChip from "design-system/components/chips/TinyChip";

type SubscriptionLineItemProps = {
  name: string;
  price: string;
};

function SubscriptionLineItem(props: SubscriptionLineItemProps) {
  const { name, price } = props;

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Typography variant="body2" whiteSpace="nowrap" mr={1}>
          {name}
        </Typography>
        <TinyChip
          label="Separate charge"
          textTransform="uppercase"
          whiteSpace="nowrap"
          color={white}
          backgroundColor={granite}
        />
      </Box>
      <Box>
        <Typography variant="subtitle2" whiteSpace="nowrap">
          {price}
        </Typography>
      </Box>
    </Box>
  );
}

export default SubscriptionLineItem;
