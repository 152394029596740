import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation } from "@apollo/client";
import moment from "moment-timezone";
import pickBy from "lodash/pickBy";
import isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router-dom";

import CreateDrawer from "../../../globals/CreateDrawer";
import { CreateDriverInput, TripLocationsVariant } from "../../../../types";
import { useSnackbar } from "../../../../globals/hooks/useSnackbar";
import { CREATE_DRIVER_MUTATION } from "../../../../globals/graphql";
import { useAnalytics } from "../../../../globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import {
  AdditionalDetails,
  ContactDetails,
  DriverTabs,
  PayoutDetails,
} from "./components";
import { DriverTabViewVariant } from "./components/DriverTabViews/types";
import { validateCreateDriverInfo } from "./utils";
import { DriverErrors } from "./types";

const initialDriver = {
  firstName: "", // required
  lastName: "",
  mobilePhone: "", // required
  phoneCountryCode: "", // required
  phoneCountryDialCode: "", // required
  phoneCountryName: "", // required
  phoneCountryFormat: "", // required
  homePhone: "",
  homeAddress: "",
  email: "",
  licenseNumber: "",
  licenseClass: "",
  licenseState: "",
  licenseExpirationDate: null,
  note: "",
  driverProfilePhoto: "",
  settings: {
    // driver payout automation for Hourly Trip
    hourlyTripHourlyRate: null,
    hourlyTripLocationsVariant: TripLocationsVariant.LocationDropOff,
    hourlyTripBaseRatePct: null,
    // driver payout automation for One Way Trip
    oneWayTripHourlyRate: null,
    oneWayTripMinimumHours: null,
    oneWayTripLocationsVariant: TripLocationsVariant.LocationDropOff,
    oneWayTripBaseRatePct: null,
    oneWayTripFlatRate: null,
    // driver payout automation for Gratuity
    gratuityPct: 0,
  },
};

const initialDriverErrors = {
  firstName: "", // required
  mobilePhone: "", // required
  licenseExpirationDate: "", // not required, but for date validation
};

function CreateDriverDrawer() {
  const snackbar = useSnackbar();
  const history = useHistory();
  const { track } = useAnalytics();

  // state
  const [driver, setDriver] = useState<CreateDriverInput>(initialDriver);
  const [driverErrors, setDriverErrors] =
    useState<DriverErrors>(initialDriverErrors);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [driverTabView, setDriverTabView] = useState(
    DriverTabViewVariant.CONTACT_DETAILS
  );

  const hasDriverError = !isEmpty(pickBy(driverErrors));

  // mutations
  const [createNewDriver] = useMutation(CREATE_DRIVER_MUTATION, {
    onCompleted(data) {
      handleCreateDrawerClose();

      snackbar.success("Successfully created driver");
      track("create_driver");
    },
    onError(error) {
      setSubmitDisabled(false);
      const errorMessage = getErrorMessage(error) || "Error creating driver.";

      snackbar.error(errorMessage);
    },
    refetchQueries: ["DriverList"],
  });

  // event handlers
  const handleCreateDrawerClose = () => {
    history.push("/settings/drivers/");
  };

  const handleClickSave = () => {
    const driverInfoErrors = validateCreateDriverInfo(driver);

    if (!isEmpty(driverInfoErrors)) {
      setDriverErrors(driverInfoErrors);
      setDriverTabView(DriverTabViewVariant.CONTACT_DETAILS);
      return;
    }

    setSubmitDisabled(true);
    createNewDriver({
      variables: {
        input: {
          ...driver,
          ...(driver.licenseExpirationDate && {
            licenseExpirationDate: moment(driver.licenseExpirationDate).format(
              "YYYY-MM-DD"
            ),
          }),
        },
      },
    });
  };

  return (
    <CreateDrawer
      onClose={handleCreateDrawerClose}
      pageLabel="Create New Driver"
      submitLabel="Save"
      onSubmit={handleClickSave}
      saveError={hasDriverError}
      saveErrorText="Required fields missing in “Contact Details” tab. Please enter all required details"
      submitDisabled={submitDisabled}
      subHeaderContent={
        <DriverTabs
          setDriverTabView={setDriverTabView}
          driverTabView={driverTabView}
          hasDriverError={hasDriverError}
        />
      }
    >
      <Helmet>
        <title>Create Driver | Moovs</title>
      </Helmet>

      {driverTabView === DriverTabViewVariant.CONTACT_DETAILS && (
        <ContactDetails
          driver={driver}
          setDriver={setDriver}
          driverErrors={driverErrors}
          setDriverErrors={setDriverErrors}
        />
      )}
      {driverTabView === DriverTabViewVariant.ADDITIONAL_DETAILS && (
        <AdditionalDetails driver={driver} setDriver={setDriver} />
      )}
      {driverTabView === DriverTabViewVariant.PAYOUT_DETAILS && (
        <PayoutDetails driver={driver} setDriver={setDriver} />
      )}
    </CreateDrawer>
  );
}

export default CreateDriverDrawer;
