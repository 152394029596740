import React from "react";

import { Box, IconButton, Typography } from "@mui/material";

import { grayLight, moovsBlue } from "../../../../design-system/colors";
import { Vehicle } from "../../../../types";
import { ArrowLeftIcon } from "../../../../design-system/icons";
import SelectableImagesList from "./SelectableImagesList";
import MoovsMediaVehiclesList from "./MoovsMediaVehiclesList";

type MoovsMediaDialogBodyMobileProps = {
  vehicles: Vehicle[];
  onImageSelect: (photoIndex: number, isSelected: boolean) => void;
  onVehicleSelect: (vehicle: Vehicle) => void;
  selectedImageIndices: {};
  selectedVehicle: Vehicle;
  vehiclesLoading?: boolean;
};

function MoovsMediaDialogBodyMobile(props: MoovsMediaDialogBodyMobileProps) {
  const {
    vehicles,
    onImageSelect,
    onVehicleSelect,
    selectedImageIndices,
    selectedVehicle,
    vehiclesLoading,
  } = props;

  // event handlers
  const handleGoBackToVehiclesList = () => {
    onVehicleSelect(null);
  };

  return (
    <Box
      display="flex"
      flex={1}
      {...(!!selectedVehicle && {
        border: `1px solid ${grayLight}`,
        borderRadius: "4px",
      })}
    >
      {!selectedVehicle ? (
        <Box flex={1}>
          <MoovsMediaVehiclesList
            vehicles={vehicles}
            onVehicleSelect={onVehicleSelect}
            selectedVehicle={selectedVehicle}
            vehiclesLoading={vehiclesLoading}
            isMobileView
          />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" flex={1}>
          <Box display="flex" alignItems="center" mt={2} mx={1}>
            <IconButton
              style={{ position: "relative", top: "2px" }}
              onClick={handleGoBackToVehiclesList}
              size="large"
            >
              <ArrowLeftIcon color={moovsBlue} />
            </IconButton>
            <Typography
              variant="h4"
              style={{ position: "relative", top: "2px" }}
            >
              {selectedVehicle.name}
            </Typography>
          </Box>
          <SelectableImagesList
            selectedImages={selectedVehicle.photos}
            selectedImageIndices={selectedImageIndices}
            onImageSelect={onImageSelect}
          />
        </Box>
      )}
    </Box>
  );
}

export default MoovsMediaDialogBodyMobile;
