import { useCallback } from "react";
import TagManager from "react-gtm-module";

let isInitialized = false;

function useGoogleTracking() {
  const googleTrack = useCallback((action: string) => {
    if (process.env.REACT_APP_ENVIRONMENT !== "production") return;

    (((window as any).dataLayer as any[]) || []).push({
      event: action,
    });
  }, []);

  if (!isInitialized && process.env.REACT_APP_ENVIRONMENT === "production") {
    TagManager.initialize({ gtmId: "GTM-WCG4TSF" });
    isInitialized = true;
  }

  return { googleTrack };
}

export { useGoogleTracking };
