import React, { ReactNode } from "react";

import { Typography, Box, Tooltip, IconButton } from "@mui/material";

import { InfoIcon } from "../../design-system/icons";
import { granite, grayLight } from "../../design-system/colors";

const styles = {
  iconBg: {
    backgroundColor: grayLight,
    width: 26,
    height: 26,
    borderRadius: "50%",
  },
  tooltipIconButton: {
    paddingLeft: "6px",
    paddingBottom: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  tooltipIcon: {
    width: "16px",
    height: "16px",
  },
};

type TopOverviewBarProps = {
  items: {
    title?: string;
    value?: string | ReactNode;
    icon?: any;
    tooltip?: { title: string };
  }[];
};

function TopOverviewBar(props: TopOverviewBarProps) {
  const { items } = props;

  return (
    <Box
      display="flex"
      flexDirection="row"
      flex="0 1 auto"
      justifyContent="flex-start"
      style={{ overflowX: "auto", overflowY: "hidden" }}
      minHeight="50px"
      py={2}
      gap={2}
    >
      {items.map((item, i) => {
        return (
          item.title && (
            <Box
              key={`${item.title}_${i}`}
              display="flex"
              flexDirection="row"
              alignItems="center"
              mr={3}
            >
              <Box display="flex" flex=".7">
                {item.icon && (
                  <Box sx={{ mr: 1 }}>
                    <Box sx={styles.iconBg}>{item.icon}</Box>
                  </Box>
                )}
              </Box>
              <Box display="flex" flex="4" flexDirection="column">
                <Box display="flex" flex="1">
                  <Typography variant="overline" sx={{ whiteSpace: "nowrap" }}>
                    {item.title}
                    {item.tooltip && (
                      <Tooltip
                        enterDelay={200}
                        enterNextDelay={200}
                        title={item.tooltip.title}
                        placement="top"
                      >
                        <IconButton
                          aria-label="info"
                          sx={styles.tooltipIconButton}
                          size="large"
                        >
                          <InfoIcon size="small" color={granite} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Typography>
                </Box>
                <Box display="flex" flex="1">
                  {typeof item.value === "object" ? (
                    item.value
                  ) : (
                    <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                      {item.value}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          )
        );
      })}
    </Box>
  );
}

export default TopOverviewBar;
