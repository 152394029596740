import React from "react";

type ReplaceContactIconProps = {
  color?: string;
};

const ReplaceContactIcon = (props: ReplaceContactIconProps) => {
  const { color } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8581 11.486L20.3572 9.57783L22.5 11.0813M20.4294 9.87784C20.897 11.8525 20.6737 13.9398 19.7971 15.7886C18.9206 17.6375 17.4442 19.135 15.6163 20.0295C13.7884 20.9239 11.7205 21.1606 9.76042 20.6998C8.76093 20.4648 7.82057 20.0558 6.978 19.4979M4.94435 12.1331L3.17736 14.1564L1.30246 12.5378M3.17736 13.2779C2.71275 11.3041 2.93798 9.21877 3.81523 7.37202C4.69248 5.52526 6.16834 4.02958 7.99496 3.13614C9.82158 2.2427 11.8877 2.0059 13.8465 2.46551C14.9311 2.72 15.9461 3.17944 16.8423 3.81165"
        stroke={color || "#565656"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 16V13.7071C16 13.2544 15.8201 12.8201 15.5 12.5V12.5C15.1799 12.1799 14.7456 12 14.2929 12H9.70711C9.25435 12 8.82014 12.1799 8.5 12.5V12.5C8.17986 12.8201 8 13.2544 8 13.7071V16M14 8C14 9.10457 13.1046 10 12 10C10.8954 10 10 9.10457 10 8C10 6.89543 10.8954 6 12 6C13.1046 6 14 6.89543 14 8Z"
        stroke={color || "#565656"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReplaceContactIcon;
