import React from "react";
import first from "lodash/first";
import moment from "moment";

import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Stop } from "types";

function TripDateColumn(props: GridRenderCellParams<string>) {
  const { row: trip } = props;

  const tripPickUpDate = moment
    .utc(first(trip.stops as unknown as Stop[]).dateTime)
    .format("M/DD/YY");

  return (
    <Typography variant="body2" pl={1}>
      {tripPickUpDate}
    </Typography>
  );
}

export default TripDateColumn;
