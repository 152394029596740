import React from "react";
import findLast from "lodash/findLast";

import { Box } from "@mui/material";

import { RouteStatusLog, Route } from "types";
import TripItineraryHeader from "pages/requests/RequestOverview/ReservationOverview/TripItineraryBlock/components/TripItineraryHeader";
import TripTimeline from "pages/requests/RequestOverview/ReservationOverview/TripItineraryBlock/components/TripTimeline";
import RideReportSummary from "./RideReportSummary";
import NoDriverTrackingPanel from "./NoDriverTrackingPanel";
import MoovsMarketAppNoAccess from "components/globals/MoovsMarketAppNoAccess";
import DriverAppNoAccess from "../../../../design-system/illustrations/driverAppNoAccess.svg";
import { useOperator } from "globals/hooks";

type RideReportTabProps = {
  route: Route;
  routeStatusLogs: RouteStatusLog[];
};

function RideReportTab(props: RideReportTabProps) {
  const { route, routeStatusLogs } = props;

  // derived state
  const doneStatusLog = findLast(routeStatusLogs, ["statusSlug", "done"]);
  const { garageOut, garageIn } = route;
  const noDriverTracking = doneStatusLog && !garageOut;
  const operator = useOperator();

  if (noDriverTracking) {
    return <NoDriverTrackingPanel />;
  }

  return (
    <Box height="100%">
      {operator?.moovsMarketApps.driverApp.active ? (
        <>
          <TripItineraryHeader trip={route.trip} />
          <Box mt={4} mb={1}>
            <TripTimeline
              trip={route.trip}
              variant="ride-report"
              garageIn={garageIn}
              garageOut={garageOut}
            />
          </Box>
          {garageIn && garageOut && (
            <RideReportSummary garageOut={garageOut} garageIn={garageIn} />
          )}
        </>
      ) : (
        <Box
          display="flex"
          height="100%"
          minHeight="50vh"
          alignItems="center"
          justifyContent="center"
        >
          <MoovsMarketAppNoAccess
            title="No Access to Ride Report"
            description="Please install Driver Platform app in Moovs Market to view"
            icon={DriverAppNoAccess}
          />
        </Box>
      )}
    </Box>
  );
}

export default RideReportTab;
