import React, { useMemo } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import withStyles from "@mui/styles/withStyles";

import { alabaster, white } from "../../../../design-system/colors";
import IndividualCell from "./IndividualCell";

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 12,
    fontStyle: "normal",
  },
  root: {
    borderBottom: "none",
    padding: "8px",
  },
}))(TableCell);

const SeparatorCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
    padding: "8px",
    backgroundColor: white,
    width: "16px",
  },
}))(TableCell);

const FixedLayoutTable = withStyles((theme) => ({
  root: {
    tableLayout: "fixed",
  },
}))(Table);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: alabaster,
    },
  },
}))(TableRow);

type TwoColumnTableGeneratorType = {
  columnsData: {
    columnText: string;
    percentage: number;
    amount: number;
  }[];
  tableHeaderText: string;
};

function TwoColumnTableGenerator(props: TwoColumnTableGeneratorType) {
  const { columnsData, tableHeaderText } = props;

  const twoColumnsData = useMemo(() => {
    const halfLengthofSortedColumn = Math.round(columnsData.length / 2);
    // to help with 4 columns in the table instead of 2
    const newOrderTypeBreakdownColumns = [];
    let counter = 0;
    while (counter < halfLengthofSortedColumn) {
      newOrderTypeBreakdownColumns.push([
        columnsData[counter],
        columnsData[counter + halfLengthofSortedColumn],
      ]);
      counter++;
    }

    return newOrderTypeBreakdownColumns;
  }, [columnsData]);

  return (
    <TableContainer>
      <Table aria-label="two column breakdown table" size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell
              style={{ paddingTop: "0px", paddingBottom: "20px" }}
            >
              <Typography variant="h4">{tableHeaderText}</Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
      </Table>

      {/* Fixed Layout table to help with word wrap */}
      <FixedLayoutTable>
        <TableBody>
          {twoColumnsData.map((row, index) => (
            <StyledTableRow key={index}>
              {/* left column */}
              <IndividualCell cell={row[0]} />

              {/* Separator column  - margin between 2 different order types*/}
              <SeparatorCell />

              {/* right column */}
              {!!row[1] && <IndividualCell cell={row[1]} />}
            </StyledTableRow>
          ))}
        </TableBody>
      </FixedLayoutTable>
    </TableContainer>
  );
}

export default TwoColumnTableGenerator;
