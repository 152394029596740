import React from "react";

import { Box } from "@mui/material";

import MoovsTabs from "components/globals/MoovsTabs";
import { VehicleTabViewVariant } from "./VehicleTabViews/types";
import { WarningIcon } from "design-system/icons";
import { errorRed } from "design-system/colors";
import { useScreenSize } from "globals/hooks";

type VehicleTabsProps = {
  setVehicleTabView: React.Dispatch<
    React.SetStateAction<VehicleTabViewVariant>
  >;
  vehicleTabView: VehicleTabViewVariant;
  hasRequiredInfoError: boolean;
  hasPricingError: boolean;
  hasChildSeatError: boolean;
};

function VehicleTabs(props: VehicleTabsProps) {
  const {
    setVehicleTabView,
    vehicleTabView,
    hasRequiredInfoError,
    hasPricingError,
    hasChildSeatError,
  } = props;

  // hooks
  const { isSmallTabletView } = useScreenSize();

  // require info tab warning icon
  const displayRequiredInfoWarning =
    hasRequiredInfoError &&
    vehicleTabView !== VehicleTabViewVariant.REQUIRED_INFO;

  // pricing tab warning icon
  const displayPricingWarning =
    hasPricingError && vehicleTabView !== VehicleTabViewVariant.PRICING;

  // features tab warning icon
  const displayChildSeatWarning =
    hasChildSeatError && vehicleTabView !== VehicleTabViewVariant.FEATURES;

  return (
    <Box ml={isSmallTabletView ? 0.5 : 2.5}>
      <MoovsTabs
        value={vehicleTabView}
        onChange={setVehicleTabView}
        variant="scrollable"
        tabs={[
          {
            label: "REQUIRED INFO",
            value: VehicleTabViewVariant.REQUIRED_INFO,
            ...(displayRequiredInfoWarning && {
              Icon: (
                <Box marginBottom={-0.5}>
                  <WarningIcon color={errorRed} size="small" />
                </Box>
              ),
            }),
          },
          {
            label: "OPTIONAL INFO",
            value: VehicleTabViewVariant.OPTIONAL_INFO,
          },
          { label: "PHOTOS", value: VehicleTabViewVariant.PHOTOS },
          {
            label: "FEATURES",
            value: VehicleTabViewVariant.FEATURES,
            ...(displayChildSeatWarning && {
              Icon: (
                <Box marginBottom={-0.5}>
                  <WarningIcon color={errorRed} size="small" />
                </Box>
              ),
            }),
          },
          {
            label: "PRICING",
            value: VehicleTabViewVariant.PRICING,
            ...(displayPricingWarning && {
              Icon: (
                <Box marginBottom={-0.5}>
                  <WarningIcon color={errorRed} size="small" />
                </Box>
              ),
            }),
          },
          {
            label: "CUSTOMER PORTAL SETTINGS",
            value: VehicleTabViewVariant.BOOKING_TOOL_SETTINGS,
          },
        ]}
      />
    </Box>
  );
}

export default VehicleTabs;
