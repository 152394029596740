import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";

import { granite, grayLight, moovsBlue, white } from "design-system/colors";
import { ChevronRightIcon } from "design-system/icons";
import EmptyCircleIcon from "design-system/icons/info/EmptyCircleIcon";
import { useAnalytics, useScreenSize, useSnackbar } from "globals/hooks";
import { UPDATE_OPERATOR_GETTING_STARTED_MUTATION } from "globals/graphql";
import { TASK_TEXT, TaskVariant } from "./util";

type IncompleteTaskProps = {
  task: TaskVariant;
};

const INCOMPLETE_TASK_DETAILS = {
  [TaskVariant.VEHICLE]: {
    header: TASK_TEXT[TaskVariant.VEHICLE].header,
    subtext: TASK_TEXT[TaskVariant.VEHICLE].subtext,
    navigateButtonText: "Create Vehicle",
    path: "/vehicles/create",
    hasSkip: false,
  },
  [TaskVariant.DRIVER]: {
    header: TASK_TEXT[TaskVariant.DRIVER].header,
    subtext: TASK_TEXT[TaskVariant.DRIVER].subtext,
    navigateButtonText: "Create Driver",
    path: "/settings/drivers/create",
    hasSkip: false,
  },
  [TaskVariant.QUOTE]: {
    header: TASK_TEXT[TaskVariant.QUOTE].header,
    subtext: TASK_TEXT[TaskVariant.QUOTE].subtext,
    navigateButtonText: "Create Quote",
    path: "/quotes/create",
    hasSkip: true,
  },
  [TaskVariant.RESERVATION]: {
    header: TASK_TEXT[TaskVariant.RESERVATION].header,
    subtext: TASK_TEXT[TaskVariant.RESERVATION].subtext,
    navigateButtonText: "Create Reservation",
    path: "/reservations/create",
    hasSkip: false,
  },
  [TaskVariant.MOOVS_PAYMENT]: {
    header: TASK_TEXT[TaskVariant.MOOVS_PAYMENT].header,
    subtext: TASK_TEXT[TaskVariant.MOOVS_PAYMENT].subtext,
    navigateButtonText: "Complete Setup",
    path: "settings/general?tab=payments",
    hasSkip: false,
  },
};

function IncompleteTask(props: IncompleteTaskProps) {
  const { task } = props;

  const { header, subtext, navigateButtonText, path, hasSkip } =
    INCOMPLETE_TASK_DETAILS[task];

  const { QUOTE, RESERVATION, MOOVS_PAYMENT } = TaskVariant;
  // hooks
  const { isMobileView } = useScreenSize();
  const history = useHistory();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // state
  const [expanded, setExpanded] = useState(false);

  // mutation
  const [updateOperatorGettingStarted, { loading }] = useMutation(
    UPDATE_OPERATOR_GETTING_STARTED_MUTATION,
    {
      onCompleted: (data) => {
        const { taskFirstQuoteCompletedAt } =
          data.updateOperatorGettingStarted.operator.gettingStarted;

        if (taskFirstQuoteCompletedAt) {
          track("gettingStarted_quoteTaskSkipped");
        }
      },
      onError: () => {
        snackbar.error("Can not skip quote");
      },
    }
  );

  // event handlers
  const handleExpandChange = () => {
    trackExpandingTask();

    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleNavigateClick = () => {
    trackNavigatingTask();
    history.push(path);
  };

  const handleSkipQuote = () => {
    updateOperatorGettingStarted({
      variables: {
        input: {
          skipQuote: true,
        },
      },
    });
  };

  const trackNavigatingTask = () => {
    if (task === QUOTE) {
      track("gettingStarted_quoteTaskOpened");
    } else if (task === RESERVATION) {
      track("gettingStarted_reservationTaskOpened");
    } else if (task === MOOVS_PAYMENT) {
      track("gettingStarted_paymentTaskOpened");
    }
  };

  const trackExpandingTask = () => {
    if (!expanded) {
      if (task === QUOTE) {
        track("gettingStarted_quoteTaskViewed");
      } else if (task === RESERVATION) {
        track("gettingStarted_reservationTaskViewed");
      } else if (task === MOOVS_PAYMENT) {
        track("gettingStarted_paymentTaskViewed");
      }
    }
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpandChange}
      variant="outlined"
      sx={{
        "&.MuiAccordion-root:before": {
          display: "none",
        },
        borderRadius: 1,
        "&:hover": {
          borderColor: expanded ? granite : moovsBlue,
        },
        paddingY: 0.8,
        borderColor: expanded ? granite : grayLight,
        backgroundColor: white,
        "& .MuiAccordionSummary-expandIconWrapper": {
          "&.Mui-expanded": {
            transform: "translateY(-50%) rotate(90deg)",
          },
        },
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
      }}
    >
      <AccordionSummary expandIcon={<ChevronRightIcon color={moovsBlue} />}>
        <Box display="flex">
          <Box
            display="flex"
            justifyContent="center"
            alignItems={isMobileView ? "center" : "flex-start"}
            mr={2}
          >
            <EmptyCircleIcon />
          </Box>
          <Box pr={2.5}>
            <Typography variant="h4" fontWeight={500} mb={0.5}>
              {header}
            </Typography>
            <Typography variant="body2" color={granite}>
              {subtext}
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingTop: 0 }}>
        <Box
          display="flex"
          justifyContent={hasSkip ? "space-between" : "flex-end"}
        >
          {hasSkip && (
            <Box>
              {loading ? (
                <CircularProgress sx={{ color: granite }} size={20} />
              ) : (
                <Button color="primary" onClick={handleSkipQuote}>
                  <Typography mr={2} fontWeight={500}>
                    Skip
                  </Typography>
                </Button>
              )}
            </Box>
          )}

          <Box>
            <Button
              color="primary"
              variant="contained"
              sx={{ minWidth: "200px" }}
              onClick={handleNavigateClick}
            >
              <Typography fontWeight={500}>{navigateButtonText}</Typography>
            </Button>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default IncompleteTask;
