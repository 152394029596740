import React from "react";
import { Link } from "react-router-dom";

import { Badge, Box, ButtonBase, Typography } from "@mui/material";

import { useScreenSize } from "globals/hooks";
import MoovsTooltip from "components/MoovsTooltip";
import {
  grayMedium,
  granite,
  moovsBlueSelected,
  moovsBlue,
} from "design-system/colors";

type SquareNavButtonProps = {
  itemsCount?: number;
  text: string;
  active?: boolean;
  path?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  tooltipText?: string;
  tooltipSubtext?: React.ReactNode;
  onClick?: () => void;
};

function SquareNavButton(props: SquareNavButtonProps) {
  const {
    text,
    icon,
    path,
    disabled,
    active = false,
    onClick,
    tooltipText,
    tooltipSubtext,
    itemsCount = 0,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  const navButtonStyles = {
    root: {
      display: "flex",
      cursor: "pointer !important",
      alignItems: "center",
      alignContent: "center",
      padding: "8px",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: "#f0f5ff",
      },
    },
    settingsRoot: {
      "&:hover": {
        backgroundColor: grayMedium,
      },
    },
    navText: {
      color: granite,
      fontWeight: 500,
    },
    navTextSmall: {
      mt: "3px",
      fontWeight: 500,
      fontSize: 11,
    },
    activeSection: {
      backgroundColor: moovsBlueSelected,
      "&:hover": {
        backgroundColor: moovsBlueSelected,
      },
    },
    activeText: {
      color: moovsBlue,
    },
    disabled: {
      color: `${grayMedium} !important`,
    },
  };

  return (
    <MoovsTooltip
      hidden={!tooltipText}
      tooltipText={tooltipText}
      tooltipSubtext={tooltipSubtext}
    >
      <ButtonBase
        disabled={disabled}
        disableTouchRipple
        sx={{ display: "flex", flex: 1 }}
        {...(path && { to: path, component: Link })}
        {...(onClick && { onClick })}
      >
        <Box
          tabIndex={0}
          sx={[navButtonStyles.root, active && navButtonStyles.activeSection]}
          flexDirection="column"
          width="100%"
        >
          {icon}
          <Badge
            color="error"
            badgeContent={itemsCount}
            sx={{ position: "absolute", top: 8, right: 8 }}
          ></Badge>
          {!isMobileView && (
            <Typography
              sx={{
                ...navButtonStyles.navTextSmall,
                ...(active && navButtonStyles.activeText),
                ...(disabled && navButtonStyles.disabled),
                textAlign: "center",
                whiteSpace: "nowrap",
              }}
            >
              {text}
            </Typography>
          )}
        </Box>
      </ButtonBase>
    </MoovsTooltip>
  );
}

export default SquareNavButton;
