import { useEffect, useState } from "react";
import filter from "lodash/filter";

import { EmailRecipient } from "../SendEmailDialog";

type UseToCcEmailsProps = {
  toEmails?: EmailRecipient[];
  ccEmails?: EmailRecipient[];
  open: boolean;
};

function useToCcEmails(props: UseToCcEmailsProps) {
  const { open } = props;

  // state
  const [toEmails, setToEmails] = useState<EmailRecipient[]>([]);
  const [ccEmails, setCcEmails] = useState<EmailRecipient[]>([]);

  // event handlers
  const handleToEmailsChange = (emails: EmailRecipient[]) => {
    setToEmails(emails);
  };
  const handleCcEmailsChange = (emails: EmailRecipient[]) => {
    setCcEmails(emails);
  };

  // effects
  // auto populate to and CC
  useEffect(() => {
    // only autoPopulate when opening to prevent seeing updates while closing
    if (open) {
      setToEmails(filter(props.toEmails, "email"));
      setCcEmails(filter(props.ccEmails, "email"));
    }
  }, [props.toEmails, props.ccEmails, open]);

  return {
    toEmails,
    ccEmails,
    onToEmailsChange: handleToEmailsChange,
    onCcEmailsChange: handleCcEmailsChange,
  };
}

export default useToCcEmails;
