import { dateFormatter } from "globals/utils/helpers";
import first from "lodash/first";
import last from "lodash/last";
import moment from "moment";

import { Stop, TripCategory } from "types";

type CalculateTripEndTimeParams = {
  tripCategory: TripCategory;
  stops: Stop[];
  totalDuration: number;
};

type TripStartEndDateTimeParserParams = {
  startDateTime: Date;
  endDateTime: Date;
  excludeNewLine?: boolean;
};

/**
 * Returns the end date time for a trip if trip is hourly
 * @param {TripCategory} tripCategory
 * @param {Stop[]} stops
 * @param {number} totalDuration
 * @returns {Date} tripEndDateTime:
 * null if not hourly, else last stop dateTime.
 * If totalDuration exists, returns dateTime where totalDuration added to first stop dateTIme
 */
export const calculateTripEndDateTime = (
  params: CalculateTripEndTimeParams
): Date => {
  const { tripCategory, stops, totalDuration } = params;
  let tripEndDateTime;
  if (tripCategory === TripCategory.Hourly) {
    let tripEndDateTime = last(stops)?.dateTime || null;
    if (tripEndDateTime && totalDuration) {
      const tripStartDateTime = first(stops).dateTime;

      return (tripEndDateTime = moment(tripStartDateTime)
        .add(totalDuration, "m")
        .toDate());
    }
  }
  return tripEndDateTime;
};

/**
 * @param {Date} startDateTime
 * @param {Date} endDateTime
 *
 * @returns {string}
 *
 * One Way Trip returns:
 *    Friday, Aug 19th, 2022 2:01 pm
 *
 * Hourly Trip, Same Date returns:
 *    Thursday, Aug 18th, 2022
 *    7:20 pm - 9:20 PM
 *
 * Hourly Trip, Different Dates returns:
 *    Friday, Aug 19th, 2022 11:50 pm -
 *    Saturday, Aug 20th, 2022 3:20 AM
 */
export const tripStartEndDateTimeParser = (
  params: TripStartEndDateTimeParserParams
): string => {
  const { startDateTime, endDateTime, excludeNewLine } = params;

  const tripStartDateTime = dateFormatter(moment.utc(startDateTime), "medium", {
    mode: "dateTime",
  });

  // One Way Trip: Friday, Aug 19th, 2022 11:50 PM
  if (!endDateTime) return tripStartDateTime;

  const tripEndDateTime = dateFormatter(moment.utc(endDateTime), "medium", {
    mode: "dateTime",
  });
  const tripStartDate = dateFormatter(moment.utc(startDateTime), "medium", {
    mode: "date",
  });

  const tripEndTime = moment.utc(endDateTime).format("LT");
  const tripStartTime = moment.utc(startDateTime).format("h:mm a");

  const startDate = moment.utc(startDateTime).format("ll");
  const endDate = moment.utc(endDateTime).format("ll");
  const sameDate = startDate === endDate;

  const newline = excludeNewLine ? "" : "\n";

  // Hourly Trip, Different Dates: Friday, Aug 19th, 2022 11:50 pm - Saturday, Aug 20th, 2022 3:20 AM
  const startToEndDifferentDays = `${tripStartDateTime} - ${newline} ${tripEndDateTime}`;

  // Hourly Trip, Same Date: Thursday, Aug 18th, 2022 7:20 pm - 9:20 PM
  const startToEndSameDay = `${tripStartDate} ${newline} ${tripStartTime} - ${tripEndTime}`;

  return sameDate ? startToEndSameDay : startToEndDifferentDays;
};
