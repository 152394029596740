import React from "react";

import { Stack, Box } from "@mui/material";

import { grayLight } from "design-system/colors";

// styles
const styles = {
  leftSkeleton: {
    backgroundColor: "#F6F6F6",
    borderRadius: "4px",
    height: "16px",
  },
  rightSkeleton: {
    backgroundColor: grayLight,
    borderRadius: "4px",
    height: "19px",
  },
};

function ReportSkeleton() {
  return (
    <Stack spacing="7px">
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box sx={{ ...styles.leftSkeleton, width: 35, marginRight: "7px" }} />
        <Box sx={{ ...styles.rightSkeleton, width: 126 }} />
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box sx={{ ...styles.leftSkeleton, width: 35, marginRight: "7px" }} />
        <Box sx={{ ...styles.rightSkeleton, width: 61 }} />
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box sx={{ ...styles.leftSkeleton, width: 29, marginRight: "13px" }} />
        <Box sx={{ ...styles.rightSkeleton, width: 119 }} />
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box sx={{ ...styles.leftSkeleton, width: 29, marginRight: "13px" }} />
        <Box sx={{ ...styles.rightSkeleton, width: 102 }} />
      </Box>
    </Stack>
  );
}

export default ReportSkeleton;
