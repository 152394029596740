import React from "react";

type AutomatedPricingIconProps = {
  size?: "small";
  color?: string;
};

const AutomatedPricingIcon = (props: AutomatedPricingIconProps) => {
  const { size, color } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#automated-pricing-icon-clip-path)">
          <path
            d="M9.66706 1L3.09839 8.13793C3.04715 8.19453 3.0143 8.26361 3.00372 8.33705C2.99314 8.41048 3.00525 8.48521 3.03864 8.55243C3.07204 8.61966 3.12531 8.67659 3.1922 8.71651C3.25909 8.75643 3.33681 8.77769 3.41621 8.77778H6.33328V15L12.902 7.86311C12.9531 7.8064 12.9858 7.73726 12.9963 7.66381C13.0068 7.59036 12.9947 7.51563 12.9614 7.44838C12.928 7.38113 12.8748 7.32414 12.808 7.28408C12.7412 7.24403 12.6635 7.22257 12.5841 7.22222H9.66706V1Z"
            stroke={color || "#34EC87"}
            strokeWidth="1.5528"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="automated-pricing-icon-clip-path">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m15.0006 2-9.85302 10.197c-.07686.0809-.12612.1796-.142.2845s.0023.2117.05239.3077c.05008.096.13.1774.23033.2344.10034.057.21692.0874.33602.0875h4.37559v8.8889l9.85299-10.1956c.0767-.081.1258-.1797.1416-.2847.0158-.1049-.0024-.2117-.0525-.3077-.05-.0961-.1298-.1775-.23-.2347-.1002-.0573-.2167-.0879-.3358-.0884h-4.3756z"
        stroke={color || "#34ec87"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default AutomatedPricingIcon;
