import { useEffect, useState } from "react";

import { EmailModeEnum } from "types";

type UsePdfAttachmentProps = {
  open: boolean;
  mode: EmailModeEnum;
};

function usePdfAttachment(props: UsePdfAttachmentProps) {
  const { open, mode } = props;

  // state
  const [pdfAttached, setPdfAttached] = useState(false);

  // event handlers
  const handleClosePdfAttachment = (e: React.MouseEvent) => {
    e.stopPropagation();
    setPdfAttached(false);
  };

  // effects
  // set pdfAttached to true if Reservation or Price Summary Email dialog opens
  useEffect(() => {
    if (
      open &&
      (mode === EmailModeEnum.Reservation ||
        mode === EmailModeEnum.PriceSummary)
    ) {
      setPdfAttached(true);
    }
  }, [open, mode]);

  return { pdfAttached, onClosePdfAttachment: handleClosePdfAttachment };
}

export default usePdfAttachment;
