import { useQuery } from "@apollo/client";
import React from "react";

import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  TextField,
} from "@mui/material";

import VehicleFormSection from "components/vehicles/VehicleFormSection";
import { LOAD_VEHICLE_TYPES_QUERY } from "globals/graphql";
import {
  getVehicleTypeNameByTypeSlug,
  vehicleIconByIconCategory,
} from "globals/utils/vehicleTypes";
import { VehicleType } from "types";
import { CreateVehicle, VehicleRequiredInfoError } from "../../types";
import theme from "theme";

type RequireInfoProps = {
  vehicle: CreateVehicle;
  setVehicle: React.Dispatch<React.SetStateAction<CreateVehicle>>;
  setVehicleRequiredInfoErrors: React.Dispatch<
    React.SetStateAction<VehicleRequiredInfoError>
  >;
  vehicleRequiredInfoError: VehicleRequiredInfoError;
};

function RequiredInfo(props: RequireInfoProps) {
  const {
    vehicle,
    vehicleRequiredInfoError,
    setVehicle,
    setVehicleRequiredInfoErrors,
  } = props;

  // query
  const { data: vehicleTypesData } = useQuery(LOAD_VEHICLE_TYPES_QUERY);

  const vehicleTypes = vehicleTypesData ? vehicleTypesData.vehicleTypes : [];

  // event handler
  const handleRequireInfoChange = (e) => {
    let { value, name } = e.target;

    if (name === "capacity") {
      value = value === "" ? "" : Number(value);
    }

    setVehicle({ ...vehicle, [name]: value });

    setVehicleRequiredInfoErrors({
      ...vehicleRequiredInfoError,
      [name]: "",
    });
  };

  return (
    <VehicleFormSection title="Required Info">
      {/* Name */}
      <Box my="8px">
        <TextField
          required
          fullWidth
          id="vehicle-name"
          name="name"
          label="Vehicle Name"
          variant="outlined"
          value={vehicle.name}
          onChange={handleRequireInfoChange}
          error={!!vehicleRequiredInfoError.name}
          helperText={vehicleRequiredInfoError.name}
        />
      </Box>

      {/* Type Slug */}
      <Box my="8px">
        <FormControl
          fullWidth
          variant="outlined"
          error={!!vehicleRequiredInfoError.typeSlug}
        >
          <TextField
            name="typeSlug"
            required
            id="vehicle-type-select"
            value={vehicle.typeSlug}
            onChange={handleRequireInfoChange}
            select
            SelectProps={{
              renderValue: (value) =>
                getVehicleTypeNameByTypeSlug(value, vehicleTypes),
            }}
            variant="outlined"
            label="Vehicle Type"
            error={!!vehicleRequiredInfoError.typeSlug}
            inputProps={{ helpertext: vehicleRequiredInfoError.typeSlug }}
          >
            {vehicleTypes.map(
              ({ typeName, typeSlug, iconCategory }: VehicleType) => (
                <MenuItem
                  key={typeSlug}
                  value={typeSlug}
                  sx={{
                    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
                  }}
                >
                  <Box mr={1.5} position="relative" top="2px">
                    {iconCategory && vehicleIconByIconCategory()[iconCategory]}
                  </Box>
                  {typeName}
                </MenuItem>
              )
            )}
          </TextField>
          <FormHelperText error={!!vehicleRequiredInfoError.typeSlug}>
            {vehicleRequiredInfoError.typeSlug}
          </FormHelperText>
        </FormControl>
      </Box>

      {/* Capacity */}
      <Box my="8px">
        <TextField
          required
          fullWidth
          id="vehicle-capacity"
          label="Passenger Capacity"
          variant="outlined"
          type="number"
          name="capacity"
          value={vehicle.capacity}
          onChange={handleRequireInfoChange}
          error={!!vehicleRequiredInfoError.capacity}
          helperText={vehicleRequiredInfoError.capacity}
        />
      </Box>
    </VehicleFormSection>
  );
}

export default RequiredInfo;
