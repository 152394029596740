import React, { ChangeEvent } from "react";

import { Box, InputBase, Typography } from "@mui/material";

import { errorRed, grayLight } from "design-system/colors";
import { useScreenSize } from "globals/hooks";

type SubjectFieldProps = {
  subject: string;
  onSubjectChange: (event: ChangeEvent<HTMLInputElement>) => void;
  subjectError: string;
};

function SubjectField(props: SubjectFieldProps) {
  const { subject, onSubjectChange, subjectError } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Box
      pb={2}
      mx={isMobileView ? -2 : -4}
      px={isMobileView ? 2 : 4}
      display="flex"
      flexDirection="column"
      borderBottom={`1px solid ${grayLight}`}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography mr={2} variant="subtitle2">
          Subject:
        </Typography>
        <Box
          display="flex"
          height="100%"
          width="100%"
          sx={{
            alignItems: "flex-start",
            cursor: "text",
            overflowX: "hidden",
          }}
        >
          <InputBase
            name="subject"
            value={subject}
            multiline
            fullWidth
            onChange={onSubjectChange}
          />
        </Box>
      </Box>
      <Typography variant="caption" color={errorRed}>
        {subjectError}
      </Typography>
    </Box>
  );
}

export default SubjectField;
