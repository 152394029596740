import { WeekdaysEnum } from "./types";

// weekdays is a list of objects {id, value} because of RHFMultipleSelect
export const weekdays = [
  { id: WeekdaysEnum.MONDAY, value: WeekdaysEnum.MONDAY, isSelected: true },
  { id: WeekdaysEnum.TUESDAY, value: WeekdaysEnum.TUESDAY, isSelected: true },
  {
    id: WeekdaysEnum.WEDNESDAY,
    value: WeekdaysEnum.WEDNESDAY,
    isSelected: true,
  },
  {
    id: WeekdaysEnum.THURSDAY,
    value: WeekdaysEnum.THURSDAY,
    isSelected: true,
  },
  { id: WeekdaysEnum.FRIDAY, value: WeekdaysEnum.FRIDAY, isSelected: true },
  {
    id: WeekdaysEnum.SATURDAY,
    value: WeekdaysEnum.SATURDAY,
    isSelected: true,
  },
  { id: WeekdaysEnum.SUNDAY, value: WeekdaysEnum.SUNDAY, isSelected: true },
];

// initial dynamic pricing input that will be updated as user moves from one step/form to another
export const initialInput = {
  date: {
    startDate: null,
    isIncrease: null,
    isPercent: false,
    amount: 0,
    vehicles: [], // additional vehicle data for UI outputs, not for mutation
    vehiclesAvailable: 0, // necessary for UI outputs, not for mutation
    vehicleIds: [],
    repeatAnnually: false,
    name: "",
    activate: true,
  },
  dateRange: {
    startDate: null,
    endDate: null,
    daysOfWeek: [...weekdays],
    isIncrease: null,
    isPercent: false,
    amount: 0,
    vehicles: [], // additional vehicle data for UI outputs, not for mutation
    vehiclesAvailable: 0, // necessary for UI outputs, not for mutation
    vehicleIds: [],
    repeatAnnually: false,
    name: "",
    activate: true,
  },
  timesOfDay: {
    startTime: null,
    endTime: null,
    daysOfWeek: [...weekdays],
    isIncrease: null,
    isPercent: false,
    amount: 0,
    vehicles: [], // additional vehicle data for UI outputs, not for mutation
    vehiclesAvailable: 0, // necessary for UI outputs, not for mutation
    vehicleIds: [],
    repeatAnnually: false,
    name: "",
    activate: true,
  },
};
