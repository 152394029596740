import size from "lodash/size";
import moment from "moment";

import { getSelectedVehicleIds } from "./getDynamicPricingVehicle";
import { DynamicPricingInputType } from "./types";

type FinalizeDynamicPricingInputProps = {
  dynamicPricingInputs: DynamicPricingInputType;
  workflowOption: string;
};

// for mutation: filter vehiclesIds, remove vehiclesAvailable, format start/endtime for graphQLTime type
export const finalizeDynamicPricingInput = (
  props: FinalizeDynamicPricingInputProps
) => {
  const { dynamicPricingInputs, workflowOption } = props;
  const validatedInput = dynamicPricingInputs[workflowOption];

  // reshape data (vehicle Ids and weekday values) for mutation
  const vehicleIds = getSelectedVehicleIds(validatedInput.vehicles);
  const daysOfWeek = validatedInput.daysOfWeek
    ?.filter((weekday) => weekday.isSelected)
    .map((weekday) => weekday.value);

  const finalData = { ...validatedInput, daysOfWeek };

  // 'selected all vehicles' will be applied to future new created vehicles
  if (size(vehicleIds) !== validatedInput.vehiclesAvailable) {
    // if not all available vehicles are selected, send vehicleIds
    finalData["vehicleIds"] = vehicleIds;
  } else {
    // if all available vehicles are selected, don't send vehicleIds to server
    delete finalData["vehicleIds"];
  }

  // remove extra data for mutation
  delete finalData["vehiclesAvailable"];
  delete finalData["vehicles"];

  // remove timezones
  if (finalData.startDate && !finalData.endDate) {
    finalData.startDate = moment(finalData.startDate).utc(true);
  }

  if (finalData.startDate && finalData.endDate) {
    finalData.startDate = moment(finalData.startDate).utc(true);
    finalData.endDate = moment(finalData.endDate).utc(true);
  }

  if (finalData.startTime && finalData.endTime) {
    finalData.startTime = moment(finalData.startTime).utc(true);
    finalData.endTime = moment(finalData.endTime).utc(true);
  }

  return { finalData };
};
