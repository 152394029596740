import React from "react";
import { black } from "../../colors";

type ExternalOperatorIconProps = {
  size?: "extra-large" | "small";
  color?: string;
};

const ExternalOperatorIcon = (props: ExternalOperatorIconProps) => {
  const { size, color } = props;

  if (size === "extra-large") {
    return (
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#external-operator-icon-clip-path)">
          <path
            d="M9.54021 10.6668C8.96971 10.6661 8.4126 10.8322 7.94353 11.1429C7.47445 11.4535 7.11582 11.8939 6.91568 12.4049L4.80012 16.9168H10.6668M4.48901 16.9168C3.82892 16.9168 3.19586 17.1677 2.7291 17.6142C2.26234 18.0607 2.00012 18.6663 2.00012 19.2978V25.1906C2.00012 25.5064 2.13123 25.8092 2.36461 26.0324C2.59799 26.2557 2.91452 26.3811 3.24457 26.3811H28.7557C29.0857 26.3811 29.4023 26.2557 29.6356 26.0324C29.869 25.8092 30.0001 25.5064 30.0001 25.1906V19.2978C30.0001 18.92 29.9063 18.5515 29.7309 18.2208M5.50012 21.3811H9.70012M17.3335 15.3335L24.6668 8.00016M24.6668 8.00016H16.6668M24.6668 8.00016V16.0002M3.55568 26.3811H9.7779V27.8097C9.7779 29.3876 8.38501 30.6668 6.66679 30.6668C4.94857 30.6668 3.55568 29.3876 3.55568 27.8097V26.3811ZM22.2223 26.3811H28.4446V27.8097C28.4446 29.3876 27.0517 30.6668 25.3335 30.6668C23.6152 30.6668 22.2223 29.3876 22.2223 27.8097V26.3811ZM31.3335 12.0002C31.3335 17.8912 26.5578 22.6668 20.6668 22.6668C14.7758 22.6668 10.0001 17.8912 10.0001 12.0002C10.0001 6.10912 14.7758 1.3335 20.6668 1.3335C26.5578 1.3335 31.3335 6.10912 31.3335 12.0002Z"
            stroke={color || black}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="external-operator-icon-clip-path">
            <rect
              width="32"
              height="32"
              fill="white"
              transform="translate(0.666748)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M4.4367 5.33335C4.15145 5.333 3.8729 5.41604 3.63836 5.57136C3.40382 5.72669 3.22451 5.94686 3.12443 6.2024L2.06666 8.45835H4.99999M1.9111 8.45835C1.58105 8.45835 1.26452 8.58378 1.03115 8.80704C0.797767 9.03029 0.666656 9.33309 0.666656 9.64883V12.5953C0.666656 12.7531 0.732212 12.9045 0.848901 13.0162C0.96559 13.1278 1.12386 13.1905 1.28888 13.1905H14.0444C14.2095 13.1905 14.3677 13.1278 14.4844 13.0162C14.6011 12.9045 14.6667 12.7531 14.6667 12.5953V9.64883C14.6667 9.45994 14.6197 9.27569 14.5321 9.11032M2.41666 10.6905H4.51666M8.33332 7.66669L12 4.00002M12 4.00002H7.99999M12 4.00002V8.00002M1.44443 13.1905H4.55555V13.9048C4.55555 14.6938 3.8591 15.3334 2.99999 15.3334C2.14088 15.3334 1.44443 14.6938 1.44443 13.9048V13.1905ZM10.7778 13.1905H13.8889V13.9048C13.8889 14.6938 13.1924 15.3334 12.3333 15.3334C11.4742 15.3334 10.7778 14.6938 10.7778 13.9048V13.1905ZM15.3333 6.00002C15.3333 8.94554 12.9455 11.3334 9.99999 11.3334C7.05447 11.3334 4.66666 8.94554 4.66666 6.00002C4.66666 3.0545 7.05447 0.666687 9.99999 0.666687C12.9455 0.666687 15.3333 3.0545 15.3333 6.00002Z"
            stroke={color || black}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m6.65507 8c-.42788-.00053-.84571.12403-1.19752.35702-.3518.23298-.62077.56325-.77088.94655l-1.58667 3.38393h4.4m-4.63333 0c-.49507 0-.96987.1881-1.31994.523-.35006.3349-.54673.7891-.54673 1.2627v4.4197c0 .2368.09833.4639.27337.6313.17503.1674.41243.2615.65996.2615h19.13337c.2475 0 .4849-.0941.6599-.2615.1751-.1674.2734-.3945.2734-.6313v-4.4197c0-.2833-.0704-.5597-.2019-.8077m-18.1731 2.3702h3.15m5.725-4.5357 5.5-5.5m0 0h-6m6 0v6m-15.83333 7.7857h4.66666v1.0714c0 1.1835-1.04467 2.1429-2.33333 2.1429s-2.33333-.9594-2.33333-2.1429zm14.00003 0h4.6666v1.0714c0 1.1835-1.0446 2.1429-2.3333 2.1429s-2.3333-.9594-2.3333-2.1429zm6.8333-10.7857c0 4.4183-3.5817 8-8 8s-8-3.5817-8-8c0-4.41828 3.5817-8 8-8s8 3.58172 8 8z"
        stroke={color || black}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default ExternalOperatorIcon;
