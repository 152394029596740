import React from "react";
import moment from "moment-timezone";

import { Badge, Box, Typography } from "@mui/material";

import {
  black,
  grayMedium,
  grayDark,
  white,
  alabaster,
} from "../../../design-system/colors";
import { UserIcon, WheelIcon, SentIcon } from "../../../design-system/icons";
import { getAffiliateId, getMediaVariant } from "../utils";
import { useCurrentUser } from "../../../globals/hooks";
import { ConversationType } from "../../../globals/hooks/useChat/Provider";

const styles = {
  conversationListItem: {
    backgroundColor: white,
    "&:hover": {
      backgroundColor: alabaster,
    },
  },
  "conversationListItem--selected": {
    backgroundColor: "#F5F8FF",
  },
};

type ConversationsListItemProps = {
  conversation: ConversationType;
  onConversationClick: () => void;
};

function ConversationsListItem(props: ConversationsListItemProps) {
  const { conversation, onConversationClick } = props;

  const { messages, unreadMessagesCount, participant } = conversation;

  // queries
  const { operatorId } = useCurrentUser() || {};
  const affiliateId = getAffiliateId(operatorId);

  const { body, media, dateCreated, author } = !!messages.length
    ? messages[messages.length - 1]
    : { body: "", dateCreated: null, author: "", media: null };

  const messagePreview = body
    ? body
    : media
    ? `Attachment: 1 ${getMediaVariant(media?.contentType)}`
    : "";

  const isSentByOperator = author === affiliateId;
  const hasUnreadMessages = !!unreadMessagesCount;

  const lastMessageDate = dateCreated ? moment(dateCreated).calendar() : "";

  return (
    <Box
      onClick={onConversationClick}
      height={105}
      py={1.5}
      px={2}
      borderBottom={`1px solid ${grayMedium}`}
      style={{ cursor: "pointer" }}
      sx={
        hasUnreadMessages
          ? styles["conversationListItem--selected"]
          : styles.conversationListItem
      }
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box display="flex" flexDirection="column" mb={1.5}>
          <Box display="flex" flexDirection="row" alignItems="center" mb={0.5}>
            {participant.role === "driver" && (
              <Box
                mr={0.5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <WheelIcon size="small" color={black} />
              </Box>
            )}
            {participant.role === "contact" && (
              <Box
                mr={0.5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <UserIcon size="small" color={black} />
              </Box>
            )}
            <Typography
              variant="subtitle1"
              style={{ fontWeight: 700, whiteSpace: "nowrap" }}
            >
              {participant.name || participant.mobilePhone || ""}
            </Typography>
            <Badge badgeContent={unreadMessagesCount} max={999} color="error">
              <Box height={7} width={14}></Box>
            </Badge>
          </Box>
          <Typography variant="body2">{participant.mobilePhone}</Typography>
        </Box>

        <Typography
          style={{
            fontWeight: 400,
            fontSize: 10,
            color: grayDark,
            textAlign: "end",
          }}
        >
          {lastMessageDate || ""}
        </Typography>
      </Box>

      <Box display="flex" flexDirection="row">
        {isSentByOperator && (
          <Box width="16px" mr={1}>
            <SentIcon size="small" />
          </Box>
        )}
        <Typography
          variant="body2"
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: grayDark,
          }}
        >
          {messagePreview}
        </Typography>
      </Box>
    </Box>
  );
}

export default ConversationsListItem;
