import React from "react";
import Button from "@mui/material/Button";

import GoogleIcon from "../../design-system/images/icons/GoogleIcon.svg";
import { Box } from "@mui/system";
import {
  alabaster,
  black,
  grayMedium,
  moovsBlue,
  white,
} from "design-system/colors";
import { Typography } from "@mui/material";

type GoogleAuthButtonProps = {
  onClick: () => void;
  isSignup?: boolean;
};

const GoogleAuthButton = (props: GoogleAuthButtonProps) => {
  const { onClick, isSignup } = props;

  const handleClick = () => {
    onClick();
  };

  return (
    <Button
      onClick={handleClick}
      variant="outlined"
      sx={{
        padding: "12px 18px",
        cursor: "pointer",
        borderColor: grayMedium,
        borderWidth: "1px !important",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
        border: `2px solid ${isSignup ? moovsBlue : grayMedium}`,
        backgroundColor: white,
        color: black,
        "&:hover": {
          borderColor: isSignup ? grayMedium : black,
          backgroundColor: alabaster,
        },
        ...(isSignup && { width: "100%" }),
      }}
    >
      <Box
        component="img"
        sx={{
          marginRight: 1.5,
          height: 25,
        }}
        src={GoogleIcon}
        alt="Google"
      />
      <Typography {...(isSignup && { color: moovsBlue })} fontWeight={500}>
        {isSignup ? "Sign Up with Google" : "Log In with Google"}
      </Typography>
    </Button>
  );
};

export default GoogleAuthButton;
