import React from "react";

import { Box, Typography } from "@mui/material";

import { grayDark } from "design-system/colors";
import { ScheduledIcon, UserIcon, WheelIcon } from "design-system/icons";
import ConfirmDateTime from "./ConfirmDateTime";
import DateTimeWithIcon from "./DateTimeWithIcon";
import { FarmRelationshipEnum, OperatorRoute } from "types";
import { DateTimeInfoForCloseTripDialog, DateTimeVariant } from "./utils";
import { useScreenSize } from "globals/hooks";
import { Moment } from "moment-timezone";

type CloseTripDialogBlockProps = {
  scheduledStartDateTime: string;
  scheduledEndDateTime: string;
  startDateTime: string;
  endDateTime: string;
  operatorRoute: OperatorRoute;
  onDateTimeChange: (
    dateTimeVariant: DateTimeVariant
  ) => (dateTime: string | Moment) => void;
  dateTimeInfo: DateTimeInfoForCloseTripDialog;
};

function CloseTripDialogBlock(props: CloseTripDialogBlockProps) {
  const {
    scheduledStartDateTime,
    scheduledEndDateTime,
    startDateTime,
    endDateTime,
    operatorRoute: {
      farmeeCloseoutStartDateTime,
      farmeeCloseoutEndDateTime,
      farmRelationship,
    },
    onDateTimeChange,
    dateTimeInfo: {
      dropOffIsEstimate,
      driverCloseoutStartDateTime,
      driverCloseoutEndDateTime,
    },
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <>
      {/* PICK UP  */}
      <Box
        {...(!isMobileView && {
          display: "flex",
          alignItems: "center",
          mt: 3,
        })}
      >
        <Box
          {...(isMobileView && {
            mt: 4,
            mb: 2,
          })}
        >
          <Typography variant="h4">Pick-Up Date &amp; Time</Typography>
        </Box>
        <Box {...(!isMobileView && { ml: 1.5 })}>
          <DateTimeWithIcon
            IconComponent={<ScheduledIcon />}
            iconName="Scheduled Pick-Up Time"
            dateTime={scheduledStartDateTime}
          />
        </Box>
        {driverCloseoutStartDateTime && (
          <Box {...(!isMobileView && { ml: 1.5 })}>
            <DateTimeWithIcon
              IconComponent={<WheelIcon color={grayDark} size="small" />}
              iconName="Driver"
              dateTime={driverCloseoutStartDateTime}
            />
          </Box>
        )}
        {farmRelationship === FarmRelationshipEnum.Farmor &&
          farmeeCloseoutStartDateTime && (
            <Box {...(!isMobileView && { ml: 1.5 })}>
              <DateTimeWithIcon
                IconComponent={<UserIcon color={grayDark} size="small" />}
                iconName="Affiliate"
                dateTime={farmeeCloseoutStartDateTime}
              />
            </Box>
          )}
      </Box>
      {/* CONFIRM PICK UP */}
      <Box
        {...(!isMobileView && {
          display: "flex",
          justifyContent: "space-between",
          px: 1,
        })}
      >
        <ConfirmDateTime
          dateTime={startDateTime}
          onDateTimeChange={onDateTimeChange(DateTimeVariant.Start)}
        />
      </Box>

      {/* DROP OFF*/}
      <Box
        {...(!isMobileView && {
          display: "flex",
          alignItems: "center",
          mt: 2,
        })}
      >
        <Box
          {...(isMobileView && {
            mt: 4,
            mb: 2,
          })}
        >
          <Typography variant="h4">Drop-Off Date &amp; Time</Typography>
        </Box>
        <Box {...(!isMobileView && { ml: 1.5 })}>
          <DateTimeWithIcon
            IconComponent={<ScheduledIcon />}
            iconName="Scheduled Drop-Off Time"
            dateTime={scheduledEndDateTime}
          />
        </Box>
        {driverCloseoutEndDateTime && (
          <Box {...(!isMobileView && { ml: 1.5 })}>
            <DateTimeWithIcon
              IconComponent={<WheelIcon color={grayDark} size="small" />}
              iconName="Driver"
              dateTime={driverCloseoutEndDateTime}
            />
          </Box>
        )}
        {farmRelationship === FarmRelationshipEnum.Farmor &&
          farmeeCloseoutEndDateTime && (
            <Box {...(!isMobileView && { ml: 1.5 })}>
              <DateTimeWithIcon
                IconComponent={<UserIcon color={grayDark} size="small" />}
                iconName="Affiliate"
                dateTime={farmeeCloseoutEndDateTime}
              />
            </Box>
          )}
      </Box>
      {/* CONFIRM DROP OFF*/}
      <Box
        {...(!isMobileView && {
          display: "flex",
          justifyContent: "space-between",
          px: 1,
        })}
      >
        <ConfirmDateTime
          dateTime={endDateTime}
          dropOffIsEstimate={dropOffIsEstimate}
          onDateTimeChange={onDateTimeChange(DateTimeVariant.End)}
        />
      </Box>
    </>
  );
}

export default CloseTripDialogBlock;
