import { useMutation } from "@apollo/client";
import map from "lodash/map";
import * as Sentry from "@sentry/react";

import { SEND_EMAIL_TO_MULTIPLE_CUSTOMERS_MUTATION } from "globals/graphql";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { EmailModeEnum } from "types";
import { EmailRecipient } from "../SendEmailDialog";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type UseSendEmailToMultipleCustomersMutationProps = {
  mode: EmailModeEnum;
  onClose: () => void;
  toEmails: EmailRecipient[];
  ccEmails: EmailRecipient[];
  pdfAttached: boolean;
  requestId?: string;
  invoiceId?: string;
  message?: string;
  subject?: string;
};

function useSendEmailToMultipleCustomersMutation(
  props: UseSendEmailToMultipleCustomersMutationProps
) {
  const {
    mode,
    onClose,
    toEmails,
    ccEmails,
    pdfAttached,
    requestId,
    invoiceId,
    message,
    subject,
  } = props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // mutation
  const [sendEmailToMultipleCustomers, { loading }] = useMutation(
    SEND_EMAIL_TO_MULTIPLE_CUSTOMERS_MUTATION,
    {
      onCompleted(data) {
        const result = data.sendEmailToMultipleCustomersV2.result;

        if (result.__typename === "SendEmailToMultipleCustomersFailure") {
          snackbar.error(result.message);

          // need to log for sentry
          const invalidClientInputMessage =
            "Operator can not send email due to improper combination of email mode and id.";

          if (result.message === invalidClientInputMessage) {
            Sentry.captureMessage(invalidClientInputMessage, {
              level: Sentry.Severity.Error,
              tags: {
                action: "SEND_EMAIL_TO_MULTIPLE_CUSTOMERS_MUTATION",
                requestId: atob(requestId) || requestId,
                invoiceId: atob(invoiceId) || invoiceId,
                emailMode: mode,
              },
            });
          }

          return;
        }

        if (mode === EmailModeEnum.Invoice) {
          track("invoice_emailSent");
        } else if (mode === EmailModeEnum.Custom) {
          track("composeEmail_emailSent");
        } else {
          track("reservations_emailSent", {
            mode,
            pdf: pdfAttached ? "included" : "removed",
          });
        }

        onClose();
        snackbar.success("Email sent.");
      },
      onError(error) {
        const errorMessage = getErrorMessage(error) || "Error sending email";
        snackbar.error(errorMessage);
      },
    }
  );

  // event handlers
  const handleSendEmailToMultipleCustomers = () => {
    sendEmailToMultipleCustomers({
      variables: {
        input: {
          mode,
          message,
          toEmails: map(toEmails, "email"),
          ccEmails: map(ccEmails, "email"),
          pdfAttached,
          ...(mode === EmailModeEnum.Custom && { subject }),
          ...(requestId && { requestId }),
          ...(invoiceId && { invoiceId }),
        },
      },
    });
  };

  return {
    sendEmailLoading: loading,
    onSendEmailToMultipleCustomers: handleSendEmailToMultipleCustomers,
  };
}

export default useSendEmailToMultipleCustomersMutation;
