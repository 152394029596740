type OnboardingImage = {
  name: string;
  role: string;
  company: string;
  description: string;
  image: string;
};

export const items: OnboardingImage[] = [
  {
    name: "Kevin",
    role: "Dispatcher",
    company: "Convey Limo",
    image: `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/onboarding_photos/onboarding_kevin.jpg`,
    description: `"Moovs has revolutionized our dispatching. Advanced features like driver tracking simplify daily workflows, boosting productivity and relieving us from unnecessary headaches."`,
  },
  {
    name: "Alex",
    role: "Sales",
    company: "Supreme SUV",
    image: `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/onboarding_photos/onboarding_alex.jpg`,
    description: `“Two years ago, we were making $200K a year and last year we made close to $1M. Moovs has kept us so organized that it allowed us to get more business and grow."`,
  },
  {
    name: "Lisa",
    role: "Accountant",
    company: "USA Excursions",
    image: `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/onboarding_photos/onboarding_lisa.jpg`,
    description: `“Managing finances has never been easier. I used to hate accounting. Moovs simplified everything, eliminating manual errors through its invoicing tools, driver payouts and accurate financial reporting."`,
  },
  {
    name: "Jesse",
    role: "Driver",
    company: "ABC Limo",
    image: `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/onboarding_photos/onboarding_jesse.jpg`,
    description: `“Moovs Driver app improves every single trip for me. It's very easy to use and has real-time updates, and notifications that help me provide a smooth and enjoyable experience for passengers."`,
  },
  {
    name: "Megan",
    role: "Client",
    company: "Travel Agent",
    image: `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/onboarding_photos/onboarding_megan.jpg`,
    description: `“I love booking with this software. I can easily make trip changes when needed and get notified every step of the way. I switched from my old black car service, because they didn't have anything like this. This is the best customer experience ever!"`,
  },
  {
    name: "Steven",
    role: "President",
    company: "Talore Transportation",
    image: `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/onboarding_photos/onboarding_steven.jpg`,
    description: `“Moovs saves me time and money every single day. I wish I knew about it earlier and could tell everyone about it!“`,
  },
  {
    name: "Korey",
    role: "Affiliate",
    company: "Alliance Transportation",
    image: `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/onboarding_photos/onboarding_korey.jpg`,
    description: `“I've worked with affiliates on various platforms, but Moovs is by far my top choice. The seamless integration and communication make it so easy.“`,
  },
];
