import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import isUndefined from "lodash/isUndefined";

import { white } from "design-system/colors";
import { NumberFormatPercent } from "design-system/components/inputs/NumberFormat";

type DriverGratuityPresetInputProps = {
  onBlur: (newValue: number) => void;
  value: number;
  error: boolean;
};

const DriverGratuityPresetInput = (props: DriverGratuityPresetInputProps) => {
  const { onBlur, error } = props;

  // state
  const [value, setValue] = useState<number>(props.value);

  // event handlers
  const handleBlur = () => onBlur(value);
  const handleChange = (e) => {
    setValue(e.target.value ? Number(e.target.value) : undefined);
  };

  // useEffect
  // update value if props change
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <Box flex={1} maxWidth="100px" mr={1} bgcolor={white}>
      <TextField
        variant="outlined"
        fullWidth
        onBlur={handleBlur}
        onChange={handleChange}
        value={value}
        error={error}
        InputProps={{
          inputComponent: NumberFormatPercent as any,
          inputProps: {
            decimalScale: 0,
            suffix: "%",
            isAllowed: ({ floatValue }) => {
              if (isUndefined(floatValue)) return true;
              return floatValue < 100 && floatValue > 0;
            },
          },
        }}
      />
    </Box>
  );
};

export { DriverGratuityPresetInput };
