/**
 * @file orderType.graphql.ts
 * queries/mutations for OrderType.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_ORDER_TYPES_QUERY = gql`
  query OrderTypes {
    orderTypes {
      id
      name
      slug
      category
    }
  }
`;

export const CREATE_OPERATOR_ORDER_TYPE = gql`
  mutation CreateOperatorOrderType($input: CreateOperatorOrderTypeInput!) {
    createOperatorOrderType(input: $input) {
      operator {
        enabledOrderTypes {
          id
          name
          category
        }
      }
    }
  }
`;

export const REMOVE_OPERATOR_ORDER_TYPE = gql`
  mutation RemoveOperatorOrderType($input: RemoveOperatorOrderTypeInput!) {
    removeOperatorOrderType(input: $input) {
      operator {
        enabledOrderTypes {
          id
          name
          category
        }
      }
    }
  }
`;
