/**
 * @file useLayout.ts
 * Hook that stores common layout dimensions
 */
import { useScreenSize } from "./useScreenSize";

// constants
export const TOP_BAR_HEIGHT_MOBILE = 56;
export const TOP_BAR_HEIGHT_DESKTOP = 78;

function useLayout() {
  // hooks
  const { isMobileView } = useScreenSize();

  const topBarHeight = isMobileView
    ? TOP_BAR_HEIGHT_MOBILE
    : TOP_BAR_HEIGHT_DESKTOP;

  return {
    topBarHeight,
  };
}

export { useLayout };
