import React from "react";

import { Box, Divider, Typography } from "@mui/material";

import { grayMedium } from "design-system/colors";
import { getColorForFarmStatus } from "globals/utils/helpers";
import { useScreenSize } from "globals/hooks";
import FarmInDetailsActionSection from "./FarmInDetailsActionSection";
import FarmAffiliateIcon, {
  getFarmAffiliateIconVariant,
} from "components/contacts/farmAffiliates/FarmAffiliateIcon";
import { Route } from "types";
import {
  FarmInDetailsInfoDesktop,
  FarmInDetailsInfoMobile,
} from "./components";

type FarmInDetailsBlockProps = {
  route: Route;
};

function FarmInDetailsBlock(props: FarmInDetailsBlockProps) {
  const { route } = props;
  const { farmStatus, farmAffiliateVariant, farmoutNote, farmAffiliate } =
    route;

  // hooks
  const { isSmallTabletView } = useScreenSize();

  const iconVariant = getFarmAffiliateIconVariant({
    farmAffiliate,
    farmAffiliateVariant,
  });

  return (
    <Box
      bgcolor={getColorForFarmStatus.bgcolor(farmStatus)}
      p={2}
      display="flex"
      flexDirection="column"
      flex="1"
      borderRadius="4px"
      boxShadow="0px 4px 15px rgba(0, 0, 0, 0.03)"
    >
      <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
        <Typography variant="h5">FARM-IN</Typography>

        <Box ml={1}>
          <FarmAffiliateIcon variant={iconVariant} />
        </Box>
      </Box>
      {isSmallTabletView ? (
        <FarmInDetailsInfoMobile route={route} />
      ) : (
        <FarmInDetailsInfoDesktop route={route} />
      )}

      {farmoutNote && (
        <Box mt={1}>
          <Typography variant="overline">note</Typography>
          <Typography variant="body2">{farmoutNote}</Typography>
        </Box>
      )}
      <Box my={2}>
        <Divider style={{ backgroundColor: grayMedium }} />
      </Box>
      <FarmInDetailsActionSection route={route} />
    </Box>
  );
}

export default FarmInDetailsBlock;
