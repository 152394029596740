/**
 * @file graphql/conversation.graphql.ts
 * queries/mutations for twilio conversations
 *
 */

import { gql } from "@apollo/client";

export const CREATE_MESSAGE_MUTATION = gql`
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      __typename
    }
  }
`;
