import React from "react";

import { Box, Typography } from "@mui/material";

import { black } from "../../../design-system/colors";

type AirportInfoProps = {
  airportCode: string;
  airportName: string;
  transitStopType?: "origin" | "destination";
  useBoldTextForName?: boolean;
};

function AirportInfo(props: AirportInfoProps) {
  const {
    airportCode,
    airportName,
    transitStopType,
    useBoldTextForName = "true",
  } = props;
  const isDestination = transitStopType === "destination";

  return (
    <Box
      display="flex"
      alignItems={isDestination ? "flex-end" : "flex-start"}
      flexDirection="column"
    >
      <Typography
        variant="subtitle1"
        style={{ fontWeight: "bold", color: black }}
        {...(isDestination && { align: "right" })}
      >
        {airportCode}
      </Typography>

      <Typography
        variant="subtitle2"
        style={{ color: black }}
        fontWeight={useBoldTextForName ? "bold" : "regular"}
        {...(isDestination && { align: "right" })}
      >
        {airportName}
      </Typography>
    </Box>
  );
}

export default AirportInfo;
