import React from "react";

type PaymentIconProps = {
  color?: string;
};

const PaymentIcon = (props: PaymentIconProps) => {
  const { color } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 7V14M12.5 7.7159H8.75C8.28587 7.7159 7.84075 7.86257 7.51256 8.12362C7.18437 8.38468 7 8.73876 7 9.10795C7 9.47714 7.18437 9.83122 7.51256 10.0923C7.84075 10.3533 8.28587 10.5 8.75 10.5H11.25C11.7141 10.5 12.1592 10.6467 12.4874 10.9077C12.8156 11.1688 13 11.5228 13 11.892C13 12.2612 12.8156 12.6153 12.4874 12.8764C12.1592 13.1374 11.7141 13.2841 11.25 13.2841H7M19 4.95455C19 4.56878 18.8541 4.19881 18.5945 3.92603C18.3348 3.65325 17.9826 3.5 17.6154 3.5H8.61538L6.95385 1.58182C6.82487 1.40117 6.65763 1.25455 6.46537 1.15356C6.27311 1.05257 6.06111 1 5.84615 1H2.38462C2.01739 1 1.66521 1.15325 1.40554 1.42603C1.14588 1.69881 1 2.06878 1 2.45455V15.5455C1 15.9312 1.14588 16.3012 1.40554 16.574C1.66521 16.8468 2.01739 17 2.38462 17H17.6154C17.9826 17 18.3348 16.8468 18.5945 16.574C18.8541 16.3012 19 15.9312 19 15.5455V4.95455Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PaymentIcon;
