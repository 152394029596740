import React from "react";

import { Typography, Box, Button } from "@mui/material";

import deactivatedIllustation from "design-system/illustrations/deactivatedAccount.svg";
import { grayDark } from "design-system/colors";

function DeactivatedPage() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      minHeight="90vh"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        component="img"
        src={deactivatedIllustation}
        alt="A plug pulled out of an electrical outlet"
      />
      <Typography variant="h1" textAlign="center">
        Oops, Something's Not Right.
      </Typography>
      <Typography variant="body2" color={grayDark} textAlign="center" mt={2}>
        This account has been deactivated.
        <br />
        Please contact info@moovsapp.com if
        <br />
        you believe this is a mistake.
      </Typography>
      <Box mt={2}>
        <Button variant="contained" color="primary" id="fab-help">
          Chat with Moovs
        </Button>
      </Box>
    </Box>
  );
}

export default DeactivatedPage;
