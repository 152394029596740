import React from "react";

type UserIconProps = {
  color?: string;
  size?: "small";
};

const UserIcon = (props: UserIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 13V11.7778C13 11.1295 12.7366 10.5077 12.2678 10.0493C11.7989 9.59087 11.163 9.33333 10.5 9.33333H5.5C4.83696 9.33333 4.20107 9.59087 3.73223 10.0493C3.26339 10.5077 3 11.1295 3 11.7778V13M10.5 4.44444C10.5 5.79447 9.38071 6.88889 8 6.88889C6.61929 6.88889 5.5 5.79447 5.5 4.44444C5.5 3.09441 6.61929 2 8 2C9.38071 2 10.5 3.09441 10.5 4.44444Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserIcon;
