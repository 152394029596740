import React from "react";

import { Box, Typography } from "@mui/material";

import MoovsTooltip from "components/MoovsTooltip";
import { green, moovsBlue, orange } from "design-system/colors";
import {
  CreditCardIcon,
  LinkIcon,
  StarIcon,
  UserIcon,
} from "design-system/icons";
import { CardItem, PaymentMethod } from "types";
import { CardNoteTooltip } from "../../CardNoteTooltip";
import LinkedCornerLeftIcon from "design-system/icons/info/LinkedCornerLeftIcon";
import { getContactName } from "globals/utils/contact";
import { useLaunchDarklyFlags } from "globals/utils/useLaunchDarklyFlags";

type CreditCardMenuItemContentProps = {
  paymentMethod: PaymentMethod;
  card: CardItem;
  isPreferredCard: boolean;
};

export function CreditCardMenuItemContent(
  props: CreditCardMenuItemContentProps
) {
  const { paymentMethod, card, isPreferredCard } = props;
  const { enableLinkedPassenger } = useLaunchDarklyFlags();

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Typography
          style={{
            display: "flex",
            textTransform: "capitalize",
            alignItems: "center",
            justifyItems: "center",
            columnGap: 0.5,
          }}
        >
          <CreditCardIcon brand={card.brand} />
          {paymentMethod?.billingDetails?.name} *** {card.last4}{" "}
          {`- ${card.expMonth}/${card.expYear.toString().slice(-2)}`}
        </Typography>
        {isPreferredCard && (
          <Box ml={1} mt="1px" style={{ cursor: "pointer" }}>
            <MoovsTooltip tooltipText="Selected card for this reservation">
              <UserIcon size="small" color={green} />
            </MoovsTooltip>
          </Box>
        )}

        {paymentMethod.isPrimary && !isPreferredCard && (
          <Box ml={1} mt="1px" style={{ cursor: "pointer" }}>
            <MoovsTooltip tooltipText="Primary Card">
              <StarIcon size="small" color={orange} />
            </MoovsTooltip>
          </Box>
        )}
      </Box>
      {enableLinkedPassenger && paymentMethod.linkedPassenger && (
        <Box
          display="flex"
          alignContent="center"
          alignItems="center"
          columnGap={0.5}
        >
          <LinkedCornerLeftIcon />
          <LinkIcon size="small" color={moovsBlue} />
          <Typography variant="body2" color={moovsBlue} fontWeight={500}>
            {getContactName(paymentMethod.linkedPassenger)}
          </Typography>
        </Box>
      )}
      {paymentMethod.cardNote && (
        <CardNoteTooltip cardNote={paymentMethod.cardNote} />
      )}
    </Box>
  );
}
