/**
 * @file CopyButton.tsx
 * Component that shows CopyIcon with tooltip and features copy functionality.
 *
 */
import React from "react";

import { IconButton, Tooltip } from "@mui/material";
import { CopyIcon } from "../icons";
import clipboardCopy from "clipboard-copy";
import { useSnackbar } from "../../globals/hooks";

type CopyButtonProps = { copyText: string; titleToolTip?: string };

function CopyButton(props: CopyButtonProps) {
  const { copyText, titleToolTip } = props;

  const snackbar = useSnackbar();

  const handleCopyLink = async () => {
    try {
      await clipboardCopy(copyText);
      snackbar.success(`Successfully copied ${copyText}!`);
    } catch (error) {}
  };

  return (
    <Tooltip enterDelay={200} title={titleToolTip || "Copy"} placement="top">
      <IconButton onClick={handleCopyLink} size="large">
        <CopyIcon size="small" />
      </IconButton>
    </Tooltip>
  );
}

export default CopyButton;
