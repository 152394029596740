import React from "react";
import { Helmet } from "react-helmet";

import { Box, Divider, Typography } from "@mui/material";

import { useOperator, useScreenSize } from "globals/hooks";
import { TOP_BAR_HEIGHT_MOBILE } from "globals/hooks/useLayout";
import { CurrentPlanInfoCard, PaymentMethodManagementCard } from "./components";
import { alabaster } from "design-system/colors";

function BillingPage() {
  // hooks
  const { isSmallTabletView } = useScreenSize();
  const operator = useOperator();

  // derived state
  const { lagoPlanCode } = operator || {};

  return (
    <Box
      px={2}
      width="100%"
      display="flex"
      flexDirection="column"
      height={
        isSmallTabletView ? `calc(100vh - ${TOP_BAR_HEIGHT_MOBILE}px)` : "100vh"
      }
      sx={{ backgroundColor: alabaster }}
    >
      <Helmet>
        <title>Billing | Moovs</title>
      </Helmet>

      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        pt={2}
        px={!isSmallTabletView && "100px"}
      >
        {/* Header Section */}
        <Box
          display="flex"
          flexWrap="wrap"
          flexDirection={isSmallTabletView ? "column" : "row"}
          justifyContent="space-between"
          alignItems={isSmallTabletView ? "flex-start" : "center"}
          mb={2}
        >
          <Typography variant="h2">Billing</Typography>
        </Box>

        <Divider
          sx={{
            width: "initial",
            mx: !isSmallTabletView ? "-100px" : -2,
            mt: 1,
            mb: 3,
          }}
        />

        <CurrentPlanInfoCard />

        {lagoPlanCode && <PaymentMethodManagementCard />}
      </Box>
    </Box>
  );
}

export default BillingPage;
