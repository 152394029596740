import React from "react";
import moment from "moment-timezone";
import { useQuery } from "@apollo/client";

import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { AppointmentTooltip } from "@devexpress/dx-react-scheduler-material-ui";

import {
  EstimationIcon,
  WarningIcon,
  CalendarIcon,
  ClockIcon,
  WheelIcon,
  CancelTripIcon,
  CalendarUnavailableIcon,
} from "../../../../design-system/icons";
import { black, errorRed } from "../../../../design-system/colors";
import { driverStatusEnum } from "../../../../globals/utils/enumMaps";
import { dateFormatter } from "../../../../globals/utils/helpers";
import FlightChangeDisplay from "pages/requests/RequestOverview/ReservationOverview/TripItineraryBlock/components/FlightChangeDisplay";
import { LOAD_OPERATOR_ROUTE_DRIVER_INFO_QUERY } from "globals/graphql";
import { OperatorRoute } from "types";

const SAVED = "saved";

const styles = {
  estimationIcon: {
    minWidth: "0px",
  },
};

const CustomAppointmentTooltipContent = (
  contentProps: AppointmentTooltip.ContentProps
) => {
  const {
    startDate,
    endDate,
    endTimeType,
    pickUpFlightOffset,
    pickUpOriginalDateTime,
    dateTime,
    routeId,
  } = contentProps.appointmentData;

  // queries
  const { data: operatorRouteData, loading: operatorRouteLoading } = useQuery(
    LOAD_OPERATOR_ROUTE_DRIVER_INFO_QUERY,
    {
      fetchPolicy: "network-only",
      variables: {
        id: routeId,
      },
      skip: !routeId,
    }
  );

  const operatorRoute: OperatorRoute = operatorRouteData?.node;
  const { routeDriver, driverStatus } = operatorRoute || {};

  const driverFirstName = routeDriver?.driver.firstName || "";
  const driverLastName = routeDriver?.driver.lastName || "";
  const driverName = `${driverFirstName} ${driverLastName}`;

  const formattedStartDate = dateFormatter(moment(startDate), "abbreviated");
  const formattedEndDate = moment(endDate).isSame(moment(startDate), "day")
    ? null
    : dateFormatter(moment(endDate), "abbreviated");

  const formattedStartTime = moment(startDate).format("h:mma");
  const formattedEndTime = moment(endDate).isSame(moment(startDate))
    ? null
    : moment(endDate).format("h:mma");

  const endTimeTypeMap = (type: "estimated" | "none" | "saved") =>
    ({
      estimated: {
        toolTipText: "End time is based on estimated duration",
        toolTipBoxProps: { pt: "6px" },
        toolTipIcon: <EstimationIcon size="small" />,
        timeDisplay: (startTime, endTime) =>
          `${startTime}  ${endTime ? `- ${endTime}` : ""}`,
      },
      none: {
        toolTipText: "The trip end time is unknown",
        toolTipBoxProps: { pt: "3px" },
        toolTipIcon: <WarningIcon color={black} />,
        timeDisplay: (startTime) => startTime,
      },
      saved: {
        timeDisplay: (startTime, endTime) =>
          `${startTime}  ${endTime ? `- ${endTime}` : ""}`,
      },
    }[type]);

  const { toolTipText, toolTipBoxProps, toolTipIcon, timeDisplay } =
    endTimeTypeMap(endTimeType);

  const appointmentInfo = [
    {
      icon: <CalendarIcon color={"#B3B3B3"} />,
      primaryValue: `${formattedStartDate} ${
        formattedEndDate ? `- ${formattedEndDate}` : ""
      }`,
      primaryTypographyProps: {},
    },
    {
      icon: <ClockIcon />,
      primaryValue: timeDisplay(formattedStartTime, formattedEndTime),
      endTimeType: endTimeType,
      primaryTypographyProps: {},
    },
    {
      icon: <WheelIcon />,
      primaryValue: driverName,
      secondaryValue: routeDriver?.driver.mobilePhone || "-",
      primaryTypographyProps: { style: { fontWeight: 500 } },
      driverStatus: driverStatus,
      driverRouteConflict:
        routeDriver && !routeDriver.routeAvailability?.available,
      driverPersonalConflict:
        routeDriver &&
        routeDriver.routeAvailability?.available &&
        !routeDriver.personalAvailability?.available,
    },
  ];

  return (
    <Box display="flex" flexDirection="column" flex="1" mx={2}>
      {operatorRouteLoading ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mb={4}
        >
          <CircularProgress size={40} thickness={2} />
        </Box>
      ) : (
        appointmentInfo.map((infoItem, i) => {
          return (
            <List key={i}>
              <ListItem>
                <ListItemIcon>{infoItem.icon}</ListItemIcon>
                <Box display="flex">
                  {infoItem.driverStatus && (
                    <Box mt={2} mr={1}>
                      {driverStatusEnum[driverStatus?.name]?.icon}
                    </Box>
                  )}
                  <ListItemText
                    primary={infoItem.primaryValue}
                    primaryTypographyProps={infoItem.primaryTypographyProps}
                    secondary={infoItem.secondaryValue}
                  />
                  <Box>
                    {infoItem.driverRouteConflict && (
                      <Tooltip placement="top" title="Trip Conflict">
                        <IconButton sx={{ mt: 0.5 }}>
                          <CancelTripIcon color={errorRed} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {infoItem.driverPersonalConflict && (
                      <Tooltip placement="top" title="Availability Conflict">
                        <IconButton sx={{ mt: 0.5 }}>
                          <CalendarUnavailableIcon color={errorRed} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                  {infoItem.endTimeType && infoItem.endTimeType !== SAVED && (
                    <Box ml={1} {...toolTipBoxProps}>
                      <Tooltip
                        arrow
                        title={toolTipText}
                        enterDelay={200}
                        enterNextDelay={200}
                      >
                        <ListItemIcon sx={styles.estimationIcon}>
                          {toolTipIcon}
                        </ListItemIcon>
                      </Tooltip>
                    </Box>
                  )}
                  {infoItem.endTimeType && (
                    <Box alignItems="center" display="flex" ml={1} mb={-0.5}>
                      <FlightChangeDisplay
                        flightOffset={pickUpFlightOffset}
                        originalDateTime={pickUpOriginalDateTime}
                        dateTime={dateTime}
                        iconSize="small"
                      />
                    </Box>
                  )}
                </Box>
              </ListItem>
            </List>
          );
        })
      )}
    </Box>
  );
};

export default CustomAppointmentTooltipContent;
