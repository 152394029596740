import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { TextField, Typography, Grid, Box } from "@mui/material";

import { useSnackbar, useStripeBillingDetailsElement } from "globals/hooks";
import MoovsDialog from "components/MoovsDialog";
import StripeTextField from "../../../common/CreditCardElements/StripeTextField";
import {
  ContactPayerProps,
  AffiliatePayerProps,
  CompanyPayerProps,
} from "../types";
import { useCreatePaymentMethodMutation } from "./hooks/useCreatePaymentMethodMutation";

export type CreateCreditCardDialogProps = {
  open: boolean;
  onClose: () => void;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
  payer: ContactPayerProps | AffiliatePayerProps | CompanyPayerProps;
};

function CreateCreditCardDialog(props: CreateCreditCardDialogProps) {
  const { open, onClose, setSaveIndicatorState, payer } = props;

  // hooks
  const snackbar = useSnackbar();
  const elements = useElements();
  const stripe = useStripe();
  const { BillingDetailsElement, getBillingDetails } =
    useStripeBillingDetailsElement();

  // state
  const [cardNote, setCardNote] = useState("");
  const [cardNoteError, setCardNoteError] = useState("");
  const [cardHolderEmail, setCardHolderEmail] = useState("");
  const [hideLoadingIndicator, setHideLoadingIndicator] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  // hoisted for use in mutation hook
  const handleClose = () => {
    elements?.getElement(CardElement)?.clear();
    elements?.getElement("address")?.clear();

    onClose();
    setSubmitDisabled(false);
    setCardNote("");
    setCardHolderEmail("");
  };

  // mutation hook
  const { onCreatePaymentMethod } = useCreatePaymentMethodMutation({
    setSaveIndicatorState,
    setSubmitDisabled,
    handleClose,
    payer,
  });

  // event handlers
  const handleSavePaymentMethod = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet.
      return;
    }

    setSaveIndicatorState("loading");
    setHideLoadingIndicator(false);
    setSubmitDisabled(true);

    const card = elements.getElement(CardElement);
    if (!card) {
      setSaveIndicatorState("error");
      setHideLoadingIndicator(true);
      setSubmitDisabled(false);
      return;
    }

    const billingDetails = await getBillingDetails();
    if (!billingDetails) {
      setSaveIndicatorState("error");
      setHideLoadingIndicator(true);
      setSubmitDisabled(false);
      return;
    }

    // create stripe payment method
    const {
      paymentMethod: stripePaymentMethod,
      error: stripePaymentMethodError,
    } = await stripe.createPaymentMethod({
      type: "card",
      card: card,
      billing_details: {
        ...billingDetails,
        email: cardHolderEmail || null,
      },
    });

    if (stripePaymentMethodError) {
      setSaveIndicatorState("default");
      setHideLoadingIndicator(true);
      setSubmitDisabled(false);
      snackbar.error(stripePaymentMethodError.message);
      return;
    }

    onCreatePaymentMethod({
      stripePaymentMethod,
      cardNote,
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSubmitDisabled(false);

    if (e.target.name === "cardNote") {
      e.target.value.length > 500
        ? setCardNoteError("Max character limit of 500 reached")
        : setCardNoteError("");
      setCardNote(e.target.value);
    }

    if (e.target.name === "cardHolderEmail") {
      setCardHolderEmail(e.target.value);
    }
  };

  return (
    <MoovsDialog
      size="sm"
      open={open}
      onClose={handleClose}
      dialogTitle="Add Card"
      onAccept={handleSavePaymentMethod}
      acceptButtonText="Save"
      acceptDisabled={submitDisabled}
      hideLoadingIndicator={hideLoadingIndicator}
    >
      <Grid container spacing={1} mb={1}>
        <Grid item xs={12}>
          <Typography variant="overline">Card Number</Typography>
          <Box mt={0.5}>
            <StripeTextField stripeElement={CardElement} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <BillingDetailsElement
            defaultValues={{ phone: payer.payerPhone || null }}
            onChange={() => setSubmitDisabled(false)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline">
            Cardholder Email (optional)
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            name="cardHolderEmail"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline">
            Additional Notes (Optional)
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            name="cardNote"
            onChange={handleChange}
            error={!!cardNoteError}
            helperText={cardNoteError}
            inputProps={{
              "data-testid": "card-note-input",
            }}
          />
        </Grid>
      </Grid>
    </MoovsDialog>
  );
}

export default CreateCreditCardDialog;
