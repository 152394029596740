import React from "react";

import { Box, Typography, Divider } from "@mui/material";

import InvoiceItemsListItemDesktop from "components/invoices/CreateInvoiceDrawer/components/InvoiceItemsListItem.desktop";
import InvoiceItemsListItemMobile from "components/invoices/CreateInvoiceDrawer/components/InvoiceItemsListItem.mobile";
import { alabaster } from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import { currency } from "globals/utils/helpers";
import { Invoice, Request } from "types";

type OrderItemsProps = {
  invoice: Invoice;
  handleNavigateToRequestClick: (requestId: string) => void;
};

function OrderItems(props: OrderItemsProps) {
  const { invoice, handleNavigateToRequestClick } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <>
      <Box mb={3} mt={2}>
        <Typography variant="h5">Items</Typography>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      {invoice.requests.map((request: Request) => {
        return isMobileView ? (
          <InvoiceItemsListItemMobile
            key={request.id}
            request={request}
            onNavigateToRequestClick={handleNavigateToRequestClick}
          />
        ) : (
          <InvoiceItemsListItemDesktop
            key={request.id}
            request={request}
            onNavigateToRequestClick={handleNavigateToRequestClick}
          />
        );
      })}
      <Box
        display="flex"
        flexDirection="column"
        bgcolor={alabaster}
        p={2}
        mt={2}
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
            Amount Due
          </Typography>
          <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
            {currency(invoice?.amountDue)}
          </Typography>
        </Box>
        <Box my={1}>
          <Divider />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
            Total amount
          </Typography>
          <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
            {currency(invoice?.totalAmount)}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default OrderItems;
