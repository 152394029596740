import React from "react";
import last from "lodash/last";

import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Trip } from "types";
import { AirportIcon } from "design-system/icons";

function DropOffLocationColumn(props: GridRenderCellParams<string>) {
  const { value, row } = props;

  const { stops } = row as Trip;
  const dropOffStop = last(stops);

  return (
    <Box display="flex" flexDirection="row" alignItems="center" width="100%">
      {dropOffStop.airport && (
        <Box mr={1.5} display="flex" alignItems="center">
          <AirportIcon size="small" />
        </Box>
      )}
      <Typography variant="body2" textOverflow="ellipsis" overflow="hidden">
        {value}
      </Typography>
    </Box>
  );
}

export default DropOffLocationColumn;
