import React, { Dispatch, SetStateAction } from "react";

import { Box, Typography } from "@mui/material";

import PaymentMethodListItem from "./PaymentMethodListItem";
import { PaymentMethod } from "types";
import {
  AffiliatePayerProps,
  CompanyPayerProps,
  ContactPayerProps,
} from "./types";

type LinkedPassengerPaymentMethodsProps = {
  paymentMethods: Partial<PaymentMethod>[];
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
  payer: AffiliatePayerProps | ContactPayerProps | CompanyPayerProps;
};

export default function LinkedPassengerPaymentMethods(
  props: LinkedPassengerPaymentMethodsProps
) {
  const { paymentMethods, payer, setSaveIndicatorState } = props;
  if (paymentMethods.length < 1) return null;
  return (
    <Box>
      <Box mb={3} mt={4}>
        <Typography variant="h3">Passenger Linked Cards</Typography>
      </Box>

      {paymentMethods.map((paymentMethod) => (
        <Box mb={1} key={paymentMethod.id}>
          <PaymentMethodListItem
            paymentMethod={paymentMethod}
            editableProps={{
              setSaveIndicatorState,
              payer,
            }}
            isPassengerLinkedCard
          />
        </Box>
      ))}
    </Box>
  );
}
