import React, { Dispatch, SetStateAction } from "react";

import { Box } from "@mui/material";

import MoovsTabs from "components/globals/MoovsTabs";
import { useCurrentUser } from "globals/hooks";
import { User } from "types";

type MemberDrawerTabsProps = {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  user: User;
};

function MemberDrawerTabs(props: MemberDrawerTabsProps) {
  const { value, setValue, user } = props;

  // hooks
  const currentUser = useCurrentUser();

  const tabs = [{ label: "DETAILS", value: "Details" }];

  // Only show Permissions tab if an owner is viewing a non-owner member
  const showPermissionsTab =
    currentUser?.role.slug === "owner" && user && user.role?.slug !== "owner";

  if (showPermissionsTab) {
    tabs.push({
      label: "PERMISSIONS",
      value: "Permissions",
    });
  }

  return (
    <Box mx={1}>
      <MoovsTabs
        value={value}
        onChange={setValue}
        tabs={tabs}
        hideBottomBorder
      />
    </Box>
  );
}

export default MemberDrawerTabs;
