/**
 * @file TopBar.tsx
 * Toolbar that contains button to expand to show navigation menu on mobile.
 *
 * reference: https://material-ui.com/components/drawers/#responsive-drawer
 *
 * components:
 *  TopBar
 */

import React from "react";
import { useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import { grayMedium, white } from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import theme, { CHAT_PANEL_WIDTH } from "theme";
import {
  TOP_BAR_HEIGHT_MOBILE,
  TOP_BAR_HEIGHT_DESKTOP,
} from "globals/hooks/useLayout";
import {
  TopBarContentDesktop,
  TopBarContentMobile,
  TopBarSettingsContentMobile,
} from "./components";

type TopBarProps = {
  settingsPage: boolean;
  onDrawerToggle: () => void;
  chatPanelOpen?: boolean;
  onChatPanelToggle?: () => void;
  isChatAccessible?: boolean;
};

function TopBar(props: TopBarProps) {
  const {
    onDrawerToggle,
    settingsPage,
    chatPanelOpen,
    onChatPanelToggle,
    isChatAccessible,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const location = useLocation();

  if (
    location.pathname.startsWith("/onboarding") ||
    (settingsPage && !isMobileView)
  ) {
    return null;
  }

  const styles = {
    appBar: {
      zIndex: theme.zIndex.drawer - 1,

      flex: 1,
      top: 0,
      height: TOP_BAR_HEIGHT_MOBILE,
      minHeight: TOP_BAR_HEIGHT_MOBILE,
      width: "100%",
      boxShadow: "none",
      borderBottom: `1px solid ${grayMedium}`,
      background: white,

      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("sm")]: {
        height: TOP_BAR_HEIGHT_DESKTOP,
        minHeight: TOP_BAR_HEIGHT_DESKTOP,
      },
    },
    "appBar--shift": {
      width: `calc(100% - ${CHAT_PANEL_WIDTH}px)`,
      marginRight: CHAT_PANEL_WIDTH,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  };

  return (
    <Box
      height={
        isMobileView
          ? `${TOP_BAR_HEIGHT_MOBILE}px`
          : `${TOP_BAR_HEIGHT_DESKTOP}px`
      }
    >
      <Box
        display="flex"
        position="fixed"
        flexDirection="row"
        alignItems="center"
        sx={[styles.appBar, chatPanelOpen && styles["appBar--shift"]]}
      >
        {!settingsPage && !isMobileView && (
          <TopBarContentDesktop
            chatPanelOpen={chatPanelOpen}
            onChatPanelToggle={onChatPanelToggle}
            isChatAccessible={isChatAccessible}
          />
        )}

        {!settingsPage && isMobileView && (
          <TopBarContentMobile
            onDrawerToggle={onDrawerToggle}
            chatPanelOpen={chatPanelOpen}
            onChatPanelToggle={onChatPanelToggle}
            isChatAccessible={isChatAccessible}
          />
        )}

        {settingsPage && isMobileView && (
          <TopBarSettingsContentMobile onDrawerToggle={onDrawerToggle} />
        )}
      </Box>
    </Box>
  );
}

export default TopBar;
