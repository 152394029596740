import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import { Box, Button, Typography } from "@mui/material";

import { green } from "../../../../../../design-system/colors";
import { FarmedRouteStatusEnum, Route } from "../../../../../../types";
import { CheckIcon } from "../../../../../../design-system/icons";
import { UPDATE_FARMED_ROUTE_STATUS_MUTATION } from "../../../../../../globals/graphql";
import DenyTripDialog from "./DenyTripDialog";
import { useAnalytics, useSnackbar } from "../../../../../../globals/hooks";
import useReservationPageRequest from "pages/reservations/hooks/useReservationPageRequest";

type FarmInDetailsActionSectionProps = {
  route: Partial<Route>;
};

function FarmInDetailsActionSection(props: FarmInDetailsActionSectionProps) {
  const {
    route: { id: routeId, farmStatus },
  } = props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const { refetch: refetchRequest } = useReservationPageRequest();

  // state
  const [denyTripDialogOpen, setDenyTripDialogOpen] = useState(false);

  // mutations
  const [updateFarmedRouteStatus] = useMutation(
    UPDATE_FARMED_ROUTE_STATUS_MUTATION,
    {
      onCompleted() {
        refetchRequest();
        setDenyTripDialogOpen(false);
      },
      onError() {
        snackbar.error("Error updating farmed trip");
      },
    }
  );

  // event handlers
  const handleDenyClick = () => {
    track("farmedRoute_denyDialogOpened");
    setDenyTripDialogOpen(true);
  };

  const handleAcceptClick = () => {
    track("farmedRoute_farmeeAccepted");

    updateFarmedRouteStatus({
      variables: {
        input: {
          routeId: routeId,
          farmedRouteStatus: Accepted,
        },
      },
    });
  };

  const handleDenyTripDialogClose = () => {
    setDenyTripDialogOpen(false);
  };

  const { Accepted, Pending, Completed } = FarmedRouteStatusEnum;

  const content = {
    [Pending]: (
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box mr={2} flex="1" data-testid="deny-button">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDenyClick}
            fullWidth
          >
            Deny
          </Button>
        </Box>
        <Box flex="1" data-testid="accept-button">
          <Button
            variant="contained"
            color="primary"
            onClick={handleAcceptClick}
            fullWidth
          >
            Accept
          </Button>
        </Box>
      </Box>
    ),
    [Accepted]: (
      <Box display="flex" flexDirection="row" alignItems="center">
        <CheckIcon size="small" color={green} />
        <Box ml={1}>
          <Typography variant="subtitle2" data-testid="farm-in-accepted">
            Farm-In Accepted
          </Typography>
        </Box>
      </Box>
    ),
    [Completed]: (
      <Box display="flex" flexDirection="row" alignItems="center">
        <CheckIcon size="small" color={green} />
        <Box ml={1}>
          <Typography variant="subtitle2" data-testid="farm-in-completed">
            Trip is completed
          </Typography>
        </Box>
      </Box>
    ),
  };

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        {content[farmStatus]}
      </Box>
      <DenyTripDialog
        open={denyTripDialogOpen}
        onDenyTripDialogClose={handleDenyTripDialogClose}
        updateFarmedRouteStatus={updateFarmedRouteStatus}
        routeId={routeId}
      />
    </>
  );
}

export default FarmInDetailsActionSection;
