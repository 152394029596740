import React from "react";

type DownloadIconProps = {
  color?: string;
  size?: "small";
  width?: string;
  height?: string;
  viewBox?: string;
};

const DownloadIcon = (props: DownloadIconProps) => {
  const { color, size, width, height, viewBox } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 2V10.9231M8 10.9231L11.7403 7.23077M8 10.9231L4.25974 7.23077M2 12.1538C2 12.6435 2.18061 13.1131 2.5021 13.4593C2.82359 13.8055 3.25963 14 3.71429 14H12.2857C12.7404 14 13.1764 13.8055 13.4979 13.4593C13.8194 13.1131 14 12.6435 14 12.1538"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox={viewBox || "0 0 24 24"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4V16.641M12 16.641L16.9091 11.4103M12 16.641L7.09091 11.4103M3 18.3846C3 19.0783 3.23705 19.7435 3.65901 20.234C4.08097 20.7245 4.65326 21 5.25 21H18.75C19.3467 21 19.919 20.7245 20.341 20.234C20.7629 19.7435 21 19.0783 21 18.3846"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon;
