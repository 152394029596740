import { Dispatch, SetStateAction, useEffect, useState } from "react";
import first from "lodash/first";
import last from "lodash/last";
import cloneDeep from "lodash/cloneDeep";

import { Request, Trip, TripCategory } from "types";

type UseCreateRequestTripTypeProps = {
  enabledTripCategories: TripCategory[];
  setRequest: Dispatch<SetStateAction<Request>>;
  defaultSubmitLabelOptions: string[];
  inputTrip?: Trip;
  initialFocusedStopDateTimes?: string[];
};

const useCreateRequestTripType = (props: UseCreateRequestTripTypeProps) => {
  const {
    enabledTripCategories,
    setRequest,
    inputTrip,
    initialFocusedStopDateTimes,
    defaultSubmitLabelOptions,
  } = props;

  // state
  const [tripType, setTripType] = useState(null);
  const [submitLabelOptions, setSubmitLabelOptions] = useState(
    defaultSubmitLabelOptions
  );

  // event handlers
  const handleTripTypeChange = (_: any, value: TripCategory) => {
    setTripType(value);

    if (value === TripCategory.RoundTrip) {
      setSubmitLabelOptions(["Save Pick-Up Trip"]);
    } else {
      setSubmitLabelOptions(defaultSubmitLabelOptions);
    }
  };

  // effects
  // defaults trip type to first option from operator settings
  useEffect(
    () => setTripType(first(enabledTripCategories)),
    [enabledTripCategories, setTripType]
  );

  // if duplicate trip, set the tripType to match
  useEffect(() => {
    if (!inputTrip) return;

    setTripType(inputTrip.tripCategory);
  }, [setTripType, inputTrip]);

  // reset stop date times
  useEffect(() => {
    if (tripType === TripCategory.Hourly) {
      return;
    }

    setRequest((prevRequest) => {
      const request = cloneDeep(prevRequest);
      const trip = last(request.trips);

      // if trip type transfers from shuttle pick up to return & then back to shuttle pick up, reset date times
      if (tripType === TripCategory.ShuttlePickUp) {
        if (!initialFocusedStopDateTimes) return request;

        const stopsWithUpdatedDates = trip.stops.map((stop, i) => {
          if (stop.stopIndex === 1) return stop;

          return {
            ...stop,
            dateTime: initialFocusedStopDateTimes[i] || null,
          };
        });
        trip.stops = stopsWithUpdatedDates;
        return request;
      }

      // if trip type transfers from shuttle pick up to return, clear stop date times
      if (tripType === TripCategory.ShuttleReturn) {
        const stopsInput = trip.stops.map((stop) => {
          if (stop.stopIndex === 1) return stop;

          return {
            ...stop,
            dateTime: null,
          };
        });

        trip.stops = stopsInput;
        return request;
      }

      // reset dropOff dateTime if choosing one-way transfers
      const dropOff = last(trip.stops);
      dropOff.dateTime = null;
      trip.stops.splice(trip.stops.length - 1, 1, dropOff);
      return request;
    });
  }, [tripType, setRequest, initialFocusedStopDateTimes]);

  return {
    tripType,
    onTripTypeChange: handleTripTypeChange,
    submitLabelOptions,
  };
};

export { useCreateRequestTripType };
