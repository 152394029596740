import moment from "moment";
import * as yup from "yup";

import { getWeekdaysFromDateRange } from "./utils/getWeekdaysFromDateRange";

export const editDynamicPricingSchema = yup.object({
  name: yup.string().required("Must Name Rule").typeError("Must Name Rule"),
  startDate: yup
    .date()
    .nullable()
    .typeError("Please select a date")
    .test({
      name: "Validate Start Date",
      message: "If end date must include start date",
      test: function () {
        const { startDate, endDate } = this.parent;

        if (endDate && !startDate) return false;
        return true;
      },
    }),
  endDate: yup
    .date()
    .nullable()
    .typeError("Please select a date")
    .test({
      name: "Validate end date is after start date",
      message: "Please select an end date that occurs after start date",
      test: function () {
        const { startDate, endDate } = this.parent;

        const formattedStartDate = moment.utc(startDate).format("YYYY-MM-DD");
        const formattedEndDate = moment.utc(endDate).format("YYYY-MM-DD");

        if (!endDate) return true;
        if (formattedStartDate === formattedEndDate) return false;
        if (startDate && endDate) {
          return new Date(formattedEndDate) > new Date(formattedStartDate);
        }
        return true;
      },
    }),
  startTime: yup
    .object()
    .nullable()
    .test({
      name: "Validate Start Time",
      message: "If end time must include start time",
      test: function () {
        const { startTime, endTime } = this.parent;

        if (
          endTime?.isValid() &&
          (!startTime?.isValid() || startTime === null)
        ) {
          return false;
        }
        return true;
      },
    }),
  endTime: yup
    .object()
    .nullable()
    .test({
      name: "Validate End Time",
      message: "If start time must include end time",
      test: function () {
        const { startTime, endTime } = this.parent;

        if (
          endTime?.isValid() &&
          (!startTime?.isValid() || startTime === null)
        ) {
          return true;
        }

        if (
          (!endTime?.isValid() || endTime === null) &&
          (!startTime?.isValid() || startTime === null)
        ) {
          return true;
        }

        if (endTime?.isValid() && startTime?.isValid()) {
          return true;
        }
      },
    })
    .test({
      name: "Validate end time occurs after start time",
      message: "Please select an end time that occurs after start time",
      test: function () {
        const { startTime, endTime } = this.parent;

        const formattedStartTime = moment(startTime).format("HH:mm");
        const formattedEndTime = moment(endTime).format("HH:mm");

        if (
          (!startTime?.isValid() || startTime === null) &&
          (!endTime?.isValid() || endTime === null)
        ) {
          return true;
        }

        if (startTime?.isValid() && endTime?.isValid()) {
          return formattedStartTime < formattedEndTime;
        }
        return true;
      },
    }),
  daysOfWeek: yup
    .array()
    .of(
      yup.object({
        id: yup.string(),
        value: yup.string(),
        isSelected: yup.boolean(),
      })
    )
    .test({
      name: "Weekdays selected are within date range",
      message: "Please select weekdays within date range",
      test: function () {
        const { startDate, endDate, daysOfWeek, startTime, endTime } =
          this.parent;

        if (!startDate && !endDate) return true;
        if (
          !startDate &&
          !endDate &&
          !startTime?.isValid() &&
          !endTime?.isValid()
        ) {
          return true;
        }

        if (
          startDate &&
          !endDate &&
          startTime?.isValid() &&
          endTime?.isValid()
        ) {
          return true;
        }

        const formattedStartDate = moment.utc(startDate).format("ll");
        const formattedEndDate = moment.utc(endDate).format("ll");

        const weekdaysFromDateRange = getWeekdaysFromDateRange({
          formattedStartDate,
          formattedEndDate,
        });

        const selectedWeekdays = daysOfWeek
          .filter((day) => day.isSelected)
          .map((day) => day.value);

        return selectedWeekdays.every((selected) =>
          weekdaysFromDateRange.includes(selected)
        );
      },
    })
    .required("Please select at least one day of week"),
  daysOfWeekIsDisabled: yup.boolean().required(),
  vehicles: yup
    .array()
    .of(
      yup.object({
        id: yup.string(),
        value: yup.string(),
        disabled: yup.boolean(),
        isSelected: yup.boolean(),
      })
    )
    .test({
      name: "Check if at least one vehicle is selected",
      message: "Please select vehicle(s)",
      test: function (vehicles) {
        return vehicles.some((item) => item.isSelected);
      },
    })
    .required("Please select vehicle(s)"),
  isIncrease: yup
    .boolean()
    .required("Please make a selection")
    .typeError("Please make a selection"),
  isPercent: yup
    .boolean()
    .required("Please make a selection")
    .typeError("Please make a selection"),
  amount: yup
    .number()
    .required("Please enter an amount")
    .typeError("Please enter an amount"),
  repeatAnnually: yup
    .boolean()
    .required("Please make a selection")
    .typeError("Please make a selection"),
  repeatRuleIsDisabled: yup.boolean().required(),
});
