import { gql } from "@apollo/client";

export const LOAD_DISPATCH_TRIPS_QUERY = gql`
  query LoadDispatchTrips {
    loadDispatchTrips {
      id
      tripNumber
      tripCategory
      roundTripVariant
      request {
        id
        orderNumber
      }
      temporaryPassenger
      contact {
        firstName
        lastName
      }
      stops {
        id
        dateTime
        originalDateTime
        flightOffset
      }
      routes {
        id
        dispatchStatus
        driver {
          id
          firstName
          lastName
        }
        driverStatus {
          name
        }
        vehicle {
          id
          name
        }
        dispatchStatus
        routeDispatch {
          id
          driverEta
          currentStatus
          currentStopName
          currentStop {
            id
            stopIndex
            location
          }
          currentStopArrivalTime
        }
      }
    }
  }
`;
