import React, { ChangeEvent } from "react";
import { useMutation } from "@apollo/client";

import { Box, Typography } from "@mui/material";

import LabeledSwitchInput from "../LabeledSwitchInput";
import {
  useAnalytics,
  useOperator,
  useSnackbar,
} from "../../../../globals/hooks";
import {
  EmptyVariables,
  DISABLE_EMAIL_NOTIFICATIONS_FOR_INBOUND_TEXTS,
  DISABLE_TEXT_NOTIFICATIONS_FOR_INBOUND_TEXTS,
  ENABLE_EMAIL_NOTIFICATIONS_FOR_INBOUND_TEXTS,
  ENABLE_TEXT_NOTIFICATIONS_FOR_INBOUND_TEXTS,
  UPDATE_OPERATOR_SETTINGS,
} from "../../../../globals/graphql";
import { stringOfUsers } from "../../../../globals/utils/helpers";
import { granite } from "design-system/colors";

type InternalToggleBlockProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function InternalToggleBlock(props: InternalToggleBlockProps) {
  const { setSaveIndicatorState } = props;

  // hooks
  const snackbar = useSnackbar();
  const {
    users,
    generalEmail,
    inboundTextNotificationsEnabled,
    inboundEmailNotificationsEnabled,
    settings: {
      internalFlightUpdatedTextsEnabled,
      driverDeclineJobEmailEnabled,
      driverDeclineJobTextEnabled,
    },
  } = useOperator();
  const { track } = useAnalytics();

  const operatorUsersText = stringOfUsers(users);

  // mutations
  const [enableEmailNotificationsForInboundTexts] = useMutation(
    ENABLE_EMAIL_NOTIFICATIONS_FOR_INBOUND_TEXTS,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        snackbar.error("error disabling email notifications for inbound texts");
      },
      variables: EmptyVariables,
    }
  );

  const [disableEmailNotificationsForInboundTexts] = useMutation(
    DISABLE_EMAIL_NOTIFICATIONS_FOR_INBOUND_TEXTS,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        snackbar.error("error disabling email notifications for inbound texts");
      },
      variables: EmptyVariables,
    }
  );

  const [enableTextNotificationsForInboundTexts] = useMutation(
    ENABLE_TEXT_NOTIFICATIONS_FOR_INBOUND_TEXTS,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        snackbar.error("error disabling text notifications for inbound texts");
      },
      variables: EmptyVariables,
    }
  );

  const [disableTextNotificationsForInboundTexts] = useMutation(
    DISABLE_TEXT_NOTIFICATIONS_FOR_INBOUND_TEXTS,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        snackbar.error("error disabling text notifications for inbound texts");
      },
      variables: EmptyVariables,
    }
  );

  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      snackbar.error("error updating operator settings");
    },
  });

  // event handler
  const handleEmailNotificationsChange = () => {
    setSaveIndicatorState("loading");

    inboundEmailNotificationsEnabled
      ? disableEmailNotificationsForInboundTexts()
      : enableEmailNotificationsForInboundTexts();
  };

  const handleTextNotificationsChange = () => {
    setSaveIndicatorState("loading");

    inboundTextNotificationsEnabled
      ? disableTextNotificationsForInboundTexts()
      : enableTextNotificationsForInboundTexts();
  };

  const handleFlightUpdateReminderTextsChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setSaveIndicatorState("loading");

    if (event.target.checked) {
      track("internalSetting_flightOn");
    }

    updateOperatorSettings({
      variables: {
        input: {
          internalFlightUpdatedTextsEnabled: event.target.checked,
        },
      },
    });
  };

  const handleDriverDeclineTripEmailNotificationChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setSaveIndicatorState("loading");

    updateOperatorSettings({
      variables: {
        input: {
          driverDeclineJobEmailEnabled: event.target.checked,
        },
      },
    });
  };

  const handleDriverDeclineTripTextNotificationChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setSaveIndicatorState("loading");

    updateOperatorSettings({
      variables: {
        input: {
          driverDeclineJobTextEnabled: event.target.checked,
        },
      },
    });
  };

  return (
    <>
      <Typography variant="h4">Internal</Typography>
      <Box mt={2}>
        <LabeledSwitchInput
          name="emailNotifications"
          checked={inboundEmailNotificationsEnabled}
          onChange={handleEmailNotificationsChange}
          text="Receive email notifications for incoming text messages"
          subText={
            <>
              <Typography variant="caption" style={{ color: granite }}>
                Email notifications will be sent to
              </Typography>{" "}
              <Typography
                variant="caption"
                style={{ color: granite, fontWeight: 600 }}
              >
                {generalEmail}
              </Typography>
            </>
          }
        />
      </Box>
      <Box mt={2.5}>
        <LabeledSwitchInput
          name="textNotifications"
          checked={inboundTextNotificationsEnabled}
          onChange={handleTextNotificationsChange}
          text="Receive text notifications for incoming text messages"
          subText={
            <>
              <Typography variant="caption" style={{ color: granite }}>
                Text notifications will be sent to
              </Typography>{" "}
              <Typography
                variant="caption"
                style={{ color: granite, fontWeight: 600 }}
              >
                {operatorUsersText}
              </Typography>
            </>
          }
        />
      </Box>
      <Box mt={2.5}>
        <LabeledSwitchInput
          name="flightUpdateNotifications"
          checked={internalFlightUpdatedTextsEnabled}
          onChange={handleFlightUpdateReminderTextsChange}
          text="Receive a text if flight time is updated"
          subText={
            <>
              <Typography variant="caption" style={{ color: granite }}>
                Text notifications will be sent to
              </Typography>{" "}
              <Typography
                variant="caption"
                style={{ color: granite, fontWeight: 600 }}
              >
                {operatorUsersText}
              </Typography>{" "}
              <Typography variant="caption" style={{ color: granite }}>
                {`if flight is updated by more than 15 minutes turned ${
                  internalFlightUpdatedTextsEnabled ? "on" : "off"
                }.`}
              </Typography>
            </>
          }
        />
      </Box>
      <Box mt={2.5}>
        <LabeledSwitchInput
          name="inboundDriverDenysTripEmailNotifications"
          checked={driverDeclineJobEmailEnabled}
          onChange={handleDriverDeclineTripEmailNotificationChange}
          text="Receive an email notification if a driver declines a trip assignment"
          subText={
            <Typography variant="caption" style={{ color: granite }}>
              Email notifications will be sent to all members.
            </Typography>
          }
        />
      </Box>
      <Box mt={2.5}>
        <LabeledSwitchInput
          name="inboundDriverDenysTripTextNotifications"
          checked={driverDeclineJobTextEnabled}
          onChange={handleDriverDeclineTripTextNotificationChange}
          text="Receive a text notification if a driver declines a trip assignment"
          subText={
            <Typography variant="caption" style={{ color: granite }}>
              Text notifications will be sent to all members.
            </Typography>
          }
        />
      </Box>
    </>
  );
}

export default InternalToggleBlock;
