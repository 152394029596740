/**
 * @file useAutomatedBaseRateQuery.tsx
 * When this hook is invoked, it will call LOAD_AUTOMATED_BASE_RATE query
 * as long as all required inputs are provided.
 *
 * If there is a preexisting automated base rate value, it skips the query and returns the preexisting value.
 * This prevents extra calls for the automated base rate.
 *
 * useAutomatedBaseRateQuery Output
 * @typedef {Object} useAutomatedBaseRateQueryOutput
 * @property {BaseRateAutomation} automatedBaseRate - The preexisting or queried automated base rate data.
 * @property {boolean} hasAutomatedBaseRate - True if automated base rate preexists OR can query for automated base rate.
 * @property {boolean} automatedBaseRateLoading - True if the query is in flight. Always returns false if automated base rate preexists.
 * @property {boolean} automatedBaseRateLoading - True if the query is in flight. Always returns false if automated base rate preexists.
 */

import first from "lodash/first";
import last from "lodash/last";
import isNull from "lodash/isNull";

import { useQuery } from "@apollo/client";
import { useSnackbar } from "globals/hooks";
import { BaseRateAutomation, Stop } from "types";
import { LOAD_AUTOMATED_BASE_RATE } from "../automatedPricing.graphql";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { shapeStopsToAutomatedBaseRateQueryStopsArg } from "../utils";

type UseAutomatedBaseRateQueryParams = {
  queryVariables: { vehicleId: string; stops: Stop[] };
  hasEnabledVehicleBRA: boolean;
  preexistingABR: BaseRateAutomation;
  preexistingABRLoading: boolean;
  isFarmedRoute?: boolean;
};

function useAutomatedBaseRateQuery(params: UseAutomatedBaseRateQueryParams) {
  const {
    queryVariables,
    queryVariables: { stops },
    hasEnabledVehicleBRA,
    preexistingABR,
    preexistingABRLoading,
    isFarmedRoute,
  } = params;

  const snackbar = useSnackbar();

  const firstStop = first(stops);
  const lastStop = last(stops);

  // should query for BRA if existing BRA was not passed in AND all required inputs are passed in
  const shouldQueryForBRA =
    !preexistingABR &&
    hasEnabledVehicleBRA &&
    firstStop?.location &&
    lastStop?.location &&
    !!firstStop?.dateTime &&
    !isFarmedRoute;

  const { data: automatedBaseRateData, loading: automatedBaseRateLoading } =
    useQuery<{ automatedBaseRate: BaseRateAutomation }>(
      LOAD_AUTOMATED_BASE_RATE,
      {
        variables: {
          ...queryVariables,
          stops: shapeStopsToAutomatedBaseRateQueryStopsArg(stops),
        },
        skip: !shouldQueryForBRA,
        onError(error) {
          const errorMessage =
            getErrorMessage(error) || "Failed to get automated base rate.";

          snackbar.error(errorMessage);
        },
        fetchPolicy: "network-only",
      }
    );

  if (preexistingABR) {
    return {
      automatedBaseRate: preexistingABR,
      automatedBaseRateLoading:
        preexistingABRLoading || automatedBaseRateLoading,
      hasAutomatedBaseRate: true,
    };
  }

  return {
    automatedBaseRateLoading: preexistingABRLoading || automatedBaseRateLoading,
    hasAutomatedBaseRate:
      shouldQueryForBRA && !isNull(automatedBaseRateData?.automatedBaseRate),
    automatedBaseRate: automatedBaseRateData?.automatedBaseRate,
  };
}

export { useAutomatedBaseRateQuery };
