import React, { useState } from "react";

import { DriverPayoutIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";
import { useAnalytics } from "globals/hooks";
import {
  FarmedRouteStatusEnum,
  FarmRelationshipEnum,
  RequestStage,
} from "types";

// constants
const { Accepted, Completed } = FarmedRouteStatusEnum;
const { Farmee, Farmor } = FarmRelationshipEnum;

type UseUpdateDriverPayoutClickProps = {
  analyticsName?: string;
  farmStatus: FarmedRouteStatusEnum;
  farmRelationship: FarmRelationshipEnum;
  requestStage: string;
};
function useUpdateDriverPayoutClick(
  props: UseUpdateDriverPayoutClickProps
): EllipsisMenuOption & {
  open: boolean;
  onClose: () => void;
} {
  const { analyticsName, farmStatus, farmRelationship, requestStage } = props;

  // hooks
  const { track } = useAnalytics();

  // state
  const [updateDriverPayoutDialogOpen, setUpdateDriverPayoutDialogOpen] =
    useState(false);

  // derived state
  const isDisabledForFarmee =
    farmRelationship === Farmee &&
    farmStatus !== Accepted &&
    farmStatus !== Completed;
  const updateDriverPayoutDisabled =
    farmRelationship === Farmor || isDisabledForFarmee;

  const isUnconfirmedReservation = requestStage === RequestStage.Unconfirmed;

  // event handlers
  const handleUpdateDriverPayoutOpen = () => {
    if (analyticsName) track(analyticsName);
    setUpdateDriverPayoutDialogOpen(true);
  };

  const handleUpdateDriverPayoutClose = () => {
    setUpdateDriverPayoutDialogOpen(false);
  };

  const handleUpdateDriverPayoutClick = () => {
    handleUpdateDriverPayoutOpen();
  };

  return {
    onClick: handleUpdateDriverPayoutClick,
    text: "Update Driver Payout",
    icon: <DriverPayoutIcon color={grayDark} />,
    disableOption: updateDriverPayoutDisabled || isUnconfirmedReservation,
    tooltip: updateDriverPayoutDisabled
      ? {
          titleText: isDisabledForFarmee
            ? "Farm-in must be accepted"
            : "Not available for farmed out trips",
        }
      : undefined,

    open: updateDriverPayoutDialogOpen,
    onClose: handleUpdateDriverPayoutClose,
  };
}

export { useUpdateDriverPayoutClick };
