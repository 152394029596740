import { Box, Typography } from "@mui/material";

import { grayDark } from "design-system/colors";

type ToggleHeaderProps = {
  text: string;
  subText: string;
};

function ToggleHeader(props: ToggleHeaderProps) {
  const { text, subText } = props;
  return (
    <Box display="flex" flexDirection="column" ml={1}>
      <Box mb={0.5}>
        <Typography variant="body2">{text}</Typography>
      </Box>
      <Typography variant="caption" style={{ color: grayDark }}>
        {subText}
      </Typography>
    </Box>
  );
}

export default ToggleHeader;
