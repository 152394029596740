import React, { useEffect, useState } from "react";
import theme, { primaryDisabledColor, primaryMainColor } from "theme";
import { useMutation } from "@apollo/client";

import { Box, Typography, CircularProgress, Button } from "@mui/material";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";

import ImageUpload from "components/ImageUpload/ImageUpload";
import { errorRed, granite, moovsBlueLight } from "design-system/colors";
import { useAnalytics, useOperator, useScreenSize } from "globals/hooks";
import { UPDATE_OPERATOR_MUTATION } from "globals/graphql";

const HTML_FOR = "widget-photo-button";

type CustomerPortalImageProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function CustomerPortalImage(props: CustomerPortalImageProps) {
  const { setSaveIndicatorState } = props;

  // mutations
  const [updateOperatorInfo] = useMutation(UPDATE_OPERATOR_MUTATION, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError() {
      setSaveIndicatorState("error");
    },
  });

  // hooks
  const { track } = useAnalytics();
  const { isMobileView } = useScreenSize();
  const operator = useOperator();

  // state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const photoUrl = operator.widgetBannerUrl;

  const handlePhotoChange = (url) => {
    updateOperatorInfo({
      variables: {
        input: { widgetBannerUrl: url },
      },
    });
  };

  const handleRemoveImage = () => {
    track("settings_bannerRemoved");
    setLoading(true);
    setTimeout(() => handlePhotoChange(null), 300);
  };

  useEffect(() => {
    setLoading(false);
  }, [photoUrl, setSaveIndicatorState]);

  useEffect(() => {
    if (loading) {
      setSaveIndicatorState("loading");
    }
  }, [loading, setSaveIndicatorState]);

  return (
    <>
      <Box mb={3}>
        <Typography variant="h3">Customer Portal Image</Typography>
      </Box>
      <label htmlFor={HTML_FOR}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            maxWidth: "700px",
            maxHeight: "100px",
            backgroundColor: primaryDisabledColor,
            borderRadius: "4px",
            cursor: "pointer",
            transition: ".2s",
            overflow: "hidden",
            "&:hover": {
              backgroundColor: moovsBlueLight,
            },
            boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.08)",
          }}
        >
          {/* Photo Exists */}
          {!!photoUrl && !error && !loading && (
            <Box
              component="img"
              src={photoUrl}
              alt={`vehicle-${photoUrl}`}
              sx={{
                objectFit: "contain",
                width: "700px",
                maxWidth: "100vw",
                minHeight: "100px",
                [theme.breakpoints.up("sm")]: {
                  maxWidth: "calc(100vw - 250px - 80px)",
                },
              }}
            />
          )}

          {/* Uploading New Photo */}
          {loading && !error && (
            <Box height={100} display="flex" alignItems="center">
              <CircularProgress />
            </Box>
          )}

          {/* No Photo */}
          {!error && !loading && !photoUrl && (
            <Box height={100} display="flex" alignItems="center">
              <CameraAltOutlinedIcon
                style={{
                  color: primaryMainColor,
                  height: "48px",
                  width: "40px",
                }}
              />
            </Box>
          )}

          {/* Error */}
          {!!error && (
            <Typography style={{ color: errorRed }}>{error}</Typography>
          )}
        </Box>
      </label>
      <Box
        display="flex"
        flexDirection={isMobileView ? "column" : "row"}
        style={{ paddingTop: 16 }}
      >
        {!photoUrl ? (
          <ImageUpload
            firebaseDirectory="widget_photos"
            htmlFor={HTML_FOR}
            aspectRatio={7 / 1}
            maxWidth={3360}
            maxHeight={480}
            onFileUploadStart={() => setLoading(true)}
            onFileUploadComplete={(url) => handlePhotoChange(url)}
            onError={(errorMessage) => setError(errorMessage)}
            button={
              <Button variant="outlined" color="primary" component="span">
                Add Widget Image
              </Button>
            }
          />
        ) : (
          <Box
            mb={1}
            display="flex"
            justifyContent="space-between"
            sx={{ width: "275px", maxHeight: "100px" }}
          >
            <ImageUpload
              firebaseDirectory="widget_photos"
              htmlFor={HTML_FOR}
              aspectRatio={7 / 1}
              maxWidth={3360}
              maxHeight={480}
              onFileUploadStart={() => setLoading(true)}
              onFileUploadComplete={(url) => handlePhotoChange(url)}
              onError={(errorMessage) => setError(errorMessage)}
              button={
                <Button variant="outlined" color="primary" component="span">
                  <Typography fontWeight={500} fontSize={13}>
                    Replace Image
                  </Typography>
                </Button>
              }
            />
            <Button
              variant="outlined"
              color="primary"
              component="span"
              onClick={handleRemoveImage}
            >
              <Typography fontWeight={500} fontSize={13}>
                Remove Image
              </Typography>
            </Button>
          </Box>
        )}
        <Box ml={isMobileView ? 0 : 2}>
          <Typography
            variant="caption"
            style={{ color: granite, fontSize: "12px", fontWeight: 500 }}
          >
            {!photoUrl
              ? "Click to upload a new photo."
              : "Click to add or remove a photo."}
          </Typography>
          <Typography style={{ color: granite, fontSize: "12px" }}>
            JPEG/PNG - Min. 1400x200px.
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default CustomerPortalImage;
