import React, {
  useState,
  useEffect,
  Ref,
  ChangeEvent,
  KeyboardEvent,
} from "react";
import { useDebounce } from "use-debounce";
import { useLazyQuery } from "@apollo/client";

import { TextField, Divider, Typography, Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { useSnackbar } from "../../globals/hooks/useSnackbar";
import { LOAD_AIRPORTS_QUERY } from "../../globals/graphql";
import { Airport, RequestsEdge } from "../../types";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type AirportAutoCompleteProps = {
  onChange: (event: any, value: any) => void;
  id?: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  value: any;
  name?: string;
  placeholder?: string;
  inputRef?: Ref<HTMLInputElement>;
  styles?: any;
  disabled?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  inputSx?: Record<string, unknown>;
  onBlur?: () => void;
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
};

function AirportAutoComplete(props: AirportAutoCompleteProps) {
  const {
    onChange,
    id,
    label,
    error,
    value,
    inputRef,
    disabled,
    helperText,
    name,
    placeholder,
    inputSx,
    required,
    fullWidth,
    onBlur,
    onKeyDown,
  } = props;

  const snackbar = useSnackbar();

  // state
  const [inputValue, setInputValue] = useState("");
  const [queryLoading, setQueryLoading] = useState(false);
  const [debouncedInput] = useDebounce(inputValue, 200);

  const [options, setOptions] = useState<Airport[]>([]);

  // queries
  const [loadAirports] = useLazyQuery(LOAD_AIRPORTS_QUERY, {
    onCompleted(data) {
      const airports = data.airports.edges.map(
        (edge: RequestsEdge) => edge.node
      );

      setQueryLoading(false);
      setOptions(airports);
    },
    onError(error) {
      setQueryLoading(false);
      const errorMessage = getErrorMessage(error) || error.message;

      snackbar.error(errorMessage);
    },
    fetchPolicy: "network-only",
  });

  // event handlers
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleUpdateField = (event: object, value: any) => {
    onChange(value, event);
  };

  useEffect(() => {
    if (debouncedInput === "") {
      setOptions([]);
      return undefined;
    }

    if (debouncedInput.length) {
      loadAirports({
        variables: {
          globalSearch: debouncedInput,
        },
      });
    }
  }, [debouncedInput, loadAirports]);

  // display loading as soon as user makes an input change
  useEffect(() => {
    setQueryLoading(!!inputValue);
  }, [inputValue]);

  return (
    <Autocomplete
      fullWidth
      autoHighlight
      disabled={!!disabled}
      onChange={handleUpdateField}
      id={id}
      getOptionLabel={(option: Airport) =>
        typeof option === "string"
          ? option
          : `${option.iataCode} - ${option.airportName}`
      }
      isOptionEqualToValue={(option: Airport, value: Airport) =>
        option.icaoCode === value.icaoCode
      }
      filterOptions={(x) => x}
      options={options}
      loading={queryLoading}
      loadingText="Loading..."
      noOptionsText={inputValue ? "No Results Found" : "Enter Airport Name"}
      autoComplete={false}
      includeInputInList
      placeholder={placeholder}
      value={value}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      renderInput={(params) => {
        const { InputProps, ...restParams } = params;
        return (
          <TextField
            inputRef={inputRef}
            {...restParams}
            error={!!error}
            helperText={helperText}
            InputProps={{
              ...InputProps,
              sx: inputSx,
              autoComplete: "new-password",
            }}
            label={label}
            variant="outlined"
            name={name}
            fullWidth={fullWidth}
            required={required}
            onChange={handleChange}
          />
        );
      }}
      renderOption={(props, { airportName, iataCode }) => (
        <li {...props}>
          <Box>
            <Typography variant="body2">{airportName}</Typography>
            <Typography style={{ fontSize: "12px" }}>{iataCode}</Typography>
          </Box>
          <Divider />
        </li>
      )}
    />
  );
}

export default AirportAutoComplete;
