import React from "react";

type InvoiceActivityLogIconProps = {
  color?: string;
};

const InvoiceActivityLogIcon = (props: InvoiceActivityLogIconProps) => {
  const { color } = props;

  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.952 1.61328C11.2782 1.61335 11.591 1.73967 11.8217 1.96448L16.6398 6.66208C16.8704 6.88704 16.9999 7.19204 17 7.51008M4.16667 10.7872C4.34714 11.0467 4.5821 11.2547 4.85163 11.3937C5.12116 11.5326 5.41731 11.5984 5.715 11.5854C6.66417 11.5854 7.43417 10.9532 7.43417 10.1741C7.43417 9.39505 6.66667 8.76375 5.71833 8.76375C4.77 8.76375 4 8.13154 4 7.35154C4 6.57154 4.77 5.94024 5.71833 5.94024M5.71833 5.94024C6.01605 5.92703 6.31228 5.99271 6.58184 6.13168C6.85141 6.27065 7.08634 6.47882 7.26667 6.73849M5.71833 5.94024V4.99968M5.71833 11.5854V12.526M9 13.8944H14M4.625 16.9997H14M17 19.7997C17 20.1179 16.8703 20.4232 16.6395 20.6482C16.4087 20.8732 16.0957 20.9997 15.7692 20.9997H2.23077C1.90435 20.9997 1.5913 20.8732 1.36048 20.6482C1.12967 20.4232 1 20.1179 1 19.7997V2.81328C1 2.49502 1.12967 2.1898 1.36048 1.96475C1.5913 1.73971 1.90435 1.61328 2.23077 1.61328H10.8462V6.41328C10.8462 6.73154 10.9758 7.03677 11.2066 7.26181C11.4375 7.48685 11.7505 7.61328 12.0769 7.61328H17L17 19.7997Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InvoiceActivityLogIcon;
