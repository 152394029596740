import { useAnalytics, useOperator, useQueryParams } from "globals/hooks";
import { appViewedToTrack } from "pages/moovs-market/helpers/enumToTrack";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { MoovsMarketAppData } from "../components/MoovsMarketAppList/hooks/useMoovsMarketApps";
import useMoovsMarketApps from "../components/MoovsMarketAppList/hooks";

const useSelectedMoovsMarketApp = () => {
  // hooks
  const { availableApps } = useMoovsMarketApps();
  const { track } = useAnalytics();
  const queryParams = useQueryParams();
  const history = useHistory();
  const operator = useOperator();

  // state
  const [selectedApp, setSelectedApp] = useState<string>("");

  // event handlers
  const handleAppClick = (app: MoovsMarketAppData) => {
    track(appViewedToTrack[app.enum]);
    setSelectedApp(app.enum);
  };

  const handleAppClose = () => {
    history.replace("/settings/moovs-market");
    setSelectedApp(null);
  };

  // for accessing market app directly via query param
  // ensures operator data is present before accessing the query param
  // and that the queried app enum is available
  useEffect(() => {
    const appEnum = queryParams.get("app");
    if (
      operator &&
      availableApps.map((app) => app.enum.toString()).includes(appEnum)
    ) {
      track(appViewedToTrack[appEnum]);
      setSelectedApp(appEnum);
    }
  }, [queryParams, operator, availableApps, track]);

  // derived state
  const app = availableApps.find((app) => selectedApp === app.enum);

  return {
    selectedApp: app,
    onAppClick: handleAppClick,
    onAppClose: handleAppClose,
  };
};

export default useSelectedMoovsMarketApp;
