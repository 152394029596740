import React, { Dispatch, SetStateAction, useState } from "react";
import moment from "moment";

import { Box, Typography } from "@mui/material";

import VehiclesBlock from "components/settings/general/VehiclesBlock";
import {
  LabeledInlineInput,
  LabeledInlineDatePicker,
  DollarInputLabeledInline,
  LabeledInlinePhoneNumberInput,
} from "design-system/components/inputs";
import { UPDATE_INSURANCE_POLICY_MUTATION } from "globals/graphql";
import { useAutoSave } from "globals/hooks";
import { InsurancePolicy, UpdateInsurancePolicyInput } from "types";
import InsuranceAttachmentsBlock from "../../InsuranceAttachmentsBlock";
import omit from "lodash/omit";

type UpdateInsuranceFormProps = {
  insurancePolicy: InsurancePolicy;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
  insurancePolicyRefetch: () => void;
};

function UpdateInsuranceForm(props: UpdateInsuranceFormProps) {
  const { setSaveIndicatorState, insurancePolicyRefetch } = props;

  // state
  const [insurancePolicy, setInsurancePolicy] =
    useAutoSave<UpdateInsurancePolicyInput>({
      setSaveIndicatorState,
      mutation: UPDATE_INSURANCE_POLICY_MUTATION,
      name: "insurance policy",
      incomingState: omit(props.insurancePolicy, [
        "vehicleIds",
        "insuranceAttachments",
        "__typename",
      ]),
    });
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");

  const handleChange = (event) => {
    const key = event.target.name;
    let value = event.target.value;

    if (key === "coverageAmount" || key === "monthlyPremium") {
      value = Number(value);
    }
    setInsurancePolicy({
      ...insurancePolicy,
      [key]: value,
    });
  };

  const handleStartDateInputChange = (date: string) => {
    if (!date || !moment(date).isValid()) {
      setStartDateError("Invalid Date");
      return;
    }

    setStartDateError("");

    setInsurancePolicy({
      ...insurancePolicy,
      startDate: moment(date).format("YYYY-MM-DD"),
    });
  };

  const handleEndDateInputChange = (date: string) => {
    if (!date || !moment(date).isValid()) {
      setEndDateError("Invalid Date");
      return;
    }

    setStartDateError("");

    setInsurancePolicy({
      ...insurancePolicy,
      endDate: moment(date).format("YYYY-MM-DD"),
    });
  };

  // wait for useAutoSave to initialize
  if (!insurancePolicy) return null;

  return (
    <>
      {/* General Details */}
      <Box pt={3}>
        <Box mb={3}>
          <Typography variant="h5">General Details</Typography>
        </Box>

        <LabeledInlineInput
          required
          label="Insurance Company"
          name="insuranceCompany"
          value={insurancePolicy.insuranceCompany || ""}
          onChange={handleChange}
          errorText="Insurance Company is a required field"
        />

        <LabeledInlineInput
          required
          name="policyNumber"
          label="Policy Number"
          value={insurancePolicy.policyNumber || ""}
          onChange={handleChange}
          errorText="Policy Number is a required field"
        />

        <LabeledInlineDatePicker
          required
          name="startDate"
          label="Starting Date"
          value={moment(insurancePolicy.startDate).format("YYYY-MM-DD")}
          onChange={handleStartDateInputChange}
          errorText={startDateError}
          error={!!startDateError}
        />

        <LabeledInlineDatePicker
          required
          name="endDate"
          label="Ending Date"
          value={moment(insurancePolicy.endDate).format("YYYY-MM-DD")}
          onChange={handleEndDateInputChange}
          errorText={endDateError}
          error={!!endDateError}
        />

        <DollarInputLabeledInline
          required
          name="coverageAmount"
          label="Coverage Amount"
          value={insurancePolicy.coverageAmount || ""}
          onChange={handleChange}
          errorText="Coverage Amount is a required field"
        />

        <DollarInputLabeledInline
          required
          name="monthlyPremium"
          label="Monthly Premium"
          value={insurancePolicy.monthlyPremium || ""}
          onChange={handleChange}
          errorText="Monthly Premium is a required field"
        />
      </Box>
      {/* Insurer Contact */}
      <Box pt={3}>
        <Box mb={3}>
          <Typography variant="h5">Insurer Contact</Typography>
        </Box>
        <LabeledInlineInput
          label="First Name"
          name="contactFirstName"
          value={insurancePolicy.contactFirstName || ""}
          onChange={handleChange}
        />

        <LabeledInlineInput
          name="contactLastName"
          label="Last Name"
          value={insurancePolicy.contactLastName || ""}
          onChange={handleChange}
        />

        <LabeledInlinePhoneNumberInput
          type="phoneNumber"
          name="contactPhone"
          label="Contact Phone"
          value={insurancePolicy.contactPhone || ""}
          onChange={handleChange}
        />

        <LabeledInlineInput
          name="contactEmail"
          label="Email Address"
          value={insurancePolicy.contactEmail || ""}
          onChange={handleChange}
        />
      </Box>
      {/* Attachments */}
      <InsuranceAttachmentsBlock
        mode="update"
        insuranceAttachments={props.insurancePolicy.insuranceAttachments}
        setSaveIndicatorState={setSaveIndicatorState}
        insuranceId={insurancePolicy.id}
      />
      {/* Vehicles */}
      <Box>
        <VehiclesBlock
          mode="update"
          vehicleIds={props.insurancePolicy.vehicleIds}
          setSaveIndicatorState={setSaveIndicatorState}
          insurancePolicyId={insurancePolicy.id}
          refetch={insurancePolicyRefetch}
        />
      </Box>
    </>
  );
}

export default UpdateInsuranceForm;
