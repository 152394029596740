import React from "react";

import { Box, Divider, Typography } from "@mui/material";

import SendUpcomingTripReminderBlock from "./SendUpcomingTripReminderBlock";
import SendTripStatusUpdateBlock from "./SendTripStatusUpdateBlock/SendTripStatusUpdateBlock";
import AddBookingContactToCalendarInviteBlock from "./AddBookingContactToCalendarInviteBlock";
import AddPassengerToCalendarInviteBlock from "./AddPassengerToCalendarInviteBlock";
import ToggleHeader from "./ToogleHeader";

type ContactsToggleBlockProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function ContactsToggleBlock(props: ContactsToggleBlockProps) {
  const { setSaveIndicatorState } = props;

  return (
    <>
      <Typography variant="h4">Contacts</Typography>

      <Box mt={1} mb={3}>
        <SendUpcomingTripReminderBlock
          setSaveIndicatorState={setSaveIndicatorState}
        />
      </Box>

      <Divider style={{ marginLeft: 8 }} />

      <Box my={3}>
        <SendTripStatusUpdateBlock
          setSaveIndicatorState={setSaveIndicatorState}
        />
      </Box>

      <Divider style={{ marginLeft: 8, marginBottom: 24 }} />

      <ToggleHeader
        text="Add Passengers and Booking Contacts To Calendar Invites"
        subText="Specify who you want to receive Calendar Invites for a trip."
      />

      <Box my={3}>
        <AddBookingContactToCalendarInviteBlock
          setSaveIndicatorState={setSaveIndicatorState}
        />
      </Box>

      <Box my={3}>
        <AddPassengerToCalendarInviteBlock
          setSaveIndicatorState={setSaveIndicatorState}
        />
      </Box>

      <Divider style={{ marginLeft: 8, marginBottom: 24 }} />
    </>
  );
}

export default ContactsToggleBlock;
