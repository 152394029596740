import { WeekdaysEnum } from "../../../DynamicPricingDialog/utils/types";

const weekdaysMap = {
  0: WeekdaysEnum.SUNDAY,
  1: WeekdaysEnum.MONDAY,
  2: WeekdaysEnum.TUESDAY,
  3: WeekdaysEnum.WEDNESDAY,
  4: WeekdaysEnum.THURSDAY,
  5: WeekdaysEnum.FRIDAY,
  6: WeekdaysEnum.SATURDAY,
};

type getWeekdaysFromDateRangeProps = {
  formattedStartDate: string;
  formattedEndDate: string;
};

// Reference: https://www.techighness.com/post/javascript-get-information-of-week-days-between-two-dates/
export const getWeekdaysFromDateRange = (
  props: getWeekdaysFromDateRangeProps
) => {
  const { formattedStartDate, formattedEndDate } = props;

  let start;
  let end;

  // to cover use case when user selects an end date that occurs before start date
  if (new Date(formattedStartDate) > new Date(formattedEndDate)) {
    start = new Date(formattedEndDate);
    end = new Date(formattedStartDate);
  } else {
    start = new Date(formattedStartDate);
    end = new Date(formattedEndDate);
  }

  end.setDate(end.getDate() + 1);

  const daysOfWeek = [];

  let i = 0;

  while (i < 7 && start < end) {
    daysOfWeek.push(start.getDay());
    start.setDate(start.getDate() + 1);
    i++;
  }

  return daysOfWeek.map((dayIndex) => weekdaysMap[dayIndex]);
};
