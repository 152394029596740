import React from "react";

import { Box, Typography } from "@mui/material";

import { LinkCompanyButton, RemoveLinkedCompany } from "./components";
import { useScreenSize } from "globals/hooks";
import { RequestStage } from "types";
import { purple } from "design-system/colors";
import { CompanyIcon } from "design-system/icons";

type CompanyBlockProps = {
  requestId: string;
  companyName: string;
  isLinkedCompanyRequest: boolean;
  stage: RequestStage;
  isShuttleReservation?: boolean;
};

function CompanyBlock(props: CompanyBlockProps) {
  const {
    requestId,
    companyName,
    isLinkedCompanyRequest,
    stage,
    isShuttleReservation,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Box
      minWidth="170px"
      minHeight="80px"
      {...((isMobileView ||
        stage === RequestStage.Reservation ||
        stage === RequestStage.Unconfirmed) && { mt: 2 })}
    >
      <Box display="flex" alignItems="center">
        <CompanyIcon color={purple} size="small" />
        <Typography variant="overline" color={purple} pl={1}>
          Company
        </Typography>
      </Box>

      <Box mt={1} mb={2}>
        {isLinkedCompanyRequest ? (
          <RemoveLinkedCompany
            requestId={requestId}
            companyName={companyName}
            stage={stage}
            isShuttleReservation={isShuttleReservation}
          />
        ) : (
          <LinkCompanyButton
            requestId={requestId}
            companyName={companyName}
            stage={stage}
          />
        )}
      </Box>
    </Box>
  );
}

export default CompanyBlock;
