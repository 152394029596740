/**
 * @file VehiclePhotosDraggableList.tsx
 * Draggable list of vehicle photos.
 * Displays three empty photos if no photos have been added.
 *
 * components:
 *  VehiclePhotosDraggableList
 */

import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { Box } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import VehiclePhotosDraggableListItem from "./VehiclePhotosDraggableListItem";
import { VehiclePhoto } from "../../../types";
import AddVehiclePhotoPlaceholder from "./placeholders/AddVehiclePhotoPlaceholder";
import BlankVehiclePhotoPlaceholder from "./placeholders/BlankVehiclePhotoPlaceholder";
import theme from "theme";

const useStyles = makeStyles(() =>
  createStyles({
    sortableList: {
      listStyleType: "none",
      zIndex: theme.zIndex.modal + 1,
    },
  })
);

type VehiclePhotosDraggableListProps = {
  photos: VehiclePhoto[];
  onCreateVehiclePhoto: (url: string) => void;
  onUpdateVehiclePhoto: (photo: VehiclePhoto) => void;
  onRemoveVehiclePhoto: (photo: VehiclePhoto) => void;
  onUpdateVehiclePhotoOrder: ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => void;
  setActivePhotoIndex: (photoIndex: number) => void;
};

function VehiclePhotosDraggableList(props: VehiclePhotosDraggableListProps) {
  const {
    photos,
    onUpdateVehiclePhotoOrder,
    onCreateVehiclePhoto,
    onRemoveVehiclePhoto,
    onUpdateVehiclePhoto,
    setActivePhotoIndex,
  } = props;

  const classes = useStyles();

  // event handlers
  // reference: https://github.com/clauderic/react-sortable-hoc/issues/328
  const handleSortStart = () => (document.body.className = "grabbing");

  const handleSortEnd = (indexes: { oldIndex: number; newIndex: number }) => {
    onUpdateVehiclePhotoOrder(indexes);
    document.body.className = "";
  };

  const SortableItem = SortableElement(({ photo }: { photo: VehiclePhoto }) => (
    <VehiclePhotosDraggableListItem
      photo={photo}
      onRemoveVehiclePhoto={onRemoveVehiclePhoto}
      onUpdateVehiclePhoto={onUpdateVehiclePhoto}
      onPhotoClick={() => setActivePhotoIndex(photo.photoIndex)}
    />
  ));

  const SortableList = SortableContainer(
    ({ photos }: { photos: VehiclePhoto[] }) => (
      <Box display="flex" flexDirection="row" flexWrap="wrap" mx={-1}>
        {/* photos */}
        {[...photos]
          // come back from server sorted, but this prevents a weird bug.
          .sort((a, b) => a.photoIndex - b.photoIndex)
          .map((photo, index) => {
            return (
              <SortableItem
                key={photo.id || photo.url}
                photo={photo}
                index={index}
              />
            );
          })}

        {/* add vehicle photo placeholder */}
        {photos.length < 6 && (
          <AddVehiclePhotoPlaceholder
            photoIndex={photos.length + 1}
            onCreateVehiclePhoto={onCreateVehiclePhoto}
          />
        )}

        {/* blank vehicle photo placeholders */}
        {photos.length === 0 && <BlankVehiclePhotoPlaceholder photoIndex={2} />}
        {photos.length < 2 && <BlankVehiclePhotoPlaceholder photoIndex={3} />}
      </Box>
    )
  );

  return (
    <SortableList
      onSortStart={handleSortStart}
      onSortEnd={handleSortEnd}
      photos={photos}
      axis="xy"
      helperClass={classes.sortableList}
      distance={3}
    />
  );
}

export default VehiclePhotosDraggableList;
