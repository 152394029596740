import React, { Dispatch, SetStateAction } from "react";
import { useLocation } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import BaseRateVariantToggle from "./components/BaseRateVariantToggle";
import HourlyBaseRateFields from "./components/HourlyBaseRateFields";
import FlatBaseRateField from "./components/FlatBaseRateField";
import { BaseRateAutomation, BaseRateVariantEnum, Stop } from "types";
import { BRAStatusEnum } from "components/buttons/types";
import useBaseRateData from "./hooks";
import { useAutomatedBaseRateQuery } from "globals/graphql/automatedPricing/hooks";
import { BRACalculationsButton } from "./components";
import { useAnalytics } from "globals/hooks";

type BaseRatePricingFieldProps = {
  onBaseRateAmtChange: (baseRateAmt: number) => void;
  onHourlyBRHoursChange: (hours: number) => void;
  BRAProps: {
    hasEnabledVehicleBRA: boolean;
    vehicleId: string;
    stops: Stop[];
    preexistingABR?: BaseRateAutomation;
    preexistingABRLoading?: boolean;
    hideBRA?: boolean;
  };
  baseRateAmt: number;
  hourlyBaseRateHours: number;
  totalDurationMinutes: number;
  isVehicleBaseRateAutomated: boolean;
  baseRateAmtError?: string;
  setIsBaseRateAutomationClicked?: Dispatch<SetStateAction<boolean>>;
  setExisitngAutomatedBaseRate?: Dispatch<SetStateAction<BaseRateAutomation>>;
  isFarmedRoute: boolean;
};

const { Flat, Hourly } = BaseRateVariantEnum;
const { Active, Inactive } = BRAStatusEnum;

function BaseRatePricingField(props: BaseRatePricingFieldProps) {
  const {
    onBaseRateAmtChange,
    onHourlyBRHoursChange,
    BRAProps: {
      hasEnabledVehicleBRA,
      preexistingABR,
      preexistingABRLoading,
      hideBRA,
      ...BRAQueryVariables
    },
    baseRateAmtError,
    baseRateAmt,
    hourlyBaseRateHours: initialHourlyBaseRateHours,
    totalDurationMinutes,
    isVehicleBaseRateAutomated,
    setIsBaseRateAutomationClicked,
    setExisitngAutomatedBaseRate,
    isFarmedRoute,
  } = props;

  // queries
  const {
    automatedBaseRateLoading: isLoadingBRA,
    hasAutomatedBaseRate,
    automatedBaseRate,
  } = useAutomatedBaseRateQuery({
    queryVariables: BRAQueryVariables,
    hasEnabledVehicleBRA,
    preexistingABR,
    preexistingABRLoading,
    isFarmedRoute,
  });

  // hooks
  const {
    baseRateVariant,
    flatRate,
    hourlyBaseRate,
    hourlyBaseRateHours,
    onToggleBaseRateVariant,
    onFlatRateInput,
    onHourlyBaseRateInput,
    onHourlyBaseRateHoursInput,
    onActivateBRA,
  } = useBaseRateData({
    baseRateAmt,
    setIsBaseRateAutomationClicked,
    setExisitngAutomatedBaseRate,
    hourlyBaseRateHours: initialHourlyBaseRateHours,
    totalDurationMinutes: totalDurationMinutes,
    onBaseRateAmtChange: onBaseRateAmtChange,
    onHoursChange: onHourlyBRHoursChange,
  });
  const location = useLocation();
  const { track } = useAnalytics();

  // event handlers
  const handleActivateBRAClick = () => {
    const isQuote = /quote/i.test(location.pathname);
    track(isQuote ? "quote_braButtonpressed" : "reservation_braButtonpressed");
    onActivateBRA(automatedBaseRate);
  };

  // derived state
  const isFlatRate = baseRateVariant === Flat;
  const isHourlyRate = baseRateVariant === Hourly;

  const BRAProps = {
    automatedBaseRate,
    hasAutomatedBaseRate,
    isLoadingBRA,
    BRAStatus: automatedBaseRate?.total === flatRate ? Active : Inactive,
  };

  return (
    <Box mb={2} display="flex">
      <Box flex={1}>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography variant="h4">Base Rate</Typography>

          {/* Base Rate Automation Calculations */}
          {!isFarmedRoute && !hideBRA && (
            <BRACalculationsButton
              vehicleId={BRAQueryVariables.vehicleId}
              baseRateAutomation={automatedBaseRate}
              isVehicleBaseRateAutomated={isVehicleBaseRateAutomated}
            />
          )}
        </Box>
        <Box display="flex" alignItems="center">
          {/* Flat/Hourly Toggle */}
          <Box mr={1} alignSelf="start" position="relative" top="9px">
            <BaseRateVariantToggle
              baseRateVariant={baseRateVariant}
              onToggle={onToggleBaseRateVariant}
            />
          </Box>

          {/* Flat Rate */}
          {isFlatRate && (
            <Box flex={1}>
              <FlatBaseRateField
                flatRate={flatRate}
                baseRateAmtError={baseRateAmtError}
                onFlatRateInput={onFlatRateInput}
                onActivateBRAClick={handleActivateBRAClick}
                BRAProps={BRAProps}
                isFarmedRoute={isFarmedRoute}
              />
            </Box>
          )}

          {/* Hourly Rate and Displayed Base Rate Total */}
          {isHourlyRate && (
            <HourlyBaseRateFields
              flatRate={flatRate}
              baseRateAmtError={baseRateAmtError}
              hourlyBaseRate={hourlyBaseRate}
              hourlyBaseRateHours={hourlyBaseRateHours}
              onHourlyBaseRateInput={onHourlyBaseRateInput}
              onHourlyBaseRateHoursInput={onHourlyBaseRateHoursInput}
              onActivateBRAClick={handleActivateBRAClick}
              BRAProps={BRAProps}
              isFarmedRoute={isFarmedRoute}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default BaseRatePricingField;
