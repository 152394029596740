import React from "react";

type SeatIconProps = {
  color?: string;
  size?: "small";
};

const SeatIcon = (props: SeatIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.46964 2.976C6.35073 2.68647 6.15173 2.43949 5.8975 2.2659C5.64327 2.09232 5.34509 1.99984 5.04019 2H3.77313C3.66724 2.0008 3.56262 2.02386 3.46566 2.06778C3.36871 2.11169 3.28145 2.17553 3.20923 2.2554C3.13701 2.33527 3.08134 2.42949 3.04563 2.5323C3.00992 2.6351 2.99491 2.74434 3.00152 2.85333L3.62212 12.5067C3.6484 12.9123 3.82339 13.2923 4.11155 13.5697C4.39971 13.847 4.77944 14.0009 5.17362 14H12.2314C12.5722 14.0007 12.9038 13.8856 13.1748 13.6726C13.4459 13.4596 13.6414 13.1604 13.7312 12.8213L13.9453 12.0101C14.0138 11.7512 14.018 11.4787 13.9577 11.2177C13.8973 10.9566 13.7742 10.7155 13.5998 10.5163C13.4253 10.3172 13.2051 10.1665 12.9594 10.0782C12.7136 9.98979 12.4503 9.96658 12.1936 10.0107L7.6012 10.8C7.89083 8.13488 7.50078 5.43796 6.46964 2.976Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.36217 4.464C9.17839 4.0297 8.87085 3.65923 8.47796 3.39886C8.08506 3.13848 7.62423 2.99976 7.15302 3H5.19484C5.03119 3.0012 4.8695 3.0358 4.71966 3.10167C4.56982 3.16754 4.43497 3.2633 4.32336 3.3831C4.21175 3.50291 4.12571 3.64423 4.07052 3.79844C4.01533 3.95265 3.99213 4.11651 4.00235 4.28L4.96146 18.76C5.00207 19.3684 5.27251 19.9385 5.71785 20.3545C6.16319 20.7706 6.75005 21.0014 7.35923 21H18.2667C18.7934 21.001 19.3058 20.8284 19.7247 20.5089C20.1437 20.1893 20.4458 19.7406 20.5845 19.232L20.9154 18.0152C21.0213 17.6268 21.0279 17.2181 20.9346 16.8265C20.8413 16.435 20.651 16.0732 20.3814 15.7745C20.1118 15.4758 19.7715 15.2498 19.3917 15.1172C19.012 14.9847 18.605 14.9499 18.2083 15.016L11.1109 16.2C11.5586 12.2023 10.9558 8.15695 9.36217 4.464Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SeatIcon;
