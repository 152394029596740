import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { signInWithPopup, SAMLAuthProvider } from "firebase/auth";

import { Box } from "@mui/material";

import { useAuth, useSnackbar } from "../../globals/hooks";
import { auth } from "../../globals/utils/firebaseApp";
import SamlAuthButton from "../../components/buttons/SamlAuthButton";

// name of SAML provider in firebase
const samlProvider = "saml.netflix-canary";

axios.defaults.withCredentials = true;

function NetflixLoginPage() {
  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const location = useLocation<{ from: any }>();
  const { onLogin } = useAuth();

  const navigateTo =
    location?.state?.from &&
    !location?.state?.from.pathname.startsWith("/login")
      ? location.state.from
      : "/";

  const handleLoginWithSaml = async () => {
    try {
      const provider = new SAMLAuthProvider(samlProvider);
      const firebaseResponse = await signInWithPopup(auth, provider);

      const token = await firebaseResponse.user.getIdToken();

      const successful = await onLogin(token);

      if (successful) history.replace(navigateTo);
    } catch (error) {
      const errorMessage = error.response ? error.response.data : error.message;
      snackbar.error(errorMessage);
    }
  };

  const styles = {
    ssoBox: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: "32px",
      marginBottom: "32px",
    },
  };

  return (
    <>
      <Helmet>
        <title>Netflix Login | Moovs</title>
      </Helmet>
      <Box sx={styles.ssoBox}>
        <SamlAuthButton onClick={handleLoginWithSaml} />
      </Box>
    </>
  );
}

export default NetflixLoginPage;
