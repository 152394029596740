import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMutation } from "@apollo/client";
import { useDebounce } from "use-debounce";

import { Box, TextField, Typography } from "@mui/material";

import {
  LOAD_REQUEST_QUERY,
  UPDATE_ROUTE_DISPATCH_MUTATION,
} from "globals/graphql";
import { useSnackbar } from "globals/hooks";

type DriverNoteBlockProps = {
  requestId: string;
  routeId: string;
  driverNote: string;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
  setIsIndicatorVisible: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
};

const DriverNoteBlock = (props: DriverNoteBlockProps) => {
  const {
    requestId,
    routeId,
    driverNote,
    setSaveIndicatorState,
    setIsIndicatorVisible,
    disabled,
  } = props;

  // hooks
  const snackbar = useSnackbar();

  // state
  const [driverNoteInput, setDriverNoteInput] = useState<string>("");
  const [debouncedDriverNote] = useDebounce(driverNoteInput, 1000);
  const flag = useRef(false);
  const firstRender = useRef(true);

  // mutations
  const [updateRouteDispatch] = useMutation(UPDATE_ROUTE_DISPATCH_MUTATION, {
    onCompleted() {
      setSaveIndicatorState("saved");
      setTimeout(() => {
        setIsIndicatorVisible(false);
      }, 2000);
      flag.current = false;
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error("Error updating route");
      flag.current = false;
    },
    refetchQueries: [
      {
        query: LOAD_REQUEST_QUERY,
        variables: { id: requestId },
      },
    ],
    awaitRefetchQueries: true,
  });

  // event handlers
  const handleDebouncedDriverNote = useCallback(() => {
    setSaveIndicatorState("loading");
    setIsIndicatorVisible(true);

    updateRouteDispatch({
      variables: {
        input: {
          routeId,
          driverNote: debouncedDriverNote,
        },
      },
    });
  }, [
    setSaveIndicatorState,
    setIsIndicatorVisible,
    updateRouteDispatch,
    routeId,
    debouncedDriverNote,
  ]);

  const handleDriverNoteChange = (event: any) => {
    setDriverNoteInput(event.target.value);
  };

  // effects
  // reset on routeId change since the component doesn't re-render
  useEffect(() => {
    flag.current = false;
    firstRender.current = true;
    setDriverNoteInput("");
  }, [routeId]);

  // if first render, set driverNote
  useEffect(() => {
    if (!driverNoteInput && driverNote && firstRender.current) {
      flag.current = false;
      firstRender.current = false;
      setDriverNoteInput(driverNote);
    }
  }, [setDriverNoteInput, driverNote, driverNoteInput]);

  // update on debounce input
  useEffect(() => {
    if (!flag.current) {
      flag.current = true;
    } else {
      if (
        // don't update if 1) input & debounce aren't sync OR 2) no change between driverNote and debounce
        driverNoteInput !== debouncedDriverNote ||
        debouncedDriverNote === driverNote
      ) {
        return;
      }
      handleDebouncedDriverNote();
    }
  }, [
    driverNoteInput,
    debouncedDriverNote,
    driverNote,
    handleDebouncedDriverNote,
  ]);

  return (
    <Box>
      <Box mb={1}>
        <Typography variant="overline">Driver Note</Typography>
      </Box>
      {
        <Box>
          <TextField
            disabled={disabled}
            fullWidth
            multiline
            placeholder="Add Driver Note"
            variant="outlined"
            value={driverNoteInput}
            onChange={handleDriverNoteChange}
          />
        </Box>
      }
    </Box>
  );
};

export default DriverNoteBlock;
