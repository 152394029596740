import React from "react";
import moment from "moment";

import { Box, Typography } from "@mui/material";

import { TrackedFlight, TransitStop } from "types";
import { PrioritizedFlightInfo } from "..";
import { getFlightTimePriorityQueue } from "components/flights/helpers/flightTimeHelpers";

type FlightTrackerDetailsProps = {
  transitStopType: "origin" | "destination";
  trackedFlight: TrackedFlight;
  transitStop: TransitStop;
};

function FlightTrackerDetails(props: FlightTrackerDetailsProps) {
  const { transitStop, transitStopType, trackedFlight } = props;

  // derived variables
  const { origin, destination, flightStatus } = trackedFlight;
  const isDestination = transitStopType === "destination";
  const isCancelledFlight = flightStatus === "CANCELLED";
  const flightTimePriorityQueue = getFlightTimePriorityQueue(
    isDestination ? destination : origin
  );
  const { scheduledGate, scheduledTerminal, actualGate, actualTerminal } =
    transitStop;

  // helpers
  const flightDateOrTime = (dateTime, mode: "date" | "time") =>
    moment(dateTime).format(mode === "date" ? "M/D/YY" : "h:mmA");

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        variant="overline"
        sx={{ mt: 2, ...(isDestination && { textAlign: "right" }) }}
      >
        {flightTimePriorityQueue[0].timeLabel}
      </Typography>
      <PrioritizedFlightInfo
        transitStopType={transitStopType}
        primaryText={flightDateOrTime(flightTimePriorityQueue[0].time, "date")}
        secondaryText={
          flightTimePriorityQueue?.[1]
            ? flightDateOrTime(flightTimePriorityQueue[1].time, "date")
            : ""
        }
        primaryProps={{
          variant: "h5",
          ...(isDestination && { textAlign: "right" }),
          style: { fontWeight: 500 },
        }}
        isTime
        strikethroughAll={isCancelledFlight}
      />
      <PrioritizedFlightInfo
        transitStopType={transitStopType}
        primaryText={flightDateOrTime(flightTimePriorityQueue[0].time, "time")}
        secondaryText={
          flightTimePriorityQueue?.[1]
            ? flightDateOrTime(flightTimePriorityQueue[1].time, "time")
            : ""
        }
        primaryProps={{
          variant: "h2",
          ...(isDestination && { textAlign: "right" }),
        }}
        isTime
        strikethroughAll={isCancelledFlight}
      />

      <Typography
        variant="overline"
        sx={{ mt: 1, ...(isDestination && { textAlign: "right" }) }}
      >
        TERMINAL
      </Typography>

      <PrioritizedFlightInfo
        transitStopType={transitStopType}
        primaryText={actualTerminal}
        secondaryText={scheduledTerminal}
        primaryProps={{
          variant: "h3",
          ...(isDestination && { textAlign: "right" }),
        }}
        strikethroughAll={isCancelledFlight}
      />

      <Typography
        variant="overline"
        sx={{ mt: 1, ...(isDestination && { textAlign: "right" }) }}
      >
        GATE
      </Typography>
      <PrioritizedFlightInfo
        transitStopType={transitStopType}
        primaryText={actualGate}
        secondaryText={scheduledGate}
        primaryProps={{
          variant: "h3",
          ...(isDestination && { textAlign: "right" }),
        }}
        strikethroughAll={isCancelledFlight}
      />
    </Box>
  );
}

export default FlightTrackerDetails;
