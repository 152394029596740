import JsFileDownloader from "js-file-downloader";
import { stringify } from "querystring";
import { getServerLink } from "./getServerLink";

export const downloader = async (
  endpoint: string,
  params?:
    | {
        startDate: string | number;
        endDate: string | number;
        companyId?: string;
      }
    | { id: string },
  filename?: string
) => {
  return new JsFileDownloader({
    forceDesktopMode: true,
    withCredentials: true,
    nativeFallbackOnError: true,
    timeout: 270000,
    headers: [
      {
        name: "timezoneOffset",
        value: new Date().getTimezoneOffset().toString(),
      },
    ],
    url: getServerLink() + `${endpoint}?${stringify(params)}`,
    ...(filename && { filename }),
  });
};
