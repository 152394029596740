import React, { useState } from "react";

import { LinkIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import { useAnalytics, useScreenSize } from "globals/hooks";
import { MenuItemOption } from "components/MoovsEllipsisMenu/components/EllipsisMenuItem";

function useLinksClick(): MenuItemOption & {
  open: boolean;
  onClose: () => void;
} {
  // hooks
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();

  // state
  const [copyLinksDialogOpen, setCopyLinksDialogOpen] = useState(false);

  // event handlers
  const handleCopyLinksDialogOpen = () => {
    track("reservation_linksModalOpened");
    setCopyLinksDialogOpen(true);
  };

  const handleCopyLinksDialogClose = () => {
    setCopyLinksDialogOpen(false);
  };

  return {
    onClick: handleCopyLinksDialogOpen,
    text: isMobileView ? "View Links" : "Links",
    icon: <LinkIcon color={grayDark} size="small" />,
    hideOption: !isMobileView,
    open: copyLinksDialogOpen,
    onClose: handleCopyLinksDialogClose,
  };
}

export { useLinksClick };
