import { useQuery } from "@apollo/client";
import { LOAD_TRACKED_VEHICLES_QUERY } from "globals/graphql";
import { Vehicle } from "types";

function useTrackedVehicles() {
  // hooks
  const { data, loading, error } = useQuery(LOAD_TRACKED_VEHICLES_QUERY, {
    fetchPolicy: "cache-first",
  });

  return {
    loading,
    error,
    vehicles: (data?.loadTrackedVehicles || []) as Vehicle[],
  };
}

export { useTrackedVehicles };
