import React from "react";

type AccessMoovsDriverAppIconProps = {
  color?: string;
};

const AccessMoovsDriverAppIcon = (props: AccessMoovsDriverAppIconProps) => {
  const { color } = props;

  return (
    <svg
      width="38"
      height="39"
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66667 34.2857L12.9998 34.2857M21.3333 23.4524V34.4306C21.3333 35.2762 21.0092 36.0872 20.4321 36.6852C19.8551 37.2831 19.0725 37.619 18.2564 37.619H4.41026C3.5942 37.619 2.81158 37.2831 2.23454 36.6852C1.65751 36.0872 1.33333 35.2762 1.33333 34.4306V4.14077C1.33333 3.29515 1.65751 2.48417 2.23454 1.88623C2.81158 1.28829 3.5942 0.952363 4.41026 0.952363H16.5897C16.8386 0.952128 17.0866 0.983176 17.3282 1.04483M21.3333 30.9524H1.33333M13 7.61903H1.33333M9.66667 4.2857L13 4.2857M21.3333 11.5699L24.6667 15.119L31.3333 7.61903M36.3333 10.9524C36.3333 16.4752 31.8562 20.9524 26.3333 20.9524C20.8105 20.9524 16.3333 16.4752 16.3333 10.9524C16.3333 5.42952 20.8105 0.952363 26.3333 0.952363C31.8562 0.952363 36.3333 5.42952 36.3333 10.9524Z"
        stroke={color || "#1E1E1E"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AccessMoovsDriverAppIcon;
