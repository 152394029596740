import React, { useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import { CrossIcon, WarningIcon } from "../../../../design-system/icons";
import { granite, grayDark, grayLight } from "../../../../design-system/colors";
import CircleGreenCheckIcon from "../../../../design-system/icons/CircleGreenCheckIcon";
import CopyButton from "../../../../design-system/components/CopyButton";
import MoovsDialog from "../../../MoovsDialog";

type CustomDomainInformationProps = {
  removeSendgridCustomDomain: any;
  validateSendgridCustomDomain: any;
  setSaveIndicatorState: (state: string) => void;
  customDomain: string;
  customDomainSendgridData: any;
  customDomainIsAuthenticated: boolean;
};

function CustomDomainInformation(props: CustomDomainInformationProps) {
  const {
    removeSendgridCustomDomain,
    validateSendgridCustomDomain,
    setSaveIndicatorState,
    customDomain,
    customDomainSendgridData,
    customDomainIsAuthenticated,
  } = props;

  const customDomainId = customDomainSendgridData?.id;
  const dkim1 = customDomainSendgridData?.dkim1;
  const dkim2 = customDomainSendgridData?.dkim2;
  const mailCname = customDomainSendgridData?.mailCname;

  // state
  const [addedRecordsCheck, setAddedRecordsCheck] = useState(false);
  const [
    removeCustomDomainDialogOpen,
    setRemoveCustomDomainDialogOpen,
  ] = useState(false);

  // event handlers
  const handleValidateCheckboxChange = (event) => {
    setAddedRecordsCheck(event.target.checked);
  };

  const handleValidateCustomDomainChange = () => {
    setSaveIndicatorState("loading");
    validateSendgridCustomDomain({
      variables: {
        input: { customDomainId: `${customDomainId}` }, //weird?
      },
    });
  };

  const handleRemoveCustomDomainClick = () => {
    removeSendgridCustomDomain({
      variables: {
        input: {},
      },
    });
  };

  return <>
    <Box display="flex" flexDirection="column">
      <Box
        mb={1.5}
        p={2}
        border={`1px solid ${grayLight}`}
        borderRadius="4px"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <Typography variant="body2">{customDomain}</Typography>
          </Box>
          {!customDomainIsAuthenticated ? (
            <>
              <Box mr={1}>
                <WarningIcon />
              </Box>
              <Typography style={{ color: grayDark }} variant="caption">
                Not connected
              </Typography>
            </>
          ) : (
            <>
              <Box mr={1}>
                <CircleGreenCheckIcon />
              </Box>
              <Typography style={{ color: grayDark }} variant="caption">
                Connected
              </Typography>
            </>
          )}
        </Box>

        <Box display="flex">
          <Tooltip title="Remove Custom Domain">
            <IconButton onClick={() => setRemoveCustomDomainDialogOpen(true)} size="large">
              <CrossIcon color={granite} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {/* Header */}
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <Typography variant="overline">Type</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="overline">Host</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="overline">Points To</Typography>
        </Grid>
      </Grid>

      {/* CNAME #1 */}
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <Typography variant="body2">CNAME</Typography>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center">
            <Typography variant="body2">{mailCname.host}</Typography>
            <CopyButton copyText={mailCname.host} />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container alignItems="center">
            <Typography variant="body2">{mailCname.pointsTo}</Typography>
            <CopyButton copyText={mailCname.pointsTo} />
          </Grid>
        </Grid>
      </Grid>

      <Divider />

      {/* CNAME #2 */}
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <Typography variant="body2">CNAME</Typography>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center">
            <Typography variant="body2">{dkim1.host}</Typography>
            <CopyButton copyText={dkim1.host} />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container alignItems="center">
            <Typography variant="body2">{dkim1.pointsTo}</Typography>
            <CopyButton copyText={dkim1.pointsTo} />
          </Grid>
        </Grid>
      </Grid>

      <Divider />

      {/* CNAME #3 */}
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <Typography variant="body2">CNAME</Typography>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center">
            <Typography variant="body2">{dkim2.host}</Typography>
            <CopyButton copyText={dkim2.host} />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container alignItems="center">
            <Typography variant="body2">{dkim2.pointsTo}</Typography>
            <CopyButton copyText={dkim2.pointsTo} />
          </Grid>
        </Grid>
      </Grid>

      {/* Validation Area */}
      {!customDomainIsAuthenticated && (
        <Box
          display="flex"
          flexDirection="row"
          flex="1"
          py={1.5}
          height="88px"
          alignItems="center"
          alignSelf="flex-end"
        >
          <Box mr={0.5}>
            <Checkbox
              color="primary"
              value={addedRecordsCheck}
              checked={addedRecordsCheck}
              onChange={handleValidateCheckboxChange}
            />
          </Box>

          <Box mr={2}>
            <Typography variant="body2">I've added these records.</Typography>
          </Box>

          <Box display="flex" flex="1">
            <Button
              onClick={handleValidateCustomDomainChange}
              color="primary"
              variant="contained"
              disabled={!addedRecordsCheck}
            >
              Verify
            </Button>
          </Box>
        </Box>
      )}
    </Box>

    {/* Remove Custom Domain Dialog */}
    <MoovsDialog
      hideTopBorder
      size="xs"
      open={removeCustomDomainDialogOpen}
      onClose={() => setRemoveCustomDomainDialogOpen(false)}
      onAccept={handleRemoveCustomDomainClick}
      dialogTitle="Are you sure?"
      acceptButtonText="Remove Custom Domain"
      closeButtonText="Close"
    >
      <Box mb={3}>This action will remove your custom domain from Moovs.</Box>
    </MoovsDialog>
  </>;
}

export default CustomDomainInformation;
