import React, { ChangeEvent, MutableRefObject } from "react";

import { Box, InputBase } from "@mui/material";

type CustomMessageInputBaseProps = {
  messageRef: MutableRefObject<HTMLInputElement>;
  messageValue: string;
  placeholder?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

function CustomMessageInputBase(props: CustomMessageInputBaseProps) {
  const { messageRef, messageValue, placeholder, onChange } = props;

  return (
    <Box
      display="flex"
      height="100%"
      minHeight="210px"
      maxHeight="100%"
      onClick={() => messageRef.current.focus()}
      sx={{
        alignItems: "flex-start",
        cursor: "text",
        overflowX: "hidden",
      }}
      pt={1.5}
    >
      <InputBase
        inputRef={messageRef}
        name="message"
        value={messageValue}
        placeholder={placeholder || "Type your message here ..."}
        multiline
        fullWidth
        onChange={onChange}
      />
    </Box>
  );
}

export default CustomMessageInputBase;
