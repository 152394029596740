import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { TextField, InputAdornment, Select, MenuItem } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import {
  DataGridPro,
  GridCellEditCommitParams,
  LicenseInfo,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";

import { LoadFilterableDispatchTripsConnection } from "types";
import { useSnackbar, useAnalytics } from "globals/hooks";
import { UPDATE_ROUTE_MUTATION } from "globals/graphql";
import { useTripsViewConfig } from "./hooks/useTripsViewConfig";
import { moovsBlue, white, grayMedium } from "design-system/colors";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { CustomColumnsPanel, CustomLoadingOverlay } from "./components";

// set license
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

type TripDataGridV2Props = {
  tripData: LoadFilterableDispatchTripsConnection;
  loading: boolean;
  onFetchMore: () => void;
  refetchTripsData: () => void;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"loading" | "default" | "error" | "saved">
  >;
};

function TripDataGridV2(props: TripDataGridV2Props) {
  const { loading, onFetchMore, refetchTripsData, setSaveIndicatorState } =
    props;

  // hooks
  const apiRef = useGridApiRef();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const {
    columns,
    onColumnVisibilityChange,
    onColumnOrderChange,
    onColumnWidthChange,
  } = useTripsViewConfig({ apiRef, setSaveIndicatorState, refetchTripsData });

  // derived state
  const tripData = useMemo(() => {
    return props.tripData.edges.map(({ node }) => node);
  }, [props.tripData]);

  // event handlers
  const handleOnRowsScrollEnd = () => {
    if (tripData.length < props.tripData.totalCount) {
      onFetchMore();
    }
  };

  // mutations
  const [updateRoute] = useMutation(UPDATE_ROUTE_MUTATION, {
    onCompleted() {
      track("reservationTripView_driverNoteUpdated");
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      const errorMessage =
        getErrorMessage(error) || "Error updating driver note.";
      snackbar.error(errorMessage);
    },
  });

  const handleRowEditCommit = useCallback(
    async (params: GridCellEditCommitParams) => {
      // @ts-ignore fix later
      const { row, value, field } = params;

      // commit changes server-side
      if (field === "driverNote") {
        setSaveIndicatorState("loading");

        updateRoute({
          variables: {
            input: {
              id: row.routes[0].id,
              driverNote: value,
            },
          },
        });
      }
    },
    [updateRoute, setSaveIndicatorState]
  );

  const [searchText, setSearchText] = React.useState("");

  const [searchColumn, setSearchColumn] = React.useState('driverRow');

  const handleSearch = useCallback((searchValue: string) => {
    setSearchText(searchValue);
    
    apiRef.current.setFilterModel({
      items: searchValue ? [{
        id: 'quickSearch',
        value: searchValue,
        columnField: searchColumn,
        operatorValue: 'contains'
      }] : []
    });
  }, [apiRef, searchColumn]);

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        display: 'flex',
        flexDirection: 'column',
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
          borderTop: `1px solid ${grayMedium}`,
        },
        "& .MuiDataGrid-virtualScroller": {
          // to account for the fixed position footer so that the last row is also visible
          paddingBottom: "52px",
        },
        "& .MuiDataGrid-cell--editable: hover": {
          cursor: "pointer",
        },
        "& .MuiDataGrid-cell--editing": {
          border: `2px solid ${moovsBlue}`,
          borderRadius: "4px",
        },
        "& .MuiDataGrid-footerContainer": {
          position: "fixed",
          bottom: 0,
          right: 0,
          width: "100%",
          paddingRight: 6, // to account for fab icon
          backgroundColor: white,
        },
        backgroundColor: white,
        "& .MuiTextField-root": {
          backgroundColor: white,
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              borderColor: moovsBlue,
            },
          },
        },
        "& .search-container": {
          display: 'flex',
          gap: 2,
          justifyContent: 'flex-end',
          width: '100%',
          mt: 1,
          mb: 1,
          pr: 2,
        },
        "& .MuiDataGrid-cell": {
          borderRight: `1px solid ${grayMedium}`,  // Light grey border
        },
        "& .MuiDataGrid-columnHeader": {
          borderRight: `1px solid ${grayMedium}`,
          borderBottom: `1px solid ${grayMedium}`,
        },
      }}
    >
      <Box className="search-container">
        <TextField
          variant="outlined"
          size="small"
          placeholder={`Search ${columns.find(c => c.field === searchColumn)?.headerName || ''}`}
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
          sx={{ width: 300 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        
        <Select
          size="small"
          value={searchColumn}
          onChange={(e) => setSearchColumn(e.target.value)}
          sx={{ minWidth: 200 }}
        >
          {columns.map((col) => (
            <MenuItem key={col.field} value={col.field}>
              {col.headerName}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <DataGridPro
        sx={{
          marginBottom: '50px',
        }}
        apiRef={apiRef}
        disableColumnPinning
        disableSelectionOnClick
        disableChildrenSorting
        hideFooterPagination
        disableMultipleColumnsSorting
        aria-label="Dispatch Table"
        paginationMode="server"
        sortingMode="client"
        loading={loading}
        onCellEditCommit={handleRowEditCommit}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        onColumnVisibilityChange={onColumnVisibilityChange}
        onColumnOrderChange={onColumnOrderChange}
        columns={columns}
        onColumnWidthChange={onColumnWidthChange}
        rows={tripData}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
          ColumnsPanel: CustomColumnsPanel,
          NoRowsOverlay: () => <div>No results found</div>
        }}
        rowCount={props.tripData.totalCount}
        filterMode="client"
      />
    </Box>
  );
}

export default TripDataGridV2;
