import React, { useMemo } from "react";
import { Pie } from "react-chartjs-2";
import startCase from "lodash/startCase";
import keys from "lodash/keys";
import first from "lodash/first";

import { currency } from "globals/utils/helpers";
import { paymentMethodPieChartColors } from "design-system/colors";

type PieChartType = {
  paymentBreakdownData: { [key: string]: number };
};

function PieChart(props: PieChartType) {
  const { paymentBreakdownData } = props;

  const pieChartData = useMemo(() => {
    if (!paymentBreakdownData) return null;

    const sortedPaymentBreakdown = {};

    keys(paymentBreakdownData)
      .sort()
      .forEach((paymentMethod) => {
        if (!paymentBreakdownData[paymentMethod]) {
          return;
        }

        if (paymentMethod === "card" || paymentMethod === "generic-card") {
          sortedPaymentBreakdown["Credit Card"] =
            sortedPaymentBreakdown["Credit Card"] || 0;

          sortedPaymentBreakdown["Credit Card"] +=
            paymentBreakdownData[paymentMethod];
        } else {
          sortedPaymentBreakdown[startCase(paymentMethod)] =
            paymentBreakdownData[paymentMethod];
        }
      });

    const labels = Object.keys(sortedPaymentBreakdown);
    const data = Object.values(sortedPaymentBreakdown).map(
      (paymentData: number) => paymentData / 100
    );
    const backgroundColor = labels.map(
      (paymentMethod) => paymentMethodPieChartColors[paymentMethod].color
    );

    return {
      labels,
      datasets: [
        {
          backgroundColor,
          data,
        },
      ],
    };
  }, [paymentBreakdownData]);

  return (
    <Pie
      data={pieChartData}
      redraw={true}
      options={{
        maintainAspectRatio: false,
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
          },
        },
        tooltips: {
          displayColors: false,
          caretSize: 0,
          callbacks: {
            title: () => null,
            label: (tooltipItem) => {
              let label = first(pieChartData.datasets).data[tooltipItem.index];
              return `${currency(Number(label))}`;
            },
          },
        },
      }}
    />
  );
}

export default PieChart;
