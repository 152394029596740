import useWorkflowForm from "../steps/workflow/useWorkflowForm";
import useDateForm from "../steps/date/useDateForm";
import useDateRangeForm from "../steps/dateRange/useDateRangeForm";
import useVehicleForm from "../steps/vehicle/useVehicleForm";
import useTimesOfDayForm from "../steps/timesOfDay/useTimesOfDayForm";
import useAdjustAmtForm from "../steps/adjustAmt/useAdjustAmountForm";
import useRepeatRuleForm from "../steps/repeatRule/useRepeatRuleForm";
import useOverviewForm from "../steps/overview/useOverviewForm";
import { getFormComponentSteps } from "../utils/getFormComponentSteps";
import { getEventHandlerSteps } from "../utils/getEventHandlerSteps";

function useInitializeDynamicPricingForms(workflow: string) {
  // workflow
  const {
    handleWorkflowSubmit,
    workFlowFormComponent,
    setWorkflowOutline,
    resetWorkflowForm,
  } = useWorkflowForm();

  // specific date
  const { handleDateSubmit, dateFormComponent, resetDateForm } = useDateForm();

  // date range
  const { handleDateRangeSubmit, dateRangeFormComponent, resetDateRangeForm } =
    useDateRangeForm();

  // time range
  const {
    handleTimesOfDaySubmit,
    timesOfDayFormComponent,
    resetTimesOfDayForm,
  } = useTimesOfDayForm();

  // adjust amount
  const { handleAdjustAmtSubmit, adjustAmtFormComponent, resetAdjustAmtForm } =
    useAdjustAmtForm();

  // vehicles
  const { handleVehicleSubmit, vehicleFormComponent, resetVehicleForm } =
    useVehicleForm();

  // repeat rule
  const {
    handleRepeatRuleSubmit,
    repeatRuleFormComponent,
    resetRepeatRuleForm,
  } = useRepeatRuleForm();

  // overview
  const { handleOverviewSubmit, overviewFormComponent, resetOverviewForm } =
    useOverviewForm();

  const { formComponentSteps } = getFormComponentSteps({
    workflow,
    workFlowFormComponent,
    dateFormComponent,
    dateRangeFormComponent,
    timesOfDayFormComponent,
    adjustAmtFormComponent,
    vehicleFormComponent,
    repeatRuleFormComponent,
    overviewFormComponent,
  });

  const eventHandlerSteps = getEventHandlerSteps({
    workflow,
    handleWorkflowSubmit,
    handleDateSubmit,
    handleDateRangeSubmit,
    handleTimesOfDaySubmit,
    handleAdjustAmtSubmit,
    handleVehicleSubmit,
    handleRepeatRuleSubmit,
    handleOverviewSubmit,
  });

  const resetAllForms = () => {
    resetWorkflowForm();
    resetDateForm();
    resetDateRangeForm();
    resetTimesOfDayForm();
    resetAdjustAmtForm();
    resetVehicleForm();
    resetRepeatRuleForm();
    resetOverviewForm();
  };

  return {
    formComponentSteps,
    eventHandlerSteps,
    resetAllForms,
    setWorkflowOutline,
  };
}

export default useInitializeDynamicPricingForms;
