/**
 * @file graphql/User.ts
 * queries/mutations for User.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_ME_QUERY = gql`
  query Me {
    me {
      id
      firstName
      lastName
      email
      mobilePhone
      role {
        name
        slug
      }
      invitePending
      operatorId
      operator {
        id
        name
        nameSlug
        plan
        moovsNetworkEnabled
        customDomainIsValid
        onboardingCompleted
      }
      accessPermissions {
        id
        name
        path
        category
      }
      createdAt
    }
  }
`;

export const LOAD_USER_SETTINGS_QUERY = gql`
  query Me {
    me {
      id
      settings {
        id
        tripsViewConfig
        defaultScreen
      }
    }
  }
`;

export const UPDATE_USER_SETTINGS_MUTATION = gql`
  mutation UpdateUserSettings($input: UpdateUserSettingsInput!) {
    updateUserSettings(input: $input) {
      user {
        id
        settings {
          defaultScreen
          tripsViewConfig
        }
      }
    }
  }
`;

export const CREATE_OPERATOR_AND_USER_MUTATION = gql`
  mutation CreateOperator($input: CreateOperatorInput!) {
    createOperator(input: $input) {
      operator {
        id
        users {
          id
          role {
            name
            slug
          }
          invitePending
        }
      }
    }
  }
`;

export const LOAD_USER_QUERY = gql`
  query User($id: ID!) {
    node(id: $id) {
      id
      ... on User {
        id
        firstName
        lastName
        email
        mobilePhone
        mobilePhoneInternational
        invitePending
        role {
          name
          slug
        }
        settings {
          defaultScreen
        }
        accessPermissions {
          id
          path
          category
          name
          grantedByDefault
        }
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        firstName
        lastName
        email
        mobilePhone
        role {
          name
          slug
        }
        invitePending
        settings {
          defaultScreen
        }
      }
    }
  }
`;

export const CREATE_USER_AND_SEND_INVITE_MUTATION = gql`
  mutation createUserAndSendInvite($input: CreateUserAndSendInviteInput!) {
    createUserAndSendInvite(input: $input) {
      user {
        id
        firstName
        lastName
        email
        mobilePhone
        invitePending
        role {
          name
          slug
        }
        settings {
          defaultScreen
        }
        accessPermissions {
          id
          path
          category
          name
          grantedByDefault
        }
      }
    }
  }
`;

export const JOIN_TEAM_MUTATION = gql`
  mutation JoinTeam($input: JoinTeamInput!) {
    joinTeam(input: $input) {
      user {
        id
        invitePending
      }
    }
  }
`;

export const RESEND_USER_INVITE_MUTATION = gql`
  mutation ResendInvite($input: ResendInviteInput!) {
    resendInvite(input: $input) {
      __typename
    }
  }
`;

export const REMOVE_USER_MUTATION = gql`
  mutation RemoveUser($input: RemoveUserInput!) {
    removeUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const RESEND_INVITE_MUTATION = gql`
  mutation ResendInviteV2($input: ResendInviteV2Input!) {
    resendInviteV2(input: $input) {
      result {
        __typename
        ... on ResendInviteFailure {
          message
        }
      }
    }
  }
`;

export const LOAD_INVITE_PENDING_USER_QUERY = gql`
  query LoadInvitePendingUser($email: String, $userId: ID) {
    loadInvitePendingUser(email: $email, userId: $userId) {
      invitePending
      id
      email
    }
  }
`;

export const UPDATE_ONBOARDING_USER_MUTATION = gql`
  mutation UpdateOnboardingUser($input: UpdateOnboardingUserInput!) {
    updateOnboardingUser(input: $input) {
      user {
        id
        firstName
        lastName
        email
        mobilePhone
      }
    }
  }
`;

export const CREATE_USER_ACCESS_PERMISSION_MUTATION = gql`
  mutation CreateUserAccessPermission(
    $input: CreateUserAccessPermissionInput!
  ) {
    createUserAccessPermission(input: $input) {
      user {
        id
        accessPermissions {
          id
          path
          category
          name
          grantedByDefault
        }
      }
    }
  }
`;

export const REMOVE_USER_ACCESS_PERMISSION_MUTATION = gql`
  mutation RemoveUserAccessPermission(
    $input: RemoveUserAccessPermissionInput!
  ) {
    removeUserAccessPermission(input: $input) {
      user {
        id
        accessPermissions {
          id
          path
          category
          name
          grantedByDefault
        }
      }
    }
  }
`;
