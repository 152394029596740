import React from "react";
import { granite } from "design-system/colors";

type MoovsMarketIconProps = {
  color?: string;
  size?: "small";
};

const MoovsMarketIcon = (props: MoovsMarketIconProps) => {
  const { color } = props;

  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1428 20.4826H11.8648M1 21L6.74207 18.7915C7.10935 18.6502 7.29298 18.5796 7.46479 18.4874C7.6174 18.4055 7.76288 18.3109 7.89972 18.2047C8.05377 18.0852 8.19289 17.9461 8.47114 17.6678L20.1428 5.99619C21.2857 4.85325 21.2857 3.00016 20.1428 1.85721C18.9998 0.714264 17.1468 0.714263 16.0038 1.85721L4.33217 13.5288C4.05392 13.8071 3.91479 13.9462 3.79525 14.1003C3.68906 14.2371 3.59452 14.3826 3.5126 14.5352C3.42038 14.707 3.34975 14.8906 3.20849 15.2579L1 21ZM1 21L3.12963 15.463C3.28202 15.0668 3.35822 14.8687 3.48891 14.778C3.60313 14.6987 3.74445 14.6687 3.88103 14.6947C4.03732 14.7246 4.18741 14.8747 4.48759 15.1749L6.82517 17.5124C7.12535 17.8126 7.27544 17.9627 7.30528 18.119C7.33136 18.2556 7.30137 18.3969 7.22207 18.5111C7.13132 18.6418 6.93321 18.718 6.53699 18.8704L1 21Z"
        stroke={color || granite}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MoovsMarketIcon;
