import React, { useMemo } from "react";
import size from "lodash/size";

import { Box } from "@mui/material";

import { Trip } from "types";
import { useGetAutomatedBaseRate } from "components/requests/update/hooks";
import {
  ViewOnlyTripDetails,
  CollapseViewOnlyTripDetails,
} from "components/globals/ViewOnlyTripDetails";
import PricingInfoUpdateBlock from "../../../PricingInfoUpdateBlock";
import { getChildSeatOptionsFromVehicle } from "components/requests/create/CreateRequest/helpers/getChildSeatOptionsFromVehicle";

type PricingProps = {
  trip: Trip;
  tripRefetch: () => void;
  setSaveIndicatorState: (
    saveState: "loading" | "default" | "saved" | "error"
  ) => void;
};

function Pricing(props: PricingProps) {
  const { trip, tripRefetch, setSaveIndicatorState } = props;

  // hooks
  const BRAProps = useGetAutomatedBaseRate({ trip });

  // derived state
  const { id: routeId, pricing: routePricing } = trip.routes[0];
  const shouldCollapseDetails = size(trip.stops) > 3;

  // memo
  const childSeatOptions = useMemo(
    () => getChildSeatOptionsFromVehicle(trip.routes[0].vehicle),
    [trip.routes]
  );

  return (
    <Box>
      <Box mt={3}>
        {shouldCollapseDetails ? (
          <CollapseViewOnlyTripDetails trip={trip} />
        ) : (
          <ViewOnlyTripDetails trip={trip} />
        )}
      </Box>

      <PricingInfoUpdateBlock
        routePricing={routePricing}
        routeId={routeId}
        totalDuration={trip.totalDuration}
        setSaveIndicatorState={setSaveIndicatorState}
        refetchQuery={tripRefetch}
        BRAProps={BRAProps}
        addPricingLabelOverride="Additional Price Items"
        childSeatOptions={childSeatOptions}
      />
    </Box>
  );
}

export default Pricing;
