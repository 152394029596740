import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Box,
  Divider,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useSnackbar } from "../../globals/hooks/useSnackbar";
import GoogleAuthButton from "../../components/buttons/GoogleAuthButton";
import { CheckIcon, ViewIcon, ViewOffIcon } from "../../design-system/icons";
import {
  errorRed,
  granite,
  moovsBlueSelected,
  successGreen,
  white,
} from "../../design-system/colors";
import {
  useAnalytics,
  useGoogleTracking,
  useScreenSize,
} from "../../globals/hooks";
import useCreateOperatorAndSignUp from "pages/hooks/useCreateOperatorAndSignUp";
import MoovsLogoWhiteOnBlue from "../../design-system/images/icons/MoovsLogoWhiteOnBlue.svg";
import MoovsLogoText from "../../design-system/images/icons/MoovsLogoText.svg";
import OnboardingStepper from "./components/OnboardingStepper/OnboardingStepper";
import OnboardingImageCarousel from "./components/OnboardingImageCarousel/OnboardingImageCarousel";

function CreateAnAccountPage() {
  // state
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [matchingPasswords, setMatchingPasswords] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [passwordErrors, setPasswordErrors] = useState("");
  const [emailErrors, setEmailErrors] = useState("");

  // hooks
  const { track } = useAnalytics();
  useGoogleTracking(); // initialize google tag manager for tracking hubspot form submission at end of onboarding flow
  const snackbar = useSnackbar();
  const { isMobileView, isSmallTabletView } = useScreenSize();
  const { onSignUp, onLogInWithGoogle } = useCreateOperatorAndSignUp({
    onLoading: setLoading,
    onError: (error) => {
      if (error === "Email isn't valid") {
        setEmailErrors(error);
      } else {
        setPasswordErrors(error);
      }
    },
    credentials,
  });
  const history = useHistory();

  // effects
  useEffect(() => {
    if (credentials.password === credentials.confirmPassword) {
      setMatchingPasswords(true);
    } else {
      setMatchingPasswords(false);
    }
  }, [credentials.password, credentials.confirmPassword]);

  useEffect(() => {
    track("onboarding_createAccountInitiated");
  }, [track]);

  const resetPasswordErrors = () => {
    setPasswordErrors("");
    setEmailErrors("");
  };

  const validateFormInputs = () => {
    const emailError = !credentials.email ? "Email is required" : null;

    const passwordError =
      credentials.password !== credentials.confirmPassword
        ? "Passwords must match"
        : credentials.password.length < 8
        ? "Password must be 8 characters"
        : !credentials.password.match(/^(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).+$/)
        ? "Password must have 1 uppercase letter and 1 special character"
        : null;

    if (!emailError && !passwordError) {
      onSignUp();
      resetPasswordErrors();
      return;
    }

    setEmailErrors(emailError);
    setPasswordErrors(passwordError);

    snackbar.error(emailError || passwordError, { hideBoxShadow: true });
  };

  const handleChange = (e) => {
    e.persist();
    resetPasswordErrors();
    setCredentials((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      validateFormInputs();
    }
  };

  return (
    <Box display="flex" {...(isSmallTabletView && { height: "100vh" })}>
      <Box
        flex={1}
        mt={4}
        mx={4}
        flexDirection="column"
        justifyContent="space-between"
        display="flex"
        gap={8}
      >
        <Box display="flex">
          <Box
            component="img"
            sx={{ logo: { height: 45 } }}
            src={MoovsLogoWhiteOnBlue}
            alt="MoovsLogoWhiteOnBlue"
          />
          <Box
            component="img"
            ml={1.5}
            sx={{ logo: { height: 45 } }}
            src={MoovsLogoText}
            alt="MoovsLogoText"
          />
        </Box>
        <Box>
          <Typography variant="h1">Create an Account</Typography>
          <Typography variant="body1" my={1} color={granite}>
            Get free access in less than 1 minute
          </Typography>
          <Box display="flex" justifyContent="center" my={3}>
            <GoogleAuthButton isSignup onClick={onLogInWithGoogle} />
          </Box>
          <Box>
            <Divider
              sx={{
                backgroundColor: moovsBlueSelected,
                margin: 2,
              }}
            >
              <Typography variant="overline">Or</Typography>
            </Divider>
          </Box>
          <TextField
            fullWidth
            error={!!emailErrors}
            variant="outlined"
            value={credentials.email}
            label="Email"
            name="email"
            onChange={handleChange}
            sx={{ backgroundColor: white }}
          />
          <Box mt={1}>
            <TextField
              type={showPassword ? "text" : "password"}
              error={!!passwordErrors}
              fullWidth
              variant="outlined"
              value={credentials.password}
              label="Choose Password"
              name="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      tabIndex={-1}
                      onClick={() => setShowPassword(!showPassword)}
                      size="large"
                    >
                      {showPassword ? (
                        <ViewOffIcon size="small" />
                      ) : (
                        <ViewIcon size="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              sx={{ backgroundColor: white }}
            />
          </Box>
          <Box mt={1}>
            <TextField
              type={showConfirmPassword ? "text" : "password"}
              error={!!passwordErrors}
              fullWidth
              variant="outlined"
              value={credentials.confirmPassword}
              label="Confirm Password"
              name="confirmPassword"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!passwordErrors &&
                      matchingPasswords &&
                      !!credentials.confirmPassword && (
                        <CheckIcon size="small" color={successGreen} />
                      )}
                    <IconButton
                      tabIndex={-1}
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      size="large"
                    >
                      {showConfirmPassword ? (
                        <ViewOffIcon size="small" />
                      ) : (
                        <ViewIcon size="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              sx={{ backgroundColor: white }}
            />
          </Box>
          <Box mt={1}>
            <Box mb={3} display="flex" flexDirection="column">
              <Typography
                color={passwordErrors ? errorRed : granite}
                variant="caption"
              >
                Password must be at least 8 characters, 1 uppercase letter, and
                1 special character
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              my={3}
            >
              <LoadingButton
                variant="contained"
                color="primary"
                loading={loading}
                fullWidth
                onClick={validateFormInputs}
              >
                Create Account
              </LoadingButton>
            </Box>
            <Typography
              variant="body1"
              sx={{
                color: granite,
                fontWeight: 400,
                textAlign: isMobileView ? "center" : "left",
              }}
            >
              {"Already have an account? "}
              <Link
                component="button"
                underline="none"
                variant="body1"
                color="primary"
                sx={{ fontWeight: 500, mb: 0.25 }}
                onClick={() => {
                  history.push("/login");
                }}
              >
                Log In
              </Link>
              {" instead."}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" alignContent="center">
          <OnboardingStepper activeStep={0} />
        </Box>
      </Box>
      {!isSmallTabletView && (
        <Box flex={2}>
          <OnboardingImageCarousel />
        </Box>
      )}
    </Box>
  );
}

export default CreateAnAccountPage;
