import React, { useState } from "react";
import copy from "clipboard-copy";

import { Box, Button, Popper, Tooltip, Typography } from "@mui/material";

import { grayLight, grayMedium, moovsBlue, white } from "design-system/colors";
import { CopyIcon, OpenIcon } from "design-system/icons";
import {
  useScreenSize,
  useOperator,
  useAnalytics,
  useSnackbar,
} from "globals/hooks";

function CustomerPortalLink() {
  // hooks
  const { isMediumTabletView } = useScreenSize();
  const operator = useOperator();
  const { track } = useAnalytics();
  const snackbar = useSnackbar();

  // state
  const [tooltipText, setTooltipText] = useState(
    "Copy customer link to clipboard"
  );

  const customerAppLink = `${process.env.REACT_APP_CUSTOMERAPP_URL}/${operator.nameSlug}/request/new`;

  // event handlers
  const handleCustomerPortalLinkClick = async () => {
    await handleCustomerPortalLinkCopy();
    setTooltipText("Copied!");
  };

  const handleCustomerPortalLinkCopy = async () => {
    await copy(customerAppLink);
    snackbar.success("Copied!");
    track("bookingToolLink_copied");
  };

  const handleCustomerPortalLinkOpen = () => {
    window.open(customerAppLink, "_blank");
    track("bookingTool_opened");
  };

  return (
    <Box display="flex" gap={1} flexDirection="column">
      <Box>
        <Typography variant="h2">Customer Portal Link</Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          This is the link to view your customer portal and can be shared with
          your customers
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection={isMediumTabletView ? "column" : "row"}
        alignItems="center"
        gap={2}
        width="100%"
        justifyContent="space-between"
      >
        {/* Customer Portal Link */}
        <Box
          flexGrow={1}
          sx={{
            padding: 2,
            borderRadius: "4px",
            backgroundColor: grayLight,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: grayMedium,
            },
          }}
          {...(isMediumTabletView && { width: "100%" })}
          onMouseEnter={() =>
            setTooltipText("Copy Customer Portal link to clipboard")
          }
          onClick={handleCustomerPortalLinkClick}
        >
          <Tooltip
            title={tooltipText}
            placement="top-end"
            PopperComponent={Popper}
            enterTouchDelay={0}
          >
            <Typography style={{ marginRight: "12px" }} variant="body2">
              {customerAppLink}
            </Typography>
          </Tooltip>
        </Box>

        <Box
          display="flex"
          gap={1.5}
          flexDirection={isMediumTabletView ? "column" : "row"}
          {...(isMediumTabletView && { width: "100%" })}
        >
          {/* Copy Button */}
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCustomerPortalLinkCopy}
            endIcon={<CopyIcon size="xs" color={moovsBlue} />}
          >
            Copy Link
          </Button>
          {/* Open Link Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleCustomerPortalLinkOpen}
            endIcon={<OpenIcon size="xs" color={white} />}
          >
            Open Link
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default CustomerPortalLink;
