/**
 * @file VehicleFeatures.tsx
 * Dynamically creates checkbox forms for vehicleFeatures
 *
 * components:
 *  VehicleFeatures
 *  VehicleFeaturesSection
 *  VehicleFeatureCheckbox
 *
 * author: jackv
 */

import React, { useMemo } from "react";

import { useQuery } from "@apollo/client";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
} from "@mui/material";

import { LOAD_VEHICLE_FEATURES_QUERY } from "../../globals/graphql";
import { VehicleFeature } from "../../types";

function VehicleFeatures({
  activeFeatures,
  handleVehicleFeaturesChange,
}: {
  activeFeatures: VehicleFeature[];
  handleVehicleFeaturesChange: (event: any) => void;
}) {
  const { data: vehicleFeaturesData } = useQuery(LOAD_VEHICLE_FEATURES_QUERY);

  const vehicleFeaturesSorted = useMemo(() => {
    if (!vehicleFeaturesData) return {};

    return vehicleFeaturesData.vehicleFeatures.reduce(
      (acc: any, { id, name, category }) => {
        if (!acc[category]) {
          acc[category] = [];
        }

        const active = activeFeatures.some(
          (feature: VehicleFeature) => feature.id === id
        );

        acc[category].push({
          id,
          name,
          category,
          active,
        });
        return acc;
      },
      {}
    );
  }, [vehicleFeaturesData, activeFeatures]);

  return (
    <Box>
      {Object.entries(vehicleFeaturesSorted).map(([category, features]) => (
        <VehicleFeaturesSection
          key={category}
          category={category}
          features={features}
          handleVehicleFeaturesChange={handleVehicleFeaturesChange}
        />
      ))}
    </Box>
  );
}

function VehicleFeaturesSection({
  category,
  features,
  handleVehicleFeaturesChange,
}: {
  category: string;
  features: any;
  handleVehicleFeaturesChange: (event: any) => void;
}) {
  return (
    <FormControl>
      <Box pt={1} pb={1}>
        <FormLabel style={{ fontSize: 12 }}>{category.toUpperCase()}</FormLabel>
        <FormGroup>
          <Grid container>
            {features.map((feature: any) => (
              <VehicleFeatureCheckbox
                key={feature.id}
                handleVehicleFeaturesChange={handleVehicleFeaturesChange(
                  feature
                )}
                {...feature}
              />
            ))}
          </Grid>
        </FormGroup>
      </Box>
    </FormControl>
  );
}

function VehicleFeatureCheckbox({
  id,
  name,
  active,
  handleVehicleFeaturesChange,
}: {
  id: string;
  name: string;
  active: boolean;
  handleVehicleFeaturesChange: (e: any) => void;
}) {
  return (
    <Grid item xs={12} sm={6}>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            value={active}
            checked={active}
            onChange={handleVehicleFeaturesChange}
          />
        }
        label={name}
        labelPlacement="end"
      />
    </Grid>
  );
}

export default VehicleFeatures;
