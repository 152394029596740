import React, { useState, MouseEvent } from "react";
import { useMutation } from "@apollo/client";
import { useLocation } from "react-router";

import { Box, Button, Menu, MenuItem } from "@mui/material";

import { useSnackbar } from "../../globals/hooks/useSnackbar";
import {
  LOAD_REQUEST_QUERY,
  UPDATE_ROUTE_DISPATCH_MUTATION,
} from "../../globals/graphql";
import { rideStatusLabelColors, grayLight } from "../../design-system/colors";
import {
  StatusCircleIcon,
  StatusCircleFullIcon,
  ChevronDownIcon,
} from "../../design-system/icons";
import { DispatchStatusEnum } from "../../globals/utils/enumMaps";
import { useAnalytics, useScreenSize } from "../../globals/hooks";
import {
  fromGlobalId,
  getDispatchStatus,
  toGlobalId,
} from "../../globals/utils/helpers";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

const styles = {
  endIconOverride: {
    marginLeft: "auto",
    marginRight: 0,
  },
};

type RouteStatusSelectProps = {
  statusSlug: string;
  operatorRouteId: string;
  setSaveIndicatorState: (
    saveState: "loading" | "default" | "saved" | "error"
  ) => void;
  requestId?: string;
};

function RouteStatusSelect(props: RouteStatusSelectProps) {
  const { statusSlug, operatorRouteId, setSaveIndicatorState, requestId } =
    props;

  const routeId = operatorRouteId && fromGlobalId(operatorRouteId).id;

  // hooks
  const snackbar = useSnackbar();
  const location = useLocation();
  const { isMobileView } = useScreenSize();
  const isUpdateDispatchPath = location.pathname.startsWith("/dispatch/update");
  const { track } = useAnalytics();

  // state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // mutations
  const [updateRouteDispatch] = useMutation(UPDATE_ROUTE_DISPATCH_MUTATION, {
    onCompleted() {
      track("dispatch_statusUpdated");
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      const errorMessage =
        getErrorMessage(error) || "Error updating route status.";

      snackbar.error(errorMessage);
    },
    refetchQueries: [
      { query: LOAD_REQUEST_QUERY, variables: { id: requestId } },
    ],
    awaitRefetchQueries: true,
  });

  // event handlers
  const handleClickListItem = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRouteStatusClick = (slug: string) => {
    setAnchorEl(null);
    setSaveIndicatorState("loading");

    updateRouteDispatch({
      variables: {
        input: {
          routeId: toGlobalId(routeId, "Route"),
          statusSlug: slug,
        },
      },
    });
  };

  const { color, backgroundColor } = rideStatusLabelColors[statusSlug];

  return (
    <>
      <Button
        style={{
          color,
          backgroundColor,
          justifyContent: "start",
        }}
        onClick={handleClickListItem}
        startIcon={<StatusCircleFullIcon color={color} />}
        endIcon={<ChevronDownIcon color={color} />}
        sx={{ "& .MuiButton-endIcon": styles.endIconOverride }}
      >
        {getDispatchStatus(statusSlug, {
          shortened: isUpdateDispatchPath && isMobileView,
        })}
      </Button>
      <Menu
        keepMounted
        id="route-status-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {Object.entries(DispatchStatusEnum).map(([slug, name]) => {
          const active = slug === statusSlug;

          const menuItemColor = rideStatusLabelColors[slug].color;

          return (
            <MenuItem
              key={slug}
              onClick={() => handleRouteStatusClick(slug)}
              selected={active}
              sx={{
                margin: "0 8px",
                padding: "2px",
                borderRadius: "4px",
                height: 44,
                fontSize: 16,
                fontWeight: 500,
                ":hover": {
                  backgroundColor: grayLight,
                },
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
              >
                {active ? (
                  <StatusCircleFullIcon color={menuItemColor} />
                ) : (
                  <StatusCircleIcon color={menuItemColor} />
                )}
              </Box>
              <Box mx={1}>{name}</Box>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default RouteStatusSelect;
