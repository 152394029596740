import React, { Fragment, useState } from "react";

import { Box, Button, Divider, Typography } from "@mui/material";

import {
  ChevronDownIcon,
  ChevronUpIcon,
  InfoIcon,
} from "../design-system/icons";
import {
  black,
  moovsBlue,
  moovsBlueLight,
  moovsBlueSelected,
  white,
} from "../design-system/colors";
import { ActionName, Billing } from "../types";
import { useScreenSize } from "../globals/hooks";
import { currency } from "../globals/utils/helpers";

type LegacyTransactionsBlockProps = {
  billings: Billing[];
};

function LegacyTransactionsBlock(props: LegacyTransactionsBlockProps) {
  const { billings } = props;
  const [showTransactions, setShowTransactions] = useState(false);

  // hooks
  const { isMobileView } = useScreenSize();

  // event handlers
  const handleToggleTransactions = () => {
    setShowTransactions((prevTransactions) => !prevTransactions);
  };

  const totalAmt = billings.reduce((total, billing) => {
    return billing.action === ActionName.Add
      ? total + billing.amount
      : total - billing.amount;
  }, 0);

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor={moovsBlueSelected}
      border={`1px solid ${moovsBlueLight}`}
      px={isMobileView ? 2 : 3}
      py={2}
      borderRadius="4px"
    >
      <Box display="flex" mb={2}>
        <InfoIcon color={black} />
        <Typography variant="subtitle1" style={{ marginLeft: "11px" }}>
          Update Required
        </Typography>
      </Box>

      <Box mb={3.5}>
        <Typography variant="body2">
          This reservation has legacy transaction-based pricing and requires
          updating to the new pricing system. The total of all transactions is
          now added as the base rate.
        </Typography>
      </Box>

      <Box>
        <Button
          onClick={handleToggleTransactions}
          color="primary"
          style={{ width: "190px" }}
        >
          {showTransactions ? "Hide Transactions" : "View Transactions"}
          {showTransactions ? (
            <Box position="relative" top="7px" left="7px">
              <ChevronUpIcon color={moovsBlue} />
            </Box>
          ) : (
            <Box position="relative" top="4px" left="4px">
              <ChevronDownIcon color={moovsBlue} />
            </Box>
          )}
        </Button>
      </Box>

      {showTransactions && (
        <Box px={2} mt={2} borderRadius="4px" bgcolor={white}>
          <Box display="flex" justifyContent="space-between" py={2}>
            <Typography variant="overline">Note</Typography>
            <Typography variant="overline" align="right">
              Amount
            </Typography>
          </Box>
          <Divider />

          {billings.map((billing) => (
            <Fragment key={billing.id}>
              <Box display="flex" justifyContent="space-between" py={2}>
                <Typography
                  variant="body2"
                  style={{ flex: isMobileView ? 2 : 3 }}
                >
                  {billing.note}
                </Typography>
                <Typography variant="body2" style={{ flex: 1 }} align="right">
                  {`${billing.action === ActionName.Add ? "" : "-"}${currency(
                    billing.amount
                  )}`}
                </Typography>
              </Box>
              <Divider />
            </Fragment>
          ))}

          <Box display="flex" justifyContent="space-between" py={2}>
            <Typography variant="subtitle1">Total</Typography>
            <Typography variant="subtitle1" align="right">
              {currency(totalAmt)}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default LegacyTransactionsBlock;
