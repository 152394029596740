import { useState } from "react";
import { useMutation } from "@apollo/client";

import { REMOVE_INVOICE_MUTATION } from "globals/graphql";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

function useRemoveInvoice() {
  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // state
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [
    removeInvoice,
    { loading: removeInvoiceLoading, data: removedInvoiceData },
  ] = useMutation(REMOVE_INVOICE_MUTATION, {
    refetchQueries: ["LoadInvoices"],
    onCompleted(data) {
      track("invoice_deleted");
      setDeleteDialogOpen(false);
      snackbar.success(`Successfully deleted invoice!`);
    },
    onError(error) {
      const errorMessage = getErrorMessage(error) || "Error deleting invoice.";
      snackbar.error(errorMessage);
    },
  });

  return {
    removeInvoice,
    removeInvoiceLoading,
    removedInvoiceData,
    setDeleteDialogOpen,
    deleteDialogOpen,
  };
}

export default useRemoveInvoice;
