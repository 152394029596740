import React, { useState } from "react";
import isNumber from "lodash/isNumber";

import { Box, Typography } from "@mui/material";

import { black, grayDark, grayLight } from "design-system/colors";
import { PlanCardActionBtnVariant, PlanCardProps } from "./types";
import PlanCardActionButton from "./PlanCardActionButton";
import UpgradeSubscriptionDialog from "./UpgradeSubscriptionDialog";

function PlanCardDesktop(props: PlanCardProps) {
  const {
    icon,
    name,
    price,
    btnVariant,
    fontColor,
    bgColor,
    link,
    planLength,
  } = props;

  // state
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false);

  return (
    <Box display="flex" justifyContent="center" width="20%" pl="24px">
      <Box
        display="flex"
        flexDirection="column"
        minWidth="161px"
        maxWidth="161px"
        borderRadius="4px"
        padding="16px"
        sx={{
          ...(bgColor && { backgroundColor: bgColor }),
        }}
        {...(!bgColor && { border: `1px solid ${grayLight}` })}
      >
        {/* Icon Section */}
        <Box mb={1}>{icon}</Box>

        {/* Name Section */}
        <Box>
          <Typography variant="h3" sx={{ color: fontColor || black }}>
            {name}
          </Typography>
        </Box>

        {/* Price Section */}
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={0.5}
          mb={2}
        >
          {/* Only Enterprise Plans have either "demo" or "no-demo" variants */}

          {/* Additional wording for Enterprise package */}
          {isNumber(price) &&
            [
              PlanCardActionBtnVariant.DEMO,
              PlanCardActionBtnVariant.NO_DEMO,
            ].includes(btnVariant) && (
              <Typography variant="h6">From</Typography>
            )}

          {isNumber(price) && (
            <Typography variant="h2">{`$${price}`}</Typography>
          )}

          {isNumber(price) && (
            <Typography variant="caption" sx={{ color: grayDark }}>
              {[
                PlanCardActionBtnVariant.DEMO,
                PlanCardActionBtnVariant.NO_DEMO,
              ].includes(btnVariant)
                ? "/ mo"
                : "/ month"}
            </Typography>
          )}
        </Box>

        {/* Action Button Section */}
        <PlanCardActionButton
          variant={btnVariant}
          link={link}
          name={name}
          planLength={planLength}
          setUpgradeDialogOpen={setUpgradeDialogOpen}
        />

        <UpgradeSubscriptionDialog
          open={upgradeDialogOpen}
          onClose={() => setUpgradeDialogOpen(false)}
          planName={name}
          planLength={planLength}
          pricePerMonth={price}
          icon={icon}
          bgColor={bgColor}
        />
      </Box>
    </Box>
  );
}

export default PlanCardDesktop;
