import React from "react";
import { useLazyQuery } from "@apollo/client";

import { moovsBlue } from "design-system/colors";
import { LOAD_INVITE_PENDING_USER_QUERY } from "globals/graphql";
import { useSnackbar } from "./useSnackbar";
import { useResendInvite } from "./useResendInvite";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { useAnalytics } from ".";

type useInvitePendingUserProps = {
  onLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  onError?: React.Dispatch<React.SetStateAction<string>>;
};

function useInvitePendingUser(props: useInvitePendingUserProps) {
  const { onLoading, onError } = props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const { onResendInvite } = useResendInvite({
    onCompleted: () => track("unauthenticated_inviteEmailResent"),
  });

  const [loadInvitePendingUser] = useLazyQuery(LOAD_INVITE_PENDING_USER_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      onLoading && onLoading(false);
      const { invitePending, id } = data.loadInvitePendingUser;
      const errorMessage =
        "Member Invite Pending, check email to complete onboarding, ";

      if (invitePending) {
        onError && onError(errorMessage);

        snackbar.error(errorMessage, {
          linkLabel: "Resend Email",
          linkColor: moovsBlue,
          onLinkClick: onResendInvite(id),
          inlineLink: true,
        });
      }
    },
    onError: (error) => {
      let errorMessage;
      onLoading && onLoading(false);
      onError && onError(error.message);

      if (error.message === "Email not found") {
        errorMessage =
          "The email address or password you entered is incorrect. Please try again or create an account.";
      } else {
        errorMessage = getErrorMessage(error) || error.message;
      }

      snackbar.error(errorMessage);
    },
  });

  // event handlers
  const validateInvitePendingByEmail = (email: string) => () => {
    return loadInvitePendingUser({
      variables: {
        email,
      },
    });
  };

  const validateInvitePendingByUserId = (userId: string) => () => {
    return loadInvitePendingUser({
      variables: {
        userId,
      },
    });
  };

  return { validateInvitePendingByEmail, validateInvitePendingByUserId };
}

export { useInvitePendingUser };
