import React from "react";
import { Link, useLocation } from "react-router-dom";
import some from "lodash/some";

import { Badge, Box, ButtonBase, Typography } from "@mui/material";

import {
  granite,
  grayMedium,
  grayLight,
  black,
  moovsBlue,
  moovsBlueSelected,
} from "../../../colors";
import {
  useAnalytics,
  useScreenSize,
  useCurrentUser,
} from "../../../../globals/hooks";
import SquareNavButton from "./SquareNavButton";

type NavButtonProps = {
  itemsCount?: number;
  text: string;
  path: string;
  active: boolean;
  icon?: any;
  disabled?: boolean;
  mode?: "settings" | "reservations";
  isCollapsedItem?: boolean;
  onDrawerToggle?: () => void;
  permissionsOverride?: boolean;
};

function NavButton(props: NavButtonProps) {
  const {
    text,
    path,
    active,
    icon,
    disabled,
    mode,
    isCollapsedItem,
    onDrawerToggle,
    permissionsOverride,
    itemsCount = 0,
  } = props;

  // hooks
  const { pathname } = useLocation();
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();
  const currentUser = useCurrentUser();

  const userAccessPermissions = currentUser?.accessPermissions || [];

  const shouldShowNavButton =
    permissionsOverride ||
    some(userAccessPermissions, (permission) => path.includes(permission.path));

  const handleClick = () => {
    switch (path) {
      case "/settings/customer-portal":
        track("customerPortalSettings_opened");
        break;
      case "/settings/dynamic-pricing":
        track("settings_dynamicPricingOpened");
        break;
      case "/settings/zone-pricing":
        track("settings_zonePricingOpened");
        break;
      case "/settings/members":
        track("settings_membersOpened");
        break;
      case "/settings/drivers":
        track("settings_driversOpened");
        break;
      case "/settings/terms-and-conditions":
        track("settings_termsAndConditionsOpened");
        break;
      case "/settings/insurance":
        track("settings_insuranceOpened");
        break;
      case "/settings/cancellation":
        track("settings_cancellationOpened");
        break;
      case "/settings/moovs-market":
        track("market_opened");
        break;
      case "/settings/general":
        if (pathname.startsWith("/settings")) {
          // Clicking on 'general' button in navigation pannel of settings
          track("settings_generalOpened");
        } else {
          track("settings_opened");
        }
        break;
      case "/card":
        track("moovsCard_opened");
        break;
    }

    if (pathname.startsWith("/settings") || path !== "/settings/general") {
      onDrawerToggle && onDrawerToggle();
    }
  };

  const isSettings = mode === "settings";
  const isReservations = mode === "reservations";

  const navButtonStyles = {
    root: {
      display: "flex",
      cursor: "pointer !important",
      alignItems: "center",
      alignContent: "center",
      padding: "8px",
      marginBottom: "1px",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: isReservations ? "#f0f5ff" : grayLight,
      },
    },
    settingsRoot: {
      "&:hover": {
        backgroundColor: grayMedium,
      },
    },
    navText: {
      color: granite,
      fontWeight: 500,
    },
    navTextSmall: {
      fontWeight: 500,
      fontSize: 11,
    },
    activeSection: {
      backgroundColor: isSettings ? grayMedium : moovsBlueSelected,
      "&:hover": {
        backgroundColor: isSettings ? grayMedium : moovsBlueSelected,
      },
    },
    activeText: {
      color: isSettings ? black : moovsBlue,
    },
    disabled: {
      color: `${grayMedium} !important`,
    },
  };

  if (!shouldShowNavButton) {
    return null;
  }

  if (isMobileView || mode === "settings" || isCollapsedItem) {
    return (
      <ButtonBase
        component={Link}
        to={path}
        disabled={disabled}
        disableTouchRipple
        sx={{ display: "flex", flex: 1 }}
        onClick={handleClick}
      >
        <Box
          tabIndex={0}
          width="100%"
          sx={[
            navButtonStyles.root,
            mode === "settings" && navButtonStyles.settingsRoot,
            active && navButtonStyles.activeSection,
          ]}
        >
          {mode === "settings" ? null : icon}
          <Box ml={mode === "settings" ? 0 : 2}>
            <Typography
              component="span"
              sx={{
                ...navButtonStyles.navText,
                ...(active && navButtonStyles.activeText),
                ...(disabled && navButtonStyles.disabled),
              }}
            >
              {text}
            </Typography>
          </Box>
          <Badge
            badgeContent={itemsCount}
            color="error"
            sx={{ position: "absolute", right: 20 }}
            max={99}
          />
        </Box>
      </ButtonBase>
    );
  }

  return (
    <SquareNavButton
      text={text}
      icon={icon}
      path={path}
      disabled={disabled}
      active={active}
      onClick={handleClick}
      itemsCount={itemsCount}
    />
  );
}

export default NavButton;
