import React from "react";

import RoundChip from "design-system/components/chips/RoundChip";
import { rideStatusLabelColors } from "design-system/colors";
import { getDispatchStatus } from "globals/utils/helpers";

type DispatchStatusChipProps = {
  dispatchStatus: string;
  shortened?: boolean;
};

function DispatchStatusChip(props: DispatchStatusChipProps) {
  const { dispatchStatus, shortened = false } = props;

  return (
    <RoundChip
      inputProps={{ style: { cursor: "inherit" } }}
      label={getDispatchStatus(dispatchStatus, { upperCase: true, shortened })}
      {...rideStatusLabelColors[dispatchStatus]}
    />
  );
}

export default DispatchStatusChip;
