import React, { useState } from "react";

import { Box, Divider, Typography } from "@mui/material";

import DriverAutoComplete from "../../autocompletes/DriverAutoComplete";
import ProfileInfoCard from "../../globals/ProfileInfoCard";
import { TrashIcon } from "../../../design-system/icons/index";
import { Driver, RouteDriver } from "../../../types";

type FarmAffiliateDriverSectionType = {
  drivers: Partial<Driver>[];
  onDriverAdd: (driver: Driver | string) => void;
  onDriverRemove: (driverId: string) => void;
  canSelectMultipleDrivers?: boolean;
};

function FarmAffiliateDriverSection(props: FarmAffiliateDriverSectionType) {
  const { drivers, onDriverAdd, onDriverRemove, canSelectMultipleDrivers } =
    props;

  // state
  const [driverFieldValue, setDriverFieldValue] = useState("");

  // event handlers
  const handleDriverRemove = (driverId: string) => {
    onDriverRemove(driverId);
  };

  const handleDriverAdd = (routeDriver: RouteDriver) => {
    if (routeDriver) {
      onDriverAdd(routeDriver.driver);
    }
    setDriverFieldValue("");
  };

  const handleDriverInputChange = (value: string) => {
    setDriverFieldValue(value);
  };

  return (
    <>
      <Typography variant="h3">
        {canSelectMultipleDrivers ? "Drivers" : "Driver"}
      </Typography>
      <Box mb={2} mt={1}>
        <Divider />
      </Box>

      <Box mb={2}>
        {drivers.map((driver) => (
          <Box mb={2} key={driver.id}>
            <ProfileInfoCard
              headerText="Driver Contact"
              firstName={driver.firstName}
              lastName={driver.lastName}
              email={driver.email}
              phone={driver.mobilePhone}
              profilePhoto={driver.driverProfilePhoto}
              actionButtons={[
                {
                  icon: <TrashIcon />,
                  eventHandler: () => handleDriverRemove(driver.id),
                },
              ]}
            />
          </Box>
        ))}

        {(drivers.length === 0 || canSelectMultipleDrivers) && (
          <DriverAutoComplete
            blurOnSelect
            label="Link Driver"
            placeholder="Search Driver"
            onDriverAutoCompleteChange={handleDriverAdd}
            maxWidth="320px"
            inputProps={{
              inputValue: driverFieldValue,
              onInputChange: handleDriverInputChange,
            }}
            isFarmAffiliate
          />
        )}
      </Box>
    </>
  );
}

export default FarmAffiliateDriverSection;
