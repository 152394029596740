import React, { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useLazyQuery } from "@apollo/client";
import { DeepPartial } from "ts-essentials";

import { Box, Divider, TextField, Typography, Paper } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Company, Contact, MoovsNetworkOperator } from "../../types";
import { white } from "../../design-system/colors";
import { LOAD_CONTACTS_AND_FARM_AFFILIATES_AND_COMPANIES_QUERY } from "globals/graphql";
import {
  CompanyIcon,
  GriddnetOperatorIcon,
  MoovsNetworkOperatorIcon,
  MoovsAiOperatorIcon,
  UserIcon,
} from "design-system/icons";

type ContactAffiliateCompanyAutoCompleteProps = {
  value?: DeepPartial<Contact> | null;
  onChange: (newValue: Contact | MoovsNetworkOperator | Company) => void;
  error?: any;
  helperText?: string;
  label?: string;
};

function ContactAffiliateCompanyAutoComplete(
  props: ContactAffiliateCompanyAutoCompleteProps
) {
  const { value, onChange, error, helperText, label } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [contactOptions, setContactOptions] = useState([]);

  const [loadContactsAndAffiliatesAndCompanies, { loading, data }] =
    useLazyQuery(LOAD_CONTACTS_AND_FARM_AFFILIATES_AND_COMPANIES_QUERY, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const contactsList =
          data?.loadContactsAndFarmAffiliatesAndCompanies.edges.map(
            ({ node }) => node
          );
        setContactOptions(contactsList || []);
      },
    });

  const handlesearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event) setSearchTerm(event.target.value);
  };

  const debouncedGetContacts = useDebouncedCallback((searchTerm: string) => {
    loadContactsAndAffiliatesAndCompanies({
      variables: {
        searchTerm: searchTerm,
        limit: 20,
      },
    });
  }, 250);

  useEffect(() => {
    if (searchTerm) {
      debouncedGetContacts(searchTerm);
    }
  }, [searchTerm, debouncedGetContacts]);

  const paperComponent = ({ children }) => {
    return (
      <Paper>
        <Box px={2} pt={1}>
          <Typography sx={{ backgroundColor: white }} variant="overline">
            Add existing contact / affiliate / company
          </Typography>
        </Box>
        {children}
      </Paper>
    );
  };

  return (
    <Autocomplete
      autoHighlight
      id="transactions-from-contact"
      PaperComponent={paperComponent}
      clearOnEscape
      noOptionsText={
        (data && searchTerm && "No Contact or Affiliate or Company Found") ||
        "Type to search for a contact or affiliate or company"
      }
      filterOptions={(x) => x}
      loading={debouncedGetContacts.isPending() || loading}
      value={value}
      onChange={(_e: any, newValue: Contact | MoovsNetworkOperator | Company) =>
        onChange(newValue)
      }
      options={contactOptions}
      onInputChange={handlesearchTermChange}
      getOptionLabel={(contact) => {
        return contact.id
          ? contact.email || contact.companyEmail || contact.operatorEmail
          : "";
      }}
      renderInput={(params) => {
        const { InputProps, ...restParams } = params;
        return (
          <TextField
            {...restParams}
            fullWidth
            label={label || "Search for contact"}
            name="no-show"
            variant="outlined"
            error={error}
            helperText={helperText}
            InputProps={{
              ...InputProps,
              autoComplete: "new-password",
            }}
          />
        );
      }}
      renderOption={(props, option) => {
        const { name, email, phone, icon } = {
          MoovsNetworkOperator: {
            name: option.operatorName,
            email: option.operatorEmail,
            phone: option.operatorPhone,
            icon: <MoovsNetworkOperatorIcon size="small" />,
          },
          GriddnetOperator: {
            name: option.operatorName,
            email: option.operatorEmail,
            phone: option.operatorPhone,
            icon: <GriddnetOperatorIcon size="small" />,
          },
          MoovsAiOperator: {
            name: option.operatorName,
            email: option.operatorEmail,
            phone: option.operatorPhone,
            icon: <MoovsAiOperatorIcon size="small" />,
          },
          Contact: {
            name: `${option.firstName} ${option.lastName}`,
            email: option.email,
            phone: option.mobilePhone,
            icon: <UserIcon size="small" />,
          },
          Company: {
            name: option.name,
            email: option.companyEmail,
            phone: null,
            icon: <CompanyIcon size="small" strokeWidth="2" />,
          },
        }[option.__typename];

        return (
          <li {...props}>
            <Box>
              <Box display="flex">
                <Typography variant="h6" pr={1}>
                  {name}
                </Typography>
                {icon}
              </Box>
              <Typography variant="body2">{email || "-"}</Typography>
              {phone ? <Typography variant="body2">{phone}</Typography> : null}
            </Box>
            <Divider />
          </li>
        );
      }}
    />
  );
}

export default ContactAffiliateCompanyAutoComplete;
