import React, { ChangeEvent } from "react";

import { Box, Divider } from "@mui/material";

import { PaymentMethod } from "../../../../types";
import { useScreenSize } from "globals/hooks";
import TotalDisplay from "./TotalDisplay";
import AddNoteField from "./AddNoteField";
import TripChargesBreakdown from "./TripChargesBreakdown";
import { ChargePayerInputErrors, TripChargeDetails } from "../types";
import MethodSelectDropdown from "./MethodSelectDropdown/MethodSelectDropdown";
import DollarPercentInputField from "./DollarPercentInputField";
import { DollarPercentVariant } from "components/pricing/utils";

type ChargePayerFieldsProps = {
  inputDollarAmt: number;
  inputWholePercentValue: number;
  paymentMethods: PaymentMethod[];
  paymentMethodValue: string;
  preferredPaymentMethod: PaymentMethod;
  inputErrors: ChargePayerInputErrors;
  tripChargeDetailsList: TripChargeDetails[];
  onDollarInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onPercentInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onMethodInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onNoteInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onDollarPercentVariantChange: (
    dollarPercentVariant: DollarPercentVariant
  ) => void;
  note: string;
  companyPaymentMethods?: PaymentMethod[];
  defaultDollarPercentVariant?: DollarPercentVariant;
};

function ChargePayerFields(props: ChargePayerFieldsProps) {
  const {
    inputDollarAmt,
    inputWholePercentValue,
    paymentMethodValue,
    inputErrors,
    paymentMethods,
    onDollarInputChange,
    onPercentInputChange,
    onMethodInputChange,
    onNoteInputChange,
    onDollarPercentVariantChange,
    tripChargeDetailsList,
    preferredPaymentMethod,
    note,
    companyPaymentMethods,
    defaultDollarPercentVariant,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <>
      <Box mt={6} display="flex" flexDirection="column">
        <DollarPercentInputField
          onDollarInputChange={onDollarInputChange}
          onPercentInputChange={onPercentInputChange}
          onDollarPercentVariantChange={onDollarPercentVariantChange}
          defaultDollarPercentVariant={defaultDollarPercentVariant}
          inputErrors={inputErrors}
          inputDollarAmt={inputDollarAmt}
          inputWholePercentValue={inputWholePercentValue}
        />

        <Box flex={isMobileView ? 1 : 2} mt={isMobileView ? 2 : 4}>
          <MethodSelectDropdown
            methodError={inputErrors.method}
            preferredPaymentMethodId={preferredPaymentMethod?.card.id}
            onMethodInputChange={onMethodInputChange}
            paymentMethods={paymentMethods}
            companyPaymentMethods={companyPaymentMethods}
            paymentMethodValue={paymentMethodValue}
            label={"Payment Method"}
          />
        </Box>
      </Box>
      <Box my={3}>
        <Divider />
      </Box>
      <Box display="flex" flexDirection="column" mb={2}>
        <TripChargesBreakdown tripChargeDetailsList={tripChargeDetailsList} />
        <Box my={3}>
          <Divider />
        </Box>
        <TotalDisplay totalAmount={inputDollarAmt} />
      </Box>
      <Box mt={1} mb={3}>
        <AddNoteField
          note={note}
          noteError={inputErrors.note}
          onNoteChange={onNoteInputChange}
        />
      </Box>
    </>
  );
}

export default ChargePayerFields;
