import React from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/client";

import { Box, Typography, CircularProgress, Divider } from "@mui/material";

import { alabaster, black, grayMedium } from "../../design-system/colors";
import { LOAD_OPERATOR_QUERY, LOAD_ME_QUERY } from "../../globals/graphql";
import {
  CreateWebsiteBlock,
  WebsiteMyPlanSection,
  WebsiteInfoSectionMobile,
  WebsiteInfoSectionDesktop,
  WebsiteTemplatePreview,
} from "../../components/website/";
import { useScreenSize } from "../../globals/hooks";
import theme from "theme";

function WebsiteMainPage() {
  // hooks
  const { isMediumTabletView } = useScreenSize();

  // queries
  const { data: meData, loading: meLoading } = useQuery(LOAD_ME_QUERY);
  const operatorId = meData?.me?.operatorId;

  const { data: remoteOperatorData, loading: operatorLoading } = useQuery(
    LOAD_OPERATOR_QUERY,
    {
      variables: {
        id: operatorId,
      },
      skip: !operatorId,
    }
  );
  const operator = remoteOperatorData?.node || {};

  const styles = {
    text: {
      color: black,
    },
    fixedToTop: {
      top: 0,
      right: 0,
      left: 0,
      width: "100%",
      backgroundColor: alabaster,
      zIndex: 1,
    },
    container: {
      minHeight: "100%",
      flex: 1,
      maxWidth: "826px",
      margin: "auto",
      padding: 0,
      [theme.breakpoints.down("lg")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      [theme.breakpoints.down("sm")]: {
        overflowY: "auto",
      },
    },
    header: {
      minHeight: "78px",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Website Settings | Moovs</title>
      </Helmet>
      {meLoading || operatorLoading || !operator ? (
        <Box
          width="100%"
          height="100px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={40} thickness={4} />
        </Box>
      ) : operator?.dudaWebsite ? (
        <Box display="flex" flexDirection="column" sx={styles.container}>
          <Box position="sticky" sx={styles.fixedToTop}>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              sx={styles.header}
            >
              <Typography variant="h2" sx={styles.text}>
                Main
              </Typography>
            </Box>
            <Divider style={{ backgroundColor: grayMedium }} />
          </Box>
          <Box my={4} px={3} display="flex" flexDirection="column">
            <Box>
              {isMediumTabletView ? (
                <WebsiteInfoSectionMobile operator={operator} />
              ) : (
                <WebsiteInfoSectionDesktop operator={operator} />
              )}
            </Box>

            <Box my={3}>
              <Divider />
            </Box>

            <Box
              display="flex"
              flexDirection={isMediumTabletView ? "column" : "row"}
              justifyContent="space-between"
            >
              <Box mb={4}>
                <WebsiteTemplatePreview operator={operator} />
              </Box>
              <Box>
                <WebsiteMyPlanSection operator={operator} />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <CreateWebsiteBlock
          operatorSlug={operator?.nameSlug}
          companyName={operator?.name}
          location={operator?.address}
        />
      )}
    </>
  );
}

export default WebsiteMainPage;
