import React from "react";
import moment from "moment";
import startCase from "lodash/startCase";

import { Box, Typography, Divider } from "@mui/material";

import { StripeTransactionsEdge } from "types";
import { currency } from "globals/utils/helpers";
import { grayLight } from "design-system/colors";
import { TripInfo } from "../../../../AccordianDetailsDesktop/components/TransactionsListItemDesktop/components";

type TransactionsListItemMobileProp = {
  transactionEdge: StripeTransactionsEdge;
};

function TransactionsListItemMobile(props: TransactionsListItemMobileProp) {
  const { transactionEdge } = props;
  const {
    action,
    contact,
    farmor,
    trips,
    transactionDate,
    gross,
    fees,
    netTotal,
  } = transactionEdge.node;

  return (
    <Box
      border={`1px solid ${grayLight}`}
      borderRadius="4px"
      mb={2}
      p={2}
      boxShadow="0px 4px 15px 0px rgba(0, 0, 0, 0.03)"
    >
      <Box display="flex" flexDirection="column" flex="1">
        <Box display="flex" flexDirection="row" flex="1">
          <Box display="flex" flexDirection="column" flex="1" mr={1}>
            <Typography variant="overline">Type</Typography>
            <Typography variant="body2">{startCase(action)}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" flex="2" mr={1}>
            <Typography variant="overline">Booking Contact</Typography>
            <Typography variant="body2">
              {contact
                ? `${contact.firstName} ${contact.lastName}`
                : farmor?.operatorName}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" flex="1">
            <Typography variant="overline">Trip</Typography>
            {trips.map((trip, i) => {
              return <TripInfo key={i} trip={trip} screenVariant="mobile" />;
            })}
          </Box>
        </Box>
        <Box my={1.5}>
          <Divider />
        </Box>
        <Box display="flex" flexDirection="row" flex="1" flexWrap="wrap">
          <Box display="flex" flexDirection="column" flex="1" mr={1} mb={1}>
            <Typography variant="overline">Txn Date</Typography>
            <Typography variant="body2">
              {moment(transactionDate).format("MM/DD/YY")}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" flex="1" mr={1}>
            <Typography variant="overline">Gross</Typography>
            <Typography variant="body2">{currency(gross)}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" flex="1" mr={1}>
            <Typography variant="overline">Fees</Typography>
            <Typography variant="body2">{currency(fees)}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" flex="1">
            <Typography variant="overline">Net Total</Typography>
            <Typography variant="body2">{currency(netTotal)}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default TransactionsListItemMobile;
