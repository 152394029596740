import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import {
  Box,
  Button,
  Container,
  Divider,
  Link,
  Typography,
} from "@mui/material";

import { ArrowLeftIcon } from "design-system/icons";
import { moovsBlue, granite } from "design-system/colors";
import { MoovsMarketAppData } from "../MoovsMarketAppList/hooks/useMoovsMarketApps";
import { InstalledChip } from "../MoovsMarketAppList/components/MoovsMarketAppListItem/components";
import { useScreenSize, useAnalytics, useOperator } from "globals/hooks";
import {
  aboutAppToTrack,
  installToTrack,
  openAppToTrack,
} from "pages/moovs-market/helpers/enumToTrack";
import { useInstallMarketApp } from "./components/ChooseReviewPlatformDialog/hooks";
import { MarketApp } from "../MoovsMarketAppList/data/appData";
import {
  CustomerReviewAppInstallOptionsDialog,
  DriverAppAdditionalInfo,
  InstallAppButton,
  ReviewPlatformAdditionalInfo,
  DeleteAppButton,
  ChooseReviewPlatformDialog,
  ConnectedGoogleAccount,
} from "./components";
import { getServerLink } from "globals/utils/getServerLink";

const AppAdditionalInfoBlocks = {
  driver: <DriverAppAdditionalInfo />,
  sales_automation: null,
  dyanmic_pricing: null, //TODO: dynamic pricing children
  review_platform: <ReviewPlatformAdditionalInfo />,
  google_calendar: <ConnectedGoogleAccount />,
};

type MoovsMarketAppInfoProps = {
  app: MoovsMarketAppData;
  onAppClose: () => void;
};

const MoovsMarketAppInfo = (props: MoovsMarketAppInfoProps) => {
  const { app, onAppClose } = props;

  // state
  const [customerReviewInstallDialogOpen, setCustomerReviewInstallDialogOpen] =
    useState(false);
  const [chooseReviewPlatformDialogOpen, setChooseReviewPlatformDialogOpen] =
    useState(false);

  // hooks
  const history = useHistory();
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();
  const { handleInstallMoovsMarketApp, loading } = useInstallMarketApp(
    app.enum
  );
  const {
    settings: { reviewSite, reviewSiteId },
  } = useOperator();

  // event handlers
  const handleBackToMoovsMarket = () => {
    onAppClose();
  };

  const handleGoToClick = () => {
    track(openAppToTrack[app.enum]);

    history.push({
      pathname: app.goTo.path,
    });
  };

  const handleAboutAppClick = () => {
    track(aboutAppToTrack[app.enum]);
  };

  const handleInstallButtonClick = async () => {
    if (
      app.enum === MarketApp.ReviewPlatform &&
      (!reviewSite || !reviewSiteId)
    ) {
      setCustomerReviewInstallDialogOpen(true);
      return;
    }

    if (app.enum === MarketApp.GoogleCalendar) {
      track(installToTrack[app.enum]);

      const res = await axios.get(
        `${getServerLink()}/google-calendar-authorize`
      );

      window.location.replace(res.data);

      return;
    }

    // if review platform install gets here, it's because a review site and site ID were already saved from a previous install
    // should log in analytics as installing with link
    handleInstallMoovsMarketApp(
      app.enum === MarketApp.ReviewPlatform
        ? "market_reviewPlatformInstalledWithLink"
        : installToTrack[app.enum]
    );
  };

  const handleInstallOptionsDialogAccept = () => {
    handleInstallMoovsMarketApp("market_reviewPlatformInstalledWithoutLink");
    setCustomerReviewInstallDialogOpen(false);
  };

  return (
    <Container maxWidth="md">
      {/* Choose Review Platform Dialog */}
      <ChooseReviewPlatformDialog
        open={chooseReviewPlatformDialogOpen}
        onClose={() => {
          setChooseReviewPlatformDialogOpen(false);
        }}
        onCloseWhenSavingPlatform={() => {
          setCustomerReviewInstallDialogOpen(false);
          setChooseReviewPlatformDialogOpen(false);
        }}
        selectedPlatform={reviewSite?.toLowerCase()}
        reviewSiteId={reviewSiteId}
        shouldInstallWhenSaving
      />
      {/* Customer Review App Install Dialog */}
      <CustomerReviewAppInstallOptionsDialog
        open={customerReviewInstallDialogOpen}
        onClose={() => {
          setCustomerReviewInstallDialogOpen(false);
        }}
        onAccept={handleInstallOptionsDialogAccept}
        onSecondaryAccept={() => {
          setChooseReviewPlatformDialogOpen(true);
        }}
        acceptLoading={loading}
      />
      {/* App Info Header */}
      <Box display="flex" width="100%" flexDirection="column">
        <Box display="flex" justifyContent="space-between" my={4}>
          <Button onClick={handleBackToMoovsMarket}>
            <Box display="flex">
              <Box display="flex" alignItems="center">
                <ArrowLeftIcon color={moovsBlue} size="small" />
              </Box>

              <Box display="flex" alignItems="center" ml={1}>
                <Typography
                  sx={{ color: moovsBlue, fontWeight: 500, fontSize: "16px" }}
                >
                  Moovs Market
                </Typography>
              </Box>
            </Box>
          </Button>

          <Box display="flex" alignItems="center">
            {app.active && <InstalledChip />}
          </Box>
        </Box>

        <Divider />

        {/* App Info Content */}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={isMobileView && "space-between"}
          mt={4}
          height="100%"
        >
          {/* Common App Info - Icon, Name, Description */}
          <Box>
            <Box height="70px" width="70px">
              <img src={app.icon} alt={app.name + "icon"} />
            </Box>
            <Box my={3}>
              <Typography variant="h1">{app.name}</Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="body1" color={granite} maxWidth={650}>
                {app.longDescription || app.description}
              </Typography>
            </Box>
          </Box>

          {/* App Content - Installation, Navigation, About App, Deletion */}
          <Box width="100%">
            {
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  flexDirection={isMobileView ? "column" : "row"}
                >
                  {/* Children components - Mobile */}
                  {app.active &&
                    isMobileView &&
                    !!AppAdditionalInfoBlocks[app.enum] && (
                      <Box my={2}>
                        <Box>{AppAdditionalInfoBlocks[app.enum]}</Box>
                        <Box mt={2}>
                          <Divider />
                        </Box>
                      </Box>
                    )}

                  {/* Install/Open App Button */}
                  {!app.active ? (
                    <InstallAppButton
                      loading={loading}
                      appEnum={app.enum}
                      handleInstallButtonClick={handleInstallButtonClick}
                    />
                  ) : (
                    app.goTo && (
                      <Box mr={!isMobileView && 2}>
                        <Button
                          variant="contained"
                          onClick={handleGoToClick}
                          fullWidth={isMobileView}
                        >
                          Open {app.goTo.text}
                        </Button>
                      </Box>
                    )
                  )}

                  <Box my={0.25}>
                    <Button
                      disableRipple
                      onClick={() => handleAboutAppClick()}
                      fullWidth={isMobileView}
                    >
                      <Link
                        target="_blank"
                        href={app.aboutPage}
                        sx={{ textDecoration: "none" }}
                      >
                        About app
                      </Link>
                    </Button>
                  </Box>

                  {/* Delete Button */}
                  <Box my={0.25} ml={!isMobileView && 2}>
                    {app.active && <DeleteAppButton app={app} />}
                  </Box>
                </Box>

                {/* Children components - Desktop */}
                {app.active &&
                  !isMobileView &&
                  !!AppAdditionalInfoBlocks[app.enum] && (
                    <Box mt={2}>{AppAdditionalInfoBlocks[app.enum]}</Box>
                  )}
              </Box>
            }
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default MoovsMarketAppInfo;
