import React from "react";

type TripIconProps = {
  color?: string;
  viewBox?: string;
  height?: string;
  width?: string;
  size?: "small";
  fill?: string;
};

const TripIcon = (props: TripIconProps) => {
  const { color, size, viewBox, height, width, fill } = props;

  if (size === "small") {
    return (
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 6.38466L11.6667 1.33203L6.61404 11.9987L5.49123 7.50747L1 6.38466Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox={viewBox || "0 0 24 24"}
      fill={fill || "#B3B3B3"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 12.5789L19 5L11.4211 21L9.73684 14.2632L3 12.5789Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TripIcon;
