/**
 * @file useChat.tsx
 * Abstracts wrapping ChatContext with useContext.
 */

import { useContext } from "react";
import client from "@twilio/conversations";

import ChatContext from "./Context";
import { ConversationType } from "./Provider";

type ChatContextType = {
  conversationsClient: client.Client;
  onRefetchToken: () => Promise<void>;
  unreadMessagesCount: number;
  conversations: ConversationType[];
};

// see useChat/Provider.tsx for implementation
function useChat() {
  return useContext<ChatContextType>(ChatContext);
}

export default useChat;
