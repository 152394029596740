import React, { ChangeEvent } from "react";

import { Typography, TextField, MenuItem } from "@mui/material";

import { PaymentMethod, PaymentMethodEnum } from "types";
import { MenuItemContent } from "./components";

type PaymentMethodDropdownProps = {
  invoicePaymentErrors: {
    method: string;
    depositPct: string;
  };
  paymentMethodValue: string;
  handleMethodInput: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  hasMoovsPayments: boolean;
  contactOrAffiliatePaymentMethods: PaymentMethod[];
  companyPaymentMethods: PaymentMethod[];
  companyBookingContactPaymentMethods: {
    fullName: string;
    paymentMethods: PaymentMethod[];
  }[];
};

function PaymentMethodDropdown(props: PaymentMethodDropdownProps) {
  const {
    invoicePaymentErrors,
    paymentMethodValue,
    handleMethodInput,
    contactOrAffiliatePaymentMethods,
    hasMoovsPayments,
    companyPaymentMethods,
    companyBookingContactPaymentMethods,
  } = props;

  return (
    <>
      <Typography variant="overline">Payment Method</Typography>
      <TextField
        select
        required
        fullWidth
        variant="outlined"
        error={!!invoicePaymentErrors.method}
        helperText={invoicePaymentErrors.method}
        value={paymentMethodValue}
        onChange={handleMethodInput}
      >
        {/* Contact or Affiliate (displayed if invoice is not associated to company) */}
        {contactOrAffiliatePaymentMethods.map(
          (paymentMethod: PaymentMethod, i) => {
            const { card } = paymentMethod;

            return (
              <MenuItem value={paymentMethod.id} key={card.id}>
                <MenuItemContent paymentMethod={paymentMethod} />
              </MenuItem>
            );
          }
        )}

        {/* Company Booking Contacts (displayed if booking contacts of Company Invoice have payment methods ) */}
        {companyBookingContactPaymentMethods.map((paymentMethod, i) => {
          const { fullName, paymentMethods } = paymentMethod;

          return paymentMethods.map((paymentMethod: PaymentMethod, i) => {
            const { card } = paymentMethod;
            const isLastPaymentMethod = i === paymentMethods.length - 1;
            const isFirstPaymentMethod = i === 0;

            return [
              isFirstPaymentMethod ? (
                <MenuItem disabled>
                  <Typography variant="h6">{fullName}</Typography>
                </MenuItem>
              ) : null,
              <MenuItem
                value={paymentMethod.id}
                key={card.id}
                divider={isLastPaymentMethod}
              >
                <MenuItemContent paymentMethod={paymentMethod} />
              </MenuItem>,
            ];
          });
        })}

        {/* Company (displayed if company of Company Invoice has payment methods) */}
        {companyPaymentMethods?.length && (
          <MenuItem disabled>
            <Typography variant="h6">COMPANY</Typography>
          </MenuItem>
        )}
        {companyPaymentMethods?.map((paymentMethod: PaymentMethod, i) => {
          const isLastPaymentMethod = i === companyPaymentMethods.length - 1;
          const { card } = paymentMethod;

          return (
            <MenuItem
              value={paymentMethod.id}
              key={card.id}
              divider={isLastPaymentMethod}
            >
              <MenuItemContent paymentMethod={paymentMethod} />
            </MenuItem>
          );
        })}

        {/* Default Items */}
        {!hasMoovsPayments && (
          <MenuItem value={PaymentMethodEnum.GenericCard}>Credit Card</MenuItem>
        )}
        <MenuItem value={PaymentMethodEnum.Cash}>Cash</MenuItem>
        <MenuItem value={PaymentMethodEnum.Venmo}>Venmo</MenuItem>
        <MenuItem value={PaymentMethodEnum.Check}>Check</MenuItem>
        <MenuItem value={PaymentMethodEnum.Wire}>Wire</MenuItem>
      </TextField>
    </>
  );
}

export default PaymentMethodDropdown;
