import { useOperator } from "globals/hooks";
import { ChangeEvent, useState } from "react";

type UseCustomDomainFromNameProps = {
  onOperatorInfoChange: (
    key: string
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
};

export const useCustomDomainFromName = (
  props: UseCustomDomainFromNameProps
) => {
  const { onOperatorInfoChange } = props;

  // hooks
  const operator = useOperator();

  // state
  const [customDomainFromName, setCustomDomainFromName] = useState(
    operator.customDomain ? operator.customDomainFromName : null
  );
  const [fromNameError, setFromNameError] = useState(null);

  // event handlers
  const handleOperatorFromNameChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setCustomDomainFromName(value);
    if (value.includes("@") && operator.customDomain) {
      setFromNameError("Only enter part of email before @ sign");
    } else {
      setFromNameError(null);
      onOperatorInfoChange("customDomainFromName")(event);
    }
  };

  return {
    customDomainFromName,
    fromNameError,
    onOperatorFromNameChange: handleOperatorFromNameChange,
  };
};
