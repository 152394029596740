/**
 * @file SnackbarAlert.tsx
 * SnackbarAlert component for useSnackbar hook.
 *
 * components:
 *  SnackbarAlert
 *
 * author: jackv
 */

import React from "react";
import { ReactElement } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import { Box, Link, Snackbar, Typography, Button } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { black, grayLight, white } from "../../../design-system/colors";

type SnackbarAlertProps = {
  severity?: "success" | "error" | "info";
  message?: string;
  autoHideDuration?: number | null;
  open: boolean;
  onLinkClick?: () => void;
  handleClose: (event: any, reason?: any) => void;
  handleExited: () => void;
  snackbarId: string;
  link?: string;
  isExternalLink?: boolean;
  linkLabel?: string;
  snackbarColor?: string;
  textColor?: string;
  linkColor?: string;
  inlineLink?: boolean;
  iconColor?: string;
  icon?: Node;
  onUndo?: any;
  verticalPosition: "top" | "bottom";
  horizontalPosition: "left" | "center" | "right";
  alertBodyComponent?: ReactElement;
  isSimple?: boolean;
  isAuthPage?: boolean;
  credentialsError?: boolean;
  hideBoxShadow?: boolean;
};

function SnackbarAlert(props: SnackbarAlertProps) {
  const {
    severity = "info",
    message,
    autoHideDuration,
    open,
    onLinkClick,
    handleClose,
    handleExited,
    snackbarId,
    link,
    linkLabel,
    inlineLink,
    snackbarColor = "",
    textColor = "",
    linkColor = "",
    iconColor,
    onUndo,
    verticalPosition = "bottom",
    horizontalPosition = "center",
    alertBodyComponent,
    icon,
    isSimple,
    isExternalLink,
    isAuthPage,
    credentialsError,
    hideBoxShadow,
  } = props;

  const styles = {
    snackbar: {
      boxShadow: hideBoxShadow ? null : "0px 4px 8px rgba(0, 0, 0, 0.08)",
    },
    snackbarIcon: {
      "&.MuiAlert-icon": {
        color: iconColor,
      },
    },
  };

  const history = useHistory();

  const defaults = {
    success: {
      message: "Successfully updated!",
      autoHideDuration: 10000,
    },
    error: {
      message: "There was an error!",
      autoHideDuration: 10000,
    },
    info: {
      message: "",
      autoHideDuration: 10000,
    },
    warning: {
      message: "",
      autoHideDuration: 10000,
    },
  };

  const displayedMessage = message || defaults[severity].message;
  const linkProps = isExternalLink
    ? { href: link }
    : link
    ? { to: link, component: RouterLink }
    : null;

  const handleSignupRedirect = (event: any) => {
    history.push("/sign-up");
    handleClose(event);
  };

  const handleLinkClick = (event) => {
    if (onLinkClick) {
      onLinkClick();
    }
    handleClose(event);
  };

  return (
    <Snackbar
      sx={styles.snackbar}
      key={snackbarId}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: verticalPosition,
        horizontal: horizontalPosition,
      }}
      autoHideDuration={
        autoHideDuration || autoHideDuration === null
          ? null
          : defaults[severity].autoHideDuration
      }
      TransitionProps={{
        onExited: handleExited,
      }}
    >
      {isSimple ? (
        <Box
          py={1.5}
          pl={2}
          pr={7}
          display="flex"
          alignItems="center"
          bgcolor={white}
          border={`1px solid ${grayLight}`}
          boxShadow={hideBoxShadow ? null : "0px 4px 8px 0px 0,0,0,0.08"}
        >
          <Box mr={2}>{icon}</Box>
          {alertBodyComponent}
        </Box>
      ) : isAuthPage ? (
        <Box
          py={1.5}
          pl={2}
          pr={7}
          display="flex"
          alignItems="center"
          bgcolor={white}
          border={`1px solid ${grayLight}`}
          boxShadow={hideBoxShadow ? null : "0px 4px 8px 0px 0,0,0,0.08"}
        >
          <Box mr={2}>{icon}</Box>
          {credentialsError && (
            <Typography variant="body2">
              The email address or password you entered is incorrect. Please try
              again or{" "}
              <Link
                component="button"
                underline="none"
                variant="body2"
                style={{ fontWeight: 700 }}
                color="primary"
                onClick={handleSignupRedirect}
              >
                Create an Account
              </Link>
              .
            </Typography>
          )}
        </Box>
      ) : (
        <MuiAlert
          sx={{ "&.Mui-icon": styles.snackbarIcon, alignItems: "center" }}
          severity={severity}
          onClose={handleClose}
          style={{ backgroundColor: snackbarColor }}
          icon={icon}
        >
          {linkLabel ? (
            <Box display="flex" flexWrap="wrap">
              <Box mr={1} color={textColor}>
                {displayedMessage}
                <Link
                  onClick={handleLinkClick}
                  style={{
                    color: linkColor || black,
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  {...linkProps}
                >
                  <Typography
                    style={{
                      color: linkColor || black,
                      fontWeight: 500,
                      ...(inlineLink && { display: "inline" }),
                    }}
                  >
                    {linkLabel}
                  </Typography>
                </Link>
              </Box>
              {onUndo && (
                <Button style={{ color: linkColor || black }} onClick={onUndo}>
                  <Typography
                    style={{ color: linkColor || black, fontWeight: 500 }}
                  >
                    Undo
                  </Typography>
                </Button>
              )}
            </Box>
          ) : onUndo ? (
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <Box mr={3} color={textColor}>
                {displayedMessage}
              </Box>
              <Button style={{ color: linkColor || black }} onClick={onUndo}>
                <Typography
                  style={{ color: linkColor || black, fontWeight: 500 }}
                >
                  Undo
                </Typography>
              </Button>
            </Box>
          ) : alertBodyComponent ? (
            alertBodyComponent
          ) : (
            displayedMessage
          )}
        </MuiAlert>
      )}
    </Snackbar>
  );
}

export default SnackbarAlert;
