import * as yup from "yup";

export const adjustAmtSchema = yup.object({
  isIncrease: yup
    .boolean()
    .required("Please make a selection")
    .typeError("Please make a selection"),
  isPercent: yup
    .boolean()
    .required("Please make a selection")
    .typeError("Please make a selection"),
  amount: yup
    .number()
    .required("Please enter an amount")
    .typeError("Please enter an amount"),
});
