import React from "react";
import { Moment } from "moment";

import ComingSoonBlock from "./ComingSoonBlock";
import { DashboardView } from "../DashboardPage";

type CorporateShuttleViewProps = {
  startDate: Moment;
  endDate: Moment;
  companyId: string;
};

function CorporateShuttleView(props: CorporateShuttleViewProps) {
  const { startDate, endDate, companyId } = props;

  return (
    <ComingSoonBlock
      view={DashboardView.CORPORATE_SHUTTLE}
      startDate={startDate}
      endDate={endDate}
      companyId={companyId}
      analyticsName="shuttleDashboard_reportDownloaded"
    />
  );
}

export default CorporateShuttleView;
