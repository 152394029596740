import React from "react";

const GoogleIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1808_22865)">
        <path
          d="M31.6879 16.3683C31.6879 15.2807 31.5997 14.1872 31.4115 13.1172H16.3198V19.2785H24.9621C24.6035 21.2656 23.4512 23.0235 21.7639 24.1405V28.1383H26.9199C29.9476 25.3516 31.6879 21.2362 31.6879 16.3683Z"
          fill="#4285F4"
        />
        <path
          d="M16.32 32.0004C20.6352 32.0004 24.2744 30.5836 26.9259 28.1379L21.7699 24.1401C20.3354 25.116 18.4835 25.6686 16.3259 25.6686C12.1517 25.6686 8.61246 22.8526 7.34257 19.0664H2.02197V23.1877C4.73812 28.5906 10.2704 32.0004 16.32 32.0004Z"
          fill="#34A853"
        />
        <path
          d="M7.33654 19.0676C6.66632 17.0805 6.66632 14.9287 7.33654 12.9416V8.82031H2.02183C-0.24751 13.3413 -0.24751 18.6678 2.02183 23.1889L7.33654 19.0676Z"
          fill="#FBBC04"
        />
        <path
          d="M16.32 6.33288C18.6011 6.29761 20.8057 7.15596 22.4578 8.73156L27.0258 4.16349C24.1333 1.44734 20.2943 -0.0459547 16.32 0.00107822C10.2704 0.00107822 4.73812 3.41096 2.02197 8.81974L7.33669 12.941C8.6007 9.14897 12.1458 6.33288 16.32 6.33288Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_1808_22865">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoogleIcon;
