import React from "react";

type VehicleTypeSUVIconProps = {
  color?: string;
};

const VehicleTypeSUVIcon = (props: VehicleTypeSUVIconProps) => {
  const { color } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 16H22V11C22 10.4477 21.5523 10 21 10H18C17.3256 9.1077 15.4818 7.09689 14.761 6.31727C14.5728 6.11369 14.3092 6 14.032 6H5L2 10M20 16C20 17.1046 19.1046 18 18 18C16.8954 18 16 17.1046 16 16M20 16C20 14.8954 19.1046 14 18 14C16.8954 14 16 14.8954 16 16M2 10V16H4M2 10L18.5 10M16 16H8M4 16C4 17.1046 4.89543 18 6 18C7.10457 18 8 17.1046 8 16M4 16C4 14.8954 4.89543 14 6 14C7.10457 14 8 14.8954 8 16M10 9.5V6"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VehicleTypeSUVIcon;
