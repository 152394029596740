export const initialRequestTemplate = {
  trips: [
    {
      totalGroupSize: 0,
      stops: [
        {
          location: "",
          dateTime: null,
          stopIndex: 1,
          airport: null,
          airline: null,
          flightNumber: null,
          variant: "address",
          id: "stop-a",
        },
        {
          location: "",
          dateTime: null,
          stopIndex: 2,
          airport: null,
          airline: null,
          flightNumber: null,
          variant: "address",
          id: "stop-b",
        },
      ],
      routes: [
        {
          baseRateAmt: null,
          hourlyBaseRateHours: null,
          driverGratuityAmt: null,
          driverGratuityPercent: null,
          taxAmt: null,
          taxPercent: null,
          promoDiscountAmt: null,
          promoDiscountPercent: null,
          tollsAmt: null,
          meetGreetAmt: null,
          otherName: "",
          otherAmt: null,
          otherPercent: null,
          other2Name: "",
          other2Amt: null,
          other2Percent: null,
          other3Name: "",
          other3Amt: null,
          other3Percent: null,
          promoCodeId: null,
          promoCodeName: null,
          promoCodeAmt: null,
          promoCodePercent: null,
          forwardFacingSeatAmt: null,
          forwardFacingSeatQuantity: null,
          rearFacingSeatAmt: null,
          rearFacingSeatQuantity: null,
          boosterSeatAmt: null,
          boosterSeatQuantity: null,
        },
      ],
      contact: {
        id: "",
        firstName: "",
        workAddress: "",
        homeAddress: "",
        lastName: "",
        email: "",
        mobilePhone: "",
      },
      temporaryPassenger: {
        name: "",
        mobilePhone: "",
      },
      comments: [{ bodyText: "" }],
    },
  ],
  comments: [{ bodyText: "" }],
};

export const initialRequestErrors = {
  type: "",
  comments: "",
  // trip
  stops: [
    {
      location: "",
      dateTime: "",
      stopIndex: 1,
    },
    {
      location: "",
      dateTime: "",
      stopIndex: 2,
    },
  ],

  // route
  driverGratuity: "",
  vehicle: "",

  // booking contact
  bookingContact: "",

  // shuttleRoute
  shuttleRoute: "",

  // contacts
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  mobilePhone: "",
};
