import React from "react";

import { Box, Typography } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";

export type ConfirmSwitchPricingDialogProps = {
  open: boolean;
  onConfirm: () => void;
  onDiscard: () => void;
  onCancel: () => void;
  newPricingLabel?: string;
  loadingChanges?: boolean;
};

const ConfirmSwitchPricingDialog = (props: ConfirmSwitchPricingDialogProps) => {
  const {
    open,
    newPricingLabel,
    onConfirm,
    onDiscard,
    onCancel,
    loadingChanges,
  } = props;

  return (
    <MoovsDialog
      open={open}
      onClose={onDiscard}
      dialogTitle="Confirm Change?"
      fixedFooter
      acceptButtonText="Save Changes"
      closeButtonText="Discard Changes"
      onAccept={onConfirm}
      size="xs"
      onXClick={onCancel}
      acceptDisabled={loadingChanges}
    >
      <Box my={3} data-testid="confirm-switch-pricing-dialog">
        <Typography variant="body2">
          Pricing {newPricingLabel ? `for ${newPricingLabel}` : ""} has changed,
          would you like to save the updated price?
        </Typography>
      </Box>
    </MoovsDialog>
  );
};

export default ConfirmSwitchPricingDialog;
