import React, { useState } from "react";
import { Controller, Control } from "react-hook-form";
import { isMoment, Moment } from "moment";

import DatePicker from "@mui/lab/DatePicker";
import { IconButton, InputAdornment, TextField } from "@mui/material";

import ArrowDownIcon from "design-system/icons/layout/ArrowDownIcon";

type RHFDatePickerProps = {
  control: Control<any>;
  name: string;
  errorMessage: string;
  textFieldPlaceholder: string;
  datePickerInputFormat: string;
  datePickerLabel?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  defaultDate?: Moment;
};

function RHFDatePicker(props: RHFDatePickerProps) {
  const {
    control,
    name,
    errorMessage,
    textFieldPlaceholder,
    datePickerInputFormat,
    datePickerLabel,
    startAdornment,
    endAdornment,
    defaultDate,
  } = props;

  // state
  const [calendarOpen, setCalendarOpen] = useState(false);

  // event handlers
  const handleCalendarOpen = () => setCalendarOpen(true);
  const handleCalendarClose = () => setCalendarOpen(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...otherFields } }) => {
        return (
          <DatePicker
            {...otherFields}
            {...(value && { label: datePickerLabel })}
            clearable
            value={isMoment(value) ? value : defaultDate || null}
            inputFormat={datePickerInputFormat}
            open={calendarOpen}
            onOpen={handleCalendarOpen}
            onClose={handleCalendarClose}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={!!errorMessage}
                helperText={errorMessage}
                inputProps={{
                  ...params.inputProps,
                  placeholder: textFieldPlaceholder,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ paddingRight: startAdornment ? "10px" : "0px" }}
                    >
                      {startAdornment || null}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleCalendarOpen}>
                        {endAdornment || <ArrowDownIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        );
      }}
    />
  );
}

export default RHFDatePicker;
