/* 
  hook used to hold a reference to props/state in the previous render
  https://usehooks.com/usePrevious/
*/

import { useRef, useEffect } from "react";

function usePrevious<T>(value: any): T {
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export { usePrevious };
