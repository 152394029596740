import React, { Dispatch, SetStateAction } from "react";
import { useMutation } from "@apollo/client/react";

import { TOGGLE_DRIVER_APP_AMOUNT_DUE_DISPLAY } from "globals/graphql";
import { useOperator, useSnackbar } from "globals/hooks";
import LabeledSwitchInput from "./LabeledSwitchInput";

type DriverAppAmountDueBlockProps = {
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

const DriverAppAmountDueBlock = (props: DriverAppAmountDueBlockProps) => {
  const { setSaveIndicatorState } = props;

  const operator = useOperator();
  const snackbar = useSnackbar();

  const [toggleDriverAppAmountDueDisplay] = useMutation(
    TOGGLE_DRIVER_APP_AMOUNT_DUE_DISPLAY,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        snackbar.error("Error updating operator");
      },
    }
  );

  // event handlers
  const handleToggleChange = () => {
    setSaveIndicatorState("loading");
    toggleDriverAppAmountDueDisplay({
      variables: {
        input: {
          enabled: !operator.driverAppAmountDueEnabled,
        },
      },
    });
  };

  return (
    <LabeledSwitchInput
      name="driverAmountDue"
      checked={operator.driverAppAmountDueEnabled}
      onChange={handleToggleChange}
      text="Enable Amount Due on driver app"
      subText={`Amount Due on driver app is ${
        operator.driverAppAmountDueEnabled ? "enabled" : "disabled"
      }.`}
    />
  );
};

export default DriverAppAmountDueBlock;
