import React from "react";

import { Box } from "@mui/material";

import { useQuery } from "@apollo/client";
import { LOAD_AMOUNT_AWAITING_PAYOUT } from "globals/graphql";
import { useScreenSize } from "globals/hooks";
import { AmountAwaitingInfo } from "./components";
import StartInstantPayoutBlock from "../StartInstantPayoutBlock";

function AmountAwaitingPayoutBlock() {
  // hooks
  const { isMobileView } = useScreenSize();

  // query
  const { data, loading } = useQuery(LOAD_AMOUNT_AWAITING_PAYOUT, {
    fetchPolicy: "network-only",
  });

  // derived state
  const { standard, instant } = data?.loadAwaitingPayoutAmounts || {};

  return (
    <Box
      mb={isMobileView ? 2 : 4}
      display="flex"
      flexDirection={isMobileView ? "column" : "row"}
    >
      <AmountAwaitingInfo
        label="Balance"
        tooltipText="This is your balance scheduled to be paid out within 2-5 business days"
        loading={loading}
        amount={standard}
      />

      <AmountAwaitingInfo
        label="Instant Available"
        tooltipText="This is your available balance that can be paid out within 30 minutes"
        loading={loading}
        amount={instant}
      />

      <Box ml={isMobileView ? 0 : "auto"} mt={isMobileView ? 1 : 0}>
        <StartInstantPayoutBlock instantAvailableBalance={instant} />
      </Box>
    </Box>
  );
}

export default AmountAwaitingPayoutBlock;
