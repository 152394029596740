import React from "react";
import first from "lodash/first";

import { Typography, Box, Divider } from "@mui/material";
import { grayDark, grayMedium } from "design-system/colors";
import DurationDisplay from "./DurationDisplay";
import { Trip } from "types";
import { getTripIconAndCategoryType } from "pages/requests/utils/getTripIconAndCategoryType";
import {
  calculateTripEndDateTime,
  tripStartEndDateTimeParser,
} from "pages/requests/utils/calculateTripEndDateTime";
import FlightChangeDisplay from "./FlightChangeDisplay";

type TripItineraryHeaderProps = {
  trip: Trip;
};

function TripItineraryHeader(props: TripItineraryHeaderProps) {
  const { trip } = props;

  const { stops, totalDuration, tripCategory, roundTripVariant } = trip;

  const { text, icon } = getTripIconAndCategoryType({
    tripCategory,
    roundTripVariant,
  });

  // only show end time for hourly rides
  const endDateTime = calculateTripEndDateTime({
    tripCategory,
    stops,
    totalDuration,
  });

  const startDateTime = first(trip.stops).dateTime;

  const tripStartEndDateString = tripStartEndDateTimeParser({
    startDateTime,
    endDateTime,
  });

  return (
    <>
      <Typography variant="h5" style={{ color: grayDark }}>
        PICK-UP DATE & TIME
      </Typography>
      <Box display="flex">
        <Box>
          {tripStartEndDateString.split(/\n/).map((dateTimeInfo) => (
            <Typography
              variant="subtitle1"
              style={{ fontWeight: 700 }}
              key={dateTimeInfo}
            >
              {dateTimeInfo}
            </Typography>
          ))}
        </Box>
        <Box ml={1} display="flex">
          <FlightChangeDisplay
            flightOffset={first(trip.stops).flightOffset}
            originalDateTime={first(trip.stops).originalDateTime}
            dateTime={first(trip.stops).dateTime}
          />
        </Box>
      </Box>
      <Box mt={1} display="flex">
        <DurationDisplay
          totalDuration={trip.totalDuration}
          estimatedDuration={trip.estimatedDuration}
          isEstimated={!trip.useTotalDuration}
        />
        <Box mx={2}>
          <Divider
            orientation="vertical"
            style={{
              backgroundColor: grayMedium,
            }}
          />
        </Box>
        <Box minHeight={32} display="flex" alignItems="center">
          <Typography noWrap variant="body2" style={{ color: grayDark }}>
            {text}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" ml={1.5}>
          {icon}
        </Box>
      </Box>
    </>
  );
}

export default TripItineraryHeader;
