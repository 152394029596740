import React from "react";
import moment from "moment";
import last from "lodash/last";

import { Box, SxProps, Typography } from "@mui/material";

import { granite } from "design-system/colors";
import { fromGlobalId } from "globals/utils/helpers";

type FreeTrialMessageProps = {
  id: string;
  chargeAt?: Date;
  comingSoon?: boolean;
  mode?: "available" | "installed";
  sx?: SxProps;
};

function FreeTrialMessage(props: FreeTrialMessageProps) {
  const { id, chargeAt, comingSoon, mode, sx } = props;

  // derived state
  const freeTrialDaysRemaining = chargeAt
    ? moment(chargeAt).diff(moment(), "days")
    : 0;
  const trialExpired = freeTrialDaysRemaining <= 0;
  // if no id is on moovsApp record, then we know it has never been installed
  const trialNotStarted = last(fromGlobalId(id).id.split(".")) === "undefined";

  if (comingSoon || (trialExpired && !trialNotStarted)) return null;

  if (trialNotStarted && mode === "available") {
    return (
      <Typography sx={sx} variant="caption" color={granite}>
        30-day Free Trial
      </Typography>
    );
  }

  if (mode === "installed") {
    return (
      <Box sx={sx}>
        <Typography variant="h6" color={granite} component="span">
          {freeTrialDaysRemaining}-day{freeTrialDaysRemaining > 1 ? "s" : ""}{" "}
          left{" "}
        </Typography>
        <Typography variant="caption" color={granite} component="span">
          from your Free Trial
        </Typography>
      </Box>
    );
  }

  return null;
}

export default FreeTrialMessage;
