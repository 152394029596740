import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import { Box, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useAnalytics, useScreenSize, useSnackbar } from "globals/hooks";
import { UPDATE_ONBOARDING_USER_MUTATION } from "globals/graphql";
import { white } from "design-system/colors";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { ErrorMessages } from "moovsErrors/errorCodesMap";
import InternationalPhoneInput from "components/inputs/InternationalPhoneInput";
import { internationalPhoneStyle } from "./constants";

type UserInfoFieldsProps = {
  setActiveStep: React.Dispatch<React.SetStateAction<2>>;
};

function UserInfoFields(props: UserInfoFieldsProps) {
  const { setActiveStep } = props;

  // state
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    mobilePhone: "",
    phoneCountryCode: "",
    phoneCountryDialCode: "",
    phoneCountryName: "",
    phoneCountryFormat: "",
  });
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [mobilePhoneError, setMobilePhoneError] = useState("");

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const { isMobileView } = useScreenSize();

  const [updateOnboardingUser] = useMutation(UPDATE_ONBOARDING_USER_MUTATION, {
    onCompleted: () => {
      track("onboarding_personalInfoSubmitted");
      setLoading(false);
      setActiveStep(2);
    },
    onError: (error) => {
      setLoading(false);

      const errorMessage = getErrorMessage(error);

      if (errorMessage === ErrorMessages.MOOVS_INVALID_MOBILE_NUMBER) {
        setMobilePhoneError(errorMessage);
      }

      snackbar.error(errorMessage);
    },
  });

  const resetNameErrors = (targetName: string) => {
    if (targetName === "firstName") setFirstNameError(false);
    if (targetName === "lastName") setLastNameError(false);
  };

  const isFormValid = () => {
    let showErrorMessage = false;

    if (!data.mobilePhone) {
      setMobilePhoneError("Please enter valid number");
      showErrorMessage = true;
    }

    if (!data.lastName) {
      setLastNameError(true);
      showErrorMessage = true;
    }

    if (!data.firstName) {
      setFirstNameError(true);
      showErrorMessage = true;
    }

    if (showErrorMessage) {
      snackbar.error("Please fill all of the required fields", {
        hideBoxShadow: true,
      });

      return false;
    }

    return true;
  };

  const handleOnSubmit = () => {
    if (!isFormValid()) return;

    setLoading(true);

    updateOnboardingUser({
      variables: {
        input: data,
      },
    });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleOnSubmit();
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetNameErrors(e.target.name);

    setData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleInternationalPhoneChange = (value, country) => {
    setData((prevState) => {
      return {
        ...prevState,
        mobilePhone: value,
        phoneCountryCode: country.countryCode,
        phoneCountryDialCode: country.dialCode,
        phoneCountryName: country.name,
        phoneCountryFormat: country.format,
      };
    });

    setMobilePhoneError("");
  };

  // effects
  useEffect(() => {
    track("onboarding_personalInfoInitiated");
  }, [track]);

  return (
    <>
      <TextField
        fullWidth
        error={firstNameError}
        variant="outlined"
        value={data.firstName}
        label="First Name"
        name="firstName"
        onChange={handleNameChange}
        onKeyDown={handleKeyPress}
        sx={{ backgroundColor: white }}
      />
      <Box mt={1}>
        <TextField
          error={lastNameError}
          fullWidth
          variant="outlined"
          value={data.lastName}
          label="Last Name"
          name="lastName"
          onChange={handleNameChange}
          onKeyDown={handleKeyPress}
          sx={{ backgroundColor: white }}
        />
      </Box>
      <Box mt={2}>
        <InternationalPhoneInput
          value={data.mobilePhone}
          onChange={handleInternationalPhoneChange}
          error={mobilePhoneError}
          errorMessage="Please enter valid number"
          sx={internationalPhoneStyle}
          label="Mobile Phone"
          dropdownWidth={isMobileView ? "600%" : "760%"}
        />
      </Box>
      <Box mt={1}>
        <Box display="flex" flexDirection="column" alignItems="center" my={3}>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            fullWidth
            onClick={handleOnSubmit}
          >
            Continue
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
}

export default UserInfoFields;
