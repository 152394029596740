import React from "react";

type EstimationRoundIconProps = { color?: string };

const EstimationRoundIcon = (props: EstimationRoundIconProps) => {
  const { color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#estimation-round-icon-clip-path)">
        <path
          d="M13.0002 7L9.05903 12.0985C9.02829 12.1389 9.00858 12.1883 9.00223 12.2407C8.99588 12.2932 9.00315 12.3466 9.02319 12.3946C9.04322 12.4426 9.07519 12.4833 9.11532 12.5118C9.15546 12.5403 9.20209 12.5555 9.24973 12.5556H11V17L14.9412 11.9022C14.9718 11.8617 14.9915 11.8123 14.9978 11.7599C15.0041 11.7074 14.9968 11.654 14.9768 11.606C14.9568 11.5579 14.9249 11.5172 14.8848 11.4886C14.8447 11.46 14.7981 11.4447 14.7505 11.4444H13.0002V7Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.1587 11.371L20.8178 9.19951L22.9893 10.8586M20.8969 9.51615C21.4098 11.6002 21.1894 13.7974 20.2727 15.738C19.356 17.6787 17.7989 19.2444 15.8634 20.172C13.9279 21.0995 11.732 21.3321 9.64513 20.8308M4.66488 13.1724L2.56076 14.9094L0.823796 12.8052M2.7617 13.9835C2.25196 11.9004 2.47449 9.70525 3.39194 7.76679C4.30938 5.82833 5.86587 4.26457 7.80003 3.33809C9.73418 2.41161 11.9282 2.17883 14.0137 2.67885"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="estimation-round-icon-clip-path">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EstimationRoundIcon;
