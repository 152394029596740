import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import isNull from "lodash/isNull";

import { Box, Typography } from "@mui/material";

import {
  DriverPayoutToggleVariant,
  FormVariant,
  InputFormatVariant,
} from "../utils/types";
import DriverPayoutField from "./DriverPayoutField";
import DriverPayoutRateToggle from "./DriverPayoutRateToggle";
import { useScreenSize } from "globals/hooks";
import { grayDark } from "design-system/colors";
import DriverPayoutLocationToggle from "./DriverPayoutLocationToggle";
import { CreateDriverInput, TripCategory, UpdateDriverInput } from "types";

type OneWayTripProps = {
  driver: CreateDriverInput | UpdateDriverInput;
  setDriver: Dispatch<SetStateAction<CreateDriverInput | UpdateDriverInput>>;
  variant?: FormVariant;
};

function OneWayTrip(props: OneWayTripProps) {
  const { driver, setDriver, variant } = props;

  // derived state
  const selectedRateToggle = !isNull(driver.settings?.oneWayTripHourlyRate)
    ? DriverPayoutToggleVariant.HOURLY_RATE
    : !isNull(driver.settings?.oneWayTripBaseRatePct)
    ? DriverPayoutToggleVariant.BASE_RATE_PCT
    : !isNull(driver.settings?.oneWayTripFlatRate)
    ? DriverPayoutToggleVariant.FLAT_RATE
    : null;

  // hook
  const { isSmallTabletView } = useScreenSize();

  // state
  const [toggleVariant, setToggleVariant] = useState<DriverPayoutToggleVariant>(
    selectedRateToggle || DriverPayoutToggleVariant.HOURLY_RATE
  );

  // event handlers
  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    setDriver({
      ...driver,
      settings: {
        ...driver.settings,
        [name]: Number(value) || null,
      },
    });
  };

  return (
    <>
      <Box
        mb={0.5}
        display="flex"
        justifyContent="space-between"
        flexDirection={isSmallTabletView ? "column" : "row"}
        alignItems={isSmallTabletView ? "flex-start" : "center"}
      >
        {/* Rate Toggle Buttons */}
        <Box mr={1} mb={isSmallTabletView ? 2.5 : 0}>
          <DriverPayoutRateToggle
            toggleVariant={toggleVariant}
            onSetToggleVariant={setToggleVariant}
            tripType={TripCategory.OneWay}
            setDriver={setDriver}
            driver={driver}
          />
        </Box>

        {/* Hourly Rate Input */}
        {toggleVariant === DriverPayoutToggleVariant.HOURLY_RATE && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection={isSmallTabletView ? "column" : "row"}
            >
              <Box m={0.5} mb={isSmallTabletView ? 1 : 0}>
                <DriverPayoutField
                  placeholder="Hourly Rate"
                  fieldName="oneWayTripHourlyRate"
                  value={driver.settings.oneWayTripHourlyRate}
                  onHandleFieldChange={handleFieldChange}
                  format={InputFormatVariant.DOLLAR}
                  variant={variant}
                />
              </Box>
              <Box m={0.5}>
                <DriverPayoutField
                  placeholder="Hourly Minimum"
                  fieldName="oneWayTripMinimumHours"
                  value={driver.settings.oneWayTripMinimumHours}
                  onHandleFieldChange={handleFieldChange}
                  format={InputFormatVariant.HOURS}
                  variant={variant}
                />
              </Box>
            </Box>
          </>
        )}

        {/* % Base Rate % Input */}
        {toggleVariant === DriverPayoutToggleVariant.BASE_RATE_PCT && (
          <DriverPayoutField
            placeholder="Percentage of Base Rate"
            fieldName="oneWayTripBaseRatePct"
            value={driver.settings.oneWayTripBaseRatePct}
            onHandleFieldChange={handleFieldChange}
            format={InputFormatVariant.PERCENT}
            variant={variant}
          />
        )}

        {/* % Flat Rate Input */}
        {toggleVariant === DriverPayoutToggleVariant.FLAT_RATE && (
          <DriverPayoutField
            placeholder="Flat Rate"
            fieldName="oneWayTripFlatRate"
            value={driver.settings.oneWayTripFlatRate}
            onHandleFieldChange={handleFieldChange}
            format={InputFormatVariant.DOLLAR}
            variant={variant}
          />
        )}
      </Box>

      {/* Input Caption */}
      {toggleVariant === DriverPayoutToggleVariant.BASE_RATE_PCT && (
        <Typography color={grayDark} variant="caption">
          Calculates percentage of the trip's base rate amount
        </Typography>
      )}

      {toggleVariant === DriverPayoutToggleVariant.FLAT_RATE && (
        <Typography color={grayDark} variant="caption">
          Populates this exact amount for all one-way trips
        </Typography>
      )}

      {/* Location Variant */}
      {toggleVariant === DriverPayoutToggleVariant.HOURLY_RATE && (
        <DriverPayoutLocationToggle
          driver={driver}
          fieldName="oneWayTripLocationsVariant"
          tripLocationsVariant={driver.settings.oneWayTripLocationsVariant}
          setDriver={setDriver}
        />
      )}
    </>
  );
}

export default OneWayTrip;
