import { Box, Typography } from "@mui/material";
import { grayDark, grayMedium } from "design-system/colors";
import React from "react";
import { SalesAutomationText } from "types";

type TextButtonSubtextProps = {
  isDisabled: boolean;
  text: SalesAutomationText;
};

function TextButtonSubtext(props: TextButtonSubtextProps) {
  const { isDisabled, text } = props;

  return (
    <Box display="flex" flexDirection="row">
      <Box display="flex" minWidth="24px" minHeight="24px" ml={1.25}>
        {text.index !== 2 && <Line height="100%" />}
      </Box>
      <Box display="flex" color={isDisabled ? grayMedium : grayDark}>
        {isDisabled ? (
          <Typography variant="caption" textAlign="left">
            Message will not be sent due to the timing of pick up
          </Typography>
        ) : (
          <>
            {text.index === 1 && (
              <Typography variant="caption" textAlign="left">
                Automated text will send two days after the first message
              </Typography>
            )}
            {text.index === 2 && (
              <Typography variant="caption" textAlign="left">
                Automated text will send three days after the second message
              </Typography>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

function Line(props: { height: string }) {
  return (
    <Box bgcolor={grayMedium} width="1.5px" height={props.height} my={0.5} />
  );
}

export default TextButtonSubtext;
