import capitalize from "lodash/capitalize";
import first from "lodash/first";
import map from "lodash/map";
import size from "lodash/size";
import React from "react";

import { DayOfWeek } from "types";
import { needTooltip } from "../../utils/needToolTip";
import { IconNames } from "../../utils/types";
import DynamicPricingChip from "../DynamicPricingChip";
import DynamicPricingChipTooltip from "../DynamicPricingChipTooltip";

type DaysOfWeekChipProps = {
  daysOfWeek: DayOfWeek[];
  onSetWeekdaysDrawerOpen: () => void;
};

function DaysOfWeekChip(props: DaysOfWeekChipProps) {
  const { daysOfWeek, onSetWeekdaysDrawerOpen } = props;

  if (!size(daysOfWeek) || size(daysOfWeek) === 7) return null;

  let daysOfWeekText;
  let chip;

  if (size(daysOfWeek) === 7) {
    daysOfWeekText = "Everyday";
    chip = (
      <DynamicPricingChip label={daysOfWeekText} iconName={IconNames.weekday} />
    );
  } else if (needTooltip(daysOfWeek)) {
    // if there are 2 weekdays or more, need to use tooltip
    daysOfWeekText = map(daysOfWeek, capitalize);
    chip = (
      <DynamicPricingChipTooltip
        onDrawerOpen={onSetWeekdaysDrawerOpen}
        list={daysOfWeekText}
        label={first(daysOfWeekText)}
        iconName={IconNames.weekday}
      />
    );
  } else {
    daysOfWeekText = map(daysOfWeek, capitalize).join(", ");
    chip = (
      <DynamicPricingChip label={daysOfWeekText} iconName={IconNames.weekday} />
    );
  }

  return chip;
}

export default DaysOfWeekChip;
