import React, { useState, useMemo } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { TextField, Box, MenuItem, Typography } from "@mui/material";

import { Term } from "../../../types";
import CreateDrawer from "../../globals/CreateDrawer";
import {
  CREATE_TERM_MUTATION,
  LOAD_TERMS_QUERY,
  LOAD_TERM_TYPES_QUERY,
} from "../../../globals/graphql";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import { useAnalytics } from "../../../globals/hooks";

const initialTerm = {
  name: "",
  description: "",
};

function CreateTermsDrawer() {
  const snackbar = useSnackbar();
  const history = useHistory();
  const { track } = useAnalytics();

  // state
  const [term, setTerm] = useState<Partial<Term>>(initialTerm);
  const [errors, setErrors] = useState(initialTerm);
  const [termSaveError, setTermSaveError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  // queries
  const { data: termsData } = useQuery(LOAD_TERMS_QUERY);
  const { data: termTypesData } = useQuery(LOAD_TERM_TYPES_QUERY);

  const terms = useMemo(() => (termsData ? termsData.terms : []), [termsData]);

  // mutations
  const [createTerm] = useMutation(CREATE_TERM_MUTATION, {
    refetchQueries: [
      { query: LOAD_TERMS_QUERY },
      { query: LOAD_TERM_TYPES_QUERY },
    ],
    onCompleted(data) {
      handleCreateDrawerClose();
      snackbar.success(`Successfully created ${term.name} term!`);
      track("create_term", {
        term_name: data.createTerm.term.name,
      });
    },
    onError(error) {
      setSubmitDisabled(false);
      snackbar.error("Error creating term");
    },
  });

  // effects
  const termTypes = useMemo(() => {
    if (!termTypesData) return [];

    const allTerms = termTypesData ? termTypesData.termTypes : [];

    return allTerms.filter((term: Term, index: number) => {
      return terms.every(({ name }) => name !== term.name);
    });
  }, [termTypesData, terms]);

  const termTypeByName = useMemo(
    () => termTypes.find(({ name }) => name === term.name),
    [termTypes, term]
  );

  // event handlers
  const handleTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "description") {
      event.target.value.toString().length >= 3000
        ? setDescriptionError("Reached character limit")
        : setDescriptionError(null);
    }

    setTerm({
      ...term,
      [event.target.name]: event.target.value,
    });

    setErrors({
      ...errors,
      [event.target.name]: "",
    });
  };

  const handleClickSave = () => {
    let errors = [];

    if (!term.name) {
      errors = [...errors, { name: "Please choose a term type" }];
    }

    if (term.description.length < 10) {
      errors = [
        ...errors,
        { description: "Please enter at least 10 characters for description" },
      ];
    }

    if (errors.length > 0) {
      setErrors(errors.reduce((acc, value) => ({ ...acc, ...value }), {}));
      setTermSaveError(true);
      return;
    }

    setTermSaveError(false);
    setSubmitDisabled(true);
    createTerm({
      variables: {
        input: {
          description: term.description,
          termTypeId: termTypeByName.id,
        },
      },
    });
  };

  const handleCreateDrawerClose = () => {
    history.push("/settings/terms-and-conditions");
  };

  return (
    <CreateDrawer
      onClose={handleCreateDrawerClose}
      pageLabel="Create New Term"
      submitLabel="Save Term"
      onSubmit={handleClickSave}
      saveError={termSaveError}
      submitDisabled={submitDisabled}
    >
      <Helmet>
        <title>Create Term | Moovs</title>
      </Helmet>
      <Box mb={4}>
        <Box mt={4.5} mb={3.5}>
          <Typography variant="h5">Type</Typography>
        </Box>
        <TextField
          select
          fullWidth
          name="name"
          variant="outlined"
          label="Term"
          value={term.name}
          onChange={handleTermChange}
          error={!!errors.name}
          helperText={errors.name}
        >
          {termTypes.map((termType) => (
            <MenuItem key={termType.id} value={termType.name}>
              {termType.name}
            </MenuItem>
          ))}
        </TextField>
        <Box minHeight="56px" pt={2} pb={3}>
          <Typography variant="body2">
            {termTypeByName ? termTypeByName.guideline : ""}
          </Typography>
        </Box>
        <TextField
          inputProps={{ style: { minHeight: 154 }, maxLength: 3000 }}
          fullWidth
          multiline
          label="Description"
          variant="outlined"
          onChange={handleTermChange}
          name="description"
          value={term.description}
          error={!!errors.description || !!descriptionError}
          helperText={errors.description || descriptionError}
        />
      </Box>
    </CreateDrawer>
  );
}

export default CreateTermsDrawer;
