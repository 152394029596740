import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { App as CapitalOSApp } from "@capitalos/react";

import { Box } from "@mui/material";

import { useOperator, useCurrentUser } from "globals/hooks";
import { MoovsCardError, MoovsCardLoading } from "./components";
import { containerStyles } from "../settings/utils/styles";
import "./styles/moovs-card.css";
import { getServerLink } from "globals/utils/getServerLink";

const MoovsCardPage = () => {
  // hooks
  const operator = useOperator();
  const currentUser = useCurrentUser();
  const history = useHistory();

  // state
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(false);

  const initiateLogin = async () => {
    try {
      const response = await axios.get(`${getServerLink()}/moovs-card-initiate-login`);

      const token = response.data;

      if (token) {
        setToken(token);
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
    }

    setIsLoading(false);
  };

  // effects
  useEffect(() => {
    if (!operator) return;

    // In case user navigates directly to "/card"
    if (currentUser.role.slug !== "owner" || !operator?.stripeAccount?.id) {
      history.replace("/reservations");
    } else {
      initiateLogin();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operator, history]);

  return (
    <>
      <Helmet>
        <title>Moovs Card | Moovs</title>
      </Helmet>
      <Box sx={containerStyles}>
        {isLoading ? (
          <MoovsCardLoading />
        ) : error ? (
          <MoovsCardError />
        ) : (
          <CapitalOSApp
            token={token}
            className="capital-os-iframe"
            loadingComponent={<MoovsCardLoading />}
            onError={(err) => setError(true)}
          />
        )}
      </Box>
    </>
  );
};

export default MoovsCardPage;
