import React, { useState } from "react";

import { Button, Typography } from "@mui/material";

import { CalculatorIcon } from "design-system/icons";
import { grayDark, swoopGreen, tintGreen } from "design-system/colors";
import BRACalculationsMenu from "./BRACalculationsMenu";
import MoovsTooltip from "components/MoovsTooltip";
import { useAnalytics } from "globals/hooks";
import { BaseRateAutomation } from "types";

type BRACalculationsButtonProps = {
  isVehicleBaseRateAutomated: boolean;
  vehicleId: string;
  baseRateAutomation: BaseRateAutomation;
};

function BRACalculationsButton(props: BRACalculationsButtonProps) {
  const { isVehicleBaseRateAutomated, vehicleId, baseRateAutomation } = props;

  // hooks
  const { track } = useAnalytics();

  // state
  const [anchorEl, setAnchorEl] = useState(null);

  // derived state
  const calculationInformationEnabled = isVehicleBaseRateAutomated && vehicleId;

  const tooltipText = !vehicleId
    ? "Vehicle must be selected in order to see BRA calculations"
    : !isVehicleBaseRateAutomated
    ? "BRA is not enabled for this vehicle"
    : "";

  // event handlers
  const handleBRACalculationsClick = (e) => {
    setAnchorEl(e.target);
    track("pricing_braCalculations");
  };

  return (
    <MoovsTooltip
      tooltipText={tooltipText}
      hidden={!!calculationInformationEnabled}
    >
      <>
        <Button
          onClick={handleBRACalculationsClick}
          endIcon={
            <CalculatorIcon
              color={calculationInformationEnabled ? swoopGreen : grayDark}
            />
          }
          sx={{
            "&:hover, &.Mui-focusVisible": {
              zIndex: 1,
              backgroundColor: tintGreen,
            },
          }}
          disabled={!calculationInformationEnabled}
        >
          <Typography
            sx={{
              color: calculationInformationEnabled ? swoopGreen : grayDark,
              fontWeight: 500,
              lineHeight: "18px",
              letterSpacing: "-0.25px",
            }}
          >
            BRA Calculations
          </Typography>
        </Button>
        <BRACalculationsMenu
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          baseRateAutomation={baseRateAutomation}
        />
      </>
    </MoovsTooltip>
  );
}

export default BRACalculationsButton;
