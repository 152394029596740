/**
 * @file useUserSettings.ts
 * Hook that abstracts query for userSettings fields on user.
 */

import { useQuery } from "@apollo/client";

import { useSnackbar } from "./useSnackbar";
import { LOAD_USER_SETTINGS_QUERY } from "../graphql";
import { User } from "../../types";
import { useAuth } from "./useAuth";

function useUserSettings(): User {
  // hooks
  const { authStage } = useAuth();
  const snackbar = useSnackbar();

  // queries
  const { data, error } = useQuery<{ me: User }>(LOAD_USER_SETTINGS_QUERY, {
    skip: authStage !== "authenticated",
  });

  if (error) {
    snackbar.error(error.message);
  }

  return data?.me || null;
}

export { useUserSettings };
