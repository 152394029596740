/**
 * @file graphql/company.ts
 * queries/mutations for Company.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_COMPANIES_QUERY = gql`
  query Companies {
    companies {
      id
      name
      description
      address
      state
      postalCode
      phoneNumber
      contactsTotalCount
      websiteUrl
      createdAt
      updatedAt
      companyLogoUrl
      email
    }
  }
`;

export const LOAD_COMPANIES_BY_SEARCH_TERM_QUERY = gql`
  query LoadCompaniesBySearchTerm(
    $searchTerm: String
    $cursor: String
    $limit: Int
  ) {
    loadCompaniesBySearchTerm(
      searchTerm: $searchTerm
      after: $cursor
      first: $limit
    ) {
      totalCount
      edges {
        node {
          ... on Company {
            id
            name
            address
          }
        }
      }
    }
  }
`;

export const LOAD_COMPANY_QUERY = gql`
  query Company($id: ID!) {
    node(id: $id) {
      id
      ... on Company {
        name
        description
        address
        state
        postalCode
        phoneNumber
        contactsTotalCount
        websiteUrl
        createdAt
        updatedAt
        email
        companyLogoUrl
        contacts {
          id
          firstName
          lastName
          mobilePhone
          email
          companyPosition
        }
        paymentMethods {
          id
          createdAt
          updatedAt
          contactId
          customer
          payerId
          cardNote
          isPrimary
          stripeStatus
          billingDetails {
            name
          }
          card {
            id
            expMonth
            expYear
            last4
            brand
            cvcCheck
            zipCodeCheck
          }
        }
      }
    }
  }
`;

export const CREATE_COMPANY_MUTATION = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      company {
        id
        name
        description
        address
        state
        postalCode
        phoneNumber
        createdAt
        updatedAt
        websiteUrl
        email
        companyLogoUrl
      }
    }
  }
`;

export const UPDATE_COMPANY_MUTATION = gql`
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      company {
        id
        name
        description
        address
        state
        postalCode
        phoneNumber
        websiteUrl
        createdAt
        updatedAt
        websiteUrl
        email
        companyLogoUrl
      }
    }
  }
`;

export const REMOVE_COMPANY_MUTATION = gql`
  mutation RemoveCompany($input: RemoveCompanyInput!) {
    removeCompany(input: $input) {
      company {
        id
        name
        description
        address
        state
        postalCode
        phoneNumber
        createdAt
        updatedAt
        websiteUrl
        email
      }
    }
  }
`;

export const LOAD_COMPANIES_NAME_AND_ID_QUERY = gql`
  query Companies {
    companies {
      id
      name
    }
  }
`;
