import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import { useQuery } from "@apollo/client";
import size from "lodash/size";

import { Box, Typography, CircularProgress } from "@mui/material";

import RequestsListHeader from "./RequestsList/RequestsListHeader";
import EmptyList from "components/globals/EmptyList";
import { LOAD_QUOTES } from "globals/graphql";
import { grayLight, white } from "design-system/colors";
import { LoadQuotesConnection } from "types";
import { renderNoQuotesText } from "./requests.utils";
import { useScreenSize } from "globals/hooks";
import { RequestModeEnum } from "./utils/enums";
import RequestListItem from "./RequestsList/RequestListItem";
import MoovsInfiniteScroll from "components/MoovsInfiniteScroll";
import QuoteOverviewManager from "./RequestOverview/QuoteOverviewManager";

// string constants
const ALL = "ALL";

function QuotesPage() {
  // hooks
  const { isMobileView } = useScreenSize();
  const history = useHistory();
  const { requestId } = useParams<{ requestId: string }>();
  const { status: statusParam } = queryString.parse(history.location.search);

  const { Quotes } = RequestModeEnum;

  // state
  const [view, setView] = useState(statusParam || ALL);
  const [hasInitialized, setHasInitialized] = useState(false);

  // queries
  const {
    data: quotesData,
    loading: quotesLoading,
    fetchMore: quotesFetchMore,
  } = useQuery<{ loadQuotes: LoadQuotesConnection }>(LOAD_QUOTES, {
    variables: {
      limit: 15,
      view,
    },
    fetchPolicy: "cache-and-network",
    skip: !view,
    onCompleted: () => setTimeout(() => setHasInitialized(true), 0),
    onError: () => setTimeout(() => setHasInitialized(true), 0),
  });

  const requests = quotesData?.loadQuotes.edges.map(({ node }) => node);

  // event handlers
  const handleSetView = (view: string) => {
    setHasInitialized(false); // set not initialized to enable loading spinner
    setView(view);
  };

  const handleFetchMore = () => {
    quotesFetchMore({
      variables: {
        cursor: quotesData?.loadQuotes.pageInfo.endCursor,
      },
    });
  };

  // effects
  // match query param w/ selected view & redirect when necessary
  useEffect(() => {
    const parsedQueryParams = queryString.parse(history.location.search);

    if (!statusParam) {
      parsedQueryParams.status = view || ALL;
      history.replace({
        pathname: history.location.pathname,
        search: queryString.stringify(parsedQueryParams),
        state: history.location.state,
      });
    }

    if (statusParam !== view) {
      parsedQueryParams.status = view || ALL;
      history.replace({
        pathname: history.location.pathname,
        search: queryString.stringify(parsedQueryParams),
        state: history.location.state,
      });
    }
  }, [history, view, statusParam, Quotes]);

  const [titleText, copyText] = renderNoQuotesText(view);

  return (
    <>
      <Helmet>
        <title>Moovs</title>
      </Helmet>
      <Box display="flex" width="100%" data-testid="requests-page">
        {/* Left - Request List */}
        <Box
          width={isMobileView ? "100vw" : "370px"}
          display="flex"
          overflow="hidden"
        >
          <Box
            display="flex"
            flexDirection="column"
            position="fixed"
            height="100%"
            minHeight={`calc(100% - ${isMobileView ? 54 : 78}px)`}
            maxHeight={`calc(100% - ${isMobileView ? 54 : 78}px)`}
            width={isMobileView ? "100vw" : "370px"}
            overflow="hidden"
            bgcolor={white}
            borderRight={`1px solid ${grayLight}`}
          >
            <RequestsListHeader
              mode={Quotes}
              statusSelectProps={{
                setStatus: handleSetView,
                selectedStatus: view,
              }}
            />

            <Box
              id="quotesList"
              height="100%"
              borderTop={`1px solid ${grayLight}`}
              borderBottom={`1px solid ${grayLight}`}
              overflow="auto"
            >
              {/* Do not show loading spinner for polling or pagination, 
              do show when switching views and on initial page load */}
              {quotesLoading && (!size(requests) || !hasInitialized) ? (
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress size={40} thickness={2} />
                </Box>
              ) : requests?.length ? (
                <MoovsInfiniteScroll
                  loading={quotesLoading}
                  data={quotesData?.loadQuotes}
                  next={handleFetchMore}
                  name="quotes"
                  scrollableId="quotesList"
                >
                  {requests.map((request, index) => (
                    <RequestListItem
                      key={index}
                      request={request}
                      mode={Quotes}
                    />
                  ))}
                </MoovsInfiniteScroll>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight={`calc(100% - ${isMobileView ? 54 : 78}px)`}
                  maxHeight={`calc(100% - ${isMobileView ? 54 : 78}px)`}
                  px={2}
                >
                  <EmptyList title={titleText} copy={copyText} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        {/* Right - Request Overview */}
        {requestId && requestId !== "create" && (
          <Box display="flex" flex="1" p={3}>
            <QuoteOverviewManager />
          </Box>
        )}

        {(requestId === "create" || !requestId) && !isMobileView && (
          <Box
            height="calc(100vh - 170px)"
            display="flex"
            flex="1"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h5" align="center">
              Select an order to view details.
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
}

export default QuotesPage;
