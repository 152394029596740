import React, { useState } from "react";

import { SalesAutomationIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import { useAnalytics } from "globals/hooks";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";
import { SalesAutomationDisabledVariantEnum } from "../../../../../../../types";

type UseSalesAutomationClickProps = {
  analyticsName?: string;
  salesAutomationDisabledVariant: SalesAutomationDisabledVariantEnum;
};

function useSalesAutomationClick(
  props: UseSalesAutomationClickProps
): EllipsisMenuOption & { open: boolean; onClose: () => void } {
  const { analyticsName, salesAutomationDisabledVariant } = props;

  // hooks
  const { track } = useAnalytics();

  // state
  const [salesAutomationOpen, setSalesAutomationOpen] = useState(false);

  // event handlers
  const handleSalesAutomationOpen = () => {
    setSalesAutomationOpen(true);
  };

  const handleSalesAutomationClose = () => {
    setSalesAutomationOpen(false);
  };

  const handleViewSalesAutomationClick = () => {
    handleSalesAutomationOpen();

    if (analyticsName) track(analyticsName);
  };

  return {
    text: "Enroll Quote in Sales Automation",
    icon: <SalesAutomationIcon color={grayDark} />,
    onClick: handleViewSalesAutomationClick,
    disableOption: !!salesAutomationDisabledVariant,

    // dialog state
    open: salesAutomationOpen,
    onClose: handleSalesAutomationClose,
    tooltip: salesAutomationDisabledVariant && {
      titleText: determineToolTipText(salesAutomationDisabledVariant),
    },
  };
}

export { useSalesAutomationClick };

const determineToolTipText = (
  disabledVariant: SalesAutomationDisabledVariantEnum
) => {
  if (disabledVariant === SalesAutomationDisabledVariantEnum.AlreadyEnrolled)
    return "Can't enable sales automation when quote is already enrolled in sales automation";

  if (disabledVariant === SalesAutomationDisabledVariantEnum.RequestArchived)
    return "Can't enable sales automation when quote is archived";

  if (disabledVariant === SalesAutomationDisabledVariantEnum.RequestExpired)
    return "Can't enable sales automation if pickup date is in the past";

  if (disabledVariant === SalesAutomationDisabledVariantEnum.ReservationCutoff)
    return "Can't enable sales automation after reservation cutoff date";

  if (disabledVariant === SalesAutomationDisabledVariantEnum.NoMoovsChatNumber)
    return "Can't enable sales automation when Moovs account does not have a chat number enabled";

  if (disabledVariant === SalesAutomationDisabledVariantEnum.NoVehicle)
    return "Can't enable sales automation when a vehicle is not assigned to every trip";

  if (disabledVariant === SalesAutomationDisabledVariantEnum.NoPricing)
    return "Can't enable sales automation when pricing is missing from at least one trip";

  return null;
};
