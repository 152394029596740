import React from "react";
import first from "lodash/first";

import { Box, Typography } from "@mui/material";

import { Trip } from "types";
import { grayDark } from "design-system/colors";
import {
  calculateTripEndDateTime,
  tripStartEndDateTimeParser,
} from "pages/requests/utils/calculateTripEndDateTime";

type DateTimeInfoProps = {
  trip: Trip;
};

function DateTimeInfo(props: DateTimeInfoProps) {
  const { trip } = props;

  // derived state
  const { stops, totalDuration, tripCategory } = trip;

  // only show end time for hourly rides
  const endDateTime = calculateTripEndDateTime({
    tripCategory,
    stops,
    totalDuration,
  });

  const startDateTime = first(trip.stops).dateTime;

  const tripStartEndDateString = tripStartEndDateTimeParser({
    startDateTime,
    endDateTime,
  });

  return (
    <Box>
      <Typography variant="overline" style={{ color: grayDark }}>
        DATE & TIME
      </Typography>
      <Box>
        {tripStartEndDateString.split(/\n/).map((lineString) => (
          <Typography variant="body2" key={lineString}>
            {lineString}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}

export default DateTimeInfo;
