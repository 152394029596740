import React from "react";
import first from "lodash/first";
import { useQuery } from "@apollo/client";

import { Box, Typography, Divider, Button } from "@mui/material";

import {
  RoundTripVariant,
  Trip,
  PlanVariant,
} from "../../../../../../../../../types";
import BillingDisplay from "../../../../../../../../../components/common/BillingDisplay";
import { grayDark } from "../../../../../../../../../design-system/colors";
import { getTripContactFromTrip } from "../../../../../../../requests.utils";
import MultiLineDateTime from "../../../../../../../../../components/MultiLineDateTime";
import { getTripIconAndCategoryType } from "pages/requests/utils/getTripIconAndCategoryType";
import { calculateTripEndDateTime } from "pages/requests/utils/calculateTripEndDateTime";
import FlightChangeDisplay from "../../../../../../ReservationOverview/TripItineraryBlock/components/FlightChangeDisplay";
import { LOAD_TRIP_CONFLICT_QUERY } from "globals/graphql";
import MoovsTooltip from "components/MoovsTooltip";
import CalendarErrorIcon from "design-system/icons/info/CalendarErrorIcon";
import { UpdateQuoteActionButtonVariant } from "../../../../../types";
import { useOperator } from "globals/hooks";

type TripInformationMobileProps = {
  confirmationNumber: string;
  trip: Trip;
  btnVariant: UpdateQuoteActionButtonVariant;
};

function TripInformationMobile(props: TripInformationMobileProps) {
  const { confirmationNumber, trip, btnVariant } = props;
  const { stops, totalDuration, tripCategory, roundTripVariant } = trip;
  const vehicleId = first(trip.routes).vehicle?.id;

  // hooks
  const operator = useOperator();

  const isProOrAbove =
    operator?.plan === PlanVariant.Pro || operator?.plan === PlanVariant.Vip;

  // query
  const { data } = useQuery(LOAD_TRIP_CONFLICT_QUERY, {
    variables: {
      tripId: trip.id,
      vehicleId: vehicleId,
    },
    fetchPolicy: "cache-and-network",
    skip: !vehicleId || !isProOrAbove,
  });

  const tripContact = getTripContactFromTrip(trip);
  const totalAmount = trip.totalAmount;
  const tripVehicle =
    trip && trip.routes.length && first(trip.routes).vehicle?.name;
  const needsPricing = btnVariant === UpdateQuoteActionButtonVariant.ADD_PRICE;

  const { text, icon } = getTripIconAndCategoryType({
    tripCategory,
    roundTripVariant,
    hideRoundTripVariant: true,
  });

  // only show end time for hourly rides
  const endDateTime = calculateTripEndDateTime({
    tripCategory,
    stops,
    totalDuration,
  });

  const shouldHideTripIconAndCategory =
    roundTripVariant !== RoundTripVariant.Return;

  return (
    <Box p={2} display="flex" flexDirection="column" flex="1">
      {shouldHideTripIconAndCategory && (
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          mb={2}
        >
          <Box mr={1.5}>
            <Typography variant="caption" style={{ color: grayDark }}>
              {text}
            </Typography>
          </Box>

          <Box display="flex">{icon}</Box>
        </Box>
      )}

      <Box display="flex" flex="1" flexDirection="row">
        <Box display="flex" flexDirection="column" flex="1" mr={2}>
          <Typography variant="overline">date & time</Typography>
          {first(trip.stops).dateTime ? (
            <MultiLineDateTime
              dateTime={first(trip.stops).dateTime}
              endDateTime={endDateTime}
              icon={
                <FlightChangeDisplay
                  flightOffset={first(trip.stops).flightOffset}
                  originalDateTime={first(trip.stops).originalDateTime}
                  dateTime={first(trip.stops).dateTime}
                  iconSize="small"
                />
              }
            />
          ) : (
            "Date/Time missing"
          )}
        </Box>
        <Box display="flex" flexDirection="column" flex="1">
          <Typography variant="overline">amount</Typography>
          <BillingDisplay
            amount={totalAmount}
            variant="caption"
            showDashWhenNoAmount
            hideColor
          />
        </Box>
      </Box>
      <Box my={2}>
        <Divider />
      </Box>
      <Box display="flex" flex="2" flexDirection="column">
        <Box display="flex" flex="1" flexDirection="row">
          <Box display="flex" flexDirection="column" flex="1" mb={1}>
            <Typography variant="overline">conf. no.</Typography>
            <Box mb="1px">
              <Typography variant="caption">{confirmationNumber}</Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" flex="1">
            <Typography variant="overline">vehicle</Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="caption" mr={0.5}>
                {tripVehicle || "-"}
              </Typography>
              {data?.loadTripConflict && (
                <MoovsTooltip tooltipText="Vehicle Conflict">
                  <CalendarErrorIcon />
                </MoovsTooltip>
              )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" mb={1}>
          <Typography variant="overline">passenger</Typography>
          <Box mb="1px">
            <Typography variant="caption">{tripContact.name}</Typography>
          </Box>
          {!!tripContact.mobilePhone && (
            <Typography variant="caption">{tripContact.mobilePhone}</Typography>
          )}
        </Box>

        <Button
          fullWidth
          variant={needsPricing ? "contained" : "outlined"}
          color="primary"
        >
          {needsPricing ? "Add Price" : "View Trip"}
        </Button>
      </Box>
    </Box>
  );
}

export default TripInformationMobile;
