import { fromGlobalId } from "globals/utils/helpers";
import { useEffect } from "react";
import { useOperator } from "./useOperator";

declare const window: { UserLeap: any };

function useUserLeapInit() {
  // hooks
  const operator = useOperator();

  // init
  useEffect(() => {
    if (!operator) return;

    window.UserLeap("setUserId", fromGlobalId(operator.id).id);
  }, [operator]);
}

export { useUserLeapInit };
