import some from "lodash/some";

import { UserAccessPermission } from "types";

type GetRedirectPathBasedOnPermissionsParams = {
  userAccessPermissions: UserAccessPermission[];
  preferredPath?: string;
};

/**
 * Gets the redirect path based on the access permissions a user has.
 * If a preferredPath is passed in, we will see if the user has permission to
 * that path first.
 * 
 * WARNING: this does not account for paths without access permission records (ex. /card, /onboarding, /getting-started, etc).
 * If you want to use this util with those desired paths, the logic will need to be modified.
 */
export const getRedirectPathBasedOnPermissions = (
  params: GetRedirectPathBasedOnPermissionsParams
) => {
  const { userAccessPermissions, preferredPath } = params;

  if (preferredPath) {
    const hasAccessToPreferredPath = some(
      userAccessPermissions,
      (userAccessPermission) =>
        preferredPath.includes(userAccessPermission.path)
    );

    if (hasAccessToPreferredPath) {
      return preferredPath;
    }
  }

  const firstHomePermission = userAccessPermissions.find(
    (permission) => permission.category === "home" && permission.path !== "chat"
  );

  let redirectPath = firstHomePermission?.path;

  if (!redirectPath) {
    // Fall back to the blank chat page if it's the member's only home permission
    const isChatAccessible = some(
      userAccessPermissions,
      (permission) => permission.path === "chat"
    );

    redirectPath = isChatAccessible ? "/chat" : "/";
  }

  return redirectPath;
};
