import React, { useEffect, useMemo, useState } from "react";
import {
  useForm,
  FormProvider,
  UseFormReturn,
  useFormContext,
  FieldNamesMarkedBoolean,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import omit from "lodash/omit";
import isNil from "lodash/isNil";

import { Vehicle, VehiclePhoto } from "types";
import { ApolloError, ApolloQueryResult, useLazyQuery } from "@apollo/client";
import { LOAD_VEHICLE_QUERY } from "globals/graphql";
import { HistoryState } from "../UpdateVehicleDrawer";

const childSeatSchema = yup.object({
  id: yup.string(),
  type: yup.string(),
  active: yup.boolean(),
  quantity: yup.number(),
  amt: yup.number(),
  imageUrl: yup.string(),
  description: yup
    .string()
    .nullable()
    .max(35, "Description cannot be more than 35 characters"),
});

const updateVehicleFormSchema = yup.object({
  vehicle: yup.object({
    id: yup.string(),
    insuranceId: yup.string().nullable(),
    cancellationPolicyId: yup.string().nullable(),
    // Details Tab
    name: yup.string().required("Vehicle name is required"),
    typeSlug: yup.string().required("Vehicle type is required"),
    capacity: yup
      .number()
      .typeError("Vehicle passenger capacity is required")
      .required("Vehicle passenger capacity is required")
      .min(1, "Capacity must be at least 1")
      .max(100, "Capacity must be less than 100"),
    licensePlate: yup
      .string()
      .nullable()
      .max(8, "License plate cannot be more than 8 characters"),
    description: yup.string().nullable(),
    exteriorColor: yup.string().nullable(),
    vinNumber: yup.string().nullable(),
    // Pricing Tab
    // Transfer Pricing
    minimumTotalBaseRate: yup
      .number()
      .nullable()
      .test(
        "transfer-pricing-coupling",
        "Minimum total base rate required to use transfer pricing",
        function (value) {
          const { deadheadRatePerMile, tripRatePerMile } = this.parent;
          if (deadheadRatePerMile !== null || tripRatePerMile !== null) {
            return value !== null;
          }
          return true;
        }
      ),
    deadheadRatePerMile: yup
      .number()
      .nullable()
      .test(
        "transfer-pricing-coupling",
        "Deadhead rate per mile required to use transfer pricing",
        function (value) {
          const { minimumTotalBaseRate, tripRatePerMile } = this.parent;
          if (minimumTotalBaseRate !== null || tripRatePerMile !== null) {
            return value !== null;
          }
          return true;
        }
      ),
    tripRatePerMile: yup
      .number()
      .nullable()
      .test(
        "transfer-pricing-coupling",
        "Trip rate per mile required to use transfer pricing",
        function (value) {
          const { minimumTotalBaseRate, deadheadRatePerMile } = this.parent;
          if (minimumTotalBaseRate !== null || deadheadRatePerMile !== null) {
            return value !== null;
          }
          return true;
        }
      ),
    // Hourly Pricing
    totalDeadheadDurationMinutes: yup.number().nullable(),
    weekdayHourlyCost: yup
      .number()
      .nullable()
      .test(
        "hourly-pricing-coupling",
        "Weekday hourly rate required to use hourly pricing",
        function (value) {
          const { weekdayMinMinutes } = this.parent;
          if (weekdayMinMinutes !== null) {
            return value !== null;
          }
          return true;
        }
      ),
    weekdayMinMinutes: yup
      .number()
      .nullable()
      .test(
        "hourly-pricing-coupling",
        "Weekday hourly minimum required to use hourly pricing",
        function (value) {
          const { weekdayHourlyCost } = this.parent;
          if (weekdayHourlyCost !== null) {
            return value !== null;
          }
          return true;
        }
      ),
    // Weekend Pricing
    weekendHourlyCost: yup
      .number()
      .nullable()
      .test(
        "hourly-weekend-pricing-coupling",
        "Weekend hourly cost required to use hourly pricing",
        function (value) {
          const { weekendMinMinutes, weekends } = this.parent;
          if (weekendMinMinutes !== null || weekends.length > 0) {
            return value !== null;
          }
          return true;
        }
      ),
    weekendMinMinutes: yup
      .number()
      .nullable()
      .test(
        "hourly-weekend-pricing-coupling",
        "Weekend hourly minimum required to use hourly pricing",
        function (value) {
          const { weekendHourlyCost, weekends } = this.parent;
          if (weekendHourlyCost !== null || weekends.length > 0) {
            return value !== null;
          }
          return true;
        }
      ),
    weekends: yup
      .array()
      .of(
        yup.object({
          name: yup.string(),
          value: yup.string(),
          index: yup.number(),
        })
      )
      .test(
        "hourly-weekend-pricing-coupling",
        "Select weekends to use weekend hourly pricing",
        function (value) {
          const { weekendHourlyCost, weekendMinMinutes } = this.parent;
          if (weekendHourlyCost !== null || weekendMinMinutes !== null) {
            return value.length > 0;
          }
          return true;
        }
      ),
    enableBaseRateAutomation: yup.boolean(),
    available: yup.boolean().required("Vehicle availability is required"),
    enableBaseRateAutomationBookingTool: yup.boolean(),
    publishedToDudaSite: yup.boolean(),
    settings: yup
      .object({
        id: yup.string(),
        conflictBlockQuote: yup.boolean(),
        conflictBlockReservation: yup.boolean(),
        pricelessBookingEnabled: yup.boolean(),
        pricelessBookingTarget: yup.string(),
        pricelessBookingCompanies: yup.array().of(
          yup.object({
            id: yup.string(),
            name: yup.string(),
          })
        ),
        pricelessBookingContacts: yup.array().of(
          yup.object({
            id: yup.string(),
            name: yup.string(),
          })
        ),
      })
      .nullable(),
  }),
  features: yup.array().of(
    yup.object({
      id: yup.string(),
      name: yup.string(),
      category: yup.string(),
    })
  ),
  childSeats: yup
    .object({
      rearFacingSeat: childSeatSchema,
      forwardFacingSeat: childSeatSchema,
      boosterSeat: childSeatSchema,
    })
    .nullable(),
});

export type UpdateVehicleFormData = yup.InferType<
  typeof updateVehicleFormSchema
>;

type UseUpdateVehicleFormReturn = {
  readyOnlyVehicle: Vehicle | null;
  vehicleRefetch: () => Promise<ApolloQueryResult<{ node: Vehicle }>>;
  photos: VehiclePhoto[] | null;
  setPhotos: (photos: VehiclePhoto[] | null) => void;
  isLoading: boolean;
  loadVehicleError?: ApolloError;
  methods: UseFormReturn<UpdateVehicleFormData>;
  isDirty: boolean;
  dirtyFields: FieldNamesMarkedBoolean<UpdateVehicleFormData>;
  getDuplicateVehicle: () => HistoryState;
};

export const useUpdateVehicleForm = (
  vehicleId: string
): UseUpdateVehicleFormReturn => {
  // state
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [photos, setPhotos] = useState<VehiclePhoto[] | null>(null);

  // queries
  const [loadVehicle, { called, loading, error }] = useLazyQuery<{
    node: Vehicle;
  }>(LOAD_VEHICLE_QUERY, {
    variables: {
      id: vehicleId,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setVehicle(data.node);
      setPhotos(data.node.photos);
    },
  });
  const vehicleRefetch = () => loadVehicle({ fetchPolicy: "network-only" });

  // hooks
  const defaultValues = useMemo(() => getDefaultValues(vehicle), [vehicle]);
  const methods = useForm<UpdateVehicleFormData>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(updateVehicleFormSchema),
  });

  const {
    formState: { isDirty, dirtyFields },
    reset,
  } = methods;

  // effects
  useEffect(() => {
    loadVehicle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  // return error state
  if (error) {
    return {
      readyOnlyVehicle: vehicle,
      vehicleRefetch,
      photos,
      setPhotos,
      isLoading: false,
      methods,
      isDirty,
      dirtyFields,
      getDuplicateVehicle: (): HistoryState => ({
        clonedVehicle: null,
        clonedPhotos: null,
        clonedFeatures: null,
        clonedSettings: null,
      }),
      loadVehicleError: error,
    };
  }

  // return loading state
  if ((loading && called) || !vehicle) {
    return {
      readyOnlyVehicle: vehicle,
      vehicleRefetch,
      photos,
      setPhotos,
      isLoading: true,
      methods,
      isDirty,
      dirtyFields,
      getDuplicateVehicle: (): HistoryState => ({
        clonedVehicle: null,
        clonedPhotos: null,
        clonedFeatures: null,
        clonedSettings: null,
      }),
    };
  }

  // getters
  const getDuplicateVehicle = (): HistoryState => {
    return {
      clonedVehicle: omit(vehicle, "comments"),
      clonedPhotos: photos,
      clonedFeatures: vehicle.features,
      clonedSettings: vehicle.settings,
    };
  };

  return {
    readyOnlyVehicle: vehicle,
    vehicleRefetch,
    photos,
    setPhotos,
    isLoading: loading,
    // form data
    methods,
    isDirty,
    dirtyFields,
    getDuplicateVehicle,
  };
};

export const UpdateVehicleFormProvider = (props: {
  methods: UseFormReturn<UpdateVehicleFormData>;
  children: React.ReactNode;
}) => {
  const { methods, children } = props;

  return (
    <FormProvider {...methods}>
      <form>{children}</form>
    </FormProvider>
  );
};

export const useUpdateVehicleFormContext =
  (): UseFormReturn<UpdateVehicleFormData> | null => {
    const context = useFormContext<UpdateVehicleFormData>();
    if (!context) {
      console.error(
        "useUpdateVehicleFormContext must be used within an UpdateVehicleFormProvider"
      );
      return null;
    }

    return context;
  };

const getDefaultValues = (vehicle: Vehicle): UpdateVehicleFormData => ({
  vehicle: {
    id: vehicle?.id || "",
    insuranceId: vehicle?.insurancePolicy?.id || null,
    cancellationPolicyId: vehicle?.cancellationPolicy?.id || null,
    available: vehicle?.available || false,
    name: vehicle?.name || "",
    capacity: vehicle?.capacity || 0,
    licensePlate: vehicle?.licensePlate || null,
    description: vehicle?.description || null,
    weekendHourlyCost: isNil(vehicle?.weekendHourlyCost)
      ? null
      : vehicle?.weekendHourlyCost,
    weekdayHourlyCost: isNil(vehicle?.weekdayHourlyCost)
      ? null
      : vehicle?.weekdayHourlyCost,
    weekendMinMinutes: isNil(vehicle?.weekendMinMinutes)
      ? null
      : vehicle?.weekendMinMinutes,
    weekdayMinMinutes: isNil(vehicle?.weekdayMinMinutes)
      ? null
      : vehicle?.weekdayMinMinutes,
    weekends: vehicle?.settings?.weekends || null,
    typeSlug: vehicle?.vehicleType?.typeSlug || "sedan",
    exteriorColor: vehicle?.exteriorColor || null,
    vinNumber: vehicle?.vinNumber || null,
    minimumTotalBaseRate: isNil(vehicle?.minimumTotalBaseRate)
      ? null
      : vehicle?.minimumTotalBaseRate,
    deadheadRatePerMile: isNil(vehicle?.deadheadRatePerMile)
      ? null
      : vehicle?.deadheadRatePerMile,
    tripRatePerMile: isNil(vehicle?.tripRatePerMile)
      ? null
      : vehicle?.tripRatePerMile,
    totalDeadheadDurationMinutes: isNil(vehicle?.totalDeadheadDurationMinutes)
      ? null
      : vehicle?.totalDeadheadDurationMinutes,
    enableBaseRateAutomation: vehicle?.enableBaseRateAutomation || false,
    enableBaseRateAutomationBookingTool:
      vehicle?.enableBaseRateAutomationBookingTool || false,
    publishedToDudaSite: vehicle?.publishedToDudaSite || false,
    settings: vehicle?.settings
      ? {
          ...vehicle.settings,
          pricelessBookingCompanies:
            vehicle.settings.pricelessBookingCompanies || [],
          pricelessBookingContacts:
            vehicle.settings.pricelessBookingContacts.map((contact) => ({
              id: contact.id,
              name: `${contact.firstName} ${contact.lastName}`,
            })) || [],
        }
      : null,
  },
  features: vehicle?.features || null,
  childSeats: vehicle?.settings
    ? {
        rearFacingSeat: {
          ...vehicle.settings.rearFacingSeat,
          description: vehicle.settings.rearFacingSeat.description || null,
        },
        forwardFacingSeat: {
          ...vehicle.settings.forwardFacingSeat,
          description: vehicle.settings.forwardFacingSeat.description || null,
        },
        boosterSeat: {
          ...vehicle.settings.boosterSeat,
          description: vehicle.settings.boosterSeat.description || null,
        },
      }
    : null,
});
