import React from "react";
import { useHistory } from "react-router-dom";
import { DeepPartial } from "ts-essentials";

import { Box, Typography, Hidden, Card, CardActionArea } from "@mui/material";

import InsurancePolicyListItem from "./InsurancePolicyListItem";
import { InsurancePolicy } from "../../../types";
import { currency } from "../../../globals/utils/helpers";

const styles = {
  subtitle: {
    fontWeight: "bold",
  },
};

type InsurancePolicyListProps = {
  insurancePolicies: DeepPartial<InsurancePolicy[]>;
  sxStyles: any;
};

function InsurancePolicyList(props: InsurancePolicyListProps) {
  const { insurancePolicies, sxStyles } = props;
  const history = useHistory();

  const handleListItemClick = (insuranceId: string) => {
    history.push(`/settings/insurance/update/${insuranceId}`);
  };

  return (
    <>
      {/* Large screen view */}
      <Hidden mdDown>
        <Box sx={sxStyles}>
          <Box display="flex" flexDirection="row" flex="1" px={3} mb={1}>
            <Box display="flex" flex="2">
              <Typography variant="overline">NAME</Typography>
            </Box>
            <Box display="flex" flex="1">
              <Typography variant="overline">NUMBER</Typography>
            </Box>
            <Box display="flex" flex="1">
              <Typography variant="overline">AMOUNT</Typography>
            </Box>
            <Box display="flex" flex="1">
              <Typography variant="overline">DATE</Typography>
            </Box>
          </Box>
          {insurancePolicies.map((insurancePolicy: InsurancePolicy) => (
            <Box mb={1} key={insurancePolicy.id}>
              <InsurancePolicyListItem
                insurancePolicy={insurancePolicy}
                onClick={handleListItemClick}
              />
            </Box>
          ))}
        </Box>
      </Hidden>
      {/* Small screen view */}
      <Hidden mdUp>
        <Box px={2}>
          {insurancePolicies.map((insurancePolicy: InsurancePolicy) => (
            <Box key={insurancePolicy.id}>
              <Card variant="outlined">
                <CardActionArea
                  disableRipple
                  onClick={() => handleListItemClick(insurancePolicy.id)}
                >
                  <Box display="flex" flexDirection="column" flex="1" p={2}>
                    <Box display="flex" flex="1" mb={1}>
                      <Typography variant="subtitle1" sx={styles.subtitle}>
                        {insurancePolicy.insuranceCompany}
                      </Typography>
                    </Box>
                    <Box display="flex" flex="1" flexDirection="row">
                      <Box display="flex" flex="1" flexDirection="column">
                        <Typography variant="overline">Policy</Typography>
                        <Typography variant="caption">
                          {insurancePolicy.policyNumber}
                        </Typography>
                      </Box>
                      <Box display="flex" flex="1" flexDirection="column">
                        <Typography variant="overline">Amount</Typography>
                        <Typography variant="caption">
                          {currency(insurancePolicy.coverageAmount)}
                        </Typography>
                      </Box>
                      <Box display="flex" flex="1" flexDirection="column">
                        <Typography variant="overline">Date</Typography>
                        <Typography variant="caption">
                          {insurancePolicy.startDate}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </CardActionArea>
              </Card>
            </Box>
          ))}
        </Box>
      </Hidden>
    </>
  );
}

export default InsurancePolicyList;
