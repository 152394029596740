import { isValidPhoneNumber } from "globals/utils/helpers";

type ValidateUserInput = {
  firstName: string;
  lastName: string;
  mobilePhone: string;
  phoneCountryCode: string;
  phoneCountryDialCode: string;
  phoneCountryName: string;
  phoneCountryFormat: string;
  email: string;
  roleSlug: string;
  defaultScreen: string;
};

export const validateUserInfo = (user: ValidateUserInput) => {
  let errors = [];

  if (!user.firstName) {
    errors = [...errors, { firstName: "Please enter first name" }];
  }
  if (!user.lastName) {
    errors = [...errors, { lastName: "Please enter last name" }];
  }

  if (!user.email.trim()) {
    errors = [...errors, { email: "Please enter email" }];
  }

  if (!user.roleSlug) {
    errors = [...errors, { roleSlug: "Please select role" }];
  }

  if (!user.defaultScreen) {
    errors = [...errors, { defaultScreen: "Please select a default screen" }];
  }

  if (!user.mobilePhone) {
    errors = [...errors, { mobilePhone: "Please enter a phone number" }];
  }

  if (user.phoneCountryCode === "us" && !isValidPhoneNumber(user.mobilePhone)) {
    errors = [...errors, { mobilePhone: "Please enter 10 digit phone number" }];
  }

  return errors;
};
