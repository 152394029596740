import React, { useState, useEffect, ChangeEvent } from "react";
import isNull from "lodash/isNull";
import { useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";

import {
  Box,
  MenuItem,
  Typography,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";

import GQLQueryStatusIndicator from "components/GQLQueryStatusIndicator";
import { FarmAffiliateVehicle, Stop } from "types";
import { LOAD_VEHICLE_TYPES_QUERY } from "globals/graphql";
import { FarmAffiliateVehicleCard } from "components/vehicles/VehicleCard";
import MoovsDialog from "components/MoovsDialog";
import { useScreenSize } from "globals/hooks";
import { errorRed } from "design-system/colors";
import FarmOutNoAffiliateVehiclesBlock from "./FarmOutNoAffiliateVehiclesBlock";
import MoovsInfiniteScroll from "components/MoovsInfiniteScroll";
import useLoadFarmAffiliateVehiclesQuery from "globals/graphql/farming/useLoadFarmAffiliateVehiclesQuery";
import map from "lodash/map";

type SharedFarmAffiliateVehiclesDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  tripId?: string; // provide when trip already exists
  stops?: Stop[]; // provide during creating (trip does not yet exist)
};

type AddVehicleDialogProps = {
  isReadOnly?: false;
  onAcceptClick: (vehicles: FarmAffiliateVehicle[]) => void;
  acceptButtonText: string;
} & SharedFarmAffiliateVehiclesDialogProps;

type ReadOnlyDialogProps = {
  isReadOnly: true;
  onAcceptClick?: undefined;
  acceptButtonText?: string;
} & SharedFarmAffiliateVehiclesDialogProps;

type FarmAffiliateVehiclesDialogProps =
  | AddVehicleDialogProps
  | ReadOnlyDialogProps;

function FarmAffiliateVehiclesDialog(props: FarmAffiliateVehiclesDialogProps) {
  const {
    open,
    title,
    acceptButtonText,
    onClose,
    onAcceptClick,
    tripId,
    stops,
    isReadOnly,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // state
  const [selectedVehicles, setSelectedVehicles] = useState<string[]>([]);
  const [filterType, setFilteredType] = useState("All");
  const [errorMessage, setErrorMessage] = useState("");
  const [globalSearch, setGlobalSearch] = useState("");
  const [hasInitialFarmVehicleResults, sethasInitialFarmVehicleResults] =
    useState<boolean | null>(null);
  const [debouncedInput] = useDebounce(globalSearch, 400);

  const {
    data: vehiclesData,
    error,
    refetch,
    loading,
    fetchMore,
  } = useLoadFarmAffiliateVehiclesQuery({
    queryArgs: {
      tripId,
      stops,
      searchFilter: debouncedInput,
      vehicleTypeSlug: filterType,
    },
    shouldSkip: !open,
  });

  const { data: vehicleTypesData } = useQuery(LOAD_VEHICLE_TYPES_QUERY);

  // shaped data
  const vehicles: FarmAffiliateVehicle[] = map(
    vehiclesData?.loadFarmAffiliateVehicles?.edges,
    "node"
  );

  const vehicleTypes = vehicleTypesData?.vehicleTypes || [];

  // effects
  // whenever dialog reopens, reset back to initial state.
  // TODO: this may be better to do on close. But best future optimization
  // is only render the dialog at the moment it's required to be open
  useEffect(() => {
    if (open) {
      refetch();
      setFilteredType("All");
      setSelectedVehicles([]);
      setErrorMessage("");
      sethasInitialFarmVehicleResults(null);
    }
  }, [open, refetch]);

  // sets initial value on if there are search results.
  useEffect(() => {
    if (vehiclesData && isNull(hasInitialFarmVehicleResults)) {
      sethasInitialFarmVehicleResults(
        !!vehiclesData?.loadFarmAffiliateVehicles?.edges.length
      );
    }
  }, [hasInitialFarmVehicleResults, vehiclesData]);

  const handleVehicleItemClick = (vehicle: FarmAffiliateVehicle) => {
    setSelectedVehicles([vehicle.id]);
  };

  // event handlers
  const handleFetchMore = () => {
    fetchMore({
      variables: {
        cursor: vehiclesData?.loadFarmAffiliateVehicles?.pageInfo.endCursor,
      },
    });
  };

  const handleFilteredTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilteredType(event.target.value as string);
  };

  const handleSaveAddClick = () => {
    if (!selectedVehicles.length) {
      setErrorMessage("At least one vehicle must be selected.");
    } else {
      setErrorMessage("");
      if (onAcceptClick) {
        onAcceptClick(
          vehicles.filter((vehicle: FarmAffiliateVehicle) =>
            selectedVehicles.includes(vehicle.id)
          )
        );
      }
    }
  };

  const hasFarmAffiliateVehicles =
    !!vehiclesData?.loadFarmAffiliateVehicles &&
    vehiclesData?.loadFarmAffiliateVehicles?.edges?.length > 0;
  const noFarmAffiliateVehicles =
    !!vehiclesData && hasInitialFarmVehicleResults === false;
  const shouldRenderVehiclesList = hasInitialFarmVehicleResults && vehiclesData;

  return (
    <>
      <MoovsDialog
        scrollableId="scrollableVehiclesDialog"
        open={open}
        size="sm"
        onClose={onClose}
        dialogTitle={title}
        acceptButtonText={acceptButtonText}
        fixedFooter
        {...(hasFarmAffiliateVehicles &&
          !!onAcceptClick && { onAccept: handleSaveAddClick })}
        {...(isReadOnly && {
          removeCloseButton: true,
          acceptButtonText: "Close",
          onAccept: onClose,
        })}
      >
        <Box minHeight="90vh">
          {error && (
            <Box height={160}>
              <GQLQueryStatusIndicator
                data={vehiclesData}
                loading={loading}
                error={error}
                name="Vehicle"
              />
            </Box>
          )}

          {errorMessage && (
            <Box p={2}>
              <Typography style={{ color: errorRed }}>
                {errorMessage}
              </Typography>
            </Box>
          )}

          {/* !!vehiclesData included so as to make sure this component is rendered
          only after vehiclesData becomes available with or without loadFarmAffiliateVehicles */}
          {noFarmAffiliateVehicles ? (
            <Box mt={7}>
              <FarmOutNoAffiliateVehiclesBlock tripId={tripId} />
            </Box>
          ) : (
            <Box data-testid="vehicles-dialog" m={1}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    select
                    name="typeSlug"
                    variant="outlined"
                    label="Vehicle Type"
                    value={filterType}
                    onChange={handleFilteredTypeChange}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {vehicleTypes.map((vehicleType, index) => (
                      <MenuItem key={index} value={vehicleType.typeSlug}>
                        {vehicleType.typeName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={isMobileView ? "Search" : "Search vehicle"}
                    fullWidth
                    value={globalSearch || ""}
                    variant="outlined"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setGlobalSearch(event.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              {shouldRenderVehiclesList && (
                <MoovsInfiniteScroll
                  loading={loading}
                  data={vehiclesData?.loadFarmAffiliateVehicles}
                  next={handleFetchMore}
                  name="vehicles"
                  scrollableId="scrollableVehiclesDialog"
                >
                  {vehicles.map((vehicle: FarmAffiliateVehicle) => (
                    <Box
                      my={1}
                      key={vehicle.id}
                      data-testid="vehicle-dialog-list-vehicle-card"
                    >
                      <FarmAffiliateVehicleCard
                        vehicle={vehicle}
                        isReadOnly={isReadOnly}
                        selectedVehicles={selectedVehicles}
                        onSelectVehicle={handleVehicleItemClick}
                      />
                    </Box>
                  ))}
                </MoovsInfiniteScroll>
              )}
            </Box>
          )}

          {loading && (
            <Box
              p={4}
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress size={40} thickness={2} />
                <Box mt={0.5}>
                  <Typography>Loading vehicles....</Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </MoovsDialog>
    </>
  );
}

export default FarmAffiliateVehiclesDialog;
