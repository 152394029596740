/**
 * Fields Rendered Hook provides pricing fields to be displayed
 * when creating, updating, or adding pricing preferences.
 *
 * The default pricing layout for the operator's preferences
 * within settings defaults to display driver gratuity and tax
 * as opposed to creating or updating a quote or reservation where
 * there are no default optional pricing fields displayed.
 */

import { useEffect, useState } from "react";
import isNil from "lodash/isNil";
import isEqual from "lodash/isEqual";

import { PricingFieldsRendered } from "components/pricing/utils";
import { RoutePricing, PricingLayout } from "types";
import { usePrevious } from "./usePrevious";

type UseFieldsRenderedProps = {
  pricing: RoutePricing | PricingLayout;
};

function useFieldsRendered(
  props: UseFieldsRenderedProps
): [
  PricingFieldsRendered,
  React.Dispatch<React.SetStateAction<PricingFieldsRendered>>
] {
  const { pricing } = props;

  const pricingFieldsRendered = constructPricingLayoutFields(pricing);

  // state
  const [fieldsRendered, setFieldsRendered] = useState<PricingFieldsRendered>(
    () => pricingFieldsRendered
  );

  // hooks
  const prevPricingId = usePrevious("id" in pricing ? pricing.id : null);

  // reset fields rendered when pricing id has changed
  useEffect(() => {
    if ("id" in pricing && !isEqual(prevPricingId, pricing.id)) {
      setFieldsRendered(pricingFieldsRendered);
    }
  }, [pricing, prevPricingId, pricingFieldsRendered]);

  return [fieldsRendered, setFieldsRendered];
}

const constructPricingLayoutFields = (
  pricing: RoutePricing | PricingLayout
) => ({
  driverGratuityAmt: !isNil(pricing?.driverGratuityAmt),
  driverGratuityPercent: !isNil(pricing?.driverGratuityPercent),
  promoDiscountAmt: !isNil(pricing?.promoDiscountAmt),
  promoDiscountPercent: !isNil(pricing?.promoDiscountPercent),
  taxPercent: !isNil(pricing?.taxPercent),
  taxAmt: !isNil(pricing?.taxAmt),
  tollsAmt: !isNil(pricing?.tollsAmt),
  meetGreetAmt: !isNil(pricing?.meetGreetAmt),
  otherAmt: !isNil(pricing?.otherAmt),
  otherPercent: !isNil(pricing?.otherPercent),
  other2Amt: !isNil(pricing?.other2Amt),
  other2Percent: !isNil(pricing?.other2Percent),
  other3Amt: !isNil(pricing?.other3Amt),
  other3Percent: !isNil(pricing?.other3Percent),
  ...("promoCodeAmt" in pricing && {
    promoCodeAmt: !isNil(pricing?.promoCodeAmt),
  }),
  ...("promoCodePercent" in pricing && {
    promoCodePercent: !isNil(pricing?.promoCodePercent),
  }),
  ...("forwardFacingSeatAmt" in pricing && {
    forwardFacingSeatAmt: !isNil(pricing?.forwardFacingSeatAmt),
  }),
  ...("rearFacingSeatAmt" in pricing && {
    rearFacingSeatAmt: !isNil(pricing?.rearFacingSeatAmt),
  }),
  ...("boosterSeatAmt" in pricing && {
    boosterSeatAmt: !isNil(pricing?.boosterSeatAmt),
  }),
});

export { useFieldsRendered, constructPricingLayoutFields };
