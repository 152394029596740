import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Button } from "@mui/material";

import { white } from "design-system/colors";
import { AddTripIcon } from "design-system/icons";
import { Request, TripCategory } from "types";

type AddTripButtonProps = {
  request: Request;
};

const AddTripButton = (props: AddTripButtonProps) => {
  const { request } = props;

  const history = useHistory();

  const handleButtonClick = () => {
    if (history.location.pathname.includes("/add-trip")) return;

    const hasTrailingSlash = history.location.pathname.endsWith("/");
    const trailingSlash = hasTrailingSlash ? "" : "/";
    const tripCategory = request.trips[0].tripCategory;
    const isShuttleReservation =
      tripCategory === TripCategory.ShuttlePickUp ||
      tripCategory === TripCategory.ShuttleReturn;

    if (isShuttleReservation) {
      history.push(
        `/reservations/shuttle/${request.id}/add-trip/${history.location.search}`,
        { request }
      );
      return;
    }

    history.push(
      `${history.location.pathname}${trailingSlash}add-trip/${history.location.search}`,
      { request }
    );
  };

  return (
    <Button
      onClick={handleButtonClick}
      variant="contained"
      color="primary"
      endIcon={
        <Box position="relative" top="9px">
          <AddTripIcon color={white} />
        </Box>
      }
      fullWidth
    >
      Add Trip
    </Button>
  );
};

export default AddTripButton;
