import * as yup from "yup";

export enum WorkflowSelectionsEnum {
  date = "date",
  dateRange = "dateRange",
  timesOfDay = "timesOfDay",
}

export const workflowSchema = yup.object({
  workflow: yup
    .mixed()
    .oneOf(
      [
        WorkflowSelectionsEnum.date,
        WorkflowSelectionsEnum.dateRange,
        WorkflowSelectionsEnum.timesOfDay,
      ],
      "Please make a selection"
    )
    .required(),
});
