import React, { Dispatch, SetStateAction } from "react";

import { Typography, Box, Card, Divider } from "@mui/material";

import { OrderType } from "../../../types";
import OrderTypeItem from "./OrderTypeItem";
import { grayLight } from "design-system/colors";

type OrderTypesCardProps = {
  orderTypes: OrderType[];
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function OrderTypesCard(props: OrderTypesCardProps) {
  const { orderTypes, setSaveIndicatorState } = props;

  const category = orderTypes[0].category;

  return (
    <>
      <Card variant="outlined">
        <Box p={2}>
          <Typography variant="h4">{category}</Typography>

          <Box my={2}>
            <Divider style={{ color: grayLight }} />
          </Box>

          {orderTypes.map((orderType) => {
            const { id, name } = orderType;

            return (
              <OrderTypeItem
                key={id}
                id={id}
                name={name}
                setSaveIndicatorState={setSaveIndicatorState}
              />
            );
          })}
        </Box>
      </Card>
    </>
  );
}

export default OrderTypesCard;
