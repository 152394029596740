import React, { PropsWithChildren } from "react";

import { Box, Typography } from "@mui/material";

import { useScreenSize } from "globals/hooks";
import { black, granite, white } from "design-system/colors";

type InstallationStepProps = PropsWithChildren<{
  stepNumber: number;
  title: string;
  subtitle?: string;
}>;

function InstallationStep(props: InstallationStepProps) {
  const { stepNumber, title, subtitle, children } = props;

  const { isMobileView } = useScreenSize();

  return (
    <Box display="flex">
      <Box
        style={{
          borderRadius: "50%",
          backgroundColor: granite,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          minWidth: "40px",
          height: "40px",
          marginRight: "16px",
        }}
      >
        <Typography variant="overline" fontSize={16} color={white}>
          {stepNumber}
        </Typography>
      </Box>
      <Box pt={1} width="100%">
        <Typography
          variant="caption"
          fontSize={16}
          color={black}
          fontWeight={600}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="body2"
            fontSize={14}
            color={granite}
            pr={isMobileView ? 0 : 25}
          >
            {subtitle}
          </Typography>
        )}
        {children}
      </Box>
    </Box>
  );
}

export { InstallationStep };
