import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { Box, CircularProgress } from "@mui/material";

import { LOAD_TRIP_QUERY } from "../../../globals/graphql";
import GQLQueryStatusIndicator from "../../GQLQueryStatusIndicator";
import { Trip } from "../../../types";
import PaymentsBlock from "../../invoices/UpdateInvoiceDrawer/components/PaymentsBlock/PaymentsBlock";

type MoneyTabViewProps = {
  setSaveIndicatorState: (
    saveState: "loading" | "default" | "saved" | "error"
  ) => void;
  hidden: boolean;
  requestRefetch: () => void;
};

function MoneyTabView(props: MoneyTabViewProps) {
  const { setSaveIndicatorState, hidden, requestRefetch } = props;

  const { tripId } = useParams<{ tripId: string }>();

  // queries
  const {
    data: tripData,
    loading: tripLoading,
    refetch: tripRefetch,
    error: tripError,
  } = useQuery(LOAD_TRIP_QUERY, {
    variables: {
      id: tripId,
    },
    skip: !tripId,
    errorPolicy: "all",
  });

  useEffect(() => {
    if (!hidden) {
      tripRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden]);

  const trip: Trip = tripData?.node;

  return (
    <Box flex="1" display={hidden ? "none" : undefined}>
      {tripLoading && (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={40} thickness={2} />
        </Box>
      )}
      {tripError && !tripLoading && (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <GQLQueryStatusIndicator
            name="Trip"
            data={tripData}
            error={tripError}
            refetch={tripRefetch}
          />
        </Box>
      )}
      {trip && (
        <>
          <Box my={3}>
            <PaymentsBlock
              trip={trip}
              setSaveIndicatorState={setSaveIndicatorState}
              tripRefetch={tripRefetch}
              requestRefetch={requestRefetch}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default MoneyTabView;
