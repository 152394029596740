import React, { ChangeEvent, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import { Box, Typography, TextField } from "@mui/material";
import InputMask from "react-input-mask";

import { Company } from "../../../types";
import {
  CREATE_COMPANY_MUTATION,
  LOAD_COMPANIES_QUERY,
} from "../../../globals/graphql";
import CreateDrawer from "../../globals/CreateDrawer";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import { useHistory } from "react-router-dom";
import { isValidPhoneNumber } from "../../../globals/utils/helpers";
import LocationAutoComplete from "../../autocompletes/LocationAutoComplete";
import { useAnalytics } from "../../../globals/hooks";
import { ImageUploadBlock } from "components/ImageUploadBlock";

const initialCompany = {
  name: "",
  phoneNumber: "",
  description: "",
  email: "",
  websiteUrl: "",
  address: "",
  companyLogoUrl: "",
};

const initialCompanyErrors = {
  name: "",
  phoneNumber: "",
  description: "",
};

function CreateCompanyDrawer() {
  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const { track } = useAnalytics();

  // state
  const [company, setCompany] = useState<Partial<Company>>(initialCompany);
  const [companyErrors, setCompanyErrors] =
    useState<Partial<Company>>(initialCompanyErrors);
  const [companySaveError, setCompanySaveError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  // effects
  useEffect(() => {
    setCompany(initialCompany);
    setCompanyErrors(initialCompanyErrors);
  }, []);

  // mutations
  const [createContactMutation] = useMutation(CREATE_COMPANY_MUTATION, {
    refetchQueries: [{ query: LOAD_COMPANIES_QUERY }],
    onCompleted(data) {
      const { name, id } = data.createCompany.company;
      snackbar.success(`Successfully created company ${name}!`, {
        link: `/companies/update/${id}`,
        linkLabel: `View ${name}`,
        onLinkClick: () => {
          track("snackbar_companyViewed");
        },
      });

      // RENAME?: company_added
      track("create_company");
      handleCreateDrawerClose();
    },
    onError(error) {
      setSubmitDisabled(false);
      snackbar.error("Error creating company");
    },
  });

  // event handlers
  const handleCreateDrawerClose = () => {
    setTimeout(() => {
      history.push("/companies");
    }, 200);
  };

  const handleInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCompany({
      ...company,
      [event.target.name]: event.target.value,
    });
    setCompanyErrors({ ...companyErrors, [event.target.name]: "" });
  };

  const handleAddressInput = (value) => {
    setCompany({
      ...company,
      address: value.description,
    });
  };

  const handleClickSave = () => {
    let errors = [];
    if (!company.name) {
      errors = [...errors, { name: "Please enter Company Name" }];
    }
    if (company.phoneNumber && !isValidPhoneNumber(company.phoneNumber)) {
      errors = [
        ...errors,
        { phoneNumber: "Please enter 10 digit phone number" },
      ];
    }

    if (errors.length > 0) {
      setCompanyErrors(
        errors.reduce((acc, value) => ({ ...acc, ...value }), {})
      );
      setCompanySaveError(true);
      return;
    }

    setCompanySaveError(false);
    setSubmitDisabled(true);

    createContactMutation({
      variables: {
        input: {
          ...company,
        },
      },
    });
  };

  return (
    <CreateDrawer
      onClose={handleCreateDrawerClose}
      pageLabel="Add New Company"
      submitLabel="Save"
      onSubmit={handleClickSave}
      saveError={companySaveError}
      submitDisabled={submitDisabled}
    >
      <ImageUploadBlock
        mode="create"
        shape="rectangle"
        variant="company"
        placeholder={company.name}
        photoUrl={company.companyLogoUrl}
        onPhotoUrlUpdate={(companyLogoUrl) =>
          setCompany({ ...company, companyLogoUrl })
        }
        CropperProps={{
          cropShape: "rect",
          aspect: 5 / 2,
        }}
      />
      <Box mt={4} mb={2}>
        <Typography variant="h5">Basic info</Typography>
      </Box>

      <Box mb={1}>
        <TextField
          required
          fullWidth
          variant="outlined"
          name="name"
          label="Company Name"
          value={company.name || ""}
          onChange={handleInput}
          error={!!companyErrors.name}
          helperText={companyErrors.name}
        />
      </Box>
      <Box mb={1}>
        <InputMask mask="(999) 999-9999" maskChar=" " onChange={handleInput}>
          {() => (
            <TextField
              type="tel"
              variant="outlined"
              fullWidth
              name="phoneNumber"
              label="Phone Number"
              error={!!companyErrors.phoneNumber}
              helperText={companyErrors.phoneNumber}
            />
          )}
        </InputMask>
      </Box>

      <Box mb={1}>
        <TextField
          fullWidth
          variant="outlined"
          name="email"
          label="Company Email"
          value={company.email || ""}
          onChange={handleInput}
        />
      </Box>
      <Box mb={1}>
        <LocationAutoComplete
          label="Company Address"
          fullWidth
          name="address"
          value={company.address || ""}
          onChange={handleAddressInput}
        />
      </Box>
      <Box mb={1}>
        <TextField
          fullWidth
          variant="outlined"
          name="websiteUrl"
          label="Company Website"
          value={company.websiteUrl || ""}
          onChange={handleInput}
        />
      </Box>

      <Box mb={2}>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          name="description"
          label="Description"
          value={company.description || ""}
          onChange={handleInput}
          error={!!companyErrors.description}
          helperText={companyErrors.description}
        />
      </Box>
    </CreateDrawer>
  );
}

export default CreateCompanyDrawer;
