import React from "react";
import clipboardCopy from "clipboard-copy";
import * as Sentry from "@sentry/react";

import { CopyIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { Request, Trip } from "types";
import { getTripDetailsText } from "./helpers/getTripDetailsText";

type UseCopyTripDetailsClickProps = {
  request: Request;
  trip: Trip;
  isFarmee: boolean;
  analyticsName?: string;
};

function useCopyTripDetailsClick(
  props: UseCopyTripDetailsClickProps
): EllipsisMenuOption {
  const { request, trip, isFarmee, analyticsName } = props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  const handleCopyTripDetailsClick = async () => {
    if (analyticsName) track(analyticsName);
    try {
      const clipboardText = getTripDetailsText(request, trip, isFarmee);

      await clipboardCopy(clipboardText);
      snackbar.success(`Successfully copied trip details`);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return {
    onClick: handleCopyTripDetailsClick,
    text: "Copy Trip Details",
    icon: <CopyIcon color={grayDark} size="small" />,
  };
}

export { useCopyTripDetailsClick };
