import React from "react";
import isNumber from "lodash/isNumber";

import { Box, Divider, Typography } from "@mui/material";

import { alabaster } from "../../design-system/colors";
import { currency } from "../../globals/utils/helpers";
import { RoutePricing } from "../../types";
import { renderBaseRateFieldLabel, renderPercentFieldLabel } from "./utils";

type PriceBreakdownBlockProps = {
  pricing: RoutePricing;
};

function PriceBreakdownBlock(props: PriceBreakdownBlockProps) {
  const { pricing } = props;

  const {
    baseRateAmt,
    hourlyBaseRateHours,
    driverGratuityPercent,
    promoDiscountPercent,
    taxPercent,
    tollsAmt,
    meetGreetAmt,
    otherName,
    otherPercent,
    other2Name,
    other2Percent,
    other3Name,
    other3Percent,
    promoCodeName,
    forwardFacingSeatQuantity,
    rearFacingSeatQuantity,
    boosterSeatQuantity,
  } = pricing || {};

  const {
    driverGratuityDollarAmt,
    promoDiscountDollarAmt,
    taxDollarAmt,
    otherDollarAmt,
    other2DollarAmt,
    other3DollarAmt,
    promoCodeDollarAmt,
    forwardFacingSeatAmt,
    rearFacingSeatAmt,
    boosterSeatAmt,
  } = getRealDollarAmounts(pricing);

  const convertNaN = (value) => {
    return isNaN(value) ? 0 : value ? Number(value.toFixed(2)) : null;
  };

  // derived state
  const calculatedTotal =
    convertNaN(baseRateAmt) +
    convertNaN(driverGratuityDollarAmt) +
    convertNaN(taxDollarAmt) +
    convertNaN(otherDollarAmt) +
    convertNaN(other2DollarAmt) +
    convertNaN(other3DollarAmt) +
    convertNaN(tollsAmt) +
    convertNaN(meetGreetAmt) +
    convertNaN(forwardFacingSeatAmt) +
    convertNaN(rearFacingSeatAmt) +
    convertNaN(boosterSeatAmt) -
    convertNaN(promoDiscountDollarAmt) -
    convertNaN(promoCodeDollarAmt);

  return (
    <Box p={2} bgcolor={alabaster} borderRadius="4px">
      <Box mb={1.5}>
        {isNumber(baseRateAmt) && (
          <PriceBreakdownRow
            label={renderBaseRateFieldLabel(
              baseRateAmt / hourlyBaseRateHours,
              hourlyBaseRateHours
            )}
            amountDisplayed={baseRateAmt}
          />
        )}

        {isNumber(driverGratuityDollarAmt) && (
          <PriceBreakdownRow
            label={renderPercentFieldLabel("Gratuity", driverGratuityPercent)}
            amountDisplayed={driverGratuityDollarAmt}
          />
        )}

        {isNumber(taxDollarAmt) && (
          <PriceBreakdownRow
            label={renderPercentFieldLabel("Tax", taxPercent)}
            amountDisplayed={taxDollarAmt}
          />
        )}

        {isNumber(promoDiscountDollarAmt) && (
          <PriceBreakdownRow
            label={renderPercentFieldLabel("Discount", promoDiscountPercent)}
            amountDisplayed={promoDiscountDollarAmt}
            isDiscount
          />
        )}

        {isNumber(tollsAmt) && (
          <PriceBreakdownRow label="Tolls" amountDisplayed={tollsAmt} />
        )}

        {isNumber(meetGreetAmt) && (
          <PriceBreakdownRow
            label="Meet & Greet"
            amountDisplayed={meetGreetAmt}
          />
        )}

        {isNumber(rearFacingSeatAmt) && (
          <PriceBreakdownRow
            label={`${
              rearFacingSeatQuantity > 1 ? `${rearFacingSeatQuantity} X ` : ""
            }Rear-facing Seat`}
            amountDisplayed={rearFacingSeatAmt}
          />
        )}

        {isNumber(forwardFacingSeatAmt) && (
          <PriceBreakdownRow
            label={`${
              forwardFacingSeatQuantity > 1
                ? `${forwardFacingSeatQuantity} X `
                : ""
            }Forward-facing Seat`}
            amountDisplayed={forwardFacingSeatAmt}
          />
        )}

        {isNumber(boosterSeatAmt) && (
          <PriceBreakdownRow
            label={`${
              boosterSeatQuantity > 1 ? `${boosterSeatQuantity} X ` : ""
            }Booster Seat`}
            amountDisplayed={boosterSeatAmt}
          />
        )}

        {isNumber(otherDollarAmt) && (
          <PriceBreakdownRow
            label={renderPercentFieldLabel(otherName || "Other", otherPercent)}
            amountDisplayed={otherDollarAmt}
          />
        )}

        {isNumber(other2DollarAmt) && (
          <PriceBreakdownRow
            label={renderPercentFieldLabel(
              other2Name || "Other 2",
              other2Percent
            )}
            amountDisplayed={other2DollarAmt}
          />
        )}

        {isNumber(other3DollarAmt) && (
          <PriceBreakdownRow
            label={renderPercentFieldLabel(
              other3Name || "Other 3",
              other3Percent
            )}
            amountDisplayed={other3DollarAmt}
          />
        )}

        {isNumber(promoCodeDollarAmt) && (
          <PriceBreakdownRow
            label={`Promo Code (${promoCodeName})`}
            amountDisplayed={promoCodeDollarAmt}
            isDiscount
          />
        )}
      </Box>
      <Divider />
      <Box pt={1.5}>
        <PriceBreakdownRow
          label="Total"
          amountDisplayed={calculatedTotal}
          isTotal
        />
      </Box>
    </Box>
  );
}

export default PriceBreakdownBlock;

type PriceBreakdownRowProps = {
  label: string;
  amountDisplayed: number;
  isDiscount?: boolean;
  isTotal?: boolean;
};

function PriceBreakdownRow(props: PriceBreakdownRowProps) {
  const { label, amountDisplayed, isTotal, isDiscount } = props;

  return (
    <Box display="flex" justifyContent="space-between" pb={0.5}>
      <Typography variant={isTotal ? "subtitle1" : "body2"}>{label}</Typography>
      <Typography
        variant={isTotal ? "subtitle1" : "body2"}
        style={{
          fontFamily: "'Open Sans', sans-serif",
          ...(isTotal && { letterSpacing: "0.7px" }),
        }}
      >
        {isDiscount ? "-" : ""}
        {currency(amountDisplayed)}
      </Typography>
    </Box>
  );
}

type RoutePricingDollarAmounts = {
  driverGratuityDollarAmt: number;
  promoDiscountDollarAmt: number;
  taxDollarAmt: number;
  otherDollarAmt: number;
  other2DollarAmt: number;
  other3DollarAmt: number;
  promoCodeDollarAmt: number;
  rearFacingSeatAmt: number;
  forwardFacingSeatAmt: number;
  boosterSeatAmt: number;
};

const convertToDollarAmt = (
  amt: number,
  percent: number,
  baseRateAmt: number
): number => {
  return percent !== null
    ? Number((Math.round(baseRateAmt * percent) / 100).toFixed(2))
    : amt;
};

const getRealDollarAmounts = (
  pricing: RoutePricing
): RoutePricingDollarAmounts => {
  const {
    baseRateAmt,
    driverGratuityAmt,
    driverGratuityPercent,
    promoDiscountAmt,
    promoDiscountPercent,
    taxAmt,
    taxPercent,
    otherAmt,
    otherPercent,
    other2Amt,
    other2Percent,
    other3Amt,
    other3Percent,
    promoCodeAmt,
    promoCodePercent,
    rearFacingSeatAmt,
    rearFacingSeatQuantity,
    forwardFacingSeatAmt,
    forwardFacingSeatQuantity,
    boosterSeatAmt,
    boosterSeatQuantity,
  } = pricing;

  return {
    driverGratuityDollarAmt: convertToDollarAmt(
      driverGratuityAmt,
      driverGratuityPercent,
      baseRateAmt
    ),
    promoDiscountDollarAmt: convertToDollarAmt(
      promoDiscountAmt,
      promoDiscountPercent,
      baseRateAmt
    ),
    taxDollarAmt: convertToDollarAmt(taxAmt, taxPercent, baseRateAmt),
    otherDollarAmt: convertToDollarAmt(otherAmt, otherPercent, baseRateAmt),
    other2DollarAmt: convertToDollarAmt(other2Amt, other2Percent, baseRateAmt),
    other3DollarAmt: convertToDollarAmt(other3Amt, other3Percent, baseRateAmt),
    promoCodeDollarAmt: convertToDollarAmt(
      promoCodeAmt,
      promoCodePercent,
      baseRateAmt
    ),
    rearFacingSeatAmt:
      rearFacingSeatAmt !== null
        ? rearFacingSeatAmt * rearFacingSeatQuantity
        : null,
    forwardFacingSeatAmt:
      forwardFacingSeatAmt !== null
        ? forwardFacingSeatAmt * forwardFacingSeatQuantity
        : null,
    boosterSeatAmt:
      boosterSeatAmt !== null ? boosterSeatAmt * boosterSeatQuantity : null,
  };
};
