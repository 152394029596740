import React from "react";

type WatchIconProps = {
  color?: string;
};

const WatchIcon = (props: WatchIconProps) => {
  const { color } = props;

  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.9992 1.66759C4.84931 1.63401 2.62581 3.02518 1.22793 4.44993C1.0812 4.60077 1 4.79658 1 4.99958C1 5.20259 1.0812 5.3984 1.22793 5.54924C2.59541 6.94386 4.81251 8.36713 6.9992 8.33306C9.18589 8.36713 11.4035 6.94386 12.7721 5.54924C12.9188 5.3984 13 5.20259 13 4.99958C13 4.79658 12.9188 4.60077 12.7721 4.44993C11.3726 3.02518 9.14909 1.63401 6.9992 1.66759Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99922 5.00057C8.99912 5.36683 8.88173 5.72483 8.6619 6.02931C8.44207 6.3338 8.12967 6.57109 7.76421 6.71118C7.39874 6.85127 6.99662 6.88788 6.60869 6.81636C6.22076 6.74485 5.86444 6.56843 5.58478 6.30942C5.30513 6.0504 5.11469 5.72041 5.03757 5.36119C4.96044 5.00196 5.00008 4.62963 5.15147 4.29126C5.30286 3.95289 5.55921 3.66369 5.8881 3.46022C6.21699 3.25675 6.60365 3.14815 6.9992 3.14815C7.26191 3.14808 7.52206 3.19595 7.76478 3.28903C8.0075 3.38211 8.22804 3.51856 8.41378 3.69059C8.59952 3.86263 8.74682 4.06687 8.84728 4.29164C8.94773 4.51641 8.99936 4.75731 8.99922 5.00057Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WatchIcon;
