import React, { Dispatch, SetStateAction } from "react";

import { Box, Typography } from "@mui/material";

import { LabeledInlineInput } from "design-system/components/inputs";
import { UPDATE_TERM_MUTATION } from "globals/graphql";
import { useAutoSave } from "globals/hooks";

import { Term, UpdateTermInput } from "types";

type UpdateTermsDrawerFormProps = {
  term: Term;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function UpdateTermsDrawerForm(props: UpdateTermsDrawerFormProps) {
  const { setSaveIndicatorState } = props;

  // state
  const [term, setTerm] = useAutoSave<UpdateTermInput>({
    setSaveIndicatorState,
    name: "term",
    incomingState: {
      id: props.term.id,
      description: props.term.description,
    },
    mutation: UPDATE_TERM_MUTATION,
  });

  // event handlers
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTerm({
      ...term,
      description: event.target.value,
    });
  };

  return (
    <Box pt={3}>
      <Box mb={3}>
        <Typography variant="h5">{props.term.name}</Typography>
      </Box>
      <LabeledInlineInput
        inputProps={{ maxLength: 3000 }}
        multiline
        required
        label="Description"
        value={term?.description}
        onChange={handleDescriptionChange}
        errorText="Desciption is a required field"
      />
    </Box>
  );
}

export default UpdateTermsDrawerForm;
