/**
 * @file LabeledInlineInputs.tsx
 * Swoop-specific inline inputs wrapped with label.
 *
 * components:
 *  LabeledInlineInput,
 *  DollarInputLabeledInline,
 *  LabeledInlineSelect,
 *  LabeledInlinePhoneNumberInput,
 *  LabeledInlineLocationAutocomplete,
 *  InlineDateTimePicker,
 *  InlineDatePicker
 *
 * author: sienag, jackv
 */

import React, {
  ReactNode,
  useState,
  useMemo,
  Ref,
  useEffect,
  ChangeEvent,
} from "react";
import InputMask from "react-input-mask";
import moment from "moment-timezone";
import clipboardCopy from "clipboard-copy";
import PhoneInput from "react-phone-input-2";
import * as Sentry from "@sentry/react";
import "react-phone-input-2/lib/material.css";

import {
  Box,
  Typography,
  TextField,
  Tooltip,
  Popper,
  InputProps as MuiInputProps,
  TypographyVariant,
  BoxProps,
  IconButton,
  SxProps,
} from "@mui/material";

import {
  white,
  grayMedium,
  black,
  alabaster,
  grayDark,
} from "design-system/colors";
import { InlineInput, InlineSelect } from "./InlineInputs";
import { InlineLocationAutocomplete } from "./InlineLocationAutocomplete";
import { InlineDateTimePicker } from "./InlineDateTimePicker";
import { InlineDatePicker } from "./InlineDatePicker";
import { DollarInlineInput } from "./DollarInlineInput";
import { PercentInlineInput } from "./PercentInlineInput";
import AirportAutoComplete from "components/autocompletes/AirportAutoComplete";
import AirlineAutoComplete from "components/autocompletes/AirlineAutoComplete";
import FlightNumberInput from "components/requests/create/FlightNumberInput";
import { isValidPhoneNumber } from "globals/utils/helpers";
import { usePrevious, useScreenSize } from "globals/hooks";
import MoovsTooltip from "components/MoovsTooltip";
import { InfoIcon } from "design-system/icons";
import { CountryData } from "components/inputs/InternationalPhoneInput";
import CharacterLimitedTextInput from "components/inputs/CharacterLimitedTextInput";

const inputSx = {
  fontSize: "14px",
  backgroundColor: white,
  "& svg": {
    fill: "transparent",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid transparent",
  },
  ":hover": {
    "& svg": {
      fill: grayMedium,
      transition: "all 0.3s",
      transitionDelay: "0s",
    },
  },
  ":hover:not(.Mui-error)": {
    ".MuiOutlinedInput-notchedOutline": {
      transition: "all 0.3s",
      transitionDelay: "0s",
      borderColor: grayMedium,
    },
  },
  "&.Mui-focused:not(.Mui-error)": {
    ".MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: black,
    },
  },
  "&.Mui-focused": {
    backgroundColor: alabaster + "!important",
    "& svg": {
      fill: black + "!important",
    },
  },
};

const stylesSx = {
  phoneBox: {
    "& .react-tel-input": {
      border: "1px solid transparent",
      borderRadius: "4px",
      "&:focus-within": {
        border: `1px solid ${black} !important`,
        boxShadow: "none !important",
      },
      "&:hover": {
        border: `1px solid ${grayMedium}`,
        transition: "all 0.3s",
        transitionDelay: "0s",
      },

      "& .special-label": {
        display: "none",
      },
      "& .form-control": {
        border: "none",
        width: "100%",
        ":focus": {
          boxShadow: "none !important",
          backgroundColor: alabaster,
        },
      },
    },
  },
};

const getClipboardText = ({
  value,
  inputName,
}: {
  value: string;
  inputName: string;
  enumName?: any;
}) => {
  //Translate db naming to human-readable
  if (inputName === "dateTime") {
    //MMM Do YYYY, h:mm a is the format coming from textfield, but
    //something is preventing that from pasting correctly into
    //KeyboardDateTimePicker
    const dateTimeText = moment.utc(value).format("MMM Do YYYY, h:mm a");
    return dateTimeText;
  }

  return value;
};

type LabelWrapperProps = {
  copy?: boolean;
  inputName: string;
  label: string;
  value: string;
  size: "xs" | "small" | "large";
  textVariant?: TypographyVariant;
  children?: ReactNode;
  maxWidth?: string;
  labelWrapperBoxProps?: BoxProps;
  infoTooltipText?: string;
};

function LabelWrapper(props: LabelWrapperProps) {
  const {
    copy = false,
    inputName,
    label,
    value,
    size = "small",
    textVariant = "body2",
    children,
    maxWidth,
    labelWrapperBoxProps,
    infoTooltipText,
  } = props;
  const { isMobileView } = useScreenSize();

  const [tooltipText, setTooltipText] = useState(
    copy && !!value ? `Copy ${label} to clipboard` : ""
  );

  const clipboardText = getClipboardText({ value, inputName });

  const copyToClipboard = async () => {
    try {
      await clipboardCopy(clipboardText);
      setTooltipText(clipboardText ? "Copied!" : "");
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const minWidth = useMemo(() => {
    if (size === "xs") {
      return 110;
    }

    if (isMobileView) {
      return 140;
    }

    if (size === "small") {
      return 168;
    }
    return 269;
  }, [isMobileView, size]);

  return (
    <Box display="flex" alignItems="center" flex="1">
      <Box
        minWidth={minWidth}
        style={{ cursor: "pointer" }}
        display="flex"
        alignItems="center"
        height="54px"
        {...(copy && { onClick: copyToClipboard })}
        {...labelWrapperBoxProps}
      >
        <Tooltip
          enterDelay={200}
          enterNextDelay={200}
          onOpen={() =>
            setTooltipText(copy && !!value ? `Copy ${label} to clipboard` : "")
          }
          title={tooltipText}
          placement="top-start"
          PopperComponent={Popper}
        >
          <Typography
            variant={textVariant}
            style={{ color: grayDark, maxWidth: maxWidth }}
          >
            {label}
          </Typography>
        </Tooltip>
        {infoTooltipText && (
          <MoovsTooltip noWrap tooltipText={infoTooltipText}>
            <IconButton size="large">
              <InfoIcon size="small" />
            </IconButton>
          </MoovsTooltip>
        )}
      </Box>
      {children}
    </Box>
  );
}

type LabeledInlineInputProps = {
  copyable?: boolean;
  label: string;
  labelMaxWidth?: string;
  labelSize?: "xs" | "small" | "large";
  error?: boolean;
  errorText?: string;
  startAdornment?: string;
  endAdornment?: string | ReactNode;
  name?: string;
  value?: any;
  onChange?: any;
  type?: string;
  placeholder?: string;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
  inputProps?: any;
  InputProps?: MuiInputProps;
  textVariant?: TypographyVariant;
};

function LabeledInlineInput(props: LabeledInlineInputProps) {
  const {
    copyable = true,
    label,
    labelMaxWidth,
    labelSize,
    error,
    errorText,
    startAdornment,
    endAdornment,
    name,
    value,
    onChange,
    type,
    placeholder,
    required,
    multiline = false,
    rows,
    disabled,
    inputProps,
    InputProps,
    textVariant,
  } = props;

  return (
    <LabelWrapper
      copy={copyable}
      inputName={name}
      value={value}
      label={label}
      size={labelSize}
      maxWidth={labelMaxWidth}
      textVariant={textVariant}
    >
      <InlineInput
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        error={error}
        errorText={errorText}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        placeholder={placeholder}
        required={required}
        multiline={multiline}
        rows={rows}
        disabled={disabled}
        inputProps={inputProps}
        InputProps={InputProps}
      />
    </LabelWrapper>
  );
}

type LabeledInlinePhoneNumberInputProps = {
  copyable?: boolean;
  label: string;
  labelMaxWidth?: string;
  labelSize?: "xs" | "small" | "large";
  errorText?: string;
  name?: string;
  value?: any;
  onChange?: any;
  type?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  textVariant?: TypographyVariant;
};

function LabeledInlinePhoneNumberInput(
  props: LabeledInlinePhoneNumberInputProps
) {
  const {
    copyable = true,
    label,
    labelMaxWidth,
    labelSize,
    errorText,
    name,
    value,
    onChange,
    type,
    placeholder,
    required,
    disabled,
    textVariant,
  } = props;

  const error = !!(
    required &&
    value &&
    value.length > 1 &&
    !isValidPhoneNumber(value)
  );

  return (
    <LabelWrapper
      copy={copyable}
      inputName={name}
      value={value}
      label={label}
      size={labelSize}
      maxWidth={labelMaxWidth}
      textVariant={textVariant}
    >
      <InputMask
        mask="(999) 999-9999"
        maskChar=" "
        required={required}
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
        {() => (
          <TextField
            fullWidth
            required={required}
            disabled={disabled}
            name={name}
            type={type}
            placeholder={placeholder}
            InputProps={{
              sx: inputSx,
            }}
            variant="outlined"
            error={error}
            helperText={error && errorText}
          />
        )}
      </InputMask>
    </LabelWrapper>
  );
}

type LabeledInlineInternationalPhoneNumberInputProps = {
  copyable?: boolean;
  label: string;
  labelMaxWidth?: string;
  labelSize?: "xs" | "small" | "large";
  errorText?: string;
  name?: string;
  value?: any;
  onChange?: any;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  textVariant?: TypographyVariant;
  dropdownBelowInput?: boolean;
  dropdownWidth?: string;
  error?: string;
  errorMessage?: string;
  defaultEmptyValue?: string;
};

function LabeledInlineInternationalPhoneNumberInput(
  props: LabeledInlineInternationalPhoneNumberInputProps
) {
  const {
    copyable = true,
    label,
    labelMaxWidth,
    labelSize,
    name,
    value,
    onChange,
    disabled,
    textVariant,
    dropdownBelowInput = true,
    dropdownWidth = "600%",
    error,
    errorMessage,
    defaultEmptyValue,
  } = props;

  const prevAreaCode = usePreviousAreaCode(value);

  const phoneInputValue = value
    ? value
    : typeof defaultEmptyValue === "string"
    ? defaultEmptyValue
    : prevAreaCode;

  return (
    <LabelWrapper
      copy={copyable}
      inputName={name}
      value={value}
      label={label}
      size={labelSize}
      maxWidth={labelMaxWidth}
      textVariant={textVariant}
    >
      <Box display="flex" flex="1" sx={stylesSx.phoneBox}>
        <PhoneInput
          value={phoneInputValue}
          isValid={() => {
            return !!error ? errorMessage || "Please enter phone number" : true;
          }}
          country="us"
          disabled={disabled}
          onChange={(value, country) => {
            let currentCountry = country as CountryData;

            const { countryCode, dialCode } = currentCountry || {};

            if (
              countryCode &&
              dialCode &&
              countryCode !== "us" &&
              dialCode[0] === "1"
            ) {
              currentCountry.dialCode = "1";
            }

            onChange(value, currentCountry);
          }}
          countryCodeEditable={false}
          enableSearch
          searchStyle={{ width: "93%" }}
          dropdownStyle={{
            maxHeight: 175,
            width: dropdownWidth,
            top: dropdownBelowInput ? "none" : 0,
            position: "absolute",
          }}
        />
      </Box>
    </LabelWrapper>
  );
}

type LabeledInlineSelectProps = {
  label: string;
  labelSize?: "small" | "large";
  children?: ReactNode;
  errorText?: string;
  name?: string;
  value?: any;
  onChange?: any;
  type?: string;
  placeholder?: string;
  required?: boolean;
  copyable?: boolean;
  disabled?: boolean;
  renderValue?: (value: any) => any;
  infoTooltipText?: string;
};

// stores previous value if it is not null
// so that area code is never an empty string
const usePreviousAreaCode = (value: string) => {
  // state
  const [prevAreaCode, setPrevAreaCode] = useState("+1");

  // hooks
  const prevValue = usePrevious<string>(value);

  // effects
  useEffect(() => {
    if (!value && !!prevValue) {
      setPrevAreaCode(prevValue);
    }
  }, [prevValue, value]);

  return prevAreaCode;
};

function LabeledInlineSelect(props: LabeledInlineSelectProps) {
  const {
    label,
    labelSize,
    children,
    errorText,
    name,
    value,
    onChange,
    type,
    placeholder,
    required,
    copyable = true,
    disabled,
    renderValue,
    infoTooltipText,
  } = props;

  return (
    <LabelWrapper
      copy={copyable}
      inputName={value}
      value={value}
      label={label}
      size={labelSize}
      infoTooltipText={infoTooltipText}
    >
      <InlineSelect
        disabled={disabled}
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        required={required}
        errorText={errorText}
        renderValue={renderValue}
      >
        {children}
      </InlineSelect>
    </LabelWrapper>
  );
}

type LabeledInlineLocationAutocompleteProps = {
  copyable?: boolean;
  label: string;
  labelMaxWidth?: string;
  labelSize?: "xs" | "small" | "large";
  errorText?: string;
  value?: any;
  onChange?: any;
  placeholder?: string;
  required?: boolean;
  name?: string;
  inputRef?: Ref<HTMLInputElement>;
  suggestedAddressInfo?: {
    firstName: string;
    lastName: string;
    address: string;
    mode: string;
  }[];
  TextFieldInputProps?: MuiInputProps;
  textVariant?: TypographyVariant;
};

function LabeledInlineLocationAutocomplete(
  props: LabeledInlineLocationAutocompleteProps
) {
  const {
    copyable = true,
    label,
    labelMaxWidth,
    labelSize,
    errorText,
    value,
    onChange,
    placeholder,
    required,
    name,
    suggestedAddressInfo,
    inputRef,
    TextFieldInputProps,
    textVariant,
  } = props;

  return (
    <LabelWrapper
      copy={copyable}
      inputName={name}
      value={value}
      label={label}
      size={labelSize}
      maxWidth={labelMaxWidth}
      textVariant={textVariant}
    >
      <InlineLocationAutocomplete
        inputRef={inputRef}
        value={value}
        onChange={onChange}
        errorText={errorText}
        placeholder={placeholder}
        required={required}
        name={name}
        suggestedAddressInfo={suggestedAddressInfo}
        TextFieldInputProps={TextFieldInputProps}
      />
    </LabelWrapper>
  );
}

type LabeledInlineAirportAutocompleteProps = {
  label: string;
  labelSize?: "xs" | "small" | "large";
  value?: any;
  onChange?: any;
  placeholder?: string;
  name?: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  inputRef?: Ref<HTMLInputElement>;
};

function LabeledInlineAirportAutocomplete(
  props: LabeledInlineAirportAutocompleteProps
) {
  const {
    label,
    labelSize,
    value,
    onChange,
    placeholder,
    name,
    required,
    error,
    helperText,
    inputRef,
  } = props;

  return (
    <LabelWrapper
      copy
      inputName="Airport IATA Code"
      value={value}
      label={label}
      size={labelSize}
    >
      <AirportAutoComplete
        fullWidth
        value={value}
        inputRef={inputRef}
        onChange={onChange}
        error={error}
        helperText={helperText}
        placeholder={placeholder}
        required={required}
        name={name}
        inputSx={inputSx}
      />
    </LabelWrapper>
  );
}

type LabeledInlineAirlineAutocompleteProps = {
  label: string;
  labelSize?: "small" | "large";
  value?: any;
  onChange?: any;
  placeholder?: string;
  name?: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
};

function LabeledInlineAirlineAutocomplete(
  props: LabeledInlineAirlineAutocompleteProps
) {
  const {
    label,
    labelSize,
    value,
    onChange,
    placeholder,
    name,
    required,
    error,
    helperText,
  } = props;

  return (
    <LabelWrapper
      copy
      label={label}
      size={labelSize}
      inputName="Airline IATA Code"
      value={value}
    >
      <AirlineAutoComplete
        fullWidth
        value={value}
        onChange={onChange}
        helperText={helperText}
        error={error}
        placeholder={placeholder}
        required={required}
        name={name}
        inputSx={inputSx}
      />
    </LabelWrapper>
  );
}

type LabeledInlineFlightNumberInputProps = {
  name?: string;
  value?: any;
  onFlightNumberInputChange: (e: any) => void;
  airlineIata: string;
  error: boolean;
  label: string;
  labelSize?: "small" | "large";
  isSearchingForFlight: boolean;
};

function LabeledInlineFlightNumberInput(
  props: LabeledInlineFlightNumberInputProps
) {
  const {
    label,
    labelSize,
    value,
    onFlightNumberInputChange,
    name,
    airlineIata,
    error,
    isSearchingForFlight,
  } = props;
  return (
    <LabelWrapper inputName={name} value={value} label={label} size={labelSize}>
      <FlightNumberInput
        onFlightNumberInputChange={onFlightNumberInputChange}
        isLoading={isSearchingForFlight}
        airlineIata={airlineIata}
        error={error}
        value={value}
      />
    </LabelWrapper>
  );
}

type LabeledInlineDateTimePickerProps = {
  minDate?: any;
  initialFocusedDate?: any;
  clearable?: boolean;
  label: string;
  labelSize?: "small" | "large";
  errorText?: string;
  name?: string;
  value?: any;
  onChange?: any;
  placeholder?: string;
  required?: boolean;
  copyable?: boolean;
  shouldDisableDate?: boolean;
  error?: boolean;
};

function LabeledInlineDateTimePicker(props: LabeledInlineDateTimePickerProps) {
  const {
    minDate,
    initialFocusedDate,
    clearable,
    label,
    labelSize,
    errorText,
    name,
    value,
    onChange,
    copyable = true,
    placeholder,
    required,
    shouldDisableDate,
    error,
  } = props;
  return (
    <LabelWrapper
      copy={copyable}
      inputName={name}
      value={value}
      label={label}
      size={labelSize}
    >
      <InlineDateTimePicker
        minDate={minDate}
        initialFocusedDate={initialFocusedDate}
        clearable={clearable}
        value={value}
        onChange={onChange}
        errorText={errorText}
        placeholder={placeholder}
        required={required}
        name={name}
        shouldDisableDate={shouldDisableDate}
        error={error}
      />
    </LabelWrapper>
  );
}

type LabeledInlineDatePickerProps = {
  copyable?: boolean;
  label: string;
  labelSize?: "small" | "large";
  errorText?: string;
  name?: string;
  value?: any;
  onChange?: any;
  placeholder?: string;
  required?: boolean;
  disablePast?: boolean;
  error?: boolean;
};

function LabeledInlineDatePicker(props: LabeledInlineDatePickerProps) {
  const {
    copyable = true,
    label,
    labelSize,
    error,
    errorText,
    name,
    value,
    onChange,
    placeholder,
    required,
    disablePast,
  } = props;

  return (
    <LabelWrapper
      copy={copyable}
      inputName={name}
      value={value}
      label={label}
      size={labelSize}
    >
      <InlineDatePicker
        value={value}
        onChange={onChange}
        error={error}
        errorText={errorText}
        placeholder={placeholder}
        required={required}
        name={name}
        disablePast={disablePast}
      />
    </LabelWrapper>
  );
}

type DollarInputLabeledInlineProps = {
  copyable?: boolean;
  label: string;
  labelSize?: "small" | "large";
  errorText?: string;
  name?: string;
  value?: any;
  onChange?: any;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  inputProps?: any;
  labelWrapperBoxProps?: BoxProps;
  textVariant?: TypographyVariant;
  error?: boolean;
};

function DollarInputLabeledInline(props: DollarInputLabeledInlineProps) {
  const {
    copyable = true,
    label,
    labelSize,
    value,
    onChange,
    errorText,
    placeholder,
    required,
    name,
    disabled,
    inputProps,
    labelWrapperBoxProps,
    textVariant,
    error,
  } = props;
  return (
    <LabelWrapper
      copy={copyable}
      inputName="dollar"
      value={value}
      label={label}
      size={labelSize}
      labelWrapperBoxProps={labelWrapperBoxProps || undefined}
      textVariant={textVariant || undefined}
    >
      <DollarInlineInput
        disabled={disabled}
        value={value}
        onChange={onChange}
        errorText={errorText}
        placeholder={placeholder}
        required={required}
        name={name}
        inputProps={inputProps || undefined}
        error={error}
      />
    </LabelWrapper>
  );
}

type PercentInputLabeledInlineProps = {
  label: string;
  labelSize?: "small" | "large";
  errorText?: string;
  name?: string;
  value?: any;
  onChange?: any;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  inputProps?: any;
};

function PercentInputLabeledInline(props: PercentInputLabeledInlineProps) {
  const {
    label,
    labelSize,
    value,
    onChange,
    errorText,
    placeholder,
    required,
    name,
    disabled,
    inputProps,
  } = props;
  return (
    <LabelWrapper inputName={name} value={value} label={label} size={labelSize}>
      <PercentInlineInput
        disabled={disabled}
        value={value}
        onChange={onChange}
        errorText={errorText}
        placeholder={placeholder}
        required={required}
        name={name}
        inputProps={inputProps || undefined}
      />
    </LabelWrapper>
  );
}

type LabeledInlineAbsoluteNumberInputProps = {
  copyable?: boolean;
  label: string;
  labelMaxWidth?: string;
  labelSize?: "xs" | "small" | "large";
  errorText?: string;
  name?: string;
  value?: any;
  onChange?: any;
  type?: string;
  placeholder?: string;
  required?: boolean;
};

function LabeledInlineAbsoluteNumberInput(
  props: LabeledInlineAbsoluteNumberInputProps
) {
  const {
    copyable = true,
    label,
    labelMaxWidth,
    labelSize,
    errorText,
    name,
    value,
    onChange,
    type,
    placeholder,
    required,
  } = props;

  const error =
    required && value && value.length > 1 && !isValidPhoneNumber(value);

  return (
    <LabelWrapper
      copy={copyable}
      inputName={name}
      value={value}
      label={label}
      size={labelSize}
      maxWidth={labelMaxWidth}
    >
      <InputMask
        mask="999"
        maskChar=""
        required={required}
        onChange={onChange}
        value={value}
      >
        {() => (
          <TextField
            fullWidth
            required={required}
            name={name}
            type={type}
            placeholder={placeholder}
            InputProps={{
              sx: inputSx,
            }}
            variant="outlined"
            error={error}
            helperText={error && errorText}
          />
        )}
      </InputMask>
    </LabelWrapper>
  );
}

type LabeledInlineCharacterLimitedTextInputProps = {
  label: string;
  name: string;
  value: any;
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  labelSize?: "small" | "large";
  preventCharacterCountUpdatePastLimit?: boolean;
  sx?: SxProps;
  disabled?: boolean;
};

function LabeledInlineCharacterLimitedTextInput(
  props: LabeledInlineCharacterLimitedTextInputProps
) {
  const {
    label,
    labelSize,
    value,
    onChange,
    name,
    preventCharacterCountUpdatePastLimit,
    sx,
    disabled,
  } = props;
  return (
    <LabelWrapper inputName={name} value={value} label={label} size={labelSize}>
      <CharacterLimitedTextInput
        name={name}
        value={value}
        onChange={onChange}
        preventCharacterCountUpdatePastLimit={
          preventCharacterCountUpdatePastLimit
        }
        InputProps={{ sx: inputSx }}
        sx={sx}
        disabled={disabled}
      />
    </LabelWrapper>
  );
}

// type Label = {
//   copyable?: boolean;
//   label: string;
//   labelMaxWidth?: string;
//   labelSize?: "xs" | "small" | "large";
//   name?: string;
//   textVariant?: TypographyVariant;
// };

export {
  LabeledInlineInput,
  LabeledInlineSelect,
  LabeledInlineLocationAutocomplete,
  LabeledInlineAirportAutocomplete,
  LabeledInlineAirlineAutocomplete,
  LabeledInlineDateTimePicker,
  LabeledInlineDatePicker,
  LabeledInlinePhoneNumberInput,
  DollarInputLabeledInline,
  PercentInputLabeledInline,
  LabeledInlineAbsoluteNumberInput,
  LabeledInlineInternationalPhoneNumberInput,
  LabeledInlineFlightNumberInput,
  LabeledInlineCharacterLimitedTextInput,
  LabelWrapper,
};
