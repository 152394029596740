import React from "react";
import { Control, FieldErrors, UseFormWatch } from "react-hook-form";

import { EditPromoCodeValues } from "../useEditPromoCodeDefaultValues";
import { LimitsForm } from "../../../../../CreatePromoCodeDialog/steps/limits/useLimitsForm";

type LimitsProps = {
  control: Control<EditPromoCodeValues>;
  errors: FieldErrors;
  watch: UseFormWatch<EditPromoCodeValues>;
};

function Limits(props: LimitsProps) {
  const { control, errors, watch } = props;

  return (
    <LimitsForm control={control} errors={errors} watch={watch} isEdit={true} />
  );
}

export default Limits;
