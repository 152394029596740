import * as yup from "yup";

export enum WorkflowSelectionsEnum {
  anytime = "anytime",
  bookingDate = "bookingDate",
  tripDate = "tripDate",
}

export const workflowSchema = yup.object({
  workflow: yup
    .mixed()
    .oneOf(
      [
        WorkflowSelectionsEnum.anytime,
        WorkflowSelectionsEnum.bookingDate,
        WorkflowSelectionsEnum.tripDate,
      ],
      "Please select a promo code type"
    )
    .required(),
});
