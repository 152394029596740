import { EmailRecipient } from "components/SendEmailDialog/SendEmailDialog";

const PATTERN = /\S+@\S+\.\S+/;

// valid email if there is an @ and .
// Example: 'anystring@anystring.anystring'
export const parseInvalidEmails = (emails: EmailRecipient[]) => {
  let error = false;

  const parsedEmails = emails.map((emailRecipient) => {
    if (!PATTERN.test(emailRecipient.email)) {
      emailRecipient.error = true;
      error = true;
    }
    return emailRecipient;
  });

  return { emails: parsedEmails, error };
};
