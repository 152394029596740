import React from "react";
import moment from "moment-timezone";

import { Box, Typography } from "@mui/material";

import { SalesAutomationText } from "types";
import { MessageIcon } from "design-system/icons";
import { black, grayMedium, moovsBlue } from "design-system/colors";

type TextButtonHeaderProps = {
  isActive: boolean;
  isDisabled: boolean;
  text: SalesAutomationText;
};

function TextButtonHeader(props: TextButtonHeaderProps) {
  const { isActive, isDisabled, text } = props;

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      minHeight="24px"
      color={isDisabled ? grayMedium : black}
    >
      <MessageIcon
        color={isDisabled ? grayMedium : isActive ? moovsBlue : black}
      />
      <Typography variant="subtitle2" ml={1}>
        {text.index === 0
          ? "Today, Send Now"
          : isDisabled
          ? `Message ${text.index + 1}`
          : moment(text.sendDate)
              .tz(moment.tz.guess()) // use guess to fetch timezone
              .format("ddd MMM D, h:mm A z")}
      </Typography>
    </Box>
  );
}

export default TextButtonHeader;
