import React from "react";

import { Box, Typography, Divider, Avatar } from "@mui/material";

import {
  alabaster,
  granite,
  grayDark,
  grayLight,
  moovsBlueSelected,
} from "../../../../design-system/colors";
import { ChevronDownIcon } from "../../../../design-system/icons";

type EmailPreviewBoxProps = {
  customDomain: string;
};

function EmailPreviewBox(props: EmailPreviewBoxProps) {
  const { customDomain } = props;

  return <>
    <Box
      p={4}
      borderRadius="2px"
      border={`1px solid ${grayLight}`}
      bgcolor={alabaster}
    >
      {/* Email Header Preview */}
      <Box display="flex" flexDirection="row" flex="1" mb={1.5}>
        <Box display="flex" mr={1}>
          <Typography variant="overline">
            <Avatar variant="rounded" />
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box display="flex" flex="2">
            <Typography style={{ fontWeight: 700 }} variant="body2">
              John Doe
            </Typography>
            <Box ml={1}>
              <Typography variant="body2">
                {"<"}
                john.doe@{customDomain}
                {">"}
              </Typography>
            </Box>
          </Box>
          <Box mt={0.5} display="flex" flex="2">
            <Typography style={{ color: grayDark }} variant="body2">
              to me
            </Typography>
            <Box
              ml={1}
              display="flex"
              height="24px"
              width="24px"
              border={`1px solid ${grayLight}`}
              alignItems="center"
              justifyContent="center"
            >
              <ChevronDownIcon />
            </Box>
          </Box>
        </Box>
      </Box>

      <Divider />

      {/* Email Body Preview */}
      <Box mt={1} display="flex" flexDirection="column">
        {/* Row 1 */}
        <Box
          my={1}
          width="33%"
          height="24px"
          bgcolor={grayLight}
          borderRadius="4px"
        />
        {/* Row 2 */}
        <Box
          my={1}
          width="100%"
          height="18px"
          bgcolor={grayLight}
          borderRadius="4px"
        />

        {/* Row 3 */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" flex="1" flexDirection="column" width="100%">
            <Box
              my={1}
              display="flex"
              height="18px"
              bgcolor={grayLight}
              borderRadius="4px"
            />
            <Box
              my={1}
              display="flex"
              height="18px"
              bgcolor={grayLight}
              borderRadius="4px"
            />
          </Box>

          <Box
            mx={2}
            display="flex"
            flex="2"
            justifyContent="center"
            width="200px"
          >
            <Typography
              style={{
                textAlign: "center",
                color: granite,
                fontWeight: 500,
              }}
              variant="body2"
            >
              What Recipients See
            </Typography>
          </Box>

          <Box display="flex" flex="1" flexDirection="column" width="100%">
            <Box
              my={1}
              display="flex"
              height="18px"
              bgcolor={grayLight}
              borderRadius="4px"
            />
            <Box
              my={1}
              display="flex"
              height="18px"
              bgcolor={grayLight}
              borderRadius="4px"
            />
          </Box>
        </Box>

        {/* Row 4 */}
        <Box
          my={1}
          width="100%"
          height="18px"
          bgcolor={grayLight}
          borderRadius="4px"
        />

        {/* Row 5 */}
        <Box display="flex" justifyContent="center">
          <Box
            mt={1}
            width="185px"
            height="44px"
            bgcolor={moovsBlueSelected}
            borderRadius="4px"
          />
        </Box>
      </Box>
    </Box>
  </>;
}

export default EmailPreviewBox;
