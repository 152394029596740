import React, { useState } from "react";

import { Box, Button, Divider, Typography } from "@mui/material";

import VehiclesDialog from "../reservations/VehiclesDialog";
import VehicleCard from "./VehicleCard";
import { Stop, Vehicle } from "../../types";
import VehicleAvailabilityDialog from "./VehicleAvailabilityDialog/VehicleAvailabilityDialog";
import { useAnalytics } from "../../globals/hooks";
import FarmAffiliateVehiclesDialog from "components/reservations/FarmAffiliateVehiclesDialog";

type AddVehicleCreateBlockProps = {
  addVehicles: (selectedVehicles: Vehicle[]) => void;
  requestErrors?: any;
  removeVehicle?: () => void;
  vehicle?: Vehicle;
  stops?: Stop[];
  isFarmAffiliate?: boolean;
  isReplace?: boolean;
  disableBRA?: boolean;
  hideVehicleAvailability?: boolean;
};

function AddVehicleCreateBlock(props: AddVehicleCreateBlockProps) {
  const {
    addVehicles,
    requestErrors,
    removeVehicle,
    vehicle,
    stops,
    isFarmAffiliate,
    isReplace,
    disableBRA,
    hideVehicleAvailability,
  } = props;

  const { track } = useAnalytics();

  // state
  const [open, setDialogOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState<"add" | "edit" | "addMultiple">(
    "edit"
  );
  const [vehicles, setVehicles] = useState(vehicle ? [vehicle] : []);
  const [swoopFarmVehiclesDialogOpen, setSwoopFarmVehiclesDialogOpen] =
    useState(false);
  const [openVehicleAvailabilityDialog, setOpenVehicleAvailabilityDialog] =
    useState(false);
  const [vehicleAvailabilityDialogProps, setVehicleAvailabilityDialogProps] =
    useState({
      title: "",
      vehicleId: "",
    });

  // event handlers
  const handleAddVehicles = (selectedVehicles: Vehicle[]) => {
    setDialogOpen(false);
    setVehicles(selectedVehicles);
    addVehicles(selectedVehicles);
  };

  const handlePressRemove = () => {
    removeVehicle();
    setVehicles([]);
  };

  const handlePressReplace = () => {
    // open VehicleDialog with edit dialog
    setDialogMode("edit");
    setDialogOpen(true);
  };

  const handleShowVehicleAvailabilityClick =
    (vehicleId: string) => (vehicleAvailabilityDialogTitle: string) => {
      setVehicleAvailabilityDialogProps({
        title: vehicleAvailabilityDialogTitle,
        vehicleId,
      });
      setOpenVehicleAvailabilityDialog(true);
      track("vehicleAvailability_opened");
    };

  // dialog handlers
  const handleSwoopFarmVehiclesDialogClose = () => {
    setSwoopFarmVehiclesDialogOpen(false);
  };
  const handleSwoopFarmVehiclesDialogOpen = () => {
    setSwoopFarmVehiclesDialogOpen(true);
  };

  const handleDialogOpen = (dialogMode: "add") => () => {
    setDialogMode(dialogMode);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <Box mb={4}>
      <Box mt={4} mb={1.5} display="flex">
        <Typography
          variant={isFarmAffiliate ? "h3" : "h5"}
          style={{ marginRight: 10 }}
        >
          Vehicle
        </Typography>
        {requestErrors?.vehicle && (
          <Typography style={{ paddingTop: 3 }} color="error">
            {requestErrors?.vehicle}
          </Typography>
        )}
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      <Box>
        {vehicles.map((vehicle: Vehicle) => {
          return (
            <VehicleCard
              key={vehicle.id}
              vehicle={vehicle}
              stops={stops}
              ellipsisMenuActions={
                isReplace
                  ? {
                      onPressReplace: handlePressReplace,
                    }
                  : {
                      onPressRemove: handlePressRemove,
                    }
              }
              onSwoopFarmVehiclesDialogOpen={handleSwoopFarmVehiclesDialogOpen}
              {...(!hideVehicleAvailability && {
                onShowVehicleAvailabilityClick:
                  handleShowVehicleAvailabilityClick(vehicle.id),
              })}
            />
          );
        })}
      </Box>

      {vehicles.length < 1 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="130px"
          flex="1"
          style={{
            borderRadius: "4px",
            background: "rgba(229, 238, 255, 0.3)",
            border: "1px solid rgba(115, 156, 255, 0.3)",
          }}
        >
          <Button
            size="large"
            variant="outlined"
            color="primary"
            onClick={handleDialogOpen("add")}
            data-testid="add-vehicle-button"
          >
            Add Vehicle
          </Button>
        </Box>
      )}

      <VehiclesDialog
        open={open}
        dialogMode={dialogMode}
        onClose={handleDialogClose}
        onCreate={handleAddVehicles}
        stops={stops}
        disableBRA={disableBRA}
      />
      <VehicleAvailabilityDialog
        title={vehicleAvailabilityDialogProps.title}
        open={openVehicleAvailabilityDialog}
        onClose={() => setOpenVehicleAvailabilityDialog(false)}
        vehicleId={vehicleAvailabilityDialogProps.vehicleId}
        stops={stops}
      />

      {swoopFarmVehiclesDialogOpen && (
        <FarmAffiliateVehiclesDialog
          open={swoopFarmVehiclesDialogOpen}
          title="Affiliate Vehicles"
          isReadOnly
          onClose={handleSwoopFarmVehiclesDialogClose}
          stops={stops}
        />
      )}
    </Box>
  );
}

export default AddVehicleCreateBlock;
