export const convertToPercentDecimals = (pricingLayout) => {
  return {
    ...pricingLayout,

    driverGratuityAmt: pricingLayout.driverGratuityAmt || null,
    driverGratuityPercent: pricingLayout.driverGratuityPercent
      ? pricingLayout.driverGratuityPercent / 100
      : null,

    other2Amt: pricingLayout.other2Amt || null,
    other2Percent: pricingLayout.other2Percent
      ? pricingLayout.other2Percent / 100
      : null,

    other3Amt: pricingLayout.other3Amt || null,
    other3Percent: pricingLayout.other3Percent
      ? pricingLayout.other3Percent / 100
      : null,

    otherAmt: pricingLayout.otherAmt || null,
    otherPercent: pricingLayout.otherPercent
      ? pricingLayout.otherPercent / 100
      : null,

    promoDiscountAmt: pricingLayout.promoDiscountAmt || null,
    promoDiscountPercent: pricingLayout.promoDiscountPercent
      ? pricingLayout.promoDiscountPercent / 100
      : null,

    taxAmt: pricingLayout.taxAmt || null,
    taxPercent: pricingLayout.taxPercent
      ? pricingLayout.taxPercent / 100
      : null,

    tollsAmt: pricingLayout.tollsAmt || null,
  };
};

export const convertToPercent = (pricingLayout) => {
  if (!pricingLayout) return null;
  delete pricingLayout.__typename;
  return {
    ...pricingLayout,
    driverGratuityPercent: pricingLayout.driverGratuityPercent
      ? pricingLayout.driverGratuityPercent * 100
      : null,
    other2Percent: pricingLayout.other2Percent
      ? pricingLayout.other2Percent * 100
      : null,
    other3Percent: pricingLayout.other3Percent
      ? pricingLayout.other3Percent * 100
      : null,
    otherPercent: pricingLayout.otherPercent
      ? pricingLayout.otherPercent * 100
      : null,
    promoDiscountPercent: pricingLayout.promoDiscountPercent
      ? pricingLayout.promoDiscountPercent * 100
      : null,
    taxPercent: pricingLayout.taxPercent
      ? pricingLayout.taxPercent * 100
      : null,
  };
};
