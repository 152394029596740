import React, { useState, useEffect, ChangeEvent } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Moment } from "moment";
import first from "lodash/first";

import {
  applyUTCOffsetToTime,
  fromRouteIdToOperatorRouteId,
} from "globals/utils/helpers";
import {
  CLOSE_OUT_TRIP_MUTATION,
  LOAD_OPERATOR_ROUTE_QUERY,
} from "globals/graphql";
import { OperatorRoute, Trip, FarmRelationshipEnum } from "types";
import MoovsDialog from "components/MoovsDialog";
import { useAnalytics, useSnackbar } from "globals/hooks";
import CloseTripDialogBlock from "./CloseTripDialogBlock";
import {
  calculateAutomatedGratuityAmount,
  DateTimeVariant,
  getDateTimeInfoForCloseTripDialog,
} from "./utils";
import CloseOutNotes from "./CloseOutNotes";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import DriverPayoutFields from "components/driverPayout/update/DriverPayoutFields";
import { isAutomationPayoutUpdated } from "components/driverPayout/update/utils";

const defaultDriverPayoutFields = {
  driverPayoutFlatRate: null,
  driverPayoutHours: null,
  driverPayoutRatePerHour: null,
  driverPayoutGratuity: null,
};

type CloseTripDialogProps = {
  onCloseTripDialogClose: () => void;
  closeTripDialogOpen: boolean;
  orderNumber: string;
  trip: Trip;
  onSuccessfullyClosedTrip: () => void;
  isPayoutAutomated: boolean;
};

const { Start } = DateTimeVariant;

function CloseTripDialog(props: CloseTripDialogProps) {
  const {
    onCloseTripDialogClose,
    closeTripDialogOpen,
    trip,
    onSuccessfullyClosedTrip,
    isPayoutAutomated,
  } = props;

  // hooks
  const { track } = useAnalytics();
  const snackbar = useSnackbar();

  // state
  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState<string>(null);
  // date times should ALWAYS be iso strings
  const [startDateTime, setStartDateTime] = useState<string>(null);
  const [endDateTime, setEndDateTime] = useState<string>(null);
  const [driverPayoutFields, setDriverPayoutFields] = useState(
    defaultDriverPayoutFields
  );

  // derived state
  const routeId = fromRouteIdToOperatorRouteId(first(trip.routes).id);
  const route = first(trip.routes);

  // queries
  const { data } = useQuery(LOAD_OPERATOR_ROUTE_QUERY, {
    variables: {
      id: routeId,
    },
    fetchPolicy: "network-only",
    skip: !routeId || !closeTripDialogOpen,
  });

  // mutation
  const [closeOutTrip, { loading: closeOutMutationLoading }] = useMutation(
    CLOSE_OUT_TRIP_MUTATION,
    {
      refetchQueries: ["Requests", "Request"],
      onCompleted(data) {
        snackbar.success("Successfully closed out trip!");
        onSuccessfullyClosedTrip();
        track("trip_closeOut");
      },
      onError(error) {
        const errorMessage =
          getErrorMessage(error) || "Error closing out your trip";

        snackbar.error(errorMessage);
      },
    }
  );

  // Event Handlers

  // date/time and note changes
  const handleDateTimeChange =
    (dateTimeVariant: DateTimeVariant) => (dateTime: string | Moment) => {
      const setDateTime =
        dateTimeVariant === Start ? setStartDateTime : setEndDateTime;

      const newDate = dateTime
        ? applyUTCOffsetToTime(dateTime, "add").toISOString()
        : null;

      setDateTime(newDate);
    };

  const handleNoteChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newNote = e.target.value;
    setNote(newNote);
    setNoteError(
      newNote.length > 150 ? "Max character limit of 150 reached" : ""
    );
  };

  // update payout fields
  const handlePayoutInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = event.target.name;
    const value =
      event.target.value === "" ? null : Number(event.target.value).toFixed(2);

    setDriverPayoutFields({
      ...driverPayoutFields,
      [name]: value,
    });
  };

  // close trip
  const handleAcceptClick = () => {
    // converts any potential leading 0 decimals e.g. 0.50
    const payoutsToNumber = {
      driverPayoutFlatRate: Number(driverPayoutFields.driverPayoutFlatRate),
      driverPayoutHours: Number(driverPayoutFields.driverPayoutHours),
      driverPayoutRatePerHour: Number(
        driverPayoutFields.driverPayoutRatePerHour
      ),
      driverPayoutGratuity: Number(driverPayoutFields.driverPayoutGratuity),
    };

    if (isPayoutAutomated) {
      // if close trip was automated but values don't match on submission
      const isPayoutUpdated = isAutomationPayoutUpdated(payoutsToNumber, {
        ...route,
        driverGratuityAmt:
          route.pricing?.driverGratuityAmt ||
          route.farmeePricing?.driverGratuityAmt,
      });

      track(
        isPayoutUpdated
          ? "closeTripDialog_automatedDriverPayoutModified"
          : "closeTripDialog_automatedDriverPayoutSaved"
      );
    }

    closeOutTrip({
      variables: {
        input: {
          tripId: trip.id,
          routeId: first(trip.routes).id,
          startDateTime,
          endDateTime,
          closeoutNote: note || null,
          ...(!isFarmor && payoutsToNumber),
        },
      },
    });
  };

  // derived state
  const operatorRoute: OperatorRoute = data?.node;
  const dateTimeInfo =
    operatorRoute && getDateTimeInfoForCloseTripDialog(operatorRoute);
  const closeTripButtonDisabled = !!noteError || closeOutMutationLoading;
  const isFarmor =
    operatorRoute?.farmRelationship === FarmRelationshipEnum.Farmor;

  // effects

  // when dialog is closed, reset state in a delay
  useEffect(() => {
    if (!closeTripDialogOpen) {
      const timeToResetState = setTimeout(() => {
        setNote("");
        setStartDateTime(null);
        setEndDateTime(null);
      }, 500);
      return () => {
        clearTimeout(timeToResetState);
      };
    }
  }, [closeTripDialogOpen]);

  // set initial dateTimes and note (if reopening)
  useEffect(() => {
    if (
      !startDateTime &&
      !endDateTime &&
      dateTimeInfo?.scheduledStartDateTime &&
      dateTimeInfo?.scheduledEndDateTime
    ) {
      setStartDateTime(dateTimeInfo.scheduledStartDateTime);
      setEndDateTime(dateTimeInfo.scheduledEndDateTime);
      setNote(dateTimeInfo.dropOffNote);
    }
  }, [dateTimeInfo, startDateTime, endDateTime]);

  // pass in payout values when opening
  useEffect(() => {
    if (!closeTripDialogOpen) return;

    const routePayoutFields = isPayoutAutomated
      ? {
          driverPayoutFlatRate: route?.automatedDriverPayoutFlatRate,
          driverPayoutHours: route?.automatedDriverPayoutHours,
          driverPayoutRatePerHour: route?.automatedDriverPayoutRatePerHour,
          driverPayoutGratuity: calculateAutomatedGratuityAmount(
            route?.pricing?.driverGratuityAmt ||
              route?.farmeePricing?.driverGratuityAmt,
            route?.automatedDriverPayoutGratuity
          ),
        }
      : {
          driverPayoutFlatRate: route?.driverPayoutFlatRate,
          driverPayoutHours: route?.driverPayoutHours,
          driverPayoutRatePerHour: route?.driverPayoutRatePerHour,
          driverPayoutGratuity: route?.driverPayoutGratuity,
        };
    setDriverPayoutFields(routePayoutFields);
  }, [closeTripDialogOpen, setDriverPayoutFields, route, isPayoutAutomated]);

  return (
    <MoovsDialog
      onClose={onCloseTripDialogClose}
      open={closeTripDialogOpen}
      dialogTitle="Close Trip"
      acceptButtonText="Close Trip"
      closeButtonText="Cancel"
      acceptDisabled={closeTripButtonDisabled}
      onAccept={handleAcceptClick}
      size="sm"
      hideLoadingIndicator={!closeOutMutationLoading}
    >
      {!!operatorRoute && (
        <CloseTripDialogBlock
          scheduledStartDateTime={dateTimeInfo.scheduledStartDateTime}
          scheduledEndDateTime={dateTimeInfo.scheduledEndDateTime}
          startDateTime={startDateTime}
          endDateTime={endDateTime}
          operatorRoute={operatorRoute}
          onDateTimeChange={handleDateTimeChange}
          dateTimeInfo={dateTimeInfo}
        />
      )}
      <CloseOutNotes
        note={note}
        onNoteChange={handleNoteChange}
        operatorRoute={operatorRoute}
        noteError={noteError}
      />
      {!isFarmor && (
        <DriverPayoutFields
          route={route}
          driverPayoutFields={driverPayoutFields}
          onInputChange={handlePayoutInputChange}
          isPayoutAutomated={isPayoutAutomated}
          isCloseTripVariant
        />
      )}
    </MoovsDialog>
  );
}

export default CloseTripDialog;
