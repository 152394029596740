import React, { useCallback } from "react";

import { Box, Divider, Typography } from "@mui/material";

import { Trip, Vehicle as VehicleType } from "types";
import AddVehicleCreateBlock from "components/vehicles/AddVehicleCreateBlock";
import VehicleAvailabilityContent from "components/vehicles/VehicleAvailabilityDialog/VehicleAvailabilityContent";
import AddVehicleUpdateBlock from "../../../AddVehicleUpdateBlock";
import { useAddVehicleToRoute } from "../../../hooks";
import { QuoteTabViewVariant } from "./types";

type VehicleProps = {
  trip: Trip;
  tripRefetch: () => void;
  setSaveIndicatorState: (
    saveState: "loading" | "default" | "saved" | "error"
  ) => void;
  tabView: QuoteTabViewVariant;
};

function Vehicle(props: VehicleProps) {
  const { trip, tripRefetch, setSaveIndicatorState, tabView } = props;

  // hooks
  const { addVehicleToRoute } = useAddVehicleToRoute({ setSaveIndicatorState });

  // derived state
  const vehicle = trip.routes[0].vehicle;
  const stops = trip.stops;
  const routeId = trip.routes[0].id;

  // callback
  const addVehicles = useCallback(
    async (vehicles: Partial<VehicleType>[]) => {
      const vehicleId = vehicles[0].id;
      await addVehicleToRoute(routeId, vehicleId);
      tripRefetch();
    },
    [addVehicleToRoute, routeId, tripRefetch]
  );

  return (
    <Box>
      {!!vehicle ? (
        <AddVehicleUpdateBlock
          mode="quotes"
          trip={trip}
          refetch={tripRefetch}
          setSaveIndicatorState={setSaveIndicatorState}
          hideVehicleAvailability
        />
      ) : (
        <AddVehicleCreateBlock
          addVehicles={addVehicles}
          stops={stops}
          hideVehicleAvailability
        />
      )}

      {!!vehicle && (
        <Box display="flex" flexDirection="column">
          <Box mt={1.5} mb={1.5} display="flex">
            <Typography variant="h5" style={{ marginRight: 10 }}>
              Availability
            </Typography>
          </Box>
          <Box mb={1.5}>
            <Divider />
          </Box>
          <VehicleAvailabilityContent
            open={tabView === QuoteTabViewVariant.VEHICLE}
            vehicleId={vehicle?.id}
            routeId={routeId}
            stops={stops}
          />
        </Box>
      )}
    </Box>
  );
}

export default Vehicle;
