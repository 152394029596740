import React from "react";

import { Typography, TypographyVariant } from "@mui/material";

import { successGreen, errorRed } from "../../design-system/colors";
import { currency } from "../../globals/utils/helpers";

type BillingDisplayProps = {
  amount: number;
  sxStyles?: any;
  variant?: TypographyVariant;
  hideWhenNoAmount?: boolean;
  showDashWhenNoAmount?: boolean;
  hideColor?: boolean;
};

function BillingDisplay(props: BillingDisplayProps) {
  const {
    amount,
    sxStyles,
    variant,
    hideWhenNoAmount,
    showDashWhenNoAmount,
    hideColor,
  } = props;

  if (hideWhenNoAmount && !amount) return null;

  if (showDashWhenNoAmount && !amount) {
    return (
      <Typography sx={sxStyles} variant={variant || "body2"}>
        {amount || "-"}
      </Typography>
    );
  }

  const isPositive = amount >= 0;

  return (
    <Typography
      sx={sxStyles}
      variant={variant || "body2"}
      style={!hideColor ? { color: isPositive ? successGreen : errorRed } : {}}
    >
      {currency(amount)}
    </Typography>
  );
}

export default BillingDisplay;
