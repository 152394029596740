import React from "react";

import { Box, Switch, Typography } from "@mui/material";

import MoovsEllipsisMenu from "components/MoovsEllipsisMenu";
import { grayLight } from "design-system/colors";
import { EditIcon } from "design-system/icons";

type EditActivateMobileProps = {
  onEditClick: () => void;
  subtitle: string;
  activationStatus: boolean;
  onHandleActivateSwitch: (e) => void;
};

function EditActivateMobile(props: EditActivateMobileProps) {
  const { onEditClick, onHandleActivateSwitch, subtitle, activationStatus } =
    props;

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="subtitle2">{subtitle}</Typography>
      <Box display="flex" justifyContent="center" alignItems="center ">
        <Box
          display="flex"
          alignItems="center"
          borderRight="1px solid"
          borderColor={grayLight}
          height="100%"
        >
          <Box>
            <MoovsEllipsisMenu
              options={[
                {
                  icon: <EditIcon />,
                  text: "Edit",
                  onClick: onEditClick,
                },
              ]}
            />
          </Box>
        </Box>
        <Box pl={2} pr={0.5}>
          <Switch
            checked={activationStatus}
            color="primary"
            onChange={onHandleActivateSwitch}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default EditActivateMobile;
