/**
 * @file useSnackbar.ts
 * useSnackbar custom hook.
 *
 * Exposes snackbarContext to functional components.
 *
 * author: jackv
 */

import { useContext } from "react";
import SnackbarContext from "./Context";

function useSnackbar() {
  const snackbarContext = useContext(SnackbarContext);

  return snackbarContext.current;
}

export default useSnackbar;
