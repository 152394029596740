import { PricingLayout } from "types";

// parses pricing layout to add to initial route
export const parsePricingLayoutForInitialRoute = (
  pricingLayout: PricingLayout
) => {
  if (!pricingLayout) return {};

  return {
    // driver gratuity
    driverGratuityAmt: pricingLayout.driverGratuityAmt || null,
    driverGratuityPercent: pricingLayout.driverGratuityPercent * 100 || null,

    // promo discount
    promoDiscountAmt: pricingLayout.promoDiscountAmt || null,
    promoDiscountPercent: pricingLayout.promoDiscountPercent * 100 || null,

    // tax
    taxAmt: pricingLayout.taxAmt || null,
    taxPercent: pricingLayout.taxPercent * 100 || null,

    // tolls
    tollsAmt: pricingLayout.tollsAmt || null,

    // meet & greet
    meetGreetAmt: null,

    // other
    ...((pricingLayout.otherAmt || pricingLayout.otherPercent) && {
      otherAmt: pricingLayout.otherAmt || null,
      otherPercent: pricingLayout.otherPercent * 100 || null,
      otherName: pricingLayout.otherName,
    }),

    // other 2
    ...((pricingLayout.other2Amt || pricingLayout.other2Percent) && {
      other2Amt: pricingLayout.other2Amt || null,
      other2Percent: pricingLayout.other2Percent * 100 || null,
      other2Name: pricingLayout.other2Name,
    }),

    // other 3
    ...((pricingLayout.other3Amt || pricingLayout.other3Percent) && {
      other3Amt: pricingLayout.other3Amt || null,
      other3Percent: pricingLayout.other3Percent * 100 || null,
      other3Name: pricingLayout.other3Name,
    }),
  };
};
