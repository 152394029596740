/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment } from "react";
import moment from "moment-timezone";
import map from "lodash/map";

import { Box, Card, Typography, Divider } from "@mui/material";

import {
  grayDark,
  paymentStatusLabelColors,
} from "../../../../../../design-system/colors";
import {
  Payment,
  PaymentPaymentStatus,
  PaymentRefund,
  SubPayment,
} from "../../../../../../types";
import RoundChip from "../../../../../../design-system/components/chips/RoundChip";
import { currency } from "../../../../../../globals/utils/helpers";
import MoovsEllipsisMenu from "components/MoovsEllipsisMenu";

type PaymentItemProps = {
  subPayment: SubPayment | PaymentRefund;
  onOpenRefundDialog?: (subPayment: SubPayment) => void;
  onOpenViewPayment?: (payment: Payment | PaymentRefund) => void;
  isEllipsisMenuDisabled?: boolean;
};

function PaymentItem(props: PaymentItemProps) {
  const {
    subPayment,
    onOpenRefundDialog,
    onOpenViewPayment,
    isEllipsisMenuDisabled,
  } = props;

  const payment =
    subPayment.__typename === "SubPayment" ? subPayment.payment : subPayment;

  const handleRefundMenuOptionClick = (parentPayment) => {
    onOpenRefundDialog(parentPayment);
  };

  const handleClickOnViewMore = () => {
    onOpenViewPayment(payment);
  };

  let totalRefundedAmount = 0;
  if ("refunds" in subPayment) {
    subPayment.refunds?.forEach((refund: PaymentRefund) => {
      totalRefundedAmount += refund.amount;
    });
  }
  const isFullyRefunded = totalRefundedAmount === subPayment.amount;

  const payoutDate = payment.stripePayoutDate
    ? moment(payment.stripePayoutDate).format("MM/DD/YY")
    : "Pending";

  const styles = {
    columnTitle: {
      color: grayDark,
      lineHeight: 1.43,
    },
  };

  return (
    <Box display="flex" flexDirection="row" p={2}>
      <Box display="flex" flexDirection="column" flex="1">
        <Box display="flex" flexDirection="row">
          <Box display="flex" flexDirection="column" flex=".8">
            <Box>
              <Typography
                gutterBottom={true}
                variant="overline"
                sx={styles.columnTitle}
              >
                amount
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">
                {currency(subPayment.amount)}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" flex="1">
            <Box>
              <Typography
                gutterBottom={true}
                variant="overline"
                sx={styles.columnTitle}
              >
                payment
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">
                {{
                  CARD: `**** - ${payment?.paymentMethod?.card?.last4}`,
                  CASH: "Cash",
                  VENMO: "Venmo",
                  CHECK: "Check",
                  WIRE: "Wire",
                  GENERIC_CARD: "Credit Card",
                }[payment.method] || "N/A"}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" flex="1.5">
            <Box>
              <Typography
                gutterBottom={true}
                variant="overline"
                sx={styles.columnTitle}
              >
                date and time
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">
                {moment(payment.updatedAt).format("MM/DD/YY h:mm a")}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" flex="1">
            <Box>
              <Typography
                gutterBottom={true}
                variant="overline"
                sx={styles.columnTitle}
              >
                status
              </Typography>
            </Box>
            <Box>
              <RoundChip {...paymentStatusLabelColors[payment.paymentStatus]} />
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box display="flex" flexDirection="column" flex=".23">
            <Typography
              gutterBottom={true}
              variant="overline"
              sx={styles.columnTitle}
            >
              payout date
            </Typography>
            <Typography variant="body2">{payoutDate}</Typography>
          </Box>

          <Box display="flex" flexDirection="column" flex="1">
            <Typography
              gutterBottom={true}
              variant="overline"
              sx={styles.columnTitle}
            >
              note
            </Typography>
            <Typography variant="body2">{payment.note || "-"}</Typography>
          </Box>
        </Box>
      </Box>
      <Box width={48}>
        {!isEllipsisMenuDisabled && (
          <Box>
            <MoovsEllipsisMenu
              options={[
                {
                  icon: null,
                  text: "View More",
                  onClick: handleClickOnViewMore,
                },
                {
                  icon: null,
                  text: "Refund",
                  onClick: () => handleRefundMenuOptionClick(subPayment),
                  disableOption: isFullyRefunded,
                  hideOption: !(
                    payment.paymentStatus === PaymentPaymentStatus.Succeeded
                  ),
                },
              ]}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

type PaymentListItemDesktopProps = {
  subPayment: SubPayment;
  onOpenRefundDialog: (subPayment: SubPayment) => void;
  onOpenViewPayment: (payment: Payment) => void;
  isEllipsisMenuDisabled?: boolean;
};

function PaymentListItemDesktop(props: PaymentListItemDesktopProps) {
  const {
    subPayment,
    onOpenRefundDialog,
    onOpenViewPayment,
    isEllipsisMenuDisabled,
  } = props;

  return (
    <Box mb={1} key={subPayment.id}>
      <Card key={subPayment.id} variant="outlined">
        <PaymentItem
          subPayment={subPayment}
          onOpenRefundDialog={onOpenRefundDialog}
          onOpenViewPayment={onOpenViewPayment}
          isEllipsisMenuDisabled={isEllipsisMenuDisabled}
        />
        {map(subPayment.refunds, (refund) => {
          return (
            <Fragment key={refund.id}>
              <Box mx={2}>
                <Divider />
              </Box>
              <PaymentItem
                subPayment={refund}
                onOpenViewPayment={onOpenViewPayment}
              />
            </Fragment>
          );
        })}
      </Card>
    </Box>
  );
}

export default PaymentListItemDesktop;
