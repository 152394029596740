import React, { useRef } from "react";

import { Box, IconButton } from "@mui/material";

import {
  moovsBlue,
  grayMedium,
  alabaster,
} from "../../../../design-system/colors";
import { ArrowLeftIcon, ArrowRightIcon } from "../../../../design-system/icons";
import { useScreenSize } from "../../../../globals/hooks";

type TemplateCarouselProps = {
  activeTemplate: any;
  leftArrowDisabled: boolean;
  rightArrowDisabled: boolean;
  onNextTemplateClick: () => void;
  onPreviousTemplateClick: () => void;
};

function TemplateCarousel(props: TemplateCarouselProps) {
  const {
    activeTemplate,
    leftArrowDisabled,
    rightArrowDisabled,
    onNextTemplateClick,
    onPreviousTemplateClick,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // refs
  const topOfWebsitePreviewImageRef = useRef(null);

  const previewImage = isMobileView
    ? activeTemplate?.mobileThumbnailUrl
    : activeTemplate?.desktopThumbnailUrl;

  // event handlers
  const handleNextClick = () => {
    topOfWebsitePreviewImageRef?.current?.scrollIntoView({
      block: "center",
    });
    onNextTemplateClick();
  };

  const handlePreviousClick = () => {
    topOfWebsitePreviewImageRef?.current?.scrollIntoView({
      block: "center",
    });
    onPreviousTemplateClick();
  };

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      overflow="hidden"
      {...(isMobileView && { bgcolor: alabaster })}
      style={{ cursor: "default" }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height={isMobileView ? "100%" : 500}
        py={2}
      >
        <Box ml={isMobileView ? 0 : 2}>
          <IconButton onClick={handlePreviousClick} disabled={leftArrowDisabled} size="large">
            <ArrowLeftIcon color={leftArrowDisabled ? grayMedium : moovsBlue} />
          </IconButton>
        </Box>
        <Box
          display="flex"
          width="100%"
          height="100%"
          mx={isMobileView ? 1 : 5}
          boxShadow="0px 4px 15px 0px rgba(0, 0, 0, 0.2)"
          borderRadius="4px"
          overflow="hidden"
        >
          <Box overflow="auto" width="100%" height="100%">
            <div ref={topOfWebsitePreviewImageRef} />
            <img
              style={{
                objectFit: "cover",
                width: "100%",
              }}
              src={previewImage}
              alt="website template preview"
            />
          </Box>
        </Box>
        <Box ml={isMobileView ? 0 : 2}>
          <IconButton onClick={handleNextClick} disabled={rightArrowDisabled} size="large">
            <ArrowRightIcon
              color={rightArrowDisabled ? grayMedium : moovsBlue}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default TemplateCarousel;
