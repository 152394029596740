import React from "react";
import { useMutation } from "@apollo/client";

import { grayDark, white } from "design-system/colors";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { ConfirmReservationDisabledVariantEnum } from "types";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";
import { EmailIcon } from "design-system/icons";
import { SEND_EMAIL_TO_CUSTOMER_MUTATION } from "globals/graphql";
import { getConfirmReservationDisabledTooltip } from "globals/utils/getConfirmReservationDisabledTooltip";

type UseSendQuoteClickProps = {
  confirmReservationDisabledVariant: ConfirmReservationDisabledVariantEnum;
  requestId: string;
  analyticsName?: string;
};

function useSendQuoteClick(props: UseSendQuoteClickProps): EllipsisMenuOption {
  const { analyticsName, requestId, confirmReservationDisabledVariant } = props;

  // hooks
  const { track } = useAnalytics();
  const snackbar = useSnackbar();

  // mutations
  const [sendEmailToCustomer] = useMutation(SEND_EMAIL_TO_CUSTOMER_MUTATION, {
    refetchQueries: ["Request"],
    onCompleted(data) {
      analyticsName && track(analyticsName, { location: "more-menu" });
      const bookingContact = data?.sendEmailToCustomer?.request?.bookingContact;
      const bookingContactName = `${bookingContact?.firstName} ${bookingContact?.lastName}`;

      snackbar.success(`Email sent to ${bookingContactName}`, {
        snackbarColor: white,
      });
    },
    onError(error) {
      snackbar.error("Error sending email");
    },
  });

  // event handlers
  const handleSendQuoteEmail = () => {
    sendEmailToCustomer({
      variables: {
        input: {
          requestId,
          mode: "QUOTE",
        },
      },
    });
  };

  return {
    text: "Send Quote",
    icon: <EmailIcon color={grayDark} size="small" />,
    onClick: handleSendQuoteEmail,
    disableOption: !!confirmReservationDisabledVariant,
    tooltip: confirmReservationDisabledVariant
      ? {
          titleText: getConfirmReservationDisabledTooltip(
            confirmReservationDisabledVariant
          ),
        }
      : null,
  };
}

export { useSendQuoteClick };
