import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Box, Typography, Divider, Button } from "@mui/material";

import websiteBrowser from "../../design-system/illustrations/websiteBrowser.svg";
import WebsiteTemplateDialog from "./WebsiteTemplateDialog/WebsiteTemplateDialog";
import { useOperator, useScreenSize } from "../../globals/hooks";
import theme from "theme";
import { PlanVariant } from "types";
import MoovsTooltip from "components/MoovsTooltip";
import RoundChip from "design-system/components/chips/RoundChip";
import { ProPlanIcon } from "design-system/icons";
import { aqua, tintAqua } from "design-system/colors";

const styles = {
  button: {
    py: "13px",
  },
  container: {
    minHeight: "100%",
    flex: 1,
    maxWidth: "826px",
    margin: "auto",
    padding: 0,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      overflowY: "auto",
    },
  },
  header: {
    minHeight: "78px",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  upgradeButton: {
    marginRight: theme.spacing(2),
    marginBottom: 0,
    [theme.breakpoints.down("lg")]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
      width: "100%",
    },
  },
};

type CreateWebsiteBlockProps = {
  operatorSlug: string;
  companyName: string;
  location: string;
};
function CreateWebsiteBlock(props: CreateWebsiteBlockProps) {
  const { operatorSlug, companyName, location } = props;

  // hooks
  const { isMediumTabletView } = useScreenSize();
  const operator = useOperator();
  const history = useHistory();

  // derived state
  const isProOrVip = [PlanVariant.Pro, PlanVariant.Vip].includes(operator.plan);

  // state
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false);

  // event handler
  const handleWebsiteDialogOpen = () => setTemplateDialogOpen(true);
  const handleWebsiteDialogClose = () => setTemplateDialogOpen(false);

  const handleRedirectToPlans = () => {
    history.push("/settings/billing/plans");
  };

  return (
    <>
      <Box display="flex" flexDirection="column" sx={styles.container}>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          sx={styles.header}
        >
          <Typography variant="h2">Create a Website</Typography>
          {!isProOrVip && (
            <Box ml={2}>
              <RoundChip
                icon={ProPlanIcon}
                backgroundColor={tintAqua}
                label="Pro"
                color={aqua}
                iconProps={{ size: "small" }}
              />
            </Box>
          )}
        </Box>
        <Divider />
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            mt={isMediumTabletView ? 7 : 24}
            alignContent="center"
            justifyContent="center"
            display="flex"
            flexDirection="column"
            textAlign="center"
            maxWidth={isMediumTabletView ? "310px" : "410px"}
          >
            <Box mb={3} width="100%">
              <img width="140px" src={websiteBrowser} alt="website-graphic" />
            </Box>
            <Box mb={1}>
              <Typography variant="h3">
                Kickstart your business with your site
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="body2">
                Using the information you’ve entered in Moovs, we can instantly
                create a website for you!
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection={isMediumTabletView ? "column" : "row"}
              justifyContent="center"
              alignItems="center"
            >
              {!isProOrVip && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRedirectToPlans}
                  sx={[styles.button, styles.upgradeButton]}
                >
                  Upgrade Plan
                </Button>
              )}

              {/* work around for max-width applied to MoovsTooltip when flex is used */}
              <Box {...(isMediumTabletView && { width: "100%" })}>
                <MoovsTooltip
                  tooltipText={
                    "Must upgrade to Pro package to create a website"
                  }
                  hidden={isProOrVip}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleWebsiteDialogOpen}
                    sx={styles.button}
                    disabled={!isProOrVip}
                    {...(isMediumTabletView && { fullWidth: true })}
                  >
                    Get Started
                  </Button>
                </MoovsTooltip>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <WebsiteTemplateDialog
        operatorSlug={operatorSlug}
        companyName={companyName}
        location={location}
        open={templateDialogOpen}
        onClose={handleWebsiteDialogClose}
      />
    </>
  );
}

export default CreateWebsiteBlock;
