/**
 * Component to manage shared queries/mutations/state/even handlers
 * between QuoteOverviewMobile and QuoteOverviewDesktop
 * */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import clipboardCopy from "clipboard-copy";

import { Box, CircularProgress } from "@mui/material";

import RequestActivityLogDialog from "components/globals/ActivityLog/RequestActivityLogDialog";
import { grayDark } from "design-system/colors";
import { AddTripIcon, LinkIcon } from "design-system/icons";
import { LOAD_REQUEST_QUERY } from "globals/graphql";
import { useOperator, useScreenSize, useSnackbar } from "globals/hooks";
import { Request } from "types";
import {
  useMakeReservationClick,
  useViewActivityLogClick,
} from "pages/reservations/components/ActionButtonPanel/components/ViewMoreActionButton/hooks";
import { useArchiveQuoteClick } from "pages/reservations/components/ActionButtonPanel/components/ViewMoreActionButton/hooks/useArchiveQuoteClick";
import { useSalesAutomationClick } from "pages/reservations/components/ActionButtonPanel/components/ViewMoreActionButton/hooks/useSalesAutomationClick";
import { useSendQuoteClick } from "pages/reservations/components/ActionButtonPanel/components/ViewMoreActionButton/hooks/useSendQuoteClick";
import {
  NoTripsText,
  QuoteOverviewDesktop,
  QuoteOverviewMobile,
} from "./components";
import { getRequestStage } from "globals/utils/getRequestStage";
import { pluralize } from "globals/utils/helpers";

function QuoteOverviewManager() {
  //  hooks
  const history = useHistory();
  const location = useLocation();
  const snackbar = useSnackbar();
  const { isMobileView } = useScreenSize();
  const { requestId } = useParams<{ requestId: string }>();
  const operator = useOperator();

  // queries
  const shouldSkipQuery = !requestId;

  const { data: requestData, loading: requestLoading } = useQuery(
    LOAD_REQUEST_QUERY,
    {
      variables: {
        id: requestId,
      },
      ...(!shouldSkipQuery && { pollInterval: 60 * 1000 }), // every 30 seconds
      skip: shouldSkipQuery,
    }
  );

  // state
  const [editOrderTypeDialogOpen, setEditOrderTypeDialogOpen] = useState(false);

  const request: Request = requestData?.node || null;

  const requestStage = request && getRequestStage(request.stage);

  // redirect to quote or reservation if on wrong page
  // i.e. if somehow visited /quotes/ when it is a reservation
  useEffect(() => {
    if (requestStage && requestStage !== "quote") {
      // TODO: fix mobile view drawer animation

      history.replace(
        `/${pluralize(requestStage)}/${requestId}/${location.search}`
      );
    }
  }, [history, isMobileView, location.search, requestStage, requestId]);

  // hook continuation
  const sendQuoteMenuOption = useSendQuoteClick({
    requestId,
    analyticsName: "send_quote",
    confirmReservationDisabledVariant:
      request?.confirmReservationDisabledVariant,
  });

  const makeReservationMenuOption = useMakeReservationClick({
    requestId,
    analyticsName: "",
    confirmReservationDisabledVariant:
      request?.confirmReservationDisabledVariant,
  });

  const viewActivityLogMenuOption = useViewActivityLogClick({
    isFarmee: false, // quotes can't be farmed
    analyticsName: "quote_activityLogViewed",
  });

  const archiveQuoteMenuOption = useArchiveQuoteClick({
    requestId,
    isArchived: request?.isArchived,
    isExpired: request?.isExpired,
  });

  const salesAutomationMenuOption = useSalesAutomationClick({
    salesAutomationDisabledVariant: request?.salesAutomation?.disabledVariant,
    analyticsName: "quote_salesAutomation",
  });

  // render methods
  if ((!request && requestLoading) || !request) {
    return (
      <Box mt={8} width="100%" display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  // more derived state
  const hasNoTrips = !request?.trips?.length;
  const whiteLabelOperatorName = request.whiteLabelOperator?.name;

  // event handlers
  const handleEditOrderType = () => {
    setEditOrderTypeDialogOpen(true);
  };

  // ellipsisMenuOptions exclusive event handlers
  const handleAddTripToQuote = () => {
    history.push(`/quotes/${requestId}/add-trip`, {
      request,
    });
  };

  const handleCopyQuoteLink = async () => {
    try {
      const clipboardText = `${process.env.REACT_APP_CUSTOMERAPP_URL}/${operator.nameSlug}/order/${request.id}`;
      await clipboardCopy(clipboardText);
      snackbar.success(`Successfully copied quote link!`);
    } catch (error) {
      snackbar.error(`Error copying quote link.`);
    }
  };

  const ellipsisMenuOptions = [
    {
      text: "Add Trip",
      icon: <AddTripIcon color={grayDark} size="small" />,
      onClick: handleAddTripToQuote,
      disabled: false,
    },
    "divider",
    sendQuoteMenuOption,
    salesAutomationMenuOption,
    makeReservationMenuOption,
    {
      text: "Copy Quote Link",
      icon: <LinkIcon color={grayDark} size="small" />,
      onClick: handleCopyQuoteLink,
    },
    "divider",
    viewActivityLogMenuOption,
    archiveQuoteMenuOption,
  ];

  return (
    <>
      {hasNoTrips ? (
        <NoTripsText orderNumber={request?.orderNumber || ""} />
      ) : (
        <>
          {/**
           * TODO: Lots of shared logic exists in QuoteOverviewMobile and QuoteOverviewDesktop
           * If larger changes get added on top of QuoteOverview, consider first creating a parent
           * component that routes to either mobile and desktop variants. This component can house
           * shared logic and state that both components have.
           *  */}
          {isMobileView ? (
            <QuoteOverviewMobile
              ellipsisMenuOptions={ellipsisMenuOptions}
              request={request}
              editOrderTypeDialogOpen={editOrderTypeDialogOpen}
              setEditOrderTypeDialogOpen={setEditOrderTypeDialogOpen}
              whiteLabelOperatorName={whiteLabelOperatorName}
              onEditOrderType={handleEditOrderType}
              salesAutomationOpen={salesAutomationMenuOption.open}
              handleSalesAutomationClose={salesAutomationMenuOption.onClose}
            />
          ) : (
            <QuoteOverviewDesktop
              ellipsisMenuOptions={ellipsisMenuOptions}
              request={request}
              editOrderTypeDialogOpen={editOrderTypeDialogOpen}
              setEditOrderTypeDialogOpen={setEditOrderTypeDialogOpen}
              whiteLabelOperatorName={whiteLabelOperatorName}
              onEditOrderType={handleEditOrderType}
              salesAutomationOpen={salesAutomationMenuOption.open}
              handleSalesAutomationClose={salesAutomationMenuOption.onClose}
            />
          )}
          <RequestActivityLogDialog
            open={viewActivityLogMenuOption.open}
            onClose={viewActivityLogMenuOption.onClose}
            requestId={request?.id}
            orderNumber={request?.orderNumber}
            requestStage={request?.stage}
          />
        </>
      )}
    </>
  );
}
export default QuoteOverviewManager;
