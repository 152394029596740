/**
 * @file conversation/hooks/useParticipant.ts
 *
 * hook for fetching participants from a conversation
 */

import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";
import { LOAD_CHAT_PARTICIPANTS_QUERY } from "../../../globals/graphql";
import { useQueryParam, StringParam } from "use-query-params";

import { ConversationType } from "../../../globals/hooks/useChat/Provider";

type useParticipantProps = {
  conversations: Map<string, ConversationType>;
  setConversations: Dispatch<SetStateAction<Map<string, ConversationType>>>;
};

export function useParticipant(props: useParticipantProps) {
  const { conversations, setConversations } = props;

  // hooks
  const [chatPanelOpen] = useQueryParam("chatPanel", StringParam);

  // state
  const [queriedChatParticipants, setQueriedChatParticipants] = useState(
    new Set()
  );
  const [debouncedConversations] = useDebounce(conversations, 500, {
    trailing: true,
  });

  const chatParticipants = useMemo(() => {
    return [...debouncedConversations.entries()]
      .map(([conversationSid, conversation]) => {
        return {
          conversationSid,
          mobilePhone: conversation.participant?.mobilePhone,
        };
      })
      .filter(
        ({ mobilePhone, conversationSid }) =>
          mobilePhone && !queriedChatParticipants.has(conversationSid)
      );
  }, [debouncedConversations, queriedChatParticipants]);

  // queries
  useQuery(LOAD_CHAT_PARTICIPANTS_QUERY, {
    variables: {
      chatParticipants,
    },
    onCompleted(data) {
      const newQueriedChatParticipants = new Set([
        ...queriedChatParticipants,
        ...chatParticipants.map(({ conversationSid }) => conversationSid),
      ]);

      setQueriedChatParticipants(newQueriedChatParticipants);

      data?.loadChatParticipants.forEach(({ conversationSid, participant }) => {
        const conversation = conversations.get(conversationSid);

        // resets conversation if no participant found (i.e. contact / driver removed or number changed)
        if (!participant) {
          conversations.set(conversationSid, {
            ...conversation,
            participant: {
              mobilePhone: conversation.participant.mobilePhone,
              proxy: conversation.participant.proxy,
            },
          });
          return;
        }

        const { firstName, lastName, mobilePhone, phoneCountryCode } =
          participant;

        const role =
          participant?.__typename === "Driver" ? "driver" : "contact";

        conversations.set(conversationSid, {
          ...conversation,
          participant: {
            ...conversation.participant,
            role,
            phoneCountryCode,
            mobilePhone,
            name: `${firstName || ""} ${lastName || ""}`,
          },
        });
      });

      setConversations(new Map(conversations));
    },
    skip: !chatParticipants.length || !chatPanelOpen,
  });
}
