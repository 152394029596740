import React from "react";
import { Control, Controller } from "react-hook-form";

import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import {
  alabaster,
  black,
  grayLight,
  grayMedium,
  moovsBlueHover,
  moovsBlueSelected,
  white,
} from "design-system/colors";
import { primaryMainColor } from "theme";

const styles = {
  "&.MuiToggleButton-root": {
    padding: 0,
    borderColor: grayMedium,
    backgroundColor: white,
    color: black,
    ":hover": {
      backgroundColor: alabaster,
    },
    ":focus": {
      backgroundColor: grayLight,
    },
    "&.Mui-selected": {
      backgroundColor: moovsBlueSelected,
      color: black,
      border: `${primaryMainColor} 1px solid !important`,
      zIndex: 1,
      ":hover": {
        backgroundColor: moovsBlueHover,
      },
      ":focus": {
        backgroundColor: moovsBlueSelected,
      },
    },
  },
};

type RHFToggleButtonsProps = {
  name: string;
  control: Control<any>;
  toggleButtons: {
    ariaLabel: string;
    value: boolean;
    icon: React.ReactNode;
  }[];
};

function RHFToggleButtons(props: RHFToggleButtonsProps) {
  const { name, control, toggleButtons } = props;
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ...otherFields } }) => (
          <ToggleButtonGroup
            exclusive
            aria-label="toggle-button-group"
            {...otherFields}
            onChange={(e, value) => {
              onChange(value);
            }}
          >
            {toggleButtons.map((button) => (
              <ToggleButton
                key={button.ariaLabel}
                value={button.value}
                aria-label={button.ariaLabel}
                sx={[styles]}
                style={{ width: 50, height: 38 }}
              >
                {button.icon}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}
      />
    </>
  );
}

export default RHFToggleButtons;
