import React from "react";
import { useMutation } from "@apollo/client";

import { Box, IconButton, Tooltip, Typography } from "@mui/material";

import { useOperator, useSnackbar } from "../../../../globals/hooks";
import {
  VALIDATE_SENDGRID_CUSTOM_DOMAIN,
  REMOVE_SENDGRID_CUSTOM_DOMAIN,
} from "../../../../globals/graphql";
import CustomDomainInformation from "./CustomDomainInformation";
import EnableCustomDomainFlow from "./EnableCustomDomainFlow";
import { InfoIcon } from "../../../../design-system/icons";

type CustomDomainBlockProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function CustomDomainBlock(props: CustomDomainBlockProps) {
  const { setSaveIndicatorState } = props;

  const operator = useOperator();
  const snackbar = useSnackbar();

  const isCustomDomainConnected = !!operator?.customDomain;

  const unauthenticatedText = `Your domain doesn’t seem to be connected yet.\nIf you’ve added CNAME records recently it may take up to 48 hours to update.`;

  // mutations
  const [validateSendgridCustomDomain] = useMutation(
    VALIDATE_SENDGRID_CUSTOM_DOMAIN,
    {
      onCompleted(data) {
        setSaveIndicatorState("saved");
        const isDomainValidated =
          data.validateSendgridCustomDomain.operator.customDomainIsValid;
        if (!isDomainValidated) {
          snackbar.error(unauthenticatedText);
        }
      },
      onError(error) {
        setSaveIndicatorState("error");
        snackbar.error("error adding custom domain");
      },
    }
  );

  const [removeSendgridCustomDomain] = useMutation(
    REMOVE_SENDGRID_CUSTOM_DOMAIN,
    {
      onCompleted(data) {
        setSaveIndicatorState("saved");
        snackbar.success("Custom domain removed.");
      },
      onError(error) {
        setSaveIndicatorState("error");
        snackbar.error("error resetting custom domain");
      },
    }
  );

  const tooltipText =
    "Setting up a custom domain allows you to send emails using your own domain";

  return <>
    <Box mt={4} mb={2} display="flex" alignItems="center">
      <Typography variant="h5">Custom Domain</Typography>
      <Tooltip enterDelay={200} title={tooltipText} placement="top">
        <IconButton aria-label="info" size="large">
          <InfoIcon size="small" />
        </IconButton>
      </Tooltip>
    </Box>
    <Box>
      {!!operator && !isCustomDomainConnected && (
        <EnableCustomDomainFlow
          setSaveIndicatorState={setSaveIndicatorState}
        />
      )}
      {!!operator && isCustomDomainConnected && (
        <CustomDomainInformation
          removeSendgridCustomDomain={removeSendgridCustomDomain}
          validateSendgridCustomDomain={validateSendgridCustomDomain}
          setSaveIndicatorState={setSaveIndicatorState}
          customDomain={operator.customDomain}
          customDomainSendgridData={operator.customDomainSendgridData}
          customDomainIsAuthenticated={operator.customDomainIsValid}
        />
      )}
    </Box>
  </>;
}

export default CustomDomainBlock;
