/**
 * DispatchTripDetails
 * - displays the f driver name, vehicle name,  passenger name and date of a trip
 */
import React from "react";
import moment from "moment-timezone";

import { Box, Tooltip, Typography } from "@mui/material";

import { errorRed, grayDark } from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import { driverStatusEnum } from "globals/utils/enumMaps";
import { DriverStatusName } from "types";
import {
  CalendarIcon,
  PassengerCountIcon,
  VehicleIcon,
  WheelIcon,
} from "design-system/icons";

export type DispatchTripDetailsProps = {
  driverName: string | null;
  driverAcceptanceStatus: DriverStatusName | null | undefined;
  vehicleName: string | null | undefined;
  passengerName: string;
  pickUpTime: Date;
};

function DispatchTripDetails(props: DispatchTripDetailsProps) {
  const {
    driverName,
    driverAcceptanceStatus,
    vehicleName,
    passengerName,
    pickUpTime,
  } = props;

  const { isMobileView } = useScreenSize();

  const driverNameIcon = driverStatusEnum[driverAcceptanceStatus]?.icon;

  const tripDetailsList = [
    {
      icon: <WheelIcon size="small" color={grayDark} />,
      text: driverName || "Driver Unassigned",
      additionalIcon: driverNameIcon && (
        <Box ml={isMobileView ? 0.5 : 1}>{driverNameIcon}</Box>
      ),
      textErrorColor: !driverName ? errorRed : "inherit",
      toolTipText: "Driver",
    },
    {
      icon: <VehicleIcon size="small" color={grayDark} />,
      text: vehicleName || "Vehicle Unassigned",
      textErrorColor: !vehicleName ? errorRed : "inherit",
      toolTipText: "Vehicle",
    },
    {
      icon: <PassengerCountIcon size="small" color={grayDark} />,
      text: passengerName,
      toolTipText: "Passenger",
    },
    {
      icon: <CalendarIcon size="small" color={grayDark} />,
      text: moment.utc(pickUpTime).format("MM/DD/YY"),
      toolTipText: "Date",
    },
  ];

  return (
    <Box width={isMobileView ? "164px" : "210px"} px={2}>
      {tripDetailsList.map((tripDetail) => {
        return (
          <Tooltip title={tripDetail.toolTipText} placement="top-start">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Box width={2} pr={3}>
                {tripDetail.icon}
              </Box>
              <Typography
                variant="caption"
                noWrap
                sx={{
                  mb: 0.25,
                  color: tripDetail.textErrorColor || "inherit",
                }}
              >
                {tripDetail.text}
              </Typography>
              {tripDetail.additionalIcon && (
                <Box ml={0.5}>{tripDetail.additionalIcon}</Box>
              )}
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );
}

export { DispatchTripDetails };
