import React from "react";
import { Box } from "@mui/material";

import { useUpdateBookingContactClick } from "./hooks/useUpdateBookingContactClick";
import EditBookingContactDialog from "./components/EditBookingContactDialog";
import AddContactToOrderDialog from "components/contacts/people/AddContactToOrderDialog";
import { Contact, RequestStage } from "types";
import BookingContactBlock from "./components/BookingContactBlock";
import { useBookingContactMutation } from "./hooks/useBookingContactMutation";
import { useAnalytics } from "globals/hooks";

type BookingContactProps = {
  requestId: string;
  requestStage: RequestStage;
  bookingContact: {
    name: string;
    email: string;
    phoneNumber: string;
  };
  canEditBookingContact?: boolean;
  companyId?: string;
};

const BookingContact = (props: BookingContactProps) => {
  const {
    requestId,
    requestStage,
    canEditBookingContact,
    companyId,
    bookingContact: currentBookingContact,
  } = props;

  // hooks
  const {
    editBookingContactDialogOpen,
    setEditBookingContactDialogOpen,
    addContactToOrderDialogOpen,
    setAddContactToOrderDialogOpen,
    carryOverSearch,
    setCarryOverSearch,
    bookingContact,
    setBookingContact: setNewBookingContact,
  } = useUpdateBookingContactClick();

  const { track } = useAnalytics();

  // mutations
  const { updateRequest } = useBookingContactMutation();

  // event handlers
  const handleAddNewBookingContact = (contact: Contact) => {
    setNewBookingContact(contact);
    setEditBookingContactDialogOpen(false);
    updateRequest({
      variables: {
        input: {
          requestId,
          bookingContactId: contact.id,
        },
      },
    });
  };

  const onClose = () => {
    setEditBookingContactDialogOpen(false);
  };

  const handleSaveBookingContact = async () => {
    if (!bookingContact) return;
    updateRequest({
      variables: {
        input: {
          requestId,
          bookingContactId: bookingContact.id,
        },
      },
    });

    track(
      requestStage === RequestStage.Reservation
        ? "reservation_contactReplaced"
        : "quote_contactReplaced"
    );
    onClose();
  };

  return (
    <Box>
      <BookingContactBlock
        bookingContact={currentBookingContact}
        onBookingContactUpdate={setEditBookingContactDialogOpen}
        canEditBookingContact={canEditBookingContact}
      />
      <EditBookingContactDialog
        title="Replace Booking Contact"
        open={editBookingContactDialogOpen}
        onClose={onClose}
        setAddContactToOrderDialogOpen={setAddContactToOrderDialogOpen}
        bookingContact={bookingContact}
        setBookingContact={setNewBookingContact}
        setCarryOverSearch={setCarryOverSearch}
        onSave={handleSaveBookingContact}
        companyId={companyId}
      />
      <AddContactToOrderDialog
        open={addContactToOrderDialogOpen}
        onClose={() => setAddContactToOrderDialogOpen(false)}
        onContactUpdate={handleAddNewBookingContact}
        carryOverSearch={carryOverSearch}
        companyId={companyId}
      />
    </Box>
  );
};

export default BookingContact;
