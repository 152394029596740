import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, CircularProgress, Paper } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@apollo/client";

import {
  ENABLE_POCKETFLOWS_CRM_MUTATION,
  GET_POCKETFLOWS_EMBED_ID_MUTATION,
} from "globals/graphql";
import { useOperatorWithLoading } from "globals/hooks";
import { white, alabaster } from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import EntityHeader from "components/common/EntityHeader";
import MoovsTabs from "components/globals/MoovsTabs";
import { useSnackbar } from "globals/hooks";

export enum CrmPageTab {
  Campaigns = "campaigns",
  EmailTemplates = "email_templates",
  Segments = "segments",
}

function InitializePocketFlows() {
  const [isLoading, setIsLoading] = useState(false);
  const [enablePocketflowsCRM] = useMutation(ENABLE_POCKETFLOWS_CRM_MUTATION, {
    onCompleted(data) {
      setIsLoading(false);
    },
    onError(error) {
      console.error("error", error);
      setIsLoading(false);
    },
  });

  const initializePocketFlows = () => {
    setIsLoading(true);
    enablePocketflowsCRM({
      variables: {
        input: {},
      },
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} mt={6}>
      <LoadingButton
        color="primary"
        variant="contained"
        onClick={initializePocketFlows}
        disabled={isLoading}
        loading={isLoading}
      >
        Initialize CRM
      </LoadingButton>
    </Box>
  );
}

function Loading() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      mt={2}
    >
      <CircularProgress />
    </Box>
  );
}

function PocketflowsEmbed({
  embedId,
  tab,
}: {
  embedId: string;
  tab: CrmPageTab;
}) {
  const { isMobileView } = useScreenSize();

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      width={isMobileView ? "100%" : "70%"}
      height="100%"
      bgcolor={white}
      mt={isMobileView ? 0 : 2}
    >
      <iframe
        title="PocketFlows CRM"
        src={`https://moovs.api.pocketflows.com/moovs_embeds/${embedId}/show/${tab}`}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
      />
    </Box>
  );
}

function CrmPage() {
  const snackbar = useSnackbar();
  const { operator, loading } = useOperatorWithLoading();
  const { isMobileView } = useScreenSize();

  const [tabMode, setTabMode] = useState<CrmPageTab>(CrmPageTab.Segments);
  const [pocketflowsEmbedId, setPocketflowsEmbedId] = useState<string | null>(
    null
  );

  const tabs = [
    { label: "Segments", value: CrmPageTab.Segments },
    { label: "Email Templates", value: CrmPageTab.EmailTemplates },
    { label: "Campaigns", value: CrmPageTab.Campaigns },
  ];

  const handleTabChange = (tab: CrmPageTab) => {
    setTabMode(tab);
  };

  const content =
    loading || (operator?.pocketflowsBusinessId && !pocketflowsEmbedId) ? (
      <Loading />
    ) : operator?.pocketflowsBusinessId ? (
      <PocketflowsEmbed embedId={pocketflowsEmbedId} tab={tabMode} />
    ) : (
      <InitializePocketFlows />
    );

  const [getPocketflowsEmbedId] = useMutation(
    GET_POCKETFLOWS_EMBED_ID_MUTATION,
    {
      onCompleted(data) {
        setPocketflowsEmbedId(data.getPocketflowsEmbedId.embedId);
      },
      onError(error) {
        console.error("error", error);
        snackbar.error("Error loading CRM. Please refresh the page.");
      },
    }
  );

  useEffect(() => {
    if (operator?.pocketflowsBusinessId) {
      getPocketflowsEmbedId({
        variables: {
          input: {},
        },
      });
    }
  }, [getPocketflowsEmbedId, operator?.pocketflowsBusinessId]);

  return (
    <>
      <Helmet>
        <title>CRM | Moovs</title>
      </Helmet>

      <Box justifyContent="center" width="100%">
        <Box
          position="sticky"
          top={isMobileView ? "56px" : "78px"}
          zIndex={10}
          width="100%"
        >
          <Paper
            square
            style={{ borderBottom: `1px solid ${white}` }}
            elevation={0}
          >
            <Box data-testid="entity-header" maxWidth="900px" margin="auto">
              <EntityHeader title="CRM" entityName="CRM" />
              <Box
                data-testid="status-tabs"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <MoovsTabs
                  value={tabMode}
                  tabs={tabs}
                  onChange={handleTabChange}
                  {...(isMobileView && {
                    variant: "fullWidth",
                    sx: { width: "100%" }
                  })}
                />
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          height="80%"
          bgcolor={alabaster}
        >
          {content}
        </Box>
      </Box>
    </>
  );
}

export default CrmPage;
