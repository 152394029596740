import React from "react";

import { Box, Button, Typography, Switch } from "@mui/material";

import { grayLight, moovsBlue } from "design-system/colors";
import { EditIcon } from "design-system/icons";

type EditActivateDesktopProps = {
  onEditClick: () => void;
  activationStatus: boolean;
  onHandleActivateSwitch: (e) => void;
};

function EditActivateDesktop(props: EditActivateDesktopProps) {
  const { onEditClick, activationStatus, onHandleActivateSwitch } = props;

  return (
    <Box display="flex" justifyContent="center" alignItems="center ">
      <Box
        display="flex"
        alignItems="center"
        pr={3}
        borderRight="1px solid"
        borderColor={grayLight}
        height="100%"
      >
        <Box>
          <Button onClick={onEditClick}>
            <EditIcon color={moovsBlue} />
            <Box ml={0.5} alignItems="center" display="flex">
              <Typography>Edit </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
      <Box pl={3} pr={0.5}>
        <Switch
          checked={activationStatus}
          color="primary"
          onChange={onHandleActivateSwitch}
        />
      </Box>
    </Box>
  );
}

export default EditActivateDesktop;
