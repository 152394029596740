import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/client";

import { Box, Typography, CircularProgress } from "@mui/material";

import { alabaster, black } from "../../design-system/colors";
import { LOAD_OPERATOR_QUERY, LOAD_ME_QUERY } from "../../globals/graphql";
import { Operator } from "../../types";
import theme from "theme";

function WebsiteAnalyticsPage() {
  // queries
  const { data: meData } = useQuery(LOAD_ME_QUERY);

  const operatorId = meData?.me?.operatorId;

  const { loading } = useQuery(LOAD_OPERATOR_QUERY, {
    variables: {
      id: operatorId,
    },
    fetchPolicy: "cache-and-network",
    skip: !operatorId,
  });

  // state
  const [operator] = useState<Partial<Operator> | undefined>();

  const styles = {
    text: {
      color: black,
    },
    fixedToTop: {
      top: 0,
      right: 0,
      left: 0,
      width: "100%",
      backgroundColor: alabaster,
      zIndex: 1,
    },
    container: {
      minHeight: "100%",
      flex: 1,
      maxWidth: "826px",
      margin: "auto",
      padding: 0,
      [theme.breakpoints.down("lg")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      [theme.breakpoints.down("sm")]: {
        overflowY: "auto",
      },
    },
    header: {
      minHeight: "78px",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Website Analytics | Moovs</title>
      </Helmet>
      {loading || !operator ? (
        <Box
          width="100%"
          height="100px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={40} thickness={4} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" sx={styles.container}>
          <Box position="sticky" sx={styles.fixedToTop}>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              sx={styles.header}
            >
              <Typography variant="h2" sx={styles.text}>
                Analytics
              </Typography>
            </Box>
            {/* Analytics here!! */}
          </Box>

          <Box px={3} mb={12}>
            {!meData && (
              <Box
                height="100%"
                justifyContent="center"
                display="flex"
                textAlign="center"
                flexDirection="column"
              >
                <Box mb={3}>
                  <Typography variant="h5" sx={styles.text}>
                    Error loading user data
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

export default WebsiteAnalyticsPage;
