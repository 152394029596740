import React from "react";

type EditIconProps = {
  color?: string;
  size?: "small";
};

const EditIcon = (props: EditIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0779 2.50136C11.2368 2.34241 11.4255 2.21632 11.6332 2.1303C11.8409 2.04428 12.0635 2 12.2883 2C12.513 2 12.7356 2.04428 12.9433 2.1303C13.151 2.21632 13.3397 2.34241 13.4986 2.50136C13.6576 2.66031 13.7837 2.84901 13.8697 3.05669C13.9557 3.26436 14 3.48695 14 3.71174C14 3.93653 13.9557 4.15912 13.8697 4.3668C13.7837 4.57447 13.6576 4.76317 13.4986 4.92212L5.32855 13.0922L2 14L2.90779 10.6714L11.0779 2.50136Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1038 4.66848C16.3158 4.45654 16.5674 4.28843 16.8443 4.17373C17.1212 4.05903 17.418 4 17.7177 4C18.0174 4 18.3142 4.05903 18.5911 4.17373C18.868 4.28843 19.1196 4.45654 19.3315 4.66848C19.5435 4.88041 19.7116 5.13201 19.8263 5.40891C19.941 5.68582 20 5.9826 20 6.28232C20 6.58204 19.941 6.87882 19.8263 7.15573C19.7116 7.43263 19.5435 7.68423 19.3315 7.89617L8.43807 18.7896L4 20L5.21038 15.5619L16.1038 4.66848Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
