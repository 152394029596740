import React from "react";

import { Box, Typography, MenuList, MenuItem, useTheme } from "@mui/material";

import AnchorPopper from "../../common/AnchorPopper";
import { ReopenIcon, UploadCloudIcon } from "../../../design-system/icons";
import { moovsBlue } from "../../../design-system/colors";

const styles = {
  selectedMenuItem: {
    fontWeight: 500,
    color: moovsBlue,
  },
  viewItem: {
    marginTop: "2px",
    marginBottom: "2px",
    textAlign: "center",
    width: "95%",
    ":hover": {
      borderRadius: "5px",
    },
  },
  attachMenuList: {
    minWidth: "191px",
    padding: "6px",
    display: "flex",
    flexDirection: "column",
  },
};

type AttachMediaPopupType = {
  attachMenuOpen: boolean;
  setAttachmentMenuOpen: (open: boolean) => void;
  onAttachFromFileSystem: (event) => void;
  setAddMoovsMediaDialogOpen: (open: boolean) => void;
};

const AttachMediaPopup = React.forwardRef(
  (props: AttachMediaPopupType, ref: any) => {
    const {
      attachMenuOpen,
      setAttachmentMenuOpen,
      onAttachFromFileSystem,
      setAddMoovsMediaDialogOpen,
    } = props;

    // hooks
    const theme = useTheme();

    return (
      <AnchorPopper
        open={attachMenuOpen}
        anchorRef={ref.current}
        onClickAway={() => setAttachmentMenuOpen(false)}
        placement="bottom"
        zIndex={theme.zIndex.drawer + 3} // higher zIndex than ChatPanel
      >
        <Box>
          <MenuList
            autoFocusItem={attachMenuOpen}
            sx={{
              display: "flex",
              minWidth: "191px",
              padding: "6px",
              flexDirection: "column",
            }}
          >
            <Box mb={2.5} ml={1}>
              <Typography variant="overline" component="div">
                Attach From...
              </Typography>
            </Box>
            {[
              {
                label: "My Device",
                onMenuItemClick: () => {},
                icon: <UploadCloudIcon size="small" />,
                isFileInput: true,
              },
              {
                label: "Moovs",
                onMenuItemClick: () => {
                  setAddMoovsMediaDialogOpen(true);
                  setAttachmentMenuOpen(false);
                },
                icon: <ReopenIcon size="small" />,
              },
            ].map(({ icon, label, onMenuItemClick, isFileInput }, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={onMenuItemClick}
                  sx={[
                    styles.viewItem,
                    { "& .Mui-selected": styles.selectedMenuItem },
                  ]}
                >
                  {isFileInput ? (
                    <>
                      <input
                        accept=".pdf,.png,.jpg,.jpeg,.mp4"
                        id="twilio-attachment-button"
                        type="file"
                        multiple
                        style={{ display: "none" }}
                        onChange={onAttachFromFileSystem}
                      />
                      <label htmlFor="twilio-attachment-button">
                        <Box display="flex">
                          <Box mr={1} position="relative" top="2px">
                            {icon}
                          </Box>
                          <Box
                            display="flex"
                            width="100%"
                            justifyContent="space-between"
                          >
                            {label}
                          </Box>
                        </Box>
                      </label>
                    </>
                  ) : (
                    <>
                      <Box mr={1} position="relative" top="2px">
                        {icon}
                      </Box>
                      <Box
                        display="flex"
                        width="100%"
                        justifyContent="space-between"
                      >
                        {label}
                      </Box>
                    </>
                  )}
                </MenuItem>
              );
            })}
          </MenuList>
        </Box>
      </AnchorPopper>
    );
  }
);

export default AttachMediaPopup;
