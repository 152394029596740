import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import { Box, ToggleButtonGroup, Typography } from "@mui/material";

import WidgetGetQuote from "design-system/images/WidgetGetQuote.svg";
import WidgetBookNow from "design-system/images/WidgetBookNow.svg";
import WebsiteBuilderLogos from "design-system/images/WebsiteBuilderLogos.svg";
import CustomerPortalWidget from "design-system/images/CustomerPortalWidget.gif";
import { grayLight, grayMedium } from "design-system/colors";
import { useOperator, useScreenSize, useSnackbar } from "globals/hooks";
import { UPDATE_OPERATOR_SETTINGS } from "globals/graphql";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { WidgetCta } from "types";
import { WidgetDesignButton } from "./components/WidgetDesignButton";

type WidgetDesignSectionProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function WidgetDesignSection(props: WidgetDesignSectionProps) {
  const { setSaveIndicatorState } = props;

  // state
  const [pendingNewValue, setPendingNewValue] = useState<WidgetCta>(null);

  // hooks
  const { isMediumTabletView } = useScreenSize();
  const operator = useOperator();
  const snackbar = useSnackbar();

  // mutations
  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
      setPendingNewValue(null);
    },
    onError(error) {
      setSaveIndicatorState("error");
      setPendingNewValue(null);

      const errorMessage =
        getErrorMessage(error) || "Error updating widget design.";

      snackbar.error(errorMessage);
    },
  });

  const handleOnClick = (_e: unknown, value: WidgetCta) => {
    setSaveIndicatorState("loading");
    setPendingNewValue(value);
    updateOperatorSettings({ variables: { input: { widgetCta: value } } });
  };

  const widgetCta = operator?.settings?.widgetCta;

  const renderWidgetSelector = () => (
    <Box mt={3} mr={2}>
      <Box my={3}>
        <Typography variant="h3">Choose widget design</Typography>
      </Box>
      <Box display="flex" justifyContent="flex-start">
        <ToggleButtonGroup onChange={handleOnClick} exclusive>
          <WidgetDesignButton
            value={WidgetCta.BookNow}
            isSelected={WidgetCta.BookNow === widgetCta}
            isDisabled={!!pendingNewValue || WidgetCta.BookNow === widgetCta}
            buttonImg={WidgetBookNow}
            isUpdating={pendingNewValue === WidgetCta.BookNow}
          />
          <Box width={10} />
          <WidgetDesignButton
            value={WidgetCta.GetAQuote}
            isSelected={WidgetCta.GetAQuote === widgetCta}
            isDisabled={!!pendingNewValue || WidgetCta.GetAQuote === widgetCta}
            buttonImg={WidgetGetQuote}
            isUpdating={pendingNewValue === WidgetCta.GetAQuote}
          />
        </ToggleButtonGroup>
      </Box>
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection={isMediumTabletView ? "column" : "row"}
      justifyContent="space-between"
      minHeight="460px"
    >
      <Box flex={0.5}>
        <Box mt={3}>
          <Typography variant="h2">Customer Portal Widget</Typography>
        </Box>
        <Box mt={2} pr="25%">
          <Typography variant="body2">
            Adding the widget for your customers ensures they can easily and
            quickly book trips directly from your website
          </Typography>
        </Box>
        <Box mt={2} pr="10%">
          <Typography variant="overline">
            Compatible with all major website builders
          </Typography>
        </Box>
        <Box paddingY={2}>
          <img
            src={WebsiteBuilderLogos}
            alt="website builder providers logos"
            style={{
              maxHeight: "100px",
            }}
          />
        </Box>
        {!isMediumTabletView && renderWidgetSelector()}
      </Box>
      <Box
        flex={0.5}
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={isMediumTabletView ? 4 : 0}
        flexDirection="column"
        bgcolor={grayLight}
        borderRadius={2}
      >
        <Box paddingX={4} paddingY={2}>
          <img
            src={CustomerPortalWidget}
            alt="customer portal"
            width="100%"
            style={{
              maxHeight: "300px",
              borderRadius: "4px",
              border: `1px solid ${grayMedium}`,
              boxShadow:
                "0px 24px 24px -4px rgba(30, 30, 30, 0.02),  0px 8px 10px -2.5px rgba(30, 30, 30, 0.02)",
            }}
          />
        </Box>
      </Box>
      {isMediumTabletView && renderWidgetSelector()}
    </Box>
  );
}

export default WidgetDesignSection;
