import React from "react";

import { Box, Divider, MenuItem, Typography } from "@mui/material";

import { currency } from "globals/utils/helpers";
import { grayDark } from "design-system/colors";
import { useScreenSize } from "globals/hooks";

type BRALineItemProps = {
  name: string;
  description: string;
  lineTotal: number;
};

function BRALineItem(props: BRALineItemProps) {
  const { name, description, lineTotal } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <MenuItem
      sx={{
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&:hover:not(.Mui-disabled)": {
          cursor: "default",
        },
        minWidth: 327,
        py: 1,
        minHeight: 0,
        whiteSpace: "normal",
      }}
    >
      <Box display="flex" flex="1" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flex="1" flexDirection="column">
            <Typography
              variant={"body2"}
              style={{
                padding: 4,
                wordWrap: "break-word",
                width: isMobileView ? "118px" : "143px",
              }}
            >
              {name}
            </Typography>
          </Box>
          <Box display="flex" style={{ padding: "4px 8px" }}>
            <Typography
              variant="body2"
              style={{
                color: grayDark,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {description}
            </Typography>
            <Typography
              variant={"subtitle2"}
              style={{
                letterSpacing: "0.7px",
                marginRight: "-12px",
                padding: "2px 4px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {currency(lineTotal)}
            </Typography>
          </Box>
        </Box>
        <Divider
          style={{ width: "100%", alignContent: "center", marginTop: 8 }}
        />
      </Box>
    </MenuItem>
  );
}

export default BRALineItem;
