import React from "react";
import { useQuery } from "@apollo/client";

import { Box, Typography, IconButton } from "@mui/material";

import { OpenIcon } from "design-system/icons";
import { LOAD_REQUEST_QUERY } from "globals/graphql";
import { confirmationNumberFromRequest } from "globals/utils/helpers";
import { Trip } from "types";
import { moovsBlue } from "design-system/colors";

type TripInfoProps = {
  trip: Trip;
  screenVariant?: "mobile";
};

function TripInfo(props: TripInfoProps) {
  const { trip, screenVariant } = props;

  // queries
  const { data: requestData } = useQuery(LOAD_REQUEST_QUERY, {
    variables: {
      id: trip.requestId,
    },
  });

  const request = requestData?.node;
  const confirmationNumber = confirmationNumberFromRequest(request, trip);

  // event handlers
  const handleOpenTripClick = () => {
    const win = window.open(
      `/reservations/${trip.requestId}/update/${trip.id}`,
      "_blank"
    );
    win.focus();
  };

  return (
    <Box display="flex" flexDirection="row">
      <Typography variant="subtitle2" color={moovsBlue}>
        {confirmationNumber}
      </Typography>
      {screenVariant !== "mobile" && (
        <IconButton
          onClick={handleOpenTripClick}
          style={{ padding: "0px 0px 0px 9px" }}
          size="small"
        >
          <OpenIcon size="small" color={moovsBlue} />
        </IconButton>
      )}
    </Box>
  );
}

export default TripInfo;
