import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import isNull from "lodash/isNull";

import { Box, Typography } from "@mui/material";

import { useScreenSize } from "globals/hooks";
import {
  DriverPayoutToggleVariant,
  FormVariant,
  InputFormatVariant,
} from "../utils/types";
import DriverPayoutField from "./DriverPayoutField";
import DriverPayoutRateToggle from "./DriverPayoutRateToggle";
import { grayDark } from "design-system/colors";
import DriverPayoutLocationToggle from "./DriverPayoutLocationToggle";
import { UpdateDriverInput, TripCategory, CreateDriverInput } from "types";

type HourlyTripProps = {
  driver: CreateDriverInput | UpdateDriverInput;
  setDriver: Dispatch<SetStateAction<CreateDriverInput | UpdateDriverInput>>;
  variant?: FormVariant;
};

function HourlyTrip(props: HourlyTripProps) {
  const { driver, setDriver, variant } = props;

  // derived state
  const selectedRateToggle = !isNull(driver.settings?.hourlyTripHourlyRate)
    ? DriverPayoutToggleVariant.HOURLY_RATE
    : !isNull(driver.settings?.hourlyTripBaseRatePct)
    ? DriverPayoutToggleVariant.BASE_RATE_PCT
    : null;

  // hook
  const { isSmallTabletView } = useScreenSize();

  // state
  const [toggleVariant, setToggleVariant] = useState<DriverPayoutToggleVariant>(
    selectedRateToggle || DriverPayoutToggleVariant.HOURLY_RATE
  );

  // event handlers
  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    setDriver({
      ...driver,
      settings: {
        ...driver.settings,
        [name]: Number(value) || null,
      },
    });
  };

  return (
    <>
      <Box
        mb={0.5}
        display="flex"
        justifyContent="space-between"
        flexDirection={isSmallTabletView ? "column" : "row"}
        alignItems={isSmallTabletView ? "flext-start" : "center"}
      >
        {/* Rate Toggle Buttons */}
        <Box mr={1} mb={isSmallTabletView ? 2.5 : 0}>
          <DriverPayoutRateToggle
            toggleVariant={toggleVariant}
            onSetToggleVariant={setToggleVariant}
            tripType={TripCategory.Hourly}
            setDriver={setDriver}
            driver={driver}
          />
        </Box>

        {/* Hourly Rate Input */}
        {toggleVariant === DriverPayoutToggleVariant.HOURLY_RATE && (
          <DriverPayoutField
            placeholder="Hourly Rate"
            fieldName="hourlyTripHourlyRate"
            value={driver.settings.hourlyTripHourlyRate}
            onHandleFieldChange={handleFieldChange}
            format={InputFormatVariant.DOLLAR}
            variant={variant}
          />
        )}

        {/* Base Rate % Input */}
        {toggleVariant === DriverPayoutToggleVariant.BASE_RATE_PCT && (
          <>
            <DriverPayoutField
              placeholder="Percentage of Base Rate"
              fieldName="hourlyTripBaseRatePct"
              value={driver.settings.hourlyTripBaseRatePct}
              onHandleFieldChange={handleFieldChange}
              format={InputFormatVariant.PERCENT}
              variant={variant}
            />
          </>
        )}
      </Box>

      {/* Input Caption */}
      {toggleVariant === DriverPayoutToggleVariant.BASE_RATE_PCT && (
        <Typography color={grayDark} variant="caption">
          Calculates percentage of the trip's base rate amount
        </Typography>
      )}

      {/* Location Variant */}
      {toggleVariant === DriverPayoutToggleVariant.HOURLY_RATE && (
        <DriverPayoutLocationToggle
          driver={driver}
          fieldName="hourlyTripLocationsVariant"
          tripLocationsVariant={driver.settings.hourlyTripLocationsVariant}
          setDriver={setDriver}
        />
      )}
    </>
  );
}

export default HourlyTrip;
