import * as yup from "yup";

export const timesOfDaySchema = yup.object({
  startTime: yup
    .date()
    .required("Please select start time")
    .typeError("Please select start time"),
  endTime: yup
    .date()
    .required("Please select end time")
    .typeError("Please select end time")
    .test({
      name: "Validate end time occurs after start time",
      message: "Please select an end time that occurs after start time",
      test: function () {
        const { startTime, endTime } = this.parent;

        return startTime < endTime;
      },
    }),
  daysOfWeek: yup
    .array()
    .of(
      yup.object({
        id: yup.string(),
        value: yup.string(),
        isSelected: yup.boolean(),
      })
    )
    .min(1, "Please select at least one day of week")
    .test(
      "Check if at least one weekday is selected",
      "Please select at least one day of week",
      (schema) => schema.some((item) => item.isSelected)
    )
    .required("Please select at least one day of week"),
});
