import React from "react";

import { Box, Divider } from "@mui/material";

function EllipsisDivider() {
  return (
    <Box mx="6px" my={1} data-testid="ellipsis-option-divider">
      <Divider />
    </Box>
  );
}

export default EllipsisDivider;
