import React, { ChangeEvent, useState } from "react";
import { useMutation } from "@apollo/client";

import { Box, Button, TextField } from "@mui/material";

import MoovsDialog from "../../../../../components/MoovsDialog";
import FarmAffiliateVehiclesDialog from "../../../../../components/reservations/FarmAffiliateVehiclesDialog";
import { FarmAffiliateVehicle } from "../../../../../types";
import { useAnalytics, useSnackbar } from "../../../../../globals/hooks";
import { CREATE_FARMED_ROUTE_MUTATION } from "../../../../../globals/graphql";
import { NumberFormatDollar } from "../../../../../design-system/components/inputs/NumberFormat";
import FarmAffiliateVehicleCard from "../../../../../components/vehicles/VehicleCard/FarmAffiliateVehicleCard";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

// this should match the input for the schema
const initialFarmedRoute = {
  vehicle: null,
  farmoutNote: "",
  baseRateAmt: null,
};

type CreateFarmedRouteDialogProps = {
  open: boolean;
  onClose: () => void;
  routeId: string;
  tripId: string;
};

function CreateFarmedRouteDialog(props: CreateFarmedRouteDialogProps) {
  const { open, onClose, routeId, tripId } = props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // state
  const [farmedRoute, setFarmedRoute] = useState(initialFarmedRoute); // add FarmOutInputType
  const [vehiclesDialogOpen, setVehiclesDialogOpen] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const farmVehicle: FarmAffiliateVehicle = farmedRoute.vehicle;

  // mutations
  const [createFarmedRoute] = useMutation(CREATE_FARMED_ROUTE_MUTATION, {
    refetchQueries: ["Requests"],
    onCompleted() {
      snackbar.success(`Farm-Out Request Sent`);
      onClose();
    },
    onError(error) {
      setSubmitDisabled(false);
      const errorMessage = getErrorMessage(error) || "Error farming out trip.";

      snackbar.error(errorMessage);
    },
  });

  // event handlers
  const handleVehicleDialogOpen = () => {
    setVehiclesDialogOpen(true);
  };

  const handleVehicleDialogClose = () => {
    setVehiclesDialogOpen(false);
  };

  const handleVehicleSelect = (vehicle: FarmAffiliateVehicle[]) => {
    setFarmedRoute({ ...farmedRoute, vehicle: vehicle[0] });
    handleVehicleDialogClose();
  };

  const handleCreateFarmedRoute = () => {
    setSubmitDisabled(true);

    track("farmedRoute_farmorSent");

    createFarmedRoute({
      variables: {
        input: {
          vehicleId: farmedRoute.vehicle.id,
          farmoutNote: farmedRoute.farmoutNote,
          routeId,
          baseRateAmt: farmedRoute.baseRateAmt,
        },
      },
    });
    handleVehicleDialogClose();
  };

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value: string | number = event.target.value;
    let name = event.target.name;

    if (name === "baseRateAmt") {
      value = parseFloat(value);
    }

    setFarmedRoute({ ...farmedRoute, [name]: value });
  };

  return (
    <>
      <MoovsDialog
        open={open}
        onClose={onClose}
        size="sm"
        onAccept={handleCreateFarmedRoute}
        dialogTitle="Farm-Out Trip"
        acceptButtonText="Farm-Out"
        closeButtonText="Cancel"
        acceptDisabled={!farmVehicle || submitDisabled}
        hideLoadingIndicator={!submitDisabled && !farmVehicle}
      >
        <Box display="flex" flexDirection="column" my={3}>
          <Box mb={2}>
            {!!farmVehicle ? (
              <FarmAffiliateVehicleCard
                vehicle={farmVehicle}
                ellipsisMenuActions={{
                  onPressReplace: handleVehicleDialogOpen,
                }}
              />
            ) : (
              <Button
                size="large"
                fullWidth
                variant="outlined"
                color="primary"
                onClick={handleVehicleDialogOpen}
                sx={{ paddingTop: "12px", paddingBottom: "12px" }}
              >
                Choose a Vehicle
              </Button>
            )}
          </Box>
          <Box mb={2}>
            <TextField
              variant="outlined"
              fullWidth
              label="Affiliate Note"
              name="farmoutNote"
              onChange={handleFieldChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              variant="outlined"
              fullWidth
              label="Payout Rate"
              name="baseRateAmt"
              onChange={handleFieldChange}
              inputProps={{ fixedDecimalScale: true }}
              InputProps={{
                inputComponent: NumberFormatDollar as any,
              }}
            />
          </Box>
        </Box>
      </MoovsDialog>
      {vehiclesDialogOpen && (
        <FarmAffiliateVehiclesDialog
          open={vehiclesDialogOpen}
          title="Add Vehicle"
          acceptButtonText="Add Vehicle"
          onClose={handleVehicleDialogClose}
          onAcceptClick={handleVehicleSelect}
          tripId={tripId}
        />
      )}
    </>
  );
}

export default CreateFarmedRouteDialog;
