import React from "react";

type ReservationIconProps = {
  color?: string;
};

const ReservationIcon = (props: ReservationIconProps) => {
  const { color } = props;

  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 7.63158L15 1L8.36842 15L6.89474 9.10526L1 7.63158Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <path
          transform="translate(11, 11)"
          d="M4.59489 5.95303C4.78922 6.21311 5.03717 6.42829 5.32192 6.58403C5.60666 6.73978 5.92155 6.83237 6.24517 6.85557C6.56884 6.87876 6.89368 6.83201 7.19766 6.71849C7.50169 6.60501 7.77774 6.42734 8.00714 6.19766L9.3648 4.83863C9.77699 4.41141 10.005 3.83926 9.99992 3.24537C9.99476 2.65148 9.75676 2.08337 9.33724 1.66342C8.91768 1.24346 8.35018 1.00525 7.75688 1.00009C7.16358 0.994921 6.59201 1.22323 6.16525 1.63583L5.38686 2.41048M6.4051 5.04701C6.21073 4.78689 5.96278 4.5717 5.67803 4.41598C5.39328 4.26025 5.07844 4.16765 4.75478 4.14445C4.43115 4.12124 4.10631 4.16799 3.80231 4.28151C3.4983 4.39502 3.22224 4.57266 2.99285 4.80239L1.63519 6.16142C1.223 6.58861 0.994927 7.16076 1.00009 7.75466C1.00524 8.34855 1.24321 8.91663 1.66275 9.33662C2.08228 9.75656 2.64981 9.99475 3.2431 9.99991C3.83639 10.0051 4.40797 9.77681 4.83474 9.3642L5.60861 8.58956"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default ReservationIcon;
