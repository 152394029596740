import { useForm } from "react-hook-form";

import { DispatchTripFilterFields } from "../types";

export const INITIAL_VALUES = {
  tripStatusSelection: [],
  companySelection: [],
  vehicleSelection: [],
  driverSelection: [],
  affiliateSelection: [],
  paidStatus: null,
  openClosedTrip: null,
  driverAssigned: null,
};

function useDispatchFilterForm() {
  // FormProvider requires all useForm methods
  const methods = useForm<DispatchTripFilterFields>({
    defaultValues: INITIAL_VALUES,
  });

  return { methods };
}

export default useDispatchFilterForm;
