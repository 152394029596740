/**
 * @file useChat/Provider.tsx
 * ContextProvider for Chat
 */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import orderBy from "lodash/orderBy";
import queryString from "query-string";
import * as Sentry from "@sentry/react";
import filter from "lodash/filter";
import size from "lodash/size";
import { Conversation, Message, Participant } from "@twilio/conversations";

import ChatContext from "./Context";
import { useAuth, useCurrentUser, useSnackbar } from "..";
import { useConversationsClient } from "components/chat/hooks/useConversationsClient";
import { useParticipant } from "components/chat/hooks/useParticipant";
import { formatPhoneNumber } from "globals/utils/phoneNumberFormatter/phoneNumberFormatter";
import { getAffiliateId } from "components/chat/utils";

// participant object (of the non-Operator Participant)
export type ParticipantType = {
  proxy: Participant;
  role?: "driver" | "contact";
  name?: string;
  mobilePhone?: string;
  phoneCountryCode?: string;
};

// conversation object that is passed down to child components
export type ConversationType = {
  proxy: Conversation; // conversation proxy to use with twilio sdk
  lastUpdatedAt: any; // keeps conversations sorted
  messages: any[]; // array of message proxys to use with twilio sdk
  unreadMessagesCount: number; // number of unread messages
  setUnreadMessagesCount: (newCount: number) => void; // updates unread messages count
  participant?: ParticipantType;
};

function ChatProvider({ children }) {
  // state
  const [conversations, setConversations] = useState(
    new Map<string, ConversationType>()
  );

  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const { authStage } = useAuth();
  const { conversationsClient, onRefetchToken } = useConversationsClient();
  useParticipant({ conversations, setConversations });

  // queries
  const { operatorId } = useCurrentUser() || {};
  const affiliateId = getAffiliateId(operatorId);

  // event handlers
  const handleConversationLeft = useCallback(
    (conversationProxy: Conversation) => {
      setConversations((conversations) => {
        conversations.delete(conversationProxy.sid);
        return new Map(conversations);
      });
    },
    []
  );

  const handleConversationJoined = useCallback(
    async (conversationProxy: Conversation) => {
      if (conversationProxy.state.current !== "closed") {
        // constuct conversation type
        const conversation: ConversationType = {
          proxy: conversationProxy,
          messages: [],
          unreadMessagesCount: 0,
          // if newly created convo, first convo wont have a date added
          lastUpdatedAt:
            conversationProxy?.lastMessage?.dateCreated || new Date(),
          setUnreadMessagesCount: (newCount: number) => {
            setConversations((conversations) => {
              const currentConversation = conversations.get(
                conversationProxy.sid
              );

              if (currentConversation) {
                currentConversation.unreadMessagesCount = newCount;
              }

              conversations.set(conversationProxy.sid, currentConversation);
              return new Map(conversations);
            });
          },
          participant: null,
        };

        // load messages
        // TODO: handle pagination
        const messagePaginator = await conversationProxy.getMessages();
        const messages = messagePaginator.items;

        conversation.messages = messages;

        try {
          conversation.unreadMessagesCount =
            await conversationProxy.getUnreadMessagesCount();
        } catch (error) {
          console.error(error);
        }

        // start tracking message read count in twilio
        if (conversation.unreadMessagesCount === null) {
          await conversationProxy.setAllMessagesUnread();

          // filter out messages that are from the author
          // all remaining messages are unread
          conversation.unreadMessagesCount = messages.filter(
            ({ author }) => author !== affiliateId
          ).length;
        }

        // get nonMoovsUser participant
        const participants = await conversationProxy.getParticipants();

        const participantProxy = participants.find(
          (participant) => participant.type === "sms"
        );

        const attributes = participantProxy?.attributes as any;

        const formattedMobilePhone = formatPhoneNumber(
          attributes?.address || ""
        ).formatted;

        // set participant with number info.
        // actual name will be fetched from server
        // once all conversations are joined.
        conversation.participant = {
          mobilePhone: formattedMobilePhone,
          proxy: participantProxy,
        };

        // calculate unread message count for author
        const lastReadMessageIndex = conversationProxy.lastReadMessageIndex;
        conversation.unreadMessagesCount = messages.filter((message) => {
          return (
            message.author !== affiliateId &&
            message.index > lastReadMessageIndex
          );
        }).length;

        setConversations((conversations) => {
          conversations.set(conversationProxy.sid, conversation);
          return new Map(conversations);
        });
      }
    },
    [affiliateId]
  );

  const handleMessageAdded = useCallback(
    async (messageProxy: Message) => {
      const conversationSid = messageProxy.conversation.sid;

      const conversation = conversations.get(conversationSid);

      // if conversation not created by time of this event,
      // create conversation (which will fetch all messages then)
      if (!conversation) {
        const conversationProxy =
          await conversationsClient.getConversationBySid(conversationSid);
        handleConversationJoined(conversationProxy);

        return;
      }

      // add message to conversation
      conversation.messages = [...conversation.messages, messageProxy];
      conversation.lastUpdatedAt = new Date();

      const { conversationId: selectedConversationSid } = queryString.parse(
        history.location.search
      );

      // update unreadMessagesCount and show info snackbar
      if (
        // message is not by author
        // conversation is not selected conversation
        messageProxy.author !== affiliateId &&
        selectedConversationSid !== conversationSid
      ) {
        conversation.unreadMessagesCount += 1;

        const { name, mobilePhone } = conversation.participant;

        snackbar.info(`Message Received from ${name || mobilePhone}`, {
          verticalPosition: "top",
        });
      }

      setConversations((conversations) => {
        conversations.set(conversation.proxy.sid, conversation);
        return new Map(conversations);
      });
    },
    [
      affiliateId,
      conversations,
      conversationsClient,
      handleConversationJoined,
      history.location.search,
      snackbar,
    ]
  );

  const handleParticipantAdded = useCallback(
    async (participantProxy: Participant) => {
      // we only care about the sms participant, other participant is operator
      if (participantProxy.type !== "sms") return;

      const conversation = conversations.get(participantProxy.conversation.sid);

      // if no conversation yet, this step will be handled when onConversationAdded occurs.
      // issues there is a race condition here.
      if (!conversation) return;

      const attributes = participantProxy?.attributes as any;

      const formattedMobilePhone = formatPhoneNumber(
        attributes?.address || ""
      ).formatted;

      // set participant with number info.
      // actual name will be fetched from server
      // once all conversations are joined.
      conversation.participant = {
        mobilePhone: formattedMobilePhone,
        proxy: participantProxy,
      };

      setConversations((conversations) => {
        conversations.set(participantProxy.conversation.sid, conversation);
        return new Map(conversations);
      });
    },
    [conversations]
  );

  // effects
  // initialize event listeners
  useEffect(() => {
    const initConversations = async () => {
      try {
        // conversations
        conversationsClient.on("conversationJoined", handleConversationJoined);
        conversationsClient.on("conversationLeft", handleConversationLeft);

        // messages
        conversationsClient.on("messageAdded", handleMessageAdded);

        // participants
        conversationsClient.on("participantJoined", handleParticipantAdded);

        // token
        conversationsClient.on("tokenAboutToExpire", onRefetchToken);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    if (conversationsClient) {
      initConversations();
    }

    return () => {
      if (conversationsClient) conversationsClient.removeAllListeners();
    };
  }, [
    conversationsClient,
    handleConversationJoined,
    handleConversationLeft,
    handleMessageAdded,
    handleParticipantAdded,
    onRefetchToken,
  ]);

  // clear conversations list on logout
  // shutdown conversations client when logged out
  useEffect(() => {
    if (authStage === "rejected" && conversations.size) {
      setConversations(new Map<string, ConversationType>());
    }
  }, [authStage, conversations.size]);

  const totalUnreadMessagesCount = useMemo(
    () =>
      [...conversations.values()].reduce((count, { unreadMessagesCount }) => {
        return count + unreadMessagesCount;
      }, 0),
    [conversations]
  );

  // sort so conversation so newest message shows at top
  const sortedConversations = useMemo(() => {
    return orderBy(
      filter(
        [...conversations.values()],
        ({ messages, participant }) =>
          !!size(messages) && !!participant.mobilePhone
      ),
      ({ lastUpdatedAt }) => lastUpdatedAt,
      ["desc"]
    );
  }, [conversations]);

  return (
    <ChatContext.Provider
      value={{
        conversationsClient,
        onRefetchToken,
        unreadMessagesCount: totalUnreadMessagesCount,
        conversations: sortedConversations,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

export default ChatProvider;
