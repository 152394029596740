import React from "react";

type VehicleTypeSedanIconProps = {
  color?: string;
};

const VehicleTypeSedanIcon = (props: VehicleTypeSedanIconProps) => {
  const { color } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 15H22V11.1923C22 10.9169 21.8946 10.6528 21.7071 10.458C21.5196 10.2633 21.2652 10.1538 21 10.1538H3C2.73478 10.1538 2.48043 10.2633 2.29289 10.458C2.10536 10.6528 2 10.9169 2 11.1923V15H4M20 15C20 16.1046 19.1046 17 18 17C16.8954 17 16 16.1046 16 15M20 15C20 13.8954 19.1046 13 18 13C16.8954 13 16 13.8954 16 15M16 15H8M4 15C4 16.1046 4.89543 17 6 17C7.10457 17 8 16.1046 8 15M4 15C4 13.8954 4.89543 13 6 13C7.10457 13 8 13.8954 8 15M17.3333 10.1538L15.5 7.69231C15.4011 7.49312 15.2537 7.32436 15.0723 7.20287C14.891 7.08139 14.6822 7.01144 14.4667 7H7.1C6.90883 7.02345 6.72733 7.10018 6.57475 7.22206C6.42217 7.34395 6.30419 7.50644 6.23333 7.69231L4.56667 10.1538H17.3333Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VehicleTypeSedanIcon;
