import { gql } from "@apollo/client";

export const CREATE_PAYMENT_METHOD_MUTATION = gql`
  mutation CreatePaymentMethod($input: CreatePaymentMethodInput!) {
    createPaymentMethod(input: $input) {
      paymentMethod {
        id
        createdAt
        updatedAt
        contactId
        cardNote
        stripeStatus
        card {
          id
          expMonth
          expYear
          last4
          brand
        }
      }
    }
  }
`;

export const CREATE_PAYMENT_METHOD_FOR_FARM_AFFILIATE = gql`
  mutation createPaymentMethodForFarmAffiliate(
    $input: createPaymentMethodForFarmAffiliateInput!
  ) {
    createPaymentMethodForFarmAffiliate(input: $input) {
      paymentMethod {
        id
        card {
          id
          expMonth
          expYear
          last4
          brand
        }
        stripeStatus
        cardNote
      }
    }
  }
`;

export const CREATE_PAYMENT_METHOD_FOR_CONTACT = gql`
  mutation createPaymentMethodForContact(
    $input: createPaymentMethodForContactInput!
  ) {
    createPaymentMethodForContact(input: $input) {
      paymentMethod {
        id
        card {
          id
          expMonth
          expYear
          last4
          brand
        }
        stripeStatus
        cardNote
      }
    }
  }
`;

export const CREATE_PAYMENT_METHOD_FOR_COMPANY = gql`
  mutation createPaymentMethodForCompany(
    $input: createPaymentMethodForCompanyInput!
  ) {
    createPaymentMethodForCompany(input: $input) {
      paymentMethod {
        id
        card {
          id
          expMonth
          expYear
          last4
          brand
        }
        stripeStatus
        cardNote
      }
    }
  }
`;

export const UPDATE_PAYMENT_METHOD_MUTATION = gql`
  mutation UpdatePaymentMethod($input: UpdatePaymentMethodInput!) {
    updatePaymentMethod(input: $input) {
      paymentMethod {
        id
        createdAt
        updatedAt
        contactId
        cardNote
        isPrimary
        card {
          id
          expMonth
          expYear
          last4
          brand
        }
        billingDetails {
          name
          phone
          email
          line1
          line2
          city
          postalCode
          state
          country
        }
      }
    }
  }
`;

export const REMOVE_PAYMENT_METHOD_MUTATION = gql`
  mutation RemovePaymentMethod($input: RemovePaymentMethodInput!) {
    removePaymentMethod(input: $input) {
      paymentMethod {
        id
      }
    }
  }
`;

export const UPDATE_PRIMARY_PAYMENT_METHOD_MUTAITON = gql`
  mutation updatePrimaryPaymentMethod(
    $input: UpdatePrimaryPaymentMethodInput!
  ) {
    updatePrimaryPaymentMethod(input: $input) {
      paymentMethod {
        id
        createdAt
        updatedAt
        contactId
        cardNote
        card {
          id
          expMonth
          expYear
          last4
          brand
        }
      }
    }
  }
`;

export const LOAD_PAYMENT_METHOD_QUERY = gql`
  query Node($id: ID!) {
    node(id: $id) {
      ... on PaymentMethod {
        id
        createdAt
        updatedAt
        contactId
        cardNote
        isPrimary
        customer
        stripeStatus
        stripeId
        linkedPassenger {
          id
          firstName
          lastName
        }
        card {
          id
          expMonth
          expYear
          last4
          brand
          country
          cvcCheck
          zipCodeCheck
        }
        billingDetails {
          name
          phone
          email
          line1
          line2
          city
          postalCode
          state
          country
        }
      }
    }
  }
`;

export const CREATE_LAGO_CUSTOMER_AND_ATTACH_PAYMENT_METHOD_MUTATION = gql`
  mutation CreateLagoCustomerAndAttachPaymentMethod(
    $input: CreateLagoCustomerAndAttachPaymentMethodInput!
  ) {
    createLagoCustomerAndAttachPaymentMethod(input: $input) {
      operator {
        id
        plan
        lagoPlanCode
      }
    }
  }
`;

export const LOAD_LAGO_PAYMENT_METHOD_QUERY = gql`
  query Operator($id: ID!) {
    node(id: $id) {
      id
      ... on Operator {
        id
        lagoPaymentMethod {
          id
          card {
            id
            last4
            brand
            expMonth
            expYear
          }
          isPrimary
        }
      }
    }
  }
`;

export const CREATE_LAGO_PAYMENT_METHOD_MUTATION = gql`
  mutation CreateSubscriptionPaymentMethod(
    $input: CreateSubscriptionPaymentMethodInput!
  ) {
    createSubscriptionPaymentMethod(input: $input) {
      paymentMethod {
        id
        card {
          id
          last4
          brand
          expMonth
          expYear
        }
        isPrimary
      }
    }
  }
`;

export const UPDATE_PRIMARY_LAGO_PAYMENT_METHOD_MUTATION = gql`
  mutation UpdatePrimarySubscriptionPaymentMethod(
    $input: UpdatePrimarySubscriptionPaymentMethodInput!
  ) {
    updatePrimarySubscriptionPaymentMethod(input: $input) {
      paymentMethod {
        id
        card {
          id
          last4
          brand
          expMonth
          expYear
        }
      }
    }
  }
`;

export const REMOVE_LAGO_PAYMENT_METHOD_MUTATION = gql`
  mutation RemoveSubscriptionPaymentMethod(
    $input: RemoveSubscriptionPaymentMethodInput!
  ) {
    removeSubscriptionPaymentMethod(input: $input) {
      paymentMethod {
        id
        card {
          id
          last4
          brand
          expMonth
          expYear
        }
        isPrimary
      }
    }
  }
`;

export const LINK_PASSENGER_TO_PAYMENT_METHOD_MUTATION = gql`
  mutation LinkPaymentMethod($input: LinkPaymentMethodInput!) {
    linkPaymentMethod(input: $input) {
      paymentMethod {
        id
        linkedPassenger {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const UNLINK_PASSENGER_FROM_PAYMENT_METHOD_MUTATION = gql`
  mutation UnlinkPaymentMethod($input: UnlinkPaymentMethodInput!) {
    unlinkPaymentMethod(input: $input) {
      paymentMethod {
        id
        linkedPassenger {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
