import React from "react";
import { useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import NavButton from "./NavButton";

export type NavLink = {
  text: string;
  icon?: JSX.Element;
  paths: string[];
  disabled?: boolean;
  permissionsOverride?: boolean;
};

type NavButtonListProps = {
  navLinks: NavLink[];
  mode?: "settings" | "reservations";
  isCollapsedItem?: boolean;
  onDrawerToggle?: () => void;
};

function NavButtonList(props: NavButtonListProps) {
  const { navLinks, mode, onDrawerToggle, isCollapsedItem } = props;

  const { pathname } = useLocation();

  return (
    <>
      {navLinks.map(({ text, icon, paths, disabled = false, permissionsOverride }) => {
        const isActive = paths.some((path) => pathname.startsWith(path));

        return (
          <Box my="1px" key={text}>
            <NavButton
              itemsCount={0} // load quotes button
              text={text}
              icon={icon}
              path={paths[0]}
              disabled={disabled}
              active={isActive}
              mode={mode}
              onDrawerToggle={onDrawerToggle}
              isCollapsedItem={isCollapsedItem}
              permissionsOverride={permissionsOverride}
            />
          </Box>
        );
      })}
    </>
  );
}

export default NavButtonList;
