import React, { Dispatch, SetStateAction } from "react";

import { Box, Typography, IconButton, Tabs, Tab } from "@mui/material";

import { grayDark } from "../../../../design-system/colors";
import { CrossIcon } from "../../../../design-system/icons";
import { primaryMainColor } from "../../../../theme";
import { useScreenSize } from "../../../../globals/hooks";

const styles = {
  selected: {
    color: primaryMainColor,
  },
  root: {
    fontSize: 14,
    fontWeight: 600,
    minWidth: "0px",
  },
};

type TemplateDialogHeaderProps = {
  onClose: () => void;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  setActiveTemplateIndex: Dispatch<SetStateAction<number>>;
  draggableHandle?: string;
};

function TemplateDialogHeader(props: TemplateDialogHeaderProps) {
  const {
    onClose,
    activeTab,
    setActiveTab,
    setActiveTemplateIndex,
    draggableHandle,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
    setActiveTemplateIndex(0);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      m={isMobileView ? 2 : 0}
      id={draggableHandle}
    >
      <Box
        display="flex"
        {...(isMobileView && { flexDirection: "column" })}
        justifyContent="space-between"
        width={480}
      >
        <Typography variant="h3">Select a Template</Typography>

        {/* free/paid tabs */}
        <Tabs
          indicatorColor="primary"
          value={activeTab}
          variant="standard"
          onChange={handleTabChange}
          style={{
            marginTop: isMobileView ? "8px" : "-10px",
            marginBottom: isMobileView ? "-16px" : "",
          }}
        >
          <Tab
            sx={{
              "&.Mui-selected": styles.selected,
              "&.MuiTab-root": styles.root,
            }}
            label="FREE"
          />
          <Tab
            sx={{
              "&.Mui-selected": styles.selected,
              "&.MuiTab-root": styles.root,
            }}
            label="PREMIUM"
          />
        </Tabs>
      </Box>

      <Box mt={-1} mr={-1}>
        <IconButton onClick={onClose} size="large">
          <CrossIcon color={grayDark} />
        </IconButton>
      </Box>
    </Box>
  );
}

export default TemplateDialogHeader;
