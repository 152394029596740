import { useMemo } from "react";

import { deriveIsFarmee } from "globals/utils/farm";
import { filterActiveTrips } from "globals/utils/trips";
import { Request } from "types";

// returns either booking contact or farmor name for a request
const useRequestContact = (request: Request) => {
  const requestContact = useMemo(() => {
    const isCancelledRequest = !!request.cancelledAt;
    const relevantTrips = isCancelledRequest
      ? request.trips
      : filterActiveTrips(request.trips);

    // farmed route derived state
    const isFarmedRoute = !!request.farmedRouteStatus;
    const isFarmee =
      isFarmedRoute && deriveIsFarmee(relevantTrips[0].routes[0]);
    const farmAffiliate =
      isFarmedRoute && relevantTrips[0].routes[0].farmAffiliate;

    const bookingContact = request.bookingContact;

    return isFarmee
      ? {
          name: farmAffiliate.operatorName,
          email: farmAffiliate.operatorEmail,
          mobilePhone: farmAffiliate.operatorPhone,
        }
      : {
          name: `${bookingContact.firstName} ${bookingContact.lastName}`,
          email: bookingContact.email,
          mobilePhone: bookingContact.mobilePhone,
        };
  }, [request]);

  return requestContact;
};

export { useRequestContact };
