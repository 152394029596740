import { Box, Typography } from "@mui/material";
import React from "react";

type DriverPayoutTextBlockProps = {
  header: string;
  body: string | number;
  bold?: boolean;
  size?: "sm" | "lg";
};

function DriverPayoutTextBlock(props: DriverPayoutTextBlockProps) {
  const { header, body, bold, size } = props;

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="overline" style={{ whiteSpace: "nowrap" }}>
        {header}
      </Typography>
      <Typography
        variant={size === "lg" ? "subtitle1" : "caption"}
        style={{ fontWeight: bold ? 700 : 400 }}
      >
        {body}
      </Typography>
    </Box>
  );
}

export default DriverPayoutTextBlock;
