import React, { useState, useCallback, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDebounce } from "use-debounce";
import cloneDeep from "lodash/cloneDeep";
import kebabCase from "lodash/kebabCase";
import { useMutation } from "@apollo/client";
import { parse } from "query-string";

import { Box, Typography, CircularProgress } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";

import { alabaster } from "../../../design-system/colors";
import {
  UPDATE_OPERATOR_MUTATION,
  UPDATE_OPERATOR_SETTINGS,
} from "../../../globals/graphql";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import { Operator } from "../../../types";
import SavedIndicatorChip from "../../../design-system/components/chips/SaveIndicatorChip";
import SettingsTabs, { TabConfig } from "../components/SettingsTabs";
import { convertToPercentDecimals, convertToPercent } from "./utils";
import {
  useAnalytics,
  useOperator,
  useScreenSize,
} from "../../../globals/hooks";
import theme from "theme";
import CompanyTab from "./tabs/CompanyTab";
import CommunicationsTab from "./tabs/CommunicationsTab";
import PaymentsTab from "./tabs/PaymentsTab";
import PreferencesTab from "./tabs/PreferencesTab";

// page with tab styles
export const styles = {
  fixedToTop: {
    top: 0,
    right: 0,
    left: 0,
    width: "100%",
    backgroundColor: alabaster,
    zIndex: 20,
  },
  container: {
    display: "flex",
    minHeight: "100%",
    flex: 1,

    maxWidth: "826px",
    margin: "auto",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      overflowY: "auto",
    },
  },
  header: {
    minHeight: "78px",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
};

function OperatorSettingsPage() {
  // hooks
  const snackbar = useSnackbar();
  const { isMediumTabletView } = useScreenSize();
  const remoteOperatorData = useOperator();
  const history = useHistory();
  const location = useLocation();
  const { track } = useAnalytics();

  // mutations
  const [updateOperatorInfo] = useMutation(UPDATE_OPERATOR_MUTATION, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      snackbar.error("Error updating operator");

      // resets graphql to match cache
      setOperator(cloneDeep(remoteOperatorData));
      flag.current = false;
    },
  });

  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      snackbar.error("Error updating pricing layout");
    },
  });

  // state
  const [operator, setOperator] = useState<Partial<Operator> | undefined>();
  const [tabIndex, setTabIndex] = useState<string>("0");
  const [debouncedOperator] = useDebounce(operator, 500);
  const flag = useRef(false);

  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "loading" | "saved" | "error"
  >("default");

  // event handlers
  const debouncedUpdateOperatorInfo = useCallback(() => {
    setSaveIndicatorState("loading");

    delete debouncedOperator.settings.pricingLayout.__typename;
    delete debouncedOperator.settings.pricingLayout.meetGreetAmtEnabled;

    // update operator
    const operatorInput = {
      name: debouncedOperator.name,
      generalEmail: debouncedOperator.generalEmail,
      customDomainFromName: debouncedOperator.customDomainFromName,
      address: debouncedOperator.address,
      websiteUrl: debouncedOperator.websiteUrl,
      permitNumber: debouncedOperator.permitNumber,
      bookingEmail: debouncedOperator.bookingEmail,
      voicePhoneNumber: debouncedOperator.voicePhoneNumber,
      textPhoneNumber: debouncedOperator.textPhoneNumber,
      widgetBannerUrl: debouncedOperator.widgetBannerUrl,
      companyLogoUrl: debouncedOperator.companyLogoUrl,
    };

    updateOperatorInfo({
      variables: {
        input: operatorInput,
      },
    });

    // update operator settings
    const operatorSettingsInput = {
      pricingLayout: convertToPercentDecimals(
        debouncedOperator.settings.pricingLayout
      ),
    };

    updateOperatorSettings({
      variables: {
        input: operatorSettingsInput,
      },
    });
  }, [debouncedOperator, updateOperatorInfo, updateOperatorSettings]);

  const handleOperatorInfoChange =
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setOperator({ ...operator, [key]: event.target.value });
    };

  const handleOperatorPricingLayoutChange = (value: any) => {
    // server only accepts meetGreetAmt as nullable graphqlFloat
    if (value.meetGreetAmt === "") {
      value.meetGreetAmt = null;
    }

    setOperator({
      ...operator,
      settings: { ...operator.settings, pricingLayout: value },
    });
  };

  const handleOperatorLocationChange = (value: any) => {
    setOperator({ ...operator, address: value.description });
  };

  const handleOperatorPhotoChange = (key: string) => (url: string) => {
    setOperator({ ...operator, [key]: url });
  };

  const handleSetTabIndex = (tabIndex) => {
    switch (generalSettingsTabs[tabIndex].label) {
      case "Company":
        track("generalSettings_companyOpened");
        break;
      case "Communication":
        track("generalSettings_communicationsOpened");
        break;
      case "Payments":
        track("generalSettings_paymentsOpened");
        break;
      case "Preferences":
        track("generalSettings_preferencesOpened");
        break;
    }

    const tabQuery = kebabCase(generalSettingsTabs[tabIndex].label);
    history.push(`/settings/general?tab=${tabQuery}`);
  };

  // handle tab query parameter
  useEffect(() => {
    const query = parse(location.search);

    if (query?.tab) {
      const tabIndex = generalSettingsTabs.findIndex(
        (tab) => query.tab === kebabCase(tab.label)
      );

      if (tabIndex > -1) {
        setTabIndex(`${tabIndex}`);
      } else {
        history.replace("/settings/general?tab=company");
      }
    } else {
      const isPhonePortingPage =
        history.location.pathname.includes("port-phone-number");

      // defaults to company or communication
      history.replace(
        `/settings/general?tab=${
          isPhonePortingPage ? "communication" : "company"
        }`
      );
    }
  }, [history, location.search]);

  // sets term on load
  useEffect(() => {
    if (remoteOperatorData && !operator) {
      const clonedOperator = cloneDeep(remoteOperatorData);

      clonedOperator.settings.pricingLayout = convertToPercent(
        clonedOperator.settings.pricingLayout
      );

      setOperator(clonedOperator);
    }
  }, [remoteOperatorData, operator]);

  // debounces update to server (except on first load);
  useEffect(() => {
    if (!debouncedOperator) {
      return;
    }

    if (!flag.current) {
      flag.current = true;
    } else {
      debouncedUpdateOperatorInfo();
    }
  }, [debouncedUpdateOperatorInfo, debouncedOperator]);

  const tabComponentProps = {
    isMobileView: isMediumTabletView,
    operator,
    onOperatorInfoChange: handleOperatorInfoChange,
    onOperatorLocationChange: handleOperatorLocationChange,
    onOperatorPricingLayoutChange: handleOperatorPricingLayoutChange,
    onOperatorPhotoChange: handleOperatorPhotoChange,
    setSaveIndicatorState,
  };

  return (
    <>
      <Helmet>
        <title>General | Moovs</title>
      </Helmet>
      {!operator ? (
        <Box
          width="100%"
          height="100px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={40} thickness={4} />
        </Box>
      ) : (
        <Box flexDirection="column" sx={styles.container}>
          <TabContext value={tabIndex}>
            <Box position="sticky" sx={styles.fixedToTop}>
              <Box flexDirection="row" flexWrap="wrap" sx={styles.header}>
                <Typography variant="h2">General</Typography>
                <Box>
                  <SavedIndicatorChip mode={saveIndicatorState} />
                </Box>
              </Box>
              <SettingsTabs
                isMobileView={isMediumTabletView}
                tabIndex={tabIndex}
                setTabIndex={handleSetTabIndex}
                tabs={generalSettingsTabs}
              />
            </Box>

            {/* General Settings Tab Contents */}
            <Box>
              {generalSettingsTabs.map((tab, index) => (
                <TabPanel key={index} value={index.toString()}>
                  {tab.component(tabComponentProps)}
                </TabPanel>
              ))}
            </Box>
          </TabContext>
        </Box>
      )}
    </>
  );
}

const generalSettingsTabs: TabConfig[] = [
  {
    label: "Company",
    component: (props) => <CompanyTab {...props} />,
  },
  {
    label: "Communication",
    component: (props) => <CommunicationsTab {...props}></CommunicationsTab>,
  },
  {
    label: "Payments",
    component: (props) => <PaymentsTab {...props}></PaymentsTab>,
  },
  {
    label: "Preferences",
    component: (props) => <PreferencesTab {...props}></PreferencesTab>,
  },
];

export default OperatorSettingsPage;
