import React from "react";
import { Controller } from "react-hook-form";
import { useQuery } from "@apollo/client";

import { Box, Typography, CircularProgress } from "@mui/material";

import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";
import { PricelessBookingTarget } from "types";
import {
  LOAD_COMPANIES_NAME_AND_ID_QUERY,
  LOAD_CONTACTS_QUERY,
} from "globals/graphql";
import UpdatePricelessBookingTargetDropdown from "./UpdatePricelessBookingTargetDropdown";
import { useScreenSize } from "globals/hooks";
import {
  CompanyType,
  ContactType,
  FieldNameEnum,
  SelectionType,
} from "components/vehicles/CreateVehicleDrawer/types";
import { toggleButtons } from "components/vehicles/constant";
import { useUpdateVehicleFormContext } from "../../hooks/useUpdateVehicleForm";

function UpdatePricelessBookingTargetSection() {
  // hooks
  const { isMobileView } = useScreenSize();
  const formCtx = useUpdateVehicleFormContext();

  // queries
  const { data: companiesData } = useQuery(LOAD_COMPANIES_NAME_AND_ID_QUERY, {
    fetchPolicy: "cache-and-network",
    skip: !formCtx,
  });
  const { data: contactsData } = useQuery(LOAD_CONTACTS_QUERY, {
    fetchPolicy: "cache-and-network",
    skip: !formCtx,
  });

  if (!formCtx) {
    console.error(
      "useUpdateVehicleFormContext must be used within an UpdateVehicleFormProvider"
    );
    return (
      <Box mt={4}>
        <Typography variant="body2" color="error">
          Error loading settings section. Please refresh the page.
        </Typography>
      </Box>
    );
  }

  if (!companiesData || !contactsData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" my={4}>
        <CircularProgress size={20} />
      </Box>
    );
  }

  const { control, watch } = formCtx;

  // derived state
  const pricelessBookingEnabled = watch(
    "vehicle.settings.pricelessBookingEnabled"
  );
  const pricelessBookingTarget = watch(
    "vehicle.settings.pricelessBookingTarget"
  );

  const companyOptions: SelectionType[] =
    companiesData.companies.map((company: CompanyType) => ({
      name: company.name,
      id: company.id,
    })) || [];

  const contactOptions: SelectionType[] =
    contactsData.loadContacts.edges.map(
      ({ node: contact }: { node: ContactType }) => ({
        name: `${contact.firstName} ${contact.lastName}`,
        id: contact.id,
      })
    ) || [];

  return (
    <>
      {pricelessBookingEnabled && (
        <>
          <Box my={1}>
            <Controller
              control={control}
              name="vehicle.settings.pricelessBookingTarget"
              render={({ field: { onChange, value } }) => {
                const handleOnChange = (_, newType) => {
                  onChange(newType);
                };
                return (
                  <MoovsToggleButtonGroup
                    onChange={handleOnChange}
                    options={toggleButtons}
                    value={value}
                    fullWidth
                    keepInline
                  />
                );
              }}
            />
          </Box>
          <Box>
            {pricelessBookingTarget === PricelessBookingTarget.Specific && (
              <Box
                display="flex"
                gap={isMobileView ? 0 : 1}
                flexDirection={isMobileView ? "column" : "row"}
              >
                <Box width="100%">
                  <Controller
                    control={control}
                    name="vehicle.settings.pricelessBookingCompanies"
                    render={({ field: { onChange, value } }) => {
                      const handleDropdownChange = (
                        selections: SelectionType[]
                      ) => {
                        onChange(selections);
                      };
                      const handleDeleteDropdown = (
                        selected: SelectionType
                      ) => {
                        onChange(
                          value.filter((item) => item.id !== selected.id)
                        );
                      };
                      return (
                        <UpdatePricelessBookingTargetDropdown
                          options={companyOptions}
                          fieldName={FieldNameEnum.company}
                          dropdownTitle="Companies"
                          placeholder="No Company Selected"
                          selection={value}
                          handleDropdownChange={handleDropdownChange}
                          handleDeleteDropdown={handleDeleteDropdown}
                        />
                      );
                    }}
                  />
                </Box>
                <Box width="100%">
                  <Controller
                    control={control}
                    name="vehicle.settings.pricelessBookingContacts"
                    render={({ field: { onChange, value } }) => {
                      const handleDropdownChange = (
                        selections: SelectionType[]
                      ) => {
                        onChange(selections);
                      };
                      const handleDeleteDropdown = (
                        selected: SelectionType
                      ) => {
                        onChange(
                          value.filter((item) => item.id !== selected.id)
                        );
                      };
                      return (
                        <UpdatePricelessBookingTargetDropdown
                          options={contactOptions}
                          fieldName={FieldNameEnum.contact}
                          dropdownTitle="Contacts"
                          placeholder="No Contact Selected"
                          selection={value}
                          handleDropdownChange={handleDropdownChange}
                          handleDeleteDropdown={handleDeleteDropdown}
                        />
                      );
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  );
}

export default UpdatePricelessBookingTargetSection;
