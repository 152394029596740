import { black, errorRed, grayLight, tintRed } from "design-system/colors";
import React, { useMemo } from "react";
import { Trip } from "types";
import RoundChip from "../RoundChip";

type TripStatusChipProps = Pick<Trip, "closedAt" | "cancelledAt">;

const TripStatusChip = (props: TripStatusChipProps) => {
  const { closedAt, cancelledAt } = props;

  const chipProps = useMemo(() => {
    if (cancelledAt)
      return { label: "CANCELLED", color: errorRed, backgroundColor: tintRed };
    if (closedAt)
      return { label: "CLOSED", color: black, backgroundColor: grayLight };
    return null;
  }, [cancelledAt, closedAt]);

  if (chipProps === null) return null;
  return <RoundChip {...chipProps} />;
};

export default TripStatusChip;
