import React from "react";

type GenericCreditCardIconProps = {
  color?: string;
};

const GenericCreditCardIcon = (props: GenericCreditCardIconProps) => {
  const { color } = props;

  return (
    <svg
      width="21"
      height="14"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 4.00001H19.5M17.152 7H14.8042M10.1086 7H3.84778M6.1956 9.24999H3.84778M3.06522 1H17.9348C18.7992 1 19.5 1.67157 19.5 2.5V11.5C19.5 12.3284 18.7992 13 17.9348 13H3.06522C2.20077 13 1.5 12.3284 1.5 11.5V2.5C1.5 1.67157 2.20077 1 3.06522 1Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GenericCreditCardIcon;
