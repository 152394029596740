import React from "react";

import { Box, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

import MoovsSearchableCheckboxDropdown from "components/MoovsSearchableCheckboxDropdown";
import { StatusSlug } from "types";
import { FilterProps } from "../FilterDispatchTripsDrawer";

const tripStatusOptions = [
  { name: "Pending", id: StatusSlug.Pending },
  { name: "Confirmed", id: StatusSlug.Confirmed },
  { name: "On The Way", id: StatusSlug.Otw },
  { name: "On Location", id: StatusSlug.OnLocation },
  { name: "Passenger On Board", id: StatusSlug.Pob },
  { name: "Done", id: StatusSlug.Done },
  { name: "Cancelled", id: StatusSlug.Cancelled },
];

function TripStatusFilter(props: FilterProps) {
  const { disabledSelect } = props;

  // hooks
  const { watch, setValue } = useFormContext();

  return (
    <Box mb={2}>
      <Typography variant="h3" mb={2}>
        Trip Status
      </Typography>

      <MoovsSearchableCheckboxDropdown
        fieldName="Trip Status"
        options={tripStatusOptions}
        selection={watch("tripStatusSelection")}
        setSelection={(newValue) => setValue("tripStatusSelection", newValue)}
        disabled={disabledSelect}
        tooltipText="Upgrade plan to Pro in order to use filtering feature"
      />
    </Box>
  );
}

export default TripStatusFilter;
