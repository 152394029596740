/**
 * @file recipient.graphql.ts
 * queries/mutations for recipient
 *
 * recipient is a contact or driver.
 *
 */
import { gql } from "@apollo/client";

export const LOAD_RECIPIENTS_QUERY = gql`
  query Recipients($searchTerm: String) {
    recipients(searchTerm: $searchTerm) {
      ... on Driver {
        id
        __typename
        firstName
        lastName
        mobilePhone
        # alias email to driverEmail as conflict between driver/contact
        # because driver.email is nullable and contact.email isn't
        driverEmail: email
      }
      ... on Contact {
        id
        __typename
        firstName
        lastName
        mobilePhone
        mobilePhoneInternational
        email
        phoneCountryCode
        phoneCountryDialCode
        phoneCountryName
        phoneCountryFormat
      }
    }
  }
`;
