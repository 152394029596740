import React, { useState } from "react";
import Linkify from "linkifyjs/react";
import moment from "moment-timezone";

import { Box, Typography } from "@mui/material";

import {
  WheelIcon,
  UserIcon,
  EstimationIcon,
} from "../../../design-system/icons";
import {
  black,
  grayDark,
  grayLight,
  white,
} from "../../../design-system/colors";
import MediaMessage from "../media/MediaMessage";
import { getMediaVariant, MediaVariantEnum } from "../utils";

type MessageBubbleProps = {
  direction: "outgoing" | "incoming";
  isLastMessage: boolean;
  message: any;
  participant: any;
};

function MessageBubble(props: MessageBubbleProps) {
  const {
    direction,
    message: { attributes, body, sid, dateCreated, media },
    participant: { name, mobilePhone, role },
  } = props;

  // state
  const [isLoadedImage, setIsLoadedImage] = useState(false);

  const messageDate = moment(dateCreated).calendar();
  const isAutomated = !!attributes?.automated;
  const mediaVariant = getMediaVariant(media?.contentType);
  const isImage = mediaVariant === MediaVariantEnum.Image;

  return (
    <Box
      display="flex"
      justifyContent={direction === "incoming" ? "flex-start" : "flex-end"}
      width="100%"
      pb={1}
    >
      <div>
        <Box
          position="relative"
          id={sid}
          width={200}
          pt={1.5}
          px={2}
          pb={2}
          border={`1px solid ${grayLight}`}
          borderRadius="4px"
          style={{ wordWrap: "break-word" }}
          bgcolor={
            direction === "incoming" || (isImage && !isLoadedImage)
              ? white
              : grayLight
          }
        >
          {/* header */}
          <Box display="flex" flexDirection="row" justifyContent="flex-start">
            <Typography
              variant="body2"
              style={{ fontWeight: 500, textAlign: "center" }}
            >
              {name || mobilePhone}
            </Typography>

            <Box
              ml={0.5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {role === "driver" && <WheelIcon size="small" color={black} />}
              {role === "contact" && <UserIcon size="small" color={black} />}
            </Box>
          </Box>

          {/* message */}
          <Box display="flex" flexDirection="row">
            {isAutomated && (
              <Box width="16px" mr={0.5} mt={0.5}>
                <EstimationIcon size="small" />
              </Box>
            )}
            <Linkify tagName="span">
              <Typography
                variant="caption"
                style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
              >
                {body}
              </Typography>
            </Linkify>
          </Box>
          {/* Renders only if message is of type media */}
          {!!media && (
            <MediaMessage
              media={media}
              {...(isImage && {
                isLoadedImage,
                setIsLoadedImage,
              })}
            />
          )}
        </Box>
        {/* date */}
        <Box
          mt={0.5}
          display="flex"
          justifyContent={direction === "incoming" ? "flex-start" : "flex-end"}
        >
          <Typography
            style={{ fontWeight: 400, fontSize: 10, color: grayDark }}
          >
            {messageDate}
          </Typography>
        </Box>
      </div>
    </Box>
  );
}

export default MessageBubble;
