import React from "react";
import startCase from "lodash/startCase";

import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import MoovsAvatar from "design-system/components/MoovsAvatar";

function NameColumn(props: GridRenderCellParams<string>) {
  const { row } = props;

  const { firstName, lastName, driverProfilePhoto } = row?.driver;

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box mr={1}>
        <MoovsAvatar
          size="small"
          src={driverProfilePhoto}
          placeholder={[firstName, lastName]}
        />
      </Box>
      <Typography variant="subtitle2">
        {`${startCase(firstName)} ${startCase(lastName)}`}
      </Typography>
    </Box>
  );
}

export default NameColumn;
