/**
 * reference: https://github.com/mui-org/material-ui-x/issues/1402
 */
import React, { Dispatch, SetStateAction } from "react";

import { GridRenderEditCellParams } from "@mui/x-data-grid-pro";

type ComponentProps = {
  params: GridRenderEditCellParams;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};
export function renderEditCell(
  Component: React.FC<ComponentProps>,
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >
) {
  return (params: GridRenderEditCellParams) => (
    <Component
      params={{ ...params }}
      setSaveIndicatorState={setSaveIndicatorState}
    />
  );
}
