import React, { useState, useEffect } from "react";

import { Button, Box, CircularProgress, Grid, Typography } from "@mui/material";

interface GQLQueryStatusIndicatorProps {
  name: string;
  networkStatus?: number;
  data?: any;
  error?: any;
  loading?: boolean;
  refetch?: () => void;
  delayLoading?: boolean;
}

function GQLQueryStatusIndicator(props: GQLQueryStatusIndicatorProps) {
  const {
    networkStatus,
    data,
    error,
    refetch,
    loading,
    name,
    delayLoading = true,
  } = props;

  const [flag, setFlag] = useState(!delayLoading);

  useEffect(() => {
    setFlag(!delayLoading);
    const timeout = setTimeout(() => setFlag(true), 500);

    return clearTimeout(timeout);
  }, [loading, refetch, delayLoading]);

  if (networkStatus === 1 || networkStatus === 4 || loading) {
    return (
      <>
        {/* wait half a second before showing loading state so it looks better. */}
        {flag && (
          <Box
            display="flex"
            flex="1"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Typography>Please wait while {name} details load.</Typography>
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <CircularProgress />
              </Grid>
            </Grid>
          </Box>
        )}
      </>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        data-testid="gql-error"
      >
        <Typography variant="h5">Error</Typography>
        <Typography>
          Oops! Something went wrong. Try refreshing below.
        </Typography>
        {refetch && (
          <Button onClick={() => refetch()} color="primary" variant="contained">
            Refetch
          </Button>
        )}
      </Box>
    );
  }

  if (!data) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        data-testid="gql-no-data"
      >
        <Typography variant="h5">No {name} data</Typography>
        <Typography>Try refreshing below.</Typography>
        {refetch && (
          <Button onClick={() => refetch()} color="primary" variant="contained">
            Refetch
          </Button>
        )}
      </Box>
    );
  }

  return null;
}

export default GQLQueryStatusIndicator;
