import React from "react";

import { Box, FormControlLabel, Grid, Switch, Typography } from "@mui/material";

import { ViewIcon, ViewOffIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";

type UserAccessPermissionToggleProps = {
  name: string;
  active: boolean;
  handleUserAccessPermissionsChange: (e: any) => void;
};

function UserAccessPermissionToggle(props: UserAccessPermissionToggleProps) {
  const { active, name, handleUserAccessPermissionsChange } = props;

  return (
    <Grid item xs={12} sm={6} sx={{ mb: 1.5 }} container alignItems="center">
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={active}
            onChange={handleUserAccessPermissionsChange}
            size="small"
          />
        }
        label={
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography
              variant="body2"
              noWrap
              sx={{ ml: 1, mr: 1.5 }}
              {...(!active && { color: grayDark })}
            >
              {name}
            </Typography>
            {active ? <ViewIcon /> : <ViewOffIcon />}
            {!active && (
              <Typography
                variant="body2"
                noWrap
                color={grayDark}
                sx={{ ml: 1, fontSize: 12 }}
              >
                No Access
              </Typography>
            )}
          </Box>
        }
        labelPlacement="end"
      />
    </Grid>
  );
}

export default UserAccessPermissionToggle;
