import map from "lodash/map";
import filter from "lodash/filter";

import { useOperator } from "globals/hooks";
import { appData, MarketApp } from "../data/appData";
import { MoovsMarketApp } from "types";

export type MoovsMarketAppData = MoovsMarketApp & {
  name: string;
  icon: string;
  description: string;
  longDescription?: string;
  comingSoon: boolean;
  isPaidApp: boolean;
  enum: MarketApp;
  goTo?: {
    text: string;
    path: string;
  };
  aboutPage: string;
};

const useMoovsMarketApps = () => {
  // hooks
  const operator = useOperator();

  // derived state
  const availableApps: MoovsMarketAppData[] = map(appData, (app, key) => {
    return {
      ...appData[key],
      ...operator?.moovsMarketApps[key],
    };
  });
  const installedApps = filter(availableApps, "active");

  return { availableApps, installedApps, loading: !operator };
};

export default useMoovsMarketApps;
