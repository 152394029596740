import React, { useMemo } from "react";
import pick from "lodash/pick";

import VehicleChildSeats from "components/vehicles/VehicleChildSeats/VehicleChildSeats";
import VehicleFeatures from "components/vehicles/VehicleFeatures";
import VehicleFormSection from "components/vehicles/VehicleFormSection";
import { VehicleChildSeat, VehicleChildSeatInput, VehicleFeature } from "types";
import { CreateVehicle, VehicleChildSeatsError } from "../../types";

type FeaturesProps = {
  vehicle: CreateVehicle;
  setVehicle: React.Dispatch<React.SetStateAction<CreateVehicle>>;
  setVehicleChildSeatErrors: React.Dispatch<
    React.SetStateAction<VehicleChildSeatsError>
  >;
};

function Features(props: FeaturesProps) {
  const { vehicle, setVehicle, setVehicleChildSeatErrors } = props;

  // memo
  const childSeats = useMemo(() => {
    return pick(vehicle.settings, [
      "forwardFacingSeat",
      "rearFacingSeat",
      "boosterSeat",
    ]);
  }, [vehicle.settings]);

  // event handler
  const handleVehicleFeaturesChange =
    (modifiedFeature: VehicleFeature) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newFeatures = event.target.checked
        ? [...vehicle.features, modifiedFeature]
        : vehicle.features.filter(
            (feature: VehicleFeature) => feature.id !== modifiedFeature.id
          );

      setVehicle({
        ...vehicle,
        features: newFeatures,
      });
    };

  const handleVehicleChildSeatChange =
    (childSeatName: "forwardFacingSeat" | "rearFacingSeat" | "boosterSeat") =>
    (childSeat: VehicleChildSeat | VehicleChildSeatInput) => {
      setVehicleChildSeatErrors((prev) => ({
        ...prev,
        [childSeatName]: {
          description: "",
        },
      }));
      setVehicle({
        ...vehicle,
        settings: {
          ...vehicle.settings,
          [childSeatName]: childSeat,
        },
      });
    };

  return (
    <VehicleFormSection title="Vehicle Features">
      <VehicleFeatures
        activeFeatures={vehicle.features}
        handleVehicleFeaturesChange={handleVehicleFeaturesChange}
      />
      <VehicleChildSeats
        childSeats={childSeats}
        handleVehicleChildSeatChange={handleVehicleChildSeatChange}
      />
    </VehicleFormSection>
  );
}

export default Features;
