import React from "react";

import { Box, Typography } from "@mui/material";

import emptyTripInbox from "design-system/illustrations/emptyTripInbox.svg";
import { grayDark } from "design-system/colors";

function EmptyTripsBlock() {
  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height={"100%"}
    >
      <Box component="img" src={emptyTripInbox} alt="An empty mail inbox" />
      <Box mt={1}>
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          No Trips
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography
          variant="body2"
          sx={{ textAlign: "center" }}
          color={grayDark}
        >
          No trips added yet.
        </Typography>
      </Box>
    </Box>
  );
}

export default EmptyTripsBlock;
