/**
 * @file chat/hooks/useConversationsClient.ts
 * Hook for encapsilating Twilio Token fetching/setting/refreshing logic
 */

import { useState, useEffect, useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import client, { Client as ConversationsClient } from "@twilio/conversations";

import { useCurrentUser, useAuth } from "globals/hooks";
import { LOAD_TWILIO_ACCESS_TOKEN_QUERY } from "../../../globals/graphql";

function useConversationsClient() {
  // state
  const [conversationsClient, setConversationsClient] =
    useState<client.Client>(null);
  const [token, setToken] = useState(null);

  // hooks
  const { operatorId } = useCurrentUser() || {};
  const { authStage } = useAuth();

  // queries
  const [loadTwilioAccessTokenQuery] = useLazyQuery(
    LOAD_TWILIO_ACCESS_TOKEN_QUERY,
    {
      fetchPolicy: "network-only",
      onCompleted(data) {
        setToken(data?.accessTokenTwilio || null);
      },
    }
  );

  // event handlers
  const handleRefetchToken = useCallback(async () => {
    const res = await loadTwilioAccessTokenQuery();
    conversationsClient.updateToken(res.data.accessTokenTwilio);
  }, [conversationsClient, loadTwilioAccessTokenQuery]);

  // effects
  // get access token if it doesn't already exist
  useEffect(() => {
    if (operatorId && !token) {
      loadTwilioAccessTokenQuery();
    }
  }, [token, loadTwilioAccessTokenQuery, operatorId]);

  // init conversations client with token
  useEffect(() => {
    if (token && !conversationsClient) {
      const conversationsClient = new ConversationsClient(token);

      conversationsClient.on("initialized", () => {
        setConversationsClient(conversationsClient);
      });
    }
  }, [
    conversationsClient,
    loadTwilioAccessTokenQuery,
    setConversationsClient,
    token,
  ]);

  // shutdown conversations client when logged out
  useEffect(() => {
    if (authStage === "rejected" && (conversationsClient || token)) {
      conversationsClient.removeAllListeners();
      conversationsClient.shutdown();
      setToken(null);
      setConversationsClient(null);
    }
  }, [authStage, conversationsClient, token]);

  return { conversationsClient, onRefetchToken: handleRefetchToken };
}

export { useConversationsClient };
