import React, {
  useState,
  Fragment,
  useEffect,
  useCallback,
  ChangeEvent,
} from "react";
import { DeepPartial } from "ts-essentials";
import { useMutation } from "@apollo/client";
import first from "lodash/first";
import size from "lodash/size";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { useHistory, useLocation } from "react-router-dom";

import {
  CardElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { Box, Typography } from "@mui/material";

import {
  Request,
  Trip,
  Contact,
  Vehicle,
  RoutePricing,
  TripCategory,
  OrderTypeEnum,
  PlanVariant,
  ShuttleRoute,
  VehicleChildSeat,
  CreateShuttleReservationPayload,
} from "types";
import { useSnackbar } from "globals/hooks/useSnackbar";
import { CREATE_SHUTTLE_RESERVATION_MUTATION } from "globals/graphql";
import { useLocalStorage } from "globals/hooks/useLocalStorage";
import {
  useOperator,
  useAnalytics,
  useStripeBillingDetailsElement,
} from "globals/hooks";
import { calculateTotalDurationMinutes } from "globals/utils/stops";
import CreateDrawer from "../../../globals/CreateDrawer";
import ShuttleOrderDetailsCreateBlock from "../ShuttleOrderDetailsCreateBlock";
import AddVehicleCreateBlock from "../../../vehicles/AddVehicleCreateBlock";
import CommentCreateBlock from "../../../CommentCreateBlock";
import {
  initialRequestTemplate,
  initialRequestErrors,
  convertTripToBeInputReady,
  removeStop,
  createStop,
} from "globals/utils/createRequests";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { intialStripeElementErrors } from "globals/utils/stripeCreditCardIntialValues";
import { initialLuggageFields } from "components/requests/luggage/initialValues";
import { useCreateRequestTripType } from "./hooks/useCreateRequestTripType";
import {
  AdditionalInfoCreateBlock,
  CreateCreditCardBlock,
  PricingInfoCreateBlock,
  TripInfoCreateBlock,
} from "./components";
import { parsePricingLayoutForInitialRoute } from "./helpers/parsePricingLayoutForInitialRoute";
import { getChildSeatOptionsFromVehicle } from "./helpers/getChildSeatOptionsFromVehicle";
import { getContactForReservation } from "globals/utils/contact";
import { trackReservations } from "globals/utils/tracking/trackReservations";

const enabledTripCategories = [
  TripCategory.ShuttlePickUp,
  TripCategory.ShuttleReturn,
];

function CreateShuttleReservationDrawer() {
  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const { track } = useAnalytics();
  const { settings, plan } = useOperator() || {};
  const elements = useElements();
  const stripe = useStripe();

  const pageLabel = "Create New Shuttle Reservation";
  const localStorageKey = "submitReservationSettings";
  const getSnackbarLink = (requestId: string) => `/reservations/${requestId}`;
  const snackbarLinkLabel = "View Reservation";
  const getCloseDrawerLink = () => "/reservations";
  const { BillingDetailsElement, getBillingDetails } =
    useStripeBillingDetailsElement();

  // state
  const [request, setRequest] = useState<DeepPartial<Request>>({
    ...JSON.parse(JSON.stringify(initialRequestTemplate)),
    orderType: OrderTypeEnum.Corporate, // default to Corporate orderType
  });
  const [requestErrors, setRequestErrors] = useState(
    JSON.parse(JSON.stringify(initialRequestErrors))
  );
  const [saveErrorText, setSaveErrorText] = useState("");
  const [selectedSubmitButtonIndex, setSelectedSubmitButtonIndex] =
    useLocalStorage(localStorageKey, 0);
  const [suggestedAddressInfo, setSuggestedAddressInfo] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [bookingContact, setBookingContact] =
    useState<DeepPartial<Contact>>(null);
  const [shuttleRoute, setShuttleRoute] =
    useState<DeepPartial<ShuttleRoute>>(null);

  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [hasEnteredCardInfo, setHasEnteredCardInfo] = useState(false);
  const [stripeElementsError, setStripeElementsError] = useState(
    intialStripeElementErrors
  );
  const [cardNote, setCardNote] = useState("");
  const [cardHolderEmail, setCardHolderEmail] = useState(null);
  const [luggageAmount, setLuggageAmount] = useState(initialLuggageFields);
  const [childSeatOptions, setChildSeatOptions] = useState<VehicleChildSeat[]>(
    []
  );

  // more hooks
  const { tripType, onTripTypeChange, submitLabelOptions } =
    useCreateRequestTripType({
      enabledTripCategories,
      setRequest,
      defaultSubmitLabelOptions: [
        "Save reservation",
        "Save reservation & send email",
      ],
    });

  // derived state
  const shouldSendEmail = selectedSubmitButtonIndex === 1;
  // mutations
  const [createShuttleReservation] = useMutation<{
    createShuttleReservation: CreateShuttleReservationPayload;
  }>(CREATE_SHUTTLE_RESERVATION_MUTATION, {
    onCompleted(data) {
      const request = data.createShuttleReservation.request;
      trackReservations(track, request, "reservation_created");

      if (shouldSendEmail) {
        track("reservation_savedAndSent");
      }

      snackbar.success(`Successfully saved reservation`, {
        link: getSnackbarLink(data.createShuttleReservation.request.id),
        linkLabel: snackbarLinkLabel,
        onLinkClick: () => {
          track("snackbar_reservation_viewed");
        },
      });

      handleCreateRequestDrawerClose();
    },
    onError(error) {
      setSubmitDisabled(false);
      const errorMessage =
        getErrorMessage(error) || "Error creating reservation";

      snackbar.error(errorMessage);
    },
    refetchQueries: ["Requests"],
  });

  // event handlers
  const updateTrip = useCallback(
    (newTrip: DeepPartial<Trip>, tripIndex: number) => {
      setRequest((prevRequest) => {
        const newTrips = [...prevRequest.trips];
        newTrips.splice(tripIndex, 1, newTrip);
        return {
          ...prevRequest,
          trips: newTrips,
        };
      });
    },
    []
  );

  const handleCreateRequestDrawerClose = () => {
    elements.getElement(CardElement)?.clear();
    elements.getElement("address")?.clear();

    setTimeout(() => {
      history.push({
        search: location.search,
        pathname: getCloseDrawerLink(),
      });
    }, 200);
  };

  const updateRoute =
    (tripIndex: number) =>
    (targetName: string, targetValue: number | string | boolean) => {
      const newRoutes = [...request.trips[tripIndex].routes];
      const newRoute = newRoutes[0];

      newRoute[targetName] = targetValue;
      const newTrip = { ...request.trips[tripIndex], routes: newRoutes };

      updateTrip(newTrip, tripIndex);
    };

  const handleCreateStop = (tripIndex: number) => () => {
    const newTrips = createStop(request.trips, tripIndex);

    const stopsErrors = [...requestErrors.stops];
    const lastStopErrors = stopsErrors.pop();
    requestErrors.stops = [...requestErrors.stops];

    setRequestErrors({
      ...requestErrors,
      stops: [
        ...stopsErrors,
        {
          location: "",
          dateTime: "",
          stopIndex: requestErrors.stops.length,
        },
        {
          ...lastStopErrors,
          stopIndex: requestErrors.stops.length + 1,
        },
      ],
    });

    setRequest({
      ...request,
      trips: newTrips,
    });
  };

  const handleRemoveStop = (tripIndex: number) => (stopIndex: number) => () => {
    const newTrips = removeStop(request.trips, tripIndex, stopIndex);

    setRequest({
      ...request,
      trips: newTrips,
    });

    requestErrors.stops.splice(stopIndex - 1, 1);

    setRequestErrors({
      ...requestErrors,
      stops: requestErrors.stops,
    });
  };

  const addVehicles = (tripIndex: number) => (vehicles: Partial<Vehicle>[]) => {
    const vehicle = first(vehicles);

    const childSeats = getChildSeatOptionsFromVehicle(vehicle);
    setChildSeatOptions(childSeats);

    const newTrips = [...request.trips];
    newTrips[tripIndex].routes[0].vehicle = { id: vehicle.id };

    setRequest({
      ...request,
      trips: newTrips,
    });
  };

  const removeVehicle = (tripIndex: number) => () => {
    const newTrips = [...request.trips];
    delete newTrips[tripIndex].routes[0].vehicle;
    setChildSeatOptions([]);

    setRequest({
      ...request,
      trips: newTrips,
    });
  };

  const handleDeleteCardElement = () => {
    const card = elements.getElement(CardNumberElement);
    card.clear();
  };

  const handleCreditCardInputChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.name === "cardNote") {
      setCardNote(event.target.value);
    }

    if (event.target.name === "cardHolderEmail") {
      setCardHolderEmail(event.target.value);
    }
  };
  const handleSubmitReservationClick = async () => {
    let errors = [];
    let paymentMethodErrors;
    let stripePaymentMethod;

    let newSaveErrorText = "Oops! Please check the above fields for errors.";

    if (!request.orderType) {
      errors = [...errors, { type: "Please choose order type" }];
    }
    // payment method
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet.
      return;
    }
    setSubmitDisabled(true);

    const card = elements.getElement(CardElement);

    // create stripe payment method
    if (card !== null && hasEnteredCardInfo) {
      const billingDetails = await getBillingDetails();
      if (!billingDetails) {
        setSubmitDisabled(false);
        return;
      }

      const { paymentMethod, error: stripePaymentMethodError } =
        await stripe.createPaymentMethod({
          type: "card",
          card: card,
          billing_details: {
            ...billingDetails,
            email: cardHolderEmail || null,
          },
        });

      if (!stripePaymentMethodError) {
        stripePaymentMethod = paymentMethod;
      } else {
        paymentMethodErrors = stripePaymentMethodError.message;
      }
    }

    // company
    if (!companyId) {
      errors = [...errors, { company: "Please add a company" }];
    }

    // booking contact
    if (!bookingContact) {
      errors = [...errors, { bookingContact: "Please add a booking contact" }];
    }

    // shuttle route
    if (!shuttleRoute) {
      errors = [...errors, { shuttleRoute: "Please add a route name" }];
    }

    // vehicle errors
    if (!request.trips[0].routes[0].vehicle) {
      errors = [...errors, { vehicle: "Please select a vehicle" }];
    }

    // stop errors
    const stopErrors = request.trips[0].stops.map((stop) => {
      const addressErrors = {
        location: !stop.location ? "Please enter location" : "",
        locationAlias:
          // This is just to prevent form submission. The component handles the helper text
          stop.locationAlias && stop.locationAlias.length > 35
            ? "Cannot exceed character limit of 35"
            : "",
      };

      const airportErrors = {
        airport: !stop.airport ? "Please select an Airport" : "",
        airline:
          !!stop.flightNumber && !stop.airline
            ? "Please select an Airline"
            : "",
      };

      const isLocationError =
        !!addressErrors.location || !!addressErrors.locationAlias;
      const isAirportError = Object.values(airportErrors).some(
        (error) => !!error.length
      );

      const isPickUpDateTimeError = !stop.dateTime && stop.stopIndex === 1;
      const isDropOffDateTimeError =
        (tripType === TripCategory.Hourly ||
          tripType === TripCategory.ShuttlePickUp) &&
        !stop.dateTime &&
        stop.stopIndex === size(first(request.trips).stops);

      const isShuttleStopDateTimeError =
        tripType === TripCategory.ShuttlePickUp &&
        stop.stopIndex !== 1 &&
        stop.stopIndex !== size(first(request.trips).stops) &&
        !stop.dateTime;

      const dateTimeErrors = {
        dateTime:
          isPickUpDateTimeError || isDropOffDateTimeError
            ? "Please enter date"
            : isShuttleStopDateTimeError
            ? `Stop ${stop.stopIndex - 1} Date & Time is required`
            : "",
      };

      // if error in both show errors
      // if only one has an error that means it
      // isnt the variant that is selected
      if (isLocationError && isAirportError) {
        return {
          ...addressErrors,
          ...airportErrors,
          ...dateTimeErrors,
        };
      }

      return {
        ...dateTimeErrors,
      };
    });

    const isErrorStops = stopErrors.some((error) =>
      Object.values(error).some((value) => value.length)
    );

    if (errors.length > 0 || isErrorStops || paymentMethodErrors) {
      errors = [...errors, { stops: stopErrors }];

      setRequestErrors(
        errors.reduce((acc, value) => ({ ...acc, ...value }), {})
      );

      setSaveErrorText(newSaveErrorText);
      setStripeElementsError({
        ...stripeElementsError,
        createStripePaymentMethodError: paymentMethodErrors,
      });
      setSubmitDisabled(false);
      return;
    }

    // sets type as input type (as stop input is different than stop type)
    const requestInput: DeepPartial<Request> = cloneDeep(request);

    // converts airport and airlines from objects to just the code for input
    requestInput.trips = requestInput.trips.map((trip: Trip) =>
      convertTripToBeInputReady(trip, luggageAmount)
    );

    // set default booking contact if nothing is picked
    requestInput.trips = requestInput.trips.map((trip: Trip) => {
      if (!trip.contact?.firstName) {
        trip.contact = getContactForReservation(
          bookingContact as Contact
        ) as Contact;
      } else {
        trip.contact = getContactForReservation(trip.contact) as Contact;
      }
      return trip;
    });

    setSaveErrorText("");

    const firstTripInput = first(requestInput.trips);

    // ensure shuttle return stops have no dateTime
    const stopsInput = firstTripInput.stops.map((stop) => {
      if (tripType === TripCategory.ShuttlePickUp || stop.stopIndex === 1)
        return stop;

      return {
        ...stop,
        dateTime: null,
      };
    });

    createShuttleReservation({
      variables: {
        input: {
          tripType,
          orderType: requestInput.orderType,
          stops: stopsInput,
          route: firstTripInput.routes[0],
          tripComments: firstTripInput.comments,
          bookingContactId: bookingContact.id,
          shuttleRouteId: shuttleRoute.id,
          shouldSendEmail,
          stripePaymentMethod,
          cardNote,
        },
      },
    });
  };

  const handleTripCommentChange =
    (trip: Trip, tripIndex: number) => (value: string) => {
      updateTrip(
        {
          ...trip,
          comments: [{ bodyText: value }],
        },
        tripIndex
      );
    };

  const handleClearBookingContact = () => {
    setBookingContact(null);

    setHasPaymentMethod(false);

    // reset passenger when booking contact is cleared
    setRequest((prevRequest) => {
      const trip = prevRequest.trips[0];
      const tripContactId = trip.contact.id;
      const bookingContactId = bookingContact?.id;

      // if booking contact is same as first trip passenger,
      // clear passenger
      if (tripContactId === bookingContactId) {
        return {
          ...prevRequest,
          trips: [
            {
              ...trip,
              contact: {
                id: "",
                firstName: "",
                workAddress: "",
                homeAddress: "",
                lastName: "",
                email: "",
                mobilePhone: "",
              },
            },
          ],
        };
      }
      return prevRequest;
    });
  };

  const handleClearShuttleRoute = () => {
    setShuttleRoute(null);
  };

  const handleChangeCompany = (companyId: string) => {
    setCompanyId(companyId);
    handleClearBookingContact();
    handleClearShuttleRoute();
    if (companyId) {
      setRequestErrors({
        ...requestErrors,
        company: "",
      });
    }
  };

  // effects

  // if not Swoop redirect
  useEffect(() => {
    if (plan !== PlanVariant.Vip) {
      history.push({
        search: location.search,
        pathname: getCloseDrawerLink(),
      });
    }
  }, [plan, history, location]);

  // suggested addresses
  useEffect(() => {
    let keys = [];
    request.trips.forEach((trip) => {
      if (trip.contact.workAddress) {
        keys.push({
          address: trip.contact.workAddress,
          firstName: trip.contact.firstName,
          lastName: trip.contact.lastName,
          mode: "workAddress",
        });
      }
      if (trip.contact.homeAddress) {
        keys.push({
          address: trip.contact.homeAddress,
          firstName: trip.contact.firstName,
          lastName: trip.contact.lastName,
          mode: "homeAddress",
        });
      }
    });

    if (!isEqual(keys, suggestedAddressInfo)) {
      setSuggestedAddressInfo(keys);
    }
  }, [request, suggestedAddressInfo]);

  // populate initial request with data from pricingLayout
  useEffect(() => {
    setRequest((prevRequest) => {
      const trip = prevRequest.trips[0];

      return {
        ...prevRequest,
        trips: [
          {
            ...trip,
            routes: trip.routes.map((route: any) => {
              return {
                ...route,
                ...parsePricingLayoutForInitialRoute(settings?.pricingLayout),
              };
            }),
          },
        ],
      };
    });
  }, [settings?.pricingLayout]);

  // populate trip's passenger with booking contact
  // for new quotes/reservations
  useEffect(() => {
    if (bookingContact) {
      setRequest((prevRequest) => {
        const trip = prevRequest.trips[0];

        return {
          ...prevRequest,
          trips: [
            {
              ...trip,
              contact: bookingContact,
            },
          ],
        };
      });
    }
  }, [bookingContact]);

  return (
    <CreateDrawer
      onClose={handleCreateRequestDrawerClose}
      pageLabel={pageLabel}
      onSubmit={handleSubmitReservationClick}
      saveError={!!saveErrorText}
      saveErrorText={saveErrorText}
      submitLabelOptions={submitLabelOptions}
      selectedSubmitButtonIndex={selectedSubmitButtonIndex}
      setSelectedSubmitButtonIndex={setSelectedSubmitButtonIndex}
      submitDisabled={submitDisabled}
    >
      <Box alignItems="flex-start" data-testid="create-request-drawer">
        {/* Order Details */}
        <Box my={4}>
          <ShuttleOrderDetailsCreateBlock
            bookingContact={bookingContact}
            setBookingContact={setBookingContact}
            shuttleRoute={shuttleRoute}
            setShuttleRoute={setShuttleRoute}
            requestErrors={requestErrors}
            setRequestErrors={setRequestErrors}
            onClearBookingContact={handleClearBookingContact}
            onClearShuttleRoute={handleClearShuttleRoute}
            setHasPaymentMethod={setHasPaymentMethod}
            companyId={companyId}
            tripType={tripType}
            onTripTypeChange={onTripTypeChange}
            onCompanyChange={handleChangeCompany}
          />
        </Box>
        {request.trips.map((trip: Trip, tripIndex: number) => (
          <Fragment key={tripIndex}>
            {/* Trip Details */}
            {/* just using the header from trip details */}
            <Box mt={4}>
              <Typography variant="h5" style={{ fontWeight: 700 }}>
                Trip Details
              </Typography>
            </Box>
            <Box my={4}>
              <TripInfoCreateBlock
                trip={trip}
                updateTrip={updateTrip}
                tripIndex={tripIndex}
                onCreateStop={handleCreateStop(tripIndex)}
                onRemoveStop={handleRemoveStop(tripIndex)}
                requestErrors={requestErrors}
                setRequestErrors={setRequestErrors}
                suggestedAddressInfo={suggestedAddressInfo}
                tripType={tripType}
              />
            </Box>
            <Box my={4}>
              <AdditionalInfoCreateBlock
                isShuttleTrip
                trip={trip}
                updateTrip={updateTrip}
                tripIndex={tripIndex}
                requestType={request.orderType}
                setLuggageAmount={setLuggageAmount}
                luggageAmount={luggageAmount}
              />
            </Box>
            <AddVehicleCreateBlock
              requestErrors={requestErrors}
              addVehicles={addVehicles(tripIndex)}
              removeVehicle={removeVehicle(tripIndex)}
              vehicle={trip.routes[0].vehicle}
              stops={trip.stops}
              disableBRA
            />
            <PricingInfoCreateBlock
              // initialized request template uses deprecated route pricing (pricing field on route type instead of on route.pricing)
              routePricing={trip.routes[0] as unknown as RoutePricing}
              updateRoute={updateRoute(tripIndex)}
              requestErrors={requestErrors}
              setRequestErrors={setRequestErrors}
              totalDurationMinutes={calculateTotalDurationMinutes(trip.stops)}
              baseRateAmtError={requestErrors.baseRateAmt}
              childSeatOptions={childSeatOptions}
            />
            {!!bookingContact && !hasPaymentMethod && (
              <Box my={4}>
                <CreateCreditCardBlock
                  stripeElementsError={stripeElementsError}
                  onChange={handleCreditCardInputChange}
                  onDeleteCardElement={handleDeleteCardElement}
                  setHasEnteredCardInfo={setHasEnteredCardInfo}
                  billingDetailsElement={<BillingDetailsElement />}
                />
              </Box>
            )}
            <Box mt={7} mb={4}>
              <CommentCreateBlock
                commentBodyText={trip.comments[0].bodyText}
                onChangeComment={handleTripCommentChange(trip, tripIndex)}
              />
            </Box>
          </Fragment>
        ))}
      </Box>
    </CreateDrawer>
  );
}

export default CreateShuttleReservationDrawer;
