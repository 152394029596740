import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import startCase from "lodash/startCase";
import moment from "moment-timezone";
import omitBy from "lodash/omitBy";
import isUndefined from "lodash/isUndefined";
import isEmpty from "lodash/isEmpty";

import { Box, Divider, MenuItem, Typography } from "@mui/material";

import {
  LabeledInlineInput,
  LabeledInlineSelect,
  LabeledInlineDatePicker,
  LabeledInlineLocationAutocomplete,
  LabeledInlinePhoneNumberInput,
  LabeledInlineInternationalPhoneNumberInput,
} from "design-system/components/inputs";
import { Driver, UpdateDriverInput } from "types";
import { useAutoSave, useScreenSize } from "globals/hooks";
import { UPDATE_DRIVER_MUTATION } from "globals/graphql";
import statesList from "globals/utils/statesList";
import DriverPayoutForm from "../../DriverPayout/DriverPayoutForm";
import { FormVariant } from "../../DriverPayout/utils/types";
import { ImageUploadBlock } from "components/ImageUploadBlock";
import { formatPhoneNumber } from "globals/utils/phoneNumberFormatter/phoneNumberFormatter";
import { isValidPhoneNumber } from "globals/utils/helpers";

type UpdateDriverFormProps = {
  driver: Driver;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function UpdateDriverForm(props: UpdateDriverFormProps) {
  const { setSaveIndicatorState } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // state
  const [driver, setDriver, resetState] = useAutoSave<UpdateDriverInput>({
    mutation: UPDATE_DRIVER_MUTATION,
    name: "driver",
    setSaveIndicatorState,
    incomingState: {
      id: props.driver.id,
      firstName: props.driver.firstName,
      lastName: props.driver.lastName,
      mobilePhone: props.driver.mobilePhone,
      phoneCountryCode: props.driver.phoneCountryCode,
      phoneCountryDialCode: props.driver.phoneCountryDialCode,
      phoneCountryName: props.driver.phoneCountryName,
      phoneCountryFormat: props.driver.phoneCountryFormat,
      homePhone: props.driver.homePhone,
      homeAddress: props.driver.homeAddress,
      email: props.driver.email,
      licenseNumber: props.driver.licenseNumber,
      licenseClass: props.driver.licenseClass,
      licenseState: props.driver.licenseState,
      licenseExpirationDate: props.driver.licenseExpirationDate,
      note: props.driver.note,
      driverProfilePhoto: props.driver.driverProfilePhoto,
      settings: {
        hourlyTripHourlyRate: props.driver.settings?.hourlyTripHourlyRate,
        hourlyTripBaseRatePct: props.driver.settings?.hourlyTripBaseRatePct,
        hourlyTripLocationsVariant:
          props.driver.settings?.hourlyTripLocationsVariant,
        oneWayTripHourlyRate: props.driver.settings?.oneWayTripHourlyRate,
        oneWayTripMinimumHours: props.driver.settings?.oneWayTripMinimumHours,
        oneWayTripFlatRate: props.driver.settings?.oneWayTripFlatRate,
        oneWayTripBaseRatePct: props.driver.settings?.oneWayTripBaseRatePct,
        oneWayTripLocationsVariant:
          props.driver.settings?.oneWayTripLocationsVariant,
        gratuityPct: props.driver.settings?.gratuityPct,
      },
    },
  });

  const [licenseExpirationDateError, setLicenseExpirationDateError] =
    useState("");
  const [driverMobilePhoneError, setDriverMobilePhoneError] = useState("");

  // event handlers
  const handleDriverInfoChange = (key: string) => (event: any) => {
    let value;

    if (key === "licenseExpirationDate") {
      if (!moment(event).isValid()) {
        setLicenseExpirationDateError("Invalid Date");
        return;
      }
      setLicenseExpirationDateError("");
      value = moment(event).format("YYYY-MM-DD");
    } else if (key === "homeAddress") {
      value = event.description;
    } else {
      value = event.target.value;
    }

    setDriver({ ...driver, [key]: value });
  };

  const handleDriverPhotoUpdate = (driverProfilePhoto: string) => {
    setDriver({ ...driver, driverProfilePhoto });
  };

  const handleDriverPhoneInputChange = (value, country) => {
    const { formatted } =
      formatPhoneNumber(value, country.countryCode, country.dialCode) || {};

    if (country.countryCode === "us" && !isValidPhoneNumber(formatted)) {
      setDriverMobilePhoneError("Please enter valid number");
      return;
    }

    setDriver({
      ...driver,
      mobilePhone: formatted,
      phoneCountryCode: country.countryCode,
      phoneCountryDialCode: country.dialCode,
      phoneCountryName: country.name,
      phoneCountryFormat: country.format,
    });
    setDriverMobilePhoneError("");
  };

  // useEffect
  // if driver doesn't have driver settings, reset localState with incomingState
  useEffect(() => {
    if (props.driver.settings !== null) {
      if (isEmpty(omitBy(driver?.settings, isUndefined))) {
        resetState();
      }
    }
  }, [driver, props.driver.settings, resetState]);

  // derived state
  const driverName =
    driver &&
    [driver.firstName, driver.lastName]
      .map((word) => startCase(word))
      .join(" ");

  // wait for useAutoSave to initialize
  if (!driver) return null;

  return (
    <Box>
      <Box
        mt={3}
        mb={4}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <ImageUploadBlock
          variant="driver"
          placeholder={[driver.firstName, driver.lastName]}
          photoUrl={driver?.driverProfilePhoto}
          onPhotoUrlUpdate={handleDriverPhotoUpdate}
          mode="update"
        />
        <Box mt={2}>
          <Typography variant="h5">{driverName}</Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1">{driver.mobilePhone}</Typography>
        </Box>
      </Box>
      <Divider />

      {/* Contact Details */}
      <Box mt={4} mb={2}>
        <Typography variant="h5">Contact Details</Typography>
      </Box>

      <LabeledInlineInput
        required
        errorText="First name required"
        label="First Name"
        value={driver.firstName}
        onChange={handleDriverInfoChange("firstName")}
      />
      <LabeledInlineInput
        label="Last Name"
        value={driver.lastName}
        onChange={handleDriverInfoChange("lastName")}
      />
      <LabeledInlineInternationalPhoneNumberInput
        label="Mobile Phone"
        name="mobilePhone"
        value={props.driver ? props.driver.mobilePhoneInternational : ""}
        onChange={handleDriverPhoneInputChange}
        dropdownWidth={isMobileView ? "400%" : "900%"}
        error={driverMobilePhoneError}
        errorMessage="Please enter valid number"
      />
      <LabeledInlinePhoneNumberInput
        label="Home Phone"
        errorText="Enter valid phone #"
        value={driver.homePhone || ""}
        type="phoneNumber"
        onChange={handleDriverInfoChange("homePhone")}
      />
      <LabeledInlineInput
        label="Email Address"
        value={driver.email}
        onChange={handleDriverInfoChange("email")}
      />
      <LabeledInlineLocationAutocomplete
        label="Home Address"
        value={driver.homeAddress}
        onChange={handleDriverInfoChange("homeAddress")}
      />

      {/* Additional Details */}
      <Box mt={4} mb={2}>
        <Typography variant="h5">Additional Details</Typography>
      </Box>
      <LabeledInlineInput
        label="DL Number"
        value={driver.licenseNumber}
        onChange={handleDriverInfoChange("licenseNumber")}
      />
      <LabeledInlineInput
        label="DL Class"
        value={driver.licenseClass}
        onChange={handleDriverInfoChange("licenseClass")}
      />
      <LabeledInlineSelect
        label="DL State"
        value={driver.licenseState || ""}
        onChange={handleDriverInfoChange("licenseState")}
      >
        {statesList.map((state) => (
          <MenuItem key={state} value={state}>
            {state}
          </MenuItem>
        ))}
      </LabeledInlineSelect>
      <LabeledInlineDatePicker
        label="Expiration Date"
        value={driver.licenseExpirationDate}
        onChange={handleDriverInfoChange("licenseExpirationDate")}
        errorText={licenseExpirationDateError}
        error={!!licenseExpirationDateError}
      />
      <LabeledInlineInput
        inputProps={{ maxLength: 3000 }}
        multiline
        label="Driver Notes"
        value={driver.note}
        onChange={handleDriverInfoChange("note")}
      />
      <Box mt={4} mb={2}>
        <Typography variant="h4">Driver Payout Details</Typography>
      </Box>
      <Box mt={2}>
        <DriverPayoutForm
          driver={driver}
          setDriver={setDriver}
          variant={FormVariant.UPDATE_DRIVER}
        />
      </Box>
    </Box>
  );
}

export default UpdateDriverForm;
