import React from "react";

import { Box, Typography, MenuItem } from "@mui/material";

import { SearchResult } from "types";
import { ClipboardIcon, UserIcon, CompanyIcon } from "design-system/icons";
import { grayDark, grayLight } from "design-system/colors";
import { SearchResultTypename } from "../../../types";

const { Request, Contact, Company } = SearchResultTypename;

type GlobalSearchResultListItemProps = {
  searchResult: SearchResult;
  searchTerm?: string;
  onClick: (searchResult: SearchResult) => void;
  isSelected?: boolean;
};

function GlobalSearchResultListItem(props: GlobalSearchResultListItemProps) {
  const { searchResult, onClick, isSelected } = props;

  let searchTerm = props.searchTerm;

  if (!searchTerm) {
    if (searchResult.__typename === Request) {
      searchTerm = searchResult.orderNumber;
    }

    if (searchResult.__typename === Contact) {
      searchTerm = `${searchResult.firstName} ${searchResult.lastName}`;
    }

    if (searchResult.__typename === Company) {
      searchTerm = `${searchResult.name}`;
    }
  }

  const icon = {
    [Request]: <ClipboardIcon color={grayDark} size="small" />,
    [Contact]: <UserIcon color={grayDark} size="small" />,
    [Company]: <CompanyIcon color={grayDark} size="small" />,
  }[searchResult.__typename];

  return (
    <MenuItem
      onClick={() => onClick(searchResult)}
      selected={isSelected}
      sx={{
        borderRadius: "4px",
        "&:hover": {
          backgroundColor: grayLight,
        },
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        {icon}
        <Box ml={0.9}>
          <Typography style={{ fontWeight: 500 }} variant="body2">
            {searchTerm}
          </Typography>
        </Box>
      </Box>
    </MenuItem>
  );
}
export default GlobalSearchResultListItem;
