import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Box, Typography, Divider, Button, Hidden } from "@mui/material";

import { PlusIcon } from "design-system/icons";
import { white } from "design-system/colors";
import SquareButton from "design-system/SquareButton";
import {
  useScreenSize,
  useOperator,
  useAnalytics,
  useCurrentUser,
} from "globals/hooks";
import {
  containerStyles,
  headerStyles,
  listStyles,
  tableStyles,
} from "../utils/styles";
import { MembersList } from "./components";

function MembersSettingsPage() {
  // hooks
  const { isSmallTabletView } = useScreenSize();
  const { users } = useOperator() || {};
  const { track } = useAnalytics();
  const currentUser = useCurrentUser();

  // event handlers
  const handleCreateClick = () => track("members_createInitiated");

  return (
    <>
      <Helmet>
        <title>Members | Moovs</title>
      </Helmet>
      <Box sx={containerStyles}>
        <Box sx={headerStyles}>
          <Typography variant="h2">Members</Typography>
          {currentUser?.role.slug !== "owner" ? null : isSmallTabletView ? (
            <SquareButton
              disableElevation={true}
              variant="contained"
              color="primary"
              component={Link}
              to="/settings/members/create"
              onClick={handleCreateClick}
            >
              <PlusIcon color={white} />
            </SquareButton>
          ) : (
            <Button
              disableElevation={true}
              variant="contained"
              color="primary"
              size="large"
              style={{ whiteSpace: "nowrap", minHeight: 40, minWidth: 40 }}
              component={Link}
              to="/settings/members/create"
              onClick={handleCreateClick}
            >
              Create Member
            </Button>
          )}
        </Box>
        <Hidden mdDown>
          <Box px={3}>
            <Divider />
          </Box>
        </Hidden>
        <Box {...(!isSmallTabletView && { sx: tableStyles })}>
          {users && <MembersList users={users} sxStyles={listStyles} />}
        </Box>
      </Box>
    </>
  );
}

export default MembersSettingsPage;
