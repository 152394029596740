import React, { useState } from "react";
import moment from "moment-timezone";
import capitalize from "lodash/capitalize";

import { Box, Typography, IconButton, Card, Collapse } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import {
  grayDark,
  errorRed,
  warningYellow,
  paymentStatusLabelColors,
} from "design-system/colors";
import RoundChip from "design-system/components/chips/RoundChip";
import { PaymentMethodEnum, PaymentPayer, PaymentPaymentStatus } from "types";
import {
  TransactionDetailsProps,
  riskEvaluationStatuses,
} from "./TransactionListItem.desktop";
import { CreditCardIcon, WarningTriangleIcon } from "design-system/icons";
import MoovsTooltip from "components/MoovsTooltip";
import MoovsEllipsisMenu from "components/MoovsEllipsisMenu";
import TransactionBreakdown from "./TransactionBreakdown";

function TransactionListItemMobile(props: TransactionDetailsProps) {
  const {
    payment,
    refund,
    amount,
    status,
    riskEvaluation,
    cardHasElevatedRisk,
    transactionEllipsesOptions,
    riskEvaluationTooltipText,
    method,
    paymentMethod,
    includePaymentMethod,
  } = props;

  // derived state
  const isAuthorizationPayment =
    payment.paymentPayer === PaymentPayer.Authorization;

  // state
  const [open, setOpen] = useState(false);

  return (
    <Card variant="outlined">
      <Box px={1} py={2}>
        <Box display="flex" flexDirection="row">
          {/* Caret */}
          <Box>
            {!isAuthorizationPayment && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </Box>

          {/* Transaction Info */}
          <Box display="flex" flexDirection="column" flexGrow={1} ml={2} mt={1}>
            {/* Amount */}
            <Box display="flex" flexDirection="column" mb={1} pb={1}>
              <Typography variant="overline" mb={0.5}>
                Amount
              </Typography>
              <Typography
                variant="body2"
                color={!payment.didPaymentSucceed && grayDark}
              >
                {amount}
              </Typography>
              {/* Expanding Section */}
              <Box mt={1}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <TransactionBreakdown
                    subItems={refund ? refund.subRefunds : payment.subPayments}
                    includePaymentMethod={includePaymentMethod}
                  />
                </Collapse>
              </Box>
            </Box>

            {/* Date */}
            <Box display="flex" flexDirection="column" mb={1} pb={1}>
              <Typography variant="overline" mb={0.5}>
                Date
              </Typography>
              <Typography
                variant="body2"
                color={!payment.didPaymentSucceed && grayDark}
              >
                {moment(payment.createdAt || refund.createdAt).format(
                  "MM/DD/YY h:mm a"
                )}
              </Typography>
            </Box>

            {/* Status */}
            <Box display="flex" flexDirection="column" mb={1} pb={1}>
              <Typography variant="overline" mb={0.5}>
                Status
              </Typography>
              <Typography variant="body2">
                <MoovsTooltip
                  tooltipText={payment.paymentStatusTooltip}
                  hidden={
                    !payment.paymentStatusTooltip ||
                    status === PaymentPaymentStatus.Succeeded
                  }
                >
                  <RoundChip {...paymentStatusLabelColors[status]} />
                </MoovsTooltip>
              </Typography>
            </Box>

            {/* Risk Evaluation */}
            <Box display="flex" flexDirection="column" mb={1} pb={1}>
              <Typography variant="overline" mb={0.5}>
                Risk Evaluation
              </Typography>
              <Box display="flex">
                <MoovsTooltip
                  tooltipText={riskEvaluationTooltipText}
                  hidden={
                    riskEvaluation === "normal" || !riskEvaluationTooltipText
                  }
                >
                  <Box display="flex">
                    <Typography variant="body2" pr={1}>
                      {riskEvaluationStatuses[riskEvaluation] || "N/A"}
                    </Typography>
                    {cardHasElevatedRisk && (
                      <WarningTriangleIcon
                        color={
                          riskEvaluation === "highest"
                            ? errorRed
                            : warningYellow
                        }
                      />
                    )}
                  </Box>
                </MoovsTooltip>
              </Box>
            </Box>

            {/* Method */}
            {includePaymentMethod && (
              <Box display="flex" flexDirection="column" mb={1} pb={1}>
                <Typography variant="overline" mb={0.5}>
                  Method
                </Typography>
                {method === PaymentMethodEnum.Card ? (
                  <Box display="flex" flexDirection="row" gap={1}>
                    <CreditCardIcon brand={paymentMethod?.card?.brand} />
                    <Typography variant="body2">{`**** ${paymentMethod?.card?.last4}`}</Typography>
                  </Box>
                ) : (
                  <Typography variant="body2">
                    {capitalize(payment.method)}
                  </Typography>
                )}
              </Box>
            )}
          </Box>

          {/* Ellipses */}
          <Box mr={1}>
            <MoovsEllipsisMenu
              size="small"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              options={transactionEllipsesOptions}
            />
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default TransactionListItemMobile;
