import React from "react";
import { InlineInput } from "./InlineInputs";
import { NumberFormatDollar } from "../NumberFormat";

type DollarInputInlineProps = {
  errorText?: string;
  name?: string;
  value?: any;
  onChange?: any;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  inputProps?: any;
  error?: boolean;
};

function DollarInlineInput(props: DollarInputInlineProps) {
  const {
    value,
    onChange,
    errorText,
    placeholder,
    required,
    name,
    disabled,
    inputProps,
    error,
  } = props;

  return (
    <InlineInput
      name={name}
      placeholder={placeholder}
      required={required}
      errorText={errorText}
      disabled={disabled}
      value={value}
      onChange={onChange}
      InputProps={{
        inputComponent: NumberFormatDollar as any,
        inputProps,
      }}
      error={error}
    />
  );
}

export { DollarInlineInput };
