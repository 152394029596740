/**
 * Component for MUI Multi-Select Searchable Input.
 *
 * InputProps:
 * 1. fieldName - Used for tooltip draw in mobile view.
 * 2. options - List of options in the dropdown.
 * 3. selection: currently selected options;
 * 4. setSelection: handle selection change;
 * 5. placeholder? - Placeholder for input
 * 6. disabled? - Disables all options, but allow dropdown
 *
 */

import React, { useState, forwardRef } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { grayDark, moovsBlue, white } from "design-system/colors";
import MoovsTooltipBottomListDrawer from "components/MoovsTooltip/MoovsTooltipBottomListDrawer";
import { useScreenSize } from "globals/hooks";

type MoovsSearchableCheckboxDropdownProps = {
  fieldName: string;
  options: { id: string; name: string }[];
  selection: { id: string; name: string }[];
  setSelection: (newValue) => void;
  placeholder?: string;
  disabled?: boolean;
  tooltipText?: string;
  handleDelete?: (item) => void;
};

function MoovsSearchableCheckboxDropdown(
  props: MoovsSearchableCheckboxDropdownProps,
  ref
) {
  const {
    fieldName,
    options,
    selection,
    setSelection,
    placeholder,
    disabled = false,
    tooltipText,
    handleDelete,
  } = props;

  const [tooltipDrawerOpen, setTooltipDrawerOpen] = useState(false);

  const { isMobileView } = useScreenSize();

  // event handler
  const handleChange = (_, value, reason, details) => {
    if (handleDelete && reason === "removeOption") {
      handleDelete(details.option);
    } else {
      setSelection(value);
    }
  };

  return (
    <Box width="100%">
      <FormControl fullWidth>
        <Autocomplete
          multiple
          disableCloseOnSelect
          disableClearable
          disablePortal
          options={options}
          value={selection}
          onChange={handleChange}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionDisabled={() => disabled}
          // limit tags are applied on blur (default MUI interaction)
          limitTags={2}
          getLimitTagsText={(count) => {
            // have to use forwardRef to be used with Tooltip component (https://mui.com/material-ui/guides/composition/#caveat-with-refs)
            const LimitTag = forwardRef((props, ref) => (
              <Box
                {...props}
                ref={ref}
                sx={{
                  backgroundColor: grayDark,
                  borderRadius: "16px",
                  padding: "4px",
                  minWidth: "24px",
                  height: "24px",
                }}
              >
                <Typography color={white} fontSize="12px" textAlign="center">
                  {count}
                </Typography>
              </Box>
            ));

            return isMobileView ? (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setTooltipDrawerOpen(true);
                }}
                sx={{ p: 0, minWidth: "24px" }}
              >
                <LimitTag />
              </Button>
            ) : (
              <Tooltip
                title={
                  <Box>
                    {selection
                      .map((item) => <Box key={item.id}>{item.name}</Box>)
                      // removed first 2 selection
                      .slice(2)}
                  </Box>
                }
              >
                <LimitTag />
              </Tooltip>
            );
          }}
          renderOption={(props, option, { selected }) => {
            const content = (
              <span>
                <li {...props} key={option.id}>
                  <Checkbox
                    checked={selected}
                    sx={{ py: 0, color: disabled ? grayDark : moovsBlue }}
                  />
                  <Typography variant="body2">{option.name}</Typography>
                </li>
              </span>
            );

            if (!disabled || !tooltipText) return content;

            return <Tooltip title={tooltipText}>{content}</Tooltip>;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              {...(!selection.length && {
                placeholder: placeholder || "Select..",
              })}
            />
          )}
        />
      </FormControl>

      <MoovsTooltipBottomListDrawer
        onSetDrawerOpen={setTooltipDrawerOpen}
        drawerOpen={tooltipDrawerOpen}
        list={selection.map((x) => x.name)}
        title={fieldName}
      />
    </Box>
  );
}

export default MoovsSearchableCheckboxDropdown;
