import React from "react";

import { Cell } from "../../utils/types";
import { CheckCircleIcon, MinusIcon } from "design-system/icons";
import { aqua, black, moovsBlue, purple } from "design-system/colors";

const row1: Cell[] = [
  {
    column: 1,
    typographyText: "Webinars",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <CheckCircleIcon size="small" color={black} />,
  },
  {
    column: 3,
    icon: <CheckCircleIcon size="small" color={moovsBlue} />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row2: Cell[] = [
  {
    column: 1,
    typographyText: "Live Chat Support",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <CheckCircleIcon size="small" color={black} />,
  },
  {
    column: 3,
    icon: <CheckCircleIcon size="small" color={moovsBlue} />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row3: Cell[] = [
  {
    column: 1,
    typographyText: "Email Support",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <CheckCircleIcon size="small" color={black} />,
  },
  {
    column: 3,
    icon: <CheckCircleIcon size="small" color={moovsBlue} />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row4: Cell[] = [
  {
    column: 1,
    typographyText: "Phone Support",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 3,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row5: Cell[] = [
  {
    column: 1,
    typographyText: "Dedicated Customer Success Manager",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 3,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row6: Cell[] = [
  {
    column: 1,
    typographyText: "Data Migration Assistance",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 3,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row7: Cell[] = [
  {
    column: 1,
    typographyText: "Optional 24/7 Emergency Support",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 3,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 4,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const chartData = [row1, row2, row3, row4, row5, row6, row7];

export default chartData;
