import React from "react";

import { Box, Typography } from "@mui/material";

const styles = {
  rowTitle: {
    fontWeight: 600,
  },
  columnTitle: {
    fontWeight: 500,
  },
};

type TripTrackerDesktopHeaderProps = {
  isFarmedRoute: boolean;
};

const TripTrackerDesktopHeader = (props: TripTrackerDesktopHeaderProps) => {
  const { isFarmedRoute } = props;

  return (
    <Box display="flex" flex="1" flexDirection="row" mb={1.5} mt={3} ml={2}>
      <Box flex="1" />
      <Box flex="1">
        <Typography variant="overline" sx={styles.columnTitle}>
          Customer
        </Typography>
      </Box>
      <Box flex="1">
        <Typography variant="overline" sx={styles.columnTitle}>
          Driver
        </Typography>
      </Box>
      {isFarmedRoute && (
        <Box flex="1">
          <Typography variant="overline" sx={styles.columnTitle}>
            Affiliate
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TripTrackerDesktopHeader;
