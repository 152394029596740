import React, { ChangeEvent, MouseEvent } from "react";
import { Link } from "react-router-dom";
import kebabCase from "lodash/kebabCase";
import camelCase from "lodash/camelCase";

import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  useTheme,
  Divider,
} from "@mui/material";

import SquareButton from "../../design-system/SquareButton";
import { black, white, moovsBlue, grayLight } from "../../design-system/colors";
import { DashboardIcon, PlusIcon, SearchIcon } from "../../design-system/icons";
import DispatchPageViewToggle from "components/dispatch/DispatchPageViewToggle";
import { useScreenSize } from "../../globals/hooks/useScreenSize";
import DownloadButton from "components/buttons/DownloadButton";
import upperFirst from "lodash/upperFirst";
import MoovsTooltip from "components/MoovsTooltip";
import { MoovsTooltipBaseProps } from "components/MoovsTooltip/MoovsTooltip";

const styles = {
  defaultListView: {
    maxWidth: 900,
    marginLeft: "auto",
    marginRight: "auto",
  },
  defaultSchedulerView: {
    maxWidth: "100%",
    marginLeft: "80px",
    marginRight: "80px",
    borderBottom: `1px solid ${grayLight}`,
  },
  tabletSchedulerView: {
    maxWidth: "100%",
    marginLeft: "30px",
    marginRight: "30px",
    borderBottom: `1px solid ${grayLight}`,
  },
  mobileDispatchView: {
    maxWidth: 900,
    marginLeft: "0px",
    marginRight: "0px",
    borderBottom: "none",
  },
};

type EntityHeaderProps = {
  title: string;
  titleVariant?: "h1" | "h2";
  entityName?: string;
  createEntityRoute?: string;
  onGlobalSearchFocus?: any;
  onGlobalSearchChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  globalSearch?: string;
  createButtonMode?: "button" | "link";
  onCreateButtonClick?: (event: MouseEvent<HTMLDivElement>) => void;
  dateFilter?: any;
  appointmentsFilter?: any;
  onPageViewClick?: (_, newType: "list" | "scheduler") => void;
  pageViewValue?: "list" | "scheduler";
  reportName?: "contacts";
  routerButtonText?: string;
  onRouterButtonClick?: () => void;
  createEntityDisabled?: boolean;
  createEntityTooltipProps?: MoovsTooltipBaseProps;
};

function EntityHeader(props: EntityHeaderProps) {
  const {
    title,
    titleVariant = "h1",
    entityName,
    createEntityRoute,
    onGlobalSearchFocus,
    onGlobalSearchChange,
    globalSearch,
    createButtonMode,
    onCreateButtonClick,
    dateFilter,
    onPageViewClick,
    pageViewValue,
    appointmentsFilter,
    reportName,
    routerButtonText,
    onRouterButtonClick,
    createEntityDisabled,
    createEntityTooltipProps,
  } = props;

  // hooks
  const theme = useTheme();
  const { isMobileView, isSmallTabletView } = useScreenSize();

  return (
    <Box
      maxWidth={900}
      m="auto"
      {...(!isMobileView &&
        pageViewValue === "list" && {
          sx: styles.defaultListView,
        })}
      {...(!isMobileView &&
        !isSmallTabletView &&
        pageViewValue === "scheduler" && {
          sx: styles.defaultSchedulerView,
        })}
      {...(!isMobileView &&
        isSmallTabletView &&
        pageViewValue === "scheduler" && {
          sx: styles.tabletSchedulerView,
        })}
      {...(isMobileView &&
        (pageViewValue === "scheduler" || pageViewValue === "list") && {
          sx: styles.mobileDispatchView,
        })}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        padding={
          isMobileView
            ? 2
            : pageViewValue === "scheduler"
            ? `${theme.spacing(2)} 0px ${theme.spacing(0.75)} 0px`
            : `${theme.spacing(2)} ${theme.spacing(3)}`
        }
      >
        {/* left aligned */}
        <Box display="flex">
          <Box
            mr={2}
            {...(pageViewValue === "scheduler" &&
              !isMobileView && { mt: -1.5 })}
          >
            <Typography variant={titleVariant} style={{ color: black }}>
              {title}
            </Typography>
          </Box>
          {pageViewValue !== "scheduler" && !isMobileView && dateFilter}
        </Box>

        {/* right aligned */}
        <Box display="flex" justifyContent="flex-end" ml={1}>
          {!!onGlobalSearchChange && !isMobileView && (
            <TextField
              fullWidth
              size="small"
              label="Search"
              value={globalSearch}
              variant="outlined"
              onFocus={onGlobalSearchFocus}
              onChange={onGlobalSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color={moovsBlue} />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                "data-testid": "global-search-input",
              }}
            />
          )}

          {(createEntityRoute || onCreateButtonClick) && (
            <Box
              ml={isMobileView ? 1 : 2}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              {reportName && isMobileView && (
                <Box display="flex" alignItems="center" mr={2} mt={1}>
                  <DownloadButton
                    buttonText="Download Report"
                    endpoint={`/reports/${kebabCase(reportName)}/download`}
                    analyticsName={`${camelCase(
                      reportName
                    )}_reporting${upperFirst(camelCase(reportName))}`}
                  />
                </Box>
              )}
              <MoovsTooltip
                hidden={!createEntityTooltipProps || !createEntityDisabled}
                {...createEntityTooltipProps}
              >
                {isMobileView ? (
                  <SquareButton
                    disableElevation={true}
                    disabled={createEntityDisabled}
                    variant="contained"
                    color="primary"
                    component={createButtonMode === "link" ? Link : "div"}
                    {...(createButtonMode === "link" && {
                      to: createEntityRoute,
                    })}
                    {...(onCreateButtonClick && {
                      onClick: onCreateButtonClick,
                    })}
                  >
                    <PlusIcon color={white} />
                  </SquareButton>
                ) : (
                  <Button
                    disableElevation={true}
                    disabled={createEntityDisabled}
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{
                      whiteSpace: "nowrap",
                      minHeight: 40,
                      minWidth: 40,
                    }}
                    component={createButtonMode === "link" ? Link : "div"}
                    {...(createButtonMode === "link" && {
                      to: createEntityRoute,
                    })}
                    {...(onCreateButtonClick && {
                      onClick: onCreateButtonClick,
                    })}
                  >
                    Create {entityName}
                  </Button>
                )}
              </MoovsTooltip>
            </Box>
          )}
          {pageViewValue === "scheduler" && !isMobileView && appointmentsFilter}

          {onPageViewClick && (
            <DispatchPageViewToggle
              onChange={onPageViewClick}
              value={pageViewValue}
            />
          )}

          {onRouterButtonClick && (
            <Button
              onClick={onRouterButtonClick}
              endIcon={<DashboardIcon color={moovsBlue} />}
              sx={{
                ml: 3,
                border: `1px solid ${moovsBlue}`,
                padding: "8px",
                ...(isMobileView && {
                  minWidth: 40,
                  minHeight: 40,
                  padding: 0,
                  "&& .MuiButton-endIcon": { marginRight: 0, marginLeft: 0 },
                }),
              }}
            >
              {routerButtonText}
            </Button>
          )}
        </Box>
      </Box>

      {/* second row */}
      {pageViewValue !== "scheduler" && isMobileView && (
        <>
          {dateFilter}
          <Divider />
        </>
      )}
      {!!onGlobalSearchChange && isMobileView && (
        <Box px={2} py={2}>
          <TextField
            fullWidth
            size="small"
            label="Search"
            value={globalSearch}
            variant="outlined"
            onChange={onGlobalSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color={moovsBlue} />
                </InputAdornment>
              ),
            }}
            inputProps={{
              "data-testid": "global-search-input",
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default EntityHeader;
