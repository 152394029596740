import React from "react";

import { Typography } from "@mui/material";

import { green, moovsBlue, moovsBlueLight } from "design-system/colors";
import { MetricsProgressBar } from "pages/settings/BillingPage/components/CurrentPlanInfoCard/components";

type TaskProgressBarProps = {
  tasksRemaining: number;
};

function TaskProgressBar(props: TaskProgressBarProps) {
  const { tasksRemaining } = props;

  return (
    <MetricsProgressBar
      count={5 - tasksRemaining}
      total={5}
      unit="steps"
      progressHeight="8px"
      textColor={!tasksRemaining ? green : moovsBlue}
      progressColor={!tasksRemaining ? green : moovsBlue}
      additionalProgressBarTextComponent={
        !tasksRemaining ? null : (
          <Typography variant="caption" color={moovsBlueLight}>
            {`About ${tasksRemaining} minute${tasksRemaining === 1 ? "" : "s"}`}
          </Typography>
        )
      }
    />
  );
}

export default TaskProgressBar;
