import React from "react";

type CopyIconProps = {
  color?: string;
  size?: "small" | "xs";
};

const CopyIcon = (props: CopyIconProps) => {
  const { color, size } = props;

  if (size === "xs") {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.8 8.8H2.2C1.88174 8.8 1.57652 8.67357 1.35147 8.44853C1.12643 8.22348 1 7.91826 1 7.6V2.2C1 1.88174 1.12643 1.57652 1.35147 1.35147C1.57652 1.12643 1.88174 1 2.2 1H7.6C7.91826 1 8.22348 1.12643 8.44853 1.35147C8.67357 1.57652 8.8 1.88174 8.8 2.2V2.8M6.4 5.2H11.8C12.4627 5.2 13 5.73726 13 6.4V11.8C13 12.4627 12.4627 13 11.8 13H6.4C5.73726 13 5.2 12.4627 5.2 11.8V6.4C5.2 5.73726 5.73726 5.2 6.4 5.2Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.1 10.1H2.4C2.0287 10.1 1.6726 9.9525 1.41005 9.68995C1.1475 9.4274 1 9.0713 1 8.7V2.4C1 2.0287 1.1475 1.6726 1.41005 1.41005C1.6726 1.1475 2.0287 1 2.4 1H8.7C9.0713 1 9.4274 1.1475 9.68995 1.41005C9.9525 1.6726 10.1 2.0287 10.1 2.4V3.1M7.3 5.9H13.6C14.3732 5.9 15 6.5268 15 7.3V13.6C15 14.3732 14.3732 15 13.6 15H7.3C6.5268 15 5.9 14.3732 5.9 13.6V7.3C5.9 6.5268 6.5268 5.9 7.3 5.9Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5M11 9H20C21.1046 9 22 9.89543 22 11V20C22 21.1046 21.1046 22 20 22H11C9.89543 22 9 21.1046 9 20V11C9 9.89543 9.89543 9 11 9Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopyIcon;
