import React, { Dispatch, SetStateAction } from "react";

import { Box, Divider } from "@mui/material";

import PricingCustomizationBlock from "../../../../components/settings/general/PricingBlock/PricingCustomizationBlock";
import { Operator } from "../../../../types";
import OrderTypesBlock from "../../../../components/settings/OrderTypesBlock/OrderTypesBlock";
import DriverAppAmountDueBlock from "components/settings/general/DriverAppAmountDueBlock";
import LuggageToggleBlock from "components/settings/general/LuggageToggleBlock";
import StopPriceSettingBlock from "components/settings/general/StopPriceSettingBlock";

type PreferencesTabProps = {
  operator: Operator;
  onOperatorPricingLayoutChange: (key: string) => void;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function PreferencesTab(props: PreferencesTabProps) {
  const { operator, onOperatorPricingLayoutChange, setSaveIndicatorState } =
    props;

  const pricingLayout = { ...operator.settings.pricingLayout };
  delete pricingLayout.meetGreetAmtEnabled;

  return (
    <>
      {/* Pricing Customization */}
      <Box mb={4}>
        <PricingCustomizationBlock
          pricingLayout={pricingLayout}
          onOperatorPricingLayoutChange={onOperatorPricingLayoutChange}
        />
      </Box>

      <Divider />

      {/* Preference Toggles */}
      <Box>
        {/* Price per stop */}
        <Box my={2}>
          <StopPriceSettingBlock
            setSaveIndicatorState={setSaveIndicatorState}
          />
        </Box>

        <Divider />

        {/* Driver App Amount Due Toggle */}
        <Box my={2}>
          <DriverAppAmountDueBlock
            setSaveIndicatorState={setSaveIndicatorState}
          />
        </Box>

        <Divider />

        {/* Enable luggage on orders toggle */}
        <Box my={2}>
          <LuggageToggleBlock setSaveIndicatorState={setSaveIndicatorState} />
        </Box>
      </Box>

      <Divider />

      {/* Order Types */}
      <Box my={4}>
        <OrderTypesBlock setSaveIndicatorState={setSaveIndicatorState} />
      </Box>
    </>
  );
}

export default PreferencesTab;
