import React from "react";

type EmptyDatabaseWarningProps = {
  color?: string;
};

const EmptyDatabaseWarningIcon = (props: EmptyDatabaseWarningProps) => {
  const { color } = props;
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 35C29.8022 35 29.6089 35.0587 29.4444 35.1685C29.28 35.2784 29.1518 35.4346 29.0761 35.6173C29.0004 35.8 28.9806 36.0011 29.0192 36.1951C29.0578 36.3891 29.153 36.5673 29.2929 36.7071C29.4327 36.847 29.6109 36.9422 29.8049 36.9808C29.9989 37.0194 30.2 36.9996 30.3827 36.9239C30.5654 36.8482 30.7216 36.72 30.8315 36.5556C30.9413 36.3911 31 36.1978 31 36C31 35.7348 30.8946 35.4804 30.7071 35.2929C30.5196 35.1054 30.2652 35 30 35Z"
        fill={color || "#195FE9"}
      />
      <path
        d="M33.5 8.50163C33.5 12.1835 26.4112 15.1683 17.6667 15.1683C8.92217 15.1683 1.83334 12.1835 1.83334 8.50163M33.5 8.50163C33.5 4.81973 26.4112 1.83496 17.6667 1.83496C8.92217 1.83496 1.83334 4.81973 1.83334 8.50163M33.5 8.50163V17.6683M1.83334 8.50163V30.1666C1.83334 33.55 7.81334 36.3433 15.5617 36.775M21.6467 21.63C11.1817 22.7033 1.83334 19.3966 1.83334 15.1666M17.6667 29.3333C8.92334 29.3333 1.83334 26.3516 1.83334 22.6666M30 33V28M31.3966 21.8302C31.2615 21.5794 31.0602 21.3697 30.8142 21.2234C30.5683 21.0772 30.2868 21 30 21C29.7132 21 29.4318 21.0772 29.1858 21.2234C28.9398 21.3697 28.7385 21.5794 28.6034 21.8302L20.177 37.748C20.0525 37.9824 19.9918 38.245 20.0009 38.5098C20.01 38.7746 20.0885 39.0325 20.2287 39.2581C20.3703 39.4853 20.5683 39.6727 20.8037 39.8026C21.0392 39.9324 21.3042 40.0004 21.5736 40H38.4264C38.6956 40.0004 38.9605 39.9326 39.1959 39.8031C39.4313 39.6735 39.6294 39.4865 39.7714 39.2598C39.9116 39.0342 39.9901 38.7763 39.9991 38.5115C40.0082 38.2467 39.9475 37.9841 39.823 37.7496L31.3966 21.8302Z"
        stroke={color || "#195FE9"}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmptyDatabaseWarningIcon;
