import React from "react";

import { Box, Typography } from "@mui/material";

import { NumberFormatDisplay } from "design-system/components/inputs/NumberFormat";

function DriverPayoutSubtotalBox(props: {
  amount: number | string;
  isBold?: boolean;
}) {
  const { amount, isBold } = props;
  return (
    <Box
      ml={2}
      display="flex"
      alignItems="center"
      width="60px"
      justifyContent="flex-end"
    >
      <Typography align="right" variant={isBold ? "subtitle2" : "body2"}>
        <NumberFormatDisplay value={amount || 0} />
      </Typography>
    </Box>
  );
}

export default DriverPayoutSubtotalBox;
