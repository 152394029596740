import React from "react";

import {
  Box,
  Divider,
  FormControl,
  FormGroup,
  FormLabel,
  Tooltip,
} from "@mui/material";

import { InfoIcon } from "design-system/icons";
import { black } from "design-system/colors";
import { VehicleChildSeat } from "types";
import ChildSeatItem from "./components/ChildSeatItem";
import MoovsLightbox from "components/MoovsLightbox";
import useVehicleChildSeatLightboxProps from "./hooks/useVehicleChildSeatLightboxProps";

const CHILD_SEAT_LABEL_TOOLTIP =
  "Seats selected here will show up on the customer portal. Booking contacts can add them when making a reservation, and the price will be adjusted accordingly.";

type VehicleChildSeatsProp = {
  childSeats: {
    forwardFacingSeat: VehicleChildSeat;
    rearFacingSeat: VehicleChildSeat;
    boosterSeat: VehicleChildSeat;
  };
  handleVehicleChildSeatChange: (
    childSeatName: "forwardFacingSeat" | "rearFacingSeat" | "boosterSeat"
  ) => (childSeat: VehicleChildSeat) => void;
};

function VehicleChildSeats(props: VehicleChildSeatsProp) {
  const { childSeats, handleVehicleChildSeatChange } = props;

  // hooks
  const [lightboxProps, openImageInLightbox] =
    useVehicleChildSeatLightboxProps();

  // event handlers
  const handleOpenImageInLightbox = (label: string) => (url: string) => {
    openImageInLightbox(url, label);
  };

  return (
    <Box>
      <FormControl sx={{ width: "100%" }}>
        <Box pt={1} pb={1}>
          <Box display="flex" alignItems="center" mb={2}>
            <FormLabel style={{ fontSize: 12 }}>CHILD SEATS</FormLabel>
            <Tooltip
              title={CHILD_SEAT_LABEL_TOOLTIP}
              placement="top"
              children={
                <Box ml={1.25} display="inline-flex">
                  <InfoIcon size="medium" color={black} />
                </Box>
              }
              PopperProps={{
                style: { textAlign: "center", width: 347 },
              }}
            />
          </Box>
          <FormGroup>
            <ChildSeatItem
              name="Rear-facing seat"
              childSeat={childSeats.rearFacingSeat}
              updateChildSeat={handleVehicleChildSeatChange("rearFacingSeat")}
              onPhotoClick={handleOpenImageInLightbox("Rear-facing seat")}
            />
            <Divider sx={{ my: 3 }} />
            <ChildSeatItem
              name="Forward-facing seat"
              childSeat={childSeats.forwardFacingSeat}
              updateChildSeat={handleVehicleChildSeatChange(
                "forwardFacingSeat"
              )}
              onPhotoClick={handleOpenImageInLightbox("Forward-facing seat")}
            />
            <Divider sx={{ my: 3 }} />
            <ChildSeatItem
              name="Booster seat"
              childSeat={childSeats.boosterSeat}
              updateChildSeat={handleVehicleChildSeatChange("boosterSeat")}
              onPhotoClick={handleOpenImageInLightbox("Booster seat")}
            />
          </FormGroup>
        </Box>
      </FormControl>

      <MoovsLightbox {...lightboxProps} />
    </Box>
  );
}

export default VehicleChildSeats;
