import React from "react";

import { Box, IconButton, Typography } from "@mui/material";

import { GriddnetOperator, MoovsAiOperator, MoovsNetworkOperator } from "types";
import { alabaster, moovsBlue } from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import { OpenIcon } from "design-system/icons";
import MoovsAvatar from "design-system/components/MoovsAvatar";

type GriddnetAndMoovsNetworkOperatorInfoProps = {
  farmAffiliate: GriddnetOperator | MoovsNetworkOperator | MoovsAiOperator;
};

function GriddnetAndMoovsNetworkOperatorInfo(
  props: GriddnetAndMoovsNetworkOperatorInfoProps
) {
  const { farmAffiliate } = props;
  const {
    operatorName,
    contactName,
    operatorEmail,
    operatorPhone,
    websiteUrl,
    logoUrl,
    operatorAddress,
  } = farmAffiliate;

  // hooks
  const { isMobileView } = useScreenSize();

  // event handlers
  const handleOpenEmail = () => {
    const openLink = websiteUrl.startsWith("http")
      ? websiteUrl
      : `http://${websiteUrl}`;
    const win = window.open(openLink, "_blank");
    win.focus();
  };

  // render variables
  const MoovsAvatarPlaceholder = operatorName.split(" ").slice(0, 2).toString();

  return (
    <>
      <Box
        bgcolor={alabaster}
        p={2}
        display="flex"
        flexDirection={isMobileView ? "column" : "row"}
        mb={4}
      >
        {!isMobileView && (
          <Box mr={2}>
            <MoovsAvatar
              size="large"
              src={logoUrl}
              placeholder={MoovsAvatarPlaceholder}
            />
          </Box>
        )}

        <Box>
          <Box display="flex" mb={1.5} alignItems="center">
            {isMobileView && (
              <Box mr={2}>
                <MoovsAvatar
                  size="large"
                  src={logoUrl}
                  placeholder={MoovsAvatarPlaceholder}
                />
              </Box>
            )}
            <Typography variant="h4">{operatorName}</Typography>
          </Box>
          <Box mb={1.5}>
            <Typography variant="overline">Owner</Typography>
            <Typography variant="body2">{contactName}</Typography>
          </Box>
          <Box mb={1.5}>
            <Typography variant="overline">Phone Number</Typography>
            <Typography variant="body2">{operatorPhone}</Typography>
          </Box>
          <Box mb={1.5}>
            <Typography variant="overline">Email Address</Typography>
            <Typography variant="body2">{operatorEmail}</Typography>
          </Box>
          <Box {...(!websiteUrl && { mb: 1.5 })}>
            <Typography variant="overline">Website</Typography>
            <Box display="flex">
              <Typography variant="body2">
                {websiteUrl || "No Website"}
              </Typography>
              {websiteUrl && (
                <IconButton
                  style={{
                    position: "relative",
                    bottom: "5px",
                    padding: "8px",
                  }}
                  onClick={handleOpenEmail}
                  size="large"
                >
                  <OpenIcon size="small" color={moovsBlue} />
                </IconButton>
              )}
            </Box>
          </Box>
          <Box>
            <Typography variant="overline">Company Address</Typography>
            <Typography variant="body2">{operatorAddress}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default GriddnetAndMoovsNetworkOperatorInfo;
