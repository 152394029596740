import React from "react";

import { IconButton, SxProps } from "@mui/material";

import { InfoIcon } from "design-system/icons";
import MoovsTooltip from "components/MoovsTooltip";
import { granite } from "design-system/colors";

type InfoIconTooltipProps = {
  tooltipText: string;
  size?: "small" | "large";
  sx?: SxProps;
};

function InfoIconTooltip(props: InfoIconTooltipProps) {
  const { tooltipText, size = "small", sx } = props;

  return (
    <MoovsTooltip tooltipText={tooltipText}>
      <IconButton aria-label="info" sx={sx} size={size} disableTouchRipple>
        <InfoIcon size={size} color={granite} />
      </IconButton>
    </MoovsTooltip>
  );
}

export default InfoIconTooltip;
