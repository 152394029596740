import React from "react";

type SortMarkerIconProps = {
  color?: string;
};

const SortMarkerIcon = (props: SortMarkerIconProps) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.64645 9.64645C7.84171 9.84171 8.15829 9.84171 8.35355 9.64645L11.1464 6.85356C11.4614 6.53857 11.2383 6 10.7929 6H5.20711C4.76165 6 4.53857 6.53857 4.85355 6.85356L7.64645 9.64645Z"
        stroke={color || "#999999"}
        fill={color || "#B3B3B3"}
      />
    </svg>
  );
};

export default SortMarkerIcon;
