import React from "react";

type DashboardIconProps = {
  color?: string;
  size?: "small";
};

const DashboardIcon = (props: DashboardIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1V13M1 13H5M1 13V9H5V13M9 13H13V1H9V13ZM9 13V5H5V13M9 13H5"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 3V21M3 21H9M3 21V15H9V21M15 21H21V3H15V21ZM15 21V9H9V21M15 21H9"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DashboardIcon;
