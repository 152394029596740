import React, { Dispatch, SetStateAction } from "react";

import { Box, Typography } from "@mui/material";

import { LuggageEnum, LuggageFields } from "../types";
import MoovsIncrementer from "components/MoovsIncrementer";

type LuggageMenuItemProps = {
  luggageAmount: LuggageFields;
  setLuggageAmount: Dispatch<SetStateAction<LuggageFields>>;
  luggageType: LuggageEnum;
  title: "Carry-On" | "Oversize" | "Checked";
};

function LuggageMenuItem(props: LuggageMenuItemProps) {
  const { luggageAmount, setLuggageAmount, luggageType, title } = props;

  return (
    <Box
      display="flex"
      flex={1}
      justifyContent="space-between"
      alignItems="center"
      my={1}
    >
      <Typography variant="body2">{title}</Typography>
      <MoovsIncrementer
        value={luggageAmount[luggageType]}
        limits={[0, 99]}
        decrement={() =>
          setLuggageAmount({
            ...luggageAmount,
            [luggageType]: luggageAmount[luggageType] - 1,
          })
        }
        increment={() =>
          setLuggageAmount({
            ...luggageAmount,
            [luggageType]: luggageAmount[luggageType] + 1,
          })
        }
        onTextFieldChange={(newLuggageCount) =>
          setLuggageAmount({
            ...luggageAmount,
            [luggageType]: newLuggageCount,
          })
        }
        ButtonProps={{ variant: "text" }}
      />
    </Box>
  );
}

export default LuggageMenuItem;
