import React from "react";
import filter from "lodash/filter";
import first from "lodash/first";
import map from "lodash/map";

import {
  Box,
  FormControl,
  FormHelperText,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { CheckIcon } from "design-system/icons";
import { errorRed, moovsBlue } from "design-system/colors";
import { DeleteReason } from "../hooks/useDeleteAppFeedback";

type DeleteAppFeedbackProps = {
  isPaidApp: boolean;
  deleteReason: string;
  deleteReasonError: boolean;
  onDeleteReasonChange: (e: any) => void;
  otherReason: string;
  onOtherReasonChange: (e: any) => void;
};

function DeleteAppFeedback(props: DeleteAppFeedbackProps) {
  const {
    isPaidApp,
    deleteReasonError,
    deleteReason,
    onDeleteReasonChange,
    otherReason,
    onOtherReasonChange,
  } = props;

  // derived state
  const deleteAppReasons = {
    Select: {
      value: null,
      text: "Select",
    },
    UserNeeds: {
      value: DeleteReason.UserNeeds,
      text: "Does not meet my needs",
    },
    Performance: {
      value: DeleteReason.Performance,
      text: "App is not performing well",
    },
    NotNeeded: {
      value: DeleteReason.NotNeeded,
      text: "Not needed anymore",
    },

    ...(isPaidApp && {
      NotWorthCost: {
        value: DeleteReason.NotWorthCost,
        text: "App is not worth the cost",
      },
    }),

    Other: {
      value: DeleteReason.Other,
      text: "Other",
    },
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box mb={1}>
          <Typography variant="h4">Feedback</Typography>
        </Box>
        <FormControl error={deleteReasonError}>
          <Select
            required
            value={deleteReason || "Select"}
            onChange={onDeleteReasonChange}
            renderValue={(selected) => {
              const reason = first(
                filter(deleteAppReasons, (reason) => reason.value === selected)
              );
              return reason?.text || "Select";
            }}
          >
            {map(deleteAppReasons, (reason) => {
              return (
                <MenuItem
                  value={reason.value}
                  key={reason.value}
                  disabled={reason.value === null}
                >
                  <ListItemText primary={reason.text} />
                  {reason.value === deleteReason && (
                    <ListItemIcon>
                      <CheckIcon color={moovsBlue} size="small" />
                    </ListItemIcon>
                  )}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>
            {deleteReasonError && (
              <Typography variant="caption" color={errorRed}>
                Please select an option
              </Typography>
            )}
          </FormHelperText>
        </FormControl>
      </Box>

      {deleteReason === DeleteReason.Other && (
        <Box mt={2} display="flex" flexDirection="column">
          <Box mb={1}>
            <Typography variant="h4">Other Reason</Typography>
          </Box>
          <TextField value={otherReason} onChange={onOtherReasonChange} />
        </Box>
      )}
    </>
  );
}

export default DeleteAppFeedback;
