import React from "react";

import { Box } from "@mui/system";

import EmptyActivityLogBlock from "../EmptyActivityLogBlock";
import { SalesAutomationText } from "types";
import SalesAutomationLogItem from "./SalesAutomationLogItem";
import useLoadSalesAutomation from "pages/requests/RequestOverview/QuoteOverviewManager/components/QuoteOverviewDesktop/components/SalesAutomationDialog/hooks/useLoadSalesAutomation";
import { CircularProgress } from "@mui/material";

type ActivityLogSalesAutomationTabProps = {
  requestId: string;
};

function ActivityLogSalesAutomationTab(
  props: ActivityLogSalesAutomationTabProps
) {
  const { requestId } = props;

  const { salesAutomation, salesAutomationLoading } = useLoadSalesAutomation({
    requestId,
  });
  const texts = salesAutomation?.texts;

  if (salesAutomationLoading)
    return (
      <Box
        display="flex"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box height="100%" my={2}>
      {texts ? (
        <Box flex="1" my={2}>
          {texts.map((text: SalesAutomationText, index: number) => (
            <Box mb={index !== texts.length - 1 ? 1 : 0} key={text.id}>
              <SalesAutomationLogItem text={text} />
            </Box>
          ))}
        </Box>
      ) : (
        <EmptyActivityLogBlock />
      )}
    </Box>
  );
}

export default ActivityLogSalesAutomationTab;
