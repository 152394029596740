import { EditPromoCodeValues } from "../useEditPromoCodeDefaultValues";
import { UpdatePromoCodeInput } from "types";
import { finalizeSharedPromoCodeAttributes } from "../../../../../CreatePromoCodeDialog/utils/finalizeCreatePromoCodeInput";

type FinalizeEditPromoCodeInputParams = {
  input: EditPromoCodeValues;
  promoCodeId: string;
};

export const finalizeEditPromoCodeInput = (
  params: FinalizeEditPromoCodeInputParams
) => {
  const { input, promoCodeId } = params;

  const {
    isPercent,
    amount,
    isTotalLimited,
    isBookingContactLimited,
    totalLimit,
    bookingContactLimit,
    bookingStartDate,
    bookingEndDate,
    tripStartDate,
    tripEndDate,
    active,
  } = input;

  const finalData: UpdatePromoCodeInput = { id: promoCodeId, active };

  return finalizeSharedPromoCodeAttributes({
    finalData,
    isPercent,
    amount,
    isTotalLimited,
    isBookingContactLimited,
    totalLimit,
    bookingContactLimit,
    bookingStartDate,
    bookingEndDate,
    tripStartDate,
    tripEndDate,
  });
};
