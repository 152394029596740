import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import { useSnackbar } from "../../../../../globals/hooks";
import { REOPEN_TRIP_MUTATION } from "../../../../../globals/graphql";
import MoovsBanner from "components/MoovsBanner";
import { tintRed, errorRed } from "design-system/colors";
import RemoveDialog from "components/RemoveDialog";

type TripIsCancelledBannerProps = { tripId: string };

function TripIsCancelledBanner(props: TripIsCancelledBannerProps) {
  const { tripId } = props;

  // state
  const [reopenDialogOpen, setReopenDialogOpen] = useState(false);

  // hooks
  const snackbar = useSnackbar();

  // mutations
  const [reopenTrip] = useMutation(REOPEN_TRIP_MUTATION, {
    refetchQueries: ["Request"],
    onCompleted(data) {
      setReopenDialogOpen(false);
      snackbar.success(`Successfully reopened trip!`);
    },
    onError(error) {
      snackbar.error("Error reopening your trip");
    },
  });

  // event handlers
  const handleReopenTrip = () => {
    reopenTrip({ variables: { input: { id: tripId } } });
  };

  return (
    <>
      <MoovsBanner
        bannerVariant="compact"
        bannerText="Trip Cancelled"
        actionButtonText="Reopen"
        onActionButtonClick={() => setReopenDialogOpen(true)}
        actionButtonVariant="outlined"
        bannerBgColor={tintRed}
        bannerTextColor={errorRed}
      />
      <RemoveDialog
        open={reopenDialogOpen}
        onRemove={handleReopenTrip}
        onClose={() => setReopenDialogOpen(false)}
        title="Reopen Trip"
        body="Are you sure you want to reopen this trip?"
        removeButtonText="Yes, Reopen Trip"
        cancelButtonText="No"
      />
    </>
  );
}

export default TripIsCancelledBanner;
