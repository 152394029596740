import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { Box, Divider } from "@mui/material";

import { useOperator, useScreenSize } from "globals/hooks";
import { TOP_BAR_HEIGHT_MOBILE } from "globals/hooks/useLayout";
import {
  BackButton,
  CancelSubscriptionButton,
  MarketingSalesSection,
  OperationsBackOfficeSection,
  SubscriptionPlans,
  SubscriptionPlansInfo,
  SupportOnboardingSection,
} from "./components";
import { PlanVariant } from "types";
import { alabaster } from "design-system/colors";

function SubscriptionsPage() {
  // hooks
  const { isSmallTabletView } = useScreenSize();
  const operator = useOperator();

  // state
  const [planLength, setPlanLength] = useState<"monthly" | "annual">("monthly");

  return (
    <Box
      px={2}
      width="100%"
      display="flex"
      flexDirection="column"
      height={
        isSmallTabletView ? `calc(100vh - ${TOP_BAR_HEIGHT_MOBILE}px)` : "100vh"
      }
    >
      {isSmallTabletView ? (
        <>
          <Helmet>
            <title>Plans | Moovs</title>
          </Helmet>

          <BackButton />

          {/* Subscription Plans */}
          <SubscriptionPlans
            setPlanLength={setPlanLength}
            planLength={planLength}
          />

          <Divider
            sx={{
              width: "initial",
              mx: -2,
              mt: 1,
            }}
          />

          {operator?.plan && operator?.plan !== PlanVariant.Free && (
            <Box display="flex" justifyContent="center" alignContent="center">
              <CancelSubscriptionButton />
            </Box>
          )}
        </>
      ) : (
        <>
          <Box bgcolor={alabaster} top={0} px="100px" position="sticky">
            <Helmet>
              <title>Plans | Moovs</title>
            </Helmet>

            <BackButton />

            {/* Subscription Plans */}
            <SubscriptionPlans
              setPlanLength={setPlanLength}
              planLength={planLength}
            />

            <Divider
              sx={{
                width: "initial",
                mx: "-100px",
                mt: 1,
              }}
            />
          </Box>

          {/* Subscription Plans Info */}

          <Box overflow="auto" px="100px">
            <SubscriptionPlansInfo />

            {/* Operations / Back Office */}
            <OperationsBackOfficeSection />

            {/* Marketing & Sales */}
            <MarketingSalesSection />

            {/* Support & Onboarding */}
            <SupportOnboardingSection />
            {/* Cancel Subscription Button */}
            {operator?.plan && operator?.plan !== PlanVariant.Free && (
              <Box display="flex">
                <CancelSubscriptionButton />
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

export default SubscriptionsPage;
