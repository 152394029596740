import { WorkflowSelectionsEnum } from "../steps/workflow/schemaValidation";

type GetEventHandlerStepsProps = {
  workflow: WorkflowSelectionsEnum;
  handleWorkflowSubmit: (data) => void;
  handleCodeAndAmountSubmit: (data) => void;
  handleBookingDateSubmit: (data) => void;
  handleTripDateSubmit: (data) => void;
  handleLimitsSubmit: (data) => void;
};

// this returns a list of eventHandlers depending on which workflow user is on
export const getEventHandlerSteps =
  (props: GetEventHandlerStepsProps) =>
  (handleAfterSubmit: (data) => void): any[] => {
    const {
      workflow,
      handleWorkflowSubmit,
      handleCodeAndAmountSubmit,
      handleBookingDateSubmit,
      handleTripDateSubmit,
      handleLimitsSubmit,
    } = props;

    // starter step needs an array of minimum length 2 for useFormStepProgress hook to work
    const starterHandlerSteps = [handleWorkflowSubmit(handleAfterSubmit), null];

    // anytime steps
    const anytimeEventHandlerSteps = [
      handleWorkflowSubmit(handleAfterSubmit),
      handleCodeAndAmountSubmit(handleAfterSubmit),
      handleLimitsSubmit(handleAfterSubmit),
    ];

    // bookingDate steps
    const bookingDateEventHandlerSteps = [
      handleWorkflowSubmit(handleAfterSubmit),
      handleCodeAndAmountSubmit(handleAfterSubmit),
      handleBookingDateSubmit(handleAfterSubmit),
      handleLimitsSubmit(handleAfterSubmit),
    ];

    // tripDate steps
    const tripDateEventHandlerSteps = [
      handleWorkflowSubmit(handleAfterSubmit),
      handleCodeAndAmountSubmit(handleAfterSubmit),
      handleTripDateSubmit(handleAfterSubmit),
      handleLimitsSubmit(handleAfterSubmit),
    ];

    if (workflow === WorkflowSelectionsEnum.anytime)
      return anytimeEventHandlerSteps;
    if (workflow === WorkflowSelectionsEnum.bookingDate)
      return bookingDateEventHandlerSteps;
    if (workflow === WorkflowSelectionsEnum.tripDate)
      return tripDateEventHandlerSteps;

    return starterHandlerSteps;
  };
