import React from "react";

import {
  Box,
  LinearProgress,
  Typography,
  linearProgressClasses,
} from "@mui/material";

import {
  black,
  grayMedium,
  moovsBlue,
  moovsBlueSelected,
} from "design-system/colors";

type MetricsProgressBarProps = {
  count: number;
  unit: string;
  total?: number;
  showCountOnly?: boolean;
  textColor?: string;
  progressHeight?: string;
  progressColor?: string;
  additionalProgressBarTextComponent?: JSX.Element;
};

function MetricsProgressBar(props: MetricsProgressBarProps) {
  const {
    count,
    total,
    unit,
    showCountOnly = false,
    textColor,
    progressHeight,
    progressColor,
    additionalProgressBarTextComponent,
  } = props;

  // derived state
  const isUnlimited = count === -1;
  const progressValue = Math.min((count / total) * 100, 100);

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between">
        <Box>
          {isUnlimited ? (
            <Box mb={1}>
              <Typography variant="h6">{`Unlimited ${unit}`}</Typography>
            </Box>
          ) : (
            <Box display="flex" gap={0.5} mb={1} color={textColor || black}>
              <Typography variant="h6">{count}</Typography>
              {showCountOnly ? (
                <Typography variant="h6">{unit}</Typography>
              ) : (
                <>
                  <Typography variant="caption">of</Typography>
                  <Typography variant="caption">{total}</Typography>
                  <Typography variant="caption">{unit}</Typography>
                </>
              )}
            </Box>
          )}
        </Box>
        <Box>
          {additionalProgressBarTextComponent &&
            additionalProgressBarTextComponent}
        </Box>
      </Box>

      <LinearProgress
        variant="determinate"
        value={progressValue}
        sx={{
          "&.MuiLinearProgress-root": {
            height: progressHeight || "6px",
            borderRadius: "24px",
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: isUnlimited ? grayMedium : moovsBlueSelected,
            },
            [`& .${linearProgressClasses.bar}`]: {
              borderRadius: 5,
              backgroundColor: isUnlimited
                ? grayMedium
                : progressColor || moovsBlue,
            },
          },
        }}
      />
    </Box>
  );
}

export default MetricsProgressBar;
