import React from "react";
import { useLocation, Link } from "react-router-dom";

import { Box, Divider, Drawer, Button } from "@mui/material";

import { grayMedium, grayLight, granite } from "../../../colors";
import { HomeIcon } from "../../../icons";
import {
  useOperator,
  useAuth,
  useScreenSize,
  useAnalytics,
  useCurrentUser,
} from "../../../../globals/hooks";
import theme, { SIDE_NAV_WIDTH } from "theme";
import NavButtonList, { NavLink } from "../SideNavButtons/NavButtonList";
import { getRedirectPathBasedOnPermissions } from "globals/utils/getRedirectPathBasedOnPermissions";

const styles = {
  sideNav: {
    [theme.breakpoints.up("sm")]: {
      width: SIDE_NAV_WIDTH,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    backgroundColor: grayLight,
    width: SIDE_NAV_WIDTH,
  },
  paperAnchorDockedLeftOverride: {
    borderRight: "none",
    height: "100vh",
  },
  joinMoovsButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  dividerColor: {
    backgroundColor: grayMedium,
  },
};

const navSectionData: NavLink[] = [
  {
    text: "General",
    paths: ["/settings/general"],
  },
  {
    text: "Customer Portal",
    paths: ["/settings/customer-portal"],
  },
  {
    text: "Cancellation",
    paths: ["/settings/cancellation"],
  },
  {
    text: "Insurance",
    paths: ["/settings/insurance"],
  },
  {
    text: "Terms & Conditions",
    paths: ["/settings/terms-and-conditions"],
  },
  {
    text: "Drivers",
    paths: ["/settings/drivers"],
  },
  {
    text: "Members",
    paths: ["/settings/members"],
  },
  {
    text: "Zone Pricing",
    paths: ["/settings/zone-pricing"],
  },
  {
    text: "Dynamic Pricing",
    paths: ["/settings/dynamic-pricing"],
  },
  {
    text: "Website",
    paths: [
      "/settings/website/main",
      "/settings/website/analytics",
      "/settings/website/settings",
    ],
  },
  {
    text: "Moovs Market",
    paths: ["/settings/moovs-market"],
  },
  {
    text: "Billing & Plans",
    paths: ["/settings/billing"],
  },
];

const financialAccountSectionData = [
  {
    text: "Financial Account",
    paths: ["/settings/financial"],
    // only owners (owner roles have full access) can potentially see /settings/financial
    // so we don't have an AccessPermission record for this path
    permissionsOverride: true,
  },
];

type SettingsSideNavProps = {
  mobileOpen: boolean;
  onDrawerToggle: () => void;
};

function SettingsSideNav(props: SettingsSideNavProps) {
  const { mobileOpen, onDrawerToggle } = props;

  // hooks
  const { pathname } = useLocation();
  const { isMobileView } = useScreenSize();
  const { authStage } = useAuth();
  const { id: operatorId, stripeAccount } = useOperator() || {};
  const user = useCurrentUser();
  const { track } = useAnalytics();

  if (
    authStage !== "authenticated" ||
    !operatorId ||
    pathname.startsWith("/onboarding")
  ) {
    return null;
  }

  // event handlers
  const handleHomeClick = () => track("home_opened");

  let navLinks = navSectionData;

  if (user.role.slug === "owner" && stripeAccount?.isTreasuryEnabled) {
    navLinks = navLinks.concat(financialAccountSectionData);
  }

  return (
    <Box
      component="nav"
      aria-label="swoop navigation panel"
      sx={{
        [theme.breakpoints.up("sm")]: {
          width: SIDE_NAV_WIDTH,
          flexShrink: 0,
        },
      }}
    >
      {/* MOBILE */}
      {isMobileView && (
        <Drawer
          variant="temporary"
          anchor="left"
          sx={{
            "& .MuiDrawer-paper": {
              ...styles.drawerPaper,
            },
            "& .MuiDrawer-paperAnchorDockedLeft": {
              ...styles.paperAnchorDockedLeftOverride,
            },
          }}
          open={mobileOpen}
          onClose={onDrawerToggle}
          ModalProps={{ keepMounted: true }}
        >
          <Box px={2} mt={1}>
            <NavButtonList
              navLinks={navLinks}
              onDrawerToggle={onDrawerToggle}
              mode="settings"
            />
          </Box>
        </Drawer>
      )}

      {/* DESKTOP */}
      {!isMobileView && (
        <Drawer
          sx={{
            "& .MuiDrawer-paper": {
              ...styles.drawerPaper,
            },
            "& .MuiDrawer-paperAnchorDockedLeft": {
              ...styles.paperAnchorDockedLeftOverride,
            },
          }}
          variant="permanent"
          open
        >
          <Box height="78px" px={2} display="flex" alignItems="center">
            <Button
              sx={{
                textAlign: "center",
                color: granite,
                padding: 2,
                ":hover": {
                  backgroundColor: "#e5e5e5",
                },
              }}
              component={Link}
              to={getRedirectPathBasedOnPermissions({
                userAccessPermissions: user?.accessPermissions || [],
                preferredPath: "/quotes",
              })}
              fullWidth
              focusRipple
              disableTouchRipple
              startIcon={
                <Box ml={0.5} mb={0.5} display="flex" alignItems="center">
                  <HomeIcon color={granite} />
                </Box>
              }
              onClick={handleHomeClick}
            >
              Home
            </Button>
          </Box>
          <Box mb={1} mx={2}>
            <Divider sx={{ backgroundColor: grayMedium }} />
          </Box>
          <Box px={2} pb={2} display="flex" flex="1" flexDirection="column">
            <NavButtonList navLinks={navLinks} mode="settings" />
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

export default SettingsSideNav;
