import React from "react";

import { Box } from "@mui/system";

import { EmailLog, LoadEmailLogsConnection, LoadEmailLogsEdge } from "types";
import EmailLogItem from "./EmailLogItem";
import EmptyActivityLogBlock from "../EmptyActivityLogBlock";
import MoovsInfiniteScroll from "components/MoovsInfiniteScroll";

type ActivityLogEmailTabProps = {
  emailLogs?: EmailLog[];
  paginatedEmailLogsData?: LoadEmailLogsConnection;
  loading?: boolean;
  fetchMore?: () => void;
  source?: string;
  requestStage?: string;
  orderNumber?: string;
};

function ActivityLogEmailTab(props: ActivityLogEmailTabProps) {
  const {
    emailLogs,
    paginatedEmailLogsData,
    loading,
    fetchMore,
    source,
    requestStage,
    orderNumber,
  } = props;

  const removeRecipientEmail =
    source === "contact" || source === "company" || source === "affiliate";

  return (
    <Box height="100%" my={2}>
      {paginatedEmailLogsData?.edges.length ? (
        <MoovsInfiniteScroll
          loading={loading}
          data={paginatedEmailLogsData}
          next={fetchMore}
          name="emailLogs"
          scrollableId="emailLogsActivityLog"
        >
          {paginatedEmailLogsData.edges?.map(
            (emailLog: LoadEmailLogsEdge, index: number) => (
              <Box
                mb={index !== paginatedEmailLogsData.edges.length - 1 ? 1 : 0}
                key={emailLog.node?.id}
              >
                <EmailLogItem
                  emailLog={emailLog.node}
                  removeRecipientEmail={removeRecipientEmail}
                  requestStage={requestStage}
                  orderNumber={orderNumber}
                />
              </Box>
            )
          )}
        </MoovsInfiniteScroll>
      ) : emailLogs?.length ? (
        <Box flex="1" my={2}>
          {emailLogs?.map((emailLog: EmailLog, index: number) => (
            <Box mb={index !== emailLogs.length - 1 ? 1 : 0} key={emailLog.id}>
              <EmailLogItem
                emailLog={emailLog}
                removeRecipientEmail={removeRecipientEmail}
                requestStage={requestStage}
                orderNumber={orderNumber}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <EmptyActivityLogBlock source={source} />
      )}
    </Box>
  );
}

export default ActivityLogEmailTab;
