import React from "react";
import { useLocation } from "react-router-dom";

import { Badge } from "@mui/material";

import SquareNavButton from "design-system/components/layout/SideNavButtons/SquareNavButton";
import { useOperatorGettingStarted } from "globals/hooks";
import GettingStartedIcon from "design-system/icons/info/GettingStartedIcon";
import GettingStartedCompletedIcon from "design-system/icons/info/GettingStartedCompletedIcon";
import { granite, moovsBlue } from "design-system/colors";

function GettingStartedButton() {
  // hooks
  const operatorGettingStarted = useOperatorGettingStarted();
  const { pathname } = useLocation();

  const active = pathname === "/getting-started";
  const showCompleteIcon =
    active && operatorGettingStarted?.tasksRemaining === 0;

  return (
    <>
      {operatorGettingStarted?.tasksRemaining ? (
        <SquareNavButton
          active={active}
          text="Getting Started"
          path="/getting-started"
          icon={
            <Badge
              badgeContent={operatorGettingStarted.tasksRemaining}
              max={5}
              color="error"
              // height set to offset badget which pushes the button text too low
              sx={{ height: 22 }}
            >
              <GettingStartedIcon color={active ? moovsBlue : granite} />
            </Badge>
          }
        />
      ) : showCompleteIcon ? (
        <SquareNavButton
          text="Getting Started"
          icon={<GettingStartedCompletedIcon color={granite} />}
        />
      ) : null}
    </>
  );
}

export default GettingStartedButton;
