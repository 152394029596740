import React from "react";

import { Step, Box, Stepper, StepIcon } from "@mui/material";

import { ProgressConnector } from "./components";
import { StatusCircleIcon } from "design-system/icons";
import { moovsBlue, moovsLiteBlue } from "design-system/colors";

type OnboardingStepperProps = {
  activeStep: 0 | 1 | 2 | 3 | 4 | 5;
};

function OnboardingStepper(props: OnboardingStepperProps) {
  const { activeStep } = props;

  return (
    <Box my={2}>
      <Stepper activeStep={activeStep} connector={<ProgressConnector />}>
        {[...Array(6)].map((_, index) => {
          let currentOrCompletedStep =
            activeStep === index || activeStep > index;

          return (
            <Step key={index} disabled={activeStep > index}>
              <StepIcon
                icon={
                  <StatusCircleIcon
                    color={currentOrCompletedStep ? moovsBlue : moovsLiteBlue}
                    size="small"
                    {...(currentOrCompletedStep && {
                      fill: moovsBlue,
                    })}
                  />
                }
              ></StepIcon>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

export default OnboardingStepper;
