import React from "react";

type FlightIconProps = {
  color?: string;
  size?: string;
};

const FlightIcon = (props: FlightIconProps) => {
  const { color, size } = props;

  return (
    <svg
      width={size === "small" ? "14" : "20"}
      height={size === "small" ? "14" : "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7438 16.8542L17.2912 16.3071C17.4395 16.1589 17.5463 15.9744 17.6011 15.7721C17.6558 15.5697 17.6566 15.3566 17.6032 15.1539L15.6563 7.76005L18.2306 5.18721C18.6533 4.78371 18.9203 4.24438 18.9848 3.66381C19.0493 3.08325 18.9073 2.49851 18.5835 2.01215C18.3815 1.72714 18.1196 1.48966 17.8161 1.31631C17.5127 1.14296 17.175 1.03791 16.8268 1.00851C16.4785 0.979114 16.128 1.02607 15.7998 1.1461C15.4715 1.26613 15.1735 1.45634 14.9265 1.70344L12.2617 4.36745L4.86507 2.42243C4.66211 2.36898 4.44868 2.36966 4.24606 2.42439C4.04344 2.47912 3.85872 2.58599 3.71033 2.73433L3.16297 3.28137C3.03397 3.41029 2.93607 3.56689 2.8767 3.73929C2.81734 3.91168 2.79807 4.09533 2.82036 4.27629C2.84266 4.45724 2.90593 4.63073 3.00537 4.78359C3.10481 4.93644 3.2378 5.06463 3.39424 5.15842L8.44213 8.18552L4.60099 12.5362L3.40064 12.5538C3.21733 12.5225 3.02924 12.5341 2.85121 12.5878C2.67317 12.6415 2.51006 12.7358 2.37473 12.8633L1.17598 13.9606C1.10656 14.0298 1.05521 14.115 1.02641 14.2087C0.997621 14.3024 0.992272 14.4018 1.01084 14.498C1.0294 14.5942 1.07131 14.6845 1.13289 14.7607C1.19447 14.837 1.27384 14.897 1.36403 14.9355L3.68072 16.2415L5.20117 18.6368C5.23993 18.7268 5.30015 18.806 5.37655 18.8674C5.45296 18.9288 5.54324 18.9706 5.63952 18.9892C5.7358 19.0077 5.83516 19.0024 5.92892 18.9737C6.02269 18.9451 6.10802 18.8939 6.17746 18.8248L7.22497 17.8267C7.37057 17.6895 7.47956 17.5182 7.54204 17.3282C7.60451 17.1383 7.61848 16.9357 7.58268 16.739L7.60188 15.5353L11.8367 11.5781L14.8656 16.623C14.9595 16.7794 15.0877 16.9123 15.2407 17.0117C15.3936 17.111 15.5672 17.1743 15.7483 17.1966C15.9294 17.2188 16.1131 17.1996 16.2856 17.1402C16.4581 17.0809 16.6148 16.9831 16.7438 16.8542Z"
        stroke={color || "#FC881D"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FlightIcon;
