import compact from "lodash/compact";
import React from "react";
import EllipsisMenuItem, { MenuItemOption } from "./EllipsisMenuItem";
import EllipsisDivider from "./EllipsisDivider";
import EllipsisLabel, { LabelOption } from "./EllipsisLabel";
import size from "lodash/size";

export type EllipsisMenuOption = MenuItemOption | LabelOption | "divider";

type EllipsisMenuListProps = {
  options: EllipsisMenuOption[];
  onOptionClick: (callback: () => void) => void;
};

function EllipsisMenuList(props: EllipsisMenuListProps) {
  const { options, onOptionClick } = props;

  return (
    <>
      {compact(
        options.map((option, index) => {
          if (!option) return null;

          if (option === "divider") {
            if (index === 0 || index === size(options)) return null;
            return <EllipsisDivider key={index} />;
          }

          if (typeof option === "string") {
            return <EllipsisLabel label={option} key={index} />;
          }

          return (
            <EllipsisMenuItem
              option={option}
              onOptionClick={onOptionClick}
              key={option.key || index}
            />
          );
        })
      )}
    </>
  );
}

export default EllipsisMenuList;
