import React, { ChangeEvent, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import first from "lodash/first";

import { Box, TextField } from "@mui/material";

import MoovsDialog from "../../MoovsDialog";
import {
  LOAD_FARM_AFFILIATES_QUERY,
  UPDATE_OPERATOR_GRIDDNET_ID_MUTATION,
} from "../../../globals/graphql";
import { useAnalytics, useOperator, useSnackbar } from "../../../globals/hooks";
import { FarmAffiliateVariantEnum } from "../../../types";

type ManageGriddnetDialogProps = {
  open: boolean;
  onClose: () => void;
};

function ManageGriddnetDialog(props: ManageGriddnetDialogProps) {
  const { open, onClose } = props;

  //hooks
  const operator = useOperator();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // state
  const [griddnetId, setGriddnetId] = useState(operator?.gnetId);
  const [errors, setErrors] = useState({ gnetId: "" });

  // mutations
  const [updateOperatorGnetId] = useMutation(
    UPDATE_OPERATOR_GRIDDNET_ID_MUTATION,
    {
      onCompleted() {
        onClose();
        snackbar.success("Saved GNet ID");
        track("affiliate_griddNetIDAdded");
      },
      onError(error) {
        if (
          first(error.graphQLErrors)?.extensions?.code ===
          "MOOVS__GRIDDNET_REGISTER_OPERATOR"
        ) {
          return snackbar.error(`Failed to register with Griddnet. This may be because you
          have another service provider enabled. Please contact Griddnet for assistance.`);
        }
        snackbar.error("Error saving GNet ID");
      },
      refetchQueries: [
        {
          query: LOAD_FARM_AFFILIATES_QUERY,
          variables: {
            viewFilter: [
              FarmAffiliateVariantEnum.ExternalOperator,
              FarmAffiliateVariantEnum.MoovsNetworkOperator,
              FarmAffiliateVariantEnum.GriddnetOperator,
            ],
          },
        },
      ],
    }
  );

  // event handlers
  const handleGriddnetIdSave = () => {
    if (!griddnetId) {
      setErrors({ gnetId: "Please enter a GNet ID" });
      return;
    }

    updateOperatorGnetId({
      variables: { input: { gnetId: griddnetId } },
    });
  };

  const handleGriddnetIdChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;

    setErrors({ gnetId: "" });
    setGriddnetId(value);
  };

  useEffect(() => {
    setGriddnetId(operator?.gnetId);
  }, [operator]);

  return (
    <MoovsDialog
      size="sm"
      dialogTitle="Manage GNet ID"
      acceptButtonText="Save"
      open={open}
      onClose={onClose}
      onAccept={handleGriddnetIdSave}
      removeCloseButton
    >
      <Box my={3}>
        <TextField
          value={griddnetId}
          variant="outlined"
          label="Add Griddnet ID"
          fullWidth
          onChange={handleGriddnetIdChange}
          error={!!errors.gnetId}
          helperText={errors.gnetId}
        />
      </Box>
    </MoovsDialog>
  );
}

export default ManageGriddnetDialog;
