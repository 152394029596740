import React, { useState } from "react";

import { BellIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import { useAnalytics } from "globals/hooks";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";

type UseOverrideTripSMSRecipientsClickProps = {
  analyticsName?: string;
};

function useOverrideTripSMSRecipientsClick(
  props: UseOverrideTripSMSRecipientsClickProps
): EllipsisMenuOption & { open: boolean; onClose: () => void } {
  const { analyticsName } = props;

  // hooks
  const { track } = useAnalytics();

  // state
  const [
    isOverrideTripSMSRecipientsDialogOpen,
    setIsOverrideTripSMSRecipientsDialogOpen,
  ] = useState(false);

  // event handlers
  const handleDialogOpen = () => {
    if (analyticsName) track(analyticsName);
    setIsOverrideTripSMSRecipientsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsOverrideTripSMSRecipientsDialogOpen(false);
  };

  return {
    text: "Notify...",
    icon: <BellIcon size="small" color={grayDark} />,
    onClick: handleDialogOpen,
    disableOption: false,
    hideOption: false,

    // dialog state
    open: isOverrideTripSMSRecipientsDialogOpen,
    onClose: handleDialogClose,
  };
}

export { useOverrideTripSMSRecipientsClick };
