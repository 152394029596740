import React, { ChangeEvent } from "react";
import { Box, Link, TextField, Typography } from "@mui/material";

import { errorRed, granite, grayDark, moovsBlue } from "design-system/colors";
import { OpenIcon } from "design-system/icons";
import { useScreenSize } from "globals/hooks";

type InputReviewSiteIdBlockProps = {
  onCorporationIdInput: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  selectedPlatform: string;
  reviewSiteId: string;
  error: string;
  onCorporationIdOnBlur: (event) => void;
};

// variable text based on which platform is selected

const descriptionText = {
  google:
    "This can be found when you log into your Google Business Profile Manager",
  facebook: 'This can be found in your Facebook "Review" tab URL',
  yelp: 'This can be found in your Yelp "Write a Review" URL',
};

const reviewSiteUrlPrefix = {
  google: "g.page/r/",
  facebook: "facebook.com/",
  yelp: "yelp.com/writeareview/biz/",
};

const reviewSiteUrlSuffix = {
  google: "/review",
  facebook: "/review",
};

const InputReviewSiteIdBlock = (props: InputReviewSiteIdBlockProps) => {
  const {
    onCorporationIdInput,
    onCorporationIdOnBlur,
    selectedPlatform,
    reviewSiteId,
    error,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Box>
      <Typography variant="body2" mt={2}>
        Add your Corporation ID
      </Typography>
      <Box
        display="flex"
        flexDirection={isMobileView ? "column" : "row"}
        mt={2}
        mb={2}
      >
        <Typography
          variant="caption"
          color={grayDark}
          flexBasis="60%"
          alignSelf={!isMobileView && "center"}
        >
          {descriptionText[selectedPlatform]}
        </Typography>
        {/* varies in mobile view due to design, 'how to find' shifts position, hence all the mobile view checks */}
        <Box
          display="flex"
          alignContent={!isMobileView && "flex-start"}
          justifyContent={!isMobileView && "flex-end"}
          alignSelf={!isMobileView && "flex-start"}
          flexBasis="40%"
          mt={isMobileView && 1}
        >
          <Link
            target="_blank"
            href="https://intercom.help/moovs-05c940f1970e/en/articles/6759829-about-customer-reviews-app"
            sx={{ textDecoration: "none" }}
          >
            <Typography variant="button" mr={0.5} color={moovsBlue}>
              How to find link
            </Typography>
            <OpenIcon color={moovsBlue} size="xs" />
          </Link>
        </Box>
      </Box>
      <TextField
        inputProps={{ maxLength: 150 }}
        id="corporationId"
        sx={{ width: "100%" }}
        label="Corporation ID"
        variant="outlined"
        onChange={onCorporationIdInput}
        onBlur={onCorporationIdOnBlur}
        value={reviewSiteId}
        error={!!error}
      />
      {error && (
        <Typography variant="body2" color={errorRed}>
          {error}
        </Typography>
      )}
      <Box display="flex" ml={1} mb={3} mt={1} flexWrap="wrap">
        <Typography variant="body2" color={grayDark}>
          {reviewSiteUrlPrefix[selectedPlatform]}
        </Typography>
        <Typography
          fontWeight={700}
          variant="body2"
          color={error ? errorRed : granite}
          maxWidth="90%"
          noWrap
        >
          {reviewSiteId || "[corporateID]"}
        </Typography>
        <Typography variant="body2" color={grayDark}>
          {reviewSiteUrlSuffix[selectedPlatform]}
        </Typography>
      </Box>
    </Box>
  );
};

export default InputReviewSiteIdBlock;
