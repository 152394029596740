const CancelledFlightIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.777 20.277L4.22115 4.72112M12.5 9L18 6.5L15.5 12M7.5 11.4737L4 13.1316L9.89474 14.6053L11.3684 20.5L13 17M12 1.5C18.0751 1.5 23 6.42487 23 12.5C23 18.5751 18.0751 23.5 12 23.5C5.92487 23.5 1 18.5751 1 12.5C1 6.42487 5.92487 1.5 12 1.5Z"
        stroke="#999999"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CancelledFlightIcon;
