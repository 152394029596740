import React, { useMemo } from "react";

import { useScreenSize } from "globals/hooks";
import SingleColumnTableGenerator from "./SingleColumnTableGenerator";
import TwoColumnTableGenerator from "./TwoColumnTableGenerator";

type TableGeneratorType = {
  tableData: { [key: string]: number };
  tableHeaderText: string;
  totalAmount: number;
  hasTwoColumns?: boolean;
};

function TableGenerator(props: TableGeneratorType) {
  const { tableData, tableHeaderText, totalAmount, hasTwoColumns } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  const isTwoColumnTable = hasTwoColumns && !isMobileView;

  // get the rows and sort them
  const columnsData = useMemo(() => {
    if (!tableData) {
      return;
    }

    const columns = [];

    for (const label in tableData) {
      // rows
      columns.push({
        columnText: label,
        percentage: tableData[label] / totalAmount,
        amount: tableData[label] / 100,
      });
    }
    // sort by highest percentage to lowest.
    return columns.sort((a, b) => b.percentage - a.percentage);
  }, [tableData, totalAmount]);

  return (
    <>
      {/* For Vehicle Type Breakdown and mobile view Order Type */}
      {!isTwoColumnTable && (
        <SingleColumnTableGenerator
          columnsData={columnsData}
          tableHeaderText={tableHeaderText}
        />
      )}

      {/* For non mobile view Order Type Breakdown */}
      {isTwoColumnTable && (
        <TwoColumnTableGenerator
          columnsData={columnsData}
          tableHeaderText={tableHeaderText}
        />
      )}
    </>
  );
}

export default TableGenerator;
