import React from "react";
import includes from "lodash/includes";

import { Box, Card } from "@mui/material";

import { useScreenSize } from "../../../../globals/hooks";
import {
  Trip,
  Request,
  FarmedRouteStatusEnum,
  FarmRelationshipEnum,
} from "../../../../types";
import TripItineraryBlock from "./TripItineraryBlock/TripItineraryBlock";
import TripMap from "./TripMap";
import PassengerBlock from "./PassengerBlock";
import TripNotesBlock from "./TripNotesBlock";
import DispatchBlock from "./DispatchBlock";
import { FarmInDetailsBlock, FarmOutDetailsBlock } from "./farm";
import { ApolloQueryResult } from "@apollo/client";
import CommentUpdateBlock from "components/CommentUpdateBlock";

const { Farmee, Farmor } = FarmRelationshipEnum;
const { Accepted, Pending, Completed, Declined } = FarmedRouteStatusEnum;

type TripDetailsProps = {
  trip: Trip;
  request: Request;
  requestRefetch: (variables?: {
    id: string;
  }) => Promise<ApolloQueryResult<any>>;
};

function TripDetails(props: TripDetailsProps) {
  const { trip, request, requestRefetch } = props;
  const {
    farmRelationship,
    isFarmedRoute,
    farmStatus: farmedRouteStatus,
  } = trip?.routes[0] || {};

  // hooks
  const { isMobileView } = useScreenSize();

  const shouldShowDispatchBlockForFarmedRoute = includes(
    {
      [Farmee]: [Accepted, Declined, Pending, Completed],
      [Farmor]: [Declined],
    }[farmRelationship],
    farmedRouteStatus
  );

  const shouldShowFarmInDetailsBlock = includes(
    {
      [Farmee]: [Pending, Accepted, Completed],
      [Farmor]: [], // no case where we show farm in details as a farmor
    }[farmRelationship],
    farmedRouteStatus
  );

  const shouldShowFarmOutDetailsBlock = includes(
    {
      [Farmee]: [], //  no case where we show farm out details as a farmee
      [Farmor]: [Pending, Accepted, Completed, Declined],
    }[farmRelationship],
    farmedRouteStatus
  );

  const isCancelledTrip = !!trip.cancelledAt;
  const shouldShowDispatchBlock = isFarmedRoute
    ? shouldShowDispatchBlockForFarmedRoute
    : !isCancelledTrip;

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection={isMobileView ? "column" : "row"}>
        {/* trip itinerary/timeline */}
        <Box
          mr={isMobileView ? 0 : 2}
          mb={isMobileView ? 2 : 0}
          display="flex"
          flexDirection="column"
          flex="1"
        >
          <TripItineraryBlock trip={trip} />
          {/* trip notes */}
          <Box mt={2}>
            <TripNotesBlock trip={trip} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" flex="1">
          {/* map */}
          {!!trip.googleDirectionsResult && (
            <Box maxHeight={86} mb={2}>
              <TripMap request={request} trip={trip} />
            </Box>
          )}

          {/* Farming Blocks */}
          {isFarmedRoute && (
            <Box mb={2}>
              {/* farm in */}
              {shouldShowFarmInDetailsBlock && (
                <FarmInDetailsBlock route={trip.routes[0]} />
              )}

              {/* farm out */}
              {shouldShowFarmOutDetailsBlock && (
                <FarmOutDetailsBlock request={request} trip={trip} />
              )}
            </Box>
          )}

          {/* dispatch block */}
          {shouldShowDispatchBlock && (
            <Box mb={2}>
              <DispatchBlock request={request} trip={trip} />
            </Box>
          )}

          {/* passenger */}
          <Box mb={2}>
            <PassengerBlock trip={trip} />
          </Box>
        </Box>
      </Box>

      <Box>
        <Card variant="outlined">
          <Box p={2}>
            <CommentUpdateBlock
              mode={
                isFarmedRoute && farmRelationship === Farmee
                  ? "farmee"
                  : "tripDetails"
              }
              comments={trip.comments}
              tripId={trip.id}
              refetchQuery={requestRefetch}
            />
          </Box>
        </Card>
      </Box>
    </Box>
  );
}

export default TripDetails;
