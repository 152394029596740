import React from "react";

import { Typography } from "@mui/material";

import { Route } from "../../../types";
import { parseDateTime, parseDurationMinutes } from "./utils";
import TripTrackerDesktopRow from "./TripTrackerDesktopRow";
import { useScreenSize } from "globals/hooks";
import TripTrackerMobileBlock from "./TripTrackerMobileBlock";
import TripTrackerDesktopHeader from "./TripTrackerDesktopHeader";
import { getCustomerDateTimes } from "pages/requests/RequestOverview/ReservationOverview/CloseTripDialog/utils";

type TripTrackerProps = {
  route: Route;
};

function TripTracker(props: TripTrackerProps) {
  const { isMobileView } = useScreenSize();
  const { route } = props;

  const timezoneId = route?.trip.timezoneId;

  const {
    customerStartDateTime,
    customerEndDateTime,
    customerDuration,
    dropOffIsEstimate,
  } = getCustomerDateTimes(route, timezoneId);

  const {
    driverStartDateTime,
    driverEndDateTime,
    farmeeStartDateTime,
    farmeeEndDateTime,
  } = parseDateTime(route, timezoneId);

  const { driverDurationMinutes, farmeeDurationMinutes } =
    parseDurationMinutes(route);

  return (
    <>
      <Typography variant="h5">Trip Tracker</Typography>
      {/* Desktop Table Header */}
      {!isMobileView && (
        <TripTrackerDesktopHeader isFarmedRoute={route.isFarmedRoute} />
      )}
      {/* PICK UP */}
      {isMobileView ? (
        <TripTrackerMobileBlock
          title="Pick-Up"
          customerData={customerStartDateTime}
          driverData={driverStartDateTime}
          farmeeData={farmeeStartDateTime}
          isFarmedRoute={route.isFarmedRoute}
          stops={route.trip.stops}
        />
      ) : (
        <TripTrackerDesktopRow
          title="Pick-Up"
          customerData={customerStartDateTime}
          driverData={driverStartDateTime}
          farmeeData={farmeeStartDateTime}
          isFarmedRoute={route.isFarmedRoute}
          stops={route.trip.stops}
        />
      )}
      {/* DROP OFF */}
      {isMobileView ? (
        <TripTrackerMobileBlock
          title="Drop-Off"
          customerData={customerEndDateTime}
          driverData={driverEndDateTime}
          farmeeData={farmeeEndDateTime}
          isFarmedRoute={route.isFarmedRoute}
          dropOffIsEstimate={dropOffIsEstimate}
        />
      ) : (
        <TripTrackerDesktopRow
          title="Drop-Off"
          customerData={customerEndDateTime}
          driverData={driverEndDateTime}
          farmeeData={farmeeEndDateTime}
          isFarmedRoute={route.isFarmedRoute}
          dropOffIsEstimate={dropOffIsEstimate}
        />
      )}
      {/* Duration */}
      {isMobileView ? (
        <TripTrackerMobileBlock
          title="Duration"
          customerData={customerDuration}
          driverData={driverDurationMinutes}
          farmeeData={farmeeDurationMinutes}
          isFarmedRoute={route.isFarmedRoute}
          dropOffIsEstimate={dropOffIsEstimate}
        />
      ) : (
        <TripTrackerDesktopRow
          title="Duration"
          customerData={customerDuration}
          driverData={driverDurationMinutes}
          farmeeData={farmeeDurationMinutes}
          isFarmedRoute={route.isFarmedRoute}
          dropOffIsEstimate={dropOffIsEstimate}
        />
      )}
    </>
  );
}

export default TripTracker;
