import React from "react";

import { Box, Divider, Typography } from "@mui/material";

import EnableChatFlow from "./EnableChatFlow";
import { successGreen, white } from "../../../../design-system/colors";
import { CheckIcon } from "../../../../design-system/icons";
import { useOperator } from "../../../../globals/hooks";
import ContactsToggleBlock from "../ContactsToggleBlock/ContactsToggleBlock";
import DriversToggleBlock from "./DriversToggleBlock";
import InternalToggleBlock from "./InternalToggleBlock";
import ShuttleToggleBlock from "./ShuttleToggleBlock";
import { PlanVariant } from "types";
import MoovsChatNumberAndVoiceNumber from "./MoovsChatNumberAndVoiceNumber";

type ChatBlockProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function ChatBlock(props: ChatBlockProps) {
  const { setSaveIndicatorState } = props;

  // hooks
  const operator = useOperator();
  const hasTwilioPhoneNumber = !!operator.twilioPhoneNumber;

  return (
    <>
      <Box mt={4} mb={2}>
        <Typography variant="h3">Chat</Typography>
      </Box>
      <Box>
        {!!operator && !hasTwilioPhoneNumber && (
          <EnableChatFlow
            operatorData={operator}
            setSaveIndicatorState={setSaveIndicatorState}
          />
        )}

        {!!operator && hasTwilioPhoneNumber && (
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={1.5}
              bgcolor="rgba(208, 236, 221, 0.4)"
              borderRadius="4px"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="24px"
                height="24px"
                borderRadius="50%"
                mr={1.5}
                bgcolor={successGreen}
              >
                <CheckIcon color={white} size="small" />
              </Box>
              <Typography variant="body2">
                Chat Functionality Enabled
              </Typography>
            </Box>

            <MoovsChatNumberAndVoiceNumber
              setSaveIndicatorState={setSaveIndicatorState}
              operatorData={operator}
            />

            <Divider style={{ width: "100%" }} />

            <Box my={2}>
              <ContactsToggleBlock
                setSaveIndicatorState={setSaveIndicatorState}
              />
            </Box>

            <Box my={2}>
              <DriversToggleBlock
                setSaveIndicatorState={setSaveIndicatorState}
              />
            </Box>

            <Box my={2}>
              <InternalToggleBlock
                setSaveIndicatorState={setSaveIndicatorState}
              />
            </Box>

            {operator.plan === PlanVariant.Vip && (
              <Box my={2}>
                <ShuttleToggleBlock
                  setSaveIndicatorState={setSaveIndicatorState}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
}

export default ChatBlock;
