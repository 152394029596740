import React from "react";

type ReplaceVehicleIconProps = {
  color?: string;
  size?: "small";
};

const ReplaceVehicleIcon = (props: ReplaceVehicleIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5947 7.93768L13.5848 6.71273L15 7.67791M13.6324 6.90533C13.9413 8.17297 13.7938 9.5129 13.2149 10.6998C12.6359 11.8866 11.6608 12.848 10.4536 13.4222C9.24636 13.9964 7.88061 14.1483 6.58609 13.8525C5.92597 13.7016 5.30491 13.4391 4.74843 13.0809M3.4053 8.35309L2.23828 9.65194L1 8.61286M2.23828 9.08802C1.93143 7.82094 2.08018 6.48223 2.65957 5.29671C3.23895 4.11118 4.21369 3.15103 5.42009 2.57748C6.62649 2.00394 7.99109 1.85193 9.28474 2.14697C10.0011 2.31035 10.6715 2.60528 11.2634 3.01113M10.4 7.11827L9.94667 5.36427C9.90378 5.25716 9.82693 5.16487 9.72641 5.09976C9.6259 5.03466 9.50652 4.99985 9.38427 5H6.61573C6.49348 4.99985 6.3741 5.03466 6.27359 5.09976C6.17307 5.16487 6.09622 5.25716 6.05333 5.36427L5.6 7.11827M5.33333 9.1018H6.66667V9.4012C6.66667 9.73191 6.36819 10 6 10C5.63181 10 5.33333 9.73191 5.33333 9.4012V9.1018ZM9.33333 9.1018H10.6667V9.4012C10.6667 9.73191 10.3682 10 10 10C9.63181 10 9.33333 9.73191 9.33333 9.4012V9.1018ZM5.53333 7.11827H10.4667C10.6081 7.11827 10.7438 7.17084 10.8438 7.26442C10.9438 7.358 11 7.48493 11 7.61727V8.8523C11 8.91847 10.9719 8.98193 10.9219 9.02872C10.8719 9.07551 10.8041 9.1018 10.7333 9.1018H5.26667C5.19594 9.1018 5.12811 9.07551 5.0781 9.02872C5.0281 8.98193 5 8.91847 5 8.8523V7.61727C5 7.48493 5.05619 7.358 5.15621 7.26442C5.25623 7.17084 5.39188 7.11827 5.53333 7.11827Z"
          stroke={color || "#195FE9"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8582 11.4858L20.3572 9.5776L22.5 11.0811M20.4294 9.87761C20.897 11.8523 20.6737 13.9396 19.7972 15.7884C18.9206 17.6372 17.4442 19.1348 15.6163 20.0292C13.7884 20.9237 11.7205 21.1604 9.76045 20.6996C8.76096 20.4646 7.8206 20.0555 6.97802 19.4976M4.94438 12.1329L3.17739 14.1561L1.30249 12.5375M3.17739 13.2777C2.71278 11.3039 2.93801 9.21854 3.81526 7.37178C4.69251 5.52503 6.16836 4.02935 7.99499 3.13591C9.82161 2.24246 11.8878 2.00566 13.8465 2.46527C14.9311 2.71977 15.9462 3.1792 16.8424 3.81142"
        stroke={color || "#195FE9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.66689 12.5557H9.77798M14.2224 12.5557H15.3335M16.0001 10.5419L15.2446 8.78725C15.1731 8.5885 15.045 8.41725 14.8775 8.29645C14.71 8.17564 14.511 8.11105 14.3073 8.11133H9.6931C9.48935 8.11105 9.29038 8.17564 9.12286 8.29645C8.95534 8.41725 8.82726 8.5885 8.75578 8.78725L8.00023 10.5419M7.55579 14.2224H9.77798V14.7779C9.77798 15.3915 9.28053 15.889 8.66689 15.889C8.05325 15.889 7.55579 15.3915 7.55579 14.7779V14.2224ZM14.2224 14.2224H16.4446V14.7779C16.4446 15.3915 15.9471 15.889 15.3335 15.889C14.7198 15.889 14.2224 15.3915 14.2224 14.7779V14.2224ZM7.88912 10.5419H16.1112C16.347 10.5419 16.5731 10.6394 16.7398 10.813C16.9065 10.9867 17.0001 11.2222 17.0001 11.4678V13.7594C17.0001 13.8822 16.9533 13.9999 16.8699 14.0868C16.7866 14.1736 16.6735 14.2224 16.5557 14.2224H7.44468C7.32681 14.2224 7.21377 14.1736 7.13042 14.0868C7.04707 13.9999 7.00024 13.8822 7.00024 13.7594V11.4678C7.00024 11.2222 7.09389 10.9867 7.26059 10.813C7.42729 10.6394 7.65338 10.5419 7.88912 10.5419Z"
        stroke={color || "#195FE9"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReplaceVehicleIcon;
