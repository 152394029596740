import { gql } from "@apollo/client";

export const LOAD_CONTACTS_AND_AFFILIATES_QUERY = gql`
  query LoadContactsAndFarmAffiliates(
    $searchTerm: String
    $cursor: String
    $limit: Int
  ) {
    loadContactsAndFarmAffiliates(
      searchTerm: $searchTerm
      after: $cursor
      first: $limit
    ) {
      totalCount
      edges {
        node {
          ... on MoovsNetworkOperator {
            id
            operatorName
            operatorEmail
            operatorPhone
          }
          ... on Contact {
            id
            createdAt
            updatedAt
            firstName
            lastName
            email
            mobilePhone
            mobilePhoneInternational
            companyPosition
            preferences
            homeAddress
            workAddress
            paymentMethods {
              id
              createdAt
              updatedAt
              contactId
              cardNote
              isPrimary
              card {
                id
                expMonth
                expYear
                last4
                brand
              }
            }
            company {
              id
              name
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
