import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import {
  REMOVE_TRIP_MUTATION,
  UNDO_REMOVE_TRIP_MUTATION,
} from "globals/graphql";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { confirmationNumberFromRequest } from "globals/utils/helpers";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { Trip, Request } from "types";

type UseUpdateQuoteMutationProps = {
  request?: Request;
  trip?: Trip;
};

export const useUpdateQuoteMutation = (props: UseUpdateQuoteMutationProps) => {
  const { request, trip } = props;

  // hooks
  const history = useHistory();
  const { track } = useAnalytics();
  const snackbar = useSnackbar();

  // derived state
  const confirmationNumber = confirmationNumberFromRequest(request, trip);

  // mutations
  const [removeTrip] = useMutation(REMOVE_TRIP_MUTATION, {
    refetchQueries: ["Request", "Trip", "LoadTripConflict"],
    onCompleted(data) {
      track("quotes_deleted");
      snackbar.success(`Successfully deleted ${confirmationNumber}!`, {
        onUndo: () => handleUndoTripRemoval(data.removeTrip),
      });
    },
    onError(error) {
      const errorMessage =
        getErrorMessage(error) || `Error deleting ${confirmationNumber}`;

      snackbar.error(errorMessage);
    },
  });

  const [undoRemoveTrip] = useMutation(UNDO_REMOVE_TRIP_MUTATION, {
    refetchQueries: ["Request", "Trip", "LoadTripConflict"],
    onCompleted(data) {
      snackbar.success(`Successfully retrieved ${confirmationNumber}!`);
    },
    onError(error) {
      const errorMessage =
        getErrorMessage(error) || "Error retrieving deleted reservation.";

      snackbar.error(errorMessage);
    },
  });

  // handlers
  const handleRemoveTrip = () => {
    removeTrip({
      variables: {
        input: {
          tripId: trip.id,
        },
      },
    });
  };
  const handleUndoTripRemoval = (removedTrip) => {
    undoRemoveTrip({
      variables: {
        input: {
          tripId: trip.id,
          // only in case of deleting a trip on roundtrip.
          ...(trip.returnTrip?.id && { returnTripId: trip.returnTrip.id }),
          ...(removedTrip?.outboundTrip?.id && {
            outboundTripId: removedTrip.outboundTrip.id,
          }),
        },
      },
    });
    history.push(`/quotes/${request.id}/update/${trip.id}`);
  };

  return {
    handleRemoveTrip,
  };
};
