import React, { useState } from "react";
import filter from "lodash/filter";
import includes from "lodash/includes";
import lowerCase from "lodash/lowerCase";
import size from "lodash/size";

import { Box, InputAdornment, TextField, Typography } from "@mui/material";

import { ConversationType } from "globals/hooks/useChat/Provider";
import searchChat from "../../../design-system/illustrations/searchChat.svg";
import SearchIcon from "design-system/icons/actions/SearchIcon";
import { granite } from "design-system/colors";
import { useAnalytics } from "globals/hooks";
import { useDebounce } from "use-debounce";

export function useChatSearchBar(conversations: ConversationType[]) {
  // state
  const [searchText, setSearchText] = useState("");
  const [filteredConversations, setFilteredConversations] = useState([]);

  // hook
  const { track } = useAnalytics();
  const [debouncedConversations] = useDebounce(filteredConversations, 300);

  // event handlers
  const handleChange = (e) => {
    setSearchText(e.target.value);

    const filtered = filter(conversations, (item) => {
      const value = lowerCase(e.target.value);

      const phone = item.participant.mobilePhone.replace(/\D+/g, "");
      const name = lowerCase(item.participant.name) || "";

      const phoneValue = value.replace(/\D+/g, "");

      return (
        (phoneValue && includes(phone, phoneValue)) || includes(name, value)
      );
    });

    setFilteredConversations(filtered);
  };

  const handleOnClick = () => track("chat_conversationSearched");

  // search bar component
  const SearchBar = (
    <TextField
      fullWidth
      id="search"
      placeholder="Search name or number"
      value={searchText}
      onChange={handleChange}
      onClick={handleOnClick}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color={granite} />
          </InputAdornment>
        ),
      }}
    />
  );

  // if user searched and no search results, display empty state
  const emptySearchState = size(searchText) !== 0 &&
    size(debouncedConversations) === 0 && (
      <Box textAlign="center">
        <img src={searchChat} alt="search" style={{ width: 73, height: 73 }} />
        <Box mt={1} mb={1}>
          <Typography variant="h4">No Results</Typography>
        </Box>
        <Typography variant="body2">
          Try searching a different key word or checking for typos”
        </Typography>
      </Box>
    );

  // if no text in search box, show original list of conversations
  const conversationsOutput = size(searchText)
    ? debouncedConversations
    : conversations;

  return {
    SearchBar,
    conversationsOutput,
    emptySearchState,
  };
}
