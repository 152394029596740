import React from "react";

import { Button } from "@mui/material";

import { OpenIcon } from "../../design-system/icons";
import { primaryMainColor } from "theme";
import { useAnalytics } from "globals/hooks";

function AffiliateMapButton() {
  // hooks
  const { track } = useAnalytics();

  // event handlers
  const handleMapClick = () => {
    track("affiliates_mapViewSelected");
  };

  return (
    <Button
      onClick={handleMapClick}
      variant="outlined"
      color="primary"
      endIcon={<OpenIcon color={primaryMainColor} size="small" />}
      style={{ marginRight: 10 }}
      href="https://swoop.retool.com/embedded/public/5a48d6e0-cc09-4f64-8e8f-64a708759074"
      target="_blank"
    >
      Map View
    </Button>
  );
}

export default AffiliateMapButton;
