import { ChangeEvent } from "react";
import { DollarPercentVariant } from "./enums";

const { Dollar } = DollarPercentVariant;

type normalizeValueToBeDisplayedAsDollarParams = {
  value: number | string | null;
  variant: DollarPercentVariant;
};

// appropriately formats value for NumberFormatDollar
export const normalizeValueToBeDisplayedAsDollar = (
  params: normalizeValueToBeDisplayedAsDollarParams
) => {
  const { value, variant } = params;
  if (variant === Dollar && typeof value === "number") {
    return value.toFixed(2);
  }
  return value;
};

// if isPct, append percentage to the label
export const renderPercentFieldLabel = (label: string, percent: number) => {
  // limit percentage to 2 characters

  if (percent) {
    return `${label} (${Number(percent.toFixed(3))}%)`;
  }
  return label;
};

// if base rate variant is HOURLY, append hourly rate and hours to label
export const renderBaseRateFieldLabel = (
  hourlyBaseRate: number,
  hourlyBaseRateHours: number
) => {
  if (!!hourlyBaseRate && !!hourlyBaseRateHours) {
    const hourLabel = hourlyBaseRateHours === 1 ? "Hour" : "Hours";
    return `Base Rate ($${hourlyBaseRate.toFixed(
      2
    )} * ${hourlyBaseRateHours} ${hourLabel})`;
  }
  return "Base Rate";
};

// onInput prop returns the innerHTML text for e.target.value
// this function removes the prepended "$"
export const normalizeOnInputDollarValue = (
  e: ChangeEvent<HTMLInputElement>
) => {
  const eventValue = e.target.value;

  // regex removes all $ and , from string
  return Number(eventValue.replace(/\$|,/g, ""));
};
