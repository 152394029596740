import React from "react";
import { Route } from "react-router-dom";

import AuthLayout from "design-system/components/layout/AuthLayout";
import LoginPage from "pages/auth/LoginPage";
import NetflixLoginPage from "pages/auth/NetflixLoginPage";
import CreateAnAccountPage from "pages/auth/CreateAnAccountPage";
import ForgotPasswordPage from "pages/auth/ForgotPasswordPage";
import JoinTeamPage from "pages/auth/JoinTeamPage";

function AuthRouter() {
  return (
    <AuthLayout>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="/netflix/login">
        <NetflixLoginPage />
      </Route>
      <Route path="/sign-up">
        <CreateAnAccountPage />
      </Route>
      <Route path="/join-team/:userId">
        <JoinTeamPage />
      </Route>
      <Route path="/forgot-password">
        <ForgotPasswordPage />
      </Route>
    </AuthLayout>
  );
}

export default AuthRouter;
