/**
 * @file graphql/payments.ts
 * queries/mutations for Payments.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_PAYMENTS_QUERY = gql`
  query Payments {
    payments {
      typeName
      typeSlug
    }
  }
`;

export const LOAD_AMOUNT_AWAITING_PAYOUT = gql`
  query loadAwaitingPayoutAmounts {
    loadAwaitingPayoutAmounts {
      standard
      instant
    }
  }
`;

export const CREATE_TRIP_PAYMENT_MUTATION = gql`
  mutation CreateTripPayment($input: CreateTripPaymentInput!) {
    createTripPayment(input: $input) {
      payment {
        id
        amount
        action
        method
        note
        subPayments {
          id
          amount
        }
      }
    }
  }
`;

export const CREATE_REFUND_MUTATION = gql`
  mutation CreateRefund($input: CreateRefundInput!) {
    createRefund(input: $input) {
      refund {
        id
        createdAt
        updatedAt
        amount
        method
        action
        note
        payment {
          id
          amount
        }
      }
    }
  }
`;

export const CREATE_REQUEST_PAYMENT_MUTATION = gql`
  mutation CreateRequestPayment($input: CreateRequestPaymentInput!) {
    createRequestPayment(input: $input) {
      payment {
        id
        amount
        action
        method
        note
        subPayments {
          id
          amount
        }
      }
    }
  }
`;

export const CREATE_INVOICE_PAYMENT_MUTATION = gql`
  mutation CreateInvoicePayment($input: CreateInvoicePaymentInput!) {
    createInvoicePayment(input: $input) {
      invoice {
        id
      }
    }
  }
`;

export const LOAD_PAYMENT_QUERY = gql`
  query Node($id: ID!) {
    node(id: $id) {
      ... on Payment {
        id
        createdAt
        updatedAt
        amount
        action
        paymentStatus
        didPaymentSucceed
        paymentMethod {
          card {
            last4
            expMonth
            expYear
            brand
          }
        }
        paymentIntent {
          status
          declineCode
          amount
          fee
          net
          createdAt
          riskEvaluation
          chargeId
        }
        subPayments {
          id
          amount
          payment {
            id
          }
        }
      }
    }
  }
`;

export const LOAD_REFUND_QUERY = gql`
  query Node($id: ID!) {
    node(id: $id) {
      ... on PaymentRefund {
        id
        createdAt
        updatedAt
        amount
        paymentStatus
        paymentMethod {
          card {
            last4
            expMonth
            expYear
            brand
          }
        }
        payment {
          id
          paymentIntent {
            status
            declineCode
            amount
            fee
            net
            createdAt
            riskEvaluation
            chargeId
          }
        }
      }
    }
  }
`;

export const LOAD_PAYMENT_METHOD_TRANSACTIONS_QUERY = gql`
  query LoadTransactionsForPaymentMethod(
    $paymentMethodId: ID!
    $cursor: String
    $limit: Int
  ) {
    loadTransactionsForPaymentMethod(
      paymentMethodId: $paymentMethodId
      after: $cursor
      first: $limit
    ) {
      edges {
        node {
          ... on Payment {
            id
            amount
            createdAt
            didPaymentSucceed
            paymentStatusTooltip
            paymentStatus
            paymentPayer
            paymentIntent {
              riskEvaluation
            }
            method
            subPayments {
              id
              amount
              route {
                id
                trip {
                  id
                  tripNumber
                  request {
                    id
                    orderNumber
                  }
                }
              }
            }
          }
          ... on PaymentRefund {
            id
            amount
            createdAt
            payment {
              id
              didPaymentSucceed
              paymentStatusTooltip
              method
              paymentPayer
            }
            paymentStatus
            subRefunds {
              id
              amount
              route {
                id
                trip {
                  id
                  tripNumber
                  request {
                    id
                    orderNumber
                  }
                }
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const LOAD_REQUEST_TRANSACTIONS_QUERY = gql`
  query LoadTransactionsForRequest($requestId: ID!) {
    loadTransactionsForRequest(requestId: $requestId) {
      ... on Payment {
        id
        amount
        createdAt
        didPaymentSucceed
        paymentStatusTooltip
        paymentStatus
        paymentPayer
        paymentIntent {
          riskEvaluation
        }
        method
        invoice {
          id
        }
        paymentMethod {
          card {
            last4
            brand
            expMonth
            expYear
          }
        }
        subPayments {
          id
          amount
          route {
            id
            trip {
              id
              tripNumber
              request {
                id
                orderNumber
              }
            }
          }
        }
      }
      ... on PaymentRefund {
        id
        amount
        createdAt
        payment {
          id
          didPaymentSucceed
          paymentStatusTooltip
          method
          paymentPayer
          paymentMethod {
            card {
              last4
              brand
              expMonth
              expYear
            }
          }
        }
        paymentStatus
        subRefunds {
          id
          amount
          route {
            id
            trip {
              id
              tripNumber
              request {
                id
                orderNumber
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_AUTHORIZATION_PAYMENT_MUTATION = gql`
  mutation CreateAuthorizationPayment(
    $input: CreateAuthorizationPaymentInput!
  ) {
    createAuthorizationPayment(input: $input) {
      payment {
        id
      }
    }
  }
`;
