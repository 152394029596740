import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";

import { Box, CircularProgress } from "@mui/material";

import {
  LOAD_PAYMENT_METHOD_TRANSACTIONS_QUERY,
  LOAD_PAYMENT_METHOD_QUERY,
} from "../../../globals/graphql";
import MoovsDialog from "components/MoovsDialog";
import { LoadTransactionsForPaymentMethodEdge, PaymentMethod } from "types";
import PaymentMethodListItem from "components/contacts/paymentMethod/PaymentMethodListItem";
import CardTransactionList from "./CardTransactionList";
import EmptyTransactionsBlock from "pages/requests/RequestOverview/ReservationOverview/ViewReservationPaymentsDialog/components/EmptyTransactionsBlock";

type ViewCardPaymentsModalProps = {
  paymentMethodId?: string;
};

function ViewCardPaymentsModal(props: ViewCardPaymentsModalProps) {
  const { paymentMethodId } = props;

  // hooks
  const history = useHistory();

  // queries
  const {
    data: transactionsData,
    loading: transactionsLoading,
    fetchMore: transactionsFetchMore,
  } = useQuery(LOAD_PAYMENT_METHOD_TRANSACTIONS_QUERY, {
    variables: {
      paymentMethodId,
      limit: 20,
    },
    skip: !paymentMethodId,
    fetchPolicy: "network-only",
  });

  const { data: paymentMethodData, loading: paymentMethodLoading } = useQuery(
    LOAD_PAYMENT_METHOD_QUERY,
    {
      variables: {
        id: paymentMethodId,
      },
      skip: !paymentMethodId,
      fetchPolicy: "cache-first",
    }
  );

  // derived state
  const paymentMethod = useMemo<PaymentMethod>(
    () => paymentMethodData?.node,
    [paymentMethodData]
  );

  const transactions = useMemo<LoadTransactionsForPaymentMethodEdge[]>(
    () => transactionsData?.loadTransactionsForPaymentMethod?.edges,
    [transactionsData]
  );

  // event handlers
  const handleFetchMore = () => {
    transactionsFetchMore({
      variables: {
        cursor:
          transactionsData?.loadTransactionsForPaymentMethod?.pageInfo
            .endCursor,
      },
    });
  };

  return (
    <MoovsDialog
      dialogTitle="Payments Activity"
      open={!!paymentMethodId}
      onClose={() => {
        history.push({ ...history.location, search: "" });
      }}
      scrollableId="transactionsDialog"
    >
      <Box minHeight={"70vh"}>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          {paymentMethodLoading && !paymentMethod && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={50} />
            </Box>
          )}
          {paymentMethod && (
            <Box zIndex={1}>
              <PaymentMethodListItem paymentMethod={paymentMethod} />
            </Box>
          )}

          {isEmpty(transactions) ? (
            <Box
              display="flex"
              flexDirection="row"
              flex="1"
              justifyContent="center"
              alignItems="center"
            >
              {transactionsLoading ? (
                <CircularProgress size={75} />
              ) : (
                <EmptyTransactionsBlock
                  title="No Activity"
                  subtitle="No payment activity for this credit card"
                />
              )}
            </Box>
          ) : (
            <CardTransactionList
              loading={transactionsLoading}
              fetchMore={handleFetchMore}
              data={transactionsData.loadTransactionsForPaymentMethod}
            />
          )}
        </Box>
      </Box>
    </MoovsDialog>
  );
}

export default ViewCardPaymentsModal;
