import moment from "moment";

import { WorkflowSelectionsEnum } from "../steps/workflow/schemaValidation";
import { DynamicPricingInputType } from "./types";

enum AdjustEnum {
  Increase = "Increase",
  Decrease = "Decrease",
}

enum Symbol {
  Percent = "%",
  Dollar = "$",
}

type GetAdjustAmountWordsProps = {
  isIncrease: boolean;
  isPercent: boolean;
  amount: number;
};

type GenerateFormNameProps = {
  currentWorkflow: string;
  validatedInput: DynamicPricingInputType;
};

// example: increase 20%, decrease $20
export const getAdjustAmountWords = (props: GetAdjustAmountWordsProps) => {
  const { isIncrease, isPercent, amount } = props;
  const adjust = isIncrease ? AdjustEnum.Increase : AdjustEnum.Decrease;
  const displayAmount = isPercent
    ? `${amount}${Symbol.Percent}`
    : `${Symbol.Dollar}${amount}`;
  return { adjust, displayAmount };
};

// on overview step, this generates form name based off of user input
export const generateFormName = (props: GenerateFormNameProps) => {
  const { currentWorkflow, validatedInput } = props;

  const inputData = validatedInput[currentWorkflow];
  const { isIncrease, isPercent, amount } = inputData || {};

  const { adjust, displayAmount } = getAdjustAmountWords({
    isIncrease,
    isPercent,
    amount,
  });

  if (currentWorkflow === WorkflowSelectionsEnum.date) {
    const date = moment(inputData.startDate).format("M/DD");
    return `${date} ${adjust} ${displayAmount}`;
  }

  if (currentWorkflow === WorkflowSelectionsEnum.dateRange) {
    const startDate = moment(inputData.startDate).format("MMMM D");
    const endDate = moment(inputData.endDate).format("MMMM D");
    return `${startDate} to ${endDate} ${adjust} ${displayAmount}`;
  }

  if (currentWorkflow === WorkflowSelectionsEnum.timesOfDay) {
    const startTime = moment(inputData.startTime).format("h:mm A");
    const endTime = moment(inputData.endTime).format("h:mm A");
    return `${startTime} to ${endTime} ${adjust} ${displayAmount}`;
  }
};
