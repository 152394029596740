import { LagoPlanCode } from "types";

export const getLagoPlanCode = (
  planLength: "monthly" | "annual",
  planName: string
) => {
  if (planLength === "monthly") {
    return planName === "Standard"
      ? LagoPlanCode.StandardMonthly
      : planName === "Pro"
      ? LagoPlanCode.ProMonthly
      : null;
  }

  if (planLength === "annual") {
    return planName === "Standard"
      ? LagoPlanCode.StandardAnnual
      : planName === "Pro"
      ? LagoPlanCode.ProAnnual
      : null;
  }

  return null;
};
