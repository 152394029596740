import React from "react";
import { Box } from "@mui/material";
import first from "lodash/first";

import ChargeReservationActionButton from "./components/ChargeReservationActionButton";
import EditTripActionButton from "./components/EditTripActionButton";
import SendEmailActionButton from "./components/SendEmailActionButton";

import ViewMoreActionButton from "./components/ViewMoreActionButton/ViewMoreActionButton";
import { alabaster, grayLight } from "design-system/colors";
import { Trip, Request, TripCategory, FarmAffiliateVariantEnum } from "types";
import { deriveIsFarmee } from "globals/utils/farm";
import UpdatePricingActionButton from "./components/UpdatePricingActionButton";

export const MOBILE_ACTION_BAR_HEIGHT = 80;

type ActionButtonPanelMobileProps = {
  request: Request;
  trip: Trip;
};

function ActionButtonPanelMobile(props: ActionButtonPanelMobileProps) {
  const { request, trip } = props;

  // derived state
  const route = first(trip.routes);
  const isFarmee = deriveIsFarmee(route);
  const isShuttleReservation =
    trip.tripCategory === TripCategory.ShuttlePickUp ||
    trip.tripCategory === TripCategory.ShuttleReturn;
  const farmAffiliateIsMoovsAi =
    route.farmAffiliateVariant === FarmAffiliateVariantEnum.MoovsAiOperator;

  return (
    <Box
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      display="flex"
      justifyContent="space-evenly"
      alignItems="center"
      zIndex={1001}
      height={MOBILE_ACTION_BAR_HEIGHT}
      bgcolor={alabaster}
      borderTop={`1px solid ${grayLight}`}
    >
      {isFarmee ? (
        <>
          {farmAffiliateIsMoovsAi && (
            <EditTripActionButton
              requestId={request.id}
              tripId={trip.id}
              route={route}
            />
          )}
          <ChargeReservationActionButton request={request} trip={trip} />
          <UpdatePricingActionButton request={request} trip={trip} />
          <ViewMoreActionButton request={request} trip={trip} />
        </>
      ) : (
        <>
          <EditTripActionButton
            requestId={request.id}
            tripId={trip.id}
            isShuttleReservation={isShuttleReservation}
            route={route}
          />
          <SendEmailActionButton request={request} trip={trip} />
          <ChargeReservationActionButton request={request} trip={trip} />
          <ViewMoreActionButton request={request} trip={trip} />
        </>
      )}
    </Box>
  );
}

export default ActionButtonPanelMobile;
