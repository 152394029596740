import React from "react";

import { Typography, Box, Checkbox, Grid } from "@mui/material";

type OrderTypesTabItemsProps = {
  id: string;
  name: string;
  activeIds?: Array<string>;
  setActiveIds?: React.Dispatch<React.SetStateAction<any[]>>;
};

const OrderTypesTabItems = (props: OrderTypesTabItemsProps) => {
  const { id, name, activeIds, setActiveIds } = props;

  // maintain the state of checked and unchecked order types
  const handleCheckedChange = (id) => {
    const newActiveIds = [...activeIds];
    const idIndex = newActiveIds.indexOf(id);
    if (idIndex > -1) {
      newActiveIds.splice(idIndex, 1);
    } else {
      newActiveIds.push(id);
    }
    setActiveIds(newActiveIds);
  };

  return (
    <Grid item xs={12} sm={6}>
      <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
        <Checkbox
          color="primary"
          checked={activeIds.includes(id)}
          onChange={() => handleCheckedChange(id)}
          style={{ padding: 0 }}
        />
        <Box ml={2}>
          <Typography variant="body2">{name}</Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default OrderTypesTabItems;
