import React from "react";

type AirportIconProps = {
  color?: string;
  size?: "small";
};

const AirportIcon = (props: AirportIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#airport-icon-clip-path)">
          <path
            d="M1 15H15M13.755 1.14585C14.1063 1.23785 14.42 1.46094 14.6458 1.77924C14.8715 2.09754 14.9962 2.49255 14.9997 2.90076C15.0054 3.21131 14.94 3.51824 14.8099 3.79171C14.6798 4.06518 14.4894 4.29587 14.2572 4.46135L5.0958 10.9042C5.00186 10.9701 4.89287 11.0034 4.78238 10.9997C4.67189 10.9961 4.56478 10.9558 4.47438 10.8837L1.1162 8.21251C1.07941 8.17916 1.04983 8.13676 1.02969 8.08852C1.00956 8.04028 0.999416 7.98748 1.00003 7.93413C1.00064 7.88078 1.01199 7.82829 1.03321 7.78065C1.05444 7.73302 1.08498 7.6915 1.12252 7.65924L2.53155 6.25205C2.57288 6.21395 2.6222 6.18856 2.67473 6.17835C2.72726 6.16814 2.78123 6.17344 2.83142 6.19376L4.59722 7.38517L6.83722 5.61595L2.59207 2.63793C2.54808 2.5954 2.51309 2.54224 2.48966 2.48237C2.46624 2.42249 2.45498 2.35743 2.45671 2.29197C2.45844 2.22651 2.47313 2.16233 2.49968 2.10415C2.52623 2.04597 2.56398 1.99528 2.61013 1.95581L3.64793 1.08552C3.69918 1.0427 3.75898 1.01488 3.82201 1.00453C3.88504 0.994182 3.94935 1.00163 4.00922 1.0262L9.85941 3.49288L12.5691 1.40152C12.7404 1.26834 12.9342 1.17686 13.1384 1.13285C13.3425 1.08884 13.5525 1.09327 13.755 1.14585Z"
            stroke={color || "#B3B3B3"}
            strokeWidth="1.5528"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="airport-icon-clip-path">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 22H22M20.2214 2.20836C20.7233 2.33979 21.1715 2.65848 21.494 3.1132C21.8165 3.56791 21.9945 4.13222 21.9995 4.71537C22.0076 5.15901 21.9142 5.59749 21.7284 5.98816C21.5425 6.37883 21.2705 6.70839 20.9389 6.94479L7.85115 16.1488C7.71695 16.2431 7.56124 16.2905 7.4034 16.2853C7.24555 16.2801 7.09254 16.2225 6.96341 16.1196L2.166 12.3036C2.11344 12.2559 2.07118 12.1954 2.04242 12.1265C2.01366 12.0575 1.99917 11.9821 2.00004 11.9059C2.00091 11.8297 2.01712 11.7547 2.04745 11.6866C2.07777 11.6186 2.1214 11.5593 2.17503 11.5132L4.18793 9.50293C4.24697 9.44849 4.31743 9.41223 4.39247 9.39764C4.46751 9.38306 4.54461 9.39064 4.61631 9.41965L7.13889 11.1217L10.3389 8.59421L4.27438 4.3399C4.21155 4.27915 4.16156 4.2032 4.12809 4.11767C4.09463 4.03213 4.07854 3.93918 4.08101 3.84567C4.08349 3.75216 4.10447 3.66047 4.1424 3.57735C4.18033 3.49424 4.23425 3.42183 4.30019 3.36544L5.78276 2.12217C5.85597 2.061 5.94139 2.02125 6.03144 2.00647C6.12148 1.99169 6.21335 2.00232 6.29889 2.03743L14.6563 5.56126L18.5273 2.5736C18.772 2.38335 19.0489 2.25265 19.3405 2.18979C19.6321 2.12692 19.9321 2.13324 20.2214 2.20836Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5528"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AirportIcon;
