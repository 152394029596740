import React from "react";
import includes from "lodash/includes";
import { useHistory } from "react-router-dom";

import { Box, Divider, Link, Typography } from "@mui/material";

import FarmAffiliateIcon, {
  getFarmAffiliateIconVariant,
} from "../../../../../../components/contacts/farmAffiliates/FarmAffiliateIcon";
import {
  black,
  grayMedium,
  reservationPaymentStatusLabelColors,
  moovsBlue,
} from "../../../../../../design-system/colors";
import { useScreenSize } from "../../../../../../globals/hooks";
import {
  currency,
  getColorForFarmStatus,
} from "../../../../../../globals/utils/helpers";
import { FarmedRouteStatusEnum, Request, Trip } from "../../../../../../types";
import RoundChip from "../../../../../../design-system/components/chips/RoundChip";
import DispatchBlock from "../../DispatchBlock";
import FarmOutActionSection from "./FarmOutActionSection";

type FarmOutDetailsBlockProps = {
  request: Request;
  trip: Trip;
};

function FarmOutDetailsBlock(props: FarmOutDetailsBlockProps) {
  const { request, trip } = props;
  const route = trip.routes[0];

  const {
    farmStatus,
    farmAffiliateVariant,
    farmoutNote,
    farmeePricing: { totalAmt, amountPaid, paymentStatus },
    farmAffiliate: { operatorName, id: farmAffiliateId },
  } = route;

  const { farmAffiliate } = route;

  // hooks
  const { isSmallTabletView } = useScreenSize();
  const history = useHistory();

  // event handlers
  const handleAffiliateNameClick = () => {
    history.push(`/affiliates/update/${farmAffiliateId}`);
  };

  const { Accepted, Pending, Completed } = FarmedRouteStatusEnum;

  const shouldShowDispatchBlock: FarmedRouteStatusEnum[] = [
    Pending,
    Accepted,
    Completed,
  ];

  const iconVariant = getFarmAffiliateIconVariant({
    farmAffiliate,
    farmAffiliateVariant,
  });

  return (
    <>
      <Box
        bgcolor={getColorForFarmStatus.bgcolor(farmStatus)}
        display="flex"
        flexDirection="column"
        flex="1"
        borderRadius="4px"
        boxShadow="0px 4px 15px rgba(0, 0, 0, 0.03)"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          mb={1}
          mt={2}
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="row" alignItems="center" ml={2}>
            <Typography variant="h5" style={{ marginRight: 8 }}>
              FARM-OUT
            </Typography>

            <FarmAffiliateIcon variant={iconVariant} />
          </Box>
        </Box>

        {/* route info */}
        <Box px={2} pb={2}>
          <Box
            display="flex"
            flexDirection={isSmallTabletView ? "column" : "row"}
          >
            <Box mr={3} {...(isSmallTabletView && { mb: 1 })}>
              <Typography variant="overline">payable</Typography>
              <Typography variant="subtitle1">{currency(totalAmt)}</Typography>
            </Box>
            <Box mr={3}>
              <Typography variant="overline">amount paid</Typography>
              <Typography variant="body2">{currency(amountPaid)}</Typography>
            </Box>
            <Box>
              <Typography variant="overline">status</Typography>
              <Box>
                <RoundChip
                  {...reservationPaymentStatusLabelColors[paymentStatus]}
                />
              </Box>
            </Box>
          </Box>
          <Box mt={1}>
            <Typography variant="overline">affiliate</Typography>
            <Link
              sx={{
                cursor: "pointer",
                textDecoration: "none",
                color: black,
                "&:hover": {
                  color: moovsBlue,
                  textDecorationLine: "underline",
                },
              }}
              onClick={handleAffiliateNameClick}
            >
              <Typography variant="body2">{operatorName}</Typography>
            </Link>
          </Box>
          <Box mt={1}>
            <Typography variant="overline">note</Typography>
            <Typography variant="body2">{farmoutNote}</Typography>
          </Box>
          <Box mt={2} mb={3}>
            <Divider style={{ backgroundColor: grayMedium }} />
          </Box>
          {/* bottom below divider section */}
          <FarmOutActionSection farmStatus={farmStatus} trip={trip} />

          {includes(shouldShowDispatchBlock, farmStatus) && (
            <Box mt={2}>
              <DispatchBlock request={request} trip={trip} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default FarmOutDetailsBlock;
