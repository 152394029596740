import VehicleIcon from "design-system/illustrations/dynamicPricingChips/vehicle.svg";
import IncreaseIcon from "design-system/illustrations/dynamicPricingChips/increase.svg";
import DecreaseIcon from "design-system/illustrations/dynamicPricingChips/decrease.svg";
import DateIcon from "design-system/illustrations/dynamicPricingChips/date.svg";
import WeekdayIcon from "design-system/illustrations/dynamicPricingChips/weekday.svg";
import RepeatIcon from "design-system/illustrations/dynamicPricingChips/repeat.svg";
import TimeIcon from "design-system/illustrations/dynamicPricingChips/time.svg";

export enum IconNames {
  weekday = "weekday",
  increase = "increase",
  decrease = "decrease",
  date = "date",
  vehicle = "vehicle",
  repeat = "repeat",
  time = "time",
}

export const dynamicPricingChips = {
  weekday: WeekdayIcon,
  increase: IncreaseIcon,
  decrease: DecreaseIcon,
  date: DateIcon,
  vehicle: VehicleIcon,
  repeat: RepeatIcon,
  time: TimeIcon,
};
