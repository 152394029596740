import React from "react";
import { Control, Controller } from "react-hook-form";

import { Select, MenuItem } from "@mui/material";

type RHFSingleSelectProps = {
  control: Control<any>;
  name: string;
  options: { id: string; value: boolean; label: string }[];
  defaultLabel: string;
  disabled: boolean;
  errors: string;
};

function RHFSingleSelect(props: RHFSingleSelectProps) {
  const { name, control, options, defaultLabel, disabled } = props;

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultLabel}
        render={({ field }) => {
          return (
            <Select {...field} fullWidth disabled={disabled}>
              {options?.map((option) => {
                return (
                  <MenuItem value={option.value as any} key={option.id}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
          );
        }}
      />
    </>
  );
}

export default RHFSingleSelect;
