import React from "react";

import { Box, Button, Card, Typography } from "@mui/material";

import {
  black,
  errorRed,
  moovsBlue,
  moovsBlueDark,
} from "design-system/colors";
import { GettingStartedGoal } from "types";

type OperatorGettingStartedGoalProps = {
  onClick: (goal: GettingStartedGoal) => void;
  gettingStartedGoal: GettingStartedGoal;
  errorMessage: string;
  GoalIcon: (props) => JSX.Element;
  goalVariant: GettingStartedGoal;
  title: string;
  subtitle: string;
};

function OperatorGettingStartedGoal(props: OperatorGettingStartedGoalProps) {
  const {
    onClick,
    gettingStartedGoal,
    errorMessage,
    GoalIcon,
    goalVariant,
    title,
    subtitle,
  } = props;

  return (
    <Button fullWidth onClick={() => onClick(goalVariant)} sx={{ padding: 0 }}>
      <Card
        variant="outlined"
        sx={{
          width: "100%",
          padding: 2,
          backgroundColor: "#FFFFFF99",
          borderColor:
            gettingStartedGoal === goalVariant
              ? moovsBlue
              : errorMessage
              ? errorRed
              : "#739CFF80",
        }}
      >
        <Box display="flex" mt={0.5}>
          <Box mr={2}>
            <GoalIcon
              color={gettingStartedGoal === goalVariant ? moovsBlue : black}
            />
          </Box>
          <Box>
            <Typography
              fontWeight={500}
              mb={0.5}
              textAlign="left"
              color={gettingStartedGoal === goalVariant ? moovsBlueDark : black}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              textAlign="left"
              color={gettingStartedGoal === goalVariant ? moovsBlue : black}
            >
              {subtitle}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Button>
  );
}

export default OperatorGettingStartedGoal;
