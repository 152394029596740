/**
 * @file useSegment.tsx
 * Abstracts wrapping SegmentContext with useContext.
 * Main point is to prevent importing two functions
 * when you want access to SegmentContext.
 *
 */
import { useContext } from "react";
import { Analytics } from "@segment/analytics-next";
import SegmentContext from "./Context";

// see useSegment/Provider.tsx for implementation
function useSegment() {
  return useContext<Analytics>(SegmentContext);
}

export default useSegment;
