import React, { Fragment } from "react";
import moment from "moment-timezone";

import { Typography, Box, Divider } from "@mui/material";

import { Comment } from "../../types";
import MoovsAvatar from "../../design-system/components/MoovsAvatar";
import { granite } from "../../design-system/colors";
import MoovsDialog from "../MoovsDialog";
import { useCurrentUser } from "../../globals/hooks";
import MoovsTooltip from "../MoovsTooltip";

type InternalCommentsDialogProps = {
  open: boolean;
  handleClose: () => void;
  comments: Comment[];
};
function InternalCommentsDialog(props: InternalCommentsDialogProps) {
  const { open, handleClose, comments } = props;

  return (
    <MoovsDialog
      onClose={handleClose}
      open={open}
      dialogTitle=" Internal Comments"
      fixedFooter
    >
      <Box height="100%">
        {comments?.length ? (
          comments.map((comment: Comment, index) => (
            <Fragment key={comment.id}>
              {index !== 0 && <Divider />}
              <Box py={2} component="div">
                <CommentListItem comment={comment} />
              </Box>
            </Fragment>
          ))
        ) : (
          <Box mt={1}>
            <Typography>No comments.</Typography>
          </Box>
        )}
      </Box>
    </MoovsDialog>
  );
}

export default InternalCommentsDialog;

type CommentListItemProps = {
  comment: Comment;
};

export function CommentListItem(props: CommentListItemProps) {
  const { comment } = props;

  // hooks
  const currentUser = useCurrentUser();

  const commentUserFirstName = comment.user.firstName;
  const commentUserLastName = comment.user.lastName;

  const commentUser =
    comment.user.id === currentUser.id
      ? "You"
      : `${commentUserFirstName} ${commentUserLastName}`;

  const commentTime = moment(comment.updatedAt).fromNow();
  const tooltipCommentTime = moment(comment.updatedAt).format("LLL");

  const commentBodyTextStyles = {
    commentBodyText: {
      fontWeight: 400,
      overflowWrap: "anywhere",
      wordBreak: "break-word", //deprecated, but needed for safari
    },
  } as const;

  return (
    <Box
      display="flex"
      flexDirection="row"
      flex="1"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <MoovsAvatar placeholder={[commentUserFirstName, commentUserLastName]} />
      <Box display="flex" flexDirection="column" ml={1.5}>
        <Box display="flex" flexDirection="row">
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {commentUser}
          </Typography>
          <Box ml={1.5}>
            <MoovsTooltip tooltipText={tooltipCommentTime}>
              <Typography variant="caption" sx={{ color: granite }}>
                {commentTime}
              </Typography>
            </MoovsTooltip>
          </Box>
        </Box>
        <Box mt={1}>
          <Typography variant="body2" sx={commentBodyTextStyles}>
            {comment.bodyText}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
