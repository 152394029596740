import React, {
  useState,
  Fragment,
  useEffect,
  useCallback,
  ChangeEvent,
} from "react";
import { DeepPartial } from "ts-essentials";
import { useMutation, useLazyQuery } from "@apollo/client";
import last from "lodash/last";
import first from "lodash/first";
import size from "lodash/size";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";
import isNil from "lodash/isNil";
import { useHistory, useLocation } from "react-router-dom";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { Box } from "@mui/material";

import {
  Request,
  Trip,
  Contact,
  Vehicle,
  Stop,
  RoutePricing,
  TripCategory,
  Company,
  VehicleChildSeat,
} from "types";
import { useSnackbar } from "globals/hooks/useSnackbar";
import {
  CREATE_REQUEST_MUTATION,
  LOAD_AUTOMATED_BASE_RATE,
} from "globals/graphql";
import { useLocalStorage } from "globals/hooks/useLocalStorage";
import {
  useOperator,
  useAnalytics,
  useScreenSize,
  useStripeBillingDetailsElement,
} from "globals/hooks";
import { calculateTotalDurationMinutes } from "globals/utils/stops";
import { shapeStopsToAutomatedBaseRateQueryStopsArg } from "globals/graphql/automatedPricing";
import CreateDrawer from "../../../globals/CreateDrawer";
import AddVehicleCreateBlock from "../../../vehicles/AddVehicleCreateBlock";
import CommentCreateBlock from "../../../CommentCreateBlock";
import {
  initialRequestTemplate,
  initialRequestErrors,
  convertTripToBeInputReady,
  removeStop,
  createStop,
} from "globals/utils/createRequests";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { intialStripeElementErrors } from "globals/utils/stripeCreditCardIntialValues";
import { initialLuggageFields } from "components/requests/luggage/initialValues";
import { useCreateRequestTripType } from "./hooks/useCreateRequestTripType";
import {
  AdditionalInfoCreateBlock,
  CreateCreditCardBlock,
  OrderDetailsCreateBlock,
  PricingInfoCreateBlock,
  TripDetailsCreateBlock,
  TripInfoCreateBlock,
} from "./components";
import { parsePricingLayoutForInitialRoute } from "./helpers/parsePricingLayoutForInitialRoute";
import { getChildSeatOptionsFromVehicle } from "./helpers/getChildSeatOptionsFromVehicle";
import { getContactForReservation } from "globals/utils/contact";
import { useLaunchDarklyFlags } from "globals/utils/useLaunchDarklyFlags";
import { trackReservations } from "globals/utils/tracking/trackReservations";

function CreateReservationDrawer() {
  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const { track } = useAnalytics();
  const { enableLinkedPassenger } = useLaunchDarklyFlags();
  const { settings, enabledOrderTypes, enabledTripCategories } =
    useOperator() || {};
  const { isMobileView } = useScreenSize();
  const elements = useElements();
  const stripe = useStripe();

  const pageLabel = "Create New Reservation";
  const modeEnum = "RESERVATION";
  const localStorageKey = "submitReservationSettings";
  const getSnackbarLink = (requestId: string) => `/reservations/${requestId}`;
  const snackbarLinkLabel = "View Reservation";
  const getCloseDrawerLink = (requestId?: string) => {
    return tripType === TripCategory.RoundTrip
      ? `/reservations/${requestId}/add-return-trip/`
      : "/reservations";
  };
  const { BillingDetailsElement, getBillingDetails } =
    useStripeBillingDetailsElement();

  // state
  const [request, setRequest] = useState<DeepPartial<Request>>(
    JSON.parse(JSON.stringify(initialRequestTemplate))
  );
  const [requestErrors, setRequestErrors] = useState(
    JSON.parse(JSON.stringify(initialRequestErrors))
  );
  const [saveErrorText, setSaveErrorText] = useState("");
  const [selectedSubmitButtonIndex, setSelectedSubmitButtonIndex] =
    useLocalStorage(localStorageKey, 0);
  const [suggestedAddressInfo, setSuggestedAddressInfo] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [createNewContact, setCreateNewContact] = useState(false);
  const [bookingContact, setBookingContact] =
    useState<DeepPartial<Contact | null>>(null);
  const [company, setCompany] = useState<DeepPartial<Company>>(null);

  const [isVehicleBaseRateAutomated, setIsVehicleBaseRateAutomated] =
    useState(false);
  const [automatedBaseRate, setAutomatedBaseRate] = useState(null);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [hasTransferPricingFields, setHasTransferPricingFields] =
    useState(false);
  const [hasEnteredCardInfo, setHasEnteredCardInfo] = useState(false);
  const [stripeElementsError, setStripeElementsError] = useState(
    intialStripeElementErrors
  );
  const [cardNote, setCardNote] = useState("");
  const [cardHolderEmail, setCardHolderEmail] = useState(null);
  const [luggageAmount, setLuggageAmount] = useState(initialLuggageFields);
  const [isBaseRateAutomationClicked, setIsBaseRateAutomationClicked] =
    useState(false);
  const [linkCompany, setLinkCompany] = useState(false);
  const [childSeatOptions, setChildSeatOptions] = useState<VehicleChildSeat[]>(
    []
  );

  // more hooks
  const { tripType, onTripTypeChange, submitLabelOptions } =
    useCreateRequestTripType({
      enabledTripCategories,
      setRequest,
      defaultSubmitLabelOptions: [
        "Save reservation",
        "Save reservation & send email",
      ],
    });

  // query for automated base rate
  const [getAutomatedBaseRate, { loading: automatedBaseRateLoading }] =
    useLazyQuery(LOAD_AUTOMATED_BASE_RATE, {
      onCompleted(data) {
        setAutomatedBaseRate(data.automatedBaseRate);
      },
      onError(error) {
        const errorMessage =
          getErrorMessage(error) || "Failed to get automated base rate";

        snackbar.error(errorMessage);
      },
      fetchPolicy: "network-only",
    });

  // derived state
  const stops = request.trips[0].stops;

  const vehicleId = request.trips[0].routes[0].vehicle?.id;
  const hasEnabledVehicleBRA =
    request.trips[0].routes[0].vehicle?.enableBaseRateAutomation;
  const shouldSendEmail = selectedSubmitButtonIndex === 1;
  const isTransfer = !request.trips[0].routes[0].pricing?.hourlyBaseRateHours;

  // mutations
  const [createNewRequest] = useMutation(CREATE_REQUEST_MUTATION, {
    onCompleted(data) {
      const request = data.createNewRequest.request;
      trackReservations(track, request, "reservation_created");

      if (shouldSendEmail) {
        track("reservation_savedAndSent");
      }

      const isRoundTrip = tripType === TripCategory.RoundTrip;

      const snackbarText = isRoundTrip
        ? "Pick-up trip successfully saved"
        : `Successfully saved reservation`;

      snackbar.success(snackbarText, {
        ...(!isRoundTrip && {
          link: getSnackbarLink(data.createNewRequest.request.id),
          onLinkClick: () => {
            track("snackbar_reservation_viewed");
          },
          linkLabel: snackbarLinkLabel,
        }),
      });

      handleCreateRequestDrawerClose(data.createNewRequest.request);
    },
    onError(error) {
      setSubmitDisabled(false);
      const errorMessage =
        getErrorMessage(error) || "Error creating reservation";

      snackbar.error(errorMessage);
    },
    refetchQueries: ["Requests", "Operator"],
  });

  // event handlers
  const updateTrip = useCallback(
    (newTrip: DeepPartial<Trip>, tripIndex: number) => {
      setRequest((prevRequest) => {
        const newTrips = [...prevRequest.trips];
        newTrips.splice(tripIndex, 1, newTrip);
        return {
          ...prevRequest,
          trips: newTrips,
        };
      });
    },
    []
  );

  const handleCreateRequestDrawerClose = (request?: Request) => {
    elements?.getElement(CardElement)?.clear();
    elements?.getElement("address")?.clear();

    setTimeout(() => {
      tripType === TripCategory.RoundTrip && request
        ? history.push({
            pathname: `${getCloseDrawerLink(request?.id)}${location.search}`,
            state: { request, trip: first(request?.trips), from: "create" },
          })
        : history.push({
            search: location.search,
            pathname: getCloseDrawerLink(),
          });
    }, 200);
  };

  const updateRoute =
    (tripIndex: number) =>
    (targetName: string, targetValue: number | string | boolean) => {
      const newRoutes = [...request.trips[tripIndex].routes];
      const newRoute = newRoutes[0];

      newRoute[targetName] = targetValue;
      const newTrip = { ...request.trips[tripIndex], routes: newRoutes };

      updateTrip(newTrip, tripIndex);
    };

  const handleCreateStop = (tripIndex: number) => () => {
    const newTrips = createStop(request.trips, tripIndex);

    const stopsErrors = [...requestErrors.stops];
    const lastStopErrors = stopsErrors.pop();
    requestErrors.stops = [...requestErrors.stops];

    setRequestErrors({
      ...requestErrors,
      stops: [
        ...stopsErrors,
        {
          location: "",
          dateTime: "",
          stopIndex: requestErrors.stops.length,
        },
        {
          ...lastStopErrors,
          stopIndex: requestErrors.stops.length + 1,
        },
      ],
    });

    setRequest({
      ...request,
      trips: newTrips,
    });
  };

  const handleRemoveStop = (tripIndex: number) => (stopIndex: number) => () => {
    const newTrips = removeStop(request.trips, tripIndex, stopIndex);

    setRequest({
      ...request,
      trips: newTrips,
    });

    requestErrors.stops.splice(stopIndex - 1, 1);

    setRequestErrors({
      ...requestErrors,
      stops: requestErrors.stops,
    });
  };

  const updateRequest = (update: Partial<Request>) => {
    setRequest({
      ...request,
      ...update,
    });
  };

  const addVehicles = (tripIndex: number) => (vehicles: Partial<Vehicle>[]) => {
    const vehicle = first(vehicles);
    const vehicleHasTransferPricingFields =
      !isNil(vehicle.minimumTotalBaseRate) &&
      !isNil(vehicle.deadheadRatePerMile) &&
      !isNil(vehicle.tripRatePerMile);

    setHasTransferPricingFields(!!vehicleHasTransferPricingFields);
    setIsVehicleBaseRateAutomated(vehicle.enableBaseRateAutomation);

    const childSeats = getChildSeatOptionsFromVehicle(vehicle);
    setChildSeatOptions(childSeats);

    const newTrips = [...request.trips];
    newTrips[tripIndex].routes[0].vehicle = { id: vehicle.id };

    setRequest({
      ...request,
      trips: newTrips,
    });
  };

  const removeVehicle = (tripIndex: number) => () => {
    setIsVehicleBaseRateAutomated(false);
    setAutomatedBaseRate(null);
    setChildSeatOptions([]);
    const newTrips = [...request.trips];
    delete newTrips[tripIndex].routes[0].vehicle;

    setRequest({
      ...request,
      trips: newTrips,
    });
    setIsBaseRateAutomationClicked(false);
  };

  const handleDeleteCardElement = () => {
    const card = elements.getElement(CardElement);
    card.clear();
  };

  const handleCreditCardInputChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.name === "cardNote") {
      setCardNote(event.target.value);
    }

    if (event.target.name === "cardHolderEmail") {
      setCardHolderEmail(event.target.value);
    }
  };

  const handleSubmitReservationClick = async () => {
    let errors = [];
    let paymentMethodErrors;
    let stripePaymentMethod;

    let newSaveErrorText = "Oops! Please check the above fields for errors.";

    if (!request.orderType) {
      errors = [...errors, { type: "Please choose order type" }];
    }
    // payment method
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet.
      return;
    }
    setSubmitDisabled(true);

    const card = elements.getElement(CardElement);

    // create stripe payment method
    if (card !== null && hasEnteredCardInfo) {
      const billingDetails = await getBillingDetails();
      if (!billingDetails) {
        setSubmitDisabled(false);
        return;
      }

      const { paymentMethod, error: stripePaymentMethodError } =
        await stripe.createPaymentMethod({
          type: "card",
          card: card,
          billing_details: {
            ...billingDetails,
            email: cardHolderEmail || null,
          },
        });

      if (!stripePaymentMethodError) {
        stripePaymentMethod = paymentMethod;
      } else {
        paymentMethodErrors = stripePaymentMethodError.message;
      }
    }

    // booking contact
    if (!bookingContact) {
      errors = [...errors, { bookingContact: "Please add a booking contact" }];
    }

    // vehicle errors
    if (!request.trips[0].routes[0].vehicle) {
      errors = [...errors, { vehicle: "Please select a vehicle" }];
    }

    // stop errors
    const stopErrors = request.trips[0].stops.map((stop) => {
      const addressErrors = {
        location: !stop.location ? "Please enter location" : "",
      };

      const airportErrors = {
        airport: !stop.airport ? "Please select an Airport" : "",
        airline:
          !!stop.flightNumber && !stop.airline
            ? "Please select an Airline"
            : "",
      };

      const isLocationError = !!addressErrors.location;
      const isAirportError = Object.values(airportErrors).some(
        (error) => !!error.length
      );

      const isPickUpDateTimeError = !stop.dateTime && stop.stopIndex === 1;
      const isDropOffDateTimeError =
        tripType === TripCategory.Hourly &&
        !stop.dateTime &&
        stop.stopIndex === size(first(request.trips).stops);

      const dateTimeErrors = {
        dateTime:
          isPickUpDateTimeError || isDropOffDateTimeError
            ? "Please enter date"
            : "",
      };

      // if error in both show errors
      // if only one has an error that means it
      // isnt the variant that is selected
      if (isLocationError && isAirportError) {
        return {
          ...addressErrors,
          ...airportErrors,
          ...dateTimeErrors,
        };
      }

      return {
        ...dateTimeErrors,
      };
    });

    const isErrorStops = stopErrors.some((error) =>
      Object.values(error).some((value) => value.length)
    );

    if (errors.length > 0 || isErrorStops || paymentMethodErrors) {
      errors = [...errors, { stops: stopErrors }];

      setRequestErrors(
        errors.reduce((acc, value) => ({ ...acc, ...value }), {})
      );

      setSaveErrorText(newSaveErrorText);
      setStripeElementsError({
        ...stripeElementsError,
        createStripePaymentMethodError: paymentMethodErrors,
      });
      setSubmitDisabled(false);
      return;
    }

    // sets type as input type (as stop input is different than stop type)
    const requestInput: DeepPartial<Request> = cloneDeep(request);

    // converts airport and airlines from objects to just the code for input
    requestInput.trips = requestInput.trips.map((trip: Trip) =>
      convertTripToBeInputReady(trip, luggageAmount)
    );

    // set default booking contact if nothing is picked
    requestInput.trips = requestInput.trips.map((trip: Trip) => {
      if (!trip.contact?.firstName) {
        trip.contact = getContactForReservation(
          bookingContact as Contact
        ) as Contact;
      } else {
        trip.contact = getContactForReservation(trip.contact) as Contact;
      }
      return trip;
    });

    setSaveErrorText("");

    createNewRequest({
      variables: {
        input: {
          ...requestInput,
          isLinkedPassengerEnabled: enableLinkedPassenger,
          bookingContactId: bookingContact.id,
          shouldSendEmail,
          mode: modeEnum,
          stripePaymentMethod,
          cardNote,
          linkCompany,
          ...(isBaseRateAutomationClicked && {
            baseRateAutomation: {
              total: automatedBaseRate.total,
              lineItems: automatedBaseRate.lineItems.map((lineItem) =>
                omit(lineItem, "__typename")
              ),
            },
          }),
        },
      },
    });
  };

  const handleTripCommentChange =
    (trip: Trip, tripIndex: number) => (value: string) => {
      updateTrip(
        {
          ...trip,
          comments: [{ bodyText: value }],
        },
        tripIndex
      );
    };

  const handleClearBookingContact = () => {
    setBookingContact({
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      mobilePhone: "",
    });

    setCompany(null);

    setHasPaymentMethod(false);

    // reset passenger when booking contact is cleared
    setRequest((prevRequest) => {
      const trip = prevRequest.trips[0];
      const tripContactId = trip.contact.id;
      const bookingContactId = bookingContact?.id;

      // if booking contact is same as first trip passenger,
      // clear passenger
      if (tripContactId !== bookingContactId) {
        return prevRequest;
      }
      return {
        ...prevRequest,
        trips: [
          {
            ...trip,
            contact: {
              id: "",
              firstName: "",
              workAddress: "",
              homeAddress: "",
              lastName: "",
              email: "",
              mobilePhone: "",
            },
          },
        ],
      };
    });
  };

  // effects

  // if requirements are met, get automated pricing
  useEffect(() => {
    const firstStop = first(stops);
    const lastStop = last(stops);
    // if trip is transfer it must have transfer pricing fields in order to get BRA
    const hasRequiredPricingFields = isTransfer
      ? hasTransferPricingFields
      : true;
    if (
      isVehicleBaseRateAutomated &&
      (firstStop.location || firstStop.airport) &&
      firstStop.dateTime &&
      (lastStop.location || lastStop.airport) &&
      hasRequiredPricingFields
    ) {
      getAutomatedBaseRate({
        variables: {
          vehicleId: vehicleId,
          // TODO: Remove 'Partial' Utility types from types in this file and redefine types as necessary.
          // If properly resolved, removing this type cast should automatically resolve to Stop[].
          stops: shapeStopsToAutomatedBaseRateQueryStopsArg(stops as Stop[]),
        },
      });
    }
  }, [
    vehicleId,
    isVehicleBaseRateAutomated,
    getAutomatedBaseRate,
    stops,
    hasTransferPricingFields,
    isTransfer,
  ]);

  // suggested addresses
  useEffect(() => {
    let keys = [];
    request.trips.forEach((trip) => {
      if (trip.contact.workAddress) {
        keys.push({
          address: trip.contact.workAddress,
          firstName: trip.contact.firstName,
          lastName: trip.contact.lastName,
          mode: "workAddress",
        });
      }
      if (trip.contact.homeAddress) {
        keys.push({
          address: trip.contact.homeAddress,
          firstName: trip.contact.firstName,
          lastName: trip.contact.lastName,
          mode: "homeAddress",
        });
      }
    });

    if (!isEqual(keys, suggestedAddressInfo)) {
      setSuggestedAddressInfo(keys);
    }
  }, [request, suggestedAddressInfo]);

  // populate initial request with data from pricingLayout
  useEffect(() => {
    setRequest((prevRequest) => {
      const trip = prevRequest.trips[0];

      return {
        ...prevRequest,
        trips: [
          {
            ...trip,
            routes: trip.routes.map((route: any) => {
              return {
                ...route,
                ...parsePricingLayoutForInitialRoute(settings?.pricingLayout),
              };
            }),
          },
        ],
      };
    });
  }, [settings?.pricingLayout]);

  // populate trip's passenger with booking contact
  // for new quotes/reservations
  useEffect(() => {
    if (bookingContact) {
      setRequest((prevRequest) => {
        const trip = prevRequest.trips[0];
        if (!bookingContact.linkedPassengers) {
          bookingContact.linkedPassengers = [];
        }
        const contactMap = {
          0: bookingContact,
          1: bookingContact.linkedPassengers[0],
        };

        const size = bookingContact?.linkedPassengers?.length;
        const contact = enableLinkedPassenger
          ? contactMap[size] || {
              id: "",
              firstName: "",
              workAddress: "",
              homeAddress: "",
              lastName: "",
              email: "",
              mobilePhone: "",
            }
          : bookingContact;
        if (size === 1 && enableLinkedPassenger) {
          track("linkPassenger_autoAdded");
        }
        return {
          ...prevRequest,
          trips: [
            {
              ...trip,
              contact,
            },
          ],
        };
      });
    }
    /**
     * no need to add track to dependency array, causes performance issues
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingContact, enableLinkedPassenger]);

  return (
    <CreateDrawer
      onClose={handleCreateRequestDrawerClose}
      pageLabel={pageLabel}
      onSubmit={handleSubmitReservationClick}
      saveError={!!saveErrorText}
      saveErrorText={saveErrorText}
      submitLabelOptions={submitLabelOptions}
      selectedSubmitButtonIndex={selectedSubmitButtonIndex}
      setSelectedSubmitButtonIndex={setSelectedSubmitButtonIndex}
      submitDisabled={submitDisabled}
    >
      <Box alignItems="flex-start" data-testid="create-request-drawer">
        {/* Order Details */}
        <Box my={4}>
          <OrderDetailsCreateBlock
            bookingContact={bookingContact}
            requestErrors={requestErrors}
            setRequestErrors={setRequestErrors}
            requestType={request.orderType}
            updateRequest={updateRequest}
            enabledOrderTypes={enabledOrderTypes}
            createNewContact={createNewContact}
            setCreateNewContact={setCreateNewContact}
            setBookingContact={setBookingContact}
            onClearBookingContact={handleClearBookingContact}
            setHasPaymentMethod={setHasPaymentMethod}
            tripType={tripType}
            onTripTypeChange={onTripTypeChange}
            enabledTripCategories={enabledTripCategories}
            company={company}
            setCompany={setCompany}
            isQuote={false}
            linkCompany={linkCompany}
            setLinkCompany={setLinkCompany}
          />
        </Box>
        {request.trips.map((trip: Trip, tripIndex: number) => (
          <Fragment key={tripIndex}>
            {/* Trip Details */}
            <TripDetailsCreateBlock
              trip={trip}
              bookingContact={bookingContact as Contact}
              updateTrip={updateTrip}
              tripIndex={tripIndex}
              companyId={linkCompany ? company?.id : null}
            />
            <Box my={4}>
              <TripInfoCreateBlock
                trip={trip}
                updateTrip={updateTrip}
                tripIndex={tripIndex}
                onCreateStop={handleCreateStop(tripIndex)}
                onRemoveStop={handleRemoveStop(tripIndex)}
                requestErrors={requestErrors}
                setRequestErrors={setRequestErrors}
                suggestedAddressInfo={suggestedAddressInfo}
                tripType={tripType}
              />
            </Box>
            <Box my={4}>
              <AdditionalInfoCreateBlock
                trip={trip}
                updateTrip={updateTrip}
                tripIndex={tripIndex}
                requestType={request.orderType}
                setLuggageAmount={setLuggageAmount}
                luggageAmount={luggageAmount}
              />
            </Box>
            <AddVehicleCreateBlock
              requestErrors={requestErrors}
              addVehicles={addVehicles(tripIndex)}
              removeVehicle={removeVehicle(tripIndex)}
              vehicle={trip.routes[0].vehicle}
              stops={trip.stops}
            />
            <PricingInfoCreateBlock
              // initialized request template uses deprecated route pricing (pricing field on route type instead of on route.pricing)
              routePricing={trip.routes[0] as unknown as RoutePricing}
              updateRoute={updateRoute(tripIndex)}
              requestErrors={requestErrors}
              setRequestErrors={setRequestErrors}
              totalDurationMinutes={calculateTotalDurationMinutes(trip.stops)}
              baseRateAmtError={requestErrors.baseRateAmt}
              BRAProps={{
                automatedBaseRate,
                automatedBaseRateLoading,
                showAutomatedBaseRateIcon: isMobileView && !!automatedBaseRate,
                vehicleId,
                hasEnabledVehicleBRA,
                stops: trip.stops,
              }}
              isVehicleBaseRateAutomated={isVehicleBaseRateAutomated}
              setIsBaseRateAutomationClicked={setIsBaseRateAutomationClicked}
              childSeatOptions={childSeatOptions}
            />
            {!!bookingContact && !hasPaymentMethod && (
              <Box my={4}>
                <CreateCreditCardBlock
                  stripeElementsError={stripeElementsError}
                  onChange={handleCreditCardInputChange}
                  onDeleteCardElement={handleDeleteCardElement}
                  setHasEnteredCardInfo={setHasEnteredCardInfo}
                  billingDetailsElement={<BillingDetailsElement />}
                />
              </Box>
            )}
            <Box mt={7} mb={4}>
              <CommentCreateBlock
                commentBodyText={trip.comments[0].bodyText}
                onChangeComment={handleTripCommentChange(trip, tripIndex)}
              />
            </Box>
          </Fragment>
        ))}
      </Box>
    </CreateDrawer>
  );
}

export default CreateReservationDrawer;
