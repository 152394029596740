import React from "react";

import { Box, Select, MenuItem, Typography } from "@mui/material";

import { Vehicle } from "../../../types";
import { FilterIcon } from "../../../design-system/icons";
import { grayDark, grayLight, white } from "../../../design-system/colors";
import { useScreenSize } from "../../../globals/hooks";

type AppointmentsFilterProps = {
  filterOptions: Vehicle[];
  onChange: (value: any) => void;
  currentFilterValue: string;
  appointmentFilterRef: any;
};

function AppointmentsFilter(props: AppointmentsFilterProps) {
  const { currentFilterValue, filterOptions, onChange, appointmentFilterRef } =
    props;

  const { isMobileView } = useScreenSize();

  const styles = {
    selectContainer: {
      "& .MuiOutlinedInput-root": {
        width: "100%",
      },
    },
  };

  return (
    <Box bgcolor={white} {...({ ref: appointmentFilterRef } as any)}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        {...(isMobileView && {
          borderBottom: `1px solid ${grayLight}`,
        })}
      >
        <Box {...(isMobileView && { ml: 2 })} mr={1}>
          <FilterIcon color={grayDark} />
        </Box>
        {isMobileView && (
          <Box mr={2}>
            <Typography variant="body2">Filter</Typography>
          </Box>
        )}
        <Box
          {...(isMobileView && { styles: styles.selectContainer })}
          flex="1"
          mr={2}
        >
          <Select
            variant="outlined"
            value={currentFilterValue}
            onChange={(e) => onChange(e.target.value)}
            sx={{ maxHeight: 38, padding: 1 }}
          >
            <MenuItem key="all" value={"all"}>
              All
            </MenuItem>
            {filterOptions?.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
    </Box>
  );
}

export default AppointmentsFilter;
