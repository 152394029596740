import { RequestStage } from "types";

export const getRequestStage = (
  stage:
    | RequestStage.Quote
    | RequestStage.Reservation
    | RequestStage.Unconfirmed
) => {
  return {
    [RequestStage.Quote]: "quote",
    [RequestStage.Reservation]: "reservation",
    [RequestStage.Unconfirmed]: "reservation",
  }[stage];
};
