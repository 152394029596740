import React from "react";

import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";

import { useScreenSize } from "globals/hooks";
import { MarketApp } from "../../MoovsMarketAppList/data/appData";

type InstallAppButtonProps = {
  handleInstallButtonClick: () => void;
  loading: boolean;
  appEnum: MarketApp;
};

const InstallAppButton = (props: InstallAppButtonProps) => {
  const { handleInstallButtonClick, loading, appEnum } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  let buttonText = "Install App";

  if (appEnum === MarketApp.GoogleCalendar) {
    buttonText = "Install and Authorize Calendar";
  }

  return (
    <Box mr={!isMobileView && 2}>
      <LoadingButton
        loading={loading}
        variant="contained"
        onClick={handleInstallButtonClick}
        fullWidth={isMobileView}
      >
        {buttonText}
      </LoadingButton>
    </Box>
  );
};

export default InstallAppButton;
