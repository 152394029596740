/**
 * TODO: Replace CreatePaymentDialog with this component.
 */

import React from "react";

import MoovsDialog from "../../MoovsDialog";
import { currency } from "../../../globals/utils/helpers";
import { PaymentMethod, PaymentPayer, Trip } from "../../../types";
import PaymentSummarySection from "../../invoices/PaymentSummarySection";
import ChargePayerFields from "./components/ChargePayerFields";
import { useChargePayer } from "./useChargePayer";
import { TripChargeDetails } from "./types";
import { getInitialPaymentMethod } from "./utils";

type ChargePayerDialogProps = {
  onClose: () => void;
  tripChargeDetailsList: TripChargeDetails[];
  requestId: string;
  payerBeingCharged: {
    payerType: PaymentPayer;
    payerId: string;
  };
  paymentMethods: PaymentMethod[];
  preferredPaymentMethod: PaymentMethod | null;
  amountDue: number;
  companyPaymentMethods?: PaymentMethod[];
  trip: Trip;
};

function ChargePayerDialog(props: ChargePayerDialogProps) {
  const {
    onClose,
    tripChargeDetailsList: initialTripChargeDetailsList,
    payerBeingCharged: { payerType },
    payerBeingCharged,
    paymentMethods,
    companyPaymentMethods,
    preferredPaymentMethod,
    requestId,
    amountDue,
    trip,
  } = props;

  const handleClose = () => {
    onClose();
  };

  const initialPaymentMethod = getInitialPaymentMethod(
    paymentMethods,
    preferredPaymentMethod,
    trip.contact
  );

  const {
    // input field values
    inputDollarAmt,
    inputWholePercentValue,
    note,
    paymentMethodValue,

    // other states
    tripChargeDetailsList,
    submitDisabled,
    inputErrors,

    // event handlers
    onCreateCharges,
    onDollarInputChange,
    onMethodInputChange,
    onPercentInputChange,
    onNoteInputChange,
    onDollarPercentVariantChange,
  } = useChargePayer({
    tripChargeDetailsList: initialTripChargeDetailsList,
    amountDue,
    onClose: handleClose,
    requestId,
    payerBeingCharged,
    initialPaymentMethod,
  });

  const { dialogTitle } = {
    [PaymentPayer.Customer]: {
      dialogTitle: "Charge Customer",
    },
    [PaymentPayer.Farmor]: {
      dialogTitle: "Charge Farmor",
    },
  }[payerType];

  return (
    <MoovsDialog
      open
      onAccept={onCreateCharges}
      acceptButtonText={`Charge ${currency(inputDollarAmt)}`}
      onClose={handleClose}
      size="sm"
      dialogTitle={dialogTitle}
      acceptDisabled={submitDisabled}
      fixedFooter
    >
      <PaymentSummarySection
        beforeAmountDue={amountDue}
        totalAmount={inputDollarAmt}
      />
      <ChargePayerFields
        inputDollarAmt={inputDollarAmt}
        inputWholePercentValue={inputWholePercentValue}
        note={note}
        paymentMethodValue={paymentMethodValue}
        paymentMethods={paymentMethods}
        companyPaymentMethods={companyPaymentMethods}
        preferredPaymentMethod={preferredPaymentMethod}
        tripChargeDetailsList={tripChargeDetailsList}
        inputErrors={inputErrors}
        onDollarPercentVariantChange={onDollarPercentVariantChange}
        onMethodInputChange={onMethodInputChange}
        onDollarInputChange={onDollarInputChange}
        onPercentInputChange={onPercentInputChange}
        onNoteInputChange={onNoteInputChange}
      />
    </MoovsDialog>
  );
}

export default ChargePayerDialog;
