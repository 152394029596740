/**
 * @file useAuth.tsx
 * Abstracts wrapping AuthContext with useContext.
 * Main point is to prevent importing two functions
 * when you want access to AuthContext.
 *
 */
import { useContext } from "react";
import AuthContext from "./Context";

type AuthContextType = {
  authStage: "pending" | "authenticated" | "rejected";
  onLogin: (token: string) => Promise<any>;
  onLogout: () => Promise<void>;
};

// see useAuth/Provider.tsx for implementation
function useAuth() {
  return useContext<AuthContextType>(AuthContext);
}

export default useAuth;
