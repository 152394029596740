import { Helmet } from "react-helmet";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import kebabCase from "lodash/kebabCase";

import { TabContext, TabPanel } from "@mui/lab";
import { Box, CircularProgress, Typography } from "@mui/material";

import SavedIndicatorChip from "design-system/components/chips/SaveIndicatorChip";
import { useOperator, useScreenSize } from "globals/hooks";
import SettingsTabs, { TabConfig } from "../components/SettingsTabs";
import {
  BrandingTab,
  InstallationAndShareTab,
  PaymentsTab,
  PromoCodesTab,
  SettingsTab,
} from "./components";
import { styles } from "../general/GeneralSettingsPage";

function CustomerPortalPage() {
  // hooks
  const operator = useOperator();
  const { isMediumTabletView } = useScreenSize();
  const history = useHistory();
  const location = useLocation();

  // state
  const [tabIndex, setTabIndex] = useState<string>("0");
  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "loading" | "saved" | "error"
  >("default");

  // event handler
  const handleSetTabIndex = (tabIndex) => {
    const tabQuery = kebabCase(customerPortalTabs[tabIndex].label);
    history.push(`/settings/customer-portal?tab=${tabQuery}`);
  };

  // effects
  useEffect(() => {
    const query = parse(location.search);

    if (query?.tab) {
      const tabIndex = customerPortalTabs.findIndex(
        (tab) => query.tab === kebabCase(tab.label)
      );

      tabIndex > -1 && setTabIndex(`${tabIndex}`);
    } else {
      // set default tab
      history.replace(`/settings/customer-portal?tab=installation-share`);
    }
  }, [history, location.search]);

  // tab props
  const tabComponentProps = {
    setSaveIndicatorState,
  };

  return (
    <>
      <Helmet>
        <title>Customer Portal | Moovs</title>
      </Helmet>
      {!operator ? (
        <Box
          width="100%"
          height="100px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={40} thickness={4} />
        </Box>
      ) : (
        <Box
          flexDirection="column"
          sx={styles.container}
          style={{
            maxWidth: "100%",
            padding: isMediumTabletView ? "0" : "0 100px",
          }}
        >
          <TabContext value={tabIndex}>
            <Box position="sticky" sx={styles.fixedToTop}>
              <Box flexDirection="row" flexWrap="wrap" sx={styles.header}>
                <Typography variant="h2">Customer Portal</Typography>
                <Box>
                  <SavedIndicatorChip mode={saveIndicatorState} />
                </Box>
              </Box>
              <SettingsTabs
                isMobileView={isMediumTabletView}
                tabIndex={tabIndex}
                setTabIndex={handleSetTabIndex}
                tabs={customerPortalTabs}
              />
            </Box>

            {/* Tab Content */}
            <Box>
              {customerPortalTabs.map((tab, index) => (
                <TabPanel
                  key={index}
                  value={index.toString()}
                  sx={{ padding: "30px 20px" }}
                >
                  {tab.component(tabComponentProps)}
                </TabPanel>
              ))}
            </Box>
          </TabContext>
        </Box>
      )}
    </>
  );
}

const customerPortalTabs: TabConfig[] = [
  {
    label: "Installation & Share",
    component: (props) => <InstallationAndShareTab {...props} />,
  },
  {
    label: "Payments",
    component: (props) => <PaymentsTab {...props} />,
  },
  {
    label: "Settings",
    component: (props) => <SettingsTab {...props} />,
  },
  {
    label: "Branding",
    component: (props) => <BrandingTab {...props} />,
  },
  {
    label: "Promo Codes",
    component: (props) => <PromoCodesTab {...props} />,
  },
];

export default CustomerPortalPage;
