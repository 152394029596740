import { useQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";

import { LOAD_STRIPE_ACCOUNT_QUERY } from "globals/graphql";

function useStripeAccount() {
  // query
  const {
    data: stripeBankAccount,
    loading: stripeBankAccountLoading,
    error: stripeBankAccountError,
  } = useQuery(LOAD_STRIPE_ACCOUNT_QUERY);

  // derived state
  const bankAccounts = stripeBankAccount?.stripeAccount?.bankAccounts;
  const accountStatus = stripeBankAccount?.stripeAccount?.status;
  const accountStatusDescription =
    stripeBankAccount?.stripeAccount?.statusDescription;
  const isAccountSetupComplete =
    stripeBankAccount?.stripeAccount?.isAccountSetupComplete;
  const isDefaultBankAccountEligibleForInstant = isEmpty(bankAccounts)
    ? false
    : find(bankAccounts, "isDefault")?.isInstantPayoutEligible;

  return {
    bankAccounts,
    accountStatus,
    accountStatusDescription,
    isAccountSetupComplete,
    isDefaultBankAccountEligibleForInstant,
    stripeBankAccountLoading,
    stripeBankAccountError,
  };
}

export { useStripeAccount };
