import React from "react";

import { Typography, Box, CircularProgress } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { useLoadPromoCodeResults } from "./hooks/useLoadPromoCodeResults";
import {
  promoCodeResultsGridColumns,
  PromoCodeResultsHeader,
} from "./components";
import { useScreenSize } from "globals/hooks";

type PromoCodeResultsGridProps = {
  promoCodeId: string;
  sx?: Record<string, unknown>;
};

function PromoCodeResultsGrid(props: PromoCodeResultsGridProps) {
  const { promoCodeId, sx } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const { trips, tripsLoading } = useLoadPromoCodeResults({ promoCodeId });

  return (
    <Box height={isMobileView ? "65%" : "70%"} sx={sx}>
      <Box pl={3}>
        <PromoCodeResultsHeader trips={trips} tripsLoading={tripsLoading} />
        <Box my={1.5}>
          <Typography variant="h3" fontSize={"20px"}>
            Trips
          </Typography>
        </Box>
      </Box>
      {tripsLoading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width="100%"
          height="100%"
        >
          <CircularProgress size={40} thickness={2} />
        </Box>
      ) : (
        <DataGridPro
          sx={{ border: "none" }}
          disableColumnFilter
          disableColumnPinning
          disableSelectionOnClick
          disableChildrenSorting
          disableChildrenFiltering
          disableColumnMenu
          disableColumnReorder
          disableMultipleColumnsSorting
          aria-label="Promo Code Results Table"
          paginationMode="server"
          sortingMode="server"
          loading={tripsLoading}
          columns={promoCodeResultsGridColumns}
          rows={trips}
        />
      )}
    </Box>
  );
}

export default PromoCodeResultsGrid;
