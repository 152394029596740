import React from "react";

type CheckFilledIconProps = {
  color?: string;
};

const CheckFilledIcon = (props: CheckFilledIconProps) => {
  const { color } = props;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="30" height="30" rx="15" fill="#28B668" />
      <rect
        x="1"
        y="1"
        width="30"
        height="30"
        rx="15"
        stroke={color || "#28B668"}
        strokeWidth="2"
      />
      <path
        d="M22 12.25L13.75 20.5L10 16.75"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckFilledIcon;
