import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import { useMutation } from "@apollo/client";

import { Box, Divider, Typography } from "@mui/material";

import { LabeledInlineInput } from "design-system/components/inputs";
import { DriverStatus, RouteDriver } from "types";
import DriverAutoComplete from "../autocompletes/DriverAutoComplete";
import ProfileInfoCard from "../globals/ProfileInfoCard";
import { UPDATE_OPERATOR_ROUTE_MUTATION } from "globals/graphql";
import {
  useAnalytics,
  usePrevious,
  useSendDriverAssignNotification,
  useSnackbar,
} from "globals/hooks";
import { errorRed, moovsBlue } from "design-system/colors";
import {
  CalendarUnavailableIcon,
  CancelTripIcon,
  TrashIcon,
} from "design-system/icons";
import AvailabilityConflictDialog from "components/AvailabilityConflictDialog";

type DriverUpdateBlockProps = {
  operatorRouteId: string;
  driver: RouteDriver;
  driverNote: string;
  setSaveIndicatorState: (
    saveState: "loading" | "default" | "saved" | "error"
  ) => void;
  driverStatus: DriverStatus;
  farmAffiliateId?: string;
};

function DriverUpdateBlock(props: DriverUpdateBlockProps) {
  const {
    operatorRouteId,
    driver: routeDriver,
    setSaveIndicatorState,
    driverNote,
    driverStatus,
    farmAffiliateId,
  } = props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const { onSendDriverAssignNotification } = useSendDriverAssignNotification();

  // state
  const [driverDetailsState, setDriverDetailsState] = useState({
    driverNote: driverNote,
  });
  const [availabilityConflictDialogOpen, setAvailabilityConflictDialogOpen] =
    useState(false);
  const [conflictedDriver, setConflictedDriver] = useState<RouteDriver>(null);

  const [showDriverNoteInput, setShowDriverNoteInput] = useState(!!driverNote);
  const prevShowDriverNoteInput = usePrevious(showDriverNoteInput);

  const [debouncedDriver] = useDebounce(driverDetailsState, 500);
  const initialMountFlag = useRef(false);
  const driverNoteInputRef = useRef(null);

  // mutations
  const [updateOperatorRoute] = useMutation(UPDATE_OPERATOR_ROUTE_MUTATION, {
    onCompleted(data) {
      setSaveIndicatorState("saved");

      const isAssigningDriver =
        !!data.updateOperatorRoute.operatorRoute.routeDriver?.id &&
        data.updateOperatorRoute.operatorRoute.routeDriver.id !==
          routeDriver?.id;

      if (isAssigningDriver) {
        track("dispatch_driverAssigned");
        onSendDriverAssignNotification(
          data.updateOperatorRoute.operatorRoute.id
        );
      }
    },
    onError(error) {
      console.error(error);
      setSaveIndicatorState("error");
      snackbar.error("Error updating driver");
    },
    refetchQueries: ["OperatorRoute"],
  });

  // event handlers
  const handleDebouncedUpdateOperatorRoute = useCallback(() => {
    setSaveIndicatorState("loading");
    updateOperatorRoute({
      variables: {
        input: {
          ...debouncedDriver,
          id: operatorRouteId,
        },
      },
    });
  }, [
    debouncedDriver,
    operatorRouteId,
    setSaveIndicatorState,
    updateOperatorRoute,
  ]);

  const handleDriverSelect = async (driver: RouteDriver) => {
    if (
      !driver.routeAvailability.available ||
      !driver.personalAvailability.available
    ) {
      setConflictedDriver(driver);
      setAvailabilityConflictDialogOpen(true);
    } else {
      handleAssignDriver(driver);
    }
  };

  const handleAssignDriver = async (routeDriver: RouteDriver) => {
    setAvailabilityConflictDialogOpen(false);
    setSaveIndicatorState("loading");

    await updateOperatorRoute({
      variables: {
        input: {
          id: operatorRouteId,
          driverId: routeDriver.driver.id,
        },
      },
    });
  };

  const handleDriverRemove = () => {
    setSaveIndicatorState("loading");

    updateOperatorRoute({
      variables: {
        input: {
          id: operatorRouteId,
          driverId: null,
        },
      },
    });
  };

  const handleDriverDetailsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDriverDetailsState({
      ...driverDetailsState,
      [event.target.name]: event.target.value,
    });
  };

  const handleDriverNoteFieldRender = () => {
    setShowDriverNoteInput(true);
  };

  // effects
  useEffect(() => {
    // prevents updating on initial load
    if (!initialMountFlag.current) {
      initialMountFlag.current = true;
    } else {
      handleDebouncedUpdateOperatorRoute();
    }
  }, [debouncedDriver, handleDebouncedUpdateOperatorRoute]);

  useEffect(() => {
    if (prevShowDriverNoteInput === false && showDriverNoteInput) {
      driverNoteInputRef?.current?.focus();
    }
  }, [showDriverNoteInput, prevShowDriverNoteInput]);

  return (
    <Box>
      <Box mt={4} mb={1.5}>
        <Typography variant="h5">Driver Details</Typography>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      {!routeDriver?.driver.id ? (
        <Box mb={1}>
          <DriverAutoComplete
            value={routeDriver}
            onDriverAutoCompleteChange={handleDriverSelect}
            farmAffiliateId={farmAffiliateId}
            routeId={operatorRouteId}
          />
        </Box>
      ) : (
        <ProfileInfoCard
          headerText="Driver Contact"
          firstName={routeDriver.driver.firstName}
          lastName={routeDriver.driver.lastName}
          email={routeDriver.driver.email}
          phone={routeDriver.driver.mobilePhone}
          profilePhoto={routeDriver.driver.driverProfilePhoto}
          actionButtons={[
            { icon: <TrashIcon />, eventHandler: handleDriverRemove },
          ]}
          infoIcons={
            !routeDriver.routeAvailability?.available
              ? [
                  {
                    label: "Trip Conflict",
                    icon: <CancelTripIcon color={errorRed} />,
                  },
                ]
              : !routeDriver.personalAvailability?.available
              ? [
                  {
                    label: "Availability Conflict",
                    icon: <CalendarUnavailableIcon color={errorRed} />,
                  },
                ]
              : []
          }
          driverStatus={driverStatus}
        />
      )}
      <Box mt={2.5} mb={5}>
        {showDriverNoteInput ? (
          <LabeledInlineInput
            multiline
            name="driverNote"
            value={driverDetailsState.driverNote}
            label="Driver Note"
            onChange={handleDriverDetailsChange}
            placeholder="-"
            InputProps={{ inputRef: driverNoteInputRef }}
          />
        ) : (
          <Box onClick={handleDriverNoteFieldRender}>
            <Typography
              variant="body2"
              style={{ color: moovsBlue, fontWeight: 500, cursor: "pointer" }}
            >
              Add a driver note
            </Typography>
          </Box>
        )}
      </Box>
      <AvailabilityConflictDialog
        open={availabilityConflictDialogOpen}
        onClose={() => setAvailabilityConflictDialogOpen(false)}
        onAssign={() => handleAssignDriver(conflictedDriver)}
        routeDriver={conflictedDriver}
      />
    </Box>
  );
}

export default DriverUpdateBlock;
