import map from "lodash/map";

import { Contact, PaymentMethod, Trip } from "./../../../types";
import { TripChargeDetails } from "./types";

export const shapeTripsToTripChargeDetailsList = (
  trips: Trip[],
  totalAmountDue: number
) =>
  trips.map((trip) => ({
    decimalPercentOfAmountDue: trip.amountDue / totalAmountDue,
    amountDue: trip.amountDue,
    amountToCharge: trip.amountDue,
    tripNumber: trip.tripNumber,
    routeId: trip.routes[0].id,
  }));

// last charge is the remaining input dollar amount, instead of percentage of amount due
// this ensures the sum of all trip charges matches the user's input amount
export const ensureTripChargeDetailsSumToInputDollarAmt = (
  tripChargeDetails: TripChargeDetails[],
  inputDollarAmt: number
) => {
  let accumulatedChargeAmount = 0;

  return map(tripChargeDetails, (chargeDetails, index) => {
    const isNotLastTripCharge = index !== tripChargeDetails.length - 1;

    if (isNotLastTripCharge) {
      const amountToCharge =
        inputDollarAmt * chargeDetails.decimalPercentOfAmountDue;

      // round charge amount to the nearest hundredth
      const roundedChargeAmount = Math.round(amountToCharge * 100) / 100;

      accumulatedChargeAmount += roundedChargeAmount;

      return {
        ...chargeDetails,
        amountToCharge: roundedChargeAmount,
      };
    } else {
      return {
        ...chargeDetails,
        amountToCharge: inputDollarAmt - accumulatedChargeAmount,
      };
    }
  });
};

/**
 * initial payment method priority order
 * 1. only card
 * 2. request's preferred payment method
 * 3. user's primary payment method
 *  */

export const getInitialPaymentMethod = (
  paymentMethods: PaymentMethod[],
  preferredPaymentMethod: PaymentMethod,
  passenger: Contact
): PaymentMethod | null => {
  const paymentMethodsLinkedToPassenger = paymentMethods.filter(
    (paymentMethod) => paymentMethod?.linkedPassenger?.id === passenger.id
  );
  const paymentMethodsNotLinkedToPassenger = paymentMethods.filter(
    (paymentMethod) => !paymentMethod?.linkedPassenger
  );

  if (paymentMethodsLinkedToPassenger.length === 1) {
    return paymentMethodsLinkedToPassenger[0];
  } else if (paymentMethodsLinkedToPassenger.length > 1) {
    return null;
  } else if (paymentMethodsNotLinkedToPassenger.length === 1) {
    return paymentMethodsNotLinkedToPassenger[0];
  } else if (preferredPaymentMethod) {
    return preferredPaymentMethod;
  } else {
    const primaryPaymentMethod = paymentMethods?.find(
      (paymentMethod) => paymentMethod.isPrimary
    );
    return primaryPaymentMethod || null;
  }
};
