/**
 * @file term.graphql.ts
 * queries/mutations for Term.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_TERM_TYPES_QUERY = gql`
  query TermTypes {
    termTypes {
      id
      name
      guideline
    }
  }
`;

export const LOAD_TERM_QUERY = gql`
  query Term($id: ID!) {
    node(id: $id) {
      id
      ... on Term {
        createdAt
        updatedAt
        name
        description
        guideline
      }
    }
  }
`;

export const LOAD_TERMS_QUERY = gql`
  query Terms {
    terms {
      id
      createdAt
      updatedAt
      name
      description
      guideline
    }
  }
`;

export const CREATE_TERM_MUTATION = gql`
  mutation CreateTerm($input: CreateTermInput!) {
    createTerm(input: $input) {
      term {
        id
        createdAt
        updatedAt
        name
        description
        guideline
      }
    }
  }
`;

export const UPDATE_TERM_MUTATION = gql`
  mutation UpdateTerm($input: UpdateTermInput!) {
    updateTerm(input: $input) {
      term {
        id
        createdAt
        updatedAt
        name
        description
        guideline
      }
    }
  }
`;

export const REMOVE_TERM_MUTATION = gql`
  mutation RemoveTerm($input: RemoveTermInput!) {
    removeTerm(input: $input) {
      term {
        id
        createdAt
        updatedAt
        name
        description
        guideline
      }
    }
  }
`;
