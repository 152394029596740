import { useQuery } from "@apollo/client";
import map from "lodash/map";

import { LOAD_TRIPS_FOR_COMPANY_QUERY } from "globals/graphql";
import { Trip } from "types";

type useLoadTripsForCompanyProps = {
  companyId: string;
};

export function useLoadTripsForCompany(props: useLoadTripsForCompanyProps) {
  const { companyId } = props;

  // queries
  const {
    data,
    loading: tripsLoading,
    fetchMore: fetchMoreTrips,
  } = useQuery(LOAD_TRIPS_FOR_COMPANY_QUERY, {
    variables: {
      companyId,
    },
    skip: !companyId,
    fetchPolicy: "network-only",
  });

  const trips: Trip[] = map(
    data?.loadCompanyTrips?.edges,
    (trip) => trip?.node
  );

  return {
    trips,
    tripsLoading,
    fetchMoreTrips,
    hasNextPage: data?.loadCompanyTrips.pageInfo.hasNextPage,
    cursor: data?.loadCompanyTrips.pageInfo.endCursor,
  };
}
