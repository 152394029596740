import { GridColumns } from "@mui/x-data-grid-pro";

import { grayDark } from "design-system/colors";

import FarmTypeColumn from "./FarmTypeColumn";
import {
  DateColumn,
  TripConfNumberColumn,
} from "components/contacts/people/UpdateContactDrawer/components/ContactTripsGrid/components";
import AffiliateTotalAmountColumn from "./AffiliateTotalAmountColumn";
import AffiliateStatusColumn from "./AffiliateStatusColumn";

const defaults = {
  sortable: false,
  hide: false,
  color: grayDark,
  width: 130,
};

const affiliateTripsGridColumns: GridColumns = [
  {
    ...defaults,
    field: "date",
    headerName: "Date",
    renderCell: DateColumn,
    align: "center",
  },
  {
    ...defaults,
    field: "conf.No.",
    headerName: "Conf. No.",
    renderCell: TripConfNumberColumn,
  },
  {
    ...defaults,
    field: "type",
    headerName: "Type",
    renderCell: FarmTypeColumn,
  },
  {
    ...defaults,
    field: "totalAmount",
    headerName: "Total Amount",
    type: "number",
    renderCell: AffiliateTotalAmountColumn,
  },
  {
    ...defaults,
    field: "status",
    headerName: "Status",
    renderCell: AffiliateStatusColumn,
  },
];

export default affiliateTripsGridColumns;
