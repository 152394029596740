import React, { useEffect, useState } from "react";
import { OperationVariables } from "@apollo/client";
import { useFormContext } from "react-hook-form";
import pickBy from "lodash/pickBy";
import isEmpty from "lodash/isEmpty";
import keys from "lodash/keys";
import map from "lodash/map";
import { useHistory } from "react-router-dom";

import { Box, Button } from "@mui/material";

import CreateDrawer from "components/globals/CreateDrawer";
import {
  AffiliateFilter,
  CloseStatusToggle,
  CompanyFilter,
  DriverAssignedToggle,
  DriverFilter,
  PaidStatusToggle,
  TripStatusFilter,
  VehicleFilter,
} from "./components";

import { useOperator } from "globals/hooks";
import { PlanVariant } from "types";
import { ProPlanIcon } from "design-system/icons";
import RoundChip from "design-system/components/chips/RoundChip";
import { aqua, tintAqua } from "design-system/colors";
import { getLoadFilterableDispatchTripFilterFields } from "../../../../utils";
import { DispatchTripFilterFields } from "pages/dispatch/types";
import { useAnalytics } from "globals/hooks";
import { INITIAL_VALUES } from "../../../../../dispatch/hooks/useDispatchFilterForm";

export type FilterProps = {
  disabledSelect: boolean;
};

type FilterDispatchTripsDrawerProps = {
  open: boolean;
  onClose: () => void;
  refetch: (variables?: Partial<OperationVariables>) => void;
};

function FilterDispatchTripsDrawer(props: FilterDispatchTripsDrawerProps) {
  const { open, onClose, refetch } = props;

  // hooks
  const { getValues, reset } = useFormContext<DispatchTripFilterFields>();
  const operator = useOperator();
  const history = useHistory();
  const { track } = useAnalytics();

  // derived state
  const isDisabled =
    operator?.plan === PlanVariant.Free ||
    operator?.plan === PlanVariant.Standard;

  // state
  const [previousValues, setPreviousValues] =
    useState<DispatchTripFilterFields>(null);

  // event handlers
  const handleSubmit = () => {
    const selectedFilters = keys(
      pickBy(getValues(), (value) => Boolean(value) && !isEmpty(value))
    );

    const eventTrackingNameMap = {
      tripStatusSelection: "Trip Status",
      companySelection: "Company",
      vehicleSelection: "Vehicle",
      driverSelection: "Driver",
      affiliateSelection: "Affiliate",
      paidStatus: "Paid Status",
      openClosedTrip: "Close Status",
      driverAssigned: "Driver Assigned",
    };

    const trackedFilters = map(
      selectedFilters,
      (key) => eventTrackingNameMap[key]
    );

    track("dispatch_filterSelected", {
      filter_type: trackedFilters,
    });
    refetch(getLoadFilterableDispatchTripFilterFields(getValues()));
    onClose();
  };

  const handleAdditionalSubmit = () => {
    history.replace("/settings/billing/plans");
  };

  const handleOnClose = () => {
    // closing without submitting will reset form to previous values
    reset(previousValues);
    onClose();
  };

  const handleClearFilters = () => {
    track("dispatch_filterCleared");
    reset(INITIAL_VALUES);
  };

  // effect
  // on opening the drawer, save current form state
  useEffect(() => {
    if (open) setPreviousValues(getValues());
  }, [open, getValues]);

  return (
    <CreateDrawer
      open={open}
      onClose={handleOnClose}
      pageLabel="Filter"
      onSubmit={handleSubmit}
      pageLabelVariant="h2"
      submitLabel="Done"
      submitDisabled={isDisabled}
      hideSubmitDisabledLoadingIndicator={isDisabled}
      headerButton={
        <>
          {isDisabled && (
            <Box ml={2}>
              <RoundChip
                icon={ProPlanIcon}
                backgroundColor={tintAqua}
                label="Pro"
                color={aqua}
                iconProps={{ size: "small" }}
              />
            </Box>
          )}
          <Box display="flex" marginLeft="auto">
            <Button
              disableRipple
              sx={{ "&:hover": { bgcolor: "transparent" } }}
              onClick={handleClearFilters}
            >
              Clear Filters
            </Button>
          </Box>
        </>
      }
      {...(isDisabled && {
        additionalSubmitLabel: "Upgrade Subscription",
        additionalOnSubmit: handleAdditionalSubmit,
      })}
    >
      <Box p={2}>
        <TripStatusFilter disabledSelect={isDisabled} />
        <DriverFilter disabledSelect={isDisabled} />
        <VehicleFilter disabledSelect={isDisabled} />
        <AffiliateFilter disabledSelect={isDisabled} />
        <CompanyFilter disabledSelect={isDisabled} />
        <CloseStatusToggle disabledSelect={isDisabled} />
        <PaidStatusToggle disabledSelect={isDisabled} />
        <DriverAssignedToggle disabledSelect={isDisabled} />
      </Box>
    </CreateDrawer>
  );
}

export default FilterDispatchTripsDrawer;
