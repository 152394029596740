import React, { useState } from "react";

import { PriceBreakdownIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import { MenuItemOption } from "components/MoovsEllipsisMenu/components/EllipsisMenuItem";
import { useAnalytics } from "globals/hooks";
import { RequestStage } from "types";

type UseViewPaymentsClickProps = {
  analyticsName?: string;
  requestStage: string;
};

function useViewPaymentsClick(
  props: UseViewPaymentsClickProps
): MenuItemOption & {
  open: boolean;
  onClose: () => void;
} {
  const { analyticsName, requestStage } = props;

  // hooks
  const { track } = useAnalytics();

  // state
  const [viewPaymentsDialogOpen, setViewPaymentsDialogOpen] = useState(false);

  // event handlers
  const handleViewPaymentsDialogOpen = () => {
    analyticsName && track(analyticsName);
    setViewPaymentsDialogOpen(true);
  };

  const isUnconfirmedReservation = requestStage === RequestStage.Unconfirmed;

  const handleViewPaymentsDialogClose = () => setViewPaymentsDialogOpen(false);

  return {
    onClick: handleViewPaymentsDialogOpen,
    text: "View Payments",
    icon: <PriceBreakdownIcon color={grayDark} size="small" />,
    open: viewPaymentsDialogOpen,
    disableOption: isUnconfirmedReservation,
    onClose: handleViewPaymentsDialogClose,
  };
}

export { useViewPaymentsClick };
