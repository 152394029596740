import React, { useRef } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";

import { Box } from "@mui/material";

import { Contact } from "types";
import {
  LOAD_CONTACT_QUERY,
  UNLINK_PASSENGER_FROM_CONTACT_MUTATION,
} from "globals/graphql";
import { useSnackbar } from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { getContactName } from "globals/utils/contact";
import ContactInfoCard from "components/globals/ContactInfoCard";

type PassengerOfListProps = {
  contact: Contact;
  refreshContact: () => void;
};

export default function PassengerOfList(props: PassengerOfListProps) {
  const { contact, refreshContact } = props;
  const snackbar = useSnackbar();
  const bookingContactIdRef = useRef<string | null>(null);

  // queries
  const [refetchBookingContact] = useLazyQuery(LOAD_CONTACT_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  // mutations
  const [removeLinkedPassenger, { loading }] = useMutation(
    UNLINK_PASSENGER_FROM_CONTACT_MUTATION,
    {
      refetchQueries: ["loadContacts"],
      awaitRefetchQueries: true,
      onCompleted(data) {
        const unlinkedPassenger = data.removeLinkedPassenger.contact;
        refreshContact();
        refetchBookingContact({
          variables: { id: bookingContactIdRef.current },
        });
        snackbar.success(
          `Successfully unlinked contact ${getContactName(unlinkedPassenger)}!`
        );
      },
      onError(error) {
        const errorMessage =
          getErrorMessage(error) || "Error unlinking passenger";

        snackbar.error(errorMessage);
      },
    }
  );

  const handleUnlinkContact = (
    e: React.MouseEvent<HTMLButtonElement>,
    bookingContactId: string
  ) => {
    e.preventDefault();
    bookingContactIdRef.current = bookingContactId;
    removeLinkedPassenger({
      variables: {
        input: {
          contactId: bookingContactId,
          passengerId: contact.id,
        },
      },
    });
  };

  return (
    <Box my={3} display="flex" rowGap={1} flexDirection="column">
      {contact.passengerOf.map((contact: Contact) => (
        <ContactInfoCard
          key={contact.id}
          contact={contact}
          onDelete={(e) => handleUnlinkContact(e, contact.id)}
          isDeleteButtonDisabled={loading}
          deleteButtonTitle="Unlink Contact"
        />
      ))}
    </Box>
  );
}
