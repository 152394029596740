import React from "react";

import { Box, Typography, Tooltip, IconButton } from "@mui/material";

import { black } from "../../../../design-system/colors";
import { InfoIcon } from "../../../../design-system/icons";
import { OptionalPricingFieldsList } from "components/pricing/MoovsPricingForm/components";
import MoovsSwitchDropdown from "components/MoovsSwitchDropdown";
import { PricingKeys } from "components/pricing/utils";
import { useAnalytics, useFieldsRendered } from "globals/hooks";
import { PricingLayout } from "types";

type PricingCustomizationBlockProps = {
  pricingLayout: any;
  onOperatorPricingLayoutChange: any;
};

const styles = {
  tooltipIconButton: {
    paddingLeft: "6px",
    paddingBottom: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
};

function PricingCustomizationBlock(props: PricingCustomizationBlockProps) {
  const { pricingLayout, onOperatorPricingLayoutChange } = props;

  const newConfig = {
    driverGratuityAmt: null,
    driverGratuityPercent: null,
    taxAmt: null,
    taxPercent: null,
    promoDiscountAmt: null,
    promoDiscountPercent: null,
    tollsAmt: null,
    otherName: "",
    otherAmt: null,
    otherPercent: null,
    other2Name: "",
    other2Amt: null,
    other2Percent: null,
    other3Name: "",
    other3Amt: null,
    other3Percent: null,
    meetGreetAmt: null,
  };

  const pricingLayoutWithDefaults: PricingLayout = {
    ...newConfig,
    ...pricingLayout,
  };

  // state
  const [fieldsRendered, setFieldsRendered] = useFieldsRendered({
    pricing: pricingLayoutWithDefaults,
  });

  // hooks
  const { track } = useAnalytics();

  // event handlers
  const handleUpdatePricing = (pricingKeys: PricingKeys) => {
    const newPricingLayout = pricingLayoutWithDefaults;
    const { fieldName, fieldAmt, percentFieldName, percentFieldValue } =
      pricingKeys;

    if (fieldAmt || fieldAmt === "" || fieldAmt === 0) {
      newPricingLayout[fieldName] = fieldAmt;

      if (percentFieldName) {
        newPricingLayout[percentFieldName] = null;
      }
    } else if (
      percentFieldValue ||
      percentFieldValue === "" ||
      percentFieldValue === 0
    ) {
      newPricingLayout[percentFieldName] = percentFieldValue;
      newPricingLayout[fieldName] = null;
    } else {
      newPricingLayout[fieldName] = null;
      if (percentFieldName) {
        newPricingLayout[percentFieldName] = null;
      }
    }

    onOperatorPricingLayoutChange(newPricingLayout);
  };

  const handleDropdownOptionClick = (
    isChecked: boolean,
    fieldName: string,
    percentFieldName: string
  ) => {
    setFieldsRendered((fieldsRendered) => ({
      ...fieldsRendered,
      ...(percentFieldName && { [percentFieldName]: isChecked }),
      [fieldName]: isChecked,
    }));

    if (!isChecked) {
      handleUpdatePricing({
        ...(percentFieldName && { percentFieldName }),
        fieldName,
        fieldAmt: null,
        percentFieldValue: null,
      });
    }

    if (isChecked && fieldName === "meetGreetAmt") {
      track("meetAndGreet_enabled");
    }
  };

  return (
    <Box>
      <Box mb={2} display="flex" alignItems="center">
        <Box mr={1} display="flex">
          <Typography variant="h5">Pricing Layout</Typography>
        </Box>
        <Box mr={1} display="flex">
          <Tooltip
            enterDelay={200}
            enterNextDelay={200}
            title="Adding a pricing item below will automatically populate the item and amount when creating an order."
            placement="top"
          >
            <IconButton
              aria-label="info"
              sx={styles.tooltipIconButton}
              size="large"
            >
              <InfoIcon size="small" color={black} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box mt={1} mb={2}></Box>
      <Box>
        <OptionalPricingFieldsList
          pricing={pricingLayoutWithDefaults}
          fieldsRendered={fieldsRendered}
          setFieldsRendered={setFieldsRendered}
          onPricingUpdate={handleUpdatePricing}
          isPricingLayout
        />
        <MoovsSwitchDropdown
          buttonLabel="Add Pricing"
          fieldsRendered={fieldsRendered}
          isFullWidth={false}
          onDropdownOptionClick={handleDropdownOptionClick}
          isPricingLayout
        />
      </Box>
    </Box>
  );
}

export default PricingCustomizationBlock;
