import React, { useState } from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useMutation } from "@apollo/client";

import { Box, TextField } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";
import StripeTextField from "components/common/CreditCardElements/StripeTextField";
import { useAnalytics, useScreenSize, useSnackbar } from "globals/hooks";
import { CREATE_LAGO_PAYMENT_METHOD_MUTATION } from "globals/graphql";
import { useCreateSubscriptionPaymentMethodForm } from "../../../../hooks/useCreateSubscriptionPaymentMethodForm";

type AddLagoPaymentMethodDialogProps = {
  open: boolean;
  onClose: () => void;
};

function AddLagoPaymentMethodDialog(props: AddLagoPaymentMethodDialogProps) {
  const { open, onClose } = props;

  // states
  const [hideLoadingIndicator, setHideLoadingIndicator] = useState(true);

  // hooks
  const { isSmallTabletView } = useScreenSize();
  const { track } = useAnalytics();
  const snackbar = useSnackbar();
  const elements = useElements();
  const stripe = useStripe();
  const {
    submitDisabled,
    setSubmitDisabled,
    cardHolderName,
    handleCardholderNameChange,
    handleStripeElementChange,
    resetFormState,
  } = useCreateSubscriptionPaymentMethodForm();

  // mutations
  const [createLagoPaymentMethod] = useMutation(
    CREATE_LAGO_PAYMENT_METHOD_MUTATION,
    {
      onError(error) {
        setSubmitDisabled(false);
        setHideLoadingIndicator(true);

        snackbar.error(error);
      },
      onCompleted() {
        setHideLoadingIndicator(true);

        snackbar.success("Successfully added payment method!");

        track("billing_paymentMethodAdded");

        handleOnClose();
      },
      refetchQueries: ["Operator"],
    }
  );

  // event handlers
  const handleOnClose = () => {
    resetFormState();
    onClose();
  };

  const handleAddLagoPaymentMethod = async () => {
    setSubmitDisabled(true);
    setHideLoadingIndicator(false);

    const card = elements?.getElement(CardNumberElement);
    if (!stripe || !elements || card === null) {
      return;
    }

    const {
      paymentMethod: stripePaymentMethod,
      error: stripePaymentMethodError,
    } = await stripe.createPaymentMethod({
      type: "card",
      card: card,
      billing_details: {
        name: cardHolderName,
      },
    });

    if (stripePaymentMethodError) {
      snackbar.error(stripePaymentMethodError.message);
      return;
    }

    createLagoPaymentMethod({
      variables: {
        input: { stripePaymentMethod },
      },
    });
  };

  return (
    <MoovsDialog
      open={open}
      onClose={handleOnClose}
      onAccept={handleAddLagoPaymentMethod}
      acceptDisabled={submitDisabled}
      hideLoadingIndicator={hideLoadingIndicator}
      dialogTitle="Add payment method"
      acceptButtonText="Add"
      closeButtonText="Cancel"
      size="sm"
      narrowSidePaddingXDesktop
    >
      <Box py={2}>
        <TextField
          variant="outlined"
          label="Name on card"
          value={cardHolderName}
          fullWidth
          onChange={handleCardholderNameChange}
          InputLabelProps={{ shrink: true }}
        />

        <Box py={2}>
          <StripeTextField
            stripeElement={CardNumberElement}
            label="Credit card number"
            onChange={handleStripeElementChange}
          />
        </Box>

        <Box
          display="flex"
          flexDirection={isSmallTabletView ? "column" : "row"}
        >
          <Box flex={1} {...(isSmallTabletView ? { pb: 1 } : { pr: 2 })}>
            <StripeTextField
              stripeElement={CardExpiryElement}
              label="MM/YY"
              onChange={handleStripeElementChange}
            />
          </Box>
          <Box flex={1} {...(isSmallTabletView ? { pt: 1 } : { pl: 2 })}>
            <StripeTextField
              stripeElement={CardCvcElement}
              label="CVC"
              onChange={handleStripeElementChange}
            />
          </Box>
        </Box>
      </Box>
    </MoovsDialog>
  );
}

export default AddLagoPaymentMethodDialog;
