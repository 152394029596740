import React from "react";

import { Box, Typography, IconButton } from "@mui/material";

import MoovsBottomDrawer from "components/MoovsBottomDrawer";
import { granite } from "design-system/colors";
import { CrossIcon } from "design-system/icons";

type MoovsTooltipBottomListDrawerProps = {
  onSetDrawerOpen: (boolean: boolean) => void;
  drawerOpen: boolean;
  list: string[];
  title: string;
};

function MoovsTooltipBottomListDrawer(
  props: MoovsTooltipBottomListDrawerProps
) {
  const { onSetDrawerOpen, drawerOpen, list, title } = props;

  // event handler
  const handleDrawerClose = () => onSetDrawerOpen(false);

  return (
    <MoovsBottomDrawer open={drawerOpen} onClose={handleDrawerClose}>
      <Box display="flex" flexDirection="column" m={3}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="h5">{title}</Typography>
          <Box display="flex" flexDirection="row" mt={-1.5} mr={-1.5}>
            <IconButton onClick={handleDrawerClose} size="large">
              <CrossIcon color={granite} />
            </IconButton>
          </Box>
        </Box>
        {list?.map((item) => (
          <Typography variant="caption" key={item}>
            {item}
          </Typography>
        ))}
      </Box>
    </MoovsBottomDrawer>
  );
}
export default MoovsTooltipBottomListDrawer;
