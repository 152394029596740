import React from "react";

type RouteIconProps = {
  color?: string;
  size?: "small";
};

const RouteIcon = (props: RouteIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 7V10.693C4 11.3049 4.23705 11.8917 4.65901 12.3243C5.08097 12.7569 5.65326 13 6.25 13H13M4 7L7 10M4 7L1 10M12 9V5.30698C12 4.69513 11.7629 4.10834 11.341 3.6757C10.919 3.24306 10.3467 3 9.75 3L3 3M12 9L9 6M12 9L15 6"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.78947 12V16.924C4.78947 17.7398 5.08891 18.5222 5.62191 19.0991C6.15491 19.6759 6.87781 20 7.63158 20H16.1579M4.78947 12L8.57895 16M4.78947 12L1 16M19.2105 12L19.2105 7.07598C19.2105 6.26018 18.9111 5.47779 18.3781 4.90093C17.8451 4.32408 17.1222 4 16.3684 4L7.84211 4M19.2105 12L15.4211 8M19.2105 12L23 8"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RouteIcon;
