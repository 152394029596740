import { useQuery } from "@apollo/client";

import { LOAD_INVOICE_QUERY } from "globals/graphql";
import { Invoice } from "types";

function useLoadInvoice(invoiceId: string) {
  const {
    data: invoiceData,
    loading: invoiceLoading,
    refetch: invoiceRefetch,
    error: invoiceError,
  } = useQuery(LOAD_INVOICE_QUERY, {
    variables: {
      id: invoiceId,
    },
    skip: !invoiceId,
    errorPolicy: "all",
  });

  const invoice: Invoice = invoiceData?.node;

  return { invoice, invoiceLoading, invoiceRefetch, invoiceError };
}

export default useLoadInvoice;
