import React from "react";

import { Box, Typography, IconButton } from "@mui/material";

import { alabaster } from "../../../../../design-system/colors";

type SelectedEntityContainerProps = {
  label: string;
  unselect: { icon: JSX.Element; eventHandler: () => void };
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  phoneNumber?: string;
};

function SelectedEntityContainer(props: SelectedEntityContainerProps) {
  const { label, leftIcon, rightIcon, unselect } = props;

  return (
    <Box
      borderRadius="4px"
      bgcolor={alabaster}
      pl={2}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" alignItems="center">
        {leftIcon && (
          <Box display="flex" alignItems="center">
            {leftIcon}
          </Box>
        )}
        <Box>
          <Typography variant="h4">{label}</Typography>
        </Box>
        {rightIcon && (
          <Box display="flex" alignItems="center">
            {rightIcon}
          </Box>
        )}
      </Box>
      <IconButton onClick={unselect.eventHandler} size="large">
        {unselect.icon}
      </IconButton>
    </Box>
  );
}

export default SelectedEntityContainer;
