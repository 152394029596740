import React from "react";
import { Box, Typography } from "@mui/material";
import moment from "moment";

import { granite, grayLight } from "design-system/colors";

type ArrivedChipProps = {
  arrivedAt: Date;
  isLastStop?: boolean;
};

function ArrivedChip(props: ArrivedChipProps) {
  const { isLastStop } = props;

  // derived state
  const arrivedAt = moment(props.arrivedAt).format("h:mm A");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "fit-content",
        borderRadius: "4px",
        textAlign: "center",
        backgroundColor: grayLight,
        color: granite,
        px: 1,
        py: 0.5,
      }}
    >
      <Typography variant="h6">
        {isLastStop ? "Drop Time" : "On Location"}
      </Typography>
      <Typography variant="caption" sx={{ marginLeft: 1 }}>
        {arrivedAt}
      </Typography>
    </Box>
  );
}

export default ArrivedChip;
