import React from "react";
import first from "lodash/first";

import { Box, Typography } from "@mui/material";

import { ClockIcon, RoundTripIcon, TripTypeIcon } from "design-system/icons";
import { TripCategory } from "types";
import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";

type TripTypeButtonsBlockProps = {
  onChange: (e: React.MouseEvent<HTMLElement>, value: any) => void;
  value: string;
  enabledTripCategories: TripCategory[];
};

const TripTypeButtonsBlock = (props: TripTypeButtonsBlockProps) => {
  const { onChange, value, enabledTripCategories } = props;

  const toggleOptions = [
    {
      value: "HOURLY",
      label: "Hourly",
      ariaLabel: "Hourly",
      icon: ClockIcon,
    },
    {
      value: "ONE_WAY",
      label: "One Way",
      ariaLabel: "One Way",
      icon: TripTypeIcon,
    },
    {
      value: "ROUND_TRIP",
      label: "Round Trip",
      ariaLabel: "Round Trip",
      icon: RoundTripIcon,
    },
  ]
    // only display offered trip types
    .filter((option) =>
      enabledTripCategories?.includes(option.value as TripCategory)
    )
    // order trip types based on settings order
    .sort((a, b) => {
      return a.value !== first(enabledTripCategories) ? 1 : -1;
    });

  if (!toggleOptions?.length) return null;

  return (
    <Box mt={3}>
      <Typography variant="h5" mb={2}>
        Trip Type
      </Typography>

      <MoovsToggleButtonGroup
        size="large"
        onChange={onChange}
        options={toggleOptions}
        value={value}
        fullWidth
      />
    </Box>
  );
};

export default TripTypeButtonsBlock;
