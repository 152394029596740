/**
 * @file RemoveCompanyDialog.tsx
 * Remove dialog for a Company.
 *
 * components:
 *   RemoveCompanyDialog
 *
 * author: jackv
 */

import React from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import {
  LOAD_COMPANIES_QUERY,
  REMOVE_COMPANY_MUTATION,
} from "../../../globals/graphql";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import { Company } from "../../../types";
import { useAnalytics } from "../../../globals/hooks";
import RemoveDialog from "components/RemoveDialog";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

function RemoveCompanyDialog({
  open,
  company,
  handleClose,
}: {
  open: boolean;
  company: Company;
  handleClose: () => void;
}) {
  // hooks
  const history = useHistory();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // mutations
  const [removeCompany] = useMutation(REMOVE_COMPANY_MUTATION, {
    refetchQueries: [{ query: LOAD_COMPANIES_QUERY }],
    onCompleted() {
      track("company_deleted");
      snackbar.success("Successfully deleted company!");
      handleCloseDrawer();
    },
    onError(error) {
      const errorMessage = getErrorMessage(error) || "Error removing company";

      snackbar.error(errorMessage);
    },
  });

  const handleCloseDrawer = () => {
    handleClose();
    history.replace(`/companies`);
  };

  // event handlers
  const handleRemoveCompanyClick = () => {
    removeCompany({
      variables: {
        input: {
          id: company.id,
        },
      },
    });
  };

  const companyName = company ? company.name : "this company";

  return (
    <RemoveDialog
      open={open}
      onRemove={handleRemoveCompanyClick}
      onClose={handleClose}
      title={`Remove ${companyName}?`}
      body={`This will premanantly remove ${companyName}. Do you want to delete this
        company?`}
      removeButtonText="Remove"
    />
  );
}

export default RemoveCompanyDialog;
