// TODO: add error and loading state to dialog and remove optional chaining
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import moment from "moment";

import { grayDark, paymentStatusLabelColors } from "design-system/colors";
import RoundChip from "design-system/components/chips/RoundChip";
import MoovsDialog from "../MoovsDialog";
import { LOAD_PAYMENT_QUERY, LOAD_REFUND_QUERY } from "globals/graphql";
import { currency, fromGlobalId } from "globals/utils/helpers";

type ViewMorePaymentDetailsDialogProps = {
  open: boolean;
  paymentId?: string;
  onClose: () => void;
};

export default function ViewMorePaymentDetailsDialog(
  props: ViewMorePaymentDetailsDialogProps
) {
  const { paymentId, open, onClose } = props;
  const [paymentType, setPaymentType] = useState<string>();
  const [loadPayment, { data, loading }] = useLazyQuery(LOAD_PAYMENT_QUERY);
  const [loadRefund, { data: refundData, loading: refundLoading }] =
    useLazyQuery(LOAD_REFUND_QUERY);

  useEffect(() => {
    if (!paymentId) return;

    const paymentType = fromGlobalId(paymentId).type;

    if (paymentType === "Payment") {
      loadPayment({ variables: { id: paymentId } });
    } else {
      loadRefund({ variables: { id: paymentId } });
    }

    setPaymentType(paymentType);
  }, [loadPayment, loadRefund, paymentId]);

  const refund = paymentType === "PaymentRefund" && refundData?.node;
  const payment = paymentType === "Payment" ? data?.node : refund?.payment;
  const amount = refund?.amount || payment?.amount;
  const status = refund?.paymentStatus || payment?.paymentStatus;
  const createdAt = moment(refund?.createdAt || payment?.createdAt).format(
    "M/D/YYYY h:mm a"
  );

  const styles = {
    columnTitle: {
      color: grayDark,
      lineHeight: 1.43,
    },
    row: {
      height: "32px",
    },
  };

  const Content = () => (
    <Grid container>
      <Grid item xs={4} sx={styles.row}>
        <Typography gutterBottom={true} variant="h5" sx={styles.columnTitle}>
          Status
        </Typography>
      </Grid>
      <Grid item xs={8} sx={styles.row}>
        <RoundChip {...paymentStatusLabelColors[status]} />
      </Grid>

      {!!payment?.paymentIntent?.declineCode && (
        <>
          <Grid item xs={4} sx={styles.row}>
            <Typography
              gutterBottom={true}
              variant="h5"
              sx={styles.columnTitle}
            >
              Decline Code
            </Typography>
          </Grid>
          <Grid item xs={8} sx={styles.row}>
            <Typography variant="body1" color="error">
              {payment?.paymentIntent?.declineCode}
            </Typography>
          </Grid>
        </>
      )}

      <Grid item xs={4} sx={styles.row}>
        <Typography gutterBottom={true} variant="h5" sx={styles.columnTitle}>
          Amount
        </Typography>
      </Grid>
      <Grid item xs={8} sx={styles.row}>
        <Typography variant="body1">{currency(amount)}</Typography>
      </Grid>

      <Grid item xs={4} sx={styles.row}>
        <Typography gutterBottom={true} variant="h5" sx={styles.columnTitle}>
          Date
        </Typography>
      </Grid>
      <Grid item xs={8} sx={styles.row}>
        <Typography variant="body1">{createdAt}</Typography>
      </Grid>

      <Grid item xs={4} sx={styles.row}>
        <Typography gutterBottom={true} variant="h5" sx={styles.columnTitle}>
          Customer
        </Typography>
      </Grid>
      <Grid item xs={8} sx={styles.row}>
        <Typography variant="body1">
          {payment?.contact?.firstName} {payment?.contact?.lastName}
        </Typography>
      </Grid>

      {!!payment?.paymentIntent && (
        <>
          <Grid item xs={4} sx={styles.row}>
            <Typography
              gutterBottom={true}
              variant="h5"
              sx={styles.columnTitle}
            >
              fee
            </Typography>
          </Grid>
          <Grid item xs={8} sx={styles.row}>
            <Typography variant="body1">
              {currency(payment.paymentIntent.fee)}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={styles.row}>
            <Typography
              gutterBottom={true}
              variant="h5"
              sx={styles.columnTitle}
            >
              net
            </Typography>
          </Grid>
          <Grid item xs={8} sx={styles.row}>
            <Typography variant="body1">
              {currency(payment.paymentIntent.net)}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={styles.row}>
            <Typography
              gutterBottom={true}
              variant="h5"
              sx={styles.columnTitle}
            >
              Risk Evaluation
            </Typography>
          </Grid>
          <Grid item xs={8} sx={styles.row} mb={2}>
            <Typography variant="body1">
              {payment?.paymentIntent?.riskEvaluation}
            </Typography>
          </Grid>

          <Grid item xs={4} sx={styles.row}>
            <Typography
              gutterBottom={true}
              variant="h5"
              sx={styles.columnTitle}
            >
              Charge Id
            </Typography>
          </Grid>
          <Grid item xs={8} sx={styles.row}>
            <Typography variant="body1">
              {payment?.paymentIntent?.chargeId}
            </Typography>
          </Grid>
        </>
      )}

      <Grid item xs={4} sx={styles.row}>
        <Typography gutterBottom={true} variant="h5" sx={styles.columnTitle}>
          Card number
        </Typography>
      </Grid>
      <Grid item xs={8} sx={styles.row}>
        <Typography variant="body1">
          **** - {payment?.paymentMethod?.card?.last4}
        </Typography>
      </Grid>

      <Grid item xs={4} sx={styles.row}>
        <Typography gutterBottom={true} variant="h5" sx={styles.columnTitle}>
          Expires
        </Typography>
      </Grid>
      <Grid item xs={8} sx={styles.row}>
        <Typography variant="body1">
          {payment?.paymentMethod?.card?.expMonth}/
          {payment?.paymentMethod?.card?.expYear}
        </Typography>
      </Grid>

      <Grid item xs={4} sx={styles.row}>
        <Typography gutterBottom={true} variant="h5" sx={styles.columnTitle}>
          Type
        </Typography>
      </Grid>
      <Grid item xs={8} sx={styles.row}>
        <Typography variant="body1">
          {payment?.paymentMethod?.card?.brand}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <MoovsDialog
      fixedFooter
      onClose={onClose}
      open={open}
      size="sm"
      dialogTitle="Charge Information"
    >
      <Box py={2}>
        {(loading || refundLoading) && (
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={40} thickness={2} />
          </Box>
        )}

        {!loading && !refundLoading && payment && <Content />}
      </Box>
    </MoovsDialog>
  );
}
