import React, { ChangeEvent, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { sendPasswordResetEmail } from "firebase/auth";

import { TextField, Typography, Box, Link } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useAnalytics, useScreenSize, useSnackbar } from "../../globals/hooks";
import { auth } from "../../globals/utils/firebaseApp";
import { WarningTriangleIcon } from "../../design-system/icons";
import { errorRed, grayLight } from "../../design-system/colors";

function ForgotPasswordPage() {
  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const location = useLocation<{ from: any }>();
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();

  // continueUrl is passed to firebase auth to supply
  // a link to click on after reseting password.
  // this does not work on local as the domain has to be
  // verified by firebase.
  let continueUrl;
  if (process.env.REACT_APP_ENVIRONMENT !== "local") {
    const { pathname, search } = location?.state?.from || {};

    continueUrl = location?.state?.from
      ? `${process.env.REACT_APP_OPERATORAPP_URL}${pathname}/${search || ""}`
      : process.env.REACT_APP_OPERATORAPP_URL;
  }

  // state
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);

  // event handlers
  const handleResetPasswordClick = async () => {
    track("forgotPassword_sendEmail");
    setLoading(true);

    try {
      await sendPasswordResetEmail(
        auth,
        email,
        continueUrl && { url: continueUrl }
      );
      setLoading(false);
      snackbar.success("Email sent! Check your email to reset your password.");
    } catch (error) {
      const errorMessage = error.response ? error.response.data : error.message;

      setLoading(false);
      setError(true);

      snackbar.error("", {
        isSimple: true,
        icon: <WarningTriangleIcon />,
        iconColor: errorRed,
        alertBodyComponent: (
          <Typography variant="body2">{errorMessage}</Typography>
        ),
      });
    }
  };

  // event handlers
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleResetPasswordClick();
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setEmail(event.target.value);
  };

  const styles = {
    divider: {
      borderBottom: `1px solid ${grayLight}`,
    },
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password? | Moovs</title>
      </Helmet>
      <Box sx={styles.divider} />
      <Box mt={3}>
        <TextField
          data-testid="email"
          fullWidth
          variant="outlined"
          value={email}
          error={error}
          label="Email"
          name="email"
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
        />
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
        <LoadingButton
          data-testid="button"
          variant="contained"
          color="primary"
          loading={loading}
          fullWidth={isMobileView}
          onClick={handleResetPasswordClick}
          sx={{ minWidth: 160 }}
        >
          Send Email
        </LoadingButton>
        <Link
          component="button"
          underline="none"
          variant="body1"
          color="primary"
          sx={{ fontWeight: 500, mt: 2, width: "100%" }}
          onClick={() =>
            history.push("/login", { from: location?.state?.from })
          }
        >
          Go Back
        </Link>
      </Box>
    </>
  );
}

export default ForgotPasswordPage;
