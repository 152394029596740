import first from "lodash/first";
import size from "lodash/size";
import React from "react";
import { Vehicle } from "types";

import { needTooltip } from "../../utils/needToolTip";
import { IconNames } from "../../utils/types";
import DynamicPricingChip from "../DynamicPricingChip";
import DynamicPricingChipTooltip from "../DynamicPricingChipTooltip";

type VehiclesChipProps = {
  vehicles: Vehicle[];
  onSetVehiclesDrawerOpen: () => void;
};

function VehiclesChip(props: VehiclesChipProps) {
  const { vehicles, onSetVehiclesDrawerOpen } = props;

  const enabledVehicles = vehicles.filter(
    (vehicle) => vehicle.enableBaseRateAutomation && vehicle.available
  );

  if (!size(enabledVehicles)) return null;

  let chip;
  const vehicleNames = enabledVehicles.map((vehicle) => vehicle.name);

  if (needTooltip(enabledVehicles)) {
    // if there are 2 vehicles or more, need to display with tooltip
    chip = (
      <DynamicPricingChipTooltip
        onDrawerOpen={onSetVehiclesDrawerOpen}
        list={vehicleNames}
        label={first(vehicleNames)}
        iconName={IconNames.vehicle}
      />
    );
  } else {
    chip = (
      <DynamicPricingChip
        label={vehicleNames.join(", ")}
        iconName={IconNames.vehicle}
      />
    );
  }
  return chip;
}

export default VehiclesChip;
