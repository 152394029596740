import React from "react";
import last from "lodash/last";
import first from "lodash/first";

import { Box, Divider } from "@mui/material";

import { grayLight, white } from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import { deriveIsFarmee } from "globals/utils/farm";
import { Request } from "types";
import { TopBar, AddTripButton } from "./components/TripList/components";
import OrderSummary from "./components/OrderSummary";
import TripsList from "./components/TripList";
import { useNumberedTrips } from "./hooks";
import AcceptRejectRequestPanel from "./components/AcceptRejectRequestPanel";
import AcceptDeclinedRequestBanner from "./components/TripList/components/AcceptDeclinedRequestBanner";

type TripsPanelProps = {
  request: Request;
};

function TripsPanel(props: TripsPanelProps) {
  const { request } = props;
  const { trips, orderNumber, whiteLabelOperator } = request;

  // hooks
  const { isMobileView } = useScreenSize();
  const { numberedTrips, tripCount } = useNumberedTrips(trips);

  // derived state
  const route = first(first(trips).routes);
  const isFarmee = route.isFarmedRoute && deriveIsFarmee(route);
  const canAddTrip = !isFarmee;

  if (isMobileView)
    return (
      <Box
        display="flex"
        flexDirection="column"
        maxWidth="100vw"
        bgcolor={white}
      >
        {/* fixed top bar */}
        <Box
          position={"sticky"}
          top={0}
          bgcolor={white}
          pt={2}
          zIndex={10}
          px={2}
        >
          <TopBar
            orderNumber={orderNumber}
            tripCount={last(numberedTrips).tripListNumber}
            whiteLabelOperatorName={whiteLabelOperator?.name}
          />
          <AcceptRejectRequestPanel
            requestId={request.id}
            requestStage={request.stage}
            isDeclined={request.isDeclined}
          />
          <AcceptDeclinedRequestBanner
            requestId={request.id}
            isDeclined={request.isDeclined}
          />
          <Box pt={2} mx={-2}>
            <Divider />
          </Box>
        </Box>

        {/* body */}
        <Box pb={3} pt={2} mx={2}>
          <OrderSummary request={request} />
        </Box>
        <Divider />

        <Box pt={2} px={2}>
          <TripsList
            trips={numberedTrips}
            requestId={request.id}
            orderNumber={orderNumber}
          />
        </Box>

        {/* fixed add trip footer */}
        {canAddTrip && (
          <Box
            p={1.5}
            position="sticky"
            bottom={0}
            bgcolor={white}
            borderTop={`1px solid ${grayLight}`}
          >
            <AddTripButton request={request} />
          </Box>
        )}
      </Box>
    );

  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth="370px"
      bgcolor={white}
      borderRight={`1px solid ${grayLight}`}
    >
      <Box mt={2} mx={2}>
        <TopBar
          orderNumber={request.orderNumber}
          tripCount={tripCount}
          whiteLabelOperatorName={whiteLabelOperator?.name}
        />
        <AcceptRejectRequestPanel
          requestId={request.id}
          requestStage={request.stage}
          isDeclined={request.isDeclined}
        />
        <AcceptDeclinedRequestBanner
          requestId={request.id}
          isDeclined={request.isDeclined}
        />
        <Box my={3}>
          <OrderSummary request={request} />
        </Box>

        <Divider />
      </Box>

      {/* trips list with sticky add trip footer */}
      <Box pt={2} px={2} sx={{ overflowY: "auto" }}>
        <TripsList
          trips={trips}
          requestId={request.id}
          orderNumber={request.orderNumber}
        />
        {canAddTrip && (
          <Box py={2} position="sticky" bottom={0} bgcolor={white}>
            <AddTripButton request={request} />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default TripsPanel;
