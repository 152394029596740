import React from "react";
import startCase from "lodash/startCase";
import { useMutation } from "@apollo/client";

import { Box, Divider, Typography } from "@mui/material";

import { tintYellow, yellowDark } from "design-system/colors";
import {
  CreditCardIcon,
  PreferredVerifiedCheckIcon,
  TrashIcon,
} from "design-system/icons";
import { PaymentMethod } from "types";
import { useScreenSize, useSnackbar } from "globals/hooks";
import {
  REMOVE_LAGO_PAYMENT_METHOD_MUTATION,
  UPDATE_PRIMARY_LAGO_PAYMENT_METHOD_MUTATION,
} from "globals/graphql";
import RoundChip from "design-system/components/chips/RoundChip";
import MoovsEllipsisMenu from "components/MoovsEllipsisMenu";

type PaymentMethodsProps = {
  paymentMethods: PaymentMethod[];
};

function PaymentMethods(props: PaymentMethodsProps) {
  const { paymentMethods } = props;

  // hooks
  const { isSmallTabletView } = useScreenSize();
  const snackbar = useSnackbar();

  // mutations
  const [updatePrimaryLagoPaymentMethod] = useMutation(
    UPDATE_PRIMARY_LAGO_PAYMENT_METHOD_MUTATION,
    {
      onError(error) {
        snackbar.error(error);
      },
      onCompleted() {
        snackbar.success("Successfully updated default payment method!");
      },
      refetchQueries: ["Operator"],
    }
  );

  const [removeLagoPaymentMethod] = useMutation(
    REMOVE_LAGO_PAYMENT_METHOD_MUTATION,
    {
      onError(error) {
        snackbar.error(error);
      },
      onCompleted() {
        snackbar.success("Successfully removed payment method!");
      },
      refetchQueries: ["Operator"],
    }
  );

  // event handlers
  const handleSetDefault = (subscriptionPaymentMethodId: string) => {
    updatePrimaryLagoPaymentMethod({
      variables: {
        input: {
          subscriptionPaymentMethodId,
        },
      },
    });
  };

  const handleRemove = (subscriptionPaymentMethodId: string) => {
    removeLagoPaymentMethod({
      variables: {
        input: {
          subscriptionPaymentMethodId,
        },
      },
    });
  };

  return (
    <>
      {paymentMethods.map((paymentMethod, index) => {
        const {
          card: { brand, expMonth, expYear, last4 },
          isPrimary,
        } = paymentMethod;
        const isLastCard = index === paymentMethods.length - 1;

        return (
          <Box key={paymentMethod.id}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              minHeight={"34px"}
            >
              <Box display="flex" alignItems="center">
                <CreditCardIcon brand={brand} />

                <Box
                  display="flex"
                  flexDirection={isSmallTabletView ? "column" : "row"}
                  ml={1.5}
                >
                  <Typography variant="body2">{`${startCase(
                    brand
                  )} **** ${last4}`}</Typography>
                  <Typography
                    variant="body2"
                    ml={isSmallTabletView ? 0 : 2.5}
                  >{`Expires ${expMonth}/${expYear}`}</Typography>
                </Box>
              </Box>

              <Box>
                {isPrimary ? (
                  <RoundChip
                    label="DEFAULT"
                    color={yellowDark}
                    backgroundColor={tintYellow}
                  />
                ) : (
                  <MoovsEllipsisMenu
                    size="small"
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    options={[
                      {
                        text: "Make default payment method",
                        onClick: () => handleSetDefault(paymentMethod.id),
                        icon: <PreferredVerifiedCheckIcon size="small" />,
                      },
                      {
                        text: "Delete",
                        onClick: () => handleRemove(paymentMethod.id),
                        icon: <TrashIcon size="small" />,
                      },
                    ]}
                  />
                )}
              </Box>
            </Box>

            {!isLastCard && (
              <Box my={2}>
                <Divider light />
              </Box>
            )}
          </Box>
        );
      })}
    </>
  );
}

export default PaymentMethods;
