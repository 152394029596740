import React, { ComponentType } from "react";

import { MenuItem, Typography } from "@mui/material";

import { grayDark } from "design-system/colors";
import { useAnalytics } from "globals/hooks";

type HelpMenuItemProps = {
  onClick: () => void;
  icon: ComponentType<any>; // TODO: consider MoovsIconProps
  name: string;
  analyticsName: string;
  link?: string;
  id?: string;
};

function HelpMenuItem(props: HelpMenuItemProps) {
  const { onClick, link, analyticsName, name, icon, id } = props;

  // hooks
  const { track } = useAnalytics();

  // derived state
  const Icon = icon;

  // event handlers
  const handleClick = () => {
    track(analyticsName);
    link && window.open(link, "_blank");
    onClick();
  };

  return (
    <MenuItem
      focusRipple
      disableTouchRipple
      id={id}
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        p: 1.5,
        justifyContent: "flex-start",
        borderRadius: "3px",
      }}
      onClick={handleClick}
    >
      <Icon color={grayDark} size="small" />
      <Typography component="span" variant="body2" sx={{ ml: 1 }}>
        {name}
      </Typography>
    </MenuItem>
  );
}

export default HelpMenuItem;
