import {
  FarmRelationshipEnum,
  Route,
  OperatorRoute,
  Request,
  Company,
} from "types";

export const deriveIsFarmee = (route: Route | OperatorRoute) => {
  return route?.farmRelationship === FarmRelationshipEnum.Farmee;
};
export const deriveIsFarmor = (route: Route | OperatorRoute) => {
  return route?.farmRelationship === FarmRelationshipEnum.Farmor;
};

type BookingContact = {
  name: string;
  email: string;
  phoneNumber: string;
  company?: Company;
};

/**
 * if not a farmedRoute OR is a farmee, then bookingContact is the first team member
 * if is farmee, then bookingContact is the farmee (farmAffiliate)
 * future improvement: return this as a field on the route type
 *  */
export const deriveBookingContact = (request: Request): BookingContact => {
  // only check the first trip/route, since this information *should* be the same for every trip/route
  const firstRoute = request.trips[0].routes[0];

  const isFarmedRoute = firstRoute.isFarmedRoute;
  const isFarmor = deriveIsFarmor(firstRoute);

  if (!isFarmedRoute || isFarmor) {
    const bookingContact = request.bookingContact;
    return {
      name: `${bookingContact.firstName} ${bookingContact.lastName}`,
      email: bookingContact.email,
      phoneNumber: bookingContact.mobilePhone,
      company: bookingContact.company,
    };
  } else {
    const { operatorName, operatorEmail, operatorPhone } =
      firstRoute.farmAffiliate;

    return {
      name: operatorName,
      email: operatorEmail,
      phoneNumber: operatorPhone,
    };
  }
};
