import React from "react";
import { useHistory } from "react-router-dom";

import { Box, Card, Typography, CardActionArea } from "@mui/material";

import MoovsAvatar from "../../../design-system/components/MoovsAvatar";
import { FarmAffiliate } from "../../../types";
import FarmAffiliateIcon, {
  getFarmAffiliateIconVariant,
} from "./FarmAffiliateIcon";
import { granite } from "design-system/colors";

type FarmAffiliateCardProps = {
  farmAffiliate: FarmAffiliate;
};

function FarmAffiliateCard(props: FarmAffiliateCardProps) {
  const {
    farmAffiliate: {
      id,
      operatorName,
      vehicleTypes = [],
      serviceArea,
      operatorAddress,
      __typename,
    },
  } = props;

  const { farmAffiliate } = props;

  // hooks
  const history = useHistory();

  // event handlers
  const handleAffiliateCardClick = () => {
    history.push(`/affiliates/update/${id}`);
  };

  const vehicleTypeNames = vehicleTypes.map(
    (vehicleType) => vehicleType.typeName
  );

  const MoovsAvatarPlaceholder = operatorName.split(" ").slice(0, 2);

  const iconVariant = getFarmAffiliateIconVariant({
    farmAffiliate,
    farmAffiliateVariant: __typename,
  });

  return (
    <CardActionArea
      style={{ height: "100%" }}
      disableRipple
      onClick={handleAffiliateCardClick}
    >
      <Card variant="outlined" style={{ height: "100%" }}>
        <Box
          p={3}
          height="100%"
          width="100%"
          display="flex"
          flexDirection="row"
          flex="1"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column">
            <Box mb={2}>
              <MoovsAvatar size="large" placeholder={MoovsAvatarPlaceholder} />
            </Box>

            {/* Operator */}
            <Typography variant="h4" mb={0.5}>
              {operatorName}
            </Typography>

            {/* Vehicles */}
            <Typography variant="body2">
              {vehicleTypeNames.map((name, i) => {
                const isLast = vehicleTypeNames.length === i + 1;

                return `${name}${!isLast ? "," : ""} `;
              })}
            </Typography>

            {/* service area */}
            <Typography mt={1.5} variant="caption" color={granite}>
              {serviceArea || operatorAddress}
            </Typography>
          </Box>

          {/* icon */}
          <Box>
            <FarmAffiliateIcon variant={iconVariant} size="extra-large" />
          </Box>
        </Box>
      </Card>
    </CardActionArea>
  );
}

export default FarmAffiliateCard;
