import React from "react";

import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { granite } from "design-system/colors";
import { Company } from "types";

function CompanyColumn(props: GridRenderCellParams<Company>) {
  const { value } = props;

  if (!value) {
    return "N/A";
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
    >
      <Typography variant="body2" textOverflow="ellipsis" overflow="hidden">
        {value.name}
      </Typography>
      <Typography
        color={granite}
        variant="caption"
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {value.email || ""}
      </Typography>
    </Box>
  );
}

export default CompanyColumn;
