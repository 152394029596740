import React from "react";

type GrowMyBusinessIconProps = {
  color?: string;
};

const GrowMyBusinessIcon = (props: GrowMyBusinessIconProps) => {
  const { color } = props;
  return (
    <svg
      width="40"
      height="39"
      viewBox="0 0 40 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66667 37.619C1.66667 35.7638 2.32104 31.9233 4.40332 29.7092C6.8407 27.1175 9.66848 25.9986 12.6133 25.9986C13.9757 25.9855 15.7086 26.6737 15.7792 26.6737M16.7418 37.619H6.43157M19.5525 0.952209V4.32544M21.1387 2.63883H17.9663M36.5691 14.8497V18.223M38.1553 16.5363H34.9829M31.7698 3.88671H27.3285C26.2832 3.88671 25.4361 4.21223 25.4361 5.57333C25.4361 7.77774 31.7809 10.1154 31.7809 12.3198C31.7809 13.4886 30.9339 14.0064 29.8886 14.0064H25.425M28.6038 3.88671V1.35679M28.6038 16.5363V14.0064M21.1387 23.5931C21.2439 23.2572 21.446 22.9649 21.7161 22.7579C21.9863 22.5508 22.3109 22.4394 22.644 22.4395H36.7468C36.9981 22.4394 37.2458 22.5029 37.4696 22.6246C37.6934 22.7463 37.8867 22.9228 38.0337 23.1396C38.1807 23.3564 38.2772 23.6072 38.3151 23.8714C38.3531 24.1355 38.3315 24.4055 38.2521 24.6591L34.1835 37.619H16.7354L21.1387 23.5931ZM18.3333 16.7857C18.3333 20.0074 15.7217 22.619 12.5 22.619C9.27834 22.619 6.66667 20.0074 6.66667 16.7857C6.66667 13.5641 9.27834 10.9524 12.5 10.9524C15.7217 10.9524 18.3333 13.5641 18.3333 16.7857Z"
        stroke={color || "#195FE9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GrowMyBusinessIcon;
