import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { Box, Button, CircularProgress } from "@mui/material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import GQLQueryStatusIndicator from "components/GQLQueryStatusIndicator";
import UpdateDrawer from "components/globals/UpdateDrawer";
import TopOverviewBar from "components/globals/TopOverviewBar";
import CommentUpdateBlock from "components/CommentUpdateBlock";
import RoundChip from "design-system/components/chips/RoundChip";
import { grayDark, white } from "design-system/colors";
import {
  DurationIcon,
  EmailIcon,
  EstimationIcon,
  QuotesIcon,
  UsersIcon,
  VehicleIcon,
} from "design-system/icons";
import {
  confirmationNumberFromRequest,
  convertMinutesToHoursMinutes,
} from "globals/utils/helpers";
import { useAnalytics, useScreenSize } from "globals/hooks";
import {
  useAddReturnTripClick,
  useDeleteTripClick,
  useDuplicateTripClick,
} from "pages/reservations/components/ActionButtonPanel/components/ViewMoreActionButton/hooks";
import { QuoteTabViewVariant } from "./components/QuoteTabViews/types";
import {
  useUpdateQuoteMutation,
  useUpdateQuoteDrawerQuery,
  useSendQuoteMutation,
} from "./hooks";
import { QuoteTabs } from "./components";
import { Pricing, TripDetails, Vehicle } from "./components/QuoteTabViews";
import RemoveDialog from "components/RemoveDialog";
import DuplicateTripDialog from "pages/reservations/components/ActionButtonPanel/components/ViewMoreActionButton/components/DuplicateTripDialog";
import MoovsTooltip from "components/MoovsTooltip";

type UpdateQuoteDrawerRoutingState = {
  tabView?: QuoteTabViewVariant;
};

function UpdateQuoteDrawer() {
  // hooks
  const history = useHistory();
  const location = useLocation();
  const { requestId, tripId } = useParams<{
    requestId: string;
    tripId: string;
  }>();
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();
  const {
    trip,
    request,
    isLoading,
    hasError,
    tripError,
    tripRefetch,
    requestRefetch,
    needsPricing,
    needsVehicle,
    sendQuoteDisabled,
    sendQuoteDisabledTooltip,
  } = useUpdateQuoteDrawerQuery({
    requestId,
    tripId,
  });
  const { handleRemoveTrip } = useUpdateQuoteMutation({ trip, request });
  const { handleSendQuoteEmail } = useSendQuoteMutation();
  const duplicateTripMenuOption = useDuplicateTripClick({
    trip,
    analyticsName: "quote_tripDuplicated",
  });
  const deleteTripMenuOption = useDeleteTripClick({
    trip,
    analyticsName: "quote_tripDeleted",
  });
  const addReturnTripMenuOption = useAddReturnTripClick({
    request,
    trip,
  });

  // state
  const routingState = history.location?.state as UpdateQuoteDrawerRoutingState;
  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "loading" | "saved" | "error"
  >("default");
  const [tabView, setTabView] = useState(
    routingState?.tabView || QuoteTabViewVariant.TRIP_DETAILS
  );

  // derived state
  const confirmationNumber = confirmationNumberFromRequest(request, trip);

  // events
  const handleClose = () => {
    history.push(`/quotes/${requestId}/${location.search}`);
  };

  const handleGoToVehicle = () => {
    track("goTo_pageSelected");
    history.push(`/vehicles/update/${trip.routes[0].vehicle.id}`);
  };

  const handleGoToBookingContact = () => {
    track("goTo_pageSelected");
    history.push(`/contacts/update/${request.bookingContact.id}`);
  };

  const handleClickRemove = () => {
    handleRemoveTrip();
    deleteTripMenuOption.onClose();
    history.push(`/quotes/${requestId}`);
  };

  const handleSendQuoteClick = async () => {
    await handleSendQuoteEmail(requestId);
    handleClose();
  };

  // menu items
  const topBarItems = isLoading
    ? []
    : [
        {
          title: "conf. no.",
          value: confirmationNumber,
          icon: (
            <AssignmentTurnedInIcon
              viewBox="-4 -4 30 30"
              style={{ color: grayDark }}
            />
          ),
        },
        {
          ...(trip.useTotalDuration
            ? {
                title: "total duration",
                value: convertMinutesToHoursMinutes(trip.totalDuration),
              }
            : {
                title: "est. duration",
                value: (
                  <Box display="flex" alignItems="center">
                    <Box mr={0.5}>
                      {convertMinutesToHoursMinutes(trip.estimatedDuration)}
                    </Box>
                    <EstimationIcon size="small" />
                  </Box>
                ),
              }),
          icon: (
            <DurationIcon
              color={grayDark}
              viewBox="-9 -9 31 31"
              height="20"
              width="20"
            />
          ),
        },
        {
          title: "status",
          value: (
            <RoundChip
              variant={
                request.isExpired
                  ? "EXPIRED"
                  : request.isArchived
                  ? "ARCHIVED"
                  : request.status
              }
            />
          ),
        },
      ];

  const ellipsisMenuOptions = isLoading
    ? ["Loading"]
    : [
        duplicateTripMenuOption,
        addReturnTripMenuOption,
        "divider",
        "GO TO",
        {
          onClick: () => {},
          text: "Quote",
          icon: <QuotesIcon color={grayDark} size="small" />,
          disableOption: true,
        },
        {
          onClick: handleGoToBookingContact,
          text: "Booking Contact",
          icon: <UsersIcon color={grayDark} size="small" />,
          disableOption: !request.bookingContact,
        },
        {
          onClick: handleGoToVehicle,
          text: "Vehicle",
          icon: <VehicleIcon color={grayDark} size="small" />,
          disableOption: !trip.routes[0].vehicle,
        },
        "divider",
        deleteTripMenuOption,
      ];

  return (
    <>
      <UpdateDrawer
        onClose={handleClose}
        updatedAt={trip?.updatedAt}
        saveIndicatorState={saveIndicatorState}
        ellipsisMenuOptions={ellipsisMenuOptions}
        footerContent={
          <MoovsTooltip
            hidden={!sendQuoteDisabled}
            tooltipText={sendQuoteDisabledTooltip}
          >
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={sendQuoteDisabled}
              startIcon={
                <EmailIcon
                  size="small"
                  color={sendQuoteDisabled ? grayDark : white}
                />
              }
              onClick={handleSendQuoteClick}
            >
              Send Quote
            </Button>
          </MoovsTooltip>
        }
        {...(!isLoading && {
          secondHeaderContent: (
            <Box px={isMobileView ? 2 : 3}>
              <TopOverviewBar items={topBarItems} />
              <QuoteTabs
                tabView={tabView}
                setTabView={setTabView}
                needsPricing={needsPricing}
                needsVehicle={needsVehicle}
              />
            </Box>
          ),
        })}
      >
        {/* Loading indicator */}
        {isLoading && (
          <Box
            display="flex"
            flex="1"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={40} thickness={2} />
          </Box>
        )}

        {/* No data error */}
        {hasError && (
          <Box
            display="flex"
            flex="1"
            alignItems="center"
            justifyContent="center"
          >
            <GQLQueryStatusIndicator
              name="quote"
              error={tripError}
              refetch={tripRefetch}
            />
          </Box>
        )}

        {/* Body content */}
        {trip && request && (
          <Box display="flex" flexDirection="column" height="100%">
            <Box>
              {/* Tab views */}
              {tabView === QuoteTabViewVariant.TRIP_DETAILS && (
                <TripDetails
                  request={request}
                  trip={trip}
                  tripRefetch={tripRefetch}
                  setSaveIndicatorState={setSaveIndicatorState}
                />
              )}
              {tabView === QuoteTabViewVariant.PRICING && (
                <Pricing
                  trip={trip}
                  tripRefetch={tripRefetch}
                  setSaveIndicatorState={setSaveIndicatorState}
                />
              )}
              {tabView === QuoteTabViewVariant.VEHICLE && (
                <Vehicle
                  trip={trip}
                  tripRefetch={tripRefetch}
                  setSaveIndicatorState={setSaveIndicatorState}
                  tabView={tabView}
                />
              )}
            </Box>

            <Box mb={2} pt={4} mt="auto" justifySelf="flex-end">
              <CommentUpdateBlock
                mode="trip"
                comments={trip.comments}
                tripId={trip.id}
                refetchQuery={requestRefetch}
                setSaveIndicatorState={setSaveIndicatorState}
              />
            </Box>
          </Box>
        )}
      </UpdateDrawer>
      <RemoveDialog
        open={deleteTripMenuOption.open}
        onRemove={handleClickRemove}
        onClose={deleteTripMenuOption.onClose}
        title="Are you sure you want to delete this trip?"
        body="This trip will be removed from your reservation, invoice, and dispatch pages. You will not be able to see these deleted trips on saved contacts."
        removeButtonText="Delete"
      />
      <DuplicateTripDialog
        open={duplicateTripMenuOption.open}
        onClose={duplicateTripMenuOption.onClose}
        request={request}
        tripId={tripId}
        isParentDrawer
      />
    </>
  );
}

export default UpdateQuoteDrawer;
