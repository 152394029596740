/**
 * @file VehiclePhotosDraggableListItem.tsx
 * Photo Item.
 * Renders a photo from provided URL with a button to remove.
 *
 * components:
 *  VehiclePhotosDraggableListItem
 */

import React, { useState, MouseEvent } from "react";

import {
  IconButton,
  Menu,
  MenuItem,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";

import MissingImage from "../../../design-system/images/MissingImage.png";
import { VehiclePhoto } from "../../../types";
import { granite, white } from "../../../design-system/colors";
import { EllipsisVerticalIcon } from "../../../design-system/icons";
import PhotoIndexIndicator from "./PhotoIndexIndicator";
import theme, { primaryMainColor } from "../../../theme";
import ImageUpload from "../../ImageUpload/ImageUpload";
import RemoveDialog from "../../RemoveDialog";
import { useSnackbar } from "../../../globals/hooks";

const styles = {
  listItem: {
    cursor: "pointer",
    overflow: "hidden",
    ":hover": {
      "& .MuiIconButton-root": {
        opacity: 1,
        backgroundColor: primaryMainColor,
      },
    },
  },
  iconButton: {
    position: "absolute",
    top: "16px",
    right: "16px",
    cursor: "pointer",
    backgroundColor: primaryMainColor,
    opacity: 0,
    [theme.breakpoints.down("sm")]: {
      opacity: 1,
    },
  },
} as const;

type VehiclePhotosDraggableListItemProps = {
  photo: VehiclePhoto;
  onUpdateVehiclePhoto: (photo: VehiclePhoto) => void;
  onRemoveVehiclePhoto: (photo: VehiclePhoto) => void;
  onPhotoClick: () => void;
};

function VehiclePhotosDraggableListItem(
  props: VehiclePhotosDraggableListItemProps
) {
  const { photo, onUpdateVehiclePhoto, onRemoveVehiclePhoto, onPhotoClick } =
    props;

  const snackbar = useSnackbar();

  // state
  const [loading, setLoading] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = !!anchorEl;

  // event handlers
  const handleRemovePhoto = async () => {
    setRemoveDialogOpen(false);
    setAnchorEl(null);
    onRemoveVehiclePhoto(photo);
  };

  const handleRemoveDialogClose = () => {
    setRemoveDialogOpen(false);
    setAnchorEl(null);
  };

  const handleUpdateVehiclePhoto = (url: string) => {
    onUpdateVehiclePhoto({ ...photo, url });
  };

  const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleRemovePhotoClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setRemoveDialogOpen(true);
  };

  const handleImageUploadError = (errorMessage: string) => {
    snackbar.error(errorMessage);
    setLoading(false);
  };

  const handleImageUploadStart = () => {
    setLoading(true);
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        style={{ cursor: "pointer" }}
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height={150}
        width={200}
        borderRadius="4px"
        border="1px solid rgba(115, 156, 255, 0.3)"
        bgcolor="rgba(229, 238, 255, 0.3)"
        m={1}
        sx={styles.listItem}
        onClick={onPhotoClick}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <img
            style={{
              borderRadius: "4px",
              border: "1px solid rgba(115, 156, 255, 0.3)",
              objectFit: "cover",
            }}
            width="200"
            height="150"
            src={photo.url === "blank" ? MissingImage : photo.url}
            onError={(e: any) => (e.target.src = MissingImage)}
            alt={photo.url || "broken"}
          />
        )}

        {/* index indicator */}
        <PhotoIndexIndicator photoIndex={photo.photoIndex} />

        {!loading && (
          <>
            {/* ellipsis menu */}
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleMenuClick}
              sx={styles.iconButton}
              size="small"
            >
              <EllipsisVerticalIcon color={white} />
            </IconButton>
          </>
        )}

        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={menuOpen}
          onClose={handleMenuClose}
        >
          <label htmlFor={`replace-photo-button-${photo.id}`}>
            <MenuItem onClick={(e) => e.stopPropagation()}>
              <Typography
                style={{ fontWeight: 500, color: granite }}
                variant="body2"
              >
                Replace Image
              </Typography>
            </MenuItem>
          </label>

          <MenuItem
            disabled={photo.photoIndex === 1}
            onClick={handleRemovePhotoClick}
          >
            <Typography
              style={{ fontWeight: 500, color: granite }}
              variant="body2"
            >
              Remove Image
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
      <RemoveDialog
        open={removeDialogOpen}
        onClose={handleRemoveDialogClose}
        onRemove={handleRemovePhoto}
        title="Remove this photo?"
        body="This will permanently remove this photo. Do you want to delete this photo?"
      />
      <ImageUpload
        firebaseDirectory="vehicle_images"
        htmlFor={`replace-photo-button-${photo.id}`}
        aspectRatio={4 / 3}
        maxWidth={2000}
        maxHeight={1500}
        onFileUploadStart={handleImageUploadStart}
        onFileUploadComplete={handleUpdateVehiclePhoto}
        onError={handleImageUploadError}
      />
    </>
  );
}

export default VehiclePhotosDraggableListItem;
