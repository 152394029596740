/**
 * @file ContactsPage.tsx
 * lists contacts for an operator.
 *
 * components:
 * ContactsPage
 *
 */
import React, { useState } from "react";
import { useDebounce } from "use-debounce";

import { Box } from "@mui/material";

import ContactsList from "../../components/contacts/people/ContactsList";
import { useAnalytics, useScreenSize } from "../../globals/hooks";
import ContactsPageTabs from "../../components/contacts/ContactsPageTabs";
import theme, { SIDE_NAV_WIDTH } from "theme";

function ContactsPage() {
  // hooks
  const { isSmallTabletView } = useScreenSize();
  const { track } = useAnalytics();

  // state
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

  // event handlers
  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchTermFocus = () => {
    track("contact_searched");
  };

  const tableStyles = {
    maxWidth: "100vw",
    [theme.breakpoints.up("sm")]: {
      maxWidth: `calc(100vw - ${SIDE_NAV_WIDTH})`,
    },
    overflowX: "auto",
    overflowY: "hidden",
    display: "flex",
    flex: 2,
    height: "100%",
  } as const;

  return (
    <>
      <Box justifyContent="center" width="100%">
        <ContactsPageTabs
          title="Contacts"
          entityName="Contact"
          createEntityRoute="/contacts/create"
          tabValue={0}
          searchTerm={searchTerm}
          onSearchTermChange={handleSearchTermChange}
          onSearchTermFocus={handleSearchTermFocus}
        />

        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            maxWidth: "900px",
            margin: "auto",
            overflowY: "auto",
          }}
        >
          <Box {...(isSmallTabletView && { sx: { tableStyles } })}>
            <ContactsList searchTerm={debouncedSearchTerm} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ContactsPage;
