import React from "react";
import {
  Control,
  FieldErrors,
  useForm,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

import { Box } from "@mui/material";

import {
  EditDynamicPricingDefaultValues,
  useEditDynamicPricingDefaultValues,
} from "./useEditDynamicPricingDefaultValues";
import AdjustAmount from "./inputs/AdjustAmount";
import DaysOfWeek from "./inputs/DaysOfWeek";
import EndDate from "./inputs/EndDate";
import EndTime from "./inputs/EndTime";
import FormName from "./inputs/FormName";
import StartDate from "./inputs/StartDate";
import StartTime from "./inputs/StartTime";
import Vehicles from "./inputs/Vehicles";
import { editDynamicPricingSchema } from "./schemaValidation";
import { useScreenSize } from "globals/hooks";
import Repeat from "./inputs/Repeat";
import { DynamicPricingRule } from "types";

type EditDynamicPricingFormProps = {
  editDynamicPricingDefault: EditDynamicPricingDefaultValues;
  control: Control<EditDynamicPricingDefaultValues>;
  errors: FieldErrors;
  onSetValue: UseFormSetValue<EditDynamicPricingDefaultValues>;
  onClearErrors: UseFormClearErrors<EditDynamicPricingDefaultValues>;
  watch: UseFormWatch<EditDynamicPricingDefaultValues>;
};

function EditDynamicPricingForm(props: EditDynamicPricingFormProps) {
  const {
    control,
    errors,
    onClearErrors,
    onSetValue,
    watch,
    editDynamicPricingDefault,
  } = props;

  const { isSmallTabletView } = useScreenSize();

  return (
    <form>
      {/* form name */}
      <FormName control={control} errors={errors} />

      {/*  start and end date */}
      <Box
        {...(!isSmallTabletView && {
          display: "flex",
          justifyContent: "space-between",
        })}
      >
        <StartDate
          control={control}
          errors={errors}
          defaultDate={moment.utc(editDynamicPricingDefault.startDate)}
        />
        <EndDate
          control={control}
          errors={errors}
          defaultDate={moment.utc(editDynamicPricingDefault.endDate)}
        />
      </Box>

      {/* Days of Week and Vehicles */}
      <Box
        {...(!isSmallTabletView && {
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        })}
      >
        <DaysOfWeek
          errors={errors}
          watch={watch}
          defaultDaysOfWeek={editDynamicPricingDefault.daysOfWeek}
          onSetValue={onSetValue}
          onClearErrors={onClearErrors}
        />
        <Vehicles
          errors={errors}
          watch={watch}
          defaultVehicles={editDynamicPricingDefault.vehicles}
          onSetValue={onSetValue}
          onClearErrors={onClearErrors}
        />
      </Box>

      {/* start and end time */}
      <Box
        {...(!isSmallTabletView && {
          display: "flex",
          justifyContent: "space-between",
        })}
      >
        <StartTime control={control} errors={errors} />
        <EndTime control={control} errors={errors} />
      </Box>

      {/* Increase or decrease amount */}
      <Box mt={2} mb={2}>
        <AdjustAmount control={control} errors={errors} watch={watch} />
      </Box>

      {/* Repeat Rule */}
      <Box mt={2} mb={2}>
        <Repeat
          control={control}
          errors={errors}
          editDynamicPricingDefault={editDynamicPricingDefault}
          watch={watch}
          onSetValue={onSetValue}
        />
      </Box>
    </form>
  );
}

function useEditDynamicPricingForm(rule: DynamicPricingRule) {
  const editDynamicPricingDefault = useEditDynamicPricingDefaultValues(rule);

  const {
    control,
    watch,
    clearErrors,
    setValue,
    formState: { errors },
    handleSubmit: handleEditDynamicPricingSubmit,
    reset: resetEditDynamicPricing,
  } = useForm({
    mode: "onChange",
    defaultValues: editDynamicPricingDefault,
    // @ts-ignore fix later
    resolver: yupResolver(editDynamicPricingSchema),
  });

  return {
    handleEditDynamicPricingSubmit,
    watch,
    resetEditDynamicPricing,
    editDynamicPricingForm: (
      <EditDynamicPricingForm
        errors={errors}
        control={control}
        editDynamicPricingDefault={editDynamicPricingDefault}
        onClearErrors={clearErrors}
        onSetValue={setValue}
        watch={watch}
      />
    ),
  };
}

export default useEditDynamicPricingForm;
