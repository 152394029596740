import React, { ReactNode, useState, useEffect } from "react";

import { Box, Drawer, IconButton, Tooltip } from "@mui/material";

import { grayDark, grayMedium } from "../../design-system/colors";
import { ArrowLeftIcon } from "../../design-system/icons";

import theme, { primaryMainColor } from "../../theme";
import { useScreenSize } from "../../globals/hooks";

const DRAWER_WIDTH = 700;

type ViewOnlyDrawerProps = {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  footerContent?: ReactNode;
  isEntityClosedOut?: boolean;
  drawerBorderColor?: string;
};

function ViewOnlyDrawer(props: ViewOnlyDrawerProps) {
  const {
    open,
    onClose,
    children,
    footerContent,
    isEntityClosedOut = false,
    drawerBorderColor,
  } = props;
  const { isMobileView } = useScreenSize();

  // state
  const [drawerOpen, setDrawerOpen] = useState(false);

  // effects
  useEffect(() => {
    setDrawerOpen(true);
  }, [setDrawerOpen]);

  // event handlers
  const handleDrawerClose = () => {
    setDrawerOpen(false);
    // waits for drawer animation to end
    setTimeout(onClose, 195);
  };

  const borderColor = isEntityClosedOut ? primaryMainColor : drawerBorderColor;

  return (
    <Drawer
      disableEnforceFocus // allows intercom to be focused on messenger while drawer open.
      // there are accessability issues with this prop.
      // reference: https://material-ui.com/api/modal/
      open={open !== undefined ? open : drawerOpen}
      onClose={(_, reason) => reason !== "backdropClick" && handleDrawerClose()}
      anchor="right"
    >
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          [theme.breakpoints.up(DRAWER_WIDTH)]: {
            width: DRAWER_WIDTH,
          },
          overflow: "hidden",
          margin: 0,
        }}
        display="flex"
        flexDirection="column"
        flex="1"
        {...(!!borderColor && {
          border: `5px solid ${borderColor}`,
        })}
      >
        <Box borderBottom={`1px solid ${grayMedium}`}>
          <Box
            mx={2}
            height="78px"
            sx={{
              backgroundColor: theme.palette.background.paper,
              zIndex: theme.zIndex.drawer + 1,
              [theme.breakpoints.down("lg")]: {
                borderBottom: `none`,
              },
            }}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            display="flex"
          >
            <Tooltip
              enterDelay={200}
              enterNextDelay={200}
              title="Close"
              placement="right"
              arrow
            >
              <IconButton
                color="primary"
                onClick={handleDrawerClose}
                size="large"
              >
                <ArrowLeftIcon color={grayDark} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box
          flex="1"
          sx={{
            overflow: "auto",
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            px={isMobileView ? 2 : 4}
          >
            {children}
          </Box>
        </Box>
        {footerContent && (
          <Box borderTop={`1px solid ${grayMedium}`}>
            <Box
              height="78px"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
              display="flex"
              mx={3}
            >
              {footerContent}
            </Box>
          </Box>
        )}
      </Box>
    </Drawer>
  );
}

export default ViewOnlyDrawer;
