import { TripCategory } from "types";
import { DriverPayoutToggleVariant } from "./types";

export const getDriverPayoutButtons = (tripCategory: TripCategory) => {
  // hourly rate button
  const hourlyRate = {
    value: DriverPayoutToggleVariant.HOURLY_RATE,
    ariaLabel: DriverPayoutToggleVariant.HOURLY_RATE,
    dataTestId: `toggle-${DriverPayoutToggleVariant.HOURLY_RATE}`,
    label: "Hourly Rate",
  };

  // base rate percent button
  const baseRatePct = {
    value: DriverPayoutToggleVariant.BASE_RATE_PCT,
    ariaLabel: DriverPayoutToggleVariant.BASE_RATE_PCT,
    dataTestId: `toggle-${DriverPayoutToggleVariant.BASE_RATE_PCT}`,
    label: "% of Base Rate",
  };

  // flat rate button
  const flatRate = {
    value: DriverPayoutToggleVariant.FLAT_RATE,
    ariaLabel: DriverPayoutToggleVariant.FLAT_RATE,
    dataTestId: `toggle-${DriverPayoutToggleVariant.FLAT_RATE}`,
    label: "Flat Rate",
  };

  if (tripCategory === TripCategory.Hourly) {
    return [hourlyRate, baseRatePct];
  }

  if (tripCategory === TripCategory.OneWay) {
    return [hourlyRate, baseRatePct, flatRate];
  }

  return [];
};
