import { useEffect } from "react";
import Cohere from "cohere-js";
import isUndefined from "lodash/isUndefined";

import { useCurrentUser } from "./useCurrentUser";

function useCohereInit() {
  // hooks
  const { id, firstName, lastName, email, isSwoopCurrentOperator } =
    useCurrentUser() || {};

  // init cohere
  useEffect(() => {
    if (isUndefined(isSwoopCurrentOperator)) return;

    if (process.env.REACT_APP_ENVIRONMENT !== "local") {
      Cohere.init(process.env.REACT_APP_COHERE_API_KEY, {
        disableRecording:
          process.env.REACT_APP_ENVIRONMENT !== "production" ||
          isSwoopCurrentOperator,
      });
    }
  }, [isSwoopCurrentOperator]);

  useEffect(() => {
    const environment = process.env.REACT_APP_ENVIRONMENT.slice(0, 3);

    Cohere.identify(id, {
      displayName: `${firstName} ${lastName}`,
      email: `${email} - ${environment} ${process.env.REACT_APP_VERSION}`,
    });
  }, [email, firstName, id, lastName]);
}

export { useCohereInit };
