import React from "react";
import isNumber from "lodash/isNumber";

import { FarmAffiliateVehicle } from "../../../types";
import FarmAffiliateIcon, {
  getFarmAffiliateIconVariant,
} from "../../contacts/farmAffiliates/FarmAffiliateIcon";
import { VehicleCardContent } from ".";
import FarmAffiliateAdditionalContent from "./FarmAffiliateVehicleAdditionalContent";

type FarmAffiliateVehicleCardProps = {
  vehicle: FarmAffiliateVehicle;
  isReadOnly?: boolean;
  selectedVehicles?: string[];
  onSelectVehicle?: (vehicle: FarmAffiliateVehicle) => void;
  ellipsisMenuActions?: {
    onPressRemove?: () => void;
    onPressReplace?: () => void;
  };
  onVehicleViewOnlyClick?: (vehicle: FarmAffiliateVehicle) => void;
};

function FarmAffiliateVehicleCard(props: FarmAffiliateVehicleCardProps) {
  const {
    vehicle,
    selectedVehicles,
    onSelectVehicle,
    ellipsisMenuActions,
    onVehicleViewOnlyClick,
    isReadOnly,
  } = props;

  // derived state
  const isSelected =
    selectedVehicles &&
    selectedVehicles.some(
      (selectedVehicleId: string) => selectedVehicleId === vehicle.id
    );

  const iconVariant = getFarmAffiliateIconVariant({ vehicle });
  const vehicleColorPlusType = `${vehicle.exteriorColor || ""} ${
    vehicle.vehicleType?.typeName || ""
  }`.trim();
  const vehicleCapacity = `${vehicle.capacity} ${
    vehicle.capacity === 1 ? "person" : "persons"
  }`;

  const cardContent = {
    header: vehicle.name,
    subHeader: [vehicleColorPlusType, vehicleCapacity],
    imageTitle: vehicle.name,
  };

  const additionalCardContent = {
    header: "Affiliate",
    line1: (
      <FarmAffiliateAdditionalContent
        farmAffiliateName={vehicle.farmAffiliateName}
        {...(isNumber(vehicle.distanceFromFirstStop) && {
          distanceFromFirstStop: vehicle.distanceFromFirstStop,
        })}
      />
    ),
  };

  return (
    <VehicleCardContent
      mainContent={cardContent}
      additionalContent={additionalCardContent}
      icons={[<FarmAffiliateIcon variant={iconVariant} size="small" />]}
      automatedBaseRateProps={{
        showBRBlock: !!vehicle.baseRateAutomation,
        baseRateAmount: vehicle.baseRateAutomation?.total,
      }}
      isReadOnly={isReadOnly}
      isSelected={isSelected}
      ellipsisMenuActions={ellipsisMenuActions}
      photoUrl={vehicle.photos?.[0]?.url}
      {...(onVehicleViewOnlyClick && {
        onVehicleViewOnlyClick: () => onVehicleViewOnlyClick(vehicle),
      })}
      {...(onSelectVehicle && {
        onSelectVehicle: () => onSelectVehicle(vehicle),
      })}
    />
  );
}

export default FarmAffiliateVehicleCard;
