import React from "react";
import { Moment } from "moment";
import { useQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";

import { Box, Card, CircularProgress } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { DashboardView } from "../DashboardPage";
import DataUnavailableBlock from "pages/dashboard/components/DataUnavailableBlock";
import { LOAD_DRIVER_DASHBOARD_QUERY } from "globals/graphql";
import DownloadButton from "components/buttons/DownloadButton";
import driverPayoutDataGridColumns from "./DriverPayoutDataGrid/DriverPayoutDataGridColumns";
import { granite, grayDark } from "design-system/colors";

type DriverPayoutViewProps = {
  startDate: Moment;
  endDate: Moment;
};

function DriverPayoutView(props: DriverPayoutViewProps) {
  const { startDate, endDate } = props;

  // queries
  const { data: dashboardData, loading: isDashBoardDataLoading } = useQuery(
    LOAD_DRIVER_DASHBOARD_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        startDate,
        endDate,
      },
    }
  );

  // derived States
  const isNoDataAvailable = isEmpty(dashboardData?.loadDriverDashboard);

  const columns = driverPayoutDataGridColumns;

  const view = DashboardView.DRIVER_PAYOUT;

  return (
    <>
      {isDashBoardDataLoading && (
        <Box
          display="flex"
          flex={1}
          alignContent="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
      {isNoDataAvailable && !isDashBoardDataLoading && (
        <DataUnavailableBlock view={view} />
      )}

      {!isNoDataAvailable && !isDashBoardDataLoading && (
        <Card
          variant="outlined"
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: 2,
            "& .MuiDataGrid-rowCount": {
              color: granite,
            },
          }}
        >
          <Box
            display="flex"
            height="40px"
            width="100%"
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <DownloadButton
              buttonText="Download Report"
              endpoint={`/reports/driver-payout/download`}
              analyticsName={"dashbord_reportingDriverPayout"}
              params={{
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
              }}
            />
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <DataGridPro
              disableColumnFilter
              disableColumnPinning
              disableSelectionOnClick
              disableChildrenSorting
              disableColumnMenu
              sortingMode="server"
              columns={columns}
              rows={dashboardData.loadDriverDashboard}
              sx={{
                width: "100%",
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: grayDark,
                },
              }}
            />
          </Box>
        </Card>
      )}
    </>
  );
}

export default DriverPayoutView;
