import React from "react";

type CalendarErrorIconProps = {
  color?: string;
};

const CalendarErrorIcon = (props: CalendarErrorIconProps) => {
  const { color } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_98_111446)">
        <path
          d="M16 2V6M8 2V6M3 10H21M5 4H19C20.1046 4 21 4.89543 21 6V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V6C3 4.89543 3.89543 4 5 4Z"
          stroke={color || "#D63F49"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="9.75"
          y="-0.25"
          width="14.5"
          height="14.5"
          rx="7.25"
          fill="#D63F49"
        />
        <path
          d="M17.0029 4L17.0029 7.6M17.0029 10L16.9969 10"
          stroke={color || "white"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="9.75"
          y="-0.25"
          width="14.5"
          height="14.5"
          rx="7.25"
          stroke={color || "white"}
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_98_111446">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalendarErrorIcon;
