import React, { useState } from "react";

import { Checkbox } from "@mui/material";

import { Request } from "../../../../types";

type RequestCheckProps = {
  onRequestCheckClick: (request: Request) => void;
  request: Request;
};

function RequestCheck(props: RequestCheckProps) {
  const { onRequestCheckClick, request } = props;

  // state
  const [checked, setChecked] = useState(false);

  // event handlers
  const handleRequestCheck = (event) => {
    setChecked(event.target.checked);
    onRequestCheckClick(request);
  };

  return (
    <Checkbox
      color="primary"
      size="medium"
      checked={checked}
      onChange={handleRequestCheck}
    />
  );
}

export default RequestCheck;
