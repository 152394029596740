import React from "react";
import moment from "moment";

import { Box, Typography } from "@mui/material";

import { black, white } from "design-system/colors";
import { EmptyDatabaseWarning } from "design-system/icons";

type DriverPlatformReportNoDataForCurrentPeriodProps = {
  datePeriodLabel: moment.Moment;
};

function DriverPlatformReportNoDataForCurrentPeriod(
  props: DriverPlatformReportNoDataForCurrentPeriodProps
) {
  const { datePeriodLabel } = props;

  return (
    <Box
      sx={{ backgroundColor: white }}
      display="flex"
      justifyContent="space-around"
      alignItems="center"
      height="180px"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <EmptyDatabaseWarning />
        <Box mt={1}>
          <Typography variant="overline" color={black}>
            {`No trips completed in driver app for ${
              datePeriodLabel?.format("MMMM 'YY") || "selected month"
            }`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default DriverPlatformReportNoDataForCurrentPeriod;
