import React from "react";

type DispatchIconProps = {
  color?: string;
  size?: "small";
};

const DispatchIcon = (props: DispatchIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6667 10.3333C11.378 10.3333 10.3333 11.378 10.3333 12.6667C10.3333 13.9553 11.378 15 12.6667 15C13.9553 15 15 13.9553 15 12.6667C15 11.378 13.9553 10.3333 12.6667 10.3333ZM12.6667 10.3333V4.88889C12.6667 4.47633 12.5028 4.08067 12.2111 3.78894C11.9193 3.49722 11.5237 3.33333 11.1111 3.33333H8.77778M3.33333 5.66667C4.622 5.66667 5.66667 4.622 5.66667 3.33333C5.66667 2.04467 4.622 1 3.33333 1C2.04467 1 1 2.04467 1 3.33333C1 4.622 2.04467 5.66667 3.33333 5.66667ZM3.33333 5.66667V15"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 15C16.3431 15 15 16.3431 15 18C15 19.6569 16.3431 21 18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15ZM18 15V8C18 7.46957 17.7893 6.96086 17.4142 6.58579C17.0391 6.21071 16.5304 6 16 6H13M6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9ZM6 9V21"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DispatchIcon;
