import React, { ChangeEvent, useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery } from "@apollo/client";
import size from "lodash/size";
import partition from "lodash/partition";

import {
  Box,
  Typography,
  Divider,
  Button,
  Hidden,
  CircularProgress,
} from "@mui/material";

import { PlusIcon } from "../../../design-system/icons";
import { white } from "../../../design-system/colors";
import SquareButton from "../../../design-system/SquareButton";
import {
  useAnalytics,
  useOperator,
  useScreenSize,
  useSnackbar,
} from "../../../globals/hooks";
import noDynamicPricing from "../../../design-system/illustrations/emptyEarnings.svg";
import { containerStyles, headerStyles } from "../utils/styles";
import EmptyList from "components/globals/EmptyList";
import DynamicPricingDialog from "./components/DynamicPricingDialog/DynamicPricingDialog";
import BetaChip from "../../../design-system/components/chips/BetaChip";
import {
  LOAD_DYNAMIC_PRICING_RULES,
  UPDATE_OPERATOR_SETTINGS,
} from "globals/graphql";
import DynamicPricingList from "./components/DynamicPricingItem/DynamicPricingList";
import DynamicPricingCombineToggle from "./components/DynamicPricingCombineToggle";
import { DynamicPricingRule } from "types";

function DynamicPricingPage() {
  // hooks
  const { isSmallTabletView } = useScreenSize();
  const { track } = useAnalytics();
  const snackbar = useSnackbar();
  const operator = useOperator();

  // state
  const [dynamicPricingDialogOpen, setDynamicPricingDialogOpen] =
    useState(false);

  // query
  const {
    data,
    loading: dynamicPricingLoading,
    refetch: refetchRules,
  } = useQuery(LOAD_DYNAMIC_PRICING_RULES, { fetchPolicy: "network-only" });

  // mutations
  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted(data) {
      const { dynamicPricingRulesCombineEnabled } =
        data.updateOperatorSettings.operator.settings;

      switch (dynamicPricingRulesCombineEnabled) {
        case true:
          track("dynamicPricing_combiningOn");
          snackbar.success(
            "All your dynamic pricing rules will now be combined when applicable"
          );
          break;
        case false:
          snackbar.success(
            "Settings updated, dynamic pricing rules will not be combined"
          );
          break;

        default:
          snackbar.success("Successfully updated settings");
          break;
      }
    },
    onError(error) {
      snackbar.error("Error updating settings");
    },
  });

  const dynamicPricingRules: DynamicPricingRule[] =
    data?.loadDynamicPricingRules;

  const [surchargeRules, discountRules] = partition(
    dynamicPricingRules,
    "isIncrease"
  );

  // event handlers
  const handleAddDynamicPricingClick = () => {
    setDynamicPricingDialogOpen(true);
    track("dynamicPricing_addDynamicBaseRate");
  };
  const handleDialogClose = () => setDynamicPricingDialogOpen(false);

  const handleCombineSettingsToggle = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const input = {
      dynamicPricingRulesCombine: event.target.checked,
    };

    updateOperatorSettings({
      variables: {
        input,
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>Dynamic Pricing | Moovs</title>
      </Helmet>
      <Box display="flex" sx={containerStyles}>
        <Box sx={headerStyles}>
          <Box display="flex" alignItems="center">
            <Typography variant="h2">Dynamic Pricing</Typography>
            <BetaChip />
          </Box>
          {isSmallTabletView ? (
            <SquareButton
              disableElevation={true}
              variant="contained"
              color="primary"
              onClick={handleAddDynamicPricingClick}
            >
              <PlusIcon color={white} />
            </SquareButton>
          ) : (
            <Button
              disableElevation={true}
              variant="contained"
              color="primary"
              size="large"
              style={{ whiteSpace: "nowrap", minHeight: 40, minWidth: 40 }}
              onClick={handleAddDynamicPricingClick}
            >
              Add Dynamic Base Rate
            </Button>
          )}
        </Box>
        <Hidden mdDown>
          <Divider />
        </Hidden>

        {dynamicPricingLoading ? (
          <Box
            height="calc(100vh - 170px)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size={40} thickness={2} />
          </Box>
        ) : (
          <>
            {size(dynamicPricingRules) ? (
              <>
                <Box {...(isSmallTabletView && { margin: 0.8 })}>
                  <DynamicPricingCombineToggle
                    checked={
                      operator?.settings?.dynamicPricingRulesCombineEnabled // optional for when operator settings are empty
                    }
                    onChange={handleCombineSettingsToggle}
                  />
                </Box>

                {size(surchargeRules) ? (
                  <Box {...(isSmallTabletView && { margin: 0.8 })}>
                    <DynamicPricingList
                      header="Surcharges"
                      rules={surchargeRules}
                    />
                  </Box>
                ) : null}

                {size(discountRules) ? (
                  <Box {...(isSmallTabletView && { margin: 0.8 })}>
                    <DynamicPricingList
                      header="Discounts"
                      rules={discountRules}
                    />
                  </Box>
                ) : null}
              </>
            ) : (
              <Box
                height="calc(100vh - 170px)"
                display="flex"
                justifyContent="center"
                alignItems="center"
                mr={1}
                ml={1}
              >
                <EmptyList
                  title="Dynamic Base Rate"
                  copy="Add a dynamic base rate rule to adjust your base rate by date, day, and time. You must have vehicle pricing for dynamic pricing to work."
                  imageUrl={noDynamicPricing}
                  ctaCopy="Add Dynamic Base Rate"
                  onCreateClick={handleAddDynamicPricingClick}
                />
              </Box>
            )}
          </>
        )}

        <DynamicPricingDialog
          open={dynamicPricingDialogOpen}
          onClose={handleDialogClose}
          onRefetchRules={refetchRules}
        />
      </Box>
    </>
  );
}

export default DynamicPricingPage;
