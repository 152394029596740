/**
 * @file graphql/vehicle.ts
 * queries/mutations for Vehicle.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_FARM_AFFILIATE_VEHICLES_QUERY = gql`
  query loadFarmAffiliateVehicles(
    $searchFilter: String
    $tripId: ID
    $vehicleTypeSlug: String
    $cursor: String
    $limit: Int
    $stops: [StopInformationInputForAutomatedPricing]
  ) {
    loadFarmAffiliateVehicles(
      searchFilter: $searchFilter
      tripId: $tripId
      vehicleTypeSlug: $vehicleTypeSlug
      after: $cursor
      first: $limit
      stops: $stops
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          available
          description
          licensePlate
          weekendHourlyCost
          weekdayHourlyCost
          weekendMinMinutes
          weekdayMinMinutes
          minimumTotalBaseRate
          deadheadRatePerMile
          tripRatePerMile
          totalDeadheadDurationMinutes
          name
          capacity
          description
          exteriorColor
          baseRateAutomation {
            total
            lineItems {
              name
              description
              lineTotal
              source
            }
          }
          cancellationPolicy {
            id
            variant
            refund100 {
              periodName
              periodValue
              active
            }
            refund50 {
              periodName
              periodValue
              active
            }
            refund25 {
              periodName
              periodValue
              active
            }
          }
          insurancePolicy {
            id
            insuranceCompany
            policyNumber
          }
          features {
            id
            name
            category
          }
          photos {
            id
            url
            photoIndex
          }
          vehicleType {
            typeName
            typeSlug
            iconCategory
          }
          farmAffiliateName
          farmAffiliateVariant
          isMoovsLightOperator
          isMoovsLiteOperator
          distanceFromFirstStop
        }
      }
    }
  }
`;

export const SEND_REQUEST_AFFILIATE_EMAIL_MUTATION = gql`
  mutation SendRequestAffiliateEmail($input: SendRequestAffiliateEmailInput!) {
    sendRequestAffiliateEmail(input: $input) {
      __typename
    }
  }
`;
