import React, { useState } from "react";

import { Button } from "@mui/material";

import { useScreenSize } from "globals/hooks";
import { MoovsMarketAppData } from "../../../MoovsMarketAppList/hooks/useMoovsMarketApps";
import DeleteAppDialog from "./components/DeleteAppDialog";

type DeleteAppButtonProps = {
  app: MoovsMarketAppData;
};

const DeleteAppButton = (props: DeleteAppButtonProps) => {
  const { app } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // state
  const [deleteAppDialogOpen, setdeleteAppDialogOpen] = useState(false);

  // event handlers
  const handleDeleteClick = () => {
    setdeleteAppDialogOpen(true);
  };

  const handleDeleteAppDialogClose = () => {
    setdeleteAppDialogOpen(false);
  };

  return (
    <>
      <Button
        disableRipple
        onClick={handleDeleteClick}
        color="error"
        sx={{
          ...(isMobileView && { paddingTop: 0 }),
        }}
        fullWidth={isMobileView}
      >
        Delete app
      </Button>
      <DeleteAppDialog
        app={app}
        open={deleteAppDialogOpen}
        onClose={handleDeleteAppDialogClose}
      />
    </>
  );
};

export default DeleteAppButton;
