import React, { Dispatch, ReactNode, SetStateAction } from "react";

import {
  Typography,
  Dialog,
  Box,
  Button,
  IconButton,
  Slider,
} from "@mui/material";
import theme from "theme";

import {
  CrossIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  TrashIcon,
} from "../design-system/icons";
import { white, grayLight, granite, moovsBlue } from "../design-system/colors";
import { useScreenSize } from "../globals/hooks";

type MoovsProfilePhotoDialogProps = {
  open: boolean;
  onClose: () => void;
  dialogTitle: string;
  onAccept?: () => void;
  children?: ReactNode;
  acceptButtonText?: string;
  closeButtonText?: string;
  removeAcceptButton?: boolean;
  autoMargin?: boolean;
  isEditImage?: boolean;
  isDeleteImage?: boolean;
  zoom?: number;
  setZoom?: Dispatch<SetStateAction<number>>;
  onRemoveImage?: any;
};

function MoovsProfilePhotoDialog(props: MoovsProfilePhotoDialogProps) {
  const {
    open,
    onClose,
    dialogTitle,
    onAccept,
    children,
    acceptButtonText,
    closeButtonText,
    removeAcceptButton,
    autoMargin,
    isEditImage,
    isDeleteImage,
    zoom,
    setZoom,
    onRemoveImage,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    // TODO: replace this with MoovsDialog to better handle submit state
    <Dialog
      maxWidth="xs"
      onClose={onClose}
      open={open}
      fullWidth
      fullScreen={isMobileView}
      aria-labelledby="profile-photo-dialog-title"
    >
      <Box display="flex" flex="1 0 auto" flexDirection="column" height="100%">
        <Box
          display="flex"
          position="sticky"
          top="0px"
          flexDirection="row"
          px={isMobileView ? 2 : 4}
          py={isMobileView ? 1 : 2}
          justifyContent="space-between"
          alignItems="center"
          bgcolor={white}
          borderBottom={`1px solid ${grayLight}`}
          zIndex={theme.zIndex.drawer + 3}
        >
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            id={"profile-photo-dialog-title"}
          >
            <Box display="flex">
              <Box display="flex" flex="1" alignItems="center">
                <Typography variant="h3">{dialogTitle}</Typography>
              </Box>

              <IconButton
                onClick={onClose}
                style={{ marginRight: "-12px" }}
                size="large"
              >
                <CrossIcon color={granite} />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minHeight="0"
          flex={1}
          style={{ cursor: "default" }}
        >
          <Box
            display="flex"
            height="100%"
            pt={1}
            px={isMobileView ? 2 : 4}
            flexDirection="column"
            overflow="auto"
            flex={1}
            {...(autoMargin && { margin: "8px auto 16px" })}
          >
            {children}
          </Box>

          {/* Zoom component for image edits */}
          {isEditImage && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={isMobileView ? 2 : 4}
              py={2}
              borderTop={`1px solid ${grayLight}`}
              bgcolor={white}
              zIndex={1}
            >
              <IconButton onClick={() => setZoom(zoom - 1)} size="large">
                <MagnifyingGlassMinusIcon color={moovsBlue} />
              </IconButton>
              <Box mx={4} width="200px" maxWidth="calc(100vw - 200px)">
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom: number) => setZoom(zoom)}
                />
              </Box>
              <IconButton onClick={() => setZoom(zoom + 1)} size="large">
                <MagnifyingGlassPlusIcon color={moovsBlue} />
              </IconButton>
            </Box>
          )}

          <Box
            display="flex"
            justifyContent={isDeleteImage ? "space-between" : "flex-end"}
            alignItems="center"
            px={isMobileView ? 2 : 4}
            py={2}
            borderTop={`1px solid ${grayLight}`}
            bgcolor={white}
            zIndex={1}
          >
            {isDeleteImage && (
              <IconButton onClick={onRemoveImage} size="large">
                <TrashIcon color={moovsBlue} />
              </IconButton>
            )}
            <Box
              display="flex"
              flexDirection="row"
              {...(isMobileView && !isDeleteImage && { flex: 1 })}
            >
              <Button color="primary" fullWidth onClick={onClose}>
                {closeButtonText || "Close"}
              </Button>

              {onAccept && !removeAcceptButton && (
                <Box
                  position="relative"
                  ml={isMobileView ? 0 : 2}
                  {...(isMobileView && { width: "100%" })}
                >
                  <Button
                    color="primary"
                    sx={{ whiteSpace: "nowrap" }}
                    onClick={onAccept}
                    variant="contained"
                    data-testid="moovs-dialog-accept-button"
                    {...(isMobileView && { fullWidth: true })}
                  >
                    {acceptButtonText || "Accept"}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

export default MoovsProfilePhotoDialog;
