import React, { useState } from "react";
import {
  FieldErrors,
  useForm,
  UseFormClearErrors,
  UseFormSetValue,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Typography } from "@mui/material";

import { errorRed } from "design-system/colors";
import { workflowSchema, WorkflowSelectionsEnum } from "./schemaValidation";
import { workflowDefaultValues } from "./defaultValues";
import WorkflowOption from "./components/WorkflowOption";
import dateOptionSvg from "../../../../../../../design-system/illustrations/dateOption.svg";
import dateRangeOptionSvg from "../../../../../../../design-system/illustrations/dateRangeOption.svg";
import TimesOfDayOptionSvg from "../../../../../../../design-system/illustrations/TimesOfDayOption.svg";
import { useScreenSize } from "globals/hooks";

type WorkflowValues = {
  workflow: string;
};

type WorkflowFormProps = {
  errors: FieldErrors;
  onSetValue: UseFormSetValue<WorkflowValues>;
  onClearErrors: UseFormClearErrors<WorkflowValues>;
  workflowOutline: WorkflowSelectionsEnum;
  onSetWorkflowOutline: (state) => void;
};

function WorkflowForm(props: WorkflowFormProps) {
  const {
    errors,
    onSetValue,
    onClearErrors,
    workflowOutline,
    onSetWorkflowOutline,
  } = props;

  const error = errors.workflow;

  // hooks
  const { isSmallTabletView } = useScreenSize();

  // event handler
  const handleClick = (e) => {
    const selectedWorkflowOutline = e.currentTarget.dataset.workflowKey;

    onSetWorkflowOutline(selectedWorkflowOutline);
    onClearErrors("workflow");
    onSetValue("workflow", selectedWorkflowOutline);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection={isSmallTabletView ? "column" : "row"}
        justifyContent="space-between"
        marginTop={isSmallTabletView ? "20px" : "0px"}
      >
        <WorkflowOption
          workflowKey={WorkflowSelectionsEnum.date}
          image={dateOptionSvg}
          header="Adjust base rate for a specific date"
          bodyText="Base rate price will be adjusted for a day of your choosing"
          onClick={handleClick}
          error={errors.workflow}
          workflowOutline={workflowOutline}
        />
        <WorkflowOption
          workflowKey={WorkflowSelectionsEnum.dateRange}
          image={dateRangeOptionSvg}
          header="Adjust base rate for a date range"
          bodyText="Base rate price will be adjusted for the date range and days of your choosing"
          onClick={handleClick}
          error={errors.workflow}
          workflowOutline={workflowOutline}
        />
        <WorkflowOption
          workflowKey={WorkflowSelectionsEnum.timesOfDay}
          image={TimesOfDayOptionSvg}
          header="Adjust base rate for time of day"
          bodyText="Base rate price will be adjusted for times and days of your choosing"
          onClick={handleClick}
          error={errors.workflow}
          workflowOutline={workflowOutline}
        />
      </Box>
      {error && (
        <Box mt={3}>
          <Typography style={{ color: errorRed }}>{error.message}</Typography>
        </Box>
      )}
    </Box>
  );
}

function useWorkflowForm() {
  // state
  // workflowOutline will persist when goes back to first step
  const [workflowOutline, setWorkflowOutline] =
    useState<WorkflowSelectionsEnum>(null);

  // setup react hook form
  const {
    setValue,
    clearErrors,
    formState: { errors },
    handleSubmit: handleWorkflowSubmit,
    reset: resetWorkflowForm,
  } = useForm({
    defaultValues: workflowDefaultValues,
    resolver: yupResolver(workflowSchema),
  });

  return {
    handleWorkflowSubmit,
    setWorkflowOutline,
    resetWorkflowForm,
    workFlowFormComponent: (
      <WorkflowForm
        errors={errors}
        onSetValue={setValue}
        onClearErrors={clearErrors}
        onSetWorkflowOutline={setWorkflowOutline}
        workflowOutline={workflowOutline}
      />
    ),
  };
}

export default useWorkflowForm;
