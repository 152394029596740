/**
 * Wrapper for RemoveDialog that encapsilates removal logic
 */
import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";

import RemoveDialog from "components/RemoveDialog";
import { REMOVE_TERM_MUTATION, LOAD_TERMS_QUERY } from "globals/graphql";
import { useOperator, useSnackbar } from "globals/hooks";
import size from "lodash/size";

type RemoveTermDialogProps = {
  open: boolean;
  onClose: () => void;
  termId: string;
};

function RemoveTermDialog(props: RemoveTermDialogProps) {
  const { open, onClose, termId } = props;

  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const operator = useOperator();

  // queries
  const { data, loading, error } = useQuery(LOAD_TERMS_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  // mutations
  const [removeTerm] = useMutation(REMOVE_TERM_MUTATION, {
    refetchQueries: [{ query: LOAD_TERMS_QUERY }],
    onCompleted() {
      snackbar.success("Successfully deleted term!");
      history.push(`/settings/terms-and-conditions`);
    },
    onError(error) {
      snackbar.error("Error deleting term");
    },
  });

  // event handlers
  const handleRemoveTerm = () => {
    if (error) {
      snackbar.error("Error deleting term");
    } else if (
      operator.settings.requireConsentWhenBooking &&
      size(data.terms) <= 1
    ) {
      snackbar.error(
        "Must have at least one terms & condition policy " +
          "when customer consent setting is on"
      );
    } else {
      removeTerm({
        variables: {
          input: {
            id: termId,
          },
        },
      });

      onClose();
    }
  };

  return (
    <RemoveDialog
      open={open}
      onRemove={handleRemoveTerm}
      onClose={onClose}
      title="Remove this term?"
      body="This will permanantly remove the term. Do you want to remove this
   term?"
      isLoading={loading}
    />
  );
}

export default RemoveTermDialog;
