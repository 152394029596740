import { useQuery } from "@apollo/client";

import { LOAD_SALES_AUTOMATION } from "globals/graphql";
import { SalesAutomation } from "types";

type UseLoadSalesAutomationProps = {
  requestId: string;
  skip?: boolean;
};

function useLoadSalesAutomation(props: UseLoadSalesAutomationProps) {
  const { requestId, skip } = props;

  const { data, loading: salesAutomationLoading } = useQuery(
    LOAD_SALES_AUTOMATION,
    {
      variables: {
        id: requestId,
      },
      skip: !requestId || skip,
      fetchPolicy: "cache-and-network",
    }
  );

  const salesAutomation: SalesAutomation = data?.node.salesAutomation;

  return {
    salesAutomation,
    salesAutomationLoading,
  };
}

export default useLoadSalesAutomation;
