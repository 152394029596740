import React, { useMemo } from "react";
import { Moment } from "moment";

import { Box } from "@mui/material";

import { EstimationIcon } from "design-system/icons";
import { applyUTCOffsetToTime } from "globals/utils/helpers";
import MoovsDateTimePicker from "components/MoovsDateTimePicker";

type ConfirmDateTimeProps = {
  dateTime: string;
  onDateTimeChange: (dateTime: string | Moment) => void;
  dropOffIsEstimate?: boolean;
};

function ConfirmDateTime(props: ConfirmDateTimeProps) {
  const { dateTime, onDateTimeChange, dropOffIsEstimate = false } = props;

  const adjustedStopDateTime = useMemo(
    () =>
      dateTime
        ? applyUTCOffsetToTime(dateTime, "subtract").toISOString()
        : null,
    [dateTime]
  );

  return (
    <Box
      mt={3}
      mb={3}
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      {
        <>
          <MoovsDateTimePicker
            value={adjustedStopDateTime}
            onAccept={onDateTimeChange}
            format={"MMM Do YYYY, h:mm a"}
          />

          {dropOffIsEstimate && (
            <Box sx={{ ml: 2 }}>
              <EstimationIcon size="small" />
            </Box>
          )}
        </>
      }
    </Box>
  );
}

export default ConfirmDateTime;
