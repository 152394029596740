import React, { useMemo } from "react";

import { Box } from "@mui/material";

import { UserAccessPermission } from "types";
import UserAccessPermissionsSection from "./UserAccessPermissionsSection";

export type PermissionData = {
  id: string;
  path: string;
  category: string;
  name: string;
  grantedByDefault: boolean;
  active: boolean;
};

type UserAccessPermissionsProps = {
  activePermissions: UserAccessPermission[];
  allPermissions: UserAccessPermission[];
  handleUserAccessPermissionsChange: (
    modifiedAccessPermission: UserAccessPermission
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  homeError: string;
};

function UserAccessPermissions(props: UserAccessPermissionsProps) {
  const {
    activePermissions,
    allPermissions,
    handleUserAccessPermissionsChange,
    homeError,
  } = props;

  const userAccessPermissionsSorted = useMemo(() => {
    if (!allPermissions || !allPermissions.length) return {};

    const permissionsByCategory = allPermissions.reduce(
      (acc: any, { id, path, category, name, grantedByDefault }) => {
        if (!acc[category]) {
          acc[category] = [];
        }

        const active = activePermissions.some(
          (userAccessPermission: UserAccessPermission) => {
            return userAccessPermission.id === id;
          }
        );

        const permissionData: PermissionData = {
          id,
          path,
          category,
          name,
          grantedByDefault,
          active,
        };

        acc[category].push(permissionData);

        return acc;
      },
      {}
    );

    // Sort permissions for each category by alphabetical order
    for (const category in permissionsByCategory) {
      permissionsByCategory[category].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    }

    return permissionsByCategory;
  }, [allPermissions, activePermissions]);

  return (
    <Box>
      {Object.entries(userAccessPermissionsSorted).map(
        ([category, permissions]) => (
          <UserAccessPermissionsSection
            key={category}
            category={category}
            permissions={permissions as PermissionData[]}
            handleUserAccessPermissionsChange={
              handleUserAccessPermissionsChange
            }
            {...(category === "home" && { error: homeError })}
          />
        )
      )}
    </Box>
  );
}

export default UserAccessPermissions;
