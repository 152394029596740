import React, { useRef } from "react";

import { Box } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";
import { EmailModeEnum } from "types";
import CustomMessageInputBase from "components/chat/CustomMessageInputBase";
import {
  PdfAttachmentButton,
  SendEmailDialogFooter,
  SubjectField,
  ToCcEmailFields,
} from "./components";
import {
  useEmailMessage,
  useEmailMode,
  useEmailSubject,
  usePdfAttachment,
  useSendEmail,
  useToCcEmails,
} from "./hooks";
import { grayLight } from "design-system/colors";
import { useScreenSize } from "globals/hooks";

export type EmailRecipient = {
  email: string;
  name?: string;
  phone?: string;
  error?: boolean;
  __typename?:
    | "Company"
    | "Contact"
    | "GriddnetOperator"
    | "MoovsNetworkOperator";
};

type SendEmailDialogBaseProps = {
  open: boolean;
  onClose: () => void;
  toEmails?: EmailRecipient[];
  ccEmails?: EmailRecipient[];
  mode: EmailModeEnum;
};

type SendEmailDialogProps =
  | (SendEmailDialogBaseProps & { requestId: string; invoiceId?: never })
  | (SendEmailDialogBaseProps & { requestId?: never; invoiceId: string })
  | (SendEmailDialogBaseProps & { requestId?: never; invoiceId?: never });

function SendEmailDialog(props: SendEmailDialogProps) {
  const { open, onClose, requestId } = props;

  // refs
  const messageRef = useRef(null);

  // hooks
  const { isMobileView } = useScreenSize();
  const { toEmails, ccEmails, onToEmailsChange, onCcEmailsChange } =
    useToCcEmails(props);
  const { mode, onModeChange } = useEmailMode(props);
  const { message, onMessageChange } = useEmailMessage({ open });
  const { subject, onSubjectChange, subjectError, onValidateSubject } =
    useEmailSubject({ open });
  const { pdfAttached, onClosePdfAttachment } = usePdfAttachment({
    open,
    mode,
  });

  const { onSendEmailClick, sendEmailLoading, errorMessage } = useSendEmail({
    ...props,
    toEmails,
    ccEmails,
    onToEmailsChange,
    onCcEmailsChange,
    subject,
    onValidateSubject,
    message,
    mode,
    pdfAttached,
  });

  return (
    <MoovsDialog
      dialogTitle="Send Email"
      acceptButtonText="Send Email"
      size="sm"
      open={open}
      onClose={onClose}
      customFooter={
        <SendEmailDialogFooter
          errorMessage={errorMessage}
          onDialogClose={onClose}
          onHandleSendEmailClick={onSendEmailClick}
          sendEmailLoading={sendEmailLoading}
        />
      }
    >
      <Box
        mt={1}
        borderBottom={`1px solid ${grayLight}`}
        mx={isMobileView ? -2 : -4}
        px={isMobileView ? 2 : 4}
      >
        {mode === EmailModeEnum.Custom && (
          <SubjectField
            subject={subject}
            onSubjectChange={onSubjectChange}
            subjectError={subjectError}
          />
        )}
        <ToCcEmailFields
          mode={mode}
          onModeChange={onModeChange}
          toEmails={toEmails}
          ccEmails={ccEmails}
          onToEmailsChange={onToEmailsChange}
          onCcEmailsChange={onCcEmailsChange}
        />
        {pdfAttached && (
          <PdfAttachmentButton
            requestId={requestId}
            onClosePdfAttachment={onClosePdfAttachment}
          />
        )}
      </Box>
      {/* Custom Email message */}
      <CustomMessageInputBase
        messageRef={messageRef}
        messageValue={message}
        placeholder="Add An Optional Message..."
        onChange={onMessageChange}
      />
    </MoovsDialog>
  );
}

export default SendEmailDialog;
