import React from "react";

type SentIconProps = {
  color?: string;
  size?: "small";
};

const SentIcon = (props: SentIconProps) => {
  const { color } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33333 5.66667C9.33333 5.66667 1 4.66667 1 13C3.66667 8 9.33333 8.66667 9.33333 8.66667V12.3333L15 7L9.33333 2V5.66667Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SentIcon;
