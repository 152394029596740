import React from "react";

import { Grid, Typography, Box } from "@mui/material";

import { VehicleFeature } from "../../types";

type ReadOnlyVehicleFeaturesProps = {
  vehicleFeatures: VehicleFeature[];
};

function ReadyOnlyVehicleFeatures(props: ReadOnlyVehicleFeaturesProps) {
  const { vehicleFeatures } = props;

  return (
    <>
      <Box mb={1}>
        <Typography variant="h4">Vehicle Features</Typography>
      </Box>
      <Grid container spacing={2}>
        {vehicleFeatures.map(({ name, id }, i) => (
          <Grid key={id} item xs={12} sm={6}>
            <Typography variant="body2">{name}</Typography>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default ReadyOnlyVehicleFeatures;
