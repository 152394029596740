import React from "react";
import { useHistory } from "react-router-dom";

import { Box, Link, Typography } from "@mui/material";

import { currency } from "globals/utils/helpers";
import { black, moovsBlue } from "design-system/colors";
import { Route } from "types";

type FarmInDetailsInfoDesktopProps = {
  route: Route;
};

function FarmInDetailsInfoDesktop(props: FarmInDetailsInfoDesktopProps) {
  const { route } = props;
  const {
    farmeePricing,
    farmAffiliate,
    requestedVehicleNote,
    externalConfirmationNumber,
  } = route;
  const { totalAmt } = farmeePricing || {};
  const { operatorName, id: farmAffiliateId } = farmAffiliate || {};

  // hooks
  const history = useHistory();

  // event handlers
  const handleAffiliateNameClick = () => {
    history.push(`/affiliates/update/${farmAffiliateId}`);
  };

  return (
    <Box display="flex" flexDirection="row">
      {totalAmt >= 0 && (
        <Box flex={1}>
          <Typography variant="overline">receivable</Typography>
          <Typography variant="body2">{currency(totalAmt)}</Typography>
        </Box>
      )}

      <Box flex={1}>
        <Typography variant="overline">affiliate</Typography>
        <Link
          sx={{
            cursor: "pointer",
            textDecoration: "none",
            color: black,
            "&:hover": {
              color: moovsBlue,
              textDecorationLine: "underline",
            },
          }}
          onClick={handleAffiliateNameClick}
        >
          <Typography variant="body2">{operatorName}</Typography>
        </Link>
      </Box>

      {externalConfirmationNumber && (
        <Box flex={1}>
          <Typography variant="overline">conf. no.</Typography>
          <Typography variant="body2">{externalConfirmationNumber}</Typography>
        </Box>
      )}

      {requestedVehicleNote && (
        <Box flex={1}>
          <Typography variant="overline">vehicle type</Typography>
          <Typography variant="body2">{requestedVehicleNote}</Typography>
        </Box>
      )}
    </Box>
  );
}

export default FarmInDetailsInfoDesktop;
