import React from "react";

import { warningYellow, successGreen, errorRed } from "design-system/colors";
import { ReplaceIcon, CheckIcon, CrossIcon } from "design-system/icons";
import { DriverStatusName } from "types";

type DriverStatusIndicatorProps = {
  driverStatus: DriverStatusName;
};

function DriverStatusIndicator(props: DriverStatusIndicatorProps) {
  const { driverStatus } = props;

  if (!driverStatus) return null;

  return {
    PENDING: <ReplaceIcon size="small" color={warningYellow} />,
    ACCEPTED: <CheckIcon size="small" color={successGreen} />,
    DECLINED: <CrossIcon size="small" color={errorRed} />,
  }[driverStatus];
}

export default DriverStatusIndicator;
