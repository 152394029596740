import React from "react";

type CancelTripIconProps = {
  color?: string;
  size?: "small";
};

const CancelTripIcon = (props: CancelTripIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.949 12.949L3.04982 3.0498M8.5 5.5L11.8182 4.18182L10.5 7.5M4.5 7.5L2.90909 8.40191L6.66029 9.33971L7.59809 13.0909L8.5 11.5M8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1Z"
          stroke={color || "#195FE9"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.777 19.777L4.22115 4.22112M12.5 8.5L18 6L15.5 11.5M7.5 10.9737L4 12.6316L9.89474 14.1053L11.3684 20L13 16.5M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1Z"
        stroke={color || "#195FE9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CancelTripIcon;
