import { QueryResult, useQuery } from "@apollo/client";
import { Replace } from "globals/utilityTypes";
import { shouldShowVehicleAvaliabilityOrBRA } from "globals/utils/shouldShowVehicleAvaliabilityOrBRA";
import {
  LoadFarmAffiliateVehiclesConnection,
  QueryLoadFarmAffiliateVehiclesArgs,
  Stop,
} from "types";
import {
  LOAD_FARM_AFFILIATE_VEHICLES_QUERY,
  shapeStopsToAutomatedBaseRateQueryStopsArg,
} from "..";

type QueryArgsWithExistingTrip = Omit<
  QueryLoadFarmAffiliateVehiclesArgs,
  "stops"
>;

type QueryArgsWithStops = Replace<
  Omit<QueryLoadFarmAffiliateVehiclesArgs, "tripId">,
  "stops",
  Stop[]
>;

type UseLoadFarmAffiliateVehiclesQueryParams = {
  queryArgs: QueryArgsWithExistingTrip | QueryArgsWithStops;
  shouldSkip?: boolean;
};

function useLoadFarmAffiliateVehiclesQuery(
  params: UseLoadFarmAffiliateVehiclesQueryParams
): QueryResult<{
  loadFarmAffiliateVehicles: LoadFarmAffiliateVehiclesConnection;
}> {
  const {
    queryArgs,
    queryArgs: { searchFilter, vehicleTypeSlug },
    shouldSkip,
  } = params;

  const cannotQueryForVehicles = !!(
    "stops" in queryArgs &&
    queryArgs.stops?.length > 0 &&
    !shouldShowVehicleAvaliabilityOrBRA(queryArgs.stops)
  );

  return useQuery<{
    loadFarmAffiliateVehicles: LoadFarmAffiliateVehiclesConnection;
  }>(LOAD_FARM_AFFILIATE_VEHICLES_QUERY, {
    variables: {
      searchFilter,
      vehicleTypeSlug: vehicleTypeSlug === "All" ? undefined : vehicleTypeSlug,
      limit: 20,
      ...("tripId" in queryArgs && { tripId: queryArgs.tripId }),
      ...("stops" in queryArgs &&
        queryArgs.stops?.length > 0 && {
          stops: shapeStopsToAutomatedBaseRateQueryStopsArg(queryArgs.stops),
        }),
    },
    fetchPolicy: "network-only",
    skip: shouldSkip || cannotQueryForVehicles,
  });
}

export default useLoadFarmAffiliateVehiclesQuery;
