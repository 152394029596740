import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import { Box, Button, TextField, Typography } from "@mui/material";

import { CREATE_SENDGRID_CUSTOM_DOMAIN } from "../../../../globals/graphql";
import MoovsDialog from "../../../MoovsDialog";
import EmailPreviewBox from "./EmailPreviewBox";
import { useAnalytics, useSnackbar } from "../../../../globals/hooks";

type EnableDomainBlockProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function EnableCustomDomainFlow(props: EnableDomainBlockProps) {
  const { setSaveIndicatorState } = props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // state
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [customDomain, setCustomDomain] = useState("");
  const [invalidCustomDomain, setInvalidCustomDomain] = useState(false);
  const [addCustomDomainDialogOpen, setAddCustomDomainDialogOpen] =
    useState(false);

  // mutations
  const [addSendgridCustomDomain] = useMutation(CREATE_SENDGRID_CUSTOM_DOMAIN, {
    onCompleted() {
      track("customDomain_setupCompleted", { status: "Succeeded" });
      setAddCustomDomainDialogOpen(false);
      snackbar.success(
        `Added custom domain! Remember, you must add a 'from' address in order to start sending emails from ${customDomain}.`,
        {
          link: `/settings/general`,
          linkLabel: `Setup 'From' Address`,
        }
      );

      track("custom_domain_request");
    },

    onError(error) {
      track("customDomain_setupCompleted", { status: "Failed" });
      setAddCustomDomainDialogOpen(false);
      setSaveIndicatorState("error");
      snackbar.error("error enabling custom domain");
    },
  });

  // event handlers
  const handleChangeUserData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubmitDisabled(false);
    setCustomDomain(event.target.value);
  };

  const handleAddCustomDomainClick = () => {
    setSubmitDisabled(true);
    const includesAtSymbol = customDomain.includes("@");

    if (!includesAtSymbol) {
      addSendgridCustomDomain({
        variables: {
          input: {
            customDomain: customDomain,
          },
        },
      });
    } else {
      setInvalidCustomDomain(true);
    }
  };

  const handleSetupCustomEmailDomainClick = () => {
    setAddCustomDomainDialogOpen(true);
    track("customDomain_setupInitiated");
  };

  const placeholderText = "abclimo.com, NOT info@abclimo.com";
  const helperText =
    "Please enter your domain. The domain is the portion after @.";

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box mb={1.5}>
          <Button
            variant="outlined"
            color="primary"
            component="span"
            onClick={handleSetupCustomEmailDomainClick}
          >
            Setup Custom Email Domain
          </Button>
        </Box>
      </Box>

      {/* Add Custom Domain Dialog */}
      <MoovsDialog
        hideTopBorder
        size="sm"
        open={addCustomDomainDialogOpen}
        onClose={() => setAddCustomDomainDialogOpen(false)}
        onAccept={handleAddCustomDomainClick}
        dialogTitle="Setup your custom domain"
        acceptButtonText="Save My Domain"
        closeButtonText="Close"
        acceptDisabled={submitDisabled}
        hideLoadingIndicator={invalidCustomDomain}
      >
        <Box display="flex" flexDirection="column" mb={2}>
          <Box mb={1}>
            <Typography style={{ fontWeight: 600 }} variant="h6">
              Domain you send from
            </Typography>
          </Box>

          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              value={customDomain}
              name="customDomain"
              placeholder={placeholderText}
              onChange={handleChangeUserData}
              error={invalidCustomDomain}
              helperText={helperText}
            />
          </Box>

          <Box mb={3}>
            <EmailPreviewBox customDomain={customDomain} />
          </Box>
        </Box>
      </MoovsDialog>
    </>
  );
}

export default EnableCustomDomainFlow;
