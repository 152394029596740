import React, { useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

import { SortMarkerIcon, TripIcon, RoundTripIcon } from "design-system/icons";
import { white, whiteTransparent, grayDark } from "design-system/colors";

type CreateReservationSplitButtonProps = {
  createRoute: string;
  createShuttlingRoute: string;
};

function CreateReservationSplitButton(
  props: CreateReservationSplitButtonProps
) {
  const { createRoute, createShuttlingRoute } = props;

  const anchorRef = useRef(null);

  const [buttonGroupOptionsOpen, setButtonGroupOptionsOpen] = useState(false);

  const handleToggle = () => {
    setButtonGroupOptionsOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setButtonGroupOptionsOpen(false);
  };

  return (
    <Box>
      <ButtonGroup
        disableElevation={true}
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{
            whiteSpace: "nowrap",
            minHeight: 40,
            minWidth: 40,
          }}
        >
          <Link to={createRoute} style={{ color: white }}>
            Create
          </Link>
        </Button>
        <Button
          color="primary"
          size="small"
          style={{ minHeight: 40, minWidth: 32 }}
          aria-controls={
            buttonGroupOptionsOpen ? "split-button-menu" : undefined
          }
          aria-expanded={buttonGroupOptionsOpen ? "true" : undefined}
          aria-label="select reservation type"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <SortMarkerIcon color={white} />
        </Button>
      </ButtonGroup>
      <Popper
        open={buttonGroupOptionsOpen}
        anchorEl={anchorRef.current}
        placement={"bottom-end"}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "right top",
            }}
          >
            <Paper elevation={2} sx={{ mt: 0.5 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" sx={{ p: 0 }}>
                  <MenuItem
                    component={Link}
                    to={createRoute}
                    onClick={handleClose}
                  >
                    <TripIcon
                      color={grayDark}
                      fill={whiteTransparent}
                      size={"small"}
                    />
                    <Typography variant="body2" ml={1}>
                      New Reservation
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to={createShuttlingRoute}
                    onClick={handleClose}
                  >
                    <RoundTripIcon color={grayDark} />
                    <Typography variant="body2" ml={1}>
                      New Shuttle Reservation
                    </Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}

export default CreateReservationSplitButton;
