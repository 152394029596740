import React from "react";
import Draggable from "react-draggable";

import { Paper } from "@mui/material";

import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    dragged: {
      cursor: "grab",
    },
    dragging: {
      cursor: "grabbing",
    },
  })
);

export function DraggableComponent(props) {
  const paperProps = props.paperProps || props;
  const classes = useStyles();

  return (
    <Draggable
      handle={`#${props.handleName || "moovs-dialog-title"}`}
      cancel={'[class*="MuiDialogContent-root"]'}
      defaultClassName={classes.dragged}
      defaultClassNameDragging={classes.dragging}
    >
      <Paper {...paperProps} />
    </Draggable>
  );
}
