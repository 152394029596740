import React from "react";

type CalendarIconProps = {
  size?: "small";
  color?: string;
};

const CalendarIcon = (props: CalendarIconProps) => {
  const { color, size } = props;
  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 1C11 1.83333 11 3.66667 11 4.5M4.85464 1V4.5M2.5 6.50007L14 6.5M4 2.5C2.89543 2.5 2 3.39543 2 4.5V13C2 14.1046 2.89543 15 4 15H12C13.1046 15 14 14.1046 14 13V4.5C14 3.39543 13.1046 2.5 12 2.5C9.33333 2.5 6.66667 2.5 4 2.5Z"
          stroke="#999999"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 2V6M8 2V6M3 10H21M5 4H19C20.1046 4 21 4.89543 21 6V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V6C3 4.89543 3.89543 4 5 4Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarIcon;
