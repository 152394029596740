import React, { ChangeEvent, useMemo, useState } from "react";
import uniqBy from "lodash/uniqBy";
import { useMutation, useQuery } from "@apollo/client";

import { Box, Typography, Divider } from "@mui/material";

import ReplacePassengerDialog from "../../globals/ReplacePassengerDialog";
import ProfileInfoCard from "../../globals/ProfileInfoCard";
import { Contact, TemporaryPassenger } from "../../../types";
import {
  LOAD_CONTACTS_QUERY,
  UPDATE_TRIP_MUTATION,
} from "../../../globals/graphql";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import { alabaster, grayDark } from "../../../design-system/colors";
import UpdateTemporaryPassengerDialog from "./UpdateTemporaryPassengerDialog";
import { ReplaceContactIcon } from "../../../design-system/icons";
import { formatPhoneNumber } from "../../../globals/utils/phoneNumberFormatter/phoneNumberFormatter";

type PassengerInfoUpdateBlockProps = {
  tripId: string;
  contact: Contact;
  refetch: () => void;
  setSaveIndicatorState: (
    saveState: "loading" | "default" | "saved" | "error"
  ) => void;
  temporaryPassenger?: TemporaryPassenger;
  bookingContact: Partial<Contact> | null;
};

function PassengerInfoUpdateBlock(props: PassengerInfoUpdateBlockProps) {
  const {
    tripId,
    contact,
    refetch,
    setSaveIndicatorState,
    temporaryPassenger,
    bookingContact,
  } = props;

  // hooks
  const snackbar = useSnackbar();

  const { data: contacts } = useQuery(LOAD_CONTACTS_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      searchTerm: "",
      limit: 30,
    },
  });

  const bookingContactLinkedPassengerIds = useMemo(
    () =>
      bookingContact?.linkedPassengers?.map((passenger) => passenger.id) || [],
    [bookingContact]
  );
  const suggestedContacts = useMemo(
    () =>
      uniqBy(
        [
          ...(contacts?.loadContacts?.edges.map(({ node }) => node) || []),
          ...(bookingContact?.linkedPassengers || []),
        ],
        "id"
      ),
    [bookingContact?.linkedPassengers, contacts?.loadContacts?.edges]
  );

  // state
  const [replaceDialogOpen, setReplaceDialogOpen] = useState(false);
  const [temporaryPassengerValues, setTemporaryPassengerValues] =
    useState<TemporaryPassenger>({
      name: temporaryPassenger?.name,
      mobilePhone: temporaryPassenger?.mobilePhone,
      mobilePhoneInternational: temporaryPassenger?.mobilePhoneInternational,
      phoneCountryCode: temporaryPassenger?.phoneCountryCode,
      phoneCountryDialCode: temporaryPassenger?.phoneCountryDialCode,
      phoneCountryName: temporaryPassenger?.phoneCountryName,
      phoneCountryFormat: temporaryPassenger?.phoneCountryFormat,
    });
  const [temporaryPassengerErrors, setTemporaryPassengerErrors] = useState({
    name: "",
  });

  const [
    createTemporaryPassengerDialogOpen,
    setCreateTemporaryPassengerDialogOpen,
  ] = useState(false);

  // mutations
  const [updateTrip] = useMutation(UPDATE_TRIP_MUTATION, {
    onCompleted() {
      setReplaceDialogOpen(false);
      setSaveIndicatorState("saved");
      refetch();
    },
    onError() {
      snackbar.error("Error updating trip");
    },
  });

  // event handlers
  const handleReplacePassengerClick = () => setReplaceDialogOpen(true);
  const handleReplaceDialogClose = () => setReplaceDialogOpen(false);

  const handleReplacePassengerSave = (passenger: Contact) => {
    if (!passenger) return;
    setSaveIndicatorState("loading");

    updateTrip({
      variables: {
        input: {
          id: tripId,
          passengerId: passenger.id,
        },
      },
    });
  };

  const handleCreateTemporaryPassengerClick = () => {
    setTemporaryPassengerValues(temporaryPassenger);
    setCreateTemporaryPassengerDialogOpen(true);
  };

  const handleCreateTemporaryPassengerDialogClose = () => {
    setTemporaryPassengerValues(temporaryPassenger);
    setCreateTemporaryPassengerDialogOpen(false);
  };

  const handleTemporaryPassengerSaveClick = () => {
    const { formatted, international } =
      formatPhoneNumber(
        temporaryPassengerValues.mobilePhoneInternational,
        temporaryPassengerValues.phoneCountryCode,
        temporaryPassengerValues.phoneCountryDialCode
      ) || {};

    if (!temporaryPassengerValues.name) {
      setTemporaryPassengerErrors({
        name: "Please enter a name.",
      });
      return;
    }

    setSaveIndicatorState("loading");
    updateTrip({
      variables: {
        input: {
          id: tripId,
          temporaryPassenger: {
            ...temporaryPassengerValues,
            mobilePhone: formatted,
            mobilePhoneInternational: international,
          },
        },
      },
    });
    setCreateTemporaryPassengerDialogOpen(false);
  };

  const handleResetToBookingContact = () => {
    setSaveIndicatorState("loading");

    updateTrip({
      variables: {
        input: {
          id: tripId,
          passengerId: bookingContact.id,
        },
      },
    });
  };

  const handleTemporaryPassengerInfoChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = event.target.name;
    const value = event.target.value;

    setTemporaryPassengerValues({
      ...temporaryPassengerValues,
      [name]: value,
    });
    name === "name" && setTemporaryPassengerErrors({ name: "" });
  };

  const handleTemporaryPassengerPhoneChange = (value, country) => {
    setTemporaryPassengerValues({
      ...temporaryPassengerValues,
      mobilePhone: value,
      mobilePhoneInternational: value,
      phoneCountryCode: country.countryCode,
      phoneCountryDialCode: country.dialCode,
      phoneCountryName: country.name,
      phoneCountryFormat: country.format,
    });
  };

  const handleRemoveTemporaryPassenger = () => {
    setSaveIndicatorState("loading");
    updateTrip({
      variables: {
        input: {
          id: tripId,
          temporaryPassenger: {
            name: null,
            mobilePhone: null,
          },
        },
      },
    });
  };

  const isTemporaryPassenger = !!temporaryPassenger?.name;

  return (
    <>
      <Box mb={2}>
        <Box mt={3} mb={1.5}>
          <Typography variant="h5">Trip Details</Typography>
        </Box>
        <Box mb={2}>
          <Divider />
        </Box>
        <Box mt={2.5} sx={{ backgroundColor: alabaster, borderRadius: "4px" }}>
          {contact && !isTemporaryPassenger && (
            <ProfileInfoCard
              headerText="Passenger"
              firstName={contact.firstName}
              lastName={contact.lastName}
              email={contact.email}
              phone={contact.mobilePhone}
              preferences={contact.preferences}
              actionButtons={[
                {
                  icon: <ReplaceContactIcon color={grayDark} />,
                  menuOptions: [
                    { label: "Header", header: "REPLACE PASSENGER" },
                    {
                      eventHandler: handleReplacePassengerClick,
                      label: "Existing Contact",
                    },
                    {
                      eventHandler: handleCreateTemporaryPassengerClick,
                      label: "Temporary Passenger",
                    },
                    ...(contact.id !== bookingContact?.id
                      ? [
                          {
                            eventHandler: handleResetToBookingContact,
                            label: "Booking Contact",
                          },
                        ]
                      : []),
                  ],
                },
              ]}
            />
          )}
          {isTemporaryPassenger && (
            <>
              <ProfileInfoCard
                headerText="Temporary Passenger"
                firstName={temporaryPassenger?.name}
                phone={temporaryPassenger?.mobilePhone || ""}
                actionButtons={[
                  {
                    icon: <ReplaceContactIcon color={grayDark} />,
                    menuOptions: [
                      {
                        eventHandler: handleCreateTemporaryPassengerClick,
                        label: "Edit Temporary Passenger",
                      },
                      {
                        eventHandler: handleRemoveTemporaryPassenger,
                        label: "Remove Temporary Passenger",
                      },
                    ],
                  },
                ]}
              />
            </>
          )}
        </Box>
      </Box>
      <ReplacePassengerDialog
        open={replaceDialogOpen}
        handleClose={handleReplaceDialogClose}
        handleSave={handleReplacePassengerSave}
        suggestedContacts={suggestedContacts}
        bookingContactLinkedPassengerIds={bookingContactLinkedPassengerIds}
      />
      <UpdateTemporaryPassengerDialog
        open={createTemporaryPassengerDialogOpen}
        onClose={handleCreateTemporaryPassengerDialogClose}
        onSave={handleTemporaryPassengerSaveClick}
        onTemporaryPassengerInfoChange={handleTemporaryPassengerInfoChange}
        handleTemporaryPassengerPhoneChange={
          handleTemporaryPassengerPhoneChange
        }
        temporaryPassengerValues={temporaryPassengerValues}
        temporaryPassengerErrors={temporaryPassengerErrors}
      />
    </>
  );
}

export default PassengerInfoUpdateBlock;
