import React from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import moment from "moment";

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";

import { black, grayDark, grayLight } from "design-system/colors";
import { useAnalytics, useOperator, useScreenSize } from "globals/hooks";
import { currency } from "globals/utils/helpers";
import { LAGO_ANNUAL_PLANS, LAGO_VIP_PLANS } from "globals/constants";
import { LOAD_SUBSCRIPTION_METRICS_QUERY } from "globals/graphql";
import { MetricsProgressBar } from "./components";
import {
  EnterprisePlanIcon,
  FreePlanIcon,
  ProPlanIcon,
  StandardPlanIcon,
} from "design-system/icons";
import { LagoPlanCode } from "types";

const {
  StandardMonthly,
  StandardAnnual,
  ProMonthly,
  ProAnnual,
  VipMonthly,
  VipAnnual,
} = LagoPlanCode;

const FREE_PLAN_INFO = {
  name: "Free",
  icon: <FreePlanIcon />,
  iconLarge: <FreePlanIcon size="large" />,
  price: 0,
  userLimit: 3,
  tripLimit: 10,
  vehicleLimit: 10,
  driverLimit: 10,
};

const LAGO_PLAN_INFO = {
  [StandardMonthly]: {
    name: "Standard",
    icon: <StandardPlanIcon size="large" />,
    price: 129,
    userLimit: 3,
    tripLimit: 150,
    vehicleLimit: -1,
    driverLimit: -1,
  },
  [StandardAnnual]: {
    name: "Standard",
    icon: <StandardPlanIcon size="large" />,
    price: 99 * 12,
    userLimit: 3,
    tripLimit: 150,
    vehicleLimit: -1,
    driverLimit: -1,
  },
  [ProMonthly]: {
    name: "Pro",
    icon: <ProPlanIcon size="large" />,
    price: 179,
    userLimit: 5,
    tripLimit: 250,
    vehicleLimit: -1,
    driverLimit: -1,
  },
  [ProAnnual]: {
    name: "Pro",
    icon: <ProPlanIcon size="large" />,
    price: 149 * 12,
    userLimit: 5,
    tripLimit: 250,
    vehicleLimit: -1,
    driverLimit: -1,
  },
  [VipMonthly]: {
    name: "Enterprise",
    icon: <EnterprisePlanIcon size="large" />,
    price: null,
    userLimit: -1,
    tripLimit: 400,
    vehicleLimit: -1,
    driverLimit: -1,
  },
  [VipAnnual]: {
    name: "Enterprise",
    icon: <EnterprisePlanIcon size="large" />,
    price: null,
    userLimit: -1,
    tripLimit: 400,
    vehicleLimit: -1,
    driverLimit: -1,
  },
};

function CurrentPlanInfoCard() {
  // hooks
  const { isSmallTabletView } = useScreenSize();
  const operator = useOperator();
  const { lagoPlanCode } = operator || {};
  const history = useHistory();
  const { track } = useAnalytics();

  // derived state
  const isAnnual = LAGO_ANNUAL_PLANS.includes(lagoPlanCode);
  const isVip = LAGO_VIP_PLANS.includes(lagoPlanCode);
  const isFree = !lagoPlanCode;
  const planInfo = lagoPlanCode ? LAGO_PLAN_INFO[lagoPlanCode] : FREE_PLAN_INFO;

  // queries
  const { data: subscriptionMetricsData, loading: metricsLoading } = useQuery(
    LOAD_SUBSCRIPTION_METRICS_QUERY,
    { fetchPolicy: "network-only" }
  );

  const subscriptionMetrics = subscriptionMetricsData?.loadSubscriptionMetrics;
  const metricsLoaded = subscriptionMetrics && !metricsLoading;

  // event handler
  const handlePlansPageButton = () => {
    track("billing_plansPageOpened");
    history.push("/settings/billing/plans");
  };

  return (
    <Box>
      <Typography variant="overline">CURRENT PLAN</Typography>

      <Box
        minHeight="198px"
        border="1px solid"
        borderRadius="4px"
        borderColor={grayLight}
        p={2}
        my={2}
        sx={{
          boxShadow:
            "0px 24px 24px -4px #1E1E1E05, 0px 8px 10px -2.5px #1E1E1E05",
        }}
      >
        {!planInfo || !metricsLoaded ? (
          <Box
            display="flex"
            minHeight="inherit"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size={24} />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              {/* Plan Name */}
              <Box display="flex" alignItems="center">
                {planInfo.icon}
                <Box
                  display="flex"
                  flexDirection={isSmallTabletView ? "column" : "row"}
                  alignSelf={isSmallTabletView ? "flex-start" : "center"}
                >
                  <Typography variant="h4" ml={2}>
                    {`${planInfo.name} plan`}
                  </Typography>

                  {lagoPlanCode && (
                    <Chip
                      size="small"
                      label={
                        <Typography variant="overline" color={black}>
                          {isAnnual ? "ANNUAL" : "MONTHLY"}
                        </Typography>
                      }
                      sx={{
                        width: "fit-content",
                        ml: 1.5,
                        ...(isSmallTabletView && { mt: 1 }),
                      }}
                    />
                  )}
                </Box>
              </Box>

              {/* Price Info */}
              {!isVip && (
                <Box display="flex" alignItems="center">
                  <Typography variant="h2">
                    {currency(planInfo.price).slice(0, -3)}
                  </Typography>
                  <Typography
                    variant="caption"
                    whiteSpace="break-spaces"
                    color={grayDark}
                  >
                    {` / ${isAnnual ? "year" : "month"}`}
                  </Typography>
                </Box>
              )}
            </Box>

            <Divider />

            <Box
              display="flex"
              flexDirection={isSmallTabletView ? "column" : "row"}
              justifyContent="space-between"
              alignItems="center"
              gap={isSmallTabletView ? 2 : 5}
              mt={2}
              mb={3}
              px={1}
            >
              <MetricsProgressBar
                count={subscriptionMetrics.userCount}
                total={planInfo.userLimit}
                unit={"members"}
              />
              <MetricsProgressBar
                count={subscriptionMetrics.tripCount}
                total={planInfo.tripLimit}
                unit={"trips"}
                showCountOnly={isVip}
              />
              <MetricsProgressBar
                count={subscriptionMetrics.vehicleCount}
                total={planInfo.vehicleLimit}
                unit={"vehicles"}
              />
              <MetricsProgressBar
                count={subscriptionMetrics.driverCount}
                total={planInfo.driverLimit}
                unit={"drivers"}
              />
            </Box>

            <Divider />

            <Box
              display="flex"
              flexDirection={isSmallTabletView ? "column" : "row"}
              alignItems={isSmallTabletView ? "flex-start" : "center"}
              alignSelf={isSmallTabletView ? "flex-start" : "flex-end"}
              {...(isSmallTabletView && { width: "100%" })}
              mt={2}
            >
              {subscriptionMetrics?.renewalDate && (
                <Box
                  display="flex"
                  {...(isSmallTabletView ? { mb: 2 } : { mr: 2 })}
                >
                  <Typography variant="caption" whiteSpace="break-spaces">
                    {"Your plan renews on "}
                  </Typography>
                  <Typography variant="h6" fontWeight="600">
                    {moment
                      .utc(subscriptionMetrics.renewalDate)
                      .format("MMMM D, YYYY")}
                  </Typography>
                </Box>
              )}

              <Box {...(isSmallTabletView && { width: "100%" })}>
                <Button
                  variant="contained"
                  onClick={handlePlansPageButton}
                  sx={{ width: "inherit" }}
                >
                  {isFree ? "Upgrade Plan" : "Manage Plan"}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default CurrentPlanInfoCard;
