import React from "react";
import moment from "moment";

import { Typography, Box } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import MuiAccordionSummary from "@mui/material/AccordionSummary";

import {
  granite,
  grayDark,
  grayLight,
  successGreen,
  tintGreen,
  moovsBlue,
  moovsBlueSelected,
} from "design-system/colors";
import theme from "theme";
import { ChevronDownIcon } from "design-system/icons";
import { StripePayout } from "types";
import { currency } from "globals/utils/helpers";
import RoundChip from "design-system/components/chips/RoundChip";

const AccordionSummary = withStyles({
  root: {
    minHeight: 56,
  },
  expandIcon: {
    order: -1,
    padding: 0,
    marginRight: theme.spacing(2),
    transform: "rotate(-90deg)",
    "&$expanded": {
      transform: "rotate(0deg) !important",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

type AccordionSummaryDesktopProps = {
  stripePayout: StripePayout;
};

function AccordionSummaryDesktop(props: AccordionSummaryDesktopProps) {
  const { stripePayout } = props;

  return (
    <AccordionSummary expandIcon={<ChevronDownIcon color={granite} />}>
      <Box display="flex" flexDirection="row" flex="1" maxWidth={900} mx="auto">
        <Box display="flex" flex="1">
          <Typography variant="body2">
            {moment.utc(stripePayout.payoutDate).format("MM/DD/YY")}
          </Typography>
        </Box>
        <Box display="flex" flex="4">
          <Typography variant="body2" fontWeight={700}>
            {currency(stripePayout.amount)}
          </Typography>
        </Box>
        <Box display="flex" flex="1" justifyContent="flex-end">
          <RoundChip
            label={
              {
                paid: "PAID",
                in_transit: "IN TRANSIT",
              }[stripePayout.status] || "N/A"
            }
            color={
              {
                paid: successGreen,
                in_transit: moovsBlue,
              }[stripePayout.status] || grayDark
            }
            backgroundColor={
              {
                paid: tintGreen,
                in_transit: moovsBlueSelected,
              }[stripePayout.status] || grayLight
            }
          />
        </Box>
      </Box>
    </AccordionSummary>
  );
}

export default AccordionSummaryDesktop;
