import React, { useState } from "react";
import InputMask from "react-input-mask";

import { DatePicker } from "@mui/lab";
import { Box, MenuItem, TextField, Typography } from "@mui/material";

import { ImageUploadBlock } from "components/ImageUploadBlock";
import LocationAutoComplete from "components/autocompletes/LocationAutoComplete";
import { CalendarIcon } from "design-system/icons";
import { useScreenSize } from "globals/hooks";
import statesList from "globals/utils/statesList";
import { CreateDriverInput } from "types";

type AdditionalDetailsProps = {
  driver: CreateDriverInput;
  setDriver: React.Dispatch<React.SetStateAction<CreateDriverInput>>;
};

function AdditionalDetails(props: AdditionalDetailsProps) {
  const { driver, setDriver } = props;

  //state
  const [noteError, setNoteError] = useState(null);

  // hooks
  const { isSmallTabletView } = useScreenSize();

  // event handler
  const handleAdditionalDetailsChange = (e) => {
    const { value, name } = e.target;

    if (name === "note") {
      value.toString().length >= 3000
        ? setNoteError("Reached character limit")
        : setNoteError(null);
    }

    setDriver({ ...driver, [name]: value });
  };

  return (
    <>
      <ImageUploadBlock
        mode="create"
        variant="driver"
        placeholder={[driver.firstName, driver.lastName]}
        photoUrl={driver.driverProfilePhoto}
        onPhotoUrlUpdate={(photoUrl) =>
          setDriver({ ...driver, driverProfilePhoto: photoUrl })
        }
      />
      <Box mt={5} mb={2}>
        <Typography variant="h3">Additional Details</Typography>
      </Box>
      <Box mb={4}>
        <Box
          display="flex"
          flexDirection={isSmallTabletView ? "column" : "row"}
          mb={1}
        >
          <Box
            display="flex"
            flex="1"
            mr={isSmallTabletView ? 0 : 1}
            mb={isSmallTabletView ? 1 : 0}
          >
            <InputMask
              mask="(999) 999-9999"
              maskChar=" "
              onChange={handleAdditionalDetailsChange}
              value={driver.homePhone}
            >
              {() => (
                <TextField
                  type="tel"
                  variant="outlined"
                  fullWidth
                  name="homePhone"
                  label="Home Phone"
                />
              )}
            </InputMask>
          </Box>
          <Box display="flex" flex="1">
            <LocationAutoComplete
              fullWidth
              label="Home Address"
              value={driver.homeAddress}
              onChange={(value) => {
                setDriver({ ...driver, homeAddress: value.description });
              }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={isSmallTabletView ? "column" : "row"}
          mb={1}
        >
          <Box
            display="flex"
            flex="1"
            mr={isSmallTabletView ? 0 : 1}
            mb={isSmallTabletView ? 1 : 0}
          >
            <TextField
              fullWidth
              name="licenseNumber"
              label="Driver License Number"
              variant="outlined"
              value={driver.licenseNumber}
              onChange={handleAdditionalDetailsChange}
            />
          </Box>
          <Box display="flex" flex="1">
            <TextField
              fullWidth
              name="licenseClass"
              label="DL Class"
              variant="outlined"
              value={driver.licenseClass}
              onChange={handleAdditionalDetailsChange}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={isSmallTabletView ? "column" : "row"}
          mb={1}
        >
          <Box
            display="flex"
            flex="1"
            mr={isSmallTabletView ? 0 : 1}
            mb={isSmallTabletView ? 1 : 0}
          >
            <TextField
              fullWidth
              select
              name="licenseState"
              label="DL State"
              variant="outlined"
              value={driver.licenseState || ""}
              onChange={handleAdditionalDetailsChange}
            >
              {statesList.map((state) => {
                return (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
          <Box display="flex" flex="1">
            <DatePicker
              disablePast
              label={isSmallTabletView ? "Exp. Date" : "Expiration Date"}
              value={driver.licenseExpirationDate}
              onChange={() => {}}
              onAccept={(date) => {
                setDriver({
                  ...driver,
                  licenseExpirationDate: date,
                });
              }}
              inputFormat="MM/DD/YYYY"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name="licenseExpirationDate"
                  variant="outlined"
                />
              )}
              components={{
                OpenPickerIcon: CalendarIcon,
              }}
            />
          </Box>
        </Box>
        <TextField
          inputProps={{ style: { minHeight: 154 }, maxLength: 3000 }}
          error={!!noteError}
          helperText={noteError}
          fullWidth
          multiline
          name="note"
          label="Driver Notes"
          variant="outlined"
          value={driver.note}
          onChange={handleAdditionalDetailsChange}
        />
      </Box>
    </>
  );
}

export default AdditionalDetails;
