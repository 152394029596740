import clone from "lodash/clone";

export const move = (array: any[], oldIndex: number, targetIndex: number) => {
  const newArray = clone(array);
  const element = newArray[oldIndex];

  newArray.splice(oldIndex, 1);
  newArray.splice(targetIndex, 0, element);

  return newArray;
};
