/**
 * @file invoice.graphql.ts
 * queries/mutations for Invoice.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_INVOICES_QUERY = gql`
  query LoadInvoices($invoiceSearch: String, $cursor: String, $limit: Int) {
    loadInvoices(invoiceSearch: $invoiceSearch, after: $cursor, first: $limit) {
      edges {
        node {
          id
          updatedAt
          createdAt
          memo
          dueDate
          status
          isOutstanding
          invoiceNumber
          amountDue
          totalAmount
          contact {
            id
            firstName
            lastName
            email
            mobilePhone
            mobilePhoneInternational
          }
          farmAffiliate {
            ... on MoovsNetworkOperator {
              id
              operatorName
              operatorEmail
              operatorPhone
              isMoovsLiteOperator
            }
            ... on GriddnetOperator {
              id
              operatorName
              operatorEmail
              operatorPhone
            }
            ... on MoovsAiOperator {
              id
              operatorName
              operatorEmail
              operatorPhone
            }
          }
          company {
            id
            name
            phoneNumber
            email
          }
        }
      }
    }
  }
`;

export const LOAD_INVOICE_QUERY = gql`
  query Invoice($id: ID!) {
    node(id: $id) {
      id
      ... on Invoice {
        id
        updatedAt
        createdAt
        memo
        dueDate
        status
        isOutstanding
        amountDue
        totalAmount
        invoiceNumber
        emailLogs {
          id
          createdAt
          emailType
          confirmationNumber
          toRecipients
          ccRecipients
          emailEvents {
            id
            eventType
            triggeredAt
            recipient
          }
        }
        subPayments {
          id
          amount
          paymentId
          payment {
            id
            createdAt
            updatedAt
            stripePayoutDate
            contact {
              id
            }
            amount
            method
            action
            paymentMethod {
              id
              createdAt
              updatedAt
              contactId
              linkedPassenger {
                id
                firstName
                lastName
              }
              card {
                id
                expMonth
                expYear
                last4
                brand
              }
              billingDetails {
                name
              }
            }
            note
            paymentStatus
            didPaymentSucceed
            refunds {
              id
              createdAt
              updatedAt
              amount
              note
              method
              paymentStatus
              stripePayoutDate
              paymentMethod {
                id
                createdAt
                updatedAt
                contactId
                linkedPassenger {
                  id
                  firstName
                  lastName
                }
                billingDetails {
                  name
                }
                card {
                  id
                  expMonth
                  expYear
                  last4
                  brand
                }
              }
            }
          }
        }
        requests {
          id
          orderNumber
          totalAmount
          amountDue
          company {
            id
          }
          bookingContact {
            id
            firstName
            lastName
            paymentMethods {
              id
              createdAt
              updatedAt
              contactId
              customer
              isPrimary
              linkedPassenger {
                id
                firstName
                lastName
              }
              card {
                id
                expMonth
                expYear
                last4
                brand
              }
              billingDetails {
                name
              }
            }
          }
          trips {
            id
            amountDue
            totalAmount
            stops {
              id
              dateTime
            }
            routes {
              id
            }
            subPayments {
              id
              createdAt
              updatedAt
              amount
              payment {
                method
                action
                note
                paymentStatus
                didPaymentSucceed
                amount
                refunds {
                  id
                  createdAt
                  updatedAt
                  amount
                  note
                  method
                  paymentStatus
                  stripePayoutDate
                  paymentMethod {
                    id
                    createdAt
                    updatedAt
                    contactId
                    linkedPassenger {
                      id
                      firstName
                      lastName
                    }
                    card {
                      id
                      expMonth
                      expYear
                      last4
                      brand
                    }
                    billingDetails {
                      name
                    }
                  }
                }
                contact {
                  id
                }
                paymentMethod {
                  id
                  createdAt
                  updatedAt
                  contactId
                  linkedPassenger {
                    id
                    firstName
                    lastName
                  }
                  card {
                    id
                    expMonth
                    expYear
                    last4
                    brand
                  }
                  billingDetails {
                    name
                  }
                }
              }
            }
            cancelledAt
          }
        }
        contact {
          id
          firstName
          lastName
          email
          mobilePhone
          mobilePhoneInternational
          preferences
          paymentMethods {
            id
            createdAt
            updatedAt
            contactId
            cardNote
            isPrimary
            linkedPassenger {
              id
              firstName
              lastName
            }
            card {
              id
              expMonth
              expYear
              last4
              brand
            }
            billingDetails {
              name
            }
          }
          company {
            id
            name
          }
        }
        farmAffiliate {
          ... on MoovsNetworkOperator {
            id
            operatorName
            operatorEmail
            operatorPhone
            isMoovsLiteOperator
            paymentMethods {
              id
              createdAt
              updatedAt
              contactId
              cardNote
              isPrimary
              linkedPassenger {
                id
                firstName
                lastName
              }
              card {
                id
                expMonth
                expYear
                last4
                brand
              }
              billingDetails {
                name
              }
            }
          }
          ... on GriddnetOperator {
            id
            operatorName
            operatorEmail
            operatorPhone
            paymentMethods {
              id
              createdAt
              updatedAt
              contactId
              cardNote
              isPrimary
              linkedPassenger {
                id
                firstName
                lastName
              }
              card {
                id
                expMonth
                expYear
                last4
                brand
              }
              billingDetails {
                name
              }
            }
          }
          ... on MoovsAiOperator {
            id
            operatorName
            operatorEmail
            operatorPhone
            paymentMethods {
              id
              createdAt
              updatedAt
              contactId
              cardNote
              isPrimary
              linkedPassenger {
                id
                firstName
                lastName
              }
              card {
                id
                expMonth
                expYear
                last4
                brand
              }
              billingDetails {
                name
              }
            }
          }
        }
        company {
          id
          name
          email
          phoneNumber
          companyLogoUrl
          paymentMethods {
            id
            createdAt
            updatedAt
            contactId
            cardNote
            isPrimary
            linkedPassenger {
              id
              firstName
              lastName
            }
            card {
              id
              expMonth
              expYear
              last4
              brand
            }
            billingDetails {
              name
            }
          }
        }
      }
    }
  }
`;

export const CREATE_INVOICE_MUTATION = gql`
  mutation CreateInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      invoice {
        id
        createdAt
        updatedAt
        memo
        dueDate
        requests {
          id
        }
        contact {
          id
          firstName
        }
        company {
          id
        }
      }
    }
  }
`;

export const UPDATE_INVOICE_MUTATION = gql`
  mutation UpdateInvoice($input: UpdateInvoiceInput!) {
    updateInvoice(input: $input) {
      invoice {
        id
        createdAt
        updatedAt
        memo
        dueDate
        status
        isOutstanding
        requests {
          id
        }
        contact {
          id
          firstName
        }
      }
    }
  }
`;

export const REMOVE_INVOICE_MUTATION = gql`
  mutation RemoveInvoice($input: RemoveInvoiceInput!) {
    removeInvoice(input: $input) {
      invoice {
        id
        createdAt
        updatedAt
        memo
        dueDate
        status
        requests {
          id
        }
        contact {
          id
          firstName
        }
      }
    }
  }
`;

export const SEND_INVOICE_EMAIL_MUTATION = gql`
  mutation SendInvoiceEmailToCustomer(
    $input: SendInvoiceEmailToCustomerInput!
  ) {
    sendInvoiceEmailToCustomer(input: $input) {
      invoice {
        id
        createdAt
        updatedAt
        memo
        dueDate
        status
        requests {
          id
        }
        contact {
          id
          firstName
        }
      }
    }
  }
`;
