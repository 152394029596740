import React from "react";
import { useHistory } from "react-router-dom";

import { IconButton, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { moovsBlue } from "design-system/colors";
import { RequestStage } from "types";
import { OpenIcon } from "design-system/icons";
import { useAnalytics, useScreenSize } from "globals/hooks";

function TripConfNumberColumn(props: GridRenderCellParams<string>) {
  const { row } = props;

  // hooks
  const history = useHistory();
  const { track } = useAnalytics();
  const { isMobileView } = useScreenSize();

  const isQuote = row.request.stage === RequestStage.Quote;

  const tripNumber = `${row.request.orderNumber}-${row.tripNumber}`;

  const navigateToTrip = () => {
    if (history.location.pathname.match("contacts")) {
      track("contact_tripViewed");
    }

    if (history.location.pathname.match("affiliates")) {
      track("affiliate_tripViewed");
    }

    const quoteRoute = isMobileView
      ? `/quotes/${row.request.id}`
      : `/quotes/${row.request.id}/update/${row.id}/${history.location.search}`;

    isQuote
      ? history.push(quoteRoute)
      : history.push(`/reservations/${row.request.id}?tripId=${row.id}`, {
          backNavigationPath: `${history.location.pathname}${history.location.search}`,
        });
  };

  return (
    <IconButton
      onClick={() => navigateToTrip()}
      style={{ padding: 0, backgroundColor: "transparent" }}
    >
      <Typography
        variant="body2"
        paddingRight={1}
        color={moovsBlue}
        fontWeight="bold"
      >
        {tripNumber}
      </Typography>
      <OpenIcon size="xs" color={moovsBlue} />
    </IconButton>
  );
}

export default TripConfNumberColumn;
