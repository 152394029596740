import React from "react";

type ClosedIconProps = {
  color?: string;
  size?: "small";
};

const ClosedIcon = (props: ClosedIconProps) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 5.27273C15 4.9834 14.8865 4.70592 14.6846 4.50134C14.4826 4.29675 14.2087 4.18182 13.9231 4.18182H6.92308L5.63077 2.43636C5.53046 2.30088 5.40038 2.19091 5.25085 2.11517C5.10131 2.03943 4.93642 2 4.76923 2H2.07692C1.79131 2 1.51739 2.11493 1.31542 2.31952C1.11346 2.5241 1 2.80158 1 3.09091V12.9091C1 13.1984 1.11346 13.4759 1.31542 13.6805C1.51739 13.8851 1.79131 14 2.07692 14H13.9231C14.2087 14 14.4826 13.8851 14.6846 13.6805C14.8865 13.4759 15 13.1984 15 12.9091V5.27273Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClosedIcon;
