import React, { ChangeEvent } from "react";
import { useMutation } from "@apollo/client";

import { Box, Typography, CircularProgress } from "@mui/material";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import {
  EmptyVariables,
  ENABLE_AUTO_EMAILS_MUTATION,
  DISABLE_AUTO_EMAILS_MUTATION,
  UPDATE_OPERATOR_SETTINGS,
} from "../../../globals/graphql";
import LabeledSwitchInput from "./LabeledSwitchInput";
import { useOperator } from "../../../globals/hooks";

type NotificationsBlockProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function NotificationsBlock(props: NotificationsBlockProps) {
  const { setSaveIndicatorState } = props;

  // hooks
  const snackbar = useSnackbar();
  const operator = useOperator();
  const { automatedEmailsEnabled, tripCancellationEmailEnabled } = operator;

  // mutations
  const [enableAutomatedEmails] = useMutation(ENABLE_AUTO_EMAILS_MUTATION, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      snackbar.error("error enabling automated emails");
    },
  });

  const [disableAutomatedEmails] = useMutation(DISABLE_AUTO_EMAILS_MUTATION, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      snackbar.error("error disabling automated emails");
    },
  });

  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      snackbar.error("error updating operator settings");
    },
  });

  // event handlers
  const handleEnableNotifications = () => {
    enableAutomatedEmails({
      variables: EmptyVariables,
    });
  };

  const handleDisableNotifications = () => {
    disableAutomatedEmails({
      variables: EmptyVariables,
    });
  };

  const handleNotificationsChange = () => {
    setSaveIndicatorState("loading");

    automatedEmailsEnabled
      ? handleDisableNotifications()
      : handleEnableNotifications();
  };

  const handleTripCancellationEmailsChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setSaveIndicatorState("loading");

    updateOperatorSettings({
      variables: {
        input: {
          sendTripCancellationEmail: event.target.checked,
        },
      },
    });
  };

  return (
    <Box>
      {!operator ? (
        <Box
          width="100%"
          height="100px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={40} thickness={4} />
        </Box>
      ) : (
        <>
          <Box mb={2}>
            <Typography variant="h3">Notifications</Typography>
          </Box>
          <Box mb={2.5}>
            <LabeledSwitchInput
              name="automatedChargeEmails"
              checked={automatedEmailsEnabled}
              onChange={handleNotificationsChange}
              text="Send automated charge emails to booking contact"
              subText={`Automated outgoing emails when charge initiated to booking contact turned ${
                automatedEmailsEnabled ? "on" : "off"
              }.`}
            />
          </Box>
          <LabeledSwitchInput
            name="automatedTripCancellationEmails"
            checked={tripCancellationEmailEnabled}
            onChange={handleTripCancellationEmailsChange}
            text="Send automated cancellation emails to booking contact"
            subText={`Automated outgoing emails when trip cancelled to booking contact turned ${
              tripCancellationEmailEnabled ? "on" : "off"
            }.`}
          />
        </>
      )}
    </Box>
  );
}

export default NotificationsBlock;
