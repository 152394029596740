import React from "react";
import size from "lodash/size";

import { Typography, Box, CircularProgress } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

import EmptyTripsBlock from "components/contacts/EmptyTripsBlock";
import { useLoadTripsForContact } from "../../hooks/useLoadTripsForContact";
import { contactTripsGridColumns } from "./components";

type ContactTripsGridProps = {
  contactId: string;
  sx?: Record<string, unknown>;
};

function ContactTripsGrid(props: ContactTripsGridProps) {
  const { contactId, sx } = props;

  // hooks
  const { trips, tripsLoading, fetchMoreTrips, hasNextPage, cursor } =
    useLoadTripsForContact({ contactId });

  const handleRowScrollEnd = () => {
    if (hasNextPage) {
      fetchMoreTrips({
        variables: {
          cursor,
        },
      });
    }
  };

  return (
    <Box height="90%" sx={sx}>
      <Box my={3}>
        <Typography variant="h3" fontSize={"20px"} pl={2}>
          Trips
        </Typography>
      </Box>
      {tripsLoading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width="100%"
          height="100%"
        >
          <CircularProgress size={40} thickness={2} />
        </Box>
      ) : size(trips) ? (
        <DataGridPro
          disableColumnFilter
          disableColumnPinning
          disableSelectionOnClick
          disableChildrenSorting
          disableChildrenFiltering
          disableColumnMenu
          disableColumnReorder
          hideFooterRowCount
          disableMultipleColumnsSorting
          hideFooter
          aria-label="Contact Trip Table"
          paginationMode="server"
          sortingMode="server"
          loading={tripsLoading}
          onRowsScrollEnd={handleRowScrollEnd}
          columns={contactTripsGridColumns}
          rows={trips}
        />
      ) : (
        <EmptyTripsBlock />
      )}
    </Box>
  );
}
export default ContactTripsGrid;
