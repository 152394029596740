import moment from "moment";

type DaysOfTheWeekDatesTimesParserParams = {
  dateTime: Date;
  endDateTime?: Date;
};

export const daysOfTheWeekDatesTimesParser = (
  params: DaysOfTheWeekDatesTimesParserParams
) => {
  const { dateTime, endDateTime } = params;

  const startDayOfWeek = moment.utc(dateTime).format("dddd");
  const startDate = moment.utc(dateTime).format("ll");
  const startTime = moment.utc(dateTime).format("LT");

  const endDayOfWeek = endDateTime && moment.utc(endDateTime).format("dddd");
  const endDate = endDateTime && moment.utc(endDateTime).format("ll");
  const endTime = endDateTime && moment.utc(endDateTime).format("LT");

  const daysOfTheWeek =
    endDayOfWeek && startDayOfWeek !== endDayOfWeek
      ? `${startDayOfWeek} - ${endDayOfWeek}`
      : `${startDayOfWeek}`;

  const dates =
    endDate && startDate !== endDate
      ? `${startDate} - ${endDayOfWeek}`
      : `${startDate}`;

  const times =
    endTime && startTime !== endTime
      ? `${startTime} - ${endTime}`
      : `${startTime}`;

  return { daysOfTheWeek, dates, times };
};
