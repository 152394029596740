import React, { useState } from "react";

import { DispatchIcon } from "design-system/icons";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";
import { useAnalytics } from "globals/hooks";
import { RequestStage } from "types";

type UseViewDispatchLogClickProps = {
  analyticsName?: string;
  requestStage: string;
};

function useViewDispatchLogClick(
  props: UseViewDispatchLogClickProps
): EllipsisMenuOption & {
  open: boolean;
  onClose: () => void;
} {
  const { analyticsName, requestStage } = props;

  // hooks
  const { track } = useAnalytics();

  // state
  const [dispatchLogOpen, setDispatchLogOpen] = useState(false);

  // event handlers
  const handleDispatchLogOpen = () => {
    setDispatchLogOpen(true);
  };

  const handleDispatchLogClose = () => {
    setDispatchLogOpen(false);
  };

  const handleViewDispatchLogClick = () => {
    if (analyticsName) track(analyticsName);
    handleDispatchLogOpen();
  };

  const isUnconfirmedReservation = requestStage === RequestStage.Unconfirmed;

  return {
    text: "View Dispatch Log",
    icon: <DispatchIcon size="small" />,
    onClick: handleViewDispatchLogClick,
    disableOption: isUnconfirmedReservation,

    // dialog state
    open: dispatchLogOpen,
    onClose: handleDispatchLogClose,
  };
}

export { useViewDispatchLogClick };
