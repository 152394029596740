import moment, { Moment } from "moment";

import { dateRangeDefaultValues } from "../../DynamicPricingDialog/steps/dateRange/defaultValues";
import { DayOfWeek, DynamicPricingRule, Vehicle } from "types";
import { WeekdaysEnum } from "../../DynamicPricingDialog/utils/types";
import { useVehicles } from "globals/hooks";

export type EditDefaultVehicles = {
  id: string;
  value: string;
  disabled: boolean;
  isSelected: boolean;
}[];

export type EditDefaultDayOfWeek = {
  id: WeekdaysEnum;
  value: WeekdaysEnum;
  isSelected: boolean;
}[];

export type EditDynamicPricingDefaultValues = {
  ruleId: string;
  name: string;
  startDate: Date;
  endDate: Date;
  vehicles: EditDefaultVehicles;
  daysOfWeek: EditDefaultDayOfWeek;
  daysOfWeekIsDisabled: boolean;
  startTime: Moment;
  endTime: Moment;
  isIncrease: boolean;
  isPercent: boolean;
  amount: number;
  repeatAnnually: boolean;
  repeatRuleIsDisabled: boolean;
};

// set up default values for EditDynamicPricing react hook form
export function useEditDynamicPricingDefaultValues(
  rule: DynamicPricingRule
): EditDynamicPricingDefaultValues {
  const vehicleOptions = useDefaultVehicles(rule.vehicles);
  const daysOfWeekOptions = useDefaultWeekdays(rule.daysOfWeek);

  return {
    ruleId: rule.id,
    name: rule.name,
    startDate: rule.startDate,
    endDate: rule.endDate,
    vehicles: vehicleOptions,
    daysOfWeek: daysOfWeekOptions,
    daysOfWeekIsDisabled: null,
    startTime: moment(rule.startTime?.slice(0, -5), "HH:mm:ss"), // rule.startTime, coming from server => "10:45:00.000Z"
    endTime: moment(rule.endTime?.slice(0, -5), "HH:mm:ss"), // rule.endTime, coming from server => "10:45:00.000Z"
    isIncrease: rule.isIncrease,
    isPercent: rule.isPercent,
    amount: rule.amount,
    repeatAnnually: rule.repeatsAnnually,
    repeatRuleIsDisabled: null,
  };
}

// shape vehicles for RHFMultipleSelect options
const useDefaultVehicles = (vehiclesRule: Vehicle[]) => {
  const [vehicles] = useVehicles();

  // show all vehicles, including vehicles that weren't selected
  const options = vehicles?.map((vehicle) => {
    const option = {
      id: vehicle.id,
      value: vehicle.name,
      disabled: !vehicle.enableBaseRateAutomation,
      isSelected: false,
    };
    if (
      vehiclesRule?.find(
        (item) => item.id === vehicle.id && item.enableBaseRateAutomation
      )
    ) {
      option.isSelected = true;
    }
    return option;
  });

  options?.sort((a, b) => (a.disabled > b.disabled ? 1 : -1));
  return options;
};

// shape weekdays for RHFMultipleSelect options
const useDefaultWeekdays = (daysOfWeekRule: DayOfWeek[]) => {
  // weekdays selected from dynamic pricing rule creation
  const selectedWeekdays = {};
  daysOfWeekRule?.forEach((day) => (selectedWeekdays[day] = day));

  // show all weekdays, including days that weren't selected
  return dateRangeDefaultValues.daysOfWeek.map((weekday) => {
    const option = {
      id: weekday.id,
      value: weekday.value,
      isSelected: false,
    };
    if (selectedWeekdays[weekday.value]) {
      option.isSelected = true;
    }
    return option;
  });
};
