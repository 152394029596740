import React from "react";

import { Typography, Box } from "@mui/material";

import { useScreenSize } from "globals/hooks";
import { Trip } from "types";
import { getTripTotalAmount, getTripPromoAmount } from "../utils";
import { currency } from "globals/utils/helpers";

type PromoCodeResultsHeaderProps = {
  trips: Trip[];
  tripsLoading: boolean;
};

function PromoCodeResultsHeader(props: PromoCodeResultsHeaderProps) {
  const { trips, tripsLoading } = props;

  const totalTrips = trips?.length || 0;

  const totalAmount = currency(
    trips.reduce((totalAmt, trip) => {
      return totalAmt + getTripTotalAmount(trip);
    }, 0)
  );
  const totalPromoAmount = currency(
    trips.reduce((totalPromoAmt, trip) => {
      return totalPromoAmt + getTripPromoAmount(trip);
    }, 0)
  );

  // hooks
  const { isMobileView } = useScreenSize();

  if (tripsLoading) {
    return <></>;
  }

  return isMobileView ? (
    <Box display="flex" flexDirection="column" py={3} gap={3}>
      <Box display="flex" flexDirection="row">
        <PromoCodeResultsHeaderItem label="redemptions" amount={totalTrips} />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mr={6}
      >
        <PromoCodeResultsHeaderItem label="total amount" amount={totalAmount} />
        <PromoCodeResultsHeaderItem
          label="total promo amount"
          amount={totalPromoAmount}
        />
      </Box>
    </Box>
  ) : (
    <Box display="flex" flexDirection="row" py={3} gap={3}>
      <PromoCodeResultsHeaderItem label="redemptions" amount={totalTrips} />
      <PromoCodeResultsHeaderItem label="total amount" amount={totalAmount} />
      <PromoCodeResultsHeaderItem
        label="total promo amount"
        amount={totalPromoAmount}
      />
    </Box>
  );
}

export default PromoCodeResultsHeader;

function PromoCodeResultsHeaderItem({ label, amount }) {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="overline">{label}</Typography>
      <Typography fontSize={"20px"}>{amount}</Typography>
    </Box>
  );
}
