import React, { ChangeEventHandler } from "react";

import { Box, InputAdornment, TextField } from "@mui/material";

import { white } from "../../../design-system/colors";
import { NumberFormatFlight } from "../../../design-system/components/inputs/NumberFormat";

const styles = {
  flightTextField: {
    backgroundColor: white,
    "& .MuiInputBase-root": {
      lineHeight: 1.43,
    },
  },
};

type FlightNumberInputProps = {
  onFlightNumberInputChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  onFlightSearch?: () => void;
  isLoading: boolean;
  airlineIata: string;
  error: boolean;
  value?: string;
  label?: string;
};

function FlightNumberInput(props: FlightNumberInputProps) {
  const { onFlightNumberInputChange, airlineIata, error, value, label } = props;

  return (
    <>
      <Box flex="1" mb={1} display="flex" alignItems="center">
        <TextField
          label={label}
          variant="outlined"
          fullWidth
          onChange={onFlightNumberInputChange}
          sx={styles.flightTextField}
          error={error}
          InputProps={{
            inputComponent: NumberFormatFlight as any,
            ...(airlineIata && {
              startAdornment: (
                <InputAdornment position="start" sx={{ lineHeight: 1.43 }}>
                  {airlineIata}
                </InputAdornment>
              ),
            }),
          }}
          // value only if provided, and removes all non-numeric characters
          // this is so we can provide the full flight number
          {...(typeof value === "string" && {
            value: value.replace(/\D/g, ""),
          })}
        />
      </Box>
    </>
  );
}

export default FlightNumberInput;
