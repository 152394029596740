import {
  VehicleFeature,
  VehiclePhoto,
  VehicleChildSeat,
  PricelessBookingTarget,
} from "types";

export type CreateVehicle = {
  weekendHourlyCost: string;
  weekdayHourlyCost: string;
  weekendMinMinutes: string;
  weekdayMinMinutes: string;
  licensePlate: string;
  typeSlug: string;
  name: string;
  exteriorColor: string;
  vinNumber: string;
  capacity: string;
  available: boolean;
  description: string;
  photos: VehiclePhoto[];
  features: VehicleFeature[];
  cancellationPolicyId: string;
  insuranceId: string;
  comments: { bodyText: string }[];
  minimumTotalBaseRate: string;
  deadheadRatePerMile: string;
  tripRatePerMile: string;
  totalDeadheadDurationMinutes: string;
  enableBaseRateAutomation: boolean;
  enableBaseRateAutomationBookingTool: boolean;
  settings: {
    weekends: { name: string; value: string; index: number }[];
    forwardFacingSeat: VehicleChildSeat;
    rearFacingSeat: VehicleChildSeat;
    boosterSeat: VehicleChildSeat;
    conflictBlockReservation?: boolean;
    conflictBlockQuote?: boolean;
    pricelessBookingEnabled: boolean;
    pricelessBookingTarget: PricelessBookingTarget;
    pricelessBookingCompanyIds: string[];
    pricelessBookingContactIds: string[];
    // when duplicating vehicle, vehicleSettings graphql fields pricelessBookingCompanies and pricelessBookingContacts are needed for Create Vehicle Drawer
    pricelessBookingCompanies?: CompanyType[];
    pricelessBookingContacts?: ContactType[];
  };
};

export type VehicleRequiredInfoError = {
  name?: string;
  typeSlug?: string;
  capacity?: string;
};

export type VehicleChildSeatsError = {
  forwardFacingSeat?: {
    description?: string;
  };
  rearFacingSeat?: {
    description?: string;
  };
  boosterSeat?: {
    description?: string;
  };
};

export type CompanyType = { name: string; id: string };

export type ContactType = { firstName: string; lastName: string; id: string };

export type SelectionType = { name: string; id: string };

export enum FieldNameEnum {
  company = "company",
  contact = "contact",
}
