import React from "react";

type AttachIconProps = {
  color?: string;
  viewBox?: string;
  height?: string;
  width?: string;
  size?: "small";
};

const AttachIcon = (props: AttachIconProps) => {
  const { color, viewBox, height, width, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 6.90518L8.14561 12.9173C7.47048 13.6105 6.5548 14 5.60002 14C4.64523 14 3.72955 13.6105 3.05442 12.9173C2.37929 12.224 2 11.2838 2 10.3033C2 9.32293 2.37929 8.38267 3.05442 7.68942L7.86294 2.75236C8.08434 2.51698 8.34916 2.32924 8.64197 2.20008C8.93478 2.07092 9.24971 2.00294 9.56838 2.00009C9.88705 1.99725 10.2031 2.0596 10.498 2.18352C10.793 2.30743 11.0609 2.49042 11.2863 2.72181C11.5116 2.9532 11.6898 3.22836 11.8105 3.53123C11.9312 3.8341 11.9919 4.15861 11.9891 4.48584C11.9864 4.81306 11.9202 5.13644 11.7944 5.43711C11.6686 5.73778 11.4858 6.00972 11.2565 6.23705L6.44855 11.1768C6.22226 11.4013 5.91916 11.5256 5.60452 11.5228C5.28988 11.5201 4.98889 11.3905 4.76637 11.1621C4.54384 10.9337 4.41759 10.6246 4.41481 10.3016C4.41202 9.97848 4.53293 9.66721 4.75148 9.43477L9.56001 4.49553"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox={viewBox || "0 0 24 24"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 10.1753L12.2427 20.1955C11.1175 21.3509 9.59133 22 8.00003 22C6.40872 22 4.88259 21.3509 3.75737 20.1955C2.63214 19.04 2 17.4729 2 15.8389C2 14.2049 2.63214 12.6378 3.75737 11.4824L11.7716 3.25393C12.1406 2.86164 12.5819 2.54873 13.07 2.33346C13.558 2.1182 14.0828 2.00489 14.614 2.00016C15.1451 1.99542 15.6718 2.09934 16.1634 2.30586C16.655 2.51238 17.1016 2.81737 17.4771 3.20302C17.8527 3.58867 18.1497 4.04727 18.3508 4.55205C18.552 5.05683 18.6532 5.59769 18.6486 6.14306C18.6439 6.68843 18.5336 7.2274 18.324 7.72852C18.1143 8.22963 17.8096 8.68286 17.4276 9.06175L9.41424 17.2947C9.0371 17.6689 8.53193 17.876 8.00753 17.8714C7.48314 17.8668 6.98148 17.6509 6.61061 17.2702C6.23973 16.8895 6.02932 16.3744 6.02468 15.8359C6.02004 15.2975 6.22155 14.7787 6.58581 14.3913L14.6 6.15921"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AttachIcon;
