import React from "react";

import { ListIcon, CalendarIcon } from "../../design-system/icons";
import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";

type DispatchPageViewToggleProps = {
  value: "scheduler" | "list";
  onChange: (_: any, newType: "scheduler" | "list") => void;
};

function DispatchPageViewToggle(props: DispatchPageViewToggleProps) {
  const { value, onChange } = props;

  const toggleButtons = [
    {
      value: "list",
      ariaLabel: "list",
      icon: ListIcon,
    },
    {
      value: "scheduler",
      ariaLabel: "scheduler",
      icon: CalendarIcon,
    },
  ];

  return (
    <MoovsToggleButtonGroup
      onChange={onChange}
      options={toggleButtons}
      value={value}
      size="large"
    />
  );
}

export default DispatchPageViewToggle;
