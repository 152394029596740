import { useMutation } from "@apollo/client";

import { UPDATE_OPERATOR_REVIEW_SITE_SETTINGS } from "globals/graphql";
import { useAnalytics } from "globals/hooks";
import { useSnackbar } from "globals/hooks/useSnackbar";
import { useState } from "react";

type UseSaveReviewSiteProps = {
  onCompleteSave: () => void;
  selectedPlatform: string;
  reviewSiteId: string;
};

const useSaveReviewSite = (props: UseSaveReviewSiteProps) => {
  const { onCompleteSave, selectedPlatform, reviewSiteId } = props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // state
  const [analyticsName, setAnalyticsName] = useState("");

  // mutations
  const [updateOperatorReviewSiteSettings, { loading }] = useMutation(
    UPDATE_OPERATOR_REVIEW_SITE_SETTINGS,
    {
      onCompleted() {
        if (analyticsName) track(analyticsName);
        snackbar.success("Successfully added customer 5-star review URL!");
        onCompleteSave();
      },
      onError(error) {
        snackbar.error("Error saving review platform");
      },
    }
  );

  // event handlers
  const handleSaveReviewSite = (analyticsName?: string) => {
    setAnalyticsName(analyticsName);

    updateOperatorReviewSiteSettings({
      variables: {
        input: {
          reviewSite: selectedPlatform.toUpperCase(),
          reviewSiteId,
        },
      },
    });
  };

  return { handleSaveReviewSite, loading };
};

export default useSaveReviewSite;
