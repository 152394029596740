import React, { useState } from "react";

import {
  Box,
  Typography,
  Tooltip,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";

import { LogoutIcon } from "../../../icons";
import MoovsAvatar from "../../MoovsAvatar";
import { granite, grayMedium, grayLight } from "../../../colors";
import {
  useAuth,
  useCurrentUser,
  useScreenSize,
} from "../../../../globals/hooks";

type LogoutButtonProps = { onDrawerToggle: () => void };

function LogoutButton(props: LogoutButtonProps) {
  const { onDrawerToggle } = props;

  // hooks
  const { onLogout } = useAuth();
  const currentUser = useCurrentUser();
  const { isMobileView } = useScreenSize();

  // state
  const [anchorEl, setAnchorEl] = useState(null);

  const firstName = currentUser?.firstName || "";
  const lastName = currentUser?.lastName || "";

  // event handlers
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    if (isMobileView) {
      onDrawerToggle();
    } else {
      handleClose();
    }

    onLogout();
  };

  const navButtonStyles = {
    root: {
      display: "flex",
      cursor: "pointer",
      flex: 1,
      margin: "0 12px",
      alignItems: "center",
      alignContent: "center",
      padding: "12px 8px",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: grayLight,
      },
    },
    settingsRoot: {
      margin: "0 0px",
      "&:hover": {
        backgroundColor: grayMedium,
      },
    },
    navText: {
      color: granite,
      fontWeight: 500,
    },
    disabled: {
      color: `${grayMedium} !important`,
    },
    collapsedMenuItem: {
      margin: "0 0px",
    },
  };

  if (isMobileView) {
    return (
      <Box sx={navButtonStyles.root} onClick={handleLogout}>
        <Box m={-1}>
          <MoovsAvatar placeholder={[firstName, lastName]} size="small" />
        </Box>
        <Box
          mx={3}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Typography
            sx={navButtonStyles.navText}
          >{`${firstName} ${lastName}`}</Typography>
          <Typography
            color="primary"
            variant="overline"
            style={{ lineHeight: 1.2 }}
          >
            Log Out
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Button
        disableFocusRipple
        disableRipple
        onClick={handleClick}
        style={{ backgroundColor: "transparent" }}
      >
        <Tooltip title={`${firstName} ${lastName}`} placement="right">
          <Box mb={2.5}>
            <MoovsAvatar placeholder={[firstName, lastName]} size="medium" />
          </Box>
        </Tooltip>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleLogout}>
          <Box display="flex" flex="1">
            <LogoutIcon viewBox="0 0 20 20" /> <span>Logout</span>
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
}

export default LogoutButton;
