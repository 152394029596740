import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { LuggageFields } from "components/requests/luggage/types";
import { UPDATE_ROUTE_MUTATION } from "globals/graphql";
import { useSnackbar } from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type UseUpdateLuggageAmountProps = {
  setSaveIndicatorState: (
    saveState: "loading" | "default" | "saved" | "error"
  ) => void;
};

function useUpdateLuggageAmount(props: UseUpdateLuggageAmountProps) {
  const { setSaveIndicatorState } = props;

  // hooks
  const snackbar = useSnackbar();

  const [updateRoute] = useMutation(UPDATE_ROUTE_MUTATION, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");

      const errorMessage =
        getErrorMessage(error) || "Error updating luggage amount.";

      snackbar.error(errorMessage);
    },
  });

  const updateLuggageAmount = useCallback(
    async (routeId: string, luggageAmount: LuggageFields) => {
      setSaveIndicatorState("loading");
      await updateRoute({
        variables: {
          input: {
            id: routeId,
            ...luggageAmount,
          },
        },
      });
    },
    [updateRoute, setSaveIndicatorState]
  );

  return { updateLuggageAmount };
}

export default useUpdateLuggageAmount;
