import React, { useState } from "react";
import moment from "moment-timezone";
import startCase from "lodash/startCase";

import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";

import { alabaster, granite, grayLight } from "../../design-system/colors";
import { CompanyIcon, CreditCardIcon } from "../../design-system/icons";
import MoovsAvatar from "../../design-system/components/MoovsAvatar";
import { driverStatusEnum } from "../../globals/utils/enumMaps";
import { DriverStatus, FarmAffiliateVariantEnum } from "../../types";
import MoovsIconPanel from "../MoovsIconPanel";
import { useScreenSize } from "../../globals/hooks";
import FarmAffiliateIcon from "components/contacts/farmAffiliates/FarmAffiliateIcon";
import size from "lodash/size";

const today = moment();

type ProfileInfoCardProps = {
  headerText: string;
  firstName?: string;
  lastName?: string;
  operatorName?: string;
  email?: string;
  phone?: string;
  profilePhoto?: string;
  preferences?: string;
  company?: string;
  paymentMethods?: any[];
  actionButtons?: {
    icon: any;
    eventHandler?: () => void;
    menuOptions?: {
      eventHandler?: () => void;
      disabled?: boolean;
      label: string;
      icon?: any;
      divider?: boolean;
      header?: string;
      navLink?: string;
    }[];
  }[];
  infoIcons?: {
    icon: React.ReactNode;
    label: string;
  }[];
  driverStatus?: DriverStatus;
  farmAffiliateVariant?: FarmAffiliateVariantEnum;
  sx?: Record<string, unknown>;
  headerColor?: string;
  headerIcon?: JSX.Element;
  avatarBackgroundColor?: string;
  avatarColor?: string;
};

function ProfileInfoCard(props: ProfileInfoCardProps) {
  const {
    headerText,
    firstName,
    lastName,
    operatorName,
    email,
    phone,
    profilePhoto,
    preferences,
    company,
    actionButtons = [],
    infoIcons = [],
    driverStatus,
    paymentMethods = [],
    farmAffiliateVariant,
    sx,
    headerColor,
    headerIcon,
    avatarBackgroundColor,
    avatarColor,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // state
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  // event handlers
  const handleActionButtonClick = (event, actionButton) => {
    const { menuOptions, eventHandler } = actionButton;
    // if menuOptions exist, open the menu
    if (menuOptions) {
      setMenuAnchorEl(event.currentTarget);
    } else {
      // run actionButton's event handler
      eventHandler();
    }
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuOptionClick = (option) => {
    option.eventHandler();
    handleMenuClose();
  };

  const additionalInfoItems = [
    ...(paymentMethods
      ? paymentMethods
          .map((method) => {
            const {
              card: { brand, expMonth, expYear, last4 },
              cardNote,
              isPrimary,
            } = method;

            const isExpired = moment(
              `${expYear}-${expMonth}`,
              "YYYY-M"
            ).isBefore(today);

            return {
              icon: (
                <CreditCardIcon
                  brand={brand}
                  showPrimary={isPrimary}
                  showError={isExpired}
                />
              ),
              renderItem: true,
              mainText: `${startCase(brand)} **** ${last4} ${expMonth}/${expYear
                .toString()
                .slice(2)}`,
              subText: isExpired ? "(Expired)" : isPrimary ? "(Primary)" : null,
              additionalText: cardNote,
              isCreditCard: true,
            };
          })
          // top three cards are ordered by primary followed by most recent
          // this is handled by the paymentMethodsByContactId query on server
          .slice(0, 3)
      : []),
    ...(company
      ? [
          {
            icon: <CompanyIcon />,
            renderItem: true,
            mainText: `Company`,
            subText: company,
          },
        ]
      : []),
  ];

  const moveIconsToNewLine = isMobileView && additionalInfoItems.length > 0;
  const MoovsAvatarPlaceholder = operatorName?.split(" ").slice(0, 2) || [
    firstName,
    lastName,
  ];

  const cardTitleStyles = {
    lineHeight: "18px",
    ...(headerIcon && { pl: 1 }),
  } as const;

  const profileHasDetailedInformation =
    size([operatorName, driverStatus, phone]) === 1;

  return (
    <Box
      borderRadius="4px"
      display="flex"
      flex="1"
      alignItems="space-between"
      sx={{
        ...sx,
        position: "relative",
        backgroundColor: alabaster,
        minHeight: 154,
      }}
      {...(isMobileView
        ? { p: 2 }
        : {
            px: 2,
            pt: 1,
            pb: 1.5,
          })}
      flexDirection="column"
    >
      {moveIconsToNewLine && (
        <Box display="flex" alignItems="center">
          {headerIcon}
          <Typography
            variant="overline"
            sx={cardTitleStyles}
            color={headerColor}
          >
            {headerText}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between">
        {!moveIconsToNewLine && (
          <Box mt={1.5} display="flex" alignItems="center">
            {headerIcon}
            <Typography
              variant="overline"
              sx={cardTitleStyles}
              color={headerColor}
            >
              {headerText}
            </Typography>
          </Box>
        )}
        <Box flex={1} display="flex" justifyContent="flex-end">
          {!!additionalInfoItems.length && (
            <Box {...(!isMobileView && { mt: "2px" })}>
              <MoovsIconPanel
                bottomDrawerHeader="Contact Info"
                items={additionalInfoItems}
              />
            </Box>
          )}

          {!!additionalInfoItems.length &&
            !!infoIcons.length &&
            !!actionButtons.length && <Divider orientation="vertical" />}
          {infoIcons.map(({ icon, label }, i) => (
            <Box key={i} ml={1}>
              <Tooltip title={label}>
                <IconButton size="large">{icon}</IconButton>
              </Tooltip>
            </Box>
          ))}
          {actionButtons.map((actionButton, i) => (
            <Box key={i} ml={1}>
              <IconButton
                onClick={(event) =>
                  handleActionButtonClick(event, actionButton)
                }
                size="large"
              >
                {actionButton.icon}
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                keepMounted
                open={!!menuAnchorEl}
                onClose={handleMenuClose}
              >
                {actionButton.menuOptions?.map((option, i) => {
                  if (option.divider)
                    return (
                      <Box mx={0.5} mb={1} style={{ width: 191 }} key={i}>
                        <Divider />
                      </Box>
                    );
                  if (option.header)
                    return (
                      <Box mx={2} key={i}>
                        <Typography variant="h6" style={{ color: granite }}>
                          {option.header}
                        </Typography>
                      </Box>
                    );
                  else
                    return (
                      <Box my={0.5} key={i}>
                        <MenuItem
                          dense
                          disabled={option.disabled}
                          onClick={() => {
                            handleMenuOptionClick(option);
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                          >
                            {option.icon && (
                              <Box display="flex" mr={1}>
                                {option.icon}
                              </Box>
                            )}
                            <Box display="flex">
                              <Typography
                                style={{ fontWeight: 500, color: granite }}
                                variant="body2"
                              >
                                {option.label}
                              </Typography>
                            </Box>
                          </Box>
                        </MenuItem>
                      </Box>
                    );
                })}
              </Menu>
            </Box>
          ))}
        </Box>
      </Box>
      <Box display="flex" flex="1" alignItems="center" mt={1}>
        <Box>
          <MoovsAvatar
            placeholder={MoovsAvatarPlaceholder}
            size="large"
            src={profilePhoto}
            backgroundColor={avatarBackgroundColor}
            color={avatarColor}
          />
        </Box>
        <Box
          display="flex"
          flex="1"
          height="100%"
          flexDirection="column"
          justifyContent={
            profileHasDetailedInformation ? "space-between" : "center"
          }
          ml={2}
        >
          <Box pb={0.5} display="flex" alignItems="center">
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", lineHeight: "18px" }}
            >
              {`${operatorName || firstName} ${lastName || ""}`}
            </Typography>
            {farmAffiliateVariant && (
              <Box ml={1}>
                <FarmAffiliateIcon variant={farmAffiliateVariant} />
              </Box>
            )}
            <Box ml={1.5}>
              {driverStatus && driverStatusEnum[driverStatus.name]?.icon}
            </Box>
          </Box>
          <Box pb={0.5}>
            <Typography variant="body2">{email}</Typography>
          </Box>
          <Box pb={0.5}>
            {!!phone && <Typography variant="body2">{phone}</Typography>}
          </Box>
        </Box>
      </Box>
      {preferences && (
        <Box mt={2} bgcolor={grayLight} p={1.5}>
          <Typography variant="body2">{preferences}</Typography>
        </Box>
      )}
    </Box>
  );
}

export default ProfileInfoCard;
