import React from "react";

import { DollarIcon, PercentageIcon } from "design-system/icons";
import { DollarPercentVariant } from "components/pricing/utils";
import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";

const { Dollar, Percent } = DollarPercentVariant;

type DollarPercentToggleProps = {
  dollarPercentVariant: DollarPercentVariant;
  onToggle: (variant: DollarPercentVariant) => void;
  fieldName?: string;
};

function DollarPercentToggle(props: DollarPercentToggleProps) {
  const { onToggle, dollarPercentVariant, fieldName } = props;

  // event handler
  const handleToggle = (_: any, variant: DollarPercentVariant) => {
    if (variant) {
      onToggle(variant);
    }
  };

  const toggleButtons = [
    {
      value: Dollar,
      ariaLabel: Dollar,
      icon: DollarIcon,
      dataTestId: `toggle-dollar-${fieldName}`,
    },
    {
      value: Percent,
      ariaLabel: Percent,
      icon: PercentageIcon,
      dataTestId: `toggle-percent-${fieldName}`,
    },
  ];

  return (
    <MoovsToggleButtonGroup
      onChange={handleToggle}
      options={toggleButtons}
      value={dollarPercentVariant}
    />
  );
}
export default DollarPercentToggle;
