import React from "react";

const BadgeReviewIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.5 23.668L38.7811 29.1682L32.6874 30.3557L30.2499 37.4807L25 30.3557"
        stroke="#0C893F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.28125 23.668L2.00014 29.1682L8.09389 30.3557L10.5314 37.4807L15.7812 30.3557"
        stroke="#0C893F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.2"
        d="M21.407 2.34841L23.3653 3.75547C24.0436 4.24284 24.8575 4.50565 25.6927 4.50702L28.258 4.51124C28.5357 4.49785 28.8098 4.57738 29.0347 4.73664C29.2596 4.89591 29.4219 5.12533 29.4946 5.38683L30.0916 7.53422C30.3559 8.48508 30.9624 9.30465 31.7945 9.83535L33.7294 11.0694C33.9639 11.2181 34.1387 11.441 34.2245 11.7004C34.3103 11.9599 34.3019 12.2403 34.2006 12.4944L33.3994 14.5413C33.0327 15.4781 33.0325 16.5186 33.3987 17.4556L34.2006 19.507C34.3001 19.7601 34.3076 20.0388 34.2218 20.2966C34.1361 20.5545 33.9623 20.7759 33.7294 20.9241L31.7947 22.158C30.9625 22.6888 30.3559 23.5085 30.0917 24.4596L29.4946 26.6082C29.4219 26.8697 29.2596 27.0992 29.0347 27.2584C28.8098 27.4177 28.5357 27.4972 28.258 27.4838L25.8986 27.3693C24.9374 27.3227 23.9917 27.6241 23.2348 28.2182L21.407 29.653C21.19 29.8229 20.92 29.9155 20.6416 29.9155C20.3633 29.9155 20.0932 29.8229 19.8762 29.653L18.0493 28.216C17.2919 27.6203 16.3449 27.318 15.3825 27.3647L13.0252 27.4791C12.7475 27.4925 12.4735 27.4129 12.2485 27.2537C12.0236 27.0944 11.8613 26.865 11.7886 26.6035L11.1916 24.4548C10.9273 23.5038 10.3207 22.684 9.48853 22.1532L7.55387 20.9193C7.32095 20.7712 7.14715 20.5497 7.0614 20.2919C6.97566 20.034 6.98315 19.7554 7.08262 19.5022L7.88396 17.455C8.25055 16.5184 8.25087 15.4781 7.88485 14.5413L7.08262 12.4881C6.98136 12.2339 6.97292 11.9536 7.05873 11.6941C7.14455 11.4346 7.31938 11.2118 7.55387 11.0631L9.48875 9.82901C10.3208 9.29832 10.9273 8.47874 11.1917 7.52789L11.7886 5.38049C11.8613 5.11899 12.0236 4.88957 12.2485 4.73031C12.4735 4.57105 12.7475 4.49151 13.0252 4.50491L15.384 4.62327C16.3455 4.67152 17.2922 4.37137 18.0502 3.77793L19.8762 2.34841C20.0932 2.17854 20.3633 2.08594 20.6416 2.08594C20.92 2.08594 21.19 2.17854 21.407 2.34841Z"
        fill="url(#paint0_linear_5568_92671)"
      />
      <path
        d="M21.2644 2.11794L24.2658 4.46761L28.1154 4.28077C28.3931 4.26738 28.6672 4.34691 28.8921 4.50618C29.1171 4.66544 29.2793 4.89486 29.352 5.15636L30.3595 8.78061L33.5868 10.8389C33.8213 10.9877 33.9961 11.2105 34.0819 11.47C34.1677 11.7294 34.1593 12.0098 34.058 12.2639L32.6865 15.7679L34.058 19.2765C34.1575 19.5296 34.165 19.8083 34.0793 20.0662C33.9935 20.324 33.8197 20.5454 33.5868 20.6936L30.3595 22.7519L29.352 26.3778C29.2793 26.6393 29.1171 26.8687 28.8921 27.028C28.6672 27.1872 28.3931 27.2668 28.1154 27.2534L24.2658 27.0665L21.2644 29.4225C21.0474 29.5924 20.7774 29.685 20.499 29.685C20.2207 29.685 19.9507 29.5924 19.7337 29.4225L16.7323 27.0618L12.8827 27.2486C12.605 27.262 12.3309 27.1825 12.106 27.0232C11.881 26.8639 11.7187 26.6345 11.646 26.373L10.6385 22.7472L7.41129 20.6889C7.17837 20.5407 7.00457 20.3192 6.91883 20.0614C6.83308 19.8036 6.84057 19.5249 6.94004 19.2718L8.31154 15.7679L6.94004 12.2576C6.83878 12.0035 6.83034 11.7231 6.91616 11.4636C7.00197 11.2041 7.1768 10.9813 7.41129 10.8326L10.6385 8.77427L11.646 5.15002C11.7187 4.88852 11.881 4.65911 12.106 4.49984C12.3309 4.34058 12.605 4.26105 12.8827 4.27444L16.7323 4.46761L19.7337 2.11794C19.9507 1.94807 20.2207 1.85547 20.499 1.85547C20.7774 1.85547 21.0474 1.94807 21.2644 2.11794V2.11794Z"
        stroke="#0C893F"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5002 21.668L14.9607 16.0804C14.4738 15.61 14.1525 15.0033 14.0423 14.3463C13.9321 13.6894 14.0386 13.0156 14.3468 12.4205C14.579 11.9712 14.9182 11.5817 15.3366 11.2842C15.755 10.9866 16.2406 10.7894 16.7532 10.7089C17.2659 10.6284 17.791 10.6669 18.2852 10.8212C18.7795 10.9755 19.2287 11.2411 19.596 11.5963L20.5002 12.4707L21.4045 11.5963C21.7717 11.2411 22.221 10.9755 22.7153 10.8212C23.2095 10.6669 23.7346 10.6284 24.2473 10.7089C24.7599 10.7894 25.2454 10.9866 25.6638 11.2842C26.0822 11.5817 26.4215 11.9712 26.6537 12.4205C26.9615 13.0154 27.0679 13.6889 26.9577 14.3456C26.8475 15.0022 26.5264 15.6087 26.0398 16.079L20.5002 21.668Z"
        fill="white"
        stroke="#0C893F"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5568_92671"
          x1="28.2468"
          y1="27.8402"
          x2="28.2468"
          y2="1.87726"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#28B668" />
          <stop offset="1" stop-color="#87AAFF" stop-opacity="0.62" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BadgeReviewIcon;
