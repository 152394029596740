import React from "react";

type SyncIconProps = {
  color?: string;
  size?: "small";
};

const SyncIcon = (props: SyncIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.709 6.41652L12.2099 3.69037L15 4.1798M12.3591 3.84116C13.3365 4.83616 13.9335 6.12971 14.0495 7.50423C14.1656 8.87875 13.7936 10.2504 12.9962 11.3884C12.1988 12.5265 11.0246 13.3615 9.67117 13.753C8.3177 14.1446 6.86752 14.0688 5.56454 13.5383M4.58625 10.1066L3.68897 12.7339L1 11.8572M3.49897 12.1541C2.52362 11.1586 1.92854 9.86549 1.81364 8.49196C1.69875 7.11842 2.07104 5.74808 2.868 4.61104C3.66496 3.47401 4.83806 2.63953 6.19031 2.24772C7.54256 1.85591 8.9916 1.93065 10.2941 2.45937"
          stroke={color || "#195FE9"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 9.42919L18.7952 5L23.2244 5.79518M19.032 5.245C20.5837 6.86157 21.5313 8.9632 21.7155 11.1964C21.8997 13.4296 21.3093 15.6581 20.0434 17.507C18.7776 19.356 16.9137 20.7127 14.7651 21.3488C12.6165 21.985 10.3144 21.8618 8.246 21M6.69301 15.4244L5.26862 19.693L1 18.2686M4.967 18.751C3.41868 17.1336 2.47401 15.0327 2.29162 12.8011C2.10923 10.5696 2.70022 8.34316 3.96537 6.49582C5.23051 4.64848 7.09275 3.2927 9.23938 2.65613C11.386 2.01957 13.6863 2.14099 15.754 3"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SyncIcon;
