import React from "react";
import moment from "moment";
import first from "lodash/first";
import capitalize from "lodash/capitalize";
import map from "lodash/map";
import size from "lodash/size";

import { DayOfWeek } from "types";
import DynamicPricingChip from "../DynamicPricingChip";
import { IconNames } from "../../utils/types";
import { needTooltip } from "../../utils/needToolTip";
import DynamicPricingChipTooltip from "../DynamicPricingChipTooltip";

type RepeatRuleChipProps = {
  repeatsAnnually: boolean;
  startDate: Date;
  endDate: Date;
  daysOfWeek: DayOfWeek[];
  onSetWeekdaysDrawerOpen: () => void;
};

type GetDateRangeRepeatRuleChipProps = {
  formattedStartDate: string;
  formattedEndDate: string;
  daysOfWeek: DayOfWeek[];
  onSetWeekdaysDrawerOpen: () => void;
};

const RepeatRuleChip = (props: RepeatRuleChipProps) => {
  const {
    repeatsAnnually,
    startDate,
    endDate,
    daysOfWeek,
    onSetWeekdaysDrawerOpen,
  } = props;

  if (!repeatsAnnually) {
    if (!startDate) return null;
    return (
      <DynamicPricingChip label="Does Not Repeat" iconName={IconNames.repeat} />
    );
  }

  let chip = null;

  if (startDate) {
    const formattedStartDate = moment.utc(startDate).format("M/DD");
    if (endDate) {
      // start date and end date + weekday selection
      const formattedEndDate = moment.utc(endDate).format("M/DD");

      chip = getDateRangeRepeatRuleChip({
        daysOfWeek,
        formattedStartDate,
        formattedEndDate,
        onSetWeekdaysDrawerOpen,
      });
    } else {
      // only start date + no weekday selections
      const repeatText = `Repeats Annually on ${formattedStartDate}`;
      chip = (
        <DynamicPricingChip label={repeatText} iconName={IconNames.repeat} />
      );
    }
  }

  return chip;
};

const getDateRangeRepeatRuleChip = (props: GetDateRangeRepeatRuleChipProps) => {
  const {
    formattedStartDate,
    formattedEndDate,
    daysOfWeek,
    onSetWeekdaysDrawerOpen,
  } = props;

  // everyday
  if (!size(daysOfWeek) || size(daysOfWeek) === 7) {
    const repeatText = `Repeats Annually ${formattedStartDate}-${formattedEndDate}`;
    return (
      <DynamicPricingChip label={repeatText} iconName={IconNames.repeat} />
    );
  }

  // not everyday
  const weekdays = map(daysOfWeek, capitalize);

  if (needTooltip(weekdays)) {
    // if 2 weekdays or more are selected, tooltip is required
    const repeatText = `Repeats Annually ${formattedStartDate}-${formattedEndDate} on ${first(
      weekdays
    )}`;
    return (
      <DynamicPricingChipTooltip
        onDrawerOpen={onSetWeekdaysDrawerOpen}
        list={weekdays}
        label={repeatText}
        iconName={IconNames.repeat}
      />
    );
  } else {
    // tooltip not needed
    const joinedWeekdays = weekdays.join(", ");
    const repeatText = `Repeats Annually from ${formattedStartDate} to ${formattedEndDate} on ${joinedWeekdays}`;
    return (
      <DynamicPricingChip label={repeatText} iconName={IconNames.repeat} />
    );
  }
};

export default RepeatRuleChip;
