import React from "react";

import moment from "moment-timezone";

import { Term } from "../../../types";
import { Card, CardActionArea, Box, Typography } from "@mui/material";
import { black } from "../../../design-system/colors";

const styles = {
  termName: {
    fontWeight: "bold",
    color: black,
  },
};

type TermsListItemMobileProps = {
  term: Term;
  onClick: (termId) => void;
};

function TermsListItemMobile(props: TermsListItemMobileProps) {
  const { term, onClick } = props;

  return (
    <Card variant="outlined">
      <CardActionArea disableRipple onClick={() => onClick(term.id)}>
        <Box display="flex" flex="1" flexDirection="column" p={2}>
          <Box mb={1}>
            <Typography variant="subtitle1" sx={styles.termName}>
              {term.name}
            </Typography>
          </Box>
          <Box display="flex" flex="1" flexDirection="column">
            <Typography variant="overline">CREATED AT</Typography>
            <Typography variant="caption">
              {moment(term.createdAt).format("LLL")}
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default TermsListItemMobile;
