import React, { useState } from "react";

import { Box, Button, Typography } from "@mui/material";

import { grayDark, moovsBlue } from "../../../../design-system/colors";
import { OpenIcon } from "../../../../design-system/icons";
import MoovsDialog from "../../../MoovsDialog";
import { currency } from "../../../../globals/utils/helpers";
import { useScreenSize } from "../../../../globals/hooks";

type TemplateDialogFooterProps = {
  activeTemplateIndex: number;
  previewUrl: string;
  templatesLength: number;
  onCreateWebsiteClick: () => void;
  templateCost: number;
};

function TemplateDialogFooter(props: TemplateDialogFooterProps) {
  const {
    activeTemplateIndex,
    previewUrl,
    templatesLength,
    onCreateWebsiteClick,
    templateCost,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // state
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  // event handlers
  const handleSelectTemplateClick = () => setConfirmDialogOpen(true);

  const handlePreviewInBrowserClick = () => {
    window.open(previewUrl, "_blank");
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection={isMobileView ? "column" : "row"}
        justifyContent="space-between"
        alignItems={isMobileView ? "flex-end" : "center"}
        zIndex={1}
        mt={3}
        {...(isMobileView && { m: 3 })}
        style={{ cursor: "default" }}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          {/* template counter */}
          <Typography variant="body2">Template</Typography>
          <Box mx={2} display="flex" flexDirection="row">
            <Typography variant="subtitle2">
              {activeTemplateIndex + 1}
            </Typography>
            <Box mx={0.5}>
              <Typography variant="body2" style={{ color: grayDark }}>
                /
              </Typography>
            </Box>
            <Typography variant="body2" style={{ color: grayDark }}>
              {templatesLength}
            </Typography>
          </Box>

          {/* preview link */}
          <Button
            fullWidth
            color="primary"
            endIcon={<OpenIcon size="small" color={moovsBlue} />}
            onClick={handlePreviewInBrowserClick}
            style={{ whiteSpace: "nowrap" }}
          >
            Preview In Browser
          </Button>
        </Box>

        {/* Paid template price */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {!!templateCost && (
            <Box mr={2} mt={1}>
              <Typography variant="subtitle1">{`${currency(
                templateCost
              )}`}</Typography>
            </Box>
          )}

          {/* select template button */}
          <Box
            display="flex"
            justifyContent="flex-end"
            mt={1}
            {...(isMobileView && { width: 200 })}
          >
            <Button
              color="primary"
              onClick={handleSelectTemplateClick}
              variant="contained"
              style={{ width: "100%" }}
            >
              Select Template
            </Button>
          </Box>
        </Box>
      </Box>
      <MoovsDialog
        dialogTitle="Use this template?"
        size="xs"
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onAccept={onCreateWebsiteClick}
        closeButtonText="Cancel"
        hideTopBorder
      >
        <Typography variant="body2" style={{ marginBottom: "24px" }}>
          Are you sure you want to use this template? You won’t be able to
          change it after your website is created.
        </Typography>
      </MoovsDialog>
    </>
  );
}

export default TemplateDialogFooter;
