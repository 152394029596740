import React from "react";

type SalesAutomationIconProps = {
  color?: string;
  size?: "small";
};

const SalesAutomationIcon = (props: SalesAutomationIconProps) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.33331 10.6668H3.99998L1.33331 13.3335V2.66683C1.33331 2.31321 1.47379 1.97407 1.72384 1.72402C1.97389 1.47397 2.31302 1.3335 2.66665 1.3335H12C12.3536 1.3335 12.6927 1.47397 12.9428 1.72402C13.1928 1.97407 13.3333 2.31321 13.3333 2.66683V5.00016M3.99998 4.66683H10.6666M3.99998 7.3335H9.33331M12.8008 7.3335L8.74954 11.077C8.70995 11.1136 8.683 11.1603 8.67208 11.211C8.66116 11.2618 8.66678 11.3144 8.68821 11.3623C8.70964 11.4101 8.74593 11.451 8.79249 11.4798C8.83906 11.5085 8.89381 11.5239 8.94983 11.524H10.5333V14.6668L14.5838 10.9233C14.6233 10.8867 14.6503 10.8401 14.6612 10.7893C14.6721 10.7385 14.6665 10.6859 14.6451 10.6381C14.6237 10.5902 14.5874 10.5493 14.5408 10.5206C14.4942 10.4918 14.4395 10.4764 14.3835 10.4764H12.8008V7.3335Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SalesAutomationIcon;
