import React from "react";
import startCase from "lodash/startCase";

import { Box, Card, CardActionArea, Typography } from "@mui/material";

import { CancellationPolicy } from "../../../types";

const styles = {
  cancelationItemText: {
    fontSize: "12px",
  },
};

type CancellationPoliciesListItemDesktopProps = {
  cancellationPolicy: CancellationPolicy;
  onClick: (cancellationPolicyId: string) => void;
};

function CancellationPoliciesListItemDesktop(
  props: CancellationPoliciesListItemDesktopProps
) {
  const { cancellationPolicy, onClick } = props;

  const { id, variant, refund100, refund50, refund25 } = cancellationPolicy;

  const refund100Amount =
    refund100.periodValue > 1
      ? `${refund100.periodValue} ${refund100.periodName}S`
      : `${refund100.periodValue} ${refund100.periodName}`;
  const refund50Amount =
    refund50.periodValue > 1
      ? `${refund50.periodValue} ${refund50.periodName}S`
      : `${refund50.periodValue} ${refund50.periodName}`;
  const refund25Amount =
    refund25.periodValue > 1
      ? `${refund25.periodValue} ${refund25.periodName}S`
      : `${refund25.periodValue} ${refund25.periodName}`;

  const handleCardClick = () => onClick(id);

  return (
    <Card variant="outlined">
      <CardActionArea disableRipple onClick={handleCardClick}>
        <Box
          display="flex"
          flexDirection="row"
          flex="1"
          py={1.5}
          px={3}
          height="76px"
          alignItems="center"
        >
          {/* Policy */}
          <Box display="flex" flex="2">
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {startCase(variant)}
            </Typography>
          </Box>

          {/* Full Refund */}
          <Box display="flex" flex="1">
            <Typography sx={styles.cancelationItemText}>
              {refund100.active ? refund100Amount : "-"}
            </Typography>
          </Box>

          {/* 50% Refund */}
          <Box display="flex" flex="1">
            <Typography sx={styles.cancelationItemText}>
              {refund50.active ? refund50Amount : "-"}
            </Typography>
          </Box>

          {/* 25% Refund */}
          <Box display="flex" flex="1">
            <Typography sx={styles.cancelationItemText}>
              {refund25.active ? refund25Amount : "-"}
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default CancellationPoliciesListItemDesktop;
