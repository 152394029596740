import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Box } from "@mui/material";

import SquareNavButton from "design-system/components/layout/SideNavButtons/SquareNavButton";
import { ComposeEmailIcon } from "design-system/icons";
import { granite, grayMedium, moovsBlueHover } from "design-system/colors";
import { useAnalytics, useOperator } from "globals/hooks";
import SendEmailDialog from "components/SendEmailDialog";
import { EmailModeEnum } from "types";

function MoovsMarketNavButton() {
  // hooks
  const { track } = useAnalytics();
  const operator = useOperator();

  // state
  const [sendEmailDialogOpen, setSendEmailDialogOpen] = useState(false);

  // event handler
  const handleComposeEmailClick = () => {
    track("composeEmail_created");

    // NOTE: TRIGGERS INFINITE LOOP, will address in DOOM-6573
    setSendEmailDialogOpen(true);
  };

  const disabled = !operator?.customDomainIsValid;

  return (
    <>
      <SquareNavButton
        text="Compose Email"
        onClick={handleComposeEmailClick}
        icon={<ComposeEmailIcon color={disabled ? grayMedium : granite} />}
        disabled={disabled}
        tooltipText={
          disabled && "Please setup custom email domain to compose email"
        }
        tooltipSubtext={
          <Box mt={1}>
            <Link
              style={{ color: moovsBlueHover }}
              to="/settings/general?tab=communication"
            >
              Go to Settings
            </Link>
          </Box>
        }
      />
      <SendEmailDialog
        open={sendEmailDialogOpen}
        mode={EmailModeEnum.Custom}
        onClose={() => setSendEmailDialogOpen(false)}
      />
    </>
  );
}

export default MoovsMarketNavButton;
