import React, { useState } from "react";
import { Area } from "react-easy-crop/types";
import Cropper from "react-easy-crop";

import { Box, Slider, CircularProgress } from "@mui/material";

import { white, moovsBlue } from "../../design-system/colors";
import MoovsDialog from "../MoovsDialog";
import {
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
} from "../../design-system/icons";
import { useScreenSize } from "../../globals/hooks";

type ImageUploadDialogProps = {
  open: boolean;
  onClose: () => void;
  imageDataUrl: string;
  aspectRatio: number;
  onFileUploadClick: (cropAreaPixels: Area) => void;
  imageDialogLoading: boolean;
};

function ImageUploadDialog(props: ImageUploadDialogProps) {
  const {
    open,
    onClose,
    imageDataUrl,
    aspectRatio,
    onFileUploadClick,
    imageDialogLoading,
  } = props;

  const { isMobileView } = useScreenSize();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>(null);

  const handleCropComplete = (_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  return (
    <MoovsDialog
      hideBottomBorder
      hideTopBorder
      fixedFooter
      open={open}
      onClose={onClose}
      dialogTitle="Add Photo"
      acceptButtonText="Upload"
      closeButtonText="Cancel"
      size="sm"
      onAccept={() => onFileUploadClick(croppedAreaPixels)}
    >
      <Box
        bgcolor={white}
        height="100%"
        display="flex"
        flex={1}
        flexDirection="column"
        overflow="hidden"
        alignItems="center"
      >
        {imageDialogLoading ? (
          <Box
            display="flex"
            flex="1"
            height="100%"
            width="100%"
            minHeight="200px"
            alignItems="center"
            justifyContent="center"
            margin={2}
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <Box
              display="flex"
              height="100vh"
              maxHeight={isMobileView ? "calc(100vh - 340px)" : "440px"}
              width="100%"
              position="relative"
            >
              <Cropper
                restrictPosition
                showGrid={false}
                image={imageDataUrl}
                crop={crop}
                zoom={zoom}
                aspect={aspectRatio}
                onCropChange={setCrop}
                onCropComplete={handleCropComplete}
                onZoomChange={setZoom}
              />
            </Box>
            <Box m={2} display="flex" flexDirection="row" alignItems="center">
              <MagnifyingGlassMinusIcon color={moovsBlue} />
              <Box mx={4} width="200px" maxWidth="calc(100vw - 200px)">
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom: number) => setZoom(zoom)}
                />
              </Box>
              <MagnifyingGlassPlusIcon color={moovsBlue} />
            </Box>
          </>
        )}
      </Box>
    </MoovsDialog>
  );
}

export default ImageUploadDialog;
