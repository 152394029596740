import React, { useState, MouseEvent } from "react";

import { Box, Tooltip, Typography } from "@mui/material";

import { granite, moovsBlue } from "design-system/colors";
import { InfoIcon } from "design-system/icons";
import { useScreenSize } from "globals/hooks";

export type CardNoteTooltipProps = {
  cardNote: string;
};

export function CardNoteTooltip(props: CardNoteTooltipProps) {
  const { cardNote } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { isMobileView } = useScreenSize();

  // prevent option from being selected when touching tooltip on mobile
  const handleMobileTooltipOpen = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  return (
    <Tooltip
      arrow
      enterTouchDelay={0}
      leaveTouchDelay={3000}
      onOpen={() => setTooltipOpen(true)}
      onClose={() => setTooltipOpen(false)}
      placement="top"
      title={
        <Box mx={0.5}>
          <Typography variant="subtitle2">{cardNote}</Typography>
        </Box>
      }
    >
      <Box mt="3px" {...(isMobileView && { onClick: handleMobileTooltipOpen })}>
        <InfoIcon color={tooltipOpen ? moovsBlue : granite} size="small" />
      </Box>
    </Tooltip>
  );
}
