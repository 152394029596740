import React from "react";
import { useQuery } from "@apollo/client";
import { useFormContext } from "react-hook-form";

import { Box, Typography } from "@mui/material";

import MoovsSearchableCheckboxDropdown from "components/MoovsSearchableCheckboxDropdown";
import { LOAD_COMPANIES_NAME_AND_ID_QUERY } from "globals/graphql";
import { FilterProps } from "../FilterDispatchTripsDrawer";

function CompanyFilter(props: FilterProps) {
  const { disabledSelect } = props;

  // hooks
  const { watch, setValue } = useFormContext();

  // queries
  const { data } = useQuery(LOAD_COMPANIES_NAME_AND_ID_QUERY);

  const companyOptions =
    data?.companies.map((company) => ({
      name: company.name,
      id: company.id,
    })) || [];

  return (
    <Box mb={2}>
      <Typography variant="h3" mb={2}>
        Company
      </Typography>

      <MoovsSearchableCheckboxDropdown
        fieldName="Company"
        options={companyOptions}
        selection={watch("companySelection")}
        setSelection={(newValue) => setValue("companySelection", newValue)}
        disabled={disabledSelect}
        tooltipText="Upgrade plan to Pro in order to use filtering feature"
      />
    </Box>
  );
}

export default CompanyFilter;
