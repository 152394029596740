import React from "react";

import { Box, IconButton, Typography, Button } from "@mui/material";

import { moovsBlueSelected, moovsBlue, white } from "design-system/colors";
import { EnrollIcon } from "design-system/icons";
import { useScreenSize } from "globals/hooks";

type EnableMoovsNetworkBannerProps = {
  onEnrollButtonClick: () => void;
};

function EnableMoovsNetworkBanner(props: EnableMoovsNetworkBannerProps) {
  const { onEnrollButtonClick } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Box
      bgcolor={moovsBlueSelected}
      display="flex"
      flexDirection={isMobileView ? "column" : "row"}
      justifyContent="space-between"
      p={2}
      borderRadius="3px"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        {...(isMobileView && { mb: 1 })}
      >
        <IconButton
          sx={{
            backgroundColor: `${white} !important`,
            marginRight: 2,
          }}
          disabled
          size="large"
        >
          <EnrollIcon color={moovsBlue} />
        </IconButton>
        <Box>
          <Typography variant="body2">
            Enroll in Moovs Network to start working with Moovs operators around
            the world
          </Typography>
        </Box>
      </Box>

      <Box {...(!isMobileView && { pl: 2 })}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={onEnrollButtonClick}
        >
          Enroll
        </Button>
      </Box>
    </Box>
  );
}

export default EnableMoovsNetworkBanner;
