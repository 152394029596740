import React from "react";

type StarIconProps = {
  color?: string;
  fillColor?: string;
  size: "small";
};

const StarIcon = (props: StarIconProps) => {
  const { color, fillColor } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.82695 1.49806L10.6202 5.65281H14.1102C14.2865 5.64559 14.4608 5.69323 14.609 5.78921C14.7573 5.88518 14.8723 6.02479 14.9383 6.18891C15.0044 6.35302 15.0182 6.5336 14.9779 6.70589C14.9376 6.87818 14.8451 7.03375 14.7132 7.15127L11.6844 9.86226L13.3624 13.7295C13.4361 13.9067 13.4525 14.1026 13.4094 14.2896C13.3662 14.4766 13.2656 14.6454 13.1218 14.772C12.9779 14.8987 12.7981 14.9769 12.6076 14.9956C12.417 15.0143 12.2255 14.9726 12.0599 14.8764L8.00898 12.5873L3.95812 14.8764C3.79249 14.9726 3.60092 15.0143 3.41041 14.9956C3.2199 14.9769 3.04005 14.8987 2.8962 14.772C2.75235 14.6454 2.65176 14.4766 2.60859 14.2896C2.56543 14.1026 2.58188 13.9067 2.65561 13.7295L4.33516 9.86226L1.28766 7.15127C1.15598 7.03392 1.06364 6.87864 1.02326 6.70667C0.982882 6.5347 0.996434 6.35442 1.06207 6.19046C1.1277 6.02651 1.24221 5.88687 1.38995 5.79064C1.53769 5.69441 1.71146 5.64628 1.8875 5.65281H5.37904L7.17544 1.49806C7.25502 1.34767 7.37392 1.22185 7.5194 1.13411C7.66488 1.04636 7.83143 1 8.00119 1C8.17096 1 8.33751 1.04636 8.48299 1.13411C8.62847 1.22185 8.74737 1.34767 8.82695 1.49806Z"
        fill={fillColor || "#FFFFFF"}
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StarIcon;
