import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import { alabaster } from "design-system/colors";
import { useOperator, useScreenSize } from "globals/hooks";
import { ModifyReviewPlatformButtons, ReviewPlatformInfo } from "./components";
import ChooseReviewPlatformDialog from "../../../ChooseReviewPlatformDialog";

function ReviewPlatformBlock() {
  // state
  const [chooseReviewPlatformDialogOpen, setChooseReviewPlatformDialogOpen] =
    useState(false);

  // hooks
  const { isMobileView } = useScreenSize();
  const {
    settings: { reviewSite, reviewSiteId },
  } = useOperator();

  //event handlers
  const handleModifyReviewPlatformClick = () => {
    setChooseReviewPlatformDialogOpen(true);
  };

  const handleChooseReviewPlatformDialogClose = () => {
    setChooseReviewPlatformDialogOpen(false);
  };

  return (
    <Box mb={3}>
      {/* Choose Review Platform Dialog */}
      <ChooseReviewPlatformDialog
        open={chooseReviewPlatformDialogOpen}
        onClose={handleChooseReviewPlatformDialogClose}
        selectedPlatform={reviewSite?.toLowerCase()}
        reviewSiteId={reviewSiteId}
      />
      {/* Header */}
      <Box mb={2}>
        {/* Usually Typography can take css properties but due to Box padding below it doesn't work as expected */}
        <Typography variant="overline">Review Platform</Typography>
      </Box>
      {/* Body Block - display review site if available, otherwise display enable button */}
      {reviewSite ? (
        <Box
          display="flex"
          flexDirection={isMobileView ? "column" : "row"}
          justifyContent="space-between"
          bgcolor={alabaster}
          px={3}
          py={2}
        >
          <ReviewPlatformInfo />

          <ModifyReviewPlatformButtons
            onModifyReviewPlatformDialogOpen={handleModifyReviewPlatformClick}
          />
        </Box>
      ) : (
        <Button variant="contained" onClick={handleModifyReviewPlatformClick}>
          Enable Review Platform
        </Button>
      )}
    </Box>
  );
}

export default ReviewPlatformBlock;
