import React from "react";

import { Box, Typography } from "@mui/material";

type NoTripsTextProps = {
  orderNumber: string;
};

function NoTripsText(props: NoTripsTextProps) {
  const { orderNumber } = props;
  return (
    <Box
      width="100%"
      height="calc(100vh - 78px)"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h5" align="center">
        Order {orderNumber} has no trips. It may have been deleted or cancelled.
      </Typography>
    </Box>
  );
}

export default NoTripsText;
