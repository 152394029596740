import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { useSnackbar } from "globals/hooks/useSnackbar";
import { REMOVE_DRIVER_MUTATION } from "globals/graphql";
import RemoveDialog from "components/RemoveDialog";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type removeDriverDialogProps = {
  open: boolean;
  handleClose: () => void;
  driverId: string;
};

function RemoveDriverDialog(props: removeDriverDialogProps) {
  const { open, handleClose, driverId } = props;

  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();

  // mutations
  const [removeDriver] = useMutation(REMOVE_DRIVER_MUTATION, {
    refetchQueries: ["DriverList"],
    onCompleted() {
      snackbar.success("Successfully deleted driver!");
      history.push("/settings/drivers");
    },
    onError(error) {
      const errorMessage = getErrorMessage(error) || "Error deleting driver";

      snackbar.error(errorMessage);
    },
  });

  // event handlers
  const handleRemoveDriver = () => {
    removeDriver({
      variables: {
        input: {
          id: driverId,
        },
      },
    });

    handleClose();
  };

  return (
    <RemoveDialog
      open={open}
      onRemove={handleRemoveDriver}
      onClose={handleClose}
      title="Remove this driver?"
      body="This will permanantly remove the driver. Do you want to remove this
      driver?"
    />
  );
}

export default RemoveDriverDialog;
