import React, { useState } from "react";

import { Box, Button, Collapse } from "@mui/material";

import { Trip } from "types";
import { useScreenSize } from "globals/hooks";
import { alabaster } from "design-system/colors";
import ViewOnlyTripDetails from "./ViewOnlyTripDetails";

const GRADIENT_OFFSET = "74px";

type CollapseViewOnlyTripDetailsProps = {
  trip: Trip;
};

function CollapseViewOnlyTripDetails(props: CollapseViewOnlyTripDetailsProps) {
  const { trip } = props;

  // state
  const [isExpanded, setIsExpanded] = useState(false);

  // hooks
  const { isMobileView } = useScreenSize();

  // events
  const onClick = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <Box position="relative" mb={`-${GRADIENT_OFFSET}`}>
      <Collapse
        in={isExpanded}
        timeout="auto"
        collapsedSize={isMobileView ? 400 : 300}
      >
        <ViewOnlyTripDetails trip={trip} />
      </Collapse>
      <Box
        sx={{
          position: "relative",
          top: `-${GRADIENT_OFFSET}`,
        }}
      >
        <Box
          height={GRADIENT_OFFSET}
          sx={{
            background: `linear-gradient(180deg, transparent, 50%, ${alabaster})`,
          }}
        />
        <Box
          sx={{
            backgroundColor: alabaster,
            px: 1,
            pb: 1,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            textAlign: isMobileView ? "center" : "left",
          }}
        >
          <Button
            variant="text"
            disableRipple
            disableFocusRipple
            onClick={onClick}
          >
            {`${isExpanded ? "Hide" : "Show all"} locations`}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default CollapseViewOnlyTripDetails;
