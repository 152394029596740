import React, { ChangeEvent, useEffect, useState } from "react";

import { Typography } from "@mui/material";
import MoovsDialog from "components/MoovsDialog";
import { errorRed, grayDark } from "design-system/colors";
import { useSaveReviewSite } from "./hooks";
import { ReviewSite } from "types";
import {
  ChooseReviewPlatformBlock,
  InputReviewSiteIdBlock,
} from "./components";
import { useInstallMarketApp } from "./hooks";
import { MarketApp } from "../../../MoovsMarketAppList/data/appData";
import { corporateIdIsInvalid } from "./utils";

type ChooseReviewPlatformDialogProps = {
  open: boolean;
  onClose: () => void;
  // onCloseWhenSavingPlatform is optional for use when additional actions need
  // to be taken when saving the review site compared to when simply closing the dialog
  onCloseWhenSavingPlatform?: () => void;
  shouldInstallWhenSaving?: boolean;
  selectedPlatform?: string;
  reviewSiteId?: string;
};

const ChooseReviewPlatformDialog = (props: ChooseReviewPlatformDialogProps) => {
  const { open, onClose, onCloseWhenSavingPlatform, shouldInstallWhenSaving } =
    props;

  // use state
  const [selectedPlatform, setSelectedPlatform] = useState(
    props.selectedPlatform
  );
  const [reviewSiteId, setReviewSiteId] = useState(props.reviewSiteId);
  const [siteIdError, setSiteIdError] = useState(null);
  const [selectedPlatformError, setSelectedPlatformError] = useState(null);

  // hooks
  const { handleSaveReviewSite, loading: saveLoading } = useSaveReviewSite({
    onCompleteSave: onCloseWhenSavingPlatform || onClose,
    selectedPlatform,
    reviewSiteId,
  });
  const { handleInstallMoovsMarketApp, loading: installLoading } =
    useInstallMarketApp(MarketApp.ReviewPlatform, handleSaveReviewSite);

  // event handlers
  const handleSelectedPlatform = (
    event: React.MouseEvent<HTMLElement>,
    newSelectedPlatform: ReviewSite
  ) => {
    setSelectedPlatformError(null);
    setSelectedPlatform(newSelectedPlatform);
  };

  const handleCorporationIdInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSiteIdError(null);
    setReviewSiteId(event.target.value.toString());
  };

  const handleAccept = () => {
    if (!selectedPlatform) {
      setSelectedPlatformError("Please select a review platform");
    } else if (!reviewSiteId) {
      setSiteIdError("Please enter Corporation ID");
    } else if (corporateIdIsInvalid(reviewSiteId)) {
      setSiteIdError("Please enter valid Corporation ID");
    } else if (shouldInstallWhenSaving) {
      handleInstallMoovsMarketApp("market_reviewPlatformInstalledWithLink");
    } else {
      handleSaveReviewSite("market_reviewPlatformModified");
    }
  };

  const handleClose = () => {
    onClose();
    setSiteIdError(null);
    // this two state settings reset any input that isn't saved back to the saved values
    setReviewSiteId(props.reviewSiteId);
    setSelectedPlatform(props.selectedPlatform);
  };

  const handleCorporateIdOnBlur = (e) => {
    const corporateId = e.target.value.toString();
    if (corporateIdIsInvalid(corporateId)) {
      setSiteIdError("Please enter valid Corporation ID");
    }
  };

  // effects
  // keeps selectedPlatform and reviewSiteId states in sync with the props values passed in
  useEffect(() => {
    setSelectedPlatform(props.selectedPlatform);
    setReviewSiteId(props.reviewSiteId);
  }, [props.selectedPlatform, props.reviewSiteId]);

  return (
    <MoovsDialog
      dialogTitle="Add your primary Review Platform"
      open={open}
      onClose={handleClose}
      acceptButtonText="Save"
      onAccept={handleAccept}
      acceptButtonVariant="contained"
      acceptDisabled={saveLoading || installLoading}
      hideLoadingIndicator={
        shouldInstallWhenSaving ? !saveLoading && !installLoading : !saveLoading
      }
      size="sm"
      subHeaderComponent={
        <Typography variant="body1" color={grayDark}>
          Select the review platform you would like your customers to leave you
          5-star reviews
        </Typography>
      }
    >
      {selectedPlatformError && (
        <Typography variant="body2" color={errorRed}>
          {selectedPlatformError}
        </Typography>
      )}
      <ChooseReviewPlatformBlock
        value={selectedPlatform}
        onChange={handleSelectedPlatform}
      />
      {/* display only if a platform is selected */}
      {selectedPlatform && (
        <InputReviewSiteIdBlock
          onCorporationIdInput={handleCorporationIdInput}
          onCorporationIdOnBlur={handleCorporateIdOnBlur}
          error={siteIdError}
          selectedPlatform={selectedPlatform}
          reviewSiteId={reviewSiteId}
        />
      )}
    </MoovsDialog>
  );
};

export default ChooseReviewPlatformDialog;
