import React, { ChangeEvent, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import { Box, TextField, Typography } from "@mui/material";

import { NumberFormatDollar } from "../../../../../../design-system/components/inputs/NumberFormat";
import MoovsDialog from "../../../../../MoovsDialog";
import { CREATE_REFUND_MUTATION } from "../../../../../../globals/graphql";
import { useSnackbar } from "../../../../../../globals/hooks/useSnackbar";
import { InfoIcon } from "../../../../../../design-system/icons";
import { granite } from "../../../../../../design-system/colors";
import {
  PaymentPaymentStatus,
  PaymentRefund,
  SubPayment,
} from "../../../../../../types";

type CreateRefundDialogProps = {
  open: boolean;
  onClose: () => void;
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
  subPayment: SubPayment;
  requestRefetch: () => void;
};

function CreateRefundDialog(props: CreateRefundDialogProps) {
  const { open, onClose, setSaveIndicatorState, subPayment, requestRefetch } =
    props;
  const snackbar = useSnackbar();

  // state
  const [refund, setRefund] = useState({ amount: null, note: null });
  const [refundErrors, setRefundErrors] = useState({ amount: "", note: "" });
  const [createRefundLoading, setCreateRefundLoading] = useState(false);

  // mutation
  const [createRefund] = useMutation(CREATE_REFUND_MUTATION, {
    onCompleted() {
      setSaveIndicatorState("saved");
      onClose();
      requestRefetch();
      setCreateRefundLoading(false);
      snackbar.success("Successfully created refund!");
    },
    onError(error) {
      setSaveIndicatorState("error");
      setCreateRefundLoading(false);
      snackbar.error(error.message);
    },
  });

  useEffect(() => {
    if (open) {
      setRefund({
        amount: null,
        note: null,
      });
    }
  }, [open]);

  // event handlers
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let name = event.target.name;
    const value =
      event.target.name === "amount" && event.target.value !== ""
        ? parseFloat(event.target.value)
        : event.target.value;

    if (event.target.name === "note") {
      value.toString().length >= 1000
        ? setRefundErrors((prevState) => ({
            ...prevState,
            note: "Reached character limit",
          }))
        : setRefundErrors((prevState) => ({ ...prevState, note: "" }));
    }

    if (event.target.name === "amount") {
      setRefundErrors((prevState) => ({ ...prevState, amount: "" }));
    }

    setRefund({
      ...refund,
      [name]: value,
    });
  };

  const handleSubmitRefundClick = () => {
    setCreateRefundLoading(true);

    let totalRefundedAmount = 0;
    subPayment?.refunds?.forEach((refund: PaymentRefund) => {
      if (refund.paymentStatus !== PaymentPaymentStatus.Failed) {
        totalRefundedAmount += refund.amount;
      }
    });

    const maxRefundAmount =
      (subPayment.amount * 100 - totalRefundedAmount * 100) / 100;

    if (parseFloat(refund.amount) === 0 || !refund.amount) {
      setRefundErrors((prevState) => ({
        ...prevState,
        amount: "Refund cannot be $0.00",
      }));
      setCreateRefundLoading(false);
      return;
    }

    if (refund.amount > maxRefundAmount) {
      setRefundErrors((prevState) => ({
        ...prevState,
        amount: `Refund cannot be more than $${maxRefundAmount} `,
      }));
      setCreateRefundLoading(false);
      return;
    }

    setSaveIndicatorState("loading");
    createRefund({
      variables: {
        input: {
          ...refund,
          subPaymentId: subPayment.id,
        },
      },
    });
  };

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      dialogTitle="Refund Payment"
      size="sm"
      acceptButtonText={createRefundLoading ? "Processing..." : "Refund"}
      onAccept={handleSubmitRefundClick}
      acceptDisabled={createRefundLoading}
    >
      <Box my={3}>
        <Box mb={2}>
          <TextField
            required
            fullWidth
            variant="outlined"
            label="Refund Amount"
            name="amount"
            value={refund.amount || ""}
            onChange={handleInputChange}
            InputProps={{
              inputComponent: NumberFormatDollar as any,
            }}
            error={!!refundErrors.amount}
            helperText={refundErrors.amount}
          />
        </Box>
        <TextField
          multiline
          fullWidth
          variant="outlined"
          label="Note"
          name="note"
          value={refund.note || ""}
          onChange={handleInputChange}
          error={!!refundErrors.note}
          helperText={refundErrors.note}
          inputProps={{ maxLength: 1000 }}
        />
        {subPayment?.payment.method === "CARD" && (
          <Box mt={2} display="flex" flexDirection="row">
            <Box mr={1}>
              <InfoIcon />
            </Box>
            <Box maxWidth={250}>
              <Typography variant="body2" sx={{ color: granite }}>
                Refunds take 5-10 days to appear on a customer’s statement
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </MoovsDialog>
  );
}

export default CreateRefundDialog;
