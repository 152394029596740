import React from "react";
import { Moment } from "moment";

import ComingSoonBlock from "./ComingSoonBlock";
import { DashboardView } from "../DashboardPage";

type TotalQuotesViewProps = {
  startDate: Moment;
  endDate: Moment;
};

function TotalQuotesView(props: TotalQuotesViewProps) {
  const { startDate, endDate } = props;

  return (
    <ComingSoonBlock
      view={DashboardView.TOTAL_QUOTES}
      startDate={startDate}
      endDate={endDate}
    />
  );
}

export default TotalQuotesView;
