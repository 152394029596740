import React from "react";
import { Control, FieldErrors } from "react-hook-form";

import { Box } from "@mui/material";

import RHFTimePicker from "components/reactHookFormInputs/RHFTimePicker";
import { useScreenSize } from "globals/hooks";
import { EditDynamicPricingDefaultValues } from "../useEditDynamicPricingDefaultValues";

type StartTimeProps = {
  control: Control<EditDynamicPricingDefaultValues>;
  errors: FieldErrors;
};

function StartTime(props: StartTimeProps) {
  const { errors, control } = props;

  const { isSmallTabletView } = useScreenSize();

  return (
    <Box my={1.5} width={isSmallTabletView ? "100%" : "49.5%"}>
      <RHFTimePicker
        name="startTime"
        control={control}
        label="Start Time (optional)"
        errorMessage={errors.startTime?.message}
        textFieldPlaceholder="Start Time (optional)"
      />
    </Box>
  );
}

export default StartTime;
