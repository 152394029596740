import { applyUTCOffsetToTime } from "globals/utils/helpers";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment-timezone";

type useDriverEtaCalculationsProps = {
  pickUpTime: Date;
  driverEta: Date;
};

function useDriverEtaCalculations(props: useDriverEtaCalculationsProps) {
  const { pickUpTime } = props;

  // derived state
  const driverEta = moment(props.driverEta);
  // pickup time (stop.dateTime) is a timestamp w/o timezone. driverETA is a timestamp w/ timezone.
  // to correctly calculate difference, we must apply UTC offset
  const minutesLate = moment(
    applyUTCOffsetToTime(cloneDeep(driverEta).startOf("minute"), "add")
  ).diff(moment.utc(pickUpTime).startOf("minute"), "minutes");

  return {
    minutesLate,
    driverEta: driverEta.format("h:mm A"),
  };
}

export default useDriverEtaCalculations;
