import React from "react";

import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";

function CustomAppointmentContent(props) {
  const { data, style } = props;

  return (
    <Appointments.AppointmentContent
      style={{
        ...style,
        color: data.appointmentTextColor,
      }}
      {...props}
    />
  );
}

export default CustomAppointmentContent;
