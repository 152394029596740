import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";

import {
  RadioGroup,
  Box,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";

import { grayDark } from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import RemoveDialog from "components/RemoveDialog";
import {
  CreateDriverInput,
  TripLocationsVariant,
  UpdateDriverInput,
} from "types";

type DriverPayoutLocationToggleProps = {
  driver: CreateDriverInput | UpdateDriverInput;
  tripLocationsVariant: TripLocationsVariant;
  setDriver: Dispatch<SetStateAction<CreateDriverInput | UpdateDriverInput>>;
  fieldName: string;
};

function DriverPayoutLocationToggle(props: DriverPayoutLocationToggleProps) {
  const {
    tripLocationsVariant = TripLocationsVariant.LocationDropOff,
    driver,
    setDriver,
    fieldName,
  } = props;

  // hook
  const { isSmallTabletView } = useScreenSize();

  // state
  const [garageDialogOpen, setGarageDialogOpen] = useState(false);

  // derived state
  const inputCaption =
    tripLocationsVariant === TripLocationsVariant.LocationDropOff
      ? "Calculates total time from 'On Location' to 'Drop-Off'"
      : tripLocationsVariant === TripLocationsVariant.GarageToGarage
      ? "Calculates total drive time from 'Garage Out' to 'Garage In' based on driver app"
      : null;

  // event handler
  const handleLocationVariantChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value, name } = event.target;
    if (value === TripLocationsVariant.GarageToGarage) {
      setGarageDialogOpen(true);
    } else {
      setDriver({
        ...driver,
        settings: {
          ...driver.settings,
          [name]: value,
        },
      });
    }
  };

  const handleDialogClick = (location: TripLocationsVariant) => {
    setGarageDialogOpen(false);

    setDriver({
      ...driver,
      settings: {
        ...driver.settings,
        [fieldName]: location,
      },
    });
  };

  return (
    <>
      {/* Location Toggle */}
      <RadioGroup
        aria-labelledby="location-radio-button"
        name={fieldName}
        value={tripLocationsVariant}
        onChange={handleLocationVariantChange}
      >
        <Box
          display="flex"
          flexDirection={isSmallTabletView ? "column" : "row"}
        >
          <FormControlLabel
            value={TripLocationsVariant.LocationDropOff}
            control={<Radio />}
            label="On Location to Drop-Off"
          />
          <FormControlLabel
            value={TripLocationsVariant.GarageToGarage}
            control={<Radio />}
            label="Garage to Garage"
          />
        </Box>
      </RadioGroup>

      {/* Location Variant Caption */}
      {inputCaption && (
        <Typography color={grayDark} variant="caption">
          {inputCaption}
        </Typography>
      )}

      {/* Confirm to Switch to Garage to Garage */}
      <RemoveDialog
        open={garageDialogOpen}
        onRemove={() => handleDialogClick(TripLocationsVariant.GarageToGarage)}
        onClose={() => handleDialogClick(TripLocationsVariant.LocationDropOff)}
        title="Garage to Garage"
        removeButtonText="Got it"
        body={
          <label>
            Driver Payout Automation will only work for this setting when driver
            uses the <label style={{ fontWeight: 600 }}>driver app</label>. If
            driver uses driver link, the automation will not work.
          </label>
        }
      />
    </>
  );
}

export default DriverPayoutLocationToggle;
