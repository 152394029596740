import React from "react";
import startCase from "lodash/startCase";
import lowerCase from "lodash/lowerCase";

import { Box, Card, CardActionArea, Typography } from "@mui/material";

import { CancellationPolicy } from "../../../types";
import { black } from "../../../design-system/colors";

const styles = {
  requestItemText: {
    fontSize: "12px",
    lineHeight: 1.33,
  },
  avatar: {
    borderRadius: "4px",
    width: 80,
    height: 55,
  },
  policyAmount: {
    color: black,
    lineHeight: 1.35,
  },
};

type CancellationPoliciesListItemMobileProps = {
  cancellationPolicy: CancellationPolicy;
  onClick: (cancellationPolicyId: string) => void;
};

function CancellationPoliciesListItemMobile(
  props: CancellationPoliciesListItemMobileProps
) {
  const { cancellationPolicy, onClick } = props;
  const { id, variant, refund100, refund50, refund25 } = cancellationPolicy;

  const refundType = startCase(lowerCase(variant));

  const refund100Amount =
    refund100.periodValue > 1
      ? `${refund100.periodValue} ${refund100.periodName}S`
      : `${refund100.periodValue} ${refund100.periodName}`;
  const refund50Amount =
    refund50.periodValue > 1
      ? `${refund50.periodValue} ${refund50.periodName}S`
      : `${refund50.periodValue} ${refund50.periodName}`;
  const refund25Amount =
    refund25.periodValue > 1
      ? `${refund25.periodValue} ${refund25.periodName}S`
      : `${refund25.periodValue} ${refund25.periodName}`;

  return (
    <Card variant="outlined">
      <CardActionArea disableRipple onClick={() => onClick(id)}>
        <Box display="flex" flexDirection="column" flex="1" m={2}>
          <Box display="flex">
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {refundType}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box display="flex" flexDirection="column" mt={1} flex="1">
              <Typography variant="overline">FULL REFUND</Typography>
              <Typography variant="caption" sx={styles.policyAmount}>
                {refund100.active ? refund100Amount : "-"}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" mt={1} flex="1">
              <Typography variant="overline">50% REFUND</Typography>
              <Typography variant="caption" sx={styles.policyAmount}>
                {refund50.active ? refund50Amount : "-"}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" mt={1} flex="1">
              <Typography variant="overline">25% REFUND</Typography>
              <Typography variant="caption" sx={styles.policyAmount}>
                {refund25.active ? refund25Amount : "-"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default CancellationPoliciesListItemMobile;
