import React from "react";

import { Box, Typography } from "@mui/material";

import MoovsDialog from "./MoovsDialog";
import { RouteDriver } from "types";

type AvailabilityConflictDialogProps = {
  open: boolean;
  onClose: () => void;
  onAssign: () => void;
  routeDriver: RouteDriver;
};

function AvailabilityConflictDialog(props: AvailabilityConflictDialogProps) {
  const { open, onAssign, onClose, routeDriver } = props;

  if (!routeDriver) return null;
  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      onAccept={onAssign}
      hideTopBorder
      dialogTitle={
        !routeDriver.routeAvailability.available
          ? "Trip Conflict"
          : "Availability Conflict"
      }
      acceptButtonText="Assign"
      closeButtonText="Cancel"
      size="sm"
    >
      <Box mb={3}>
        <Typography variant="body2">
          {`${routeDriver?.driver.firstName} has ${
            !routeDriver.routeAvailability.available
              ? "a trip"
              : "an availability"
          } conflict and is unavailable. Would you still like to assign?`}
        </Typography>
      </Box>
    </MoovsDialog>
  );
}

export default AvailabilityConflictDialog;
