import React from "react";

import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { DriverStatus, DriverStatusName, RouteDriver } from "types";
import { errorRed, granite, grayMedium } from "design-system/colors";
import CancelTripIcon from "design-system/icons/actions/CancelTripIcon";
import { CalendarUnavailableIcon } from "design-system/icons";
import { DriverStatusIndicator } from "./components";

function DriverColumn(props: GridRenderCellParams<string>) {
  const { value, row } = props;

  const routeDriver = row.routes[0].routeDriver as RouteDriver;
  const driverStatus = row.routes[0].driverStatus as DriverStatus;

  if (!routeDriver) {
    return (
      <Typography variant="body2" color={grayMedium}>
        Add Driver
      </Typography>
    );
  }

  const showRouteConflict = !routeDriver.routeAvailability?.available;
  const showPersonalConflict =
    !showRouteConflict && !routeDriver.personalAvailability?.available;

  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box pr={1} flexShrink={0}>
        <DriverStatusIndicator
          driverStatus={driverStatus?.name || DriverStatusName.Pending}
        />
      </Box>
      <Box
        minHeight="38px"
        display="flex"
        flexDirection="column"
        flexShrink={1}
        overflow="hidden"
      >
        <Typography variant="body2" textOverflow="ellipsis" overflow="hidden">
          {value}
        </Typography>
        <Typography
          color={granite}
          variant="caption"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {routeDriver?.driver.mobilePhone}
        </Typography>
      </Box>
      <Box>
        {showRouteConflict && (
          <Tooltip placement="top" title="Trip Conflict">
            <IconButton
              sx={{
                "&.MuiButtonBase-root": {
                  paddingTop: "2px",
                  paddingLeft: "6px",
                },
              }}
            >
              <CancelTripIcon color={errorRed} />
            </IconButton>
          </Tooltip>
        )}
        {showPersonalConflict && (
          <Tooltip placement="top" title="Availability Conflict">
            <IconButton sx={{ "&.MuiButtonBase-root": { paddingTop: "2px" } }}>
              <CalendarUnavailableIcon color={errorRed} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}

export default DriverColumn;
