import React from "react";
import { useHistory } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import MoovsDialog from "../../../../../../components/MoovsDialog";
import { MutationFunctionOptions } from "@apollo/client/react/types/types";
import { OperationVariables } from "@apollo/client/core/types";
import { FetchResult } from "@apollo/client/link/core/types";
import { FarmedRouteStatusEnum } from "../../../../../../types";
import { useAnalytics } from "../../../../../../globals/hooks";

type DenyTripDialogProps = {
  open: boolean;
  onDenyTripDialogClose: () => void;
  routeId: string;
  updateFarmedRouteStatus: (
    options?: MutationFunctionOptions<any, OperationVariables>
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
};

function DenyTripDialog(props: DenyTripDialogProps) {
  const { open, onDenyTripDialogClose, routeId, updateFarmedRouteStatus } =
    props;

  // hooks
  const { track } = useAnalytics();
  const history = useHistory();

  // event handlers
  const handleDenyTrip = () => {
    track("farmedRoute_farmeeDenied");

    updateFarmedRouteStatus({
      variables: {
        input: {
          routeId: routeId,
          farmedRouteStatus: FarmedRouteStatusEnum.Declined,
        },
      },
      onCompleted: () => {
        onDenyTripDialogClose();
        history.replace("/reservations");
      },
    });
  };

  return (
    <MoovsDialog
      open={open}
      onClose={onDenyTripDialogClose}
      onAccept={handleDenyTrip}
      hideTopBorder
      dialogTitle="Deny Trip"
      acceptButtonText="Deny Trip"
      closeButtonText="Cancel"
      size="xs"
    >
      <Box mb={3}>
        <Typography variant="body2">
          Are you sure you want to deny this trip?
        </Typography>
      </Box>
    </MoovsDialog>
  );
}

export default DenyTripDialog;
