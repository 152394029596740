import React from "react";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { Box } from "@mui/material";

import RequestListItem from "./RequestListItem";
import { Request } from "../../../types";

// constants
export const ORDER_ITEM_HEIGHT = 111;

type RequestsListProps = {
  requests: Request[];
  mode: "quotes" | "reservations";
};

function RequestsList(props: RequestsListProps) {
  const { mode, requests = [] } = props;

  const Row = ({ index, style }) => {
    const request = requests[index];

    return (
      <div style={style}>
        <RequestListItem key={index} request={request} mode={mode} />
      </div>
    );
  };

  return (
    <Box width="100%" height="100%" overflow="hidden">
      <AutoSizer>
        {({ height, width }) => (
          <List
            className="List"
            height={height}
            itemCount={requests.length}
            itemSize={ORDER_ITEM_HEIGHT}
            width={width}
          >
            {Row}
          </List>
        )}
      </AutoSizer>
    </Box>
  );
}

export default RequestsList;
