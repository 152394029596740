import React, { ChangeEvent, useState, useEffect, useRef } from "react";

import {
  Box,
  TextField,
  Typography,
  InputProps as MuiInputProps,
  SxProps,
} from "@mui/material";

import { granite, errorRed, warningYellow } from "design-system/colors";

type CharacterLimitedTextInputProps = {
  name: string;
  value: string;
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  label?: string;
  warningLimit?: number;
  errorLimit?: number;
  preventCharacterCountUpdatePastLimit?: boolean;
  InputProps?: MuiInputProps;
  sx?: SxProps;
  disabled?: boolean;
};

function CharacterLimitedTextInput(props: CharacterLimitedTextInputProps) {
  const {
    name,
    value,
    onChange,
    label,
    warningLimit = 25,
    errorLimit = 35,
    preventCharacterCountUpdatePastLimit,
    InputProps,
    sx,
    disabled,
  } = props;

  // state
  const [inputValue, setInputValue] = useState(value || "");
  const [isFocused, setIsFocused] = useState(false);

  // refs
  const textFieldRef = useRef(null);

  // event handlers
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    // If the parent component doesn't update the value past the error limit (ex. auto-save) in its onChange,
    // then we don't want to update the character count (helper text) past the error limit either
    if (
      !preventCharacterCountUpdatePastLimit ||
      (preventCharacterCountUpdatePastLimit && newValue.length <= errorLimit)
    ) {
      setInputValue(newValue);
    }
    onChange(event);
  };

  const characterCount = inputValue?.length || 0;
  let helperText = "";

  if (characterCount > 0) {
    helperText = `${characterCount} / ${errorLimit}`;
    if (characterCount > errorLimit) {
      helperText += `: Cannot exceed character limit of ${errorLimit}`;
    }
  }

  let helperTextColor = granite;
  let isError = false;

  if (characterCount >= warningLimit && characterCount <= errorLimit) {
    helperTextColor = warningYellow;
  } else if (characterCount > errorLimit) {
    helperTextColor = errorRed;
    isError = true;
  }

  // If the onChange prop triggers a network request, disable the field while loading then
  // this effect will re-focus the field after the network request is complete
  useEffect(() => {
    if (!disabled && isFocused) {
      setTimeout(() => {
        textFieldRef.current.focus();
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  // We need this to reset the helper text properly when drag/drop elements are swapped
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <TextField
      fullWidth
      variant="outlined"
      label={label}
      name={name}
      value={inputValue || ""}
      onChange={handleInputChange}
      error={isError}
      disabled={disabled}
      inputRef={textFieldRef}
      sx={sx}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      helperText={
        <Box mt={0.5}>
          <Typography variant="body2" color={helperTextColor}>
            {helperText}
          </Typography>
        </Box>
      }
      {...(InputProps && { InputProps })}
    />
  );
}

export default CharacterLimitedTextInput;
