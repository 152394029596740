/**
 * @file MoovsApolloProvider.tsx
 * Migration from Apollo Boost to Apollo Client to enable advanced configurations of Apollo Link.
 *
 * reference:
 *  https://www.apollographql.com/docs/react/v2.4/advanced/boost-migration/
 *  https://www.apollographql.com/blog/full-stack-error-handling-with-graphql-apollo-5c12da407210/
 *  https://graphql-code-generator.com/docs/plugins/fragment-matcher
 *
 * Also passes in Snackbar hook to be used at the Apollo level.
 *
 * components:
 *  MoovsApolloProvider
 *
 * author: harrisonw
 */
import React from "react";
import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloProvider,
} from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

import introspection from "../possibleTypes";
import { headerMiddlewareLink, onErrorLink } from "./ApolloLinks";
import { getServerLink } from "globals/utils/getServerLink";

const cache = new InMemoryCache({
  //@ts-ignore
  possibleTypes: introspection.possibleTypes,
  typePolicies: {
    // reference: https://www.apollographql.com/docs/react/caching/cache-configuration/#generating-unique-identifiers
    Airport: {
      keyFields: ["icaoCode"],
      merge: true,
    },
    Airline: {
      keyFields: ["icaoCode"],
      merge: true,
    },
    VehicleType: {
      keyFields: ["typeSlug"],
      merge: true,
    },
    Query: {
      fields: {
        operatorRoutes: relayStylePagination(),
        stripePayouts: relayStylePagination(),
        loadContacts: relayStylePagination(),
        loadFarmAffiliates: relayStylePagination(),
        loadFarmAffiliateVehicles: relayStylePagination(),
        loadReservations: relayStylePagination(),
        loadQuotes: relayStylePagination(),
        loadTransactionsForPaymentMethod: relayStylePagination(),
        loadTripReviews: relayStylePagination(),
        loadTripsForBookingContact: relayStylePagination(),
        loadAffiliateTrips: relayStylePagination(),
        loadCompanyTrips: relayStylePagination(),
        loadEmailLogs: relayStylePagination(),
        loadFilterableDispatchTrips: relayStylePagination(),
      },
    },
  },
});

const client = () =>
  new ApolloClient({
    link: ApolloLink.from([
      headerMiddlewareLink,
      onErrorLink(),
      new HttpLink({
        uri: `${getServerLink()}/operator`,
        credentials: "include",
      }),
    ]),
    connectToDevTools: process.env.NODE_ENV !== "production",
    cache,
  });

function MoovsApolloProvider(props) {
  const { children } = props;

  return (
    <ApolloProvider client={client()}>
      {children}
    </ApolloProvider>
  );
}

export default MoovsApolloProvider;
