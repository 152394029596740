import React, { useState } from "react";

import { Box, CircularProgress, Typography } from "@mui/material";

import imageIllustration from "../../../../design-system/illustrations/image.svg";
import PhotoIndexIndicator from "../PhotoIndexIndicator";
import ImageUpload from "../../../ImageUpload/ImageUpload";
import { useSnackbar } from "../../../../globals/hooks";

type AddVehiclePhotoPlaceholderProps = {
  photoIndex: number;
  onCreateVehiclePhoto: (url: string) => void;
};

function AddVehiclePhotoPlaceholder(props: AddVehiclePhotoPlaceholderProps) {
  const { photoIndex, onCreateVehiclePhoto } = props;

  const snackbar = useSnackbar();

  // state
  const [loading, setLoading] = useState(false);

  // event handlers
  const handleImageUploadError = (errorMessage: string) => {
    snackbar.error(errorMessage);
    setLoading(false);
  };

  const handleFileUploadComplete = (url: string) => {
    onCreateVehiclePhoto(url);
  };

  return (
    <ImageUpload
      firebaseDirectory="vehicle_images"
      htmlFor="v-photo-button"
      aspectRatio={4 / 3}
      maxWidth={2000}
      maxHeight={1500}
      onFileUploadStart={() => setLoading(true)}
      onFileUploadComplete={handleFileUploadComplete}
      onError={handleImageUploadError}
      button={
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height={150}
          width={200}
          borderRadius="4px"
          border="1px solid rgba(115, 156, 255, 0.3)"
          m={1}
          style={{ cursor: "pointer" }}
          sx={{
            backgroundColor: "rgba(229, 238, 255, 0.3)",
            ":hover": {
              backgroundColor: "rgba(229, 238, 255, 0.6)",
            },
          }}
        >
          <PhotoIndexIndicator photoIndex={photoIndex} />
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Box my={1.5}>
                <img src={imageIllustration} alt="messages illustration" />
              </Box>
              <Typography variant="body2" color="primary">
                Add a Vehicle Photo
              </Typography>
            </>
          )}
        </Box>
      }
    />
  );
}

export default AddVehiclePhotoPlaceholder;
