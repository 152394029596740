import React, { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useLazyQuery } from "@apollo/client";

import {
  Box,
  Divider,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";

import { Company } from "../../types";
import { LOAD_COMPANIES_BY_SEARCH_TERM_QUERY } from "../../globals/graphql";

type CompanyAutoCompleteProps = {
  onCompanyChange: (companyId: string) => void;
  value?: string;
  error?: boolean;
  helperText?: string;
};

function CompanyAutoComplete(props: CompanyAutoCompleteProps) {
  const { value, error, helperText, onCompanyChange } = props;

  const [companySearchInput, setCompanySearchInput] = useState("");
  const [companyOptions, setCompanyOptions] = useState([]);

  const [loadCompaniesBySearchTerm, { loading, data }] = useLazyQuery(
    LOAD_COMPANIES_BY_SEARCH_TERM_QUERY,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const companies = data?.loadCompaniesBySearchTerm.edges.map(
          ({ node }) => node
        );
        setCompanyOptions(companies || []);
      },
    }
  );

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompanySearchInput(event.target.value);
  };

  const debouncedGetCompanies = useDebouncedCallback(
    (companySearchInput: string) => {
      loadCompaniesBySearchTerm({
        variables: {
          searchTerm: companySearchInput,
          limit: 20,
        },
      });
    },
    250
  );

  useEffect(() => {
    if (companySearchInput) {
      debouncedGetCompanies(companySearchInput);
    }
  }, [companySearchInput, debouncedGetCompanies]);

  return (
    <Autocomplete
      autoHighlight
      clearOnEscape
      noOptionsText={
        data && companySearchInput
          ? "No Company Found"
          : "Type to search for a company"
      }
      filterOptions={(x) => x}
      loading={debouncedGetCompanies.isPending() || loading}
      value={value}
      onChange={(_e, newValue: Company) =>
        onCompanyChange(newValue?.id || null)
      }
      options={companyOptions}
      onInputChange={handleSearchInputChange}
      getOptionLabel={(company: Company) => company.name}
      renderInput={(params) => {
        const { InputProps, ...restParams } = params;
        return (
          <TextField
            {...restParams}
            fullWidth
            label={"Search for company"}
            name="no-show"
            variant="outlined"
            error={error}
            helperText={helperText}
            InputProps={{
              ...InputProps,
              autoComplete: "organization",
            }}
          />
        );
      }}
      renderOption={(props, option) => (
        <li {...props}>
          <Box>
            <Typography variant="h6">{option.name}</Typography>
            {option?.address ? (
              <Typography variant="body2">{option.address}</Typography>
            ) : null}
          </Box>
          <Divider />
        </li>
      )}
    />
  );
}

export default CompanyAutoComplete;
