/**
 * @file graphql/stripe.ts
 * queries/mutations for Stripe.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_FINANCIAL_ACCOUNT = gql`
  query {
    loadFinancialAccount {
      id
      balance
    }
  }
`;

export const UPDATE_PAYOUT_SCHEDULE = gql`
  mutation UpdatePayoutSchedule($input: UpdatePayoutScheduleInput!) {
    updatePayoutSchedule(input: $input) {
      stripeAccount {
        id
        payoutSchedule
      }
    }
  }
`;
