import React from "react";

import CreateTermsDrawer from "components/settings/terms/CreateTermsDrawer";
import CreateInsuranceDrawer from "components/settings/insurance/CreateInsuranceDrawer";
import CreateDriverDrawer from "components/settings/drivers/CreateDriverDrawer";
import CreateCancellationDrawer from "components/settings/cancellation/CreateCancellationDrawer";
import CreateContactDrawer from "components/contacts/people/CreateContactDrawer";
import CreateCompanyDrawer from "components/contacts/companies/CreateCompanyDrawer";
import CreateFarmAffiliateDrawer from "components/contacts/affiliates/CreateFarmAffiliateDrawer/CreateFarmAffiliateDrawer";
import CreateInvoiceDrawer from "components/invoices/CreateInvoiceDrawer/CreateInvoiceDrawer";
import UpdateRequestDrawer from "components/requests/update/UpdateRequestDrawer";
import UpdateDispatchRouteDrawer from "components/dispatch/UpdateDispatchRouteDrawer";
import UpdateCancellationDrawer from "components/settings/cancellation/UpdateCancellationDrawer";
import UpdateInsuranceDrawer from "components/settings/insurance/UpdateInsuranceDrawer/UpdateInsuranceDrawer";
import UpdateDriverDrawer from "components/settings/drivers/UpdateDriverDrawer/UpdateDriverDrawer";
import UpdateTermsDrawer from "components/settings/terms/UpdateTermsDrawer/UpdateTermsDrawer";
import UpdateCompanyDrawer from "components/contacts/companies/UpdateCompanyDrawer/UpdateCompanyDrawer";
import UpdateFarmAffiliateDrawer from "components/contacts/affiliates/UpdateFarmAffiliateDrawer/UpdateFarmAffiliateDrawer";
import CreateQuoteDrawer from "components/requests/create/CreateRequest/CreateQuoteDrawer";
import CreateShuttleReservationDrawer from "components/requests/create/CreateRequest/CreateShuttleReservationDrawer";
import CreateTripDrawer from "components/requests/create/CreateRequest/CreateTripDrawer";
import CreateShuttleTripDrawer from "components/requests/create/CreateRequest/CreateShuttleTripDrawer";
import CreateReturnTripDrawer from "components/requests/create/CreateRequest/CreateReturnTripDrawer";
import PrivateDrawerRoute from "routes/PrivateDrawerRoute";
import UpdateVehicleDrawer from "components/vehicles/UpdateVehicleDrawer";
import UpdateShuttleRequestDrawer from "components/requests/update/UpdateShuttleRequestDrawer";
import UpdateContactDrawer from "components/contacts/people/UpdateContactDrawer/UpdateContactDrawer";
import CreateReservationDrawer from "components/requests/create/CreateRequest/CreateReservationDrawer";
import UpdateInvoiceDrawer from "components/invoices/UpdateInvoiceDrawer";
import {
  CreateMemberDrawer,
  UpdateMemberDrawer,
} from "pages/settings/MemberSettingsPage/components";
import UpdatePromoCodeDrawer from "pages/settings/CustomerPortalPage/components/Tabs/PromoCodesTab/components/UpdatePromoCodeDrawer";
import CreateVehicleDrawer from "components/vehicles/CreateVehicleDrawer";
import UpdateQuoteDrawer from "components/requests/update/UpdateQuoteDrawer";

function DrawerRouter() {
  return (
    <>
      <PrivateDrawerRoute path="/quotes/create">
        <CreateQuoteDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/quotes/:requestId/add-trip">
        <CreateTripDrawer addTripFor="quote" />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/reservations/:requestId/add-trip">
        <CreateTripDrawer addTripFor="reservation" />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/reservations/shuttle/:requestId/add-trip">
        <CreateShuttleTripDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/quotes/:requestId/add-return-trip">
        <CreateReturnTripDrawer returnTripFor="quote" />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/reservations/:requestId/add-return-trip">
        <CreateReturnTripDrawer returnTripFor="reservation" />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/reservations/create">
        <CreateReservationDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/reservations/shuttle/create">
        <CreateShuttleReservationDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/vehicles/create">
        <CreateVehicleDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/vehicles/update/:vehicleId">
        <UpdateVehicleDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/contacts/create">
        <CreateContactDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/affiliates/create">
        <CreateFarmAffiliateDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/companies/create">
        <CreateCompanyDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/settings/insurance/create">
        <CreateInsuranceDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/settings/terms-and-conditions/create">
        <CreateTermsDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/settings/members/create">
        <CreateMemberDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/settings/drivers/create">
        <CreateDriverDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/settings/cancellation/create">
        <CreateCancellationDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/invoices/create">
        <CreateInvoiceDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/settings/members/update/:userId">
        <UpdateMemberDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute exact path="/quotes/:requestId/update/:tripId">
        <UpdateQuoteDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/reservations/:requestId/update/:tripId">
        <UpdateRequestDrawer mode="reservations" />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/reservations/shuttle/:requestId/update/:tripId">
        <UpdateShuttleRequestDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/dispatch/:requestId/update/:tripId">
        <UpdateRequestDrawer mode="reservations" />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/dispatch/update/:routeId">
        <UpdateDispatchRouteDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/settings/cancellation/update/:cancellationPolicyId">
        <UpdateCancellationDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/settings/insurance/update/:insuranceId">
        <UpdateInsuranceDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/settings/drivers/update/:driverId">
        <UpdateDriverDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/settings/terms-and-conditions/update/:termId">
        <UpdateTermsDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/contacts/update/:contactId">
        <UpdateContactDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/affiliates/update/:affiliateId">
        <UpdateFarmAffiliateDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/companies/update/:companyId">
        <UpdateCompanyDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/invoices/update/:invoiceId">
        <UpdateInvoiceDrawer />
      </PrivateDrawerRoute>
      <PrivateDrawerRoute path="/settings/customer-portal/promo-codes/update/:promoCodeId">
        <UpdatePromoCodeDrawer />
      </PrivateDrawerRoute>
    </>
  );
}

export default DrawerRouter;
