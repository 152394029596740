import React from "react";

import { Box, Typography } from "@mui/material";

import { LabeledInlineInput } from "../../../design-system/components/inputs";

type BasicInfoBlockProps = {
  vehicleName: string;
  typeName: string;
  capacity: number;
  licensePlate: string;
  description: string;
  exteriorColor: string;
};

function BasicInfoBlock(props: BasicInfoBlockProps) {
  const {
    vehicleName,
    typeName,
    capacity,
    licensePlate,
    description,
    exteriorColor,
  } = props;

  return (
    <Box display="flex" flexDirection="column" mt={3}>
      <Box mb={3}>
        <Typography variant="h5">Basic Info</Typography>
      </Box>

      <LabeledInlineInput
        label="Name"
        labelSize="large"
        disabled
        value={vehicleName}
      />
      <LabeledInlineInput
        label="Type"
        labelSize="large"
        disabled
        value={typeName}
      />
      <LabeledInlineInput
        label="Passenger Capacity"
        labelSize="large"
        disabled
        value={capacity}
      />
      <LabeledInlineInput
        label="License Plate"
        labelSize="large"
        disabled
        value={licensePlate}
      />
      <LabeledInlineInput
        multiline
        label="Description"
        labelSize="large"
        disabled
        value={description}
      />
      <LabeledInlineInput
        label="Exterior Color"
        labelSize="large"
        disabled
        value={exteriorColor}
      />
    </Box>
  );
}

export default BasicInfoBlock;
