import { Dispatch, SetStateAction } from "react";
import { useMutation } from "@apollo/client";

import {
  CREATE_PAYMENT_METHOD_FOR_COMPANY,
  CREATE_PAYMENT_METHOD_FOR_CONTACT,
  CREATE_PAYMENT_METHOD_FOR_FARM_AFFILIATE,
  LOAD_COMPANY_QUERY,
  LOAD_CONTACT_QUERY,
  LOAD_GRIDDNET_OPERATOR_QUERY,
  LOAD_MOOVS_NETWORK_OPERATOR_QUERY,
  LOAD_MOOVS_AI_OPERATOR_QUERY,
} from "globals/graphql";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { fromGlobalId } from "globals/utils/helpers";
import { grayDark, white } from "design-system/colors";
import {
  ContactPayerProps,
  AffiliatePayerProps,
  CompanyPayerProps,
} from "../../types";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { FarmAffiliateVariantEnum } from "types";

const { MoovsNetworkOperator, GriddnetOperator } = FarmAffiliateVariantEnum;

type UseCreatePaymentMethodMutationProps = {
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
  setSubmitDisabled: Dispatch<SetStateAction<boolean>>;
  payer: ContactPayerProps | AffiliatePayerProps | CompanyPayerProps;
  handleClose: () => void;
};

export function useCreatePaymentMethodMutation(
  props: UseCreatePaymentMethodMutationProps
) {
  const { setSaveIndicatorState, setSubmitDisabled, payer, handleClose } =
    props;

  const { payerType, payerId } = payer;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // derived state
  const farmAffiliateVariant =
    payerType === "affiliate" ? payer.farmAffiliateVariant : null;
  const isCreatingContactPaymentMethod = payerType === "contact";
  const isCreatingCompanyPaymentMethod = payerType === "company";

  const mutation = isCreatingContactPaymentMethod
    ? CREATE_PAYMENT_METHOD_FOR_CONTACT
    : isCreatingCompanyPaymentMethod
    ? CREATE_PAYMENT_METHOD_FOR_COMPANY
    : CREATE_PAYMENT_METHOD_FOR_FARM_AFFILIATE;

  const [createPaymentMethod] = useMutation(mutation, {
    onError(error) {
      setSubmitDisabled(false);
      setSaveIndicatorState("error");

      const alternateErrorMessage = error.message
        ? `${error.message.replace(
            "GraphQL error:",
            "Error adding payment method:"
          )}`
        : "Error adding payment method";

      const errorMessage = getErrorMessage(error) || alternateErrorMessage;

      snackbar.error(errorMessage);
    },

    // contact payment method mutation
    ...(isCreatingContactPaymentMethod && {
      onCompleted(data) {
        const { stripeStatus } =
          data.createPaymentMethodForContact?.paymentMethod;

        handleComplete(stripeStatus);

        track("contact_creditCardAdded", {
          contact_id: fromGlobalId(payerId).id,
        });

        handleClose();
      },
      refetchQueries: [
        {
          query: LOAD_CONTACT_QUERY,
          variables: {
            id: payerId,
          },
        },
      ],
    }),

    // company payment method mutation
    ...(isCreatingCompanyPaymentMethod && {
      onCompleted(data) {
        const { stripeStatus } =
          data.createPaymentMethodForCompany?.paymentMethod;

        handleComplete(stripeStatus);

        track("company_creditCardAdded", {
          company_id: fromGlobalId(payerId).id,
        });

        handleClose();
      },
      refetchQueries: [
        {
          query: LOAD_COMPANY_QUERY,
          variables: {
            id: payerId,
          },
        },
      ],
    }),

    // affiliate payment methoid mutation
    ...(farmAffiliateVariant && {
      onCompleted(data) {
        const { stripeStatus } =
          data.createPaymentMethodForFarmAffiliate?.paymentMethod;

        handleComplete(stripeStatus);

        track("affiliate_creditCardAdded", {
          affiliate_id: fromGlobalId(payerId).id,
        });

        handleClose();
      },
      refetchQueries: [
        {
          query:
            farmAffiliateVariant === MoovsNetworkOperator
              ? LOAD_MOOVS_NETWORK_OPERATOR_QUERY
              : farmAffiliateVariant === GriddnetOperator
              ? LOAD_GRIDDNET_OPERATOR_QUERY
              : LOAD_MOOVS_AI_OPERATOR_QUERY,
          variables: {
            id: payerId,
          },
        },
      ],
    }),
  });

  const handleComplete = (stripeStatus) => {
    if (stripeStatus === "pending") {
      snackbar.info(
        "The card you entered requires authorization from the cardholder",
        { snackbarColor: white, iconColor: grayDark }
      );
    } else {
      snackbar.success("Successfully added card!");
    }

    setSaveIndicatorState("saved");
    setSubmitDisabled(false);
  };

  const onCreatePaymentMethod = ({ stripePaymentMethod, cardNote }) => {
    createPaymentMethod({
      variables: {
        input: {
          stripePaymentMethod,
          cardNote,
          ...(isCreatingContactPaymentMethod && { contactId: payerId }),
          ...(isCreatingCompanyPaymentMethod && { companyId: payerId }),
          ...(farmAffiliateVariant && { farmAffiliateId: payerId }),
        },
      },
    });
  };

  return {
    onCreatePaymentMethod,
  };
}
