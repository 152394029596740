import React, { useState } from "react";

import ContactAutoComplete from "../autocompletes/ContactAutoComplete";
import { Contact } from "../../types";
import MoovsDialog from "components/MoovsDialog";
import { useLaunchDarklyFlags } from "globals/utils/useLaunchDarklyFlags";

type ReplacePassengerDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleSave: (contact: Contact) => void;
  suggestedContacts?: Contact[];
  bookingContactLinkedPassengerIds?: string[];
};

function ReplacePassengerDialog(props: ReplacePassengerDialogProps) {
  const {
    open,
    handleClose,
    handleSave,
    suggestedContacts,
    bookingContactLinkedPassengerIds,
  } = props;
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);

  const { enableLinkedPassenger } = useLaunchDarklyFlags();

  const handleClickSave = () => {
    handleSave(selectedContact);
    setSelectedContact(null);
  };

  return (
    <MoovsDialog
      open={open}
      onClose={handleClose}
      onAccept={handleClickSave}
      hideTopBorder
      hideBottomBorder
      dialogTitle={"Replace Passenger"}
      acceptButtonText={"Save"}
      closeButtonText={"Cancel"}
      size="xs"
    >
      <ContactAutoComplete
        onChange={setSelectedContact}
        value={selectedContact}
        suggestedContacts={suggestedContacts}
        {...(enableLinkedPassenger &&
          bookingContactLinkedPassengerIds && {
            contactsOptionsGroupBy: {
              "ADD LINKED PASSENGER": bookingContactLinkedPassengerIds,
            },
          })}
      />
    </MoovsDialog>
  );
}

export default ReplacePassengerDialog;
