import React from "react";

import { Box, Typography } from "@mui/material";

import installWidget from "design-system/illustrations/installWidget.svg";
import CustomerPortalEducation from "design-system/images/CustomerPortalEducation.gif";
import {
  moovsBlue,
  moovsBlueLight,
  moovsBlueSelected,
  white,
} from "design-system/colors";
import { ArrowRightIcon } from "design-system/icons";
import { useScreenSize } from "globals/hooks";

function EducationSection() {
  // hooks
  const { isMediumTabletView } = useScreenSize();

  const renderFooter = () => (
    <Box mt={3} display="flex" alignItems="center">
      <Box
        style={{
          rotate: "90deg",
          borderRadius: "50%",
          backgroundColor: `${moovsBlueLight}20`,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          width: "40px",
          height: "40px",
          marginRight: "16px",
        }}
      >
        <ArrowRightIcon color={moovsBlue} strokeWeight="2.2" />
      </Box>
      <Typography variant="caption" fontSize={12} color={moovsBlue} width={200}>
        Follow the instructions below to get this onto your website.
      </Typography>
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection={isMediumTabletView ? "column" : "row"}
      bgcolor={moovsBlueSelected}
      border={`1px solid ${moovsBlueLight}`}
      borderRadius={2}
      padding={4}
      justifyContent="space-between"
    >
      <Box flex={0.48}>
        <Box
          borderRadius="50%"
          bgcolor={white}
          width={90}
          height={90}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img
            src={installWidget}
            style={{ marginTop: "10px", marginLeft: "4px" }}
            alt="install widget illustration"
          />
        </Box>
        <Box mt={3}>
          <Typography variant="h2">
            Enable customers to
            <br />
            book with you online
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="body2">
            The customer portal enables your customers to book transportation
            online. You can share the portal with clients, add it to your
            website as a widget, embed it as an iframe, or even use it as your
            own branded app!
          </Typography>
        </Box>
        {!isMediumTabletView && renderFooter()}
      </Box>
      <Box
        flex={0.48}
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={isMediumTabletView ? 4 : 0}
        flexDirection="column"
      >
        <Box>
          <img
            src={CustomerPortalEducation}
            alt="customer portal"
            width="100%"
            style={{
              maxHeight: "300px",
              borderRadius: "4px",
              border: `1px solid ${moovsBlueLight}50`,
              boxShadow:
                "0px 24px 24px -4px rgba(30, 30, 30, 0.02),  0px 8px 10px -2.5px rgba(30, 30, 30, 0.02)",
            }}
          />
          {isMediumTabletView && renderFooter()}
        </Box>
      </Box>
    </Box>
  );
}

export default EducationSection;
