/**
 * @file InlineInputs.tsx
 * Swoop-specific inline inputs.
 *
 * components:
 *  InlineInput,
 *  DollarInlineInput,
 *  InlineSelect
 *
 * author: sienag
 */

import React, { ReactNode } from "react";

import {
  InputAdornment,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Select,
  Typography,
  InputProps as MuiInputProps,
  MenuItem,
  Theme,
} from "@mui/material";

import { withStyles, createStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { black, grayMedium, alabaster, white, grayDark } from "../../../colors";
import { NumberFormatDollar } from "../NumberFormat";

const outlinedInputStyles = {
  fontSize: "14px",
  backgroundColor: "white",
  "& .expand-more-icon": {
    fill: "transparent",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid transparent",
  },
  ":hover": {
    "& .expand-more-icon": {
      fill: grayMedium,
      transition: "all 0.3s",
      transitionDelay: "0s",
    },
  },
  ":hover:not(.Mui-error)": {
    ".MuiOutlinedInput-notchedOutline": {
      transition: "all 0.3s",
      transitionDelay: "0s",
      borderColor: grayMedium,
    },
  },
  "&.Mui-focused:not(.Mui-error)": {
    ".MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: black,
    },
  },
  "&.Mui-focused": {
    backgroundColor: alabaster + "!important",
    "& .expand-more-icon": {
      fill: black + "!important",
    },
  },
} as const;

const StyledSelect = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: white,
      "&:focus": {
        backgroundColor: alabaster,
      },
    },
  })
)(Select);

type InlineInputProps = {
  startAdornment?: string;
  endAdornment?: string | ReactNode;
  name?: string;
  value?: any;
  onChange?: any;
  type?: string;
  placeholder?: string;
  required?: boolean;
  errorText?: string;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
  InputProps?: MuiInputProps;
  inputProps?: any;
  error?: boolean;
};

function InlineInput(props: InlineInputProps) {
  const {
    startAdornment,
    endAdornment,
    name,
    value,
    onChange,
    type,
    placeholder,
    required = false,
    errorText,
    multiline,
    rows,
    disabled,
    InputProps,
    inputProps,
    error: inputError,
  } = props;
  const error = (required && !value) || inputError;

  const startAdornmentNode = (
    <InputAdornment position="start">
      <Typography>{startAdornment}</Typography>
    </InputAdornment>
  );

  const endAdornmentNode =
    typeof endAdornment === "string" ? (
      <Typography display="inline" variant="body2" color={grayDark}>
        {endAdornment}
      </Typography>
    ) : (
      endAdornment
    );

  return (
    <FormControl error={error || false} fullWidth>
      <OutlinedInput
        fullWidth
        name={name}
        value={value || value === 0 ? value : ""}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        multiline={multiline}
        rows={rows}
        disabled={disabled}
        sx={outlinedInputStyles}
        inputProps={{ ...inputProps }}
        {...(startAdornment && { startAdornment: startAdornmentNode })}
        {...(endAdornment && { endAdornment: endAdornmentNode })}
        {...InputProps}
      />
      <FormHelperText>{error ? errorText : ""}</FormHelperText>
    </FormControl>
  );
}

type DollarInputLabeledInlineProps = {
  errorText?: string;
  name?: string;
  value?: any;
  onChange?: any;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
};

function DollarInlineInput(props: DollarInputLabeledInlineProps) {
  const { value, onChange, errorText, placeholder, required, name, disabled } =
    props;

  return (
    <InlineInput
      name={name}
      placeholder={placeholder}
      required={required}
      errorText={errorText}
      disabled={disabled}
      value={value}
      onChange={onChange}
      InputProps={{
        inputComponent: NumberFormatDollar as any,
      }}
    />
  );
}

type InlineSelectProps = {
  name?: string;
  value?: any;
  onChange?: any;
  type?: string;
  placeholder?: string;
  children?: any;
  required?: boolean;
  errorText?: string;
  disabled?: boolean;
  renderValue?: (value: any) => any;
};

function InlineSelect(props: InlineSelectProps) {
  const {
    name,
    value,
    onChange,
    type,
    placeholder,
    children,
    required,
    errorText,
    disabled,
    renderValue,
  } = props;
  const error = required && !value;

  return (
    <FormControl error={error} fullWidth>
      <StyledSelect
        disabled={disabled}
        defaultValue=""
        displayEmpty
        IconComponent={() => <ExpandMoreIcon className="expand-more-icon" />}
        input={<OutlinedInput fullWidth sx={outlinedInputStyles} />}
        inputProps={{
          "aria-label": "Without label",
        }}
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        renderValue={renderValue}
      >
        <MenuItem value={""} disabled>
          {placeholder}
        </MenuItem>
        {children}
      </StyledSelect>
      <FormHelperText>{error ? errorText : ""}</FormHelperText>
    </FormControl>
  );
}

export { InlineInput, DollarInlineInput, InlineSelect };
