import { useState } from "react";
import { useMutation } from "@apollo/client";

import { UPDATE_REQUEST_COMPANY_LINKING } from "globals/graphql";
import { useSnackbar } from "./useSnackbar";
import { useAnalytics } from ".";
import { RequestStage } from "types";

type UseRequestCompanyLinkingProps = {
  requestId: string;
  stage: RequestStage;
};

function useRequestCompanyLinking(props: UseRequestCompanyLinkingProps) {
  const { requestId, stage } = props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // state
  // loading here instead of mutation so that it still shows as loading while cache updates
  const [linkCompanyLoading, setLinkCompanyLoading] = useState(false);

  // mutations
  const [linkCompanyToRequest] = useMutation(UPDATE_REQUEST_COMPANY_LINKING, {
    onCompleted: () => {
      snackbar.success("Successfully linked company to order.");
    },
    onError: () => {
      setLinkCompanyLoading(false);
      snackbar.error("Error linking company to order.");
    },
  });

  const [unlinkCompanyToRequest] = useMutation(UPDATE_REQUEST_COMPANY_LINKING, {
    onCompleted: () => {
      snackbar.success("Successfully unlinked company from order.");
    },
    onError: () => {
      setLinkCompanyLoading(true);
      snackbar.error("Error unlinking company from order.");
    },
  });

  // event handlers
  const handleLinkCompanyToRequest = () => {
    setLinkCompanyLoading(true);

    stage === RequestStage.Quote
      ? track("quote_companyAdded")
      : track("reservation_companyAdded");

    linkCompanyToRequest({
      variables: {
        input: {
          requestId,
          linkCompany: true,
        },
      },
    });
  };

  const handleUnlinkCompanyToRequest = () => {
    setLinkCompanyLoading(true);

    stage === RequestStage.Quote
      ? track("quote_companyRemoved")
      : track("reservation_companyRemoved");

    unlinkCompanyToRequest({
      variables: {
        input: {
          requestId,
          linkCompany: false,
        },
      },
    });
  };

  return {
    onLinkCompanyToRequest: handleLinkCompanyToRequest,
    onUnlinkCompanyToRequest: handleUnlinkCompanyToRequest,
    linkCompanyLoading,
  };
}

export { useRequestCompanyLinking };
