import React, { ChangeEventHandler } from "react";

import { Box, TextField, Typography } from "@mui/material";

import { grayDark } from "design-system/colors";
import { FarmRelationshipEnum, OperatorRoute } from "types";

type CloseOutNotesProps = {
  note: string;
  onNoteChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  operatorRoute: OperatorRoute;
  noteError: string;
};

const { Farmor, Farmee } = FarmRelationshipEnum;

function CloseOutNotes(props: CloseOutNotesProps) {
  const { note, onNoteChange, operatorRoute, noteError } = props;
  const {
    driverCloseoutNote,
    farmeeCloseoutNote,
    farmRelationship,
    operatorCloseoutNote,
  } = operatorRoute || {};

  const noteValue =
    note ||
    (farmRelationship === Farmor && operatorCloseoutNote) ||
    (farmRelationship === Farmee && farmeeCloseoutNote) ||
    "";

  return (
    <>
      <Box mt={2}>
        <Typography variant="h4">Close Out Notes</Typography>
      </Box>
      <Box>
        {driverCloseoutNote && (
          <>
            <Typography mt={2} variant="h5" style={{ color: grayDark }}>
              DRIVER CLOSE OUT NOTE
            </Typography>
            <Typography variant="body2">{driverCloseoutNote}</Typography>
          </>
        )}
        {farmRelationship === Farmor && farmeeCloseoutNote && (
          <>
            <Typography mt={2} variant="h5" style={{ color: grayDark }}>
              AFFILIATE CLOSE OUT NOTE
            </Typography>
            <Typography variant="body2">{farmeeCloseoutNote}</Typography>
          </>
        )}
      </Box>
      <Box mt={2} mb={1}>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Please provide any comments about the trip here..."
          multiline
          rows={2}
          inputProps={{ maxLength: 3000 }}
          value={noteValue}
          onChange={onNoteChange}
          error={!!noteError}
          helperText={noteError}
        />
      </Box>
    </>
  );
}

export default CloseOutNotes;
