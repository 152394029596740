import React from "react";

import { AddressIcon, AirportIcon } from "../../design-system/icons";
import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";

type AddressAirportToggleProps = {
  value: "address" | "airport";
  onChange: (_: any, newType: "address" | "airport") => void;
};

function AddressAirportToggle(props: AddressAirportToggleProps) {
  const { value, onChange } = props;

  const toggleButtons = [
    {
      value: "address",
      ariaLabel: "address",
      icon: AddressIcon,
    },
    {
      value: "airport",
      ariaLabel: "airport",
      icon: AirportIcon,
    },
  ];

  return (
    <MoovsToggleButtonGroup
      onChange={onChange}
      options={toggleButtons}
      value={value}
    />
  );
}

export default AddressAirportToggle;
