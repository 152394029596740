import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { Box, Button, Typography, CircularProgress } from "@mui/material";

import {
  moovsBlueLight,
  granite,
  errorRed,
} from "../../../design-system/colors";

import { storage } from "../../../globals/utils/firebaseApp";
import generateId from "../../../globals/utils/generateId";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import InsuranceAttachmentsList from "./InsuranceAttachmentsList";
import { InsuranceAttachment } from "../../../types";
import {
  CREATE_INSURANCE_ATTACHMENT_MUTATION,
  REMOVE_INSURANCE_ATTACHMENT_MUTATION,
} from "../../../globals/graphql";
import { primaryDisabledColor } from "../../../theme";

// kinda hacky way to parse filename from url
export const parseString = "8OI2V";

const styles = {
  photoBox: {
    height: "158px",
    backgroundColor: primaryDisabledColor,
    borderRadius: "4px",
    cursor: "pointer",
    transition: ".2s",
    overflow: "hidden",
    "&:hover": {
      backgroundColor: moovsBlueLight,
    },
  },
};

type InsuranceAttachmentsBlockProps = {
  onAttachmentsChange?: Function;
  mode?: "create" | "update";
  insuranceAttachments?: InsuranceAttachment[];
  setSaveIndicatorState?: Function;
  insuranceId?: string;
  refetch?: Function;
};

function InsuranceAttachmentsBlock(props: InsuranceAttachmentsBlockProps) {
  const {
    onAttachmentsChange,
    mode = "create",
    insuranceAttachments = [],
    setSaveIndicatorState,
    insuranceId,
  } = props;

  const snackbar = useSnackbar();

  // state
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // mutations
  const [createInsuranceAttachment] = useMutation(
    CREATE_INSURANCE_ATTACHMENT_MUTATION,
    {
      onCompleted(data) {
        setSaveIndicatorState("saved");
      },
      onError() {
        setSaveIndicatorState("error");
        snackbar.error("Error adding insurance attachment");
      },
    }
  );

  const [removeInsuranceAttachment] = useMutation(
    REMOVE_INSURANCE_ATTACHMENT_MUTATION,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError() {
        setSaveIndicatorState("error");
        snackbar.error("Error removing insurance attachment ");
      },
    }
  );

  // event handlers
  const handleFileUpload = async (e: any) => {
    try {
      const file = e.target.files[0];
      if (!file) return;

      if (file.size >= 5000000) {
        setError(
          "File size maximum of 5 MB exceeded. Please upload a smaller file."
        );

        return;
      }

      setError("");
      setLoading(true);

      const newFileName = file.name.split(" ").join("_");

      const randomId = generateId(8);
      const imagesRef = ref(
        storage,
        `insurance/${randomId}_${parseString}${newFileName}${parseString}`
      );

      // upload photo to firebase
      const snapshot = await uploadBytes(imagesRef, file);
      const url = await getDownloadURL(snapshot.ref);

      return url;
    } catch (err) {
      snackbar.error("error uploading file");
    }
  };

  const handleAddAttachment = async (file) => {
    const url = await handleFileUpload(file);
    if (!url) return;
    const newAttachments = [...attachments, { url }];

    setLoading(false);
    setAttachments(newAttachments);

    if (mode === "create") {
      onAttachmentsChange(newAttachments);
    } else if (mode === "update") {
      setSaveIndicatorState("loading");

      createInsuranceAttachment({
        variables: {
          input: {
            insuranceId,
            url,
          },
        },
      });
    }
  };

  const handlePressRemove = (
    removedAttachment: Partial<InsuranceAttachment>
  ) => {
    const newAttachments = attachments.filter((attachment) =>
      mode === "create"
        ? attachment.url !== removedAttachment.url
        : attachment.id !== removedAttachment.id
    );

    if (mode === "create") {
      onAttachmentsChange(newAttachments);
    } else if (mode === "update") {
      setSaveIndicatorState("loading");

      removeInsuranceAttachment({
        variables: {
          input: {
            id: removedAttachment.id,
          },
        },
      });
    }

    setAttachments(newAttachments);
  };

  useEffect(() => {
    setAttachments(insuranceAttachments);
  }, [insuranceAttachments, setAttachments]);

  return (
    <Box>
      <Box mt={4} mb={2}>
        <Typography variant="h5">Attachments</Typography>
      </Box>

      <input
        accept=".pdf,.png,.jpg,.jpeg"
        id="insurance-attachment-button"
        type={attachments.length < 3 ? "file" : "hidden"}
        style={{ display: "none" }}
        onChange={handleAddAttachment}
      />
      {/* Attachments Exists */}
      {!!attachments.length && (
        <>
          <InsuranceAttachmentsList
            attachments={attachments}
            onPressRemove={handlePressRemove}
          />
          <input
            accept=".pdf,.png,.jpg,.jpeg"
            id="insurance-attachment-button"
            type={attachments.length < 3 ? "file" : "hidden"}
            style={{ display: "none" }}
            onChange={attachments.length < 3 ? handleAddAttachment : undefined}
          />
          <Box
            my={2}
            display="flex"
            width="100%"
            justifyContent="space-between"
          >
            {/* Error */}
            <div>
              {!!error && (
                <Typography style={{ color: errorRed }}>{error}</Typography>
              )}
            </div>
            <label htmlFor="insurance-attachment-button">
              <Button
                variant="outlined"
                color="primary"
                component="span"
                disabled={attachments.length >= 3}
              >
                {loading && !error ? (
                  <CircularProgress size={20} thickness={2} />
                ) : (
                  "Add File"
                )}
              </Button>
            </label>
          </Box>
        </>
      )}

      {/* Empty State */}
      {!attachments.length && (
        <label htmlFor="insurance-attachment-button">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            sx={styles.photoBox}
          >
            {/* Uploading New Photo */}
            {loading && !error && <CircularProgress />}

            {/* Empty State */}
            {!attachments.length && !error && !loading && (
              <>
                <input
                  accept=".pdf,.png,.jpg,.jpeg"
                  id="insurance-attachment-button"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleAddAttachment}
                />
                <label htmlFor="insurance-attachment-button">
                  <Button variant="outlined" color="primary" component="span">
                    Add File
                  </Button>
                </label>
              </>
            )}
            <Box mt={2} textAlign="center">
              <Typography
                variant="caption"
                style={{ color: granite, fontSize: "12px", fontWeight: 500 }}
              >
                Click to upload an insurance document.
              </Typography>
              <Typography style={{ color: granite, fontSize: "12px" }}>
                PDF/JPEG/PNG
              </Typography>
            </Box>
          </Box>
        </label>
      )}
    </Box>
  );
}

export default InsuranceAttachmentsBlock;
