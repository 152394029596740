import React from "react";

type TrashIconProps = {
  color?: string;
  size?: "small";
};

const TrashIcon = (props: TrashIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 4.4H4.11111M4.11111 4.4H13M4.11111 4.4V12.8C4.11111 13.1183 4.22817 13.4235 4.43655 13.6485C4.64492 13.8736 4.92754 14 5.22222 14H10.7778C11.0725 14 11.3551 13.8736 11.5635 13.6485C11.7718 13.4235 11.8889 13.1183 11.8889 12.8V4.4H4.11111ZM5.77778 4.4V3.2C5.77778 2.88174 5.89484 2.57652 6.10322 2.35147C6.31159 2.12643 6.5942 2 6.88889 2H9.11111C9.4058 2 9.68841 2.12643 9.89679 2.35147C10.1052 2.57652 10.2222 2.88174 10.2222 3.2V4.4"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrashIcon;
