import React from "react";

import { Box, Typography } from "@mui/material";

import { alabaster } from "design-system/colors";
import { currency } from "globals/utils/helpers";

type TotalDisplayProps = {
  totalAmount: number;
};

function TotalDisplay(props: TotalDisplayProps) {
  const { totalAmount } = props;

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-end"
      style={{ backgroundColor: alabaster }}
      p={2}
    >
      <Box flex=".5">
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: 600,
          }}
        >
          Total
        </Typography>
      </Box>
      <Box flex=".5" textAlign="right"></Box>
      <Box flex="1" textAlign="right">
        <Typography variant="subtitle1">{currency(totalAmount)}</Typography>
      </Box>
    </Box>
  );
}

export default TotalDisplay;
