import React from "react";
import { Box } from "@mui/material";
import { CheckIcon } from ".";
import { successGreen, tintGreen } from "../colors";

function CircleGreenCheckIcon() {
  return (
    <>
      <Box
        display="flex"
        height="24px"
        minWidth="24px"
        justifyContent="center"
        alignItems="center"
        borderRadius="50%"
        bgcolor={tintGreen}
      >
        <Box height="16px" width="16px">
          <CheckIcon color={successGreen} size="small" />
        </Box>
      </Box>
    </>
  );
}

export default CircleGreenCheckIcon;
