import React from "react";
import first from "lodash/first";

import { Box } from "@mui/material";
import EditTripActionButton from "./components/EditTripActionButton";
import SendEmailActionButton from "./components/SendEmailActionButton";
import ChargeReservationActionButton from "./components/ChargeReservationActionButton";
import UpdatePricingActionButton from "./components/UpdatePricingActionButton";
import ViewCopyLinksDialogActionButton from "./components/ViewCopyLinksDialogActionButton";
import ViewMoreActionButton from "./components/ViewMoreActionButton/ViewMoreActionButton";
import { Trip, Request, TripCategory, FarmAffiliateVariantEnum } from "types";
import { deriveIsFarmee } from "globals/utils/farm";

type ActionButtonPanelDesktopProps = {
  request: Request;
  trip: Trip;
};

function ActionButtonPanelDesktop(props: ActionButtonPanelDesktopProps) {
  const { request, trip } = props;

  // derived state
  const route = first(trip.routes);
  const isFarmee = deriveIsFarmee(route);
  const isShuttleReservation =
    trip.tripCategory === TripCategory.ShuttlePickUp ||
    trip.tripCategory === TripCategory.ShuttleReturn;
  const farmAffiliateIsMoovsAi =
    route.farmAffiliateVariant === FarmAffiliateVariantEnum.MoovsAiOperator;

  return (
    <Box display="flex" flexDirection="row">
      {isFarmee ? (
        <>
          {farmAffiliateIsMoovsAi && (
            <EditTripActionButton
              requestId={request.id}
              tripId={trip.id}
              route={route}
            />
          )}
          <ChargeReservationActionButton request={request} trip={trip} />
          <UpdatePricingActionButton request={request} trip={trip} />
          <ViewCopyLinksDialogActionButton requestId={request.id} trip={trip} />
          <ViewMoreActionButton request={request} trip={trip} />
        </>
      ) : (
        <>
          <EditTripActionButton
            requestId={request.id}
            tripId={trip.id}
            isShuttleReservation={isShuttleReservation}
            route={route}
          />
          <SendEmailActionButton request={request} trip={trip} />
          <ChargeReservationActionButton request={request} trip={trip} />
          <UpdatePricingActionButton request={request} trip={trip} />
          <ViewCopyLinksDialogActionButton requestId={request.id} trip={trip} />
          <ViewMoreActionButton request={request} trip={trip} />
        </>
      )}
    </Box>
  );
}

export default ActionButtonPanelDesktop;
