import React, { useState, ChangeEvent, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useElements } from "@stripe/react-stripe-js";
import upperFirst from "lodash/upperFirst";

import { TextField, Box, Typography, Grid, Divider } from "@mui/material";

import MoovsDialog from "../../MoovsDialog";
import { CardCheckEnum, PaymentMethod } from "../../../types";
import {
  LOAD_PAYMENT_METHOD_QUERY,
  UPDATE_PAYMENT_METHOD_MUTATION,
} from "globals/graphql";
import {
  errorRed,
  grayDark,
  successGreen,
  warningYellow,
} from "design-system/colors";
import {
  CheckIcon,
  CrossIcon,
  ViewOffIcon,
  WarningIcon,
} from "design-system/icons";
import GQLQueryStatusIndicator from "components/GQLQueryStatusIndicator";
import { useScreenSize, useStripeBillingDetailsElement } from "globals/hooks";
import theme from "theme";

const styles = {
  column1: {
    height: 32,
    display: "flex",
    alignItems: "start",
    [theme.breakpoints.up("sm")]: {
      height: 48,
      alignItems: "center",
    },
  },
  column2: {
    height: 48,
    alignItems: "start",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  labelGridItem: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(0),
    },
  },
  valueGridItem: {
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(3),
    },
  },
  label: {
    color: grayDark,
    marginBottom: 0,
  },
};

type EditCreditCardDialogProps = {
  id: string;
  payerId: string;
  open: boolean;
  onClose: () => void;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
};

function EditCreditCardDialog(props: EditCreditCardDialogProps) {
  const { id, onClose, open, payerId } = props;

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(
    null
  );
  const [hasBillingAddress, setHasBillingAddress] = useState(false);
  const zipCodeCheck = paymentMethod?.card?.zipCodeCheck;
  const cvcCheck = paymentMethod?.card?.cvcCheck;

  // hooks
  const elements = useElements();
  const { BillingDetailsElement, getBillingDetails } =
    useStripeBillingDetailsElement();

  const { data, refetch, loading, error } = useQuery<{ node: PaymentMethod }>(
    LOAD_PAYMENT_METHOD_QUERY,
    {
      variables: { id: id },
      skip: !id || !open,
    }
  );

  const [updatePaymentMethod, { loading: updateLoading }] = useMutation(
    UPDATE_PAYMENT_METHOD_MUTATION,
    {
      onCompleted: () => {
        elements?.getElement("address")?.clear();

        props.onSuccess(
          `****-${paymentMethod?.card.last4} information is updated`
        );
      },
      onError: (error) => {
        props.onError(error.message || "Card information could not be updated");
      },
    }
  );
  const { isMobileView } = useScreenSize();

  useEffect(() => {
    if (data?.node) {
      setPaymentMethod(data.node);
      if (data.node.billingDetails?.line1) setHasBillingAddress(true);
    }
  }, [data]);

  useEffect(() => {
    if (id && open) {
      refetch();
    }
  }, [open, id, refetch]);

  // event handlers
  const handleSaveCard = async () => {
    if (!elements) {
      // Stripe elements has not loaded yet.
      return;
    }

    let billingParams;

    if (hasBillingAddress) {
      const billingDetails = await getBillingDetails();
      if (!billingDetails) {
        return;
      }

      const {
        name,
        phone,
        address: { line1, line2, city, state, country, postal_code },
      } = billingDetails;

      billingParams = {
        name,
        phone,
        line1,
        line2,
        city,
        state,
        country,
        postalCode: postal_code,
      };
    } else {
      billingParams = {
        postalCode: paymentMethod.billingDetails?.postalCode,
      };
    }

    updatePaymentMethod({
      variables: {
        input: {
          paymentMethodId: props.id,
          payerId,
          cardNote: paymentMethod.cardNote,
          expMonth: paymentMethod.card?.expMonth,
          expYear: paymentMethod.card?.expYear,
          // billing info from stripe elements or postalCode from input
          ...billingParams,
        },
      },
    });
  };

  const handleCardNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod({ ...paymentMethod, cardNote: event.target.value });
  };

  const handleChangePostalCode = (event: ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod({
      ...paymentMethod,
      billingDetails: {
        ...paymentMethod?.billingDetails,
        postalCode: event.target.value,
      },
    });
  };

  const handleChangeExpMonth = (event: ChangeEvent<HTMLInputElement>) => {
    const expMonth = isNaN(Number(event.target.value))
      ? paymentMethod?.card.expMonth
      : Number(event.target.value);

    if (expMonth > 12) return;

    setPaymentMethod({
      ...paymentMethod,
      card: {
        ...paymentMethod?.card,
        expMonth,
      },
    });
  };
  const handleChangeExpYear = (event: ChangeEvent<HTMLInputElement>) => {
    const expYear = isNaN(Number(event.target.value))
      ? paymentMethod?.card.expYear
      : Number(event.target.value);

    setPaymentMethod({
      ...paymentMethod,
      card: {
        ...paymentMethod?.card,
        expYear,
      },
    });
  };

  const handleOnClose = () => {
    elements?.getElement("address")?.clear();

    onClose();
  };

  const getCheckIcon = (name: CardCheckEnum) => {
    return {
      PASSED: <CheckIcon size="small" color={successGreen} />,
      FAILED: <WarningIcon size="small" color={errorRed} />,
      UNCHECKED: <ViewOffIcon size="small" color={warningYellow} />,
      UNAVAILABLE: <CrossIcon size="small" color={grayDark} />,
    }[name];
  };

  const getCheckColor = (name: CardCheckEnum) => {
    return {
      PASSED: successGreen,
      FAILED: errorRed,
      UNCHECKED: warningYellow,
      UNAVAILABLE: grayDark,
    }[name];
  };

  return (
    <MoovsDialog
      open={open}
      onClose={handleOnClose}
      dialogTitle="Edit Card Information"
      acceptDisabled={updateLoading}
      acceptButtonText="Save"
      onAccept={handleSaveCard}
      size="sm"
      fixedFooter
      hideTopBorder={!isMobileView}
    >
      <GQLQueryStatusIndicator
        name="Payment Method"
        loading={loading}
        data={paymentMethod}
        error={error}
      />

      <Grid container>
        <Grid item xs={12} sm={12}>
          <Typography variant="h5">Card Information</Typography>
        </Grid>

        <Grid item xs={12} sm={4} sx={styles.column1}>
          <Typography variant="h5" sx={styles.label}>
            CARD NUMBER
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} sx={styles.column2}>
          <Typography variant="body1">
            ****-{paymentMethod?.card.last4}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} sx={styles.column1}>
          <Typography variant="h5" sx={styles.label}>
            BRAND
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} sx={styles.column2}>
          <Typography variant="body1">
            {upperFirst(paymentMethod?.card?.brand) || "-"}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} sx={styles.column1}>
          <Typography variant="h5" sx={styles.label}>
            ID
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} sx={styles.column2}>
          <Typography variant="body1">
            {paymentMethod?.stripeId || "-"}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} sx={styles.column1}>
          <Typography variant="h5" sx={styles.label}>
            EMAIL
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} sx={styles.column2}>
          <Typography variant="body1">
            {paymentMethod?.billingDetails?.email || "-"}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} sx={styles.column1}>
          <Typography variant="h5" sx={styles.label}>
            CVC CHECK
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} sx={styles.column2}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
          >
            <Box
              mr={cvcCheck ? 1 : 0}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              {getCheckIcon(cvcCheck)}
            </Box>

            <Typography
              variant="body1"
              style={{ color: getCheckColor(cvcCheck) }}
            >
              {upperFirst(cvcCheck) || "-"}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} sx={styles.column1}>
          <Typography variant="h5" sx={styles.label}>
            ZIP CHECK
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} sx={styles.column2}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
          >
            <Box
              mr={zipCodeCheck ? 1 : 0}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              {getCheckIcon(zipCodeCheck)}
            </Box>

            <Typography
              variant="body1"
              style={{ color: getCheckColor(zipCodeCheck) }}
            >
              {upperFirst(zipCodeCheck) || "-"}
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={[styles.column1, styles.labelGridItem]}
          style={{ marginTop: 24 }}
        >
          <Typography variant="h5" sx={styles.label}>
            Expiry Mo / Year
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} sx={[styles.column2, styles.valueGridItem]}>
          <Box display="flex" flexDirection="row">
            <TextField
              value={paymentMethod?.card?.expMonth || ""}
              type="text"
              variant="outlined"
              style={{ width: 64 }}
              onChange={handleChangeExpMonth}
              error={!paymentMethod ? false : !paymentMethod?.card?.expMonth}
              data-testId="expiration-month"
            />
            <TextField
              value={paymentMethod?.card?.expYear || ""}
              type="text"
              variant="outlined"
              style={{ width: 80, marginLeft: 16 }}
              onChange={handleChangeExpYear}
              error={
                !paymentMethod
                  ? false
                  : paymentMethod?.card?.expYear < new Date().getFullYear()
              }
              data-testId="expiration-year"
            />
          </Box>
        </Grid>

        {hasBillingAddress ? (
          <Grid item xs={12} sm={12} sx={{ mt: 3 }}>
            <BillingDetailsElement
              defaultValues={
                paymentMethod?.billingDetails
                  ? {
                      name: paymentMethod.billingDetails.name,
                      address: {
                        line1: paymentMethod.billingDetails.line1,
                        line2: paymentMethod.billingDetails.line2,
                        city: paymentMethod.billingDetails.city,
                        state: paymentMethod.billingDetails.state,
                        postal_code: paymentMethod.billingDetails.postalCode,
                        country: paymentMethod.billingDetails.country,
                      },
                      phone: paymentMethod.billingDetails.phone,
                    }
                  : {}
              }
            />
          </Grid>
        ) : (
          <>
            <Grid
              item
              xs={12}
              sm={4}
              sx={[styles.column1, styles.labelGridItem]}
            >
              <Typography variant="h5" sx={styles.label}>
                POSTAL CODE
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              sx={[styles.column2, styles.valueGridItem]}
            >
              <TextField
                value={paymentMethod?.billingDetails?.postalCode || ""}
                type="text"
                variant="outlined"
                onChange={handleChangePostalCode}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} style={{ marginTop: 32 }}>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={4} sx={[styles.column1, styles.labelGridItem]}>
          <Typography variant="h5" sx={styles.label}>
            NOTES
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} sx={[styles.column2, styles.valueGridItem]}>
          <TextField
            value={paymentMethod?.cardNote || ""}
            type="text"
            variant="outlined"
            placeholder="Enter Note"
            multiline
            fullWidth
            onChange={handleCardNoteChange}
          />
        </Grid>

        <Grid item xs={12} sx={styles.labelGridItem}></Grid>
      </Grid>
    </MoovsDialog>
  );
}

export default EditCreditCardDialog;
