import { ChangeEvent, useEffect, useState } from "react";

type UseEmailMessageProps = {
  open: boolean;
};

function useEmailMessage(props: UseEmailMessageProps) {
  const { open } = props;

  // state
  const [message, setMessage] = useState("");

  // event handlers
  const handleMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  // effects
  // reset message on dialog open
  useEffect(() => {
    if (open) {
      setMessage("");
    }
  }, [open]);

  return { message, onMessageChange: handleMessageChange };
}

export default useEmailMessage;
