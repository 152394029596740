import React from "react";

type InvoiceIconProps = {
  color?: string;
  size?: "small";
};

const InvoiceIcon = (props: InvoiceIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.30166 1.74219C8.51912 1.74223 8.72767 1.82644 8.88149 1.97632L12.0935 5.10805C12.2472 5.25803 12.3336 5.46136 12.3337 5.67339M3.7781 7.85813C3.89842 8.03112 4.05506 8.16982 4.23474 8.26245C4.41443 8.35509 4.61187 8.39894 4.81033 8.39029C5.4431 8.39029 5.95644 7.96882 5.95644 7.44942C5.95644 6.93003 5.44477 6.50917 4.81255 6.50917C4.18033 6.50917 3.66699 6.08769 3.66699 5.56769C3.66699 5.04769 4.18033 4.62683 4.81255 4.62683M4.81255 4.62683C5.01102 4.61802 5.20851 4.6618 5.38822 4.75445C5.56793 4.8471 5.72455 4.98588 5.84477 5.15899M4.81255 4.62683V3.99979M4.81255 8.39029V9.01733M7.00033 9.92961H10.3337M4.08366 11.9998H10.3337M12.3337 13.8665C12.3337 14.0786 12.2472 14.2821 12.0933 14.4321C11.9395 14.5822 11.7308 14.6665 11.5131 14.6665H2.4875C2.26989 14.6665 2.06119 14.5822 1.90731 14.4321C1.75344 14.2821 1.66699 14.0786 1.66699 13.8665V2.54219C1.66699 2.33001 1.75344 2.12653 1.90731 1.9765C2.06119 1.82647 2.26989 1.74219 2.4875 1.74219H8.23109V4.94219C8.23109 5.15436 8.31754 5.35784 8.47142 5.50787C8.62529 5.6579 8.83399 5.74219 9.05161 5.74219H12.3337L12.3337 13.8665Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.952 2.6136C14.2782 2.61367 14.591 2.73999 14.8217 2.9648L19.6398 7.6624C19.8704 7.88736 19.9999 8.19236 20 8.5104M20 20.8C20 21.1183 19.8703 21.4235 19.6395 21.6485C19.4087 21.8736 19.0957 22 18.7692 22H5.23077C4.90435 22 4.5913 21.8736 4.36048 21.6485C4.12967 21.4235 4 21.1183 4 20.8V3.8136C4 3.49534 4.12967 3.19012 4.36048 2.96507C4.5913 2.74003 4.90435 2.6136 5.23077 2.6136H13.8462V7.4136C13.8462 7.73186 13.9758 8.03709 14.2066 8.26213C14.4375 8.48717 14.7505 8.6136 15.0769 8.6136H20L20 20.8Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.16667 11.7875C7.34714 12.047 7.5821 12.255 7.85163 12.394C8.12116 12.533 8.41731 12.5987 8.715 12.5858C9.66417 12.5858 10.4342 11.9535 10.4342 11.1745C10.4342 10.3954 9.66667 9.76407 8.71833 9.76407C7.77 9.76407 7 9.13186 7 8.35186C7 7.57186 7.77 6.94056 8.71833 6.94056M8.71833 6.94056C9.01605 6.92735 9.31228 6.99303 9.58184 7.132C9.85141 7.27097 10.0863 7.47914 10.2667 7.73881M8.71833 6.94056V6M8.71833 12.5858V13.5263M12 14.8947H17M7.625 18H17"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InvoiceIcon;
