import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";

import { Box } from "@mui/material";

import SelectableImageWrapper from "./SelectableImageWrapper";
import { usePrevious } from "../../../../globals/hooks";

type SelectableImagesListProps = {
  selectedImageIndices: any;
  selectedImages: any[];
  onImageSelect: (photoIndex: number, isSelected: boolean) => void;
};

function SelectableImagesList(props: SelectableImagesListProps) {
  const { selectedImageIndices, selectedImages, onImageSelect } = props;

  // hooks
  const prevSelectedImages = usePrevious(selectedImages);
  const isNewImageList = !isEqual(selectedImages, prevSelectedImages);

  // state
  const [areImagesLoaded, setAreImagesLoaded] = useState(
    [...Array(selectedImages.length)].map(() => false)
  );

  // event handlers
  const handleImageLoaded = (index: number) => () => {
    const shallowAreImagesLoaded = [...areImagesLoaded];
    shallowAreImagesLoaded[index] = true;
    setAreImagesLoaded(shallowAreImagesLoaded);
  };

  // effects
  useEffect(() => {
    // resets image loading states when rendering new list of vehicle photos
    if (isNewImageList) {
      setAreImagesLoaded([...Array(selectedImages.length)].map(() => false));
    }
  }, [selectedImages, isNewImageList]);

  const allImagesLoaded = areImagesLoaded.every((loadState) => loadState);

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignContent="flex-start"
      justifyContent="flex-start"
      p={1}
      flex={1}
    >
      {selectedImages.map((photo, index) => (
        <Box m={1} key={index}>
          <SelectableImageWrapper
            photo={photo}
            isSelected={selectedImageIndices[photo.photoIndex]}
            onImageSelect={onImageSelect}
            onImageLoaded={handleImageLoaded(index)}
            allImagesLoaded={allImagesLoaded}
            isNewImage={isNewImageList}
          />
        </Box>
      ))}
    </Box>
  );
}

export default SelectableImagesList;
