import React from "react";

import { Box, Button, Typography } from "@mui/material";

import { grayLight, white } from "design-system/colors";
import MoovsTooltip from "./MoovsTooltip";
import { AutomatedPricingIcon } from "design-system/icons";

const getBannerTextVariant = (
  bannerTextVariant:
    | "h4"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "overline",
  isCompactVariant: boolean
) => {
  if (bannerTextVariant) return bannerTextVariant;
  return isCompactVariant ? "h4" : "subtitle1";
};

type MoovsBannerProps = {
  bannerText: string;
  actionButtonText: string;
  actionButtonDisabledTooltipText?: string;
  onActionButtonClick: () => void;
  actionButtonVariant?: "text" | "contained" | "outlined";
  bannerBgColor: string;
  bannerTextColor?: string;
  bannerVariant?: "compact";
  showAutomationIcon?: boolean;
  bannerTextVariant?:
    | "h4"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "overline";
};

function MoovsBanner(props: MoovsBannerProps) {
  const {
    bannerText,
    bannerTextVariant,
    actionButtonText,
    actionButtonDisabledTooltipText,
    onActionButtonClick,
    bannerBgColor,
    bannerTextColor,
    actionButtonVariant,
    bannerVariant,
    showAutomationIcon,
  } = props;

  // derived state
  const isCompactVariant = bannerVariant === "compact";
  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="row"
      py={isCompactVariant ? 1 : 3}
      px={isCompactVariant ? 1.5 : 3}
      borderRadius="4px"
      bgcolor={bannerBgColor}
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography
        variant={getBannerTextVariant(bannerTextVariant, isCompactVariant)}
        {...(!!bannerTextColor && { style: { color: bannerTextColor } })}
      >
        {bannerText}
      </Typography>
      {actionButtonDisabledTooltipText ? (
        <MoovsTooltip tooltipText={actionButtonDisabledTooltipText}>
          <Button
            variant={actionButtonVariant || "contained"}
            size={isCompactVariant ? "small" : "medium"}
            style={{ backgroundColor: grayLight }}
            disabled
          >
            {actionButtonText}
          </Button>
        </MoovsTooltip>
      ) : (
        <Button
          color="primary"
          variant={actionButtonVariant || "contained"}
          size={isCompactVariant ? "small" : "medium"}
          onClick={onActionButtonClick}
        >
          {actionButtonText}
          {showAutomationIcon && (
            <Box display="flex" alignItems="center" ml={0.5}>
              <AutomatedPricingIcon size="small" color={white} />
            </Box>
          )}
        </Button>
      )}
    </Box>
  );
}

export default MoovsBanner;
