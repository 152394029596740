import React, { useState } from "react";

import { FarmedRouteStatusEnum, RequestStage } from "types";
import { FarmOutIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import { useAnalytics } from "globals/hooks";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";

type UseFarmOutTripClickProps = {
  farmStatus: FarmedRouteStatusEnum;
  analyticsName?: string;
  isFarmee: boolean;
  requestStage: string;
};

function useFarmOutTripClick(
  props: UseFarmOutTripClickProps
): EllipsisMenuOption & { open: boolean; onClose: () => void } {
  const { analyticsName, farmStatus, isFarmee, requestStage } = props;

  // hooks
  const { track } = useAnalytics();

  // state
  const [farmOutDialogOpen, setFarmOutDialogOpen] = useState(false);

  // derived state
  const farmOutDisabled =
    farmStatus && farmStatus !== FarmedRouteStatusEnum.Declined;

  // event handlers
  const handleFarmOutTripClick = () => {
    if (analyticsName) track(analyticsName);
    setFarmOutDialogOpen(true);
  };

  const handleFarmOutTripDialogClose = () => {
    setFarmOutDialogOpen(false);
  };

  const isUnconfirmedReservation = requestStage === RequestStage.Unconfirmed;

  return {
    text: "Farm-Out Trip",
    icon: <FarmOutIcon size="small" color={grayDark} />,
    onClick: handleFarmOutTripClick,
    disableOption: farmOutDisabled || isUnconfirmedReservation,
    hideOption: isFarmee,
    tooltip: farmOutDisabled
      ? { titleText: "Cannot farm out a trip that is already farmed out" }
      : undefined,

    // dialog state
    open: farmOutDialogOpen,
    onClose: handleFarmOutTripDialogClose,
  };
}

export { useFarmOutTripClick };
