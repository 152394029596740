import React from "react";
import { Link } from "react-router-dom";

import { Box, IconButton } from "@mui/material";

import { grayLight, black } from "design-system/colors";
import { HamburgerMenuIcon, HomeIcon } from "design-system/icons";
import { useAnalytics } from "globals/hooks";

type TopBarSettingsContentMobileProps = {
  onDrawerToggle: () => void;
};

function TopBarSettingsContentMobile(props: TopBarSettingsContentMobileProps) {
  const { onDrawerToggle } = props;

  // hooks
  const { track } = useAnalytics();

  // event handler
  const handleHomeClick = () => track("home_opened");

  return (
    <Box
      display="flex"
      flex={1}
      justifyContent="space-between"
      alignItems="center"
      height="100%"
      bgcolor={grayLight}
    >
      <Box display="flex" alignItems="center" ml="15px">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onDrawerToggle}
          size="large"
        >
          <HamburgerMenuIcon color={black} />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center" mr="3.5px">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          component={Link}
          to="/quotes"
          size="large"
          onClick={handleHomeClick}
        >
          <HomeIcon color={black} />
        </IconButton>
      </Box>
    </Box>
  );
}

export default TopBarSettingsContentMobile;
