import { gql } from "@apollo/client";

export const LOAD_MOOVS_AI_OPERATOR_QUERY = gql`
  query MoovsAiOperator($id: ID!) {
    node(id: $id) {
      id
      ... on MoovsAiOperator {
        createdAt
        updatedAt
        operatorName
        operatorAddress
        operatorPhone
        operatorEmail
        contactName
        websiteUrl
        paymentMethods {
          id
          createdAt
          updatedAt
          cardNote
          isPrimary
          billingDetails {
            name
          }
          payerId
          stripeStatus
          card {
            expMonth
            expYear
            last4
            brand
          }
        }
      }
    }
  }
`;
