import React, { ChangeEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMutation } from "@apollo/client";

import { Box } from "@mui/material";

import CreateDrawer from "../../../globals/CreateDrawer";
import {
  CREATE_EXTERNAL_OPERATOR_MUTATION,
  LOAD_FARM_AFFILIATES_QUERY,
} from "../../../../globals/graphql";
import { useAnalytics, useSnackbar } from "../../../../globals/hooks";
import { ExternalOperatorErrorsType } from "./utils";
import {
  Driver,
  FarmAffiliate,
  ExternalOperator,
  FarmAffiliateVariantEnum,
  FarmAffiliateVehicle,
} from "../../../../types";
import CreateExternalOperatorSection from "./CreateExternalOperatorSection";
import FarmAffiliateDriverSection from "../FarmAffiliateDriverSection";
import FarmAffiliateVehicleSection from "../FarmAffiliateVehicleSection";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

const initialAffiliate: Partial<FarmAffiliate> = {
  operatorName: "",
  contactName: "",
  operatorEmail: "",
  operatorPhone: "",
  operatorAddress: "",
  internalNote: "",
  drivers: [],
  vehicles: [],
};

const initialExternalOperatorErrors: ExternalOperatorErrorsType = {
  operatorName: "",
  contactName: "",
  operatorEmail: "",
  operatorPhone: "",
  operatorAddress: "",
};

function CreateAffiliateDrawer() {
  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const { track } = useAnalytics();

  // state
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [newAffiliate, setNewAffiliate] =
    useState<Partial<FarmAffiliate>>(initialAffiliate);
  const [externalOperatorErrors, setExternalOperatorErrors] =
    useState<ExternalOperatorErrorsType>(initialExternalOperatorErrors);
  const [affiliateSaveError, setAffiliateSaveError] = useState(false);
  const [selectedDrivers, setSelectedDrivers] = useState<Partial<Driver>[]>([]);
  const [selectedVehicles, setSelectedVehicles] = useState<
    FarmAffiliateVehicle[]
  >([]);

  // mutations
  const [createExternalOperator] = useMutation(
    CREATE_EXTERNAL_OPERATOR_MUTATION,
    {
      refetchQueries: [
        {
          query: LOAD_FARM_AFFILIATES_QUERY,
          variables: {
            viewFilter: [
              FarmAffiliateVariantEnum.ExternalOperator,
              FarmAffiliateVariantEnum.MoovsNetworkOperator,
              FarmAffiliateVariantEnum.GriddnetOperator,
            ],
          },
        },
      ],
      onCompleted(data) {
        const { operatorName, id } =
          data.createExternalOperator.externalOperator;
        snackbar.success(`Successfully created affiliate ${operatorName}!`, {
          link: `/affiliates/update/${id}`,
          linkLabel: `View ${operatorName}`,
        });

        track("affiliate_externalCreated");
        handleCreateDrawerClose();
      },
      onError(error) {
        setSubmitDisabled(false);
        const errorMessage =
          getErrorMessage(error) || "Error creating affiliate";

        snackbar.error(errorMessage);
      },
    }
  );

  // event handlers
  const handleCreateDrawerClose = () => {
    history.push("/affiliates");
  };

  const handleAffiliateInfoChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    let name = event.target.name;

    setNewAffiliate({ ...newAffiliate, [name]: value });

    // reset fields error state
    if (externalOperatorErrors.hasOwnProperty(name)) {
      setExternalOperatorErrors({ ...externalOperatorErrors, [name]: "" });
    }
  };

  const handleAffiliateAddressChange = (location: { description?: string }) => {
    setNewAffiliate({ ...newAffiliate, operatorAddress: location.description });

    if (externalOperatorErrors.hasOwnProperty("operatorAddress")) {
      setExternalOperatorErrors({
        ...externalOperatorErrors,
        operatorAddress: "",
      });
    }
  };

  const handleDriverAdd = (driver: Driver | string) => {
    // The DriverAutoComplete component calls with empty string
    // as part of DriverUpdateBlock conditional.
    // Here selectedDrivers state is of type Driver hence the following check.
    if (typeof driver !== "string") {
      setSelectedDrivers([...selectedDrivers, driver]);
    }
  };

  const handleDriverRemove = (id) => {
    const driversWithoutRemovedDriver = [...selectedDrivers].filter(
      (driver) => driver.id !== id
    );

    setSelectedDrivers(driversWithoutRemovedDriver);
  };

  const handleVehicleAdd = (vehicles: FarmAffiliateVehicle[]) => {
    setSelectedVehicles([...selectedVehicles, ...vehicles]);
  };

  const handleVehicleRemove = (vehicleId: string) => {
    setSelectedVehicles(
      selectedVehicles.filter((vehicle) => vehicle.id !== vehicleId)
    );
  };

  const handleCreateAffiliateClick = () => {
    setSubmitDisabled(true);
    if (checkForExternalOperatorErrors()) {
      setSubmitDisabled(false);
    } else {
      const selectedDriverIDs = selectedDrivers.map((driver) => driver.id);
      const selectedVehicleIDs = selectedVehicles.map((vehicle) => vehicle.id);

      createExternalOperator({
        variables: {
          input: {
            ...newAffiliate,
            ...(selectedDriverIDs.length > 0 && { drivers: selectedDriverIDs }),
            ...(selectedVehicleIDs.length > 0 && {
              vehicles: selectedVehicleIDs,
            }),
          },
        },
      });
    }
  };

  // helpers
  // returns true if there are errors in external operator form
  const checkForExternalOperatorErrors = (): boolean => {
    let externalOperatorErrors = [];

    if (!newAffiliate.operatorName) {
      externalOperatorErrors = [
        ...externalOperatorErrors,
        { operatorName: "Please enter the name of the affiliate" },
      ];
    }
    if (!newAffiliate.contactName) {
      externalOperatorErrors = [
        ...externalOperatorErrors,
        { contactName: "Please enter the name of the contact" },
      ];
    }
    if (!newAffiliate.operatorEmail) {
      externalOperatorErrors = [
        ...externalOperatorErrors,
        { operatorEmail: "Please enter the affiliate's email" },
      ];
    }
    if (!newAffiliate.operatorPhone) {
      externalOperatorErrors = [
        ...externalOperatorErrors,
        { operatorPhone: "Please enter the affiliate's phone number" },
      ];
    }
    if (!newAffiliate.operatorAddress) {
      externalOperatorErrors = [
        ...externalOperatorErrors,
        { operatorAddress: "Please select the affiliate's address" },
      ];
    }

    if (externalOperatorErrors.length > 0) {
      setExternalOperatorErrors(
        externalOperatorErrors.reduce(
          (acc, value) => ({ ...acc, ...value }),
          {}
        )
      );

      setAffiliateSaveError(true);
      return true;
    }
    return false;
  };

  return (
    <CreateDrawer
      onClose={handleCreateDrawerClose}
      pageLabel="Add Affiliate"
      submitLabel="Create Affiliate"
      onSubmit={handleCreateAffiliateClick}
      saveError={affiliateSaveError}
      submitDisabled={submitDisabled}
    >
      <Helmet>
        <title>Add Affiliate | Moovs</title>
      </Helmet>
      <Box px={1} pt={3}>
        {/* Details Section */}
        <CreateExternalOperatorSection
          externalOperator={newAffiliate as ExternalOperator}
          externalOperatorErrors={externalOperatorErrors}
          onAffiliateInfoChange={handleAffiliateInfoChange}
          onAffiliateAddressChange={handleAffiliateAddressChange}
        />

        {/* Driver */}
        <FarmAffiliateDriverSection
          drivers={selectedDrivers}
          onDriverAdd={handleDriverAdd}
          onDriverRemove={handleDriverRemove}
          canSelectMultipleDrivers
        />

        {/* Vehicle */}
        <FarmAffiliateVehicleSection
          vehicles={selectedVehicles}
          canAddOrRemoveVehicles
          onVehicleAdd={handleVehicleAdd}
          onVehicleRemove={handleVehicleRemove}
          canSelectMultipleVehicles
        />
      </Box>
    </CreateDrawer>
  );
}

export default CreateAffiliateDrawer;
