import React from "react";

type ViewIconProps = {
  color?: string;
  size?: "small";
};

const ViewIcon = (props: ViewIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.99907 4.00072C5.49086 3.96042 2.89678 5.62982 1.26592 7.33953C1.09473 7.52053 1 7.7555 1 7.99911C1 8.24272 1.09473 8.47769 1.26592 8.65869C2.86131 10.3322 5.44792 12.0402 7.99907 11.9993C10.5502 12.0402 13.1374 10.3322 14.7341 8.65869C14.9053 8.47769 15 8.24272 15 7.99911C15 7.7555 14.9053 7.52053 14.7341 7.33953C13.1014 5.62982 10.5073 3.96042 7.99907 4.00072Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3324 8.00029C10.3323 8.4398 10.1954 8.86941 9.93888 9.23479C9.68242 9.60017 9.31795 9.88491 8.89157 10.053C8.4652 10.2211 7.99606 10.2651 7.54347 10.1792C7.09088 10.0934 6.67517 9.88173 6.34891 9.57091C6.02265 9.26009 5.80048 8.86411 5.71049 8.43304C5.62051 8.00196 5.66676 7.55516 5.84338 7.14912C6.02001 6.74308 6.31908 6.39604 6.70278 6.15187C7.08649 5.90771 7.5376 5.77738 7.99907 5.77738C8.30556 5.77731 8.60907 5.83475 8.89225 5.94645C9.17542 6.05814 9.43271 6.22188 9.6494 6.42832C9.8661 6.63476 10.038 6.87985 10.1552 7.14958C10.2724 7.4193 10.3326 7.70838 10.3324 8.00029Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9988 1.00089C6.77396 0.950521 3.43872 3.03728 1.34189 5.17441C1.1218 5.40066 1 5.69438 1 5.99889C1 6.30339 1.1218 6.59711 1.34189 6.82337C3.39312 8.91531 6.71876 11.0502 9.9988 10.9991C13.2788 11.0502 16.6053 8.91531 18.6581 6.82337C18.8782 6.59711 19 6.30339 19 5.99889C19 5.69438 18.8782 5.40066 18.6581 5.17441C16.5589 3.03728 13.2236 0.950521 9.9988 1.00089Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9988 6.00037C12.9987 6.54975 12.8226 7.08676 12.4928 7.54348C12.1631 8.00021 11.6945 8.35614 11.1463 8.56628C10.5981 8.77642 9.99493 8.83133 9.41303 8.72406C8.83113 8.61679 8.29665 8.35216 7.87717 7.96363C7.45769 7.57511 7.17204 7.08013 7.05635 6.5413C6.94066 6.00246 7.00011 5.44395 7.2272 4.9364C7.45429 4.42885 7.83882 3.99505 8.33215 3.68984C8.82549 3.38463 9.40548 3.22173 9.9988 3.22173C10.3929 3.22163 10.7831 3.29344 11.1472 3.43306C11.5113 3.57267 11.8421 3.77735 12.1207 4.0354C12.3993 4.29345 12.6202 4.59981 12.7709 4.93697C12.9216 5.27413 12.999 5.63548 12.9988 6.00037Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ViewIcon;
