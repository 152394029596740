import { useMutation } from "@apollo/client";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import isNil from "lodash/isNil";

import { Box, Typography } from "@mui/material";

import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";
import { grayDark } from "design-system/colors";
import { UPDATE_OPERATOR_SETTINGS } from "globals/graphql";
import { useOperator, useScreenSize, useSnackbar } from "globals/hooks";
import { AutoPaymentPreference } from "types";
import {
  DollarPercentVariant,
  normalizeValueToBeDisplayedAsDollar,
} from "components/pricing/utils";
import DollarPercentInputField from "components/requests/ChargePayerDialog/components/DollarPercentInputField";
import { toggleOptions, toggleSubtext } from "./constant";

type ReservationPaymentPreferenceProps = {
  setSaveIndicatorState: (
    savedState: "saved" | "error" | "loading" | "default"
  ) => void;
};

const { Deposit } = AutoPaymentPreference;
const { Dollar, Percent } = DollarPercentVariant;

function ReservationPaymentPreference(
  props: ReservationPaymentPreferenceProps
) {
  const { setSaveIndicatorState } = props;

  // hooks
  const operator = useOperator();
  const snackbar = useSnackbar();
  const { isMobileView } = useScreenSize();
  const flag = useRef(false);

  const {
    autoPaymentPreference: initialAutoPaymentPreference,
    autoPaymentDeposit: { amt: initialAmt, percent: initialPercent },
  } = operator.settings;

  const initialDepositPercent = isNil(initialPercent)
    ? initialPercent
    : initialPercent * 100; // convert 0.5 to 50%

  // state
  const [autoPayment, setAutoPayment] = useState({
    autoPaymentPreference: initialAutoPaymentPreference,
    autoPaymentDeposit: { amt: initialAmt, percent: initialDepositPercent },
  });

  const {
    autoPaymentDeposit: { amt, percent },
    autoPaymentPreference,
  } = autoPayment;

  // debounce
  const [debouncedAutoPayment] = useDebounce(autoPayment, 500);

  // mutation
  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      snackbar.error("Error updating operator");
    },
  });

  // event handlers
  const debouncedUpdateOperatorSetting = useCallback(() => {
    setSaveIndicatorState("loading");

    const {
      autoPaymentPreference,
      autoPaymentDeposit: { amt, percent },
    } = debouncedAutoPayment;

    // convert 50% to 0.5
    const decimalPercent = isNil(percent) ? percent : percent / 100;

    updateOperatorSettings({
      variables: {
        input: {
          autoPaymentPreference,
          autoPaymentDeposit: {
            amt,
            percent: decimalPercent,
          },
        },
      },
    });
  }, [debouncedAutoPayment, updateOperatorSettings, setSaveIndicatorState]);

  const handleAutoPaymentPreferenceToggle = (e, variant) => {
    setAutoPayment({
      ...autoPayment,
      autoPaymentPreference: variant,
    });
  };

  const handleDollarPercentVariantChange = (variant) => {
    if (!flag.current) return;

    if (variant === Dollar && isNil(amt)) {
      setAutoPayment({
        ...autoPayment,
        autoPaymentDeposit: {
          amt: 0,
          percent: null,
        },
      });
    } else if (variant === Percent && isNil(percent)) {
      setAutoPayment({
        ...autoPayment,
        autoPaymentDeposit: {
          amt: null,
          percent: 0,
        },
      });
    }
  };

  const handleDollarValueChange = (e) => {
    const { value } = e.target;

    setAutoPayment({
      ...autoPayment,
      autoPaymentDeposit: {
        ...autoPayment.autoPaymentDeposit,
        amt: Number(value),
      },
    });
  };

  const handlePercentValueChange = (e) => {
    const { value } = e.target;

    setAutoPayment({
      ...autoPayment,
      autoPaymentDeposit: {
        ...autoPayment.autoPaymentDeposit,
        percent: value > 100 ? 100 : Number(value),
      },
    });
  };

  // effects
  useEffect(() => {
    if (!debouncedAutoPayment) return;

    if (!flag.current) {
      flag.current = true;
    } else {
      debouncedUpdateOperatorSetting();
    }
  }, [debouncedUpdateOperatorSetting, debouncedAutoPayment]);

  const depositTextValue = !isNil(percent)
    ? `${percent}% of the Total Amount`
    : `$${normalizeValueToBeDisplayedAsDollar({
        value: amt,
        variant: Dollar,
      })}`;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection={isMobileView ? "column" : "row"}
      mt={3}
    >
      <Box>
        <Typography variant="body2">Reservation Payment Preference</Typography>
        <Box maxWidth="400px">
          <Typography variant="caption" color={grayDark}>
            Choose how much your customers will be charged when confirming a
            reservation
          </Typography>
        </Box>
      </Box>
      <Box mt={isMobileView ? 2 : 0}>
        {/* Auto Payment Preference Toggle */}
        <Box>
          <MoovsToggleButtonGroup
            size="large"
            onChange={handleAutoPaymentPreferenceToggle}
            options={toggleOptions}
            value={autoPaymentPreference}
            fullWidth
            keepInline
          />
          <Box mt={0.5}>
            <Typography variant="caption" color={grayDark}>
              {toggleSubtext[autoPaymentPreference]}
            </Typography>
          </Box>
        </Box>
        {/* Deposit Percent Dollar Toggle */}
        {autoPayment.autoPaymentPreference === Deposit && (
          <Box mt={3}>
            <DollarPercentInputField
              onDollarInputChange={handleDollarValueChange}
              onPercentInputChange={handlePercentValueChange}
              onDollarPercentVariantChange={handleDollarPercentVariantChange}
              defaultDollarPercentVariant={!isNil(percent) ? Percent : Dollar}
              inputErrors={{ inputChargeAmt: null }}
              inputDollarAmt={amt}
              inputWholePercentValue={percent}
            />
            <Box maxWidth="300px">
              <Typography variant="caption" color={grayDark} mt={3}>
                {`Customers will be charged ${depositTextValue} when making
                  a reservation`}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ReservationPaymentPreference;
