import React from "react";
import { useMutation } from "@apollo/client";

import MoovsDialog from "components/MoovsDialog";
import { DynamicPricingRule } from "types";
import useEditDynamicPricingForm from "./EditDynamicPricingForm/useEditDynamicPricingForm";
import {
  LOAD_DYNAMIC_PRICING_RULES,
  UPDATE_DYNAMIC_PRICING_RULE,
} from "globals/graphql";
import { finalizeEditDynamicPricingInput } from "./EditDynamicPricingForm/utils/finalizeEditDynamicPricingInput";
import { useSnackbar, useVehicles } from "globals/hooks";

type EditDynamicPricingDialogProps = {
  open: boolean;
  onClose: () => void;
  rule: DynamicPricingRule;
};

function EditDynamicPricingDialog(props: EditDynamicPricingDialogProps) {
  const { open, onClose, rule } = props;

  // hook
  const snackbar = useSnackbar();
  const [availableVehicles] = useVehicles();
  const {
    editDynamicPricingForm,
    handleEditDynamicPricingSubmit,
    watch,
    resetEditDynamicPricing,
  } = useEditDynamicPricingForm(rule);

  // mutation
  const [updateDynamicPricing, { loading: dynamicPricingUpdateLoading }] =
    useMutation(UPDATE_DYNAMIC_PRICING_RULE, {
      refetchQueries: [{ query: LOAD_DYNAMIC_PRICING_RULES }],
      onCompleted: () => {
        onClose();
      },
      onError: () => {
        snackbar.error("Could not update rule");
      },
    });

  // event handlers
  const handleAfterSubmit = () => {
    const formInput = watch();

    const enabledAvailableVehicles = availableVehicles.filter(
      (vehicle) => vehicle.enableBaseRateAutomation
    );

    const rule = finalizeEditDynamicPricingInput({
      // @ts-ignore fix later
      input: { ...formInput },
      availableVehicles: enabledAvailableVehicles,
    });

    updateDynamicPricing({
      variables: {
        input: rule,
      },
    });
  };

  const handleCloseAndReset = () => {
    resetEditDynamicPricing();
    onClose();
  };

  return (
    <MoovsDialog
      open={open}
      onClose={handleCloseAndReset}
      dialogTitle="Edit Pricing Rule"
      size="md"
      sx={{ minHeight: "460px" }}
      onAccept={handleEditDynamicPricingSubmit(handleAfterSubmit)}
      acceptButtonText="Save"
      acceptDisabled={dynamicPricingUpdateLoading}
    >
      {editDynamicPricingForm}
    </MoovsDialog>
  );
}

export default EditDynamicPricingDialog;
