import { gql } from "@apollo/client";

export const LOAD_DYNAMIC_PRICING_RULES = gql`
  query LoadDynamicPricingRules {
    loadDynamicPricingRules {
      id
      name
      vehicles {
        id
        name
        enableBaseRateAutomation
        available
      }
      startDate
      endDate
      daysOfWeek
      startTime
      endTime
      isIncrease
      isPercent
      amount
      repeatsAnnually
      isActive
    }
  }
`;

export const CREATE_DYNAMIC_PRICING_RULE = gql`
  mutation CreateDynamicPricingRule($input: CreateDynamicPricingRuleInput!) {
    createDynamicPricingRule(input: $input) {
      rule {
        id
        name
        vehicles {
          id
          name
        }
        startDate
        endDate
        daysOfWeek
        startTime
        endTime
        isIncrease
        isPercent
        amount
        repeatsAnnually
        isActive
      }
    }
  }
`;

export const UPDATE_DYNAMIC_PRICING_ACTIVE_STATUS = gql`
  mutation UpdateDynamicPricingRuleActiveStatus(
    $input: UpdateDynamicPricingRuleActiveStatusInput!
  ) {
    updateDynamicPricingRuleActiveStatus(input: $input) {
      rule {
        id
        isActive
      }
    }
  }
`;

export const UPDATE_DYNAMIC_PRICING_RULE = gql`
  mutation updateDynamicPricingRule($input: UpdateDynamicPricingRuleInput!) {
    updateDynamicPricingRule(input: $input) {
      rule {
        id
        repeatsAnnually
        isIncrease
      }
    }
  }
`;
