import React from "react";

type AirportDropOffIconProps = {
  color?: string;
};

const AirportDropOffIcon = (props: AirportDropOffIconProps) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.3335 14.6665H14.6668M14.5601 8.93441C14.6943 9.26303 14.7022 9.63848 14.5823 10.0004C14.4624 10.3623 14.2219 10.6896 13.8992 10.9297C13.6556 11.1147 13.3728 11.242 13.0785 11.2991C12.7841 11.3563 12.4882 11.3413 12.2194 11.2557L1.68453 7.82668C1.57659 7.79146 1.48579 7.72542 1.42343 7.63676C1.36107 7.54811 1.32991 7.44076 1.33382 7.32808L1.4754 3.14459C1.4802 3.09638 1.49646 3.04856 1.52294 3.00474C1.54942 2.96092 1.58543 2.92227 1.62823 2.89172C1.67102 2.86118 1.71947 2.83955 1.7699 2.82847C1.82032 2.8174 1.87139 2.81718 1.91921 2.82782L3.87061 3.11314C3.92532 3.12335 3.97464 3.14722 4.01378 3.18242C4.05293 3.21763 4.08058 3.26298 4.09406 3.31411L4.18917 5.38992L6.91925 6.11555L6.78107 1.05896C6.78893 0.999786 6.81055 0.941466 6.84434 0.888301C6.87813 0.835137 6.92323 0.788482 6.97632 0.751777C7.02941 0.715072 7.08914 0.689251 7.15109 0.676217C7.21305 0.663183 7.27566 0.663268 7.33431 0.676466L8.63942 0.983233C8.70374 0.998471 8.76119 1.02913 8.80664 1.07248C8.85209 1.11583 8.88415 1.17053 8.89996 1.23171L10.3927 7.24806L13.6564 8.15423C13.8635 8.21096 14.0507 8.30959 14.2063 8.44388C14.3618 8.57817 14.4823 8.7452 14.5601 8.93441Z"
        stroke={color || "#28B668"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AirportDropOffIcon;
