import React from "react";

import { Box, Button } from "@mui/material";

import { grayDark } from "../../../design-system/colors";
import {
  CrossIcon,
  FarmOutIcon,
  ReplaceVehicleIcon,
} from "../../../design-system/icons";
import { VehicleCalendarVariantEnum } from "../../../globals/utils/vehicleCalendarEnum";
import VehicleAvailabilityBlock from "./VehicleAvailabilityBlock";
import AutomatedBaseRateBlock from "./AutomatedBaseRateBlock";
import { useScreenSize } from "globals/hooks";
import MoovsEllipsisMenu from "components/MoovsEllipsisMenu";

type VehicleCardContentRightEdgeBlockProps = {
  ellipsisMenuOptionsProps: {
    hasEllipsisMenuOptions: boolean;

    ellipsisMenuActions: {
      onPressRemove?: () => void;
      onPressReplace?: () => void;
    };
  };
  onVehicleViewOnlyClick: () => void;
  onSwoopFarmVehiclesDialogOpen?: () => void;
  automatedBaseRateProps?: {
    showBRBlock?: boolean;
    baseRateAmount?: number;
  };
  vehicleAvailabilityProps?: {
    vehicleAvailabilityDialogTitle: string;
    vehicleCalendarVariant: VehicleCalendarVariantEnum;
    onShowVehicleAvailabilityClick: (
      vehicleAvailabilityDialogTitle: string
    ) => void;
  };
};

function VehicleCardContentRightEdgeBlock(
  props: VehicleCardContentRightEdgeBlockProps
) {
  const {
    automatedBaseRateProps,
    ellipsisMenuOptionsProps,
    onVehicleViewOnlyClick,
    vehicleAvailabilityProps,
    onSwoopFarmVehiclesDialogOpen,
  } = props;

  const { hasEllipsisMenuOptions, ellipsisMenuActions } =
    ellipsisMenuOptionsProps;

  const { onPressRemove, onPressReplace } = ellipsisMenuActions;
  const showBRBlock = automatedBaseRateProps?.showBRBlock;
  const automatedBRAmount = automatedBaseRateProps?.baseRateAmount;

  // hooks
  const { isMobileView } = useScreenSize();

  // event handlers
  const handlePressRemove = () => {
    onPressRemove();
  };

  const handlePressReplace = () => {
    onPressReplace();
  };

  // vehicle dialog handlers
  const handlePressAffiliateVehicles = () => {
    onSwoopFarmVehiclesDialogOpen();
  };

  // derived state
  const shouldRenderBRBlock = showBRBlock && !isMobileView;

  const ellipsisMenuOptions = [
    ...(onPressReplace
      ? [
          {
            icon: <ReplaceVehicleIcon color={grayDark} size="small" />,
            text: "Replace Vehicle",
            onClick: handlePressReplace,
          },
        ]
      : []),
    ...(onPressRemove
      ? [
          {
            icon: <CrossIcon color={grayDark} size="small" />,
            text: "Remove Vehicle",
            onClick: handlePressRemove,
          },
        ]
      : []),
    ...(!!onSwoopFarmVehiclesDialogOpen
      ? [
          ...((onPressRemove || onPressReplace) && ["divider"]),
          {
            key: "AffiliateVehicles",
            onClick: handlePressAffiliateVehicles,
            "data-testid": "affiliate-vehicles-button",
            text: "Affiliate Vehicles",
            icon: <FarmOutIcon size="small" color={grayDark} />,
          },
        ]
      : []),
  ];

  return (
    <>
      <Box display="flex" flexDirection="row-reverse" maxWidth={86}>
        <Box display="flex" alignItems="center" flex={1}>
          {!!vehicleAvailabilityProps && (
            <VehicleAvailabilityBlock {...vehicleAvailabilityProps} />
          )}

          {/* Vehicle View-Only */}
          {onVehicleViewOnlyClick && (
            <Button
              sx={{ ml: 1 }}
              variant="text"
              onClick={onVehicleViewOnlyClick}
            >
              View
            </Button>
          )}

          {/* Ellipsis */}
          {hasEllipsisMenuOptions && (
            <>
              <MoovsEllipsisMenu
                data-testid="vehicle-card-small-ellipsis"
                options={ellipsisMenuOptions}
              />
            </>
          )}
        </Box>
      </Box>
      {/* Base Rate Price */}
      {shouldRenderBRBlock && (
        <AutomatedBaseRateBlock automatedBRAmount={automatedBRAmount} />
      )}
    </>
  );
}

export default VehicleCardContentRightEdgeBlock;
