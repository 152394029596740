import React from "react";

type ChargeCustomerIconProps = {
  color?: string;
};

const ChargeCustomerIcon = (props: ChargeCustomerIconProps) => {
  const { color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0004 5.99978V17.9998M14.5004 8.1816H10.7504C10.2863 8.1816 9.84117 8.38274 9.51299 8.74076C9.1848 9.09878 9.00042 9.58437 9.00042 10.0907C9.00042 10.597 9.1848 11.0826 9.51299 11.4406C9.84117 11.7986 10.2863 11.9998 10.7504 11.9998H13.2504C13.7146 11.9998 14.1597 12.2009 14.4879 12.5589C14.816 12.917 15.0004 13.4026 15.0004 13.9089C15.0004 14.4152 14.816 14.9008 14.4879 15.2588C14.1597 15.6168 13.7146 15.818 13.2504 15.818H9.00042M19.1591 11.3715L20.8182 9.2L22.9897 10.8591M20.8973 9.51664C21.4102 11.6007 21.1898 13.7979 20.2731 15.7385C19.3564 17.6792 17.7994 19.2449 15.8639 20.1724C13.9283 21.1 11.7325 21.3326 9.64555 20.8313M4.6653 13.1729L2.56118 14.9098L0.824219 12.8057M2.76213 13.984C2.25238 11.9008 2.47491 9.70574 3.39236 7.76728C4.30981 5.82882 5.86629 4.26506 7.80045 3.33858C9.73461 2.41209 11.9286 2.17932 14.0141 2.67934"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChargeCustomerIcon;
