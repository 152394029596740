import React from "react";

import { Box, Typography } from "@mui/material";

import DriversListItem from "./DriversListItem";
import { Driver } from "../../../types";

type DriversListProps = {
  drivers: Driver[];
  //sxStyles: any;
  onDriverListItemClick: (driverId) => void;
};
function DriversList(props: DriversListProps) {
  const { drivers, onDriverListItemClick } = props;

  return (
    <>
      <Box width="100%" mr={3}>
        <Box display="flex" flexDirection="row" flex="1" px={3} mb={1}>
          <Box display="flex" flex="2">
            <Typography variant="overline">name</Typography>
          </Box>
          <Box display="flex" flex="1">
            <Typography variant="overline">phone</Typography>
          </Box>
        </Box>
        {drivers.map((driver) => {
          return (
            <Box key={driver.id} mb={1}>
              <DriversListItem
                driver={driver}
                onDriverClick={(driverId) => {
                  onDriverListItemClick(driverId);
                }}
              />
            </Box>
          );
        })}
      </Box>
    </>
  );
}

export default DriversList;
