import { useMutation } from "@apollo/client";

import { INSTALL_MOOVS_MARKET_APP_MUTATION } from "globals/graphql";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { MarketApp } from "../../../../MoovsMarketAppList/data/appData";
import { useState } from "react";

const useInstallMarketApp = (app: MarketApp, onCompleted?: () => void) => {
  // hooks
  const { track } = useAnalytics();
  const snackbar = useSnackbar();

  // state
  const [analyticsName, setAnalyticsName] = useState("");

  // mutation
  const [installApp, { loading }] = useMutation(
    INSTALL_MOOVS_MARKET_APP_MUTATION,
    {
      onCompleted(data) {
        track(analyticsName);
        if (onCompleted) onCompleted();
      },
      onError() {
        snackbar.error("Error installing app");
      },
    }
  );

  // event handler
  const handleInstallMoovsMarketApp = (analyticsName: string) => {
    setAnalyticsName(analyticsName);

    installApp({
      variables: {
        input: {
          app,
        },
      },
    });
  };

  return { handleInstallMoovsMarketApp, loading };
};

export default useInstallMarketApp;
