import React from "react";

type HomeIconProps = {
  color?: string;
  size?: "small";
};

const HomeIcon = (props: HomeIconProps) => {
  const { color, size } = props;
  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#home-icon-clip-path)">
          <path
            d="M14.7655 6.00662L8 1L1.23463 6.00662C1.0947 6.14218 1.01056 6.32963 1.00005 6.52922L1 14.3592C1.00049 14.5284 1.06476 14.6906 1.17878 14.8103C1.29279 14.93 1.44729 14.9975 1.60853 14.998H5.66667L5.66672 11.9205C5.66672 11.2708 5.91255 10.6477 6.35014 10.1883C6.78772 9.72887 7.38121 9.47078 8.00005 9.47078C8.61889 9.47078 9.21238 9.72887 9.64997 10.1883C10.0876 10.6477 10.3334 11.2708 10.3334 11.9205L10.3333 15H14.3908C14.5521 14.9995 14.7067 14.932 14.8208 14.8123C14.9349 14.6927 14.9993 14.5305 15 14.3611L15.0001 6.52922C14.9893 6.3297 14.9052 6.14235 14.7655 6.00662Z"
            stroke={color || "#999999"}
            strokeWidth="1.5528"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="home-icon-clip-path">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6649 8.65231L12.0044 1L2.33511 8.65231C2.13522 8.84597 2.01502 9.11375 2 9.39888V21.0845C2.0007 21.3264 2.09252 21.5581 2.2554 21.7291C2.41828 21.9001 2.63899 21.9965 2.86933 21.9972H8.66667V17.1007C8.66667 16.1725 9.01786 15.2824 9.64298 14.6261C10.2681 13.9698 11.1159 13.6011 12 13.6011C12.8841 13.6011 13.7319 13.9698 14.357 14.6261C14.9821 15.2824 15.3333 16.1725 15.3333 17.1007V22H21.1298C21.3602 21.9993 21.581 21.9029 21.744 21.7319C21.907 21.5609 21.9991 21.3292 22 21.0873V9.39888C21.9846 9.11385 21.8645 8.84621 21.6649 8.65231Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HomeIcon;
