import { useMutation } from "@apollo/client";

import { Box } from "@mui/material";

import LabeledSwitchInput from "../LabeledSwitchInput";
import ToggleHeader from "./ToogleHeader";
import { useOperator, useSnackbar } from "globals/hooks";
import { TextRecipientEnum } from "types";

import { UPDATE_OPERATOR_SETTINGS } from "globals/graphql";
import getRecipient from "globals/utils/getRecipient";

type SendUpcomingTripReminderBlockProps = {
  setSaveIndicatorState: (value: string) => void;
};

function SendUpcomingTripReminderBlock(
  props: SendUpcomingTripReminderBlockProps
) {
  const { setSaveIndicatorState } = props;

  const snackbar = useSnackbar();
  const {
    settings: { tripReminderTextTo },
  } = useOperator();

  const isRecipientPassenger =
    tripReminderTextTo === TextRecipientEnum.Passenger ||
    tripReminderTextTo === TextRecipientEnum.Both;

  const isRecipientBookingContact =
    tripReminderTextTo === TextRecipientEnum.BookingContact ||
    tripReminderTextTo === TextRecipientEnum.Both;

  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error(
        "Error updating setting for text notifications for trip status updates"
      );
    },
  });

  const handleUpdateRecipientAsPassenger = () => {
    setSaveIndicatorState("loading");
    updateOperatorSettings({
      variables: {
        input: {
          tripReminderTextTo: getRecipient(
            !isRecipientPassenger,
            isRecipientBookingContact
          ),
        },
      },
    });
  };

  const handleUpdateRecipientAsBookingContact = () => {
    setSaveIndicatorState("loading");
    updateOperatorSettings({
      variables: {
        input: {
          tripReminderTextTo: getRecipient(
            isRecipientPassenger,
            !isRecipientBookingContact
          ),
        },
      },
    });
  };

  return (
    <>
      <ToggleHeader
        text="Send Upcoming Trip Reminders"
        subText="When this is on, the selected recipients will receive text messages to remind them of the upcoming trip 24 hours and 1 hour prior to the pick-up time."
      />

      <Box display="flex" my={2} gap={2}>
        <LabeledSwitchInput
          name="sendUpcomingTripReminderToPassenger"
          checked={isRecipientPassenger}
          onChange={handleUpdateRecipientAsPassenger}
          text="Passenger"
        />

        <LabeledSwitchInput
          name="sendUpcomingTripReminderToBookingContact"
          checked={isRecipientBookingContact}
          onChange={handleUpdateRecipientAsBookingContact}
          text="Booking Contact"
        />
      </Box>
    </>
  );
}

export default SendUpcomingTripReminderBlock;
