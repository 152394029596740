import React from "react";

type WebsiteBrowserIconProps = {
  color?: string;
  size?: "small";
  fill?: string;
};

const WebsiteBrowserIcon = (props: WebsiteBrowserIconProps) => {
  const { size, color, fill } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 7.5H14M6.80921 7.5V13.625M3.04348 2H12.9565C13.5328 2 14 2.54703 14 3.22182V12.7782C14 13.453 13.5328 14 12.9565 14H3.04348C2.46718 14 2 13.453 2 12.7782V3.22182C2 2.54703 2.46718 2 3.04348 2Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="4.85104"
          cy="4.77682"
          r="0.851036"
          fill={fill || "#999999"}
        />
        <circle
          cx="7.40426"
          cy="4.77682"
          r="0.851036"
          fill={fill || "#999999"}
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 9.5H21M9 9.49999V14.5M9 14.5V19.5M9 14.5H21M4.56522 4H19.4348C20.2992 4 21 4.72937 21 5.62909V18.3709C21 19.2706 20.2992 20 19.4348 20H4.56522C3.70077 20 3 19.2706 3 18.3709V5.62909C3 4.72937 3.70077 4 4.56522 4Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7C7 7.55228 6.55228 8 6 8C5.44772 8 5 7.55228 5 7C5 6.44772 5.44772 6 6 6C6.55228 6 7 6.44772 7 7Z"
        fill={fill || "#999999"}
      />
      <path
        d="M10 7C10 7.55228 9.55228 8 9 8C8.44772 8 8 7.55228 8 7C8 6.44772 8.44772 6 9 6C9.55228 6 10 6.44772 10 7Z"
        fill={fill || "#999999"}
      />
      <path
        d="M13 7C13 7.55228 12.5523 8 12 8C11.4477 8 11 7.55228 11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7Z"
        fill={fill || "#999999"}
      />
    </svg>
  );
};

export default WebsiteBrowserIcon;
