import React from "react";

import { Box, Typography } from "@mui/material";

import PassengerApp from "design-system/images/PassengerApp.svg";
import {
  grayDark,
  greenLabelText,
  moovsBlue,
  moovsBlueSelected,
  tintGreen,
} from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import RoundChip from "design-system/components/chips/RoundChip";
import { CheckCircleIcon } from "design-system/icons";

function PassengerAppSection() {
  // hooks
  const { isMediumTabletView } = useScreenSize();

  return (
    <Box
      display="flex"
      flexDirection={isMediumTabletView ? "column" : "row"}
      justifyContent="space-between"
      minHeight="460px"
    >
      <Box flex={0.5}>
        <Box mt={3}>
          <RoundChip
            label="COMING SOON"
            color={greenLabelText}
            backgroundColor={tintGreen}
          />
          <Typography variant="h2" mt={1}>
            Customer Portal App
          </Typography>
        </Box>
        <Box mt={2} pr="25%">
          <Typography variant="body2">
            {isMediumTabletView
              ? "Available Q1’24 for iOS and Android"
              : "Elevate your service with your own branded passenger app"}
          </Typography>
        </Box>
        {!isMediumTabletView && (
          <Box mt={2} display="flex" flexDirection="column" gap={1} pr="10%">
            {[
              "Effortless Quote Submission",
              "Simple Reservation Management",
              "Access Trip History",
              "View Upcoming Trips",
            ].map((text) => (
              <Box display="flex" alignItems="center">
                <CheckCircleIcon color={moovsBlue} />
                <Typography variant="body2" ml={2}>
                  {text}
                </Typography>
              </Box>
            ))}
            <Typography variant="caption" color={grayDark} mt={2}>
              Available Q1’24 for iOS and Android
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        flex={0.5}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        mt={isMediumTabletView ? 4 : 0}
        pt={isMediumTabletView ? 4 : 0}
        flexDirection="column"
        bgcolor={moovsBlueSelected}
        borderRadius={2}
      >
        <Box paddingX={4} height="433px">
          <img
            src={PassengerApp}
            alt="customer portal"
            style={{
              maxHeight: "433px",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default PassengerAppSection;
