/**
 * @file ImageUpload.tsx
 * Image uploader that wraps around a button
 * handles resizing / croping in a dialog using canvas
 * and sends blob to firebase
 */
import React, { ChangeEvent, ReactNode, useState, useRef } from "react";
import { Area } from "react-easy-crop/types";
import * as Sentry from "@sentry/react";

import { Box } from "@mui/material";

import ImageUploadDialog from "./ImageUploadDialog";
import { resizeFile, getCroppedImg } from "./imageUtils";
import generateId from "../../globals/utils/generateId";
import { storage } from "../../globals/utils/firebaseApp";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

type ImageUploadProps = {
  button?: ReactNode;
  aspectRatio: number; // aspect ratio of cropper
  firebaseDirectory: string; // what folder in firebase storage to add
  htmlFor: string; // what input the html input is tied to
  maxWidth: number; // maxWidth pixels to resize down to
  maxHeight: number; // maxHeight pixels to resize down to
  onFileUploadStart?: () => void;
  onFileUploadComplete: (url: string) => void;
  onError?: (errorMessage: string) => void;
};

function ImageUpload(props: ImageUploadProps) {
  const {
    button,
    aspectRatio,
    firebaseDirectory,
    maxWidth,
    maxHeight,
    onFileUploadStart,
    onFileUploadComplete,
    onError,
    htmlFor,
  } = props;

  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageDialogLoading, setImageDialogLoading] = useState(true);
  const [fileName, setFileName] = useState("");
  const [imageDataUrl, setImageDataUrl] = useState("");

  const fileInputRef = useRef(null);

  const handleSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];

        setImageDialogOpen(true);
        setImageDialogLoading(true);

        // setTimeout so that loading indicator has time to render prior to file resize blocking it
        setTimeout(async () => {
          // resize image
          const image = await resizeFile(file, maxWidth, maxHeight);
          setFileName(file.name);
          setImageDataUrl(image);
          setImageDialogLoading(false);
        }, 500);
      }
    } catch (error) {
      if (onError) onError(error.message);
      Sentry.captureException(error);
    }
  };

  const handleFileUploadClick = async (croppedAreaPixels: Area) => {
    try {
      if (onFileUploadStart) {
        // close dialog to render loading outside
        onFileUploadStart();
        setImageDialogOpen(false);
      } else {
        // set loading indicator inside dialog
        setImageDialogLoading(true);
      }

      // crop image
      const croppedImage = await getCroppedImg(imageDataUrl, croppedAreaPixels);

      // upload image
      const randomId = generateId(8);
      const imagesRef = ref(
        storage,
        `/${firebaseDirectory}/${randomId}_${fileName}`
      );

      // upload photo to firebase
      const snapshot = await uploadBytes(imagesRef, croppedImage);
      const url = await getDownloadURL(snapshot.ref);

      setImageDialogOpen(false);
      onFileUploadComplete(url);

      // delay stopping loading indicator until dialog closes
      setTimeout(() => setImageDialogLoading(false), 300);
    } catch (error) {
      if (onError) onError(error.message);
      Sentry.captureException(error);
    }
  };

  const handleImageUploadClose = () => {
    setImageDialogOpen(false);
    setFileName("");
    setImageDataUrl("");
    fileInputRef.current.value = "";
  };

  return (
    <>
      <Box>
        <input
          accept="image/*"
          id={htmlFor}
          type="file"
          style={{ display: "none" }}
          onChange={handleSelectFile}
          ref={fileInputRef}
          onClick={(e) => e.stopPropagation()}
        />
        <label htmlFor={htmlFor}>{button}</label>
      </Box>
      <ImageUploadDialog
        open={imageDialogOpen}
        onClose={handleImageUploadClose}
        imageDataUrl={imageDataUrl}
        imageDialogLoading={imageDialogLoading}
        aspectRatio={aspectRatio}
        onFileUploadClick={handleFileUploadClick}
      />
    </>
  );
}

export default ImageUpload;
