import { FieldNamesMarkedBoolean } from "react-hook-form";

// This function takes a dirtyFields object from react-hook-form that tracks which fields have been modified
// and the corresponding form values, then returns an object containing only the dirty field values
export const getDirtyFields = <T extends Record<string, any>>(
  dirtyFields: FieldNamesMarkedBoolean<T>,
  formValues: T
): Partial<T> => {
  if (!dirtyFields || !formValues) return {};
  return Object.keys(dirtyFields).reduce((accumulator, key) => {
    const isDirty = dirtyFields[key];
    const value = formValues[key];

    // If it's an object, apply the logic recursively
    if (typeof isDirty === "object" && isDirty !== null) {
      accumulator[key] = getDirtyFields(isDirty, value);
    }
    // If it's a dirty field, get the value from formValues
    else if (isDirty) {
      accumulator[key] = value;
    }

    return accumulator;
  }, {});
};
