/**
 * RouteDispatchDetails
 */
import React, { useMemo } from "react";
import moment from "moment-timezone";

import { Box, Typography } from "@mui/material";

import {
  RoundTripVariant,
  RouteDispatchLogStatusEnum,
  Stop,
  TripCategory,
} from "types";
import DispatchStatusChip from "components/DispatchStatusChip";
import { DispatchTimeChip } from "components/DispatchTimeChips/DispatchTimeChips";
import { grayLight } from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import TripOrderNumberAndCategory from "./TripOrderNumberAndCategory";
import FlightChangeDisplay from "pages/requests/RequestOverview/ReservationOverview/TripItineraryBlock/components/FlightChangeDisplay";
import first from "lodash/first";

export type RouteDispatchDetailsProps = {
  orderNumber: string; // full order number + trip number - ex: RHJK-15
  tripCategory: TripCategory;
  routeDispatchLogStatus: RouteDispatchLogStatusEnum | null | undefined;
  stopLabel: string;
  stopAbbrevationWithAddressLabel: string;
  dispatchStatus: string;
  pickUpTime: Date;
  arrivalStopTime: Date;
  driverEta: Date;
  roundTripVariant?: RoundTripVariant;
  stops: Stop[];
};

function RouteDispatchDetails(props: RouteDispatchDetailsProps) {
  const {
    orderNumber,
    tripCategory,
    roundTripVariant,
    routeDispatchLogStatus,
    pickUpTime,
    stopLabel,
    stopAbbrevationWithAddressLabel,
    arrivalStopTime,
    dispatchStatus,
    driverEta,
    stops,
  } = props;

  // hook
  const { isMobileView } = useScreenSize();

  // derived state
  const pickUpFlightOffset = first(stops).flightOffset;
  const pickUpOriginalDateTime = first(stops).originalDateTime;
  const dateTime = first(stops).dateTime;

  const [heading, label] = useMemo(() => {
    const pickUpTimeLabel = moment.utc(pickUpTime).format("h:mm A");

    switch (routeDispatchLogStatus) {
      case RouteDispatchLogStatusEnum.EnRoute:
        return ["Heading To", stopAbbrevationWithAddressLabel];
      case RouteDispatchLogStatusEnum.Arrived:
        return ["Arrived At", stopAbbrevationWithAddressLabel];
      default:
        return ["Pick-Up", pickUpTimeLabel];
    }
  }, [pickUpTime, routeDispatchLogStatus, stopAbbrevationWithAddressLabel]);

  return (
    <Box
      sx={{
        display: "flex",
        flex: "1",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRight: `1px solid ${grayLight}`,
        maxWidth: isMobileView ? "190px" : "240px",
        pr: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <DispatchStatusChip dispatchStatus={dispatchStatus} shortened />
        {!isMobileView && (
          <TripOrderNumberAndCategory
            orderNumber={orderNumber}
            tripCategory={tripCategory}
            roundTripVariant={roundTripVariant}
          />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 1,
        }}
      >
        <Typography variant="overline" noWrap>
          {heading}
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography variant="h4" noWrap>
            {label}
          </Typography>
          <Box ml={0.8}>
            {heading === "Pick-Up" && (
              <FlightChangeDisplay
                flightOffset={pickUpFlightOffset}
                originalDateTime={pickUpOriginalDateTime}
                dateTime={dateTime}
                iconSize="small"
              />
            )}
          </Box>
        </Box>

        <Box>
          <DispatchTimeChip
            pickUpTime={pickUpTime}
            arrivalStopTime={arrivalStopTime}
            stopLabel={stopLabel}
            routeDispatchLogStatus={routeDispatchLogStatus}
            driverEta={driverEta}
          />
        </Box>
      </Box>

      {isMobileView && (
        <Box mt={2}>
          <TripOrderNumberAndCategory
            orderNumber={orderNumber}
            tripCategory={tripCategory}
            roundTripVariant={roundTripVariant}
          />
        </Box>
      )}
    </Box>
  );
}

export { RouteDispatchDetails };
