import React from "react";
import moment from "moment";

import { Typography, Box } from "@mui/material";

import { StripePayout } from "types";
import RoundChip from "design-system/components/chips/RoundChip";
import { currency } from "globals/utils/helpers";
import { grayLight, successGreen, white } from "design-system/colors";

export type InstantPayoutsListItemProps = {
  stripePayout: StripePayout;
};

function InstantPayoutsListItemDesktop(props: InstantPayoutsListItemProps) {
  const { stripePayout } = props;

  return (
    <Box
      border={`1px solid ${grayLight}`}
      borderRadius="4px"
      mb={1}
      p={2}
      boxShadow="0px 4px 15px 0px rgba(0, 0, 0, 0.03)"
    >
      <Box display="flex" flexDirection="row" flex="1" maxWidth={900} mx="auto">
        <Box display="flex" flex="1">
          <Typography variant="body2">
            {moment.utc(stripePayout.payoutDate).format("MM/DD/YY")}
          </Typography>
        </Box>
        <Box display="flex" flex="4">
          <Typography variant="body2" fontWeight={700}>
            {currency(stripePayout.amount)}
          </Typography>
        </Box>
        <Box display="flex" flex="1" justifyContent="flex-end">
          <RoundChip
            label="INSTANT PAID"
            color={white}
            backgroundColor={successGreen}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default InstantPayoutsListItemDesktop;
