import React from "react";

import { Box, IconButton } from "@mui/material";

import { granite } from "../../design-system/colors";
import { TrashIcon, EditIcon } from "../../design-system/icons";
import { useScreenSize } from "../../globals/hooks";
import MoovsEllipsisMenu from "components/MoovsEllipsisMenu";

type EditRemoveStopButtonsProps = {
  canRemoveStop: boolean;
  onRemoveStopClick: () => void;
  onEditAdditionalStopInfoClick: () => void;
  stopLabel: string;
  canEditStop?: boolean;
};

function EditRemoveStopButtons(props: EditRemoveStopButtonsProps) {
  const {
    canRemoveStop,
    onRemoveStopClick,
    onEditAdditionalStopInfoClick,
    stopLabel,
    canEditStop = true,
  } = props;

  const { isMobileView } = useScreenSize();

  // event handlers
  const handleEditAdditionalStopClick = () => {
    onEditAdditionalStopInfoClick();
  };

  const handleRemoveStopClick = () => {
    onRemoveStopClick();
  };

  return (
    <Box>
      {isMobileView ? (
        <>
          <MoovsEllipsisMenu
            options={[
              {
                icon: <TrashIcon color={granite} />,
                text: "Remove Stop",
                hideOption: !canRemoveStop,
                onClick: handleRemoveStopClick,
              },
              {
                icon: <EditIcon color={granite} />,
                text: `Additional ${stopLabel} Info`,
                hideOption: !canEditStop,
                onClick: handleEditAdditionalStopClick,
              },
            ]}
          />
        </>
      ) : (
        <>
          {/* desktop buttons */}
          {canRemoveStop && (
            <IconButton onClick={handleRemoveStopClick} size="large" data-testid="remove-stop">
              <TrashIcon color={granite} />
            </IconButton>
          )}
          {canEditStop && (
            <IconButton onClick={handleEditAdditionalStopClick} size="large" data-testid="edit-stop">
              <EditIcon color={granite} />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
}

export default EditRemoveStopButtons;
