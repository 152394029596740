/**
 * @file graphql/twilio
 * queries/mutations for Twilio.
 *
 */

import { gql } from "@apollo/client";

// gets phone numbers from sid
export const LOAD_PHONE_NUMBER_BY_SID_QUERY = gql`
  query TwilioNumberBySid($phoneSid: String) {
    twilioNumberBySid(phoneSid: $phoneSid)
  }
`;

// gets 10 available phone numbers to purchase
export const LOAD_AVAILABLE_NUMBERS_QUERY = gql`
  query AvailableNumbers {
    availableNumbersTwilio
  }
`;

// generates a new new token for twilio sdk
export const LOAD_TWILIO_ACCESS_TOKEN_QUERY = gql`
  query AccessToken {
    accessTokenTwilio
  }
`;

// provisions phone number on twilio for operator messaging
export const PROVISION_PHONE_NUMBER_TWILIO_MUTATION = gql`
  mutation ProvisionPhoneNumberTwilio(
    $input: ProvisionPhoneNumberTwilioInput!
  ) {
    provisionPhoneNumberTwilio(input: $input) {
      operator {
        id
        driverReminderTripReminderTextsEnabled
        twilioPhoneNumber
      }
    }
  }
`;

// deprovisions phone number on twilio for operator messaging
export const PORT_PHONE_NUMBER_TWILIO_MUTATION = gql`
  mutation PortTwilioNumber($input: PortTwilioNumberInput!) {
    portTwilioNumber(input: $input) {
      operator {
        id
        driverReminderTripReminderTextsEnabled
        twilioPhoneNumber
      }
    }
  }
`;

export const ENABLE_AUTOMATED_TEXTS_MUTATION = gql`
  mutation EnableAutomatedTexts($input: EnableAutomatedTextsInput!) {
    enableAutomatedTexts(input: $input) {
      operator {
        id
        driverReminderTripReminderTextsEnabled
      }
    }
  }
`;

export const DISABLE_AUTOMATED_TEXTS_MUTATION = gql`
  mutation DisableAutomatedTexts($input: DisableAutomatedTextsInput!) {
    disableAutomatedTexts(input: $input) {
      operator {
        id
        driverReminderTripReminderTextsEnabled
      }
    }
  }
`;

export const LOAD_CHAT_PARTICIPANTS_QUERY = gql`
  query LoadChatParticipants($chatParticipants: [LoadChatParticipantInput]!) {
    loadChatParticipants(chatParticipants: $chatParticipants) {
      conversationSid
      participant {
        ... on Driver {
          id
          __typename
          firstName
          lastName
          mobilePhone
          # alias email to driverEmail as conflict between driver/contact
          # because driver.email is nullable and contact.email isn't
          driverEmail: email
        }
        ... on Contact {
          id
          __typename
          firstName
          lastName
          mobilePhone
          mobilePhoneInternational
          email
          phoneCountryCode
          phoneCountryDialCode
          phoneCountryName
          phoneCountryFormat
          homeAddress
          workAddress
        }
      }
    }
  }
`;
