// @ts-nocheck fix-error in file
import React from "react";
import NumberFormat from "react-number-format";

type NumberFormatCustomProps = {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  [key: string]: unknown;
};

const NumberFormatNatural = React.forwardRef<
  NumberFormat,
  NumberFormatCustomProps
>(function (props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={0}
      thousandSeparator
      allowNegative={false}
      isNumericString
    />
  );
});

const NumberFormatDollar = React.forwardRef<
  NumberFormat,
  NumberFormatCustomProps
>(function (props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      allowNegative={false}
      decimalScale={2}
      isNumericString
      prefix="$ "
      inputMode="decimal"
      {...(other.fixedDecimalScale && { fixedDecimalScale: true })}
    />
  );
});

const NumberFormatDollarNoPrefix = React.forwardRef<
  NumberFormat,
  NumberFormatCustomProps
>(function (props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      allowNegative={false}
      decimalScale={2}
      isNumericString
      inputMode="decimal"
    />
  );
});

const NumberFormatDecimal = React.forwardRef<
  NumberFormat,
  NumberFormatCustomProps
>(function (props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      allowNegative={false}
      decimalScale={2}
      isNumericString
      inputMode="decimal"
      {...other}
    />
  );
});

const NumberFormatPercent = React.forwardRef<
  NumberFormat,
  NumberFormatCustomProps
>(function (props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      decimalScale={3}
      isNumericString
      suffix="%"
      {...other}
    />
  );
});

type NumberFormatDisplayProps = {
  value: any;
};

const NumberFormatDisplay = React.forwardRef<
  NumberFormat,
  NumberFormatDisplayProps
>((props, ref) => {
  const { value, ...other } = props;
  return (
    <NumberFormat
      displayType="text"
      thousandSeparator
      allowNegative={false}
      value={value}
      decimalScale={2}
      fixedDecimalScale
      prefix="$"
      isNumericString
      {...other}
    />
  );
});

const NumberFormatFlight = React.forwardRef<NumberFormat, { value: number }>(
  function (props, ref) {
    const { value, ...other } = props;
    return (
      <NumberFormat
        getInputRef={ref}
        value={value}
        isNumericString
        decimalScale={0}
        {...other}
      />
    );
  }
);

export {
  NumberFormatNatural,
  NumberFormatDecimal,
  NumberFormatDollar,
  NumberFormatDollarNoPrefix,
  NumberFormatPercent,
  NumberFormatDisplay,
  NumberFormatFlight,
};
