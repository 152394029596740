/**
 * @file UpdateVehicleDrawer.tsx
 * Drawer for updating / viewing existing vehicles.
 *
 * components:
 *  UpdateVehicleDrawer
 *
 * author: jackv
 */

import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment-timezone";
import { useQuery, useMutation } from "@apollo/client";
import { FieldErrors } from "react-hook-form";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";

import { Box, Button, CircularProgress, Typography } from "@mui/material";

import VehicleHeaderUpdateBlock from "../VehicleHeaderUpdateBlock";
import RemoveDialog from "../../RemoveDialog";
import UpgradePlanDialog from "../../UpgradePlanDialog";
import UpdateDrawer from "../../globals/UpdateDrawer";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import {
  Vehicle,
  VehicleFeature,
  VehiclePhoto,
  VehicleSettings,
  PlanVariant,
  UpdateVehiclePayload,
  LoadVehiclesConnection,
  UpdateVehicleInput,
  PricelessBookingTarget,
} from "../../../types";
import {
  UPDATE_VEHICLE_MUTATION,
  REMOVE_VEHICLE_MUTATION,
  ADD_VEHICLE_TO_DUDA_WEBSITE,
  LOAD_VEHICLES_NAME_AND_ID_QUERY,
} from "../../../globals/graphql";
import GQLQueryStatusIndicator from "../../GQLQueryStatusIndicator";
import {
  CopyIcon,
  TrashIcon,
  WarningTriangleIcon,
  WebsiteBrowserIcon,
} from "../../../design-system/icons";
import { errorRed, grayDark, moovsBlue } from "../../../design-system/colors";
import CommentUpdateBlock from "../../CommentUpdateBlock";
import {
  useAnalytics,
  useOperator,
  useScreenSize,
} from "../../../globals/hooks";
import {
  BookingToolSettings,
  Details,
  Features,
  Pricing,
  VehicleTabs,
} from "./components";
import { VehicleTabViewVariant } from "./components/VehicleTabViews/types";
import {
  useUpdateVehicleForm,
  UpdateVehicleFormProvider,
  UpdateVehicleFormData,
} from "./hooks/useUpdateVehicleForm";
import { getDirtyFields } from "globals/utils/reactHookForm/getDirtyFields";
import DiscardChangesDialog from "components/DiscardChangesDialog";

const MAX_FREE_PLAN_VEHICLES_COUNT = 10;

export type HistoryState = {
  clonedVehicle?: Partial<Vehicle>;
  clonedPhotos?: VehiclePhoto[];
  clonedFeatures?: VehicleFeature[];
  clonedSettings?: VehicleSettings;
};

function UpdateVehicleDrawer() {
  // hooks
  const history = useHistory<HistoryState>();
  const snackbar = useSnackbar();
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const { track } = useAnalytics();
  const operator = useOperator();
  const { isMobileView } = useScreenSize();

  // state
  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "loading" | "saved" | "error"
  >("default");
  const [publishedIndicatorState, setPublishedIndicatorState] = useState<
    "default" | "publishing" | "published" | "error"
  >("default");
  const [removeVehicleDialogOpen, setRemoveVehicleDialogOpen] = useState(false);
  const [upgradePlanDialogOpen, setUpgradePlanDialogOpen] = useState(false);
  const [discardChangesDialogOpen, setDiscardChangesDialogOpen] =
    useState(false);
  const [vehicleTabView, setVehicleTabView] = useState(
    VehicleTabViewVariant.DETAILS
  );
  const [tabHasError, setTabHasError] = useState({
    [VehicleTabViewVariant.DETAILS]: false,
    [VehicleTabViewVariant.FEATURES]: false,
    [VehicleTabViewVariant.PRICING]: false,
    [VehicleTabViewVariant.BOOKING_TOOL_SETTINGS]: false,
  });

  const {
    readyOnlyVehicle,
    vehicleRefetch,
    photos,
    setPhotos,
    isLoading,
    methods,
    isDirty,
    dirtyFields,
    getDuplicateVehicle,
    loadVehicleError,
  } = useUpdateVehicleForm(vehicleId);

  // queries
  const { data: vehiclesData } = useQuery<{
    loadVehicles: LoadVehiclesConnection;
  }>(LOAD_VEHICLES_NAME_AND_ID_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const { skipVehicleSelectionEnabled, skipVehicleDefaultVehicleId } =
    operator?.settings || {};

  // mutations
  const [removeVehicle] = useMutation(REMOVE_VEHICLE_MUTATION, {
    refetchQueries: ["loadVehicles"],
    awaitRefetchQueries: true,
    onCompleted() {
      track("vehicle_deleted");
      snackbar.success("Successfully deleted vehicle!");
      history.push("/vehicles");
    },
    onError(error) {
      snackbar.error("Error deleting vehicle");
    },
  });

  const [updateVehicle] = useMutation<
    {
      updateVehicle: UpdateVehiclePayload;
    },
    { input: UpdateVehicleInput }
  >(UPDATE_VEHICLE_MUTATION, {
    onCompleted(data) {
      setSaveIndicatorState("saved");

      if (data.updateVehicle.vehicle?.publishedToDudaSite) {
        setPublishedIndicatorState("publishing");
        setTimeout(() => {
          // arbitrary timeout to simulate publishing "time"
          setPublishedIndicatorState("default");
        }, 3000);
      }
    },
    onError(error) {
      setSaveIndicatorState("error");
      snackbar.error("Error updating vehicle");

      // resets to match cache
      methods.reset();
    },
  });

  const [addVehicleToDudaWebsite] = useMutation(ADD_VEHICLE_TO_DUDA_WEBSITE, {
    onCompleted() {
      setSaveIndicatorState("saved");
      setPublishedIndicatorState("publishing");
      setTimeout(() => {
        // arbitrary timeout to simulate publishing "time"
        setPublishedIndicatorState("default");
      }, 3000);
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error("Error publishing vehicle");
    },
  });

  // effects
  useEffect(() => {
    if (saveIndicatorState === "saved" && isDirty) {
      setSaveIndicatorState("default");
    }
  }, [saveIndicatorState, isDirty]);

  // event handlers
  const handleVehiclePhotosChange = (newPhotos: VehiclePhoto[]) => {
    setPhotos([...newPhotos]);
  };

  const handleResetPhotos = () => {
    setPhotos(readyOnlyVehicle.photos);
    vehicleRefetch();
  };

  const handleRemoveVehicle = () => {
    removeVehicle({
      variables: {
        input: {
          id: vehicleId,
        },
      },
    });
    setRemoveVehicleDialogOpen(false);
  };

  const handleDuplicateVehicleClickWithLimitReached = useCallback(() => {
    track("vehicles_createInitiated", {
      limitRestriction: "limit reached",
    });
    setUpgradePlanDialogOpen(true);
  }, [track, setUpgradePlanDialogOpen]);

  const handleUpgradePlanClick = () => {
    track("vehicle_upgradePlan");
    history.push("/settings/billing/plans");
  };

  const handleSubmit = () => {
    const handleValidSubmit = (formData: UpdateVehicleFormData) => {
      setSaveIndicatorState("loading");

      const dataToSubmit = getDirtyFields(dirtyFields, formData);

      if (isEmpty(dataToSubmit)) {
        setSaveIndicatorState("default");
        return;
      }

      updateVehicle({
        variables: {
          input: {
            id: vehicleId,
            cancellationPolicyId: dataToSubmit.vehicle?.cancellationPolicyId,
            insuranceId: dataToSubmit.vehicle?.insuranceId,
            name: dataToSubmit.vehicle?.name,
            typeSlug: dataToSubmit.vehicle?.typeSlug,
            capacity: dataToSubmit.vehicle?.capacity,
            licensePlate: dataToSubmit.vehicle?.licensePlate,
            description: dataToSubmit.vehicle?.description,
            exteriorColor: dataToSubmit.vehicle?.exteriorColor,
            vinNumber: dataToSubmit.vehicle?.vinNumber,
            minimumTotalBaseRate: dataToSubmit.vehicle?.minimumTotalBaseRate,
            deadheadRatePerMile: dataToSubmit.vehicle?.deadheadRatePerMile,
            tripRatePerMile: dataToSubmit.vehicle?.tripRatePerMile,
            totalDeadheadDurationMinutes:
              dataToSubmit.vehicle?.totalDeadheadDurationMinutes,
            weekdayHourlyCost: dataToSubmit.vehicle?.weekdayHourlyCost,
            weekdayMinMinutes: dataToSubmit.vehicle?.weekdayMinMinutes,
            weekendHourlyCost: dataToSubmit.vehicle?.weekendHourlyCost,
            weekendMinMinutes: dataToSubmit.vehicle?.weekendMinMinutes,
            weekends: dataToSubmit.vehicle?.weekends,
            enableBaseRateAutomation:
              dataToSubmit.vehicle?.enableBaseRateAutomation,
            available: dataToSubmit.vehicle?.available,
            enableBaseRateAutomationBookingTool:
              dataToSubmit.vehicle?.enableBaseRateAutomationBookingTool,
            settings: {
              conflictBlockQuote:
                dataToSubmit.vehicle?.settings?.conflictBlockQuote,
              conflictBlockReservation:
                dataToSubmit.vehicle?.settings?.conflictBlockReservation,
              pricelessBookingEnabled:
                dataToSubmit.vehicle?.settings?.pricelessBookingEnabled,
              pricelessBookingTarget: dataToSubmit.vehicle?.settings
                ?.pricelessBookingTarget as PricelessBookingTarget,
              pricelessBookingCompaniesIds:
                dataToSubmit.vehicle?.settings?.pricelessBookingCompanies?.map(
                  (company) => company.id
                ),
              pricelessBookingContactsIds:
                dataToSubmit.vehicle?.settings?.pricelessBookingContacts?.map(
                  (contact) => contact.id
                ),
            },
            // Feature Ids need for input
            ...(dataToSubmit.features && {
              featureIds: dataToSubmit.features.map((feature) => feature.id),
            }),
            // shape child seats into array for input
            ...(dataToSubmit.childSeats && {
              childSeats: Object.values(dataToSubmit.childSeats).map(
                (childSeat) => ({
                  ...omit(childSeat, "id", "type", "__typename"),
                  vehicleChildSeatId: childSeat.id,
                })
              ),
            }),
          },
        },
      });
    };

    const handleInvalidSubmit = (
      invalidFields: FieldErrors<UpdateVehicleFormData>
    ) => {
      const detailsHasErrors = Boolean(
        invalidFields?.vehicle?.name ||
          invalidFields?.vehicle?.typeSlug ||
          invalidFields?.vehicle?.capacity ||
          invalidFields?.vehicle?.licensePlate
      );
      const featuresHasErrors = Boolean(
        invalidFields.features || invalidFields.childSeats
      );
      const pricingHasErrors = Boolean(
        invalidFields?.vehicle?.minimumTotalBaseRate ||
          invalidFields?.vehicle?.deadheadRatePerMile ||
          invalidFields?.vehicle?.tripRatePerMile ||
          invalidFields?.vehicle?.weekendHourlyCost ||
          invalidFields?.vehicle?.weekdayHourlyCost ||
          invalidFields?.vehicle?.weekendMinMinutes ||
          invalidFields?.vehicle?.weekdayMinMinutes ||
          invalidFields?.vehicle?.weekends
      );
      const bookingToolSettingsHasErrors = Boolean(
        invalidFields?.vehicle?.available
      );

      setTabHasError({
        [VehicleTabViewVariant.DETAILS]: detailsHasErrors,
        [VehicleTabViewVariant.FEATURES]: featuresHasErrors,
        [VehicleTabViewVariant.PRICING]: pricingHasErrors,
        [VehicleTabViewVariant.BOOKING_TOOL_SETTINGS]:
          bookingToolSettingsHasErrors,
      });
    };

    return methods.handleSubmit(handleValidSubmit, handleInvalidSubmit)();
  };

  const checkIfChangesAreSaved = useCallback(() => {
    if (isDirty) {
      setDiscardChangesDialogOpen(true);
      return false;
    }
    return true;
  }, [isDirty, setDiscardChangesDialogOpen]);

  const duplicateVehicle = useCallback(() => {
    if (!checkIfChangesAreSaved()) return;
    const historyState = getDuplicateVehicle();
    history.push("/vehicles/create", historyState);
  }, [checkIfChangesAreSaved, getDuplicateVehicle, history]);

  const addVehicleToDudaSite = useCallback(() => {
    if (!checkIfChangesAreSaved()) return;

    setSaveIndicatorState("loading");
    addVehicleToDudaWebsite({
      variables: {
        input: {
          vehicleId,
        },
      },
    });
  }, [
    checkIfChangesAreSaved,
    setSaveIndicatorState,
    addVehicleToDudaWebsite,
    vehicleId,
  ]);

  const UpdateVehicleDrawerProps = useMemo(() => {
    if (!readyOnlyVehicle || loadVehicleError) return {};

    return {
      validateBeforeClose: checkIfChangesAreSaved,
      createdAt: moment(readyOnlyVehicle.createdAt).format("LLL"),
      // used for the saved indicator tooltip
      ...(saveIndicatorState !== "default" && {
        updatedAt: moment(readyOnlyVehicle.updatedAt).format("LLL"),
      }),
      publishedAt: readyOnlyVehicle.publishedToDudaSite
        ? moment(readyOnlyVehicle.updatedAt).format("LLL")
        : null,
      saveIndicatorState: saveIndicatorState,
      saveIndicatorStateMapOverride: {
        default: {
          label: "",
          icon: <></>,
        },
      },
      publishedIndicatorState: publishedIndicatorState,
      ellipsisMenuOptions: [
        {
          onClick: addVehicleToDudaSite,
          text: readyOnlyVehicle.publishedToDudaSite
            ? "Already Published"
            : "Publish on Website",
          disableOption: readyOnlyVehicle.publishedToDudaSite,
          icon: <WebsiteBrowserIcon color={grayDark} size="small" />,
        },
        {
          onClick:
            operator?.plan === PlanVariant.Free &&
            vehiclesData?.loadVehicles.edges?.length >=
              MAX_FREE_PLAN_VEHICLES_COUNT
              ? handleDuplicateVehicleClickWithLimitReached
              : duplicateVehicle,
          text: "Duplicate Vehicle",
          icon: <CopyIcon color={grayDark} size="small" />,
        },
        {
          onClick: () => {
            if (
              vehicleId === skipVehicleDefaultVehicleId &&
              skipVehicleSelectionEnabled
            ) {
              return snackbar.error(
                <>
                  <Typography variant="subtitle2" color={errorRed}>
                    This is your default vehicle in the customer portal.
                  </Typography>{" "}
                  <Typography variant="body2" color={errorRed} py={0.5}>
                    To delete it, first select a different one from your
                    settings
                  </Typography>
                </>,
                {
                  icon: <WarningTriangleIcon color={errorRed} size="large" />,
                  link: `/settings/customer-portal?tab=settings`,
                  linkLabel: "Go to Settings",
                  linkColor: moovsBlue,
                }
              );
            }
            setRemoveVehicleDialogOpen(true);
          },
          text: "Delete",
          icon: <TrashIcon color={grayDark} size="small" />,
        },
      ],
      secondHeaderContent: (
        <>
          <Box px={isMobileView ? 2 : 4}>
            <VehicleHeaderUpdateBlock
              vehicle={readyOnlyVehicle}
              setSaveIndicatorState={setSaveIndicatorState}
            />
          </Box>
          <VehicleTabs
            setVehicleTabView={setVehicleTabView}
            vehicleTabView={vehicleTabView}
            tabHasError={tabHasError}
          />
        </>
      ),
    };
  }, [
    loadVehicleError,
    readyOnlyVehicle,
    checkIfChangesAreSaved,
    saveIndicatorState,
    publishedIndicatorState,
    addVehicleToDudaSite,
    operator?.plan,
    vehiclesData?.loadVehicles.edges?.length,
    handleDuplicateVehicleClickWithLimitReached,
    duplicateVehicle,
    vehicleId,
    skipVehicleDefaultVehicleId,
    skipVehicleSelectionEnabled,
    snackbar,
    setRemoveVehicleDialogOpen,
    isMobileView,
    setVehicleTabView,
    vehicleTabView,
    tabHasError,
  ]);

  return (
    <UpdateVehicleFormProvider methods={methods}>
      <UpdateDrawer
        onClose={() => history.push("/vehicles")}
        footerContent={
          <Box mr={5}>
            <Button
              disabled={
                !isDirty ||
                saveIndicatorState === "loading" ||
                publishedIndicatorState === "publishing"
              }
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Box>
        }
        {...UpdateVehicleDrawerProps}
      >
        {isLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={40} thickness={2} />
          </Box>
        )}
        {loadVehicleError && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <GQLQueryStatusIndicator
              name="Vehicle"
              data={readyOnlyVehicle}
              error={loadVehicleError}
              refetch={vehicleRefetch}
            />
          </Box>
        )}
        {readyOnlyVehicle && photos && (
          <Box>
            {/* Selected Tab View */}
            {vehicleTabView === VehicleTabViewVariant.DETAILS && (
              <Details
                photos={photos}
                handleVehiclePhotosChange={handleVehiclePhotosChange}
                handleResetPhotos={handleResetPhotos}
                setSaveIndicatorState={setSaveIndicatorState}
              />
            )}
            {vehicleTabView === VehicleTabViewVariant.FEATURES && <Features />}
            {vehicleTabView === VehicleTabViewVariant.PRICING && <Pricing />}
            {vehicleTabView === VehicleTabViewVariant.BOOKING_TOOL_SETTINGS && (
              <BookingToolSettings />
            )}
            <Box mb={3} pt={4}>
              <CommentUpdateBlock
                mode="vehicle"
                comments={readyOnlyVehicle.comments}
                vehicleId={readyOnlyVehicle.id}
                refetchQuery={vehicleRefetch}
                setSaveIndicatorState={setSaveIndicatorState}
              />
            </Box>
            <RemoveDialog
              open={removeVehicleDialogOpen}
              onRemove={handleRemoveVehicle}
              onClose={() => setRemoveVehicleDialogOpen(false)}
              title="Remove this vehicle?"
              body="This will permanantly remove the vehicle. Do you want to remove this
            vehicle?"
            />

            <UpgradePlanDialog
              open={upgradePlanDialogOpen}
              onUpgrade={handleUpgradePlanClick}
              onClose={() => setUpgradePlanDialogOpen(false)}
              body={
                <Typography variant="body2">
                  You must upgrade your plan in order to{" "}
                  <strong>
                    create more than {MAX_FREE_PLAN_VEHICLES_COUNT} vehicles
                  </strong>
                  .
                </Typography>
              }
            />

            <DiscardChangesDialog
              open={discardChangesDialogOpen}
              onClose={() => setDiscardChangesDialogOpen(false)}
              onDiscard={() => history.push("/vehicles")}
            />
          </Box>
        )}
      </UpdateDrawer>
    </UpdateVehicleFormProvider>
  );
}

export default UpdateVehicleDrawer;
