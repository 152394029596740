import React from "react";

type ClickedIconProps = {
  color?: string;
};

const ClickedIcon = (props: ClickedIconProps) => {
  const { color } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.02623 5.83514L7.12858 12.8428C7.16088 12.9508 7.22066 13.0486 7.30205 13.1265C7.38344 13.2045 7.48367 13.26 7.59293 13.2876C7.70219 13.3152 7.81673 13.3139 7.92537 13.284C8.03401 13.254 8.13301 13.1964 8.2127 13.1167L9.5 11.8291L12.2629 14.5927C12.3233 14.6532 12.3951 14.7011 12.4741 14.7339C12.5531 14.7666 12.6378 14.7835 12.7233 14.7835C12.8089 14.7835 12.8936 14.7666 12.9726 14.7339C13.0516 14.7011 13.1234 14.6532 13.1838 14.5927L14.5913 13.1847C14.6518 13.1242 14.6998 13.0524 14.7325 12.9734C14.7653 12.8944 14.7821 12.8097 14.7821 12.7241C14.7821 12.6386 14.7653 12.5539 14.7325 12.4748C14.6998 12.3958 14.6518 12.324 14.5913 12.2635L11.8285 9.5L13.1171 8.21419C13.1968 8.13454 13.2545 8.03557 13.2845 7.92695C13.3145 7.81833 13.3158 7.70379 13.2883 7.59452C13.2608 7.48525 13.2054 7.38498 13.1275 7.30353C13.0497 7.22208 12.952 7.16222 12.8441 7.12981L5.83736 5.02695C5.72503 4.9936 5.6058 4.99116 5.4922 5.01987C5.3786 5.04857 5.27484 5.10738 5.19183 5.19009C5.10882 5.2728 5.04963 5.37636 5.02048 5.48987C4.99134 5.60338 4.99333 5.72266 5.02623 5.83514Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.30143 2.8484C8.77185 2.1406 8.05003 1.59998 7.22195 1.29093C6.39388 0.981893 5.49444 0.917455 4.63079 1.1053C3.76713 1.29314 2.97565 1.72534 2.35063 2.35042C1.72561 2.97551 1.29338 3.76713 1.10544 4.63099C0.917498 5.49485 0.981758 6.39455 1.29057 7.22291C1.59938 8.05127 2.13972 8.77339 2.84723 9.30324"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClickedIcon;
