import React, { ChangeEvent } from "react";
import { NumberFormatValues } from "react-number-format";

import { Box, TextField, Typography } from "@mui/material";

import AutomatedBaseRateButton from "components/buttons/AutomatedBaseRateButton";
import { BRAStatusEnum } from "components/buttons/types";
import {
  NumberFormatDecimal,
  NumberFormatDisplay,
  NumberFormatDollar,
} from "design-system/components/inputs/NumberFormat";
import { useScreenSize } from "globals/hooks";
import {
  normalizeValueToBeDisplayedAsDollar,
  DollarPercentVariant,
} from "components/pricing/utils";
import { normalizeOnInputDollarValue } from "components/pricing/utils/displayUtils";
import { BaseRateAutomation } from "types";

type HourlyBaseRateFieldsProps = {
  flatRate: number;
  baseRateAmtError: string;
  hourlyBaseRate: number;
  hourlyBaseRateHours: number;
  onHourlyBaseRateInput: (hourlyRate: number) => void;
  onHourlyBaseRateHoursInput: (hours: number) => void;
  onActivateBRAClick: () => void;
  BRAProps: {
    automatedBaseRate: BaseRateAutomation;
    hasAutomatedBaseRate: boolean;
    BRAStatus: BRAStatusEnum;
    isLoadingBRA: boolean;
  };
  isFarmedRoute?: boolean;
};

const { Dollar } = DollarPercentVariant;

function HourlyBaseRateFields(props: HourlyBaseRateFieldsProps) {
  const {
    flatRate,
    baseRateAmtError,
    hourlyBaseRate,
    hourlyBaseRateHours,
    onHourlyBaseRateInput,
    onHourlyBaseRateHoursInput,
    onActivateBRAClick,
    BRAProps: {
      automatedBaseRate,
      hasAutomatedBaseRate,
      BRAStatus,
      isLoadingBRA,
    },
    isFarmedRoute,
  } = props;

  const { isMobileView } = useScreenSize();

  // event handlers
  const roundInputValueToNearestQuarterHour = (value: NumberFormatValues) => {
    const floatValue = value.floatValue || 0;
    if (floatValue === 0) {
      onHourlyBaseRateHoursInput(0);
    } else {
      const roundedNumber = Number((Math.ceil(floatValue * 4) / 4).toFixed(2));
      onHourlyBaseRateHoursInput(roundedNumber);
    }
  };

  const handleHourlyBRRateInput = (e: ChangeEvent<HTMLInputElement>) => {
    onHourlyBaseRateInput(normalizeOnInputDollarValue(e));
  };

  // render functions
  const renderBRAandFlatRate = () => {
    return (
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        flex={isMobileView ? 1 : 0.5}
      >
        {(hasAutomatedBaseRate || isLoadingBRA) && !isFarmedRoute && (
          <AutomatedBaseRateButton
            automatedBaseRate={automatedBaseRate}
            BRAStatus={BRAStatus}
            isLoadingBRA={isLoadingBRA}
            onActivateBRAClick={onActivateBRAClick}
          />
        )}
        <Typography variant="subtitle1" align="right" style={{ marginLeft: 2 }}>
          <NumberFormatDisplay value={flatRate || 0} />
        </Typography>
      </Box>
    );
  };

  // derived state
  const hourFieldSuffix = isMobileView
    ? Number(hourlyBaseRateHours) === 1
      ? " Hr"
      : " Hrs"
    : Number(hourlyBaseRateHours) === 1
    ? " Hour"
    : " Hours";

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection={isMobileView ? "column" : "row"}
    >
      <Box flex={1} {...(!isMobileView && { pr: 1 })}>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Enter Hourly Rate"
          name="hourlyBaseRate"
          onInput={handleHourlyBRRateInput}
          value={normalizeValueToBeDisplayedAsDollar({
            value: hourlyBaseRate,
            variant: Dollar,
          })}
          InputProps={{
            inputComponent: NumberFormatDollar as any,
          }}
          {...(!!baseRateAmtError && {
            error: true,
            helperText: baseRateAmtError,
          })}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flex={1}
        {...(isMobileView && { mt: 1 })}
      >
        <Box flex={1}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Hours"
            name="hourlyBaseRateHours"
            value={hourlyBaseRateHours}
            InputProps={{
              inputComponent: NumberFormatDecimal as any,
              inputProps: {
                onValueChange: roundInputValueToNearestQuarterHour,
                suffix: hourFieldSuffix,
              },
            }}
          />
        </Box>
        {isMobileView && renderBRAandFlatRate()}
      </Box>
      {!isMobileView && renderBRAandFlatRate()}
    </Box>
  );
}

export default HourlyBaseRateFields;
