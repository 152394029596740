import React from "react";
import moment from "moment";

import {
  Box,
  CircularProgress,
  Fade,
  IconButton,
  Typography,
} from "@mui/material";

import { TrackedFlight } from "types";
import { granite } from "design-system/colors";
import RoundChip from "design-system/components/chips/RoundChip";
import { SyncIcon } from "design-system/icons";
import flightStatusMap from "../../helpers/flightStatusMap";
import { PrioritizedFlightInfo } from "..";
import { getFlightTimePriorityQueue } from "components/flights/helpers/flightTimeHelpers";

type CompactFlightInfoCardProps = {
  isExpanded: boolean;
  transitStopType: "origin" | "destination";
  trackedFlight: TrackedFlight;
  refreshFlightProps?: {
    onClickRefreshFlight?: () => void;
    isRefreshingFlight?: boolean;
  };
};

function CompactFlightInfoCard(props: CompactFlightInfoCardProps) {
  const {
    transitStopType,
    trackedFlight,
    refreshFlightProps = {},
    isExpanded,
  } = props;

  const { onClickRefreshFlight, isRefreshingFlight } = refreshFlightProps;
  const { origin, destination, airline, flightNumber, flightStatus } =
    trackedFlight;

  const airlineHeader = `${airline.airlineName} (${airline.iataCode}) ${flightNumber}`;
  const isDestination = transitStopType === "destination";
  const isCancelledFlight = flightStatus === "CANCELLED";
  const flightTimePriorityQueue = getFlightTimePriorityQueue(
    isDestination ? destination : origin
  );
  const { statusColor, allowRefresh } = flightStatusMap(flightStatus);

  // helpers
  const flightDateOrTime = (dateTime, mode: "date" | "time") =>
    moment(dateTime).format(mode === "date" ? "M/D/YY" : "h:mmA");

  return (
    <>
      <Box
        display="flex"
        alignItems="space-between"
        justifyContent="space-between"
        mb={1}
      >
        {isRefreshingFlight ? (
          <Box p={1.5}>
            <CircularProgress size={20} thickness={4} />
          </Box>
        ) : (
          !!onClickRefreshFlight &&
          allowRefresh && (
            <IconButton onClick={onClickRefreshFlight} size="large">
              <SyncIcon color={granite} />
            </IconButton>
          )
        )}
      </Box>
      <Box display="flex" width="100%" justifyContent="space-between">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            sx={{ mb: 1, maxWidth: "220px" }}
            variant="subtitle2"
            fontWeight={700}
          >
            {airlineHeader}
          </Typography>
          <RoundChip
            label={flightStatus}
            color="white"
            backgroundColor={statusColor}
            textTransform="uppercase"
          />
        </Box>
        <Fade in={!isExpanded}>
          <Box
            sx={{
              pl: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-start",
            }}
          >
            <Typography variant="overline">
              {flightTimePriorityQueue[0].timeLabel}
            </Typography>
            <PrioritizedFlightInfo
              transitStopType={transitStopType}
              primaryText={flightDateOrTime(
                flightTimePriorityQueue[0].time,
                "date"
              )}
              secondaryText={
                flightTimePriorityQueue?.[1]
                  ? flightDateOrTime(flightTimePriorityQueue[1].time, "date")
                  : ""
              }
              primaryProps={{ variant: "caption", style: { fontWeight: 500 } }}
              isTime
              strikethroughAll={isCancelledFlight}
            />
            <PrioritizedFlightInfo
              transitStopType={transitStopType}
              primaryText={flightDateOrTime(
                flightTimePriorityQueue[0].time,
                "time"
              )}
              secondaryText={
                flightTimePriorityQueue?.[1]
                  ? flightDateOrTime(flightTimePriorityQueue[1].time, "time")
                  : ""
              }
              primaryProps={{ variant: "subtitle2" }}
              isTime
              strikethroughAll={isCancelledFlight}
            />
          </Box>
        </Fade>
      </Box>
    </>
  );
}

export default CompactFlightInfoCard;
