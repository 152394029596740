import React from "react";

import { ToggleButton, Box, CircularProgress } from "@mui/material";

import { grayDark, moovsBlue } from "design-system/colors";

type WidgetDesignButtonProps<T> = {
  value: T;
  isSelected: boolean;
  isDisabled: boolean;
  buttonImg: string;
  isUpdating: boolean;
};

function WidgetDesignButton<T>(props: WidgetDesignButtonProps<T>) {
  return (
    <ToggleButton
      {...props}
      color="primary"
      value={props.value}
      selected={props.isSelected}
      disabled={props.isDisabled}
      style={{
        overflow: "hidden",
        padding: "5px 15px",
        height: "64px",
        width: "156px",
        borderRadius: "4px",
        borderColor: props.isSelected ? moovsBlue : grayDark,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
      >
        {props.isUpdating ? (
          <CircularProgress />
        ) : (
          <img
            src={props.buttonImg}
            style={{ position: "absolute", top: "4px" }}
            alt="book now widget"
          />
        )}
      </Box>
    </ToggleButton>
  );
}

export { WidgetDesignButton };
