import {
  Company,
  Contact,
  GriddnetOperator,
  MoovsNetworkOperator,
  MoovsAiOperator,
  FarmAffiliateVariantEnum,
} from "types";

export const parseInvoiceRecipientIds = (
  invoiceRecipient:
    | Contact
    | MoovsNetworkOperator
    | MoovsAiOperator
    | Company
    | GriddnetOperator
) => {
  const contactId =
    invoiceRecipient.__typename === "Contact" ? invoiceRecipient.id : undefined;

  const farmorOperatorId =
    invoiceRecipient.__typename ===
      FarmAffiliateVariantEnum.MoovsNetworkOperator ||
    invoiceRecipient.__typename === FarmAffiliateVariantEnum.MoovsAiOperator ||
    invoiceRecipient.__typename === FarmAffiliateVariantEnum.GriddnetOperator
      ? invoiceRecipient.id
      : undefined;

  const companyId =
    invoiceRecipient.__typename === "Company" ? invoiceRecipient.id : undefined;

  return { contactId, companyId, farmorOperatorId };
};
