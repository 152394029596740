import React from "react";

type PreferedAddressIconProps = {
  color?: string;
  size?: "small";
};

const PreferedAddressIcon = (props: PreferedAddressIconProps) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.82646 1.49806L10.6197 5.65281H14.1097C14.286 5.64559 14.4603 5.69323 14.6085 5.78921C14.7568 5.88518 14.8718 6.02479 14.9378 6.18891C15.0039 6.35302 15.0177 6.5336 14.9774 6.70589C14.9371 6.87818 14.8446 7.03375 14.7127 7.15127L11.6839 9.86226L13.3619 13.7295C13.4356 13.9067 13.4521 14.1026 13.4089 14.2896C13.3657 14.4766 13.2651 14.6454 13.1213 14.772C12.9774 14.8987 12.7976 14.9769 12.6071 14.9956C12.4166 15.0143 12.225 14.9726 12.0594 14.8764L8.0085 12.5873L3.95763 14.8764C3.792 14.9726 3.60043 15.0143 3.40992 14.9956C3.21941 14.9769 3.03956 14.8987 2.89571 14.772C2.75186 14.6454 2.65127 14.4766 2.60811 14.2896C2.56494 14.1026 2.58139 13.9067 2.65512 13.7295L4.33467 9.86226L1.28717 7.15127C1.15549 7.03392 1.06315 6.87864 1.02277 6.70667C0.982393 6.5347 0.995946 6.35442 1.06158 6.19046C1.12721 6.02651 1.24172 5.88687 1.38946 5.79064C1.5372 5.69441 1.71097 5.64628 1.88701 5.65281H5.37855L7.17495 1.49806C7.25453 1.34767 7.37343 1.22185 7.51891 1.13411C7.66439 1.04636 7.83095 1 8.00071 1C8.17047 1 8.33702 1.04636 8.4825 1.13411C8.62798 1.22185 8.74689 1.34767 8.82646 1.49806Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PreferedAddressIcon;
