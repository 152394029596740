import { Card, Divider } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { TripCategory } from "types";
import { StringParam, useQueryParam } from "use-query-params";
import TripCardContent from "./TripCardContent";
//@ts-ignore fix later - import url does not exist
import { TripPanelTrip } from "../../../utils/types";

type TripCardProps = {
  trip: TripPanelTrip;
  orderNumber: string;
  requestId: string;
};

const TripCard = (props: TripCardProps) => {
  const {
    trip,
    trip: { tripCategory, returnTrip },
    orderNumber,
    requestId,
  } = props;

  // hooks
  const history = useHistory<{ backNavigationPath: string }>();
  const [queryParamTripId] = useQueryParam("tripId", StringParam);

  // derived state
  const isRoundTrip = tripCategory === TripCategory.RoundTrip;

  // render card content
  const renderTripCardContent = (
    trip: TripPanelTrip,
    isReturnTrip?: boolean
  ) => {
    const { tripNumber, id: tripId } = trip;
    const completeOrderNumber = `${orderNumber}-${tripNumber}`;

    return (
      <TripCardContent
        onClick={() => {
          history.push(
            `/reservations/${requestId}?tripId=${tripId}`,
            history.location.state
          );
        }}
        trip={trip}
        completeOrderNumber={completeOrderNumber}
        isSelected={queryParamTripId === tripId}
        hideStatus={isReturnTrip}
        hideTripCategory={isReturnTrip}
      />
    );
  };

  return (
    <Card variant="outlined">
      {renderTripCardContent(trip)}

      {isRoundTrip && returnTrip && (
        <>
          <Divider />
          {renderTripCardContent(returnTrip, true)}
        </>
      )}
    </Card>
  );
};

export default TripCard;
