import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import size from "lodash/size";

import { Box, CircularProgress, Typography } from "@mui/material";

import OnboardingStepper from "pages/auth/components/OnboardingStepper";
import OnboardingImageCarousel from "pages/auth/components/OnboardingImageCarousel/OnboardingImageCarousel";
import {
  useCurrentUser,
  useOperator,
  useScreenSize,
  useSnackbar,
} from "globals/hooks";
import { LOAD_VEHICLES_NAME_AND_ID_QUERY } from "globals/graphql";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import MoovsLogoWhiteOnBlue from "design-system/images/icons/MoovsLogoWhiteOnBlue.svg";
import MoovsLogoText from "design-system/images/icons/MoovsLogoText.svg";
import { granite, moovsBlueSelected } from "design-system/colors";
import {
  FirstDriverFields,
  FirstVehicleFields,
  GettingStartedFields,
  OperatorInfoFields,
  UserInfoFields,
} from "./components";
import { getRedirectPathBasedOnPermissions } from "globals/utils/getRedirectPathBasedOnPermissions";

function OnboardingPage() {
  // hooks
  const user = useCurrentUser();
  const operator = useOperator();
  const { isMobileView, isSmallTabletView } = useScreenSize();
  const history = useHistory();
  const snackbar = useSnackbar();

  // state
  const [isLoadingStep, setIsLoadingStep] = useState(true);
  const [activeStep, setActiveStep] = useState<1 | 2 | 3 | 4 | 5>(1); // zero-based, step 0 is sign-up page

  // query
  const [loadVehicles, { data: loadVehiclesData, loading: loadingVehicles }] =
    useLazyQuery(LOAD_VEHICLES_NAME_AND_ID_QUERY, {
      onError: (error) => {
        const errorMessage = getErrorMessage(error) || error.message;

        snackbar.error(errorMessage);
      },
      fetchPolicy: "cache-first",
    });

  // effects
  useEffect(() => {
    if (user.operator.onboardingCompleted) {
      history.replace(
        getRedirectPathBasedOnPermissions({
          userAccessPermissions: user?.accessPermissions || [],
          preferredPath: "/settings/customer-portal",
        })
      );
    }

    // leave in loading state
    if (!user || !operator) return;

    // load step on first load only
    if (!isLoadingStep) return;

    if (user.mobilePhone && !operator.name) {
      setActiveStep(2);
    }

    if (user.mobilePhone && operator.name && !operator.gettingStarted?.goal) {
      setActiveStep(3);
    }

    if (user.mobilePhone && operator.name && operator.gettingStarted?.goal) {
      if (loadingVehicles) return;

      if (!loadVehiclesData) {
        loadVehicles();
        return;
      }

      if (size(loadVehiclesData.loadVehicles.edges)) {
        setActiveStep(5);
      } else {
        setActiveStep(4);
      }
    }

    setIsLoadingStep(false);
  }, [
    history,
    isLoadingStep,
    user,
    operator,
    loadingVehicles,
    loadVehicles,
    loadVehiclesData,
  ]);

  const onboardingSteps = {
    1: {
      title: "Welcome To Moovs",
      subtitle: "",
      component: <UserInfoFields setActiveStep={setActiveStep} />,
    },
    2: {
      title: "We need a few more details about your company",
      subtitle: "",
      component: <OperatorInfoFields setActiveStep={setActiveStep} />,
    },
    3: {
      title: "What do you want to accomplish first with Moovs?",
      subtitle: `Pick your top priority need now, and we'll set you up to achieve it quickly`,
      component: <GettingStartedFields setActiveStep={setActiveStep} />,
    },
    4: {
      title: "Create your first vehicle!",
      subtitle:
        "Creating a vehicle will give you access to display it on your Customer Portal so you can start receiving requests!",
      component: <FirstVehicleFields setActiveStep={setActiveStep} />,
    },
    5: {
      title: "Create your first driver!",
      subtitle: "Creating a driver will allow you to start sending them trips!",
      component: <FirstDriverFields />,
    },
  };

  return (
    <Box
      display="flex"
      bgcolor={moovsBlueSelected}
      {...(isSmallTabletView && { height: "100%", minHeight: "100vh" })}
    >
      <Box
        flex={1}
        mt={4}
        mx={4}
        flexDirection="column"
        justifyContent="space-between"
        display="flex"
        gap={8}
        overflow="hidden"
      >
        <Box display="flex">
          <Box
            component="img"
            sx={{ logo: { height: 45 } }}
            src={MoovsLogoWhiteOnBlue}
            alt="MoovsLogoWhiteOnBlue"
          />
          <Box
            component="img"
            ml={1.5}
            sx={{ logo: { height: 45 } }}
            src={MoovsLogoText}
            alt="MoovsLogoText"
          />
        </Box>
        {isLoadingStep ? (
          <>
            <Box textAlign="center">
              <CircularProgress />
            </Box>
            <Box />
          </>
        ) : (
          <>
            <Box>
              <Typography
                variant="h1"
                mb={onboardingSteps[activeStep].subtitle ? 2 : 5}
              >
                {onboardingSteps[activeStep].title}
              </Typography>
              <Typography variant="body1" color={granite} mb={5}>
                {onboardingSteps[activeStep].subtitle}
              </Typography>

              {onboardingSteps[activeStep].component}
            </Box>
            <Box display="flex" justifyContent="center" alignContent="center">
              <OnboardingStepper activeStep={activeStep} />
            </Box>
          </>
        )}
      </Box>
      {!isMobileView && !isSmallTabletView && (
        <Box flex={2}>
          <OnboardingImageCarousel />
        </Box>
      )}
    </Box>
  );
}

export default OnboardingPage;
