import React from "react";

type UnArchiveIconProps = {
  color?: string;
};

const UnArchiveIcon = (props: UnArchiveIconProps) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7778 4.33325V7.66659M2.22226 4.33325V11.4871C2.22226 11.9767 2.40956 12.4463 2.74296 12.7925C3.07636 13.1387 3.52854 13.3333 4.00004 13.3333H4.66671M13.7778 1.33325H2.22226C1.98651 1.33325 1.76042 1.4305 1.59372 1.60361C1.42702 1.77673 1.33337 2.01151 1.33337 2.25633V4.10248H14.6667V2.25633C14.6667 2.01151 14.5731 1.77673 14.4064 1.60361C14.2397 1.4305 14.0136 1.33325 13.7778 1.33325ZM9.38099 9.33325C9.38099 9.33325 15.3334 8.60598 15.3334 14.6666C13.4286 11.0302 9.38099 11.5151 9.38099 11.5151V14.1817L5.33337 10.3029L9.38099 6.66659V9.33325Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UnArchiveIcon;
