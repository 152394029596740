import React from "react";

import { Box, Typography } from "@mui/material";

import MoovsTooltip from "components/MoovsTooltip";
import { CardNoteTooltip } from "components/requests/ChargePayerDialog/components/CardNoteTooltip";
import { moovsBlue, orange } from "design-system/colors";
import { LinkIcon, StarIcon } from "design-system/icons";
import { PaymentMethod } from "types";
import { useLaunchDarklyFlags } from "globals/utils/useLaunchDarklyFlags";
import LinkedCornerLeftIcon from "design-system/icons/info/LinkedCornerLeftIcon";
import { getContactName } from "globals/utils/contact";
import { CreditCardIcon } from "design-system/icons";

type MenuItemContentProps = {
  paymentMethod: PaymentMethod;
};

function MenuItemContent(props: MenuItemContentProps) {
  const { paymentMethod } = props;
  const { card } = paymentMethod;
  const { enableLinkedPassenger } = useLaunchDarklyFlags();

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box display="flex" justifyContent="flex-start">
        <Typography
          style={{
            display: "flex",
            textTransform: "capitalize",
            alignItems: "center",
            justifyItems: "center",
            columnGap: 0.5,
          }}
        >
          <CreditCardIcon brand={card.brand} />
          {paymentMethod?.billingDetails?.name} *** {card.last4}{" "}
          {`- ${card.expMonth}/${card.expYear.toString().slice(-2)}`}
        </Typography>
        {paymentMethod.isPrimary && (
          <Box ml={1} mt="1px" style={{ cursor: "pointer" }}>
            <MoovsTooltip tooltipText="Primary Card">
              <StarIcon size="small" color={orange} />
            </MoovsTooltip>
          </Box>
        )}
      </Box>
      {enableLinkedPassenger && paymentMethod.linkedPassenger && (
        <Box
          display="flex"
          alignContent="center"
          alignItems="center"
          columnGap={0.5}
        >
          <LinkedCornerLeftIcon />
          <LinkIcon size="small" color={moovsBlue} />
          <Typography variant="body2" color={moovsBlue} fontWeight={500}>
            {getContactName(paymentMethod.linkedPassenger)}
          </Typography>
        </Box>
      )}
      {paymentMethod.cardNote && (
        <CardNoteTooltip cardNote={paymentMethod.cardNote} />
      )}
    </Box>
  );
}

export default MenuItemContent;
