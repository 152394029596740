import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import { LOAD_VEHICLES_QUERY } from "globals/graphql";
import { assignColorsByIndex } from "globals/utils/helpers";
import { Vehicle } from "types";

function useVehiclesWithColors() {
  // queries
  const { data } = useQuery(LOAD_VEHICLES_QUERY, {
    variables: { includeExternalOperators: true },
  });

  const vehiclesWithColors = useMemo(() => {
    const vehicles = data?.loadVehicles.edges.map(({ node }) => node) || [];

    return assignColorsByIndex<Vehicle>(vehicles);
  }, [data]);

  return vehiclesWithColors;
}

export default useVehiclesWithColors;
