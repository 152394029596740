import React, { useMemo, useState } from "react";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";

import { Divider, Box, CircularProgress } from "@mui/material";

import UpdateDrawer from "components/globals/UpdateDrawer";
import GQLQueryStatusIndicator from "components/GQLQueryStatusIndicator";
import { grayDark } from "design-system/colors";
import { CopyIcon, DueIcon, TrashIcon } from "design-system/icons";
import RemoveCompanyDialog from "../RemoveCompanyDialog";
import {
  UpdateCompanyForm,
  ContactsBlock,
  CompanyTripsGrid,
} from "./components";
import clipboardCopy from "clipboard-copy";
import { useAnalytics, useCurrentUser, useSnackbar } from "globals/hooks";
import { useCompany } from "./hooks/useCompany";
import PaymentMethodList from "components/contacts/paymentMethod/PaymentMethodList";
import ViewCardPaymentsModal from "components/contacts/paymentMethod/ViewCardPaymentsModal";
import TripDrawerTabs from "components/contacts/people/UpdateContactDrawer/components/TripDrawerTabs";
import EmailActivityLogDialog from "components/globals/ActivityLog/EmailActivityLogDialog";
import { PlanVariant } from "types";

function UpdateCompanyDrawer() {
  // hooks
  const history = useHistory();
  const { track } = useAnalytics();
  const snackbar = useSnackbar();
  const { operator } = useCurrentUser();
  const {
    company,
    companyLoading,
    companyError,
    companyRefetch,
    paymentMethods,
  } = useCompany();

  // state
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "saved" | "loading" | "error"
  >("default");
  const [tabMode, setTabMode] = useState("Profile");
  const [activityLogOpen, setActivityLogOpen] = useState(false);

  const paymentMethodId = useMemo(
    () => new URLSearchParams(history.location.search).get("card"),
    [history.location]
  );

  // event handlers
  const handleDeleteCompanyClick = () => setRemoveDialogOpen(true);
  const handleRemoveDialogClose = () => setRemoveDialogOpen(false);

  const handleCopyShuttleAppLinkClick = async () => {
    try {
      await clipboardCopy(
        `${process.env.REACT_APP_SHUTTLEAPP_URL}/${company.id}/trips`
      );
      snackbar.success("Successfully copied shuttle app link!");
      track("company_shuttleLinkCopied ");
    } catch (error) {
      snackbar.error("Could not copy shuttle app link");
    }
  };

  const handleTabChange = (tabMode: string) => {
    if (tabMode === "Trips") track("company_tripsViewed");

    setTabMode(tabMode);
  };

  return (
    <UpdateDrawer
      onClose={() => {
        history.push(`/companies`);
      }}
      createdAt={company ? moment(company.createdAt).format("LLL") : ""}
      updatedAt={company ? moment(company.updatedAt).format("LLL") : ""}
      saveIndicatorState={saveIndicatorState}
      ellipsisMenuOptions={[
        {
          text: "View Activity Log",
          icon: <DueIcon color={grayDark} size="small" />,
          onClick: () => setActivityLogOpen(true),
        },
        "divider",
        // only show shuttle app functionality for VIP plans
        operator.plan === PlanVariant.Vip && {
          ...{
            onClick: handleCopyShuttleAppLinkClick,
            text: "Copy Shuttle App Link",
            icon: <CopyIcon size="small" />,
          },
        },
        {
          onClick: handleDeleteCompanyClick,
          text: "Delete",
          icon: <TrashIcon color={grayDark} size="small" />,
        },
      ]}
      subHeaderContent={
        <TripDrawerTabs value={tabMode} setValue={handleTabChange} />
      }
    >
      {companyLoading && !company && (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={40} thickness={2} />
        </Box>
      )}

      {companyError && !companyLoading && (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <GQLQueryStatusIndicator
            name="Company"
            data={company}
            error={companyError}
            refetch={companyRefetch}
          />
        </Box>
      )}

      {company && (
        <>
          {tabMode === "Profile" ? (
            <Box>
              <UpdateCompanyForm
                company={company}
                setSaveIndicatorState={setSaveIndicatorState}
              />
              <RemoveCompanyDialog
                open={removeDialogOpen}
                company={company}
                handleClose={handleRemoveDialogClose}
              />

              <Divider sx={{ m: 3 }} />
              <ContactsBlock company={company} />

              <Box mb={4}>
                <PaymentMethodList
                  payer={{
                    payerType: "company",
                    payerId: company.id,
                    payerPhone: company.phoneNumber,
                  }}
                  paymentMethods={paymentMethods}
                  setSaveIndicatorState={setSaveIndicatorState}
                />
              </Box>
            </Box>
          ) : (
            <CompanyTripsGrid companyId={company.id} />
          )}
        </>
      )}

      <EmailActivityLogDialog
        open={activityLogOpen}
        onClose={() => setActivityLogOpen(false)}
        email={company?.email}
        source="company"
      />

      <ViewCardPaymentsModal paymentMethodId={paymentMethodId} />
    </UpdateDrawer>
  );
}

export default UpdateCompanyDrawer;
