/**
 * @file NewMessageDialog.tsx
 * sends a message to multiple contacts/drivers or new numbers
 *
 * reference:
 * http://media.twiliocdn.com/sdk/js/conversations/releases/1.1.0/docs/Client.html
 * http://media.twiliocdn.com/sdk/js/conversations/releases/1.1.0/docs/Conversation.html
 *
 * components:
 * NewMessageDialog
 *
 */
import React, { useState, useRef } from "react";
import map from "lodash/map";

import { Box, Typography } from "@mui/material";

import * as Sentry from "@sentry/react";

import MoovsDialog from "../../MoovsDialog";
import ToFieldAutoComplete from "../ToFieldAutoComplete";
import { grayDark } from "../../../design-system/colors";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import { useAnalytics } from "../../../globals/hooks";
import { useMutation } from "@apollo/client";
import { CREATE_MESSAGE_MUTATION } from "globals/graphql/conversation.graphql";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import CustomMessageInputBase from "../CustomMessageInputBase";

type NewMessageDialogProps = {
  open: boolean;
  onClose: () => void;
};

function NewMessageDialog(props: NewMessageDialogProps) {
  const { open, onClose } = props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // state
  const [messageText, setMessageText] = useState("");
  const [recipients, setRecipients] = useState([]);
  const [isSending, setIsSending] = useState(false);

  // refs
  const messageTextRef = useRef(null);

  // queries
  const [createMessage] = useMutation(CREATE_MESSAGE_MUTATION, {
    onCompleted() {
      snackbar.success("Message sent successfully!");
    },
    onError(error) {
      Sentry.captureException(error);

      const errorMessage = getErrorMessage(error) || error.message;
      snackbar.error(errorMessage);

      setIsSending(false);
    },
  });

  // event handlers
  const handleMessageFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMessageText(event.target.value);
  };

  const handleSendClick = async () => {
    track("chatCompose_sent");
    setIsSending(true);

    await createMessage({
      variables: {
        input: {
          recipients: map(recipients, "E164Number"),
          body: messageText,
        },
      },
    });

    handleClose();
  };

  const handleClose = () => {
    setIsSending(false);
    onClose();
    setRecipients([]);
    setMessageText("");
  };

  return (
    <MoovsDialog
      open={open}
      onClose={handleClose}
      dialogTitle="New Message"
      size="sm"
      fixedFooter
      removeCloseButton
      onAccept={handleSendClick}
      subHeaderComponent={
        <Box display="flex" alignItems="center" minHeight="50px">
          <Box mr={1}>
            <Typography variant="body2" style={{ color: grayDark }}>
              To:{" "}
            </Typography>
          </Box>
          <Box display="flex" width="100%">
            <ToFieldAutoComplete
              recipients={recipients}
              onChange={setRecipients}
            />
          </Box>
        </Box>
      }
      acceptButtonText="Send"
      acceptDisabled={isSending || !messageText || !recipients.length}
      hideLoadingIndicator={!isSending}
      hideTopBorder
      hideBottomBorder
    >
      <CustomMessageInputBase
        messageRef={messageTextRef}
        messageValue={messageText}
        onChange={handleMessageFieldChange}
      />
    </MoovsDialog>
  );
}

export default NewMessageDialog;
