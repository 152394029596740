import React, { useState } from "react";

import { CloseOutDisabledEnum, Request, Trip } from "types";
import TripIsCancelledBanner from "./TripIsCancelledBanner";
import TripCompleteBanner from "./TripCompleteBanner";
import TripIsClosedBanner from "./TripIsClosedBanner";
import MoovsConfetti from "design-system/components/MoovsConfetti";

type TripSummaryBannerProps = {
  request: Request;
  trip: Trip;
};

function TripSummaryBanner(props: TripSummaryBannerProps) {
  const { request, trip } = props;

  // state
  const [confetti, setConfetti] = useState(false);

  // event handlers
  const handleShowConfetti = () => {
    setConfetti(true);
  };

  // derived state
  const isCancelledTrip = !!trip.cancelledAt;
  const isClosedTrip = !!trip.closedAt;
  const isCompletedTrip =
    !trip.closeOutDisabledVariant ||
    trip.closeOutDisabledVariant !== CloseOutDisabledEnum.AwaitingTripEnd; // we only show closeable trip after tripEnd

  return (
    <>
      {isCancelledTrip ? (
        <TripIsCancelledBanner tripId={trip.id} />
      ) : isClosedTrip ? (
        <TripIsClosedBanner
          tripId={trip.id}
          reopenDisabled={trip.reopenDisabled}
        />
      ) : isCompletedTrip ? (
        <TripCompleteBanner
          trip={trip}
          orderNumber={request.orderNumber}
          closeOutDisabledVariant={trip.closeOutDisabledVariant}
          onShowConfetti={handleShowConfetti}
        />
      ) : null}

      <MoovsConfetti active={confetti} />
    </>
  );
}

export default TripSummaryBanner;
