import React from "react";

type FlightInfoPlaneIconProps = {
  borderColor?: string;
  fillColor?: string;
};

const FlightInfoPlaneIcon = (props: FlightInfoPlaneIconProps) => {
  const { borderColor, fillColor } = props;

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.8796 16.1119L19.1957 8.42636C19.122 8.35289 19.0346 8.29466 18.9384 8.255C18.8423 8.21533 18.7392 8.19501 18.6352 8.1952H14.5423C14.385 8.19459 14.231 8.24087 14.1001 8.32811C13.9693 8.41536 13.8673 8.53962 13.8073 8.68504C13.7474 8.83047 13.7321 8.99046 13.7634 9.14461C13.7948 9.29876 13.8713 9.44007 13.9833 9.55053L20.5478 16.1166"
        fill={fillColor || "#E1E1E1"}
      />
      <path
        d="M26.8796 16.1119L19.1957 8.42636C19.122 8.35289 19.0346 8.29466 18.9384 8.255C18.8423 8.21533 18.7392 8.19501 18.6352 8.1952H14.5423C14.385 8.19459 14.231 8.24087 14.1001 8.32811C13.9693 8.41536 13.8673 8.53962 13.8073 8.68504C13.7474 8.83047 13.7321 8.99046 13.7634 9.14461C13.7948 9.29876 13.8713 9.44007 13.9833 9.55053L20.5478 16.1166"
        stroke={borderColor || "#B3B3B3"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.7964 16.1117H11.0464L8.11245 13.1762C8.0388 13.1028 7.95138 13.0445 7.85521 13.0049C7.75903 12.9652 7.65598 12.9449 7.55195 12.9451H2.82887C2.69373 12.9448 2.56078 12.9791 2.44267 13.0448C2.32455 13.1105 2.22521 13.2053 2.15411 13.3202C2.083 13.4351 2.04249 13.5663 2.03644 13.7013C2.03039 13.8363 2.05899 13.9706 2.11954 14.0914L6.07787 22.0081C6.1438 22.1396 6.2451 22.2502 6.3704 22.3274C6.4957 22.4046 6.64003 22.4454 6.7872 22.4451H18.963L10.8168 30.5929C10.7058 30.7036 10.6302 30.8448 10.5995 30.9986C10.5688 31.1523 10.5845 31.3117 10.6445 31.4566C10.7046 31.6014 10.8063 31.7251 10.9367 31.8121C11.0672 31.8991 11.2205 31.9454 11.3773 31.9451H17.0535C17.1577 31.945 17.2608 31.9245 17.357 31.8845C17.4532 31.8446 17.5405 31.7861 17.614 31.7123L26.8813 22.4451H34.798C35.6378 22.4451 36.4433 22.1114 37.0371 21.5176C37.631 20.9237 37.9646 20.1183 37.9646 19.2784C37.9646 18.4385 37.631 17.6331 37.0371 17.0392C36.4433 16.4454 35.6378 16.1117 34.798 16.1117H34.7964Z"
        fill={fillColor || "#E1E1E1"}
        stroke={borderColor || "#B3B3B3"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FlightInfoPlaneIcon;
