import { useEffect, useState } from "react";
import { Request } from "types";

type UseSuggestedAddressInfoProps = {
  request?: Request;
};

function useSuggestedAddressInfo(props: UseSuggestedAddressInfoProps) {
  const { request } = props;

  // state
  const [suggestedAddressInfo, setSuggestedAddressInfo] = useState<
    {
      firstName: string;
      lastName: string;
      address: string;
      mode: string;
    }[]
  >([]);

  // effect
  useEffect(() => {
    const keys = [];

    const bookingContact = request?.bookingContact;

    if (bookingContact?.workAddress) {
      keys.push({
        address: bookingContact.workAddress,
        firstName: bookingContact.firstName,
        lastName: bookingContact.lastName,
        mode: "workAddress",
      });
    }

    if (bookingContact?.homeAddress) {
      keys.push({
        address: bookingContact.homeAddress,
        firstName: bookingContact.firstName,
        lastName: bookingContact.lastName,
        mode: "homeAddress",
      });
    }

    request?.trips.forEach((trip) => {
      if (trip.contact.workAddress) {
        keys.push({
          address: trip.contact.workAddress,
          firstName: trip.contact.firstName,
          lastName: trip.contact.lastName,
          mode: "workAddress",
        });
      }
      if (trip.contact.homeAddress) {
        keys.push({
          address: trip.contact.homeAddress,
          firstName: trip.contact.firstName,
          lastName: trip.contact.lastName,
          mode: "homeAddress",
        });
      }

      const uniqueSuggestedAddresses = keys.reduce((unique, currentObj) => {
        // if the element is unique, then add it to the unique array
        if (
          !unique.some(
            (obj) =>
              obj.address === currentObj.address &&
              obj.firstName === currentObj.firstName &&
              obj.lastName === currentObj.lastName &&
              obj.mode === currentObj.mode
          )
        ) {
          unique.push(currentObj);
        }
        return unique;
      }, []);

      setSuggestedAddressInfo(uniqueSuggestedAddresses);
    });
  }, [request]);

  return { suggestedAddressInfo };
}

export default useSuggestedAddressInfo;
