import React from "react";
import sortBy from "lodash/sortBy";

import { Box, Chip, TextField, Autocomplete } from "@mui/material";

import { CrossIcon, CheckIcon } from "../../design-system/icons";
import {
  black,
  errorRed,
  granite,
  moovsBlue,
} from "../../design-system/colors";
import { primaryDisabledColor } from "../../theme";
import { vehicleWeekendsArr } from "globals/utils/vehicleWeekends";

const autocompleteSx = {
  "& fieldset": {
    border: "none",
  },
  borderRadius: 1,
  border: "1px solid transparent",
};

const chipStyles = {
  borderRadius: "6px",
  height: "40px",
  fontSize: "14px",
  backgroundColor: primaryDisabledColor,
  mr: "8px",
};

type VehicleWeekendsProps = {
  required?: boolean;
  error?: boolean;
  errorText?: string;
  value: { name: string; value: string; index: number }[];
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    newSettings: { name: string; value: string; index: number }[]
  ) => void;
  disabled?: boolean;
  transparent?: boolean; // used for update drawer style
};

function VehicleWeekendsBlock(props: VehicleWeekendsProps) {
  const { required, error, errorText, value, onChange, disabled, transparent } =
    props;

  if (!value) return null;
  const sortedWeekends = sortBy(value, (weekend) => weekend.index);

  return (
    <Autocomplete
      multiple
      disableClearable
      disableCloseOnSelect
      fullWidth
      disabled={disabled}
      onChange={onChange}
      value={sortedWeekends || []}
      sx={transparent && !disabled ? autocompleteSx : null}
      id="settings"
      options={vehicleWeekendsArr}
      getOptionLabel={(option) => option.value}
      isOptionEqualToValue={(option, value) => {
        return option.name === value.name;
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Box
            display="flex"
            width="100%"
            sx={{ justifyContent: "space-between" }}
          >
            {option.name}
            {selected && <CheckIcon color={moovsBlue} />}
          </Box>
        </li>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          return (
            <Chip
              key={option.value}
              id={option.value}
              onDelete={onChange}
              deleteIcon={
                <Box sx={{ "&.MuiChip-deleteIcon": { mt: 0.5 } }}>
                  <CrossIcon color={granite} />
                </Box>
              }
              sx={chipStyles}
              label={option.name}
              {...getTagProps({ index })}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          variant="outlined"
          placeholder={
            sortedWeekends.length !== 0
              ? ""
              : required
              ? "Choose Your Weekends *"
              : "Choose Your Weekends"
          }
          sx={{
            "& .MuiOutlinedInput-input": {
              color: transparent ? null : error ? errorRed : black,
            },
            "& input::placeholder": {
              opacity: error ? "100%" : "70%",
              color: error ? errorRed : black,
            },
            "& .MuiTextField:fieldset": {
              borderColor: error ? errorRed : "none",
            },
          }}
          error={error}
          helperText={errorText}
          {...params}
        />
      )}
    />
  );
}

export { VehicleWeekendsBlock };
