import React from "react";

import { Box, Button, Typography } from "@mui/material";

import { errorRed, white } from "design-system/colors";
import { EmailIcon } from "design-system/icons";
import { LoadingButton } from "@mui/lab";

type SendEmailDialogFooterProps = {
  errorMessage: string;
  onDialogClose: () => void;
  onHandleSendEmailClick: () => void;
  sendEmailLoading: boolean;
};

function SendEmailDialogFooter(props: SendEmailDialogFooterProps) {
  const {
    errorMessage,
    onDialogClose,
    onHandleSendEmailClick,
    sendEmailLoading,
  } = props;

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          {errorMessage && (
            <Typography color={errorRed} fontWeight={700}>
              {errorMessage}
            </Typography>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button color="primary" onClick={onDialogClose}>
            Close
          </Button>
          <Box ml={2}>
            <LoadingButton
              loading={sendEmailLoading}
              onClick={onHandleSendEmailClick}
              variant="contained"
            >
              Send Email
              <Box display="flex" ml={1}>
                <EmailIcon color={white} size="small" />
              </Box>
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SendEmailDialogFooter;
