export type PlanCardProps = {
  name: string;
  icon: JSX.Element;
  planLength: "monthly" | "annual";
  price?: number;
  btnVariant?: PlanCardActionBtnVariant;
  fontColor?: string;
  bgColor?: string;
  link?: string;
};

export enum PlanCardActionBtnVariant {
  UPGRADE = "upgrade",
  INTERCOM_UPGRADE = "intercom-upgrade",
  DOWNGRADE = "downgrade",
  DEMO = "demo",
  CURRENT = "current",
  NO_DEMO = "no-demo",
  VIP_CURRENT = "vip-current",
}
