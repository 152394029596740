import React, { memo, useCallback, useState } from "react";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useHistory } from "react-router-dom";

import { Box, Button, IconButton, Typography } from "@mui/material";

import NewMessageDialog from "./NewMessageDialog";
import ConversationsListItem from "./ConversationsListItem";
import {
  grayMedium,
  granite,
  tintGreen,
  white,
} from "../../../design-system/colors";
import { CrossIcon } from "../../../design-system/icons";
import messagesIllustration from "../../../design-system/illustrations/messages.svg";
import MoovsTooltip from "../../MoovsTooltip";
import { formatPhoneNumber } from "../../../globals/utils/phoneNumberFormatter/phoneNumberFormatter";
import {
  useAnalytics,
  useOperator,
  useScreenSize,
} from "../../../globals/hooks";
import { ConversationType } from "../../../globals/hooks/useChat/Provider";
import { CHAT_PANEL_WIDTH } from "theme";
import { useChatSearchBar } from "../hooks/useChatSearchBar";
import UpgradePlanDialog from "components/UpgradePlanDialog";
import { PlanVariant } from "types";

type ConversationsListProps = {
  conversations: ConversationType[];
  onConversationClick: (conversation: ConversationType) => void;
  onChatPanelClose: () => void;
};

function ConversationsList(props: ConversationsListProps) {
  const { conversations, onConversationClick, onChatPanelClose } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const operator = useOperator();
  const { track } = useAnalytics();
  const { SearchBar, conversationsOutput, emptySearchState } =
    useChatSearchBar(conversations);
  const history = useHistory();

  // state
  const [newMessageDialogOpen, setNewMessageDialogOpen] = useState(false);
  const [upgradePlanDialogOpen, setUpgradePlanDialogOpen] = useState(false);

  // event handlers
  const handleClickComposeButton = () => {
    track("chatCompose_opened");
    setNewMessageDialogOpen(true);
  };

  const handleNewMessageDialogClose = useCallback(
    () => setNewMessageDialogOpen(false),
    []
  );

  const handleClickComposeButtonOnFreePlan = () => {
    setUpgradePlanDialogOpen(true);
  };

  const handleUpgradePlanClick = () => {
    track("chatCompose_upgradePlan");
    history.push("/settings/billing/plans");
  };

  // useful variables
  const twilioPhoneNumber = operator?.twilioPhoneNumber?.phoneNumber;
  const formattedPhoneNumber = twilioPhoneNumber
    ? formatPhoneNumber(twilioPhoneNumber).formatted
    : "";

  const chatEnabled = !!twilioPhoneNumber;

  const Row = ({ index, style }) => {
    const conversation = conversationsOutput[index];
    return (
      <div style={style}>
        <ConversationsListItem
          key={conversation.proxy.sid}
          conversation={conversation}
          onConversationClick={() => onConversationClick(conversation)}
        />
      </div>
    );
  };

  return (
    <>
      {/* header */}
      <Box
        flexDirection="row"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderBottom={`1px solid ${grayMedium}`}
        width="100%"
        maxWidth={isMobileView ? "100vw" : CHAT_PANEL_WIDTH}
        p={1}
        bgcolor={white}
        zIndex={10}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <IconButton onClick={onChatPanelClose} size="large">
            <CrossIcon size="small" color={granite} />
          </IconButton>
          <Typography style={{ fontWeight: 700 }} variant="subtitle1">
            Chat
          </Typography>
          {chatEnabled && (
            <Box ml={1}>
              <MoovsTooltip
                tooltipText={`Your phone number is ${formattedPhoneNumber}`}
              />
            </Box>
          )}
        </Box>
        <Button
          onClick={
            operator?.plan === PlanVariant.Free
              ? handleClickComposeButtonOnFreePlan
              : handleClickComposeButton
          }
          variant="contained"
          color="primary"
          disabled={!chatEnabled}
        >
          Compose
        </Button>
      </Box>

      {/* Search Bar : Name + Phone Number Only */}
      <Box p={1}>{SearchBar}</Box>

      {conversationsOutput.length ? (
        <Box overflow="hidden" height="100%">
          <AutoSizer>
            {({ height, width }) => (
              <List
                className="List"
                height={height}
                itemCount={conversationsOutput.length}
                itemSize={105}
                width={width}
              >
                {Row}
              </List>
            )}
          </AutoSizer>
        </Box>
      ) : (
        <Box
          display="flex"
          flex="1"
          height="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {emptySearchState || (
            <>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={2}
                mb={2}
                borderRadius="50%"
                height={isMobileView ? 120 : 73}
                width={isMobileView ? 120 : 73}
                bgcolor={tintGreen}
              >
                <img
                  src={messagesIllustration}
                  alt="messages illustration"
                  style={{ width: 37, height: 37 }}
                />
              </Box>
              <Typography
                style={{
                  fontWeight: 500,
                  letterSpacing: "-.25px",
                  textAlign: "center",
                }}
              >
                {chatEnabled
                  ? "No conversations"
                  : "Chat is not enabled. See general settings page to setup chat."}
              </Typography>
            </>
          )}
        </Box>
      )}
      <NewMessageDialog
        open={newMessageDialogOpen}
        onClose={handleNewMessageDialogClose}
      />
      <UpgradePlanDialog
        open={upgradePlanDialogOpen}
        onUpgrade={handleUpgradePlanClick}
        onClose={() => setUpgradePlanDialogOpen(false)}
        body={
          <Typography variant="body2">
            You must upgrade your plan in order to{" "}
            <strong>send free text messages</strong>.
          </Typography>
        }
      />
    </>
  );
}

export default memo(ConversationsList);
