import React from "react";

import { Chip, ChipProps, Typography } from "@mui/material";

import { black, grayLight } from "design-system/colors";
import { ChildSeatVariant } from "types";

const { RearFacing, ForwardFacing, Booster } = ChildSeatVariant;

type ChildSeatChipProps = {
  variant: ChildSeatVariant;
  quantity?: number;
  maxWidth?: string;
  ChipProps?: ChipProps;
};

const CHILD_SEAT_LABELS = {
  [RearFacing]: "Rear-Facing Seat",
  [ForwardFacing]: "Forward-Facing Seat",
  [Booster]: "Booster Seat",
};

function ChildSeatChip(props: ChildSeatChipProps) {
  const { variant, quantity, ChipProps } = props;

  if (!quantity) {
    return null;
  }

  // derived state
  const label =
    quantity > 1
      ? `${quantity} X ${CHILD_SEAT_LABELS[variant]}`
      : CHILD_SEAT_LABELS[variant];

  return (
    <Chip
      {...ChipProps}
      component="div"
      label={
        <Typography variant="overline" color={black}>
          {label}
        </Typography>
      }
      size="small"
      sx={{ mt: 0.5, mr: 0.5, backgroundColor: grayLight }}
    />
  );
}

export default ChildSeatChip;
