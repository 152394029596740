/**
 * @file priceZone.graphql.ts
 * queries/mutations for priceZones.
 */

import { gql } from "@apollo/client";

export const LOAD_PRICE_ZONE_QUERY = gql`
  query PriceZone($id: ID!) {
    node(id: $id) {
      id
      ... on priceZone {
        id
        createdAt
        updatedAt
        zoneName
        geoJson
        zoneVariantData {
          ... on ZipCodeZone {
            variant
            zipCodes {
              zipCode
            }
          }
          ... on LocationZone {
            variant
            radius
            address
            centerCoordinates {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;

export const LOAD_PRICE_ZONES_QUERY = gql`
  query PriceZones($filter: String, $orderBy: OrderByPriceZoneInput) {
    priceZones(filter: $filter, orderBy: $orderBy) {
      edges {
        node {
          id
          createdAt
          updatedAt
          zoneName
          zoneVariantData {
            ... on ZipCodeZone {
              variant
              zipCodes {
                zipCode
              }
            }
            ... on LocationZone {
              variant
              radius
              address
              centerCoordinates {
                lat
                lng
              }
            }
          }
          geoJson
          routeWithinZone {
            id
            dropOffZone {
              id
              zoneName
              geoJson
              zoneVariantData {
                ... on ZipCodeZone {
                  variant
                  zipCodes {
                    zipCode
                  }
                }
                ... on LocationZone {
                  variant
                  radius
                  address
                  centerCoordinates {
                    lat
                    lng
                  }
                }
              }
            }
            pickUpZone {
              id
              zoneName
              geoJson
              zoneVariantData {
                ... on ZipCodeZone {
                  variant
                  zipCodes {
                    zipCode
                  }
                }
                ... on LocationZone {
                  variant
                  radius
                  address
                  centerCoordinates {
                    lat
                    lng
                  }
                }
              }
            }
            priceZoneRouteVehicles {
              vehicle {
                id
                name
                vehicleType {
                  typeSlug
                  typeName
                  iconCategory
                }
              }
              baseRate
            }
          }
        }
      }
    }
  }
`;

export const CREATE_PRICE_ZONE_MUTATION = gql`
  mutation CreatePriceZone($input: CreatePriceZoneInput!) {
    createPriceZone(input: $input) {
      priceZone {
        id
        createdAt
        updatedAt
        zoneName
        geoJson
        zoneVariantData {
          ... on ZipCodeZone {
            variant
            zipCodes {
              zipCode
            }
          }
          ... on LocationZone {
            variant
            radius
            address
            centerCoordinates {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_PRICE_ZONE_MUTATION = gql`
  mutation UpdatePriceZone($input: UpdatePriceZoneInput!) {
    updatePriceZone(input: $input) {
      priceZone {
        id
        createdAt
        updatedAt
        zoneName
        geoJson
        zoneVariantData {
          ... on ZipCodeZone {
            variant
            zipCodes {
              zipCode
            }
          }
          ... on LocationZone {
            variant
            radius
            address
            centerCoordinates {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;

export const REMOVE_PRICE_ZONE_MUTATION = gql`
  mutation RemovePriceZone($input: RemovePriceZoneInput!) {
    removePriceZone(input: $input) {
      success
    }
  }
`;

export const LOAD_PRICE_ZONE_ROUTES_QUERY = gql`
  query PriceZoneRoutesByZoneId($priceZoneId: ID!) {
    priceZoneRoutesByZoneId(priceZoneId: $priceZoneId) {
      id
      distanceMiles
      dropOffZone {
        id
        zoneName
        geoJson
        zoneVariantData {
          ... on ZipCodeZone {
            variant
            zipCodes {
              zipCode
            }
          }
          ... on LocationZone {
            variant
            radius
            address
            centerCoordinates {
              lat
              lng
            }
          }
        }
      }
      pickUpZone {
        id
        zoneName
        geoJson
        zoneVariantData {
          ... on ZipCodeZone {
            variant
            zipCodes {
              zipCode
            }
          }
          ... on LocationZone {
            variant
            radius
            address
            centerCoordinates {
              lat
              lng
            }
          }
        }
      }
      priceZoneRouteVehicles {
        vehicle {
          id
          name
          vehicleType {
            typeSlug
            iconCategory
          }
        }
        baseRate
      }
    }
  }
`;

export const UPDATE_PRICE_ZONE_ROUTE_BASE_RATES_MUTATION = gql`
  mutation UpdatePriceZoneRouteBaseRates(
    $input: UpdatePriceZoneRouteBaseRatesInput!
  ) {
    updatePriceZoneRouteBaseRates(input: $input) {
      priceZoneRoute {
        id
        dropOffZone {
          id
          zoneName
          geoJson
        }
        pickUpZone {
          id
          zoneName
          geoJson
        }
        priceZoneRouteVehicles {
          vehicle {
            id
            name
            vehicleType {
              typeSlug
              iconCategory
            }
          }
          baseRate
        }
      }
    }
  }
`;
