import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import size from "lodash/size";

import { CircularProgress, Box, Divider } from "@mui/material";

import UpdateMemberForm from "./components/UpdateMemberForm";
import RemoveMemberDialog from "./components/RemoveMemberDialog";
import ResendInviteDialog from "./components/ResendInviteDialog";
import UpdateDrawer from "components/globals/UpdateDrawer";
import GQLQueryStatusIndicator from "components/GQLQueryStatusIndicator";
import { User, UserAccessPermission } from "types";
import { LOAD_USER_QUERY } from "globals/graphql";
import MemberDrawerTabs from "./components/MemberDrawerTabs";
import MemberDrawerHeader from "./components/MemberDrawerHeader";
import MemberPermissions from "../MemberPermissions";
import { useLoadAllAccessPermissions } from "../hooks/useLoadAllAccessPermissions";
import { useAccessPermissionsChange } from "../hooks/useAccessPermissionsChange";

function UpdateMemberDrawer() {
  // state
  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "loading" | "saved" | "error"
  >("default");
  const [removeUserDialogOpen, setRemoveUserDialogOpen] =
    useState<boolean>(false);
  const [resendInviteDialogOpen, setResendInviteDialogOpen] =
    useState<boolean>(false);
  const [tabMode, setTabMode] = useState("Details");
  const [allPermissions, setAllPermissions] = useState<UserAccessPermission[]>(
    []
  );
  const [activePermissions, setActivePermissions] = useState<
    UserAccessPermission[]
  >([]);

  // hooks
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const { allAccessPermissions, allAccessPermissionsLoading } =
    useLoadAllAccessPermissions();
  const { handleUserAccessPermissionsChange, memberPermissionsHomeError } =
    useAccessPermissionsChange({
      userId,
      setSaveIndicatorState,
      activePermissions,
      setActivePermissions,
    });

  // queries
  const {
    data: userData,
    loading: userLoading,
    refetch: userRefetch,
    error: userError,
  } = useQuery(LOAD_USER_QUERY, {
    variables: {
      id: userId,
    },
    skip: !userId,
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
  });

  // event handlers
  const handleUpdateDrawerClose = () => {
    history.push("/settings/members/");
  };

  const handleTabChange = (tabMode: string) => {
    setTabMode(tabMode);
  };

  // derived state
  const user: User = userData?.node;

  useEffect(() => {
    if (allAccessPermissionsLoading) {
      return;
    }

    if (allAccessPermissions && allAccessPermissions.length) {
      setAllPermissions(allAccessPermissions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAccessPermissionsLoading]);

  // sets activePermissions on load
  useEffect(() => {
    if (user && !size(activePermissions)) {
      setActivePermissions(user.accessPermissions);
    }
  }, [user, activePermissions]);

  return (
    <UpdateDrawer
      onClose={handleUpdateDrawerClose}
      createdAt={user?.createdAt ? new Date(user.createdAt).toISOString() : ""}
      updatedAt={user?.updatedAt ? new Date(user.updatedAt).toISOString() : ""}
      saveIndicatorState={saveIndicatorState}
      ellipsisMenuOptions={[
        {
          onClick: () => setRemoveUserDialogOpen(true),
          text: "Delete Member",
        },
        {
          onClick: () => setResendInviteDialogOpen(true),
          text: "Resend Invite",
          hideOption: !user?.invitePending,
        },
      ]}
      subHeaderContent={
        <>
          <Divider />
          {user && <MemberDrawerHeader user={user} />}
          <MemberDrawerTabs
            value={tabMode}
            setValue={handleTabChange}
            user={user}
          />
        </>
      }
    >
      {userLoading && (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={40} thickness={2} />
        </Box>
      )}
      {userError && !userLoading && (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <GQLQueryStatusIndicator
            name="User"
            data={userData}
            error={userError}
            refetch={userRefetch}
          />
        </Box>
      )}
      {user && (
        <>
          {tabMode === "Details" && (
            <UpdateMemberForm
              user={user}
              setSaveIndicatorState={setSaveIndicatorState}
            />
          )}
          {tabMode === "Permissions" && (
            <Box mt={4} mb={2}>
              <MemberPermissions
                activePermissions={activePermissions}
                allPermissions={allPermissions}
                handleUserAccessPermissionsChange={
                  handleUserAccessPermissionsChange
                }
                homeError={memberPermissionsHomeError}
              />
            </Box>
          )}
          <RemoveMemberDialog
            open={removeUserDialogOpen}
            onClose={() => setRemoveUserDialogOpen(false)}
            userId={userId}
          />
          <ResendInviteDialog
            open={resendInviteDialogOpen}
            onClose={() => setResendInviteDialogOpen(false)}
            userId={userId}
            userEmail={user.email}
          />
        </>
      )}
    </UpdateDrawer>
  );
}

export default UpdateMemberDrawer;
