// reference: https://stackoverflow.com/questions/56588625/react-show-material-ui-tooltip-only-for-text-that-has-ellipsis
import React, { useRef, useEffect, useState } from "react";

import { Tooltip, TooltipProps, Typography } from "@mui/material";

type OverflowTooltipProps = {
  toolTipText: string;
  displayText?: string;
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline"
    | "inherit";
} & Partial<TooltipProps>;

const OverflowTooltip = (props: OverflowTooltipProps) => {
  const { toolTipText, displayText, variant, ...tooltipProps } = props;

  // State and Ref
  const [hoverStatus, setHoverStatus] = useState(false);
  const textElementRef = useRef();

  const compareSize = () => {
    const textElementNode = textElementRef.current as any;
    const compare = textElementNode.scrollWidth > textElementNode.clientWidth;
    setHoverStatus(compare);
  };

  // compare once and add resize listener
  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);

    return window.removeEventListener("resize", compareSize);
  }, []);

  const sxStyles = {
    overflowText: {
      textOverflow: "ellipsis",
    },
  };

  return (
    <Tooltip
      title={toolTipText}
      disableHoverListener={!hoverStatus}
      enterDelay={200}
      enterNextDelay={200}
      {...tooltipProps}
    >
      <Typography
        variant={variant}
        ref={textElementRef}
        sx={[
          { overflow: "hidden", whiteSpace: "nowrap", display: "block" },
          sxStyles.overflowText,
        ]}
      >
        {displayText || toolTipText}
      </Typography>
    </Tooltip>
  );
};

export default OverflowTooltip;
