import * as yup from "yup";

export const limitsSchema = yup.object({
  isTotalLimited: yup
    .boolean()
    .required("Please make a selection")
    .typeError("Please make a selection"),
  isBookingContactLimited: yup
    .boolean()
    .required("Please make a selection")
    .typeError("Please make a selection"),
  totalLimit: yup.mixed().when("isTotalLimited", {
    is: true,
    then: yup
      .number()
      .min(1, "Limit must be greater than 0")
      .required("Please enter a limit")
      .typeError("Please enter a limit"),
    otherwise: yup.mixed(),
  }),
  bookingContactLimit: yup.mixed().when("isBookingContactLimited", {
    is: true,
    then: yup
      .number()
      .min(1, "Limit must be greater than 0")
      .required("Please enter a limit")
      .typeError("Please enter a limit"),
    otherwise: yup.mixed(),
  }),
});
