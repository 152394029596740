import React from "react";

type ReplaceIconProps = {
  color?: string;
  size?: "small";
};

const ReplaceIcon = (props: ReplaceIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.9795 9.65539L15.8657 7.62435L17.9569 9.53519M15.9106 7.83166C16.2011 9.19588 16.0103 10.6077 15.3702 11.8296C14.7302 13.0516 13.68 14.0091 12.3963 14.5411C11.7384 14.8137 11.0398 14.9661 10.333 14.9966M5.98928 8.94458L3.82174 10.6794L2.02383 8.49616M3.97272 10.0883C3.68431 8.72477 3.87648 7.31435 4.51695 6.09386C5.15743 4.87337 6.20722 3.91713 7.49002 3.38572C8.14721 3.11348 8.84504 2.96123 9.55107 2.93064"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 4.5C15 5.32843 14.3284 6 13.5 6C12.6716 6 12 5.32843 12 4.5C12 3.67157 12.6716 3 13.5 3C14.3284 3 15 3.67157 15 4.5Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 13.5C8 14.3284 7.32843 15 6.5 15C5.67157 15 5 14.3284 5 13.5C5 12.6716 5.67157 12 6.5 12C7.32843 12 8 12.6716 8 13.5Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9795 9.65539L15.8657 7.62435L17.9569 9.53519M15.9106 7.83166C16.2011 9.19588 16.0103 10.6077 15.3702 11.8296C14.7302 13.0516 13.68 14.0091 12.3963 14.5411C11.7384 14.8137 11.0398 14.9661 10.333 14.9966M5.98928 8.94458L3.82174 10.6794L2.02383 8.49616M3.97272 10.0883C3.68431 8.72477 3.87648 7.31435 4.51695 6.09386C5.15743 4.87337 6.20722 3.91713 7.49002 3.38572C8.14721 3.11348 8.84504 2.96123 9.55107 2.93064"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 4.5C15 5.32843 14.3284 6 13.5 6C12.6716 6 12 5.32843 12 4.5C12 3.67157 12.6716 3 13.5 3C14.3284 3 15 3.67157 15 4.5Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 13.5C8 14.3284 7.32843 15 6.5 15C5.67157 15 5 14.3284 5 13.5C5 12.6716 5.67157 12 6.5 12C7.32843 12 8 12.6716 8 13.5Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReplaceIcon;
