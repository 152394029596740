/**
 * @file RemoveContactDialog.tsx
 * Remove dialog for a Contact.
 *
 * components:
 *   RemoveContactDialog
 *
 * author: jackv
 */

import React from "react";

import { useMutation } from "@apollo/client";
import { Contact } from "../../../types";

import { REMOVE_CONTACT_MUTATION } from "../../../globals/graphql";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import { useHistory } from "react-router-dom";
import { useAnalytics } from "../../../globals/hooks";
import RemoveDialog from "components/RemoveDialog";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type RemoveContactDialogProps = {
  open: boolean;
  contact: Contact;
  onClose: () => void;
};

function RemoveContactDialog(props: RemoveContactDialogProps) {
  const { open, contact, onClose } = props;

  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const { track } = useAnalytics();

  // mutations
  const [removeContact] = useMutation(REMOVE_CONTACT_MUTATION, {
    refetchQueries: ["PaginatedContacts"],
    onCompleted() {
      track("contact_deleted");
      snackbar.success("Successfully deleted contact!");
      history.push(`/contacts`);
      onClose();
    },
    onError(error) {
      const errorMessage = getErrorMessage(error) || "Error deleting contact";

      snackbar.error(errorMessage);
    },
  });

  // event handlers
  const handleRemoveContactClick = () => {
    removeContact({
      variables: {
        input: {
          id: contact.id,
        },
      },
    });
  };

  const contactName = contact
    ? `${contact.firstName} ${contact.lastName}`
    : "this contact";

  return (
    <RemoveDialog
      open={open}
      onRemove={handleRemoveContactClick}
      onClose={onClose}
      title={`Remove ${contactName}?`}
      body={`This will premanantly remove ${contactName}. Do you want to delete this
      contact?`}
      removeButtonText="Remove"
    />
  );
}

export default RemoveContactDialog;
