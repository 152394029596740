/**
 * @file routeStatusLog.graphql.ts
 * queries/mutations for RouteStatusLogs.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_ROUTE_STATUS_LOGS_QUERY = gql`
  query RouteStatusLogs($operatorRouteId: ID!) {
    routeStatusLogs(operatorRouteId: $operatorRouteId) {
      id
      createdAt
      statusSlug
      statusName
      author {
        ... on Driver {
          __typename
          id
          firstName
          lastName
        }
        ... on User {
          __typename
          id
          firstName
          lastName
        }
      }
    }
  }
`;
