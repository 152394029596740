import React from "react";

import { Box, Typography } from "@mui/material";

import { ListIcon, MapIcon } from "design-system/icons";
import { grayLight, white } from "design-system/colors";
import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";
import { PageViewEnum } from "../DriverTrackingPage";

type MobileDriverTrackingHeaderProps = {
  onPageViewClick?: (_, newType: "trips" | "map") => void;
  pageView?: PageViewEnum.Trips | PageViewEnum.Map;
};

function MobileDriverTrackingHeader(props: MobileDriverTrackingHeaderProps) {
  const { onPageViewClick, pageView } = props;

  const toggleButtons = [
    {
      value: "trips",
      ariaLabel: "trips",
      icon: ListIcon,
    },
    {
      value: "map",
      ariaLabel: "map",
      icon: MapIcon,
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        padding: "15px 0",
        borderBottom: `1px solid ${grayLight}`,
        backgroundColor: white,
      }}
    >
      <Box m={2} sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h1">Driver Tracking</Typography>
      </Box>
      <Box m={2}>
        <MoovsToggleButtonGroup
          onChange={onPageViewClick}
          options={toggleButtons}
          value={pageView}
        />
      </Box>
    </Box>
  );
}

export default MobileDriverTrackingHeader;
