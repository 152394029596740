import React from "react";

type PDFLogoIconProps = {
  color?: string;
  width?: string;
  height?: string;
  viewBox?: string;
};

const PDFLogoIcon = (props: PDFLogoIconProps) => {
  const { color, width, height, viewBox } = props;

  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox={viewBox || "0 0 24 24"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.248 0.749024V5.24902C17.248 5.64685 17.4061 6.02838 17.6874 6.30968C17.9687 6.59099 18.3502 6.74902 18.748 6.74902H23.248M8.51405 6.72302C8.44275 6.20588 8.26976 5.70797 8.00505 5.25802C7.87024 5.0344 7.68174 4.84795 7.45666 4.71559C7.23157 4.58322 6.97701 4.50913 6.71605 4.50002C6.3837 4.53581 6.07283 4.68159 5.83278 4.91421C5.59273 5.14683 5.43726 5.45296 5.39105 5.78402C5.31665 6.45333 5.48989 7.12672 5.87805 7.67702C7.10097 9.08642 8.41584 10.4133 9.81405 11.649C11.3792 12.9173 13.1502 13.908 15.05 14.578C15.5707 14.8206 16.1397 14.9417 16.714 14.932C17.0008 14.9204 17.2767 14.8197 17.5035 14.6439C17.7302 14.468 17.8965 14.2258 17.979 13.951C18.0101 13.7253 17.9819 13.4953 17.897 13.2838C17.8121 13.0724 17.6736 12.8866 17.495 12.745C17.1334 12.4669 16.7084 12.2831 16.258 12.21C14.4652 11.9482 12.6352 12.1422 10.937 12.774C9.31505 13.274 4.57505 14.841 4.79305 18.826C4.79282 18.9585 4.82328 19.0893 4.88205 19.208C4.96864 19.3222 5.08581 19.4094 5.21996 19.4597C5.35412 19.5099 5.49978 19.5212 5.64005 19.492C5.99138 19.4235 6.32464 19.2827 6.61866 19.0785C6.91269 18.8743 7.16107 18.6113 7.34805 18.306C8.44105 16.763 8.55505 14.796 8.69805 12.975C8.90899 10.8919 8.84713 8.79014 8.51405 6.72302ZM22.809 5.56002C23.0901 5.84122 23.248 6.22248 23.248 6.62002V21C23.2478 21.5964 23.0107 22.1683 22.589 22.59C22.1673 23.0117 21.5954 23.2488 20.999 23.249H2.99905C2.70357 23.2492 2.41097 23.1911 2.13793 23.0781C1.8649 22.9652 1.61679 22.7996 1.40776 22.5907C1.19874 22.3819 1.0329 22.1339 0.919701 21.861C0.806506 21.5881 0.748178 21.2955 0.748047 21V3.00002C0.747916 2.70438 0.80605 2.41161 0.919127 2.13845C1.0322 1.86528 1.19801 1.61708 1.40706 1.40803C1.61611 1.19898 1.86431 1.03318 2.13747 0.920103C2.41063 0.807026 2.7034 0.748892 2.99905 0.749024H17.377C17.5741 0.748934 17.7692 0.787658 17.9512 0.862982C18.1333 0.938307 18.2987 1.04876 18.438 1.18802L22.809 5.56002Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PDFLogoIcon;
