import React, { useState, ChangeEvent } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { Box, MenuItem, Typography, TextField } from "@mui/material";

import CreateDrawer from "../../globals/CreateDrawer";
import VehiclesBlock from "../general/VehiclesBlock";
import RefundCreateRow from "./RefundCreateRow";
import {
  CancellationPolicy,
  CancellationPolicyVariant,
  Vehicle,
} from "../../../types";
import {
  CREATE_CANCELLATION_POLICY_MUTATION,
  LOAD_CANCELLATION_POLICIES_QUERY,
} from "../../../globals/graphql";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import GQLQueryStatusIndicator from "../../GQLQueryStatusIndicator";
import { useHistory } from "react-router-dom";
import { useAnalytics } from "../../../globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

const policyTypeMap = {
  FLEXIBLE: {
    name: "Flexible",
    copy: "The most lenient policy. Commonly used for trips in sedans and SUVs.",
  },
  MODERATE: {
    name: "Moderate",
    copy: "Your ordinary policy. Suitable for weekend events and average trips.",
  },
  STRICT: {
    name: "Strict",
    copy: "The strongest policy. Effective for weddings, prom, and events scheduled during your busy season.",
  },
};

const errorRefundMap = {
  periodName: "Active refunds require a time",
  periodValue: "Active refunds require a quantity",
};

const initialCancellationPolicy = {
  variant: null,
  refund100: {
    periodName: null,
    periodValue: null,
    active: true,
  },
  refund50: {
    periodName: null,
    periodValue: null,
    active: true,
  },
  refund25: {
    periodName: null,
    periodValue: null,
    active: true,
  },
  vehicles: [],
};

const initialCancellationPolicyErrors = {
  refund100periodName: "",
  refund100periodValue: "",
  refund50periodName: "",
  refund50periodValue: "",
  refund25periodName: "",
  refund25periodValue: "",
};

function CreateCancellationDrawer() {
  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const { track } = useAnalytics();

  // state
  const [cancellationPolicy, setCancellationPolicy] = useState<
    Partial<CancellationPolicy>
  >(initialCancellationPolicy);

  const [cancellationPolicyErrors, setCancellationPolicyErrors] = useState(
    initialCancellationPolicyErrors
  );

  const [cancellationPolicySaveError, setCancellationPolicySaveError] =
    useState(false);

  const [vehicles, setVehicles] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  // queries
  const { data, error, refetch, networkStatus, loading } = useQuery(
    LOAD_CANCELLATION_POLICIES_QUERY,
    {
      onError: (error) => {
        const errorMessage = getErrorMessage(error) || error.message;

        snackbar.error(errorMessage);
      },
    }
  );

  const cancellationPolicies = data ? data.cancellationPolicies : [];

  const policyVariants = ["FLEXIBLE", "MODERATE", "STRICT"].filter(
    (variant) =>
      !cancellationPolicies.find(
        (policy: CancellationPolicy) => policy.variant === variant
      )
  );

  initialCancellationPolicy.variant = policyVariants[0];

  // mutations
  const [createCancellationPolicyMutation] = useMutation(
    CREATE_CANCELLATION_POLICY_MUTATION,
    {
      refetchQueries: [{ query: LOAD_CANCELLATION_POLICIES_QUERY }],
      onCompleted(data) {
        const result = data.createCancellationPolicyV2.result;

        if (result.__typename === "CreateCancellationPolicyFailure") {
          snackbar.error(result.message);
          setSubmitDisabled(false);
          return;
        }

        handleCreateDrawerClose();
        snackbar.success(`Successfully created ${result.variant} policy!`);
        track("create_cancellation_policy");
      },
      onError(error) {
        setSubmitDisabled(false);
        const errorMessage =
          getErrorMessage(error) || "Error creating cancellation policy.";

        snackbar.error(errorMessage);
      },
    }
  );

  // event handlers
  const handleCreateDrawerClose = () => {
    history.push("/settings/cancellation");
  };

  const handlePolicyVariantChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCancellationPolicy({
      ...cancellationPolicy,
      variant: event.target.value as CancellationPolicyVariant,
    });
  };

  const handleRefundChange =
    (name: string) => (key: string, value: string | number) => {
      const refund = {
        ...cancellationPolicy[name],
        [key]: value,
      };

      setCancellationPolicy({
        ...cancellationPolicy,
        [name]: refund,
      });

      setCancellationPolicyErrors({
        ...cancellationPolicyErrors,
        [`${name}${key}`]: "",
      });
    };

  const handleClickSave = () => {
    let errors = [];

    if (
      cancellationPolicy.refund100.active &&
      !cancellationPolicy.refund100.periodName
    ) {
      errors = [...errors, { refund100periodName: errorRefundMap.periodName }];
    }

    if (
      cancellationPolicy.refund100.active &&
      !cancellationPolicy.refund100.periodValue
    ) {
      errors = [
        ...errors,
        { refund100periodValue: errorRefundMap.periodValue },
      ];
    }

    if (
      cancellationPolicy.refund50.active &&
      !cancellationPolicy.refund50.periodName
    ) {
      errors = [...errors, { refund50periodName: errorRefundMap.periodName }];
    }

    if (
      cancellationPolicy.refund50.active &&
      !cancellationPolicy.refund50.periodValue
    ) {
      errors = [...errors, { refund50periodValue: errorRefundMap.periodValue }];
    }

    if (
      cancellationPolicy.refund25.active &&
      !cancellationPolicy.refund25.periodName
    ) {
      errors = [...errors, { refund25periodName: errorRefundMap.periodName }];
    }

    if (
      cancellationPolicy.refund25.active &&
      !cancellationPolicy.refund25.periodValue
    ) {
      errors = [...errors, { refund25periodValue: errorRefundMap.periodValue }];
    }

    if (errors.length) {
      setCancellationPolicyErrors(
        errors.reduce((acc, value) => ({ ...acc, ...value }), {})
      );
      setCancellationPolicySaveError(true);
      return;
    }

    setCancellationPolicySaveError(false);
    setSubmitDisabled(true);
    createCancellationPolicyMutation({
      variables: {
        input: {
          ...cancellationPolicy,
          vehicles,
        },
      },
    });
  };

  const handleVehiclesChange = (selectedVehicles: Partial<Vehicle>[]) => {
    setVehicles(selectedVehicles.map(({ id }) => id));
  };

  if (!data || loading) {
    return (
      <GQLQueryStatusIndicator
        name="Cancellation Policy"
        error={error}
        data={data}
        loading={loading}
        networkStatus={networkStatus}
        refetch={refetch}
      />
    );
  }

  return (
    <CreateDrawer
      onClose={handleCreateDrawerClose}
      pageLabel="Create New Policy"
      submitLabel="Save Policy"
      onSubmit={handleClickSave}
      saveError={cancellationPolicySaveError}
      submitDisabled={submitDisabled}
    >
      {/* Cancellation Type */}
      <Box>
        <Box mt={4.5} mb={3.5}>
          <Typography variant="h5">Cancellation Type</Typography>
        </Box>
        <TextField
          select
          fullWidth
          name="variant"
          variant="outlined"
          label="Policy"
          value={cancellationPolicy.variant}
          onChange={handlePolicyVariantChange}
        >
          {policyVariants.map((policyType) => (
            <MenuItem key={policyType} value={policyType}>
              {policyTypeMap[policyType].name}
            </MenuItem>
          ))}
        </TextField>
        <Box m={6}>
          <Typography variant="body2">
            {policyTypeMap[cancellationPolicy.variant] &&
              policyTypeMap[cancellationPolicy.variant].copy}
          </Typography>
        </Box>
      </Box>

      {/* Refund Conditions */}
      <Box mt={1}>
        <Typography variant="h5">Refund Conditions</Typography>
        <Box my={3}>
          <RefundCreateRow
            label="Full Refund"
            refund={cancellationPolicy.refund100}
            onChange={handleRefundChange("refund100")}
            periodNameError={cancellationPolicyErrors.refund100periodName}
            periodValueError={cancellationPolicyErrors.refund100periodValue}
          />
        </Box>
        <Box my={3}>
          <RefundCreateRow
            label="50% Refund"
            refund={cancellationPolicy.refund50}
            onChange={handleRefundChange("refund50")}
            periodNameError={cancellationPolicyErrors.refund50periodName}
            periodValueError={cancellationPolicyErrors.refund50periodValue}
          />
        </Box>
        <Box my={3}>
          <RefundCreateRow
            label="25% Refund"
            refund={cancellationPolicy.refund25}
            onChange={handleRefundChange("refund25")}
            periodNameError={cancellationPolicyErrors.refund25periodName}
            periodValueError={cancellationPolicyErrors.refund25periodValue}
          />
        </Box>
      </Box>

      {/* Vehicles */}
      <Box>
        <VehiclesBlock
          onVehiclesChange={handleVehiclesChange}
          vehicleIds={vehicles}
        />
      </Box>
    </CreateDrawer>
  );
}

export default CreateCancellationDrawer;
