import { gql } from "@apollo/client";

export const UPDATE_SMS_RECIPIENTS_FOR_TRIP_MUTATION = gql`
  mutation UpdateTripTextRecipients($input: UpdateTripTextRecipientsInput!) {
    updateTripTextRecipients(input: $input) {
      trip {
        id
        reminderTextTo
        statusUpdateTextTo
      }
    }
  }
`;
