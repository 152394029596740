import React from "react";

type PhoneIconProps = {
  color?: string;
  size?: "small";
};

const PhoneIcon = (props: PhoneIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9997 10.4869V11.9923C13.0003 12.1321 12.9716 12.2704 12.9155 12.3985C12.8594 12.5265 12.7771 12.6414 12.6739 12.7359C12.5707 12.8304 12.4489 12.9023 12.3163 12.9471C12.1836 12.9919 12.0431 13.0085 11.9036 12.9959C10.3564 12.8281 8.87022 12.3005 7.56445 11.4554C6.34961 10.685 5.31963 9.65703 4.54767 8.44459C3.69793 7.13549 3.16912 5.64504 3.00408 4.09397C2.99152 3.95521 3.00804 3.81536 3.0526 3.68331C3.09716 3.55127 3.16878 3.42994 3.2629 3.32703C3.35703 3.22413 3.47158 3.14191 3.59929 3.08562C3.72699 3.02932 3.86504 3.00018 4.00465 3.00005H5.51304C5.75705 2.99765 5.99361 3.08389 6.17862 3.24269C6.36364 3.40148 6.48448 3.622 6.51863 3.86315C6.5823 4.34491 6.70037 4.81794 6.87059 5.27321C6.93824 5.45281 6.95288 5.64801 6.91278 5.83567C6.87268 6.02332 6.77952 6.19557 6.64433 6.33201L6.00578 6.96929C6.72154 8.22558 7.76379 9.26576 9.02256 9.9801L9.66112 9.34281C9.79782 9.2079 9.97041 9.11492 10.1584 9.0749C10.3465 9.03488 10.5421 9.04949 10.722 9.117C11.1782 9.28689 11.6522 9.40473 12.1349 9.46826C12.3791 9.50265 12.6022 9.62543 12.7616 9.81325C12.9211 10.0011 13.0058 10.2408 12.9997 10.4869Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9995 15.9791V18.3877C20.0004 18.6113 19.9545 18.8327 19.8648 19.0375C19.775 19.2424 19.6434 19.4263 19.4783 19.5775C19.3132 19.7286 19.1183 19.8437 18.906 19.9154C18.6938 19.987 18.4689 20.0136 18.2457 19.9935C15.7702 19.725 13.3923 18.8808 11.3031 17.5286C9.35937 16.2959 7.71141 14.6512 6.47627 12.7113C5.11669 10.6168 4.27059 8.23206 4.00653 5.75036C3.98643 5.52834 4.01286 5.30457 4.08416 5.0933C4.15546 4.88203 4.27005 4.6879 4.42065 4.52325C4.57124 4.35861 4.75454 4.22706 4.95886 4.13699C5.16319 4.04691 5.38407 4.00029 5.60744 4.00008H8.02086C8.41128 3.99624 8.78977 4.13422 9.0858 4.3883C9.38182 4.64237 9.57517 4.99521 9.62981 5.38103C9.73168 6.15185 9.92059 6.9087 10.1929 7.63713C10.3012 7.9245 10.3246 8.23682 10.2604 8.53707C10.1963 8.83732 10.0472 9.11292 9.83093 9.33121L8.80925 10.3509C9.95446 12.3609 11.6221 14.0252 13.6361 15.1682L14.6578 14.1485C14.8765 13.9326 15.1527 13.7839 15.4535 13.7198C15.7544 13.6558 16.0673 13.6792 16.3552 13.7872C17.0851 14.059 17.8435 14.2476 18.6158 14.3492C19.0066 14.4042 19.3635 14.6007 19.6186 14.9012C19.8737 15.2017 20.0093 15.5853 19.9995 15.9791Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhoneIcon;
