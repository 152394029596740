import React from "react";

type VehicleTypeBusIconProps = {
  color?: string;
};

const VehicleTypeBusIcon = (props: VehicleTypeBusIconProps) => {
  const { color } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 6H16.974C17.3033 6 17.6115 6.16212 17.798 6.43347L23 14M15 6H11.5M15 6V11M11.5 6H8M11.5 6V10.5M8 6H4.5M8 6V10.5M4.5 6H1.5L1 11M4.5 6V10.5M1 11V18H5M1 11H15M23 14V18H20.5M23 14H18.5C16.1 14 15.1667 11.8333 15 11M8 18H17M8 18.5C8 19.3284 7.32843 20 6.5 20C5.67157 20 5 19.3284 5 18.5C5 17.6716 5.67157 17 6.5 17C7.32843 17 8 17.6716 8 18.5ZM20 18.5C20 19.3284 19.3284 20 18.5 20C17.6716 20 17 19.3284 17 18.5C17 17.6716 17.6716 17 18.5 17C19.3284 17 20 17.6716 20 18.5Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VehicleTypeBusIcon;
