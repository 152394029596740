import React from "react";

type CollapseIconProps = {
  color?: string;
};

const CollapseIcon = (props: CollapseIconProps) => {
  const { color } = props;

  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 7L19.5 1M19.5 1H15.1364M19.5 1V5.36364M7.5 13L1.5 19M1.5 19H5.86364M1.5 19L1.5 14.6364M7.5 7L1.5 1M1.5 1L1.5 5.36364M1.5 1L5.86364 1M13.5 13L19.5 19M19.5 19V14.6364M19.5 19H15.1364"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CollapseIcon;
