export enum LuggageEnum {
  carryOnLuggage = "carryOnLuggage",
  checkedLuggage = "checkedLuggage",
  oversizeLuggage = "oversizeLuggage",
}

export type LuggageFields = {
  carryOnLuggage: number;
  checkedLuggage: number;
  oversizeLuggage: number;
};
