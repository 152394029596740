import React from "react";

type VideoIconProps = {
  color?: string;
  size?: "small";
  width?: string;
  height?: string;
  viewBox?: string;
};

const VideoIcon = (props: VideoIconProps) => {
  const { color, size, width, height, viewBox } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4286 6.28571L9.71429 6.85714V6.28571C9.71429 6.05839 9.62398 5.84037 9.46323 5.67962C9.30249 5.51888 9.08447 5.42857 8.85714 5.42857H5.42857C5.20124 5.42857 4.98323 5.51888 4.82248 5.67962C4.66173 5.84037 4.57143 6.05839 4.57143 6.28571V9.71429C4.57143 9.94161 4.66173 10.1596 4.82248 10.3204C4.98323 10.4811 5.20124 10.5714 5.42857 10.5714H8.85714C9.08447 10.5714 9.30249 10.4811 9.46323 10.3204C9.62398 10.1596 9.71429 9.94161 9.71429 9.71429V9.14286L11.4286 9.71429V6.28571Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4286 2H4.57143C3.15127 2 2 3.15127 2 4.57143V11.4286C2 12.8487 3.15127 14 4.57143 14H11.4286C12.8487 14 14 12.8487 14 11.4286V4.57143C14 3.15127 12.8487 2 11.4286 2Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox={viewBox || "0 0 24 24"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1429 9.42857L14.5714 10.2857V9.42857C14.5714 9.08758 14.436 8.76055 14.1949 8.51943C13.9537 8.27832 13.6267 8.14286 13.2857 8.14286H8.14286C7.80186 8.14286 7.47484 8.27832 7.23372 8.51943C6.9926 8.76055 6.85714 9.08758 6.85714 9.42857V14.5714C6.85714 14.9124 6.9926 15.2394 7.23372 15.4806C7.47484 15.7217 7.80186 15.8571 8.14286 15.8571H13.2857C13.6267 15.8571 13.9537 15.7217 14.1949 15.4806C14.436 15.2394 14.5714 14.9124 14.5714 14.5714V13.7143L17.1429 14.5714V9.42857Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1429 3H6.85714C4.7269 3 3 4.7269 3 6.85714V17.1429C3 19.2731 4.7269 21 6.85714 21H17.1429C19.2731 21 21 19.2731 21 17.1429V6.85714C21 4.7269 19.2731 3 17.1429 3Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VideoIcon;
