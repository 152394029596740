import React from "react";

import { Box } from "@mui/material";

import MoovsTabs from "components/globals/MoovsTabs";
import { WarningIcon } from "design-system/icons";
import { errorRed } from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import { DriverTabViewVariant } from "./DriverTabViews/types";

type DriverTabsProps = {
  setDriverTabView: React.Dispatch<React.SetStateAction<DriverTabViewVariant>>;
  driverTabView: DriverTabViewVariant;
  hasDriverError: boolean;
};

function DriverTabs(props: DriverTabsProps) {
  const { setDriverTabView, driverTabView, hasDriverError } = props;

  // hooks
  const { isSmallTabletView } = useScreenSize();

  const displayInfoWarning =
    hasDriverError && driverTabView !== DriverTabViewVariant.CONTACT_DETAILS;

  return (
    <Box ml={isSmallTabletView ? 0.5 : 2.5}>
      <MoovsTabs
        value={driverTabView}
        onChange={setDriverTabView}
        variant="scrollable"
        tabs={[
          {
            label: "CONTACT DETAILS",
            value: DriverTabViewVariant.CONTACT_DETAILS,
            ...(displayInfoWarning && {
              Icon: (
                <Box marginBottom={-0.5}>
                  <WarningIcon color={errorRed} size="small" />
                </Box>
              ),
            }),
          },
          {
            label: "ADDITIONAL DETAILS",
            value: DriverTabViewVariant.ADDITIONAL_DETAILS,
          },
          {
            label: "PAYOUT DETAILS",
            value: DriverTabViewVariant.PAYOUT_DETAILS,
          },
        ]}
      />
    </Box>
  );
}

export default DriverTabs;
