import { useMemo } from "react";

import { Trip } from "types";
import { NumberedTrip } from "../types";

// adds a visual number to trips, accounting for nested return trips
function useNumberedTrips(trips: Trip[]): {
  numberedTrips: NumberedTrip[];
  tripCount: number;
} {
  return useMemo(() => {
    let tripCount = 0;

    const numberedTrips = trips.map((trip) => {
      tripCount++;

      const newTrip = {
        ...trip,
        tripListNumber: tripCount,
      };

      if (trip.returnTrip) {
        tripCount++;

        const newReturnTrip = {
          ...trip.returnTrip,
          tripListNumber: tripCount,
        };

        newTrip.returnTrip = newReturnTrip;
      }

      return newTrip;
    });

    return {
      numberedTrips,
      tripCount,
    };
  }, [trips]);
}

export default useNumberedTrips;
