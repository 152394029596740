import React from "react";
import first from "lodash/first";

import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Trip } from "types";
import { AirportIcon } from "design-system/icons";
import RoundChip from "design-system/components/chips/RoundChip";
import { pickUpVariantLabelColors } from "design-system/colors";

function PickUpLocationColumn(props: GridRenderCellParams<string>) {
  const { value, row } = props;

  const { stops } = row as Trip;
  const pickupStop = first(stops);
  const pickupStopVariant = pickupStop.pickUpVariant;

  return (
    <Box display="flex" flexDirection="row" alignItems="center" width="100%">
      {pickupStop.airport && (
        <Box mr={1.5} display="flex" alignItems="center">
          <AirportIcon size="small" />
        </Box>
      )}
      <Typography variant="body2" textOverflow="ellipsis" overflow="hidden">
        {value}
      </Typography>
      {pickupStopVariant && (
        <Box display={"flex"} flex={2} justifyContent={"flex-end"}>
          <RoundChip
            {...pickUpVariantLabelColors[pickupStopVariant]}
            whiteSpace={"nowrap"}
          />
        </Box>
      )}
    </Box>
  );
}

export default PickUpLocationColumn;
