import React from "react";
import moment from "moment";

import { Box, Typography } from "@mui/material";

import { granite, grayDark } from "design-system/colors";
import { useScreenSize } from "globals/hooks";

type ArchiveDateHeaderProps = {
  autoArchiveDate: Date;
};

function ArchiveDateHeader(props: ArchiveDateHeaderProps) {
  const { autoArchiveDate } = props;

  // hooks
  const { isSmallTabletView } = useScreenSize();

  return (
    <Box display="flex" flexDirection={isSmallTabletView ? "column" : "row"}>
      <Box width={isSmallTabletView ? "100%" : "50%"}>
        <Typography variant="h4" mb={1}>
          Scheduled message
        </Typography>
        <Typography variant="body2" color={granite}>
          Automated text messages will be sent on scheduled dates unless the
          quote is confirmed into a reservation
        </Typography>
      </Box>
      <Box
        width={isSmallTabletView ? "100%" : "50%"}
        mt={isSmallTabletView ? 3 : 1}
      >
        <Box
          display="flex"
          justifyContent={isSmallTabletView ? "flex-start" : "flex-end"}
          mb={1}
        >
          <Typography variant="overline" color={grayDark}>
            Auto Archive date
          </Typography>
        </Box>
        <Typography
          variant="body2"
          textAlign={isSmallTabletView ? "left" : "right"}
          color={granite}
        >
          {moment(autoArchiveDate).format("M/DD/YY")}
        </Typography>
      </Box>
    </Box>
  );
}

export default ArchiveDateHeader;
