import React, { useState } from "react";

import { Box, Button, Divider, Typography } from "@mui/material";

import { FarmAffiliateVehicle } from "../../../types";
import VehiclesDialog from "../../reservations/VehiclesDialog";
import ViewOnlyVehicleDrawer from "./ViewOnlyVehicleDrawer";
import FarmAffiliateVehicleCard from "../../../components/vehicles/VehicleCard/FarmAffiliateVehicleCard";
import { useAnalytics } from "../../../globals/hooks";

type FarmAffiliateVehicleSectionType = {
  vehicles: FarmAffiliateVehicle[];
  canAddOrRemoveVehicles: boolean;
  onVehicleAdd: (vehicles: FarmAffiliateVehicle[]) => void;
  onVehicleRemove: (vehicleId: string) => void;
  canSelectMultipleVehicles?: boolean;
};

function FarmAffiliateVehicleSection(props: FarmAffiliateVehicleSectionType) {
  const {
    vehicles,
    onVehicleAdd,
    onVehicleRemove,
    canSelectMultipleVehicles,
    canAddOrRemoveVehicles,
  } = props;

  const [vehicleDialogOpen, setVehicleDialogOpen] = useState(false);
  const [vehicleViewOnlyDrawerOpen, setVehicleViewOnlyDrawerOpen] =
    useState(false);
  const [viewOnlyVehicle, setViewOnlyVehicle] = useState(null);

  const { track } = useAnalytics();

  const handleVehicleDialogOpen = () => {
    setVehicleDialogOpen(true);
  };

  const handleVehicleDialogClose = () => {
    setVehicleDialogOpen(false);
  };

  const handleViewOnlyVehicleDialogClose = () => {
    setViewOnlyVehicle(false);
  };

  // event handlers
  const handleVehicleRemove = (vehicleId: string) => () => {
    onVehicleRemove(vehicleId);
  };

  const handleVehicleAdd = (vehicles: FarmAffiliateVehicle[]) => {
    onVehicleAdd(vehicles);
    setVehicleDialogOpen(false);
  };

  const handleVehicleViewOnlyClick = (vehicle: FarmAffiliateVehicle) => {
    track("affiliateDrawer_vehicleOpened");
    setViewOnlyVehicle(vehicle);
    setVehicleViewOnlyDrawerOpen(true);
  };

  return (
    <>
      <Typography variant="h3">Vehicles</Typography>
      <Box mb={2} mt={1}>
        <Divider />
      </Box>

      <Box mb={2}>
        {vehicles.map((vehicle) => (
          <Box mb={2} key={vehicle.id}>
            <FarmAffiliateVehicleCard
              key={vehicle.id}
              vehicle={vehicle}
              {...(canAddOrRemoveVehicles && {
                ellipsisMenuActions: {
                  onPressRemove: handleVehicleRemove(vehicle.id),
                },
              })}
              onVehicleViewOnlyClick={handleVehicleViewOnlyClick}
            />
          </Box>
        ))}

        {canAddOrRemoveVehicles && (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleVehicleDialogOpen}
          >
            Link Vehicle
          </Button>
        )}

        <VehiclesDialog
          dialogMode={canSelectMultipleVehicles ? "addMultiple" : "add"}
          open={vehicleDialogOpen}
          onClose={handleVehicleDialogClose}
          onCreate={handleVehicleAdd}
          vehiclesToFilterOut={vehicles.map((vehicle) => vehicle.id)}
        />

        {viewOnlyVehicle && (
          <ViewOnlyVehicleDrawer
            open={vehicleViewOnlyDrawerOpen}
            handleClose={handleViewOnlyVehicleDialogClose}
            vehicle={viewOnlyVehicle}
          />
        )}
      </Box>
    </>
  );
}

export default FarmAffiliateVehicleSection;
