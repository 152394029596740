import React from "react";

import { Box, Card, CardActionArea, Typography } from "@mui/material";

import { ComingSoonChip, InstalledChip, FreeTrialMessage } from "./components";
import {
  grayLight,
  moovsBlue,
  moovsBlueLight,
  moovsBlueSelected,
} from "design-system/colors";
import { MoovsMarketAppData } from "../../hooks/useMoovsMarketApps";

type MoovsMarketAppListItemProps = MoovsMarketAppData & {
  onClick: (app: MoovsMarketAppData) => void;
  mode: "available" | "installed";
};

function MoovsMarketAppListItem(props: MoovsMarketAppListItemProps) {
  const {
    id,
    name,
    icon,
    description,
    comingSoon,
    active,
    onClick,
    mode,
    isPaidApp,
  } = props;

  // event handlers
  const handleClick = () => {
    onClick(props);
  };

  return (
    <Card
      variant="outlined"
      key={id}
      sx={{
        backgroundColor: comingSoon && "#F6F6F6",
        // hide border here so can change on focus/hover of child CardActionArea
        border: "none",
        display: "flex",
        flex: 1,
        height: "100%",
      }}
    >
      <CardActionArea
        disableRipple
        onClick={handleClick}
        disabled={comingSoon}
        sx={{
          border: `1px solid ${grayLight}`,
          borderRadius: "4px",
          ":focus": {
            backgroundColor: moovsBlueSelected,
            borderColor: moovsBlueLight,
          },
          ":hover": {
            backgroundColor: moovsBlueSelected,
            borderColor: moovsBlueLight,
          },
          display: "flex",
          alignItems: "flex-start",
          height: "100%",
        }}
      >
        <Box
          display="flex"
          flex="1"
          justifyContent="space-between"
          flexDirection="row"
          height="100%"
          py={3}
          px={2.5}
        >
          <Box display="flex" flexDirection="row">
            {/* icon */}
            <Box
              minHeight="70px"
              minWidth="70px"
              display="flex"
              justifyContent="center"
              alignItems="flex-start"
            >
              <img src={icon} alt={name + "icon"} />
            </Box>

            {/* details */}
            <Box display="flex" flexDirection="column" mx={3}>
              <Box>
                {comingSoon && <ComingSoonChip />}
                {active && mode === "available" && <InstalledChip />}
              </Box>
              <Typography
                variant="subtitle2"
                mt={comingSoon || (active && mode === "available") ? 1 : 0}
              >
                {name}
              </Typography>
              <Typography sx={{ mt: 0.5 }} variant="caption">
                {description}
              </Typography>
              {isPaidApp && <FreeTrialMessage sx={{ mt: 1.5 }} {...props} />}
            </Box>
          </Box>

          {/* view */}
          {!comingSoon && (
            <Box display="flex" alignItems="center" p={2}>
              <Typography color={moovsBlue} variant="button">
                View
              </Typography>
            </Box>
          )}
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default MoovsMarketAppListItem;
