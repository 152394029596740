import { useFlags } from "launchdarkly-react-client-sdk";

interface LaunchDarklyFlags {
  enableLinkedPassenger: boolean;
  enablePocketflowsCrm: boolean;
  enableDispatchV2: boolean;
}

export function useLaunchDarklyFlags(): Partial<LaunchDarklyFlags> {
  return useFlags();
}
