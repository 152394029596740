import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useLazyQuery, useMutation } from "@apollo/client";
import map from "lodash/map";
import size from "lodash/size";
import pickBy from "lodash/pickBy";

import moment, { Moment } from "moment-timezone";
import DatePicker from "@mui/lab/DatePicker";
import {
  Typography,
  Box,
  Divider,
  TextField,
  CircularProgress,
} from "@mui/material";

import CreateDrawer from "../../globals/CreateDrawer";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import { LOAD_INVOICEABLE_REQUESTS_QUERY } from "../../../globals/graphql";
import { CREATE_INVOICE_MUTATION } from "../../../globals/graphql/invoice.graphql";
import { currency, currencyConverter } from "../../../globals/utils/helpers";
import {
  Company,
  Contact,
  FarmAffiliateVariantEnum,
  GriddnetOperator,
  MoovsNetworkOperator,
  Request,
} from "../../../types";
import ProfileInfoCard from "../../globals/ProfileInfoCard";
import {
  CalendarIcon,
  CompanyIcon,
  TrashIcon,
} from "../../../design-system/icons";
import InvoiceItemsListItemDesktop from "./components/InvoiceItemsListItem.desktop";
import InvoiceItemsListItemMobile from "./components/InvoiceItemsListItem.mobile";
import { alabaster, purple, tintPurple } from "../../../design-system/colors";
import {
  useAnalytics,
  useLocalStorage,
  useScreenSize,
} from "../../../globals/hooks";
import ContactAffiliateCompanyAutoComplete from "components/autocompletes/ContactAffiliateCompanyAutoComplete";
import { parseInvoiceRecipientIds } from "./helpers/parseInvoiceRecipientIds";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

// constants
const LOCAL_STORAGE_KEY = "submitInvoiceSettings";
const INITIAL_INVOICE_ERRORS = {
  memo: "",
  dueDate: "",
  contactId: "",
  farmorOperatorId: "",
  requestIds: "",
};

function CreateInvoiceDrawer() {
  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const { track } = useAnalytics();
  const { isMobileView } = useScreenSize();

  // state
  const [invoice, setInvoice] = useState({
    memo: "",
    dueDate: moment().add(1, "week"),
    contactId: "",
    farmorOperatorId: "",
    companyId: "",
    requestIds: [],
  });
  const [invoiceErrors, setInvoiceErrors] = useState(INITIAL_INVOICE_ERRORS);
  const [invoiceSaveError, setInvoiceSaveError] = useState("");
  const [invoiceRecipient, setInvoiceRecipient] = useState(null);
  const [invoiceRecipientRequests, setInvoiceRecipientRequests] =
    useState(null);
  const [
    selectedInvoiceRecipientRequests,
    setSelectedInvoiceRecipientRequests,
  ] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [selectedSubmitButtonIndex, setSelectedSubmitButtonIndex] =
    useLocalStorage(LOCAL_STORAGE_KEY, 0);

  // derived state
  const shouldSendEmail = selectedSubmitButtonIndex === 0;

  const [loadRequestsList, { loading: requestsLoading }] = useLazyQuery(
    LOAD_INVOICEABLE_REQUESTS_QUERY,
    {
      onCompleted: (data) => {
        setInvoiceRecipientRequests(
          map(data.loadInvoiceableRequests.edges, "node")
        );
      },
      onError(error) {
        setSubmitDisabled(false);
        const errorMessage =
          getErrorMessage(error) ||
          "Error loading requests for selected contact";

        snackbar.error(errorMessage);
      },
      fetchPolicy: "network-only",
    }
  );

  // mutations
  const [createInvoice] = useMutation(CREATE_INVOICE_MUTATION, {
    onCompleted(data) {
      const invoiceSource = data.createInvoice.invoice.company
        ? { source: "company" }
        : { source: "contact" };

      track("create_invoice", invoiceSource);

      snackbar.success("Successfully created invoice");
      const invoiceId = data.createInvoice.invoice.id;

      // open send email dialog if user selected 'save and send invoice'
      if (shouldSendEmail) {
        history.push({
          pathname: `/invoices/update/${invoiceId}`,
          state: { dialogOpen: true },
        });
      } else {
        handleCreateDrawerClose();
      }
    },
    onError(error) {
      setSubmitDisabled(false);
      const errorMessage = getErrorMessage(error) || "Error creating invoice";

      snackbar.error(errorMessage);
    },
    refetchQueries: ["LoadInvoices"],
  });

  // effects
  // lazy load requests query when a contact is selected
  useEffect(() => {
    if (invoiceRecipient?.id) {
      loadRequestsList({
        variables: parseInvoiceRecipientIds(invoiceRecipient),
      });
    }
  }, [invoiceRecipient, loadRequestsList]);

  // event handlers
  const handleCreateDrawerClose = () => {
    history.push("/invoices/");
  };

  const handleInvoiceRecipientDeselect = () => {
    setInvoiceRecipient(null);
    setInvoice((prevState) => ({
      ...prevState,
      contactId: "",
      farmorOperatorId: "",
      companyId: "",
    }));
    setInvoiceRecipientRequests(null);
  };

  const handleInvoiceRecipientSelect = (
    invoiceRecipient:
      | Contact
      | MoovsNetworkOperator
      | Company
      | GriddnetOperator
  ) => {
    setInvoiceRecipient(invoiceRecipient);

    setInvoiceErrors((prevState) => ({
      ...prevState,
      contactId: "",
      farmorOperatorId: "",
      companyId: "",
    }));

    setInvoice((prevState) => ({
      ...prevState,
      ...parseInvoiceRecipientIds(invoiceRecipient),
    }));
  };

  const handleSubmitClick = () => {
    const errors = { ...INITIAL_INVOICE_ERRORS };

    if (!invoice.contactId && !invoice.farmorOperatorId && !invoice.companyId)
      errors.contactId = "Please add a invoice recipient to continue";

    if (!size(invoice.requestIds))
      errors.requestIds = "Please select at least 1 order to create an invoice";

    if (!invoice.dueDate?.isValid())
      errors.dueDate = "Please enter a valid date";

    if (size(pickBy(errors))) {
      setInvoiceErrors(errors);
      setInvoiceSaveError("Oops! Please check the above fields");
      return;
    }

    setSubmitDisabled(true);
    setInvoiceSaveError("");

    createInvoice({
      variables: {
        input: invoice,
      },
    });
  };

  const handleNavigateToRequestClick = (requestId) => {
    history.push(`/reservations/${requestId}`);
  };

  const handleRequestCheckClick = (request) => {
    // if selectedInvoiceRecipientRequests already contains the event request, remove it.
    // if not add it to the list.
    let newRequests = selectedInvoiceRecipientRequests;

    if (newRequests.includes(request)) {
      const result = newRequests.filter((newRequest) => newRequest !== request);
      newRequests = result;
    } else {
      newRequests.push(request);
    }
    setSelectedInvoiceRecipientRequests(newRequests);
    const newRequestIds = newRequests.map((request) => request.id);

    setInvoiceErrors((prevState) => ({ ...prevState, requestIds: "" }));
    setInvoice((prevState) => ({ ...prevState, requestIds: newRequestIds }));
  };

  const handleInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = event.target.value;
    let name = event.target.name;

    if (name === "memo") {
      value.toString().length >= 500
        ? setInvoiceErrors((prevState) => ({
            ...prevState,
            memo: "Reached character limit",
          }))
        : setInvoiceErrors((prevState) => ({ ...prevState, memo: "" }));
    }

    setInvoice((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDueDateChange = (dueDate: Moment) => {
    setInvoice((prevState) => ({
      ...prevState,
      dueDate,
    }));
  };

  let tripsArr = [];
  selectedInvoiceRecipientRequests.forEach((request) => {
    request.trips.forEach((trip) => {
      tripsArr.push(trip);
    });
  });

  const amountDue = tripsArr.reduce((amountDue, trip) => {
    const amountDueCents = currencyConverter.toCents(amountDue);
    const tripAmountDueCents = currencyConverter.toCents(trip.amountDue);
    const summedAmountCents = amountDueCents + tripAmountDueCents;

    return currencyConverter.toDollars(summedAmountCents);
  }, 0);

  const totalAmount = tripsArr.reduce((totalAmount, trip) => {
    const totalAmountCents = currencyConverter.toCents(totalAmount);
    const tripTotalAmountCents = currencyConverter.toCents(trip.totalAmount);
    const summedTotalAmountCents = totalAmountCents + tripTotalAmountCents;

    return currencyConverter.toDollars(summedTotalAmountCents);
  }, 0);

  return (
    <CreateDrawer
      onClose={handleCreateDrawerClose}
      pageLabel="Create Invoice"
      onSubmit={handleSubmitClick}
      saveError={!!invoiceSaveError}
      saveErrorText={invoiceSaveError}
      submitDisabled={submitDisabled}
      submitLabelOptions={["Save & Send Invoice", "Save Invoice"]}
      selectedSubmitButtonIndex={selectedSubmitButtonIndex}
      setSelectedSubmitButtonIndex={setSelectedSubmitButtonIndex}
    >
      <Helmet>
        <title>Create Invoice | Moovs</title>
      </Helmet>

      {/* Billing Contact */}
      <Box mt={4} mb={1}>
        <Typography variant="h5">Billing Contact</Typography>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      {!invoiceRecipient && (
        <Box mb={1}>
          <ContactAffiliateCompanyAutoComplete
            value={invoiceRecipient || null}
            onChange={handleInvoiceRecipientSelect}
            error={!!invoiceErrors.contactId}
            helperText={invoiceErrors.contactId}
            label="Search for contact"
          />
        </Box>
      )}

      {invoiceRecipient?.__typename === "Contact" && (
        <ProfileInfoCard
          headerText="Booking / Billing Contact"
          firstName={invoiceRecipient.firstName}
          lastName={invoiceRecipient.lastName}
          email={invoiceRecipient.email}
          phone={invoiceRecipient.formattedMobilePhone}
          preferences={invoiceRecipient.preferences}
          company={invoiceRecipient.company?.name}
          paymentMethods={invoiceRecipient.paymentMethods}
          actionButtons={[
            {
              icon: <TrashIcon />,
              eventHandler: handleInvoiceRecipientDeselect,
            },
          ]}
        />
      )}
      {invoiceRecipient?.__typename ===
        FarmAffiliateVariantEnum.MoovsNetworkOperator && (
        <ProfileInfoCard
          headerText="Billing Contact"
          farmAffiliateVariant={FarmAffiliateVariantEnum.MoovsNetworkOperator}
          operatorName={invoiceRecipient.operatorName}
          email={invoiceRecipient.operatorEmail}
          phone={invoiceRecipient.operatorPhone}
          actionButtons={[
            {
              icon: <TrashIcon />,
              eventHandler: handleInvoiceRecipientDeselect,
            },
          ]}
        />
      )}
      {invoiceRecipient?.__typename ===
        FarmAffiliateVariantEnum.GriddnetOperator && (
        <ProfileInfoCard
          headerText="Billing Contact"
          farmAffiliateVariant={FarmAffiliateVariantEnum.GriddnetOperator}
          operatorName={invoiceRecipient.operatorName}
          email={invoiceRecipient.operatorEmail}
          phone={invoiceRecipient.operatorPhone}
          actionButtons={[
            {
              icon: <TrashIcon />,
              eventHandler: handleInvoiceRecipientDeselect,
            },
          ]}
        />
      )}
      {invoiceRecipient?.__typename ===
        FarmAffiliateVariantEnum.MoovsAiOperator && (
        <ProfileInfoCard
          headerText="Billing Contact"
          farmAffiliateVariant={FarmAffiliateVariantEnum.MoovsAiOperator}
          operatorName={invoiceRecipient.operatorName}
          email={invoiceRecipient.operatorEmail}
          phone={invoiceRecipient.operatorPhone}
          actionButtons={[
            {
              icon: <TrashIcon />,
              eventHandler: handleInvoiceRecipientDeselect,
            },
          ]}
        />
      )}
      {invoiceRecipient?.__typename === "Company" && (
        <ProfileInfoCard
          headerText="Company"
          firstName={invoiceRecipient.name}
          email={invoiceRecipient.companyEmail}
          profilePhoto={invoiceRecipient.companyLogoUrl}
          paymentMethods={invoiceRecipient.paymentMethods}
          actionButtons={[
            {
              icon: <TrashIcon />,
              eventHandler: handleInvoiceRecipientDeselect,
            },
          ]}
          headerColor={purple}
          headerIcon={<CompanyIcon color={purple} size="small" />}
          avatarBackgroundColor={tintPurple}
          avatarColor={purple}
        />
      )}

      {/* Items */}
      <Box mt={4}>
        <Typography variant="h5">Items</Typography>
      </Box>
      {!!invoiceErrors.requestIds && (
        <Typography color="error">{invoiceErrors.requestIds}</Typography>
      )}
      <Box mt={1} mb={2}>
        <Divider />
      </Box>
      {invoiceRecipientRequests?.map((request: Request) => {
        return isMobileView ? (
          <InvoiceItemsListItemMobile
            key={request.id}
            request={request}
            onRequestCheckClick={handleRequestCheckClick}
            onNavigateToRequestClick={handleNavigateToRequestClick}
          />
        ) : (
          <InvoiceItemsListItemDesktop
            key={request.id}
            request={request}
            onRequestCheckClick={handleRequestCheckClick}
            onNavigateToRequestClick={handleNavigateToRequestClick}
          />
        );
      })}

      {/* loading state */}
      {requestsLoading && <CircularProgress />}

      {invoiceRecipient?.id &&
        invoiceRecipientRequests?.length === 0 &&
        !requestsLoading && (
          <Box textAlign="center" my={4}>
            <Typography variant="h5">
              This contact doesn't have any unpaid orders{" "}
            </Typography>
          </Box>
        )}
      <Box
        display="flex"
        flexDirection="column"
        bgcolor={alabaster}
        p={2}
        mt={2}
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
            Amount Due
          </Typography>
          <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
            {currency(amountDue)}
          </Typography>
        </Box>
        <Box my={1}>
          <Divider />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
            Total amount
          </Typography>
          <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
            {currency(totalAmount)}
          </Typography>
        </Box>
      </Box>

      {/* Memo */}
      <Box mt={4} mb={1}>
        <Typography variant="h5">Memo</Typography>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      <Box>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          name="memo"
          label="Leave message to customer"
          onChange={handleInput}
          inputProps={{ style: { minHeight: 132 }, maxLength: 500 }}
          error={!!invoiceErrors.memo}
          helperText={invoiceErrors.memo}
        />
      </Box>

      {/* Due Date */}
      <Box mt={4} mb={1}>
        <Typography variant="h5">Due Date</Typography>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      <Box mb={4}>
        <DatePicker
          value={invoice.dueDate}
          onChange={() => {}}
          onAccept={handleDueDateChange}
          label="Due Date"
          inputFormat="MM/DD/YYYY"
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              required
              variant="outlined"
              name="dueDate"
              error={!!invoiceErrors.dueDate}
              helperText={invoiceErrors.dueDate}
            />
          )}
          components={{
            OpenPickerIcon: CalendarIcon,
          }}
        />
      </Box>
    </CreateDrawer>
  );
}

export default CreateInvoiceDrawer;
