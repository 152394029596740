import React from "react";

type GriddnetOperatorIconProps = {
  size?: "extra-large" | "small";
};

const GriddnetOperatorIcon = (props: GriddnetOperatorIconProps) => {
  const { size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5771 5.85659C14.6067 4.72244 14.1028 3.92098 13.2284 3.36147C11.8946 2.51463 10.5163 1.75854 9.13806 0.987315C8.42669 0.594143 7.64122 0.548777 6.92985 0.926827C5.43302 1.72829 3.951 2.57512 2.52827 3.49756C1.77244 3.99659 1.31301 4.76781 1.35747 5.78098C1.38711 6.56733 1.35747 7.36879 1.35747 8.17025C1.35747 8.86587 1.40193 9.5766 1.34265 10.2722C1.25373 11.5425 1.8169 12.4195 2.83949 13.0547C3.23963 13.2966 3.6546 13.5234 4.05474 13.7503C4.95877 14.2644 5.83316 14.8088 6.75201 15.3078C7.4782 15.701 8.29331 15.7615 9.0195 15.3683C10.4867 14.5669 11.9391 13.72 13.347 12.8278C14.1473 12.3137 14.5919 11.5425 14.5622 10.5142C14.5474 8.9566 14.5326 7.39903 14.5771 5.85659ZM12.1466 11.9205C11.1684 12.5405 10.1458 13.1 9.15288 13.6747C8.33777 14.1434 7.52266 14.1283 6.70755 13.6444C5.7887 13.0849 4.84021 12.5405 3.90654 11.9961C3.19517 11.5727 2.78021 10.9981 2.79503 10.1361C2.80985 8.79026 2.80985 7.4444 2.79503 6.08342C2.79503 5.32732 3.18035 4.78293 3.74352 4.42C4.75129 3.76976 5.81834 3.16488 6.87057 2.59024C7.61158 2.18195 8.38223 2.19707 9.12324 2.62049C10.131 3.19512 11.1388 3.76976 12.1169 4.38976C12.7394 4.78293 13.1543 5.35757 13.1247 6.17415C13.1099 6.85464 13.1247 7.52001 13.1247 8.2005C13.1247 8.88099 13.1099 9.54636 13.1247 10.2268C13.1395 10.9829 12.7394 11.5425 12.1466 11.9205Z"
          fill="#080808"
        />
        <path
          d="M3.65454 5.91715C3.86202 5.50886 4.12878 5.35764 4.38072 5.20642C5.26993 4.69227 6.15914 4.163 7.04835 3.63373C7.56706 3.31617 8.08576 3.22544 8.64893 3.55812C9.7308 4.19324 10.8127 4.82837 11.8945 5.46349C11.9835 5.50886 12.0576 5.58447 12.0724 5.73569C11.5685 6.17422 10.9312 6.4313 10.3236 6.80935C9.53814 6.35569 8.75267 5.90203 7.93756 5.43325C7.10763 5.88691 6.29252 6.32544 5.5367 6.86983C5.5367 7.74691 5.5367 8.59374 5.5367 9.51618C6.23324 10.0757 7.07799 10.4689 7.90792 10.9679C8.72303 10.5294 9.53814 10.1059 10.2495 9.42545C9.5085 9.42545 8.76749 9.42545 7.9672 9.42545C7.8931 8.9113 7.92274 8.45764 7.9672 7.95862C9.38994 7.95862 10.7682 7.95862 12.2354 7.95862C12.265 8.76008 12.2947 9.57667 12.2206 10.3933C12.1909 10.7108 11.939 10.9074 11.6871 11.0737C10.6645 11.6786 9.6567 12.2835 8.63411 12.8581C8.17468 13.1152 7.68562 13.1152 7.22619 12.843C6.2036 12.2533 5.19583 11.6333 4.17324 11.0435C3.83238 10.8469 3.65454 10.5898 3.65454 10.2118C3.63972 8.74496 3.65454 7.29325 3.65454 5.91715Z"
          fill="#02CA17"
        />
      </svg>
    );
  }

  if (size === "extra-large") {
    return (
      <svg
        width="31"
        height="32"
        viewBox="0 0 31 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.5669 11.8973C29.6277 9.62897 28.5949 8.02604 26.8028 6.90701C24.069 5.21335 21.2441 3.70115 18.4192 2.15871C16.9612 1.37237 15.3513 1.28164 13.8933 2.03774C10.8254 3.64067 7.7879 5.33433 4.87188 7.17921C3.32274 8.17726 2.38111 9.7197 2.47224 11.746C2.53299 13.3187 2.47224 14.9217 2.47224 16.5246C2.47224 17.9158 2.56336 19.3373 2.44186 20.7285C2.25961 23.269 3.41387 25.0231 5.50976 26.2934C6.32989 26.7773 7.18039 27.231 8.00052 27.6846C9.85341 28.7129 11.6455 29.8017 13.5288 30.7997C15.0172 31.5861 16.6878 31.7071 18.1762 30.9207C21.1834 29.3178 24.1601 27.6241 27.0458 25.8397C28.686 24.8114 29.5973 23.269 29.5365 21.2124C29.5062 18.0973 29.4758 14.9822 29.5669 11.8973ZM24.5854 24.0251C22.5806 25.2651 20.4847 26.3841 18.4496 27.5334C16.779 28.471 15.1083 28.4407 13.4377 27.4729C11.5544 26.3539 9.61041 25.2651 7.69677 24.1763C6.23876 23.3295 5.38826 22.1802 5.41863 20.4563C5.44901 17.7646 5.44901 15.0729 5.41863 12.3509C5.41863 10.8387 6.20839 9.74995 7.36265 9.02409C9.42816 7.7236 11.6152 6.51384 13.7718 5.36457C15.2906 4.54798 16.8701 4.57823 18.3888 5.42506C20.4544 6.57433 22.5199 7.7236 24.5246 8.9636C25.8004 9.74995 26.6509 10.8992 26.5902 12.5324C26.5598 13.8934 26.5902 15.2241 26.5902 16.5851C26.5902 17.9461 26.5598 19.2768 26.5902 20.6378C26.6205 22.15 25.8004 23.269 24.5854 24.0251Z"
          fill="#080808"
        />
        <path
          d="M7.18074 12.0184C7.60599 11.2018 8.15274 10.8994 8.66912 10.5969C10.4916 9.56862 12.3141 8.51008 14.1367 7.45154C15.1998 6.81642 16.2629 6.63496 17.4172 7.30032C19.6346 8.57057 21.852 9.84082 24.0693 11.1111C24.2516 11.2018 24.4035 11.353 24.4339 11.6555C23.4011 12.5325 22.095 13.0467 20.8496 13.8028C19.2397 12.8955 17.6298 11.9881 15.9592 11.0506C14.2582 11.9579 12.5875 12.835 11.0384 13.9238C11.0384 15.6779 11.0384 17.3716 11.0384 19.2164C12.466 20.3355 14.1974 21.1218 15.8984 22.1199C17.5691 21.2428 19.2397 20.396 20.6977 19.035C19.1789 19.035 17.6602 19.035 16.0199 19.035C15.868 18.0067 15.9288 17.0994 16.0199 16.1013C18.9359 16.1013 21.7608 16.1013 24.768 16.1013C24.8287 17.7042 24.8895 19.3374 24.7376 20.9706C24.6769 21.6057 24.1605 21.9989 23.6441 22.3316C21.5482 23.5413 19.4827 24.7511 17.3868 25.9004C16.4452 26.4145 15.4428 26.4145 14.5012 25.8701C12.4053 24.6906 10.3398 23.4506 8.24387 22.2711C7.54524 21.8779 7.18074 21.3638 7.18074 20.6077C7.15036 17.674 7.18074 14.7706 7.18074 12.0184Z"
          fill="#02CA17"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8653 8.78513C21.9098 7.08391 21.154 5.88171 19.8424 5.04244C17.8417 3.7722 15.7743 2.63805 13.7068 1.48122C12.6398 0.891459 11.4616 0.82341 10.3945 1.39048C8.14928 2.59268 5.92626 3.86293 3.79215 5.24659C2.65841 5.99513 1.96927 7.15196 2.03596 8.67172C2.08043 9.85123 2.03596 11.0534 2.03596 12.2556C2.03596 13.299 2.10266 14.3651 2.01373 15.4086C1.88035 17.3139 2.7251 18.6295 4.25899 19.5822C4.85921 19.9452 5.48165 20.2854 6.08187 20.6256C7.43791 21.3969 8.7495 22.2134 10.1278 22.962C11.2171 23.5517 12.4397 23.6425 13.529 23.0527C15.7298 21.8505 17.9084 20.5803 20.0202 19.242C21.2207 18.4708 21.8876 17.3139 21.8431 15.7715C21.8209 13.4351 21.7987 11.0988 21.8653 8.78513ZM18.2196 17.881C16.7524 18.811 15.2185 19.6503 13.7291 20.5122C12.5064 21.2154 11.2837 21.1927 10.0611 20.4669C8.68281 19.6276 7.26007 18.811 5.85957 17.9944C4.79251 17.3593 4.17007 16.4973 4.1923 15.2044C4.21453 13.1856 4.21453 11.1668 4.1923 9.12538C4.1923 7.99123 4.77028 7.17464 5.61503 6.63025C7.12669 5.65488 8.72727 4.74756 10.3056 3.88561C11.4171 3.27317 12.5731 3.29585 13.6846 3.93098C15.1963 4.79293 16.7079 5.65488 18.1751 6.58488C19.1088 7.17464 19.7312 8.03659 19.6868 9.26148C19.6645 10.2822 19.6868 11.2803 19.6868 12.301C19.6868 13.3217 19.6645 14.3198 19.6868 15.3405C19.709 16.4747 19.1088 17.3139 18.2196 17.881Z"
        fill="#080808"
      />
      <path
        d="M5.48254 8.87597C5.79376 8.26353 6.1939 8.0367 6.57182 7.80987C7.90563 7.03865 9.23945 6.24474 10.5733 5.45084C11.3513 4.9745 12.1294 4.8384 12.9741 5.33743C14.5969 6.29011 16.2197 7.24279 17.8425 8.19548C17.9759 8.26353 18.0871 8.37694 18.1093 8.60377C17.3535 9.26158 16.3976 9.64719 15.4861 10.2143C14.3079 9.53377 13.1297 8.85329 11.9071 8.15011C10.6622 8.8306 9.43952 9.48841 8.30578 10.305C8.30578 11.6206 8.30578 12.8909 8.30578 14.2745C9.3506 15.1138 10.6177 15.7035 11.8626 16.4521C13.0853 15.7943 14.3079 15.1592 15.375 14.1384C14.2635 14.1384 13.152 14.1384 11.9515 14.1384C11.8404 13.3672 11.8848 12.6867 11.9515 11.9382C14.0856 11.9382 16.1531 11.9382 18.3538 11.9382C18.3983 13.1404 18.4428 14.3652 18.3316 15.5901C18.2872 16.0665 17.9092 16.3614 17.5313 16.6109C15.9974 17.5182 14.4858 18.4255 12.9519 19.2875C12.2628 19.6731 11.5292 19.6731 10.84 19.2648C9.30614 18.3801 7.79448 17.4501 6.26059 16.5655C5.7493 16.2706 5.48254 15.885 5.48254 15.3179C5.46031 13.1177 5.48254 10.9401 5.48254 8.87597Z"
        fill="#02CA17"
      />
    </svg>
  );
};

export default GriddnetOperatorIcon;
