import React from "react";

import { Box, Typography } from "@mui/material";

import { grayDark } from "design-system/colors";
import ChildSeatChip from "components/ChildSeatChip";
import { ChildSeatVariant } from "types";

const { RearFacing, ForwardFacing, Booster } = ChildSeatVariant;

type ChildSeatsNoteBlockProps = {
  boosterSeatQuantity?: number;
  forwardFacingSeatQuantity?: number;
  rearFacingSeatQuantity?: number;
};

function ChildSeatsNoteBlock(props: ChildSeatsNoteBlockProps) {
  const {
    rearFacingSeatQuantity,
    boosterSeatQuantity,
    forwardFacingSeatQuantity,
  } = props;

  return (
    <>
      <Typography variant="h5" color={grayDark}>
        Child Seats
      </Typography>

      <Box display="flex" flexWrap="wrap" columnGap={2} mt={1}>
        <ChildSeatChip variant={RearFacing} quantity={rearFacingSeatQuantity} />
        <ChildSeatChip
          variant={ForwardFacing}
          quantity={forwardFacingSeatQuantity}
        />
        <ChildSeatChip variant={Booster} quantity={boosterSeatQuantity} />
      </Box>
    </>
  );
}

export default ChildSeatsNoteBlock;
