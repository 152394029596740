import React from "react";
import { useHistory } from "react-router-dom";

import { Box, Typography, Hidden } from "@mui/material";

import { CancellationPolicy } from "../../../types";
import CancellationPoliciesListItem from "./CancellationPoliciesListItem.desktop";
import CancellationPoliciesMobileListItem from "./CancellationPoliciesListItem.mobile";

type CancellationPoliciesListProps = {
  cancellationPolicies: CancellationPolicy[];
  listStyles: any;
};

function CancellationPoliciesList(props: CancellationPoliciesListProps) {
  const { cancellationPolicies, listStyles } = props;
  const history = useHistory();

  const handleListItemClick = (cancellationPolicyId: string) => {
    history.push(`/settings/cancellation/update/${cancellationPolicyId}`);
  };

  return (
    <>
      {/* Large screen view */}
      <Hidden mdDown>
        <Box sx={listStyles}>
          <Box display="flex" flexDirection="row" flex="1" px={3} mb={1}>
            <Box display="flex" flex="2">
              <Typography variant="overline">POLICY</Typography>
            </Box>
            <Box display="flex" flex="1">
              <Typography variant="overline">FULL REFUND</Typography>
            </Box>
            <Box display="flex" flex="1">
              <Typography variant="overline">50% REFUND</Typography>
            </Box>
            <Box display="flex" flex="1">
              <Typography variant="overline">25% REFUND</Typography>
            </Box>
          </Box>
          {[...cancellationPolicies]
            .sort((a, b) => a.variant.localeCompare(b.variant))
            .map((cancellationPolicy: CancellationPolicy) => (
              <Box mb={1} key={cancellationPolicy.id}>
                <CancellationPoliciesListItem
                  cancellationPolicy={cancellationPolicy}
                  onClick={() => handleListItemClick(cancellationPolicy.id)}
                />
              </Box>
            ))}
        </Box>
      </Hidden>

      {/* Small screen view */}
      <Hidden mdUp>
        <Box px={2}>
          {[...cancellationPolicies]
            .sort((a, b) => a.variant.localeCompare(b.variant))
            .map((cancellationPolicy: CancellationPolicy) => (
              <Box mb={1} key={cancellationPolicy.id}>
                <CancellationPoliciesMobileListItem
                  cancellationPolicy={cancellationPolicy}
                  onClick={() => handleListItemClick(cancellationPolicy.id)}
                />
              </Box>
            ))}
        </Box>
      </Hidden>
    </>
  );
}

export default CancellationPoliciesList;
