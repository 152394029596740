import React from "react";

import { Box, Typography, CircularProgress, IconButton } from "@mui/material";

import { blueLabelText } from "design-system/colors";
import { TrashIcon } from "design-system/icons";
import { useRequestCompanyLinking } from "globals/hooks";
import { RequestStage } from "types";

type RemoveLinkedCompanyProps = {
  requestId: string;
  companyName: string;
  stage: RequestStage;
  isShuttleReservation?: boolean;
};

function RemoveLinkedCompany(props: RemoveLinkedCompanyProps) {
  const { requestId, companyName, stage, isShuttleReservation } = props;

  // hooks
  const { linkCompanyLoading, onUnlinkCompanyToRequest } =
    useRequestCompanyLinking({ requestId, stage });

  if (linkCompanyLoading) {
    return (
      <Box ml={1}>
        <CircularProgress size={24} />
      </Box>
    );
  }

  return (
    <Typography variant="subtitle2" display="flex" mb={2}>
      <Box
        sx={{
          my: "auto",
          wordWrap: "break-word",
        }}
      >
        {companyName}
      </Box>

      {!isShuttleReservation && (
        <IconButton
          disabled={linkCompanyLoading}
          onClick={onUnlinkCompanyToRequest}
          sx={{ "&.MuiButtonBase-root": { padding: 0 }, ml: 1 }}
        >
          <TrashIcon color={blueLabelText} />
        </IconButton>
      )}
    </Typography>
  );
}

export default RemoveLinkedCompany;
