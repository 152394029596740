import React from "react";

type AddressIconProps = { color?: string; size?: "small" };

const AddressIcon = (props: AddressIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 5.91145C13 8.27623 9.19852 13.5711 8.22518 14.8874C8.19942 14.9223 8.16562 14.9507 8.12655 14.9702C8.08747 14.9898 8.04423 15 8.00037 15C7.95651 15 7.91327 14.9898 7.8742 14.9702C7.83512 14.9507 7.80132 14.9223 7.77556 14.8874C6.80222 13.5711 3 8.27623 3 5.91145C3 4.60885 3.52678 3.35961 4.46447 2.43853C5.40215 1.51746 6.67392 1 8 1C9.32608 1 10.5979 1.51746 11.5355 2.43853C12.4732 3.35961 13 4.60885 13 5.91145Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="8"
          cy="6"
          r="1"
          fill="#B3B3B3"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 8.86718C19.5 12.4143 13.7978 20.3567 12.3378 22.3311C12.2991 22.3834 12.2484 22.426 12.1898 22.4553C12.1312 22.4847 12.0663 22.5 12.0006 22.5C11.9348 22.5 11.8699 22.4847 11.8113 22.4553C11.7527 22.426 11.702 22.3834 11.6633 22.3311C10.2033 20.3567 4.5 12.4143 4.5 8.86718C4.5 6.91328 5.29018 5.03941 6.6967 3.6578C8.10322 2.27618 10.0109 1.5 12 1.5C13.9891 1.5 15.8968 2.27618 17.3033 3.6578C18.7098 5.03941 19.5 6.91328 19.5 8.86718Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12"
        cy="9"
        r="1.5"
        fill={color || "#B3B3B3"}
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddressIcon;
