import { gql } from "@apollo/client";

export const LOAD_WEBSITE_TEMPLATE_QUERY = gql`
  query WebsiteTemplate($templateId: String) {
    websiteTemplate(templateId: $templateId)
  }
`;

export const CREATE_DUDA_WEBSITE_MUTATION = gql`
  mutation CreateWebsite($input: CreateWebsiteInput!) {
    createWebsite(input: $input) {
      operator {
        id
        dudaWebsite {
          id
          createdAt
          desktopThumbnailUrl
          publishStatus
          siteDomain
          siteName
          templateId
          websitePlan
        }
      }
    }
  }
`;

export const CREATE_OPERATOR_DUDA_WEBSITE_USER = gql`
  mutation CreateOperatorDudaWebsiteUser(
    $input: CreateOperatorDudaWebsiteUserInput!
  ) {
    createOperatorDudaWebsiteUser(input: $input) {
      operator {
        id
        dudaWebsite {
          id
          createdAt
          desktopThumbnailUrl
          publishStatus
          siteDomain
          siteName
          templateId
          websitePlan
        }
      }
    }
  }
`;

export const PUBLISH_DUDA_WEBSITE_MUTATION = gql`
  mutation PublishDudaWebsite($input: PublishDudaWebsiteInput!) {
    publishDudaWebsite(input: $input) {
      operator {
        id
        dudaWebsite {
          id
          createdAt
          desktopThumbnailUrl
          publishStatus
          siteDomain
          siteName
          templateId
          websitePlan
        }
      }
    }
  }
`;

export const ADD_VEHICLE_TO_DUDA_WEBSITE = gql`
  mutation AddVehicleToDudaWebsite($input: AddVehicleToDudaWebsiteInput!) {
    addVehicleToDudaWebsite(input: $input) {
      vehicle {
        id
        publishedToDudaSite
      }
    }
  }
`;

export const GENERATE_DUDA_SSO_LINK_MUTATION = gql`
  mutation GenerateSSOLinkToDudaWebsiteEditor(
    $input: GenerateSSOLinkToDudaWebsiteEditorInput!
  ) {
    generateSSOLinkToDudaWebsiteEditor(input: $input) {
      ssoLink
    }
  }
`;

export const SET_BASIC_DUDA_PERMISSIONS_MUTATION = gql`
  mutation SetBasicOperatorDudaWebsiteUserPermissions(
    $input: SetBasicOperatorDudaWebsiteUserPermissionsInput!
  ) {
    setBasicOperatorDudaWebsiteUserPermissions(input: $input) {
      operator {
        id
        dudaWebsite {
          id
          websitePermissions
        }
      }
    }
  }
`;

export const SET_PREMIUM_DUDA_PERMISSIONS_MUTATION = gql`
  mutation SetPremiumOperatorDudaWebsiteUserPermissions(
    $input: SetPremiumOperatorDudaWebsiteUserPermissionsInput!
  ) {
    setPremiumOperatorDudaWebsiteUserPermissions(input: $input) {
      operator {
        id
        dudaWebsite {
          id
          websitePermissions
        }
      }
    }
  }
`;
