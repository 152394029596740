import { useQuery } from "@apollo/client";
import { LOAD_DISPATCH_TRIPS_QUERY } from "globals/graphql";

function useDispatchTrips() {
  // hooks
  const { data, loading, error } = useQuery(LOAD_DISPATCH_TRIPS_QUERY, {
    fetchPolicy: "cache-and-network",
    pollInterval: 10 * 1000,
  });

  return {
    loading,
    error,
    trips: data?.loadDispatchTrips || [],
  };
}

export { useDispatchTrips };
