import React from "react";

import { Paper } from "@mui/material";
import { Box } from "@mui/system";

import { SalesAutomationText } from "types";
import LogItemTextBlock from "./LogItemTextBlock";

type LogItemBlockProps = {
  text: SalesAutomationText;
};

const LogItemBlock = (props: LogItemBlockProps) => {
  const { text } = props;

  return (
    <Paper variant="outlined">
      <Box display="flex" flexDirection="row" alignItems="center" m={2}>
        <LogItemTextBlock text={text} />
      </Box>
    </Paper>
  );
};

export default LogItemBlock;
