import React from "react";

type WarningTriangleIconProps = {
  color?: string;
  size?: string;
};

const WarningTriangleIcon = (props: WarningTriangleIconProps) => {
  const { color, size } = props;
  if (size === "large") {
    return (
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 6.72341V10.0378M10 13.3522H10.008M8.63613 2.46439L1.88046 14.1808C1.74117 14.4314 1.66747 14.7155 1.66669 15.0049C1.66591 15.2943 1.73808 15.5788 1.87601 15.8302C2.01395 16.0816 2.21284 16.2911 2.4529 16.4378C2.69297 16.5846 2.96583 16.6635 3.24435 16.6667H16.7557C17.0342 16.6635 17.3071 16.5846 17.5471 16.4378C17.7872 16.2911 17.9861 16.0816 18.124 15.8302C18.262 15.5788 18.3341 15.2943 18.3333 15.0049C18.3326 14.7155 18.2589 14.4314 18.1196 14.1808L11.3639 2.46439C11.2217 2.22087 11.0215 2.01953 10.7826 1.8798C10.5437 1.74006 10.2742 1.66666 10 1.66666C9.72586 1.66666 9.45632 1.74006 9.21742 1.8798C8.97852 2.01953 8.77831 2.22087 8.63613 2.46439Z"
          stroke={color || "#D63F49"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#warning-triangle-icon-clip-path)">
        <path
          d="M1 15.0005H15L8 1.00049L1 15.0005Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 6.50049L8 9.70049M8 12.5005L7.993 12.5005"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="warning-triangle-icon-clip-path">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.000488281)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WarningTriangleIcon;
