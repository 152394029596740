import React from "react";

type ExternalLinkIconProps = {
  color?: string;
};

const ExternalLinkIcon = (props: ExternalLinkIconProps) => {
  const { color } = props;

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.3 1H2.2C1.53726 1 1 1.53726 1 2.2V11.8C1 12.4627 1.53726 13 2.2 13H11.8C12.4627 13 13 12.4627 13 11.8V6.7M6.7 7.3L13 1M13 1H9.8M13 1V4.2"
        stroke={color || "#195FE9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExternalLinkIcon;
