import React from "react";

import MoovsDateTimePicker from "components/MoovsDateTimePicker";
import { alabaster, black, grayMedium, white } from "../../../colors";

const inputSx = {
  fontSize: "14px",
  backgroundColor: white,
  "& path": {
    stroke: "transparent",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid transparent",
  },
  ":hover": {
    "& path": {
      stroke: grayMedium,
      transition: "all 0.3s",
      transitionDelay: "0s",
    },
  },
  ":hover:not(.Mui-error)": {
    ".MuiOutlinedInput-notchedOutline": {
      transition: "all 0.3s",
      transitionDelay: "0s",
      borderColor: grayMedium,
    },
  },
  "&.Mui-focused:not(.Mui-error)": {
    ".MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: black,
    },
  },
  "&.Mui-focused": {
    backgroundColor: alabaster + "!important",
    "& path": {
      stroke: black + "!important",
    },
  },
};

function InlineDateTimePicker({
  minDate,
  initialFocusedDate,
  clearable,
  value,
  onChange,
  errorText,
  placeholder,
  required,
  name,
  shouldDisableDate,
  error,
}: {
  minDate?: any;
  initialFocusedDate?: any;
  clearable?: boolean;
  value: any;
  onChange: any;
  errorText?: any;
  placeholder?: any;
  required?: boolean;
  name?: string;
  shouldDisableDate?: boolean;
  error?: boolean;
}) {
  error = error || (required && !value);

  return (
    <MoovsDateTimePicker
      clearable={clearable}
      disablePast={shouldDisableDate}
      minDate={minDate}
      value={value || initialFocusedDate}
      onAccept={onChange}
      renderInputProps={{
        multiline: true,
        placeholder: placeholder,
        name,
        error,
        helperText: error ? errorText : "",
        sx: inputSx,
      }}
    />
  );
}

export { InlineDateTimePicker };
