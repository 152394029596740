import React from "react";

import { Box, Typography, Divider } from "@mui/material";

import ProfileInfoCard from "components/globals/ProfileInfoCard";
import { formatPhoneNumber } from "globals/utils/phoneNumberFormatter/phoneNumberFormatter";
import { FarmAffiliateVariantEnum, Invoice } from "types";
import { purple, tintPurple } from "design-system/colors";
import { CompanyIcon } from "design-system/icons";

const { GriddnetOperator, MoovsAiOperator, MoovsNetworkOperator } =
  FarmAffiliateVariantEnum;

type BillingContactProps = {
  invoice: Invoice;
};

function BillingContact(props: BillingContactProps) {
  const { invoice } = props;

  return (
    <>
      <Box mb={3}>
        <Typography variant="h5">Billing Contact</Typography>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      <Box display="flex">
        {invoice.contact && (
          <ProfileInfoCard
            headerText="Booking / Billing Contact"
            firstName={invoice.contact.firstName}
            lastName={invoice.contact.lastName || ""}
            email={invoice.contact.email}
            phone={invoice.contact.mobilePhone}
            preferences={invoice.contact.preferences}
            company={invoice.contact?.company?.name}
            paymentMethods={invoice.contact?.paymentMethods}
          />
        )}
        {invoice.farmAffiliate && (
          <ProfileInfoCard
            headerText="Billing Contact"
            operatorName={invoice.farmAffiliate.operatorName}
            email={invoice.farmAffiliate.operatorEmail}
            phone={invoice.farmAffiliate.operatorPhone}
            farmAffiliateVariant={
              invoice.farmAffiliate.__typename === GriddnetOperator
                ? GriddnetOperator
                : invoice.farmAffiliate.__typename === MoovsAiOperator
                ? MoovsAiOperator
                : MoovsNetworkOperator
            }
          />
        )}
        {invoice.company && (
          <ProfileInfoCard
            headerText="Company"
            firstName={invoice.company.name}
            email={invoice.company.email}
            profilePhoto={invoice.company.companyLogoUrl}
            phone={formatPhoneNumber(invoice.company.phoneNumber)?.formatted}
            headerColor={purple}
            headerIcon={<CompanyIcon color={purple} size="small" />}
            avatarBackgroundColor={tintPurple}
            avatarColor={purple}
          />
        )}
      </Box>
    </>
  );
}

export default BillingContact;
