import React from "react";

import { Box, Divider, Typography } from "@mui/material";

import { white } from "design-system/colors";

function RequestTransactionDesktopHeader() {
  return (
    <Box position="sticky" zIndex={10} top="-8px">
      <Box
        display="flex"
        flex={1}
        justifyContent="flex-start"
        bgcolor={white}
        py={2}
      >
        <Box flex={0.2} />
        <Box flex={0.4}>
          <Typography variant="overline">Amount</Typography>
        </Box>
        <Box flex={0.6}>
          <Typography variant="overline">Date</Typography>
        </Box>
        <Box flex={0.6}>
          <Typography variant="overline">Status</Typography>
        </Box>
        <Box flex={0.5}>
          <Typography variant="overline">Risk Evaluation</Typography>
        </Box>
        <Box flex={0.5}>
          <Typography variant="overline">Method</Typography>
        </Box>
        <Box flex={0.1}></Box>
      </Box>
      <Divider />
    </Box>
  );
}

export default RequestTransactionDesktopHeader;
