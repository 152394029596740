import React from "react";
import { Control, Controller } from "react-hook-form";

import { Box, TextField } from "@mui/material";

import { granite, moovsBlue } from "design-system/colors";
import {
  NumberFormatDollar,
  NumberFormatPercent,
} from "design-system/components/inputs/NumberFormat";
import { DollarIcon, PercentageIcon } from "design-system/icons";
import RHFToggleButtons from "./RHFToggleButtons";

type RHFDollarPctAmtProps = {
  toggleName: string;
  control: Control<any>;
  textFieldName: string;
  errorMessage: string;
  isPercent: boolean;
  customDecimal?: number;
  shouldTextFieldFlexGrow?: boolean;
  shouldContainerBeFullWidth?: boolean;
};

function RHFDollarPctAmt(props: RHFDollarPctAmtProps) {
  const {
    toggleName,
    control,
    textFieldName,
    errorMessage,
    isPercent,
    customDecimal,
    shouldTextFieldFlexGrow,
    shouldContainerBeFullWidth,
  } = props;

  const inputProps = isPercent ? { suffix: "%" } : { prefix: "$" };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        {...(shouldContainerBeFullWidth && { width: "100%" })}
      >
        <RHFToggleButtons
          name={toggleName}
          control={control}
          toggleButtons={[
            {
              ariaLabel: "dollar",
              value: false,
              icon: (
                <DollarIcon
                  size="small"
                  color={!isPercent ? moovsBlue : granite}
                />
              ),
            },
            {
              ariaLabel: "percent",
              value: true,
              icon: (
                <PercentageIcon
                  size="small"
                  color={isPercent ? moovsBlue : granite}
                />
              ),
            },
          ]}
        />
        <Box ml={2} {...(shouldTextFieldFlexGrow && { flexGrow: 1 })}>
          <Controller
            name={textFieldName}
            control={control}
            render={({ field: { value, ...otherFields } }) => {
              const decimal = parseFloat(value).toFixed(customDecimal || 3);
              return (
                <TextField
                  {...otherFields}
                  value={parseFloat(decimal) || parseFloat(value)}
                  label={isPercent ? "Enter percent" : "Enter amount"}
                  InputProps={{
                    inputComponent: isPercent
                      ? NumberFormatPercent
                      : NumberFormatDollar,
                    inputProps: inputProps,
                  }}
                  error={!!errorMessage}
                  helperText={errorMessage}
                  {...(shouldTextFieldFlexGrow && { fullWidth: true })}
                />
              );
            }}
          />
        </Box>
      </Box>
    </>
  );
}

export default RHFDollarPctAmt;
