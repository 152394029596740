import React, { useState } from "react";

import { Company } from "types";
import { AddContactToCompanyDialog, ContactsList } from "./components";

type ContactsBlockProps = {
  company: Company;
};

function ContactsBlock(props: ContactsBlockProps) {
  const { company } = props;

  // state
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  return (
    <>
      <ContactsList
        contacts={company.contacts}
        setAddDialogOpen={setAddDialogOpen}
      />

      <AddContactToCompanyDialog
        open={addDialogOpen}
        company={company}
        handleClose={() => setAddDialogOpen(false)}
      />
    </>
  );
}

export default ContactsBlock;
