import React from "react";

type ScheduledIconProps = {
  color?: string;
};
const ScheduledIcon = (props: ScheduledIconProps) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 4V6.5L4.5 8M3.27695 10.6941C1.90631 9.80243 1 8.25706 1 6.5C1 3.73858 3.23858 1.5 6 1.5C8.23882 1.5 10.134 2.97145 10.7711 5M7 11.2895L15 7.5L11.2105 15.5L10.3684 12.1316L7 11.2895Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ScheduledIcon;
