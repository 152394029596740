/**
 * @file graphql/vehicle.ts
 * queries/mutations for Vehicle.
 *
 */

import { gql } from "@apollo/client";

export const CREATE_VEHICLE_MUTATION = gql`
  mutation CreateVehicle($input: CreateVehicleInput!) {
    createVehicle(input: $input) {
      vehicle {
        id
        capacity
        description
        name
        available
        licensePlate
        exteriorColor
        vinNumber
        weekendHourlyCost
        weekdayHourlyCost
        weekendMinMinutes
        weekdayMinMinutes
        minimumTotalBaseRate
        deadheadRatePerMile
        tripRatePerMile
        totalDeadheadDurationMinutes
        enableBaseRateAutomation
        features {
          id
          name
          category
        }
        photos {
          id
          url
          photoIndex
        }
        vehicleType {
          typeName
          typeSlug
        }
        cancellationPolicy {
          id
          variant
          refund100 {
            periodName
            periodValue
            active
          }
          refund50 {
            periodName
            periodValue
            active
          }
          refund25 {
            periodName
            periodValue
            active
          }
        }
        insurancePolicy {
          id
          insuranceCompany
          policyNumber
        }
        settings {
          id
          weekends
          conflictBlockQuote
          conflictBlockReservation
          pricelessBookingEnabled
          pricelessBookingTarget
          forwardFacingSeat {
            id
            type
            active
            quantity
            amt
            imageUrl
            description
          }
          rearFacingSeat {
            id
            type
            active
            quantity
            amt
            imageUrl
            description
          }
          boosterSeat {
            id
            type
            active
            quantity
            amt
            imageUrl
            description
          }
        }
      }
    }
  }
`;

export const UPDATE_VEHICLE_MUTATION = gql`
  mutation UpdateVehicle($input: UpdateVehicleInput!) {
    updateVehicle(input: $input) {
      vehicle {
        id
        updatedAt
        capacity
        description
        name
        licensePlate
        exteriorColor
        vinNumber
        weekendHourlyCost
        weekdayHourlyCost
        weekendMinMinutes
        weekdayMinMinutes
        available
        minimumTotalBaseRate
        deadheadRatePerMile
        tripRatePerMile
        totalDeadheadDurationMinutes
        enableBaseRateAutomation
        enableBaseRateAutomationBookingTool
        features {
          id
          name
          category
        }
        settings {
          id
          pricelessBookingEnabled
          pricelessBookingTarget
          pricelessBookingCompanies {
            id
            name
          }
          pricelessBookingContacts {
            id
            firstName
            lastName
          }
          weekends
          conflictBlockQuote
          conflictBlockReservation
          forwardFacingSeat {
            id
            type
            active
            quantity
            amt
            imageUrl
            description
          }
          rearFacingSeat {
            id
            type
            active
            quantity
            amt
            imageUrl
            description
          }
          boosterSeat {
            id
            type
            active
            quantity
            amt
            imageUrl
            description
          }
        }
        operator {
          id
          name
        }
        vehicleType {
          typeName
          typeSlug
        }
        cancellationPolicy {
          id
          variant
          refund100 {
            periodName
            periodValue
            active
          }
          refund50 {
            periodName
            periodValue
            active
          }
          refund25 {
            periodName
            periodValue
            active
          }
        }
        insurancePolicy {
          id
          insuranceCompany
          policyNumber
        }
      }
    }
  }
`;

export const REMOVE_VEHICLE_MUTATION = gql`
  mutation RemoveVehicle($input: RemoveVehicleInput!) {
    removeVehicle(input: $input) {
      vehicle {
        id
      }
    }
  }
`;

// deprecated 11/19/24 - use UPDATE_VEHICLE_MUTATION instead
export const CREATE_VEHICLE_FEATURES_MUTATION = gql`
  mutation CreateVehicleFeature($input: CreateVehicleFeatureInput!) {
    createVehicleFeature(input: $input) {
      vehicle {
        id
        features {
          id
          name
          category
        }
      }
    }
  }
`;

// deprecated 11/19/24 - use UPDATE_VEHICLE_MUTATION instead
export const REMOVE_VEHICLE_FEATURES_MUTATION = gql`
  mutation RemoveVehicleFeature($input: RemoveVehicleFeatureInput!) {
    removeVehicleFeature(input: $input) {
      vehicle {
        id
        features {
          id
          name
          category
        }
      }
    }
  }
`;

export const CREATE_VEHICLE_PHOTO_MUTATION = gql`
  mutation CreateVehiclePhoto($input: CreateVehiclePhotoInput!) {
    createVehiclePhoto(input: $input) {
      vehicle {
        id
        photos {
          id
          url
          photoIndex
        }
      }
    }
  }
`;

export const UPDATE_VEHICLE_PHOTO_MUTATION = gql`
  mutation UpdateVehiclePhoto($input: UpdateVehiclePhotoInput!) {
    updateVehiclePhoto(input: $input) {
      vehicle {
        id
        photos {
          id
          url
          photoIndex
        }
      }
    }
  }
`;

export const REMOVE_VEHICLE_PHOTO_MUTATION = gql`
  mutation RemoveVehiclePhoto($input: RemoveVehiclePhotoInput!) {
    removeVehiclePhoto(input: $input) {
      vehicle {
        id
        photos {
          id
          url
          photoIndex
        }
      }
    }
  }
`;

export const UPDATE_VEHICLE_PHOTO_ORDER_MUTATION = gql`
  mutation UpdateVehiclePhotoOrder($input: UpdateVehiclePhotoOrderInput!) {
    updateVehiclePhotoOrder(input: $input) {
      photos {
        id
        url
        photoIndex
      }
    }
  }
`;

export const LOAD_VEHICLES_QUERY = gql`
  query loadVehicles(
    $searchTerm: String
    $stops: [StopInformationInputForAutomatedPricing]
    $farmAffiliateId: ID
    $includeExternalOperators: Boolean
    $shouldCalculateBRA: Boolean
  ) {
    loadVehicles(
      searchTerm: $searchTerm
      stops: $stops
      farmAffiliateId: $farmAffiliateId
      includeExternalOperators: $includeExternalOperators
      shouldCalculateBRA: $shouldCalculateBRA
    ) {
      edges {
        node {
          id
          updatedAt
          capacity
          description
          name
          available
          licensePlate
          exteriorColor
          vinNumber
          weekendHourlyCost
          weekdayHourlyCost
          weekendMinMinutes
          weekdayMinMinutes
          minimumTotalBaseRate
          deadheadRatePerMile
          tripRatePerMile
          totalDeadheadDurationMinutes
          enableBaseRateAutomation
          baseRateAutomation {
            total
            lineItems {
              name
              description
              lineTotal
              source
            }
          }
          publishedToDudaSite
          operator {
            id
            name
          }
          features {
            id
            name
            category
          }
          photos {
            id
            url
            photoIndex
          }
          vehicleType {
            typeName
            typeSlug
            iconCategory
          }
          vehicleType {
            typeName
            typeSlug
            iconCategory
          }
          cancellationPolicy {
            id
            variant
            refund100 {
              periodName
              periodValue
              active
            }
            refund50 {
              periodName
              periodValue
              active
            }
            refund25 {
              periodName
              periodValue
              active
            }
          }
          insurancePolicy {
            id
            insuranceCompany
            policyNumber
          }
          externalOperator {
            id
          }
          settings {
            id
            forwardFacingSeat {
              id
              type
              active
              quantity
              amt
              imageUrl
              description
            }
            rearFacingSeat {
              id
              type
              active
              quantity
              amt
              imageUrl
              description
            }
            boosterSeat {
              id
              type
              active
              quantity
              amt
              imageUrl
              description
            }
          }
        }
      }
    }
  }
`;

export const LOAD_VEHICLE_QUERY = gql`
  query Vehicles($id: ID!) {
    node(id: $id) {
      id
      ... on Vehicle {
        id
        updatedAt
        capacity
        description
        name
        available
        createdAt
        updatedAt
        licensePlate
        exteriorColor
        vinNumber
        weekendHourlyCost
        weekdayHourlyCost
        weekendMinMinutes
        weekdayMinMinutes
        minimumTotalBaseRate
        deadheadRatePerMile
        tripRatePerMile
        totalDeadheadDurationMinutes
        enableBaseRateAutomation
        enableBaseRateAutomationBookingTool
        publishedToDudaSite
        comments {
          id
          createdAt
          updatedAt
          bodyText
          user {
            id
            firstName
            lastName
          }
        }
        operator {
          id
          name
        }
        features {
          id
          name
          category
        }
        photos {
          id
          url
          photoIndex
        }
        vehicleType {
          typeName
          typeSlug
          iconCategory
        }
        cancellationPolicy {
          id
          variant
          refund100 {
            periodName
            periodValue
            active
          }
          refund50 {
            periodName
            periodValue
            active
          }
          refund25 {
            periodName
            periodValue
            active
          }
        }
        insurancePolicy {
          id
          insuranceCompany
          policyNumber
        }
        externalOperator {
          id
          operatorName
        }
        settings {
          id
          pricelessBookingEnabled
          pricelessBookingTarget
          pricelessBookingCompanies {
            id
            name
          }
          pricelessBookingContacts {
            id
            firstName
            lastName
          }
          weekends
          conflictBlockQuote
          conflictBlockReservation
          pricelessBookingEnabled
          pricelessBookingTarget
          forwardFacingSeat {
            id
            type
            active
            quantity
            amt
            imageUrl
            description
          }
          rearFacingSeat {
            id
            type
            active
            quantity
            amt
            imageUrl
            description
          }
          boosterSeat {
            id
            type
            active
            quantity
            amt
            imageUrl
            description
          }
        }
      }
    }
  }
`;

export const LOAD_VEHICLES_NODES_QUERY = gql`
  query Vehicles($ids: [ID!]!) {
    nodes(ids: $ids) {
      id
      ... on Vehicle {
        id
        updatedAt
        capacity
        description
        name
        available
        createdAt
        updatedAt
        licensePlate
        exteriorColor
        vinNumber
        weekendHourlyCost
        weekdayHourlyCost
        weekendMinMinutes
        weekdayMinMinutes
        minimumTotalBaseRate
        deadheadRatePerMile
        tripRatePerMile
        totalDeadheadDurationMinutes
        enableBaseRateAutomation
        publishedToDudaSite
        operator {
          id
          name
        }
        features {
          id
          name
          category
        }
        photos {
          id
          url
          photoIndex
        }
        vehicleType {
          typeName
          typeSlug
        }
        vehicleType {
          typeName
          typeSlug
        }
        cancellationPolicy {
          id
          variant
          refund100 {
            periodName
            periodValue
            active
          }
          refund50 {
            periodName
            periodValue
            active
          }
          refund25 {
            periodName
            periodValue
            active
          }
        }
        insurancePolicy {
          id
          insuranceCompany
          policyNumber
        }
      }
    }
  }
`;

export const LOAD_VEHICLE_FEATURES_QUERY = gql`
  query Features {
    vehicleFeatures {
      id
      name
      category
    }
  }
`;

export const LOAD_VEHICLE_TYPES_QUERY = gql`
  query VehicleTypes {
    vehicleTypes {
      typeName
      typeSlug
      iconCategory
    }
  }
`;

export const LOAD_VEHICLE_AVAILABILITY_CALENDAR = gql`
  query loadVehicleAvailabilityCalendar(
    $vehicleId: ID!
    $routeId: ID
    $stops: [TripStopInput]!
  ) {
    loadVehicleAvailabilityCalendar(
      vehicleId: $vehicleId
      stops: $stops
      routeId: $routeId
    ) {
      previousTrip {
        confirmationNumber
        isQuote
        isOverlap
        minutesToCurrentTrip
        pickUpLocation
        pickUpDateTime
        dropOffLocation
        dropOffDateTime
        estimatedDuration
        requestId
        tripId
      }
      currentTrip {
        isOverlap
        pickUpLocation
        pickUpDateTime
        dropOffLocation
        dropOffDateTime
        estimatedDuration
      }
      nextTrip {
        confirmationNumber
        isQuote
        isOverlap
        minutesToCurrentTrip
        pickUpLocation
        pickUpDateTime
        dropOffLocation
        dropOffDateTime
        estimatedDuration
        requestId
        tripId
      }
    }
  }
`;

export const LOAD_VEHICLES_NAME_AND_ID_QUERY = gql`
  query loadVehicles {
    loadVehicles {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const LOAD_TRACKED_VEHICLES_QUERY = gql`
  query loadTrackedVehicles {
    loadTrackedVehicles {
      id
      name
      deviceId
    }
  }
`;

// deprecated 11/19/24 - use UPDATE_VEHICLE_MUTATION instead
export const UPDATE_VEHICLE_CHILD_SEAT_MUTATION = gql`
  mutation UpdateVehicleChildSeat($input: UpdateVehicleChildSeatInput!) {
    updateVehicleChildSeat(input: $input) {
      vehicleChildSeat {
        id
        type
        active
        quantity
        amt
        imageUrl
        description
      }
    }
  }
`;
