/**
 * @file ImageUploadBlock.tsx
 *
 * Image upload block to be used in create/update drawers.
 * Currently used in Drivers and Companies.
 */
import React, { useRef, useState } from "react";
import { CropperProps } from "react-easy-crop";
import startCase from "lodash/startCase";

import { Box, Typography, IconButton, Button, Tooltip } from "@mui/material";

import { moovsBlue, white, granite } from "design-system/colors";
import MoovsAvatar from "design-system/components/MoovsAvatar";
import { ViewIcon, ImagePhotoIcon } from "design-system/icons";
import photoCamera from "design-system/illustrations/photoCamera.svg";
import { SelectOrUploadPhotoDialog, ViewPhotoDialog } from "./components";

type ImageUploadBlockProps = {
  mode: "create" | "update";
  variant: "driver" | "company";
  placeholder: string | [string, string];
  onPhotoUrlUpdate: (photoUrl: string) => void;
  photoUrl?: string;
  shape?: "circle" | "rectangle";
  CropperProps?: Partial<CropperProps>;
};

function ImageUploadBlock(props: ImageUploadBlockProps) {
  const {
    mode,
    variant,
    placeholder,
    photoUrl,
    onPhotoUrlUpdate,
    CropperProps,
    shape,
  } = props;

  // hooks
  const fileInputRef = useRef(null);

  // state
  const [imageSelectDialogOpen, setImageSelectDialogOpen] = useState(false);
  const [imageViewDialogOpen, setImageViewDialogOpen] = useState(false);

  // derived state
  const isPhotoAvailable = !!photoUrl;

  // event handlers
  const handleActionButtonClick = () => {
    isPhotoAvailable
      ? setImageViewDialogOpen(true)
      : setImageSelectDialogOpen(true);
  };

  const handleReplaceLogoClick = () => {
    setImageSelectDialogOpen(true);
  };

  const handleRemoveLogoClick = () => {
    onPhotoUrlUpdate("");
  };

  return (
    <>
      {/* header to show up only in create drawer's photo section */}
      {mode === "create" && (
        <Box mt={4} mb={2}>
          <Typography variant="h3">{startCase(variant)} Photo</Typography>
        </Box>
      )}

      {/* avatar shows up in update drawer no matter what but the avatar 
    shows up in create drawer only if profile photo was selected and 
    uploaded to firebase*/}
      {((mode === "create" && isPhotoAvailable) || mode === "update") && (
        <Box mb={-4}>
          <MoovsAvatar
            src={photoUrl}
            size="xxl"
            shape={shape}
            alt={`${variant} photo`}
            placeholder={placeholder}
          />
          <Tooltip
            title={
              isPhotoAvailable
                ? `View ${startCase(variant)} Photo`
                : `Upload ${startCase(variant)} Photo`
            }
            placement="top"
          >
            <IconButton
              onClick={handleActionButtonClick}
              style={{
                position: "relative",
                left: shape === "rectangle" ? "290px" : "90px",
                bottom: shape === "rectangle" ? "20px" : "40px",
                backgroundColor: `${moovsBlue}`,
              }}
              size="large"
            >
              {isPhotoAvailable ? (
                /* In create drawer - when profile Photo uploaded to firebase 
            Note - will be uploaded to Moovs dB only when driver is created */

                /* In update drawer - when photo has been previously uploaded */
                <ViewIcon color={white} size="small" />
              ) : (
                /* update drawer - Profile photo is not uploaded */
                <ImagePhotoIcon color={white} size="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      )}

      {/* Shows only in create drawer - only if a photo was not selected and uploaded to firebase */}
      {mode === "create" && !isPhotoAvailable && (
        <>
          <Box
            maxWidth={shape === "rectangle" ? 124 * 2.5 : 124}
            maxHeight={124}
          >
            <img src={photoCamera} alt="upload" />
          </Box>
          <Box display="flex" alignItems="center" flexDirection="row" mt={2}>
            <Button
              variant="outlined"
              color="primary"
              component="span"
              onClick={() => setImageSelectDialogOpen(true)}
            >
              Add Photo
            </Button>
            <Box color={granite} ml={2}>
              <Typography variant="h6">Click to upload a new photo.</Typography>
              <Typography variant="caption">JPEG/PNG files only</Typography>
            </Box>
          </Box>
        </>
      )}

      {mode === "create" && isPhotoAvailable && (
        <Box
          display="flex"
          justifyContent="space-between"
          width="fit-content"
          mt={4}
        >
          <Box>
            <Button variant="outlined" onClick={handleReplaceLogoClick}>
              Replace Logo
            </Button>
          </Box>
          <Box ml={2}>
            <Button variant="outlined" onClick={handleRemoveLogoClick}>
              Remove Logo
            </Button>
          </Box>
        </Box>
      )}

      <SelectOrUploadPhotoDialog
        variant={variant}
        photoUrl={photoUrl}
        onPhotoUrlUpdate={onPhotoUrlUpdate}
        imageSelectDialogOpen={imageSelectDialogOpen}
        setImageSelectDialogOpen={setImageSelectDialogOpen}
        fileInputRef={fileInputRef}
        CropperProps={CropperProps}
      />

      {/* To view the picture if it is in firebase - (even if photo was not created in create drawer) */}
      <ViewPhotoDialog
        variant={variant}
        photoUrl={photoUrl}
        shape={shape}
        onPhotoUrlUpdate={onPhotoUrlUpdate}
        imageViewDialogOpen={imageViewDialogOpen}
        setImageViewDialogOpen={setImageViewDialogOpen}
      />
    </>
  );
}

export default ImageUploadBlock;
