import React from "react";

type QuotesIconProps = {
  color?: string;
  size?: "small";
};

const QuotesIcon = (props: QuotesIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="14"
        height="10"
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.66699 1H1.66699M9.66699 9H1.66699M9.66699 5H1.66699M12.3337 1H11.667M12.3337 9H11.667M12.3337 5H11.667"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 6H4M16 18H4M16 12H4M20 6H19M20 18H19M20 12H19"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default QuotesIcon;
