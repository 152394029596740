import React from "react";

import { Box, Card, CardContent, Typography } from "@mui/material";

import { granite, green, tintAqua } from "design-system/colors";
import CheckFilledIcon from "design-system/icons/info/CheckFilledIcon";
import { useScreenSize } from "globals/hooks";
import { TASK_TEXT, TaskVariant } from "./util";

const COMPLETED_TASK_DETAILS = {
  [TaskVariant.VEHICLE]: {
    header: TASK_TEXT[TaskVariant.VEHICLE].header,
    subtext: TASK_TEXT[TaskVariant.VEHICLE].subtext,
  },
  [TaskVariant.DRIVER]: {
    header: TASK_TEXT[TaskVariant.DRIVER].header,
    subtext: TASK_TEXT[TaskVariant.DRIVER].subtext,
  },
  [TaskVariant.QUOTE]: {
    header: TASK_TEXT[TaskVariant.QUOTE].header,
    subtext: TASK_TEXT[TaskVariant.QUOTE].subtext,
  },
  [TaskVariant.RESERVATION]: {
    header: TASK_TEXT[TaskVariant.RESERVATION].header,
    subtext: TASK_TEXT[TaskVariant.RESERVATION].subtext,
  },
  [TaskVariant.MOOVS_PAYMENT]: {
    header: TASK_TEXT[TaskVariant.MOOVS_PAYMENT].header,
    subtext: TASK_TEXT[TaskVariant.MOOVS_PAYMENT].subtext,
  },
};

type CompleteTaskProps = {
  task: TaskVariant;
};

function CompletedTask(props: CompleteTaskProps) {
  const { task } = props;

  const { header, subtext } = COMPLETED_TASK_DETAILS[task];

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: tintAqua,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
      }}
    >
      <CardContent>
        <Box display="flex">
          <Box
            display="flex"
            justifyContent="center"
            alignItems={isMobileView ? "center" : "flex-start"}
            mr={2}
          >
            <CheckFilledIcon color={green} />
          </Box>
          <Box pr={4}>
            <Typography variant="h4" fontWeight={500} mb={0.5}>
              {header}
            </Typography>
            <Typography variant="body2" color={granite}>
              {subtext}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default CompletedTask;
