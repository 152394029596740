import { useMutation } from "@apollo/client";

import { ENROLL_SALES_AUTOMATION } from "globals/graphql";
import { useSnackbar } from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type UseEnrollSalesAutomationProps = {
  onClose: () => void;
};

function useEnrollSalesAutomation(props: UseEnrollSalesAutomationProps) {
  const { onClose } = props;

  // hook
  const snackbar = useSnackbar();

  // mutation
  const [enrollSalesAutomation, { loading: enrollSalesMutationLoading }] =
    useMutation(ENROLL_SALES_AUTOMATION, {
      onCompleted() {
        snackbar.success("Quote Successfully Texted");
        onClose();
      },
      onError(error) {
        const errorMessage = getErrorMessage(error) || "Quote Text Failed";
        snackbar.error(errorMessage);
      },
    });

  return {
    enrollSalesAutomation,
    enrollSalesMutationLoading,
  };
}

export default useEnrollSalesAutomation;
