import React from "react";

import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { granite } from "design-system/colors";

function BookingContactColumn(props: GridRenderCellParams<string>) {
  const { row } = props;

  const bookingContact = row.request.bookingContact;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
    >
      <Typography variant="body2" textOverflow="ellipsis" overflow="hidden">
        {`${bookingContact.firstName} ${bookingContact.lastName}`}
      </Typography>
      <Typography
        color={granite}
        variant="caption"
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {bookingContact.mobilePhone}
      </Typography>
    </Box>
  );
}

export default BookingContactColumn;
