import React, { useState } from "react";
import isNull from "lodash/isNull";

import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import { ArrowLongRightIcon } from "design-system/icons";
import { TripCategory } from "types";
import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";

type ShuttleTypeButtonsBlockProps = {
  onChange: (e: any, value: any) => void;
  value: string;
  isDuplicateTrip?: boolean;
};

const transferValue = "TRANSFER";

const ShuttleTypeButtonsBlock = (props: ShuttleTypeButtonsBlockProps) => {
  const { onChange, value, isDuplicateTrip } = props;

  // state
  const [transferType, setTransferType] = useState(
    isDuplicateTrip ? value : (TripCategory.ShuttlePickUp as string)
  ); // ShuttlePickUp by default

  const toggleOptions = [
    {
      value: transferValue,
      label: "Transfer",
      ariaLabel: "Transfer",
      icon: ArrowLongRightIcon,
    },
  ];

  const isShuttleCategory =
    value === TripCategory.ShuttlePickUp ||
    value === TripCategory.ShuttleReturn;

  const handleOnChange = (e: React.MouseEvent<HTMLElement>, v: any) => {
    if (isNull(v)) return; // escape to disable deselect

    if (v !== transferValue) {
      onChange(e, v);
      return;
    }

    onChange(e, transferType);
  };

  const handleTransferTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTransferType(e.target.value);
    onChange(e, e.target.value);
  };

  return (
    <Box mt={3}>
      <Typography variant="h5" mb={2}>
        Shuttle Type
      </Typography>
      <MoovsToggleButtonGroup
        size="large"
        onChange={handleOnChange}
        options={toggleOptions}
        value={isShuttleCategory ? transferValue : value}
        fullWidth
        keepInline
      />
      {isShuttleCategory && (
        <Box mt={1.5}>
          <RadioGroup
            row
            name="shuttle-type-radio-buttons-group"
            value={transferType}
            onChange={handleTransferTypeChange}
          >
            <FormControlLabel
              value={TripCategory.ShuttlePickUp}
              control={<Radio />}
              label="Pick-up"
            />
            <FormControlLabel
              value={TripCategory.ShuttleReturn}
              control={<Radio />}
              label="Return"
            />
          </RadioGroup>
        </Box>
      )}
    </Box>
  );
};

export default ShuttleTypeButtonsBlock;
