import React from "react";

import { Box, Typography } from "@mui/material";

import { alabaster, granite } from "design-system/colors";
import { useScreenSize } from "globals/hooks";

const DESKTOP_PADDING = 8;

type EmptyActivityLogBlockProps = {
  source?: string;
};

function EmptyActivityLogBlock(props: EmptyActivityLogBlockProps) {
  const { isMobileView } = useScreenSize();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      py={!isMobileView && DESKTOP_PADDING}
      bgcolor={alabaster}
    >
      <Typography variant="h6" align="center" color={granite}>
        {`No current activity for this ${props.source || "order"}.`}
      </Typography>
    </Box>
  );
}

export default EmptyActivityLogBlock;
