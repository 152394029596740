import React, { useState } from "react";

import { Badge, Box, IconButton, badgeClasses } from "@mui/material";

import { HamburgerMenuIcon, SearchIcon } from "design-system/icons";
import { black } from "design-system/colors";
import ChatToggleButton from "components/chat/ChatToggleButton";
import GlobalSearch from "components/GlobalSearch";
import ComposeEmailButton from "./ComposeEmailButton";
import GettingStartedButton from "./GettingStartedButton";
import { LoadQuotesConnection } from "types";
import { LOAD_NEW_QUOTES } from "globals/graphql";
import { useQuery } from "@apollo/client";

type TopBarContentMobileProps = {
  onDrawerToggle: () => void;
  chatPanelOpen: boolean;
  onChatPanelToggle: () => void;
  isChatAccessible: boolean;
};

function TopBarContentMobile(props: TopBarContentMobileProps) {
  const { onDrawerToggle, chatPanelOpen, onChatPanelToggle, isChatAccessible } =
    props;

  const [isGlobalSearchOpen, setIsGlobalSearchOpen] = useState(false);
  const { data: quotes } = useQuery<{ loadNewQuotes: LoadQuotesConnection }>(
    LOAD_NEW_QUOTES,
    {
      pollInterval: 60 * 1000, // 1 minute
    }
  );

  const newQuotesCount = quotes?.loadNewQuotes.totalCount;
  const hasNoNewQuotes = newQuotesCount === 0;

  const handleGlobalSearchToggle = () =>
    setIsGlobalSearchOpen(!isGlobalSearchOpen);

  return (
    <>
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
        height="100%"
      >
        <Box width="100px" ml="4px">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={onDrawerToggle}
            size="large"
          >
            <HamburgerMenuIcon color={black} />
            <Badge
              color="error"
              variant="dot"
              sx={{
                [`& .${badgeClasses.dot}`]: {
                  width: "12px",
                  minWidth: "12px",
                  height: "12px",
                  top: -10,
                  right: 3,
                  borderRadius: "50%",
                },
              }}
              invisible={hasNoNewQuotes}
            ></Badge>
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="open global search"
            edge="start"
            onClick={handleGlobalSearchToggle}
            size="large"
          >
            <SearchIcon color={black} />
          </IconButton>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          mr="10px"
        >
          <GettingStartedButton />
          <ComposeEmailButton />
          <ChatToggleButton
            chatPanelOpen={chatPanelOpen}
            onChatPanelToggle={onChatPanelToggle}
            isChatAccessible={isChatAccessible}
          />
        </Box>
      </Box>

      <GlobalSearch
        variant="mobile"
        open={isGlobalSearchOpen}
        onClose={() => setIsGlobalSearchOpen(false)}
      />
    </>
  );
}

export default TopBarContentMobile;
