/**
 * Apollo Link documentation: https://www.apollographql.com/docs/react/api/link/introduction
 *
 * handling request -
 * syntax:

   // ...link logic going downstream (request)...
   return forward(operation)

 * handling response:
 * syntax:

    // ...link logic going downstream (request)...
    return forward(operation).map((data) => {
      // ...link logic going upstream (response)...
    return data;
});
 */

import { onError } from "@apollo/client/link/error";
import { ApolloLink } from "@apollo/client";

import { auth } from "../globals/utils/firebaseApp";
import authApi from "../globals/hooks/useAuth/authApi";

type MoovsError = {
  statusCode?: number;
  message?: string;
  name?: string;
  code?: string;
};

const timezoneOffset = new Date().getTimezoneOffset();

const headerMiddlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    return {
      headers: {
        ...headers,
        timezoneOffset,
      },
    };
  });

  return forward(operation);
});

const onErrorLink = () =>
  // onError will prevent any upstream links from triggering
  onError((errorHandler) => {
    const { graphQLErrors } = errorHandler;

    if (graphQLErrors) {
      graphQLErrors.map(async (error: MoovsError) => {
        if (error?.code === "MOOVS_AUTHENTICATION_ERROR") {
          await authApi.logout();
          await auth.signOut();
          window.location.replace("/login");
        }
      });
    }
  });

export { headerMiddlewareLink, onErrorLink };
