import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import first from "lodash/first";

import { Box, Typography } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";
import DialogItemDesktop from "components/DialogItemDesktop";
import DialogItemMobile from "components/DialogItemMobile";
import { grayDark } from "design-system/colors";
import {
  ReservationIcon,
  DoublePinIcon,
  PriceBreakdownIcon,
  ExternalOperatorIcon,
  ChecklistIcon,
} from "design-system/icons";
import { useAnalytics, useScreenSize, useSnackbar } from "globals/hooks";
import { downloader } from "globals/utils/downloader";
import { IS_SIGNED_TERMS_AND_CONDITIONS_PDF_DOWNLOAD_ENABLED } from "globals/graphql";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { Trip } from "types";
import { openPdf } from "globals/utils/openPdf";

type ViewPDFsDialogProps = {
  open: boolean;
  onClose: () => void;
  requestId: string;
  trip: Trip;
};

enum PdfType {
  Reservation = "Reservation",
  Trip = "Trip",
  PriceSummary = "Price Summary",
  Affiliate = "Affiliate",
  SignedTermsAndCondition = "Signed Terms & Conditions",
}

function ViewPDFsDialog(props: ViewPDFsDialogProps) {
  const { open, onClose, trip, requestId } = props;
  const { farmRelationship } = first(trip.routes);
  const tripId = trip.id;

  // hooks
  const { isMobileView } = useScreenSize();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // state
  const [canDownloadTermsAndConditions, setCanDownloadTermsAndConditions] =
    useState(false);
  const [pdfDownloadType, setPdfDownloadType] = useState(null);

  // queries
  // check if termsAndConditions PDF is downloadable
  const { data: termsAndConditionsPdfData } = useQuery(
    IS_SIGNED_TERMS_AND_CONDITIONS_PDF_DOWNLOAD_ENABLED,
    {
      variables: {
        id: requestId,
      },
    }
  );

  // useEffect
  // check if termsAndConditions pdf is downloadable on initial page load and whenever termsAndConditionsPdfData changes
  useEffect(() => {
    const downloadEnabled =
      termsAndConditionsPdfData?.node?.reservationConfirmationSignatureLog
        ?.downloadEnabled;

    if (downloadEnabled) {
      setCanDownloadTermsAndConditions(downloadEnabled);
    }
  }, [termsAndConditionsPdfData]);

  const pdfOptionsConfig = useMemo(
    () => [
      {
        label: "Reservation",
        icon: ReservationIcon,
        endPoint: "/endpoint/reservation/operatorPdfDownloadHandler",
        analyticsNameDownload: "reservation_reservationPdfDownloaded",
        analyticsNameOpen: "reservation_reservationPdfOpened",
        params: { id: requestId },
        isDownloadingPdf: pdfDownloadType === PdfType.Reservation,
      },
      {
        label: "Trip",
        icon: DoublePinIcon,
        endPoint: "/endpoint/trip/operatorPdfDownloadHandler",
        analyticsNameDownload: "reservation_tripPdfDownloaded",
        analyticsNameOpen: "reservation_tripPdfOpened",
        params: { id: tripId },
        isDownloadingPdf: pdfDownloadType === PdfType.Trip,
      },
      {
        label: "Price Summary",
        icon: PriceBreakdownIcon,
        endPoint: "/endpoint/priceSummary/operatorPdfDownloadHandler",
        analyticsNameDownload: "reservation_priceSummaryPdfDowloaded",
        analyticsNameOpen: "reservation_priceSummaryPdfOpened",
        params: { id: requestId },
        isDownloadingPdf: pdfDownloadType === PdfType.PriceSummary,
      },
      {
        label: "Affiliate",
        icon: ExternalOperatorIcon,
        endPoint: "/endpoint/affiliate/operatorPdfDownloadHandler",
        analyticsNameDownload: "reservation_affiliatePdfDownloaded",
        analyticsNameOpen: "reservation_affiliatePdfOpened",
        params: { id: tripId },
        hide: !farmRelationship,
        isDownloadingPdf: pdfDownloadType === PdfType.Affiliate,
      },
      {
        label: "Signed Terms & Conditions",
        icon: ChecklistIcon,
        endPoint: "/endpoint/signedConsent/operatorPdfDownloadHandler",
        analyticsNameDownload: "reservation_termsConditionsDownloaded",
        analyticsNameOpen: "reservation_termsConditionsOpened",
        params: { id: requestId },
        disabled: !canDownloadTermsAndConditions,
        isDownloadingPdf: pdfDownloadType === PdfType.SignedTermsAndCondition,
        disableTooltipText: "Order does not have signed terms and conditions",
      },
    ],
    [
      tripId,
      requestId,
      farmRelationship,
      canDownloadTermsAndConditions,
      pdfDownloadType,
    ]
  );

  // event handlers
  const onDownload =
    ({ label, endPoint, analyticsName, params }) =>
    async () => {
      try {
        setPdfDownloadType(label);
        await downloader(endPoint, params);

        snackbar.success(`Successfully downloaded ${label} PDF!`);
      } catch (error) {
        const errorMessage =
          getErrorMessage(error) ||
          `Error downloading ${label} PDF, please try again.`;
        snackbar.error(errorMessage);
      }
      setPdfDownloadType(null);
      if (analyticsName) track(analyticsName);
    };

  const onOpen =
    ({ label, endPoint, analyticsName, params }) =>
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      try {
        await openPdf({ endPoint, queryParams: params });

        snackbar.success(`Successfully opened ${label} PDF!`);
      } catch (error) {
        const errorMessage =
          getErrorMessage(error) || `Error opening ${label} PDF`;
        snackbar.error(errorMessage);
      }
      if (analyticsName) track(analyticsName);
    };

  const DownloadPDFItem = isMobileView ? DialogItemMobile : DialogItemDesktop;

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      dialogTitle="View PDFs"
      size="sm"
      subHeaderComponent={
        !isMobileView && (
          <Box>
            <Typography variant="body1" color={grayDark}>
              Click to download PDF
            </Typography>
          </Box>
        )
      }
    >
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {pdfOptionsConfig.map(
          ({
            label,
            icon,
            endPoint,
            analyticsNameDownload,
            analyticsNameOpen,
            params,
            hide,
            disabled,
            disableTooltipText,
            isDownloadingPdf,
          }) =>
            !hide && (
              <DownloadPDFItem
                key={label}
                label={label}
                icon={icon}
                disabled={disabled}
                isLoading={isDownloadingPdf}
                disableTooltipText={disableTooltipText}
                onClickPrimary={onDownload({
                  label,
                  endPoint,
                  analyticsName: analyticsNameDownload,
                  params,
                })}
                onClickOpen={onOpen({
                  label,
                  endPoint,
                  analyticsName: analyticsNameOpen,
                  params,
                })}
                primaryAction="Download"
              />
            )
        )}
      </Box>
    </MoovsDialog>
  );
}

export default ViewPDFsDialog;
