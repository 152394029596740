import React from "react";

import { Box, Typography } from "@mui/material";

import { SeparatorDotIcon } from "design-system/icons";

type FarmAffiliateAdditionalContentProps = {
  farmAffiliateName: string;
  distanceFromFirstStop?: number;
};

function FarmAffiliateAdditionalContent(
  props: FarmAffiliateAdditionalContentProps
) {
  const { farmAffiliateName, distanceFromFirstStop } = props;

  return (
    <Box display="flex">
      <Typography variant="h6">{farmAffiliateName}</Typography>
      {!!distanceFromFirstStop && (
        <Box display="flex">
          <Box m="0 4px">
            <SeparatorDotIcon />
          </Box>
          <Typography variant="caption">{distanceFromFirstStop} mi</Typography>
        </Box>
      )}
    </Box>
  );
}

export default FarmAffiliateAdditionalContent;
