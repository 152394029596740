import React from "react";

type FreePlanIconProps = {
  size?: "large";
};

const FreePlanIcon = (props: FreePlanIconProps) => {
  if (props.size === "large") {
    return (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="48" height="48" rx="24" fill="#EDEDED" />
        <path
          d="M14.5 24H24M33.0526 24C33.0526 25.6569 31.6977 27 30.0263 27C28.3549 27 27 25.6569 27 24C27 22.3431 28.3549 21 30.0263 21C31.6977 21 33.0526 22.3431 33.0526 24Z"
          stroke="#1E1E1E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="18" fill="#EDEDED" />
      <path
        d="M10.0833 18H17.9999M25.5438 18C25.5438 19.3807 24.4147 20.5 23.0218 20.5C21.629 20.5 20.4999 19.3807 20.4999 18C20.4999 16.6193 21.629 15.5 23.0218 15.5C24.4147 15.5 25.5438 16.6193 25.5438 18Z"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FreePlanIcon;
