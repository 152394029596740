import React, {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useState,
} from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Cropper, { CropperProps } from "react-easy-crop";
import { Area } from "react-easy-crop/types";

import { Box, CircularProgress, Typography } from "@mui/material";

import { useAnalytics, useScreenSize, useSnackbar } from "globals/hooks";
import generateId from "globals/utils/generateId";
import { storage } from "globals/utils/firebaseApp";
import {
  granite,
  alabaster,
  black,
  moovsBlueLight,
} from "design-system/colors";
import image from "design-system/illustrations/image.svg";
import { getCroppedImg, resizeFile } from "components/ImageUpload/imageUtils";
import MoovsProfilePhotoDialog from "components/MoovsProfilePhotoDialog";

type SelectOrUploadPhotoDialogProps = {
  variant: "driver" | "company";
  photoUrl: string;
  onPhotoUrlUpdate: (photoUrl: string) => void;
  imageSelectDialogOpen: boolean;
  setImageSelectDialogOpen: Dispatch<SetStateAction<boolean>>;
  fileInputRef: MutableRefObject<any>;
  CropperProps?: Partial<CropperProps>;
};

function SelectOrUploadPhotoDialog(props: SelectOrUploadPhotoDialogProps) {
  const {
    variant,
    photoUrl,
    onPhotoUrlUpdate,
    imageSelectDialogOpen,
    setImageSelectDialogOpen,
    fileInputRef,
    CropperProps,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // state
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>(null);
  const [fileName, setFileName] = useState("");
  const [imageDataUrl, setImageDataUrl] = useState("");
  const [imageDialogLoading, setImageDialogLoading] = useState(false);
  const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false);

  // event handlers
  const handleCropComplete = (_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  // event handlers
  const handleImageUploadClose = () => {
    setImageUploadDialogOpen(false);
    setFileName("");
    setImageDataUrl("");
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleImageUploadtoFireBase = async (croppedAreaPixels: Area) => {
    try {
      setImageDialogLoading(true);

      // crop image
      const croppedImage = await getCroppedImg(imageDataUrl, croppedAreaPixels);
      const firebaseDirectory = `${variant}_photos`;

      // upload image
      const randomId = generateId(8);
      const imagesRef = ref(
        storage,
        `/${firebaseDirectory}/${randomId}_${fileName}`
      );

      // upload photo to firebase
      const snapshot = await uploadBytes(imagesRef, croppedImage);
      const url = await getDownloadURL(snapshot.ref);

      setImageUploadDialogOpen(false);
      onPhotoUrlUpdate(url);
      setImageDialogLoading(false);
    } catch (error) {
      snackbar.error(error.message);
      setImageDialogLoading(false);
      setImageUploadDialogOpen(false);
    }
  };

  const handleSelectFile = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];

        setImageDialogLoading(true);

        // resize image
        setTimeout(async () => {
          const image = await resizeFile(file, 1000, 400);
          setFileName(file.name);
          setImageDataUrl(image);
          setImageSelectDialogOpen(false);
          setImageUploadDialogOpen(true);
          setImageDialogLoading(false);
        }, 500);
      }
    } catch (error) {
      snackbar.error(error.message);
      setImageDialogLoading(false);
      setImageUploadDialogOpen(false);
    }
  };

  const handleRemoveImage = () => {
    onPhotoUrlUpdate("");
    setImageUploadDialogOpen(false);
    setFileName("");
    setImageDataUrl("");
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setImageSelectDialogOpen(true);
  };

  return (
    <MoovsProfilePhotoDialog
      open={imageUploadDialogOpen || imageSelectDialogOpen}
      onClose={() => {
        imageUploadDialogOpen
          ? handleImageUploadClose()
          : setImageSelectDialogOpen(false);
      }}
      dialogTitle="Add Photo"
      onAccept={() => {
        track(
          variant === "company" ? "company_logoAdded" : "driver_photoAdded"
        );
        handleImageUploadtoFireBase(croppedAreaPixels);
      }}
      acceptButtonText="Upload"
      closeButtonText="Close"
      zoom={zoom}
      setZoom={setZoom}
      isEditImage={imageUploadDialogOpen}
      isDeleteImage={imageUploadDialogOpen}
      onRemoveImage={handleRemoveImage}
      autoMargin={imageSelectDialogOpen}
      removeAcceptButton={imageSelectDialogOpen}
    >
      {imageDialogLoading ? (
        <Box
          minHeight={isMobileView ? "calc(100vh - 340px)" : "440px"}
          display="flex"
          alignItems="center"
          margin="auto"
        >
          <CircularProgress />
        </Box>
      ) : imageUploadDialogOpen ? (
        // upload dialog
        <Box
          position="relative"
          height={isMobileView ? "100%" : 380}
          width="100%"
        >
          <Cropper
            restrictPosition
            cropShape="round"
            showGrid={false}
            image={imageDataUrl || photoUrl}
            crop={crop}
            zoom={zoom}
            aspect={10 / 10}
            onCropChange={setCrop}
            onCropComplete={handleCropComplete}
            onZoomChange={setZoom}
            {...CropperProps}
          />
        </Box>
      ) : (
        //  select dialog
        <>
          <input
            accept="image/*"
            id={`${variant}-photo-upload`}
            type="file"
            style={{
              display: "none",
              minHeight: `${isMobileView ? "calc(100vh - 340px)" : "440px"}`,
            }}
            onChange={handleSelectFile}
            ref={fileInputRef}
            onClick={(event) => event.stopPropagation()}
          />
          <label htmlFor={`${variant}-photo-upload`}>
            {
              <Box
                bgcolor={alabaster}
                border={`2px dashed ${moovsBlueLight}`}
                textAlign="center"
                p={5}
                maxWidth="327px"
                {...(isMobileView && {
                  marginTop: "80px",
                  marginBottom: "80px",
                })}
              >
                <img src={image} alt="profile Illustration" />

                <Typography
                  variant="h3"
                  style={{
                    color: `${black}`,
                    marginBottom: "8px",
                    marginTop: "16px",
                  }}
                >
                  Select file to upload
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    color: `${granite}`,
                  }}
                >
                  Click here to upload a photo
                </Typography>
              </Box>
            }
          </label>
        </>
      )}
    </MoovsProfilePhotoDialog>
  );
}

export default SelectOrUploadPhotoDialog;
