import React, { ReactNode } from "react";

import {
  Box,
  CardMedia,
  Typography,
  Tooltip,
  Divider,
  Card,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import size from "lodash/size";

import MissingImage from "../../../design-system/images/MissingImage.png";
import {
  grayDark,
  grayLight,
  grayMedium,
  moovsBlue,
  white,
} from "../../../design-system/colors";
import { primaryDisabledColor } from "../../../theme";
import { useScreenSize } from "../../../globals/hooks";
import { VehicleCardContentRightEdgeBlock } from "./index";
import { VehicleCalendarVariantEnum } from "../../../globals/utils/vehicleCalendarEnum";
import { SeparatorDotIcon } from "design-system/icons";
import AutomatedBaseRateBlock from "./AutomatedBaseRateBlock";

type VehicleCardContentProps = {
  mainContent: {
    header: string;
    subHeader: string[];
    imageTitle?: string;
  };
  isSelected: boolean;
  photoUrl: string;
  vehicleCalendarVariant?: VehicleCalendarVariantEnum;
  automatedBaseRateProps?: {
    showBRBlock?: boolean;
    baseRateAmount?: number;
  };
  icons?: ReactNode[];
  ellipsisMenuActions?: {
    onPressRemove?: () => void;
    onPressReplace?: () => void;
  };
  onSelectVehicle?: () => void;
  onVehicleViewOnlyClick?: () => void;
  onShowVehicleAvailabilityClick?: (
    vehicleAvailabilityDialogTitle: string
  ) => void;
  additionalContent?: {
    header: string;
    line1: string | ReactNode;
  };
  isReadOnly?: boolean;
  onSwoopFarmVehiclesDialogOpen?: () => void;
};

function VehicleCardContent(props: VehicleCardContentProps) {
  const { NoCalendar } = VehicleCalendarVariantEnum;
  const {
    mainContent,
    isSelected,
    additionalContent,
    icons = [],
    photoUrl,
    ellipsisMenuActions = {},
    onSelectVehicle,
    automatedBaseRateProps = {},
    onVehicleViewOnlyClick,
    vehicleCalendarVariant = NoCalendar,
    onShowVehicleAvailabilityClick,
    isReadOnly,
    onSwoopFarmVehiclesDialogOpen,
  } = props;

  const { onPressRemove, onPressReplace } = ellipsisMenuActions;
  const { showBRBlock, baseRateAmount } = automatedBaseRateProps;
  const { header, subHeader, imageTitle } = mainContent;

  // hooks
  const theme = useTheme();
  const { isMobileView } = useScreenSize();

  // derived state
  const isSelectable = !!onSelectVehicle;
  const canSelectVehicle = !isReadOnly && isSelectable;

  // render functions
  const renderAdditionalDetails = () => {
    return !!additionalContent ? (
      <>
        <Typography variant="overline">{additionalContent.header}</Typography>
        <Box display="flex">
          {/* Currently only used to render farming icons - make sure to verify if other icons are added in future */}
          {icons.map((icon, index) => (
            <Box key={index} {...(!!icon && { mr: 1 })}>
              {icon}
            </Box>
          ))}
          <Typography variant="caption">{additionalContent.line1}</Typography>
        </Box>
      </>
    ) : null;
  };

  // derived state
  const hasEllipsisMenuOptions = !!(onPressReplace || onPressRemove);
  const showARBAndAdditionalInfoInMobileView =
    isMobileView && (showBRBlock || !!additionalContent);
  const showMarginBelowMainContent = !showARBAndAdditionalInfoInMobileView;

  const width = isMobileView ? 99 : 128;
  const height = isMobileView ? 74 : 96;
  const hoverBgColor = canSelectVehicle && !isSelected ? grayLight : null;

  const cardRootStyles = {
    position: "relative",
    display: "flex",
    flex: 1,
    borderRadius: "4px",
    padding: "16px",
    flexDirection: "column",
    backgroundColor: isSelected ? primaryDisabledColor : white,
    cursor: canSelectVehicle ? "pointer" : "default",
    borderColor: isSelected ? moovsBlue : grayMedium,
    ...(!!hoverBgColor && {
      ":hover": {
        backgroundColor: canSelectVehicle && !isSelected ? grayLight : null,
      },
    }),
  } as const;

  const cardMediaStyles = {
    width,
    height,
    borderRadius: "4px",
  };

  return (
    <Card
      data-testid="vehicle-card-small"
      sx={cardRootStyles}
      variant="outlined"
      {...(!isReadOnly && {
        onClick: onSelectVehicle,
      })}
    >
      <Box display="flex" flexDirection="row">
        <CardMedia
          sx={cardMediaStyles}
          image={photoUrl || MissingImage}
          component="img"
          onError={(e: any) => (e.target.src = MissingImage)}
          title={imageTitle || header || "missing image"}
        />
        <Box ml={2.5} display="flex" flexDirection="column" flex={2}>
          <Box display="flex" flexDirection="column" mb={1}>
            <Tooltip title={header || "-"}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "bold",
                  fontSize: 16,
                  overflow: "hidden",
                  ...(isMobileView && { maxWidth: 150 }),
                }}
              >
                {header || "-"}
              </Typography>
            </Tooltip>

            <Box
              display="flex"
              flexDirection={isMobileView ? "column" : "row"}
              alignItems={isMobileView ? "flex-start" : "center"}
              flex={1}
              whiteSpace="nowrap"
              color={grayDark}
              {...(showMarginBelowMainContent && { mb: 4 })}
            >
              {subHeader.map((subHeaderPart, index) => {
                const isLastPart = index === size(subHeader) - 1;

                return (
                  <>
                    <Typography variant="body2">{subHeaderPart}</Typography>
                    {!isLastPart && !isMobileView && (
                      <Box display="flex" alignItems="center" mx={0.5}>
                        <SeparatorDotIcon />
                      </Box>
                    )}
                  </>
                );
              })}
            </Box>
            {!isMobileView && (
              <Box
                bottom={theme.spacing(1)}
                display="flex"
                flexDirection="column"
                flex="1"
              >
                {renderAdditionalDetails()}
              </Box>
            )}
          </Box>
        </Box>

        <Box
          display="flex"
          flex={1}
          flexDirection={
            vehicleCalendarVariant !== NoCalendar ? "row" : "column"
          }
        >
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <VehicleCardContentRightEdgeBlock
              automatedBaseRateProps={automatedBaseRateProps}
              ellipsisMenuOptionsProps={{
                hasEllipsisMenuOptions,
                ellipsisMenuActions,
              }}
              onVehicleViewOnlyClick={onVehicleViewOnlyClick}
              onSwoopFarmVehiclesDialogOpen={onSwoopFarmVehiclesDialogOpen}
              {...(onShowVehicleAvailabilityClick && {
                vehicleAvailabilityProps: {
                  vehicleAvailabilityDialogTitle: header,
                  vehicleCalendarVariant,
                  onShowVehicleAvailabilityClick,
                },
              })}
            />
          </Box>
        </Box>
      </Box>

      {/* Bottom Details in Mobile View */}
      {showARBAndAdditionalInfoInMobileView && (
        <Box mt={2}>
          <Divider />
          <Box display="flex" justifyContent="space-between" mt={1}>
            <Box display="flex" flexDirection="column">
              {renderAdditionalDetails()}
            </Box>
            {showBRBlock && (
              <AutomatedBaseRateBlock automatedBRAmount={baseRateAmount} />
            )}
          </Box>
        </Box>
      )}
    </Card>
  );
}

export default VehicleCardContent;
