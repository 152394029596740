/**
 * Future todo: To eliminate prop drilling - create a context for the DispatchTripCard.
 */
import React from "react";

import { Box, CardActionArea } from "@mui/material";

import { alabaster, grayLight, white } from "design-system/colors";
import { ORDER_ITEM_HEIGHT } from "pages/requests/RequestsList/RequestsList";
import {
  DriverStatusName,
  RoundTripVariant,
  RouteDispatchLogStatusEnum,
  Stop,
  TripCategory,
} from "types";
import { DispatchTripDetails } from "./components/DispatchTripDetails";
import { RouteDispatchDetails } from "./components/RouteDispatchDetails";

export type DriverTrackingTripCardProps = {
  orderNumber: string; // full order number + trip number - ex: RHJK-15
  tripCategory: TripCategory;
  onClick: () => void;
  driverName: string | null; // if not provided, displays "Driver Unassigned" in errorRed
  vehicleName: string | null | undefined;
  passengerName: string;
  driverAcceptanceStatus: DriverStatusName | null | undefined;
  routeDispatchLogStatus: RouteDispatchLogStatusEnum | null | undefined;
  dispatchStatus: string;
  pickUpTime: Date; // if no routeDispatchLogStatus, then we will display this pick up time
  stopLabel: string;
  stopAbbrevationWithAddressLabel: string;
  arrivalStopTime: Date;
  driverEta: Date;
  roundTripVariant?: RoundTripVariant;
  stops: Stop[];
};

function DriverTrackingTripCard(props: DriverTrackingTripCardProps) {
  const {
    orderNumber,
    tripCategory,
    roundTripVariant,
    onClick,
    driverName,
    vehicleName,
    passengerName,
    driverAcceptanceStatus,
    dispatchStatus,
    routeDispatchLogStatus,
    pickUpTime,
    stopLabel,
    stopAbbrevationWithAddressLabel,
    arrivalStopTime,
    driverEta,
    stops,
  } = props;

  return (
    <CardActionArea
      disableRipple
      onClick={onClick}
      sx={{
        minHeight: ORDER_ITEM_HEIGHT,
        borderBottom: `1px solid ${grayLight}`,
        backgroundColor: dispatchStatus === "done" ? alabaster : white,
        m: 1,
        borderRadius: 1,
        boxShadow:
          "2px 0px 4px rgba(0, 0, 0, 0.05), -2px 2px 4px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box display="flex" m={2}>
        <RouteDispatchDetails
          orderNumber={orderNumber}
          tripCategory={tripCategory}
          roundTripVariant={roundTripVariant}
          routeDispatchLogStatus={routeDispatchLogStatus}
          pickUpTime={pickUpTime}
          stopLabel={stopLabel}
          stopAbbrevationWithAddressLabel={stopAbbrevationWithAddressLabel}
          arrivalStopTime={arrivalStopTime}
          dispatchStatus={dispatchStatus}
          driverEta={driverEta}
          stops={stops}
        />

        {/* Right of divider */}
        <DispatchTripDetails
          driverName={driverName}
          driverAcceptanceStatus={driverAcceptanceStatus}
          vehicleName={vehicleName}
          passengerName={passengerName}
          pickUpTime={pickUpTime}
        />
      </Box>
    </CardActionArea>
  );
}

export { DriverTrackingTripCard };
