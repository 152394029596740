import React from "react";

import { Box, Divider } from "@mui/material";

import { Trip } from "types";
import {
  DateTimeInfo,
  PassengerCountInfo,
  TripDurationAndCategory,
  VehicleInfo,
} from "./components";
import { useScreenSize } from "globals/hooks";
import { alabaster } from "design-system/colors";
import TripTimeline from "pages/requests/RequestOverview/ReservationOverview/TripItineraryBlock/components/TripTimeline";

type ViewOnlyTripDetailsProps = {
  trip: Trip;
};

function ViewOnlyTripDetails(props: ViewOnlyTripDetailsProps) {
  const { trip } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Box p={2} borderRadius={2} bgcolor={alabaster}>
      <TripDurationAndCategory trip={trip} />

      <Box
        display="flex"
        flexDirection={isMobileView ? "column" : "row-reverse"}
        mt={1.5}
      >
        <Box display="flex" flexDirection="column" gap={1} flex={0.4}>
          <DateTimeInfo trip={trip} />
          <VehicleInfo trip={trip} />
          <PassengerCountInfo trip={trip} />
        </Box>

        <Divider
          flexItem
          orientation={isMobileView ? "horizontal" : "vertical"}
          sx={{
            ...(isMobileView ? { my: 2 } : { mx: 4 }),
          }}
        />

        <Box flex={0.6}>
          <TripTimeline trip={trip} variant="location-only" />
        </Box>
      </Box>
    </Box>
  );
}

export default ViewOnlyTripDetails;
