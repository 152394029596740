import React from "react";

type ViewReceiptIconProps = {
  color?: string;
  size?: "small";
};

const ViewReceiptIcon = (props: ViewReceiptIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.57478 12.3335H5.60323M1.57478 14.6667H6.3335M8.44199 5.66671H10.3028M8.44199 3.14394H12.9884M12.1346 5.66671H14.6668M11.6668 9.83337V13.5M11.6668 13.5L13.0418 12.125M11.6668 13.5L10.2918 12.125M1.52762 7.99778C1.73783 8.29659 2.0115 8.53615 2.32544 8.69616C2.63938 8.85617 2.98433 8.93191 3.33107 8.91697C4.43663 8.91697 5.3335 8.18897 5.3335 7.29184C5.3335 6.39471 4.43954 5.66776 3.33495 5.66776C2.23037 5.66776 1.3335 4.93976 1.3335 4.04158C1.3335 3.14339 2.23037 2.41645 3.33495 2.41645M3.33495 2.41645C3.68172 2.40123 4.02676 2.47686 4.34074 2.63689C4.65472 2.79692 4.92836 3.03663 5.1384 3.33564M3.33495 2.41645V1.33337M3.33495 8.91697V10M8.00016 11.6667C8.00016 9.64166 9.64179 8.00004 11.6668 8.00004C13.6919 8.00004 15.3335 9.64166 15.3335 11.6667C15.3335 13.6918 13.6919 15.3334 11.6668 15.3334C9.64178 15.3334 8.00016 13.6918 8.00016 11.6667Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.36192 18.5002H8.4046M2.36192 22H9.5M12.6627 8.5H15.454M12.6627 4.71585H19.4823M18.2017 8.5H22M17.5 14.75V20.25M17.5 20.25L19.5625 18.1875M17.5 20.25L15.4375 18.1875M2.29119 11.9966C2.60651 12.4448 3.01701 12.8042 3.48792 13.0442C3.95883 13.2842 4.47626 13.3978 4.99636 13.3754C6.65469 13.3754 8 12.2834 8 10.9377C8 9.592 6.65906 8.50158 5.00219 8.50158C3.34531 8.50158 2 7.40958 2 6.0623C2 4.71503 3.34531 3.62461 5.00219 3.62461M5.00219 3.62461C5.52234 3.60179 6.0399 3.71523 6.51086 3.95527C6.98183 4.19532 7.39229 4.55488 7.70735 5.00339M5.00219 3.62461V2M5.00219 13.3754V15M12 17.5C12 14.4624 14.4624 12 17.5 12C20.5376 12 23 14.4624 23 17.5C23 20.5376 20.5376 23 17.5 23C14.4624 23 12 20.5376 12 17.5Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ViewReceiptIcon;
