import React, { ChangeEvent, ReactNode } from "react";
import { CardElement } from "@stripe/react-stripe-js";

import {
  Box,
  Typography,
  TextField,
  Divider,
  InputAdornment,
  IconButton,
  Grid,
} from "@mui/material";

import { alabaster, black, errorRed, white } from "design-system/colors";
import { TrashIcon } from "design-system/icons";
import StripeTextField from "../../../../common/CreditCardElements/StripeTextField";

type CreateCreditCardBlockProps = {
  stripeElementsError: {
    cardNumber: null | string;
    cardExpirationDate: null | string;
    cvc: null | string;
    createStripePaymentMethodError: null | string;
  };
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDeleteCardElement: () => void;
  setHasEnteredCardInfo: (value: boolean) => void;
  billingDetailsElement: ReactNode;
};

function CreateCreditCardBlock(props: CreateCreditCardBlockProps) {
  const {
    stripeElementsError,
    onChange,
    onDeleteCardElement,
    setHasEnteredCardInfo,
    billingDetailsElement,
  } = props;

  const styles = {
    creditCardFields: {
      backgroundColor: white,
    },
  };

  return (
    <>
      <Box mt={3} mb={2}>
        <Typography variant="h5">Credit Card</Typography>
        {stripeElementsError.createStripePaymentMethodError && (
          <Typography variant="h5" color={errorRed}>
            {`: ${stripeElementsError.createStripePaymentMethodError}`}
          </Typography>
        )}
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>

      {/* Stripe Elements credit card */}
      <Box bgcolor={alabaster} py={3} px={2}>
        <Grid container spacing={1} mb={1}>
          <Grid item xs={12}>
            <Typography variant="overline">Card Number</Typography>
            <Box mt={0.5}>
              <StripeTextField
                onChange={(e) => setHasEnteredCardInfo(!e.empty)}
                stripeElement={CardElement}
                label='stripe'
                InputProps={{
                  sx: styles.creditCardFields,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={onDeleteCardElement} size="large">
                        <TrashIcon color={black} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {billingDetailsElement}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline">
              Cardholder Email (optional)
            </Typography>
            <TextField
              style={{ backgroundColor: white }}
              variant="outlined"
              fullWidth
              name="cardHolderEmail"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline">
              Additional Notes (Optional)
            </Typography>
            <TextField
              variant="outlined"
              style={{ backgroundColor: white }}
              fullWidth
              multiline
              name="cardNote"
              onChange={onChange}
              inputProps={{
                "data-testid": "card-note-input",
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default CreateCreditCardBlock;
