import React from "react";

import { Box, IconButton, Typography } from "@mui/material";

import { useScreenSize } from "globals/hooks";
import { OpenIcon } from "design-system/icons";

type LabelTextLinkProps = {
  text: string;
  link: string;
};

function LabelTextLink(props: LabelTextLinkProps) {
  const { text, link } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // events
  const handleOpenLink = (link) => {
    window.open(link, "_blank").focus();
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      {...(isMobileView && { justifyContent: "space-between" })}
    >
      <Typography variant="body2">{text}</Typography>
      <IconButton onClick={() => handleOpenLink(link)} sx={{ mt: -0.5 }}>
        <OpenIcon size="xs" />
      </IconButton>
    </Box>
  );
}

export default LabelTextLink;
