import React from "react";
import { Box } from "@mui/material";

import EmptyList from "components/globals/EmptyList";
import noDriverTrackingIllustration from "design-system/illustrations/noDriverTracking.svg";
import { useScreenSize } from "globals/hooks";

function NoDriverTrackingPanel() {
  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Box my={isMobileView ? 6 : 10}>
      <EmptyList
        title="Driver Tracking was not initiated for this trip"
        copy="In order to access the ride report, driver must use the driver app while completing trips."
        imageUrl={noDriverTrackingIllustration as string}
      />
    </Box>
  );
}

export default NoDriverTrackingPanel;
