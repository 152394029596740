import React from "react";

import { Box } from "@mui/material";

import DriverTrackingGoogleMap from "./DriverTrackingGoogleMap/DriverTrackingGoogleMap";
import { TrackedAsset } from "../types";

type DriverTrackingMapViewProps = {
  trackedAssets: TrackedAsset[];
};

function DriverTrackingMapView(props: DriverTrackingMapViewProps) {
  const { trackedAssets } = props;
  return (
    <Box sx={{ flex: 1, height: "100%" }}>
      <DriverTrackingGoogleMap trackedAssets={trackedAssets} />
    </Box>
  );
}

export default DriverTrackingMapView;
