import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMutation } from "@apollo/client";
import { useDebounce } from "use-debounce";

import { Box, Typography, Divider } from "@mui/material";

import {
  LabeledInlineInput,
  LabeledInlineAbsoluteNumberInput,
} from "../../../design-system/components/inputs";
import { UPDATE_TRIP_MUTATION } from "../../../globals/graphql";
import { Trip, TripCategory } from "../../../types";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import LuggageMenu from "../luggage/LuggageMenu";
import { LuggageEnum, LuggageFields } from "../luggage/types";
import { useOperator } from "globals/hooks";

type TripDetailsUpdateBlockProps = {
  trip: Trip;
  setSaveIndicatorState: (
    saveState: "loading" | "default" | "saved" | "error"
  ) => void;
  adjustLuggageAmount: (luggageAmount: LuggageFields) => void;
  sectionName?: string;
};

function TripDetailsUpdateBlock(props: TripDetailsUpdateBlockProps) {
  const {
    trip,
    setSaveIndicatorState,
    adjustLuggageAmount,
    sectionName = "Trip Details",
  } = props;

  // hooks
  const snackbar = useSnackbar();
  const operator = useOperator();

  // state
  const [tripState, setTripState] = useState({
    note: trip.note || "",
    totalGroupSize: trip.totalGroupSize || "",
  });
  const [luggageAmount, setLuggageAmount] = useState({
    [LuggageEnum.carryOnLuggage]: trip.routes[0].carryOnLuggage,
    [LuggageEnum.checkedLuggage]: trip.routes[0].checkedLuggage,
    [LuggageEnum.oversizeLuggage]: trip.routes[0].oversizeLuggage,
  });

  // derived state
  const [debouncedTrip] = useDebounce(tripState, 500);
  const initialMountFlag = useRef(false);
  const [debouncedLuggageAmount] = useDebounce(luggageAmount, 500);
  const isShuttleTrip = useMemo(
    () =>
      [TripCategory.ShuttlePickUp, TripCategory.ShuttleReturn].includes(
        trip.tripCategory
      ),
    [trip]
  );

  // Luggage settings on or off don't matter here. During update, if luggage counts are all 0, luggage menu will now show
  const showLuggageMenu =
    operator?.settings?.luggageEnabled ||
    trip.routes[0].carryOnLuggage !== 0 ||
    trip.routes[0].checkedLuggage !== 0 ||
    trip.routes[0].oversizeLuggage !== 0;

  // mutations
  const [updateTrip] = useMutation(UPDATE_TRIP_MUTATION, {
    refetchQueries: ["Request"],
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError() {
      snackbar.error("Error updating trip");
      setSaveIndicatorState("error");
    },
  });

  // event handlers
  const handleDebouncedUpdateTrip = useCallback(() => {
    setSaveIndicatorState("loading");

    updateTrip({
      variables: {
        input: {
          ...debouncedTrip,
          totalGroupSize: Number(debouncedTrip.totalGroupSize),
          id: trip.id,
        },
      },
    });
  }, [debouncedTrip, trip.id, setSaveIndicatorState, updateTrip]);

  const handleTripDetailsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTripState({
      ...tripState,
      [event.target.name]: event.target.value,
    });
  };

  const handleDebouncedLuggageAmount = useCallback(() => {
    adjustLuggageAmount(debouncedLuggageAmount);
  }, [adjustLuggageAmount, debouncedLuggageAmount]);

  useEffect(() => {
    // prevents updating on initial load
    if (!initialMountFlag.current) {
      initialMountFlag.current = true;
    } else {
      handleDebouncedUpdateTrip();
      handleDebouncedLuggageAmount();
    }
  }, [debouncedTrip, handleDebouncedUpdateTrip, handleDebouncedLuggageAmount]);

  return (
    <Box>
      <Box mt={4} mb={1.5}>
        <Typography variant="h5">{sectionName}</Typography>
      </Box>
      <Box mb={1}>
        <Divider />
      </Box>
      <Box display="flex" flexDirection="column">
        {!isShuttleTrip && (
          <LabeledInlineAbsoluteNumberInput
            name="totalGroupSize"
            value={tripState.totalGroupSize}
            label="Trip Passenger Count"
            onChange={handleTripDetailsChange}
            placeholder="-"
          />
        )}

        <LabeledInlineInput
          name="note"
          value={tripState.note}
          label="Trip Notes"
          onChange={handleTripDetailsChange}
          multiline
          placeholder="-"
        />

        {showLuggageMenu && !isShuttleTrip && (
          <LuggageMenu
            luggageAmount={luggageAmount}
            setLuggageAmount={setLuggageAmount}
          />
        )}
      </Box>
    </Box>
  );
}

export default TripDetailsUpdateBlock;
