import React, { ReactNode } from "react";

import { Box } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";

type UpgradePlanDialogProps = {
  open: boolean;
  onUpgrade: () => void;
  onClose: () => void;
  body: ReactNode;
};

function UpgradePlanDialog(props: UpgradePlanDialogProps) {
  const { open, onUpgrade, onClose, body } = props;

  // event handlers
  const handleUpgradePlan = () => {
    onUpgrade();
    onClose();
  };

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      onAccept={handleUpgradePlan}
      hideTopBorder
      dialogTitle="Upgrade Plan"
      acceptButtonText="Upgrade Plan"
      closeButtonText="Cancel"
      size="xs"
    >
      <Box mb={3}>{body}</Box>
    </MoovsDialog>
  );
}

export default UpgradePlanDialog;
