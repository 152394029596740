import React from "react";
import { DeepPartial } from "ts-essentials";

import { Button, Typography } from "@mui/material";

import LinkCompanyIcon from "design-system/icons/actions/LinkCompanyIcon";
import { moovsBlueLight, moovsBlueSelected } from "design-system/colors";
import { Company } from "types";
import { useScreenSize } from "globals/hooks";

type LinkCompanyButtonProps = {
  company: DeepPartial<Company>;
  onLinkCompany: () => void;
  isQuote: boolean;
};

function LinkCompanyButton(props: LinkCompanyButtonProps) {
  const { company, onLinkCompany, isQuote } = props;

  const mode = isQuote ? "quote" : "reservation";

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Button
      sx={{
        background: moovsBlueSelected,
        border: `1px solid ${moovsBlueLight}`,
        borderRadius: "4px",
        width: isMobileView ? "340px" : "310px",
        ...(isMobileView && { height: "128px" }),
      }}
      onClick={onLinkCompany}
    >
      <LinkCompanyIcon size="large" />
      <Typography px={0.5}>
        Link {mode} to {company?.name}
      </Typography>
    </Button>
  );
}

export default LinkCompanyButton;
