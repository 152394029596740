import React from "react";

import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { currency } from "globals/utils/helpers";

function TotalPayoutColumn(props: GridRenderCellParams<string>) {
  const { row } = props;
  return (
    <Typography variant="body2">{currency(row.totalPayoutAmount)}</Typography>
  );
}

export default TotalPayoutColumn;
