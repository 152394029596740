import React, { Dispatch, SetStateAction, useMemo, useState } from "react";

import { Box, Button, Divider, Typography } from "@mui/material";

import PaymentMethodListItem from "./PaymentMethodListItem";
import { Contact, PaymentMethod } from "../../../types";
import {
  AffiliatePayerProps,
  CompanyPayerProps,
  ContactPayerProps,
} from "./types";
import CreateCreditCardDialog from "./CreateCreditCardDialog/CreateCreditCardDialog";
import { useScreenSize } from "globals/hooks";

type PaymentMethodListProps = {
  paymentMethods?: Partial<PaymentMethod>[];
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
  payer: AffiliatePayerProps | ContactPayerProps | CompanyPayerProps;
  contact?: Contact;
};

function PaymentMethodList(props: PaymentMethodListProps) {
  const { paymentMethods, setSaveIndicatorState, payer, contact } = props;
  const { isMobileView } = useScreenSize();

  const [creditCardDialogOpen, setCreditCardDialogOpen] = useState(false);

  const linkedPassengerIds = useMemo(
    () => contact?.linkedPassengers?.map((passenger) => passenger.id) || [],
    [contact]
  );

  const toggleCreditCardDialog = () => {
    setCreditCardDialogOpen(!creditCardDialogOpen);
  };

  const handleCloseCreditCardDialog = () => {
    toggleCreditCardDialog();
  };

  return (
    <>
      <Box mb={3}>
        <Typography variant="h3">Credit Cards</Typography>
      </Box>
      {payer.payerType !== "contact" && (
        <Box pb={2} mt={-2}>
          <Divider />
        </Box>
      )}

      {paymentMethods?.map((paymentMethod) => (
        <Box mb={1} key={paymentMethod.id}>
          <PaymentMethodListItem
            paymentMethod={paymentMethod}
            editableProps={{
              setSaveIndicatorState,
              payer,
              linkedPassengerIds,
              contact,
            }}
          />
        </Box>
      ))}
      <Box mt={2}>
        <Button
          color="primary"
          variant="outlined"
          disableElevation
          onClick={toggleCreditCardDialog}
          fullWidth={isMobileView ? true : false}
        >
          Add Card
        </Button>
      </Box>

      <CreateCreditCardDialog
        open={creditCardDialogOpen}
        onClose={handleCloseCreditCardDialog}
        setSaveIndicatorState={setSaveIndicatorState}
        payer={payer}
      />
    </>
  );
}

export default PaymentMethodList;
