import moment from "moment";
import React from "react";

import { IconNames } from "../../utils/types";
import DynamicPricingChip from "../DynamicPricingChip";

type TimeChipProps = {
  startTime: String;
  endTime: String;
};

function TimeChip(props: TimeChipProps) {
  const { startTime, endTime } = props;

  if (!startTime || !endTime) return null;

  const formattedStartTime = moment(startTime?.slice(0, -5), "HH:mm:ss").format(
    "hh:mm A"
  );
  const formattedEndTime = moment(endTime?.slice(0, -5), "HH:mm:ss").format(
    "hh:mm A"
  );

  const label = `${formattedStartTime} - ${formattedEndTime}`;

  return <DynamicPricingChip label={label} iconName={IconNames.time} />;
}

export default TimeChip;
