import React from "react";
import { useParams } from "react-router-dom";

import {
  Box,
  IconButton,
  Typography,
  Divider,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";

import RoundChip from "design-system/components/chips/RoundChip";
import { alabaster, granite, blueLabelText } from "design-system/colors";
import BillingDisplay from "components/common/BillingDisplay";
import { EditIcon, TripIcon } from "design-system/icons";
import { Request, RequestStage } from "types";
import { orderTypeEnumToNameMap } from "globals/utils/enumMaps";
import MoovsEllipsisMenu, {
  EllipsisMenuOption,
} from "components/MoovsEllipsisMenu";
import EditOrderTypeDialog from "../../../EditOrderTypeDialog";
import BookingContact from "components/contacts/bookingContact/BookingContact";
import { deriveBookingContact } from "globals/utils/farm";
import OverflowTooltip from "design-system/components/OverflowTooltip";
import { CompanyBlock } from "pages/reservations/components/TripsPanel/components/OrderSummary/components";
import { QuoteTripItemsDesktop, SalesAutomationDialog } from "./components";
import { QuoteTripItemsMobile } from "../QuoteOverviewMobile/components";

const WHITE_LABEL_OPERATOR_NAME_MAX_WIDTH = "80px";

function QuoteTripTableHeaders() {
  return (
    <Box
      display="flex"
      flexDirection="row"
      flex="1"
      px={3}
      justifyContent="space-around"
      mb={1}
    >
      <Box display="flex" flex=".6">
        <Typography variant="overline">date & time</Typography>
      </Box>

      <Box display="flex" flex={0.3}>
        <Typography variant="overline">amount</Typography>
      </Box>

      <Box mx={1} />
      <Divider
        style={{ visibility: "hidden" }}
        orientation="vertical"
        flexItem
      />
      <Box mx={1} />

      <Box display="flex" flex=".5">
        <Typography variant="overline">conf. no.</Typography>
      </Box>

      <Box display="flex" flex="1">
        <Typography variant="overline">vehicle</Typography>
      </Box>

      <Box display="flex" flex="1">
        <Typography variant="overline">passenger</Typography>
      </Box>

      <Box display="flex" flex=".5"></Box>
    </Box>
  );
}

type QuoteOverviewDesktopProps = {
  ellipsisMenuOptions?: EllipsisMenuOption[];
  request: Request;
  editOrderTypeDialogOpen: boolean;
  setEditOrderTypeDialogOpen: (e: any) => void;
  whiteLabelOperatorName: string | undefined;
  onEditOrderType: () => void;
  salesAutomationOpen: boolean;
  handleSalesAutomationClose: () => void;
};

function QuoteOverviewDesktop(props: QuoteOverviewDesktopProps) {
  const {
    ellipsisMenuOptions,
    request,
    editOrderTypeDialogOpen,
    setEditOrderTypeDialogOpen,
    whiteLabelOperatorName,
    onEditOrderType,
    salesAutomationOpen,
    handleSalesAutomationClose,
  } = props;

  // hooks
  const { requestId } = useParams<{ requestId: string }>();

  // derived state
  const { orderNumber, trips = [], totalAmount, orderType } = request;
  const bookingContact = deriveBookingContact(request);
  const company = request.company || request.bookingContact.company;

  // set for when QuoteTripItemsDesktop begins to get cut off
  const isTabletView = useMediaQuery("(max-width:1290px)", {
    noSsr: true,
  });

  if (!request) {
    return (
      <Box
        width="100%"
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={40} thickness={2} />
      </Box>
    );
  }

  return (
    <Box
      width="100%"
      height="calc(100vh - 78px)"
      overflow="auto"
      data-testid="quote-overview"
    >
      {/* Header Info - Top Row*/}
      <Box zIndex={10} bgcolor={alabaster} position="sticky" top="0">
        <Box display="flex" justifyContent="space-between" py={2} mx={3}>
          <Box display="flex">
            <Box mr={2} display="flex" alignItems="center">
              <Typography variant="h1">{orderNumber}</Typography>
            </Box>
            <IconButton disabled size="small">
              <TripIcon size="small" />
            </IconButton>
            <Box mx={1.5} display="flex" alignItems="center">
              <Typography variant="overline" style={{ color: granite }} noWrap>
                {trips.length} {trips.length === 1 ? "TRIP" : "TRIPS"}
              </Typography>
            </Box>
            <Box mx={2} display="flex" alignItems="center">
              <Typography variant="overline" style={{ color: granite }}>
                {orderTypeEnumToNameMap[orderType]}
              </Typography>
              <IconButton onClick={onEditOrderType}>
                <EditIcon color={blueLabelText} />
              </IconButton>
            </Box>
            {whiteLabelOperatorName && (
              <Box
                display="flex"
                alignItems="center"
                maxWidth={WHITE_LABEL_OPERATOR_NAME_MAX_WIDTH}
              >
                <OverflowTooltip
                  toolTipText={`Sourced by ${whiteLabelOperatorName}`}
                  displayText={whiteLabelOperatorName}
                  variant="caption"
                  placement="top"
                />
              </Box>
            )}
          </Box>
          <Box display="flex">
            <Box
              display="flex"
              flex=".6"
              justifyContent="flex-end"
              alignItems="center"
            >
              <RoundChip
                variant={
                  request.isExpired
                    ? "EXPIRED"
                    : request.isArchived
                    ? "ARCHIVED"
                    : request.status
                }
              />
            </Box>
            {ellipsisMenuOptions && (
              <MoovsEllipsisMenu
                variant="withMoreText"
                options={ellipsisMenuOptions}
              />
            )}
          </Box>
        </Box>
        <Box mx={3}>
          <Divider />
        </Box>
      </Box>

      {/* Header Contact and Amount */}
      <Box display="flex" mt={2} mb={5} mx={3}>
        {/* Booking Contact */}
        <BookingContact
          requestStage={RequestStage.Quote}
          requestId={requestId}
          bookingContact={bookingContact}
          canEditBookingContact
          companyId={request.company?.id}
        />

        {company && (
          <Box mx={3}>
            <CompanyBlock
              requestId={request.id}
              isLinkedCompanyRequest={!!request.company}
              companyName={company.name}
              stage={request.stage}
            />
          </Box>
        )}

        {/* total amount */}
        <Box ml={5}>
          <Box mb="1px">
            <Typography variant="overline">total amount</Typography>
          </Box>
          <BillingDisplay amount={totalAmount} />
        </Box>
      </Box>

      <Box mx={3} pb={2}>
        {isTabletView ? (
          <QuoteTripItemsMobile request={request} />
        ) : (
          <Box minWidth="600px" maxWidth="1000px">
            <QuoteTripTableHeaders />
            <QuoteTripItemsDesktop request={request} />
          </Box>
        )}
      </Box>

      <EditOrderTypeDialog
        open={editOrderTypeDialogOpen}
        onClose={() => setEditOrderTypeDialogOpen(false)}
        requestId={requestId}
        orderType={orderType}
      />

      <SalesAutomationDialog
        open={salesAutomationOpen}
        onClose={handleSalesAutomationClose}
        requestId={request.id}
        orderNumber={orderNumber}
      />
    </Box>
  );
}

export default QuoteOverviewDesktop;
