import React from "react";

import { fromGlobalId } from "../../../../../../../globals/utils/helpers";
import { useCurrentUser } from "globals/hooks";
import { getAffiliateId } from "components/chat/utils";

import { Divider, Menu, Typography } from "@mui/material";

import { grayDark } from "design-system/colors";
import {
  KnowledgeBaseIcon,
  UserIcon,
  InfoIcon,
  MailReferIcon,
} from "design-system/icons";
import HelpMenuItem from "./HelpMenuItem";

type HelpMenuProps = { anchorEl: HTMLElement; handleClose?: () => void };

function HelpMenu(props: HelpMenuProps) {
  const { anchorEl, handleClose } = props;
  const { operatorId } = useCurrentUser() || {};
  const affiliateId = getAffiliateId(operatorId);
  const operatorIdActual = fromGlobalId(affiliateId).id


  return (
    <Menu
      id="help-fab-menu"
      anchorEl={anchorEl}
      keepMounted
      open={!!anchorEl}
      onClick={handleClose}
      sx={{
        "& .MuiList-root": {
          padding: 0,
          margin: 0.5,
          width: 200,
        },
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left", // or right depending on preference
      }}
    >
      {/* Knowledge Base */}
      <HelpMenuItem
        name="Knowledge Base"
        onClick={handleClose}
        link="https://intercom.help/moovs-05c940f1970e/en/collections/3513392-moovs-software"
        icon={KnowledgeBaseIcon}
        analyticsName="helpFAB_knowledgeBaseClicked"
      />

      {/* Chat with Moovs */}
      <HelpMenuItem
        //div with id supports intercom integration
        id="fab-help"
        name="Chat with Moovs"
        onClick={handleClose}
        icon={UserIcon}
        analyticsName="helpFAB_chatWithMoovsClicked"
      />

      <Divider
        sx={{
          "&.MuiDivider-root": {
            m: 0.5,
          },
        }}
      />

      {/* What's New? */}
      <HelpMenuItem
        name="What's New?"
        onClick={handleClose}
        link="https://www.notion.so/swoopapp/Change-Log-What-s-New-e086bd2f2ece4fdb9bea664956292ef8"
        icon={InfoIcon}
        analyticsName="helpFAB_whatsNewClicked"
      />

      {/* Refer a Friend */}
      <HelpMenuItem
        name="Refer a Friend"
        onClick={handleClose}
        link={`https://swoop.retool.com/p/moovs-referrals?operator_id=${operatorIdActual}`}
        icon={MailReferIcon}
        analyticsName="helpFAB_referAFriendClicked"
      />

      <Divider
        sx={{
          "&.MuiDivider-root": {
            m: 0.5,
          },
        }}
      />

      {/* App Version */}
      <Typography
        variant="caption"
        sx={{
          color: grayDark,
          margin: 2,
          display: "flex",
          flexDirection: "row",
        }}
      >
        Version: {process.env.REACT_APP_VERSION}
      </Typography>
    </Menu>
  );
}

export default HelpMenu;
