// initial create promo code input that will be updated as user moves from one step/form to another
export const initialCreatePromoCodeInputs = {
  anytime: {
    isPercent: false,
    amount: 0,
    name: "",
    isTotalLimited: true,
    isBookingContactLimited: true,
    totalLimit: 500,
    bookingContactLimit: 1,
  },
  bookingDate: {
    isPercent: false,
    amount: 0,
    name: "",
    bookingStartDate: null,
    bookingEndDate: null,
    isTotalLimited: true,
    isBookingContactLimited: true,
    totalLimit: 500,
    bookingContactLimit: 1,
  },
  tripDate: {
    isPercent: false,
    amount: 0,
    name: "",
    tripStartDate: null,
    tripEndDate: null,
    isTotalLimited: true,
    isBookingContactLimited: true,
    totalLimit: 500,
    bookingContactLimit: 1,
  },
};
