import React, { createRef, useEffect, useState } from "react";
import { DeepPartial } from "ts-essentials";
import { DragDropContext } from "react-beautiful-dnd";
import isEqual from "lodash/isEqual";
import last from "lodash/last";

import { Box } from "@mui/material";

import { Trip, Stop } from "types";
import TripInfoCreateBlockItem from "../../TripInfoCreateBlockItem";
import {
  checkIfStopTimesAreInOrder,
  reorderStops,
} from "globals/utils/helpers";
import { useSnackbar } from "globals/hooks/useSnackbar";

import AddStopButton from "../../../update/AddStopButton";

type TripInfoCreateBlockType = {
  trip: DeepPartial<Trip>;
  updateTrip: (newTrip: DeepPartial<Trip>, tripIndex: number) => void;
  onCreateStop: () => void;
  onRemoveStop: (stopIndex: number) => () => void;
  tripIndex: number;
  requestErrors: any;
  setRequestErrors: any;
  suggestedAddressInfo: {
    firstName: string;
    lastName: string;
    address: string;
    mode: string;
  }[];
  initialFocusedStopDateTimes?: string[];
  tripType?: string;
};

function TripInfoCreateBlock(props: TripInfoCreateBlockType) {
  const {
    trip,
    updateTrip,
    onCreateStop,
    onRemoveStop,
    tripIndex,
    requestErrors,
    setRequestErrors,
    initialFocusedStopDateTimes,
    suggestedAddressInfo,
    tripType,
  } = props;

  const snackbar = useSnackbar();

  // state
  const [stopRefs, setStopRefs] = useState([]);
  const [reorderedStops, setReorderedStops] = useState<any[]>(null);
  const [draggableIndexes, setDraggableIndexes] = useState<{
    sourceIndex: number;
    destinationIndex: number;
  }>(null);
  const [renderWarningForStop, setRenderWarningForStop] = useState<boolean[]>(
    []
  );

  // event handlers
  const handleStopChange = (stopIndex: number) => (newStop: Partial<Stop>) => {
    const newStops = trip.stops.map((stop: Partial<Stop>, j: number) => {
      if (j !== stopIndex - 1) return stop;

      return {
        ...stop,
        ...newStop,
      };
    });

    updateTrip(
      {
        ...trip,
        stops: newStops,
      },
      tripIndex
    );
  };

  const handleScrollToStop = (ref) => {
    ref?.current?.focus();
    ref?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const handleDragEnd = (e) => {
    const { destination, source } = e;

    if (!destination) {
      return;
    }

    const nextStops = reorderStops(trip.stops, source.index, destination.index);

    updateTrip(
      {
        ...trip,
        stops: nextStops,
      },
      tripIndex
    );

    // prevents stops from flashing
    setTimeout(() => {
      setDraggableIndexes(null);
      setReorderedStops(null);
    }, 0);
  };

  const handleDragUpdate = (e) => {
    const { destination, source } = e;

    if (!destination) {
      setReorderedStops(null);
      setDraggableIndexes(null);
      return;
    }

    const nextStops = reorderStops(trip.stops, source.index, destination.index);

    setReorderedStops(nextStops);
    setDraggableIndexes({
      sourceIndex: source.index,
      destinationIndex: destination.index,
    });
  };

  // effects
  useEffect(() => {
    // add or remove refs
    setStopRefs((stopRefs) => {
      const newStopRefs = trip.stops.map(createRef);

      // only focus after adding new stop, not on drawer mount
      if (stopRefs.length && trip.stops.length > stopRefs.length) {
        // we need timeout for ref.current to not be undefined
        setTimeout(
          // scroll to added stop
          () => handleScrollToStop(newStopRefs[trip.stops.length - 2]),
          0
        );
      }
      return newStopRefs;
    });
  }, [trip.stops]);

  // show/hide stop time out of order warning
  useEffect(() => {
    const stopsInOrderArray = checkIfStopTimesAreInOrder(trip.stops);

    if (!isEqual(stopsInOrderArray, renderWarningForStop)) {
      // if in previous render, any stop didn't have a warning and now does, render snackbar
      if (
        renderWarningForStop.some(
          (warning, index) => !warning && !!stopsInOrderArray[index]
        )
      ) {
        snackbar.warning("Date & times are not in order");
      }

      setRenderWarningForStop(stopsInOrderArray);
    }
  }, [setRenderWarningForStop, renderWarningForStop, snackbar, trip]);

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragUpdate={handleDragUpdate}>
      {trip.stops.map((stop: Partial<Stop>, index) => {
        const { sourceIndex, destinationIndex } = draggableIndexes || {};
        let nextStop;

        if (
          draggableIndexes &&
          reorderedStops &&
          destinationIndex !== sourceIndex
        ) {
          nextStop = reorderedStops[index];
        }

        return (
          <Box mb={3} key={stop.id}>
            <TripInfoCreateBlockItem
              showIncorrectStopOrderWarning={!!renderWarningForStop[index]}
              addressRef={stopRefs[index]}
              onStopChange={handleStopChange}
              stop={stop}
              firstStop={trip.stops[0]}
              canRemoveStop={
                stop.stopIndex !== 1 && stop.stopIndex !== trip.stops.length
              }
              stopsLength={trip.stops.length}
              requestErrors={requestErrors}
              setRequestErrors={setRequestErrors}
              initialFocusedStopDateTime={
                !!initialFocusedStopDateTimes
                  ? initialFocusedStopDateTimes[index]
                  : ""
              }
              suggestedAddressInfo={suggestedAddressInfo}
              onRemoveStop={onRemoveStop(stop.stopIndex)}
              tripType={tripType}
              dropOff={last(trip.stops) as Stop}
              // draggable props
              nextStop={nextStop}
              isSourceIndex={sourceIndex === index}
              isDestinationIndex={destinationIndex === index}
            />

            {index === trip.stops.length - 2 && (
              <AddStopButton onClick={onCreateStop} />
            )}
          </Box>
        );
      })}
    </DragDropContext>
  );
}

export default TripInfoCreateBlock;
