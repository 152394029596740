import React, { useState } from "react";

import { Grid } from "@mui/material";

import { GriddnetOperatorIcon } from "../../../design-system/icons";
import ManageGriddnetDialog from "./ManageGriddnetDialog";
import { useScreenSize } from "globals/hooks";
import AffiliateMapButton from "../AffiliateMapButton";
import MoovsEllipsisMenu from "components/MoovsEllipsisMenu";

function FarmAffiliatesEllipsisMenu() {
  // hooks
  const { isMobileView } = useScreenSize();

  // state
  const [manageGriddnetDialogOpen, setManageGriddnetDialogOpen] =
    useState(false);

  // event handlers
  const handleManageGriddnetIdMenuItemClick = () => {
    setManageGriddnetDialogOpen(true);
  };

  const handleManageGriddnetDialogClose = () => {
    setManageGriddnetDialogOpen(false);
  };

  return (
    <Grid justifyContent="flex-end">
      {isMobileView && <AffiliateMapButton />}
      <MoovsEllipsisMenu
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // needed to position menu popover
        transformOrigin={{ vertical: "top", horizontal: "center" }} // needed to position menu popover
        options={[
          {
            icon: <GriddnetOperatorIcon size="small" />,
            text: "Manage GNet ID",
            onClick: handleManageGriddnetIdMenuItemClick,
          },
        ]}
      />

      <ManageGriddnetDialog
        open={manageGriddnetDialogOpen}
        onClose={handleManageGriddnetDialogClose}
      />
    </Grid>
  );
}

export default FarmAffiliatesEllipsisMenu;
