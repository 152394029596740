import React from "react";

import StepConnector, { StepConnectorProps } from "@mui/material/StepConnector";

import { moovsBlue, moovsLiteBlue } from "design-system/colors";

function ProgressConnector(props: StepConnectorProps) {
  return (
    <StepConnector
      sx={{
        "&.Mui-active": {
          "& .MuiStepConnector-line": {
            top: "7px",
            width: "20px",
            left: "135px",
            border: `1.5px solid ${moovsBlue}`,
            borderRadius: "5px",
          },
        },
        "&.Mui-completed": {
          "& .MuiStepConnector-line": {
            top: "7px",
            width: "20px",
            left: "135px",
            border: `1.5px solid ${moovsBlue}`,
            borderRadius: "5px",
          },
        },
        "& .MuiStepConnector-line": {
          top: "7px",
          backgroundColor: moovsLiteBlue,
          width: "20px",
          left: "135px",
        },
      }}
      {...props}
    />
  );
}

export default ProgressConnector;
