import React from "react";

const NoReviewBadgeIcon = () => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="72" height="72" rx="36" fill="#E5EEFF" />
      <path
        d="M50.5 39.668L54.7811 45.1682L48.6874 46.3557L46.2499 53.4807L41 46.3557"
        stroke="#739CFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2812 39.668L18.0001 45.1682L24.0939 46.3557L26.5314 53.4807L31.7812 46.3557"
        stroke="#739CFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.2"
        d="M37.407 18.3484L39.3653 19.7555C40.0436 20.2428 40.8575 20.5056 41.6927 20.507L44.258 20.5112C44.5357 20.4978 44.8098 20.5774 45.0347 20.7366C45.2596 20.8959 45.4219 21.1253 45.4946 21.3868L46.0916 23.5342C46.3559 24.4851 46.9624 25.3046 47.7945 25.8353L49.7294 27.0694C49.9639 27.2181 50.1387 27.441 50.2245 27.7004C50.3103 27.9599 50.3019 28.2403 50.2006 28.4944L49.3994 30.5413C49.0327 31.4781 49.0325 32.5186 49.3987 33.4556L50.2006 35.507C50.3001 35.7601 50.3076 36.0388 50.2218 36.2966C50.1361 36.5545 49.9623 36.7759 49.7294 36.9241L47.7947 38.158C46.9625 38.6888 46.3559 39.5085 46.0917 40.4596L45.4946 42.6082C45.4219 42.8697 45.2596 43.0992 45.0347 43.2584C44.8098 43.4177 44.5357 43.4972 44.258 43.4838L41.8986 43.3693C40.9374 43.3227 39.9917 43.6241 39.2348 44.2182L37.407 45.653C37.19 45.8229 36.92 45.9155 36.6416 45.9155C36.3633 45.9155 36.0932 45.8229 35.8762 45.653L34.0493 44.216C33.2919 43.6203 32.3449 43.318 31.3825 43.3647L29.0252 43.4791C28.7475 43.4925 28.4735 43.4129 28.2485 43.2537C28.0236 43.0944 27.8613 42.865 27.7886 42.6035L27.1916 40.4548C26.9273 39.5038 26.3207 38.684 25.4885 38.1532L23.5539 36.9193C23.3209 36.7712 23.1471 36.5497 23.0614 36.2919C22.9757 36.034 22.9831 35.7554 23.0826 35.5022L23.884 33.455C24.2505 32.5184 24.2509 31.4781 23.8848 30.5413L23.0826 28.4881C22.9814 28.2339 22.9729 27.9536 23.0587 27.6941C23.1445 27.4346 23.3194 27.2118 23.5539 27.0631L25.4887 25.829C26.3208 25.2983 26.9273 24.4787 27.1917 23.5279L27.7886 21.3805C27.8613 21.119 28.0236 20.8896 28.2485 20.7303C28.4735 20.571 28.7475 20.4915 29.0252 20.5049L31.384 20.6233C32.3455 20.6715 33.2922 20.3714 34.0502 19.7779L35.8762 18.3484C36.0932 18.1785 36.3633 18.0859 36.6416 18.0859C36.92 18.0859 37.19 18.1785 37.407 18.3484Z"
        fill="url(#paint0_linear_5610_86804)"
      />
      <path
        d="M37.2654 18.1179L40.2668 20.4676L44.1164 20.2808C44.3941 20.2674 44.6682 20.3469 44.8931 20.5062C45.118 20.6654 45.2803 20.8949 45.353 21.1564L46.3605 24.7806L49.5878 26.8389C49.8223 26.9877 49.9971 27.2105 50.0829 27.47C50.1687 27.7294 50.1603 28.0098 50.059 28.2639L48.6875 31.7679L50.059 35.2765C50.1585 35.5296 50.166 35.8083 50.0802 36.0662C49.9945 36.324 49.8207 36.5454 49.5878 36.6936L46.3605 38.7519L45.353 42.3778C45.2803 42.6393 45.118 42.8687 44.8931 43.028C44.6682 43.1872 44.3941 43.2668 44.1164 43.2534L40.2668 43.0665L37.2654 45.4225C37.0484 45.5924 36.7784 45.685 36.5 45.685C36.2217 45.685 35.9516 45.5924 35.7346 45.4225L32.7333 43.0618L28.8836 43.2486C28.6059 43.262 28.3319 43.1825 28.1069 43.0232C27.882 42.8639 27.7197 42.6345 27.647 42.373L26.6395 38.7472L23.4123 36.6889C23.1793 36.5407 23.0055 36.3192 22.9198 36.0614C22.8341 35.8036 22.8415 35.5249 22.941 35.2718L24.3125 31.7679L22.941 28.2576C22.8398 28.0035 22.8313 27.7231 22.9171 27.4636C23.0029 27.2041 23.1778 26.9813 23.4123 26.8326L26.6395 24.7743L27.647 21.15C27.7197 20.8885 27.882 20.6591 28.1069 20.4998C28.3319 20.3406 28.6059 20.261 28.8836 20.2744L32.7333 20.4676L35.7346 18.1179C35.9516 17.9481 36.2217 17.8555 36.5 17.8555C36.7784 17.8555 37.0484 17.9481 37.2654 18.1179V18.1179Z"
        stroke="#195FE9"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.5002 37.668L30.9607 32.0804C30.4738 31.61 30.1525 31.0033 30.0423 30.3463C29.9321 29.6894 30.0386 29.0156 30.3468 28.4205C30.579 27.9712 30.9182 27.5817 31.3366 27.2842C31.755 26.9866 32.2406 26.7894 32.7532 26.7089C33.2659 26.6284 33.791 26.6669 34.2852 26.8212C34.7795 26.9755 35.2287 27.2411 35.596 27.5963L36.5002 28.4707L37.4045 27.5963C37.7717 27.2411 38.221 26.9755 38.7153 26.8212C39.2095 26.6669 39.7346 26.6284 40.2473 26.7089C40.7599 26.7894 41.2454 26.9866 41.6638 27.2842C42.0822 27.5817 42.4215 27.9712 42.6537 28.4205C42.9615 29.0154 43.0679 29.6889 42.9577 30.3456C42.8475 31.0022 42.5264 31.6087 42.0398 32.079L36.5002 37.668Z"
        fill="white"
        stroke="#195FE9"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5610_86804"
          x1="44.2468"
          y1="43.8402"
          x2="44.2468"
          y2="17.8773"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#195FE9" />
          <stop offset="1" stop-color="#87AAFF" stop-opacity="0.62" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NoReviewBadgeIcon;
