import React, { Dispatch, SetStateAction } from "react";

import { Box, Typography } from "@mui/material";

import DriverGratuity from "./components/DriverGratuity";
import HourlyTrip from "./components/HourlyTrip";
import OneWayTrip from "./components/OneWayTrip";
import { useScreenSize } from "globals/hooks";
import { CreateDriverInput, UpdateDriverInput } from "types";
import { FormVariant } from "./utils/types";

type DriverPayoutFormProps = {
  driver: CreateDriverInput | UpdateDriverInput;
  setDriver: Dispatch<SetStateAction<CreateDriverInput | UpdateDriverInput>>;
  variant?: FormVariant;
};

function DriverPayoutForm(props: DriverPayoutFormProps) {
  const { driver, setDriver, variant } = props;

  // hook
  const { isSmallTabletView } = useScreenSize();

  return (
    <>
      <Box mb={3}>
        <Box mb={isSmallTabletView ? 2 : 1}>
          <Typography variant="h4">Hourly Trips</Typography>
        </Box>
        <HourlyTrip driver={driver} setDriver={setDriver} variant={variant} />
      </Box>
      <Box mb={3}>
        <Box mb={isSmallTabletView ? 2 : 1}>
          <Typography variant="h4">One-Way Trips</Typography>
        </Box>
        <OneWayTrip driver={driver} setDriver={setDriver} variant={variant} />
      </Box>
      <Box mb={3}>
        <Box mb={2}>
          <Typography variant="h4">Driver Gratuity</Typography>
        </Box>
        <DriverGratuity
          driver={driver}
          setDriver={setDriver}
          variant={variant}
        />
      </Box>
    </>
  );
}

export default DriverPayoutForm;
