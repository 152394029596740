import React from "react";
import { useForm, Control, FieldErrors } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Typography } from "@mui/material";

import { dateDefaultValues } from "./defaultValues";
import { dateSchema } from "./schemaValidation";
import { CalendarIcon } from "design-system/icons";
import ArrowDownIcon from "design-system/icons/layout/ArrowDownIcon";
import RHFDatePicker from "components/reactHookFormInputs/RHFDatePicker";

type DateFormValues = {
  startDate: Date;
};

type DateFormProps = {
  control: Control<DateFormValues>;
  errors: FieldErrors;
};

function DateForm(props: DateFormProps) {
  const { control, errors } = props;

  return (
    <>
      <Box style={{ marginBottom: "50px" }}>
        <Typography variant="body1">Choose a date</Typography>
      </Box>
      <Box style={{ marginBottom: "100px" }}>
        <RHFDatePicker
          control={control}
          name="startDate"
          errorMessage={errors.startDate?.message}
          textFieldPlaceholder="Date"
          datePickerInputFormat="MM/DD/YYYY"
          startAdornment={<CalendarIcon />}
          endAdornment={<ArrowDownIcon />}
        />
      </Box>
    </>
  );
}

function useDateForm() {
  // setup react hook form
  const {
    control,
    formState: { errors },
    handleSubmit: handleDateSubmit,
    reset: resetDateForm,
  } = useForm({
    defaultValues: dateDefaultValues,
    resolver: yupResolver(dateSchema),
  });

  return {
    handleDateSubmit,
    resetDateForm,
    dateFormComponent: <DateForm control={control} errors={errors} />,
  };
}

export default useDateForm;
