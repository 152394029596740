/**
 * @file HelpFAB.tsx
 * FAB used for triggering help menu.
 * Replaces Intercom's FAB.
 */

import React from "react";
import { useLocation } from "react-router-dom";

import { Fab, Typography } from "@mui/material";

import { black, granite, white } from "design-system/colors";
import theme from "theme";
import { useScreenSize } from "globals/hooks";
import { HelpMenu } from "./components";

const FIXED_FOOTER_HEIGHT = 79;

function HelpFAB() {
  // hooks
  const { isMobileView } = useScreenSize();
  const location = useLocation();

  // derived state
  // somewhat hacky way to determine if bottom fixed footer on reservations page exists. Eventually can come up with better solution.
  const hasFixedFooter =
    location.pathname.includes("reservation") &&
    location.search.includes("tripId") &&
    isMobileView;

  const isMoveHelpFabUp = isMobileView && location.pathname.includes("/create");

  // state
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // event handlers
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Fab
        aria-label="help-menu"
        onClick={handleClick}
        sx={{
          height: 40,
          width: 40,
          position: "fixed",
          zIndex: theme.zIndex.drawer + 2,
          bottom: hasFixedFooter ? FIXED_FOOTER_HEIGHT : 0,
          right: 0,
          margin: "0 auto",
          marginRight: theme.spacing(2),
          marginBottom: isMoveHelpFabUp ? theme.spacing(8) : theme.spacing(2),
          backgroundColor: granite,
          ":hover": {
            backgroundColor: black,
          },
        }}
      >
        <Typography sx={{ fontSize: 24, fontWeight: 300, color: white }}>
          ?
        </Typography>
      </Fab>
      <HelpMenu anchorEl={anchorEl} handleClose={handleClose} />
    </>
  );
}

export default HelpFAB;
