import React, { useState } from "react";

import { Box, Button, Tooltip, Typography } from "@mui/material";

import { CheckCircleIcon, InstantPayoutIcon } from "design-system/icons";
import { granite, successGreen, white } from "design-system/colors";
import { useStripeAccount } from "../../../MoovsPaymentsBlock/hooks";
import { CreateInstantPayoutDialog } from "./components";
import { useAnalytics, useScreenSize, useOperator } from "globals/hooks";
import { PlanVariant } from "types";

type StartInstantPayoutBlockProps = {
  instantAvailableBalance: number;
};

function StartInstantPayoutBlock(props: StartInstantPayoutBlockProps) {
  const { instantAvailableBalance } = props;

  // state
  const [open, setOpen] = useState(false);

  // hooks
  const { isMobileView } = useScreenSize();
  const { isDefaultBankAccountEligibleForInstant } = useStripeAccount();
  const { track } = useAnalytics();
  const operator = useOperator();

  // derived state
  const isFreePlan = operator?.plan === PlanVariant.Free;
  const hasEnoughToStartPayout = instantAvailableBalance >= 0.5;
  const canStartPayout =
    isDefaultBankAccountEligibleForInstant &&
    hasEnoughToStartPayout &&
    !isFreePlan;

  const disabledTooltip =
    // If the user is on a free plan, we don't want them to know they can just upgrade to use instant payout (fraud purposes)
    isFreePlan
      ? ""
      : isDefaultBankAccountEligibleForInstant
      ? hasEnoughToStartPayout
        ? ""
        : "You must have a balance greater than $0.50"
      : "You must have an eligible card or bank account on file to use instant payout";

  // event handlers
  const handleOpenDialog = () => {
    track("instantPayout_started");
    setOpen(true);
  };

  const handleCloseDialog = () => setOpen(false);

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Tooltip
          title={
            <Box textAlign="center">
              <Typography variant="h6">
                Unable to initiate instant payout.
              </Typography>
              <Typography variant="caption">{disabledTooltip}</Typography>
            </Box>
          }
          placement="top"
          {...(canStartPayout && {
            disableHoverListener: true,
            disableFocusListener: true,
            disableTouchListener: true,
          })}
        >
          <span>
            <Button
              color="primary"
              variant="contained"
              onClick={handleOpenDialog}
              disabled={!canStartPayout}
              startIcon={
                <InstantPayoutIcon
                  color={canStartPayout ? white : "rgba(0, 0, 0, 0.26)"}
                />
              }
              sx={{ mb: 2 }}
              {...(isMobileView && { fullWidth: true })}
            >
              Start Instant Payout
            </Button>
          </span>
        </Tooltip>

        <Box display="flex">
          <CheckCircleIcon color={successGreen} size="small" />
          <Typography variant="caption" color={granite} ml={1}>
            {"Arrives instantly (1.75% fee)"}
          </Typography>
        </Box>
      </Box>

      <CreateInstantPayoutDialog
        open={open}
        onClose={handleCloseDialog}
        instantAvailableBalance={instantAvailableBalance}
      />
    </>
  );
}

export default StartInstantPayoutBlock;
