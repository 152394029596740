import React from "react";
import { Control, FieldErrors } from "react-hook-form";

import { Box } from "@mui/material";

import RHFTimePicker from "components/reactHookFormInputs/RHFTimePicker";
import { useScreenSize } from "globals/hooks";
import { EditDynamicPricingDefaultValues } from "../useEditDynamicPricingDefaultValues";

type EndTimeProps = {
  control: Control<EditDynamicPricingDefaultValues>;
  errors: FieldErrors;
};

function EndTime(props: EndTimeProps) {
  const { errors, control } = props;

  const { isSmallTabletView } = useScreenSize();

  return (
    <Box my={1.5} width={isSmallTabletView ? "100%" : "49.5%"}>
      <RHFTimePicker
        name="endTime"
        control={control}
        label="End Time (optional)"
        errorMessage={errors.endTime?.message}
        textFieldPlaceholder="End Time (optional)"
      />
    </Box>
  );
}

export default EndTime;
