import React, { ChangeEvent, useEffect, useState } from "react";

import { Box, TextField } from "@mui/material";

import { DollarPercentVariant } from "components/pricing/utils";
import {
  NumberFormatDollar,
  NumberFormatPercent,
} from "design-system/components/inputs/NumberFormat";
import { useScreenSize } from "globals/hooks";
import { ChargePayerInputErrors } from "../types";
import DollarPercentToggle from "components/pricing/MoovsPricingForm/components/DollarPercentToggle";

type DollarPercentInputFieldProps = {
  inputDollarAmt: number;
  inputWholePercentValue: number;
  inputErrors: Pick<ChargePayerInputErrors, "inputChargeAmt">;
  onDollarInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onPercentInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onDollarPercentVariantChange: (
    dollarPercentVariant: DollarPercentVariant
  ) => void;
  defaultDollarPercentVariant?: DollarPercentVariant;
};

const { Dollar, Percent } = DollarPercentVariant;

function DollarPercentInputField(props: DollarPercentInputFieldProps) {
  const {
    onDollarInputChange,
    onPercentInputChange,
    onDollarPercentVariantChange,
    inputErrors,
    inputDollarAmt,
    inputWholePercentValue,
    defaultDollarPercentVariant,
  } = props;

  // state
  const [dollarPercentVariant, setDollarPercentVariant] = useState(
    defaultDollarPercentVariant || Dollar
  );

  // hooks
  const { isMobileView } = useScreenSize();

  // event handlers
  const handleToggle = (variant: DollarPercentVariant) => {
    setDollarPercentVariant(variant);
  };

  // effects
  // trigger dollar percent variant callback whenever dollar percent variant changes
  useEffect(() => {
    onDollarPercentVariantChange(dollarPercentVariant);
  }, [onDollarPercentVariantChange, dollarPercentVariant]);

  // derived state
  const isDollar = dollarPercentVariant === Dollar;
  const isPercent = dollarPercentVariant === Percent;

  return (
    <Box
      display="flex"
      alignItems="center"
      {...(!isMobileView && { marginRight: 1, flex: 2 })}
    >
      <Box display="flex" flex={1} gap={1}>
        <DollarPercentToggle
          dollarPercentVariant={dollarPercentVariant}
          onToggle={handleToggle}
        />
        <TextField
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          name="totalAmtToCharge"
          {...(isDollar && {
            value: inputDollarAmt,
            label: "Charge Amount",
            error: !!inputErrors.inputChargeAmt,
            helperText: inputErrors.inputChargeAmt,
            onChange: onDollarInputChange,
            InputProps: {
              inputComponent: NumberFormatDollar as any,
              inputProps: {
                prefix: "$",
              },
            },
          })}
          {...(isPercent && {
            value: inputWholePercentValue,
            label: "Charge Percent",
            error: !!inputErrors.inputChargeAmt,
            helperText: inputErrors.inputChargeAmt,
            onChange: onPercentInputChange,
            InputProps: {
              inputComponent: NumberFormatPercent as any,
              inputProps: {
                suffix: "%",
              },
            },
          })}
        />
      </Box>
    </Box>
  );
}

export default DollarPercentInputField;
