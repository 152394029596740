import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment-timezone";
import clipboardCopy from "clipboard-copy";
import { useQuery } from "@apollo/client";

import { Box, CircularProgress } from "@mui/material";

import UpdateDrawer from "../../../globals/UpdateDrawer";
import { useSnackbar } from "../../../../globals/hooks/useSnackbar";
import { LOAD_OPERATOR_DRIVER_QUERY } from "../../../../globals/graphql";
import RemoveDriverDialog from "./components/RemoveDriverDialog";
import { Driver } from "../../../../types";
import GQLQueryStatusIndicator from "../../../GQLQueryStatusIndicator";
import { CopyIcon, TrashIcon } from "../../../../design-system/icons";
import { useAnalytics } from "../../../../globals/hooks";
import UpdateDriverForm from "./components/UpdateDriverForm";

function UpdateDriverDrawer() {
  // hooks
  const history = useHistory();
  const snackbar = useSnackbar();
  const { driverId } = useParams<{ driverId: string }>();
  const { track } = useAnalytics();

  // state
  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "loading" | "saved" | "error"
  >("default");
  const [removeDriverDialogOpen, setRemoveDriverDialogOpen] =
    useState<boolean>(false);

  // queries
  const {
    data: driverData,
    loading: driverLoading,
    refetch: driverRefetch,
    error: driverError,
  } = useQuery(LOAD_OPERATOR_DRIVER_QUERY, {
    variables: {
      id: driverId,
    },
    skip: !driverId,
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
  });

  // event handlers
  const handleUpdateDriverClose = () => {
    history.push("/settings/drivers");
  };

  const handleCopyDriverAppLink = async () => {
    try {
      await clipboardCopy(
        `${process.env.REACT_APP_DRIVERAPP_URL}/driver/${driver.id}`
      );
      snackbar.success("Successfully copied driver link!");
      track("dispatch_driverLinkCopied");
    } catch (error) {
      snackbar.error("Could not copy driver link");
    }
  };

  // derived state
  const driver: Driver = driverData?.node;

  return (
    <UpdateDrawer
      onClose={handleUpdateDriverClose}
      createdAt={driver ? moment(driver.createdAt).format("LLL") : ""}
      updatedAt={driver ? moment(driver.updatedAt).format("LLL") : ""}
      saveIndicatorState={saveIndicatorState}
      ellipsisMenuOptions={[
        {
          onClick: handleCopyDriverAppLink,
          text: "Copy Driver App Link",
          icon: <CopyIcon size="small" />,
        },
        {
          onClick: () => {
            setRemoveDriverDialogOpen(true);
          },
          text: "Delete Driver",
          icon: <TrashIcon size="small" />,
        },
      ]}
    >
      {driverLoading && (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={40} thickness={2} />
        </Box>
      )}

      {driverError && !driverLoading && (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <GQLQueryStatusIndicator
            name="Driver"
            data={driverData}
            error={driverError}
            refetch={driverRefetch}
          />
        </Box>
      )}
      {driver && (
        <>
          <UpdateDriverForm
            driver={driver}
            setSaveIndicatorState={setSaveIndicatorState}
          />
          <RemoveDriverDialog
            open={removeDriverDialogOpen}
            handleClose={() => setRemoveDriverDialogOpen(false)}
            driverId={driverId}
          />
        </>
      )}
    </UpdateDrawer>
  );
}

export default UpdateDriverDrawer;
