import React from "react";
import { ApolloError } from "@apollo/client";

import {
  CircularProgress,
  Box,
  Accordion as MuiAccordion,
} from "@mui/material";

import { withStyles } from "@mui/styles";

import { grayLight } from "design-system/colors";
import theme from "theme";
import {
  StripePayout,
  StripePayoutsConnection,
  StripePayoutsEdge,
} from "types";
import { useScreenSize } from "globals/hooks";
import MoovsInfiniteScroll from "components/MoovsInfiniteScroll";
import GQLQueryStatusIndicator from "components/GQLQueryStatusIndicator";
import {
  AccordionDetailsDesktop,
  AccordionSummaryDesktop,
  InstantPayoutsListItemDesktop,
  InstantPayoutsListItemMobile,
  PayoutsListItemMobile,
} from "./components";

const Accordion = withStyles({
  root: {
    border: `1px solid ${grayLight}`,
    borderRadius: "4px",
    boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.03)",
    "&:not(:last-child)": {
      borderRadius: "4px",
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto auto 8px auto",
    },
    marginBottom: theme.spacing(1),
  },
  expanded: {},
})(MuiAccordion);

type PayoutsListProps = {
  stripePayoutsData: StripePayoutsConnection;
  stripePayoutsError: ApolloError;
  stripePayoutsLoading: boolean;
  onFetchMore: () => void;
};

function PayoutsList(props: PayoutsListProps) {
  const {
    stripePayoutsData,
    stripePayoutsError,
    stripePayoutsLoading,
    onFetchMore,
  } = props;

  // hooks
  const { isSmallTabletView } = useScreenSize();

  const stripePayouts = stripePayoutsData?.edges.map(
    (edge: StripePayoutsEdge) => edge.node
  );

  if (stripePayoutsLoading && !stripePayoutsData) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        flex="1"
        px={3}
        mb={1}
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (stripePayoutsError) {
    return (
      <GQLQueryStatusIndicator error={stripePayoutsError} name="Payouts" />
    );
  }

  return (
    <>
      <Box
        sx={{
          padding: 0,
          "& .infinite-scroll-component__outerdiv": {
            flex: 1,
          },
        }}
        style={{
          overflowX: "auto",
          overflowY: "hidden",
        }}
      >
        <MoovsInfiniteScroll
          loading={stripePayoutsLoading}
          data={stripePayoutsData}
          next={onFetchMore}
          name="payouts"
        >
          {stripePayouts.map((stripePayout: StripePayout) => {
            if (stripePayout.variant === "instant") {
              return isSmallTabletView ? (
                <InstantPayoutsListItemMobile
                  key={stripePayout.stripePayoutId}
                  stripePayout={stripePayout}
                />
              ) : (
                <InstantPayoutsListItemDesktop
                  key={stripePayout.stripePayoutId}
                  stripePayout={stripePayout}
                />
              );
            }

            // standard payouts
            return isSmallTabletView ? (
              // tablet/mobile version implements a clickable list item to open a drawer
              <PayoutsListItemMobile
                key={stripePayout.stripePayoutId}
                stripePayout={stripePayout}
              />
            ) : (
              // desktop version implements an accordion
              <Accordion key={stripePayout.stripePayoutId}>
                <AccordionSummaryDesktop stripePayout={stripePayout} />
                <AccordionDetailsDesktop stripePayout={stripePayout} />
              </Accordion>
            );
          })}
        </MoovsInfiniteScroll>
      </Box>
    </>
  );
}

export default PayoutsList;
