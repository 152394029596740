import React, { useEffect, useMemo, useState } from "react";

import { Box, TextField } from "@mui/material";

import { granite, white } from "../../design-system/colors";
import {
  CalendarIcon,
  NoteIcon,
  PassengerCountIcon,
} from "../../design-system/icons";
import MoovsDialog from "../MoovsDialog";
import { applyUTCOffsetToTime } from "../../globals/utils/helpers";
import { Stop, TripCategory } from "../../types";
import MoovsDateTimePicker from "components/MoovsDateTimePicker";

type AdditionalStopInfoDialogProps = {
  stopLabel: string;
  open: boolean;
  onClose: () => void;
  stop: Partial<Stop>;
  clearable: boolean;
  onSave: (stop: Partial<Stop>) => void;
  isDateTimeDisabled?: boolean;
  tripCategory?: TripCategory;
};

function AdditionalStopInfoDialog(props: AdditionalStopInfoDialogProps) {
  const {
    stopLabel,
    open,
    onClose,
    stop,
    clearable,
    onSave,
    isDateTimeDisabled,
    tripCategory,
  } = props;

  // derived state
  const isShuttleReservation =
    tripCategory === TripCategory.ShuttlePickUp ||
    tripCategory === TripCategory.ShuttleReturn;

  // state
  const [dateTime, setDateTime] = useState(null);
  const [groupSize, setGroupSize] = useState(null);
  const [note, setNote] = useState(null);
  const [noteError, setNoteError] = useState(null);

  // event handlers
  const handleGroupSizeChange = (e: any) => {
    setGroupSize(Number(e.target.value) || null);
  };

  const handleNoteChange = (e: any) => {
    const newNote = e.target.value;

    setNote(newNote);
    setNoteError(
      newNote.length > 120 ? "Max character limit of 120 reached" : null
    );
  };

  const handleDateTimeChange = (date: any) => {
    setDateTime(date ? applyUTCOffsetToTime(date, "add").toISOString() : null);
  };

  const adjustedStopDateTime = useMemo(
    () =>
      dateTime
        ? applyUTCOffsetToTime(dateTime, "subtract").toISOString()
        : null,
    [dateTime]
  );

  const handleSaveClick = () => {
    onClose();
    onSave({ dateTime, groupSize, note });
  };

  // effects
  useEffect(() => {
    // on open reset to saved values
    if (open) {
      setNote(stop.note);
      setGroupSize(stop.groupSize);
      setDateTime(stop.dateTime);
    }
  }, [stop.note, stop.groupSize, stop.dateTime, open, stop]);

  return (
    <MoovsDialog
      hideTopBorder
      hideLoadingIndicator
      open={open}
      onClose={onClose}
      dialogTitle={`Additional ${stopLabel} Info`}
      size="xs"
      onAccept={handleSaveClick}
      acceptButtonText="Save"
      acceptDisabled={!!noteError}
    >
      {stop.stopIndex !== 1 && (
        <Box
          flex="1"
          mb={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Box
            mr={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CalendarIcon color={granite} />
          </Box>
          {/* date time */}
          <MoovsDateTimePicker
            disabled={isDateTimeDisabled}
            disabledReason="Drop-off date and time is not available for one way trips"
            hideCalendarIcon
            clearable={clearable}
            inputFormat="MMM Do YYYY, h:mm a"
            label={`${stopLabel} Date & Time`}
            value={adjustedStopDateTime}
            onAccept={handleDateTimeChange}
            renderInputProps={{
              name: "pickupDateTime",
            }}
          />
        </Box>
      )}

      {!isShuttleReservation && (
        <>
          <Box
            flex="1"
            mb={1}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Box
              mr={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <PassengerCountIcon color={granite} />
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              name="groupSize"
              label={`${stopLabel} Passenger Count`}
              value={groupSize || ""}
              onChange={handleGroupSizeChange}
              style={{ backgroundColor: white }}
            />
          </Box>
          <Box
            flex="1"
            mb={3}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Box
              mr={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <NoteIcon color={granite} />
            </Box>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              name="note"
              label={`${stopLabel} Note`}
              value={note || ""}
              onChange={handleNoteChange}
              style={{ backgroundColor: white }}
              error={!!noteError}
              helperText={noteError}
            />
          </Box>
        </>
      )}
    </MoovsDialog>
  );
}

export default AdditionalStopInfoDialog;
