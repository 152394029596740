import React, { ChangeEvent, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import AffiliateMapButton from "./AffiliateMapButton";
import { black, granite, grayDark, white } from "../../design-system/colors";
import { SortMarkerIcon } from "../../design-system/icons";
import { useScreenSize } from "../../globals/hooks";
import { FarmAffiliateVariantEnum } from "types";
import size from "lodash/size";

const {
  ExternalOperator,
  GriddnetOperator,
  MoovsNetworkOperator,
  MoovsAiOperator,
} = FarmAffiliateVariantEnum;

type FarmAffiliatesViewMenuProps = {
  viewFilter: FarmAffiliateVariantEnum[];
  onViewFilterChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

function FarmAffiliatesViewMenu(props: FarmAffiliatesViewMenuProps) {
  const { viewFilter, onViewFilterChange } = props;

  //hooks
  const { isMobileView } = useScreenSize();

  // state
  const [anchorEl, setAnchorEl] = useState(null);

  const handleViewClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleViewMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* View Filter Button */}
      <Box {...(isMobileView && { px: 1 })}>
        {!isMobileView && <AffiliateMapButton />}
        <Button
          style={{ cursor: "pointer", ...(isMobileView && { marginLeft: 2 }) }} // keeps it aligned with title.
          onClick={handleViewClick}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            color={granite}
            alignItems="center"
          >
            <Typography variant="subtitle2">View</Typography>

            <Box
              mx={1}
              bgcolor={grayDark}
              width={24}
              height={24}
              borderRadius={16}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="caption" color={white}>
                {size(viewFilter)}
              </Typography>
            </Box>
            <Box mt={0.5}>
              <SortMarkerIcon color={black} />
            </Box>
          </Box>
        </Button>
      </Box>

      {/* View Filter Menu */}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleViewMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <FormGroup>
          {[
            MoovsAiOperator,
            ExternalOperator,
            GriddnetOperator,
            MoovsNetworkOperator,
          ].map((view) => {
            // takes "ExternalOperator" and turns it into "External" to match designs
            const label = {
              [MoovsAiOperator]: "AI",
              [ExternalOperator]: "External",
              [GriddnetOperator]: "Griddnet",
              [MoovsNetworkOperator]: "Moovs",
            }[view];

            return (
              <Box key={view}>
                <MenuItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={view}
                        checked={viewFilter.includes(view)}
                        onChange={onViewFilterChange}
                      />
                    }
                    label={label}
                  />
                </MenuItem>
              </Box>
            );
          })}
        </FormGroup>
      </Menu>
    </>
  );
}

export default FarmAffiliatesViewMenu;
