/**
 * @file MoovsConfetti.tsx
 * Component that renders Confetti component.
 *
 * props:
 * active - Required. Triggers an explosion when the prop transitions from falsy to truthy.
 * zIndex - Optional. Defaults to confettiParticlesZIndex.
 * config - Optional. Defaults to MOOVS_CONFETTI_CONFIG.
 *
 * config object - Configuration object to control the characteristics of the confetti
 * note: defaults are for base Confetti component, NOT MoovsConfetti component
 * docs: https://github.com/daniel-lundin/react-dom-confetti
 *  - angle - direction of the explosion in degrees, defaults to 90.
 *  - spread - spread of the explosion in degrees, defaults to 45.
 *  - startVelocity - Initial velocity of the particles, defaults to 45.
 *  - width - width of the confetti elements
 *  - height - height of the confetti elements
 *  - elementCount - Number of particle elements, defaults to 50.
 *  - decay - deprecated - Decrease in velocity per frame, defaults to 0.9 (Use of this will disable dragFriction)
 *  - dragFriction - Decrease in velocity proportional to current velocity, default to 0.1
 *  - delay - deprecated - Use stagger instead.
 *  - stagger - Delay for each fetti in milliseconds, defaults to 0.
 *  - random - Randomization function, defaults to Math.random
 *  - colors - An array of color codes, defaults to ['#a864fd', '#29cdff', '#78ff44', '#ff718d' '#fdff6a']
 *  - (from dom-confetti docs) perspective - perspective of root element.
 *  - (from dom-confetti docs) duration - Duration in milliseconds, defaults to 3000
 */
import React from "react";
import Confetti, { ConfettiConfig } from "react-dom-confetti";

import { Box } from "@mui/material";

import { confettiParticlesZIndex } from "../../theme";

type ConfettiProps = {
  zIndex?: number;
  active: boolean;
  config?: ConfettiConfig;
};

const MOOVS_CONFETTI_CONFIG = {
  spread: 360,
  startVelocity: 40,
  elementCount: 200,
  dragFriction: 0.12,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "500px",
};

function MoovsConfetti(props: ConfettiProps) {
  const { zIndex, active, config } = props;

  return (
    <Box position="relative" zIndex={zIndex || confettiParticlesZIndex}>
      <Confetti active={active} config={config || MOOVS_CONFETTI_CONFIG} />
    </Box>
  );
}

export default MoovsConfetti;
