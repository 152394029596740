import React from "react";

type ChecklistIconProps = {
  color?: string;
};

const ChecklistIcon = (props: ChecklistIconProps) => {
  const { color } = props;

  return (
    <svg
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 10H4.5M7 14H4.5M13 6H4.5M11.5 18L13.5 20L18 15.5M16.5 11V3C16.5 1.89543 15.6046 1 14.5 1H3C1.89543 1 1 1.89543 1 3V19C1 20.1046 1.89543 21 3 21H8.5"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChecklistIcon;
