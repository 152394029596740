import React from "react";

type LinkIconProps = {
  color?: string;
  size?: "small";
};

const LinkIcon = (props: LinkIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.09707 12.3124C5.64698 12.7527 5.03653 13 4.40001 13C3.76349 13 3.15304 12.7527 2.70295 12.3124C2.25286 11.8721 2 11.275 2 10.6523C2 10.0297 2.25286 9.43251 2.70295 8.99223L5.24854 6.5016C5.67532 6.08317 6.24775 5.83762 6.85151 5.81398C7.45528 5.79034 8.04594 5.99035 8.50555 6.37406C8.96516 6.75778 9.25989 7.29697 9.33088 7.88395M9.90293 4.68762C10.353 4.24735 10.9635 4 11.6 4C12.2365 4 12.847 4.24735 13.2971 4.68762C13.7471 5.1279 14 5.72505 14 6.3477C14 6.97034 13.7471 7.56749 13.2971 8.00777L10.7515 10.4979C10.3246 10.9161 9.75216 11.1615 9.14845 11.185C8.54475 11.2085 7.9542 11.0084 7.4947 10.6247C7.03521 10.2409 6.74057 9.70175 6.66963 9.11483C6.63885 8.86016 6.65117 8.60463 6.704 8.35789"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.6569 18.0436L8.82845 18.854C8.0783 19.5878 7.06088 20 6.00001 20C4.93915 20 3.92173 19.5878 3.17158 18.854C2.42143 18.1202 2 17.1249 2 16.0872C2 15.0494 2.42143 14.0542 3.17158 13.3204L7.41423 9.16933C8.12554 8.47196 9.07959 8.0627 10.0859 8.0233C11.0921 7.9839 12.0766 8.31724 12.8426 8.95677C13.6086 9.5963 14.0998 10.4949 14.2181 11.4732C14.3365 12.4515 14.0732 13.4375 13.4809 14.2342M14.5049 6.79817L15.1715 6.14604C15.9217 5.41224 16.9391 5 18 5C19.0609 5 20.0783 5.41224 20.8284 6.14604C21.5786 6.87984 22 7.87508 22 8.91283C22 9.95057 21.5786 10.9458 20.8284 11.6796L16.5858 15.8298C15.8743 16.5269 14.9203 16.9358 13.9141 16.975C12.9079 17.0142 11.9237 16.6807 11.1578 16.0411C10.392 15.4015 9.90096 14.5029 9.78272 13.5247C9.66449 12.5465 9.92777 11.5607 10.52 10.764"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkIcon;
