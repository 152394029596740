/**
 * @file globalSearch.graphql.ts
 * queries/mutations for globalSearch
 *
 */
import { gql } from "@apollo/client";
export const LOAD_SEARCH_RESULTS_QUERY = gql`
  query SearchResults($searchTerm: String) {
    searchResults(searchTerm: $searchTerm) {
      ... on Request {
        id
        __typename
        orderNumber
        stage
        trips {
          id
          __typename
          tripNumber
        }
      }
      ... on Company {
        id
        __typename
        name
      }
      ... on Contact {
        id
        __typename
        firstName
        lastName
      }
    }
  }
`;

export const LOAD_RECENT_SEARCHES_QUERY = gql`
  query RecentSearches {
    recentSearches {
      id
      createdAt
      searchTerm
      searchResult {
        ... on Request {
          id
          __typename
          orderNumber
          stage
          trips {
            id
            __typename
            tripNumber
          }
        }
        ... on Company {
          id
          __typename
          name
        }
        ... on Contact {
          id
          __typename
          firstName
          lastName
        }
      }
    }
  }
`;
export const CREATE_RECENT_SEARCH_MUTATION = gql`
  mutation CreateRecentSearch($input: CreateRecentSearchInput!) {
    createRecentSearch(input: $input) {
      recentSearch {
        id
        createdAt
        searchTerm
        searchResult {
          ... on Request {
            id
            __typename
            orderNumber
            stage
            trips {
              id
              __typename
              tripNumber
            }
          }
          ... on Company {
            id
            __typename
            name
          }
          ... on Contact {
            id
            __typename
            firstName
            lastName
          }
        }
      }
    }
  }
`;
