import React from "react";

import { Box, Typography } from "@mui/material";

import {
  alabaster,
  grayDark,
  grayLight,
} from "../../../../design-system/colors";
import { Vehicle } from "../../../../types";
import SelectableImagesList from "./SelectableImagesList";
import MoovsMediaVehiclesList from "./MoovsMediaVehiclesList";

type MoovsMediaDialogBodyDesktopProps = {
  vehicles: Vehicle[];
  onImageSelect: (photoIndex: number, isSelected: boolean) => void;
  onVehicleSelect: (vehicle: Vehicle) => void;
  selectedImageIndices: {};
  selectedVehicle: Vehicle;
  vehiclesLoading?: boolean;
};

function MoovsMediaDialogBodyDesktop(props: MoovsMediaDialogBodyDesktopProps) {
  const {
    vehicles,
    onImageSelect,
    onVehicleSelect,
    selectedImageIndices,
    selectedVehicle,
    vehiclesLoading,
  } = props;

  return (
    <Box display="flex" minHeight={380}>
      <Box flex={1}>
        <MoovsMediaVehiclesList
          vehicles={vehicles}
          onVehicleSelect={onVehicleSelect}
          selectedVehicle={selectedVehicle}
          vehiclesLoading={vehiclesLoading}
        />
      </Box>
      <Box
        display="flex"
        flex={2}
        bgcolor={alabaster}
        borderTop={`1px solid ${grayLight}`}
        borderBottom={`1px solid ${grayLight}`}
        borderRight={`1px solid ${grayLight}`}
      >
        {!!selectedVehicle ? (
          <SelectableImagesList
            selectedImages={selectedVehicle.photos}
            selectedImageIndices={selectedImageIndices}
            onImageSelect={onImageSelect}
          />
        ) : (
          <Box
            display="flex"
            flex={1}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="body2"
              style={{ fontWeight: 500, color: grayDark }}
            >
              Select a Vehicle
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default MoovsMediaDialogBodyDesktop;
