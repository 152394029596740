import React from "react";

import { Box, Typography } from "@mui/material";

import { grayMedium, grayDark, white } from "../../../design-system/colors";
import { PreferedAddressIcon } from "../../../design-system/icons";
import { primaryDisabledColor, primaryMainColor } from "../../../theme";

type PhotoIndexIndicatorProps = {
  photoIndex: number;
  disabled?: boolean;
};

function PhotoIndexIndicator(props: PhotoIndexIndicatorProps) {
  const { photoIndex, disabled } = props;

  const backgroundColor =
    photoIndex === 1
      ? primaryMainColor
      : disabled
      ? grayMedium
      : primaryDisabledColor;

  return (
    <Box position="absolute" top="16px" left="16px">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={24}
        width={24}
        bgcolor={backgroundColor}
        borderRadius="4px"
      >
        {/* star for primary photo */}
        {photoIndex === 1 ? (
          <PreferedAddressIcon color={white} />
        ) : (
          <Typography
            variant="body2"
            style={{ color: disabled ? grayDark : primaryMainColor }}
          >
            {photoIndex}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default PhotoIndexIndicator;
