import React, { useState } from "react";
import moment from "moment";
import capitalize from "lodash/capitalize";

import { Box, Typography, IconButton, Divider, Collapse } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import {
  Payment,
  PaymentMethod,
  PaymentMethodEnum,
  PaymentPayer,
  PaymentPaymentStatus,
  PaymentRefund,
} from "types";
import { CreditCardIcon, WarningTriangleIcon } from "design-system/icons";
import RoundChip from "design-system/components/chips/RoundChip";
import {
  errorRed,
  grayDark,
  paymentStatusLabelColors,
  warningYellow,
} from "design-system/colors";
import MoovsTooltip from "components/MoovsTooltip";
import TransactionBreakdown from "./TransactionBreakdown";
import MoovsEllipsisMenu, {
  EllipsisMenuOption,
} from "components/MoovsEllipsisMenu";

export type TransactionDetailsProps = {
  payment: Payment;
  status: PaymentPaymentStatus;
  amount: string;
  riskEvaluation: string;
  cardHasElevatedRisk: boolean;
  transactionEllipsesOptions: EllipsisMenuOption[];
  method: PaymentMethodEnum;
  paymentMethod?: PaymentMethod;
  riskEvaluationTooltipText?: string;
  refund?: PaymentRefund;
  isFirstItem?: boolean;
  includePaymentMethod?: boolean;
};

export const riskEvaluationStatuses = {
  normal: "Normal",
  elevated: "Elevated",
  highest: "Highest",
};

function TransactionListItemDesktop(props: TransactionDetailsProps) {
  const {
    payment,
    refund,
    amount,
    status,
    riskEvaluation,
    cardHasElevatedRisk,
    method,
    paymentMethod,
    transactionEllipsesOptions,
    riskEvaluationTooltipText,
    isFirstItem,
    includePaymentMethod,
  } = props;

  // derived state
  const isAuthorizationPayment =
    payment.paymentPayer === PaymentPayer.Authorization;

  // state
  const [open, setOpen] = useState(false);

  return (
    <Box mb={2}>
      {!isFirstItem && <Divider />}
      <Box
        display="flex"
        flex="1"
        justifyContent="flex-start"
        alignItems="center"
        mt={2}
      >
        <Box flex={0.2}>
          {!isAuthorizationPayment && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </Box>
        <Box flex={0.4}>
          <Typography
            variant="body2"
            color={!payment.didPaymentSucceed && grayDark}
          >
            {amount}
          </Typography>
        </Box>
        <Box flex={0.6}>
          <Typography
            variant="body2"
            color={!payment.didPaymentSucceed && grayDark}
          >
            {moment(refund.createdAt || payment.createdAt).format(
              "MM/DD/YY h:mm a"
            )}
          </Typography>
        </Box>
        <Box flex={0.6} justifyContent={"flex-start"}>
          <MoovsTooltip
            tooltipText={payment.paymentStatusTooltip}
            hidden={
              !payment.paymentStatusTooltip ||
              status === PaymentPaymentStatus.Succeeded
            }
          >
            <RoundChip {...paymentStatusLabelColors[status]} />
          </MoovsTooltip>
        </Box>
        <Box flex={0.5} display="flex">
          <MoovsTooltip
            tooltipText={riskEvaluationTooltipText}
            hidden={riskEvaluation === "normal" || !riskEvaluationTooltipText}
          >
            <Box display="flex">
              <Typography variant="body2" paddingRight={1}>
                {riskEvaluationStatuses[riskEvaluation] || "N/A"}
              </Typography>
              {cardHasElevatedRisk && (
                <WarningTriangleIcon
                  color={
                    riskEvaluation === "highest" ? errorRed : warningYellow
                  }
                />
              )}
            </Box>
          </MoovsTooltip>
        </Box>

        {/* Method */}
        {includePaymentMethod && (
          <Box flex={0.5} display="flex">
            {method === PaymentMethodEnum.Card ? (
              <Box display="flex" flexDirection="row" gap={1}>
                <CreditCardIcon brand={paymentMethod?.card?.brand} />
                <Typography variant="body2">{`**** ${paymentMethod?.card?.last4}`}</Typography>
              </Box>
            ) : (
              <Typography variant="body2">
                {capitalize(payment.method)}
              </Typography>
            )}
          </Box>
        )}

        {/* Ellipses */}
        <Box>
          <MoovsEllipsisMenu
            size="small"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            options={transactionEllipsesOptions}
          />
        </Box>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <TransactionBreakdown
          subItems={refund ? refund.subRefunds : payment.subPayments}
          includePaymentMethod={includePaymentMethod}
        />
      </Collapse>
    </Box>
  );
}

export default TransactionListItemDesktop;
