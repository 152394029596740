import React from "react";
import { Helmet } from "react-helmet";

/* 
Blank page just in case a member's only home permission is chat
*/
const ChatPage = () => {
  return (
    <>
      <Helmet>
        <title>Chat | Moovs</title>
      </Helmet>
    </>
  );
};

export default ChatPage;
