/**
 * @file graphql/promoCode.ts
 * queries/mutations for PromoCode.
 *
 */

import { gql } from "@apollo/client";

export const CREATE_PROMO_CODE_MUTATION = gql`
  mutation CreatePromoCode($input: CreatePromoCodeInput!) {
    createPromoCode(input: $input) {
      operator {
        id
        settings {
          promoCodes {
            id
            promoCodeName
            active
            redemptionCount
            promoCodeAmt
            promoCodePercent
            totalLimit
            bookingContactLimit
            bookingStartDate
            bookingEndDate
            tripStartDate
            tripEndDate
          }
          hasActivePromoCodes
        }
      }
    }
  }
`;

export const LOAD_PROMO_CODE_RESULTS_QUERY = gql`
  query LoadPromoCodeResults($promoCodeId: ID!) {
    loadPromoCodeResults(promoCodeId: $promoCodeId) {
      totalCount
      edges {
        node {
          ... on Trip {
            id
            createdAt
            tripNumber
            totalAmount
            routes {
              id
              farmRelationship
              pricing {
                id
                promoCodeName
                promoCodeAmt
                promoCodePercent
                totalAmt
              }
              farmeePricing {
                id
                promoCodeName
                promoCodeAmt
                promoCodePercent
                totalAmt
              }
            }
            contact {
              id
              firstName
              lastName
            }
            request {
              id
              stage
              orderNumber
            }
            stops {
              id
              createdAt
              pickUpDateTime
              dateTime
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_PROMO_CODE_MUTATION = gql`
  mutation UpdatePromoCode($input: UpdatePromoCodeInput!) {
    updatePromoCode(input: $input) {
      promoCode {
        id
        promoCodeName
        active
        redemptionCount
        promoCodeAmt
        promoCodePercent
        totalLimit
        bookingContactLimit
        bookingStartDate
        bookingEndDate
        tripStartDate
        tripEndDate
      }
    }
  }
`;

export const REMOVE_PROMO_CODE_MUTATION = gql`
  mutation RemovePromoCode($input: RemovePromoCodeInput!) {
    removePromoCode(input: $input) {
      operator {
        id
        settings {
          promoCodes {
            id
            promoCodeName
            active
            redemptionCount
            promoCodeAmt
            promoCodePercent
            totalLimit
            bookingContactLimit
            bookingStartDate
            bookingEndDate
            tripStartDate
            tripEndDate
          }
          hasActivePromoCodes
        }
      }
    }
  }
`;
