import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import size from "lodash/size";
import { Box } from "@mui/material";

import { useAnalytics, useSnackbar } from "globals/hooks";
import { CREATE_DUPLICATE_RESERVATION_MUTATION } from "globals/graphql";
import { Request } from "types";
import MoovsDialog from "components/MoovsDialog";
import MoovsMultiDatePicker from "components/MoovsMultiDatePicker";

type DuplicateReservationDialogProps = {
  open: boolean;
  onClose: () => void;
  request: Request;
};

function DuplicateReservationDialog(props: DuplicateReservationDialogProps) {
  const { open, onClose, request } = props;

  // derived state
  const requestId = request.id;

  // hooks
  const { track } = useAnalytics();
  const snackbar = useSnackbar();

  // state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [dateList, setDateList] = useState([]);

  const getSnackbarLink = (requestId: string) => `/reservations/${requestId}`;
  const snackbarLinkLabel = "View Reservation";

  // mutations
  const [createDuplicateReservation] = useMutation(
    CREATE_DUPLICATE_RESERVATION_MUTATION,
    {
      refetchQueries: ["Request"],
      awaitRefetchQueries: true,
      onCompleted(data) {
        setIsSubmitting(false);

        const { reservations } = data.createDuplicateReservation;

        if (size(reservations) === 1) {
          snackbar.success(`Successfully saved reservation`, {
            link: getSnackbarLink(reservations[0].id),
            linkLabel: snackbarLinkLabel,
            onLinkClick: () => {
              track("snackbar_reservation_viewed");
            },
          });
        } else {
          snackbar.success(`Successfully saved reservations`);
        }

        track("reservation_duplicated", {
          numberOfReservationsCreated: size(reservations),
        });

        clearListAndClose();
      },
      onError(error) {
        setIsSubmitting(false);
        snackbar.error(error.message);
      },
    }
  );

  // effects
  useEffect(() => {
    if (!!errorMsg && size(dateList)) setErrorMsg(null);
  }, [errorMsg, dateList]);

  // event handlers
  const handleSubmit = () => {
    setIsSubmitting(true);
    setErrorMsg(null);

    if (size(dateList) < 1) {
      setIsSubmitting(false);
      setErrorMsg("Please select at least one date");
      return;
    }

    const reservationDates = dateList.map((momentDate) => momentDate.toDate());

    createDuplicateReservation({
      variables: {
        input: { requestId, reservationDates },
      },
    });
  };

  const clearListAndClose = () => {
    setDateList([]);
    onClose();
  };

  const handleOnClose = () => {
    if (isSubmitting) return;

    clearListAndClose();
  };

  return (
    <MoovsDialog
      open={open}
      onClose={handleOnClose}
      dialogTitle={"Duplicate Reservation"}
      size="sm"
      xsFullscreenMobile
      acceptButtonText="Duplicate"
      closeButtonText="Cancel"
      onAccept={handleSubmit}
      acceptDisabled={isSubmitting}
    >
      <Box display="flex" flex="1" alignItems="center" justifyContent="center">
        <MoovsMultiDatePicker
          dateList={dateList}
          dateLimit={5}
          errorMsg={errorMsg}
          setDateList={setDateList}
        />
      </Box>
    </MoovsDialog>
  );
}

export default DuplicateReservationDialog;
