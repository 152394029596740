import { useCallback, useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";

import { useSegment } from "./useSegment";
import { fromGlobalId } from "globals/utils/helpers";
import { useCurrentUser } from "./useCurrentUser";
import { useOperator } from "./useOperator";
import { usePrevious } from ".";
import { User } from "types";
import { useCachedWidgetInstalled } from "./useCachedWidgetInstalled";

type UseIdentifyAnalyticsParams = {
  init: boolean;
};

function useIdentifyAnalytics(params: UseIdentifyAnalyticsParams) {
  const { init } = params;

  // hooks
  const analytics = useSegment();
  const operator = useOperator();
  const currentUser = useCurrentUser();
  const previousUser = usePrevious<User>(currentUser);
  const widgetInstalled = useCachedWidgetInstalled({ operator });

  const [hasInitialized, setHasInitialized] = useState(false);

  // handlers
  const identify = useCallback(() => {
    if (!currentUser || !operator || !analytics) return;

    // NOTE: This is just to update group analytics in mixpanel,
    // due to a bug where segment does not update the values
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

    const { id, firstName, lastName, email, createdAt, role } = currentUser;
    const operatorId = fromGlobalId(operator.id).id;

    analytics.identify(fromGlobalId(id).id, {
      email,
      operator_id: operatorId,
      name: `${firstName} ${lastName}`,
      source: "operator",
      created_at: createdAt,
      role: role?.slug,
      widget_installed: widgetInstalled,
    });

    const groupAnalytics = {
      name: operator.name,
      operator_id: operatorId,
      phone: operator.voicePhoneNumber,
      email: operator.generalEmail,
      location: operator.address,
      source: "operator",
      plan: operator.plan,
      stripe_id: operator.stripeAccountId,
      created_at: operator.createdAt,
    };

    analytics.group(operatorId, groupAnalytics);
    mixpanel.get_group("operator_id", operatorId).set(groupAnalytics);

    setHasInitialized(true);
  }, [analytics, currentUser, operator, widgetInstalled]);

  // initialize app
  useEffect(() => {
    if (
      init && // init flag is passed
      operator && // operator has fetched
      currentUser && // current user has fetched
      analytics && // analytics has loaded
      widgetInstalled !== null &&
      !hasInitialized
    ) {
      identify();
    }
  }, [
    analytics,
    currentUser,
    hasInitialized,
    identify,
    init,
    operator,
    widgetInstalled,
  ]);

  // reset initialized state if user changes
  useEffect(() => {
    if (currentUser && currentUser.id !== previousUser?.id) {
      setHasInitialized(false);
    }
  }, [currentUser, currentUser?.id, previousUser?.id]);

  return { identify };
}

export { useIdentifyAnalytics };
