import React from "react";
import { useHistory } from "react-router-dom";

import { Box, Typography, Button, Switch } from "@mui/material";

import { alabaster } from "design-system/colors";
import ProPlanWithTextIcon from "design-system/icons/subscriptions/ProPlanWithTextIcon";
import { useOperator, useScreenSize } from "globals/hooks";
import { PlanVariant } from "types";

type BookingToolSettingSectionWithPlanUpgradeProps = {
  handleVehicleSettingsToggle: (e: any) => void;
  mobileImage: string;
  desktopImage: string;
  title: string;
  subtext: JSX.Element;
  name: string;
  imgWidth: string;
  value: boolean;
};

function BookingToolSettingSectionWithPlanUpgrade(
  props: BookingToolSettingSectionWithPlanUpgradeProps
) {
  const {
    handleVehicleSettingsToggle,
    mobileImage,
    desktopImage,
    title,
    subtext,
    value,
    name,
    imgWidth,
  } = props;

  // hooks
  const { isSmallTabletView } = useScreenSize();
  const operator = useOperator();
  const history = useHistory();

  // derived
  const needToUpgradePlan =
    operator.plan !== PlanVariant.Pro && operator.plan !== PlanVariant.Vip;

  return (
    <Box mb={3}>
      <Box display="flex" justifyContent="space-between">
        <Box maxWidth={isSmallTabletView ? "100%" : "75%"}>
          <Box display="flex" alignItems="center">
            <Typography variant="body2" mb={0.5}>
              {title}
            </Typography>
            {needToUpgradePlan ? (
              <Box ml={2}>
                <ProPlanWithTextIcon />
              </Box>
            ) : null}
          </Box>
          {subtext}
        </Box>
        <Box display="flex" alignItems="start">
          {needToUpgradePlan && isSmallTabletView ? null : needToUpgradePlan ? (
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push("/settings/billing/plans")}
            >
              Upgrade Now
            </Button>
          ) : (
            <Switch
              checked={value}
              onChange={handleVehicleSettingsToggle}
              name={name}
              color="primary"
            />
          )}
        </Box>
      </Box>
      {/* Upgrade Plan Button */}
      <Box>
        {needToUpgradePlan && isSmallTabletView && (
          <Button
            color="primary"
            variant="contained"
            fullWidth
            sx={{ my: 2 }}
            onClick={() => history.push("/settings/billing/plans")}
          >
            Upgrade Now
          </Button>
        )}
      </Box>
      <Box
        mt={2}
        bgcolor={alabaster}
        display="flex"
        justifyContent="center"
        py={imgWidth !== "100%" ? 2 : 0}
      >
        <img
          src={isSmallTabletView ? mobileImage : desktopImage}
          alt="booking-tool-setting"
          width={imgWidth}
        />
      </Box>
    </Box>
  );
}

export default BookingToolSettingSectionWithPlanUpgrade;
