import React, { useState } from "react";

import { Box, CircularProgress } from "@mui/material";

import ActionButton from "design-system/components/ActionButton";
import { OpenIcon } from "design-system/icons";
import MoovsTooltip from "components/MoovsTooltip";
import { DialogItemProps } from "./DialogItemMobile";

function DialogItemDesktop(props: DialogItemProps) {
  const {
    label,
    icon,
    onClickPrimary,
    onClickOpen,
    disabled,
    disableTooltipText,
    isLoading,
  } = props;
  const [showIcon, setShowIcon] = useState(false);

  return (
    <Box
      width="133px"
      position="relative"
      onMouseEnter={() => !disabled && setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
    >
      <ActionButton
        label={label}
        icon={icon}
        onClick={onClickPrimary}
        disabled={disabled || isLoading}
        disableTooltipText={disableTooltipText}
        sx={{
          width: "133px",
          maxWidth: "133px",
          height: "90px",
          maxHeight: "90px;",
        }}
      >
        {showIcon && !isLoading && !disabled && (
          <Box
            position="absolute"
            m={1}
            top={0}
            right={0}
            onClick={onClickOpen}
          >
            <MoovsTooltip tooltipText="Open">
              <OpenIcon size="xs" />
            </MoovsTooltip>
          </Box>
        )}

        {isLoading && (
          <Box position="absolute" m={1} top={1} right={0}>
            <MoovsTooltip tooltipText="Download in progress">
              <CircularProgress size={20} />
            </MoovsTooltip>
          </Box>
        )}
      </ActionButton>
    </Box>
  );
}

export default DialogItemDesktop;
