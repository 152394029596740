/**
 * @file useSegment/Provider.tsx
 * ContextProvider for Segment
 */
import React, { useEffect, useState } from "react";
import { Analytics, AnalyticsBrowser } from "@segment/analytics-next";

import SegmentContext from "./Context";

function SegmentProvider({ children }) {
  // state
  const [analytics, setSegment] = useState<Analytics | undefined>(undefined);

  // effects
  useEffect(() => {
    const loadSegment = async () => {
      try {
        const [response] = await AnalyticsBrowser.load({
          writeKey: process.env.REACT_APP_SEGMENT_KEY,
        });
        setSegment(response);
      } catch (error) {
        console.log(error);
      }
    };
    loadSegment();
  }, []);

  useEffect(() => {
    if (analytics) {
      // @ts-ignore
      window.analyticsNext = analytics;
    }
  }, [analytics]);

  return (
    <SegmentContext.Provider value={analytics}>
      {children}
    </SegmentContext.Provider>
  );
}

export default SegmentProvider;
