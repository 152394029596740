import React, { useState } from "react";

import { Box, Button, ButtonBase, Menu, Typography } from "@mui/material";

import { granite, grayLight } from "../../../../colors";
import { EllipsisHorizontalIcon } from "../../../../icons";
import { useScreenSize } from "../../../../../globals/hooks";

const navButtonStyles = {
  root: {
    display: "flex",
    cursor: "pointer",
    flex: 1,
    padding: "8px",
    alignItems: "center",
    alignContent: "center",
    borderRadius: "4px",
    margin: "0 8px",
    "&:hover": {
      backgroundColor: grayLight,
    },
  },
  navText: {
    color: granite,
    fontWeight: 500,
  },
  navTextSmall: {
    color: granite,
    fontWeight: 500,
    fontSize: 11,
  },
  collapsedMenuButton: {
    backgroundColor: "transparent",
    borderRadius: "4px",
    padding: "8px",
    "&:hover": {
      backgroundColor: grayLight,
    },
    width: "100%",
  },
  collapsedMenuItem: {
    margin: "0 0px",
    width: "100%",
  },
};

type NavCollapsedMenuProps = {
  navCollapsibleItems: any;
};

function NavCollapsedMenu(props: NavCollapsedMenuProps) {
  const { navCollapsibleItems } = props;

  // state
  const [anchorEl, setAnchorEl] = useState(null);

  // hooks
  const { isMobileView } = useScreenSize();

  // event handlers
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = (
    <Menu
      id="collapsed-menu"
      anchorEl={anchorEl}
      keepMounted
      open={!!anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <Box px={1} onClick={handleClose}>
        {navCollapsibleItems}
      </Box>
    </Menu>
  );

  return (
    <>
      {/* Mobile */}
      {isMobileView && (
        <>
          <Box sx={navButtonStyles.root} onClick={handleClick}>
            <ButtonBase focusRipple>
              <Box m={-0.5}>
                <EllipsisHorizontalIcon />
              </Box>
              <Box
                mx={3}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography sx={navButtonStyles.navText}>More</Typography>
              </Box>
            </ButtonBase>
          </Box>
          {menuItems}
        </>
      )}

      {/* Desktop */}
      {!isMobileView && (
        <>
          <Button
            disableTouchRipple
            onClick={handleClick}
            style={{ backgroundColor: "transparent", width: "100%" }}
          >
            <Box py={0.5} px={1.75} sx={navButtonStyles.collapsedMenuButton}>
              <EllipsisHorizontalIcon color={granite} />
              <Typography sx={navButtonStyles.navTextSmall}>More</Typography>
            </Box>
          </Button>
          {menuItems}
        </>
      )}
    </>
  );
}

export default NavCollapsedMenu;
