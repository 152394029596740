import React from "react";
import { useQuery } from "@apollo/client";
import {
  Typography,
  Box,
  Divider,
  AccordionDetails as MuiAccordionDetails,
  CircularProgress,
} from "@mui/material";

import { StripePayout } from "types";
import { TransactionsListItemDesktop } from "./components";
import { currency } from "globals/utils/helpers";
import { LOAD_STRIPE_TRANSACTIONS_QUERY } from "globals/graphql";
import GQLQueryStatusIndicator from "../../../../../../../../../GQLQueryStatusIndicator";

type AccordionDetailsDesktopProps = {
  stripePayout: StripePayout;
};

function AccordionDetailsDesktop(props: AccordionDetailsDesktopProps) {
  const { stripePayoutId, variant } = props.stripePayout;

  // queries
  const {
    data: stripeTransactionsData,
    error: stripeTransactionsError,
    loading: stripeTransactionsLoading,
  } = useQuery(LOAD_STRIPE_TRANSACTIONS_QUERY, {
    variables: {
      stripePayoutId: stripePayoutId,
    },
    fetchPolicy: "network-only",
    skip: !stripePayoutId || variant === "instant",
  });

  if (stripeTransactionsError) {
    return (
      <GQLQueryStatusIndicator
        error={stripeTransactionsError}
        name="Payout details"
      />
    );
  }

  if (stripeTransactionsLoading) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        flex="1"
        px={3}
        mb={1}
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  const transactionEdges = stripeTransactionsData?.stripeTransactions.edges;

  const totalGross = transactionEdges?.reduce((gross, transactionEdge) => {
    return gross + transactionEdge.node.gross;
  }, 0);

  const totalFees = transactionEdges?.reduce((fees, transactionEdge) => {
    return fees + transactionEdge.node.fees;
  }, 0);

  const totalNetTotal = transactionEdges?.reduce(
    (netTotal, transactionEdge) => {
      return netTotal + transactionEdge.node.netTotal;
    },
    0
  );

  return (
    <>
      <Box mx={2}>
        <Divider />
      </Box>
      <MuiAccordionDetails sx={{ padding: 2 }}>
        <Box display="flex" flexDirection="column" flex="1">
          <Box display="flex" flexDirection="row" flex="1" mb={1}>
            <Box display="flex" flex="1">
              <Typography variant="overline">Type</Typography>
            </Box>
            <Box display="flex" flex="2">
              <Typography variant="overline">Booking Contact</Typography>
            </Box>
            <Box display="flex" flex="2">
              <Typography variant="overline">Trip</Typography>
            </Box>
            <Box display="flex" flex="1">
              <Typography variant="overline">Txn Date</Typography>
            </Box>
            <Box display="flex" flex="1" justifyContent="flex-end">
              <Typography variant="overline">Gross</Typography>
            </Box>
            <Box display="flex" flex="1" justifyContent="flex-end">
              <Typography variant="overline">Fees</Typography>
            </Box>
            <Box display="flex" flex="1" justifyContent="flex-end">
              <Typography variant="overline">Net Total</Typography>
            </Box>
          </Box>
          {transactionEdges?.map((transactionEdge, i) => (
            <TransactionsListItemDesktop
              key={i}
              transactionEdge={transactionEdge}
            />
          ))}
          <Box my={2}>
            <Divider />
          </Box>
          <Box display="flex" flexDirection="row" flex="1">
            <Box display="flex" flex="1">
              <Typography variant="body2" style={{ fontWeight: 700 }}>
                Totals
              </Typography>
            </Box>
            <Box display="flex" flex="2"></Box>
            <Box display="flex" flex="2"></Box>
            <Box display="flex" flex="1"></Box>
            <Box display="flex" flex="1" justifyContent="flex-end">
              <Typography variant="body2">{currency(totalGross)}</Typography>
            </Box>
            <Box display="flex" flex="1" justifyContent="flex-end">
              <Typography variant="body2">{currency(totalFees)}</Typography>
            </Box>
            <Box display="flex" flex="1" justifyContent="flex-end">
              <Typography variant="body2">{currency(totalNetTotal)}</Typography>
            </Box>
          </Box>
        </Box>
      </MuiAccordionDetails>
    </>
  );
}

export default AccordionDetailsDesktop;
