import React from "react";

type StandardPlanIconProps = {
  size?: "large";
};

const StandardPlanIcon = (props: StandardPlanIconProps) => {
  if (props.size === "large") {
    return (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="48"
          height="48"
          rx="24"
          fill="#739CFF"
          fillOpacity="0.24"
        />
        <path
          d="M22.5 24H25.5M34 24C34 25.6569 32.6569 27 31 27C29.3431 27 28 25.6569 28 24C28 22.3431 29.3431 21 31 21C32.6569 21 34 22.3431 34 24ZM20.0526 24C20.0526 25.6569 18.6977 27 17.0263 27C15.3549 27 14 25.6569 14 24C14 22.3431 15.3549 21 17.0263 21C18.6977 21 20.0526 22.3431 20.0526 24Z"
          stroke="#195FE9"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="18" fill="#739CFF" fillOpacity="0.24" />
      <path
        d="M16.7501 18H19.2501M26.3334 18C26.3334 19.3807 25.2141 20.5 23.8334 20.5C22.4527 20.5 21.3334 19.3807 21.3334 18C21.3334 16.6193 22.4527 15.5 23.8334 15.5C25.2141 15.5 26.3334 16.6193 26.3334 18ZM14.7106 18C14.7106 19.3807 13.5815 20.5 12.1887 20.5C10.7959 20.5 9.66675 19.3807 9.66675 18C9.66675 16.6193 10.7959 15.5 12.1887 15.5C13.5815 15.5 14.7106 16.6193 14.7106 18Z"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StandardPlanIcon;
