import React from "react";
import { Controller } from "react-hook-form";

import { Box, Typography } from "@mui/material";

import { VehicleChildSeat, VehicleFeature, VehicleSettings } from "types";
import VehicleFeatures from "components/vehicles/VehicleFeatures";
import VehicleFormSection from "components/vehicles/VehicleFormSection";
import VehicleChildSeats from "components/vehicles/VehicleChildSeats/VehicleChildSeats";
import { useUpdateVehicleFormContext } from "../../hooks/useUpdateVehicleForm";

function Features() {
  // hooks
  const formCtx = useUpdateVehicleFormContext();

  if (!formCtx) {
    console.error(
      "useUpdateVehicleFormContext must be used within an UpdateVehicleFormProvider"
    );
    return (
      <Box mt={4}>
        <Typography variant="body2" color="error">
          Error loading vehicle features. Please refresh the page.
        </Typography>
      </Box>
    );
  }

  const { control } = formCtx;

  return (
    <Box mt={4}>
      <VehicleFormSection title="Vehicle Features">
        <Controller
          control={control}
          name="features"
          render={({ field: { value, onChange } }) => {
            // handlers
            const handleVehicleFeaturesChange =
              (modifiedFeature: VehicleFeature) =>
              (event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(
                  event.target.checked
                    ? [...value, modifiedFeature]
                    : value.filter(
                        (feature) => feature.id !== modifiedFeature.id
                      )
                );

            return (
              <VehicleFeatures
                activeFeatures={value}
                handleVehicleFeaturesChange={handleVehicleFeaturesChange}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="childSeats"
          render={({ field: { value, onChange } }) => {
            const childSeats = value as Pick<
              VehicleSettings,
              "forwardFacingSeat" | "rearFacingSeat" | "boosterSeat"
            >;

            // handlers
            const handleVehicleChildSeatChange =
              (
                childSeatName:
                  | "forwardFacingSeat"
                  | "rearFacingSeat"
                  | "boosterSeat"
              ) =>
              (childSeat: VehicleChildSeat) => {
                onChange({
                  ...value,
                  [childSeatName]: childSeat,
                });
              };

            return (
              <VehicleChildSeats
                childSeats={childSeats}
                handleVehicleChildSeatChange={handleVehicleChildSeatChange}
              />
            );
          }}
        />
      </VehicleFormSection>
    </Box>
  );
}

export default Features;
