import React from "react";

import { Box } from "@mui/material";

import MissingImage from "../../../design-system/images/MissingImage.png";
import { VehiclePhoto } from "../../../types";
import PhotoIndexIndicator from "./PhotoIndexIndicator";
import { primaryMainColor } from "theme";

type VehiclePhotosDraggableListProps = {
  photos: VehiclePhoto[];
  onPhotoClick: (photoIndex: number) => void;
};

function ReadOnlyVehiclePhotos(props: VehiclePhotosDraggableListProps) {
  const { photos, onPhotoClick } = props;

  const ViewOnlyVehiclePhoto = ({ photo }: { photo: VehiclePhoto }) => (
    <Box
      style={{ cursor: "pointer" }}
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height={150}
      width={200}
      borderRadius="4px"
      border="1px solid rgba(115, 156, 255, 0.3)"
      bgcolor="rgba(229, 238, 255, 0.3)"
      m={1}
      sx={{
        cursor: "pointer",
        overflow: "hidden",
        "&:hover $iconButton": {
          opacity: 1,
          backgroundColor: primaryMainColor,
        },
      }}
      onClick={() => onPhotoClick(photo.photoIndex)}
    >
      <img
        style={{
          borderRadius: "4px",
          border: "1px solid rgba(115, 156, 255, 0.3)",
          objectFit: "cover",
        }}
        width="200"
        height="150"
        src={photo.url === "blank" ? MissingImage : photo.url}
        onError={(e: any) => (e.target.src = MissingImage)}
        alt={photo.url || "broken"}
      />

      {/* index indicator */}
      <PhotoIndexIndicator photoIndex={photo.photoIndex} />
    </Box>
  );

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" mx={-1}>
      {/* photos */}
      {[...photos]
        // come back from server sorted, but this prevents a weird bug.
        .sort((a, b) => a.photoIndex - b.photoIndex)
        .map((photo, index) => {
          return <ViewOnlyVehiclePhoto photo={photo} key={photo.id} />;
        })}
    </Box>
  );
}

export default ReadOnlyVehiclePhotos;
