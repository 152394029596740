import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { Box, Drawer } from "@mui/material";

import ConversationsList from "./conversation/ConversationsList";
import Conversation from "./conversation/Conversation";
import { useAnalytics, useScreenSize } from "../../globals/hooks";
import { ConversationType } from "../../globals/hooks/useChat/Provider";
import { useChat } from "../../globals/hooks/useChat";
import { CHAT_PANEL_WIDTH } from "theme";

type ChatPanelProps = {
  open: boolean;
  onClose: () => void;
};

function ChatPanel(props: ChatPanelProps) {
  const { open, onClose } = props;

  // hooks
  const { conversations } = useChat();
  const { isMobileView } = useScreenSize();
  const history = useHistory();
  const location = useLocation();
  const { track } = useAnalytics();

  const { conversationId } = queryString.parse(history.location.search);

  // state
  const [selectedConversation, setSelectedConversation] =
    useState<ConversationType>(null);

  // effects
  useEffect(() => {
    if (conversationId) {
      setSelectedConversation(
        conversations.find(
          (conversation) => conversation?.proxy?.sid === conversationId
        )
      );
    }
  }, [conversations, conversationId]);

  // event handlers
  const handleConversationClick = useCallback(
    (conversation: ConversationType) => {
      track("chatConversation_opened");
      setSelectedConversation(conversation);

      const parsedQueryParams = queryString.parse(history.location.search);
      parsedQueryParams.conversationId = conversation.proxy.sid;
      const stringified = queryString.stringify(parsedQueryParams);

      history.push({
        pathname: location.pathname,
        search: stringified,
      });
    },
    [history, location.pathname, track]
  );

  return (
    <Drawer variant="persistent" anchor="right" open={open} onClose={onClose}>
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        width={isMobileView ? "100vw" : CHAT_PANEL_WIDTH}
        height="100vh"
        overflow="hidden"
      >
        {selectedConversation ? (
          <Conversation
            conversation={selectedConversation}
            onBackClick={() => {
              setSelectedConversation(null);
              const parsedQueryParams = queryString.parse(
                history.location.search
              );
              parsedQueryParams.conversationId = null;
              const stringified = queryString.stringify(parsedQueryParams, {
                skipNull: true,
              });
              history.push({
                pathname: history.location.pathname,
                search: stringified,
              });
            }}
          />
        ) : (
          <ConversationsList
            conversations={conversations}
            onConversationClick={handleConversationClick}
            onChatPanelClose={onClose}
          />
        )}
      </Box>
    </Drawer>
  );
}

export default ChatPanel;
