import React from "react";
import { Control, FieldErrors } from "react-hook-form";

import { Box } from "@mui/material";

import RHFTextField from "components/reactHookFormInputs/RHFTextField";
import { black, errorRed, grayMedium } from "design-system/colors";
import { EditDynamicPricingDefaultValues } from "../useEditDynamicPricingDefaultValues";

type FormNameProps = {
  control: Control<EditDynamicPricingDefaultValues>;
  errors: FieldErrors;
};

function FormName(props: FormNameProps) {
  const { control, errors } = props;
  return (
    <Box my={1.5}>
      <RHFTextField
        name="name"
        control={control}
        errorMessage={errors.name?.message}
        fullWidth
        variant="outlined"
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: black,
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: errors.name ? errorRed : grayMedium,
          },
          "& .MuiInput-underline:before": {
            borderBottomColor: grayMedium,
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: grayMedium,
          },
        }}
      />
    </Box>
  );
}

export default FormName;
