import React from "react";

type BrowserBookingToolIconProps = {
  color?: string;
};

const BrowserBookingToolIcon = (props: BrowserBookingToolIconProps) => {
  const { color } = props;

  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="56" height="56" rx="28" fill="white" />
      <path
        d="M39 24.5V21C39 19.8954 38.1046 19 37 19H19C17.8954 19 17 19.8954 17 21V35C17 36.1046 17.8954 37 19 37H37C38.1046 37 39 36.1046 39 35V34.5M17 22H39M22 29.5H24M22 29.5C22 30.3284 21.3284 31 20.5 31C19.6716 31 19 30.3284 19 29.5C19 28.6716 19.6716 28 20.5 28C21.3284 28 22 28.6716 22 29.5ZM27.5 29.5H29.5M27.5 29.5C27.5 30.3284 26.8284 31 26 31C25.1716 31 24.5 30.3284 24.5 29.5C24.5 28.6716 25.1716 28 26 28C26.8284 28 27.5 28.6716 27.5 29.5ZM32.5 30L33.8756 31.35L36.5 28.5M39 29.5C39 31.9853 36.9853 34 34.5 34C32.0147 34 30 31.9853 30 29.5C30 27.0147 32.0147 25 34.5 25C36.9853 25 39 27.0147 39 29.5Z"
        stroke={color || "#A444B7"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BrowserBookingToolIcon;
