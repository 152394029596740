import { useQuery } from "@apollo/client";

import { LOAD_INVITE_PENDING_USER_QUERY } from "globals/graphql";
import { useSnackbar } from "./useSnackbar";
import { useHistory } from "react-router-dom";

type useLoadPendingUserProps = {
  userId: string;
};

function useLoadPendingUser(props: useLoadPendingUserProps) {
  const { userId } = props;

  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();

  const { data } = useQuery(LOAD_INVITE_PENDING_USER_QUERY, {
    variables: {
      userId,
    },
    fetchPolicy: "network-only",
    onError: (error) => {
      history.replace("/login");

      snackbar.error(
        "Invite to join team is no longer valid. Please contact operator to send another invitation"
      );
    },
  });

  return data?.loadInvitePendingUser || null;
}

export { useLoadPendingUser };
