import { Stop, Vehicle } from "types";
import { shouldShowVehicleAvaliabilityOrBRA } from "./shouldShowVehicleAvaliabilityOrBRA";

// show automated base rate when stops are provided and
// there is a first & last stop location/airport and
// first stop date time
export const shouldShowVehiclesAutomatedBaseRate = (
  vehicle: Vehicle,
  stops: Stop[]
) =>
  vehicle.enableBaseRateAutomation && shouldShowVehicleAvaliabilityOrBRA(stops);
