import { useEffect, useState } from "react";

export function useCreateSubscriptionPaymentMethodForm() {
  // state
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardExpirationComplete, setCardExpirationComplete] = useState(false);
  const [cardCVCComplete, setCardCVCComplete] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  // event handlers
  const handleCardholderNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCardHolderName(e.target.value);
  };

  const handleStripeElementChange = (e) => {
    if (e.elementType === "cardNumber") {
      setCardNumberComplete(e.complete);
    }

    if (e.elementType === "cardExpiry") {
      setCardExpirationComplete(e.complete);
    }

    if (e.elementType === "cardCvc") {
      setCardCVCComplete(e.complete);
    }
  };

  const resetFormState = () => {
    setCardHolderName("");
    setCardNumberComplete(false);
    setCardExpirationComplete(false);
    setCardCVCComplete(false);
    setSubmitDisabled(true);
  };

  // effects
  useEffect(() => {
    if (
      cardHolderName.length &&
      cardNumberComplete &&
      cardExpirationComplete &&
      cardCVCComplete
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [
    cardHolderName,
    cardNumberComplete,
    cardExpirationComplete,
    cardCVCComplete,
  ]);

  return {
    submitDisabled,
    setSubmitDisabled,
    cardHolderName,
    handleCardholderNameChange,
    handleStripeElementChange,
    resetFormState,
  };
}
