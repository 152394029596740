import React from "react";

type EmptyCircleIconProps = {
  color?: string;
};

const EmptyCircleIcon = (props: EmptyCircleIconProps) => {
  const { color } = props;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="30" height="30" rx="15" fill="white" />
      <rect
        x="1"
        y="1"
        width="30"
        height="30"
        rx="15"
        stroke={color || "#195FE9"}
        strokeWidth="2"
      />
    </svg>
  );
};

export default EmptyCircleIcon;
