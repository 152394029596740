import React, { useState } from "react";

import ActionButton from "design-system/components/ActionButton";
import { ChargeCustomerIcon } from "design-system/icons";
import ChargePayerDialog from "components/requests/ChargePayerDialog/ChargePayerDialog";
import { FarmedRouteStatusEnum, PaymentPayer, Request, Trip } from "types";
import { shapeTripsToTripChargeDetailsList } from "components/requests/ChargePayerDialog/utils";
import { deriveIsFarmee } from "globals/utils/farm";
import first from "lodash/first";
import { useAnalytics } from "globals/hooks";

// constants
const { Accepted, Completed } = FarmedRouteStatusEnum;

type ChargeReservationActionButtonProps = {
  request: Request;
  trip: Trip;
};

function ChargeReservationActionButton(
  props: ChargeReservationActionButtonProps
) {
  const { request, trip } = props;

  // hooks
  const { track } = useAnalytics();

  // derived state
  const route = first(trip.routes);
  const isFarmee = deriveIsFarmee(route);
  const isFarmedRouteAccepted =
    route.farmStatus === Accepted || route.farmStatus === Completed;
  const isCancelledTrip = trip.cancelledAt;
  const canChargeReservation =
    !isCancelledTrip && (!isFarmee || (isFarmee && isFarmedRouteAccepted));
  const isCompanyRequest = !!request.company && !isFarmee;

  // state
  const [chargePayerDialogOpen, setChargePayerDialogOpen] = useState(false);

  // event handler
  const handleChargeClick = () => {
    track("reservations_chargeResInitiated");
    setChargePayerDialogOpen(true);
  };

  return (
    <>
      <ActionButton
        icon={ChargeCustomerIcon}
        label="Charge"
        onClick={handleChargeClick}
        disabled={!canChargeReservation}
        disableTooltipText={
          isCancelledTrip
            ? "Cannot update pricing on a cancelled trip."
            : "Farm-In must be accepted."
        }
      />
      {chargePayerDialogOpen && (
        <ChargePayerDialog
          trip={trip}
          onClose={() => setChargePayerDialogOpen(false)}
          tripChargeDetailsList={shapeTripsToTripChargeDetailsList(
            request.trips,
            request.amountDue
          )}
          requestId={request.id}
          amountDue={request.amountDue}
          payerBeingCharged={
            isFarmee
              ? {
                  payerType: PaymentPayer.Farmor,
                  payerId: route.farmAffiliate.id,
                }
              : {
                  payerType: PaymentPayer.Customer,
                  payerId: request.bookingContact.id,
                }
          }
          paymentMethods={
            isFarmee
              ? route.farmAffiliate.paymentMethods || []
              : request.bookingContact.paymentMethods || []
          }
          preferredPaymentMethod={
            isFarmee ? null : request.preferredPaymentMethod
          }
          {...(isCompanyRequest && {
            companyPaymentMethods: request.company.paymentMethods,
          })}
        />
      )}
    </>
  );
}

export default ChargeReservationActionButton;
