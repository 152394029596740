import React from "react";

type OfficeIconProps = {
  color?: string;
  size?: "small";
};

const OfficeIcon = (props: OfficeIconProps) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#office-icon-clip-path)">
        <path
          d="M2.86667 3.86667V15H13.1333V3.86667M2.86667 3.86667L3.8 1H12.2L13.1333 3.86667M2.86667 3.86667H8H13.1333M1 15H15M9.4 13.6C9.4 13.2287 9.2525 12.8726 8.98995 12.6101C8.7274 12.3475 8.3713 12.2 8 12.2C7.6287 12.2 7.2726 12.3475 7.01005 12.6101C6.7475 12.8726 6.6 13.2287 6.6 13.6V15H9.4V13.6Z"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.22656 6.73043C5.28666 6.73043 5.3443 6.75431 5.3868 6.7968C5.42929 6.8393 5.45317 6.89694 5.45317 6.95704"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 6.95704C5 6.89694 5.02387 6.8393 5.06637 6.7968C5.10887 6.75431 5.16651 6.73043 5.22661 6.73043"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.22661 7.18364C5.16651 7.18364 5.10887 7.15977 5.06637 7.11727C5.02387 7.07477 5 7.01713 5 6.95704"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.45317 6.95704C5.45317 7.01713 5.42929 7.07477 5.3868 7.11727C5.3443 7.15977 5.28666 7.18364 5.22656 7.18364"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.22656 9.44969C5.28666 9.44969 5.3443 9.47357 5.3868 9.51606C5.42929 9.55856 5.45317 9.6162 5.45317 9.6763"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 9.6763C5 9.6162 5.02387 9.55856 5.06637 9.51606C5.10887 9.47357 5.16651 9.44969 5.22661 9.44969"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.22661 9.9029C5.16651 9.9029 5.10887 9.87903 5.06637 9.83653C5.02387 9.79403 5 9.7364 5 9.6763"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.45317 9.6763C5.45317 9.7364 5.42929 9.79403 5.3868 9.83653C5.3443 9.87903 5.28666 9.9029 5.22656 9.9029"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.9458 6.73043C8.0059 6.73043 8.06354 6.75431 8.10603 6.7968C8.14853 6.8393 8.17241 6.89694 8.17241 6.95704"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.71924 6.95704C7.71924 6.89694 7.74311 6.8393 7.78561 6.7968C7.82811 6.75431 7.88574 6.73043 7.94584 6.73043"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.94584 7.18364C7.88574 7.18364 7.82811 7.15977 7.78561 7.11727C7.74311 7.07477 7.71924 7.01713 7.71924 6.95704"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.17241 6.95704C8.17241 7.01713 8.14853 7.07477 8.10603 7.11727C8.06354 7.15977 8.0059 7.18364 7.9458 7.18364"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.9458 9.44969C8.0059 9.44969 8.06354 9.47357 8.10603 9.51606C8.14853 9.55856 8.17241 9.6162 8.17241 9.6763"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.71924 9.6763C7.71924 9.6162 7.74311 9.55856 7.78561 9.51606C7.82811 9.47357 7.88574 9.44969 7.94584 9.44969"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.94584 9.9029C7.88574 9.9029 7.82811 9.87903 7.78561 9.83653C7.74311 9.79403 7.71924 9.7364 7.71924 9.6763"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.17241 9.6763C8.17241 9.7364 8.14853 9.79403 8.10603 9.83653C8.06354 9.87903 8.0059 9.9029 7.9458 9.9029"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6653 6.73043C10.7254 6.73043 10.783 6.75431 10.8255 6.7968C10.868 6.8393 10.8919 6.89694 10.8919 6.95704"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4385 6.95704C10.4385 6.89694 10.4624 6.8393 10.5048 6.7968C10.5473 6.75431 10.605 6.73043 10.6651 6.73043"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6651 7.18364C10.605 7.18364 10.5473 7.15977 10.5048 7.11727C10.4624 7.07477 10.4385 7.01713 10.4385 6.95704"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8919 6.95704C10.8919 7.01713 10.868 7.07477 10.8255 7.11727C10.783 7.15977 10.7254 7.18364 10.6653 7.18364"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6653 9.44969C10.7254 9.44969 10.783 9.47357 10.8255 9.51606C10.868 9.55856 10.8919 9.6162 10.8919 9.6763"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4385 9.6763C10.4385 9.6162 10.4624 9.55856 10.5048 9.51606C10.5473 9.47357 10.605 9.44969 10.6651 9.44969"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6651 9.9029C10.605 9.9029 10.5473 9.87903 10.5048 9.83653C10.4624 9.79403 10.4385 9.7364 10.4385 9.6763"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8919 9.6763C10.8919 9.7364 10.868 9.79403 10.8255 9.83653C10.783 9.87903 10.7254 9.9029 10.6653 9.9029"
          stroke={color || "#999999"}
          strokeWidth="1.5528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="office-icon-clip-path">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OfficeIcon;
