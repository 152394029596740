import React from "react";
import first from "lodash/first";
import { Box, Typography } from "@mui/material";
import EmailRecipientChipTooltip from "./Email/EmailRecipientChipTooltip";

type ToAndCCLabelProps = {
  toRecipients: string[];
  ccRecipients: string[];
  onRecipientsDrawerOpen: () => void;
};

function ToAndCCLabel(props: ToAndCCLabelProps) {
  const { toRecipients, ccRecipients, onRecipientsDrawerOpen } = props;

  const styles = {
    text: {
      fontWeight: 500,
    },
  };

  // derived state
  let toRecipientArray = [first(toRecipients)];
  const remainingToRecipients = toRecipients.slice(1);

  // helper function components
  const generateSingleToRecipientLabel = () => {
    return (
      <Box display="flex" alignItems="center" flexWrap="wrap">
        {toRecipientArray.map((recipient) => {
          return (
            <Typography variant="body2" style={styles.text} key={recipient}>
              {recipient}{" "}
            </Typography>
          );
        })}
      </Box>
    );
  };

  const generateMultipleToRecipientsLabel = (chipRecipients: string[]) => {
    return (
      <Box display="flex" alignItems="center" flexWrap="wrap">
        {toRecipientArray.map((recipient, index) => {
          return (
            <Typography variant="body2" style={styles.text} key={recipient}>
              {recipient}
              {index < toRecipientArray.length - 1 ? ", " : null}
            </Typography>
          );
        })}
        {!!chipRecipients.length && (
          <>
            <Typography> </Typography>
            <EmailRecipientChipTooltip
              onDrawerOpen={onRecipientsDrawerOpen}
              list={chipRecipients}
            />
          </>
        )}
        <Typography> </Typography>
      </Box>
    );
  };

  const generateToAndCCRecipientsLabel = (
    ccRecipientString: string,
    remainingCCRecipients: string[]
  ) => {
    return (
      <Box display="flex" alignItems="center" flexWrap="wrap">
        {toRecipientArray.map((recipient, index) => {
          return (
            <Typography
              variant="body2"
              style={styles.text}
              alignItems="center"
              key={recipient}
            >
              {recipient}
              {index < toRecipientArray.length - 1 ? ", " : " "}
            </Typography>
          );
        })}
        {!!remainingToRecipients.length ? " " : null}
        {!!remainingToRecipients.length && (
          <EmailRecipientChipTooltip
            onDrawerOpen={onRecipientsDrawerOpen}
            list={remainingToRecipients}
          />
        )}
        <Typography>{"; cc: "}</Typography>
        <Typography variant="body2" sx={[styles.text]}>
          {ccRecipientString}
          {!!remainingCCRecipients.length ? " " : null}
        </Typography>
        {!!remainingCCRecipients.length && (
          <EmailRecipientChipTooltip
            onDrawerOpen={onRecipientsDrawerOpen}
            list={remainingCCRecipients}
          />
        )}
        <Typography> </Typography>
      </Box>
    );
  };

  if (!ccRecipients.length) {
    // only to-recipients, add the second if possible, then chip the rest
    // single to-recipient
    if (!remainingToRecipients.length) {
      return generateSingleToRecipientLabel();
    } else {
      // multiple to-recipients
      toRecipientArray.push(first(remainingToRecipients));
      const chipRecipients = remainingToRecipients.slice(1);
      return generateMultipleToRecipientsLabel(chipRecipients);
    }
  } else {
    // to- and cc-recipients
    let ccRecipientString = first(ccRecipients);
    const remainingCCRecipients = ccRecipients.slice(1);
    return generateToAndCCRecipientsLabel(
      ccRecipientString,
      remainingCCRecipients
    );
  }
}

export default ToAndCCLabel;
