export const freeWebsiteTemplates = [
  {
    name: "Template 1",
    subtitle: "Small business",
    dudaTemplateName: "Operator Site 1",
    templateId: "1214677",
    desktopThumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/duda_website%2Fthumbnails%2Fdesktop%2FLanding1Desktop.jpg?alt=media&token=ba4323c7-cc59-4f90-aedb-c1bff41eb56f",
    mobileThumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/duda_website%2Fthumbnails%2Fmobile%2FLanding1Mobile.jpg?alt=media&token=3361baae-f094-468d-8221-bb8a4e78f5ad",
    previewUrl:
      "http://moovs-website.responsivewebsitebuilder.io/preview/4ea671f0",
  },
  {
    name: "Template 2",
    subtitle: "Small business",
    dudaTemplateName: "Operator Site 2",
    templateId: "1214307",
    desktopThumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/duda_website%2Fthumbnails%2Fdesktop%2FLanding2Desktop.jpg?alt=media&token=9c5b3344-e2b2-4f11-a819-926bd4da413c",
    mobileThumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/duda_website%2Fthumbnails%2Fmobile%2FLanding2Mobile.jpg?alt=media&token=3b9912cc-3fb5-4497-812c-9448535d9334",
    previewUrl:
      "http://moovs-website.responsivewebsitebuilder.io/preview/5736af23",
  },
  {
    name: "Template 3",
    subtitle: "Small business",
    dudaTemplateName: "Operator Template 3",
    templateId: "1214281",
    desktopThumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/duda_website%2Fthumbnails%2Fdesktop%2FLanding3Desktop.jpg?alt=media&token=6e280c3d-21cb-4c85-a66e-2d7f56e44144",
    mobileThumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/duda_website%2Fthumbnails%2Fmobile%2FLanding3Mobile.jpg?alt=media&token=14054935-1e4f-4267-aabf-c84551a621e1",
    previewUrl:
      "http://moovs-website.responsivewebsitebuilder.io/preview/1a49a2ba",
  },
  {
    name: "Template 4",
    subtitle: "Small business",
    dudaTemplateName: "Operator Template 4",
    templateId: "1214666",
    desktopThumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/duda_website%2Fthumbnails%2Fdesktop%2FLanding4Desktop.jpg?alt=media&token=2934d614-d478-438e-aacd-f83cca39c1b3",
    mobileThumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/duda_website%2Fthumbnails%2Fmobile%2FLanding-4Mobile.jpg?alt=media&token=f5355973-4ea7-4479-bc95-2bafb460eb46",
    previewUrl:
      "http://moovs-website.responsivewebsitebuilder.io/preview/8958d2f9",
  },
];

export const paidWebsiteTemplates = [
  {
    name: "Premium Template 1",
    subtitle: "Small business",
    dudaTemplateName: "Premium Template 1",
    templateId: "1216725",
    desktopThumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/duda_website%2Fthumbnails%2Fdesktop%2FPremiumLanding1Desktop.jpg?alt=media&token=3a6d71ea-0149-4484-9e0e-9da01b73931f",
    mobileThumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/duda_website%2Fthumbnails%2Fmobile%2FPremiumLanding1Mobile.jpg?alt=media&token=e1884c3f-a9ba-4d09-87c1-4490903cc67d",
    previewUrl:
      "http://moovs-website.responsivewebsitebuilder.io/preview/c1062fd0",
  },
  {
    name: "Premium Template 2",
    subtitle: "Small business",
    dudaTemplateName: "Premium Template 2",
    templateId: "1216839",
    desktopThumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/duda_website%2Fthumbnails%2Fdesktop%2FPremiumLanding3Desktop.jpg?alt=media&token=3f47aed6-bb43-4271-8c00-018b046bece2",
    mobileThumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/duda_website%2Fthumbnails%2Fmobile%2FPremiumLanding3Mobile.jpg?alt=media&token=eb617972-ab23-4cd4-a19f-fad007e961ec",
    previewUrl:
      "http://moovs-website.responsivewebsitebuilder.io/preview/fc25127f",
  },
  {
    name: "Premium Template 3",
    subtitle: "Small business",
    dudaTemplateName: "Premium Template 3",
    templateId: "1216845",
    desktopThumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/duda_website%2Fthumbnails%2Fdesktop%2FPremiumLanding2Desktop.jpg?alt=media&token=996b5364-a91d-4a4d-a19a-39d18bea5c15",
    mobileThumbnailUrl:
      "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/duda_website%2Fthumbnails%2Fmobile%2FPremiumLanding2Mobile.jpg?alt=media&token=8cad0675-57ac-48a9-b7a0-b7f75ff15555",
    previewUrl:
      "http://moovs-website.responsivewebsitebuilder.io/preview/6b5cd83f",
  },
];
