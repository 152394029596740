import React from "react";
import moment from "moment";

import { Typography, Box } from "@mui/material";

import RoundChip from "design-system/components/chips/RoundChip";
import { currency } from "globals/utils/helpers";
import { grayLight, successGreen, white } from "design-system/colors";
import { InstantPayoutsListItemProps } from "./InstantPayoutsListItem.desktop";

function InstantPayoutsListItemMobile(props: InstantPayoutsListItemProps) {
  const { stripePayout } = props;

  return (
    <Box
      border={`1px solid ${grayLight}`}
      borderRadius="4px"
      mb={1}
      p={2}
      boxShadow="0px 4px 15px 0px rgba(0, 0, 0, 0.03)"
    >
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column">
          <Typography variant="body2" fontWeight={700}>
            Payout
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body2">
              {moment.utc(stripePayout.payoutDate).format("MM/DD/YY")}
            </Typography>
            <Box ml={1}>
              <RoundChip
                label="INSTANT PAID"
                color={white}
                backgroundColor={successGreen}
              />
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography variant="body2" fontWeight={700}>
            {currency(stripePayout.amount)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default InstantPayoutsListItemMobile;
