import { Box } from "@mui/material";

import ToggleHeader from "../ToogleHeader";
import TripStatusTriggersNotSetNotification from "./TripStatusTriggersNotSetNotification";
import SendTripStatusRecipientsBlock from "./SendTripStatusRecipientsBlock";
import SendTripStatusTriggeredByBlock from "./SendTripStatusTriggeredByBlock";
import { useOperator, useScreenSize } from "globals/hooks";
import SendPOBTripStatusUpdateBlock from "./SendPOBTripStatusUpdateBlock";

type SendTripStatusUpdateBlockProps = {
  setSaveIndicatorState: (value: string) => void;
};

function SendTripStatusUpdateBlock(props: SendTripStatusUpdateBlockProps) {
  const { setSaveIndicatorState } = props;

  const { isMobileView } = useScreenSize();
  const {
    driverStatusUpdateTextsEnabled,
    settings: { memberChangedStatusTextEnabled },
  } = useOperator();

  const shouldShowTriggersNotSetNotification =
    !driverStatusUpdateTextsEnabled && !memberChangedStatusTextEnabled;

  return (
    <>
      <ToggleHeader
        text="Send Trip Status Updates"
        subText="Specify what will trigger Trip Status Update SMS notifications (on the way, on location, done) to go out."
      />

      {shouldShowTriggersNotSetNotification && (
        <Box my={2}>
          <TripStatusTriggersNotSetNotification />
        </Box>
      )}

      <Box
        display="flex"
        my={2}
        flexDirection={isMobileView ? "column" : "row"}
      >
        <SendTripStatusTriggeredByBlock
          setSaveIndicatorState={setSaveIndicatorState}
        />
        <SendTripStatusRecipientsBlock
          setSaveIndicatorState={setSaveIndicatorState}
        />
      </Box>

      <SendPOBTripStatusUpdateBlock
        setSaveIndicatorState={setSaveIndicatorState}
      />
    </>
  );
}

export default SendTripStatusUpdateBlock;
