import React from "react";
import { useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";

import { grayDark } from "design-system/colors";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { ConfirmReservationDisabledVariantEnum } from "types";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";
import { CheckIcon } from "design-system/icons";
import {
  CONFIRM_REQUEST_MUTATION,
  UNDO_CONVERT_QUOTE_MUTATION,
} from "globals/graphql";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type UseMakeReservationClickProps = {
  confirmReservationDisabledVariant: ConfirmReservationDisabledVariantEnum;
  requestId: string;
  analyticsName?: string;
};

function useMakeReservationClick(
  props: UseMakeReservationClickProps
): EllipsisMenuOption {
  const { analyticsName, requestId, confirmReservationDisabledVariant } = props;

  // hooks
  const { track } = useAnalytics();
  const snackbar = useSnackbar();
  const history = useHistory();
  const location = useLocation();

  // mutations
  const [confirmRequest] = useMutation(CONFIRM_REQUEST_MUTATION, {
    refetchQueries: ["LoadQuotes", "Operator", "LoadNewQuotes"],
    awaitRefetchQueries: true,
    onCompleted() {
      analyticsName && track(analyticsName);

      snackbar.success("Successfully confirmed quote", {
        link: `/reservations/${requestId}/`,
        linkLabel: "View Reservation",
        onLinkClick: () => {
          track("snackbar_reservationViewed");
        },
        onUndo: handleClickUndo,
      });

      track("quote_converted");

      history.push(`/quotes${location.search}`);
    },
    onError(error) {
      const errorMessage =
        getErrorMessage(error) || "Error confirming reservation.";

      snackbar.error(errorMessage);
      console.log(error);
    },
  });

  const [undoConvertQuote] = useMutation(UNDO_CONVERT_QUOTE_MUTATION, {
    refetchQueries: ["Requests", "Request"],
    onCompleted() {
      snackbar.success(`Successfully reverted to quote!`);
    },
    onError(error) {
      const errorMessage =
        getErrorMessage(error) || "Error reverting reservation to quote";

      snackbar.error(errorMessage);
    },
  });

  // event handlers
  const handleConvertQuoteToReservation = () => {
    confirmRequest({
      variables: {
        input: {
          requestId,
        },
      },
    });
  };

  const handleClickUndo = () => {
    undoConvertQuote({
      variables: {
        input: {
          requestId,
        },
      },
    });
  };

  // derived state
  const disableMakeReservation =
    confirmReservationDisabledVariant &&
    confirmReservationDisabledVariant !==
      ConfirmReservationDisabledVariantEnum.ReservationCutoff;

  return {
    text: "Make Reservation",
    icon: <CheckIcon color={grayDark} size="small" />,
    onClick: handleConvertQuoteToReservation,
    disableOption: disableMakeReservation,
    tooltip: disableMakeReservation
      ? {
          titleText: determineToolTipText(confirmReservationDisabledVariant),
        }
      : null,
  };
}

export { useMakeReservationClick };

const determineToolTipText = (
  disabledVariant: ConfirmReservationDisabledVariantEnum
) => {
  if (disabledVariant === ConfirmReservationDisabledVariantEnum.RequestArchived)
    return "Can't make reservation when quote is archived";

  if (disabledVariant === ConfirmReservationDisabledVariantEnum.RequestExpired)
    return "Can't make reservation when pickup date is in the past";

  if (disabledVariant === ConfirmReservationDisabledVariantEnum.NoVehicle)
    return "Can't make reservation when a vehicle is not assigned to every trip";

  if (disabledVariant === ConfirmReservationDisabledVariantEnum.NoPricing)
    return "Can't make reservation when pricing is missing from at least one trip";

  return null;
};
