/**
 * @file FarmAffiliatesPage.tsx
 *
 * components:
 * FarmAffiliatesPage
 *
 */

import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import { useDebounce } from "use-debounce";

import { Box, CircularProgress, Grid, Typography } from "@mui/material";

import ContactsPageTabs from "../../components/contacts/ContactsPageTabs";
import {
  useAnalytics,
  useCurrentUser,
  useOperator,
  useScreenSize,
} from "../../globals/hooks";
import FarmAffiliateCard from "../../components/contacts/farmAffiliates/FarmAffiliateCard";
import { LOAD_FARM_AFFILIATES_QUERY } from "../../globals/graphql";
import GQLQueryStatusIndicator from "../../components/GQLQueryStatusIndicator";
import { FarmAffiliate, FarmAffiliateVariantEnum } from "../../types";
import MoovsInfiniteScroll from "components/MoovsInfiniteScroll";
import EnableMoovsNetworkDialog from "./components/EnableMoovsNetworkDialog";
import EnableMoovsNetworkBanner from "./components/EnableMoovsNetworkBanner";
import filter from "lodash/filter";

const {
  ExternalOperator,
  GriddnetOperator,
  MoovsNetworkOperator,
  MoovsAiOperator,
} = FarmAffiliateVariantEnum;

function FarmAffiliatesPage() {
  // hooks
  const { isMediumTabletView } = useScreenSize();
  const { track } = useAnalytics();
  const operator = useOperator();
  const isMoovsNetworkEnabled = !!operator?.moovsNetworkEnabled;
  const { shouldHideAffiliates } = useCurrentUser();
  const history = useHistory();

  // state
  const [enrollInMoovsDialogOpen, setEnrollInMoovsDialogOpen] = useState(false);
  const [viewFilter, setViewFilter] = useState<FarmAffiliateVariantEnum[]>([
    ExternalOperator,
    GriddnetOperator,
    MoovsNetworkOperator,
    MoovsAiOperator,
  ]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

  // queries
  const {
    data: farmAffiliatesData,
    error: farmAffiliatesError,
    loading: farmAffiliatesLoading,
    fetchMore: farmAffiliatesFetchMore,
    refetch: farmAffiliatesRefetch,
  } = useQuery(LOAD_FARM_AFFILIATES_QUERY, {
    variables: {
      viewFilter,
      searchTerm: debouncedSearchTerm,
      limit: 20,
      cursor: null,
    },
    fetchPolicy: "cache-and-network",
  });

  const farmAffiliates = farmAffiliatesData?.loadFarmAffiliates?.edges;

  // effect
  useEffect(() => {
    if (shouldHideAffiliates) {
      history.replace("/contacts");
    }
  }, [history, shouldHideAffiliates]);

  // event handlers
  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchTermFocus = () => {
    track("affiliates_affiliateSearched");
  };

  const handleViewFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const nextViewFilter = event.target.checked
      ? [...viewFilter, event.target.name as FarmAffiliateVariantEnum]
      : filter(viewFilter, (view) => view !== event.target.name);

    setViewFilter(nextViewFilter);
  };

  const handleFetchMore = () => {
    farmAffiliatesFetchMore({
      variables: {
        cursor: farmAffiliatesData?.loadFarmAffiliates?.pageInfo?.endCursor,
      },
    });
  };

  const handleEnrollButtonClick = () => {
    setEnrollInMoovsDialogOpen(true);
  };
  const handleEnrollInMoovsDialogClose = () => {
    setEnrollInMoovsDialogOpen(false);
  };

  return (
    <>
      <Box display="flex" flex="1" flexDirection="column" width="100%">
        <div data-testid="entity-header">
          <ContactsPageTabs
            title="Affiliates"
            entityName="Affiliate"
            createEntityRoute="/affiliates/create"
            tabValue={2}
            viewFilterProps={{
              viewFilter,
              onViewFilterChange: handleViewFilterChange,
            }}
            searchTerm={searchTerm}
            onSearchTermChange={handleSearchTermChange}
            onSearchTermFocus={handleSearchTermFocus}
          />
        </div>

        {farmAffiliatesError && (
          <GQLQueryStatusIndicator
            error={farmAffiliatesError}
            name="Affiliates Data"
            refetch={farmAffiliatesRefetch}
          />
        )}

        <Box
          maxWidth={900}
          {...(!isMediumTabletView && { width: "100%" })}
          margin={`24px ${isMediumTabletView ? "16px" : "auto"}`}
        >
          {operator && !isMoovsNetworkEnabled && (
            <Box mx={1} mb={1}>
              <EnableMoovsNetworkBanner
                onEnrollButtonClick={handleEnrollButtonClick}
              />
            </Box>
          )}

          {farmAffiliatesLoading && !farmAffiliates && (
            <Box
              display="flex"
              flexDirection="row"
              flex="1"
              px={3}
              mt={2}
              mb={1}
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          )}

          {farmAffiliates &&
            (isEmpty(farmAffiliates) && !farmAffiliatesLoading ? (
              <Box display="flex" justifyContent="center" m={10}>
                <Typography variant="subtitle1">No Affiliates Found</Typography>
              </Box>
            ) : (
              <MoovsInfiniteScroll
                data={farmAffiliatesData?.loadFarmAffiliates}
                loading={farmAffiliatesLoading}
                next={handleFetchMore}
                name="affiliates"
              >
                <Grid
                  container
                  spacing={2}
                  data-testid="farm-affiliates-list-container"
                  style={{ margin: 0, width: "100%" }} //style override to prevent horizontal scroll
                >
                  {farmAffiliates.map(
                    (farmAffiliate: { node: FarmAffiliate }, i: number) => (
                      <Grid item xs={12} sm={6} lg={4} key={i}>
                        <FarmAffiliateCard farmAffiliate={farmAffiliate.node} />
                      </Grid>
                    )
                  )}
                </Grid>
              </MoovsInfiniteScroll>
            ))}
        </Box>
      </Box>
      <EnableMoovsNetworkDialog
        open={enrollInMoovsDialogOpen}
        onClose={handleEnrollInMoovsDialogClose}
      />
    </>
  );
}

export default FarmAffiliatesPage;
