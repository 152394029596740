import React from "react";
import first from "lodash/first";
import moment from "moment";

import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Stop } from "types";

function DateColumn(props: GridRenderCellParams<string>) {
  const { row } = props;

  const tripPickUpDate = moment
    .utc(first(row.stops as unknown as Stop[]).dateTime)
    .format("M/DD/YY");

  return <Typography variant="body2">{tripPickUpDate}</Typography>;
}

export default DateColumn;
