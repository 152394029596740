import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

import RemoveDialog from "components/RemoveDialog";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { CANCEL_TRIP_MUTATION } from "globals/graphql";
import { confirmationNumberFromRequest } from "globals/utils/helpers";
import { Request, Trip } from "types";

type CancelTripDialogProps = {
  open: boolean;
  onClose: () => void;
  trip: Trip;
  request: Request;
  analyticsName?: string;
};

function CancelTripDialog(props: CancelTripDialogProps) {
  const { open, onClose, trip, request, analyticsName } = props;

  // derived state
  const confirmationNumber = confirmationNumberFromRequest(request, trip);

  // hooks
  const { track } = useAnalytics();
  const snackbar = useSnackbar();
  const history = useHistory();

  // mutations
  const [cancelTrip] = useMutation(CANCEL_TRIP_MUTATION, {
    refetchQueries: ["Request"],
    onCompleted(data) {
      const { result } = data.cancelTripV2;

      if (result.__typename === "CancelTripFailure") {
        snackbar.error(result.message);
        return;
      }

      snackbar.success(`Successfully cancelled ${confirmationNumber}!`);
    },
    onError(error) {
      snackbar.error(`Error cancelling ${confirmationNumber}`);
    },
  });

  // event handlers
  const handleCancelTrip = () => {
    if (analyticsName) track(analyticsName);
    cancelTrip({
      variables: {
        input: {
          tripId: trip.id,
        },
      },
    });
    onClose();
    history.push(`/reservations/${request.id}`);
  };

  return (
    <RemoveDialog
      open={open}
      onClose={onClose}
      onRemove={handleCancelTrip}
      title="Cancel Trip"
      body="Are you sure you want to cancel this Trip?"
      removeButtonText="Yes, Cancel Trip"
      cancelButtonText="No, Keep"
    />
  );
}

export default CancelTripDialog;
