import { WorkflowSelectionsEnum } from "../steps/workflow/schemaValidation";
import useWorkflowForm from "../steps/workflow/useWorkflowForm";
import useCodeAndAmountForm from "../steps/codeAndAmount/useCodeAndAmountForm";
import useDateRangeForm from "../steps/dateRange/useDateRangeForm";
import useLimitsForm from "../steps/limits/useLimitsForm";
import { getFormComponentSteps } from "../utils/getFormComponentSteps";
import { getEventHandlerSteps } from "../utils/getEventHandlerSteps";
import { getSubHeaderSteps } from "../utils/getSubHeaderSteps";

function useInitializeCreatePromoCodeForms(workflow: WorkflowSelectionsEnum) {
  // workflow
  const {
    handleWorkflowSubmit,
    workFlowFormComponent,
    resetWorkflowForm,
    setWorkflowOutline,
  } = useWorkflowForm();

  // codeAndAmount workflow
  const {
    handleCodeAndAmountSubmit,
    codeAndAmountFormComponent,
    resetCodeAndAmountForm,
  } = useCodeAndAmountForm();

  // bookingDate workflow
  const {
    handleDateRangeSubmit: handleBookingDateSubmit,
    dateRangeFormComponent: bookingDateFormComponent,
    resetDateRangeForm: resetBookingDateForm,
  } = useDateRangeForm("bookingDate");

  // tripDate workflow
  const {
    handleDateRangeSubmit: handleTripDateSubmit,
    dateRangeFormComponent: tripDateFormComponent,
    resetDateRangeForm: resetTripDateForm,
  } = useDateRangeForm("tripDate");

  // limits workflow
  const { handleLimitsSubmit, limitsFormComponent, resetLimitsForm } =
    useLimitsForm();

  const { formComponentSteps } = getFormComponentSteps({
    workflow,
    workFlowFormComponent,
    codeAndAmountFormComponent,
    bookingDateFormComponent,
    tripDateFormComponent,
    limitsFormComponent,
  });

  const eventHandlerSteps = getEventHandlerSteps({
    workflow,
    handleWorkflowSubmit,
    handleCodeAndAmountSubmit,
    handleBookingDateSubmit,
    handleTripDateSubmit,
    handleLimitsSubmit,
  });

  const resetAllForms = () => {
    resetWorkflowForm();
    resetCodeAndAmountForm();
    resetBookingDateForm();
    resetTripDateForm();
    resetLimitsForm();
  };

  const subHeaderSteps = getSubHeaderSteps(workflow);

  return {
    formComponentSteps,
    eventHandlerSteps,
    resetAllForms,
    setWorkflowOutline,
    subHeaderSteps,
  };
}

export default useInitializeCreatePromoCodeForms;
