import React, { useMemo } from "react";

import { Box, Tooltip, Typography } from "@mui/material";

import { grayDark, white } from "design-system/colors";

type EmailRecipientChipTooltipProps = {
  onDrawerOpen: () => void;
  list: string[];
};

function EmailRecipientChipTooltip(props: EmailRecipientChipTooltipProps) {
  const { onDrawerOpen, list } = props;

  // derived state
  const tooltipContent = useMemo(() => {
    return list.map((item) => <Box key={item}>{item}</Box>);
  }, [list]);

  return (
    <Tooltip title={<Box>{tooltipContent}</Box>}>
      <Box
        display="flex"
        justifyContent="center"
        width="24px"
        height="24px"
        sx={{
          backgroundColor: grayDark,
          borderRadius: "50%",
          padding: "4px",
        }}
        onClick={(event) => {
          event.stopPropagation();
          onDrawerOpen();
        }}
      >
        <Typography color={white} fontSize="12px">
          +{list.length}
        </Typography>
      </Box>
    </Tooltip>
  );
}

export default EmailRecipientChipTooltip;
