import React from "react";

import { Box, Typography } from "@mui/material";

import { grayMedium, alabaster } from "../../design-system/colors";
import { Operator } from "../../types";

import {
  freeWebsiteTemplates,
  paidWebsiteTemplates,
} from "../../globals/utils/templates";
import { useScreenSize } from "../../globals/hooks";

type WebsiteTemplatePreviewProps = {
  operator: Partial<Operator>;
};

function WebsiteTemplatePreview(props: WebsiteTemplatePreviewProps) {
  const { operator } = props;

  const { isMobileView } = useScreenSize();

  const templateId = operator?.dudaWebsite?.templateId;

  const template = [...freeWebsiteTemplates, ...paidWebsiteTemplates].find(
    (template) => template.templateId === templateId
  );

  const templateName = template?.name;
  const templateDescription = "Small business";
  const websiteTemplatePreviewImageUrl = template?.desktopThumbnailUrl;

  return (
    <Box display="flex" flexDirection="column">
      <Box mb={2}>
        <Typography variant="overline">Template</Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={isMobileView ? "304px" : "360px"}
        borderRadius="4px"
        border={`1px solid ${grayMedium}`}
      >
        <Box
          mb={2}
          borderBottom={`1px solid ${grayMedium}`}
          maxHeight={isMobileView ? "403px" : "403px"}
          overflow="hidden"
        >
          <img
            alt="media"
            style={{
              width: "100%",
              borderRadius: "2px",
            }}
            src={websiteTemplatePreviewImageUrl}
          />
        </Box>
        <Box m={2} display="flex" flexDirection="column" bgcolor={alabaster}>
          <Box mb={0.5}>
            <Typography variant="h4">{templateName}</Typography>
            <Typography variant="caption">{templateDescription}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default WebsiteTemplatePreview;
