import React from "react";

import { black, moovsBlueSelected } from "design-system/colors";
import MoovsBanner from "components/MoovsBanner";
import { Box } from "@mui/material";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { useMutation } from "@apollo/client";
import {
  ACCEPT_UNCONFIRMED_REQUEST_MUTATION,
  LOAD_REQUEST_QUERY,
} from "globals/graphql";
import { trackReservations } from "globals/utils/tracking/trackReservations";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type AcceptDeclinedRequestBannerProps = {
  isDeclined: boolean;
  requestId: string;
};

function AcceptDeclinedRequestBanner(props: AcceptDeclinedRequestBannerProps) {
  const { isDeclined, requestId } = props;

  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  const [acceptUnconfirmedReservation] = useMutation(
    ACCEPT_UNCONFIRMED_REQUEST_MUTATION,
    {
      refetchQueries: [
        {
          query: LOAD_REQUEST_QUERY,
          variables: { id: requestId },
        },
      ],
      onCompleted(data) {
        snackbar.success("Reservation successfully updated");
        const request = data.acceptUnconfirmedReservation.request;
        trackReservations(track, request, "reservation_accepted");
      },
      onError(error) {
        console.error(error);
        snackbar.error(getErrorMessage(error) || "Error updating reservation");
      },
    }
  );
  // event handlers
  const handleAcceptDeclinedRequest = () => {
    acceptUnconfirmedReservation({
      variables: { input: { requestId } },
    });
  };

  if (!isDeclined) return null;
  return (
    <Box my={1}>
      <MoovsBanner
        bannerVariant="compact"
        bannerText="Reservation Declined"
        actionButtonText="Accept"
        onActionButtonClick={handleAcceptDeclinedRequest}
        bannerBgColor={moovsBlueSelected}
        bannerTextColor={black}
        actionButtonVariant="outlined"
        bannerTextVariant="subtitle2"
      />
    </Box>
  );
}

export default AcceptDeclinedRequestBanner;
