import React, { useState } from "react";

import { Box } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";
import ShuttleRouteAutoComplete from "../autocompletes/ShuttleRouteAutoComplete";
import ShuttleRouteInfoCard from "components/globals/ShuttleRouteInfoCard";
import { ShuttleRoute } from "../../types";
import { TrashIcon } from "design-system/icons";

type ReplaceShuttleRouteDialogProps = {
  companyId: string;
  open: boolean;
  onClose: () => void;
  onSave: (shuttleRoute: ShuttleRoute) => void;
  disabled?: boolean;
};

function ReplaceShuttleRouteDialog(props: ReplaceShuttleRouteDialogProps) {
  const { companyId, open, onClose, onSave, disabled } = props;
  const [selectedShuttleRoute, setSelectedShuttleRoute] =
    useState<ShuttleRoute | null>(null);
  const [error, setError] = useState(null);

  const handleClose = () => {
    onClose();
    setSelectedShuttleRoute(null);
    setError("");
  };

  const handleClickSave = () => {
    if (!selectedShuttleRoute) {
      setError("Please add a route name");
    } else {
      setError("");
      onSave(selectedShuttleRoute);
      setSelectedShuttleRoute(null);
    }
  };

  const handleInputChange = (shuttleRoute) => {
    setSelectedShuttleRoute(shuttleRoute);
    setError("");
  };

  const handleClearSelectedShuttleRoute = () => {
    setSelectedShuttleRoute(null);
  };

  return (
    <MoovsDialog
      open={open}
      onClose={handleClose}
      onAccept={handleClickSave}
      dialogTitle={"Replace Route"}
      acceptButtonText={"Replace"}
      closeButtonText={"Cancel"}
      size="xs"
      acceptDisabled={disabled}
    >
      <Box minHeight={95} display="flex" alignItems="center">
        {selectedShuttleRoute ? (
          <Box width="100%">
            <ShuttleRouteInfoCard
              name={selectedShuttleRoute.name}
              actionButtonIcon={<TrashIcon />}
              onClickActionButton={handleClearSelectedShuttleRoute}
            />
          </Box>
        ) : (
          <ShuttleRouteAutoComplete
            companyId={companyId}
            onChange={handleInputChange}
            label="Search for route name"
            error={!!error}
            helperText={error}
            disabled={disabled}
          />
        )}
      </Box>
    </MoovsDialog>
  );
}

export default ReplaceShuttleRouteDialog;
