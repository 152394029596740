import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import { Card, Box, Typography, CircularProgress } from "@mui/material";

import { useScreenSize, useSnackbar } from "globals/hooks";
import RoundChip from "design-system/components/chips/RoundChip";
import MoovsEllipsisMenu from "components/MoovsEllipsisMenu";
import {
  successGreen,
  tintYellow,
  white,
  yellowDark,
} from "design-system/colors";
import { BankAccount } from "types";
import RemoveDialog from "components/RemoveDialog";
import {
  LOAD_STRIPE_ACCOUNT_QUERY,
  REMOVE_STRIPE_BANK_ACCOUNT_MUTATION,
  UPDATE_DEFAULT_BANK_ACCOUNT_MUTATION,
} from "globals/graphql";
import { InstantPayoutChipIcon } from "design-system/icons";

type BankAccountCardProps = {
  account: BankAccount;
};

function BankAccountCard(props: BankAccountCardProps) {
  const { account } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const snackbar = useSnackbar();

  // state
  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = useState(false);
  const [removableAccountId, setRemovableAccountId] = useState("");

  // mutations
  const [removeStripeBankAccount, { loading: stripeRemoveLoading }] =
    useMutation(REMOVE_STRIPE_BANK_ACCOUNT_MUTATION, {
      refetchQueries: [{ query: LOAD_STRIPE_ACCOUNT_QUERY }],
      onCompleted() {
        snackbar.success("Successfully removed bank account");

        handleRemoveDialogClose();
      },
      onError() {
        snackbar.error("Error removing account");
      },
      awaitRefetchQueries: true,
    });

  const [updateDefaultBankAccount, { loading }] = useMutation(
    UPDATE_DEFAULT_BANK_ACCOUNT_MUTATION,
    {
      onCompleted() {
        snackbar.success("Successfully made bank account default for payment");
      },
      onError() {
        snackbar.error("Error making account default for currency");
      },
      refetchQueries: [{ query: LOAD_STRIPE_ACCOUNT_QUERY }],
      awaitRefetchQueries: true,
    }
  );

  // handlers
  const handleRemoveBankAccount = () => {
    removeStripeBankAccount({
      variables: {
        input: {
          bankAccountId: removableAccountId,
        },
      },
    });
  };

  const handleRemoveDialogClose = () => {
    setDeleteAccountDialogOpen(false);
    setRemovableAccountId("");
  };

  const handleSetDefaultBankAccount = (bankAccountId) => {
    updateDefaultBankAccount({
      variables: {
        input: {
          bankAccountId,
        },
      },
    });
  };

  return (
    <>
      <Card variant="outlined" sx={{ marginBottom: 1 }}>
        <Box
          m={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {/* Bank details */}
          <Box
            display="flex"
            flex="auto"
            maxWidth="65%"
            flexDirection={isMobileView ? "column" : "row"}
            alignItems="center"
          >
            <Box width={isMobileView ? "100%" : "50%"}>
              <Typography variant="subtitle2">{account.bankName}</Typography>
            </Box>
            <Box
              width={isMobileView ? "100%" : "50%"}
              {...(isMobileView && { mt: "4px" })}
              {...(!isMobileView && { ml: "4px" })}
            >
              <Typography variant="body2">{`${account.routingNumber} **** ${account.bankAccountNumberLast4}`}</Typography>
            </Box>
          </Box>

          {/* Default chip or menu icon */}
          {account.isDefault ? (
            <Box
              display="flex"
              flexDirection="column"
              gap={1.5}
              alignItems="flex-end"
            >
              <RoundChip
                label="DEFAULT"
                backgroundColor={tintYellow}
                color={yellowDark}
              />
              {account.isInstantPayoutEligible && (
                <RoundChip
                  label="INSTANT ELIGIBLE"
                  icon={InstantPayoutChipIcon}
                  iconProps={{ size: "small", iconColor: white }}
                  backgroundColor={successGreen}
                  color={white}
                />
              )}
            </Box>
          ) : (
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {loading ? (
                <CircularProgress size={24} sx={{ margin: 1.5 }} />
              ) : (
                <Box display="flex" flex="none" alignItems="center">
                  {account.isInstantPayoutEligible && (
                    <RoundChip
                      label="INSTANT ELIGIBLE"
                      icon={InstantPayoutChipIcon}
                      iconProps={{ size: "small", iconColor: white }}
                      backgroundColor={successGreen}
                      color={white}
                    />
                  )}
                  <MoovsEllipsisMenu
                    size="small"
                    options={[
                      {
                        text: "Make default for currency",
                        onClick: () => {
                          handleSetDefaultBankAccount(account.id);
                        },
                      },
                      {
                        text: "Remove",
                        onClick: () => {
                          setDeleteAccountDialogOpen(true);
                          setRemovableAccountId(account.id);
                        },
                      },
                    ]}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Card>
      <RemoveDialog
        open={deleteAccountDialogOpen}
        onRemove={handleRemoveBankAccount}
        onClose={handleRemoveDialogClose}
        title={`Are you sure you want to delete this account?`}
        body={`This account will be removed from your Moovs Payments account. You will not be able to use this deleted account to receive credit
        card payments.`}
        removeButtonText={"Delete"}
        isSaving={stripeRemoveLoading}
      />
    </>
  );
}

export default BankAccountCard;
