import React from "react";

import { Box } from "@mui/material";

import { Route, RouteStatusLog } from "types";
import TripNotes from "../TripNotes";
import TripTracker from "../TripTracker";
import StatusTimeline from "./StatusTimeline";

type DispatchLogTabProps = {
  route: Route;
  routeStatusLogs: RouteStatusLog[];
};

function DispatchLogTab(props: DispatchLogTabProps) {
  const { route, routeStatusLogs } = props;

  return (
    <>
      <Box mt={3} mb={4}>
        <StatusTimeline
          routeStatusLogs={routeStatusLogs}
          timezoneId={route.trip.timezoneId}
        />
      </Box>

      <TripTracker route={route} />

      <Box my={4}>
        <TripNotes
          driverCloseoutNote={route.driverCloseoutNote}
          farmeeCloseoutNote={route.farmeeCloseoutNote}
          isFarmedRoute={route.isFarmedRoute}
          operatorCloseoutNote={route.operatorCloseoutNote}
        />
      </Box>
    </>
  );
}

export default DispatchLogTab;
