import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
  KeyboardEvent,
  useCallback,
} from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import moment from "moment-timezone";
import isEqual from "lodash/isEqual";
import { useDebounce } from "use-debounce";

import {
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  CircularProgress,
} from "@mui/material";

import {
  Airport,
  Airline,
  Stop,
  TripCategory,
  TrackedFlight,
} from "../../../types";
import LocationAutoComplete from "../../autocompletes/LocationAutoComplete";
import MultipleFlightsDialog from "./MultipleFlightsDialog";
import FlightInfoCard from "../../flights/components/FlightInfoCard/FlightInfoCard";
import {
  alabaster,
  white,
  orange,
  grayLight,
  errorRed,
  black,
} from "../../../design-system/colors";
import { RearrangeIcon, WarningIcon } from "../../../design-system/icons";
import AirlineAutoComplete from "../../autocompletes/AirlineAutoComplete";
import AirportAutoComplete from "../../autocompletes/AirportAutoComplete";
import AddressAirportToggle from "../../globals/AddressAirportToggle";
import { applyUTCOffsetToTime } from "../../../globals/utils/helpers";
import {
  usePrevious,
  useScreenSize,
  useSnackbar,
} from "../../../globals/hooks";
import MoovsTooltip from "../../../components/MoovsTooltip";
import AdditionalStopInfoDialog from "../AdditionalStopInfoDialog";
import EditRemoveStopButtons from "../EditRemoveStopButtons";
import AdditionalStopInfoButtonPanel from "../AdditionalStopInfoButtonPanel";
import FlightNumberInput from "./FlightNumberInput";
import ShuttleStopDateTimeInput from "./ShuttleStopDateTimeInput";
import MoovsDateTimePicker from "components/MoovsDateTimePicker";
import { usePotentialTrackedFlight } from "../hooks/usePotentialTrackedFlightQuery";
import CharacterLimitedTextInput from "../../inputs/CharacterLimitedTextInput";

type TripInfoCreateBlockItemType = {
  onStopChange: (stopIndex: number) => (stop: Partial<Stop>) => void;
  stop: any;
  stopsLength: number;
  firstStop: any;
  requestErrors: any;
  setRequestErrors: any;
  canRemoveStop: boolean;
  addressRef: any;
  initialFocusedStopDateTime: string; // not currently being used
  suggestedAddressInfo: {
    firstName: string;
    lastName: string;
    address: string;
    mode: string;
  }[];
  onRemoveStop: () => void;
  showIncorrectStopOrderWarning: boolean;
  tripType: string;
  dropOff: Partial<Stop>;
  // draggable props
  nextStop: any;
  isSourceIndex: boolean;
  isDestinationIndex: boolean;
};

function TripInfoCreateBlockItem(props: TripInfoCreateBlockItemType) {
  const {
    onStopChange,
    stop,
    firstStop,
    stopsLength,
    canRemoveStop,
    requestErrors,
    setRequestErrors,
    addressRef,
    suggestedAddressInfo,
    onRemoveStop,
    showIncorrectStopOrderWarning,
    tripType,
    dropOff,
    // draggable props
    nextStop,
    isSourceIndex,
    isDestinationIndex,
  } = props;

  const { isMobileView } = useScreenSize();
  const snackbar = useSnackbar();

  const prevFirstStopDateTime = usePrevious(firstStop.dateTime);

  // state
  const [additionalStopInfoOpen, setAdditionalStopInfoOpen] = useState(false);
  const [multipleFlightsDialogData, setMultipleFlightsDialogData] = useState<{
    flights: TrackedFlight[];
    flightNumber: string;
    inputAirportIcao: string;
  }>(undefined);
  const [flightNumberInput, setFlightNumberInput] = useState("");
  const [dropOffDateTime, setDropOffDateTime] = useState(null);
  const [isDropOffAutoDateTime, setIsDropOffAutoDateTime] = useState(false);
  const airportRef = useRef(null);

  const [debouncedFlightNumber] = useDebounce(flightNumberInput, 500);

  const stopsErrors = requestErrors.stops || [];
  const stopErrors = requestErrors.stops[stop.stopIndex - 1] || {};

  // queries
  const { loadPotentialTrackedFlight, potentialTrackedFlightLoading } =
    usePotentialTrackedFlight({
      onStopChange: onStopChange(stop.stopIndex),
      setMultipleFlightsDialogData,
      stop,
    });

  // derived state
  // time manually adjusted to display properly on DateTimePicker
  const adjustedStopDateTime = stop.dateTime
    ? applyUTCOffsetToTime(stop.dateTime, "subtract")
    : null;

  const dropOffErrors = requestErrors.stops[dropOff.stopIndex - 1] || {};

  const isDialogDateTimeDisabled =
    stop.stopIndex === stopsLength && tripType === TripCategory.OneWay;

  const isShuttleTrip =
    tripType === TripCategory.ShuttlePickUp ||
    tripType === TripCategory.ShuttleReturn;

  const isShuttleStopPickUpDateTimeDisabled =
    tripType !== TripCategory.ShuttlePickUp || stop.stopIndex === 1;

  const stopLabel = useMemo(() => {
    const { stopIndex } = stop;

    if (stopIndex === 1) return "Pick-up";
    if (stopIndex === stopsLength) return "Drop-off";

    return `Stop ${stopIndex - 1}`;
  }, [stop, stopsLength]);

  const getAirportLabel = (stopIndex: number) => {
    if (stopIndex === 1) return "Arrival Airport";
    if (stopIndex === stopsLength) return "Departure Airport";

    return "Airport";
  };

  let nextStopValue = nextStop?.location;
  let nextStopLabel = "Address";

  if (nextStop?.airport) {
    const { airport, stopIndex } = nextStop;

    nextStopValue = `${airport.iataCode} - ${airport.airportName}`;
    nextStopLabel = getAirportLabel(stopIndex);
  }

  // event handlers
  const handleStopTypeToggle = (_, variant: "address" | "airport") => {
    if (variant !== null) {
      onStopChange(stop.stopIndex)({
        ...stop,
        variant,
        ...(variant === "address" && {
          airport: null,
          airline: null,
          flightNumber: null,
          trackedFlight: null,
        }),
        ...(variant === "airport" && {
          location: "",
        }),
      });
    }

    stopsErrors[stop.stopIndex - 1] = {
      ...stopErrors,
      dateTime: "",
    };

    setRequestErrors({
      ...requestErrors,
      stops: stopsErrors,
    });

    // focus on address / airport
    setTimeout(() => {
      if (variant === "address") {
        addressRef.current && addressRef.current.focus();
      }
      if (variant === "airport") {
        airportRef.current && airportRef.current.focus();
      }
    }, 0);
  };

  const handleStopDateTimeChange = (stopIndex: number, date: any) => {
    const newDateTime = date ? applyUTCOffsetToTime(date, "add") : null;

    onStopChange(stopIndex)({
      dateTime: moment(newDateTime).toISOString(),
      flightNumber: null,
      trackedFlight: null,
    });

    stopsErrors[stopIndex - 1] = {
      ...stopErrors,
      dateTime: "",
    };

    setRequestErrors({
      ...requestErrors,
      stops: stopsErrors,
    });

    // if updating dateTime w/ flight, re-query for flight info
    if (stopIndex === 1 && stop.variant === "airport") {
      validateFlightQueryParams({
        updatedDateTime: moment(newDateTime).toISOString(),
      });
    }
  };

  const handleStopLocationChange = (value) => {
    onStopChange(stop.stopIndex)({ location: value.description || "" });

    stopsErrors[stop.stopIndex - 1] = {
      ...stopErrors,
      location: "",
    };

    setRequestErrors({
      ...requestErrors,
      stops: stopsErrors,
    });
  };

  const handleStopLocationAliasChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onStopChange(stop.stopIndex)({ locationAlias: event.target.value || "" });
  };

  const handleStopChange = (key, value) => {
    const isAirlineOrAirport = key === "airline" || key === "airport";
    onStopChange(stop.stopIndex)({
      [key]: value,
      ...(isAirlineOrAirport && {
        flightNumber: null,
        trackedFlight: null,
      }),
    });

    if (isAirlineOrAirport && !value) {
      setFlightNumberInput("");
    }

    stopsErrors[stop.stopIndex - 1] = {
      ...stopErrors,
      [key]: "",
    };

    setRequestErrors({
      ...requestErrors,
      stops: stopsErrors,
    });
  };

  const validateFlightQueryParams = (updatedParams: {
    updatedFlightNumber?: string;
    updatedDateTime?: string;
    updatedAirline?: Partial<Airline>;
    updatedAirport?: Partial<Airport>;
  }) => {
    const {
      updatedFlightNumber,
      updatedDateTime,
      updatedAirline,
      updatedAirport,
    } = updatedParams;

    const currentStopErrors = {
      airport: !(updatedAirport || stop.airport)
        ? "Please select an airport"
        : "",
      airline: !(updatedAirline || stop.airline)
        ? "Please select an airline"
        : "",
      flightNumber: !(updatedFlightNumber || flightNumberInput)
        ? "Please enter flight number"
        : "",
      dateTime: !(updatedDateTime || firstStop.dateTime)
        ? "Please enter date"
        : "",
    };

    const hasErrors =
      Object.values(currentStopErrors).some((value) => value.length) ||
      (!updatedDateTime && !firstStop.dateTime);

    if (stop.stopIndex !== 1) {
      // sets error for when pickup dateTime is not set while searching on non-pickup stop
      requestErrors.stops[0] = {
        ...requestErrors.stops[0],
        dateTime: !firstStop.dateTime ? "Please enter flight time" : "",
      };
    }

    stopsErrors[stop.stopIndex - 1] = {
      ...stopErrors,
      ...currentStopErrors,
    };

    setRequestErrors({
      ...requestErrors,
      stops: stopsErrors,
    });

    if (hasErrors) {
      if (flightNumberInput) {
        snackbar.warning("Please enter missing information to search flights", {
          snackbarColor: white,
          iconColor: errorRed,
          textColor: black,
        });
      }
      return;
    }
    handleStopFlightSearch(updatedParams);
  };

  const handleStopFlightSearch = (updatedParams: {
    updatedFlightNumber?: string;
    updatedDateTime?: string;
    updatedAirline?: Partial<Airline>;
    updatedAirport?: Partial<Airport>;
  }) => {
    const {
      updatedFlightNumber,
      updatedDateTime,
      updatedAirline,
      updatedAirport,
    } = updatedParams;

    const hasUpdatedParams =
      updatedFlightNumber ||
      updatedDateTime ||
      updatedAirline ||
      updatedAirport;

    const hasStopBeenUpdated =
      prevFirstStopDateTime !== firstStop.dateTime ||
      !isEqual(
        stop.stopIndex === 1
          ? stop.trackedFlight?.destination.airport.icaoCode
          : stop.trackedFlight?.origin.airport.icaoCode,
        stop.airport.icaoCode
      ) ||
      !isEqual(stop.trackedFlight?.airline, stop.airline) ||
      stop.trackedFlight?.flightNumber !== stop.flightNumber;

    if (!hasUpdatedParams) {
      if (!hasStopBeenUpdated) return;
    }

    loadPotentialTrackedFlight({
      variables: {
        flightNumber: updatedFlightNumber
          ? Number(updatedFlightNumber)
          : Number(flightNumberInput),
        firstStopDateTime: updatedDateTime || firstStop.dateTime,
        airline: updatedAirline?.icaoCode || stop.airline?.icaoCode,
        ...{
          ...(stop.stopIndex === stopsLength
            ? {
                departureAirport:
                  updatedAirport?.icaoCode || stop.airport?.icaoCode,
              }
            : {
                arrivalAirport:
                  updatedAirport?.icaoCode || stop.airport?.icaoCode,
              }),
        },
      },
    });
  };

  const handleStopFlightNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputFlightNumber = e.target.value;
    stopsErrors[stop.stopIndex - 1] = {
      ...stopErrors,
      flightNumber: "",
    };

    setRequestErrors({
      ...requestErrors,
      stops: stopsErrors,
    });

    setFlightNumberInput(inputFlightNumber);
  };

  const handleDebouncedFlightNumber = useCallback(() => {
    validateFlightQueryParams({ updatedFlightNumber: debouncedFlightNumber });
    // comment below silences issues w/ validateFlightQueryParams
    // eslint-disable-next-line
  }, [debouncedFlightNumber]);

  const handleMultipleFlightSelect = (selectedFlight: TrackedFlight) => {
    onStopChange(stop.stopIndex)({
      trackedFlight: selectedFlight,
      flightNumber:
        selectedFlight.actualFlightNumber || selectedFlight.flightNumber,
    });
    setMultipleFlightsDialogData(undefined);
  };

  const handleAdditionalStopInfoChange = (
    additionalStopInfo: Partial<Stop>
  ) => {
    onStopChange(stop.stopIndex)({
      ...additionalStopInfo,
      // if dateTime is changed
      ...(additionalStopInfo.dateTime !== stop.dateTime && {
        dateTime: moment(additionalStopInfo.dateTime).toISOString(),
        // for first flight, if dateTime changes, trackedFlight should also reset
        ...(stop.stopIndex === 1 && {
          flightNumber: null,
          trackedFlight: null,
        }),
      }),
    });

    if (additionalStopInfo.dateTime) {
      stopsErrors[stop.stopIndex - 1] = {
        ...stopErrors,
        dateTime: "",
      };
      setRequestErrors({
        ...requestErrors,
        stops: stopsErrors,
      });
    }
  };

  // blurs input focus when clicking drag handle
  // dnd library prevents default on handle events
  const handleBlur = () => {
    addressRef && addressRef.current.blur();
    airportRef && airportRef.current.blur();
  };

  /* dateTime, airport, ailine and flight number are coupled for
  searching a flight. handleAirportDetailsBlur and
  handleAirportDetailsKeyDown to make sure handleStopFlightSearch
  is triggered when flight number is entered and dateTime/airport
  or airline are entered later*/
  const handleAirlineDetailsBlur = () => {
    if (stop.variant === "airport") {
      validateFlightQueryParams({});
    }
  };

  const handleAirlineDetailsKeyDown = (
    event: KeyboardEvent<HTMLDivElement>
  ) => {
    if (
      stop.variant === "airport" &&
      !!flightNumberInput &&
      (event.keyCode === 13 || event.key === "Enter")
    ) {
      validateFlightQueryParams({});
    }
  };

  const handleDropOffDateTimeOpen = () => {
    if (dropOffDateTime) return;

    if (stop.stopIndex === 1 && adjustedStopDateTime) {
      setDropOffDateTime(adjustedStopDateTime);
      setIsDropOffAutoDateTime(true);
    }
  };

  const handleDropOffDateTimeClose = () => {
    if (stop.stopIndex === 1 && isDropOffAutoDateTime) {
      setDropOffDateTime(null);
      handleStopDateTimeChange(stopsLength, null);
    }

    setIsDropOffAutoDateTime(false);
  };

  // effects
  useEffect(() => {
    // removes tracked flight card if the pickupDateTime changes
    if (prevFirstStopDateTime !== firstStop.dateTime) {
      onStopChange(stop.stopIndex)({
        trackedFlight: null,
      });
    }
  }, [prevFirstStopDateTime, firstStop.dateTime, onStopChange, stop.stopIndex]);

  // clear the dropoff dateTime input
  useEffect(() => {
    if (tripType === TripCategory.Hourly) {
      setDropOffDateTime(null);
    }
  }, [tripType]);

  useEffect(() => {
    if (dropOff.dateTime) {
      setDropOffDateTime(applyUTCOffsetToTime(dropOff.dateTime, "subtract"));
    }
  }, [setDropOffDateTime, dropOff]);

  useEffect(() => {
    if (!debouncedFlightNumber) return;
    handleDebouncedFlightNumber();
  }, [debouncedFlightNumber, handleDebouncedFlightNumber]);

  return (
    <>
      <div>
        <Box display="flex" flexDirection="column">
          {/* date & time picker */}
          {stop.stopIndex === 1 && (
            <Box mb={4}>
              <Box mb={1.5}>
                <Typography variant="h5">Date & Time</Typography>
              </Box>
              <Box mb={2}>
                <Divider />
              </Box>
              {/* pick-up stop */}
              <MoovsDateTimePicker
                inputFormat="MMM Do YYYY, h:mm a"
                label={`${stopLabel} Date & Time`}
                value={adjustedStopDateTime}
                onAccept={(date) =>
                  handleStopDateTimeChange(stop.stopIndex, date)
                }
                clearable={stop.stopIndex !== 1}
                renderInputProps={{
                  name: "pickupDateTime",
                  multiline: true,
                  onKeyDown: handleAirlineDetailsKeyDown,
                  error: stopErrors && !!stopErrors.dateTime,
                  helperText: stopErrors && stopErrors.dateTime,
                }}
              />

              <Box mt={2} />

              {/* drop-off stop */}
              {tripType === TripCategory.Hourly && (
                <MoovsDateTimePicker
                  inputFormat="MMM Do YYYY, h:mm a"
                  label={`Drop-Off Date & Time`}
                  value={dropOffDateTime}
                  onOpen={handleDropOffDateTimeOpen}
                  onClose={handleDropOffDateTimeClose}
                  onChange={(date) => {
                    setDropOffDateTime(date);
                    setIsDropOffAutoDateTime(false);
                  }}
                  onAccept={(date) => {
                    setIsDropOffAutoDateTime(false);
                    handleStopDateTimeChange(stopsLength, date);
                    setDropOffDateTime(applyUTCOffsetToTime(date, "subtract"));
                  }}
                  clearable
                  renderInputProps={{
                    name: "dropOffDateTime",
                    multiline: true,
                    onBlur: handleAirlineDetailsBlur,
                    onKeyDown: handleAirlineDetailsKeyDown,
                    error: dropOffErrors && !!dropOffErrors.dateTime,
                    helperText: dropOffErrors && dropOffErrors.dateTime,
                  }}
                />
              )}
            </Box>
          )}

          {/* stop header */}
          <Box
            display="flex"
            flex="1"
            justifyContent="space-between"
            flexDirection={isMobileView ? "column" : "row"}
            alignItems={isMobileView ? "flex-start" : "center"}
            mb={1}
          >
            <Box mr={2} mb={1}>
              <Typography variant="h5" style={{ whiteSpace: "nowrap" }}>
                {stopLabel}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <AddressAirportToggle
                onChange={handleStopTypeToggle}
                value={nextStop?.variant || stop.variant}
              />
              <Box display="flex" alignItems="center">
                {showIncorrectStopOrderWarning && (
                  <MoovsTooltip tooltipText="Date & time falls before a previous date & time">
                    <IconButton size="large">
                      <WarningIcon color={orange} />
                    </IconButton>
                  </MoovsTooltip>
                )}

                {!isShuttleTrip && (
                  <AdditionalStopInfoButtonPanel
                    stopLabel={stopLabel}
                    dateTime={stop.stopIndex !== 1 && stop.dateTime}
                    groupSize={stop.groupSize}
                    note={stop.note}
                    onEditAdditionalStopInfoClick={() =>
                      setAdditionalStopInfoOpen(true)
                    }
                  />
                )}

                {/* edit additional info / remove stop buttons */}
                <EditRemoveStopButtons
                  canRemoveStop={canRemoveStop}
                  canEditStop={!isShuttleTrip}
                  onRemoveStopClick={onRemoveStop}
                  onEditAdditionalStopInfoClick={() =>
                    setAdditionalStopInfoOpen(true)
                  }
                  stopLabel={stopLabel}
                />
              </Box>
            </Box>
          </Box>
          <Box mb={1}>
            <Divider />
          </Box>
          <Droppable droppableId={stop.id} direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{
                  backgroundColor: snapshot.isDraggingOver ? alabaster : white,
                  borderRadius: "4px",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  // prevents background from going on top of dragging item
                  zIndex: snapshot.isDraggingOver ? 900 : 1000,
                }}
                {...provided.droppableProps}
              >
                {/* temp draggable value */}
                {/* used to visually rearrange stops while dragging */}
                {nextStop && !isDestinationIndex && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    flex="1"
                    alignItems="center"
                    bgcolor={white}
                    pl={2}
                    pr={0}
                    py={1}
                    mb={2}
                    borderRadius="4px"
                  >
                    <Box
                      mr={1.5}
                      height={56}
                      display="flex"
                      alignItems="center"
                    >
                      <RearrangeIcon />
                    </Box>
                    <Box display="flex" flex="1" width="100%">
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        label={nextStopLabel}
                        value={nextStopValue}
                      />
                    </Box>
                  </Box>
                )}
                <Draggable index={stop.stopIndex - 1} draggableId={stop.id}>
                  {(provided, draggableSnapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      {/* address/airport reorderable input */}
                      <Box
                        display={nextStop && !isSourceIndex ? "none" : "flex"}
                        flexDirection="row"
                        flex="1"
                        alignItems="center"
                        height="100%"
                        bgcolor={white}
                        boxShadow={
                          draggableSnapshot.isDragging
                            ? "0px 0px 30px rgba(0, 0, 0, 0.08)"
                            : "none"
                        }
                        py={1}
                        mb={draggableSnapshot.isDragging ? 0 : 2}
                        pl={2}
                        pr={draggableSnapshot.isDragging ? 2 : 0}
                        borderRadius="4px"
                      >
                        <Box
                          mr={1.5}
                          height={56}
                          display="flex"
                          alignItems="center"
                          {...provided.dragHandleProps}
                          onMouseDown={handleBlur}
                        >
                          <RearrangeIcon />
                        </Box>
                        <Box display="flex" flex="1" width="100%">
                          {/* address */}
                          <Box
                            display={
                              stop.variant === "address" ? "flex" : "none"
                            }
                            flex="1"
                          >
                            <LocationAutoComplete
                              inputRef={addressRef}
                              required
                              label="Address"
                              fullWidth
                              value={stop.location}
                              onChange={handleStopLocationChange}
                              error={stopErrors && !!stopErrors.location}
                              helperText={stopErrors && stopErrors.location}
                              suggestedAddressInfo={suggestedAddressInfo}
                            />
                          </Box>
                          {/* airport */}
                          <Box
                            display={
                              stop.variant === "airport" ? "flex" : "none"
                            }
                            flex="1"
                          >
                            <AirportAutoComplete
                              inputRef={airportRef}
                              fullWidth
                              required
                              label={getAirportLabel(stop.stopIndex)}
                              onChange={(airport: Airport) => {
                                handleStopChange("airport", airport);
                                validateFlightQueryParams({
                                  updatedAirport: airport,
                                });
                              }}
                              onBlur={handleAirlineDetailsBlur}
                              onKeyDown={handleAirlineDetailsKeyDown}
                              value={stop.airport}
                              error={stopErrors && !!stopErrors.airport}
                            />
                          </Box>
                        </Box>
                      </Box>
                      {isShuttleTrip && stop.variant === "address" && (
                        <Box
                          bgcolor={white}
                          borderRadius="4px"
                          pb={draggableSnapshot.isDragging ? 2 : 0}
                          px={draggableSnapshot.isDragging ? 2 : 0}
                        >
                          <CharacterLimitedTextInput
                            label="Location Name"
                            name="locationAlias"
                            value={stop.locationAlias || ""}
                            onChange={handleStopLocationAliasChange}
                          />
                        </Box>
                      )}
                    </div>
                  )}
                </Draggable>
                <Box visibility="hidden" height={0}>
                  {provided.placeholder}
                </Box>
              </div>
            )}
          </Droppable>
        </Box>

        {stop.variant === "airport" && (
          <Box
            p={2}
            mb={2}
            style={{ backgroundColor: alabaster, borderRadius: "4px" }}
          >
            {/* airport stop type additional info fields */}
            <>
              {/* airline */}
              <Box flex="1" mb={1}>
                <AirlineAutoComplete
                  fullWidth
                  label="Airline"
                  onChange={(airline: Airline) => {
                    handleStopChange("airline", airline);
                    validateFlightQueryParams({ updatedAirline: airline });
                  }}
                  onBlur={handleAirlineDetailsBlur}
                  onKeyDown={handleAirlineDetailsKeyDown}
                  value={stop.airline}
                  error={stopErrors && !!stopErrors.airline}
                  helperText={stopErrors && stopErrors.airline}
                />
              </Box>

              {/* flight number */}
              <Box flex="1" mb={1} display="flex" alignItems="center">
                <FlightNumberInput
                  label="Flight"
                  onFlightNumberInputChange={handleStopFlightNumberChange}
                  isLoading={potentialTrackedFlightLoading}
                  airlineIata={stop?.airline?.iataCode}
                  error={stopErrors && !!stopErrors.flightNumber}
                  value={flightNumberInput}
                />
              </Box>

              {stop.trackedFlight && !potentialTrackedFlightLoading && (
                <Box>
                  <FlightInfoCard trackedFlight={stop.trackedFlight} />
                </Box>
              )}

              {potentialTrackedFlightLoading && (
                <Box
                  py={10}
                  style={{ backgroundColor: grayLight, borderRadius: "4px" }}
                  display="flex"
                  justifyContent="center"
                >
                  <CircularProgress />
                </Box>
              )}
            </>
          </Box>
        )}

        {/* add date time to stops for shuttle pickup */}
        <ShuttleStopDateTimeInput
          stopLabel={stopLabel}
          stop={stop}
          onSave={handleAdditionalStopInfoChange}
          stopErrors={stopErrors}
          isDateTimeDisabled={isShuttleStopPickUpDateTimeDisabled}
        />
      </div>

      <AdditionalStopInfoDialog
        stopLabel={stopLabel}
        open={additionalStopInfoOpen}
        onClose={() => setAdditionalStopInfoOpen(false)}
        stop={stop}
        clearable={stop.stopIndex !== 1}
        onSave={handleAdditionalStopInfoChange}
        isDateTimeDisabled={isDialogDateTimeDisabled}
      />

      {!!multipleFlightsDialogData && (
        <MultipleFlightsDialog
          open={!!multipleFlightsDialogData}
          onClose={() => setMultipleFlightsDialogData(undefined)}
          onAccept={handleMultipleFlightSelect}
          data={multipleFlightsDialogData}
        />
      )}
    </>
  );
}

export default TripInfoCreateBlockItem;
