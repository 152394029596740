import React from "react";

type ProPlanIconProps = {
  color?: string;
  size?: string;
};

const ProPlanIcon = (props: ProPlanIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Prefix / Small">
          <path
            id="Vector"
            d="M8.53301 5L6.13301 6.43099M8.53301 11L6.13301 9.58578M11.533 2C12.5271 2 13.333 2.80589 13.333 3.8C13.333 4.79411 12.5271 5.6 11.533 5.6C10.5389 5.6 9.73301 4.79411 9.73301 3.8C9.73301 2.80589 10.5389 2 11.533 2ZM11.533 10.4C12.5271 10.4 13.333 11.2059 13.333 12.2C13.333 13.1941 12.5271 14 11.533 14C10.5389 14 9.73301 13.1941 9.73301 12.2C9.73301 11.2059 10.5389 10.4 11.533 10.4ZM3.13301 6.2C4.12712 6.2 4.93301 7.00589 4.93301 8C4.93301 8.99411 4.12712 9.8 3.13301 9.8C2.1389 9.8 1.33301 8.99411 1.33301 8C1.33301 7.00589 2.1389 6.2 3.13301 6.2Z"
            stroke={color || "#28B299"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    );
  }

  if (size === "large") {
    return (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="48"
          height="48"
          rx="24"
          fill="#28B299"
          fillOpacity="0.12"
        />
        <path
          d="M24.8 19.5L21.2 21.6465M24.8 28.5L21.2 26.3787M29.3 15C30.7912 15 32 16.2088 32 17.7C32 19.1912 30.7912 20.4 29.3 20.4C27.8088 20.4 26.6 19.1912 26.6 17.7C26.6 16.2088 27.8088 15 29.3 15ZM29.3 27.6C30.7912 27.6 32 28.8088 32 30.3C32 31.7912 30.7912 33 29.3 33C27.8088 33 26.6 31.7912 26.6 30.3C26.6 28.8088 27.8088 27.6 29.3 27.6ZM16.7 21.3C18.1912 21.3 19.4 22.5088 19.4 24C19.4 25.4912 18.1912 26.7 16.7 26.7C15.2088 26.7 14 25.4912 14 24C14 22.5088 15.2088 21.3 16.7 21.3Z"
          stroke={color || "#28B299"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="18" fill="#28B299" fillOpacity="0.12" />
      <path
        d="M18.6667 14.25L15.6667 16.0387M18.6667 21.75L15.6667 19.9822M22.4167 10.5C23.6594 10.5 24.6667 11.5074 24.6667 12.75C24.6667 13.9926 23.6594 15 22.4167 15C21.1741 15 20.1667 13.9926 20.1667 12.75C20.1667 11.5074 21.1741 10.5 22.4167 10.5ZM22.4167 21C23.6594 21 24.6667 22.0074 24.6667 23.25C24.6667 24.4926 23.6594 25.5 22.4167 25.5C21.1741 25.5 20.1667 24.4926 20.1667 23.25C20.1667 22.0074 21.1741 21 22.4167 21ZM11.9167 15.75C13.1594 15.75 14.1667 16.7574 14.1667 18C14.1667 19.2426 13.1594 20.25 11.9167 20.25C10.6741 20.25 9.66675 19.2426 9.66675 18C9.66675 16.7574 10.6741 15.75 11.9167 15.75Z"
        stroke={color || "#28B299"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProPlanIcon;
