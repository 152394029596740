import { GettingStartedGoal } from "types";

import { MarketApp } from "pages/moovs-market/components/MoovsMarketAppList/data/appData";
import QuickLinkDriverPlatform from "../../../../design-system/illustrations/gettingStarted/QuickLinkDriverPlatform.svg";
import QuickLinkZonePricing from "../../../../design-system/illustrations/gettingStarted/QuickLinkZonePricing.svg";
import QuickLinkGoogleCalendar from "../../../../design-system/illustrations/gettingStarted/QuickLinkGoogleCalendar.svg";
import QuickLinkCustomerPortal from "../../../../design-system/illustrations/gettingStarted/QuickLinkCustomerPortal.svg";

const quickLinkDriverPlatform = {
  src: QuickLinkDriverPlatform,
  navigateButtonText: "Open Driver Platform",
  subtext: "Install the Driver App and track your drivers",
  path: `/settings/moovs-market?app=${MarketApp.Driver}`,
  toExternalLink: false,
};

const quickLinkZonePricing = {
  src: QuickLinkZonePricing,
  navigateButtonText: "Open Zone Pricing",
  subtext: "Add zone to zone pricing for your vehicles",
  path: "/settings/zone-pricing",
  toExternalLink: false,
};

const quickLinkGoogleCalendar = {
  src: QuickLinkGoogleCalendar,
  navigateButtonText: "Open Google Calendar",
  subtext: "Sync your trips with your Google Calendar",
  path: `/settings/moovs-market?app=${MarketApp.GoogleCalendar}`,
  toExternalLink: false,
};

const quickLinkCustomerPortal = {
  src: QuickLinkCustomerPortal,
  navigateButtonText: "Open Customer Portal",
  subtext: "Add Customer Portal to your website",
  path: "settings/customer-portal",
  toExternalLink: false,
};

export const QUICK_LINK_DETAILS = {
  [GettingStartedGoal.GrowBusinessOnline]: [
    quickLinkDriverPlatform,
    quickLinkZonePricing,
    quickLinkGoogleCalendar,
  ],
  [GettingStartedGoal.AccessDriverApp]: [
    quickLinkCustomerPortal,
    quickLinkZonePricing,
    quickLinkGoogleCalendar,
  ],
  [GettingStartedGoal.ExploreOnMyOwn]: [
    quickLinkCustomerPortal,
    quickLinkDriverPlatform,
    quickLinkZonePricing,
  ],
};
