import React, { Dispatch, SetStateAction, useState } from "react";
import Cards from "react-credit-cards";
import "design-system/styles/react-credit-card-mobile.css";

import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import padStart from "lodash/padStart";

import { Box, IconButton, Typography, Card, Button, Menu } from "@mui/material";

import {
  EditIcon,
  TrashIcon,
  StarIcon,
  DollarIcon,
  CheckIcon,
  InfoIcon,
  LinkIcon,
} from "../../../design-system/icons";
import {
  alabaster,
  moovsBlue,
  orange,
  stripeStatusBgPending,
  stripeStatusBgRejected,
  stripeStatusTextPending,
  stripeStatusTextRejected,
  white,
} from "../../../design-system/colors";
import RoundChip from "../../../design-system/components/chips/RoundChip";
import {
  Contact,
  FarmAffiliateVariantEnum,
  PaymentMethod,
} from "../../../types";
import EditCreditCardDialog from "./EditCreditCardDialog";
import {
  CREATE_AUTHORIZATION_PAYMENT_MUTATION,
  LINK_PASSENGER_TO_PAYMENT_METHOD_MUTATION,
  LOAD_COMPANY_QUERY,
  LOAD_CONTACTS_QUERY,
  LOAD_CONTACT_QUERY,
  LOAD_GRIDDNET_OPERATOR_QUERY,
  LOAD_MOOVS_NETWORK_OPERATOR_QUERY,
  REMOVE_PAYMENT_METHOD_MUTATION,
  UNLINK_PASSENGER_FROM_PAYMENT_METHOD_MUTATION,
  UPDATE_PRIMARY_PAYMENT_METHOD_MUTAITON,
} from "../../../globals/graphql";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import MoovsTooltip from "../../MoovsTooltip";
import DeleteCreditCardDialog from "./DeleteCreditCardDialog";
import { useAnalytics, useScreenSize } from "../../../globals/hooks";
import {
  AffiliatePayerProps,
  CompanyPayerProps,
  ContactPayerProps,
} from "./types";
import MoovsEllipsisMenu from "components/MoovsEllipsisMenu";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import theme from "theme";
import CheckStatus from "./CheckStatus";
import ActionButton from "design-system/components/ActionButton";
import { useLaunchDarklyFlags } from "globals/utils/useLaunchDarklyFlags";
import { getContactName } from "globals/utils/contact";
import OverflowTooltip from "design-system/components/OverflowTooltip";
import EllipsisMenuItem from "components/MoovsEllipsisMenu/components/EllipsisMenuItem";
import EditBookingContactDialog from "../bookingContact/components/EditBookingContactDialog";
import { useUpdateBookingContactClick } from "../bookingContact/hooks/useUpdateBookingContactClick";

const styles = {
  body: {
    fontWeight: 400,
  },
  ellipsisOption: {
    fontWeight: 500,
    marginLeft: theme.spacing(1),
  },
};

type PaymentMethodListItemProps = {
  paymentMethod: Partial<PaymentMethod>;
  editableProps?: {
    setSaveIndicatorState: Dispatch<
      SetStateAction<"default" | "saved" | "loading" | "error">
    >;
    linkedPassengerIds?: string[];
    payer: AffiliatePayerProps | ContactPayerProps | CompanyPayerProps;
    contact?: Contact;
  };
  isPassengerLinkedCard?: boolean;
};

function PaymentMethodListItem(props: PaymentMethodListItemProps) {
  const {
    paymentMethod: {
      id,
      card,
      customer,
      cardNote,
      id: paymentMethodId,
      isPrimary,
      stripeStatus,
      linkedPassenger,
    },
    editableProps,
    isPassengerLinkedCard,
  } = props;
  const editable = !!editableProps;
  const { payer, setSaveIndicatorState, contact, linkedPassengerIds } =
    editableProps || {};
  const {
    billingDetails: { name: cardholderName },
  } = props.paymentMethod;
  const { brand, last4, expMonth, expYear, cvcCheck, zipCodeCheck } = card;
  const { MoovsNetworkOperator, GriddnetOperator } = FarmAffiliateVariantEnum;
  const farmAffiliateVariant =
    editable && payer.payerType === "affiliate"
      ? payer.farmAffiliateVariant
      : null;

  const refetchQuery = [
    {
      query:
        farmAffiliateVariant === MoovsNetworkOperator
          ? LOAD_MOOVS_NETWORK_OPERATOR_QUERY
          : farmAffiliateVariant === GriddnetOperator
          ? LOAD_GRIDDNET_OPERATOR_QUERY
          : editable && payer.payerType === "company"
          ? LOAD_COMPANY_QUERY
          : LOAD_CONTACT_QUERY,
      variables: {
        id: payer?.payerId,
      },
    },
  ];

  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const { track } = useAnalytics();
  const { isMobileView } = useScreenSize();
  const { enableLinkedPassenger } = useLaunchDarklyFlags();
  const {
    editBookingContactDialogOpen,
    setEditBookingContactDialogOpen,
    setCarryOverSearch,
    bookingContact: passengerContact,
    setBookingContact: setPassengerContact,
  } = useUpdateBookingContactClick();

  // state
  const [editCreditCardDialogOpen, setEditCreditCardDialogOpen] =
    useState(false);
  const [deleteCreditCardDialogOpen, setDeleteCreditCardDialogOpen] =
    useState(false);
  const [anchorElement, setAnchorElement] = useState(null);

  const isCardLinked = !!linkedPassenger?.id;

  // mutations

  const [removePaymentMethod] = useMutation(REMOVE_PAYMENT_METHOD_MUTATION, {
    refetchQueries: refetchQuery,
    onCompleted: () => {
      setSaveIndicatorState("saved");
      snackbar.info("Credit card deleted");
    },
    onError: (error) => {
      setSaveIndicatorState("error");
      const errorMessage = getErrorMessage(error) || "Error deleting card";

      snackbar.error(errorMessage);
    },
  });

  const [setPrimaryPaymentMethod] = useMutation(
    UPDATE_PRIMARY_PAYMENT_METHOD_MUTAITON,
    {
      refetchQueries: refetchQuery,
      onCompleted: () => {
        setSaveIndicatorState("saved");
      },
      onError: (error) => {
        setSaveIndicatorState("error");
        const errorMessage =
          getErrorMessage(error) || "Error setting primary card";

        snackbar.error(errorMessage);
      },
    }
  );

  const [createAuthorizationPayment] = useMutation(
    CREATE_AUTHORIZATION_PAYMENT_MUTATION,
    {
      onCompleted: () => {
        setSaveIndicatorState("saved");
        snackbar.success("Authorization complete", {
          link: `?card=${paymentMethodId}`,
          linkLabel: "View Risk Status",
          onLinkClick: () => {
            track(
              payer.payerType === "affiliate"
                ? "affiliates_paymentActivityviewed"
                : payer.payerType === "company"
                ? "company_paymentActivityviewed"
                : "contacts_paymentActivityviewed"
            );
          },
        });
      },
      onError: (error) => {
        setSaveIndicatorState("error");

        snackbar.error("Card authorization failed", {
          link: `?card=${paymentMethodId}`,
          linkLabel: "View Risk Status",
          onLinkClick: () => {
            track(
              payer.payerType === "affiliate"
                ? "affiliates_paymentActivityviewed"
                : payer.payerType === "company"
                ? "company_paymentActivityviewed"
                : "contacts_paymentActivityviewed"
            );
          },
        });
      },
    }
  );

  const [linkPaymentMethod, { loading: isCardLinkingLoading }] = useMutation(
    LINK_PASSENGER_TO_PAYMENT_METHOD_MUTATION,
    {
      refetchQueries: [...refetchQuery, { query: LOAD_CONTACTS_QUERY }],
      onCompleted: (data) => {
        const passenger = data.linkPaymentMethod.paymentMethod.linkedPassenger;
        setSaveIndicatorState("saved");
        snackbar.success(
          `Linked card to passenger ${getContactName(passenger)}`
        );
        track("linkPassenger_cardLinked");
        setPassengerContact(null);
        setAnchorElement(null);
        setEditBookingContactDialogOpen(false);
      },
      onError: (error) => {
        setPassengerContact(null);
        setAnchorElement(null);
        setEditBookingContactDialogOpen(false);
        const errorMessage =
          getErrorMessage(error) || "Error linking card to passenger";

        snackbar.error(errorMessage);
      },
    }
  );

  const [unlinkPaymentMethod, { loading: isCardUnlinkingLoading }] =
    useMutation(UNLINK_PASSENGER_FROM_PAYMENT_METHOD_MUTATION, {
      refetchQueries: [...refetchQuery, { query: LOAD_CONTACTS_QUERY }],
      onCompleted: () => {
        setSaveIndicatorState("saved");
        snackbar.success("Unlinked card from passenger");
        track("linkPassenger_cardUnlinked");
        setEditBookingContactDialogOpen(false);
        setAnchorElement(null);
      },
      onError: (error) => {
        setSaveIndicatorState("error");
        const errorMessage =
          getErrorMessage(error) || `Error unlinking card from passenger`; // need to update server to get name
        snackbar.error(errorMessage);
      },
    });

  // event handlers

  const handleOpenEditDialog = () => setEditCreditCardDialogOpen(true);
  const handleCloseEditDialog = () => {
    setEditCreditCardDialogOpen(false);
  };

  const handleOpenDeleteDialog = () => setDeleteCreditCardDialogOpen(true);
  const handleCloseDeleteDialog = () => {
    setDeleteCreditCardDialogOpen(false);
  };

  const handleSuccessEdit = (message: string) => {
    setSaveIndicatorState("saved");
    snackbar.success(message);
    setEditCreditCardDialogOpen(false);
  };
  const handleErrorEdit = (message: string) => {
    snackbar.error(message);
  };

  const handleSetPrimaryPaymentMethod = () => {
    setSaveIndicatorState("loading");

    setPrimaryPaymentMethod({
      variables: {
        input: {
          paymentMethodId,
          payerId: payer.payerId,
        },
      },
    });
  };

  const handleAuthorizeCard = () => {
    setSaveIndicatorState("loading");

    track("contacts_paymentAuthorized");

    createAuthorizationPayment({
      variables: {
        input: {
          paymentMethodId,
        },
      },
    });
  };

  const handleUnlinkCardFromPassenger = () => {
    setSaveIndicatorState("loading");
    unlinkPaymentMethod({
      variables: {
        input: {
          paymentMethodId,
        },
      },
    });
  };

  const handleLinkCardToPassenger = () => {
    setSaveIndicatorState("loading");
    linkPaymentMethod({
      variables: {
        input: {
          paymentMethodId,
          linkedPassengerId: passengerContact?.id,
        },
      },
    });
  };

  const onDeleteCard = () => {
    setSaveIndicatorState("loading");

    removePaymentMethod({
      variables: {
        input: {
          paymentMethodId,
          payerId: payer.payerId,
        },
      },
    });
    setDeleteCreditCardDialogOpen(false);
  };

  const onViewPayments = () => {
    track(
      payer.payerType === "affiliate"
        ? "affiliates_paymentActivityviewed"
        : "contacts_paymentActivityviewed"
    );

    history.push({
      ...history.location,
      search: `?card=${paymentMethodId}`,
    });
  };

  return (
    <Card variant="outlined">
      <Box
        py={1.5}
        pr={2}
        bgcolor={stripeStatus === "succeeded" ? white : alabaster}
      >
        <Box display="flex" justifyContent="space-between">
          <Box
            display="flex"
            flex="1"
            alignItems={"flex-start"}
            justifyContent="flex-start"
            flexDirection={isMobileView ? "column" : "row"}
          >
            <Box ml={2} mr={2}>
              <Cards
                cvc={"***"}
                expiry={`${padStart(String(expMonth), 2, "0")}/${expYear}`}
                issuer={brand}
                placeholders={{ name: "" }}
                name={cardholderName || customer}
                number={"************" + last4}
                preview
              />
            </Box>

            <Box
              display="flex"
              flex={1}
              flexDirection={isMobileView ? "row" : "column"}
              ml={isMobileView ? 3 : 7}
              mt={1}
              flexWrap="wrap"
            >
              <Box mb={isMobileView ? 0 : 3} mr={isMobileView ? 3 : 0}>
                <CheckStatus label="zip check" checkStatus={zipCodeCheck} />
              </Box>
              <Box mb={isMobileView ? 0 : 3} mr={isMobileView ? 3 : 0}>
                <CheckStatus label="cvc check" checkStatus={cvcCheck} />
              </Box>
              {cardNote && (
                <Box>
                  <Typography variant="overline">Notes</Typography>
                  <Typography variant="body2" sx={styles.body}>
                    {cardNote}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box alignSelf="top" position="relative">
            <Box
              display="flex"
              flexDirection={isMobileView ? "row-reverse" : "row"}
              flexWrap="wrap-reverse"
              alignContent="flex-end"
            >
              {isPrimary && !isPassengerLinkedCard && (
                <Box
                  mt="10px"
                  alignSelf={"flex-end"}
                  mr={1}
                  style={{ cursor: "pointer" }}
                >
                  <MoovsTooltip tooltipText="Primary Card">
                    <StarIcon size="small" color={orange} />
                  </MoovsTooltip>
                </Box>
              )}
              {stripeStatus === "succeeded" ? (
                <Box
                  display="flex"
                  ml={4}
                  flexWrap={isMobileView ? "wrap-reverse" : "nowrap"}
                  justifyContent="flex-end"
                >
                  {editable && !isPassengerLinkedCard && (
                    <ActionButton
                      icon={DollarIcon}
                      label={isMobileView ? "" : "View Payment Activity"}
                      maxWidth={50}
                      onClick={onViewPayments}
                      minWidth={isMobileView ? 50 : 70}
                      sx={{ fontWeight: 500 }}
                      variant="button"
                    />
                  )}

                  {editable && !isPassengerLinkedCard && (
                    <Box>
                      <MoovsEllipsisMenu
                        variant={isMobileView ? "default" : "actionButton"}
                        options={[
                          {
                            icon: <StarIcon size="small" />,
                            text: "Make Primary",
                            onClick: () => {
                              handleSetPrimaryPaymentMethod();
                            },
                            disableOption: isPrimary,
                          },
                          {
                            icon: <EditIcon size="small" />,
                            text: "Edit",
                            onClick: () => {
                              handleOpenEditDialog();
                            },
                          },
                          {
                            icon: <CheckIcon size="small" />,
                            text: "Authorize Card",
                            endItem: (
                              <MoovsTooltip
                                tooltipText={
                                  "A 0.50 cent charge will be authorized but not charged"
                                }
                                placement={"top"}
                              >
                                <InfoIcon size="small" />
                              </MoovsTooltip>
                            ),
                            onClick: handleAuthorizeCard,
                          },
                          {
                            icon: <TrashIcon size="small" />,
                            text: "Delete",
                            onClick: () => {
                              handleOpenDeleteDialog();
                            },
                          },

                          enableLinkedPassenger && {
                            icon: <LinkIcon size="small" />,
                            text: "Link to passenger",
                            hideOption: isCardLinked,
                            onClick: () =>
                              setEditBookingContactDialogOpen(true),
                          },
                        ]}
                      />
                    </Box>
                  )}
                  {isPassengerLinkedCard && (
                    <Box>
                      <IconButton
                        onClick={handleUnlinkCardFromPassenger}
                        disabled={isCardUnlinkingLoading}
                        size="large"
                        title="Unlink card from passenger"
                      >
                        <TrashIcon color={moovsBlue} />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <RoundChip
                    label={
                      {
                        pending: "PENDING",
                        rejected: "REJECTED",
                      }[stripeStatus]
                    }
                    color={
                      {
                        pending: stripeStatusTextPending,
                        rejected: stripeStatusTextRejected,
                      }[stripeStatus]
                    }
                    backgroundColor={
                      {
                        pending: stripeStatusBgPending,
                        rejected: stripeStatusBgRejected,
                      }[stripeStatus]
                    }
                  />
                  {stripeStatus === "rejected" && editable && (
                    <IconButton onClick={handleOpenDeleteDialog} size="large">
                      <TrashIcon />
                    </IconButton>
                  )}
                </Box>
              )}
            </Box>
            {linkedPassenger && (
              <Box
                position="absolute"
                bottom={-5}
                right={isMobileView ? -20 : 9}
                minWidth="50%"
              >
                <Button onClick={(e) => setAnchorElement(e.currentTarget)}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={0.5}
                    flexWrap="nowrap"
                    maxWidth={isMobileView ? "150px" : "200px"}
                  >
                    <LinkIcon color={moovsBlue} size="small" />
                    <OverflowTooltip
                      variant="button"
                      placement="top"
                      toolTipText={getContactName(linkedPassenger)}
                      displayText={getContactName(linkedPassenger)}
                    />
                  </Box>
                </Button>

                <Menu
                  id="unlink-passenger-from-card-menu"
                  anchorEl={anchorElement}
                  keepMounted
                  sx={{ maxWidth: "250px" }}
                  open={!!anchorElement}
                  onClose={() => setAnchorElement(null)}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                >
                  <EllipsisMenuItem
                    option={{
                      icon: <LinkIcon size="small" />,
                      text: "Unlink from passenger",
                      disableOption: isCardUnlinkingLoading,
                    }}
                    onOptionClick={handleUnlinkCardFromPassenger}
                  />
                </Menu>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {editable && (
        <>
          <EditCreditCardDialog
            id={id}
            payerId={payer.payerId}
            open={editCreditCardDialogOpen}
            onClose={handleCloseEditDialog}
            onSuccess={handleSuccessEdit}
            onError={handleErrorEdit}
          />
          <DeleteCreditCardDialog
            open={deleteCreditCardDialogOpen}
            onClose={handleCloseDeleteDialog}
            last4={last4}
            onDeleteCard={onDeleteCard}
          />
          <EditBookingContactDialog
            title="Link Card to Passenger"
            open={editBookingContactDialogOpen}
            onClose={() => setEditBookingContactDialogOpen(false)}
            bookingContact={passengerContact}
            setBookingContact={setPassengerContact}
            setAddContactToOrderDialogOpen={setEditBookingContactDialogOpen}
            setCarryOverSearch={setCarryOverSearch}
            onSave={handleLinkCardToPassenger}
            isSaveButtonDisabled={isCardLinkingLoading}
            filter={linkedPassengerIds}
            isInEditMode
            shouldIncludeFilterItems
            defaultContactSuggestions={contact?.linkedPassengers}
            contactsOptionsGroupBy={{
              "Linked Passengers": linkedPassengerIds,
            }}
          />
        </>
      )}
    </Card>
  );
}

export default PaymentMethodListItem;
