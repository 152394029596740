import React, { useEffect, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";

import { Dialog, Box, Typography } from "@mui/material";

import {
  CREATE_DUDA_WEBSITE_MUTATION,
  CREATE_OPERATOR_DUDA_WEBSITE_USER,
  SET_BASIC_DUDA_PERMISSIONS_MUTATION,
} from "../../../globals/graphql";
import TemplateDialogHeader from "./components/TemplateDialogHeader";
import TemplateDialogFooter from "./components/TemplateDialogFooter";
import TemplateCarousel from "./components/TemplateCarousel";
import {
  useAnalytics,
  useScreenSize,
  useSnackbar,
} from "../../../globals/hooks";
import {
  freeWebsiteTemplates,
  paidWebsiteTemplates,
} from "../../../globals/utils/templates";
import { granite } from "../../../design-system/colors";
import { WebsiteBuilderLottie } from "../../../design-system/lottie";
import { DraggableComponent } from "components/DraggableComponent";

// todo: add duda template type
// type DudaTemplate = {};

type WebsiteTemplateDialogProps = {
  operatorSlug?: string;
  companyName?: string;
  location?: string;

  open: boolean;
  onClose: () => void;
};

function WebsiteTemplateDialog(props: WebsiteTemplateDialogProps) {
  const { operatorSlug, companyName, location, open, onClose } = props;

  // state
  const [activeTab, setActiveTab] = useState(0);

  const isFreeTab = activeTab === 0;
  const isPaidTab = activeTab === 1;

  // hooks
  const snackbar = useSnackbar();
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();

  // mutations
  const [setBasicPermissions] = useMutation(
    SET_BASIC_DUDA_PERMISSIONS_MUTATION
  );
  const [createOperatorDudaWebsiteUser] = useMutation(
    CREATE_OPERATOR_DUDA_WEBSITE_USER
  );
  const [createDudaWebsite] = useMutation(CREATE_DUDA_WEBSITE_MUTATION, {
    onError(error) {
      snackbar.error("Error creating website.");
      setIsCreatingWebsite(false);
      onClose();
    },
    async onCompleted(data) {
      // Tie operator to duda website
      // Creates duda account authorized to modify site
      await createOperatorDudaWebsiteUser({ variables: { input: {} } });
      await setBasicPermissions({ variables: { input: {} } });

      track("Duda website created");
      onClose();
    },
  });

  // state
  const [activeTemplateIndex, setActiveTemplateIndex] = useState(0);
  const [isCreatingWebsite, setIsCreatingWebsite] = useState(false);

  const templates: any = useMemo(
    () =>
      isFreeTab ? freeWebsiteTemplates : isPaidTab ? paidWebsiteTemplates : [],
    [isFreeTab, isPaidTab]
  );
  const activeTemplate = templates.length
    ? templates[activeTemplateIndex]
    : null;

  // event handlers
  const handleNextTemplateClick = () => {
    setActiveTemplateIndex(activeTemplateIndex + 1);
  };

  const handlePeviousTemplateClick = () => {
    setActiveTemplateIndex(activeTemplateIndex - 1);
  };

  const handleCreateWebsiteClick = () => {
    createDudaWebsite({
      variables: {
        input: {
          websitePlan: isFreeTab ? "free" : "paid",
          siteDomain: operatorSlug,
          templateId: activeTemplate.templateId,
          seoDescription: `${companyName} provides the #1 chauffeured black car transportation services in ${location}. Instantly book a black car or limousine now.`,
        },
      },
    });
    setIsCreatingWebsite(true);
  };

  // preload images in memory
  // reference: https://stackoverflow.com/questions/42615556/how-to-preload-images-in-react-js
  useEffect(() => {
    templates.forEach(({ desktopThumbnailUrl, mobileThumbnailUrl }) => {
      new Image().src = desktopThumbnailUrl;
      new Image().src = mobileThumbnailUrl;
    });
  }, [templates]);

  const draggableHandleName = "website-template-dialog-title";

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="md"
      fullScreen={isMobileView}
      {...(!isMobileView && {
        PaperComponent: (paperProps) =>
          DraggableComponent({
            paperProps,
            handleName: draggableHandleName,
          }),
      })}
      aria-labelledby={draggableHandleName}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={isCreatingWebsite ? "center" : "space-between"}
        {...(isCreatingWebsite && {
          alignItems: "center",
        })}
        maxHeight="100vh"
        height="100%"
        minHeight={600}
        p={isMobileView ? 1 : 3}
      >
        {isCreatingWebsite ? (
          <Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Lottie
                loop
                animationData={WebsiteBuilderLottie}
                play
                style={{
                  height: "120px",
                  width: "160px",
                  display: "inline-flex",
                }}
              />

              <Box mt={3}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    color: granite,
                  }}
                  variant="h4"
                >
                  Building Your New Website...
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            {/* header */}
            <TemplateDialogHeader
              onClose={onClose}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              setActiveTemplateIndex={setActiveTemplateIndex}
              draggableHandle={draggableHandleName}
            />

            {/* templates carousel */}
            {!!templates.length && (
              <TemplateCarousel
                activeTemplate={activeTemplate}
                leftArrowDisabled={activeTemplateIndex === 0}
                rightArrowDisabled={
                  activeTemplateIndex === templates.length - 1
                }
                onNextTemplateClick={handleNextTemplateClick}
                onPreviousTemplateClick={handlePeviousTemplateClick}
              />
            )}

            {/* footer */}
            <TemplateDialogFooter
              activeTemplateIndex={activeTemplateIndex}
              previewUrl={activeTemplate?.previewUrl}
              templatesLength={templates.length}
              onCreateWebsiteClick={handleCreateWebsiteClick}
              templateCost={activeTemplate?.templateCost || 0}
            />
          </>
        )}
      </Box>
    </Dialog>
  );
}

export default WebsiteTemplateDialog;
