import React, { useState, useMemo } from "react";
import first from "lodash/first";

import { Trip } from "types";
import { PriceBreakdownIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import { useAnalytics } from "globals/hooks";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";
import { PricingDialogPricingData } from "components/pricing/utils";

type UseAffiliatePricingClickProps = {
  trip: Trip;
  isFarmee: boolean;
  analyticsName?: string;
};

function useAffiliatePricingClick(
  props: UseAffiliatePricingClickProps
): EllipsisMenuOption & {
  pricings: PricingDialogPricingData[];
  open: boolean;
  onClose: () => void;
} {
  const { trip, isFarmee, analyticsName } = props;

  // hooks
  const { track } = useAnalytics();

  // state
  const [affiliatePricingDialogOpen, setAffiliatePricingDialogOpen] =
    useState(false);

  // derived state
  const route = first(trip.routes);
  const updateAffiliatePricingDisabled = !route.canEditFarmeePricing;

  const pricings = useMemo(
    () => [
      {
        ...route.farmeePricing,
        label: route.id,
        totalDurationMinutes: trip.totalDuration,
      },
    ],
    [route.id, route.farmeePricing, trip.totalDuration]
  );

  // event handlers
  const handleAffiliatePricingDialogOpen = () =>
    setAffiliatePricingDialogOpen(true);
  const handleAffiliatePricingDialogClose = () =>
    setAffiliatePricingDialogOpen(false);

  const handleAffiliatePricingClick = () => {
    analyticsName && track(analyticsName);
    handleAffiliatePricingDialogOpen();
  };

  return {
    onClick: handleAffiliatePricingClick,
    text: "Update Affiliate Pricing",
    icon: <PriceBreakdownIcon color={grayDark} size="small" />,
    hideOption: isFarmee,
    disableOption: updateAffiliatePricingDisabled,
    tooltip: updateAffiliatePricingDisabled
      ? {
          titleText: !route.isFarmedRoute
            ? "Trip is not farmed out"
            : "Cannot adjust pricing when there is an active invoice.",
        }
      : undefined,

    // dialog state
    pricings,
    open: affiliatePricingDialogOpen,
    onClose: handleAffiliatePricingDialogClose,
  };
}

export { useAffiliatePricingClick };
