import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";

import { BaseRateAutomation, Trip } from "types";
import { LOAD_AUTOMATED_BASE_RATE } from "globals/graphql";
import { shapeStopsToAutomatedBaseRateQueryStopsArg } from "globals/graphql/automatedPricing";
import { useScreenSize, useSnackbar } from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type UseGetAutomatedBaseRateProps = {
  trip: Trip;
};

function useGetAutomatedBaseRate(props: UseGetAutomatedBaseRateProps) {
  const { trip } = props;

  // hooks
  const snackbar = useSnackbar();
  const { isMobileView } = useScreenSize();

  // state
  const [showAutomatedBaseRateIcon, setShowAutomatedBaseRateIcon] =
    useState(false);

  // query
  const [
    getAutomatedBaseRate,
    { data: baseRate, loading: automatedBaseRateLoading },
  ] = useLazyQuery(LOAD_AUTOMATED_BASE_RATE, {
    onError(error) {
      const errorMessage =
        getErrorMessage(error) || "Failed to get automated base rate.";

      snackbar.error(errorMessage);
    },
  });

  // derived state
  const automatedBaseRate: BaseRateAutomation = baseRate?.automatedBaseRate;
  const stops = trip.stops;
  const vehicleId = trip.routes[0].vehicle?.id;
  const hasEnabledVehicleBRA = trip.routes[0].vehicle?.enableBaseRateAutomation;

  // effects
  useEffect(() => {
    setShowAutomatedBaseRateIcon(!!automatedBaseRate);
  }, [automatedBaseRate]);

  // if requirements are met, get automated pricing
  useEffect(() => {
    if (stops.length) {
      const firstStop = stops[0];
      const lastStop = stops[stops.length - 1];

      if (
        hasEnabledVehicleBRA &&
        (firstStop.location || firstStop.airport) &&
        firstStop.dateTime &&
        (lastStop.location || lastStop.airport)
      ) {
        getAutomatedBaseRate({
          variables: {
            vehicleId: vehicleId,
            stops: shapeStopsToAutomatedBaseRateQueryStopsArg(stops),
          },
        });
      }
    }
  }, [vehicleId, hasEnabledVehicleBRA, getAutomatedBaseRate, stops]);

  return {
    automatedBaseRate,
    automatedBaseRateLoading,
    showAutomatedBaseRateIcon: isMobileView && showAutomatedBaseRateIcon,
    vehicleId,
    hasEnabledVehicleBRA,
    stops,
  };
}

export default useGetAutomatedBaseRate;
