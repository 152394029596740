import React from "react";

import { Box } from "@mui/material";

import ChatToggleButton from "components/chat/ChatToggleButton";
import CompanyLogo from "components/globals/CompanyLogo";
import GlobalSearch from "components/GlobalSearch";
import ComposeEmailButton from "./ComposeEmailButton";
import GettingStartedButton from "./GettingStartedButton";

type TopBarContentDesktopProps = {
  chatPanelOpen: boolean;
  onChatPanelToggle: () => void;
  isChatAccessible: boolean;
};

function TopBarContentDesktop(props: TopBarContentDesktopProps) {
  const { chatPanelOpen, onChatPanelToggle, isChatAccessible } = props;

  return (
    <Box display="flex" justifyContent="space-between" flex="1">
      <Box
        display="flex"
        maxWidth="50vw"
        width="100%"
        alignItems="center"
        flex="1"
        ml={2}
      >
        <CompanyLogo size="medium" />
        <Box m={2} justifyContent="center" width="100%">
          <GlobalSearch variant="desktop" />
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center" mr="21px">
        <GettingStartedButton />
        <ComposeEmailButton />
        <ChatToggleButton
          chatPanelOpen={chatPanelOpen}
          onChatPanelToggle={onChatPanelToggle}
          isChatAccessible={isChatAccessible}
        />
      </Box>
    </Box>
  );
}

export default TopBarContentDesktop;
