import React from "react";

type DollarIconProps = {
  color?: string;
  size?: "small";
  center?: string;
};

const DollarIcon = (props: DollarIconProps) => {
  const { color, size, center } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 2V14M10.5 4.18182H6.75C6.28587 4.18182 5.84075 4.38295 5.51256 4.74098C5.18437 5.099 5 5.58459 5 6.09091C5 6.59723 5.18437 7.08282 5.51256 7.44084C5.84075 7.79886 6.28587 8 6.75 8H9.25C9.71413 8 10.1592 8.20114 10.4874 8.55916C10.8156 8.91718 11 9.40277 11 9.90909C11 10.4154 10.8156 10.901 10.4874 11.259C10.1592 11.617 9.71413 11.8182 9.25 11.8182H5"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox={center || "0 0 24 24"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 3V21M16.1667 6.27273H9.91667C9.14312 6.27273 8.40125 6.57443 7.85427 7.11147C7.30729 7.6485 7 8.37688 7 9.13636C7 9.89585 7.30729 10.6242 7.85427 11.1613C8.40125 11.6983 9.14312 12 9.91667 12H14.0833C14.8569 12 15.5987 12.3017 16.1457 12.8387C16.6927 13.3758 17 14.1042 17 14.8636C17 15.6231 16.6927 16.3515 16.1457 16.8885C15.5987 17.4256 14.8569 17.7273 14.0833 17.7273H7"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DollarIcon;
