import React from "react";
import moment from "moment-timezone";

import { Box, Card, CardActionArea, Typography } from "@mui/material";

import { Term } from "../../../types";

const styles = {
  cancelationItemText: {
    fontSize: "12px",
  },
};

type TermsListItemDesktopProps = {
  term: Term;
  onClick: (termId: string) => void;
};

function TermsListItemDesktop(props: TermsListItemDesktopProps) {
  const { term, onClick } = props;

  const handleCardClick = () => onClick(term.id);

  return (
    <Card variant="outlined">
      <CardActionArea disableRipple onClick={handleCardClick}>
        <Box
          display="flex"
          flexDirection="row"
          flex="1"
          py={1.5}
          px={3}
          height="76px"
          alignItems="center"
        >
          {/* Name */}
          <Box display="flex" flex="4">
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {term.name}
            </Typography>
          </Box>

          {/* Created */}
          <Box display="flex" flex="1">
            <Typography sx={styles.cancelationItemText}>
              {moment(term.createdAt).format("LLL")}
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default TermsListItemDesktop;
