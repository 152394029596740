import React from "react";

import { Box } from "@mui/material";
import { ReviewPlatformBlock, CustomerReviews } from "./components";
import CustomerReviewSetting from "./components/CustomerReviewSetting";

function ReviewPlatformReport() {
  return (
    <Box display="flex" flexDirection="column">
      <ReviewPlatformBlock />
      <CustomerReviewSetting />
      <CustomerReviews />
    </Box>
  );
}

export default ReviewPlatformReport;
