import React from "react";

import { Box, Card, CardActionArea, Typography } from "@mui/material";

import { phoneNumber } from "globals/utils/phoneNumberFormatter/phoneNumberFormatter";

const styles = {
  invoiceItemText: {
    fontSize: "12px",
    lineHeight: 1.33,
  },
};

type InvoicesListItemDesktopProps = {
  id: string;
  smartDueDate: string;
  handleClick: (invoiceId: string) => void;
  billingContact: {
    name: string;
    email: string;
    phone: string | phoneNumber;
  };
  invoiceNumber: string;
  createdAt: string;
  totalAmount: string;
};

function InvoicesListItemDesktop(props: InvoicesListItemDesktopProps) {
  const {
    id,
    smartDueDate,
    handleClick,
    invoiceNumber,
    billingContact,
    createdAt,
    totalAmount,
  } = props;

  return (
    <Card variant="outlined" sx={{ width: "800px" }}>
      <CardActionArea
        onClick={() => {
          handleClick(id);
        }}
        disableRipple
      >
        <Box
          display="flex"
          flexDirection="row"
          flex="1"
          py={1.5}
          px={3}
          height="72px"
          margin="auto"
          alignItems="center"
        >
          {/* Invoice Number */}
          <Box display="flex" flex="1">
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              {invoiceNumber}
            </Typography>
          </Box>

          {/* Billing Contact */}
          <Box display="flex" flex="1.5" ml={-6}>
            <Box flexDirection="column">
              <Typography sx={styles.invoiceItemText}>
                {billingContact.name}
              </Typography>
              <Typography sx={styles.invoiceItemText}>
                {billingContact.email}
              </Typography>
              {billingContact.phone && (
                <Typography sx={styles.invoiceItemText}>
                  {billingContact.phone}
                </Typography>
              )}
            </Box>
          </Box>

          {/* Due Date */}
          <Box
            display="flex"
            flex="1"
            height="100%"
            alignItems="center"
            mr={smartDueDate.length < 10 ? -4 : -1}
          >
            <Typography sx={styles.invoiceItemText} style={{ fontWeight: 600 }}>
              {smartDueDate}
            </Typography>
          </Box>

          {/* Created At */}
          <Box display="flex" flex="1" mr={-5}>
            <Box>
              <Typography sx={styles.invoiceItemText}>{createdAt}</Typography>
            </Box>
          </Box>

          {/* Total Amount */}
          <Box display="flex" flex="1" ml={1} mr={-12}>
            {totalAmount}
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default InvoicesListItemDesktop;
