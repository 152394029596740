import { gql } from "@apollo/client";

export const LOAD_GRIDDNET_OPERATOR_QUERY = gql`
  query GriddnetOperator($id: ID!) {
    node(id: $id) {
      id
      ... on GriddnetOperator {
        createdAt
        updatedAt
        operatorName
        operatorAddress
        operatorPhone
        operatorEmail
        contactName
        websiteUrl
        serviceArea
        paymentMethods {
          id
          createdAt
          updatedAt
          cardNote
          isPrimary
          billingDetails {
            name
          }
          payerId
          stripeStatus
          card {
            expMonth
            expYear
            last4
            brand
          }
        }
      }
    }
  }
`;

export const UPDATE_OPERATOR_GRIDDNET_ID_MUTATION = gql`
  mutation UpdateOperatorGnetId($input: UpdateOperatorGnetIdInput!) {
    updateOperatorGnetId(input: $input) {
      operator {
        id
      }
    }
  }
`;
