import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment-timezone";
import clipboardCopy from "clipboard-copy";

import { Box, CircularProgress, Typography } from "@mui/material";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";

import UpdateDrawer from "../../globals/UpdateDrawer";
import TopOverviewBar from "../../globals/TopOverviewBar";
import {
  grayDark,
  grayLight,
  invoiceStatusBgPaid,
  invoiceStatusBgPending,
  invoiceStatusTextPaid,
  invoiceStatusTextPending,
} from "../../../design-system/colors";
import GQLQueryStatusIndicator from "../../GQLQueryStatusIndicator";
import {
  ClipboardIcon,
  CopyIcon,
  DollarIcon,
  DownloadIcon,
  DueIcon,
  EmailIcon,
  TrashIcon,
} from "../../../design-system/icons";
import { currency } from "../../../globals/utils/helpers";
import { useAnalytics, useOperator, useSnackbar } from "../../../globals/hooks";
import RemoveDialog from "../../RemoveDialog";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { downloader } from "globals/utils/downloader";
import RoundChip from "design-system/components/chips/RoundChip";
import InvoiceActivityLogDialog from "components/globals/ActivityLog/InvoiceActivityLogDialog";
import { BillingContact, OrderItems, PaymentsBlock, Memo } from "./components";
import { useLoadInvoice, useRemoveInvoice } from "./hooks";
import { getSmartDueDate } from "./utils";
import SendEmailDialog, {
  EmailRecipient,
} from "components/SendEmailDialog/SendEmailDialog";
import { EmailModeEnum } from "types";
import MoovsTooltip from "components/MoovsTooltip";

type LocationState = {
  dialogOpen: boolean;
};

function UpdateInvoiceDrawer() {
  // hooks
  const history = useHistory();
  const location = useLocation();
  const snackbar = useSnackbar();
  const { nameSlug } = useOperator() || {};
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { track } = useAnalytics();
  const { dialogOpen } = (location.state as LocationState) || {};
  const { invoice, invoiceLoading, invoiceRefetch, invoiceError } =
    useLoadInvoice(invoiceId);
  const {
    removeInvoice,
    removeInvoiceLoading,
    removedInvoiceData,
    deleteDialogOpen,
    setDeleteDialogOpen,
  } = useRemoveInvoice();

  // state
  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "loading" | "saved" | "error"
  >("default");
  const [sendEmailDialogOpen, setSendEmailDialogOpen] = useState(
    dialogOpen || false
  );
  const [activityLogOpen, setActivityLogOpen] = useState(false);
  const [isInvoicePDFDownloading, setIsInvoicePDFDownloading] = useState(false);

  // derived state
  const isInvoicePaid = invoice?.status === "PAID";

  // effects
  // close RemoveDialog when removeInvoice mutation completes
  useEffect(() => {
    if (removedInvoiceData) {
      history.push(`/invoices`);
    }
  }, [removedInvoiceData, history]);

  // event handlers
  const handleNavigateToRequestClick = (requestId) => {
    const win = window.open(`/reservations/${requestId}`, "_blank");
    win.focus();
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleClickRemove = () => {
    removeInvoice({
      variables: {
        input: {
          id: invoice.id,
        },
      },
    });
  };

  const handleCopyInvoice = async () => {
    try {
      await clipboardCopy(
        `${process.env.REACT_APP_CUSTOMERAPP_URL}/${nameSlug}/invoice/${invoice.id}`
      );
      snackbar.success(`Successfully copied invoice!`);
      track("invoice_copied");
    } catch (error) {
      snackbar.error("Could not copy invoice");
    }
  };

  const handleViewInvoice = () => {
    track("invoice_viewed");
    window.open(
      `${process.env.REACT_APP_CUSTOMERAPP_URL}/${nameSlug}/invoice/${invoice.id}`,
      "_blank"
    );
  };

  const handleDownloadInvoicePDF = async () => {
    const params = {
      id: invoice.id,
    };
    const endPoint = "/endpoint/invoice/operatorPdfDownloadHandler";
    try {
      setIsInvoicePDFDownloading(true);
      await downloader(endPoint, params);
    } catch (error) {
      const errorMessage =
        getErrorMessage(error) ||
        "We had issues downloading your invoice pdf, please try again.";
      snackbar.error(errorMessage);
    }
    setIsInvoicePDFDownloading(false);
    track("invoice_operatorPdf");
  };

  const handleOpenActivityLog = () => setActivityLogOpen(true);
  const handleCloseActivityLog = () => setActivityLogOpen(false);
  const handleEmailDialogClose = () => setSendEmailDialogOpen(false);
  const handleSendInvoice = () => setSendEmailDialogOpen(true);

  return (
    <>
      <UpdateDrawer
        onClose={() => history.push(`/invoices`)}
        createdAt={invoice ? moment(invoice.createdAt).format("LLL") : ""}
        updatedAt={invoice ? moment(invoice.updatedAt).format("LLL") : ""}
        saveIndicatorState={saveIndicatorState}
        headerContent={
          invoice && (
            <>
              <Box mr={1}>
                <RoundChip
                  label={
                    {
                      PAID: "PAID",
                      PENDING: "UNPAID",
                    }[invoice?.status] || "N/A"
                  }
                  color={
                    {
                      PAID: invoiceStatusTextPaid,
                      PENDING: invoiceStatusTextPending,
                    }[invoice?.status] || grayDark
                  }
                  backgroundColor={
                    {
                      PAID: invoiceStatusBgPaid,
                      PENDING: invoiceStatusBgPending,
                    }[invoice?.status] || grayLight
                  }
                />
              </Box>
              {isInvoicePDFDownloading && (
                <Box mr={1}>
                  <MoovsTooltip tooltipText="Please wait while we download your invoice pdf">
                    <CircularProgress size={20} />
                  </MoovsTooltip>
                </Box>
              )}
            </>
          )
        }
        // @ts-ignore fix later
        ellipsisMenuOptions={
          invoice
            ? invoice.status === "PENDING"
              ? [
                  {
                    onClick: handleSendInvoice,
                    text: "Send Invoice",
                    icon: <EmailIcon color={grayDark} size="small" />,
                  },
                  {
                    onClick: handleCopyInvoice,
                    text: "Copy Invoice",
                    icon: <CopyIcon color={grayDark} size="small" />,
                  },

                  {
                    onClick: handleDownloadInvoicePDF,
                    text: "Download as PDF",
                    disableOption: isInvoicePDFDownloading,
                    icon: <DownloadIcon color={grayDark} size="small" />,
                  },

                  {
                    onClick: handleOpenActivityLog,
                    text: "View Activity Log",
                    icon: <DueIcon color={grayDark} size="small" />,
                  },
                  "divider",

                  {
                    text: "Delete Invoice",
                    icon: <TrashIcon color={grayDark} size="small" />,
                    onClick: handleDeleteClick,
                  },
                ]
              : [
                  {
                    onClick: handleCopyInvoice,
                    text: "Copy Invoice",
                    icon: <CopyIcon color={grayDark} size="small" />,
                  },
                  {
                    onClick: handleViewInvoice,
                    text: "View Invoice",
                    icon: <DollarIcon color={grayDark} size="small" />,
                  },
                  {
                    onClick: handleDownloadInvoicePDF,
                    text: "Download as PDF",
                    icon: <DownloadIcon color={grayDark} size="small" />,
                  },
                  "divider",
                  {
                    text: "Delete Invoice",
                    icon: <TrashIcon color={grayDark} size="small" />,
                    onClick: handleDeleteClick,
                    disableOption: isInvoicePaid,
                    tooltip: {
                      titleText: isInvoicePaid
                        ? "You cannot delete an invoice that is paid."
                        : "",
                    },
                  },
                ]
            : ["Loading"]
        }
      >
        {invoiceLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={40} thickness={2} />
          </Box>
        )}

        {invoiceError && !invoiceLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <GQLQueryStatusIndicator
              name="Invoice"
              data={invoice}
              error={invoiceError}
              refetch={invoiceRefetch}
            />
          </Box>
        )}

        {invoice && (
          <>
            <Box mb={3}>
              <TopOverviewBar
                items={[
                  {
                    title: "invoice no.",
                    value: (
                      <Typography style={{ fontWeight: 600 }}>
                        {invoice?.invoiceNumber}
                      </Typography>
                    ),
                    icon: (
                      <ClipboardIcon viewBox="-4 -4 30 30" color={grayDark} />
                    ),
                  },
                  {
                    title: "amount due",
                    value: currency(invoice?.amountDue),
                    icon: (
                      <FormatAlignLeftIcon
                        viewBox="-4 -4 30 30"
                        style={{ color: grayDark }}
                      />
                    ),
                  },
                  {
                    title: "due date",
                    value: getSmartDueDate(invoice),
                    icon: (
                      <FormatAlignLeftIcon
                        viewBox="-4 -4 30 30"
                        style={{ color: grayDark }}
                      />
                    ),
                  },
                ]}
              />
            </Box>

            {/* Billing Contact */}
            <BillingContact invoice={invoice} />

            {/* Items */}
            <OrderItems
              invoice={invoice}
              handleNavigateToRequestClick={handleNavigateToRequestClick}
            />

            {/* Payments */}
            <Box mt={2}>
              <PaymentsBlock
                payments={invoice.subPayments}
                setSaveIndicatorState={setSaveIndicatorState}
                parentComponent="update-invoice-drawer"
                invoiceAmountDue={invoice?.amountDue}
                invoiceRefetch={invoiceRefetch}
                invoice={invoice}
              />
            </Box>

            {/* Memo */}
            <Memo invoice={invoice} />

            {/* Dialogs */}
            <SendEmailDialog
              invoiceId={invoiceId}
              //@ts-ignore fix later
              toEmails={
                // @ts-ignore
                [
                  invoice.company || invoice.contact || invoice.farmAffiliate,
                ] as EmailRecipient
              }
              mode={EmailModeEnum.Invoice}
              open={sendEmailDialogOpen}
              onClose={handleEmailDialogClose}
            />

            <InvoiceActivityLogDialog
              open={activityLogOpen}
              onClose={handleCloseActivityLog}
              invoiceId={invoiceId}
              invoiceNumber={invoice.invoiceNumber}
            />

            <RemoveDialog
              open={deleteDialogOpen}
              onRemove={handleClickRemove}
              onClose={() => setDeleteDialogOpen(false)}
              title="Delete invoice?"
              removeButtonText="Delete"
              isSaving={removeInvoiceLoading}
              body="This action will permanently delete this invoice.  You will be able to create a new invoice with these order(s)."
            />
          </>
        )}
      </UpdateDrawer>
    </>
  );
}

export default UpdateInvoiceDrawer;
