import React from "react";

type ChevronLeftIconProps = {
  color?: string;
  size: "small";
};

const ChevronLeftIcon = (props: ChevronLeftIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 2L5 8L11 14"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
};

export default ChevronLeftIcon;
