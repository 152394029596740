import React from "react";
import startCase from "lodash/startCase";

import { Box, Typography, CardActionArea, Card } from "@mui/material";

import { Contact } from "../../../types";
import MoovsAvatar from "../../../design-system/components/MoovsAvatar";
import { black } from "../../../design-system/colors";

type ContactsListItemMobileProps = {
  contact: Contact;
  onContactClick: (contactId) => void;
};

function ContactsListItemMobile(props: ContactsListItemMobileProps) {
  const { contact, onContactClick } = props;

  return (
    <Box mb={1}>
      <Card variant="outlined">
        <CardActionArea
          disableRipple
          onClick={() => {
            onContactClick(contact.id);
          }}
        >
          <Box p={2} display="flex" flexDirection="row" flex="1">
            <Box mr={2}>
              <MoovsAvatar
                alt="contact, person"
                size="small"
                placeholder={[contact.firstName, contact.lastName]}
              />
            </Box>
            <Box display="flex" flexDirection="column" flex="1">
              <Box
                display="flex"
                flexDirection="row"
                flex="1"
                alignItems="baseline"
                mb={0.5}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 700, color: black, fontSize: 16 }}
                >
                  {`${startCase(contact.firstName)} ${startCase(
                    contact.lastName
                  )}`}
                </Typography>
              </Box>
              <Box mb={0.5}>
                <Typography variant="caption" sx={{ color: black }}>
                  {contact.email}
                </Typography>
              </Box>
              <Box mb={0.5}>
                {!!contact.mobilePhone && (
                  <Typography variant="caption" sx={{ color: black }}>
                    {contact.mobilePhone}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </CardActionArea>
      </Card>
    </Box>
  );
}

export default ContactsListItemMobile;
