import React from "react";

type GettingStartedIconProps = {
  color?: string;
};

const GettingStartedIcon = (props: GettingStartedIconProps) => {
  const { color } = props;

  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 1H2.96667C2.15665 1 1.5 1.65665 1.5 2.46667V20.5333C1.5 21.3434 2.15665 22 2.96667 22H21.0333C21.8434 22 22.5 21.3434 22.5 20.5333V11M9.87931 6.06944H12M9.87931 11.5H16.5M9.87931 16.9306H18.5M4.5 6.18827L5.40237 7.13889L7.4327 5M4.5 11.6188L5.40237 12.5694L7.4327 10.4306M4.5 17.0494L5.40237 18L7.4327 15.8611"
        stroke={color || "#195FE9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GettingStartedIcon;
