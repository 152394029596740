import { useEffect, useState } from "react";

import { EmailModeEnum } from "types";

type UseEmailModeProps = {
  open: boolean;
  mode: EmailModeEnum;
};

function useEmailMode(props: UseEmailModeProps) {
  const { open } = props;

  // state
  const [mode, setMode] = useState(props.mode);

  // event handlers
  const handleModeChange = (mode: EmailModeEnum) => {
    setMode(mode);
  };

  // effects
  // reset message on dialog open
  useEffect(() => {
    if (open) {
      setMode(props.mode);
    }
  }, [open, props.mode]);

  return { mode, onModeChange: handleModeChange };
}

export default useEmailMode;
