import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import InputMask from "react-input-mask";
import { DeepPartial } from "ts-essentials";

import { Box, Typography, TextField, Divider } from "@mui/material";

import { Trip } from "../../../../../types";
import { useOperator } from "globals/hooks";
import LuggageMenu from "../../../luggage/LuggageMenu";
import { LuggageFields } from "../../../luggage/types";

type AdditionalInfoCreateBlockType = {
  trip: DeepPartial<Trip>;
  updateTrip: (newTrip: DeepPartial<Trip>, tripIndex: number) => void;
  tripIndex: number;
  requestType: string;
  setLuggageAmount: Dispatch<SetStateAction<LuggageFields>>;
  luggageAmount: LuggageFields;
  isShuttleTrip?: boolean;
};

function AdditionalInfoCreateBlock(props: AdditionalInfoCreateBlockType) {
  const {
    trip,
    updateTrip,
    tripIndex,
    setLuggageAmount,
    luggageAmount,
    isShuttleTrip,
  } = props;

  // hooks
  const operator = useOperator();

  // event handler
  const handleTripDetailsChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value =
      event.target.name ===
      ("totalGroupSize" || "baseRate" || "driverGratuity" || "promoPct")
        ? Number(event.target.value)
        : event.target.value;

    updateTrip(
      {
        ...trip,
        [event.target.name]: value,
      },
      tripIndex
    );
  };

  // derived state
  const showLuggageMenu =
    !!operator?.settings?.luggageEnabled && !isShuttleTrip;
  const showPassengerCount = !isShuttleTrip;

  return (
    <Box>
      <Box mt={4} mb={1.5}>
        <Typography variant="h5">Additional Info</Typography>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      <Box display="flex" flexDirection="column">
        {showPassengerCount && (
          <Box mt={1}>
            <InputMask
              mask="999"
              maskChar=""
              onChange={handleTripDetailsChange}
              value={trip.totalGroupSize || ""}
            >
              {() => (
                <TextField
                  fullWidth
                  variant="outlined"
                  name="totalGroupSize"
                  label="Passenger Count"
                />
              )}
            </InputMask>
          </Box>
        )}
        <Box mt={1}>
          <TextField
            fullWidth
            variant="outlined"
            name="note"
            value={trip.note || ""}
            label="Trip Notes"
            onChange={handleTripDetailsChange}
            multiline
          />
        </Box>
        {showLuggageMenu && (
          <LuggageMenu
            luggageAmount={luggageAmount}
            setLuggageAmount={setLuggageAmount}
          />
        )}
      </Box>
    </Box>
  );
}

export default AdditionalInfoCreateBlock;
