import React from "react";

import { Typography } from "@mui/material";
import MoovsDialog from "components/MoovsDialog";
import { granite } from "design-system/colors";

type FreeTrialDeleteDialogProps = {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
};

const FreeTrialDeleteDialog = (props: FreeTrialDeleteDialogProps) => {
  const { open, onClose, onAccept } = props;

  return (
    <MoovsDialog
      dialogTitle="Are you sure you want to delete?"
      open={open}
      onClose={onClose}
      onAccept={onAccept}
      acceptButtonText="Yes, Delete"
      closeButtonText="No, Continue Trial"
      size="xs"
      acceptButtonIsDelete
      xsFullscreenMobile
    >
      <Typography variant="body2" color={granite} my={2}>
        If you delete the app while in free trial, you will no longer have
        access and your free trial period will end.
      </Typography>
    </MoovsDialog>
  );
};

export default FreeTrialDeleteDialog;
