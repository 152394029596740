import React from "react";

type CalendarUnavailableIconProps = {
  color?: string;
};

const CalendarIconUnavailable = (props: CalendarUnavailableIconProps) => {
  const { color } = props;
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 1V5M6 1V5M1 9H19M13 12L10 15M10 15L7 18M10 15L7 12M10 15L13 18M3 3H17C18.1046 3 19 3.89543 19 5V19C19 20.1046 18.1046 21 17 21H3C1.89543 21 1 20.1046 1 19V5C1 3.89543 1.89543 3 3 3Z"
        stroke={color || "#D63F49"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarIconUnavailable;
