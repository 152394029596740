/**
 * @file CreateDrawer.tsx
 * Global component for adding/creating  vehicles, quotes, contacts, etc.
 * Built on top of Material UI Drawer
 *
 * components:
 *  CreateDrawer
 *
 * author: jackv
 */
import React, { ReactNode, useEffect, useState, useRef } from "react";

import {
  Box,
  Button,
  Drawer,
  Typography,
  ButtonGroup,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Grow,
  IconButton,
  Tooltip,
  CircularProgress,
  SxProps,
} from "@mui/material";

import {
  grayLight,
  alabaster,
  granite,
  grayMedium,
  black,
  white,
} from "../../design-system/colors";
import { SortMarkerIcon, CrossIcon } from "../../design-system/icons";
import { useScreenSize } from "../../globals/hooks";
import theme from "theme";
import { Variant } from "@mui/material/styles/createTypography";

const DRAWER_WIDTH = 700;

const styles = {
  drawer: {
    flex: 1,
    height: "100%",
    width: "100vw",
    [theme.breakpoints.up(DRAWER_WIDTH)]: {
      width: DRAWER_WIDTH,
    },
    margin: 0,
  },
  drawerHeader: {
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 3,
    "& .MuiIconButton-colorPrimary": { color: granite },
  },
  drawerFooter: {
    borderTop: `1px solid ${grayLight}`,
    backgroundColor: alabaster,
    zIndex: 1001,
    padding: 2,
  },
};

type CreateDrawerProps = {
  // TODO: delete once all drawers are updated
  open?: boolean;
  onClose: () => void;
  pageLabel: string;
  submitLabel?: string;
  onSubmit: () => void;
  saveError?: boolean;
  saveErrorText?: string;
  children?: ReactNode;
  submitLabelOptions?: string[];
  disableSecondarySubmitLabelOptions?: boolean;
  selectedSubmitButtonIndex?: number;
  setSelectedSubmitButtonIndex?: (submitButtonIndex: number) => void;
  submitDisabled?: boolean;
  pageLabelVariant?: Variant;
  headerButton?: ReactNode;
  hideSubmitDisabledLoadingIndicator?: boolean;
  additionalSubmitLabel?: string;
  additionalSubmitDisabled?: boolean;
  additionalOnSubmit?: () => void;
  subHeaderContent?: ReactNode;
  drawerSx?: SxProps;
};

function CreateDrawer(props: CreateDrawerProps) {
  const {
    open,
    onClose,
    pageLabel,
    submitLabel,
    onSubmit,
    saveError,
    saveErrorText = "Oops! Please check the above fields for errors.",
    children,
    submitLabelOptions = [],
    disableSecondarySubmitLabelOptions = false,
    selectedSubmitButtonIndex = 0,
    setSelectedSubmitButtonIndex,
    submitDisabled,
    pageLabelVariant,
    headerButton,
    hideSubmitDisabledLoadingIndicator,
    additionalSubmitLabel,
    additionalSubmitDisabled,
    additionalOnSubmit,
    subHeaderContent,
    drawerSx,
  } = props;

  const { isMobileView } = useScreenSize();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [buttonGroupOptionsOpen, setButtonGroupOptionsOpen] = useState(false);
  const anchorRef = useRef(null);

  useEffect(() => {
    setDrawerOpen(true);
  }, [setDrawerOpen]);

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    // waits for drawer animation to end
    setTimeout(onClose, 195);
  };

  const handleMenuItemClick = (_, index: number) => {
    setSelectedSubmitButtonIndex(index);
    setButtonGroupOptionsOpen(false);
  };

  const handleToggle = () => {
    setButtonGroupOptionsOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setButtonGroupOptionsOpen(false);
  };

  return (
    <Drawer
      disableEscapeKeyDown
      disableEnforceFocus // allows intercom to be focused on messenger while drawer open.
      // there are accessability issues with this prop.
      // reference: https://material-ui.com/api/modal/
      open={open !== undefined ? open : drawerOpen}
      onClose={(_, reason) => reason !== "backdropClick" && handleDrawerClose()}
      anchor="right"
      sx={drawerSx}
    >
      <Box
        display="flex"
        sx={[{ overflowX: "hidden" }, styles.drawer]}
        flexDirection="column"
        flex="1"
        mx={4}
      >
        <Box borderBottom={`1px solid ${grayMedium}`}>
          <Box
            mx={isMobileView ? 2 : 4}
            height="78px"
            sx={styles.drawerHeader}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            display="flex"
          >
            <Box display="flex" alignItems="center" ml="-12px">
              <Tooltip
                enterDelay={200}
                enterNextDelay={200}
                title="Close"
                placement="right"
                arrow
              >
                <IconButton
                  color="primary"
                  onClick={handleDrawerClose}
                  size="large"
                >
                  <CrossIcon color={black} />
                </IconButton>
              </Tooltip>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant={pageLabelVariant || "h4"} pl={1}>
                {pageLabel}
              </Typography>
            </Box>
            {headerButton}
          </Box>
          {subHeaderContent}
        </Box>
        <Box
          display="flex"
          flex="1"
          sx={{
            overflow: "auto",
            flex: 1,
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            px={isMobileView ? 2 : 4}
          >
            {children}
          </Box>
          <Box
            px={isMobileView ? 2 : 4}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            sx={styles.drawerFooter}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mr={1}
              my={1}
              maxWidth="250px"
            >
              {saveError && (
                <Typography variant="subtitle2" color="error">
                  {saveErrorText}
                </Typography>
              )}
            </Box>

            <Box display="flex" alignItems="center" m={2}>
              {/* additional submit button */}
              {additionalSubmitLabel && (
                <Box display="flex" mr={2}>
                  {additionalSubmitDisabled && (
                    <Box mr={1} mt={1}>
                      <CircularProgress size={24} />
                    </Box>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={additionalSubmitDisabled}
                    onClick={additionalOnSubmit}
                  >
                    {additionalSubmitLabel}
                  </Button>
                </Box>
              )}

              {/* normal button */}
              {(submitLabel || submitLabelOptions.length === 1) && (
                <Box display="flex">
                  {submitDisabled && !hideSubmitDisabledLoadingIndicator && (
                    <Box mr={1} mt={1}>
                      <CircularProgress size={24} />
                    </Box>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={submitDisabled}
                    onClick={onSubmit}
                  >
                    {submitLabel || submitLabelOptions[0]}
                  </Button>
                </Box>
              )}

              {/* split button */}
              {submitLabelOptions.length > 1 && !submitLabel && (
                <>
                  {submitDisabled && (
                    <Box mr={1} mt={1}>
                      <CircularProgress size={24} />
                    </Box>
                  )}
                  <ButtonGroup
                    variant="contained"
                    color="primary"
                    ref={anchorRef}
                    aria-label="split button"
                    disabled={submitDisabled}
                  >
                    <Button onClick={onSubmit}>
                      {submitLabelOptions &&
                        submitLabelOptions[selectedSubmitButtonIndex]}
                    </Button>
                    <Button
                      color="primary"
                      size="small"
                      aria-controls={
                        buttonGroupOptionsOpen ? "split-button-menu" : undefined
                      }
                      aria-expanded={
                        buttonGroupOptionsOpen ? "true" : undefined
                      }
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={handleToggle}
                    >
                      <SortMarkerIcon color={white} />
                    </Button>
                  </ButtonGroup>
                  <Popper
                    open={buttonGroupOptionsOpen}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu">
                              {submitLabelOptions.map((option, index) => (
                                <MenuItem
                                  key={option}
                                  disabled={
                                    disableSecondarySubmitLabelOptions &&
                                    index !== 0
                                  }
                                  selected={index === selectedSubmitButtonIndex}
                                  onClick={(event) =>
                                    handleMenuItemClick(event, index)
                                  }
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}

export default CreateDrawer;
