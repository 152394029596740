import React from "react";
import toLower from "lodash/toLower";

import { Button, Typography } from "@mui/material";

import { PlanCardActionBtnVariant } from "./types";
import { useAnalytics } from "globals/hooks";

const { UPGRADE, INTERCOM_UPGRADE, DOWNGRADE, DEMO, CURRENT, VIP_CURRENT } =
  PlanCardActionBtnVariant;

type PlanCardActionButtonProps = {
  variant: PlanCardActionBtnVariant;
  link: string;
  name: string;
  planLength: "monthly" | "annual";
  setUpgradeDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function PlanCardActionButton(props: PlanCardActionButtonProps) {
  const { variant, link, name, planLength, setUpgradeDialogOpen } = props;

  // hooks
  const { track } = useAnalytics();

  const trackButtonClick = () => {
    let trackingName = "";
    const planType = `${planLength}_${toLower(name)}`;

    if (variant === UPGRADE || variant === INTERCOM_UPGRADE) {
      trackingName = "upgradedInitiated";
    }

    if (variant === DEMO) {
      trackingName = "demoBooked";
    }

    if (variant === DOWNGRADE) {
      trackingName = "downgradedInitiated";
    }

    track(`subscription_${trackingName}`, variant !== DEMO && { planType });
  };

  // event handlers
  const openUpgradeDialog = () => {
    trackButtonClick();
    setUpgradeDialogOpen(true);
  };

  const openLink = (e: React.MouseEvent) => {
    e.stopPropagation();

    trackButtonClick();

    window.open(link, "_blank").focus();
  };

  switch (variant) {
    case UPGRADE:
    case INTERCOM_UPGRADE:
      return (
        <Button
          variant="contained"
          {...(variant === INTERCOM_UPGRADE
            ? { id: "fab-help", onClick: trackButtonClick }
            : { onClick: openUpgradeDialog })}
        >
          <Typography variant="body2">Upgrade now</Typography>
        </Button>
      );
    case DOWNGRADE:
      return (
        <Button id="fab-help" variant="outlined" onClick={trackButtonClick}>
          <Typography variant="body2">Downgrade</Typography>
        </Button>
      );
    case DEMO:
      return (
        <Button variant="contained" onClick={openLink}>
          <Typography variant="body2">Book a demo</Typography>
        </Button>
      );
    case CURRENT:
    case VIP_CURRENT:
      return (
        <Button variant="outlined" disabled>
          <Typography variant="body2">Current Plan</Typography>
        </Button>
      );
    default:
      return null;
  }
}

export default PlanCardActionButton;
