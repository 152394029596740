/* Moovs Colors */

import { PaymentPaymentStatus, PickUpVariant } from "types";
import { RoundChipProps } from "./components/chips/RoundChip";

// Blues
export const moovsBlue = "#195FE9";
export const moovsBlueDark = "#0044CB";
export const moovsBlueLight = "#739CFF";
export const moovsBlueSelected = "#E5EEFF";
export const moovsBlueHover = "#CCDEFF";
export const moovsLiteBlue = "#AED1DD";
// Grayscale
export const black = "#1E1E1E";
export const granite = "#565656";
export const grayDark = "#999999";
export const grayMedium = "#D3D3D3";
export const grayLight = "#EDEDED";
export const alabaster = "#FAFAFA";
export const white = "#FFFFFF";
export const whiteTransparent = "#FFFFFF00";
// Alert Colors
export const successGreen = "#0C893F";
export const warningYellow = "#FFB301";
export const errorRed = "#D63F49";
// Contextual Colors
export const aqua = "#28B299";
export const green = "#28B668";
export const greenDark = "#17693C";
export const greenLight = "#E7F5EE";
export const yellow = "#EEE647";
export const yellowDark = "#898423";
export const orange = "#FC881D";
export const orangeDark = "#B26115";
export const orangeDarkSharp = "#DC6803";
export const redDark = "#960031";
export const purple = "#A444B7";
export const purpleDark = "#3831A0";
export const pink = "#EE64D2";
// Color Theme Colors - Customer Portal colors (e.g. primary, light)
// other Color Themes w/ different alias: aqua, grayDark, greenDark, moovsBlue, purple, purpleDark, redDark
export const grass = "#559F63";
export const navy = "#0044CB";
export const rose = "#EF6351";
export const sky = "#5591F4";
// Tint Colors
export const tintAqua = "#E9F9F5";
export const tintGreen = "#D0ECDD";
export const tintYellow = "#F8F5B5";
export const tintOrange = "#FEEAD2";
export const tintRed = "#FDECED";
export const tintPurple = "#F6EDF7";
export const tintPink = "#FEE8F7";
export const lightTintOrange = "#FFFCF5";

/* Dev-friendly Color Palettes */
export const swoopGreen = "#34EC87";
export const dispatchAlertColor = "#ADD8E6";

// Invite Status
export const invitePending = "#f37f2d";
export const inviteAccepted = "#6FCF97";

// Request Status Chip Background
export const requestStatusBgNewQuote = "#e9f9f5";
export const requestStatusBgDraft = "#f8f5b5";
export const requestStatusBgEmailSent = "#f6edf7";
export const requestStatusBgArchived = tintPink;
export const requestStatusBgExpired = "#ebebeb";

// Request Status Chip Text
export const requestStatusTextNewQuote = "#28b299";
export const requestStatusTextDraft = "#898423";
export const requestStatusTextEmailSent = "#a444b7";
export const requestStatusTextArchived = errorRed;
export const requestStatusTextExpired = granite;

// Invoice Status Chip Background
export const invoiceStatusBgPaid = "#D0ECDD";
export const invoiceStatusBgPending = "#F8F5B5";

// Invoice Status Chip Text
export const invoiceStatusTextPaid = "#28B668";
export const invoiceStatusTextPending = "#898423";

// Stripe Status Chip Background
export const stripeStatusBgPending = "#F8F5B5";
export const stripeStatusBgRejected = "#FDECED";

// Stripe Status Chip Text
export const stripeStatusTextPending = "#898423";
export const stripeStatusTextRejected = "#EF5259";

// Ride Status Chip Background
export const rideStatusBgDone = "#D0ECDD";
export const rideStatusBgOnLoc = "#E9F9F5";
export const rideStatusBgOTW = "#F6EDF7";
export const rideStatusBgPOB = "#FEE8F7";
export const rideStatusBgConfirmed = "#E5EEFF";
export const rideStatusBgPending = "#F8F5B5";
export const rideStatusBgCancelled = "#FDECED";

// Ride Status Chip Text
export const rideStatusTextDone = "#28B668";
export const rideStatusTextOnLoc = "#28B299";
export const rideStatusTextOTW = "#A444B7";
export const rideStatusTextPOB = "#EE64D2";
export const rideStatusTextConfirmed = "#256EFF";
export const rideStatusTextPending = "#898423";
export const rideStatusTextCancelled = "#EF5259";

/* Component Color Objects */
// Request Status Chip
export const requestStatusLabelColors = {
  NEW_QUOTE: {
    backgroundColor: requestStatusBgNewQuote,
    color: requestStatusTextNewQuote,
  },
  DRAFT: {
    backgroundColor: requestStatusBgDraft,
    color: requestStatusTextDraft,
  },
  EMAIL_SENT: {
    backgroundColor: requestStatusBgEmailSent,
    color: requestStatusTextEmailSent,
  },
  ARCHIVED: {
    backgroundColor: requestStatusBgArchived,
    color: requestStatusTextArchived,
  },
  EXPIRED: {
    backgroundColor: requestStatusBgExpired,
    color: requestStatusTextExpired,
  },
};

// Invoice Status Chip
export const invoiceStatusLabelColors = {
  PAID: {
    backgroundColor: invoiceStatusBgPaid,
    color: invoiceStatusTextPaid,
  },
  PENDING: {
    backgroundColor: invoiceStatusBgPending,
    color: invoiceStatusTextPending,
  },
};

// Ride Status Chip
export const rideStatusLabelColors = {
  done: {
    backgroundColor: rideStatusBgDone,
    color: rideStatusTextDone,
  },
  "on-location": {
    backgroundColor: rideStatusBgOnLoc,
    color: rideStatusTextOnLoc,
  },
  otw: {
    backgroundColor: rideStatusBgOTW,
    color: rideStatusTextOTW,
  },
  pob: {
    backgroundColor: rideStatusBgPOB,
    color: rideStatusTextPOB,
  },
  confirmed: {
    backgroundColor: rideStatusBgConfirmed,
    color: rideStatusTextConfirmed,
  },
  pending: {
    backgroundColor: rideStatusBgPending,
    color: rideStatusTextPending,
  },
  cancelled: {
    backgroundColor: rideStatusBgCancelled,
    color: rideStatusTextCancelled,
  },
};

// Reservation Payment Status Chip
export const reservationPaymentStatusLabelColors = {
  NOT_PAID: {
    backgroundColor: tintPink,
    color: errorRed,
    label: "NOT PAID",
  },
  PAID: {
    backgroundColor: tintGreen,
    color: successGreen,
    label: "PAID",
  },
  UNCONFIRMED: {
    backgroundColor: moovsBlueSelected,
    color: moovsBlue,
    label: "UNCONFIRMED",
  },
  DECLINED: {
    backgroundColor: tintPink,
    color: errorRed,
    label: "DECLINED",
  },
};

// Trip Grid Status Chip
export const tripGridStatusLabelColors = {
  ...reservationPaymentStatusLabelColors,
  QUOTE: {
    backgroundColor: grayLight,
    color: black,
    label: "QUOTE",
  },
  CANCELLED: {
    color: errorRed,
    backgroundColor: tintRed,
    label: "CANCELLED",
  },
};

// Affiliate Grid Status Chip
export const affiliateGridStatusLabelColors = {
  "FARM-IN": {
    backgroundColor: tintOrange,
    color: orange,
    label: "FARM-IN",
  },
  "FARM-OUT": {
    backgroundColor: tintPurple,
    color: purple,
    label: "FARM-OUT",
  },
};

// Payment Statuses Chip
export const paymentStatusLabelColors: Record<
  PaymentPaymentStatus,
  RoundChipProps
> = {
  DISPUTE_WON: {
    backgroundColor: moovsBlueDark,
    color: white,
    label: "DISPUTE WON",
  },
  DISPUTE_LOST: {
    backgroundColor: greenDark,
    color: white,
    label: "DISPUTE LOST",
  },
  DISPUTED: {
    backgroundColor: orangeDark,
    color: white,
    label: "DISPUTED",
  },
  EVIDENCE_IN_REVIEW: {
    backgroundColor: tintYellow,
    color: yellowDark,
    label: "EVIDENCE IN REVIEW",
    size: "medium",
  },
  EARLY_FRAUD_WARNING: {
    backgroundColor: tintPurple,
    color: purple,
    label: "EARLY FRAUD WARNING",
    size: "medium",
  },
  FAILED: {
    backgroundColor: tintPink,
    color: errorRed,
    label: "FAILED",
  },
  SUCCEEDED: {
    backgroundColor: tintGreen,
    color: greenDark,
    label: "SUCCEEDED",
  },
  BLOCKED: {
    backgroundColor: redDark,
    color: white,
    label: "BLOCKED",
  },
  PARTIAL: {
    backgroundColor: tintOrange,
    color: orange,
    label: "PARTIAL REFUND",
  },
  FULL: {
    backgroundColor: moovsBlueSelected,
    color: moovsBlue,
    label: "REFUNDED",
  },
  CANCELLED: {
    backgroundColor: yellowDark,
    color: white,
    label: "CANCELLED",
  },
  UNCAPTURED: {
    backgroundColor: grayLight,
    color: black,
    label: "UNCAPTURED",
  },
};

export const paymentMethodPieChartColors = {
  "Credit Card": {
    color: moovsBlue,
  },
  Cash: {
    color: moovsBlueLight,
  },
  Check: {
    color: warningYellow,
  },
  Venmo: {
    color: moovsBlueSelected,
  },
  Wire: {
    color: greenDark,
  },
};

// Dispatch Scheduler Colors
export const dispatchSchedulerColors = [
  {
    backgroundColor: "#574CFA",
    textColor: white,
  },
  {
    backgroundColor: "#0080FC",
    textColor: white,
  },
  {
    backgroundColor: "#02A38A",
    textColor: white,
  },
  {
    backgroundColor: "#EB004C",
    textColor: white,
  },
  {
    backgroundColor: "#201C5B",
    textColor: white,
  },
  {
    backgroundColor: "#013C33",
    textColor: white,
  },
  {
    backgroundColor: "#9C540D",
    textColor: white,
  },
  {
    backgroundColor: "#740127",
    textColor: white,
  },
  {
    backgroundColor: "#003B73",
    textColor: white,
  },
  {
    backgroundColor: "#B60830",
    textColor: white,
  },
  {
    backgroundColor: "#FE9202",
    textColor: black,
  },
  {
    backgroundColor: "#C1BDFD",
    textColor: black,
  },
  {
    backgroundColor: "#D037FE",
    textColor: black,
  },
  {
    backgroundColor: "#D1EEE9",
    textColor: black,
  },
  {
    backgroundColor: "#FECD8C",
    textColor: black,
  },
  {
    backgroundColor: "#FFA3C0",
    textColor: black,
  },
  {
    backgroundColor: "#757CFA",
    textColor: black,
  },
  {
    backgroundColor: "#73B8FD",
    textColor: black,
  },
  {
    backgroundColor: "#5CC2B4",
    textColor: black,
  },
  {
    backgroundColor: "#FF4782",
    textColor: black,
  },
  {
    backgroundColor: "#007765",
    textColor: white,
  },
  {
    backgroundColor: "#0052A1",
    textColor: white,
  },
  {
    backgroundColor: "#3831A0",
    textColor: white,
  },
  {
    backgroundColor: "#D60046",
    textColor: white,
  },
];

export const stripeAccountStatusLabelColors = {
  COMPLETE: {
    backgroundColor: tintGreen,
    color: successGreen,
    label: "Completed",
    iconProps: { color: successGreen },
  },
  RESTRICTED: {
    backgroundColor: tintRed,
    color: errorRed,
    label: "Restricted",
    iconProps: { color: errorRed },
  },
  RESTRICTED_SOON: {
    backgroundColor: tintOrange,
    color: orange,
    label: "Restricted Soon",
    iconProps: { color: orange },
  },
  PENDING: {
    backgroundColor: tintYellow,
    color: yellowDark,
    label: "Pending",
    iconProps: { color: yellowDark },
  },
  ENABLED: {
    backgroundColor: moovsBlueSelected,
    color: moovsBlue,
    label: "Enabled",
    iconProps: { color: moovsBlue },
  },
  REJECTED: {
    backgroundColor: grayLight,
    color: black,
    label: "Rejected",
    iconProps: { color: black },
  },
};

// Pick Up Variant Colors
export const pickUpVariantLabelColors: Record<PickUpVariant, RoundChipProps> = {
  [PickUpVariant.MeetGreet]: {
    label: "MEET & GREET",
    color: purple,
    backgroundColor: tintPurple,
  },
  [PickUpVariant.Curbside]: {
    label: "CURBSIDE",
    color: black,
    backgroundColor: grayLight,
  },
};

// Colored Label Color Combos
export const blueLabelBackground = moovsBlueSelected;
export const blueLabelText = moovsBlue;
export const purpleLabelBackground = tintPurple;
export const purpleLabelText = purple;
export const greenLabelBackground = tintGreen;
export const greenLabelText = successGreen;
export const redLabelBackground = tintPink;
export const redLabelText = errorRed;
export const orangeLabelText = orange;
export const orangeLabelBackground = tintOrange;
