/**
 * @file VehicleFormSection.tsx
 * Title Component for Vehicle Form with valid indicator
 *
 * components:
 *  VehicleFormTitle
 */

import React from "react";

import { Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import MoovsTooltip from "../MoovsTooltip";

type VehicleFormSectionProps = {
  title: string;
  valid?: boolean;
  children?: any;
  tooltipText?: string;
  subTitle?: string;
};

function VehicleFormSection(props: VehicleFormSectionProps) {
  const { title, valid, children, tooltipText, subTitle } = props;

  return (
    <Box mt={2.5}>
      <Box display="flex" flexDirection="column" mt={2} mb={2}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="h3" style={{ marginRight: 10 }}>
            {title}
          </Typography>
          {tooltipText && (
            <Box mr={1}>
              <MoovsTooltip tooltipText={tooltipText} />
            </Box>
          )}
          {valid && <CheckCircleIcon color="primary" />}
        </Box>
        {subTitle && (
          <Box>
            <Typography variant="caption">{subTitle}</Typography>
          </Box>
        )}
      </Box>

      <Box display="flex" flexDirection="column" mt={2} mb={3}>
        {children}
      </Box>
    </Box>
  );
}

export default VehicleFormSection;
