import { useMutation } from "@apollo/client";

import LabeledSwitchInput from "../LabeledSwitchInput";
import { useOperator, useSnackbar } from "globals/hooks";

import { UPDATE_OPERATOR_SETTINGS } from "globals/graphql";
import MoovsTooltip from "components/MoovsTooltip";

type AddPassengerToCalendarInviteBlockProps = {
  setSaveIndicatorState: (value: string) => void;
};

function AddPassengerToCalendarInviteBlock(
  props: AddPassengerToCalendarInviteBlockProps
) {
  const { setSaveIndicatorState } = props;

  const snackbar = useSnackbar();
  const {
    settings: {
      calendarTripInvitePassengerEnabled,
      isCalendarIntegrationEnabled,
    },
  } = useOperator();

  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error(
        "Error updating setting for adding passenger to calendar trip invites"
      );
    },
  });

  const handleAddPassengerToCalendarTripInvite = () => {
    setSaveIndicatorState("loading");
    updateOperatorSettings({
      variables: {
        input: {
          calendarTripInvitePassengerEnabled:
            !calendarTripInvitePassengerEnabled,
        },
      },
    });
  };

  return (
    <MoovsTooltip
      hidden={isCalendarIntegrationEnabled}
      tooltipText={
        "This feature requires Google Calendar to be authorized from the Moovs Market. Check back a few minutes after the installation"
      }
      followCursor={true}
    >
      <LabeledSwitchInput
        name="addPassengerToCalendarInvite"
        checked={
          isCalendarIntegrationEnabled && calendarTripInvitePassengerEnabled
        }
        onChange={handleAddPassengerToCalendarTripInvite}
        disabled={!isCalendarIntegrationEnabled}
        text="Add Passengers to trip calendar invites"
        subText="If your Google Calendar is authorized via Moovs Market, passengers will be automatically added to trip calendar invites."
      />
    </MoovsTooltip>
  );
}

export default AddPassengerToCalendarInviteBlock;
