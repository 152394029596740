import React from "react";

type VehicleTypeVanIconProps = {
  color?: string;
};

const VehicleTypeVanIcon = (props: VehicleTypeVanIconProps) => {
  const { color } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 6H16.532C16.8092 6 17.0728 6.11369 17.261 6.31727C17.9818 7.09689 20.5 11 20.5 11M14.5 6H6M14.5 6V10.5M6 6H2L1 10V17H5M6 6V10.5M20.5 11H1.5M20.5 11L23 12V17H21.5M5 17C5 18.1046 5.89543 19 7 19C8.10457 19 9 18.1046 9 17M5 17C5 15.8954 5.89543 15 7 15C8.10457 15 9 15.8954 9 17M21.5 17C21.5 18.1046 20.6046 19 19.5 19C18.3954 19 17.5 18.1046 17.5 17M21.5 17C21.5 15.8954 20.6046 15 19.5 15C18.3954 15 17.5 15.8954 17.5 17M9 17H17.5"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VehicleTypeVanIcon;
