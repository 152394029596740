import React, { lazy, useMemo } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Appearance } from "@stripe/stripe-js";

// providers
import { ChatProvider } from "globals/hooks/useChat";
import FourZeroFourPage from "../pages/404Page";
import OperatorRouter from "./Router";
import { useOperator } from "globals/hooks";
import {
  black,
  errorRed,
  grayDark,
  moovsBlue,
  white,
} from "design-system/colors";

const isDevelopment = process.env.NODE_ENV === "development";

// lazy loading
const PlaygroundPage = lazy(() => import("../playground/PlaygroundPage"));
const SandboxPage = lazy(() => import("../playground/sandbox/SandboxPage"));

// pubnub
const pubnub = new PubNub({
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
  uuid: null,
});

// stripe instances
const stripePromiseForCustomer = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
);
const stripePromiseForOperatorSubscription = loadStripe(
  process.env.REACT_APP_SUBSCRIPTION_STRIPE_PUBLISHABLE_KEY
);

const StripeAddressElementAppearance: Appearance = {
  theme: "stripe",

  variables: {
    colorBackground: white,
    colorText: black,
    colorDanger: errorRed,
    fontSizeBase: "14px",
    fontFamily: "Poppins, system-ui, sans-serif",
    spacingUnit: "6px",
    colorTextPlaceholder: grayDark,
    colorPrimary: moovsBlue,
    colorTextSecondary: grayDark,
  },
};

function OuterRouter() {
  const { id: operatorId } = useOperator() || {};
  pubnub.setUUID(operatorId);

  // hooks
  const location = useLocation();

  const isSubscriptionPage = location.pathname.includes("settings/billing");

  const stripe = useMemo(() => {
    if (isSubscriptionPage) {
      return { key: "operator", promise: stripePromiseForOperatorSubscription };
    }

    return { key: "customer", promise: stripePromiseForCustomer };
  }, [isSubscriptionPage]);

  return (
    <PubNubProvider client={pubnub}>
      <ChatProvider>
        <Elements
          stripe={stripe.promise}
          key={stripe.key}
          options={{ appearance: StripeAddressElementAppearance }}
        >
          <Switch>
            <Route path="/" component={OperatorRouter} />
            <Route path="*" children={<FourZeroFourPage />} />
            {isDevelopment && (
              <Route path="/playground/sandbox" component={SandboxPage} />
            )}
            {isDevelopment && (
              <Route path="/playground" component={PlaygroundPage} />
            )}
          </Switch>
        </Elements>
      </ChatProvider>
    </PubNubProvider>
  );
}

export default OuterRouter;
