import React from "react";
import { useQuery } from "@apollo/client";

import { Box, CircularProgress } from "@mui/material";

import { LOAD_EMAIL_LOGS_QUERY } from "globals/graphql";
import MoovsDialog from "../../MoovsDialog";
import ActivityLogEmailTab from "./components/Email/ActivityLogEmailTab";

type EmailActivityLogDialogProps = {
  open: boolean;
  onClose: () => void;
  email: string;
  source: string;
};

function EmailActivityLogDialog(props: EmailActivityLogDialogProps) {
  const { open, onClose, email, source } = props;

  // queries
  const {
    data: emailLogsData,
    loading: emailLogsDataLoading,
    fetchMore: emailLogsDataFetchMore,
  } = useQuery(LOAD_EMAIL_LOGS_QUERY, {
    variables: {
      email,
      limit: 20,
    },
    skip: !email,
    fetchPolicy: "network-only",
  });

  // event handlers
  const handleFetchMore = () => {
    emailLogsDataFetchMore({
      variables: {
        cursor: emailLogsData?.loadEmailLogs.pageInfo.endCursor,
      },
    });
  };

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      dialogTitle={`Activity Log for ${email}`}
      scrollableId="emailLogsActivityLog"
    >
      {emailLogsDataLoading ? (
        <Box
          display="flex"
          flex="1"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <ActivityLogEmailTab
          paginatedEmailLogsData={emailLogsData?.loadEmailLogs}
          loading={emailLogsDataLoading}
          fetchMore={handleFetchMore}
          source={source}
        />
      )}
    </MoovsDialog>
  );
}

export default EmailActivityLogDialog;
