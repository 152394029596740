import React from "react";

import { Box } from "@mui/material";

import { Operator } from "types";
import {
  MoovsPaymentsBlock,
  PayoutsBlock,
} from "components/settings/general/Payouts/components";

type PaymentsTabProps = {
  operator: Operator;
};

function PaymentsTab(props: PaymentsTabProps) {
  const { operator } = props;

  return (
    <>
      {/* Merchant Processor block */}
      <Box mb={4}>
        <MoovsPaymentsBlock hasStripeAccount={!!operator.stripeAccountId} />
      </Box>

      {/* payouts block */}
      <Box mb={4}>
        <PayoutsBlock hasStripeAccount={!!operator.stripeAccountId} />
      </Box>
    </>
  );
}

export default PaymentsTab;
