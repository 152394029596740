/**
 * @file graphql/stop.ts
 * queries/mutations for Stop.
 *
 */

import { gql } from "@apollo/client";

export const CREATE_STOP_MUTATION = gql`
  mutation CreateStop($input: CreateStopInput!) {
    createStop(input: $input) {
      stop {
        id
        location
        locationAlias
        dateTime
      }
    }
  }
`;

export const UPDATE_STOP_MUTATION = gql`
  mutation UpdateStop($input: UpdateStopInput!) {
    updateStop(input: $input) {
      stop {
        id
        createdAt
        updatedAt
        stopIndex
        variant
        dateTime
        location
        locationAlias
        groupSize
        note
        operatorNote
        driverNote
        groupSize
        logisticStreetViewUrl
        flightNumber
        airport {
          icaoCode
          iataCode
          airportName
          countryName
          countryIso2
          timezone
          gmt
          coordinates
        }
        airline {
          icaoCode
          iataCode
          airlineName
          callsign
          fleetSize
          countryName
          countryIso2
          dateFounded
        }
        trackedFlight {
          id
          faFlightId
          updatedAt
          airline {
            icaoCode
            airlineName
            iataCode
          }
          actualAirline {
            airlineName
            iataCode
            icaoCode
          }
          flightStatus
          flightNumber
          actualFlightNumber
          origin {
            id
            airport {
              airportName
              iataCode
              icaoCode
              countryName
              countryIso2
              timezone
              gmt
            }
            scheduledGate
            scheduledDateTime
            scheduledTerminal
            actualGate
            actualDateTime
            estimatedDateTime
            actualTerminal
          }
          destination {
            id
            airport {
              airportName
              iataCode
              icaoCode
              countryName
              countryIso2
              timezone
              gmt
            }
            scheduledGate
            scheduledDateTime
            scheduledTerminal
            actualGate
            estimatedDateTime
            actualDateTime
            actualTerminal
          }
        }
      }
    }
  }
`;

export const REMOVE_STOP_MUTATION = gql`
  mutation RemoveStop($input: RemoveStopInput!) {
    removeStop(input: $input) {
      stop {
        id
      }
    }
  }
`;

export const UPDATE_STOP_ORDER_MUTATION = gql`
  mutation UpdateStopOrder($input: UpdateStopOrderInput!) {
    updateStopOrder(input: $input) {
      stops {
        id
        createdAt
        updatedAt
        stopIndex
        variant
        dateTime
        location
        locationAlias
        groupSize
        note
        operatorNote
        driverNote
        groupSize
        logisticStreetViewUrl
        flightNumber
        airport {
          icaoCode
          iataCode
          airportName
          countryName
          countryIso2
          timezone
          gmt
        }
        airline {
          icaoCode
          iataCode
          airlineName
          callsign
          fleetSize
          countryName
          countryIso2
          dateFounded
        }
      }
    }
  }
`;
