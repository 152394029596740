import React, { ChangeEvent } from "react";
import startCase from "lodash/startCase";

import {
  Box,
  Divider,
  FormControlLabel,
  MenuItem,
  Switch,
  Typography,
  TextField,
} from "@mui/material";

import { Refund } from "../../../types";
import { NumberFormatNatural } from "../../../design-system/components/inputs/NumberFormat";

const periodNames = [
  { name: "Weeks", value: "WEEK" },
  { name: "Days", value: "DAY" },
  { name: "Hours", value: "HOUR" },
];

type RefundCreateRowProps = {
  label: string;
  refund: Refund;
  onChange: (key: string, value: number | string | boolean) => void;
  periodNameError: string;
  periodValueError: string;
};

function RefundCreateRow(props: RefundCreateRowProps) {
  const { label, refund, onChange, periodNameError, periodValueError } = props;

  const handleRefundChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value: number | string | boolean = event.target.value;

    if (event.target.name === "periodValue") {
      value = Number(value);
    }
    if (event.target.name === "active") {
      value = event.target.checked;
    }

    onChange(event.target.name, value);
  };

  const formattedRefundPlaceholder = refund.periodName
    ? `${startCase(refund.periodName)}s`
    : "Quantity";

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body2">{label}</Typography>
        <FormControlLabel
          labelPlacement="start"
          control={
            <Switch
              checked={refund.active}
              onChange={handleRefundChange}
              name="active"
              color="primary"
            />
          }
          label={refund.active ? "Active" : "Inactive"}
        />
      </Box>
      <Box display="flex" flexDirection="row" mt={1}>
        {refund.active && (
          <>
            <Box flex="1" display="flex" mr={1.5}>
              <TextField
                fullWidth
                label={formattedRefundPlaceholder}
                variant="outlined"
                onChange={handleRefundChange}
                name="periodValue"
                value={refund.periodValue || ""}
                error={!!periodValueError}
                helperText={periodValueError}
                InputProps={{
                  inputComponent: NumberFormatNatural as any,
                }}
              />
            </Box>
            <Box flex="1" display="flex">
              <TextField
                fullWidth
                select
                label="Time"
                variant="outlined"
                name="periodName"
                onChange={handleRefundChange}
                value={refund.periodName || ""}
                error={!!periodNameError}
                helperText={periodNameError}
              >
                <MenuItem key="-" value={null}>
                  -
                </MenuItem>
                {periodNames.map(({ name, value }) => (
                  <MenuItem key={value} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </>
        )}
      </Box>
      <Box my={3}>
        <Divider />
      </Box>
    </>
  );
}

export default RefundCreateRow;
