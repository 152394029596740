import React from "react";
import capitalize from "lodash/capitalize";

import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

function BookingContactColumn(props: GridRenderCellParams<string>) {
  const { row: trip } = props;
  const { contact } = trip;

  const fullName = contact
    ? `${capitalize(contact.firstName)} ${capitalize(contact.lastName)}`
    : "";

  return (
    <Box>
      <Typography
        variant="body2"
        textOverflow="ellipsis"
        overflow="hidden"
        pl={1}
      >
        {fullName}
      </Typography>
    </Box>
  );
}

export default BookingContactColumn;
