import { useQuery } from "@apollo/client";
import partition from "lodash/partition";
import map from "lodash/map";
import size from "lodash/size";
import uniq from "lodash/uniq";

import { LOAD_REQUEST_TRANSACTIONS_QUERY } from "globals/graphql";
import { MoovsTransaction, Payment } from "types";

type useLoadRequestTransactionsProps = {
  requestId: string;
};

function useLoadRequestTransactions(props: useLoadRequestTransactionsProps) {
  // props
  const { requestId } = props;

  // queries
  const {
    data,
    loading: transactionsLoading,
    refetch: refetchTransactions,
  } = useQuery(LOAD_REQUEST_TRANSACTIONS_QUERY, {
    variables: { requestId },
    skip: !requestId,
    fetchPolicy: "network-only",
  });

  const [invoiceTransactions, nonInvoiceTransactions]: [
    Payment[],
    MoovsTransaction[]
  ] = partition(data?.loadTransactionsForRequest, "invoice");

  const uniqueInvoiceIds = uniq(
    map(
      invoiceTransactions,
      (invoiceTransaction) => invoiceTransaction.invoice.id
    )
  );

  return {
    transactions: nonInvoiceTransactions,
    transactionsLoading,
    hasInvoicePaymentsPaid: !!size(uniqueInvoiceIds),
    invoiceIds: uniqueInvoiceIds,
    refetchTransactions,
  };
}

export { useLoadRequestTransactions };
