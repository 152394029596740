/**
 * @file graphql/farmAffiliates/farmPricing.graphql.ts
 * queries/mutations for farm pricing.
 *
 */

import { gql } from "@apollo/client";

export const UPDATE_FARMED_ROUTE_PRICING_MUTATION = gql`
  mutation UpdateFarmedRoutePricing($input: UpdateFarmedRoutePricingInput!) {
    updateFarmedRoutePricing(input: $input) {
      route {
        id
        pricing {
          id
          baseRateAmt
          hourlyBaseRate
          hourlyBaseRateHours
          driverGratuityAmt
          driverGratuityIsPct
          driverGratuityPercent
          promoDiscountAmt
          promoDiscountIsPct
          promoDiscountPercent
          taxAmt
          taxIsPct
          taxPercent
          tollsAmt
          otherName
          otherAmt
          otherIsPct
          otherPercent
          other2Name
          other2Amt
          other2IsPct
          other2Percent
          other3Name
          other3Amt
          other3IsPct
          other3Percent
          promoCodeId
          promoCodeName
          promoCodeAmt
          promoCodePercent
          forwardFacingSeatQuantity
          forwardFacingSeatAmt
          rearFacingSeatQuantity
          rearFacingSeatAmt
          boosterSeatQuantity
          boosterSeatAmt
          totalAmt
          amountPaid
          paymentStatus
        }
        farmeePricing {
          id
          baseRateAmt
          hourlyBaseRate
          hourlyBaseRateHours
          driverGratuityAmt
          driverGratuityIsPct
          driverGratuityPercent
          promoDiscountAmt
          promoDiscountIsPct
          promoDiscountPercent
          taxAmt
          taxIsPct
          taxPercent
          tollsAmt
          otherName
          otherAmt
          otherIsPct
          otherPercent
          other2Name
          other2Amt
          other2IsPct
          other2Percent
          other3Name
          other3Amt
          other3IsPct
          other3Percent
          promoCodeId
          promoCodeName
          promoCodeAmt
          promoCodePercent
          forwardFacingSeatQuantity
          forwardFacingSeatAmt
          rearFacingSeatQuantity
          rearFacingSeatAmt
          boosterSeatQuantity
          boosterSeatAmt
          totalAmt
          amountPaid
          paymentStatus
        }
      }
    }
  }
`;
