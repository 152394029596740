import React from "react";
import { Helmet } from "react-helmet";
import { FormProvider } from "react-hook-form";

import DispatchSchedulerPage from "pages/dispatch/DispatchSchedulerPage";
import { useLocalStorage } from "../../globals/hooks";
import TripListPage from "./DispatchTripsPage";
import useDispatchFilterForm from "./hooks/useDispatchFilterForm";

function DispatchPage() {
  // state
  const [pageView, setPageView] = useLocalStorage("TripViewPageView", "list");

  // hooks
  const { methods } = useDispatchFilterForm();

  // event handlers
  const handlePageViewClick = (_, newView: "list" | "scheduler") => {
    if (newView?.length) setPageView(newView);
  };

  const Page = {
    list: <TripListPage onPageViewClick={handlePageViewClick} />,
    scheduler: <DispatchSchedulerPage onPageViewClick={handlePageViewClick} />,
  }[pageView];

  return (
    <FormProvider {...methods}>
      <Helmet>
        <title>Dispatch | Moovs</title>
      </Helmet>
      {Page}
    </FormProvider>
  );
}

export default DispatchPage;
