import React from "react";
import { useQuery } from "@apollo/client";
import { useFormContext } from "react-hook-form";

import { Box, Typography } from "@mui/material";

import MoovsSearchableCheckboxDropdown from "components/MoovsSearchableCheckboxDropdown";
import { LOAD_FARM_AFFILIATES_NAME_AND_ID_QUERY } from "globals/graphql";
import { FarmAffiliateVariantEnum } from "types";
import { FilterProps } from "../FilterDispatchTripsDrawer";

function AffiliateFilter(props: FilterProps) {
  const { disabledSelect } = props;

  // hooks
  const { watch, setValue } = useFormContext();

  // queries
  const { data } = useQuery(LOAD_FARM_AFFILIATES_NAME_AND_ID_QUERY, {
    variables: {
      viewFilter: Object.values(FarmAffiliateVariantEnum),
    },
  });

  const affiliateOptions =
    data?.loadFarmAffiliates.edges.map(({ node: affiliate }) => ({
      name: affiliate.operatorName,
      id: affiliate.id,
    })) || [];

  return (
    <Box mb={2}>
      <Typography variant="h3" mb={2}>
        Affiliate
      </Typography>

      <MoovsSearchableCheckboxDropdown
        fieldName="Affiliate"
        options={affiliateOptions}
        selection={watch("affiliateSelection")}
        setSelection={(newValue) => setValue("affiliateSelection", newValue)}
        disabled={disabledSelect}
        tooltipText="Upgrade plan to Pro in order to use filtering feature"
      />
    </Box>
  );
}

export default AffiliateFilter;
