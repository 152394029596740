import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import pick from "lodash/pick";

import { Typography, Box } from "@mui/material";

import { granite } from "design-system/colors";
import {
  LabeledInlineInput,
  LabeledInlinePhoneNumberInput,
  LabeledInlineLocationAutocomplete,
} from "design-system/components/inputs";
import { Company, UpdateCompanyInput } from "types";
import { UPDATE_COMPANY_MUTATION } from "globals/graphql";
import { useAutoSave } from "globals/hooks";
import { ImageUploadBlock } from "components/ImageUploadBlock";

type UpdateCompanyFormProps = {
  company: Company;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function UpdateCompanyForm(props: UpdateCompanyFormProps) {
  const { setSaveIndicatorState } = props;

  const [company, setCompany] = useAutoSave<UpdateCompanyInput>({
    setSaveIndicatorState,
    mutation: UPDATE_COMPANY_MUTATION,
    name: "company",
    incomingState: {
      ...pick(props.company, [
        "id",
        "name",
        "description",
        "websiteUrl",
        "phoneNumber",
        "state",
        "address",
        "postalCode",
        "timeZone",
        "email",
        "companyLogoUrl",
      ]),
    },
  });

  // event handlers
  const handleCompanyInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCompany({
      ...company,
      [event.target.name]: event.target.value,
    });
  };

  const handleCompanyAddressChange = (value) => {
    setCompany({
      ...company,
      address: value.description,
    });
  };

  const handleCompanyLogoUrlChange = (companyLogoUrl: string) => {
    setCompany({
      ...company,
      companyLogoUrl,
    });
  };

  return (
    <>
      <Box my={3} display="flex" flexDirection="column" alignItems="center">
        <ImageUploadBlock
          variant="company"
          placeholder={company?.name}
          photoUrl={company?.companyLogoUrl}
          onPhotoUrlUpdate={handleCompanyLogoUrlChange}
          mode="update"
          shape="rectangle"
          CropperProps={{
            cropShape: "rect",
            aspect: 5 / 2,
          }}
        />
        <Typography mt={2} variant="h2">
          {company?.name || ""}
        </Typography>
        <Typography variant="body2" sx={{ color: granite }}>
          {company?.address || ""}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <LabeledInlineInput
          label="Name"
          value={company ? company.name : ""}
          name="name"
          onChange={handleCompanyInputChange}
        />
        <LabeledInlineInput
          label="Description"
          value={company ? company.description : ""}
          name="description"
          onChange={handleCompanyInputChange}
        />

        <LabeledInlinePhoneNumberInput
          label="Phone"
          value={company ? company.phoneNumber : ""}
          name="phoneNumber"
          onChange={handleCompanyInputChange}
        />
        <LabeledInlineInput
          label="Email"
          value={company ? company.email : ""}
          name="email"
          onChange={handleCompanyInputChange}
        />
        <LabeledInlineLocationAutocomplete
          label="Work Address"
          value={company ? company.address : ""}
          onChange={handleCompanyAddressChange}
        />
        <LabeledInlineInput
          label="Website"
          value={company ? company.websiteUrl : ""}
          name="websiteUrl"
          onChange={handleCompanyInputChange}
        />
      </Box>
    </>
  );
}

export default UpdateCompanyForm;
