import React from "react";
import { useQuery } from "@apollo/client";
import { Controller } from "react-hook-form";

import { Box, MenuItem, SelectChangeEvent, Typography } from "@mui/material";

import VehiclePhotos from "components/vehicles/VehiclePhotos/VehiclePhotos";
import {
  LabeledInlineInput,
  LabeledInlineSelect,
} from "design-system/components/inputs";
import {
  getVehicleTypeNameByTypeSlug,
  vehicleIconByIconCategory,
} from "globals/utils/vehicleTypes";
import theme from "theme";
import { VehiclePhoto, VehicleType } from "types";
import { LOAD_VEHICLE_TYPES_QUERY } from "globals/graphql";
import VehicleFormSection from "components/vehicles/VehicleFormSection";
import { useUpdateVehicleFormContext } from "../../hooks/useUpdateVehicleForm";

type DetailsProps = {
  photos: VehiclePhoto[];
  handleVehiclePhotosChange: (newPhotos: VehiclePhoto[]) => void;
  handleResetPhotos: () => void;
  setSaveIndicatorState: React.Dispatch<
    React.SetStateAction<"default" | "loading" | "saved" | "error">
  >;
};

function Details(props: DetailsProps) {
  const {
    handleVehiclePhotosChange,
    handleResetPhotos,
    photos,
    setSaveIndicatorState,
  } = props;

  // query
  const { data: vehicleTypesData } = useQuery(LOAD_VEHICLE_TYPES_QUERY);

  const vehicleTypes = vehicleTypesData ? vehicleTypesData.vehicleTypes : [];
  const ctx = useUpdateVehicleFormContext();

  if (!ctx) {
    console.error(
      "useUpdateVehicleFormContext must be used within an UpdateVehicleFormProvider"
    );
    return (
      <Box mt={4}>
        <Typography variant="body2" color="error">
          Error loading vehicle details. Please refresh the page.
        </Typography>
      </Box>
    );
  }

  const { control, getValues } = ctx;

  // derived state
  const vehicleId = getValues("vehicle.id");

  return (
    <Box mt={4}>
      {/* Basic Info */}
      <VehicleFormSection title="Basic Info">
        <Controller
          control={control}
          name="vehicle.name"
          render={({
            field: { name, value, onChange },
            fieldState: { error },
          }) => (
            <LabeledInlineInput
              required
              label="Name"
              name={name}
              value={value}
              onChange={onChange}
              error={!!error}
              errorText={error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="vehicle.typeSlug"
          render={({
            field: { name, value, onChange },
            fieldState: { error },
          }) => {
            // handler
            const handleOnChange = (event: SelectChangeEvent) =>
              onChange(event.target.value);

            return (
              <LabeledInlineSelect
                name={name}
                label="Type"
                value={value}
                onChange={handleOnChange}
                renderValue={(value) =>
                  getVehicleTypeNameByTypeSlug(value, vehicleTypes)
                }
                required={!!error}
                errorText={error?.message}
              >
                {[
                  {
                    typeName: "Select Vehicle Type",
                    typeSlug: "",
                    iconCategory: "",
                  },
                  ...vehicleTypes,
                ].map(({ typeName, typeSlug, iconCategory }: VehicleType) => (
                  <MenuItem
                    key={typeSlug}
                    value={typeSlug}
                    sx={{
                      padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
                    }}
                  >
                    <Box mr={1.5} position="relative" top="2px">
                      {iconCategory &&
                        vehicleIconByIconCategory()[iconCategory]}
                    </Box>
                    {typeName}
                  </MenuItem>
                ))}
              </LabeledInlineSelect>
            );
          }}
        />
        <Controller
          control={control}
          name="vehicle.capacity"
          render={({
            field: { name, value, onChange },
            fieldState: { error },
          }) => (
            <LabeledInlineInput
              name={name}
              value={value}
              onChange={onChange}
              label="Passenger Capacity"
              inputProps={{
                type: "number",
              }}
              error={!!error}
              errorText={error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="vehicle.licensePlate"
          render={({
            field: { name, value, onChange },
            fieldState: { error },
          }) => (
            <LabeledInlineInput
              name={name}
              value={value}
              onChange={onChange}
              label="License Plate"
              inputProps={{ maxLength: 8 }}
              error={!!error}
              errorText={error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="vehicle.description"
          render={({ field: { name, value, onChange } }) => (
            <LabeledInlineInput
              name={name}
              value={value}
              multiline
              label="Description"
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="vehicle.exteriorColor"
          render={({ field: { name, value, onChange } }) => (
            <LabeledInlineInput
              name={name}
              value={value}
              onChange={onChange}
              label="Exterior Color"
            />
          )}
        />
        <Controller
          control={control}
          name="vehicle.vinNumber"
          render={({ field: { name, value, onChange } }) => (
            <LabeledInlineInput
              name={name}
              value={value}
              onChange={onChange}
              label="VIN Number"
            />
          )}
        />
      </VehicleFormSection>

      {/* Photos */}
      <VehicleFormSection
        title="Photos"
        subTitle=".jpg and .png files"
        tooltipText="Drag to reorder photos. The first photo is your featured image"
      >
        <VehiclePhotos
          mode="update"
          photos={photos}
          vehicleId={vehicleId}
          onVehiclePhotosChange={handleVehiclePhotosChange}
          setSaveIndicatorState={setSaveIndicatorState}
          onResetPhotos={handleResetPhotos}
        />
      </VehicleFormSection>
    </Box>
  );
}

export default Details;
