/**
 * @file SmallSelect.tsx
 * Swoop-specific small select.
 *
 * components:
 *  Small Select
 *
 * author: sienag
 */

import React from "react";

import { TextField, TextFieldProps, OutlinedInputProps } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { black, grayMedium, alabaster, white } from "../../../colors";

const inputSx = {
  border: "1px solid transparent",
  borderRadius: "4px",
  background: white,
  overflow: "hidden",
  "& svg": {
    marginRight: "4px",
    fill: "transparent",
  },
  ":hover": {
    background: "white",
    borderColor: grayMedium,
    "& svg": {
      fill: grayMedium,
    },
  },
  "&.Mui-focused *": {
    backgroundColor: alabaster + "!important",
    "& .MuiInputBase-root.MuiFilledInput-root": {
      borderColor: black,
    },
  },
};

const inputLabelSx = {
  whiteSpace: "nowrap",
  "&.Mui-focused": {
    "&.MuiFormLabel-root.MuiInputLabel-root": {
      color: black,
    },
  },
} as const;

function SmallSelect(props: TextFieldProps) {
  return (
    <TextField
      select
      fullWidth
      variant="filled"
      sx={{
        "& .MuiInputBase-root.MuiFilledInput-root": {
          "&.Mui-focused": {
            backgroundColor: alabaster + "!important",
            borderColor: black,
            "& svg": {
              fill: black + "!important",
            },
          },
        },
      }}
      SelectProps={{
        IconComponent: () => <ExpandMoreIcon />,
      }}
      InputProps={
        {
          sx: inputSx,
          disableUnderline: true,
        } as Partial<OutlinedInputProps>
      }
      InputLabelProps={{
        sx: inputLabelSx,
      }}
      {...props}
    />
  );
}

export { SmallSelect };
