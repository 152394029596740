/**
 * @file MoovsDialog.tsx
 * Called moovs differentiate from our more commonly used MUI Dialog.
 * Be very cautious changing CSS here. Needs to be tested on variety of devices and browsers.
 *
 * components: MoovsDialog
 */
import React, { ReactNode } from "react";

import {
  Typography,
  Dialog,
  Box,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
  SxProps,
} from "@mui/material";

import { CrossIcon, InfoIcon } from "design-system/icons";
import { white, grayLight, granite } from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import { DraggableComponent } from "./DraggableComponent";

const buttonProgressStyles = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: "auto",
} as const;

type MoovsDialogProps = {
  scrollableId?: string; // used with MoovsInfiniteScroll
  open: boolean;
  onClose: () => void;
  dialogTitle: string;
  children?: ReactNode;
  onAccept?: () => void;
  onSecondaryAccept?: () => void;
  acceptButtonText?: string;
  secondaryAcceptButtonText?: string;
  closeButtonText?: string;
  hideTopBorder?: boolean;
  hideBottomBorder?: boolean;
  fixedFooter?: boolean;
  size?: false | "xs" | "sm" | "md" | "lg" | "xl";
  titleToolTip?: string;
  acceptDisabled?: boolean;
  hideLoadingIndicator?: boolean;
  removeCloseButton?: boolean;
  subHeaderComponent?: ReactNode;
  acceptButtonVariant?: "contained" | "outlined" | "text";
  secondaryAcceptButtonVariant?: "contained" | "outlined" | "text";
  acceptButtonIsDelete?: boolean;
  acceptEndIcon?: JSX.Element;
  acceptStartIcon?: JSX.Element;
  xsFullscreenMobile?: boolean;
  autoFocusOnAccept?: boolean;
  onXClick?: () => void; // invokes onXClick handler for CrossIcon, and closeButton continues using onClose function
  narrowSidePaddingXDesktop?: boolean;
  tabs?: JSX.Element;
  customFooter?: React.ReactNode;
  sx?: SxProps;
  childrenSx?: SxProps;
};

function MoovsDialog(props: MoovsDialogProps) {
  const {
    scrollableId,
    open,
    onClose,
    children,
    dialogTitle,
    onAccept,
    onSecondaryAccept,
    acceptButtonText,
    secondaryAcceptButtonText,
    closeButtonText,
    hideTopBorder,
    hideBottomBorder,
    fixedFooter,
    size = "md",
    titleToolTip,
    acceptDisabled,
    hideLoadingIndicator,
    subHeaderComponent,
    removeCloseButton,
    acceptButtonVariant,
    secondaryAcceptButtonVariant,
    acceptButtonIsDelete,
    acceptEndIcon,
    acceptStartIcon,
    narrowSidePaddingXDesktop,
    xsFullscreenMobile,
    autoFocusOnAccept,
    onXClick,
    tabs,
    customFooter,
    sx,
    childrenSx,
  } = props;

  // hooks
  const { isMobileView, isMediumTabletView } = useScreenSize();

  // derived state
  const isIOS =
    !!global.navigator.userAgent &&
    /iPad|iPhone|iPod/.test(global.navigator.userAgent);
  const fullScreen =
    isMobileView && (size !== "xs" || (size === "xs" && xsFullscreenMobile));

  return (
    <Dialog
      maxWidth={size}
      onClose={onClose}
      open={open}
      fullWidth
      fullScreen={fullScreen}
      aria-labelledby="moovs-dialog-title"
      // draggable causes issues on iOS/mobile due to touch events
      // and there is no need for it on smaller screens
      {...(!isIOS &&
        !isMediumTabletView && { PaperComponent: DraggableComponent })}
    >
      <Box
        display="flex"
        flex="1 0 auto"
        flexDirection="column"
        maxHeight={fullScreen ? "100vh" : "85vh"}
        sx={sx}
        overflow="hidden"
      >
        <Box
          display="flex"
          flexDirection="row"
          px={isMobileView ? 2 : narrowSidePaddingXDesktop ? 3 : 4}
          pt={isMobileView ? 1 : 2}
          pb={tabs ? 0 : isMobileView ? 1 : 2}
          justifyContent="space-between"
          alignItems="center"
          bgcolor={white}
          borderBottom={hideTopBorder ? "none" : `1px solid ${grayLight}`}
          zIndex={10}
          id="moovs-dialog-title"
        >
          <Box display="flex" flex="1" flexDirection="column">
            <Box display="flex">
              <Box display="flex" flex="1" alignItems="center">
                <Typography variant="h3">{dialogTitle}</Typography>

                {titleToolTip && (
                  <Tooltip
                    enterDelay={200}
                    enterNextDelay={200}
                    title={titleToolTip}
                    placement="top"
                  >
                    <IconButton aria-label="info" size="large">
                      <InfoIcon size="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>

              <IconButton
                onClick={onXClick || onClose}
                style={{ marginRight: "-12px" }}
                size="large"
              >
                <CrossIcon color={granite} />
              </IconButton>
            </Box>
            {subHeaderComponent}
            {tabs}
          </Box>
        </Box>
        {/* WARNING, change this CSS at your own risk. Be sure to test on actual mobile device*/}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flex={1}
          style={{ cursor: "default" }}
          // requires a height to be set to allow scrolling
          minHeight="1px"
          // forces scrollable on fullScreen. Otherwise footer is pushed to bottom
          {...(isMobileView && fullScreen && { height: "65vh" })}
        >
          <Box
            sx={childrenSx}
            display="flex"
            py={1}
            px={isMobileView ? 2 : narrowSidePaddingXDesktop ? 3 : 4}
            flexDirection="column"
            overflow="auto"
            flex={1}
            id={scrollableId}
          >
            {children}
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            px={isMobileView ? 2 : 4}
            py={2}
            borderTop={hideBottomBorder ? "none" : `1px solid ${grayLight}`}
            bgcolor={white}
            zIndex={1}
            {...(fixedFooter && { sx: { position: "sticky", bottom: "0px" } })}
          >
            {customFooter ? (
              customFooter
            ) : (
              <Box
                display="flex"
                flexDirection={
                  /* using columns on mobile view with a secondary accept */
                  /* because there is likely not space for the buttons to be in a row */
                  isMobileView && onSecondaryAccept ? "column" : "row"
                }
                {...(isMobileView && { flex: 1 })}
              >
                {!removeCloseButton && (
                  <Button color="primary" fullWidth onClick={onClose}>
                    {closeButtonText || "Close"}
                  </Button>
                )}
                {onAccept && (
                  <Box
                    position="relative"
                    ml={removeCloseButton || isMobileView ? 0 : 2}
                    /* mt and mb are for vertical spacing when flex is column due to secondary accept */
                    mt={isMobileView && onSecondaryAccept ? 1 : 0}
                    mb={isMobileView && onSecondaryAccept ? 1 : 0}
                    {...(isMobileView && { width: "100%" })}
                  >
                    <Button
                      color={acceptButtonIsDelete ? "error" : "primary"}
                      sx={{ whiteSpace: "nowrap" }}
                      onClick={onAccept}
                      variant={acceptButtonVariant || "contained"}
                      disabled={acceptDisabled}
                      data-testid="moovs-dialog-accept-button"
                      autoFocus={autoFocusOnAccept}
                      {...(isMobileView && { fullWidth: true })}
                      {...(acceptEndIcon && { endIcon: acceptEndIcon })}
                      {...(acceptStartIcon && { startIcon: acceptStartIcon })}
                    >
                      {acceptButtonText || "Accept"}
                    </Button>
                    {acceptDisabled && !hideLoadingIndicator && (
                      <CircularProgress size={24} sx={buttonProgressStyles} />
                    )}
                  </Box>
                )}
                {onSecondaryAccept && (
                  <Box
                    position="relative"
                    ml={removeCloseButton || isMobileView ? 0 : 2}
                    {...(isMobileView && { width: "100%" })}
                  >
                    <Button
                      color="primary"
                      sx={{ whiteSpace: "nowrap" }}
                      onClick={onSecondaryAccept}
                      variant={secondaryAcceptButtonVariant || "contained"}
                      data-testid="moovs-dialog-accept-button"
                      {...(isMobileView && { fullWidth: true })}
                    >
                      {secondaryAcceptButtonText || "Accept"}
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

export default MoovsDialog;
