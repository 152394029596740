import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useMutation } from "@apollo/client";
import isEqual from "lodash/isEqual";

import { Box } from "@mui/material";

import TripInfoUpdateBlockItem from "./TripInfoUpdateBlockItem";
import CreateStopDialog from "./CreateStopDialog";
import {
  checkIfDropOffTimeIsMissing,
  checkIfStopTimesAreInOrder,
  reorderStops,
} from "../../../globals/utils/helpers";
import { Trip, Stop, TripCategory } from "../../../types";
import { UPDATE_STOP_ORDER_MUTATION } from "../../../globals/graphql";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import AddStopButton from "./AddStopButton";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type TripInfoUpdateBlockProps = {
  trip: Trip;
  setSaveIndicatorState: (
    saveState: "loading" | "default" | "saved" | "error"
  ) => void;
  suggestedAddressInfo?: {
    firstName: string;
    lastName: string;
    address: string;
    mode: string;
  }[];
};

function TripInfoUpdateBlock(props: TripInfoUpdateBlockProps) {
  const { trip, setSaveIndicatorState, suggestedAddressInfo } = props;

  const snackbar = useSnackbar();

  // derived state
  const isShuttlePickUp = trip.tripCategory === TripCategory.ShuttlePickUp;
  const isShuttleTrip =
    isShuttlePickUp || trip.tripCategory === TripCategory.ShuttleReturn;

  // state
  const [stops, setStops] = useState(trip.stops);
  const [createStopDialogOpen, setCreateStopDialogOpen] = useState(false);
  const [reorderedStops, setReorderedStops] = useState<any[]>(null);
  const [draggableIndexes, setDraggableIndexes] = useState<{
    sourceIndex: number;
    destinationIndex: number;
  }>(null);
  const [renderWarningForStop, setRenderWarningForStop] = useState<boolean[]>(
    []
  );
  const [renderWarningForDropOff, setRenderWarningForDropOff] = useState<
    boolean[]
  >([]);

  // mutations
  const [updateStopOrder] = useMutation(UPDATE_STOP_ORDER_MUTATION, {
    refetchQueries: ["Requests", "Trip", "OperatorRoute", "Request"],
    onCompleted(data) {
      setStops(data.updateStopOrder.stops);
      setSaveIndicatorState("saved");
    },
    onError(error) {
      const alternateErrorMessage = error.message
        ? `Error: ${error.message.replace("GraphQL error:", "")}`
        : "Error updating stop";

      const errorMessage = getErrorMessage(error) || alternateErrorMessage;

      snackbar.error(errorMessage);
      setSaveIndicatorState("error");
      setStops(trip.stops);
    },
  });

  // event handlers
  const handleOpenCreateStopDialog = () => setCreateStopDialogOpen(true);
  const handleCreateStopDialogClose = () => setCreateStopDialogOpen(false);

  const handleDragEnd = (e) => {
    const { destination, source } = e;

    if (!destination) {
      return;
    }

    setSaveIndicatorState("loading");

    updateStopOrder({
      variables: {
        input: {
          tripId: trip.id,
          sourceIndex: source.index,
          destinationIndex: destination.index,
        },
      },
    });

    const nextStops = reorderStops(stops, source.index, destination.index);
    setStops(nextStops);

    setDraggableIndexes(null);
    setReorderedStops(null);
  };

  const handleDragUpdate = (e) => {
    const { destination, source } = e;

    if (!destination) {
      setReorderedStops(null);
      setDraggableIndexes(null);
      return;
    }

    const nextStops = reorderStops(stops, source.index, destination.index);

    setReorderedStops(nextStops);
    setDraggableIndexes({
      sourceIndex: source.index,
      destinationIndex: destination.index,
    });
  };

  useEffect(() => {
    if (trip.stops.length !== stops.length) {
      setStops(trip.stops);
    }
  }, [trip.stops, stops.length]);

  // show/hide stop time out of order warning
  useEffect(() => {
    const stopsInOrderArray = checkIfStopTimesAreInOrder(trip.stops);

    if (!isEqual(stopsInOrderArray, renderWarningForStop)) {
      // if in previous render, any stop didn't have a warning and now does, render snackbar
      if (
        renderWarningForStop.some(
          (warning, index) => !warning && !!stopsInOrderArray[index]
        )
      ) {
        snackbar.warning("Date & times are not in order");
      }
      setRenderWarningForStop(stopsInOrderArray);
    }
  }, [setRenderWarningForStop, renderWarningForStop, snackbar, trip]);

  useEffect(() => {
    const stopsInOrderArray = checkIfDropOffTimeIsMissing(trip.stops);

    if (!isEqual(stopsInOrderArray, renderWarningForDropOff)) {
      // if in previous render, any stop didn't have a warning and now does, render snackbar
      if (
        renderWarningForDropOff.some(
          (warning, index) => !warning && !!stopsInOrderArray[index]
        )
      ) {
        snackbar.warning("Dropoff is missing a date & time");
      }

      setRenderWarningForDropOff(stopsInOrderArray);
    }
  }, [setRenderWarningForDropOff, renderWarningForDropOff, snackbar, trip]);

  useEffect(() => {
    setStops(trip.stops);
  }, [trip.stops]);

  return (
    <>
      <DragDropContext
        onDragEnd={handleDragEnd}
        onDragUpdate={handleDragUpdate}
      >
        {stops.map((stop: Stop, index) => {
          const { sourceIndex, destinationIndex } = draggableIndexes || {};
          let nextStop;

          if (
            draggableIndexes &&
            reorderedStops &&
            destinationIndex !== sourceIndex
          ) {
            nextStop = reorderedStops[index];
          }

          return (
            <Box my={1} key={stop.id}>
              <TripInfoUpdateBlockItem
                showIncorrectStopOrderWarning={renderWarningForStop[index]}
                showMissingDropOffDateTimeWarning={
                  renderWarningForDropOff[index]
                }
                stop={stop}
                firstStop={trip.stops[0]}
                stopsLength={trip.stops.length}
                setSaveIndicatorState={setSaveIndicatorState}
                suggestedAddressInfo={suggestedAddressInfo}
                canRemoveStop={
                  stop.stopIndex > 1 && stop.stopIndex < stops.length
                }
                nextStop={nextStop}
                isSourceIndex={sourceIndex === index}
                isDestinationIndex={destinationIndex === index}
                tripCategory={trip.tripCategory}
              />

              {index === trip.stops.length - 2 && (
                <AddStopButton onClick={handleOpenCreateStopDialog} />
              )}
            </Box>
          );
        })}
      </DragDropContext>

      <CreateStopDialog
        tripId={trip.id}
        open={createStopDialogOpen}
        onClose={handleCreateStopDialogClose}
        suggestedAddressInfo={suggestedAddressInfo}
        isShuttlePickUp={isShuttlePickUp}
        isShuttleTrip={isShuttleTrip}
        stopsLength={trip.stops.length}
      />
    </>
  );
}

export default TripInfoUpdateBlock;
