import { useState } from "react";
import { DeepPartial } from "ts-essentials";

import { Contact } from "types";

function useUpdateBookingContactClick() {
  // state
  const [editBookingContactDialogOpen, setEditBookingContactDialogOpen] =
    useState(false);
  const [addContactToOrderDialogOpen, setAddContactToOrderDialogOpen] =
    useState(false);
  const [carryOverSearch, setCarryOverSearch] = useState("");
  const [bookingContact, setBookingContact] =
    useState<DeepPartial<Contact>>(null);

  return {
    editBookingContactDialogOpen: editBookingContactDialogOpen,
    setEditBookingContactDialogOpen,
    addContactToOrderDialogOpen: addContactToOrderDialogOpen,
    setAddContactToOrderDialogOpen,
    bookingContact: bookingContact,
    setBookingContact,
    carryOverSearch: carryOverSearch,
    setCarryOverSearch,
  };
}

export { useUpdateBookingContactClick };
