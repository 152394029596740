/**
 * This is a temporary fix until we adjust Request.trips on server
 * to accommodate round trips holistically.
 *
 * https://swoopapp.atlassian.net/browse/DOOM-4702
 */
import { RequestsEdge } from "types";
import remove from "lodash/remove";
import some from "lodash/some";

/**
 * input: request with trips array where return trips are nested AND are direct elements in the trips list.
 * - farmed outbound trips - will have a nested returnTrip, even if returnTrip is not farmed.
 * - farmed return trips - will be direct elements in trips list. outbound trip will only be in the trips list if the outbound trip is also farmed.
 *
 * output: trips array where return trips are ONLY nested.
 * - farmed return trips will only appear on nested farmed outbound trip if they are both farmed.
 *
 *
 * example 1 - regular round trip
 * - id: 1 = outbound trip
 * - id: 2 = returnTrip
 * input trips: [{id: 1, returnTrip: { id: 2 }}, {id: 2 }]
 * output trips: [{id: 1, returnTrip: {id: 2}}]
 *
 * example 2: round trip where farmee was farmed only the outbound trip
 * input: [{id: 1, returnTrip: { id: 2 }}]
 * output: [{id: 1 }]
 *
 * example 3: round trip where farmee was farmed only the return trip
 * input: [{id: 2}]
 * output: [{id: 2}]
 *
 */

export function convertRequestTripsForReservationPage(
  requestData: RequestsEdge["node"]
) {
  if (!requestData?.trips?.length) return requestData;

  const returnTripsToRemove = [];

  const finalTrips = requestData.trips.map((trip) => {
    const { returnTrip } = trip;
    if (!returnTrip) return trip;

    returnTripsToRemove.push(returnTrip.id);

    // if the return trip is an element in the list of trips, we keep the return trip nested
    if (some(requestData.trips, (trip) => returnTrip.id === trip.id)) {
      return trip;
    }

    // if returnTrip is not an element in the list of trips, then remove the nested trip
    return { ...trip, returnTrip: null };
  });

  // all relevant return trips are now nested on the outbound trip's returnTrip propery
  // irrelevant return trips are now null on the outbound tri's returnTrip propery

  // remove all returnTrips that are direct elements in the trips array
  remove(finalTrips, (trip) =>
    returnTripsToRemove.some((id) => id === trip.id)
  );

  return { ...requestData, trips: finalTrips };
}
