import React from "react";

import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Trip } from "types";
import { EstimationIcon } from "design-system/icons";

function DurationColumn(props: GridRenderCellParams<string>) {
  const { value, row } = props;

  const { useTotalDuration } = row as Trip;

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {!useTotalDuration && (
        <Box mr={1} display="flex" alignItems="center">
          <EstimationIcon size="small" />
        </Box>
      )}
      <Typography variant="body2">{value}</Typography>
    </Box>
  );
}

export default DurationColumn;
