import React, { useEffect, useState } from "react";
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import { Box } from "@mui/material";

import RHFMultipleSelect from "components/reactHookFormInputs/RHFMultipleSelect";
import { useScreenSize } from "globals/hooks";
import {
  EditDefaultDayOfWeek,
  EditDynamicPricingDefaultValues,
} from "../useEditDynamicPricingDefaultValues";

type DaysOfWeekProps = {
  errors: FieldErrors;
  onSetValue: UseFormSetValue<EditDynamicPricingDefaultValues>;
  onClearErrors: UseFormClearErrors<EditDynamicPricingDefaultValues>;
  watch: UseFormWatch<EditDynamicPricingDefaultValues>;
  defaultDaysOfWeek: EditDefaultDayOfWeek;
};

function DaysOfWeek(props: DaysOfWeekProps) {
  const { errors, watch, defaultDaysOfWeek, onSetValue, onClearErrors } = props;

  // hook
  const { isSmallTabletView } = useScreenSize();

  // state
  const [disabled, setDisabled] = useState(false);

  // get updated values as user changes react hook form inputs
  const { startDate, endDate, startTime, endTime } = watch();

  const updateReactHookForm = (newValues) => {
    const rhfValues = newValues.map((val) => ({
      id: val.id,
      value: val.value,
      isSelected: val.isSelected,
    }));
    onSetValue("daysOfWeek", rhfValues);
    onClearErrors("daysOfWeek");
  };

  // disable weekdays input if only start date is selected
  useEffect(() => {
    if (startDate && !endDate) {
      setDisabled(true);
      onSetValue("daysOfWeekIsDisabled", true);
    } else {
      setDisabled(false);
      onSetValue("daysOfWeekIsDisabled", false);
    }
  }, [startDate, endDate, startTime, endTime, onSetValue]);

  return (
    <Box my={1.5} width={isSmallTabletView ? "100%" : "49.5%"}>
      <RHFMultipleSelect
        name="daysOfWeek"
        errorMessage={errors.daysOfWeek?.message}
        options={defaultDaysOfWeek}
        labelId="weekday-select"
        label="Days of Week"
        selectAllValue={"Everyday"}
        onUpdateRHF={updateReactHookForm}
        watch={watch}
        isDisabled={disabled}
      />
    </Box>
  );
}

export default DaysOfWeek;
