/**
 * @file useEmptyGarage.tsx
 * hook for placing garage marker on map when no tracked drivers present
 * auth: Ben W.
 */

import { useEffect, useMemo, useState } from "react";

import { useOperator } from "globals/hooks";
import garageSVG from "../../../../../design-system/illustrations/garage.svg";

type useEmptyGarageProps = {
  map: google.maps.Map;
  isTrackedDriversEmpty: boolean;
};

export const useEmptyGarage = (props: useEmptyGarageProps) => {
  const { map, isTrackedDriversEmpty } = props;

  // state
  const [garageMarker, setGarageMarker] = useState<null | google.maps.Marker>(
    null
  );

  // hooks
  const operator = useOperator();

  const garageCoordinates = useMemo(() => {
    return {
      lat: operator?.location.x,
      lng: operator?.location.y,
    };
  }, [operator]);

  // effect adjusts map bounds to garage if present
  useEffect(() => {
    // no effect if conditions aren't satisfied
    if (!map || !isTrackedDriversEmpty) return;
    if (!garageCoordinates.lat || !garageCoordinates.lng) return;

    const bounds = new google.maps.LatLngBounds();
    bounds.extend(garageCoordinates);
    map.fitBounds(bounds);
    const listener = google.maps.event.addListener(map, "idle", () => {
      map.setZoom(13);
      google.maps.event.removeListener(listener);
    });
  }, [map, isTrackedDriversEmpty, garageCoordinates]);

  // effect places the marker on the map
  useEffect(() => {
    if (!garageCoordinates.lat || !garageCoordinates.lng) return;

    if (map && isTrackedDriversEmpty) {
      const garageMarker = new google.maps.Marker({
        position: garageCoordinates,
        icon: createCenterPinIcon(), //garageSVG,
        map: map,
        label: {
          text: "Garage",
          fontFamily: "Poppins",
          fontSize: "14",
          fontWeight: "bold",
        },
      });
      setGarageMarker(garageMarker);
    }
  }, [map, isTrackedDriversEmpty, garageCoordinates]);

  // remove garage marker if there are active drivers
  useEffect(() => {
    if (!isTrackedDriversEmpty) {
      garageMarker?.setMap(null);
    }
  }, [garageMarker, isTrackedDriversEmpty]);
};

const createCenterPinIcon = () => {
  return {
    url: garageSVG,
    scaledSize: new google.maps.Size(32, 32), // set SVG size to determine label coords
    labelOrigin: new google.maps.Point(16, 42), // x-coord equivalent to SVG.width/2. y is eyeballed
  };
};
