import React from "react";

import { Cell } from "../../utils/types";
import { CheckCircleIcon, MinusIcon } from "design-system/icons";
import { aqua, black, moovsBlue, purple } from "design-system/colors";

const row1: Cell[] = [
  {
    column: 1,
    typographyText: "Customer Booking Widget",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <CheckCircleIcon size="small" color={black} />,
  },
  {
    column: 3,
    icon: <CheckCircleIcon size="small" color={moovsBlue} />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row2: Cell[] = [
  {
    column: 1,
    typographyText: "Quote & Reservation Dashboard",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <CheckCircleIcon size="small" color={black} />,
  },
  {
    column: 3,
    icon: <CheckCircleIcon size="small" color={moovsBlue} />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row3: Cell[] = [
  {
    column: 1,
    typographyText: "Real-Time Dispatching",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <CheckCircleIcon size="small" color={black} />,
  },
  {
    column: 3,
    icon: <CheckCircleIcon size="small" color={moovsBlue} />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row4: Cell[] = [
  {
    column: 1,
    typographyText: "Calendar View Scheduling",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <CheckCircleIcon size="small" color={black} />,
  },
  {
    column: 3,
    icon: <CheckCircleIcon size="small" color={moovsBlue} />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row5: Cell[] = [
  {
    column: 1,
    typographyText: "Digital Invoicing",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <CheckCircleIcon size="small" color={black} />,
  },
  {
    column: 3,
    icon: <CheckCircleIcon size="small" color={moovsBlue} />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row6: Cell[] = [
  {
    column: 1,
    typographyText: "Text & Email Notifications",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <CheckCircleIcon size="small" color={black} />,
  },
  {
    column: 3,
    icon: <CheckCircleIcon size="small" color={moovsBlue} />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row7: Cell[] = [
  {
    column: 1,
    typographyText: "Driver App iOS/Android",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <CheckCircleIcon size="small" color={black} />,
  },
  {
    column: 3,
    icon: <CheckCircleIcon size="small" color={moovsBlue} />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row8: Cell[] = [
  {
    column: 1,
    typographyText: "Customer Portals & Login",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <CheckCircleIcon size="small" color={black} />,
  },
  {
    column: 3,
    icon: <CheckCircleIcon size="small" color={moovsBlue} />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row9: Cell[] = [
  {
    column: 1,
    typographyText: "Moovs Chat/SMS",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <CheckCircleIcon size="small" color={black} />,
  },
  {
    column: 3,
    icon: <CheckCircleIcon size="small" color={moovsBlue} />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row10: Cell[] = [
  {
    column: 1,
    typographyText: "Custom Zone Pricing",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <CheckCircleIcon size="small" color={black} />,
  },
  {
    column: 3,
    icon: <CheckCircleIcon size="small" color={moovsBlue} />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row11: Cell[] = [
  {
    column: 1,
    typographyText: "Driver Links",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <CheckCircleIcon size="small" color={black} />,
  },
  {
    column: 3,
    icon: <CheckCircleIcon size="small" color={moovsBlue} />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row12: Cell[] = [
  {
    column: 1,
    typographyText: "Chargeback Protection",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <CheckCircleIcon size="small" color={black} />,
  },
  {
    column: 3,
    icon: <CheckCircleIcon size="small" color={moovsBlue} />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row13: Cell[] = [
  {
    column: 1,
    typographyText: "E-Signature Authorizations",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <CheckCircleIcon size="small" color={black} />,
  },
  {
    column: 3,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row14: Cell[] = [
  {
    column: 1,
    typographyText: "Dynamic Pricing",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 3,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row15: Cell[] = [
  {
    column: 1,
    typographyText: "Report Downloads",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 3,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row16: Cell[] = [
  {
    column: 1,
    typographyText: "Advanced Dispatch Filtering",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 3,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const chartData = [
  row1,
  row2,
  row3,
  row4,
  row5,
  row6,
  row7,
  row8,
  row9,
  row10,
  row11,
  row12,
  row13,
  row14,
  row15,
  row16,
];

export default chartData;
