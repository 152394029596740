import React from "react";

import { Box, Typography } from "@mui/material";

export type LabelOption = string;

type EllipsisLabelProps = {
  label: string;
};

function EllipsisLabel(props: EllipsisLabelProps) {
  const { label } = props;

  return (
    <Box mx={1} mb={0.5} data-testid="ellipsis-option-label">
      <Typography variant="overline">{label}</Typography>
    </Box>
  );
}

export default EllipsisLabel;
