import React from "react";
import { useMutation } from "@apollo/client";

import { Box, Typography } from "@mui/material";

import LabeledSwitchInput from "components/settings/general/LabeledSwitchInput";
import {
  ENABLE_CREDIT_CARD_WHEN_BOOKING_MUTATION,
  DISABLE_CREDIT_CARD_WHEN_BOOKING_MUTATION,
} from "globals/graphql";
import { useSnackbar, useOperator } from "globals/hooks";
import ReservationPaymentPreference from "./ReservationPaymentPreference";

type PaymentsTabProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function PaymentsTab(props: PaymentsTabProps) {
  const { setSaveIndicatorState } = props;

  // hooks
  const snackbar = useSnackbar();
  const operator = useOperator();

  // mutations
  const [enableCreditCard] = useMutation(
    ENABLE_CREDIT_CARD_WHEN_BOOKING_MUTATION,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        snackbar.error("Error enabling credit cards");
      },
    }
  );

  const [disableCreditCard] = useMutation(
    DISABLE_CREDIT_CARD_WHEN_BOOKING_MUTATION,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        snackbar.error("Error disabling credit cards.");
      },
    }
  );

  // event handlers
  const handleEnableCreditCard = () => {
    enableCreditCard({
      variables: {
        input: {},
      },
    });
  };

  const handleDisableCreditCard = () => {
    disableCreditCard({
      variables: {
        input: {},
      },
    });
  };

  const handleEnableCreditWhenBookingChange = () => {
    setSaveIndicatorState("loading");
    operator.enableCreditCardWhenBooking
      ? handleDisableCreditCard()
      : handleEnableCreditCard();
  };

  return (
    <>
      <Typography variant="h3">Credit Card Payments</Typography>
      <Box mt={3} mb={2} ml={-1}>
        <LabeledSwitchInput
          labelPlacement="start"
          name="enableCreditCardWhenBooking"
          checked={operator.enableCreditCardWhenBooking}
          onChange={handleEnableCreditWhenBookingChange}
          text="Enable credit cards for reservations"
          subText={
            operator.enableCreditCardWhenBooking
              ? "Credit card section enabled"
              : "Credit card section disabled, customers using the widget will not be able to enter a credit card"
          }
        />
      </Box>
      <Box>
        {operator.enableCreditCardWhenBooking && (
          <ReservationPaymentPreference
            setSaveIndicatorState={setSaveIndicatorState}
          />
        )}
      </Box>
    </>
  );
}

export default PaymentsTab;
