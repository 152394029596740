import { useMutation } from "@apollo/client";

import LabeledSwitchInput from "../LabeledSwitchInput";
import { useOperator, useSnackbar } from "globals/hooks";
import { UPDATE_OPERATOR_SETTINGS } from "globals/graphql";
import MoovsTooltip from "components/MoovsTooltip";

type AddBookingContactToCalendarInviteBlockProps = {
  setSaveIndicatorState: (value: string) => void;
};

function AddBookingContactToCalendarInviteBlock(
  props: AddBookingContactToCalendarInviteBlockProps
) {
  const { setSaveIndicatorState } = props;

  const snackbar = useSnackbar();
  const {
    settings: { calendarTripInviteBcEnabled, isCalendarIntegrationEnabled },
  } = useOperator();

  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error(
        "Error updating setting for adding booking contacts to calendar trip invites"
      );
    },
  });

  const handleAddBookingContactToCalendarTripInvite = () => {
    setSaveIndicatorState("loading");
    updateOperatorSettings({
      variables: {
        input: {
          calendarTripInviteBcEnabled: !calendarTripInviteBcEnabled,
        },
      },
    });
  };

  return (
    <MoovsTooltip
      hidden={isCalendarIntegrationEnabled}
      tooltipText={
        "This feature requires Google Calendar to be authorized from the Moovs Market. Check back a few minutes after the installation"
      }
      followCursor={true}
    >
      <LabeledSwitchInput
        name="addBookingContactToCalendarInvite"
        checked={isCalendarIntegrationEnabled && calendarTripInviteBcEnabled}
        disabled={!isCalendarIntegrationEnabled}
        onChange={handleAddBookingContactToCalendarTripInvite}
        text="Add Booking Contacts to trip calendar invites"
        subText="If your Google Calendar is authorized via Moovs Market, booking contacts will be automatically added to trip calendar invites."
      />
    </MoovsTooltip>
  );
}

export default AddBookingContactToCalendarInviteBlock;
