import React from "react";

import { Box } from "@mui/material";

import { DynamicPricingRule } from "types";
import DateChip from "./chips/DateChip";
import TimeChip from "./chips/TimeChip";
import DaysOfWeekChip from "./chips/DaysOfWeekChip";
import AdjustAmountChip from "./chips/AdjustAmountChip";
import RepeatRuleChip from "./chips/RepeatRuleChip";
import VehiclesChip from "./chips/VehiclesChip";

type ChipsListProps = {
  rule: DynamicPricingRule;
  onSetVehiclesDrawerOpen: () => void;
  onSetWeekdaysDrawerOpen: () => void;
};

function ChipsList(props: ChipsListProps) {
  const { rule, onSetVehiclesDrawerOpen, onSetWeekdaysDrawerOpen } = props;

  const {
    startDate,
    endDate,
    startTime,
    endTime,
    isIncrease,
    amount,
    isPercent,
    daysOfWeek,
    vehicles,
    repeatsAnnually,
  } = rule;

  return (
    <Box mt={2}>
      <DateChip startDate={startDate} endDate={endDate} />
      <TimeChip startTime={startTime} endTime={endTime} />
      <DaysOfWeekChip
        daysOfWeek={daysOfWeek}
        onSetWeekdaysDrawerOpen={onSetWeekdaysDrawerOpen}
      />
      <AdjustAmountChip
        isIncrease={isIncrease}
        amount={amount}
        isPercent={isPercent}
      />
      <RepeatRuleChip
        repeatsAnnually={repeatsAnnually}
        startDate={startDate}
        endDate={endDate}
        daysOfWeek={daysOfWeek}
        onSetWeekdaysDrawerOpen={onSetWeekdaysDrawerOpen}
      />
      <VehiclesChip
        vehicles={vehicles}
        onSetVehiclesDrawerOpen={onSetVehiclesDrawerOpen}
      />
    </Box>
  );
}

export default ChipsList;
