import React from "react";

type VehicleIconProps = {
  color?: string;
  size?: "small";
};

const VehicleIcon = (props: VehicleIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    // icon not on figma, so resized 24 version
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.14287 13.1429H7.42858M16.5714 13.1429H18.8572M20.2286 9L18.6743 5.39048C18.5272 4.98162 18.2638 4.62933 17.9191 4.38082C17.5745 4.1323 17.1652 3.99943 16.7461 4H7.25395C6.83481 3.99943 6.4255 4.1323 6.08088 4.38082C5.73625 4.62933 5.47277 4.98162 5.32572 5.39048L3.77144 9M2.85715 16.5714H7.42858V17.7143C7.42858 18.9767 6.40523 20 5.14287 20C3.8805 20 2.85715 18.9767 2.85715 17.7143V16.5714ZM16.5714 16.5714H21.1429V17.7143C21.1429 18.9767 20.1195 20 18.8572 20C17.5948 20 16.5714 18.9767 16.5714 17.7143V16.5714ZM3.54287 9H20.4571C20.9421 9 21.4072 9.20068 21.7501 9.55789C22.0931 9.9151 22.2857 10.3996 22.2857 10.9048V15.619C22.2857 15.8716 22.1894 16.1139 22.0179 16.2925C21.8465 16.4711 21.6139 16.5714 21.3714 16.5714H2.62858C2.3861 16.5714 2.15354 16.4711 1.98208 16.2925C1.81062 16.1139 1.71429 15.8716 1.71429 15.619V10.9048C1.71429 10.3996 1.90695 9.9151 2.24987 9.55789C2.59279 9.20068 3.0579 9 3.54287 9Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.14287 13.1429H7.42858M16.5714 13.1429H18.8572M20.2286 9L18.6743 5.39048C18.5272 4.98162 18.2638 4.62933 17.9191 4.38082C17.5745 4.1323 17.1652 3.99943 16.7461 4H7.25395C6.83481 3.99943 6.4255 4.1323 6.08088 4.38082C5.73625 4.62933 5.47277 4.98162 5.32572 5.39048L3.77144 9M2.85715 16.5714H7.42858V17.7143C7.42858 18.9767 6.40523 20 5.14287 20C3.8805 20 2.85715 18.9767 2.85715 17.7143V16.5714ZM16.5714 16.5714H21.1429V17.7143C21.1429 18.9767 20.1195 20 18.8572 20C17.5948 20 16.5714 18.9767 16.5714 17.7143V16.5714ZM3.54287 9H20.4571C20.9421 9 21.4072 9.20068 21.7501 9.55789C22.0931 9.9151 22.2857 10.3996 22.2857 10.9048V15.619C22.2857 15.8716 22.1894 16.1139 22.0179 16.2925C21.8465 16.4711 21.6139 16.5714 21.3714 16.5714H2.62858C2.3861 16.5714 2.15354 16.4711 1.98208 16.2925C1.81062 16.1139 1.71429 15.8716 1.71429 15.619V10.9048C1.71429 10.3996 1.90695 9.9151 2.24987 9.55789C2.59279 9.20068 3.0579 9 3.54287 9Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VehicleIcon;
