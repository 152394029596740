import React from "react";

import ActionButton from "design-system/components/ActionButton";
import { LinkIcon } from "design-system/icons";
import CopyLinksDialog from "pages/requests/RequestOverview/ReservationOverview/CopyLinksDialog";
import { useLinksClick } from "./ViewMoreActionButton/hooks";
import { Trip } from "types";

type ViewCopyLinksDialogActionButtonProps = {
  requestId: string;
  trip: Trip;
};

function ViewCopyLinksDialogActionButton(
  props: ViewCopyLinksDialogActionButtonProps
) {
  const { trip, requestId } = props;

  // state
  const viewLinksModal = useLinksClick();

  return (
    <>
      <ActionButton
        icon={LinkIcon}
        label="Links"
        onClick={viewLinksModal.onClick}
      />
      <CopyLinksDialog
        open={viewLinksModal.open}
        onClose={viewLinksModal.onClose}
        requestId={requestId}
        trip={trip}
      />
    </>
  );
}

export default ViewCopyLinksDialogActionButton;
