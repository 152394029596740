import React, { useCallback, useState } from "react";
import startsWith from "lodash/startsWith";
import replace from "lodash/replace";

import { Box, Link, Typography } from "@mui/material";

import { useAnalytics, useSnackbar } from "globals/hooks";
import { WarningTriangleIcon } from "design-system/icons";
import { errorRed } from "design-system/colors";

const useTestWidgetConnection = () => {
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  const [isTesting, setIsTesting] = useState(false);
  const [hasValidationError, setHasValidationError] = useState(false);

  /* 
    Returns true or false based on if the customer portal widget
    could be added to the website
  */
  const testConnection = useCallback(
    async (testUrl: string, testSilently = false): Promise<boolean> => {
      setIsTesting(true);
      setHasValidationError(false);

      const url = startsWith(testUrl, "http:")
        ? replace(testUrl, /^http:/, "https:")
        : !startsWith(testUrl, "http")
        ? "https://" + testUrl
        : testUrl;

      // attempt to validate as url
      try {
        new URL(url);
      } catch (e) {
        setHasValidationError(true);
        setIsTesting(false);
        return false;
      }

      return new Promise((resolve) => {
        let onErrorTimeout: NodeJS.Timeout;
        let iframe: HTMLIFrameElement;

        // listening for response from the customer sdk
        const windowListener = (message) => {
          if (message.data === "moovs-widget-healthy") {
            cleanup(true, "Success");
            resolve(true);
          }
        };

        const onError = () => {
          cleanup(false, "Fail");
          resolve(false);
        };

        const cleanup = (success: boolean, status: "Success" | "Fail") => {
          setIsTesting(false);
          window.removeEventListener("message", windowListener);
          iframe?.remove(); // Optional chaining in case iframe isn't initialized yet.
          clearTimeout(onErrorTimeout);

          if (!testSilently) {
            track("portalWidget_connectionTested", { status });
            success
              ? snackbar.success(
                  "Customer portal widget was successfully added to your website"
                )
              : snackbar.error("", {
                  icon: <WarningTriangleIcon color={errorRed} />,
                  alertBodyComponent: (
                    <FailedAlertBodyComponent track={track} />
                  ),
                });
          }
        };

        window.addEventListener("message", windowListener);

        // create iframe
        iframe = document.createElement("iframe");
        iframe.style.display = "none";

        iframe.addEventListener("error", onError);
        iframe.addEventListener("abort", onError);
        iframe.addEventListener("load", () => {
          iframe.contentWindow.postMessage(["moovs-health-check"], "*");
          onErrorTimeout = setTimeout(onError, 5000);
        });

        iframe.src = url;
        document.body.appendChild(iframe);
      });
    },
    [snackbar, track]
  );

  const resetErrors = useCallback(() => setHasValidationError(false), []);

  return {
    testConnection,
    isTesting,
    hasValidationError,
    resetErrors,
  };
};

function FailedAlertBodyComponent(props: { track: Function }) {
  return (
    <Box display="flex" alignItems="center">
      <Typography color={errorRed}>
        Customer portal failed to be added to your website.&nbsp;&nbsp;
      </Typography>
      <Link
        id="fab-help"
        onClick={() =>
          props.track("customerPortalSettings_helpRequested", {
            location: "Widget",
          })
        }
        style={{
          cursor: "pointer",
          textDecoration: "none",
          whiteSpace: "nowrap",
          fontSize: "16px",
          fontWeight: 600,
        }}
      >
        Ask for help
      </Link>
    </Box>
  );
}

export { useTestWidgetConnection };
