import moment from "moment";
import React from "react";

import { IconNames } from "../../utils/types";
import DynamicPricingChip from "../DynamicPricingChip";

type DateChipProps = {
  startDate: Date;
  endDate: Date;
};

function DateChip(props: DateChipProps) {
  const { startDate, endDate } = props;

  if (!startDate && !endDate) return null;

  let label;

  if (startDate) {
    const formattedStartDate = moment.utc(startDate).format("M/DD/YYYY");
    if (endDate) {
      // start date to end date
      const formattedEndDate = moment.utc(endDate).format("M/DD/YYYY");
      label = `${formattedStartDate} - ${formattedEndDate}`;
    } else {
      // start date only
      label = formattedStartDate;
    }
  }

  return <DynamicPricingChip label={label} iconName={IconNames.date} />;
}

export default DateChip;
