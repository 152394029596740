import first from "lodash/first";
import join from "lodash/join";
import last from "lodash/last";
import slice from "lodash/slice";
import map from "lodash/map";

import { PickUpVariant, Request, Trip } from "types";
import { tripStartEndDateTimeParser } from "pages/requests/utils/calculateTripEndDateTime";
import { getPassenger } from "globals/utils/passenger";

export const getTripDetailsText = (
  request: Request,
  trip: Trip,
  isFarmee: boolean
) => {
  const pickUpStop = first(trip.stops);

  // trip number
  const tripNumber = `${request.orderNumber}-${trip.tripNumber}`;

  // dateTimes
  const pickUpDateTime = tripStartEndDateTimeParser({
    startDateTime: pickUpStop.dateTime,
    endDateTime: last(trip.stops).dateTime,
    excludeNewLine: true,
  });

  // pickup variant
  const pickUpStopVariant =
    pickUpStop.pickUpVariant === PickUpVariant.MeetGreet
      ? "Meet & Greet"
      : pickUpStop.pickUpVariant === PickUpVariant.Curbside
      ? "Curbside"
      : null;

  // passenger
  const farmeePassenger = getPassenger(trip.contact, trip.tempPassenger, true);

  const passenger = trip.tempPassenger?.name
    ? `${trip.tempPassenger.name} 
Ph: ${trip.tempPassenger.mobilePhone}`
    : `${trip.contact.firstName} ${trip.contact.lastName}
Ph: ${trip.contact.mobilePhone}`; // phone number is hidden from farmee

  // stops
  const modifiedStops = trip.stops.map((stop) =>
    stop.trackedFlight
      ? `${stop.airport.airportName} - ${stop.trackedFlight.flightNumber}`
      : stop.location
  );
  const innerStops = join(
    map(slice(modifiedStops, 1, -1), (stop, index) => {
      return `
[ST${index + 1}]: ${stop}`;
    })
  );

  return `Trip Number: ${tripNumber} 
Pick-up Date/Time: ${pickUpDateTime}
Pax: ${isFarmee ? farmeePassenger.name : passenger}
[PU]: ${first(modifiedStops)}${innerStops} ${
    pickUpStopVariant ? `(${pickUpStopVariant})` : ""
  }
[DO]: ${last(modifiedStops)}
Trip Notes: ${trip.note || "N/A"}
Driver Notes: ${first(trip.routes).driverNote || "N/A"}`;
};
