import React, { Dispatch, SetStateAction } from "react";

import { Box } from "@mui/material";

import MoovsTabs from "components/globals/MoovsTabs";

type PromoCodeDrawerTabsProps = {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
};

function PromoCodeDrawerTabs(props: PromoCodeDrawerTabsProps) {
  const { value, setValue } = props;

  return (
    <Box mx={1}>
      <MoovsTabs
        value={value}
        onChange={setValue}
        tabs={[
          { label: "OVERVIEW", value: "Overview" },
          {
            label: "RESULTS",
            value: "Results",
          },
        ]}
        hideBottomBorder
      />
    </Box>
  );
}

export default PromoCodeDrawerTabs;
