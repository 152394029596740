import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import StepperFormFooter from "components/forms/StepperForm/StepperFormFooter";
import MoovsDialog from "components/MoovsDialog";
import useFormStepProgress from "globals/hooks/useFormStepProgress";
import StepperFormBody from "components/forms/StepperForm/StepperFormBody";
import useInitializeDynamicPricingForms from "./hooks/useInitializeDynamicPricingForms";
import { CREATE_DYNAMIC_PRICING_RULE } from "globals/graphql";
import { finalizeDynamicPricingInput } from "./utils/finalizeDynamicPricingInput";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { DynamicPricingInputType } from "./utils/types";
import { WorkflowSelectionsEnum } from "./steps/workflow/schemaValidation";
import { initialInput } from "./utils/initialDynamicPricingInputs";

export const GlobalDynamicPricing = React.createContext([]);

type DynamicPricingDialogProps = {
  open: boolean;
  onClose: () => void;
  onRefetchRules: () => void;
};

function DynamicPricingDialog(props: DynamicPricingDialogProps) {
  const { open, onClose, onRefetchRules } = props;

  // state
  const [workflowOption, setWorkflowOption] =
    useState<WorkflowSelectionsEnum>(null);
  const [dynamicPricingInputs, setDynamicPricingInputs] =
    useState<DynamicPricingInputType>(initialInput);

  // hooks
  const { track } = useAnalytics();
  const snackbar = useSnackbar();
  const {
    formComponentSteps,
    eventHandlerSteps,
    resetAllForms,
    setWorkflowOutline,
  } = useInitializeDynamicPricingForms(workflowOption);

  const { currentStep, goForward, goBack, startAtFirstStep, isFirst, isLast } =
    useFormStepProgress(formComponentSteps?.length);

  // mutation
  const [createDynamicPricingRule, { loading: createPricingRuleLoading }] =
    useMutation(CREATE_DYNAMIC_PRICING_RULE, {
      onCompleted() {
        handleCloseAndReset();
        onRefetchRules();
      },
      onError() {
        snackbar.error("Error creating dynamic base rate.");
      },
    });

  // event handlers
  // handleAfterSubmit only gets invoked when validation passes (handleValidate is where validation occurs)
  const handleAfterSubmit = (data) => {
    if (!isLast) {
      if (data.workflow) {
        // on step 1, changing workflow option
        const selectedWorkflow = data.workflow;
        setDynamicPricingInputs(initialInput);
        setWorkflowOption(selectedWorkflow);

        // tracking
        if (selectedWorkflow === WorkflowSelectionsEnum.date) {
          track("dynamicPricing_specificDateSelected");
        } else if (selectedWorkflow === WorkflowSelectionsEnum.dateRange) {
          track("dynamicPricing_dateRangeSelected");
        } else if (selectedWorkflow === WorkflowSelectionsEnum.timesOfDay) {
          track("dynamicPricing_timeOfDaySelected");
        }
      } else {
        /* on step 2 and above
        Note: We do not update the inputs with this hook on the last page 
        because the state would not be updated in time before the server request
        unless we put the request in a useEffect. Instead, the values for the last
        page are updated directly within the useOverviewForm.tsx file's component
        */
        setDynamicPricingInputs({
          ...dynamicPricingInputs,
          [workflowOption]: {
            ...dynamicPricingInputs[workflowOption],
            ...data,
          },
        });
      }
      goForward();
    } else {
      // on last step
      const { finalData } = finalizeDynamicPricingInput({
        dynamicPricingInputs,
        workflowOption,
      });
      createDynamicPricingRule({
        variables: {
          input: finalData,
        },
      });
    }
  };

  // each step/form is validated before user is allowed to move towards to next step/form
  const handleValidate = () => {
    eventHandlerSteps(handleAfterSubmit)[currentStep]();

    // tracking on last step when user clicks activate
    if (isLast) {
      track("dynamicPricing_activated");
    }
  };

  const handleCloseAndReset = () => {
    setWorkflowOutline(null);
    resetAllForms();
    onClose();

    const timeToResetState = setTimeout(() => {
      startAtFirstStep();
    }, 500);
    return () => {
      clearTimeout(timeToResetState);
    };
  };

  return (
    <MoovsDialog
      open={open}
      onClose={handleCloseAndReset}
      dialogTitle={formComponentSteps?.[currentStep].header}
      size="md"
      sx={{ minHeight: "460px" }}
      customFooter={
        <StepperFormFooter
          isFirst={isFirst}
          isLast={isLast}
          onGoBack={goBack}
          onCancel={handleCloseAndReset}
          onValidate={handleValidate}
          showHelpButton={true}
          onHelp={() => track("dynamicPricing_helpRequested")}
          submitDisabled={createPricingRuleLoading}
        />
      }
    >
      <GlobalDynamicPricing.Provider
        value={[dynamicPricingInputs, workflowOption, setDynamicPricingInputs]}
      >
        <StepperFormBody steps={formComponentSteps} currentStep={currentStep} />
      </GlobalDynamicPricing.Provider>
    </MoovsDialog>
  );
}

export default DynamicPricingDialog;
