import { gql } from "@apollo/client";

export const LOAD_CONTACTS_AND_FARM_AFFILIATES_AND_COMPANIES_QUERY = gql`
  query LoadContactsAndFarmAffiliatesAndCompanies(
    $searchTerm: String
    $cursor: String
    $limit: Int
  ) {
    loadContactsAndFarmAffiliatesAndCompanies(
      searchTerm: $searchTerm
      after: $cursor
      first: $limit
    ) {
      totalCount
      edges {
        node {
          ... on MoovsNetworkOperator {
            id
            operatorName
            operatorEmail
            operatorPhone
          }
          ... on GriddnetOperator {
            id
            operatorName
            operatorEmail
            operatorPhone
          }
          ... on MoovsAiOperator {
            id
            operatorName
            operatorEmail
            operatorPhone
          }
          ... on Contact {
            id
            createdAt
            updatedAt
            firstName
            lastName
            email
            mobilePhone
            mobilePhoneInternational
            companyPosition
            preferences
            homeAddress
            workAddress
            paymentMethods {
              id
              createdAt
              updatedAt
              contactId
              cardNote
              isPrimary
              card {
                id
                expMonth
                expYear
                last4
                brand
              }
            }
            company {
              id
              name
            }
          }
          ... on Company {
            id
            name
            companyEmail: email
            phoneNumber
            companyLogoUrl
            paymentMethods {
              id
              createdAt
              updatedAt
              contactId
              cardNote
              isPrimary
              card {
                id
                expMonth
                expYear
                last4
                brand
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
