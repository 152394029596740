import React from "react";

import { Typography, Box } from "@mui/material";

import { useScreenSize } from "globals/hooks";

type TripNotesProps = {
  driverCloseoutNote: string;
  farmeeCloseoutNote: string;
  operatorCloseoutNote: string;
  isFarmedRoute: boolean;
};

function TripNotes(props: TripNotesProps) {
  const {
    driverCloseoutNote,
    farmeeCloseoutNote,
    isFarmedRoute,
    operatorCloseoutNote,
  } = props;
  const { isMobileView } = useScreenSize();

  return (
    <>
      <Typography variant="h4">Close Out Notes</Typography>
      <Box mt={3} display="flex" flex="1">
        <Box flex="1">
          <Typography variant="body2" style={{ color: "#999999" }}>
            Driver Note
          </Typography>
        </Box>
        <Box flex={isMobileView ? "2" : "4"}>
          <Typography variant="body2">{driverCloseoutNote || "N/A"}</Typography>
        </Box>
      </Box>
      {isFarmedRoute && (
        <Box mt={3} display="flex" flex="1">
          <Box flex="1">
            <Typography variant="body2" style={{ color: "#999999" }}>
              Affiliate Note
            </Typography>
          </Box>
          <Box flex={isMobileView ? "2" : "4"}>
            <Typography variant="body2">
              {farmeeCloseoutNote || "N/A"}
            </Typography>
          </Box>
        </Box>
      )}
      <Box mt={3} display="flex" flex="1">
        <Box flex="1">
          <Typography variant="body2" style={{ color: "#999999" }}>
            Internal Note
          </Typography>
        </Box>
        <Box flex={isMobileView ? "2" : "4"}>
          <Typography variant="body2">
            {operatorCloseoutNote || "N/A"}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default TripNotes;
