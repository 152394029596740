/**
 * @file VehicleFeaturesChipAutocomplete.tsx
 * Swoop-specific chip select.
 *
 * components:
 *  VehicleFeaturesChipAutocomplete
 *
 * author: sienag
 */

import React from "react";
import { useQuery } from "@apollo/client";
import sortBy from "lodash/sortBy";

import { Box, Chip, TextField, Autocomplete } from "@mui/material";

import { CrossIcon, ChevronDownIcon } from "../../icons";
import { black, grayMedium, alabaster, granite } from "../../colors";
import { LOAD_VEHICLE_FEATURES_QUERY } from "../../../globals/graphql";
import { VehicleFeature } from "../../../types";
import { primaryDisabledColor } from "../../../theme";

const autocompleteSx = {
  borderRadius: 1,
  // removes fieldset border in favor of input border.
  "& fieldset": {
    border: "none",
  },
  // targets drop down icon but not svg inside of chip
  "& button svg": {
    fill: "transparent",
  },
  border: "1px solid transparent",
  "&:hover:not(.Mui-focused)": {
    borderColor: grayMedium,
    "& button svg": {
      fill: grayMedium,
    },
  },
  "&.Mui-focused": {
    borderColor: black,
    backgroundColor: alabaster,
    "& button svg": {
      fill: black,
    },
  },
};

const chipStyles = {
  borderRadius: "6px",
  height: "40px",
  fontSize: "14px",
  backgroundColor: primaryDisabledColor,
  margin: "4px",
} as const;

function VehicleFeaturesChipAutocomplete({
  placeholder,
  vehicleFeatures,
  onChange,
}: {
  placeholder?: string;
  vehicleFeatures?: Partial<VehicleFeature>[];
  onChange?: any;
}) {
  const { data: vehicleFeaturesData, loading } = useQuery(
    LOAD_VEHICLE_FEATURES_QUERY
  );

  if (loading) {
    return null;
  }

  const sortedFeatureNames = sortBy(vehicleFeaturesData?.vehicleFeatures, [
    "category",
    "name",
  ]);

  return (
    <Autocomplete
      multiple
      disableClearable
      fullWidth
      onChange={onChange}
      value={vehicleFeatures}
      placeholder={placeholder || "Vehicle Features"}
      sx={autocompleteSx}
      id="chip-autocomplete"
      popupIcon={<ChevronDownIcon transparentFill />}
      options={sortedFeatureNames}
      groupBy={(option: VehicleFeature) => option.category}
      getOptionLabel={(option: VehicleFeature) => option.name}
      isOptionEqualToValue={(option: VehicleFeature, value: VehicleFeature) => {
        return option.name === value.name;
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={option.name}
            id={option.name}
            deleteIcon={
              <Box>
                <CrossIcon color={granite} />
              </Box>
            }
            sx={chipStyles}
            label={option.name}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          variant="outlined"
          placeholder={placeholder || "Add Vehicle Features"}
          {...params}
        />
      )}
    />
  );
}

export { VehicleFeaturesChipAutocomplete };
