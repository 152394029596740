import React, { useEffect } from "react";
import isEmpty from "lodash/isEmpty";

import { Box, CircularProgress } from "@mui/material";

import { useScreenSize } from "globals/hooks";
import MoovsDialog from "components/MoovsDialog";
import EmptyTransactionsBlock from "./components/EmptyTransactionsBlock";
import InvoiceBannerBlock from "./components/InvoiceBannerBlock";
import RequestTransactionList from "./components/RequestTransactionList";
import { useLoadRequestTransactions } from "./hooks/useLoadRequestTransactions";

type ViewPaymentsDialogProps = {
  open: boolean;
  onClose: () => void;
  requestId: string;
};

function ViewPaymentsDialog(props: ViewPaymentsDialogProps) {
  const { open, onClose, requestId } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const {
    transactions,
    transactionsLoading,
    refetchTransactions,
    hasInvoicePaymentsPaid,
    invoiceIds,
  } = useLoadRequestTransactions({
    requestId,
  });

  // effects
  useEffect(() => {
    if (open) refetchTransactions();
  }, [open, refetchTransactions]);

  return (
    <MoovsDialog open={open} onClose={onClose} dialogTitle="Payments Activity">
      <Box {...(isMobileView ? { height: "100%" } : { minHeight: "50vh" })}>
        {/* Empty State */}
        {isEmpty(transactions) && !hasInvoicePaymentsPaid && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            {...(isMobileView ? { height: "100%" } : { minHeight: "50vh" })}
          >
            {transactionsLoading ? (
              <Box p={4}>
                <CircularProgress size={32} />
              </Box>
            ) : (
              <EmptyTransactionsBlock
                title="No Activity"
                subtitle="No payment activity for this reservation"
              />
            )}
          </Box>
        )}

        {/* Invoice */}
        {hasInvoicePaymentsPaid &&
          invoiceIds.map((invoiceId) => (
            <InvoiceBannerBlock key={invoiceId} invoiceId={invoiceId} />
          ))}

        {/* Transaction List */}
        {!isEmpty(transactions) && (
          <RequestTransactionList transactions={transactions} />
        )}
      </Box>
    </MoovsDialog>
  );
}

export default ViewPaymentsDialog;
