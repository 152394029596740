import React, { useState } from "react";

import { Box } from "@mui/material";

import { useScreenSize } from "globals/hooks";
import { MoovsTransaction } from "types";
import TransactionListItem from "components/globals/TransactionListItem/";
import ViewMoreDetailsDialog from "components/invoices/ViewMorePaymentDetailsDialog";
import RequestTransactionDesktopHeader from "./components/RequestTransactionDesktopHeader";

type RequestTransactionListProps = {
  transactions: MoovsTransaction[];
};

function RequestTransactionList(props: RequestTransactionListProps) {
  // props
  const { transactions } = props;

  // state
  const [viewMoreDetailsPaymentId, setViewMoreDetailsPaymentId] = useState("");

  // hooks
  const { isMobileView } = useScreenSize();

  // handler
  const handleCloseViewPaymentDialog = () => {
    setViewMoreDetailsPaymentId("");
  };

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      {...(!isMobileView && { mt: "-8px" })}
      height="100%"
    >
      {!isMobileView && <RequestTransactionDesktopHeader />}

      <Box pb={2}>
        {transactions.map((transactionData, index) => (
          <TransactionListItem
            transaction={transactionData}
            key={transactionData.id}
            isFirstItem={index === 0}
            setViewMoreDetailsPaymentId={setViewMoreDetailsPaymentId}
            includePaymentMethod
          />
        ))}
      </Box>

      <ViewMoreDetailsDialog
        open={!!viewMoreDetailsPaymentId}
        onClose={handleCloseViewPaymentDialog}
        paymentId={viewMoreDetailsPaymentId}
      />
    </Box>
  );
}

export default RequestTransactionList;
