import { PricingDialogPricingData } from "components/pricing/utils";
import { filterRelevantOrderTrips } from "globals/utils/trips";
import { Trip } from "./../../../types";

type NormalizeTripsPricingForPricingDialogParams = {
  trips: Trip[];
  requestOrderNumber: string;
  isFarmee: boolean;
};

// generate the pricings array for MoovsPricingDialog
export const normalizeTripsPricingForPricingDialog = (
  params: NormalizeTripsPricingForPricingDialogParams
): PricingDialogPricingData[] => {
  const { trips, requestOrderNumber, isFarmee } = params;
  let pricingKey = isFarmee ? "farmeePricing" : "pricing";

  return filterRelevantOrderTrips({ trips, isFarmee }).map((trip, index) => {
    const completeOrderNumber = `${requestOrderNumber}-${trip.tripNumber}`;
    return {
      ...trip.routes[0][pricingKey],
      totalDurationMinutes: trip.totalDuration,
      preexistingABR: trip.routes[0].vehicle?.baseRateAutomation,
      label: `Trip ${index + 1} (${completeOrderNumber}) ${
        trip.cancelledAt ? "Cancelled" : ""
      }`,
      isCancelled: !!trip.cancelledAt,
      BRAProps: {
        vehicleId: trip.routes[0].vehicle?.id,
        hasEnabledVehicleBRA: trip.routes[0].vehicle?.enableBaseRateAutomation,
        stops: trip.stops,
      },
    };
  });
};
