import { GridColumns } from "@mui/x-data-grid-pro";

import { grayDark } from "design-system/colors";

import DateColumn from "./DateColumn";
import PassengerColumn from "./PassengerColumn";
import StatusColumn from "./StatusColumn";
import TotalAmountColumn from "./TotalAmountColumn";
import TripConfNumberColumn from "./TripConfNumberColumn";

const defaults = {
  sortable: false,
  hide: false,
  color: grayDark,
  width: 130,
};

const contactTripsGridColumns: GridColumns = [
  {
    ...defaults,
    field: "date",
    headerName: "Date",
    renderCell: DateColumn,
    align: "center",
  },
  {
    ...defaults,
    field: "conf.No.",
    headerName: "Conf. No.",
    renderCell: TripConfNumberColumn,
    align: "center",
  },
  {
    ...defaults,
    field: "passenger",
    headerName: "Passenger",
    renderCell: PassengerColumn,
  },
  {
    ...defaults,
    field: "totalAmount",
    headerName: "Total Amount",
    type: "number",
    renderCell: TotalAmountColumn,
  },
  {
    ...defaults,
    field: "status",
    headerName: "Status",
    renderCell: StatusColumn,
  },
];

export default contactTripsGridColumns;
