import React from "react";

type SentIconProps = {
  color?: string;
  size?: "small";
};

const SentIcon = (props: SentIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.2856 11.5711L7.71416 11.5711M16.2856 11.5711L12.5654 15.1426M16.2856 11.5711L12.5654 7.99972"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 11.5717C22 11.9662 21.6802 12.286 21.2858 12.286C20.8913 12.286 20.5715 11.9662 20.5715 11.5717C20.5715 11.1772 20.8913 10.8574 21.2858 10.8574C21.6802 10.8574 22 11.1772 22 11.5717Z"
          fill="#999999"
        />
        <path
          d="M3.42857 11.5717C3.42857 11.9662 3.10877 12.286 2.71429 12.286C2.3198 12.286 2 11.9662 2 11.5717C2 11.1772 2.3198 10.8574 2.71429 10.8574C3.10877 10.8574 3.42857 11.1772 3.42857 11.5717Z"
          fill="#999999"
        />
        <path
          d="M22 11.5717C22 11.9662 21.6802 12.286 21.2858 12.286C20.8913 12.286 20.5715 11.9662 20.5715 11.5717C20.5715 11.1772 20.8913 10.8574 21.2858 10.8574C21.6802 10.8574 22 11.1772 22 11.5717Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.42857 11.5717C3.42857 11.9662 3.10877 12.286 2.71429 12.286C2.3198 12.286 2 11.9662 2 11.5717C2 11.1772 2.3198 10.8574 2.71429 10.8574C3.10877 10.8574 3.42857 11.1772 3.42857 11.5717Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2856 11.5711L7.71416 11.5711M16.2856 11.5711L12.5654 15.1426M16.2856 11.5711L12.5654 7.99972"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 11.5717C22 11.9662 21.6802 12.286 21.2858 12.286C20.8913 12.286 20.5715 11.9662 20.5715 11.5717C20.5715 11.1772 20.8913 10.8574 21.2858 10.8574C21.6802 10.8574 22 11.1772 22 11.5717Z"
        fill="#999999"
      />
      <path
        d="M3.42857 11.5717C3.42857 11.9662 3.10877 12.286 2.71429 12.286C2.3198 12.286 2 11.9662 2 11.5717C2 11.1772 2.3198 10.8574 2.71429 10.8574C3.10877 10.8574 3.42857 11.1772 3.42857 11.5717Z"
        fill="#999999"
      />
      <path
        d="M22 11.5717C22 11.9662 21.6802 12.286 21.2858 12.286C20.8913 12.286 20.5715 11.9662 20.5715 11.5717C20.5715 11.1772 20.8913 10.8574 21.2858 10.8574C21.6802 10.8574 22 11.1772 22 11.5717Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.42857 11.5717C3.42857 11.9662 3.10877 12.286 2.71429 12.286C2.3198 12.286 2 11.9662 2 11.5717C2 11.1772 2.3198 10.8574 2.71429 10.8574C3.10877 10.8574 3.42857 11.1772 3.42857 11.5717Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SentIcon;
