import React from "react";

import { Box, Typography } from "@mui/material";

import paymentEmptyCard from "design-system/illustrations/paymentEmptyCard.svg";
import { granite } from "design-system/colors";

type EmptyTransactionsBlockProps = {
  title: string;
  subtitle: string;
};

function EmptyTransactionsBlock(props: EmptyTransactionsBlockProps) {
  const { title, subtitle } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={4}
    >
      <img src={paymentEmptyCard} alt="payment empty illustration" />
      <Typography variant="h3" p={1.5}>
        {title}
      </Typography>
      <Typography variant="body2" color={granite}>
        {subtitle}
      </Typography>
    </Box>
  );
}

export default EmptyTransactionsBlock;
