import React, { ChangeEvent, useState } from "react";
import truncate from "lodash/truncate";
import uniqBy from "lodash/uniqBy";
import map from "lodash/map";
import { useDebounce } from "use-debounce";

import {
  Autocomplete,
  Box,
  Chip,
  Divider,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { black, errorRed, grayLight, white } from "design-system/colors";
import {
  CompanyIcon,
  CrossIcon,
  GriddnetOperatorIcon,
  MoovsNetworkOperatorIcon,
  UserIcon,
} from "design-system/icons";
import { useScreenSize } from "globals/hooks";
import { Company, Contact, MoovsNetworkOperator } from "types";
import { EmailRecipient } from "components/SendEmailDialog/SendEmailDialog";
import { useEmailRecipientQuery } from "./hooks";

type EmailRecipientChipAutoCompleteProps = {
  emails: EmailRecipient[];
  onEmailChange: (emails: EmailRecipient[]) => void;
};

function EmailChipAutoComplete(props: EmailRecipientChipAutoCompleteProps) {
  const { emails, onEmailChange } = props;

  // state
  const [searchInput, setSearchInput] = useState("");
  const [debouncedSearchInput, { isPending }] = useDebounce(searchInput, 250);

  // hooks
  const { isMobileView } = useScreenSize();
  const { options, emptyOptions } = useEmailRecipientQuery({
    searchTerm: debouncedSearchInput,
  });

  // event handlers
  const handleSeachInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchInput(event?.target?.value || "");
  };

  const handleAddToList = (_: ChangeEvent, emails: EmailRecipient[]) => {
    // ensures each entry is an object (AKA partial contact) and is unique by email/operatorEmail
    const newEmails = uniqBy(
      map(emails, (email) => (typeof email === "string" ? { email } : email)),
      "email"
    );

    onEmailChange(newEmails);

    setSearchInput("");
    emptyOptions();
  };

  const handleBlur = () => {
    if (!searchInput.length) return;
    const newList = [...emails, { email: searchInput }];
    handleAddToList(null, newList);
  };

  const handleDeleteChip = (deletedEmail: string) => {
    // delete email from emailList
    const newEmails = emails.filter(({ email }) => email !== deletedEmail);
    onEmailChange(newEmails);
  };

  // helper components
  const paperComponent = ({ children }) => {
    const displayAutocomplete = !!options.length && !!searchInput.length;
    if (!displayAutocomplete) return <></>;

    return (
      <Paper>
        <Box px={2} pt={1}>
          <Typography sx={{ backgroundColor: white }} variant="overline">
            Add existing contact
          </Typography>
        </Box>
        {children}
      </Paper>
    );
  };

  return (
    <>
      <Autocomplete
        disableClearable
        multiple
        freeSolo
        PaperComponent={paperComponent}
        filterOptions={(x) => x}
        loading={isPending()}
        value={emails}
        onChange={handleAddToList}
        onBlur={handleBlur}
        options={options}
        onInputChange={handleSeachInputChange}
        inputValue={searchInput}
        getOptionLabel={(contact: EmailRecipient) => contact.email}
        isOptionEqualToValue={(
          option: Contact & MoovsNetworkOperator & Company,
          value: Contact & MoovsNetworkOperator & Company
        ) => option.email === value.email}
        sx={{ "& fieldset": { border: "none" } }}
        renderInput={(params) => {
          const { InputProps, ...restParams } = params;
          return (
            <TextField
              {...restParams}
              fullWidth
              name="no-show"
              InputProps={{
                ...InputProps,
                autoComplete: "name",
                style: { paddingTop: 4, paddingBottom: 4 },
              }}
            />
          );
        }}
        renderOption={(props, { name, email, phone, __typename }) => {
          const icon = {
            MoovsNetworkOperator: <MoovsNetworkOperatorIcon size="small" />,
            GriddnetOperator: <GriddnetOperatorIcon size="small" />,
            Contact: <UserIcon size="small" />,
            Company: <CompanyIcon size="small" strokeWidth="2" />,
          }[__typename];

          return (
            <li {...props} key={name + email}>
              <Box>
                <Box display="flex">
                  <Typography variant="h6" pr={1}>
                    {name}
                  </Typography>
                  {icon}
                </Box>
                <Typography variant="body2">{email}</Typography>
                {!!phone && <Typography variant="body2">{phone}</Typography>}
              </Box>
              <Divider />
            </li>
          );
        }}
        renderTags={(value, getTagProps) =>
          value.map(({ name, email, error }, index) => {
            const chipText =
              !isMobileView && name ? `${name}: ${email}` : email;

            return (
              <Chip
                {...getTagProps({ index })}
                key={email}
                id={email}
                onDelete={() => handleDeleteChip(email)}
                label={truncate(chipText, { length: 45 })}
                sx={{
                  backgroundColor: error ? errorRed : grayLight,
                  color: error ? white : black,
                }}
                deleteIcon={
                  <Box pr={1}>
                    <CrossIcon size="small" color={error ? white : black} />
                  </Box>
                }
              />
            );
          })
        }
      />
    </>
  );
}

export default EmailChipAutoComplete;
