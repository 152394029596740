import React from "react";

import { TextField } from "@mui/material";

import { grayDark, grayMedium, white } from "design-system/colors";
import {
  NumberFormatDecimal,
  NumberFormatDollar,
} from "design-system/components/inputs/NumberFormat";

import { FormVariant, InputFormatVariant } from "../utils/types";

const inputSxOnUpdate = {
  ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: white,
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: grayMedium,
  },
  "&:focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: grayMedium,
  },
  ".MuiFormLabel-root": {
    color: grayDark,
  },
};

type DriverPayoutFieldProps = {
  placeholder: string;
  fieldName: string;
  value: number;
  onHandleFieldChange: (e) => void;
  format: InputFormatVariant;
  variant?: FormVariant;
};

function DriverPayoutField(props: DriverPayoutFieldProps) {
  const {
    placeholder,
    fieldName,
    value,
    onHandleFieldChange,
    format,
    variant,
  } = props;

  const isPercent = format === InputFormatVariant.PERCENT;
  const isDollar = format === InputFormatVariant.DOLLAR;
  const isHours = format === InputFormatVariant.HOURS;

  return (
    <TextField
      fullWidth
      variant="outlined"
      placeholder={placeholder}
      name={fieldName}
      value={value}
      onChange={onHandleFieldChange}
      {...(variant !== FormVariant.UPDATE_DRIVER && { label: placeholder })} // design removes label on update driver drawer
      sx={variant === FormVariant.UPDATE_DRIVER && inputSxOnUpdate}
      InputProps={{
        ...(isPercent && {
          inputComponent: NumberFormatDecimal as any,
          inputProps: {
            suffix: "%",
          },
        }),
        ...(isDollar && {
          inputProps: {
            prefix: "$",
          },
          inputComponent: NumberFormatDollar as any,
        }),
        ...(isHours && {
          inputProps: {
            suffix: " Hours",
          },
          inputComponent: NumberFormatDecimal as any,
        }),
      }}
    />
  );
}

export default DriverPayoutField;
