import React from "react";

import { Box } from "@mui/material";

type StepperFormBodyProps = {
  steps: {
    header: string;
    component: React.ReactNode;
  }[];
  currentStep: number;
};

function StepperFormBody(props: StepperFormBodyProps) {
  const { steps, currentStep } = props;

  return (
    <form>
      <Box mt={3} mb={3}>
        <Box mb={3}>{steps[currentStep].component}</Box>
      </Box>
    </form>
  );
}

export default StepperFormBody;
