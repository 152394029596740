import React from "react";

import { Box, Typography } from "@mui/material";

import paymentBreakdownEmpty from "../../../design-system/illustrations/dashboardEmpty/paymentBreakdownEmpty.svg";
import { grayDark } from "design-system/colors";

function PaymentBreakdownEmptyBlock() {
  return (
    <>
      <Box
        component="img"
        src={paymentBreakdownEmpty}
        alt="Payment Breakdown Empty"
      />
      <Typography variant="subtitle2" sx={{ textAlign: "center", my: 2 }}>
        We couldn't find any data for your query.
      </Typography>
      <Typography
        variant="caption"
        sx={{ textAlign: "center" }}
        color={grayDark}
      >
        We couldn't find any data for that date range.
      </Typography>
    </>
  );
}

export default PaymentBreakdownEmptyBlock;
