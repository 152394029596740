/**
 * Auto Saving Hook, primarily used to encapsilate the
 * somewhat complex way of using autosaving with Apollo Cache.
 *
 * Ensures autosaving fires prior to unmounting component.
 * Using optimistic response via apollo does not work well
 * when updating as you type so this component keeps its own
 * independent local state, that is debounced and updated.
 * ref: https://www.npmjs.com/package/use-debounce
 */

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from "@apollo/client";
import { useDebouncedCallback } from "use-debounce";

import { useSnackbar } from "./useSnackbar";
import upperFirst from "lodash/upperFirst";
import lowerCase from "lodash/lowerCase";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type UseAutoSaveProps<T> = {
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
  mutation: DocumentNode | TypedDocumentNode<any, OperationVariables>;
  incomingState: T;
  name: string;
  onComplete?: (data) => void;
};

function useAutoSave<T>(
  props: UseAutoSaveProps<T>
): [T, (val: T) => void, () => void] {
  const { setSaveIndicatorState, mutation, incomingState, name, onComplete } =
    props;

  // refs
  // prevents firing UI related actions on unmounting component
  const isMounted = useRef(true);

  // hooks
  const snackbar = useSnackbar();
  const debounced = useDebouncedCallback(() => {
    handleDebouncedUpdate();
  }, 1500);

  // state
  const [localState, setLocalState] = useState<T>(null);

  // mutations
  const [update] = useMutation(mutation, {
    onCompleted(data) {
      if (isMounted.current) {
        setSaveIndicatorState("saved");
      } else {
        snackbar.success(`${upperFirst(name)} changes saved!`);
      }

      if (onComplete) onComplete(data);
    },
    onError(error) {
      if (isMounted.current) {
        setSaveIndicatorState("error");
        // reset local state to remote values
        setLocalState(incomingState);
      }

      const errorMessage =
        getErrorMessage(error) || `Error updating ${lowerCase(name)}.`;

      snackbar.error(errorMessage);
    },
  });

  // event handlers
  const handleSetState = (val: T) => {
    // triggers save indicator on type,
    // to make it feel like its instant
    setSaveIndicatorState("loading");

    setLocalState(val);
    debounced();
  };

  const handleDebouncedUpdate = useCallback(async () => {
    await update({
      variables: {
        input: localState,
      },
    });
  }, [localState, update]);

  const resetState = () => {
    setLocalState(incomingState);
  };

  // effects
  // sets local state on load
  useEffect(() => {
    if (incomingState && !localState) {
      setLocalState(incomingState);
    }
  }, [debounced, incomingState, localState]);

  // cleanup debounced state
  useEffect(() => {
    return async () => {
      // ensures that any pending debounced actions are fired
      isMounted.current = false;

      if (debounced.isPending) {
        debounced.flush();
      }
    };
  }, [debounced]);

  return [localState, handleSetState, resetState];
}

export { useAutoSave };
