import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { Box, Button, Typography } from "@mui/material";
import { grayDark, moovsBlue } from "design-system/colors";
import OverflowTooltip from "design-system/components/OverflowTooltip";
import { useScreenSize } from "globals/hooks";
import { ArrowLeftIcon } from "design-system/icons";

type TopBarProps = {
  orderNumber: string;
  tripCount: number;
  whiteLabelOperatorName: string | undefined;
};

const TopBar = (props: TopBarProps) => {
  const { orderNumber, tripCount, whiteLabelOperatorName } = props;

  // hooks
  const history = useHistory<{ backNavigationPath: string }>();
  const location = useLocation();
  const { isMobileView } = useScreenSize();

  const handleBackClick = () => {
    // go back to backNavigationPath stored in location state.
    // if no backNavigationPath was provided, go back to reservations list page
    const { startDate, endDate, status } = queryString.parse(location.search);
    const newQueryParams = queryString.stringify({
      startDate,
      endDate,
      status,
    });
    history.push(
      history.location.state?.backNavigationPath ||
        `/reservations?${newQueryParams}`
    );
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" flex="1" mr={1}>
        <Button
          sx={{ px: 1, mr: isMobileView ? 2 : 0.5 }}
          onClick={handleBackClick}
          color="primary"
          startIcon={<ArrowLeftIcon color={moovsBlue} />}
        >
          Back
        </Button>
        <Typography variant="h1" sx={{ mr: isMobileView ? 1.5 : 2 }}>
          {orderNumber}
        </Typography>
        <Typography
          variant="overline"
          sx={{ position: "relative", top: "12px" }}
          noWrap
        >
          {tripCount} Trips
        </Typography>
      </Box>
      {whiteLabelOperatorName && (
        <OverflowTooltip
          toolTipText={`Sourced by ${whiteLabelOperatorName}`}
          displayText={whiteLabelOperatorName}
          variant="caption"
          placement="top"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            color: grayDark,
            flex: "1",
          }}
        />
      )}
    </Box>
  );
};

export default TopBar;
