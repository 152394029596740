import React, { useMemo, useState } from "react";
import { useQuery } from "@apollo/client";

import { LOAD_VEHICLES_QUERY } from "../../../../globals/graphql";
import MoovsDialog from "../../../MoovsDialog";
import { Vehicle } from "../../../../types";
import MoovsMediaDialogBodyMobile from "./AddMediaFromMoovsBody.mobile";
import MoovsMediaDialogBodyDesktop from "./AddMediaFromMoovsBody.desktop";
import { useScreenSize } from "../../../../globals/hooks";

type AddMoovsMediaDialogProps = {
  open: boolean;
  onClose: () => void;
  onAttachMediaFromMoovs: (selectedMedia) => void;
};

// this component currently built out only for vehicles
function AddMoovsMediaDialog(props: AddMoovsMediaDialogProps) {
  const { open, onClose, onAttachMediaFromMoovs } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // state
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>();
  const [selectedImageIndices, setSelectedImageIndices] = useState({});
  const [isAttachingMedia, setIsAttachingMedia] = useState(false);

  // queries
  const { data: vehiclesData, loading: vehiclesLoading } = useQuery(
    LOAD_VEHICLES_QUERY,
    {
      variables: {
        includeExternalOperators: true,
      },
      fetchPolicy: "network-only",
    }
  );
  const vehicles: Vehicle[] = useMemo(() => {
    if (vehiclesData) {
      return vehiclesData.loadVehicles.edges.map(({ node }) => node);
    }
    return [];
  }, [vehiclesData]);

  // event handlers
  const handleVehicleSelect = (vehicle: Vehicle) => {
    if (selectedVehicle?.id !== vehicle?.id) {
      setSelectedImageIndices({});
      setSelectedVehicle(vehicle);
    }
  };

  const handleImageSelect = (photoIndex: number, isSelected: boolean) => {
    setSelectedImageIndices({
      ...selectedImageIndices,
      [photoIndex]: isSelected,
    });
  };

  const handleCloseDialog = () => {
    setSelectedImageIndices({});
    setSelectedVehicle(null);
    onClose();
  };

  const handleAttachMedia = async () => {
    const selectedMedia = selectedVehicle.photos.filter(
      (photo) => !!selectedImageIndices[photo.photoIndex]
    );

    setIsAttachingMedia(true);
    await onAttachMediaFromMoovs(selectedMedia);
    setIsAttachingMedia(false);
    handleCloseDialog();
  };

  const numSelected = Object.values(selectedImageIndices).reduce(
    (totalSelected: 0, val) => totalSelected + (val ? 1 : 0),
    0
  );
  const acceptButtonText = !!numSelected ? `Select (${numSelected})` : "Select";

  return (
    <MoovsDialog
      dialogTitle="Add from Moovs"
      open={open}
      onClose={handleCloseDialog}
      hideBottomBorder
      hideTopBorder
      size="md"
      closeButtonText="Cancel"
      onAccept={handleAttachMedia}
      acceptButtonText={acceptButtonText}
      removeCloseButton={isMobileView}
      acceptDisabled={!numSelected || isAttachingMedia}
      hideLoadingIndicator={!numSelected}
    >
      {isMobileView ? (
        <MoovsMediaDialogBodyMobile
          vehicles={vehicles}
          onImageSelect={handleImageSelect}
          onVehicleSelect={handleVehicleSelect}
          selectedImageIndices={selectedImageIndices}
          selectedVehicle={selectedVehicle}
          vehiclesLoading={vehiclesLoading}
        />
      ) : (
        <MoovsMediaDialogBodyDesktop
          vehicles={vehicles}
          onImageSelect={handleImageSelect}
          onVehicleSelect={handleVehicleSelect}
          selectedImageIndices={selectedImageIndices}
          selectedVehicle={selectedVehicle}
          vehiclesLoading={vehiclesLoading}
        />
      )}
    </MoovsDialog>
  );
}

export default AddMoovsMediaDialog;
