import React, { useState } from "react";

import { Box, Button, CircularProgress } from "@mui/material";

import MoovsTooltip from "components/MoovsTooltip";
import { useOperator, useScreenSize } from "globals/hooks";
import { InlineInput } from "design-system/components/inputs";
import { grayDark, white } from "design-system/colors";
import { useTestWidgetConnection } from "../hooks/useTestWidgetConnection";

function TestWidgetConnection() {
  // hooks
  const { websiteUrl } = useOperator();
  const { isMobileView } = useScreenSize();

  const [testUrl, setTestUrl] = useState(websiteUrl || "");

  const { testConnection, isTesting, hasValidationError, resetErrors } =
    useTestWidgetConnection();

  const isDisabled = !testUrl || isTesting;

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      flexDirection={isMobileView ? "column" : "row"}
      mt={2}
      gap={2}
    >
      <Box width={isMobileView ? "100%" : "320px"}>
        <InlineInput
          disabled={isTesting}
          placeholder="Enter website URL"
          value={testUrl}
          error={hasValidationError}
          errorText="Please enter a valid URL"
          onChange={(e) => {
            setTestUrl(e.target.value);
            resetErrors();
          }}
          InputProps={{
            style: {
              minHeight: "52px",
              width: isMobileView ? "100%" : "320px",
            },
          }}
        />
      </Box>
      <Box width={isMobileView ? "100%" : "175px"}>
        <MoovsTooltip
          hidden={!!testUrl}
          tooltipText="Please enter your website URL in order to test if code was successfully added"
        >
          <Button
            variant="contained"
            color={isDisabled ? "inherit" : "primary"}
            style={{
              color: isDisabled ? grayDark : white,
            }}
            disabled={isDisabled}
            onClick={() => testConnection(testUrl)}
            fullWidth
          >
            {isTesting ? (
              <>
                Testing
                <CircularProgress style={{ marginLeft: "8px" }} size={20} />
              </>
            ) : (
              "Test Connection"
            )}
          </Button>
        </MoovsTooltip>
      </Box>
    </Box>
  );
}

export { TestWidgetConnection };
