import React, { useState } from "react";

import { Box, Button, Typography } from "@mui/material";

import MoovsDialog from "../../../components/MoovsDialog";
import { convertTimeToLocalAirlineTimezone } from "../../../globals/utils/helpers";
import { moovsBlue, moovsBlueSelected } from "../../../design-system/colors";
import { TrackedFlight } from "types";

const styles = {
  selectedFlight: {
    backgroundColor: moovsBlueSelected,
    border: `2px solid ${moovsBlue}`,
    ":hover": {
      backgroundColor: `${moovsBlueSelected} !important`,
    },
  },
};

type MultipleFlightsDialogProps = {
  open: boolean;
  onClose: () => void;
  onAccept: (selectedFlight: TrackedFlight) => void;
  data: {
    flightNumber: string;
    flights: TrackedFlight[];
    inputAirportIcao: string;
  };
};

function MultipleFlightsDialog(props: MultipleFlightsDialogProps) {
  const { open, onClose, data, onAccept } = props;
  const { flightNumber, flights, inputAirportIcao } = data;

  //state
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [selectedFlightIndex, setSelectedFlightIndex] = useState(null);

  // event handlers
  const handleAccept = () => {
    onAccept(selectedFlight);
    onClose();
  };

  const handleButtonClick = (flight: TrackedFlight, index: number) => {
    setSelectedFlight(flight);
    setSelectedFlightIndex(index);
  };

  const getLocalizedFlightTime = (flight) => {
    return flight.origin.airport.icaoCode === inputAirportIcao
      ? convertTimeToLocalAirlineTimezone(
          flight.origin.scheduledDateTime,
          flight.origin.airport.icaoCode
        )
      : convertTimeToLocalAirlineTimezone(
          flight.destination.scheduledDateTime,
          flight.destination.airport.icaoCode
        );
  };

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      dialogTitle={`Which ${flightNumber} are you searching for?`}
      acceptButtonText="Select"
      onAccept={handleAccept}
      size="sm"
      fixedFooter
      hideTopBorder
      acceptDisabled={!selectedFlight}
      hideLoadingIndicator
    >
      <Box py={2}>
        {flights.map((flight, index) => {
          const flightTime = getLocalizedFlightTime(flight);

          return (
            <Button
              key={index}
              style={{ height: "56px", marginBottom: "8px" }}
              variant="outlined"
              fullWidth
              onClick={() => handleButtonClick(flight, index)}
              sx={[
                {
                  backgroundColor: moovsBlueSelected,
                  border: `2px solid ${moovsBlue}`,
                },
                index === selectedFlightIndex && styles.selectedFlight,
              ]}
            >
              {flight.origin.airport.icaoCode === inputAirportIcao ? (
                <>
                  <Typography
                    component="pre"
                    variant="body1"
                    style={{ fontWeight: "bold" }}
                  >
                    Departs{" "}
                  </Typography>
                  <Typography variant="body1">
                    from {inputAirportIcao} on {flightTime.format("MMM D")} at{" "}
                    {flightTime.format("h:mmA")}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    component="pre"
                    variant="body1"
                    style={{ fontWeight: "bold" }}
                  >
                    Arrives{" "}
                  </Typography>
                  <Typography variant="body1">
                    at {inputAirportIcao} on {flightTime.format("MMM D")} at{" "}
                    {flightTime.format("h:mmA")}
                  </Typography>
                </>
              )}
            </Button>
          );
        })}
      </Box>
    </MoovsDialog>
  );
}

export default MultipleFlightsDialog;
