import isNumber from "lodash/isNumber";

import {
  CreateVehicle,
  VehicleChildSeatsError,
  VehicleRequiredInfoError,
} from "./types";
import { VehiclePricingError } from "../types";
import { ChildSeatVariant, VehicleChildSeat } from "types";

const DEFAULT_CHILD_SEAT_IMAGE_URL_MAP = {
  [ChildSeatVariant.ForwardFacing]:
    "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/child_seat_images%2Fforward-facing.png?alt=media&token=f9126402-57f5-406d-8aa6-2bec5ecc15ff",
  [ChildSeatVariant.RearFacing]:
    "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/child_seat_images%2Frear-facing.png?alt=media&token=27b5272c-864e-46cb-9326-a689d52f7628",
  [ChildSeatVariant.Booster]:
    "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/child_seat_images%2Fbooster.png?alt=media&token=7d78ab1f-5d21-4a39-b816-4fe7dd3c34ba",
};

export const validateRequiredInfo = (
  vehicle: CreateVehicle
): VehicleRequiredInfoError => {
  let errors: VehicleRequiredInfoError = {};

  if (!vehicle.name) {
    errors = { ...errors, name: "Please enter vehicle name" };
  }

  if (!vehicle.typeSlug) {
    errors = { ...errors, typeSlug: "Please select vehicle type" };
  }

  if (!vehicle.capacity) {
    errors = {
      ...errors,
      capacity: "Please enter vehicle passenger capacity",
    };
  }

  return errors;
};

export const validatePricing = (
  vehicle: CreateVehicle
): VehiclePricingError => {
  let errors: VehiclePricingError = {};

  // enforce all or nothing transfer logic
  if (
    isNumber(vehicle.minimumTotalBaseRate) ||
    isNumber(vehicle.deadheadRatePerMile) ||
    isNumber(vehicle.tripRatePerMile)
  ) {
    if (!isNumber(vehicle.minimumTotalBaseRate)) {
      errors = {
        ...errors,
        minimumTotalBaseRate: "Please enter or clear all transfer info",
      };
    }

    if (!isNumber(vehicle.deadheadRatePerMile)) {
      errors = {
        ...errors,
        deadheadRatePerMile: "Please enter or clear all transfer info",
      };
    }

    if (!isNumber(vehicle.tripRatePerMile)) {
      errors = {
        ...errors,
        tripRatePerMile: "Please enter or clear all transfer info",
      };
    }
  }

  // enforce all or nothing weekday logic
  if (
    isNumber(vehicle.weekdayHourlyCost) ||
    isNumber(vehicle.weekdayMinMinutes)
  ) {
    if (!isNumber(vehicle.weekdayHourlyCost)) {
      errors = {
        ...errors,
        weekdayHourlyCost: "Please enter or clear all weekday info",
      };
    }

    if (!isNumber(vehicle.weekdayMinMinutes)) {
      errors = {
        ...errors,
        weekdayMinMinutes: "Please enter or clear all weekday info",
      };
    }
  }

  // enforce all or nothing weekend logic
  if (
    isNumber(vehicle.weekendHourlyCost) ||
    isNumber(vehicle.weekendMinMinutes) ||
    vehicle.settings.weekends.length > 0
  ) {
    if (!isNumber(vehicle.weekendHourlyCost)) {
      errors = {
        ...errors,
        weekendHourlyCost: "Please enter or clear all weekend info ",
      };
    }

    if (!isNumber(vehicle.weekendMinMinutes)) {
      errors = {
        ...errors,
        weekendMinMinutes: "Please enter or clear all weekend info",
      };
    }

    if (vehicle.settings.weekends.length === 0) {
      errors = {
        ...errors,
        settings: "Please enter or clear all weekend info",
      };
    }
  }

  // enforce BRA logic
  if (vehicle.enableBaseRateAutomation) {
    if (!isNumber(vehicle.weekdayHourlyCost)) {
      errors = {
        ...errors,
        weekdayHourlyCost: "Please enter weekday hourly rate to enable BRA",
      };
    }

    if (!isNumber(vehicle.weekdayMinMinutes)) {
      errors = {
        ...errors,
        weekdayMinMinutes: "Please enter min. minutes to enable BRA",
      };
    }
  }

  return errors;
};

export const validateChildSeats = (vehicle: CreateVehicle) => {
  let errors: VehicleChildSeatsError = {};

  if (vehicle.settings.forwardFacingSeat.description?.length > 35) {
    errors = {
      ...errors,
      forwardFacingSeat: {
        description: "Cannot exceed character limit of 35",
      },
    };
  }

  if (vehicle.settings.rearFacingSeat.description?.length > 35) {
    errors = {
      ...errors,
      rearFacingSeat: {
        description: "Cannot exceed character limit of 35",
      },
    };
  }

  if (vehicle.settings.boosterSeat.description?.length > 35) {
    errors = {
      ...errors,
      boosterSeat: {
        description: "Cannot exceed character limit of 35",
      },
    };
  }

  return errors;
};

export const getInitialChildSeat = (
  type: ChildSeatVariant
): VehicleChildSeat => ({
  id: type,
  type,
  active: false,
  quantity: 1,
  amt: 0,
  imageUrl: DEFAULT_CHILD_SEAT_IMAGE_URL_MAP[type],
  description: null,
});
