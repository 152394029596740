import React from "react";

import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

function TripsColumn(props: GridRenderCellParams<string>) {
  const { row } = props;

  return <Typography variant="body2">{row.tripCount}</Typography>;
}

export default TripsColumn;
