import React from "react";

import { Box, Skeleton } from "@mui/material";

import MissingImage from "../../../../design-system/images/MissingImage.png";
import { moovsBlue, white } from "../../../../design-system/colors";
import { CheckIcon } from "../../../../design-system/icons";
import { useScreenSize } from "../../../../globals/hooks";

type SelectableImageWrapperProps = {
  photo: any;
  isSelected: boolean;
  onImageSelect: (photoIndex: number, isSelected: boolean) => void;
  onImageLoaded: () => void;
  allImagesLoaded: boolean;
  isNewImage: boolean;
};

function SelectableImageWrapper(props: SelectableImageWrapperProps) {
  const {
    photo,
    isSelected,
    onImageSelect,
    onImageLoaded,
    allImagesLoaded,
    isNewImage,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  const showCheckbox = allImagesLoaded && !isNewImage;
  const imageWidth = isMobileView ? 144 : 200;
  const imageHeight = isMobileView ? 108 : 150;
  const checkboxDimension = isMobileView ? 18 : 24;
  const checkboxPosition = isMobileView ? 12 : 16;

  return (
    <Box
      p={0}
      position="relative"
      border="none"
      style={{ cursor: "pointer" }}
      component="button"
      {...(showCheckbox && {
        onClick: () => onImageSelect(photo.photoIndex, !isSelected),
      })}
    >
      <img
        onLoad={onImageLoaded}
        style={{
          borderRadius: "4px",
          border: "1px solid rgba(115, 156, 255, 0.3)",
          objectFit: "cover",
          display: allImagesLoaded ? "block" : "none",
        }}
        width={imageWidth}
        height={imageHeight}
        src={photo.url === "blank" ? MissingImage : photo.url}
        onError={(e: any) => (e.target.src = MissingImage)}
        alt={photo.url || "broken"}
      />
      {showCheckbox ? (
        <Box
          position="absolute"
          top={checkboxPosition}
          right={checkboxPosition}
          borderRadius="2px"
          width={checkboxDimension}
          height={checkboxDimension}
          border={`2px solid ${moovsBlue}`}
          bgcolor={isSelected ? moovsBlue : white}
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ cursor: "pointer" }}
        >
          {isSelected && <CheckIcon size="small" color={white} />}
        </Box>
      ) : (
        <Box
          style={{
            overflow: "hidden",
          }}
        >
          <Skeleton
            sx={{ "& .MuiSkeleton-text": { transform: "none" } }}
            width={imageWidth}
            height={imageHeight}
          />
        </Box>
      )}
    </Box>
  );
}

export default SelectableImageWrapper;
