import { ConfirmReservationDisabledVariantEnum } from "types";

const {
  NoPricing,
  NoVehicle,
  RequestArchived,
  RequestExpired,
  ReservationCutoff,
} = ConfirmReservationDisabledVariantEnum;

const tooltipTextMap = {
  [NoPricing]:
    "Can't send quote to customer when pricing is missing from at least one trip",
  [NoVehicle]:
    "Can't send quote to customer when a vehicle is not assigned to every trip",
  [RequestArchived]: "Can't send quote to customer when quote is archived",
  [RequestExpired]:
    "Can't send quote to customer if pickup date is in the past",
  [ReservationCutoff]:
    "Can't send quote to customer after reservation cutoff date",
};

export const getConfirmReservationDisabledTooltip = (
  variant: ConfirmReservationDisabledVariantEnum
) => {
  if (!variant) return null;

  return tooltipTextMap[variant];
};
