import React from "react";
import moment from "moment-timezone";

import { Card, Box, Typography, IconButton } from "@mui/material";
import RequestCheck from "./RequestCheck";
import { Request } from "../../../../types";
import { OpenIcon } from "../../../../design-system/icons";
import { currency } from "../../../../globals/utils/helpers";

type InvoiceItemsListItemMobileProps = {
  request: Request;
  onRequestCheckClick?: (request: Request) => void;
  onNavigateToRequestClick: (requestId: string) => void;
};

function InvoiceItemsListItemMobile(props: InvoiceItemsListItemMobileProps) {
  const { request, onRequestCheckClick, onNavigateToRequestClick } = props;
  const { orderNumber, id, trips, company, bookingContact } = request;

  const totalAmount = trips.reduce((totalAmount, trip) => {
    return totalAmount + trip.totalAmount;
  }, 0);

  const amountDue = trips.reduce((amountDue, trip) => {
    if (!trip.cancelledAt) {
      return amountDue + trip.amountDue;
    }
    return amountDue;
  }, 0);

  return (
    <Box mb={1}>
      <Card variant="outlined">
        <Box py={1.5} px={2}>
          <Box display="flex" flex="1">
            <Box
              display="flex"
              flex="1"
              alignItems="center"
              flexDirection="row"
            >
              {onRequestCheckClick && (
                <RequestCheck
                  request={request}
                  onRequestCheckClick={onRequestCheckClick}
                />
              )}

              <Box ml={2} display="flex" flexDirection="column">
                <Box mb={1}>
                  <Typography variant="overline">Order #</Typography>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {orderNumber}
                  </Typography>
                </Box>
                {company && (
                  <Box mb={1}>
                    <Typography variant="overline">Booking Contact</Typography>
                    <Typography variant="body2">
                      {`${bookingContact.firstName} ${bookingContact.lastName}`}
                    </Typography>
                  </Box>
                )}
                <Box>
                  <Typography variant="overline">Tot. Amount</Typography>
                  <Typography variant="body2">
                    {currency(totalAmount)}
                  </Typography>
                </Box>
              </Box>
              <Box ml={2} display="flex" flexDirection="column">
                <Box mb={1}>
                  <Typography variant="overline">Date</Typography>
                  <Typography variant="body2">
                    {moment.utc(trips[0].stops[0].dateTime).format("MM/DD/YY")}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="overline">Amount Due</Typography>
                  <Typography variant="body2">{currency(amountDue)}</Typography>
                </Box>
              </Box>
              <Box my={0.5} right={20}>
                <IconButton
                  onClick={() => onNavigateToRequestClick(id)}
                  size="large"
                >
                  <OpenIcon size="small" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export default InvoiceItemsListItemMobile;
