import React from "react";

import { Box, Dialog, Button, IconButton } from "@mui/material";

import { grayDark, moovsBlue, white } from "../../../design-system/colors";
import { CrossIcon, DownloadIcon } from "../../../design-system/icons";
import theme from "../../../theme";
import { MediaVariantEnum } from "../utils";
import { useScreenSize } from "../../../globals/hooks";
import { DraggableComponent } from "components/DraggableComponent";

type MediaMessageLightBoxProps = {
  mediaVariant:
    | MediaVariantEnum.Image
    | MediaVariantEnum.Video
    | MediaVariantEnum.PDF
    | MediaVariantEnum.Invalid;
  url: string;
  open: boolean;
  onDialogClose: (event) => void;
  onDownload?: (event) => void;
};

const MediaMessageLightBox = (props: MediaMessageLightBoxProps) => {
  const { mediaVariant, url, open, onDialogClose, onDownload } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      maxWidth="md"
      sx={{
        "& .MuiDialog-paper": {
          width: "65%",
          [theme.breakpoints.down("sm")]: {
            width: "80%",
          },
        },
      }}
      {...(!isMobileView && {
        PaperComponent: (paperProps) =>
          DraggableComponent({
            paperProps,
            handleName: "imageMedia",
          }),
      })}
      aria-labelledby={"imageMedia"}
    >
      <Box
        display="flex"
        id={"imageMedia"}
        width="960px" // equal to md breakpoint
        justifyContent="space-between"
        position="absolute"
        top={theme.spacing(1)}
        right={theme.spacing(1)}
      >
        <Box />
        <Box>
          <IconButton onClick={onDialogClose} size="large">
            <CrossIcon color={grayDark} />
          </IconButton>
        </Box>
      </Box>
      <Box
        color={white}
        display="flex"
        flex={1}
        maxHeight="100vh"
        flexDirection="column"
        alignItems="center"
        px={4}
        {...(!!onDownload ? { pt: 9, pb: 4 } : { py: 9 })}
        style={{ cursor: "default" }}
      >
        {mediaVariant === MediaVariantEnum.Image ? (
          <img
            style={{
              maxHeight: "60vh",
              maxWidth: isMobileView ? "60vw" : "50vw",
            }}
            src={url}
            alt={url}
          />
        ) : (
          <video
            width="90%"
            height={!isMobileView ? 500 : 400}
            controls
            autoPlay
            style={{
              margin: "auto",
              display: "block",
            }}
          >
            <source src={url} />
          </video>
        )}
        {!!onDownload && (
          <Box mt={4}>
            <Button
              onClick={onDownload}
              startIcon={<DownloadIcon color={moovsBlue} size="small" />}
              variant="outlined"
              color="primary"
              component="a"
              href={url}
              download
            >
              Download
            </Button>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default MediaMessageLightBox;
