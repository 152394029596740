import React, { Dispatch, SetStateAction } from "react";
import { Box, SelectChangeEvent, Typography } from "@mui/material";

import MoovsSelect from "components/MoovsSelect";
import { useScreenSize } from "globals/hooks";
import { DashboardView } from "../DashboardPage";
import TimePeriodPicker from "components/dashboard/TimePeriodPicker";

type DashboardSelectorHeaderProps = {
  view: string;
  onHandleViewChange: (event: SelectChangeEvent<DashboardView>) => void;
  isVip: boolean;
  defaultMenuOptions: DashboardView[];
  setStartDate: Dispatch<SetStateAction<moment.Moment>>;
  setEndDate: Dispatch<SetStateAction<moment.Moment>>;
};

function DashboardSelectorHeader(props: DashboardSelectorHeaderProps) {
  const {
    view,
    onHandleViewChange,
    isVip,
    defaultMenuOptions,
    setStartDate,
    setEndDate,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Box
      display="flex"
      flexDirection={isMobileView ? "column" : "row"}
      justifyContent="space-between"
      {...(!isMobileView && { alignItems: "center" })}
      py={2}
    >
      <Typography
        variant="h1"
        sx={{ ...(isMobileView ? { pb: 2 } : { mr: 3 }) }}
      >
        Dashboard
      </Typography>

      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        flexDirection={isMobileView ? "column" : "row"}
      >
        <MoovsSelect
          title="dashboard-view"
          value={view}
          onChange={onHandleViewChange}
          items={
            isVip
              ? [...defaultMenuOptions, DashboardView.CORPORATE_SHUTTLE]
              : defaultMenuOptions
          }
          sx={{ minWidth: 200, mb: isMobileView ? 1 : 0 }}
        />

        <Box
          display="flex"
          flexDirection={isMobileView ? "column" : "row"}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Typography
            variant="body2"
            width="100%"
            fontWeight={700}
            sx={{ ...(isMobileView ? { pb: 1 } : { mr: 3 }) }}
          >
            Trip Date
          </Typography>
          <TimePeriodPicker
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardSelectorHeader;
