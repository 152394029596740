import React from "react";

import { Card, CardActionArea, Box, Typography } from "@mui/material";

import { black, granite } from "../../../design-system/colors";
import { Company } from "../../../types";
import MoovsAvatar from "../../../design-system/components/MoovsAvatar";
import { formatPhoneNumber } from "globals/utils/phoneNumberFormatter/phoneNumberFormatter";

type CompaniesListItemDesktopProps = {
  company: Company;
  onClickCompany: (companyId: string) => void;
};

function CompaniesListItemDesktop(props: CompaniesListItemDesktopProps) {
  const { company, onClickCompany } = props;

  return (
    <Card variant="outlined">
      <CardActionArea disableRipple onClick={() => onClickCompany(company.id)}>
        <Box
          display="flex"
          flexDirection="row"
          flex="1"
          py={1.5}
          px={3}
          height="88px"
          alignItems="center"
        >
          {/* Company Name */}
          <Box display="flex" flex="2" alignItems="center">
            <MoovsAvatar
              alt="contact, company"
              size="medium"
              placeholder={company.name}
              shape="rectangle"
              src={company.companyLogoUrl}
            />

            <Box ml={2}>
              <Typography sx={{ fontWeight: 700, color: black, fontSize: 16 }}>
                {company.name}
              </Typography>
            </Box>
          </Box>

          {/* Company Email */}
          <Box display="flex" flex="2">
            <Typography variant="body2" sx={{ color: granite }}>
              {company.email || "-"}
            </Typography>
          </Box>

          {/* Company Phone Number */}
          <Box display="flex" flex="1">
            <Typography variant="body2" sx={{ color: granite }}>
              {company.phoneNumber
                ? formatPhoneNumber(company.phoneNumber).formatted
                : "-"}
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default CompaniesListItemDesktop;
