import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";

import { Box, Button, Typography } from "@mui/material";

import MoovsDialog from "../../../MoovsDialog";
import SelectPhoneNumberDialog from "./SelectPhoneNumberDialog";
import { errorRed, granite, moovsBlue } from "../../../../design-system/colors";
import {
  PROVISION_PHONE_NUMBER_TWILIO_MUTATION,
  UPDATE_OPERATOR_MUTATION,
} from "../../../../globals/graphql";
import { useChat } from "globals/hooks/useChat";
import { Operator } from "types";
import { useDebounce } from "use-debounce";
import { useScreenSize, useSnackbar } from "globals/hooks";
import InternationalPhoneInput from "components/inputs/InternationalPhoneInput";

type EnableChatFlowProps = {
  isOnboarding?: boolean;
  operatorData: Operator;
  setSaveIndicatorState?: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function EnableChatFlow(props: EnableChatFlowProps) {
  const { isOnboarding, operatorData, setSaveIndicatorState } = props;

  // hooks
  const { onRefetchToken } = useChat();
  const flag = useRef(false);
  const snackbar = useSnackbar();
  const { isMobileView } = useScreenSize();

  // state
  const [addPhoneNumberDialogOpen, setAddPhoneNumberDialogOpen] =
    useState(false);
  const [selectPhoneNumberDialogOpen, setSelectPhoneNumberDialogOpen] =
    useState(false);
  const [confirmPhoneNumberDialogOpen, setConfirmPhoneNumberDialogOpen] =
    useState(false);
  const [errorPhoneNumberDialogOpen, setErrorPhoneNumberDialogOpen] =
    useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);
  const [confirmPhoneNumberDisabled, setConfirmPhoneNumberDisabled] =
    useState(false);
  const [operator, setOperator] = useState(null);
  const [debouncedOperator] = useDebounce(operator, 500);

  // mutations
  const [provisionPhoneNumber] = useMutation(
    PROVISION_PHONE_NUMBER_TWILIO_MUTATION,
    {
      onError(error) {
        setConfirmPhoneNumberDisabled(false);
        setConfirmPhoneNumberDialogOpen(false);
        setErrorPhoneNumberDialogOpen(true);
      },
      onCompleted() {
        // refresh twilio token once phone number is provisioned
        // so that chat works without having to refresh app
        onRefetchToken();
      },
    }
  );
  const [updateOperator] = useMutation(UPDATE_OPERATOR_MUTATION, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error("Error updating operator");

      setOperator(operatorData);
      flag.current = false;
    },
  });

  // useCallbacks
  const handleDebouncedOperatorUpdate = useCallback(() => {
    setSaveIndicatorState("loading");

    updateOperator({
      variables: {
        input: {
          voicePhoneNumber: debouncedOperator.voicePhoneNumber,
          voicePhoneCountryCode: debouncedOperator.voicePhoneCountryCode,
          voicePhoneCountryDialCode:
            debouncedOperator.voicePhoneCountryDialCode,
          voicePhoneCountryName: debouncedOperator.voicePhoneCountryName,
          voicePhoneCountryFormat: debouncedOperator.voicePhoneCountryFormat,
        },
      },
    });
  }, [debouncedOperator, updateOperator, setSaveIndicatorState]);

  // event handlers
  const handleAddPhoneNumberClick = () => {
    setSelectPhoneNumberDialogOpen(true);
    setAddPhoneNumberDialogOpen(false);
  };

  const handlePhoneNumberSelect = (phoneNumber: string) => {
    setSelectedPhoneNumber(phoneNumber);
  };

  const handlePhoneNumberNextClick = () => {
    setConfirmPhoneNumberDialogOpen(true);
    setSelectPhoneNumberDialogOpen(false);
  };

  const handleConfirmPhoneNumberClick = () => {
    setConfirmPhoneNumberDisabled(true);

    provisionPhoneNumber({
      variables: {
        input: {
          phoneNumber: selectedPhoneNumber,
        },
      },
    });
  };

  const handleErrorAcceptClick = () => {
    setErrorPhoneNumberDialogOpen(false);
    setSelectPhoneNumberDialogOpen(true);
  };

  const handleVoicePhoneNumberChange = (value, country) => {
    setOperator({
      ...operator,
      voicePhoneNumber: value,
      voicePhoneCountryCode: country.countryCode,
      voicePhoneCountryDialCode: country.dialCode,
      voicePhoneCountryName: country.name,
      voicePhoneCountryFormat: country.format,
    });
  };

  // effects
  // handle update operator debounce
  useEffect(() => {
    if (!debouncedOperator) return;

    if (!flag.current) {
      flag.current = true;
    } else {
      handleDebouncedOperatorUpdate();
    }
  }, [debouncedOperator, handleDebouncedOperatorUpdate]);

  // set initial state
  useEffect(() => {
    if (operatorData && !operator) {
      flag.current = false;
      setOperator(operatorData);
    }
  }, [operatorData, operator, setOperator]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems={isOnboarding ? "center" : undefined}
      >
        <Box mb={1.5}>
          <Button
            variant="outlined"
            color="primary"
            component="span"
            onClick={() => setAddPhoneNumberDialogOpen(true)}
          >
            Get a Moovs Number
          </Button>
        </Box>
        <Typography
          variant="caption"
          style={{ color: isOnboarding ? moovsBlue : granite }}
          textAlign={isOnboarding ? "center" : undefined}
        >
          This will allow you to send automated texts to drivers and receive
          texts. All calls and texts to this number will be forwarded to all
          members phone numbers.
        </Typography>
      </Box>

      <Box my={4}>
        <InternationalPhoneInput
          value={operator?.voicePhoneNumberInternational}
          onChange={handleVoicePhoneNumberChange}
          dropdownWidth={isMobileView ? "600%" : "1220%"}
          sx={{ width: "50%" }}
        />
      </Box>

      {/* Add Phone Number Dialog */}
      <MoovsDialog
        hideTopBorder
        size="xs"
        open={addPhoneNumberDialogOpen}
        onClose={() => setAddPhoneNumberDialogOpen(false)}
        onAccept={handleAddPhoneNumberClick}
        dialogTitle="Add Phone Number"
        acceptButtonText="Get a Number"
        closeButtonText="Cancel"
      >
        <Box mb={2}>
          Get local, mobile numbers so your customers can call and text you.
        </Box>
      </MoovsDialog>

      {/* Select Phone Number Dialog */}
      <SelectPhoneNumberDialog
        open={selectPhoneNumberDialogOpen}
        onClose={() => setSelectPhoneNumberDialogOpen(false)}
        onPhoneNumberSelect={handlePhoneNumberSelect}
        selectedPhoneNumber={selectedPhoneNumber}
        onPhoneNumberNextClick={handlePhoneNumberNextClick}
      />

      {/* Confirm Phone Number Dialog */}
      <MoovsDialog
        hideTopBorder
        size="xs"
        open={confirmPhoneNumberDialogOpen}
        onClose={() => setConfirmPhoneNumberDialogOpen(false)}
        onAccept={handleConfirmPhoneNumberClick}
        dialogTitle="Confirm Number"
        acceptButtonText="Confirm"
        closeButtonText="Cancel"
        acceptDisabled={confirmPhoneNumberDisabled}
      >
        <Box display="flex" flexDirection="column" mb={2}>
          <Typography style={{ fontWeight: 700 }} variant="subtitle1">
            {selectedPhoneNumber?.friendlyName}
          </Typography>
          {selectedPhoneNumber?.locality && (
            <Typography variant="body2">
              {`${selectedPhoneNumber?.locality}, ${selectedPhoneNumber?.region}`}
            </Typography>
          )}
        </Box>
      </MoovsDialog>

      {/* Phone Number Error Dialog */}
      <MoovsDialog
        hideTopBorder
        size="xs"
        open={errorPhoneNumberDialogOpen}
        onClose={() => setErrorPhoneNumberDialogOpen(false)}
        onAccept={handleErrorAcceptClick}
        dialogTitle="Error Adding Number"
        acceptButtonText="Get a Number"
        closeButtonText="Cancel"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mb={2}
        >
          <Typography
            style={{
              fontWeight: 700,
              color: errorRed,
              marginBottom: "16px",
            }}
            variant="subtitle1"
          >
            {selectedPhoneNumber?.friendlyName}
          </Typography>

          <Typography variant="body2">
            Error adding phone number. Please go back to list to confirm a new
            number.
          </Typography>
        </Box>
      </MoovsDialog>
    </>
  );
}

export default EnableChatFlow;
