import React, { useState, Fragment, useEffect, useCallback } from "react";
import { DeepPartial } from "ts-essentials";
import { useMutation } from "@apollo/client";
import first from "lodash/first";
import isEqual from "lodash/isEqual";
import size from "lodash/size";
import cloneDeep from "lodash/cloneDeep";

import { useHistory, useLocation } from "react-router-dom";
import { parse, stringify } from "query-string";

import { Box, Typography } from "@mui/material";

import {
  Request,
  Trip,
  Vehicle,
  RoutePricing,
  TripCategory,
  RequestStage,
  ShuttleRoute,
  VehicleChildSeat,
  Contact,
} from "types";
import { useSnackbar, useScreenSize } from "globals/hooks";
import { CREATE_TRIP_MUTATION, LOAD_REQUEST_QUERY } from "globals/graphql";
import CreateDrawer from "../../../globals/CreateDrawer";
import AddVehicleCreateBlock from "../../../vehicles/AddVehicleCreateBlock";
import CommentCreateBlock from "../../../CommentCreateBlock";
import { useLocalStorage } from "globals/hooks/useLocalStorage";
import { useOperator } from "globals/hooks";
import { calculateTotalDurationMinutes } from "globals/utils/stops";
import {
  initialRequestTemplate,
  initialRequestErrors,
  convertTripToBeInputReady,
  removeStop,
  createStop,
} from "globals/utils/createRequests";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { initialLuggageFields } from "components/requests/luggage/initialValues";
import { useCreateRequestTripType } from "./hooks/useCreateRequestTripType";
import ShuttleTypeButtonsBlock from "components/requests/ShuttleTypeButtonsBlock";
import {
  AdditionalInfoCreateBlock,
  PricingInfoCreateBlock,
  TripInfoCreateBlock,
} from "./components";
import { parsePricingLayoutForInitialRoute } from "./helpers/parsePricingLayoutForInitialRoute";
import ShuttleRouteAutoComplete from "components/autocompletes/ShuttleRouteAutoComplete";
import ShuttleRouteInfoCard from "components/globals/ShuttleRouteInfoCard";
import CreateAndAddShuttleRouteToRequestDialog from "components/requests/CreateAndAddShuttleRouteToRequestDialog";
import { TrashIcon } from "design-system/icons";
import { getChildSeatOptionsFromVehicle } from "./helpers/getChildSeatOptionsFromVehicle";
import { getContactForReservation } from "globals/utils/contact";

export type CreateShuttleTripDrawerProps = {
  isDuplicateTrip?: boolean;
};

const SHUTTLE_TRIP_CATEGORIES = [
  TripCategory.ShuttlePickUp,
  TripCategory.ShuttleReturn,
];

function CreateShuttleTripDrawer(props: CreateShuttleTripDrawerProps) {
  // props
  const { isDuplicateTrip } = props;

  // hooks
  const snackbar = useSnackbar();
  const { isMobileView } = useScreenSize();
  const history = useHistory();
  const location = useLocation<{ request: Request; trip: Trip }>();
  const { settings } = useOperator() || {};

  const orderNumber = location?.state?.request?.orderNumber;
  const companyId = location?.state?.request?.company?.id;
  const bookingContact = location?.state?.request?.bookingContact;

  const pageLabel = `Add Trip to ${orderNumber}`;
  const localStorageKey = "submitReservationSettings";
  const getCloseDrawerLink = (requestId: string) =>
    `/reservations/${requestId}`;

  // state
  const [request, setRequest] = useState<DeepPartial<Request>>(
    JSON.parse(JSON.stringify(initialRequestTemplate))
  );
  const [requestErrors, setRequestErrors] = useState(
    JSON.parse(JSON.stringify(initialRequestErrors))
  );

  const [saveErrorText, setSaveErrorText] = useState("");
  const [selectedSubmitButtonIndex, setSelectedSubmitButtonIndex] =
    useLocalStorage(localStorageKey, 0);
  const [suggestedAddressInfo, setSuggestedAddressInfo] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [luggageAmount, setLuggageAmount] = useState(initialLuggageFields);
  // allows initial dateTimes of stops that show up
  // when you open dateTimePicker but not in field
  // used for duplicate trip functionality
  const [initialFocusedStopDateTimes, setInitialFocusedStopDateTimes] =
    useState<string[]>([]);
  const [shuttleRoute, setShuttleRoute] =
    useState<DeepPartial<ShuttleRoute>>(null);
  const [createNewShuttleRouteDialogOpen, setCreateNewShuttleRouteDialogOpen] =
    useState(false);
  const [shuttleRouteCarryOverSearch, setShuttleRouteCarryOverSearch] =
    useState("");
  const [childSeatOptions, setChildSeatOptions] = useState<VehicleChildSeat[]>(
    []
  );

  // more hooks
  const { tripType, onTripTypeChange, submitLabelOptions } =
    useCreateRequestTripType({
      enabledTripCategories: SHUTTLE_TRIP_CATEGORIES,
      setRequest,
      initialFocusedStopDateTimes,
      defaultSubmitLabelOptions: ["Save trip"],
      ...(isDuplicateTrip && { inputTrip: location?.state?.trip }),
    });

  // derived state
  const shouldSendEmail = selectedSubmitButtonIndex === 1;

  // mutations
  const [createTrip] = useMutation(CREATE_TRIP_MUTATION, {
    onCompleted(data) {
      snackbar.success("Successfully added trip!");

      handleCreateRequestDrawerClose(data.createTrip.trip.id);
    },
    onError(error) {
      setSubmitDisabled(false);
      const errorMessage = getErrorMessage(error) || "Error creating trip.";

      snackbar.error(errorMessage);
    },
    refetchQueries: [
      {
        query: LOAD_REQUEST_QUERY,
        variables: {
          id: request.id,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  // event handlers
  const updateTrip = useCallback(
    (newTrip: DeepPartial<Trip>, tripIndex: number) => {
      setRequest((prevRequest) => {
        const newTrips = [...prevRequest.trips];
        newTrips.splice(tripIndex, 1, newTrip);
        return {
          ...prevRequest,
          trips: newTrips,
        };
      });
    },
    []
  );

  const handleCreateRequestDrawerClose = (tripId?: string) => {
    let search = location.search;

    // if passed a tripId, makes sure that that tripId is showing when drawer closes
    if (tripId) {
      const parsed = parse(search);

      search = `?${stringify({
        ...parsed,
        tripId,
      })}`;
    }

    setTimeout(() => {
      history.push({
        search,
        pathname: getCloseDrawerLink(request.id),
      });
    }, 200);
  };

  const updateRoute =
    (tripIndex: number) =>
    (targetName: string, targetValue: number | string | boolean) => {
      const newRoutes = [...request.trips[tripIndex].routes];
      const newRoute = newRoutes[0];

      newRoute[targetName] = targetValue;
      const newTrip = { ...request.trips[tripIndex], routes: newRoutes };

      updateTrip(newTrip, tripIndex);
    };

  const handleCreateStop = (tripIndex: number) => () => {
    const newTrips = createStop(request.trips, tripIndex);

    const stopsErrors = [...requestErrors.stops];
    const lastStopErrors = stopsErrors.pop();
    requestErrors.stops = [...requestErrors.stops];

    setRequestErrors({
      ...requestErrors,
      stops: [
        ...stopsErrors,
        {
          location: "",
          dateTime: "",
          stopIndex: requestErrors.stops.length,
        },
        {
          ...lastStopErrors,
          stopIndex: requestErrors.stops.length + 1,
        },
      ],
    });

    setRequest({
      ...request,
      trips: newTrips,
    });
  };

  const handleRemoveStop = (tripIndex: number) => (stopIndex: number) => () => {
    const newTrips = removeStop(request.trips, tripIndex, stopIndex);

    setRequest({
      ...request,
      trips: newTrips,
    });

    requestErrors.stops.splice(stopIndex - 1, 1);

    setRequestErrors({
      ...requestErrors,
      stops: requestErrors.stops,
    });
  };

  const addVehicles = (tripIndex: number) => (vehicles: Partial<Vehicle>[]) => {
    const newTrips = [...request.trips];
    newTrips[tripIndex].routes[0].vehicle = { id: vehicles[0].id };

    const childSeats = getChildSeatOptionsFromVehicle(vehicles[0]);
    setChildSeatOptions(childSeats);

    setRequest({
      ...request,
      trips: newTrips,
    });
  };

  const removeVehicle = (tripIndex: number) => () => {
    const newTrips = [...request.trips];
    delete newTrips[tripIndex].routes[0].vehicle;
    setChildSeatOptions([]);

    setRequest({
      ...request,
      trips: newTrips,
    });
  };

  const handleSubmitClick = () => {
    let errors = [];

    const newSaveErrorText = "Oops! Please check the above fields for errors.";

    // shuttle route errors
    if (!shuttleRoute) {
      errors = [...errors, { shuttleRoute: "Please add a route name" }];
    }

    // vehicle errors
    if (!request.trips[0].routes[0].vehicle) {
      errors = [...errors, { vehicle: "Please select a vehicle" }];
    }

    // stop errors
    const stopErrors = request.trips[0].stops.map((stop) => {
      const addressErrors = {
        location: !stop.location ? "Please enter location" : "",
        locationAlias:
          // This is just to prevent form submission. The component handles the helper text
          stop.locationAlias && stop.locationAlias.length > 35
            ? "Cannot exceed character limit of 35"
            : "",
      };

      const airportErrors = {
        airport: !stop.airport ? "Please select an Airport" : "",
        airline:
          !!stop.flightNumber && !stop.airline
            ? "Please select an Airline"
            : "",
      };

      const isLocationError =
        !!addressErrors.location || !!addressErrors.locationAlias;
      const isAirportError = Object.values(airportErrors).some(
        (error) => !!error.length
      );

      const isPickUpDateTimeError = !stop.dateTime && stop.stopIndex === 1;
      const isDropOffDateTimeError =
        tripType === TripCategory.ShuttlePickUp &&
        !stop.dateTime &&
        stop.stopIndex === size(first(request.trips).stops);

      const isShuttleStopDateTimeError =
        tripType === TripCategory.ShuttlePickUp &&
        stop.stopIndex !== 1 &&
        stop.stopIndex !== size(first(request.trips).stops) &&
        !stop.dateTime;

      const dateTimeErrors = {
        dateTime:
          isPickUpDateTimeError || isDropOffDateTimeError
            ? "Please enter date"
            : isShuttleStopDateTimeError
            ? `Stop ${stop.stopIndex - 1} Date & Time is required`
            : "",
      };

      // if error in both show errors
      // if only one has an error that means it
      // isnt the variant that is selected
      if (isLocationError && isAirportError) {
        return {
          ...addressErrors,
          ...airportErrors,
          ...dateTimeErrors,
        };
      }

      return {
        ...dateTimeErrors,
      };
    });

    const isErrorStops = stopErrors.some((error) =>
      Object.values(error).some((value) => !!value)
    );

    if (errors.length > 0 || isErrorStops) {
      errors = [...errors, { stops: stopErrors }];

      setRequestErrors(
        errors.reduce((acc, value) => ({ ...acc, ...value }), {})
      );

      setSaveErrorText(newSaveErrorText);
      return;
    }

    // sets type as input type (as stop input is different than stop type)
    const requestInput: DeepPartial<Request> = cloneDeep(request);

    // converts airport and airlines from objects to just the code for input
    requestInput.trips = requestInput.trips.map((trip: Trip) =>
      convertTripToBeInputReady(trip, luggageAmount)
    );

    // set default booking contact if nothing is picked
    requestInput.trips = requestInput.trips.map((trip: Trip) => {
      if (!trip.contact?.firstName) {
        trip.contact = getContactForReservation(
          bookingContact as Contact
        ) as Contact;
      } else {
        trip.contact = getContactForReservation(trip.contact) as Contact;
      }
      return trip;
    });
    setSaveErrorText("");
    setSubmitDisabled(true);

    const addTripInput = {
      requestId: requestInput.id,
      trip: {
        ...requestInput.trips[0],
        tripType,
        shuttleRouteId: shuttleRoute.id,
      },
      shouldSendEmail,
      mode: RequestStage.Reservation,
      comments: requestInput.trips[0].comments,
    };

    // remove unneeded trip contact fields
    delete addTripInput.trip.contact.homeAddress;
    delete addTripInput.trip.contact.workAddress;

    createTrip({
      variables: {
        input: addTripInput,
      },
    });
  };

  const handleTripCommentChange =
    (trip: Trip, tripIndex: number) => (value: string) => {
      updateTrip(
        {
          ...trip,
          comments: [{ bodyText: value }],
        },
        tripIndex
      );
    };

  const handleClearShuttleRoute = () => {
    setShuttleRoute(null);
  };

  const handleShuttleRouteSelect = (shuttleRoute: ShuttleRoute) => {
    setShuttleRoute(shuttleRoute);
    setRequestErrors({
      ...requestErrors,
      shuttleRoute: "",
    });
  };

  // Resets shuttleRoute in case user has already selected a
  // shuttleRoute and then decides to create new shuttleRoute
  const handleCreateNewShuttleRouteClick = () => {
    handleClearShuttleRoute(); // clear request state
    setCreateNewShuttleRouteDialogOpen(true);
  };

  const handleShuttleRouteCreated = (shuttleRoute: ShuttleRoute) => {
    setShuttleRoute(shuttleRoute);
  };

  // effects
  // suggested addresses
  useEffect(() => {
    let keys = [];
    request.trips.forEach((trip) => {
      if (trip.contact.workAddress) {
        keys.push({
          address: trip.contact.workAddress,
          firstName: trip.contact.firstName,
          lastName: trip.contact.lastName,
          mode: "workAddress",
        });
      }
      if (trip.contact.homeAddress) {
        keys.push({
          address: trip.contact.homeAddress,
          firstName: trip.contact.firstName,
          lastName: trip.contact.lastName,
          mode: "homeAddress",
        });
      }
    });

    if (!isEqual(keys, suggestedAddressInfo)) {
      setSuggestedAddressInfo(keys);
    }
  }, [request, suggestedAddressInfo]);

  // redirect to correct url if missing state
  useEffect(() => {
    if (!location?.state?.request) {
      history.replace("/reservations");
    }
  }, [history, location]);

  // populate initial request with data from location state
  useEffect(() => {
    if (location?.state?.request) {
      const { request } = location.state;

      if (isDuplicateTrip) {
        const { trip } = location.state;

        const initialRequest = {
          id: request.id,
          type: request.orderType,
          trips: [
            {
              totalGroupSize: trip?.totalGroupSize,
              note: trip?.note,
              stops: trip?.stops.map((stop: any) => {
                return {
                  id: stop.id,
                  location: stop.location,
                  stopIndex: stop.stopIndex,
                  groupSize: stop.groupSize,
                  logisticStreetViewUrl: stop.logisticStreetViewUrl,
                  note: stop.note,
                  airport: stop.airport,
                  flightNumber: null,
                  airlineIcao: null,
                  airline: null,
                  dateTime: stop.dateTime,
                  variant: stop.airport ? "airport" : "address",
                };
              }),
              routes: trip?.routes.map((route: any) => {
                return {
                  vehicle: route.isFarmedRoute ? null : route.vehicle,

                  // pricing
                  baseRateAmt: route.pricing.baseRateAmt,
                  driverGratuityAmt: route.pricing.driverGratuityAmt,
                  driverGratuityPercent: route.pricing.driverGratuityPercent
                    ? route.pricing.driverGratuityPercent * 100
                    : null,
                  promoDiscountAmt: route.pricing.promoDiscountAmt,
                  promoDiscountPercent: route.pricing.promoDiscountPercent
                    ? route.pricing.promoDiscountPercent * 100
                    : null,
                  taxAmt: route.pricing.taxAmt,
                  taxPercent: route.pricing.taxPercent
                    ? route.pricing.taxPercent * 100
                    : null,
                  tollsAmt: route.pricing.tollsAmt || null,
                  meetGreetAmt: route.pricing.meetGreetAmt || null,
                  ...(route.pricing.otherName && {
                    otherName: route.pricing.otherName,
                  }),
                  otherAmt: route.pricing.otherAmt,
                  otherPercent: route.pricing.otherPercent
                    ? route.pricing.otherPercent * 100
                    : null,
                  ...(route.pricing.other2Name && {
                    other2Name: route.pricing.other2Name,
                  }),
                  other2Amt: route.pricing.other2Amt,
                  other2Percent: route.pricing.other2Percent
                    ? route.pricing.other2Percent * 100
                    : null,
                  ...(route.pricing.other3Name && {
                    other3Name: route.pricing.other3Name,
                  }),
                  other3Amt: route.pricing.other3Amt,
                  other3Percent: route.pricing.other3Percent
                    ? route.pricing.other3Percent * 100
                    : null,
                };
              }),
              contact: {
                id: trip.contact.id,
                firstName: trip.contact.firstName,
                lastName: trip.contact.lastName,
                email: trip.contact.email,
                mobilePhone: trip.contact.mobilePhone,
                workAddress: trip.contact.workAddress,
                homeAddress: trip.contact.homeAddress,
              },
              comments: [{ bodyText: "" }],
            },
          ],
        };

        setInitialFocusedStopDateTimes(
          trip.stops.map(({ dateTime }) => dateTime)
        );

        setRequest((prevRequest: any) => ({
          ...prevRequest,
          ...initialRequest,
        }));
      } else {
        setRequest((prevRequest) => {
          const trip = prevRequest.trips[0];

          return {
            ...prevRequest,
            id: request.id,
            trips: [
              {
                ...trip,
                contact: request.bookingContact,
                routes: trip.routes.map((route: any) => {
                  return {
                    ...route,
                    ...parsePricingLayoutForInitialRoute(
                      settings?.pricingLayout
                    ),
                  };
                }),
              },
            ],
          };
        });
      }
    }
  }, [location, updateTrip, isDuplicateTrip, settings?.pricingLayout]);

  return (
    <CreateDrawer
      onClose={handleCreateRequestDrawerClose}
      pageLabel={pageLabel}
      onSubmit={handleSubmitClick}
      saveError={!!saveErrorText}
      saveErrorText={saveErrorText}
      submitLabelOptions={submitLabelOptions}
      selectedSubmitButtonIndex={selectedSubmitButtonIndex}
      setSelectedSubmitButtonIndex={setSelectedSubmitButtonIndex}
      submitDisabled={submitDisabled}
    >
      <Box alignItems="flex-start" data-testid="create-request-drawer">
        <Box mt={2}>
          <Typography variant="h5" mb={2}>
            Route Name
          </Typography>
          {shuttleRoute ? (
            <ShuttleRouteInfoCard
              name={shuttleRoute.name}
              actionButtonIcon={<TrashIcon />}
              onClickActionButton={handleClearShuttleRoute}
            />
          ) : (
            <Box {...(!isMobileView && { width: "400px" })}>
              <ShuttleRouteAutoComplete
                companyId={companyId}
                onChange={handleShuttleRouteSelect}
                setCarryOverSearch={setShuttleRouteCarryOverSearch}
                onCreateNewShuttleRouteClick={handleCreateNewShuttleRouteClick}
                error={!!requestErrors.shuttleRoute}
                helperText={requestErrors.shuttleRoute}
                label="Enter Route name *"
              />
            </Box>
          )}
        </Box>
        <ShuttleTypeButtonsBlock
          onChange={onTripTypeChange}
          value={tripType}
          isDuplicateTrip={isDuplicateTrip}
        />

        {request.trips.map((trip: Trip, tripIndex: number) => (
          <Fragment key={tripIndex}>
            {/* Trip Details */}
            <Box my={4}>
              <TripInfoCreateBlock
                trip={trip}
                updateTrip={updateTrip}
                tripIndex={tripIndex}
                onCreateStop={handleCreateStop(tripIndex)}
                onRemoveStop={handleRemoveStop(tripIndex)}
                requestErrors={requestErrors}
                setRequestErrors={setRequestErrors}
                suggestedAddressInfo={suggestedAddressInfo}
                tripType={tripType}
                {...(isDuplicateTrip && {
                  initialFocusedStopDateTimes: initialFocusedStopDateTimes,
                })}
              />
            </Box>
            <Box my={4}>
              <AdditionalInfoCreateBlock
                trip={trip}
                updateTrip={updateTrip}
                tripIndex={tripIndex}
                requestType={request.orderType}
                setLuggageAmount={setLuggageAmount}
                luggageAmount={luggageAmount}
                isShuttleTrip
              />
            </Box>
            <AddVehicleCreateBlock
              requestErrors={requestErrors}
              addVehicles={addVehicles(tripIndex)}
              vehicle={trip.routes[0].vehicle}
              stops={trip.stops}
              isReplace={isDuplicateTrip ? true : false}
              disableBRA={true}
              {...(!isDuplicateTrip && {
                removeVehicle: removeVehicle(tripIndex),
              })}
            />
            <PricingInfoCreateBlock
              // initialized request template uses deprecated route pricing (pricing field on route type instead of on route.pricing)
              routePricing={trip.routes[0] as unknown as RoutePricing}
              updateRoute={updateRoute(tripIndex)}
              requestErrors={requestErrors}
              setRequestErrors={setRequestErrors}
              totalDurationMinutes={calculateTotalDurationMinutes(trip.stops)}
              baseRateAmtError={requestErrors.baseRateAmt}
              childSeatOptions={childSeatOptions}
            />
            <Box mt={7} mb={4}>
              <CommentCreateBlock
                commentBodyText={trip.comments[0]?.bodyText}
                onChangeComment={handleTripCommentChange(trip, tripIndex)}
              />
            </Box>
          </Fragment>
        ))}
      </Box>
      <CreateAndAddShuttleRouteToRequestDialog
        open={createNewShuttleRouteDialogOpen}
        onClose={() => setCreateNewShuttleRouteDialogOpen(false)}
        onShuttleRouteCreated={handleShuttleRouteCreated}
        companyId={companyId}
        carryOverSearch={shuttleRouteCarryOverSearch}
      />
    </CreateDrawer>
  );
}

export default CreateShuttleTripDrawer;
