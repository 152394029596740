import {
  parsePhoneNumberWithError,
  AsYouType,
  CountryCode,
  PhoneNumber,
} from 'libphonenumber-js';

const getAsYouTypePhoneNumber = (
  phoneNumber: string,
  defaultCountryCode: CountryCode,
): PhoneNumber => {
  const asYouType = new AsYouType(defaultCountryCode);
  asYouType.input(phoneNumber);
  return asYouType.getNumber();
};

export const parsePhoneNumber = (
  phoneNumber: string,
  countryCode?: string,
): PhoneNumber => {
  // capitalize country code as our db stores them as lower case
  const defaultCountryCode = countryCode
    ? (countryCode.toUpperCase() as CountryCode)
    : 'US';

  try {
    const parsedPhoneNumber = parsePhoneNumberWithError(
      phoneNumber,
      defaultCountryCode,
    );

    // if phone number is not valid number of digits,
    // parse via "as you type" which parses incomplete phone numbers
    if (!parsedPhoneNumber.isPossible()) {
      return getAsYouTypePhoneNumber(phoneNumber, defaultCountryCode);
    }

    return parsedPhoneNumber;
  } catch (error) {
    // if error, attempt as you type
    return getAsYouTypePhoneNumber(phoneNumber, defaultCountryCode);
  }
};
