import { useState, useEffect } from "react";
import size from "lodash/size";
import filter from "lodash/filter";

import { useAnalytics } from "globals/hooks";
import { parseInvalidEmails } from "globals/utils/email";
import { EmailModeEnum } from "types";
import { EmailRecipient } from "../SendEmailDialog";
import useSendEmailToMultipleCustomersMutation from "./useSendEmailToMultipleCustomersMutation";

const { Invoice } = EmailModeEnum;

type UseSendEmailBaseProps = {
  mode: EmailModeEnum;
  message: string;
  subject: string;
  toEmails: EmailRecipient[];
  ccEmails: EmailRecipient[];
  onToEmailsChange: (emails: EmailRecipient[]) => void;
  onCcEmailsChange: (emails: EmailRecipient[]) => void;
  onClose: () => void;
  onValidateSubject: () => boolean;
  pdfAttached?: boolean;
};

type UseSendEmailProps =
  | (UseSendEmailBaseProps & { requestId: string; invoiceId?: never })
  | (UseSendEmailBaseProps & { requestId?: never; invoiceId: string })
  | (UseSendEmailBaseProps & { requestId?: never; invoiceId?: never });

function useSendEmail(props: UseSendEmailProps) {
  const {
    mode,
    message,
    subject,
    toEmails,
    ccEmails,
    onToEmailsChange,
    onCcEmailsChange,
    onClose,
    onValidateSubject,
    requestId,
    invoiceId,
    pdfAttached,
  } = props;

  // state
  const [errorMessage, setErrorMessage] = useState("");

  // hooks
  const { track } = useAnalytics();

  const { onSendEmailToMultipleCustomers, sendEmailLoading } =
    useSendEmailToMultipleCustomersMutation({
      mode,
      onClose,
      message,
      subject,
      toEmails,
      ccEmails,
      requestId,
      invoiceId,
      pdfAttached,
    });

  const handleSendEmailClick = () => {
    if (mode === EmailModeEnum.Custom && !onValidateSubject()) {
      return;
    }

    // validate to ensure there is a minimal of 1 email
    if (!size(toEmails) && !size(ccEmails)) {
      setErrorMessage("No Email");
      return;
    }

    // validate the formats of toEmailList and ccEmailList (valid format: string@string.string)
    const parsedToEmails = parseInvalidEmails(toEmails);
    const parsedCcEmails = parseInvalidEmails(ccEmails);

    if (parsedToEmails.error || parsedCcEmails.error) {
      onToEmailsChange(parsedToEmails.emails);
      onCcEmailsChange(parsedCcEmails.emails);
      setErrorMessage("Invalid Email");
      return;
    }

    // tracking events
    if (toEmails.some((email) => !props.toEmails.includes(email))) {
      if (mode === Invoice) {
        track("sendInvoice_toAdded");
      } else {
        track("sendEmail_toAdded");
      }
    }

    if (ccEmails.some((email) => !props.ccEmails.includes(email))) {
      if (mode === Invoice) {
        track("sendInvoice_ccAdded");
      } else {
        track("sendEmail_ccAdded");
      }
    }

    if (message.length) {
      if (mode === Invoice) {
        track("sendInvoice_messageAdded");
      } else {
        track("sendEmail_messageAdded");
      }
    }

    setErrorMessage("");
    onSendEmailToMultipleCustomers();
  };

  // clear error messages
  useEffect(() => {
    if (!size(filter(toEmails, "error")) && !size(filter(ccEmails, "error"))) {
      setErrorMessage("");
    }
  }, [ccEmails, toEmails]);

  return {
    sendEmailLoading,
    onSendEmailClick: handleSendEmailClick,
    errorMessage,
  };
}

export default useSendEmail;
