/**
 * @file graphql/trackedFlight.graphql.ts
 * queries/mutations for tracked flights.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_POTENTIAL_TRACKED_FLIGHT_QUERY = gql`
  query LoadPotentialTrackedFlight(
    $flightNumber: Int!
    $firstStopDateTime: String!
    $departureAirport: String
    $arrivalAirport: String
    $airline: String!
  ) {
    loadPotentialTrackedFlight(
      flightNumber: $flightNumber
      firstStopDateTime: $firstStopDateTime
      arrivalAirport: $arrivalAirport
      departureAirport: $departureAirport
      airline: $airline
    ) {
      id
      updatedAt
      flightStatus
      actualFlightNumber
      flightNumber
      faFlightId
      airline {
        icaoCode
        iataCode
        airlineName
        callsign
        fleetSize
        countryName
        countryIso2
        dateFounded
      }
      actualAirline {
        icaoCode
        iataCode
        airlineName
        callsign
        fleetSize
        countryName
        countryIso2
        dateFounded
      }
      origin {
        id
        airport {
          icaoCode
          iataCode
          airportName
          countryName
          countryIso2
          timezone
          gmt
          coordinates
        }
        scheduledDateTime
        estimatedDateTime
        actualDateTime
        scheduledTerminal
        actualTerminal
        scheduledGate
        actualGate
      }
      destination {
        id
        airport {
          icaoCode
          iataCode
          airportName
          countryName
          countryIso2
          timezone
          gmt
          coordinates
        }
        scheduledDateTime
        estimatedDateTime
        actualDateTime
        scheduledTerminal
        actualTerminal
        scheduledGate
        actualGate
      }
    }
  }
`;

export const UPDATE_TRACKED_FLIGHT_MUTATION = gql`
  mutation UpdateTrackedFlight($input: UpdateTrackedFlightInput!) {
    updateTrackedFlight(input: $input) {
      trackedFlight {
        id
        faFlightId
        updatedAt
        airline {
          icaoCode
          airlineName
          iataCode
        }
        actualAirline {
          airlineName
          iataCode
          icaoCode
        }
        flightStatus
        flightNumber
        actualFlightNumber
        origin {
          id
          airport {
            airportName
            iataCode
            icaoCode
            countryName
            countryIso2
            timezone
            gmt
          }
          scheduledGate
          scheduledDateTime
          scheduledTerminal
          actualGate
          actualDateTime
          estimatedDateTime
          actualTerminal
        }
        destination {
          id
          airport {
            airportName
            iataCode
            icaoCode
            countryName
            countryIso2
            timezone
            gmt
          }
          scheduledGate
          scheduledDateTime
          scheduledTerminal
          actualGate
          estimatedDateTime
          actualDateTime
          actualTerminal
        }
      }
    }
  }
`;
