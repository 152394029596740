import React, { useState } from "react";
import { Controller, Control } from "react-hook-form";

import { TimePicker } from "@mui/lab";
import { IconButton, InputAdornment, TextField } from "@mui/material";

import ArrowDownIcon from "design-system/icons/layout/ArrowDownIcon";

type RHFTimePickerProps = {
  name: string;
  control: Control<any>;
  label: string;
  errorMessage: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  textFieldPlaceholder: string;
};

function RHFTimePicker(props: RHFTimePickerProps) {
  const {
    name,
    control,
    label,
    errorMessage,
    startAdornment,
    endAdornment,
    textFieldPlaceholder,
  } = props;

  // state
  const [clockOpen, setClockOpen] = useState(false);

  // event handlers
  const handleClockOpen = () => setClockOpen(true);
  const handleClockClose = () => setClockOpen(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...otherFields } }) => {
        return (
          <TimePicker
            {...otherFields}
            value={value}
            {...(value?.isValid() && { label: label })} // design doesn't show label if input is empty
            clearable
            open={clockOpen}
            onOpen={handleClockOpen}
            onClose={handleClockClose}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={!!errorMessage}
                helperText={errorMessage}
                inputProps={{
                  ...params.inputProps,
                  placeholder: textFieldPlaceholder,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ paddingRight: startAdornment ? "10px" : "0px" }}
                    >
                      {startAdornment || null}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClockOpen}>
                        {endAdornment || <ArrowDownIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        );
      }}
    />
  );
}

export default RHFTimePicker;
