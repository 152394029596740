import React from "react";

type PercentageIconProps = {
  color?: string;
  size?: "small";
};

const PercentageIcon = (props: PercentageIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.25 2.75L2.75 13.25M5.75 3.875C5.75 4.91053 4.91053 5.75 3.875 5.75C2.83947 5.75 2 4.91053 2 3.875C2 2.83947 2.83947 2 3.875 2C4.91053 2 5.75 2.83947 5.75 3.875ZM14 12.125C14 13.1605 13.1605 14 12.125 14C11.0895 14 10.25 13.1605 10.25 12.125C10.25 11.0895 11.0895 10.25 12.125 10.25C13.1605 10.25 14 11.0895 14 12.125Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.25 2.75L2.75 13.25M5.75 3.875C5.75 4.91053 4.91053 5.75 3.875 5.75C2.83947 5.75 2 4.91053 2 3.875C2 2.83947 2.83947 2 3.875 2C4.91053 2 5.75 2.83947 5.75 3.875ZM14 12.125C14 13.1605 13.1605 14 12.125 14C11.0895 14 10.25 13.1605 10.25 12.125C10.25 11.0895 11.0895 10.25 12.125 10.25C13.1605 10.25 14 11.0895 14 12.125Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PercentageIcon;
