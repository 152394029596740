import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { Box } from "@mui/material";

import { useScreenSize } from "../../globals/hooks";
import MobileDispatchHeader from "./components/MobileDriverTrackingHeader";
import DispatchMapView from "./components/DriverTrackingMapView";
import DispatchTripsView from "./components/DriverTrackingTripsView";
import { useDispatchTrips, useTrackedAssets } from "./hooks";
import { useTrackedVehicles } from "./hooks/useTrackedVehicles";

export enum PageViewEnum {
  Trips = "trips",
  Map = "map",
}

function DispatchPage() {
  // state
  const [pageView, setPageView] = useState(PageViewEnum.Trips);

  // hooks
  const { isMobileView } = useScreenSize();
  const { trips, loading } = useDispatchTrips();
  const { vehicles } = useTrackedVehicles();
  const { trackedAssets } = useTrackedAssets(trips, vehicles);

  // event handlers
  const handlePageViewClick = (
    _,
    newView: PageViewEnum.Trips | PageViewEnum.Map
  ) => {
    if (newView?.length) setPageView(newView);
  };

  const pages = {
    trips: <DispatchTripsView trips={trips} isLoading={loading} />,
    map: <DispatchMapView trackedAssets={trackedAssets} />,
  };

  return (
    <>
      <Helmet>
        <title>DriverTracking | Moovs</title>
      </Helmet>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* mobile : toggles trip list view and map view */}
        {isMobileView && (
          <>
            <MobileDispatchHeader
              onPageViewClick={handlePageViewClick}
              pageView={pageView}
            />
            <Box sx={{ display: "flex", flex: 1 }}>{pages[pageView]}</Box>
          </>
        )}

        {/* desktop : displays both trip list and map view */}
        {!isMobileView && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            {pages.trips}
            {pages.map}
          </Box>
        )}
      </Box>
    </>
  );
}

export default DispatchPage;
