import React from "react";
import { Control, FieldErrors } from "react-hook-form";
import { Moment } from "moment";

import { Box } from "@mui/material";

import RHFDatePicker from "components/reactHookFormInputs/RHFDatePicker";
import { useScreenSize } from "globals/hooks";
import { EditDynamicPricingDefaultValues } from "../useEditDynamicPricingDefaultValues";

type EndDateProps = {
  control: Control<EditDynamicPricingDefaultValues>;
  errors: FieldErrors;
  defaultDate: Moment;
};

function EndDate(props: EndDateProps) {
  const { control, errors, defaultDate } = props;

  const { isSmallTabletView } = useScreenSize();

  return (
    <Box my={1.5} width={isSmallTabletView ? "100%" : "49.5%"}>
      <RHFDatePicker
        control={control}
        name="endDate"
        errorMessage={errors.endDate?.message}
        textFieldPlaceholder="End Date (optional)"
        datePickerInputFormat="MM/DD/YYYY"
        datePickerLabel="End Date (optional)"
        defaultDate={defaultDate}
      />
    </Box>
  );
}

export default EndDate;
