import React, { ChangeEventHandler } from "react";

import { TextField } from "@mui/material";

type AddNoteFieldProps = {
  note: string;
  noteError: string;
  onNoteChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
};

function AddNoteField(props: AddNoteFieldProps) {
  const { note, noteError, onNoteChange } = props;

  return (
    <TextField
      inputProps={{ maxLength: 1000 }}
      error={!!noteError}
      helperText={noteError}
      fullWidth
      multiline
      variant="outlined"
      name="note"
      label="Add Note"
      value={note || ""}
      onChange={onNoteChange}
    />
  );
}

export default AddNoteField;
