import React from "react";

import { Box, Typography } from "@mui/material";

import MoovsSearchableCheckboxDropdown from "components/MoovsSearchableCheckboxDropdown";
import { granite } from "design-system/colors";
import { FieldNameEnum } from "components/vehicles/CreateVehicleDrawer/types";

type SelectionType = { name: string; id: string };

type UpdatePricelessBookingTargetDropdownProps = {
  options: SelectionType[];
  fieldName: FieldNameEnum;
  dropdownTitle: string;
  placeholder: string;
  selection: SelectionType[];
  handleDropdownChange: (
    value: SelectionType[],
    fieldName: FieldNameEnum
  ) => void;
  handleDeleteDropdown: (item: SelectionType, fieldName: FieldNameEnum) => void;
};

function UpdatePricelessBookingTargetDropdown(
  props: UpdatePricelessBookingTargetDropdownProps
) {
  const {
    options,
    fieldName,
    dropdownTitle,
    placeholder,
    selection,
    handleDropdownChange,
    handleDeleteDropdown,
  } = props;

  return (
    <Box mb={2}>
      <Box my={1}>
        <Typography variant="overline" color={granite}>
          {dropdownTitle}
        </Typography>
      </Box>
      <MoovsSearchableCheckboxDropdown
        fieldName={fieldName}
        options={options}
        selection={selection}
        setSelection={(e) => handleDropdownChange(e, fieldName)}
        placeholder={placeholder}
        handleDelete={(e) => handleDeleteDropdown(e, fieldName)}
      />
    </Box>
  );
}

export default UpdatePricelessBookingTargetDropdown;
