import React from "react";

type MailReferIconProps = {
  color?: string;
  size?: "small";
};

const MailReferIcon = (props: MailReferIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.16265 1.75576L14.1813 5.0179C14.3587 5.13317 14.4473 5.1908 14.5116 5.26768C14.5684 5.33574 14.6112 5.41445 14.6372 5.49922C14.6667 5.59498 14.6667 5.70073 14.6667 5.91224V10.8C14.6667 11.9201 14.6667 12.4802 14.4487 12.908C14.2569 13.2843 13.951 13.5903 13.5747 13.7821C13.1468 14 12.5868 14 11.4667 14H4.53334C3.41324 14 2.85319 14 2.42536 13.7821C2.04904 13.5903 1.74308 13.2843 1.55133 12.908C1.33334 12.4802 1.33334 11.9201 1.33334 10.8V5.91224C1.33334 5.70073 1.33334 5.59498 1.36279 5.49922C1.38886 5.41445 1.43157 5.33574 1.48844 5.26768C1.55268 5.1908 1.64135 5.13317 1.81869 5.0179L6.83737 1.75576M9.16265 1.75576C8.74182 1.48222 8.53141 1.34545 8.30469 1.29224C8.1043 1.2452 7.89573 1.2452 7.69533 1.29224C7.46861 1.34545 7.2582 1.48222 6.83737 1.75576M9.16265 1.75576L13.2908 4.43904C13.7493 4.7371 13.9786 4.88614 14.058 5.07514C14.1274 5.2403 14.1274 5.42645 14.058 5.59162C13.9786 5.78061 13.7493 5.92965 13.2908 6.22772L9.16265 8.91099C8.74183 9.18453 8.53141 9.3213 8.30469 9.37452C8.1043 9.42155 7.89573 9.42155 7.69533 9.37452C7.46861 9.3213 7.2582 9.18453 6.83737 8.91099L2.70925 6.22772C2.25069 5.92965 2.0214 5.78061 1.94199 5.59162C1.8726 5.42645 1.8726 5.2403 1.94199 5.07514C2.0214 4.88614 2.25069 4.7371 2.70925 4.43904L6.83737 1.75576M14.3333 12.6667L9.9048 8.66671M6.09523 8.66671L1.66668 12.6667"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.744 2.63346L21.272 7.52667C21.538 7.69957 21.671 7.78602 21.7674 7.90134C21.8527 8.00342 21.9167 8.12149 21.9558 8.24865C22 8.39229 22 8.55092 22 8.86818V16.1999C22 17.88 22 18.7201 21.673 19.3619C21.3854 19.9263 20.9265 20.3853 20.362 20.6729C19.7202 20.9999 18.8802 20.9999 17.2 20.9999H6.8C5.11984 20.9999 4.27976 20.9999 3.63803 20.6729C3.07354 20.3853 2.6146 19.9263 2.32698 19.3619C2 18.7201 2 17.88 2 16.1999V8.86818C2 8.55092 2 8.39229 2.04417 8.24865C2.08327 8.12149 2.14735 8.00342 2.23265 7.90134C2.32901 7.78602 2.46201 7.69957 2.72802 7.52667L10.256 2.63346M13.744 2.63346C13.1127 2.22315 12.7971 2.01799 12.457 1.93817C12.1564 1.86761 11.8436 1.86761 11.543 1.93817C11.2029 2.01799 10.8873 2.22315 10.256 2.63346M13.744 2.63346L19.9361 6.65837C20.624 7.10547 20.9679 7.32902 21.087 7.61252C21.1911 7.86027 21.1911 8.13949 21.087 8.38724C20.9679 8.67074 20.624 8.89429 19.9361 9.34139L13.744 13.3663C13.1127 13.7766 12.7971 13.9818 12.457 14.0616C12.1564 14.1321 11.8436 14.1321 11.543 14.0616C11.2029 13.9818 10.8873 13.7766 10.256 13.3663L4.06386 9.34139C3.37601 8.89429 3.03209 8.67074 2.91297 8.38724C2.80888 8.13949 2.80888 7.86027 2.91297 7.61252C3.03209 7.32902 3.37601 7.10547 4.06386 6.65837L10.256 2.63346M21.5 18.9999L14.8572 12.9999M9.14282 12.9999L2.5 18.9999"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MailReferIcon;
