import React from "react";
import map from "lodash/map";

import { Box, Divider, IconButton, Typography } from "@mui/material";

import { SubRefund, SubPayment } from "types";
import { moovsBlue } from "design-system/colors";
import { OpenIcon } from "design-system/icons";
import { currency } from "globals/utils/helpers";
import { useScreenSize } from "globals/hooks";
import { encodeTripId } from "globals/utils/encodeStringQueryParams";

type TransactionBreakdownProps = {
  subItems: (SubPayment | SubRefund)[];
  includePaymentMethod: boolean;
};

function TransactionBreakdown(props: TransactionBreakdownProps) {
  const { subItems, includePaymentMethod } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // event handlers
  const handleNavigateToPaymentAssociation = (
    subItem: SubPayment | SubRefund
  ) => {
    const encodedTripId = encodeTripId(subItem.route.trip.id);
    const win = window.open(
      `/reservations/${subItem.route.trip.request.id}?${encodedTripId}`,
      "_blank"
    );
    win.focus();
  };

  return (
    <>
      <Box display="flex" flex="1" justifyContent="flex-start" mt={1}>
        <Box flex={0.2} />
        <Box flex={isMobileView ? 0.7 : 0.4} />
        <Box flex={0.6}>
          <Typography variant="overline">Trip</Typography>
        </Box>
        <Box flex={0.6} />
        <Box flex={0.5} />
        {!isMobileView && includePaymentMethod && <Box flex={0.5} />}
        <Box flex={0.1} />
      </Box>
      {map(subItems, (subItem) => (
        <Box
          display="flex"
          flex="1"
          justifyContent="flex-start"
          alignItems="center"
          mt={0.5}
        >
          <Box flex={isMobileView ? 0.0 : 0.2} />
          <Box flex={isMobileView ? 0.6 : 0.4}>
            <Typography variant="body2">
              {subItem.__typename === "SubPayment" ? "+ " : "- "}
              {currency(subItem.amount)}
            </Typography>
          </Box>
          <Box flex={0.6} ml={0}>
            <IconButton
              onClick={() => handleNavigateToPaymentAssociation(subItem)}
              style={{ padding: 0, backgroundColor: "transparent" }}
            >
              <Typography
                variant="body2"
                paddingRight={1}
                color={moovsBlue}
                fontWeight="bold"
                noWrap
              >
                {`${subItem.route.trip.request.orderNumber}-${subItem.route.trip.tripNumber}`}
              </Typography>
              <OpenIcon size="xs" color={moovsBlue} />
            </IconButton>
          </Box>
          <Box flex={isMobileView ? 0 : 0.6} />
          <Box flex={0.5} />
          {!isMobileView && includePaymentMethod && <Box flex={0.5} />}
          <Box flex={0.1} />
        </Box>
      ))}
      {isMobileView && (
        <Box mt={2} mb={0.5}>
          <Divider variant="middle" />
        </Box>
      )}
    </>
  );
}

export default TransactionBreakdown;
