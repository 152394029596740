import React from "react";

import { Box, Typography } from "@mui/material";

import { alabaster, grayDark } from "../../../design-system/colors";
import { AttachIcon } from "../../../design-system/icons";
import { InsuranceAttachment } from "../../../types";
import { parseString } from "./InsuranceAttachmentsBlock";
import MoovsEllipsisMenu from "components/MoovsEllipsisMenu";

type InsuranceAttachmentsListProps = {
  attachments: Partial<InsuranceAttachment>[];
  onPressRemove: (attachment: Partial<InsuranceAttachment>) => void;
};

function InsuranceAttachmentsList(props: InsuranceAttachmentsListProps) {
  const { attachments, onPressRemove } = props;

  return (
    <>
      {attachments.map((attachment, index) => (
        <Box my={1} key={attachment.id || attachment.url}>
          <InsuranceAttachmentListItem
            attachment={attachment}
            onPressRemove={onPressRemove}
          />
        </Box>
      ))}
    </>
  );
}

export default InsuranceAttachmentsList;

type InsuranceAttachmentsListItemProps = {
  attachment: Partial<InsuranceAttachment>;
  onPressRemove: (attachment: Partial<InsuranceAttachment>) => void;
};

function InsuranceAttachmentListItem(props: InsuranceAttachmentsListItemProps) {
  const { attachment, onPressRemove } = props;

  const handlePressRemove = () => {
    onPressRemove(attachment);
  };

  const getFileNameFromUrl = (url: string) => {
    const splitUrl = url.split(parseString);

    return splitUrl[splitUrl.length - 2];
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      height="80px"
      flex="1"
      width="100%"
      style={{ backgroundColor: alabaster, borderRadius: "4px" }}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginRight="7px"
          style={{
            height: "60px",
            width: "60px",
            // backgroundColor: grayLight,
          }}
        >
          <AttachIcon color={grayDark} height="40" width="40" />
        </Box>
        <Typography
          variant="subtitle2"
          style={{
            fontWeight: "bold",
            fontSize: 16,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "450px",
          }}
        >
          {getFileNameFromUrl(attachment.url)}
        </Typography>
      </Box>
      <Box mr={1}>
        <MoovsEllipsisMenu
          options={[
            {
              icon: null,
              text: "View Attachment",
              onClick: () => window.open(attachment.url),
            },
            {
              icon: null,
              text: "Remove Attachment",
              onClick: handlePressRemove,
            },
          ]}
        />
      </Box>
    </Box>
  );
}
