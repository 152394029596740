import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

import theme, { SIDE_NAV_WIDTH } from "theme";

export const containerStyles: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  minHeight: "100%",
  maxWidth: "800px",
  margin: "auto",
  overflowY: "auto",
};

export const headerStyles: SxProps<Theme> = {
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  minHeight: "78px",
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  },
};

export const tableStyles: SxProps<Theme> = {
  overflowX: "auto",
  overflowY: "hidden",
  marginTop: theme.spacing(2.5),
  maxWidth: "100vw",
  [theme.breakpoints.up("sm")]: {
    maxWidth: `calc(100vw - ${SIDE_NAV_WIDTH})`,
  },
  paddingLeft: theme.spacing(3),
  display: "flex",
  flex: 2,
  height: "100%",
};

export const listStyles: SxProps<Theme> = {
  minWidth: "500px",
  width: "100%",
  overflowX: "auto",
  overflowY: "hidden",
  height: "100%",
  paddingRight: theme.spacing(3),
};
