import React, { useCallback } from "react";
import { Control, FieldErrors, useForm, UseFormWatch } from "react-hook-form";
import * as yup from "yup";

import { Box, Typography } from "@mui/material";

import RHFDollarPctAmt from "components/reactHookFormInputs/RHFDollarPctAmt";
import RHFTextField from "components/reactHookFormInputs/RHFTextField";
import { useOperator, useScreenSize } from "globals/hooks";
import { codeAndAmountDefaultValues } from "./defaultValues";
import { codeAndAmountSchema } from "./schemaValidation";
import { errorRed, grayDark } from "design-system/colors";
import { EditPromoCodeValues } from "../../../UpdatePromoCodeDrawer/components/PromoCodeOverviewSection/EditPromoCodeForm/useEditPromoCodeDefaultValues";

type CodeAndAmountFormValues = {
  isPercent: boolean;
  amount: number;
  name: string;
};

type CodeAndAmountFormProps = {
  control: Control<CodeAndAmountFormValues> | Control<EditPromoCodeValues>;
  errors: FieldErrors;
  watch?: UseFormWatch<CodeAndAmountFormValues>; // Either watch or watchEdit is required
  watchEdit?: UseFormWatch<EditPromoCodeValues>;
  isEdit?: boolean;
};

export function CodeAndAmountForm(props: CodeAndAmountFormProps) {
  const { control, errors, watch, watchEdit, isEdit } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  const isPercent = isEdit ? watchEdit("isPercent") : watch("isPercent");

  const sanitizeAndFormatInput = (e) => {
    const input = e.target.value;

    // If the input contains non-alphanumeric characters, filter them out
    let filteredValue = input.replace(/[^a-zA-Z0-9]/g, "");

    // Always display promo code in uppercase
    filteredValue = filteredValue.toUpperCase();
    e.target.value = filteredValue;
  };

  return (
    <Box mx={isEdit ? 0 : 2}>
      <Box
        mb={3}
        display="flex"
        flexDirection={isMobileView ? "column" : "row"}
      >
        <RHFDollarPctAmt
          toggleName="isPercent"
          textFieldName="amount"
          errorMessage={errors.amount?.message}
          control={control}
          isPercent={isPercent}
          customDecimal={2}
          shouldTextFieldFlexGrow={true}
          shouldContainerBeFullWidth={isEdit}
        />
        {!isEdit && (
          <Box {...(!isMobileView && { ml: 2 })}>
            <RHFTextField
              name="name"
              control={control}
              errorMessage={errors.name?.message}
              variant="outlined"
              onInput={sanitizeAndFormatInput}
              label="Enter unique code"
              sx={{
                height: "50px",
                ...(errors.name?.message && {
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: errorRed,
                  },
                }),
                ...(isMobileView
                  ? { width: "100%", my: 2 }
                  : { width: "282px" }),
              }}
            />
          </Box>
        )}
      </Box>
      {!isEdit && (
        <Typography variant="caption" style={{ color: grayDark }}>
          Promo codes can use both letters and numbers e.g. SUMMER2024
        </Typography>
      )}
    </Box>
  );
}

function useCodeAndAmountForm() {
  // hooks
  const operator = useOperator();
  const promoCodes = operator?.settings?.promoCodes || [];
  const promoCodeNames = promoCodes.map((promoCode) => promoCode.promoCodeName);

  const useCustomYupValidationResolver = (
    codeAndAmountSchema,
    promoCodeNames
  ) =>
    useCallback(
      async (data) => {
        const validationErrors = [];
        let values;

        try {
          values = await codeAndAmountSchema.validate(data, {
            abortEarly: false,
          });
        } catch (errors) {
          if (errors instanceof yup.ValidationError) {
            validationErrors.push(...errors.inner);
          } else {
            throw errors;
          }
        }

        try {
          // Custom validation ensuring promo code name is unique to the operator
          if (data.name && promoCodeNames.includes(data.name)) {
            throw new yup.ValidationError(
              "Code already exists. Code must be unique",
              data.name,
              "name"
            );
          }
        } catch (error) {
          if (error instanceof yup.ValidationError) {
            validationErrors.push(error);
          } else {
            throw error;
          }
        }

        if (!validationErrors.length) {
          return {
            values,
            errors: {},
          };
        }

        return {
          values: {},
          errors: validationErrors.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      },
      [codeAndAmountSchema, promoCodeNames]
    );

  const customResolver = useCustomYupValidationResolver(
    codeAndAmountSchema,
    promoCodeNames
  );

  // setup react hook form
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit: handleCodeAndAmountSubmit,
    reset: resetCodeAndAmountForm,
  } = useForm({
    defaultValues: codeAndAmountDefaultValues,
    resolver: customResolver,
  });

  return {
    handleCodeAndAmountSubmit,
    resetCodeAndAmountForm,
    codeAndAmountFormComponent: (
      <CodeAndAmountForm control={control} errors={errors} watch={watch} />
    ),
  };
}

export default useCodeAndAmountForm;
