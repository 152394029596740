// @ts-nocheck
import React, { Dispatch, SetStateAction } from "react";

import { Box, Divider, Typography } from "@mui/material";

import MoovsPricingForm from "components/pricing/MoovsPricingForm";
import { EstimationRoundIcon } from "design-system/icons";
import { useScreenSize } from "globals/hooks";
import {
  BaseRateAutomation,
  RoutePricing,
  Stop,
  VehicleChildSeat,
} from "types";
import { PricingKeys } from "components/pricing/utils";
import { swoopGreen } from "design-system/colors";

const HideBRAProps = {
  hideBRA: true,
  hasEnabledVehicleBRA: false,
  stops: [],
  vehicleId: null,
};

type PricingInfoCreateBlockProps = {
  requestErrors: any;
  setRequestErrors: any; // future change - pass in a handler that resets request errors given a field
  routePricing: RoutePricing;
  updateRoute: (
    targetName: string,
    targetValue: number | string | boolean
  ) => void;
  totalDurationMinutes: number;
  baseRateAmtError: string;
  BRAProps?: {
    hasEnabledVehicleBRA: boolean;
    vehicleId: string;
    stops: Stop[];
    showAutomatedBaseRateIcon: boolean;
    automatedBaseRate: BaseRateAutomation;
    automatedBaseRateLoading: boolean;
  };
  setIsBaseRateAutomationClicked?: Dispatch<SetStateAction<boolean>>;
  isVehicleBaseRateAutomated?: boolean;
  setExisitngAutomatedBaseRate?: Dispatch<SetStateAction<BaseRateAutomation>>;
  childSeatOptions?: VehicleChildSeat[];
};

function PricingInfoCreateBlock(props: PricingInfoCreateBlockProps) {
  const {
    requestErrors,
    setRequestErrors,
    routePricing,
    updateRoute,
    totalDurationMinutes,
    baseRateAmtError,
    isVehicleBaseRateAutomated,
    setIsBaseRateAutomationClicked,
    setExisitngAutomatedBaseRate,
    childSeatOptions,
  } = props;

  const {
    vehicleId,
    showAutomatedBaseRateIcon,
    automatedBaseRate,
    hasEnabledVehicleBRA,
    automatedBaseRateLoading,
    stops,
  } = props.BRAProps || {};

  const { isMobileView } = useScreenSize();

  // event handlers
  const handlePricingUpdate = (pricingKeys: PricingKeys) => {
    const { fieldName, fieldAmt, percentFieldName, percentFieldValue } =
      pricingKeys;

    if (fieldName) {
      updateRoute(fieldName, fieldAmt);
    }
    if (percentFieldName) {
      updateRoute(percentFieldName, percentFieldValue);
    }

    // reset baseRateAmt error
    if (fieldName === "baseRateAmt") {
      setRequestErrors({
        ...requestErrors,
        baseRateAmt: "",
      });
    }
  };

  // MoovsPricingForm props
  const BRAProps = props.BRAProps
    ? {
        hasEnabledVehicleBRA,
        vehicleId,
        stops,
        preexistingABR: automatedBaseRate,
        preexistingABRLoading: automatedBaseRateLoading,
      }
    : HideBRAProps;

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        mt={4}
        mb={1.5}
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Typography variant="h5">Pricing</Typography>
        {isMobileView && showAutomatedBaseRateIcon && (
          <Box ml={1} display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              <Typography variant="caption">
                Automated base rate. Tap
              </Typography>
              <Box m="0 4px" display="flex">
                <EstimationRoundIcon color={swoopGreen} />
              </Box>
            </Box>
            <Typography variant="caption">
              below to apply: ${automatedBaseRate?.total || null}
            </Typography>
          </Box>
        )}
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>

      <Box>
        <MoovsPricingForm
          pricing={routePricing}
          totalDurationMinutes={totalDurationMinutes}
          BRAProps={BRAProps}
          onPricingUpdate={handlePricingUpdate}
          baseRateAmtError={baseRateAmtError}
          isVehicleBaseRateAutomated={isVehicleBaseRateAutomated}
          setIsBaseRateAutomationClicked={setIsBaseRateAutomationClicked}
          setExisitngAutomatedBaseRate={setExisitngAutomatedBaseRate}
          childSeatOptions={childSeatOptions}
        />
      </Box>
    </>
  );
}

export default PricingInfoCreateBlock;
