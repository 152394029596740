import React from "react";

import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";

import CustomerPortalIframeGif from "design-system/images/CustomerPortalIframe.gif";
import { grayLight, grayMedium, moovsBlue } from "design-system/colors";
import { useAnalytics, useScreenSize } from "globals/hooks";
import { IframeCode } from "./components/IframeCode";
import { HelpIcon } from "design-system/icons";

function CustomerPortalIframe() {
  // hooks
  const { isMediumTabletView } = useScreenSize();
  const { track } = useAnalytics();

  const renderDetails = () => (
    <>
      <Box mt={2} mr={2}>
        <IframeCode />
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2} color="inherit">
        <Typography variant="h3">Installation Steps</Typography>
        <Box
          id="fab-help"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          minWidth={120}
          sx={{
            cursor: "pointer",
            pointerEvents: "auto",
          }}
          mr={2}
          onClick={() =>
            track("customerPortalSettings_helpRequested", {
              location: "Iframe",
            })
          }
        >
          <Typography fontSize={16} fontWeight={500} color={moovsBlue}>
            Ask for help
          </Typography>
          <HelpIcon size="small" color={moovsBlue} />
        </Box>
      </Box>
      <List
        dense
        style={{
          marginTop: "8px",
          marginLeft: "4px",
          paddingRight: "10%",
          display: "flex",
          flexDirection: "column",
          color: "inherit",
        }}
      >
        <ListItem>
          <Typography variant="body2">1. Enter your website URL.</Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body2">
            2. Click on the iframe above to copy the code
          </Typography>
        </ListItem>

        <ListItem>
          <Box display="flex" flexDirection="column">
            <ListItemText>
              <Typography variant="body2" whiteSpace="nowrap">
                3. Paste the code in your website’s HTML, be sure
              </Typography>
            </ListItemText>
            <ListItemText>
              <Typography variant="body2" ml={2} mt={-1} whiteSpace="nowrap">
                to paste it in the area where you want it to be
              </Typography>
            </ListItemText>
          </Box>
        </ListItem>

        <ListItem>
          <Typography variant="body2">4. Re-deploy site, check site</Typography>
        </ListItem>
      </List>
    </>
  );

  return (
    <Box
      display="flex"
      flexDirection={isMediumTabletView ? "column" : "row"}
      justifyContent="space-between"
      minHeight="460px"
    >
      <Box flex={0.5}>
        <Box mt={3}>
          <Typography variant="h2">Customer Portal iFrame</Typography>
        </Box>
        <Box mt={2} pr="25%">
          <Typography variant="body2">
            Add our code to your site to enable our booking widget onto your
            website.
          </Typography>
        </Box>
        {!isMediumTabletView && renderDetails()}
      </Box>
      <Box
        flex={0.5}
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={isMediumTabletView ? 4 : 0}
        flexDirection="column"
        bgcolor={grayLight}
        borderRadius={2}
        position="relative"
      >
        <Box paddingX={4} paddingY={2}>
          <img
            src={CustomerPortalIframeGif}
            alt="customer portal"
            width="100%"
            style={{
              maxHeight: "300px",
              borderRadius: "4px",
              border: `1px solid ${grayMedium}`,
              boxShadow:
                "0px 24px 24px -4px rgba(30, 30, 30, 0.02),  0px 8px 10px -2.5px rgba(30, 30, 30, 0.02)",
            }}
          />
        </Box>
      </Box>
      {isMediumTabletView && renderDetails()}
    </Box>
  );
}

export default CustomerPortalIframe;
