import moment, { isMoment } from "moment";
import omitBy from "lodash/omitBy";
import isNull from "lodash/isNull";
import size from "lodash/size";

import { getSelectedVehicleIds } from "../../../DynamicPricingDialog/utils/getDynamicPricingVehicle";
import { EditDynamicPricingDefaultValues } from "../useEditDynamicPricingDefaultValues";
import { Vehicle } from "types";

type FinalizeEditDynamicPricingInput = {
  availableVehicles: Vehicle[];
  input: EditDynamicPricingDefaultValues;
};

export const finalizeEditDynamicPricingInput = (
  param: FinalizeEditDynamicPricingInput
) => {
  const { input, availableVehicles } = param;

  const vehicleIds = getSelectedVehicleIds(input.vehicles);
  const daysOfWeek = input.daysOfWeekIsDisabled
    ? null
    : input.daysOfWeek
        ?.filter((weekday) => weekday.isSelected)
        .map((weekday) => weekday.value);

  const repeatAnnually = !input.repeatRuleIsDisabled && input.repeatAnnually;
  // @ts-ignore
  const amount = parseFloat(input.amount); // type change from number to string, need to convert back
  // @ts-ignore
  const isIncrease = JSON.parse(input.isIncrease); // type change from boolean to string, need to convert back

  const finalData = {
    ...input,
    daysOfWeek,
    repeatAnnually,
    amount,
    isIncrease,
  };

  // 'selected all vehicles' will be applied to future new created vehicles
  if (size(vehicleIds) !== size(availableVehicles)) {
    // if not all available vehicles are selected, send vehicleIds
    finalData["vehicleIds"] = vehicleIds;
  } else {
    // if all available vehicles are selected, don't send vehicleIds to server
    delete finalData["vehicleIds"];
  }

  // remove extra data for mutation
  delete finalData["vehicles"];
  delete finalData["repeatRuleIsDisabled"];
  delete finalData["daysOfWeekIsDisabled"];

  // remove daysOfWeek if empty list (if no daysOfWeek selected, server will interpret it as all daysOfWeek selected)
  if (!size(finalData.daysOfWeek)) {
    delete finalData["daysOfWeek"];
  }

  // remove timezones
  if (finalData.startDate) {
    finalData.startDate = isMoment(finalData.startDate)
      ? moment(finalData.startDate).utc(true).toDate()
      : finalData.startDate;
  }

  if (finalData.endDate) {
    finalData.endDate = isMoment(finalData.endDate)
      ? moment(finalData.endDate).utc(true).toDate()
      : finalData.endDate;
  }

  if (finalData.startTime?.isValid()) {
    finalData.startTime = moment(finalData.startTime).utc(true);
  } else {
    delete finalData["startTime"];
  }

  if (finalData.endTime?.isValid()) {
    finalData.endTime = moment(finalData.endTime).utc(true);
  } else {
    delete finalData["endTime"];
  }

  // server currently can't handle any keys with null
  return omitBy(finalData, isNull);
};
