import React, { useEffect, useState } from "react";
import { UseFormWatch } from "react-hook-form";
import capitalize from "lodash/capitalize";
import size from "lodash/size";
import every from "lodash/every";

import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";

import { errorRed, grayMedium, white } from "design-system/colors";

type RHFMultipleSelectOptionType = {
  id: string;
  value: string;
  disabled?: boolean;
  isSelected: boolean;
};

type RHFMultipleSelectProps = {
  name: string;
  errorMessage: string;
  options: RHFMultipleSelectOptionType[];
  label: string;
  labelId: string;
  selectAllValue: string;
  watch: UseFormWatch<any>;
  onUpdateRHF: (list) => void;
  disabledToolTipText?: string;
  isDisabled?: boolean;
};

// RHFMultipleSelect works with a list of items that include disabled items
// Example of enabled items => [{id:1, value: 'hello', isSelected: false}]
// Example of disabled items => [{id: 1, value: 'hello', disabled: true, isSelected: false}]
function RHFMultipleSelect(props: RHFMultipleSelectProps) {
  const {
    name,
    errorMessage,
    options,
    labelId,
    label,
    selectAllValue,
    disabledToolTipText,
    isDisabled,
    watch,
    onUpdateRHF,
  } = props;

  // state
  const [selectedOptions, setSelectedOptions] =
    useState<RHFMultipleSelectOptionType[]>(options);

  const watchedValues = watch(name);
  const isAllSelected = every(
    selectedOptions,
    (item) => item.isSelected || item.disabled
  );

  // event handler
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    option: any
  ) => {
    let newValues = [...selectedOptions];

    if (option === selectAllValue) {
      // select all or unselect all
      if (selectedOptions.some((item) => !item.disabled && !item.isSelected)) {
        newValues.forEach((item) => !item.disabled && (item.isSelected = true));
      } else {
        newValues.forEach((item) => (item.isSelected = false));
      }
    } else {
      if (option.isSelected) {
        // unselecting an individual item
        newValues.find((item) => item.id === option.id).isSelected = false;
      } else {
        // selecting an individual item
        newValues.find((item) => item.id === option.id).isSelected = true;
      }
      // maintains order as user selects and unselects items
      // example: selecting weekdays, input will always display [Mon, Wed, Thurs] and not [Wed, Thurs, Mon]
      newValues.sort(
        (a, b) =>
          options.findIndex(({ value }) => value === a.value) -
          options.findIndex(({ value }) => value === b.value)
      );
    }

    setSelectedOptions(newValues);
    onUpdateRHF(newValues);
  };

  // useEffect
  // using watched values will persist values when going from one step/workflow form to another
  useEffect(() => {
    if (size(watchedValues)) {
      const formattedOptions = watchedValues.map((item) => ({
        id: item.id,
        value: item.value,
        disabled: item.disabled,
        isSelected: item.isSelected,
      }));
      setSelectedOptions(formattedOptions);
    }
  }, [watchedValues]);

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id={labelId}>{!errorMessage && label}</InputLabel>
        <Select
          {...(errorMessage && {
            style: { border: `1px solid ${errorRed}` },
          })}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              border: errorMessage ? "none" : `1px solid ${grayMedium}`,
            },
          }}
          labelId={labelId}
          multiple
          label={label}
          value={selectedOptions}
          disabled={isDisabled}
          renderValue={(selected) => {
            return isDisabled
              ? label
              : isAllSelected
              ? selectAllValue
              : selectedOptions.every((item) => !item.isSelected)
              ? label
              : selectedOptions
                  .filter((weekday) => !weekday.disabled && weekday.isSelected)
                  .map((weekday) => capitalize(weekday.value))
                  .join(", ");
          }}
        >
          {/* select all option */}
          <MenuItem
            value={selectAllValue}
            onClick={(e) => handleMenuItemClick(e, selectAllValue)}
          >
            <ListItemIcon>
              <Checkbox checked={isAllSelected} />
            </ListItemIcon>
            <ListItemText primary={selectAllValue} />
          </MenuItem>
          {/* other options */}
          {selectedOptions.map((option) => {
            const { id, value, disabled, isSelected } = option;
            return disabled ? (
              <Tooltip
                title={disabledToolTipText}
                placement="bottom-start"
                key={value}
              >
                {/* div is needed for tooltip to work on disabled menu item */}
                <div>
                  <MenuItem key={id} value={option as any} disabled={disabled}>
                    <ListItemIcon>
                      <Checkbox
                        checked={
                          disabled ? false : isAllSelected ? true : isSelected
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary={value} />
                  </MenuItem>
                </div>
              </Tooltip>
            ) : (
              <MenuItem
                key={id}
                value={option as any}
                disabled={disabled}
                onClick={(e) => handleMenuItemClick(e, option)}
                style={{ backgroundColor: white }}
              >
                <ListItemIcon>
                  <Checkbox checked={isAllSelected || isSelected} />
                </ListItemIcon>
                <ListItemText primary={capitalize(value)} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {errorMessage ? (
        <FormHelperText style={{ color: errorRed, marginLeft: "15px" }}>
          {errorMessage}
        </FormHelperText>
      ) : null}
    </>
  );
}

export default RHFMultipleSelect;
