import React, { Dispatch, SetStateAction } from "react";
import moment from "moment";

import { Box, Button, Typography } from "@mui/material";

import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "design-system/icons";
import { black, grayMedium, moovsBlue } from "design-system/colors";
import { useAnalytics, useScreenSize } from "globals/hooks";

type DriverPlatformReportDateWindowProps = {
  setDateRange: Dispatch<
    SetStateAction<{ startDate: string; endDate: string }>
  >;
  datePeriodLabel: moment.Moment;
  setDatePeriodLabel: Dispatch<SetStateAction<moment.Moment>>;
  loading: boolean;
};

function DriverPlatformReportDateWindow(
  props: DriverPlatformReportDateWindowProps
) {
  const { setDateRange, loading, datePeriodLabel, setDatePeriodLabel } = props;

  const cutoffDate = moment(new Date());

  // hooks
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();

  // derived State
  const isDisableNext =
    cutoffDate.format("MMM 'YY") === datePeriodLabel.format("MMM 'YY") ||
    loading;

  // event handlers
  const handleDateShift = (direction: "next" | "previous") => {
    const isNext = direction === "next";

    isNext
      ? setDatePeriodLabel(datePeriodLabel.add(1, "months"))
      : setDatePeriodLabel(datePeriodLabel.subtract(1, "months"));

    setDateRange({
      startDate: datePeriodLabel.startOf("month").format("YYYY-MM-DD"),
      endDate: datePeriodLabel.endOf("month").format("YYYY-MM-DD"),
    });

    track("driverPlatform_dateSwitched");
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{ ...(isMobileView && { width: "100%", marginTop: "32px" }) }}
    >
      <Button
        onClick={() => handleDateShift("previous")}
        disabled={loading}
        sx={{ ...(isMobileView && { marginLeft: "-30px" }) }}
      >
        <ChevronLeftIcon size="small" color={loading ? grayMedium : black} />
      </Button>

      <Box display="flex">
        <Box mr={1} mt={0.25}>
          <CalendarIcon size="small" />
        </Box>
        <Box width="60px">
          <Typography color={moovsBlue}>
            {datePeriodLabel.format("MMM 'YY")}
          </Typography>
        </Box>
      </Box>

      <Button
        onClick={() => handleDateShift("next")}
        disabled={isDisableNext}
        sx={{ ...(isMobileView && { marginRight: "-28px" }) }}
      >
        <ChevronRightIcon color={isDisableNext ? grayMedium : black} />
      </Button>
    </Box>
  );
}

export default DriverPlatformReportDateWindow;
