/**
 * @file UpdateDrawer.tsx
 * Global component for editing/viewing  vehicles, quotes, contacts, etc.
 * Built on top of Material UI Drawer
 *
 * components:
 *  UpdateDrawer
 *
 * author: joshp
 */
import React, { ReactNode, useState, useEffect } from "react";

import { Box, Drawer, IconButton, Tooltip } from "@mui/material";

import { granite, grayMedium, black } from "../../design-system/colors";
import { CrossIcon } from "../../design-system/icons";

import SavedIndicatorChip from "../../design-system/components/chips/SaveIndicatorChip";
import theme, { primaryMainColor } from "../../theme";
import PublishedIndicatorChip from "../../design-system/components/PublishedIndicatorChip";
import { useScreenSize } from "../../globals/hooks";
import MoovsEllipsisMenu, {
  EllipsisMenuOption,
} from "components/MoovsEllipsisMenu";

const DEFAULT_DRAWER_WIDTH = 700;

type UpdateDrawerProps = {
  open?: boolean;
  onClose: () => void;
  createdAt?: string;
  publishedAt?: string;
  updatedAt?: string;
  saveIndicatorState?: "default" | "saved" | "loading" | "error";
  saveIndicatorStateMapOverride?: {
    [mode in "default" | "saved" | "loading" | "error"]?: {
      label: string;
      icon: React.ReactNode;
    };
  };
  children?: ReactNode;
  headerContent?: ReactNode;
  subHeaderContent?: ReactNode;
  footerContent?: ReactNode;
  isEntityClosedOut?: boolean;
  drawerBorderColor?: string;
  publishedIndicatorState?: "default" | "published" | "publishing" | "error";
  ellipsisMenuOptions?: (string | EllipsisMenuOption)[];
  farmAffiliateProps?: {
    icon: ReactNode;
  };
  showMoreTextOnEllipsis?: boolean;
  secondHeaderContent?: ReactNode;
  drawerWidth?: number;
  validateBeforeClose?: () => boolean;
};

function UpdateDrawer(props: UpdateDrawerProps) {
  const {
    open,
    onClose,
    updatedAt,
    publishedAt,
    saveIndicatorState,
    saveIndicatorStateMapOverride,
    children,
    headerContent,
    subHeaderContent,
    ellipsisMenuOptions,
    footerContent,
    isEntityClosedOut = false,
    drawerBorderColor,
    publishedIndicatorState,
    farmAffiliateProps,
    showMoreTextOnEllipsis = false,
    secondHeaderContent,
    drawerWidth,
    validateBeforeClose,
  } = props;
  const { isMobileView } = useScreenSize();

  // state
  const [drawerOpen, setDrawerOpen] = useState(false);

  // effects
  useEffect(() => {
    setDrawerOpen(true);
  }, [setDrawerOpen]);

  // event handlers
  const handleDrawerClose = () => {
    if (validateBeforeClose && !validateBeforeClose()) {
      return;
    }
    setDrawerOpen(false);
    // waits for drawer animation to end
    setTimeout(onClose, 195);
  };

  const borderColor = isEntityClosedOut ? primaryMainColor : drawerBorderColor;

  const width = drawerWidth || DEFAULT_DRAWER_WIDTH;

  return (
    <Drawer
      disableEnforceFocus // allows intercom to be focused on messenger while drawer open.
      // there are accessability issues with this prop.
      // reference: https://material-ui.com/api/modal/
      open={open !== undefined ? open : drawerOpen}
      onClose={(_, reason) => reason !== "backdropClick" && handleDrawerClose()}
      anchor="right"
    >
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          [theme.breakpoints.up(width)]: {
            width,
          },
          overflow: "hidden",
          margin: 0,
        }}
        display="flex"
        flexDirection="column"
        flex="1"
        {...(!!borderColor && {
          border: `5px solid ${borderColor}`,
        })}
      >
        {/* First Header */}
        <Box borderBottom={`1px solid ${grayMedium}`}>
          <Box
            mx={isMobileView ? 2 : 4}
            height="78px"
            sx={{
              backgroundColor: theme.palette.background.paper,
              zIndex: theme.zIndex.drawer + 3,
              [theme.breakpoints.down("lg")]: {
                borderBottom: `none`,
              },
              "& .MuiIconButton-colorPrimary": { color: granite }, //remove
            }}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            display="flex"
          >
            <Tooltip
              enterDelay={200}
              enterNextDelay={200}
              title="Close"
              placement="right"
              arrow
            >
              <IconButton
                sx={{ color: granite }}
                color="primary"
                onClick={handleDrawerClose}
                size="large"
              >
                <CrossIcon color={black} />
              </IconButton>
            </Tooltip>
            <Box display="flex" alignItems="center">
              {farmAffiliateProps && (
                <Box mr={2}>{farmAffiliateProps.icon}</Box>
              )}
              <Box mr={0.5}>
                <SavedIndicatorChip
                  mode={saveIndicatorState}
                  updatedAt={updatedAt}
                  modeMapOverride={saveIndicatorStateMapOverride}
                />
              </Box>
              {publishedAt && (
                <Box mr={0.5}>
                  <PublishedIndicatorChip
                    mode={publishedIndicatorState}
                    publishedAt={publishedAt}
                  />
                </Box>
              )}

              {headerContent}
              {ellipsisMenuOptions && (
                <>
                  <MoovsEllipsisMenu
                    variant={
                      showMoreTextOnEllipsis ? "withMoreText" : "default"
                    }
                    options={ellipsisMenuOptions}
                  />
                </>
              )}
            </Box>
          </Box>
          {subHeaderContent}
        </Box>
        {/* Second Header */}
        {secondHeaderContent && (
          <Box borderBottom={`1px solid ${grayMedium}`}>
            {secondHeaderContent}
          </Box>
        )}

        <Box
          flex="1"
          sx={{
            overflow: "auto",
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            px={isMobileView ? 2 : 4}
          >
            {children}
          </Box>
        </Box>
        {footerContent && (
          <Box borderTop={`1px solid ${grayMedium}`}>
            <Box
              height="78px"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
              display="flex"
              mx={3}
            >
              {footerContent}
            </Box>
          </Box>
        )}
      </Box>
    </Drawer>
  );
}

export default UpdateDrawer;
