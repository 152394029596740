import React, { ChangeEvent } from "react";

import {
  Box,
  Typography,
  TextField,
  Divider,
  IconButton,
  BoxProps,
  useTheme,
} from "@mui/material";

import { TrashIcon } from "../../../../design-system/icons";
import {
  NumberFormatDollar,
  NumberFormatPercent,
} from "../../../../design-system/components/inputs/NumberFormat";
import { useScreenSize } from "../../../../globals/hooks";
import {
  normalizeValueToBeDisplayedAsDollar,
  DollarPercentVariant,
} from "../../utils";
import DollarPercentToggle from "./DollarPercentToggle";
import MoovsLabeledIncrementer from "components/MoovsLabeledIncrementer";
import { currency } from "globals/utils/helpers";

type MoovsPricingFieldProps = {
  variant: DollarPercentVariant;
  label: string;
  fieldValue: number;
  fieldName: string;
  helperText?: string;
  labelIcon?: any;
  onFieldChange: (
    percentFieldName: string,
    fieldName: string,
    value: number | string,
    variant: DollarPercentVariant
  ) => void;
  percentFieldName?: string;
  onToggle?: (
    fieldName: string,
    percentFieldName: string,
    variant: DollarPercentVariant
  ) => void;
  onRemove?: (fieldName: string, percentFieldName?: string) => void;
  additionalFieldProps?: {
    fieldName: string;
    fieldValue: string;
    onFieldChange: (fieldName: string, fieldValue: string) => void;
    placeholder?: string;
    mobilePlaceholder?: string;
    disabled?: boolean;
  };
  quantityFieldProps?: {
    fieldName: string;
    fieldValue: number;
    onFieldChange: (fieldName: string, fieldValue: number) => void;
  };
};

const { Dollar, Percent } = DollarPercentVariant;

function MoovsPricingField(props: MoovsPricingFieldProps & BoxProps) {
  const {
    fieldValue,
    variant,
    label,
    fieldName,
    percentFieldName,
    helperText,
    labelIcon,
    onFieldChange,
    onToggle,
    onRemove,
    additionalFieldProps,
    quantityFieldProps,
    ...boxProps
  } = props;

  // hooks
  const theme = useTheme();
  const { isMobileView } = useScreenSize();

  const isDollar = variant === Dollar;
  const isPercent = variant === Percent;

  const pricingFieldIsInline =
    (isMobileView && !additionalFieldProps) || !isMobileView;

  // event handlers
  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value === "" ? "" : Number(event.target.value);

    onFieldChange(
      percentFieldName,
      fieldName,
      value,
      !percentFieldName ? Dollar : variant
    );
  };

  const handleToggle = (variant: DollarPercentVariant) => {
    onToggle(fieldName, percentFieldName, variant);
  };

  const handleAdditionalFieldChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    additionalFieldProps.onFieldChange(
      additionalFieldProps.fieldName,
      event.target.value
    );
  };

  const handlerQuantityFieldChange = (value: number) => {
    quantityFieldProps.onFieldChange(quantityFieldProps.fieldName, value);
  };

  const handleRemoveField = () => onRemove(fieldName, percentFieldName);

  // render methods
  const renderPricingField = () => (
    <Box flex={1}>
      <TextField
        variant="outlined"
        fullWidth
        placeholder={isMobileView ? "Enter Amt" : "Enter Amount"}
        name={isPercent ? percentFieldName : fieldName}
        onChange={handleFieldChange}
        helperText={helperText}
        value={normalizeValueToBeDisplayedAsDollar({
          value: fieldValue,
          variant,
        })}
        InputProps={{
          ...(isPercent && {
            inputComponent: NumberFormatPercent as any,
            inputProps: {
              suffix: "%",
              "data-testid": `pricing-field-${fieldName}`,
            },
          }),
          ...(isDollar && {
            inputProps: {
              prefix: "$",
              "data-testid": `pricing-field-${fieldName}`,
            },
            inputComponent: NumberFormatDollar as any,
          }),
        }}
        sx={{
          ".MuiFormHelperText-root": {
            marginLeft: 0.4,
            marginTop: 0.4,
          },
        }}
      />
    </Box>
  );

  return (
    <Box mb={2} display="flex" {...boxProps} data-testid="moovs-pricing-field">
      <Box flex={1}>
        <Box mb={1} display="flex" alignItems="center">
          <Typography variant="h4" mr={1}>
            {label}
          </Typography>
          {labelIcon && labelIcon}
        </Box>
        <Box display="flex" alignItems="center">
          {/* Dollar/Percent Toggle */}
          {!!onToggle && (
            <Box mr={1}>
              <DollarPercentToggle
                fieldName={fieldName}
                dollarPercentVariant={variant}
                onToggle={handleToggle}
              />
            </Box>
          )}

          {/* Text fields */}
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            {...(isMobileView &&
              !!quantityFieldProps && {
                flexDirection: "column",
                rowGap: 1.5,
                alignItems: "stretch",
              })}
          >
            {!!additionalFieldProps && (
              <Box {...(!isMobileView && { mr: 1 })} flex={1}>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={additionalFieldProps.fieldValue}
                  placeholder={
                    isMobileView
                      ? additionalFieldProps.mobilePlaceholder
                      : additionalFieldProps.placeholder
                  }
                  name={additionalFieldProps.fieldName}
                  onChange={handleAdditionalFieldChange}
                  {...(additionalFieldProps.disabled && { disabled: true })}
                />
              </Box>
            )}
            {pricingFieldIsInline && renderPricingField()}
            {!!quantityFieldProps && (
              <Box
                ml={1}
                display="flex"
                alignItems="center"
                {...(isMobileView && {
                  justifyContent: "space-between",
                  ml: 0,
                })}
              >
                <MoovsLabeledIncrementer
                  label="Quantity"
                  value={quantityFieldProps.fieldValue}
                  limits={[1, 99]}
                  decrement={() =>
                    handlerQuantityFieldChange(
                      quantityFieldProps.fieldValue - 1
                    )
                  }
                  increment={() =>
                    handlerQuantityFieldChange(
                      quantityFieldProps.fieldValue + 1
                    )
                  }
                  onTextFieldChange={(value) =>
                    handlerQuantityFieldChange(value)
                  }
                  sx={{ width: isMobileView ? null : 146 }}
                />
                <Typography variant={"subtitle1"} ml={3}>
                  {currency(fieldValue * quantityFieldProps.fieldValue)}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        {!pricingFieldIsInline && <Box mt={1}>{renderPricingField()}</Box>}
      </Box>

      {/* Remove Action */}
      {!!onRemove && (
        <Box ml={3} display="flex" alignItems="center">
          <Divider
            orientation="vertical"
            style={{ marginRight: theme.spacing(2) }}
          />
          <IconButton
            onClick={handleRemoveField}
            style={{
              position: "relative",
              top: fieldName === "meetGreetAmt" ? 5 : 15,
            }}
            data-testid={`remove-pricing-field-${fieldName}`}
            size="large"
          >
            <TrashIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

export default MoovsPricingField;
