import React, { useState } from "react";

import { CopyIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import { Request, RequestStage } from "types";
import { useAnalytics } from "globals/hooks";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";
import { isAllTripsOnTheSameDay } from "globals/utils/trips";

type UseDuplicateReservationClickProps = {
  request: Request;
  isFarmee: boolean;
  analyticsName?: string;
};

function useDuplicateReservationClick(
  props: UseDuplicateReservationClickProps
): EllipsisMenuOption & { open: boolean; onClose: () => void } {
  const { analyticsName, request, isFarmee } = props;

  // hooks
  const { track } = useAnalytics();

  // derived state
  const isTripsOnSameDay = isAllTripsOnTheSameDay(request.trips);

  const isUnconfirmedReservation = request.stage === RequestStage.Unconfirmed;

  const duplicateReservationDisabled =
    !isTripsOnSameDay || isUnconfirmedReservation;

  // state
  const [duplicateReservationDialogOpen, setDuplicateReservationDialogOpen] =
    useState(false);

  // event handlers
  const handleDuplicateReservationDialogOpen = () => {
    setDuplicateReservationDialogOpen(true);
  };

  const handleDuplicateReservationDialogClose = () => {
    setDuplicateReservationDialogOpen(false);
  };

  const handleDuplicateReservationClick = () => {
    handleDuplicateReservationDialogOpen();

    if (analyticsName) track(analyticsName);
  };

  return {
    text: "Duplicate Reservation",
    icon: <CopyIcon color={grayDark} size="small" />,
    onClick: handleDuplicateReservationClick,
    hideOption: isFarmee,
    disableOption: duplicateReservationDisabled,
    tooltip: !isTripsOnSameDay && {
      titleText: "Cannot duplicate when trips fall on different dates",
      noWrap: true,
    },

    // dialog state
    open: duplicateReservationDialogOpen,
    onClose: handleDuplicateReservationDialogClose,
  };
}

export { useDuplicateReservationClick };
