import React from "react";

import { Box, Card, CardActionArea, Typography, Divider } from "@mui/material";

import { black } from "../../design-system/colors";
import { phoneNumber } from "globals/utils/phoneNumberFormatter/phoneNumberFormatter";

const styles = {
  confNo: {
    color: black,
    fontWeight: "bold",
  },
  invoiceItemText: {
    color: black,
  },
};

type InvoicesListItemMobileProps = {
  id: string;
  smartDueDate: string;
  handleClick: (invoiceId: string) => void;
  billingContact: {
    name: string;
    email: string;
    phone: string | phoneNumber;
  };
  invoiceNumber: string;
  createdAt: string;
  totalAmount: string;
};

function InvoicesListItemMobile(props: InvoicesListItemMobileProps) {
  const {
    id,
    smartDueDate,
    handleClick,
    invoiceNumber,
    billingContact,
    createdAt,
    totalAmount,
  } = props;

  return (
    <Card variant="outlined">
      <CardActionArea
        onClick={() => {
          handleClick(id);
        }}
        disableRipple
      >
        <Box
          display="flex"
          flexDirection="row"
          flex=".4"
          py={2}
          px={2}
          alignItems="center"
        >
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            alignSelf="baseLine"
          >
            <Typography variant="overline">Invoice No.</Typography>
            <Typography variant="subtitle1" sx={styles.confNo}>
              {invoiceNumber}
            </Typography>
          </Box>
          <Box display="flex" flex="2" flexDirection="column">
            <Typography variant="overline">Billing Contact</Typography>
            <Typography variant="caption" sx={styles.invoiceItemText}>
              {billingContact.name}
            </Typography>
            <Typography variant="caption" sx={styles.invoiceItemText}>
              {billingContact.email}
            </Typography>
            {billingContact.phone && (
              <Typography variant="caption" sx={styles.invoiceItemText}>
                {billingContact.phone}
              </Typography>
            )}
          </Box>
        </Box>
        <Box mx={2} mb={2}>
          <Divider />
        </Box>
        <Box display="flex" flexDirection="row" flex="1">
          <Box display="flex" flexDirection="column" flex="1" mb={1} mx={2}>
            <Box display="flex" flex="1">
              <Typography variant="overline">Due Date</Typography>
            </Box>
            <Box display="flex" flex="1">
              <Typography
                variant="caption"
                sx={styles.invoiceItemText}
                style={{ fontWeight: 600 }}
              >
                {smartDueDate}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" flex="1" mb={2} mx={2}>
            <Box display="flex" flex="1">
              <Typography variant="overline">Create Date</Typography>
            </Box>
            <Box display="flex" flex="1">
              <Typography variant="caption" sx={styles.invoiceItemText}>
                {createdAt}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" flex="1" mb={2} mx={2}>
            <Box display="flex" flex="1">
              <Typography variant="overline">Total Amount</Typography>
            </Box>
            <Box display="flex" flex="1">
              <Typography variant="caption" sx={styles.invoiceItemText}>
                {totalAmount}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default InvoicesListItemMobile;
