import React from "react";

type InstantPayoutChipIconProps = {
  color?: string;
  iconColor?: string;
  size?: "small";
};

const InstantPayoutChipIcon = (props: InstantPayoutChipIconProps) => {
  const { color, iconColor, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.6673 2.66669V4.60608M11.6673 4.60608V8.00002M11.6673 4.60608H14.1673M11.6673 4.60608H10.4173C9.95319 4.60608 9.50807 4.78487 9.17988 5.10311C8.85169 5.42135 8.66732 5.85299 8.66732 6.30305C8.66732 6.75311 8.85169 7.18474 9.17988 7.50299C9.50807 7.82123 9.95319 8.00002 10.4173 8.00002H11.6673M11.6673 8.00002V11.394M11.6673 8.00002H12.9173C13.3814 8.00002 13.8266 8.17881 14.1548 8.49705C14.4829 8.81529 14.6673 9.24692 14.6673 9.69699C14.6673 10.1471 14.4829 10.5787 14.1548 10.8969C13.8266 11.2152 13.3814 11.394 12.9173 11.394H11.6673M11.6673 11.394V13.3334M11.6673 11.394H8.66732M1.33398 7.64446H6.66732M6.66732 7.64446L3.81883 10.4889M6.66732 7.64446L3.81883 4.80002"
          stroke={color || iconColor || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 4V6.90909M17.5 6.90909V12M17.5 6.90909H21.25M17.5 6.90909H15.625C14.9288 6.90909 14.2611 7.17727 13.7688 7.65464C13.2766 8.132 13 8.77945 13 9.45455C13 10.1296 13.2766 10.7771 13.7688 11.2545C14.2611 11.7318 14.9288 12 15.625 12H17.5M17.5 12V17.0909M17.5 12H19.375C20.0712 12 20.7389 12.2682 21.2312 12.7455C21.7234 13.2229 22 13.8704 22 14.5455C22 15.2206 21.7234 15.868 21.2312 16.3454C20.7389 16.8227 20.0712 17.0909 19.375 17.0909H17.5M17.5 17.0909V20M17.5 17.0909H13M2 11.4667H10M10 11.4667L5.72727 15.7333M10 11.4667L5.72727 7.2"
        stroke={color || iconColor || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InstantPayoutChipIcon;
