import axios from "axios";
import { stringify } from "querystring";
import { getServerLink } from "./getServerLink";

type OpenPdfProps = {
  endPoint: string;
  queryParams: {
    id: string;
  };
};

export const openPdf = async ({
  endPoint,
  queryParams,
}: OpenPdfProps) => {
  // Get the PDF data as an array buffer
  const { data } = await axios.get(
    getServerLink() + `${endPoint}?${stringify(queryParams)}`,
    {
      responseType: "arraybuffer",
    }
  );

  const url = URL.createObjectURL(
    new Blob([data], { type: "application/pdf" })
  );

  window.open(url, "_blank").focus();
};
