import moment from "moment";

import { WorkflowSelectionsEnum } from "../steps/workflow/schemaValidation";
import { getAdjustAmountWords } from "./generateFormName";
import { getSelectedRepeatRule } from "./getRepeatRule";
import { getSelectedEnabledVehicles } from "./getDynamicPricingVehicle";
import { firstLetterUpperCaseList } from "./changeUpperLowerCase";
import { DynamicPricingInputType } from "./types";

type GenerateOverviewInputsProps = {
  currentWorkflow: string;
  validatedInput: DynamicPricingInputType;
};

enum LabelEnum {
  date = "Date",
  startDate = "Start Date",
  endDate = "End Date",
  daysOfWeek = "Days of Week",
  rule = "Rule",
  vehicles = "Vehicles",
  repeat = "Repeat",
  startTime = "Start Time",
  endTime = "End Time",
}

// this return the output of overview form with label and value
export const generateOverviewInputs = (props: GenerateOverviewInputsProps) => {
  const { currentWorkflow, validatedInput } = props;

  const inputData = validatedInput[currentWorkflow];
  const {
    isIncrease,
    isPercent,
    amount,
    vehicles,
    vehiclesAvailable,
    daysOfWeek,
  } = inputData || {};

  const overviewLayout = [];

  // vehicle input values
  const { allSelected, selectedVehicleNames } = getSelectedEnabledVehicles({
    vehicles,
    vehiclesAvailable,
  });
  const vehicle = allSelected
    ? "All Vehicles"
    : selectedVehicleNames.join(", ");

  // rule input values
  const { adjust, displayAmount } = getAdjustAmountWords({
    isIncrease,
    isPercent,
    amount,
  });
  const rule = `${adjust} ${displayAmount}`;

  // repeat input values
  const repeat = getSelectedRepeatRule({
    currentWorkflow,
    validatedInput,
  });

  // days of week input values
  const weekdays = daysOfWeek?.every((day) => day.isSelected)
    ? "Everyday"
    : firstLetterUpperCaseList(
        daysOfWeek?.filter((day) => day.isSelected)
      ).join(", ");

  // the order of overviewLayout matters
  if (currentWorkflow === WorkflowSelectionsEnum.date) {
    const date = moment(inputData.startDate).format("MM/DD/YY");

    overviewLayout.push({ label: LabelEnum.date, value: date });
    overviewLayout.push({ label: LabelEnum.rule, value: rule });
    overviewLayout.push({ label: LabelEnum.vehicles, value: vehicle });
    overviewLayout.push({ label: LabelEnum.repeat, value: repeat });
    return overviewLayout;
  }

  if (currentWorkflow === WorkflowSelectionsEnum.dateRange) {
    const startDate = moment(inputData.startDate).format("MM/DD");
    const endDate = moment(inputData.endDate).format("MM/DD");

    overviewLayout.push({ label: LabelEnum.startDate, value: startDate });
    overviewLayout.push({ label: LabelEnum.endDate, value: endDate });
    overviewLayout.push({ label: LabelEnum.daysOfWeek, value: weekdays });
    overviewLayout.push({ label: LabelEnum.rule, value: rule });
    overviewLayout.push({ label: LabelEnum.vehicles, value: vehicle });
    overviewLayout.push({ label: LabelEnum.repeat, value: repeat });
    return overviewLayout;
  }

  if (currentWorkflow === WorkflowSelectionsEnum.timesOfDay) {
    const startTime = moment(inputData.startTime).format("h:mm A");
    const endTime = moment(inputData.endTime).format("h:mm A");

    overviewLayout.push({ label: LabelEnum.startTime, value: startTime });
    overviewLayout.push({ label: LabelEnum.endTime, value: endTime });
    overviewLayout.push({ label: LabelEnum.daysOfWeek, value: weekdays });
    overviewLayout.push({ label: LabelEnum.rule, value: rule });
    overviewLayout.push({ label: LabelEnum.vehicles, value: vehicle });
    return overviewLayout;
  }
};
