import React, { useEffect, useState } from "react";
import { DeepPartial } from "ts-essentials";
import { useLazyQuery } from "@apollo/client";
import uniqBy from "lodash/uniqBy";

import { Box } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";
import ContactAutoComplete from "components/autocompletes/ContactAutoComplete";
import { Contact } from "types";
import { LOAD_CONTACTS_QUERY } from "globals/graphql";

type EditBookingContactDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  setAddContactToOrderDialogOpen: (e: any) => void;
  onSave: () => void;
  bookingContact: DeepPartial<Contact>;
  setBookingContact: (e: any) => void;
  setCarryOverSearch: (e: any) => void;
  companyId?: string;
  isSaveButtonDisabled?: boolean;
  filter?: string[];
  isInEditMode?: boolean;
  shouldIncludeFilterItems?: boolean;
  defaultContactSuggestions?: DeepPartial<Contact>[];
  contactsOptionsGroupBy?: {
    [key: string]: string[] | string;
  };
};

function EditBookingContactDialog(props: EditBookingContactDialogProps) {
  const {
    open,
    onClose,
    title,
    onSave,
    bookingContact,
    setAddContactToOrderDialogOpen,
    setBookingContact,
    setCarryOverSearch,
    companyId,
    isSaveButtonDisabled,
    filter,
    isInEditMode = false,
    shouldIncludeFilterItems = false,
    defaultContactSuggestions = [],
    contactsOptionsGroupBy,
  } = props;

  // state
  const [requestErrors, setRequestErrors] = useState<string>(null);
  const [suggestedContacts, setSuggestedContacts] = useState(
    defaultContactSuggestions
  );

  // queries
  const [loadContactsForCompany] = useLazyQuery(LOAD_CONTACTS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const fetchedContactList = data?.loadContacts.edges.map(
        ({ node }) => node
      );
      const contactList = uniqBy(
        [...defaultContactSuggestions, ...fetchedContactList],
        "id"
      );
      const filteredContactList = contactList?.filter((contact) =>
        shouldIncludeFilterItems
          ? filter.includes(contact.id)
          : !filter?.includes(contact.id)
      );
      setSuggestedContacts(filteredContactList || []);
    },
  });

  // event handlers
  const handleClose = () => {
    setBookingContact(null);
    onClose();
  };

  const handleContactSelect = (contact: Contact) => {
    setBookingContact(contact);
    setRequestErrors("");
  };

  const handleClearBookingContact = () => {
    setBookingContact({
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      mobilePhone: "",
    });
  };

  // Resets contact in case user has already selected a
  // contact and then decides to create new contact
  const handleCreateNewContactClick = () => {
    handleClearBookingContact(); // clear request state
    setBookingContact(null); // clear local state
    setAddContactToOrderDialogOpen(true);
  };

  // effects
  useEffect(() => {
    if (companyId) {
      loadContactsForCompany({
        variables: {
          limit: 20,
          companyId,
        },
      });
    }
  }, [companyId, loadContactsForCompany]);

  return (
    <MoovsDialog
      open={open}
      onClose={handleClose}
      dialogTitle={title}
      onAccept={onSave}
      acceptButtonText="Save"
      acceptDisabled={isSaveButtonDisabled}
      size="sm"
    >
      <Box mt={2}>
        <ContactAutoComplete
          setCarryOverSearch={setCarryOverSearch}
          onCreateNewContactClick={handleCreateNewContactClick}
          onChange={handleContactSelect}
          error={!!requestErrors}
          helperText={requestErrors}
          label="Search for booking contact"
          companyId={companyId}
          suggestedContacts={suggestedContacts as Contact[]}
          filter={filter}
          value={bookingContact}
          isInEditMode={isInEditMode}
          shouldIncludeFilterItems={shouldIncludeFilterItems}
          contactsOptionsGroupBy={contactsOptionsGroupBy}
        />
      </Box>
    </MoovsDialog>
  );
}

export default EditBookingContactDialog;
