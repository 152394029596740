import React, { Dispatch, SetStateAction } from "react";

import {
  MoovsTransaction,
  PaymentMethodEnum,
  PaymentMethodTransaction,
  PaymentPayer,
} from "types";
import { currency } from "globals/utils/helpers";
import { PageViewIcon, ViewReceiptIcon, CopyIcon } from "design-system/icons";
import { useReceiptAction } from "./useReceiptAction";

type useTransactionDetailsProps = {
  transaction: PaymentMethodTransaction | MoovsTransaction;
  setViewMoreDetailsPaymentId: Dispatch<SetStateAction<string>>;
};

export function useTransactionDetails(props: useTransactionDetailsProps) {
  const { transaction, setViewMoreDetailsPaymentId } = props;

  // hooks
  const { handleViewReceipt, handleCopyReceipt } = useReceiptAction({
    transaction,
  });

  // derived state
  const payment =
    transaction.__typename === "Payment" ? transaction : transaction.payment;

  const refund = transaction.__typename === "PaymentRefund" && transaction;

  const status = refund?.paymentStatus || payment.paymentStatus;

  const riskEvaluation = !refund && payment.paymentIntent?.riskEvaluation;

  const cardHasElevatedRisk =
    riskEvaluation === "elevated" || riskEvaluation === "highest";

  const riskEvaluationTooltipText =
    riskEvaluation === "highest"
      ? "Highest risk payments are likely to be fradulent"
      : riskEvaluation === "elevated"
      ? "Elevated risk payments have an increased chance of being fradulent"
      : null;

  const amount = currency(refund ? refund.amount * -1 : payment.amount);

  // method
  const method = payment.method;
  const isCardPayment =
    method === PaymentMethodEnum.Card &&
    payment.paymentPayer !== PaymentPayer.Authorization;
  const paymentMethod = payment.paymentMethod;

  // handlers
  const handleViewMoreDetails = () => {
    setViewMoreDetailsPaymentId(refund.id || payment.id);
  };

  return {
    payment,
    status,
    amount,
    riskEvaluation,
    cardHasElevatedRisk,
    riskEvaluationTooltipText,
    refund,
    method,
    ...(method === PaymentMethodEnum.Card && { paymentMethod }),
    transactionEllipsesOptions: [
      {
        icon: <PageViewIcon size="small" />,
        text: "View more details",
        onClick: handleViewMoreDetails,
        disableOption: !isCardPayment,
        ...(!isCardPayment && {
          tooltip: { titleText: "Can only access on credit card payments" },
        }),
      },
      {
        icon: <ViewReceiptIcon size="small" />,
        text: "View receipt",
        onClick: handleViewReceipt,
        disableOption: !isCardPayment,
        ...(!isCardPayment && {
          tooltip: { titleText: "Can only access on credit card payments" },
        }),
      },
      {
        icon: <CopyIcon size="small" />,
        text: "Copy receipt",
        onClick: handleCopyReceipt,
        disableOption: !isCardPayment,
        ...(!isCardPayment && {
          tooltip: { titleText: "Can only access on credit card payments" },
        }),
      },
    ],
  };
}
