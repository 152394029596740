import React from "react";
import clsx from "clsx";

import {
  Popper,
  Box,
  Grow,
  Paper,
  ClickAwayListener,
  PopperPlacementType,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useScreenSize } from "../../globals/hooks";

type AnchorPopperProps = {
  anchorRef: HTMLInputElement;
  open: boolean;
  children: React.ReactChild;
  onClickAway: () => void;
  placement?: PopperPlacementType;
  zIndex?: number;
  isTrips?: boolean;
};

function AnchorPopper(props: AnchorPopperProps) {
  const { anchorRef, open, children, onClickAway, placement, zIndex, isTrips } =
    props;

  const useStyles = makeStyles(() => ({
    popper: {
      zIndex: zIndex || 20, // higher z-index than fixed header
    },
    tripListOnMobile: {
      marginLeft: 20,
    },
  }));

  // hooks
  const classes = useStyles();
  const { isMobileView } = useScreenSize();

  return (
    <Popper
      className={clsx(
        classes.popper,
        isTrips && isMobileView && classes.tripListOnMobile
      )}
      open={open}
      anchorEl={anchorRef}
      placement={placement || "bottom-start"}
      transition
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: "center top",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={onClickAway}>
              <Box>{children}</Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export default AnchorPopper;
