import React from "react";

import { Box } from "@mui/material";

import MoovsTabs from "components/globals/MoovsTabs";
import { WarningIcon } from "design-system/icons";
import { errorRed } from "design-system/colors";
import { QuoteTabViewVariant } from "./QuoteTabViews/types";

type QuoteTabsProps = {
  tabView: QuoteTabViewVariant;
  setTabView: React.Dispatch<React.SetStateAction<QuoteTabViewVariant>>;
  needsPricing: boolean;
  needsVehicle: boolean;
};

function QuoteTabs(props: QuoteTabsProps) {
  const { tabView, setTabView, needsPricing, needsVehicle } = props;

  const displayNeedPricingWarning =
    needsPricing && tabView !== QuoteTabViewVariant.PRICING;

  const displayNeedVehicleWarning =
    needsVehicle && tabView !== QuoteTabViewVariant.VEHICLE;

  return (
    <MoovsTabs
      value={tabView}
      onChange={setTabView}
      variant="scrollable"
      hideBottomBorder
      indicatorWidth={0}
      tabs={[
        {
          label: "TRIP DETAILS",
          value: QuoteTabViewVariant.TRIP_DETAILS,
        },
        {
          label: "PRICING",
          value: QuoteTabViewVariant.PRICING,
          ...(displayNeedPricingWarning && {
            Icon: (
              <Box marginBottom={-0.5}>
                <WarningIcon color={errorRed} size="small" />
              </Box>
            ),
          }),
        },
        {
          label: "VEHICLE",
          value: QuoteTabViewVariant.VEHICLE,
          ...(displayNeedVehicleWarning && {
            Icon: (
              <Box marginBottom={-0.5}>
                <WarningIcon color={errorRed} size="small" />
              </Box>
            ),
          }),
        },
      ]}
    />
  );
}

export default QuoteTabs;
