import { GridColumns } from "@mui/x-data-grid-pro";

import AmountDueColumn from "./AmountDueColumn";
import AmountPaidColumn from "./AmountPaidColumn";
import NameColumn from "./NameColumn";
import TotalPayoutColumn from "./TotalPayoutColumn";
import TripsColumn from "./TripsColumn";

const defaults = {
  sortable: false,
  hide: false,
  width: 212,
};

const driverPayoutDataGridColumns: GridColumns = [
  { ...defaults, field: "name", headerName: "NAME", renderCell: NameColumn },
  { ...defaults, field: "trips", headerName: "TRIPS", renderCell: TripsColumn },
  {
    ...defaults,
    field: "totalPayout",
    headerName: "TOTAL PAYOUT",
    renderCell: TotalPayoutColumn,
  },
  {
    ...defaults,
    field: "amountPaid",
    headerName: "AMOUNT PAID",
    renderCell: AmountPaidColumn,
  },
  {
    ...defaults,
    field: "amountDue",
    headerName: "AMOUNT DUE",
    renderCell: AmountDueColumn,
  },
];

export default driverPayoutDataGridColumns;
