import React from "react";
import moment from "moment-timezone";

import { Box, Typography, Divider } from "@mui/material";

import { User, Driver } from "../../../../../types";
import { grayLight } from "../../../../../design-system/colors";
import { WheelIcon } from "../../../../../design-system/icons";
import { useScreenSize } from "../../../../../globals/hooks";
import DispatchStatusChip from "components/DispatchStatusChip";

type StatusTimelineItemProps = {
  isLastItem: boolean;
  createdAt: string;
  statusSlug: string;
  statusName: string;
  author: User | Driver;
  timezoneId?: string;
};

function StatusTimelineItem(props: StatusTimelineItemProps) {
  const { isLastItem, createdAt, statusSlug, statusName, author, timezoneId } =
    props;

  const { isMobileView } = useScreenSize();

  const authorName = `${author.firstName} ${
    author.lastName ? `${author.lastName[0]}.` : ""
  }`;

  const formattedTime = timezoneId
    ? moment.tz(createdAt, timezoneId).format("L LT (z)")
    : moment(createdAt).format("L LT");

  return (
    <>
      <Box
        display="flex"
        flexDirection={isMobileView ? "column" : "row"}
        flex="1"
        minHeight={isMobileView ? "60px" : "38px"}
        mb={0.5}
        justifyContent="flex-start"
        alignItems={isMobileView ? "flex-start" : "center"}
      >
        <Box
          width="160px"
          minWidth="160px"
          justifyContent="flex-start"
          {...(isMobileView && { mb: 1.375 })}
        >
          <DispatchStatusChip dispatchStatus={statusSlug} />
        </Box>
        <Box
          display="flex"
          flexDirection={isMobileView ? "column" : "row"}
          flex="1"
        >
          <Box
            display="flex"
            flex="1"
            flexDirection={isMobileView ? "column" : "row"}
            mb={isMobileView ? 1 : 0}
            alignItems={isMobileView ? "flex-start" : "center"}
          >
            <Box display="flex" alignItems="center">
              {author.__typename === "Driver" && (
                <Box mr={1} display="flex" alignItems="center">
                  <WheelIcon size="small" />
                </Box>
              )}
              <Typography
                variant="caption"
                style={{ fontWeight: "bold", marginRight: "8px" }}
              >
                {authorName}
              </Typography>
            </Box>
            <Typography variant="caption">{`updated status to ${statusName}`}</Typography>
          </Box>
          <Box flex="1">
            <Typography variant="caption" style={{ fontWeight: "bold" }}>
              {formattedTime}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box my={1}>
        {isMobileView && !isLastItem && (
          <Divider style={{ color: grayLight }} />
        )}
      </Box>
    </>
  );
}

export default StatusTimelineItem;
