/**
 * @file graphql/airline.graphql.ts
 * queries/mutations for airlines.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_AIRLINES_QUERY = gql`
  query Airlines($globalSearch: String) {
    airlines(globalSearch: $globalSearch) {
      edges {
        node {
          icaoCode
          iataCode
          airlineName
          callsign
          fleetSize
          countryName
          countryIso2
          dateFounded
        }
      }
    }
  }
`;
