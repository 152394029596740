export const appViewedToTrack = {
  driver: "market_driverPlatformViewed",
  sales_automation: "market_salesAutomationViewed",
  dynamic_pricing: "market_dynamicPricingViewed",
  review_platform: "market_reviewPlatformViewed",
  google_calendar: "market_googleCalendarViewed",
};

export const installToTrack = {
  driver: "market_driverPlatformInstalled",
  sales_automation: "market_salesAutomationInstalled",
  dynamic_pricing: "market_dynamicPricingInstalled",
  review_platform: null,
  google_calendar: "market_calendarInstalled",
};

export const openAppToTrack = {
  driver: "market_driverTrackingOpened",
  sales_automation: null,
  dynamic_pricing: "market_dynamicPricingOpened",
  review_platform: "market_reviewPlatformOpened",
  google_calendar: "market_calendarOpened",
};

export const aboutAppToTrack = {
  driver: "market_aboutDriverPlatformOpened",
  sales_automation: "market_aboutSalesAutomationOpened",
  dynamic_pricing: "market_aboutDynamicPricingOpened",
  review_platform: "market_aboutReviewPlatformOpened",
  google_calendar: "market_aboutAppOpened",
};

export const deleteInitiatedToTrack = {
  driver: "market_deleteDriverPlatformInitiated",
  sales_automation: "market_deleteSalesAutomationInitiated",
  dynamic_pricing: "market_deleteDynamicPricingInitiated",
  review_platform: "market_deleteReviewPlatformInitiated",
  google_calendar: "market_deleteGoogleCalendarInitiated",
};

export const deleteToTrack = {
  driver: "market_driverPlatformDeleted",
  sales_automation: "market_salesAutomationDeleted",
  dynamic_pricing: "market_dynamicPricingDeleted",
  review_platform: "market_reviewPlatformDeleted",
  google_calendar: "market_calendarDeleted",
};
