import { Dispatch, SetStateAction } from "react";
import { useLazyQuery } from "@apollo/client";
import first from "lodash/first";

import { black, errorRed, white } from "design-system/colors";
import { LOAD_POTENTIAL_TRACKED_FLIGHT_QUERY } from "globals/graphql";
import { useSnackbar } from "globals/hooks";
import { Stop, TrackedFlight } from "types";

type UsePotentialTrackedFlightProps = {
  stop: Partial<Stop>;
  onStopChange: (
    stop: Partial<Stop>
  ) => void | Dispatch<SetStateAction<Partial<Stop>>>;
  setMultipleFlightsDialogData: Dispatch<
    SetStateAction<{
      flights: TrackedFlight[];
      flightNumber: string;
      inputAirportIcao: string;
    }>
  >;
};

function usePotentialTrackedFlight(props: UsePotentialTrackedFlightProps) {
  const { stop, onStopChange, setMultipleFlightsDialogData } = props;

  // hooks
  const snackbar = useSnackbar();

  const [
    loadPotentialTrackedFlight,
    { loading: potentialTrackedFlightLoading },
  ] = useLazyQuery<{ loadPotentialTrackedFlight: TrackedFlight[] }>(
    LOAD_POTENTIAL_TRACKED_FLIGHT_QUERY,
    {
      onCompleted: ({ loadPotentialTrackedFlight: trackedFlights }) => {
        // determine outcome based on # of flight results
        if (trackedFlights.length === 0) {
          // no flights match
          snackbar.warning(
            "Flight does not exist. Please check the number and try again",
            { snackbarColor: white, iconColor: errorRed, textColor: black }
          );
          // if typename present, then is update case - this check prevents infinite update loops in TripInfoUpdateBlockItem
          if (!stop.__typename) {
            onStopChange({
              trackedFlight: null,
            });
          }
        } else if (trackedFlights.length === 1) {
          onStopChange({
            ...stop,
            trackedFlight: first(trackedFlights),
            flightNumber: first(trackedFlights).flightNumber,
          });
        } else {
          // multiple flights match
          setMultipleFlightsDialogData({
            flightNumber: first(trackedFlights).flightNumber,
            flights: trackedFlights,
            inputAirportIcao: stop.airport?.icaoCode,
          });
        }
      },

      onError: () => {
        snackbar.error("Could not retrieve flight");
      },
      fetchPolicy: "network-only",
    }
  );

  return {
    loadPotentialTrackedFlight,
    potentialTrackedFlightLoading,
  };
}

export { usePotentialTrackedFlight };
