import { DriverErrors } from "./types";
import { isValidPhoneNumber } from "globals/utils/helpers";
import { CreateDriverInput } from "types";

export const validateCreateDriverInfo = (driver: CreateDriverInput) => {
  let errors: DriverErrors = {};

  // required
  if (!driver.firstName) {
    errors = { ...errors, firstName: "Please enter first name" };
  }

  if (!driver.mobilePhone) {
    errors = {
      ...errors,
      mobilePhone: "Please enter a phone number",
    };
  }

  if (
    driver.phoneCountryCode === "us" &&
    !isValidPhoneNumber(driver.mobilePhone)
  ) {
    errors = {
      ...errors,
      mobilePhone: "Please enter 10 digit mobile phone number",
    };
  }

  return errors;
};
