import React from "react";
import { useMutation } from "@apollo/client";

import { Box, Typography } from "@mui/material";

import LabeledSwitchInput from "components/settings/general/LabeledSwitchInput";
import { useOperator, useScreenSize, useSnackbar } from "globals/hooks";
import { UPDATE_OPERATOR_SETTINGS } from "globals/graphql";

function CustomerReviewSetting() {
  // hooks
  const operator = useOperator();
  const snackbar = useSnackbar();
  const { isMobileView } = useScreenSize();

  const { reviewSiteTextIncludeLink } = operator.settings;

  // mutation
  const [updateOperatorSettings, { loading }] = useMutation(
    UPDATE_OPERATOR_SETTINGS,
    {
      onError() {
        snackbar.error("Error updating settings");
      },
    }
  );

  // event handler
  const handleSettingChange = () => {
    updateOperatorSettings({
      variables: {
        input: {
          reviewSiteTextIncludeLink: !reviewSiteTextIncludeLink,
        },
      },
    });
  };

  return (
    <Box mt={1} mb={4}>
      <Typography variant="overline">Settings</Typography>
      <Box mt={2} ml={-1} maxWidth={isMobileView ? "100%" : "90%"}>
        <LabeledSwitchInput
          name="includeReviewLinkSetting"
          checked={reviewSiteTextIncludeLink}
          onChange={handleSettingChange}
          text="Include Review Link in 'Done' Text Message Sent to Passenger"
          subText="When this is on, your Customer Review link will be included in the automated text message sent to passengers when the trip status is updated to ‘Done’"
          disabled={loading}
        />
      </Box>
    </Box>
  );
}

export default CustomerReviewSetting;
