import React from "react";

type ShuttleReturnTripIconProps = {
  color?: string;
};

const ShuttleReturnTripIcon = (props: ShuttleReturnTripIconProps) => {
  const { color } = props;

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4 13L1 10.6M1 10.6L3.4 8.2M1 10.6H10.6C11.2365 10.6 11.847 10.3471 12.2971 9.89706C12.7471 9.44697 13 8.83652 13 8.2V3.4C13 2.76348 12.7471 2.15303 12.2971 1.70294C11.847 1.25286 11.2365 1 10.6 1H6.2"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShuttleReturnTripIcon;
