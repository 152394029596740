/**
 * @file: canvasUtils.ts
 *
 * helper utils for image uploading
 *
 *  reference:
 *    https://codesandbox.io/s/react-easy-crop-custom-image-demo-forked-0kdzi?file=/src/canvasUtils.js
 *    https://github.com/DominicTobias/react-image-crop
 */
import { Area } from "react-easy-crop/types";
import Resizer from "react-image-file-resizer";

export const getCroppedImg = async (
  imageDataUrl: string,
  crop: Area
): Promise<Blob> => {
  const image: any = await createImage(imageDataUrl);

  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob: Blob) => resolve(blob), "image/png", 1);
  });
};

const createImage = (url: string) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    // image.setAttribute("crossOrigin", "anonymous"); // TODO: verify this is not needed in production
    image.src = url;
  });
};

export const resizeFile = (
  file: File,
  maxWidth: number,
  maxHeight: number
): Promise<string> => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth * 2, // multiple pixels by 2 for retina displays
      maxHeight * 2, // multiple pixels by 2 for retina displays
      "PNG",
      100,
      0,
      (uri: string) => {
        resolve(uri);
      },
      "base64"
      // These features (minWidth/minHeight) cause UI to freeze.
      // I created an issue on this packages github: https://github.com/onurzorluer/react-image-file-resizer/issues/37
      // minWidth,
      // minHeight
    );
  });
};
