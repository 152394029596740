import capitalize from "lodash/capitalize";

import {
  Box,
  Card,
  CardActionArea,
  Chip,
  Switch,
  Typography,
} from "@mui/material";

import { black } from "design-system/colors";
import MoovsAvatar from "design-system/components/MoovsAvatar";
import { getGuestChipStyle } from "../utils";

type GuestCardProps = {
  id: string;
  firstName: string;
  lastName: string;
  guestType: "BOOKING CONTACT" | "PASSENGER" | "DRIVER" | null;
  checked: boolean;
  disabled?: boolean;
  onChange?: () => void;
  isToggleVisible?: boolean;
};

export default function GuestCard(props: GuestCardProps) {
  const {
    id,
    firstName,
    lastName,
    guestType,
    checked,
    disabled,
    onChange,
    isToggleVisible,
  } = props;
  return (
    <Box display="flex" flexDirection="column" my={1} gap={1}>
      <Card variant="outlined" key={id}>
        <CardActionArea disableRipple>
          <Box
            display="flex"
            flexDirection="row"
            flex="1"
            my={0.5}
            py={1.5}
            px={3}
            height="88px"
            alignItems="center"
          >
            <Box>
              {isToggleVisible && (
                <Switch
                  id={id}
                  checked={checked}
                  disabled={disabled}
                  onChange={onChange}
                  name={`${capitalize(firstName)} ${capitalize(lastName)}`}
                  color="primary"
                />
              )}
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flex="1"
              flexWrap="wrap"
            >
              <Box display="flex" alignItems="center">
                <MoovsAvatar
                  alt="contact, person"
                  size="medium"
                  placeholder={[firstName, lastName]}
                />

                <Typography
                  sx={{
                    margin: 1,
                    fontWeight: 700,
                    color: black,
                    fontSize: 16,
                  }}
                >
                  {capitalize(firstName)} {capitalize(lastName)}
                </Typography>
              </Box>

              <Box>
                {guestType && (
                  <Chip
                    label={guestType}
                    style={getGuestChipStyle(guestType)}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </CardActionArea>
      </Card>
    </Box>
  );
}
