import React, { useMemo, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import compact from "lodash/compact";
import uniqBy from "lodash/uniqBy";
import map from "lodash/map";

import { Box, CircularProgress } from "@mui/material";

import DispatchScheduler from "../../components/dispatch/DispatchScheduler/DispatchScheduler";
import AppointmentsFilter from "../../components/dispatch/DispatchScheduler/AppointmentsFilter";
import { useScreenSize } from "../../globals/hooks/useScreenSize";
import { LOAD_DISPATCH_CALENDAR_ROUTES_QUERY } from "../../globals/graphql";
import TripViewHeader from "pages/dispatch/components/TripViewHeader";
import moment from "moment";
import useVehiclesWithColors from "./hooks/useVehiclesWithColors";

type DispatchSchedulerPageProps = {
  onPageViewClick: (_, newView: "list" | "scheduler") => void;
};

function DispatchSchedulerPage(props: DispatchSchedulerPageProps) {
  const { onPageViewClick } = props;

  // hooks
  const appointmentFilterRef = useRef(null);
  const { isSmallTabletView, isMobileView } = useScreenSize();
  const vehiclesWithColors = useVehiclesWithColors();

  // state
  const [selectedDate, setSelectedDate] = useState(new Date());

  // queries
  const { data, loading: calendarViewLoading } = useQuery(
    LOAD_DISPATCH_CALENDAR_ROUTES_QUERY,
    {
      variables: {
        startDate: moment(selectedDate)
          .startOf("month")
          .subtract(1, "week")
          .format("YYYY-MM-DD"),
        endDate: moment(selectedDate)
          .endOf("month")
          .add(1, "week")
          .format("YYYY-MM-DD"),
      },
      fetchPolicy: "cache-and-network",
      pollInterval: 120 * 1000, // 2 min
    }
  );

  const operatorRoutes = useMemo(
    () => data?.operatorRoutes.edges.map(({ node }) => node) || [],
    [data]
  );

  const uniqueVehicles = compact(uniqBy(map(operatorRoutes, "vehicle"), "id"));

  // state
  const [appointmentFilterValue, setAppointmentFilterValue] = useState("all");

  // event handlers
  const handleFilterValueChange = (filterValue) => {
    setAppointmentFilterValue(filterValue);
  };

  return (
    <Box justifyContent="center" width="100%">
      <Box
        position="sticky"
        top={isMobileView ? "56px" : "78px"}
        zIndex={10}
        width="100%"
      >
        <TripViewHeader
          onPageViewClick={onPageViewClick}
          pageViewValue="scheduler"
          appointmentsFilter={
            <AppointmentsFilter
              filterOptions={uniqueVehicles}
              currentFilterValue={appointmentFilterValue}
              onChange={handleFilterValueChange}
              appointmentFilterRef={appointmentFilterRef}
            />
          }
        />
      </Box>
      {calendarViewLoading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width="100%"
          height="100%"
          p={4}
        >
          <CircularProgress size={40} />
        </Box>
      ) : (
        <Box mx={isMobileView ? 0 : isSmallTabletView ? 3 : 10}>
          <DispatchScheduler
            appointmentFilterRef={appointmentFilterRef}
            appointmentFilterValue={appointmentFilterValue}
            vehiclesWithColors={vehiclesWithColors}
            operatorRoutes={operatorRoutes}
            onFilterValueChange={handleFilterValueChange}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
          />
        </Box>
      )}
    </Box>
  );
}

export default DispatchSchedulerPage;
