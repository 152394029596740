/**
 * @file useDimensions.ts
 * Returns element's computed dimensions
 *
 * clientWidth/clientHeight = content + padding
 * offsetWidth/offsetHeight = content + padding + border
 * 
 * we can also get specific values for margins/padding/etc.
 * with window.getComputedStyle(element) but is not necessary right now
 * 
 */

function useDimensions(element: HTMLElement) {
  if (!element) return {};

  const { offsetWidth, offsetHeight, clientWidth, clientHeight } = element;

  return {
    offsetWidth,
    offsetHeight,
    clientWidth,
    clientHeight,
  };
}

export { useDimensions };
