import React from "react";
import moment from "moment-timezone";

import { Box } from "@mui/material";

import TripTimelineStep from "./TripTimelineStep";
import { Trip } from "types";

type TripTimelineProps = {
  trip: Trip;
  variant?: "ride-report" | "default" | "location-only";
  garageIn?: Date;
  garageOut?: Date;
};

function TripTimeline(props: TripTimelineProps) {
  const {
    trip: { stops, estimatedDuration, useTotalDuration },
    variant = "default",
    garageIn,
    garageOut,
  } = props;

  const addedStopsHasDateTime = (() => {
    if (stops.length > 2) {
      const addedStops = stops.slice(1, stops.length - 1);
      for (const stop of addedStops) {
        if (stop.dateTime) {
          return true;
        }
      }
    }
    return false;
  })();

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      {stops.map((stop, index) => {
        const isLastStop = index === stops.length - 1;

        const estimatedDropOffTime =
          isLastStop &&
          !useTotalDuration &&
          moment
            .utc(stops[0].dateTime)
            .add(estimatedDuration, "minutes")
            .toISOString();

        return (
          <TripTimelineStep
            key={index}
            stop={stop}
            variant={variant}
            isLastStop={isLastStop}
            estimatedDropOffTime={estimatedDropOffTime}
            garageIn={garageIn}
            garageOut={garageOut}
            addedStopsHasDateTime={addedStopsHasDateTime}
          />
        );
      })}
    </Box>
  );
}

export default TripTimeline;
