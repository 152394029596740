import React from "react";

import { Box, Typography } from "@mui/material";

import { grayDark } from "design-system/colors";
import { getTripIconAndCategoryType } from "pages/requests/utils/getTripIconAndCategoryType";
import { RoundTripVariant, TripCategory } from "types";
import { useScreenSize } from "globals/hooks";

type TripOrderNumberAndCategoryParams = {
  orderNumber: string; // full order number + trip number - ex: RHJK-15
  tripCategory: TripCategory;
  roundTripVariant?: RoundTripVariant;
};

function TripOrderNumberAndCategory(params: TripOrderNumberAndCategoryParams) {
  const { orderNumber, tripCategory, roundTripVariant } = params;

  // hooks
  const { isMobileView } = useScreenSize();

  const { text: tripCategoryText, icon: tripCategoryIcon } =
    getTripIconAndCategoryType({ tripCategory, roundTripVariant });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          textAlign: !isMobileView ? "right" : "left",
        }}
      >
        {orderNumber}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="caption" sx={{ color: { grayDark }, mr: 1 }}>
          {tripCategoryText}
        </Typography>
        {tripCategoryIcon}
      </Box>
    </Box>
  );
}

export default TripOrderNumberAndCategory;
