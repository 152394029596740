import React from "react";

import { Typography, Box } from "@mui/material";

import { granite, black } from "../../design-system/colors";
import { useOperator } from "../../globals/hooks";

type CompanyLogoProps = {
  size?: "small" | "medium";
};

function CompanyLogo(props: CompanyLogoProps) {
  const { size } = props;

  // hooks
  const { companyLogoUrl, name } = useOperator() || {};

  if (!companyLogoUrl) {
    return (
      <Box>
        <Typography
          variant="h4"
          style={size === "small" ? { color: black } : { color: granite }}
        >
          {name}
        </Typography>
      </Box>
    );
  }

  return (
    <img
      src={companyLogoUrl}
      alt={name}
      style={{ height: size === "small" ? 40 : size === "medium" ? 56 : 80 }}
    />
  );
}

export default CompanyLogo;
