import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMutation } from "@apollo/client";
import { DeepPartial } from "ts-essentials";

import { Box, Checkbox, Typography } from "@mui/material";

import {
  CREATE_OPERATOR_ORDER_TYPE,
  LOAD_OPERATOR_QUERY,
  REMOVE_OPERATOR_ORDER_TYPE,
} from "globals/graphql";
import { useOperator, useSnackbar } from "globals/hooks";
import { OrderType } from "types";
import { moovsBlue } from "design-system/colors";

type OrderTypesItemProps = {
  id: string;
  name: string;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function OrderTypeItem(props: OrderTypesItemProps) {
  const { id, name, setSaveIndicatorState } = props;
  // state
  const [active, setActive] = useState<DeepPartial<OrderType>>();

  // hooks
  const snackbar = useSnackbar();
  const operator = useOperator();
  const initialPageLoadFlag = useRef(false);

  // mutations
  const [createOperatorOrderType] = useMutation(CREATE_OPERATOR_ORDER_TYPE, {
    refetchQueries: [
      {
        query: LOAD_OPERATOR_QUERY,
        variables: { id: operator.id },
      },
    ],
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError() {
      setSaveIndicatorState("error");
    },
  });

  const [removeOperatorOrderType] = useMutation(REMOVE_OPERATOR_ORDER_TYPE, {
    refetchQueries: [
      {
        query: LOAD_OPERATOR_QUERY,
        variables: { id: operator.id },
      },
    ],
    onCompleted() {
      setSaveIndicatorState("saved");
      setActive(null);
    },
    onError(error) {
      const errorMessage = error.message
        ? `${error.message.replace("GraphQL error:", "Error:")}`
        : "Error: At least 1 order type must be selected";
      snackbar.error(errorMessage);
      setSaveIndicatorState("error");
    },
  });

  // event handlers
  const handleOrderTypesCheckboxClick = () => {
    setSaveIndicatorState("loading");

    if (active?.id) {
      // at least 1 order type must be enabled
      // run mutation first, server will throw error if operator is attempting to disable last order type.
      // onComplete() mutation, we setActive(null)
      removeOperatorOrderType({
        variables: { input: { orderTypeId: id } },
      });
    } else {
      setActive({ id, name });

      createOperatorOrderType({
        variables: { input: { orderTypeId: id } },
      });
    }
  };

  // sets checkbox active on load if order type is already enabled
  useEffect(() => {
    if (operator.enabledOrderTypes.length > 0) {
      initialPageLoadFlag.current = false;

      const isEnabled = operator.enabledOrderTypes.some(
        (enabledOrderType) => enabledOrderType.id === id
      );

      if (isEnabled) {
        setActive({ id, name });
      }
    }
  }, [operator.enabledOrderTypes, id, name]);

  return (
    <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
      <Checkbox
        color="primary"
        checked={!!active?.id}
        onChange={handleOrderTypesCheckboxClick}
        style={{ padding: 0, color: moovsBlue }}
      />
      <Box ml={2}>
        <Typography variant="body2">{name}</Typography>
      </Box>
    </Box>
  );
}

export default OrderTypeItem;
