import React from "react";
import moment from "moment-timezone";

import { Box, Typography } from "@mui/material";

import { errorRed, grayDark, orange } from "design-system/colors";
import { MessageIcon } from "design-system/icons";
import { SalesAutomationText } from "types";

type LogItemTextBlockProps = { text: SalesAutomationText };

const LogItemTextBlock = (props: LogItemTextBlockProps) => {
  const { text } = props;
  const recipientFullName =
    text.recipient.firstName + " " + text.recipient.lastName;

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <MessageIcon
        color={
          text.stage === "SCHEDULED"
            ? orange
            : text.stage === "ERROR"
            ? errorRed
            : grayDark
        }
      />
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        whiteSpace="pre"
        alignItems="center"
        ml="18px"
        my={1}
        color={text.stage === "ERROR" && errorRed}
      >
        <Typography display="inline" variant="body2">
          Quote text
        </Typography>
        <Typography
          display="inline"
          variant="body2"
          style={{ fontWeight: 700 }}
        >
          {` ${text.index + 1} `}
        </Typography>
        <Typography display="inline" variant="body2">
          with
        </Typography>
        <Typography
          display="inline"
          variant="body2"
          style={{ fontWeight: 700 }}
        >
          {` retail `}
        </Typography>
        <Typography display="inline" variant="body2">
          {"automation "}
        </Typography>
        <Typography display="inline" variant="body2">
          {text.stage === "SENT" && `sent to `}
          {text.stage === "ERROR" && `failed to send to `}
          {text.stage === "SCHEDULED" && `scheduled to send to `}
        </Typography>
        <Typography
          display="inline"
          variant="body2"
          style={{ fontWeight: 700 }}
        >
          {recipientFullName || "-"}
        </Typography>
        <Typography display="inline" variant="body2">
          {` on `}
        </Typography>
        <Typography
          display="inline"
          variant="body2"
          style={{ fontWeight: 700 }}
        >
          {moment(text.sendDate).format("L LT")}
        </Typography>
      </Box>
    </Box>
  );
};

export default LogItemTextBlock;
