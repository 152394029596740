/**
 * Future improvement: trip list number should be attached to trip and returnTrip on server
 */
import { Box } from "@mui/system";
import React from "react";
import { TripCard } from "./components";
import { NumberedTrip } from "../../types";

type TripsListProps = {
  orderNumber: string;
  trips: NumberedTrip[];
  requestId: string;
};

const TripsList = (props: TripsListProps) => {
  const { trips, orderNumber, requestId } = props;

  return (
    <>
      {trips.map((trip, index) => (
        <Box mb={1} key={trip.id}>
          <TripCard
            trip={trip}
            orderNumber={orderNumber}
            requestId={requestId}
          />
        </Box>
      ))}
    </>
  );
};

export default TripsList;
