import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { usePubNub } from "pubnub-react";
import map from "lodash/map";
import filter from "lodash/filter";
import size from "lodash/size";
import first from "lodash/first";
import find from "lodash/find";

import { Trip } from "types";
import { ParticleLocation, PubNubMessage, TrackedAsset } from "../types";

function useTrackedAssets(trips: Trip[], vehicles: any) {
  // hooks
  const pubnub = usePubNub();

  // refs
  const hasInitialized = useRef(false);

  // state
  const [trackedAssets, setTrackedAssets] = useState<Map<string, TrackedAsset>>(
    new Map()
  );

  // derived state
  const activeOrUpcomingTrips = useMemo(() => {
    const activeTrips = filter(
      trips,
      ({ routes }) => routes[0].dispatchStatus !== "done"
    );

    return new Map(map(activeTrips, (trip) => [trip.id, trip]));
  }, [trips]);

  const routeIds = useMemo(() => {
    let routeIdArray = [];
    for (const trip of activeOrUpcomingTrips.values()) {
      const routeId = trip.routes[0].id;
      routeIdArray.push(routeId);
    }
    return routeIdArray;
  }, [activeOrUpcomingTrips]);

  // event handlers
  // handle incoming pubnub messages
  const handleMessage = useCallback(
    ({ message }: { message: PubNubMessage }) => {
      if (!message.location) return;

      // asset tracker message
      if ("deviceId" in message) {
        const { loc } = JSON.parse(message.location) as ParticleLocation;
        const deviceId = message.deviceId;

        const vehicle = find(vehicles, { deviceId });
        if (!vehicle) return;

        setTrackedAssets((prevTrackedAssets) => {
          prevTrackedAssets.set(deviceId, {
            location: {
              lat: loc.lat,
              lng: loc.lon,
              timestamp: loc.time,
            },
            type: "vehicle",
            id: vehicle.id,
            label: vehicle.name,
          });

          return new Map(prevTrackedAssets);
        });
      } else {
        // driver app message
        const tripId = message.tripId;

        const activeTrip = activeOrUpcomingTrips.get(tripId);
        if (!activeTrip) return;

        const { id, firstName, lastName } = activeTrip.routes[0].driver;

        setTrackedAssets((prevTrackedAssets) => {
          prevTrackedAssets.set(tripId, {
            id,
            location: {
              lat: message.location.coords.latitude,
              lng: message.location.coords.longitude,
              timestamp: message.location.timestamp,
              heading: message.location.coords.heading,
            },
            type: "trip",
            label: `${firstName} ${lastName[0] || ""}`,
          });

          return new Map(prevTrackedAssets);
        });
      }
    },
    [activeOrUpcomingTrips, vehicles]
  );

  // effects
  // initialize
  useEffect(() => {
    // prevent initializing more than once,
    // but wait for incoming trips query to initialize
    if (hasInitialized.current || !size(vehicles)) return;
    hasInitialized.current = true;

    const channels = map(vehicles, "deviceId");

    // call pubnub to get most recent message for each tracked vehicle
    const fetchMessages = async () => {
      const response = await pubnub.fetchMessages({ channels, count: 1 });
      const messages = map(response.channels, first);

      if (!messages) return;

      const newTrackedAssets = new Map();

      messages.forEach(({ message }: { message: PubNubMessage }) => {
        if (!("deviceId" in message)) return;

        const { deviceId, location } = message;

        const vehicle = find(vehicles, { deviceId });

        if (!vehicle) return;

        const { loc } = JSON.parse(location) as ParticleLocation;

        newTrackedAssets.set(deviceId, {
          id: vehicle.id,
          type: "vehicle",
          location: {
            lat: loc.lat,
            lng: loc.lon,
            timestamp: loc.time,
          },
          label: vehicle.name,
        });
      });

      setTrackedAssets(newTrackedAssets);
    };

    if (size(channels)) {
      fetchMessages();
    }
  }, [pubnub, vehicles]);

  // clean up handleMessage callback
  useEffect(() => {
    pubnub.addListener({ message: handleMessage });

    return () => {
      pubnub.removeListener({ message: handleMessage });
    };
  }, [handleMessage, pubnub]);

  // clean up subscription
  useEffect(() => {
    const channels = [...routeIds, ...map(vehicles, "deviceId")];
    pubnub.subscribe({ channels });

    return () => {
      pubnub.unsubscribe({ channels });
    };
  }, [pubnub, routeIds, vehicles]);

  // remove tracked drivers without corresponding active trip
  useEffect(() => {
    const newTrackedAssets = new Map();

    trackedAssets.forEach((asset, id) => {
      if (asset.type === "trip" && !activeOrUpcomingTrips.has(id)) {
        return;
      }

      newTrackedAssets.set(id, asset);
    });

    if (trackedAssets.size !== newTrackedAssets.size) {
      setTrackedAssets(newTrackedAssets);
    }
  }, [activeOrUpcomingTrips, setTrackedAssets, trackedAssets]);

  return {
    trackedAssets: [...trackedAssets.values()],
  };
}

export { useTrackedAssets };
