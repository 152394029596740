import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Box, Button, Typography } from "@mui/material";

import VehiclesDialog from "../../reservations/VehiclesDialog";
import VehicleCard from "../../vehicles/VehicleCard";
import { Vehicle } from "../../../types";
import {
  LOAD_VEHICLES_NODES_QUERY,
  UPDATE_VEHICLE_MUTATION,
} from "../../../globals/graphql";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";

type VehiclesBlockProps = {
  onVehiclesChange?: (selectedVehicles: Vehicle[]) => void;
  mode?: "create" | "update";
  vehicleIds?: string[];
  setSaveIndicatorState?: Function;
  cancellationPolicyId?: string;
  insurancePolicyId?: string;
  refetch?: Function;
};

function VehiclesBlock(props: VehiclesBlockProps) {
  const {
    onVehiclesChange,
    mode = "create",
    vehicleIds = [],
    setSaveIndicatorState,
    cancellationPolicyId,
    insurancePolicyId,
    refetch,
  } = props;

  const snackbar = useSnackbar();

  // queries
  const { data, loading } = useQuery(LOAD_VEHICLES_NODES_QUERY, {
    variables: {
      ids: vehicleIds,
    },
  });

  // state
  const [open, setDialogOpen] = useState(false);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);

  // mutations
  const [updateVehicle] = useMutation(UPDATE_VEHICLE_MUTATION, {
    onCompleted() {
      setSaveIndicatorState("saved");
      refetch();
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error("Error updating vehicle");
    },
  });

  // event handlers
  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const handleAddVehicles = (selectedVehicles: Vehicle[]) => {
    setDialogOpen(false);

    if (mode === "create") {
      setVehicles([...vehicles, ...selectedVehicles]);
      onVehiclesChange([...vehicles, ...selectedVehicles]);
    } else if (mode === "update") {
      setSaveIndicatorState("loading");
      selectedVehicles.forEach((vehicle) => {
        updateVehicle({
          variables: {
            input: {
              id: vehicle.id,
              ...(cancellationPolicyId && { cancellationPolicyId }),
              ...(insurancePolicyId && { insuranceId: insurancePolicyId }),
            },
          },
        });
      });
    }
  };

  const handlePressRemove = (vehicleId: string) => () => {
    const newVehicles = vehicles.filter((vehicle) => vehicle.id !== vehicleId);

    if (mode === "create") {
      setVehicles(newVehicles);
      onVehiclesChange(newVehicles);
    } else if (mode === "update") {
      setSaveIndicatorState("loading");

      updateVehicle({
        variables: {
          input: {
            id: vehicleId,
            ...(cancellationPolicyId && { cancellationPolicyId: null }),
            ...(insurancePolicyId && { insuranceId: null }),
          },
        },
      });
    }
  };

  useEffect(() => {
    if (mode === "create") {
      setVehicles([]);
    }
  }, [mode]);

  useEffect(() => {
    data && data.nodes && setVehicles(data.nodes);
  }, [vehicleIds, data]);

  if (loading) return null;

  return (
    <Box mb={4}>
      <Box my={4} display="flex">
        <Typography variant="h5" style={{ marginRight: 10 }}>
          Vehicles
        </Typography>
      </Box>
      {!vehicles.length ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="130px"
          style={{
            borderRadius: "4px",
            background: "rgba(229, 238, 255, 0.3)",
            border: "1px solid rgba(115, 156, 255, 0.3)",
          }}
        >
          <Button
            size="large"
            variant="outlined"
            color="primary"
            onClick={handleDialogOpen}
          >
            Add Vehicle
          </Button>
        </Box>
      ) : (
        <>
          {vehicles.map((vehicle) => (
            <Box mb={1} key={vehicle.id}>
              <VehicleCard
                vehicle={vehicle}
                ellipsisMenuActions={{
                  onPressRemove: handlePressRemove(vehicle.id),
                }}
              />
            </Box>
          ))}
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              size="large"
              variant="outlined"
              color="primary"
              onClick={handleDialogOpen}
            >
              Add Vehicle
            </Button>
          </Box>
        </>
      )}
      <VehiclesDialog
        open={open}
        dialogMode={"addMultiple"}
        onClose={handleDialogClose}
        onCreate={handleAddVehicles}
        vehiclesToFilterOut={vehicles.map(({ id }) => id)}
      />
    </Box>
  );
}

export default VehiclesBlock;
