/**
 * @file PrivateRoute.tsx
 * Protected route HOC for redirecting unauthenticated users. Wrapper for react-router Route component
 * Reference: https://reacttraining.com/react-router/web/example/auth-workflow
 *
 *
 * author: jackv
 */

import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import some from "lodash/some";

import { useAuth, useCurrentUser } from "../globals/hooks";
import LoadingPage from "pages/LoadingPage";

import AuthenticatedRouteHOC from "./AuthenticatedRouteHOC";

function PrivateRoute({ children, ...rest }) {
  const { path, permissionsOverride } = rest;

  // hooks
  const { authStage } = useAuth();
  const history = useHistory();
  const currentUser = useCurrentUser();

  if (authStage === "pending") {
    return <LoadingPage />;
  }

  const userAccessPermissions = currentUser?.accessPermissions || [];
  const paths: string[] = Array.isArray(path) ? path : [path];

  const userHasAccessToPage =
    permissionsOverride ||
    paths.some((path) =>
      some(
        userAccessPermissions,
        (userAccessPermission) =>
          path.includes(userAccessPermission.path) ||
          // '/companies' and '/affiliates' are in the Contacts Page
          (userAccessPermission.path === "/contacts" &&
            (path === "/companies" || path === "/affiliates"))
      )
    );

  return (
    <Route
      {...rest}
      render={({ location }) => {
        // authentication fails
        if (authStage === "rejected") {
          return (
            <Redirect
              to={{
                pathname: "/login",
                search: history.location.search,
                state: { from: location },
              }}
            />
          );
        }
        if (!userHasAccessToPage) {
          return (
            <Redirect
              to={{
                pathname: "/no-access",
                state: { from: location },
              }}
            />
          );
        }

        return <AuthenticatedRouteHOC>{children}</AuthenticatedRouteHOC>;
      }}
    />
  );
}

export default PrivateRoute;
