import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import flatMap from "lodash/flatMap";
import compact from "lodash/compact";

import { Box, Divider, MenuItem, TextField, Typography } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";
import { OrderTypeEnum, RequestStage } from "types";
import { UPDATE_REQUEST_MUTATION } from "globals/graphql";
import { orderTypeSlugToEnumMap } from "globals/utils/enumMaps";
import { useOperator, useAnalytics, useSnackbar } from "globals/hooks";
type EditOrderTypeDialogProps = {
  open: boolean;
  onClose: () => void;
  requestId: string;
  orderType: OrderTypeEnum;
  requestStage?: RequestStage;
};

function EditOrderTypeDialog(props: EditOrderTypeDialogProps) {
  const { open, onClose, requestId, orderType, requestStage } = props;

  // hooks
  const { enabledOrderTypes } = useOperator() || {};
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // mutations
  const [updateRequest] = useMutation(UPDATE_REQUEST_MUTATION, {
    refetchQueries: ["Request"],
    onCompleted(data) {
      snackbar.success("Successfully updated order type");
    },
    onError: () => snackbar.error("Error updating request order type"),
  });

  // state
  const [dialogOrderType, setDialogOrderType] =
    useState<OrderTypeEnum>(orderType);
  const [requestErrors, setRequestErrors] = useState<string>(null);

  // event handlers
  const handleSaveOrderType = async () => {
    updateRequest({
      variables: {
        input: {
          requestId: requestId,
          orderType: dialogOrderType,
        },
      },
    });

    track(
      requestStage === RequestStage.Reservation
        ? "reservation_orderTypeReplaced"
        : "quote_orderTypeReplaced"
    );
    onClose();
  };

  const handleOrderTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDialogOrderType(event.target.value as OrderTypeEnum);

    setRequestErrors("");
  };

  const handleOnClose = () => {
    setDialogOrderType(orderType);
    onClose();
  };

  // generate order type options
  const orderTypeMenuItems = flatMap(
    enabledOrderTypes,
    ({ id, name, slug, category }, index) => {
      const isNewCategory =
        !index || category !== enabledOrderTypes[index - 1].category;

      return compact([
        index && isNewCategory && (
          <MenuItem key={`${id}_${category}_divider`} disabled>
            <Box mb={1} mt={1} width={"100%"}>
              <Divider />
            </Box>
          </MenuItem>
        ),

        isNewCategory && (
          <MenuItem key={`${id}_${category}`} disabled>
            <Typography variant={"overline"}>{category}</Typography>
          </MenuItem>
        ),

        <MenuItem key={id} value={orderTypeSlugToEnumMap[slug]}>
          <Box>{name}</Box>
        </MenuItem>,
      ]);
    }
  );

  return (
    <MoovsDialog
      open={open}
      onClose={handleOnClose}
      dialogTitle="Replace Order Type"
      onAccept={handleSaveOrderType}
      acceptButtonText="Save"
      size="sm"
    >
      <Box mt={2}>
        <TextField
          label="Order Type"
          fullWidth
          variant="outlined"
          select
          required
          value={dialogOrderType || ""}
          onChange={handleOrderTypeChange}
          error={!!requestErrors}
          helperText={requestErrors}
        >
          {orderTypeMenuItems}
        </TextField>
      </Box>
    </MoovsDialog>
  );
}

export default EditOrderTypeDialog;
