import { useState } from "react";

/**
 * useInputErrors Hook
 * - Manages error states and provides handlers for manipulating error states.
 *
 * * Future Additions to API
 * - A function to set multiple errors at once.
 * - A function to reset all errors at once.
 */

function useInputErrors<T extends object>() {
  const [inputErrors, setInputErrors] = useState<T>({} as T);

  // input error utils
  const resetInputErrorField = (field: keyof T) => {
    setInputErrors((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  const setInputErrorField = (field: keyof T, error: string) => {
    setInputErrors((prev) => ({
      ...prev,
      [field]: error,
    }));
  };

  return {
    inputErrors,
    resetInputErrorField,
    setInputErrorField,
  };
}

export { useInputErrors };
