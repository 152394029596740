import React from "react";
import { Moment } from "moment";

import ComingSoonBlock from "./ComingSoonBlock";
import { DashboardView } from "../DashboardPage";

type AffiliatePayoutViewProps = {
  startDate: Moment;
  endDate: Moment;
};

function AffiliatePayoutView(props: AffiliatePayoutViewProps) {
  const { startDate, endDate } = props;

  return (
    <ComingSoonBlock
      view={DashboardView.AFFILIATE_PAYOUT}
      startDate={startDate}
      endDate={endDate}
    />
  );
}

export default AffiliatePayoutView;
