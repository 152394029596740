import React from "react";

import { Box } from "@mui/material";

import { CompletedTask, IncompleteTask } from "./components";
import { OperatorGettingStarted } from "types";
import { TaskVariant } from "./components/util";

type TaskListProps = {
  operatorGettingStarted: OperatorGettingStarted;
};

function TaskList(props: TaskListProps) {
  const { operatorGettingStarted } = props;

  return (
    <Box display="flex" flexDirection="column">
      {/* Vehicle */}
      <Box mb={1}>
        {!!operatorGettingStarted.taskFirstVehicleCompletedAt ? (
          <CompletedTask task={TaskVariant.VEHICLE} />
        ) : (
          <IncompleteTask task={TaskVariant.VEHICLE} />
        )}
      </Box>

      {/* Driver */}
      <Box mb={1}>
        {!!operatorGettingStarted.taskFirstDriverCompletedAt ? (
          <CompletedTask task={TaskVariant.DRIVER} />
        ) : (
          <IncompleteTask task={TaskVariant.DRIVER} />
        )}
      </Box>

      {/* Quote */}
      <Box mb={1}>
        {!!operatorGettingStarted.taskFirstQuoteCompletedAt ? (
          <CompletedTask task={TaskVariant.QUOTE} />
        ) : (
          <IncompleteTask task={TaskVariant.QUOTE} />
        )}
      </Box>

      {/* Reservation */}
      <Box mb={1}>
        {!!operatorGettingStarted.taskFirstReservationCompletedAt ? (
          <CompletedTask task={TaskVariant.RESERVATION} />
        ) : (
          <IncompleteTask task={TaskVariant.RESERVATION} />
        )}
      </Box>

      {/* Moovs Payment */}
      <Box mb={1}>
        {!!operatorGettingStarted.taskMoovsPaymentAccountCompletedAt ? (
          <CompletedTask task={TaskVariant.MOOVS_PAYMENT} />
        ) : (
          <IncompleteTask task={TaskVariant.MOOVS_PAYMENT} />
        )}
      </Box>
    </Box>
  );
}

export default TaskList;
