import React from "react";
import { Control, FieldErrors, UseFormWatch } from "react-hook-form";

import { Box } from "@mui/material";

import RHFDollarPctAmt from "components/reactHookFormInputs/RHFDollarPctAmt";
import RHFRadioButtons from "components/reactHookFormInputs/RHFRadioButtons";
import { EditDynamicPricingDefaultValues } from "../useEditDynamicPricingDefaultValues";

type AdjustAmountProps = {
  control: Control<EditDynamicPricingDefaultValues>;
  errors: FieldErrors;
  watch: UseFormWatch<EditDynamicPricingDefaultValues>;
};

function AdjustAmount(props: AdjustAmountProps) {
  const { control, errors, watch } = props;

  // @ts-ignore fix later
  const isPercent = watch("isPercent");

  return (
    <>
      <Box my={1.5}>
        <RHFRadioButtons
          name="isIncrease"
          control={control}
          errorMessage={errors.isIncrease?.message}
          radioButtons={[
            { label: "Increase", value: true },
            { label: "Decrease", value: false },
          ]}
        />
      </Box>
      <Box mt={2} mb={2}>
        <RHFDollarPctAmt
          toggleName="isPercent"
          textFieldName="amount"
          errorMessage={errors.amount?.message}
          control={control}
          isPercent={isPercent}
          customDecimal={2}
        />
      </Box>
    </>
  );
}

export default AdjustAmount;
