import React, { ReactNode } from "react";

import { Drawer } from "@mui/material";

type MoovsBottomDrawerProps = {
  open: boolean;
  onClose?: () => void;
  children?: ReactNode;
  overrideZIndex?: number;
  isMobileView?: boolean; // used where backdrops conflict on mobile variants
};

function MoovsBottomDrawer(props: MoovsBottomDrawerProps) {
  const { open, children, onClose, overrideZIndex, isMobileView } = props;

  return (
    <Drawer
      variant="temporary"
      open={open}
      onClose={onClose}
      anchor="bottom"
      sx={{
        "& .MuiDrawer-paper": {
          borderTopLeftRadius: "14px",
          borderTopRightRadius: "14px",
          boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.08)",
        },
        zIndex: overrideZIndex,
      }}
      hideBackdrop={!isMobileView && !!overrideZIndex}
    >
      {children}
    </Drawer>
  );
}

export default MoovsBottomDrawer;
