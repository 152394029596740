import { useCallback, useEffect, useRef } from "react";

import { black, moovsBlue } from "design-system/colors";
import { TrackedAsset } from "pages/driverTracking/types";
import { setIconPathByRotation } from "pages/driverTracking/utils/setIconPathByRotation";

type UseActiveAssetIconProps = {
  map: google.maps.Map;
  trackedAssets: TrackedAsset[];
};

function useActiveAssetIcon(props: UseActiveAssetIconProps) {
  const { map, trackedAssets } = props;

  const markers = useRef<Map<string, google.maps.Marker>>(new Map());

  // event handlers
  const setMapBound = useCallback(() => {
    if (trackedAssets.length === 0) return;

    const bounds = new google.maps.LatLngBounds();

    trackedAssets.forEach(({ location: { lat, lng } }) => {
      if (lat && lng) {
        bounds.extend({ lat, lng });
      }
    });

    if (map) {
      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds);
      if (trackedAssets.length === 1) {
        map.setZoom(11);
      }
    }
  }, [trackedAssets, map]);

  // add, remove, and update Assets by comparing previous markers to incoming trackedAssets
  useEffect(() => {
    if (!map) return;

    let newMarkerAdded = false;
    const newMarkers = new Map();

    trackedAssets.forEach(
      ({
        id,
        label,
        type,
        location: { lat, lng, heading, timestamp: currentTimeStamp },
      }) => {
        if (!lat || !lng) return;

        // if tracked id does not exist in previous markers, create new Marker
        if (!markers.current.has(id)) {
          const marker = new google.maps.Marker({
            position: { lat, lng },
            icon: createAssetIcon(heading),
            map: map,
            label: {
              text: label,
              color: type === "vehicle" ? moovsBlue : black,
              fontWeight: "600",
              fontSize: "14px",
              fontFamily: "Poppins",
            },
          });
          marker.set("timestamp", currentTimeStamp);
          newMarkers.set(id, marker);
          newMarkerAdded = true;
        } else {
          // if tracked id exists in previous markers, and timestamp is different (change of coordinates), update Marker
          const previousMarker = markers.current.get(id);
          markers.current.delete(id);

          if (currentTimeStamp !== previousMarker["timestamp"]) {
            previousMarker.setPosition({ lat, lng });
            previousMarker.setIcon(createAssetIcon(heading));
            previousMarker.set("timestamp", currentTimeStamp);
          }
          newMarkers.set(id, previousMarker);
        }
      }
    );

    if (newMarkerAdded || markers.current?.size > 0) setMapBound();

    // remove inactive markers on map
    markers.current.forEach((marker) => marker.setMap(null));

    markers.current = newMarkers;
  }, [trackedAssets, map, setMapBound]);
}

const createAssetIcon = (rotation: number) => {
  return {
    url: setIconPathByRotation(rotation),
    anchor: new google.maps.Point(12, 20),
    labelOrigin: new google.maps.Point(16, 42),
    scaledSize: new google.maps.Size(32, 32),
  };
};

export default useActiveAssetIcon;
