import moment from "moment";
import * as yup from "yup";
import { getWeekdaysFromDateRange } from "../../../EditDynamicPricingDialog/EditDynamicPricingForm/utils/getWeekdaysFromDateRange";

export const dateRangeSchema = yup.object({
  startDate: yup
    .date()
    .required("Please select a date")
    .typeError("Please select a date"),
  endDate: yup
    .date()
    .min(
      yup.ref("startDate"),
      "Please select an end date that occurs after start date"
    )
    .required("Please select a date")
    .typeError("Please select a date"),
  daysOfWeek: yup
    .array()
    .of(
      yup.object({
        id: yup.string(),
        value: yup.string(),
        isSelected: yup.boolean(),
      })
    )
    .test({
      name: "Weekdays selected are within date range",
      message: "Please select weekdays within date range",
      test: function () {
        const { startDate, endDate, daysOfWeek } = this.parent;

        const formattedStartDate = moment.utc(startDate).format("ll");
        const formattedEndDate = moment.utc(endDate).format("ll");

        const weekdaysFromDateRange = getWeekdaysFromDateRange({
          formattedStartDate,
          formattedEndDate,
        });

        const selectedWeekdays = daysOfWeek
          .filter((day) => day.isSelected)
          .map((day) => day.value);

        return selectedWeekdays.every((selected) =>
          weekdaysFromDateRange.includes(selected)
        );
      },
    })
    .test({
      name: "Check if at least one weekday is selected",
      message: "Please select at least one day of week",
      test: function () {
        const { daysOfWeek } = this.parent;

        return daysOfWeek.some((item) => item.isSelected);
      },
    })
    .required("Please select at least one day of week"),
});
