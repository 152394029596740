import React from "react";

import { Box, Typography } from "@mui/material";

import { granite } from "design-system/colors";
import { DollarInlineInput } from "design-system/components/inputs/inline/InlineInputs";
import { useScreenSize } from "globals/hooks";

type LabeledDollarInputProps = {
  value: string;
  onChange: (event: any) => void;
  text: string;
  subText: string;
  placeholder: string;
  name?: string;
};

function LabeledDollarInput(props: LabeledDollarInputProps) {
  const { value, onChange, text, subText, placeholder, name } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      {/* Labels */}
      <Box display="flex" flexDirection="column">
        <Box mb={0.5}>
          <Typography variant="body2">{text}</Typography>
        </Box>
        <Box mb={0.5}>
          <Typography variant="caption" style={{ color: granite }}>
            {subText}
          </Typography>
        </Box>
      </Box>

      {/* Input */}
      <Box
        width={!isMobileView && "128px"}
        minWidth={isMobileView && "128px"}
        ml={isMobileView && 1}
      >
        <DollarInlineInput
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          name={name}
        />
      </Box>
    </Box>
  );
}

export default LabeledDollarInput;
