import { Contact } from "types";

/**
 * Return the temporary passenger if one exists. Otherwise, return trip contact.
 */
export const getPassenger = (
  contact: Contact,
  temporaryPassenger: { name?: string; mobilePhone?: string },
  isFarmee: boolean
) => {
  const isTemporaryPassenger = !!temporaryPassenger?.name;

  let name = isTemporaryPassenger
    ? temporaryPassenger.name
    : `${contact.firstName} ${contact.lastName}`;

  let mobilePhone = isTemporaryPassenger
    ? temporaryPassenger.mobilePhone
    : contact.mobilePhone;

  const email = isTemporaryPassenger ? null : contact.email;

  if (isFarmee) {
    // farmees see displayed contact's with last name initial - this conceals some farmee info.
    name = getAbbreviatedName(name);
    mobilePhone = "";
  }

  return {
    name,
    mobilePhone,
    email,
  };
};

const getAbbreviatedName = (name: string) => {
  const nameParts = name.split(" ");
  const lastInitial = nameParts[nameParts.length - 1][0];

  nameParts.pop();

  return `${nameParts.join(" ")} ${lastInitial}.`;
};
