import React from "react";
import keys from "lodash/keys";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import size from "lodash/size";

import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Alert } from "types";
import { grayMedium, moovsBlue, moovsBlueSelected } from "design-system/colors";
import {
  WheelIcon,
  MapIcon,
  TruckIcon,
  SyncIcon,
  BriefcaseIcon,
  ClipboardIcon,
  SeatIcon,
  PlaneIcon,
} from "design-system/icons";
import MoovsTooltip from "components/MoovsTooltip";

export const alertsMap = {
  newDriver: {
    title: "New Driver",
    icon: WheelIcon,
  },
  uniqueLocation: {
    title: "Location",
    icon: MapIcon,
  },
  airport: {
    title: "Airport",
    icon: PlaneIcon,
  },
  largeVehicle: {
    title: "Large Vehicle",
    icon: TruckIcon,
  },
  partner: {
    title: "Partner",
    icon: SyncIcon,
  },
  luggage: {
    title: "Luggage",
    icon: BriefcaseIcon,
  },
  signage: {
    title: "Signage",
    icon: ClipboardIcon,
  },
  carSeat: {
    title: "Car Seat",
    icon: SeatIcon,
  },
};

function AlertsColumn(props: GridRenderCellParams<string>) {
  const {
    row: { routes },
  } = props;

  const alerts = routes[0].alert as Alert;
  const activeAlerts = keys(pickBy(omit(alerts, "__typename")));

  if (!size(activeAlerts)) {
    return (
      <Typography variant="body2" color={grayMedium}>
        Add Alerts
      </Typography>
    );
  }
  return (
    <Box display="flex" flexDirection="row" justifyContent="center">
      {activeAlerts.map((alert, i) => {
        const { title, icon } = alertsMap[alert];
        const Icon = icon;

        return (
          <MoovsTooltip tooltipText={title} key={i}>
            <Box
              sx={{
                backgroundColor: moovsBlueSelected,
                width: "24px",
                height: "24px",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 0.5,
              }}
            >
              <Icon color={moovsBlue} size="small" />
            </Box>
          </MoovsTooltip>
        );
      })}
    </Box>
  );
}

export default AlertsColumn;
