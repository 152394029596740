import React from "react";

type SeparatorDotIconProps = {
  color?: string;
};

const SeparatorDotIcon = (props: SeparatorDotIconProps) => {
  const { color } = props;
  return (
    <svg
      width="4"
      height="5"
      viewBox="0 0 4 5"
      fill={color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.87 4.046C1.394 4.046 0.988 3.88267 0.652 3.556C0.325333 3.22 0.162 2.814 0.162 2.338C0.162 1.85267 0.325333 1.44667 0.652 1.12C0.988 0.784 1.394 0.616 1.87 0.616C2.35533 0.616 2.766 0.784 3.102 1.12C3.438 1.44667 3.606 1.85267 3.606 2.338C3.606 2.814 3.438 3.22 3.102 3.556C2.766 3.88267 2.35533 4.046 1.87 4.046Z"
        fill="#999999"
      />
    </svg>
  );
};

export default SeparatorDotIcon;
