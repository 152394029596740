import React from "react";
import { Moment } from "moment";
import kebabCase from "lodash/kebabCase";
import startCase from "lodash/startCase";
import camelCase from "lodash/camelCase";

import { Box, Card, Typography } from "@mui/material";

import ReportSkeleton from "./ReportSkeleton";
import { DashboardView } from "../DashboardPage";
import RoundChip from "design-system/components/chips/RoundChip";
import { orange, white } from "design-system/colors";
import DownloadButton from "components/buttons/DownloadButton";
import { useCurrentUser } from "globals/hooks";

type ComingSoonBlockProps = {
  startDate: Moment;
  endDate: Moment;
  view: DashboardView;
  companyId?: string;
  analyticsName?: string;
};

function ComingSoonBlock(props: ComingSoonBlockProps) {
  const { startDate, endDate, view, companyId, analyticsName } = props;
  const { isSwoopCurrentOperator } = useCurrentUser();

  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: 2,
      }}
    >
      <Box
        display="flex"
        height="40px"
        width="100%"
        alignItems="flex-start"
        justifyContent="flex-end"
      >
        {isSwoopCurrentOperator && view === DashboardView.AFFILIATE_PAYOUT && (
          <DownloadButton
            buttonText="Download White Label Report"
            endpoint={`/reports/white-label/download`}
            params={{
              startDate: startDate.toISOString(),
              endDate: endDate.toISOString(),
            }}
          />
        )}
        <DownloadButton
          buttonText="Download Report"
          endpoint={`/reports/${kebabCase(view)}/download`}
          analyticsName={
            analyticsName || `dashbord_reporting${startCase(camelCase(view))}`
          }
          params={{
            companyId,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          }}
        />
      </Box>
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <ReportSkeleton />
        <Box mt={3}>
          <RoundChip
            label="COMING SOON"
            color={white}
            backgroundColor={orange}
          />
        </Box>
        <Typography variant="h2" my={1.5}>
          {view}
        </Typography>
        <Typography variant="body2">{view} dashboard coming soon.</Typography>
      </Box>
    </Card>
  );
}

export default ComingSoonBlock;
