import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Box, Card, CardActionArea, Divider } from "@mui/material";

import { Request, Trip } from "../../../../../../../../types";
import { confirmationNumberFromRequest } from "../../../../../../../../globals/utils/helpers";
import { orderTripsByAscendingStopTimes } from "../../../../../../requests.utils";
import { TripInformationDesktop } from "./components";
import { useAnalytics } from "globals/hooks";
import { UpdateQuoteActionButtonVariant } from "../../../../types";
import { QuoteTabViewVariant } from "components/requests/update/UpdateQuoteDrawer/components/QuoteTabViews/types";

const { VIEW_TRIP, ADD_PRICE, ADD_VEHICLE } = UpdateQuoteActionButtonVariant;
const { TRIP_DETAILS, PRICING, VEHICLE } = QuoteTabViewVariant;

type QuoteTripItemsDesktopProps = {
  request: Request;
};

function QuoteTripItemsDesktop(props: QuoteTripItemsDesktopProps) {
  const { request } = props;

  // hooks
  const { track } = useAnalytics();
  const history = useHistory();
  const location = useLocation();

  // derived state
  const orderedTrips = orderTripsByAscendingStopTimes(request.trips);

  // events
  const handleTripClick = (
    btnVariant: UpdateQuoteActionButtonVariant,
    tripId: string
  ) => {
    track("quotes_tripOpened", { tripAction: btnVariant });

    const tabView =
      btnVariant === ADD_PRICE
        ? PRICING
        : btnVariant === ADD_VEHICLE
        ? VEHICLE
        : TRIP_DETAILS;

    history.push(`/quotes/${request.id}/update/${tripId}/${location.search}`, {
      tabView,
    });
  };

  return (
    <>
      {orderedTrips.map((trip: Trip, i) => {
        const confirmationNumber = confirmationNumberFromRequest(request, trip);

        const isRoundTrip = !!trip.returnTrip;
        const returnTripConfirmationNumber = confirmationNumberFromRequest(
          request,
          trip.returnTrip
        );
        const btnVariant =
          request.isExpired || request.isArchived
            ? VIEW_TRIP
            : !trip.totalAmount
            ? ADD_PRICE
            : !trip.routes[0].vehicle
            ? ADD_VEHICLE
            : VIEW_TRIP;

        const returnTripBtnVariant =
          request.isExpired || request.isArchived
            ? VIEW_TRIP
            : !trip.returnTrip?.totalAmount
            ? ADD_PRICE
            : !trip.returnTrip?.routes[0].vehicle
            ? ADD_VEHICLE
            : VIEW_TRIP;

        return (
          <Box key={trip.id} mb={1}>
            <Card variant="outlined">
              <CardActionArea
                disableRipple
                onClick={() => handleTripClick(btnVariant, trip.id)}
              >
                <TripInformationDesktop
                  confirmationNumber={confirmationNumber}
                  trip={trip}
                  btnVariant={btnVariant}
                />
              </CardActionArea>
              {isRoundTrip && (
                <>
                  <Box px={0.5}>
                    <Divider />
                  </Box>
                  <CardActionArea
                    onClick={() =>
                      handleTripClick(returnTripBtnVariant, trip.returnTrip.id)
                    }
                  >
                    <TripInformationDesktop
                      confirmationNumber={returnTripConfirmationNumber}
                      trip={trip.returnTrip}
                      btnVariant={returnTripBtnVariant}
                    />
                  </CardActionArea>
                </>
              )}
            </Card>
          </Box>
        );
      })}
    </>
  );
}

export default QuoteTripItemsDesktop;
