import React from "react";
import { FieldPath } from "react-hook-form";
import isEmpty from "lodash/isEmpty";

import { Box, Typography } from "@mui/material";

import VehiclePricingBlock from "components/vehicles/VehiclePricingBlock";
import { getBRAVehiclePricingErrors } from "../../util";
import VehicleFormSection from "components/vehicles/VehicleFormSection";
import { useAnalytics } from "globals/hooks";
import {
  UpdateVehicleFormData,
  useUpdateVehicleFormContext,
} from "../../hooks/useUpdateVehicleForm";

function Pricing() {
  // hooks
  const { track } = useAnalytics();

  const formContext = useUpdateVehicleFormContext();

  if (!formContext) {
    console.error(
      "Pricing component must be used within UpdateVehicleFormContext"
    );
    return (
      <Box mt={4}>
        <Typography variant="body2" color="error">
          Error loading vehicle pricing. Please refresh the page.
        </Typography>
      </Box>
    );
  }

  // event handlers
  const handleEnableBaseRateAutomationChange = (event) => {
    let { checked } = event.target;

    if (!checked) return checked;

    const [
      minimumTotalBaseRate,
      deadheadRatePerMile,
      tripRatePerMile,
      weekdayHourlyCost,
      weekdayMinMinutes,
      weekendHourlyCost,
      weekendMinMinutes,
      weekends,
    ] = formContext.watch([
      "vehicle.minimumTotalBaseRate",
      "vehicle.deadheadRatePerMile",
      "vehicle.tripRatePerMile",
      "vehicle.weekdayHourlyCost",
      "vehicle.weekdayMinMinutes",
      "vehicle.weekendHourlyCost",
      "vehicle.weekendMinMinutes",
      "vehicle.weekends",
    ]);

    const pricingErrors = getBRAVehiclePricingErrors({
      minimumTotalBaseRate,
      deadheadRatePerMile,
      tripRatePerMile,
      weekdayHourlyCost,
      weekdayMinMinutes,
      weekendHourlyCost,
      weekendMinMinutes,
      weekends,
    });

    if (isEmpty(pricingErrors)) {
      track("vehicle_braEnabled");
      return checked;
    }

    // map formContext.setError to pricingErrors
    Object.entries(pricingErrors).forEach(([field, error]) => {
      if (field === "settings") field = "weekends";

      formContext.setError(
        `vehicle.${field}` as FieldPath<UpdateVehicleFormData>,
        {
          message: error,
        }
      );
    });

    return false;
  };

  return (
    <Box mt={4}>
      <VehicleFormSection title="Pricing">
        <VehiclePricingBlock
          formContext={formContext}
          handleEnableBaseRateAutomationChange={
            handleEnableBaseRateAutomationChange
          }
        />
      </VehicleFormSection>
    </Box>
  );
}

export default Pricing;
