import React from "react";
import { useHistory } from "react-router-dom";

import { IconButton, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { moovsBlue } from "design-system/colors";
import { OpenIcon } from "design-system/icons";

function TripConfNumberColumn(props: GridRenderCellParams<string>) {
  const { row: trip } = props;

  // hooks
  const history = useHistory();

  const confNumber = `${trip.request.orderNumber}-${trip.tripNumber}`;

  const navigateToTrip = () => {
    history.push(`/reservations/${trip.request.id}?tripId=${trip.id}`, {
      backNavigationPath: `${history.location.pathname}${history.location.search}`,
    });
  };

  return (
    <IconButton
      onClick={() => navigateToTrip()}
      style={{ padding: 0, backgroundColor: "transparent" }}
    >
      <Typography variant="body2" px={1} color={moovsBlue} fontWeight="bold">
        {confNumber}
      </Typography>
      <OpenIcon size="xs" color={moovsBlue} />
    </IconButton>
  );
}

export default TripConfNumberColumn;
