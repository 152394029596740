import React, { useState, Fragment, useEffect, useCallback } from "react";
import { DeepPartial } from "ts-essentials";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import last from "lodash/last";
import first from "lodash/first";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import reverse from "lodash/reverse";
import startCase from "lodash/startCase";
import omit from "lodash/omit";
import isNil from "lodash/isNil";
import { useHistory, useLocation } from "react-router-dom";
import { parse, stringify } from "query-string";
import theme from "theme";

import { Box } from "@mui/material";

import {
  Request,
  Trip,
  Contact,
  Vehicle,
  Stop,
  RoutePricing,
  VehicleChildSeat,
} from "types";
import { useSnackbar } from "globals/hooks/useSnackbar";
import {
  CREATE_RETURN_TRIP_MUTATION,
  LOAD_REQUEST_QUERY,
  LOAD_AUTOMATED_BASE_RATE,
  LOAD_TRIP_QUERY,
} from "globals/graphql";
import CreateDrawer from "../../../globals/CreateDrawer";
import AddVehicleCreateBlock from "../../../vehicles/AddVehicleCreateBlock";
import CommentCreateBlock from "../../../CommentCreateBlock";
import { useLocalStorage } from "globals/hooks/useLocalStorage";
import { useAnalytics, useOperator, useScreenSize } from "globals/hooks";
import { calculateTotalDurationMinutes } from "globals/utils/stops";
import { shapeStopsToAutomatedBaseRateQueryStopsArg } from "globals/graphql/automatedPricing";
import {
  initialRequestTemplate,
  initialRequestErrors,
  convertTripToBeInputReady,
  removeStop,
  createStop,
} from "globals/utils/createRequests";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { initialLuggageFields } from "components/requests/luggage/initialValues";
import { useCreateRequestTripType } from "./hooks/useCreateRequestTripType";
import {
  AdditionalInfoCreateBlock,
  PricingInfoCreateBlock,
  TripDetailsCreateBlock,
  TripInfoCreateBlock,
} from "./components";
import { getChildSeatOptionsFromVehicle } from "./helpers/getChildSeatOptionsFromVehicle";
import { getContactForReservation } from "globals/utils/contact";

export type CreateReturnTripDrawerProps = {
  returnTripFor: "quote" | "reservation";
};

function CreateReturnTripDrawer(props: CreateReturnTripDrawerProps) {
  const { returnTripFor } = props;

  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const location = useLocation<{
    request: Request;
    trip: Trip;
    from?: string;
  }>();
  const { track } = useAnalytics();
  const { isMobileView } = useScreenSize();
  const { enabledTripCategories } = useOperator() || {};

  const orderNumber = location?.state?.request?.orderNumber;

  const pageLabel = `Add Trip to ${orderNumber}`;
  const localStorageKey = `submit${startCase(returnTripFor)}Settings`;
  const getCloseDrawerLink = (requestId: string) =>
    `/${returnTripFor}s/${requestId}`;

  // query for automated base rate
  const [getAutomatedBaseRate, { loading: automatedBaseRateLoading }] =
    useLazyQuery(LOAD_AUTOMATED_BASE_RATE, {
      onCompleted(data) {
        setAutomatedBaseRate(data.automatedBaseRate);
      },
      onError(error) {
        const errorMessage =
          getErrorMessage(error) || "Failed to get automated base rate";

        snackbar.error(errorMessage);
      },
      fetchPolicy: "network-only",
    });

  const { data: tripData } = useQuery(LOAD_TRIP_QUERY, {
    variables: {
      id: location?.state?.trip.id,
    },
  });

  // state
  const [request, setRequest] = useState<DeepPartial<Request>>(
    JSON.parse(JSON.stringify(initialRequestTemplate))
  );
  const [requestErrors, setRequestErrors] = useState(
    JSON.parse(JSON.stringify(initialRequestErrors))
  );
  const [saveErrorText, setSaveErrorText] = useState("");
  const [selectedSubmitButtonIndex, setSelectedSubmitButtonIndex] =
    useLocalStorage(localStorageKey, 0);
  const [suggestedAddressInfo, setSuggestedAddressInfo] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [bookingContact, setBookingContact] =
    useState<DeepPartial<Contact>>(null);

  const [isVehicleBaseRateAutomated, setIsVehicleBaseRateAutomated] =
    useState(false);
  const [automatedBaseRate, setAutomatedBaseRate] = useState(null);
  const [luggageAmount, setLuggageAmount] = useState(initialLuggageFields);
  const [isBaseRateAutomationClicked, setIsBaseRateAutomationClicked] =
    useState(false);
  //  To support for BRA icon click when vehicle is not changed but BRA icon is clicked for exisitng vehicle.
  const [existingAutomatedBaseRate, setExisitngAutomatedBaseRate] =
    useState(null);
  const [childSeatOptions, setChildSeatOptions] = useState<VehicleChildSeat[]>(
    []
  );

  // more hooks
  const { tripType, submitLabelOptions } = useCreateRequestTripType({
    enabledTripCategories,
    setRequest,
    inputTrip: first(request.trips) as Trip,
    defaultSubmitLabelOptions:
      location.state?.from === "create"
        ? [`Save ${returnTripFor}`, `Save ${returnTripFor} & send email`]
        : location.state?.from === "add_trip"
        ? ["Save trip"]
        : [`Save ${returnTripFor}`],
  });

  // derived state
  const stops = request.trips[0].stops;

  const vehicleId = request.trips[0].routes[0].vehicle?.id;
  const hasEnabledVehicleBRA =
    request.trips[0].routes[0].vehicle?.enableBaseRateAutomation;
  const shouldSendEmail = selectedSubmitButtonIndex === 1;
  const linkedPassengerIds = bookingContact?.linkedPassengers?.map(
    (passenger) => passenger.id
  );

  // mutations
  const [createReturnTrip] = useMutation(CREATE_RETURN_TRIP_MUTATION, {
    onCompleted(data) {
      let tripId;

      if (returnTripFor === "reservation") {
        tripId = data.createReturnTrip.trip.id;
      }
      const trip = data.createReturnTrip.trip;
      if (trip.tempPassenger?.name) {
        track(`${returnTripFor}_tripAdded`, {
          passengerType: "Temporary Passenger",
        });
      } else if (
        trip.contact.id === bookingContact.id &&
        !trip.tempPassenger?.name
      ) {
        track(`${returnTripFor}_tripAdded`, {
          passengerType: "Booking Contact",
        });
      } else if (linkedPassengerIds.includes(trip.contact.id)) {
        track(`${returnTripFor}_tripAdded`, {
          passengerType: "Linked Passenger",
        });
      }

      snackbar.success("Successfully added return trip");
      track(`${returnTripFor}_returnTripAdded`);

      handleCreateRequestDrawerClose(tripId);
    },
    onError(error) {
      setSubmitDisabled(false);
      const errorMessage = getErrorMessage(error) || "Error creating trip";

      snackbar.error(errorMessage);
    },
    refetchQueries: [
      {
        query: LOAD_REQUEST_QUERY,
        variables: {
          id: request.id,
        },
      },
      "LoadTripConflict",
    ],
    awaitRefetchQueries: true,
  });

  // event handlers
  const updateTrip = useCallback(
    (newTrip: DeepPartial<Trip>, tripIndex: number) => {
      setRequest((prevRequest) => {
        const newTrips = [...prevRequest.trips];
        newTrips.splice(tripIndex, 1, newTrip);
        return {
          ...prevRequest,
          trips: newTrips,
        };
      });
    },
    []
  );

  const handleCreateRequestDrawerClose = (tripId?: string) => {
    let search = location.search;

    // if passed a tripId, makes sure that that tripId is showing when drawer closes
    if (tripId) {
      const parsed = parse(search);

      search = `?${stringify({
        ...parsed,
        tripId,
      })}`;
    }

    setTimeout(() => {
      history.push({
        search,
        pathname: getCloseDrawerLink(request.id),
      });
    }, 200);
  };

  const updateRoute =
    (tripIndex: number) =>
    (targetName: string, targetValue: number | string | boolean) => {
      const newRoutes = [...request.trips[tripIndex].routes];
      const newRoute = newRoutes[0];

      newRoute[targetName] = targetValue;
      const newTrip = { ...request.trips[tripIndex], routes: newRoutes };

      updateTrip(newTrip, tripIndex);
    };

  const handleCreateStop = (tripIndex: number) => () => {
    const newTrips = createStop(request.trips, tripIndex);

    const stopsErrors = [...requestErrors.stops];
    const lastStopErrors = stopsErrors.pop();
    requestErrors.stops = [...requestErrors.stops];

    setRequestErrors({
      ...requestErrors,
      stops: [
        ...stopsErrors,
        {
          location: "",
          dateTime: "",
          stopIndex: requestErrors.stops.length,
        },
        {
          ...lastStopErrors,
          stopIndex: requestErrors.stops.length + 1,
        },
      ],
    });

    setRequest({
      ...request,
      trips: newTrips,
    });
  };

  const handleRemoveStop = (tripIndex: number) => (stopIndex: number) => () => {
    const newTrips = removeStop(request.trips, tripIndex, stopIndex);

    setRequest({
      ...request,
      trips: newTrips,
    });

    requestErrors.stops.splice(stopIndex - 1, 1);

    setRequestErrors({
      ...requestErrors,
      stops: requestErrors.stops,
    });
  };

  const addVehicles = (tripIndex: number) => (vehicles: Partial<Vehicle>[]) => {
    setIsVehicleBaseRateAutomated(vehicles[0].enableBaseRateAutomation);

    const childSeats = getChildSeatOptionsFromVehicle(vehicles[0]);
    setChildSeatOptions(childSeats);

    const newTrips = [...request.trips];
    newTrips[tripIndex].routes[0].vehicle = { id: vehicles[0].id };

    setRequest({
      ...request,
      trips: newTrips,
    });
  };

  const removeVehicle = (tripIndex: number) => () => {
    setIsVehicleBaseRateAutomated(false);
    setAutomatedBaseRate(null);
    setChildSeatOptions([]);
    const newTrips = [...request.trips];
    delete newTrips[tripIndex].routes[0].vehicle;

    setRequest({
      ...request,
      trips: newTrips,
    });
    setIsVehicleBaseRateAutomated(false);
  };

  const handleSubmitClick = () => {
    let errors = [];

    let newSaveErrorText = "Oops! Please check the above fields for errors.";

    // booking contact
    if (!bookingContact) {
      errors = [...errors, { bookingContact: "Please add a booking contact" }];
    }

    // vehicle errors
    if (!request.trips[0].routes[0].vehicle) {
      errors = [...errors, { vehicle: "Please select a vehicle" }];
    }

    // stop errors
    const stopErrors = request.trips[0].stops.map((stop) => {
      const addressErrors = {
        location: !stop.location ? "Please enter location" : "",
      };

      const airportErrors = {
        airport: !stop.airport ? "Please select an Airport" : "",
        airline:
          !!stop.flightNumber && !stop.airline
            ? "Please select an Airline"
            : "",
      };

      const isLocationError = !!addressErrors.location;
      const isAirportError = Object.values(airportErrors).some(
        (error) => !!error.length
      );

      // if error in both show errors
      // if only one has an error that means it
      // isnt the variant that is selected
      if (isLocationError && isAirportError) {
        return {
          ...addressErrors,
          ...airportErrors,
          dateTime:
            !stop.dateTime && stop.stopIndex === 1 ? "Please enter date" : "",
        };
      }

      return {
        dateTime:
          !stop.dateTime && stop.stopIndex === 1 ? "Please enter date" : "",
      };
    });

    const isErrorStops = stopErrors.some((error) =>
      Object.values(error).some((value) => value.length)
    );

    if (errors.length > 0 || isErrorStops) {
      errors = [...errors, { stops: stopErrors }];

      setRequestErrors(
        errors.reduce((acc, value) => ({ ...acc, ...value }), {})
      );

      setSaveErrorText(newSaveErrorText);
      return;
    }

    // sets type as input type (as stop input is different than stop type)
    const requestInput: DeepPartial<Request> = cloneDeep(request);

    // converts airport and airlines from objects to just the code for input
    requestInput.trips = requestInput.trips.map((trip: Trip) =>
      convertTripToBeInputReady(trip, luggageAmount)
    );

    // set default booking contact if nothing is picked
    requestInput.trips = requestInput.trips.map((trip: Trip) => {
      if (!trip.contact?.firstName) {
        trip.contact = getContactForReservation(
          bookingContact as Contact
        ) as Contact;
      } else {
        trip.contact = getContactForReservation(trip.contact) as Contact;
      }
      return trip;
    });

    setSaveErrorText("");
    setSubmitDisabled(true);

    const { trip } = location.state;

    const baseRateAutomation = automatedBaseRate || existingAutomatedBaseRate;

    const addReturnTripInput = {
      requestId: requestInput.id,
      firstTripId: trip.id,
      contactId: first(requestInput.trips).contact.id,
      temporaryPassenger: first(requestInput.trips).temporaryPassenger,
      // within this context, our "request" only has
      // the return trip
      stops: requestInput.trips[0].stops.map((stop) => ({
        location: stop.location,
        stopIndex: stop.stopIndex,
        dateTime: stop.dateTime,
        groupSize: stop.groupSize,
        note: stop.note,
        flightNumber: stop.flightNumber,
        airportIcao: stop.airportIcao,
        airlineIcao: stop.airlineIcao,
        trackedFlight: stop.trackedFlight,
        variant: stop.variant,
        logisticStreetViewUrl: stop.logisticStreetViewUrl,
        operatorNote: stop.operatorNote,
      })),
      routes: first(requestInput.trips).routes,
      totalGroupSize: first(requestInput.trips).totalGroupSize,
      note: first(requestInput.trips).note,
      comments: first(requestInput.trips).comments,
      ...(isBaseRateAutomationClicked && {
        baseRateAutomation: {
          total: baseRateAutomation.total,
          lineItems: baseRateAutomation.lineItems.map((lineItem) =>
            omit(lineItem, "__typename")
          ),
        },
      }),
    };

    createReturnTrip({
      variables: {
        input: { ...addReturnTripInput, shouldSendEmail },
      },
    });
  };

  const handleTripCommentChange =
    (trip: Trip, tripIndex: number) => (value: string) => {
      updateTrip(
        {
          ...trip,
          comments: [{ bodyText: value }],
        },
        tripIndex
      );
    };

  // effects

  // if requirements are met, get automated pricing
  useEffect(() => {
    const firstStop = first(stops);
    const lastStop = last(stops);
    if (
      isVehicleBaseRateAutomated &&
      (firstStop.location || firstStop.airport) &&
      firstStop.dateTime &&
      (lastStop.location || lastStop.airport)
    ) {
      getAutomatedBaseRate({
        variables: {
          vehicleId: vehicleId,
          // TODO: Remove 'Partial' Utility types from types in this file and redefine types as necessary.
          // If properly resolved, removing this type cast should automatically resolve to Stop[].
          stops: shapeStopsToAutomatedBaseRateQueryStopsArg(stops as Stop[]),
        },
      });
    }
  }, [vehicleId, isVehicleBaseRateAutomated, getAutomatedBaseRate, stops]);

  // suggested addresses
  useEffect(() => {
    let keys = [];
    request.trips.forEach((trip) => {
      if (trip.contact.workAddress) {
        keys.push({
          address: trip.contact.workAddress,
          firstName: trip.contact.firstName,
          lastName: trip.contact.lastName,
          mode: "workAddress",
        });
      }
      if (trip.contact.homeAddress) {
        keys.push({
          address: trip.contact.homeAddress,
          firstName: trip.contact.firstName,
          lastName: trip.contact.lastName,
          mode: "homeAddress",
        });
      }
    });

    if (!isEqual(keys, suggestedAddressInfo)) {
      setSuggestedAddressInfo(keys);
    }
  }, [request, suggestedAddressInfo]);

  // redirect to correct url if missing state
  useEffect(() => {
    if (!location?.state?.request) {
      history.replace(`/${returnTripFor}s`);
    }
  }, [history, location, returnTripFor]);

  // set contact and request id
  useEffect(() => {
    if (location?.state?.request) {
      const { request } = location.state;
      const bookingContact = request.bookingContact;

      setBookingContact(bookingContact);
      setRequest((prevRequest) => {
        const trip = prevRequest.trips[0];

        return {
          ...prevRequest,
          id: request.id,
          trips: [
            {
              ...trip,
              contact: bookingContact,
            },
          ],
        };
      });
    }
  }, [location]);

  // populate initial request with data from location state
  useEffect(() => {
    if (location?.state?.request) {
      const { request, trip } = location.state;

      const bookingContact = request.bookingContact;
      setBookingContact(bookingContact);
      const childSeats = getChildSeatOptionsFromVehicle(trip.routes[0].vehicle);
      setChildSeatOptions(childSeats);

      // reverse trip
      const reversedStops: Stop[] = reverse([...trip.stops]);
      const reversedTripStops = reversedStops.map((stop: Stop, i) => {
        return {
          ...stop,
          stopIndex: i + 1,
          // initialize return trip's start time to be original trip's start time
          dateTime: i === 0 ? trip.stops[0].dateTime : null,
          flightNumber: null,
          airlineIcao: null,
          airline: null,
          trackedFlight: null,
          variant: stop.airport ? "airport" : "address",
        };
      });

      const initialRequest = {
        id: request.id,
        type: request.orderType,
        trips: [
          {
            totalGroupSize: trip.totalGroupSize,
            note: trip.note,
            stops: reversedTripStops,
            routes: trip.routes.map((route: any) => {
              return {
                vehicle: route.isFarmedRoute ? null : route.vehicle,

                // pricing
                baseRateAmt: route.pricing.baseRateAmt,
                driverGratuityAmt: route.pricing.driverGratuityAmt,
                driverGratuityPercent: route.pricing.driverGratuityPercent
                  ? route.pricing.driverGratuityPercent * 100
                  : null,
                promoDiscountAmt: route.pricing.promoDiscountAmt,
                promoDiscountPercent: route.pricing.promoDiscountPercent
                  ? route.pricing.promoDiscountPercent * 100
                  : null,
                taxAmt: route.pricing.taxAmt,
                taxPercent: route.pricing.taxPercent
                  ? route.pricing.taxPercent * 100
                  : null,
                tollsAmt: route.pricing.tollsAmt || null,
                meetGreetAmt: route.pricing.meetGreetAmt || null,
                ...(route.pricing.otherName && {
                  otherName: route.pricing.otherName,
                }),
                otherAmt: route.pricing.otherAmt,
                otherPercent: route.pricing.otherPercent
                  ? route.pricing.otherPercent * 100
                  : null,
                ...(route.pricing.other2Name && {
                  other2Name: route.pricing.other2Name,
                }),
                other2Amt: route.pricing.other2Amt,
                other2Percent: route.pricing.other2Percent
                  ? route.pricing.other2Percent * 100
                  : null,
                ...(route.pricing.other3Name && {
                  other3Name: route.pricing.other3Name,
                }),
                other3Amt: route.pricing.other3Amt,
                other3Percent: route.pricing.other3Percent
                  ? route.pricing.other3Percent * 100
                  : null,
                ...(route.pricing.promoCodeName && {
                  promoCodeName: route.pricing.promoCodeName,
                }),
                ...(route.pricing.promoCodeId && {
                  promoCodeId: route.pricing.promoCodeId,
                }),
                promoCodeAmt: route.pricing.promoCodeAmt,
                promoCodePercent: !isNil(route.pricing.promoCodePercent)
                  ? route.pricing.promoCodePercent * 100
                  : null,
                forwardFacingSeatQuantity:
                  route.pricing.forwardFacingSeatQuantity,
                forwardFacingSeatAmt: route.pricing.forwardFacingSeatQuantity
                  ? route.pricing.forwardFacingSeatAmt /
                    route.pricing.forwardFacingSeatQuantity
                  : null,
                rearFacingSeatQuantity: route.pricing.rearFacingSeatQuantity,
                rearFacingSeatAmt: route.pricing.rearFacingSeatQuantity
                  ? route.pricing.rearFacingSeatAmt /
                    route.pricing.rearFacingSeatQuantity
                  : null,
                boosterSeatQuantity: route.pricing.boosterSeatQuantity,
                boosterSeatAmt: route.pricing.boosterSeatQuantity
                  ? route.pricing.boosterSeatAmt /
                    route.pricing.boosterSeatQuantity
                  : null,
              };
            }),
            contact: {
              id: trip.contact.id,
              firstName: trip.contact.firstName,
              lastName: trip.contact.lastName,
              email: trip.contact.email,
              mobilePhone: trip.contact.mobilePhone,
              workAddress: trip.contact.workAddress,
              homeAddress: trip.contact.homeAddress,
            },
            temporaryPassenger: trip.temporaryPassenger,
            comments: [{ bodyText: "" }],
          },
        ],
      };

      setRequest((prevRequest: any) => ({
        ...prevRequest,
        ...initialRequest,
      }));
    }
  }, [location, updateTrip]);

  // populate luggage data
  useEffect(() => {
    if (tripData) {
      setLuggageAmount({
        carryOnLuggage: tripData.node.routes[0].carryOnLuggage,
        checkedLuggage: tripData.node.routes[0].checkedLuggage,
        oversizeLuggage: tripData.node.routes[0].oversizeLuggage,
      });
    }
  }, [tripData]);

  return (
    <CreateDrawer
      onClose={handleCreateRequestDrawerClose}
      pageLabel={pageLabel}
      onSubmit={handleSubmitClick}
      saveError={!!saveErrorText}
      saveErrorText={saveErrorText}
      submitLabelOptions={submitLabelOptions}
      selectedSubmitButtonIndex={selectedSubmitButtonIndex}
      setSelectedSubmitButtonIndex={setSelectedSubmitButtonIndex}
      submitDisabled={submitDisabled}
      drawerSx={{ zIndex: theme.zIndex.drawer + 3 }}
    >
      <Box alignItems="flex-start" data-testid="create-request-drawer">
        {/* Order Details */}
        {request.trips.map((trip: Trip, tripIndex: number) => (
          <Fragment key={tripIndex}>
            {/* Trip Details */}
            <TripDetailsCreateBlock
              trip={trip}
              updateTrip={updateTrip}
              tripIndex={tripIndex}
              bookingContact={bookingContact as Contact}
            />
            <Box my={4}>
              <TripInfoCreateBlock
                trip={trip}
                updateTrip={updateTrip}
                tripIndex={tripIndex}
                onCreateStop={handleCreateStop(tripIndex)}
                onRemoveStop={handleRemoveStop(tripIndex)}
                requestErrors={requestErrors}
                setRequestErrors={setRequestErrors}
                suggestedAddressInfo={suggestedAddressInfo}
                tripType={tripType}
              />
            </Box>
            <Box my={4}>
              <AdditionalInfoCreateBlock
                trip={trip}
                updateTrip={updateTrip}
                tripIndex={tripIndex}
                requestType={request.orderType}
                luggageAmount={luggageAmount}
                setLuggageAmount={setLuggageAmount}
              />
            </Box>
            <AddVehicleCreateBlock
              requestErrors={requestErrors}
              addVehicles={addVehicles(tripIndex)}
              removeVehicle={removeVehicle(tripIndex)}
              vehicle={trip.routes[0].vehicle}
              stops={trip.stops}
            />
            <PricingInfoCreateBlock
              // initialized request template uses deprecated route pricing (pricing field on route type instead of on route.pricing)
              routePricing={trip.routes[0] as unknown as RoutePricing}
              updateRoute={updateRoute(tripIndex)}
              requestErrors={requestErrors}
              setRequestErrors={setRequestErrors}
              totalDurationMinutes={calculateTotalDurationMinutes(trip.stops)}
              baseRateAmtError={requestErrors.baseRateAmt}
              BRAProps={{
                automatedBaseRate,
                automatedBaseRateLoading,
                showAutomatedBaseRateIcon:
                  isMobileView && !!automatedBaseRate?.total,
                vehicleId,
                hasEnabledVehicleBRA,
                stops: trip.stops,
              }}
              isVehicleBaseRateAutomated={isVehicleBaseRateAutomated}
              setIsBaseRateAutomationClicked={setIsBaseRateAutomationClicked}
              setExisitngAutomatedBaseRate={setExisitngAutomatedBaseRate}
              childSeatOptions={childSeatOptions}
            />
            <Box mt={7} mb={4}>
              <CommentCreateBlock
                commentBodyText={trip.comments[0]?.bodyText}
                onChangeComment={handleTripCommentChange(trip, tripIndex)}
              />
            </Box>
          </Fragment>
        ))}
      </Box>
    </CreateDrawer>
  );
}

export default CreateReturnTripDrawer;
