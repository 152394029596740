import React from "react";

import { Box } from "@mui/material";

import MoovsTabs from "components/globals/MoovsTabs";
import { VehicleTabViewVariant } from "./VehicleTabViews/types";
import { useScreenSize } from "globals/hooks";
import { WarningIcon } from "design-system/icons";
import { errorRed } from "design-system/colors";

type VehicleTabsProps = {
  setVehicleTabView: React.Dispatch<
    React.SetStateAction<VehicleTabViewVariant>
  >;
  vehicleTabView: VehicleTabViewVariant;
  tabHasError: {
    [key in VehicleTabViewVariant]: boolean;
  };
};

function VehicleTabs(props: VehicleTabsProps) {
  const { vehicleTabView, setVehicleTabView, tabHasError } = props;

  // hooks
  const { isSmallTabletView } = useScreenSize();

  return (
    <Box ml={isSmallTabletView ? 0.5 : 2.5}>
      <MoovsTabs
        value={vehicleTabView}
        onChange={setVehicleTabView}
        variant="scrollable"
        hideBottomBorder
        indicatorWidth={0}
        tabs={[
          {
            label: "DETAILS",
            value: VehicleTabViewVariant.DETAILS,
            ...(tabHasError[VehicleTabViewVariant.DETAILS] && {
              Icon: (
                <Box marginBottom={-0.5}>
                  <WarningIcon color={errorRed} size="small" />
                </Box>
              ),
            }),
          },
          {
            label: "FEATURES",
            value: VehicleTabViewVariant.FEATURES,
            ...(tabHasError[VehicleTabViewVariant.FEATURES] && {
              Icon: (
                <Box marginBottom={-0.5}>
                  <WarningIcon color={errorRed} size="small" />
                </Box>
              ),
            }),
          },
          {
            label: "PRICING",
            value: VehicleTabViewVariant.PRICING,
            ...(tabHasError[VehicleTabViewVariant.PRICING] && {
              Icon: (
                <Box marginBottom={-0.5}>
                  <WarningIcon color={errorRed} size="small" />
                </Box>
              ),
            }),
          },
          {
            label: "CUSTOMER PORTAL SETTINGS",
            value: VehicleTabViewVariant.BOOKING_TOOL_SETTINGS,
            ...(tabHasError[VehicleTabViewVariant.BOOKING_TOOL_SETTINGS] && {
              Icon: (
                <Box marginBottom={-0.5}>
                  <WarningIcon color={errorRed} size="small" />
                </Box>
              ),
            }),
          },
        ]}
      />
    </Box>
  );
}

export default VehicleTabs;
