import React, { ReactNode } from "react";

import { Box, Typography } from "@mui/material";

import MoovsDialog from "./MoovsDialog";

type RemoveDialogProps = {
  open: boolean;
  onRemove: () => void;
  onClose: () => void;
  title: string;
  body: string | ReactNode;
  removeButtonText?: string;
  cancelButtonText?: string;
  isSaving?: boolean;
  isLoading?: boolean;
};

function RemoveDialog(props: RemoveDialogProps) {
  const {
    open,
    onRemove,
    onClose,
    title,
    body,
    removeButtonText = "Remove",
    cancelButtonText = "Cancel",
    isSaving,
    isLoading,
  } = props;

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      onAccept={onRemove}
      hideTopBorder
      dialogTitle={title}
      acceptButtonText={removeButtonText}
      closeButtonText={cancelButtonText}
      size="xs"
      hideLoadingIndicator={!isSaving}
      acceptDisabled={isSaving || isLoading}
    >
      <Box mb={3}>
        <Typography variant="body2">{body}</Typography>
      </Box>
    </MoovsDialog>
  );
}

export default RemoveDialog;
