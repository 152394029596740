import every from "lodash/every";
import compact from "lodash/compact";

import { VehicleAvailabilityCalendar } from "types";

export const getCurrentTripAvailabilityInfo = (
  vehicleCalendarData: VehicleAvailabilityCalendar
) => {
  if (!vehicleCalendarData) return {};

  const { previousTrip, currentTrip, nextTrip } = vehicleCalendarData;

  let isVehicleAvailable = true;
  let blockedByQuote = false;

  if (!currentTrip.isOverlap) {
    return { isVehicleAvailable, blockedByQuote };
  }

  isVehicleAvailable = false;

  // removes null trips
  const otherVehicleTrips = compact([previousTrip, nextTrip]);

  if (every(otherVehicleTrips, "isQuote")) {
    blockedByQuote = true;
  }

  return { isVehicleAvailable, blockedByQuote };
};
