/**
 * @file graphql/comment.ts
 * queries/mutations for comments.
 *
 */

import { gql } from "@apollo/client";

export const CREATE_TRIP_COMMENT = gql`
  mutation CreateTripComment($input: CreateTripCommentInput!) {
    createTripComment(input: $input) {
      tripComment {
        id
        bodyText
        user {
          id
          firstName
        }
      }
    }
  }
`;

export const CREATE_FARMED_ROUTE_COMMENT = gql`
  mutation CreateFarmedRouteComment($input: CreateFarmedRouteCommentInput!) {
    createFarmedRouteComment(input: $input) {
      farmedRouteComment {
        id
        bodyText
        user {
          id
          firstName
        }
      }
    }
  }
`;

export const CREATE_CONTACT_COMMENT = gql`
  mutation CreateContactComment($input: CreateContactCommentInput!) {
    createContactComment(input: $input) {
      contactComment {
        id
        bodyText
        user {
          id
          firstName
        }
      }
    }
  }
`;

export const CREATE_VEHICLE_COMMENT = gql`
  mutation CreateVehicleComment($input: CreateVehicleCommentInput!) {
    createVehicleComment(input: $input) {
      vehicleComment {
        id
        bodyText
        user {
          id
          firstName
        }
      }
    }
  }
`;
