import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import isNil from "lodash/isNil";
import { useMutation } from "@apollo/client";
import { useDebounce } from "use-debounce";

import { UPDATE_OPERATOR_SETTINGS } from "globals/graphql";
import { useOperator, useSnackbar } from "globals/hooks";
import LabeledDollarInput from "./LabeledDollarInput";

type StopPriceSettingBlockProps = {
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

const StopPriceSettingBlock = (props: StopPriceSettingBlockProps) => {
  const { setSaveIndicatorState } = props;

  // hooks
  const operator = useOperator();
  const snackbar = useSnackbar();

  // state
  const [stopPriceInput, setStopPriceInput] = useState(
    operator.settings.stopPrice / 100
  );
  const [stopPriceDebounced] = useDebounce(stopPriceInput, 750);

  // mutations
  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted(data) {
      setStopPriceInput(
        data.updateOperatorSettings.operator.settings.stopPrice / 100
      );
      setSaveIndicatorState("saved");
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error("Error updating stop price");
    },
  });

  // event handlers
  const handleStopPriceChange = (event: any) => {
    setStopPriceInput(event.target.value);
  };

  const handleDebouncedInput = useCallback(() => {
    setSaveIndicatorState("loading");
    updateOperatorSettings({
      variables: {
        input: {
          stopPrice: stopPriceDebounced.toString().length
            ? Number(stopPriceDebounced) * 100 // stored as cents
            : 0,
        },
      },
    });
  }, [setSaveIndicatorState, stopPriceDebounced, updateOperatorSettings]);

  // update operator settings on debounce
  useEffect(() => {
    if (
      !isNil(stopPriceDebounced) && // do nothing if null or undefined, 0s allowed
      Number(stopPriceDebounced) !== Number(operator.settings.stopPrice / 100)
    ) {
      handleDebouncedInput();
    }
  }, [stopPriceDebounced, operator, handleDebouncedInput]);

  return (
    <LabeledDollarInput
      value={stopPriceInput?.toString()}
      onChange={handleStopPriceChange}
      text="Enter in a price per stop"
      subText={`Additional stops on transfer trips will be priced at $${
        stopPriceInput || 0
      } and automatically included in the base rate.`}
      placeholder="$ 0"
    />
  );
};

export default StopPriceSettingBlock;
