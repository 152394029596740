import React from "react";
import size from "lodash/size";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import { Box, CircularProgress, Dialog } from "@mui/material";

import theme from "theme";
import { StringParam, useQueryParam } from "use-query-params";
import { useScreenSize, useSnackbar } from "../../globals/hooks";
import TripsPanel from "./components/TripsPanel/TripsPanel";
import TripSummary from "./components/TripSummary/TripSummary";
import useReservationPageRequest from "./hooks/useReservationPageRequest";

export enum PageViewEnum {
  TripsPanel = "tripsPanel",
  TripSummary = "tripSummary",
}

function ReservationsPage() {
  // hooks
  const history = useHistory<{ backNavigationPath: string }>();
  const { isMediumTabletView } = useScreenSize();
  const { request, refetch } = useReservationPageRequest();
  const [currentTripId] = useQueryParam("tripId", StringParam);
  const snackbar = useSnackbar();

  // event handlers
  const handleCloseTripSummaryClick = () => {
    history.push(`${history.location.pathname}`, history.location.state);
  };

  const pageView = currentTripId
    ? PageViewEnum.TripSummary
    : PageViewEnum.TripsPanel;

  const pages = {
    tripsPanel: <TripsPanel request={request} />,
    tripSummary: (
      <TripSummary
        request={request}
        onCloseTripSummaryClick={handleCloseTripSummaryClick}
        requestRefetch={refetch}
      />
    ),
  };

  if (!request)
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={40} thickness={2} />
      </Box>
    );

  if (request && !size(request.trips)) {
    snackbar.error(
      `Order ${request.orderNumber} has no trips. It may have been deleted or cancelled.`,
      {
        linkLabel: "Go back.",
        link: "/reservations",
      }
    );
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Moovs</title>
      </Helmet>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* mobile : toggles trip list view and map view */}
        {isMediumTabletView && (
          <Box sx={{ display: "flex", height: "100%", maxWidth: "100vw" }}>
            <Dialog
              open
              fullScreen
              sx={{
                // dialog default zindex is 1300, set zindex to 1200 so this is above appbar and below update/create drawer
                zIndex: theme.zIndex.drawer,
              }}
            >
              {pages[pageView]}
            </Dialog>
          </Box>
        )}

        {/* desktop : displays both trip list and map view */}
        {!isMediumTabletView && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            {pages[PageViewEnum.TripsPanel]}
            {pages[PageViewEnum.TripSummary]}
          </Box>
        )}
      </Box>
    </>
  );
}

export default ReservationsPage;
