import React, {
  ChangeEvent,
  MouseEvent,
  useMemo,
  useRef,
  useState,
} from "react";
import filter from "lodash/filter";
import includes from "lodash/includes";

import {
  Box,
  Button,
  ButtonProps,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import { grayDark } from "design-system/colors";
import { SearchIcon } from "design-system/icons";

export type MoovsSearchableDropdownOption<T = Record<string, any>> = {
  label: string;
  fieldName: string;
  sublabel?: string;
  payload?: T;
};

type MoovsSearchableDropdownButtonProps = {
  buttonLabel: string;
  options: MoovsSearchableDropdownOption[];
  onDropdownOptionClick: (option: MoovsSearchableDropdownOption) => void;
  MuiButtonProps?: ButtonProps;
  isFullWidth?: boolean;
  disabled?: boolean;
};

function MoovsSearchableDropdownButton(
  props: MoovsSearchableDropdownButtonProps
) {
  const {
    buttonLabel,
    options,
    onDropdownOptionClick,
    MuiButtonProps,
    isFullWidth,
    disabled = false,
  } = props;

  // hooks
  const buttonRef = useRef();

  // state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchFilter, setSearchFilter] = useState("");

  // memo
  const filteredOptions = useMemo(
    () =>
      filter(options, ({ label }) =>
        includes(label.toLowerCase(), searchFilter.toLowerCase())
      ),
    [searchFilter, options]
  );

  // event handlers
  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchFilter("");
  };

  const handleSearchOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(e.target.value);
  };

  const handleDropdownOptionClick = (option: MoovsSearchableDropdownOption) => {
    onDropdownOptionClick(option);
    handleClose();
  };

  return (
    <Box>
      <Button
        ref={buttonRef}
        color="primary"
        variant="outlined"
        onClick={handleMenuOpen}
        disabled={disabled}
        sx={{ marginTop: 1 }}
        {...MuiButtonProps}
        {...(!!isFullWidth && { fullWidth: true })}
      >
        {buttonLabel}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        autoFocus
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            minWidth: "225px",
            textAlign: "center",
          },
        }}
      >
        <MenuItem
          disableGutters
          disableRipple
          disableTouchRipple
          onKeyDown={(e) => e.stopPropagation()}
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&.Mui-focusVisible": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Box mb={1} px={1}>
            <TextField
              size="small"
              autoFocus
              value={searchFilter}
              placeholder="Search"
              onChange={handleSearchOnChange}
              InputProps={{
                startAdornment: <SearchIcon size="small" />,
                sx: {
                  "& .MuiInputBase-input": {
                    pl: 1,
                  },
                },
              }}
            />
          </Box>
        </MenuItem>
        {filteredOptions.map((option, i) => {
          const { label, sublabel } = option;
          return (
            <MenuItem
              key={i}
              onClick={() => handleDropdownOptionClick(option)}
              sx={{
                p: "12px",
                height: "44px",
              }}
            >
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="body2">{label}</Typography>
                {sublabel && (
                  <Typography variant="body2" color={grayDark}>
                    {sublabel}
                  </Typography>
                )}
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}

export default MoovsSearchableDropdownButton;
