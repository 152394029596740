import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { blueLabelText } from "design-system/colors";
import OverflowTooltip from "design-system/components/OverflowTooltip";
import { EditIcon } from "design-system/icons";
import { formatPhoneNumber } from "globals/utils/phoneNumberFormatter/phoneNumberFormatter";
import React from "react";

type BookingContactBlockProps = {
  bookingContact: {
    name: string;
    email: string;
    phoneNumber: string;
  };
  onBookingContactUpdate: (e: any) => void;
  canEditBookingContact?: boolean;
};

const BookingContactBlock = (props: BookingContactBlockProps) => {
  const { bookingContact, onBookingContactUpdate, canEditBookingContact } =
    props;

  return (
    <>
      <Box mb="2px">
        <Typography variant="overline">Booking Contact</Typography>
      </Box>
      <Box mb="1px" display="flex" flexDirection="row">
        <Typography
          variant="body2"
          sx={{ fontWeight: "bold", position: "relative", top: "4px" }}
        >
          {bookingContact.name}
        </Typography>
        {canEditBookingContact && (
          <IconButton
            onClick={() => onBookingContactUpdate(true)}
            sx={{
              "&.MuiButtonBase-root": { padding: 0 },
              ml: 1,
            }}
          >
            <EditIcon color={blueLabelText} />
          </IconButton>
        )}
      </Box>
      <Box mb="1px">
        <OverflowTooltip
          variant="body2"
          toolTipText={bookingContact?.email || "-"}
          displayText={bookingContact?.email || "-"}
          arrow={true}
        />
      </Box>
      {bookingContact.phoneNumber && (
        <OverflowTooltip
          variant="body2"
          toolTipText={
            formatPhoneNumber(bookingContact.phoneNumber).formatted || "-"
          }
          displayText={
            formatPhoneNumber(bookingContact.phoneNumber).formatted || "-"
          }
          arrow={true}
        />
      )}
    </>
  );
};

export default BookingContactBlock;
