import React from "react";

type GettingStartedCompletedIconProps = {
  color?: string;
};

const GettingStartedCompletedIcon = (
  props: GettingStartedCompletedIconProps
) => {
  const { color } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_869_88497)">
        <path
          d="M12 2H2.46667C1.65665 2 1 2.65665 1 3.46667V21.5333C1 22.3434 1.65665 23 2.46667 23H20.5333C21.3434 23 22 22.3434 22 21.5333V12M9.37931 7.06944H11.5M9.37931 12.5H16M9.37931 17.9306H18M4 7.18827L4.90237 8.13889L6.9327 6M4 12.6188L4.90237 13.5694L6.9327 11.4306M4 18.0494L4.90237 19L6.9327 16.8611"
          stroke={color || "#195FE9"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="13" width="11" height="11" rx="5.5" fill="#28B668" />
        <path
          d="M21 4L17.5625 7L16 5.63636"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_869_88497">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GettingStartedCompletedIcon;
