import React from "react";

type FarmOutIconProps = {
  color?: string;
  size?: "small";
};

const FarmOutIcon = (props: FarmOutIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.15 4.85L15 1M15 1H10.8M15 1V5.2M2.4 15H8.7C9.4732 15 10.1 14.3732 10.1 13.6V7.3C10.1 6.5268 9.4732 5.9 8.7 5.9H2.4C1.6268 5.9 1 6.5268 1 7.3V13.6C1 14.3732 1.6268 15 2.4 15ZM3.5 10.5L7.5 8.5L5.5 12.5L5.26316 10.7368L3.5 10.5Z"
          stroke={color || "#565656"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m16.5 7.5 5.5-5.5m0 0h-6m6 0v6m-17 7.3158 7-3.3158-3.31579 7-.73684-2.9474zm-1 6.6842h9c1.1046 0 2-.8954 2-2v-9c0-1.10457-.8954-2-2-2h-9c-1.10457 0-2 .89543-2 2v9c0 1.1046.89543 2 2 2z"
        stroke={color || "#195fe9"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default FarmOutIcon;
