/**
 * @file CompaniesList.tsx
 * List of Company info cards. Used on companiesPage.
 *
 * components:
 *  CompaniesList
 */

import React from "react";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

import { Box, CircularProgress, Typography } from "@mui/material";

import CompaniesListItemDesktop from "./CompaniesListItem.desktop";
import { Company } from "../../../types";
import { LOAD_COMPANIES_QUERY } from "../../../globals/graphql";
import CompaniesListItemMobile from "./CompaniesListItem.mobile";
import GQLQueryStatusIndicator from "../../GQLQueryStatusIndicator";
import { useAnalytics, useScreenSize } from "globals/hooks";

function CompaniesList() {
  // hooks
  const { isSmallTabletView } = useScreenSize();
  const history = useHistory();
  const { track } = useAnalytics();

  // queries
  const { data, error, loading } = useQuery(LOAD_COMPANIES_QUERY, {
    fetchPolicy: "network-only",
  });

  // event handlers
  const handleClickCompany = (companyId: string) => {
    track("company_opened");
    history.push(`/companies/update/${companyId}`);
  };

  if (error) {
    return <GQLQueryStatusIndicator error={error} name="Companies Data" />;
  }

  if (loading && !data) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        flex="1"
        px={3}
        mb={1}
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      {...(isSmallTabletView && {
        sx: {
          width: "100%",
          overflowX: "auto",
          overflowY: "hidden",
          height: "100%",
        },
      })}
      data-testid="companies-list"
    >
      {isEmpty(data.companies) ? (
        <Box display="flex" justifyContent="center" m={10}>
          <Typography variant="subtitle1">No Companies Found</Typography>
        </Box>
      ) : (
        <>
          {data.companies.map((company: Company) => {
            return (
              <Box
                key={company.id}
                my={1}
                {...(isSmallTabletView && { mx: 2 })}
              >
                {isSmallTabletView ? (
                  <CompaniesListItemMobile
                    company={company}
                    onClickCompany={handleClickCompany}
                  />
                ) : (
                  <CompaniesListItemDesktop
                    company={company}
                    onClickCompany={handleClickCompany}
                  />
                )}
              </Box>
            );
          })}
        </>
      )}
    </Box>
  );
}

export default CompaniesList;
