import React from "react";
import { ChangeEvent, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import size from "lodash/size";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import { alabaster, grayMedium } from "../../../../design-system/colors";
import MoovsDialog from "../../../MoovsDialog";
import {
  LOAD_AVAILABLE_NUMBERS_QUERY,
  LOAD_PHONE_NUMBER_BY_SID_QUERY,
} from "../../../../globals/graphql";
import { primaryDisabledColor, primaryMainColor } from "../../../../theme";

type SelectPhoneNumberDialogProps = {
  open: boolean;
  onClose: () => void;
  onPhoneNumberSelect: (phoneNumber: string) => void;
  selectedPhoneNumber: any;
  onPhoneNumberNextClick: () => void;
};

const styles = {
  selected: {
    color: primaryMainColor,
  },
  root: {
    fontSize: 14,
    fontWeight: 600,
    minWidth: 111,
    padding: "16px",
    borderBottom: `1px solid ${grayMedium}`,
  },
  indicator: {
    width: "114px !important",
  },
};

function SelectPhoneNumberDialog(props: SelectPhoneNumberDialogProps) {
  const {
    open,
    onClose,
    onPhoneNumberSelect,
    selectedPhoneNumber,
    onPhoneNumberNextClick,
  } = props;

  // state
  const [tabIndex, setTabIndex] = useState(0);
  const [phoneSid, setPhoneSid] = useState("");
  const [phoneSidError, setPhoneSidError] = useState("");
  const [searchForPhoneSid, setSearchForPhoneSid] = useState(false);

  // queries
  const { data: availableNumbers, loading } = useQuery(
    LOAD_AVAILABLE_NUMBERS_QUERY,
    {
      skip: !open,
      fetchPolicy: "network-only",
    }
  );

  // queries
  const [loadPhoneBySid] = useLazyQuery(LOAD_PHONE_NUMBER_BY_SID_QUERY, {
    onCompleted(data) {
      if (data) {
        setSearchForPhoneSid(false);
        onPhoneNumberSelect(data?.twilioNumberBySid);
      }
    },
    onError: (error) => {
      setPhoneSidError("Phone Sid is not valid");
    },
    variables: {
      phoneSid: phoneSid,
    },
    fetchPolicy: "network-only",
  });

  // event handlers
  const handleTabSelect = (_, tabIndex) => {
    setTabIndex(tabIndex);
  };

  const handlePhoneSidChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPhoneSid(event.target.value);
  };

  const handlePhoneSidClick = () => {
    if (!phoneSid) {
      setPhoneSidError("Please enter Phone Sid");
    }
    loadPhoneBySid({
      variables: {
        phoneSid: phoneSid,
      },
    });
  };

  return (
    <MoovsDialog
      fixedFooter
      hideLoadingIndicator
      size="sm"
      open={open}
      onClose={onClose}
      onAccept={onPhoneNumberNextClick}
      dialogTitle="Select a Number"
      acceptButtonText="Next"
      closeButtonText="Cancel"
      acceptDisabled={selectedPhoneNumber === null}
    >
      {loading ? (
        <Box my={2} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Tabs
            indicatorColor="primary"
            value={tabIndex}
            variant={"fullWidth"}
            onChange={handleTabSelect}
          >
            <Tab
              sx={{
                "&.Mui-selected": styles.selected,
                "&.MuiTab-root": styles.root,
              }}
              label="SEARCH"
            />
            <Tab
              sx={{
                "&.Mui-selected": styles.selected,
                "&.MuiTab-root": styles.root,
              }}
              label="PORT"
            />
          </Tabs>

          {tabIndex === 0 && (
            <>
              {!size(availableNumbers?.availableNumbersTwilio) && (
                <Box
                  height="165px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="body1">
                    Numbers are not available in your area
                  </Typography>
                </Box>
              )}
              {availableNumbers?.availableNumbersTwilio.map((phoneNumber) => (
                <PhoneNumberListItem
                  key={phoneNumber.phoneNumber}
                  phoneNumber={phoneNumber}
                  onPhoneNumberSelect={() => onPhoneNumberSelect(phoneNumber)}
                  isSelected={
                    selectedPhoneNumber?.phoneNumber === phoneNumber.phoneNumber
                  }
                />
              ))}
            </>
          )}

          {tabIndex === 1 && (
            <Box mb={2}>
              <Box my={2}>
                <Typography>Enter Phone SID for ported number</Typography>
              </Box>
              <TextField
                required
                fullWidth
                variant="outlined"
                label="Phone SID"
                name="phoneSid"
                value={phoneSid || ""}
                onChange={handlePhoneSidChange}
                error={!!phoneSidError}
                helperText={phoneSidError}
              />

              <Button
                style={{ marginLeft: "8px" }}
                color="primary"
                onClick={handlePhoneSidClick}
              >
                Confirm
              </Button>

              <Divider />

              {searchForPhoneSid && (
                <Box display="flex" flexDirection="column" mt={2} mb={2}>
                  <CircularProgress />
                </Box>
              )}

              {selectedPhoneNumber && (
                <Box display="flex" flexDirection="column" mt={2} mb={2}>
                  <Typography style={{ fontWeight: 700 }} variant="subtitle1">
                    {selectedPhoneNumber?.phoneNumber}
                  </Typography>
                  <Typography variant="body2">
                    {`SID: ${selectedPhoneNumber?.sid}`}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </MoovsDialog>
  );
}

export default SelectPhoneNumberDialog;

type PhoneNumberListItemProps = {
  phoneNumber: any;
  onPhoneNumberSelect: () => void;
  isSelected: boolean;
};

function PhoneNumberListItem(props: PhoneNumberListItemProps) {
  const { phoneNumber, onPhoneNumberSelect, isSelected } = props;

  return (
    <Box
      bgcolor={isSelected ? primaryDisabledColor : alabaster}
      {...(isSelected && { border: `1px solid ${primaryMainColor}` })}
      borderRadius="4px"
      my={1}
      p={2}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column">
        <Typography style={{ fontWeight: 700 }} variant="subtitle1">
          {phoneNumber.friendlyName}
        </Typography>
        <Typography variant="body2">
          {`${phoneNumber.locality}, ${phoneNumber.region}`}
        </Typography>
      </Box>
      <Button
        disableTouchRipple
        disableElevation
        variant={isSelected ? "contained" : "outlined"}
        color="primary"
        onClick={onPhoneNumberSelect}
      >
        Select
      </Button>
    </Box>
  );
}
