/**
 * @file graphql/airport.graphql.ts
 * queries/mutations for airports.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_AIRPORTS_QUERY = gql`
  query Airports($globalSearch: String) {
    airports(globalSearch: $globalSearch) {
      edges {
        node {
          icaoCode
          iataCode
          airportName
          countryName
          countryIso2
          timezone
          gmt
        }
      }
    }
  }
`;
