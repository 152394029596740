import { Stop, StopInformationInputForAutomatedPricing } from "types";

export const shapeStopsToAutomatedBaseRateQueryStopsArg = (
  stops: Pick<Stop, "airport" | "location" | "dateTime">[]
): StopInformationInputForAutomatedPricing[] => {
  return (stops || []).map((stop) => ({
    location: stop?.airport?.airportName || stop.location,
    dateTime: stop.dateTime,
    ...(stop.airport && { airportIcao: stop.airport.icaoCode }),
  }));
};
