import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import { Box, Typography } from "@mui/material";

import { ENABLE_MOOVS_NETWORK } from "globals/graphql";
import { useSnackbar, useAnalytics } from "globals/hooks";
import MoovsDialog from "components/MoovsDialog";

type EnableMoovsNetworkDialogProps = {
  open: boolean;
  onClose: () => void;
};

function EnableMoovsNetworkDialog(props: EnableMoovsNetworkDialogProps) {
  const { open, onClose } = props;

  //hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // state
  const [submitDisabled, setSubmitDisabled] = useState(false);

  // mutations
  const [enableMoovsNetwork] = useMutation(ENABLE_MOOVS_NETWORK, {
    onCompleted(data) {
      track("affiliate_enrolled");
      snackbar.success("You are now enrolled in Moovs Network!");
      onClose();
    },
    onError(error) {
      setSubmitDisabled(false);
      snackbar.error(
        "There was an issue enrolling you in Moovs Network. Please try again."
      );
    },
  });

  // event handlers
  const handleAcceptClick = () => {
    setSubmitDisabled(true);
    enableMoovsNetwork({
      variables: {
        input: {},
      },
    });
  };

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      onAccept={handleAcceptClick}
      size="xs"
      dialogTitle="Enroll in Moovs Network"
      acceptButtonText="Enroll"
      hideTopBorder
      acceptDisabled={submitDisabled}
    >
      <Box mb={3}>
        <Typography variant="body2">
          By enrolling, other Moovs operators will have access to seamlessly
          farm in and out trips with you.
        </Typography>
      </Box>
    </MoovsDialog>
  );
}

export default EnableMoovsNetworkDialog;
