import React from "react";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";

import { Box } from "@mui/material";

import { Invoice } from "../../types";
import InvoicesListItemDesktop from "./InvoicesListItem.desktop";
import InvoicesListItemMobile from "./InvoicesListItem.mobile";
import { useScreenSize } from "globals/hooks";
import { formatPhoneNumber } from "globals/utils/phoneNumberFormatter/phoneNumberFormatter";
import { currency } from "globals/utils/helpers";

type InvoicesListProps = {
  invoices: Invoice[];
  sxStyles?: any;
};

function InvoicesList(props: InvoicesListProps) {
  const { sxStyles, invoices } = props;

  const { isMobileView } = useScreenSize();
  const history = useHistory();

  return (
    <Box sx={sxStyles}>
      {invoices.map((invoice) => {
        const {
          contact,
          totalAmount,
          invoiceNumber,
          dueDate,
          createdAt,
          id,
          farmAffiliate,
          company,
        } = invoice;

        // billing contact
        const contactData = contact && {
          name: `${contact.firstName} ${contact.lastName}`,
          email: contact.email,
          phone: contact.mobilePhone,
        };

        const farmAffiliateData = farmAffiliate && {
          name: farmAffiliate.operatorName,
          email: farmAffiliate.operatorEmail,
          phone: farmAffiliate.operatorPhone,
        };

        const companyData = company && {
          name: company.name,
          email: company.email,
          phone: company.phoneNumber
            ? formatPhoneNumber(company.phoneNumber)?.formatted
            : null,
        };

        const billingContact = contactData
          ? contactData
          : farmAffiliateData
          ? farmAffiliateData
          : companyData;

        // smart due date
        const todaysDate = moment();
        const dateDiff = todaysDate.diff(dueDate, "days");
        let smartDueDate;
        if (Math.abs(dateDiff) >= 7) {
          smartDueDate = moment(dueDate).format("MM/DD/YY");
        }
        smartDueDate = moment(dueDate).fromNow();

        // event handler
        const handleClick = (invoiceId) => {
          history.push(`/invoices/update/${invoiceId}`);
        };

        return (
          <Box my={1} mx={2} key={id}>
            {isMobileView ? (
              <InvoicesListItemMobile
                id={id}
                smartDueDate={smartDueDate}
                handleClick={handleClick}
                billingContact={billingContact}
                invoiceNumber={invoiceNumber}
                createdAt={moment(createdAt).format("MM/DD/YY")}
                totalAmount={currency(totalAmount)}
              />
            ) : (
              <InvoicesListItemDesktop
                id={id}
                smartDueDate={smartDueDate}
                handleClick={handleClick}
                billingContact={billingContact}
                invoiceNumber={invoiceNumber}
                createdAt={moment(createdAt).format("MM/DD/YY")}
                totalAmount={currency(totalAmount)}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
}

export default InvoicesList;
