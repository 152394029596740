import React from "react";

import { Box, Divider } from "@mui/material";
import { alabaster } from "../../design-system/colors";
import DisplayedAmount from "./DisplayedAmount";

type PaymentSummarySectionProps = {
  totalAmount: number;
  beforeAmountDue: number;
};

function PaymentSummarySection(props: PaymentSummarySectionProps) {
  const { totalAmount, beforeAmountDue } = props;

  const afterAmountDue =
    (Number(beforeAmountDue) * 100 - Number(totalAmount) * 100) / 100;

  return (
    <Box my={2} style={{ backgroundColor: alabaster }}>
      <Box py={3} display="flex" justifyContent="space-between">
        <DisplayedAmount label="Before" amountDue={beforeAmountDue} />

        <Divider orientation="vertical" flexItem />

        <DisplayedAmount label="After" amountDue={afterAmountDue} />
      </Box>
    </Box>
  );
}

export default PaymentSummarySection;
