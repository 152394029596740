import React from "react";
import Carousel from "react-material-ui-carousel";

import { Card, CardMedia, Typography, Box } from "@mui/material";

import { ArrowLeftIcon, ArrowRightIcon } from "design-system/icons";
import "./OnboardingImageCarousel.css";
import { useScreenSize } from "globals/hooks";
import { items } from "./OnboardingImageItems";

const OnboardingImageCarousel = () => {
  const { isMediumTabletView } = useScreenSize();

  return (
    <Carousel
      className="OnboardingImageCarousel"
      autoPlay={true}
      animation={"fade"}
      indicators={false}
      interval={10000}
      navButtonsAlwaysVisible={true}
      cycleNavigation={true}
      swipe={true}
      navButtonsProps={{
        style: {
          borderRadius: 50,
          border: "1px solid rgba(255, 255, 255, 0.5)",
          backgroundColor: "transparent",
        },
      }}
      navButtonsWrapperProps={{
        style: {
          bottom: "0",
          position: "absolute",
          marginRight: isMediumTabletView ? "3%" : "5%",
        },
      }}
      NextIcon={
        <ArrowRightIcon color={"rgba(255, 255, 255, 0.5)"} size="small" />
      }
      PrevIcon={
        <ArrowLeftIcon color={"rgba(255, 255, 255, 0.5)"} size="small" />
      }
    >
      {items.map((item, index) => {
        return (
          <Box key={index}>
            <Card>
              <CardMedia
                style={{
                  minHeight: "100vh",
                  backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.7) 81.25%), url(${item.image})`,
                }}
              />
              <Typography
                sx={{
                  position: "absolute",
                  color: "white",
                  transform: "translateX(-90%)",
                  top: "55%",
                  left: "90%",
                  fontWeight: 500,
                  width: "90%",
                }}
                variant="h1"
              >
                {item.description}
              </Typography>
              <Typography
                sx={{
                  position: "absolute",
                  color: "white",
                  transform: "translateX(-90%)",
                  bottom: "12%",
                  left: "90%",
                  width: "90%",
                }}
                variant="h2"
              >
                {item.name}
              </Typography>
              <Typography
                sx={{
                  position: "absolute",
                  color: "white",
                  transform: "translateX(-90%)",
                  bottom: "7%",
                  left: "90%",
                  width: "90%",
                }}
                variant="subtitle2"
              >
                {item.role}
              </Typography>
              <Typography
                sx={{
                  position: "absolute",
                  color: "white",
                  transform: "translateX(-90%)",
                  bottom: "5%",
                  left: "90%",
                  width: "90%",
                }}
                variant="caption"
              >
                {item.company}
              </Typography>
            </Card>
          </Box>
        );
      })}
    </Carousel>
  );
};

export default OnboardingImageCarousel;
