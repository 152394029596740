import React from "react";

import { SalesAutomationText } from "types";
import LogItemAccordionBlock from "./LogItemAccordionBlock";
import LogItemBlock from "./LogItemBlock";

type SalesAutomationLogItemProps = {
  text: SalesAutomationText;
};

function SalesAutomationLogItem(props: SalesAutomationLogItemProps) {
  const { text } = props;

  return text.impressions.length ? (
    <LogItemAccordionBlock text={text} />
  ) : (
    <LogItemBlock text={text} />
  );
}

export default SalesAutomationLogItem;
