import React from "react";
import { Helmet } from "react-helmet";

import { Box, Container, Typography } from "@mui/material";

import { alabaster, grayLight, white } from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import MoovsMarketAppList from "./components/MoovsMarketAppList";
import useSelectedMoovsMarketApp from "./hooks";
import MoovsMarketAppInfo from "./components/MoovsMarketAppInfo";
import DriverPlatformReport from "./components/DriverPlatformReport";

const REPORT_BLOCKS = {
  driver: <DriverPlatformReport />,
  sales_automation: null,
  dyanmic_pricing: null,
  review_platform: null,
  google_calendar: null,
};

function MoovsMarketPage() {
  // hooks
  const { isMobileView } = useScreenSize();
  const { selectedApp, onAppClick, onAppClose } = useSelectedMoovsMarketApp();

  if (selectedApp) {
    return (
      <Box sx={{ backgroundColor: white, width: "100%" }}>
        <Container
          sx={{
            backgroundColor: white,
          }}
          disableGutters={true}
          maxWidth="xl"
        >
          <MoovsMarketAppInfo app={selectedApp} onAppClose={onAppClose} />
          {selectedApp.active && REPORT_BLOCKS[selectedApp.enum]}
        </Container>
      </Box>
    );
  }

  return (
    <Box width="100%" display="flex" flexDirection="column" bgcolor={alabaster}>
      <Helmet>
        <title>Moovs Market | Moovs</title>
      </Helmet>
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor={white}
        borderBottom={`1px solid ${grayLight}`}
        p={1}
        {...(!isMobileView && {
          py: 3,
        })}
      >
        <Container maxWidth="md">
          <Typography variant="h1">Moovs Market</Typography>
        </Container>
      </Box>
      <Container maxWidth="md" sx={{ overflow: "auto" }}>
        <MoovsMarketAppList onAppClick={onAppClick} />
      </Container>
    </Box>
  );
}

export default MoovsMarketPage;
