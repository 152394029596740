import React, { MouseEvent, ChangeEvent } from "react";

import { Box, IconButton, SxProps, TextField, Typography } from "@mui/material";

import { MinusIcon, PlusIcon } from "design-system/icons";
import { NumberFormatDecimal } from "design-system/components/inputs/NumberFormat";
import { primaryMainColor } from "theme";

type MoovsLabeledIncrementerProps = {
  label: string;
  value: number;
  limits: [number, number];
  decrement: (event: MouseEvent<HTMLElement>) => void;
  increment: (event: MouseEvent<HTMLElement>) => void;
  onTextFieldChange: (value: number, event: ChangeEvent<HTMLElement>) => void;
  disabled?: boolean;
  errorMessage?: string;
  sx?: SxProps;
};

function MoovsLabeledIncrementer(props: MoovsLabeledIncrementerProps) {
  const {
    label,
    value,
    limits,
    decrement,
    increment,
    disabled,
    onTextFieldChange,
    errorMessage,
    sx,
  } = props;

  const [lowerLimit, upperLimit] = limits;

  const isMinusDisabled = disabled || value <= lowerLimit;
  const isPlusDisabled = disabled || value >= upperLimit;

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <TextField
        label={label}
        variant="outlined"
        value={value}
        onChange={(e) => {
          onTextFieldChange(Number(e.target.value), e);
        }}
        InputProps={{
          sx: {
            "& input": { textAlign: "center" },
          },
          inputComponent: NumberFormatDecimal as any,
          startAdornment: (
            <IconButton
              size="small"
              onClick={decrement}
              disabled={isMinusDisabled}
            >
              <MinusIcon
                {...(!isMinusDisabled && {
                  color: primaryMainColor,
                })}
              />
            </IconButton>
          ),
          endAdornment: (
            <IconButton
              size="small"
              onClick={increment}
              disabled={isPlusDisabled}
            >
              <PlusIcon
                {...(!isPlusDisabled && {
                  color: primaryMainColor,
                })}
              />
            </IconButton>
          ),
        }}
        sx={sx}
      />

      {errorMessage && (
        <Typography variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
}
export default MoovsLabeledIncrementer;
