import React, { useMemo } from "react";
import clipboardCopy from "clipboard-copy";

import { Box, Button, Typography } from "@mui/material";

import { fromGlobalId } from "globals/utils/helpers";
import { CopyIcon } from "design-system/icons";
import { white } from "design-system/colors";
import {
  useAnalytics,
  useOperator,
  useScreenSize,
  useSnackbar,
} from "globals/hooks";

function WidgetCode() {
  const { isMediumTabletView } = useScreenSize();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const { id } = useOperator();

  const operatorId = fromGlobalId(id).id;

  const codeSnippet = useMemo(
    () => `<script>
  window["moovsAPI"] = moovsAPI = window["moovsAPI"] || [];
  moovsAPI.push(["operator", "${operatorId}"]);
  (function(m, oo, v, s) {
      s = m.createElement(oo);
      s.src = v;
      s.async = 1; 
      m.head.appendChild(s);
  })(document, "script", "${process.env.REACT_APP_CUSTOMERWIDGET_URL}");
</script>`,
    [operatorId]
  );

  const handleCopyCode = async () => {
    try {
      await clipboardCopy(codeSnippet);
      track("portalWidget_codeCopied");
      snackbar.success(`Code copied to clipboard`);
    } catch (error) {
      snackbar.error("Could not copy code");
    }
  };

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      alignItems="flex-start"
      borderRadius={1}
      bgcolor="rgba(211, 211, 211, 0.72)"
      p={2}
      mt={2}
      flexDirection={isMediumTabletView ? "column" : "row"}
    >
      <Typography
        variant="body2"
        whiteSpace={isMediumTabletView ? "break-spaces" : "pre"}
      >
        {codeSnippet}
      </Typography>
      <Box
        mt={isMediumTabletView ? 2 : 0}
        width="100%"
        display="flex"
        justifyContent="flex-end"
        pl={1}
      >
        <Button
          variant="contained"
          onClick={handleCopyCode}
          fullWidth={isMediumTabletView}
          endIcon={<CopyIcon size="xs" color={white} />}
          style={{ whiteSpace: "nowrap" }}
        >
          Copy code
        </Button>
      </Box>
    </Box>
  );
}

export { WidgetCode };
