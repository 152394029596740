import React from "react";

type LinkCompanyIconProps = {
  size?: string;
};

const LinkCompanyIcon = (props: LinkCompanyIconProps) => {
  const { size = "small" } = props;

  return (
    <svg
      width={size === "small" ? "32.5" : "65"}
      height={size === "small" ? "32" : "64"}
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="64" height="64" rx="32" fill="#E5EEFF" />
      <g clip-path="url(#clip0_2909_241083)">
        <path
          d="M52.5 64C59.1274 64 64.5 58.6274 64.5 52C64.5 45.3726 59.1274 40 52.5 40C45.8726 40 40.5 45.3726 40.5 52C40.5 58.6274 45.8726 64 52.5 64Z"
          fill="#195FE9"
        />
        <path
          d="M52.4993 46.6665V51.9998M52.4993 51.9998V57.3332M52.4993 51.9998H47.166M52.4993 51.9998H57.8327"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M20.1568 31.0135V20.1602C20.1568 19.8985 20.2651 19.6476 20.458 19.4625C20.6509 19.2775 20.9125 19.1735 21.1853 19.1735H29.4139C29.6867 19.1735 29.9483 19.2775 30.1412 19.4625C30.3341 19.6476 30.4425 19.8985 30.4425 20.1602V31.0135M24.271 19.1735V17.2002M24.271 27.0669H26.3282M24.271 23.1202H26.3282M22.2139 34.9602H28.3853M22.2139 38.9069H28.3853M22.2139 42.8535H28.3853M18.7853 31.0135H31.8139C32.1926 31.0135 32.4996 31.308 32.4996 31.6713V46.1424C32.4996 46.5057 32.1926 46.8002 31.8139 46.8002H18.7853C18.4066 46.8002 18.0996 46.5057 18.0996 46.1424V31.6713C18.0996 31.308 18.4066 31.0135 18.7853 31.0135Z"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.9004 34.7998H42.9004M38.9004 30.7998H42.9004M38.9004 38.7998H42.9004M38.9004 42.7998H42.9004M35.5671 26.7998H46.2337C46.6019 26.7998 46.9004 27.0983 46.9004 27.4665V46.1331C46.9004 46.5013 46.6019 46.7998 46.2337 46.7998H35.5671C35.1989 46.7998 34.9004 46.5013 34.9004 46.1331V27.4665C34.9004 27.0983 35.1989 26.7998 35.5671 26.7998Z"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id="clip0_2909_241083">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(40.5 40)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkCompanyIcon;
