import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";

import { Box, CircularProgress } from "@mui/material";

import { LOAD_REQUEST_QUERY } from "../../../globals/graphql";
import MoovsDialog from "../../MoovsDialog";
import MoovsTabs from "../MoovsTabs";
import ActivityLogEmailTab from "./components/Email/ActivityLogEmailTab";
import useActivityLogTabs, {
  ActivityLogDialogTab,
} from "./hooks/useActivityLogTabs";
import ActivityLogSalesAutomationTab from "./components/SalesAutomation/ActivityLogSalesAutomationTab";

type RequestActivityLogDialogProps = {
  open: boolean;
  onClose: () => void;
  requestId: string;
  orderNumber: string;
  requestStage: string;
};
function RequestActivityLogDialog(props: RequestActivityLogDialogProps) {
  const { open, onClose, requestId, orderNumber, requestStage } = props;

  // queries
  const {
    data: requestData,
    loading: requestLoading,
    refetch,
  } = useQuery(LOAD_REQUEST_QUERY, {
    variables: {
      id: requestId,
    },
    skip: !requestId,
  });

  // hooks
  const { tabMode, onTabModeChange } = useActivityLogTabs(open);

  // effects
  useEffect(() => {
    if (open) refetch();
  }, [open, refetch]);

  // derived state
  const emailLogs = requestData?.node?.emailLogs;

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      dialogTitle={`Activity Log for Order: ${orderNumber}`}
      tabs={
        <MoovsTabs
          onChange={onTabModeChange}
          value={tabMode}
          tabs={[
            {
              label: "EMAIL",
              value: ActivityLogDialogTab.Email,
            },
            {
              label: "SALES AUTOMATION",
              value: ActivityLogDialogTab.SalesAutomation,
            },
          ]}
        />
      }
    >
      {requestLoading ? (
        <Box
          display="flex"
          flex="1"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {
            [
              <ActivityLogEmailTab
                emailLogs={emailLogs}
                requestStage={requestStage}
                orderNumber={orderNumber}
              />,

              <ActivityLogSalesAutomationTab requestId={requestId} />,
            ][tabMode]
          }
        </>
      )}
    </MoovsDialog>
  );
}

export default RequestActivityLogDialog;
