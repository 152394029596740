import React, { useEffect } from "react";
import {
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
  UseFormClearErrors,
} from "react-hook-form";

import { Box } from "@mui/material";

import RHFMultipleSelect from "components/reactHookFormInputs/RHFMultipleSelect";
import { useScreenSize } from "globals/hooks";
import {
  EditDefaultVehicles,
  EditDynamicPricingDefaultValues,
} from "../useEditDynamicPricingDefaultValues";

type VehiclesProps = {
  errors: FieldErrors;
  onSetValue: UseFormSetValue<EditDynamicPricingDefaultValues>;
  watch: UseFormWatch<EditDynamicPricingDefaultValues>;
  onClearErrors: UseFormClearErrors<EditDynamicPricingDefaultValues>;
  defaultVehicles: EditDefaultVehicles;
};

function Vehicles(props: VehiclesProps) {
  const { errors, defaultVehicles, watch, onSetValue, onClearErrors } = props;

  // hook
  const { isSmallTabletView } = useScreenSize();

  // update react hook form
  const updateReactHookForm = (newValues) => {
    const rhfValues = newValues.map((val) => ({
      id: val.id,
      value: val.value,
      disabled: val.disabled,
      isSelected: val.isSelected,
    }));
    onSetValue("vehicles", rhfValues);
    onClearErrors("vehicles");
  };

  // watch("vehicles") will be undefined, need to update with default vehicles here
  useEffect(() => {
    if (!watch("vehicles")) {
      onSetValue("vehicles", defaultVehicles);
    }
  }, [defaultVehicles, watch, onSetValue]);

  return (
    <Box my={1.5} width={isSmallTabletView ? "100%" : "49.5%"}>
      <RHFMultipleSelect
        name="vehicles"
        errorMessage={errors.vehicles?.message}
        options={defaultVehicles}
        labelId="vehicle-select"
        label="Vehicle"
        selectAllValue="All Vehicles"
        disabledToolTipText="Vehicle must have BRA on to add dynamic pricing"
        onUpdateRHF={updateReactHookForm}
        watch={watch}
      />
    </Box>
  );
}

export default Vehicles;
