import React from "react";
import { Control, FieldErrors } from "react-hook-form";
import { Moment } from "moment";

import { EditPromoCodeValues } from "../useEditPromoCodeDefaultValues";
import {
  DateRangeForm,
  DateType,
} from "../../../../../CreatePromoCodeDialog/steps/dateRange/useDateRangeForm";

type DateRangesProps = {
  dateType: DateType;
  control: Control<EditPromoCodeValues>;
  errors: FieldErrors;
  defaultStartDate: Moment;
  defaultEndDate: Moment;
};

function DateRanges(props: DateRangesProps) {
  const { dateType, control, errors, defaultStartDate, defaultEndDate } = props;

  return (
    <DateRangeForm
      dateType={dateType}
      control={control}
      errors={errors}
      defaultStartDate={defaultStartDate}
      defaultEndDate={defaultEndDate}
      isEdit={true}
    />
  );
}

export default DateRanges;
