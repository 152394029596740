import moment from "moment-timezone";

import { convertMinutesToHoursMinutes } from "../../../globals/utils/helpers";
import { OperatorRoute, Route } from "types";

const PLACEHOLDER = "-";

export const formatDateTime = (
  closeoutDateTime: string,
  timezoneId?: string
): string => {
  if (closeoutDateTime) {
    const time = moment.utc(closeoutDateTime).format("LLL");
    const timezone = timezoneId
      ? ` (${moment().tz(timezoneId).format("z")})`
      : "";
    return time + timezone;
  }
  return PLACEHOLDER;
};

export const parseDateTime = (
  operatorRoute: OperatorRoute | Route,
  timezoneId?: string
) => {
  if (!operatorRoute) return null;
  const driverStartDateTime = formatDateTime(
    operatorRoute.driverCloseoutStartDateTime,
    timezoneId
  );
  const driverEndDateTime = formatDateTime(
    operatorRoute.driverCloseoutEndDateTime,
    timezoneId
  );
  const farmeeStartDateTime = formatDateTime(
    operatorRoute.farmeeCloseoutStartDateTime,
    timezoneId
  );
  const farmeeEndDateTime = formatDateTime(
    operatorRoute.farmeeCloseoutEndDateTime,
    timezoneId
  );

  return {
    driverStartDateTime,
    driverEndDateTime,
    farmeeStartDateTime,
    farmeeEndDateTime,
  };
};

export const parseDurationMinutes = (operatorRoute: OperatorRoute | Route) => {
  if (!operatorRoute) return null;
  const driverDurationMinutes =
    convertMinutesToHoursMinutes(operatorRoute.driverCloseoutDurationMinutes) ||
    PLACEHOLDER;
  const farmeeDurationMinutes =
    convertMinutesToHoursMinutes(operatorRoute.farmeeCloseoutDurationMinutes) ||
    PLACEHOLDER;
  return {
    driverDurationMinutes,
    farmeeDurationMinutes,
  };
};
