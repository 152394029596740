import React from "react";

import { Box, Typography, CircularProgress } from "@mui/material";

import { grayDark } from "design-system/colors";
import { AutomatedPricingIcon } from "design-system/icons";
import { currency } from "globals/utils/helpers";
import { useScreenSize } from "globals/hooks";

type AutomatedBaseRateBlockProps = {
  automatedBRAmount: number;
};

function AutomatedBaseRateBlock(props: AutomatedBaseRateBlockProps) {
  const { automatedBRAmount } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  if (automatedBRAmount === null) return null;

  return (
    <Box ml={1}>
      <Box display="flex" flexDirection="column">
        <Box
          alignContent="flex-end"
          textAlign="right"
          {...(!isMobileView && { mt: 4 })}
        >
          <Typography variant="overline" style={{ color: grayDark }}>
            Base Rate
          </Typography>
          <Box textAlign="right">
            {automatedBRAmount === undefined ? (
              <Box
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <CircularProgress size={20} thickness={2} />
              </Box>
            ) : (
              <Box display="flex">
                <Box pr={0.5}>
                  <AutomatedPricingIcon size="small" />
                </Box>
                <Typography variant="caption">
                  {currency(automatedBRAmount)}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AutomatedBaseRateBlock;
