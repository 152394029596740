import React from "react";

type EnrollIconProps = { color?: string };

const EnrollIcon = (props: EnrollIconProps) => {
  const { color } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00049 5C8.00049 5 11.7783 1.90592 12.5084 1.52461C14.2914 0.593398 15.7807 1.01538 16.5242 1.90589C17.4552 3.02096 17.6194 4.57489 16.1591 6.09996C15.429 6.86251 11.3946 10.1507 8.85772 12.2004C5.78249 14.918 0.709951 17.9627 3.38174 21.7324C4.25834 22.9692 5.7149 23.4688 8.12931 22.4627C9.65989 21.8249 11.0654 20.4897 13.585 18.5388C15.3494 17.1726 21.5658 11.8127 21.9787 11.4565M21.9787 11.4565L22.0003 11.4379M21.9787 11.4565L22.0003 11.375L14.6669 11.7209M21.9787 11.4565L20.3874 19.1092"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="5.5" cy="6.5" r="3.5" fill={color || "#999999"} />
    </svg>
  );
};

export default EnrollIcon;
