import { WorkflowSelectionsEnum } from "../steps/workflow/schemaValidation";

type GetFormComponentStepsProps = {
  workflow: WorkflowSelectionsEnum;
  workFlowFormComponent: React.ReactNode;
  codeAndAmountFormComponent: React.ReactNode;
  bookingDateFormComponent: React.ReactNode;
  tripDateFormComponent: React.ReactNode;
  limitsFormComponent: React.ReactNode;
};

// this function will return a list of steps depending on which workflow the user selects
export const getFormComponentSteps = (props: GetFormComponentStepsProps) => {
  const {
    workflow,
    workFlowFormComponent,
    codeAndAmountFormComponent,
    bookingDateFormComponent,
    tripDateFormComponent,
    limitsFormComponent,
  } = props;

  const promoCodeTypeOptions = {
    anytime: {
      header: "Anytime Promo Code",
    },
    bookingDate: {
      header: "Booking Date Promo Code",
    },
    tripDate: {
      header: "Trip Date Promo Code",
    },
  };

  const formDictionary = {
    workflow: {
      header: "Select Promo Code Type",
      component: workFlowFormComponent,
    },
    codeAndAmount: {
      header: promoCodeTypeOptions[workflow]?.header,
      component: codeAndAmountFormComponent,
    },
    bookingDate: {
      header: promoCodeTypeOptions[workflow]?.header,
      component: bookingDateFormComponent,
    },
    tripDate: {
      header: promoCodeTypeOptions[workflow]?.header,
      component: tripDateFormComponent,
    },
    limits: {
      header: promoCodeTypeOptions[workflow]?.header,
      component: limitsFormComponent,
    },
  };

  // starter step needs an array of minimum length 2 for useFormStepProgress hook to work
  const starterStep = [formDictionary.workflow, null];

  const anytimeSteps = [
    formDictionary.workflow,
    formDictionary.codeAndAmount,
    formDictionary.limits,
  ];

  const bookingDateSteps = [
    formDictionary.workflow,
    formDictionary.codeAndAmount,
    formDictionary.bookingDate,
    formDictionary.limits,
  ];

  const tripDateSteps = [
    formDictionary.workflow,
    formDictionary.codeAndAmount,
    formDictionary.tripDate,
    formDictionary.limits,
  ];

  if (workflow === WorkflowSelectionsEnum.anytime)
    return { formComponentSteps: anytimeSteps };
  if (workflow === WorkflowSelectionsEnum.bookingDate)
    return { formComponentSteps: bookingDateSteps };
  if (workflow === WorkflowSelectionsEnum.tripDate)
    return { formComponentSteps: tripDateSteps };

  return { formComponentSteps: starterStep };
};
