import React from "react";
import startCase from "lodash/startCase";
import lowerCase from "lodash/lowerCase";

import { Box, Typography } from "@mui/material";

import { CheckIcon, WarningIcon } from "design-system/icons";
import { errorRed, grayMedium, green } from "design-system/colors";
import { CardCheckEnum } from "../../../types";

const { Passed, Failed, Unavailable } = CardCheckEnum;

type CheckStatusProps = {
  label: string;
  checkStatus: CardCheckEnum;
};

function CheckStatus(props: CheckStatusProps) {
  const { label, checkStatus } = props;

  const color =
    checkStatus === Passed
      ? green
      : checkStatus === Failed
      ? errorRed
      : grayMedium;

  const Icon =
    checkStatus === Passed
      ? CheckIcon
      : checkStatus === Failed
      ? WarningIcon
      : null;

  return (
    <>
      <Typography variant="overline" sx={{ whiteSpace: "nowrap" }}>
        {label}
      </Typography>
      <Box style={{ display: "flex" }}>
        {Icon && (
          <Box mr={1}>
            <Icon size="small" color={color} />
          </Box>
        )}
        <Typography variant="h6" sx={{ fontWeight: 400 }} color={color}>
          {startCase(lowerCase(checkStatus || Unavailable))}
        </Typography>
      </Box>
    </>
  );
}

export default CheckStatus;
