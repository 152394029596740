import { useMutation } from "@apollo/client";

import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";

import { grayDark, moovsBlue } from "design-system/colors";
import { UPDATE_OPERATOR_SETTINGS } from "globals/graphql";
import { useOperator, useSnackbar } from "globals/hooks";

type SendPOBTripStatusUpdateBlockProps = {
  setSaveIndicatorState: (value: string) => void;
};

function SendPOBTripStatusUpdateBlock(
  props: SendPOBTripStatusUpdateBlockProps
) {
  const { setSaveIndicatorState } = props;

  const snackbar = useSnackbar();
  const {
    settings: { pobStatusTextToBcEnabled },
  } = useOperator();

  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error(
        "Error updating setting for text notifications for trip status updates"
      );
    },
  });

  const handleUpdatePOBStatusTextEnabled = () => {
    setSaveIndicatorState("loading");
    updateOperatorSettings({
      variables: {
        input: {
          pobStatusTextToBcEnabled: !pobStatusTextToBcEnabled,
        },
      },
    });
  };

  return (
    <Box ml={1}>
      <FormControlLabel
        labelPlacement="end"
        control={
          <Checkbox
            checked={pobStatusTextToBcEnabled}
            sx={{
              py: 0,
              color: pobStatusTextToBcEnabled ? moovsBlue : grayDark,
            }}
            onChange={handleUpdatePOBStatusTextEnabled}
          />
        }
        label={
          <Typography variant="caption" style={{ color: grayDark }}>
            Include Passenger on board notification for Booking Contact
          </Typography>
        }
      />
    </Box>
  );
}

export default SendPOBTripStatusUpdateBlock;
