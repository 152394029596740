import React from "react";

import { Box, Divider, Typography } from "@mui/material";

import { useOperator, useScreenSize } from "globals/hooks";
import {
  PlanCardActionBtnVariant,
  PlanCardDesktop,
  PlanCardMobile,
  PlanLengthToggle,
} from "./components";
import {
  aqua,
  moovsBlue,
  moovsBlueSelected,
  purple,
  tintAqua,
  tintPurple,
} from "design-system/colors";
import {
  EnterprisePlanIcon,
  FreePlanIcon,
  ProPlanIcon,
  StandardPlanIcon,
} from "design-system/icons";
import { LagoPlanCode, PlanVariant } from "types";
import { getBtnVariant } from "./utils/getBtnVariant";

// Book a demo Link
const BOOK_A_DEMO_LINK = "https://calendly.com/d/4g4-3q8-9td/moovs-chat";

type SubscriptionPlansProps = {
  setPlanLength: React.Dispatch<React.SetStateAction<"monthly" | "annual">>;
  planLength: "monthly" | "annual";
};

function SubscriptionPlans(props: SubscriptionPlansProps) {
  const { planLength, setPlanLength } = props;

  // hooks
  const { isSmallTabletView } = useScreenSize();
  const operator = useOperator();
  const { plan, lagoPlanCode } = operator || {};

  const handleToggle = (_: any, length) => {
    if (length) setPlanLength(length);
  };

  const plans = [
    {
      name: "Free",
      icon: <FreePlanIcon />,
      ...(plan === PlanVariant.Free && {
        price: 0,
        btnVariant: PlanCardActionBtnVariant.CURRENT,
      }),
    },
    {
      name: "Standard",
      icon: <StandardPlanIcon />,
      price: planLength === "monthly" ? 129 : 99,
      btnVariant: getBtnVariant(
        lagoPlanCode,
        planLength === "monthly"
          ? LagoPlanCode.StandardMonthly
          : LagoPlanCode.StandardAnnual
      ),
      bgColor: moovsBlueSelected,
      fontColor: moovsBlue,
    },
    {
      name: "Pro",
      icon: <ProPlanIcon />,
      price: planLength === "monthly" ? 179 : 149,
      btnVariant: getBtnVariant(
        lagoPlanCode,
        planLength === "monthly"
          ? LagoPlanCode.ProMonthly
          : LagoPlanCode.ProAnnual
      ),
      bgColor: tintAqua,
      fontColor: aqua,
    },
    {
      name: "Enterprise",
      icon: <EnterprisePlanIcon />,
      price: 499,
      btnVariant: getBtnVariant(
        lagoPlanCode,
        planLength === "monthly"
          ? LagoPlanCode.VipMonthly
          : LagoPlanCode.VipAnnual
      ),
      bgColor: tintPurple,
      fontColor: purple,
      link: BOOK_A_DEMO_LINK,
    },
  ];

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {/* Header Section */}
      <Box
        display="flex"
        flexWrap="wrap"
        flexDirection={isSmallTabletView ? "column" : "row"}
        justifyContent="space-between"
        alignItems={isSmallTabletView ? "flex-start" : "center"}
        mb={2}
      >
        <Typography variant="h2" mb={isSmallTabletView ? 2 : 0}>
          Plans
        </Typography>

        <PlanLengthToggle planLength={planLength} handleToggle={handleToggle} />
      </Box>

      {isSmallTabletView && (
        <Divider
          sx={{
            width: "initial",
            mx: -2,
            mt: 1,
            mb: 3,
          }}
        />
      )}

      {/* Plan Selection */}
      <Box
        display="flex"
        mb={2}
        {...(isSmallTabletView
          ? { flexDirection: "column", gap: 1 }
          : {
              justifyContent: "space-between",
              alignItems: "flex-start",
              // styling to match MUI Grid
              width: "calc(100% + 24px)",
              ml: "-24px",
            })}
      >
        {/* Adding spacing on desktop */}
        {!isSmallTabletView && <Box width="20%" minWidth="161px"></Box>}

        {plans.map((plan) =>
          isSmallTabletView ? (
            <PlanCardMobile key={plan.name} {...plan} planLength={planLength} />
          ) : (
            <PlanCardDesktop
              key={plan.name}
              {...plan}
              planLength={planLength}
            />
          )
        )}
      </Box>
    </Box>
  );
}

export default SubscriptionPlans;
