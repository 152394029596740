import React from "react";

type UploadCloudIconProps = {
  color?: string;
  size: "small";
};

const UploadCloudIcon = (props: UploadCloudIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.3942 11.75C4.36351 11.75 4.33283 11.7477 4.30259 11.7422C3.66662 11.6271 3.08599 11.2564 2.66427 10.6949C2.23252 10.1201 1.99669 9.38431 2.0006 8.62436C1.993 8.21304 2.05764 7.80418 2.19065 7.42232C2.32366 7.04046 2.52229 6.69347 2.77463 6.40215C3.02698 6.11083 3.32783 5.88119 3.65915 5.72701C3.8616 5.6328 4.07287 5.56795 4.28812 5.53349C4.53543 5.4939 4.7603 5.34225 4.87532 5.11077C5.1578 4.54232 5.55299 4.05606 6.03075 3.69311C6.61559 3.24881 7.29933 3.00816 7.99998 3C8.94837 3.02056 9.85396 3.45818 10.531 4.22309C11.0519 4.81165 11.4071 5.55964 11.5594 6.3704C11.6313 6.75299 11.9867 7.03104 12.3545 7.10332C12.4094 7.11412 12.4641 7.12761 12.5182 7.14379C12.8051 7.22944 13.0716 7.3882 13.298 7.60832C13.5244 7.82844 13.705 8.10432 13.8264 8.41556C13.9478 8.7268 14.0069 9.06546 13.9994 9.40653C13.9994 11.7232 12.0237 11.7502 11.9171 11.75L11.9075 11.75M7.96711 13L7.96707 6.24406M7.96707 6.24406L6.03075 8.16077M7.96707 6.24406L9.84893 8.16077"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
};

export default UploadCloudIcon;
