/**
 * @file phoneNumberFormatter
 *
 * Abstraction of libphonenumber-js
 */
import { formatIncompletePhoneNumber } from "libphonenumber-js";
import { parsePhoneNumber } from "./helpers";

export type phoneNumber = {
  formatted: string;
  international: string;
  plain: string;
  e164: string;
  countryCode: string;
  isPossible: () => boolean;
};

/**
 * @function formatPhoneNumber
 * A helper function to format from one format to another.
 * If a phone number does not have a country code, we can assume it is 'US'.
 */
export const formatPhoneNumber = (
  phoneNumber: string,
  countryCode?: string,
  // country dial code only needed for formating "as you type"
  // international inputs which include dial code
  countryDialCode?: string
): phoneNumber => {
  // return empty object if no phone number passed in.
  if (phoneNumber === undefined || phoneNumber === null) {
    return;
  }

  // slice country dial code when provided
  // leaving dialing code at beginning causes bugs
  // when parsing international numbers
  if (countryDialCode) {
    phoneNumber = phoneNumber.slice(countryDialCode.length);
  }

  // we handle empty strings as a special case to handle
  // saving while auto editing. we could imagine in
  // the future not saving any invalid numbers client side,
  // in which case we would remove this.
  if (phoneNumber === "" || phoneNumber === "(   )    -    ") {
    return {
      formatted: "",
      international: "",
      plain: "",
      e164: "",
      countryCode: "",
      isPossible: (): boolean => false,
    };
  }

  const parsedPhoneNumber = parsePhoneNumber(phoneNumber, countryCode);

  if (!parsedPhoneNumber) return;

  // formatted in national style for us numbers, international for all others
  let formatted =
    parsedPhoneNumber.country === "US"
      ? parsedPhoneNumber.format("NATIONAL")
      : parsedPhoneNumber.format("INTERNATIONAL");

  // formats incomplete numbers
  // in the event we remove saving partial numbers,
  // we can remove this part as well.
  if (!parsedPhoneNumber.isPossible()) {
    formatted =
      parsedPhoneNumber.country === "US"
        ? formatIncompletePhoneNumber(formatted, "US") // formats national
        : formatIncompletePhoneNumber(formatted); // formats as international
  }

  return {
    formatted,
    plain: parsedPhoneNumber.nationalNumber as string,
    e164: parsedPhoneNumber.number as string,
    international: parsedPhoneNumber.format("INTERNATIONAL"),
    countryCode: parsedPhoneNumber.country,
    isPossible: parsedPhoneNumber.isPossible,
  };
};
