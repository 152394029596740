import React, { Dispatch, SetStateAction } from "react";
import { useMutation } from "@apollo/client/react";

import { UPDATE_OPERATOR_SETTINGS } from "globals/graphql";
import { useAnalytics, useOperator, useSnackbar } from "globals/hooks";
import LabeledSwitchInput from "./LabeledSwitchInput";

type LuggageToggleBlockProps = {
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

const LuggageToggleBlock = (props: LuggageToggleBlockProps) => {
  const { setSaveIndicatorState } = props;

  // hooks
  const operator = useOperator();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // derived
  const isLuggageEnabled = operator.settings?.luggageEnabled;

  // mutations
  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      snackbar.error("Error updating operator");
    },
  });

  // event handlers
  const handleToggleChange = () => {
    setSaveIndicatorState("loading");
    if (!isLuggageEnabled) {
      track("settings_luggageOn");
    }
    updateOperatorSettings({
      variables: {
        input: {
          luggageEnabled: !isLuggageEnabled,
        },
      },
    });
  };

  return (
    <LabeledSwitchInput
      name="luggageEnabled"
      checked={isLuggageEnabled}
      onChange={handleToggleChange}
      text="Enable luggage options for orders"
      subText={`Access to add luggage types to orders turned ${
        isLuggageEnabled ? "on" : "off"
      }`}
    />
  );
};

export default LuggageToggleBlock;
