import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";

import { Box, CircularProgress } from "@mui/material";

import { LOAD_INVOICE_QUERY } from "../../../globals/graphql";
import MoovsDialog from "../../MoovsDialog";
import ActivityLogEmailTab from "./components/Email/ActivityLogEmailTab";

type InvoiceActivityLogDialogProps = {
  open: boolean;
  onClose: () => void;
  invoiceId: string;
  invoiceNumber: string;
};
function InvoiceActivityLogDialog(props: InvoiceActivityLogDialogProps) {
  const { open, onClose, invoiceId, invoiceNumber } = props;

  // queries
  const {
    data: invoiceData,
    loading: invoiceLoading,
    refetch: invoiceRefetch,
  } = useQuery(LOAD_INVOICE_QUERY, {
    variables: {
      id: invoiceId,
    },
    skip: !invoiceId,
  });

  // effects
  useEffect(() => {
    if (open) invoiceRefetch();
  }, [open, invoiceRefetch]);

  // derived state
  const emailLogs = invoiceData?.node?.emailLogs;

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      dialogTitle={`Activity Log for Invoice: ${invoiceNumber}`}
    >
      {invoiceLoading ? (
        <Box
          display="flex"
          flex="1"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <ActivityLogEmailTab emailLogs={emailLogs} />
      )}
    </MoovsDialog>
  );
}

export default InvoiceActivityLogDialog;
