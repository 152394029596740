import React, { Dispatch, SetStateAction } from "react";

import { Box, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";

import { UPDATE_ROUTE_DISPATCH_MUTATION } from "../../globals/graphql";
import { useSnackbar } from "../../globals/hooks/useSnackbar";
import SquareChip from "../../design-system/components/chips/SquareChip";
import {
  granite,
  grayLight,
  moovsBlue,
  blueLabelBackground,
} from "../../design-system/colors";
import { OperatorRoute } from "../../types";
import {
  WheelIcon,
  MapIcon,
  TruckIcon,
  SyncIcon,
  BriefcaseIcon,
  ClipboardIcon,
  SeatIcon,
  PlaneIcon,
} from "../../design-system/icons";
import { useAnalytics } from "../../globals/hooks";
import { fromGlobalId, toGlobalId } from "../../globals/utils/helpers";

export const dispatchAlertsArr = [
  {
    dbName: "newDriver",
    name: "New Driver",
    icon: WheelIcon,
  },
  {
    dbName: "uniqueLocation",
    name: "Location",
    icon: MapIcon,
  },
  {
    dbName: "airport",
    name: "Airport",
    icon: PlaneIcon,
  },
  {
    dbName: "largeVehicle",
    name: "Large Vehicle",
    icon: TruckIcon,
  },
  {
    dbName: "partner",
    name: "Partner",
    icon: SyncIcon,
  },
  {
    dbName: "luggage",
    name: "Luggage",
    icon: BriefcaseIcon,
  },
  {
    dbName: "signage",
    name: "Signage",
    icon: ClipboardIcon,
  },
  {
    dbName: "carSeat",
    name: "Car Seat",
    icon: SeatIcon,
  },
];

type DispatchAlertsProps = {
  setSaveIndicatorState: (
    saveState: "loading" | "default" | "saved" | "error"
  ) => void;
  operatorRoute: OperatorRoute;
  isTripSummaryVariant?: boolean;
  setIsIndicatorVisible?: Dispatch<SetStateAction<boolean>>;
};

function DispatchAlertsBlock(props: DispatchAlertsProps) {
  const {
    setSaveIndicatorState,
    operatorRoute,
    isTripSummaryVariant,
    setIsIndicatorVisible,
  } = props;

  // derived state
  const operatorRouteId = fromGlobalId(operatorRoute.id).id;
  const routeId = toGlobalId(operatorRouteId, "Route");

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // Mutations
  const [updateRouteDispatch] = useMutation(UPDATE_ROUTE_DISPATCH_MUTATION, {
    refetchQueries: ["OperatorRoute"],
    onCompleted() {
      setSaveIndicatorState("saved");
      track("dispatch_alertSelected");
      setIsIndicatorVisible &&
        setTimeout(() => setIsIndicatorVisible(false), 3000);
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error("Error updating Dispatch Alerts!");
    },
  });

  // Event handlers
  const handleAlertClick = (dbName: string) => {
    setSaveIndicatorState("loading");
    setIsIndicatorVisible && setIsIndicatorVisible(true);

    updateRouteDispatch({
      variables: {
        input: {
          routeId: routeId,
          alert: {
            [dbName]: !operatorRoute.alert[dbName],
          },
        },
      },
    });
  };

  return (
    <Box>
      <Box my={isTripSummaryVariant ? 1 : 2}>
        <Typography variant={isTripSummaryVariant ? "overline" : "h5"}>
          Dispatch Alerts
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        flex="1"
        maxWidth="640px"
        flexWrap="wrap"
        justifyContent="flex-start"
      >
        {dispatchAlertsArr.map((alert, index) => {
          return (
            <Box
              key={index}
              m={0.5}
              onClick={() => {
                handleAlertClick(alert.dbName);
              }}
            >
              <SquareChip
                label={alert.name}
                variant="dispatchAlert"
                color={operatorRoute.alert[alert.dbName] ? moovsBlue : granite}
                backgroundColor={
                  operatorRoute.alert[alert.dbName]
                    ? blueLabelBackground
                    : grayLight
                }
                icon={alert.icon}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default DispatchAlertsBlock;
