import React from "react";

import { Chip } from "@mui/material";

import { grayLight } from "design-system/colors";
import { dynamicPricingChips } from "../utils/types";

type DynamicPricingChipProps = {
  label: string;
  iconName: string;
};

function DynamicPricingChip(props: DynamicPricingChipProps) {
  const { label, iconName } = props;

  return (
    <Chip
      sx={{
        backgroundColor: grayLight,
        paddingLeft: 0.4,
        marginRight: 1,
        marginBottom: 1,
      }}
      label={label}
      icon={<img src={dynamicPricingChips[iconName]} alt={iconName} />}
    />
  );
}

export default DynamicPricingChip;
