import { LagoPlanCode, PlanVariant } from "types";
import { PlanCardActionBtnVariant } from "../components";

const planHierarchyList = [
  LagoPlanCode.StandardMonthly,
  LagoPlanCode.StandardAnnual,
  LagoPlanCode.ProMonthly,
  LagoPlanCode.ProAnnual,
  LagoPlanCode.VipMonthly,
  LagoPlanCode.VipAnnual,
];

export const getBtnVariant = (
  currentPlanCode: LagoPlanCode,
  btnPlanCode: LagoPlanCode
) => {
  if (!currentPlanCode)
    return btnPlanCode.includes(PlanVariant.Vip)
      ? PlanCardActionBtnVariant.DEMO
      : PlanCardActionBtnVariant.UPGRADE;

  const currentPlanCodeIndex = planHierarchyList.indexOf(currentPlanCode);
  const planButtonVariantIndex = planHierarchyList.indexOf(btnPlanCode);

  if (currentPlanCodeIndex === -1 || planButtonVariantIndex === -1) return null;

  if (currentPlanCode.includes("ANNUAL") && btnPlanCode.includes("MONTHLY"))
    return btnPlanCode.includes(PlanVariant.Vip)
      ? PlanCardActionBtnVariant.NO_DEMO
      : null;

  // hierarchy comparsion
  if (currentPlanCodeIndex < planButtonVariantIndex) {
    // VIP plans show demo or no demo variants
    return btnPlanCode.includes(PlanVariant.Vip)
      ? PlanCardActionBtnVariant.DEMO
      : currentPlanCode === LagoPlanCode.StandardAnnual &&
        btnPlanCode === LagoPlanCode.ProAnnual
      ? PlanCardActionBtnVariant.INTERCOM_UPGRADE
      : PlanCardActionBtnVariant.UPGRADE;
  } else if (currentPlanCodeIndex > planButtonVariantIndex) {
    // additional logic for annual plans to not show downgrade buttons
    return currentPlanCode.includes("ANNUAL")
      ? null
      : PlanCardActionBtnVariant.DOWNGRADE;
  } else {
    return btnPlanCode.includes(PlanVariant.Vip)
      ? PlanCardActionBtnVariant.VIP_CURRENT
      : PlanCardActionBtnVariant.CURRENT;
  }
};
