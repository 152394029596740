/**
 * @file graphql/shuttleRoute.ts
 * queries/mutations for ShuttleRoute.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_SHUTTLE_ROUTES_QUERY = gql`
  query loadShuttleRoutes(
    $searchTerm: String
    $companyId: ID!
    $cursor: String
    $limit: Int
  ) {
    loadShuttleRoutes(
      searchTerm: $searchTerm
      companyId: $companyId
      after: $cursor
      first: $limit
    ) {
      totalCount
      edges {
        node {
          id
          company {
            id
          }
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const CREATE_SHUTTLE_ROUTE_MUTATION = gql`
  mutation CreateShuttleRoute($input: CreateShuttleRouteInput!) {
    createShuttleRoute(input: $input) {
      shuttleRoute {
        id
        company {
          id
        }
        name
      }
    }
  }
`;
