import React from "react";

import OnboardingLayout from "design-system/components/layout/OnboardingLayout";
import OperatorOnboardingPage from "pages/onboarding/OperatorOnboardingPage";

function OnboardingRouter() {
  return (
    <OnboardingLayout>
      <OperatorOnboardingPage />
    </OnboardingLayout>
  );
}

export default OnboardingRouter;
