import React from "react";

import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { moovsBlue } from "design-system/colors";
import { useHistory } from "react-router-dom";

function ConfNumberColumn(props: GridRenderCellParams<string>) {
  const { value, row } = props;
  const history = useHistory();

  const navigateToTripSummary = () => {
    history.push(`/reservations/${row.request.id}?tripId=${row.id}`, {
      backNavigationPath: `${history.location.pathname}${history.location.search}`,
    });
  };

  return (
    <Typography
      variant="subtitle2"
      onClick={navigateToTripSummary}
      sx={{
        color: moovsBlue,
        cursor: "pointer",
        "&:hover": { textDecoration: "underline" },
      }}
    >
      {value}
    </Typography>
  );
}

export default ConfNumberColumn;
