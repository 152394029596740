import React from "react";

import { Box, Typography } from "@mui/material";
import { convertMinutesToHoursMinutes } from "globals/utils/helpers";
import moment from "moment";
import { alabaster } from "design-system/colors";
import InfoIconTooltip from "components/globals/InfoIconTooltip";

type RideReportSummaryProps = {
  garageOut: Date;
  garageIn: Date;
};

function RideReportSummary(props: RideReportSummaryProps) {
  const { garageOut, garageIn } = props;

  // derived state
  const totalDriveTimeMinutes = moment(
    moment.utc(garageIn).startOf("minute")
  ).diff(moment(garageOut).startOf("minute"), "minutes");

  const totalDriveTime = convertMinutesToHoursMinutes(
    totalDriveTimeMinutes,
    "semi-formal"
  );

  return (
    <Box
      sx={{
        backgroundColor: alabaster,
        borderRadius: "4px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        px: 2,
        py: 1,
      }}
    >
      <Typography variant="subtitle2">Total Drive Time</Typography>
      <Box sx={{ ml: 0.5, mr: 1 }}>
        <InfoIconTooltip tooltipText="Time from Garage Out to Garage In" />
      </Box>
      <Typography variant="body2">{totalDriveTime}</Typography>
    </Box>
  );
}

export default RideReportSummary;
