import React, { ComponentProps } from "react";

import TextField, { TextFieldProps } from "@mui/material/TextField";

import {
  AddressElement,
  AuBankAccountElement,
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  FpxBankElement,
  IbanElement,
  IdealBankElement,
} from "@stripe/react-stripe-js";

import StripeInput from "./StripeInput";

type StripeElement =
  | typeof AuBankAccountElement
  | typeof CardCvcElement
  | typeof CardExpiryElement
  | typeof CardNumberElement
  | typeof FpxBankElement
  | typeof IbanElement
  | typeof IdealBankElement
  | typeof CardElement
  | typeof AddressElement;

interface StripeTextFieldProps<T extends StripeElement>
  extends Omit<TextFieldProps, "onChange" | "inputComponent" | "inputProps"> {
  inputProps?: ComponentProps<T>;
  stripeElement?: T;
  onChange?: React.ComponentProps<T>["onChange"];
  isStripeElementEmpty?: boolean;
}

const StripeTextField = <T extends StripeElement>(
  props: StripeTextFieldProps<T>
) => {
  const {
    InputProps = {},
    inputProps,
    error,
    stripeElement,
    autoFocus,
    label,
    helperText,
    isStripeElementEmpty,
    onChange,
    ...other
  } = props;

  return (
    <TextField
      fullWidth
      autoFocus={autoFocus}
      variant="outlined"
      error={error}
      label={label}
      helperText={helperText}
      {...(!isStripeElementEmpty && { InputLabelProps: { shrink: true } })}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...inputProps,
          ...InputProps.inputProps,
          component: stripeElement,
          isHideOriginalPlaceholder: !!label,
          onChange: onChange,
        },
        inputComponent: StripeInput,
      }}
      {...(other as any)}
    />
  );
};

export default StripeTextField;
