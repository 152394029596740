import React from "react";
import moment from "moment-timezone";

import { Box, Typography } from "@mui/material";

import PrioritizedFlightInfo from "./PrioritizedFlightInfo";
import { FlightStatus, TransitStop } from "../../../types";
import { useScreenSize } from "../../../globals/hooks";
import { getFlightTimePriorityQueue } from "../helpers/flightTimeHelpers";

type FlightDetailsProps = {
  transitStop: TransitStop;
  transitStopType: "origin" | "destination";
  flightStatus: FlightStatus;
};

function FlightDetails(props: FlightDetailsProps) {
  const { transitStop, transitStopType, flightStatus } = props;

  const { scheduledGate, scheduledTerminal, actualGate, actualTerminal } =
    transitStop;

  const { isMobileView } = useScreenSize();

  const isDestination = transitStopType === "destination";
  const isMobileDestination = isMobileView && isDestination;
  const isCancelledFlight = flightStatus === "CANCELLED";

  const flightTimePriorityQueue = getFlightTimePriorityQueue(transitStop);

  const flightDateOrTime = (dateTime, mode: "date" | "time") =>
    moment(dateTime).format(mode === "date" ? "M/D/YY" : "h:mmA");

  return (
    <Box
      display="flex"
      flexDirection={isMobileView ? "column" : "row"}
      flexWrap="wrap"
      flex={1}
      justifyContent={transitStopType === "origin" ? "flex-start" : "flex-end"}
    >
      <Box
        display="flex"
        mb={1}
        flex={2}
        {...(isMobileDestination && { justifyContent: "flex-end" })}
        {...(isDestination ? { ml: 2 } : { mr: 2 })}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems={isMobileDestination ? "flex-end" : "flex-start"}
        >
          <Typography
            variant="overline"
            {...(isMobileDestination && { align: "right" })}
          >
            {flightTimePriorityQueue[0].timeLabel}
          </Typography>
          <PrioritizedFlightInfo
            transitStopType={transitStopType}
            primaryText={flightDateOrTime(
              flightTimePriorityQueue[0].time,
              "date"
            )}
            secondaryText={
              flightTimePriorityQueue?.[1]
                ? flightDateOrTime(flightTimePriorityQueue[1].time, "date")
                : ""
            }
            primaryProps={{ variant: "body2", style: { fontWeight: 500 } }}
            isTime
            strikethroughAll={isCancelledFlight}
          />
          <PrioritizedFlightInfo
            transitStopType={transitStopType}
            primaryText={flightDateOrTime(
              flightTimePriorityQueue[0].time,
              "time"
            )}
            secondaryText={
              flightTimePriorityQueue?.[1]
                ? flightDateOrTime(flightTimePriorityQueue[1].time, "time")
                : ""
            }
            primaryProps={{ variant: "h4" }}
            isTime
            strikethroughAll={isCancelledFlight}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        mb={1}
        {...(isDestination ? { justifyContent: "flex-end", ml: 2 } : { mr: 2 })}
        flex={1}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems={isMobileDestination ? "flex-end" : "flex-start"}
        >
          <Typography
            variant="overline"
            {...(isMobileDestination && { align: "right" })}
          >
            TERMINAL
          </Typography>
          <PrioritizedFlightInfo
            transitStopType={transitStopType}
            primaryText={actualTerminal}
            secondaryText={scheduledTerminal}
            primaryProps={{ variant: "h5" }}
            strikethroughAll={isCancelledFlight}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flex={1}
        {...(isDestination ? { justifyContent: "flex-end", ml: 2 } : { mr: 2 })}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems={isMobileDestination ? "flex-end" : "flex-start"}
        >
          <Typography
            variant="overline"
            {...(isMobileDestination && { align: "right" })}
          >
            GATE
          </Typography>
          <PrioritizedFlightInfo
            transitStopType={transitStopType}
            primaryText={actualGate}
            secondaryText={scheduledGate}
            primaryProps={{ variant: "h5" }}
            strikethroughAll={isCancelledFlight}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default FlightDetails;
