import { useMutation } from "@apollo/client";
import first from "lodash/first";
import { useHistory } from "react-router-dom";
import { GraphQLError } from "graphql";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

import { moovsBlue } from "design-system/colors";
import { CREATE_OPERATOR_AND_USER_MUTATION } from "globals/graphql";
import {
  useAnalytics,
  useAuth,
  useResendInvite,
  useSnackbar,
} from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { auth } from "globals/utils/firebaseApp";

type useCreateOperatorAndSignUpProps = {
  onLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onError: React.Dispatch<React.SetStateAction<string>>;
  credentials: {
    email: string;
    password: string;
    confirmPassword: string;
  };
};

type MoovsGraphQLError = GraphQLError & {
  code?: string;
  clientData?: { userId: string };
};

function useCreateOperatorAndSignUp(props: useCreateOperatorAndSignUpProps) {
  const { onLoading, onError, credentials } = props;

  // hooks
  const history = useHistory();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const { onResendInvite } = useResendInvite({
    onCompleted: () => track("unauthenticated_inviteEmailResent"),
  });
  const { onLogin } = useAuth();

  const [createOperatorAndUser] = useMutation(
    CREATE_OPERATOR_AND_USER_MUTATION,
    {
      onError(error) {
        onLoading && onLoading(false);

        onError && onError(error.message);

        const emailIsInUse =
          (first(error?.graphQLErrors) as MoovsGraphQLError)?.code ===
          "MOOVS_USER_EMAIL_IN_USE";
        const inviteIsPending =
          (first(error?.graphQLErrors) as MoovsGraphQLError)?.code ===
          "MOOVS_USER_INVITE_PENDING";

        const errorMessage = getErrorMessage(error) || error.message;

        snackbar.error(errorMessage, {
          linkLabel: emailIsInUse
            ? "Log In"
            : inviteIsPending
            ? "Resend Email"
            : null,
          linkColor: emailIsInUse || inviteIsPending ? moovsBlue : null,
          ...(emailIsInUse && {
            link: "/login",
          }),
          ...(inviteIsPending && {
            onLinkClick: onResendInvite(
              (first(error?.graphQLErrors) as MoovsGraphQLError)?.clientData
                .userId
            ),
          }),
        });
      },
    }
  );

  const handleSignUp = async () => {
    onLoading(true);

    const email = credentials.email;

    const { data, errors } = await createOperatorAndUser({
      variables: {
        input: {
          email,
        },
      },
      onCompleted() {
        track("onboarding_accountInfoSubmitted", { accountType: "Email" });
      },
    });

    if (errors) return;

    try {
      const firebaseResponse = await createUserWithEmailAndPassword(
        auth,
        email,
        credentials.password
      );
      const token = await firebaseResponse.user.getIdToken();

      await onLogin(token);

      if (data.createOperator.operator.users[0].role.slug === "owner") {
        history.replace("/onboarding");
      } else {
        history.replace("/settings/general");
      }
    } catch (error) {
      onLoading(false);
      const errorMessage = error.message.replace("GraphQL error: ", "");
      snackbar.error(errorMessage);
      onError(errorMessage);
    }
  };

  const handleLoginWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const firebaseResponse = await signInWithPopup(auth, provider);

      const { data, errors } = await createOperatorAndUser({
        variables: {
          input: {
            email: firebaseResponse.user.email,
          },
        },
        onCompleted() {
          track("onboarding_accountInfoSubmitted", { accountType: "Google" });
        },
      });

      if (errors) return;

      const token = await firebaseResponse.user.getIdToken();

      await onLogin(token);

      if (data.createOperator.operator.users[0].role.slug === "owner") {
        history.replace("/onboarding");
      } else {
        history.replace("/settings/general");
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data : error.message;
      snackbar.error(errorMessage);
    }
  };

  return { onSignUp: handleSignUp, onLogInWithGoogle: handleLoginWithGoogle };
}

export default useCreateOperatorAndSignUp;
