import first from "lodash/first";
import last from "lodash/last";

import moment from "moment-timezone";

import { convertMinutesToHoursMinutes } from "globals/utils/helpers";
import { FarmRelationshipEnum, OperatorRoute, Route } from "types";

export enum DateTimeVariant {
  Start = "START",
  End = "END",
}

export type DateTimeInfoForCloseTripDialog = {
  scheduledStartDateTime: string;
  scheduledEndDateTime: string;
  driverCloseoutStartDateTime: string;
  driverCloseoutEndDateTime: string;
  isClosedOut: boolean;
  dropOffIsEstimate: boolean;
  dropOffNote: string;
};

// gets scheduled date times and relevant information for each route variant (farmee, farmor, non-farm),
export const getDateTimeInfoForCloseTripDialog = (
  operatorRoute: OperatorRoute
): DateTimeInfoForCloseTripDialog => {
  const {
    farmRelationship,
    farmeeCloseoutEndDateTime,
    farmeeCloseoutStartDateTime,
    farmeeCloseoutNote,
    operatorCloseoutEndDateTime,
    operatorCloseoutStartDateTime,
    operatorCloseoutNote,
  } = operatorRoute;

  // !isFarmee is the farmor OR non-farmed route case
  const isFarmee = farmRelationship === FarmRelationshipEnum.Farmee;

  const scheduledStartDateTime = isFarmee
    ? farmeeCloseoutStartDateTime
    : operatorCloseoutStartDateTime;

  const scheduledEndDateTime = isFarmee
    ? farmeeCloseoutEndDateTime
    : operatorCloseoutEndDateTime;

  const dropOffNote = isFarmee ? farmeeCloseoutNote : operatorCloseoutNote;

  if (scheduledStartDateTime) {
    // case where trip has been closed out
    return {
      dropOffNote,
      dropOffIsEstimate: false,
      isClosedOut: true,
      scheduledStartDateTime,
      scheduledEndDateTime,
      ...getDriverDateTimes(operatorRoute),
    };
  } else {
    // case where trip has NOT been closed out
    const { customerStartDateTime, customerEndDateTime, dropOffIsEstimate } =
      getCustomerDateTimes(operatorRoute);

    return {
      dropOffNote,
      dropOffIsEstimate,
      isClosedOut: false,
      scheduledStartDateTime: customerStartDateTime,
      scheduledEndDateTime: customerEndDateTime,
      ...getDriverDateTimes(operatorRoute),
    };
  }
};

// if timezone id is passed, return it formatted as string with correct timezone
// otherwise return as utc to be used in an input field
export const getCustomerDateTimes = (
  operatorRoute: OperatorRoute | Route,
  timezoneId?: string
) => {
  const {
    operatorCloseoutDurationMinutes,
    operatorCloseoutEndDateTime,
    operatorCloseoutStartDateTime,
    trip: { stops, estimatedDuration, totalDuration },
  } = operatorRoute;

  const timezoneClause = timezoneId
    ? ` (${moment().tz(timezoneId).format("z")})`
    : "";

  const { dateTime: scheduledStartDateTime } = first(stops);
  const { dateTime: scheduledEndDateTime } = last(stops);

  // conditions
  const operatorHasClosedTrip =
    operatorCloseoutStartDateTime && operatorCloseoutEndDateTime;
  const customerHasScheduledEndTime = !!scheduledEndDateTime; // if false, then end time is estimated

  const customerStartDateTime = operatorHasClosedTrip
    ? operatorCloseoutStartDateTime
    : scheduledStartDateTime;

  const customerEndDateTime = operatorHasClosedTrip
    ? operatorCloseoutEndDateTime
    : customerHasScheduledEndTime
    ? scheduledEndDateTime
    : moment(scheduledStartDateTime)
        .add(estimatedDuration, "minutes")
        .toISOString();

  const customerDuration = operatorHasClosedTrip
    ? convertMinutesToHoursMinutes(operatorCloseoutDurationMinutes)
    : convertMinutesToHoursMinutes(
        customerHasScheduledEndTime ? totalDuration : estimatedDuration
      );

  return {
    customerDuration,
    customerStartDateTime:
      customerStartDateTime && timezoneClause
        ? moment.utc(customerStartDateTime).format("LLL") + timezoneClause
        : customerStartDateTime || null,
    customerEndDateTime:
      customerEndDateTime && timezoneClause
        ? moment.utc(customerEndDateTime).format("LLL") + timezoneClause
        : customerEndDateTime || null,
    dropOffIsEstimate: !operatorCloseoutEndDateTime && !scheduledEndDateTime,
  };
};

const getDriverDateTimes = (operatorRoute: OperatorRoute) => {
  const { driverCloseoutEndDateTime, driverCloseoutStartDateTime } =
    operatorRoute;
  if (driverCloseoutStartDateTime && driverCloseoutEndDateTime) {
    return {
      driverCloseoutStartDateTime: driverCloseoutStartDateTime
        ? driverCloseoutStartDateTime
        : null,
      driverCloseoutEndDateTime: driverCloseoutEndDateTime
        ? driverCloseoutEndDateTime
        : null,
    };
  }
};

export const calculateAutomatedGratuityAmount = (
  driverGratuityAmt: number,
  automatedDriverPayoutGratuity: number
) => {
  if (!driverGratuityAmt || !automatedDriverPayoutGratuity) return 0;
  return Number(
    ((automatedDriverPayoutGratuity * driverGratuityAmt) / 100).toFixed(2)
  );
};
