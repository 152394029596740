import React, { useState } from "react";

import { PDFIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";
import { RequestStage } from "types";

type ViewPDFProps = {
  requestStage: string;
};

function useViewPDFsClick(props: ViewPDFProps): EllipsisMenuOption & {
  open: boolean;
  onClose: () => void;
} {
  const { requestStage } = props;
  // state
  const [viewPDFsDialogOpen, setViewPDFsDialogOpen] = useState(false);

  // event handlers
  const handleViewPDFsClick = () => {
    setViewPDFsDialogOpen(true);
  };

  const handleViewPDFsDialogClose = () => {
    setViewPDFsDialogOpen(false);
  };

  const isUnconfirmedReservation = requestStage === RequestStage.Unconfirmed;

  return {
    onClick: handleViewPDFsClick,
    text: "View PDFs",
    disableOption: isUnconfirmedReservation,
    icon: <PDFIcon color={grayDark} size="small" />,
    open: viewPDFsDialogOpen,
    onClose: handleViewPDFsDialogClose,
  };
}

export { useViewPDFsClick };
