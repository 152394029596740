import React, { Dispatch, SetStateAction } from "react";

import { Box, CircularProgress } from "@mui/material";
import DispatchAlertsBlock from "components/dispatch/DispatchAlertsBlock";
import { useQuery } from "@apollo/client";
import { LOAD_OPERATOR_ROUTE_QUERY } from "globals/graphql";
import GQLQueryStatusIndicator from "components/GQLQueryStatusIndicator";
import { OperatorRoute } from "types";
import { fromGlobalId, toGlobalId } from "globals/utils/helpers";

type DispatchAlertsBlockProps = {
  routeId: string;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
  setIsIndicatorVisible: Dispatch<SetStateAction<boolean>>;
};

const DispatchAlertBlock = (props: DispatchAlertsBlockProps) => {
  const { routeId, setSaveIndicatorState, setIsIndicatorVisible } = props;

  const shouldSkipQuery = !routeId;
  const fromRouteGID = fromGlobalId(routeId).id;

  // queries
  const {
    data: operatorRouteData,
    loading: operatorRouteLoading,
    refetch: operatorRouteRefetch,
    error: operatorRouteError,
  } = useQuery(LOAD_OPERATOR_ROUTE_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: toGlobalId(fromRouteGID, "OperatorRoute"),
    },
    skip: shouldSkipQuery,
    ...(!shouldSkipQuery && { pollInterval: 10 * 1000 }), // every 10 seconds
  });

  // state
  const operatorRoute: OperatorRoute = operatorRouteData?.node;

  return (
    <Box>
      <Box display="flex" flexWrap="wrap">
        {operatorRouteLoading && !operatorRouteData?.node && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={40} thickness={2} />
          </Box>
        )}
        {operatorRouteError && !operatorRouteLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <GQLQueryStatusIndicator
              name="Route"
              data={operatorRouteData}
              error={operatorRouteError}
              refetch={operatorRouteRefetch}
            />
          </Box>
        )}
        {operatorRoute && (
          <DispatchAlertsBlock
            operatorRoute={operatorRoute}
            setSaveIndicatorState={setSaveIndicatorState}
            setIsIndicatorVisible={setIsIndicatorVisible}
            isTripSummaryVariant
          />
        )}
      </Box>
    </Box>
  );
};

export default DispatchAlertBlock;
