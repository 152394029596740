import React from "react";
import moment from "moment";

import { Box, IconButton, Typography } from "@mui/material";

import { grayDark } from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import MoovsTooltip from "components/MoovsTooltip";

type DateTimeWithIconProps = {
  IconComponent: JSX.Element;
  dateTime: string;
  iconName: string;
};

function DateTimeWithIcon(props: DateTimeWithIconProps) {
  const { IconComponent, dateTime, iconName } = props;
  const { isMobileView } = useScreenSize();
  return (
    <Box display="flex" alignItems="center">
      {isMobileView && (
        <>
          {IconComponent}
          <Box ml={isMobileView ? 1 : 0.3}>
            <Typography variant="h6">{iconName}</Typography>
          </Box>
        </>
      )}
      {!isMobileView && (
        <MoovsTooltip tooltipText={iconName}>
          <IconButton size="small">{IconComponent}</IconButton>
        </MoovsTooltip>
      )}
      <Box ml={isMobileView ? 1 : 0.3}>
        <Typography variant="caption" style={{ color: grayDark }}>
          {moment.utc(dateTime).format("M/D/YY h:mm a")}
        </Typography>
      </Box>
    </Box>
  );
}

export default DateTimeWithIcon;
