/**
 * @file graphql/farmAffiliates]/farmAffiliate.ts
 * queries/mutations for Request.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_FARM_AFFILIATES_QUERY = gql`
  query loadFarmAffiliates(
    $viewFilter: [FarmAffiliateVariantEnum]!
    $searchTerm: String
    $cursor: String
    $limit: Int
  ) {
    loadFarmAffiliates(
      viewFilter: $viewFilter
      searchTerm: $searchTerm
      after: $cursor
      first: $limit
    ) {
      totalCount
      edges {
        node {
          ... on ExternalOperator {
            id
            createdAt
            updatedAt
            operatorName
            operatorAddress
            serviceArea
            logoUrl
            vehicleTypes {
              typeName
              typeSlug
              iconCategory
            }
          }
          ... on MoovsNetworkOperator {
            id
            createdAt
            updatedAt
            operatorName
            operatorAddress
            serviceArea
            logoUrl
            isMoovsLiteOperator
            vehicleTypes {
              typeName
              typeSlug
              iconCategory
            }
          }
          ... on GriddnetOperator {
            id
            createdAt
            updatedAt
            operatorName
            operatorAddress
            operatorPhone
            serviceArea
          }
          ... on MoovsAiOperator {
            id
            createdAt
            updatedAt
            operatorName
            operatorAddress
            operatorPhone
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const LOAD_FARM_AFFILIATES_NAME_AND_ID_QUERY = gql`
  query loadFarmAffiliates($viewFilter: [FarmAffiliateVariantEnum]!) {
    loadFarmAffiliates(viewFilter: $viewFilter) {
      edges {
        node {
          ... on ExternalOperator {
            id
            operatorName
          }
          ... on MoovsNetworkOperator {
            id
            operatorName
          }
          ... on GriddnetOperator {
            id
            operatorName
          }
          ... on MoovsAiOperator {
            id
            operatorName
          }
        }
      }
    }
  }
`;
