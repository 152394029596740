import isNil from "lodash/isNil";

import { PromoCode } from "types";

export type EditPromoCodeValues = {
  isPercent: boolean;
  amount: number;
  bookingStartDate: Date;
  bookingEndDate: Date;
  tripStartDate: Date;
  tripEndDate: Date;
  isTotalLimited: boolean;
  isBookingContactLimited: boolean;
  totalLimit: number;
  bookingContactLimit: number;
  active: boolean;
};

export function useEditPromoCodeDefaultValues(
  promoCode: PromoCode
): EditPromoCodeValues {
  const {
    promoCodePercent,
    promoCodeAmt,
    bookingStartDate,
    bookingEndDate,
    tripStartDate,
    tripEndDate,
    totalLimit,
    bookingContactLimit,
    active,
  } = promoCode;

  return {
    isPercent: !isNil(promoCodePercent) ? true : false,
    amount: !isNil(promoCodePercent) ? promoCodePercent * 100 : promoCodeAmt,
    bookingStartDate: bookingStartDate,
    bookingEndDate: bookingEndDate,
    tripStartDate: tripStartDate,
    tripEndDate: tripEndDate,
    isTotalLimited: !!totalLimit,
    isBookingContactLimited: !!bookingContactLimit,
    totalLimit: totalLimit,
    bookingContactLimit: bookingContactLimit,
    active,
  };
}
