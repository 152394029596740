import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";

import { blueLabelText } from "design-system/colors";
import { EditIcon } from "design-system/icons";
import { orderTypeEnumToNameMap } from "globals/utils/enumMaps";
import EditOrderTypeDialog from "pages/requests/RequestOverview/EditOrderTypeDialog";
import { OrderTypeEnum, RequestStage } from "types";

// constants
const ORDER_TYPE_LABEL_MAX_WIDTH = "130px";

type OrderTypeProps = {
  requestId: string;
  orderType: OrderTypeEnum;
  stage: RequestStage;
};

function OrderType(props: OrderTypeProps) {
  const { requestId, orderType, stage } = props;

  // derived state
  const orderTypeName = orderTypeEnumToNameMap[orderType]?.replace("/", "/ "); // prevents overflow on compound order types

  // state
  const [editOrderTypeDialogOpen, setEditOrderTypeDialogOpen] = useState(false);

  // event handlers
  const handleEditOrderTypeOpen = () => setEditOrderTypeDialogOpen(true);
  const handleEditOrderTypeClose = () => setEditOrderTypeDialogOpen(false);

  return (
    <>
      <Box mr={3}>
        <Typography variant="overline">Order Type</Typography>
        <Typography variant="subtitle2" display="flex">
          <Box
            sx={{
              my: "auto",
              wordWrap: "break-word",
              maxWidth: ORDER_TYPE_LABEL_MAX_WIDTH,
            }}
          >
            {orderTypeName}
          </Box>
          <IconButton
            onClick={handleEditOrderTypeOpen}
            sx={{ "&.MuiButtonBase-root": { padding: 0 }, ml: 1 }}
          >
            <EditIcon color={blueLabelText} />
          </IconButton>
        </Typography>
      </Box>

      <EditOrderTypeDialog
        open={editOrderTypeDialogOpen}
        onClose={handleEditOrderTypeClose}
        requestId={requestId}
        orderType={orderType}
        requestStage={stage}
      />
    </>
  );
}

export default OrderType;
