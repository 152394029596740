import { WorkflowSelectionsEnum } from "../steps/workflow/schemaValidation";

export enum SubHeaderStepsEnum {
  codeAndAmount = "Code & Amount",
  dateRange = "Date Range",
  limits = "Limits",
}

// this function will return a list of steps for the subheader stepper
export const getSubHeaderSteps = (workflow: WorkflowSelectionsEnum) => {
  const { anytime, bookingDate, tripDate } = WorkflowSelectionsEnum;
  const { codeAndAmount, dateRange, limits } = SubHeaderStepsEnum;

  if (workflow === anytime) {
    return [codeAndAmount, limits];
  }
  if (workflow === bookingDate || workflow === tripDate) {
    return [codeAndAmount, dateRange, limits];
  }

  return [];
};
