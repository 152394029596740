/*
util fn for showing vehicle avaliability and/or BRA
if there is a first and last stop location/airport and
first stop date time
*/

import first from "lodash/first";
import last from "lodash/last";

import { Stop } from "../../types";

export const shouldShowVehicleAvaliabilityOrBRA = (stops: Stop[]) => {
  let stopLocationAndDateTimeAdded = false;

  if (stops?.length) {
    const firstStop = first(stops);
    const lastStop = last(stops);

    stopLocationAndDateTimeAdded =
      (firstStop.location || firstStop.airport) &&
      (lastStop.location || lastStop.airport) &&
      !!firstStop.dateTime;
  }

  return stopLocationAndDateTimeAdded;
};
