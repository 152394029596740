import React from "react";

import { Box, Typography, CardActionArea, Card } from "@mui/material";

import { Company } from "../../../types";
import MoovsAvatar from "../../../design-system/components/MoovsAvatar";
import { black } from "../../../design-system/colors";
import { formatPhoneNumber } from "globals/utils/phoneNumberFormatter/phoneNumberFormatter";

type CompaniesListItemMobileProps = {
  company: Company;
  onClickCompany: (companyId) => void;
};

function CompaniesListItemMobile(props: CompaniesListItemMobileProps) {
  const { company, onClickCompany } = props;

  return (
    <Box mb={1}>
      <Card variant="outlined">
        <CardActionArea
          disableRipple
          onClick={() => {
            onClickCompany(company.id);
          }}
        >
          <Box p={2} display="flex" flexDirection="row" flex="1">
            <Box mr={2}>
              <MoovsAvatar
                alt="company, person"
                size="small"
                shape="rectangle"
                src={company.companyLogoUrl}
                placeholder={company.name}
              />
            </Box>
            <Box display="flex" flexDirection="column" flex="1">
              <Box
                display="flex"
                flexDirection="row"
                flex="1"
                alignItems="baseline"
                mb={0.5}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 700, color: black, fontSize: 16 }}
                >
                  {company.name}
                </Typography>
              </Box>

              <Typography variant="caption" sx={{ color: black }}>
                {company.email}
              </Typography>

              <Box my={0.5}>
                <Typography variant="caption" sx={{ color: black }}>
                  {company.phoneNumber
                    ? formatPhoneNumber(company.phoneNumber).formatted
                    : ""}
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardActionArea>
      </Card>
    </Box>
  );
}

export default CompaniesListItemMobile;
