import React from "react";
import { useMutation } from "@apollo/client";

import { Box } from "@mui/material";

import { Contact } from "types";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import ContactInfoCard from "components/globals/ContactInfoCard";
import { getContactName } from "globals/utils/contact";
import { UNLINK_PASSENGER_FROM_CONTACT_MUTATION } from "globals/graphql";

type LinkedPassengersListProps = {
  contact: Contact;
  refreshContact: () => void;
};

export default function LinkedPassengersList({
  contact,
  refreshContact,
}: LinkedPassengersListProps) {
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // mutations
  const [removeLinkedPassenger, { loading }] = useMutation(
    UNLINK_PASSENGER_FROM_CONTACT_MUTATION,
    {
      refetchQueries: ["loadContacts"],
      awaitRefetchQueries: true,
      onCompleted(data) {
        const unlinkedPassenger = data?.removeLinkedPassenger?.passenger;
        refreshContact();
        snackbar.success(
          `Successfully unlinked passenger ${getContactName(
            unlinkedPassenger
          )}!`
        );
        track("linkPassenger_wasRemoved");
      },
      onError(error) {
        const errorMessage =
          getErrorMessage(error) || "Error unlinking passenger";

        snackbar.error(errorMessage);
      },
    }
  );

  const unLinkPassenger = (
    e: React.MouseEvent<HTMLButtonElement>,
    passengerId: string
  ) => {
    e.preventDefault();
    removeLinkedPassenger({
      variables: {
        input: {
          contactId: contact.id,
          passengerId,
        },
      },
    });
  };

  if (!contact?.linkedPassengers?.length) {
    return null;
  }

  const linkedPassengers = [...contact.linkedPassengers].sort((a, b) =>
    getContactName(a) > getContactName(b) ? 1 : -1
  );

  return (
    <Box my={3} display="flex" rowGap={1} flexDirection="column">
      {linkedPassengers.map((contact: Contact) => (
        <ContactInfoCard
          key={contact.id}
          contact={contact}
          isDeleteButtonDisabled={loading}
          onDelete={(e) => unLinkPassenger(e, contact.id)}
          deleteButtonTitle="Unlink Passenger"
        />
      ))}
    </Box>
  );
}
