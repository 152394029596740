import React from "react";

type GpsLocationIconProps = { color?: string; size?: "small" };

const GpsLocationIcon = (props: GpsLocationIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.5 12C19.5 16.1421 16.1421 19.5 12 19.5M19.5 12C19.5 7.85786 16.1421 4.5 12 4.5M19.5 12H23.25M12 19.5C7.85786 19.5 4.5 16.1421 4.5 12M12 19.5V23.25M4.5 12C4.5 7.85786 7.85786 4.5 12 4.5M4.5 12H0.75M12 4.5V0.75M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 12C19.5 16.1421 16.1421 19.5 12 19.5M19.5 12C19.5 7.85786 16.1421 4.5 12 4.5M19.5 12H23.25M12 19.5C7.85786 19.5 4.5 16.1421 4.5 12M12 19.5V23.25M4.5 12C4.5 7.85786 7.85786 4.5 12 4.5M4.5 12H0.75M12 4.5V0.75M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GpsLocationIcon;
