import React, { useState, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import filter from "lodash/filter";

import { Box, CircularProgress, Typography } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";
import { useAnalytics, useOperator, useSnackbar } from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import {
  LOAD_LAGO_PAYMENT_METHOD_QUERY,
  UPGRADE_SUBSCRIPTION_PLAN_MUTATION,
} from "globals/graphql";
import { getLagoPlanCode } from "../../utils/getLagoPlanCode";
import { currency } from "globals/utils/helpers";
import { LagoPaymentMethods, PlanInfoSection } from "./components";
import { InfoIcon } from "design-system/icons";
import { LagoPlanCode } from "types";
import { BaseUpgradeSubscriptionDialogProps } from "./UpgradeSubscriptionDialog";

function UpgradeExistingSubscriptionDialog(
  props: BaseUpgradeSubscriptionDialogProps
) {
  const { open, onClose, planName, planLength, pricePerMonth, icon, bgColor } =
    props;

  // state
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [hideLoadingIndicator, setHideLoadingIndicator] = useState(true);
  const [lagoPaymentMethods, setLagoPaymentMethods] = useState([]);

  // hooks
  const snackbar = useSnackbar();
  const operator = useOperator();
  const { track } = useAnalytics();
  const { lagoPlanCode } = operator || {};

  // derived state
  const upgradePlanCode = getLagoPlanCode(planLength, planName);
  // prorated pricing if upgrading stardard-monthly -> pro plans
  const isProrated =
    lagoPlanCode === LagoPlanCode.StandardMonthly &&
    (upgradePlanCode === LagoPlanCode.ProMonthly ||
      upgradePlanCode === LagoPlanCode.ProAnnual);

  // memo
  const subscriptionFee = useMemo(() => {
    return currency(
      planLength === "monthly" ? pricePerMonth : pricePerMonth * 12
    ).slice(0, -3);
  }, [planLength, pricePerMonth]);

  // queries
  const { loading: paymentMethodLoading } = useQuery(
    LOAD_LAGO_PAYMENT_METHOD_QUERY,
    {
      variables: {
        id: operator?.id,
      },
      fetchPolicy: "cache-and-network",
      skip: !open || !operator?.id,
      onCompleted: (data) => {
        const primaryPaymentMethod = filter(
          data.node.lagoPaymentMethod,
          "isPrimary"
        );
        setLagoPaymentMethods(primaryPaymentMethod);

        if (primaryPaymentMethod.length) {
          setSubmitDisabled(false);
        }
      },
      onError: () => {
        snackbar.error();
      },
    }
  );

  // mutations
  const [upgradeSubscriptionPlan] = useMutation(
    UPGRADE_SUBSCRIPTION_PLAN_MUTATION,
    {
      onError(error) {
        setSubmitDisabled(false);

        const errorMessage = getErrorMessage(error);

        snackbar.error(errorMessage);
      },
      onCompleted() {
        snackbar.success("Successfully upgraded subscription!");

        track("subscriptions_planUpgraded", {
          term: planLength,
          package: planName,
        });

        onClose();
      },
    }
  );

  const handleSubscriptionUpgrade = async () => {
    setSubmitDisabled(true);
    setHideLoadingIndicator(false);

    upgradeSubscriptionPlan({
      variables: {
        input: {
          lagoPlanCode: upgradePlanCode,
        },
      },
    });
  };

  return (
    <>
      <MoovsDialog
        open={open}
        onClose={onClose}
        onAccept={handleSubscriptionUpgrade}
        acceptDisabled={submitDisabled}
        hideLoadingIndicator={hideLoadingIndicator}
        dialogTitle={`Confirm ${planLength} ${planName} subscription`}
        acceptButtonText="Subscribe"
        closeButtonText="Cancel"
        size="sm"
      >
        <Box py={2}>
          <PlanInfoSection
            planName={planName}
            icon={icon}
            bgColor={bgColor}
            pricePerMonth={pricePerMonth}
          />

          {/* Payment Method Section */}
          {paymentMethodLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              p={2}
              mt={3}
              sx={{
                // styling to match height of card component
                border: "1px solid transparent",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <LagoPaymentMethods lagoPaymentMethods={lagoPaymentMethods} />
          )}

          {/* Charge Info */}
          <Box mt={3}>
            <Box display="flex" alignItems="center">
              <InfoIcon size="md" />
              {isProrated ? (
                <Typography ml={1} variant="body2">
                  You will be charged a prorated amount for this current billing
                  cycle and then{" "}
                  <strong>{`${subscriptionFee} every ${
                    planLength === "monthly" ? "month" : "year"
                  }`}</strong>{" "}
                  moving forward
                </Typography>
              ) : (
                <Typography ml={1} variant="body2">
                  Your new {planLength === "monthly" ? "monthly" : "annual"}{" "}
                  billing of <strong>{subscriptionFee}</strong> will start at
                  the end of your current billing period and continue{" "}
                  {planLength === "monthly" ? "montly" : "annually"}.
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </MoovsDialog>
    </>
  );
}

export default UpgradeExistingSubscriptionDialog;
