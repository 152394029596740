import React from "react";
import { useQuery } from "@apollo/client";

import { Box, CircularProgress, Typography } from "@mui/material";

import { LOAD_TRIP_REVIEWS_QUERY } from "globals/graphql/tripReview.graphql";
import { StarIcon } from "design-system/icons";
import { granite, warningYellow } from "design-system/colors";
import { NoReviews, CustomerReviewList } from "./components";

function CustomerReviews() {
  // query
  const {
    data: tripReviewsData,
    loading: tripReviewsLoading,
    fetchMore: tripReviewsFetchMore,
  } = useQuery(LOAD_TRIP_REVIEWS_QUERY, {
    variables: {
      limit: 20,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  // event handler
  const handleFetchMore = () => {
    tripReviewsFetchMore({
      variables: {
        cursor: tripReviewsData?.loadTripReviews?.pageInfo.endCursor,
      },
    });
  };

  return (
    <Box>
      {/* header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        mb={3}
      >
        <Typography variant="overline">Customer Reviews</Typography>
        <Box display="flex" alignItems="center" justifyContent="center">
          {tripReviewsData?.loadTripReviews?.totalReviewCount ? (
            <>
              <Box display="flex" alignItems="center" mr={2}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: warningYellow, marginRight: "5px" }}
                >
                  {`${tripReviewsData?.loadTripReviews.avgRating}`}
                </Typography>
                <StarIcon
                  size="small"
                  color={warningYellow}
                  fillColor={warningYellow}
                />
              </Box>
              <Typography
                variant="caption"
                sx={{ color: granite, fontSize: "12px" }}
              >{`From ${tripReviewsData?.loadTripReviews.totalReviewCount} ${
                tripReviewsData?.loadTripReviews.totalReviewCount === 1
                  ? "review"
                  : "reviews"
              }`}</Typography>
            </>
          ) : null}
        </Box>
      </Box>
      {/* customer review list */}
      {!tripReviewsData && tripReviewsLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : !tripReviewsData?.loadTripReviews?.totalReviewCount ? (
        <NoReviews />
      ) : (
        <CustomerReviewList
          tripReviewsData={tripReviewsData.loadTripReviews}
          tripReviewsLoading={tripReviewsLoading}
          tripReviewsFetchMore={handleFetchMore}
        />
      )}
    </Box>
  );
}

export default CustomerReviews;
