import React, { ChangeEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import {
  Box,
  Typography,
  TextField,
  Grid,
  InputAdornment,
} from "@mui/material";

import CompanySelect from "../companies/CompanySelect";
import UseCompanyAddressButton from "./UseCompanyAddressButton";
import CommentCreateBlock from "../../CommentCreateBlock";
import { Company, Contact, CreateContactInput } from "../../../types";
import {
  CREATE_CONTACT_MUTATION,
  LOAD_CONTACTS_QUERY,
} from "../../../globals/graphql";
import CreateDrawer from "../../globals/CreateDrawer";
import { isValidPhoneNumber } from "../../../globals/utils/helpers";
import LocationAutoComplete from "../../autocompletes/LocationAutoComplete";
import InternationalPhoneInput from "../../inputs/InternationalPhoneInput";
import {
  useSnackbar,
  useScreenSize,
  useAnalytics,
  useStripeBillingDetailsElement,
} from "../../../globals/hooks";
import StripeTextField from "../../common/CreditCardElements/StripeTextField";
import { errorRed } from "design-system/colors";
import { intialStripeElementErrors } from "globals/utils/stripeCreditCardIntialValues";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import AddLinkedPassengersToCreateContact from "./AddLinkedPassengersToCreateContact";
import { useLaunchDarklyFlags } from "globals/utils/useLaunchDarklyFlags";

const initialContact = {
  firstName: "",
  lastName: "",
  email: "",
  mobilePhone: "",
  companyPosition: "",
  comments: [{ bodyText: "" }],
  phoneCountryCode: "",
  phoneCountryDialCode: "",
  phoneCountryName: "",
  phoneCountryFormat: "",
};

const initialContactErrors = {
  firstName: "",
  lastName: "",
  email: "",
  mobilePhone: "",
  cardNote: "",
};

function CreateContactDrawer() {
  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const { track } = useAnalytics();
  const elements = useElements();
  const stripe = useStripe();
  const { enableLinkedPassenger } = useLaunchDarklyFlags();

  const { isSmallTabletView, isMobileView } = useScreenSize();
  const { BillingDetailsElement, getBillingDetails } =
    useStripeBillingDetailsElement();

  // state
  const [company, setCompany] = useState<Company | null>(null);
  const [contact, setContact] = useState<CreateContactInput>(initialContact);

  const [stripeElementsError, setStripeElementsError] = useState(
    intialStripeElementErrors
  );
  const [cardNote, setCardNote] = useState("");
  const [hasEnteredCardInfo, setHasEnteredCardInfo] = useState(false);

  const [contactErrors, setContactErrors] = useState(initialContactErrors);
  const [contactSaveError, setContactSaveError] = useState(false);
  const [preferencesError, setPreferencesError] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [cardHolderEmail, setCardHolderEmail] = useState(null);
  const [linkedPassengers, setLinkedPassengers] = useState<Contact[]>([]);

  const linkedPassengerIds = linkedPassengers.map((passenger) => passenger.id);

  // mutations
  const [createContactMutation] = useMutation(CREATE_CONTACT_MUTATION, {
    refetchQueries: [
      {
        query: LOAD_CONTACTS_QUERY,
        variables: {
          searchTerm: "",
          limit: 30,
        },
      },
    ],
    onCompleted(data) {
      const contactId = data.createContact.contact.id;
      snackbar.success(`Successfully created contact ${contact.firstName}!`, {
        link: `/contacts/update/${contactId}`,
        linkLabel: "View Contact",
        onLinkClick: () => {
          track("snackbar_contactViewed");
        },
      });

      // RENAME?: contact_created
      track("create_contact");

      handleCreateDrawerClose();
    },
    onError(error) {
      setSubmitDisabled(false);

      const errorMessage =
        getErrorMessage(error) || error.message || "Error creating contact";

      snackbar.error(errorMessage);
    },
  });

  // event handlers
  const handleCreateDrawerClose = () => {
    elements.getElement(CardElement)?.clear();
    elements.getElement("address")?.clear();

    setTimeout(() => history.push("/contacts"), 200);
  };

  const handleCompanyChange = (company: Company | null) => {
    setCompany(company);
  };

  const handleChangeContactComment = (value) => {
    setContact({
      ...contact,
      comments: [{ bodyText: value }],
    });
  };

  const handleInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.name === "preferences") {
      event.target.value.toString().length >= 3000
        ? setPreferencesError("Reached character limit")
        : setPreferencesError(null);
    }

    setContact({
      ...contact,
      [event.target.name]: event.target.value,
    });
    setContactErrors({ ...contactErrors, [event.target.name]: "" });
  };

  const handlePhoneNumberInput = (value, country) => {
    setContact({
      ...contact,
      mobilePhone: value,
      phoneCountryCode: country.countryCode,
      phoneCountryDialCode: country.dialCode,
      phoneCountryName: country.name,
      phoneCountryFormat: country.format,
    });
    setContactErrors({ ...contactErrors, mobilePhone: "" });
  };

  const handleAddressInput = (value, name) => {
    setContact({
      ...contact,
      [name]: value.description,
    });
  };

  const handleCreditCardInputChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.name === "cardNote") {
      setCardNote(event.target.value);
    }

    if (event.target.name === "cardHolderEmail") {
      setCardHolderEmail(event.target.value);
    }
  };

  const handleClickSave = async () => {
    let contactErrors = [];
    let paymentMethodErrors;
    let stripePaymentMethod;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet.
      return;
    }
    setSubmitDisabled(true);

    const card = elements.getElement(CardElement);

    // create stripe payment method
    if (card !== null && hasEnteredCardInfo) {
      const billingDetails = await getBillingDetails();

      if (!billingDetails) {
        setSubmitDisabled(false);
        return;
      }

      // create stripe payment method
      const { paymentMethod, error: stripePaymentMethodError } =
        await stripe.createPaymentMethod({
          type: "card",
          card: card,
          billing_details: {
            ...billingDetails,
            email: cardHolderEmail || null,
          },
        });

      if (!stripePaymentMethodError) {
        stripePaymentMethod = paymentMethod;
      } else {
        paymentMethodErrors = stripePaymentMethodError.message;
      }
    }

    // contact errors
    if (!contact.firstName) {
      contactErrors = [
        ...contactErrors,
        { firstName: "Please enter First Name" },
      ];
    }

    if (!contact.lastName) {
      contactErrors = [
        ...contactErrors,
        { lastName: "Please enter Last Name" },
      ];
    }

    if (!contact.email) {
      contactErrors = [...contactErrors, { email: "Please enter Email" }];
    }

    if (contact.phoneCountryCode === "us") {
      if (!contact.mobilePhone || !isValidPhoneNumber(contact.mobilePhone)) {
        contactErrors = [
          ...contactErrors,
          { mobilePhone: "Please enter 10 digit phone number" },
        ];
      }
    } else {
      if (!contact.mobilePhone) {
        contactErrors = [
          ...contactErrors,
          { mobilePhone: "Please enter a phone number" },
        ];
      }
    }

    if (contact.companyPosition.length > 100) {
      contactErrors = [
        ...contactErrors,
        {
          companyPosition:
            "Company position exceeds max length of 100 characters",
        },
      ];
    }

    if (cardNote.length > 500) {
      contactErrors = [
        ...contactErrors,
        { cardNote: "Card note exceeds max length of 500 characters" },
      ];
    }

    if (contactErrors.length > 0 || paymentMethodErrors) {
      setContactErrors(
        contactErrors.reduce((acc, value) => ({ ...acc, ...value }), {})
      );

      setStripeElementsError({
        ...stripeElementsError,
        createStripePaymentMethodError: paymentMethodErrors,
      });

      setContactSaveError(true);
      setSubmitDisabled(false);
      return;
    }

    setContactSaveError(false);

    createContactMutation({
      variables: {
        input: {
          ...contact,
          stripePaymentMethod,
          cardNote,
          ...(company !== null && {
            companyId: company.id,
          }),
          linkedPassengerIds,
        },
      },
    });
  };

  const handleUseCompanyAddressClick = () => {
    handleAddressInput({ description: company.address }, "workAddress");
  };

  const showUseCompanyAddressButton =
    company?.address && company?.address !== contact.workAddress;

  return (
    <CreateDrawer
      onClose={handleCreateDrawerClose}
      pageLabel="Add New Contact"
      submitLabel="Save"
      onSubmit={handleClickSave}
      saveError={contactSaveError}
      submitDisabled={submitDisabled}
    >
      <Box mt={3}>
        <Typography variant="h5">Basic Info</Typography>
        <Box mb={1} mt={2}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                required
                fullWidth
                variant="outlined"
                label="First Name"
                name="firstName"
                value={contact.firstName || ""}
                onChange={handleInput}
                error={!!contactErrors.firstName}
                helperText={contactErrors.firstName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                variant="outlined"
                name="lastName"
                label="Last Name"
                value={contact.lastName || ""}
                onChange={handleInput}
                error={!!contactErrors.lastName}
                helperText={contactErrors.lastName}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={1}>
          <TextField
            required
            fullWidth
            multiline
            variant="outlined"
            name="email"
            label="Email"
            value={contact.email || ""}
            onChange={handleInput}
            error={!!contactErrors.email}
            helperText={contactErrors.email}
          />
        </Box>
        <Box mb={1}>
          <InternationalPhoneInput
            value={contact.mobilePhone}
            error={contactErrors.mobilePhone}
            onChange={handlePhoneNumberInput}
            dropdownWidth={isMobileView ? "600%" : "1220%"}
          />
        </Box>
      </Box>
      <Box mt={3} mb={2}>
        <Typography variant="h5">Optional Info</Typography>
      </Box>
      <Box mb={1}>
        <CompanySelect
          outlined
          company={company}
          handleCompanyChange={handleCompanyChange}
        />
      </Box>
      <Box mb={1}>
        <LocationAutoComplete
          label="Home Address"
          fullWidth
          value={contact.homeAddress || ""}
          onChange={(value) => handleAddressInput(value, "homeAddress")}
        />
      </Box>
      <Box mb={1}>
        <LocationAutoComplete
          label="Work Address"
          fullWidth
          value={contact.workAddress || ""}
          onChange={(value) => handleAddressInput(value, "workAddress")}
          sxStyles={{ paddingRight: "20px !important" }}
          TextFieldInputProps={{
            ...(!isSmallTabletView &&
              showUseCompanyAddressButton && {
                endAdornment: (
                  <InputAdornment position="end">
                    <UseCompanyAddressButton
                      onClick={handleUseCompanyAddressClick}
                    />
                  </InputAdornment>
                ),
              }),
          }}
        />
        {isSmallTabletView && showUseCompanyAddressButton && (
          <UseCompanyAddressButton
            onClick={handleUseCompanyAddressClick}
            layoutProps={{ pl: "15px", py: 1 }}
          />
        )}
      </Box>
      <Box mb={1}>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          name="companyPosition"
          label="Company Position"
          value={contact.companyPosition || ""}
          onChange={handleInput}
          // @ts-ignore  fix later, companyPosition is not in contactErrors type
          error={!!contactErrors.companyPosition}
          // @ts-ignore
          helperText={contactErrors.companyPosition}
        />
      </Box>
      <Box mb={1}>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          name="preferences"
          label="Contact Preferences"
          value={contact.preferences || ""}
          onChange={handleInput}
          inputProps={{ style: { minHeight: 114 }, maxLength: 3000 }}
          error={!!preferencesError}
          helperText={preferencesError}
        />
      </Box>
      <Box mb={2}>
        <Box mt={3} mb={2} sx={{ display: "flex" }}>
          <Typography variant="h5">Billing</Typography>
          {stripeElementsError.createStripePaymentMethodError && (
            <Typography variant="h5" color={errorRed}>
              {`: ${stripeElementsError.createStripePaymentMethodError}`}
            </Typography>
          )}
        </Box>

        {/* Stripe Elements credit card */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="overline">Card Number</Typography>
            <Box mt={0.5}>
              <StripeTextField
                stripeElement={CardElement}
                onChange={(e) => setHasEnteredCardInfo(!e.empty)}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <BillingDetailsElement onChange={() => setSubmitDisabled(false)} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline">
              Cardholder Email (optional)
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              name="cardHolderEmail"
              onChange={handleCreditCardInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline">
              Additional Notes (Optional)
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              name="cardNote"
              onChange={handleCreditCardInputChange}
              error={!!contactErrors.cardNote}
              helperText={contactErrors.cardNote}
              inputProps={{
                "data-testid": "card-note-input",
              }}
            />
          </Grid>
        </Grid>

        <Box mb={3}>
          {enableLinkedPassenger && (
            <AddLinkedPassengersToCreateContact
              linkedPassengers={linkedPassengers}
              setLinkedPassengers={setLinkedPassengers}
            />
          )}
        </Box>

        <Box mt={7} mb={4}>
          <CommentCreateBlock
            commentBodyText={contact.comments[0].bodyText}
            onChangeComment={handleChangeContactComment}
          />
        </Box>
      </Box>
    </CreateDrawer>
  );
}

export default CreateContactDrawer;
