import React from "react";

type ExploreMyOwnIconProps = {
  color?: string;
};

const ExploreMyOwnIcon = (props: ExploreMyOwnIconProps) => {
  const { color } = props;

  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0606 9.19481C24.0606 10.8684 22.7039 12.2251 21.0303 12.2251C19.3567 12.2251 18 10.8684 18 9.19481M24.0606 9.19481C24.0606 7.52122 22.7039 6.1645 21.0303 6.1645C19.3567 6.1645 18 7.52122 18 9.19481M24.0606 9.19481L30.1212 9.19481M18 9.19481H5.87879M14.9697 18.2857C14.9697 19.9593 13.613 21.316 11.9394 21.316C10.2658 21.316 8.90909 19.9593 8.90909 18.2857M14.9697 18.2857C14.9697 16.6121 13.613 15.2554 11.9394 15.2554C10.2658 15.2554 8.90909 16.6121 8.90909 18.2857M14.9697 18.2857H30.1212M8.90909 18.2857H5.87879M19.5152 27.3766C19.5152 29.0502 18.1584 30.4069 16.4848 30.4069C14.8113 30.4069 13.4545 29.0502 13.4545 27.3766M19.5152 27.3766C19.5152 25.703 18.1584 24.3463 16.4848 24.3463C14.8113 24.3463 13.4545 25.703 13.4545 27.3766M19.5152 27.3766H30.1212M13.4545 27.3766H5.87879M2.78261 1.61905H33.2174C34.0178 1.61905 34.6667 2.26791 34.6667 3.06832V33.5031C34.6667 34.3035 34.0178 34.9524 33.2174 34.9524H2.78261C1.98219 34.9524 1.33333 34.3035 1.33333 33.5031V3.06832C1.33333 2.26791 1.98219 1.61905 2.78261 1.61905Z"
        stroke={color || "#1E1E1E"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExploreMyOwnIcon;
