import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import first from "lodash/first";
import last from "lodash/last";
import moment from "moment";
import * as Sentry from "@sentry/react";

import { Box, Divider, Typography } from "@mui/material";

import { applyUTCOffsetToTime } from "../../../globals/utils/helpers";
import { Stop, TripCategory } from "../../../types";
import { LabeledInlineDateTimePicker } from "design-system/components/inputs";
import { useSnackbar } from "globals/hooks";
import { UPDATE_STOP_MUTATION } from "globals/graphql";

type ShuttleTripInfoUpdateBlockProps = {
  stops: Stop[];
  tripCategory: TripCategory;
  setSaveIndicatorState: Function;
};

function ShuttleTripDateTimeInfoBlock(props: ShuttleTripInfoUpdateBlockProps) {
  const { stops, tripCategory, setSaveIndicatorState } = props;

  const snackbar = useSnackbar();

  // derived state
  const isShuttlePickUp = tripCategory === TripCategory.ShuttlePickUp;
  const pickUp = first(stops);
  const dropOff = last(stops);

  // time manually adjusted to display properly on DateTimePicker
  const adjustedPickUpStopDateTime = pickUp?.dateTime
    ? applyUTCOffsetToTime(pickUp.dateTime, "subtract").toISOString()
    : null;

  const adjustedDropOffStopDateTime = dropOff?.dateTime
    ? applyUTCOffsetToTime(dropOff.dateTime, "subtract").toISOString()
    : null;

  // state
  const [pickUpErrorText, setPickUpErrorText] = useState(null);
  const [dropOffErrorText, setDropOffErrorText] = useState(null);

  // mutations
  const [updateStop] = useMutation(UPDATE_STOP_MUTATION, {
    refetchQueries: ["Trip", "OperatorRoute", "Request"],
    onCompleted(data) {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      const errorMessage = error.message
        ? `Error: ${error.message.replace("GraphQL error:", "")}`
        : "Error updating stop";

      snackbar.error(errorMessage);
      Sentry.captureException(error);
      setSaveIndicatorState("error");
    },
  });

  // event handlers
  const handlePickUpStopDateTimeChange = (date: Date) => {
    setPickUpErrorText(null);
    setSaveIndicatorState("loading");

    // throw error if pick-up is set after dropoff
    if (
      adjustedDropOffStopDateTime &&
      moment(date) > moment(adjustedDropOffStopDateTime)
    ) {
      setPickUpErrorText(
        "Pick-up date & time must come before dropoff date & time."
      );
      setSaveIndicatorState("error");
      return;
    }

    // throw error if pick-up is set after next stop
    if (
      stops[1].dateTime &&
      moment(date).utc(true).toISOString() >
        moment.utc(stops[1].dateTime).toISOString()
    ) {
      setPickUpErrorText(
        "Pick-up date & time must come before subsequent stop date & time."
      );
      setSaveIndicatorState("error");
      return;
    }

    updateStop({
      variables: {
        input: {
          id: pickUp.id,
          dateTime: applyUTCOffsetToTime(moment(date), "add").toISOString(),
        },
      },
    });
  };

  const handleDropOffStopDateTimeChange = (date: unknown) => {
    setDropOffErrorText(null);
    setSaveIndicatorState("loading");

    // throw error if dropoff is set before pickup
    if (moment(date) < moment(adjustedPickUpStopDateTime)) {
      setDropOffErrorText(
        "Dropoff date & time must come after pick-up date & time."
      );
      setSaveIndicatorState("error");
      return;
    }

    // throw error if dropoff is set before previous stop
    if (
      stops[stops.length - 2].dateTime &&
      moment(date).utc(true).toISOString() <
        moment.utc(stops[stops.length - 2].dateTime).toISOString()
    ) {
      setDropOffErrorText(
        "Dropoff date & time must come after previous stop date & time."
      );
      setSaveIndicatorState("error");
      return;
    }

    updateStop({
      variables: {
        input: {
          id: dropOff.id,
          dateTime: applyUTCOffsetToTime(moment(date), "add").toISOString(),
        },
      },
    });
  };

  return (
    <Box mb={4} paddingTop={2}>
      <Box mb={1.5}>
        <Typography variant="h5">Date & Time</Typography>
      </Box>
      <Box mb={1}>
        <Divider />
      </Box>
      <LabeledInlineDateTimePicker
        label="Pick-up Date & Time"
        name="dateTime"
        value={adjustedPickUpStopDateTime}
        onChange={handlePickUpStopDateTimeChange}
        errorText={pickUpErrorText}
        error={!!pickUpErrorText}
      />
      {isShuttlePickUp && (
        <Box mt={1}>
          <LabeledInlineDateTimePicker
            label="Dropoff Date & Time"
            name="dateTime"
            value={adjustedDropOffStopDateTime}
            onChange={handleDropOffStopDateTimeChange}
            errorText={dropOffErrorText}
            error={!!dropOffErrorText}
          />
        </Box>
      )}
    </Box>
  );
}

export default ShuttleTripDateTimeInfoBlock;
