import { toGlobalId, fromGlobalId } from "../../globals/utils/helpers";

export type MediaFileType = File & {
  isInvalid?: boolean;
};

export enum MediaVariantEnum {
  Image = "Image",
  Video = "Video",
  PDF = "PDF",
  Invalid = "",
}

export const imageTypes = ["image/png", "image/jpg", "image/jpeg"];
export const videoTypes = ["video/mp4"];
export const pdfTypes = ["application/pdf"];
export const validMediaTypes = imageTypes.concat(videoTypes).concat(pdfTypes);

export const checkIsSupportedMediaType = (
  mediaType,
  variant:
    | MediaVariantEnum.Image
    | MediaVariantEnum.Video
    | MediaVariantEnum.PDF
    | "any"
) => {
  let mediaTypes;
  switch (variant) {
    case MediaVariantEnum.Image:
      mediaTypes = imageTypes;
      break;
    case MediaVariantEnum.Video:
      mediaTypes = videoTypes;
      break;
    case MediaVariantEnum.PDF:
      mediaTypes = pdfTypes;
      break;
    default:
      mediaTypes = validMediaTypes;
      break;
  }

  return mediaTypes.find((type) => mediaType === type);
};

export const getMediaVariant = (mediaType) => {
  if (imageTypes.find((type) => type === mediaType)) {
    return MediaVariantEnum.Image;
  }
  if (videoTypes.find((type) => type === mediaType)) {
    return MediaVariantEnum.Video;
  }
  if (pdfTypes.find((type) => type === mediaType)) {
    return MediaVariantEnum.PDF;
  }
  return MediaVariantEnum.Invalid;
};

export const getAffiliateId = (operatorId: string) => {
  return toGlobalId(fromGlobalId(operatorId || "").id, "Affiliate");
};
