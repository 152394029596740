import React from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import Close from "@mui/icons-material/Close";
import { Box, Button, CircularProgress, Typography } from "@mui/material";

import { granite, greenLight, tintPurple, white } from "design-system/colors";
import { ArrowRightIcon } from "design-system/icons";
import BrowserBookingToolIcon from "design-system/icons/info/BrowserBookingToolIcon";
import { useAnalytics, useScreenSize, useSnackbar } from "globals/hooks";
import { UPDATE_OPERATOR_GETTING_STARTED_MUTATION } from "globals/graphql";
import { GettingStartedGoal } from "types";
import MobilePhoneAppIcon from "design-system/icons/info/MobilePhoneAppIcon";
import { MarketApp } from "pages/moovs-market/components/MoovsMarketAppList/data/appData";

type GettingStartedBannerProps = {
  goal: GettingStartedGoal;
};

const GETTING_STARTER_BANNER_DETAILS = {
  [GettingStartedGoal.GrowBusinessOnline]: {
    header: "Add the customer portal to your website",
    subtext:
      "Adding the Customer Portal to your website offers your customers quick and easy access to seamlessly book trips.",
    navigateButtonText: "Add Customer Portal to website",
    icon: <BrowserBookingToolIcon />,
    bannerBgColor: tintPurple,
    path: "/settings/customer-portal",
    toExternalLink: false,
  },
  [GettingStartedGoal.AccessDriverApp]: {
    header: "Get the Moovs Driver App",
    subtext:
      "By using the Moovs Driver App, drivers provide you with real-time location updates and estimated arrival times, offering valuable insights into all your active trips to ensure timely pick-ups.",
    navigateButtonText: "Install the Driver App",
    icon: <MobilePhoneAppIcon />,
    bannerBgColor: greenLight,
    path: `/settings/moovs-market?app=${MarketApp.Driver}`,
    toExternalLink: false,
  },
};

function GettingStartedBanner(props: GettingStartedBannerProps) {
  const { goal } = props;

  const {
    header,
    subtext,
    navigateButtonText,
    icon,
    bannerBgColor,
    path,
    toExternalLink,
  } = GETTING_STARTER_BANNER_DETAILS[goal];

  const { AccessDriverApp, GrowBusinessOnline } = GettingStartedGoal;

  // hooks
  const { isMobileView } = useScreenSize();
  const snackbar = useSnackbar();
  const history = useHistory();
  const { track } = useAnalytics();

  // mutation
  const [updateOperatorGettingStarted, { loading }] = useMutation(
    UPDATE_OPERATOR_GETTING_STARTED_MUTATION,
    {
      onCompleted: (data) => {
        const { bannerRemovedAt, goal } =
          data.updateOperatorGettingStarted.operator.gettingStarted;

        if (bannerRemovedAt) {
          if (goal === AccessDriverApp) {
            track("gettingStarted_driverPlatformSkipped");
          } else if (goal === GrowBusinessOnline) {
            track("gettingStarted_customerPortalSkipped");
          }
        }
      },
      onError: () => {
        snackbar.error("Can not close banner.");
      },
    }
  );

  // event handlers
  const handleCloseBannerClick = () => {
    updateOperatorGettingStarted({
      variables: {
        input: {
          removeBanner: true,
        },
      },
    });
  };

  const handleNavigateClick = () => {
    trackNavigation();
    if (!toExternalLink) history.push(path);
  };

  const trackNavigation = () => {
    if (goal === AccessDriverApp) {
      track("gettingStarted_driverPlatformOpened");
    } else if (goal === GrowBusinessOnline) {
      track("gettingStarted_customerPortalOpened");
    }
  };

  // component
  const NavigationButton = (
    <Button
      color="primary"
      variant="contained"
      onClick={handleNavigateClick}
      {...(isMobileView && { style: { width: "100%" } })}
      {...(toExternalLink && {
        target: "_blank",
        href: path,
      })}
    >
      <Typography mr={2} fontWeight={500}>
        {navigateButtonText}
      </Typography>
      <ArrowRightIcon size="small" color={white} strokeWeight="2.5" />
    </Button>
  );

  return (
    <Box
      display="flex"
      flexDirection={isMobileView ? "column" : "row"}
      bgcolor={bannerBgColor}
      borderRadius={1}
      p={2}
    >
      <Box display="flex">
        {/* Icon */}
        <Box display="flex" alignItems="center" mr={2}>
          <Box bgcolor={white} borderRadius={25}>
            {icon}
          </Box>
        </Box>
        {/* Text and Navigation Button */}
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            <Typography variant="h4" mb={0.5}>
              {header}
            </Typography>
            <Typography variant="body2" mb={2} color={granite}>
              {subtext}
            </Typography>
            {!isMobileView && NavigationButton}
          </Box>

          <Box>
            {loading ? (
              <Box sx={{ padding: 0, minWidth: "20px", marginLeft: "60px" }}>
                <CircularProgress sx={{ color: granite }} size={20} />
              </Box>
            ) : (
              <Button
                sx={{ padding: 0, minWidth: "20px", marginLeft: "60px" }}
                onClick={handleCloseBannerClick}
              >
                <Close sx={{ width: "20px", color: granite }} />
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Box>{isMobileView && NavigationButton}</Box>
    </Box>
  );
}

export default GettingStartedBanner;
