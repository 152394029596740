import React from "react";
import { useMutation } from "@apollo/client";

import { Box, Typography } from "@mui/material";

import LabeledSwitchInput from "components/settings/general/LabeledSwitchInput";
import { UPDATE_OPERATOR_SETTINGS } from "globals/graphql";
import { useSnackbar, useOperator } from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { DriverGratuityPresetsBlock } from "./components";

type GratuitySelectionProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function GratuitySelection(props: GratuitySelectionProps) {
  const { setSaveIndicatorState } = props;

  // hooks
  const snackbar = useSnackbar();
  const operator = useOperator();

  // derived state
  const {
    gratuityWhenBooking,
    customGratuityWhenBooking,
    cashGratuityWhenBooking,
    driverGratuityPresets,
  } = operator;

  // mutations
  const [updateGratuitySelection] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      const errorMessage =
        getErrorMessage(error) ||
        "Error updating gratuity selection when booking.";

      snackbar.error(errorMessage);
    },
  });

  // event handlers
  const handleGratuityWhenBookingChange = () => {
    setSaveIndicatorState("loading");
    const enableGratuity = !gratuityWhenBooking;
    updateGratuitySelection({
      variables: {
        input: {
          gratuityWhenBooking: enableGratuity,
          // when users enable gratuity we want custom gratuity and cash gratuity to be enabled
          // similarly when grauity is disabled, custom gratuity and cash gratuity need to be disabled
          customGratuityWhenBooking: enableGratuity,
          cashGratuityWhenBooking: enableGratuity,
        },
      },
    });
  };

  const handleCustomGratuityWhenBookingChange = () => {
    setSaveIndicatorState("loading");
    updateGratuitySelection({
      variables: {
        input: {
          customGratuityWhenBooking: !customGratuityWhenBooking,
        },
      },
    });
  };

  const handleCashGratuityWhenBookingChange = () => {
    setSaveIndicatorState("loading");
    updateGratuitySelection({
      variables: {
        input: {
          cashGratuityWhenBooking: !cashGratuityWhenBooking,
        },
      },
    });
  };

  return (
    <Box mb={4}>
      <Typography variant="h3" mb={2}>
        Gratuity
      </Typography>
      <Box ml={-1}>
        <LabeledSwitchInput
          labelPlacement="start"
          name="gratuityWhenBookingSwitch"
          checked={gratuityWhenBooking}
          onChange={handleGratuityWhenBookingChange}
          text="Enable gratuity selection for customers"
          subText={
            gratuityWhenBooking
              ? "Gratuity selection is enabled"
              : "Gratuity selection is disabled"
          }
        />
        {gratuityWhenBooking && (
          <>
            <DriverGratuityPresetsBlock
              setSaveIndicatorState={setSaveIndicatorState}
              driverGratuityPresets={
                driverGratuityPresets as [number, number, number]
              }
            />

            <Box my={3}>
              <LabeledSwitchInput
                labelPlacement="start"
                name="cashGratuityWhenBookingSwitch"
                checked={cashGratuityWhenBooking}
                onChange={handleCashGratuityWhenBookingChange}
                text="Offer cash gratuity option"
                subText={
                  gratuityWhenBooking
                    ? "cash gratuity option on"
                    : "cash gratuity option off"
                }
              />
            </Box>

            <Box my={3}>
              <LabeledSwitchInput
                labelPlacement="start"
                name="customGratuityWhenBookingSwitch"
                checked={customGratuityWhenBooking}
                onChange={handleCustomGratuityWhenBookingChange}
                text="Offer custom gratuity option"
                subText={
                  gratuityWhenBooking
                    ? "Custom gratuity option on"
                    : "Custom gratuity option off"
                }
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

export default GratuitySelection;
