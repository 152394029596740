import React, { useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Control, FieldErrors, useForm } from "react-hook-form";

import { Box, Typography } from "@mui/material";

import { repeatRuleDefaultValues } from "./defaultValues";
import { repeatRuleSchema } from "./schemaValidation";
import RHFRadioButtons from "components/reactHookFormInputs/RHFRadioButtons";
import { GlobalDynamicPricing } from "../../DynamicPricingDialog";
import { getRepeatRuleText } from "../../utils/getRepeatRule";

type RepeatRuleValues = {
  repeatAnnually: boolean;
};

type RepeatRuleFormProps = {
  control: Control<RepeatRuleValues>;
  errors: FieldErrors;
};

function RepeatRuleForm(props: RepeatRuleFormProps) {
  const { control, errors } = props;

  const [validatedInput, currentWorkflow] = useContext(GlobalDynamicPricing);

  const repeatText = getRepeatRuleText({ currentWorkflow, validatedInput });

  return (
    <>
      <Box style={{ marginBottom: "50px" }}>
        <Typography variant="body1">Does this rule repeat?</Typography>
      </Box>
      <Box>
        <Box mb={3} width="100%">
          <RHFRadioButtons
            name="repeatAnnually"
            control={control}
            errorMessage={errors.repeatAnnually?.message}
            radioButtons={[
              { label: "Does not repeat", value: false },
              { label: repeatText, value: true },
            ]}
          />
        </Box>
        <Box m={1} />
      </Box>
    </>
  );
}

function useRepeatRuleForm() {
  // setup react hook forms
  const {
    control,
    formState: { errors },
    handleSubmit: handleRepeatRuleSubmit,
    reset: resetRepeatRuleForm,
  } = useForm({
    defaultValues: repeatRuleDefaultValues,
    resolver: yupResolver(repeatRuleSchema),
  });

  return {
    handleRepeatRuleSubmit,
    resetRepeatRuleForm,
    repeatRuleFormComponent: (
      <RepeatRuleForm control={control} errors={errors} />
    ),
  };
}
export default useRepeatRuleForm;
