import React from "react";
import startCase from "lodash/startCase";

import { Card, CardActionArea, Typography, Box } from "@mui/material";

import { black, granite } from "design-system/colors";
import MoovsAvatar from "design-system/components/MoovsAvatar";
import { User } from "types";

const styles = {
  userName: {
    fontWeight: 700,
    color: black,
    fontSize: 16,
  },
  userInfoCopy: {
    color: granite,
  },
};

type MembersListItemProps = {
  user: User;
  handleUserClick?: (userId: string) => void;
};

function MembersListItemDesktop(props: MembersListItemProps) {
  const { user, handleUserClick } = props;

  const userName = `${startCase(user.firstName)} ${startCase(user.lastName)}
  `;

  return (
    <Card variant="outlined">
      <CardActionArea
        disableRipple
        onClick={() => handleUserClick && handleUserClick(user.id)}
      >
        <Box
          display="flex"
          flexDirection="row"
          flex="1"
          py={1.5}
          px={3}
          height="76px"
          alignItems="center"
        >
          <Box display="flex" flex="2" flexDirection="row" alignItems="center">
            <Box mr={2}>
              <MoovsAvatar
                alt="contact, person"
                size="medium"
                placeholder={[user.firstName, user.lastName]}
              />
            </Box>
            <Box>
              <Typography sx={styles.userName}>{userName}</Typography>
              <Typography color="error" variant="caption">
                {user.invitePending ? "Email sent" : null}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flex="2">
            <Typography variant="body2" sx={styles.userInfoCopy}>
              {user.email}
            </Typography>
          </Box>
          <Box display="flex" flex="1.5">
            <Typography variant="body2" sx={styles.userInfoCopy}>
              {user.mobilePhone}
            </Typography>
          </Box>

          <Box display="flex" flex="1">
            <Typography variant="body2" sx={styles.userInfoCopy}>
              {user.role.name}
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default MembersListItemDesktop;
