import { LagoPlanCode } from "types";

const {
  StandardMonthly,
  StandardAnnual,
  ProMonthly,
  ProAnnual,
  VipMonthly,
  VipAnnual,
} = LagoPlanCode;

export const LAGO_ANNUAL_PLANS = [StandardAnnual, ProAnnual, VipAnnual];

export const LAGO_MONTHLY_PLANS = [StandardMonthly, ProMonthly, VipMonthly];

export const LAGO_VIP_PLANS = [VipMonthly, VipAnnual];
