import React from "react";
import startCase from "lodash/startCase";

import { Box, Typography, CardActionArea, Card } from "@mui/material";

import { User } from "types";
import MoovsAvatar from "design-system/components/MoovsAvatar";
import { black } from "design-system/colors";

const styles = {
  memberName: {
    fontWeight: 700,
    color: black,
    fontSize: 16,
  },
  memberInfo: {
    color: black,
  },
};

type MembersListItemMobileProps = {
  user: User;
};

function MembersListItemMobile(props: MembersListItemMobileProps) {
  const { user } = props;

  return (
    <Box mb={1}>
      <Card variant="outlined">
        <CardActionArea disableRipple>
          <Box p={2} display="flex" flexDirection="row" flex="1">
            <Box mr={2}>
              <MoovsAvatar
                alt="contact, person"
                size="small"
                placeholder={[user.firstName, user.lastName]}
              />
            </Box>
            <Box display="flex" flexDirection="column" flex="1">
              <Box
                display="flex"
                flexDirection="row"
                flex="1"
                alignItems="baseline"
                mb={0.5}
              >
                <Typography variant="subtitle1" sx={styles.memberName}>
                  {`${startCase(user.firstName)} ${startCase(user.lastName)}`}
                </Typography>
                <Box ml={1}>
                  <Typography color="error" variant="caption">
                    {user.invitePending ? "Email sent" : null}
                  </Typography>
                </Box>
              </Box>
              <Box mb={0.5}>
                <Typography variant="caption" sx={styles.memberInfo}>
                  {user.email}
                </Typography>
              </Box>
              <Box mb={0.5}>
                <Typography variant="caption" sx={styles.memberInfo}>
                  {user.mobilePhone}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption" sx={styles.memberInfo}>
                  {user.role.name}
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardActionArea>
      </Card>
    </Box>
  );
}

export default MembersListItemMobile;
