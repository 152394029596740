import React, { useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FieldErrors,
  useForm,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import { Box, Typography } from "@mui/material";

import { vehicleDefaultValues } from "./defaultValues";
import { vehicleSchema } from "./schemaValidation";
import RHFMultipleSelect from "components/reactHookFormInputs/RHFMultipleSelect";
import { Vehicle } from "types";
import { useVehicles } from "globals/hooks";

type VehicleValues = {
  vehicles: string[];
  vehiclesAvailable: number;
};

type VehicleFormProps = {
  errors: FieldErrors;
  onSetValue: UseFormSetValue<VehicleValues>;
  onClearErrors: UseFormClearErrors<VehicleValues>;
  watch: UseFormWatch<VehicleValues>;
  vehiclesData: Vehicle[];
};

function VehicleForm(props: VehicleFormProps) {
  const { errors, onSetValue, onClearErrors, watch, vehiclesData } = props;

  // options is a list of objects for RHFMultipleSelect
  const options = useMemo(() => {
    let vehiclesAvailable = 0;

    const options = vehiclesData.map((vehicle) => {
      if (vehicle.enableBaseRateAutomation) vehiclesAvailable++;
      return {
        id: vehicle.id,
        value: vehicle.name,
        disabled: !vehicle.enableBaseRateAutomation,
        isSelected: false,
      };
    });

    onSetValue("vehiclesAvailable", vehiclesAvailable);
    options.sort((a, b) => (a.disabled > b.disabled ? 1 : -1));

    return options;
  }, [vehiclesData, onSetValue]);

  const updateReactHookForm = (newValues) => {
    const rhfValues = newValues.map((val) => ({
      id: val.id,
      value: val.value,
      disabled: val.disabled,
      isSelected: val.isSelected,
    }));
    onSetValue("vehicles", rhfValues);
    onClearErrors("vehicles");
  };

  return (
    <>
      <Box style={{ marginBottom: "50px" }}>
        <Typography variant="body1">Choose Vehicles</Typography>
      </Box>
      <RHFMultipleSelect
        name="vehicles"
        errorMessage={errors.vehicles?.message}
        options={options}
        labelId="vehicle-select"
        label="Vehicle"
        selectAllValue="Select All Vehicles"
        disabledToolTipText="Vehicle must have BRA on to add dynamic pricing"
        onUpdateRHF={updateReactHookForm}
        watch={watch}
      />
    </>
  );
}

function useVehicleForm() {
  const [vehicles] = useVehicles();

  const {
    setValue,
    clearErrors,
    watch,
    formState: { errors: vehicleErrors },
    handleSubmit: handleVehicleSubmit,
    reset: resetVehicleForm,
  } = useForm({
    defaultValues: vehicleDefaultValues,
    resolver: yupResolver(vehicleSchema),
  });

  return {
    handleVehicleSubmit,
    resetVehicleForm,
    vehicleFormComponent: (
      <VehicleForm
        errors={vehicleErrors}
        onSetValue={setValue}
        onClearErrors={clearErrors}
        watch={watch}
        vehiclesData={vehicles}
      />
    ),
  };
}
export default useVehicleForm;
