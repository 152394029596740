/**
 * @file estimatedPricing.graphql.ts
 * queries to get estimated pricing.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_AUTOMATED_BASE_RATE = gql`
  query AutomatedBaseRate(
    $vehicleId: ID!
    $stops: [StopInformationInputForAutomatedPricing]!
  ) {
    automatedBaseRate(vehicleId: $vehicleId, stops: $stops) {
      total
      lineItems {
        name
        description
        lineTotal
        source
      }
    }
  }
`;
