import React from "react";
import first from "lodash/first";

import { Box, CircularProgress, Typography } from "@mui/material";

import { useScreenSize } from "globals/hooks";
import { Trip } from "types";
import noTrips from "../../../design-system/illustrations/noTrips.svg";
import { alabaster, grayLight, white } from "design-system/colors";
import { DriverTrackingTripCard } from "pages/driverTracking/DriverTrackingTripCard/DriverTrackingTripCard";
import { useHistory } from "react-router-dom";

type DriverTrackingTripsViewProps = {
  trips: Trip[];
  isLoading: boolean;
};

function DriverTrackingTripsView(props: DriverTrackingTripsViewProps) {
  const { trips, isLoading } = props;

  // hooks
  const history = useHistory();
  const { isMobileView } = useScreenSize();

  const isNoTrips = !trips?.length;

  return (
    <Box
      sx={{
        width: isMobileView ? "100vw" : "480px",
        backgroundColor: isNoTrips ? white : alabaster,
        height: "100%",
        overflow: "auto",
      }}
    >
      {!isMobileView && (
        <Box
          sx={{
            borderBottom: `1px solid ${grayLight}`,
            display: "flex",
            alignItems: "center",
            padding: 2,
            backgroundColor: white,
          }}
        >
          <Typography variant="h1">Driver Tracking</Typography>
        </Box>
      )}

      {isLoading ? (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : isNoTrips ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              marginTop: "50%",
              padding: "0 30px",
            }}
          >
            <img src={noTrips} alt="noTrips" width="90px" />
            <Typography variant="h2" m={1}>
              No Trips
            </Typography>
            <Typography variant="body1" textAlign="center">
              No current trips. Only trips in progress and coming up will be
              displayed here.
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box pr={2}>
          {trips.map((trip) => {
            const route = trip.routes[0];
            // routeDispatch is the driver app route info
            const { driver, routeDispatch } = route;

            const orderNumber = `${trip.request.orderNumber}-${trip.tripNumber}`;
            const tripCategory = trip.tripCategory;
            const roundTripVariant = trip.roundTripVariant;

            const currentStopIndex = routeDispatch?.currentStop?.stopIndex - 1;

            const stopLabel = routeDispatch?.currentStopName;

            const stopAbbrevationWithAddressLabel = `${
              currentStopIndex === 0
                ? "PU"
                : currentStopIndex === trip.stops.length - 1
                ? "DO"
                : "ST" + currentStopIndex
            }: ${routeDispatch?.currentStop?.location}`;

            const routeDispatchLogStatus = routeDispatch?.currentStatus;
            const driverName = driver
              ? `${driver.firstName} ${driver.lastName}`
              : null;

            const passengerName = !!trip.temporaryPassenger?.name
              ? trip.temporaryPassenger.name
              : `${trip.contact?.firstName} ${trip.contact?.lastName}`;

            const vehicleName = route.vehicle?.name;

            // datetimes
            const pickUpTime = new Date(first(trip.stops).dateTime);
            const arrivalStopTime = routeDispatch?.currentStopArrivalTime
              ? new Date(routeDispatch.currentStopArrivalTime)
              : undefined;
            const driverEta = routeDispatch?.driverEta
              ? new Date(routeDispatch.driverEta)
              : undefined;

            const navigateToTripSummary = () => {
              if (isMobileView) {
                history.push(`/dispatch/${trip.request.id}/update/${trip.id}`, {
                  backNavigationPath: `${history.location.pathname}${history.location.search}`,
                });
              } else {
                history.push(
                  `/reservations/${trip.request.id}?tripId=${trip.id}`,
                  {
                    backNavigationPath: `${history.location.pathname}${history.location.search}`,
                  }
                );
              }
            };

            return (
              <DriverTrackingTripCard
                key={trip.id}
                onClick={navigateToTripSummary}
                orderNumber={orderNumber}
                tripCategory={tripCategory}
                roundTripVariant={roundTripVariant}
                driverName={driverName}
                vehicleName={vehicleName}
                passengerName={passengerName}
                driverAcceptanceStatus={route.driverStatus?.name}
                dispatchStatus={route.dispatchStatus}
                routeDispatchLogStatus={routeDispatchLogStatus}
                pickUpTime={pickUpTime}
                stopLabel={stopLabel}
                stopAbbrevationWithAddressLabel={
                  stopAbbrevationWithAddressLabel
                }
                arrivalStopTime={arrivalStopTime}
                driverEta={driverEta}
                stops={trip.stops}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
}

export default DriverTrackingTripsView;
