/**
 * @file useKeyPress.ts
 * react hook for handling keypresses
 *
 * Can be used in multiple ways:
 *
 * // save keyPress as boolean
 * 1. const isEnterPressed = useKeyPress('Enter');
 *
 * // pass in a callback "action"
 * 2. useKeyPress('Enter', {
 *      action: () => console.log('enter pressed!'),
 *    })
 *
 * Takes "ref" tracks keystrokes on a specific element
 * 3. useKeyPress('Enter', {
 *      ref: inputRef,
 *    })
 *
 * reference: https://stackoverflow.com/questions/42036865/react-how-to-navigate-through-list-by-arrow-keys
 */

import { useState, RefObject, useEffect } from "react";

type useKeyPressOptionsProps = {
  ref?: RefObject<HTMLInputElement>;
  action?: (event: any) => void;
};

export const useKeyPress = (
  targetKey: string,
  options?: useKeyPressOptionsProps
): boolean => {
  const { ref, action } = options;

  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler(event: KeyboardEvent) {
    if (event.key === targetKey) {
      setKeyPressed(true);
      if (action) {
        action(event);
      }
    }
  }

  const upHandler = (event: KeyboardEvent) => {
    if (event.key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    const target = ref?.current ? ref.current : window;

    target.addEventListener("keydown", downHandler);
    target.addEventListener("keyup", upHandler);

    return () => {
      target.removeEventListener("keydown", downHandler);
      target.removeEventListener("keyup", upHandler);
    };
  });

  return keyPressed;
};
