import React from "react";
import { Link } from "react-router-dom";

import { Box, Typography, Hidden } from "@mui/material";

import {
  PromoCodesListItemDesktop,
  PromoCodesListItemMobile,
} from "./components";
import { PromoCode } from "types";
import { alabaster, grayLight } from "design-system/colors";

type PromoCodesListProps = {
  promoCodes: PromoCode[];
};

function PromoCodesList(props: PromoCodesListProps) {
  const { promoCodes } = props;

  return (
    <>
      {/* Large Screen View */}
      <Hidden mdDown>
        <Box>
          <Box
            sx={{
              position: "sticky",
              top: "127px",
              backgroundColor: alabaster,
              zIndex: 20,
              py: 2,
              borderBottom: `1px solid ${grayLight}`,
            }}
            display="flex"
            flexDirection="row"
            flex="1"
            px={3}
            mb={1}
          >
            <Box display="flex" flex="1.25">
              <Typography variant="overline">code</Typography>
            </Box>
            <Box display="flex" flex="1">
              <Typography variant="overline">status</Typography>
            </Box>
            <Box display="flex" flex="1">
              <Typography variant="overline">amount</Typography>
            </Box>
            <Box display="flex" flex="1">
              <Typography variant="overline">redemptions</Typography>
            </Box>
            <Box display="flex" flex="1.5">
              <Typography variant="overline">booking date</Typography>
            </Box>
            <Box display="flex" flex="1.5">
              <Typography variant="overline">trip date</Typography>
            </Box>
            <Box display="flex" flex="0.5">
              <Typography variant="overline"></Typography>
            </Box>
          </Box>
          {promoCodes.map((promoCode) => {
            return (
              <Box key={promoCode.id} mb={1}>
                <Link
                  to={`/settings/customer-portal/promo-codes/update/${promoCode.id}?tab=promo-codes`}
                >
                  <PromoCodesListItemDesktop promoCode={promoCode} />
                </Link>
              </Box>
            );
          })}
        </Box>
      </Hidden>
      {/* Small screen view */}
      <Hidden mdUp>
        <Box>
          {promoCodes.map((promoCode) => (
            <Link
              key={promoCode.id}
              to={`/settings/customer-portal/promo-codes/update/${promoCode.id}?tab=promo-codes`}
            >
              <PromoCodesListItemMobile promoCode={promoCode} />
            </Link>
          ))}
        </Box>
      </Hidden>
    </>
  );
}

export default PromoCodesList;
