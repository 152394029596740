import { useMutation } from "@apollo/client";

import { Box, Button } from "@mui/material";

import { white } from "design-system/colors";
import { CheckIcon, CrossIcon } from "design-system/icons";
import {
  ACCEPT_UNCONFIRMED_REQUEST_MUTATION,
  DECLINE_UNCONFIRMED_REQUEST_MUTATION,
  LOAD_REQUEST_QUERY,
} from "globals/graphql";
import { useAnalytics, usePrevious, useSnackbar } from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { trackReservations } from "globals/utils/tracking/trackReservations";
import { RequestStage } from "types";

type AcceptRejectRequestPanelProps = {
  requestId: string;
  requestStage: string;
  isDeclined: boolean;
};

const AcceptRejectRequestPanel = (props: AcceptRejectRequestPanelProps) => {
  const { requestId, requestStage, isDeclined } = props;

  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const previousDeclineStatus = usePrevious(isDeclined);

  const [acceptUnconfirmedReservation] = useMutation(
    ACCEPT_UNCONFIRMED_REQUEST_MUTATION,
    {
      refetchQueries: [
        {
          query: LOAD_REQUEST_QUERY,
          variables: { id: requestId },
        },
      ],
      onCompleted(data) {
        snackbar.success("Reservation successfully updated");
        const request = data.acceptUnconfirmedReservation.request;
        trackReservations(track, request, "reservation_accepted");
      },
      onError(error) {
        console.error(error);
        snackbar.error(getErrorMessage(error) || "Error updating reservation");
      },
    }
  );

  const [declineUnconfirmedReservation] = useMutation(
    DECLINE_UNCONFIRMED_REQUEST_MUTATION,
    {
      refetchQueries: [
        {
          query: LOAD_REQUEST_QUERY,
          variables: { id: requestId },
        },
      ],
      onCompleted(data) {
        snackbar.success("Reservation successfully updated");
        const request = data.declineUnconfirmedReservation.request;
        trackReservations(track, request, "reservation_rejected");
      },
      onError(error) {
        snackbar.error(getErrorMessage(error) || "Error updating reservation");
      },
    }
  );

  const handleAcceptUnconfirmedReservation = () => {
    acceptUnconfirmedReservation({
      variables: {
        input: { requestId },
      },
    });
  };

  const handleDeclineUnconfirmedReservation = () => {
    declineUnconfirmedReservation({
      variables: {
        input: { requestId },
      },
    });
  };

  const isUnconfirmedReservation =
    requestStage === RequestStage.Unconfirmed && !isDeclined;

  const isUpdatingReservation =
    requestStage === RequestStage.Unconfirmed &&
    previousDeclineStatus &&
    !isDeclined;

  if (isUpdatingReservation) {
    return null;
  }

  return (
    isUnconfirmedReservation && (
      <Box display="flex" gap={1.2} my={1}>
        <Button
          onClick={handleAcceptUnconfirmedReservation}
          variant="contained"
          color="success"
          startIcon={<CheckIcon color={white} />}
          fullWidth
        >
          Accept
        </Button>
        <Button
          onClick={handleDeclineUnconfirmedReservation}
          variant="contained"
          color="error"
          startIcon={<CrossIcon color={white} />}
          fullWidth
        >
          Decline
        </Button>
      </Box>
    )
  );
};

export default AcceptRejectRequestPanel;
