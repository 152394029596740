import React from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import RemoveDialog from "components/RemoveDialog";
import { useSnackbar } from "globals/hooks";
import {
  REMOVE_INSURANCE_POLICY_MUTATION,
  LOAD_INSURANCE_POLICIES_QUERY,
} from "globals/graphql";

type RemoveInsuranceDialogProps = {
  open: boolean;
  onClose: () => void;
  insuranceId: string;
};

function RemoveInsuranceDialog(props: RemoveInsuranceDialogProps) {
  const { open, onClose, insuranceId } = props;

  // hooks
  const history = useHistory();
  const snackbar = useSnackbar();

  // mutations
  const [removeInsurancePolicy] = useMutation(
    REMOVE_INSURANCE_POLICY_MUTATION,
    {
      refetchQueries: [{ query: LOAD_INSURANCE_POLICIES_QUERY }],
      onCompleted() {
        snackbar.success("Successfully deleted insurance policy!");
        history.push(`/settings/insurance`);
      },
      onError(error) {
        snackbar.error("Error deleting insurance policy");
      },
    }
  );

  // event handlers
  const handleRemoveInsurance = () => {
    removeInsurancePolicy({
      variables: {
        input: {
          id: insuranceId,
        },
      },
    });

    onClose();
  };

  return (
    <RemoveDialog
      open={open}
      onClose={onClose}
      onRemove={handleRemoveInsurance}
      title="Remove this insurance policy?"
      body="This will permanantly remove the insurance policy. Do you want to remove this
      insurance policy?"
    />
  );
}

export default RemoveInsuranceDialog;
