import React from "react";

import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { currency } from "globals/utils/helpers";
import { getTripPromoAmount } from "../utils";

function PromoAmountColumn(props: GridRenderCellParams<string>) {
  const { row: trip } = props;

  const promoAmount = getTripPromoAmount(trip);

  return <Typography variant="body2">{currency(promoAmount)}</Typography>;
}

export default PromoAmountColumn;
