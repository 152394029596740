import { gql } from "@apollo/client";

export const CREATE_FARMED_ROUTE_MUTATION = gql`
  mutation CreateFarmedRoute($input: CreateFarmedRouteInput!) {
    createFarmedRoute(input: $input) {
      route {
        id
        farmAffiliateVariant
        farmStatus
        farmoutNote
        farmeePricing {
          id
          baseRateAmt
        }
      }
    }
  }
`;

export const UPDATE_FARMED_ROUTE_STATUS_MUTATION = gql`
  mutation UpdateFarmedRouteStatus($input: UpdateFarmedRouteStatusInput!) {
    updateFarmedRouteStatus(input: $input) {
      route {
        id
        updatedAt
        farmStatus
        canEditPricing
      }
    }
  }
`;
