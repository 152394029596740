import React from "react";

import { Box, Typography } from "@mui/material";

import NoReviewBadgeIcon from "design-system/icons/info/NoReviewBadgeIcon";
import { useScreenSize } from "globals/hooks";

function NoReviews() {
  // hook
  const { isSmallTabletView } = useScreenSize();

  return (
    <Box display="flex" alignItems="center" flexDirection="column" p={1} my={3}>
      <NoReviewBadgeIcon />
      <Typography variant="h4" mt={2} mb={1}>
        No Reviews
      </Typography>
      <Box width={isSmallTabletView ? "100%" : "50%"}>
        <Typography variant="body2" textAlign="center">
          You have no reviews at the moment. Send customers trip review links to
          start getting feedback!
        </Typography>
      </Box>
    </Box>
  );
}

export default NoReviews;
