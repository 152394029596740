import React from "react";
import { useQuery } from "@apollo/client";

import { Box, CircularProgress, Typography } from "@mui/material";

import { MoovsMarketAppData } from "../../../../../../MoovsMarketAppList/hooks/useMoovsMarketApps";
import { LOAD_OPERATOR_DRIVERS_QUERY } from "globals/graphql";
import { granite } from "design-system/colors";
import { MarketApp } from "pages/moovs-market/components/MoovsMarketAppList/data/appData";

type DeleteAppDialogContentProps = {
  app: MoovsMarketAppData;
};

const DeleteAppDialogContent = (props: DeleteAppDialogContentProps) => {
  const { app } = props;

  // queries
  const { data, loading: isDriversLoading } = useQuery(
    LOAD_OPERATOR_DRIVERS_QUERY
  );

  // derived state
  const numberOfDrivers = data?.drivers.length;

  const textContents = {
    [MarketApp.Driver]: `By deleting Driver Platform, ${numberOfDrivers} of your drivers will lose access to
    the Moovs Driver App and you will lose access to the driver tracking
    page.`,
    [MarketApp.SalesAutomation]: "",
    [MarketApp.DynamicPrice]: "",
    [MarketApp.ReviewPlatform]:
      "Are you sure you want to delete the 'Customer Reviews' app?",
  };

  if (isDriversLoading && app.enum === MarketApp.Driver) {
    return <CircularProgress />;
  }

  return (
    <Box mb={3}>
      <Typography variant="body2" color={granite}>
        {textContents[app.enum]}
      </Typography>
    </Box>
  );
};

export default DeleteAppDialogContent;
