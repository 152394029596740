import React from "react";

type CancellationIconProps = {
  color?: string;
  size?: "small";
  viewBox?: string;
};

const CancellationIcon = (props: CancellationIconProps) => {
  const { color, size, viewBox } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox={viewBox || "0 0 16 16"}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.5 2H9.5L13 5.5V9M3 4.5V12.8C3 13.1183 3.1317 13.4235 3.36612 13.6485C3.60054 13.8736 3.91848 14 4.25 14H11.75C12.0815 14 12.3995 13.8736 12.6339 13.6485M9 2V6H13"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 2L15 15"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox={viewBox || "0 0 24 24"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 2H9.5L13 5.5V9M3 4.5V12.8C3 13.1183 3.1317 13.4235 3.36612 13.6485C3.60054 13.8736 3.91848 14 4.25 14H11.75C12.0815 14 12.3995 13.8736 12.6339 13.6485M9 2V6H13"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 2L15 15"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CancellationIcon;
