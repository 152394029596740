import React from "react";

import { Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import DispatchStatusChip from "components/DispatchStatusChip";

function DispatchStatusColumn(props: GridRenderCellParams<string>) {
  const { value } = props;

  return (
    <Box display="flex" justifyContent="center">
      <DispatchStatusChip dispatchStatus={value} shortened />
    </Box>
  );
}

export default DispatchStatusColumn;
