import React from "react";

type ViewOffIconProps = {
  color?: string;
  size?: "small";
};

const ViewOffIcon = (props: ViewOffIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.99907 4.00072C10.5073 3.96042 13.1013 5.62982 14.7341 7.33953C14.9053 7.52053 15 7.7555 15 7.99911C15 8.24272 14.9053 8.47769 14.7341 8.65869C14.2984 9.11531 14.0623 9.14539 13.5 9.57091M7.54347 10.1792C7.09088 10.0934 6.67517 9.88173 6.34891 9.57091C6.02265 9.26009 5.80048 8.86411 5.71049 8.43304M8.89225 5.94645C9.17542 6.05814 9.43271 6.22188 9.6494 6.42832C9.8661 6.63476 10.038 6.87985 10.1552 7.14958M3 6C2.15361 6.54855 1.88938 6.68592 1.26592 7.33953C1.09473 7.52053 1 7.7555 1 7.99911C1 8.24272 1.09473 8.47769 1.26592 8.65869C2.86131 10.3322 5.44792 12.0402 7.99907 11.9993C8.50058 12.0073 9.00349 11.9478 9.5 11.8342M2 2L14 13"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 14.6851C12.139 14.7381 11.7046 14.7778 11.413 14.7241C10.8311 14.6168 10.2967 14.3522 9.87717 13.9636C9.45769 13.5751 9.17204 13.0801 9.05635 12.5413C8.99636 12.2619 9.04452 11.8408 9.1048 11.5M12.5 9.26066C12.7215 9.29536 12.9386 9.35309 13.1472 9.43306C13.5113 9.57267 13.8421 9.77735 14.1207 10.0354C14.3993 10.2935 14.6202 10.5998 14.7709 10.937C14.8358 11.0822 14.8871 11.2996 14.9244 11.5M10.114 7.19879C10.7398 7.06218 11.3714 6.99109 11.9988 7.00089C15.2236 6.95052 18.5589 9.03728 20.6581 11.1744C20.8782 11.4007 21 11.6944 21 11.9989C21 12.3034 20.8782 12.5971 20.6581 12.8234C19.978 13.5165 19.158 14.2143 18.2463 14.8357M6.43067 8.72823C5.25629 9.44114 4.19629 10.3036 3.34189 11.1744C3.1218 11.4007 3 11.6944 3 11.9989C3 12.3034 3.1218 12.5971 3.34189 12.8234C5.39312 14.9153 8.71876 17.0502 11.9988 16.9991C12.8368 17.0122 13.6779 16.8825 14.5 16.6474"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.28571 4L19.7143 19"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ViewOffIcon;
