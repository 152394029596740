import React from "react";
import startCase from "lodash/startCase";

import { Box, Typography, Chip, Divider } from "@mui/material";

import { FarmAffiliateVehicle } from "../../../types";
import { useScreenSize } from "../../../globals/hooks";
import ViewOnlyDrawer from "../../globals/ViewOnlyDrawer";
import AutomatedPricingIcon from "../../../design-system/icons/info/AutomatedPricingIcon";
import { errorRed, successGreen } from "../../../design-system/colors";
import ReadOnlyVehicleFeatures from "../../vehicles/ReadOnlyVehicleFeatures";
import VehiclePricingBlock from "../../vehicles/VehiclePricingBlock";
import VehiclePhotos from "../../vehicles/VehiclePhotos/VehiclePhotos";
import BasicInfoBlock from "./BasicInfoBlock";

type ViewOnlyVehicleDrawerProps = {
  open: boolean;
  vehicle: FarmAffiliateVehicle;
  handleClose: () => void;
};

function ViewOnlyVehicleDrawer(props: ViewOnlyVehicleDrawerProps) {
  const { open, handleClose, vehicle } = props;

  const { isMobileView } = useScreenSize();

  const {
    enableBaseRateAutomation,
    name: vehicleName,
    available,
    capacity,
    licensePlate,
    description,
    exteriorColor,
    insurancePolicy,
  } = vehicle;
  const typeName = vehicle.vehicleType.typeName;
  const cancellationPolicy = !vehicle.cancellationPolicy
    ? "Not Listed"
    : startCase(vehicle.cancellationPolicy.variant.toLowerCase());

  const InsuranceBlock = () => {
    if (!!insurancePolicy) {
      return (
        <Box>
          {insurancePolicy.insuranceCompany}
          <Typography style={{ display: "block" }} variant="caption">
            {insurancePolicy.policyNumber}
          </Typography>
        </Box>
      );
    }

    return <Box>{"Not Listed"}</Box>;
  };

  return (
    <ViewOnlyDrawer open={open} onClose={handleClose}>
      {/* Vehicle Header */}
      <Box display="flex" flexDirection="column" my={1}>
        <Box my={1} display="flex" flexDirection="row" alignItems="center">
          <Typography variant="h4">{vehicleName}</Typography>
          {enableBaseRateAutomation && (
            <Box ml={1}>
              <AutomatedPricingIcon />
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="row" my={1}>
          <Box mr={1}>
            <Chip label={typeName} />
          </Box>
          <Box mr={1}>
            <Chip label={`${capacity} Seats`} />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={isMobileView ? "column" : "row"}
          flexWrap="wrap"
          my={1}
          justifyContent="flex-start"
        >
          <Box flexDirection="column" mr={3} {...(isMobileView && { mb: 1 })}>
            <Typography variant="overline">CANCELLATION POLICY</Typography>
            <Typography variant="body2">
              {startCase(cancellationPolicy)}
            </Typography>
          </Box>

          <Box flexDirection="column" mr={3} {...(isMobileView && { mb: 1 })}>
            <Typography variant="overline">INSURANCE POLICY</Typography>
            <InsuranceBlock />
          </Box>

          <Box display="flex" alignItems="center">
            <Box
              display="flex"
              justifyContent="flex-start"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Typography variant="overline">STATUS</Typography>
              <Typography
                variant="body2"
                style={{
                  color: available ? successGreen : errorRed,
                }}
              >
                {available ? "Active" : "Deactivated"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
      {/* Basic Info */}
      <BasicInfoBlock
        vehicleName={vehicleName}
        typeName={typeName}
        capacity={capacity}
        licensePlate={licensePlate}
        description={description}
        exteriorColor={exteriorColor}
      />
      {/* Photos */}
      <Box display="flex" flexDirection="column" my={2}>
        <Typography variant="h5">Photos</Typography>
        <VehiclePhotos mode="view-only" photos={vehicle.photos} />
      </Box>
      {/* Vehicle Features */}
      <ReadOnlyVehicleFeatures vehicleFeatures={vehicle.features} />
      {/* Vehicle Pricing */}
      <Box mt={4}>
        <VehiclePricingBlock vehicle={vehicle} isReadOnly />
      </Box>
    </ViewOnlyDrawer>
  );
}

export default ViewOnlyVehicleDrawer;
