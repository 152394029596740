import React from "react";

import StepConnector, { StepConnectorProps } from "@mui/material/StepConnector";

import { moovsBlue, grayLight } from "design-system/colors";

function ProgressConnector(props: StepConnectorProps) {
  const sharedStyles = {
    height: "4px",
    borderRadius: "5px",
    border: "none",
  };

  const activeOrCompletedStyles = {
    ...sharedStyles,
    backgroundColor: moovsBlue,
  };

  // "&.Mui-active": the line displayed directly before the current step
  // "&.Mui-completed": any lines displayed before (but not directly before) the current step
  // "&.Mui-disabled": the line displayed directly after the current step
  // "& .MuiStepConnector-line": any lines displayed after (but not directly after) the current step
  return (
    <StepConnector
      sx={{
        "&.Mui-active": {
          "& .MuiStepConnector-line": activeOrCompletedStyles,
        },
        "&.Mui-completed": {
          "& .MuiStepConnector-line": activeOrCompletedStyles,
        },
        "&.Mui-disabled": {
          "& .MuiStepConnector-line": {
            background: `linear-gradient(90deg, ${moovsBlue} 33%, ${grayLight} 33%)`,
          },
        },
        "& .MuiStepConnector-line": {
          ...sharedStyles,
          backgroundColor: grayLight,
        },
      }}
      {...props}
    />
  );
}

export default ProgressConnector;
