import React, { useContext, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Control,
  FieldErrors,
  useForm,
  UseFormHandleSubmit,
  UseFormSetValue,
} from "react-hook-form";
import size from "lodash/size";

import { Box, Typography } from "@mui/material";

import { overviewDefaultValues } from "./defaultValues";
import { overviewSchema } from "./schemaValidation";
import { GlobalDynamicPricing } from "../../DynamicPricingDialog";
import { generateFormName } from "../../utils/generateFormName";
import EditName from "./components/EditName";
import { generateOverviewInputs } from "../../utils/generateOverviewInputs";

type OverviewValues = {
  name: string;
};

type OverviewFormProps = {
  control: Control<OverviewValues>;
  errors: FieldErrors;
  onSetValue: UseFormSetValue<OverviewValues>;
  onOverviewSubmit: UseFormHandleSubmit<OverviewValues>;
};

function OverviewForm(props: OverviewFormProps) {
  const { control, errors, onOverviewSubmit, onSetValue } = props;

  const [validatedInput, currentWorkflow, setDynamicPricingInput] =
    useContext(GlobalDynamicPricing);

  const formName = generateFormName({
    currentWorkflow,
    validatedInput,
  });

  const handleNameSave = (data) => {
    setDynamicPricingInput({
      ...validatedInput,
      [currentWorkflow]: {
        ...validatedInput[currentWorkflow],
        ...data,
      },
    });
  };

  // update default value with generated form name
  useEffect(() => {
    if (size(validatedInput[currentWorkflow].name)) return;

    onSetValue("name", formName);
    setDynamicPricingInput({
      ...validatedInput,
      [currentWorkflow]: {
        ...validatedInput[currentWorkflow],
        name: formName,
      },
    });
  }, [
    formName,
    onSetValue,
    setDynamicPricingInput,
    currentWorkflow,
    validatedInput,
  ]);

  const overViewLayout = generateOverviewInputs({
    validatedInput,
    currentWorkflow,
  });

  return (
    <>
      <Box mb={4}>
        <Typography variant="subtitle1">Overview</Typography>
      </Box>
      <Box>
        <Box mb={3}>
          <EditName
            name="name"
            control={control}
            errorMessage={errors.name?.message}
            onNameSave={handleNameSave}
            onOverviewSubmit={onOverviewSubmit}
          />
          {overViewLayout.map(({ label, value }) => (
            <Box display="flex" alignItems="center" mb={4} key={label}>
              <Box mr={2}>
                <Typography variant="subtitle1">{label}: </Typography>
              </Box>
              <Typography variant="body1">{value}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}

function useOverviewForm() {
  // setup react hook forms
  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit: handleOverviewSubmit,
    reset: resetOverviewForm,
  } = useForm({
    defaultValues: overviewDefaultValues,
    resolver: yupResolver(overviewSchema),
  });

  return {
    handleOverviewSubmit,
    resetOverviewForm,
    overviewFormComponent: (
      <OverviewForm
        control={control}
        errors={errors}
        onOverviewSubmit={handleOverviewSubmit}
        onSetValue={setValue}
      />
    ),
  };
}
export default useOverviewForm;
