export enum DriverPayoutToggleVariant {
  HOURLY_RATE = "hourly-rate",
  BASE_RATE_PCT = "base-rate-pct",
  FLAT_RATE = "flat-rate",
}

export enum InputFormatVariant {
  DOLLAR = "Dollar",
  PERCENT = "Percent",
  HOURS = "Hours",
}

export enum FormVariant {
  UPDATE_DRIVER = "update-driver",
}
