import React from "react";
import { useHistory } from "react-router-dom";

import { Box, Typography, Hidden } from "@mui/material";

import { Term } from "../../../types";
import TermsListItemDesktop from "./TermsListItem.desktop";
import TermsListItemMobile from "./TermsListItem.mobile";

type TermListProps = {
  terms: Term[];
  sxStyles: any;
};

function TermList(props: TermListProps) {
  const { terms, sxStyles } = props;
  const history = useHistory();

  const handleListItemClick = (termId: string) => {
    history.push(`/settings/terms-and-conditions/update/${termId}`);
  };

  return (
    <>
      {/* Large Screen View */}
      <Hidden mdDown>
        <Box sx={sxStyles}>
          <Box display="flex" flexDirection="row" flex="1" px={3} mb={1}>
            <Box display="flex" flex="4">
              <Typography variant="overline">NAME</Typography>
            </Box>
            <Box display="flex" flex="1">
              <Typography variant="overline">CREATED</Typography>
            </Box>
          </Box>
          {[...terms]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((term: Term) => (
              <Box mb={1} key={term.id}>
                <TermsListItemDesktop
                  term={term}
                  onClick={handleListItemClick}
                />
              </Box>
            ))}
        </Box>
      </Hidden>
      {/* Small screen view */}
      <Hidden mdUp>
        {[...terms]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((term: Term) => (
            <Box mx={2} mb={1} key={term.id}>
              <TermsListItemMobile term={term} onClick={handleListItemClick} />
            </Box>
          ))}
      </Hidden>
    </>
  );
}

export default TermList;
