import React from "react";

import { Box, Typography } from "@mui/material";

type MoovsMarketAppNoAccessProps = {
  title: string;
  description: string;
  icon: string;
};

function MoovsMarketAppNoAccess(props: MoovsMarketAppNoAccessProps) {
  const { title, description, icon } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Box m={2}>
        <img src={icon} width="140px" alt="no-access"></img>
      </Box>
      <Box mb={1}>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Box mb={1} textAlign="center">
        <Typography variant="body1">{description}</Typography>
      </Box>
    </Box>
  );
}

export default MoovsMarketAppNoAccess;
