import React, { ChangeEvent } from "react";

import {
  Box,
  Divider,
  FormControlLabel,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";

import { Refund } from "../../../types";
import {
  LabeledInlineSelect,
  LabeledInlineInput,
} from "../../../design-system/components/inputs";

const periodNames = [
  { name: "Weeks", value: "WEEK" },
  { name: "Days", value: "DAY" },
  { name: "Hours", value: "HOUR" },
];

type RefundUpdateRowProps = {
  label: string;
  refund: Refund;
  onChange: (key: string, value: number | string | boolean) => void;
};

function RefundUpdateRow(props: RefundUpdateRowProps) {
  const { label, refund, onChange } = props;

  const handleRefundChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value: number | string | boolean = event.target.value;

    if (event.target.name === "periodValue") {
      value = Number(value);
    }

    if (event.target.name === "active") {
      value = event.target.checked;
    }

    onChange(event.target.name, value);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body2">{label}</Typography>
        <FormControlLabel
          labelPlacement="start"
          control={
            <Switch
              checked={refund.active}
              onChange={handleRefundChange}
              name="active"
              color="primary"
            />
          }
          label={refund.active ? "Active" : "Inactive"}
        />
      </Box>
      <Box display="flex" flexDirection="column" mt={1}>
        {refund.active && (
          <>
            <Box flex="1" display="flex">
              <LabeledInlineInput
                required
                errorText="Active refunds require a quantity"
                label="Quantity"
                type="number"
                onChange={handleRefundChange}
                name="periodValue"
                value={(refund && refund.periodValue) || ""}
              />
            </Box>
            <Box flex="1" display="flex">
              <LabeledInlineSelect
                required
                errorText="Active refunds require a time"
                label="Time"
                name="periodName"
                onChange={handleRefundChange}
                value={(refund && refund.periodName) || ""}
              >
                <MenuItem key="-" value={null}>
                  -
                </MenuItem>
                {periodNames.map(({ name, value }) => (
                  <MenuItem key={value} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </LabeledInlineSelect>
              {/* </TextField> */}
            </Box>
          </>
        )}
      </Box>
      <Box my={3}>
        <Divider />
      </Box>
    </>
  );
}

export default RefundUpdateRow;
