/**
 * @file VehiclesList.tsx
 * List of Vehicle info cards. Used on vehiclesPage.
 *
 * components:
 *  VehiclesList
 *
 * author: jackv, refactored by sienag, andrew
 */
import React from "react";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";

import { Vehicle } from "../../types";
import VehiclesListItemDesktop from "./VehiclesListItem.desktop";
import VehiclesListItemMobile from "./VehiclesListItem.mobile";
import { useScreenSize } from "../../globals/hooks";

type VehicleListProps = {
  listStyles: any;
  globalSearch: string;
  vehicles: Vehicle[];
};

function VehiclesList(props: VehicleListProps) {
  const { listStyles, vehicles } = props;

  // hooks
  const history = useHistory();
  const { isMobileView } = useScreenSize();

  const handleVehicleItemClick = (vehicleId: string) => {
    history.push(`/vehicles/update/${vehicleId}`);
  };

  return (
    <>
      {isMobileView ? (
        <>
          {vehicles.map((vehicle) => (
            <Box mx={2} my={1} key={vehicle.id} data-testid="vehicles-list">
              <VehiclesListItemMobile
                vehicle={vehicle}
                onClick={handleVehicleItemClick}
              />
            </Box>
          ))}
        </>
      ) : (
        <Box sx={listStyles}>
          {vehicles.map((vehicle) => (
            <Box my={1} key={vehicle.id} data-testid="vehicles-list">
              <VehiclesListItemDesktop
                vehicle={vehicle}
                onClick={handleVehicleItemClick}
              />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
}

export default VehiclesList;
