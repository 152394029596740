import React, { useState } from "react";

import { MobileDateTimePicker, MobileDateTimePickerProps } from "@mui/lab";
import { Box, InputAdornment, TextField, TextFieldProps } from "@mui/material";

import { CalendarIcon } from "design-system/icons";
import MoovsTooltip from "./MoovsTooltip";

type MoovsDateTimePickerProps = {
  hideCalendarIcon?: boolean;
  onAccept: (date: any) => void;
  onClose?: () => void;
  onChange?: (date: any) => void;
  renderInputProps?: TextFieldProps;
  clearable?: boolean;
  format?: string;
  disabledReason?: string;
} & Omit<
  MobileDateTimePickerProps,
  "onChange" | "renderInput" | "InputProps" | "clearable" | "open"
>;

function MoovsDateTimePicker(props: MoovsDateTimePickerProps) {
  const {
    hideCalendarIcon,
    renderInputProps = {},
    clearable = false,
    onAccept,
    onClose,
    onChange,
    format,
    disabled,
    disabledReason,
    ...otherProps
  } = props;

  // state
  const [openDateTimePicker, setOpenDateTimePicker] = useState(false);

  // event handlers
  const handleClosePicker = () => {
    if (onClose) {
      onClose();
    }
    setOpenDateTimePicker(false);
  };
  const handleOpenPicker = () => setOpenDateTimePicker(true);

  const handleAccept = (date) => {
    onAccept(date);
    handleClosePicker();
  };

  return (
    <Box width="100%">
      <MoovsTooltip
        hidden={!disabled}
        tooltipText={disabledReason || "Date and time is not available"}
      >
        <MobileDateTimePicker
          {...otherProps}
          open={openDateTimePicker}
          clearable={clearable}
          onChange={onChange || (() => {})}
          onAccept={handleAccept}
          onClose={handleClosePicker}
          inputFormat={format || null}
          disabled={disabled}
          InputProps={
            hideCalendarIcon
              ? { style: { cursor: "pointer" } }
              : {
                  endAdornment: (
                    <InputAdornment position="end" sx={{ mr: 1 }}>
                      <CalendarIcon />
                    </InputAdornment>
                  ),
                  style: { cursor: "pointer" },
                }
          }
          renderInput={(params) => (
            <TextField
              {...params}
              onClick={handleOpenPicker}
              variant="outlined"
              fullWidth
              inputProps={{
                ...params.inputProps,
                style: { cursor: "pointer" },
              }}
              {...renderInputProps}
              sx={{
                "& .MuiOutlinedInput-root": { padding: 0 },
                "& .MuiOutlinedInput-input": {
                  paddingY: 2,
                  paddingX: 2,
                },
              }}
            />
          )}
        />
      </MoovsTooltip>
    </Box>
  );
}
export default MoovsDateTimePicker;
