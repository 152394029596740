import { DeepPartial } from "ts-essentials";

import { Trip } from "types";

export const removeStop = (
  trips: DeepPartial<Trip>[],
  tripIndex: number,
  stopIndex: number
) => {
  return trips.map((trip: DeepPartial<Trip>, i) => {
    if (i !== tripIndex) return trip;

    const newStops = trip.stops
      .filter((stop) => stop.stopIndex !== stopIndex)
      .map((stop, index) => {
        stop.stopIndex = index + 1;
        return stop;
      });

    return {
      ...trip,
      stops: newStops,
    };
  });
};

export const createStop = (trips: DeepPartial<Trip>[], tripIndex: number) => {
  return trips.map((trip: DeepPartial<Trip>, i) => {
    if (i !== tripIndex) return trip;

    const stops = [...trip.stops];
    const lastStop = stops.pop();

    return {
      ...trip,
      stops: [
        ...stops,
        {
          variant: "address",
          location: "",
          dateTime: null,
          airport: null,
          airline: null,
          flightNumber: null,
          stopIndex: trip.stops.length,
          id: `${trip.stops.length}_${Date.now()}`,
        },
        {
          ...lastStop,
          stopIndex: trip.stops.length + 1,
          id: `${trip.stops.length + 1}_${Date.now()}`,
        },
      ],
    };
  });
};
