import React from "react";

import { CustomerPortalColorTheme, CustomerPortalImage } from "./sections";

type BrandingTabProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function BrandingTab(props: BrandingTabProps) {
  const { setSaveIndicatorState } = props;

  return (
    <>
      <CustomerPortalImage setSaveIndicatorState={setSaveIndicatorState} />
      <CustomerPortalColorTheme setSaveIndicatorState={setSaveIndicatorState} />
    </>
  );
}

export default BrandingTab;
