import { useMutation } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import LabeledSwitchInput from "components/settings/general/LabeledSwitchInput";
import { UPDATE_PAYOUT_SCHEDULE } from "globals/graphql";
import { useSnackbar } from "globals/hooks";
import { currency } from "globals/utils/helpers";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import React from "react";

import { PayoutSchedule, StripeAccount, StripeFinancialAccount } from "types";

type BalanceTabProps = {
  stripeAccount: StripeAccount;
  financialAccount: StripeFinancialAccount;
  setSaveIndicatorState: React.Dispatch<string>;
};

function BalanceTab(props: BalanceTabProps) {
  const { stripeAccount, financialAccount, setSaveIndicatorState } = props;

  // hooks
  const snackbar = useSnackbar();
  const [updatePayoutSchedule, { loading }] = useMutation(
    UPDATE_PAYOUT_SCHEDULE,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        const errorMessage = getErrorMessage(error) || error.message;

        snackbar.error(errorMessage);
      },
    }
  );

  const onPayoutScheduleChange = (_, newValue: boolean) => {
    setSaveIndicatorState("loading");

    updatePayoutSchedule({
      variables: {
        input: {
          payoutSchedule: newValue
            ? PayoutSchedule.Instant
            : PayoutSchedule.Standard,
        },
      },
    });
  };

  const isEnabled = stripeAccount.payoutSchedule === PayoutSchedule.Instant;

  return (
    <Box mb={3} display="flex" flexDirection="column">
      <Typography sx={{ my: 1 }} variant="h2">
        Balance
      </Typography>
      <Typography sx={{ fontWeight: 500, mb: 1 }} variant="h1">
        {currency(financialAccount.balance)}
      </Typography>

      <Box mt={10}>
        <Typography sx={{ my: 1 }} variant="h4">
          Instant Payouts
        </Typography>
        <Box>
          <LabeledSwitchInput
            disabled={loading}
            name="automatedChargeEmails"
            checked={isEnabled}
            onChange={onPayoutScheduleChange}
            text="Enable Instant Payouts"
            subText={`Setting ${
              isEnabled ? "enabled" : "disabled"
            }: Payouts are usually dispersed within ${
              isEnabled ? "an hour" : "3-5 business days"
            }.`}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default BalanceTab;
