import { WorkflowSelectionsEnum } from "../steps/workflow/schemaValidation";

type GetEventHandlerStepsProps = {
  workflow: string;
  handleWorkflowSubmit: (data) => void;
  handleDateSubmit: (data) => void;
  handleDateRangeSubmit: (data) => void;
  handleTimesOfDaySubmit: (data) => void;
  handleAdjustAmtSubmit: (data) => void;
  handleVehicleSubmit: (data) => void;
  handleRepeatRuleSubmit: (data) => void;
  handleOverviewSubmit: (data) => void;
};

// this returns a list of eventHandlers depending on which workflow user is on
export const getEventHandlerSteps =
  (props: GetEventHandlerStepsProps) => (handleAfterSubmit: (data) => void) => {
    const {
      workflow,
      handleWorkflowSubmit,
      handleDateSubmit,
      handleDateRangeSubmit,
      handleTimesOfDaySubmit,
      handleAdjustAmtSubmit,
      handleVehicleSubmit,
      handleRepeatRuleSubmit,
      handleOverviewSubmit,
    } = props;

    const dateHandlers = {
      date: handleDateSubmit(handleAfterSubmit),
      dateRange: handleDateRangeSubmit(handleAfterSubmit),
      timesOfDay: handleTimesOfDaySubmit(handleAfterSubmit),
    };

    // starter step needs an array of minimum length 2 for useFormStepProgress hook to work
    const starterHandlerSteps = [handleWorkflowSubmit(handleAfterSubmit), null];

    // specific date steps
    const dateEventHandlerSteps = [
      handleWorkflowSubmit(handleAfterSubmit),
      dateHandlers[workflow],
      handleAdjustAmtSubmit(handleAfterSubmit),
      handleVehicleSubmit(handleAfterSubmit),
      handleRepeatRuleSubmit(handleAfterSubmit),
      handleOverviewSubmit(handleAfterSubmit),
    ];

    // date range
    const dateRangeEventHandlerSteps = [
      handleWorkflowSubmit(handleAfterSubmit),
      dateHandlers[workflow],
      handleAdjustAmtSubmit(handleAfterSubmit),
      handleVehicleSubmit(handleAfterSubmit),
      handleRepeatRuleSubmit(handleAfterSubmit),
      handleOverviewSubmit(handleAfterSubmit),
    ];

    // time range
    const timeRangeEventHandlerSteps = [
      handleWorkflowSubmit(handleAfterSubmit),
      dateHandlers[workflow],
      handleAdjustAmtSubmit(handleAfterSubmit),
      handleVehicleSubmit(handleAfterSubmit),
      handleOverviewSubmit(handleAfterSubmit),
    ];

    if (workflow === WorkflowSelectionsEnum.date) return dateEventHandlerSteps;
    if (workflow === WorkflowSelectionsEnum.dateRange)
      return dateRangeEventHandlerSteps;
    if (workflow === WorkflowSelectionsEnum.timesOfDay)
      return timeRangeEventHandlerSteps;

    return starterHandlerSteps;
  };
