import React, { ChangeEvent, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import { Box, TextField } from "@mui/material";

import MoovsDialog from "../../MoovsDialog";
import InternationalPhoneInput from "components/inputs/InternationalPhoneInput";
import { Contact, CreateContactInput } from "types";
import { CREATE_CONTACT_MUTATION } from "globals/graphql";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { isValidPhoneNumber } from "globals/utils/helpers";
import { useScreenSize } from "globals/hooks";

type AddContactToOrderDialogProps = {
  open: boolean;
  onClose: () => void;
  onContactUpdate: (contact: Contact) => void;
  carryOverSearch?: string;
  companyId?: string;
};

const initialContact = {
  firstName: "",
  lastName: "",
  email: "",
  mobilePhone: "",
  companyPosition: "",
  comments: [{ bodyText: "" }],
  phoneCountryCode: "",
  phoneCountryDialCode: "",
  phoneCountryName: "",
  phoneCountryFormat: "",
};

const initialContactErrors = {
  firstName: "",
  lastName: "",
  email: "",
  mobilePhone: "",
};

function AddContactToOrderDialog(props: AddContactToOrderDialogProps) {
  const {
    open,
    onClose,
    onContactUpdate,
    carryOverSearch = "",
    companyId,
  } = props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const { isMobileView } = useScreenSize();

  // state
  const [contact, setContact] = useState<CreateContactInput>(initialContact);
  const [contactErrors, setContactErrors] =
    useState<Partial<Contact>>(initialContactErrors);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // mutations
  const [createContactMutation] = useMutation(CREATE_CONTACT_MUTATION, {
    onCompleted(data) {
      setIsSubmitting(false);
      const contact = data.createContact.contact;

      // populate request booking contact with new contact
      onContactUpdate(contact);
      handleCloseDialog();
    },
    onError(error) {
      setIsSubmitting(false);
      snackbar.error("Error creating contact");
    },
  });

  // event handlers
  const handleCloseDialog = () => {
    setContact(initialContact);
    setContactErrors(initialContactErrors);
    onClose();
  };

  const handleContactChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setContact({
      ...contact,
      [event.target.name]: event.target.value,
    });
    setContactErrors({ ...contactErrors, [event.target.name]: "" });
  };

  const handlePhoneNumberInput = (value, country) => {
    setContact({
      ...contact,
      mobilePhone: value,
      phoneCountryCode: country.countryCode,
      phoneCountryDialCode: country.dialCode,
      phoneCountryName: country.name,
      phoneCountryFormat: country.format,
    });
    setContactErrors({ ...contactErrors, mobilePhone: "" });
  };

  const handleSaveClick = () => {
    let contactErrors = [];

    // contact errors
    if (!contact.firstName) {
      contactErrors = [
        ...contactErrors,
        { firstName: "Please enter First Name" },
      ];
    }

    if (!contact.lastName) {
      contactErrors = [
        ...contactErrors,
        { lastName: "Please enter Last Name" },
      ];
    }

    if (!contact.email) {
      contactErrors = [...contactErrors, { email: "Please enter Email" }];
    }

    if (contact.phoneCountryCode === "us") {
      if (!contact.mobilePhone || !isValidPhoneNumber(contact.mobilePhone)) {
        contactErrors = [
          ...contactErrors,
          { mobilePhone: "Please enter a valid phone number" },
        ];
      }
    } else {
      if (!contact.mobilePhone) {
        contactErrors = [
          ...contactErrors,
          { mobilePhone: "Please enter a phone number" },
        ];
      }
    }

    if (contactErrors.length > 0) {
      setContactErrors(
        contactErrors.reduce((acc, value) => ({ ...acc, ...value }), {})
      );

      return;
    }

    setIsSubmitting(true);

    if (companyId) {
      track("contact_companyAdded");
    }

    createContactMutation({
      variables: {
        input: {
          ...contact,
          ...(companyId && { companyId }),
        },
      },
    });
  };

  // effects
  // reset dialog on close -> re-open
  useEffect(() => {
    if (open) {
      setIsSubmitting(false);
      setContactErrors(initialContactErrors);
      setContact(initialContact);
    }
  }, [open]);

  // set fields depending on `carryOverSearch` content
  useEffect(() => {
    const digitsOnly = /^\d+$/.test(carryOverSearch);
    if (digitsOnly) {
      setContact({
        ...contact,
        mobilePhone: carryOverSearch,
      });
    }
    if (carryOverSearch.includes("@")) {
      setContact({
        ...contact,
        email: carryOverSearch,
      });
    } else if (!digitsOnly && !contact.firstName) {
      // split name string into first and last
      const nameArr = carryOverSearch.split(" ");
      setContact({
        ...contact,
        firstName: nameArr[0],
        lastName: nameArr[1],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carryOverSearch]);

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      dialogTitle="Add New Contact"
      onAccept={handleSaveClick}
      acceptButtonText="Add New Contact"
      closeButtonText="Cancel"
      hideTopBorder
      hideBottomBorder
      size="sm"
      acceptDisabled={isSubmitting}
      childrenSx={{ overflow: "visible" }}
    >
      <Box my={6}>
        <Box display="flex" flexDirection="row">
          <Box mb={1} flex="1">
            <TextField
              fullWidth
              variant="outlined"
              name="firstName"
              value={contact.firstName}
              label="First Name"
              onChange={handleContactChange}
              error={!!contactErrors.firstName}
              helperText={contactErrors.firstName}
            />
          </Box>
          <Box mb={1} ml={1} flex="1">
            <TextField
              fullWidth
              variant="outlined"
              name="lastName"
              value={contact.lastName}
              label="Last Name"
              onChange={handleContactChange}
              error={!!contactErrors.lastName}
              helperText={contactErrors.lastName}
            />
          </Box>
        </Box>
        <Box mb={1}>
          <TextField
            fullWidth
            variant="outlined"
            name="email"
            value={contact.email}
            label="Email"
            onChange={handleContactChange}
            error={!!contactErrors.email}
            helperText={contactErrors.email}
          />
        </Box>
        <Box display="flex" flex="1">
          <InternationalPhoneInput
            value={contact.mobilePhone}
            error={contactErrors.mobilePhone}
            onChange={handlePhoneNumberInput}
            dropdownBelowInput={!!isMobileView}
            dropdownWidth={isMobileView ? "600%" : "1035%"}
          />
        </Box>
      </Box>
    </MoovsDialog>
  );
}

export default AddContactToOrderDialog;
