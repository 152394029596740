import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";

import { alabaster, grayLight } from "design-system/colors";
import { Cell } from "./types";

const styles = {
  1: {
    bgColor: alabaster,
    borderBottom: `1px solid ${grayLight}`,
  },
  2: {
    bgColor: alabaster,
    borderBottom: `1px solid ${grayLight}`,
  },
  3: {
    bgColor: "rgba(25, 95, 233, 0.04)",
    borderBottom: "1px solid rgba(25, 95, 233, 0.08)",
  },
  4: {
    bgColor: "rgba(40, 178, 153, 0.04)",
    borderBottom: "1px solid rgba(40, 178, 153, 0.1)",
  },
  5: {
    bgColor: "rgba(164, 68, 183, 0.04)",
    borderBottom: "1px solid rgba(164, 68, 183, 0.08)",
  },
};

type SubscriptionPlansGridChartProps = {
  chartData: Cell[][];
};

function SubscriptionPlansGridChart(props: SubscriptionPlansGridChartProps) {
  const { chartData } = props;

  return (
    <Grid container columnSpacing={3}>
      {chartData.map((row) => {
        return row.map((cell: Cell, index) => {
          const isHeader = cell.column === 1;

          return (
            <Grid key={cell.column} item sm={2.4}>
              <Box
                bgcolor={styles[cell.column].bgColor}
                borderBottom={styles[cell.column].borderBottom}
                minHeight="65px"
                minWidth="161px"
                display="flex"
                alignItems="center"
                {...(!isHeader && { sx: { justifyContent: "center" } })}
              >
                {/* grid item will at minimal show typography or icon */}
                {cell.typographyText ? (
                  <Typography
                    variant={cell.typographyVariant as Variant}
                    textAlign={index === 0 ? "left" : "center"}
                  >
                    {cell.typographyText}
                  </Typography>
                ) : null}
                {cell.icon ? cell.icon : null}
                {/* tooltip is always optional */}
                {cell.tooltip ? cell.tooltip : null}
              </Box>
            </Grid>
          );
        });
      })}
    </Grid>
  );
}

export default SubscriptionPlansGridChart;
