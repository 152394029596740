import React from "react";

import { Box, Typography } from "@mui/material";

type PlanInfoSectionProps = {
  planName: string;
  icon: JSX.Element;
  bgColor: string;
  pricePerMonth: number;
};

function PlanInfoSection(props: PlanInfoSectionProps) {
  const { planName, icon, bgColor, pricePerMonth } = props;

  return (
    <Box
      bgcolor={bgColor}
      p={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center" py={1}>
        <Box px={1}>{icon}</Box>
        <Typography variant="h4" px={1}>
          {planName}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" pr={1}>
        <Typography variant="h2" pr={0.5}>
          {`$${pricePerMonth}`}
        </Typography>
        <Typography variant="caption">/month</Typography>
      </Box>
    </Box>
  );
}

export default PlanInfoSection;
