import React from "react";

import { Box, Typography } from "@mui/material";

import DriverPayoutForm from "components/settings/drivers/DriverPayout/DriverPayoutForm";
import { CreateDriverInput } from "types";

type PayoutDetailsProps = {
  driver: CreateDriverInput;
  setDriver: React.Dispatch<React.SetStateAction<CreateDriverInput>>;
};

function PayoutDetails(props: PayoutDetailsProps) {
  const { driver, setDriver } = props;

  return (
    <>
      <Box mt={4} mb={2}>
        <Typography variant="h3">Driver Payout Details</Typography>
      </Box>
      <Box mb={4}>
        <DriverPayoutForm driver={driver} setDriver={setDriver} />
      </Box>
    </>
  );
}

export default PayoutDetails;
