import * as yup from "yup";

export const vehicleSchema = yup.object({
  vehicles: yup
    .array()
    .of(
      yup.object({
        id: yup.string(),
        value: yup.string(),
        disabled: yup.boolean(),
        isSelected: yup.boolean(),
      })
    )
    .min(1, "Please select vehicle(s)")
    .test(
      "Check if at least one vehicle is selected",
      "Please select vehicle(s)",
      (schema) => schema.some((item) => item.isSelected)
    )
    .required("Please select vehicle(s)"),
  vehiclesAvailable: yup.number(),
});
