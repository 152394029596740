import React from "react";

import { Box, Button, CircularProgress, Typography } from "@mui/material";

import { moovsBlue } from "design-system/colors";
import { HelpIcon } from "design-system/icons";
import { useScreenSize } from "globals/hooks";

type StepperFormFooterProps = {
  isFirst: boolean;
  isLast: boolean;
  onGoBack: () => void;
  onCancel: () => void;
  onValidate: () => void;
  lastStepSubmitButtonText?: string;
  showHelpButton?: boolean;
  onHelp?: () => void;
  submitDisabled?: boolean;
};

function StepperFormFooter(props: StepperFormFooterProps) {
  const {
    isFirst,
    isLast,
    onGoBack,
    onCancel,
    onValidate,
    lastStepSubmitButtonText = "Activate",
    showHelpButton,
    onHelp,
    submitDisabled,
  } = props;

  const submitButtonText = isLast ? lastStepSubmitButtonText : "Next";

  const { isMobileView } = useScreenSize();

  return (
    <Box
      display="flex"
      justifyContent={showHelpButton ? "space-between" : "flex-end"}
      {...(showHelpButton && { width: "100%" })}
    >
      {/* Help Button */}
      {showHelpButton && (
        <Box
          id="fab-help"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={120}
          sx={{ cursor: "pointer" }}
          onClick={onHelp}
        >
          <Typography fontWeight={500} color={moovsBlue}>
            Ask for help
          </Typography>
          {!isMobileView && <HelpIcon size="small" color={moovsBlue} />}
        </Box>
      )}

      <Box display="flex">
        {/* Back / Cancel Button */}
        {!isFirst ? (
          <Button color="primary" onClick={onGoBack}>
            Go Back
          </Button>
        ) : (
          <Button color="primary" onClick={onCancel}>
            Cancel
          </Button>
        )}

        {/* Next / Activate (last step) Button will submit/validate the current form that user is on.
        If validation fails, it will not move to next step. */}
        <Box
          ml={isMobileView ? 1 : 2}
          {...(isMobileView && { maxWidth: "130px" })}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={onValidate}
            disabled={submitDisabled}
          >
            {submitDisabled ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              submitButtonText
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default StepperFormFooter;
