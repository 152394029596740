import React from "react";
import PhoneInput from "react-phone-input-2";

import { Box, SxProps } from "@mui/material";

import { moovsBlue, granite } from "../../design-system/colors";

const styles = {
  phoneBox: {
    width: "100%",
    "& .react-tel-input": {
      "&:focus-within": {
        "& .special-label": {
          color: moovsBlue,
        },
      },
      "& .special-label": {
        color: granite,
      },
      "& .form-control": {
        width: "100%",
        "&:focus": {
          border: `2px solid ${moovsBlue} !important`,
          boxShadow: "none !important",
        },
      },
    },
  },
};

export type CountryData = {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
};

type InternationalPhoneInputProps = {
  value: string;
  error?: string;
  phoneCountryDialCode?: string;
  onChange: (value, country, e?, formattedValue?) => void;
  dropdownBelowInput?: boolean;
  dropdownWidth?: string;
  errorMessage?: string;
  sx?: SxProps;
  label?: string;
};

function InternationalPhoneInput(props: InternationalPhoneInputProps) {
  const {
    value,
    error,
    onChange,
    phoneCountryDialCode = "",
    dropdownBelowInput = true,
    dropdownWidth = "600%",
    errorMessage,
    sx,
    label,
  } = props;

  return (
    <Box sx={sx || styles.phoneBox}>
      <PhoneInput
        value={value || phoneCountryDialCode}
        specialLabel={label || "Phone Number *"}
        isValid={() => {
          return !!error ? errorMessage || "Please enter phone number" : true;
        }}
        country="us"
        onChange={(value, country) => {
          let currentCountry = country as CountryData;

          const { countryCode, dialCode } = currentCountry || {};

          if (
            countryCode &&
            dialCode &&
            countryCode !== "us" &&
            dialCode[0] === "1"
          ) {
            currentCountry.dialCode = "1";
          }

          onChange(value, currentCountry);
        }}
        countryCodeEditable={false}
        enableSearch
        searchStyle={{ width: "93%" }}
        dropdownStyle={{
          maxHeight: 175,
          width: dropdownWidth,
          top: dropdownBelowInput ? "none" : 0,
          position: "absolute",
        }}
      />
    </Box>
  );
}

export default InternationalPhoneInput;
