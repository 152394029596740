import { useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";

import { LOAD_REQUEST_QUERY, LOAD_TRIP_QUERY } from "globals/graphql";
import { Trip, Request } from "types";
import { getConfirmReservationDisabledTooltip } from "globals/utils/getConfirmReservationDisabledTooltip";

type UseUpdateQuoteDrawerQueryProps = {
  requestId: string;
  tripId: string;
};

export const useUpdateQuoteDrawerQuery = (
  props: UseUpdateQuoteDrawerQueryProps
) => {
  const { requestId, tripId } = props;

  // queries
  const {
    data: tripData,
    error: tripError,
    refetch: tripRefetch,
    loading: tripLoading,
  } = useQuery(LOAD_TRIP_QUERY, {
    variables: {
      id: tripId,
    },
    fetchPolicy: "network-only",
    skip: !tripId,
    ...(tripId && { pollInterval: 60 * 1000 }), // every 60 seconds
  });

  const {
    data: requestData,
    loading: requestLoading,
    refetch: requestRefetch,
  } = useQuery(LOAD_REQUEST_QUERY, {
    variables: {
      id: requestId,
    },
    skip: !tripData,
  });

  // derived state
  const trip: Trip = tripData?.node;
  const request: Request = requestData?.node;
  const needsPricing = !trip?.totalAmount;
  const needsVehicle = !trip?.routes[0]?.vehicle;
  const sendQuoteDisabled =
    needsPricing ||
    needsVehicle ||
    !!request?.confirmReservationDisabledVariant;

  // memo
  const sendQuoteDisabledTooltip = useMemo(() => {
    // trip level disabled tooltip
    if (needsPricing && needsVehicle) {
      return "Must add price and vehicle in order to send";
    } else if (needsPricing) {
      return "Must add price in order to send";
    } else if (needsVehicle) {
      return "Must add vehicle in order to send";
    }

    // request level disabled tooltip
    return getConfirmReservationDisabledTooltip(
      request?.confirmReservationDisabledVariant
    );
  }, [needsPricing, needsVehicle, request?.confirmReservationDisabledVariant]);

  // loading states
  const isLoading = (requestLoading || tripLoading) && !requestData;
  const hasError = tripError && !tripLoading;

  // effects
  useEffect(() => {
    // refetch request query when there is new tripData
    requestRefetch();
  }, [tripData, requestRefetch]);

  return {
    trip,
    request,
    isLoading,
    hasError,
    tripError,
    tripRefetch,
    requestRefetch,
    needsPricing,
    needsVehicle,
    sendQuoteDisabled,
    sendQuoteDisabledTooltip,
  };
};
