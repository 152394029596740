import React from "react";

import { Box } from "@mui/material";

import {
  CustomerPortalIframe,
  CustomerPortalLink,
  EducationSection,
  InstallationSection,
  PassengerAppSection,
  WidgetDesignSection,
} from "./sections";

type InstallationAndShareTabProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function InstallationAndShareTab(props: InstallationAndShareTabProps) {
  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <EducationSection />
      <WidgetDesignSection
        setSaveIndicatorState={props.setSaveIndicatorState}
      />
      <InstallationSection />
      <CustomerPortalIframe />
      <CustomerPortalLink />
      <PassengerAppSection />
    </Box>
  );
}

export default InstallationAndShareTab;
