import React from "react";
import startCase from "lodash/startCase";

import { Box, Typography } from "@mui/material";

import MoovsAvatar from "design-system/components/MoovsAvatar";
import { User } from "types";

type MemberDrawerHeaderProps = {
  user: User;
};

function MemberDrawerHeader(props: MemberDrawerHeaderProps) {
  const { user } = props;

  const userName = user
    ? [user.firstName, user.lastName].map((word) => startCase(word)).join(" ")
    : "";

  return (
    <Box
      mt={3}
      mb={4}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box>
        <MoovsAvatar
          alt="contact, person"
          size="xl"
          placeholder={[user.firstName, user.lastName]}
        />
      </Box>
      <Box mt={2}>
        <Typography variant="h4">{userName}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1">
          {startCase(user.role?.slug)}
        </Typography>
      </Box>
      <Box>
        <Typography color="error" variant="subtitle1">
          {user.invitePending ? "Email sent" : null}
        </Typography>
      </Box>
    </Box>
  );
}

export default MemberDrawerHeader;
