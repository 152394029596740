import React from "react";

import { Box, IconButton, Typography } from "@mui/material";

import { alabaster } from "../../design-system/colors";

type ShuttleRouteInfoCardProps = {
  name: string;
  actionButtonIcon: any;
  onClickActionButton: () => void;
};

function ShuttleRouteInfoCard(props: ShuttleRouteInfoCardProps) {
  const { name, actionButtonIcon, onClickActionButton } = props;

  return (
    <Box
      borderRadius="4px"
      display="flex"
      justifyContent="space-between"
      flexDirection="row"
      sx={{
        position: "relative",
        backgroundColor: alabaster,
      }}
      p={2}
    >
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="overline">Route Name</Typography>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {name}
        </Typography>
      </Box>
      <IconButton onClick={onClickActionButton} size="large">
        {actionButtonIcon}
      </IconButton>
    </Box>
  );
}

export default ShuttleRouteInfoCard;
