/**
 * @file VehicleHeaderUpdateBlock.tsx
 * Goes at the top of the update vehicle drawer. Contains Vehicle name and other styling.
 */
import React from "react";
import startCase from "lodash/startCase";
import { Controller } from "react-hook-form";

import { useQuery } from "@apollo/client";

import { Box, Chip, Typography, MenuItem } from "@mui/material";

import { SmallSelect } from "../../design-system/components/inputs";
import { grayDark } from "../../design-system/colors";
import {
  LOAD_CANCELLATION_POLICIES_QUERY,
  LOAD_INSURANCE_POLICIES_QUERY,
} from "../../globals/graphql";
import { CancellationPolicy, InsurancePolicy, Vehicle } from "../../types";
import AutomatedPricingIcon from "../../design-system/icons/info/AutomatedPricingIcon";
import { ExternalOperatorIcon } from "../../design-system/icons";
import { useUpdateVehicleFormContext } from "./UpdateVehicleDrawer/hooks/useUpdateVehicleForm";

type VehicleHeaderUpdateBlockProps = {
  setSaveIndicatorState: Function;
  vehicle: Vehicle;
};

function VehicleHeaderUpdateBlock(props: VehicleHeaderUpdateBlockProps) {
  const { vehicle } = props;

  // hooks
  const formCtx = useUpdateVehicleFormContext();

  const { data: cancellationPoliciesData } = useQuery(
    LOAD_CANCELLATION_POLICIES_QUERY,
    {
      skip: !formCtx,
    }
  );
  const { data: insurancePoliciesData } = useQuery(
    LOAD_INSURANCE_POLICIES_QUERY,
    {
      skip: !formCtx,
    }
  );

  if (!formCtx) {
    console.error(
      "useUpdateVehicleFormContext must be used within an UpdateVehicleFormProvider"
    );
    return (
      <Box mt={4}>
        <Typography variant="body2" color="error">
          Error loading vehicle details. Please refresh the page.
        </Typography>
      </Box>
    );
  }

  if (!cancellationPoliciesData || !insurancePoliciesData) {
    return null;
  }

  // derived state
  const { control } = formCtx;

  const {
    name: vehicleName,
    vehicleType: { typeName },
    capacity,
    enableBaseRateAutomation,
    externalOperator,
  } = vehicle;

  return (
    <Box display="flex" flexDirection="column" my={1}>
      <Box
        my={1}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        columnGap={1}
        alignItems="center"
      >
        <Typography variant="h4">{vehicleName}</Typography>

        {enableBaseRateAutomation && (
          <Box ml={1}>
            <AutomatedPricingIcon />
          </Box>
        )}

        {externalOperator && (
          <Box display="flex" columnGap="1" alignItems="center">
            <Box>
              <ExternalOperatorIcon color={grayDark} size="small" />
            </Box>
            <Box ml={1} color={grayDark} fontSize={"12px"}>
              {externalOperator.operatorName}
            </Box>
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="row" my={1}>
        <Box mr={1}>
          <Chip label={typeName} />
        </Box>
        <Box mr={1}>
          <Chip label={`${capacity} Seats`} />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap" ml={-1} mt={0.5}>
        {cancellationPoliciesData && (
          <Box minWidth="220px">
            <Controller
              control={control}
              name="vehicle.cancellationPolicyId"
              render={({ field: { name, value, onChange } }) => (
                <SmallSelect
                  name={name}
                  label="CANCELLATION POLICY"
                  value={value || ""}
                  onChange={(e) => onChange(e.target.value)}
                >
                  {cancellationPoliciesData.cancellationPolicies.map(
                    (cancellationPolicy: CancellationPolicy) => (
                      <MenuItem
                        key={cancellationPolicy.id}
                        value={cancellationPolicy.id}
                      >
                        {startCase(cancellationPolicy.variant.toLowerCase())}
                      </MenuItem>
                    )
                  )}
                </SmallSelect>
              )}
            />
          </Box>
        )}
        {insurancePoliciesData && (
          <Box minWidth="220px">
            <Controller
              control={control}
              name="vehicle.insuranceId"
              render={({ field: { name, value, onChange } }) => (
                <SmallSelect
                  name={name}
                  label="INSURANCE POLICY"
                  value={value || ""}
                  onChange={(e) => onChange(e.target.value)}
                >
                  {insurancePoliciesData.insurancePolicies.map(
                    (insurancePolicy: InsurancePolicy) => (
                      <MenuItem
                        key={insurancePolicy.id}
                        value={insurancePolicy.id}
                      >
                        <Box>
                          {insurancePolicy.insuranceCompany}
                          <Typography
                            style={{ display: "block" }}
                            variant="caption"
                          >
                            {insurancePolicy.policyNumber}
                          </Typography>
                        </Box>
                      </MenuItem>
                    )
                  )}
                </SmallSelect>
              )}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default VehicleHeaderUpdateBlock;
