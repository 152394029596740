/**
 * @file graphql/operatorRoute.graphql.ts
 * queries/mutations for operatorRoutes.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_OPERATOR_ROUTE_QUERY = gql`
  query OperatorRoute($id: ID!) {
    node(id: $id) {
      id
      ... on OperatorRoute {
        id
        orderNumber
        operatorConfirmation
        driverCloseoutStartDateTime
        driverCloseoutEndDateTime
        driverCloseoutDurationMinutes
        driverCloseoutNote
        operatorCloseoutStartDateTime
        operatorCloseoutEndDateTime
        operatorCloseoutNote
        farmeeCloseoutStartDateTime
        farmeeCloseoutEndDateTime
        farmeeCloseoutNote
        publicId
        isFarmedRoute
        farmRelationship
        farmAffiliate {
          ... on ExternalOperator {
            id
            operatorName
            operatorEmail
            operatorPhone
          }
          ... on MoovsNetworkOperator {
            id
            operatorName
            operatorEmail
            operatorPhone
          }
          ... on GriddnetOperator {
            id
            operatorName
            operatorEmail
            operatorPhone
          }
          ... on MoovsAiOperator {
            id
            operatorName
            operatorEmail
            operatorPhone
          }
        }
        operator {
          id
          nameSlug
        }
        request {
          id
          orderNumber
          orderType
          bookingContact {
            id
            createdAt
            updatedAt
            firstName
            lastName
            email
            mobilePhone
            mobilePhoneInternational
            preferences
            homeAddress
            workAddress
            linkedPassengers {
              id
              firstName
              lastName
              createdAt
              updatedAt
              email
              mobilePhone
              mobilePhoneInternational
              preferences
              homeAddress
              workAddress
            }
          }
        }
        alert {
          newDriver
          uniqueLocation
          airport
          largeVehicle
          partner
          luggage
          signage
          carSeat
        }
        routeDriver {
          id
          driver {
            id
            firstName
            lastName
            mobilePhone
            email
            driverProfilePhoto
          }
          routeAvailability {
            available
          }
          personalAvailability {
            available
          }
        }
        driverStatus {
          name
          assignedAt
          acceptedAt
          declinedAt
        }
        vehicle {
          id
          name
          licensePlate
          vehicleType {
            typeName
            typeSlug
          }
          operator {
            id
            name
            generalEmail
            companyLogoUrl
          }
        }
        statusSlug
        driverNote
        trip {
          id
          requestId
          note
          totalGroupSize
          totalDuration
          estimatedDuration
          useTotalDuration
          tripNumber
          temporaryPassenger
          request {
            id
            bookingContact {
              id
              firstName
              lastName
              email
              mobilePhone
              mobilePhoneInternational
              linkedPassengers {
                id
                firstName
                lastName
                createdAt
                updatedAt
                email
                mobilePhone
                mobilePhoneInternational
                preferences
                homeAddress
                workAddress
              }
            }
          }
          comments {
            id
            bodyText
            createdAt
            updatedAt
            user {
              id
              firstName
              lastName
            }
          }
          routes {
            id
            createdAt
            updatedAt
            driver {
              id
              firstName
              lastName
              mobilePhone
              email
            }
            vehicle {
              id
              name
              capacity
              vehicleType {
                typeName
                typeSlug
              }
              description
              photos {
                id
                url
                photoIndex
              }
            }
            carryOnLuggage
            checkedLuggage
            oversizeLuggage
          }
          stops {
            id
            stopIndex
            variant
            location
            locationAlias
            dateTime
            logisticStreetViewUrl
            note
            flightNumber
            groupSize
            airport {
              icaoCode
              iataCode
              airportName
              countryName
              countryIso2
              timezone
              gmt
            }
            airline {
              icaoCode
              iataCode
              airlineName
              callsign
              fleetSize
              countryName
              countryIso2
              dateFounded
            }
            trackedFlight {
              id
              faFlightId
              updatedAt
              airline {
                icaoCode
                airlineName
                iataCode
              }
              actualAirline {
                airlineName
                iataCode
                icaoCode
              }
              flightStatus
              flightNumber
              actualFlightNumber
              origin {
                id
                airport {
                  airportName
                  iataCode
                  icaoCode
                  countryName
                  countryIso2
                  timezone
                  gmt
                }
                scheduledGate
                scheduledDateTime
                scheduledTerminal
                actualGate
                actualDateTime
                estimatedDateTime
                actualTerminal
              }
              destination {
                id
                airport {
                  airportName
                  iataCode
                  icaoCode
                  countryName
                  countryIso2
                  timezone
                  gmt
                }
                scheduledGate
                scheduledDateTime
                scheduledTerminal
                actualGate
                estimatedDateTime
                actualDateTime
                actualTerminal
              }
            }
          }
          contact {
            id
            firstName
            lastName
            email
            mobilePhone
            mobilePhoneInternational
            preferences
            homeAddress
            workAddress
          }
        }
      }
    }
  }
`;

export const LOAD_OPERATOR_ROUTE_DRIVER_INFO_QUERY = gql`
  query OperatorRoute($id: ID!) {
    node(id: $id) {
      id
      ... on OperatorRoute {
        id
        routeDriver {
          id
          driver {
            id
            firstName
            lastName
            mobilePhone
          }
          routeAvailability {
            available
          }
          personalAvailability {
            available
          }
        }
        driverStatus {
          name
          assignedAt
          acceptedAt
          declinedAt
        }
      }
    }
  }
`;

export const LOAD_DISPATCH_ROUTES_QUERY = gql`
  query OperatorRoutes(
    $startDate: Date
    $endDate: Date
    $cursor: String
    $limit: Int
  ) {
    operatorRoutes(
      startDate: $startDate
      endDate: $endDate
      after: $cursor
      first: $limit
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          statusSlug
          completeOrderNumber
          isFarmedRoute
          farmRelationship
          farmAffiliate {
            ... on ExternalOperator {
              id
              operatorName
            }
            ... on MoovsNetworkOperator {
              id
              operatorName
            }
            ... on GriddnetOperator {
              id
              operatorName
            }
            ... on MoovsAiOperator {
              id
              operatorName
            }
          }
          driverNote
          vehicle {
            id
            name
          }
          request {
            id
            orderType
            bookingContact {
              id
              firstName
              lastName
            }
            company {
              id
              name
              email
            }
          }
          trip {
            id
            tripNumber
            useTotalDuration
            totalDuration
            estimatedDuration
            totalGroupSize
            tripCategory
            roundTripVariant
            note
            temporaryPassenger
            routes {
              id
              pricing {
                totalAmt
              }
            }
            stops {
              id
              dateTime
              location
              locationAlias
              originalDateTime
              flightOffset
              airport {
                icaoCode
              }
              pickUpVariant
            }
            contact {
              id
              firstName
              lastName
              email
              mobilePhone
            }
          }
          driver {
            id
            firstName
            lastName
            mobilePhone
          }
          routeDriver {
            id
            driver {
              id
              firstName
              lastName
              mobilePhone
              email
              driverProfilePhoto
            }
            routeAvailability {
              available
            }
            personalAvailability {
              available
            }
          }
          driverStatus {
            name
            assignedAt
            acceptedAt
            declinedAt
          }
          alert {
            newDriver
            uniqueLocation
            airport
            largeVehicle
            partner
            luggage
            signage
            carSeat
          }
        }
      }
    }
  }
`;

export const LOAD_DISPATCH_CALENDAR_ROUTES_QUERY = gql`
  query OperatorRoutes(
    $startDate: Date
    $endDate: Date
    $cursor: String
    $limit: Int
  ) {
    operatorRoutes(
      startDate: $startDate
      endDate: $endDate
      after: $cursor
      first: $limit
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          isFarmedRoute
          farmRelationship
          farmAffiliate {
            ... on ExternalOperator {
              id
              operatorName
            }
            ... on MoovsNetworkOperator {
              id
              operatorName
            }
            ... on GriddnetOperator {
              id
              operatorName
            }
            ... on MoovsAiOperator {
              id
              operatorName
            }
          }
          vehicle {
            id
            name
          }
          trip {
            id
            tripNumber
            estimatedDuration
            stops {
              id
              dateTime
              location
              locationAlias
              originalDateTime
              flightOffset
            }
          }
        }
      }
    }
  }
`;

export const LOAD_OPERATOR_ROUTES_QUERY = gql`
  query OperatorRoutes(
    $startDate: Date
    $endDate: Date
    $cursor: String
    $limit: Int
  ) {
    operatorRoutes(
      startDate: $startDate
      endDate: $endDate
      after: $cursor
      first: $limit
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          orderNumber
          operatorConfirmation
          isFarmedRoute
          farmRelationship
          request {
            id
            orderNumber
            type
            stage
            bookingContact {
              id
              createdAt
              updatedAt
              firstName
              lastName
              email
              mobilePhone
              mobilePhoneInternational
              preferences
            }
          }
          statusSlug
          driverNote
          driverStatus {
            name
            assignedAt
            acceptedAt
            declinedAt
          }
          operator {
            id
          }
          alert {
            newDriver
            uniqueLocation
            airport
            largeVehicle
            partner
            luggage
            signage
            carSeat
          }
          driver {
            id
            firstName
            lastName
            mobilePhone
            email
          }
          vehicle {
            id
            capacity
            name
          }
          trip {
            id
            requestId
            totalGroupSize
            totalDuration
            estimatedDuration
            useTotalDuration
            tripNumber
            temporaryPassenger
            contact {
              id
              firstName
              lastName
              mobilePhone
              mobilePhoneInternational
              email
            }
            stops {
              id
              dateTime
              location
              locationAlias
              stopIndex
              variant
              flightNumber
              groupSize
              airport {
                icaoCode
                iataCode
                airportName
                countryName
                countryIso2
                timezone
                gmt
              }
              airline {
                icaoCode
                iataCode
                airlineName
                callsign
                fleetSize
                countryName
                countryIso2
                dateFounded
              }
              trackedFlight {
                id
                faFlightId
                updatedAt
                airline {
                  icaoCode
                  airlineName
                  iataCode
                }
                actualAirline {
                  airlineName
                  iataCode
                  icaoCode
                }
                flightStatus
                flightNumber
                actualFlightNumber
                origin {
                  id
                  airport {
                    airportName
                    iataCode
                    icaoCode
                    countryName
                    countryIso2
                    timezone
                    gmt
                  }
                  scheduledGate
                  scheduledDateTime
                  scheduledTerminal
                  actualGate
                  actualDateTime
                  estimatedDateTime
                  actualTerminal
                }
                destination {
                  id
                  airport {
                    airportName
                    iataCode
                    icaoCode
                    countryName
                    countryIso2
                    timezone
                    gmt
                  }
                  scheduledGate
                  scheduledDateTime
                  scheduledTerminal
                  actualGate
                  estimatedDateTime
                  actualDateTime
                  actualTerminal
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_OPERATOR_ROUTE_MUTATION = gql`
  mutation UpdateOperatorRoute($input: UpdateOperatorRouteInput!) {
    updateOperatorRoute(input: $input) {
      operatorRoute {
        id
        orderNumber
        operatorConfirmation
        statusSlug
        driverNote
        request {
          id
          orderNumber
          type
          bookingContact {
            id
            createdAt
            updatedAt
            firstName
            lastName
            email
            mobilePhone
            mobilePhoneInternational
            preferences
          }
        }
        operator {
          id
        }
        alert {
          newDriver
          uniqueLocation
          airport
          largeVehicle
          partner
          luggage
          signage
          carSeat
        }
        routeDriver {
          id
        }
        vehicle {
          id
          capacity
          name
        }
        trip {
          id
          requestId
          totalGroupSize
          totalDuration
          estimatedDuration

          useTotalDuration
          contact {
            id
            firstName
            lastName
            mobilePhone
            mobilePhoneInternational
          }
          stops {
            id
            dateTime
            location
            locationAlias
            stopIndex
            variant
            flightNumber
            airport {
              icaoCode
              iataCode
              airportName
              countryName
              countryIso2
              timezone
              gmt
            }
            airline {
              icaoCode
              iataCode
              airlineName
              callsign
              fleetSize
              countryName
              countryIso2
              dateFounded
            }
            trackedFlight {
              id
              faFlightId
              updatedAt
              airline {
                icaoCode
                airlineName
                iataCode
              }
              actualAirline {
                airlineName
                iataCode
                icaoCode
              }
              flightStatus
              flightNumber
              actualFlightNumber
              origin {
                id
                airport {
                  airportName
                  iataCode
                  icaoCode
                  countryName
                  countryIso2
                  timezone
                  gmt
                }
                scheduledGate
                scheduledDateTime
                scheduledTerminal
                actualGate
                actualDateTime
                estimatedDateTime
                actualTerminal
              }
              destination {
                id
                airport {
                  airportName
                  iataCode
                  icaoCode
                  countryName
                  countryIso2
                  timezone
                  gmt
                }
                scheduledGate
                scheduledDateTime
                scheduledTerminal
                actualGate
                estimatedDateTime
                actualDateTime
                actualTerminal
              }
            }
          }
        }
      }
    }
  }
`;
