import axios from "axios";
import React, { useState, useEffect } from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import LoadingButton from "@mui/lab/LoadingButton";
import { getServerLink } from "globals/utils/getServerLink";
import { useSnackbar } from "globals/hooks";
import * as Sentry from "@sentry/react";

export type Message = {
  role: "customer" | "operator";
  content: string;
};

type GenerateAIResponseProps = {
  setSuggestion: (suggestion: string) => void;
  conversationHistory: Message[];
};

type GenerateAIResponseResponse = {
  suggestion: string;
  version: number;
};

function GenerateAIResponse(props: GenerateAIResponseProps) {
  const { setSuggestion, conversationHistory } = props;

  // hooks
  const snackbar = useSnackbar();

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [lastConversationHistory, setLastConversationHistory] = useState("");
  const [generatedSuggestion, setGeneratedSuggestion] = useState("");

  useEffect(() => {
    const currentConversationString = JSON.stringify(conversationHistory);
    if (currentConversationString !== lastConversationHistory) {
      setGeneratedSuggestion("");
      setLastConversationHistory(currentConversationString);
    }
  }, [conversationHistory, lastConversationHistory]);

  // event handlers
  const handleGenerateAIResponse = async (e: React.MouseEvent) => {
    e.stopPropagation();

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const chatSuggestionURL = `${getServerLink()}/chat-suggestion`;

    try {
      const response = await axios.post<GenerateAIResponseResponse>(
        chatSuggestionURL,
        {
          messages: conversationHistory,
        },
        {
          timeout: 15000, // 15 seconds timeout
        }
      );
      const { suggestion } = response.data;
      setSuggestion(suggestion);
      setGeneratedSuggestion(suggestion);
    } catch (error) {
      console.error(error);
      // Response error
      if (error.response) {
        if (error.response.status === 429) {
          snackbar.error(
            "You've reached your limit for suggested responses. Please reach out to support@moovsapp.com to increase your limit."
          );
        } else {
          Sentry.captureException(error);
          snackbar.error(
            "Something went wrong generating a suggested response."
          );
        }
      } else {
        // Request or other error
        Sentry.captureException(error);
        snackbar.error(
          "Something went wrong sending the request to generate a suggested response. Please try again later."
        );
      }
    }
    setIsLoading(false);
  };

  const isButtonDisabled =
    isLoading ||
    (!!generatedSuggestion &&
      JSON.stringify(conversationHistory) === lastConversationHistory);

  return (
    <LoadingButton
      variant="contained"
      color="primary"
      onClick={handleGenerateAIResponse}
      loading={isLoading}
      loadingPosition="start"
      startIcon={<AutoAwesomeIcon />}
      disabled={isButtonDisabled}
    >
      Suggest Response
    </LoadingButton>
  );
}


export default GenerateAIResponse;
