import { gql } from "@apollo/client";

export const INSTALL_MOOVS_MARKET_APP_MUTATION = gql`
  mutation InstallMoovsMarketApp($input: InstallMoovsMarketAppInput!) {
    installMoovsMarketApp(input: $input) {
      moovsMarketApps {
        id
        driverApp {
          id
          active
          chargeAt
        }
        dynamicPricing {
          id
          active
          chargeAt
        }
        salesAutomation {
          id
          active
          chargeAt
        }
        reviewPlatform {
          id
          chargeAt
          active
        }
      }
    }
  }
`;

export const DELETE_MOOVS_MARKET_APP_MUTATION = gql`
  mutation DeleteMoovsMarketApp($input: DeleteMoovsMarketAppInput!) {
    deleteMoovsMarketApp(input: $input) {
      moovsMarketApps {
        id
        driverApp {
          id
          active
          chargeAt
        }
        dynamicPricing {
          id
          active
          chargeAt
        }
        salesAutomation {
          id
          active
          chargeAt
        }
        reviewPlatform {
          id
          chargeAt
          active
        }
      }
    }
  }
`;
