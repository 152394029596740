import React from "react";

type AddReturnIconProps = {
  color?: string;
  size?: "small";
};

const AddReturnIcon = (props: AddReturnIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.8 15L1 12.2M1 12.2L3.8 9.4M1 12.2H12.2C12.9426 12.2 13.6548 11.905 14.1799 11.3799C14.705 10.8548 15 10.1426 15 9.4V3.8C15 3.05739 14.705 2.3452 14.1799 1.8201C13.6548 1.295 12.9426 1 12.2 1H7.06667"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.8 15L1 12.2M1 12.2L3.8 9.4M1 12.2H12.2C12.9426 12.2 13.6548 11.905 14.1799 11.3799C14.705 10.8548 15 10.1426 15 9.4V3.8C15 3.05739 14.705 2.3452 14.1799 1.8201C13.6548 1.295 12.9426 1 12.2 1H7.06667"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddReturnIcon;
