import React from "react";

import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { black, grayMedium } from "design-system/colors";

function DriverNoteColumn(props: GridRenderCellParams<string>) {
  const { value } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
    >
      <Typography
        variant="body2"
        color={value ? black : grayMedium}
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {value || "Add Driver Note"}
      </Typography>
    </Box>
  );
}

export default DriverNoteColumn;
