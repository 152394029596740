import React from "react";
import { Control, Controller } from "react-hook-form";

import { TextField } from "@mui/material";

type RHFTextFieldProps = {
  name: string;
  control: Control<any>;
  errorMessage: string;
  // All other props
  [x: string]: any;
  hideErrorHelperText?: boolean;
};

function RHFTextField(props: RHFTextFieldProps) {
  const {
    name,
    control,
    errorMessage,
    hideErrorHelperText,
    ...textFieldProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const allProps = { ...field, ...textFieldProps };
        return (
          <TextField
            {...allProps}
            error={!!errorMessage}
            helperText={hideErrorHelperText ? "" : errorMessage}
          />
        );
      }}
    />
  );
}

export default RHFTextField;
