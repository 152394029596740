import React from "react";
import first from "lodash/first";
import moment from "moment";

import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Stop } from "types";
import FlightChangeDisplay from "pages/requests/RequestOverview/ReservationOverview/TripItineraryBlock/components/FlightChangeDisplay";

function PickUpTimeColumn(props: GridRenderCellParams<string>) {
  const { value, row } = props;

  const { flightOffset, originalDateTime, dateTime } = first(row.stops) as Stop;

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
    >
      <Box mr={1} mb={-0.5}>
        <FlightChangeDisplay
          flightOffset={flightOffset}
          originalDateTime={originalDateTime}
          dateTime={dateTime}
          iconSize="small"
        />
      </Box>
      <Typography variant="body2">{moment.utc(value).format("LT")}</Typography>
    </Box>
  );
}

export default PickUpTimeColumn;
