import React from "react";

import { Box, Typography } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";
import { useMutation } from "@apollo/client";
import {
  DISCONNECT_GOOGLE_CALENDAR_AUTH_MUTATION,
  LOAD_GOOGLE_CALENDAR_AUTH_QUERY,
} from "globals/graphql";
import { useAnalytics, useSnackbar } from "globals/hooks";

type DisconnectAccountDialogProps = {
  open: boolean;
  emailAddress: string;
  onClose: () => void;
};

const DisconnectAccountDialog = (props: DisconnectAccountDialogProps) => {
  const { open, emailAddress, onClose } = props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // mutations
  const [disconnectGoogleCalendarAuth, { loading }] = useMutation(
    DISCONNECT_GOOGLE_CALENDAR_AUTH_MUTATION,
    {
      refetchQueries: [{ query: LOAD_GOOGLE_CALENDAR_AUTH_QUERY }],
      onCompleted() {
        snackbar.success("Successfully disconnected account");

        track("market_calendarDisconnected");
      },
      onError() {
        onClose();

        snackbar.error("Error disconnecting Google calendar");
      },
    }
  );

  // event handlers
  const handleDisconnectClick = async () => {
    await disconnectGoogleCalendarAuth({
      variables: {
        input: {},
      },
    });

    onClose();
  };

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      onAccept={handleDisconnectClick}
      acceptButtonText="Disconnect"
      dialogTitle="Disconnect Google Calendar?"
      size="xs"
      acceptButtonIsDelete
      xsFullscreenMobile
      acceptDisabled={loading}
    >
      <Box m={2}>
        <Typography fontSize="14px" lineHeight="20px">
          {`Are you sure you want to disconnect the ‘Google Calendar’ App from ${emailAddress}?`}
        </Typography>
      </Box>
    </MoovsDialog>
  );
};

export default DisconnectAccountDialog;
