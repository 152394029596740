import React from "react";
import { useMutation } from "@apollo/client";

import { black, moovsBlueSelected } from "../../../../../design-system/colors";
import { useSnackbar } from "../../../../../globals/hooks";
import { UNDO_CLOSE_OUT_TRIP_MUTATION } from "../../../../../globals/graphql";
import MoovsBanner from "components/MoovsBanner";

type TripIsClosedBannerProps = { tripId: string; reopenDisabled: boolean };

function TripIsClosedBanner(props: TripIsClosedBannerProps) {
  const { tripId, reopenDisabled } = props;

  // hooks
  const snackbar = useSnackbar();

  // mutations
  const [undoCloseOutTrip] = useMutation(UNDO_CLOSE_OUT_TRIP_MUTATION, {
    refetchQueries: ["Requests"],
    onCompleted(data) {
      snackbar.success(`Successfully reopened trip!`);
    },
    onError(error) {
      snackbar.error("Error reopening your trip");
    },
  });

  // event handlers
  const handleReopenTripClick = () => {
    undoCloseOutTrip({
      variables: {
        input: {
          tripId,
        },
      },
    });
  };

  return (
    <MoovsBanner
      bannerVariant="compact"
      bannerText="Trip Closed"
      actionButtonVariant="outlined"
      actionButtonText="Reopen"
      onActionButtonClick={handleReopenTripClick}
      bannerBgColor={moovsBlueSelected}
      bannerTextColor={black}
      {...(reopenDisabled && {
        actionButtonDisabledTooltipText: "Affiliate must reopen",
      })}
    />
  );
}

export default TripIsClosedBanner;
