import React from "react";

import { Box, Typography } from "@mui/material";

import chartData from "./utils/data";
import SubscriptionPlansGridChart from "../utils/SubscriptionPlansGridChart";

function MarketingSalesSection() {
  return (
    <Box my={3}>
      <Typography variant="h3" mb={2}>
        Marketing & Sales
      </Typography>

      <SubscriptionPlansGridChart chartData={chartData} />
    </Box>
  );
}

export default MarketingSalesSection;
