import React from "react";
import { Control, FieldErrors, UseFormWatch } from "react-hook-form";

import { EditPromoCodeValues } from "../useEditPromoCodeDefaultValues";
import { CodeAndAmountForm } from "../../../../../CreatePromoCodeDialog/steps/codeAndAmount/useCodeAndAmountForm";

type AmountProps = {
  control: Control<EditPromoCodeValues>;
  errors: FieldErrors;
  watch: UseFormWatch<EditPromoCodeValues>;
};

function Amount(props: AmountProps) {
  const { control, errors, watch } = props;

  return (
    <CodeAndAmountForm
      control={control}
      errors={errors}
      watchEdit={watch}
      isEdit={true}
    />
  );
}

export default Amount;
