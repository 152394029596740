import { grayDark } from "design-system/colors";
import {
  CancelTripIcon,
  CheckCircleIcon,
  DollarIcon,
  EmailIcon,
  InvoiceActivityLogIcon,
  QuotesIcon,
  TripIcon,
} from "design-system/icons";
import CancelledFlightIcon from "design-system/icons/navigation/CancelledFlightIcon";
import React from "react";

type MapEmailTypeToIconAndNameProps = {
  emailType: string;
  confirmationNumber: string;
  isReservation: boolean;
  orderNumber: string;
};

export const mapEmailTypeToIconAndName = (
  props: MapEmailTypeToIconAndNameProps
) => {
  const { emailType, confirmationNumber, isReservation, orderNumber } = props;

  const emailTypeMap = {
    "affiliate-sent-invoice-to-customer": {
      icon: <DollarIcon />,
      name: "Price Summary",
    },
    "customer-create-quote-to-affiliate": {
      icon: <QuotesIcon />,
      name: "Create Quote",
    },
    "customer-confirm-quote-to-affiliate": {
      icon: isReservation ? <CheckCircleIcon /> : <QuotesIcon />,
      name: isReservation ? "Confirmed reservation" : "Confirm Quote",
    },
    "customer-confirm-quote-to-customer": {
      icon: isReservation ? <CheckCircleIcon /> : <QuotesIcon />,
      name: isReservation ? "Confirmed reservation" : "Confirm Quote",
    },
    "customer-unconfirmed-request-submitted": {
      icon: <TripIcon fill="#FFF" />,
      name: `Reservation request (${orderNumber})`,
    },
    "customer-create-quote-to-customer": {
      icon: <QuotesIcon />,
      name: isReservation ? "Reservation received" : "Quote Received",
    },
    "operator-declined-priceless-booking": {
      icon: <CancelledFlightIcon />,
      name: "Declined reservation",
    },
    // DEPRECATED 10/02/2022
    "affiliate-update-invoice-to-customer": {
      icon: <DollarIcon />,
      name: "Update Price Summary",
    },
    "affiliate-update-price-summary-to-customer": {
      icon: <DollarIcon />,
      name: "Update Price Summary",
    },
    "affiliate-update-trip-to-customer": {
      icon: <TripIcon />,
      name: "Update Reservation",
    },
    "affiliate-sent-trip-to-customer": {
      icon: <TripIcon />,
      name: "Reservation",
    },
    "affiliate-sent-quote-to-customer": {
      icon: <QuotesIcon />,
      name: "Quote",
    },
    "operator-cancel-trip-to-customer": {
      icon: <CancelTripIcon color={grayDark} />,
      name: `Cancellation Email (${confirmationNumber})`,
    },
    "operator-sent-price-summary-to-customer": {
      icon: <DollarIcon />,
      name: "Price Summary",
    },
    "affiliate-sent-invoice-to-multiple-customers": {
      icon: <InvoiceActivityLogIcon />,
      name: "Invoice",
    },
    "operator-custom-email": {
      icon: <EmailIcon />,
      name: "Custom",
    },
    "customer-charge-processed": {
      icon: <DollarIcon />,
      name: "Charge Processed",
    },
  };

  return emailTypeMap[emailType] || { icon: <EmailIcon />, name: "Email" };
};
