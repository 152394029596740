import React from "react";

import {
  Box,
  ButtonBase,
  SxProps,
  Typography,
  TypographyVariant,
} from "@mui/material";
import { grayLight, grayMedium, moovsBlue } from "design-system/colors";
import { useAnalytics } from "globals/hooks";
import MoovsTooltip from "components/MoovsTooltip";

type ActionButtonProps = {
  label: string;
  icon: (props: any) => JSX.Element;
  onClick: (e: any) => void;
  disabled?: boolean;
  disableTooltipText?: string;
  analyticsName?: string;
  maxWidth?: number;
  minWidth?: number;
  sx?: SxProps;
  variant?: TypographyVariant;
};

function ActionButton(props: React.PropsWithChildren<ActionButtonProps>) {
  const {
    label,
    icon,
    disabled,
    analyticsName,
    onClick,
    disableTooltipText,
    maxWidth,
    minWidth = 70,
    sx,
    children,
    variant,
  } = props;
  const Icon = icon;

  // hooks
  const { track } = useAnalytics();

  // event handlers
  const handleActionButtonClick = (event: any) => {
    analyticsName && track(analyticsName);
    onClick(event);
  };

  return (
    <MoovsTooltip
      tooltipText={disableTooltipText}
      hidden={!disabled || !disableTooltipText}
    >
      <ButtonBase
        onClick={handleActionButtonClick}
        disabled={disabled}
        sx={{
          p: 1,
          maxWidth: 100,
          minWidth,
          borderRadius: "4px",
          "&:hover, &.Mui-focusVisible": {
            zIndex: 1,
            backgroundColor: grayLight,
          },
          "&.Mui-disabled": {
            color: grayMedium,
          },
          ...sx,
        }}
      >
        <Box maxWidth={maxWidth || "auto"}>
          <Icon color={disabled ? grayMedium : moovsBlue} />
          <Typography
            variant={variant}
            sx={{
              color: disabled ? grayMedium : moovsBlue,
              fontSize: "13px",
              lineHeight: "18px",
              letterSpacing: "-0.25px",
            }}
          >
            {label}
          </Typography>
        </Box>
        {children}
      </ButtonBase>
    </MoovsTooltip>
  );
}

export default ActionButton;
