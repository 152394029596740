import React from "react";

type EnterprisePlanIconProps = {
  size?: "large";
};

const EnterprisePlanIcon = (props: EnterprisePlanIconProps) => {
  if (props.size === "large") {
    return (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="48"
          height="48"
          rx="24"
          fill="#A444B7"
          fillOpacity="0.12"
        />
        <path
          d="M17.3367 31.7358C16.8954 31.3643 16.4911 30.9503 16.1301 30.5M31.8699 30.5C31.5089 30.9503 31.1046 31.3643 30.6633 31.7358M30.6633 16.2642C31.1046 16.6357 31.5089 17.0497 31.8699 17.5M16.1301 17.5C16.5351 16.9947 16.9947 16.5351 17.5 16.1301M24 13C25.8225 13 27.3 14.4775 27.3 16.3C27.3 18.1225 25.8225 19.6 24 19.6C22.1775 19.6 20.7 18.1225 20.7 16.3C20.7 14.4775 22.1775 13 24 13ZM35 24C35 25.8225 33.5225 27.3 31.7 27.3C29.8775 27.3 28.4 25.8225 28.4 24C28.4 22.1775 29.8775 20.7 31.7 20.7C33.5225 20.7 35 22.1775 35 24ZM24 28.4C25.8225 28.4 27.3 29.8775 27.3 31.7C27.3 33.5225 25.8225 35 24 35C22.1775 35 20.7 33.5225 20.7 31.7C20.7 29.8775 22.1775 28.4 24 28.4ZM19.6 24C19.6 25.8225 18.1225 27.3 16.3 27.3C14.4775 27.3 13 25.8225 13 24C13 22.1775 14.4775 20.7 16.3 20.7C18.1225 20.7 19.6 22.1775 19.6 24Z"
          stroke="#A444B7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="18" fill="#A444B7" fillOpacity="0.12" />
      <path
        d="M12.4472 24.4467C12.0794 24.1371 11.7425 23.7921 11.4417 23.4168M24.5582 23.4168C24.2574 23.7921 23.9204 24.1371 23.5526 24.4467M23.5526 11.5537C23.9204 11.8632 24.2574 12.2082 24.5582 12.5835M11.4417 12.5835C11.7792 12.1624 12.1622 11.7794 12.5833 11.4419M17.9999 8.8335C19.5187 8.8335 20.7499 10.0647 20.7499 11.5835C20.7499 13.1023 19.5187 14.3335 17.9999 14.3335C16.4811 14.3335 15.2499 13.1023 15.2499 11.5835C15.2499 10.0647 16.4811 8.8335 17.9999 8.8335ZM27.1666 18.0002C27.1666 19.5189 25.9354 20.7502 24.4166 20.7502C22.8978 20.7502 21.6666 19.5189 21.6666 18.0002C21.6666 16.4814 22.8978 15.2502 24.4166 15.2502C25.9354 15.2502 27.1666 16.4814 27.1666 18.0002ZM17.9999 21.6668C19.5187 21.6668 20.7499 22.898 20.7499 24.4168C20.7499 25.9356 19.5187 27.1668 17.9999 27.1668C16.4811 27.1668 15.2499 25.9356 15.2499 24.4168C15.2499 22.898 16.4811 21.6668 17.9999 21.6668ZM14.3333 18.0002C14.3333 19.5189 13.102 20.7502 11.5833 20.7502C10.0645 20.7502 8.83325 19.5189 8.83325 18.0002C8.83325 16.4814 10.0645 15.2502 11.5833 15.2502C13.102 15.2502 14.3333 16.4814 14.3333 18.0002Z"
        stroke="#A444B7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EnterprisePlanIcon;
