import React from "react";

import { Card, Box, Typography } from "@mui/material";

import { Trip } from "../../../../types";
import { grayDark } from "../../../../design-system/colors";
import { deriveIsFarmee } from "globals/utils/farm";
import { getPassenger } from "globals/utils/passenger";

type PassengerBlockProps = { trip: Trip };

function PassengerBlock(props: PassengerBlockProps) {
  const { trip } = props;
  const { contact, temporaryPassenger } = trip;

  const isFarmee = trip.routes.some(
    (route) => route.isFarmedRoute && deriveIsFarmee(route)
  );

  const passenger = getPassenger(contact, temporaryPassenger, isFarmee);

  // render methods
  const renderFullPassengerInfo = () => (
    <Box mt={1} data-testid="full-passenger-info">
      <Typography variant="subtitle2">{passenger.name}</Typography>
      <Typography variant="body2">{passenger.email}</Typography>
      <Typography variant="body2">{passenger.mobilePhone}</Typography>
    </Box>
  );

  const renderConcealedPassengerInfo = () => (
    <Box mt={1} data-testid="concealed-passenger-info">
      <Typography variant="subtitle2">{passenger.name}</Typography>
    </Box>
  );

  return (
    <Box width="100%">
      <Card variant="outlined">
        <Box display="flex" flexDirection="column" p={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" style={{ color: grayDark }}>
              Passenger
            </Typography>
          </Box>
          {isFarmee
            ? renderConcealedPassengerInfo()
            : renderFullPassengerInfo()}
        </Box>
      </Card>
    </Box>
  );
}

export default PassengerBlock;
