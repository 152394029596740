import React from "react";
import { Control, Controller } from "react-hook-form";

import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { black, grayLight, white } from "design-system/colors";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  padding: "6px",
  width: "100%",
  justifyContent: "space-between",
  "& .MuiToggleButtonGroup-grouped": {
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 4,
    },
    "&:first-of-type": {
      borderRadius: 4,
    },
  },
});

const StyledToggleButton = styled(ToggleButton)({
  padding: "12px 16px",
  width: "100%",
  flexWrap: "nowrap",
  color: black,
  "&:hover": {
    background: "none",
  },
  "&.Mui-selected": {
    color: black,
    backgroundColor: white,
    "&:hover": {
      backgroundColor: white,
    },
  },
});

type RHFToggleButtonsAlternativeProps = {
  name: string;
  control: Control<any>;
  toggleButtons: {
    ariaLabel: string;
    value: boolean;
    text: string;
  }[];
  width?: string;
};

function RHFToggleButtonsAlternative(props: RHFToggleButtonsAlternativeProps) {
  const { name, control, toggleButtons, width } = props;
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ...otherFields } }) => (
          <Box
            display="flex"
            alignContent="center"
            justifyContent="space-around"
            height="54px"
            width={width || "196px"}
            sx={{
              backgroundColor: grayLight,
              borderRadius: "6px",
            }}
          >
            <StyledToggleButtonGroup
              exclusive
              aria-label="toggle-button-group"
              {...otherFields}
              onChange={(e, value) => {
                if (value !== null) {
                  onChange(value);
                }
              }}
            >
              {toggleButtons.map((button) => (
                <StyledToggleButton
                  disableRipple
                  key={button.ariaLabel}
                  aria-label={button.ariaLabel}
                  value={button.value}
                >
                  <Typography variant="body2">{button.text}</Typography>
                </StyledToggleButton>
              ))}
            </StyledToggleButtonGroup>
          </Box>
        )}
      />
    </>
  );
}

export default RHFToggleButtonsAlternative;
