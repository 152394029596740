import React from "react";

import { Box, Typography } from "@mui/material";

import ContactOrAffiliateOrCompanyEmailChipAutoComplete from "components/autocompletes/EmailRecipientChipAutoComplete";
import { EmailModeEnum } from "types";
import EmailModeRadioButtons from "./components";
import { EmailRecipient } from "components/SendEmailDialog/SendEmailDialog";

type ToCcEmailFieldsProps = {
  mode: EmailModeEnum;
  onModeChange: (mode: EmailModeEnum) => void;
  toEmails: EmailRecipient[];
  ccEmails: EmailRecipient[];
  onToEmailsChange: (emails: EmailRecipient[]) => void;
  onCcEmailsChange: (emails: EmailRecipient[]) => void;
};

function ToCcEmailFields(props: ToCcEmailFieldsProps) {
  const {
    mode,
    onModeChange,
    toEmails,
    ccEmails,
    onToEmailsChange,
    onCcEmailsChange,
  } = props;

  return (
    <Box>
      {/* Email mode toggles */}
      {mode !== EmailModeEnum.Invoice && mode !== EmailModeEnum.Custom && (
        <EmailModeRadioButtons mode={mode} onModeChange={onModeChange} />
      )}

      <Box display="flex" flexDirection="column" mt={1}>
        {/* To autocomplete */}
        <Box display="flex" my={1}>
          <Typography variant="subtitle2" mt={1.5}>
            To:
          </Typography>
          <ContactOrAffiliateOrCompanyEmailChipAutoComplete
            emails={toEmails}
            onEmailChange={onToEmailsChange}
          />
        </Box>

        {/* Cc autocomplete */}
        <Box display="flex" my={1}>
          <Typography variant="subtitle2" mt={1.5}>
            Cc:
          </Typography>
          <ContactOrAffiliateOrCompanyEmailChipAutoComplete
            emails={ccEmails}
            onEmailChange={onCcEmailsChange}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ToCcEmailFields;
