import React from "react";

import { Box } from "@mui/material";

import chartData from "./utils/data";
import SubscriptionPlansGridChart from "../utils/SubscriptionPlansGridChart";

function SubscriptionPlansInfo() {
  return (
    <Box my={3}>
      <SubscriptionPlansGridChart chartData={chartData} />
    </Box>
  );
}

export default SubscriptionPlansInfo;
