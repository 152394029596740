import React from "react";

import { Typography, Button, Box } from "@mui/material";
import { granite, black } from "../../design-system/colors";

type EmptyListProps = {
  title: string;
  copy: string;
  ctaCopy?: string;
  imageUrl?: string;
  imageWidth?: number;
  imageHeight?: number;
  onCreateClick?: () => void;
};

function EmptyList(props: EmptyListProps) {
  const {
    title,
    copy,
    ctaCopy,
    onCreateClick,
    imageUrl,
    imageWidth = 140,
    imageHeight = 140,
  } = props;

  const image = imageUrl ? (
    <img
      src={imageUrl}
      alt=""
      style={{
        width: imageWidth,
        height: imageHeight,
        marginBottom: "24px",
      }}
    />
  ) : null;

  return (
    <Box display="flex" flexDirection="column" flex="1" alignItems="center">
      {image}
      <Typography
        variant="h2"
        sx={{ color: black, fontWeight: 500, textAlign: "center" }}
      >
        {title}
      </Typography>
      <Box mt={1} mb={3} maxWidth={410} textAlign="center">
        <Typography variant="body1" sx={{ color: granite }}>
          {copy}
        </Typography>
      </Box>
      {ctaCopy && onCreateClick && (
        <Button variant="contained" color="primary" onClick={onCreateClick}>
          {ctaCopy}
        </Button>
      )}
    </Box>
  );
}

export default EmptyList;
