/**
 * @file Layout.tsx
 * Top and SideNav HOC that wraps operator-app
 *
 * components:
 *   Layout
 */

import React, {
  useState,
  useCallback,
  ReactNode,
  useEffect,
  useMemo,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import some from "lodash/some";

import { Box } from "@mui/material/";

import TopBar from "./TopBar/TopBar";
import ChatPanel from "components/chat/ChatPanel";
import theme, { CHAT_PANEL_WIDTH } from "theme";
import { useQueryParams } from "globals/hooks/useQueryParams";
import {
  useAnalytics,
  useCurrentUser,
  useLayout,
  usePrevious,
} from "globals/hooks";
import SettingsSideNav from "./SideNav/SettingsSideNav";
import SideNavigation from "./SideNav/SideNavigation";
import { HelpFAB } from "./SideNav/components/HelpFAB";

const styles = {
  content: {
    display: "flex",
    height: "100%",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  "content--shift": {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: CHAT_PANEL_WIDTH,
  },
};

type LayoutProps = {
  children: ReactNode;
};

function Layout(props: LayoutProps) {
  const { children } = props;

  // hooks
  const location = useLocation();
  const history = useHistory();
  const queryParams = useQueryParams();
  const { topBarHeight } = useLayout();
  const { track } = useAnalytics();
  const currentUser = useCurrentUser();

  // state
  const [mobileOpen, setMobileOpen] = useState(false);
  const [chatPanelOpen, setChatPanelOpen] = useState(
    location.search.includes("conversationId")
  );
  const prevChatPanelOpen = usePrevious(chatPanelOpen);

  // event handlers
  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prev) => !prev);
  }, []);

  const handleChatPanelToggle = useCallback(() => {
    track("chat_opened");
    setChatPanelOpen((prevState) => !prevState);
  }, [track]);

  // effects
  // react to chatPanelToggle
  useEffect(() => {
    if (prevChatPanelOpen === chatPanelOpen) {
      return;
    }

    if (!chatPanelOpen) {
      queryParams.delete("chatPanel");
      history.replace({ search: queryParams.toString() });
    } else {
      history.push({
        pathname: `${history.location.pathname}`,
        search: "?chatPanel=true&" + queryParams.toString(),
      });
    }
  }, [chatPanelOpen, history, prevChatPanelOpen, queryParams]);

  // derived state
  const isWebsitePage = location.pathname.includes("website");
  const isSettingsPage = location.pathname.includes("settings");

  const userAccessPermissions = useMemo(() => {
    return currentUser?.accessPermissions || [];
  }, [currentUser]);

  const isChatAccessible = useMemo(() => {
    return some(
      userAccessPermissions,
      (permission) => permission.path === "chat"
    );
  }, [userAccessPermissions]);

  const chatPanelOpenAndAccessible = chatPanelOpen && isChatAccessible;

  return (
    <Box height="100%">
      <TopBar
        settingsPage={isSettingsPage || isWebsitePage}
        onDrawerToggle={handleDrawerToggle}
        chatPanelOpen={chatPanelOpenAndAccessible}
        onChatPanelToggle={handleChatPanelToggle}
        isChatAccessible={isChatAccessible}
      />
      <Box
        display="flex"
        flex="1"
        flexDirection="row"
        height={`calc(100% - ${topBarHeight}px)`}
      >
        {/* Navigation */}
        <Box>
          {isSettingsPage || isWebsitePage ? (
            <SettingsSideNav
              mobileOpen={mobileOpen}
              onDrawerToggle={handleDrawerToggle}
            />
          ) : (
            <SideNavigation
              mobileOpen={mobileOpen}
              onDrawerToggle={handleDrawerToggle}
            />
          )}
        </Box>

        {/* Main Content */}
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          width="100%"
          height="100%"
        >
          <Box
            component="main"
            sx={[
              styles.content,
              chatPanelOpenAndAccessible && styles["content--shift"],
            ]}
          >
            {children}
          </Box>
        </Box>

        {/* Chat Panel */}
        {isChatAccessible && (
          <Box display="flex" flexShrink={0} zIndex={theme.zIndex.drawer + 1}>
            <ChatPanel
              open={chatPanelOpenAndAccessible}
              onClose={handleChatPanelToggle}
            />
          </Box>
        )}
      </Box>

      <HelpFAB />
    </Box>
  );
}

export default Layout;
