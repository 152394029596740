import React from "react";

import { Box, Typography } from "@mui/material";

import warningCircle from "design-system/illustrations/warningCircle.svg";
import theme from "theme";

function DriverPayoutUnavaliableView() {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" m={7}>
      <img src={warningCircle} alt="" />
      <Typography variant="h4" style={{ marginTop: theme.spacing(2) }}>
        Not Available
      </Typography>
      <Typography variant="body2" style={{ textAlign: "center" }}>
        Driver Payout not available for farmed out trips
      </Typography>
    </Box>
  );
}
export default DriverPayoutUnavaliableView;
