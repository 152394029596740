import React from "react";

import { Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import RoundChip from "design-system/components/chips/RoundChip";
import { tripGridStatusLabelColors } from "design-system/colors";
import { PaymentStatus, RequestStage } from "types";

function StatusColumn(props: GridRenderCellParams<string>) {
  const { row } = props;

  const status =
    row.request.stage === RequestStage.Quote
      ? RequestStage.Quote
      : row.cancelledAt
      ? "CANCELLED"
      : row.paymentStatus === PaymentStatus.Partial
      ? PaymentStatus.NotPaid
      : row.paymentStatus;

  return (
    <Box display="flex" justifyContent="center">
      <RoundChip {...tripGridStatusLabelColors[status]} />
    </Box>
  );
}

export default StatusColumn;
