import React from "react";
import first from "lodash/first";

import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { FarmRelationshipEnum, Route } from "types";
import { currency } from "globals/utils/helpers";

function TotalAmountColumn(props: GridRenderCellParams<string>) {
  const { row } = props;

  const route = first(row.routes as Route[]);
  const isFarmee = route.farmRelationship === FarmRelationshipEnum.Farmee;

  const totalAmount = isFarmee
    ? route.farmeePricing?.totalAmt
    : row.totalAmount;

  return <Typography variant="body2">{currency(totalAmount || 0)}</Typography>;
}

export default TotalAmountColumn;
