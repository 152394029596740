import React from "react";

import { Box, CircularProgress, Typography } from "@mui/material";

import DownloadButton from "components/buttons/DownloadButton";

type PayoutsTitleBlockProps = {
  showPayoutDownloadSection: boolean;
  hasIntervalDates: boolean;
  payoutInterval: { startDate: number; endDate: number };
};

function PayoutsTitleBlock(props: PayoutsTitleBlockProps) {
  const { showPayoutDownloadSection, hasIntervalDates, payoutInterval } = props;

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography sx={{ fontWeight: 600 }} variant="subtitle1">
        Payouts
      </Typography>
      {showPayoutDownloadSection &&
        (!hasIntervalDates ? (
          <CircularProgress />
        ) : (
          <DownloadButton
            buttonText="Payouts Report"
            endpoint="/reports/stripe-payouts/download"
            analyticsName="payouts_downloadReport"
            params={payoutInterval}
          />
        ))}
    </Box>
  );
}

export default PayoutsTitleBlock;
