import React from "react";

import { Box, Typography } from "@mui/material";

import { grayDark } from "design-system/colors";
import { currency } from "globals/utils/helpers";
import { TripChargeDetails } from "../types";

type TripChargesBreakdownProps = {
  tripChargeDetailsList: TripChargeDetails[];
};

// aligning column headers with charge contents
const leftColumnPositioning = { flex: 1 };
const midColumnPositioning = { flex: 2, alignItems: "flex-end" };
const rightColumnPositioning = { flex: 1, alignItems: "flex-end" };

function TripChargesBreakdown(props: TripChargesBreakdownProps) {
  const { tripChargeDetailsList } = props;

  return (
    <div>
      <Box display="flex" flexDirection="row" alignItems="flex-end" mb={2}>
        <Box sx={leftColumnPositioning}></Box>
        <Box sx={midColumnPositioning}>
          <Typography variant="overline">Amount Due</Typography>
        </Box>
        <Box sx={rightColumnPositioning}>
          <Typography variant="overline">Charge</Typography>
        </Box>
      </Box>

      {tripChargeDetailsList.map((tripCharge, i) => {
        const { amountDue, amountToCharge, tripNumber } = tripCharge;

        const isTripPaid = amountDue === 0;

        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
            mb={2}
            key={i}
          >
            <Box sx={leftColumnPositioning}>
              {tripNumber && (
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: 600,
                  }}
                  {...(isTripPaid && { style: { color: grayDark } })}
                >{`Trip ${i + 1} (- ${tripNumber})`}</Typography>
              )}
            </Box>
            <Box sx={midColumnPositioning}>
              <Typography
                variant="subtitle2"
                {...(isTripPaid && { style: { color: grayDark } })}
              >
                {currency(amountDue)}
              </Typography>
            </Box>
            <Box {...rightColumnPositioning}>
              <Typography
                variant="subtitle2"
                {...(isTripPaid && { style: { color: grayDark } })}
              >
                {currency(amountToCharge) || ""}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </div>
  );
}

export default TripChargesBreakdown;
