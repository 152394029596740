import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { Box, MenuItem, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useAnalytics, useSnackbar } from "globals/hooks";
import {
  CREATE_VEHICLE_MUTATION,
  LOAD_VEHICLE_TYPES_QUERY,
} from "globals/graphql";
import { white } from "design-system/colors";
import {
  getVehicleTypeNameByTypeSlug,
  vehicleIconByIconCategory,
} from "globals/utils/vehicleTypes";
import { VehicleType } from "types";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type FirstVehicleFieldsProps = {
  setActiveStep: React.Dispatch<React.SetStateAction<5>>;
};

function FirstVehicleFields(props: FirstVehicleFieldsProps) {
  const { setActiveStep } = props;

  // state
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    typeSlug: "",
    capacity: "",
  });
  const [nameError, setNameError] = useState(false);
  const [typeSlugError, setTypeSlugError] = useState(false);
  const [capacityError, setCapacityError] = useState(false);

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // query
  const { data: vehicleTypesData } = useQuery(LOAD_VEHICLE_TYPES_QUERY);

  // derived state
  const vehicleTypes = vehicleTypesData ? vehicleTypesData.vehicleTypes : [];
  const vehicleIcons = vehicleIconByIconCategory();

  // mutation
  const [createVehicle] = useMutation(CREATE_VEHICLE_MUTATION, {
    onCompleted: () => {
      setLoading(false);
      track("onboarding_vehicleCreated");
      setActiveStep(5);
    },
    onError: (error) => {
      setLoading(false);

      const errorMessage = getErrorMessage(error) || error.message;

      snackbar.error(errorMessage);
    },
    refetchQueries: ["Operator"],
  });

  const resetErrors = (targetName: string) => {
    if (targetName === "name") setNameError(false);
    if (targetName === "typeSlug") setTypeSlugError(false);
    if (targetName === "capacity") setCapacityError(false);
  };

  const isFormValid = () => {
    let showErrorMessage = false;

    if (!data.name) {
      setNameError(true);
      showErrorMessage = true;
    }

    if (!data.typeSlug) {
      setTypeSlugError(true);
      showErrorMessage = true;
    }

    if (!data.capacity || Number(data.capacity) <= 0) {
      setCapacityError(true);
      showErrorMessage = true;
    }

    if (showErrorMessage) {
      snackbar.error("Please fill all of the required fields", {
        hideBoxShadow: true,
      });

      return false;
    }

    return true;
  };

  const handleOnSubmit = () => {
    if (!isFormValid()) return;

    setLoading(true);

    createVehicle({
      variables: {
        input: {
          ...data,
          capacity: Number(data.capacity),
          // other required fields
          available: true,
          settings: {
            weekends: [],
          },
        },
      },
    });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleOnSubmit();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetErrors(e.target.name);

    setData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  // effects
  useEffect(() => {
    track("onboarding_vehicleInitiated");
  }, [track]);

  return (
    <>
      <TextField
        fullWidth
        required
        error={nameError}
        variant="outlined"
        value={data.name}
        label="Vehicle Name"
        name="name"
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        sx={{ backgroundColor: white }}
      />
      <Box mt={1.5}>
        <TextField
          fullWidth
          required
          error={typeSlugError}
          variant="outlined"
          value={data.typeSlug}
          label="Vehicle Type"
          name="typeSlug"
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          sx={{ backgroundColor: white }}
          select
          SelectProps={{
            renderValue: (value) =>
              getVehicleTypeNameByTypeSlug(value, vehicleTypes),
          }}
        >
          {vehicleTypes.map(
            ({ typeName, typeSlug, iconCategory }: VehicleType) => (
              <MenuItem key={typeSlug} value={typeSlug}>
                <Box mr={1.5} position="relative" top="2px">
                  {iconCategory && vehicleIcons[iconCategory]}
                </Box>
                {typeName}
              </MenuItem>
            )
          )}
        </TextField>
      </Box>
      <Box mt={1.5}>
        <TextField
          fullWidth
          required
          error={capacityError}
          variant="outlined"
          value={data.capacity}
          label="Passenger Capacity"
          name="capacity"
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          sx={{ backgroundColor: white }}
          type="number"
        />
      </Box>
      <Box mt={1.5}>
        <Box display="flex" flexDirection="column" alignItems="center" my={3}>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            fullWidth
            onClick={handleOnSubmit}
          >
            Continue
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
}

export default FirstVehicleFields;
