import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Box, Typography, Button } from "@mui/material";

import EmptyList from "components/globals/EmptyList";
import UpgradePlanDialog from "components/UpgradePlanDialog";
import CreatePromoCodeDialog from "./components/CreatePromoCodeDialog/CreatePromoCodeDialog";
import { useAnalytics, useOperator, useScreenSize } from "globals/hooks";
import { PlanVariant } from "types";
import { grayDark } from "design-system/colors";
import PromoCodesList from "./components/PromoCodesList";

type PromoCodesTabProps = {};

function PromoCodesTab(props: PromoCodesTabProps) {
  // hooks
  const { track } = useAnalytics();
  const operator = useOperator();
  const history = useHistory();
  const { isSmallTabletView } = useScreenSize();

  // state
  const [createPromoCodeDialogOpen, setCreatePromoCodeDialogOpen] =
    useState(false);
  const [upgradePlanDialogOpen, setUpgradePlanDialogOpen] = useState(false);

  const promoCodes = operator?.settings?.promoCodes || [];

  // derived state
  const creationLimitIsReached =
    (operator?.plan === PlanVariant.Free ||
      operator?.plan === PlanVariant.Standard) &&
    !!promoCodes.length;

  // event handlers
  const handleCreatePromoCodeClick = () => {
    if (creationLimitIsReached) {
      setUpgradePlanDialogOpen(true);
      track("promoCode_createInitiated", { planRestriction: "restricted" });
      return;
    }

    setCreatePromoCodeDialogOpen(true);
    track("promoCode_createInitiated");
  };

  const handleUpgradePlanClick = () => {
    track("promoCode_upgradePlan");
    history.push("/settings/billing/plans");
  };

  const handleCreatePromoCodeDialogClose = () =>
    setCreatePromoCodeDialogOpen(false);

  const createButtonCtaCopy = "Create Promo Code";

  return (
    <>
      {!promoCodes.length ? (
        <>
          <Typography variant="h3">Promo Codes</Typography>
          <Box
            height="calc(100vh - 170px)"
            display="flex"
            justifyContent="center"
            alignItems="center"
            mx={1}
          >
            <EmptyList
              title="NO PROMO CODES CREATED"
              copy="Create promo codes that booking contacts can enter on the Customer Portal to receive discounts."
              ctaCopy={createButtonCtaCopy}
              onCreateClick={handleCreatePromoCodeClick}
            />
          </Box>
        </>
      ) : (
        <Box>
          <Box
            display="flex"
            flexDirection={isSmallTabletView ? "column" : "row"}
            {...(!isSmallTabletView && {
              justifyContent: "space-between",
              height: "46px",
              alignItems: "center",
              my: 1,
            })}
          >
            <Box display="flex" flexDirection={"column"}>
              <Typography variant="h3">Promo Codes</Typography>
              <Box mt={1} {...(!isSmallTabletView && { width: "466px" })}>
                <Typography variant="body2" sx={{ color: grayDark }}>
                  Generate promo codes that customers can enter on the Customer
                  Portal to receive discounts.
                </Typography>
              </Box>
            </Box>
            <Button
              {...(isSmallTabletView && { sx: { mt: 2 } })}
              variant="contained"
              color="primary"
              onClick={handleCreatePromoCodeClick}
            >
              {createButtonCtaCopy}
            </Button>
          </Box>
          <Box mt={3}>
            <PromoCodesList promoCodes={promoCodes} />
          </Box>
        </Box>
      )}
      <UpgradePlanDialog
        open={upgradePlanDialogOpen}
        onUpgrade={handleUpgradePlanClick}
        onClose={() => setUpgradePlanDialogOpen(false)}
        body={
          <Typography variant="body2">
            You must upgrade your plan in order to{" "}
            <strong>create multiple promo codes</strong>.
          </Typography>
        }
      />
      <CreatePromoCodeDialog
        open={createPromoCodeDialogOpen}
        onClose={handleCreatePromoCodeDialogClose}
      />
    </>
  );
}

export default PromoCodesTab;
