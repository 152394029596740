import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import ActionButton from "design-system/components/ActionButton";
import { EditIcon } from "design-system/icons";
import { useAnalytics } from "globals/hooks";
import { FarmedRouteStatusEnum, Route } from "types";
import { deriveIsFarmee, deriveIsFarmor } from "globals/utils/farm";

type EditTripActionButtonProps = {
  requestId: string;
  tripId: string;
  isShuttleReservation?: boolean;
  route?: Route;
};

function EditTripActionButton(props: EditTripActionButtonProps) {
  const { requestId, tripId, isShuttleReservation, route } = props;

  // hooks
  const history = useHistory();
  const location = useLocation();
  const { track } = useAnalytics();

  // derived state
  const isFarmee = deriveIsFarmee(route);
  const isFarmor = deriveIsFarmor(route);

  // event handlers
  const handleEditTripClick = () => {
    track("reservation_editTripOpened", {
      reservation_type: isFarmor
        ? "Farm-out"
        : isFarmee
        ? "Farm-in"
        : "Standard",
    });
    history.push(
      `/reservations/${
        isShuttleReservation ? `shuttle/${requestId}` : requestId
      }/update/${tripId}/${location.search}`
    );
  };

  return (
    <ActionButton
      icon={EditIcon}
      label="Edit"
      maxWidth={40}
      onClick={handleEditTripClick}
      disabled={route?.farmStatus === FarmedRouteStatusEnum.Pending}
    />
  );
}

export default EditTripActionButton;
