import React, { useCallback } from "react";

import { Box } from "@mui/material";

import { Request, Trip } from "types";
import {
  useSuggestedAddressInfo,
  useUpdateLuggageAmount,
} from "components/requests/update/hooks";
import PassengerInfoUpdateBlock from "../../../PassengerInfoUpdateBlock";
import TripInfoUpdateBlock from "../../../TripInfoUpdateBlock";
import TripDetailsUpdateBlock from "../../../TripDetailsUpdateBlock";
import { LuggageFields } from "components/requests/luggage/types";

type TripDetailsProps = {
  trip: Trip;
  request: Request;
  tripRefetch: () => void;
  setSaveIndicatorState: (
    saveState: "loading" | "default" | "saved" | "error"
  ) => void;
};

function TripDetails(props: TripDetailsProps) {
  const { trip, request, tripRefetch, setSaveIndicatorState } = props;

  // hooks
  const { suggestedAddressInfo } = useSuggestedAddressInfo({ request });
  const { updateLuggageAmount } = useUpdateLuggageAmount({
    setSaveIndicatorState,
  });

  // derived state
  const routeId = trip.routes[0].id;

  // callback
  const adjustLuggageAmount = useCallback(
    async (luggageAmount: LuggageFields) => {
      await updateLuggageAmount(routeId, luggageAmount);
      tripRefetch();
    },
    [updateLuggageAmount, routeId, tripRefetch]
  );

  return (
    <Box>
      <PassengerInfoUpdateBlock
        tripId={trip.id}
        contact={trip.contact}
        bookingContact={trip.request.bookingContact}
        temporaryPassenger={trip.tempPassenger}
        refetch={tripRefetch}
        setSaveIndicatorState={setSaveIndicatorState}
      />
      <TripInfoUpdateBlock
        trip={trip}
        setSaveIndicatorState={setSaveIndicatorState}
        suggestedAddressInfo={suggestedAddressInfo}
      />
      <TripDetailsUpdateBlock
        trip={trip}
        setSaveIndicatorState={setSaveIndicatorState}
        adjustLuggageAmount={adjustLuggageAmount}
        sectionName="Additional Info"
      />
    </Box>
  );
}

export default TripDetails;
