import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import RemoveDialog from "components/RemoveDialog";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { FarmedRouteStatusEnum } from "types";
import {
  UPDATE_FARMED_ROUTE_STATUS_MUTATION,
  LOAD_REQUEST_QUERY,
} from "globals/graphql";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type CancelFarmOutDialogProps = {
  open: boolean;
  onClose: () => void;
  routeId: string;
  requestId: string;
};

function CancelFarmOutDialog(props: CancelFarmOutDialogProps) {
  const { open, onClose, routeId, requestId } = props;

  // state
  const [isSaving, setIsSaving] = useState(false);

  // hooks
  const { track } = useAnalytics();
  const snackbar = useSnackbar();

  // mutations
  const [updateFarmedRouteStatus] = useMutation(
    UPDATE_FARMED_ROUTE_STATUS_MUTATION,
    {
      onCompleted() {
        snackbar.success("Farm-Out Cancelled");
        setIsSaving(false);
        onClose();
      },
      onError(error) {
        const errorMessage =
          getErrorMessage(error) || "Error cancelling Farm-Out";

        snackbar.error(errorMessage);
        setIsSaving(false);
      },
      refetchQueries: [
        {
          query: LOAD_REQUEST_QUERY,
          variables: {
            id: requestId,
          },
        },
      ],
    }
  );

  // event handlers
  const handleCancelFarmout = () => {
    track("farmedRoute_farmorCancelSent");
    setIsSaving(true);

    updateFarmedRouteStatus({
      variables: {
        input: {
          routeId,
          farmedRouteStatus: FarmedRouteStatusEnum.Cancelled,
        },
      },
    });
  };

  return (
    <RemoveDialog
      open={open}
      onClose={onClose}
      onRemove={handleCancelFarmout}
      title="Cancel Farm-Out"
      body="Are you sure you want to cancel this Farm-Out?"
      removeButtonText="Yes, Cancel Farm-Out"
      cancelButtonText="No, Keep"
      isSaving={isSaving}
    />
  );
}

export default CancelFarmOutDialog;
