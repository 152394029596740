import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import moment, { Moment } from "moment";
import mapKeys from "lodash/mapKeys";
import fromPairs from "lodash/fromPairs";
import toPairs from "lodash/toPairs";
import sortBy from "lodash/sortBy";
import isEmpty from "lodash/isEmpty";

import { Box, CircularProgress, Typography, Card, Grid } from "@mui/material";

import DownloadButton from "components/buttons/DownloadButton";
import TableGenerator from "components/dashboard/Charts/TableGenerator/TableGenerator";
import DashboardChartGenerator from "components/dashboard/DashboardChartGenerator";
import { green } from "design-system/colors";
import { LOAD_DASHBOARD_QUERY } from "globals/graphql/dashboard.graphql";
import { currency } from "globals/utils/helpers";
import { useScreenSize } from "globals/hooks";
import DataUnavailableBlock from "./DataUnavailableBlock";
import { DashboardView } from "../DashboardPage";
import { TimeInterval } from "types";
import PieChart from "components/dashboard/Charts/PieChart";
import PaymentBreakdownEmptyBlock from "./PaymentBreakdownEmptyBlock";

type TotalReservationsViewProps = {
  startDate: Moment;
  endDate: Moment;
};

type TotalValueHeaderProps = {
  title: string;
  value: number;
};

function TotalValueHeader(props: TotalValueHeaderProps) {
  const { title, value } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Box {...(isMobileView ? { mb: 1 } : { mr: 2 })}>
      <Typography variant="h4">{title}</Typography>
      <Box color={green}>
        <Typography variant={isMobileView ? "h3" : "h2"}>
          {currency(value)}
        </Typography>
      </Box>
    </Box>
  );
}

function TotalReservationsView(props: TotalReservationsViewProps) {
  const { startDate, endDate } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // queries
  const { data, loading: isDashBoardDataLoading } = useQuery(
    LOAD_DASHBOARD_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        startDate,
        endDate,
      },
    }
  );

  const dashboardData = data?.loadReservationDashboard;

  // derived state
  const isDashBoardDataEmpty = dashboardData?.totalAmount === 0;
  // to display date Range selected on the chart
  const displayDateRange = useMemo(() => {
    const formattedStartDate = moment(startDate).format("MM/DD/YY");
    const formattedEndDate = moment(endDate).format("MM/DD/YY");

    return moment(startDate).isSame(endDate)
      ? formattedStartDate
      : `${formattedStartDate} - ${formattedEndDate}`;
  }, [startDate, endDate]);

  const barChartData = useMemo(() => {
    if (!dashboardData) return null;

    const { timeSeries, seriesInterval } = dashboardData;
    const format = seriesInterval === TimeInterval.Day ? "MM/DD/YY" : "MMM 'YY";

    // sort time series object keys
    const sorted = fromPairs(sortBy(toPairs(timeSeries), 0));

    return mapKeys(sorted, (_, key) => moment.utc(key).format(format));
  }, [dashboardData]);

  return (
    <>
      {isDashBoardDataLoading && (
        <Box
          display="flex"
          flex={1}
          alignContent="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
      {isDashBoardDataEmpty && !isDashBoardDataLoading && (
        <DataUnavailableBlock view={DashboardView.TOTAL_RESERVATIONS} />
      )}
      {!isDashBoardDataLoading && !isDashBoardDataEmpty && (
        <>
          <Card variant="outlined">
            {!!dashboardData && (
              <Box p={2}>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Box
                      display="flex"
                      flexDirection={isMobileView ? "column" : "row"}
                    >
                      <TotalValueHeader
                        title={"Total Reservations"}
                        value={dashboardData.totalAmount}
                      />

                      <TotalValueHeader
                        title={"Total Amount Paid"}
                        value={dashboardData.totalAmountPaid}
                      />

                      <TotalValueHeader
                        title={"Total Amount Due"}
                        value={dashboardData.totalAmountDue}
                      />
                    </Box>
                    {isMobileView && (
                      <Typography variant="body2" sx={{ mt: 2 }}>
                        {displayDateRange}
                      </Typography>
                    )}
                  </Box>
                  <Box display="flex" flexDirection="row" alignItems="baseline">
                    {!isMobileView && (
                      <Typography variant="body1">
                        {displayDateRange}
                      </Typography>
                    )}
                    <DownloadButton
                      buttonText="Download Report"
                      endpoint="/reports/total-reservations/download"
                      analyticsName="dashbord_reportingReservations"
                      params={{
                        startDate: startDate.toISOString(),
                        endDate: endDate.toISOString(),
                      }}
                    />
                  </Box>
                </Box>
                <DashboardChartGenerator barChartData={barChartData} />
              </Box>
            )}
          </Card>

          <Grid container spacing={3} sx={{ marginTop: 2 }}>
            {/* payments breakdown */}
            <Grid item xs={12} sm={4} mb={3}>
              <Card variant="outlined" sx={{ maxHeight: "536px" }}>
                <Box p={3}>
                  <TotalValueHeader
                    title={"Payments Breakdown"}
                    value={dashboardData?.totalAmountPaid}
                  />
                  <Box
                    height={"350px"}
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                    flex="1"
                    flexDirection="column"
                  >
                    {isEmpty(dashboardData?.paymentBreakdown) ? (
                      <PaymentBreakdownEmptyBlock />
                    ) : (
                      <PieChart
                        paymentBreakdownData={dashboardData?.paymentBreakdown}
                      />
                    )}
                  </Box>
                </Box>
              </Card>
            </Grid>

            {/* vehicle breakdown */}
            <Grid item xs={12} sm={4} mb={3}>
              <Card variant="outlined" sx={{ height: "100%" }}>
                <Box p={3}>
                  <TableGenerator
                    totalAmount={dashboardData?.totalAmount}
                    tableData={dashboardData?.vehicleBreakdown}
                    tableHeaderText="Vehicle Breakdown"
                  />
                </Box>
              </Card>
            </Grid>

            {/* order type breakdown */}
            <Grid item xs={12} sm={4} mb={3}>
              <Card variant="outlined" sx={{ height: "100%" }}>
                <Box p={3}>
                  <TableGenerator
                    totalAmount={dashboardData?.totalAmount}
                    tableData={dashboardData?.orderTypeBreakdown}
                    tableHeaderText="Order Type Breakdown"
                    hasTwoColumns
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default TotalReservationsView;
