import moment from "moment-timezone";

import { Invoice } from "types";

export default function getSmartDueDate(invoice: Invoice) {
  const todaysDate = moment();

  const dateDiff = todaysDate.diff(invoice?.dueDate, "days");
  if (Math.abs(dateDiff) >= 7) {
    return moment(invoice?.dueDate).format("MM/DD/YY");
  }
  return moment(invoice?.dueDate).fromNow();
}
