import * as yup from "yup";
import moment from "moment";

import { codeAndAmountSchema } from "../../../../CreatePromoCodeDialog/steps/codeAndAmount/schemaValidation";
import { limitsSchema } from "../../../../CreatePromoCodeDialog/steps/limits/schemaValidation";

const amountSchema = codeAndAmountSchema.omit(["name"]);

const validateEndDateOnOrAfterStartDate = (startDate, endDate) => {
  const formattedStartDate = moment.utc(startDate).format("YYYY-MM-DD");
  const formattedEndDate = moment.utc(endDate).format("YYYY-MM-DD");

  if (startDate && endDate) {
    return new Date(formattedEndDate) >= new Date(formattedStartDate);
  }
  return true;
};

const bookingDateEditSchema = yup.object({
  bookingStartDate: yup
    .date()
    .nullable()
    .typeError("Please add the Start Date")
    .test({
      name: "Validate Start Date",
      message: "Please add the Start Date",
      test: function () {
        const { bookingStartDate, bookingEndDate } = this.parent;

        if (bookingEndDate && !bookingStartDate) return false;
        return true;
      },
    }),
  bookingEndDate: yup
    .date()
    .nullable()
    .typeError("Please add the End Date")
    .test({
      name: "Validate End Date",
      message: "Please add the End Date",
      test: function () {
        const { bookingStartDate, bookingEndDate } = this.parent;

        if (bookingStartDate && !bookingEndDate) return false;
        return true;
      },
    })
    .test({
      name: "Validate end date is after start date",
      message: "End Date must be after Start Date",
      test: function () {
        const { bookingStartDate, bookingEndDate } = this.parent;

        return validateEndDateOnOrAfterStartDate(bookingStartDate, bookingEndDate);
      },
    }),
});

const tripDateEditSchema = yup.object({
  tripStartDate: yup
    .date()
    .nullable()
    .typeError("Please add the Start Date")
    .test({
      name: "Validate Start Date",
      message: "Please add the Start Date",
      test: function () {
        const { tripStartDate, tripEndDate } = this.parent;

        if (tripEndDate && !tripStartDate) return false;
        return true;
      },
    }),
  tripEndDate: yup
    .date()
    .nullable()
    .typeError("Please add the End Date")
    .test({
      name: "Validate End Date",
      message: "Please add the End Date",
      test: function () {
        const { tripStartDate, tripEndDate } = this.parent;

        if (tripStartDate && !tripEndDate) return false;
        return true;
      },
    })
    .test({
      name: "Validate end date is after start date",
      message: "End Date must be after Start Date",
      test: function () {
        const { tripStartDate, tripEndDate } = this.parent;

        return validateEndDateOnOrAfterStartDate(tripStartDate, tripEndDate);
      },
    }),
});

export const activeSchema = yup.object({
  active: yup
    .boolean()
    .required("Please make a selection")
    .typeError("Please make a selection"),
});

export const editPromoCodeSchema = yup
  .object({})
  .concat(amountSchema)
  .concat(bookingDateEditSchema)
  .concat(tripDateEditSchema)
  .concat(limitsSchema)
  .concat(activeSchema);
