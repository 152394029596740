import { PricelessBookingTarget } from "types";

type ToggleButton = {
  value: PricelessBookingTarget;
  label: string;
};

export const toggleButtons: ToggleButton[] = [
  {
    value: PricelessBookingTarget.All,
    label: "All Customers",
  },
  {
    value: PricelessBookingTarget.Specific,
    label: "Specific Contacts",
  },
];
