import React, { useRef } from "react";

import { TrackedAsset } from "pages/driverTracking/types";
import useActiveAssetIcon from "./hooks/useActiveAssetIcon";
import { useInitializeMap } from "./hooks/useInitializeMap";
import { useEmptyGarage } from "./hooks/useEmptyGarage";

type DriverTrackingGoogleMapProps = {
  trackedAssets: TrackedAsset[];
};

function DriverTrackingGoogleMap(props: DriverTrackingGoogleMapProps) {
  const { trackedAssets } = props;

  // hooks
  const googleMapRef = useRef();
  const { map } = useInitializeMap({ googleMapRef });

  useEmptyGarage({
    map,
    isTrackedDriversEmpty: trackedAssets.length === 0,
  });

  useActiveAssetIcon({ map, trackedAssets });

  return (
    <div
      style={{
        display: "block",
        width: "100%",
        height: "100%",
      }}
      id="map"
      ref={googleMapRef}
    />
  );
}

export default DriverTrackingGoogleMap;
