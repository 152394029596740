import React, { Dispatch, SetStateAction, useMemo } from "react";
import pick from "lodash/pick";

import { Box, MenuItem, Typography } from "@mui/material";

import {
  LabeledInlineInput,
  LabeledInlineInternationalPhoneNumberInput,
  LabeledInlineSelect,
} from "design-system/components/inputs";
import { UpdateUserInput, UpdateUserSettingsInput, User } from "types";
import {
  useAnalytics,
  useAutoSave,
  useScreenSize,
  useCurrentUser,
} from "globals/hooks";
import {
  UPDATE_USER_MUTATION,
  UPDATE_USER_SETTINGS_MUTATION,
} from "globals/graphql";
import { DefaultScreenUpdateDropdown } from "./components";

type UpdateMemberFormProps = {
  user: User;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function UpdateMemberForm(props: UpdateMemberFormProps) {
  const { setSaveIndicatorState } = props;

  // hook
  const { track } = useAnalytics();
  const { isMobileView } = useScreenSize();
  const currentUser = useCurrentUser();

  // state
  const [user, setUser] = useAutoSave<UpdateUserInput>({
    setSaveIndicatorState,
    mutation: UPDATE_USER_MUTATION,
    name: "member",
    incomingState: {
      ...pick(props.user, ["id", "firstName", "lastName", "mobilePhone"]),
      phoneCountryCode: props.user.phoneCountryCode,
      phoneCountryDialCode: props.user.phoneCountryDialCode,
      phoneCountryName: props.user.phoneCountryName,
      phoneCountryFormat: props.user.phoneCountryFormat,
      roleSlug: props.user.role.slug,
    },
  });

  const [userSettings, setUserSettings] = useAutoSave<UpdateUserSettingsInput>({
    setSaveIndicatorState,
    mutation: UPDATE_USER_SETTINGS_MUTATION,
    name: "member",
    incomingState: pick(props.user.settings, ["id", "defaultScreen"]),
    onComplete(data) {
      const { defaultScreen } = data.updateUserSettings.user.settings;

      track("members_defaultScreenUpdated", {
        defaultScreen,
      });
    },
  });

  // event handlers
  const handleUserInfoChange =
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setUser({ ...user, [key]: event.target.value });
    };

  const handleUserSettingsChange =
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserSettings({ ...userSettings, [key]: event.target.value });
    };

  const handleMemberPhoneInputChange = (value, country) => {
    setUser({
      ...user,
      mobilePhone: value,
      phoneCountryCode: country.countryCode,
      phoneCountryDialCode: country.dialCode,
      phoneCountryName: country.name,
      phoneCountryFormat: country.format,
    });
  };

  const isRoleDisabled = useMemo(() => {
    if (!currentUser?.role?.slug || !props.user?.id) {
      return true;
    }
    // Only owners can update roles
    if (currentUser.role.slug !== "owner") {
      return true;
    }

    // Users cannot update their own role
    if (currentUser.id === props.user.id) {
      return true;
    }

    return false;
  }, [currentUser, props.user.id]);

  // wait for useAutoSave to initialize
  if (!user) return null;

  return (
    <Box>
      {/* Member Details */}
      <Box mt={4} mb={2}>
        <Typography variant="h5">Member Details</Typography>
      </Box>
      <LabeledInlineInput
        required
        errorText="First name required"
        label="First Name"
        value={user.firstName}
        onChange={handleUserInfoChange("firstName")}
      />
      <LabeledInlineInput
        required
        errorText="Last name required"
        label="Last Name"
        value={user.lastName}
        onChange={handleUserInfoChange("lastName")}
      />
      <Box my={0.5}>
        <LabeledInlineInternationalPhoneNumberInput
          label="Mobile Phone"
          name="mobilePhone"
          value={props.user?.mobilePhoneInternational || ""}
          defaultEmptyValue=""
          onChange={handleMemberPhoneInputChange}
          dropdownWidth={isMobileView ? "400%" : "900%"}
        />
      </Box>
      <LabeledInlineInput
        required
        disabled // Cannot update email until we build feature to update via firebase auth
        errorText="Email required"
        label="Email Address"
        value={props.user.email}
        onChange={handleUserInfoChange("email")}
      />

      <LabeledInlineSelect
        label="Role"
        value={user.roleSlug || ""}
        onChange={handleUserInfoChange("roleSlug")}
        disabled={isRoleDisabled}
      >
        <MenuItem value={"owner"}>Owner</MenuItem>
        <MenuItem value={"admin"}>Admin</MenuItem>
        <MenuItem value={"member"}>Member</MenuItem>
      </LabeledInlineSelect>

      {/* default screen */}
      <DefaultScreenUpdateDropdown
        value={userSettings.defaultScreen}
        onChange={handleUserSettingsChange("defaultScreen")}
      />
    </Box>
  );
}

export default UpdateMemberForm;
