import React from "react";

import { Box, Card, CardActionArea, Typography } from "@mui/material";

import { Vehicle } from "../../types";
import { black } from "../../design-system/colors";
import AutomatedPricingIcon from "../../design-system/icons/info/AutomatedPricingIcon";
import WebsiteBrowserIcon from "../../design-system/icons/navigation/WebsiteBrowserIcon";

function VehiclesListItemMobile({
  vehicle,
  onClick,
}: {
  vehicle: Vehicle;
  onClick: (vehicleId: string) => void;
}) {
  const vehicleName = vehicle.name;
  const vehicleType = vehicle.vehicleType.typeName;
  const vehiclePlateNo = vehicle.licensePlate;
  const vehiclePic =
    vehicle.photos && vehicle.photos[0] && vehicle.photos[0].url;

  const styles = {
    vehiclePhoto: {
      borderRadius: "4px",
      width: 80, // 4:3 aspect ratio
      height: 60,
      objectFit: "cover",
    },
    carParam: {
      color: black,
      lineHeight: 1.35,
    },
  } as const;

  return (
    <Card variant="outlined">
      <CardActionArea disableRipple onClick={() => onClick(vehicle.id)}>
        <Box display="flex" flexDirection="column" flex="1" p={2}>
          {/* Vehicle Pic */}
          <Box display="flex">
            <Box
              component="img"
              alt="Vehicle Preview"
              src={vehiclePic}
              sx={{
                borderRadius: "4px",
                width: 80, // 4:3 aspect ratio
                height: 60,
                objectFit: "cover",
              }}
            />
          </Box>

          {/* Vehicle Name and Automated Price Icon */}
          <Box display="flex" flexDirection="column" mt={1}>
            <Box display="flex" flexDirection="row">
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                {vehicleName}
                {vehicle.enableBaseRateAutomation && (
                  <Box ml={1} display="inline">
                    <AutomatedPricingIcon size="small" />
                  </Box>
                )}
              </Typography>
            </Box>

            {/* Vehicle Type and Plate Number */}
            <Box display="flex">
              <Box display="flex" flexDirection="column" mt={1}>
                <Typography variant="overline">VEHICLE TYPE</Typography>
                <Typography variant="caption" sx={styles.carParam}>
                  {vehicleType}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" mt={1} ml={6}>
                <Typography variant="overline">PLATE NUMBER</Typography>
                <Typography variant="caption" sx={styles.carParam}>
                  {vehiclePlateNo}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box display="flex" flex="1" justifyContent="flex-end" mt="4px">
            {vehicle.publishedToDudaSite && (
              <Box ml={1}>
                <WebsiteBrowserIcon size="small" />
              </Box>
            )}
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default VehiclesListItemMobile;
