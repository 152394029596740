import React from "react";
import first from "lodash/first";

import { Box } from "@mui/system";

import { PaidNotPaid, Request, RequestStage, TripCategory } from "types";
import BookingContact from "../../../../../../components/contacts/bookingContact/BookingContact";
import { CompanyBlock, InfoPanel, OrderType } from "./components";
import { deriveBookingContact, deriveIsFarmee } from "globals/utils/farm";

const getRequestStatusText = (request: Request) => {
  const isUnconfirmedReservation =
    request.stage === RequestStage.Unconfirmed && !request.isDeclined;
  const isDeclinedReservation = request.isDeclined;
  if (isUnconfirmedReservation) return RequestStage.Unconfirmed;
  if (isDeclinedReservation) return "DECLINED";
  return request.paymentStatus;
};

type OrderSummaryProps = {
  request: Request;
};

const OrderSummary = (props: OrderSummaryProps) => {
  const { request } = props;

  // derived state
  const { tripCategory, routes } = first(request.trips);
  const canEditBookingContact = !deriveIsFarmee(first(routes));

  const isShuttleReservation = // shuttle reservations exclusively contain shuttle trips
    tripCategory === TripCategory.ShuttlePickUp ||
    tripCategory === TripCategory.ShuttleReturn;
  const shuttleCompanyId =
    isShuttleReservation && request.bookingContact.company?.id;

  const shouldRenderCompanyBlock =
    (request.company || request.bookingContact.company) &&
    !deriveIsFarmee(first(routes));

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row">
        <OrderType
          requestId={request.id}
          stage={request.stage}
          orderType={request.orderType}
        />

        <BookingContact
          requestId={request.id}
          requestStage={RequestStage.Reservation}
          bookingContact={deriveBookingContact(request)}
          canEditBookingContact={canEditBookingContact}
          companyId={request.company?.id || shuttleCompanyId}
        />
      </Box>

      {shouldRenderCompanyBlock && (
        <CompanyBlock
          requestId={request.id}
          isLinkedCompanyRequest={!!request.company}
          companyName={
            request.company?.name || request.bookingContact.company?.name
          }
          stage={request.stage}
          isShuttleReservation={isShuttleReservation}
        />
      )}

      <InfoPanel
        totalAmount={request.totalAmount}
        amountDue={request.amountDue}
        paymentStatus={getRequestStatusText(request) as PaidNotPaid}
      />
    </Box>
  );
};

export default OrderSummary;
