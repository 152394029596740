import React, { useState, ChangeEvent } from "react";
import { useMutation } from "@apollo/client";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import moment, { Moment } from "moment-timezone";
import InputMask from "react-input-mask";

import DatePicker from "@mui/lab/DatePicker";
import { Box, Typography, TextField } from "@mui/material";

import CreateDrawer from "../../globals/CreateDrawer";
import VehiclesBlock from "../general/VehiclesBlock";
import InsuranceAttachmentsBlock from "./InsuranceAttachmentsBlock";
import { InsurancePolicy, Vehicle } from "../../../types";
import {
  CREATE_INSURANCE_POLICY_MUTATION,
  LOAD_INSURANCE_POLICIES_QUERY,
} from "../../../globals/graphql";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import { isValidPhoneNumber } from "../../../globals/utils/helpers";
import { NumberFormatDollar } from "../../../design-system/components/inputs/NumberFormat";
import { CalendarIcon } from "../../../design-system/icons";
import { useAnalytics } from "../../../globals/hooks";

const initialInsurance = {
  insuranceCompany: "", // required
  policyNumber: "", // required
  startDate: null, // required
  endDate: null, // required
  coverageAmount: null, // required
  monthlyPremium: null, // required
  contactFirstName: "",
  contactLastName: "",
  contactPhone: "",
  contactEmail: "",
};

const initialErrors = {
  insuranceCompany: "",
  policyNumber: "",
  startDate: "",
  endDate: "",
  coverageAmount: "",
  monthlyPremium: "",
  contactPhone: "", // not required but validated
};

function CreateInsuranceDrawer() {
  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const { track } = useAnalytics();

  // state
  const [insurance, setInsurance] =
    useState<Partial<InsurancePolicy>>(initialInsurance);
  const [vehicles, setVehicles] = useState([]);
  const [attachments, setAttachments] = useState([]);

  const [errors, setErrors] = useState(initialErrors);
  const [insuranceSaveError, setInsuranceSaveError] = useState(false);

  const [submitDisabled, setSubmitDisabled] = useState(false);

  // mutations
  const [createInsurancePolicyMutation] = useMutation(
    CREATE_INSURANCE_POLICY_MUTATION,
    {
      refetchQueries: [{ query: LOAD_INSURANCE_POLICIES_QUERY }],
      onCompleted(data) {
        handleDrawerClose();
        snackbar.success(`Successfully created insurance policy!`);
        track("create_insurance_policy");
      },

      onError(error) {
        setSubmitDisabled(false);
        snackbar.error("Error creating insurance policy");
      },
    }
  );

  // event handlers
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInsurance({
      ...insurance,
      [event.target.name]: event.target.value,
    });
    setErrors({ ...errors, [event.target.name]: "" });
  };

  const handleDateInputChange = (key: string) => (date: Moment) => {
    setInsurance({
      ...insurance,
      [key]: moment(date),
    });

    setErrors({
      ...errors,
      [key]: "",
    });
  };

  const handleClickSave = () => {
    let errors = [];

    if (!insurance.insuranceCompany) {
      errors = [
        ...errors,
        { insuranceCompany: "Please enter insurance company" },
      ];
    }

    if (!insurance.policyNumber) {
      errors = [...errors, { policyNumber: "Please enter policy number" }];
    }

    if (!insurance.startDate?.isValid()) {
      errors = [...errors, { startDate: "Please enter a valid starting date" }];
    }

    if (!insurance.endDate?.isValid()) {
      errors = [...errors, { endDate: "Please enter a valid ending date" }];
    }

    if (!insurance.coverageAmount) {
      errors = [...errors, { coverageAmount: "Please enter coverage amount" }];
    }

    if (!insurance.monthlyPremium) {
      errors = [...errors, { monthlyPremium: "Please enter monthly premium" }];
    }

    if (insurance.contactPhone && !isValidPhoneNumber(insurance.contactPhone)) {
      errors = [...errors, { contactPhone: "Phone number must be 10 Digits" }];
    }

    if (errors.length > 0) {
      setErrors(errors.reduce((acc, value) => ({ ...acc, ...value }), {}));
      setInsuranceSaveError(true);
      return;
    }

    setInsuranceSaveError(false);
    setSubmitDisabled(true);

    createInsurancePolicyMutation({
      variables: {
        input: {
          ...insurance,
          coverageAmount: Number(insurance.coverageAmount),
          monthlyPremium: Number(insurance.monthlyPremium),
          insuranceAttachments: attachments,
          startDate: insurance.startDate.format("YYYY-MM-DD"),
          endDate: insurance.endDate.format("YYYY-MM-DD"),
          vehicles,
        },
      },
    });
  };

  const handleVehiclesChange = (vehicles: Partial<Vehicle>[]) => {
    setVehicles(vehicles.map(({ id }) => id));
  };

  const handleAttachmentsChange = (attachments: string[]) => {
    setAttachments(attachments);
  };

  const handleDrawerClose = () => history.push("/settings/insurance");

  return (
    <>
      <Helmet>
        <title>Create Insurance | Moovs</title>
      </Helmet>
      <CreateDrawer
        onClose={handleDrawerClose}
        pageLabel="Create New Policy"
        submitLabel="Save Policy"
        onSubmit={handleClickSave}
        saveError={insuranceSaveError}
        submitDisabled={submitDisabled}
      >
        <Box mt={4}>
          {/* General Details */}
          <Typography variant="h5">General Details</Typography>
          <Box display="flex" flexDirection="row" mt={3} mb={1}>
            <Box display="flex" flex="1" mr={0.75}>
              <TextField
                required
                fullWidth
                variant="outlined"
                label="Insurance Company"
                name="insuranceCompany"
                value={insurance.insuranceCompany || ""}
                onChange={handleInputChange}
                error={!!errors.insuranceCompany}
                helperText={errors.insuranceCompany}
              />
            </Box>
            <Box display="flex" flex="1" ml={0.75}>
              <TextField
                required
                fullWidth
                variant="outlined"
                name="policyNumber"
                label="Policy Number"
                value={insurance.policyNumber || ""}
                onChange={handleInputChange}
                error={!!errors.policyNumber}
                helperText={errors.policyNumber}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" mb={1}>
            <Box display="flex" flex="1" mr={0.75}>
              <DatePicker
                label="Starting Date"
                value={insurance.startDate}
                onChange={() => {}}
                onAccept={handleDateInputChange("startDate")}
                inputFormat="MM/DD/YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    required
                    variant="outlined"
                    name="dueDate"
                    error={!!errors.startDate}
                    helperText={errors.startDate}
                  />
                )}
                components={{
                  OpenPickerIcon: CalendarIcon,
                }}
              />
            </Box>
            <Box display="flex" flex="1" ml={0.75}>
              <DatePicker
                label="Ending Date"
                value={insurance.endDate}
                onChange={() => {}}
                onAccept={handleDateInputChange("endDate")}
                inputFormat="MM/DD/YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    required
                    variant="outlined"
                    name="dueDate"
                    error={!!errors.endDate}
                    helperText={errors.endDate}
                  />
                )}
                components={{
                  OpenPickerIcon: CalendarIcon,
                }}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" mb={4}>
            <Box display="flex" flex="1" mr={0.75}>
              <TextField
                required
                fullWidth
                label="Coverage Amount"
                name="coverageAmount"
                value={insurance.coverageAmount || ""}
                onChange={handleInputChange}
                error={!!errors.coverageAmount}
                helperText={errors.coverageAmount}
                InputProps={{
                  inputComponent: NumberFormatDollar as any,
                }}
              />
            </Box>
            <Box display="flex" flex="1" ml={0.75}>
              <TextField
                required
                fullWidth
                name="monthlyPremium"
                label="Monthly Premium"
                value={insurance.monthlyPremium || ""}
                onChange={handleInputChange}
                error={!!errors.monthlyPremium}
                helperText={errors.monthlyPremium}
                InputProps={{
                  inputComponent: NumberFormatDollar as any,
                }}
              />
            </Box>
          </Box>

          {/* Insurer Contact */}
          <Typography variant="h5">Insurer Contact</Typography>
          <Box display="flex" flexDirection="row" mt={3} mb={1}>
            <Box display="flex" flex="1" mr={0.75}>
              <TextField
                fullWidth
                variant="outlined"
                label="First Name"
                name="contactFirstName"
                value={insurance.contactFirstName || ""}
                onChange={handleInputChange}
              />
            </Box>
            <Box display="flex" flex="1" ml={0.75}>
              <TextField
                fullWidth
                variant="outlined"
                name="contactLastName"
                label="Last Name"
                value={insurance.contactLastName || ""}
                onChange={handleInputChange}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" mb={1}>
            <Box display="flex" flex="1" mr={0.75}>
              <InputMask
                mask="(999) 999-9999"
                maskChar=" "
                onChange={handleInputChange}
              >
                {() => (
                  <TextField
                    type="tel"
                    variant="outlined"
                    fullWidth
                    name="contactPhone"
                    label="Phone"
                    error={!!errors.contactPhone}
                    helperText={errors.contactPhone}
                  />
                )}
              </InputMask>
            </Box>
            <Box display="flex" flex="1" ml={0.75}>
              <TextField
                fullWidth
                variant="outlined"
                name="contactEmail"
                label="Email Address"
                value={insurance.contactEmail || ""}
                onChange={handleInputChange}
              />
            </Box>
          </Box>
        </Box>

        {/* Attachments */}
        <InsuranceAttachmentsBlock
          onAttachmentsChange={handleAttachmentsChange}
          insuranceAttachments={attachments}
        />
        {/* Vehicles */}
        <VehiclesBlock
          onVehiclesChange={handleVehiclesChange}
          vehicleIds={vehicles}
        />
      </CreateDrawer>
    </>
  );
}

export default CreateInsuranceDrawer;
