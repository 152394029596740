import React from "react";
import { useQuery } from "@apollo/client";

import {
  MenuItem,
  TextField,
} from "@mui/material";

import { Company } from "../../../types";
import { LOAD_COMPANIES_QUERY } from "../../../globals/graphql";

function CompanySelect({
  company,
  outlined,
  handleCompanyChange,
}: {
  outlined?: boolean | undefined;
  company: Company;
  handleCompanyChange: (company: Company) => void;
}) {
  const { data } = useQuery(LOAD_COMPANIES_QUERY);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (event.target.value === null) {
      handleCompanyChange(null);
    } else if (typeof event.target.value === "string") {
      const company = data.companies.find(
        ({ id }) => id === event.target.value
      );

      handleCompanyChange(company);
    }
  };

  const dynamicProps = outlined
    ? {
        variant: "outlined" as "outlined",
        label: "Company",
      }
    : { variant: "standard" as "standard" };

  return (
    <TextField
      {...dynamicProps}
      select
      fullWidth
      onClick={(e) => e.stopPropagation()}
      value={company ? company.id : ""}
      onChange={handleChange}
    >
      <MenuItem key="blank" value={null}>&nbsp;</MenuItem>
      {((data && data.companies) || []).map((company: Company) => (
        <MenuItem key={company.id} value={company.id || ""}>
          {company.name}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default CompanySelect;
