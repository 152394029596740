import React from "react";

import { useOperator } from "globals/hooks";
import UpgradeFirstSubscriptionDialog from "./UpgradeFirstSubscriptionDialog";
import UpgradeExistingSubscriptionDialog from "./UpgradeExistingSubscriptionDialog";

export type BaseUpgradeSubscriptionDialogProps = {
  open: boolean;
  onClose: () => void;
  planName: string;
  icon: JSX.Element;
  bgColor: string;
  planLength: "monthly" | "annual";
  pricePerMonth: number;
};

function UpgradeSubscriptionDialog(props: BaseUpgradeSubscriptionDialogProps) {
  // hooks
  const operator = useOperator();
  const { lagoPlanCode } = operator || {};

  return lagoPlanCode ? (
    <UpgradeExistingSubscriptionDialog {...props} />
  ) : (
    <UpgradeFirstSubscriptionDialog {...props} />
  );
}

export default UpgradeSubscriptionDialog;
