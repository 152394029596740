import React from "react";

import { Box, MenuItem, Select, Typography } from "@mui/material";

import { ChevronDownIcon } from "../../design-system/icons";
import { grayDark } from "../../design-system/colors";

type MoovsSelectDropdownProps = {
  options: (string | { label: string; disabled?: boolean })[];
  activeIndex: number;
  onOptionClick: (index: unknown) => void;
};

function MoovsSelectDropdown(props: MoovsSelectDropdownProps) {
  const { activeIndex, onOptionClick, options } = props;

  return (
    <Select
      value={activeIndex}
      fullWidth
      variant="outlined"
      onChange={(event) => onOptionClick(event.target.value)}
      IconComponent={() => (
        <Box
          sx={{
            marginTop: 1,
            position: "absolute",
            right: 20,
            display: "inline-block",
            pointerEvents: "none",
          }}
        >
          <ChevronDownIcon color={grayDark} size="small" />
        </Box>
      )}
      data-testid="moovs-select-dropdown"
    >
      {options.map((option, i) => {
        let label = option;
        let disabled = false;

        if (typeof option !== "string") {
          label = option.label;
          disabled = option.disabled;
        }

        return (
          <MenuItem
            value={i}
            key={i}
            data-testid={`moovs-select-dropdown-option-${i + 1}`}
            disabled={disabled}
          >
            <Typography>{label}</Typography>
          </MenuItem>
        );
      })}
    </Select>
  );
}

export default MoovsSelectDropdown;
