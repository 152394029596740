import React from "react";

import { Box, TextField } from "@mui/material";

import MoovsDialog from "../../MoovsDialog";
import InternationalPhoneInput from "../../inputs/InternationalPhoneInput";
import { useScreenSize } from "globals/hooks";

type UpdateTemporaryPassengerDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  onTemporaryPassengerInfoChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  temporaryPassengerValues: {
    name?: string;
    mobilePhoneInternational?: string;
    phoneCountryDialCode?: string;
  };
  temporaryPassengerErrors: { name: string };
  handleTemporaryPassengerPhoneChange: (
    value: any,
    country: any,
    e: any,
    formattedValue: any
  ) => void;
};

function UpdateTemporaryPassengerDialog(
  props: UpdateTemporaryPassengerDialogProps
) {
  const {
    open,
    onClose,
    onSave,
    onTemporaryPassengerInfoChange,
    temporaryPassengerValues,
    temporaryPassengerErrors,
    handleTemporaryPassengerPhoneChange,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      dialogTitle="Temporary Passenger"
      acceptButtonText="Save"
      onAccept={onSave}
      size="sm"
      titleToolTip="Passenger info will not be saved in contact list"
      childrenSx={{ overflow: "visible" }}
    >
      <Box my={6}>
        <Box mb={2}>
          <TextField
            required
            fullWidth
            variant="outlined"
            name="name"
            label="Passenger Name"
            value={temporaryPassengerValues?.name || ""}
            onChange={onTemporaryPassengerInfoChange}
            error={!!temporaryPassengerErrors.name}
            helperText={temporaryPassengerErrors.name}
          />
        </Box>
        <InternationalPhoneInput
          value={temporaryPassengerValues?.mobilePhoneInternational}
          onChange={handleTemporaryPassengerPhoneChange}
          phoneCountryDialCode={temporaryPassengerValues?.phoneCountryDialCode}
          dropdownBelowInput={!!isMobileView}
          dropdownWidth={isMobileView ? "600%" : "1035%"}
        />
      </Box>
    </MoovsDialog>
  );
}

export default UpdateTemporaryPassengerDialog;
