import { useState } from "react";
import { useQuery } from "@apollo/client";
import compact from "lodash/compact";
import filter from "lodash/filter";

import { LOAD_CONTACTS_AND_FARM_AFFILIATES_AND_COMPANIES_QUERY } from "globals/graphql";
import { EmailRecipient } from "components/SendEmailDialog/SendEmailDialog";
import { ContactsAndFarmAffiliatesAndCompanies } from "types";

type UseEmailRecipientQueryParams = {
  searchTerm: string;
};

function useEmailRecipientQuery(params: UseEmailRecipientQueryParams) {
  const { searchTerm } = params;

  // state
  const [options, setOptions] = useState<EmailRecipient[]>([]);

  // event handlers
  const emptyOptions = () => setOptions([]);

  // queries
  useQuery(LOAD_CONTACTS_AND_FARM_AFFILIATES_AND_COMPANIES_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      searchTerm,
      limit: 20,
    },
    skip: !searchTerm,
    onCompleted(data) {
      const emailRecipients =
        data.loadContactsAndFarmAffiliatesAndCompanies.edges.map(
          ({ node }: { node: ContactsAndFarmAffiliatesAndCompanies }) => {
            const { __typename } = node;

            if (__typename === "Contact") {
              return {
                email: node.email,
                name: `${node.firstName} ${node.lastName}`,
                phone: node.mobilePhone,
                __typename,
              };
            }

            if (__typename === "Company") {
              return {
                email: (node as any).companyEmail, // companyEmail aliased when querying this union
                name: node.name,
                phone: node.phoneNumber,
                __typename,
              };
            }

            if (
              __typename === "MoovsNetworkOperator" ||
              __typename === "GriddnetOperator" ||
              __typename === "MoovsAiOperator"
            ) {
              return {
                email: node.operatorEmail,
                name: node.operatorName,
                phone: node.operatorPhone,
                __typename,
              };
            }

            return null;
          }
        );

      setOptions(compact(filter(emailRecipients, "email")));
    },
  });

  return { options, emptyOptions };
}

export default useEmailRecipientQuery;
