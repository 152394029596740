import React from "react";

import { Box } from "@mui/material";

import { green, grayMedium, black } from "../../../design-system/colors";
import { useScreenSize } from "../../../globals/hooks";
import { StatusCircleIcon } from "../../../design-system/icons";

export const TimeBetweenTripsStyledBorder = () => {
  return (
    <Box px={1}>
      <Box
        px="auto"
        mx="auto"
        height="50%"
        width="1.5px"
        borderLeft={`1.5px dashed ${green}`}
      />
      <Box width="10px" />
      <Box
        px="auto"
        mx="auto"
        height="50%"
        width="1.5px"
        borderLeft={`1.5px dashed ${green}`}
      />
    </Box>
  );
};

export const CurrentTripStyledBorder = () => {
  const { isMobileView } = useScreenSize();

  return (
    <Box display="flex" alignItems="stretch">
      <Box px={1}>
        <Box
          mx="auto"
          height={isMobileView ? 72 : 18}
          width="1.5px"
          borderLeft={`1.5px dashed ${grayMedium}`}
        />
        <Box py={0.5} display="flex" alignItems="center">
          <StatusCircleIcon size="small" color={black} />
        </Box>

        <Box
          mx="auto"
          height={isMobileView ? "30%" : `calc(16px + 30%)`}
          width="1.5px"
          borderLeft={`1.5px solid ${grayMedium}`}
        />

        <Box py={0.5} display="flex" alignItems="center">
          <StatusCircleIcon size="small" color={black} />
        </Box>

        <Box
          mx="auto"
          height={`calc(8px + 30%)`}
          width="1.5px"
          borderLeft={`1.5px dashed ${grayMedium}`}
        />
      </Box>
    </Box>
  );
};

export const PrevNextTripStyledBorder = () => {
  return (
    <Box display="flex" alignItems="stretch">
      <Box px={1}>
        <Box
          mx="auto"
          height={`24px`}
          width="1.5px"
          borderLeft={`1.5px dashed ${grayMedium}`}
        />

        <Box py={0.5} display="flex" alignItems="center">
          <StatusCircleIcon size="small" />
        </Box>

        <Box
          mx="auto"
          height={`calc(100% - 42px)`}
          width="1.5px"
          borderLeft={`1.5px dashed ${grayMedium}`}
        />
      </Box>
    </Box>
  );
};
