import { useState } from "react";

import { MoovsLightboxProps } from "components/MoovsLightbox";

function useVehicleChildSeatLightboxProps(): [
  MoovsLightboxProps,
  (imageUrl: string, label?: string) => void
] {
  // state
  const [photos, setPhotos] = useState<string[]>([]);
  const [activePhotoIndex, setActivePhotoIndex] = useState<number>(null);
  const [label, setLabel] = useState<string>(null);

  const openImageInLightbox = (imageUrl: string, label?: string) => {
    if (label) setLabel(label);

    setPhotos([imageUrl]);
    setActivePhotoIndex(1);
  };

  return [
    {
      photos,
      activePhotoIndex,
      setActivePhotoIndex,
      label,
      hideArrows: true,
    },
    openImageInLightbox,
  ];
}

export default useVehicleChildSeatLightboxProps;
