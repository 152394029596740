import React from "react";

import { grayDark } from "design-system/colors";
import {
  ArrowUpIcon,
  ClockIcon,
  RoundTripIcon,
  ShuttleReturnTripIcon,
  TripTypeIcon,
} from "design-system/icons";
import { RoundTripVariant, TripCategory } from "types";

type getTripIconAndCategoryTypeParams = {
  tripCategory: TripCategory;
  roundTripVariant?: RoundTripVariant;
  hideRoundTripVariant?: boolean;
};

export const getTripIconAndCategoryType = (
  params: getTripIconAndCategoryTypeParams
) => {
  const { tripCategory, roundTripVariant, hideRoundTripVariant } = params;

  switch (tripCategory) {
    case TripCategory.RoundTrip: {
      const isOutboundTrip = roundTripVariant === RoundTripVariant.Outbound;

      if (hideRoundTripVariant) {
        return {
          text: "Round Trip",
          icon: <RoundTripIcon size="small" color={grayDark} />,
        };
      }

      return {
        text: isOutboundTrip ? "Round Trip: Pick-Up" : "Round Trip: Return",
        icon: <RoundTripIcon size="small" color={grayDark} />,
      };
    }
    case TripCategory.Hourly:
      return {
        text: "Hourly",
        icon: <ClockIcon size="small" color={grayDark} />,
      };
    case TripCategory.OneWay:
      return {
        text: "One way",
        icon: <TripTypeIcon color={grayDark} />,
      };
    case TripCategory.ShuttlePickUp:
      return {
        text: `Shuttle Transfer:\nPick-up`,
        icon: <ArrowUpIcon color={grayDark} />,
      };
    case TripCategory.ShuttleReturn:
      return {
        text: `Shuttle Transfer:\nReturn`,
        icon: <ShuttleReturnTripIcon color={grayDark} />,
      };
    default:
      return { text: "", icon: <></> };
  }
};
