import React from "react";
import moment from "moment";

import { Box, Typography, IconButton } from "@mui/material";

import {
  errorRed,
  granite,
  grayDark,
  grayLight,
  moovsBlue,
  tintRed,
  white,
  orange,
  tintOrange,
} from "../../../design-system/colors";
import { AutomatedPricingIcon, OpenIcon } from "../../../design-system/icons";
import { useScreenSize } from "../../../globals/hooks";
import { VehicleAvailability } from "../../../types";
import { encodeTripId } from "globals/utils/encodeStringQueryParams";
import RoundChip from "design-system/components/chips/RoundChip";

type PrevTripScheduleBlockProps = {
  isVehicleAvailable: boolean;
  prevTrip?: VehicleAvailability;
};

function PrevTripScheduleBlock(props: PrevTripScheduleBlockProps) {
  const { isVehicleAvailable, prevTrip } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  const {
    requestId: prevTripRequestId,
    tripId: prevTripId,
    dropOffDateTime: dropOffTime,
    dropOffLocation: dropOffAddress,
    isQuote,
  } = prevTrip;

  const isAutomatedDropTimeCalculation = !prevTrip.dropOffDateTime;

  // event handlers
  const handleViewPrevTripClick = () => {
    const stage = isQuote ? "quotes" : "reservations";

    const encodedTripId = encodeTripId(prevTripId);

    const win = window.open(
      `/${stage}/${prevTripRequestId}?${encodedTripId}`,
      "_blank"
    );
    win.focus();
  };

  return (
    <Box
      bgcolor={isVehicleAvailable ? grayLight : isQuote ? tintOrange : tintRed}
      borderRadius="4px"
    >
      <Box
        display="flex"
        sx={
          isMobileView
            ? { flexDirection: "column" }
            : {
                justifyContent: "space-between",
              }
        }
        p={3}
      >
        <Box maxHeight={80} {...(isMobileView && { mb: "12px" })}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box mb={0.5}>
                <Typography variant="h5" style={{ color: grayDark }}>
                  DROP-OFF
                </Typography>
              </Box>
              <Box display="flex" mb={0.75}>
                {isAutomatedDropTimeCalculation && (
                  <AutomatedPricingIcon size="small" />
                )}
                <Box ml={0.5}>
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: isVehicleAvailable
                        ? granite
                        : isQuote
                        ? orange
                        : errorRed,
                    }}
                  >
                    {moment.utc(dropOffTime).format("hh:mm A")}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {isMobileView && (
              <RoundChip
                label={isQuote ? "QUOTE" : "RESERVATION"}
                backgroundColor={isQuote ? grayDark : granite}
                color={white}
              />
            )}
          </Box>
          <Box {...(!isMobileView && { maxWidth: 350 })}>
            <Typography variant="caption" style={{ color: granite }}>
              {dropOffAddress}
            </Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems={isMobileView ? "flex-start" : "flex-end"}
        >
          {!isMobileView && (
            <Box mb="15px">
              <RoundChip
                label={isQuote ? "QUOTE" : "RESERVATION"}
                backgroundColor={isQuote ? grayDark : granite}
                color={white}
              />
            </Box>
          )}
          <Box {...(isMobileView && { mt: 2 })}>
            <Typography variant="h5" style={{ color: grayDark }}>
              {isQuote ? "QUOTE NO." : "CONF. NO."}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2">
              {prevTrip.confirmationNumber}
            </Typography>
            <Box ml="10px">
              <IconButton size="small" onClick={handleViewPrevTripClick}>
                <OpenIcon size="small" color={moovsBlue} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PrevTripScheduleBlock;
