/**
 * @file ShuttleRouteAutoComplete.tsx
 * Material ui autocomplete function
 * hooked up to shuttle route search.
 *
 * You should supply null as initial value.
 *
 * components:
 *  ShuttleRouteAutoComplete
 */

import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useLazyQuery } from "@apollo/client";

import {
  Box,
  Divider,
  TextField,
  Typography,
  Paper,
  Button,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { ShuttleRoute } from "types";
import { LOAD_SHUTTLE_ROUTES_QUERY } from "globals/graphql";
import { white, black, alabaster } from "design-system/colors";
import { PlusIcon } from "design-system/icons";
import MoovsTooltip from "components/MoovsTooltip";

type ShuttleRouteAutoCompleteProps = {
  companyId: string;
  onChange: (newValue: ShuttleRoute) => void;
  onCreateNewShuttleRouteClick?: () => void;
  error?: any;
  helperText?: string;
  setCarryOverSearch?: Dispatch<SetStateAction<string>>;
  label?: string;
  disabled?: boolean;
};

function ShuttleRouteAutoComplete(props: ShuttleRouteAutoCompleteProps) {
  const {
    companyId,
    onChange,
    onCreateNewShuttleRouteClick,
    error,
    helperText,
    setCarryOverSearch,
    label,
    disabled,
  } = props;

  const [shuttleRouteInput, setShuttleRouteInput] = useState("");
  const [shuttleRouteOptions, setShuttleRouteOptions] = useState([]);
  const [suggestionsList, setSuggestionsList] = useState([]);

  const [loadShuttleRoutesList, { loading, data }] = useLazyQuery(
    LOAD_SHUTTLE_ROUTES_QUERY,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const shuttleRoutesList = data?.loadShuttleRoutes.edges.map(
          ({ node }) => node
        );
        setShuttleRouteOptions(shuttleRoutesList || []);
      },
    }
  );

  const [loadSuggestedShuttleRoutes] = useLazyQuery(LOAD_SHUTTLE_ROUTES_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const suggestedShuttleRoutes =
        data?.loadShuttleRoutes.edges.map(({ node }) => node) || [];

      setSuggestionsList(suggestedShuttleRoutes);
      setShuttleRouteOptions(suggestedShuttleRoutes);
    },
  });

  const handleShuttleRouteInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event) setShuttleRouteInput(event.target.value);
  };

  const debouncedGetShuttleRoutes = useDebouncedCallback(
    (shuttleRouteInput: string) => {
      if (shuttleRouteInput) {
        loadShuttleRoutesList({
          variables: {
            companyId,
            searchTerm: shuttleRouteInput,
            limit: 20,
          },
        });
      } else {
        setShuttleRouteOptions(suggestionsList);
      }
    },
    250
  );

  const handleCreateNewShuttleRouteClick = () => {
    setCarryOverSearch(shuttleRouteInput);
    onCreateNewShuttleRouteClick();
  };

  // Pre-populate suggested shuttle routes when companyId is updated
  useEffect(() => {
    if (!companyId) {
      return;
    }

    loadSuggestedShuttleRoutes({
      variables: {
        limit: 20,
        companyId,
      },
    });
  }, [companyId, loadSuggestedShuttleRoutes]);

  useEffect(() => {
    if (!companyId) {
      return;
    }

    debouncedGetShuttleRoutes(shuttleRouteInput);
  }, [companyId, shuttleRouteInput, debouncedGetShuttleRoutes]);

  const paperComponent = ({ children }) => {
    return (
      <Paper>
        <Box px={2} pt={1}>
          <Typography sx={{ backgroundColor: white }} variant="overline">
            Add existing route name
          </Typography>
        </Box>
        {children}
        {onCreateNewShuttleRouteClick && (
          <Box flex="1" display="flex" justifyContent="flex-start">
            <Button
              fullWidth
              onMouseDown={(event) => {
                event.preventDefault();
              }}
              onClick={handleCreateNewShuttleRouteClick}
              startIcon={<PlusIcon size="small" color={black} />}
            >
              Create New Route
            </Button>
          </Box>
        )}
      </Paper>
    );
  };

  const isDisabled = disabled || !companyId;

  return (
    <Autocomplete
      disabled={isDisabled}
      autoHighlight
      id="shuttle-route-autocomplete"
      PaperComponent={paperComponent}
      clearOnEscape
      noOptionsText={
        data && shuttleRouteInput
          ? "No route name found"
          : "Type to search for a route name"
      }
      filterOptions={(x) => x}
      loading={debouncedGetShuttleRoutes.isPending() || loading}
      value={null}
      onChange={(_e, newValue: ShuttleRoute) => {
        onChange(newValue);
      }}
      options={shuttleRouteOptions}
      onInputChange={handleShuttleRouteInputChange}
      getOptionLabel={(shuttleRoute: ShuttleRoute) => shuttleRoute.name}
      renderInput={(params) => {
        const { InputProps, ...restParams } = params;
        const textField = (
          <TextField
            {...restParams}
            fullWidth
            label={label || "Enter Route name *"}
            name="no-show"
            variant="outlined"
            error={error}
            helperText={helperText}
            InputProps={{
              ...InputProps,
              autoComplete: "name",
              style: {
                backgroundColor: isDisabled ? alabaster : "inherit",
              },
            }}
          />
        );

        return companyId ? (
          <>{textField}</>
        ) : (
          <MoovsTooltip tooltipText="Must select company first">
            {textField}
          </MoovsTooltip>
        );
      }}
      renderOption={(props, option) => (
        <li {...props}>
          <Box>
            <Typography variant="body2">{option.name}</Typography>
          </Box>
          <Divider />
        </li>
      )}
    />
  );
}

export default ShuttleRouteAutoComplete;
