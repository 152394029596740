import isNil from "lodash/isNil";
import toLower from "lodash/toLower";
import { Request } from "types";

export const trackReservations = (
  track,
  request: Request,
  trackName: string
) => {
  const createdTrips = request.trips;
  const linkedPassengerIds =
    request.bookingContact?.linkedPassengers?.map(
      (passenger) => passenger.id
    ) || [];
  const promoCodeApplied = !isNil(
    request.trips[0].routes[0].pricing.promoCodeAmt
  );
  const hasChildSeatApplied = [
    "rearFacingSeatAmt",
    "boosterSeatAmt",
    "forwardFacingSeatAmt",
  ].some(
    (childSeatField) =>
      !isNil(request.trips[0].routes[0].pricing[childSeatField])
  );

  createdTrips.forEach((trip) => {
    if (trip.tempPassenger?.name) {
      track(trackName, {
        passengerType: "Temporary Passenger",
        tripType: toLower(trip.tripCategory),
        ...(promoCodeApplied && { promo: "code_applied" }),
        ...(hasChildSeatApplied && { child_seat: "seat_added" }),
      });
    } else if (
      trip.contact.id === request.bookingContact.id &&
      !trip.tempPassenger?.name
    ) {
      track(trackName, {
        passengerType: "Booking Contact",
        tripType: toLower(trip.tripCategory),
        ...(promoCodeApplied && { promo: "code_applied" }),
        ...(hasChildSeatApplied && { child_seat: "seat_added" }),
      });
    } else if (linkedPassengerIds.includes(trip.contact.id)) {
      track(trackName, {
        passengerType: "Linked Passenger",
        tripType: toLower(trip.tripCategory),
        ...(promoCodeApplied && { promo: "code_applied" }),
        ...(hasChildSeatApplied && { child_seat: "seat_added" }),
      });
    }
  });
};
