import { useMutation } from "@apollo/client";

import { RESEND_INVITE_MUTATION } from "globals/graphql";
import { useSnackbar } from "globals/hooks";

type useResendInviteProps = {
  onCompleted?: () => void;
};

function useResendInvite(props: useResendInviteProps) {
  const { onCompleted } = props;

  // hooks
  const snackbar = useSnackbar();

  // mutation
  const [resendInvite, { loading: resendInviteLoading }] = useMutation(
    RESEND_INVITE_MUTATION,
    {
      onCompleted(data) {
        const result = data.resendInviteV2.result;

        if (result.__typename === "ResendInviteFailure") {
          snackbar.error(result.message);

          return;
        }

        onCompleted && onCompleted();

        snackbar.success("Successfully sent invite!");
      },
      onError() {
        snackbar.error("Error sending invite");
      },
    }
  );

  // event handlers
  const handleResendInvite = (userId: string) => () => {
    resendInvite({
      variables: {
        input: {
          userId,
        },
      },
    });
  };

  return {
    onResendInvite: handleResendInvite,
    resendInviteLoading,
  };
}

export { useResendInvite };
