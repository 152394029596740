import React from "react";

import { Stop } from "../../../types";
import MoovsDialog from "../../MoovsDialog";
import VehicleAvailabilityContent from "./VehicleAvailabilityContent";

type VehicleAvailabilityDialogProps = {
  onClose: () => void;
  open: boolean;
  title: string;
  vehicleId?: string;
  stops?: Partial<Stop>[];
  routeId?: string;
};

function VehicleAvailabilityDialog(props: VehicleAvailabilityDialogProps) {
  const { open, onClose, title } = props;

  return (
    <MoovsDialog
      open={open}
      size="sm"
      onClose={onClose}
      dialogTitle={title}
      fixedFooter
      narrowSidePaddingXDesktop
    >
      <VehicleAvailabilityContent {...props} />
    </MoovsDialog>
  );
}

export default VehicleAvailabilityDialog;
