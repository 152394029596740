import React from "react";

import { Box, TextField, Typography } from "@mui/material";

import { CreateDriverInput } from "types";
import { DriverErrors } from "../../types";
import { useScreenSize } from "globals/hooks";
import InternationalPhoneInput from "components/inputs/InternationalPhoneInput";

type ContactDetailsProps = {
  driver: CreateDriverInput;
  setDriver: React.Dispatch<React.SetStateAction<CreateDriverInput>>;
  driverErrors: DriverErrors;
  setDriverErrors: React.Dispatch<React.SetStateAction<DriverErrors>>;
};

function ContactDetails(props: ContactDetailsProps) {
  const { driver, setDriver, driverErrors, setDriverErrors } = props;

  // hooks
  const { isSmallTabletView } = useScreenSize();

  // event handlers
  const handleContactDetailsChange = (e) => {
    const { value, name } = e.target;

    setDriver({ ...driver, [name]: value });

    setDriverErrors({
      ...driverErrors,
      [name]: "",
    });
  };

  const handlePhoneNumberInput = (value, country) => {
    setDriver({
      ...driver,
      mobilePhone: value,
      phoneCountryCode: country.countryCode,
      phoneCountryDialCode: country.dialCode,
      phoneCountryName: country.name,
      phoneCountryFormat: country.format,
    });
    setDriverErrors({ ...driverErrors, mobilePhone: "" });
  };

  return (
    <>
      <Box mt={4} mb={2}>
        <Typography variant="h3">Contact Details</Typography>
      </Box>
      <Box mb={4}>
        <Box
          display="flex"
          flexDirection={isSmallTabletView ? "column" : "row"}
          mb={2}
        >
          <Box
            display="flex"
            flex="1"
            mr={isSmallTabletView ? 0 : 1}
            mb={isSmallTabletView ? 1 : 0}
          >
            <TextField
              required
              fullWidth
              name="firstName"
              label="First Name"
              variant="outlined"
              value={driver.firstName}
              onChange={handleContactDetailsChange}
              error={!!driverErrors.firstName}
              helperText={driverErrors.firstName}
            />
          </Box>
          <Box display="flex" flex="1">
            <TextField
              fullWidth
              name="lastName"
              label="Last Name"
              variant="outlined"
              value={driver.lastName}
              onChange={handleContactDetailsChange}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={isSmallTabletView ? "column" : "row"}
          mb={1}
        >
          <Box
            display="flex"
            flex="1"
            mr={isSmallTabletView ? 0 : 1}
            mb={isSmallTabletView ? 1 : 0}
          >
            <InternationalPhoneInput
              value={driver.mobilePhone}
              error={driverErrors.mobilePhone}
              onChange={handlePhoneNumberInput}
              errorMessage="Please enter valid number"
              dropdownWidth="600%"
            />
          </Box>
          <Box display="flex" flex="1">
            <TextField
              fullWidth
              name="email"
              label="Email Address"
              variant="outlined"
              value={driver.email}
              onChange={handleContactDetailsChange}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ContactDetails;
