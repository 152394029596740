import React from "react";
import first from "lodash/first";

import { Box, Typography } from "@mui/material";

import { Trip } from "types";
import { grayDark } from "design-system/colors";

type VehicleInfoProps = {
  trip: Trip;
};

function VehicleInfo(props: VehicleInfoProps) {
  const { trip } = props;

  // derived state
  const { routes } = trip;

  const vehicle = first(routes).vehicle;

  return (
    <Box>
      <Typography variant="overline" style={{ color: grayDark }}>
        VEHICLE
      </Typography>
      <Typography variant="body2">{vehicle ? vehicle.name : "-"}</Typography>
    </Box>
  );
}

export default VehicleInfo;
