import React, { useState } from "react";

import { Button, Box, Typography } from "@mui/material";

import { green, errorRed } from "../../../../../../design-system/colors";
import { CheckIcon, CrossIcon } from "../../../../../../design-system/icons";
import { FarmedRouteStatusEnum, Trip } from "../../../../../../types";
import CreateFarmedRouteDialog from "../CreateFarmedRouteDialog";
import { useAnalytics } from "../../../../../../globals/hooks";

type FarmOutActionSectionProps = {
  farmStatus: FarmedRouteStatusEnum;
  trip: Trip;
};

function FarmOutActionSection(props: FarmOutActionSectionProps) {
  const { farmStatus, trip } = props;

  // hooks
  const { track } = useAnalytics();

  // state
  const [farmOutDialogOpen, setFarmOutDialogOpen] = useState(false);

  // event handlers
  const handleReassignClick = () => {
    track("farmedRoute_farmorReassigned");
    setFarmOutDialogOpen(true);
  };

  const message = {
    [FarmedRouteStatusEnum.Pending]: "Trip is pending acceptance",
    [FarmedRouteStatusEnum.Accepted]: "Trip is accepted",
    [FarmedRouteStatusEnum.Declined]: "Trip is declined",
    [FarmedRouteStatusEnum.Completed]: "Trip is complete",
  };

  const icon = {
    [FarmedRouteStatusEnum.Pending]: null,
    [FarmedRouteStatusEnum.Accepted]: <CheckIcon size="small" color={green} />,
    [FarmedRouteStatusEnum.Declined]: (
      <CrossIcon size="small" color={errorRed} />
    ),
    [FarmedRouteStatusEnum.Completed]: <CheckIcon size="small" color={green} />,
  };

  const cta = {
    [FarmedRouteStatusEnum.Pending]: null,
    [FarmedRouteStatusEnum.Accepted]: null,
    [FarmedRouteStatusEnum.Declined]: (
      <Button variant="contained" onClick={handleReassignClick} color="primary">
        Reassign
      </Button>
    ),
    [FarmedRouteStatusEnum.Completed]: null,
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        {icon[farmStatus]}
        <Box {...(icon[farmStatus] && { ml: 1 })}>
          <Typography variant="subtitle2">{message[farmStatus]}</Typography>
        </Box>
      </Box>
      {cta[farmStatus]}
      {farmOutDialogOpen && (
        <CreateFarmedRouteDialog
          open={farmOutDialogOpen}
          onClose={() => setFarmOutDialogOpen(false)}
          routeId={trip.routes[0].id}
          tripId={trip.id}
        />
      )}
    </Box>
  );
}

export default FarmOutActionSection;
