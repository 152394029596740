import React from "react";

import RHFTextField from "components/reactHookFormInputs/RHFTextField";
import { black, errorRed, grayMedium, white } from "design-system/colors";

function EditNameTextField(props) {
  const { name, control, errorMessage, editName } = props;

  return (
    <RHFTextField
      name={name}
      control={control}
      errorMessage={errorMessage}
      fullWidth
      disabled={!editName}
      variant={errorMessage ? "outlined" : editName ? "outlined" : "standard"}
      sx={{
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: black,
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: errorMessage ? errorRed : grayMedium,
        },
        "& .MuiInput-underline:before": {
          borderBottomColor: editName ? grayMedium : white,
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: editName ? grayMedium : white,
        },
      }}
    />
  );
}

export default EditNameTextField;
