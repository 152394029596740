import * as yup from "yup";

export const bookingDateSchema = yup.object({
  bookingStartDate: yup
    .date()
    .required("Please add the Start Date")
    .typeError("Please add the Start Date"),
  bookingEndDate: yup
    .date()
    .min(yup.ref("bookingStartDate"), "End Date must be after Start Date")
    .required("Please add the End Date")
    .typeError("Please add the End Date"),
});

export const tripDateSchema = yup.object({
  tripStartDate: yup
    .date()
    .required("Please add the Start Date")
    .typeError("Please add the Start Date"),
  tripEndDate: yup
    .date()
    .min(yup.ref("tripStartDate"), "End Date must be after Start Date")
    .required("Please add the End Date")
    .typeError("Please add the End Date"),
});
