import React from "react";

import {
  MoovsNetworkOperatorIcon,
  ExternalOperatorIcon,
  GriddnetOperatorIcon,
  MoovsAiOperatorIcon,
} from "design-system/icons";
import { moovsLitePrimary } from "theme";
import { FarmAffiliate, FarmAffiliateVariantEnum } from "types";

type getFarmAffiliateIconVariantProps = {
  vehicle?: any;
  farmAffiliate?: FarmAffiliate;
  farmAffiliateVariant?:
    | "MoovsLiteOperator"
    | "MoovsNetworkOperator"
    | "ExternalOperator"
    | "GriddnetOperator"
    | "MoovsAiOperator";
};

export function getFarmAffiliateIconVariant(
  props: getFarmAffiliateIconVariantProps
) {
  const { vehicle, farmAffiliate } = props;

  if (vehicle) {
    return vehicle?.isMoovsLiteOperator
      ? "MoovsLiteOperator"
      : vehicle?.farmAffiliateVariant ||
          FarmAffiliateVariantEnum.ExternalOperator; // case for CreateFarmAffiliateDrawer
  }

  if (farmAffiliate) {
    if (farmAffiliate.__typename !== "MoovsNetworkOperator") {
      return farmAffiliate.__typename;
    }

    if (farmAffiliate.__typename === "MoovsNetworkOperator") {
      return farmAffiliate?.isMoovsLiteOperator
        ? "MoovsLiteOperator"
        : "MoovsNetworkOperator";
    }
  }
  return "MoovsLiteOperator";
}

type FarmAffiliateIconProps = {
  variant:
    | "ExternalOperator"
    | "MoovsNetworkOperator"
    | "GriddnetOperator"
    | "MoovsLiteOperator"
    | "MoovsAiOperator";
  size?: "extra-large" | "small";
};

function FarmAffiliateIcon(props: FarmAffiliateIconProps) {
  const { variant, size } = props;

  switch (variant) {
    case "MoovsNetworkOperator":
      return <MoovsNetworkOperatorIcon {...(size && { size: size })} />;
    case "MoovsLiteOperator":
      return (
        <MoovsNetworkOperatorIcon
          fill={moovsLitePrimary}
          {...(size && { size: size })}
        />
      );
    case "ExternalOperator":
      return <ExternalOperatorIcon {...(size && { size: size })} />;
    case "GriddnetOperator":
      return <GriddnetOperatorIcon {...(size && { size: size })} />;
    case "MoovsAiOperator":
      return <MoovsAiOperatorIcon {...(size && { size: size })} />;
  }
}

export default FarmAffiliateIcon;
