import React from "react";
import { useHistory } from "react-router-dom";

import RemoveDialog from "components/RemoveDialog";
import { useAnalytics, useResendInvite } from "globals/hooks";

type ResendInviteProps = {
  open: boolean;
  onClose: () => void;
  userId: string;
  userEmail: string;
};

function RemoveMemberDialog(props: ResendInviteProps) {
  const { open, onClose, userId, userEmail } = props;

  const { track } = useAnalytics();

  // event listeners
  const handleResendInviteComplete = () => {
    track("updateUser_inviteEmailResent");
    history.push("/settings/members/");
  };

  // hooks
  const history = useHistory();
  const { onResendInvite, resendInviteLoading } = useResendInvite({
    onCompleted: handleResendInviteComplete,
  });

  return (
    <RemoveDialog
      open={open}
      onRemove={onResendInvite(userId)}
      onClose={onClose}
      removeButtonText="Resend"
      title="Resend invite?"
      body={`This will resend the invitation email to ${userEmail} so they can use Moovs.`}
      isSaving={resendInviteLoading}
    />
  );
}

export default RemoveMemberDialog;
