import React from "react";

import { Box, Typography } from "@mui/material";

const MoovsCardError = () => {
  return (
    <Box
      height="400px"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Typography variant="h5">Error</Typography>
        <Typography>
          Moovs Card unavailable. Please reach out to support@moovsapp.com.
        </Typography>
      </Box>
    </Box>
  );
};

export default MoovsCardError;
