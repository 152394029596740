import React, { ReactNode, useState } from "react";
import { useLocation } from "react-router-dom";

import { Box, CircularProgress } from "@mui/material";

import { useAuth } from "../../../globals/hooks";
import TopBar from "./TopBar/TopBar";
import { HelpFAB } from "./SideNav/components/HelpFAB";

type OnboardingLayoutProps = {
  children: ReactNode;
};

function OnboardingLayout(props: OnboardingLayoutProps) {
  const { children } = props;
  const { authStage } = useAuth();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isWebsiteCreatePage = useLocation().pathname.includes("website");

  return (
    <>
      <HelpFAB />
      <div>
        {authStage === "pending" ? (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={80} thickness={4} />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" width="100%">
            <TopBar
              settingsPage={isWebsiteCreatePage} // TODO: refactor to use isHidden
              onDrawerToggle={handleDrawerToggle}
            />
            <div>{children}</div>
          </Box>
        )}
      </div>
    </>
  );
}

export default OnboardingLayout;
