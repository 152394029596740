import first from "lodash/first";

import { FarmRelationshipEnum, Route, Trip } from "types";

function getTripAmount(trip: Trip, amountType: "total" | "promo") {
  const route = first(trip.routes as Route[]);
  const isFarmee = route.farmRelationship === FarmRelationshipEnum.Farmee;

  if (amountType === "total") {
    const totalAmount = isFarmee
      ? route.farmeePricing?.totalAmt
      : trip.totalAmount;

    return totalAmount || 0;
  } else {
    const promoCodeAmount = isFarmee
      ? route.farmeePricing?.promoCodeAmt
      : route.pricing?.promoCodeAmt;

    return promoCodeAmount || 0;
  }
}

export function getTripTotalAmount(trip: Trip) {
  return getTripAmount(trip, "total");
}

export function getTripPromoAmount(trip: Trip) {
  return getTripAmount(trip, "promo");
}
