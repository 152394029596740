import React from "react";
import { ApolloQueryResult } from "@apollo/client";

import { Box, Typography } from "@mui/material";

import { Contact } from "types";
import PassengerOfList from "./PassengerOfList";

type PassengerOfSectionProps = {
  contact: Contact;
  refreshContact: (
    variables?: Partial<{
      id: string;
    }>
  ) => Promise<ApolloQueryResult<any>>;
};

export default function PassengerOfSection(props: PassengerOfSectionProps) {
  const { contact, refreshContact } = props;
  const hasPassengerOf = contact?.passengerOf?.length > 0;
  if (!hasPassengerOf) return null;
  return (
    <Box>
      <Box mt={4}>
        <Box>
          <Typography variant="h3">Passenger Of</Typography>
        </Box>
        <PassengerOfList contact={contact} refreshContact={refreshContact} />
      </Box>
    </Box>
  );
}
