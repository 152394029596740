import React from "react";
import { useFormContext } from "react-hook-form";

import { Box, Typography } from "@mui/material";

import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";
import { AssignedUnassigned } from "types";
import { useScreenSize } from "globals/hooks";

import { FilterProps } from "../FilterDispatchTripsDrawer";

function DriverAssignedToggle(props: FilterProps) {
  const { disabledSelect } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const { watch, setValue } = useFormContext();

  const driverAssignedToggleButtons = [
    {
      value: null,
      label: "All",
    },
    {
      value: AssignedUnassigned.Unassigned,
      label: "Driver Unassigned",
    },
    {
      value: AssignedUnassigned.Assigned,
      label: "Driver Assigned",
    },
  ];

  return (
    <Box mb={2}>
      <Typography variant="h3" mb={2}>
        Driver Assigned
      </Typography>

      <MoovsToggleButtonGroup
        onChange={(_, newValue) => setValue("driverAssigned", newValue)}
        options={driverAssignedToggleButtons}
        value={watch("driverAssigned")}
        size="large"
        fullWidth={isMobileView}
        overrideNoWrap
        disabled={disabledSelect}
        tooltipText="Upgrade plan to Pro in order to use filtering feature"
      />
    </Box>
  );
}

export default DriverAssignedToggle;
