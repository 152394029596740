import React from "react";

type ArchiveIconProps = {
  color?: string;
  size?: "small";
};

const ArchiveIcon = (props: ArchiveIconProps) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7778 4V11.1538C13.7778 11.6435 13.5905 12.1131 13.2571 12.4593C12.9237 12.8055 12.4715 13 12 13H3.99998C3.52848 13 3.0763 12.8055 2.7429 12.4593C2.4095 12.1131 2.2222 11.6435 2.2222 11.1538V4M13.7778 1H2.2222C1.98645 1 1.76036 1.09725 1.59366 1.27036C1.42696 1.44347 1.33331 1.67826 1.33331 1.92308V3.76923H14.6666V1.92308C14.6666 1.67826 14.573 1.44347 14.4063 1.27036C14.2396 1.09725 14.0135 1 13.7778 1ZM10.6666 7.20513C10.6666 7.44994 10.573 7.68473 10.4063 7.85784C10.2396 8.03095 10.0135 8.12821 9.77776 8.12821H6.33331C6.09757 8.12821 5.87147 8.03095 5.70477 7.85784C5.53807 7.68473 5.44442 7.44994 5.44442 7.20513C5.44442 6.96031 5.53807 6.72552 5.70477 6.55241C5.87147 6.3793 6.09757 6.28205 6.33331 6.28205H9.77776C10.0135 6.28205 10.2396 6.3793 10.4063 6.55241C10.573 6.72552 10.6666 6.96031 10.6666 7.20513Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArchiveIcon;
