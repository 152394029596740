import { WorkflowSelectionsEnum } from "../steps/workflow/schemaValidation";

type GetFormComponentStepsProps = {
  workflow: string;
  workFlowFormComponent: React.ReactNode;
  dateFormComponent: React.ReactNode;
  dateRangeFormComponent: React.ReactNode;
  timesOfDayFormComponent: React.ReactNode;
  adjustAmtFormComponent: React.ReactNode;
  vehicleFormComponent: React.ReactNode;
  repeatRuleFormComponent: React.ReactNode;
  overviewFormComponent: React.ReactNode;
};

// this function will return a list of steps depending on which workflow the user selects
export const getFormComponentSteps = (props: GetFormComponentStepsProps) => {
  const {
    workflow,
    workFlowFormComponent,
    dateFormComponent,
    dateRangeFormComponent,
    timesOfDayFormComponent,
    adjustAmtFormComponent,
    vehicleFormComponent,
    repeatRuleFormComponent,
    overviewFormComponent,
  } = props;

  const dateOptions = {
    date: {
      header: "Adjust base rate for a specific date",
      component: dateFormComponent,
    },
    dateRange: {
      header: "Adjust base rate for date range",
      component: dateRangeFormComponent,
    },
    timesOfDay: {
      header: "Adjust base rate for times of day",
      component: timesOfDayFormComponent,
    },
  };

  const formDictionary = {
    workflow: {
      header: "Create advanced workflows tailored to your needs",
      component: workFlowFormComponent,
    },
    dateOption: {
      header: dateOptions[workflow]?.header,
      component: dateOptions[workflow]?.component,
    },
    adjustAmt: {
      header: dateOptions[workflow]?.header,
      component: adjustAmtFormComponent,
    },
    vehicle: {
      header: dateOptions[workflow]?.header,
      component: vehicleFormComponent,
    },
    repeatRule: {
      header: dateOptions[workflow]?.header,
      component: repeatRuleFormComponent,
    },
    overview: {
      header: dateOptions[workflow]?.header,
      component: overviewFormComponent,
    },
  };

  // starter step needs an array of minimum length 2 for useFormStepProgress hook to work
  const starterStep = [formDictionary.workflow, null];

  const dateSteps = [
    formDictionary.workflow,
    formDictionary.dateOption,
    formDictionary.adjustAmt,
    formDictionary.vehicle,
    formDictionary.repeatRule,
    formDictionary.overview,
  ];

  const dateRangeSteps = [
    formDictionary.workflow,
    formDictionary.dateOption,
    formDictionary.adjustAmt,
    formDictionary.vehicle,
    formDictionary.repeatRule,
    formDictionary.overview,
  ];

  const timesOfDaySteps = [
    formDictionary.workflow,
    formDictionary.dateOption,
    formDictionary.adjustAmt,
    formDictionary.vehicle,
    formDictionary.overview,
  ];

  if (workflow === WorkflowSelectionsEnum.date)
    return { formComponentSteps: dateSteps };
  if (workflow === WorkflowSelectionsEnum.dateRange)
    return { formComponentSteps: dateRangeSteps };
  if (workflow === WorkflowSelectionsEnum.timesOfDay)
    return { formComponentSteps: timesOfDaySteps };

  return { formComponentSteps: starterStep };
};
