import React from "react";

import { Box } from "@mui/material";

import { grayLight } from "../../design-system/colors";
import { currency } from "../../globals/utils/helpers";
import { DriverPayout } from "../../types";
import DriverPayoutTextBlock from "./DriverPayoutTextBlock";
import { useScreenSize } from "../../globals/hooks";

type DriverPayoutSummaryType = {
  driverPayout: DriverPayout;
};

function DriverPayoutSummary(props: DriverPayoutSummaryType) {
  const { driverPayout } = props;
  const {
    driversCount,
    routesCount,
    flatRateAmt,
    hourlyAmt,
    gratuityAmt,
    totalAmt,
  } = driverPayout || {};

  const { isSmallTabletView } = useScreenSize();

  // tablet/mobile view
  if (isSmallTabletView)
    return (
      <Box
        display="flex"
        flexDirection="column"
        bgcolor={grayLight}
        borderRadius="4px"
        px={3}
        py={2}
        mx={2}
      >
        <Box display="flex" flexDirection="row" mb={2}>
          <Box flex={1}>
            <DriverPayoutTextBlock
              size="lg"
              header="drivers"
              body={driversCount}
            />
          </Box>
          <Box flex={1}>
            <DriverPayoutTextBlock
              size="lg"
              header="trips"
              body={routesCount}
            />
          </Box>
          <Box flex={1}>
            <DriverPayoutTextBlock
              size="lg"
              header="flat rate"
              body={currency(flatRateAmt)}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box flex={1}>
            <DriverPayoutTextBlock
              size="lg"
              header="hourly"
              body={currency(hourlyAmt)}
            />
          </Box>
          <Box flex={1}>
            <DriverPayoutTextBlock
              size="lg"
              header="gratuity"
              body={currency(gratuityAmt)}
            />
          </Box>
          <Box flex={1}>
            <DriverPayoutTextBlock
              size="lg"
              bold
              header="total"
              body={currency(totalAmt)}
            />
          </Box>
        </Box>
      </Box>
    );

  // desktop view
  return (
    <Box
      display="flex"
      flexDirection="row"
      bgcolor={grayLight}
      borderRadius="4px"
      px={3}
      py={2}
      mx={3}
    >
      <Box flex={2.5}>
        <DriverPayoutTextBlock size="lg" header="drivers" body={driversCount} />
      </Box>
      <Box flex={0.5}>
        <DriverPayoutTextBlock size="lg" header="trips" body={routesCount} />
      </Box>
      <Box flex={1}>
        <DriverPayoutTextBlock
          size="lg"
          header="flat rate"
          body={currency(flatRateAmt)}
        />
      </Box>
      <Box flex={1}>
        <DriverPayoutTextBlock
          size="lg"
          header="hourly"
          body={currency(hourlyAmt)}
        />
      </Box>
      <Box flex={1}>
        <DriverPayoutTextBlock
          size="lg"
          header="gratuity"
          body={currency(gratuityAmt)}
        />
      </Box>
      <Box flex={1}>
        <DriverPayoutTextBlock
          size="lg"
          bold
          header="total"
          body={currency(totalAmt)}
        />
      </Box>
    </Box>
  );
}

export default DriverPayoutSummary;
