import React, { ChangeEvent } from "react";

import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";

import { VehicleChildSeat } from "types";
import { useScreenSize } from "globals/hooks";
import MoovsLabeledIncrementer from "components/MoovsLabeledIncrementer";
import {
  DollarPercentVariant,
  normalizeValueToBeDisplayedAsDollar,
} from "components/pricing/utils";
import { NumberFormatDollar } from "design-system/components/inputs/NumberFormat";
import CharacterLimitedTextInput from "components/inputs/CharacterLimitedTextInput";
import ChildSeatPhoto from "./ChildSeatPhoto";

type ChildSeatItemProps = {
  name: string;
  childSeat: VehicleChildSeat;
  updateChildSeat: (childSeat: VehicleChildSeat) => void;
  onPhotoClick: (url: string) => void;
};

const ChildSeatItem = (props: ChildSeatItemProps) => {
  const { name, childSeat, updateChildSeat, onPhotoClick } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // event handlers
  const handleChildSeatSelect = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const active = event.target.checked;

    updateChildSeat({
      ...childSeat,
      active,
    });
  };

  const handleChildSeatQuantityChange = (value: number) => {
    const quantity = value >= 99 ? 99 : value <= 1 ? 1 : value;

    updateChildSeat({
      ...childSeat,
      quantity,
    });
  };

  const handleChildSeatAmountChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value === "" ? 0 : Number(event.target.value);

    updateChildSeat({
      ...childSeat,
      amt: value,
    });
  };

  const handleChildSeatDescriptionChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    updateChildSeat({
      ...childSeat,
      description: event.target.value === "" ? null : event.target.value,
    });
  };

  const handleChildSeatImageUpload = (url: string) => {
    updateChildSeat({
      ...childSeat,
      imageUrl: url,
    });
  };

  const handleChildSeatPhotoClick = () => {
    onPhotoClick(childSeat.imageUrl);
  };

  return (
    <Box display="flex" alignItems="flex-start" justifyContent="space-between">
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={1.5}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={childSeat.active}
                checked={childSeat.active}
                onChange={handleChildSeatSelect}
              />
            }
            label={name}
            labelPlacement="end"
          />

          {isMobileView && (
            <Box mr={-1}>
              <ChildSeatPhoto
                childSeat={childSeat}
                onUpdateVehicleChildSeatPhoto={handleChildSeatImageUpload}
                onPhotoClick={handleChildSeatPhotoClick}
                size={120}
              />
            </Box>
          )}
        </Box>

        <Box display="flex" mb={1.5}>
          <MoovsLabeledIncrementer
            label="Quantity"
            value={childSeat.quantity}
            limits={[1, 99]}
            decrement={() =>
              handleChildSeatQuantityChange(childSeat.quantity - 1)
            }
            increment={() =>
              handleChildSeatQuantityChange(childSeat.quantity + 1)
            }
            onTextFieldChange={(value) => handleChildSeatQuantityChange(value)}
            sx={{ maxWidth: 180 }}
          />

          <TextField
            label="Amount"
            value={normalizeValueToBeDisplayedAsDollar({
              value: childSeat.amt,
              variant: DollarPercentVariant.Dollar,
            })}
            onChange={handleChildSeatAmountChange}
            sx={{ ml: 2 }}
            InputProps={{
              inputProps: {
                prefix: "$",
              },
              inputComponent: NumberFormatDollar as any,
            }}
          />
        </Box>

        <CharacterLimitedTextInput
          label="Short Description"
          name="description"
          value={childSeat.description}
          warningLimit={25}
          errorLimit={35}
          onChange={handleChildSeatDescriptionChange}
        />
      </Box>

      {/* Desktop View Only */}
      {!isMobileView && (
        <ChildSeatPhoto
          childSeat={childSeat}
          onUpdateVehicleChildSeatPhoto={handleChildSeatImageUpload}
          onPhotoClick={handleChildSeatPhotoClick}
        />
      )}
    </Box>
  );
};

export default ChildSeatItem;
