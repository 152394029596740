import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import moment from "moment";
import first from "lodash/first";
import last from "lodash/last";

import {
  Box,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { StaticDateRangePicker } from "@mui/lab";

import { useAnalytics, useScreenSize } from "../../globals/hooks";
import MoovsSelect from "components/MoovsSelect";
import AnchorPopper from "components/common/AnchorPopper";
import { errorRed } from "design-system/colors";

enum TimePeriod {
  THIS_YEAR = "This Year",
  LAST_YEAR = "Last Year",
  LAST_30_DAYS = "Last 30 Days",
  CUSTOM_RANGE = "Custom Range",
}

const { THIS_YEAR, LAST_YEAR, LAST_30_DAYS, CUSTOM_RANGE } = TimePeriod;

type TimePeriodPickerType = {
  setStartDate: Dispatch<SetStateAction<moment.Moment>>;
  setEndDate: Dispatch<SetStateAction<moment.Moment>>;
};

function TimePeriodPicker(props: TimePeriodPickerType) {
  const { setStartDate, setEndDate } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();
  const anchorRef = useRef(null);

  // state
  const [selectedRange, setSelectedRange] = useState(LAST_30_DAYS);
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
  const [customDateRangeLabel, setCustomDateRangeLabel] =
    useState("Custom Range");
  const [errorText, setErrorText] = useState(null);
  const [textInputSavedStartDate, setTextInputSavedStartDate] = useState(null);
  const [textInputSavedEndDate, setTextInputSavedEndDate] = useState(null);

  // event handlers
  const handleSelectedRangeChange = (event: SelectChangeEvent<any>) => {
    const nextSelectedRange = event.target.value;
    setSelectedRange(nextSelectedRange);

    setErrorText(null);

    setCustomDateRangeLabel("Custom Range");

    if (nextSelectedRange === LAST_30_DAYS) {
      setStartDate(moment().utc(true).subtract(30, "days"));
      setEndDate(moment().utc(true));
    }

    if (nextSelectedRange === LAST_YEAR) {
      setStartDate(moment().utc(true).subtract(1, "year").startOf("year"));
      setEndDate(moment().utc(true).subtract(1, "year").endOf("year"));
    }
    if (nextSelectedRange === THIS_YEAR) {
      setStartDate(moment().utc(true).startOf("year"));
      setEndDate(moment().utc(true).endOf("year"));
    }
  };

  const handleDateTimeChange = (
    dateRange: moment.Moment[],
    dateChangeFromInput?: string
  ) => {
    setErrorText(null);
    const firstDateRange = first(dateRange)?.utc(true);
    const lastDateRange = last(dateRange)?.utc(true);

    if (!dateChangeFromInput && firstDateRange && lastDateRange) {
      setStartDate(firstDateRange);
      setEndDate(last(dateRange));
    }

    if (
      firstDateRange &&
      dateChangeFromInput === firstDateRange.format("MM/DD/YYYY")
    ) {
      setTextInputSavedStartDate(() => firstDateRange);
    }

    if (
      lastDateRange &&
      dateChangeFromInput === lastDateRange.format("MM/DD/YYYY")
    ) {
      setTextInputSavedEndDate(() => lastDateRange);
    }

    const textInputStartDate = textInputSavedStartDate || firstDateRange;
    const textInputEndDate = textInputSavedEndDate || lastDateRange;

    if (
      textInputStartDate &&
      textInputEndDate &&
      textInputEndDate.isBefore(textInputStartDate)
    ) {
      setErrorText("End date must be after start date");
      return;
    }
  };

  const handleCustomSelectorLablel = (dateRange) => {
    const formattedStartDate = moment(first(dateRange)).format("MM/DD/YY");
    const formattedEndDate = moment(last(dateRange)).format("MM/DD/YY");

    setCustomDateRangeLabel(
      moment(first(dateRange)).isSame(last(dateRange))
        ? formattedStartDate
        : `${formattedStartDate} - ${formattedEndDate}`
    );
  };

  const handleDateTimeAccept = () => {
    // when custom range from calendar view
    track("dashboard_customRangeApplied");
  };

  // effects
  useEffect(() => {
    if (
      textInputSavedStartDate &&
      textInputSavedEndDate &&
      textInputSavedStartDate.isBefore(textInputSavedEndDate)
    ) {
      // when custom range from text input view
      track("dashboard_customRangeApplied");
      setStartDate(textInputSavedStartDate);
      setEndDate(textInputSavedEndDate);
      handleCustomSelectorLablel([
        textInputSavedStartDate,
        textInputSavedEndDate,
      ]);
      setTextInputSavedStartDate(null);
      setTextInputSavedEndDate(null);
      setErrorText(null);
      setOpenDateRangePicker(false);
    }
  }, [
    textInputSavedStartDate,
    textInputSavedEndDate,
    setStartDate,
    setEndDate,
    track,
  ]);

  return (
    <Stack direction={isMobileView ? "column" : "row"} width="100%">
      <Box ref={anchorRef}>
        <MoovsSelect
          title="timePeriodPicker"
          items={[
            THIS_YEAR,
            LAST_YEAR,
            LAST_30_DAYS,
            {
              key: CUSTOM_RANGE,
              value: customDateRangeLabel,
              onClick: () => setOpenDateRangePicker(true),
            },
          ]}
          value={
            selectedRange === CUSTOM_RANGE
              ? customDateRangeLabel
              : selectedRange
          }
          onChange={handleSelectedRangeChange}
          sx={{ minWidth: isMobileView ? "100%" : 170 }}
        />
      </Box>
      <AnchorPopper
        anchorRef={anchorRef.current}
        open={openDateRangePicker}
        onClickAway={() => setOpenDateRangePicker(false)}
        placement={isMobileView ? "bottom" : "bottom-end"}
      >
        {openDateRangePicker && (
          <Box mt={1}>
            <StaticDateRangePicker
              disableCloseOnSelect={false}
              open={openDateRangePicker}
              onChange={handleDateTimeChange}
              onAccept={handleDateTimeAccept}
              onClose={() => setOpenDateRangePicker(false)}
              value={[null, null]}
              renderInput={(startProps, endProps) => (
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center">
                    <TextField {...startProps} variant="outlined" />
                    <Box sx={{ mx: 2 }}> - </Box>
                    <TextField variant="outlined" {...endProps} />
                  </Box>
                  <Typography
                    color={errorRed}
                    variant="body2"
                    sx={{ fontWeight: 500, textAlign: "right", pt: 1 }}
                  >
                    {errorText}
                  </Typography>
                </Box>
              )}
            />
          </Box>
        )}
      </AnchorPopper>
    </Stack>
  );
}

export default TimePeriodPicker;
