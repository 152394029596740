import React from "react";
import { useHistory } from "react-router-dom";

import { Box, Typography, IconButton } from "@mui/material";
import { AppointmentTooltip } from "@devexpress/dx-react-scheduler-material-ui";

import { EditIcon } from "../../../../design-system/icons";
import { useAnalytics } from "../../../../globals/hooks";
import { FarmRelationshipEnum } from "../../../../types";

// custom appointmentTooltipHeader component
function CustomAppointmentTooltipHeader(props: AppointmentTooltip.HeaderProps) {
  const { title, routeId, isFarmedRoute, farmRelationship } =
    props.appointmentData;
  const history = useHistory();
  const { track } = useAnalytics();

  const isFarmor = farmRelationship === FarmRelationshipEnum.Farmor;

  // show button if route is not farmed out, or it is farmed out and the user is farmee
  const shouldShowButton = !isFarmedRoute || (isFarmedRoute && isFarmor);

  const handleNavigateToTripClick = () => {
    track("dispatch_opened");
    history.push(`/dispatch/update/${routeId}`);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      flex="1"
      p={3}
    >
      <Typography variant="h4">{title}</Typography>
      {shouldShowButton && (
        <IconButton onClick={handleNavigateToTripClick} size="large">
          <EditIcon />
        </IconButton>
      )}
    </Box>
  );
}

export default CustomAppointmentTooltipHeader;
