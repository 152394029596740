import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";

import { granite, stripeAccountStatusLabelColors } from "design-system/colors";
import { InfoIcon } from "design-system/icons";
import { primaryMainColor } from "theme";
import RoundChip from "design-system/components/chips/RoundChip";
import {
  CREATE_ONBOARDING_STRIPE_ACCOUNT_MUTATION,
  CREATE_STRIPE_ACCOUNT_LINK_MUTATION,
} from "globals/graphql";
import { useAnalytics, useScreenSize, useSnackbar } from "globals/hooks";
import AddBankAccountDialog from "../../../AddBankAccountDialog";
import BankAccountsList from "../../../BankAccountsBlock/BankAccountsList";
import { StripeAccountStatusEnum } from "types";
import { useStripeAccount } from "./hooks";

type MoovsPaymentsBlockProps = {
  hasStripeAccount: boolean;
};

const businessTypeOptions = [
  {
    type: "company",
    label: "Incorporated business",
  },
  {
    type: "individual",
    label: "Individual or sole proprietorship",
  },
];

const styles = {
  tooltipIconButton: {
    padding: 0,
  },
};

function MoovsPaymentsBlock(props: MoovsPaymentsBlockProps) {
  const { hasStripeAccount } = props;

  const returnUrl = window.location.href;

  // state
  const [businessType, setBusinessType] = useState("");
  const [addBankAccountDialogOpen, setAddBankAccountDialogOpen] =
    useState(false);
  const [completeAccountSetupLoading, setCompleteAccountSetupLoading] =
    useState(false);

  // hooks
  const snackbar = useSnackbar();
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();
  const {
    bankAccounts,
    accountStatus,
    accountStatusDescription,
    isAccountSetupComplete,
    stripeBankAccountLoading,
    stripeBankAccountError,
  } = useStripeAccount();

  // mutations
  const [createOnboardingStripeAccount] = useMutation(
    CREATE_ONBOARDING_STRIPE_ACCOUNT_MUTATION,
    {
      onCompleted(data) {
        const { accountLink } = data.createOnboardingStripeAccount;

        if (accountLink) {
          track("moovsPayments_accountSetup");
          window.location.replace(accountLink.url);
        } else {
          setCompleteAccountSetupLoading(false);
        }
      },
      onError(error) {
        setCompleteAccountSetupLoading(false);

        const errorMessage =
          getErrorMessage(error) || "Error creating Stripe account";
        snackbar.error(errorMessage);
      },
    }
  );

  const [createStripeAccountLink] = useMutation(
    CREATE_STRIPE_ACCOUNT_LINK_MUTATION,
    {
      onCompleted(data) {
        const { accountLink } = data.createStripeAccountLink;

        if (accountLink) {
          track("moovsPayments_accountSetup");
          window.location.replace(accountLink.url);
        } else {
          setCompleteAccountSetupLoading(false);
        }
      },
      onError() {
        setCompleteAccountSetupLoading(false);
        snackbar.error("Error redirecting to Connect Onboarding");
      },
    }
  );

  // handlers
  const handleCompleteAccountSetup = async () => {
    setCompleteAccountSetupLoading(true);
    createOnboardingStripeAccount({
      variables: {
        input: {
          businessType,
          returnUrl,
        },
      },
    });
  };

  const handleUpdateStripeAccountSetup = () => {
    setCompleteAccountSetupLoading(true);
    createStripeAccountLink({
      variables: {
        input: {
          returnUrl,
        },
      },
    });
  };

  const toggleAddBankAccountDialog = () => {
    track("payments_bankAccountInitiated");
    setAddBankAccountDialogOpen(!addBankAccountDialogOpen);
  };

  const isShowStatusChip = hasStripeAccount && accountStatus;

  const hideCompleteAccountSetup =
    (hasStripeAccount &&
      (accountStatus === StripeAccountStatusEnum.Complete ||
        accountStatus === StripeAccountStatusEnum.Pending)) ||
    isAccountSetupComplete;

  return (
    <Box>
      <Box
        mb={hasStripeAccount ? 2 : 1}
        display="flex"
        flexDirection="row"
        gap={1}
      >
        <Typography
          style={{ fontWeight: 600, fontSize: "16px" }}
          variant="subtitle1"
        >
          Moovs Payments
        </Typography>
        {isShowStatusChip && (
          <Tooltip
            enterDelay={200}
            enterNextDelay={200}
            title={
              <Typography
                style={{ whiteSpace: "pre-line" }}
                variant={"caption"}
              >
                {accountStatusDescription}
              </Typography>
            }
            placement="top"
          >
            <IconButton
              aria-label="info"
              sx={styles.tooltipIconButton}
              size="large"
            >
              <RoundChip
                icon={InfoIcon}
                {...stripeAccountStatusLabelColors[accountStatus]}
              />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      {!hasStripeAccount && (
        <Box mb={2}>
          <FormControl component="fieldset">
            <RadioGroup
              name="businessType"
              value={businessType}
              onChange={(event) => setBusinessType(event.target.value)}
            >
              {businessTypeOptions.map((option) => (
                <FormControlLabel
                  key={option.type}
                  value={option.type}
                  control={
                    <Radio
                      sx={{
                        color: primaryMainColor,
                      }}
                    />
                  }
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      )}

      {stripeBankAccountLoading ? (
        <CircularProgress />
      ) : (
        <>
          {/* Bank account cards */}
          {hasStripeAccount && (
            <BankAccountsList
              bankAccounts={bankAccounts}
              error={stripeBankAccountError?.message}
            />
          )}

          <Box mb={2}>
            <Box
              mb={2}
              display={"flex"}
              flexDirection={isMobileView ? "column" : "row"}
              gap={2}
            >
              {!hideCompleteAccountSetup && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={
                    hasStripeAccount
                      ? handleUpdateStripeAccountSetup
                      : handleCompleteAccountSetup
                  }
                  disabled={
                    (!hasStripeAccount && businessType === "") ||
                    completeAccountSetupLoading
                  }
                >
                  Complete Account Setup
                  {completeAccountSetupLoading && (
                    <CircularProgress
                      size={18}
                      thickness={2}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Button>
              )}
              <Button
                color="primary"
                variant="outlined"
                onClick={toggleAddBankAccountDialog}
                disabled={!hasStripeAccount}
              >
                Add Bank Account
              </Button>
              <AddBankAccountDialog
                open={addBankAccountDialogOpen}
                onClose={toggleAddBankAccountDialog}
              />
            </Box>

            <Typography style={{ color: granite }} variant="caption">
              Complete account setup and add bank account in order to receive
              credit card payments.
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
}

export default MoovsPaymentsBlock;
