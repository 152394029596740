import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import {
  Box,
  Drawer,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";

import {
  granite,
  grayMedium,
  white,
  blueLabelText,
} from "../../../../../../design-system/colors";
import RoundChip from "../../../../../../design-system/components/chips/RoundChip";
import BillingDisplay from "../../../../../../components/common/BillingDisplay";
import { ArrowLeftIcon, TripIcon, EditIcon } from "design-system/icons";
import { Request, RequestStage } from "../../../../../../types";
import { orderTypeEnumToNameMap } from "../../../../../../globals/utils/enumMaps";
import MoovsEllipsisMenu, {
  EllipsisMenuOption,
} from "components/MoovsEllipsisMenu";
import theme from "theme";
import EditOrderTypeDialog from "../../../EditOrderTypeDialog";
import BookingContact from "components/contacts/bookingContact/BookingContact";
import { deriveBookingContact } from "globals/utils/farm";
import OverflowTooltip from "design-system/components/OverflowTooltip";
import { QuoteTripItemsMobile } from "./components";
import { SalesAutomationDialog } from "../QuoteOverviewDesktop/components";
import { CompanyBlock } from "pages/reservations/components/TripsPanel/components/OrderSummary/components";

const DRAWER_WIDTH = 700;

const styles = {
  drawer: {
    width: "100vw",
    [theme.breakpoints.up(DRAWER_WIDTH)]: {
      width: DRAWER_WIDTH,
    },
    margin: 0,
  },
  drawerHeader: {
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 2,
    [theme.breakpoints.down("lg")]: {
      borderBottom: `none`,
    },
    "& .MuiIconButton-colorPrimary": { color: granite },
  },
};

type QuoteOverviewMobileProps = {
  ellipsisMenuOptions: EllipsisMenuOption[];
  request: Request;
  editOrderTypeDialogOpen: boolean;
  setEditOrderTypeDialogOpen: (e: any) => void;
  whiteLabelOperatorName: string | undefined;
  onEditOrderType: () => void;
  salesAutomationOpen: boolean;
  handleSalesAutomationClose: () => void;
};

function QuoteOverviewMobile(props: QuoteOverviewMobileProps) {
  const {
    ellipsisMenuOptions,
    request,
    editOrderTypeDialogOpen,
    setEditOrderTypeDialogOpen,
    whiteLabelOperatorName,
    onEditOrderType,
    salesAutomationOpen,
    handleSalesAutomationClose,
  } = props;

  // hooks
  const location = useLocation();
  const history = useHistory();
  const { requestId } = useParams<{ requestId: string }>();

  // state
  const [drawerOpen, setDrawerOpen] = useState(true);

  // derived state
  const { orderNumber, trips = [], totalAmount, orderType } = request;
  const bookingContact = deriveBookingContact(request);
  const company = request.company || request.bookingContact.company;

  // event handlers
  const handleDrawerClose = () => {
    setDrawerOpen(false);
    // waits for drawer animation to end

    setTimeout(() => {
      history.push(`/quotes/${location.search}`);
    }, 195);
  };

  return (
    <Drawer
      open={drawerOpen}
      onClose={handleDrawerClose}
      anchor="right"
      disableEnforceFocus // allows update drawer to have focus on reload
    >
      {request ? (
        <Box
          sx={styles.drawer}
          display="flex"
          flexDirection="column"
          overflow="auto"
        >
          {/* Header */}
          <Box
            zIndex={10}
            bgcolor={white}
            position="sticky"
            top="0"
            borderBottom={`1px solid ${grayMedium}`}
            px={2}
          >
            <Box
              mt={2}
              mb={1.5}
              sx={styles.drawerHeader}
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              display="flex"
            >
              <Box display="flex">
                <IconButton
                  edge="start"
                  onClick={handleDrawerClose}
                  size="large"
                >
                  <ArrowLeftIcon />
                </IconButton>
                <Box display="flex" alignItems="center">
                  <Typography variant="h1">{orderNumber}</Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="center">
                <Box display="flex" flex=".6" justifyContent="flex-end">
                  <RoundChip
                    variant={request.isArchived ? "ARCHIVED" : request.status}
                  />
                </Box>

                {/* Ellipsis */}
                {ellipsisMenuOptions && (
                  <MoovsEllipsisMenu
                    variant="withMoreText"
                    options={ellipsisMenuOptions}
                  />
                )}
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" my={1.5}>
              <Box display="flex" alignItems="center">
                <IconButton disabled size="small">
                  <TripIcon size="small" />
                </IconButton>
                <Box mx={1.5}>
                  <Typography
                    variant="overline"
                    style={{ color: granite }}
                    noWrap
                  >
                    {trips.length} {trips.length === 1 ? "TRIP" : "TRIPS"}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" ml={2}>
                <Typography variant="overline" style={{ color: granite }}>
                  {orderTypeEnumToNameMap[orderType]}
                </Typography>
                <Box ml={0.5}>
                  <IconButton onClick={onEditOrderType} sx={{ padding: 0 }}>
                    <EditIcon color={blueLabelText} />
                  </IconButton>
                </Box>
              </Box>

              {whiteLabelOperatorName && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  flex={1}
                  ml={1}
                  overflow="hidden"
                >
                  <OverflowTooltip
                    toolTipText={`Sourced by ${whiteLabelOperatorName}`}
                    displayText={whiteLabelOperatorName}
                    placement="top"
                    variant="body2"
                  />
                </Box>
              )}
            </Box>
          </Box>

          {/* Content */}
          <Box mt={2} px={2}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              mb={2.5}
            >
              <BookingContact
                requestStage={RequestStage.Quote}
                requestId={requestId}
                bookingContact={bookingContact}
                canEditBookingContact
                companyId={request.company?.id}
              />

              {company && (
                <CompanyBlock
                  requestId={request.id}
                  isLinkedCompanyRequest={!!request.company}
                  companyName={company.name}
                  stage={request.stage}
                />
              )}

              <Box>
                <Box mb="2px">
                  <Typography variant="overline">total amount</Typography>
                </Box>
                <Box>
                  <BillingDisplay amount={totalAmount} />
                </Box>
              </Box>
            </Box>
            <Box>{request && <QuoteTripItemsMobile request={request} />}</Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={styles.drawer}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={40} thickness={2} />
        </Box>
      )}
      <EditOrderTypeDialog
        open={editOrderTypeDialogOpen}
        onClose={() => setEditOrderTypeDialogOpen(false)}
        requestId={requestId}
        orderType={orderType}
      />

      <SalesAutomationDialog
        open={salesAutomationOpen}
        onClose={handleSalesAutomationClose}
        requestId={request.id}
        orderNumber={orderNumber}
      />
    </Drawer>
  );
}

export default QuoteOverviewMobile;
