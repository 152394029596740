import React, { ChangeEvent } from "react";

import { MenuItem, TextField, Typography } from "@mui/material";

import { PaymentMethod, PaymentMethodEnum } from "types";
import { useOperator } from "globals/hooks";
import { CreditCardMenuItemContent } from "./components/CreditCardMenuItemContent";

type MethodSelectDropdownProps = {
  methodError: string;
  onMethodInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  paymentMethods: PaymentMethod[];
  paymentMethodValue: string;
  preferredPaymentMethodId?: string;
  companyPaymentMethods?: PaymentMethod[];
  label?: string;
};

function MethodSelectDropdown(props: MethodSelectDropdownProps) {
  const {
    methodError,
    onMethodInputChange,
    paymentMethods,
    paymentMethodValue,
    preferredPaymentMethodId,
    companyPaymentMethods,
    label,
  } = props;

  // hooks
  const operator = useOperator();

  const hasMoovsPayments = !!operator.stripeAccountId;

  return (
    <TextField
      select
      fullWidth
      variant="outlined"
      error={!!methodError}
      helperText={methodError}
      value={paymentMethodValue}
      onChange={onMethodInputChange}
      label={label}
    >
      <MenuItem disabled style={{ opacity: 1 }}>
        Payment Method
      </MenuItem>

      {/* only show contact label if company cards also exist */}
      {paymentMethods.length && companyPaymentMethods?.length && (
        <MenuItem disabled>
          <Typography variant="h6">CONTACT</Typography>
        </MenuItem>
      )}

      {paymentMethods.map((paymentMethod: PaymentMethod, index) => {
        const { card } = paymentMethod;
        const isPreferredCard = preferredPaymentMethodId === card.id;
        const isLastItem = index === paymentMethods.length - 1;

        return (
          <MenuItem value={paymentMethod.id} key={card.id} divider={isLastItem}>
            <CreditCardMenuItemContent
              paymentMethod={paymentMethod}
              card={card}
              isPreferredCard={isPreferredCard}
            />
          </MenuItem>
        );
      })}

      {companyPaymentMethods?.length && (
        <MenuItem disabled>
          <Typography variant="h6">COMPANY</Typography>
        </MenuItem>
      )}

      {companyPaymentMethods &&
        companyPaymentMethods.map((paymentMethod: PaymentMethod, index) => {
          const { card } = paymentMethod;
          const isPreferredCard = preferredPaymentMethodId === card.id;
          const isLastItem = index === companyPaymentMethods.length - 1;

          return (
            <MenuItem
              value={paymentMethod.id}
              key={card.id}
              divider={isLastItem}
            >
              <CreditCardMenuItemContent
                paymentMethod={paymentMethod}
                card={card}
                isPreferredCard={isPreferredCard}
              />
            </MenuItem>
          );
        })}

      {!hasMoovsPayments && (
        <MenuItem value={PaymentMethodEnum.GenericCard}>Credit Card</MenuItem>
      )}
      <MenuItem value={PaymentMethodEnum.Cash}>Cash</MenuItem>
      <MenuItem value={PaymentMethodEnum.Venmo}>Venmo</MenuItem>
      <MenuItem value={PaymentMethodEnum.Check}>Check</MenuItem>
      <MenuItem value={PaymentMethodEnum.Wire}>Wire</MenuItem>
    </TextField>
  );
}

export default MethodSelectDropdown;
