import React from "react";

import { orange, tintOrange } from "design-system/colors";
import RoundChip from "design-system/components/chips/RoundChip";

function ComingSoonChip() {
  return (
    <RoundChip
      label="COMING SOON"
      color={orange}
      backgroundColor={tintOrange}
    />
  );
}

export default ComingSoonChip;
