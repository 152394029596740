import {
  aqua,
  grass,
  grayDark,
  greenDark,
  moovsBlue,
  navy,
  purple,
  purpleDark,
  redDark,
  rose,
  sky,
} from "design-system/colors";

export const colorThemeOptions = {
  MOOVS: {
    label: "Moovs (Default)",
    value: "MOOVS",
    color: moovsBlue,
  },
  SKY: {
    label: "Sky",
    value: "SKY",
    color: sky,
  },
  NAVY: {
    label: "Navy",
    value: "NAVY",
    color: navy,
  },
  LAVENDER: {
    label: "Lavender",
    value: "LAVENDER",
    color: purple,
  },
  VIOLET: {
    label: "Violet",
    value: "VIOLET",
    color: purpleDark,
  },
  ROSE: {
    label: "Rose",
    value: "ROSE",
    color: rose,
  },
  BURGUNDY: {
    label: "Burgundy",
    value: "BURGUNDY",
    color: redDark,
  },
  MIDNIGHT: {
    label: "Midnight",
    value: "MIDNIGHT",
    color: grayDark,
  },
  FOREST: {
    label: "Forest",
    value: "FOREST",
    color: greenDark,
  },
  GRASS: {
    label: "Grass",
    value: "GRASS",
    color: grass,
  },
  OCEAN: {
    label: "Ocean",
    value: "OCEAN",
    color: aqua,
  },
};
