import { useQuery } from "@apollo/client";
import map from "lodash/map";
import { Trip } from "types";

import { LOAD_PROMO_CODE_RESULTS_QUERY } from "globals/graphql";

type useLoadPromoCodeResultsProps = {
  promoCodeId: string;
};

export function useLoadPromoCodeResults(props: useLoadPromoCodeResultsProps) {
  const { promoCodeId } = props;

  // queries
  const { data, loading: tripsLoading } = useQuery(
    LOAD_PROMO_CODE_RESULTS_QUERY,
    {
      variables: {
        promoCodeId,
      },
      skip: !promoCodeId,
      fetchPolicy: "network-only",
    }
  );

  const trips: Trip[] = map(
    data?.loadPromoCodeResults?.edges,
    (trip) => trip?.node
  );

  return {
    trips,
    tripsLoading,
  };
}
