import React, { useMemo } from "react";
import clipboardCopy from "clipboard-copy";

import { Box, Button, Typography } from "@mui/material";

import { CopyIcon } from "design-system/icons";
import { grayMedium, white } from "design-system/colors";
import {
  useAnalytics,
  useOperator,
  useScreenSize,
  useSnackbar,
} from "globals/hooks";

function IframeCode() {
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();
  const snackbar = useSnackbar();
  const { nameSlug } = useOperator();

  const codeSnippet = useMemo(
    () =>
      `<iframe src="${process.env.REACT_APP_CUSTOMERAPP_URL}/${nameSlug}/iframe" title="Moovs App"></iframe>`,

    [nameSlug]
  );

  const handleCopyCode = async () => {
    try {
      await clipboardCopy(codeSnippet);
      snackbar.success(`Code copied to clipboard`);
      track("bookingToolEnhancements_iframeCopied");
    } catch (error) {
      snackbar.error("Could not copy code");
    }
  };

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      alignItems="flex-start"
      borderRadius={1}
      bgcolor={`${grayMedium}72`}
      p={2}
      mt={2}
      flexDirection="column"
    >
      <Typography variant="body2" whiteSpace="break-spaces">
        {codeSnippet}
      </Typography>
      <Box
        mt={2}
        width="100%"
        display="flex"
        justifyContent="flex-start"
        pl={1}
      >
        <Button
          variant="contained"
          onClick={handleCopyCode}
          fullWidth={isMobileView}
          endIcon={<CopyIcon size="xs" color={white} />}
        >
          Copy&nbsp;code
        </Button>
      </Box>
    </Box>
  );
}

export { IframeCode };
