import { ChangeEvent, useEffect, useState } from "react";

const SUBJECT_LIMIT = 256;

type UseEmailSubjectProps = {
  open: boolean;
};

function useEmailSubject(props: UseEmailSubjectProps) {
  const { open } = props;

  // state
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState("");

  // event handlers
  const handleSubjectChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (subject.length >= SUBJECT_LIMIT) {
      setSubjectError("Character limit exceeded");
    } else {
      setSubjectError("");
    }

    setSubject(event.target.value);
  };

  const handleValidateSubject = () => {
    let subjectError = "";

    if (subject.length >= SUBJECT_LIMIT) {
      subjectError = "Character limit exceeded";
    }

    if (!subject.length) {
      subjectError = "Subject is required";
    }

    setSubjectError(subjectError);

    return !subjectError;
  };

  // effects
  // reset subject on dialog open
  useEffect(() => {
    if (open) {
      setSubject("");
      setSubjectError("");
    }
  }, [open]);

  return {
    subject,
    subjectError,
    onSubjectChange: handleSubjectChange,
    onValidateSubject: handleValidateSubject,
  };
}

export default useEmailSubject;
