import React, { useState } from "react";

import { DueIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import { useAnalytics } from "globals/hooks";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";

type UseViewActivityLogClickProps = {
  analyticsName?: string;
  isFarmee: boolean;
};

function useViewActivityLogClick(
  props: UseViewActivityLogClickProps
): EllipsisMenuOption & { open: boolean; onClose: () => void } {
  const { analyticsName, isFarmee } = props;

  // hooks
  const { track } = useAnalytics();

  // state
  const [activityLogOpen, setActivityLogOpen] = useState(false);

  // event handlers
  const handleActivityLogOpen = () => {
    setActivityLogOpen(true);
  };

  const handleActivityLogClose = () => {
    setActivityLogOpen(false);
  };

  const handleViewActivityLogClick = () => {
    handleActivityLogOpen();

    if (analyticsName) track(analyticsName);
  };

  return {
    text: "View Activity Log",
    icon: <DueIcon color={grayDark} size="small" />,
    onClick: handleViewActivityLogClick,
    hideOption: isFarmee,

    // dialog state
    open: activityLogOpen,
    onClose: handleActivityLogClose,
  };
}

export { useViewActivityLogClick };
