import React from "react";

import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { currency } from "globals/utils/helpers";
import { getTripTotalAmount } from "../utils";

function TripAmountColumn(props: GridRenderCellParams<string>) {
  const { row: trip } = props;

  const tripAmount = getTripTotalAmount(trip);

  return <Typography variant="body2">{currency(tripAmount)}</Typography>;
}

export default TripAmountColumn;
