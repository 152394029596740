import React, { ChangeEvent, useMemo } from "react";
import { Control, Controller, FieldPath, UseFormReturn } from "react-hook-form";
import isNumber from "lodash/isNumber";
import isNull from "lodash/isNull";

import { Typography, Box, MenuItem, Switch } from "@mui/material";

import { grayDark, granite, grayLight, black } from "design-system/colors";
import { InfoIcon } from "design-system/icons";
import {
  DollarInputLabeledInline,
  LabeledInlineSelect,
} from "design-system/components/inputs";
import { hourlyOptions } from "globals/utils/hourlyOptions";
import { LabelWrapper } from "design-system/components/inputs/inline/LabeledInlineInputs";
import { FarmAffiliateVehicle } from "types";
import { UpdateVehicleFormData } from "./UpdateVehicleDrawer/hooks/useUpdateVehicleForm";
import { VehicleWeekendsBlock } from "./VehicleWeekendsBlock";

const styles = {
  sectionSubTitle: {
    color: granite,
  },
};

type VehiclePricingBlockProps =
  | {
      vehicle: Partial<FarmAffiliateVehicle>;
      isReadOnly: true;
      formContext?: never;
      handleEnableBaseRateAutomationChange?: never;
    }
  | {
      vehicle?: never;
      isReadOnly?: never;
      formContext: UseFormReturn<UpdateVehicleFormData>;
      handleEnableBaseRateAutomationChange: (
        e: ChangeEvent<HTMLInputElement>
      ) => Promise<boolean>;
    };

function VehiclePricingBlock(props: VehiclePricingBlockProps) {
  const {
    vehicle,
    isReadOnly = false,
    formContext,
    handleEnableBaseRateAutomationChange,
  } = props;

  const { control, watch } = formContext || {
    control: null,
  };

  // derived state
  const vehiclePricing = useMemo(() => {
    if (!isReadOnly) return null;

    return {
      weekendHourlyCost: vehicle.weekendHourlyCost,
      weekdayHourlyCost: vehicle.weekdayHourlyCost,
      weekendMinMinutes: vehicle.weekendMinMinutes,
      weekdayMinMinutes: vehicle.weekdayMinMinutes,
      minimumTotalBaseRate: vehicle.minimumTotalBaseRate,
      deadheadRatePerMile: vehicle.deadheadRatePerMile,
      tripRatePerMile: vehicle.tripRatePerMile,
      totalDeadheadDurationMinutes: vehicle.totalDeadheadDurationMinutes,
      enableBaseRateAutomation: vehicle.enableBaseRateAutomation,
      settings: vehicle.settings,
    };
  }, [vehicle, isReadOnly]);

  // if control is null, form context is not available
  // fallback to true to keep disabled state
  const isBRAEnabled = control
    ? watch("vehicle.enableBaseRateAutomation")
    : true;
  const isDisabled = isReadOnly || isBRAEnabled;

  return (
    <>
      <Box mb={2} display="flex" flexDirection="column">
        <Box mb={2}>
          {isReadOnly && <Typography variant="h4">Pricing</Typography>}
          {!isReadOnly && isBRAEnabled && (
            <Box
              mt={1}
              p={2}
              flexDirection="row"
              alignItems="center"
              display="flex"
              flex="1"
              bgcolor={grayLight}
              color={grayDark}
              borderRadius="4px"
            >
              <InfoIcon color={black} />
              <Box ml="14px" display="flex" alignItems="flex-start">
                <Typography style={{ fontSize: "12px", color: granite }}>
                  Disable base rate automation to edit pricing fields.
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        {/* Transfer Pricing */}
        <Typography variant="overline" sx={styles.sectionSubTitle}>
          transfer
        </Typography>
        <ControllerWrapper
          control={control}
          name="vehicle.minimumTotalBaseRate"
          transformOnChange={(e) =>
            e.target.value === "" ? null : Number(e.target.value)
          }
          deps={["vehicle.deadheadRatePerMile", "vehicle.tripRatePerMile"]}
        >
          <DollarInputLabeledInline
            disabled={isDisabled}
            label="Minimum Total Base Rate"
            labelSize="large"
            {...(isReadOnly && {
              value: vehiclePricing.minimumTotalBaseRate,
            })}
          />
        </ControllerWrapper>
        <ControllerWrapper
          control={control}
          name="vehicle.deadheadRatePerMile"
          transformOnChange={(e) =>
            e.target.value === "" ? null : Number(e.target.value)
          }
          deps={["vehicle.minimumTotalBaseRate", "vehicle.tripRatePerMile"]}
        >
          <DollarInputLabeledInline
            disabled={isDisabled}
            label="Deadhead Rate per Mile"
            labelSize="large"
            {...(isReadOnly && {
              value: vehiclePricing.deadheadRatePerMile,
            })}
          />
        </ControllerWrapper>
        <ControllerWrapper
          control={control}
          name="vehicle.tripRatePerMile"
          transformOnChange={(e) =>
            e.target.value === "" ? null : Number(e.target.value)
          }
          deps={["vehicle.minimumTotalBaseRate", "vehicle.deadheadRatePerMile"]}
        >
          <DollarInputLabeledInline
            disabled={isDisabled}
            label="Trip Rate per Mile"
            labelSize="large"
            {...(isReadOnly && {
              value: vehiclePricing.tripRatePerMile,
            })}
          />
        </ControllerWrapper>

        {/* Hourly Pricing */}
        <Box my={1}>
          <Typography variant="overline" sx={styles.sectionSubTitle}>
            hourly
          </Typography>
        </Box>
        <ControllerWrapper
          control={control}
          name="vehicle.totalDeadheadDurationMinutes"
          transformOnChange={(e) =>
            isNull(e.target.value)
              ? e.target.value
              : Number(e.target.value) * 60
          }
          transformValue={(value) => (isNull(value) ? value : value / 60)}
        >
          <LabeledInlineSelect
            disabled={isDisabled}
            label="Total Deadhead Duration"
            labelSize="large"
            {...(isReadOnly && {
              value: isNumber(vehiclePricing.totalDeadheadDurationMinutes)
                ? vehiclePricing.totalDeadheadDurationMinutes / 60
                : null,
            })}
          >
            {hourlyOptions.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {isNull(option) ? "No Deadhead" : `${option} Hours`}
                </MenuItem>
              );
            })}
          </LabeledInlineSelect>
        </ControllerWrapper>
        <ControllerWrapper
          control={control}
          name="vehicle.weekdayHourlyCost"
          transformOnChange={(e) =>
            e.target.value === "" ? null : Number(e.target.value)
          }
          deps={["vehicle.weekdayMinMinutes"]}
        >
          <DollarInputLabeledInline
            disabled={isDisabled}
            label="Weekday Hourly Rate"
            labelSize="large"
            {...(isReadOnly && {
              value: vehiclePricing.weekdayHourlyCost,
            })}
          />
        </ControllerWrapper>
        <ControllerWrapper
          control={control}
          name="vehicle.weekdayMinMinutes"
          transformOnChange={(e) =>
            isNull(e.target.value)
              ? e.target.value
              : Number(e.target.value) * 60
          }
          transformValue={(value) => (isNull(value) ? value : value / 60)}
          deps={["vehicle.weekdayHourlyCost"]}
        >
          <LabeledInlineSelect
            disabled={isDisabled}
            label="Weekday Hourly Minimum"
            labelSize="large"
            {...(isReadOnly && {
              value: isNumber(vehiclePricing.weekdayMinMinutes)
                ? vehiclePricing.weekdayMinMinutes / 60
                : null,
            })}
          >
            {hourlyOptions.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {isNull(option) ? "None" : `${option} Hours`}
                </MenuItem>
              );
            })}
          </LabeledInlineSelect>
        </ControllerWrapper>
        <ControllerWrapper
          control={control}
          name="vehicle.weekendHourlyCost"
          transformOnChange={(e) =>
            e.target.value === "" ? null : Number(e.target.value)
          }
          deps={["vehicle.weekendMinMinutes", "vehicle.weekends"]}
        >
          <DollarInputLabeledInline
            disabled={isDisabled}
            label="Weekend Hourly Rate"
            labelSize="large"
            {...(isReadOnly && {
              value: vehiclePricing.weekendHourlyCost,
            })}
          />
        </ControllerWrapper>
        <ControllerWrapper
          control={control}
          name="vehicle.weekendMinMinutes"
          transformOnChange={(e) => {
            return isNull(e.target.value)
              ? e.target.value
              : Number(e.target.value) * 60;
          }}
          transformValue={(value) => (isNull(value) ? value : value / 60)}
          deps={["vehicle.weekendHourlyCost", "vehicle.weekends"]}
        >
          <LabeledInlineSelect
            disabled={isDisabled}
            label="Weekend Hourly Minimum"
            labelSize="large"
            {...(isReadOnly && {
              value: isNumber(vehiclePricing.weekendMinMinutes)
                ? vehiclePricing.weekendMinMinutes / 60
                : null,
            })}
          >
            {hourlyOptions.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {isNull(option) ? "None" : `${option} Hours`}
                </MenuItem>
              );
            })}
          </LabeledInlineSelect>
        </ControllerWrapper>

        <LabelWrapper
          inputName="Vehicle Settings"
          label="Choose Your Weekends"
          value="Vehicle Settings"
          size="large"
        >
          <ControllerWrapper
            control={control}
            name="vehicle.weekends"
            deps={["vehicle.weekendHourlyCost", "vehicle.weekendMinMinutes"]}
            transformOnChange={(_, newWeekendsValue) => newWeekendsValue}
          >
            <VehicleWeekendsBlock
              transparent
              disabled={isDisabled}
              {...(isReadOnly && {
                value: vehiclePricing?.settings?.weekends,
              })}
              // onChange is required for the component to work, onChange will be handled by the wrapper
              onChange={() => {}}
            />
          </ControllerWrapper>
        </LabelWrapper>
      </Box>

      {/* Enable Base Rate Automation */}
      {!isReadOnly && (
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box display="flex" ml={-1}>
            <ControllerWrapper
              control={control}
              name="vehicle.enableBaseRateAutomation"
              transformOnChange={handleEnableBaseRateAutomationChange}
            >
              <Switch checked={isBRAEnabled} color="primary" />
            </ControllerWrapper>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box>
              <Typography
                variant="caption"
                style={{ color: granite, fontSize: "14px", fontWeight: 500 }}
              >
                Enable Base Rate Automation
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

type ControllerWrapperProps<T = UpdateVehicleFormData> = {
  control: Control<T> | null;
  name: FieldPath<T>;
  children: React.ReactNode;
  transformValue?: (value) => any;
  transformOnChange?: (...args: any[]) => any;
  deps?: FieldPath<T>[];
};

function ControllerWrapper({
  control,
  name,
  children,
  transformValue,
  transformOnChange,
  deps,
}: ControllerWrapperProps) {
  if (control) {
    return (
      <Controller
        control={control}
        name={name}
        {...(deps && {
          rules: {
            deps,
          },
        })}
        render={({
          field: { name, value, onChange },
          fieldState: { error },
        }) => {
          const handleOnChange = (...args: any[]) => {
            const event = args[0] as ChangeEvent<HTMLInputElement>;
            let value = event.target.value;

            if (transformOnChange) {
              value = transformOnChange(...args);
            }

            onChange(value);
          };

          return React.cloneElement(children as React.ReactElement, {
            name,
            value: transformValue ? transformValue(value) : value,
            onChange: handleOnChange,
            error: !!error,
            // required prop used to show error message for LabeledInlineSelect
            required: !!error,
            errorText: error?.message,
          });
        }}
      />
    );
  }

  // if no control, just return the children with the name
  return React.cloneElement(children as React.ReactElement, { name });
}

export default VehiclePricingBlock;
