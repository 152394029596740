import React from "react";

type PDFIconProps = {
  color?: string;
  size?: "small";
  width?: string;
  height?: string;
  viewBox?: string;
};

const PDFIcon = (props: PDFIconProps) => {
  const { color, size, width, height, viewBox } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.85714 14V9.7636C8.85714 9.52319 8.95264 9.29263 9.12264 9.12264C9.29263 8.95264 9.52319 8.85714 9.7636 8.85714H13.5714M8.8688 14H2.8C2.58783 14 2.38434 13.9157 2.23431 13.7657C2.08429 13.6157 2 13.4122 2 13.2V2.8C2 2.58783 2.08429 2.38434 2.23431 2.23431C2.38434 2.08429 2.58783 2 2.8 2H13.2C13.4122 2 13.6157 2.08429 13.7657 2.23431C13.9157 2.38434 14 2.58783 14 2.8V8.8688C14 9.08083 13.9157 9.28416 13.7659 9.43413L9.43413 13.7659C9.28416 13.9157 9.08083 14 8.8688 14Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.3678 4.96581C6.3678 5.1396 6.32649 5.29915 6.24388 5.44444C6.16126 5.58689 6.03439 5.70228 5.86326 5.7906C5.69213 5.87892 5.47969 5.92308 5.22594 5.92308H4.75681V7H4V4H5.22594C5.47379 4 5.68328 4.04131 5.85441 4.12393C6.02554 4.20655 6.15389 4.32051 6.23945 4.46581C6.32502 4.61111 6.3678 4.77778 6.3678 4.96581ZM5.16841 5.34188C5.31298 5.34188 5.42068 5.30912 5.49149 5.24359C5.5623 5.17806 5.59771 5.08547 5.59771 4.96581C5.59771 4.84615 5.5623 4.75356 5.49149 4.68803C5.42068 4.62251 5.31298 4.58974 5.16841 4.58974H4.75681V5.34188H5.16841Z"
          fill="#999999"
        />
        <path
          d="M7.92578 4C8.25329 4 8.53949 4.06268 8.78439 4.18803C9.02928 4.31339 9.21811 4.49003 9.35089 4.71795C9.48661 4.94302 9.55447 5.2037 9.55447 5.5C9.55447 5.79345 9.48661 6.05413 9.35089 6.28205C9.21811 6.50997 9.0278 6.68661 8.77996 6.81197C8.53507 6.93732 8.25034 7 7.92578 7H6.7618V4H7.92578ZM7.8771 6.36752C8.1633 6.36752 8.38606 6.29202 8.54539 6.14103C8.70472 5.99003 8.78439 5.77635 8.78439 5.5C8.78439 5.22365 8.70472 5.00855 8.54539 4.8547C8.38606 4.70085 8.1633 4.62393 7.8771 4.62393H7.51861V6.36752H7.8771Z"
          fill="#999999"
        />
        <path
          d="M12 4V4.58547H10.7342V5.21795H11.6813V5.78632H10.7342V7H9.97741V4H12Z"
          fill="#999999"
        />
      </svg>
    );
  }

  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox={viewBox || "0 0 24 24"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2857 21V14.6454C13.2857 14.2848 13.429 13.9389 13.684 13.684C13.9389 13.429 14.2848 13.2857 14.6454 13.2857H20.3571M13.3032 21H4.2C3.88174 21 3.57652 20.8736 3.35147 20.6485C3.12643 20.4235 3 20.1183 3 19.8V4.2C3 3.88174 3.12643 3.57652 3.35147 3.35147C3.57652 3.12643 3.88174 3 4.2 3H19.8C20.1183 3 20.4235 3.12643 20.6485 3.35147C20.8736 3.57652 21 3.88174 21 4.2V13.3032C20.9999 13.6212 20.8736 13.9262 20.6488 14.1512L14.1512 20.6488C13.9262 20.8736 13.6212 20.9999 13.3032 21Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5517 7.60969C9.5517 7.89934 9.48974 8.16524 9.36582 8.40741C9.24189 8.64482 9.05158 8.83713 8.79489 8.98433C8.53819 9.13153 8.21953 9.20513 7.83892 9.20513H7.13522V11H6V6H7.83892C8.21068 6 8.52491 6.06885 8.78161 6.20655C9.03831 6.34425 9.23083 6.53419 9.35918 6.77635C9.48752 7.01852 9.5517 7.2963 9.5517 7.60969ZM7.75261 8.23647C7.96948 8.23647 8.13102 8.18186 8.23724 8.07265C8.34346 7.96344 8.39657 7.80912 8.39657 7.60969C8.39657 7.41026 8.34346 7.25594 8.23724 7.14672C8.13102 7.03751 7.96948 6.98291 7.75261 6.98291H7.13522V8.23647H7.75261Z"
        fill="#999999"
      />
      <path
        d="M11.8887 6C12.3799 6 12.8092 6.10446 13.1766 6.31339C13.5439 6.52232 13.8272 6.81671 14.0263 7.19658C14.2299 7.5717 14.3317 8.00617 14.3317 8.5C14.3317 8.98908 14.2299 9.42355 14.0263 9.80342C13.8272 10.1833 13.5417 10.4777 13.1699 10.6866C12.8026 10.8955 12.3755 11 11.8887 11H10.1427V6H11.8887ZM11.8156 9.94587C12.2449 9.94587 12.5791 9.82004 12.8181 9.56838C13.0571 9.31671 13.1766 8.96059 13.1766 8.5C13.1766 8.03941 13.0571 7.68091 12.8181 7.4245C12.5791 7.16809 12.2449 7.03989 11.8156 7.03989H11.2779V9.94587H11.8156Z"
        fill="#999999"
      />
      <path
        d="M18 6V6.97578H16.1013V8.02991H17.522V8.97721H16.1013V11H14.9661V6H18Z"
        fill="#999999"
      />
    </svg>
  );
};

export default PDFIcon;
