import React from "react";

type PlaneIconProps = {
  color?: string;
  size?: "small";
};

const PlaneIcon = (props: PlaneIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#plane-icon-clip-path)">
          <path
            d="M12.4959 12.5694L12.8608 12.2047C12.9596 12.1059 13.0309 11.9829 13.0674 11.848C13.1039 11.7132 13.1044 11.5711 13.0688 11.4359L11.7708 6.5067L13.4871 4.79147C13.7688 4.52247 13.9468 4.16292 13.9899 3.77588C14.0329 3.38883 13.9382 2.99901 13.7224 2.67477C13.5876 2.48476 13.413 2.32644 13.2108 2.21087C13.0085 2.09531 12.7834 2.02527 12.5512 2.00568C12.319 1.98608 12.0853 2.01738 11.8665 2.0974C11.6477 2.17742 11.449 2.30423 11.2843 2.46896L9.50778 4.24497L4.57671 2.94828C4.44141 2.91266 4.29912 2.91311 4.16404 2.94959C4.02896 2.98608 3.90581 3.05732 3.80689 3.15622L3.44198 3.52092C3.35598 3.60686 3.29071 3.71126 3.25113 3.82619C3.21156 3.94112 3.19871 4.06356 3.21358 4.18419C3.22844 4.30483 3.27062 4.42049 3.33691 4.52239C3.4032 4.62429 3.49187 4.70975 3.59616 4.77228L6.96142 6.79035L4.40066 9.69082L3.60043 9.70255C3.47822 9.68164 3.35283 9.6894 3.23414 9.72521C3.11545 9.76102 3.00671 9.8239 2.91649 9.90889L2.11732 10.6404C2.07104 10.6866 2.0368 10.7434 2.01761 10.8058C1.99841 10.8683 1.99485 10.9345 2.00722 10.9987C2.0196 11.0628 2.04754 11.123 2.08859 11.1738C2.12965 11.2247 2.18256 11.2647 2.24269 11.2903L3.78715 12.161L4.80078 13.7579C4.82662 13.8179 4.86676 13.8707 4.9177 13.9116C4.96864 13.9526 5.02883 13.9804 5.09301 13.9928C5.1572 14.0051 5.22344 14.0016 5.28595 13.9825C5.34846 13.9634 5.40534 13.9293 5.45164 13.8832L6.14998 13.2178C6.24705 13.1263 6.31971 13.0121 6.36136 12.8855C6.40301 12.7588 6.41232 12.6238 6.38845 12.4927L6.40126 11.6902L9.22449 9.05208L11.2438 12.4153C11.3063 12.5196 11.3918 12.6082 11.4938 12.6744C11.5958 12.7407 11.7115 12.7828 11.8322 12.7977C11.9529 12.8126 12.0754 12.7997 12.1904 12.7602C12.3054 12.7206 12.4099 12.6554 12.4959 12.5694Z"
            stroke={color || "#195FE9"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="plane-icon-clip-path">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7438 18.8542L19.2912 18.3071C19.4395 18.1589 19.5463 17.9744 19.6011 17.7721C19.6558 17.5697 19.6566 17.3566 19.6032 17.1539L17.6563 9.76005L20.2306 7.18721C20.6533 6.78371 20.9203 6.24438 20.9848 5.66381C21.0493 5.08325 20.9073 4.49851 20.5835 4.01215C20.3815 3.72714 20.1196 3.48966 19.8161 3.31631C19.5127 3.14296 19.175 3.03791 18.8268 3.00851C18.4785 2.97911 18.128 3.02607 17.7998 3.1461C17.4715 3.26613 17.1735 3.45634 16.9265 3.70344L14.2617 6.36745L6.86507 4.42243C6.66211 4.36898 6.44868 4.36966 6.24606 4.42439C6.04344 4.47912 5.85872 4.58599 5.71033 4.73433L5.16297 5.28137C5.03397 5.41029 4.93607 5.56689 4.8767 5.73929C4.81734 5.91168 4.79807 6.09533 4.82036 6.27629C4.84266 6.45724 4.90593 6.63073 5.00537 6.78359C5.10481 6.93644 5.2378 7.06463 5.39424 7.15842L10.4421 10.1855L6.60099 14.5362L5.40064 14.5538C5.21733 14.5225 5.02924 14.5341 4.85121 14.5878C4.67317 14.6415 4.51006 14.7358 4.37473 14.8633L3.17598 15.9606C3.10656 16.0298 3.05521 16.115 3.02641 16.2087C2.99762 16.3024 2.99227 16.4018 3.01084 16.498C3.0294 16.5942 3.07131 16.6845 3.13289 16.7607C3.19447 16.837 3.27384 16.897 3.36403 16.9355L5.68072 18.2415L7.20117 20.6368C7.23993 20.7268 7.30015 20.806 7.37655 20.8674C7.45296 20.9288 7.54324 20.9706 7.63952 20.9892C7.7358 21.0077 7.83516 21.0024 7.92892 20.9737C8.02269 20.9451 8.10802 20.8939 8.17746 20.8248L9.22497 19.8267C9.37057 19.6895 9.47956 19.5182 9.54204 19.3282C9.60451 19.1383 9.61848 18.9357 9.58268 18.739L9.60188 17.5353L13.8367 13.5781L16.8656 18.623C16.9595 18.7794 17.0877 18.9123 17.2407 19.0117C17.3936 19.111 17.5672 19.1743 17.7483 19.1966C17.9294 19.2188 18.1131 19.1996 18.2856 19.1402C18.4581 19.0809 18.6148 18.9831 18.7438 18.8542Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlaneIcon;
