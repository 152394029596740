import React from "react";

import { Box, Typography, Divider } from "@mui/material";

import { LabeledInlineInput } from "design-system/components/inputs";
import { Invoice } from "types";

type MemoProps = {
  invoice: Invoice;
};

function Memo(props: MemoProps) {
  const { invoice } = props;

  return (
    <Box mb={4} mt={2}>
      <Box mb={3}>
        <Typography variant="h5">Memo</Typography>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      <LabeledInlineInput
        disabled
        label="Memo to customers"
        value={invoice.memo}
      />
    </Box>
  );
}

export default Memo;
