import React from "react";
import { Controller, FieldPath } from "react-hook-form";

import { Box, Typography } from "@mui/material";

import VehicleFormSection from "components/vehicles/VehicleFormSection";
import DisplayBookingToolRequestMobile from "design-system/images/DisplayBookingToolRequestMobile.png";
import DisplayBookingToolRequestDesktop from "design-system/images/DisplayBookingToolRequestDesktop.png";
import BookingToolBRAMobile from "design-system/images/BookingToolBRAMobile.png";
import BookingToolBRADesktop from "design-system/images/BookingToolBRADesktop.png";
import BookingToolBlockQuoteDesktop from "design-system/images/BookingToolBlockQuoteDesktop.png";
import BookingToolBlockQuoteMobile from "design-system/images/BookingToolBlockQuoteMobile.png";
import BookingToolBlockReservationDesktop from "design-system/images/BookingToolBlockReservationDesktop.png";
import BookingToolBlockReservationMobile from "design-system/images/BookingToolBlockReservationMobile.png";
import EnablePricelessBookingDesktop from "design-system/images/EnablePricelessBookingDesktop.png";
import EnablePricelessBookingMobile from "design-system/images/EnablePricelessBookingMobile.png";
import { useAnalytics, useScreenSize } from "globals/hooks";
import {
  BookingToolSettingSection,
  BookingToolSettingSectionWithPlanUpgrade,
} from "components/vehicles/CreateVehicleDrawer/components/VehicleTabViews/BookingToolSettings/components";
import { grayDark } from "design-system/colors";
import UpdatePricelessBookingTargetSection from "./UpdatePricelessBookingTargetSection";
import {
  UpdateVehicleFormData,
  useUpdateVehicleFormContext,
} from "../../hooks/useUpdateVehicleForm";

function BookingToolSettings() {
  // hooks
  const { track } = useAnalytics();
  const { isSmallTabletView } = useScreenSize();
  const formCtx = useUpdateVehicleFormContext();

  if (!formCtx) {
    console.error(
      "useUpdateVehicleFormContext must be used within an UpdateVehicleFormProvider"
    );
    return (
      <Box mt={4}>
        <Typography variant="body2" color="error">
          Error loading vehicle booking tool settings. Please refresh the page.
        </Typography>
      </Box>
    );
  }

  const { control, watch } = formCtx;

  // event handler
  const handleTrackingEvents = (
    name: FieldPath<UpdateVehicleFormData>,
    checked: boolean
  ) => {
    if (name === "vehicle.enableBaseRateAutomationBookingTool") {
      track("vehicleBookingTool_braUpdated", {
        toggleState: checked ? "On" : "Off",
      });
    } else if (name === "vehicle.settings.conflictBlockQuote") {
      track(
        checked
          ? "vehicleBookingTool_blockQuoteEnabled"
          : "vehicleBookingTool_blockQuoteDisabled"
      );
    } else if (name === "vehicle.settings.conflictBlockReservation") {
      track(
        checked
          ? "vehicleBookingTool_blockReservationEnabled"
          : "vehicleBookingTool_blockReservationDisabled"
      );
    } else if (name === "vehicle.settings.pricelessBookingEnabled") {
      track(
        checked ? "pricelessBooking_enabled" : "pricelessBooking_disabled",
        {
          type: watch("vehicle.settings.pricelessBookingTarget"),
        }
      );
    }
  };

  return (
    <Box mt={4}>
      <VehicleFormSection title="Customer Portal Settings">
        {/* Display on Customer Portal */}
        <Controller
          control={control}
          name="vehicle.available"
          render={({ field: { name, value, onChange } }) => (
            <BookingToolSettingSection
              vehicleSetting={value}
              handleBookingToolSettingsToggle={(e) =>
                onChange(e.target.checked)
              }
              mobileImage={DisplayBookingToolRequestMobile}
              desktopImage={DisplayBookingToolRequestDesktop}
              name={name}
              title="Display on Customer Portal"
              subtext="When this is on, vehicle will be displayed on customer portal for customers to request"
            />
          )}
        />
        {/* Enable BRA */}
        <Controller
          control={control}
          name="vehicle.enableBaseRateAutomationBookingTool"
          render={({ field: { name, value, onChange } }) => {
            const handleOnChange = (e) => {
              handleTrackingEvents(name, e.target.checked);
              onChange(e.target.checked);
            };
            return (
              <BookingToolSettingSection
                vehicleSetting={value}
                handleBookingToolSettingsToggle={handleOnChange}
                mobileImage={BookingToolBRAMobile}
                desktopImage={BookingToolBRADesktop}
                name={name}
                title="Enable Base Rate Automation on Customer Portal"
                subtext="When this is on, price will be displayed and customers can book vehicle without requesting a quote"
              />
            );
          }}
        />

        {/* Priceless Booking */}
        <Controller
          control={control}
          name="vehicle.settings.pricelessBookingEnabled"
          render={({ field: { name, value, onChange } }) => {
            const handleOnChange = (e) => {
              handleTrackingEvents(name, e.target.checked);
              onChange(e.target.checked);
            };
            return (
              <BookingToolSettingSection
                vehicleSetting={value}
                handleBookingToolSettingsToggle={handleOnChange}
                mobileImage={EnablePricelessBookingMobile}
                desktopImage={EnablePricelessBookingDesktop}
                name={name}
                title="Enable reservation requests on Customer Portal"
                subtext="When this is on, customers will be able to request a vehicle with or without a price defined upfront. No charges will be automatically applied to the customer. You can accept or decline these reservations in the Reservations tab. Choose who can access this feature:"
                midSection={<UpdatePricelessBookingTargetSection />}
              />
            );
          }}
        />
        {/* Block Quote */}
        <Controller
          control={control}
          name="vehicle.settings.conflictBlockQuote"
          render={({ field: { name, value, onChange } }) => {
            const handleOnChange = (e) => {
              handleTrackingEvents(name, e.target.checked);
              onChange(e.target.checked);
            };
            return (
              <BookingToolSettingSectionWithPlanUpgrade
                value={value}
                handleVehicleSettingsToggle={handleOnChange}
                mobileImage={BookingToolBlockQuoteMobile}
                desktopImage={BookingToolBlockQuoteDesktop}
                name={name}
                imgWidth="100%"
                title="Block quote requests when vehicle is unavailable"
                subtext={
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: grayDark,
                    }}
                  >
                    When this is on, customer will not be able to{" "}
                    <Typography
                      fontWeight="600"
                      fontSize="12px"
                      display="inline"
                    >
                      submit a quote request
                    </Typography>{" "}
                    if there is a trip conflict. Customer will be instructed to
                    choose another vehicle, update pickup time or contact you
                    directly.
                  </Typography>
                }
              />
            );
          }}
        />
        {/* Block Reservation */}
        <Controller
          control={control}
          name="vehicle.settings.conflictBlockReservation"
          render={({ field: { name, value, onChange } }) => {
            const handleOnChange = (e) => {
              handleTrackingEvents(name, e.target.checked);
              onChange(e.target.checked);
            };
            return (
              <BookingToolSettingSectionWithPlanUpgrade
                value={value}
                handleVehicleSettingsToggle={handleOnChange}
                mobileImage={BookingToolBlockReservationMobile}
                desktopImage={BookingToolBlockReservationDesktop}
                name={name}
                title="Block reservations when vehicle is unavailable"
                imgWidth={isSmallTabletView ? "80%" : "60%"}
                subtext={
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: grayDark,
                    }}
                  >
                    When this is on, customer will not be able to{" "}
                    <Typography
                      fontWeight="600"
                      fontSize="12px"
                      display="inline"
                    >
                      reserve
                    </Typography>{" "}
                    if there is a trip conflict. Customer will be instructed to
                    choose another vehicle, update pickup time or contact you
                    directly.
                  </Typography>
                }
              />
            );
          }}
        />
      </VehicleFormSection>
    </Box>
  );
}

export default BookingToolSettings;
