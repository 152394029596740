import React from "react";

import VehicleFormSection from "components/vehicles/VehicleFormSection";
import VehiclePhotos from "components/vehicles/VehiclePhotos/VehiclePhotos";
import { VehiclePhoto } from "types";
import { CreateVehicle } from "../../types";

type PhotosProps = {
  vehicle: CreateVehicle;
  setVehicle: React.Dispatch<React.SetStateAction<CreateVehicle>>;
};

function Photos(props: PhotosProps) {
  const { vehicle, setVehicle } = props;

  // event handler
  const handleVehiclePhotosChange = (newPhotos: VehiclePhoto[]) => {
    setVehicle({
      ...vehicle,
      photos: newPhotos,
    });
  };

  return (
    <VehicleFormSection
      title="Photos"
      subTitle=".jpg and .png files"
      tooltipText="Drag to reorder photos. The first photo is your featured image"
    >
      <VehiclePhotos
        mode="create"
        photos={vehicle.photos}
        vehicleId=""
        onVehiclePhotosChange={handleVehiclePhotosChange}
      />
    </VehicleFormSection>
  );
}

export default Photos;
