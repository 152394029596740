import React from "react";

import { Box, MenuItem, Typography } from "@mui/material";

import MoovsTooltip from "components/MoovsTooltip";

export type MenuItemOption = {
  key?: string;
  "data-testid"?: string;
  text: string;
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  icon?: React.ReactNode;
  endItem?: React.ReactNode;
  hideOption?: boolean;
  disableOption?: boolean;
  tooltip?: Tooltip;
};

type EllipsisMenuItemProps = {
  option: MenuItemOption;
  onOptionClick: (callback: () => void) => void;
};

// constants
const MENU_ITEM_WIDTH = 270;

function EllipsisMenuItem(props: EllipsisMenuItemProps) {
  const { option, onOptionClick } = props;

  return option.tooltip ? (
    <MoovsTooltip
      tooltipText={option.tooltip.titleText}
      placement={option.tooltip.placement || "top"}
      noWrap={option.tooltip.noWrap || false}
    >
      <MenuItemContent option={option} onOptionClick={onOptionClick} />
    </MoovsTooltip>
  ) : (
    <MenuItemContent option={option} onOptionClick={onOptionClick} />
  );
}

type Tooltip = {
  titleText: string;
  placement?:
    | "top"
    | "right"
    | "bottom"
    | "left"
    | "left-start"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start";
  noWrap?: boolean;
};

type MenuItemContentProps = {
  option: MenuItemOption;
  onOptionClick: (callback: () => void) => void;
};

function MenuItemContent(props: MenuItemContentProps) {
  const { option, onOptionClick } = props;

  return (
    <MenuItem
      autoFocus
      sx={{
        minWidth: MENU_ITEM_WIDTH,
        py: 1,
        minHeight: 0,
      }}
      onClick={() => onOptionClick(option.onClick)}
      tabIndex={0}
      data-testid={
        option["data-testid"] || `ellipsis-option-menu-item-${option.text}`
      }
      {...(option?.disableOption && { disabled: true })}
    >
      <Box display="flex" justifyContent="center">
        {option?.icon || null}
      </Box>
      <Box ml={1}>
        <Typography variant="body2" fontSize="14px">
          {option.text}
        </Typography>
      </Box>
      {option?.endItem && (
        <Box ml={"auto"} display="flex" justifyContent="flex-end">
          {option.endItem}
        </Box>
      )}
    </MenuItem>
  );
}

export default EllipsisMenuItem;
