import React from "react";

import { Button, Typography, IconButton } from "@mui/material";

import { grayLight, moovsBlue } from "design-system/colors";
import ActionButton from "design-system/components/ActionButton";
import { EllipsisVerticalIcon } from "design-system/icons";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

type EllipsisButtonProps = {
  variant?: "default" | "withMoreText" | "actionButton";
  onClick: (e: any) => void;
  ellipsisColor?: string;
  size?: "small" | "medium" | "large";
  icon?: ReactJSXElement;
};

function EllipsisButton(props: EllipsisButtonProps) {
  const { variant = "default", onClick, ellipsisColor, size, icon } = props;

  if (variant === "withMoreText")
    return (
      <Button
        sx={{
          paddingY: 0.5,
          paddingLeft: 0.75,
          "& .MuiButton-startIcon": { marginRight: "2px" },
          ":hover": { backgroundColor: grayLight, borderRadius: 1 },
        }}
        variant="text"
        startIcon={<EllipsisVerticalIcon color={moovsBlue} />}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={onClick}
        data-testid="ellipsis-menu-button"
        size="large"
      >
        <Typography
          sx={{ fontWeight: "500", paddingLeft: -0.25 }}
          data-testid="ellipsis-button-more-text"
        >
          More
        </Typography>
      </Button>
    );

  if (variant === "actionButton")
    return (
      <ActionButton
        icon={EllipsisVerticalIcon}
        label="More"
        maxWidth={60}
        onClick={onClick}
      />
    );

  return (
    <IconButton
      aria-label="more"
      aria-controls="long-menu"
      aria-haspopup="true"
      onClick={onClick}
      data-testid="ellipsis-menu-button"
      size={size}
    >
      {icon || <EllipsisVerticalIcon color={ellipsisColor} />}
    </IconButton>
  );
}

export default EllipsisButton;
