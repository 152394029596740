import React, { Dispatch, SetStateAction } from "react";

import { Box } from "@mui/material";

import { MoovsTransaction, PaymentMethodTransaction } from "types";
import { useScreenSize } from "globals/hooks";
import { useTransactionDetails } from "./hooks/useTransactionDetails";
import TransactionListItemDesktop from "./components/TransactionListItem.desktop";
import TransactionListItemMobile from "./components/TransactionListItem.mobile";

type TransactionListItemProps = {
  transaction: PaymentMethodTransaction | MoovsTransaction;
  setViewMoreDetailsPaymentId: Dispatch<SetStateAction<string>>;
  isFirstItem?: boolean;
  includePaymentMethod?: boolean;
};

function TransactionListItem(props: TransactionListItemProps) {
  const {
    transaction,
    setViewMoreDetailsPaymentId,
    isFirstItem,
    includePaymentMethod,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const transactionDetails = useTransactionDetails({
    transaction,
    setViewMoreDetailsPaymentId,
  });

  return (
    <>
      {isMobileView ? (
        <Box mt={1}>
          <TransactionListItemMobile
            {...transactionDetails}
            includePaymentMethod={includePaymentMethod}
          />
        </Box>
      ) : (
        <TransactionListItemDesktop
          {...transactionDetails}
          isFirstItem={isFirstItem}
          includePaymentMethod={includePaymentMethod}
        />
      )}
    </>
  );
}

export default TransactionListItem;
