import React, { ChangeEventHandler } from "react";
import InputMask from "react-input-mask";

import { Box, TextField } from "@mui/material";

import LocationAutoComplete from "../../../autocompletes/LocationAutoComplete";
import { ExternalOperatorErrorsType } from "./utils";
import { ExternalOperator } from "../../../../types";

type CreateExternalOperatorSectionProps = {
  externalOperator: ExternalOperator;
  onAffiliateInfoChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  onAffiliateAddressChange: (location: { description?: string }) => void;
  externalOperatorErrors: ExternalOperatorErrorsType;
};

function CreateExternalOperatorSection(
  props: CreateExternalOperatorSectionProps
) {
  const {
    externalOperator,
    onAffiliateInfoChange,
    onAffiliateAddressChange,
    externalOperatorErrors,
  } = props;

  return (
    <Box mb={4} display="flex" flexDirection="column">
      {/* Affiliate Name */}
      <Box display="flex" flex="1" mb={1}>
        <TextField
          required
          fullWidth
          name="operatorName"
          label="Affiliate Name"
          variant="outlined"
          value={externalOperator.operatorName}
          onChange={onAffiliateInfoChange}
          error={!!externalOperatorErrors.operatorName}
          helperText={externalOperatorErrors.operatorName}
        />
      </Box>

      {/* Contact Name */}
      <Box display="flex" flex="1" mb={1}>
        <TextField
          required
          fullWidth
          name="contactName"
          label="Contact Name"
          variant="outlined"
          value={externalOperator.contactName}
          onChange={onAffiliateInfoChange}
          error={!!externalOperatorErrors.contactName}
          helperText={externalOperatorErrors.contactName}
        />
      </Box>

      {/* Affiliate Email */}
      <Box display="flex" flex="1" mb={1}>
        <TextField
          required
          fullWidth
          name="operatorEmail"
          label="Affiliate Email"
          variant="outlined"
          value={externalOperator.operatorEmail}
          onChange={onAffiliateInfoChange}
          error={!!externalOperatorErrors.operatorEmail}
          helperText={externalOperatorErrors.operatorEmail}
        />
      </Box>

      {/* Affiliate Phone */}
      <Box display="flex" flex="1" mb={1}>
        <InputMask
          required
          mask="(999) 999-9999"
          maskChar=" "
          onChange={onAffiliateInfoChange}
        >
          {() => (
            <TextField
              required
              type="tel"
              variant="outlined"
              fullWidth
              name="operatorPhone"
              label="Affiliate Phone"
              error={!!externalOperatorErrors.operatorPhone}
              helperText={externalOperatorErrors.operatorPhone}
            />
          )}
        </InputMask>
      </Box>

      {/* Affiliate Address */}
      <Box display="flex" flex="1" mb={1}>
        <LocationAutoComplete
          required
          label="Affiliate Address"
          fullWidth
          value={externalOperator.operatorAddress || ""}
          onChange={onAffiliateAddressChange}
          error={!!externalOperatorErrors.operatorAddress}
          helperText={externalOperatorErrors.operatorAddress}
        />
      </Box>

      {/* Internal Notes */}
      <Box display="flex" flex="1" mb={1}>
        <TextField
          fullWidth
          name="internalNote"
          label="Internal Notes"
          variant="outlined"
          value={externalOperator.internalNote}
          onChange={onAffiliateInfoChange}
        />
      </Box>
    </Box>
  );
}

export default CreateExternalOperatorSection;
