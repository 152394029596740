import React from "react";
import { useMutation } from "@apollo/client";

import { Box, Typography } from "@mui/material";

import { UPDATE_OPERATOR_SETTINGS } from "globals/graphql";
import { useSnackbar, useOperator } from "globals/hooks";
import LabeledSwitchInput from "../LabeledSwitchInput";

type ShuttleToggleBlockProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function ShuttleToggleBlock(props: ShuttleToggleBlockProps) {
  const { setSaveIndicatorState } = props;

  // hooks
  const snackbar = useSnackbar();
  const operator = useOperator();

  const { shuttleLateTextEnabled } = operator.settings;

  // mutations
  const [updateOperatorSettings, { loading }] = useMutation(
    UPDATE_OPERATOR_SETTINGS,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        snackbar.error("error updating operator settings");
      },
    }
  );

  // event handlers
  const handleShuttleToggleChange = () => {
    setSaveIndicatorState("loading");
    updateOperatorSettings({
      variables: {
        input: {
          shuttleLateTextEnabled: !shuttleLateTextEnabled,
        },
      },
    });
  };

  return (
    <>
      <Typography variant="h4">Shuttle App</Typography>
      <Box mt={2}>
        <LabeledSwitchInput
          name="shuttleLateTextEnabled"
          checked={shuttleLateTextEnabled}
          disabled={loading}
          onChange={handleShuttleToggleChange}
          text="Send Late Arrival Alerts to Passengers"
          subText="When this is on, passengers will receive a text message stating driver is running behind with driver’s ETA when driver’s ETA is 3 minutes late or more to pick-up location"
        />
      </Box>
    </>
  );
}

export default ShuttleToggleBlock;
