import React from "react";

import { Box, Divider, Typography } from "@mui/material";

import { Trip } from "types";
import { grayDark, grayMedium } from "design-system/colors";
import { getTripIconAndCategoryType } from "pages/requests/utils/getTripIconAndCategoryType";
import DurationDisplay from "pages/requests/RequestOverview/ReservationOverview/TripItineraryBlock/components/DurationDisplay";

type TripDurationAndCategoryProps = {
  trip: Trip;
};

function TripDurationAndCategory(props: TripDurationAndCategoryProps) {
  const { trip } = props;

  const {
    totalDuration,
    estimatedDuration,
    useTotalDuration,
    tripCategory,
    roundTripVariant,
  } = trip;

  const { text: categoryText, icon } = getTripIconAndCategoryType({
    tripCategory,
    roundTripVariant,
  });

  return (
    <Box display="flex">
      <DurationDisplay
        totalDuration={totalDuration}
        estimatedDuration={estimatedDuration}
        isEstimated={!useTotalDuration}
      />
      <Box mx={2}>
        <Divider
          orientation="vertical"
          style={{
            backgroundColor: grayMedium,
          }}
        />
      </Box>
      <Box minHeight={32} display="flex" alignItems="center">
        <Typography noWrap variant="body2" style={{ color: grayDark }}>
          {categoryText}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" ml={1.5}>
        {icon}
      </Box>
    </Box>
  );
}

export default TripDurationAndCategory;
