/**
 * NOTE: This is largely a copy of the default mui-x component with some minor changes for our functionality.
 */
import React from "react";
import filter from "lodash/filter";
import size from "lodash/size";

import Switch, { switchClasses } from "@mui/material/Switch";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import {
  allGridColumnsSelector,
  GridPanelContent,
  GridPanelFooter,
  GridPanelHeader,
  GridPanelWrapper,
  GridStateColDef,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { Box, InputAdornment } from "@mui/material";

import { SearchIcon } from "design-system/icons";
import { useSnackbar } from "globals/hooks";
import { useTripsViewConfig } from "../hooks/useTripsViewConfig";

const GridColumnsPanelRoot = styled("div", {
  name: "MuiDataGrid",
  slot: "ColumnsPanel",
  overridesResolver: (props, styles) => styles.columnsPanel,
})(() => ({
  padding: "8px 0px 8px 8px",
}));

const GridColumnsPanelRowRoot = styled("div", {
  name: "MuiDataGrid",
  slot: "ColumnsPanelRow",
  overridesResolver: (props, styles) => styles.columnsPanelRow,
})(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "1px 8px 1px 7px",
  [`& .${switchClasses.root}`]: {
    marginRight: theme.spacing(0.5),
  },
}));

function CustomColumnsPanel() {
  // hooks
  const snackbar = useSnackbar();
  const apiRef = useGridApiContext();
  const { onShowAllColumnsClick } = useTripsViewConfig({ apiRef });
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const columns = useGridSelector(
    apiRef,
    allGridColumnsSelector
  ) as GridStateColDef[];

  // state
  const [searchValue, setSearchValue] = React.useState("");

  // event handlers
  const toggleColumn = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const { name } = event.target as HTMLInputElement;
      const column = apiRef.current.getColumn(name);
      const visibleColumns = filter(columns, (column) => !column.hide);

      // prevent hiding if only one column is showing
      if (!column.hide && size(visibleColumns) === 1) {
        snackbar.error("Can't hide all columns. One must be visible.");
        return;
      }

      apiRef.current.setColumnVisibility(name, !!column.hide);
    },
    [apiRef, columns, snackbar]
  );

  const showAllColumns = React.useCallback(() => {
    apiRef.current.updateColumns(
      columns.map((col) => {
        col.hide = false;
        return col;
      })
    );

    onShowAllColumnsClick();
  }, [apiRef, columns, onShowAllColumnsClick]);

  const handleSearchValueChange = React.useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const currentColumns = React.useMemo(
    () =>
      !searchValue
        ? columns
        : columns.filter(
            (column) =>
              column.field.toLowerCase().indexOf(searchValue.toLowerCase()) >
                -1 ||
              (column.headerName &&
                column.headerName
                  .toLowerCase()
                  .indexOf(searchValue.toLowerCase()) > -1)
          ),
    [columns, searchValue]
  );

  React.useEffect(() => {
    searchInputRef.current!.focus();
  }, []);

  return (
    <GridPanelWrapper>
      <GridPanelHeader>
        <TextField
          placeholder="Find Column"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          inputRef={searchInputRef}
          value={searchValue}
          onChange={handleSearchValueChange}
          variant="outlined"
          fullWidth
        />
      </GridPanelHeader>
      <GridPanelContent>
        <GridColumnsPanelRoot>
          {currentColumns.map((column) => (
            <GridColumnsPanelRowRoot key={column.field} sx={{ mb: 2 }}>
              <FormControlLabel
                componentsProps={{
                  typography: {
                    variant: "body2",
                    ml: 1,
                  },
                }}
                control={
                  <Switch
                    checked={!column.hide}
                    onClick={toggleColumn}
                    name={column.field}
                    color="primary"
                    size="small"
                  />
                }
                label={column.headerName || column.field}
              />
            </GridColumnsPanelRowRoot>
          ))}
        </GridColumnsPanelRoot>
      </GridPanelContent>
      <GridPanelFooter>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <Button onClick={showAllColumns} color="primary" variant="contained">
            {apiRef.current.getLocaleText("columnsPanelShowAllButton")}
          </Button>
        </Box>
      </GridPanelFooter>
    </GridPanelWrapper>
  );
}

export default CustomColumnsPanel;
