import React from "react";

import { Box, Typography } from "@mui/material";
import { grayDark } from "design-system/colors";

type LuggageBlockProps = {
  carryOnLuggage?: number;
  checkedLuggage?: number;
  oversizeLuggage?: number;
};

const LuggageBlock = (props: LuggageBlockProps) => {
  const { carryOnLuggage, checkedLuggage, oversizeLuggage } = props;

  // derived state
  const hasLuggage = carryOnLuggage || checkedLuggage || oversizeLuggage;

  if (!hasLuggage) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h5" style={{ color: grayDark }}>
        Luggage
      </Typography>
      <Box mt={1}>
        {!!carryOnLuggage && (
          <Box display="flex" flexDirection="row">
            <Box>
              <Typography
                variant="body2"
                align="center"
                sx={{ minWidth: "9px" }}
              >
                {carryOnLuggage}
              </Typography>
            </Box>
            <Box ml={0.5}>
              <Typography variant="body2">Carry-On</Typography>
            </Box>
          </Box>
        )}
        {!!checkedLuggage && (
          <Box display="flex" flexDirection="row">
            <Box>
              <Typography
                variant="body2"
                align="center"
                sx={{ minWidth: "9px" }}
              >
                {checkedLuggage}
              </Typography>
            </Box>
            <Box ml={0.5}>
              <Typography variant="body2">Checked</Typography>
            </Box>
          </Box>
        )}
        {!!oversizeLuggage && (
          <Box display="flex" flexDirection="row">
            <Box>
              <Typography
                variant="body2"
                align="center"
                sx={{ minWidth: "9px" }}
              >
                {oversizeLuggage}
              </Typography>
            </Box>
            <Box ml={0.5}>
              <Typography variant="body2">Oversize</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LuggageBlock;
