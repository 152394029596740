import React from "react";

import { Box } from "@mui/material";

import { grayDark } from "design-system/colors";
import { NoteIcon } from "design-system/icons";
import MoovsIconPanel from "components/MoovsIconPanel";
import DurationDisplay from "./DurationDisplay";
import PassengerCountDisplay from "./PassengerCountDisplay";
import { Stop } from "types";
import FlightTracker from "components/flights/components/FlightTracker/FlightTracker";
import { getStopFlightType } from "components/flights/helpers/flightTimeHelpers";

type MoreInfoPanelProps = {
  stop: Stop;
  stopLabel: string;
  estimatedDropOffTime: string;
  renderDropOffWarning?: boolean;
};

function MoreInfoPanel(props: MoreInfoPanelProps) {
  const { stop, stopLabel, estimatedDropOffTime, renderDropOffWarning } = props;

  const iconPanelItems = [
    ...(!!stop.note
      ? [
          {
            icon: <NoteIcon color={grayDark} />,
            renderItem: !!stop.note,
            mainText: "Note:",
            subText: stop.note,
          },
        ]
      : []),
  ];

  const renderDuration =
    stop.stopIndex !== 1 && !!(stop.dateTime || estimatedDropOffTime);
  const renderPassengerCount = !!stop.groupSize;
  const renderIconPanel = !!iconPanelItems.length;
  const renderAirportStopFlightTracker =
    stop.variant === "airport" && stop.trackedFlight;

  return (
    <>
      {(renderDuration ||
        renderPassengerCount ||
        renderIconPanel ||
        renderAirportStopFlightTracker) && (
        <>
          <Box my={1.5} display="flex" flexDirection="row" alignItems="center">
            {renderDuration && (
              <Box mr={2}>
                <DurationDisplay
                  dateTime={stop.dateTime || estimatedDropOffTime}
                  isEstimated={!stop.dateTime}
                  renderDropOffWarning={renderDropOffWarning}
                />
              </Box>
            )}
            {renderPassengerCount && (
              <Box mr={2}>
                <PassengerCountDisplay passengerCount={stop.groupSize} />
              </Box>
            )}
            {renderIconPanel && (
              <Box display="flex" alignItems="center">
                <MoovsIconPanel
                  items={iconPanelItems}
                  bottomDrawerHeader={`Additional ${stopLabel} Info`}
                />
              </Box>
            )}
          </Box>
          {renderAirportStopFlightTracker && (
            <Box>
              <FlightTracker
                trackedFlight={stop.trackedFlight}
                stopFlightType={getStopFlightType(stop)}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
}

export default MoreInfoPanel;
