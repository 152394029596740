import React, { useState, Fragment } from "react";
import uniqBy from "lodash/uniqBy";
import map from "lodash/map";

import { Box, Typography, Button, Hidden, Divider } from "@mui/material";

import { Payment, Trip, Invoice, SubPayment } from "types";
import CreatePaymentDialog from "components/invoices/CreatePaymentDialog";
import ViewMorePaymentDetailsDialog from "components/invoices/ViewMorePaymentDetailsDialog";
import {
  CreateInvoicePaymentDialog,
  CreateRefundDialog,
  PaymentListItemDesktop,
  PaymentListItemMobile,
} from "./components";
import { useAnalytics } from "globals/hooks";

type PaymentsBlockProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
  trip?: Trip;
  tripRefetch?: Function;
  requestRefetch?: () => void;
  parentComponent?: "update-invoice-drawer";
  payments?: SubPayment[];
  invoice?: Invoice;
  invoiceAmountDue?: number;
  invoiceRefetch?: any;
};

function PaymentsBlock(props: PaymentsBlockProps) {
  const {
    trip,
    setSaveIndicatorState,
    tripRefetch,
    requestRefetch,
    parentComponent = "money-tab",
    payments,
    invoice,
    invoiceAmountDue,
    invoiceRefetch,
  } = props;

  // hooks
  const { track } = useAnalytics();

  // state
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [createRefundDialogOpen, setCreateRefundDialogOpen] = useState(false);
  const [viewPaymentIdInsideDialog, setPaymentIdInsideDialog] = useState<
    string | undefined
  >(null);
  const [subPayment, setSubPayment] = useState(null);

  // derived state
  const isUpdateInvoiceDrawer = parentComponent === "update-invoice-drawer";

  // event handlers
  const handleOpenCreateDialog = () => {
    if (isUpdateInvoiceDrawer) {
      track("invoices_chargeInitiated");
    } else if (parentComponent === "money-tab") {
      track("reservations_chargeTripInitiated");
    }

    setCreateDialogOpen(true);
  };

  const handleRefetches = () => {
    if (isUpdateInvoiceDrawer) {
      invoiceRefetch();
    } else {
      tripRefetch();
    }
  };

  const handleCloseCreateDialog = () => {
    handleRefetches();
    setCreateDialogOpen(false);
  };

  const handleOpenRefundDialog = (subPayment: SubPayment) => {
    setCreateRefundDialogOpen(true);
    setSubPayment(subPayment);
  };

  const handleOpenViewPaymentDialog = (payment: Payment) => {
    setPaymentIdInsideDialog(payment.id);
  };
  const handleCloseViewPaymentDialog = () => {
    setPaymentIdInsideDialog(null);
  };

  const handleCloseRefundDialog = () => {
    handleRefetches();
    setCreateRefundDialogOpen(false);
    setSubPayment(null);
  };

  const requestId = trip ? trip.requestId : "";

  let paymentsList = trip?.subPayments;

  // this is a hack due to the fact that in the money tab we want to display
  // sub payments (i.e. any time money was paid/refunded towards the trip)
  // whereas on the update invoice drawer we want to display payments (i.e. each time a
  // payment action was taken by the user). For example if you invoice 2 requests and
  // make a payment you want 1 payment item, not 2 (one for each trip).

  // this is deduplicating the subpayments by payment id and then
  // setting the amount field to that of the total payment amount.
  if (parentComponent !== "money-tab") {
    const uniqByPayment = uniqBy(payments, "paymentId");
    paymentsList = map(uniqByPayment, (subPayment) => ({
      ...subPayment,
      amount: subPayment.payment.amount,
    }));
  }

  return (
    <>
      <Box mb={isUpdateInvoiceDrawer ? 3 : 2}>
        <Typography variant={isUpdateInvoiceDrawer ? "h5" : "h4"}>
          Payments
        </Typography>
      </Box>

      {isUpdateInvoiceDrawer && (
        <Box mb={2}>
          <Divider />
        </Box>
      )}

      {paymentsList?.length > 0 &&
        paymentsList.map((subPayment) => {
          return (
            <Fragment key={subPayment.id}>
              {/* DESKTOP */}
              <Hidden smDown>
                <PaymentListItemDesktop
                  subPayment={subPayment}
                  onOpenViewPayment={handleOpenViewPaymentDialog}
                  onOpenRefundDialog={handleOpenRefundDialog}
                  {...(isUpdateInvoiceDrawer && {
                    isEllipsisMenuDisabled: true,
                  })}
                />
              </Hidden>

              {/* MOBILE */}
              <Hidden smUp>
                <PaymentListItemMobile
                  subPayment={subPayment}
                  onOpenViewPayment={handleOpenViewPaymentDialog}
                  onOpenRefundDialog={handleOpenRefundDialog}
                  {...(isUpdateInvoiceDrawer && {
                    isEllipsisMenuDisabled: true,
                  })}
                />
              </Hidden>
            </Fragment>
          );
        })}

      <Box mt={2} mb={1}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOpenCreateDialog}
          {...(isUpdateInvoiceDrawer &&
            invoiceAmountDue === 0 && {
              disabled: true,
            })}
        >
          Charge Customer
        </Button>
      </Box>

      {isUpdateInvoiceDrawer && (
        <CreateInvoicePaymentDialog
          open={createDialogOpen}
          onClose={handleCloseCreateDialog}
          setSaveIndicatorState={setSaveIndicatorState}
          invoice={invoice}
          invoiceRefetch={invoiceRefetch}
        />
      )}

      {parentComponent === "money-tab" && (
        <CreatePaymentDialog
          open={createDialogOpen}
          onClose={handleCloseCreateDialog}
          setSaveIndicatorState={setSaveIndicatorState}
          totalAmount={trip?.totalAmount}
          amountDue={trip?.amountDue}
          requestId={requestId}
          routeId={trip?.routes[0].id}
          passenger={trip?.contact}
        />
      )}

      <CreateRefundDialog
        open={createRefundDialogOpen}
        onClose={handleCloseRefundDialog}
        setSaveIndicatorState={setSaveIndicatorState}
        subPayment={subPayment}
        requestRefetch={requestRefetch}
      />

      <ViewMorePaymentDetailsDialog
        open={Boolean(viewPaymentIdInsideDialog)}
        onClose={handleCloseViewPaymentDialog}
        paymentId={viewPaymentIdInsideDialog}
      />
    </>
  );
}

export default PaymentsBlock;
