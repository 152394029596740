import React from "react";
import { Controller, Control } from "react-hook-form";

import {
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";

import { black, errorRed, moovsBlue } from "design-system/colors";

type RHFRadioButtonsProps = {
  control: Control<any>;
  name: string;
  errorMessage: string;
  radioButtons: { value: string | number | boolean; label: string }[];
};

function RHFRadioButtons(props: RHFRadioButtonsProps) {
  const { control, name, errorMessage, radioButtons } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <RadioGroup
            row
            aria-label="radio-buttons-group"
            style={{ color: errorMessage ? errorRed : black }}
            {...field}
          >
            {radioButtons.map((radio) => {
              return (
                <FormControlLabel
                  key={radio.label}
                  value={radio.value}
                  control={
                    <Radio
                      sx={{
                        color: errorMessage ? errorRed : moovsBlue,
                      }}
                    />
                  }
                  label={radio.label}
                />
              );
            })}
          </RadioGroup>
          {errorMessage ? (
            <FormHelperText style={{ color: errorRed }}>
              {errorMessage}
            </FormHelperText>
          ) : null}
        </>
      )}
    />
  );
}

export default RHFRadioButtons;
