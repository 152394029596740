/**
 * @file DriverAutoComplete.tsx
 * AutoComplete dropdown that populates with list of drivers.
 *
 * components:
 *  DriverAutoComplete
 */

import React, { ChangeEvent, useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import sortBy from "lodash/sortBy";

import {
  Box,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Autocomplete } from "@mui/material";

import { Driver, RouteDriver } from "../../types";
import { LOAD_ROUTE_DRIVERS_QUERY } from "../../globals/graphql";
import { useSnackbar } from "../../globals/hooks/useSnackbar";
import { formatPhoneNumber } from "../../globals/utils/phoneNumberFormatter/phoneNumberFormatter";
import CancelTripIcon from "../../design-system/icons/actions/CancelTripIcon";
import CalendarUnavailableIcon from "../../design-system/icons/layout/CalendarUnavailableIcon";
import { alabaster, errorRed, grayMedium } from "design-system/colors";

type DriverAutoCompleteProps = {
  value?: Driver | RouteDriver;
  onDriverAutoCompleteChange?: (
    newValue: Driver | RouteDriver | string
  ) => void;
  farmAffiliateId?: string;
  routeId?: string;
  label?: string;
  placeholder?: string;
  hideLabel?: boolean;
  blurOnSelect?: boolean;
  maxWidth?: string; //px
  disabled?: boolean;
  inputProps?: {
    inputValue: string;
    onInputChange: (inputValue: string) => void;
  };
  isFarmAffiliate?: boolean;
};

function DriverAutoComplete(props: DriverAutoCompleteProps) {
  const {
    value,
    onDriverAutoCompleteChange,
    farmAffiliateId,
    routeId,
    label,
    placeholder,
    hideLabel,
    inputProps,
    blurOnSelect,
    maxWidth,
    isFarmAffiliate,
    disabled,
  } = props;

  // hooks
  const snackbar = useSnackbar();

  // state
  const [open, setOpen] = useState(false);

  // queries
  const { data: driversData, loading } = useQuery(LOAD_ROUTE_DRIVERS_QUERY, {
    variables: {
      farmAffiliateId,
      routeId,
    },
    fetchPolicy: "cache-and-network",
    skip: !open,
    onError: () => {
      snackbar.error();
    },
  });

  // TODO: Fix repeated logic across files
  // - src/components/autocompletes/DriverAutoComplete.tsx
  // - src/pages/tripView/components/TripDataGrid/components/editCellComponents/DriverEditAutocomplete.tsx
  const sortedDrivers = useMemo(() => {
    if (!driversData) return null;

    const routeDrivers = sortBy(driversData.loadRouteDrivers, [
      "driver.firstName",
      "driver.lastName",
    ]);

    const driversAndAvailability = routeDrivers.map((routeDriver) => {
      return {
        routeDriver,
        availability: !routeDriver.routeAvailability?.available
          ? "routeConflict"
          : !routeDriver.personalAvailability?.available
          ? "personalConflict"
          : "available",
      };
    });

    return [
      ...driversAndAvailability.filter(
        (driver) => driver.availability === "available"
      ),
      ...driversAndAvailability.filter(
        (driver) => driver.availability === "personalConflict"
      ),
      ...driversAndAvailability.filter(
        (driver) => driver.availability === "routeConflict"
      ),
    ];
  }, [driversData]);

  // event handler
  const handleAutoCompleteChange = (
    _e: ChangeEvent<HTMLInputElement>,
    driverData
  ) => {
    const routeDriver = driverData?.routeDriver;
    onDriverAutoCompleteChange(routeDriver || null);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    if (typeof value === "string") {
      inputProps.onInputChange(event.target.value);
    }
  };

  return (
    <Autocomplete
      sx={{
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: alabaster,
        },
        "& .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: grayMedium,
        },
      }}
      disabled={disabled}
      fullWidth
      blurOnSelect={blurOnSelect}
      {...(maxWidth && { style: { maxWidth } })}
      id="driver-autocomplete"
      value={value}
      loading={loading}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={handleAutoCompleteChange}
      options={sortedDrivers || []}
      filterOptions={(drivers, { inputValue }) =>
        drivers.filter(({ routeDriver }) => {
          const input = inputValue.toLowerCase();

          return (
            routeDriver?.driver.firstName.toLowerCase().startsWith(input) ||
            routeDriver?.driver.lastName.toLowerCase().startsWith(input) ||
            routeDriver?.driver.mobilePhone.includes(input) ||
            formatPhoneNumber(
              routeDriver?.driver.mobilePhone
            ).formatted.includes(input)
          );
        })
      }
      getOptionLabel={(driverData) =>
        driverData
          ? `${driverData.routeDriver.driver.firstName} ${driverData.routeDriver.driver.lastName}`
          : ""
      }
      {...(inputProps && {
        inputValue: inputProps.inputValue,
        onInputChange: handleInputChange,
      })}
      renderInput={(params) => (
        <TextField
          fullWidth
          disabled={disabled}
          {...params}
          {...(!hideLabel && { label: label || "Add Driver" })}
          variant="outlined"
          placeholder={placeholder || "Enter Driver Name"}
        />
      )}
      renderOption={(props, optionData) => {
        const routeDriver = optionData.routeDriver;
        const showRouteConflict =
          !isFarmAffiliate && !routeDriver.routeAvailability?.available;
        const showPersonalConflict =
          !isFarmAffiliate &&
          !showRouteConflict &&
          !routeDriver.personalAvailability?.available;

        return (
          <li {...props}>
            <Box display="flex" alignItems="center" width="100%">
              <Box>
                <Typography variant="body2" fontWeight={500}>
                  {`${routeDriver?.driver.firstName} ${routeDriver?.driver.lastName}`}
                </Typography>
                <Typography fontSize={12}>
                  {routeDriver?.driver.mobilePhone}
                </Typography>
              </Box>
              <Box marginLeft="auto" display="flex" alignItems="center">
                {showRouteConflict && (
                  <Tooltip placement="top" title="Trip Conflict">
                    <IconButton>
                      <CancelTripIcon color={errorRed} />
                    </IconButton>
                  </Tooltip>
                )}
                {showPersonalConflict && (
                  <Tooltip placement="top" title="Availability Conflict">
                    <IconButton>
                      <CalendarUnavailableIcon color={errorRed} />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </li>
        );
      }}
      PaperComponent={({ children }) => (
        <Paper>
          <Box px={2} pt={1}>
            <Typography variant="overline">Add Driver</Typography>
          </Box>
          {children}
        </Paper>
      )}
    />
  );
}

export default DriverAutoComplete;
