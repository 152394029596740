import React from "react";

import {
  AutomatedRequestChanges,
  CustomerSignature,
  GratuitySelection,
  ReservationCutoff,
  SkipVehicleSelection,
  TripTypes,
} from "./sections";

type SettingsTabProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function SettingsTab(props: SettingsTabProps) {
  const { setSaveIndicatorState } = props;

  return (
    <>
      <TripTypes setSaveIndicatorState={setSaveIndicatorState} />
      <GratuitySelection setSaveIndicatorState={setSaveIndicatorState} />
      <CustomerSignature setSaveIndicatorState={setSaveIndicatorState} />
      <ReservationCutoff setSaveIndicatorState={setSaveIndicatorState} />
      <AutomatedRequestChanges setSaveIndicatorState={setSaveIndicatorState} />
      <SkipVehicleSelection setSaveIndicatorState={setSaveIndicatorState} />
    </>
  );
}

export default SettingsTab;
