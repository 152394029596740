import map from "lodash/map";

import { DispatchTripFilterFields } from "../types";

export function getLoadFilterableDispatchTripFilterFields(
  values: DispatchTripFilterFields
) {
  const {
    tripStatusSelection,
    companySelection,
    vehicleSelection,
    driverSelection,
    affiliateSelection,
    paidStatus,
    openClosedTrip,
    driverAssigned,
  } = values;

  return {
    tripStatuses: map(tripStatusSelection, "id"),
    companyIds: map(companySelection, "id"),
    vehicleIds: map(vehicleSelection, "id"),
    driverIds: map(driverSelection, "id"),
    affiliateIds: map(affiliateSelection, "id"),
    paidStatus,
    openClosedTrip,
    driverAssigned,
  };
}
