import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import orderBy from "lodash/orderBy";

import { Box, Button, CircularProgress, Typography } from "@mui/material";

import { useOperator, useScreenSize, useSnackbar } from "globals/hooks";
import { LOAD_LAGO_PAYMENT_METHOD_QUERY } from "globals/graphql";
import { grayLight, moovsBlue } from "design-system/colors";
import { PlusIcon } from "design-system/icons";
import { AddLagoPaymentMethodDialog, PaymentMethods } from "./components";

function PaymentMethodManagementCard() {
  // states
  const [lagoPaymentMethods, setLagoPaymentMethods] = useState([]);
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  // hooks
  const { isSmallTabletView } = useScreenSize();
  const operator = useOperator();
  const snackbar = useSnackbar();

  // queries
  const { loading: paymentMethodLoading } = useQuery(
    LOAD_LAGO_PAYMENT_METHOD_QUERY,
    {
      variables: {
        id: operator?.id,
      },
      fetchPolicy: "cache-and-network",
      skip: !operator?.id,
      onCompleted: (data) => {
        const sortedPaymentMethods = orderBy(
          data.node.lagoPaymentMethod,
          ["isPrimary"],
          ["desc"]
        );
        setLagoPaymentMethods(sortedPaymentMethods);
      },
      onError: () => {
        snackbar.error();
      },
    }
  );

  // event handlers
  const handleAddPaymentMethod = () => setAddDialogOpen(true);

  const handleCloseAddDialog = () => setAddDialogOpen(false);

  return (
    <Box>
      <Typography variant="overline">PAYMENT METHOD</Typography>

      <Box
        border="1px solid"
        borderRadius="4px"
        borderColor={grayLight}
        p={2}
        my={2}
        sx={{
          boxShadow:
            "0px 24px 24px -4px #1E1E1E05, 0px 8px 10px -2.5px #1E1E1E05",
        }}
      >
        {paymentMethodLoading ? (
          <Box
            display="flex"
            minHeight="inherit"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size={24} />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column">
            {lagoPaymentMethods.length ? (
              <Box mb={3}>
                <PaymentMethods paymentMethods={lagoPaymentMethods} />
              </Box>
            ) : null}

            <Box {...(isSmallTabletView && { width: "100%" })}>
              <Button
                variant="outlined"
                onClick={handleAddPaymentMethod}
                sx={{ width: "inherit" }}
              >
                <PlusIcon size="small" color={moovsBlue} />
                <Typography variant="body2" fontWeight={500} ml={1}>
                  Add payment method
                </Typography>
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      <AddLagoPaymentMethodDialog
        open={addDialogOpen}
        onClose={handleCloseAddDialog}
      />
    </Box>
  );
}

export default PaymentMethodManagementCard;
