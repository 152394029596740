import React from "react";
import moment from "moment";
import startCase from "lodash/startCase";

import { Box, Typography } from "@mui/material";

import { StripeTransactionsEdge } from "types";
import { currency } from "globals/utils/helpers";
import { TripInfo } from "./components";

type TransactionsListItemDesktopProp = {
  transactionEdge: StripeTransactionsEdge;
};

function TransactionsListItemDesktop(props: TransactionsListItemDesktopProp) {
  const { transactionEdge } = props;

  const {
    action,
    contact,
    farmor,
    trips,
    transactionDate,
    gross,
    fees,
    netTotal,
  } = transactionEdge.node;

  return (
    <Box display="flex" flexDirection="row" flex="1" mb={1}>
      <Box display="flex" flex="1">
        <Typography variant="body2">{startCase(action)}</Typography>
      </Box>
      <Box display="flex" flex="2">
        <Typography variant="body2">
          {contact
            ? `${contact.firstName} ${contact.lastName}`
            : farmor
            ? farmor.operatorName
            : "-"}
        </Typography>
      </Box>
      <Box display="flex" flex="2" flexDirection="column">
        {trips.length ? (
          trips.map((trip, i) => {
            return <TripInfo key={i} trip={trip} />;
          })
        ) : (
          <Typography variant="body2">-</Typography>
        )}
      </Box>
      <Box display="flex" flex="1">
        <Typography variant="body2">
          {moment(transactionDate).format("MM/DD/YY")}
        </Typography>
      </Box>
      <Box display="flex" flex="1" justifyContent="flex-end">
        <Typography variant="body2">{currency(gross)}</Typography>
      </Box>
      <Box display="flex" flex="1" justifyContent="flex-end">
        <Typography variant="body2">{currency(fees)}</Typography>
      </Box>
      <Box display="flex" flex="1" justifyContent="flex-end">
        <Typography variant="body2">{currency(netTotal)}</Typography>
      </Box>
    </Box>
  );
}

export default TransactionsListItemDesktop;
