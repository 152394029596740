import { gql } from "@apollo/client";

export const UPDATE_CALENDAR_TRIP_INVITE_MUTATION = gql`
  mutation UpdateCalendarTripInvite($input: UpdateCalendarTripInviteInput!) {
    updateCalendarTripInvite(input: $input) {
      trip {
        id
        calendarTripInviteBcEnabled
        calendarTripInvitePassengerEnabled
      }
    }
  }
`;
