import React from "react";
import { useHistory } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";

import { ArrowRightIcon } from "design-system/icons";
import { granite, moovsBlue } from "design-system/colors";
import { useScreenSize } from "globals/hooks";

type QuickLinkProps = {
  src: string;
  navigateButtonText: string;
  subtext: string;
  path: string;
  toExternalLink: boolean;
};

function QuickLink(props: QuickLinkProps) {
  const { src, navigateButtonText, subtext, path, toExternalLink } = props;

  // hooks
  const history = useHistory();
  const { isMobileView } = useScreenSize();

  // event handler
  const handleNavigateClick = () => {
    if (!toExternalLink) {
      history.push(path);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignSelf="center"
      width={isMobileView ? "98%" : "305px"}
      mb={isMobileView ? 2 : 0}
    >
      <img src={src} alt="driver-platform" />
      <Button
        variant="outlined"
        sx={{ marginY: 2 }}
        onClick={handleNavigateClick}
        {...(toExternalLink && {
          target: "_blank",
          href: path,
        })}
      >
        <Typography mr={2} fontWeight={500}>
          {navigateButtonText}
        </Typography>
        <ArrowRightIcon size="small" color={moovsBlue} strokeWeight="2.5" />
      </Button>
      <Typography variant="body2" color={granite} textAlign="center" px={5}>
        {subtext}
      </Typography>
    </Box>
  );
}

export default QuickLink;
