import React from "react";

type DurationIconProps = {
  color?: string;
  viewBox?: string;
  height?: string;
  width?: string;
  size?: "small";
};

const DurationIcon = (props: DurationIconProps) => {
  const { color, size, viewBox, height, width } = props;

  if (size === "small") {
    return (
      <svg
        width={width || "16"}
        height={height || "16"}
        viewBox={viewBox || "0 0 16 16"}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 4V7L9.4 8.2M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.6863 3.68629 1 7 1C10.3137 1 13 3.6863 13 7Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox={viewBox || "0 0 24 24"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 6V11L15 13M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DurationIcon;
