import { AutoPaymentPreference } from "types";

const { NoCharge, Deposit, FullCharge } = AutoPaymentPreference;

export const toggleOptions = [
  {
    label: "No Charge",
    value: NoCharge,
    ariaLabel: NoCharge,
  },
  {
    label: "Deposit",
    value: Deposit,
    ariaLabel: Deposit,
  },
  {
    label: "Full Charge",
    value: FullCharge,
    ariaLabel: FullCharge,
  },
];

export const toggleSubtext = {
  [NoCharge]: "Customers won't be charged",
  [Deposit]: "Collect a partial payment",
  [FullCharge]: "Collect the total amount",
};
