import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { Box, Typography, Button, Hidden, Divider } from "@mui/material";

import { LOAD_CANCELLATION_POLICIES_QUERY } from "../../globals/graphql";
import { CancellationPolicy } from "../../types";
import { granite, white } from "../../design-system/colors";
import { PlusIcon } from "../../design-system/icons";
import CancellationPoliciesList from "../../components/settings/cancellation/CancellationPoliciesList";
import SquareButton from "../../design-system/SquareButton";
import GQLQueryStatusIndicator from "../../components/GQLQueryStatusIndicator";
import { useSnackbar } from "../../globals/hooks/useSnackbar";
import { useAnalytics, useScreenSize } from "../../globals/hooks";
import {
  containerStyles,
  headerStyles,
  listStyles,
  tableStyles,
} from "./utils/styles";

function CancellationSettingsPage() {
  const snackbar = useSnackbar();
  const { isSmallTabletView } = useScreenSize();
  const { track } = useAnalytics();

  const { data, error, refetch, networkStatus, loading } = useQuery(
    LOAD_CANCELLATION_POLICIES_QUERY,
    {
      onError: (error) => {
        snackbar.error();
      },
    }
  );

  // event handler
  const handleCreatePolicyClick = () => track("cancellation_createInitiated");

  if (!data || loading) {
    return (
      <GQLQueryStatusIndicator
        name="Cancellation Policy"
        error={error}
        data={data}
        loading={loading}
        networkStatus={networkStatus}
        refetch={refetch}
      />
    );
  }

  const { cancellationPolicies } = data;
  const policies = !!cancellationPolicies.length;

  const policyVariants = ["FLEXIBLE", "MODERATE", "STRICT"].filter(
    (variant) =>
      !cancellationPolicies.find(
        (policy: CancellationPolicy) => policy.variant === variant
      )
  );

  return (
    <>
      <Helmet>
        <title>Cancellation | Moovs</title>
      </Helmet>
      <Box sx={containerStyles}>
        <Box sx={headerStyles}>
          <Typography variant="h2">Cancellation</Typography>
          <Box>
            {policies && (
              <Box>
                {isSmallTabletView ? (
                  <SquareButton
                    disabled={
                      policyVariants.length === 0 || policyVariants.length === 3
                    }
                    disableElevation
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/settings/cancellation/create"
                    onClick={handleCreatePolicyClick}
                  >
                    <PlusIcon color={white} />
                  </SquareButton>
                ) : (
                  <Button
                    disableElevation
                    disabled={
                      policyVariants.length === 0 || policyVariants.length === 3
                    }
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/settings/cancellation/create"
                    onClick={handleCreatePolicyClick}
                  >
                    Create Policy
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Box>

        <Hidden mdDown>
          <Box px={3}>
            <Divider />
          </Box>
        </Hidden>

        {policies ? (
          <Box {...(!isSmallTabletView && { sx: tableStyles })}>
            <CancellationPoliciesList
              cancellationPolicies={cancellationPolicies}
              listStyles={listStyles}
            />
          </Box>
        ) : (
          <Box
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            display="flex"
            flex="1"
            flexDirection="column"
          >
            <Box m={1}>
              <Typography variant="h5">No Policy Added Yet.</Typography>
            </Box>
            <Box maxWidth="410px" mb={3}>
              <Typography variant="body2" sx={{ color: granite }}>
                Add a new cancellation policy to the list.
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                size="large"
                to="/settings/cancellation/create"
                component={Link}
                onClick={handleCreatePolicyClick}
              >
                Create Policy
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}

export default CancellationSettingsPage;
