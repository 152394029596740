import React from "react";

type CompanyIconProps = {
  color?: string;
  size?: "small";
  strokeWidth?: "2";
};

const CompanyIcon = (props: CompanyIconProps) => {
  const { color, size, strokeWidth } = props;

  return (
    <svg
      width={size === "small" ? "16" : "24"}
      height={size === "small" ? "16" : "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66667 6.09524V22H19.3333V6.09524M4.66667 6.09524L6 2H18L19.3333 6.09524M4.66667 6.09524H12H19.3333M2 22H22M14 20C14 19.4696 13.7893 18.9609 13.4142 18.5858C13.0391 18.2107 12.5304 18 12 18C11.4696 18 10.9609 18.2107 10.5858 18.5858C10.2107 18.9609 10 19.4696 10 20V22H14V20Z"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.03809 10.1855C8.12394 10.1855 8.20628 10.2197 8.26699 10.2804C8.3277 10.3411 8.36181 10.4234 8.36181 10.5093"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.71436 10.5093C7.71436 10.4234 7.74846 10.3411 7.80917 10.2804C7.86988 10.2197 7.95222 10.1855 8.03808 10.1855"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.03808 10.8335C7.95222 10.8335 7.86988 10.7994 7.80917 10.7387C7.74846 10.678 7.71436 10.5956 7.71436 10.5098"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.36181 10.5098C8.36181 10.5956 8.3277 10.678 8.26699 10.7387C8.20628 10.7994 8.12394 10.8335 8.03809 10.8335"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.03809 14.0703C8.12394 14.0703 8.20628 14.1044 8.26699 14.1651C8.3277 14.2258 8.36181 14.3082 8.36181 14.394"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.71436 14.394C7.71436 14.3082 7.74846 14.2258 7.80917 14.1651C7.86988 14.1044 7.95222 14.0703 8.03808 14.0703"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.03808 14.7183C7.95222 14.7183 7.86988 14.6841 7.80917 14.6234C7.74846 14.5627 7.71436 14.4804 7.71436 14.3945"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.36181 14.3945C8.36181 14.4804 8.3277 14.5627 8.26699 14.6234C8.20628 14.6841 8.12394 14.7183 8.03809 14.7183"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9229 10.1855C12.0087 10.1855 12.091 10.2197 12.1518 10.2804C12.2125 10.3411 12.2466 10.4234 12.2466 10.5093"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5991 10.5093C11.5991 10.4234 11.6332 10.3411 11.6939 10.2804C11.7546 10.2197 11.837 10.1855 11.9228 10.1855"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9228 10.8335C11.837 10.8335 11.7546 10.7994 11.6939 10.7387C11.6332 10.678 11.5991 10.5956 11.5991 10.5098"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2466 10.5098C12.2466 10.5956 12.2125 10.678 12.1518 10.7387C12.091 10.7994 12.0087 10.8335 11.9229 10.8335"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9229 14.0703C12.0087 14.0703 12.091 14.1044 12.1518 14.1651C12.2125 14.2258 12.2466 14.3082 12.2466 14.394"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5991 14.394C11.5991 14.3082 11.6332 14.2258 11.6939 14.1651C11.7546 14.1044 11.837 14.0703 11.9228 14.0703"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9228 14.7183C11.837 14.7183 11.7546 14.6841 11.6939 14.6234C11.6332 14.5627 11.5991 14.4804 11.5991 14.3945"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2466 14.3945C12.2466 14.4804 12.2125 14.5627 12.1518 14.6234C12.091 14.6841 12.0087 14.7183 11.9229 14.7183"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8076 10.1855C15.8935 10.1855 15.9758 10.2197 16.0365 10.2804C16.0972 10.3411 16.1313 10.4234 16.1313 10.5093"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4834 10.5093C15.4834 10.4234 15.5175 10.3411 15.5782 10.2804C15.6389 10.2197 15.7213 10.1855 15.8071 10.1855"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8071 10.8335C15.7213 10.8335 15.6389 10.7994 15.5782 10.7387C15.5175 10.678 15.4834 10.5956 15.4834 10.5098"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1313 10.5098C16.1313 10.5956 16.0972 10.678 16.0365 10.7387C15.9758 10.7994 15.8935 10.8335 15.8076 10.8335"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8076 14.0703C15.8935 14.0703 15.9758 14.1044 16.0365 14.1651C16.0972 14.2258 16.1313 14.3082 16.1313 14.394"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4834 14.394C15.4834 14.3082 15.5175 14.2258 15.5782 14.1651C15.6389 14.1044 15.7213 14.0703 15.8071 14.0703"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8071 14.7183C15.7213 14.7183 15.6389 14.6841 15.5782 14.6234C15.5175 14.5627 15.4834 14.4804 15.4834 14.3945"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1313 14.3945C16.1313 14.4804 16.0972 14.5627 16.0365 14.6234C15.9758 14.6841 15.8935 14.7183 15.8076 14.7183"
        stroke={color || "#999999"}
        strokeWidth={strokeWidth || "1.5528"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CompanyIcon;
