import React from "react";
import { useMutation } from "@apollo/client";

import { ArchiveIcon, UnArchiveIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";
import { TOGGLE_QUOTE_ARCHIVED_MUTATION } from "globals/graphql";

type UseArchiveQuoteClickProps = {
  requestId: string;
  isArchived: boolean;
  isExpired: boolean;
};

function useArchiveQuoteClick(
  props: UseArchiveQuoteClickProps
): EllipsisMenuOption {
  const { requestId, isArchived, isExpired } = props;

  // hooks
  const { track } = useAnalytics();
  const snackbar = useSnackbar();
  const [toggleQuoteArchived] = useMutation(TOGGLE_QUOTE_ARCHIVED_MUTATION, {
    refetchQueries: ["LoadQuotes", "Request", "LoadNewQuotes"],
    onCompleted(data) {
      const { isArchived } = data.updateRequest.request;

      snackbar.success(
        isArchived
          ? "Quote successfully archived"
          : "Quote successfully re-opened"
      );
    },
    onError() {
      snackbar.error(
        isArchived ? "Error re-opening quote" : "Error archiving quote"
      );
    },
  });

  // event handlers
  const handleArchiveQuoteClick = async () => {
    await toggleQuoteArchived({
      variables: {
        input: {
          requestId,
          isArchived: !isArchived,
        },
      },
    });

    track(isArchived ? "quote_reopen" : "quote_archive");
  };

  return {
    text: isArchived || isExpired ? "Re-open Quote" : "Archive Quote",
    icon:
      isArchived || isExpired ? (
        <UnArchiveIcon color={grayDark} />
      ) : (
        <ArchiveIcon color={grayDark} />
      ),
    onClick: handleArchiveQuoteClick,
    disableOption: isExpired,
    tooltip: isExpired && {
      titleText: "Can't re-open quotes with a pick up date in the past",
    },
  };
}

export { useArchiveQuoteClick };
