import React from "react";

type ProPlanWithTextIconProps = {
  color?: string;
};

const ProPlanWithTextIcon = (props: ProPlanWithTextIconProps) => {
  const { color } = props;

  return (
    <svg
      width="60"
      height="31"
      viewBox="0 0 60 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.825684" width="60" height="30" rx="15" fill="#E9F9F5" />
      <path
        d="M16.5335 12.8257L14.1335 14.2567M16.5335 18.8257L14.1335 17.4115M19.5335 9.82568C20.5276 9.82568 21.3335 10.6316 21.3335 11.6257C21.3335 12.6198 20.5276 13.4257 19.5335 13.4257C18.5394 13.4257 17.7335 12.6198 17.7335 11.6257C17.7335 10.6316 18.5394 9.82568 19.5335 9.82568ZM19.5335 18.2257C20.5276 18.2257 21.3335 19.0316 21.3335 20.0257C21.3335 21.0198 20.5276 21.8257 19.5335 21.8257C18.5394 21.8257 17.7335 21.0198 17.7335 20.0257C17.7335 19.0316 18.5394 18.2257 19.5335 18.2257ZM11.1335 14.0257C12.1276 14.0257 12.9335 14.8316 12.9335 15.8257C12.9335 16.8198 12.1276 17.6257 11.1335 17.6257C10.1394 17.6257 9.3335 16.8198 9.3335 15.8257C9.3335 14.8316 10.1394 14.0257 11.1335 14.0257Z"
        stroke={color || "#28B299"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.516 13.9097C36.516 14.6057 36.276 15.1857 35.796 15.6497C35.324 16.1057 34.6 16.3337 33.624 16.3337H32.016V19.8257H30.924V11.4617H33.624C34.568 11.4617 35.284 11.6897 35.772 12.1457C36.268 12.6017 36.516 13.1897 36.516 13.9097ZM33.624 15.4337C34.232 15.4337 34.68 15.3017 34.968 15.0377C35.256 14.7737 35.4 14.3977 35.4 13.9097C35.4 12.8777 34.808 12.3617 33.624 12.3617H32.016V15.4337H33.624ZM38.9652 14.3177C39.1572 13.9417 39.4292 13.6497 39.7812 13.4417C40.1412 13.2337 40.5772 13.1297 41.0892 13.1297V14.2577H40.8012C39.5772 14.2577 38.9652 14.9217 38.9652 16.2497V19.8257H37.8732V13.2497H38.9652V14.3177ZM45.2178 19.9337C44.6018 19.9337 44.0418 19.7937 43.5378 19.5137C43.0418 19.2337 42.6498 18.8377 42.3618 18.3257C42.0818 17.8057 41.9418 17.2057 41.9418 16.5257C41.9418 15.8537 42.0858 15.2617 42.3738 14.7497C42.6698 14.2297 43.0698 13.8337 43.5738 13.5617C44.0778 13.2817 44.6418 13.1417 45.2658 13.1417C45.8898 13.1417 46.4538 13.2817 46.9578 13.5617C47.4618 13.8337 47.8578 14.2257 48.1458 14.7377C48.4418 15.2497 48.5898 15.8457 48.5898 16.5257C48.5898 17.2057 48.4378 17.8057 48.1338 18.3257C47.8378 18.8377 47.4338 19.2337 46.9218 19.5137C46.4098 19.7937 45.8418 19.9337 45.2178 19.9337ZM45.2178 18.9737C45.6098 18.9737 45.9778 18.8817 46.3218 18.6977C46.6658 18.5137 46.9418 18.2377 47.1498 17.8697C47.3658 17.5017 47.4738 17.0537 47.4738 16.5257C47.4738 15.9977 47.3698 15.5497 47.1618 15.1817C46.9538 14.8137 46.6818 14.5417 46.3458 14.3657C46.0098 14.1817 45.6458 14.0897 45.2538 14.0897C44.8538 14.0897 44.4858 14.1817 44.1498 14.3657C43.8218 14.5417 43.5578 14.8137 43.3578 15.1817C43.1578 15.5497 43.0578 15.9977 43.0578 16.5257C43.0578 17.0617 43.1538 17.5137 43.3458 17.8817C43.5458 18.2497 43.8098 18.5257 44.1378 18.7097C44.4658 18.8857 44.8258 18.9737 45.2178 18.9737Z"
        fill="#28B299"
      />
    </svg>
  );
};

export default ProPlanWithTextIcon;
