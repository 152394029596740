export enum TaskVariant {
  VEHICLE = "vehicle",
  DRIVER = "driver",
  QUOTE = "quote",
  RESERVATION = "reservation",
  MOOVS_PAYMENT = "moovs-payment",
}

export const TASK_TEXT = {
  [TaskVariant.VEHICLE]: {
    header: "Create your first vehicle",
    subtext:
      "Create vehicles to display on the customer portal and start getting bookings!",
  },
  [TaskVariant.DRIVER]: {
    header: "Create your first driver",
    subtext: "Create drivers so you can start assigning them jobs.",
  },
  [TaskVariant.QUOTE]: {
    header: "Send first quote",
    subtext: "Quickly build out quote and send to get your first booking",
  },
  [TaskVariant.RESERVATION]: {
    header: "Create your first reservation",
    subtext: "Create reservations to start managing your bookings.",
  },
  [TaskVariant.MOOVS_PAYMENT]: {
    header: "Setup your Moovs Payment account",
    subtext:
      "Setup your Moovs Payment and add a bank account to start collecting credit card payments.",
  },
};
