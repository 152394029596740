import VehicleCard from "./VehicleCard";
import VehicleCardContentRightEdgeBlock from "./VehicleCardContentRightEdgeBlock";
import VehicleCardContent from "./VehicleCardContent";
import FarmAffiliateVehicleCard from "./FarmAffiliateVehicleCard";

export {
  VehicleCardContentRightEdgeBlock,
  VehicleCardContent,
  FarmAffiliateVehicleCard,
};

export default VehicleCard;
