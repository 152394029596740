import React from "react";

type ArrowUpIconProps = {
  color?: string;
};

const ArrowUpIcon = (props: ArrowUpIconProps) => {
  const { color } = props;

  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.757324 9.24309L9.24261 0.757812M9.24261 0.757812V9.24309M9.24261 0.757812H0.757324"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowUpIcon;
