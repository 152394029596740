import React from "react";

import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

import MoovsBottomDrawer from "components/MoovsBottomDrawer";
import { CrossIcon } from "design-system/icons";
import { granite } from "design-system/colors";
import { useScreenSize } from "globals/hooks/useScreenSize";

type RecipientsBottomDrawerProps = {
  drawerOpen: boolean;
  onSetDrawerOpen: (boolean: boolean) => void;
  title: string;
  toList: string[];
  ccList: string[];
};

function RecipientsBottomDrawer(props: RecipientsBottomDrawerProps) {
  const { drawerOpen, onSetDrawerOpen, title, toList, ccList } = props;

  // hooks
  const theme = useTheme();
  const { isMobileView } = useScreenSize();

  // event handler
  const handleDrawerClose = () => onSetDrawerOpen(false);

  return (
    <MoovsBottomDrawer
      open={drawerOpen}
      onClose={handleDrawerClose}
      overrideZIndex={theme.zIndex.modal}
      isMobileView={isMobileView}
    >
      <Box display="flex" flexDirection="column" m={3}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="h5">{title}</Typography>
          <Box display="flex" flexDirection="row" mt={-1.5} mr={-1.5}>
            <IconButton onClick={handleDrawerClose} size="large">
              <CrossIcon color={granite} />
            </IconButton>
          </Box>
        </Box>

        <Box>
          <Typography variant="h6">To</Typography>
          <Box display="flex" flexDirection="column">
            {toList?.map((item) => (
              <Typography variant="caption" key={item}>
                {item}
              </Typography>
            ))}
          </Box>
        </Box>

        <Box mt={2}>
          <Typography variant="h6">CC</Typography>
          <Box display="flex" flexDirection="column">
            {ccList?.map((item) => (
              <Typography variant="caption" key={item}>
                {item}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
    </MoovsBottomDrawer>
  );
}

export default RecipientsBottomDrawer;
