import React from "react";
import first from "lodash/first";

import { Box, Paper, Typography } from "@mui/material";

import { EstimationIcon } from "design-system/icons";
import { Stop } from "types";
import FlightChangeDisplay from "pages/requests/RequestOverview/ReservationOverview/TripItineraryBlock/components/FlightChangeDisplay";

type TripTrackerDesktopRowProps = {
  title: string;
  customerData: string | number;
  driverData: string;
  farmeeData: string;
  dropOffIsEstimate?: boolean | null;
  isFarmedRoute: boolean;
  stops?: Stop[];
};

const styles = {
  rowTitle: {
    fontWeight: 600,
  },
};

const TripTrackerDesktopRow = (props: TripTrackerDesktopRowProps) => {
  const {
    title,
    customerData,
    driverData,
    farmeeData,
    dropOffIsEstimate = null,
    isFarmedRoute,
    stops,
  } = props;

  return (
    <Paper variant="outlined" style={{ marginBottom: "8px" }}>
      <Box
        display="flex"
        flex="1"
        flexDirection="row"
        height="68px"
        ml={2}
        alignItems="center"
      >
        <Box flex="1">
          <Typography variant="subtitle1" sx={styles.rowTitle}>
            {title}
          </Typography>
        </Box>
        {/* CUSTOMER */}
        <Box flex="1">
          {dropOffIsEstimate ? (
            <Box display="flex">
              <Box mr={0.5} display="flex" alignItems="center">
                <Typography variant="caption">{customerData || "-"}</Typography>
              </Box>
              <EstimationIcon size="small" />
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              <Typography variant="caption">{customerData || "-"}</Typography>
              <Box ml={0.5}>
                <FlightChangeDisplay
                  flightOffset={first(stops)?.flightOffset}
                  originalDateTime={first(stops)?.originalDateTime}
                  dateTime={first(stops)?.dateTime}
                  iconSize="small"
                />
              </Box>
            </Box>
          )}
        </Box>
        {/* DRIVER */}
        <Box flex="1" display="flex" alignItems="center">
          <Typography variant="caption">{driverData}</Typography>
        </Box>
        {/* AFFILIATE */}
        {isFarmedRoute && (
          <Box flex="1" display="flex" alignItems="center">
            <Typography variant="caption">{farmeeData}</Typography>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default TripTrackerDesktopRow;
