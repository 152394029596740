import React, { useState, useMemo } from "react";
import { useMutation } from "@apollo/client";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { Box, Divider, TextField, Typography } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";
import { useAnalytics, useScreenSize, useSnackbar } from "globals/hooks";
import StripeTextField from "components/common/CreditCardElements/StripeTextField";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { CREATE_LAGO_CUSTOMER_AND_ATTACH_PAYMENT_METHOD_MUTATION } from "globals/graphql";
import { getLagoPlanCode } from "../../utils/getLagoPlanCode";
import { currency } from "globals/utils/helpers";
import { PlanInfoSection, SubscriptionLineItem } from "./components";
import { grayLight } from "design-system/colors";
import { InfoIcon } from "design-system/icons";
import { BaseUpgradeSubscriptionDialogProps } from "./UpgradeSubscriptionDialog";
import { useCreateSubscriptionPaymentMethodForm } from "../../../../../BillingPage/hooks/useCreateSubscriptionPaymentMethodForm";

function UpgradeFirstSubscriptionDialog(
  props: BaseUpgradeSubscriptionDialogProps
) {
  const { open, onClose, planName, planLength, pricePerMonth, icon, bgColor } =
    props;

  // state
  const [hideLoadingIndicator, setHideLoadingIndicator] = useState(true);

  // hooks
  const { isSmallTabletView } = useScreenSize();
  const snackbar = useSnackbar();
  const elements = useElements();
  const stripe = useStripe();
  const { track } = useAnalytics();
  const {
    submitDisabled,
    setSubmitDisabled,
    cardHolderName,
    handleCardholderNameChange,
    handleStripeElementChange,
    resetFormState,
  } = useCreateSubscriptionPaymentMethodForm();

  // memo
  const subscriptionFee = useMemo(() => {
    const raw = planLength === "monthly" ? pricePerMonth : pricePerMonth * 12;
    const withCents = currency(raw);

    return {
      raw,
      withCents,
      withoutCents: withCents.slice(0, -3),
    };
  }, [planLength, pricePerMonth]);

  const setupFee = useMemo(() => {
    const raw = 299;
    const withCents = currency(raw);

    return {
      raw,
      withCents,
      withoutCents: withCents.slice(0, -3),
    };
  }, []);

  // mutations
  const [createLagoCustomerAndAttachPaymentMethod] = useMutation(
    CREATE_LAGO_CUSTOMER_AND_ATTACH_PAYMENT_METHOD_MUTATION,
    {
      onError(error) {
        setSubmitDisabled(false);

        const errorMessage = getErrorMessage(error);

        snackbar.error(errorMessage);
      },
      onCompleted() {
        snackbar.success("Successfully upgraded subscription!");

        track("subscriptions_planSubscribed", {
          term: planLength,
          package: planName,
        });

        onClose();
      },
    }
  );

  // event handlers
  const handleSavePaymentMethod = async () => {
    setSubmitDisabled(true);
    setHideLoadingIndicator(false);

    const card = elements?.getElement(CardNumberElement);
    if (!stripe || !elements || card === null) {
      return;
    }

    // create stripe payment method
    const {
      paymentMethod: stripePaymentMethod,
      error: stripePaymentMethodError,
    } = await stripe.createPaymentMethod({
      type: "card",
      card: card,
      billing_details: {
        name: cardHolderName,
      },
    });

    if (stripePaymentMethodError) {
      snackbar.error(stripePaymentMethodError.message);
      return;
    }

    createLagoCustomerAndAttachPaymentMethod({
      variables: {
        input: {
          stripePaymentMethod,
          lagoPlanCode: getLagoPlanCode(planLength, planName),
        },
      },
    });
  };

  const handleOnClose = () => {
    resetFormState();
    onClose();
  };

  return (
    <MoovsDialog
      open={open}
      onClose={handleOnClose}
      onAccept={handleSavePaymentMethod}
      acceptDisabled={submitDisabled}
      hideLoadingIndicator={hideLoadingIndicator}
      dialogTitle={`Confirm ${planLength} ${planName} subscription`}
      acceptButtonText="Subscribe"
      closeButtonText="Cancel"
      size="sm"
    >
      <Box py={2}>
        <PlanInfoSection
          planName={planName}
          icon={icon}
          bgColor={bgColor}
          pricePerMonth={pricePerMonth}
        />

        {/* Payment Method Form Section */}
        <Box pt={3} pb={2}>
          <TextField
            variant="outlined"
            label="Name on card"
            value={cardHolderName}
            fullWidth
            onChange={handleCardholderNameChange}
            InputLabelProps={{ shrink: true }}
          />
          <Box py={2}>
            <StripeTextField
              stripeElement={CardNumberElement}
              label="Credit card number"
              onChange={handleStripeElementChange}
            />
          </Box>
          <Box display="flex">
            <Box flex={1} pr={2}>
              <StripeTextField
                stripeElement={CardExpiryElement}
                label="MM/YY"
                onChange={handleStripeElementChange}
              />
            </Box>
            <Box flex={1} pl={2}>
              <StripeTextField
                stripeElement={CardCvcElement}
                label="CVC"
                onChange={handleStripeElementChange}
              />
            </Box>
          </Box>
        </Box>

        {/* Price Breakdown Section */}
        <Box display="flex" flexDirection="column" px={2} py={1.5} my={2}>
          <Box mb={2}>
            <SubscriptionLineItem
              name="Subscription fee"
              price={subscriptionFee.withCents}
            />
            <Divider sx={{ my: 0.5 }} />
            <SubscriptionLineItem name="Setup fee" price={setupFee.withCents} />
          </Box>
          {/* Total */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              px: 2,
              py: 1.5,
              mx: -2,
              borderRadius: "4px",
              backgroundColor: grayLight,
              ...(!isSmallTabletView && {
                width: "212px",
                alignSelf: "flex-end",
              }),
            }}
          >
            <Typography variant="subtitle2">Total Amount</Typography>
            <Typography variant="subtitle2">
              {currency(subscriptionFee.raw + setupFee.raw)}
            </Typography>
          </Box>
        </Box>

        {/* Charge Info */}
        <Box>
          <Box display="flex" alignItems="center">
            <InfoIcon size="md" />
            <Typography ml={1} variant="body2">
              {`You will be charged ${subscriptionFee.withoutCents} and ${
                setupFee.withoutCents
              } today and then ${subscriptionFee.withoutCents} every ${
                planLength === "monthly" ? "month" : "year"
              }`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </MoovsDialog>
  );
}

export default UpgradeFirstSubscriptionDialog;
