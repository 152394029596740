import React from "react";
import { useHistory } from "react-router-dom";

import { Box, Typography, Button } from "@mui/material";

import { Contact } from "types";
import ContactsListItemDesktop from "components/contacts/people/ContactsListItem.desktop";

type ContactsListProps = {
  contacts: Contact[];
  setAddDialogOpen: (open: boolean) => void;
};

function ContactsList(props: ContactsListProps) {
  const { contacts, setAddDialogOpen } = props;

  // hooks
  const history = useHistory();

  // event handlers
  const handleAddCompanyContactClick = () => {
    setAddDialogOpen(true);
  };

  const handleContactClick = (contactId: string) => {
    history.push(`/contacts/update/${contactId}`);
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant="h5">Contacts</Typography>
      </Box>
      {contacts.map((contact: Contact) => {
        return (
          <Box key={contact.id} mb={1}>
            <ContactsListItemDesktop
              mode="updateCompanyDrawer"
              contact={contact}
              onContactClick={handleContactClick}
            />
          </Box>
        );
      })}
      <Box my={2}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleAddCompanyContactClick}
        >
          Add Contact
        </Button>
      </Box>
    </>
  );
}

export default ContactsList;
