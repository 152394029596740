import React from "react";

import { Typography, Box, BoxProps } from "@mui/material";

import { moovsBlue } from "../../../design-system/colors";

type UseCompanyAddressButtonProps = {
  onClick: () => void;
  layoutProps?: BoxProps;
};

function UseCompanyAddressButton(props: UseCompanyAddressButtonProps) {
  const { onClick, layoutProps } = props;
  return (
    <Box onClick={onClick} {...layoutProps}>
      <Typography
        variant="body2"
        style={{
          color: moovsBlue,
          cursor: "pointer",
          fontWeight: 500,
        }}
      >
        Use Company Address
      </Typography>
    </Box>
  );
}

export default UseCompanyAddressButton;
