import React from "react";

import { Box, Typography } from "@mui/material";

function BadTripInfo() {
  return (
    <Box
      width="100%"
      height="calc(100vh - 78px)"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h5" align="center">
        Something went wrong retrieving this trip. Please contact
        info@moovsapp.com.
      </Typography>
    </Box>
  );
}

export default BadTripInfo;
