/**
 * App.tsx
 * Entry point to Moovs Operator App.
 * Wraps Main.tsx with Apollo, MaterialUI, ReactRouter and custom Swoop Providers.
 *
 * components:
 *  App
 */

import React, { Suspense } from "react";
import * as Sentry from "@sentry/react";
import { BrowserRouter, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";

import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";

import OuterRouter from "./routes/OuterRouter";
import MoovsApolloProvider from "./apollo-provider/MoovsApolloProvider";
import { SnackbarProvider } from "./globals/hooks/useSnackbar";
import { SegmentProvider } from "globals/hooks/useSegment";
import { useForceReload } from "./globals/hooks";
import LoadingPage from "pages/LoadingPage";
import { AuthProvider } from "globals/hooks/useAuth";

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_PUBLIC_DNS });

function App() {
  // force reload on pinned iOS devices when serving stale cached frontend
  // temp commented out for bugfix
  useForceReload();

  return (
    <Suspense fallback={<LoadingPage />}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <BrowserRouter>
          <QueryParamProvider ReactRouterRoute={Route}>
            <SnackbarProvider>
              <MoovsApolloProvider>
                <SegmentProvider>
                  <AuthProvider>
                    <OuterRouter />
                  </AuthProvider>
                </SegmentProvider>
              </MoovsApolloProvider>
            </SnackbarProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </Suspense>
  );
}

export default App;
