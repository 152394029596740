import React, { Dispatch, SetStateAction } from "react";
import { useMutation } from "@apollo/client";

import { GridEvents, GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import Select, { SelectProps } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { MenuProps } from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { DispatchStatusEnum } from "globals/utils/enumMaps";
import { StatusCircleFullIcon, StatusCircleIcon } from "design-system/icons";
import { rideStatusLabelColors } from "design-system/colors";
import { UPDATE_ROUTE_DISPATCH_MUTATION } from "globals/graphql";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { useAnalytics, useSnackbar } from "globals/hooks";

type DispatchStatusEditColumnType = {
  params: GridRenderEditCellParams;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function DispatchStatusEditColumn(props: DispatchStatusEditColumnType) {
  const { params, setSaveIndicatorState } = props;
  const { id, value, api, field, row } = params;

  // derived state
  const routeId = row?.routes[0].id;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // mutations
  const [updateRouteDispatch] = useMutation(UPDATE_ROUTE_DISPATCH_MUTATION, {
    onCompleted() {
      track("reservationTripView_tripStatusUpdated");
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      const errorMessage =
        getErrorMessage(error) || "Error updating route status.";
      snackbar.error(errorMessage);
    },
  });

  // event handlers
  const handleChange: SelectProps["onChange"] = (event) => {
    setSaveIndicatorState("loading");
    const slug = event.target.value.toString();
    api.setEditCellValue({ id, field, value: slug }, event);
    api.commitCellChange({ id, field });
    api.setCellMode(id, field, "view");
    // to support event bubbling of events fired from portals, Mui currently doesn't support this on its own.
    if ((event as any).key) {
      const params = api.getCellParams(id, field);
      api.publishEvent(
        GridEvents.cellNavigationKeyDown,
        params,
        event as any as React.KeyboardEvent<HTMLElement>
      );
    }

    updateRouteDispatch({
      variables: {
        input: {
          routeId,
          statusSlug: slug,
        },
      },
    });
  };

  const handleClose: MenuProps["onClose"] = (_, reason) => {
    if (reason === "backdropClick") {
      api.setCellMode(id, field, "view");
    }
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      MenuProps={{
        onClose: handleClose,
      }}
      autoFocus
      fullWidth
      open
      variant="standard"
      sx={{
        "& .MuiSelect-select": {
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      {Object.entries(DispatchStatusEnum).map(([slug, option]) => {
        const statusIconColor = rideStatusLabelColors[slug].color;
        const isActive = slug === value;

        return (
          <MenuItem key={slug} value={slug}>
            <ListItemIcon sx={{ minWidth: "24px" }}>
              {isActive ? (
                <StatusCircleFullIcon color={statusIconColor} />
              ) : (
                <StatusCircleIcon color={statusIconColor} />
              )}
            </ListItemIcon>
            <ListItemText sx={{ overflow: "hidden" }} primary={option} />
          </MenuItem>
        );
      })}
    </Select>
  );
}

export default DispatchStatusEditColumn;
