import { useMutation } from "@apollo/client";
import { Box, Typography } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";
import LabeledSwitchInput from "components/settings/general/LabeledSwitchInput";
import { granite, grayDark, grayLight } from "design-system/colors";
import { InfoIcon } from "design-system/icons";
import { UPDATE_SMS_RECIPIENTS_FOR_TRIP_MUTATION } from "globals/graphql/tripNotifications.graphql";
import { useOperator, useSnackbar } from "globals/hooks";
import getRecipient, { getDefaultRecipient } from "globals/utils/getRecipient";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { useState } from "react";
import { TextRecipientEnum, Trip } from "types";

type OverrideSMSRecipientsForTripDialogProps = {
  open: boolean;
  onClose: () => void;
  trip: Trip;
};

function OverrideSMSRecipientsForTripDialog(
  props: OverrideSMSRecipientsForTripDialogProps
) {
  const { open, onClose, trip } = props;

  const {
    driverStatusUpdateTextsEnabled,
    settings: { memberChangedStatusTextEnabled },
  } = useOperator();
  const snackbar = useSnackbar();

  const isTriggersDisabled =
    !driverStatusUpdateTextsEnabled && !memberChangedStatusTextEnabled;

  const [statusUpdateTextTo, setStatusUpdateTextTo] = useState(
    getDefaultRecipient(isTriggersDisabled, trip.statusUpdateTextTo)
  );
  const [reminderTextTo, setReminderTextTo] = useState(
    trip.reminderTextTo || TextRecipientEnum.None
  );

  const [updateTripTextRecipients, { loading: isLoading }] = useMutation(
    UPDATE_SMS_RECIPIENTS_FOR_TRIP_MUTATION,
    {
      onCompleted() {
        snackbar.success(
          "Notification settings for this trip has been updated!"
        );
        onClose();
      },
      onError(error) {
        snackbar.error(
          getErrorMessage(error) ||
            "There were issues updating sms recipients settings for this trip"
        );
      },
    }
  );

  const isReminderRecipientPassenger =
    reminderTextTo === TextRecipientEnum.Passenger ||
    reminderTextTo === TextRecipientEnum.Both;

  const isReminderRecipientBookingContact =
    reminderTextTo === TextRecipientEnum.BookingContact ||
    reminderTextTo === TextRecipientEnum.Both;

  const isTripStatusRecipientPassenger =
    statusUpdateTextTo === TextRecipientEnum.Passenger ||
    statusUpdateTextTo === TextRecipientEnum.Both;

  const isTripStatusRecipientBookingContact =
    statusUpdateTextTo === TextRecipientEnum.BookingContact ||
    statusUpdateTextTo === TextRecipientEnum.Both;

  const handleUpdateTripStatusRecipientAsPassenger = () => {
    setStatusUpdateTextTo(
      getRecipient(
        !isTripStatusRecipientPassenger,
        isTripStatusRecipientBookingContact
      )
    );
  };

  const handleUpdateTripStatusRecipientAsBookingContact = () => {
    setStatusUpdateTextTo(
      getRecipient(
        isTripStatusRecipientPassenger,
        !isTripStatusRecipientBookingContact
      )
    );
  };

  const handleUpdateReminderRecipientAsPassenger = () => {
    setReminderTextTo(
      getRecipient(
        !isReminderRecipientPassenger,
        isReminderRecipientBookingContact
      )
    );
  };

  const handleUpdateReminderRecipientAsBookingContact = () => {
    setReminderTextTo(
      getRecipient(
        isReminderRecipientPassenger,
        !isReminderRecipientBookingContact
      )
    );
  };

  const handleUpdateNotifyTripSettings = () => {
    updateTripTextRecipients({
      variables: {
        input: {
          tripId: trip.id,
          statusUpdateTextTo,
          reminderTextTo,
        },
      },
    });
  };

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      closeButtonText="Cancel"
      dialogTitle="SMS notifications for this trip"
      size="sm"
      acceptButtonText="Confirm"
      acceptDisabled={isLoading}
      onAccept={handleUpdateNotifyTripSettings}
      subHeaderComponent={
        <Box>
          <Typography variant="body1" color={grayDark}>
            Override default recipients for this trip
          </Typography>
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" flexWrap="wrap">
        <Box display="flex" flexDirection="column" my={2} gap={1}>
          <Typography variant="h4">Trip Reminders</Typography>
          <Typography variant="overline" fontWeight={500} my={1}>
            RECIPIENTS
          </Typography>

          <LabeledSwitchInput
            name="setReminderUpdateRecipientsToBePassenger"
            checked={isReminderRecipientPassenger}
            onChange={handleUpdateReminderRecipientAsPassenger}
            text="Passenger"
          />

          <LabeledSwitchInput
            name="setReminderUpdateRecipientsToBeBookingContact"
            checked={isReminderRecipientBookingContact}
            onChange={handleUpdateReminderRecipientAsBookingContact}
            text="Booking Contact"
          />
        </Box>

        <Box display="flex" flexDirection="column" my={2} gap={1}>
          <Typography variant="h4">Trip Status Updates</Typography>
          {isTriggersDisabled && (
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              p={2}
              bgcolor={grayLight}
              role="alert"
            >
              <InfoIcon />
              <Typography
                variant="caption"
                fontWeight={400}
                fontSize={13}
                color={granite}
              >
                To edit recipients enable at least one trigger from general
                settings
              </Typography>
            </Box>
          )}
          <Typography variant="overline" fontWeight={500} my={1}>
            RECIPIENTS
          </Typography>

          <LabeledSwitchInput
            name="setTripStatusUpdateRecipientsToBePassenger"
            checked={isTripStatusRecipientPassenger}
            onChange={handleUpdateTripStatusRecipientAsPassenger}
            text="Passenger"
            disabled={isTriggersDisabled}
          />

          <LabeledSwitchInput
            name="setTripStatusUpdateRecipientsToBeBookingContact"
            checked={isTripStatusRecipientBookingContact}
            onChange={handleUpdateTripStatusRecipientAsBookingContact}
            text="Booking Contact"
            disabled={isTriggersDisabled}
          />
        </Box>
      </Box>
    </MoovsDialog>
  );
}

export default OverrideSMSRecipientsForTripDialog;
