import React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import withStyles from "@mui/styles/withStyles";

import { alabaster } from "../../../../design-system/colors";
import IndividualCell from "./IndividualCell";

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 12,
    fontStyle: "normal",
  },
  root: {
    borderBottom: "none",
    padding: "8px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: alabaster,
    },
  },
}))(TableRow);

type SingleColumnTableGeneratorType = {
  columnsData: {
    columnText: string;
    percentage: number;
    amount: number;
  }[];
  tableHeaderText: string;
};

function SingleColumnTableGenerator(props: SingleColumnTableGeneratorType) {
  const { columnsData, tableHeaderText } = props;

  return (
    <TableContainer>
      <Table aria-label="breakdown table" size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell
              style={{ paddingTop: "0px", paddingBottom: "20px" }}
            >
              <Typography variant="h4">{tableHeaderText}</Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {columnsData.map((row) => (
            <StyledTableRow key={row.columnText}>
              <IndividualCell cell={row} />
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SingleColumnTableGenerator;
