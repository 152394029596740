import { useCallback } from "react";
import { useSegment } from "./useSegment";
import { useCurrentUser } from "./useCurrentUser";
import { fromGlobalId } from "globals/utils/helpers";

function useAnalytics() {
  // hooks
  const analytics = useSegment();
  const currentUser = useCurrentUser();
  const isDevelopment = process.env.NODE_ENV === "development";

  // handlers
  const track = useCallback(
    (name: string, options?: any) => {
      const id = currentUser?.id;
      const operatorId = currentUser?.operatorId;

      // show events on dev
      if (isDevelopment) console.log("track: ", name, options);

      analytics?.track(name, {
        ...options,
        ...(id && { user_id: fromGlobalId(id).id }),
        ...(operatorId && { affiliate_id: fromGlobalId(operatorId).id }),
      });
    },
    [analytics, currentUser?.id, currentUser?.operatorId, isDevelopment]
  );

  return { track };
}

export { useAnalytics };
