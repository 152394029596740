import React, { useMemo, useState } from "react";
import filter from "lodash/filter";

import { Menu, PopoverOrigin } from "@mui/material";

import { primaryMainColor } from "../../theme";
import EllipsisButton from "./components/EllipsisButton";
import EllipsisMenuList, {
  EllipsisMenuOption,
} from "./components/EllipsisMenuList";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

type MoovsEllipsisMenuProps = {
  options: EllipsisMenuOption[];
  size?: "small" | "large";
  withMoreText?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  buttonSxStyles?: any;
  ellipsisColor?: string;
  icon?: ReactJSXElement | null;
  variant?: "default" | "withMoreText" | "actionButton";
};

function MoovsEllipsisMenu(props: MoovsEllipsisMenuProps) {
  const {
    ellipsisColor = primaryMainColor,
    size = "large",
    variant = "default",
    options,
    anchorOrigin,
    transformOrigin,
    icon,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  // event handlers
  const handleEllipsisMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEllipsisMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (
    onClick: (e?: React.MouseEvent<HTMLElement>) => void
  ) => {
    handleEllipsisMenuClose();
    onClick();
  };

  // derived state
  const open = !!anchorEl;

  // filter out dividers and labels
  const nonHiddenOptions = useMemo(
    () =>
      filter(
        options,
        (option) => typeof option === "string" || !option?.hideOption
      ),
    [options]
  );

  // if only dividers and labels are present (due to other options being hidden), we do not render the menu button at all.
  const shouldRenderMenu = useMemo(
    () => nonHiddenOptions.some((option) => typeof option === "object"),
    [nonHiddenOptions]
  );

  if (!shouldRenderMenu) return null;

  return (
    <>
      <EllipsisButton
        onClick={handleEllipsisMenuClick}
        size={size}
        variant={variant}
        ellipsisColor={ellipsisColor}
        icon={icon}
      />
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleEllipsisMenuClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        tabIndex={null}
      >
        <EllipsisMenuList
          options={nonHiddenOptions}
          onOptionClick={handleOptionClick}
        />
      </Menu>
    </>
  );
}

export default MoovsEllipsisMenu;
