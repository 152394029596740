/**
 * @file graphql/transaction.ts
 * queries/mutations for Transaction.
 *
 */

import { gql } from "@apollo/client";

export const CREATE_TRANSACTION_MUTATION = gql`
  mutation CreateTransaction($input: CreateTransactionInput!) {
    createTransaction(input: $input) {
      transaction {
        id
      }
    }
  }
`;

export const UPDATE_TRANSACTION_MUTATION = gql`
  mutation UpdateTransaction($input: UpdateTransactionInput!) {
    updateTransaction(input: $input) {
      transaction {
        id
      }
    }
  }
`;

export const REMOVE_TRANSACTION_MUTATION = gql`
  mutation RemoveTransaction($input: RemoveTransactionInput!) {
    removeTransaction(input: $input) {
      transaction {
        id
      }
    }
  }
`;
