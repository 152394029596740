import React from "react";

import { Chip, ChipProps } from "@mui/material";

type TextTransform =
  | "-moz-initial"
  | "inherit"
  | "initial"
  | "revert"
  | "unset"
  | "none"
  | "full-width"
  | "capitalize"
  | "full-size-kana"
  | "lowercase"
  | "uppercase";

type WhiteSpace =
  | "inherit"
  | "initial"
  | "revert"
  | "revert-layer"
  | "unset"
  | "normal"
  | "nowrap"
  | "pre"
  | "pre-wrap"
  | "pre-line"
  | "breakspaces";

export type TinyChipProps = {
  label: string | JSX.Element;
  color?: string;
  backgroundColor?: string;
  textTransform?: TextTransform;
  whiteSpace?: WhiteSpace;
  inputProps?: ChipProps;
};

function TinyChip(props: TinyChipProps) {
  const {
    label,
    color,
    backgroundColor,
    inputProps,
    textTransform,
    whiteSpace,
  } = props;

  const styles = {
    root: {
      height: "17px",
      borderRadius: "20px",
      fontSize: "10px",
      fontWeight: 500,
      ...(color && { color }),
      ...(backgroundColor && { backgroundColor }),

      "& span": {
        ...(textTransform && { textTransform }),
      },

      "& .MuiChip-label": {
        wordWrap: "break-word",
        whiteSpace: whiteSpace || "normal",
        textOverflow: "clip",
        textAlign: "center",
        paddingLeft: "6px",
        paddingRight: "6px",
      },
    },
  };

  return <Chip sx={styles.root} size="small" label={label} {...inputProps} />;
}

export default TinyChip;
