import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
  useState,
} from "react";
import { useMutation } from "@apollo/client";
import { useDebounce } from "use-debounce";
import isEqual from "lodash/isEqual";

import { Box } from "@mui/material";

import { PromoCode } from "types";
import useEditPromoCodeForm from "./EditPromoCodeForm/useEditPromoCodeForm";
import { UPDATE_PROMO_CODE_MUTATION } from "globals/graphql";
import { finalizeEditPromoCodeInput } from "./EditPromoCodeForm/utils/finalizeEditPromoCodeInput";
import { useSnackbar } from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type PromoCodeOverviewSectionProps = {
  promoCode: PromoCode;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function PromoCodeOverviewSection(props: PromoCodeOverviewSectionProps) {
  const { promoCode, setSaveIndicatorState } = props;

  const promoCodeId = promoCode.id;

  // hooks
  const snackbar = useSnackbar();
  const { editPromoCodeForm, handleEditPromoCodeSubmit, watch } =
    useEditPromoCodeForm(promoCode);

  // state
  const [isInitialRender, setIsInitialRender] = useState(true);

  const [watchedFormValues] = useDebounce(watch(), 500, {
    // Check for deep equality since watch() will produce a new unique object each time
    equalityFn: (prev, next) => {
      return isEqual(prev, next);
    },
  });

  // mutation
  const [updatePromoCode] = useMutation(UPDATE_PROMO_CODE_MUTATION, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      const errorMessage =
        getErrorMessage(error) || "Error updating promo code";

      snackbar.error(errorMessage);
    },
  });

  // event handlers
  const handleAfterSubmit = useCallback(() => {
    const formInput = watch();

    setSaveIndicatorState("loading");

    const { finalData } = finalizeEditPromoCodeInput({
      input: formInput,
      promoCodeId,
    });

    // Reset the values not explicitly set so that
    // we don't incorrectly merge with the existing values
    updatePromoCode({
      variables: {
        input: {
          promoCodePercent: null,
          promoCodeAmt: null,
          totalLimit: null,
          bookingContactLimit: null,
          bookingStartDate: null,
          bookingEndDate: null,
          tripStartDate: null,
          tripEndDate: null,
          ...finalData,
        },
      },
    });
  }, [watch, promoCodeId, setSaveIndicatorState, updatePromoCode]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }
    if (!promoCode) {
      return;
    }

    handleEditPromoCodeSubmit(handleAfterSubmit)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleEditPromoCodeSubmit, handleAfterSubmit, watchedFormValues]);

  return <Box mt={3}>{editPromoCodeForm}</Box>;
}

export default PromoCodeOverviewSection;
