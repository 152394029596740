import React from "react";

import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";

import { black, grayMedium, alabaster } from "../../../colors";
import { CalendarIcon } from "../../../icons";

const inputSx = {
  fontSize: "14px",
  backgroundColor: "white",
  "& path": {
    stroke: "transparent",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid transparent",
  },
  ":hover": {
    "& path": {
      stroke: grayMedium,
      transition: "all 0.3s",
      transitionDelay: "0s",
    },
  },
  ":hover:not(.Mui-error)": {
    "MuiOutlinedInput-notchedOutline": {
      transition: "all 0.3s",
      transitionDelay: "0s",
      borderColor: grayMedium,
    },
  },
  "&.Mui-focused:not(.Mui-error)": {
    ".MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: black,
    },
  },
  "&.Mui-focused": {
    backgroundColor: alabaster + "!important",
    "& path": {
      stroke: black + "!important",
    },
  },
};

type InlineDatePickerProps = {
  value: any;
  onChange: any;
  errorText?: any;
  placeholder?: any;
  required?: boolean;
  name?: string;
  disablePast?: boolean;
  label?: string;
  error?: boolean;
};

function InlineDatePicker(props: InlineDatePickerProps) {
  const {
    value,
    onChange,
    errorText,
    placeholder,
    required,
    name,
    disablePast,
    label,
  } = props;

  const error = props.error || (required && !value);

  return (
    <DatePicker
      label={label}
      disablePast={disablePast}
      value={value}
      onChange={() => {}}
      onAccept={onChange}
      components={{
        OpenPickerIcon: CalendarIcon,
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          multiline
          variant="outlined"
          placeholder={placeholder}
          name={name}
          helperText={error ? errorText : ""}
          error={error}
          sx={[{ cursor: "pointer" }, inputSx]}
        />
      )}
    />
  );
}

export { InlineDatePicker };
