import React from "react";
import first from "lodash/first";

import { Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import RoundChip from "design-system/components/chips/RoundChip";
import { affiliateGridStatusLabelColors } from "design-system/colors";
import { FarmRelationshipEnum, Route } from "types";

function FarmTypeColumn(props: GridRenderCellParams<string>) {
  const { row } = props;

  const farmType =
    first(row.routes as unknown as Route[]).farmRelationship ===
    FarmRelationshipEnum.Farmee
      ? "FARM-IN"
      : "FARM-OUT";

  return (
    <Box display="flex" justifyContent="center">
      <RoundChip {...affiliateGridStatusLabelColors[farmType]} />
    </Box>
  );
}

export default FarmTypeColumn;
