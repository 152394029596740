import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DeepPartial } from "ts-essentials";
import isEmpty from "lodash/isEmpty";
import { useDebouncedCallback } from "use-debounce";

import { Box, Typography, Divider } from "@mui/material";

import { Contact, ShuttleRoute, TripCategory } from "types";
import ContactAutoComplete from "../../autocompletes/ContactAutoComplete";
import ProfileInfoCard from "../../globals/ProfileInfoCard";
import ShuttleRouteInfoCard from "../../globals/ShuttleRouteInfoCard";
import { TrashIcon } from "../../../design-system/icons";
import AddContactToOrderDialog from "components/contacts/people/AddContactToOrderDialog";
import CreateAndAddShuttleRouteToRequestDialog from "components/requests/CreateAndAddShuttleRouteToRequestDialog";
import CompanyAutoComplete from "components/autocompletes/CompanyAutoComplete";
import ShuttleRouteAutoComplete from "components/autocompletes/ShuttleRouteAutoComplete";
import ShuttleTypeButtonsBlock from "../ShuttleTypeButtonsBlock";
import { useLazyQuery } from "@apollo/client";
import { LOAD_CONTACTS_QUERY } from "globals/graphql";
import { useScreenSize } from "globals/hooks";

type RequestErrorsType = {
  type: string;
  comments: string;
  stops: [
    {
      location: string;
      dateTime: string;
      stopIndex: number;
    },
    {
      location: string;
      dateTime: string;
      stopIndex: number;
    }
  ];
  driverGratuity: string;
  vehicle: string;
  bookingContact: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  shuttleRoute: string;
  company?: string;
};

type ShuttleOrderDetailsCreateBlockProps = {
  bookingContact: DeepPartial<Contact>;
  setBookingContact: Dispatch<SetStateAction<Contact>>;
  shuttleRoute: DeepPartial<ShuttleRoute>;
  setShuttleRoute: Dispatch<SetStateAction<ShuttleRoute>>;
  requestErrors: RequestErrorsType;
  setRequestErrors: Dispatch<SetStateAction<RequestErrorsType>>;
  onClearBookingContact: () => void;
  onClearShuttleRoute: () => void;
  setHasPaymentMethod?: Dispatch<SetStateAction<boolean>>;
  companyId: string;
  tripType: string;
  onTripTypeChange: (e: any, value: TripCategory) => void;
  onCompanyChange: (companyId: string) => void;
};

function ShuttleOrderDetailsCreateBlock(
  props: ShuttleOrderDetailsCreateBlockProps
) {
  const {
    bookingContact,
    setBookingContact,
    shuttleRoute,
    setShuttleRoute,
    requestErrors,
    setRequestErrors,
    onClearBookingContact,
    onClearShuttleRoute,
    setHasPaymentMethod,
    companyId,
    tripType,
    onTripTypeChange,
    onCompanyChange,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // state
  const [contactCarryOverSearch, setContactCarryOverSearch] = useState("");
  const [shuttleRouteCarryOverSearch, setShuttleRouteCarryOverSearch] =
    useState("");
  const [createNewContactDialogOpen, setCreateNewContactDialogOpen] =
    useState(false);
  const [createNewShuttleRouteDialogOpen, setCreateNewShuttleRouteDialogOpen] =
    useState(false);
  const [suggestedContacts, setSuggestedContacts] = useState([]);

  // queries
  const [loadContactsForCompany] = useLazyQuery(LOAD_CONTACTS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const contactsList = data?.loadContacts.edges.map(({ node }) => node);
      setSuggestedContacts(contactsList || []);
    },
  });

  // event handlers
  const handleContactSelect = (contact: Contact) => {
    setBookingContact(contact);
    if (!!setHasPaymentMethod) {
      setHasPaymentMethod(!isEmpty(contact.paymentMethods));
    }
    setRequestErrors({
      ...requestErrors,
      email: "",
    });
  };

  // Resets contact in case user has already selected a
  // contact and then decides to create new contact
  const handleCreateNewContactClick = () => {
    onClearBookingContact();
    setCreateNewContactDialogOpen(true);
  };

  const handleAddNewBookingContact = (contact: Contact) => {
    setBookingContact(contact);
    setHasPaymentMethod && setHasPaymentMethod(false);
  };

  // Resets shuttleRoute in case user has already selected a
  // shuttleRoute and then decides to create new shuttleRoute
  const handleCreateNewShuttleRouteClick = () => {
    onClearShuttleRoute();
    setCreateNewShuttleRouteDialogOpen(true);
  };

  const handleShuttleRouteCreated = (shuttleRoute: ShuttleRoute) => {
    setShuttleRoute(shuttleRoute);
  };

  const handleShuttleRouteSelect = (shuttleRoute: ShuttleRoute) => {
    setShuttleRoute(shuttleRoute);
    setRequestErrors({
      ...requestErrors,
      shuttleRoute: "",
    });
  };

  const debouncedGetContacts = useDebouncedCallback((companyId: string) => {
    loadContactsForCompany({
      variables: {
        limit: 20,
        companyId,
      },
    });
  }, 300);

  // Pre-populate suggested contacts and shuttle routes when company is updated or field is cleared
  useEffect(() => {
    if (companyId) {
      debouncedGetContacts(companyId);
    } else {
      onClearBookingContact();
      onClearShuttleRoute();
    }
  }, [
    companyId,
    debouncedGetContacts,
    onClearBookingContact,
    onClearShuttleRoute,
  ]);

  return (
    <>
      <Box mt={4} mb={1.5}>
        <Typography variant="h5" style={{ fontWeight: 700 }}>
          Order Details
        </Typography>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      <Box mb={1}>
        <CompanyAutoComplete
          onCompanyChange={onCompanyChange}
          error={!!requestErrors.company}
          helperText={requestErrors.company}
        />
      </Box>

      {companyId && !bookingContact && (
        <Box mb={1}>
          <ContactAutoComplete
            setCarryOverSearch={setContactCarryOverSearch}
            onCreateNewContactClick={handleCreateNewContactClick}
            onChange={handleContactSelect}
            error={!!requestErrors.bookingContact}
            helperText={requestErrors.bookingContact}
            label="Search for booking contact"
            companyId={companyId}
            suggestedContacts={suggestedContacts}
          />
        </Box>
      )}
      {bookingContact && (
        <ProfileInfoCard
          headerText="Booking / Billing Contact"
          firstName={bookingContact.firstName}
          lastName={bookingContact.lastName}
          email={bookingContact.email}
          phone={bookingContact.mobilePhone}
          preferences={bookingContact.preferences}
          company={bookingContact?.company?.name}
          paymentMethods={bookingContact.paymentMethods}
          actionButtons={[
            {
              icon: <TrashIcon />,
              eventHandler: onClearBookingContact,
            },
          ]}
        />
      )}

      <Box mt={2}>
        <Typography variant="h5" mb={2}>
          Route Name
        </Typography>
        {shuttleRoute ? (
          <ShuttleRouteInfoCard
            name={shuttleRoute.name}
            actionButtonIcon={<TrashIcon />}
            onClickActionButton={onClearShuttleRoute}
          />
        ) : (
          <Box {...(!isMobileView && { width: "400px" })}>
            <ShuttleRouteAutoComplete
              companyId={companyId}
              onChange={handleShuttleRouteSelect}
              setCarryOverSearch={setShuttleRouteCarryOverSearch}
              onCreateNewShuttleRouteClick={handleCreateNewShuttleRouteClick}
              error={!!requestErrors.shuttleRoute}
              helperText={requestErrors.shuttleRoute}
              label="Enter Route name *"
            />
          </Box>
        )}
      </Box>

      <ShuttleTypeButtonsBlock onChange={onTripTypeChange} value={tripType} />

      <AddContactToOrderDialog
        open={createNewContactDialogOpen}
        onClose={() => setCreateNewContactDialogOpen(false)}
        onContactUpdate={handleAddNewBookingContact}
        carryOverSearch={contactCarryOverSearch}
        companyId={companyId}
      />
      <CreateAndAddShuttleRouteToRequestDialog
        open={createNewShuttleRouteDialogOpen}
        onClose={() => setCreateNewShuttleRouteDialogOpen(false)}
        onShuttleRouteCreated={handleShuttleRouteCreated}
        companyId={companyId}
        carryOverSearch={shuttleRouteCarryOverSearch}
      />
    </>
  );
}

export default ShuttleOrderDetailsCreateBlock;
