import clipboardCopy from "clipboard-copy";
import { useLazyQuery } from "@apollo/client";

import { MoovsTransaction, PaymentMethodTransaction } from "types";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { LOAD_STRIPE_PAYMENT_RECEIPT_LINK } from "globals/graphql";

type useReceiptActionProps = {
  transaction: PaymentMethodTransaction | MoovsTransaction;
};

export function useReceiptAction(props: useReceiptActionProps) {
  const { transaction } = props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const [getReceiptLink] = useLazyQuery(LOAD_STRIPE_PAYMENT_RECEIPT_LINK);

  // derived state
  const { id: paymentId } =
    transaction.__typename === "Payment" ? transaction : transaction.payment;

  const handleViewReceipt = async () => {
    const {
      data: { stripePaymentReceiptLink: receiptUrl },
    } = await getReceiptLink({
      variables: {
        paymentId,
      },
    });

    track("payments_receiptViewed");

    window.open(receiptUrl, "_blank").focus();
  };

  const handleCopyReceipt = async () => {
    try {
      const {
        data: { stripePaymentReceiptLink: receiptUrl },
      } = await getReceiptLink({
        variables: {
          paymentId,
        },
      });

      await clipboardCopy(receiptUrl);
      track("payments_receiptCopied");
      snackbar.success("Successfully copied receipt url!");
    } catch (error) {
      snackbar.error("Error copying receipt url.");
    }
  };

  return {
    handleViewReceipt,
    handleCopyReceipt,
  };
}
