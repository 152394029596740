import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import { Box, CircularProgress } from "@mui/material";

import { useAnalytics, useScreenSize, useSnackbar } from "globals/hooks";
import UpdateDrawer from "../../../globals/UpdateDrawer";
import { FarmAffiliateInfoBlock } from "./components";
import { Driver, FarmAffiliateVariantEnum, FarmAffiliateVehicle } from "types";
import { fromGlobalId } from "globals/utils/helpers";
import {
  LINK_EXTERNAL_OPERATOR_DRIVER_MUTATION,
  LINK_EXTERNAL_OPERATOR_VEHICLE_MUTATION,
  LOAD_EXTERNAL_OPERATOR_QUERY,
  UNLINK_EXTERNAL_OPERATOR_DRIVER_MUTATION,
  UNLINK_EXTERNAL_OPERATOR_VEHICLE_MUTATION,
  LOAD_GRIDDNET_OPERATOR_QUERY,
  LOAD_MOOVS_NETWORK_OPERATOR_QUERY,
  LOAD_VEHICLES_QUERY,
  LOAD_MOOVS_AI_OPERATOR_QUERY,
} from "globals/graphql";
import GQLQueryStatusIndicator from "../../../GQLQueryStatusIndicator";
import FarmAffiliateDriverSection from "../FarmAffiliateDriverSection";
import FarmAffiliateVehicleSection from "../FarmAffiliateVehicleSection";
import FarmAffiliateIcon, {
  getFarmAffiliateIconVariant,
} from "../../farmAffiliates/FarmAffiliateIcon";
import PaymentMethodList from "components/contacts/paymentMethod/PaymentMethodList";
import ViewCardPaymentsModal from "components/contacts/paymentMethod/ViewCardPaymentsModal";
import TripDrawerTabs from "components/contacts/people/UpdateContactDrawer/components/TripDrawerTabs";
import { AffiliateTripsGrid } from "./components";
import { grayDark } from "design-system/colors";
import { DueIcon } from "design-system/icons";
import EmailActivityLogDialog from "components/globals/ActivityLog/EmailActivityLogDialog";

function UpdateFarmAffiliateDrawer() {
  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const { affiliateId } = useParams<{ affiliateId: string }>();
  const { track } = useAnalytics();
  const { isMobileView } = useScreenSize();

  const farmAffiliateVariant = fromGlobalId(affiliateId)
    .type as FarmAffiliateVariantEnum;

  const {
    ExternalOperator,
    MoovsNetworkOperator,
    GriddnetOperator,
    MoovsAiOperator,
  } = FarmAffiliateVariantEnum;

  const LOAD_FARM_AFFILIATE_QUERY = useMemo(() => {
    switch (farmAffiliateVariant) {
      case ExternalOperator:
        return LOAD_EXTERNAL_OPERATOR_QUERY;
      case MoovsNetworkOperator:
        return LOAD_MOOVS_NETWORK_OPERATOR_QUERY;
      case GriddnetOperator:
        return LOAD_GRIDDNET_OPERATOR_QUERY;
      case MoovsAiOperator:
        return LOAD_MOOVS_AI_OPERATOR_QUERY;
    }
  }, [
    farmAffiliateVariant,
    ExternalOperator,
    MoovsNetworkOperator,
    GriddnetOperator,
    MoovsAiOperator,
  ]);

  // state
  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "saved" | "loading" | "error"
  >("default");
  const [tabMode, setTabMode] = useState("Profile");
  const [activityLogOpen, setActivityLogOpen] = useState(false);

  const paymentMethodId = useMemo(
    () => new URLSearchParams(history.location.search).get("card"),
    [history.location]
  );

  // queries
  const {
    data: farmAffiliateData,
    loading: farmAffiliateLoading,
    refetch: farmAffiliateRefetch,
    error: farmAffiliateError,
  } = useQuery(LOAD_FARM_AFFILIATE_QUERY, {
    variables: {
      id: affiliateId,
    },
    skip: !affiliateId,
  });

  const farmAffiliate = farmAffiliateData?.node;
  const farmAffiliateDrivers = farmAffiliate?.drivers || [];
  const farmAffiliateVehicles = farmAffiliate?.vehicles || [];

  // mutations
  // driver mutations
  const [linkExternalOperatorDriver] = useMutation(
    LINK_EXTERNAL_OPERATOR_DRIVER_MUTATION,
    {
      refetchQueries: [
        {
          query: LOAD_VEHICLES_QUERY,
          variables: { includeExternalOperators: true },
        },
      ],
      onCompleted(data) {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        snackbar.error("Could not link the affiliate's driver.");
      },
    }
  );
  const [unlinkExternalOperatorDriver] = useMutation(
    UNLINK_EXTERNAL_OPERATOR_DRIVER_MUTATION,
    {
      refetchQueries: [
        {
          query: LOAD_VEHICLES_QUERY,
          variables: { includeExternalOperators: true },
        },
      ],
      onCompleted(data) {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        snackbar.error("Could not remove the affiliate's driver.");
      },
    }
  );

  // vehicle mutations
  const [linkExternalOperatorVehicle] = useMutation(
    LINK_EXTERNAL_OPERATOR_VEHICLE_MUTATION,
    {
      refetchQueries: [
        {
          query: LOAD_VEHICLES_QUERY,
          variables: { includeExternalOperators: true },
        },
      ],
      onCompleted(data) {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        snackbar.error("Could not link the affiliate's vehicle.");
      },
    }
  );
  const [unlinkExternalOperatorVehicle] = useMutation(
    UNLINK_EXTERNAL_OPERATOR_VEHICLE_MUTATION,
    {
      refetchQueries: [
        {
          query: LOAD_VEHICLES_QUERY,
          variables: { includeExternalOperators: true },
        },
      ],
      onCompleted(data) {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        snackbar.error("Could not remove the affiliate's vehicle.");
      },
    }
  );

  // event handlers
  // driver event handlers
  const handleLinkExternalOperatorDriver = (driver: Driver) => {
    setSaveIndicatorState("loading");

    const driverId = driver.id;
    linkExternalOperatorDriver({
      variables: {
        input: {
          externalOperatorId: farmAffiliate.id,
          driverId: driverId,
        },
      },
    });
  };

  const handleUnlinkeExternalOperatorDriver = (driverId: string) => {
    setSaveIndicatorState("loading");

    unlinkExternalOperatorDriver({
      variables: {
        input: {
          driverId: driverId,
        },
      },
    });
  };

  const handleTabChange = (tabMode: string) => {
    if (tabMode === "Trips") {
      track("affiliate_tripsTabOpened");
    }
    setTabMode(tabMode);
  };

  // vehicle event handlers
  const handleLinkExternalOperatorVehicle = (
    vehicles: FarmAffiliateVehicle[]
  ) => {
    setSaveIndicatorState("loading");

    const vehicleId = vehicles[0].id;
    linkExternalOperatorVehicle({
      variables: {
        input: {
          externalOperatorId: farmAffiliate.id,
          vehicleId: vehicleId,
        },
      },
    });
  };
  const handleUnlinkExternalOperatorVehicle = (vehicleId: string) => {
    setSaveIndicatorState("loading");

    unlinkExternalOperatorVehicle({
      variables: {
        input: {
          vehicleId: vehicleId,
        },
      },
    });
  };

  const iconVariant = getFarmAffiliateIconVariant({
    farmAffiliate,
    farmAffiliateVariant,
  });

  const farmAffiliateIcon = FarmAffiliateIcon({
    variant: iconVariant,
    size: "extra-large",
  });

  return (
    <>
      <UpdateDrawer
        onClose={() => {
          history.push(`/affiliates`);
        }}
        saveIndicatorState={saveIndicatorState}
        farmAffiliateProps={{ icon: farmAffiliateIcon }}
        subHeaderContent={
          <TripDrawerTabs value={tabMode} setValue={handleTabChange} />
        }
        ellipsisMenuOptions={[
          {
            text: "View Activity Log",
            icon: <DueIcon color={grayDark} size="small" />,
            onClick: () => setActivityLogOpen(true),
          },
        ]}
      >
        {farmAffiliateLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={40} thickness={2} />
          </Box>
        )}
        {farmAffiliateError && !farmAffiliateLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <GQLQueryStatusIndicator
              name="Affiliate"
              data={farmAffiliateData}
              error={farmAffiliateError}
              refetch={farmAffiliateRefetch}
            />
          </Box>
        )}
        {farmAffiliate && (
          <>
            {tabMode === "Profile" ? (
              <Box>
                <Box mt={3} mb={2}>
                  <FarmAffiliateInfoBlock
                    farmAffiliate={farmAffiliate}
                    farmAffiliateVariant={farmAffiliateVariant}
                    setSaveIndicatorState={setSaveIndicatorState}
                  />
                </Box>

                {/* Driver Section */}
                {farmAffiliateVariant === ExternalOperator && (
                  <Box mb={4}>
                    <FarmAffiliateDriverSection
                      drivers={farmAffiliateDrivers}
                      onDriverAdd={handleLinkExternalOperatorDriver}
                      onDriverRemove={handleUnlinkeExternalOperatorDriver}
                      canSelectMultipleDrivers
                    />
                  </Box>
                )}

                {/* Vehicle Section */}
                {farmAffiliateVariant !== MoovsAiOperator && (
                  <Box mb={4}>
                    <FarmAffiliateVehicleSection
                      canAddOrRemoveVehicles={
                        farmAffiliateVariant === ExternalOperator
                      }
                      vehicles={farmAffiliateVehicles}
                      onVehicleAdd={handleLinkExternalOperatorVehicle}
                      onVehicleRemove={handleUnlinkExternalOperatorVehicle}
                    />
                  </Box>
                )}

                {/* Credit Card Section */}
                {farmAffiliateVariant !== ExternalOperator && (
                  <Box mb={4}>
                    <PaymentMethodList
                      setSaveIndicatorState={setSaveIndicatorState}
                      paymentMethods={farmAffiliate.paymentMethods}
                      payer={{
                        payerType: "affiliate",
                        payerId: affiliateId,
                        farmAffiliateVariant,
                        payerPhone: farmAffiliate.operatorPhone,
                      }}
                    />
                  </Box>
                )}
              </Box>
            ) : (
              <AffiliateTripsGrid
                affiliateId={farmAffiliate.id}
                farmAffiliateVariant={farmAffiliateVariant}
                {...(isMobileView
                  ? { sx: { mx: -2 } }
                  : {
                      sx: {
                        mx: -4,
                        "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
                          {
                            display: "none",
                          },
                      },
                    })}
              />
            )}
          </>
        )}
      </UpdateDrawer>

      <EmailActivityLogDialog
        open={activityLogOpen}
        onClose={() => setActivityLogOpen(false)}
        email={farmAffiliate?.operatorEmail}
        source="affiliate"
      />

      <ViewCardPaymentsModal paymentMethodId={paymentMethodId} />
    </>
  );
}

export default UpdateFarmAffiliateDrawer;
