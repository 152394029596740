import React from "react";
import { useQuery } from "@apollo/client";

import { Box, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

import MoovsSearchableCheckboxDropdown from "components/MoovsSearchableCheckboxDropdown";
import { LOAD_OPERATOR_DRIVERS_NAME_AND_ID_QUERY } from "globals/graphql";
import { FilterProps } from "../FilterDispatchTripsDrawer";

function DriverFilter(props: FilterProps) {
  const { disabledSelect } = props;

  // hooks
  const { watch, setValue } = useFormContext();

  // queries
  const { data } = useQuery(LOAD_OPERATOR_DRIVERS_NAME_AND_ID_QUERY);

  const driverOptions =
    data?.drivers.map((driver) => ({
      name: `${driver.firstName} ${driver.lastName}`,
      id: driver.id,
    })) || [];

  return (
    <Box mb={2}>
      <Typography variant="h3" mb={2}>
        Driver
      </Typography>

      <MoovsSearchableCheckboxDropdown
        fieldName="Driver"
        options={driverOptions}
        selection={watch("driverSelection")}
        setSelection={(newValue) => setValue("driverSelection", newValue)}
        disabled={disabledSelect}
        tooltipText="Upgrade plan to Pro in order to use filtering feature"
      />
    </Box>
  );
}

export default DriverFilter;
