import filter from "lodash/filter";

type GenerateOptionalPricingFieldsParams = {
  isForDropdownMenu?: boolean;
  childSeatFieldNameOptions?: string[];
};

type OptionalPricingField = {
  label: string;
  fieldName: string;
  percentFieldName?: string;
  additionalFieldProps?: any; // have to use any type, getting type errors when passing in as props
  quantityFieldProps?: any;
};

const OPTIONAL_PRICING_FIELDS: OptionalPricingField[] = [
  {
    label: "Gratuity",
    fieldName: "driverGratuityAmt",
    percentFieldName: "driverGratuityPercent",
  },
  {
    label: "Tax",
    fieldName: "taxAmt",
    percentFieldName: "taxPercent",
  },
  {
    label: "Discount",
    fieldName: "promoDiscountAmt",
    percentFieldName: "promoDiscountPercent",
  },
  {
    label: "Tolls",
    fieldName: "tollsAmt",
  },
  {
    label: "Meet & Greet",
    fieldName: "meetGreetAmt",
  },
];

const OTHER_OPTIONAL_PRICING_FIELDS: OptionalPricingField[] = [
  {
    label: "Other",
    fieldName: "otherAmt",
    percentFieldName: "otherPercent",
    additionalFieldProps: {
      fieldName: "otherName",
      placeholder: "Add Pricing Name",
      mobilePlaceholder: "Pricing Name",
    },
  },
  {
    label: "Other 2",
    fieldName: "other2Amt",
    percentFieldName: "other2Percent",
    additionalFieldProps: {
      fieldName: "other2Name",
      placeholder: "Add Pricing Name",
      mobilePlaceholder: "Pricing Name",
    },
  },
  {
    label: "Other 3",
    fieldName: "other3Amt",
    percentFieldName: "other3Percent",
    additionalFieldProps: {
      fieldName: "other3Name",
      placeholder: "Add Pricing Name",
      mobilePlaceholder: "Pricing Name",
    },
  },
];

const OPTIONAL_PROMO_CODE_PRICING_FIELD: OptionalPricingField = {
  label: "Promo Code",
  fieldName: "promoCodeAmt",
  percentFieldName: "promoCodePercent",
  additionalFieldProps: {
    fieldName: "promoCodeName",
    disabled: true,
  },
};

const OPTIONAL_CHILD_SEAT_PRICING_FIELDS: OptionalPricingField[] = [
  {
    label: "Rear-facing Seat",
    fieldName: "rearFacingSeatAmt",
    quantityFieldProps: {
      fieldName: "rearFacingSeatQuantity",
    },
  },
  {
    label: "Forward-facing Seat",
    fieldName: "forwardFacingSeatAmt",
    quantityFieldProps: {
      fieldName: "forwardFacingSeatQuantity",
    },
  },
  {
    label: "Booster Seat",
    fieldName: "boosterSeatAmt",
    quantityFieldProps: {
      fieldName: "boosterSeatQuantity",
    },
  },
];

export const ALL_OPTIONAL_PRICING_FIELDS: OptionalPricingField[] = [
  ...OPTIONAL_PRICING_FIELDS,
  ...OPTIONAL_CHILD_SEAT_PRICING_FIELDS,
  ...OTHER_OPTIONAL_PRICING_FIELDS,
  OPTIONAL_PROMO_CODE_PRICING_FIELD,
];

export const generateOptionalPricingFields = (
  params: GenerateOptionalPricingFieldsParams
) => {
  const { isForDropdownMenu = false, childSeatFieldNameOptions = [] } = params;

  const optionalPricingFields: OptionalPricingField[] = [
    ...OPTIONAL_PRICING_FIELDS,
    // filter out optional child seat pricing fields if not in childSeatFieldNameOptions
    ...(childSeatFieldNameOptions &&
      filter(OPTIONAL_CHILD_SEAT_PRICING_FIELDS, (field) =>
        childSeatFieldNameOptions.includes(field.fieldName)
      )),
    ...OTHER_OPTIONAL_PRICING_FIELDS,
  ];

  if (!isForDropdownMenu)
    optionalPricingFields.push(OPTIONAL_PROMO_CODE_PRICING_FIELD);

  return optionalPricingFields;
};
