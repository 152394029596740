import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import lowerCase from "lodash/lowerCase";

import { Request, RequestStage, Trip, TripCategory } from "types";
import { AddReturnIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import { useAnalytics } from "globals/hooks";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";

type UseAddReturnTripClickProps = {
  request: Request;
  trip: Trip;
  analyticsName?: string;
  isFarmee?: boolean;
};

function useAddReturnTripClick(
  props: UseAddReturnTripClickProps
): EllipsisMenuOption {
  const { request, trip, analyticsName, isFarmee } = props;

  // hooks
  const history = useHistory();
  const location = useLocation();
  const { track } = useAnalytics();

  // derived state
  const isCancelledTrip = !!trip?.cancelledAt;
  const isRoundTrip = trip?.tripCategory === TripCategory.RoundTrip;
  const isHourly = trip?.tripCategory === TripCategory.Hourly;
  const isShuttle =
    trip?.tripCategory === TripCategory.ShuttlePickUp ||
    trip?.tripCategory === TripCategory.ShuttleReturn;
  const isUnconfirmedReservation = request?.stage === RequestStage.Unconfirmed;
  const addReturnTripDisabled =
    isCancelledTrip ||
    isRoundTrip ||
    isHourly ||
    isShuttle ||
    isUnconfirmedReservation;
  const tooltipTripTypeText = isHourly
    ? "an Hourly"
    : isRoundTrip
    ? "a Round"
    : isShuttle
    ? "a Shuttle"
    : "a Cancelled";

  const handleAddReturnTripClick = () => {
    if (analyticsName) track(analyticsName);

    history.push({
      pathname: `/${lowerCase(request.stage)}s/${request.id}/add-return-trip`,
      search: location.search,
      state: {
        request,
        trip,
      },
    });
  };

  return {
    onClick: handleAddReturnTripClick,
    text: "Add Return Trip",
    icon: <AddReturnIcon color={grayDark} size="small" />,
    disableOption: addReturnTripDisabled,
    hideOption: isFarmee,
    tooltip:
      addReturnTripDisabled && !isUnconfirmedReservation
        ? {
            titleText: `Cannot Add Return Trip to ${tooltipTripTypeText} Trip`,
          }
        : undefined,
  };
}

export { useAddReturnTripClick };
