import React from "react";

import { Box, Typography } from "@mui/material";

import { RouteStatusLog } from "types";
import { alabaster } from "design-system/colors";
import StatusTimelineItem from "./StatusTimelineItem";

type StatusTimelineProps = {
  routeStatusLogs: RouteStatusLog[];
  timezoneId?: string;
};

function StatusTimeline(props: StatusTimelineProps) {
  const { routeStatusLogs, timezoneId } = props;

  return (
    <Box width="100%">
      <Typography variant="h5">Status Timeline</Typography>
      <Box my={2}>
        {routeStatusLogs.length ? (
          routeStatusLogs.map(
            (routeStatusLog: RouteStatusLog, index: number) => (
              <StatusTimelineItem
                key={routeStatusLog.id}
                isLastItem={index === routeStatusLogs.length - 1}
                timezoneId={timezoneId}
                {...routeStatusLog}
              />
            )
          )
        ) : (
          <Box
            display="flex"
            flex="1"
            justifyContent="center"
            alignItems="center"
            height="100px"
            bgcolor={alabaster}
          >
            <Typography variant="body2">No activity yet</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default StatusTimeline;
