import React from "react";
import { Helmet } from "react-helmet";

import { Box, Typography } from "@mui/material";

import { granite, grayLight, white } from "design-system/colors";
import {
  useCurrentUser,
  useOperatorGettingStarted,
  useScreenSize,
} from "globals/hooks";
import {
  GettingStartedBanner,
  NeedHelpSection,
  QuickLinkSection,
  TaskList,
  TaskProgressBar,
} from "./components";
import { GettingStartedGoal } from "types";

function GettingStartedPage() {
  // hooks
  const { isMobileView } = useScreenSize();
  const user = useCurrentUser();

  const operatorGettingStarted = useOperatorGettingStarted();

  if (!operatorGettingStarted) return null;

  return (
    <>
      <Helmet>
        <title>Getting Started | Moovs</title>
      </Helmet>

      <Box justifyContent="center" width="100%">
        <Box
          position="sticky"
          top={isMobileView ? "56px" : "78px"}
          zIndex={10}
          width="100%"
          bgcolor={white}
          pb={1}
          borderBottom={`1px solid ${grayLight}`}
        >
          <Box maxWidth="980px" margin="auto" p={isMobileView ? 1 : 0}>
            {/* Intro */}
            <Box pt={6}>
              <Typography variant="h1" mb={1}>{`Welcome ${
                user?.firstName || ""
              }`}</Typography>
              <Typography variant="body1" color={granite}>
                Get started by completing these tasks.
              </Typography>
            </Box>

            {/* Banner */}
            <Box my={3}>
              {!operatorGettingStarted.bannerRemovedAt &&
                operatorGettingStarted.goal !==
                  GettingStartedGoal.ExploreOnMyOwn && (
                  <GettingStartedBanner goal={operatorGettingStarted.goal} />
                )}
            </Box>

            <Box display="flex">
              {/* Progress Bar + Tasks List */}
              <Box>
                <Box mb={3.5}>
                  <TaskProgressBar
                    tasksRemaining={operatorGettingStarted.tasksRemaining}
                  />
                </Box>
                <TaskList operatorGettingStarted={operatorGettingStarted} />
              </Box>

              {/* Need Help Section Desktop View */}
              {!isMobileView && (
                <Box
                  ml={4}
                  width="340px"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <NeedHelpSection />
                </Box>
              )}
            </Box>

            {/* Quick Links */}
            <Box mt={3} mb={isMobileView ? 0 : 4}>
              <QuickLinkSection goal={operatorGettingStarted.goal} />
            </Box>

            {/* Need Help Section Mobile View */}
            {isMobileView && (
              <Box
                ml={0}
                width="100%"
                display="flex"
                justifyContent="flex-end"
                mb={2}
              >
                <NeedHelpSection />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default GettingStartedPage;
