import React from "react";
import first from "lodash/first";

import ActionButton from "design-system/components/ActionButton";
import { DollarIcon } from "design-system/icons";
import { Request, Trip } from "types";
import FarmeePricingDialog from "./ViewMoreActionButton/components/FarmeePricingDialog";
import TripPricingDialog from "./ViewMoreActionButton/components/TripPricingDialog";
import { useUpdatePricingClick } from "./ViewMoreActionButton/hooks";
import { deriveIsFarmee } from "globals/utils/farm";

type UpdatePricingActionButtonProps = {
  request: Request;
  trip: Trip;
};

function UpdatePricingActionButton(props: UpdatePricingActionButtonProps) {
  const { request, trip } = props;

  // hooks
  const updatePricing = useUpdatePricingClick({
    request,
    trip,
    analyticsName: "reservation_pricingOpened",
  });

  // derived state
  const route = first(trip.routes);
  const isFarmee = deriveIsFarmee(route);
  const currentTripIndex = request?.trips?.findIndex(
    ({ id }) => trip.id === id
  );

  return (
    <>
      <ActionButton
        icon={DollarIcon}
        label="Price"
        maxWidth={70}
        // @ts-ignore fix later
        onClick={updatePricing.onClick}
        // @ts-ignore fix later
        disabled={updatePricing.disableOption}
        // @ts-ignore fix later
        disableTooltipText={updatePricing.tooltip?.titleText}
      />

      {isFarmee ? (
        <FarmeePricingDialog
          open={updatePricing.open}
          onClose={updatePricing.onClose}
          currentTripIndex={currentTripIndex}
          pricings={updatePricing.pricings}
          routeId={route.id}
        />
      ) : (
        <TripPricingDialog
          open={updatePricing.open}
          onClose={updatePricing.onClose}
          currentTripIndex={currentTripIndex}
          pricings={updatePricing.pricings}
          request={request}
          legacyBillings={trip.billings}
        />
      )}
    </>
  );
}

export default UpdatePricingActionButton;
