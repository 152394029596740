import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import { Box, Typography, IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  black,
  errorRed,
  grayMedium,
  moovsBlue,
  moovsBlueHover,
} from "design-system/colors";
import { PDFLogoIcon } from "design-system/icons";
import { LOAD_REQUEST_QUERY } from "globals/graphql";
import { useSnackbar } from "globals/hooks";
import { downloader } from "globals/utils/downloader";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type PdfAttachmentButtonProps = {
  requestId: string;
  onClosePdfAttachment: (e: React.MouseEvent) => void;
};

function PdfAttachmentButton(props: PdfAttachmentButtonProps) {
  const { requestId, onClosePdfAttachment } = props;

  // hooks
  const snackbar = useSnackbar();

  // state
  const [isBoxHover, setIsBoxHover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // queries
  const { data: requestData } = useQuery(LOAD_REQUEST_QUERY, {
    variables: {
      id: requestId,
    },
  });

  const orderNumber = requestData?.node?.orderNumber || "";
  const filename = `Reservation_${orderNumber}`;
  const filenameWithExtension = `${filename}.pdf`;

  // event handlers
  const handlePdfAttachmentClick = async (e: React.MouseEvent) => {
    e.stopPropagation();

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const endPoint = "/endpoint/reservation/operatorPdfDownloadHandler";
    const params = { id: requestId };

    try {
      await downloader(
        endPoint,
        params,
        filenameWithExtension
      );

      snackbar.success(`Successfully downloaded Reservation PDF!`);
    } catch (error) {
      const errorMessage =
        getErrorMessage(error) || `Error downloading Reservation PDF`;
      snackbar.error(errorMessage);
    }
    setIsLoading(false);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mx="auto"
      p={1}
      border={`1px solid ${grayMedium}`}
      mb="24px"
      borderRadius={1}
      onClick={handlePdfAttachmentClick}
      sx={{
        ...(!isLoading && { cursor: "pointer" }),
        ...(isBoxHover && !isLoading && { borderColor: moovsBlue }),
      }}
      onMouseEnter={() => setIsBoxHover(true)}
      onMouseLeave={() => setIsBoxHover(false)}
    >
      <Box display="flex" alignItems="center" marginLeft={1}>
        <PDFLogoIcon color={errorRed} />
        <Typography
          variant="subtitle2"
          sx={{
            marginLeft: "16px",
            fontWeight: "bold",
            color: isBoxHover && !isLoading ? moovsBlue : black,
          }}
        >
          {filename}
        </Typography>
        {isLoading && <CircularProgress size={20} sx={{ ml: 2 }} />}
      </Box>
      <IconButton
        onClick={onClosePdfAttachment}
        onMouseEnter={() => setIsBoxHover(false)}
        onMouseLeave={() => setIsBoxHover(true)}
        disabled={isLoading}
        sx={{
          "&: hover": {
            backgroundColor: moovsBlueHover,
            color: moovsBlue,
          },
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
}

export default PdfAttachmentButton;
