import { useMutation } from "@apollo/client";

import { Box } from "@mui/material";

import ToggleHeader from "../ToogleHeader";
import LabeledSwitchInput from "../../LabeledSwitchInput";
import { useOperator, useSnackbar } from "globals/hooks";
import {
  DISABLE_TEXT_FOR_DRIVER_STATUS_UPDATES,
  EmptyVariables,
  ENABLE_TEXT_FOR_DRIVER_STATUS_UPDATES,
  UPDATE_OPERATOR_SETTINGS,
} from "globals/graphql";

type SendTripStatusTriggeredByBlockProps = {
  setSaveIndicatorState: (value: string) => void;
};

function SendTripStatusTriggeredByBlock(
  props: SendTripStatusTriggeredByBlockProps
) {
  const { setSaveIndicatorState } = props;
  const snackbar = useSnackbar();

  const {
    driverStatusUpdateTextsEnabled,
    twilioPhoneNumber,
    settings: { memberChangedStatusTextEnabled },
  } = useOperator();

  const isChatConnected = !!twilioPhoneNumber;

  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error(
        "Error updating setting for text notifications for trip status updates"
      );
    },
  });

  const [enableTextForDriverStatusUpdates] = useMutation(
    ENABLE_TEXT_FOR_DRIVER_STATUS_UPDATES,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError() {
        setSaveIndicatorState("error");
        snackbar.error(
          "error enabling text notifications for driver status updates"
        );
      },
      variables: EmptyVariables,
    }
  );

  const [disableTextForDriverStatusUpdates] = useMutation(
    DISABLE_TEXT_FOR_DRIVER_STATUS_UPDATES,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError() {
        setSaveIndicatorState("error");
        snackbar.error(
          "error disabling text notifications for driver status updates"
        );
      },
      variables: EmptyVariables,
    }
  );

  const handleDriverTriggerTripStatusTextsChange = () => {
    setSaveIndicatorState("loading");

    driverStatusUpdateTextsEnabled
      ? disableTextForDriverStatusUpdates()
      : enableTextForDriverStatusUpdates();
  };

  const handleMemberTriggerTripStatusTextsChange = () => {
    setSaveIndicatorState("loading");
    updateOperatorSettings({
      variables: {
        input: {
          memberChangedStatusTextEnabled: !memberChangedStatusTextEnabled,
        },
      },
    });
  };

  return (
    <Box display="flex" flexDirection="column">
      <ToggleHeader
        text="TRIGGERED BY"
        subText="Specify what will trigger Trip Status Update SMS notifications (on the way, on location, done) to go out."
      />

      <Box display="flex" flexDirection="column" gap={1} my={2}>
        <LabeledSwitchInput
          name="setTripStatusToBeTriggeredByDriver"
          checked={driverStatusUpdateTextsEnabled}
          disabled={!isChatConnected}
          onChange={handleDriverTriggerTripStatusTextsChange}
          text="Driver"
        />

        <LabeledSwitchInput
          name="setTripStatusToBeTriggeredByMember"
          disabled={!isChatConnected}
          checked={memberChangedStatusTextEnabled}
          onChange={handleMemberTriggerTripStatusTextsChange}
          text="Member"
        />
      </Box>
    </Box>
  );
}

export default SendTripStatusTriggeredByBlock;
