// weekdays
export enum WeekdaysEnum {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

export type DynamicPricingVehicleType = {
  id: string;
  value: string;
  disabled: boolean;
  isSelected: boolean;
};

// dynamicPricingInput
export type DynamicPricingInputType = {
  date: {
    startDate: Date;
    isIncrease: boolean;
    isPercent: boolean;
    amount: number;
    vehicles: DynamicPricingVehicleType[]; // list of vehicle ids for mutation
    vehiclesAvailable: number; // data necessary for UI outputs, not for mutation
    repeatAnnually: boolean;
    name: string;
    activate: boolean;
  };
  dateRange: {
    startDate: Date;
    endDate: Date;
    daysOfWeek: { id: WeekdaysEnum; value: WeekdaysEnum }[];
    isIncrease: boolean;
    isPercent: boolean;
    amount: number;
    vehicles: DynamicPricingVehicleType[];
    vehiclesAvailable: number; // data necessary for UI outputs, not for mutation
    repeatAnnually: boolean;
    name: string;
    activate: boolean;
  };
  timesOfDay: {
    startTime: Date;
    endTime: Date;
    daysOfWeek: { id: WeekdaysEnum; value: WeekdaysEnum }[];
    isIncrease: boolean;
    isPercent: boolean;
    amount: number;
    vehicles: DynamicPricingVehicleType[];
    vehiclesAvailable: number; // data necessary for UI outputs, not for mutation
    repeatAnnually: boolean;
    name: string;
    activate: boolean;
  };
};
