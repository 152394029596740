import React, { Dispatch, SetStateAction } from "react";
import isNil from "lodash/isNil";

import { Box } from "@mui/material";

import MoovsPricingField from "./MoovsPricingField";
import { DollarPercentVariant } from "../../utils";
import {
  PricingFieldsRendered,
  PricingKeys,
} from "components/pricing/utils/types";
import { PricingLayout, RoutePricing } from "types";
import { ALL_OPTIONAL_PRICING_FIELDS } from "components/pricing/utils/optionalPricingFields";
import AirportDropOffIcon from "design-system/icons/info/AirportDropOffIcon";

type OptionalPricingFieldsListProps = {
  pricing: RoutePricing | PricingLayout;
  baseRateAmt?: number;
  onPricingUpdate: (pricingKeys: PricingKeys) => void;
  setFieldsRendered: Dispatch<SetStateAction<PricingFieldsRendered>>;
  isPricingLayout?: boolean;
  fieldsRendered?: PricingFieldsRendered;
  hidePromoCodes?: boolean;
};

const { Dollar, Percent } = DollarPercentVariant;

function OptionalPricingFieldsList(props: OptionalPricingFieldsListProps) {
  const {
    pricing,
    baseRateAmt,
    onPricingUpdate,
    setFieldsRendered,
    isPricingLayout,
    fieldsRendered,
    hidePromoCodes,
  } = props;

  // event handlers
  const handleFieldChange = (
    percentFieldName: string,
    fieldName: string,
    value: number | string,
    variant: DollarPercentVariant
  ) => {
    const updateKey = variant === "Percent" ? "percentFieldValue" : "fieldAmt";

    if (!isPricingLayout) {
      onPricingUpdate({
        fieldName,
        fieldAmt:
          variant === Dollar
            ? value
            : ((pricing[percentFieldName] ? pricing[percentFieldName] : 0) /
                100) *
              baseRateAmt,
        percentFieldName,
        percentFieldValue: variant === Percent ? value : null,
      });
    } else {
      onPricingUpdate({
        [updateKey]: value,
        fieldName,
        percentFieldName,
      });
    }
  };

  // resets removed field values back to default
  const handleRemoveField = (fieldName: string, percentFieldName?: string) => {
    setFieldsRendered((fieldsRendered) => ({
      ...fieldsRendered,
      [fieldName]: false,
      [percentFieldName]: false,
    }));

    // handle promo code removal
    if (fieldName === "promoCodeAmt") {
      onPricingUpdate({
        fieldName: "promoCodeId",
        fieldAmt: null,
      });
      onPricingUpdate({
        fieldName: "promoCodeName",
        fieldAmt: null,
      });
    }

    // handle child seat removal
    if (fieldName === "rearFacingSeatAmt") {
      onPricingUpdate({
        fieldName: "rearFacingSeatQuantity",
        fieldAmt: null,
      });
    }
    if (fieldName === "forwardFacingSeatAmt") {
      onPricingUpdate({
        fieldName: "forwardFacingSeatQuantity",
        fieldAmt: null,
      });
    }
    if (fieldName === "boosterSeatAmt") {
      onPricingUpdate({
        fieldName: "boosterSeatQuantity",
        fieldAmt: null,
      });
    }

    onPricingUpdate({
      fieldName,
      percentFieldName,
      fieldAmt: null,
      percentFieldValue: null,
    });
  };

  // toggle between dollar and percent selectors
  const handleDropdownVariantToggle = (
    fieldName: string,
    percentFieldName: string,
    variant: DollarPercentVariant
  ) => {
    if (!variant) return;

    // assigning 0 to indicate selector is selected but there are no values
    const updateKey = variant === "Percent" ? "percentFieldValue" : "fieldAmt";
    const updateValue =
      variant === "Percent"
        ? pricing[fieldName] || 0
        : pricing[percentFieldName] || 0;

    if (!isPricingLayout) {
      onPricingUpdate({
        fieldName,
        fieldAmt:
          variant === Dollar ? pricing[percentFieldName] : pricing[fieldName],
        percentFieldName,
        percentFieldValue: variant === Percent ? pricing[fieldName] : null,
      });
    } else {
      onPricingUpdate({
        [updateKey]: updateValue,
        fieldName,
        percentFieldName,
      });
    }
  };

  const handleOtherNameFieldChange = (
    fieldName: string,
    fieldValue: string
  ) => {
    onPricingUpdate({ fieldName, fieldAmt: fieldValue });
  };

  const handleQuantityFieldChange = (
    quantityFieldName: string,
    quantityFieldValue: number
  ) => {
    onPricingUpdate({
      fieldName: quantityFieldName,
      fieldAmt: quantityFieldValue,
    });
  };

  return (
    <Box display="flex" flexDirection="column">
      {ALL_OPTIONAL_PRICING_FIELDS.map((option) => {
        if (hidePromoCodes && option.fieldName === "promoCodeAmt") return null;

        const {
          label,
          fieldName,
          percentFieldName,
          additionalFieldProps,
          quantityFieldProps,
        } = option;

        let isChecked: boolean;

        if (fieldsRendered) {
          isChecked =
            fieldsRendered[fieldName] || fieldsRendered[percentFieldName];
        } else {
          isChecked =
            !isNil(pricing[fieldName]) || !isNil(pricing[percentFieldName]);
        }

        const DollarPercentVariant =
          pricing[percentFieldName] === 0 && pricing[fieldName] === null // toggling from dollar to percent with 0 input
            ? Percent
            : pricing[percentFieldName] === null && pricing[fieldName] === 0 // toggling from percent to dollar with 0 input
            ? Dollar
            : pricing[percentFieldName] !== null && // when there is a percent value show percent variant
              pricing[percentFieldName] !== undefined // when tollAmt, show percent variant
            ? Percent
            : Dollar; // everything else should show dollar variant

        const fieldValue =
          DollarPercentVariant === Dollar
            ? pricing[fieldName]
            : pricing[percentFieldName];

        const showMeetGreetHelpers =
          fieldName === "meetGreetAmt" && isPricingLayout;

        return isChecked ? (
          <MoovsPricingField
            key={fieldName}
            label={label}
            fieldValue={
              fieldValue ? parseFloat(fieldValue.toFixed(5)) : fieldValue
            }
            fieldName={fieldName}
            percentFieldName={percentFieldName}
            onRemove={handleRemoveField}
            variant={DollarPercentVariant}
            onFieldChange={handleFieldChange}
            {...(showMeetGreetHelpers && {
              labelIcon: <AirportDropOffIcon />,
              helperText: `Will be applied when client selects 'Meet & Greet' on an airport pick-up trip`,
            })}
            {...(percentFieldName && {
              onToggle: handleDropdownVariantToggle,
            })}
            {...(!!additionalFieldProps && {
              additionalFieldProps: {
                ...additionalFieldProps,
                fieldValue: pricing[additionalFieldProps.fieldName],
                onFieldChange: handleOtherNameFieldChange,
              },
            })}
            {...(!!quantityFieldProps && {
              quantityFieldProps: {
                ...quantityFieldProps,
                fieldValue: pricing[quantityFieldProps.fieldName],
                onFieldChange: handleQuantityFieldChange,
              },
            })}
          />
        ) : null;
      })}
    </Box>
  );
}

export default OptionalPricingFieldsList;
