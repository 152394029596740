import React from "react";

import { Box, IconButton, Typography } from "@mui/material";

import { grayLight, moovsBlue } from "design-system/colors";
import { CrossIcon } from "design-system/icons";
import { useScreenSize } from "globals/hooks";
import { Request, Trip } from "types";
import ActionButtonPanelDesktop from "../../ActionButtonPanel/ActionButtonPanel.desktop";
import ActionButtonPanelMobile from "../../ActionButtonPanel/ActionButtonPanel.mobile";

type TripSummaryHeaderProps = {
  request: Request;
  trip: Trip;
  onCloseTripSummaryClick: () => void;
};

function TripSummaryHeader(props: TripSummaryHeaderProps) {
  const { request, trip, onCloseTripSummaryClick } = props;

  // hooks
  const { isMobileView, isMediumTabletView } = useScreenSize();

  // derived state
  const confNo = `${request.orderNumber}-${trip.tripNumber}`;
  const tripIndex = request.trips.findIndex(({ id }) => trip.id === id);
  const tripLabel = trip.cancelledAt ? "Cancelled" : `Trip ${tripIndex + 1}`;

  return (
    <Box
      pt={2}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      {...(isMobileView
        ? { borderBottom: `1px solid ${grayLight}`, pb: 2 }
        : { ml: 2 })}
    >
      <Box display="flex" alignItems="center">
        {isMediumTabletView && (
          <IconButton
            sx={{ mx: 1 }}
            onClick={onCloseTripSummaryClick}
            size="large"
          >
            <CrossIcon color={moovsBlue} />
          </IconButton>
        )}
        <Box display="flex" flexDirection="column">
          <Typography variant="overline">{tripLabel}</Typography>
          <Typography variant="h2">{confNo}</Typography>
        </Box>
      </Box>
      <Box mr={2}>
        {isMobileView ? (
          <ActionButtonPanelMobile request={request} trip={trip} />
        ) : (
          <ActionButtonPanelDesktop request={request} trip={trip} />
        )}
      </Box>
    </Box>
  );
}

export default TripSummaryHeader;
