import flatMap from "lodash/flatMap";
import first from "lodash/first";
import moment from "moment-timezone";

import { FarmedRouteStatusEnum, Trip } from "types";

// farmed trips that are accepted or completed
const filterConfirmedFarmTrips = (trips: Trip[] = []) => {
  const confirmedTrips = trips.filter(
    ({ farmedRouteStatus }) =>
      farmedRouteStatus === FarmedRouteStatusEnum.Accepted ||
      farmedRouteStatus === FarmedRouteStatusEnum.Completed
  );
  return confirmedTrips;
};

// active trips are not cancelled
export const filterActiveTrips = (trips: Trip[] = []) => {
  return trips.filter((trip) => !trip.cancelledAt);
};

type filterRelevantOrderTripsParams = {
  trips: Trip[];
  isFarmee: boolean;
};

export const filterRelevantOrderTrips = (
  params: filterRelevantOrderTripsParams
): Trip[] => {
  const { trips, isFarmee } = params;
  return isFarmee ? filterConfirmedFarmTrips(trips) : trips;
};

// converts trips array with return trips at 'trip.returnTrip' to array of trips without 'trip.returnTrip', and return trips succeeding outbound trips
// ex: [{id: 1, returnTrip: { id: 2 }}, {id: 3}] -> [{ id: 1 }, { id: 2 }, { id: 3 }]
export const flattenReturnTrips = (
  trips: Trip[]
): Exclude<Trip, "returnTrip">[] => {
  return flatMap(trips, (trip) => {
    if (!trip.returnTrip) return trip;
    return [{ ...trip, returnTrip: null }, trip.returnTrip];
  });
};

// finds index of trip from nested trips list
export const findReturnTripIndex = (trips: Trip[], tripId: string) => {
  return flattenReturnTrips(trips).findIndex(({ id }) => id === tripId);
};

// return whether or not all trips have stops in the same day (in regards to the pickup time)
export const isAllTripsOnTheSameDay = (trips: Trip[]) => {
  // get first trip's first stop's dateTime
  const { dateTime } = first(first(trips).stops);
  return trips.every((trip) => {
    return moment(dateTime)
      .utc()
      .startOf("day")
      .isSame(moment(first(trip.stops).dateTime).utc().startOf("day"));
  });
};
