import React, { useState } from "react";
import first from "lodash/first";

import { Trip, RequestStage } from "types";
import { TrashIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import { useAnalytics } from "globals/hooks";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";
import { deriveIsFarmee, deriveIsFarmor } from "globals/utils/farm";

type UseDeleteTripClickProps = {
  trip: Trip;
  analyticsName?: string;
  requestStage?: RequestStage;
};

function useDeleteTripClick(
  props: UseDeleteTripClickProps
): EllipsisMenuOption & { open: boolean; onClose: () => void } {
  const { trip, analyticsName, requestStage } = props;

  // state
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  // hooks
  const { track } = useAnalytics();

  // derived state
  const { routes, isTripInvoiced, amountDue, totalAmount } = trip || {};
  const isFarmee = deriveIsFarmee(first(routes));
  const isFarmor = deriveIsFarmor(first(routes));
  const isTripCharged =
    amountDue < totalAmount ||
    (first(routes)?.farmeePricing?.amountPaid > 0 && isFarmor);
  const isUnconfirmedReservation = requestStage === RequestStage.Unconfirmed;
  const isDeleteDisabled =
    isTripInvoiced || isTripCharged || isUnconfirmedReservation;
  const tripDeleteToolTip = isTripInvoiced
    ? "You cannot delete a trip that has an active invoice."
    : isTripCharged
    ? "Cannot delete trip with charge."
    : "";

  const handleDeleteTripClick = () => {
    if (analyticsName) track(analyticsName);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  return {
    onClick: handleDeleteTripClick,
    text: "Delete Trip",
    icon: <TrashIcon color={grayDark} size="small" />,
    hideOption: isFarmee,
    disableOption: isDeleteDisabled,
    tooltip:
      isTripInvoiced || isTripCharged
        ? {
            titleText: tripDeleteToolTip,
          }
        : undefined,
    open: deleteDialogOpen,
    onClose: handleDeleteDialogClose,
  };
}

export { useDeleteTripClick };
