import React from "react";

import {
  Box,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";

import UserAccessPermissionToggle from "./UserAccessPermissionToggle";
import { PermissionData } from "./UserAccessPermissions";
import { UserAccessPermission } from "types";
import { errorRed } from "design-system/colors";

type UserAccessPermissionsSectionProps = {
  category: string;
  permissions: PermissionData[];
  handleUserAccessPermissionsChange: (
    modifiedAccessPermission: UserAccessPermission
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
};

function UserAccessPermissionsSection(
  props: UserAccessPermissionsSectionProps
) {
  const { category, permissions, handleUserAccessPermissionsChange, error } =
    props;

  return (
    <FormControl>
      <Box pt={1}>
        <Box mb={1.5}>
          <FormLabel sx={{ fontSize: 12 }}>{category.toUpperCase()}</FormLabel>
        </Box>
        <FormGroup>
          <Grid container>
            {permissions.map((permission: PermissionData) => (
              <UserAccessPermissionToggle
                key={permission.id}
                handleUserAccessPermissionsChange={handleUserAccessPermissionsChange(
                  permission
                )}
                {...permission}
              />
            ))}
          </Grid>
        </FormGroup>
        {error && (
          <Typography variant="body2" color={errorRed} my={1}>
            {error}
          </Typography>
        )}
      </Box>
    </FormControl>
  );
}

export default UserAccessPermissionsSection;
