import React from "react";

type NoteIconProps = {
  color?: string;
  size?: "small";
};

const NoteIcon = (props: NoteIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 6.5H8M4 4H11M4 9H6.33333"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 15V10.0575C9 9.77706 9.11142 9.50807 9.30974 9.30974C9.50807 9.11142 9.77706 9 10.0575 9H14.5M9.0136 15H1.93333C1.6858 15 1.4484 14.9017 1.27337 14.7266C1.09833 14.5516 1 14.3142 1 14.0667V1.93333C1 1.6858 1.09833 1.4484 1.27337 1.27337C1.4484 1.09833 1.6858 1 1.93333 1H14.0667C14.3142 1 14.5516 1.09833 14.7266 1.27337C14.9017 1.4484 15 1.6858 15 1.93333V9.0136C14.9999 9.26096 14.9017 9.49819 14.7268 9.67316L9.67316 14.7268C9.49819 14.9017 9.26096 14.9999 9.0136 15Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 10.5H11.8571M6 7H17M6 14H9"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2857 21V14.6454C13.2857 14.2848 13.429 13.9389 13.684 13.684C13.9389 13.429 14.2848 13.2857 14.6454 13.2857H20.3571M13.3032 21H4.2C3.88174 21 3.57652 20.8736 3.35147 20.6485C3.12643 20.4235 3 20.1183 3 19.8V4.2C3 3.88174 3.12643 3.57652 3.35147 3.35147C3.57652 3.12643 3.88174 3 4.2 3H19.8C20.1183 3 20.4235 3.12643 20.6485 3.35147C20.8736 3.57652 21 3.88174 21 4.2V13.3032C20.9999 13.6212 20.8736 13.9262 20.6488 14.1512L14.1512 20.6488C13.9262 20.8736 13.6212 20.9999 13.3032 21Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NoteIcon;
