type DriverPayoutFields = {
  driverPayoutFlatRate: number;
  driverPayoutHours: number;
  driverPayoutRatePerHour: number;
  driverPayoutGratuity: number;
};

type AutomatedDriverPayoutFields = {
  automatedDriverPayoutFlatRate?: number;
  automatedDriverPayoutHours?: number;
  automatedDriverPayoutRatePerHour?: number;
  automatedDriverPayoutGratuity?: number;
  driverGratuityAmt: number;
};

export function calculateDriverPayoutTotal(
  driverPayoutFields: DriverPayoutFields
) {
  const {
    driverPayoutFlatRate = 0,
    driverPayoutHours = 0,
    driverPayoutRatePerHour = 0,
    driverPayoutGratuity = 0,
  } = driverPayoutFields;
  const hourlyRateSubtotal = driverPayoutHours * driverPayoutRatePerHour;

  // returns total and hourly rate subtotal
  return [
    Number(
      (
        +driverPayoutFlatRate +
        +hourlyRateSubtotal +
        +driverPayoutGratuity
      ).toFixed(2)
    ),
    hourlyRateSubtotal,
  ];
}

export const calculateAutomatedGratuityAmount = (
  driverGratuityAmt: number,
  automatedDriverPayoutGratuity: number
) => {
  if (!driverGratuityAmt || !automatedDriverPayoutGratuity) return 0;

  return Number(
    ((automatedDriverPayoutGratuity / 100) * driverGratuityAmt).toFixed(2)
  );
};

export function isAutomationPayoutUpdated(
  driverPayoutFields: DriverPayoutFields,
  automatedDriverPayoutFields: AutomatedDriverPayoutFields
) {
  const {
    driverPayoutFlatRate = 0,
    driverPayoutHours = 0,
    driverPayoutRatePerHour = 0,
    driverPayoutGratuity = 0,
  } = driverPayoutFields;

  const {
    automatedDriverPayoutFlatRate = 0,
    automatedDriverPayoutHours = 0,
    automatedDriverPayoutRatePerHour = 0,
    automatedDriverPayoutGratuity = 0,
    driverGratuityAmt = 0,
  } = automatedDriverPayoutFields;

  return (
    Number(driverPayoutFlatRate) !== Number(automatedDriverPayoutFlatRate) ||
    Number(driverPayoutHours) !== Number(automatedDriverPayoutHours) ||
    Number(driverPayoutRatePerHour) !==
      Number(automatedDriverPayoutRatePerHour) ||
    Number(driverPayoutGratuity) !==
      calculateAutomatedGratuityAmount(
        driverGratuityAmt,
        automatedDriverPayoutGratuity
      )
  );
}
