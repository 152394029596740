import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { Box, Typography } from "@mui/material";

import UpdateDrawer from "components/globals/UpdateDrawer";
import { TrashIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import {
  useAnalytics,
  useOperator,
  useSnackbar,
  useScreenSize,
} from "globals/hooks";
import { REMOVE_PROMO_CODE_MUTATION } from "globals/graphql";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { PromoCode } from "types";
import PromoCodeDrawerTabs from "./PromoCodeDrawerTabs";
import { PromoCodeResultsGrid, PromoCodeOverviewSection } from "./components";

function UpdatePromoCodeDrawer() {
  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();
  const operator = useOperator();
  const { promoCodeId } = useParams<{
    promoCodeId: string;
  }>();

  const allPromoCodes = operator?.settings?.promoCodes || [];
  const promoCode = allPromoCodes.find(
    (promoCode: PromoCode) => promoCode.id === promoCodeId
  );

  // state
  const [tabMode, setTabMode] = useState("Overview");

  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "saved" | "loading" | "error"
  >("default");

  // mutations
  const [removePromoCode] = useMutation(REMOVE_PROMO_CODE_MUTATION, {
    onCompleted() {
      track("promoCode_deleted");
      snackbar.success("Successfully deleted promo code!");
      history.push(`/settings/customer-portal?tab=promo-codes`);
    },
    onError(error) {
      const errorMessage =
        getErrorMessage(error) || "Error deleting promo code";

      snackbar.error(errorMessage);
    },
  });

  // event handlers
  const handleTabChange = (tabMode: string) => {
    if (tabMode === "Results") {
      track("promoCode_resultsViewed");
    }
    setTabMode(tabMode);
  };

  const handleDeletePromoCodeClick = () => {
    removePromoCode({
      variables: {
        input: {
          id: promoCodeId,
        },
      },
    });
  };

  const shouldDisableDelete = promoCode?.redemptionCount > 0;

  return (
    <>
      <UpdateDrawer
        onClose={() => {
          history.push(`/settings/customer-portal?tab=promo-codes`);
        }}
        updatedAt={promoCode ? new Date().toISOString() : ""}
        saveIndicatorState={saveIndicatorState}
        ellipsisMenuOptions={[
          {
            onClick: handleDeletePromoCodeClick,
            text: "Delete Promo Code",
            icon: <TrashIcon color={grayDark} size="small" />,
            disableOption: shouldDisableDelete,
            ...(shouldDisableDelete && {
              tooltip: {
                titleText: "You cannot delete a promo code with redemptions",
              },
            }),
          },
        ]}
        headerContent={
          <Box mr={1}>
            <Typography variant="h2">
              {promoCode?.promoCodeName || ""}
            </Typography>
          </Box>
        }
        subHeaderContent={
          <PromoCodeDrawerTabs value={tabMode} setValue={handleTabChange} />
        }
        drawerWidth={831}
      >
        {promoCode && (
          <>
            {tabMode === "Overview" ? (
              <Box>
                <PromoCodeOverviewSection
                  promoCode={promoCode}
                  setSaveIndicatorState={setSaveIndicatorState}
                />
              </Box>
            ) : (
              <PromoCodeResultsGrid
                promoCodeId={promoCodeId}
                {...(isMobileView
                  ? { sx: { mx: -2 } }
                  : {
                      sx: {
                        mx: -4,
                        "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
                          {
                            display: "none",
                          },
                      },
                    })}
              />
            )}
          </>
        )}
      </UpdateDrawer>
    </>
  );
}

export default UpdatePromoCodeDrawer;
