import React, { useState } from "react";
import moment from "moment";
import { Typography, Box } from "@mui/material";

import {
  granite,
  grayDark,
  grayLight,
  successGreen,
  tintGreen,
  moovsBlue,
  moovsBlueSelected,
} from "design-system/colors";
import { ChevronDownIcon } from "design-system/icons";
import { StripePayout } from "types";
import { currency } from "globals/utils/helpers";
import RoundChip from "design-system/components/chips/RoundChip";
import { PayoutDetailsDrawerMobile } from "./components";

type PayoutsListItemMobileProps = { stripePayout: StripePayout };

function PayoutsListItemMobile(props: PayoutsListItemMobileProps) {
  const { stripePayout } = props;

  // state
  const [open, setOpen] = useState(false);

  // event handlers
  const handlePayoutListItemClick = () => {
    setOpen(true);
  };

  const onBackClick = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        border={`1px solid ${grayLight}`}
        borderRadius="4px"
        mb={1}
        p={2}
        boxShadow="0px 4px 15px 0px rgba(0, 0, 0, 0.03)"
        onClick={handlePayoutListItemClick}
      >
        <Box display="flex" flexDirection="row" flex="1" mx="auto">
          <Box display="flex" flexDirection="column" flex="1">
            <Typography variant="body2" fontWeight={700}>
              Payout
            </Typography>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="body2">
                {moment.utc(stripePayout.payoutDate).format("MM/DD/YY")}
              </Typography>
              <Box ml={1}>
                <RoundChip
                  label={
                    {
                      paid: "PAID",
                      in_transit: "IN TRANSIT",
                    }[stripePayout.status] || "N/A"
                  }
                  color={
                    {
                      paid: successGreen,
                      in_transit: moovsBlue,
                    }[stripePayout.status] || grayDark
                  }
                  backgroundColor={
                    {
                      paid: tintGreen,
                      in_transit: moovsBlueSelected,
                    }[stripePayout.status] || grayLight
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Typography variant="body2" fontWeight={700}>
              {currency(stripePayout.amount)}
            </Typography>
          </Box>
          <Box
            alignSelf="center"
            ml={1}
            style={{ transform: "rotate(-90deg)" }}
          >
            <ChevronDownIcon color={granite} />
          </Box>
        </Box>
      </Box>
      <PayoutDetailsDrawerMobile
        open={open}
        stripePayout={stripePayout}
        onBackClick={onBackClick}
      />
    </>
  );
}

export default PayoutsListItemMobile;
