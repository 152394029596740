import React, { ChangeEvent } from "react";

import { Box, Card, Switch, Typography } from "@mui/material";

import { granite, grayLight } from "design-system/colors";

type DynamicPricingCombineToggleProps = {
  checked: boolean;
  onChange: (event: ChangeEvent) => void;
};

function DynamicPricingCombineToggle(props: DynamicPricingCombineToggleProps) {
  const { checked, onChange } = props;

  return (
    <Box marginTop={2}>
      <Card variant="outlined" sx={{ backgroundColor: grayLight }}>
        <Box
          display="flex"
          height="100%"
          padding={2}
          width="100%"
          justifyContent="start"
        >
          <Switch checked={checked} onChange={onChange} color="primary" />

          <Box display="flex" flexDirection="column" ml={1}>
            <Box mb={0.5}>
              <Typography variant="body2">
                {"Combine Dynamic Pricing Rules"}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption" style={{ color: granite }}>
                {checked
                  ? "All surcharges and discounts will be combined on top of each other."
                  : "All surcharges and discounts will NOT be combined on top of each other only the highest surcharge and discount will be applied."}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export default DynamicPricingCombineToggle;
