import React from "react";
import { ApolloQueryResult, useMutation } from "@apollo/client";

import { Box, Button, Typography } from "@mui/material";

import AddContactToOrderDialog from "components/contacts/people/AddContactToOrderDialog";
import EditBookingContactDialog from "components/contacts/bookingContact/components/EditBookingContactDialog";
import { LINK_PASSENGER_TO_CONTACT_MUTATION } from "globals/graphql";
import { useAnalytics, useScreenSize, useSnackbar } from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { Contact } from "types";
import { useUpdateBookingContactClick } from "components/contacts/bookingContact/hooks/useUpdateBookingContactClick";
import LinkedPassengersList from "./LinkedPassengersList";
import { getContactName } from "globals/utils/contact";

type ContactLinkPassengerSectionProps = {
  contact: Contact;
  refreshContact: (
    variables?: Partial<{
      id: string;
    }>
  ) => Promise<ApolloQueryResult<any>>;
};

export default function ContactLinkPassengerSection(
  props: ContactLinkPassengerSectionProps
) {
  const { contact, refreshContact } = props;
  const {
    editBookingContactDialogOpen,
    setEditBookingContactDialogOpen,
    addContactToOrderDialogOpen,
    setAddContactToOrderDialogOpen,
    carryOverSearch,
    setCarryOverSearch,
    bookingContact: passengerContact,
    setBookingContact: setPassengerContact,
  } = useUpdateBookingContactClick();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const { isMobileView } = useScreenSize();

  const linkedPassengerIds =
    contact?.linkedPassengers?.map((passenger) => passenger.id) || [];

  // mutations
  const [createLinkedPassenger, { loading }] = useMutation(
    LINK_PASSENGER_TO_CONTACT_MUTATION,
    {
      refetchQueries: ["loadContacts"],
      awaitRefetchQueries: true,
      onCompleted(data) {
        const passenger = data.createLinkedPassenger.passenger;

        snackbar.success(
          `Successfully linked passenger ${getContactName(passenger)}!`
        );
        track("linkPassenger_newAdded");
        setEditBookingContactDialogOpen(false);
        setPassengerContact(null);
        refreshContact();
      },
      onError(error) {
        const errorMessage =
          getErrorMessage(error) || "Error linking passenger";

        snackbar.error(errorMessage);
      },
    }
  );

  const handleCreateLinkPassenger = () => {
    if (contact?.id && passengerContact?.id) {
      createLinkedPassenger({
        variables: {
          input: {
            contactId: contact.id,
            passengerId: passengerContact.id,
          },
        },
      });
    }
  };

  const hasLinkedPassengers = contact?.linkedPassengers?.length > 0;

  return (
    <Box mt={4}>
      <Box>
        <Typography variant="h3">Linked Passengers</Typography>
      </Box>
      {hasLinkedPassengers && (
        <LinkedPassengersList
          contact={contact}
          refreshContact={refreshContact}
        />
      )}
      <Box mt={3}>
        <Button
          color="primary"
          variant="outlined"
          disableElevation
          onClick={() => setEditBookingContactDialogOpen(true)}
          fullWidth={isMobileView ? true : false}
        >
          Link Passenger
        </Button>
      </Box>
      <EditBookingContactDialog
        title="Link Passenger"
        open={editBookingContactDialogOpen}
        onClose={() => setEditBookingContactDialogOpen(false)}
        setAddContactToOrderDialogOpen={setAddContactToOrderDialogOpen}
        bookingContact={passengerContact}
        setBookingContact={setPassengerContact}
        setCarryOverSearch={setCarryOverSearch}
        onSave={handleCreateLinkPassenger}
        isSaveButtonDisabled={loading}
        filter={linkedPassengerIds}
      />
      <AddContactToOrderDialog
        open={addContactToOrderDialogOpen}
        onClose={() => setAddContactToOrderDialogOpen(false)}
        onContactUpdate={setPassengerContact}
        carryOverSearch={carryOverSearch}
      />
    </Box>
  );
}
