import React from "react";
import { Control, FieldErrors, useForm, UseFormWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Typography } from "@mui/material";

import RHFToggleButtonsAlternative from "components/reactHookFormInputs/RHFToggleButtonsAlternative";
import RHFTextField from "components/reactHookFormInputs/RHFTextField";
import { grayDark } from "design-system/colors";
import { NumberFormatNatural } from "design-system/components/inputs/NumberFormat";
import { InfoIcon } from "design-system/icons";
import { useScreenSize } from "globals/hooks";
import { limitsDefaultValues } from "./defaultValues";
import { limitsSchema } from "./schemaValidation";
import { EditPromoCodeValues } from "../../../UpdatePromoCodeDrawer/components/PromoCodeOverviewSection/EditPromoCodeForm/useEditPromoCodeDefaultValues";

type LimitsValues = {
  isTotalLimited: boolean;
  isBookingContactLimited: boolean;
  totalLimit: number | null;
  bookingContactLimit: number | null;
};

type LimitsFormProps = {
  control: Control<LimitsValues> | Control<EditPromoCodeValues>;
  errors: FieldErrors;
  watch: UseFormWatch<LimitsValues> | UseFormWatch<EditPromoCodeValues>;
  isEdit?: boolean;
};

export function LimitsForm(props: LimitsFormProps) {
  const { control, errors, watch, isEdit } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  const {
    isTotalLimited,
    isBookingContactLimited,
    totalLimit,
    bookingContactLimit,
  } = watch();

  const setInputAsNumber = (e) => {
    // Number strings with commas causes issues with parseInt
    const commaRemovedValue = e.target.value.replace(/,/g, "");
    e.target.value = parseInt(commaRemovedValue);
  };

  return (
    <Box px={isMobileView ? 2 : 0}>
      <Box mb={3} sx={{ minHeight: isMobileView ? "250px" : "158px" }}>
        <Box
          py={isMobileView ? 0.5 : 0}
          display="flex"
          alignItems="center"
          mb={1}
        >
          <Typography variant="h4">Total Limit</Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            width="100%"
            flexDirection={isMobileView ? "column" : "row"}
          >
            <RHFToggleButtonsAlternative
              name="isTotalLimited"
              control={control}
              width={isMobileView ? "100%" : "196px"}
              toggleButtons={[
                {
                  ariaLabel: "totalLimited",
                  value: true,
                  text: "Limited",
                },
                {
                  ariaLabel: "totalUnlimited",
                  value: false,
                  text: "Unlimited",
                },
              ]}
            />
            <Box
              display="flex"
              alignItems="center"
              ml={isMobileView ? 0 : 2}
              mt={isMobileView ? 1.5 : 0}
            >
              <InfoIcon size="small" />
              <Typography ml={1} variant="body2" sx={{ color: grayDark }}>
                Setting a limit is recommended
              </Typography>
            </Box>
          </Box>
          <Box mt={2}>
            {isTotalLimited ? (
              <Box
                display="flex"
                flexDirection={isMobileView ? "column" : "row"}
                width="100%"
              >
                <RHFTextField
                  name="totalLimit"
                  control={control}
                  errorMessage={errors.totalLimit?.message}
                  hideErrorHelperText={true}
                  label="Limit"
                  InputProps={{
                    inputComponent: NumberFormatNatural,
                  }}
                  onInput={setInputAsNumber}
                  sx={{
                    width: isMobileView ? "100%" : "120px",
                  }}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  ml={isMobileView ? 0 : 2}
                  mt={isMobileView ? 1.5 : 0}
                >
                  {errors.totalLimit?.message ? (
                    <Typography variant="body2" color="error">
                      {errors.totalLimit?.message}
                    </Typography>
                  ) : (
                    <Typography variant="body2">
                      This promo code can be used a{" "}
                      <strong>
                        maximum of {totalLimit || "1"}{" "}
                        {totalLimit === 1 ? "time" : "times"} in total
                      </strong>
                    </Typography>
                  )}
                </Box>
              </Box>
            ) : (
              <Typography variant="body2">
                This promo code can be used an{" "}
                <strong>unlimited number of times in total</strong>
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        mb={isEdit ? 0 : 3}
        sx={{ minHeight: isMobileView ? "250px" : "158px" }}
      >
        <Box
          py={isMobileView ? 0.5 : 0}
          display="flex"
          alignItems="center"
          mb={1}
        >
          <Typography variant="h4">Booking Contact Limit</Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            width="100%"
            flexDirection={isMobileView ? "column" : "row"}
          >
            <RHFToggleButtonsAlternative
              name="isBookingContactLimited"
              control={control}
              width={isMobileView ? "100%" : "196px"}
              toggleButtons={[
                {
                  ariaLabel: "bookingContactLimited",
                  value: true,
                  text: "Limited",
                },
                {
                  ariaLabel: "bookingContactUnlimited",
                  value: false,
                  text: "Unlimited",
                },
              ]}
            />
            <Box
              display="flex"
              alignItems="center"
              ml={isMobileView ? 0 : 2}
              mt={isMobileView ? 1.5 : 0}
            >
              <InfoIcon size="small" />
              <Typography ml={1} variant="body2" sx={{ color: grayDark }}>
                Setting a limit is recommended
              </Typography>
            </Box>
          </Box>
          <Box mt={2}>
            {isBookingContactLimited ? (
              <Box
                display="flex"
                flexDirection={isMobileView ? "column" : "row"}
                width="100%"
              >
                <RHFTextField
                  name="bookingContactLimit"
                  control={control}
                  errorMessage={errors.bookingContactLimit?.message}
                  hideErrorHelperText={true}
                  label="Limit"
                  InputProps={{
                    inputComponent: NumberFormatNatural,
                  }}
                  onInput={setInputAsNumber}
                  sx={{
                    width: isMobileView ? "100%" : "120px",
                  }}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  ml={isMobileView ? 0 : 2}
                  mt={isMobileView ? 1.5 : 0}
                >
                  {errors.bookingContactLimit?.message ? (
                    <Typography variant="body2" color="error">
                      {errors.bookingContactLimit?.message}
                    </Typography>
                  ) : (
                    <Typography variant="body2">
                      This promo code can be used a{" "}
                      <strong>
                        maximum of {bookingContactLimit || "1"}{" "}
                        {bookingContactLimit === 1 ? "time" : "times"} per
                        booking contact
                      </strong>
                    </Typography>
                  )}
                </Box>
              </Box>
            ) : (
              <Typography variant="body2">
                This promo code can be used an{" "}
                <strong>unlimited number of times per booking contact</strong>
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function useLimitsForm() {
  // setup react hook forms
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit: handleLimitsSubmit,
    reset: resetLimitsForm,
  } = useForm({
    defaultValues: limitsDefaultValues,
    resolver: yupResolver(limitsSchema),
  });

  return {
    handleLimitsSubmit,
    resetLimitsForm,
    limitsFormComponent: (
      <LimitsForm control={control} errors={errors} watch={watch} />
    ),
  };
}
export default useLimitsForm;
