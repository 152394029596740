import React, { useState } from "react";
import size from "lodash/size";
import uniq from "lodash/uniq";
import some from "lodash/some";
import isUndefined from "lodash/isUndefined";
import { useMutation } from "@apollo/client";

import { Box, Typography } from "@mui/material";

import { UPDATE_OPERATOR_SETTINGS } from "globals/graphql";
import { useSnackbar } from "globals/hooks";
import { errorRed } from "design-system/colors";
import { DriverGratuityPresetInput } from "./components";

type DriverGratuityPresetsBlockProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
  driverGratuityPresets: [number, number, number];
};

const DriverGratuityPresetsBlock = (props: DriverGratuityPresetsBlockProps) => {
  const { setSaveIndicatorState, driverGratuityPresets } = props;

  // state
  const [gratuityPresets, setGratuityPresets] = useState<
    [number, number, number]
  >(driverGratuityPresets);
  const [errorMessage, setErrorMessage] = useState("");

  // hooks
  const snackbar = useSnackbar();

  // mutations
  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      snackbar.error("Error updating operator settings");
    },
  });

  // event handlers
  const updateGratuityPreset = (index: number) => (value: number) => {
    const normalizedValue = value === 100 ? 99 : value === 0 ? 1 : value;

    setGratuityPresets((prevPcts) => {
      // replaceValue in array
      const driverGratuityPresets = Object.assign([], prevPcts, {
        [index]: normalizedValue,
      });

      // if at least one is not defined
      if (some(driverGratuityPresets, isUndefined)) {
        setErrorMessage("All presets must have a number");
        return driverGratuityPresets;
      }

      // if not all values unique, error out
      if (size(driverGratuityPresets) !== size(uniq(driverGratuityPresets))) {
        setErrorMessage("All gratuity presets must be unique");
        return driverGratuityPresets;
      }

      // clear error
      setErrorMessage("");

      // sort after error checking to prevent issues when a value is undefined
      const sortedDriverGratuityPresets = driverGratuityPresets.sort(
        (a, b) => a - b
      );

      setSaveIndicatorState("loading");
      updateOperatorSettings({
        variables: {
          input: {
            driverGratuityPresets: sortedDriverGratuityPresets,
          },
        },
      });

      return sortedDriverGratuityPresets;
    });
  };

  return (
    <Box display="flex" mt={2} ml={1} flexDirection="column">
      <Box display="flex" flexDirection="row">
        {gratuityPresets.map((value, index) => (
          <DriverGratuityPresetInput
            onBlur={updateGratuityPreset(index)}
            value={value}
            error={!!errorMessage}
          />
        ))}
      </Box>
      <Typography variant="caption" color={errorRed}>
        {errorMessage}
      </Typography>
    </Box>
  );
};

export default DriverGratuityPresetsBlock;
