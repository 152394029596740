import React, { ChangeEvent } from "react";
import { Helmet } from "react-helmet";

import { Box, Paper, Typography } from "@mui/material";

import { useCurrentUser, useScreenSize } from "../../globals/hooks";
import EntityHeader from "../common/EntityHeader";
import { black, grayLight } from "../../design-system/colors";
import FarmAffiliatesViewMenu from "./FarmAffiliatesViewMenu";
import FarmAffiliatesEllipsisMenu from "./farmAffiliates/FarmAffiliateEllipsisMenu";
import DownloadButton from "components/buttons/DownloadButton";
import MoovsTabs from "components/globals/MoovsTabs";
import { FarmAffiliateVariantEnum } from "types";
import { useLaunchDarklyFlags } from "globals/utils/useLaunchDarklyFlags";

const styles = {
  header: {
    alignItems: "center",
    paddingBottom: "12px",
    zIndex: 10,
    color: black,
    opacity: 0.6,
  },
};

type ContactsPageTabsProps = {
  title: "Contacts" | "Companies" | "Affiliates";
  entityName: string;
  createEntityRoute: string;
  tabValue: number;
  searchTerm?: string;
  onSearchTermChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSearchTermFocus?: (event) => void;
  viewFilterProps?: {
    viewFilter: FarmAffiliateVariantEnum[];
    onViewFilterChange: (event: ChangeEvent<HTMLInputElement>) => void;
  };
};

function ContactsPageTabs(props: ContactsPageTabsProps) {
  const {
    title,
    entityName,
    createEntityRoute,
    tabValue,
    searchTerm,
    onSearchTermChange,
    onSearchTermFocus,
    viewFilterProps,
  } = props;
  const isAffiliateTab = title === "Affiliates";
  const isContactTab = title === "Contacts";

  // hooks
  const { isMobileView, isSmallTabletView } = useScreenSize();
  const { shouldHideAffiliates } = useCurrentUser();
  const { enableLinkedPassenger } = useLaunchDarklyFlags();

  const tabs = [
    { label: "CONTACTS", to: "/contacts", value: 0 },
    { label: "COMPANIES", to: "/companies", value: 1 },
    ...(shouldHideAffiliates
      ? []
      : [{ label: "AFFILIATES", to: "/affiliates", value: 2 }]),
  ];

  return (
    <>
      <Helmet>
        <title>{title} | Moovs</title>
      </Helmet>

      <Box
        position="sticky"
        top={isMobileView ? "56px" : "78px"}
        zIndex={10}
        width="100%"
      >
        <Paper
          square
          style={{ borderBottom: `1px solid ${grayLight}` }}
          elevation={0}
        >
          <EntityHeader
            title={title}
            entityName={entityName}
            createEntityRoute={createEntityRoute}
            createButtonMode="link"
            globalSearch={searchTerm}
            onGlobalSearchChange={onSearchTermChange}
            onGlobalSearchFocus={onSearchTermFocus}
            {...(tabValue === 0 && { reportName: "contacts" })}
          />

          <Box
            px={isSmallTabletView ? 0 : 3}
            mb={isSmallTabletView ? 0 : 3}
            maxWidth={900}
            mx="auto"
            display="flex"
            flex={1}
            justifyContent="space-between"
            {...(isMobileView && { flexDirection: "column" })}
          >
            <MoovsTabs value={tabValue} tabs={tabs} isLink />

            {isContactTab && !isMobileView && (
              <Box display="flex" alignItems="flex-end">
                <DownloadButton
                  buttonText="Download Report"
                  endpoint={`/reports/contacts/download`}
                  analyticsName="contacts_reportingContacts"
                />
              </Box>
            )}

            {isAffiliateTab && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <FarmAffiliatesViewMenu {...viewFilterProps} />
                <FarmAffiliatesEllipsisMenu />
              </Box>
            )}
          </Box>

          {!isSmallTabletView && !isAffiliateTab && (
            <Box
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
              sx={styles.header}
            >
              <Box
                display="flex"
                flexDirection="row"
                flex="1"
                px={3}
                maxWidth="900px"
                m="auto"
              >
                <Box display="flex" flex="2">
                  <Typography variant="overline">name</Typography>
                </Box>
                <Box display="flex" flex="2">
                  <Typography variant="overline">email</Typography>
                </Box>
                {enableLinkedPassenger && isContactTab && (
                  <Box display="flex" flex="1">
                    <Typography variant="overline">Passengers</Typography>
                  </Box>
                )}
                <Box display="flex" flex="1">
                  <Typography variant="overline">phone</Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Paper>
      </Box>
    </>
  );
}

export default ContactsPageTabs;
