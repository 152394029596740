import React from "react";

type ReopenIconProps = {
  color?: string;
  size?: "small";
};

const ReopenIcon = (props: ReopenIconProps) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1331 6.61538V4.76923C13.1331 4.52442 13.0347 4.28963 12.8597 4.11652C12.6847 3.94341 12.4473 3.84615 12.1997 3.84615H5.66656V2.92308C5.66656 2.67826 5.56823 2.44347 5.3932 2.27036C5.21817 2.09725 4.98078 2 4.73325 2H1.93331C1.68578 2 1.44839 2.09725 1.27336 2.27036C1.09833 2.44347 1 2.67826 1 2.92308V12.9538C1.00351 13.2052 1.09842 13.4469 1.26735 13.6347C1.43629 13.8225 1.66795 13.9439 1.91996 13.9766C2.17196 14.0094 2.42745 13.9513 2.63968 13.8129C2.85191 13.6746 3.00669 13.4653 3.07569 13.2234L4.5385 7.28062C4.59499 7.08856 4.71302 6.91985 4.87484 6.79988C5.03665 6.6799 5.23349 6.61516 5.43572 6.61538H14.0664C14.2094 6.61534 14.3506 6.64782 14.4789 6.71032C14.6073 6.77282 14.7194 6.86366 14.8066 6.97584C14.8938 7.08801 14.9538 7.21851 14.9818 7.35726C15.0099 7.496 15.0054 7.63927 14.9686 7.776L13.5997 13.3145C13.5468 13.5111 13.4296 13.6849 13.2664 13.8089C13.1033 13.9329 12.9033 14.0001 12.6975 14H2.05589"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReopenIcon;
