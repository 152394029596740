import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/client";
import queryString from "query-string";
import size from "lodash/size";

import { Box, Typography, CircularProgress } from "@mui/material";

import RequestsListHeader from "./RequestsList/RequestsListHeader";
import EmptyList from "../../components/globals/EmptyList";
import { LOAD_RESERVATIONS } from "../../globals/graphql";
import GQLQueryStatusIndicator from "../../components/GQLQueryStatusIndicator";
import { grayLight, white } from "../../design-system/colors";
import { RequestsConnection } from "../../types";
import { renderNoReservationsText } from "./requests.utils";
import { useScreenSize } from "../../globals/hooks";
import { RequestModeEnum } from "./utils/enums";
import { DateRange } from "components/common/DateFilter/types";
import RequestListItem from "./RequestsList/RequestListItem";
import MoovsInfiniteScroll from "components/MoovsInfiniteScroll";
import { useCustomDateRange } from "components/common/hooks/useCustomDateRange";

// constants
const { Reservations } = RequestModeEnum;

function ReservationsPage() {
  // hooks
  const { isMobileView } = useScreenSize();
  const location = useLocation();
  const history = useHistory();
  const { storedDateRange } = useCustomDateRange({ mode: "reservations" });

  // state
  const { status: queryParamStatus } = queryString.parse(location.search);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [reservationView, setReservationView] = useState(
    queryParamStatus || "OPEN"
  );

  const [dateRange, setDateRange] = useState<DateRange>(storedDateRange);

  const [startDate, endDate] = dateRange;

  const title = "Reservation";
  const requestQueryVariables = useMemo(
    () => ({
      view: reservationView,
      startDate,
      endDate,
    }),
    [startDate, endDate, reservationView]
  );
  const skipQueryCondition = !startDate || !endDate;

  // queries
  const {
    error,
    networkStatus,
    data: requestsData,
    refetch: refetchRequests,
    loading: requestsLoading,
    fetchMore: requestsFetchMore,
  } = useQuery<{ loadReservations: RequestsConnection }>(LOAD_RESERVATIONS, {
    variables: {
      limit: 15,
      ...requestQueryVariables,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true, // needed to render loading when fetching more
    skip: skipQueryCondition,
    onCompleted: () => setTimeout(() => setHasInitialized(true), 0),
    onError: () => setTimeout(() => setHasInitialized(true), 0),
  });

  const requests = requestsData?.loadReservations.edges.map(({ node }) => node);

  // event handlers
  const handleSetView = (view: string) => {
    setHasInitialized(false); // set not initialized to enable loading spinner
    setReservationView(view);
  };

  const handleFetchMore = () => {
    requestsFetchMore({
      variables: {
        cursor: requestsData?.loadReservations?.pageInfo.endCursor,
      },
    });
  };

  // effects
  // change queryParam status when user selects a new request status view
  useEffect(() => {
    const queryParam = queryString.parse(location.search);
    const newQueryParams = {
      ...queryParam,
      status: reservationView,
    };
    history.replace({
      search: queryString.stringify(newQueryParams),
    });
  }, [reservationView, location.search, history]);

  if (error) {
    return (
      <Box
        width="100%"
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <GQLQueryStatusIndicator
          name={title}
          error={error}
          data={requestsData}
          loading={requestsLoading}
          networkStatus={networkStatus}
          refetch={refetchRequests}
        />
      </Box>
    );
  }

  const [titleText, copyText] = renderNoReservationsText(
    startDate,
    endDate,
    reservationView
  );

  return (
    <>
      <Helmet>
        <title>Moovs</title>
      </Helmet>
      <Box display="flex" width="100%" data-testid="requests-page">
        {/* Left - Request List */}
        <Box
          width={isMobileView ? "100vw" : "370px"}
          display="flex"
          overflow="hidden"
        >
          <Box
            display="flex"
            flexDirection="column"
            position="fixed"
            height="100%"
            minHeight={`calc(100% - ${isMobileView ? 54 : 78}px)`}
            maxHeight={`calc(100% - ${isMobileView ? 54 : 78}px)`}
            width={isMobileView ? "100vw" : "400px"}
            overflow="auto"
            bgcolor={white}
            borderRight={`1px solid ${grayLight}`}
          >
            <RequestsListHeader
              mode={Reservations}
              statusSelectProps={{
                setStatus: handleSetView,
                selectedStatus: reservationView,
              }}
              dateFilterProps={{
                dateRange,
                setDateRange,
              }}
            />

            <Box
              id="reservationsList"
              borderTop={`1px solid ${grayLight}`}
              borderBottom={`1px solid ${grayLight}`}
              overflow="auto"
              height="100%"
            >
              {/* Do not show loading spinner for polling or pagination, 
              do show when switching views and on initial page load */}
              {requestsLoading && (!size(requests) || !hasInitialized) ? (
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress size={40} thickness={2} />
                </Box>
              ) : requests?.length ? (
                <MoovsInfiniteScroll
                  loading={requestsLoading}
                  data={requestsData?.loadReservations}
                  next={handleFetchMore}
                  name="reservations"
                  scrollableId="reservationsList"
                >
                  {requests.map((request, index) => (
                    <RequestListItem
                      key={index}
                      request={request}
                      mode={Reservations}
                    />
                  ))}
                </MoovsInfiniteScroll>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight={`calc(100% - ${isMobileView ? 54 : 78}px)`}
                  maxHeight={`calc(100% - ${isMobileView ? 54 : 78}px)`}
                  px={2}
                >
                  <EmptyList title={titleText} copy={copyText} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        {!isMobileView && (
          <Box
            height="calc(100vh - 170px)"
            display="flex"
            flex="1"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h5" align="center">
              Select an order to view details.
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
}

export default ReservationsPage;
