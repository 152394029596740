import React from "react";

import {
  Box,
  Card,
  CardActionArea,
  IconButton,
  Typography,
} from "@mui/material";

import MoovsAvatar from "design-system/components/MoovsAvatar";
import { black, moovsBlue } from "design-system/colors";
import { TrashIcon } from "design-system/icons";
import { Contact } from "types";
import { getContactName } from "globals/utils/contact";

type LinkedPassengersListProps = {
  contact: Contact;
  isDeleteButtonDisabled: boolean;
  onDelete: (e: React.MouseEvent<HTMLButtonElement>, contactId: string) => void;
  deleteButtonTitle: string;
};
export default function ContactInfoCard({
  contact,
  isDeleteButtonDisabled,
  onDelete,
  deleteButtonTitle,
}: LinkedPassengersListProps) {
  return (
    <Card variant="outlined" data-testid="contacts-list-items" key={contact.id}>
      <CardActionArea disableRipple>
        <Box
          display="flex"
          flexDirection="row"
          flex="1"
          py={1.5}
          px={3}
          height="88px"
          alignItems="center"
        >
          {/* Contact Name */}
          <Box display="flex" flex="2" alignItems="center">
            <MoovsAvatar
              alt="contact, person"
              size="medium"
              placeholder={[contact.firstName, contact.lastName]}
            />

            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              ml={2}
            >
              <Typography
                sx={{ fontWeight: 700, color: black, fontSize: 16 }}
                data-testid="contacts-list-contact-name"
              >
                {getContactName(contact)}
              </Typography>
            </Box>
          </Box>
          <Box>
            <IconButton
              onClick={(e) => onDelete(e, contact.id)}
              disabled={isDeleteButtonDisabled}
              size="large"
              title={deleteButtonTitle}
            >
              <TrashIcon color={moovsBlue} />
            </IconButton>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}
