import React, { ChangeEvent } from "react";

import { Box, Divider, TextField, Typography } from "@mui/material";

import DriverPayoutSubtotalBox from "../DriverPayoutSubtotalBox";
import { calculateDriverPayoutTotal, isAutomationPayoutUpdated } from "./utils";
import { black, grayDark, grayLight } from "design-system/colors";
import {
  NumberFormatDollar,
  NumberFormatDecimal,
  NumberFormatDisplay,
} from "design-system/components/inputs/NumberFormat";
import { AutomatedPricingIcon, WheelIcon } from "design-system/icons";
import { Route } from "types";
import { useScreenSize } from "globals/hooks";

type DriverPayoutFieldsProps = {
  route: Route;
  driverPayoutFields: {
    driverPayoutFlatRate: number;
    driverPayoutHours: number;
    driverPayoutRatePerHour: number;
    driverPayoutGratuity: number;
  };
  onInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  isPayoutAutomated: boolean;
  isCloseTripVariant?: boolean;
};

function DriverPayoutFields(props: DriverPayoutFieldsProps) {
  const {
    route,
    driverPayoutFields,
    onInputChange,
    isPayoutAutomated,
    isCloseTripVariant,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // payout variables
  const {
    driverPayoutFlatRate,
    driverPayoutHours,
    driverPayoutRatePerHour,
    driverPayoutGratuity,
  } = driverPayoutFields;

  // derived state
  const driverFullName =
    route?.driver && `${route?.driver.firstName} ${route?.driver.lastName}`;

  const doesPayoutMatchAutomation = !isAutomationPayoutUpdated(
    {
      driverPayoutFlatRate,
      driverPayoutHours,
      driverPayoutRatePerHour,
      driverPayoutGratuity,
    },
    {
      ...route,
      driverGratuityAmt:
        route?.pricing?.driverGratuityAmt ||
        route?.farmeePricing?.driverGratuityAmt,
    }
  );
  const showAutomationIcon = isPayoutAutomated && doesPayoutMatchAutomation;

  const [driverPayoutTotal, hourlyRateSubtotal] =
    calculateDriverPayoutTotal(driverPayoutFields);

  // price limit helper function
  const handleIsAllowedValue = (value: number, limit: number) => {
    return value < limit || !value;
  };

  return (
    <>
      <Divider />
      <Box my={3}>
        <Box
          {...(isCloseTripVariant && {
            sx: {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            },
          })}
          mb={2}
        >
          {isCloseTripVariant && (
            <Typography variant="h4">Driver Payout</Typography>
          )}
          <Typography
            variant={isCloseTripVariant ? "subtitle2" : "h4"}
            style={{ color: driverFullName ? black : grayDark }}
          >
            {driverFullName ? (
              <Box display="flex">
                <Box position="relative" top="2px" mr="10px">
                  <WheelIcon size="small" color={black} />
                </Box>
                {driverFullName}
              </Box>
            ) : (
              "No driver assigned"
            )}
          </Typography>
        </Box>
        <Box mb={2}>
          {/* Flat Rate */}
          <Box
            display="flex"
            flexDirection={isMobileView ? "column" : "row"}
            mb={2}
          >
            {isCloseTripVariant && (
              <Box display="flex" alignItems="center">
                <Typography variant="body1" color={grayDark} mr={6} mb={1}>
                  Flat Rate
                </Typography>
              </Box>
            )}

            <Box display="flex" flexDirection="row">
              <Box flex="1">
                <TextField
                  fullWidth
                  variant="outlined"
                  label={isCloseTripVariant ? "Amount" : "Flat Rate"}
                  name="driverPayoutFlatRate"
                  value={driverPayoutFlatRate || ""}
                  onChange={onInputChange}
                  InputProps={{
                    inputComponent: NumberFormatDollar as any,
                    inputProps: {
                      isAllowed: (values) =>
                        handleIsAllowedValue(values.floatValue, 10000),
                      allowLeadingZeros: false,
                    },
                  }}
                />
              </Box>
              <DriverPayoutSubtotalBox
                amount={driverPayoutFlatRate}
                isBold={isCloseTripVariant}
              />
            </Box>
          </Box>

          {/* Hourly Rate */}
          <Box
            display="flex"
            flexDirection={isMobileView ? "column" : "row"}
            mb={2}
          >
            {isCloseTripVariant && (
              <Box display="flex" alignItems="center">
                <Typography
                  minWidth="91px"
                  variant="body1"
                  color={grayDark}
                  mb={1}
                  mr={3.25}
                >
                  Hourly Rate
                </Typography>
              </Box>
            )}

            <Box
              display="flex"
              flexDirection="row"
              mr={!isMobileView && isCloseTripVariant && 1}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                mr={!isMobileView ? (!isCloseTripVariant ? 2.75 : 2) : null}
                flex={isMobileView && 1}
              >
                <Box flex="1">
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Hours"
                    name="driverPayoutHours"
                    value={driverPayoutHours || ""}
                    onChange={onInputChange}
                    InputProps={{
                      inputComponent: NumberFormatDecimal as any,
                      inputProps: {
                        isAllowed: (values) =>
                          handleIsAllowedValue(values.floatValue, 1000),
                        allowLeadingZeros: false,
                      },
                    }}
                  />
                </Box>

                <Box flex="1" ml={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Rate/HR"
                    name="driverPayoutRatePerHour"
                    value={driverPayoutRatePerHour || ""}
                    onChange={onInputChange}
                    InputProps={{
                      inputComponent: NumberFormatDollar as any,
                      inputProps: {
                        isAllowed: (values) =>
                          handleIsAllowedValue(values.floatValue, 10000),
                        allowLeadingZeros: false,
                      },
                    }}
                  />
                </Box>
              </Box>
              <DriverPayoutSubtotalBox
                amount={hourlyRateSubtotal}
                isBold={isCloseTripVariant}
              />
            </Box>
          </Box>

          {/* Gratuity */}
          <Box
            display="flex"
            flexDirection={isMobileView ? "column" : "row"}
            mb={2}
          >
            {isCloseTripVariant && (
              <Box display="flex" alignItems="center">
                <Typography variant="body1" color={grayDark} mr={6.75} mb={1}>
                  Gratuity
                </Typography>
              </Box>
            )}

            <Box display="flex" flexDirection="row">
              <Box flex="1">
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Gratuity"
                  name="driverPayoutGratuity"
                  value={driverPayoutGratuity || ""}
                  onChange={onInputChange}
                  InputProps={{
                    inputComponent: NumberFormatDollar as any,
                    inputProps: {
                      isAllowed: (values) =>
                        handleIsAllowedValue(values.floatValue, 10000),
                      allowLeadingZeros: false,
                    },
                  }}
                />
              </Box>
              <DriverPayoutSubtotalBox
                amount={driverPayoutGratuity}
                isBold={isCloseTripVariant}
              />
            </Box>
          </Box>
        </Box>

        {/* Total */}
        <Box
          bgcolor={grayLight}
          display="flex"
          justifyContent="space-between"
          height="48px"
          alignItems="center"
          px={2}
          borderRadius="4px"
        >
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Total
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              <NumberFormatDisplay value={driverPayoutTotal} />
            </Typography>
            {showAutomationIcon && (
              <Box display="flex" alignItems="center" ml={1}>
                <AutomatedPricingIcon />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default DriverPayoutFields;
