import React, { Dispatch, SetStateAction } from "react";
import isNil from "lodash/isNil";
import filter from "lodash/filter";

import { Box, Button, Divider } from "@mui/material";

import MoovsSwitchDropdown from "components/MoovsSwitchDropdown";
import BaseRatePricingField from "../BaseRatePricingField";
import PriceBreakdownBlock from "../PriceBreakdownBlock";
import { useFieldsRendered, useOperator } from "globals/hooks";
import {
  BaseRateAutomation,
  ChildSeatVariant,
  PricingLayout,
  PromoCode,
  RoutePricing,
  Stop,
  VehicleChildSeat,
} from "types";
import { PricingKeys } from "../utils/types";
import { OptionalPricingFieldsList } from "./components";
import { parsePricingLayoutForInitialRoute } from "components/requests/create/CreateRequest/helpers/parsePricingLayoutForInitialRoute";
import { constructPricingLayoutFields } from "globals/hooks/useFieldsRendered";
import MoovsSearchableDropdownButton, {
  MoovsSearchableDropdownOption,
} from "components/MoovsSearchableDropdownButton";
import { currency } from "globals/utils/helpers";

type MoovsPricingFormProps = {
  pricing: RoutePricing;
  onPricingUpdate: (pricingKeys: PricingKeys) => void;
  BRAProps: {
    hasEnabledVehicleBRA: boolean;
    vehicleId: string;
    stops: Stop[];
    preexistingABR?: BaseRateAutomation;
    preexistingABRLoading?: boolean;
    hideBRA?: boolean;
  };
  totalDurationMinutes?: number;
  baseRateAmtError?: string;
  hasFullWidthSwitchDropdown?: boolean;
  isVehicleBaseRateAutomated?: boolean;
  setIsBaseRateAutomationClicked?: Dispatch<SetStateAction<boolean>>;
  setExisitngAutomatedBaseRate?: Dispatch<SetStateAction<BaseRateAutomation>>;
  isFarmedRoute?: boolean;
  displayUsePricingLayout?: boolean;
  setPricingValues?: React.Dispatch<
    React.SetStateAction<RoutePricing | PricingLayout>
  >;
  dropdownButtonLabel?: string;
  hidePromoCodes?: boolean;
  childSeatOptions?: VehicleChildSeat[];
};

function MoovsPricingForm(props: MoovsPricingFormProps) {
  const {
    pricing,
    baseRateAmtError,
    onPricingUpdate,
    totalDurationMinutes,
    BRAProps,
    hasFullWidthSwitchDropdown,
    isVehicleBaseRateAutomated,
    setIsBaseRateAutomationClicked,
    setExisitngAutomatedBaseRate,
    isFarmedRoute,
    displayUsePricingLayout,
    setPricingValues,
    dropdownButtonLabel = "Add Pricing",
    hidePromoCodes,
    childSeatOptions,
  } = props;
  const [fieldsRendered, setFieldsRendered] = useFieldsRendered({
    pricing,
  });
  const { settings } = useOperator() || {};

  // derived state
  const promoCodeOptions = filter(settings?.promoCodes, "active").map<
    MoovsSearchableDropdownOption<PromoCode>
  >((promoCode) => {
    const { promoCodeName, promoCodeAmt, promoCodePercent } = promoCode;

    const sublabel = !isNil(promoCodeAmt)
      ? currency(promoCodeAmt)
      : `${(promoCodePercent * 100).toFixed(2)}%`;

    return {
      sublabel,
      label: promoCodeName,
      fieldName: !isNil(promoCodeAmt) ? "promoCodeAmt" : "promoCodePercent",
      payload: promoCode,
    };
  });

  const childSeatFieldNameOptions = childSeatOptions?.map((option) => {
    switch (option.type) {
      case ChildSeatVariant.ForwardFacing:
        return "forwardFacingSeatAmt";
      case ChildSeatVariant.RearFacing:
        return "rearFacingSeatAmt";
      case ChildSeatVariant.Booster:
        return "boosterSeatAmt";
      // default case needed, but this should never be reached
      default:
        return "otherSeatAmt";
    }
  });

  // event handlers
  const handleDropdownOptionClick = (
    isChecked: boolean,
    fieldName: string,
    percentFieldName: string
  ) => {
    setFieldsRendered((fieldsRendered) => ({
      ...fieldsRendered,
      [fieldName]: isChecked,
      [percentFieldName]: isChecked,
    }));

    // handle child seat options
    if (
      fieldName === "rearFacingSeatAmt" ||
      fieldName === "forwardFacingSeatAmt" ||
      fieldName === "boosterSeatAmt"
    ) {
      handleChildSeatOptionClick(isChecked, fieldName);
      return;
    }

    if (!isChecked) {
      onPricingUpdate({
        fieldName,
        fieldAmt: null,
        percentFieldName,
        percentFieldValue: null,
      });
    } else {
      onPricingUpdate({
        fieldName,
        fieldAmt:
          fieldName === "meetGreetAmt" &&
          !isNil(settings?.pricingLayout?.meetGreetAmt)
            ? settings?.pricingLayout.meetGreetAmt
            : 0,
      });
    }
  };

  const handleChildSeatOptionClick = (
    isChecked: boolean,
    fieldName: "rearFacingSeatAmt" | "forwardFacingSeatAmt" | "boosterSeatAmt"
  ) => {
    let quanityFieldName: string;
    let fieldAmt: number;

    switch (fieldName) {
      case "rearFacingSeatAmt":
        quanityFieldName = "rearFacingSeatQuantity";
        fieldAmt = childSeatOptions?.find(
          (option) => option.type === ChildSeatVariant.RearFacing
        )?.amt;
        break;
      case "forwardFacingSeatAmt":
        quanityFieldName = "forwardFacingSeatQuantity";
        fieldAmt = childSeatOptions?.find(
          (option) => option.type === ChildSeatVariant.ForwardFacing
        )?.amt;
        break;
      case "boosterSeatAmt":
        quanityFieldName = "boosterSeatQuantity";
        fieldAmt = childSeatOptions?.find(
          (option) => option.type === ChildSeatVariant.Booster
        )?.amt;
        break;
    }

    if (!isChecked) {
      onPricingUpdate({
        fieldName,
        fieldAmt: null,
      });
      onPricingUpdate({
        fieldName: quanityFieldName,
        fieldAmt: null,
      });
    } else {
      onPricingUpdate({
        fieldName,
        fieldAmt,
      });
      onPricingUpdate({
        fieldName: quanityFieldName,
        fieldAmt: 1,
      });
    }
  };

  const handlePromoCodeDropdownOptionClick = (
    option: MoovsSearchableDropdownOption<PromoCode>
  ) => {
    const { fieldName, payload } = option;

    const pricingUpdateParams = {
      promoCodeAmt: {
        fieldName,
        fieldAmt: payload.promoCodeAmt,
        percentFieldName: "promoCodePercent",
        percentFieldValue: null,
      },
      promoCodePercent: {
        percentFieldName: fieldName,
        percentFieldValue: payload.promoCodePercent * 100,
        fieldName: "promoCodeAmt",
        fieldAmt: null,
      },
    }[fieldName];

    setFieldsRendered((fieldsRendered) => ({
      ...fieldsRendered,
      promoCodeAmt: true,
      promoCodePercent: true,
    }));

    onPricingUpdate({
      fieldName: "promoCodeId",
      fieldAmt: payload.id,
    });
    onPricingUpdate({
      fieldName: "promoCodeName",
      fieldAmt: payload.promoCodeName,
    });
    onPricingUpdate(pricingUpdateParams);
  };

  const handleBaseRateAmtChange = (baseRateAmt: number) => {
    onPricingUpdate({ fieldName: "baseRateAmt", fieldAmt: baseRateAmt });
  };
  const handleHourlyBRHoursChange = (hours: number) => {
    onPricingUpdate({ fieldName: "hourlyBaseRateHours", fieldAmt: hours });
  };

  const handlePricingLayoutClick = () => {
    const updatedRoutePricing = {
      ...pricing,
      ...parsePricingLayoutForInitialRoute(settings?.pricingLayout),
    };

    delete updatedRoutePricing.baseRateVariant;

    const pricingFieldsRendered =
      constructPricingLayoutFields(updatedRoutePricing);

    setFieldsRendered(pricingFieldsRendered);

    setPricingValues(updatedRoutePricing);
  };

  return (
    <Box display="flex" flexDirection="column">
      <BaseRatePricingField
        onBaseRateAmtChange={handleBaseRateAmtChange}
        onHourlyBRHoursChange={handleHourlyBRHoursChange}
        baseRateAmtError={baseRateAmtError}
        BRAProps={BRAProps}
        baseRateAmt={pricing.baseRateAmt}
        hourlyBaseRateHours={pricing.hourlyBaseRateHours}
        totalDurationMinutes={totalDurationMinutes}
        isVehicleBaseRateAutomated={
          isVehicleBaseRateAutomated || BRAProps.hasEnabledVehicleBRA
        }
        setIsBaseRateAutomationClicked={setIsBaseRateAutomationClicked}
        setExisitngAutomatedBaseRate={setExisitngAutomatedBaseRate}
        isFarmedRoute={isFarmedRoute}
      />

      <Box
        display="flex"
        flexDirection={hasFullWidthSwitchDropdown ? "column" : "row"}
        gap={1}
      >
        <MoovsSwitchDropdown
          buttonLabel={dropdownButtonLabel}
          isFullWidth={hasFullWidthSwitchDropdown}
          fieldsRendered={fieldsRendered}
          onDropdownOptionClick={handleDropdownOptionClick}
          childSeatFieldNameOptions={childSeatFieldNameOptions}
        />

        {settings?.hasActivePromoCodes && !hidePromoCodes && (
          <MoovsSearchableDropdownButton
            buttonLabel="Add Promo Code"
            isFullWidth={hasFullWidthSwitchDropdown}
            options={promoCodeOptions}
            onDropdownOptionClick={handlePromoCodeDropdownOptionClick}
            disabled={
              fieldsRendered.promoCodeAmt || fieldsRendered.promoCodePercent
            }
          />
        )}

        <Box mt={1}>
          {displayUsePricingLayout && (
            <Button
              fullWidth={hasFullWidthSwitchDropdown}
              onClick={handlePricingLayoutClick}
            >
              Use Pricing Layout
            </Button>
          )}
        </Box>
      </Box>

      <Box my={3}>
        <Divider />
      </Box>

      <OptionalPricingFieldsList
        pricing={pricing}
        baseRateAmt={pricing.baseRateAmt}
        setFieldsRendered={setFieldsRendered}
        onPricingUpdate={onPricingUpdate}
        hidePromoCodes={hidePromoCodes}
      />

      <Box pb={3}>
        <PriceBreakdownBlock pricing={pricing} />
      </Box>
    </Box>
  );
}

export default MoovsPricingForm;
