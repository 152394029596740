import React from "react";

type HamburgerMenuIconProps = {
  color?: string;
};

const HamburgerMenuIcon = (props: HamburgerMenuIconProps) => {
  const { color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 5H3M21 12H3M21 19H3"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HamburgerMenuIcon;
