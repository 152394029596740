import React from "react";

type LinkedCornerLeftIconProps = {
  color?: string;
};

export default function LinkedCornerLeftIcon(props: LinkedCornerLeftIconProps) {
  const { color } = props;
  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1V10C1 12.2091 2.79086 14 5 14H17"
        stroke={color || "#D3D3D3"}
        strokeLinecap="round"
      />
    </svg>
  );
}
