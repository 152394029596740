/**
 * @file VehiclesPage.tsx
 * Lists all vehicles in DB and has button to create a new vehicle.
 *
 * components:
 * VehiclesPage
 *
 */

import React, { useState, ChangeEvent } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import partition from "lodash/partition";
import { useDebounce } from "use-debounce";

import { Box, Paper, Hidden, Typography } from "@mui/material";

import VehiclesList from "components/vehicles/VehiclesList";
import EntityHeader from "components/common/EntityHeader";
import GQLQueryStatusIndicator from "components/GQLQueryStatusIndicator";
import EmptyList from "components/globals/EmptyList";
import MoovsTabs from "components/globals/MoovsTabs";
import UpgradePlanDialog from "components/UpgradePlanDialog";
import { LOAD_VEHICLES_QUERY } from "globals/graphql";
import { useAnalytics, useOperator, useScreenSize } from "globals/hooks";
import { black, grayLight } from "design-system/colors";
import carIllustration from "design-system/illustrations/vehicle.svg";
import theme, { SIDE_NAV_WIDTH } from "theme";
import { PlanVariant } from "types";

export enum VehiclesPageTab {
  MyVehicles,
  ExternalAffiliates,
}

function VehiclesPage() {
  // constants
  const MAX_FREE_PLAN_VEHICLES_COUNT = 10;

  // hooks
  const { isMobileView, isSmallTabletView } = useScreenSize();
  const history = useHistory();
  const { track } = useAnalytics();
  const operator = useOperator();

  const isVehiclesPath = history.location.pathname === "/vehicles";

  // state
  const [globalSearch, setGlobalSearch] = useState("");
  const [debouncedSearchTerm] = useDebounce(globalSearch, 300);
  const [tabMode, setTabMode] = useState<VehiclesPageTab>(
    VehiclesPageTab.MyVehicles
  );
  const [upgradePlanDialogOpen, setUpgradePlanDialogOpen] = useState(false);

  // queries
  const { data, error, refetch, networkStatus, loading } = useQuery(
    LOAD_VEHICLES_QUERY,
    {
      variables: {
        includeExternalOperators: true,
        searchTerm: debouncedSearchTerm,
      },
    }
  );

  // event handlers
  const handleGlobalSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGlobalSearch(event.target.value);
  };

  const handleCreateVehicleClick = () => {
    track("handleCreateVehicleClick");
    history.push("/vehicles/create");
  };

  const handleVehicleSearchFocus = () => {
    track("vehicle_searched");
  };

  const handleUpgradePlanClick = () => {
    track("vehicle_upgradePlan");
    history.push("/settings/billing/plans");
  };

  const handleCreateVehicleClickWithLimitReached = () => {
    track("vehicles_createInitiated", {
      limitRestriction: "limit reached",
    });
    setUpgradePlanDialogOpen(true);
  };

  // free plan can only create 10 vehicles
  const freePlanAndVehicleLimitReached =
    operator?.plan === PlanVariant.Free &&
    data?.loadVehicles?.edges.length >= MAX_FREE_PLAN_VEHICLES_COUNT;

  const vehicles = partition(data?.loadVehicles?.edges, ({ node }) => {
    return !node.externalOperator;
  })[tabMode].map((edge) => edge.node);

  const styles = {
    header: {
      paddingBottom: "12px",
      color: black,
      opacity: 0.6,
    },
    table: {
      maxWidth: "100vw",
      [theme.breakpoints.up("sm")]: {
        maxWidth: `calc(100vw - ${SIDE_NAV_WIDTH})`,
      },
      paddingLeft: theme.spacing(3),
      overflowX: "auto",
      overflowY: "hidden",
      display: "flex",
      flex: 2,
      height: "100%",
    },
    list: {
      minWidth: "500px",
      width: "100%",
      overflowX: "auto",
      overflowY: "hidden",
      height: "100%",
      paddingRight: "24px",
    },
    tabsContainer: {
      maxWidth: "900px",
      margin: "auto",
      padding: `0px ${theme.spacing(2)} 0px ${theme.spacing(2)}`,
      [theme.breakpoints.up("lg")]: {
        padding: `0px ${theme.spacing(2)} 0px ${theme.spacing(3)}`,
        marginBottom: "40px",
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Vehicles | Moovs</title>
      </Helmet>

      <Box justifyContent="center" width="100%">
        <Box
          position="sticky"
          {...(isVehiclesPath && { top: isMobileView ? "56px" : "78px" })}
          {...(!isVehiclesPath && { top: "0px" })}
          zIndex={10}
          width="100%"
        >
          <Paper
            square
            style={{ borderBottom: `1px solid ${grayLight}` }}
            elevation={0}
          >
            <Box data-testid="entity-header">
              {freePlanAndVehicleLimitReached ? (
                <>
                  <EntityHeader
                    title="Vehicles"
                    entityName="Vehicle"
                    onGlobalSearchFocus={handleVehicleSearchFocus}
                    onGlobalSearchChange={handleGlobalSearchChange}
                    globalSearch={globalSearch}
                    createButtonMode="button"
                    onCreateButtonClick={
                      handleCreateVehicleClickWithLimitReached
                    }
                  />
                  <UpgradePlanDialog
                    open={upgradePlanDialogOpen}
                    onUpgrade={handleUpgradePlanClick}
                    onClose={() => setUpgradePlanDialogOpen(false)}
                    body={
                      <Typography variant="body2">
                        You must upgrade your plan in order to{" "}
                        <strong>
                          create more than {MAX_FREE_PLAN_VEHICLES_COUNT}{" "}
                          vehicles
                        </strong>
                        .
                      </Typography>
                    }
                  />
                </>
              ) : (
                <EntityHeader
                  title="Vehicles"
                  entityName="Vehicle"
                  createEntityRoute={"/vehicles/create"}
                  onGlobalSearchFocus={handleVehicleSearchFocus}
                  onGlobalSearchChange={handleGlobalSearchChange}
                  globalSearch={globalSearch}
                  createButtonMode="link"
                  onCreateButtonClick={() =>
                    track("vehicles_createInitiated", {
                      limitRestriction: "limit not reached",
                    })
                  }
                />
              )}

              <Box data-testid="vehicle-type-tabs" sx={styles.tabsContainer}>
                {data && !loading && (
                  <MoovsTabs
                    onChange={(newTabMode) => setTabMode(newTabMode)}
                    value={tabMode}
                    tabs={[
                      {
                        label: "MY VEHICLES",
                        value: VehiclesPageTab.MyVehicles,
                      },
                      {
                        label: "EXTERNAL AFFILIATES",
                        value: VehiclesPageTab.ExternalAffiliates,
                      },
                    ]}
                  />
                )}
              </Box>
            </Box>
            <Hidden mdDown>
              <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                alignItems="center"
                sx={styles.header}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  flex="1"
                  px={6}
                  maxWidth={900}
                  mx="auto"
                >
                  <Box display="flex" flex="4">
                    <Typography variant="overline">Name</Typography>
                  </Box>
                  <Box display="flex" flex="1">
                    <Typography variant="overline">Type</Typography>
                  </Box>
                  <Box display="flex" flex="1">
                    <Typography variant="overline">Plate</Typography>
                  </Box>
                  <Box display="flex" width={65}></Box>
                </Box>
              </Box>
            </Hidden>
          </Paper>
        </Box>

        <Box
          data-testid="vehicles-list-container"
          display="flex"
          flex="1"
          flexDirection="column"
          maxWidth="900px"
          margin="auto"
          sx={{ overflowY: "auto" }}
        >
          {!data && loading ? (
            <GQLQueryStatusIndicator
              name="Vehicle"
              error={error}
              data={data}
              loading={loading}
              networkStatus={networkStatus}
              refetch={refetch}
            />
          ) : vehicles.length > 0 || !!globalSearch ? (
            <Box
              {...(isSmallTabletView && !isMobileView && styles.table)}
              {...(isMobileView && { sx: { flexDirection: "column" } })}
            >
              <VehiclesList
                vehicles={vehicles}
                globalSearch={debouncedSearchTerm}
                listStyles={styles.list}
              />
            </Box>
          ) : (
            // no vehicles
            <Box
              height="calc(100vh - 170px)"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box display="flex">
                {tabMode === VehiclesPageTab.MyVehicles && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <EmptyList
                      title="No Vehicle Added Yet."
                      copy="Add a new vehicle to the list. It's time to get moovin'!"
                      ctaCopy="Create Vehicle"
                      imageUrl={carIllustration as string}
                      onCreateClick={
                        freePlanAndVehicleLimitReached
                          ? handleCreateVehicleClickWithLimitReached
                          : handleCreateVehicleClick
                      }
                    />
                  </Box>
                )}
                {tabMode === VehiclesPageTab.ExternalAffiliates && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <EmptyList
                      title="No External Affiliate Vehicles"
                      copy="Any vehicles linked to an external affiliate will populate here"
                      ctaCopy="Create Vehicle"
                      imageUrl={carIllustration as string}
                      onCreateClick={
                        freePlanAndVehicleLimitReached
                          ? handleCreateVehicleClickWithLimitReached
                          : handleCreateVehicleClick
                      }
                    />
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default VehiclesPage;
