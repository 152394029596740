import React from "react";

import { TableCell, Tooltip } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { currency } from "../../../../globals/utils/helpers";

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 12,
    fontStyle: "normal",
  },
  root: {
    borderBottom: "none",
    padding: "8px",
  },
}))(TableCell);

type IndividualCellType = {
  cell: {
    columnText: string;
    percentage: number;
    amount: number;
  };
};

function IndividualCell(props: IndividualCellType) {
  const { cell } = props;

  const { columnText, amount, percentage } = cell;

  return (
    <>
      <StyledTableCell align="left">{columnText}</StyledTableCell>
      <Tooltip id={columnText} title={currency(amount)}>
        <StyledTableCell align="right" style={{ fontWeight: 600 }}>
          {`${percentage.toFixed(2)}%`}
        </StyledTableCell>
      </Tooltip>
    </>
  );
}

export default IndividualCell;
