import React from "react";

import { Box, Card, CardContent, Link, Typography } from "@mui/material";

import { black, grayDark } from "design-system/colors";
import { InfoIcon, KnowledgeBaseIcon, MessageIcon } from "design-system/icons";
import WatchIcon from "design-system/icons/info/WatchIcon";
import { useAnalytics, useScreenSize } from "globals/hooks";

function NeedHelpSection() {
  // hooks
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();

  return (
    <Box width={isMobileView ? "100%" : "250px"}>
      <Typography variant="h3" mb={3} mt={2}>
        Need help?
      </Typography>
      <Card
        variant="outlined"
        sx={{
          padding: 0,
          width: isMobileView ? "100%" : "250px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
        }}
      >
        <CardContent
          sx={{
            marginLeft: 2,
            marginTop: 1,
          }}
        >
          <Link
            onClick={() => track("gettingStarted_knowledgeOpened")}
            target="_blank"
            underline="none"
            color={black}
            href="https://intercom.help/moovs-05c940f1970e/en/collections/3513392-moovs-software"
          >
            <Box display="flex" alignItems="center" mb={2.5}>
              <KnowledgeBaseIcon size="small" strokeWidth="2.2" />
              <Typography variant="body2" ml={2}>
                Knowledge Base
              </Typography>
            </Box>
          </Link>

          <Link
            onClick={() => track("gettingStarted_demoOpened")}
            target="_blank"
            underline="none"
            color={black}
            href="https://vimeo.com/895889420?share=copy"
          >
            <Box display="flex" alignItems="center" mb={2.5}>
              <WatchIcon />
              <Typography variant="body2" ml={2}>
                Watch Demo
              </Typography>
            </Box>
          </Link>

          <Link
            onClick={() => track("gettingStarted_chatOpened")}
            target="_blank"
            underline="none"
            color={black}
            component="button"
            id="fab-help"
          >
            <Box display="flex" alignItems="center" mb={2.5}>
              <MessageIcon color={grayDark} size="small" />
              <Typography variant="body2" ml={2}>
                Chat with Moovs
              </Typography>
            </Box>
          </Link>

          <Link
            onClick={() => track("gettingStarted_whatsNewOpened")}
            target="_blank"
            underline="none"
            color={black}
            href="https://swoopapp.notion.site/Change-Log-What-s-New-e086bd2f2ece4fdb9bea664956292ef8"
          >
            <Box display="flex" alignItems="center">
              <InfoIcon size="small" />
              <Typography variant="body2" ml={2}>
                What's New
              </Typography>
            </Box>
          </Link>
        </CardContent>
      </Card>
    </Box>
  );
}

export default NeedHelpSection;
