import { gql } from "@apollo/client";

export const SEND_DRIVER_ASSIGN_NOTIFICATION = gql`
  mutation SendDriverAssignTextOrNotificationV2(
    $input: SendDriverAssignTextOrNotificationV2Input!
  ) {
    sendDriverAssignTextOrNotificationV2(input: $input) {
      result {
        __typename
        ... on SendDriverAssignTextOrNotificationFailure {
          message
        }
      }
    }
  }
`;
