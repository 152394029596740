import React, { useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import partition from "lodash/partition";

import { Box, Typography } from "@mui/material";

import { errorRed } from "../../../../design-system/colors";
import { BankAccount } from "types";
import BankAccountCard from "./BankAccountCard";

type BankAccountsListProps = {
  bankAccounts: BankAccount[];
  error: string;
};

function BankAccountsList(props: BankAccountsListProps) {
  const { bankAccounts, error } = props;

  const [[defaultBankAccount], nonDefaultBankAccounts] = useMemo(
    () => partition(bankAccounts, "isDefault"),
    [bankAccounts]
  );

  const isShowBankAccountCards = !isEmpty(bankAccounts);

  return (
    <Box
      sx={{
        margin: "24px 0",
        maxWidth: "500px",
      }}
    >
      {error && (
        <Typography style={{ color: errorRed }}>
          Error retrieving bank account details.
        </Typography>
      )}

      {isShowBankAccountCards && (
        <>
          {/* Default Bank Account card*/}
          <BankAccountCard account={defaultBankAccount} />

          {/* Rest of the Bank Account cards */}
          {nonDefaultBankAccounts &&
            nonDefaultBankAccounts.map((account) => {
              return <BankAccountCard account={account} key={account.id} />;
            })}
        </>
      )}
    </Box>
  );
}

export default BankAccountsList;
