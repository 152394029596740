import React from "react";
import isNil from "lodash/isNil";

import MoovsPricingDialog from "components/pricing/MoovsPricingDialog";
import { PricingDialogPricingData } from "components/pricing/utils";
import { useSnackbar } from "globals/hooks";
import { useMutation } from "@apollo/client";
import { UPDATE_FARMED_ROUTE_PRICING_MUTATION } from "globals/graphql";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { UpdateFarmedRoutePricingInput } from "types";

type FarmeePricingDialogProps = {
  open: boolean;
  onClose: () => void;
  pricings: PricingDialogPricingData[];
  currentTripIndex: number;
  routeId: string;
  hidePromoCodes?: boolean;
};

function FarmeePricingDialog(props: FarmeePricingDialogProps) {
  const { open, onClose, pricings, currentTripIndex, routeId, hidePromoCodes } =
    props;

  // hooks
  const snackbar = useSnackbar();

  // mutations
  const [
    updateFarmedRoutePricingAndCloseDialog,
    { loading: farmedRoutePricingUpdating },
  ] = useMutation(UPDATE_FARMED_ROUTE_PRICING_MUTATION, {
    refetchQueries: ["Request"],
    onCompleted() {
      snackbar.success("Price succesfully updated!");
      onClose();
    },
    onError(error) {
      snackbar.error("Error updating price.");
    },
  });

  const [
    updateFarmedRoutePricingWithoutClosingDialog,
    { loading: farmedRoutePricingUpdatingWCD },
  ] = useMutation(UPDATE_FARMED_ROUTE_PRICING_MUTATION, {
    refetchQueries: ["Request"],
    onCompleted() {
      snackbar.success("Price succesfully updated!");
    },
    onError(error) {
      const errorMessage = getErrorMessage(error) || "Error updating price.";
      snackbar.error(errorMessage);
    },
  });

  // event handlers
  const handleUpdateFarmedRoutePricing = (
    farmeePricing: Omit<
      UpdateFarmedRoutePricingInput,
      | "routeId"
      | "driverGratuityIsPct"
      | "promoDiscountIsPct"
      | "taxIsPct"
      | "otherIsPct"
      | "other2IsPct"
      | "other3IsPct"
    >,
    tripIndex: number,
    closeDialogOnComplete: boolean
  ) => {
    const {
      driverGratuityAmt,
      driverGratuityPercent,
      promoDiscountAmt,
      promoDiscountPercent,
      taxAmt,
      taxPercent,
      otherAmt,
      otherPercent,
      other2Amt,
      other2Percent,
      other3Amt,
      other3Percent,
      promoCodeAmt,
      promoCodePercent,
    } = farmeePricing;

    const updatedFarmedRoutePricingInput: UpdateFarmedRoutePricingInput = {
      routeId,
      ...farmeePricing,
      driverGratuityAmt: driverGratuityPercent ? null : driverGratuityAmt,
      driverGratuityPercent:
        driverGratuityPercent !== null ? driverGratuityPercent / 100 : null,
      taxAmt: taxPercent ? null : taxAmt,
      taxPercent: taxPercent !== null ? taxPercent / 100 : null,
      promoDiscountAmt: promoDiscountPercent ? null : promoDiscountAmt,
      promoDiscountPercent:
        promoDiscountPercent !== null ? promoDiscountPercent / 100 : null,
      otherAmt: otherPercent ? null : otherAmt,
      otherPercent: otherPercent !== null ? otherPercent / 100 : null,
      other2Amt: other2Percent ? null : other2Amt,
      other2Percent: other2Percent !== null ? other2Percent / 100 : null,
      other3Amt: other3Percent ? null : other3Amt,
      other3Percent: other3Percent !== null ? other3Percent / 100 : null,
      promoCodeAmt: !isNil(promoCodePercent) ? null : promoCodeAmt,
      promoCodePercent:
        promoCodePercent !== null ? promoCodePercent / 100 : null,
    };

    const mutationInput = {
      variables: {
        input: updatedFarmedRoutePricingInput,
      },
    };

    if (closeDialogOnComplete) {
      updateFarmedRoutePricingAndCloseDialog(mutationInput);
    } else {
      updateFarmedRoutePricingWithoutClosingDialog(mutationInput);
    }
  };

  return (
    <MoovsPricingDialog
      open={open}
      onClose={onClose}
      pricings={pricings}
      currentTripIndex={currentTripIndex}
      onAccept={handleUpdateFarmedRoutePricing}
      isUpdating={farmedRoutePricingUpdating || farmedRoutePricingUpdatingWCD}
      // no sendPricingAsDollarAmt prop b/c when isPct flag on, updateFarmedRoute mutation converts amt to percent
      isFarmedRoute
      hidePromoCodes={hidePromoCodes}
    />
  );
}

export default FarmeePricingDialog;
