import React from "react";

import { Box, Button, Typography } from "@mui/material";

import { moovsBlue, moovsBlueSelected } from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import { CheckIcon, OpenIcon } from "design-system/icons";

type InvoiceBannerBlockProps = {
  invoiceId: string;
};

function InvoiceBannerBlock(props: InvoiceBannerBlockProps) {
  const { invoiceId } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // handler
  const handleViewInvoiceClick = () => {
    window.open(`/invoices/update/${invoiceId}`, "_blank");
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection={isMobileView ? "column" : "row"}
      borderRadius={1}
      p={2}
      mb={1}
      height={isMobileView ? "222px" : "94px"}
      bgcolor={moovsBlueSelected}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="38px"
        width="38px"
        borderRadius="50%"
        border="2px solid"
        borderColor={moovsBlue}
        {...(isMobileView ? { m: 2 } : { mx: 2 })}
      >
        <CheckIcon color={moovsBlue} />
      </Box>

      <Box {...(isMobileView ? { my: 1 } : { mx: 1 })}>
        <Typography variant="h4">Payment has been paid from invoice</Typography>
      </Box>

      <Box
        justifyItems="flex-end"
        margin="auto"
        {...(isMobileView ? { mb: 0 } : { mr: 0 })}
      >
        <Button color="primary" onClick={handleViewInvoiceClick}>
          <Box display="flex" alignItems="center">
            <Box mr={1}>View Invoice</Box>
            <OpenIcon size="small" color={moovsBlue} />
          </Box>
        </Button>
      </Box>
    </Box>
  );
}

export default InvoiceBannerBlock;
