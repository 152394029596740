import React from "react";

import { Box, Button } from "@mui/material";

import { moovsBlueSelected } from "../../../design-system/colors";
import { PlusIcon } from "../../../design-system/icons";
import { primaryMainColor } from "../../../theme";

type AddStopButtonProps = {
  onClick: () => void;
};

function AddStopButton(props: AddStopButtonProps) {
  const { onClick } = props;

  return (
    <Box
      mt={1}
      mb={3}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box height="1px" width="100%" bgcolor={moovsBlueSelected} />
      <Box
        width="100%"
        display="flex"
        minWidth="120px"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          color="primary"
          onClick={onClick}
          size="large"
          startIcon={<PlusIcon color={primaryMainColor} />}
        >
          Add Stop
        </Button>
      </Box>
      <Box height="1px" width="100%" bgcolor={moovsBlueSelected} />
    </Box>
  );
}

export default AddStopButton;
