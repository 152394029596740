import React from "react";

import { Typography, Box } from "@mui/material";

import { black } from "../design-system/colors";
import { daysOfTheWeekDatesTimesParser } from "pages/requests/utils/daysOfTheWeekDatesTimesParser";

type MultiLineDateTimeProps = {
  dateTime: Date;
  endDateTime?: Date;
  icon?: React.ReactNode;
};
function MultiLineDateTime(props: MultiLineDateTimeProps) {
  const { dateTime, endDateTime, icon } = props;

  const { daysOfTheWeek, dates, times } = daysOfTheWeekDatesTimesParser({
    dateTime,
    endDateTime,
  });

  return (
    <Box display="flex" flexDirection="column" flex="1">
      <Typography variant="caption" style={{ fontWeight: 500, color: black }}>
        {daysOfTheWeek},
      </Typography>
      <Typography variant="caption" style={{ fontWeight: 500, color: black }}>
        {dates},
      </Typography>
      <Box display="flex">
        <Typography variant="caption" style={{ fontWeight: 500, color: black }}>
          {times}
        </Typography>
        <Box ml={0.8}>{icon}</Box>
      </Box>
    </Box>
  );
}

export default MultiLineDateTime;
