import React from "react";
import moment from "moment";

import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

function BookingDateColumn(props: GridRenderCellParams<string>) {
  const { row: trip } = props;

  const bookingDate = moment.utc(trip.createdAt).format("M/DD/YY");

  return (
    <Typography variant="body2" pl={1}>
      {bookingDate}
    </Typography>
  );
}

export default BookingDateColumn;
