import capitalize from "lodash/capitalize";
import size from "lodash/size";
import moment, { isMoment, Moment } from "moment";

type GetDefaultRepeatLabelProps = {
  repeatAnnually: boolean;
  startDate: Date;
  endDate: Date;
  startTime: Moment;
  endTime: Moment;
  daysOfWeek: { id: string; value: string; isSelected: boolean }[];
};

export const getDefaultRepeatLabel = (props: GetDefaultRepeatLabelProps) => {
  const { repeatAnnually, startDate, startTime, endTime } = props;

  const repeatText = getRepeatOptionText(props);

  const noRepeatOption = {
    id: "Does Not Repeat",
    value: false,
    label: "Does Not Repeat",
  };

  const repeatOption = {
    id: repeatText,
    value: true,
    label: repeatText,
  };

  const options = [noRepeatOption, repeatOption];

  // Disabled Options
  // no date selected, repeat rule is disabled
  if (!startDate) {
    return {
      options,
      disabled: true,
      defaultLabel: noRepeatOption.label, // label text will be "Does Not Repeat"
    };
  }

  // A minimum of 1 date is selected, but time range is not complete
  if (
    startDate &&
    ((startTime?.isValid() && !endTime?.isValid()) ||
      (endTime?.isValid() && !startTime?.isValid()))
  ) {
    return {
      options,
      disabled: true,
      defaultLabel: repeatOption.label, // label text will be "Does Not Repeat"
    };
  }

  //  Enabled Options
  // a minimum of 1 date is selected and rule does not repeat
  if (startDate && !repeatAnnually) {
    return {
      options,
      disabled: false,
      defaultLabel: noRepeatOption.label,
    };
  }

  // a minimum of 1 date is selected and rule repeats
  if (startDate && repeatAnnually) {
    return {
      options,
      disabled: false,
      defaultLabel: repeatOption.label,
    };
  }
};

const getRepeatOptionText = (props: GetDefaultRepeatLabelProps) => {
  const { startDate, endDate, startTime, endTime, daysOfWeek } = props;

  const formattedStartDate = isMoment(startDate)
    ? moment(startDate).format("MMMM DD")
    : moment.utc(startDate).format("MMMM DD");

  const formattedEndDate = isMoment(endDate)
    ? moment(endDate).format("MMMM DD")
    : moment.utc(endDate).format("MMMM DD");

  const formattedStartTime = moment(startTime).format("h:mm A");
  const formattedEndTime = moment(endTime).format("h:mm A");

  const weekdays = daysOfWeek
    .filter((day) => day.isSelected)
    .map((day) => capitalize(day.value));

  const weekdayPhrase =
    size(weekdays) === 0
      ? ""
      : size(weekdays) === 7
      ? "everyday"
      : `on ${weekdays.join(", ")}`;

  // start date only
  if (startDate && !endDate && !startTime?.isValid() && !endTime?.isValid()) {
    return `Repeats annually on ${formattedStartDate}`;
  }

  // start date only + time range
  if (startDate && !endDate && startTime?.isValid() && endTime?.isValid()) {
    return `Repeats annually on ${formattedStartDate} from ${formattedStartTime} to ${formattedEndTime}`;
  }

  // date range + weekdays
  if (
    startDate &&
    endDate &&
    !startTime?.isValid() &&
    !endTime?.isValid() &&
    size(daysOfWeek)
  ) {
    return `Repeats annually from ${formattedStartDate} to ${formattedEndDate} ${weekdayPhrase}`;
  }

  // date range + weekdays + time range
  if (
    startDate &&
    endDate &&
    startTime?.isValid() &&
    endTime?.isValid() &&
    size(daysOfWeek)
  ) {
    return `Repeats annually from ${formattedStartDate} to ${formattedEndDate} ${weekdayPhrase} from ${formattedStartTime} to ${formattedEndTime}`;
  }

  return "Does Not Repeat";
};
