import { Vehicle, VehicleChildSeat } from "types";

export const getChildSeatOptionsFromVehicle = (
  vehicle?: Vehicle | Partial<Vehicle>
) => {
  let options: VehicleChildSeat[] = [];

  if (!vehicle?.settings) return options;

  const { forwardFacingSeat, rearFacingSeat, boosterSeat } = vehicle.settings;

  if (rearFacingSeat.active) options.push(rearFacingSeat);

  if (forwardFacingSeat.active) options.push(forwardFacingSeat);

  if (boosterSeat.active) options.push(boosterSeat);

  return options;
};
