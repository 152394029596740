import React, { Dispatch, SetStateAction } from "react";

import {
  FarmAffiliateVariantEnum,
  GriddnetOperator,
  ExternalOperator,
  MoovsAiOperator,
  MoovsNetworkOperator,
} from "../../../../../types";
import ExternalOperatorInfo from "./ExternalOperatorInfo";
import GriddnetAndMoovsNetworkOperatorInfo from "./GriddnetAndMoovsNetworkOperatorInfo";

type FarmAffiliateInfoBlockProps = {
  farmAffiliate:
    | ExternalOperator
    | GriddnetOperator
    | MoovsNetworkOperator
    | MoovsAiOperator;
  farmAffiliateVariant: FarmAffiliateVariantEnum;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function FarmAffiliateInfoBlock(props: FarmAffiliateInfoBlockProps) {
  const { farmAffiliate, farmAffiliateVariant, setSaveIndicatorState } = props;

  return (
    <>
      {farmAffiliateVariant === FarmAffiliateVariantEnum.ExternalOperator ? (
        <ExternalOperatorInfo
          externalOperator={farmAffiliate as ExternalOperator}
          setSaveIndicatorState={setSaveIndicatorState}
        />
      ) : (
        <GriddnetAndMoovsNetworkOperatorInfo
          farmAffiliate={
            farmAffiliate as
              | GriddnetOperator
              | MoovsNetworkOperator
              | MoovsAiOperator
          }
        />
      )}
    </>
  );
}

export default FarmAffiliateInfoBlock;
