import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Control,
  FieldErrors,
  useForm,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import { Box, Typography } from "@mui/material";

import { dateRangeDefaultValues } from "./defaultValues";
import { dateRangeSchema } from "./schemaValidation";
import { CalendarIcon } from "design-system/icons";
import ArrowDownIcon from "design-system/icons/layout/ArrowDownIcon";
import { useScreenSize } from "globals/hooks";
import RHFDatePicker from "components/reactHookFormInputs/RHFDatePicker";
import RHFMultipleSelect from "components/reactHookFormInputs/RHFMultipleSelect";
import { WeekdaysEnum } from "../../utils/types";

type DateRangeValues = {
  startDate: Date;
  endDate: Date;
  daysOfWeek: { id: WeekdaysEnum; value: WeekdaysEnum; isSelected: boolean }[];
};

type DateRangeFormProps = {
  control: Control<DateRangeValues>;
  errors: FieldErrors;
  onSetValue: UseFormSetValue<DateRangeValues>;
  onClearErrors: UseFormClearErrors<DateRangeValues>;
  watch: UseFormWatch<DateRangeValues>;
};

function DateRangeForm(props: DateRangeFormProps) {
  const { control, errors, onSetValue, onClearErrors, watch } = props;

  // hooks
  const { isSmallTabletView } = useScreenSize();

  // options is a list of objects for RHFMultipleSelect
  const options = dateRangeDefaultValues.daysOfWeek.map((weekday) => ({
    id: weekday.id,
    value: weekday.value,
    isSelected: weekday.isSelected,
  }));

  const updateReactHookForm = (newValues) => {
    const rhfValues = newValues.map((val) => ({
      id: val.id,
      value: val.value,
      isSelected: val.isSelected,
    }));
    onSetValue("daysOfWeek", rhfValues);
    onClearErrors("daysOfWeek");
  };

  return (
    <>
      <Box style={{ marginBottom: "30px" }}>
        <Typography variant="body1">Choose a date range</Typography>
      </Box>
      <Box
        display="flex"
        flexDirection={isSmallTabletView ? "column" : "row"}
        justifyContent="space-between"
      >
        <Box mb={3} width="100%">
          <RHFDatePicker
            control={control}
            name="startDate"
            errorMessage={errors.startDate?.message}
            textFieldPlaceholder="Start Date"
            datePickerInputFormat="MM/DD/YYYY"
            datePickerLabel="Start Date"
            startAdornment={<CalendarIcon />}
            endAdornment={<ArrowDownIcon />}
          />
        </Box>
        <Box m={1} />
        <Box mb={3} width="100%">
          <RHFDatePicker
            control={control}
            name="endDate"
            errorMessage={errors.endDate?.message}
            textFieldPlaceholder="End Date"
            datePickerInputFormat="MM/DD/YYYY"
            datePickerLabel="End Date"
            startAdornment={<CalendarIcon />}
            endAdornment={<ArrowDownIcon />}
          />
        </Box>
      </Box>
      <Box style={{ marginBottom: "40px", marginTop: "30px" }}>
        <Typography variant="body1">Choose days of week </Typography>
      </Box>
      <Box>
        <RHFMultipleSelect
          name="daysOfWeek"
          errorMessage={errors.daysOfWeek?.message}
          options={options}
          labelId="weekday-select"
          label="Day of Week"
          selectAllValue="Everyday"
          onUpdateRHF={updateReactHookForm}
          watch={watch}
        />
      </Box>
    </>
  );
}

function useDateRangeForm() {
  // setup react hook forms
  const {
    control,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
    handleSubmit: handleDateRangeSubmit,
    reset: resetDateRangeForm,
  } = useForm({
    defaultValues: dateRangeDefaultValues,
    resolver: yupResolver(dateRangeSchema),
  });

  return {
    handleDateRangeSubmit,
    resetDateRangeForm,
    dateRangeFormComponent: (
      <DateRangeForm
        control={control}
        errors={errors}
        onSetValue={setValue}
        onClearErrors={clearErrors}
        watch={watch}
      />
    ),
  };
}

export default useDateRangeForm;
