import { useQuery } from "@apollo/client";
import React from "react";
import moment from "moment";

import {
  Box,
  Drawer,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";

import { LOAD_STRIPE_TRANSACTIONS_QUERY } from "globals/graphql";
import {
  granite,
  grayDark,
  grayMedium,
  grayLight,
  successGreen,
  tintGreen,
  moovsBlue,
  moovsBlueSelected,
} from "design-system/colors";
import { ArrowLeftIcon } from "design-system/icons";
import { StripePayout, StripeTransactionsEdge } from "types";
import RoundChip from "design-system/components/chips/RoundChip";
import EmptyList from "../../../../../../../../../../../globals/EmptyList";
import { TransactionsListItemMobile } from "./components";
import { currency } from "globals/utils/helpers";
import GQLQueryStatusIndicator from "../../../../../../../../../../../GQLQueryStatusIndicator";
import theme from "theme";

const styles = {
  drawer: {
    height: "100vh",
    width: "100vw",
    overflow: "hidden",
    margin: 0,
    padding: "16px 16px 0px 16px",
  },
  drawerHeader: {
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.down("lg")]: {
      borderBottom: "none",
    },
    "& .MuiIconButton-colorPrimary": { color: granite },
  },
  drawerBody: {
    justifyContent: "space-between",
  },
};

type PayoutDetailsDrawerMobileProps = {
  open: boolean;
  stripePayout: StripePayout;
  onBackClick: () => void;
};

function PayoutDetailsDrawerMobile(props: PayoutDetailsDrawerMobileProps) {
  const { open, stripePayout, onBackClick } = props;
  const { stripePayoutId, payoutDate, status, variant } = stripePayout;

  const shouldSkipQuery = !stripePayoutId || !open || variant === "instant";

  // queries
  const {
    data: stripeTransactionsData,
    error: stripeTransactionsError,
    loading: stripeTransactionsLoading,
  } = useQuery(LOAD_STRIPE_TRANSACTIONS_QUERY, {
    variables: {
      stripePayoutId: stripePayoutId,
    },
    fetchPolicy: "network-only",
    skip: shouldSkipQuery,
  });

  if (stripeTransactionsError) {
    return (
      <GQLQueryStatusIndicator
        error={stripeTransactionsError}
        name="Payout details"
      />
    );
  }

  const transactionEdges = stripeTransactionsData?.stripeTransactions.edges;

  const totalNetTotal = transactionEdges?.reduce(
    (netTotal, transactionEdge) => {
      return netTotal + transactionEdge.node.netTotal;
    },
    0
  );

  return (
    <Drawer anchor="right" open={open}>
      <Box sx={styles.drawer} display="flex" flexDirection="column" flex="1">
        <Box>
          <Box
            mb={2}
            sx={styles.drawerHeader}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            display="flex"
          >
            <IconButton color="primary" onClick={onBackClick} size="large">
              <ArrowLeftIcon color={granite} />
            </IconButton>
            <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
              {moment(payoutDate).format("MM/DD/YY")}
            </Typography>
            <Box display="flex" flex="1" justifyContent="flex-end">
              <RoundChip
                label={
                  {
                    paid: "PAID",
                    in_transit: "IN TRANSIT",
                  }[status] || "N/A"
                }
                color={
                  {
                    paid: successGreen,
                    in_transit: moovsBlue,
                  }[status] || grayDark
                }
                backgroundColor={
                  {
                    paid: tintGreen,
                    in_transit: moovsBlueSelected,
                  }[status] || grayLight
                }
              />
            </Box>
          </Box>
        </Box>
        <Box
          flex="1"
          sx={[
            {
              overflow: "auto",
              display: "flex",
              flex: 1,
              flexDirection: "column",
            },
            styles.drawerBody,
          ]}
        >
          <Box display="flex" flex="1" flexDirection="column" px={2}>
            {stripeTransactionsLoading && (
              <Box
                height="calc(100vh - 170px)"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            )}
            {transactionEdges?.length > 0 ? (
              <>
                {transactionEdges.map(
                  (transactionEdge: StripeTransactionsEdge, i) => {
                    return (
                      <TransactionsListItemMobile
                        transactionEdge={transactionEdge}
                        key={i}
                      />
                    );
                  }
                )}
              </>
            ) : (
              <Box
                height="calc(100vh - 170px)"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box display="flex">
                  {!stripeTransactionsLoading && (
                    <EmptyList
                      title="No payout details available"
                      copy="No payout details available"
                    />
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box borderTop={`1px solid ${grayMedium}`} mx={-2}>
          <Box
            height="68px"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            display="flex"
            mx={3}
          >
            <Typography variant="body2">Total</Typography>
            <Typography variant="body2" style={{ fontWeight: 700 }}>
              {currency(totalNetTotal)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}

export default PayoutDetailsDrawerMobile;
