import React from "react";
import moment from "moment-timezone";

import { Divider } from "@mui/material";

import { grayDark } from "../../design-system/colors";
import {
  CalendarIcon,
  PassengerCountIcon,
  NoteIcon,
} from "../../design-system/icons";
import MoovsIconPanel from "../MoovsIconPanel";

type AdditionalStopInfoButtonPanelProps = {
  stopLabel: string;
  dateTime: string;
  groupSize: number;
  note: string;
  onEditAdditionalStopInfoClick: () => void;
  editDateTimeDisabled?: boolean;
};

function AdditionalStopInfoButtonPanel(
  props: AdditionalStopInfoButtonPanelProps
) {
  const {
    stopLabel,
    dateTime,
    groupSize,
    note,
    onEditAdditionalStopInfoClick,
    editDateTimeDisabled,
  } = props;

  // if no additional info fields show nothing
  if (!dateTime && !note && !groupSize) {
    return null;
  }

  return (
    <>
      <MoovsIconPanel
        bottomDrawerHeader={`Additional ${stopLabel} Info`}
        onEditClick={onEditAdditionalStopInfoClick}
        items={[
          {
            icon: <CalendarIcon color={grayDark} />,
            renderItem: !!dateTime,
            mainText: `${stopLabel} Date & Time`,
            subText: moment.utc(dateTime).format("M/D/YYYY h:mm a"),
          },
          {
            icon: <PassengerCountIcon color={grayDark} />,
            renderItem: groupSize >= 0 && groupSize !== null,
            mainText: "Passenger Count",
            subText: groupSize?.toString(),
          },
          {
            icon: <NoteIcon color={grayDark} />,
            renderItem: !!note,
            mainText: `${stopLabel} Note`,
            subText: note,
          },
        ]}
      />
      {!editDateTimeDisabled && (
        <Divider
          flexItem
          orientation="vertical"
          style={{ marginLeft: "16px", marginRight: "16px" }}
        />
      )}
    </>
  );
}

export default AdditionalStopInfoButtonPanel;
