import { granite, moovsBlue } from "design-system/colors";

export const hubSpotFleetSizeOptions = [
  {
    label: `I'm a broker`,
    value: "0 / Broker",
  },
  {
    label: "1 / individual operator",
    value: "1",
  },
  {
    label: "2-4",
    value: "2-4",
  },
  {
    label: "5-10",
    value: "5-10",
  },
  {
    label: "11-20",
    value: "11-20",
  },
  {
    label: "21-30",
    value: "21-30",
  },
  {
    label: "31+",
    value: "31+",
  },
];

export const internationalPhoneStyle = {
  width: "100%",
  "& .react-tel-input": {
    "&:focus-within": {
      "& .special-label": {
        color: moovsBlue,
        display: "block",
      },
    },
    "& .special-label": {
      color: granite,
      display: "none",
      marginLeft: -2,
    },
    "& .invalid-number-message": {
      marginLeft: -2,
    },
    "& .form-control": {
      width: "100%",
      "&:focus": {
        border: `2px solid ${moovsBlue} !important`,
        boxShadow: "none !important",
      },
    },
  },
};
