import React from "react";

import { Box, Card, CardContent, Typography } from "@mui/material";

import { errorRed, grayLight, moovsBlue } from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import { WorkflowSelectionsEnum } from "../schemaValidation";

type WorkflowOptionProps = {
  image: string;
  header: string;
  bodyText: string;
  onClick: (event) => void;
  workflowKey: WorkflowSelectionsEnum;
  error: boolean;
  workflowOutline: WorkflowSelectionsEnum;
};

const mobileStyles = {
  width: "100%",
  cursor: "pointer",
  marginBottom: "10px",
};

const desktopStyles = {
  height: "285px",
  width: "31%",
  cursor: "pointer",
  "&:hover": {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
  },
};

function WorkflowOption(props: WorkflowOptionProps) {
  const {
    image,
    header,
    bodyText,
    onClick,
    workflowKey,
    error,
    workflowOutline,
  } = props;

  const { isSmallTabletView } = useScreenSize();

  return (
    <Box
      onClick={onClick}
      data-workflow-key={workflowKey}
      sx={isSmallTabletView ? mobileStyles : desktopStyles}
    >
      <Card
        variant="outlined"
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          borderColor:
            workflowOutline === workflowKey
              ? moovsBlue
              : error
              ? errorRed
              : grayLight,
        }}
      >
        <CardContent>
          <Box p={isSmallTabletView ? 0.5 : 1}>
            <img src={image} alt="date-option"></img>
          </Box>
          <Box p={isSmallTabletView ? 0.5 : 1}>
            <Typography variant="h4">{header}</Typography>
          </Box>
          <Box p={isSmallTabletView ? 0.5 : 1}>
            <Typography variant="body2">{bodyText}</Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default WorkflowOption;
