import React, { useState } from "react";
import { Badge, Box, Button, Typography, badgeClasses } from "@mui/material";

import {
  QuoteStatusViewsEnum,
  quoteStatusViews,
  requestClosedStatusViews,
} from "../../globals/utils/views";
import {
  granite,
  moovsBlue,
  moovsBlueSelected,
} from "../../design-system/colors";
import { useAnalytics } from "globals/hooks";
import { LoadQuotesConnection, ReservationView } from "types";
import { LOAD_NEW_QUOTES } from "globals/graphql";
import { useQuery } from "@apollo/client";

const styles = {
  selectedButton: {
    "&:hover": {
      backgroundColor: moovsBlueSelected,
    },
    backgroundColor: moovsBlueSelected,
  },
};

type StatusSelectType = {
  setStatus: (statusValue: string) => void;
  selectedStatus: string | string[];
  mode?: "reservations" | "quotes";
};

function StatusSelect(props: StatusSelectType) {
  const { selectedStatus, setStatus, mode } = props;

  // state
  // used for optimistic render after status button clicks
  const [clicked, setClicked] = useState(selectedStatus);

  const { data: quotes } = useQuery<{ loadNewQuotes: LoadQuotesConnection }>(
    LOAD_NEW_QUOTES,
    {
      skip: mode === "reservations",
      pollInterval: 60 * 1000, // 1 minute
    }
  );

  const newQuotesCount = quotes?.loadNewQuotes.totalCount || 0;

  // hooks
  const { track } = useAnalytics();

  const handleStatusSelect = (statusValue: ReservationView) => {
    setClicked(statusValue);
    setStatus(statusValue);

    if (statusValue === ReservationView.NotPaid) {
      track("reservations_notPaidSelected");
    }
  };

  const statusViews =
    mode === "quotes" ? quoteStatusViews : requestClosedStatusViews;

  return (
    <Box display="flex" justifyContent="space-around" px={2}>
      {statusViews.map((status, i) => {
        const isSelected = status.value === selectedStatus;
        const isClicked = status.value === clicked;

        const showBgColor = isClicked || (isSelected && isClicked);
        const isNewQuotesCount = status.value === QuoteStatusViewsEnum.NEW;
        const isReservationsMode = mode === "reservations";
        const hasNoNewQuotes = newQuotesCount === 0;
        const shouldHideBadge =
          isReservationsMode || hasNoNewQuotes || !isNewQuotesCount;

        return (
          <Badge
            key={i}
            color={"error"}
            variant="dot"
            invisible={shouldHideBadge}
            sx={{
              [`& .${badgeClasses.dot}`]: {
                width: "12px",
                minWidth: "12px",
                height: "12px",
                right: 8,
                top: 0,
                borderRadius: "50%",
              },
            }}
          >
            <Button
              key={i}
              disableRipple
              onClick={() =>
                handleStatusSelect(status.value as ReservationView)
              }
              {...(showBgColor && { sx: styles.selectedButton })}
              {...(mode === "reservations" && { style: { borderRadius: 50 } })}
            >
              <Typography
                variant="overline"
                style={{ color: showBgColor ? moovsBlue : granite }}
              >
                {status.menuLabel}
              </Typography>
            </Button>
          </Badge>
        );
      })}
    </Box>
  );
}

export default StatusSelect;
