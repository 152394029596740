import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Control, FieldErrors, useForm, UseFormWatch } from "react-hook-form";

import { Box, Typography } from "@mui/material";

import { adjustAmtDefaultValues } from "./defaultValues";
import { adjustAmtSchema } from "./schemaValidation";
import RHFRadioButtons from "components/reactHookFormInputs/RHFRadioButtons";
import RHFDollarPctAmt from "components/reactHookFormInputs/RHFDollarPctAmt";

export type AdjustAmtValues = {
  isIncrease: boolean;
  isPercent: boolean;
  amount: number;
};

type DateRangeProps = {
  control: Control<AdjustAmtValues>;
  errors: FieldErrors;
  watch: UseFormWatch<AdjustAmtValues>;
};

function AdjustAmtForm(props: DateRangeProps) {
  const { control, errors, watch } = props;

  const isPercent = watch("isPercent");

  return (
    <>
      <Box style={{ marginBottom: "50px" }}>
        <Typography variant="body1">
          Do you want to increase or decrease base rate?
        </Typography>
      </Box>
      <Box>
        <Box mb={3} width="100%">
          <RHFRadioButtons
            name="isIncrease"
            control={control}
            errorMessage={errors.isIncrease?.message}
            radioButtons={[
              { label: "Increase", value: true },
              { label: "Decrease", value: false },
            ]}
          />
        </Box>
        <Box m={1} />
      </Box>
      <Box style={{ marginBottom: "30px", marginTop: "30px" }}>
        <RHFDollarPctAmt
          toggleName="isPercent"
          textFieldName="amount"
          errorMessage={errors.amount?.message}
          control={control}
          isPercent={isPercent}
          customDecimal={2}
        />
      </Box>
    </>
  );
}

function useAdjustAmtForm() {
  // setup react hook forms
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit: handleAdjustAmtSubmit,
    reset: resetAdjustAmtForm,
  } = useForm({
    defaultValues: adjustAmtDefaultValues,
    resolver: yupResolver(adjustAmtSchema),
  });

  return {
    handleAdjustAmtSubmit,
    resetAdjustAmtForm,
    adjustAmtFormComponent: (
      <AdjustAmtForm control={control} errors={errors} watch={watch} />
    ),
  };
}
export default useAdjustAmtForm;
