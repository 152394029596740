import React, { ReactNode } from "react";

import { Box, Typography } from "@mui/material";

import MoovsDialog from "./MoovsDialog";

type DiscardChangesDialogProps = {
  open: boolean;
  onDiscard: () => void;
  onClose: () => void;
  body?: string | ReactNode;
};

function DiscardChangesDialog(props: DiscardChangesDialogProps) {
  const {
    open,
    onDiscard,
    onClose,
    body = "Are you sure you want to exit without saving?",
  } = props;

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      onAccept={onDiscard}
      hideTopBorder
      dialogTitle="You have unsaved changes"
      acceptButtonText="Discard changes"
      acceptButtonIsDelete // changes color to red
      closeButtonText="Cancel"
      size="xs"
    >
      <Box mb={3}>
        <Typography variant="body2">{body}</Typography>
      </Box>
    </MoovsDialog>
  );
}

export default DiscardChangesDialog;
