/**
 * @file RoundChip.tsx
 * Restyling of Chip component to match designs.
 */
import React, { ComponentType } from "react";

import { Chip, ChipProps, IconProps } from "@mui/material";

import {
  requestStatusLabelColors,
  invoiceStatusLabelColors,
} from "../../colors";

type TextTransform =
  | "-moz-initial"
  | "inherit"
  | "initial"
  | "revert"
  | "unset"
  | "none"
  | "full-width"
  | "capitalize"
  | "full-size-kana"
  | "lowercase"
  | "uppercase";

type WhiteSpace =
  | "inherit"
  | "initial"
  | "revert"
  | "revert-layer"
  | "unset"
  | "normal"
  | "nowrap"
  | "pre"
  | "pre-wrap"
  | "pre-line"
  | "breakspaces";

type RequestStatusVariants =
  | "NEW_QUOTE"
  | "DRAFT"
  | "EMAIL_SENT"
  | "ARCHIVED"
  | "EXPIRED";
type InvoiceStatusVariants = "PAID" | "NOT_PAID" | "PARTIAL";

export type RoundChipProps = {
  variant?: RequestStatusVariants | InvoiceStatusVariants;
  label?: string | JSX.Element;
  icon?: ComponentType<IconProps>;
  color?: string;
  backgroundColor?: string;
  textTransform?: TextTransform;
  whiteSpace?: WhiteSpace;
  iconProps?: IconProps & { size?: string; iconColor?: string };
  inputProps?: ChipProps;
  size?: "medium";
};

function RoundChip(props: RoundChipProps) {
  const {
    label,
    icon,
    color,
    backgroundColor,
    iconProps,
    inputProps,
    variant,
    textTransform,
    whiteSpace,
    size,
  } = props;

  const Icon = icon;

  const requestStatusMap = {
    NEW_QUOTE: "NEW",
    DRAFT: "DRAFT",
    EMAIL_SENT: "SENT",
    ARCHIVED: "ARCHIVED",
    EXPIRED: "EXPIRED",
  };

  if (requestStatusMap.hasOwnProperty(variant)) {
    return (
      <RoundChip
        label={requestStatusMap[variant]}
        {...requestStatusLabelColors[variant]}
        inputProps={inputProps}
      />
    );
  }

  const invoiceStatusMap = {
    PAID: "PAID",
    NOT_PAID: "NOT PAID",
    PARTIAL: "PARTIAL",
  };

  if (invoiceStatusMap.hasOwnProperty(variant)) {
    return (
      <RoundChip
        label={invoiceStatusMap[variant]}
        {...invoiceStatusLabelColors[variant]}
        inputProps={inputProps}
      />
    );
  }

  const styles = {
    root: {
      borderRadius: "50px",
      fontSize: "12px",
      fontWeight: 500,
      ...(color && { color }),
      ...(backgroundColor && { backgroundColor }),
      ...(icon && { paddingLeft: 1 }),

      "& span": {
        ...(textTransform && { textTransform }),
      },

      "& .MuiChip-label": {
        wordWrap: "break-word",
        whiteSpace: whiteSpace || "normal",
        textOverflow: "clip",
        textAlign: "center",
        paddingLeft: "6px",
        paddingRight: "6px",
        ...(size === "medium" && { width: "100px" }),
      },
    },
  };

  return (
    <Chip
      sx={styles.root}
      size={size || "small"}
      label={label}
      icon={icon && <Icon fontSize="small" {...iconProps} style={{ color }} />}
      {...inputProps}
    />
  );
}

export default RoundChip;
