import React from "react";

import { MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";

import { CheckIcon } from "../design-system/icons";
import { moovsBlue, white } from "../design-system/colors";
import { useScreenSize } from "globals/hooks";

const MenuProps = {
  PaperProps: {
    variant: "outlined" as "outlined",
    style: {
      padding: "0 6px",
      boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.1)",
    },
  },
};

type MoovsSelectOption =
  | {
      key: string;
      value: any;
      onClick?: () => void;
    }
  | string;

type MoovsSelectProps = {
  items: MoovsSelectOption[];
  value: any;
  title: string;
  onChange: (event: SelectChangeEvent<any>) => void;
  sx?: Record<string, unknown>;
};

function MoovsSelect(props: MoovsSelectProps) {
  const { value, onChange, items, title = "moovsSelect", sx } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Select
      value={value}
      title={title}
      variant="outlined"
      onChange={onChange}
      data-testid={`moovs-select-${title}`}
      renderValue={(selected) => selected}
      sx={{
        backgroundColor: white,
        ...sx,
      }}
      MenuProps={MenuProps}
    >
      {items.map((item) => {
        const [key, value] =
          typeof item === "string" ? [item, item] : [item.key, item.value];
        const isSelected = item === props.value;
        const onClick = typeof item !== "string" && item.onClick;

        return (
          <MenuItem
            value={value}
            key={key}
            data-testid={`moovs-select-${title}-item-${key}`}
            sx={{
              ...(isMobileView && { minWidth: "100%" }),
              display: "flex",
              justifyContent: "space-between",
              height: 44,
            }}
            onClick={onClick}
          >
            <Typography variant="body2">{key}</Typography>
            {isSelected && <CheckIcon size="small" color={moovsBlue} />}
          </MenuItem>
        );
      })}
    </Select>
  );
}

export default MoovsSelect;
