import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { SEND_EMAIL_TO_CUSTOMER_MUTATION } from "globals/graphql";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { white } from "design-system/colors";
import { RequestStage } from "types";

export const useSendQuoteMutation = () => {
  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // mutations
  const [sendEmailToCustomer] = useMutation(SEND_EMAIL_TO_CUSTOMER_MUTATION, {
    refetchQueries: ["LoadNewQuotes"],
    onCompleted(data) {
      track("send_quote", { location: "drawer" });

      const bookingContact = data.sendEmailToCustomer.request.bookingContact;
      const bookingContactName = `${bookingContact.firstName} ${bookingContact.lastName}`;

      snackbar.success(`Email sent to ${bookingContactName}`, {
        snackbarColor: white,
      });
    },
    onError(error) {
      snackbar.error("Error sending email");
    },
  });

  // event handlers
  const handleSendQuoteEmail = useCallback(
    (requestId: string) => {
      sendEmailToCustomer({
        variables: {
          input: {
            requestId,
            mode: RequestStage.Quote,
          },
        },
      });
    },
    [sendEmailToCustomer]
  );

  return {
    handleSendQuoteEmail,
  };
};
