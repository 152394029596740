import React, { useState, MouseEvent, useEffect } from "react";

import {
  IconButton,
  Menu,
  MenuItem,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";

import { useSnackbar } from "globals/hooks";
import ImageUpload from "components/ImageUpload/ImageUpload";
import MissingImage from "design-system/images/MissingImage.png";
import { EllipsisVerticalIcon } from "design-system/icons";
import { granite, white } from "design-system/colors";
import { VehicleChildSeat } from "types";

type ChildSeatPhotoProps = {
  childSeat: VehicleChildSeat;
  onUpdateVehicleChildSeatPhoto: (url: string) => void;
  onPhotoClick: () => void;
  size?: number; // size of image in pixels
};

function ChildSeatPhoto(props: ChildSeatPhotoProps) {
  const {
    childSeat,
    onUpdateVehicleChildSeatPhoto,
    onPhotoClick,
    size = 170,
  } = props;

  const snackbar = useSnackbar();

  // state
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = !!anchorEl;

  // effects
  // hide loading spinner when image is loaded
  useEffect(() => {
    if (loading) setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childSeat.imageUrl]);

  // event handlers
  const handleUpdateVehiclePhoto = (url: string) => {
    onUpdateVehicleChildSeatPhoto(url);
  };

  const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuItemClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuClose = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleImageUploadError = (errorMessage: string) => {
    snackbar.error(errorMessage);
    setLoading(false);
  };

  const handleImageUploadStart = () => {
    setLoading(true);
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        style={{ cursor: "pointer" }}
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height={size}
        width={size}
        borderRadius="4px"
        border="1px solid rgba(115, 156, 255, 0.3)"
        bgcolor="rgba(229, 238, 255, 0.3)"
        m={1}
        sx={(theme) => ({
          cursor: "pointer",
          overflow: "hidden",
          ":hover": {
            "& .MuiIconButton-root": {
              opacity: 1,
              backgroundColor: theme.palette.primary.main,
            },
          },
        })}
        onClick={onPhotoClick}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <img
            style={{
              borderRadius: "4px",
              border: "1px solid rgba(115, 156, 255, 0.3)",
              objectFit: "cover",
            }}
            width={size}
            height={size}
            src={childSeat.imageUrl}
            onError={(e: any) => (e.target.src = MissingImage)}
            alt={childSeat.imageUrl || "broken"}
          />
        )}

        {!loading && (
          <>
            {/* ellipsis menu */}
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleMenuClick}
              sx={(theme) => ({
                position: "absolute",
                top: "16px",
                right: "16px",
                cursor: "pointer",
                backgroundColor: theme.palette.primary.main,
                opacity: 0,
                [theme.breakpoints.down("sm")]: {
                  opacity: 1,
                },
              })}
              size="small"
            >
              <EllipsisVerticalIcon color={white} />
            </IconButton>
          </>
        )}

        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: -100,
            vertical: "center",
          }}
          keepMounted
          open={menuOpen}
          onClose={handleMenuClose}
        >
          <label htmlFor={`replace-photo-button-${childSeat.id}`}>
            <MenuItem onClick={handleMenuItemClick}>
              <Typography
                style={{ fontWeight: 500, color: granite }}
                variant="body2"
              >
                Replace Image
              </Typography>
            </MenuItem>
          </label>
        </Menu>
      </Box>

      <ImageUpload
        firebaseDirectory="child_seat_images"
        htmlFor={`replace-photo-button-${childSeat.id}`}
        aspectRatio={1}
        maxWidth={1500}
        maxHeight={1500}
        onFileUploadStart={handleImageUploadStart}
        onFileUploadComplete={handleUpdateVehiclePhoto}
        onError={handleImageUploadError}
      />
    </>
  );
}

export default ChildSeatPhoto;
