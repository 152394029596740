import isNull from "lodash/isNull";

import { VehiclePricingError } from "../types";

export const getBRAVehiclePricingErrors = (values: {
  minimumTotalBaseRate: number | null;
  deadheadRatePerMile: number | null;
  tripRatePerMile: number | null;
  weekdayHourlyCost: number | null;
  weekdayMinMinutes: number | null;
  weekendHourlyCost: number | null;
  weekendMinMinutes: number | null;
  weekends: Array<{ name: string; value: string; index: number }>;
}) => {
  let errors: VehiclePricingError = {};

  if (
    !isNull(values.minimumTotalBaseRate) ||
    !isNull(values.deadheadRatePerMile) ||
    !isNull(values.tripRatePerMile)
  ) {
    if (isNull(values.minimumTotalBaseRate)) {
      errors = {
        ...errors,
        minimumTotalBaseRate:
          "Clear or set transfer fields to enable base rate automation",
      };
    }

    if (isNull(values.deadheadRatePerMile)) {
      errors = {
        ...errors,
        deadheadRatePerMile:
          "Clear or set transfer fields to enable base rate automation",
      };
    }

    if (isNull(values.tripRatePerMile)) {
      errors = {
        ...errors,
        tripRatePerMile:
          "Clear or set transfer fields to enable base rate automation",
      };
    }
  }

  if (
    !isNull(values.weekendHourlyCost) ||
    !isNull(values.weekendMinMinutes) ||
    values.weekends.length > 0
  ) {
    if (isNull(values.weekendHourlyCost)) {
      errors = {
        ...errors,
        weekendHourlyCost:
          "All weekend fields required for base rate automation",
      };
    }

    if (isNull(values.weekendMinMinutes)) {
      errors = {
        ...errors,
        weekendMinMinutes:
          "All weekend fields required for base rate automation",
      };
    }

    if (values.weekends.length === 0) {
      errors = {
        ...errors,
        settings: "All weekend fields required for base rate automation",
      };
    }
  }

  if (isNull(values.weekdayHourlyCost)) {
    errors = {
      ...errors,
      weekdayHourlyCost:
        "Weekday hourly rate required for base rate automation",
    };
  }
  if (isNull(values.weekdayMinMinutes)) {
    errors = {
      ...errors,
      weekdayMinMinutes:
        "Weekday hourly minimum required for base rate automation",
    };
  }

  return errors;
};
