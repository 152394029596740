import React from "react";

const GoogleColoredIconBig = () => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5443_97136)">
        <path
          d="M39.9434 20.4624C39.9434 19.1028 39.8332 17.7359 39.598 16.3984H20.7334V24.1001H31.5363C31.088 26.584 29.6476 28.7813 27.5385 30.1776V35.1748H33.9835C37.7682 31.6915 39.9434 26.5473 39.9434 20.4624Z"
          fill="#4285F4"
        />
        <path
          d="M20.7329 39.9996C26.1269 39.9996 30.6759 38.2285 33.9902 35.1714L27.5453 30.1741C25.7521 31.394 23.4372 32.0848 20.7402 32.0848C15.5225 32.0848 11.0985 28.5647 9.5111 23.832H2.86035V28.9836C6.25554 35.7372 13.1708 39.9996 20.7329 39.9996Z"
          fill="#34A853"
        />
        <path
          d="M9.5043 23.8325C8.66652 21.3486 8.66652 18.6589 9.5043 16.175V11.0234H2.8609C0.0242307 16.6747 0.0242307 23.3328 2.8609 28.9841L9.5043 23.8325Z"
          fill="#FBBC04"
        />
        <path
          d="M20.7328 7.9161C23.5842 7.87201 26.34 8.94494 28.4051 10.9144L34.1152 5.20436C30.4995 1.80917 25.7007 -0.0574433 20.7328 0.00134778C13.1708 0.00134778 6.25554 4.2637 2.86035 11.0247L9.50375 16.1763C11.0838 11.4362 15.5151 7.9161 20.7328 7.9161Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_5443_97136">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0.333008)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoogleColoredIconBig;
