import capitalize from "lodash/capitalize";
import moment from "moment";

import { WorkflowSelectionsEnum } from "../steps/workflow/schemaValidation";
import { DynamicPricingInputType } from "./types";

type GetRepeatRuleTextProps = {
  currentWorkflow: string;
  validatedInput: DynamicPricingInputType;
};

type GetSelectedRepeatRuleProps = {
  currentWorkflow: string;
  validatedInput: DynamicPricingInputType;
};

// on repeat rule step, this returns the repeat text if user want to select repeat
// only date and dateRange workflows have repeat rule
export const getRepeatRuleText = (props: GetRepeatRuleTextProps) => {
  const { currentWorkflow, validatedInput } = props;
  const inputData = validatedInput[currentWorkflow];

  if (currentWorkflow === WorkflowSelectionsEnum.date) {
    const date = moment(inputData.startDate).format("M/DD");
    return `Repeat Annually on ${date}`;
  }

  if (currentWorkflow === WorkflowSelectionsEnum.dateRange) {
    const startDate = moment(inputData.startDate).format("MMMM D");
    const endDate = moment(inputData.endDate).format("MMMM D");
    const weekdays = inputData.daysOfWeek
      .filter((day) => day.isSelected)
      .map((day) => capitalize(day.value));
    return `Repeat Annually from ${startDate} to ${endDate} on ${weekdays.join(
      ", "
    )}`;
  }
};

// on overview step, this returns the final output based off of which repeat rule user selected
export const getSelectedRepeatRule = (props: GetSelectedRepeatRuleProps) => {
  const { currentWorkflow, validatedInput } = props;
  const inputData = validatedInput[currentWorkflow];

  if (!inputData.repeatAnnually) return "Does Not Repeat";

  return getRepeatRuleText({ currentWorkflow, validatedInput });
};
