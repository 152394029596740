import React from "react";
import moment from "moment-timezone";

import { Box, Typography, CircularProgress, IconButton } from "@mui/material";

import { granite } from "../../../../design-system/colors";
import { SyncIcon } from "../../../../design-system/icons";
import RoundChip from "../../../../design-system/components/chips/RoundChip";
import { AirportInfo, FlightProgressDisplay } from "..";
import { FlightInfoCardContentProps } from "./FlightInfoCard";
import flightStatusMap from "../../helpers/flightStatusMap";

function FlightInfoCardContentDesktop(props: FlightInfoCardContentProps) {
  const {
    airlineHeader,
    refreshFlightProps = {},
    trackedFlight,
    sxStyles,
  } = props;

  const { origin, destination, flightStatus, updatedAt } = trackedFlight;
  const { isRefreshingFlight, onClickRefreshFlight } = refreshFlightProps;

  const { statusColor, allowRefresh } = flightStatusMap(flightStatus);

  const originAirportCode =
    origin.airport.iataCode || origin.airport.icaoCode || "-"; // if airport not in our db, will only have icao code
  const destinationAirportCode =
    destination.airport.iataCode || destination.airport.icaoCode || "-"; // if airport not in our db, will only have icao code

  return (
    <>
      <Box
        pb={2}
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        sx={sxStyles.flightInfoHeader}
      >
        <Box>
          <Box mb={1}>
            <Typography sx={sxStyles.flightNumber} variant="body2">
              {airlineHeader}
            </Typography>
          </Box>
          <RoundChip
            label={flightStatus}
            color="white"
            backgroundColor={statusColor}
            textTransform="uppercase"
          />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          {isRefreshingFlight ? (
            <Box p={1.5}>
              <CircularProgress size={20} thickness={4} />
            </Box>
          ) : (
            <>
              {updatedAt && (
                <Box display="flex" flexDirection="column">
                  <Typography sx={sxStyles.flightNumber} variant="caption">
                    Last Updated:
                  </Typography>
                  <Typography variant="body2">
                    {moment(updatedAt).calendar()}
                  </Typography>
                </Box>
              )}
              {!!onClickRefreshFlight && allowRefresh && (
                <IconButton onClick={onClickRefreshFlight} size="large">
                  <SyncIcon color={granite} />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box py={2} display="flex" justifyContent="space-between">
        <Box flex={1}>
          <AirportInfo
            airportCode={originAirportCode}
            airportName={origin.airport.airportName || ""}
            transitStopType="origin"
          />
        </Box>
        <Box flex={3}>
          <FlightProgressDisplay flightStatus={flightStatus} />
        </Box>
        <Box flex={1}>
          <AirportInfo
            airportCode={destinationAirportCode}
            airportName={destination.airport.airportName || ""}
            transitStopType="destination"
          />
        </Box>
      </Box>
    </>
  );
}

export default FlightInfoCardContentDesktop;
