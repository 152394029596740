import React from "react";

import { Box } from "@mui/material";

import MediaPreview from "./MediaPreview";
import { MediaFileType } from "../utils";

type MediaPreviewListProps = {
  attachments: MediaFileType[];
  onRemoveAttachment: (media: any) => void;
};

function MediaPreviewList(props: MediaPreviewListProps) {
  const { attachments, onRemoveAttachment } = props;

  if (!attachments.length) return null;

  return (
    <Box display="flex" flexWrap="wrap">
      {attachments.map((media, index) => {
        return (
          <Box mr={2} mb={1} key={index}>
            <MediaPreview
              media={media}
              onRemoveMedia={() => onRemoveAttachment(index)}
            />
          </Box>
        );
      })}
    </Box>
  );
}

export default MediaPreviewList;
