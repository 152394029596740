import React, { useEffect, useState } from "react";
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import RHFSingleSelect from "components/reactHookFormInputs/RHFSingleSelect";
import { getDefaultRepeatLabel } from "../utils/getDefaultRepeat";
import { EditDynamicPricingDefaultValues } from "../useEditDynamicPricingDefaultValues";

type RepeatProps = {
  control: Control<EditDynamicPricingDefaultValues>;
  errors: FieldErrors;
  onSetValue: UseFormSetValue<EditDynamicPricingDefaultValues>;
  watch: UseFormWatch<EditDynamicPricingDefaultValues>;
  editDynamicPricingDefault: EditDynamicPricingDefaultValues;
};

function Repeat(props: RepeatProps) {
  const { control, errors, editDynamicPricingDefault, watch, onSetValue } =
    props;

  // get updated values as user changes react hook form inputs
  const { repeatAnnually, startDate, endDate, startTime, endTime, daysOfWeek } =
    watch();

  // state
  const [repeatRules, setRepeatRules] = useState({
    disabled: null,
    options: [],
    defaultLabel: "",
  });

  // set default values
  useEffect(() => {
    const { options, disabled, defaultLabel } = getDefaultRepeatLabel(
      editDynamicPricingDefault
    );
    if (!repeatRules.defaultLabel) {
      // @ts-ignore fix later
      onSetValue("repeatRuleIsDisabled", disabled);
      setRepeatRules({
        ...repeatRules,
        disabled,
        defaultLabel,
        options,
      });
    }
  }, [repeatRules, editDynamicPricingDefault, onSetValue]);

  // update repeat options list when there is a change in react hook form inputs
  useEffect(() => {
    const { options, disabled, defaultLabel } = getDefaultRepeatLabel({
      repeatAnnually,
      startDate,
      endDate,
      // @ts-ignore fix later
      startTime,
      // @ts-ignore fix later
      endTime,
      daysOfWeek,
    });
    if (
      repeatRules.disabled !== disabled ||
      repeatRules.options[1]?.id !== options[1]?.id
    ) {
      onSetValue("repeatRuleIsDisabled", disabled);
      setRepeatRules({
        ...repeatRules,
        disabled,
        defaultLabel,
        options,
      });
    }
  }, [
    repeatRules,
    repeatAnnually,
    startDate,
    endDate,
    startTime,
    endTime,
    daysOfWeek,
    onSetValue,
  ]);

  return (
    <RHFSingleSelect
      name="repeatAnnually"
      control={control}
      errors={errors.repeatAnnually?.message}
      options={repeatRules.options}
      defaultLabel={repeatRules.defaultLabel}
      disabled={repeatRules.disabled}
    />
  );
}

export default Repeat;
