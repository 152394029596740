import React from "react";

import { Box } from "@mui/material";

import { granite, grayLight } from "design-system/colors";

function BetaChip() {
  return (
    <Box
      sx={{
        minWidth: "45px",
        height: "28px",
        backgroundColor: grayLight,
        color: granite,
        padding: "2px",
        fontSize: "12px",
        fontWeight: "800",
        marginLeft: "10px",
        borderRadius: "4px",
        letterSpacing: "-0.5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      BETA
    </Box>
  );
}

export default BetaChip;
