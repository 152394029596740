/**
 * @file graphql/email.ts
 * queries/mutations for email.
 *
 */

import { gql } from "@apollo/client";

export const ENABLE_AUTO_EMAILS_MUTATION = gql`
  mutation EnableAutomatedEmails($input: EnableAutomatedEmailsInput!) {
    enableAutomatedEmails(input: $input) {
      operator {
        id
        automatedEmailsEnabled
      }
    }
  }
`;

export const DISABLE_AUTO_EMAILS_MUTATION = gql`
  mutation DisableAutomatedEmails($input: DisableAutomatedEmailsInput!) {
    disableAutomatedEmails(input: $input) {
      operator {
        id
        automatedEmailsEnabled
      }
    }
  }
`;

export const LOAD_EMAIL_LOGS_QUERY = gql`
  query LoadEmailLogs($email: String!, $cursor: String, $limit: Int) {
    loadEmailLogs(email: $email, after: $cursor, first: $limit) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ... on EmailLog {
            id
            createdAt
            emailType
            confirmationNumber
            toRecipients
            ccRecipients
            emailEvents {
              id
              eventType
              triggeredAt
              recipient
            }
          }
        }
      }
    }
  }
`;
