import React, { useMemo } from "react";

import { Box, Chip, Tooltip, Typography } from "@mui/material";

import { black, grayDark, grayLight, white } from "design-system/colors";
import { dynamicPricingChips } from "../utils/types";

type DynamicPricingChipTooltipProps = {
  onDrawerOpen: () => void;
  list: string[];
  label: string;
  iconName: string;
};

function DynamicPricingChipTooltip(props: DynamicPricingChipTooltipProps) {
  const { onDrawerOpen, list, label, iconName } = props;

  const tooltipContent = useMemo(() => {
    return list.map((item) => <Box key={item}>{item}</Box>).slice(1);
  }, [list]);

  return (
    <Chip
      sx={{
        backgroundColor: grayLight,
        color: black,
        paddingRight: 0.4,
        marginRight: 1,
        marginBottom: 1,
      }}
      label={label}
      onDelete={onDrawerOpen}
      icon={<img src={dynamicPricingChips[iconName]} alt={iconName} />}
      deleteIcon={
        <Tooltip title={<Box>{tooltipContent}</Box>}>
          <Box
            sx={{
              backgroundColor: grayDark,
              borderRadius: "25px",
              padding: "4px",
              paddingRight: "4px",
            }}
          >
            <Typography color={white} fontSize="12px">
              +{list.length - 1}
            </Typography>
          </Box>
        </Tooltip>
      }
    />
  );
}

export default DynamicPricingChipTooltip;
