import React, { ChangeEvent, Dispatch, SetStateAction } from "react";

import { Box, Typography } from "@mui/material";

import { ExternalOperator, UpdateExternalOperatorInput } from "types";
import MoovsAvatar from "design-system/components/MoovsAvatar";
import {
  LabeledInlineInput,
  LabeledInlineLocationAutocomplete,
  LabeledInlinePhoneNumberInput,
} from "design-system/components/inputs";
import { UPDATE_EXTERNAL_OPERATOR_MUTATION } from "globals/graphql";
import { useAutoSave } from "globals/hooks/useAutoSave";

type ExternalOperatorInfoProps = {
  externalOperator: ExternalOperator;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function ExternalOperatorInfo(props: ExternalOperatorInfoProps) {
  const { setSaveIndicatorState } = props;

  const incomingState = {
    id: props.externalOperator.id,
    operatorName: props.externalOperator.operatorName,
    operatorEmail: props.externalOperator.operatorEmail,
    operatorPhone: props.externalOperator.operatorPhone,
    operatorAddress: props.externalOperator.operatorAddress,
    contactName: props.externalOperator.contactName,
    internalNote: props.externalOperator.internalNote,
  };

  const [externalOperator, setExternalOperator] =
    useAutoSave<UpdateExternalOperatorInput>({
      setSaveIndicatorState,
      incomingState,
      mutation: UPDATE_EXTERNAL_OPERATOR_MUTATION,
      name: "affiliate",
    });
  // event handlers
  const handleFarmAffiliateInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setExternalOperator({
      ...externalOperator,
      [event.target.name]: event.target.value,
    });
  };

  const handleFarmAffiliateAddressInputChange = (value: {
    description: string;
  }) => {
    setExternalOperator({
      ...externalOperator,
      operatorAddress: value.description,
    });
  };

  return (
    <>
      <Box my={3} display="flex" flexDirection="column" alignItems="center">
        <Box>
          <MoovsAvatar
            alt="contact, person"
            size="xl"
            placeholder={props.externalOperator.operatorName}
          />
        </Box>
        <Box mt={2}>
          <Typography variant="h5">
            {props.externalOperator.operatorName}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        <LabeledInlineInput
          label="Affiliate Name"
          value={externalOperator?.operatorName || ""}
          name="operatorName"
          onChange={handleFarmAffiliateInputChange}
        />
        <LabeledInlineInput
          label="Company Contact"
          value={externalOperator?.contactName || ""}
          name="contactName"
          onChange={handleFarmAffiliateInputChange}
        />
        <LabeledInlineInput
          label="Affiliate Email"
          value={externalOperator?.operatorEmail || ""}
          name="operatorEmail"
          onChange={handleFarmAffiliateInputChange}
        />
        <LabeledInlinePhoneNumberInput
          label="Affiliate Phone"
          value={externalOperator?.operatorPhone || ""}
          name="operatorPhone"
          onChange={handleFarmAffiliateInputChange}
        />
        <LabeledInlineLocationAutocomplete
          label="Affiliate Address"
          name="operatorAddress"
          value={externalOperator?.operatorAddress || ""}
          onChange={handleFarmAffiliateAddressInputChange}
        />

        <LabeledInlineInput
          multiline
          label="Notes"
          value={externalOperator?.internalNote || ""}
          name="internalNote"
          onChange={handleFarmAffiliateInputChange}
        />
      </Box>
    </>
  );
}

export default ExternalOperatorInfo;
