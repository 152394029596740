import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";

import { black, grayLight, moovsBlue } from "design-system/colors";
import { ChevronDownIcon, ChevronUpIcon } from "design-system/icons";
import { LuggageEnum, LuggageFields } from "./types";
import LuggageMenuItem from "./components/LuggageMenuItem";

type LuggageMenuProps = {
  luggageAmount: LuggageFields;
  setLuggageAmount: Dispatch<SetStateAction<LuggageFields>>;
};

function LuggageMenu(props: LuggageMenuProps) {
  const { luggageAmount, setLuggageAmount } = props;

  // state
  const [expanded, setExpanded] = useState(false);

  // derived state
  const totalLuggages =
    luggageAmount.carryOnLuggage +
    luggageAmount.checkedLuggage +
    luggageAmount.oversizeLuggage;

  // event handler
  const handleChange = () => {
    setExpanded(!expanded);
  };

  // open menu if there are any luggage counts
  useEffect(() => {
    if (
      luggageAmount.carryOnLuggage ||
      luggageAmount.checkedLuggage ||
      luggageAmount.oversizeLuggage
    ) {
      setExpanded(true);
    }
  }, [
    luggageAmount.carryOnLuggage,
    luggageAmount.checkedLuggage,
    luggageAmount.oversizeLuggage,
  ]);

  return (
    <Box my={2}>
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        sx={{
          border: `1px solid ${grayLight}`,
          boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.03)",
          borderRadius: "5px",
        }}
      >
        <AccordionSummary aria-controls="luggage-options">
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            p={0.7}
          >
            <Box>
              <Typography variant="h4" color={moovsBlue}>
                Luggage
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box mr={6}>
                <Typography color={black} variant="body1">
                  {`${totalLuggages} Total`}
                </Typography>
              </Box>
              <Box sx={{ position: "relative" }}>
                {expanded ? (
                  <Box sx={{ position: "absolute", top: "-6px", right: "4px" }}>
                    <ChevronUpIcon color={moovsBlue} />
                  </Box>
                ) : (
                  <Box
                    sx={{ position: "absolute", top: "-12px", right: "4px" }}
                  >
                    <ChevronDownIcon color={moovsBlue} />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Divider
            sx={{
              bgcolor: grayLight,
              borderColor: grayLight,
              marginTop: "-6px",
            }}
          />
          <Box pt={2} px={0.5}>
            <LuggageMenuItem
              luggageAmount={luggageAmount}
              setLuggageAmount={setLuggageAmount}
              title="Carry-On"
              luggageType={LuggageEnum.carryOnLuggage}
            />
            <LuggageMenuItem
              luggageAmount={luggageAmount}
              setLuggageAmount={setLuggageAmount}
              title="Checked"
              luggageType={LuggageEnum.checkedLuggage}
            />
            <LuggageMenuItem
              luggageAmount={luggageAmount}
              setLuggageAmount={setLuggageAmount}
              title="Oversize"
              luggageType={LuggageEnum.oversizeLuggage}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default LuggageMenu;
