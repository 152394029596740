import React from "react";
import first from "lodash/first";
import some from "lodash/some";

import { Card, Box, Typography } from "@mui/material";

import { Trip } from "types";
import { grayDark } from "design-system/colors";
import PassengerCountDisplay from "./TripItineraryBlock/components/PassengerCountDisplay";
import LuggageBlock from "./TripItineraryBlock/components/LuggageBlock";
import ChildSeatsNoteBlock from "./ChildSeatsNoteBlock";

type TripNotesBlockProps = { trip: Trip };

function TripNotesBlock(props: TripNotesBlockProps) {
  const { trip } = props;

  // derived state
  const route = first(trip.routes);
  const { carryOnLuggage, checkedLuggage, oversizeLuggage } = route;
  const hasChildSeats = some(trip.childSeats, (qty) => qty > 0);

  return (
    <Box width="100%">
      <Card variant="outlined">
        <Box display="flex" flexDirection="column" p={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" style={{ color: grayDark }}>
              Trip Notes
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography variant="body2" sx={{ whiteSpace: "break-spaces" }}>
              {trip.note || "No Trip Notes"}
            </Typography>
          </Box>
          <Box mt={2}>
            <PassengerCountDisplay passengerCount={trip.totalGroupSize} />
          </Box>
          <Box mt={2}>
            <LuggageBlock
              carryOnLuggage={carryOnLuggage}
              checkedLuggage={checkedLuggage}
              oversizeLuggage={oversizeLuggage}
            />
          </Box>
          {/* Child Seat Section */}
          {hasChildSeats && (
            <Box mt={2}>
              <ChildSeatsNoteBlock {...trip.childSeats} />
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
}

export default TripNotesBlock;
