import React from "react";

import { Box, Typography } from "@mui/material";

import { successGreen, tintGreen } from "design-system/colors";
import { BadgeReviewIcon } from "design-system/icons";
import { useScreenSize } from "globals/hooks";

function FiveStarReviewBanner() {
  // hooks
  const { isSmallTabletView } = useScreenSize();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection={isSmallTabletView ? "column" : "row"}
      sx={{ backgroundColor: tintGreen, padding: 1, borderRadius: "5px" }}
    >
      <Box
        m={1}
        display="flex"
        justifyContent={isSmallTabletView ? "start" : "center"}
        width="100%"
        flex={0.5}
      >
        <BadgeReviewIcon />
      </Box>

      <Box m={1} flex={3}>
        <Typography variant="subtitle2" sx={{ color: successGreen }}>
          Congratulations!
        </Typography>
        <Typography variant="body2">
          You got a 5 star review for your excellent service. Keep up the great
          work!
        </Typography>
      </Box>
    </Box>
  );
}

export default FiveStarReviewBanner;
