import { useQuery } from "@apollo/client";
import { LOAD_REQUEST_TRIP_PANEL_QUERY } from "globals/graphql";
import { useScreenSize } from "globals/hooks";
import { convertRequestTripsForReservationPage } from "globals/utils/convertRequestTripsForReservationPage";
import { getRequestStage } from "globals/utils/getRequestStage";
import { pluralize } from "globals/utils/helpers";
import { flattenReturnTrips } from "globals/utils/trips";
import { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { RequestsEdge } from "types";
import { StringParam, useQueryParam } from "use-query-params";

function useReservationPageRequest() {
  // hooks
  const history = useHistory<{ backNavigationPath: string }>();
  const location = useLocation();
  const { isMediumTabletView } = useScreenSize();
  const { requestId } = useParams<{ requestId: string }>();
  const [currentTripId, setCurrentTripId] = useQueryParam(
    "tripId",
    StringParam
  );

  // derived state
  const shouldSkipQuery = !requestId;

  // queries
  const { data, loading, refetch } = useQuery<RequestsEdge>(
    LOAD_REQUEST_TRIP_PANEL_QUERY,
    {
      variables: {
        id: requestId,
      },
      skip: shouldSkipQuery,
      fetchPolicy: "network-only",
      ...(!shouldSkipQuery && { pollInterval: 60 * 1000 }), // every 60 seconds
    }
  );

  const requestData = data?.node || null;

  const request = convertRequestTripsForReservationPage(requestData);

  const requestStage = request && getRequestStage(request.stage);

  // effects
  // reset tripId to first trip if no matching trip id is found
  useEffect(() => {
    if (!request || isMediumTabletView) return;

    const flattenedTrips = flattenReturnTrips(request.trips);
    const requestIncludesTripId = flattenedTrips.some(
      (trip) => trip.id === currentTripId
    );

    if (!requestIncludesTripId && flattenedTrips.length) {
      setCurrentTripId(flattenedTrips[0].id);
    }
  }, [request, setCurrentTripId, currentTripId, isMediumTabletView]);

  // redirect to quote or reservation if on wrong page
  // i.e. if somehow visited /quotes/ when it is a reservation
  useEffect(() => {
    if (requestStage && requestStage !== "reservation") {
      // TODO: fix mobile view drawer animation
      history.replace(
        `/${pluralize(requestStage)}/${requestId}/${location.search}`
      );
    }
  }, [history, location.search, requestStage, requestId]);

  return { request, loading, refetch };
}

export default useReservationPageRequest;
