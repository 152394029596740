import React from "react";

import { Box, Card, Typography } from "@mui/material";

import dashboardEmptySVG from "../../../design-system/illustrations/dashboardEmpty/dashboardEmpty.svg";
import dashboardEmptyDriverPayoutSVG from "../../../design-system/illustrations/dashboardEmpty/dashboardEmptyDriverPayout.svg";
import { DashboardView } from "../DashboardPage";

type DataUnavailableBlockProps = {
  view: DashboardView;
};

function DataUnavailableBlock(props: DataUnavailableBlockProps) {
  const { view } = props;

  const dataEmptyIllustration = {
    [DashboardView.TOTAL_RESERVATIONS]: dashboardEmptySVG,
    [DashboardView.DRIVER_PAYOUT]: dashboardEmptyDriverPayoutSVG,
  }[view];

  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: 2,
      }}
    >
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          component="img"
          sx={{
            marginRight: 1.5,
            height: 140,
          }}
          src={dataEmptyIllustration}
          alt="Dashboard Empty"
        />
        <Box mt={3}>
          <Typography
            variant="h2"
            sx={{ textAlign: "center" }}
          >{`${view} Unavailable`}</Typography>
        </Box>
        <Box mt={1.5}>
          <Typography
            variant="body1"
            sx={{ textAlign: "center" }}
          >{`No ${view.toLowerCase()} information is available for selected time period.`}</Typography>
        </Box>
      </Box>
    </Card>
  );
}

export default DataUnavailableBlock;
