import { stringify } from "query-string";
import { encodeQueryParams, StringParam } from "use-query-params";

type encodeQueryParamsToStringParams = {
  key: EncodableQueryParamKeysEnum;
  value: string;
};

export enum EncodableQueryParamKeysEnum {
  Trip = "tripId",
}

export const encodeQueryParamsToString = (
  params: encodeQueryParamsToStringParams
): string => {
  const { key, value } = params;

  const encodedKey = encodeQueryParams(
    { [key]: StringParam },
    { [key]: value }
  );

  return stringify(encodedKey);
};

export const encodeTripId = (tripId: string): string => {
  return encodeQueryParamsToString({
    key: EncodableQueryParamKeysEnum.Trip,
    value: tripId,
  });
};
