import React, { Dispatch, SetStateAction } from "react";

import { Box } from "@mui/material";

import { SalesAutomationText } from "types";
import { SalesAutomationTextButton } from "./components";

type SalesAutomationTextListProps = {
  draftTexts: SalesAutomationText[];
  activeTextIndex: number;
  setActiveTextIndex: Dispatch<SetStateAction<number>>;
};

function SalesAutomationTextList(props: SalesAutomationTextListProps) {
  const { draftTexts, activeTextIndex, setActiveTextIndex } = props;

  return (
    <Box display="flex" flexDirection="column">
      {draftTexts.map((text) => {
        return (
          <SalesAutomationTextButton
            text={text}
            activeTextIndex={activeTextIndex}
            setActiveTextIndex={setActiveTextIndex}
          />
        );
      })}
    </Box>
  );
}

export default SalesAutomationTextList;
