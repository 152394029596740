import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import { Box, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  useAnalytics,
  useOperator,
  useScreenSize,
  useSnackbar,
} from "globals/hooks";
import { CREATE_ONBOARDING_DRIVER_MUTATION } from "globals/graphql";
import { white } from "design-system/colors";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { ErrorMessages } from "moovsErrors/errorCodesMap";
import InternationalPhoneInput from "components/inputs/InternationalPhoneInput";
import { internationalPhoneStyle } from "./constants";
import { GettingStartedGoal } from "types";

function FirstDriverFields() {
  // state
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    mobilePhone: "",
    phoneCountryCode: "",
    phoneCountryDialCode: "",
    phoneCountryName: "",
    phoneCountryFormat: "",
  });
  const [firstNameError, setFirstNameError] = useState(false);
  const [mobilePhoneError, setMobilePhoneError] = useState("");

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const history = useHistory();
  const { isMobileView } = useScreenSize();
  const operator = useOperator();

  // mutation
  const [createDriver] = useMutation(CREATE_ONBOARDING_DRIVER_MUTATION, {
    onCompleted: () => {
      const { GrowBusinessOnline, ExploreOnMyOwn } = GettingStartedGoal;
      const { goal } = operator.gettingStarted;

      const goalForTracking =
        goal === GrowBusinessOnline
          ? "businessOnline"
          : goal === ExploreOnMyOwn
          ? "exploreOnOwn"
          : "driverApp";

      track("onboarding_driverCreated", { answer: goalForTracking });

      setLoading(false);

      history.replace("/getting-started");
    },
    onError: (error) => {
      setLoading(false);

      const errorMessage = getErrorMessage(error) || error.message;

      if (errorMessage === ErrorMessages.MOOVS_INVALID_MOBILE_NUMBER) {
        setMobilePhoneError(errorMessage);
        snackbar.error(errorMessage);
      } else {
        snackbar.error("Error creating driver");
      }
    },
    refetchQueries: ["Operator"],
  });

  const resetNameErrors = (targetName: string) => {
    if (targetName === "firstName") setFirstNameError(false);
  };

  const isFormValid = () => {
    let showErrorMessage = false;

    if (!data.mobilePhone) {
      setMobilePhoneError("Please enter valid number");
      showErrorMessage = true;
    }

    if (!data.firstName) {
      setFirstNameError(true);
      showErrorMessage = true;
    }

    if (showErrorMessage) {
      snackbar.error("Please fill all of the required fields", {
        hideBoxShadow: true,
      });

      return false;
    }

    return true;
  };

  const handleOnSubmit = () => {
    if (!isFormValid()) return;

    setLoading(true);

    createDriver({ variables: { input: data } });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleOnSubmit();
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetNameErrors(e.target.name);

    setData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleInternationalPhoneChange = (value, country) => {
    setData((prevState) => {
      return {
        ...prevState,
        mobilePhone: value,
        phoneCountryCode: country.countryCode,
        phoneCountryDialCode: country.dialCode,
        phoneCountryName: country.name,
        phoneCountryFormat: country.format,
      };
    });

    setMobilePhoneError("");
  };

  // effects
  useEffect(() => {
    track("onboarding_driverInitiated");
  }, [track]);

  return (
    <>
      <TextField
        fullWidth
        required
        error={firstNameError}
        variant="outlined"
        value={data.firstName}
        label="First Name"
        name="firstName"
        onChange={handleNameChange}
        onKeyDown={handleKeyPress}
        sx={{ backgroundColor: white }}
      />
      <Box mt={2}>
        <InternationalPhoneInput
          value={data.mobilePhone}
          onChange={handleInternationalPhoneChange}
          error={mobilePhoneError}
          errorMessage="Please enter valid number"
          sx={internationalPhoneStyle}
          label="Mobile Phone"
          dropdownWidth={isMobileView ? "600%" : "760%"}
        />
      </Box>
      <Box mt={1.5}>
        <Box display="flex" flexDirection="column" alignItems="center" my={3}>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            fullWidth
            onClick={handleOnSubmit}
          >
            Continue
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
}

export default FirstDriverFields;
