import pick from "lodash/pick";
import startCase from "lodash/startCase";

import { Contact } from "types";

export const getContactName = (contact: Contact) =>
  [contact.firstName, contact.lastName]
    .map((word) => startCase(word))
    .join(" ");

/**
 *
 * Takes format {
 * groupTitle1: [contactId1, contactId2],
 * groupTitle2: [contactId3, contactId4],
 * }
 * and contacts list
 * and returns an array of objects with title and options
 */
export function getContactDropdownOptions(
  contactsOptionsGroupBy: {
    [key: string]: string[] | string;
  },
  contacts: Contact[]
) {
  const groupedContacts = Object.entries(contactsOptionsGroupBy).map(
    ([key, value]) => {
      return {
        title: key,
        options: contacts
          .filter((contact) => value.includes(contact.id))
          .sort((a, b) => (getContactName(a) > getContactName(b) ? 1 : -1)),
      };
    }
  );
  const contactOptions = [];
  const groupedContactIds = [];

  groupedContacts.forEach((group) => {
    group.options.forEach((option) => {
      contactOptions.push({ ...option, title: group.title });
      groupedContactIds.push(option.id);
    });
  });

  const remainingContacts = contacts.filter(
    (contact) => !groupedContactIds.includes(contact.id)
  );
  const remainingContactOptions = remainingContacts.map((contact) => ({
    ...contact,
    title: "Other contacts",
  }));

  return [...contactOptions, ...remainingContactOptions];
}

export function getContactForReservation(contact: Contact) {
  const fields = [
    "id",
    "firstName",
    "lastName",
    "email",
    "mobilePhone",
    "preferences",
    "workAddress",
    "homeAddress",
    "phoneCountryCode",
    "phoneCountryDialCode",
    "phoneCountryName",
    "phoneCountryFormat",
  ];
  return pick(contact, fields);
}
