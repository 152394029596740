import React, { useEffect } from "react";
import first from "lodash/first";
import last from "lodash/last";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { Box, CircularProgress, Typography } from "@mui/material";

import { useSnackbar } from "../../../globals/hooks";
import { LOAD_VEHICLE_AVAILABILITY_CALENDAR } from "../../../globals/graphql";
import { Stop, VehicleAvailabilityCalendar } from "../../../types";
import { grayDark, grayMedium, green } from "../../../design-system/colors";
import CurrentTripScheduleBlock from "./CurrentTripScheduleBlock";
import NextTripScheduleBlock from "./NextTripScheduleBlock";
import PrevTripScheduleBlock from "./PrevTripScheduleBlock";
import {
  PrevNextTripStyledBorder,
  TimeBetweenTripsStyledBorder,
  CurrentTripStyledBorder,
} from "./StyledBorders";
import { convertMinsToHrsMins, getCurrentTripAvailabilityInfo } from "./utils";

type VehicleAvailabilityContentProps = {
  open: boolean;
  vehicleId?: string;
  stops?: Partial<Stop>[];
  routeId?: string;
};

function VehicleAvailabilityContent(props: VehicleAvailabilityContentProps) {
  const { open, vehicleId, routeId, stops } = props;

  let filteredStops = [];
  if (stops?.length > 0) {
    filteredStops = [
      {
        location: first(stops).location,
        dateTime: first(stops).dateTime,
        stopIndex: first(stops).stopIndex,
        airportIcao: first(stops)?.airport?.icaoCode,
      },
      {
        location: last(stops).location,
        dateTime: last(stops).dateTime,
        stopIndex: last(stops).stopIndex,
        airportIcao: last(stops)?.airport?.icaoCode,
      },
    ];
  }

  // hooks
  const snackbar = useSnackbar();

  // queries
  const { data, loading, refetch } = useQuery(
    LOAD_VEHICLE_AVAILABILITY_CALENDAR,
    {
      variables: { vehicleId, routeId, stops: filteredStops },
      skip: !open,
      onError: () => {
        snackbar.error();
      },
    }
  );

  // effects
  // refetch data when opening
  useEffect(() => {
    if (open) refetch();
  }, [open, refetch]);

  const vehicleCalendarData: VehicleAvailabilityCalendar =
    data?.loadVehicleAvailabilityCalendar;

  const { previousTrip, currentTrip, nextTrip } = vehicleCalendarData || {};

  const { isVehicleAvailable, blockedByQuote } =
    getCurrentTripAvailabilityInfo(vehicleCalendarData);

  const pickUpTime = vehicleCalendarData
    ? moment.utc(currentTrip?.pickUpDateTime).format("hh:mm A")
    : "";

  const isAutomatedDropTimeCalculation = vehicleCalendarData
    ? !last(filteredStops).dateTime && !!currentTrip?.dropOffDateTime
    : false;

  const dropOffTime =
    vehicleCalendarData && currentTrip?.dropOffDateTime
      ? moment.utc(currentTrip?.dropOffDateTime).format("hh:mm A")
      : "";

  return (
    <>
      {loading && (
        <Box display="flex" m="auto" pb={0.5}>
          <CircularProgress />
        </Box>
      )}

      {!!vehicleCalendarData && !loading && (
        <Box display="flex" flexDirection="column" pt={2} pb={4} ml={-1}>
          {/* Previous drop off component */}
          {!!previousTrip && (
            <>
              {/* Prev pickup component */}
              <Box
                display="flex"
                alignItems="stretch"
                {...(!previousTrip.minutesToCurrentTrip && { pb: 0.5 })}
              >
                <PrevNextTripStyledBorder />
                <Box width="100%">
                  <PrevTripScheduleBlock
                    prevTrip={previousTrip}
                    isVehicleAvailable={!previousTrip.isOverlap}
                  />
                </Box>
              </Box>

              {/* Time between trips component */}
              {previousTrip.minutesToCurrentTrip && (
                <Box display="flex" alignItems="stretch">
                  <TimeBetweenTripsStyledBorder />
                  <Box py={2}>
                    <Typography variant="h5" style={{ color: grayDark }}>
                      TIME BETWEEN TRIPS
                    </Typography>
                    <Typography variant="caption" style={{ color: grayDark }}>
                      {convertMinsToHrsMins(previousTrip.minutesToCurrentTrip)}
                    </Typography>
                  </Box>
                </Box>
              )}
            </>
          )}

          {/* Current trip */}
          <Box display="flex" flex={1} alignItems="stretch">
            <CurrentTripStyledBorder />
            <Box width="100%">
              <CurrentTripScheduleBlock
                pickUpTime={pickUpTime}
                pickUpLocation={currentTrip.pickUpLocation}
                isAutomatedDropTimeCalculation={isAutomatedDropTimeCalculation}
                dropOffTime={dropOffTime}
                dropOffLocation={currentTrip.dropOffLocation}
                isVehicleAvailable={isVehicleAvailable}
                blockedByQuote={blockedByQuote}
              />
            </Box>
          </Box>

          {/* Next pickup component */}
          {!!nextTrip && (
            <>
              {/* Time between trips component */}
              {nextTrip.minutesToCurrentTrip && (
                <>
                  <Box
                    display="flex"
                    alignItems="stretch"
                    {...(!nextTrip.minutesToCurrentTrip && { pb: 0.5 })}
                  >
                    <TimeBetweenTripsStyledBorder />

                    <Box>
                      <Typography variant="h5" style={{ color: grayDark }}>
                        TIME BETWEEN TRIPS
                      </Typography>
                      <Typography variant="caption" style={{ color: grayDark }}>
                        {convertMinsToHrsMins(nextTrip.minutesToCurrentTrip)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width={26}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box width={26} />
                    <Box
                      height={12}
                      width={1.5}
                      borderLeft={`1.5px dashed ${
                        nextTrip.minutesToCurrentTrip ? green : grayMedium
                      }`}
                    />
                  </Box>
                </>
              )}
              <Box
                width={26}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box width={26} />
                <Box
                  height={4}
                  width={1.5}
                  borderLeft={`1.5px dashed ${
                    nextTrip.minutesToCurrentTrip ? green : grayMedium
                  }`}
                />
              </Box>
              <Box display="flex" alignItems="stretch">
                <PrevNextTripStyledBorder />
                <Box width="100%">
                  <NextTripScheduleBlock
                    nextTrip={nextTrip}
                    isVehicleAvailable={!nextTrip.isOverlap}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
}

export default VehicleAvailabilityContent;
