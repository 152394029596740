/**
 * @file insurancePolicy.graphql.ts
 * queries/mutations for InsurancePolicy.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_INSURANCE_POLICIES_QUERY = gql`
  query InsurancePolicies {
    insurancePolicies {
      id
      insuranceCompany
      policyNumber
      startDate
      endDate
      coverageAmount
      monthlyPremium
      contactFirstName
      contactLastName
      contactPhone
      contactEmail
      insuranceAttachments {
        id
        url
      }
      vehicleIds
    }
  }
`;

export const LOAD_INSURANCE_POLICY_QUERY = gql`
  query InsurancePolicy($id: ID!) {
    node(id: $id) {
      id
      ... on InsurancePolicy {
        id
        insuranceCompany
        policyNumber
        startDate
        endDate
        coverageAmount
        monthlyPremium
        contactFirstName
        contactLastName
        contactPhone
        contactEmail
        insuranceAttachments {
          id
          url
        }
        vehicleIds
      }
    }
  }
`;

export const CREATE_INSURANCE_POLICY_MUTATION = gql`
  mutation CreateInsurancePolicy($input: CreateInsurancePolicyInput!) {
    createInsurancePolicy(input: $input) {
      insurancePolicy {
        id
        insuranceCompany
        policyNumber
        startDate
        endDate
        coverageAmount
        monthlyPremium
        contactFirstName
        contactLastName
        contactPhone
        contactEmail
        insuranceAttachments {
          id
          url
        }
        vehicleIds
      }
    }
  }
`;

export const UPDATE_INSURANCE_POLICY_MUTATION = gql`
  mutation UpdateInsurancePolicy($input: UpdateInsurancePolicyInput!) {
    updateInsurancePolicy(input: $input) {
      insurancePolicy {
        id
        insuranceCompany
        policyNumber
        startDate
        endDate
        coverageAmount
        monthlyPremium
        contactFirstName
        contactLastName
        contactPhone
        contactEmail
        insuranceAttachments {
          id
          url
        }
        vehicleIds
      }
    }
  }
`;

export const REMOVE_INSURANCE_POLICY_MUTATION = gql`
  mutation RemoveInsurancePolicy($input: RemoveInsurancePolicyInput!) {
    removeInsurancePolicy(input: $input) {
      insurancePolicy {
        id
        insuranceCompany
        policyNumber
        startDate
        endDate
        coverageAmount
        monthlyPremium
        contactFirstName
        contactLastName
        contactPhone
        contactEmail
        insuranceAttachments {
          id
          url
        }
        vehicleIds
      }
    }
  }
`;

export const CREATE_INSURANCE_ATTACHMENT_MUTATION = gql`
  mutation CreateInsuranceAttachment($input: CreateInsuranceAttachmentInput!) {
    createInsuranceAttachment(input: $input) {
      insurancePolicy {
        id
        insuranceCompany
        policyNumber
        startDate
        endDate
        coverageAmount
        monthlyPremium
        contactFirstName
        contactLastName
        contactPhone
        contactEmail
        insuranceAttachments {
          id
          url
        }
        vehicleIds
      }
    }
  }
`;

export const REMOVE_INSURANCE_ATTACHMENT_MUTATION = gql`
  mutation RemoveInsuranceAttachment($input: RemoveInsuranceAttachmentInput!) {
    removeInsuranceAttachment(input: $input) {
      insurancePolicy {
        id
        insuranceCompany
        policyNumber
        startDate
        endDate
        coverageAmount
        monthlyPremium
        contactFirstName
        contactLastName
        contactPhone
        contactEmail
        insuranceAttachments {
          id
          url
        }
        vehicleIds
      }
    }
  }
`;
