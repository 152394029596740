import React from "react";
import Button from "@mui/material/Button";

import NetflixLogoIcon from "../../design-system/images/icons/NetflixLogo.svg";
import { Box } from "@mui/system";
import { alabaster, black, grayMedium, white } from "design-system/colors";
import { Typography } from "@mui/material";

type SamlAuthButtonProps = {
  onClick: () => void;
};

const SamlAuthButton = (props: SamlAuthButtonProps) => {
  const { onClick } = props;

  const handleClick = () => {
    onClick();
  };

  return (
    <Button
      onClick={handleClick}
      variant="outlined"
      sx={{
        padding: "12px 18px",
        cursor: "pointer",
        borderColor: grayMedium,
        borderWidth: "1px !important",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
        border: `2px solid ${grayMedium}`,
        backgroundColor: white,
        color: black,
        "&:hover": {
          borderColor: black,
          backgroundColor: alabaster,
        },
      }}
    >
      <Box
        component="img"
        sx={{
          marginRight: 1.5,
          height: 25,
        }}
        src={NetflixLogoIcon}
        alt="NetflixLogo"
      />
      <Typography fontWeight={500}>Log In with Netflix</Typography>
    </Button>
  );
};

export default SamlAuthButton;
