import React from "react";

import { Box, Typography } from "@mui/material";

import { QuickLink } from "./components";
import { useScreenSize } from "globals/hooks";
import { GettingStartedGoal } from "types";
import { QUICK_LINK_DETAILS } from "./constants";

type QuickLinkSectionProps = {
  goal: GettingStartedGoal;
};

function QuickLinkSection(props: QuickLinkSectionProps) {
  const { goal } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <>
      <Typography variant="h3" mb={3} mt={4}>
        Quick links to help you navigate
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={isMobileView ? "column" : "row"}
      >
        {QUICK_LINK_DETAILS[goal].map((data) => {
          return <QuickLink {...data} />;
        })}
      </Box>
    </>
  );
}

export default QuickLinkSection;
