import React from "react";

import { Box, LinearProgress } from "@mui/material";

function LoadingPage() {
  return (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <img
        src="/moovs512.png"
        alt="Loading Moovs..."
        height="80px"
        width="80px"
      />

      <Box width="33%" mt={3}>
        <LinearProgress />
      </Box>
    </Box>
  );
}

export default LoadingPage;
