import React from "react";

import { Box, CircularProgress } from "@mui/material";

const MoovsCardLoading = () => {
  return (
    <Box
      height="400px"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );
};

export default MoovsCardLoading;
