import React from "react";

type MobilePhoneAppIconProps = {
  color?: string;
};

const MobilePhoneAppIcon = (props: MobilePhoneAppIconProps) => {
  const { color } = props;

  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="56" height="56" rx="28" fill="white" />
      <path
        d="M19.5 36.4999H36.5M23.75 42.1666H32.25C34.5972 42.1666 36.5 40.2638 36.5 37.9166V18.0833C36.5 15.736 34.5972 13.8333 32.25 13.8333H23.75C21.4028 13.8333 19.5 15.736 19.5 18.0833V37.9166C19.5 40.2638 21.4028 42.1666 23.75 42.1666Z"
        stroke={color || "#28B668"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.75 26.3378L32.0833 21.9167L27.6623 31.2501L26.6798 27.3203L22.75 26.3378Z"
        stroke={color || "#28B668"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MobilePhoneAppIcon;
