import React, { Dispatch, SetStateAction } from "react";
import { Box, SelectChangeEvent, Typography } from "@mui/material";

import MoovsSelect from "components/MoovsSelect";
import { useScreenSize } from "globals/hooks";
import { DashboardView } from "../DashboardPage";
import { Company } from "types";
import TimePeriodPicker from "components/dashboard/TimePeriodPicker";

type DashboardCoporateSelectorHeaderProps = {
  view: string;
  onHandleViewChange: (event: SelectChangeEvent<DashboardView>) => void;
  isVip: boolean;
  defaultMenuOptions: DashboardView[];
  // TimePeriodPicker: ReactNode;
  companyName: string;
  onHandleCompanyChange: (event: SelectChangeEvent<string>) => void;
  companyList: Company[];
  setStartDate: Dispatch<SetStateAction<moment.Moment>>;
  setEndDate: Dispatch<SetStateAction<moment.Moment>>;
};

function DashboardCoporateSelectorHeader(
  props: DashboardCoporateSelectorHeaderProps
) {
  const {
    view,
    onHandleViewChange,
    isVip,
    defaultMenuOptions,
    companyName,
    onHandleCompanyChange,
    companyList,
    setStartDate,
    setEndDate,
  } = props;

  // hooks
  const { isMobileView, isSmallTabletView } = useScreenSize();

  return (
    <>
      <Box
        display="flex"
        flexDirection={isMobileView ? "column" : "row"}
        {...(!isMobileView && { alignItems: "center" })}
        pt={2}
        pb={1}
      >
        <Typography
          variant="h1"
          sx={{ ...(isMobileView ? { pb: 2 } : { mr: 3 }) }}
        >
          Dashboard
        </Typography>

        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          flexDirection={isMobileView ? "column" : "row"}
        >
          <Box>
            <MoovsSelect
              title="dashboard-view"
              value={view}
              onChange={onHandleViewChange}
              items={
                isVip
                  ? [...defaultMenuOptions, DashboardView.CORPORATE_SHUTTLE]
                  : defaultMenuOptions
              }
              sx={{ minWidth: 220, mb: isMobileView ? 1 : 0, width: "100%" }}
            />
          </Box>
          <Box display="flex" ml={isSmallTabletView ? 0 : 1}>
            {isVip && view === DashboardView.CORPORATE_SHUTTLE && (
              <>
                <MoovsSelect
                  title="company"
                  value={companyName}
                  onChange={onHandleCompanyChange}
                  items={companyList.map((company) => company.name)}
                  sx={{
                    minWidth: isMobileView ? "100%" : 220,
                    width: "100%",
                    marginLeft: isMobileView ? 0 : 0.5,
                  }}
                />
              </>
            )}
            {!isSmallTabletView && (
              <Box ml={1} display="flex" justifyContent="flex-end" width="100%">
                <TimePeriodPicker
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {isSmallTabletView && (
        <Box ml={0} mb={2} display="flex" justifyContent="flex-end">
          <Box {...(isMobileView && { width: "100%" })}>
            <TimePeriodPicker
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </Box>
        </Box>
      )}
    </>
  );
}

export default DashboardCoporateSelectorHeader;
