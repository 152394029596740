import { Box } from "@mui/material";
import React from "react";
import { grayMedium } from "../../../../design-system/colors";
import PhotoIndexIndicator from "../PhotoIndexIndicator";

type BlankVehiclePhotoPlaceholderProps = {
  photoIndex: number;
};

function BlankVehiclePhotoPlaceholder(
  props: BlankVehiclePhotoPlaceholderProps
) {
  const { photoIndex } = props;

  return (
    <Box
      display="flex"
      position="relative"
      height={150}
      width={200}
      borderRadius="4px"
      border={`1px dashed ${grayMedium}`}
      m={1}
    >
      <PhotoIndexIndicator disabled photoIndex={photoIndex} />
    </Box>
  );
}

export default BlankVehiclePhotoPlaceholder;
