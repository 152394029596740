import { Box, Typography } from "@mui/material";
import {
  lightTintOrange,
  orangeDarkSharp,
  tintOrange,
} from "design-system/colors";
import { WarningTriangleIcon } from "design-system/icons";

function TripStatusTriggersNotSetNotification() {
  return (
    <Box
      bgcolor={lightTintOrange}
      borderRadius={1}
      border={1}
      borderColor={tintOrange}
      display="flex"
      role="alert"
      py={1.5}
      px={2}
      ml={1}
      alignItems="center"
      gap={2}
    >
      <Box>
        <WarningTriangleIcon size="large" color={orangeDarkSharp} />
      </Box>

      <Box>
        <Typography
          variant="subtitle2"
          color={orangeDarkSharp}
          fontWeight={700}
          fontSize={14}
          mb={0.5}
        >
          No triggers selected
        </Typography>
        <Typography variant="body2" fontWeight={400} color={orangeDarkSharp}>
          No Trip update SMS will be sent.
        </Typography>
      </Box>
    </Box>
  );
}

export default TripStatusTriggersNotSetNotification;
