import React from "react";

type StatusCircleIconProps = {
  color?: string;
  fill?: string;
  size?: string;
};

const StatusCircleIcon = (props: StatusCircleIconProps) => {
  const { color, fill, size } = props;

  if (size === "small") {
    return (
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill={fill || "none"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.25 5C9.25 7.34721 7.34721 9.25 5 9.25C2.65279 9.25 0.75 7.34721 0.75 5C0.75 2.65279 2.65279 0.75 5 0.75C7.34721 0.75 9.25 2.65279 9.25 5Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.6863 11.3137 2 8 2C4.68629 2 2 4.6863 2 8C2 11.3137 4.68629 14 8 14Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StatusCircleIcon;
