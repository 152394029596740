import { GridColumns } from "@mui/x-data-grid-pro";

import { grayDark } from "design-system/colors";
import BookingDateColumn from "./BookingDateColumn";
import TripDateColumn from "./TripDateColumn";
import TripConfNumberColumn from "./TripConfNumberColumn";
import BookingContactColumn from "./BookingContactColumn";
import TripAmountColumn from "./TripAmountColumn";
import PromoAmountColumn from "./PromoAmountColumn";

const defaults = {
  sortable: false,
  hide: false,
  color: grayDark,
  width: 130,
};

const promoCodeResultsGridColumns: GridColumns = [
  {
    ...defaults,
    field: "bookingDate",
    headerName: "Booking Date",
    renderCell: BookingDateColumn,
    width: 140,
  },
  {
    ...defaults,
    field: "tripDate",
    headerName: " Trip Date",
    renderCell: TripDateColumn,
    width: 110,
  },
  {
    ...defaults,
    field: "conf.No.",
    headerName: "Conf. No.",
    renderCell: TripConfNumberColumn,
  },
  {
    ...defaults,
    field: "bookingContact",
    headerName: "Booking Contact",
    renderCell: BookingContactColumn,
    width: 170,
  },
  {
    ...defaults,
    field: "tripAmount",
    headerName: "Trip Amount",
    type: "number",
    renderCell: TripAmountColumn,
  },
  {
    ...defaults,
    field: "promoAmount",
    headerName: "Promo Amount",
    type: "number",
    renderCell: PromoAmountColumn,
    width: 140,
  },
];

export default promoCodeResultsGridColumns;
