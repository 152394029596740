import React from "react";
import { Control, FieldErrors, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Moment } from "moment";

import { Box, IconButton, Typography, Tooltip } from "@mui/material";

import {
  bookingDateDefaultValues,
  tripDateDefaultValues,
} from "./defaultValues";
import { bookingDateSchema, tripDateSchema } from "./schemaValidation";
import { CalendarIcon, InfoIcon } from "design-system/icons";
import ArrowDownIcon from "design-system/icons/layout/ArrowDownIcon";
import { grayDark } from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import RHFDatePicker from "components/reactHookFormInputs/RHFDatePicker";
import { EditPromoCodeValues } from "../../../UpdatePromoCodeDrawer/components/PromoCodeOverviewSection/EditPromoCodeForm/useEditPromoCodeDefaultValues";

export type DateType = "bookingDate" | "tripDate";

type BookingDateValues = {
  bookingStartDate: Date;
  bookingEndDate: Date;
};

type TripDateValues = {
  tripStartDate: Date;
  tripEndDate: Date;
};

type DateRangeFormProps = {
  dateType: DateType;
  control:
    | Control<BookingDateValues | TripDateValues>
    | Control<EditPromoCodeValues>;
  errors: FieldErrors;
  defaultStartDate?: Moment;
  defaultEndDate?: Moment;
  isEdit?: boolean;
};

export function DateRangeForm(props: DateRangeFormProps) {
  const {
    dateType,
    control,
    errors,
    defaultStartDate,
    defaultEndDate,
    isEdit,
  } = props;

  // hooks
  const { isSmallTabletView } = useScreenSize();

  const bookingDateValues = {
    header: "Booking Date",
    tooltipText: `Target low booking dates to increase reservations during that time.`,
    startDateFieldName: "bookingStartDate",
    endDateFieldName: "bookingEndDate",
    startDateErrorMessage: errors.bookingStartDate?.message,
    endDateErrorMessage: errors.bookingEndDate?.message,
    captionMessage: (
      <>
        Promo code can only be used when booking contact{" "}
        <strong>books within this date range</strong>
      </>
    ),
  };
  const tripDateValues = {
    header: "Trip Date",
    tooltipText: `Target low trip dates where you have fewer trips occurring.`,
    startDateFieldName: "tripStartDate",
    endDateFieldName: "tripEndDate",
    startDateErrorMessage: errors.tripStartDate?.message,
    endDateErrorMessage: errors.tripEndDate?.message,
    captionMessage: (
      <>
        Promo code can only be used when customer{" "}
        <strong>books a trip within this date range</strong>
      </>
    ),
  };

  const {
    header,
    tooltipText,
    startDateFieldName,
    endDateFieldName,
    startDateErrorMessage,
    endDateErrorMessage,
    captionMessage,
  } = dateType === "bookingDate" ? bookingDateValues : tripDateValues;

  return (
    <>
      <Box
        py={isSmallTabletView ? 0.5 : 1}
        display="flex"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <Typography variant="h4">{header}</Typography>
        <Tooltip
          enterDelay={200}
          title={
            <Box sx={{ textAlign: "center", width: "200px" }}>
              {tooltipText}
            </Box>
          }
          placement="top"
        >
          <IconButton aria-label="info" size="medium" sx={{ ml: "8px", p: 0 }}>
            <InfoIcon size="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        display="flex"
        flexDirection={isSmallTabletView ? "column" : "row"}
        justifyContent="space-between"
      >
        <Box mb={isSmallTabletView ? 2 : 1} width="100%">
          <RHFDatePicker
            control={control}
            name={startDateFieldName}
            errorMessage={startDateErrorMessage}
            textFieldPlaceholder="Start Date"
            datePickerInputFormat="MM/DD/YYYY"
            datePickerLabel="Start Date"
            startAdornment={<CalendarIcon />}
            endAdornment={<ArrowDownIcon />}
            {...(defaultStartDate && { defaultDate: defaultStartDate })}
          />
        </Box>
        <Box {...{ m: isSmallTabletView ? 0 : 1 }} />
        <Box mb={isSmallTabletView ? 2 : 1} width="100%">
          <RHFDatePicker
            control={control}
            name={endDateFieldName}
            errorMessage={endDateErrorMessage}
            textFieldPlaceholder="End Date"
            datePickerInputFormat="MM/DD/YYYY"
            datePickerLabel="End Date"
            startAdornment={<CalendarIcon />}
            endAdornment={<ArrowDownIcon />}
            {...(defaultEndDate && { defaultDate: defaultEndDate })}
          />
        </Box>
      </Box>
      {!isEdit && (
        <Typography variant="caption" style={{ color: grayDark }}>
          {captionMessage}
        </Typography>
      )}
    </>
  );
}

function useDateRangeForm(dateType: DateType) {
  const defaultValues =
    dateType === "bookingDate"
      ? bookingDateDefaultValues
      : tripDateDefaultValues;

  const validationSchema =
    dateType === "bookingDate" ? bookingDateSchema : tripDateSchema;

  // setup react hook forms
  const {
    control,
    formState: { errors },
    handleSubmit: handleDateRangeSubmit,
    reset: resetDateRangeForm,
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  return {
    handleDateRangeSubmit,
    resetDateRangeForm,
    dateRangeFormComponent: (
      <DateRangeForm dateType={dateType} control={control} errors={errors} />
    ),
  };
}

export default useDateRangeForm;
