import React, { ChangeEvent, useState, forwardRef } from "react";

import { Box, InputLabel, InputBase, FormControl } from "@mui/material";

import { SearchIcon } from "design-system/icons";
import {
  grayDark,
  grayMedium,
  white,
  black,
  granite,
} from "design-system/colors";
import { primaryMainColor } from "theme";
import { useAnalytics } from "globals/hooks";

type GlobalSearchInputProps = {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  variant: "mobile" | "desktop";
  onDropdownOpen?: () => void;
  isDropdownOpen?: boolean;
};

function GlobalSearchInput(props: GlobalSearchInputProps, inputRef: any) {
  const { value, onChange, variant, isDropdownOpen, onDropdownOpen } = props;

  // state
  const [isFocused, setIsFocused] = useState(false);

  // hooks
  const { track } = useAnalytics();

  // event handlers
  const handleInputFocus = () => {
    if (onDropdownOpen) onDropdownOpen();
    setIsFocused(true);
  };

  const isMobile = variant === "mobile";
  const styles = {
    input: {
      padding: isMobile ? "0" : "27px 0px 10px",
    },
    divider: {
      height: 28,
      margin: "4px",
    },
    formControl: {
      transform: "translate(12px, 17px) scale(1)",
    },
    shrink: {
      transform: "translate(12px, 10px) scale(0.75)",
    },
  };

  const RootBoxStyles = {
    display: "flex",
    height: 56,
    width: "100%",
    borderRadius: "4px",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    background: white,
    padding: "2px 4px",
    alignItems: "center",
    border:
      isFocused && isMobile
        ? `1px solid ${primaryMainColor}`
        : `1px solid ${grayMedium}`,
    ":hover": {
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    },
    // dropdown open
    ...(isDropdownOpen && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    }),
    // mobile
    ...(isMobile && {
      height: 46,
      width: "100%",
      borderRadius: "4px",
      ":hover": {
        borderColor: isFocused ? primaryMainColor : black,
        boxShadow: "none",
      },
    }),
  };

  return (
    <FormControl fullWidth size={variant === "mobile" ? "small" : "medium"}>
      {variant === "desktop" && (
        <InputLabel
          sx={[
            { "&.MuiInputLabel-formControl": styles.formControl },
            { "&.MuiInputLabel-shrink": styles.shrink },
          ]}
          style={{
            color: grayDark,
            marginLeft: "36px",
            paddingRight: "36px",
          }}
        >
          Search for contacts, orders, or companies
        </InputLabel>
      )}

      <Box
        sx={RootBoxStyles}
        height={variant === "mobile" ? "46px" : "56px"}
        onClick={() => inputRef.current.focus()}
      >
        <Box
          display="flex"
          justifyContent="center"
          padding={variant === "mobile" ? "2px" : "10px"}
          aria-label="menu"
        >
          <SearchIcon color={granite} />
        </Box>

        <InputBase
          fullWidth
          inputRef={inputRef}
          autoFocus={variant === "mobile"}
          onFocus={handleInputFocus}
          onBlur={() => setIsFocused(false)}
          value={value}
          placeholder={variant === "mobile" ? "Search" : ""}
          sx={styles.input}
          onChange={onChange}
          onClick={() => track("globalSearch_selected")}
          name="global-search-input"
          autoComplete="off"
        />
      </Box>
    </FormControl>
  );
}

export default forwardRef(GlobalSearchInput);
