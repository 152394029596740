import React from "react";

import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";

import { swoopGreen } from "design-system/colors";
import { AutomatedPricingIcon, EstimationRoundIcon } from "design-system/icons";
import { BRAStatusEnum } from "./types";
import { BaseRateAutomation } from "types";

type AutomatedBaseRateButtonProps = {
  automatedBaseRate: BaseRateAutomation;
  BRAStatus: BRAStatusEnum;
  isLoadingBRA: boolean;
  onActivateBRAClick: () => void;
};

const { Inactive } = BRAStatusEnum;

function AutomatedBaseRateButton(props: AutomatedBaseRateButtonProps) {
  const { automatedBaseRate, BRAStatus, onActivateBRAClick, isLoadingBRA } =
    props;
  const isInactive = BRAStatus === Inactive;

  return (
    <Box display="flex" flex={1} alignItems="center">
      {isLoadingBRA ? (
        <Box display="flex" justifyContent="center" p={1.5}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <Tooltip
          title={`Automated base rate. Click to apply: $${
            automatedBaseRate?.total || null
          }`}
          placement="top"
        >
          <IconButton
            color="primary"
            style={{ width: 48, height: 48 }}
            {...(isInactive && { onClick: onActivateBRAClick })}
            size="large"
          >
            {isInactive ? (
              <EstimationRoundIcon color={swoopGreen} />
            ) : (
              <AutomatedPricingIcon />
            )}
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}

export default AutomatedBaseRateButton;
