import React, { useEffect, useMemo, useState } from "react";

import { Box } from "@mui/material";

import { applyUTCOffsetToTime } from "globals/utils/helpers";
import { Stop } from "types";
import MoovsDateTimePicker from "components/MoovsDateTimePicker";

type ShuttleStopDateTimeInputProps = {
  stopLabel: string;
  stop: Partial<Stop>;
  onSave: (stop: Partial<Stop>) => void;
  stopErrors: { dateTime: string };
  isDateTimeDisabled?: boolean;
};

function ShuttleStopDateTimeInput(props: ShuttleStopDateTimeInputProps) {
  const { stopLabel, stop, onSave, stopErrors, isDateTimeDisabled } = props;

  // state
  const [dateTime, setDateTime] = useState(null);

  // effects
  useEffect(() => {
    // on open reset to saved values
    setDateTime(stop.dateTime);
  }, [stop.dateTime, stop]);

  // event handlers
  const handleDateTimeChange = (date: any) => {
    const utcOffsetDate = date
      ? applyUTCOffsetToTime(date, "add").toISOString()
      : null;
    setDateTime(utcOffsetDate);
    onSave({ dateTime: utcOffsetDate });
  };

  const adjustedStopDateTime = useMemo(
    () =>
      dateTime
        ? applyUTCOffsetToTime(dateTime, "subtract").toISOString()
        : null,
    [dateTime]
  );

  return (
    !isDateTimeDisabled && (
      <Box
        flex="1"
        mb={1}
        mt={1}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        {/* date time */}
        <MoovsDateTimePicker
          hideCalendarIcon
          inputFormat="MMM Do YYYY, h:mm a"
          label={`${stopLabel} Date & Time`}
          value={adjustedStopDateTime}
          onAccept={handleDateTimeChange}
          renderInputProps={{
            name: "pickupDateTime",
            error: stopErrors && !!stopErrors.dateTime,
            helperText: stopErrors && stopErrors.dateTime,
          }}
        />
      </Box>
    )
  );
}

export default ShuttleStopDateTimeInput;
