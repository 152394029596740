import React, { Dispatch, SetStateAction } from "react";
import { useQuery } from "@apollo/client";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import map from "lodash/map";

import { Typography, Box, CircularProgress, Grid } from "@mui/material";

import { grayDark } from "../../../design-system/colors";
import { OrderType } from "../../../types";
import { LOAD_ORDER_TYPES_QUERY } from "../../../globals/graphql";
import GQLQueryStatusIndicator from "../../GQLQueryStatusIndicator";
import OrderTypesCard from "./OrderTypesCard";

type OrderTypesBlockProps = {
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function OrderTypesBlock(props: OrderTypesBlockProps) {
  const { setSaveIndicatorState } = props;

  // queries
  const {
    data: orderTypesData,
    error: orderTypesError,
    loading: orderTypesLoading,
  } = useQuery<{ orderTypes: OrderType[] }>(LOAD_ORDER_TYPES_QUERY);

  if (orderTypesLoading || !orderTypesData) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        flex="1"
        px={3}
        mb={1}
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (orderTypesError) {
    return (
      <GQLQueryStatusIndicator error={orderTypesError} name="Order Types" />
    );
  }

  const groupedOrderTypes = groupBy(orderTypesData.orderTypes, "category");
  const sortedGroupedOrderTypes = map(groupedOrderTypes, (category) =>
    sortBy(category, "name")
  );

  const halfPoint = Math.ceil(sortedGroupedOrderTypes.length / 2);
  const leftColumn = sortedGroupedOrderTypes.slice(0, halfPoint);
  const rightColumn = sortedGroupedOrderTypes.slice(halfPoint);

  const firstHalfOrderTypesByCategory = Object.entries(leftColumn);
  const secondHalfOrderTypesByCategory = Object.entries(rightColumn);

  return (
    <>
      <Box mb={2}>
        <Typography variant="h3">Order Types</Typography>
      </Box>
      <Box mb={2}>
        <Box mb={2}>
          <Typography variant="body2" style={{ color: grayDark }}>
            Select all order types you would like to offer
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {firstHalfOrderTypesByCategory.map(([key, value]) => {
              return (
                <Box mb={1.5}>
                  <OrderTypesCard
                    key={key}
                    orderTypes={value}
                    setSaveIndicatorState={setSaveIndicatorState}
                  />
                </Box>
              );
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {secondHalfOrderTypesByCategory.map(([key, value]) => {
              return (
                <Box mb={1.5}>
                  <OrderTypesCard
                    key={key}
                    orderTypes={value}
                    setSaveIndicatorState={setSaveIndicatorState}
                  />
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default OrderTypesBlock;
