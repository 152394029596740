import React, { useEffect, useState } from "react";

import { Box, Typography, Skeleton } from "@mui/material";

import {
  DownloadIcon,
  InfoIcon,
  PDFIcon,
  VideoIcon,
} from "../../../design-system/icons";
import {
  alabaster,
  granite,
  grayDark,
  grayMedium,
  white,
} from "../../../design-system/colors";
import MediaMessageLightBox from "./MediaMessageLightBox";
import chatMediaBackground from "../../../design-system/images/chatMediaBackground.svg";
import { useScreenSize, useSnackbar } from "../../../globals/hooks";
import {
  checkIsSupportedMediaType,
  getMediaVariant,
  MediaVariantEnum,
} from "../utils";

const styles = {
  downloadIcon: {
    backgroundColor: white,
    borderRadius: "50%",
    position: "absolute",
    zIndex: 1,
    display: "",
    margin: "4px",
    marginLeft: "130px",
    "&:hover": {
      backgroundColor: grayMedium,
    },
  },
  hideDownloadIcon: {
    display: "none",
  },
};

type MediaMessageProps = {
  media: any;
  isLoadedImage?: boolean;
  setIsLoadedImage?: (state: boolean) => void;
};

const MediaMessage = (props: MediaMessageProps) => {
  const { media, isLoadedImage, setIsLoadedImage } = props;

  // hooks
  const snackbar = useSnackbar();
  const { isMobileView } = useScreenSize();

  // state
  const [isHover, setIsHover] = useState(false);
  const [tempURL, setTempURL] = useState("");
  const [MediaLightBoxDialogOpen, setMediaLightBoxDialogOpen] = useState(false);

  const mediaVariant = getMediaVariant(media.contentType);

  // effects
  useEffect(() => {
    // temporary image URL expires every 5 minutes, so get a new one when it does
    // ref: https://www.twilio.com/docs/chat/media-support#downloading-media
    const getURL = async () => {
      setTempURL(await media.getContentTemporaryUrl());
    };

    getURL();

    const getNewMediaURLIntervalId = setInterval(() => {
      getURL();
    }, 1000 * 60 * 5); // 5 minutes

    return () => clearInterval(getNewMediaURLIntervalId);
  }, [media]);

  // event handlers
  const handleDialogClose = () => {
    setMediaLightBoxDialogOpen(false);
  };

  const handleDownload = async (event) => {
    event.preventDefault();

    // Fetch - twilio URL
    try {
      const response = await fetch(tempURL, {
        method: "GET",
      });

      const buffer = await response.arrayBuffer();

      ((buffer) => {
        // Will be the downloaded file's default name
        let attribute;
        if (checkIsSupportedMediaType(media.contentType, "any")) {
          // turns valid content types from variant/format -> variant.format
          // ex: image/png -> image.png  or video/mp4 -> video.mp4
          attribute = media.contentType.replace("/", ".");
        }

        const newURL = URL.createObjectURL(new Blob([buffer]));

        // creating new 'a' element to download file.
        const link = document.createElement("a");
        link.href = newURL;
        link.setAttribute("download", attribute);
        document.body.appendChild(link);
        link.click();
      })(buffer);
    } catch (err) {
      snackbar.error("Error downloading media");
    }
  };

  const handleOpenLightBox = () => {
    setMediaLightBoxDialogOpen(true);
    setIsHover(false);
  };

  // download icon visible only on valid media content type and non mobile View
  const downloadComponent =
    !isMobileView &&
    (mediaVariant === MediaVariantEnum.Image ? isLoadedImage : true) ? (
      <a href={tempURL} download onClick={handleDownload}>
        <Box
          width={32}
          height={32}
          sx={isHover ? styles.downloadIcon : styles.hideDownloadIcon}
        >
          <DownloadIcon viewBox="-9 -7 32 32" color={granite} />
        </Box>
      </a>
    ) : (
      ""
    );

  // available only for video and image content
  const lightBox = (
    <MediaMessageLightBox
      mediaVariant={mediaVariant}
      url={tempURL}
      open={MediaLightBoxDialogOpen}
      onDialogClose={handleDialogClose}
      onDownload={handleDownload}
    />
  );

  const mediaMessageComponent = () => {
    switch (mediaVariant) {
      case MediaVariantEnum.Image:
        return (
          <>
            {downloadComponent}
            {/* TODO: set height equal to actual height of image (if possible?) */}
            {!isLoadedImage && <Skeleton variant="rectangular" height={200} />}
            <img
              onLoad={() => setIsLoadedImage(true)}
              width={164}
              src={tempURL}
              alt="media"
              style={{
                borderRadius: "5px",
                position: "relative",
                margin: "auto",
                display: isLoadedImage ? "block" : "none",
              }}
              onClick={handleOpenLightBox}
            />
            {lightBox}
          </>
        );
      case MediaVariantEnum.Video:
        return (
          <>
            {downloadComponent}
            <Box
              height={70}
              border={1}
              borderColor={grayMedium}
              borderRadius="5px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={handleOpenLightBox}
              style={{
                margin: "auto",
                backgroundImage: `url(${chatMediaBackground})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                opacity: "0.8",
              }}
            >
              <Box
                height={40}
                width={40}
                borderRadius="50%"
                style={{ backgroundColor: alabaster }}
              >
                <VideoIcon
                  color={granite}
                  height="32"
                  width="32"
                  viewBox="-5 -5 28 28"
                />
              </Box>
            </Box>
            {lightBox}
          </>
        );
      case MediaVariantEnum.PDF:
        return (
          <>
            {downloadComponent}
            <a href={tempURL} target="_blank" rel="noopener noreferrer">
              <Box
                height={70}
                border={1}
                borderColor={grayMedium}
                borderRadius="5px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  margin: "auto",
                  backgroundImage: `url(${chatMediaBackground})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  opacity: 0.8,
                }}
              >
                <Box
                  height={40}
                  width={40}
                  borderRadius="50%"
                  style={{ backgroundColor: alabaster }}
                >
                  <PDFIcon
                    color={granite}
                    height="32"
                    width="32"
                    viewBox="-6 -6 28 28"
                  />
                </Box>
              </Box>
            </a>
          </>
        );
      default:
        return (
          <Box
            border={1}
            borderRadius="5px"
            borderColor={grayMedium}
            color={granite}
            display="flex"
            flex={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box m={1}>
              <InfoIcon color={grayDark} />
            </Box>
            <Box m={1}>
              <Typography variant="caption">
                Sent a file that is not supported
              </Typography>
            </Box>
          </Box>
        );
    }
  };

  return (
    <Box
      mt={1}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={{
        cursor: "pointer",
      }}
    >
      {mediaMessageComponent()}
    </Box>
  );
};

export default MediaMessage;
