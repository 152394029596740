import React from "react";

type DoublePinIconProps = {
  color?: string;
  size?: "small";
};

const DoublePinIcon = (props: DoublePinIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 10.5C8 11.3284 7.32843 12 6.5 12C5.67157 12 5 11.3284 5 10.5C5 9.67157 5.67157 9 6.5 9C7.32843 9 8 9.67157 8 10.5Z"
          fill="#999999"
        />
        <path
          d="M10.6109 2.54128C11.6424 1.55442 13.0413 1 14.5 1C15.9587 1 17.3576 1.55442 18.3891 2.54128C19.4205 3.52815 20 4.86663 20 6.26227C20 8.79596 15.8184 14.4691 14.7477 15.8794C14.7194 15.9167 14.6822 15.9471 14.6392 15.9681C14.5962 15.9891 14.5487 16 14.5004 16C14.4522 16 14.4046 15.9891 14.3616 15.9681C14.3186 15.9471 14.2814 15.9167 14.2531 15.8794C14.0951 15.6712 13.8692 15.3701 13.599 15M12 10.2623C12 12.796 7.81837 18.4691 6.7477 19.8794C6.71937 19.9167 6.68218 19.9471 6.6392 19.9681C6.59622 19.9891 6.54865 20 6.50041 20C6.45216 20 6.4046 19.9891 6.36162 19.9681C6.31863 19.9471 6.28145 19.9167 6.25311 19.8794C5.18244 18.4691 1 12.796 1 10.2623C1 8.86663 1.57946 7.52815 2.61091 6.54128C3.64236 5.55442 5.04131 5 6.5 5C7.95869 5 9.35764 5.55442 10.3891 6.54128C11.4205 7.52815 12 8.86663 12 10.2623ZM8 10.5C8 11.3284 7.32843 12 6.5 12C5.67157 12 5 11.3284 5 10.5C5 9.67157 5.67157 9 6.5 9C7.32843 9 8 9.67157 8 10.5Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 10.5C8 11.3284 7.32843 12 6.5 12C5.67157 12 5 11.3284 5 10.5C5 9.67157 5.67157 9 6.5 9C7.32843 9 8 9.67157 8 10.5Z"
        fill="#999999"
      />
      <path
        d="M10.6109 2.54128C11.6424 1.55442 13.0413 1 14.5 1C15.9587 1 17.3576 1.55442 18.3891 2.54128C19.4205 3.52815 20 4.86663 20 6.26227C20 8.79596 15.8184 14.4691 14.7477 15.8794C14.7194 15.9167 14.6822 15.9471 14.6392 15.9681C14.5962 15.9891 14.5487 16 14.5004 16C14.4522 16 14.4046 15.9891 14.3616 15.9681C14.3186 15.9471 14.2814 15.9167 14.2531 15.8794C14.0951 15.6712 13.8692 15.3701 13.599 15M12 10.2623C12 12.796 7.81837 18.4691 6.7477 19.8794C6.71937 19.9167 6.68218 19.9471 6.6392 19.9681C6.59622 19.9891 6.54865 20 6.50041 20C6.45216 20 6.4046 19.9891 6.36162 19.9681C6.31863 19.9471 6.28145 19.9167 6.25311 19.8794C5.18244 18.4691 1 12.796 1 10.2623C1 8.86663 1.57946 7.52815 2.61091 6.54128C3.64236 5.55442 5.04131 5 6.5 5C7.95869 5 9.35764 5.55442 10.3891 6.54128C11.4205 7.52815 12 8.86663 12 10.2623ZM8 10.5C8 11.3284 7.32843 12 6.5 12C5.67157 12 5 11.3284 5 10.5C5 9.67157 5.67157 9 6.5 9C7.32843 9 8 9.67157 8 10.5Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DoublePinIcon;
