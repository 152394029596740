/**
 * @file Companies.tsx
 * lists companies for an operator.
 *
 * components:
 * CompaniesPage
 */

import React from "react";

import { Box } from "@mui/material";

import CompaniesList from "../../components/contacts/companies/CompaniesList";
import { useScreenSize } from "../../globals/hooks";
import ContactsPageTabs from "../../components/contacts/ContactsPageTabs";
import theme, { SIDE_NAV_WIDTH } from "theme";

function CompaniesPage() {
  // hooks
  const { isSmallTabletView } = useScreenSize();

  const tableStyles = {
    maxWidth: "100vw",
    [theme.breakpoints.up("sm")]: {
      maxWidth: `calc(100vw - ${SIDE_NAV_WIDTH})`,
    },
    overflowX: "auto",
    overflowY: "hidden",
    display: "flex",
    flex: 2,
    height: "100%",
  } as const;

  return (
    <>
      <Box justifyContent="center" width="100%">
        <ContactsPageTabs
          title="Companies"
          entityName="Company"
          createEntityRoute="/companies/create"
          tabValue={1}
        />
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            maxWidth: "900px",
            margin: "auto",
            overflowY: "auto",
            flexShrink: 0,
          }}
        >
          <Box {...(isSmallTabletView && { sx: { tableStyles } })}>
            <CompaniesList />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default CompaniesPage;
