import React from "react";

import { Box } from "@mui/material";
import { Toolbar } from "@devexpress/dx-react-scheduler-material-ui";

import { grayLight, white } from "../../../../design-system/colors";
import { useScreenSize } from "../../../../globals/hooks";

const styles = {
  viewFilterOptions: {
    "&.MuiSelect-select.MuiSelect-select": {
      textTransform: "initial",
    },
  },
};

function CustomToolbar(props) {
  const { style } = props;

  const { isMobileView } = useScreenSize();

  return (
    <Box
      bgcolor={white}
      ml={-10}
      borderBottom={`1px solid ${grayLight}`}
      {...(!isMobileView && { mr: -10 })}
    >
      <Box ml={!isMobileView ? 5 : 10} {...(!isMobileView && { mr: 7 })}>
        <Toolbar.Root
          style={{
            ...style,
            borderBottom: "none",
          }}
          sx={styles.viewFilterOptions}
          {...props}
        />
      </Box>
    </Box>
  );
}

export default CustomToolbar;
