// Scheduler Docs: https://devexpress.github.io/devextreme-reactive/react/scheduler/docs/guides/getting-started/

import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import first from "lodash/first";

import { Getter } from "@devexpress/dx-react-core";
import { ResourceInstance, ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  WeekView,
  Appointments,
  Toolbar,
  ViewSwitcher,
  MonthView,
  DateNavigator,
  AppointmentTooltip,
  Resources,
} from "@devexpress/dx-react-scheduler-material-ui";

import {
  getMinutesOfCurrentDay,
  applyUTCOffsetToTime,
} from "../../../globals/utils/helpers";
import { useLocalStorage } from "../../../globals/hooks/useLocalStorage";
import {
  CustomAppointmentContent,
  CustomAppointmentTooltipContent,
  CustomAppointmentTooltipHeader,
  CustomToolbar,
} from "../DispatchScheduler/customs";
import AppointmentsFilter from "./AppointmentsFilter";
import { useScreenSize } from "../../../globals/hooks/useScreenSize";
import { OperatorRoute } from "../../../types";
import { black, white } from "../../../design-system/colors";
import compact from "lodash/compact";
import uniqBy from "lodash/uniqBy";
import map from "lodash/map";

const ESTIMATED = "estimated";
const SAVED = "saved";
const NONE = "none";

type DispatchSchedulerProps = {
  appointmentFilterRef: MutableRefObject<any>;
  appointmentFilterValue: string;
  vehiclesWithColors: any[];
  operatorRoutes: OperatorRoute[];
  onFilterValueChange: (filterValue: string) => void;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
  selectedDate: Date;
};

function DispatchScheduler(props: DispatchSchedulerProps) {
  const {
    appointmentFilterRef,
    appointmentFilterValue,
    vehiclesWithColors,
    operatorRoutes,
    onFilterValueChange,
    setSelectedDate,
    selectedDate,
  } = props;

  // hooks
  const history = useHistory();
  const firstAppointmentRef = useRef(null);
  const schedulerRef = useRef(null);
  const { isMobileView } = useScreenSize();

  // state
  const [firstAppointmentRouteId, setFirstAppointmentRouteId] = useState();
  const [schedulerHeight, setSchedulerHeight] = useState(660);
  const [dataSource, setDataSource] = useState([]);
  const [currentViewName, setCurrentViewName] = useLocalStorage(
    "schedulerCurrentViewName",
    "day"
  );

  const uniqueVehicles = compact(uniqBy(map(operatorRoutes, "vehicle"), "id"));

  useEffect(() => {
    const allAppointments = operatorRoutes.map((operatorRoute) => {
      const {
        trip,
        vehicle,
        id: routeId,
        isFarmedRoute,
        farmRelationship,
      } = operatorRoute;

      const lastStop = trip.stops.length - 1;

      const tripStartTime = trip.stops[0].dateTime;
      let tripEndTime = trip.stops[lastStop]?.dateTime || null;
      let endTimeType: "saved" | "estimated" | "none" = SAVED;

      if (!tripEndTime && trip?.estimatedDuration) {
        endTimeType = ESTIMATED;
        tripEndTime = moment(tripStartTime)
          .add(trip?.estimatedDuration, "m")
          .toISOString();
      } else if (!tripEndTime && !trip?.estimatedDuration) {
        endTimeType = NONE;
        tripEndTime = moment(tripStartTime).add(1, "h").toISOString();
      }

      // scheduler auto adjusts times to local timezone
      // this adjusts times to correctly display on the scheduler
      const adjustedStartDate = applyUTCOffsetToTime(tripStartTime, "subtract");

      const adjustedEndDate = tripEndTime
        ? applyUTCOffsetToTime(tripEndTime, "subtract")
        : null;

      const associatedVehicle = vehiclesWithColors?.find(
        (uniqueVeh) => uniqueVeh.id === vehicle?.id
      );

      const appointment = {
        isFarmedRoute,
        farmRelationship,
        endTimeType,
        routeId,
        title: vehicle?.name || "No Vehicle",
        startDate: adjustedStartDate,
        ...(adjustedEndDate && {
          endDate: adjustedEndDate,
        }),
        vehicleId: vehicle?.id,
        appointmentBgColor: isFarmedRoute // set farmedRoute bgcolor to black
          ? black
          : associatedVehicle?.backgroundColor,
        appointmentTextColor: isFarmedRoute // set farmedRoute text color to white
          ? white
          : associatedVehicle?.textColor,
        pickUpFlightOffset: first(trip.stops).flightOffset,
        pickUpOriginalDateTime: first(trip.stops).originalDateTime,
        dateTime: first(trip.stops).dateTime,
      };

      return appointment;
    });

    let filteredAppointments;
    if (appointmentFilterValue !== "all") {
      filteredAppointments = allAppointments?.filter(
        (item) => item.vehicleId === appointmentFilterValue
      );
    }

    setDataSource(filteredAppointments || allAppointments);
  }, [appointmentFilterValue, operatorRoutes, vehiclesWithColors]);

  useEffect(() => {
    // scroll to first appointment
    setTimeout(() => {
      firstAppointmentRef?.current?.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    });
  }, [firstAppointmentRef, firstAppointmentRouteId]);

  // set height of scheduler
  useEffect(() => {
    const appointmentRefHeight = isMobileView
      ? appointmentFilterRef?.current?.getBoundingClientRect().height
      : 0;

    const heightAboveScheduler =
      schedulerRef?.current?.getBoundingClientRect()?.top +
      appointmentRefHeight;

    setSchedulerHeight(window.innerHeight - heightAboveScheduler);
  }, [appointmentFilterRef, isMobileView]);

  const handleCurrentViewNameChange = (currentViewName) => {
    history.push({ search: `?view=${currentViewName}` });
    setCurrentViewName(currentViewName);
  };

  const resourceInstances: ResourceInstance[] = dataSource?.length
    ? dataSource.map((veh) => ({
        id: veh.vehicleId,
        text: veh.title,
        color: veh.appointmentBgColor,
      }))
    : [];

  // scheduler props
  const timeTableAppointmentsComputed = useCallback(
    // finds the earliest appointment being rendered and saves it
    ({ timeTableAppointments }) => {
      const schedulerAppointments = timeTableAppointments?.[0];
      const earliestAppointment = schedulerAppointments.reduce(
        (earliestApp, currentApp) => {
          const earliestAppTime = getMinutesOfCurrentDay(
            earliestApp?.dataItem?.startDate
          );
          const currentAppTime = getMinutesOfCurrentDay(
            currentApp?.dataItem?.startDate
          );
          return earliestAppTime < currentAppTime ? earliestApp : currentApp;
        },
        schedulerAppointments?.[0]
      );

      setFirstAppointmentRouteId(earliestAppointment?.dataItem?.routeId);
      return timeTableAppointments;
    },
    [setFirstAppointmentRouteId]
  );

  const CustomAppointment = useCallback(
    // attaches the ref to the earliest appointment
    (props: Appointments.AppointmentProps) => {
      return (
        <div
          {...(props.data.routeId === firstAppointmentRouteId && {
            ref: firstAppointmentRef,
          })}
        >
          <Appointments.Appointment {...props} />
        </div>
      );
    },
    [firstAppointmentRef, firstAppointmentRouteId]
  );

  return (
    <div ref={schedulerRef}>
      {isMobileView && (
        <AppointmentsFilter
          filterOptions={uniqueVehicles}
          currentFilterValue={appointmentFilterValue}
          onChange={onFilterValueChange}
          appointmentFilterRef={appointmentFilterRef}
        />
      )}
      <Scheduler data={dataSource} height={schedulerHeight}>
        <ViewState
          defaultCurrentDate={selectedDate}
          currentViewName={currentViewName}
          onCurrentViewNameChange={handleCurrentViewNameChange}
          onCurrentDateChange={(date) => {
            setSelectedDate(date);
          }}
        />
        <DayView name="day" displayName="Day" />
        <WeekView name="week" displayName="Week" />
        <MonthView name="month" displayName="Month" />
        <Getter
          name="timeTableAppointments"
          computed={timeTableAppointmentsComputed}
        />
        <Appointments
          appointmentContentComponent={CustomAppointmentContent}
          appointmentComponent={CustomAppointment}
        />
        {!!resourceInstances?.length && (
          <Resources
            data={[
              {
                fieldName: "vehicleId",
                instances: resourceInstances,
              },
            ]}
          />
        )}
        <Toolbar rootComponent={CustomToolbar} />
        <ViewSwitcher />
        <AppointmentTooltip
          headerComponent={CustomAppointmentTooltipHeader}
          contentComponent={CustomAppointmentTooltipContent}
          showOpenButton
          showCloseButton
        />
        <DateNavigator />
      </Scheduler>
    </div>
  );
}

export default DispatchScheduler;
