import React from "react";
import moment from "moment";
import { useMutation } from "@apollo/client";

import { Box, Typography } from "@mui/material";

import { grayDark, tintGreen, successGreen } from "../../design-system/colors";
import { Operator } from "../../types";
import RoundChip from "../../design-system/components/chips/RoundChip";
import { EditIcon, OpenIcon, ViewIcon } from "../../design-system/icons";
import { useSnackbar } from "../../globals/hooks";
import { GENERATE_DUDA_SSO_LINK_MUTATION } from "../../globals/graphql";
import ActionButton from "design-system/components/ActionButton";

type WebsiteInfoSectionDesktopProps = {
  operator: Partial<Operator>;
};

function WebsiteInfoSectionDesktop(props: WebsiteInfoSectionDesktopProps) {
  const { operator } = props;

  // hooks
  const snackbar = useSnackbar();

  const previewUrl = operator.dudaWebsite.previewSiteUrl;
  const websiteUrl = operator.dudaWebsite.siteDomain;
  const createdAt = moment(operator.dudaWebsite.createdAt).format("LLL");
  const publishStatus = operator.dudaWebsite.publishStatus;

  // mutations
  const [generateSSOLink] = useMutation(GENERATE_DUDA_SSO_LINK_MUTATION, {
    onCompleted(data) {
      const ssoLink = data.generateSSOLinkToDudaWebsiteEditor.ssoLink;
      snackbar.success(
        `Redirecting to site editor! \nIf a new window does not pop up, click the link below.`,
        {
          isExternalLink: true,
          link: ssoLink,
          linkLabel: `Launch Site Editor`,
        }
      );
      const win = window.open(ssoLink, "_blank");
      win.focus();
    },
    onError(error) {
      snackbar.error("Error generating SSO link. Please try again.");
    },
  });

  // event handlers
  const handleOpenWebsitePreview = () => {
    const win = window.open(previewUrl, "_blank");
    win.focus();
  };

  const handleOpenWebsiteUrl = () => {
    const win = window.open(`http://${websiteUrl}`, "_blank");
    win.focus();
  };

  const handleOpenWebsiteEditor = () => {
    snackbar.success("Securely logging into website editor...");
    generateSSOLink({
      variables: {
        input: {},
      },
    });
  };

  return (
    <>
      <Box>
        <Typography variant="overline">Website</Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box mt={1.5} display="flex" flexDirection="column">
            <Box mb={1}>
              <Typography variant="subtitle1">{websiteUrl}</Typography>
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="caption" style={{ color: grayDark }}>
                {createdAt}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box mr={2}>
              <RoundChip
                label={publishStatus}
                backgroundColor={tintGreen}
                color={successGreen}
              />
            </Box>
            <Box mr={1}>
              <ActionButton
                icon={ViewIcon}
                label="Preview Site"
                maxWidth={65}
                onClick={handleOpenWebsitePreview}
              />
            </Box>
            <Box mr={1}>
              <ActionButton
                icon={EditIcon}
                label="Edit Site"
                maxWidth={65}
                onClick={handleOpenWebsiteEditor}
              />
            </Box>
            <Box>
              <ActionButton
                icon={OpenIcon}
                label="Open Site"
                maxWidth={65}
                onClick={handleOpenWebsiteUrl}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default WebsiteInfoSectionDesktop;
