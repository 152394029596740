import React from "react";

import { Box, Step, Stepper, StepLabel } from "@mui/material";

import { ProgressConnector } from "./components";
import { SubHeaderStepsEnum } from "../../utils/getSubHeaderSteps";
import { granite, moovsBlue } from "design-system/colors";

type CreatePromoCodeHeaderStepperProps = {
  subHeaderSteps: SubHeaderStepsEnum[];
  activeStepExcludingTypeSelectStep: number;
};

function CreatePromoCodeHeaderStepper(
  props: CreatePromoCodeHeaderStepperProps
) {
  const { subHeaderSteps, activeStepExcludingTypeSelectStep } = props;

  return (
    <Box mt={1}>
      <Stepper
        activeStep={activeStepExcludingTypeSelectStep}
        connector={<ProgressConnector />}
        alternativeLabel
      >
        {subHeaderSteps.map((label, index) => {
          const showPartialProgress =
            index === activeStepExcludingTypeSelectStep + 1; // connector to next step

          return (
            <Step
              key={index}
              disabled={showPartialProgress} // this will display partial progress bar
            >
              <StepLabel
                sx={{
                  "&& .Mui-active": {
                    color: moovsBlue,
                  },
                  "&& .Mui-completed": {
                    color: granite,
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

export default CreatePromoCodeHeaderStepper;
