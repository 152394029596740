import React, { useMemo } from "react";
import clipboardCopy from "clipboard-copy";
import toLower from "lodash/toLower";
import first from "lodash/first";

import { Box, Typography } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";
import { grayDark } from "design-system/colors";
import {
  ManifestIcon,
  PaymentIcon,
  PriceBreakdownIcon,
  ReservationIcon,
  StarIcon,
  UserIcon,
  WheelIcon,
} from "design-system/icons";
import {
  useAnalytics,
  useOperator,
  useScreenSize,
  useSnackbar,
} from "globals/hooks";
import DialogItemDesktop from "components/DialogItemDesktop";
import DialogItemMobile from "components/DialogItemMobile";
import { FarmRelationshipEnum, Trip } from "types";
import { fromGlobalId, toGlobalId } from "globals/utils/helpers";

const { Farmor, Farmee } = FarmRelationshipEnum;

type CopyLinksDialogProps = {
  open: boolean;
  onClose: () => void;
  requestId: string;
  trip: Trip;
};

function CopyLinksDialog(props: CopyLinksDialogProps) {
  const { open, onClose, trip, requestId } = props;
  const { publicId, routeDriver, id, farmRelationship } = first(trip.routes);
  const driverId = routeDriver?.driver.id;
  const tripId = trip.id;

  // hooks
  const {
    nameSlug,
    moovsMarketApps: { reviewPlatform },
  } = useOperator();
  const { isMobileView } = useScreenSize();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // state

  // convert route to operatorRouteId so this works when only have access to route
  const operatorRouteId = useMemo(
    () => toGlobalId(fromGlobalId(id).id, "OperatorRoute"),
    [id]
  );

  const linksConfig = useMemo(
    () => [
      {
        label: "Passenger",
        icon: UserIcon,
        link: `${process.env.REACT_APP_CUSTOMERAPP_URL}/${nameSlug}/trip/${publicId}`,
        hide: farmRelationship === Farmee,
      },
      {
        label: "Driver",
        icon: WheelIcon,
        link: `${process.env.REACT_APP_DRIVERAPP_URL}/driver/${driverId}/dispatch/${operatorRouteId}`,
        disabled: !driverId || farmRelationship === Farmor,
        disableTooltipText: !driverId
          ? "Driver is not assigned"
          : "Driver Link is not available for farmed-out trips",
      },
      {
        label: "Payment",
        icon: PaymentIcon,
        link: `${process.env.REACT_APP_CUSTOMERAPP_URL}/${nameSlug}/price-summary/${requestId}/pay`,
        hide: farmRelationship === Farmee,
      },
      {
        label: "Price Summary",
        icon: PriceBreakdownIcon,
        link: `${process.env.REACT_APP_CUSTOMERAPP_URL}/${nameSlug}/price-summary/${requestId}`,
      },
      {
        label: "Reservation",
        icon: ReservationIcon,
        link: `${process.env.REACT_APP_CUSTOMERAPP_URL}/${nameSlug}/order/${requestId}`,
        hide: farmRelationship === Farmee,
      },
      {
        label: "Reservation Manifest",
        icon: ManifestIcon,
        link: `${process.env.REACT_APP_CUSTOMERAPP_URL}/${nameSlug}/dispatch/${requestId}`,
        hide: farmRelationship === Farmee,
      },
      {
        label: "Trip Review",
        icon: StarIcon,
        link: `${process.env.REACT_APP_CUSTOMERAPP_URL}/${nameSlug}/trip-review/${tripId}`,
        hide: farmRelationship === Farmee,
        disabled: !reviewPlatform.active,
        disableTooltipText:
          "Install Review Platform from Moovs Market to enable",
      },
    ],
    [
      nameSlug,
      requestId,
      publicId,
      driverId,
      operatorRouteId,
      farmRelationship,
      reviewPlatform,
      tripId,
    ]
  );

  // event handlers
  const onCopy =
    ({ label, link }) =>
    async () => {
      const lowerLabel = toLower(label);

      try {
        await clipboardCopy(link);

        track("reservation_linkCopied", { linkType: lowerLabel });

        snackbar.success(`Successfully copied ${lowerLabel} link!`, {
          link,
          linkLabel: "Open",
          isExternalLink: true,
        });

        onClose();
      } catch (error) {
        snackbar.error(`Could not copy ${lowerLabel} link`);
      }
    };

  const onOpen =
    ({ label, link }) =>
    (e: React.MouseEvent) => {
      e.stopPropagation();
      track("reservation_linkOpened", { linkType: toLower(label) });
      onClose();
      window.open(link, "_blank").focus();
    };

  const CopyLinkItem = isMobileView ? DialogItemMobile : DialogItemDesktop;

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      dialogTitle="Links"
      size="sm"
      subHeaderComponent={
        !isMobileView && (
          <Box>
            <Typography variant="body1" color={grayDark}>
              Click to copy link
            </Typography>
          </Box>
        )
      }
    >
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {linksConfig.map(
          ({ label, icon, link, disabled, hide, disableTooltipText }) =>
            !hide && (
              <CopyLinkItem
                key={label}
                label={label}
                icon={icon}
                disabled={disabled}
                disableTooltipText={disableTooltipText}
                onClickPrimary={onCopy({ label, link })}
                onClickOpen={onOpen({ label, link })}
                primaryAction="Copy"
              />
            )
        )}
      </Box>
    </MoovsDialog>
  );
}

export default CopyLinksDialog;
