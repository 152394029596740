import React, { useState, useEffect, useMemo, ChangeEvent } from "react";
import { useMutation } from "@apollo/client";
import some from "lodash/some";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";

import { Box } from "@mui/material";

import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import LocationAutoComplete from "../../autocompletes/LocationAutoComplete";
import { CREATE_STOP_MUTATION } from "../../../globals/graphql";
import {
  AddressIcon,
  CalendarIcon,
  GpsLocationIcon,
} from "../../../design-system/icons";
import { white, granite } from "../../../design-system/colors";
import MoovsDialog from "../../MoovsDialog";
import MoovsDateTimePicker from "components/MoovsDateTimePicker";
import {
  applyUTCOffsetToTime,
  checkIfStopTimesAreInOrder,
} from "globals/utils/helpers";
import { Stop } from "types";
import CharacterLimitedTextInput from "components/inputs/CharacterLimitedTextInput";

type CreateStopDialogProps = {
  open: boolean;
  onClose: () => void;
  tripId: string;
  suggestedAddressInfo: any;
  isShuttlePickUp: boolean;
  isShuttleTrip: boolean;
  stopsLength: number;
  stops?: Stop[];
};

function CreateStopDialog(props: CreateStopDialogProps) {
  const {
    open,
    onClose,
    tripId,
    suggestedAddressInfo,
    isShuttlePickUp,
    isShuttleTrip,
    stopsLength,
    stops,
  } = props;

  // state
  const [location, setLocation] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [stopDateTime, setStopDateTime] = useState(null);
  const [stopDateTimeError, setStopDateTimeError] = useState(false);
  const [stopDateTimeErrorText, setStopDateTimeErrorText] = useState("");
  const [locationAlias, setLocationAlias] = useState(null);
  const [locationAliasError, setLocationAliasError] = useState(false);

  // hooks
  const snackbar = useSnackbar();
  const adjustedStopDateTime = useMemo(
    () =>
      stopDateTime
        ? applyUTCOffsetToTime(stopDateTime, "subtract").toISOString()
        : null,
    [stopDateTime]
  );

  // effects
  useEffect(() => {
    if (open) {
      setLocation(null);
      setStopDateTime(null);
      setStopDateTimeError(false);
      setLocationAlias(null);
    }
  }, [open]);

  const [createStop] = useMutation(CREATE_STOP_MUTATION, {
    refetchQueries: ["Trip", "OperatorRoute"],
    onCompleted() {
      setIsSaving(false);
      onClose();
      snackbar.success("Successfully created stop!");
    },
    onError() {
      setIsSaving(false);
      snackbar.error("Error creating stop");
    },
  });

  const handleSaveClick = () => {
    if (isShuttlePickUp) {
      if (!stopDateTime) {
        setStopDateTimeError(true);
        setStopDateTimeErrorText("This field is required.");
        return;
      }

      const temporaryStops = cloneDeep(stops);

      temporaryStops.splice(stopsLength - 1, 0, {
        dateTime: stopDateTime,
      } as Stop);

      const stopsAreNotInOrder = checkIfStopTimesAreInOrder(temporaryStops);

      if (some(stopsAreNotInOrder)) {
        setStopDateTimeError(true);
        setStopDateTimeErrorText(
          "Stop must come after previous stop and before drop-off."
        );
        return;
      }
    }

    setIsSaving(true);

    createStop({
      variables: {
        input: {
          tripId,
          location,
          dateTime: stopDateTime,
          locationAlias,
        },
      },
    });
  };

  const handleAcceptStopDateTime = (date: unknown) => {
    setStopDateTime(moment(applyUTCOffsetToTime(date, "add")).toISOString());

    setStopDateTimeError(false);
    setStopDateTimeErrorText("");
  };

  const handleLocationAliasChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    if (newValue.length > 35) {
      setLocationAliasError(true);
    } else {
      setLocationAliasError(false);
    }

    setLocationAlias(newValue || "");
  };

  return (
    <MoovsDialog
      hideTopBorder
      hideLoadingIndicator={!isSaving}
      open={open}
      onClose={onClose}
      dialogTitle="Add Stop"
      size="xs"
      onAccept={handleSaveClick}
      acceptButtonText="Save"
      acceptDisabled={!location || isSaving || locationAliasError}
    >
      <Box flex="1" mb={3} bgcolor={white} display="flex" flexDirection="row">
        <Box mr={2} display="flex" justifyContent="center" alignItems="center">
          <AddressIcon color={granite} />
        </Box>
        {/* location */}
        <LocationAutoComplete
          required
          label="Address"
          fullWidth
          value={location}
          onChange={(value) => setLocation(value.description || "")}
          suggestedAddressInfo={suggestedAddressInfo}
        />
      </Box>
      {isShuttleTrip && (
        <Box flex="1" mb={3} bgcolor={white} display="flex" flexDirection="row">
          <Box
            mr={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <GpsLocationIcon color={granite} />
          </Box>
          {/* locationAlias */}
          <CharacterLimitedTextInput
            label="Location Name"
            name="locationAlias"
            value={locationAlias || ""}
            onChange={handleLocationAliasChange}
          />
        </Box>
      )}
      {isShuttlePickUp && (
        <Box
          flex="1"
          mb={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Box
            mr={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CalendarIcon color={granite} />
          </Box>
          {/* date time */}
          <MoovsDateTimePicker
            hideCalendarIcon
            inputFormat="MMM Do YYYY, h:mm a"
            label={`Stop ${stopsLength - 1} Date & Time`}
            value={adjustedStopDateTime}
            onAccept={handleAcceptStopDateTime}
            renderInputProps={{
              error: stopDateTimeError,
              helperText: stopDateTimeErrorText,
            }}
          />
        </Box>
      )}
    </MoovsDialog>
  );
}

export default CreateStopDialog;
