import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import { Box } from "@mui/material";

import { Contact, Company } from "types";
import { useSnackbar } from "globals/hooks/useSnackbar";
import { LOAD_COMPANY_QUERY, UPDATE_CONTACT_MUTATION } from "globals/graphql";
import ContactAutoComplete from "components/autocompletes/ContactAutoComplete";
import MoovsDialog from "components/MoovsDialog";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { useAnalytics } from "globals/hooks";

type AddContactToCompanyDialogProps = {
  open: boolean;
  handleClose: () => void;
  company: Company;
};

function AddContactToCompanyDialog(props: AddContactToCompanyDialogProps) {
  const { open, handleClose, company } = props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // state
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);

  // mutations
  const [updateContact, { loading }] = useMutation(UPDATE_CONTACT_MUTATION, {
    refetchQueries: [LOAD_COMPANY_QUERY],
    onCompleted() {
      snackbar.success(
        `Successfully added contact ${selectedContact.firstName} ${selectedContact.lastName} to ${company.name}!`
      );
      handleClose();
    },
    onError(error) {
      const errorMessage =
        getErrorMessage(error) || "Error creating company contact";

      snackbar.error(errorMessage);
    },
  });

  const handleClickSave = () => {
    handleAddCompanyToContact(selectedContact);
  };

  const handleAddCompanyToContact = (contact: Contact) => {
    track("company_contactAdded");

    updateContact({
      variables: {
        input: {
          id: contact.id,
          companyId: company ? company.id : null,
        },
      },
    });
  };

  return (
    <MoovsDialog
      open={open}
      onClose={handleClose}
      dialogTitle="Add Contact"
      closeButtonText="Cancel"
      acceptButtonText="Save"
      onAccept={handleClickSave}
      acceptDisabled={!selectedContact || loading}
      hideLoadingIndicator={!selectedContact}
      size="xs"
    >
      <Box mb={1}>
        <ContactAutoComplete
          onChange={setSelectedContact}
          value={selectedContact}
        />
      </Box>
    </MoovsDialog>
  );
}

export default AddContactToCompanyDialog;
