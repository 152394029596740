import React from "react";
import { useFormContext } from "react-hook-form";

import { Box, Typography } from "@mui/material";

import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";
import { OpenClosedTrip } from "types";
import { useScreenSize } from "globals/hooks";
import { FilterProps } from "../FilterDispatchTripsDrawer";

function CloseStatusToggle(props: FilterProps) {
  const { disabledSelect } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const { watch, setValue } = useFormContext();

  const closeStatusToggleButtons = [
    {
      value: null,
      label: "All",
    },
    {
      value: OpenClosedTrip.Open,
      label: "Open",
    },
    {
      value: OpenClosedTrip.Closed,
      label: "Closed",
    },
  ];

  return (
    <Box mb={2}>
      <Typography variant="h3" mb={2}>
        Close Status
      </Typography>

      <MoovsToggleButtonGroup
        onChange={(_, newValue) => setValue("openClosedTrip", newValue)}
        options={closeStatusToggleButtons}
        value={watch("openClosedTrip")}
        size="large"
        fullWidth={isMobileView}
        disabled={disabledSelect}
        tooltipText="Upgrade plan to Pro in order to use filtering feature"
      />
    </Box>
  );
}

export default CloseStatusToggle;
