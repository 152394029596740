/**
 * @file Provider.tsx
 * ContextProvider for Global Snackbar
 *
 * Based heavily on the below reference.
 *
 * reference:
 * https://github.com/schiehll/react-alert
 * https://material-ui.com/components/snackbars/#consecutive-snackbars
 *
 * author: jackv
 */

import React, { useCallback, useState, useRef } from "react";
import SnackbarAlert from "./SnackbarAlert";
import SnackbarContext from "./Context";

function SnackbarProvider({ children }) {
  const snackbarContext = useRef(null);
  const alertQueue = useRef([]);
  const [currentAlert, setCurrentAlert] = useState();
  const [open, setOpen] = useState(false);

  const processQueue = useCallback(() => {
    if (alertQueue.current.length > 0) {
      setCurrentAlert(alertQueue.current.shift());
      setOpen(true);
    }
  }, []);

  const handleClose = useCallback(
    (_event: React.SyntheticEvent | MouseEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    },
    []
  );

  const handleExited = useCallback(() => {
    processQueue();
  }, [processQueue]);

  const show = useCallback(
    (message = "", options = {}) => {
      // creates randomID so each snackbar has a unique ID.
      const snackbarId = Math.random().toString(36).substr(2, 9);

      alertQueue.current.push({
        ...options,
        snackbarId,
        message,
      });

      if (open) {
        setOpen(false);
      } else {
        processQueue();
      }
    },
    [open, processQueue]
  );

  const success = useCallback(
    (message = "", options = {}) => {
      options.severity = "success";
      return show(message, options);
    },
    [show]
  );

  const error = useCallback(
    (message = "", options = {}) => {
      options.severity = "error";
      return show(message, options);
    },
    [show]
  );

  const info = useCallback(
    (message = "", options = {}) => {
      options.severity = "info";
      return show(message, options);
    },
    [show]
  );

  const warning = useCallback(
    (message = "", options = {}) => {
      options.severity = "warning";
      return show(message, options);
    },
    [show]
  );

  snackbarContext.current = {
    success,
    error,
    info,
    warning,
  };

  return (
    <SnackbarContext.Provider value={snackbarContext}>
      <SnackbarAlert
        handleClose={handleClose}
        handleExited={handleExited}
        open={open}
        // @ts-ignore
        {...currentAlert}
      />
      {children}
    </SnackbarContext.Provider>
  );
}

export default SnackbarProvider;
