import React, { ChangeEvent } from "react";
import { useMutation } from "@apollo/client";
import { Box, Typography } from "@mui/material";

import LabeledSwitchInput from "../../LabeledSwitchInput";
import {
  EmptyVariables,
  ENABLE_AUTOMATED_TEXTS_MUTATION,
  DISABLE_AUTOMATED_TEXTS_MUTATION,
  UPDATE_OPERATOR_SETTINGS,
} from "globals/graphql";
import { useSnackbar, useOperator, useAnalytics } from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import LabelTextLink from "./components/LabelTextLink";

const SEND_TRIP_REMINDER_TEXTS_LINK =
  "https://intercom.help/moovs-05c940f1970e/en/articles/8006883-driver-notification-send-trip-reminder-texts-to-driver";
const REMIND_ACCEPT_OR_DENY_LINK =
  "https://intercom.help/moovs-05c940f1970e/en/articles/8007469-driver-notification-remind-driver-to-accept-or-deny-pending-jobs";
const REMIND_UPDATE_TRIP_STATUSES_LINK =
  "https://intercom.help/moovs-05c940f1970e/en/articles/8007480-driver-notification-remind-driver-to-update-trip-statuses";
const SEND_UPDATED_FLIGHT_TIME_LINK =
  "https://intercom.help/moovs-05c940f1970e/en/articles/8002457-driver-notifications-send-driver-a-text-if-flight-time-is-updated";
const NOTIFY_DRIVER_TRIP_CHANGE_LINK =
  "https://intercom.help/moovs-05c940f1970e/en/articles/9875130-driver-notification-updates-in-a-trip";

type DriversToggleBlockProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function DriversToggleBlock(props: DriversToggleBlockProps) {
  const { setSaveIndicatorState } = props;

  // hooks
  const snackbar = useSnackbar();
  const {
    driverReminderTripReminderTextsEnabled,
    driverReminderUpdateTripStatusTextEnabled,
    driverReminderPendingJobsTextEnabled,
    settings: {
      driverFlightUpdatedTextsEnabled,
      notifyDriverTripChangeEnabled,
    },
  } = useOperator();
  const { track } = useAnalytics();

  // mutations
  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      snackbar.error("error updating operator settings");
    },
  });

  const [enableAutomatedTexts] = useMutation(ENABLE_AUTOMATED_TEXTS_MUTATION, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      const errorMessage =
        getErrorMessage(error) || "Error enabling automated texts.";

      snackbar.error(errorMessage);
    },
    variables: EmptyVariables,
  });

  const [disableAutomatedTexts] = useMutation(
    DISABLE_AUTOMATED_TEXTS_MUTATION,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        const errorMessage =
          getErrorMessage(error) || "Error disabling automated texts.";

        snackbar.error(errorMessage);
      },
      variables: EmptyVariables,
    }
  );

  // event handlers
  const handleAutomatedTripReminderTextsChange = () => {
    setSaveIndicatorState("loading");

    driverReminderTripReminderTextsEnabled
      ? disableAutomatedTexts()
      : enableAutomatedTexts();
  };

  const handleAutomatedTripStatusReminderTextsChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setSaveIndicatorState("loading");
    updateOperatorSettings({
      variables: {
        input: {
          remindDriverTripStatus: event.target.checked,
        },
      },
    });
  };

  const handleAutomatedJobReminderTextsChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setSaveIndicatorState("loading");
    updateOperatorSettings({
      variables: {
        input: {
          remindDriverPendingJobs: event.target.checked,
        },
      },
    });
  };

  const handleFlightUpdateReminderTextsChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setSaveIndicatorState("loading");
    if (event.target.checked) {
      track("driverSetting_flightOn");
    }

    updateOperatorSettings({
      variables: {
        input: {
          driverFlightUpdatedTextsEnabled: event.target.checked,
        },
      },
    });
  };

  const handleNotifyDriverTripChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setSaveIndicatorState("loading");
    updateOperatorSettings({
      variables: {
        input: {
          notifyDriverTripChangeEnabled: event.target.checked,
        },
      },
    });
  };

  return (
    <>
      <Typography variant="h4">Drivers</Typography>
      <Box mt={2}>
        <LabeledSwitchInput
          name="tripReminderTexts"
          checked={driverReminderTripReminderTextsEnabled}
          onChange={handleAutomatedTripReminderTextsChange}
          text={
            <LabelTextLink
              text="Send trip reminder texts to driver"
              link={SEND_TRIP_REMINDER_TEXTS_LINK}
            />
          }
          subText="When this is turned on, drivers will receive automated trip reminder text messages 24 hours & 1 hour before trip"
        />
      </Box>
      <Box mt={2}>
        <LabeledSwitchInput
          name="pendingJobReminderTexts"
          checked={driverReminderPendingJobsTextEnabled}
          onChange={handleAutomatedJobReminderTextsChange}
          text={
            <LabelTextLink
              text="Remind driver to accept or deny pending jobs"
              link={REMIND_ACCEPT_OR_DENY_LINK}
            />
          }
          subText="When this is turned on, drivers will receive automated notification to accept/deny jobs every two hours from 8:00 AM to 10:00 PM until job is accepted or denied"
        />
      </Box>
      <Box mt={2}>
        <LabeledSwitchInput
          name="updateTripStatusReminderTexts"
          checked={driverReminderUpdateTripStatusTextEnabled}
          onChange={handleAutomatedTripStatusReminderTextsChange}
          text={
            <LabelTextLink
              text="Remind driver to update trip statuses"
              link={REMIND_UPDATE_TRIP_STATUSES_LINK}
            />
          }
          subText="When this is on, drivers will receive automated notifications to update their trip status - up to four reminders will be sent per status"
        />
      </Box>
      <Box mt={2}>
        <LabeledSwitchInput
          name="flightUpdateReminderTexts"
          checked={driverFlightUpdatedTextsEnabled}
          onChange={handleFlightUpdateReminderTextsChange}
          text={
            <LabelTextLink
              text="Send driver a text if flight time is updated"
              link={SEND_UPDATED_FLIGHT_TIME_LINK}
            />
          }
          subText="When this is turned on, automated text messages will be sent to drivers if flight is updated by more than 15 minutes."
        />
      </Box>
      <Box mt={2}>
        <LabeledSwitchInput
          name="notifyDriverTripChangeEnabled"
          checked={notifyDriverTripChangeEnabled}
          onChange={handleNotifyDriverTripChange}
          text={
            <LabelTextLink
              text="Send driver trip update notifications"
              link={NOTIFY_DRIVER_TRIP_CHANGE_LINK}
            />
          }
          subText="When this is turned on and a trip’s type, notes, driver notes, pick-up / drop-off date & time, vehicle or child seats are updated, drivers will receive an automated notification"
        />
      </Box>
    </>
  );
}

export default DriversToggleBlock;
