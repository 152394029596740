import React, { forwardRef, useState, useImperativeHandle } from "react";

import { InputBaseComponentProps } from "@mui/material/InputBase";

import { black, grayDark } from "design-system/colors";

const StripeInput = forwardRef<any, InputBaseComponentProps>(
  function StripeInput(props, ref) {
    const {
      component: Component,
      options,
      isHideOriginalPlaceholder,
      placeholder,
      onChange,
      ...other
    } = props;
    const [mountNode, setMountNode] = useState<any | null>(null);

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          if (mountNode === null) {
            return;
          }

          mountNode.focus();
        },
      }),
      [mountNode]
    );

    return (
      <Component
        onReady={
          other.autoFocus
            ? (element) => {
                element.focus();
              }
            : setMountNode
        }
        options={{
          ...options,
          ...(isHideOriginalPlaceholder && { placeholder: "" }),
          style: {
            base: {
              color: black,
              fontSize: "14px",
              "::placeholder": {
                color: grayDark,
              },
            },
          },
        }}
        onChange={onChange}
        {...other}
      />
    );
  }
);

export default StripeInput;
