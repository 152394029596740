import React, { ChangeEvent } from "react";

import {
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import { useScreenSize } from "globals/hooks";
import { EmailModeEnum } from "types";

type EmailModeRadioButtonsProps = {
  mode: EmailModeEnum;
  onModeChange: (mode: EmailModeEnum) => void;
};

function EmailModeRadioButtons(props: EmailModeRadioButtonsProps) {
  const { mode, onModeChange } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // event handlers
  const handleModeChange = (event: ChangeEvent<HTMLInputElement>) => {
    onModeChange(event.target.value as EmailModeEnum);
  };

  return (
    <>
      <Typography variant="subtitle2" mt={2}>
        Choose which email to send
      </Typography>

      <Box mt={1}>
        <RadioGroup
          row={isMobileView ? false : true}
          name="email-type-radio-buttons-group"
          value={mode}
          onChange={handleModeChange}
        >
          <FormControlLabel
            value={EmailModeEnum.Reservation}
            control={<Radio />}
            label="Reservation Email"
          />
          <FormControlLabel
            value={EmailModeEnum.PriceSummary}
            control={<Radio />}
            label="Price Summary Email"
          />
        </RadioGroup>
      </Box>
    </>
  );
}

export default EmailModeRadioButtons;
