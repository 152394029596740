import React, { useState } from "react";

import { Box, Button, CircularProgress, Typography } from "@mui/material";

import {
  LabeledInlineInput,
  LabeledInlineLocationAutocomplete,
  LabeledInlinePhoneNumberInput,
} from "../../../design-system/components/inputs";
import ImageUpload from "../../../components/ImageUpload/ImageUpload";
import { Operator } from "../../../types";
import { useSnackbar } from "../../../globals/hooks";

type CompanyInfoProps = {
  isMobileView?: boolean;
  companyInfo?: Partial<Operator> | undefined;
  onCompanyInfoChange?: (event: any) => void;
  onCompanyAddressChange?: (value: string) => void;
  onPhotoChange?: (url: string) => void;
};

const CompanyInfoTab = (props: CompanyInfoProps) => {
  const {
    isMobileView,
    companyInfo,
    onCompanyInfoChange,
    onCompanyAddressChange,
    onPhotoChange,
  } = props;

  // state
  const [photoLoading, setPhotoLoading] = useState(false);

  // hooks
  const snackbar = useSnackbar();

  return (
    <Box mx={2}>
      <Box my={0.5}>
        <LabeledInlineInput
          required
          errorText="Company name required"
          label="Company Name"
          {...(isMobileView && { labelMaxWidth: "80px", labelSize: "xs" })}
          placeholder="What is the name of your company?"
          textVariant="overline"
          value={companyInfo?.name || ""}
          onChange={onCompanyInfoChange("name")}
        />
      </Box>
      <Box my={0.5}>
        <LabeledInlineLocationAutocomplete
          label="Company Address"
          {...(isMobileView && { labelMaxWidth: "80px", labelSize: "xs" })}
          placeholder="Your company address"
          textVariant="overline"
          value={companyInfo?.address || ""}
          onChange={(value) => onCompanyAddressChange(value.description)}
        />
      </Box>
      <Box my={1}>
        <LabeledInlineInput
          label="Email Address"
          {...(isMobileView && { labelMaxWidth: "80px", labelSize: "xs" })}
          placeholder="Company email address"
          textVariant="overline"
          value={companyInfo?.generalEmail || ""}
          onChange={onCompanyInfoChange("generalEmail")}
        />
      </Box>
      <Box my={0.5}>
        <LabeledInlinePhoneNumberInput
          label="Phone Number"
          {...(isMobileView && { labelMaxWidth: "80px", labelSize: "xs" })}
          placeholder="Company phone number"
          textVariant="overline"
          value={companyInfo?.voicePhoneNumber || ""}
          onChange={onCompanyInfoChange("voicePhoneNumber")}
        />
      </Box>
      <Box
        my={1}
        display="flex"
        flex={1}
        {...(!isMobileView && {
          alignItems: "center",
        })}
        flexDirection={isMobileView ? "column" : "row"}
      >
        <Box mr={8} {...(isMobileView && { width: "50px" })}>
          <Typography variant="overline">COMPANY LOGO</Typography>
        </Box>
        <Box my={1}>
          {!!companyInfo?.companyLogoUrl && (
            <img
              src={companyInfo?.companyLogoUrl}
              alt="company-logo"
              style={{
                objectFit: "contain",
                width: "188px",
                height: "80px",
              }}
            />
          )}
          {!!photoLoading ? (
            <Box ml={2}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            <ImageUpload
              firebaseDirectory="company_logos"
              htmlFor="company-logo-button"
              aspectRatio={5 / 2}
              maxWidth={1000}
              maxHeight={400}
              onFileUploadStart={() => setPhotoLoading(true)}
              onFileUploadComplete={(url) => {
                onPhotoChange(url);
                setPhotoLoading(false);
              }}
              onError={(errorMessage) => {
                snackbar.error(errorMessage);
              }}
              button={
                <Button variant="outlined" color="primary" component="span">
                  Add Company Logo
                </Button>
              }
            />
          )}
          <Box width="198px" mt={1}>
            <Typography variant="caption" color="textSecondary">
              Select “Save Changes” to apply company logo.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyInfoTab;
