import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { Box, Typography, Divider, Button, Hidden } from "@mui/material";

import { PlusIcon } from "../../design-system/icons";
import { granite, white } from "../../design-system/colors";
import { LOAD_INSURANCE_POLICIES_QUERY } from "../../globals/graphql";
import GQLQueryStatusIndicator from "../../components/GQLQueryStatusIndicator";
import InsurancePolicyList from "../../components/settings/insurance/InsurancePolicyList";
import { useSnackbar } from "../../globals/hooks/useSnackbar";
import SquareButton from "../../design-system/SquareButton";
import { useAnalytics, useScreenSize } from "../../globals/hooks";
import {
  containerStyles,
  headerStyles,
  listStyles,
  tableStyles,
} from "./utils/styles";

function InsuranceSettingsPage() {
  const snackbar = useSnackbar();
  const { isSmallTabletView } = useScreenSize();
  const { track } = useAnalytics();

  const { data, error, refetch, networkStatus, loading } = useQuery(
    LOAD_INSURANCE_POLICIES_QUERY,
    {
      onError: (error) => {
        snackbar.error();
      },
    }
  );

  // event handler
  const handleCreatePolicyClick = () => track("insurance_createInitiated");

  if (!data || loading) {
    return (
      <GQLQueryStatusIndicator
        name="Insurance Policy"
        error={error}
        data={data}
        loading={loading}
        networkStatus={networkStatus}
        refetch={refetch}
      />
    );
  }

  const { insurancePolicies } = data;
  const policies = !!insurancePolicies.length;

  return (
    <>
      <Helmet>
        <title>Insurance | Moovs</title>
      </Helmet>
      <Box sx={containerStyles}>
        <Box sx={headerStyles}>
          <Typography variant="h2">Insurance</Typography>

          <Box>
            {policies && !isSmallTabletView && (
              <Button
                disableElevation={true}
                variant="contained"
                color="primary"
                component={Link}
                to="/settings/insurance/create"
                onClick={handleCreatePolicyClick}
              >
                Create Policy
              </Button>
            )}
            {policies && isSmallTabletView && (
              <SquareButton
                disableElevation={true}
                variant="contained"
                color="primary"
                component={Link}
                to="/settings/insurance/create"
                onClick={handleCreatePolicyClick}
              >
                <PlusIcon color={white} />
              </SquareButton>
            )}
          </Box>
        </Box>
        <Hidden mdDown>
          <Box px={3}>
            <Divider />
          </Box>
        </Hidden>
        {policies ? (
          <Box {...(!isSmallTabletView && { sx: tableStyles })}>
            <InsurancePolicyList
              insurancePolicies={insurancePolicies}
              sxStyles={listStyles}
            />
          </Box>
        ) : (
          <Box
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            display="flex"
            flex="1"
            flexDirection="column"
          >
            <Box m={1}>
              <Typography variant="h5">No Policy Added Yet.</Typography>
            </Box>
            <Box maxWidth="410px" mb={3}>
              <Typography variant="body2" sx={{ color: granite }}>
                Upload your insurance policy and legalize your fleet.
              </Typography>
            </Box>
            <Box>
              <Link to="/settings/insurance/create">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleCreatePolicyClick}
                >
                  Create Policy
                </Button>
              </Link>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}

export default InsuranceSettingsPage;
