import React from "react";

type MoovsAiOperatorIconProps = {
  size?: "small" | "extra-large";
};

const MoovsAiOperatorIcon = (props: MoovsAiOperatorIconProps) => {
  const { size } = props;

  if (size === "small") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width="16"
        height="16"
      >
        <path
          d="m12.1,1.16c-.05.37-.01.75.11,1.1.12.35.32.68.58.94.26.26.59.46.94.58.35.12.73.16,1.1.11-1.77,0-2.65.94-2.74,2.74.05-.37.02-.75-.1-1.11-.12-.36-.32-.68-.58-.95-.27-.27-.59-.47-.95-.58-.36-.12-.73-.15-1.11-.1.37.05.75.01,1.1-.11.35-.12.68-.32.94-.59.26-.26.46-.59.58-.94.12-.35.16-.73.11-1.1Z"
          fill="#A444B7"
        />
        <path
          d="m6.29,4.58c-.01,3.22,1.56,5.05,5.13,5.13-3.31-.01-4.96,1.77-5.13,5.13-.03-3.17-1.45-5.13-5.13-5.13,3.29-.05,5.12-1.63,5.13-5.13Z"
          fill="#A444B7"
        />
        <path
          d="m12.1,1.16c-.05.37-.01.75.11,1.1.12.35.32.68.58.94.26.26.59.46.94.58.35.12.73.16,1.1.11-1.77,0-2.65.94-2.74,2.74.05-.37.02-.75-.1-1.11-.12-.36-.32-.68-.58-.95-.27-.27-.59-.47-.95-.58-.36-.12-.73-.15-1.11-.1.37.05.75.01,1.1-.11.35-.12.68-.32.94-.59.26-.26.46-.59.58-.94.12-.35.16-.73.11-1.1Z"
          stroke="#A444B7"
          fill="#A444B7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m6.29,4.58c-.01,3.22,1.56,5.05,5.13,5.13-3.31-.01-4.96,1.77-5.13,5.13-.03-3.17-1.45-5.13-5.13-5.13,3.29-.05,5.12-1.63,5.13-5.13Z"
          stroke="#A444B7"
          fill="#A444B7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  if (size === "extra-large") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        width="32"
        height="32"
      >
        <path
          d="m24.41,1.9c-.1.76-.03,1.53.22,2.26s.66,1.39,1.21,1.92c.55.55,1.21.96,1.92,1.21.73.25,1.5.32,2.26.22-3.62,0-5.44,1.94-5.62,5.62.11-.76.04-1.55-.21-2.28-.24-.73-.66-1.39-1.19-1.94-.55-.55-1.21-.96-1.94-1.19s-1.5-.31-2.28-.21c.76.1,1.53.01,2.26-.22.73-.25,1.39-.66,1.92-1.21.55-.55.96-1.21,1.21-1.92.25-.73.32-1.5.22-2.26Z"
          fill="#A444B7"
        />
        <path
          d="m12.47,8.93c-.03,6.62,3.2,10.38,10.54,10.54-6.81-.03-10.18,3.62-10.54,10.54-.06-6.52-2.98-10.54-10.54-10.54,6.76-.1,10.51-3.36,10.54-10.54Z"
          fill="#A444B7"
        />
        <path
          d="m24.41,1.9c-.1.76-.03,1.53.22,2.26s.66,1.39,1.21,1.92c.55.55,1.21.96,1.92,1.21.73.25,1.5.32,2.26.22-3.62,0-5.44,1.94-5.62,5.62.11-.76.04-1.55-.21-2.28-.24-.73-.66-1.39-1.19-1.94-.55-.55-1.21-.96-1.94-1.19s-1.5-.31-2.28-.21c.76.1,1.53.01,2.26-.22.73-.25,1.39-.66,1.92-1.21.55-.55.96-1.21,1.21-1.92.25-.73.32-1.5.22-2.26Z"
          stroke="#A444B7"
          fill="#A444B7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m12.47,8.93c-.03,6.62,3.2,10.38,10.54,10.54-6.81-.03-10.18,3.62-10.54,10.54-.06-6.52-2.98-10.54-10.54-10.54,6.76-.1,10.51-3.36,10.54-10.54Z"
          stroke="#A444B7"
          fill="#A444B7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <path
        d="m18,2c-.07.54-.02,1.09.16,1.61.18.52.47.99.86,1.37.39.39.86.68,1.37.86.52.18,1.07.23,1.61.16-2.58,0-3.87,1.38-4,4,.08-.54.03-1.1-.15-1.62-.17-.52-.47-.99-.85-1.38-.39-.39-.86-.68-1.38-.85-.52-.17-1.07-.22-1.62-.15.54.07,1.09.01,1.61-.16.52-.18.99-.47,1.37-.86.39-.39.68-.86.86-1.37.18-.52.23-1.07.16-1.61Z"
        fill="#A444B7"
      />
      <path
        d="m9.5,7c-.02,4.71,2.28,7.39,7.5,7.5-4.85-.02-7.25,2.58-7.5,7.5-.04-4.64-2.12-7.5-7.5-7.5,4.81-.07,7.48-2.39,7.5-7.5Z"
        fill="#A444B7"
      />
      <path
        d="m18,2c-.07.54-.02,1.09.16,1.61.18.52.47.99.86,1.37.39.39.86.68,1.37.86.52.18,1.07.23,1.61.16-2.58,0-3.87,1.38-4,4,.08-.54.03-1.1-.15-1.62-.17-.52-.47-.99-.85-1.38-.39-.39-.86-.68-1.38-.85-.52-.17-1.07-.22-1.62-.15.54.07,1.09.01,1.61-.16.52-.18.99-.47,1.37-.86.39-.39.68-.86.86-1.37.18-.52.23-1.07.16-1.61Z"
        stroke="#A444B7"
        fill="#A444B7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m9.5,7c-.02,4.71,2.28,7.39,7.5,7.5-4.85-.02-7.25,2.58-7.5,7.5-.04-4.64-2.12-7.5-7.5-7.5,4.81-.07,7.48-2.39,7.5-7.5Z"
        stroke="#A444B7"
        fill="#A444B7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MoovsAiOperatorIcon;
