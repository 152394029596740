import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import some from "lodash/some";

import { Box, Typography, TextField, MenuItem } from "@mui/material";

import {
  useAnalytics,
  useScreenSize,
  useSnackbar,
  useCurrentUser,
} from "globals/hooks";
import CreateDrawer from "components/globals/CreateDrawer";
import { CREATE_USER_AND_SEND_INVITE_MUTATION } from "globals/graphql";
import { DefaultScreenCreateDropdown } from "./components";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import InternationalPhoneInput from "components/inputs/InternationalPhoneInput";
import { validateUserInfo } from "./utils";
import MemberPermissions from "../MemberPermissions";
import { UserAccessPermission } from "types";
import { useLoadAllAccessPermissions } from "../hooks/useLoadAllAccessPermissions";

const initialUser = {
  firstName: "",
  lastName: "",
  mobilePhone: "",
  phoneCountryCode: "",
  phoneCountryDialCode: "",
  phoneCountryName: "",
  phoneCountryFormat: "",
  email: "",
  roleSlug: "",
  defaultScreen: "",
  accessPermissions: [],
};

const initialUserErrors = {
  firstName: "",
  lastName: "",
  mobilePhone: "",
  email: "",
  roleSlug: "",
  defaultScreen: "",
  accessPermissions: "",
};

function CreateMemberDrawer() {
  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const { track } = useAnalytics();
  const { isMobileView } = useScreenSize();
  const currentUser = useCurrentUser();
  const { allAccessPermissions, allAccessPermissionsLoading } =
    useLoadAllAccessPermissions();

  // state
  const [user, setUser] = useState(initialUser);
  const [userErrors, setUserErrors] = useState(initialUserErrors);
  const [memberSaveError, setMemberSaveError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [allPermissions, setAllPermissions] = useState([]);

  // mutations
  const [createUserAndSendInvite] = useMutation(
    CREATE_USER_AND_SEND_INVITE_MUTATION,
    {
      onCompleted(data) {
        const { defaultScreen } = data.createUserAndSendInvite.user.settings;

        track("create_user_invite", { defaultScreen });
        snackbar.success("Invite sent");
        handleCreateDrawerClose();
      },
      onError(error) {
        setSubmitDisabled(false);
        const errorMessage =
          getErrorMessage(error) || "Error creating new member";
        snackbar.error(errorMessage);
      },
      refetchQueries: ["Operator"],
    }
  );

  // event handlers
  const handleUserInfoChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    setUser({ ...user, [name]: value });
    if (userErrors.hasOwnProperty(name)) {
      setUserErrors({ ...userErrors, [name]: "" });
    }
  };

  const handlePhoneNumberInput = (value, country) => {
    setUser({
      ...user,
      mobilePhone: value,
      phoneCountryCode: country.countryCode,
      phoneCountryDialCode: country.dialCode,
      phoneCountryName: country.name,
      phoneCountryFormat: country.format,
    });
    setUserErrors({ ...userErrors, mobilePhone: "" });
  };

  const handleClickSave = () => {
    const userErrors = validateUserInfo(user);

    if (userErrors.length > 0) {
      setUserErrors(
        userErrors.reduce((acc, value) => ({ ...acc, ...value }), {})
      );
      setMemberSaveError(true);
      return;
    }

    setMemberSaveError(false);
    setSubmitDisabled(true);

    // modify accessPermissions for mutation
    const accessPermissions = user.accessPermissions.map(
      (accessPermission) => ({
        id: accessPermission.id,
      })
    );

    createUserAndSendInvite({
      variables: {
        input: {
          ...user,
          email: user.email.trim(),
          accessPermissions,
        },
      },
    });
  };

  const handleCreateDrawerClose = () => {
    history.push("/settings/members");
  };

  const handleUserAccessPermissionsChange =
    (modifiedAccessPermission: UserAccessPermission) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserErrors({ ...userErrors, accessPermissions: "" });

      const newAccessPermissions = event.target.checked
        ? [...user.accessPermissions, modifiedAccessPermission]
        : user.accessPermissions.filter(
            (accessPermission: UserAccessPermission) =>
              accessPermission.id !== modifiedAccessPermission.id
          );

      const hasHomeCategory = some(newAccessPermissions, {
        category: "home",
      });

      if (!hasHomeCategory) {
        setUserErrors({
          ...userErrors,
          accessPermissions: "Member must have access to at least 1 Home Page",
        });
      } else {
        setUser({
          ...user,
          accessPermissions: newAccessPermissions,
        });
      }
    };

  // derived state
  const showMemberPermissions =
    currentUser?.role.slug === "owner" &&
    user.roleSlug &&
    user.roleSlug !== "owner";

  // effects
  useEffect(() => {
    // Only owners can create new members
    if (currentUser?.role.slug !== "owner") {
      history.replace("/settings/members");
    }
  }, [history, currentUser]);

  // Set default access permissions for a new user
  useEffect(() => {
    if (allAccessPermissionsLoading) {
      return;
    }

    if (allAccessPermissions && allAccessPermissions.length) {
      const defaultAccessPermissions =
        user.roleSlug === "owner"
          ? allAccessPermissions
          : allAccessPermissions.filter(
              (permission) => permission.grantedByDefault
            );

      setAllPermissions(allAccessPermissions);
      setUser((prevUser) => ({
        ...prevUser,
        accessPermissions: defaultAccessPermissions,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAccessPermissionsLoading, user.roleSlug]);

  return (
    <CreateDrawer
      onClose={handleCreateDrawerClose}
      pageLabel="Create New Member"
      submitLabel="Invite Member"
      onSubmit={handleClickSave}
      saveError={memberSaveError}
      submitDisabled={submitDisabled}
    >
      <Helmet>
        <title>Create Member | Moovs</title>
      </Helmet>
      <Box mt={4} mb={2}>
        <Typography variant="h5">Member Details</Typography>
      </Box>
      <Box mb={4}>
        <Box display="flex" flexDirection="row" mb={1}>
          <Box display="flex" flex="1" mr={1}>
            <TextField
              required
              fullWidth
              name="firstName"
              label="First Name"
              variant="outlined"
              value={user.firstName}
              onChange={handleUserInfoChange}
              error={!!userErrors.firstName}
              helperText={userErrors.firstName}
            />
          </Box>
          <Box display="flex" flex="1">
            <TextField
              required
              fullWidth
              name="lastName"
              label="Last Name"
              variant="outlined"
              value={user.lastName}
              onChange={handleUserInfoChange}
              error={!!userErrors.lastName}
              helperText={userErrors.lastName}
            />
          </Box>
        </Box>
        <Box my={1}>
          <InternationalPhoneInput
            value={user.mobilePhone}
            error={userErrors.mobilePhone}
            onChange={handlePhoneNumberInput}
            dropdownWidth={isMobileView ? "600%" : "1220%"}
            errorMessage="Please enter valid number"
          />
        </Box>
        <Box mb={1}>
          <TextField
            required
            fullWidth
            name="email"
            label="Email Address"
            variant="outlined"
            value={user.email}
            error={!!userErrors.email}
            helperText={userErrors.email}
            onChange={handleUserInfoChange}
          />
        </Box>
        <Box mb={1}>
          <TextField
            required
            fullWidth
            select
            name="roleSlug"
            label="Role"
            variant="outlined"
            value={user.roleSlug}
            error={!!userErrors.roleSlug}
            helperText={userErrors.roleSlug}
            onChange={handleUserInfoChange}
          >
            <MenuItem value="owner">Owner</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="member">Member</MenuItem>
          </TextField>
        </Box>

        {/* default screen */}
        <DefaultScreenCreateDropdown
          value={user.defaultScreen}
          error={!!userErrors.defaultScreen}
          helperText={userErrors.defaultScreen}
          onChange={handleUserInfoChange}
        />
      </Box>
      {showMemberPermissions && (
        <MemberPermissions
          activePermissions={user.accessPermissions || []}
          allPermissions={allPermissions}
          handleUserAccessPermissionsChange={handleUserAccessPermissionsChange}
          homeError={userErrors.accessPermissions}
        />
      )}
    </CreateDrawer>
  );
}

export default CreateMemberDrawer;
