import React from "react";
import first from "lodash/first";

import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Route } from "types";
import { currency } from "globals/utils/helpers";

function AffiliateTotalAmountColumn(props: GridRenderCellParams<string>) {
  const { row } = props;

  const route = first(row.routes as Route[]);

  return (
    <Typography variant="body2">
      {currency(route?.farmeePricing?.totalAmt || 0)}
    </Typography>
  );
}

export default AffiliateTotalAmountColumn;
