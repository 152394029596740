import React from "react";
import { Helmet } from "react-helmet";

import OnboardingPage from "./OnboardingPage";

function OperatorOnboardingPage() {
  return (
    <>
      <Helmet>
        <title>Onboarding | Moovs</title>
      </Helmet>
      <OnboardingPage />
    </>
  );
}

export default OperatorOnboardingPage;
