import moment from "moment-timezone";

type CalculateStopDateTimeParams = {
  dateTime?: Date;
}[];

export const calculateTotalDurationMinutes = (
  stops: CalculateStopDateTimeParams
): number => {
  const startDateTime = stops[0].dateTime;
  const endDateTime = stops[stops.length - 1].dateTime;
  // do not calculate if missing fields
  if (!startDateTime || !endDateTime) return null;

  return Math.floor(
    moment.duration(moment(endDateTime).diff(moment(startDateTime))).asMinutes()
  );
};
