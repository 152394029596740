import React, { Dispatch, SetStateAction } from "react";
import { Link, useLocation } from "react-router-dom";

import { Box, Button, Paper, Typography } from "@mui/material";

import { PlusIcon } from "../../../design-system/icons";
import SquareButton from "../../../design-system/SquareButton";
import { black, white } from "../../../design-system/colors";
import DateFilter from "../../../components/common/DateFilter";
import StatusSelect from "../StatusSelect";
import {
  useAnalytics,
  useCurrentUser,
  useScreenSize,
} from "../../../globals/hooks";
import { DateRange } from "components/common/DateFilter/types";
import CreateReservationSplitButton from "./components/CreateReservationSplitButton";
import { PlanVariant } from "types";

type RequestsListHeaderType = {
  mode: "quotes" | "reservations";
  statusSelectProps?: {
    setStatus: Dispatch<SetStateAction<string | string[]>>;
    selectedStatus: string | string[];
  };
  dateFilterProps?: {
    setDateRange: (dateRange: DateRange) => void;
    dateRange: DateRange;
  };
};

function RequestsListHeader(props: RequestsListHeaderType) {
  const { mode, dateFilterProps, statusSelectProps } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const location = useLocation();
  const { operator } = useCurrentUser();
  const { track } = useAnalytics();

  // event handler
  const handleCreateQuoteClick = (title) => {
    if (title === "Quotes") {
      track("quotes_createInitiated");
    } else if (title === "Reservations") {
      track("reservations_createInitiated");
    }
  };

  const modeMap = (mode: "quotes" | "reservations") =>
    ({
      quotes: {
        filterComponent: <StatusSelect mode={mode} {...statusSelectProps} />,
        title: "Quotes",
        createRoute: `/quotes/create${location.search || ""}`,
      },
      reservations: {
        filterComponent: (
          <DateFilter
            isMobileView={isMobileView}
            mode="reservations"
            {...dateFilterProps}
          />
        ),
        requestClosedOutStatusFilterComponent: (
          <StatusSelect mode={mode} {...statusSelectProps} />
        ),
        title: "Reservations",
        createRoute: `/reservations/create${location.search || ""}`,
        createShuttlingRoute: `/reservations/shuttle/create${
          location.search || ""
        }`,
      },
    }[mode]);

  const {
    filterComponent,
    title,
    requestClosedOutStatusFilterComponent,
    createRoute,
    createShuttlingRoute,
  } = modeMap(mode);

  return (
    <Box display="flex" flexDirection="column" zIndex={10} width="100%" pb={2}>
      <Paper square elevation={0}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          py={2}
          px={2.5}
        >
          <Box display="flex" flexDirection="column">
            <Typography variant="h2" style={{ color: black }}>
              {title}
            </Typography>
          </Box>

          <Box ml={isMobileView ? 1 : 2}>
            {operator.plan === PlanVariant.Vip && createShuttlingRoute ? (
              <CreateReservationSplitButton
                createRoute={createRoute}
                createShuttlingRoute={createShuttlingRoute}
              />
            ) : isMobileView ? (
              <SquareButton
                disableElevation={true}
                variant="contained"
                color="primary"
                component={Link}
                to={createRoute}
                onClick={() => handleCreateQuoteClick(title)}
              >
                <PlusIcon color={white} />
              </SquareButton>
            ) : (
              <Button
                disableElevation={true}
                variant="contained"
                color="primary"
                size="large"
                style={{ whiteSpace: "nowrap", minHeight: 40, minWidth: 40 }}
                component={Link}
                to={createRoute}
                onClick={() => handleCreateQuoteClick(title)}
              >
                Create
              </Button>
            )}
          </Box>
        </Box>
        <Box mb={1} display="flex" flex="1">
          {requestClosedOutStatusFilterComponent}
        </Box>
        <Box height={30}>{filterComponent}</Box>
      </Paper>
    </Box>
  );
}

export default RequestsListHeader;
