import React from "react";

type UpgradeIconProps = {
  color?: string;
  size?: string;
};

const UpgradeIcon = (props: UpgradeIconProps) => {
  const { color, size } = props;

  let squareDimension = "24";

  if (size === "small") {
    squareDimension = "12";
  }

  if (size === "medium") {
    squareDimension = "16";
  }

  return (
    <svg
      width={squareDimension}
      height={squareDimension}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9 12.4V8.8H15.9L10.5 3.4L5.1 8.8H8.1V12.4M12.9 14.8V16M8.1 14.8V16M3.9 1H17.1C18.4255 1 19.5 2.07452 19.5 3.4V16.6C19.5 17.9255 18.4255 19 17.1 19H3.9C2.57452 19 1.5 17.9255 1.5 16.6V3.4C1.5 2.07452 2.57452 1 3.9 1Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UpgradeIcon;
