import React, { useState, Dispatch, SetStateAction } from "react";
import { useMutation } from "@apollo/client";
import keys from "lodash/keys";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";

import { GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import Select, { SelectProps } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { MenuProps } from "@mui/material/Menu";
import ListItemText from "@mui/material/ListItemText";

import { UPDATE_OPERATOR_ROUTE_MUTATION } from "globals/graphql";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { alertsMap } from "../AlertsColumn";
import { Alert } from "types";
import { Checkbox, Typography } from "@mui/material";
import { moovsBlue } from "design-system/colors";

type AlertsEditColumnType = {
  params: GridRenderEditCellParams;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function AlertsEditColumn(props: AlertsEditColumnType) {
  const { params, setSaveIndicatorState } = props;
  const {
    id,
    api,
    field,
    row: { routes },
  } = params;
  const alerts = routes[0].alert as Alert;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // state
  const [activeAlerts, setActiveAlerts] = useState(
    keys(pickBy(omit(alerts, "__typename")))
  );

  // mutations;
  const [updateAlert] = useMutation(UPDATE_OPERATOR_ROUTE_MUTATION, {
    onCompleted() {
      track("reservationTripView_alertsUpdated");
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      snackbar.error("Error updating alerts.");
    },
  });

  // event handlers
  const handleChange: SelectProps["onChange"] = (event) => {
    const updateAlertName = event.target.value.toString();

    if (!activeAlerts.includes(updateAlertName)) {
      setActiveAlerts((prevActiveAlerts) => [
        ...prevActiveAlerts,
        updateAlertName,
      ]);
    } else {
      setActiveAlerts(activeAlerts.filter((item) => item !== updateAlertName));
    }
  };

  const handleClose: MenuProps["onClose"] = (_, reason) => {
    setSaveIndicatorState("loading");
    if (reason === "backdropClick") {
      api.setCellMode(id, field, "view");
    }

    const updateAlertsNames = {};
    Object.keys(alertsMap).forEach((item) => {
      updateAlertsNames[item] = !!activeAlerts.includes(item);
    });

    updateAlert({
      variables: {
        input: {
          id: routes[0].id,
          alert: updateAlertsNames,
        },
      },
    });
  };

  return (
    <>
      <Select
        value="Select"
        variant="standard"
        onChange={handleChange}
        MenuProps={{
          onClose: handleClose,
        }}
        autoFocus
        fullWidth
        open
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem value="Select" sx={{ display: "none" }}>
          <Typography variant="body2">Select</Typography>
        </MenuItem>
        {Object.entries(alertsMap).map(([slug, option]) => {
          return (
            <MenuItem key={slug} value={slug}>
              <Checkbox
                checked={activeAlerts.includes(slug)}
                sx={{ color: moovsBlue }}
              />
              <ListItemText primary={option.title} />
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
}

export default AlertsEditColumn;
