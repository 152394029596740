type StripeElementType = {
  cardNumber: boolean;
  cardExpirationDate: boolean;
  cvc: boolean;
};

export const intialStripeElement: StripeElementType = {
  cardNumber: true,
  cardExpirationDate: true,
  cvc: true,
};

type StripeElementErrorsType = {
  cardNumber: string | null;
  cardExpirationDate: string | null;
  cvc: string | null;
  createStripePaymentMethodError: string | null;
};

export const intialStripeElementErrors: StripeElementErrorsType = {
  cardNumber: null,
  cardExpirationDate: null,
  cvc: null,
  createStripePaymentMethodError: null,
};
