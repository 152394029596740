import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import { Box, Typography, CircularProgress } from "@mui/material";

import UpdateDrawer from "../../globals/UpdateDrawer";
import VehiclesBlock from "../general/VehiclesBlock";
import RemoveDialog from "../../RemoveDialog";
import { CancellationPolicy } from "../../../types";
import {
  LOAD_CANCELLATION_POLICY_QUERY,
  LOAD_CANCELLATION_POLICIES_QUERY,
  UPDATE_CANCELLATION_POLICY_MUTATION,
  REMOVE_CANCELLATION_POLICY_MUTATION,
} from "../../../globals/graphql";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import { grayLight } from "../../../design-system/colors";
import { CancellationIcon } from "../../../design-system/icons";
import RefundUpdateRow from "./RefundUpdateRow";
import GQLQueryStatusIndicator from "../../GQLQueryStatusIndicator";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

const policyVariantMap = {
  FLEXIBLE: "Flexible",
  MODERATE: "Moderate",
  STRICT: "Strict",
};

function UpdateCancellationDrawer() {
  const history = useHistory();
  const snackbar = useSnackbar();
  const { cancellationPolicyId } = useParams<{
    cancellationPolicyId: string;
  }>();

  // queries
  const {
    data: cancellationPolicyData,
    loading: cancellationPolicyLoading,
    refetch: cancellationPolicyRefetch,
    error: cancellationPolicyError,
  } = useQuery(LOAD_CANCELLATION_POLICY_QUERY, {
    variables: {
      id: cancellationPolicyId,
    },
    skip: !cancellationPolicyId,
  });

  // state
  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "loading" | "saved" | "error"
  >("default");

  const [cancellationPolicy, setCancellationPolicy] =
    useState<Partial<CancellationPolicy>>(null);
  // not debouncing this drawer since changes are very small

  const [removeCancellationDialogOpen, setRemoveCancellationDialogOpen] =
    useState<boolean>(false);

  const createdAt = cancellationPolicy
    ? moment(cancellationPolicy.createdAt).format("LLL")
    : "";

  const updatedAt = cancellationPolicy
    ? moment(cancellationPolicy.updatedAt).format("LLL")
    : "";

  // mutations
  const [updateCancellationPolicy] = useMutation(
    UPDATE_CANCELLATION_POLICY_MUTATION,
    {
      refetchQueries: [{ query: LOAD_CANCELLATION_POLICIES_QUERY }],
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        const errorMessage =
          getErrorMessage(error) || "Error updating cancellation policy.";

        snackbar.error(errorMessage);
      },
    }
  );

  const [removeCancellationPolicy] = useMutation(
    REMOVE_CANCELLATION_POLICY_MUTATION,
    {
      refetchQueries: [{ query: LOAD_CANCELLATION_POLICIES_QUERY }],
      onCompleted() {
        snackbar.success("Successfully deleted cancellation policy!");
        history.push("/settings/cancellation");
      },
      onError(error) {
        snackbar.error("Error deleting cancellation policy");
      },
    }
  );

  // event handlers

  const handleRefundChange =
    (name: string) => (key: string, value: string | number) => {
      const newRefund = {
        ...cancellationPolicy[name],
        [key]: value,
      };

      const newCancellationPolicy = {
        ...cancellationPolicy,
        [name]: newRefund,
      };

      setCancellationPolicy(newCancellationPolicy);

      const shouldSave = ["refund100", "refund50", "refund25"].every(
        (refundKey) => {
          const refund = newCancellationPolicy[refundKey];
          return (
            !refund.active || (!!refund.periodName && !!refund.periodValue)
          );
        }
      );

      if (!shouldSave) {
        return;
      }

      setSaveIndicatorState("loading");

      updateCancellationPolicy({
        variables: {
          input: {
            id: cancellationPolicy.id,
            refund100: newCancellationPolicy.refund100,
            refund50: newCancellationPolicy.refund50,
            refund25: newCancellationPolicy.refund25,
          },
        },
        optimisticResponse: {
          updateCancellationPolicy: {
            cancellationPolicy: {
              ...newCancellationPolicy,
              refund100: {
                ...newCancellationPolicy.refund100,
                __typename: "Refund",
              },
              refund50: {
                ...newCancellationPolicy.refund50,
                __typename: "Refund",
              },
              refund25: {
                ...newCancellationPolicy.refund25,
                __typename: "Refund",
              },
              __typename: "CancellationPolicy",
            },
            __typename: "UpdateCancellationPolicyPayload",
          },
        },
      });
    };

  const handleRemoveCancellation = () => {
    removeCancellationPolicy({
      variables: {
        input: {
          id: cancellationPolicy.id,
        },
      },
    });

    setRemoveCancellationDialogOpen(false);
  };

  // clears cancellation poliy on toggling drawer
  useEffect(() => {
    setCancellationPolicy(null);
    setSaveIndicatorState("default");
  }, [cancellationPolicyId]);

  // sets cancellation policy on load
  useEffect(() => {
    if (!cancellationPolicy && cancellationPolicyData) {
      const newCancellationPolicy = cloneDeep(cancellationPolicyData?.node);

      delete newCancellationPolicy?.__typename;
      delete newCancellationPolicy?.refund100.__typename;
      delete newCancellationPolicy?.refund50.__typename;
      delete newCancellationPolicy?.refund25.__typename;

      setCancellationPolicy(newCancellationPolicy);
    }
  }, [cancellationPolicyData, cancellationPolicy]);

  // update vehicles
  useEffect(() => {
    const newVehicleIds = cancellationPolicyData?.node.vehicleIds;
    const oldVehicleIds = cancellationPolicy?.vehicleIds;
    if (!isEqual(newVehicleIds, oldVehicleIds)) {
      setCancellationPolicy((cancellationPolicy) => ({
        ...cancellationPolicy,
        vehicleIds: newVehicleIds,
      }));
    }
  }, [cancellationPolicyData, cancellationPolicy]);

  return (
    <>
      <UpdateDrawer
        onClose={() => {
          history.push("/settings/cancellation");
        }}
        createdAt={createdAt}
        updatedAt={updatedAt}
        saveIndicatorState={saveIndicatorState}
        ellipsisMenuOptions={[
          {
            onClick: () => {
              setRemoveCancellationDialogOpen(true);
            },
            text: "Delete Policy",
          },
        ]}
      >
        {cancellationPolicyLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={40} thickness={2} />
          </Box>
        )}
        {cancellationPolicyError && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <GQLQueryStatusIndicator
              name="Cancellation Policy"
              data={cancellationPolicyData}
              error={true}
              refetch={cancellationPolicyRefetch}
            />
          </Box>
        )}
        {cancellationPolicy && (
          <>
            {/* Cancellation Type */}
            <Box>
              <Box mt={4.5} mb={3.5} display="flex" alignItems="center">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  marginRight="7px"
                  style={{
                    height: "26px",
                    width: "26px",
                    borderRadius: "50%",
                    backgroundColor: grayLight,
                  }}
                >
                  <CancellationIcon viewBox="-4 -4 24 24" />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography
                    variant="overline"
                    style={{
                      lineHeight: "16px",
                    }}
                  >
                    Cancellation Policy
                  </Typography>
                  <Typography variant="body2">
                    {policyVariantMap[cancellationPolicy?.variant]}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* Refund Conditions */}
            <Box pt={3}>
              <Typography variant="h5">Refund Conditions</Typography>
              <Box my={3}>
                <RefundUpdateRow
                  label="Full Refund"
                  refund={cancellationPolicy.refund100}
                  onChange={handleRefundChange("refund100")}
                />
              </Box>
              <Box my={3}>
                <RefundUpdateRow
                  label="50% Refund"
                  refund={cancellationPolicy.refund50}
                  onChange={handleRefundChange("refund50")}
                />
              </Box>
              <Box my={3}>
                <RefundUpdateRow
                  label="25% Refund"
                  refund={cancellationPolicy.refund25}
                  onChange={handleRefundChange("refund25")}
                />
              </Box>
            </Box>

            {/* Vehicles */}
            <Box>
              <VehiclesBlock
                mode="update"
                vehicleIds={cancellationPolicy.vehicleIds}
                setSaveIndicatorState={setSaveIndicatorState}
                cancellationPolicyId={cancellationPolicy.id}
                refetch={cancellationPolicyRefetch}
              />
            </Box>
          </>
        )}
      </UpdateDrawer>
      <RemoveDialog
        open={removeCancellationDialogOpen}
        onRemove={handleRemoveCancellation}
        onClose={() => setRemoveCancellationDialogOpen(false)}
        title="Remove this cancellation policy?"
        body="This will permanantly remove the cancellation policy. Do you want to remove this
            cancellation policy?"
      />
    </>
  );
}

export default UpdateCancellationDrawer;
