import React from "react";
import { Control, FieldErrors, useForm, UseFormWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

import { Box } from "@mui/material";

import {
  EditPromoCodeValues,
  useEditPromoCodeDefaultValues,
} from "./useEditPromoCodeDefaultValues";
import Active from "./inputs/Active";
import Amount from "./inputs/Amount";
import DateRanges from "./inputs/DateRanges";
import Limits from "./inputs/Limits";
import { editPromoCodeSchema } from "./schemaValidation";
import { PromoCode } from "types";

type EditPromoCodeFormProps = {
  editPromoCodeDefaultValues: EditPromoCodeValues;
  control: Control<EditPromoCodeValues>;
  errors: FieldErrors;
  watch: UseFormWatch<EditPromoCodeValues>;
};

function EditPromoCodeForm(props: EditPromoCodeFormProps) {
  const { control, errors, watch, editPromoCodeDefaultValues } = props;

  const { bookingStartDate, bookingEndDate, tripStartDate, tripEndDate } =
    editPromoCodeDefaultValues;

  return (
    <form>
      {/* amount */}
      <Box>
        <Amount control={control} errors={errors} watch={watch} />
      </Box>
      {/* bookingStartDate and bookingEndDate */}
      <Box>
        <DateRanges
          dateType="bookingDate"
          control={control}
          errors={errors}
          defaultStartDate={moment.utc(bookingStartDate)}
          defaultEndDate={moment.utc(bookingEndDate)}
        />
      </Box>
      {/* tripStartDate and tripEndDate */}
      <Box>
        <DateRanges
          dateType="tripDate"
          control={control}
          errors={errors}
          defaultStartDate={moment.utc(tripStartDate)}
          defaultEndDate={moment.utc(tripEndDate)}
        />
      </Box>
      {/* limits */}
      <Box mt={2}>
        <Limits control={control} errors={errors} watch={watch} />
      </Box>
      {/* active */}
      <Box mt={2}>
        <Active control={control} errors={errors} watch={watch} />
      </Box>
    </form>
  );
}

function useEditPromoCodeForm(promoCode: PromoCode) {
  const editPromoCodeDefaultValues = useEditPromoCodeDefaultValues(promoCode);

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit: handleEditPromoCodeSubmit,
  } = useForm({
    mode: "onChange",
    defaultValues: editPromoCodeDefaultValues,
    resolver: yupResolver(editPromoCodeSchema),
  });

  return {
    handleEditPromoCodeSubmit,
    watch,
    editPromoCodeForm: (
      <EditPromoCodeForm
        errors={errors}
        control={control}
        editPromoCodeDefaultValues={editPromoCodeDefaultValues}
        watch={watch}
      />
    ),
  };
}

export default useEditPromoCodeForm;
