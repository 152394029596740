import React from "react";

import { Box, Card } from "@mui/material";

import { DriverPayable } from "../../../types";
import DriverPayoutListItemDesktop from "./DriverPayoutListItem.desktop";
import DriverPayoutListItemMobile from "./DriverPayoutListItem.mobile";
import { useScreenSize } from "../../../globals/hooks";

type DriverPayoutListProps = {
  driverPayables: DriverPayable[];
};

function DriverPayoutList(props: DriverPayoutListProps) {
  const { driverPayables = [] } = props;

  const { isSmallTabletView } = useScreenSize();

  return (
    <Box>
      {driverPayables.map((driverPayable: DriverPayable) => {
        return (
          <Box key={driverPayable.id} mb={1}>
            <Card variant="outlined">
              {isSmallTabletView ? (
                <DriverPayoutListItemMobile driverPayable={driverPayable} />
              ) : (
                <DriverPayoutListItemDesktop driverPayable={driverPayable} />
              )}
            </Card>
          </Box>
        );
      })}
    </Box>
  );
}

export default DriverPayoutList;
