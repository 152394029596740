import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useMutation } from "@apollo/client";
import { useDebounce } from "use-debounce";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import some from "lodash/some";
import isNumber from "lodash/isNumber";
import isNil from "lodash/isNil";

import { Box, Typography, Divider } from "@mui/material";

import {
  BaseRateAutomation,
  RoutePricing,
  Stop,
  VehicleChildSeat,
} from "types";
import { UPDATE_ROUTE_PRICING_MUTATION } from "globals/graphql";
import { useSnackbar } from "globals/hooks/useSnackbar";
import MoovsPricingForm from "components/pricing/MoovsPricingForm";
import { PricingKeys } from "components/pricing/utils";
import { EstimationRoundIcon } from "design-system/icons";
import { swoopGreen } from "design-system/colors";
import { useOperator, useScreenSize } from "globals/hooks";

type PricingInfoUpdateBlockProps = {
  routePricing: RoutePricing;
  routeId: string;
  setSaveIndicatorState: Function;
  refetchQuery: Function;
  BRAProps: {
    hasEnabledVehicleBRA: boolean;
    vehicleId: string;
    stops: Stop[];
    showAutomatedBaseRateIcon: boolean;
    automatedBaseRate: BaseRateAutomation;
    automatedBaseRateLoading: boolean;
  };
  totalDuration: number;
  addPricingLabelOverride?: string;
  childSeatOptions?: VehicleChildSeat[];
};

function PricingInfoUpdateBlock(props: PricingInfoUpdateBlockProps) {
  const {
    routePricing,
    routeId,
    setSaveIndicatorState,
    refetchQuery,
    BRAProps: {
      vehicleId,
      showAutomatedBaseRateIcon,
      automatedBaseRate,
      hasEnabledVehicleBRA,
      automatedBaseRateLoading,
      stops,
    },
    totalDuration,
    addPricingLabelOverride,
    childSeatOptions,
  } = props;
  const snackbar = useSnackbar();
  const operator = useOperator();
  const { isMobileView } = useScreenSize();

  // ref
  // using ref to prevent callback dependencies loop
  const automatedBaseRateRef = useRef(automatedBaseRate);

  // state
  const [pricingValues, setPricingValues] = useState(() => {
    return {
      id: routePricing.id,
      baseRateAmt: routePricing.baseRateAmt,
      hourlyBaseRateHours: routePricing.hourlyBaseRateHours,
      driverGratuityAmt: routePricing.driverGratuityAmt,
      driverGratuityPercent:
        routePricing.driverGratuityPercent !== null
          ? routePricing.driverGratuityPercent * 100
          : null,
      promoDiscountAmt: routePricing.promoDiscountAmt,
      promoDiscountPercent:
        routePricing.promoDiscountPercent !== null
          ? routePricing.promoDiscountPercent * 100
          : null,
      taxAmt: routePricing.taxAmt,
      taxPercent:
        routePricing.taxPercent !== null ? routePricing.taxPercent * 100 : null,
      tollsAmt: routePricing.tollsAmt,
      meetGreetAmt: routePricing.meetGreetAmt,
      otherAmt: routePricing.otherAmt,
      otherPercent:
        routePricing.otherPercent !== null
          ? routePricing.otherPercent * 100
          : null,
      otherName: routePricing.otherName,
      other2Amt: routePricing.other2Amt,
      other2Percent:
        routePricing.other2Percent !== null
          ? routePricing.other2Percent * 100
          : null,
      other2Name: routePricing.other2Name,
      other3Amt: routePricing.other3Amt,
      other3Percent:
        routePricing.other3Percent !== null
          ? routePricing.other3Percent * 100
          : null,
      other3Name: routePricing.other3Name,
      promoCodeId: routePricing.promoCodeId,
      promoCodeName: routePricing.promoCodeName,
      promoCodeAmt: routePricing.promoCodeAmt,
      promoCodePercent:
        routePricing.promoCodePercent !== null
          ? routePricing.promoCodePercent * 100
          : null,
      forwardFacingSeatQuantity: routePricing.forwardFacingSeatQuantity,
      forwardFacingSeatAmt:
        routePricing.forwardFacingSeatQuantity !== null
          ? routePricing.forwardFacingSeatAmt /
            routePricing.forwardFacingSeatQuantity
          : null,
      rearFacingSeatQuantity: routePricing.rearFacingSeatQuantity,
      rearFacingSeatAmt:
        routePricing.rearFacingSeatQuantity !== null
          ? routePricing.rearFacingSeatAmt / routePricing.rearFacingSeatQuantity
          : null,
      boosterSeatQuantity: routePricing.boosterSeatQuantity,
      boosterSeatAmt:
        routePricing.boosterSeatQuantity !== null
          ? routePricing.boosterSeatAmt / routePricing.boosterSeatQuantity
          : null,
    };
  });
  const [isBaseRateAutomationClicked, setIsBaseRateAutomationClicked] =
    useState(false);

  const [debouncedPricing] = useDebounce(pricingValues, 800, {
    equalityFn: isEqual,
  });
  const flag = useRef(false);

  const [updateRoutePricing] = useMutation(UPDATE_ROUTE_PRICING_MUTATION, {
    onCompleted() {
      setSaveIndicatorState("saved");
      refetchQuery();
      setIsBaseRateAutomationClicked(false);
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error("Error updating pricing");
      setIsBaseRateAutomationClicked(false);
    },
  });

  const handleDebouncedUpdateRoute = useCallback(() => {
    setSaveIndicatorState("loading");

    const {
      driverGratuityAmt,
      driverGratuityPercent,
      taxAmt,
      taxPercent,
      promoDiscountAmt,
      promoDiscountPercent,
      tollsAmt,
      meetGreetAmt,
      otherAmt,
      otherPercent,
      other2Amt,
      other2Percent,
      other3Amt,
      other3Percent,
      promoCodeAmt,
      promoCodePercent,
      rearFacingSeatQuantity,
      forwardFacingSeatQuantity,
      boosterSeatQuantity,
      // child seat amt are per unit
      rearFacingSeatAmt,
      forwardFacingSeatAmt,
      boosterSeatAmt,
    } = debouncedPricing;

    updateRoutePricing({
      variables: {
        input: {
          ...omit(debouncedPricing, "promoCodeName"),
          id: routeId,
          driverGratuityAmt:
            driverGratuityPercent === null && driverGratuityAmt
              ? driverGratuityAmt
              : null,
          driverGratuityPercent: driverGratuityPercent
            ? driverGratuityPercent / 100
            : null,
          taxAmt: taxPercent === null && taxAmt ? taxAmt : null,
          taxPercent: taxPercent ? taxPercent / 100 : null,
          promoDiscountAmt:
            promoDiscountPercent === null && promoDiscountAmt
              ? promoDiscountAmt
              : null,
          promoDiscountPercent: promoDiscountPercent
            ? promoDiscountPercent / 100
            : null,
          tollsAmt: tollsAmt ? tollsAmt : null,
          meetGreetAmt,
          otherAmt: otherPercent === null && otherAmt ? otherAmt : null,
          otherPercent: otherPercent ? otherPercent / 100 : null,
          other2Amt: other2Percent === null && other2Amt ? other2Amt : null,
          other2Percent: other2Percent ? other2Percent / 100 : null,
          other3Amt: other3Percent === null && other3Amt ? other3Amt : null,
          other3Percent: other3Percent ? other3Percent / 100 : null,
          promoCodeAmt:
            promoCodePercent === null && !isNil(promoCodeAmt)
              ? promoCodeAmt
              : null,
          promoCodePercent: !isNil(promoCodePercent)
            ? promoCodePercent / 100
            : null,
          ...(isBaseRateAutomationClicked && {
            baseRateAutomation: {
              total: automatedBaseRateRef?.current?.total,
              lineItems: automatedBaseRateRef?.current?.lineItems.map(
                (lineItem) => omit(lineItem, "__typename")
              ),
            },
          }),
          rearFacingSeatAmt: rearFacingSeatQuantity
            ? rearFacingSeatAmt * rearFacingSeatQuantity
            : null,
          forwardFacingSeatAmt: forwardFacingSeatQuantity
            ? forwardFacingSeatAmt * forwardFacingSeatQuantity
            : null,
          boosterSeatAmt: boosterSeatQuantity
            ? boosterSeatAmt * boosterSeatQuantity
            : null,
        },
      },
    });
  }, [
    debouncedPricing,
    routeId,
    setSaveIndicatorState,
    updateRoutePricing,
    isBaseRateAutomationClicked,
  ]);

  // effects
  useEffect(() => {
    // prevents updating on initial load
    if (!flag.current) {
      flag.current = true;
    } else {
      handleDebouncedUpdateRoute();
    }
  }, [debouncedPricing, handleDebouncedUpdateRoute]);

  // update ref if automatedBaseRate changed
  useEffect(() => {
    if (!isEqual(automatedBaseRate, automatedBaseRateRef.current)) {
      automatedBaseRateRef.current = automatedBaseRate;
    }
  }, [automatedBaseRate]);

  // event handlers
  const handlePricingUpdate = (pricingKeys: PricingKeys) => {
    const { fieldName, fieldAmt, percentFieldName, percentFieldValue } =
      pricingKeys;

    const fieldsToUpdate = {
      ...(fieldName && { [fieldName]: fieldAmt }),
      ...(percentFieldName && { [percentFieldName]: percentFieldValue }),
    };
    setPricingValues((pricingValues) => ({
      ...pricingValues,
      ...fieldsToUpdate,
    }));
  };

  const pricingLayoutSettingEnabled = useMemo(() => {
    if (!operator.settings?.pricingLayout) return false;
    const { pricingLayout } = operator.settings;

    return some(pricingLayout, (value, key) => {
      // excluding pricing layout setting meetGreetAmt, user needs to manually add item
      if (key === "meetGreetAmt" && pricingLayout.meetGreetAmtEnabled) {
        return false;
      }
      return isNumber(value) && value > 0;
    });
  }, [operator.settings]);

  const hasPricingLayoutItemsAdded = useMemo(
    () =>
      some(
        pricingValues,
        (value, key) => key !== "baseRateAmt" && isNumber(value) && value > 0
      ),
    [pricingValues]
  );

  // MoovsPricingForm props
  const BRAProps = {
    hasEnabledVehicleBRA,
    vehicleId,
    stops,
    preexistingABR: automatedBaseRate,
    preexistingABRLoading: automatedBaseRateLoading,
  };

  return (
    <Box>
      <Box
        mt={4}
        mb={1.5}
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
      >
        <Typography variant="h5">Pricing</Typography>
        {showAutomatedBaseRateIcon && (
          <Box ml={1} display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              <Typography variant="caption">
                Automated base rate. Tap
              </Typography>
              <Box m="0 4px" display="flex">
                <EstimationRoundIcon color={swoopGreen} />
              </Box>
            </Box>
            <Typography variant="caption">
              below to apply: ${automatedBaseRate?.total || null}
            </Typography>
          </Box>
        )}
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>

      <Box>
        <MoovsPricingForm
          pricing={{
            ...pricingValues,
            baseRateVariant: routePricing.baseRateVariant,
          }}
          onPricingUpdate={handlePricingUpdate}
          BRAProps={BRAProps}
          setIsBaseRateAutomationClicked={setIsBaseRateAutomationClicked}
          displayUsePricingLayout={
            pricingLayoutSettingEnabled && !hasPricingLayoutItemsAdded
          }
          setPricingValues={setPricingValues}
          totalDurationMinutes={totalDuration}
          hasFullWidthSwitchDropdown={isMobileView}
          dropdownButtonLabel={addPricingLabelOverride}
          childSeatOptions={childSeatOptions}
        />
      </Box>
    </Box>
  );
}

export default PricingInfoUpdateBlock;
