import React, { useState } from "react";

import { Box, CircularProgress } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";
import { alabaster } from "design-system/colors";
import useLoadSalesAutomation from "./hooks/useLoadSalesAutomation";
import useEnrollSalesAutomation from "./hooks/useEnrollSalesAutomation";
import { useScreenSize, useAnalytics } from "globals/hooks";
import {
  ArchiveDateHeader,
  SalesAutomationTextContent,
  SalesAutomationTextList,
} from "./components";

// constants
const MIN_DIALOG_HEIGHT = "440px";

type SalesAutomationDialogProps = {
  open: boolean;
  onClose: () => void;
  requestId: string;
  orderNumber: string;
};

function SalesAutomationDialog(props: SalesAutomationDialogProps) {
  const { open, onClose, requestId, orderNumber } = props;

  // hook
  const { salesAutomation, salesAutomationLoading } = useLoadSalesAutomation({
    requestId,
    skip: !open,
  });

  const { isMobileView } = useScreenSize();
  const { enrollSalesAutomation, enrollSalesMutationLoading } =
    useEnrollSalesAutomation({ onClose });
  const { track } = useAnalytics();

  // state
  const [activeTextIndex, setActiveTextIndex] = useState(0);

  // event handler
  const handleEnrollClick = () => {
    track("quote_salesAutomationEnrolled");

    enrollSalesAutomation({
      variables: {
        input: {
          requestId: requestId,
        },
      },
    });
  };

  // return null if query hasn't started
  if (!salesAutomation && !salesAutomationLoading) return null;

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      dialogTitle="Enroll Quote in Sales Automation"
      onAccept={handleEnrollClick}
      acceptButtonText="Enroll"
      acceptDisabled={enrollSalesMutationLoading}
    >
      {!salesAutomation && salesAutomationLoading ? (
        <Box
          height="100%"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={MIN_DIALOG_HEIGHT} // prevents resizing while loading
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: alabaster,
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <ArchiveDateHeader
            autoArchiveDate={salesAutomation?.autoArchiveDate}
          />

          {/* Text List Component */}
          <Box display={!isMobileView && "flex"} mt={4}>
            {/* TextList Buttons*/}
            <Box flex={!isMobileView && 1} mr={2}>
              <SalesAutomationTextList
                draftTexts={salesAutomation.draftTexts}
                activeTextIndex={activeTextIndex}
                setActiveTextIndex={setActiveTextIndex}
              />
            </Box>

            {/* Text Content */}
            <Box flex={!isMobileView && 2}>
              <SalesAutomationTextContent
                requestId={requestId}
                orderNumber={orderNumber}
                text={salesAutomation.draftTexts[activeTextIndex]}
                sender={salesAutomation.sender}
              />
            </Box>
          </Box>
        </Box>
      )}
    </MoovsDialog>
  );
}

export default SalesAutomationDialog;
