import React, { useState, ChangeEvent } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

import {
  TextField,
  InputAdornment,
  Typography,
  Box,
  IconButton,
  Link,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  useAnalytics,
  useAuth,
  useInvitePendingUser,
  useScreenSize,
  useSnackbar,
} from "../../globals/hooks";
import { auth } from "../../globals/utils/firebaseApp";
import { ViewIcon, ViewOffIcon } from "../../design-system/icons";
import { grayDark } from "../../design-system/colors";
import GoogleAuthButton from "../../components/buttons/GoogleAuthButton";

axios.defaults.withCredentials = true;

function LoginPage() {
  // state
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState("");

  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const location = useLocation<{ from: any }>();
  const { onLogin } = useAuth();
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();
  const { validateInvitePendingByEmail } = useInvitePendingUser({
    onError: setErrors,
  });

  const navigateTo =
    location?.state?.from &&
    !location?.state?.from.pathname.startsWith("/login")
      ? location.state.from
      : "/";

  const handleLoginWithEmail = async () => {
    track("user_login");
    setLoading(true);

    try {
      const { error, data } = await validateInvitePendingByEmail(
        credentials.email
      )();

      if (error || data.loadInvitePendingUser.invitePending) {
        setLoading(false);
        return;
      }

      const firebaseResponse = await signInWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password
      );

      const token = await firebaseResponse.user.getIdToken();

      const successful = await onLogin(token);

      if (successful) history.replace(navigateTo);

      setLoading(false);
    } catch (error) {
      const errorCode = error.response ? error.response.status : error.code;

      setErrors(errorCode);
      setLoading(false);

      if (
        errorCode === "auth/wrong-password" ||
        errorCode === "auth/user-not-found"
      ) {
        snackbar.error(
          "The email address or password you entered is incorrect. Please try again or create an account."
        );
      } else {
        snackbar.error("Error logging in.");
      }
    }
  };

  const handleLoginWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const firebaseResponse = await signInWithPopup(auth, provider);

      const token = await firebaseResponse.user.getIdToken();

      const successful = await onLogin(token);

      if (successful) history.replace(navigateTo);
    } catch (error) {
      const errorMessage = error.response ? error.response.data : error.message;
      setErrors(errorMessage);
      snackbar.error(errorMessage);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setErrors("");
    setCredentials((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleLoginWithEmail();
    }
  };

  const styles = {
    googleBox: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: "32px",
      marginBottom: "32px",
      borderBottom: "1px solid #EDEDED",
    },
  };

  return (
    <>
      <Helmet>
        <title>Login | Moovs</title>
      </Helmet>
      <Box sx={styles.googleBox}>
        <GoogleAuthButton onClick={handleLoginWithGoogle} />
      </Box>
      <Box display="flex" justifyContent="center" mb="23px">
        <Typography
          align="center"
          variant="overline"
          style={{ color: grayDark }}
        >
          OR YOU CAN ALSO
        </Typography>
      </Box>
      <Box mt={1}>
        <TextField
          data-testid="email"
          fullWidth
          variant="outlined"
          error={!!errors}
          value={credentials.email}
          label="Email"
          name="email"
          onChange={handleChange}
          onKeyDown={handleKeyPress}
        />
      </Box>
      <Box mt={1}>
        <TextField
          data-testid="password"
          type={showPassword ? "text" : "password"}
          fullWidth
          variant="outlined"
          error={!!errors}
          value={credentials.password}
          label="Password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  tabIndex={-1}
                  onClick={() => setShowPassword(!showPassword)}
                  size="large"
                >
                  {showPassword ? (
                    <ViewOffIcon size="small" />
                  ) : (
                    <ViewIcon size="small" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          name="password"
          onChange={handleChange}
          onKeyDown={handleKeyPress}
        />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
        <LoadingButton
          data-testid="button"
          variant="contained"
          color="primary"
          loading={loading}
          fullWidth={isMobileView}
          onClick={handleLoginWithEmail}
          sx={{ minWidth: 160 }}
        >
          Login
        </LoadingButton>

        <Link
          component="button"
          underline="none"
          variant="body1"
          color="primary"
          sx={{ fontWeight: 500, mt: 2 }}
          onClick={() =>
            history.push("/forgot-password", { from: location?.state?.from })
          }
        >
          Forgot Password?
        </Link>
      </Box>
    </>
  );
}

export default LoginPage;
