import { useEffect, useState } from "react";
import { usePrevious } from "./usePrevious";
import every from "lodash/every";
import { useInputErrors } from "./useInputErrors";

type UseInputFieldsArgs = {
  onSuccessfulSubmit: () => void;
};

/**
 * useInputFields hook
 * - Provides API to set and reset errors, and submit after checking for errors.
 *
 * * Function Generic
 * @typedef {T extends object} InputErrorType - An Object Type, with input field names as the keys which we wish to check for errors.
 *
 * * useInputFields Arguments
 * @param {function} onSuccessfulSubmit - Is invoked when submitting AND there are no errors. Only invoked after @function errorCheckAndSubmit is called.
 *
 * * useInputFields API
 * @returns useInputFields API
 * @param {T extends object} inputErrors - Error strings matching generic. If value is a non-empty string, that field key has an error.
 * @param {function} resetInputErrorField - Resets the given field name's value in 'inputErrors' to an empty string.
 * @param {function} setInputErrorField - Sets the given field name's value in 'inputErrors' to the given error string.
 * @param {function} errorCheckAndSubmit - Checks for errors in 'inputErrors' and if there are none, invokes @function onSuccessfulSubmit.
 *
 * * Future Additions to API
 * - onFailedSubmit - An optional function that is invoked after user attempts to submit but has input errors.
 */
function useInputFields<T extends object>(args: UseInputFieldsArgs) {
  const { onSuccessfulSubmit } = args;

  // state
  const [isAttemptingSubmit, setIsAttemptingSubmit] = useState(false);

  // hooks
  const prevIsAttemptingSubmit = usePrevious(isAttemptingSubmit);
  const { inputErrors, resetInputErrorField, setInputErrorField } =
    useInputErrors<T>();

  // Event Handlers
  // sets state that will trigger the error checking useEffect
  const errorCheckAndSubmit = () => {
    setIsAttemptingSubmit(true);
  };

  // Effects
  /**
   * Invoke @function onSuccessfulSubmit ONLY if there are no errors.
   *  */
  useEffect(() => {
    if (!isAttemptingSubmit || prevIsAttemptingSubmit === isAttemptingSubmit) {
      setIsAttemptingSubmit(false);
      return;
    }

    const doesNotHaveInputError = every(
      inputErrors,
      (errorField) => !errorField
    );

    if (doesNotHaveInputError) {
      onSuccessfulSubmit();
    }

    // current attempt to submit has finished
    setIsAttemptingSubmit(false);
  }, [
    inputErrors,
    onSuccessfulSubmit,
    prevIsAttemptingSubmit,
    isAttemptingSubmit,
  ]);

  return {
    inputErrors,
    resetInputErrorField,
    setInputErrorField,
    errorCheckAndSubmit,
  };
}

export { useInputFields };
