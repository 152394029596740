import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";

import {
  granite,
  moovsBlue,
  moovsBlueLight,
  moovsBlueSelected,
} from "design-system/colors";
import { useCurrentUser, useScreenSize, useSnackbar } from "globals/hooks";
import farmVehicles from "../../design-system/illustrations/farmVehicles.svg";
import checkSymbol from "../../design-system/illustrations/checkSymbol.svg";
import { SEND_REQUEST_AFFILIATE_EMAIL_MUTATION } from "../../globals/graphql/farming/farmVehicle.graphql";

type FarmOutRequestMoovsBlockProps = {
  tripId: string;
};

function FarmOutRequestMoovsBlock(props: FarmOutRequestMoovsBlockProps) {
  const { tripId } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const snackbar = useSnackbar();
  const { isSwoopCurrentOperator } = useCurrentUser();

  // state
  const [hasRequestAffiliateSent, setHasRequestAffiliateSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const [sendAffiliateRequest] = useMutation(
    SEND_REQUEST_AFFILIATE_EMAIL_MUTATION,
    {
      onCompleted() {
        setHasRequestAffiliateSent(true);
        setLoading(false);
      },
      onError(error) {
        setLoading(false);
        snackbar.error("Error sending request to Moovs. Try again later.");
      },
    }
  );

  // event handlers
  const handleRequestAffiliateClick = async () => {
    setHasRequestAffiliateSent(false);
    setLoading(true);
    await sendAffiliateRequest({
      variables: {
        input: {
          tripId,
        },
      },
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      textAlign="center"
      flexDirection="column"
      color={granite}
      p={isMobileView ? 1 : 10}
      pt={2}
      data-testid="farm-out-request-moovs"
    >
      <Box width={145} m="auto" mb={2}>
        <img src={farmVehicles} alt="farmout" />
      </Box>
      <Box mb={2}>
        <Box mb={2}>
          <Typography variant="h3" color="textPrimary">
            No Affiliate Vehicles in Area
          </Typography>
        </Box>
        <Typography variant="body2">
          There are no affiliate vehicles available within 300 miles of this
          route.
        </Typography>
      </Box>
      {!isSwoopCurrentOperator && tripId && (
        <Box
          border={`1px solid ${moovsBlueLight}`}
          bgcolor={moovsBlueSelected}
          p={2}
        >
          {hasRequestAffiliateSent ? (
            <Box>
              <Box width={40} m="auto" mb={2}>
                <img src={checkSymbol} alt="check" />
              </Box>

              <Typography>
                We've received your request. Expect an email response from Moovs
                within 3-5 business days.
              </Typography>
            </Box>
          ) : loading ? (
            <Box>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Box mb={2}>
                <Typography variant="body2">
                  You can also request for Moovs to find an affiliate for this
                  trip. Please note this will take 3-5 business days.
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginBottom: "16px" }}
                onClick={handleRequestAffiliateClick}
              >
                Request Affiliate
              </Button>
              <Divider style={{ backgroundColor: moovsBlue }} />
              <Box mt={2}>
                <Typography variant="body2">
                  Do you know an affiliate in the area? Refer them and you'll
                  receive a free month of Moovs!
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default FarmOutRequestMoovsBlock;
