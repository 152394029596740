import React from "react";
import { useHistory } from "react-router-dom";

import { Typography, Box, Button } from "@mui/material";

import noAccessVehiclesCrashIllustationMobile from "design-system/illustrations/noAccessVehiclesCrash/noAccessVehiclesCrashMobile.svg";
import noAccessVehiclesCrashIllustationDesktop from "design-system/illustrations/noAccessVehiclesCrash/noAccessVehiclesCrashDesktop.svg";
import { granite } from "design-system/colors";
import { useCurrentUser, useScreenSize } from "globals/hooks";
import { getRedirectPathBasedOnPermissions } from "globals/utils/getRedirectPathBasedOnPermissions";

function NoAccessPage() {
  // hooks
  const { isMobileView } = useScreenSize();
  const history = useHistory();
  const currentUser = useCurrentUser();

  const userAccessPermissions = currentUser?.accessPermissions || [];

  const redirectPath = getRedirectPathBasedOnPermissions({
    userAccessPermissions,
  });

  const handleClick = () => {
    history.push(redirectPath);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      minHeight="90vh"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        component="img"
        src={
          isMobileView
            ? noAccessVehiclesCrashIllustationMobile // TODO @Ramin update this when you get it back from Marton
            : noAccessVehiclesCrashIllustationDesktop
        }
        alt="A limo and a shuttle crashing"
        mb={6}
      />
      <Typography variant="h2" textAlign="center">
        Oops, You have
        <br />
        no access to this page.
      </Typography>
      <Typography
        variant="body1"
        color={granite}
        textAlign="center"
        mt={2}
        px={2}
      >
        Please contact your admin for further information.
      </Typography>
      <Box mt={4} width={isMobileView ? "327px" : "129px"}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleClick}
        >
          Go to Home
        </Button>
      </Box>
    </Box>
  );
}

export default NoAccessPage;
