import React from "react";

import { Dialog, Box, IconButton, Typography } from "@mui/material";

import { granite, black, grayDark, white } from "../design-system/colors";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CrossIcon,
} from "../design-system/icons";
import { primaryMainColor } from "../theme";
import { useScreenSize } from "../globals/hooks";
import { DraggableComponent } from "./DraggableComponent";

export type MoovsLightboxProps = {
  photos: string[];
  activePhotoIndex: number;
  setActivePhotoIndex: (photoIndex: number) => void;
  label?: string;
  hideArrows?: boolean;
};

function MoovsLightbox(props: MoovsLightboxProps) {
  const { photos, setActivePhotoIndex, activePhotoIndex, label, hideArrows } =
    props;

  const { isMobileView } = useScreenSize();

  const leftArrowDisabled = activePhotoIndex === 1;
  const rightArrowDisabled = activePhotoIndex === photos.length;

  // event handlers
  const handleCloseLightbox = () => setActivePhotoIndex(null);
  const handleNextPhotoClick = () => setActivePhotoIndex(activePhotoIndex + 1);
  const handlePrevPhotoClick = () => setActivePhotoIndex(activePhotoIndex - 1);

  return (
    <Dialog
      onClose={handleCloseLightbox}
      open={!!activePhotoIndex}
      maxWidth="md"
      {...(!isMobileView && {
        PaperComponent: (paperProps) =>
          DraggableComponent({
            paperProps,
            handleName: "moovs-lightbox-dialog-title",
          }),
      })}
      aria-labelledby={"moovs-lightbox-dialog-title"}
    >
      <Box display="flex" flexDirection="column" maxHeight="100vh" p={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          id={"moovs-lightbox-dialog-title"}
        >
          {label ? (
            <Typography variant="h3" style={{ color: black }}>
              {label}
            </Typography>
          ) : (
            <Box m={2} display="flex" flexDirection="row">
              <Typography variant="body2" style={{ color: black }}>
                {activePhotoIndex}
              </Typography>
              <Box mx={1.5}>
                <Typography variant="body2" style={{ color: grayDark }}>
                  /
                </Typography>
              </Box>
              <Typography variant="body2" style={{ color: grayDark }}>
                {photos.length}
              </Typography>
            </Box>
          )}

          <Box>
            <IconButton onClick={handleCloseLightbox} size="large">
              <CrossIcon color={granite} />
            </IconButton>
          </Box>
        </Box>

        <Box
          height="100%"
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          style={{ cursor: "default" }}
        >
          {isMobileView ? (
            <>
              {/* mobile view */}
              <Box width="100%">
                <img
                  width="100%"
                  src={photos[activePhotoIndex - 1]}
                  alt={photos[activePhotoIndex - 1]}
                />
              </Box>

              {!hideArrows && (
                <Box
                  display="flex"
                  alignItems="space-between"
                  justifyContent="space-between"
                  width="100%"
                  mt={1}
                >
                  <Box>
                    <IconButton
                      onClick={handlePrevPhotoClick}
                      disabled={leftArrowDisabled}
                      style={{
                        backgroundColor: leftArrowDisabled
                          ? white
                          : primaryMainColor,
                        border: `1px solid ${
                          leftArrowDisabled ? grayDark : "transparent"
                        }`,
                      }}
                      size="large"
                    >
                      <ArrowLeftIcon
                        color={leftArrowDisabled ? black : white}
                      />
                    </IconButton>
                  </Box>

                  <Box>
                    <IconButton
                      onClick={handleNextPhotoClick}
                      disabled={rightArrowDisabled}
                      style={{
                        backgroundColor: rightArrowDisabled
                          ? white
                          : primaryMainColor,
                        border: `1px solid ${
                          rightArrowDisabled ? grayDark : "transparent"
                        }`,
                      }}
                      size="large"
                    >
                      <ArrowRightIcon
                        color={rightArrowDisabled ? black : white}
                      />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <>
              {/* desktop view */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
              >
                {!hideArrows && (
                  <Box ml={2}>
                    <IconButton
                      onClick={handlePrevPhotoClick}
                      disabled={leftArrowDisabled}
                      style={{
                        backgroundColor: leftArrowDisabled
                          ? white
                          : primaryMainColor,
                        border: `1px solid ${
                          leftArrowDisabled ? grayDark : "transparent"
                        }`,
                      }}
                      size="large"
                    >
                      <ArrowLeftIcon
                        color={leftArrowDisabled ? black : white}
                      />
                    </IconButton>
                  </Box>
                )}
                <Box mx={5} width="100%">
                  <img
                    width="100%"
                    src={photos[activePhotoIndex - 1]}
                    alt={photos[activePhotoIndex - 1]}
                  />
                </Box>
                {!hideArrows && (
                  <Box mr={2}>
                    <IconButton
                      onClick={handleNextPhotoClick}
                      disabled={rightArrowDisabled}
                      style={{
                        backgroundColor: rightArrowDisabled
                          ? white
                          : primaryMainColor,
                        border: `1px solid ${
                          rightArrowDisabled ? grayDark : "transparent"
                        }`,
                      }}
                      size="large"
                    >
                      <ArrowRightIcon
                        color={rightArrowDisabled ? black : white}
                      />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}

export default MoovsLightbox;
