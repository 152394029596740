import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment-timezone";
import clipboardCopy from "clipboard-copy";

import { useQuery } from "@apollo/client";

import { Box, CircularProgress } from "@mui/material";

import UpdateDrawer from "../../../globals/UpdateDrawer";
import { LOAD_TERM_QUERY } from "../../../../globals/graphql";
import { useSnackbar } from "../../../../globals/hooks/useSnackbar";
import { CopyIcon, TrashIcon } from "../../../../design-system/icons";
import GQLQueryStatusIndicator from "../../../GQLQueryStatusIndicator";
import { useOperator } from "../../../../globals/hooks";
import UpdateTermsDrawerForm from "./components/UpdateTermsDrawerForm";
import { Term } from "types";
import RemoveTermDialog from "./components/RemoveTermDialog";

function UpdateTermsDrawer() {
  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const { termId } = useParams<{ termId: string }>();
  const { nameSlug } = useOperator() || {};

  // queries
  const {
    data: termData,
    loading: termLoading,
    refetch: termRefetch,
    error: termError,
  } = useQuery(LOAD_TERM_QUERY, {
    variables: {
      id: termId,
    },
    skip: !termId,
    errorPolicy: "all",
  });

  // state
  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "loading" | "saved" | "error"
  >("default");
  const [removeTermDialogOpen, setRemoveTermDialogOpen] =
    useState<boolean>(false);

  // derived state
  const term: Term = termData?.node;
  const createdAt = term ? moment(term.createdAt).format("LLL") : "";
  const updatedAt = term ? moment(term.updatedAt).format("LLL") : "";

  // event handlers
  const handleCopyLink = async (name: string) => {
    let snackbarText: string;
    let clipboardText: string;

    if (name === "terms-and-conditions") {
      snackbarText = "Terms and Conditions";
      clipboardText = `${process.env.REACT_APP_CUSTOMERAPP_URL}/${nameSlug}/terms-and-conditions`;
    }
    try {
      await clipboardCopy(clipboardText);
      snackbar.success(`Successfully copied ${snackbarText} link!`);
    } catch (error) {
      snackbar.error(`Error copying ${snackbarText} link.`);
    }
  };

  // clears term on toggling drawer
  useEffect(() => {
    setSaveIndicatorState("default");
  }, [termId]);

  return (
    <>
      <UpdateDrawer
        onClose={() => {
          history.push("/settings/terms-and-conditions");
        }}
        createdAt={createdAt}
        updatedAt={updatedAt}
        saveIndicatorState={saveIndicatorState}
        ellipsisMenuOptions={[
          "COPY",
          {
            onClick: () => handleCopyLink("terms-and-conditions"),
            text: "Terms & Conditions",
            icon: <CopyIcon size="small" />,
          },
          {
            onClick: () => {
              setRemoveTermDialogOpen(true);
            },
            text: "Delete Term",
            icon: <TrashIcon size="small" />,
          },
        ]}
      >
        {termLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={40} thickness={2} />
          </Box>
        )}
        {termError && !termLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <GQLQueryStatusIndicator
              name="Term"
              data={termData}
              error={termError}
              refetch={termRefetch}
            />
          </Box>
        )}
        {term && (
          <UpdateTermsDrawerForm
            term={term}
            setSaveIndicatorState={setSaveIndicatorState}
          />
        )}
      </UpdateDrawer>
      <RemoveTermDialog
        open={removeTermDialogOpen}
        onClose={() => setRemoveTermDialogOpen(false)}
        termId={termId}
      />
    </>
  );
}

export default UpdateTermsDrawer;
