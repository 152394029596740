import React, { useState } from "react";

import { Box, Button, Typography } from "@mui/material";

import { moovsBlue } from "design-system/colors";
import { EditIcon } from "design-system/icons";
import { useScreenSize } from "globals/hooks";
import EditNameTextField from "./EditNameTextField";

function EditName(props) {
  const { control, name, errorMessage, onOverviewSubmit, onNameSave } = props;

  // hooks
  const { isSmallTabletView } = useScreenSize();

  // state
  const [editName, setEditName] = useState(false);

  // event handlers
  // handleNameSave will update default generated form name to user's new input and validate
  const handleNameSave = () => {
    setEditName(false);
    onOverviewSubmit(onNameSave)();
  };

  const handleNameEdit = () => {
    setEditName(true);
  };

  return (
    <>
      {!isSmallTabletView ? (
        <Box display="flex" alignItems="center" mb={4}>
          <Box mr={2}>
            <Typography variant="subtitle1">Name: </Typography>
          </Box>
          <EditNameTextField
            name={name}
            control={control}
            errorMessage={errorMessage}
            editName={editName}
          />
          {editName ? (
            <Box ml={3}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleNameSave}
              >
                Save
              </Button>
            </Box>
          ) : (
            <Box ml={3} onClick={handleNameEdit} style={{ cursor: "pointer" }}>
              <EditIcon color={moovsBlue} />
            </Box>
          )}
        </Box>
      ) : (
        <>
          <Box display="flex" alignItems="center" mb={2}>
            <Box mr={2}>
              <Typography variant="subtitle1">Name: </Typography>
            </Box>
            <EditNameTextField
              name={name}
              control={control}
              errorMessage={errorMessage}
              editName={editName}
            />
            {editName ? null : (
              <Box onClick={handleNameEdit} ml={2}>
                <EditIcon color={moovsBlue} />
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end" mb={4}>
            {editName ? (
              <Box ml={1}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleNameSave}
                  disabled={errorMessage ? true : false}
                >
                  Save
                </Button>
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </>
  );
}

export default EditName;
