import React from "react";
import startCase from "lodash/startCase";

import { Card, CardActionArea, Typography, Box } from "@mui/material";

import { Driver } from "../../../types";
import MoovsAvatar from "../../../design-system/components/MoovsAvatar";
import { black } from "../../../design-system/colors";

type DriversMobileListItemProps = {
  driver: Driver;
  onDriverListItemClick: (driverId) => void;
};

function DriversMobileListItem(props: DriversMobileListItemProps) {
  const { driver, onDriverListItemClick } = props;

  const driverName = `${startCase(driver.firstName)} ${startCase(
    driver.lastName
  )}
  `;

  return (
    <Card variant="outlined">
      <CardActionArea
        disableRipple
        onClick={() => onDriverListItemClick(driver.id)}
      >
        <Box p={2} display="flex" flexDirection="row" flex="1">
          <Box mr={2}>
            <MoovsAvatar
              alt="contact, person"
              size="medium"
              src={driver.driverProfilePhoto}
              placeholder={[driver.firstName, driver.lastName]}
            />
          </Box>
          <Box display="flex" flexDirection="column" flex="1">
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 700, color: black, fontSize: 16 }}
              >
                {driverName}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption" sx={{ color: black }}>
                {driver.mobilePhone}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default DriversMobileListItem;
