import React from "react";

import { useOperator, useSnackbar } from "globals/hooks";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { LOAD_OPERATOR_QUERY, REMOVE_USER_MUTATION } from "globals/graphql";
import RemoveDialog from "components/RemoveDialog";

type RemoveTermDialogProps = {
  open: boolean;
  onClose: () => void;
  userId: string;
};

function RemoveMemberDialog(props: RemoveTermDialogProps) {
  const { open, onClose, userId } = props;

  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const { id: operatorId } = useOperator() || {};

  // mutations
  const [removeUser] = useMutation(REMOVE_USER_MUTATION, {
    refetchQueries: [
      { query: LOAD_OPERATOR_QUERY, variables: { id: operatorId } },
    ],
    onCompleted() {
      snackbar.success("Successfully deleted member!");
      history.push("/settings/members");
    },
    onError(error) {
      snackbar.error("Error deleting member");
    },
  });

  // event handlers
  const handleRemoveUser = () => {
    removeUser({
      variables: {
        input: {
          id: userId,
        },
      },
    });

    onClose();
  };

  return (
    <RemoveDialog
      open={open}
      onRemove={handleRemoveUser}
      onClose={onClose}
      title="Remove this user?"
      body="This will permanantly remove the user. Do you want to remove this
    user?"
    />
  );
}

export default RemoveMemberDialog;
