/**
 * @file graphql/tripReview.graphql.ts
 * queries/mutations for tripReview.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_TRIP_REVIEWS_QUERY = gql`
  query loadTripReviews($limit: Int, $cursor: String) {
    loadTripReviews(first: $limit, after: $cursor) {
      totalReviewCount
      avgRating
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          createdAt
          rating
          body
          reviewSite
          trip {
            id
            createdAt
            tripNumber
            stops {
              id
              dateTime
            }
            request {
              id
              orderNumber
            }
          }
        }
      }
    }
  }
`;
