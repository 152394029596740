import React, { ChangeEvent } from "react";

import { Box, Typography } from "@mui/material";

import {
  LabeledInlineInput,
  LabeledInlineLocationAutocomplete,
} from "../../../../design-system/components/inputs";
import ProfileCustomizationBlock from "../../../../components/settings/general/ProfileCustomizationBlock";
import { Operator } from "../../../../types";
import { errorRed, granite } from "design-system/colors";
import { useCustomDomainFromName } from "../hooks/useCustomDomainFromName";
import { useOperator } from "globals/hooks";

type CompanyTabProps = {
  operator: Operator;
  isMobileView: boolean;
  onOperatorInfoChange: (
    key: string
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  onOperatorLocationChange: (key: string) => void;
  onOperatorPhotoChange: (key: string) => (url: string) => void;
};

function CompanyTab(props: CompanyTabProps) {
  const {
    operator,
    isMobileView,
    onOperatorInfoChange,
    onOperatorLocationChange,
    onOperatorPhotoChange,
  } = props;

  // hooks
  const operatorData = useOperator(); // ensures customDomain property is up to date after adding one from Communication - ideally should be maintained by one hook + refetch
  const { customDomainFromName, fromNameError, onOperatorFromNameChange } =
    useCustomDomainFromName({ onOperatorInfoChange });

  return (
    <Box>
      {/* Operator Information */}
      <Box mb={2}>
        <Typography variant="h5">Company Information</Typography>
      </Box>
      <Box my={0.5}>
        <LabeledInlineInput
          required
          errorText="Company name required"
          label="Company Name"
          labelMaxWidth={isMobileView ? "80px" : undefined}
          labelSize={isMobileView ? "xs" : undefined}
          value={operator.name}
          onChange={onOperatorInfoChange("name")}
        />
      </Box>
      <Box my={0.5}>
        <LabeledInlineLocationAutocomplete
          label="Company Address"
          labelMaxWidth={isMobileView ? "80px" : undefined}
          labelSize={isMobileView ? "xs" : undefined}
          value={operator.address}
          onChange={(event) => {
            onOperatorLocationChange(event);
          }}
          required
          errorText="Address required"
        />
      </Box>
      <Box my={0.5}>
        <LabeledInlineInput
          label="Website URL"
          labelMaxWidth={isMobileView ? "80px" : undefined}
          labelSize={isMobileView ? "xs" : undefined}
          value={operator.websiteUrl}
          onChange={onOperatorInfoChange("websiteUrl")}
        />
      </Box>
      <Box my={0.5}>
        <LabeledInlineInput
          label="Permit #"
          labelMaxWidth={isMobileView ? "80px" : undefined}
          labelSize={isMobileView ? "xs" : undefined}
          value={operator.permitNumber}
          onChange={onOperatorInfoChange("permitNumber")}
        />
      </Box>

      {/* Contact Information */}
      <Box mt={4} mb={2}>
        <Typography variant="h5">Additional Details</Typography>
      </Box>
      <Box my={0.5}>
        <LabeledInlineInput
          required
          errorText="General email required"
          label="General Email"
          labelMaxWidth={isMobileView ? "80px" : undefined}
          labelSize={isMobileView ? "xs" : undefined}
          value={operator.generalEmail}
          onChange={onOperatorInfoChange("generalEmail")}
        />
      </Box>
      <Box my={0.5}>
        <LabeledInlineInput
          label="From Email"
          labelMaxWidth={isMobileView ? "80px" : undefined}
          labelSize={isMobileView ? "xs" : undefined}
          value={customDomainFromName}
          disabled={!operatorData.customDomain}
          placeholder={
            operatorData.customDomain
              ? "Email name (the portion before @)"
              : "Set up your domain in Communication to use"
          }
          onChange={onOperatorFromNameChange}
          error={fromNameError}
          errorText={fromNameError}
        />
        <Box display="flex">
          {/* Empty box for spacing - matches default LabelWrapper widths */}
          <Box width={isMobileView ? "110px" : "168px"} />
          {operatorData.customDomain && (
            <>
              <Typography
                variant="body2"
                fontWeight={700}
                mt={1}
                mb={2}
                display="inline-flex"
                justifyContent="flex-start"
                color={fromNameError && errorRed}
              >
                {customDomainFromName || "[email name]"}
              </Typography>
              <Typography
                variant="body2"
                color={fromNameError ? errorRed : granite}
                mt={1}
                mb={2}
                display="inline-flex"
                justifyContent="flex-start"
              >
                @{operatorData.customDomain}
              </Typography>
            </>
          )}
        </Box>
      </Box>
      <Box my={0.5}>
        <LabeledInlineInput
          label="Booking Email"
          labelMaxWidth={isMobileView ? "80px" : undefined}
          labelSize={isMobileView ? "xs" : undefined}
          value={operator.bookingEmail}
          onChange={onOperatorInfoChange("bookingEmail")}
        />
      </Box>

      {/* Profile Customization */}
      <Box mt={4} mb={2}>
        <ProfileCustomizationBlock
          onPhotoChange={onOperatorPhotoChange("companyLogoUrl")}
          photoUrl={operator.companyLogoUrl || ""}
        />
      </Box>
    </Box>
  );
}

export default CompanyTab;
