import React, { useState } from "react";
import axios from "axios";
import { useQuery } from "@apollo/client";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";

import { alabaster, grayLight } from "design-system/colors";
import { GoogleIcon } from "design-system/icons";
import { useAnalytics, useScreenSize } from "globals/hooks";
import { DisconnectAccountDialog } from "./components";
import { LOAD_GOOGLE_CALENDAR_AUTH_QUERY } from "globals/graphql";
import { installToTrack } from "pages/moovs-market/helpers/enumToTrack";
import { getServerLink } from "globals/utils/getServerLink";

function ConnectedGoogleAccount() {
  // state
  const [disconnectAccountDialogOpen, setDisconnectAccountDialogOpen] =
    useState(false);
  // hooks
  const { isMobileView } = useScreenSize();
  const { data, loading } = useQuery(LOAD_GOOGLE_CALENDAR_AUTH_QUERY, {
    fetchPolicy: "network-only",
  });
  const { track } = useAnalytics();

  const googleCalendarAuthData = data?.loadGoogleCalendarAuth;

  // event handlers
  const handleConnectGoogleAccount = async () => {
    track(installToTrack["google_calendar"]);

    const res = await axios.get(
      `${getServerLink()}/google-calendar-authorize`
    );

    window.location.replace(res.data);

    return;
  };

  if (loading) {
    return (
      <Box
        width="100%"
        height="100px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!googleCalendarAuthData) {
    return (
      <Button
        variant="contained"
        onClick={handleConnectGoogleAccount}
        {...(isMobileView && { sx: { width: "100%" } })}
      >
        Authorize Calendar
      </Button>
    );
  }

  return (
    <Box display="flex" flexDirection="column" mb={3}>
      <Typography mb={2} variant="overline">
        Connected Google Account
      </Typography>
      <Box
        display="flex"
        bgcolor={alabaster}
        borderRadius="4px"
        flexDirection={isMobileView ? "column" : "row"}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          p={3}
          display="flex"
          flexDirection={isMobileView ? "column" : "row"}
          alignItems={isMobileView ? "flex-start" : "center"}
          width="100%"
        >
          <Box display="flex" alignItems="center" py={1}>
            <GoogleIcon />
            <Typography variant="subtitle2" fontSize="14px" px={2}>
              Google
            </Typography>
          </Box>
          <Typography fontSize="14px" px={isMobileView ? 1 : 2} py={1}>
            {googleCalendarAuthData.emailAddress}
          </Typography>
        </Box>
        {isMobileView && <Divider sx={{ width: "90%", color: grayLight }} />}
        <Box px={2} py={2}>
          <Button
            color="error"
            onClick={() => setDisconnectAccountDialogOpen(true)}
          >
            <Typography fontWeight={500} width="180px">
              Disconnnect Calendar
            </Typography>
          </Button>
        </Box>
        <DisconnectAccountDialog
          open={disconnectAccountDialogOpen}
          emailAddress={googleCalendarAuthData.emailAddress}
          onClose={() => {
            setDisconnectAccountDialogOpen(false);
          }}
        />
      </Box>
    </Box>
  );
}

export default ConnectedGoogleAccount;
