/**
 * @file graphql/linkPassenger.ts
 * queries/mutations for LinkPassenger.
 *
 */

import { gql } from "@apollo/client";

export const LINK_PASSENGER_TO_CONTACT_MUTATION = gql`
  mutation CreateLinkedPassenger($input: CreateLinkedPassengerInput!) {
    createLinkedPassenger(input: $input) {
      contact {
        id
        firstName
        lastName
      }
      passenger {
        id
        firstName
        lastName
      }
    }
  }
`;

export const UNLINK_PASSENGER_FROM_CONTACT_MUTATION = gql`
  mutation RemoveLinkedPassenger($input: RemoveLinkedPassengerInput!) {
    removeLinkedPassenger(input: $input) {
      contact {
        id
        firstName
        lastName
      }
      passenger {
        id
        firstName
        lastName
      }
    }
  }
`;
