import React from "react";
import { Box, Typography } from "@mui/material";

import { StopDispatch } from "types";
import ArrivedChip from "components/DispatchTimeChips/components/ArrivedChip";
import DepartedChip from "components/DispatchTimeChips/components/DepartedChip";
import { useScreenSize } from "globals/hooks";
import moment from "moment";

type RideReportPanelProps = {
  isFirstStop: boolean;
  isLastStop: boolean;
  garageIn: Date;
  garageOut: Date;
  stopDispatch: StopDispatch;
};

function RideReportPanel(props: RideReportPanelProps) {
  const { isFirstStop, isLastStop, stopDispatch } = props;

  //  hooks
  const { isMobileView } = useScreenSize();

  if (!stopDispatch) return null;

  // derived state
  const { arrivedAt, departedAt } = stopDispatch;
  const garageOut =
    isFirstStop && props.garageOut && moment(props.garageOut).format("h:mm A");
  const garageIn =
    isLastStop && props.garageIn && moment(props.garageIn).format("h:mm A");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: isMobileView ? "flex-start" : "flex-end",
        mt: 3,
      }}
    >
      {/* garage out */}
      {garageOut && (
        <Box sx={{ display: "flex", flexDirection: "row", mb: 2 }}>
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            Garage Out
          </Typography>
          <Typography variant="body2">{garageOut}</Typography>
        </Box>
      )}
      {/* arrival/departure chips */}
      <Box display="flex" flexDirection="row">
        {arrivedAt && (
          <Box mr={isLastStop ? 0 : 2}>
            <ArrivedChip arrivedAt={arrivedAt} isLastStop={isLastStop} />
          </Box>
        )}
        {departedAt && <DepartedChip departedAt={departedAt} />}
      </Box>
      {/* garage in */}
      {garageIn && (
        <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            Garage In
          </Typography>
          <Typography variant="body2">{garageIn}</Typography>
        </Box>
      )}
    </Box>
  );
}

export default RideReportPanel;
