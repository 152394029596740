import React from "react";

import { grayDark } from "../../design-system/colors";
import {
  VehicleTypeBusIcon,
  VehicleTypeSedanIcon,
  VehicleTypeSUVIcon,
  VehicleTypeVanIcon,
} from "../../design-system/icons";
import { VehicleType } from "../../types";

enum VehicleTypeIconCategories {
  BUS = "bus",
  SEDAN = "sedan",
  SUV = "suv",
  VAN = "van",
}

const { BUS, SEDAN, SUV, VAN } = VehicleTypeIconCategories;

const vehicleIconByIconCategory = (color?: string) => ({
  [BUS]: <VehicleTypeBusIcon color={color || grayDark} />,
  [SEDAN]: <VehicleTypeSedanIcon color={color || grayDark} />,
  [SUV]: <VehicleTypeSUVIcon color={color || grayDark} />,
  [VAN]: <VehicleTypeVanIcon color={color || grayDark} />,
});

const getVehicleTypeNameByTypeSlug = (
  typeSlug: any,
  vehicleTypes: VehicleType[]
) => {
  return vehicleTypes?.find(
    (vehicleType: VehicleType) => vehicleType.typeSlug === typeSlug
  )?.typeName;
};

export { vehicleIconByIconCategory, getVehicleTypeNameByTypeSlug };
