import React from "react";

import { Box, Typography } from "@mui/material";
import { currency } from "../../globals/utils/helpers";
import { useScreenSize } from "globals/hooks";

type DisplayedAmountProps = {
  label: string;
  amountDue: number;
};

function DisplayedAmount(props: DisplayedAmountProps) {
  const { label, amountDue } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Box ml={4} display="flex" flexDirection="column" flex={1}>
      <Box>
        <Typography variant="body2">{label}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
          {currency(amountDue)}
        </Typography>
      </Box>
      <Box mb={0.5}>
        <Typography variant="body2">
          {isMobileView ? "Res" : "Reservation"} Amount Due
        </Typography>
      </Box>
    </Box>
  );
}

export default DisplayedAmount;
