import React from "react";
import { BaseRateVariantEnum } from "types";

import { ClockIcon, PinIcon } from "../../../../design-system/icons";
import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";

// enums
const { Flat, Hourly } = BaseRateVariantEnum;

type BaseRateVariantToggleProps = {
  baseRateVariant: BaseRateVariantEnum;
  onToggle: (baseRateVariant: BaseRateVariantEnum) => void;
};

function BaseRateVariantToggle(props: BaseRateVariantToggleProps) {
  const { baseRateVariant, onToggle } = props;

  // event handlers
  const handleToggle = (_: any, baseRateVariant: BaseRateVariantEnum) => {
    onToggle(baseRateVariant);
  };

  const toggleButtons = [
    {
      value: Flat,
      ariaLabel: Flat,
      icon: PinIcon,
    },
    {
      value: Hourly,
      ariaLabel: Hourly,
      icon: ClockIcon,
    },
  ];

  return (
    <MoovsToggleButtonGroup
      onChange={handleToggle}
      options={toggleButtons}
      value={baseRateVariant}
    />
  );
}
export default BaseRateVariantToggle;
