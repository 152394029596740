import React from "react";

import { Box } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";
import { MoovsMarketAppData } from "pages/moovs-market/components/MoovsMarketAppList/hooks/useMoovsMarketApps";
import {
  DeleteAppDialogContent,
  DeleteAppFeedback,
  FreeTrialDeleteDialog,
} from "./components";
import {
  useDeleteAppFeedback,
  useDeleteAppMutation,
  useFreeTrialDeleteDialog,
} from "./hooks";

type DeleteAppDialogProps = {
  app: MoovsMarketAppData;
  open: boolean;
  onClose: () => void;
};

const DeleteAppDialog = (props: DeleteAppDialogProps) => {
  const { app, open, onClose } = props;

  // hooks
  const {
    deleteReason,
    deleteReasonError,
    onDeleteReasonChange,
    otherReason,
    onOtherReasonChange,
    onDeleteReasonValidate,
  } = useDeleteAppFeedback({ open });

  const { onDeleteApp, loading } = useDeleteAppMutation({
    app,
    deleteReason,
    otherReason,
    onCompleted: onClose,
  });

  const {
    freeTrialDeleteDialogOpen,
    onFreeTrialDeleteDialogOpen,
    onFreeTrialDeleteDialogClose,
    onFreeTrialDeleteApp,
  } = useFreeTrialDeleteDialog({ onClose, onDeleteApp });

  // event handlers
  const handleDeleteClick = () => {
    const canDelete = onDeleteReasonValidate();
    if (!canDelete) return;

    if (app.isPaidApp && app.chargeAt) {
      onFreeTrialDeleteDialogOpen();
      return;
    }

    onDeleteApp();
  };

  return (
    <>
      <MoovsDialog
        open={open}
        onClose={onClose}
        onAccept={handleDeleteClick}
        acceptDisabled={loading}
        acceptButtonText="Delete"
        dialogTitle={`Delete ${app.name}?`}
        size="xs"
        acceptButtonIsDelete
        xsFullscreenMobile
      >
        <Box display="flex" flexDirection="column" my={2}>
          <DeleteAppDialogContent app={app} />
          <DeleteAppFeedback
            isPaidApp={app.isPaidApp}
            deleteReason={deleteReason}
            deleteReasonError={deleteReasonError}
            onDeleteReasonChange={onDeleteReasonChange}
            otherReason={otherReason}
            onOtherReasonChange={onOtherReasonChange}
          />
        </Box>
      </MoovsDialog>
      <FreeTrialDeleteDialog
        open={freeTrialDeleteDialogOpen}
        onClose={onFreeTrialDeleteDialogClose}
        onAccept={onFreeTrialDeleteApp}
      />
    </>
  );
};

export default DeleteAppDialog;
