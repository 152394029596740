import React, { useState, useEffect } from "react";

import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { Box, Button, Typography, CircularProgress } from "@mui/material";

import {
  moovsBlueLight,
  granite,
  errorRed,
} from "../../../design-system/colors";
import ImageUpload from "../../ImageUpload/ImageUpload";
import { primaryDisabledColor, primaryMainColor } from "../../../theme";
import { useAnalytics } from "globals/hooks";

const styles = {
  photoBox: {
    width: "250px",
    height: "100px",
    backgroundColor: primaryDisabledColor,
    borderRadius: "4px",
    cursor: "pointer",
    transition: ".2s",
    overflow: "hidden",
    "&:hover": {
      backgroundColor: moovsBlueLight,
    },
    boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.08)",
  },
  img: {
    objectFit: "contain",
    width: "250px",
    maxWidth: "100vw",
  },
};

type ProfileCustomizationProps = {
  onPhotoChange: Function;
  photoUrl: string;
};

function ProfileCustomization(props: ProfileCustomizationProps) {
  const { onPhotoChange, photoUrl } = props;

  // state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // hooks
  const { track } = useAnalytics();

  useEffect(() => {
    setLoading(false);
  }, [photoUrl]);

  const handleRemoveCompanyLogo = () => {
    track("settings_logoRemoved");
    setLoading(true);
    setTimeout(() => onPhotoChange(null), 300);
  };

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5">Profile Customization</Typography>
      </Box>
      <label htmlFor={HTML_FOR}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={styles.photoBox}
        >
          {/* Photo Exists */}
          {!!photoUrl.length && !error && !loading && (
            <img
              src={photoUrl}
              alt={`vehicle-${photoUrl}`}
              style={{
                objectFit: "cover",
                width: "250px",
                height: "100px",
              }}
            />
          )}

          {/* Uploading or Removing a Photo */}
          {loading && !error && <CircularProgress />}

          {/* No Photo */}
          {!error && !loading && !photoUrl.length && (
            <CameraAltOutlinedIcon
              style={{ color: primaryMainColor, height: "48px", width: "40px" }}
            />
          )}

          {/* Error */}
          {!!error && (
            <Typography style={{ color: errorRed }}>{error}</Typography>
          )}
        </Box>
      </label>
      <Box my={1.5}>
        {!photoUrl.length ? (
          <ImageUpload
            firebaseDirectory="company_logos"
            htmlFor={HTML_FOR}
            aspectRatio={5 / 2}
            maxWidth={1000}
            maxHeight={400}
            onFileUploadStart={() => setLoading(true)}
            onFileUploadComplete={(url) => onPhotoChange(url)}
            onError={(errorMessage) => setError(errorMessage)}
            button={
              <Button variant="outlined" color="primary" component="span">
                Add Company Logo
              </Button>
            }
          />
        ) : (
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ maxWidth: "250px", maxHeight: "100px" }}
          >
            <ImageUpload
              firebaseDirectory="onboarding_photos"
              htmlFor={HTML_FOR}
              aspectRatio={5 / 2}
              maxWidth={1000}
              maxHeight={1000}
              onFileUploadStart={() => setLoading(true)}
              onFileUploadComplete={(url) => onPhotoChange(url)}
              onError={(errorMessage) => setError(errorMessage)}
              button={
                <Button variant="outlined" color="primary" component="span">
                  <Typography fontWeight={500} fontSize={13}>
                    Replace Logo
                  </Typography>
                </Button>
              }
            />
            <Button
              variant="outlined"
              color="primary"
              component="span"
              onClick={handleRemoveCompanyLogo}
            >
              <Typography fontWeight={500} fontSize={13}>
                Remove Logo
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
      <Box>
        <Typography
          variant="caption"
          style={{ color: granite, fontSize: "12px", fontWeight: 500 }}
        >
          {!photoUrl.length
            ? "Click to upload a new photo."
            : "Click to add or remove a photo."}
        </Typography>
        <Typography style={{ color: granite, fontSize: "12px" }}>
          JPEG/PNG - Min. 500x200px.
        </Typography>
      </Box>
    </Box>
  );
}

export default ProfileCustomization;

const HTML_FOR = "company-logo-button";
