import React from "react";

import { Box, Button, Typography, CardActionArea, Card } from "@mui/material";

import { PromoCode } from "types";
import {
  getFormattedAmount,
  getActiveStatusChip,
  getFormattedDateRange,
  promoCodeStyles,
} from "../utils/getFormattedValues";

type PromoCodesListItemMobileProps = {
  promoCode: PromoCode;
};

function PromoCodesListItemMobile(props: PromoCodesListItemMobileProps) {
  const { promoCode } = props;

  const {
    promoCodeName,
    active,
    promoCodeAmt,
    promoCodePercent,
    redemptionCount,
    bookingStartDate,
    bookingEndDate,
    tripStartDate,
    tripEndDate,
  } = promoCode;

  return (
    <Box mb={1}>
      <Card variant="outlined">
        <CardActionArea disableRipple>
          <Box p={2} display="flex" flexDirection="column" flex="1">
            <Box display="flex" flexDirection="row" flex="1">
              <Box display="flex" flexDirection="column" flex="1">
                <Box>
                  <Typography variant="overline">code</Typography>
                </Box>
                <Typography mt={0.5} sx={promoCodeStyles.promoCodeName}>
                  {promoCodeName}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" flex="1">
                <Box>
                  <Typography variant="overline">status</Typography>
                </Box>
                <Typography mt={0.5}>{getActiveStatusChip(active)}</Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" flex="1" mt={2}>
              <Box display="flex" flexDirection="column" flex="1">
                <Box>
                  <Typography variant="overline">amount</Typography>
                </Box>
                <Typography mt={0.5} sx={promoCodeStyles.promoCodeInfoCopy}>
                  {getFormattedAmount(promoCodeAmt, promoCodePercent)}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" flex="1">
                <Box>
                  <Typography variant="overline">redemptions</Typography>
                </Box>
                <Typography mt={0.5} sx={promoCodeStyles.promoCodeInfoCopy}>
                  {redemptionCount > 0 ? redemptionCount : ""}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              flex="1"
              mt={2}
              minHeight={"42px"}
            >
              <Box display="flex" flexDirection="column" flex="1">
                <Box>
                  <Typography variant="overline">booking date</Typography>
                </Box>
                <Typography
                  mt={0.5}
                  variant="body2"
                  sx={promoCodeStyles.promoCodeInfoCopy}
                >
                  {getFormattedDateRange(bookingStartDate, bookingEndDate)}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" flex="1">
                <Box>
                  <Typography variant="overline">trip date</Typography>
                </Box>
                <Typography
                  mt={0.5}
                  variant="body2"
                  sx={promoCodeStyles.promoCodeInfoCopy}
                >
                  {getFormattedDateRange(tripStartDate, tripEndDate)}
                </Typography>
              </Box>
            </Box>
            <Box mt={2}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                sx={{
                  whiteSpace: "nowrap",
                  height: "46px",
                  width: "100%",
                  alignSelf: "self-end",
                }}
              >
                View
              </Button>
            </Box>
          </Box>
        </CardActionArea>
      </Card>
    </Box>
  );
}

export default PromoCodesListItemMobile;
