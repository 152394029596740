import React, { useState } from "react";

import { Typography, TextField, Box, Divider } from "@mui/material";

import MoovsAvatar from "../design-system/components/MoovsAvatar";
import { useCurrentUser } from "../globals/hooks";

const CHAR_LIMIT = 3000;

type CommentCreateBlockProps = {
  commentBodyText: string;
  onChangeComment: (value: string) => void;
};

function CommentCreateBlock(props: CommentCreateBlockProps) {
  const { commentBodyText, onChangeComment } = props;

  // hooks
  const { firstName, lastName } = useCurrentUser() || {};

  // state
  const [commentBodyTextError, setCommentBodyTextError] = useState(null);

  // event handlers
  const handleChangeCommentInput = (event: any) => {
    let value = event.target.value;

    if (commentBodyText.length >= CHAR_LIMIT) {
      setCommentBodyTextError("Reached character limit");
      value = value.substring(0, CHAR_LIMIT);
    } else {
      setCommentBodyTextError(null);
    }

    onChangeComment(value);
  };

  return (
    <>
      <Box mt={4} mb={1.5}>
        <Typography variant="h5">Internal Comments</Typography>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        flex="1"
        alignItems="center"
        mt={2}
      >
        {firstName && lastName && (
          <MoovsAvatar placeholder={[firstName, lastName]} />
        )}
        <Box width="100%" ml={1.5}>
          <TextField
            fullWidth
            multiline
            label="Comment..."
            variant="outlined"
            value={commentBodyText}
            onChange={handleChangeCommentInput}
            helperText={commentBodyTextError}
            error={!!commentBodyTextError}
          />
        </Box>
      </Box>
    </>
  );
}

export default CommentCreateBlock;
