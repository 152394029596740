import React from "react";
import {
  Box,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import {
  aqua,
  moovsBlue,
  moovsBlueSelected,
  tintAqua,
} from "design-system/colors";
import { ReviewSite } from "types";
import GoogleColoredIconBig from "design-system/icons/branded/GoogleColoredIconBig";
import YelpIcon from "design-system/icons/branded/YelpIcon";
import FacebookIcon from "design-system/icons/branded/FacebookIcon";

type ChooseReviewPlatformBlockProps = {
  value: string;
  onChange: (
    event: React.MouseEvent<HTMLElement>,
    newSelectedPlatform: ReviewSite
  ) => void;
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  "& .MuiToggleButtonGroup-grouped": {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "&.MuiToggleButton-root": {
      borderRadius: "4px",
      borderColor: "#195FE980",
      borderSize: "1px",
      width: "30%",
      height: "125px",
      margin: "10px",
      color: moovsBlue,
      fontWeight: 500,
      fontSize: "14px",
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      justifyContent: "center",
    },
    "&.Mui-selected": {
      borderColor: moovsBlue,
      backgroundColor: moovsBlueSelected,
    },
  },
}));

const ChooseReviewPlatformBlock = (props: ChooseReviewPlatformBlockProps) => {
  const { value, onChange } = props;

  return (
    <StyledToggleButtonGroup value={value} exclusive onChange={onChange}>
      <ToggleButton value="google">
        {/* 'recommended' marker */}
        <Box
          position="absolute"
          sx={{
            top: "5px",
            left: "5px",
            backgroundColor: tintAqua,
            borderRadius: "20px",
          }}
        >
          <Typography fontSize="10px" color={aqua} paddingX={0.75}>
            Recommended
          </Typography>
        </Box>
        <GoogleColoredIconBig />
        Google
      </ToggleButton>
      <ToggleButton value="facebook">
        <FacebookIcon />
        Facebook
      </ToggleButton>
      <ToggleButton value="yelp">
        <YelpIcon />
        Yelp
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};

export default ChooseReviewPlatformBlock;
