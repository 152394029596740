import React from "react";

type UsersIconProps = {
  color?: string;
  size?: "small";
};

const UsersIcon = (props: UsersIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.1818 13V11.7778C11.1818 11.1295 10.9136 10.5077 10.4363 10.0493C9.95891 9.59087 9.31146 9.33333 8.63636 9.33333H3.54545C2.87036 9.33333 2.22291 9.59087 1.74555 10.0493C1.26818 10.5077 1 11.1295 1 11.7778V13M15 13V11.7778C14.9996 11.2362 14.8119 10.71 14.4663 10.282C14.1208 9.85391 13.637 9.54818 13.0909 9.41278M10.5455 2.07944C11.093 2.21407 11.5783 2.51987 11.9249 2.94863C12.2714 3.3774 12.4595 3.90473 12.4595 4.4475C12.4595 4.99027 12.2714 5.5176 11.9249 5.94637C11.5783 6.37513 11.093 6.68093 10.5455 6.81556M8.63636 4.44444C8.63636 5.79447 7.49672 6.88889 6.09091 6.88889C4.68509 6.88889 3.54545 5.79447 3.54545 4.44444C3.54545 3.09441 4.68509 2 6.09091 2C7.49672 2 8.63636 3.09441 8.63636 4.44444Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13M16 3.13C16.8604 3.3503 17.623 3.8507 18.1676 4.55231C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89317 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UsersIcon;
