import React, { useMemo } from "react";

import { Box, Divider, Typography } from "@mui/material";

import { grayMedium, pickUpVariantLabelColors } from "design-system/colors";
import MoreInfoPanel from "./MoreInfoPanel";
import RideReportPanel from "./RideReportPanel";
import { useScreenSize } from "globals/hooks";
import RoundChip from "design-system/components/chips/RoundChip";
import { Stop } from "types";

function Line(props: { height: string }) {
  return (
    <Box
      bgcolor={grayMedium}
      width="1.5px"
      height={props.height}
      my={0.5}
      mr={0.75}
    />
  );
}

function HollowCircle() {
  return (
    <Box
      mr={0.75}
      border="1.5px solid #D3D3D3"
      width={10}
      height={10}
      borderRadius="50%"
      display="inline-block"
      bgcolor="rgba(0, 0, 0, 0)"
    />
  );
}

type TripTimelineStepProps = {
  stop: Stop;
  estimatedDropOffTime: string;
  variant: "ride-report" | "default" | "location-only";
  isLastStop?: boolean;
  garageIn?: Date;
  garageOut?: Date;
  addedStopsHasDateTime?: boolean;
};

function TripTimelineStep(props: TripTimelineStepProps) {
  const {
    stop,
    isLastStop,
    estimatedDropOffTime,
    variant,
    garageIn,
    garageOut,
    addedStopsHasDateTime,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // derived state
  const isRideReport = variant === "ride-report";
  const isLocationOnly = variant === "location-only";
  const pickUpVariant = stop.pickUpVariant;

  const stopLabel = useMemo(() => {
    if (stop.stopIndex === 1) {
      return "Pick-up";
    }
    if (isLastStop) {
      return `Drop-off`;
    }

    return `Stop ${stop.stopIndex - 1}`;
  }, [isLastStop, stop.stopIndex]);

  const airportText =
    stop.variant === "airport" &&
    `${stop.airport.airportName} Airport (${stop.airport.iataCode})`;

  const [address, ...rest] = stop.location.split(",");
  const city = rest.join(",");

  return (
    <Box display="flex" flexDirection="row" width="100%">
      {/* stepper */}
      <Box display="flex" flexDirection="column" alignItems="center">
        <HollowCircle />
        {!isLastStop && <Line height="100%" />}
      </Box>

      {/* label */}
      <Box
        display="flex"
        ml={2}
        mt={-0.5}
        mb={isRideReport ? 2 : 4}
        width="100%"
        flexDirection="column"
      >
        <Box
          display="flex"
          width="100%"
          flexDirection={isMobileView ? "column" : "row"}
          justifyContent={isMobileView ? "flex-start" : "space-between"}
        >
          <Box>
            <Box display="flex" alignItems={"center"} gap={1}>
              <Typography variant="overline">{stopLabel}</Typography>
              {pickUpVariant && (
                <RoundChip {...pickUpVariantLabelColors[pickUpVariant]} />
              )}
            </Box>
            <Typography variant="body2" style={{ marginTop: "8px" }}>
              {airportText || address}
            </Typography>
            <Typography variant="body2" style={{ maxWidth: 200 }}>
              {city}
            </Typography>

            {/* more info panel */}
            {!isLocationOnly && (
              <MoreInfoPanel
                stop={stop}
                stopLabel={stopLabel}
                estimatedDropOffTime={estimatedDropOffTime}
                renderDropOffWarning={isLastStop && addedStopsHasDateTime}
              />
            )}
          </Box>

          {/* ride report panel  */}
          {isRideReport && (
            <RideReportPanel
              stopDispatch={stop.stopDispatch}
              isFirstStop={stop.stopIndex === 1}
              isLastStop={isLastStop}
              garageIn={garageIn}
              garageOut={garageOut}
            />
          )}
        </Box>

        {/* divider line, only for ride report */}
        {isRideReport && !isLastStop && (
          <Divider flexItem light sx={{ mt: 2 }} />
        )}
      </Box>
    </Box>
  );
}

export default TripTimelineStep;
