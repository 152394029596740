import React from "react";

import { Box, Button, Card, CardActionArea, Typography } from "@mui/material";

import { PromoCode } from "types";
import {
  getFormattedAmount,
  getActiveStatusChip,
  getFormattedDateRange,
  promoCodeStyles,
} from "../utils/getFormattedValues";
import OverflowTooltip from "design-system/components/OverflowTooltip";

type PromoCodesListItemDesktopProps = {
  promoCode: PromoCode;
};

function PromoCodesListItemDesktop(props: PromoCodesListItemDesktopProps) {
  const { promoCode } = props;

  const {
    promoCodeName,
    active,
    promoCodeAmt,
    promoCodePercent,
    redemptionCount,
    bookingStartDate,
    bookingEndDate,
    tripStartDate,
    tripEndDate,
  } = promoCode;

  return (
    <Card variant="outlined">
      <CardActionArea disableRipple>
        <Box
          display="flex"
          flexDirection="row"
          flex="1"
          py={1.5}
          px={3}
          height="76px"
          alignItems="center"
        >
          <Box display="flex" flex="1.25" minWidth="100px">
            <Box width="100%">
              <OverflowTooltip
                variant="h4"
                toolTipText={promoCodeName}
                displayText={promoCodeName}
                placement="top"
                style={promoCodeStyles.promoCodeName}
              />
            </Box>
          </Box>
          <Box display="flex" flex="1">
            <Typography>{getActiveStatusChip(active)}</Typography>
          </Box>
          <Box display="flex" flex="1">
            <Typography variant="body2" sx={promoCodeStyles.promoCodeInfoCopy}>
              {getFormattedAmount(promoCodeAmt, promoCodePercent)}
            </Typography>
          </Box>
          <Box display="flex" flex="1">
            <Typography variant="body2" sx={promoCodeStyles.promoCodeInfoCopy}>
              {redemptionCount > 0 ? redemptionCount : ""}
            </Typography>
          </Box>
          <Box display="flex" flex="1.5">
            <Typography variant="body2" sx={promoCodeStyles.promoCodeInfoCopy}>
              {getFormattedDateRange(bookingStartDate, bookingEndDate)}
            </Typography>
          </Box>
          <Box display="flex" flex="1.5">
            <Typography variant="body2" sx={promoCodeStyles.promoCodeInfoCopy}>
              {getFormattedDateRange(tripStartDate, tripEndDate)}
            </Typography>
          </Box>
          <Box display="flex" flex="0.5">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              sx={{
                whiteSpace: "nowrap",
                height: "30px",
                width: "57px",
                alignSelf: "self-end",
              }}
            >
              View
            </Button>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default PromoCodesListItemDesktop;
