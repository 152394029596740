import React from "react";

type ImagePhotoIconProps = {
  color?: string;
  size?: "small";
};

const ImagePhotoIcon = (props: ImagePhotoIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 11.8889C14 12.1836 13.8851 12.4662 13.6805 12.6746C13.4759 12.8829 13.1984 13 12.9091 13H3.09091C2.80158 13 2.52411 12.8829 2.31952 12.6746C2.11493 12.4662 2 12.1836 2 11.8889V5.77778C2 5.48309 2.11493 5.20048 2.31952 4.9921C2.52411 4.78373 2.80158 4.66667 3.09091 4.66667H5.27273L6.36364 3H9.63636L10.7273 4.66667H12.9091C13.1984 4.66667 13.4759 4.78373 13.6805 4.9921C13.8851 5.20048 14 5.48309 14 5.77778V11.8889Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 10.7778C9.20499 10.7778 10.1818 9.78286 10.1818 8.55556C10.1818 7.32826 9.20499 6.33333 8 6.33333C6.79502 6.33333 5.81818 7.32826 5.81818 8.55556C5.81818 9.78286 6.79502 10.7778 8 10.7778Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 18.2222C22 18.6937 21.8084 19.1459 21.4675 19.4793C21.1265 19.8127 20.664 20 20.1818 20H3.81818C3.33597 20 2.87351 19.8127 2.53253 19.4793C2.19156 19.1459 2 18.6937 2 18.2222V8.44444C2 7.97295 2.19156 7.52076 2.53253 7.18737C2.87351 6.85397 3.33597 6.66667 3.81818 6.66667H7.45455L9.27273 4H14.7273L16.5455 6.66667H20.1818C20.664 6.66667 21.1265 6.85397 21.4675 7.18737C21.8084 7.52076 22 7.97295 22 8.44444V18.2222Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16.4444C14.0083 16.4444 15.6364 14.8526 15.6364 12.8889C15.6364 10.9252 14.0083 9.33333 12 9.33333C9.99169 9.33333 8.36364 10.9252 8.36364 12.8889C8.36364 14.8526 9.99169 16.4444 12 16.4444Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ImagePhotoIcon;
