import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import { ChartDataSets } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { currency } from "globals/utils/helpers";
import { useScreenSize } from "globals/hooks";

type HorizontalBarChartType = {
  barChartRenderData: {
    labels: any[];
    datasets: {
      datalabels?: any[];
    } & ChartDataSets[];
    dataSize: number;
  };
};

function HorizontalBarChart(props: HorizontalBarChartType) {
  const { barChartRenderData } = props;

  const { dataSize } = barChartRenderData;

  const { isMobileView } = useScreenSize();

  return (
    <HorizontalBar
      data={barChartRenderData}
      {...(dataSize > 5 && { width: 100 })}
      {...(dataSize > 5 && !isMobileView && dataSize < 10 && { width: 200 })}
      height={isMobileView ? 200 : 120}
      plugins={[ChartDataLabels]}
      redraw={true}
      options={{
        responsive: true,
        plugins: {
          datalabels: {
            align: "end",
            anchor: "end",
            display: true,
            font: {
              weight: "bold",
            },
            formatter: function (value, context) {
              if (!context.dataset.datalabels?.[context.dataIndex]) return null;
              return currency(
                Number(context.dataset.datalabels[context.dataIndex])
              );
            },
          },
        },
        layout: {
          padding: {
            top: 20,
          },
        },
        legend: {
          display: false,
        },
        tooltips: {
          mode: "point",
          displayColors: false,
          caretSize: 0,
          callbacks: {
            title: () => null,
            label: function (tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label;
              return `${label}: ${currency(Number(tooltipItem.value))}`;
            },
          },
        },
        hover: {
          mode: "point",
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              ticks: {
                suggestedMax:
                  Math.max.apply(
                    Math,
                    barChartRenderData.datasets[1].datalabels
                  ) * (isMobileView ? 1.3 : 1.1),
                callback: (label) => {
                  return Number(label) > 1000 || Number(label) === 0
                    ? `$ ${Number(label) / 1000}k`
                    : `$ ${Number(label)}`;
                },
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      }}
    />
  );
}

export default HorizontalBarChart;
