import React from "react";

import { Cell } from "../../utils/types";
import { CheckCircleIcon, MinusIcon } from "design-system/icons";
import { aqua, black, moovsBlue, purple } from "design-system/colors";

const row1: Cell[] = [
  {
    column: 1,
    typographyText: "GNET Farm-in Integration",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <CheckCircleIcon size="small" color={black} />,
  },
  {
    column: 3,
    icon: <CheckCircleIcon size="small" color={moovsBlue} />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row2: Cell[] = [
  {
    column: 1,
    typographyText: "Sales Automation",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <CheckCircleIcon size="small" color={black} />,
  },
  {
    column: 3,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row3: Cell[] = [
  {
    column: 1,
    typographyText: "Affiliate Network",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 3,
    icon: <CheckCircleIcon size="small" color={moovsBlue} />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row4: Cell[] = [
  {
    column: 1,
    typographyText: "SEO-Optimized Website",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 3,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row5: Cell[] = [
  {
    column: 1,
    typographyText: "Embeded Booking Widget",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 3,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row6: Cell[] = [
  {
    column: 1,
    typographyText: "Custom Widget Colors",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 3,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row7: Cell[] = [
  {
    column: 1,
    typographyText: "Reviews Integration",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 3,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 4,
    icon: <CheckCircleIcon size="small" color={aqua} />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row8: Cell[] = [
  {
    column: 1,
    typographyText: "Google Tag Manager",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 3,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 4,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row9: Cell[] = [
  {
    column: 1,
    typographyText: "Customizable Emails",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 3,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 4,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row10: Cell[] = [
  {
    column: 1,
    typographyText: "Customizable Links",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 3,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 4,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const row11: Cell[] = [
  {
    column: 1,
    typographyText: "Live Website Chat",
    typographyVariant: "caption",
  },
  {
    column: 2,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 3,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 4,
    icon: <MinusIcon size="small" />,
  },
  {
    column: 5,
    icon: <CheckCircleIcon size="small" color={purple} />,
  },
];

const chartData = [
  row1,
  row2,
  row3,
  row4,
  row5,
  row6,
  row7,
  row8,
  row9,
  row10,
  row11,
];

export default chartData;
