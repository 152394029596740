import React from "react";

import { Box } from "@mui/material";

import { FlightInfoPlaneIcon } from "../../../design-system/icons";
import { grayMedium } from "../../../design-system/colors";
import flightStatusMap from "../helpers/flightStatusMap";
import { FlightStatus } from "../../../types";
import { useScreenSize } from "../../../globals/hooks";

type FlightProgressDisplayType = {
  flightStatus: FlightStatus;
  removeHorizontalPadding?: boolean;
};

function FlightProgressDisplay(props: FlightProgressDisplayType) {
  const { flightStatus, removeHorizontalPadding } = props;
  const { planeBorderColor, planeFillColor, planePosition } =
    flightStatusMap(flightStatus);

  const { isMobileView } = useScreenSize();

  return (
    <Box
      {...(!isMobileView && !removeHorizontalPadding && { px: 4 })}
      display="flex"
      alignItems="center"
    >
      <hr
        style={{
          flex: planePosition === "start" ? 0 : 1,
          height: 2,
          borderWidth: 0,
          color: grayMedium,
          backgroundColor: grayMedium,
        }}
      />
      <Box
        style={{ width: 45, height: 45 }}
        {...(planePosition === "start" && { mr: 2 })}
        {...(planePosition === "end" && { ml: 2 })}
        {...(planePosition === "middle" && { mx: 2 })}
      >
        <FlightInfoPlaneIcon
          borderColor={planeBorderColor}
          fillColor={planeFillColor}
        />
      </Box>
      <hr
        style={{
          flex: planePosition === "end" ? 0 : 1,
          height: 2,
          borderWidth: 0,
          color: grayMedium,
          backgroundColor: grayMedium,
        }}
      />
    </Box>
  );
}

export default FlightProgressDisplay;
