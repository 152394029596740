import React from "react";
import { getAdjustAmountWords } from "../../../DynamicPricingDialog/utils/generateFormName";
import { IconNames } from "../../utils/types";
import DynamicPricingChip from "../DynamicPricingChip";

type AdjustAmountChipProps = {
  isIncrease: boolean;
  amount: number;
  isPercent: boolean;
};

function AdjustAmountChip(props: AdjustAmountChipProps) {
  const { isIncrease, amount, isPercent } = props;

  let chip;

  const { adjust, displayAmount } = getAdjustAmountWords({
    isIncrease,
    amount,
    isPercent,
  });
  const label = `${adjust} ${displayAmount}`;

  if (isIncrease) {
    chip = <DynamicPricingChip label={label} iconName={IconNames.increase} />;
  } else {
    chip = <DynamicPricingChip label={label} iconName={IconNames.decrease} />;
  }

  return chip;
}

export default AdjustAmountChip;
