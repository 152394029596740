/**
 * Wrapper for Infinite Scroll component.
 * Has two main values:
 * 1. Automatically adds the end message display.
 * 2. Handles data object as returned from server relay pagination.
 *
 * Note: For pagination to work need to add cache in MoovsApolloProvider
 * ref: https://www.apollographql.com/docs/react/pagination/cursor-based/#relay-style-cursor-pagination
 *
 */
import React, { ReactNode } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import isUndefined from "lodash/isUndefined";

import { Box, CircularProgress, Typography } from "@mui/material";

import { grayDark } from "design-system/colors";

type MoovsInfiniteScrollProps = {
  loading: boolean;
  data: {
    edges?: any[];
    totalCount?: number;
    pageInfo: {
      hasNextPage: boolean;
    };
  };
  next: () => void;
  name: string;
  scrollableId?: string;
  children: ReactNode;
};

function MoovsInfiniteScroll(props: MoovsInfiniteScrollProps) {
  const { loading, data, next, name, scrollableId, children } = props;

  const {
    pageInfo: { hasNextPage },
    totalCount,
    edges: { length },
  } = data;

  const InfiniteScrollEndMarker = () => (
    <Typography variant="caption" style={{ color: grayDark }}>
      Displaying{" "}
      <span style={{ fontWeight: "bold", color: "black" }}>{length}</span> out
      of <span style={{ fontWeight: "bold" }}>{totalCount} </span>
      {name}
    </Typography>
  );

  return (
    <InfiniteScroll
      scrollableTarget={scrollableId}
      dataLength={length}
      next={next}
      hasMore={hasNextPage}
      loader={
        <Box textAlign="center">
          {loading && (
            <Box mt={5} mb={3}>
              <CircularProgress />
            </Box>
          )}
          {!isUndefined(data.totalCount) && (
            <Box mb={5}>
              <InfiniteScrollEndMarker />
            </Box>
          )}
        </Box>
      }
      endMessage={
        !isUndefined(data.totalCount) && (
          <Box textAlign="center" mt={3} mb={5}>
            <InfiniteScrollEndMarker />
          </Box>
        )
      }
    >
      {children}
    </InfiniteScroll>
  );
}

export default MoovsInfiniteScroll;
