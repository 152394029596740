import React, { Dispatch, SetStateAction } from "react";
import { useMutation } from "@apollo/client";

import { GridEvents, GridRenderEditCellParams } from "@mui/x-data-grid-pro";

import { UPDATE_ROUTE_DISPATCH_MUTATION } from "globals/graphql";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import DriverEditAutoComplete from "./DriverEditAutocomplete";
import {
  useAnalytics,
  useSnackbar,
  useSendDriverAssignNotification,
} from "globals/hooks";
import { RouteDriver, FarmRelationshipEnum } from "types";

type DriverEditColumnType = {
  params: GridRenderEditCellParams;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function DriverEditColumn(props: DriverEditColumnType) {
  const { params, setSaveIndicatorState } = props;
  const { id, api, field, row } = params;

  // derived state
  const routeId = row?.routes[0].id;
  const driver: RouteDriver = row?.routes[0].routeDriver;
  const farmAffiliateId = row?.routes[0].farmAffiliate?.id;
  const isFarmedRoute = row?.routes[0].isFarmedRoute;
  const isFarmor =
    row?.routes[0].farmRelationship === FarmRelationshipEnum.Farmor;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const { onSendDriverAssignNotification } = useSendDriverAssignNotification();

  // event handlers
  const handleChange = async (event, newValue) => {
    setSaveIndicatorState("loading");
    api.setEditCellValue({ id, field, value: newValue }, event);

    // Check if the event is not from the keyboard
    // https://github.com/facebook/react/issues/7407
    if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
      await api.commitCellChange({ id, field });
      api.setCellMode(id, field, "view");
    }

    // Supports event bubbling of events fired from portals
    // MUI currently doesn't support this on its own.
    if ((event as any).key) {
      const params = api.getCellParams(id, field);
      api.publishEvent(
        GridEvents.cellNavigationKeyDown,
        params,
        event as any as React.KeyboardEvent<HTMLElement>
      );
    }
  };

  const handleDriverSelect = async (event, routeDriver) => {
    const selectedDriver = routeDriver;

    if (selectedDriver?.id !== driver?.id) {
      handleChange(event, selectedDriver);

      updateRouteDispatch({
        variables: {
          input: {
            routeId,
            driverId: selectedDriver?.driver.id || null,
          },
        },
      });
    }
  };

  // mutations
  const [updateRouteDispatch] = useMutation(UPDATE_ROUTE_DISPATCH_MUTATION, {
    refetchQueries: ["OperatorRoutes"],
    onCompleted(data) {
      track("reservationTripView_driverUpdated");

      setSaveIndicatorState("saved");

      if (data.updateRouteDispatch.route.routeDriver?.id) {
        onSendDriverAssignNotification(data.updateRouteDispatch.route.id);
      }
    },
    onError(error) {
      setSaveIndicatorState("error");

      const errorMessage =
        getErrorMessage(error) || "Error updating route status.";
      snackbar.error(errorMessage);
    },
  });

  // refs
  const handleRef = (element) => {
    if (element) {
      element.querySelector(`input[id="dispatch-driver-autocomplete"]`).focus();
    }
  };

  return (
    <DriverEditAutoComplete
      ref={handleRef}
      value={driver}
      onDriverAutoCompleteChange={handleDriverSelect}
      routeId={routeId}
      {...(isFarmedRoute &&
        isFarmor && {
          farmAffiliateId: farmAffiliateId,
        })}
    />
  );
}

export default DriverEditColumn;
