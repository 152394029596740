import React from "react";

import { Badge } from "@mui/material";

import { granite } from "../../design-system/colors";
import { MessageIcon } from "../../design-system/icons";
import { primaryMainColor } from "../../theme";
import { useChat } from "../../globals/hooks/useChat";
import SquareNavButton from "design-system/components/layout/SideNavButtons/SquareNavButton";

type ChatToggleButtonProps = {
  chatPanelOpen: boolean;
  onChatPanelToggle: () => void;
  isChatAccessible: boolean;
};

function ChatToggleButton(props: ChatToggleButtonProps) {
  const { chatPanelOpen, onChatPanelToggle, isChatAccessible } = props;

  const { unreadMessagesCount } = useChat();

  if (!isChatAccessible) {
    return null;
  }

  return (
    <SquareNavButton
      text="Chat"
      active={chatPanelOpen}
      onClick={onChatPanelToggle}
      icon={
        <Badge
          badgeContent={unreadMessagesCount}
          max={999}
          color="error"
          // height set to offset badget which pushes the button text too low
          sx={{ height: 22 }}
        >
          <MessageIcon color={chatPanelOpen ? primaryMainColor : granite} />
        </Badge>
      }
    />
  );
}

export default ChatToggleButton;
